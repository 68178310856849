import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { withTranslation } from 'react-i18next';
import TenantLicenseTable from '../General/TenantLicenseTable';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import common from '../../jsons/common.json';

class TenantUserCountTab extends Component {
    constructor(props){
        super(props);
        this.state = {
            startDate: '',
            endDate: '',
            tenant: '',
            list: [],
            inputOpen: '',
            active: true,
            type: '',
            offset: 0,
            listSize: 10,
            totalCount: 0,
            page: 0,
        };
    }

    async componentDidMount(){
        this.setState({active: this.props.active, type: this.props.licenseType, tenant: this.props.tenant}, () => {this.loadRecords()});
    }

    loadRecords = async () => {
        let data = {
            startDate: this.state.startDate, 
            endDate: this.state.endDate, 
            tenant: this.state.tenant, 
            active: this.state.active, 
            type: this.state.type,
            size: this.state.listSize,
            offset: this.state.offset
        };
        let list = await axios.get('/int/tenantusercount', {params: data});
        this.setState({list: list.data, totalCount: list.data.totalCount});
    };

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    userTable = () => {
        const users = this.state.list != null && this.state.list.users != null ? this.state.list.users : [];
        return (
            <TableContainer component={Paper}>
                <Table aria-label="tenantUsers" size="small">
                    <TableHead>
                        <TableRow key='tenantUserHeader'>
                            <TableCell>Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Created On</TableCell>
                            <TableCell>First Login</TableCell>
                            <TableCell>Last Login</TableCell>
                            <TableCell>Disabled On</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user, k) =>
                            <TableRow key={user._id}>
                                <TableCell>{user.firstName + ' ' + user.lastName}</TableCell>
                                <TableCell>{user.active ? user.type : 'Disabled'}</TableCell>
                                <TableCell>
                                    {user.createdOn != null &&
                                        <DateDisplay value={user.createdOn} format={this.props.auth.user.dateFormat}/>
                                    }
                                </TableCell>
                                <TableCell>
                                    {user.firstLogin != null &&
                                        <DateDisplay value={user.firstLogin} format={this.props.auth.user.dateFormat}/>
                                    }
                                </TableCell> 
                                <TableCell>
                                    {user.lastLogin != null &&
                                        <DateDisplay value={user.lastLogin} format={this.props.auth.user.dateFormat}/>
                                    }
                                </TableCell>    
                                <TableCell>
                                    {user.disabledOn != null &&
                                        <DateDisplay value={user.disabledOn} format={this.props.auth.user.dateFormat}/>
                                    }
                                </TableCell>                                                      
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow key="tableFooter">
                            <TablePagination count={this.state.totalCount}
                            rowsPerPage={this.state.listSize}
                            onPageChange={this.handleChangePage}
                            page = {this.state.page}
                            rowsPerPageOptions={common.rowOptions}
                            onRowsPerPageChange={this.handleRowsPerPage}/>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        )
    }

    render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={9} xl={10} order={{ xs: 2, md: 1}}>
                    {this.userTable()}
                </Grid>
                <Grid item xs={12} md={3} xl={2} order={{ xs: 1, md: 2}}>
                    <TenantLicenseTable
                        types={this.props.auth.common.tenantTypes}
                        type={this.state.type}
                        tenant={this.state.tenant}
                        t={this.props.t}
                    />
                </Grid>
            </Grid>
        )        
    }

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(TenantUserCountTab));