import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Aux from '../../hoc/Auxilary/Auxilary';
import Panel from '../UI/Panel/Panel';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import ActionBar from '../UI/Buttons/ActionBar';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import ValueHelper from '../../helpers/valueHelper';

class TenantList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            name: '',
            listSize: 10,
            offset: 0,
            pages: 1,
            orderBy: 'name',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            security: [],
            licenseTypes: [],
            licenseType: '',
            licenseTypeSel: {},
            authTypes: [],
            authType: '',
            authTypeSel: {},
            readOnly: false,
            isActive: true
        };
    }

    componentDidMount(){
        const authTypes = ValueHelper.authOptions();
        this.setState({ authTypes }, () => {
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        const sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            offset: this.state.offset,
            size: this.state.listSize,
            name: this.state.search,
            licenseType: this.state.licenseType,
            authType: this.state.authType,
            isActive: this.state.isActive,
            readOnly: this.state.readOnly,
            sort: JSON.stringify(sort)
        }
        const list = await axios.get('/int/tenants', {params: data});
        this.setState({
            list: list.data.data,
            pages: Math.ceil(list.data.totalCount / this.state.listSize),
            totalCount: list.data.totalCount,
            security: this.props.permission,
            licenseTypes: list.data.values.licenseTypes
        }, () => {
            const crumbs = [
                { path: 'active', label: 'Tenants', screen: this.props.auth.screenDefs.TenantList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => this.loadRecords());
    }

    changeBool = e => {
        this.setState({[e.target.name]: !this.state[e.target.name], page: 0, offset: 0}, () => this.loadRecords());
    }

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    handlePageClick = data => {
        const selected = data.selected;
        const offset = Math.ceil(selected * this.state.listSize);
    
        this.setState({ offset: offset }, () => {
          this.loadRecords();
        });
    };

    render(){
        const security = this.state.security;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/tenant'
                        createAccess={security.create}
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3} xl={1}>
                            <TextField value={this.state.search} onChange={this.changeValue} name="search"
                            size="medium" fullWidth label="Name"/>
                        </Grid>
                        <Grid item xs={6} sm={3} xl={1}>
                            <AutoCompleteField
                                value={this.state.licenseTypeSel}
                                options={this.state.licenseTypes}
                                onChange={this.changeAuto('licenseTypeSel', 'licenseType')}
                                label="License"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} xl={1}>
                            <AutoCompleteField
                                value={this.state.authTypeSel}
                                options={this.state.authTypes}
                                onChange={this.changeAuto('authTypeSel', 'authType')}
                                label="Authentication"
                            />
                        </Grid>
                        <Grid item>
                            <CheckboxField checked={this.state.isActive} onChange={this.changeBool} name="isActive" label="Active"/>
                        </Grid>
                        <Grid item>
                            <CheckboxField checked={this.state.readOnly} onChange={this.changeBool} name="readOnly" label="Read Only"/>
                        </Grid>                        
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Tenants">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('name')}
                                            active={this.state.orderBy === 'name'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('licenseType')}
                                            active={this.state.orderBy === 'licenseType'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>License
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('authType')}
                                            active={this.state.orderBy === 'authType'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Authentication
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Read Only</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell sx={{ fontStyle: row.active ? null : 'italic' }}><Link to={'/tenant/'  + row._id}>{row.name}</Link></TableCell>
                                        <TableCell>{row.typeLabel != null ? row.typeLabel : 'None'}</TableCell>
                                        <TableCell>
                                            {row.authType?.length === 1 ? 
                                                this.state.authTypes.find(x => x.value === row.authType[0])?.label :
                                                row.authType?.length > 1 ? 
                                                    row.authType.map(auth => this.state.authTypes.find(x => x.value === auth)?.label).join(', ') : 
                                                    'None'
                                            }
                                         </TableCell>
                                        <TableCell>{row.readOnly? 'Yes' : 'No'}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(TenantList);