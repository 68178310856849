import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min.js';
import 'bootstrap-select/dist/js/i18n/defaults-en_US.min.js';
import 'popper.js/dist/popper.min.js';
import './index.css';
import './App.css';
import { CookiesProvider } from 'react-cookie';
import './i18n';
import { GoogleOAuthProvider } from '@react-oauth/google';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <div className="app" id="__cy_root" data-cy-root>
        <GoogleOAuthProvider clientId="288568592512-7ram6dnh1j3qil5l4jsi42qp9hu80q98.apps.googleusercontent.com">
            <CookiesProvider>
                <HashRouter>
                    <App />
                </HashRouter>
            </CookiesProvider>
        </GoogleOAuthProvider>
    </div>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register();
