import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import DateSelect  from '../UI/DateSelect/DateSelect';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ValueHelper from '../../helpers/valueHelper';

class LotsExpiring extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            orderBy: 'expires',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            item: '',
            itemSel: null,
            items: [],
            expires: ''
        }
    }

    async componentDidMount(){
        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.allItems], this.props.auth.constants, null, this.props.auth.user.tenant);
        let items = cacheValues.allItems
        this.setState({items: items});
        this.loadRecords();
    }

    loadRecords = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: 'item', value: this.state.item !== '' ? this.state.item : null},
            {id: 'expires', value: this.state.expires !== '' ? [this.state.expires, null] : []},
            {id: 'expiring', value: true}
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let list = await axios.get('/api/lots', {params: params});
        this.setState({
            list: list.data.data,
            pages: Math.ceil(list.data.totalCount / this.state.listSize),
            totalCount: list.data.totalCount
        });
    };

    changeExpires = (e) => {
        this.setState({expires: e}, () => {this.loadRecords()});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        let orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <Grid container spacing={2} sx={{ my: 1 }}>
                    <Grid item xs={12}>
                        <SectionTitle title={t('lotsExpiring')}/>
                        <Grid container spacing={2} >
                            <Grid item xs={4} sm={4}>
                                <DateSelect
                                    onChange={this.changeExpires}
                                    value={this.state.expires}
                                    label={t('expires')}
                                    format={this.props.auth.user.dateFormat}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AutoCompleteField
                                    value={this.state.itemSel}
                                    options={this.state.items}
                                    onChange={this.changeAuto('itemSel', 'item')}
                                    label={t('item')}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Table aria-label="Lots Expiring List" size="small">
                                    <TableHead key="lotsExpiringHeader">
                                        <TableRow>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('number')}
                                                active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('item')}
                                                active={this.state.orderBy === 'item'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('item')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('available')}
                                                active={this.state.orderBy === 'available'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('available')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('amount')}
                                                active={this.state.orderBy === 'amount'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('total')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('expires')}
                                                active={this.state.orderBy === 'expires'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('expires')}
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.list != null && this.state.list !== '' ? this.state.list.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell><Link to={'/lot/' + row._id}>{row.number}</Link></TableCell>
                                                <TableCell><Link to={'/item/' + row.item._id}>{row.item.number + '-' + row.item.name}</Link></TableCell>
                                                <TableCell><NumericFormat displayType={'text'} value={row.available} thousandSeparator={true} decimalScale={3}/></TableCell>
                                                <TableCell><NumericFormat displayType={'text'} value={row.amount} thousandSeparator={true} decimalScale={3}/></TableCell>
                                                <TableCell><DateDisplay value={row.expires} format={this.props.auth.user.dateFormat}/></TableCell>
                                            </TableRow> ) :
                                            <TableRow key="noLots">
                                                <TableCell colSpan={5} sx={{ textAlign: 'center' }}>{t('noLots')}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow key="tableFooter">
                                            <TablePagination count={this.state.totalCount}
                                            rowsPerPage={this.state.listSize}
                                            onPageChange={this.handleChangePage}
                                            page = {this.state.page}
                                            rowsPerPageOptions={common.rowOptions}
                                            onRowsPerPageChange={this.handleRowsPerPage}/>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Aux>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(LotsExpiring));