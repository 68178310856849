import React, { useState, useEffect } from 'react';

export default function PrintPreview(props) {
  const [image, setImage] = useState('');
  const { altText, dimensions, labelData, quality } = props;

  useEffect(() => {
    const fetchData = async () => {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `file=${labelData}`,
      };
      try {
        let response = await fetch(`https://api.labelary.com/v1/printers/${quality.replace(/\s/g, '')}/labels/${dimensions}/0/`, options);
        if(response.ok){
          const base64 = btoa(
            new Uint8Array(await response.arrayBuffer())
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          setImage('data:image/png;base64,' + base64);
        }else {
          console.error(response);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [labelData, dimensions, quality]);

  if(image)
    return <img src={image} alt={altText} width='550px' height='250px' />;
  else
    return <div>Loading...</div>;
}