import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { MenuContent } from './OptionMenuContent';
import process from 'process';

export default function OptionMenu (props) {
  const { clicked, drawerWidth, open, onOpen } = props;
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const desktopMenu = (
    <Drawer
      anchor="right"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      open={open}
      onClose={clicked} >
      <MenuContent isMobile={isMobile} onClose={clicked}/>
    </Drawer>
  )

  const mobileMenu = (
    <SwipeableDrawer
      anchor="right"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
        },
      }}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      ModalProps={{
        keepMounted: true
      }}
      onClose={clicked}
      onOpen={onOpen}
      open={open}
      variant="temporary" >
      <MenuContent isMobile={isMobile}/>
    </SwipeableDrawer>
  )

  if(open) {
    if(isMobile) {
      return mobileMenu;
    }else {
      return desktopMenu;
    }
  }else {
    return null;
  }
};