import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { withTranslation } from 'react-i18next';
import ModalDialog from '../UI/Dialog/ModalDialog';
import Divider from '@mui/material/Divider';
import TextField from '../UI/TextField/TextField';
import { encryptString } from '../../helpers/securityHelper';
import isEmpty from '../../is-empty';
import Confirmation from '../UI/Dialog/Confirmation';
import DocumentDisplay  from '../General/DocumentDisplay';

class SopCertification extends Component {
    constructor(props){
        super(props);
        this.state = {
            sopId: this.props.sopId,
            sop: {},
            cert: [],
            document: null,
            signature: null,
            _id: '',
            user: '',
            status: '',
            statusSel: null,
            statuses: [],
            expiration: '',
            start: new Date(),
            number: '',
            content: '',
            scrollBottom: false,
            buttonStack: [],
            errors: [],
            pin: '',
            certified: false,
            confirmOpen: false,
            scrollingEnabled: false,
            docData: null,
            pageNumber: 1,
            numPages: null
        };
    }

    async componentDidMount(){
        const { certification, document, user } = this.props;
        let cert = null;
        let sop = null;
        if(certification) {
            cert = certification;
            sop = certification.sop;
        }else {
            cert = certification;
        }
        let values = await axios.get('/api/certvalues');
        let certified = cert != null && cert.status === this.props.auth.constants.documentCertificationStatuses.signed;
        if (cert != null){
            let statusSel = values.data.statuses.find(x => x.value === cert.status);
            this.setState({
                cert: cert,
                sop: sop != null ? sop.data : null,
                document: document,
                user: user,
                _id: cert._id,
                signature: cert.signature,
                status: cert.status,
                statusSel: statusSel,
                statuses: values.data.statuses,
                expiration: cert.expiration,
                start: cert.start,
                number: cert.number,
                content: document.content,
                certified: certified,
                scrollBottom: false,
                pin: ''
            });
        }else {
            let statusSel = values.data.statuses.find(x => x.value === this.props.auth.constants.documentCertificationStatuses.open);
            this.setState({
                cert: null,
                expiration: null,
                number: '',
                signature: null,
                sop: sop != null ? sop.data : null,
                document: document,
                user: user,
                statuses: values.data.statuses,
                status: this.props.auth.constants.documentCertificationStatuses.open,
                statusSel: statusSel,
                content: document != null ? document.content : null,
                certified: certified,
                scrollBottom: false,
                pin: ''
            });
        }
    }

    async componentDidUpdate(prevProps){
        if (this.props.certification !== prevProps.certification || 
            this.props.user !== prevProps.user) {
            this.componentDidMount();
        }
    }

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    sign = async () => {
        const t = this.props.t;
        const valid = this.validatePin();
        if(!valid) 
            return;        
        let signature = null;
        let pinEnc = encryptString(this.state.pin);
        let pinResult = await axios.post('/api/userpin', {userid: this.state.user, pin: pinEnc});
        if(pinResult.data.signature != null && !pinResult.data.expired) {
            signature = pinResult.data.signature;
        }else {
            if(pinResult.data.expired){
                this.setState({errors: {pin: t('pinExp')}});
            }else {
                this.setState({errors: {pin: t('pinInvalid')}});
            }
            return;
        }

        let cert = {
            id: this.state.cert != null ? this.state.cert._id : "",
            sop: this.state.sop != null ? this.state.sop._id : null,
            user: this.state.user,
            document: this.state.document._id,
            documentVersion: this.state.document.version,
            start: new Date(),
            status: this.props.auth.constants.documentCertificationStatuses.signed
        }
        let id = '';
        try{
            let result = await axios.post('/api/cert', cert);
            id = result.data.id
        } catch(err) {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            return;
        }

        let data = {
            _id: this.state.signature != null && this.state.signature._id != null && this.state.signature._id !== '' ?
                 this.state.signature._id : null,
            signature: signature,
            user: this.state.user,
            object: id,
            objectType: this.props.auth.constants.objectIds.documentCertification,
            type: this.props.auth.constants.signatureTypes.user,
            date: this.state.start
        };
        try {
            let result = await axios.post('/api/signature', data);
            this.props.toggleDialog();
            this.props.refresh();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        }catch(err) {
            if(err.response != null)
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    validatePin = () => {
        const t = this.props.t;
        let errors = {};
        if(this.state.pin == null || this.state.pin === '')
            errors[t('pin')] = t('required');
        this.setState({errors: errors});
        return isEmpty(errors);
    }

    dialogButtons = (t, toggleDialog) => {
        const errors = this.state.errors;
        const certified = this.state.certified;
        // const enableButtons = this.state.scrollingEnabled ? this.state.scrollBottom : !this.state.scrollingEnabled;
        const enableButtons = true;
        return (
            <Grid container spacing={1}>
                {!certified &&
                    <Aux>
                        <Grid item xs={6} sm={3} md={2}>
                            <TextField value={this.state.pin} onChange={this.changeNumber} name="pin"
                            size="medium" fullWidth={true} disabled={!enableButtons} label={t('pin')} type={'password'}
                            error={errors[t('pin')] != null ? true : false} helperText={errors[t('pin')]}/>
                        </Grid>
                        <Grid item xs={4}>
                            <Button color="primary" size="large" onClick={this.sign}
                            disabled={!enableButtons}>{t('sign')}</Button>&nbsp;&nbsp;
                            <Button color="secondary" size="large" onClick={this.toggleConfirmation}>{t('close')}</Button>
                        </Grid>
                    </Aux>
                }
                {certified &&
                    <Grid item xs={4}>
                        <Button color="primary" size="large" onClick={this.renewClick}
                        disabled={!enableButtons}>{t('renew')}</Button>&nbsp;&nbsp;
                        <Button color="secondary" size="large" onClick={toggleDialog}>{t('close')}</Button>
                    </Grid>
                }
            </Grid>
        );
    }

    toggleConfirmation = () => {
        this.setState({ confirmOpen: !this.state.confirmOpen });
    }

    clickYes = () => {
        this.setState({ confirmOpen: false });
        this.props.toggleDialog();
    }

    renewClick = (e) => {
        this.setState({ certified: false });
    }

    handleScroll = (e) => {
        const scrollBottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) === e.target.clientHeight;
        if(scrollBottom && !this.state.scrollBottom) this.setState({ scrollBottom : true });        
    }

    toggleScroll = (value) => {
        this.setState({ scrollingEnabled: value });
    }

    onDocumentLoadSuccess = ({numPages}) => {
        this.setState({numPages});
    };

    prevClick = e => {
        this.setState({pageNumber: this.state.pageNumber - 1});
    };

    nextClick = e => {
        this.setState({pageNumber: this.state.pageNumber + 1});
    };

    render(){
        let version = this.state.document != null ? this.state.document.version : '';
        let name = this.state.document != null && this.state.document.name != null ? this.state.document.name : '';
        const { dialogOpen, t, toggleDialog } = this.props;
        return (
            <ModalDialog buttonStack={this.dialogButtons(t, toggleDialog)} title={name !== '' ? name + ' - ' + version : ''} toggleDialog={toggleDialog}
                        dialogStatus={dialogOpen} fullWidth maxWidth='lg' onScroll={this.handleScroll} scrollExists={this.toggleScroll} >
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <FormControl>
                            <FormLabel>{t('number')}</FormLabel>
                            {this.state.number != null && this.state.number !== '' ? this.state.number : t('none')}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <FormControl>
                            <FormLabel>{t('status')}</FormLabel>
                            {this.state.statusSel != null && this.state.statusSel !== '' && this.state.statusSel.label}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <FormControl>
                            <FormLabel>{t('start')}</FormLabel>
                            <DateDisplay value={this.state.start} format={this.props.auth.user.dateFormat}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <FormControl>
                            <FormLabel>{t('expiration')}</FormLabel>
                            {this.state.expiration != null && this.state.expiration !== '' ?
                                <DateDisplay value={this.state.expiration} format={this.props.auth.user.dateFormat}/> :
                                t('none')
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <FormControl>
                            <FormLabel>{t('signature')}</FormLabel>
                            {this.state.signature != null && this.state.signature !== '' ?
                                <img src={this.state.signature.signature} alt={t('noSignature')} /> :
                                t('none')}
                        </FormControl>
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <br />
                {this.state.document != null && this.state.document.content != null && this.state.document.content !== '' &&
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            <div onScroll={this.handleScroll}>
                                {parse(this.state.content)}
                            </div>
                        </Grid>
                    </Grid>
                }
                {this.state.document != null && this.state.document.contentDocument != null &&
                    <DocumentDisplay document={this.state.document.contentDocument}/>
                }
                <Confirmation
                    content={t('confirmCloseWithoutSave')}
                    no={this.toggleConfirmation}
                    noButton={t('no')}
                    yes={this.clickYes}
                    yesButton={t('yes')}
                    title={t('confirmCloseWithoutSaveTitle')}
                    open={this.state.confirmOpen}
                />
            </ModalDialog>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    sop: state.sop
  });
  
export default withTranslation() (connect(mapStateToProps)(SopCertification));