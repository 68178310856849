import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Aux from '../../hoc/Auxilary/Auxilary';
import Panel from '../UI/Panel/Panel';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Title from '../UI/Typography/Title';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import readXlsxFile from 'read-excel-file';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import base64 from 'base-64';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import DocumentViewer from '../General/DocumentViewer';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';

class DataUpload extends Component {
    constructor(props){
        super(props);
        this.state = {
            file: null,
            objects: [],
            object: '',
            objectSel: null,
            fileRows: null,
            disableSave: false,
            hasHeader: true,
            fileBinary: '',
            fileName: '',
            user: '',
            userSel: null,
            users: [],
            status: '',
            statusSel: null,
            statuses: [],
            _id: '',
            date: '',
            result: '',
            fileObj: {},
            delete: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.status, common.cacheValues.object, common.cacheValues.user], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({
            objects: cacheValues.objects,
            statuses: cacheValues.statuses,
            users: cacheValues.users,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }
        });
    }

    async loadRecord(){
        let record = await axios.get('/int/dataupload/' + this.state._id);
        let userSel = this.state.users.find(x => x.value === record.data.user);
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let objectSel = this.state.objects.find(x => x.value === record.data.object);
        this.setState({
            date: record.data.date,
            object: record.data.object,
            objectSel: objectSel,
            user: record.data.user,
            userSel: userSel,
            result: record.data.result,
            status: record.data.status,
            statusSel: statusSel,
            fileName: record.data.fileName,
            fileObj: record.data.file,
            delete: record.data.delete === true ? true : false
        })
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({[name]: newValue, [data]: newValue != null ? newValue.value : ''});
        if(data === 'object'){
            if(newValue == null || (newValue != null && newValue.value !== this.props.auth.constants.objectIds.itemLedger)){
                this.setState({delete: false});
            }
        }
    }

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    loadClick = async (e) => {
        let data = {
            object: this.state.object,
            fileRows: this.state.fileRows,
            hasHeader: this.state.hasHeader,
            fileName: this.state.fileName,
            fileBinary: this.state.fileBinary,
            delete: this.state.delete
        };
        this.setState({disableSave: true}, async () => {
            try {
                let result = await axios.post('/int/dataupload', data);
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: "Success", severity: 'success'}});
                this.setState({disableSave: false, _id: result.data.id}, () => this.loadRecord());
            } catch(err){
                console.error(err.response.data);
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: "Error", severity: 'error'}});
                this.setState({disableSave: false});
            }
        });
        
    }

    handleFileUpload = (file) => {
        let rawFile = file.target.files[0];
        let reader = new FileReader();
        reader.readAsBinaryString(rawFile);
        reader.onload = async () => {
            let fileData = base64.encode(reader.result);
            this.setState({fileBinary: fileData});
        }
        readXlsxFile(rawFile).then((rows) => {
            this.setState({fileRows: rows, fileName: rawFile.name});
        });
    }

    render(){
        return(
            <Aux>
                <Title title="Data Upload"/>
                <Panel>
                    {(this.state._id == null || this.state._id === '') &&
                        <Aux>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.objectSel}
                                        options={this.state.objects}
                                        onChange={this.changeAuto('objectSel', 'object')}
                                        label='Object'
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.hasHeader} onChange={this.changeBool('hasHeader')}
                                    label="Has Headers" name="hasHeader" disabled={false}
                                    />
                                </Grid>
                                {this.state.objectSel != null && this.state.object === this.props.auth.constants.objectIds.itemLedger &&
                                    <Grid item xs={4} sm={2}>
                                        <CheckboxField
                                        checked={this.state.delete} onChange={this.changeBool('delete')}
                                        label="Delete Ledgers" name="deleteRows" disabled={false}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}>
                                    <label>Excel File</label>
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    <input type="file" onChange={this.handleFileUpload} multiple={false}/>
                                </Grid>
                            </Grid>
                            {this.state.fileRows != null && this.state.fileRows.length > 0 && this.state.objectSel != null &&
                                <Grid container spacing={3}>
                                    <Grid item sm={12}>
                                        <Button variant="contained" disabled={this.state.disableSave} size="small" color="primary" onClick={this.loadClick}>Load</Button>
                                    </Grid>
                                </Grid>
                            }
                        </Aux>
                    }
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>Status</FormLabel>
                                        {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>Date</FormLabel>
                                        <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>Object</FormLabel>
                                        {this.state.objectSel != null ? this.state.objectSel.label : ''}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <FormControl>
                                        <FormLabel>User</FormLabel>
                                        {this.state.userSel != null ? this.state.userSel.label : ''}
                                    </FormControl>
                                </Grid>
                                {this.state.delete === true &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>Delete</FormLabel>
                                            Yes
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.fileObj != null && this.state.fileObj.azureId != null && this.state.fileObj.azureId !== '' &&
                                    <Aux> 
                                        <Grid item xs={6} sm={3}>
                                            <FormControl>
                                                <FormLabel>Blob Azure Id</FormLabel>
                                                {this.state.fileObj.azureId}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={8} sm={4}>
                                            <FormControl>
                                                <FormLabel>File</FormLabel>
                                                <DocumentViewer document={this.state.fileObj}/>
                                            </FormControl>
                                        </Grid>
                                    </Aux>
                                }
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    <FormControl>
                                        <FormLabel>Result</FormLabel>
                                        {this.state.result}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Aux>
                    }
                </Panel>
            </Aux>
        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(DataUpload);