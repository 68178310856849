import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Link from '../../UI/Link/Link';
import FormControlLabel from '@mui/material/FormControlLabel';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteRoleIcon from '@mui/icons-material/ClearRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import isEmpty from '../../../is-empty';
import AutoCompleteField from '../../UI/AutoCompleteField/AutoCompleteField';
import { EvoraVerticalTabs, VerticalTabCanvas } from '../../UI/Tabs/Tabs';
import Aux from '../../../hoc/Auxilary/Auxilary';
import SectionTitle from '../../UI/Typography/SubSectionHeading';
import Switch from '../../UI/Switch/Switch';

class Screen extends Component { 
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            enableSave: true,
            tabKey: 0,
            tabLabels: [
                {name: t('read'), disabled: false},
                {name: t('create'), disabled: false},
                {name: t('delete'), disabled: false},
                {name: t('update'), disabled: false},
                {name: t('complete'), disabled: false},
                {name: t('review'), disabled: false},
                {name: t('signoff'), disabled: false},
                {name: t('print'), disabled: false},
                {name: t('unlock'), disabled: false},
            ],
            errors: {},
            all: false,
            complete: [],
            create: [],
            delete: [],
            departments: [],
            internal: false,
            print: [],
            processes: [],
            read: [],
            review: [],
            roles: [],
            screen: '',
            signoff: [],
            tenant: '',
            unlock: [],
            update: [],
            __v: 0,
            _id: '',
        }
    }

    componentDidMount = async () => {
        if(!isEmpty(this.props.settingsData))
            this.loadRecord();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.settingsData !== this.props.settingsData) {
            this.loadRecord();
        }
    }

    loadRecord = () => {
        const settingsData = this.props.settingsData;
        if(this.state.tabLabels.length > 0) {
            this.state.tabLabels.forEach(row => {
                let settings = settingsData[row.name.toLowerCase()];
                settings.forEach(setting => {
                    if(setting.accordion == null)
                        setting.accordion = false        
                    if(setting.role != null && setting.role !== '')
                        setting.roleSel = settingsData.roles.find(x => x.value === setting.role);
                    else
                        setting.roleSel = {};        
                    if(setting.process != null && setting.process !== '')
                        setting.procSel = settingsData.processes.find(x => x.value === setting.process);
                    else
                        setting.procSel = {};        
                    if(setting.departments.length > 0) {
                        setting.depSels = [];
                        let depString = '';
                        setting.departments.forEach((department, i) => {
                            const depSel = settingsData.departments.find(x => x.value === department);
                            setting.depSels.push(depSel);
                            depString += depSel.label;
                            if((setting.departments.length - 1) !== i) 
                                depString += ', ';
                        });
                        setting.depString = depString;
                    }else {
                        setting.depSels = [];
                        setting.depString = '';
                    }
                    if(setting.errors == null)
                        setting.errors = {};
                });
            })
        }
        
        this.setState({
            all: settingsData.all,
            complete: settingsData.complete,
            create: settingsData.create,
            delete: settingsData.delete,
            departments: settingsData.departments,
            internal: settingsData.internal,
            print: settingsData.print,
            processes: settingsData.processes,
            read: settingsData.read,
            review: settingsData.review,
            roles: settingsData.roles,
            screen: settingsData.screen,
            signoff: settingsData.signoff,
            tenant: settingsData.tenant,
            unlock: settingsData.unlock,
            update: settingsData.update,
            __v: settingsData.__v,
            _id: settingsData._id,
        });
    }

    addRole = (type) => {
        let setting = this.state[type];
        setting.push({
            accordion: true,
            departments: [],
            depSel: [],
            depString: '',
            role: '',
            roleSel: {},
            process: '',
            processSel: {},
            errors: {}
        })
        let state = this.state;
        state[type] = setting;
        this.setState(state);
    }

    deleteRole = (type, i) => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._deleteRole(type, i));                
    };

    _deleteRole = async (type, i) => {
        let security = this.state[type][i];                
        const data = {
            type: type,
            role: security.role,
            screenId: this.state._id
        }
        try {
            const result = await axios.post('/api/screenroledelete', data);
            this.setState({enableSave: true}, () => {
                this.props.callBack();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    changeBool = (name) => {
        let state = this.state;
        state[name] = !state[name];
        this.setState(state);
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    changeAuto = (type, i, name, data) => (e, newValue) => {
        let state = this.state;
        state[type][i][name] = newValue;
        state[type][i][data] = newValue != null ? newValue.value : null;
        this.setState(state);
    };

    changeDepartment = (type, i) => (e, newValue) => {
        let state = this.state;
        let depSels = [];
        let ids = [];
        let depString = '';
        if(newValue != null){
            newValue.forEach((row, index) => {
                depSels.push({value: row.value, label: row.label});
                ids.push(row.value);
                depString += row.label;
                if((newValue.length - 1) !== index)
                    depString += ', ';
            })
        }
        state[type][i].depSels = depSels;
        state[type][i].departments = ids;
        state[type][i].depString = depString;
        this.setState(state);
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false}, () => this._save());
    }

    _save = async (e) => {
        const data = {
            screen: this.state.screen,
            all: this.state.all,
            internal: this.state.internal,
            _id: this.state._id
        };
        try {
            const result = await axios.post('/api/screen', data);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.props.callBack();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        }
    };

    saveRole = (type, i) => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSaveRole: false }, () => this._saveRole(type, i));
    }

    _saveRole = async (type, i) => {
        const valid = this.validate(type, i);
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let security = this.state[type][i];
        const data = {
            type: type,
            role: security.role,
            departments: security.departments,
            screenId: this.state._id,
            process: security.process
        }
        try {
            const result = await axios.post('/api/screenrole', data);
            this.setState({ enableSave: true }, () => {
                this.props.callBack();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSaveRole: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        }
    };

    validate = (type, i) => {
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        let setting = this.state[type][i];
        if(setting.role == null || setting.role === ''){
            errors.role = t('required');
        }
        state[type][i].errors = errors;
        if(isEmpty(errors))
            this.setState(state);
        return isEmpty(errors);
    }

    toggleAccordion = (type, index) => {
        let permission = this.state[type];
        permission[index].accordion = !permission[index].accordion;
        let state = this.state;
        state[type] = permission;
        this.setState(state);
    }

    securityTab = (row) => {
        const { t } = this.props;
        const security = this.state;
        let settings = this.state[row.name.toLowerCase()];

        return (
            <Grid container item spacing={3}>
                <Grid item container xs={12}>
                    <SectionTitle title={row.name} add={() => this.addRole(row.name.toLowerCase())} addTitle={t('add')} />
                    <hr />
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow key={row.name + "TableHeader"} >
                                        <TableCell sx={{ width: '5%'}} />
                                        <TableCell sx={{ width: '10%'}}>{t('role')}</TableCell>
                                        <TableCell>{t('departments')}</TableCell>
                                        <TableCell sx={{ width: '5%'}} />
                                    </TableRow>
                            </TableHead>
                            <TableBody>
                                {(settings != null && settings !== '' && settings.length > 0) ?
                                    settings.map((setting, i) => {
                                        return (
                                            <Aux key={row.name.toLowerCase() + 'AuxRow-' + i}>
                                                <TableRow key={row.name.toLowerCase() + "Row-" + i + '-a'}>
                                                    <TableCell width="5%">
                                                        <IconButton aria-label="expand row" size="small" onClick={() => this.toggleAccordion(row.name.toLowerCase(), i)}>
                                                            {!setting.accordion ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell sx={{ width: '10%'}}>{setting.roleSel.label}</TableCell>
                                                    <TableCell>{setting.depString != null ? setting.depString : ''}</TableCell>
                                                    <TableCell width="5%">
                                                        <IconButton aria-label="delete row" size="small" onClick={() => this.deleteRole(row.name.toLowerCase(), i)}>
                                                            <DeleteRoleIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key={row.name.toLowerCase() + "Row-" + i + '-b'}>
                                                    <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                                        <Collapse in={setting.accordion} timeout="auto" unmountOnExit>
                                                            <Box margin={1}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={6} sm={3}>
                                                                        <AutoCompleteField
                                                                            value={setting.roleSel}
                                                                            options={security.roles}
                                                                            onChange={this.changeAuto(row.name.toLowerCase(), i, 'roleSel', 'role')}
                                                                            error={setting.errors.role != null ? true : false}
                                                                            helperText={setting.errors.role}
                                                                            label={t('role')}
                                                                            required
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6} sm={3}>
                                                                        <AutoCompleteField
                                                                            value={setting.procSel}
                                                                            options={security.processes}
                                                                            onChange={this.changeAuto(row.name.toLowerCase(), i, 'procSel', 'process')}
                                                                            error={setting.errors.process != null ? true : false}
                                                                            helperText={setting.errors.process}
                                                                            label={t('process')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item sm={12}>
                                                                        <AutoCompleteField
                                                                            multiple={true}
                                                                            stayOpen={true}
                                                                            value={setting.depSels}
                                                                            options={security.departments}
                                                                            onChange={this.changeDepartment(row.name.toLowerCase(), i)}
                                                                            error={setting.errors.depSels != null ? true : false}
                                                                            helperText={setting.errors.depSels}
                                                                            label={t('departments')}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container item xs={12} sx={{ justifyContent: 'flex-start' }} >
                                                                    <Grid item>
                                                                        <Button onClick={() => this.saveRole(row.name.toLowerCase(), i)} 
                                                                                variant='text' color='primary' 
                                                                                disabled={!this.state.enableSave} key='screen-settings-submit-btn'>{t('save')}</Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow> 
                                            </Aux>
                                        )
                                    }) : <TableRow key={row.name.toLowerCase() + '-row'}>
                                            <TableCell colSpan={4} align='center'>
                                                <strong>
                                                    <Link color="primary" onClick={() => this.addRole(row.name.toLowerCase())}>
                                                        {t('clickHereMessage.1')}
                                                    </Link>
                                                </strong>                                                        
                                                {t('clickHereMessage.2')}
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        );
    };

    securityTabs = () => {
        let tabs = [];
        const tabLabels = this.state.tabLabels;
        if (tabLabels != null && tabLabels.length > 0) {
            tabs = tabLabels.map((row, i) => 
                <VerticalTabCanvas value={this.state.tabKey} index={i} key={'security-settings-' + i}>
                    <Grid item container xs={12} spacing={3}>
                        {this.securityTab(row)}
                    </Grid>
                </VerticalTabCanvas>
            )
        }
        return tabs;
    };

    render() {
        const { t, toggleDialog } = this.props;
        const security = this.state;
        return (
            <Aux>
                <Grid container spacing={2} sx={{ justifyContent: 'flex-end' }} >
                    <Grid item>
                        <FormControlLabel
                            control={<Switch checked={security.all} onChange={() => this.changeBool('all')} name="all" />}
                            label={t('all')}
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={<Switch checked={security.internal} onChange={() => this.changeBool('internal')} name="internal" />}
                            label={t('internal')}
                            labelPlacement="start"
                        />
                    </Grid>    
                </Grid>
                <EvoraVerticalTabs TabContents={this.securityTabs} tabLabels={security.tabLabels} tabKey={security.tabKey} changeTab={this.changeTab} />                
                <Grid container item xs={12} sx={{ justifyContent: 'flex-end' }} >
                    <Grid item>
                        <Button onClick={() => toggleDialog()} variant='text' color='secondary' key='screen-settings-close-btn'>Close</Button>
                        <Button onClick={() => this.save()} 
                                variant='text' color='primary' 
                                disabled={!this.state.enableSave} key='screen-settings-submit-btn'>{t('save')}</Button>
                    </Grid>
                </Grid>
            </Aux>            
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(Screen));