import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Aux from '../../hoc/Auxilary/Auxilary';
import SectionTitle from '../UI/Typography/SectionTitle';
import React, { Component } from 'react';
import axios from 'axios';
import isEmpty from '../../is-empty';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import IconButton from '@mui/material/IconButton';
import PlusIcon from 'mdi-material-ui/Plus';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';

class RoomTables extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: '',
            listSize: 10,
            totalCount: 0,
            page: 0,
            orderBy: 'number',
            ordDir: -1,
            searchCode: '',
            _id: '',
            code: '',
            positionCode: '',
            grossArea: null,
            netArea: null,
            width: null,
            height: null,
            index: -1,
            showRecord: false,
            locked: true,
            enableSave: true
        };
    }

    async componentDidMount(){
        this.setState({
            locked: !this.props.locked ? false : true
        }, async () => {
            await this.loadRecords();
        })
    }

    loadRecords = async () => {
        var sort = {[this.state.orderBy]: this.state.ordDir};
        var parms = {
            offset: this.state.offset,
            size: this.state.listSize,
            sort: JSON.stringify(sort),
            room: this.props.room,
            code: this.state.searchCode
        }
        var list = await axios.get('/api/tables', {params: parms});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        });
    };

    changeSearch = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, async () => {this.loadRecords()});
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeNumber = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value});
    }

    save = () =>{
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async (e) => {
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            _id: this.state._id,
            code: this.state.code,
            positionCode: this.state.positionCode,
            grossArea: this.state.grossArea,
            netArea: this.state.netArea,
            length: this.state.length,
            width: this.state.width,
            room: this.props.room
        };
        try {
            let response = await axios.post('/api/table', data);
            this.setState({ enableSave: true }, () => {
                this.clear();
                this.loadRecords();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: response.data.message, title: this.props.t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        }
    };

    validate(){
        let errors = {};
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    delete = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._delete(e));
    }

    _delete = async (e) => {
        if(this.state._id == null || this.state._id === '' || this.state.locked){
            this.setState({enableSave: true});
            return;
        }
        try {
            let result = await axios.delete('/api/table/' + this.state._id);
            this.setState({enableSave: true});
            this.clear();
            this.loadRecords();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    };

    clear = () => {
        this.setState({
            _id: '',
            index: -1,
            code: '',
            positionCode: '',
            grossArea: null,
            netArea: null,
            width: null,
            height: null,
            showRecord: false
        })
    };

    select = (index) => (e) => {
        var list = this.state.list;
        var row = list[index];
        this.setState({
            index: index,
            _id: row._id,
            code: row.code,
            positionCode: row.positionCode,
            grossArea: row.grossArea,
            netArea: row.netArea,
            length: row.length,
            width: row.width,
            showRecord: true
        })
    };

    add = (e) => {
        this.setState({
            showRecord: true
        })
    };

    return = (e) => {
        this.props.history.goBack();
    };

    unlock = (e) => {
        this.setState({locked: !this.state.locked});
    }

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const locked = this.state.locked;
        const errors = this.props.errors;
        const t = this.props.t;
        return(
            <Aux>
                <SectionTitle title={t('roomTables')}/>
                <TableContainer component={Paper}>
                    <Table aria-label="Tables">
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>{t('code')}</TableCell>
                                <TableCell>{t('position')}</TableCell>
                                <TableCell>{t('grossArea')}</TableCell>
                                <TableCell>{t('length')}</TableCell>
                                <TableCell>{t('width')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list.map((row, i) =>
                                <TableRow key={row._id}>
                                    <TableCell>
                                        <TextOnlyClickButton enabled={true} click={this.select(i)} name={row.code}/>
                                    </TableCell>
                                    <TableCell>{row.positionCode}</TableCell>
                                    <TableCell>{row.grossArea}</TableCell>
                                    <TableCell>{row.length}</TableCell>
                                    <TableCell>{row.width}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                {this.props.permission.create &&
                                    <IconButton onClick={this.add}>
                                        <PlusIcon/>
                                    </IconButton>
                                }
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

                <Dialog open={this.state.showRecord} onClose={this.clear}>
                    <DialogTitle>
                        {t('table')}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={4} sm={2}>
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('code')}</FormLabel>
                                        {this.state.code}
                                    </FormControl>
                                }
                                {!locked &&
                                    <TextField value={this.state.code} onChange={this.changeValue} name="code"
                                    size="medium" fullWidth={true} disabled={locked} label={t('code')}
                                    error={errors[t('code')] != null ? true : false} helperText={errors[t('code')]}/>
                                }
                            </Grid>
                            <Grid item sm={7}>
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('position')}</FormLabel>
                                        {this.state.positionCode}
                                    </FormControl>
                                }
                                {!locked &&
                                    <TextField value={this.state.positionCode} onChange={this.changeValue} name="positionCode"
                                    size="medium" fullWidth={true} disabled={locked} label={t('position')}
                                    error={errors[t('positionCode')] != null ? true : false} helperText={errors[t('positionCode')]}/>
                                }
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('grossArea')}</FormLabel>
                                        {this.state.grossArea}
                                    </FormControl>
                                }
                                {!locked &&
                                    <TextField type="number" value={this.state.grossArea} onChange={this.changeNumber} name="grossArea"
                                    size="medium" fullWidth={true} disabled={locked} label={t('grossArea')}
                                    error={errors[t('grossArea')] != null ? true : false} helperText={errors[t('grossArea')]}/>
                                }
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('netArea')}</FormLabel>
                                        {this.state.netArea}
                                    </FormControl>
                                }
                                {!locked &&
                                    <TextField type="number" value={this.state.netArea} onChange={this.changeNumber} name="netArea"
                                    size="medium" fullWidth={true} disabled={locked} label={t('netArea')}
                                    error={errors[t('netArea')] != null ? true : false} helperText={errors[t('netArea')]}/>
                                }
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('length')}</FormLabel>
                                        {this.state.length}
                                    </FormControl>
                                }
                                {!locked &&
                                    <TextField type="number" value={this.state.length} onChange={this.changeNumber} name="length"
                                    size="medium" fullWidth={true} disabled={locked} label={t('length')}
                                    error={errors[t('length')] != null ? true : false} helperText={errors[t('length')]}/>
                                }
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('width')}</FormLabel>
                                        {this.state.width}
                                    </FormControl>
                                }
                                {!locked &&
                                    <TextField type="number" value={this.state.width} onChange={this.changeNumber} name="width"
                                    size="medium" fullWidth={true} disabled={locked} label={t('width')}
                                    error={errors[t('width')] != null ? true : false} helperText={errors[t('width')]}/>
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {!locked &&
                            <Button variant="contained" color="primary" size="small" onClick={this.save} disabled={locked && !this.state.enableSave}>{t('save')}</Button>
                        }
                        <Button variant="contained" color="secondary" size="small" onClick={this.clear}>{t('close')}</Button>
                        {!locked &&
                            <Button variant="contained" color="secondary" size="small" onClick={this.delete}>{t('delete')}</Button>
                        }
                        {this.props.permission.unlock &&
                            <Button variant="contained" color="secondary" size="small" onClick={this.unlock}>
                                {locked ? t('unlock') : t('lock')}
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(RoomTables));