import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Table} from 'react-bootstrap';
import { connect } from 'react-redux';
import { setSopId, setTemplateId } from '../../actions/sop';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../App.css';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class SopClientList extends Component {
    constructor(props){
        super(props);

        this.state = {
            sops: [],
            name: "",
            offset: 0,
            listSize: 10,
            relatedTo: '',
            relatedTos: [],
            process: '',
            processes: []
        };

        this.changeValue = this.changeValue.bind(this);
        this.searchSops = this.searchSops.bind(this);
    }

    async componentDidMount(){
      this.props.setSopId('');
      this.props.setTemplateId('');
      var values = await axios.get('/api/soplistvalues');
      this.setState({
        relatedTos: values.data.relatedTos,
        processes: values.data.processes
      }, () => {
        const crumbs = [
          { path: 'active', label: this.props.t('activities'), screen: this.props.auth.screenDefs.SopClientList}
      ]
      this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
      });
      this.loadSopsFromServer();
    }

    loadSopsFromServer(){
      var url = '/api/sops';
      const data = {
        size: this.state.listSize,
        offset: this.state.offset,
        name: this.state.name,
        relatedTo: this.state.relatedTo,
        process: this.state.process
      };
      axios.get(url, {params: data})
        .then(result => {
          this.setState({
            sops: result.data.data,
            pageCount: Math.ceil(result.data.totalCount / this.state.listSize)
          });

        })
        .catch(err => {
          this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        });
    }

    handlePageClick = data => {
      let selected = data.selected;
      let offset = Math.ceil(selected * this.state.listSize);

      this.setState({ offset: offset }, () => {
        this.loadSopsFromServer();
      });
    };

    changeValue(e){
      this.setState({[e.target.name]: e.target.value});
    }

    changeSelect = (name, data) => value => {
      this.setState({[name]: value, [data]: value.value});
    }

    searchSops(){
      this.loadSopsFromServer();
    }

    render(){
        const t = this.props.t;
        return(
            <div className='container'>
                <BreadcrumbBar />
                <form>
                <div className="form-group row">
                  <label>{t('name')}</label>
                  <div className="col-sm-2">
                    <input type="text" name="name" value={this.state.name} className="form-control" onChange={this.changeValue}/>
                  </div>
                  <label>{t('process')}</label>
                  <div className="col-sm-3">
                    
                  </div>
                  <label>{t('relatedTo')}</label>
                  <div className="col-sm-3">
                    
                  </div>
                  <button type="button" className="btn" onClick={() => this.searchSops()}><FontAwesomeIcon icon="search"/></button>
                </div>
                </form>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>{t('name')}&nbsp;<FontAwesomeIcon icon="caret-down"/></th>
                      <th>{t('process')}</th>
                      <th>{t('relatedTo')}&nbsp;<FontAwesomeIcon icon="caret-down"/></th>
                      <th>{t('updatedBy')}</th>
                    </tr>
                  </thead>
                    <tbody>
                        {this.state.sops.map((sop, i) =>
                          <tr key={sop._id}>
                          <td><Link to={'/instances/' + sop._id}>{sop.name}</Link></td>
                          <td>{sop.process.label}</td>
                          <td>{sop.relatedTo != null ? sop.relatedTo.label : t('location')}</td>
                          <td>{sop.updatedBy != null ? sop.updatedBy.firstName + ' ' + sop.updatedBy.lastName : ""}</td>
                        </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        );
    }
}

const mapStateToProps = state => ({
  auth: state.auth,
  sop: state.sop
});

export default withTranslation() (connect(mapStateToProps, { setSopId, setTemplateId })(SopClientList));
