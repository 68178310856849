import { SCREEN_LOCK, BLUR_SCREEN } from '../actions/types';

const initialState = {
    screenLock: false
}

export default function lockReducer(state = initialState, action){
    switch(action.type){
        case SCREEN_LOCK: {
            const screenLock = action.payload.screenLock === true || action.payload.screenLock === 'true' ? true : false;
            sessionStorage.setItem('screenLock', screenLock);
            return {
                ...state,
                    screenLock: screenLock
            }
        }
        case BLUR_SCREEN: {
            const blurScreen = action.payload.blurScreen === true || action.payload.blurScreen === 'true' ? true : false;
            sessionStorage.setItem('blurScreen', blurScreen);
            return {
                ...state,
                    blurScreen: blurScreen
            }
        }
        default:
            return state;
    }
}