import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Aux from '../../hoc/Auxilary/Auxilary';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { NumericFormat } from 'react-number-format';

class ItemTotals extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            item: this.props.item != null && this.props.item !== '' ? this.props.item : null,
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            page: 0,
        }
    }

    async componentDidMount(){
        this.loadRecords();
    }

    loadRecords = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: 'item', value: this.props.item},
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let list = await axios.get('/api/itemtotals', {params: params});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        });
    }

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <TableContainer component={Paper}>
                    <Table aria-label="Transactions">
                        <TableHead>
                            <TableRow key="transHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('date')}
                                    active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('quantity')}
                                    active={this.state.orderBy === 'quantity'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('quantity')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">
                                    <TableSortLabel onClick={() => this.sortClick('count')}
                                    active={this.state.orderBy === 'count'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('count')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">
                                    <TableSortLabel onClick={() => this.sortClick('thc')}
                                    active={this.state.orderBy === 'thc'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('thc')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">
                                    <TableSortLabel onClick={() => this.sortClick('cannabis')}
                                    active={this.state.orderBy === 'cannabis'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('cannabis')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">
                                    <TableSortLabel onClick={() => this.sortClick('packages')}
                                    active={this.state.orderBy === 'packages'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('packages')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>{t('lots')}</TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('created')}
                                    active={this.state.orderBy === 'created'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('created')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>{t('tenantTotal')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                           {this.state.list.map((row, i) => 
                                <TableRow key={row._id}>
                                    <TableCell>{row.date != null ? <DateDisplay format={this.props.auth.user.dateFormat} value={row.date}/> : ''}</TableCell>
                                    <TableCell align="right">
                                        <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}
                                            suffix={row.uom != null ? row.uom : ''} decimalScale={3}/>
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumericFormat displayType={'text'} value={row.count} thousandSeparator={true}
                                            decimalScale={0}/>
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumericFormat displayType={'text'} value={row.thc} thousandSeparator={true}
                                         decimalScale={2}/>
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumericFormat displayType={'text'} value={row.cannabis} thousandSeparator={true}
                                        decimalScale={3}/>
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumericFormat displayType={'text'} value={row.packages} thousandSeparator={true}
                                            decimalScale={0}/>
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumericFormat displayType={'text'} value={row.lots} thousandSeparator={true}
                                            decimalScale={0}/>
                                    </TableCell>
                                    <TableCell>{row.created != null ? <DateDisplay format={this.props.auth.user.dateFormat} value={row.created}/> : ''}</TableCell>
                                    <TableCell>{row.tenantTotal != null ? <Link to={'/pastinventory/' + row.tenantTotal._id}>{row.tenantTotal.number}</Link> : ''} </TableCell>
                                </TableRow>
                           )} 
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(ItemTotals));