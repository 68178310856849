import React, { Component } from 'react'
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import Document from '../General/Document';
import DateSelect from '../UI/DateSelect/DateSelect';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import 'bootstrap-select/dist/css/bootstrap-select.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SectionTitle from '../UI/Typography/SectionTitle';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import DocumentCertification from '../Documents/DocumentCertification';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import AutoComplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '../UI/TextField/TextField';
import ToolTip from '@mui/material/Tooltip';

class ActivityWorkRecord extends Component {
    constructor(props){
        super(props);
        this.state = {
            ebatch: '',
            _id: '',
            productionOrder: '',
            template: '',
            number: '',
            users: [],
            userOptions: [],
            userSel: [],
            user: null,
            reviewer: null,
            signer: null,
            reviewers: [],
            signers: [],
            cert: {},
            status: '',
            statusSel: null,
            statuses: [],
            locked: false,
            completedDate: new Date(),
            ebatches: [],
            ebatchSel: null, 
            batchAction: false,
            location: '',
            locSel: null,
            locations: [],
            recordId: null,
            objectType: null,
            sop: null,
            documents: [],
            certified: false,
            showDocument: false,
            enableSave: true,
            certification: {},
            document: {},
            activeUsers: [],
            activeManagers: [],
            activeQas: []
        };
    }

    async componentDidMount() {
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.activeLocation, common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        let values = await axios.get('/api/instancevalues/' + this.props.sop);
        let locationSel = locations.find(x => x.value === this.props.locationId);
        let ebatchSel = ( this.props.batch != null && values.data.ebatches != null ) ? values.data.ebatches.find(x => x.value === this.props.batch) : null;
        let lotSel = this.props.lot != null ? values.data.lots.find(x => x.value === this.props.lot) : null;
        let sopObj = await axios.get('/api/sops/' + this.props.sop);
        var template = this.props.id == null || this.props.id === '' ? JSON.parse(sopObj.data.template) : '';
        let userSel = [];
        let users = [];
        if(this.props.users != null && this.props.users.length > 0) {
            this.props.users.forEach(row => {
                let userVal = values.data.users.find(x => x.value === row);
                if(userVal){
                    userVal.cert = false;
                    userSel.push(userVal);
                    users.push(userVal.value);
                }
        });
        let body = {
            document: sopObj.data.document._id,
            users: userSel
        };

        let certResult = await axios.post('/api/usersopcerts', body);
        let userList = certResult.data.userCerts;
        let certified = certResult.data.certified;
        let activeUsers = [];
        values.data.users.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        values.data.managers.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let activeQas = [];
        values.data.qas.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeQas.push(row);
            }
        })
        this.setState({
            _id: this.props.id,
            statuses: cacheValues.statuses,
            batchAction: this.props.batch != null && this.props.batch !== '',
            userOptions: values.data.users,
            reviewers: values.data.managers,
            approvers: values.data.qas,
            cleaningLots: values.data.cleaningSolutions,
            pesticideLots: values.data.pesticides,
            ebatch: this.props.batch,
            location: this.props.locationId,
            locSel: locationSel,
            ebatchSel: ebatchSel,
            ebatches: values.data.ebatches,
            template: template,
            objectType: this.props.objectType !== '' ? this.props.objectType : null,
            recordId: this.props.recordId !== '' ? this.props.recordId : null,
            sop: sopObj.data,
            lot: this.props.lot,
            lotSelected: lotSel,
            lots: values.data.lots,
            users: users,
            userSel: userList,
            productionOrders: values.data.productionOrders,
            productionOrder: this.props.productionOrder != null & this.props.productionOrder !== '' ? this.props.productionOrder : '',
            certified: certified,
            locations: locations,
            completedDate: this.props.date != null ? this.props.date : new Date(),
            document: sopObj.data.document,
            activeUsers: activeUsers,
            activeManagers: activeManagers,
            activeQas: activeQas
        }, () => {
            if(this.props.id != null && this.props.id !== undefined && this.props.id !== ''){
                this.loadRecord();
            }
        });
    }}

    async componentDidUpdate(prevProps, prevState){
        if (prevProps.renderNum !== this.props.renderNum && this.props.id != null && this.props.id !== '') {
            this.loadRecord();
        }
    }

    loadRecord = async (e) => {
        let url = '/api/instances/' + this.state._id;
        let result = await axios.get(url);
        let userids = [];
        let users = [];
        if(result.data.users != null && result.data.users.length > 0) {
            result.data.users.forEach(row => {
                users.push(row._id);
                userids.push({ 
                    value: row._id, 
                    label: row.firstName + ' ' + row.lastName,
                    cert: false
                });
            })
        }        
        let body = {
            document: result.data.sop.document._id,
            users: userids
        };
        let certResult = await axios.post('/api/usersopcerts', body);
        let userSel = certResult.data.userCerts;
        let certified = certResult.data.certified;
        let template = JSON.parse(result.data.formValues);
        

        let workOrders = [];
        let status = this.getStatusValues(result.data.status, this.state.statuses);
        let locationSelected = this.state.locations.find(x => x.value === result.data.location);
        let ebatchSelected = null;
        let lotSelected = null;
        let poSelected = null;
        let cleaningLotSelected  = null;
        let pesticideLotSelected = null;
        let roomSelected = null;
        if(result.data.ebatch != null && result.data.ebatch !== '' && this.state.ebatches != null){
            ebatchSelected = this.state.ebatches.find(x => x.value === result.data.ebatch);
        }
        if(result.data.room != null && result.data.room !== '' && this.state.rooms != null){
            roomSelected = this.state.rooms.find(x => x.value === result.data.room);
        }
        if(result.data.lot != null && result.data.lot !== '' && this.state.lots != null){
            lotSelected = this.state.lots.find(x => x.value === result.data.lot);
        }
        if(result.data.productionOrder != null && result.data.productionOrder !== '' && this.state.productionOrders != null){
            poSelected = this.state.productionOrders.find(x => x.value === result.data.productionOrder);
        }
        if(result.data.cleaningLot != null && result.data.cleaningLot !== '' && this.state.cleaningSolutions != null){
            cleaningLotSelected = this.state.cleaningSolutions.find(x => x.value === result.data.cleaningLot);
        }
        if(result.data.pesticideLot != null && result.data.pesticideLot !== '' && this.state.pesticides != null){
            pesticideLotSelected = this.state.pesticides.find(x => x.value === result.data.pesticideLot);
        }
        if(result.data.schedule != null && result.data.schedule.riskAssessmentRequired){
            await this.getTaskRiskAssessments(result.data.sop._id, result.data.taskRiskAssessment);
        }
        this.setState({
            _id: result.data._id,
            template: template,
            completedDate: new Date(result.data.completedDate),
            instance: result.data,
            location: result.data.location,
            room: result.data.room,
            productionOrder: result.data.productionOrder,
            ebatch: result.data.ebatch,
            lot: result.data.lot,
            workOrder: result.data.workOrder != null ? result.data.workOrder : '',
            workOrders: workOrders.data,
            users: users,
            userSel: userSel,
            sop: result.data.sop,
            reviewer: result.data.reviewer,
            signer: result.data.signer,
            currentStatus: status,
            status: result.data.status,
            valuesRetrieved: true,
            documents: result.data.documents,
            cleaningLot : result.data.cleaningLot,
            pesticideLot: result.data.pesticideLot,
            user: result.data.user,
            locationSelected: locationSelected,
            ebatchSelected: ebatchSelected,
            lotSelected: lotSelected,
            poSelected: poSelected,
            cleaningLotSelected: cleaningLotSelected,
            pesticideLotSelected: pesticideLotSelected,
            roomSelected: roomSelected,
            finalDocument: result.data.finalDocument,
            scheduleBase: result.data.schedule,
            taskRisk: result.data.schedule != null ? result.data.schedule.riskAssessmentRequired : false,
            taskRiskAssessment: result.data.taskRiskAssessment,
            number: result.data.number,
            certified: certified,
            document: result.data.sop.document
        });
    };

    checkUserCerts = async () => {
        let body = {
            document: this.state.sop.document._id,
            users: this.state.userSel
        };
        let certResult = await axios.post('/api/usersopcerts', body);
        this.setState({ certified: certResult.data.certified, userSel: certResult.data.userCerts });
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeMulti = (name, data) => (e, newValue) => {
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    usersChanged = async (e, newValue) => {
        let userids = [];
        let userSel = [];
        let certified = this.state.certified;
        if(newValue){
            newValue.forEach(row => {
                userids.push(row.value);
                userSel.push({ 
                    value: row.value, 
                    label: row.label,
                    cert: row.cert ?? false,
                });
            });
            let body = {
                sop: this.state.sop._id,
                users: userSel
            };
            let result = await axios.post('/api/usersopcerts', body);
            certified = result.data.certified;
            userSel = result.data.userCerts;
        }
        this.setState({ certified, userSel, users: userids });
    }

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    valueChanged = (e) => {
        this.setState({value: e});
    };

    getStatusValues(status, statuses){
        let val = status != null && status !== '' ? status : this.props.auth.constants.statuses.inprogress;
        let response = statuses.find(x => x.value === val);
        return response;
    }

    formSubmit = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._formSubmit());
    }

    _formSubmit = async () => {
        const t = this.props.t;
        let valid = this.validateForm();
        if(valid){
            let template = this.state.template;
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < template.length; i++){
                    let field = template[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let docIds = [];
            this.state.documents.forEach(doc => {
                docIds.push(doc._id);
            });
            let data = {
                formValues: JSON.stringify(template),
                sopId: this.state.sop._id,
                location: this.state.location,
                room: this.state.room,
                productionOrder: this.state.productionOrder,
                ebatch: this.state.ebatch,
                workOrder: this.state.workOrder,
                lot: this.state.lot,
                completedDate: this.state.completedDate,
                entityInstance: this.state.entityInstance,
                users: this.state.userSel,
                documents: docIds,
                cleaningLot: this.state.cleaningLot,
                pesticideLot: this.state.pesticideLot,
                schedule: this.state.schedule,
                taskRiskAssessment: this.state.taskRiskAssessment,
                recordId: this.state.recordId,
                objectType: this.state.objectType
            };
            let url = '';
            if(this.state._id != null && this.state._id !== ''){
                url = '/api/instances/' + this.state._id;
            }else{
                url = "/api/instances/add";
            }
            try {
                let response = await axios.post(url, data);
                if(this.props.complete != null)
                    this.props.complete();
                this.setState({_id: response.data.id, number: response.data.number, enableSave: true}, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: response.data.message, title: t('success'), severity: 'success'}});
                });                
            } catch(err){
                this.setState({ enableSave: true }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
                });                
            }
        }else {
            this.setState({ enableSave: true }, () => {return});
        }
    }

    validateForm(){
        const t = this.props.t;
        let errors = {};
        if(this.state.completedDate == null || this.state.completedDate === "" )
            errors[t('completedDate')] = t('required');
        else {
            let completedDate = Date.parse(this.state.completedDate);
            if(completedDate == null)
                errors[t('completedDate')] = t('required');
        }
        if(this.state.location == null || this.state.location === '')
            errors[t('location')] = t('required');
        if(this.state.userSel == null || this.state.userSel === '' || this.state.userSel.length === 0)
            errors[t('users')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }

    }

    certifyClick = (e) => {
        if(this.state.users != null && this.state.users.length > 0){ 
            if(this.state.users.length > 1){
                this.setState({showCertify: true})
            } else {
                this.processCertify(this.state.users[0]);
            }
        }
    };

    submitCertify = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.certifyUser == null || this.state.certifyUser === '')
            errors[t('certifyUser')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return;
        }
        this.processCertify(this.state.certifyUser);
    };

    processCertify(user){
        let sopId = this.props.sop;
        let link = '/cert/' + sopId + '?user=' + user;
        this.props.history.push(link);
    }

    closeCertify = (e) => {
        this.setState({showCertify: false});
    };

    closeDocument = (e) => {
        let certification = [];
        this.setState({ certification, showDocument: false})
    }

    clickUser = (user) => {
        this.setState({ certification: user, showDocument: true})
    }

    render(){
        let locked = this.state.status === this.props.auth.constants.statuses.complete || 
            this.state.status === this.props.auth.constants.statuses.reviewed ||
            this.state.status === this.props.auth.constants.statuses.signed;
        const errors = this.props.errors;
        let signed = this.state.status === this.props.auth.constants.statuses.complete || 
        this.state.status === this.props.auth.constants.statuses.reviewed ||
        this.state.status === this.props.auth.constants.statuses.signed;
        const certified = this.state.certified;
        const t = this.props.t;
        if(!certified || this.state.users == null || this.state.users.length === 0)
            locked = true;

            return(
            <Aux>
                <Grid container spacing={3}>
                    {this.state._id != null && this.state._id !== '' &&
                        <Grid item xs={4} sm={2}>
                            <FormControl>
                                <FormLabel>{t('number')}</FormLabel>
                                {this.state.number}
                            </FormControl>
                        </Grid>
                    }
                    <Grid item xs={4} sm={2}>
                        {!locked &&
                            <DateSelect 
                                onChange={this.dateChange('completedDate')}
                                value={this.state.completedDate}
                                helperText={errors[t('completedDate')]}
                                error={errors[t('completedDate')] != null ? true : false}
                                disabled={locked}
                                label={t('completed')}
                                format={this.props.auth.user.dateFormat}
                                required
                            />
                        }
                        {locked &&
                            <FormControl>
                                <FormLabel>{t('completed')}</FormLabel>
                                <DateDisplay value={this.state.completedDate} format={this.props.auth.user.dateFormat}/>
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        {!locked &&
                            <AutoCompleteField
                                value={this.state.locSel}
                                options={this.state.locations}
                                onChange={this.changeAuto('locSel', 'location')}
                                error={errors[t('location')] != null ? true : false}
                                helperText={errors[t('location')]} 
                                label={t('location')}
                                disabled={this.state.batchAction || locked}
                                required
                            />
                        }
                        {locked &&
                            <FormControl>
                                <FormLabel>{t('location')}</FormLabel>
                                {this.state.locSel != null ? this.state.locSel.label : ''}
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={10}>
                        <AutoComplete
                            multiple
                            disableOpenOnFocus
                            freeSolo
                            value={this.state.userSel}
                            options={this.state.userOptions}
                            getOptionLabel={(option) => option.label}
                            onChange={this.usersChanged}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <ToolTip key={'tip' + index} title={option.cert ? t('review') : t('sign')} arrow placement='top' id={'userCert-ToolTip-' + index}>
                                        <Chip 
                                            {...getTagProps({ index })}
                                            color={option.cert ? 'primary' : 'secondary'}
                                            onClick={() => this.clickUser(option)}
                                            label={option.label}
                                            variant={option.cert ? 'default' : 'outlined'}
                                        />
                                    </ToolTip>
                                    
                                ))
                            }
                            renderInput={(params) => <TextField {...params} helperText={errors[t('users')]}
                                error={errors[t('users')] != null ? true : false} label={t('users')}
                                required
                            />}
                            disabled={signed}
                            fullWidth
                            required
                            id="users-certified-field"
                            slotProps={{
                                popper: {
                                    sx: {
                                        zIndex: 1000
                                    }
                                }
                            }}
                        />
                        {this.state.showDocument &&
                            <DocumentCertification
                                certification={this.state.certification != null ? this.state.certification.cert : false}
                                user={this.state.certification != null ? this.state.certification.value : ''}
                                dialogOpen={this.state.showDocument}
                                document={this.state.document}
                                toggleDialog={this.closeDocument}
                                refresh={this.checkUserCerts}
                            />
                        }                        
                    </Grid>
                </Grid>
                <br/>
                <Form renderer={renderer}
                    defaultFields={this.state.template}
                    onChange={this.valueChanged}
                    disabled={locked}>
                    <br/>
                    {this.state._id != null && this.state._id !== '' &&
                        <Document record={this.state._id} documents={this.state.documents}
                        objectType={this.props.auth.constants.objectIds.sopInstance} callback={this.loadRecord}/>
                    }
                    <Button variant="contained" color="primary" size="small" disabled={locked} onClick={this.formSubmit}>{t('save')}</Button>
                </Form>
                {this.state._id != null && this.state._id !== '' &&
                    <div>
                        <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.sopInstance} tab={this.props.tab}/>
                        <ObjectLog id={this.state._id}/>
                    </div>
                }
                <Dialog open={this.state.showCertify || false} onClose={this.closeCertify} center maxWidth="sm" fullWidth>
                    <DialogTitle>
                        <SectionTitle title={t('users')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={10} sm={8}>
                                <AutoCompleteField
                                    value={this.state.certifyUserSel}
                                    options={this.state.userSel}
                                    onChange={this.changeAuto('certifyUserSel', 'certifyUser')}
                                    error={errors[t('certifyUser')] != null ? true : false}
                                    helperText={errors[t('certifyUser')]}
                                    label={t('user')}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color='primary' size="small" onClick={this.submitCertify}>{t('certify')}</Button>
                        <Button variant="contained" color="secondary" aize="small" onClick={this.closeCertify}>{t('cancel')}</Button>
                    </DialogActions>
                </Dialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
  });

export default withTranslation() (connect(mapStateToProps)(withRouter(ActivityWorkRecord)));