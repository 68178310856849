import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Title from '../UI/Typography/Title';
import Aux from '../../hoc/Auxilary/Auxilary';
import Typography from '@mui/material/Typography';
import SectionTitle from '../UI/Typography/SectionTitle';
import TextField from '../UI/TextField/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ClearButton, DeleteButton, SaveButton, TextOnlyClickButton } from '../UI/Buttons/Buttons';
import ActionBar from '../UI/Buttons/ActionBar';

class RoleList extends Component {
  constructor(){
    super();
    
    this.state = {
        roles: [],
        selectedRole: "",
        roleName: "",
        edit: false,
        security: [],
        enableSave: true
    }

    this.saveRole = this.saveRole.bind(this);
    this.deleteRole = this.deleteRole.bind(this);
    this.roleChanged = this.roleChanged.bind(this);
    this.selectRole = this.selectRole.bind(this);
    this.loadRoles = this.loadRoles.bind(this);
    this.clearClick = this.clearClick.bind(this);
    this.newClick = this.newClick.bind(this);
  }

  componentDidMount(){
      this.loadRoles();
  }

  selectRole(roleId){
    this.setState({selectedRole: roleId});
    for(let i = 0; i < this.state.roles.length; i++){
      if(this.state.roles[i]._id === roleId){
        this.setState({
          roleName: this.state.roles[i].name,
          selectedRole: roleId,
          edit: true
        });
        break;
      }
    }
  }

  loadRoles(){
    axios.get('/api/roles')
    .then(result => {
        this.setState({ 
          roles: result.data,
          selectedRole: "",
          roleName: "",
          edit: false,
          security: this.props.permission
        });
    });
  }

  roleChanged(value){
    this.setState({roleName: value.target.value});
  }

  saveRole(e){
    if(!this.state.enableSave)
        return;
    this.toggleSave();
    if(this.state.roleName == null || this.state.roleName === ""){
      this.toggleSave();
      return;
    }
    
    const data = {
        _id: this.state.selectedRole,
        name: this.state.roleName
    };

    axios.post('api/role', data)
    .then(response => {
      this.setState({
        selectedRole: '',
        roleName: '',
        edit: false
      });
      this.loadRoles();
      this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: response.data.message, title: 'Success', severity: 'success'}});
    }).catch(function(error){
      this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: error, title: 'Error', severity: 'error'}});
    });
    this.toggleSave();
  }

  deleteRole(e){
    if(this.state.selectedRole == null || this.state.selectedRole === ''){
      return;
    }
    const url = '/api/role/' + this.state.selectedRole;
        axios.delete(url)
    .then(response => {
      this.loadRoles();
      this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: response.data.message, title: 'Success', severity: 'success'}});
    })
    .catch(function(error){
      this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: error, title: 'Error', severity: 'error'}});
    })
  }

  clearClick(e){
    this.setState({
      selectedRole: '',
      roleName: '',
      edit: false
    });
  }

  newClick(e){
    this.setState({edit: true});
  }

  render(){
    const security = this.state.security;
    return(
      <Aux>
        <ActionBar
            createClick={this.newClick}
            createAccess={security.create}
            helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
            helpUser={this.props.auth.user.helpUser}
            helpPassword={this.props.auth.user.helpPassword}
        />
        <Title title="Role List" />
        <TableContainer component={Paper}>
          <Table aria-label="Role List">
            <TableHead>
              <TableRow key="tableHeader">
                <TableCell>Name</TableCell>
                <TableCell>Display Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.roles.map((role, i) =>
                <TableRow key={role._id}>
                  <TableCell>
                    <TextOnlyClickButton enabled={true} click={() => this.selectRole(role._id)} name={role.name}/>
                  </TableCell>
                  <TableCell>
                      {role.displayName}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={this.state.edit} onClose={this.clearClick} center maxWidth="md" fullWidth>
          <DialogTitle>
              <SectionTitle title="Role"/>
          </DialogTitle>
          <DialogContent>
              <Typography>(Careful deleting roles, most are required)</Typography>
              <Grid container spacing={2}>
                  <Grid item xs={8} sm={4}>
                      <TextField value={this.state.roleName} onChange={this.roleChanged} name="roleName"
                      size="medium" fullWidth={true} label="Name" required/>
                  </Grid>
              </Grid>
          </DialogContent>
          <DialogActions>
              <SaveButton saveClick={this.saveRole} enabled={this.state.enableSave && (security.complete || security.update)} />
              <ClearButton clearClick={this.clearClick} enabled={security.complete || security.update} />
              <DeleteButton deleteClick={this.deleteRole} enabled={security.complete || security.updateore} />
          </DialogActions>
        </Dialog>
      </Aux>
    );
  }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(RoleList);