import React, { Component } from 'react';
import axios from 'axios';
import GeneratePdf from '../General/GeneratePdf';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';

class ComponentToPrint extends Component {
    render(){
        const t = this.props.t;
        return(
            <div>
                <h2>{t('incident')}: {this.props.data.number}</h2>
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('status')}</th>
                            <th width="200">{t('location')}</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.status.label}</td>
                            <td>{this.props.data.location.name}</td>
                            <td>{this.props.data.type.label}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('date')}</th>
                            <th width="200">{t('employee')}</th>
                            <th>Age</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.date}/></td>
                            <td>{this.props.data.employee.firstName + ' ' + this.props.data.employee.lastName}</td>
                            <td>{this.props.data.age}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('gender')}</th>
                            <th width="200">{t('lengthOfService')}</th>
                            <th>{t('lengthInPosition')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.gender.label}</td>
                            <td>{this.props.data.lengthOfService}</td>
                            <td>{this.props.data.lengthInCurrentPosition}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('jobTitle')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.occupation}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                {this.props.data.correctiveMeasures.length > 0 &&
                    <div>
                        <h3>{t('correctiveMeasures')}</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th width="300">{t('description')}</th>
                                    <th>{t('employee')}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.props.data.correctiveMeasures.map((row) =>
                                <tr>
                                    <td>{row.description}</td>
                                    <td>{row.user.firstName + ' ' + row.user.lastName}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <br/>
                    </div>
                }
                <h3>{t('signatures')}</h3>
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('reviewer')}</th>
                            <th>{t('signoff')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.reviewer != null ? this.props.data.reviewer.user.firstName + ' ' +
                            this.props.data.reviewer.user.lastName : ''}</td>
                            <td>{this.props.data.signer != null ? this.props.data.signer.user.firstName + ' ' +
                            this.props.data.signer.user.lastName : ''}</td>
                        </tr>
                        <tr>
                            <td>{this.props.data.reviewer != null && <DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.reviewer.date}/>}</td>
                            <td>{this.props.data.signer != null && <DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.signer.date}/>}</td>
                        </tr>
                        <tr>
                            <td>{this.props.data.reviewer != null && <img src={this.props.data.reviewer.signature} alt={t('noSignature')}/>}</td>
                            <td>{this.props.data.signer != null && <img src={this.props.data.signer.signature} alt={t('noSignature')}/>}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
            </div>
        )
    }
}

class IncidentPrint extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            employee: {},
            location: {},
            type: {},
            date: '',
            age: '',
            gender: {},
            lengthOfService: '',
            lengthInCurrentPosition: '',
            occupation: '',
            nature: '',
            employeeDescription: '',
            basicCause: '',
            correctiveMeasures: [],
            outsideRecommendation: '',
            inspectionDepartment: '',
            description: '',
            status: '',
            employeeSignature: null,
            reviewer: null,
            signer: null,
            number: ''
        };
    }

    async componentDidMount(){
        var record = await axios.get('/api/majorprint/' + this.props.match.params.id);
        this.setState({
            _id: record.data._id,
            employee: record.data.employee,
            location: record.data.location,
            type: record.data.type,
            date: record.data.date,
            age: record.data.age,
            gender: record.data.gender,
            lengthOfService: record.data.lengthOfService,
            lengthInCurrentPosition: record.data.lengthInCurrentPosition,
            occupation: record.data.occupation,
            nature: record.data.nature,
            employeeDescription: record.data.employeeDescription,
            basicCause: record.data.basicCause,
            correctiveMeasures: record.data.correctiveMeasures,
            outsideRecommendation: record.data.outsideRecommendation,
            inspectionDepartment: record.data.inspectionDepartment,
            description: record.data.description,
            status: record.data.status,
            employeeSignature: record.data.employeeSignature,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            number: record.data.number
        });
    }

    returnClick () {
        this.props.history.goBack();
    }

    generateMarkup(){
        var htmlData = ReactDOMServer.renderToStaticMarkup(<ComponentToPrint data={this.state} auth={this.props.auth}/>);
        return htmlData;
    }

    render(){
        const t = this.props.t;
        return(
            <div className="container">
                <ComponentToPrint data={this.state} auth={this.props.auth} t={t}/>
                <div className="row">
                <Button variant="contained" color="secondary" size="small" onClick={() => this.returnClick()}>{t('return')}</Button>
                    <GeneratePdf markup={() => this.generateMarkup()} id={this.props.match.params.id} objectType={this.props.auth.constants.objectIds.incident}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(IncidentPrint));