import React  from 'react';
import Aux from '../../../hoc/Auxilary/Auxilary';
import ConversionHelper from '../../../helpers/conversionHelper';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ActivityRecord from './ActivityRecord';
import ReportTitle from '../../UI/Typography/ReportTitle';
import ReportSectionTitle from '../../UI/Typography/ReportSectionTitle';

export default function ProductionOrderActivityTemplate(props){
    // Also available: printView
    const { dateFormat, order, record, sequence, t } = props;

    return(
        <div style={{ width: '8.5in', 
                      pageBreakBefore: 'always', 
                      display: record == null ? 'none' : 'block' }}>
            <Aux>
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                        <ReportTitle 
                            title={
                                (sequence != null && sequence !== '' ? 
                                    sequence + '. ' + t('orderActivity') + ': ' : t('orderActivity') + ': ') + 
                                (record?.number != null && record?.number !== '' ? 
                                    record.number : ''
                                )
                            } 
                            description={record?.description != null && record?.description !== '' ? record.description : null}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ReportSectionTitle title={t('activityDetails')} />
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('order')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('status')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('startTime')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('endTime')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('effortM')}</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {order != null && order !== '' ? order :
                                            record?.productionOrder?.productionBatch?.number != null && record?.productionOrder?.productionBatch?.number !== '' ?
                                                record.productionOrder.productionBatch.number : t('na')
                                        }
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {record?.status?.label != null && record?.status?.label !== '' ?
                                            record.status.label : t('na')
                                        }
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {record?.startTime != null && record?.startTime !== '' ?
                                            <DateDisplay value={record.startTime} format={dateFormat + ' hh:mm a'}/> : t('na')
                                        }
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {record?.completeTime != null && record?.completeTime !== '' ?
                                            <DateDisplay value={record.completeTime} format={dateFormat + ' hh:mm a'}/> : t('na')
                                        }
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {record?.effort != null && record?.effort !== '' ?
                                            record.effort : t('na')
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12}>
                        <ReportSectionTitle title={t('performedBy')} />
                        {record?.users != null && record?.users !== '' && record?.users.length > 0 ?
                            <div style={{ fontSize: '9pt', padding: '5px' }}>
                                {record.users.map((user, i) => {
                                    if(i === record.users.length - 1)
                                        return user.name
                                    else
                                        return user.name + ', '
                                    })
                                }
                            </div> : ''                             
                        }
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    {record?.form != null &&
                        <Grid item xs={12}>
                            <Form renderer={renderer}
                                    defaultFields={record.form}
                                    disabled={true}
                                    print={true}
                            />
                        </Grid>
                    }
                    {record?.notes != null && record.notes !== '' &&
                        <Grid item xs={12}>
                            <ReportSectionTitle title={t('notes')} />
                            <div style={{ fontSize: '9pt', padding: '5px' }}>
                                {record.notes}
                            </div>
                        </Grid>
                    }                        
                    <Grid item xs={12}>
                        <Divider sx={{ m: '0 auto', width: '80%' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <ReportSectionTitle title={t('equipment')} />
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', textAlign: 'center', width: '350px' }}>{t('type')}</th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', textAlign: 'center', width: '350px' }}>{t('id')}</th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', textAlign: 'center', width: '350px' }}>{t('name')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {record?.equipments != null && record?.equipments.length > 0 ?
                                    record.equipments.map((equipment, i) => 
                                        equipment.equipment != null && equipment.equipment !== '' && equipment.equipment.length > 0 &&
                                            equipment.equipment.map((eq, j) => {
                                                return (
                                                    <tr key={'equipment-' + i}>
                                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                                            {equipment.machineType != null && equipment.machineType !== '' ? equipment.machineType : t('na')}
                                                        </td>
                                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                                            {eq.identityNo != null && eq.identityNo !== '' ? 
                                                                eq.identityNo : t('na')
                                                            }
                                                        </td>
                                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                                            {eq.name != null && eq.name !== '' ?
                                                                eq.name : t('na')
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        )
                                    ) : 
                                    <tr key='noEquipment'>
                                        <td colSpan={3} style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center'}}>{t('none')}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ m: '0 auto', width: '80%' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <ReportSectionTitle title={t('outputs')} />
                        <table style={{ borderCollapse: 'collapse' }} >
                            <thead>
                                <tr>
                                    <td style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('type')}</strong></td>
                                    <td style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '150px', textAlign: 'center' }}><strong>{t('item')}</strong></td>
                                    <td style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '125px', textAlign: 'center' }}><strong>{t('lot')}</strong></td>
                                    <td style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '125px', textAlign: 'center' }}><strong>{t('container')}</strong></td>
                                    <td style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '80px', textAlign: 'center' }}><strong>{t('quantity')}</strong></td>
                                    <td style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '80px', textAlign: 'center' }}><strong>{t('count')}</strong></td>
                                    <td style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '80px', textAlign: 'center' }}><strong>{t('discreteWeightG')}</strong></td>
                                    <td style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '80px', textAlign: 'center' }}><strong>{t('totalWeightG')}</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                {record?.containers != null && record?.containers.length > 0 ?
                                    record.containers.map((row, i) => 
                                    <tr key={'containerRow-' + i}>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{row.type != null && row.type !== '' ? row.type : t('na')}</td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{row.item != null && row.item !== '' ? row.item : t('na')}</td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{row.lot != null && row.lot !== '' ? row.lot : t('na')}</td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{row.container != null && row.container !== '' ? row.container : t('na')}</td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>{row.quantity != null && row.quantity !== '' && row.uom != null && row.uom !== '' ? row.quantity + row.uom : t('na')}</td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>{row.count != null && row.count !== '' ? row.count : t('na')}</td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>{row.discreteWeight != null && row.discreteWeight !== '' ? row.discreteWeight : t('na')}</td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>{row.totalWeight != null && row.totalWeight !== '' ? row.totalWeight : t('na')}</td>
                                    </tr>
                                ) :
                                    <tr>
                                        <td colSpan={8} style={{ textAlign: 'center', border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px' }}>{t('none')}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>      
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ m: '0 auto', width: '80%' }} />
                    </Grid>
                    {record?.sops != null && record?.sops.length > 0 &&
                        record.sops.map((sop, i) => 
                            <Grid item xs={12} key={'workRecord-' + i}>
                                <ActivityRecord dateFormat={dateFormat} record={sop.workRecord} sop={sop.sop} t={t} 
                                sequence={sequence != null && sequence !== '' ? 
                                    sequence + '.' + ConversionHelper.numberToRomanConverter(i + 1) : ConversionHelper.numberToLetterConverter(i + 1)
                                } />
                            </Grid>    
                        )
                    }
                </Grid>
            </Aux>
        </div>
    )
}