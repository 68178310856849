import React, { useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import { ModalToaster } from '../Toaster/Toaster';
import DownloadButton from '../../Printing/Buttons/Download';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props}/>
    </Draggable>
  );
}

export const DialogTitle = ((props) => {
  const { children, classes, downloadRecord, onClose, sx, ...other } = props;
  return (
    <MuiDialogTitle {...other} sx={{ display: 'flex', justifyContent: 'space-between', ...sx }}>
      <Typography sx={{ color: '#00AC89',
                        fontFamily: 'Ubuntu',
                        fontSize: '1em',
                        fontWeight: 'semi-bold',
                        textTransform: 'uppercase' }}>{children}</Typography>
      <div>
        {downloadRecord != null ? (
          <DownloadButton
            type='icon'
            record={downloadRecord}
          />
        ) : null}
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} 
            sx={{
              color: 'grey.500',
            }}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>      
    </MuiDialogTitle>
  );
});

export const DialogButtons = (props) => {
    const { buttonStack } = props;
    return (
      <DialogActions sx={{ m: 0, p: 1 }}>
        {buttonStack}
      </DialogActions>
    );
};

export default function ModalDialog(props) {
  const { buttonStack, children, title, toggleDialog, dialogStatus, fullWidth, 
          maxWidth = 'sm', onScroll, scrollExists, fScreen = 'sm', notification } = props;
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down(fScreen));
  const contentElementRef = useRef(null);
  useEffect(() => {
    if (dialogStatus) {
      const descriptionElement = contentElementRef.current;
      if (descriptionElement !== null && scrollExists != null) {
        scrollExists(descriptionElement.scrollHeight > descriptionElement.clientHeight);
      }
    }
  }, [dialogStatus, scrollExists]);

  return (
     <Dialog
      open={dialogStatus}  
      onClose={toggleDialog} 
      aria-labelledby="draggable-dialog-title" 
      PaperComponent={PaperComponent}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      scroll='paper'
      >
      <DialogTitle id="draggable-dialog-title" sx={{ m: 0, p: 1, cursor: 'move', backgroundColor: '#e5e3e3' }} onClose={toggleDialog} >
        {title}
      </DialogTitle>
      <DialogContent dividers onScroll={onScroll} ref={contentElementRef} sx={{ p: 2 }}>
        {children}
        <ModalToaster alert={notification} />
      </DialogContent>
      {buttonStack != null && 
        <DialogButtons buttonStack={buttonStack} /> 
      }
     </Dialog>
  );
}