import React from 'react';
import Confirmation from '../UI/Dialog/Confirmation';
import { useTranslation } from 'react-i18next';

export default function ConfirmDelete(props) {
    const { t } = useTranslation();
     const {redirect, open, toggleDialog} = props;
     const confirmationTitle = t('deleteRecord')
     const content = t('deleteConfirm');
     const yesButton = t('okay');
     const noButton = t('noThanks');
     return (
          <Confirmation content={content} title={confirmationTitle} open={open}
                        no={toggleDialog} noButton={noButton}
                        yes={redirect} yesButton={yesButton} />
     );         
} 