import React, { Component } from 'react';
import axios from 'axios';
import Link from '../UI/Link/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { connect } from 'react-redux';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import TextField from '../UI/TextField/TextField';
import TablePagination from '@mui/material/TablePagination';
import { withTranslation } from 'react-i18next';
import DocumentCertification from '../Documents/DocumentCertification';
import common from '../../jsons/common.json';

class OutstandingCertifications extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            orderBy: 'number',
            ordDir: 1,
            departmentOptions: [],
            department: '',
            departmentSel: null,
            name: '',
            listSize: 5,
            page: 0,
            document: {},
            showCertification: false
        }
    }

    async componentDidMount(){
        const values =  await axios.get('/api/uservalues');
        this.setState({
            departmentOptions: values.data.departments
        }, () => {
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        var sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            name: this.state.name,
            department: this.state.department,
            sort: JSON.stringify(sort)
        };
        let list = await axios.get('/api/certsoutstanding/' + this.props.auth.user.id, {params: data});
        this.setState({
            list: list.data
        });
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords()});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({listSize: event.target.value});
        this.setState({page:0});
    };

    clickDocument = (doc) => {
        this.setState({ document: doc, showCertification: true });
    }

    closeDialog = () => {
        this.setState({ document: {}, showCertification: false });
    }

    render(){
        const t = this.props.t;

        return(
            <Aux>
                {this.props.title != null &&
                    <SectionTitle title={this.props.title}/>
                }
                {this.state.list != null && this.state.list.length > 0 &&
                    <Aux>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={4}>
                                <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                size="medium" fullWidth={true} label={t('name')}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.departmentSel}
                                    options={this.state.departmentOptions}
                                    onChange={this.changeAuto('departmentSel', 'department')}
                                    label={t('department')}
                                />
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table aria-label="outstandingcerts" size="small">
                                <TableHead>
                                    <TableRow key="outstandingCertHeader">
                                        <TableCell width="75%">{t('document')}</TableCell>
                                        <TableCell width="25%">{t('department')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.list.slice(this.state.page * this.state.listSize, this.state.page * this.state.listSize + this.state.listSize).map((row, i) =>
                                        <TableRow key={row._id}>
                                            <TableCell>
                                                <Link onClick={() => this.clickDocument(row)}>{row.name + ' ' + row.version}</Link>
                                            </TableCell>
                                            <TableCell>{row.departmentString}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                {this.state.list.length > 0 &&
                                    <TableFooter>
                                        <TableRow key="tableFooter">
                                            <TablePagination
                                                rowsPerPageOptions={common.rowOptions}
                                                count={this.state.list.length}
                                                rowsPerPage={this.state.listSize}
                                                page={this.state.page}
                                                onPageChange={this.handleChangePage}
                                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                }                           
                            </Table>
                        </TableContainer>
                        {this.state.showCertification &&
                            <DocumentCertification
                                certification={false}
                                user={this.props.auth.user.id}
                                dialogOpen={this.state.showCertification}
                                document={this.state.document}
                                toggleDialog={this.closeDialog}
                                refresh={this.loadRecords}
                            />
                        }
                    </Aux>
                }
                {(this.state.list == null || this.state.list.length === 0) &&
                    <p>Not outstanding certifications</p>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(OutstandingCertifications));
