import React from 'react';
import { NumericFormat } from 'react-number-format';
import ReportTitle from '../../UI/Typography/ReportTitle';
import ReportSectionTitle from '../../UI/Typography/ReportSectionTitle';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';

import CultivationActivity from './CultivationActivity';
import TrimmingOrder from './TrimmingOrder';
import DryingOrder from './DryingOrder';
import CuringOrder from './CuringOrder';
import IPMTreatment from './IPMTreatment';
import RoomCleaning from './Cleaning';
import RoomFeeding from './RoomFeeding';

const boxStyle = {
    margin: '8px',
    width: '100%'
}

const thStyle = {
    borderBottom: '2px solid rgb(224, 224, 224)', 
    fontSize: '9pt', 
    textAlign: 'center'
}

const tdStyle = { 
    border: '0.5px solid rgb(224, 224, 224)', 
    fontSize: '8pt', 
    padding: '5px',
}

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse'
}

export default function CultivationBatchTemplate(props) {
    const { dateFormat, record, t } = props;
    return (
        <div style={{ width: '8.5in', pageBreakBefore: 'always' }}>
            <div style={boxStyle}>
                <ReportTitle 
                    title={record.documentParameters != null && record.documentParameters.title != null ? record.documentParameters.title : t('cultivationBatchRecord')} 
                    description={record.description != null && record.description !== '' ? record.description : null}
                />
            </div>
            <div style={{ ...boxStyle, display: 'block' }}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '100px' }}>{t('number')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('status')}</th>
                            <th style={thStyle}>{t('strain')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('startDate')}</th>
                            <th style={{ ...thStyle, width: '175px' }}>{t('location')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.number != null && record.number !== '' ? record.number : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.status != null && record.status !== '' ? record.status.label : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.strain != null && record.strain !== '' ? 
                                    record.strain.name + ' (' + record.strain.shortCode + ')' : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.actualStart != null ?
                                    <DateDisplay value={record.actualStart} format={dateFormat}/> : 
                                 record.plannedStart != null ?
                                    <DateDisplay value={record.plannedStart} format={dateFormat}/> : 
                                    t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }} >
                                {record.location != null && record.location !== '' ? record.location.name : t('na')}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('plantHistory')} />
                </div>
                <div style={{ width: '800px' }}>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={thStyle}>{t('stage')}</th>
                                <th style={thStyle}>{t('activity')}</th>
                                <th style={thStyle}>{t('date')}</th>
                                <th style={thStyle}>{t('item')}</th>
                                <th style={thStyle}>{t('adjustment')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {record.stages != null && record.stages.length > 0 &&
                                record.stages.map((stage, i) =>{
                                    return (
                                        stage.activities != null && stage.activities.length > 0 &&
                                            stage.activities.map((activity, j) => {
                                                return (
                                                    activity.ledgers != null && activity.ledgers.results != null &&
                                                    activity.ledgers.results.items != null && activity.ledgers.results.items.length > 0 &&
                                                        activity.ledgers.results.items.map((item, k) => {
                                                            return (
                                                                <tr key={'stage-' + i + '-inventory-' + j + k }>
                                                                    <td style={tdStyle}>
                                                                        {stage.stage != null && stage.stage !== '' ? stage.stage : t('na')}
                                                                    </td>
                                                                    <td style={tdStyle}>
                                                                        {activity.activity != null && activity.activity !== '' &&
                                                                         activity.activityDefinition != null && activity.activityDefinition !== '' ? 
                                                                            activity.activity.number + ' - ' + activity.activityDefinition.description : t('na')
                                                                        }
                                                                    </td>
                                                                    <td style={tdStyle}>
                                                                        {activity.actualDate != null ?
                                                                            <DateDisplay value={activity.actualDate} format={dateFormat}/> : 
                                                                        activity.actualDate != null ?
                                                                            <DateDisplay value={activity.actualDate} format={dateFormat}/> : 
                                                                            t('na')
                                                                        }
                                                                    </td>
                                                                    <td style={tdStyle}>
                                                                        {item != null && item.label != null ? item.label : t('na')}
                                                                    </td>
                                                                    <td style={{ ...tdStyle, textAlign: 'right' }}>
                                                                        {item != null && item.count != null ? item.count : t('na')}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                )
                                            })
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            <div style={{ ...boxStyle, display: 'block' }}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('activitySummary')} />
                </div>
                <div style={{ width: '800px' }}>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={{ ...thStyle, width: '45px'}}>{t('stage')}</th>
                                <th style={{ ...thStyle, width: '45px'}}>{t('plannedStart')}</th>
                                <th style={{ ...thStyle, width: '45px'}}>{t('actualStart')}</th>
                                {/* <th style={thStyle}>{t('room')}</th> */}
                                <th style={{ ...thStyle, width: '150px'}}>{t('activity')}</th>
                                <th style={{ ...thStyle, width: '125px'}}>{t('cannabisInputs')}</th>
                                <th style={{ ...thStyle, width: '125px'}}>{t('cannabisOutputs')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {record.stages != null && record.stages.length > 0 &&
                                record.stages.map((stage, i) => {
                                    return (
                                        stage.activities != null && stage.activities.length > 0 &&
                                            stage.activities.map((activity, j) => {
                                                return (
                                                    <tr key={'stage-' + i + '-activity-' + j }>
                                                        <td style={tdStyle}>
                                                            {stage.stage != null && stage.stage !== '' ? stage.stage : t('na')}
                                                        </td>
                                                        <td style={tdStyle}>
                                                            {activity.plannedDate != null ?
                                                                <DateDisplay value={activity.plannedDate} format={dateFormat}/> : 
                                                                t('na')
                                                            }
                                                        </td>
                                                        <td style={tdStyle}>
                                                            {activity.actualDate != null ?
                                                                <DateDisplay value={activity.actualDate} format={dateFormat}/> : 
                                                                t('na')
                                                            }
                                                        </td>
                                                        {/* <td style={tdStyle}>
                                                            {activity.room != null && activity.room !== '' ? activity.room.name : t('na')}
                                                        </td> */}
                                                        <td style={tdStyle}>
                                                            {activity.activity != null && activity.activity !== '' &&
                                                             activity.activityDefinition != null && activity.activityDefinition !== '' ? 
                                                                activity.activity.number + ' - ' + activity.activityDefinition.description : t('na')
                                                            }
                                                        </td>
                                                        {/* <Grid item xs={12}>
                                                            <ReportSectionTitle title={t('performedBy')} />
                                                            {record.users != null && record.users !== '' && record.users.length > 0 ?
                                                                <div style={{ fontSize: '9pt', padding: '5px' }}>
                                                                    {record.users.map((user, i) => {
                                                                        if(i === record.users.length - 1)
                                                                            return user.name
                                                                        else
                                                                            return user.name + ', '
                                                                        })
                                                                    }
                                                                </div> : ''                             
                                                            }
                                                        </Grid> */}
                                                        <td style={{ ...tdStyle, textAlign: 'right' }}>
                                                            <div style={{ width: '100%', display: 'inline-block' }}>
                                                                <div style={{ display: 'inline-block', width: '75%', textAlign: 'left', verticalAlign: 'top'}}>
                                                                    {activity.activity != null && activity.activity !== '' &&
                                                                    activity.activity.cannabisInputTotals != null && activity.activity.cannabisInputTotals.length > 0 &&
                                                                        activity.activity.cannabisInputTotals.map((cannabisInput, k) => {
                                                                            if(cannabisInput.category != null && cannabisInput.category !== '') {
                                                                                return (
                                                                                    <span style={{ display: 'block' }}>
                                                                                        {cannabisInput.item}<br />
                                                                                        {' (' + cannabisInput.category + ')'}
                                                                                    </span>
                                                                                )
                                                                            }else {
                                                                                return t('na')
                                                                            }
                                                                        })
                                                                    }
                                                                </div>
                                                                <div style={{ display: 'inline-block', width: '25%', textAlign: 'right', verticalAlign: 'top' }}>
                                                                    {activity.activity != null && activity.activity !== '' &&
                                                                    activity.activity.cannabisInputTotals != null && activity.activity.cannabisInputTotals.length > 0 &&
                                                                        activity.activity.cannabisInputTotals.map((cannabisInput, k) => {
                                                                            if(cannabisInput.category != null && cannabisInput.category !== '') {
                                                                                return (
                                                                                    <span style={{ display: 'block' }}>
                                                                                        <NumericFormat displayType={'text'} value={cannabisInput.amount} thousandSeparator={true}
                                                                                        decimalScale={3} suffix={cannabisInput.uom}/><br /><br />
                                                                                    </span>
                                                                                )
                                                                            }else {
                                                                                return t('na')
                                                                            }
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td style={{ ...tdStyle, textAlign: 'right' }}>
                                                            <div style={{ width: '100%', display: 'inline-block' }}>
                                                                <div style={{ display: 'inline-block', width: '75%', textAlign: 'left' }}>
                                                                    {activity.activity != null && activity.activity !== '' &&
                                                                    activity.activity.cannabisOutputTotals != null && activity.activity.cannabisOutputTotals.length > 0 &&
                                                                        activity.activity.cannabisOutputTotals.map((cannabisOutput, k) => {
                                                                            if(cannabisOutput.category != null && cannabisOutput.category !== '') {
                                                                                return (
                                                                                    <span style={{ display: 'block' }}>
                                                                                        {cannabisOutput.item}<br />
                                                                                        {' (' + cannabisOutput.category + ')'}
                                                                                    </span>
                                                                                )
                                                                            }else {
                                                                                return t('na')
                                                                            }
                                                                        })
                                                                    }
                                                                </div>
                                                                <div style={{ display: 'inline-block', width: '25%', textAlign: 'right', verticalAlign: 'top' }}>
                                                                    {activity.activity != null && activity.activity !== '' &&
                                                                    activity.activity.cannabisOutputTotals != null && activity.activity.cannabisOutputTotals.length > 0 &&
                                                                        activity.activity.cannabisOutputTotals.map((cannabisOutput, k) => {
                                                                            if(cannabisOutput.category != null && cannabisOutput.category !== '') {
                                                                                return (
                                                                                    <span style={{ display: 'block' }}>
                                                                                        <NumericFormat displayType={'text'} value={cannabisOutput.amount} thousandSeparator={true}
                                                                                        decimalScale={3} suffix={cannabisOutput.uom}/><br /><br />
                                                                                    </span>
                                                                                )
                                                                            }else {
                                                                                return t('na')
                                                                            }
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            <div style={{ ...boxStyle, display: 'block' }}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('inventoryTransactionSummary')} />
                </div>
                <div style={{ width: '800px', display: 'block' }}>
                    <div style={{ width: '49%', display: 'inline-block', float: 'left' }}>
                        <div style={{ width: '100%', display: 'block' }}>
                            <ReportSectionTitle title={t('inputs')} />
                        </div>
                        <table style={tableStyle}>
                            <thead>
                                <tr>
                                    <th style={thStyle}>{t('item')}</th>
                                    <th style={thStyle}>{t('category')}</th>
                                    <th style={thStyle}>{t('containers')}</th>
                                    <th style={thStyle}>{t('quantity')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {record.inputSummary != null && record.inputSummary.length > 0 ?
                                    record.inputSummary.map((input, i) => 
                                        <tr>
                                            <td style={tdStyle}>
                                                {input.item != null && input.item !== '' ? input.item : t('na')}
                                            </td>
                                            <td style={tdStyle}>
                                                {input.itemCategory != null && input.itemCategory !== '' ? input.itemCategory : t('na')}
                                            </td>
                                            <td style={{ ...tdStyle, textAlign: 'right' }}>
                                                {input.containers != null && input.containers !== '' ? input.containers : t('na')}
                                            </td>
                                            <td style={{ ...tdStyle, textAlign: 'right' }}>
                                                {input.amount != null && input.amount !== '' &&
                                                 input.uom != null && input.uom !== '' ? 
                                                    <NumericFormat displayType={'text'} value={input.amount} thousandSeparator={true}
                                                    decimalScale={3} suffix={input.uom}/> : t('na')
                                                }
                                            </td>
                                        </tr>
                                    ) :
                                    <tr>
                                        <td style={tdStyle} colSpan={4}>
                                            {t('noData')}
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div style={{ width: '49%', display: 'inline-block', float: 'right' }}>
                        <div style={{ width: '100%', display: 'block' }}>
                            <ReportSectionTitle title={t('outputs')} />
                        </div>
                        <table style={tableStyle}>
                            <thead>
                                <tr>
                                    <th style={thStyle}>{t('item')}</th>
                                    <th style={thStyle}>{t('category')}</th>
                                    <th style={thStyle}>{t('containers')}</th>
                                    <th style={thStyle}>{t('quantity')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {record.outputSummary != null && record.outputSummary.length > 0 ?
                                    record.outputSummary.map((output, i) => 
                                        <tr>
                                            <td style={tdStyle}>
                                                {output.item != null && output.item !== '' ? output.item : t('na')}
                                            </td>
                                            <td style={tdStyle}>
                                                {output.itemCategory != null && output.itemCategory !== '' ? output.itemCategory : t('na')}
                                            </td>
                                            <td style={{ ...tdStyle, textAlign: 'right' }}>
                                                {output.containers != null && output.containers !== '' ? output.containers : t('na')}
                                            </td>
                                            <td style={{ ...tdStyle, textAlign: 'right' }}>
                                                {output.amount != null && output.amount !== '' &&
                                                 output.uom != null && output.uom !== '' ? 
                                                    <NumericFormat displayType={'text'} value={output.amount} thousandSeparator={true}
                                                    decimalScale={3} suffix={output.uom}/> : t('na')
                                                }
                                            </td>
                                        </tr>
                                    ) :
                                    <tr>
                                        <td style={tdStyle} colSpan={4}>
                                            {t('noData')}
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ width: '100%', display: 'block', clear: 'both', paddingTop: '10px' }}>
                    <hr style={{ width: '80%', margin: '16px auto' }} />
                </div>
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('dry')} />
                </div>
                <div style={{ width: '800px' }}>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ ...thStyle, width: '100px' }} >{t('number')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('type')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('startAmount')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('output')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('loss')}</th>
                        </thead>
                        <tbody>
                            {record.dryings != null && record.dryings.length > 0 ?
                                record.dryings.map((row, i) => 
                                    <tr key={'dryingRecord-' + i}>
                                        <td style={tdStyle}>{row.number}</td>
                                        <td style={tdStyle}>{row.type}</td>
                                        <td style={{ ...tdStyle, textAlign: 'right' }}>
                                            {row.startAmount != null && row.startAmount !== '' ?
                                                <NumericFormat displayType={'text'} value={row.startAmount} thousandSeparator={true}
                                                decimalScale={3} suffix={row.startAmountUOM} fixedDecimalScale /> : t('na')
                                            }
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'right' }}>
                                            {row.actualOutput != null && row.actualOutput !== '' ?
                                                <NumericFormat displayType={'text'} value={row.actualOutput} thousandSeparator={true}
                                                decimalScale={3} suffix={row.startAmountUOM} fixedDecimalScale /> : t('na')
                                            }
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'right', fontWeight: 'bold' }}>
                                            {row.startAmount != null && row.startAmount !== '' &&
                                             row.actualOutput != null && row.actualOutput !== '' ?
                                                <NumericFormat 
                                                    displayType={'text'} 
                                                    value={
                                                        (row.startAmount - row.actualOutput) / row.startAmount * 100
                                                    } 
                                                    thousandSeparator={true}
                                                    decimalScale={3} 
                                                    suffix={'%'} 
                                                    fixedDecimalScale 
                                                /> : t('na')
                                            }
                                        </td>
                                    </tr>
                                ) : <tr><td colSpan='5' style={tdStyle}><center>{t('noDryingRecords')}</center></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('trim')} />
                </div>
                <div style={{ width: '800px' }}>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ ...thStyle, width: '100px' }} >{t('number')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('type')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('startAmount')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('output')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('loss')}</th>
                        </thead>
                        <tbody>
                            {record.trimmings != null && record.trimmings.length > 0 ?
                                record.trimmings.map((row, i) => 
                                <tr style={{ border: '1px solid #ddd', padding: '2px' }} key={'trimmingRecord-' + i} >
                                        <td style={tdStyle}>{row.number}</td>
                                        <td style={tdStyle}>
                                            {row.type != null && row.type !== '' && row.type.length > 0 ?
                                                row.type.map((type, i) => {
                                                    if(i === row.type.length - 1)
                                                        return type;
                                                    else
                                                        return type + ', ';
                                                }) : t('na')
                                            }
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'right' }}>
                                            {row.startAmount != null && row.startAmount !== '' ?
                                                <NumericFormat displayType={'text'} value={row.startAmount} thousandSeparator={true}
                                                decimalScale={3} suffix={row.startAmountUOM} fixedDecimalScale /> : t('na')
                                            }
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'right' }}>
                                            {row.actualOutput != null && row.actualOutput !== '' ?
                                                <NumericFormat displayType={'text'} value={row.actualOutput} thousandSeparator={true}
                                                decimalScale={3} suffix={row.startAmountUOM} fixedDecimalScale /> : t('na')
                                            }
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'right', fontWeight: 'bold' }}>
                                            {row.startAmount != null && row.startAmount !== '' &&
                                             row.actualOutput != null && row.actualOutput !== '' ?
                                                <NumericFormat 
                                                    displayType={'text'} 
                                                    value={
                                                        (row.startAmount - row.actualOutput) / row.startAmount * 100
                                                    } 
                                                    thousandSeparator={true}
                                                    decimalScale={3} 
                                                    suffix={'%'} 
                                                    fixedDecimalScale 
                                                /> : t('na')
                                            }
                                        </td>
                                    </tr>
                                ) : <tr><td colSpan='5' style={tdStyle}><center>{t('noTrimmingRecords')}</center></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div> 
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('cure')} />
                </div>
                <div style={{ width: '800px' }}>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ ...thStyle, width: '100px' }} >{t('number')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('startAmount')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('output')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('loss')}</th>
                        </thead>
                        <tbody>
                            {record.curings != null && record.curings.length > 0 ?
                                record.curings.map((row, i) => 
                                    <tr style={{ border: '1px solid #ddd', padding: '2px' }} key={'curingRecord-' + i} >
                                        <td style={tdStyle}>{row.number}</td>
                                        <td style={{ ...tdStyle, textAlign: 'right' }}>
                                            {row.startAmount != null && row.startAmount !== '' ?
                                                <NumericFormat displayType={'text'} value={row.startAmount} thousandSeparator={true}
                                                decimalScale={3} suffix={row.startAmountUOM} fixedDecimalScale /> : t('na')
                                            }
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'right' }}>
                                            {row.actualOutput != null && row.actualOutput !== '' ?
                                                <NumericFormat displayType={'text'} value={row.actualOutput} thousandSeparator={true}
                                                decimalScale={3} suffix={row.startAmountUOM} fixedDecimalScale /> : t('na')
                                            }
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'right', fontWeight: 'bold' }}>
                                            {row.startAmount != null && row.startAmount !== '' &&
                                             row.actualOutput != null && row.actualOutput !== '' ?
                                                <NumericFormat 
                                                    displayType={'text'} 
                                                    value={
                                                        (row.startAmount - row.actualOutput) / row.startAmount * 100
                                                    } 
                                                    thousandSeparator={true}
                                                    decimalScale={3} 
                                                    suffix={'%'} 
                                                    fixedDecimalScale 
                                                /> : t('na')
                                            }
                                        </td>
                                    </tr>
                                ) : <tr><td colSpan='4' style={tdStyle}><center>{t('noCuringRecords')}</center></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            <div style={{ ...boxStyle }}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('deviations')} />
                </div>
                <div style={{ width: '800px' }}>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ ...thStyle, width: '100px' }} >{t('recordNumber')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('occurred')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('title')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('type')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('category')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('impact')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('status')}</th>
                        </thead>
                        <tbody>
                            {record.deviations != null && record.deviations.length > 0 ?
                                record.deviations.map((deviation, i) => 
                                    <tr style={{ border: '1px solid #ddd', padding: '5px' }} key={'deviationRecord-' + i} >
                                        <td style={tdStyle}>{deviation.number}</td>
                                        <td style={tdStyle}><DateDisplay format={props.dateFormat} value={deviation.occuranceDate}/></td>
                                        <td style={tdStyle}>{deviation.title}</td>
                                        <td style={tdStyle}>{deviation.type != null ? deviation.type.label : ''}</td>
                                        <td style={tdStyle}>{deviation.category != null ? deviation.category.label : ''}</td>
                                        <td style={tdStyle}>{deviation.impact ?? t('notKnown')}</td>
                                        <td style={tdStyle}>{deviation.status.label}</td>
                                    </tr>
                                ) : <tr style={{ border: '1px solid #ddd', fontSize: '8pt', padding: '5px' }} ><td colSpan='7'><center>{t('noDeviations')}</center></td></tr>
                            }
                        </tbody>
                    </table>                
                </div>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('nonCorformances')} />
                </div>
                <div style={{ width: '800px' }}>
                    <table style={{ fontFamily: 'Open Sans', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ ...thStyle, width: '100px' }} >{t('recordNumber')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('occurred')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('title')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('status')}</th>
                        </thead>
                        <tbody>
                            {record.ncrs != null && record.ncrs.length > 0 ?
                                record.ncrs.map((ncr, i) => 
                                    <tr style={{ border: '1px solid #ddd', fontSize: '8pt', padding: '5px' }} key={'ncrRecord-' + i} >
                                        <td style={tdStyle}>{ncr.number}</td>
                                        <td style={tdStyle}><DateDisplay format={props.dateFormat} value={ncr.occuranceDate}/></td>
                                        <td style={tdStyle}>{ncr.title}</td>
                                        <td style={tdStyle}>{ncr.status != null && ncr.status !== '' ? ncr.status.label : 'None'}</td>
                                    </tr>
                                ) : <tr style={{ border: '1px solid #ddd', fontSize: '8pt', padding: '5px' }} ><td colSpan='4'><center>{t('noNcrs')}</center></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('roomFeedings')} />
                </div>
                <div style={{ width: '800px' }}>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ ...thStyle, width: '100px' }} >{t('number')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('date')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('type')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('room')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('status')}</th>
                        </thead>
                        <tbody>
                            {record.roomFeedings != null && record.roomFeedings.length > 0 ?
                                record.roomFeedings.map((row, i) => {
                                    return(<tr style={{ border: '1px solid #ddd', padding: '2px' }} key={'roomFeedngRecord-' + i}>
                                        <td style={tdStyle}>{row.number}</td>
                                        <td style={tdStyle}><DateDisplay format={dateFormat} value={row.date}/></td>
                                        <td style={tdStyle}>{row.type}</td>
                                        <td style={tdStyle}>
                                            {row.room != null && row.room !== '' && 
                                             typeof row.room !== 'object' ? 
                                                row.room : typeof row.room === 'object' ?
                                                row.room[0].name : t('na')
                                            }
                                        </td>
                                        <td style={tdStyle}>{row.status}</td>
                                    </tr>)
                                }) : <tr><td colSpan='5' style={tdStyle}><center>{t('noRoomFeedingRecords')}</center></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('cropTreatments')} />
                </div>
                <div style={{ width: '800px' }}>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <th style={{ ...thStyle, width: '100px' }} >{t('number')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('completed')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('type')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('applicationMethod')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('room')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('status')}</th>
                        </thead>
                        <tbody>
                            {record.cropTreatments != null && record.cropTreatments.length > 0 ?
                                record.cropTreatments.map((ct, i) => (
                                    <tr style={{ border: '1px solid #ddd', padding: '2px' }} key={'ipmRecord-' + i}  >
                                        <td style={tdStyle}>
                                            {ct.number != null && ct.number !== '' ? ct.number : t('na')}
                                        </td>
                                        <td style={tdStyle}>
                                            {ct.date != null && ct.date !== '' ? <DateDisplay format={dateFormat} value={ct.date}/> : t('na')}
                                        </td>
                                        <td style={tdStyle}>
                                            {ct.treatmentType != null && ct.treatmentType !== '' ? ct.treatmentType : t('na')}
                                        </td>
                                        <td style={tdStyle}>
                                            {ct.applicationMethod != null ? ct.applicationMethod : t('na')}
                                        </td>
                                        <td style={tdStyle}>
                                            {ct.room != null && ct.room !== undefined ? ct.room : t('na')}
                                        </td>
                                        <td style={tdStyle}>
                                            {ct.status != null && ct.status !== '' ? ct.status : t('na')}
                                        </td>
                                    </tr>
                                )) : <tr><td colSpan='6' style={tdStyle}><center>{t('noCropTreatments')}</center></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('cleaningRecords')} />
                </div>
                <div style={{ width: '800px' }}>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ ...thStyle, width: '100px' }} >{t('number')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('completed')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('type')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('room')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('status')}</th>
                        </thead>
                        <tbody>
                            {record.roomCleaning != null && record.roomCleaning.length > 0 ?
                                record.roomCleaning.map((cleaning, i) =>
                                    <tr style={{ border: '1px solid #ddd', padding: '2px' }} key={'roomCleaningRecord-' + i}  >
                                        <td style={tdStyle}>{cleaning.number}</td>
                                        <td style={tdStyle}><DateDisplay format={dateFormat} value={cleaning.date}/></td>
                                        <td style={tdStyle}>{cleaning.type}</td>
                                        <td style={tdStyle}>{cleaning.room.name}</td>
                                        <td style={tdStyle}>{cleaning.status}</td>
                                    </tr>
                                ) : <tr><td colSpan='5' style={tdStyle}><center>{t('noCleaningRecords')}</center></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('externalTreatments')} />
                </div>
                <div style={{ width: '800px' }}>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={thStyle} >{t('number')}</th>
                            <th style={thStyle} >{t('status')}</th>
                            <th style={thStyle} >{t('type')}</th>
                            <th style={thStyle} >{t('amount')}</th>
                            <th style={thStyle} >{t('vendor')}</th>
                        </thead>
                        <tbody>
                            {record.externalTreatments != null && record.externalTreatments.length > 0 ?
                                record.externalTreatments.map((row, i) => 
                                    <tr style={{ border: '1px solid #ddd', padding: '2px' }} key={'extTreatmentRecord-' + i}  >
                                        <td style={tdStyle}>{row.number}</td>
                                        <td style={tdStyle}>{row.status.label}</td>
                                        <td style={tdStyle}>{row.sterilizationType.label}</td>
                                        <td style={tdStyle}>{row.radiationAmount}</td>
                                        <td style={tdStyle}>{row.vendor != null ? row.vendor.name : ''}</td>
                                    </tr>
                                ) : <tr><td colSpan='5' style={tdStyle}><center>{t('noExternalTreatmentRecords')}</center></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            {record.stages != null && record.stages.length > 0 &&
                record.stages.map((stage, i) =>
                    stage.activities != null && stage.activities.length > 0 &&
                        stage.activities.map((activity, j) => 
                            <div key={'cultivationActivity-' + i + '-' + j } style={{ display: 'block' }}>
                                <CultivationActivity dateFormat={dateFormat} record={activity.activity} t={t} 
                                batchNumber={record.number} stage={stage.stage}
                                // sequence={record.sequence != null && record.sequence !== '' ? 
                                //     record.sequence + '.' + ConversionHelper.numberToLetterConverter(i + 1) : ConversionHelper.numberToRomanConverter(i + 1)
                                // } 
                                />
                            </div>    
                        )
                )
            }
            {record.dryings != null && record.dryings.length > 0 &&
                record.dryings.map((drying, i) =>
                    <div key={'dryingOrder-' + i } style={{ display: 'block' }}>
                        <DryingOrder dateFormat={dateFormat} record={drying} t={t} sequence={drying.sequence}/>
                    </div>  
                )
            }
            {record.trimmings != null && record.trimmings.length > 0 &&
                record.trimmings.map((trimming, i) =>
                    <div key={'trimmingOrder-' + i } style={{ display: 'block' }}>
                        <TrimmingOrder dateFormat={dateFormat} record={trimming} t={t} sequence={trimming.sequence}/>
                    </div>  
                )
            }
            {record.curings != null && record.curings.length > 0 &&
                record.curings.map((curing, i) =>
                    <div key={'curingOrder-' + i } style={{ display: 'block' }}>
                        <CuringOrder dateFormat={dateFormat} record={curing} t={t} sequence={curing.sequence}/>
                    </div>  
                )
            }
            {record.roomFeedings != null && record.roomFeedings.length > 0 &&
                record.roomFeedings.map((feeding, i) =>
                    <div key={'roomFeeding-' + i } style={{ display: 'block' }}>
                        <RoomFeeding dateFormat={dateFormat} record={feeding} t={t} batchNumber={record.number} sequence={feeding.sequence}/>
                    </div>  
                )
            }
            {record.roomCleaning != null && record.roomCleaning.length > 0 &&
                record.roomCleaning.map((cleaning, i) =>
                    <div key={'roomCleaning-' + i } style={{ display: 'block' }}>
                        <RoomCleaning dateFormat={dateFormat} record={cleaning} t={t} batchNumber={record.number} sequence={cleaning.sequence}/>
                    </div>  
                )
            }
            {record.cropTreatments != null && record.cropTreatments.length > 0 &&
                record.cropTreatments.map((treatment, i) =>
                    <div key={'cropTreatment-' + i } style={{ display: 'block' }}>
                        <IPMTreatment dateFormat={dateFormat} record={treatment} t={t} batchNumber={record.number} sequence={treatment.sequence}/>
                    </div>  
                )
            }
        </div>
    )
}