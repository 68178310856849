import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import DateSelect from '../UI/DateSelect/DateSelect';
import Grid from '@mui/material/Grid';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class RoomCheckList extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            date: null,
            user: '',
            users: [],
            room: '',
            rooms: [],
            offset: 0,
            pages: 1,
            listSize: 10,
            location: '',
            locations: [],
            statuses: [],
            status: '',
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            roomSelected: null,
            locationSelected: null,
            statusSelected: null,
            security : []
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.activeLocation, common.cacheValues.room], this.props.auth.constants, null, this.props.auth.user.tenant);
        const statuses = await ValueHelper.getStatusesForObject(this.props.auth.constants.objectIds.roomCheck, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.roomCheck, this.props.auth.user.tenant);
        let storage = sessionStorage.getItem('roomChecks');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        this.setState({
            rooms: cacheValues.rooms,
            users: userValues.create,
            locations: locations,
            statuses: statuses,
            security: this.props.permission,
            ffset: storage != null ? storage.offset : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'date',
            ordDir: storage != null ? storage.ordDir : -1,
            status: storage != null ? storage.status : '',
            statusSelected: storage != null ? storage.statusSelected : null,
            room: storage != null ? storage.room : '',
            roomSelected: storage != null ? storage.roomSelected : null,
            location: storage != null ? storage.location : '',
            locationSelected: storage != null ? storage.locationSelected : null,
            date: storage != null && storage.date != null && storage.date !== '' ? new Date(storage.date) : ''
        }, () => {
            this.loadRecords();
        });
    }

    async loadRecords(){
        const sort = {[this.state.orderBy]: this.state.ordDir};
        const data = {
            offset: this.state.offset,
            size: this.state.listSize,
            user: this.state.user,
            room: this.state.room,
            date: this.state.date,
            status: this.state.status,
            sort: JSON.stringify(sort)
        }
        const result = await axios.get('/api/roomchecks', {params: data});
        this.setState({
            list: result.data.data,
            pages: Math.ceil(result.data.totalCount / this.state.listSize),
            totalCount: result.data.totalCount
        });
        const crumbs = [
            { path: 'active', label: this.props.t('roomChecks'), screen: this.props.auth.screenDefs.RoomCheckList}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        this.saveFilters();
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            status: this.state.status,
            statusSelected: this.state.statusSelected,
            room: this.state.room,
            roomSelected: this.state.roomSelected,
            location: this.state.location,
            locationSelected: this.state.locationSelected,
            date: this.state.date
        };
        sessionStorage.setItem('roomChecks', JSON.stringify(data));
    }

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => this.loadRecords());
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    dateChange = e => {
        this.setState({date: e, page: 0, offset: 0}, () => this.loadRecords());
    }

    search = e => {
        e.preventDefault();
        this.loadRecords();
    }

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const security = this.props.permission;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/roomcheck'
                        createAccess={security.create}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.roomSelected}
                                options={this.state.rooms}
                                onChange={this.changeAuto('roomSelected', 'room')}
                                label={t('room')}
                                data-cy="room"
                            />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <DateSelect
                                className="form-control"
                                onChange={this.dateChange}
                                value={this.state.date}
                                label={t('date')}
                                format={this.props.auth.user.dateFormat}
                                data-cy="date"
                            />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <AutoCompleteField
                                value={this.state.locationSelected}
                                options={this.state.locations}
                                onChange={this.changeAuto('locationSelected', 'location')}
                                label={t('location')}
                                data-cy="location"
                            />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <AutoCompleteField
                                value={this.state.statusSelected}
                                options={this.state.statuses}
                                onChange={this.changeAuto('statusSelected', 'status')}
                                label={t('status')}
                                data-cy="status"
                            />
                        </Grid>
                    </Grid>
                    <TableContainer comopnent={Paper}>
                        <Table aria-label="Room checks">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('room')}
                                        active={this.state.orderBy === 'room'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('room')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('date')}
                                        active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('status')}
                                        active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/roomcheck/' + row._id} name={'roomCheckLink' + i}>{row.number}</Link></TableCell>
                                        <TableCell>{row.room.name + ' - ' + row.location.name}</TableCell>
                                        <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={row.date}/></TableCell>
                                        <TableCell>{row.status != null ? row.status.label : ''}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(RoomCheckList));
