import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import ActionBar from '../UI/Buttons/ActionBar';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import { withTranslation } from 'react-i18next';
import ModalDialog from '../UI/Dialog/ModalDialog';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class TestItemCategories extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            pages: 1,
            listSize: 10,
            code: '',
            description: '',
            index: -1,
            _id: '',
            edit: false,
            security: [],
            enableSave: true,
            exportName: ''
        }
    }

    componentDidMount(){
        this.loadRecords();
    }

    async loadRecords(){
        var list = await axios.get('/api/testitemcategories');
        this.setState({
            list: list.data.data,
            pages: Math.ceil(list.data.totalCount / this.state.listSize),
            security: this.props.permission
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('testCategories'), screen: this.props.auth.screenDefs.TestItemCategories}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        })
    }

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async e => {
        const t = this.props.t;
        if(this.state.code != null && this.state.code !== ''){
            var data = {
                _id: this.state._id,
                code: this.state.code,
                description: this.state.description,
                exportName: this.state.exportName
            }
            try{
                var result = await axios.post('/api/testitemcategory', data);
                this.setState({ enableSave: true }, () => {
                    this.loadRecords();
                    this.clearRecord();
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                })                
            } catch(err){
                this.setState({ enableSave: true}, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
                })                
            }
        } else {
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('required'), title: t('warning'), severity: 'warning'}});
            });            
        }
    }

    clearRecord = () => {
        this.setState({
            index: -1,
            code: '',
            description: '',
            _id: '',
            edit: false,
            exportName: ''
        })
    }

    delete = (e) => {
        if(!this.state.enableSave && (this.state.exportName == null || this.state.exportName === ''))
            return;
        this.setState({enableSave: false}, () => this._delete(e));
    }

    _delete = async e => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            try{
                let result = await axios.delete('/api/testitemcategory/' + this.state._id);
                this.setState({enableSave: true});
                this.loadRecords();
                this.clearRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            } catch (err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.setState({enableSave: true});
            this.clearRecord();
        }
    }

    newCat = () => {
        this.setState({
            edit: true
        })
    }

    editRecord(index) {
        var row = this.state.list[index];
        this.setState({
            index: index,
            _id: row._id,
            code: row.code,
            description: row.description,
            edit: true,
            exportName: row.exportName
        })
    }

    dialogButtons = () => {
        const t = this.props.t;
        const buttons = [
            <Button variant="text" sx={{ color: 'error.main' }} size="small" onClick={this.delete} disabled={this.state._id != null && this.state._id !== ''}>{t('delete')}</Button>,
            <Button variant="text" sx={{ color: 'secondary.main' }} size="small" onClick={this.clearRecord} name="Clear">{t('clear')}</Button>,
            <Button disabled={!this.state.enableSave} variant="contained" color="primary" size="small" onClick={this.save}>{t('save')}</Button>                
        ];
        return buttons;
    }

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createClick={this.newCat}
                        createAccess={security.create}
                    />
                </BreadcrumbBar>
                <Panel>                    
                    <TableContainer component={Paper}>
                        <Table aria-label="Test Item Categories">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>{t('code')}</TableCell>
                                    <TableCell>{t('description')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <TextOnlyClickButton enabled={true} click={() => this.editRecord(i)} name={row.code} id={'itemCat' + i}/>
                                        </TableCell>
                                        <TableCell>{row.description}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Panel>
                <ModalDialog buttonStack={this.dialogButtons()} title={t('category')} toggleDialog={this.clearRecord}
                             dialogStatus={this.state.edit} fullWidth maxWidth='xs' >
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <TextField value={this.state.code} onChange={this.changeValue} name="code"
                            size="medium" fullWidth={true} label={t('code')}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={this.state.description} onChange={this.changeValue} name="description"
                            size="medium" fullWidth={true} label={t('description')}/>
                        </Grid>
                        {this.props.auth.user.internal &&
                            <Grid item xs={5}>
                                <TextField value={this.state.exportName} onChange={this.changeValue} name="exportName"
                                size="medium" fullWidth={true} label={t('exportName')}/>
                            </Grid>
                        }
                    </Grid>
                </ModalDialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(TestItemCategories));
