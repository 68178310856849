import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Aux from '../../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import SectionTitle from '../../UI/Typography/SectionTitle';
import ConversionHelper from '../../../helpers/conversionHelper';
import DateSelect from '../../UI/DateSelect/DateSelect';
import Grid from '@mui/material/Grid';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ReferenceLine,
    Tooltip,
    ResponsiveContainer
} from "recharts";

const ItemNeobiSales = (props) => {
    const { t } = useTranslation('translation');
    const baseData = props.data;
    const [sortedData, setSortedData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [avgPrice, setAvgPrice] = useState(0);
    const [values, setValues] = useState({
        startDate: null,
        endDate: new Date()
    });

    useEffect(() => {
        if (baseData != null && baseData.length > 0) {
            let rawData = JSON.parse(JSON.stringify(baseData));
            let sorted = [];
            if(sortedData.length === 0){
                sorted = rawData.sort((a, b) => {
                    let aDate = new Date(a.dateMax)
                    let bDate = new Date(b.dateMax)
                    if(aDate.getTime() < bDate.getTime()) { return 1; }
                    else if (aDate.getTime() > bDate.getTime()) { return -1; }
                    else { return 0; }
                });
                setSortedData(sorted);
                setValues({...values, endDate: new Date(sorted[0].dateMin), startDate: new Date(sorted[sorted.length - 1].dateMax)});
            } else {
                sorted = sortedData;
            }
        }
    }, [baseData, sortedData, t, values]);

    useEffect(() => {
        let avg = 0;
        let totalPrice = 0;
        let data = [];
        let currDate = null
        let dayAvg = 0;
        let dayTotal = 0;
        let dayCount = 1;
        let dayHigh = 0;
        let dayLow = 0;
        let start = values.startDate != null ? new Date(values.startDate) : null;
        let end = values.endDate != null ? new Date(values.endDate) : null;
        for(let row of sortedData) {
            if(start != null && new Date(row.dateMax).getTime() < start.getTime()){
                continue;
            }
            if(end != null && new Date(row.dateMax).getTime() > end.getTime()){
                continue;
            }
            totalPrice += row.avgPrice;
            if(row.avgPrice > dayHigh){
                dayHigh = row.avgPrice;
            }
            if(dayLow === 0 || (row.avgPrice < dayLow && row.avgPrice !== 0)){
                dayLow = row.avgPrice
            }
            let date = new Date(row.dateMax);
            if(currDate == null || (currDate.getTime() !== date.getTime())){
                if(currDate != null){
                    let month = currDate.getMonth() + 1;
                    if(dayAvg !== 0){
                        data.push({
                            x: currDate.getFullYear() + '-' + month + '-' + currDate.getDate(), 
                            avg: ConversionHelper.round2(dayAvg), 
                            high: ConversionHelper.round2(dayHigh), 
                            low: ConversionHelper.round2(dayLow)
                        });
                    }
                    currDate = date;
                    dayAvg = 0;
                    dayCount = 1;
                    dayTotal = 0;
                    dayHigh = 0;
                    dayLow = 0;
                }
                currDate = date;
            } else {
                dayTotal += row.avgPrice;
                if(row.avgPrice !== 0)
                dayCount++;
                dayAvg = dayTotal / dayCount;
            }
        }
        if(totalPrice !== 0){
            avg = totalPrice / sortedData.length;
        }
        setAvgPrice(avg);
        setChartData(data);
    }, [values, sortedData]);

    const dateChange = (name) => (e) => {
        setValues({...values, [name]: e});
    };

    return(
        <Aux>
            <SectionTitle title={t('salePrices')}/>
            {chartData != null && chartData.length > 0  &&
                <Aux>
                    <Grid container spacing={2}>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                className="form-control"
                                name="startDate"
                                onChange={dateChange('startDate')}
                                value={values.startDate}
                                label={t('startDate')}
                                format={props.auth.user.dateFormat}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                className="form-control"
                                id="endDate"
                                onChange={dateChange('endDate')}
                                value={values.endDate}
                                label={t('endDate')}
                                format={props.auth.user.dateFormat}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    width={1000}
                                    height={300}
                                    data={chartData}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="x" />
                                    <YAxis />
                                    <Tooltip />
                                    {avgPrice !== 0 &&
                                        <ReferenceLine y={avgPrice} stroke="red" />
                                    }
                                    <Line type="monotone" dataKey="avg" stroke="#dc9e20" />
                                    <Line type="monotone" dataKey="low" stroke="#8884d8" />
                                    <Line type="monotone" dataKey="high" stroke="#1dc70c" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Grid>
                    </Grid>
                </Aux>
            }
        </Aux>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(ItemNeobiSales);