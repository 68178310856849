import { CREATE_ALERT, CLEAR_ALERT } from '../actions/types';

const initialState = {
  message: '',
  title: '',
  severity: '',
  isOpen: false
}

function alert(state = initialState, action){
  switch(action.type){
    case CREATE_ALERT: {
      return {
        ...state,
        message: action.payload.message,
        title: action.payload.title,
        severity: action.payload.severity,
        isOpen: true
      }
    }
    case CLEAR_ALERT: {
      return initialState;
    }
    default:
      return state;
  };
}

export default alert;