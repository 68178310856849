import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';

class Approvals extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            pageCount: 1,
            listSize: 5,
            activity: '',
            status: '',
            sops: [],
            statuses: [],
            orderBy: 'dueDate',
            ordDir: 1,
            totalCount: 0,
            page: 0
        }
    }

    async componentDidMount(){
        this.setState({status: this.props.status}, () => {
            this.loadRecords();
        });
       
    }

    async loadRecords(){
        var sortName = this.state.orderBy;
        var ordDir = this.state.ordDir;
        var sort = {[sortName]: ordDir};
        const data = {
            offset: this.state.offset,
            size: this.state.listSize,
            sort: JSON.stringify(sort),
            department: this.props.department,
            status: this.state.status,
            incomplete: true
        }
        var list = await axios.get('/api/schedules', {params: data});
        this.setState({
            list: list.data.data,
            pageCount: Math.ceil(list.data.totalCount / this.state.listSize),
            orderBy: sortName,
            ordDir: ordDir,
            totalCount: list.data.totalCount,
        })
    }

    changeSelect = (name, data) => value => {
        this.setState({[name]: value, [data]: value.value}, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;

        return(
            <Aux>
                <SectionTitle title={this.props.title}/>
                <TableContainer component={Paper}>
                    <Table aria-label="Activities" size="small">
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('dueDate')}
                                    active={this.state.orderBy === 'dueDate'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('dueDate')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Activity</TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('assignee')}
                                    active={this.state.orderBy === 'assignee'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('assignee')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('status')}
                                    active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list.map((row, i) =>
                                <TableRow key={row._id}>
                                    <TableCell><Link to={'/scheduledActivity/' + row._id} name={'schedActLink' + i}>
                                        <DateDisplay format={this.props.auth.user.dateFormat} value={row.dueDate}/></Link>
                                    </TableCell>
                                    <TableCell>{row.label}</TableCell>
                                    <TableCell>
                                        {row.assignee != null ? row.assignee.firstName + ' ' + row.assignee.lastName : ''}
                                    </TableCell>
                                    <TableCell>{row.status.label}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(Approvals));
