import React from 'react';
import { NumericFormat } from 'react-number-format';
import ConversionHelper from '../../../helpers/conversionHelper';
import ReportTitle from '../../UI/Typography/ReportTitle';
import ReportSectionTitle from '../../UI/Typography/ReportSectionTitle';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';

import ActivityRecord from './ActivityRecord';

const boxStyle = {
    margin: '8px',
    width: '100%'
}

const thStyle = {
    borderBottom: '2px solid rgb(224, 224, 224)', 
    fontSize: '9pt', 
    textAlign: 'center'
}

const tdStyle = { 
    border: '0.5px solid rgb(224, 224, 224)', 
    fontSize: '8pt', 
    padding: '5px',
}

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse'
}

export default function ipmTreatmentTemplate(props) {
    const { batchNumber, dateFormat, record, sequence, t } = props;
    return (
        <div style={{ width: '8.5in', pageBreakBefore: 'always' }}>
            <div style={boxStyle}>
                <ReportTitle 
                    title={(sequence != null && sequence !== '' ? sequence + '. ' + t('cropTreatment') + ': ' : t('cropTreatment') + ': ') + 
                            (record.number != null && record.number !== '' ? 
                                record.number : ''
                            )   
                        }
                />
            </div>
            <div style={{ ...boxStyle, display: 'block' }}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '200px' }}>{t('number')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('date')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('status')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('room')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('location')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.number != null && record.number !== '' ? record.number : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.date != null ?
                                    <DateDisplay value={record.date} format={dateFormat}/> : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.status != null && record.status !== '' ? record.status : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.room != null && record.status !== '' ? record.status : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.location != null && record.location !== '' ? record.location : t('na')}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ ...boxStyle, display: 'block' }}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '100px'}}>{t('type')}</th>
                            <th style={{ ...thStyle, width: '100px'}}>{t('method')}</th>
                            <th style={{ ...thStyle, width: '100px'}}>{t('startTime')}</th>
                            <th style={{ ...thStyle, width: '100px'}}>{t('endTime')}</th>
                            <th style={{ ...thStyle, width: '100px'}}>{t('duration')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.treatmentType != null && record.treatmentType !== '' ? record.treatmentType : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.applicationMethod != null && record.applicationMethod !== '' ?
                                    record.applicationMethod : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.startTime != null ?
                                    <DateDisplay value={record.startTime} format={dateFormat + ' hh:mm a'}/> : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.stopTime != null ?
                                    <DateDisplay value={record.stopTime} format={dateFormat + ' hh:mm a'}/> : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.duration != null && record.duration !== '' ?
                                    <NumericFormat displayType={'text'} value={record.duration} thousandSeparator={true}
                                    decimalScale={3} suffix={' ' + t('minutes')}/> : t('na')
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('description')} />
                </div>
                <div style={{ fontSize: '9pt', padding: '5px' }}>
                    {record.description != null && record.description !== '' ?
                        record.description : t('na')
                    }
                </div>
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('batches')} />
                </div>
                {record.batchCosts != null && record.batchCosts !== '' && record.batchCosts.length > 0 ?
                    <div style={{ fontSize: '9pt', padding: '5px' }}>
                        {record.batchCosts.map((batch, i) => {
                            if(i === record.batchCosts.length - 1)
                                return <span style={{ backgroundColor: batchNumber != null &&
                                                      batchNumber === batch.number ?
                                                      'yellow' : null,
                                                      fontWeight: batchNumber != null && 
                                                      batchNumber === batch.batch.number ? 
                                                      'bold' : null 
                                        }}>{batch.batch.number + ' (' + batch.plantCount + ')'}</span>
                            else
                                return (
                                    <>
                                        <span style={{  backgroundColor: batchNumber != null &&
                                                        batchNumber === batch.number ?
                                                        'yellow' : null,
                                                        fontWeight: batchNumber != null && 
                                                        batchNumber === batch.batch.number ? 
                                                        'bold' : null 
                                        }}>{batch.batch.number + ' (' + batch.plantCount + ')'}</span>{', '}
                                    </>
                                );
                            })
                        }
                    </div> : ''                             
                }
            </div>
            <div style={{ width: '100%' }}>
                <div style={{ width: '50%', display: 'inline-block' }}>
                    <div style={boxStyle}>
                        <div style={{ width: '100%', display: 'block' }}>
                            <ReportSectionTitle title={t('authorizedBy')} />
                        </div>
                        <div style={{ fontSize: '9pt', padding: '5px' }}>
                            {record.authorizedBy != null && record.authorizedBy !== '' ? record.authorizedBy : t('na')}
                        </div>
                    </div>
                </div>
                <div style={{ width: '50%', display: 'inline-block' }}>
                    <div style={boxStyle}>
                        <div style={{ width: '100%', display: 'block' }}>
                            <ReportSectionTitle title={t('performedBy')} />
                        </div>
                        {record.users != null && record.users !== '' && record.users.length > 0 ?
                            <div style={{ fontSize: '9pt', padding: '5px' }}>
                                {record.users.map((user, i) => {
                                    if(i === record.users.length - 1)
                                        return user.name
                                    else
                                        return user.name + ', '
                                    })
                                }
                            </div> : ''                             
                        }
                    </div>
                </div>                
            </div>            
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('inputs')} />
                </div>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '350px' }}>{t('item')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('regNo')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('preparation')}</th>
                            <th style={{ ...thStyle, width: '85px' }}>{t('container')}</th>
                            <th style={{ ...thStyle, width: '75px' }}>{t('expires')}</th>
                            <th style={{ ...thStyle, width: '75px' }}>{t('quantity')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {record.pesticides != null && record.pesticides.length > 0 ?
                            record.pesticides.map((input, i) => 
                                <tr key={'ipmInput-' + i}>
                                    <td style={tdStyle}>
                                        {input.item != null && input.item !== '' ? input.item.name : t('na')}
                                    </td>
                                    <td style={{ ...tdStyle, textAlign: 'center' }}>
                                        {input.registrationNumber != null && input.registrationNumber !== '' ? input.registrationNumber : t('na')}
                                    </td>
                                    <td style={{ ...tdStyle, textAlign: 'center' }}>
                                        {input.preparation != null && input.preparation !== '' && input.preparation.number != null && input.preparation.number !== '' ? 
                                            input.preparation.number : t('na')
                                        }
                                    </td>
                                    <td style={{ ...tdStyle, textAlign: 'center' }}>
                                        {input.container != null && input.container !== '' ? input.container.number : t('na')}
                                    </td>
                                    <td style={{ ...tdStyle, textAlign: 'center' }}>
                                        {input.container != null && input.container.expires != null && input.container.expires !== '' ?
                                            <DateDisplay value={input.container.expires} format={dateFormat}/> : t('na')
                                        }
                                    </td>
                                    <td style={{ ...tdStyle, textAlign: 'right' }}>
                                        {input.quantity != null && input.quantity !== '' && input.uom != null && input.uom !== '' ? 
                                            <NumericFormat displayType={'text'} value={input.quantity} thousandSeparator={true}
                                            decimalScale={3} suffix={input.uom}/> : t('na')
                                        }
                                    </td>
                                </tr>
                            ) : 
                            <tr key='noIPMInputs'>
                                <td style={{ ...tdStyle, textAlign: 'center' }} colSpan={4}>{t('noIPMInputs')}</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            
            {/* <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('consumption')} />
                </div>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '375px' }}>{t('item')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('category')}</th>
                            <th style={{ ...thStyle, width: '75px' }}>{t('hcReportCat')}</th>
                            <th style={{ ...thStyle, width: '75px' }}>{t('amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={tdStyle}>
                                {record.consumption != null && record.consumption.item != null && record.consumption.item !== '' ?
                                    record.consumption.item.name : t('na')
                                }
                            </td>
                            <td style={tdStyle}>
                                {record.consumption != null && record.consumption.category != null && record.consumption.category !== '' ?
                                    record.consumption.category : t('na')
                                }
                            </td>
                            <td style={tdStyle}>
                                {record.consumption != null && record.consumption.hcCategory != null && record.consumption.hcCategory !== '' ?
                                    record.consumption.hcCategory : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'right', fontWeight: 'bold' }}>
                                {record.consumption != null && record.consumption.amount != null && record.consumption.amount !== '' ?
                                    record.consumption.amount + record.consumption.uom : t('na')
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('outputs')} />
                </div>
                <table style={{ width: '100%', borderCollapse: 'collapse' }} >
                    <thead>
                        <tr>
                            <td style={{ ...thStyle, width: '75px' }}><strong>{t('type')}</strong></td>
                            <td style={{ ...thStyle, width: '300px' }}><strong>{t('item')}</strong></td>
                            <td style={{ ...thStyle, width: '75px' }}><strong>{t('lot')}</strong></td>
                            <td style={{ ...thStyle, width: '75px' }}><strong>{t('container')}</strong></td>
                            <td style={{ ...thStyle, width: '75px' }}><strong>{t('quantity')}</strong></td>
                        </tr>
                    </thead>
                    <tbody>
                        {record.containers != null && record.containers.length > 0 ?
                            record.containers.map((row, i) => 
                            <tr key={'containerRow-' + i}>
                                <td style={tdStyle}>{row.type != null && row.type !== '' ? row.type : t('na')}</td>
                                <td style={tdStyle}>{row.item != null && row.item !== '' ? row.item : t('na')}</td>
                                <td style={tdStyle}>{row.lot != null && row.lot !== '' ? row.lot : t('na')}</td>
                                <td style={tdStyle}>{row.container != null && row.container !== '' ? row.container : t('na')}</td>
                                <td style={{ ...tdStyle, textAlign: 'right' }}>
                                    {row.amount != null && row.amount !== '' && row.uom != null && row.uom !== '' ? 
                                        <NumericFormat displayType={'text'} value={row.amount} thousandSeparator={true}
                                        decimalScale={3} suffix={row.uom}/> : t('na')
                                    }
                                </td>
                            </tr>
                        ) :
                            <tr>
                                <td colSpan={5} style={{ ...tdStyle, textAlign: 'center' }}>{t('none')}</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <hr style={{ width: '80%', margin: '16px auto' }} />  
            </div> */}
            {record.sops != null && record.sops.length > 0 &&
                record.sops.map((sop, i) => 
                    <div style={boxStyle}>
                        <ActivityRecord dateFormat={dateFormat} record={sop.workRecord} sop={sop.sop} t={t} 
                        sequence={sequence != null && sequence !== '' ? 
                            sequence + '.' + ConversionHelper.numberToRomanConverter(i + 1) : ConversionHelper.numberToLetterConverter(i + 1)
                        } />
                    </div>
                )
            }
        </div>
    );
}