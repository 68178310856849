import React, { Component } from 'react';
import { connect } from 'react-redux';
import HazardousSpills from '../Widgets/HazardousSpills';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import ActionBar from '../UI/Buttons/ActionBar';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class InjuryHazardousList extends Component {

    render(){
        const security = this.props.permission;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/hazardous'
                        createAccess={security.create}
                    />
                </BreadcrumbBar>
                <Panel>
                    <HazardousSpills listSize="10"/>
                </Panel>
            </Aux>

        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default (connect(mapStateToProps)(InjuryHazardousList));
