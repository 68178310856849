import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import base64 from 'base-64';
import axios from 'axios';
import Aux from '../../hoc/Auxilary/Auxilary';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

export default function ProfileImage(props){
    const { callback, id, image } = props;
    const [ values, setValues ] = React.useState({
        imageURL: '',
        imageBinary: '',
        newImage: false,
        fileImage: {},
        openModal: false,
        imageSrc: ''
    });
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const recordImage = () => {
            if(image != null)
                setValues(values => ({...values, imageSrc: "data:application/octet-stream;base64," + image.thumbnail }));
        }
        recordImage();
    }, [image])

    const profileImageChangeHandler = (event) => {
        let fileImage = event[0];
        let reader = new FileReader();
        let fileBinary = null;
        reader.readAsBinaryString(fileImage);
        reader.onload = () => {
            fileBinary = base64.encode(reader.result);
            setValues({ ...values, 
                        imageURL: URL.createObjectURL(fileImage), 
                        imageBinary: fileBinary, 
                        newImage: true, 
                        fileImage: fileImage 
                    });
        }
    };

    const saveImage = async (event) => {
        let data = {
            file: values.imageBinary,
            fileName: values.fileImage.name,
            type: values.fileImage.type,
            userid: id,
            documentId: image != null ? image._id : null,
            version: image != null ? image.version : 0
        };
        try { 
            let result = await axios.post('/api/profileimage', data);
            dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            setValues({ ...values, openModal: false });
            callback();
        } catch(err){
            dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    const toggleDialog = (e) => {
        setValues({ ...values, openModal: !values.openModal });
    }

    return(
        <Aux>
            {props.image != null && props.image.thumbnail != null &&
                <Grid container spacing={3} sx={{ justifyContent: 'center' }} >
                    <Grid item xs={12}>
                        <Avatar 
                            src={values.imageSrc}
                            alt=""
                            sx={{
                                margin: 'auto',
                                alignSelf: 'center',
                                height: 110,
                                width: 100,
                                flexShrink: 0,
                                flexGrow: 0
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <Button variant="contained" color="primary" onClick={() => toggleDialog()}>{t('changeImage')}</Button>
                    </Grid>
                </Grid>
            }
            {props.image == null &&
                <Grid container spacing={3}>
                    <Grid item sm={2}>
                        <Button variant="contained" color="primary" onClick={() => toggleDialog()}>{t('setProfileImage')}</Button>
                    </Grid>
                </Grid>
            }
            
            <Modal size="lg" centered show={values.openModal} onHide={() => toggleDialog()}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('profileImage')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <input type="file" onChange={e => profileImageChangeHandler(e.target.files)}/>
                        </div>
                        {values.imageURL !== '' &&
                            <div>
                                <Avatar src={values.imageURL} variant="square" alt=""/>
                                <br/>
                                <br/>
                                <div className="form-group row">
                                    <Button variant="contained" color="primary" size="small" onClick={() => saveImage()}>{t('use')}</Button>&nbsp;&nbsp;
                                    <Button variant="contained" color="secondary" size="small" onClick={() => toggleDialog()}>{t('cancel')}</Button>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </Aux>
    )
};