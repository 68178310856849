import React, { Component } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Aux from '../../hoc/Auxilary/Auxilary';
import SectionTitle from '../UI/Typography/SectionTitle';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';

class RoomBatches extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            fullList: [],
            offset: '',
            listSize: 10,
            totalCount: 0,
            page: 0,
            orderBy: 'number',
            ordDir: -1,
            plnntCount: null
        };
    }

    async componentDidMount(){
        await this.loadRecords()
    }

    loadRecords = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let parms = {
            sort: JSON.stringify(sort),
            growing: true,
            room: this.props.room
        };
        let fullList = [];
        let plantCount = null;
        let totalCount = 0;
        let result = await axios.get('/api/roombatches/' + this.props.room, {params: parms});
        fullList = result.data.list;
        plantCount = result.data.plantCount;
        totalCount = result.data.totalCount;
        let list = fullList != null ? fullList.slice(0, this.state.listSize) : [];
        this.setState({
            list: list,
            totalCount: totalCount,
            plantCount: plantCount,
            fullList: fullList
        })
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage}, () => {
            this.sliceRows();
        });
    };

    sliceRows(){
        let list = this.state.fullList.slice(this.state.listSize * this.state.page, (this.state.page * this.state.listSize) + this.state.listSize);
        this.setState({list: list})
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.sliceRows();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <SectionTitle title={t('currentBatches')}/>
                <br/>
                {this.state.plantCount != null &&
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <FormControl>
                                <FormLabel>{t('plantCount')}</FormLabel>
                                {this.state.plantCount}
                            </FormControl>
                        </Grid>
                    </Grid>
                }
                <TableContainer component={Paper}>
                    <Table aria-label="Room Batches" size="small">
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('number')}
                                    active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('strain')}
                                    active={this.state.orderBy === 'strain'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('strain')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('startDate')}
                                    active={this.state.orderBy === 'startDate'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('startDate')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>{t('plants')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list.map((row, i) =>
                                <TableRow key={row._id}>
                                    <TableCell><Link to={'/evorabatch/' + row._id}>{row.number}</Link></TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell>{row.strain.name != null ? row.strain.name : row.strain.label}</TableCell>
                                    {row.startDate != null &&
                                        <TableCell><DateDisplay value={row.startDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                    }
                                    {row.actualStart != null && 
                                        <TableCell><DateDisplay value={row.actualStart} format={this.props.auth.user.dateFormat}/></TableCell>
                                    }
                                    <TableCell>{row.plantCount}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                {this.state.fullList != null && this.state.fullList.length > 0 &&
                                    <TablePagination count={this.state.fullList.length}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                }
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(RoomBatches));