import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import isEmpty from '../../is-empty';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import { withTranslation } from 'react-i18next';

class HazardousProperty extends Component {
    constructor(props){
        super(props);

        this.state = {
            properties: [],
            _id: '',
            name: '',
            damageDescription: '',
            lossValue: '',
            edit: false,
            enableSave: true
        }

        this.changeValue = this.changeValue.bind(this);
        this.saveClick = this.saveClick.bind(this);
        this.deleteClick = this.deleteClick.bind(this);
        this.newClick = this.newClick.bind(this);
        this.clearClick = this.clearClick.bind(this);
        this.changeNumberValue = this.changeNumberValue.bind(this);
    }

    componentDidMount(){
        this.loadProperties();
    }

    loadProperties(){
        axios.get('/api/incidentproperties/' + this.props.id)
        .then(properties => {
            this.setState({
                properties: properties.data,
                name: '',
                damageDescription: '',
                lossValue: '',
                edit: false
            })
        })
    }

    editAction(id){
        for(var i = 0; i < this.state.properties.length; i++){
            if(id === this.state.properties[i]._id){
                this.setState({
                    _id: this.state.properties[i]._id,
                    name: this.state.properties[i].name,
                    damageDescription: this.state.properties[i].damageDescription,
                    lossValue: this.state.properties[i].lossValue,
                    edit: true
                })
                break;
            }
        }
    }

    changeValue(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = () => {
        const t = this.props.t;
        const valid = this.validateRecord();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        var data = ({
            _id: this.state._id,
            name: this.state.name,
            damageDescription: this.state.damageDescription,
            lossValue: this.state.lossValue,
            incidentId: this.props.id
        })
        axios.post('/api/incidentpropertysave', data)
        .then(result => {
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.loadProperties();
            });            
        })
        .catch(err => {
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        });
    }

    validateRecord(){
        const t = this.props.t;
        let errors = {};
        if(this.state.name == null || this.state.name === '')
            errors[t('name')] = t('required');
        if(this.state.damageDescription == null || this.state.damageDescription === '')
            errors[t('damageDescription')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    clearClick(e){
        e.preventDefault();
        this.setState({
            _id: '',
            name: '',
            damageDescription: '',
            lossValue: '',
            edit: false
        })
    }

    deleteClick(e){
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._deleteClick(e);
        })
    }

    _deleteClick(e){
        const t = this.props.t;
        var data = {
            _id: this.state._id,
            incidentId: this.props.id
        }
        axios.post('/api/incidentpropertydelete', data)
        .then(result => {
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            this.loadProperties();
        })
        .catch(err => {
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        })
    }

    newClick(e){
        e.preventDefault();
        this.setState({edit: true});
    }

    changeNumberValue(e){
        this.setState({lossValue: e.value});
    }

    render(){
        const errors = this.props.errors;
        const signed = false;
        const t = this.props.t;
        return(
            <Aux>
                <br/>
                <TableContainer component={Paper}>
                    <Table aria-label="Properties">
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>{t('propertyName')}</TableCell>
                                <TableCell>{t('damageDescription')}</TableCell>
                                <TableCell>{t('estimatedLossValue')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.properties.map((property, i) =>
                                <TableRow key={property._id}>
                                    <TableCell>
                                        <TextOnlyClickButton enabled={true} click={() => this.editAction(property._id)} name={property.name}/>
                                    </TableCell>
                                    <TableCell>{property.damageDescription}</TableCell>
                                    <TableCell><NumericFormat displayType={'text'} value={property.lossValue} 
                                                thousandSeparator={true} prefix={t('dollarSign')}/></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.edit === false &&
                    <Button variant="contained" size="small" color="primary" onClick={this.newClick}>{t('add')}</Button>
                }
                {this.state.edit &&
                    <Aux>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                size="medium" fullWidth={true} disabled={signed} label={t('propertyName')} required
                                error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <TextField value={this.state.damageDescription} onChange={this.changeValue} name="damageDescription"
                                size="medium" fullWidth={true} disabled={signed} label={t('damageDescription')} required
                                error={errors[t('damageDescription')] != null ? true : false} helperText={errors[t('damageDescription')]}/>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <InputMaskField mask="$99999999" name="lossValue" value={this.state.lossValue}
                                    onChange={this.changeValue} disabled={signed}
                                    helperText={errors[t('lossValue')]}
                                    error={errors[t('lossValue')] != null ? true : false} 
                                    label={t('estimatedLossValue')}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={5}>
                                <Button variant="contained" color="primary" size="small" disabled={!this.state.enableSave}
                                    onClick={this.saveClick}>{t('save')}</Button>&nbsp;&nbsp;
                                <Button variant="contained" color="secondary" size="small"
                                    onClick={this.clearClick}>{t('close')}</Button>&nbsp;&nbsp;
                                <Button variant="contained" color="secondary" size="small"
                                    onClick={this.deleteClick} disabled={this.state._id === ''}>{t('delete')}</Button>
                            </Grid>
                        </Grid>
                        <div className="form-group row">
                                
                            </div>
                    </Aux>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(HazardousProperty));