import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import common from '../../jsons/common.json';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { SaveButton, DeleteButton } from '../UI/Buttons/Buttons';
import { withTranslation } from 'react-i18next';
import ValueHelper from '../../helpers/valueHelper';
import { FormControl, FormLabel } from '@mui/material';
import TextField from '../UI/TextField/TextField';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ActionBar from '../UI/Buttons/ActionBar';
import isEmpty from '../../is-empty';
import Panel from '../UI/Panel/Panel';
import {
    renderer as materialuiRenderer
  } from '@sop/react-forms-processor-material-ui';
import ObjectLog from '../General/ObjectLog';
import Title from '../UI/Typography/Title';
import { Alert } from '@mui/lab';
import SectionTitle from '../UI/Typography/SectionTitle';
import { FormBuilder } from '@sop/react-forms-processor-formbuilder';
import ConfirmDelete from '../General/ConfirmDelete';

class EquipmentCalibrationType extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: null,
            number: '',
            name: '',
            form: '',
            documents: [],
            fieldAccess: false,
            enableSave: true,
            security: {},
            sopList: [],
            sops: [],
            sopsSel: [],
            formBuilderKey: 0,
            deleteOpen: false
        };
    }

    async componentDidMount(){
        const fieldAccess = this.props.permission.update;
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.equipmentCalibrationSop], this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null,
            fieldAccess: fieldAccess,
            security: this.props.permission,
            sopList: cacheValues.sops
        }, () => {
            if(this.state._id != null){
                this.loadRecord();
            }  else {
                const crumbs = [
                    { path: '/#/equipmentcalibrationtypes', label: this.props.t('calibrationTypes'), screen: this.props.auth.screenDefs.EquipmentCalibrationTypes},
                    { path: 'active', label: this.props.t('calibrationType'), screen: this.props.auth.screenDefs.EquipmentCalibrationType}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/equipmentcalibtype/' + this.state._id);
        let sopsSel = [];
        if(record.data.sops != null && record.data.sops.length > 0){
            record.data.sops.forEach(sop => {
                let sel = this.state.sopList.find(s => s.value === sop);
                if(sel != null){
                    sopsSel.push(sel);
                }
            });
        }
        const fieldAccess = this.props.permission.update;
        this.setState({
            number: record.data.number,
            name: record.data.name,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            documents: record.data.documents,
            sops: record.data.sops,
            sopsSel: sopsSel,
            formBuilderKey: record.data._id,
            fieldAccess: fieldAccess,
            deleteOpen: false
        }, () => {
            const crumbs = [
                { path: '/#/equipmentcalibrationtypes', label: this.props.t('calibrationTypes'), screen: this.props.auth.screenDefs.EquipmentCalibrationTypes},
                { path: 'active', label: this.props.t('calibrationType') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.EquipmentCalibrationType}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    changeValue = e => {
        const {name, value} = e.target;
        this.setState({[name]: value });
    };

    changeMulti = (name, data) => (e, newValue) => {
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._saveClick();
        });
    }

    _saveClick = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid){
            this.setState({enableSave: true});
            return; 
        }
        try {
            let data = {
                _id: this.state._id,
                name: this.state.name,
                form: this.state.form != null ? JSON.stringify(this.state.form) : null,
                sops: this.state.sops
            };
            let result = await axios.post('/api/equipmentcalibtype', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/equipmentcalibrationtype/' + result.data.id);
            else
                this.loadRecord();
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    validate(){
        const t = this.props.t;
        let errors = {};
        if(this.state.name == null || this.state.name === '')
            errors[t('name')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    onFormBuilderChange(value) {
        this.setState({form: value});
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._deleteClick();
        });
    };

    _deleteClick = async () => {
        const t = this.props.t;
        if(this.state._id == null || this.state._id === '' || !this.state.fieldAccess){
            this.setState({enableSave: true, deleteOpen: false});
            this.props.history.goBack();
            return;
        }
        try {
            let result = await axios.delete('/api/equipmentcalibtype/' + this.state._id);
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            this.props.history.goBack();
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        } finally {
            this.setState({enableSave: true, deleteOpen: false});
        }
    }

    render(){
        const t = this.props.t;
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        fieldAccess={this.state.security.update}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.equipmentCalibrationType}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                    />
                </BreadcrumbBar>
                <Title title={t('equipmentCalibrationType')} />
                <Panel>
                    <Grid container spacing={3}>
                        {this.state.number != null && this.state.number !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={10} sm={5}>
                            <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                size="medium" fullWidth={true} label={t('name')} data-cy='name'
                                error={errors[t('name')] != null} helperText={errors[t('name')]} disabled={!fieldAccess}/>
                        </Grid>
                        <Grid item sm={10}>
                            <AutoCompleteField
                                multiple
                                stayOpen
                                value={this.state.sopsSel}
                                options={this.state.sopList}
                                onChange={this.changeMulti('sopsSel', 'sops')}
                                error={errors[t('sops')] != null ? true : false}
                                helperText={errors[t('sops')]}
                                label={t('workRecs')}
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title={t('form')}/>
                    <Grid item sm={12}>
                        {errors[t('form')] &&
                            <Alert variant="outlined" severity="error" sx={{ 'marginTop': '5px', 'marginBottom': '5px' }}>{errors[t('form')]}</Alert>
                        }
                        <FormBuilder
                        key={this.state.formBuilderKey}
                        renderer={materialuiRenderer}
                        value={this.state.form}
                        onChange={(value) => {
                            this.onFormBuilderChange(value);
                        }}
                        editable={this.props.auth.user.internal}
                        />
                    </Grid>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            <SaveButton saveClick={this.saveClick} enabled={fieldAccess && this.state.enableSave}/>
                            <DeleteButton deleteClick={this.deleteToggle} enabled={fieldAccess && this.state.enableSave}/>
                        </Grid>
                    </Grid>
                    <br/>
                    {this.state._id != null && this.state._id !== '' &&
                        <ObjectLog id={this.state._id}/>
                    }
                </Panel>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(EquipmentCalibrationType));