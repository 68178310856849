import { OPEN_NEW_NAVMENU, OPEN_NEW_NAVSUBMENU, RESET_NAVMENU, TOGGLE_NAV_DRAWER,
         SET_NAVMENU_LOCATION, SET_NAV_CRUMBS, TOGGLE_NAVMENU, TOGGLE_NAVSUBMENU } from './types';

export const setNavMenuIndex = navMenu => ({
    type: SET_NAVMENU_LOCATION,
    payload: {
      navMenu: navMenu
    }
});

export const setNavCrumbs = crumbs => ({
  type: SET_NAV_CRUMBS,
  payload: crumbs
});

export const openNewNavMenu = menu => ({
  type: OPEN_NEW_NAVMENU,
  payload: {
    openMenu: menu
  }
});

export const openNewNavSubMenu = menu => ({
  type: OPEN_NEW_NAVSUBMENU,
  payload: {
    openSubMenu: menu
  }
});

export const toggleNavDawer = () => ({
  type: TOGGLE_NAV_DRAWER,
});

export const toggleNavMenu = () => ({
  type: TOGGLE_NAVMENU,
});

export const toggleNavSubMenu = () => ({
  type: TOGGLE_NAVSUBMENU,
});

export const resetNavMenu = () => ({
  type: RESET_NAVMENU,
});