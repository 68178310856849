import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';

class MaintenanceLogs extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 5,
            requestor: '',
            assignee: '',
            users: [],
            priority: '',
            priorities: [],
            category: '',
            categories: [],
            room: this.props.room,
            status: '',
            statuses: [],
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            statusSel: null,
            catSel: null,
            prioritySel: null
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.user, common.cacheValues.priority, 
            common.cacheValues.maintenanceRequestCategory], this.props.auth.constants, null, this.props.auth.user.tenant);
        const statuses = await ValueHelper.getStatusesForObject(this.props.auth.constants.objectIds.maintenanceRequest, this.props.auth.user.tenant);
        this.setState({
            users: cacheValues.users,
            priorities: cacheValues.priorities,
            categories: cacheValues.maintenanceRequestCategories,
            statuses: statuses
        }, () => { this.loadRecords();});
    }

    loadRecords = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [];
        if(this.state.category != null && this.state.category !== '')
            columnFilters.push({'id': 'category', 'value': this.state.category});
        if(this.state.priority != null && this.state.priority !== '')
            columnFilters.push({'id': 'priority', 'value': this.state.priority});
        if(this.state.status != null && this.state.status !== '')
            columnFilters.push({'id': 'status', 'value': this.state.status});
        if(this.state.room != null && this.state.room !== '')
            columnFilters.push({'id': 'room', 'value': this.state.room});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        const list = await axios.get('/api/maintenancerequests', {params: params});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        })
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();}
        );
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                {this.state.list != null && this.state.list.length > 0 &&
                    <Aux>
                    
                        <SectionTitle title={t('serviceRequests')}/>
                        <Panel>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.catSel}
                                        options={this.state.categories}
                                        onChange={this.changeAuto('catSel', 'category')}
                                        label={t('category')}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.prioritySel}
                                        options={this.state.priorities}
                                        onChange={this.changeAuto('prioritySel', 'priority')}
                                        label={t('priority')}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.statusSelected}
                                        options={this.state.statuses}
                                        onChange={this.changeAuto('statusSelected', 'status')}
                                        label={t('status')}
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper}>
                                <Table aria-label="Service Requests" size="small">
                                    <TableHead>
                                        <TableRow key="tableHeader">
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('number')}
                                                    active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('date')}
                                                    active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('requestor')}
                                                    active={this.state.orderBy === 'requestor'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('requestor')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('category')}
                                                    active={this.state.orderBy === 'category'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('category')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('priority')}
                                                    active={this.state.orderBy === 'priority'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('priority')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('assignee')}
                                                    active={this.state.orderBy === 'assignee'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('assignee')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('status')}
                                                    active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.list.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell><Link to={'/maintenancerequest/' + row._id} name={'maintReqLink' + i}>{row.number}</Link></TableCell>
                                                <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={row.date}/></TableCell>
                                                <TableCell>{row.requestor.firstName + ' ' + row.requestor.lastName}</TableCell>
                                                <TableCell>{row.category.label}</TableCell>
                                                <TableCell>{row.priority.label}</TableCell>
                                                <TableCell>{row.assignee != null ? row.assignee.firstName + ' ' + row.assignee.lastName : ''}</TableCell>
                                                <TableCell>{row.status.label}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow key="tableFooter">
                                            <TablePagination count={this.state.totalCount}
                                            rowsPerPage={this.state.listSize}
                                            onPageChange={this.handleChangePage}
                                            page = {this.state.page}
                                            rowsPerPageOptions={common.rowOptions}
                                            onRowsPerPageChange={this.handleRowsPerPage}/>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Panel>
                    </Aux>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(MaintenanceLogs));