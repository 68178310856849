import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import TextField from '../UI/TextField/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withTranslation } from 'react-i18next';
import NoteAddTwoToneIcon from '@mui/icons-material/NoteAddTwoTone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Signature from '../General/Signature';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import Unlock from '../General/Unlock';
import DocumentVersions from './DocumentVersions';
import './draft.css';
import Aux from '../../hoc/Auxilary/Auxilary';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import SectionTitle from '../UI/Typography/SectionTitle';
import SubSectionTitle from '../UI/Typography/SubSectionHeading'
import { EvoraTabs, TabPanel, TabCanvas } from '../UI/Tabs/Tabs';
import ActionBar from '../UI/Buttons/ActionBar';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import DraftDocument from './DraftDocument';
import RichTextField from '../UI/RichTextField/RichTextField';
import RichText from '../../hoc/RichText/RichText';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import ModalDialog from '../UI/Dialog/ModalDialog';
import Confirmation from '../UI/Dialog/Confirmation';
import { SaveButton, DeleteButton } from '../UI/Buttons/Buttons';
import DocumentDisplay from '../General/DocumentDisplay';
import FileUpload  from './FileUpload';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';

class ManagedDocument extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            name: '',
            type: '',
            types: [],
            category: '',
            categorySel: null,
            categories: [],
            status: '',
            statuses: [],
            certificationMonths: '',
            version: '',
            links: [],
            documents: [],
            author: null,
            signer: null,
            users: [],
            qas: [],
            linkName: '',
            linkLink: '',
            editLink: false,
            linkIndex: -1,
            signed: false,
            locked: false,
            draft: false,
            typeSel: null,
            managedDocuments: [],
            relatedDocuments: [],
            relatedSels: [],
            departments: [],
            depsSel: [],
            departmentList: [],
            changeRequests: [],
            changeRequestsSel: [],
            changeRequestList: [],
            change: '',
            draftChange: '',
            tabKey: 0,
            tabLabels: [{name: 'Document', disabled: false}],
            security: [],
            showVersion: false,
            content: '',
            template: '',
            templates: [],
            templateSel: {},
            showTemplate: false,
            confirmDocOverwrite: false,
            enableSave: true,
            report: '',
            reports: [],
            reportSel: {},
            deleted: false,
            format: '',
            formatSel: null,
            formats: [],
            internal: false,
            contentDocument: null,
            pageNumber: 1,
            numPages: null,
            location: '',
            locationSel: null,
            locations: [],
            deleteOpen: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location, common.cacheValues.department, common.cacheValues.documentCategory,
            common.cacheValues.documentFormat, common.cacheValues.documentStatus, common.cacheValues.documentType, common.cacheValues.documentAuthor,
            common.cacheValues.documentReviewer, common.cacheValues.managedDocument, common.cacheValues.managedDocumentTemplate, common.cacheValues.tenantReport],
            this.props.auth.constants, this.props.auth.constants.objectIds.managedDocument, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        let statusSel = cacheValues.documentStatuses.find(x => x.value === this.props.auth.constants.documentStatuses.inprogress);
        let docs = cacheValues.managedDocuments;
        if(this.props.match.params.id != null && this.props.match.params.id !== ''){
            for(let i = 0; i < docs.length; i++){
                let doc = docs[i];
                if(doc.value === this.props.match.params.id){
                    docs.splice(i, 1);
                    break;
                }
            }
        }
        let locationSel = null;
        let location = '';
        if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
            locationSel = locations.find(x => x.value === this.props.auth.user.defaultLocation);
            location = this.props.auth.user.defaultLocation;
        } else {
            if(locations.length === 1){
                locationSel = locations[0];
                location = locationSel.value;
            }
        }
        this.setState({
            types: cacheValues.documentTypes,
            categories: cacheValues.documentCategories,
            statuses: cacheValues.documentStatuses,
            users: cacheValues.documentAuthors,
            qas: cacheValues.documentReviewers,
            departmentList: cacheValues.departments,
            managedDocuments: docs,
            _id: this.props.match.params.id != null ? this.props.match.params.id : '',
            status: this.props.auth.constants.documentStatuses.inprogress,
            statusSel: statusSel,
            security: [],
            templates: cacheValues.managedDocumentTemplates,
            reports: cacheValues.tenantReports,
            formats: cacheValues.documentFormats,
            locations: locations,
            location: location,
            locationSel: locationSel
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/manageddocs', label: this.props.t('documents'), screen: this.props.auth.screenDefs.ManagedDocument},
                    { path: 'active', label: this.props.t('document'), screen: this.props.auth.screenDefs.ManagedDocument}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/manageddoc/' + this.state._id);
        this.setStateFromRecord(record);
    };

    setStateFromRecord(record){
        let typeSel = this.state.types.find(x => x.value === record.data.type);
        let formatSel = this.state.formats.find(x => x.value === record.data.format);
        let categorySel = record.data.category != null ? this.state.categories.find(x => x.value === record.data.category) : null;
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let report = '';
        let reportSel = {};
        if(record.data.report != null && record.data.report !== '') {
            report = record.data.report;
            reportSel = this.state.reports.find(x => x.value === record.data.report);
        } else {
            if(record.data.format === this.props.auth.constants.documentFormat.internal){
                reportSel = this.state.reports.find(x => x.default === true);
                report = reportSel != null ? reportSel.value  : '';
            }
        }
        let depsSel = [];
        if(record.data.departments != null && record.data.departments.length > 0){
            record.data.departments.forEach(row => {
                let sel = this.state.departmentList.find(x => x.value === row);
                depsSel.push(sel);
            });
        }
        let relatedSels = [];
        if(record.data.relatedDocuments != null && record.data.relatedDocuments.length > 0){
            record.data.relatedDocuments.forEach(row => {
                let val = this.state.managedDocuments.find(x => x.value === row);
                relatedSels.push(val);
            });
        }
        let changeRequestsSel = [];
        if(record.data.changeRequests != null && record.data.changeRequests.length > 0){
            record.data.changeRequests.forEach(row => {
                let sel = record.data.changeRequestList.find(x => x.value === row);
                changeRequestsSel.push(sel);
            });
        }
        let deleted = record.data.status === this.props.auth.constants.documentStatuses.deleted;
        let signed = record.data.status !== this.props.auth.constants.documentStatuses.inprogress;
        let locked = record.data.status === this.props.auth.constants.documentStatuses.approved;
        let locationSel = this.state.locations.find(x => x.value === record.data.location);
        let draft = record.data.draftChange != null && record.data.draftChange !== '';
        let tabLabels = draft ?
                [{name: this.props.t('document'), disabled: false}, {name: this.props.t('draft'), disabled: false}, {name: this.props.t('history'), disabled: false}] :
                [{name: this.props.t('document'), disabled: false}, {name: this.props.t('history'), disabled: false}];

        this.setState({
            number: record.data.number,
            name: record.data.name,
            type: record.data.type,
            typeSel: typeSel,
            category: record.data.category,
            categorySel: categorySel,
            status: record.data.status,
            statusSel: statusSel,
            certificationMonths: record.data.certificationMonths,
            version: record.data.version,
            links: record.data.links,
            documents: record.data.documents,
            author: record.data.author,
            signer: record.data.signer,
            signed: signed,
            locked: locked,
            changeRequests: record.data.changeRequests,
            changeRequestsSel: changeRequestsSel,
            changeRequestList: record.data.changeRequestList,
            relatedSels: relatedSels,
            relatedDocuments: record.data.relatedDocuments,
            departments: record.data.departments,
            depsSel: depsSel,
            draft: draft,
            change: record.data.change,
            draftChange: record.data.draftChange,
            tabLabels: tabLabels,
            tabKey: 0,
            showVersion: false,
            content: record.data.content,
            report: report,
            reportSel: reportSel,
            deleted: deleted,
            format: record.data.format,
            formatSel: formatSel,
            internal: record.data.format === this.props.auth.constants.documentFormat.internal ? true : false,
            contentDocument: record.data.contentDocument,
            location: record.data.location,
            locationSel: locationSel,
            deleteOpen: false
        }, () => {
            const crumbs = [
                { path: '/#/manageddocs', label: this.props.t('documents'), screen: this.props.auth.screenDefs.ManagedDocument},
                { path: 'active', label: record.data.name + ' - ' + record.data.number, screen: this.props.auth.screenDefs.ManagedDocument}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    changeValue = (e) => {
        let name = e.target.name;
        let value = e.target.value
        if(name === "version"){
            if(isNaN(value)){
                return;
            }
        }
        this.setState({[name]: value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    }

    changeAutoComplete = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(data === 'format'){
            if(newValue != null && newValue.value === this.props.auth.constants.documentFormat.internal){
                this.setState({internal: true});
            } else {
                this.setState({internal: false});
            }
        }
    };

    changeRelatedDocs = (e, newValue) => {
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            relatedSels: newValue,
            relatedDocuments: ids
        })
    };

    changeMultiAuto = (name, data) => (e, newValue) => {
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            [name]: newValue,
            [data]: ids
        })
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async (e) => {
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }

        let data = {
            _id: this.state._id,
            number: this.state.number,
            name: this.state.name,
            type: this.state.type,
            certificationMonths: this.state.certificationMonths,
            category: this.state.category,
            departments: this.state.departments,
            status: this.state.status,
            version: this.state.version,
            links: this.state.links,
            relatedDocuments: this.state.relatedDocuments,
            content: this.state.content,
            report: this.state.report,
            format: this.state.format,
            location: this.state.location
        };
        try{
            let result = await axios.post('/api/manageddoc', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/manageddoc/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    delete = () => {
        if(!this.state.enableSave && !this.state.security.delete)
            return;
        this.setState({ enableSave: false }, () => this._delete());
    };

    _delete = async (e) => {
        try {
            let result = await axios.delete('/api/manageddoc/' + this.state._id);
            this.setState({enableSave: true, deleteOpen: false});
            this.props.history.goBack();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true, deleteOpen: false }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    };

    validate = (final) => {
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.number == null || state.number === '')
            errors[t('number')] = t('required');
        if(state.name == null || state.name === '')
            errors[t('name')] = t('required');
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(state.status == null || state.status === '')
            errors[t('status')] = t('required');
        if(state.version == null || state.version === '')
            errors[t('version')] = t('required');
        if(final === true && this.state.format === this.props.auth.constants.documentFormat.internal){
            if(this.state.content == null || this.state.content === '')
                errors[t('content')] = t('required');
        }
        if(final === true && this.state.format !== this.props.auth.constants.documentFormat.internal){
            if(this.state.contentDocument == null)
                errors[t('contentDocument')] = t('required');
        }
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.departments == null || state.departments.length === 0)
            errors[t('departments')] = t('required');
        if(state.format == null || state.format === '')
            errors[t('format')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    newLink = (e) => {
        this.setState({editLink: true});
    };

    clearLink = (e) => {
        this.setState({
            editLink: false,
            linkIndex: -1,
            linkName: '',
            linkLink: ''
        });
    };

    editLink(index){
        var row = this.state.links[index];
        this.setState({
            linkIndex: index,
            editLink: true,
            linkName: row.name,
            linkLink: row.link
        })
    };

    saveLink = (e) => {
        const valid = this.validateLink();
        if(!valid)
            return;
        let links = this.state.links;
        if(this.state.linkIndex > -1){
            let link = links[this.state.linkIndex];
            link.name = this.state.linkName;
            link.link = this.state.linkLink;
        } else {
            let link = {
                name: this.state.linkName,
                link: this.state.linkLink
            };
            links.push(link);
        }
        this.setState({links: links});
        this.clearLink();
    }

    validateLink(){
        let errors = {};
        const t = this.props.t;
        if(this.state.linkName == null || this.state.linkName === '')
            errors[t('linkName')] = t('required');
        if(this.state.linkLink == null || this.state.linkLink === '')
            errors[t('linkLink')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteLink = (e) => {
        if(this.state.linkIndex != null && this.state.linkIndex > -1){
            let links = this.state.links;
            links.splice(this.state.linkIndex, 1);
            this.setState({links: links});
        }
        this.clearLink();
    }

    setSignature = () => {
        this.loadRecord();
    }

    versionToggle = (e) => {
        this.setState({showVersion: !this.state.showVersion});
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    performDraft = (type) => (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._performDraft(type, e));
    }

    _performDraft = async (type, e) => {
        try {
            let data = {
                versionType: type,
                id: this.state._id
            }
            let result = await axios.post('/api/manageddoccreatedraft/', data);
            this.setState({enableSave: true});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    };

    newTab = (link) => {
        if(link != null && link !== '')
            window.open(link);
        return;
    }

    changeRichText = async (index, newText, e) => {
        const content = newText;
        this.setState({content});
    }

    toggleTemplate = () => {
        if(this.state.showTemplate) {
            this.setState({ showTemplate: false, template: '', templateSel: {}, confirmDocOverwrite: false})
        }else {
            this.setState({ showTemplate: true });
        }
    }

    validateTemplate(){
        let errors = {};
        const t = this.props.t;
        if(this.state.template == null || this.state.template === '')
            errors[t('template')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    applyTemplate = (e) => {
        const valid = this.validateTemplate();
        if(!valid)
            return;
        if(this.state.content != null && this.state.content !== '') {
            this.setState({ confirmDocOverwrite: true });
        }else {
            this._applyTemplate();
        }
    }

    _applyTemplate = () => {
        let reportSel = this.state.reports.find(x => x.value === this.state.templateSel.report._id);
        this.setState({ content: this.state.templateSel.content, report: this.state.templateSel.report._id, reportSel: reportSel,
                        confirmDocOverwrite: false, showTemplate: false, templateSel: {}, template: '' });
    }

    clearTemplate = () => {
        this.setState({ templateSel: {}, template: '' });
    }

    render(){
        const { t } = this.props;
        const errors = this.props.errors;
        const signed = this.state.signed;
        const deleted = this.state.deleted;
        const internal = this.state.internal;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.managedDocument}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                        constants={this.props.auth.constants}
                        //optionsAvailable={this.props.optionMenu.options.length !== 0}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                    <TabPanel value={this.state.tabKey} index={0}>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.statusSel != null ? this.state.statusSel.label : ''}  name="status"
                                size="medium" fullWidth disabled={true} label={t('status')} data-cy='status'/>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.number} onChange={this.changeValue} name="number"
                                error={errors[t('number')] != null ? true : false} inputProps={{maxLength: 20}}
                                size="medium" disabled={signed} helperText={errors[t('number')]} data-cy='number'
                                label={t('number')} fullWidth/>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                error={errors[t('name')] != null ? true : false} data-cy='name'
                                size="medium" fullWidth disabled={signed} helperText={errors[t('name')]}
                                label={t('name')}/>
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <AutoCompleteField
                                    value={this.state.locationSel}
                                    options={this.state.locations}
                                    onChange={this.changeAutoComplete('locationSel', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]}
                                    label={t('location')}
                                    disabled={signed}
                                    data-cy='location'
                                />
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <AutoCompleteField
                                    value={this.state.formatSel}
                                    options={this.state.formats}
                                    onChange={this.changeAutoComplete('formatSel', 'format')}
                                    error={errors[t('format')] != null ? true : false}
                                    helperText={errors[t('format')]}
                                    label={t('format')}
                                    disabled={signed}
                                    data-cy='format'
                                />
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <TextField value={this.state.version} onChange={this.changeValue} name="version"
                                error={errors[t('version')] != null ? true : false} data-cy='version'
                                size="medium" fullWidth disabled={signed} helperText={errors[t('version')]}
                                label={t('version')}/>
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <TextField value={this.state.certificationMonths} onChange={this.changeNumber} name="certificationMonths"
                                error={errors[t('certificationMonths')] != null ? true : false} data-cy='certificationMonths'
                                size="medium" fullWidth disabled={signed} helperText={errors[t('certificationMonths')]}
                                label={t('certMonths')}/>
                            </Grid>
                            <Grid item sm={11}>
                                <AutoCompleteField
                                    multiple={true}
                                    stayOpen={true}
                                    value={this.state.depsSel}
                                    options={this.state.departmentList}
                                    onChange={this.changeMultiAuto('depsSel', 'departments')}
                                    error={errors[t('departments')] != null ? true : false}
                                    helperText={errors[t('departments')]}
                                    label={t('departments')}
                                    disabled={signed}
                                    data-cy='departments'
                                />
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <AutoCompleteField
                                    value={this.state.typeSel}
                                    options={this.state.types}
                                    onChange={this.changeAutoComplete('typeSel', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]}
                                    label={t('type')}
                                    disabled={signed}
                                    data-cy='type'
                                />
                            </Grid>
                            <Grid item xs={4} md={2}>
                                <AutoCompleteField
                                    value={this.state.categorySel}
                                    options={this.state.categories}
                                    onChange={this.changeAutoComplete('categorySel', 'category')}
                                    error={errors[t('category')] != null ? true : false}
                                    helperText={errors[t('category')]}
                                    label={t('category')}
                                    disabled={signed}
                                    data-cy='category'
                                />
                            </Grid>
                            {this.state.format === this.props.auth.constants.documentFormat.internal &&
                                <Aux>
                                    <Grid item xs={4} md={3}>
                                        <AutoCompleteField
                                            value={this.state.reportSel}
                                            options={this.state.reports}
                                            onChange={this.changeAutoComplete('reportSel', 'report')}
                                            error={errors[t('report')] != null ? true : false}
                                            helperText={errors[t('report')]}
                                            label={t('reportSettings')}
                                            disabled={signed}
                                            data-cy='report'
                                        />
                                    </Grid>
                                    {!signed &&
                                        <Grid item xs={4} md={2}>
                                            <Tooltip title={t('addTemplate')} placement='right'>
                                                <IconButton aria-label={t('addTemplate')} onClick={this.toggleTemplate} data-cy='addTemplate'>
                                                    <NoteAddTwoToneIcon color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                            <ModalDialog
                                                buttonStack={[
                                                    <Button color="secondary" size="large" onClick={this.clearTemplate} data-cy='clear'>{t('clear')}</Button>,
                                                    <Button color="primary" size="large" onClick={this.applyTemplate} data-cy='apply'>{t('apply')}</Button>
                                                ]}
                                                title={t('addTemplate')} toggleDialog={this.toggleTemplate} dialogStatus={this.state.showTemplate} fullWidth maxWidth='xs'>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <AutoCompleteField
                                                            value={this.state.templateSel}
                                                            options={this.state.templates}
                                                            onChange={this.changeAutoComplete('templateSel', 'template')}
                                                            error={errors[t('template')] != null ? true : false}
                                                            helperText={errors[t('template')]}
                                                            label={t('template')}
                                                            disabled={signed}
                                                            data-cy='template'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </ModalDialog>
                                            <Confirmation
                                                content={t('confirmDocumentOverwrite')}
                                                no={this.toggleTemplate}
                                                noButton={t('no')}
                                                yes={this._applyTemplate}
                                                yesButton={t('yes')}
                                                title={t('confirmDocumentOverwriteTitle')}
                                                open={this.state.confirmDocOverwrite}
                                            />
                                        </Grid>
                                    }
                                </Aux>
                            }
                            <Grid item sm={11}>
                                <AutoCompleteField
                                    multiple={true}
                                    stayOpen={true}
                                    value={this.state.relatedSels}
                                    options={this.state.managedDocuments}
                                    onChange={this.changeRelatedDocs}
                                    error={errors[t('relatedDocuments')] != null ? true : false}
                                    helperText={errors[t('relatedDocuments')]}
                                    label={t('relatedDocuments')}
                                    disabled={signed}
                                    data-cy='relatedDocuments'
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={3}>
                            <Grid item xs={12}>
                                <SubSectionHeading add={this.newLink} addTitle={t('addLink')} addSecurity={signed} title={t('links')}/>
                                {this.state.links != null && this.state.links.length > 0 &&
                                    <Aux>
                                        <TableContainer>
                                            <Table aria-label="Links">
                                                <TableHead>
                                                    <TableRow key="tableHeader">
                                                        <TableCell>{t('name')}</TableCell>
                                                        <TableCell>{t('linkUrl')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.links.map((row, i) =>
                                                        <TableRow key={row._id}>
                                                            <TableCell>
                                                                <TextOnlyClickButton enabled={true} click={() => this.editLink(i)} name={row.name}/>
                                                            </TableCell>
                                                            <TableCell>{row.link}</TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Aux>
                                }
                                <ModalDialog
                                    buttonStack={[
                                        <Button sx={(theme) => ({ color: theme.palette.error.main })} size="large" onClick={this.deleteLink} data-cy='delete'>{t('delete')}</Button>,
                                        <Button color="secondary" size="large" onClick={this.clearLink} data-cy='clearButton'>{t('clear')}</Button>,
                                        <Button color="primary" size="large" onClick={this.saveLink} data-cy='save'>{t('save')}</Button>
                                    ]}
                                    title={t('addLink')} toggleDialog={this.clearLink} dialogStatus={this.state.editLink} fullWidth maxWidth='md'>
                                    <Grid container spacing={3}>
                                        <Grid item xs={5}>
                                            <TextField value={this.state.linkName} onChange={this.changeValue} name="linkName"
                                            error={errors[t('linkName')] != null ? true : false} label={t('name')}
                                            size="medium" fullWidth disabled={signed} helperText={errors[t('linkName')]} data-cy='linkName'/>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField value={this.state.linkLink} onChange={this.changeValue} name="linkLink"
                                            error={errors[t('linkLink')] != null ? true : false} label={t('linkUrl')}
                                            size="medium" fullWidth disabled={signed} helperText={errors[t('linkLink')]} data-cy='linkLink'/>
                                        </Grid>
                                    </Grid>
                                </ModalDialog>
                                <br />
                            </Grid>
                        </Grid>
                        {this.state.reportSel != null && this.state.reportSel !== '' &&
                         this.state.reportSel.data != null && this.state.reportSel.data !== '' &&
                            <Grid item xs={12}>
                                <SubSectionHeading title={t('reportSettings')}/>
                                {this.state.reportSel.data.margin != null && this.state.reportSel.data.margin !== '' &&
                                    <FormControl sx={{width: '100%'}}>
                                        <FormLabel>{t('margins')}</FormLabel>
                                            <Grid container spacing={3}>
                                                <Grid item>
                                                    <FormControl>
                                                        <FormLabel>{t('topMargin')}</FormLabel>
                                                        {this.state.reportSel.data.margin.top != null && this.state.reportSel.data.margin.top !== '' && this.state.reportSel.data.margin.top}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <FormControl>
                                                        <FormLabel>{t('bottomMargin')}</FormLabel>
                                                        {this.state.reportSel.data.margin.bottom != null && this.state.reportSel.data.margin.bottom !== '' && this.state.reportSel.data.margin.bottom}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <FormControl>
                                                        <FormLabel>{t('leftMargin')}</FormLabel>
                                                        {this.state.reportSel.data.margin.left != null && this.state.reportSel.data.margin.left !== '' && this.state.reportSel.data.margin.left}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <FormControl>
                                                        <FormLabel>{t('rightMargin')}</FormLabel>
                                                        {this.state.reportSel.data.margin.right != null && this.state.reportSel.data.margin.right !== '' && this.state.reportSel.data.margin.right}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                    </FormControl>
                                }
                                <br /><Divider /><br />
                                <FormControl sx={{width: '100%'}}>
                                    <FormLabel>{t('header')}</FormLabel>
                                    {this.state.reportSel.data.header.header != null && this.state.reportSel.data.header.header !== '' &&
                                        <RichText>{this.state.reportSel.data.header.header}</RichText>
                                    }
                                </FormControl>
                                <br /><Divider /><br />
                                <FormControl sx={{width: '100%'}}>
                                    <FormLabel>{t('footer')}</FormLabel>
                                    {this.state.reportSel.data.header.footer != null && this.state.reportSel.data.header.footer !== '' &&
                                        <RichText>{this.state.reportSel.data.header.footer}</RichText>
                                    }
                                </FormControl>
                                <br /><Divider /><br />
                            </Grid>
                        }
                        {internal === true &&
                            <Grid item container xs={12} spacing={3}>
                                <Grid item xs={12}>
                                    <SubSectionHeading title={t('documentContent')} />
                                    {this.state.status === this.props.auth.constants.documentStatuses.inprogress ?
                                        <RichTextField
                                            disabled={signed}
                                            id='documentContent'
                                            key='documentContent'
                                            index={0}
                                            value={this.state.content != null ? this.state.content : ''}
                                            errors={errors[t('content')] != null ? errors[t('content')] : null}
                                            textAreaName='documentContent-richText'
                                            height={900}
                                            changeRichText={this.changeRichText}
                                            data-cy='documentContent'
                                        /> :
                                        <RichText>{this.state.content != null ? this.state.content : ''}</RichText>
                                    }
                                </Grid>
                            </Grid>
                        }
                        {internal === false &&
                            <Aux>
                                <SubSectionHeading title={t('contentDocument')}/>
                                {this.state.contentDocument != null &&
                                    <Aux>
                                        <br/>
                                        <Grid container spacing={3}>
                                            <Grid item xs={10} sm={5}>
                                                <FormControl>
                                                    <FormLabel>{this.state.contentDocument.name}</FormLabel>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Aux>
                                }
                                {!signed && this.state._id != null && this.state._id !== '' &&
                                    <FileUpload callback={this.loadRecord}
                                        record={this.state._id}
                                        _id={this.state.contentDocument != null ? this.state.contentDocument._id : ''}
                                        version={this.state.contentDocument != null ? this.state.contentDocument.version : ''}
                                        property='contentDocument'
                                        object={this.props.auth.constants.objectIds.managedDocument}
                                    />
                                }
                                <br/>
                                <br/>
                                {this.state.contentDocument != null && this.state.contentDocument._id != null &&
                                    <Aux>
                                        <DocumentDisplay document={this.state.contentDocument}/>
                                    </Aux>
                                }
                            </Aux>
                        }
                        <br/>
                        {this.state._id != null && this.state._id !== '' && !deleted &&
                            <Aux>
                                {this.state.author != null &&
                                    <SubSectionTitle title={t('author')}/>
                                }
                                <Signature users={this.state.users} name={t('author')} status={this.state.status} validate={this.validate}
                                    title={t('authorSignature')} setId={this.setSignature} object={this.state._id} signature={this.state.author}
                                    objectType={this.props.auth.constants.objectIds.managedDocument} type={this.props.auth.constants.signatureTypes.user}
                                    user={this.props.auth.user.id} data-cy='authorSignature'
                                />
                                <br/>
                            </Aux>
                        }
                        {(this.state.status === this.props.auth.constants.documentStatuses.complete ||
                            this.state.status === this.props.auth.constants.documentStatuses.approved) && !deleted &&
                            <Aux>
                                <br/>
                                <br/>
                                {this.state.author != null &&
                                    <SubSectionTitle title={t('reviewer')}/>
                                }
                                <Signature users={this.state.qas} name={t('reviewer')} status={this.state.status}
                                    title={t('reviewerSignature')} setId={this.setSignature} object={this.state._id} signature={this.state.signer}
                                    objectType={this.props.auth.constants.objectIds.managedDocument} type={this.props.auth.constants.signatureTypes.signer}
                                    user={this.props.auth.user.id} data-cy='reviewerSignature'/>
                                <br/>
                            </Aux>
                        }
                        <Grid container spacing={3}>
                            <Grid item sm={12}>
                                {!signed && !deleted &&
                                    <Aux>
                                        <SaveButton saveClick={this.save} enabled={this.state.enableSave} data-cy='save'/>
                                    </Aux>
                                }
                                {this.state._id != null && this.state._id !== '' &&
                                    <Aux>
                                        <Button variant="contained" color="secondary" size="small" data-cy='view'
                                        href={'/#/printview/' + this.props.auth.constants.objectIds.managedDocument + '/' + this.state._id}>
                                            {t('view')}
                                        </Button>&nbsp;&nbsp;
                                    </Aux>
                                }
                                {!this.state.draft && signed && !deleted &&
                                    <Aux>
                                        <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.managedDocument} callback={this.setSignature}
                                        isDocument={true} data-cy='unlock'/>&nbsp;&nbsp;
                                    </Aux>
                                }
                                {!this.state.draft && signed && !deleted &&
                                    <Aux>
                                        <Button variant="contained" color="secondary" size="small" onClick={this.versionToggle} data-cy='newDraft'>{t('newDraft')}</Button>&nbsp;&nbsp;
                                    </Aux>
                                }
                                {this.props.permission.delete && this.state._id != null && this.state._id !== '' && !deleted &&
                                    <Aux>
                                        <DeleteButton deleteClick={this.deleteToggle} enabled={this.props.permission.delete} data-cy='delete'/>
                                    </Aux>
                                }
                            </Grid>
                        </Grid>
                        {this.state._id != null && this.state._id !== '' &&
                            <div>
                                <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.managedDocument}/>
                                <ObjectLog id={this.state._id}/>
                            </div>
                        }
                    </TabPanel>
                    {this.state.draft &&
                        <TabCanvas value={this.state.tabKey} index={1}>
                            <DraftDocument id={this.state.draftChange} reload={this.loadRecord}/>
                        </TabCanvas>
                    }
                    {this.state._id != null && this.state._id !== '' &&
                        <TabPanel value={this.state.tabKey} index={this.state.draft ? 2 : 1}>
                            <DocumentVersions document={this.state._id}/>
                        </TabPanel>
                    }
                    <Dialog open={this.state.showVersion} onClose={this.versionToggle} maxWidth="sm" fullWidth={false}>
                        <DialogTitle>
                            <SectionTitle title={t('versionType')}/>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item sm={3}>
                                    <Button variant="outlined" color="primary" size="small" data-cy='minor'
                                    onClick={this.performDraft(this.props.auth.common.versionChanges.minor)}>{t('minor')}</Button>&nbsp;&nbsp;
                                </Grid>
                                <Grid item sm={3}>
                                    <Button variant="outlined" color="primary" size="small" data-cy='major'
                                    onClick={this.performDraft(this.props.auth.common.versionChanges.major)}>{t('major')}</Button>&nbsp;&nbsp;
                                </Grid>
                                <Grid item sm={3}><Button variant="outlined" color="primary" size="small"
                                onClick={this.versionToggle} data-cy='cancelVersion'>{t('cancel')}</Button></Grid>
                            </Grid>
                            <br/>
                            <br/>
                        </DialogContent>
                    </Dialog>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.delete()} open={this.state.deleteOpen} />
                }
            </Aux>
        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    optionMenu: state.optionMenu,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ManagedDocument));
