import React from 'react';
import { NumericFormat } from 'react-number-format';
import ConversionHelper from '../../../helpers/conversionHelper';
import ReportTitle from '../../UI/Typography/ReportTitle';
import ReportSectionTitle from '../../UI/Typography/ReportSectionTitle';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';

import ActivityRecord from './ActivityRecord';

const boxStyle = {
    margin: '8px',
    width: '100%'
}

const thStyle = {
    borderBottom: '2px solid rgb(224, 224, 224)', 
    fontSize: '9pt', 
    textAlign: 'center'
}

const tdStyle = { 
    border: '0.5px solid rgb(224, 224, 224)', 
    fontSize: '8pt', 
    padding: '5px',
}

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse'
}

export default function roomFeedingTemplate(props) {
    const { batchNumber, dateFormat, record, sequence, t } = props;
    return (
        <div style={{ width: '8.5in', pageBreakBefore: 'always' }}>
            <div style={boxStyle}>
                <ReportTitle 
                    title={
                        (sequence != null && sequence !== '' ? 
                            sequence + '. ' + t('roomFeeding') + ': ' : t('roomFeeding')
                        )
                    }
                />
            </div>
            <div style={{ ...boxStyle, display: 'block' }}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '200px' }}>{t('number')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('date')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('status')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('location')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.number != null && record.number !== '' ? record.number : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.date != null ?
                                    <DateDisplay value={record.date} format={dateFormat}/> : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.status != null && record.status !== '' ? record.status : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.location != null && record.location !== '' ? record.location : t('na')}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ ...boxStyle, display: 'block' }}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '100px'}}>{t('type')}</th>
                            <th style={{ ...thStyle, width: '100px'}}>{t('pH')}</th>
                            <th style={{ ...thStyle, width: '100px'}}>{t('ec')}</th>
                            <th style={{ ...thStyle, width: '100px'}}>{t('startTime')}</th>
                            <th style={{ ...thStyle, width: '100px'}}>{t('endTime')}</th>
                            <th style={{ ...thStyle, width: '100px'}}>{t('duration')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.type != null && record.type !== '' ? record.type : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.ph != null && record.ph !== '' ?
                                    record.ph : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.ec != null && record.ec !== '' ?
                                    record.ec : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.startTime != null ?
                                    <DateDisplay value={record.startTime} format={dateFormat + ' hh:mm a'}/> : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.stopTime != null ?
                                    <DateDisplay value={record.stopTime} format={dateFormat + ' hh:mm a'}/> : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.duration != null && record.duration !== '' ?
                                    <NumericFormat displayType={'text'} value={record.duration} thousandSeparator={true}
                                    decimalScale={3} suffix={' ' + t('minutes')}/> : t('na')
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={boxStyle}>
            <div style={{ width: '50%', display: 'inline-block', float: 'left', padding: '8px' }}>
                    <div style={{ width: '100%', display: 'block' }}>
                        <ReportSectionTitle title={t('performedBy')} />
                    </div>
                    {record.users != null && record.users !== '' && record.users.length > 0 ?
                        <div style={{ fontSize: '9pt', padding: '5px' }}>
                            {record.users.map((user, i) => {
                                if(i === record.users.length - 1)
                                    return user.name
                                else
                                    return user.name + ', '
                                })
                            }
                        </div> : ''                             
                    }
                </div>
                <div style={{ width: '50%', display: 'inline-block', float: 'right', padding: '8px' }}>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={{ ...thStyle, width: '100px'}}>{t('room')}</th>
                                <th style={{ ...thStyle, width: '100px'}}>{t('batches')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {record.roomBatches != null && record.roomBatches.length > 0 ?
                                record.roomBatches.map((roomBatch, i) => 
                                    <tr key={'roomBatch-' + i}>
                                        <td style={{ ...tdStyle, textAlign: 'center' }}>
                                            {roomBatch.room != null && roomBatch.room !== '' ? roomBatch.room : t('na')}
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'center' }}>
                                            {roomBatch.batches != null && roomBatch.batches !== '' && roomBatch.batches.length > 0 ? 
                                                roomBatch.batches.map((batch, j) => {
                                                    if(j === roomBatch.batches.length - 1) {
                                                        return <span style={{ backgroundColor: batchNumber != null &&
                                                                            batchNumber === batch.number ?
                                                                            'yellow' : null,
                                                                            fontWeight: batchNumber != null && 
                                                                            batchNumber === batch.number ? 
                                                                            'bold' : null 
                                                                }}>{batch.number + ' (' + batch.count + ')'}</span>
                                                    }else {
                                                        return (
                                                            <>
                                                                <span style={{ backgroundColor: batchNumber != null &&
                                                                                batchNumber === batch.number ?
                                                                                'yellow' : null,
                                                                                fontWeight: batchNumber != null && 
                                                                                batchNumber === batch.number ? 
                                                                                'bold' : null 
                                                                }}>{batch.number + ' (' + batch.count + ')'}</span>{', '}
                                                            </>
                                                        );
                                                    }
                                                }) : record.batches != null && record.batches !== '' && record.batches.length > 0 ?
                                                    record.batches.map((batch, j) => {
                                                        if(j === record.batches.length - 1) {
                                                            return <span style={{ backgroundColor: batchNumber != null &&
                                                                                batchNumber === batch.number ?
                                                                                'yellow' : null,
                                                                                fontWeight: batchNumber != null && 
                                                                                batchNumber === batch.number ? 
                                                                                'bold' : null 
                                                                    }}>{batch.number}</span>
                                                        }else {
                                                            return (
                                                                <>
                                                                    <span style={{ backgroundColor: batchNumber != null &&
                                                                                    batchNumber === batch.number ?
                                                                                    'yellow' : null,
                                                                                    fontWeight: batchNumber != null && 
                                                                                    batchNumber === batch.number ? 
                                                                                    'bold' : null 
                                                                    }}>{batch.number}</span>{', '}
                                                                </>
                                                            );
                                                        }
                                                }) : t('na')
                                            }
                                        </td>
                                    </tr>
                                ) : 
                                <tr>
                                    <td style={{ ...tdStyle, textAlign: 'center' }} colSpan={2}>{t('noBatches')}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <hr style={{ width: '80%', margin: '16px auto', clear: 'both' }} />
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('inputs')} />
                </div>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={thStyle}>{t('item')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('recipe')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('lot')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('container')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('quantity')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {record.feeds != null && record.feeds.length > 0 ?
                            record.feeds.map((input, i) => 
                                <tr key={'roomFeedingInput-' + i}>
                                    <td style={tdStyle}>
                                        {input.item != null && input.item !== '' ? input.item : t('na')}
                                    </td>
                                    <td style={{ ...tdStyle, textAlign: 'center' }}>
                                        {input.feedingLot != null && input.feedingLot !== '' &&
                                         input.feedingLot.recipe != null && input.feedingLot.recipe !== '' ? 
                                            input.feedingLot.recipe : t('na')
                                        }
                                    </td>
                                    <td style={{ ...tdStyle, textAlign: 'center' }}>
                                        {input.lot != null && input.lot !== '' ? 
                                            input.lot : t('na')
                                        }
                                    </td>
                                    <td style={{ ...tdStyle, textAlign: 'center' }}>
                                        {input.container != null && input.container !== '' ? input.container : t('na')}
                                    </td>
                                    <td style={{ ...tdStyle, textAlign: 'right' }}>
                                        {input.quantity != null && input.quantity !== '' && 
                                         input.uom != null && input.uom !== '' ? 
                                            <NumericFormat displayType={'text'} value={input.quantity} thousandSeparator={true}
                                            decimalScale={3} suffix={input.uom}/> : t('na')
                                        }
                                    </td>
                                </tr>
                            ) : 
                            <tr key='noRoomFeedingInputs'>
                                <td style={{ ...tdStyle, textAlign: 'center' }} colSpan={5}>{t('noInputs')}</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            {record.sops != null && record.sops.length > 0 &&
                record.sops.map((sop, i) => 
                    <div style={boxStyle}>
                        <ActivityRecord dateFormat={dateFormat} record={sop.workRecord} sop={sop.sop} t={t} 
                        sequence={sequence != null && sequence !== '' ? 
                            sequence + '.' + ConversionHelper.numberToRomanConverter(i + 1) : ConversionHelper.numberToLetterConverter(i + 1)
                        } />
                    </div>
                )
            }
        </div>
    );
}