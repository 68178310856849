import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import isEmpty from '../../is-empty';
import BinContents from './BinContents';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from "@mui/material";
import ValueHelper from '../../helpers/valueHelper';
import { Link } from 'react-router-dom';
import Panel from '../UI/Panel/Panel';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import Switch from '../UI/Switch/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import ConfirmDelete from '../General/ConfirmDelete';

class Bin extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            code: '',
            description: '',
            location: '',
            locSel: null,
            locations: [],
            quarantine: false,
            room: '',
            roomSel: null,
            rooms: [],
            locked: false,
            vendor: '',
            vendorSel: null,
            vendors: [],
            tabKey: 0,
            tabLabels: [
                {name: this.props.t('bin'), disabled: false}
            ],
            security: [],
            enableSave: true,
            isNew: false,
            type: '',
            typeSel: null,
            types: [],
            hide: false,
            deleteOpen: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.vendor, common.cacheValues.location, common.cacheValues.defaultRoom, common.cacheValues.binType],
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let vendors = cacheValues.vendors;
        let locations = cacheValues.locations;
        let location = '';
        let locSel = null;
        if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
            location = this.props.auth.user.defaultLocation;
            locSel = locations.find(x => x.value === location);
        } else {
            if(locations.length === 1){
                locSel = locations[0];
                location = locSel.value;
            }
        }
        let isNew = (this.props.match.params.id == null || this.props.match.params.id === '') && this.props.permission.create;
        this.setState({
            rooms: cacheValues.rooms,
            locations: locations,
            location: location,
            locSel: locSel,
            types: cacheValues.binTypes,
            vendors: vendors,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            security: this.props.permission,
            isNew: isNew,
            locked: isNew ? false : true,
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/bins', label: this.props.t('bins'), screen: this.props.auth.screenDefs.BinList},
                    { path: 'active', label: this.props.t('bin'), screen: this.props.auth.screenDefs.Bin}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/bin/' + this.state._id);
        let locSel = ValueHelper.valueSelect(record.data.location, this.state.locations);
        let roomSel = ValueHelper.valueSelect(record.data.room, this.state.rooms);
        let vendorSel = ValueHelper.valueSelect(record.data.vendor, this.state.vendors);
        let typeSel = ValueHelper.valueSelect(record.data.type, this.state.types);
        let tabLabels = [
            {name: this.props.t('bin'), disabled: false},
            {name: this.props.t('contents'), disabled: false}
        ];
        this.setState({
            code: record.data.code,
            description: record.data.description,
            location: record.data.location,
            locSel: locSel,
            quarantine: record.data.quarantine,
            room: record.data.room,
            roomSel: roomSel,
            vendor: record.data.vendor,
            vendorSel: vendorSel,
            tabLabels: tabLabels,
            type: record.data.type,
            typeSel: typeSel,
            hide: record.data.hide === true ? true : false,
            deleteOpen: false
        }, () => {
            const crumbs = [
                { path: '/#/bins', label: this.props.t('bins'), screen: this.props.auth.screenDefs.BinList},
                { path: 'active', label: this.props.t('bin') + ' - ' + record.data.code, screen: this.props.auth.screenDefs.Bin}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeValue = (e) => {
        var name = e.target.name;
        var value = e.target.value
        this.setState({[name]: value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    unlock = (e) => {
        this.setState({locked: !this.state.locked});
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            description: this.state.description,
            location: this.state.location,
            quarantine: this.state.quarantine,
            room: this.state.room,
            vendor: this.state.vendor,
            _id: this.state._id,
            type: this.state.type,
            code: this.state.code,
            hide: this.state.hide
        };
        try {
            let result = await axios.post('/api/bin', data);
            this.props.history.replace('/bin/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    };

    validate = () => {
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.description == null || state.description === '')
            errors[t('description')] = t('required');
        if(state.room != null && state.room !== '' && (state.location == null || state.location === ''))
            errors[t('location')] = t('required');
        if(state.room != null && state.room !== '' && state.vendor != null && state.vendor !== '')
            errors[t('room')] = t('roomVendor2');
        if((state.room == null || state.room === '') && (state.vendor == null || state.vendor === ''))
            errors[t('room')] = t('roomVendor');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e))
    }

    _deleteClick = async (e) => {
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/bin/' + this.state._id);
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            }
        } else {
            this.setState({enableSave: true, deleteOpen: false});
            this.props.history.goBack();
        }
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    render(){
        const errors = this.props.errors;
        const locked = this.state.locked;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <TextField value={this.state.code} onChange={this.changeValue} name="code"
                                size="medium" fullWidth={true} label={t('code')} data-cy="code"
                                error={errors[t('code')] != null ? true : false} helperText={errors[t('code')]}/>
                            }
                            {locked &&
                                <FormControl data-cy="code">
                                    <FormLabel>{t('code')}</FormLabel>
                                    {this.state.code}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {!locked &&
                                <TextField value={this.state.description} onChange={this.changeValue} name="description"
                                size="medium" fullWidth={true} disabled={locked} label={t('description')} required data-cy="description"
                                error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('description') + '*'}</FormLabel>
                                    {this.state.description}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.locSel}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locSel', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]}
                                    label={t('location')}
                                    disabled={locked}
                                    required
                                    data-cy="location"
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('location') + '*'}</FormLabel>
                                    {this.state.locSel != null &&
                                        <Link to={'/locationdetail/' + this.state.locSel.value}>{this.state.locSel.label}</Link>
                                    }
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.roomSel}
                                    options={this.state.rooms}
                                    onChange={this.changeAuto('roomSel', 'room')}
                                    error={errors[t('room')] != null ? true : false}
                                    helperText={errors[t('room')]}
                                    label={t('room')}
                                    disabled={locked}
                                    required
                                    data-cy="room"
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('room') + '*'}</FormLabel>
                                    {this.state.roomSel != null && <Link to={'/room/' + this.state.roomSel.value}>{this.state.roomSel.label}</Link>}
                                </FormControl>
                            }
                        </Grid>
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <AutoCompleteField
                                    value={this.state.typeSel}
                                    options={this.state.types}
                                    onChange={this.changeAuto('typeSel', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]}
                                    label={t('type')}
                                    disabled={locked}
                                    data-cy="type"
                                />
                            </Grid>
                        }
                        {locked && this.state.typeSel != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('type')}</FormLabel>
                                    {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={6} sm={5}>
                                <AutoCompleteField
                                    value={this.state.vendorSel}
                                    options={this.state.vendors}
                                    onChange={this.changeAuto('vendorSel', 'vendor')}
                                    error={errors[t('vendor')] != null ? true : false}
                                    helperText={errors[t('vendor')]}
                                    label={t('vendor')}
                                    disabled={locked}
                                    data-cy="vendor"
                                />
                            </Grid>
                        }
                        {locked && this.state.vendorSel != null &&
                            <Grid item xs={6} sm={5}>
                                <FormControl>
                                    <FormLabel>{t('vendor')}</FormLabel>
                                    {this.state.vendorSel != null ? this.state.vendorSel.label : ''}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={6} sm={3}>
                                <FormControlLabel
                                    disabled={locked}
                                    control={<Switch checked={this.state.quarantine} onChange={this.changeBool('quarantine')} name="quarantine" />}
                                    label={t('quarantine')} 
                                />
                            </Grid>
                        }
                        {locked && this.state.quarantine === true &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('quarantine')}</FormLabel>
                                    {t('yes')}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={6} sm={3}>
                                <FormControlLabel
                                    disabled={locked}
                                    control={<Switch checked={this.state.hide} onChange={this.changeBool('hide')} name="hide" />}
                                    label={t('hide')} 
                                />
                            </Grid>
                        }
                        {locked && this.state.hide === true &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('hide')}</FormLabel>
                                    {t('yes')}
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <br/>
                    {this.state._id != null && this.state._id !== '' &&
                        <BinContents bin={this.state._id}/>
                    }
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={8}>
                            {!locked &&
                                <Aux>
                                    <Button variant="contained" color="primary" size="small" onClick={this.save} disabled={!this.state.enableSave} data-cy="save">
                                        {t('save')}
                                    </Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            <Button variant="contained" color="secondary" size="small" onClick={this.unlock} data-cy="unlock">
                                {locked ? t('unlock') : t('lock')}
                            </Button>&nbsp;&nbsp;
                            {!locked && this.props.permission.delete && this.state._id != null && this.state._id !== '' &&
                                <Button variant="contained" color="secondary" size="small" onClick={this.deleteToggle} data-cy="delete">
                                    {t('delete')}
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Panel>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Bin));
