import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ClearButton, DeleteButton, SaveButton,
         TextOnlyClickButton } from '../UI/Buttons/Buttons';
import ActionBar from '../UI/Buttons/ActionBar';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { Link } from 'react-router-dom';

class ValueTypes extends Component {
    constructor(props){
        super(props);

        this.state = {
            types: [],
            _id: '',
            name: '',
            edit: false,
            search: '',
            offset: 0,
            listSize: 10,
            pageCount: 1,
            editOpen: false,
            orderBy: 'name',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            globalval: false,
            globals: false,
            exportJson: false,
            exportJsonVal: false,
            exportName: '',
            exportNameVal: '',
            security: [],
            parentType: '',
            parentTypeSel: null,
            parentValue: '',
            parentValueSel: null,
            parentValues: [],
            parentTypes: [],
            enableSave: true,
            searchManaged: false,
            clientManaged: false,
            clientAdd: false,
            relatedTypes: [],
            relatedTypesSel: [],
            allTypes: [],
            displayTypes: []
        }

        this.saveClick = this.saveClick.bind(this);
        this.clearClick = this.clearClick.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.newClick = this.newClick.bind(this);
        this.deleteClick = this.deleteClick.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.loadRecords = this.loadRecords.bind(this);
    }

    componentDidMount(){
        let storage = sessionStorage.getItem('valueTypeSearch');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        this.setState({
            offset: storage != null ? storage.offset : 0,
            page: storage != null ? storage.page : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'name',
            ordDir: storage != null ? storage.ordDir : 1,
            search: storage != null ? storage.searchStr : '',
            globals: storage != null ? storage.globals : false,
            exportJson: storage != null ? storage.exportJson : false,
            clientManaged: storage != null ? storage.clientManaged : false,
            exportName: storage != null ? storage.exportName : '',
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('valueTypes'), screen: this.props.auth.screenDefs.ValueTypes}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        })
    }


    saveFilters(){
        let data = {
            offset: this.state.offset,
            page: this.state.page,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            searchStr: this.state.search,
            globals: this.state.globals,
            exportJson: this.state.exportJson,
            clientManaged: this.state.clientManaged,
            exportName: this.state.exportName
        };
        sessionStorage.setItem('valueTypeSearch', JSON.stringify(data));
    }

    loadRecords = async () => {
        const data = {
        }
        let list = await axios.post('/api/typessearch', data);
        let types = list.data.data;
        let showList = types.slice(this.state.offset, this.state.offset + this.state.listSize);
        this.setState({
            types: showList,
            displayTypes: types,
            allTypes: types,
            pageCount: Math.ceil(list.data.totalCount / this.state.listSize),
            totalCount: list.data.totalCount,
            security: this.props.permission,
            parentTypes: list.data.selectList
        }, () => {
            this.setTypes();
        });
    };

    setTypes(){
        this.saveFilters();
        let types = this.state.allTypes;
        types.sort((a, b) => {
            let aName = a.name != null ? a.name.toUpperCase() : '';
            let bName = b.name != null ? b.name.toUpperCase() : '';
            if(this.state.ordDir > 0){
                if(aName < bName)
                    return -1;
                if(aName > bName)
                    return 1;
                return 0;
            } else {
                if(aName < bName)
                    return 1;
                if(aName > bName)
                    return -1;
                return 0;
            }
        });
        if(this.state.search !== ''){
            types = types.filter(x => x.name.toUpperCase().match(".*" + this.state.search.toUpperCase() + '.*'));
        }
        if(this.state.globals){
            types = types.filter(x => x.global === true);
        }
        if(this.state.exportJson){
            types = types.filter(x => x.exportJson === true);
        }
        if(this.state.clientManaged){
            types = types.filter(x => x.clientManaged === true);
        }
        if(this.state.exportName !== ''){
            types = types.filter(x => x.exportName != null && x.exportName.toUpperCase().match(".*" + this.state.exportName.toUpperCase() + '.*'));
        }
        let showList = types.slice(this.state.offset, this.state.offset + this.state.listSize);
        this.setState({displayTypes: types, types: showList, totalCount: types.length});
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(newValue != null && data === 'parentType'){
            let parentValues = await axios.get('/api/values/' + newValue.value);
            this.setState({parentValues: parentValues.data});
        }
    };

    changeMulti = (name, data) => async (e, newValue) => {
        let ids = [];
        if(newValue != null){
            newValue.forEach(x => {
                ids.push(x.value);
            });
        }
        this.setState({[name]: newValue, [data]: ids});
    }

    sortClick(name) {
        let ordDir = this.state.ordDir;
        if(ordDir > 0)
            ordDir = -1;
        else
            ordDir = 1;
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.setTypes();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.setTypes();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.setTypes();
        });
    };

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.listSize);

        this.setState({ offset: offset }, () => {
          this.setTypes();
        });
    };

    searchChange(e){
        this.setState({search: e.target.value, page: 0, offset: 0}, () => {this.setTypes()});
    }

    nameChange(e){
        this.setState({name: e.target.value, page: 0, offset: 0}, () => {this.setTypes()});
    }

    changeValue = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]: value}, () => {
            this.setTypes();
        });
    };

    globalChange = (e) => {
        this.setState({globalval: !this.state.globalval}, () => this.setTypes());
    };

    changeSearchBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]}, () => {
            this.setTypes();
        });
    };

    changeBool = (name) => (e) => {
        if(name === 'clientManaged' || name === 'clientAdd'){
            if(this.state[name] === false && this.state.globalval === true){
                alert('Globals cannot be client managed');
                return;
            }
            if(this.state.clientManaged === false){
                if(name === 'clientAdd' && this.state[name] === false){
                    alert('Not client managed');
                    return;
                }
            }
        }
        this.setState({[name]: !this.state[name]});
    };

    async saveClick(e){
        if(!this.state.enableSave)
            return;
        this.toggleSave();
        e.preventDefault();
        let data = {
            _id: this.state._id,
            name: this.state.name,
            global: this.state.globalval,
            exportJson: this.state.exportJsonVal,
            exportName: this.state.exportNameVal,
            parentType: this.state.parentType,
            parentValue: this.state.parentValue,
            clientManaged: this.state.clientManaged,
            clientAdd: this.state.clientAdd,
            relatedTypes: this.state.relatedTypes
        }
        try {
            let result = await axios.post('/api/typesave', data)
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            this.setState({editOpen: false, _id: '', name: '', globalval: false, exportJsonVal: false, enableSave: true});
            this.loadRecords();
            this.toggleSave();
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
            });
        }
    }

    clearClick(e){
        e.preventDefault();
        this.setState({
            _id: '',
            name: '',
            editOpen: false,
            globalval: false,
            exportJsonVal: false,
            parentType: '',
            parentTypeSel: null,
            parentValue: '',
            parentValueSel: null,
            parentValues: [],
            clientManaged: false,
            clientAdd: false,
            relatedTypes: [],
            relatedTypesSel: []
        })
    }

    async editClick(id){
        let typeResponse = await axios.get('/api/valuetype/' + id);
        let type = typeResponse.data;
        let parentTypeSel = type.parentType != null ? this.state.parentTypes.find(x => x.value === type.parentType) : null;
        let parentValueSel = null;
        let parentValues = [];
        let parentValue = ''
        if(parentTypeSel != null){
            let tenantParent = type.parentValues.find(x => x.tenant === this.props.auth.user.tenant);
            let response = await axios.get('/api/values/' + type.parentType);
            parentValues = response.data;
            if(tenantParent != null){
                parentValueSel = parentValues.find(x => x.value === tenantParent.parentValue);
                parentValue = tenantParent.parentValue
            }
        }
        let relatedTypesSel = [];
        if(type.relatedTypes != null){
            type.relatedTypes.forEach(x => {
                let relatedType = this.state.parentTypes.find(y => y.value === x);
                if(relatedType != null)
                    relatedTypesSel.push(relatedType);
            });
        }
        if(type){
            this.setState({
                _id: type._id,
                name: type.name,
                clientManaged: type.clientManaged !== true && type.clientManaged !== 'true' ? false : true,
                clientAdd: type.clientAdd !== true && type.clientAdd !== 'true' ? false : true,
                globalval: type.global,
                exportJsonVal: type.exportJson,
                exportNameVal: type.exportName,
                parentType: type.parentType,
                parentTypeSel: parentTypeSel,
                parentValue: parentValue,
                parentValueSel: parentValueSel,
                parentValues: parentValues,
                relatedTypes: type.relatedTypes != null ? type.relatedTypes : [],
                relatedTypesSel: relatedTypesSel,
                editOpen: true
            })
        }
    }

    newClick = () => {
        this.setState({editOpen: true});
    }

    deleteClick(e){
        e.preventDefault();
        if(this.state._id === ''){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: 'This record is not saved yet', title: 'Warning', severity: 'warning'}});
            return;
        }
        axios.post('/api/typedelete', {_id: this.state._id})
        .then(result => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            this.setState({editOpen: false});
            this.loadRecords();
        })
        .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
        })
    }

    closeClick(e){
        this.setState({editOpen: false});
    }

    toggleSave = () => {
        this.setState({ enableSave: !this.state.enableSave });
    }

    render(){
        const security = this.state.security;
        const internal = this.props.auth.user.internal;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createClick={this.newClick}
                        createAccess={security.create}
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={3}>
                            <TextField value={this.state.search} onChange={this.searchChange} name="search"
                            size="medium" fullWidth={true} label={t('name')} data-cy='name'/>
                        </Grid>
                        {internal &&
                            <Grid item xs={3} sm={2}>
                                <CheckboxField
                                    checked={this.state.globals} onChange={this.changeSearchBool('globals')}
                                    label="Global" name="globals" data-cy='global'
                                />
                            </Grid>
                        }
                        {internal &&
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                    checked={this.state.exportJson} onChange={this.changeSearchBool('exportJson')}
                                    label={t('exportJson')} name="exportJson" data-cy='exportJson'
                                />
                            </Grid>
                        }
                        {internal &&
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                    checked={this.state.searchManaged} onChange={this.changeSearchBool('searchManaged')}
                                    label={t('clientManaged')} name="searchManaged" data-cy='searchManaged'
                                />
                            </Grid>
                        }
                        {internal &&
                            <Grid item xs={4} sm={3}>
                                <TextField value={this.state.exportName} onChange={this.changeValue} name="exportName" data-cy='exportName'
                                size="medium" fullWidth={true} label={t('exportName')} variant='outlined' error helperText='Internal Only'/>
                            </Grid>
                        }
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Value Types">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('name')}
                                            active={this.state.orderBy === 'name'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Name
                                        </TableSortLabel>
                                    </TableCell>
                                    {internal &&
                                        <TableCell>Global</TableCell>
                                    }
                                    {internal &&
                                        <TableCell>Client Managed</TableCell>
                                    }
                                    {internal &&
                                        <TableCell>Export JSON</TableCell>
                                    }
                                    {internal &&
                                        <TableCell><TableSortLabel onClick={() => this.sortClick('exportName')}
                                                active={this.state.orderBy === 'exportName'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Export Name
                                            </TableSortLabel>
                                        </TableCell>
                                    }
                                    <TableCell/>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.types.map((type, i) =>
                                    <TableRow key={type._id}>
                                        <TableCell><Link to={'/values/' + type._id}>{type.name}</Link></TableCell>
                                        {internal &&
                                            <TableCell>{type.global ? 'Yes' :'No'}</TableCell>
                                        }
                                        {internal &&
                                            <TableCell>{type.clientManaged === true || type.clientManaged === 'true' ? t('yes') : t('no')}</TableCell>
                                        }
                                        {internal &&
                                            <TableCell>{type.exportJson ? t('yes') : t('no')}</TableCell>
                                        }
                                        {internal &&
                                            <TableCell>{type.exportName}</TableCell>
                                        }
                                        <TableCell>
                                            {internal &&
                                                <TextOnlyClickButton
                                                    click={() => this.editClick(type._id)}
                                                    enabled={security.update}
                                                    iteration={i}
                                                    name={t('edit')}
                                                    data-cy={'typeEdit' + i}/>
                                            }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <Dialog open={this.state.editOpen} onClose={() => this.closeClick()} maxWidth="lg" fullWidth>
                        <DialogTitle disableTypography>
                            <SectionTitle title={t('valueTypes')} />
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={8} sm={4}>
                                    <TextField value={this.state.name} onChange={this.nameChange}
                                    size="medium" fullWidth={true} label={t('name')} name="name" data-cy='editName'/>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.globalval} onChange={this.globalChange}
                                    label={t('global')} name="globalval" data-cy='editGlobal'
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.clientManaged} onChange={this.changeBool('clientManaged')}
                                    label={t('clientManaged')} name="clientManaged" data-cy='editClientManaged'
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.clientAdd} onChange={this.changeBool('clientAdd')}
                                    label={t('clientAdd')} name="clientAdd" data-cy='editClientAdd'
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.exportJsonVal} onChange={this.changeBool('exportJsonVal')}
                                    label={t('exportJson')} name="exportJsonVal" data-cy='editExportJson'
                                    />
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    <TextField value={this.state.exportNameVal} onChange={this.changeValue}
                                    size="medium" fullWidth={true} label={t('exportName')} name="exportNameVal"
                                    data-cy='editExportName'/>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.parentTypeSel}
                                        options={this.state.parentTypes}
                                        onChange={this.changeAuto('parentTypeSel', 'parentType')}
                                        label={t('parentType')}
                                        data-cy='parentType'
                                    />
                                </Grid>
                                {this.state.parentValues != null && this.state.parentValues.length > 0 &&
                                    <Grid item xs={6} sm={3}>
                                        <AutoCompleteField
                                            value={this.state.parentValueSel}
                                            options={this.state.parentValues}
                                            onChange={this.changeAuto('parentValueSel', 'parentValue')}
                                            label={t('parentValue')} data-cy='parentValue'
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} sm={8}>
                                    <AutoCompleteField
                                        value={this.state.relatedTypesSel}
                                        options={this.state.parentTypes}
                                        multiple={true}
                                        onChange={this.changeMulti('relatedTypesSel', 'relatedTypes')}
                                        label={t('relatedTypes')}
                                        data-cy='relatedTypes'
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <SaveButton saveClick={this.saveClick} enabled={this.state.enableSave && security.update} data-cy='save'/>
                            <ClearButton clearClick={this.clearClick} enabled={true} data-cy='clear'/>
                            <DeleteButton deleteClick={this.deleteClick} enabled={security.delete && this.state._id === ''} data-cy='delete'/>
                        </DialogActions>
                    </Dialog>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(ValueTypes));
