import axios from 'axios';

const BASE_URL = 'http://127.0.0.1:9100/';

// //Gets the application configuration
const _getAppConfig = async (device) => {
  const result = await _print(device);
  return result;
}

const _getDeviceStatus = async (device, data = "~hs") => {
  let networkCallResponse = `
    ^XA
    ^HS
    ^XZ
  `
  const result = await fetch(BASE_URL + 'write', {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain'
    },
    body: JSON.stringify({device, data: data + networkCallResponse})
  })
  .catch(error => {
    return error;
  });
  if(result.ok) {
    return result.json();
  }else{
    return result;
  }
}

// Discover all of the devices that are available to the host system
const _getAvailableDevices = async () => {
  const devices = await fetch(BASE_URL + 'available')
  .then(response => {
      if(response.ok) return response.json()
      throw response;})
  .catch(error => console.info(error));
  return devices;
}

// Gets the user specified default device by type; to avoid having to run a full discovery on every page load
const _defaultDevice = async () => {
  const device = await fetch(BASE_URL + 'default?type=printer')
    .then(response => {
        if(response.ok) return response.json()
        throw response;})
    .catch(error => console.info(error));
  return device;
}

const _getDevices = async () => {
  try {
    const defaultDevice = await _defaultDevice();
    const deviceList = await _getAvailableDevices(); //returns deviceList.printer Array
    const printers = await axios.get('/api/devices');
    let devices = { registered: [], new: [], notConnected: [], hasRegisteredDevices: false, default: defaultDevice };
    if(deviceList != null && deviceList !== '' && 
       deviceList.printer != null && deviceList.printer.length > 0) {
      if(printers.data != null && printers.data !== '' && printers.data.length > 0) {
        deviceList.printer.forEach(device => {
          if(printers.data.some(printer => printer.name === device.name && printer.uid === device.uid)) {
            let printer = printers.data.find(printer => printer.name === device.name && printer.uid === device.uid);
            devices.registered.push(printer);
            devices.hasRegisteredDevices = true;
            if(devices.default) {
              if(printer.name === devices.default.name && printer.uid === devices.default.uid) {
                devices.default = printer;
              }
            }
          }else {
            devices.new.push(device);
          }
          devices.hasDevices = true;
        });
        printers.data.forEach(printer => {
          if(!deviceList.printer.some(device => device.name === printer.name && device.uid === printer.uid))
            devices.notConnected.push(printer);
        });
      }else {
        devices.new = deviceList.printer;
        devices.hasRegisteredDevices = false;
        devices.hasDevices = true;
      }
    }else {
      devices.notConnected = printers.data;
    }    
    return devices;
  }catch(err) {
    return err;
  }
}

const _print = async (device, data = "~wc") => {
  try {
    const response = await fetch(BASE_URL + 'write', {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain'
      },
      body: JSON.stringify({device: device, data: data}),
    });
    return response;
  } catch (error) {
    console.info(error);
    return error;
  }
}

const _testPrint = async (printer) => {
  try {
    const serial = 'evoraTechnologies.com';
    const zpl = `^XA
    ^BY2,2,100
    ^FO20,20^BC^FD${serial}^FS
    ^XZ`;
    const result = await _print(printer, zpl);
    return result;
  }catch (err) {
    return {error: true, message: err.message};
  }
}

export const DeviceHelper = {
    getAppConfig: _getAppConfig,
    getDevices: _getDevices,
    getDeviceStatus: _getDeviceStatus,
    print: _print,
    testPrint: _testPrint
}