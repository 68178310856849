import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import sopReducer from './sopReducer';
import notificationReducer from './notificationReducer';
import messageReduder from './messageReducer';
import profileReducer from './profileReducer';
import activityReducer from './activityReducer';
import navMenuReducer from './navMenuReducer';
import alertReducer from './alertReducer';
import securityReducer from './securityReducer';
import optionMenuReducer from './optionMenuReducer';
import lockReducer from './lockReducer';
import devices from './deviceReducer';
import helpReducer from './helpReducer';

export default combineReducers({
    errors: errorReducer,
    auth: authReducer,
    sop: sopReducer,
    notification: notificationReducer,
    message: messageReduder,
    profile: profileReducer,
    activity: activityReducer,
    navMenu: navMenuReducer,
    alert: alertReducer,
    security: securityReducer,
    optionMenu: optionMenuReducer,
    devices: devices,
    lock: lockReducer,
    help: helpReducer
});