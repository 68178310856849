import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Aux from '../../hoc/Auxilary/Auxilary';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { NumericFormat } from 'react-number-format';

class ItemContainers extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            item: this.props.item != null && this.props.item !== '' ? this.props.item : null,
            orderBy: 'number',
            ordDir: -1,
            totalCount: 0,
            page: 0,
        }
    }

    async componentDidMount(){
        this.loadRecords();
    }

    loadRecords = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: 'item', value: this.props.id},
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let list = await axios.get('/api/containers', {params: params});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        });
    }

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <TableContainer component={Paper}>
                    <Table aria-label="Transactions">
                        <TableHead>
                            <TableRow key="transHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('number')}
                                    active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('lot')}
                                    active={this.state.orderBy === 'lot'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('lot')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">
                                    <TableSortLabel onClick={() => this.sortClick('quantity')}
                                    active={this.state.orderBy === 'quantity'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('total')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">
                                    <TableSortLabel onClick={() => this.sortClick('available')}
                                    active={this.state.orderBy === 'available'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('available')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="right">
                                    <TableSortLabel onClick={() => this.sortClick('reserved')}
                                    active={this.state.orderBy === 'reserved'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('reserved')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('type')}
                                    active={this.state.orderBy === 'type'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('type')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('bin')}
                                    active={this.state.orderBy === 'bin'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('bin')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('expires')}
                                    active={this.state.orderBy === 'expires'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('expires')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                           {this.state.list.map((row, i) => 
                                <TableRow key={row._id}>
                                    <TableCell><Link to={'/container/' + row._id}>{row.number}</Link></TableCell>
                                    <TableCell>{row.lot != null ? row.lot.number : ''}</TableCell>
                                    <TableCell align="right">
                                        <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}
                                            suffix={row.uom != null ? row.uom.label : ''} decimalScale={3}/>
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumericFormat displayType={'text'} value={row.available} thousandSeparator={true}
                                            suffix={row.uom != null ? row.uom.label : ''} decimalScale={3}/>
                                    </TableCell>
                                    <TableCell align="right">
                                        <NumericFormat displayType={'text'} value={row.reserved} thousandSeparator={true}
                                            suffix={row.uom != null ? row.uom.label : ''} decimalScale={3}/>
                                    </TableCell>
                                    <TableCell>{row.type != null ? row.type.label : ''}</TableCell>
                                    <TableCell>{row.bin != null ? row.bin.description : '' }</TableCell>
                                    <TableCell>{row.expires != null ? <DateDisplay format={this.props.auth.user.dateFormat} value={row.expires}/> : ''}</TableCell>
                                </TableRow>
                           )} 
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(ItemContainers));