import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ListButtonStack from '../UI/Buttons/ListButtonStack';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';

class EquipmentLogs extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            list: [],
            equipment: null,
            offset: 0,
            listSize: 10,
            pageCount: 1,
            status: '',
            statuses: [],
            organization: '',
            organizations: [],
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            statSel: null,
            orgSel: null,
            security: []
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.status, common.cacheValues.equipment], this.props.auth.constants, null, this.props.auth.user.tenant);
        if(!this.props.auth.isAuthenticated) this.props.history.push('/login');
        try {
            this.setState({
                equipment: cacheValues.equipments,
                security: this.props.permission,
                _id: this.props.id          
            }, () => {
                this.loadLogs();
            });
        }catch(err) {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        };
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if((prevProps.id == null || prevProps.id === '') && this.props.id != null && this.props.id !== ''){
            let equipment = null;
            if(this.props.id != null && this.props.id !== '')
                equipment = await axios.get('/api/equipment/' + this.props.id);
            this.setState({
                equipment: equipment != null ? equipment.data : null,
                _id: this.props.id          
            }, () => {
                if(this.props.id != null && this.props.id !== '')
                    this.loadLogs();
            });
        }
    }
    
    async loadLogs(){
        let sorting = [{'id': 'number', 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: 'equipment', value: this.props.id},
            {id: 'organization', value: this.state.organization !== '' ? this.state.organization : null}, 
            {id: 'status', value: this.state.status !== '' ? this.state.status : null}
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        try {
            const logs = await axios.get('/api/equipmentlogs', {params: params});
            this.setState({
                list: logs.data.data,
                pageCount: Math.ceil(logs.data.totalCount / this.state.listSize),
                totalCount: logs.data.totalCount
            });
        }catch(err) {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        };
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadLogs();
        });
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => this.loadLogs());
    };

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {this.loadLogs();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadLogs();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadLogs();
        });
    };

    render(){
        const t = this.props.t;
         return(
            <Aux>
                <SectionTitle title={t('maintenanceLogs')}/>
                <ListButtonStack
                    createLocation={"/#/equipmentlog?equipment=" + this.state._id}
                    security={this.state.security}
                />
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.statSel}
                                options={this.state.statuses}
                                onChange={this.changeAuto('statSel', 'status')}
                                label={t('status')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.orgSel}
                                options={this.state.organizations}
                                onChange={this.changeAuto('orgSel', 'organization')}
                                label={t('organization')}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Equipment Logs" size="small">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('date')}
                                        active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('procedureOccurrence')}</TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('organization')}
                                        active={this.state.orderBy === 'organization'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('organization')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('conductedExecution')}</TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('status')}
                                        active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <Link to={'/equipmentlog/' + this.state.equipment._id + '/' + row._id}>
                                            {row.number}</Link>
                                        </TableCell>
                                        <TableCell><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell>{row.organization!= null ? row.organization.name : t('internal')}</TableCell>
                                        <TableCell>{row.conductedExecution}</TableCell>
                                        <TableCell>{row.status != null ? row.status.label : ''}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(EquipmentLogs));