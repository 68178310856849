import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { fieldSecurity } from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import Panel from '../UI/Panel/Panel';
import ObjectLog from '../General/ObjectLog';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { withTranslation } from 'react-i18next';
import RichTextField from '../UI/RichTextField/RichTextField';
import SectionTitle from '../UI/Typography/SectionTitle';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class EmailTemplate extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            name: '',
            number: '',
            definition: '',
            definitionSel: null,
            definitions: [],
            template: '',
            subject: '',
            global: false,
            fieldAccess: false,
            security: [],
            templates: [],
            languages: []
        };
    }

    async componentDidMount(){
        let values = await axios.get('/api/emailtemplatevalues');
        const fieldAccess = fieldSecurity(this.props.permission, this.props.auth.constants.statuses.inprogress, this.props.auth.common, this.props.auth.constants);
        this.setState({
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            definitions: values.data.definitions,
            security: this.props.permission,
            languages: values.data.languages,
            fieldAccess: fieldAccess
        }, () => {
            if( this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/emailtemplates', label: this.props.t('emailTemplates'), screen: this.props.auth.screenDefs.EmailTemplateList},
                    { path: 'active', label: this.props.t('emailTemplate'), screen: this.props.auth.screenDefs.EmailTemplate}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
                this.prepareTemplates();
}        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/emailtemplate/' + this.state._id);
        if(record.data._id != null && record.data._id !== ''){
            let definitionSel = this.state.definitions.find(x => x.value === record.data.definition);
            let templates = record.data.templates;
            if(templates != null && templates.length > 0){
                templates.forEach(row => {
                    row.languageSel = this.state.languages.find(x => x.value === row.language);
                })
            }
            this.setState({
                name: record.data.name,
                number: record.data.number,
                definition: record.data.definition,
                definitionSel: definitionSel,
                template: record.data.template,
                subject: record.data.subject,
                global: record.data.global ? true : false,
                templates: templates != null ? templates : []
            }, () => {
                const crumbs = [
                    { path: '/#/emailtemplates', label: this.props.t('emailTemplates'), screen: this.props.auth.screenDefs.EmailTemplateList},
                    { path: 'active', label: record.data.name + ' - ' + record.data.number, screen: this.props.auth.screenDefs.EmailTemplate}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
                if(this.state.templates == null || this.state.templates.length < this.state.languages.length){
                    this.prepareTemplates();
                }
            });
        }
    };

    prepareTemplates(){
        let templates = this.state.templates;
        this.state.languages.forEach(row => {
            let exists = templates.find(x => x.language === row.value);
            if(!exists){
                templates.push({
                    language: row.value,
                    languageSel: row,
                    template: ''
                });
            }
            
        });
        this.setState({templates: templates});
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeTemplate = (index, content) => {
        this.setState({ template: content });
    };

    changeTemplateRow = (i) => (index, content) => {
        let templates = this.state.templates;
        templates[i].template = content;
        this.setState({templates: templates});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            global: newValue != null ? newValue.global : false
        });
    };

    changeLineAuto = (name, data, i) => (e, newValue) => {
        let templates = this.state.templates;
        let row = templates[i];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : '';
        this.setState({templates: templates});
    }

    changeGlobal = (e) => {
        this.setState({global: !this.state.global});
    }

    save = async (e) => {
        let valid = this.validate();
        if(!valid)
            return;
        let data = {
            _id: this.state._id,
            name: this.state.name,
            number: this.state.number,
            subject: this.state.subject,
            definition: this.state.definition,
            template: this.state.template,
            templates: this.state.templates
        };
        try {
            let result = await axios.post('/api/emailtemplate', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/emailtemplate/' + result.data.id);
            this.setState({_id: result.data.id, number: result.data.number});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    };

    validate(){
        let errors = {};
        const t = this.props.t;
        if(this.state.name == null || this.state.name === '')
            errors[t('name')] = this.props.t('required');
        if(this.state.definition == null || this.state.defintion === '')
            errors[t('definition')] = this.props.t('required');
        if(this.state.subject == null || this.state.subject === '')
            errors[t('subject')] = this.props.t('required');
        if(this.state.templates != null && this.state.templates.length > 0){
            let i = 0;
            this.state.templates.forEach(row => {
                if(row.language == null || row.language === ''){
                    errors[`${t('language')} (${t('line')} ${i})`] = this.props.t('required');
                }
                i++;
            });
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    render(){
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        {this.state._id != null && this.state._id !== '' &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.number}  name="number"
                                size="medium" fullWidth={true} disabled={true} label={t('number')}/>
                            </Grid>
                        }
                        <Grid item xs={6} sm={4}>
                            <AutoCompleteField
                                value={this.state.definitionSel}
                                options={this.state.definitions}
                                onChange={this.changeAuto('definitionSel', 'definition')}
                                error={errors[t('definition')] != null ? true : false}
                                helperText={errors[t('definition')]} 
                                label={t('definition')}
                                disabled={!fieldAccess}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.name} onChange={this.changeValue} name="name"
                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('name')} 
                            error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                        </Grid>
                        <Grid item xs={10} sm={6}>
                            <TextField value={this.state.subject} onChange={this.changeValue} name="subject"
                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('subject')} 
                            error={errors[t('subject')] != null ? true : false} helperText={errors[t('subject')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField
                            checked={this.state.global} onChange={this.changeGlobal}
                            label={t('global')} name="global" disabled={true}
                            />
                        </Grid>
                    </Grid>
                    {this.state.templates != null && this.state.templates.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('templates')}/>
                            {this.state.templates.map((row, i) => 
                                <Aux key={i}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} sm={2}>
                                            <AutoCompleteField
                                                value={row.languageSel}
                                                options={this.state.languages}
                                                onChange={this.changeLineAuto('languageSel', 'language', i)}
                                                error={errors[`${t('language')} (${t('line')} ${i})`] != null ? true : false}
                                                helperText={errors[`${t('language')} (${t('line')} ${i})`]} 
                                                label={t('language')}
                                                disabled={!fieldAccess}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item sm={11}>
                                            <RichTextField
                                                disabled={!fieldAccess}
                                                id={'template' + i}
                                                key={'template' + i}
                                                index={1}
                                                value={row.template != null ? row.template : ''}
                                                errors={errors}
                                                textAreaName={'emailTemplateField-richText' + i}
                                                height={250}
                                                changeRichText={this.changeTemplateRow(i)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <br/>
                                </Aux>
                            )}
                        </Aux>
                    }
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            <Button variant="contained" color="primary" size="small" onClick={this.save}>{t('save')}</Button>
                        </Grid>
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.emailTemplate}/>
                            <ObjectLog id={this.state._id}/>
                        </Aux>
                    }
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(EmailTemplate));