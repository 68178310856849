import React from 'react';

export default function ReportTitle (props) {
  return (
    <div>
      <span style={{ fontSize: '12pt', fontWeight: 'bold' }}>{props.title}</span><br/>
      <span style={{ fontSize: '11pt', fontWeight: 'bold' }}>{props.description}</span>
    </div>
    
  );
}