import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import BarcodeIcon from 'mdi-material-ui/Barcode';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import { DeviceHelper } from '../../../helpers/deviceHelper';
import Tooltip from '@mui/material/Tooltip';
import isEmpty from '../../../is-empty';

export default function LabelPrintButton(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const security = useSelector(state => state.security.currentPath.print);
    const [enabled, setEnabled] = useState(true);
    const [multipleLabels, setMultipleLabels] = useState(false);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const defaultDevice = useSelector(state => state.devices.defaultDevice);
    const hasRegisteredDevices = useSelector(state => state.devices.hasRegisteredDevices);
    const constants = useSelector(state => state.auth.constants);
    const { containerData, lotLabel, plantLabel, printAmount } = props;

    const buildLabelData = (details, type, label, number) => {
        let data = {};
        const printer = {
            density: defaultDevice.quality,
            pause: defaultDevice.pauseCount ?? 0,
            replica: defaultDevice.replica ?? 1,
            override: defaultDevice.override ?? false,
        };
        switch(type) {
            case constants.labelPrintTypes.container: {
                data = {
                    ...details,
                    ...printer,
                    count: details.count ?? 1,
                    labelFormat: label.label
                };
                break;
            }
            case constants.labelPrintTypes.lot: {
                data = {
                    ...details,
                    ...printer,
                    count: details.count ?? 1,
                    labelFormat: label.label,
                };
                break;
            }
            case constants.labelPrintTypes.plant: {
                data = {
                    ...details,
                    ...printer,
                    count: label.eachPlant ? 1 : 
                           details.count ?? 1,
                    labelFormat: label.label,
                    number: number ?? -1,
                    eachPlant: label.eachPlant ?? false,
                };
                break;
            }
            default: 
                break;
        }
        return data;
    }

    const handlePlantClick = async () => {
        setEnabled(false);
        let label = null;
        if(defaultDevice.labels != null && defaultDevice.labels.length > 0)
            label = defaultDevice.labels.find(l => l.type === constants.labelPrintTypes.plant);
        if(!label || label.label === '' || label.label === null) {
            dispatch({ 
                type: 'TOGGLE_PRINTDIALOG', 
                payload: { 
                    labelType: constants.labelPrintTypes.plant, 
                    labelData: plantLabel
                }
            });
        }else {
            let data = [];
            if(label.eachPlant) {
                if(plantLabel.startQuantity != null && plantLabel.startQuantity > 1) {
                    for(let i = 0; i < plantLabel.startQuantity; i++) {
                        data.push(buildLabelData(plantLabel, constants.labelPrintTypes.plant, label, i + 1));
                    }
                }
            }else {
                data.push(buildLabelData(plantLabel, constants.labelPrintTypes.plant, label));
            }
            try {
                let labelBuild = '';
                if(data.length === 1)
                    labelBuild = await axios.post('api/label', data[0]);
                else
                    labelBuild = await axios.post('api/multilabel', data);
                const result = await DeviceHelper.print(defaultDevice, labelBuild.data);
                if(result.ok){
                    setEnabled(true);
                    dispatch({ type: 'CREATE_ALERT', payload: {message: t('labelPrintSuccess'), title: t('success'), severity: 'success'}});
                }else {
                    dispatch({ type: 'CREATE_ALERT', payload: {message: result.statusText, title: result.status + ' ' + t('error'), severity: 'error'}});
                }
            }catch(err) {
                dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: t('error'), severity: 'error'}});
            }
        }
        setEnabled(true);
    };
    const handleLotClick = async () => {};

    const handleContainerClick = async () => {
        setEnabled(false);
        let label = null;
        if(defaultDevice.labels != null && defaultDevice.labels.length > 0)
            label = defaultDevice.labels.find(l => l.type === constants.labelPrintTypes.container);
        if(!label || label.label === '' || label.label === null) {
            dispatch({ 
                type: 'TOGGLE_PRINTDIALOG', 
                payload: { 
                labelType: constants.labelPrintTypes.container, 
                labelData: containerData
                }
            });
        }else {
            if(printAmount != null && (printAmount === 'single' || printAmount === 'single-actionBar')) {
                const data = buildLabelData(containerData, constants.labelPrintTypes.container, label);
                try {
                    const labelBuild = await axios.post('api/label', data);
                    const result = await DeviceHelper.print(defaultDevice, labelBuild.data);
                    if(result.ok){
                        setEnabled(true);
                        dispatch({ type: 'CREATE_ALERT', payload: {message: t('labelPrintSuccess'), title: t('success'), severity: 'success'}});
                    }else {
                        dispatch({ type: 'CREATE_ALERT', payload: {message: result.statusText, title: result.status + ' ' + t('error'), severity: 'error'}});
                    }
                }catch(err) {
                    dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: t('error'), severity: 'error'}});
                    setEnabled(true);
                }
            }else {
                if(containerData != null && containerData.length > 0) {
                    let labelDetails = [];
                    try {
                        containerData.forEach(async (data) => {
                            const details = buildLabelData(data, constants.labelPrintTypes.container, label);
                            labelDetails.push(details);
                        });
                        const multiLabel = await axios.post('api/multilabel', labelDetails);
                        const result = await DeviceHelper.print(defaultDevice, multiLabel.data);
                        if(result.ok){
                            dispatch({ type: 'CREATE_ALERT', payload: {message: t('labelPrintSuccess'), title: t('success'), severity: 'success'}});
                        }else {
                            dispatch({ type: 'CREATE_ALERT', payload: {message: result.statusText, title: result.status + ' ' + t('error'), severity: 'error'}});
                        }
                        setEnabled(true);
                    }catch (err) {
                        dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: t('error'), severity: 'error'}});
                    }
                }
            }
        }
        setEnabled(true);
    }

    useEffect(() => {
        let count = 0;
        if(!isEmpty(containerData))
            count++;
        if(!isEmpty(plantLabel))
            count++;
        if(!isEmpty(lotLabel))
            count++;
        if(count > 1)
            setMultipleLabels(true);
        else
            setMultipleLabels(false);        
    }, [containerData, plantLabel, lotLabel]);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };
      
    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        };    
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    if(multipleLabels) {
        return (
            <Tooltip title={'printLabels'} placement='top'>
                <div>
                    <IconButton
                        id="printIcon"
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        sx={{
                            borderRadius: '4px',
                            margin: 0,
                            paddingBottom: 2,
                            padding: printAmount === 'single' ? 0 : 1,
                            paddingRight: printAmount === 'single' ? 1 : null,
                            color: 'primary.main',
                            minWidth: 0,
                        }}
                        disabled={!enabled && !security && !hasRegisteredDevices}
                        disableRipple={printAmount === 'single' ? true : false}
                        ref={anchorRef}
                    >
                        <BarcodeIcon sx={{ borderRadius: '4px' }} />
                    </IconButton>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                        {...TransitionProps}
                        sx={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                {!isEmpty(plantLabel) && <MenuItem onClick={handlePlantClick}>{t('printPlantLabels')}</MenuItem>}
                                {!isEmpty(lotLabel) && <MenuItem onClick={handleLotClick}>{t('printLotLabels')}</MenuItem>}
                                {!isEmpty(containerData) && <MenuItem onClick={handleContainerClick}>{t('printAllContainerLabels')}</MenuItem>}
                            </MenuList>
                            </ClickAwayListener>
                        </Paper>
                        </Grow>
                    )}
                    </Popper>
                </div>
            </Tooltip>
        );
    }else {
        let onClick = null;
        let label = '';
        if(!isEmpty(plantLabel)){
            label = t('printPlantLabels');
            onClick = handlePlantClick;}
        else if(!isEmpty(lotLabel)) {
            label = t('printLotLabels');
            onClick = handleLotClick;
        }else if(!isEmpty(containerData)){
            label = t('printContainerLabel');
            onClick = handleContainerClick;
        }else{
            label = t('noneFound');
            onClick = null;
        }
        return (
            <Tooltip title={label}>
                <div>
                    <IconButton onClick={onClick} id="printIcon" aria-haspopup="true" 
                                sx={{ borderRadius: '4px', margin: 0, 
                                    paddingBottom: 2,
                                    padding: (printAmount === 'single' ? 0 : 1), 
                                    paddingRight: (printAmount === 'single' ? 1 : null),
                                    color: 'primary.main', minWidth: 0,
                                    
                                    }}
                                    disabled={!enabled && !security && !hasRegisteredDevices} 
                                    disableRipple={printAmount === 'single' ? true : false} >
                        <BarcodeIcon sx={{ borderRadius: '4px' }}/>
                    </IconButton>
                </div>
            </Tooltip>
        );
    }    
}