import React, { Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import SectionTitle from '../UI/Typography/SectionTitle';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Box from '@mui/material/Box';
import isEmpty from '../../is-empty';
import conversionHelper from '../../helpers/conversionHelper';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import Switch from '../UI/Switch/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


class ProductionPlan extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            status: '',
            number: '',
            statusSel: null,
            statuses: [],
            outputItem : null,
            items: [],
            outputItemSel: null,
            deliveryDate: null,
            interimItems: [],
            outputQuantity: '',
            outputUOM: null,
            outputUOMSel: null,
            uoms: [],
            testTurnaround: 0,
            activities: null,
            shippingTime: 0,
            totalTime: 0,
            totalTimeString: '',
            startDate: null,
            timeUOMs: [],
            locked: false,
            productionSchedule: null,
            startItem: null,
            startSel: null,
            stepItems: [],
            growDays: 0,
            location: '',
            locationSel: null,
            locations: [],
            security: [],
            enableSave: true,
            deleteOpen: false,
            poOnly: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.workLocation, common.cacheValues.quantityUOM, common.cacheValues.timeUOM,
                                common.cacheValues.producedItem, common.cacheValues.productionPlanStatus], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        let statusSel = cacheValues.productionPlanStatuses.find(x => x.value === this.props.auth.constants.productionPlanStatuses.inprogress);
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
            this.props.auth.user.defaultLocation : '';
        let locSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locSel == null && locations.length === 1){
            locSel = locations[0];
            location = locSel.value;
        }
        this.setState({
            items: cacheValues.producedItems,
            uoms: cacheValues.quantityUOMs,
            statuses: cacheValues.productionPlanStatuses,
            locations: locations,
            location: location,
            locationSel: locSel,
            timeUOMs: cacheValues.timeUOMs,
            _id: this.props.match.params.id != null ? this.props.match.params.id : '',
            statusSel: statusSel,
            status: this.props.auth.constants.productionPlanStatuses.inprogress,
            security: []
        }, () => {
            if(this.props.match.params.id != null){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/productionplans', label: this.props.t('productionPlans'), screen: this.props.auth.screenDefs.ProductionPlans},
                    { path: 'active', label: this.props.t('productionPlan'), screen: this.props.auth.screenDefs.ProductionPlan}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async (e) => {
        let record = await axios.get('/api/productionplan/' + this.state._id);
        let outputItemSel = this.state.items.find(x => x.value === record.data.outputItem);
        let outputUOMSel = this.state.uoms.find(x => x.value === record.data.outputUOM);
        let activities = record.data.activities;
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let locationSel = this.state.locations.find(x => x.value === record.data.location);
        var growDays = 0;
        activities.forEach(act => {
            if(act.routing != null){
                act.routingLines.forEach(line => {
                    if(line.equipment != null){
                        var capUOMSel = this.state.uoms.find(x => x.value === line.equipment.capacityUOM);
                        var maxUOMSel = this.state.timeUOMs.find(x => x.value === line.equipment.maxProcessTimeUOM);
                        var minUOMSel = this.state.timeUOMs.find(x => x.value === line.equipment.minProcessTimeUOM);
                        var moveUOMSel = this.state.timeUOMs.find(x => x.value === line.equipment.moveTimeUOM);
                        var setupUOMSel = this.state.timeUOMs.find(x => x.value === line.equipment.setupTimeUOM);
                        var waitUOMSel = this.state.timeUOMs.find(x => x.value === line.equipment.waitTimeUOM);
                        line.equipment.capUOMSel = capUOMSel;
                        line.equipment.maxUOMSel = maxUOMSel;
                        line.equipment.minUOMSel = minUOMSel;
                        line.equipment.moveUOMSel = moveUOMSel;
                        line.equipment.setupUOMSel = setupUOMSel;
                        line.equipment.waitUOMSel = waitUOMSel;
                    }
                });
                act.bomLines.forEach(line => {
                    if(line.item != null){
                        var uomSel = this.state.uoms.find(x => x.value === line.item.uom);
                        line.uomSel = uomSel;
                    }
                });
            } else if (act.batchTemplate != null){
                growDays = growDays + act.totalDays;
            } else if(act.type === this.props.auth.constants.objectIds.curing || act.type === this.props.auth.constants.objectIds.drying){
                growDays = growDays + act.totalDays;
            }

        });
        var startSel = record.data.startItem != null ? record.data.stepItems.find(x => x.value === record.data.startItem) : null;
        var totalTimeString = conversionHelper.calculateDayString(record.data.totalTime, growDays);
        var locked = record.data.status === this.props.auth.constants.productionPlanStatuses.production;
        this.setState({
            outputItem: record.data.outputItem,
            outputQuantity: record.data.outputQuantity,
            outputUOM: record.data.outputUOM,
            deliveryDate: record.data.deliveryDate,
            testTurnaround: record.data.testTurnaround,
            shippingTime: record.data.shippingTime,
            outputItemSel: outputItemSel,
            outputUOMSel: outputUOMSel,
            totalTime: record.data.totalTime,
            activities: activities,
            startDate: record.data.startDate,
            totalTimeString: totalTimeString,
            status: record.data.status,
            statusSel: statusSel,
            documents: record.data.documents,
            locked: locked,
            number: record.data.number,
            productionSchedule: record.data.productionSchedule,
            startItem: record.data.startItem,
            stepItems: record.data.stepItems,
            startSel: startSel,
            location: record.data.location,
            locationSel: locationSel,
            deleteOpen: false,
            poOnly: record.data.poOnly === true ? true : false
        }, () => {
            const crumbs = [
                { path: '/#/productionplans', label: this.props.t('productionPlans'), screen: this.props.auth.screenDefs.ProductionPlans},
                { path: 'active', label: this.props.t('productionPlan') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.ProductionPlan}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e}, () => {
            if(this.state.activites != null){
                this.calculateTotalTime(this.state.activites);
            }
        });
    };

    changeNumber = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value}, () => {
            if(this.state.activities != null){
                var result = this.calculateTotalTime(this.state.activities);
                var totalTime = result.totalTime;
                var totalTimeString = conversionHelper.calculateDayString(totalTime, result.growDays);
                this.setState({totalTime: totalTime, totalTimeString: totalTimeString, growDays: result.growDays});
            }
        });
    };

    changeLineNumber = (index) => (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value)){
            return;
        }
        let activities = this.state.activities;
        let activity = activities[index];
        activity[name] = value;
        let newValue = value != null && value !== '' ? parseInt(value) : 0;
        activity.additionalTime = newValue;
        /*var result = this.calculateTotalTime(activities);
        var totalTime = result.totalTime;
        var totalTimeString = conversionHelper.calculateDayString(totalTime, result.growDays);
        this.setState({activities: activities, totalTime: totalTime, totalTimeString: totalTimeString, growDays: result.growDays});*/
        this.setState({activities: activities},() => {
            this.calculateEverything();
        })
    };

    calculateTotalTime(activities){
        let totalTime = 0;
        let fullTime = 0;
        let startDate = null;
        let growDays = 0;
        activities.forEach(act => {
            if(act.routing != null ){
                totalTime = totalTime + (act.totalTime != null ? act.totalTime : act.totalMinutes);
                if(act.additionalTime != null && act.additionalTime !== '')
                    totalTime = totalTime + parseInt(act.additionalTime);
            } else if(act.batchTemplate != null){
                growDays = growDays + act.totalDays;
                if(act.additionalTime != null && act.additionalTime !== '')
                    growDays = growDays + parseInt(act.additionalTime);
            } else if(act.type === this.props.auth.constants.objectIds.dryingOrder){
                growDays = growDays + act.totalDays;
                if(act.additionalTime != null && act.additionalTime !== '')
                    growDays = growDays + parseInt(act.additionalTime);
            } else if(act.type === this.props.auth.constants.objectIds.trimmingOrder){
                totalTime = totalTime + (act.totalTime != null ? act.totalTime : act.totalMinutes);
                if(act.additionalTime != null && act.additionalTime !== '')
                    totalTime = totalTime + parseInt(act.additionalTime);
            } else if(act.type === this.props.auth.constants.objectIds.curing){
                growDays = growDays + act.totalDays;
                if(act.additionalTime != null && act.additionalTime !== '')
                    growDays = growDays + parseInt(act.additionalTime);
            }
        });
        fullTime = fullTime + totalTime;
        if(this.state.testTurnaround != null && this.state.testTurnaround !== '' && this.state.testTurnaround !== 0)
            fullTime = fullTime + (this.state.testTurnaround * (8 * 60));
        if(this.state.shippingTime != null && this.state.shippingTime !== '' && this.state.shippingTime !== 0)
            fullTime = fullTime + (this.state.shippingTime * (8 * 60));
        if(this.state.deliveryDate != null && this.state.deliveryDate !== ''){
            let days = Math.ceil(fullTime / (8 * 60));
            if(growDays > 0)
                days = days + growDays;
            startDate = new Date(this.state.deliveryDate);
            startDate.setHours(8);
            startDate.setMinutes(0);
            startDate.setMilliseconds(0);
            startDate.setDate(startDate.getDate() - days);
            this.setState({startDate: startDate});
        }
        return {fullTime: fullTime, totalTime: totalTime, startDate: startDate, growDays: growDays};
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            if((data === 'outputItem' && newValue != null) ||(data === 'startItem' && newValue != null)){
                this.loadItem();
            } else if(data === 'outputItem' && newValue == null){
                this.setState({
                    activities: [],
                    stepItems: [],
                    totalTime: 0,
                    totalTimeString: ''
                })
            }
        });
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]}, () => {
            if(name === 'poOnly'){
                if(this.state.outputItem != null && this.state.outputItem !== ''){
                    this.loadItem();
                }
            }
        });
    }

    async loadItem(){
        let query = {
            startItem: this.state.startItem,
            poOnly: this.state.poOnly
        };
        let rows = await axios.post('/api/itemproduction/' + this.state.outputItem, query);
        let result = this.calculateTotalTime(rows.data.activities);
        let totalTime = result.totalTime;
        let totalTimeString = conversionHelper.calculateDayString(totalTime, result.growDays);
        let activities = rows.data.activities;
        let stepItems = this.state.stepItems;
        let setSteps = stepItems.length === 0;
        activities.forEach(act => {
            if(act.routingLines != null && act.routingLines.length > 0){
                act.routingLines.forEach(line => {
                    if(line.equipment != null){
                        let capUOMSel = this.state.uoms.find(x => x.value === line.equipment.capacityUOM);
                        let maxUOMSel = this.state.timeUOMs.find(x => x.value === line.equipment.maxProcessTimeUOM);
                        let minUOMSel = this.state.timeUOMs.find(x => x.value === line.equipment.minProcessTimeUOM);
                        let moveUOMSel = this.state.timeUOMs.find(x => x.value === line.equipment.moveTimeUOM);
                        let setupUOMSel = this.state.timeUOMs.find(x => x.value === line.equipment.setupTimeUOM);
                        let waitUOMSel = this.state.timeUOMs.find(x => x.value === line.equipment.waitTimeUOM);
                        line.equipment.capUOMSel = capUOMSel;
                        line.equipment.maxUOMSel = maxUOMSel;
                        line.equipment.minUOMSel = minUOMSel;
                        line.equipment.moveUOMSel = moveUOMSel;
                        line.equipment.setupUOMSel = setupUOMSel;
                        line.equipment.waitUOMSel = waitUOMSel;
                    }
                });
                act.bomLines.forEach(line => {
                    if(line.item != null){
                        let uomSel = this.state.uoms.find(x => x.value === line.item.uom);
                        line.uomSel = uomSel;
                    }
                });
                if(setSteps)
                    stepItems.push({value: act.item._id, label: act.item.number + ' - ' + act.item.name});
            }
        });
        let item = activities[0].item;
        let outputUOMSel = this.state.uoms.find(x => x.value === item.uom._id);
        this.setState({
            activities: activities,
            totalTime: totalTime,
            totalTimeString: totalTimeString,
            stepItems: stepItems,
            outputUOM: item.uom,
            outputUOMSel: outputUOMSel,
            growDays: result.growDays
        });
    }

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        try {
            let errors = {};
            if(this.state.location == null || this.state.location === '')
                errors[t('location')] = t('required');
            if(this.state.outputItem == null || this.state.outputItem === '')
                errors[t('outputItem')] = t('required');
            if(this.state.outputQuantity == null || this.state.outputQuantity === '')
                errors[t('outputQuantity')] = t('required');
            if(this.state.deliveryDate == null || this.state.deliveryDate === '')
                errors[t('deliveryDate')] = t('required');
            if(!isEmpty(errors)){
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
            var result = await this.processSave();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
        }
    };

    processSave = async () => {
        let activities = [];
        let acts = this.state.activities;
        acts.forEach(act => {
            let bomLines = null;
            let routingLines = null;
            if(act.routing != null){
                bomLines = [];
                routingLines = [];
                act.bomLines.forEach(bl => {
                    bomLines.push({
                        item: bl.item != null ? bl.item._id : null,
                        productionBom: bl.productionBom != null ? bl.productionBom._id : null,
                        itemAmount: bl.itemAmount
                    });
                });
                act.routingLines.forEach(rl => {
                    routingLines.push({
                        equipment: rl.equipment != null ? rl.equipment._id : null,
                        runs: rl.runs,
                        quantity: rl.quantity,
                        totalRunTime: rl.totalRunTime,
                        totalTime: rl.totalTime,
                        startDate: rl.startDate,
                        endDate: rl.endDate,
                        capacity: rl.capacity,
                        name: rl.name,
                        runTime: rl.runTime
                    });
                });
            }
            let stages = [];
            if(act.batchTemplate != null){
                act.stages.forEach(stage => {
                    stages.push({
                        stage: stage.stage._id,
                        duration: stage.duration,
                        plantCount: stage.plantCount
                    })
                })
            }
            activities.push({
                item: act.item._id,
                productionBom: act.productionBom != null ? act.productionBom._id : null,
                routing: act.routing != null ? act.routing._id : null,
                bomLines: bomLines,
                routingLines: routingLines,
                totalMinutes: act.totalMinutes,
                additionalTime: act.additionalTime,
                startDate: act.startDate,
                endDate: act.endDate,
                totalDays: act.totalDays,
                batchTemplate: act.batchTemplate != null ? act.batchTemplate._id : null,
                stages: stages,
                type: act.type,
                quantity: act.quantity != null ? act.quantity : null,
                dryingType: act.dryingType != null ? act.dryingType._id : null,
                equipment: act.equipment != null ? act.equipment._id : null,
                runs: act.runs != null ? act.runs : null
            });
        });
        let data = {
            _id: this.state._id,
            outputItem: this.state.outputItem,
            outputQuantity: this.state.outputQuantity,
            outputUOM: this.state.outputUOM,
            deliveryDate: this.state.deliveryDate,
            testTurnaround: this.state.testTurnaround,
            shippingTime: this.state.shippingTime,
            totalTime: this.state.totalTime,
            activities: activities,
            startDate: this.state.startDate,
            status: this.state.status,
            startItem: this.state.startItem,
            totalDays: this.state.totalDays,
            location: this.state.location,
            poOnly: this.state.poOnly
        };
        try {
            let result = await axios.post('/api/productionplan', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/productionplan/' + result.data.id);
            this.setState({_id: result.data.id, number: result.data.number, enableSave: true}, () => {return result});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
                throw err;
            });
        }
    }

    return = (e) => {
        this.props.history.goBack();
    }

    validate(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.outputItem == null || state.outputItem === '')
            errors[t('outputItem')] = t('required');
        if(state.outputQuantity == null || state.outputQuantity === '')
            errors[t('outputQuantity')] = t('required');
        if(state.outputUOM == null || state.outputUOM === '')
            errors[t('outputUOM')] = t('required');
        if(state.deliveryDate == null || state.deliveryDate === '')
            errors[t('deliveryDate')] = t('required');
        if(Date.parse(state.startDate) < new Date()){
            errors[t('deliveryDate')] = t('deliveryDateInvalid');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    scheduleClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._scheduleClick(e);
        })
    }

    _scheduleClick = async (e) => {
        if(this.state.productionSchedule == null || this.state.productionSchedule === ''){
            let body = {
                plan: this.state._id
            };
            try {
                let result = await axios.post('/api/createproductionschedule', body);
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
                this.props.history.push("/productionschedule/" + result.data.id);
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            }
        }
    }

    calculateClick = async (e) => {
        this.calculateEverything();
        this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: this.props.t('planCalculated'), title: this.props.t('information'),
        severity: 'info'}});
    };

    async calculateEverything(){
        let data = {
            activities: this.state.activities,
            deliveryDate: this.state.deliveryDate,
            quantity: this.state.outputQuantity
        }
        let result = await axios.post('/api/productionplancalculate', data);;
        let totalTime = result.data.totalTime;
        let activities = result.data.activities
        let totalTimeString = '';
        if(activities != null){
            totalTimeString = conversionHelper.calculateDayString(totalTime, result.data.growDays);
        }
        this.setState({activities: activities, totalTime: totalTime, totalTimeString: totalTimeString, growDays: result.data.growDays});
        this.setState({startDate: result.data.startDate}, async () => {
            this.validate();
            if(this.state._id != null && this.state._id !== '')
                await this._save();
        });
    }

    calculatePlan(activities, date, quantity, uom){
        let valid = this.validate();
        if(!valid)
            return;
        if(quantity == null || activities == null || date == null || uom == null){
            return;
        }
        let outputQuantity = parseInt(quantity);
        for(var i = 0; i < activities.length; i++){
            let activity = activities[i];
            let activityQuantity = 0;
            let firstEquipment = true;
            if(activity.routing != null){
                if(!activity.routing.drying){
                    for(let j = 0; j < activity.routingLines.length; j++){
                        let rl = activity.routingLines[j];
                        let rlo = activity.routing.lines[j];
                        if(rlo.equipment != null || rlo.workCenter != null){
                            let equipmentQuantity = 0;
                            for (var k = 0; k < activity.bomLines.length; k++){
                                let bl = activity.bomLines[k];
                                let blo = activity.productionBom.lines[k];
                                let itemQuantity = 0;
                                if(blo.item != null ){
                                    let blQuantity = parseFloat(blo.quantity);
                                    itemQuantity = blQuantity * outputQuantity;
                                    if(blo.scrapPercent != null){
                                        itemQuantity = Math.ceil(itemQuantity + (itemQuantity * (blo.scrapPercent / 100)));
                                    }
                                    bl.itemAmount = itemQuantity;
                                }
                                if(!blo.exclude){
                                    equipmentQuantity = equipmentQuantity + itemQuantity;
                                }
                            }
                            if(rl.equipment != null)
                                rl.runs = Math.ceil(equipmentQuantity / rl.equipment.capacity);
                            else
                                rl.runs = Math.ceil(equipmentQuantity / rl.workCenter.capacity);
                            if(firstEquipment){
                                activityQuantity = activityQuantity + equipmentQuantity;
                                firstEquipment = false;
                            }
                        }
                    }
                } else {
                    let strain = activity.item.strain;
                    activityQuantity = outputQuantity * (1 + (strain.dryingPercentLoss / 100));
                    activity.bomLines[0].itemAmount = activityQuantity;
                }
            } else if (activity.batchTemplate != null){
                let plants = Math.ceil(outputQuantity / activity.item.strain.wetOutputPerPlant);
                let flowerPlants = Math.ceil(plants * (1 + (activity.item.strain.attritionPercentFlower / 100)));
                let vegPlants = Math.ceil(flowerPlants * (1 + (activity.item.strain.attritionPercentVeg / 100)));
                let propPlants = Math.ceil(vegPlants * (1 + (activity.item.strain.attritionPercentProp / 100)));
                let propLine = activity.stages.find(x => x.stage.number === 'PROP');
                if(propLine)
                    propLine.plantCount = propPlants;
                let vegLine = activity.stages.find(x => x.stage.number === 'VEG');
                if(vegLine)
                    vegLine.plantCount = vegPlants;
                let flowerLine = activity.stages.find(x => x.stage.number === 'FLOWER');
                if(flowerLine)
                    flowerLine.plantCount = flowerPlants;
                activityQuantity = propPlants;
                activity.quantity = outputQuantity;
            } else if(activity.type === this.props.auth.constants.objectIds.dryingOrder){
                activity.quantity = outputQuantity;
                let dryingQuantity = activity.item.strain.dryingPercentLoss != null && activity.item.strain.dryingPercentLoss > 0 ?
                    outputQuantity + ((activity.item.strain.dryingPercentLoss / 100) * outputQuantity) : outputQuantity;
                activityQuantity = Math.round(dryingQuantity);
            } else if(activity.type === this.props.auth.constants.objectIds.trimmingOrder){
                activity.quantity = outputQuantity;
                let trimmingQuantity = activity.item.strain.trimmingPercentLoss != null && activity.item.strain.trimmingPercentLoss > 0 ?
                    outputQuantity + ((activity.item.strain.trimmingPercentLoss / 100) * outputQuantity) : outputQuantity;
                activityQuantity = Math.round(trimmingQuantity);
                if(activity.equipment != null){
                    let runs = Math.ceil(activityQuantity / activity.equipment.capacity);
                    activity.runs = runs;
                    let totalCalcTime = runs * activity.totalMinutes;
                    activity.totalTime = totalCalcTime;
                }
            } else if(activity.type === this.props.auth.constants.objectIds.curing){
                activity.quantity = outputQuantity;
                let curingQuantity = activity.item.strain.curingPercentLoss != null && activity.item.strain.curingPercentLoss > 0 ?
                    outputQuantity + ((activity.item.strain.curingPercentLoss / 100) * outputQuantity) : outputQuantity;
                activityQuantity = Math.round(curingQuantity);
            }
            outputQuantity = activityQuantity;
        }
        return activities;
    }

    calculateTimes(activities, totalTime){
        activities.forEach(act => {
            let activityTotal = 0;
            if(act.routing != null){
                for(let i = 0; i < act.routingLines.length; i++){
                    let rl = act.routingLines[i];
                    let runs = rl.runs != null ? rl.runs : 1;
                    let runTotal = runs * rl.totalRunTime;
                    activityTotal = activityTotal + runTotal;
                    rl.totalTime = runTotal;
                    totalTime = totalTime + activityTotal;
                }
                act.totalMinutes = activityTotal;
            }
        });
        return {activities: activities, totalTime: totalTime};
    }

    setActivityDates(activities, startDate){
        let beginDate = new Date(startDate);
        beginDate.setHours(8);
        beginDate.setMinutes(0);
        beginDate.setMilliseconds(0);
        for(let i = activities.length - 1; i >= 0; i--){
            let act = activities[i];
            act.startDate = beginDate;
            if(act.routing != null){
                for(let j = act.routingLines.length - 1; j >= 0; j--){
                    let rl = act.routingLines[j];
                    rl.startDate = beginDate;
                    let result = conversionHelper.calculateWorkDate(beginDate, rl.totalTime);
                    rl.endDate = result;
                    beginDate = new Date(rl.endDate);
                }
            } else if(act.batchTemplate){
                let endDate = new Date(beginDate);
                let totalDays = act.totalDays;
                if(act.additionalTime != null && act.additionalTime !== '')
                    totalDays = totalDays + parseInt(act.additionalTime);
                endDate.setDate(endDate.getDate() + totalDays);
                beginDate = new Date(endDate);
            } else if(act.type === this.props.auth.constants.objectIds.dryingOrder || act.type === this.props.auth.constants.objectIds.curing){
                let endDate = new Date(beginDate);
                let totalDays = act.totalDays;
                if(act.additionalTime != null && act.additionalTime !== '')
                    totalDays = totalDays + parseInt(act.additionalTime);
                endDate.setDate(endDate.getDate() + totalDays);
                let end = new Date(endDate)
                act.endDate = end;
                beginDate = end;
            } else if(act.type === this.props.auth.constants.objectIds.trimmingOrder){
                let endDate = new Date(beginDate);
                let totalTime = act.totalTime;
                if(act.additionalTime != null && act.additionalTime !== '')
                    totalTime = totalTime + parseInt(act.additionalTime);
                let result = conversionHelper.calculateWorkDate(beginDate, totalTime);
                endDate = result;
                beginDate = endDate;
            }
            act.endDate = beginDate;
        }
        return activities;
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e));
    };

    _deleteClick = async (e) => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== '' && (this.state.productionSchedule == null || this.state.productionSchedule === '')){
            try {
                let result = await axios.delete('/api/productionplan/' + this.state._id);
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.setState({enableSave: true});
        }
    };

    render(){
        const errors = this.props.errors;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        const locked = this.state.locked;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar 
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.productionPlan}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        {this.state.number != null && this.state.number !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl data-cy='number'>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number != null ? this.state.number: ''}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            <FormControl data-cy='status'>
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSel != null ? this.state.statusSel.label: ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.locationSel}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locationSel', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]}
                                    label={t('location')}
                                    disabled={locked}
                                    data-cy='location'
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('location')}</FormLabel>
                                    {this.state.locationSel != null ? this.state.locationSel.label: ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Switch checked={this.state.poOnly} onChange={this.changeBool('poOnly')} name="poOnly" disabled={locked}/>}
                                label={t('processingOnly')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.outputItemSel}
                                    options={this.state.items}
                                    onChange={this.changeAuto('outputItemSel', 'outputItem')}
                                    error={errors[t('outputItem')] != null ? true : false}
                                    helperText={errors[t('outputItem')]}
                                    label={t('outputItem')}
                                    disabled={locked}
                                    required
                                    data-cy='outputItem'
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('outputItem')}</FormLabel>
                                    {this.state.outputItemSel != null ? this.state.outputItemSel.label: ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <TextField value={this.state.outputQuantity} onChange={this.changeNumber} name="outputQuantity"
                                size="medium" fullWidth={true} label={t('quantity')} disabled={locked} data-cy='outputQuantity' required
                                error={errors[t('outputQuantity')] != null ? true : false} helperText={errors[t('outputQuantity')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('quantity')}</FormLabel>
                                    {this.state.outputQuantity}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <FormControl>
                                <FormLabel>{t('uom')}</FormLabel>
                                {this.state.outputUOMSel != null ? this.state.outputUOMSel.label: ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <DateSelect
                                    onChange={this.dateChange('deliveryDate')}
                                    value={this.state.deliveryDate}
                                    helperText={errors[t('deliveryDate')]}
                                    error={errors[t('deliveryDate')] != null ? true : false}
                                    label={t('deliveryDate')}
                                    disabled={locked}
                                    format={this.props.auth.user.dateFormat}
                                    required
                                    data-cy='deliveryDate'
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('deliveryDate')}</FormLabel>
                                    <DateDisplay value={this.state.deliveryDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <TextField value={this.state.testTurnaround} onChange={this.changeNumber} name="testTurnaround"
                                size="medium" fullWidth={true} label={t('testTurnaround')} disabled={locked} data-cy='testTurnaround'
                                error={errors[t('testTurnaround')] != null ? true : false} helperText={errors[t('testTurnaround')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('testTurnaround')}</FormLabel>
                                    {this.state.testTurnaround}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <TextField value={this.state.shippingTime} onChange={this.changeNumber} name="shippingTime"
                                size="medium" fullWidth={true} label={t('shippingTime')} disabled={locked} data-cy='shippingTime'
                                error={errors[t('shippingTime')] != null ? true : false} helperText={errors[t('shippingTime')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('testTurnaround')}</FormLabel>
                                    {this.state.testTurnaround}
                                </FormControl>
                            }
                        </Grid>
                        {this.state.stepItems != null && this.state.stepItems.length > 0 &&
                            <Grid item xs={8} sm={4}>
                                {!locked &&
                                    <AutoCompleteField
                                        value={this.state.startSel}
                                        options={this.state.stepItems}
                                        onChange={this.changeAuto('startSel', 'startItem')}
                                        error={errors[t('startItem')] != null ? true : false}
                                        helperText={errors[t('startItem')]}
                                        label={t('startItem')}
                                        disabled={locked}
                                        data-cy='startItem'
                                    />
                                }
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('startItem')}</FormLabel>
                                        {this.state.startSel != null ? this.state.startSel.label: ''}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {this.state.totalTimeString != null && this.state.totalTimeString !== '' &&
                            <Grid item xs={8} sm={4}>
                                <FormControl>
                                    <FormLabel>{t('totalDuration')}</FormLabel>
                                    {this.state.totalTimeString}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.startDate != null &&
                            <Grid item xs={8} sm={4}>
                                <FormControl>
                                    <FormLabel>{t('startDate')}</FormLabel>
                                    <DateDisplay format={this.props.auth.user.dateFormat} value={this.state.startDate}/>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={8}>
                            {this.state.outputQuantity != null && this.state.outputQuantity != null && this.state.outputQuantity !== '' &&
                                this.state.outputUOM != null && this.state.outputUOM !== '' && this.state.activities != null &&
                                <Aux>
                                    {!locked &&
                                        <Aux>
                                            <Button variant="contained" color="primary" disabled={locked} size="small"
                                                onClick={this.calculateClick}>{t('calculate')}</Button>&nbsp;&nbsp;
                                            <Button variant="contained" color="primary" disabled={locked && !this.state.enableSave} onClick={this.save} size="small" data-cy='save'>
                                                {t('save')}
                                            </Button>&nbsp;&nbsp;
                                            {this.state._id != null && this.state._id !== '' &&
                                                <Aux>
                                                    <Button variant="contained" color="secondary" size="small" disabled={locked} onClick={this.scheduleClick} data-cy='schedule'>
                                                        {t('schedule')}
                                                    </Button>&nbsp;&nbsp;
                                                </Aux>
                                            }
                                            {this.state._id != null && this.state._id !== '' && (this.state.productionSchedule == null ||
                                                this.state.productionSchedule === '') &&
                                                <Aux>
                                                    <Button variant="contained" color="secondary" size="small" disabled={locked} onClick={this.deleteToggle} data-cy='delete'>
                                                        {t('delete')}
                                                    </Button> &nbsp;&nbsp;
                                                </Aux>
                                            }
                                        </Aux>
                                    }
                                    {locked &&
                                        <Aux>
                                            <Button variant="contained" color="secondary" size="small" href={"/#/productionschedule/" + this.state.productionSchedule} data-cy='viewSchedule'>
                                                {t('viewSchedule')}
                                            </Button>&nbsp;&nbsp;
                                        </Aux>
                                    }
                                </Aux>
                            }
                            <Button variant="contained" color="secondary" onClick={this.return} size="small">{t('return')}</Button>
                        </Grid>
                    </Grid>
                    {this.state.activities != null && this.state.activities.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('activityLines')}/>
                            {this.state.activities.map((row, i) =>
                                <Box borderColor="primary.main" {...defaultProps} key={row._id}>
                                    {row.routing != null &&
                                        <Aux>
                                            <Grid container spacing={3}>
                                                <Grid item xs={8} sm={4}>
                                                    <FormControl>
                                                        <FormLabel>{t('routing')}</FormLabel>
                                                        <Link to={'/routing/' + row.routing._id}>{row.routing.number + ' - ' + row.routing.name}</Link>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <FormControl>
                                                        <FormLabel>{t('outputItem')}</FormLabel>
                                                        {row.item.number + ' - ' + row.item.name}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4} sm={2}>
                                                    <FormControl>
                                                        <FormLabel>{t('durationM')}</FormLabel>
                                                    {row.totalMinutes}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4} sm={2}>
                                                    <TextField value={row.additionalTime} onChange={this.changeLineNumber(i)} name="additionalTime"
                                                    size="medium" fullWidth={true} label={t('additionalTimeM')} disabled={locked}
                                                    error={errors[t('additionalTime')] != null ? true : false} helperText={errors[t('additionalTime')]}/>
                                                </Grid>
                                                {row.totalTime != null &&
                                                    <Grid item xs={4} sm={2}>
                                                        <FormControl>
                                                            <FormLabel>{t('totalTimeM')}</FormLabel>
                                                            {row.totalTime}
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {row.startDate != null &&
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControl>
                                                            <FormLabel>{t('startDate')}</FormLabel>
                                                            <DateDisplay format="yyyy/M/d hh:mm a" value={row.startDate}/>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {row.endDate != null &&
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControl>
                                                            <FormLabel>{t('endDate')}</FormLabel>
                                                            <DateDisplay format="yyyy/M/d hh:mm a" value={row.endDate}/>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                            </Grid>
                                            {row.routingLines.map((rl, a) =>
                                                <Aux key={rl._id}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={2} sm={1}></Grid>
                                                        <Grid item sm={3}>
                                                            {rl.equipment != null &&
                                                                <FormControl>
                                                                    <FormLabel>{t('equipment')}</FormLabel>
                                                                    <Link to={'/equipment/' + rl.equipment._id}>
                                                                        {rl.equipment.identityNo + ' - ' + rl.equipment.name}
                                                                    </Link>
                                                                </FormControl>
                                                            }
                                                            {rl.name != null &&
                                                                 <FormControl>
                                                                    <FormLabel>{t('name')}</FormLabel>
                                                                        {rl.name}
                                                                </FormControl>
                                                            }
                                                        </Grid>
                                                        <Grid item sm={8}>
                                                            <FormControl>
                                                                <FormLabel>{t('capacity')}</FormLabel>
                                                                {rl.equipment != null && rl.equipment.capUOMSel != null ? rl.equipment.capacity + rl.equipment.capUOMSel.label : rl.capacity != null ? rl.capacity : ''}
                                                            </FormControl>
                                                            &nbsp;&nbsp;
                                                            {rl.equipment != null &&
                                                                <Aux>
                                                                     <FormControl>
                                                                        <FormLabel>{t('maxMin')}</FormLabel>
                                                                        {rl.equipment.minUOMSel != null ?
                                                                            rl.equipment.maxProcessTime + '/' + rl.equipment.minProcessTime + rl.equipment.minUOMSel.label : ''}
                                                                    </FormControl>
                                                                    &nbsp;&nbsp;
                                                                    <FormControl>
                                                                        <FormLabel>{t('setup')}</FormLabel>
                                                                        {rl.equipment.setupUOMSel != null ? rl.equipment.setupTime + rl.equipment.setupUOMSel.label : ''}
                                                                    </FormControl>
                                                                    &nbsp;&nbsp;
                                                                    <FormControl>
                                                                        <FormLabel>{t('wait')}</FormLabel>
                                                                        {rl.equipment.waitUOMSel != null ? rl.equipment.waitTime + rl.equipment.waitUOMSel.label : ''}
                                                                    </FormControl>
                                                                    &nbsp;&nbsp;
                                                                    <FormControl>
                                                                        <FormLabel>{t('move')}</FormLabel>
                                                                        {rl.equipment.moveUOMSel != null ? rl.equipment.moveTime + rl.equipment.moveUOMSel.label : ''}
                                                                    </FormControl>
                                                                    &nbsp;&nbsp;
                                                                    <FormControl>
                                                                        <FormLabel>{t('runTime')}</FormLabel>
                                                                        {rl.equipment.moveUOMSel != null ? rl.totalRunTime + rl.equipment.moveUOMSel.label : ''}
                                                                    </FormControl>
                                                                    &nbsp;&nbsp;
                                                                </Aux>
                                                            }
                                                            {rl.equipment == null && rl.runTime != null &&
                                                                <FormControl>
                                                                    <FormLabel>{t("runTime")}</FormLabel>
                                                                    {rl.runTime}
                                                                </FormControl>
                                                            }
                                                            {rl.runs != null &&
                                                                <Aux>
                                                                    &nbsp;&nbsp;
                                                                    <FormControl>
                                                                        <FormLabel>{t('runs')}</FormLabel>
                                                                        {rl.runs}
                                                                    </FormControl>
                                                                    &nbsp;&nbsp;
                                                                    <FormControl>
                                                                        <FormLabel>{t('totalTime')}</FormLabel>
                                                                        {rl.totalTime}
                                                                    </FormControl>
                                                                </Aux>
                                                            }

                                                        </Grid>
                                                    </Grid>
                                                </Aux>
                                            )}
                                            <Grid container spacing={3}>
                                                <Grid item xs={8} sm={4}>
                                                    <FormControl>
                                                        <FormLabel>{t('bom')}</FormLabel>
                                                        <Link to={'/bom/' + row.productionBom._id}>
                                                            {row.productionBom.number + ' - ' + row.productionBom.description}
                                                        </Link>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <SubSectionHeading title={t('inputs')}/>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} sm={3}><FormLabel>{t('item')}</FormLabel></Grid>
                                                <Grid item sm={4}><FormLabel>{t('quantity')}</FormLabel></Grid>
                                                <Grid item sm={4}><FormLabel>{t('runQuantity')}</FormLabel></Grid>
                                            </Grid>
                                            {row.bomLines.map((bl, j) =>
                                                <Grid container spacing={3} key={bl._id}>
                                                    {bl.item != null &&
                                                        <Aux>
                                                            <Grid item sm={3}>
                                                                    <Link to={'/item/' + bl.item._id}>{bl.item.number + ' - ' + bl.item.name}</Link>
                                                            </Grid>
                                                            <Grid item sm={4}>
                                                                {bl.uomSel != null ?
                                                                row.productionBom.lines[j].quantity +
                                                                    bl.uomSel.label :
                                                                    row.productionBom.lines[j].quantity}
                                                            </Grid>
                                                            <Grid item sm={4}>
                                                                {bl.itemAmount != null && <NumericFormat displayType={'text'} value={bl.itemAmount} thousandSeparator={true}
                                                                decimalScale={3} suffix={bl.uomSel != null ? bl.uomSel.label : ''}/>}
                                                            </Grid>
                                                        </Aux>
                                                    }
                                                    {bl.productionBom != null &&
                                                        <Grid item xs={8} sm={4}>
                                                            <FormControl>
                                                                <FormLabel>{t('productionBom')}</FormLabel>
                                                                <Link to={'/bom/' + bl.productionBom._id}>{bl.productionBom.number + ' - ' + bl.productionBom.description}</Link>
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            )}
                                        </Aux>
                                    }
                                    {row.batchTemplate != null && row.batchTemplate._id != null &&
                                        <Aux>
                                            <Grid container spacing={3}>
                                                <Grid item xs={8} sm={4}>
                                                    <FormControl>
                                                        <FormLabel>{t('batchTemplate')}</FormLabel>
                                                        <Link to={'/batchtemplate/' + row.batchTemplate._id}>
                                                            {row.batchTemplate.code + ' - ' + row.batchTemplate.description}
                                                        </Link>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <FormControl>
                                                        <FormLabel>{t('outputItem')}</FormLabel>
                                                        <Link to={'/item/' + row.item._id}>{row.item.number + ' - ' + row.item.name}</Link>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <FormControl>
                                                        <FormLabel>{t('quantity')}</FormLabel>
                                                        {row.quantity != null ? row.quantity + row.item.uom.label : ''}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4} sm={2}>
                                                    <FormControl>
                                                        <FormLabel>{t('durationD')}</FormLabel>
                                                        {row.totalDays}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4} sm={2}>
                                                    <TextField value={row.additionalTime} onChange={this.changeLineNumber(i)} name="additionalTime"
                                                    size="medium" fullWidth={true} label={t('additionalTimeD')} disabled={locked}
                                                    error={errors[t('additionalTime')] != null ? true : false} helperText={errors[t('additionalTime')]}/>
                                                </Grid>
                                                {row.startDate != null &&
                                                    <Grid item xs={8} sm={4}>
                                                        <FormControl>
                                                            <FormLabel>{t('startDate')}</FormLabel>
                                                            <DateDisplay value={row.startDate} format={this.props.auth.user.dateFormat}/>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {row.endDate != null &&
                                                    <Grid item xs={8} sm={4}>
                                                        <FormControl>
                                                            <FormLabel>{t('endDate')}</FormLabel>
                                                            <DateDisplay value={row.endDate} format={this.props.auth.user.dateFormat}/>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <br/>
                                            <Grid container spacing={1}>
                                                <Grid item xs={4} sm={2}><FormLabel>{t('stage')}</FormLabel></Grid>
                                                <Grid item xs={4} sm={2}><FormLabel>{t('durationD')}</FormLabel></Grid>
                                                <Grid item xs={4} sm={2}><FormLabel>{t('plants')}</FormLabel></Grid>
                                            </Grid>
                                            {row.stages.map((stage, i) =>
                                                <Grid container spacing={3} key={row._id}>
                                                    <Grid item xs={4} sm={2}>{stage.stage.label}</Grid>
                                                    <Grid item xs={4} sm={2}>{stage.duration}</Grid>
                                                    <Grid item xs={4} sm={2}>{stage.plantCount != null ? stage.plantCount : ''}</Grid>
                                                </Grid>
                                            )}
                                        </Aux>
                                    }
                                    {row.type != null &&
                                        <Aux>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6} sm={3}>
                                                    <FormControl>
                                                        <FormLabel>{t('type')}</FormLabel>
                                                        {row.type === this.props.auth.constants.objectIds.dryingOrder ? t('drying') :
                                                        row.type === this.props.auth.constants.objectIds.trimmingOrder ? t('trimming') :
                                                            row.type === this.props.auth.constants.objectIds.curing ? t('curing') : ''}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={8} sm={4}>
                                                    <FormControl>
                                                        <FormLabel>{t('outputItem')}</FormLabel>
                                                        <Link to={'/item/' + row.item._id}>{row.item.number + '-' + row.item.name}</Link>
                                                    </FormControl>
                                                </Grid>
                                                {row.dryingType != null &&
                                                    <Grid item xs={8} sm={4}>
                                                        <FormControl>
                                                            <FormLabel>{t('dryingType')}</FormLabel>
                                                            {row.dryingType.label}
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {(row.type === this.props.auth.constants.objectIds.dryingOrder || row.type === this.props.auth.constants.objectIds.curing) &&
                                                    <Aux>
                                                        <Grid item xs={4} sm={2}>
                                                            <FormControl>
                                                                <FormLabel>{t('durationD')}</FormLabel>
                                                                {row.totalDays}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={4} sm={2}>
                                                            <TextField value={row.additionalTime} onChange={this.changeLineNumber(i)} name="additionalTime"
                                                            size="medium" fullWidth={true} label={t('additionalTimeD')} disabled={locked} data-cy={'additionalTime' + i}
                                                            error={errors[`${t('additionalTime')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('additionalTime')} (${t('line')} ${i})`]}/>
                                                        </Grid>
                                                    </Aux>
                                                }
                                                {row.type === this.props.auth.constants.objectIds.trimmingOrder &&
                                                    <Aux>
                                                        {row.equipment != null &&
                                                            <Grid item xs={6} sm={3}>
                                                                <FormControl>
                                                                    <FormLabel>{t('equipment')}</FormLabel>
                                                                    <Link to={'/equipment/' + row.equipment._id}>{row.equipment.identityNo + '-' + row.equipment.name}</Link>
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        {row.runs != null &&
                                                            <Grid item xs={4} sm={2}>
                                                                <FormControl>
                                                                    <FormLabel>{t('runs')}</FormLabel>
                                                                    {row.runs}
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        <Grid item xs={4} sm={2}>
                                                            <TextField value={row.additionalTime} onChange={this.changeLineNumber(i)} name="additionalTime"
                                                            size="medium" fullWidth={true} label={t('additionalTimeM')} disabled={locked} 
                                                            error={errors[`${t('additionalTime')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('additionalTime')} (${t('line')} ${i})`]}/>
                                                        </Grid>
                                                    </Aux>
                                                }
                                            </Grid>
                                            {row.startDate != null &&
                                                <Grid container spacing={3}>
                                                    <Grid item xs={8} sm={4}>
                                                        <FormControl>
                                                            <FormLabel>{t('startDate')}</FormLabel>
                                                            <DateDisplay value={row.startDate} format={this.props.auth.user.dateFormat}/>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={8} sm={4}>
                                                        <FormControl>
                                                            <FormLabel>{t('endDate')}</FormLabel>
                                                            <DateDisplay value={row.endDate} format={this.props.auth.user.dateFormat}/>
                                                        </FormControl>
                                                    </Grid>
                                                    {row.quantity != null &&
                                                        <Grid item xs={4} sm={2}>
                                                            <FormControl>
                                                                <FormLabel>{t('quantity')}</FormLabel>
                                                                {row.quantity + row.item.uom.label}
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            }
                                        </Aux>
                                    }
                                </Box>
                            )}
                        </Aux>
                    }
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.productionPlan}/>
                            <ObjectLog id={this.state._id}/>
                        </Aux>
                    }
                </Panel>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ProductionPlan));
