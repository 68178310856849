import React,{ Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import { Link } from 'react-router-dom';
import ItemTests from './ItemTests';
import ItemLots from './ItemLots';
import ItemBatches from './ItemBatches';
import ItemOutputValues from './ItemOutputValues';
import Aux from '../../hoc/Auxilary/Auxilary';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ItemTransactions from './ItemTransactions';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import SectionTitle from '../UI/Typography/SectionTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ActionBar from '../UI/Buttons/ActionBar';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import { withTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ItemContainers from './ItemContainers';
import { NumericFormat } from 'react-number-format';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import IconButton from '@mui/material/IconButton';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import ItemCategoryDialog from './Dialogs/ItemCategory';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import ShipmentsIcon from '@mui/icons-material/DirectionsBoatTwoTone';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '../UI/Switch/Switch';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import ItemNeobiSales from './Widgets/ItemNeobiSales';
import ItemNeobiStores from './Widgets/ItemNeobiStores';
import ItemTotals from './ItemTotals';

class Item extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            name: '',
            number: '',
            category: '',
            categories: [],
            uom: '',
            uoms: [],
            purchaseUOM: '',
            purchaseUOMSel: null,
            eachQuantity: '',
            strain: [],
            showStrain: false,
            inventory: '',
            price: '',
            count: '',
            locked: true,
            testDefinition: null,
            productionBom: '',
            productionBoms: [],
            routing: '',
            routings: [],
            catSelected: null,
            uomSelected: null,
            routingSel: null,
            cureTime: '',
            cureTimeUOM: '',
            cureUOMSel: null,
            bomSel: null,
            strainSelected: null,
            timeUOMs: [],
            trackingTypes: [],
            tracking: '',
            trackingSel: null,
            batchTemplate: '',
            batchTemplateSel: null,
            batchTemplates: [],
            reorderLeadTime: '',
            dryingTimes: [],
            dryingType: '',
            dryingTypeSel: null,
            dryingTypes: [],
            dryingDays: '',
            showDrying: false,
            dryingIndex: -1,
            exportName: '',
            taxItem: '',
            taxItemSel: null,
            taxItems: [],
            thcMg: '',
            cbdMg: '',
            packSizeG: '',
            newLotStatus: '',
            newLotStatusSel: null,
            lotStatuses: [],
            expirationMonths: '',
            cost: '',
            avgWeight: '',
            producedBy: [],
            tabKey: 0,
            security: {},
            listSize: 5,
            offset: 0,
            page: 0,
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            tabLabels: [
                {name: this.props.t('item'), disabled: false},
                {name: this.props.t('values'), disabled: false},
                {name: this.props.t('transactions'), disabled: false},
                {name: this.props.t('totals'), disabled: false}
            ],
            enableSave: true,
            isCannabis: false,
            isPesticide: false,
            registrationNumber: '',
            expectedUnitCost: '',
            newRouting: '',
            newRoutingSel: null,
            newBom: '',
            newBomSel: null,
            newProducedPrimary: false,
            vendors: [],
            vendorsSel: [],
            vendorList: [],
            fullDiscreteWeight: '',
            fullDiscreteWeightUOM: '',
            fullDiscreteWeightUOMSel: null,
            ctlsExclude: false,
            categoryObject: null,
            showCategory: false,
            feedingRecipe: '',
            feedingRecipeSel: null,
            feedingRecipes: [],
            hide: false,
            sku: '',
            gtin: '',
            minimumQuantity: '',
            productNumber: '',
            description2: '',
            fullCost: '',
            caseQuantity: '',
            hasRecords: false,
            totalUnitWeightG: 0,
            casedItem: '',
            casedItemSel: null,
            items: [],
	        documents: [],
            pesticideRecipe: '',
            pestRecipeSel: null,
            pesticideRecipes: [],
            b300Exclude: false,
            showPurchaseUOM: false,
            trackingRequired: false,
            canPackSize: false,
            packCount: '',
            hcReportCategorySel: null,
            hcReportCategories: [],
            showCopy: false,
            copyName: '',
            copyNumber: '',
	        isCasedItem: false,
            casedItems: [],
	        skuOwner: '',
            skuOwnerSel: null,
            packaged: false,
            seeds: false,
            containerTypes: [],
            totals: [],
            boms: [],
            excised: false,
            isPackagedItem: false,
            packagedItem: '',
            packagedItemSel: null,
            packagedItems: [],
	        inventoryDetail: null,
            thcPercent: '',
            hasTHC: false,
            isHCSeeds: false,
            allItems: [],
            testDefinitions: [],
            testDefinitionsSel: null,
            testDefinitionList: [],
            packageThc: null,
            unitThc: null,
            bomPage: 0,
            bomSize: 5,
            bomCount: 0,
            allBoms: [],
            cleaningSolutionRecipes: [],
            cleaningSolutionRecipeSel: null,
            cleaningSolutionRecipe: '',
            feedingRecipesAll: [],
            salesData: null
        }
    }

    async componentDidMount(){
        let locked = (this.props.match.params.id == null || this.props.match.params.id === '') && this.props.permission.create ? false : true;
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.vendor, common.cacheValues.strain, common.cacheValues.bom, common.cacheValues.routing,
                            common.cacheValues.itemCategory, common.cacheValues.quantityUOM, common.cacheValues.timeUOM, common.cacheValues.batchTemplate,
                            common.cacheValues.lotStatus, common.cacheValues.itemTrackingType, common.cacheValues.taxItem, common.cacheValues.feedingRecipe,
                            common.cacheValues.dryType, common.cacheValues.item, common.cacheValues.pesticideRecipe, common.cacheValues.hcReportCategory, 
                            common.cacheValues.casedItem, common.cacheValues.containerType, common.cacheValues.packagedItem, common.cacheValues.allItems,
                            common.cacheValues.testDefinition, common.cacheValues.cleaningSolutionRecipe, common.cacheValues.feedingRecipeAll], 
                            this.props.auth.constants, null, this.props.auth.user.tenant);
        let vendors = cacheValues.vendors;
        let strains = cacheValues.strains;
        this.setState({
            categories: cacheValues.itemCategories,
            uoms: cacheValues.quantityUOMs,
            strains: strains,
            productionBoms: cacheValues.boms,
            routings: cacheValues.routings,
            timeUOMs: cacheValues.timeUOMs,
            trackingTypes: cacheValues.itemTrackingTypes,
            batchTemplates: cacheValues.batchTemplates,
            dryingTypes: cacheValues.dryingTypes,
            lotStatuses: cacheValues.lotStatuses,
            taxItems: cacheValues.taxItems,
            vendorList: vendors,
            _id: this.props.match.params.id != null && this.props.match.params._id !== '' ? this.props.match.params.id : '',
            security: this.props.permission,
            locked: locked,
            feedingRecipes: cacheValues.feedingRecipes,
            items: cacheValues.items,
            allItems: cacheValues.allItems,
            pesticideRecipes: cacheValues.pesticideRecipes,
            hcReportCategories: cacheValues.hcReportCategories,
            casedItems: cacheValues.casedItems,
            packagedItems: cacheValues.packagedItems,
            containerTypes: cacheValues.containerTypes,
            testDefinitionList: cacheValues.testDefinitions,
            cleaningSolutionRecipes: cacheValues.cleaningSolutionRecipes,
            feedingRecipesAll: cacheValues.feedingRecipesAll
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else { 
                const crumbs = [
                    { path: '/#/itemlist', label: this.props.t('items'), screen: this.props.auth.screenDefs.ItemList},
                    { path: 'active', label: this.props.t('item'), screen: this.props.auth.screenDefs.Item}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const constants = this.props.auth.constants;
        const record = await axios.get('/api/item/' + this.state._id);
        const invResult = await axios.get('/api/iteminventory/' + this.state._id);
        let showStrain = record.data.strain != null && record.data.strain !== '';
        let catSelected = this.state.categories.find(x => x.value === record.data.category);
        let hcReportCategorySel = catSelected.hcReportCategory != null ? this.state.hcReportCategories.find(x => x.value === catSelected.hcReportCategory) : null;
        let packaged = false;
        let hasTHC = false;
        if(hcReportCategorySel != null){
            packaged = ValueHelper.packagedHCCategory(hcReportCategorySel.value);
            hasTHC = ValueHelper.thcHCCategory(hcReportCategorySel.value);
        }
        let seeds = record.data.category === constants.itemCategories.seeds ? true : false;
        let isHCSeeds = false;
        if(catSelected != null && catSelected.hcReportCategory != null){
            isHCSeeds = catSelected.hcReportCategory === common.hcReportCategories.seeds || catSelected.hcRepportCategory === common.hcReportCategories.seedsPacked ? true : false;
        }
        let isCannabis = catSelected != null ? catSelected.isCannabis : false;
        let isPesticide = catSelected != null ? catSelected.isPesticide : false;
        let uomSelected = this.state.uoms.find(x => x.value === record.data.uom);
        let purchaseUOMSel = record.data.purchaseUOM != null ? this.state.uoms.find(x => x.value === record.data.purchaseUOM) : null;
        let strainSelected = showStrain ? this.state.strains.find(x => x.value === record.data.strain) : null;
        let bomSel = this.state.productionBoms.find(x => x.value === record.data.productionBom);
        let routingSel = record.data.routing != null ? this.state.routings.find(x => x.value === record.data.routing) : null;
        let cureUOMSel = record.data.cureTimeUOM != null ? this.state.timeUOMs.find(x => x.value === record.data.cureTimeUOM) : null;
        let trackingSel = record.data.tracking != null ? this.state.trackingTypes.find(x => x.value === record.data.tracking) : null;
        let newLotStatusSel = record.data.newLotStatus != null && record.data.newLotStatus !== '' ? this.state.lotStatuses.find(x => x.value === record.data.newLotStatus) : null;
        let batchTemplateSel = record.data.batchTemplate != null ? this.state.batchTemplates.find(x => x.value === record.data.batchTemplate) : null;
        let taxItemSel = record.data.taxItem != null ? this.state.taxItems.find(x => x.value === record.data.taxItem) : null;
        let producedBy = record.data.producedBy != null ? record.data.producedBy : [];
        let casedItemSel = record.data.casedItem != null ? this.state.allItems.find(x => x.value === record.data.casedItem) : null;
        let packagedItemSel = record.data.packagedItem != null ? this.state.allItems.find(x => x.value === record.data.packagedItem) : null;
        let fullDiscreteWeightUOMSel = record.data.fullDiscreteWeightUOM != null && record.data.fullDiscreteWeightUOM !== '' ?
            this.state.uoms.find(x => x.value === record.data.fullDiscreteWeightUOM) : null;
        let feedingRecipeSel = record.data.feedingRecipe != null && record.data.feedingRecipe !== '' ?
            this.state.feedingRecipesAll.find(x => x.value === record.data.feedingRecipe) : null;
        let pestRecipeSel = record.data.pesticideRecipe != null && record.data.pesticideRecipe !== '' ? 
            this.state.pesticideRecipes.find(x => x.value === record.data.pesticideRecipe) : null;
        let cleaningSolutionRecipeSel = record.data.cleaningSolutionRecipe != null && record.data.cleaningSolutionRecipe !== '' ?
            this.state.cleaningSolutionRecipes.find(x => x.value === record.data.cleaningSolutionRecipe) : null;
        let skuOwnerSel = record.data.skuOwner != null && record.data.skuOwner !== '' ? this.state.vendorList.find(x => x.value === record.data.skuOwner) : null;
        let vendorsSel = [];
        if(record.data.vendors != null && record.data.vendors.length > 0){
            record.data.vendors.forEach(row => {
                let vendor = this.state.vendorList.find(x => x.value === row);
                vendorsSel.push(vendor);
            });
        }
        let testDefinitionsSel = [];
        if(record.data.testDefinitions != null && record.data.testDefinitions.length > 0){
            record.data.testDefinitions.forEach(row => {
                let testDef = this.state.testDefinitionList.find(x => x.value === row);
                testDefinitionsSel.push(testDef);
            });
        }
        if(producedBy.length > 0){
            for(let row of producedBy){
                row.routingSel = this.state.routings.find(x => x.value === row.routing);
                row.bomSel = this.state.productionBoms.find(x => x.value === row.productionBom);
                row.primary = row.primary === true ? true : false
            }
        }
        let dryingTimes = [];
        if(record.data.dryingTimes != null && record.data.dryingTimes.length > 0){
            dryingTimes = record.data.dryingTimes;
            dryingTimes.forEach(row => {
                let typeSel = this.state.dryingTypes.find(x => x.value === row.type);
                row.typeSel = typeSel;
            });
        }
        let totals = record.data.totals;
        if(totals != null && totals.length > 0){
            totals.forEach(row => {
                let typeSel = row.type != null ? this.state.containerTypes.find(x => x.value === row.type) : null;
                row.typeSel = typeSel;
            });
        }
        let tabLabels = [
            {name: this.props.t('item'), disabled: false},
            {name: this.props.t('values'), disabled: false},
            {name: this.props.t('transactions'), disabled: false},
            {name: this.props.t('totals'), disabled: false}
        ];
        if(record.data.tracking != null && record.data.tracking !== ''){
            tabLabels.push({name: this.props.t('containers'), disabled: false});
        }
        if(this.props.auth.user.neobi === true && record.data.neobi?.salesFile != null && record.data.neobi.salesFile !== ''){
            tabLabels.push({name: this.props.t('sales'), disabled: false});
        }
        let boms = record.data.boms.slice(0, this.state.bomSize);
        
        this.setState({
            _id: record.data._id,
            name: record.data.name,
            number: record.data.number,
            category: record.data.category,
            uom: record.data.uom,
            strain: record.data.strain,
            showStrain: showStrain,
            catSelected: catSelected,
            uomSelected: uomSelected,
            strainSelected: strainSelected,
            purchaseUOM: record.data.purchaseUOM,
            purchaseUOMSel: purchaseUOMSel,
            eachQuantity: record.data.eachQuantity,
            inventory: record.data.inventory != null ? parseFloat(record.data.inventory).toFixed(2) : '',
            price: record.data.price,
            count: record.data.count,
            testDefinition: record.data.testDefinition,
            productionBom: record.data.productionBom,
            bomSel: bomSel,
            routing: record.data.routing !== undefined ? record.data.routing : null,
            routingSel: routingSel,
            producedBy: producedBy,
            cureTime: record.data.cureTime,
            cureTimeUOM: record.data.cureTimeUOM,
            cureUOMSel: cureUOMSel,
            tracking: record.data.tracking,
            trackingSel: trackingSel,
            batchTemplate: record.data.batchTemplate,
            batchTemplateSel: batchTemplateSel,
            reorderLeadTime: record.data.reorderLeadTime != null ? record.data.reorderLeadTime : '',
            dryingTimes: dryingTimes,
            exportName: record.data.exportName != null ? record.data.exportName : '',
            taxItem: record.data.taxItem,
            taxItemSel: taxItemSel,
            thcMg: record.data.thcMg,
            cbdMg: record.data.cbdMg,
            packSizeG: record.data.packSizeG,
            newLotStatus: record.data.newLotStatus,
            newLotStatusSel: newLotStatusSel,
            expirationMonths: record.data.expirationMonths,
            cost: record.data.cost,
            avgWeight: record.data.avgWeight,
            isCannabis: isCannabis,
            tabLabels: tabLabels,
            isPesticide: isPesticide,
            registrationNumber: record.data.registrationNumber,
            expectedUnitCost: record.data.expectedUnitCost,
            vendors: record.data.vendors,
            vendorsSel: vendorsSel,
            fullDiscreteWeight: record.data.fullDiscreteWeight,
            fullDiscreteWeightUOMSel: fullDiscreteWeightUOMSel,
            fullDiscreteWeightUOM: record.data.fullDiscreteWeightUOM,
            ctlsExclude: record.data.ctlsExclude === true ? true : false,
            b300Exclude: record.data.b300Exclude === true ? true : false,
            feedingRecipe: record.data.feedingRecipe,
            feedingRecipeSel: feedingRecipeSel,
            hide: record.data.hide === true ? true : false,
            sku: record.data.sku,
            gtin: record.data.gtin,
            minimumQuantity: record.data.minimumQuantity != null ? record.data.minimumQuantity : '',
            productNumber: record.data.productNumber,
            description2: record.data.description2,
            fullCost: record.data.fullCost,
            caseQuantity: record.data.caseQuantity,
            hasRecords: record.data.hasRecords,
            totalUnitWeightG: record.data.totalUnitWeightG,
            casedItem: record.data.casedItem,
            casedItemSel: casedItemSel,
	        documents: record.data.documents,
            pesticideRecipe: record.data.pesticideRecipe,
            pestRecipeSel: pestRecipeSel,
            packCount: record.data.packCount,
            hcReportCategorySel: hcReportCategorySel,
	        isCasedItem: record.data.isCasedItem === true ? true : false,
            skuOwner: record.data.skuOwner,
            skuOwnerSel: skuOwnerSel,
            packaged: packaged,
            seeds: seeds,
            totals: totals,
            boms: boms,
            allBoms: record.data.boms,
            excised: record.data.excised === true || record.data.excised === 'true' ? true : false,
            packagedItem: record.data.packagedItem,
            packagedItemSel: packagedItemSel,
            isPackagedItem: record.data.isPackagedItem === true ? true : false,
	        inventoryDetail: invResult.data,
            hasTHC: hasTHC,
            thcPercent: record.data.thcPercent,
            isHCSeeds: isHCSeeds,
            testDefinitionsSel: testDefinitionsSel,
            testDefinitions: record.data.testDefinitions,
            packageThc: record.data.packageThc,
            unitThc: record.data.unitThc,
            cleaningSolutionRecipeSel: cleaningSolutionRecipeSel,
            cleaningSolutionRecipe: record.data.cleaningSolutionRecipe
        }, () => {
            const crumbs = [
                { path: '/#/itemlist', label: this.props.t('items'), screen: this.props.auth.screenDefs.ItemList},
                { path: 'active', label: this.props.t('item') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.Item}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { 
                options: [
                    { menuType: this.props.auth.constants.optionMenuTypes.comments },
                    { menuType: this.props.auth.constants.optionMenuTypes.logs }
                ],
                title: this.props.t('options')
            }});
            this.setFlags();
        });
        this.loadRecords();
        if(record.data.neobi?.salesFile != null && record.data.neobi.salesFile !== ''){
            this.loadSalesData();
        }
    };

    loadSalesData = async () => {
        let result = await axios.get('/api/saledata/' + this.state._id);
        let salesJson = result.data;
        this.setState({salesData: salesJson});
    }

    setFlags = () => {
        const quantityUOMs = this.props.auth.constants.quantityUOMs;
        if(this.state.catSelected != null){
            const catSelected = this.state.catSelected;
            let showPurchaseUOM = false;
            let trackingRequired = catSelected != null && catSelected.tracking != null ? true : false;
            let isCannabis = catSelected != null && catSelected.isCannabis === true ? true : false;
            if(this.state.isCannabis || catSelected.customLotNumber === true || catSelected.itemThcMg === true || catSelected.lotThcMg === true || 
                catSelected.tare === true || catSelected.hasDiscrete === true || catSelected.packagedOn === true || catSelected.strainRequired === true ||
                catSelected.curingRequired === true || catSelected.drying === true || catSelected.trimming === true){
                trackingRequired = true;
            } else if(catSelected.infinite === true){
                trackingRequired = false;
            }
            if(this.state.uom != null){
                if(this.state.uom === quantityUOMs.each || this.state.uom === quantityUOMs.g || this.state.uom === quantityUOMs.mg || 
                    this.state.uom === quantityUOMs.ml || this.state.uom === quantityUOMs.kg || this.state.uom === quantityUOMs.l){
                    showPurchaseUOM = true;
                }
            }
            let canPackSize = this.state.uom !== quantityUOMs.g && this.state.uom !== quantityUOMs.mg && this.state.uom !== quantityUOMs.kg && 
                this.state.uom !== quantityUOMs.ml && this.state.uom !== quantityUOMs.l && this.state.casedItemSel == null;
            this.setState({
                showPurchaseUOM: showPurchaseUOM, 
                trackingRequired: trackingRequired, 
                isCannabis: isCannabis, 
                canPackSize: canPackSize
            });
        }
    };

    loadRecords = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let saleParams = {
            size: this.state.listSize,
            offset: this.state.offset,
            sort: JSON.stringify(sort)
        }
        let sales = await axios.get('/api/itemsales/' + this.state._id, {params: saleParams});
        this.setState({
            sales: sales.data.list,
            totalCount: sales.data.totalCount
        });
    };

    changeValue = e => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[e.target.name]: e.target.value}, () => {
            this.processChange(name, value);
        });
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            this.processChange(data, newValue != null ? newValue.value : null, newValue);
        });
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value});
    };

    processChange(name, value, newValue){
        let showStrain = this.state.showStrain;
        let isCannabis = this.state.isCannabis;
        let tracking = this.state.tracking;
        let trackingSel = this.state.trackingSel;
        let hcReportCategorySel = this.state.hcReportCategorySel;
        let purchaseUOM = this.state.purchaseUOM;
        let purchaseUOMSel = this.state.purchaseUOMSel;
        let eachQuantity = this.state.eachQuantity;
        let avgWeight = this.state.avgWeight;
        let packSizeG = this.state.packSizeG;
        let thcMg = this.state.thcMg;
        let cbdMg = this.state.cbdMg;
        let packCount = this.state.packCount;
        let caseQuantity = this.state.caseQuantity;
        let casedItem = this.state.casedItem;
        let casedItemSel = this.state.casedItemSel;
        let isCasedItem = this.state.isCasedItem;
        let seeds = this.state.seeds;
        let packaged = this.state.packaged;
        let hasTHC = this.state.hasTHC;
        let isHCSeeds = this.state.isHCSeeds;
        if(name === 'category'){
            if(value !== '' && value != null){
                let cat = this.state.categories.find(x => x.value === value);
                hcReportCategorySel = newValue.hcReportCategory != null ? this.state.hcReportCategories.find(x => x.value === newValue.hcReportCategory) : null;
                if(hcReportCategorySel != null){
                    packaged = ValueHelper.packagedHCCategory(hcReportCategorySel.value);
                    hasTHC = ValueHelper.thcHCCategory(hcReportCategorySel.value);
                }
                if(cat.tracking != null){
                    if(tracking === '' || trackingSel == null){
                        tracking = cat.tracking;
                        trackingSel = this.state.trackingTypes.find(x => x.value === tracking);
                    }
                }
                if(cat != null){
                    showStrain = cat.strainRequired;
                    isCannabis = cat.isCannabis;
                }
                seeds = value === this.props.auth.constants.itemCategories.seeds ? true : false;
                isHCSeeds = newValue.hcReportCategory != null && (newValue.hcReportCategory === common.hcReportCategories.seeds || 
                    newValue.hcReportCategory === common.hcReportCategories.seedsPacked) ? true : false;
            } else {
                showStrain = false;
                isCannabis = false;
                hcReportCategorySel = null;
                seeds = false;
                isHCSeeds = false;
            }
            thcMg = '';
            cbdMg = '';
        } else if (name === 'uom'){
            purchaseUOM = '';
            purchaseUOMSel = null;
            eachQuantity = '';
            avgWeight = '';
            packSizeG = '';
            packCount = '';
            caseQuantity = '';
            casedItem = '';
            casedItemSel = null;
        } else if (name === 'casedItem'){
            isCasedItem = false;
        }
        this.setState({ 
            showStrain: showStrain, 
            isCannabis: isCannabis, 
            tracking: tracking, 
            trackingSel: trackingSel, 
            hcReportCategorySel: hcReportCategorySel,
            purchaseUOM: purchaseUOM,
            purchaseUOMSel: purchaseUOMSel,
            eachQuantity: eachQuantity,
            avgWeight: avgWeight,
            packSizeG: packSizeG,
            thcMg: thcMg,
            cbdMg: cbdMg,
            packCount: packCount,
            caseQuantity: caseQuantity,
            casedItem: casedItem,
            casedItemSel: casedItemSel,
            isCasedItem: isCasedItem,
            seeds: seeds,
            packaged: packaged,
            hasTHC: hasTHC,
            isHCSeeds: isHCSeeds
        }, () => {
            this.setFlags();
        });
    }

    changeMulti = (name, data) => (e, newValue) => {
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    changeBool = (name) => (e) => {
        let casedItem = this.state.casedItem;
        let casedItemSel = this.state.casedItemSel;
        if(name === 'isCasedItem'){
            casedItem = '';
            casedItemSel = null;
        }
        this.setState({[name]: !this.state[name], casedItem: casedItem, casedItemSel: casedItemSel});
    }

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let producedBy = this.state.producedBy;
        if(producedBy != null && producedBy.length === 1){
            producedBy[0].primary = true;
        } else if (producedBy != null && producedBy.length > 1){
            let hasPrimary = false;
            for(let row of producedBy){
                if(row.primary){
                    hasPrimary = true;
                    break;
                }
            }
            if(!hasPrimary){
                producedBy[0].primary = true;
            }
        }
        let data = {
            _id: this.state._id,
            name: this.state.name,
            number: this.state.number,
            category: this.state.category,
            uom: this.state.uom,
            purchaseUOM: this.state.purchaseUOM,
            eachQuantity: this.state.eachQuantity,
            strain: this.state.strain,
            routing: this.state.routing,
            productionBom: this.state.productionBom,
            cureTime: this.state.cureTime,
            cureTimeUOM: this.state.cureTimeUOM,
            price: this.state.price,
            count: this.state.count,
            tracking: this.state.tracking,
            batchTemplate: this.state.batchTemplate,
            dryingTimes: this.state.dryingTimes,
            taxItem: this.state.taxItem,
            thcMg: this.state.thcMg,
            cbdMg: this.state.cbdMg,
            packSizeG: this.state.packSizeG,
            newLotStatus: this.state.newLotStatus,
            expirationMonths: this.state.expirationMonths,
            cost: this.state.cost,
            avgWeight: this.state.avgWeight,
            exportName: this.state.exportName != null && this.state.exportName !== '' ? this.state.exportName : null,
            reorderLeadTime: this.state.reorderLeadTime != null && this.state.reorderLeadTime !== '' ? this.state.reorderLeadTime : null,
            registrationNumber: this.state.registrationNumber,
            expectedUnitCost: this.state.expectedUnitCost,
            producedBy: this.state.producedBy,
            vendors: this.state.vendors,
            ctlsExclude: this.state.ctlsExclude,
            b300Exclude: this.state.b300Exclude,
            feedingRecipe: this.state.feedingRecipe,
            cleaningSolutionRecipe: this.state.cleaningSolutionRecipe,
            hide: this.state.hide,
            sku: this.state.sku,
            gtin: this.state.gtin,
            minimumQuantity: this.state.minimumQuantity !== '' ? this.state.minimumQuantity : null,
            productNumber: this.state.productNumber,
            description2: this.state.description2,
            fullCost: this.state.fullCost,
            caseQuantity: this.state.caseQuantity,
            totalUnitWeightG: this.state.totalUnitWeightG,
            casedItem: this.state.casedItem,
            pesticideRecipe: this.state.pesticideRecipe,
            packCount: this.state.packCount,
            isCasedItem: this.state.isCasedItem,
	        skuOwner: this.state.skuOwner,
            excised: this.state.excised,
            isPackagedItem: this.state.isPackagedItem,
            packagedItem: this.state.packagedItem,
            thcPercent: this.state.thcPercent,
            testDefinitions: this.state.testDefinitions
        };
        try{
            const result = await axios.post('/api/item', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/item/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    unlock = e => {
        this.setState({locked: !this.state.locked});
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.name == null || state.name === '')
            errors[t('name')] = t('required');
        if(state.category == null || state.category === '')
            errors[t('category')] = t('required');
        if(state.uom == null || state.uom === '')
            errors[t('uom')] = t('required');
        if(state.trackingRequired === true){
            if(state.tracking == null || state.tracking === ''){
                errors[t('tracking')] = t('required');
            }
        }
        if(state.cureTime != null && state.cureTime !== ''){
            if(state.cureTimeUOM == null || state.cureTimeUOM === '')
                errors[t('cureTimeUOM')] = t('required');
        }
        if(state.showPurchaseUOM === false){
            if(state.purchaseUOM != null && state.purchaseUOM !== '')
                errors[t('purchaseUOM')] = t('notAllowedField');
            if(state.eachQuantity != null && state.eachQuantity !== '')
                errors[t('eachQuantity')] = t('notAllowedField');
        } else {
            if((state.purchaseUOM != null && state.purchaseUOM !== '') || (state.eachQuantity != null && state.eachQuantity !== '' && state.eachQuantity !== 0)){
                if(state.purchaseUOM == null || state.purchaseUOM === '')
                    errors[t('purchaseUOM')] = t('required');
                if(state.eachQuantity == null || state.eachQuantity === '')
                    errors[t('eachQuantity')] = t('required');
            }
        }
        if(state.isPesticide){
            if(state.registrationNumber == null || state.registrationNumber === '')
                errors[t('registrationNumber')] = t('required');
        }
        if(this.props.auth.user.manualItemNumber === true && state._id != null && state._id !== ''){
            if(state.number == null || state.number === '')
                errors[t('number')] = t('required');
        }
        if(state.packSizeG != null && state.packSizeG !== '' && state.casedItem != null && state.casedItem !== ''){
            errors[t('packSizeG')] = t('cannotPackSizeAndCasedIten');
            errors[t('casedItem')] = t('cannotPackSizeAndCasedIten');
        }
        if(state.catSelected != null && state.catSelected.itemThcMg !== true){
            if(state.thcMg != null && state.thcMg !== '')
                errors[t('thcMg')] = t('notAllowedField');
            if(state.cbdMg != null && state.cbdMg !== '')
                errors[t('cbdMg')] = t('notAllowedField');
        } else if(state.catSelected != null && state.catSelected.itemThcMg === true){
            if(state.casedItemSel == null && state.packagedItemSel == null){
                if(state.thcMg == null || state.thcMg === ''){
                    errors[t('thcMg')] = t('required');
                }
            }
        }
        if(state.packaged){
            if(state.casedItemSel == null && state.packagedItemSel == null){
                if(state.packSizeG == null || state.packSizeG === '')
                    errors[t('packSizeG')] = t('required');
            }
        } else {
            if(state.isCannabis && state.uom != null && state.uom === this.props.auth.constants.quantityUOMs.each && state.catSelected != null && 
            (state.catSelected.hcReportCategory !== common.hcReportCategories.seeds && state.catSelected.hcReportCategory !== common.hcReportCategories.seedsPacked &&
            state.catSelected.hcReportCategory !== common.hcReportCategories.vegPlant && state.catSelected.hcReportCategory !== common.hcReportCategories.wholePlant && 
            state.catSelected.hcReportCategory !== common.hcReportCategories.vegPlantPacked)){
                if(state.packSizeG == null || state.packSizeG === '')
                    errors[t('packSizeG')] = t('required');
            }
        }
        if(state.catSelected != null && state.catSelected.caseLot === true){
            if(state.caseQuantity == null || state.caseQuantity === '')
                errors[t('caseQuantity')] = t('required');
        }
        if(!state.canPackSize){
            if(state.packSizeG != null && state.packSizeG !== '')
                errors[t('packSizeG')] = t('notAllowedField');
            if(state.packCount != null && state.packCount !== '')
                errors[t('packCount')] = t('notAllowedField');
        }
        if(state.packaged && this.state.avgWeight != null && this.state.avgWeight !== '' && this.state.avgWeight !== '0' && this.state.avgWeight !== 0){
            errors[t('avgWeight')] = t('notAllowedField');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e));
    }

    _deleteClick = async (e) => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/item/' + this.state._id)
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch (err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        }
    };

    toggleCopy = (e) => {
        this.setState({showCopy: !this.state.showCopy, copyName: !this.state.showCopy ? this.state.name : '', copyNumber: ''});
    };

    copyClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._copyClick(e));
    };

    _copyClick = async (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.copyName == null || this.state.copyName === ''){
            errors[t('copyName')] = t('required');
        }
        if(this.state.copyNumber != null && this.state.copyNumber !== ''){
            if(this.state.copyNumber.length < 3 || this.state.copyNumber.length > 20){
                errors[t('copyNumber')] = t('length3to20');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
        }
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.post('/api/itemcopy', {_id: this.state._id, name: this.state.copyName, number: this.state.copyNumber});
                this.setState({enableSave: true, copyName: '', copyNumber: '', showCopy: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            } catch (err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        }
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    addDryingType = (e) => {
        this.setState({showDrying: true});
    };

    editDryingType = (i) => (e) => {
        let drying = this.state.dryingTimes[i];
        this.setState({
            dryingIndex: i,
            showDrying: true,
            dryingType: drying.type,
            dryingTypeSel: drying.typeSel,
            dryingDays: drying.days
        });
    };

    deleteDrying = (e) => {
        let dryingTimes = this.state.dryingTimes;
        dryingTimes.splice(this.state.dryingIndex, 1);
        this.setState({dryingTimes: dryingTimes});
    };

    saveDrying = (e) => {
        let valid = this.validateDrying();
        if(!valid)
            return;
        let dryingTimes = this.state.dryingTimes;
        if(this.state.dryingIndex > -1){
            let drying = dryingTimes[this.state.dryingIndex];
            drying.type = this.state.dryingType;
            drying.typeSel = this.state.dryingTypeSel;
            drying.days = this.state.dryingDays;
        } else {
            dryingTimes.push({
                type: this.state.dryingType,
                typeSel: this.state.dryingTypeSel,
                days: this.state.dryingDays
            });
        }
        this.setState({dryingTimes: dryingTimes});
        this.clearDrying();
    };

    validateDrying(){
        const t = this.props.t;
        let errors = {};
        if(this.state.dryingType == null || this.state.dryingType === '')
            errors[t('dryingType')] = t('required');
        if(this.state.dryingDays == null || this.state.dryingDays === '' || this.state.dryingDays === '0')
            errors[t('dryingDays')] = t('required');
        let index = this.state.dryingTimes.findIndex(x => x.type === this.state.dryingType);
        if(index >= 0){
            if(this.state.dryingIndex !== index)
                errors[t('dryingType')] = t('dryingTypeUsed');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    };

    clearDrying = (e) => {
        this.setState({
            dryingIndex: -1,
            showDrying: false,
            dryingType: '',
            dryingTypeSel: null,
            dryingDays: ''
        });
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    handleBomChangePage = (event, newPage) => {
        this.setState({bomPage: newPage}, () => {
            this.sliceBoms();
        });
    };

    handleBomRowsPerPage = e => {
        this.setState({bomSize: parseInt(e.target.value)}, () => {
            this.sliceBoms();
        });
    };

    sliceBoms() {
        let boms = this.state.allBoms.slice(this.state.bomSize * this.state.bomPage, this.state.bomSize * this.state.bomPage + this.state.bomSize);
        this.setState({boms: boms});
    }

    addProduction = (e) => {
        const t = this.props.t;
        let producedBy = this.state.producedBy;
        let errors = {};
        if(this.state.newBom == null || this.state.newBom === '')
            errors[t('newBom')] = t('required');
        if(this.state.newRouting == null || this.state.newRouting === '')
            errors[t('newRouting')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
        }
        if(this.state.newProducedPrimary){
            for(let row of producedBy){
                row.primary = false;
            }
        }
        producedBy.push({
            routing: this.state.newRouting,
            routingSel: this.state.newRoutingSel,
            productionBom: this.state.newBom,
            primary: this.state.newProducedPrimary,
            bomSel: this.state.newBomSel
        });
        this.setState({
            producedBy: producedBy,
            newRouting: '',
            newRoutingSel: null,
            newBom: '',
            newBomSel: null,
            newProducedPrimary: false
        })
    };

    deleteProduction = (i) => (e) => {
        let producedBy = this.state.producedBy;
        if(producedBy.length > 0 && i >= 0 && i < producedBy.length){
            producedBy.splice(i, 1);
            this.setState({producedBy: producedBy});
        }
    };

    changeProdAuto = (name, data, row) => (e, newValue) => {
        let list = this.state.producedBy;
        let line = list[row];
        line[name] = newValue;
        line[data] = newValue != null ? newValue.value : null;
        this.setState({producedBy: list});
    };

    changePrimaryRow = (i) => (e) => {
        const t = this.props.t;
        let producedBy = this.state.producedBy;
        let primary = producedBy[i].primary;
        let newPrimary = !primary;
        if(newPrimary === true){
            if(producedBy.length > 1){
                for(let index = 0; index < producedBy.length; index++){
                    if(index !== i){
                        producedBy[index].primary = false;
                    }
                }
            }
            producedBy[i].primary = true;
        } else {
            if(producedBy.length === 1){
                let errors = {};
                errors[`${t('primary')} (${t('line')} ${i})`] = t('onePrimary');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            }
        }
        this.setState({producedBy: producedBy})
    };

    changePrimary = (e) => {
        this.setState({newProducedPrimary: !this.state.newProducedPrimary});
    }

    categoryClick = async (e) => {
        if(this.state.showCategory === false){
            let cat = await axios.get('/api/itemCategory/' + this.state.category);
            this.setState({
                showCategory: true,
                categoryObject: cat.data
            })
        } else {
            this.setState({showCategory: false});
        }
    }

    render() {
        const errors = this.props.errors;
        const locked = this.state.locked;
        const t = this.props.t;
        const user = this.props.auth.user;
        const constants = this.props.auth.constants;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar 
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.item}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        optionsAvailable={this.state._id != null && this.state._id !== '' ? true : false}
                        flagForSignature={0}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabPanel value={this.state.tabKey} index={0}>
                    <Grid container spacing={3}>
                        {((locked && this.state.number != null && this.state.number !== '') ||
                            (!locked && this.state.number != null && this.state.number !== '' && user.manualItemNumber === false)) &&
                            <Grid item xs={4} sm={2}>
                                <FormControl data-cy="number">
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        {!locked && user.manualItemNumber === true &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.number} onChange={this.changeValue} name="number"
                                size="medium" fullWidth={true} disabled={locked} label={t('number')}
                                error={errors[t('number')] != null ? true : false} helperText={errors[t('number')]}
                                data-cy="number"/>
                            </Grid>
                        }
                        <Grid item xs={8} sm={4}>
                            {!locked &&
                                <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                size="medium" fullWidth={true} disabled={locked} label={t('name')} required
                                error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}
                                data-cy="name"/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('name')}</FormLabel>
                                    {this.state.name}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {!locked &&
                                <TextField value={this.state.description2} onChange={this.changeValue} name="description2"
                                size="medium" fullWidth={true} disabled={locked} label={t('description2')}
                                error={errors[t('description2')] != null ? true : false} helperText={errors[t('description2')]}
                                data-cy="description2"/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('description2')}</FormLabel>
                                    {this.state.description2 != null ? this.state.description2 : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.catSelected}
                                    options={this.state.categories}
                                    onChange={this.changeAuto('catSelected', 'category')}
                                    error={errors[t('category')] != null ? true : false}
                                    helperText={errors[t('category')]}
                                    label={t('category')}
                                    disabled={locked}
                                    required
                                    data-cy="category"
                                />
                            }
                            {locked && this.state.catSelected != null  &&
                                <FormControl>
                                    <FormLabel>{t('category')}</FormLabel>
                                    <TextOnlyClickButton enabled={true} click={this.categoryClick} name={this.state.catSelected.label}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked && !this.state.hasRecords &&
                                <AutoCompleteField
                                    value={this.state.uomSelected}
                                    options={this.state.uoms}
                                    onChange={this.changeAuto('uomSelected', 'uom')}
                                    error={errors[t('uom')] != null ? true : false}
                                    helperText={errors[t('uom')]}
                                    label={t('uom')}
                                    disabled={locked}
                                    required
                                    data-cy="uom"
                                />
                            }
                            {(locked || this.state.hasRecords) &&
                                <FormControl>
                                    <FormLabel>{t('uom')}</FormLabel>
                                    {this.state.uomSelected != null ? this.state.uomSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        {!locked  && (this.state.showPurchaseUOM === true || (this.state.purchaseUOM != null && this.state.purchaseUOM !== '')) &&
                            <Grid item xs={4} sm={2}>
                                <AutoCompleteField
                                    value={this.state.purchaseUOMSel}
                                    options={this.state.uoms}
                                    onChange={this.changeAuto('purchaseUOMSel', 'purchaseUOM')}
                                    error={errors[t('purchaseUOM')] != null ? true : false}
                                    helperText={errors[t('purchaseUOM')]}
                                    label={t('purchaseUOM')}
                                    disabled={locked}
                                    data-cy="purchaseUOM"
                                />
                            </Grid>
                        }
                        {locked && this.state.purchaseUOMSel != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('purchaseUOM')}</FormLabel>
                                    {this.state.purchaseUOMSel.label}
                                </FormControl>
                            </Grid>
                        }
                        {!locked && this.state.purchaseUOM != null && this.state.purchaseUOM !== '' &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.eachQuantity} onChange={this.changeValue} name="eachQuantity"
                                size="medium" fullWidth={true} disabled={locked} label={t('purchaseQuantity')} type="number"
                                error={errors[t('eachQuantity')] != null ? true : false} helperText={errors[t('eachQuantity')]}
                                data-cy="eachQuantity"/>
                            </Grid>
                        }
                        {locked && this.state.eachQuantity != null && this.state.eachQuantity !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('purchaseQuantity')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.eachQuantity} thousandSeparator={true}
                                    decimalScale={3}/>
                                </FormControl>
                            </Grid>
                        }
                        {locked && this.state.showStrain &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('strain')}</FormLabel>
                                    <Link to={'/strain/' + this.state.strain}>{this.state.strainSelected.label}</Link>
                                </FormControl>
                            </Grid>
                        }
                        {!locked && this.state.catSelected != null && this.state.catSelected.strainRequired &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.strainSelected}
                                    options={this.state.strains}
                                    onChange={this.changeAuto('strainSelected', 'strain')}
                                    error={errors[t('strain')] != null ? true : false}
                                    helperText={errors[t('strain')]}
                                    label={t('strain')}
                                    disabled={locked}
                                    data-cy="strain"
                                />
                            </Grid>
                        }
                        {!locked && this.props.auth.user.internal &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.exportName} onChange={this.changeValue} name="exportName"
                                size="medium" fullWidth={true} disabled={locked} label={t('exportName')} variant='outlined' helperText='Internal Only'
                                data-cy="exportName"/>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.price} onChange={this.changeNumber} name="price"
                                size="medium" fullWidth={true} disabled={locked} label={t('unitPrice')} data-cy="price"
                                error={errors[t('price')] != null ? true : false} helperText={errors[t('price')]}/>
                            </Grid>
                        }
                        {locked && this.state.price != null && this.state.price !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('unitPrice')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.price} thousandSeparator={true}
                                    decimalScale={2} prefix="$"/>
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.fullCost} onChange={this.changeNumber} name="fullCost"
                                size="medium" fullWidth={true} disabled={locked} label={t('cost')} data-cy="fullCost"
                                error={errors[t('fullCost')] != null ? true : false} helperText={errors[t('fullCost')]}/>
                            </Grid>
                        }
                        {locked && this.state.fullCost != null && this.state.fullCost !== '' &&
                             <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('cost')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.fullCost} thousandSeparator={true}
                                    decimalScale={2} prefix="$"/>
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.cost} onChange={this.changeNumber} name="cost"
                                size="medium" fullWidth={true} disabled={locked} label={t('unitCost')} data-cy="cost"
                                error={errors[t('cost')] != null ? true : false} helperText={errors[t('cost')]}/>
                            </Grid>
                        }
                        {locked && this.state.cost != null && this.state.cost !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('unitCost')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.cost} thousandSeparator={true}
                                    decimalScale={3} prefix="$"/>
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.minimumQuantity} onChange={this.changeNumber} name="minimumQuantity"
                                size="medium" fullWidth={true} disabled={locked} label={t('minimumQuantity')} data-cy="minimumQuantity"
                                error={errors[t('minimumQuantity')] != null ? true : false} helperText={errors[t('minimumQuantity')]}/>
                            </Grid>
                        }
                        {locked && this.state.minimumQuantity != null && this.state.minimumQuantity !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('minimumQuantity')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.minimumQuantity} thousandSeparator={true}
                                    decimalScale={2}/>
                                </FormControl>
                            </Grid>
                        }
                        {!locked && locked && (user.isCannabis == null || user.isCannabis) && this.state.isCannabis &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.taxItemSel}
                                    options={this.state.taxItems}
                                    onChange={this.changeAuto('taxItemSel', 'taxItem')}
                                    error={errors[t('taxItem')] != null ? true : false}
                                    helperText={errors[t('taxItem')]}
                                    label={t('taxItem')}
                                    disabled={locked}
                                    data-cy="taxItem"
                                />
                            </Grid>
                        }
                        {this.state.isCannabis && ((this.state.catSelected != null && this.state.catSelected.caseLot !== true) || this.state.isCasedItem === true) &&
                        <Grid item xs={4} sm={2}>
                            <FormControlLabel
                                disabled={locked}
                                control={
                                <Switch
                                    checked={this.state.isCasedItem}
                                    onChange={this.changeBool("isCasedItem")}
                                    name="isCasedItem"
                                />
                                }
                                label={t("casedItem")}
                            />
                        </Grid>
                        }
                        {this.state.isCannabis && !this.state.isCasedItem && this.state.catSelected != null && !this.state.catSelected.caseLot &&
                            <Aux>
                                <Grid item xs={4} sm={2}>
                                    <FormControlLabel
                                        disabled={locked}
                                        control={
                                        <Switch
                                            checked={this.state.isPackagedItem}
                                            onChange={this.changeBool("isPackagedItem")}
                                            name="isPakagedItem"
                                        />
                                        }
                                        label={t("packagedItem")}
                                    />
                                </Grid>
                            </Aux>
                        }
                        {!this.state.isPackagedItem && this.state.packaged && 
                            <Aux>
                                {!locked &&
                                    <Grid item xs={6} sm={3}>
                                        <AutoCompleteField
                                            value={this.state.packagedItemSel}
                                            options={this.state.packagedItems}
                                            onChange={this.changeAuto('packagedItemSel', 'packagedItem')}
                                            error={errors[t('packagedItem')] != null ? true : false}
                                            helperText={errors[t('packagedItem')]}
                                            label={t('packagedItem')}
                                            disabled={locked}
                                            data-cy="packagedItem"
                                        />
                                    </Grid>
                                }
                                {locked && this.state.packagedItemSel != null &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('packagedItem')}</FormLabel>
                                            <Link to={'/item/' + this.state.packagedItemSel.value}>{this.state.packagedItemSel.label}</Link>
                                        </FormControl>
                                    </Grid>
                                }
                            </Aux>
                        }
                        {((this.state.catSelected != null && this.state.catSelected.caseLot === true) || (this.state.isCasedItem) || (this.state.sku != null && this.state.sku !== '')) && !locked &&
                            <Grid item xs={6} sm={3}>
                                <TextField type="text" value={this.state.sku} onChange={this.changeValue} name="sku"
                                size="medium" fullWidth={true} disabled={locked} label={t('sku')} data-cy="sku"
                                error={errors[t('sku')] != null ? true : false} helperText={errors[t('sku')]}/>
                            </Grid>
                        }
                        {locked && this.state.sku != null && this.state.sku !== '' && !this.state.isCasedItem &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('sku')}</FormLabel>
                                    {this.state.sku}
                                </FormControl>
                            </Grid>
                        }
                        {((this.state.catSelected != null && this.state.catSelected.caseLot === true) || (this.state.isCasedItem) || (this.state.gtin != null && this.state.gtin !== '')) && !locked &&
                            <Grid item xs={6} sm={3}>
                                <TextField type="text" value={this.state.gtin} onChange={this.changeValue} name="gtin"
                                size="medium" fullWidth={true} disabled={locked} label={t('gtin')} data-cy="gtin"
                                error={errors[t('gtin')] != null ? true : false} helperText={errors[t('gtin')]}/>
                            </Grid>
                        }
                        {locked && this.state.gtin != null && this.state.gtin !== '' && 
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('gtin')}</FormLabel>
                                    {this.state.gtin}
                                </FormControl>
                            </Grid>
                        }
                        {((this.state.catSelected != null && this.state.catSelected.caseLot === true) || (this.state.isCasedItem) || (this.state.productNumber != null && this.state.productNumber !== '')) && !locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.productNumber} onChange={this.changeValue} name="productNumber"
                                size="medium" fullWidth={true} disabled={locked} label={t('productNumber')} data-cy="productNumber"
                                error={errors[t('productNumber')] != null ? true : false} helperText={errors[t('productNumber')]}/>
                            </Grid>
                        }
                        {locked && this.state.productNumber != null && this.state.productNumber !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('productNumber')}</FormLabel>
                                    {this.state.productNumber != null ? this.state.productNumber : ''}
                                </FormControl>
                            </Grid>
                        }
                        {((this.state.catSelected != null && this.state.catSelected.caseLot === true) || (this.state.caseQuantity != null && this.state.caseQuantity !== '')) && !locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="text" value={this.state.caseQuantity} onChange={this.changeNumber} name="caseQuantity"
                                size="medium" fullWidth={true} disabled={locked} label={t('caseQuantity')} data-cy="caseQuantity"
                                error={errors[t('caseQuantity')] != null ? true : false} helperText={errors[t('caseQuantity')]}/>
                            </Grid>
                        }
                        {locked && this.state.caseQuantity != null && this.state.caseQuantity !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('caseQuantity')}</FormLabel>
                                    {this.state.caseQuantity}
                                </FormControl>
                            </Grid>
                        }
                        {((this.state.catSelected != null && this.state.catSelected.caseLot === true) || (this.state.casedItemSel != null)) && !locked &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.casedItemSel}
                                    options={this.state.casedItems}
                                    onChange={this.changeAuto('casedItemSel', 'casedItem')}
                                    error={errors[t('casedItem')] != null ? true : false}
                                    helperText={errors[t('casedItem')]}
                                    label={t('casedItem')}
                                    disabled={locked}
                                    data-cy="casedItem"
                                />
                            </Grid>
                        }
                        {locked && this.state.casedItemSel != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('casedItem')}</FormLabel>
                                    <Link to={'/item/' + this.state.casedItemSel.value}>{this.state.casedItemSel.label}</Link>
                                </FormControl>
                            </Grid>
                        }
                        {((this.state.catSelected != null && this.state.catSelected.caseLot === true) || (this.state.isCasedItem) || (this.state.skuOwner != null && this.state.skuOwner !== '')) && !locked &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.skuOwnerSel}
                                    options={this.state.vendorList}
                                    onChange={this.changeAuto('skuOwnerSel', 'skuOwner')}
                                    error={errors[t('skuOwner')] != null ? true : false}
                                    helperText={errors[t('skuOwner')]}
                                    label={t('skuOwner')}
                                    disabled={locked}
                                    data-cy="skuOwner"
                                />
                            </Grid>
                        }
                        {locked && this.state.skuOwnerSel != null &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('skuOwner')}</FormLabel>
                                    {this.state.skuOwnerSel.label}
                                </FormControl>
                            </Grid>
                        }
                        {locked && this.state.taxItem != null && this.state.taxItem !== '' &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('taxItem')}</FormLabel>
                                    {this.state.taxItemSel != null ? this.state.taxItemSel.label : ''}
                                </FormControl>
                            </Grid>
                        }
                        {!locked && this.state.isCannabis && (this.props.auth.user.isSuper || !this.state.hasRecords) &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.thcPercent} onChange={this.changeNumber} name="thcPercent"
                                size="medium" fullWidth={true} disabled={locked} label={t('thcPercent')} data-cy="thcPercent"
                                error={errors[t('thcPercent')] != null ? true : false} helperText={errors[t('thcPercent')]}/>
                            </Grid>
                        }
                        {locked && this.state.thcPercent != null && this.state.thcPercent !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('thcPercent')}</FormLabel>
                                    {this.state.thcPercent}
                                </FormControl>
                            </Grid>
                        }
                        {!locked && ((this.state.catSelected != null && this.state.catSelected.itemThcMg === true) || (this.state.thcMg != null && this.state.thcMg !== '')) &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.thcMg} onChange={this.changeNumber} name="thcMg"
                                size="medium" fullWidth={true} disabled={locked} label={t('thcMg')} data-cy="thcMg"
                                error={errors[t('thcMg')] != null ? true : false} helperText={errors[t('thcMg')]}/>
                            </Grid>
                        }
                        {(locked || (!locked && this.state.hasRecords && this.props.auth.user.isSuper !== true)) && this.state.thcMg != null && this.state.thcMg !== '' && 
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('thcMg')}</FormLabel>
                                    {this.state.thcMg}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.packageThc != null && this.state.packageThc !== '' && 
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('packageThc')}</FormLabel>
                                    {this.state.packageThc}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.unitThc != null && this.state.unitThc !== '' && 
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('unitThc')}</FormLabel>
                                    {this.state.unitThc}
                                </FormControl>
                            </Grid>
                        }
                        {!locked && ((this.state.catSelected != null && this.state.catSelected.itemThcMg === true) || (this.state.cbdMg != null && this.state.cbdMg !== '')) &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.cbdMg} onChange={this.changeNumber} name="cbdMg"
                                size="medium" fullWidth={true} disabled={locked} label={t('cbdMg')} data-cy="cbdMg"
                                error={errors[t('cbdMg')] != null ? true : false} helperText={errors[t('cbdMg')]}/>
                            </Grid>
                        }
                        {(locked || (!locked && this.state.hasRecords && this.props.auth.user.isSuper !== true)) && this.state.cbdMg != null && this.state.cbdMg !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('cbdMg')}</FormLabel>
                                    {this.state.cbdMg}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.isCannabis && 
                            <Aux>
                                <Grid item xs={4} sm={2}>
                                    <FormControlLabel
                                        disabled={locked}
                                        control={
                                        <Switch
                                            checked={this.state.ctlsExclude}
                                            onChange={this.changeBool("ctlsExclude")}
                                            name="ctlsExclude"
                                        />
                                        }
                                        label={t("ctlsExclude")}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <FormControlLabel
                                        disabled={locked}
                                        control={
                                        <Switch
                                            checked={this.state.b300Exclude}
                                            onChange={this.changeBool("b300Exclude")}
                                            name="b300Exclude"
                                        />
                                        }
                                        label={t("b300Exclude")}
                                    />
                                </Grid>
                            </Aux>
                        }
                        {!locked && ((this.state.packSizeG != null && this.state.packSizeG !== '') || (this.state.isCannabis && this.state.uomSelected != null && 
                        this.state.canPackSize === true && (!this.state.hasRecords || this.props.auth.user.isSuper))) &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.packSizeG} onChange={this.changeNumber} name="packSizeG"
                                size="medium" fullWidth={true} disabled={locked} label={t('packSize')} data-cy="packSizeG"
                                error={errors[t('packSizeG')] != null ? true : false} helperText={errors[t('packSizeG')]}/>
                            </Grid>
                        }
                        {this.state.packSizeG != null && this.state.packSizeG !== '' && (locked || (!locked && this.state.hasRecords && this.props.auth.user.isSuper !== true)) && 
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('packSize')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.packSizeG} thousandSeparator={true}
                                    decimalScale={2}/>
                                </FormControl>
                            </Grid>
                        }
                        {!locked && ((this.state.packCount != null && this.state.packCount !== '') || (this.state.isHCSeeds && this.state.isCannabis && this.state.uomSelected != null && 
                        this.state.canPackSize === true && (!this.state.hasRecords || this.props.auth.user.isSuper))) &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.packCount} onChange={this.changeNumber} name="packCount"
                                size="medium" fullWidth={true} disabled={locked} label={t('packCount')} data-cy="packCount"
                                error={errors[t('packCount')] != null ? true : false} helperText={errors[t('packCount')]}/>
                            </Grid>
                        } 
                        {(locked || (!locked && this.state.hasRecords && this.props.auth.user.isSuper !== true)) && this.state.packCount != null && this.state.packCount !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('packCount')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.packCount} thousandSeparator={true}
                                    decimalScale={2}/>
                                </FormControl>
                            </Grid>
                        }
                        {!locked && this.state.isCannabis && this.state.uomSelected != null &&
                        (this.state.uom === this.props.auth.constants.quantityUOMs.each || this.state.uom === this.props.auth.constants.quantityUOMs.cs) &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.totalUnitWeightG} onChange={this.changeNumber} name="totalUnitWeightG"
                                size="medium" fullWidth={true} disabled={locked} label={t('totalUnitWeightG')} data-cy="totalUnitWeightG"
                                error={errors[t('totalUnitWeightG')] != null ? true : false} helperText={errors[t('totalUnitWeightG')]}/>
                            </Grid>
                        }
                        {locked && this.state.totalUnitWeightG != null && this.state.totalUnitWeightG !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('totalUnitWeightG')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.totalUnitWeightG} thousandSeparator={true}
                                    decimalScale={2}/>
                                </FormControl>
                            </Grid>
                        }
                        {!locked && ((this.state.avgWeight != null && this.state.avgWeight !== '') || (this.state.seeds === true) ||
                        (this.state.uom !== this.props.auth.constants.quantityUOMs.each && 
                        this.state.uom !== this.props.auth.constants.quantityUOMs.case && !this.state.packaged &&
                        this.state.isCannabis && this.state.hcReportCategorySel != null && this.state.hcReportCategorySel.packed !== true &&
                        (!this.state.hasRecords || this.props.auth.user.isSuper))) &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.avgWeight} onChange={this.changeNumber} name="avgWeight"
                                size="medium" fullWidth={true} disabled={locked} label={t('avgWeightg')} data-cy="avgWeight"
                                error={errors[t('avgWeight')] != null ? true : false} helperText={errors[t('avgWeight')]}/>
                            </Grid>
                        }
                        {(locked || (!locked && this.state.hasRecords && this.props.auth.user.isSuper !== true && this.state.seeds !== true)) && this.state.avgWeight != null && this.state.avgWeight !== ''  &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('avgWeightg')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.avgWeight} thousandSeparator={true}
                                    decimalScale={3}/>
                                </FormControl>
                            </Grid>
                        }
                        {!locked && this.state.isCannabis &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.expectedUnitCost} onChange={this.changeNumber} name="expectedUnitCost"
                                size="medium" fullWidth={true} disabled={locked} label={t('expectedUnitCost')} data-cy="expectedUnitCost"
                                error={errors[t('expectedUnitCost')] != null ? true : false} helperText={errors[t('expectedUnitCost')]}/>
                            </Grid>
                        }
                        {locked && this.state.expectedUnitCost != null && this.state.expectedUnitCost !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('expectedUnitCost')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.expectedUnitCost} thousandSeparator={true}
                                    decimalScale={2} prefix="$"/>
                                </FormControl>
                            </Grid>
                        }
                        {!locked && this.state.catSelected != null && this.state.catSelected.curingRequired &&
                            <Aux>
                                <Grid item xs={4} sm={2}>
                                    <TextField type="number" value={this.state.cureTime} onChange={this.changeNumber} name="cureTime"
                                    size="medium" fullWidth={true} disabled={locked} label={t('cureTime')} data-cy="cureTime"
                                    error={errors[t('cureTime')] != null ? true : false} helperText={errors[t('cureTime')]}/>
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <AutoCompleteField
                                        value={this.state.cureUOMSel}
                                        options={this.state.timeUOMs}
                                        onChange={this.changeAuto('cureUOMSel', 'cureTimeUOM')}
                                        error={errors[t('cureTimeUOM')] != null ? true : false}
                                        helperText={errors[t('cureTimeUOM')]}
                                        label={t('uom')}
                                        disabled={locked}
                                        data-cy="cureTimeUOM"
                                    />
                                </Grid>
                            </Aux>
                        }
                        {locked && this.state.cureTime != null && this.state.cureTime !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('cureTime')}</FormLabel>
                                    {this.state.cureUOMSel != null ? this.state.cureTime + this.state.cureUOMSel.label : this.state.cureTime}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.reorderLeadTime} onChange={this.changeNumber} name="reorderLeadTime"
                                size="medium" fullWidth={true} disabled={locked} label={t('reorderTime')} data-cy="reorderTime"
                                error={errors[t('reorderLeadTime')] != null ? true : false} helperText={errors[t('reorderLeadTime')]}/>
                            </Grid>
                        }
                        {locked && this.state.reorderLeadTime != null && this.state.reorderLeadTime !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('reorderTime')}</FormLabel>
                                    {this.state.reorderLeadTime}
                                </FormControl>
                            </Grid>
                        }
                        {!locked && this.state.isPesticide &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.registrationNumber} onChange={this.changeValue} name="registrationNumber"
                                size="medium" fullWidth={true} disabled={locked} label={t('regNo')} data-cy="registrationNumber" required
                                error={errors[t('registrationNumber')] != null ? true : false} helperText={errors[t('registrationNumber')]}/>
                            </Grid>
                        }
                        {locked && this.state.isPesticide && this.state.registrationNumber != null && this.state.registrationNumber !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('regNo')}</FormLabel>
                                    {this.state.registrationNumber}
                                </FormControl>
                            </Grid>
                        }
                        {!locked && this.state.isCannabis && (this.state.category === constants.itemCategories.clones ||
                        this.state.category === constants.itemCategories.seeds) &&
                            <Grid item xs={8} sm={4}>
                                <AutoCompleteField
                                    value={this.state.batchTemplateSel}
                                    options={this.state.batchTemplates}
                                    onChange={this.changeAuto('batchTemplateSel', 'batchTemplate')}
                                    error={errors[t('batchTemplate')] != null ? true : false}
                                    helperText={errors[t('batchTemplate')]}
                                    label={t('batchTemplate')}
                                    disabled={locked}
                                    data-cy="batchTemplate"
                                />
                            </Grid>
                        }
                        {locked && this.state.batchTemplate != null && this.state.batchTemplate !== '' &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('batchTemplate')}</FormLabel>
                                    {this.state.batchTemplateSel != null ? this.state.batchTemplateSel.label : ''}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.trackingSel}
                                    options={this.state.trackingTypes}
                                    onChange={this.changeAuto('trackingSel', 'tracking')}
                                    error={errors[t('tracking')] != null ? true : false}
                                    helperText={errors[t('tracking')]}
                                    label={t('lotType')}
                                    disabled={locked}
                                    data-cy="tracking"
                                />
                            }
                            {locked && this.state.trackingSel != null &&
                                <FormControl>
                                    <FormLabel>{t('lotType')}</FormLabel>
                                    {this.state.trackingSel != null ? this.state.trackingSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        {!locked && this.state.tracking != null && this.state.tracking !== '' &&
                            <Aux>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.newLotStatusSel}
                                        options={this.state.lotStatuses}
                                        onChange={this.changeAuto('newLotStatusSel', 'newLotStatus')}
                                        error={errors[t('newLotStatus')] != null ? true : false}
                                        helperText={errors[t('newLotStatus')]}
                                        label={t('newLotStatus')}
                                        disabled={locked}
                                        data-cy="newLotStatus"
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <TextField type="number" value={this.state.expirationMonths} onChange={this.changeNumber} name="expirationMonths"
                                    size="medium" fullWidth={true} disabled={locked} label={t('expMonths')} data-cy="expirationMonths"
                                    error={errors[t('expirationMonths')] != null ? true : false} helperText={errors[t('expirationMonths')]}/>
                                </Grid>
                            </Aux>
                        }
                        {locked && this.state.newLotStatusSel != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('newLotStatus')}</FormLabel>
                                    {this.state.newLotStatusSel.label}
                                </FormControl>
                            </Grid>
                        }
                        {locked && this.state.expirationMonths != null && this.state.expirationMonths !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('expMonths')}</FormLabel>
                                    {this.state.expirationMonths}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.catSelected != null && (this.state.catSelected.feedingSolution === true ||
                            this.state.catSelected.feedingPremix === true) &&
                            <Grid item xs={6} sm={3}>
                                {!locked &&
                                    <AutoCompleteField
                                        value={this.state.feedingRecipeSel}
                                        options={this.state.feedingRecipes}
                                        onChange={this.changeAuto('feedingRecipeSel', 'feedingRecipe')}
                                        error={errors[t('feedingRecipe')] != null ? true : false}
                                        helperText={errors[t('feedingRecipe')]}
                                        label={t('feedingRecipe')}
                                        disabled={locked}
                                        data-cy="feedingRecipe"
                                    />
                                }
                                {locked && this.state.feedingRecipeSel != null &&
                                    <FormControl>
                                        <FormLabel>{t('feedingRecipe')}</FormLabel>
                                        <Link to={'/feedingrecipe/' + this.state.feedingRecipe}>{this.state.feedingRecipeSel != null ? this.state.feedingRecipeSel.label : ''}</Link>
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {this.state.catSelected != null && (this.state.catSelected.pesticideSolution === true) &&
                            <Grid item xs={6} sm={3}>
                                {!locked &&
                                    <AutoCompleteField
                                        value={this.state.pestRecipeSel}
                                        options={this.state.pesticideRecipes}
                                        onChange={this.changeAuto('pestRecipeSel', 'pesticideRecipe')}
                                        error={errors[t('pesticideRecipe')] != null ? true : false}
                                        helperText={errors[t('pesticideRecipe')]}
                                        label={t('pesticideRecipe')}
                                        disabled={locked}
                                        data-cy="pesticideRecipe"
                                    />
                                }
                                {locked && this.state.pestRecipeSel != null &&
                                    <FormControl>
                                        <FormLabel>{t('pesticideRecipe')}</FormLabel>
                                        <Link to={'/pesticideRecipe/' + this.state.pesticideRecipe}>{this.state.pestRecipeSel != null ? this.state.pestRecipeSel.label : ''}</Link>
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {this.state.catSelected != null && (this.state.catSelected.cleaningSolution === true) &&
                            <Grid item xs={6} sm={3}>
                                {!locked &&
                                    <AutoCompleteField
                                        value={this.state.cleaningSolutionRecipeSel}
                                        options={this.state.cleaningSolutionRecipes}
                                        onChange={this.changeAuto('cleaningSolutionRecipeSel', 'cleaningSolutionRecipe')}
                                        error={errors[t('cleaningSolutionRecipe')] != null ? true : false}
                                        helperText={errors[t('cleaningSolutionRecipe')]}
                                        label={t('cleaningSolutionRecipe')}
                                        disabled={locked}
                                        data-cy="cleaningSolutionRecipe"
                                    />
                                }
                                {locked && this.state.cleaningSolutionRecipeSel != null &&
                                    <FormControl>
                                        <FormLabel>{t('cleaningSolutionRecipe')}</FormLabel>
                                        <Link to={'/cleaningsolutionrecipe/' + this.state.cleaningSolutionRecipe}>{this.state.cleaningSolutionRecipeSel != null ? this.state.cleaningSolutionRecipeSel.label : ''}</Link>
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {this.state.testDefinition != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('testDefinition')}</FormLabel>
                                    <Link to={'/testDefinition/' + this.state.testDefinition._id}>{this.state.testDefinition.name}</Link>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.testDefinition == null && !locked && this.state.isCannabis &&
                            <Grid item xs={6} sm={3}>
                                <Button variant="outlined" color="primary" size="small" href="/#/testDefinition" data-cy="createTest">
                                    {t('createTest')}
                                </Button>
                            </Grid>
                        }
                        {this.state.packaged &&
                            <Grid item xs={4} sm={2}>
                                <FormControlLabel
                                    disabled={locked}
                                    control={
                                    <Switch
                                        checked={this.state.excised}
                                        onChange={this.changeBool("excised")}
                                        name="excised"
                                    />
                                    }
                                    label={t("excised")}
                                />
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <CheckboxField
                                checked={this.state.hide}
                                onChange={this.changeBool('hide')}
                                name="hide"
                                label={t('hide')}
                                error={errors[t('hide')] != null ? true : false}
                                helperText={errors[t('hide')]}
                                disabled={locked}
                                data-cy="hide"
                                />
                            </Grid>
                        }
                        {locked && this.state.hide === true &&
                            <Grid item xs={4} sm={2}>
                                <CheckboxField
                                checked={this.state.hide}
                                onChange={this.changeBool('hide')}
                                name="hide"
                                label={t('hide')}
                                error={errors[t('hide')] != null ? true : false}
                                helperText={errors[t('hide')]}
                                disabled={locked}
                                />
                            </Grid>
                        }
                    </Grid>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={11}>
                            <AutoCompleteField
                                multiple={true} disabled={locked}
                                value={this.state.vendorsSel}
                                options={this.state.vendorList}
                                onChange={this.changeMulti('vendorsSel', 'vendors')}
                                error={errors[t('vendors')] != null ? true : false}
                                helperText={errors[t('vendors')]}
                                label={t('vendors')}
                                data-cy="vendors"
                            />
                        </Grid>
                        <Grid item sm={11}>
                            <AutoCompleteField
                                multiple={true} disabled={locked}
                                value={this.state.testDefinitionsSel}
                                options={this.state.testDefinitionList}
                                onChange={this.changeMulti('testDefinitionsSel', 'testDefinitions')}
                                error={errors[t('testDefinitions')] != null ? true : false}
                                helperText={errors[t('testDefinitions')]}
                                label={t('testDefinitions')}
                                data-cy="testDefinitions"
                            />
                        </Grid>
                    </Grid>
                    {locked && this.state.producedBy != null && this.state.producedBy.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('producedBy')}/>
                            <Grid container spacing={3} key="prodHeader" sx={{pb: 1}}>
                                <Grid item xs={8} sm={4}>{t('routing')}</Grid>
                                <Grid item xs={8} sm={4}>{t('productionBom')}</Grid>
                                <Grid item xs={2} sm={1}>{t('primary')}</Grid>
                            </Grid>
                            {this.state.producedBy.map((row, i) =>
                                <Grid container spacing={3} key={row._id} sx={{pb: 2}}>
                                    {row.routingSel != null &&
                                        <Grid item xs={8} sm={4}>
                                            <Link to={'/routing/' + row.routingSel.value}>{row.routingSel.label}</Link>
                                        </Grid>
                                    }
                                    {row.bomSel != null &&
                                        <Grid item xs={8} sm={4}>
                                            <Link to={'/bom/' + row.bomSel.value}>{row.bomSel.label}</Link>
                                        </Grid>
                                    }
                                    <Grid item xs={2} sm={1}>
                                        {row.primary  ? t('yes') : ''}
                                    </Grid>
                                </Grid>
                            )}
                        </Aux>
                    }
                    {!locked &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('producedBy')}/>
                            <Grid container spacing={3} key="prodEditHeader">
                                <Grid item xs={8} sm={4}>{t('routing')}</Grid>
                                <Grid item xs={8} sm={4}>{t('productionBom')}</Grid>
                                <Grid item xs={2} sm={1}>{t('primary')}</Grid>
                            </Grid>
                            {this.state.producedBy.map((row, i) =>
                                <Grid container spacing={3} key={'row' + i}>
                                    <Grid item xs={8} sm={4}>
                                        <AutoCompleteField
                                            value={row.routingSel}
                                            options={this.state.routings}
                                            onChange={this.changeProdAuto('routingSel', 'routing', i)}
                                            error={errors[`${t('routing')} (${t('line')} ${i})`] != null ? true : false}
                                            helperText={errors[`${t('routing')} (${t('line')} ${i})`]}
                                            disabled={locked}
                                            required
                                            data-cy={"routing" + i}
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={4}>
                                        <AutoCompleteField
                                            value={row.bomSel}
                                            options={this.state.productionBoms}
                                            onChange={this.changeProdAuto('bomSel', 'productionBom', i)}
                                            error={errors[`${t('productionBom')} (${t('line')} ${i})`] != null ? true : false}
                                            helperText={errors[`${t('productionBom')} (${t('line')} ${i})`]}
                                            required
                                            data-cy={"productionBom" + i}
                                        />
                                    </Grid>
                                    <Grid item xs={2} sm={1}>
                                        <CheckboxField
                                        checked={row.primary}
                                        onChange={this.changePrimaryRow(i)}
                                        name={'primary' + i}
                                        error={errors[`${t('primary')} (${t('line')} ${i})`] != null ? true : false}
                                        helperText={errors[`${t('primary')} (${t('line')} ${i})`]}
                                        data-cy={"primary" + i}
                                        />
                                    </Grid>
                                    <Grid item sx={{width: 50}}>
                                        <IconButton aria-label="delete output" onClick={this.deleteProduction(i)} data-cy={"deleteProduction" + i}>
                                            <RemoveCircleTwoToneIcon  />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container spacing={3} key="newProd">
                                <Grid item xs={8} sm={4}>
                                    <AutoCompleteField
                                        value={this.state.newRoutingSel}
                                        options={this.state.routings}
                                        onChange={this.changeAuto('newRoutingSel', 'newRouting')}
                                        error={errors[t('newRouting')] != null ? true : false}
                                        helperText={errors[t('newRouting')]}
                                        required
                                        data-cy="newRouting"
                                    />
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    <AutoCompleteField
                                        value={this.state.newBomSel}
                                        options={this.state.productionBoms}
                                        onChange={this.changeAuto('newBomSel', 'newBom')}
                                        error={errors[t('newBom')] != null ? true : false}
                                        helperText={errors[t('newBom')]}
                                        required
                                        data-cy="newBom"
                                    />
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <CheckboxField
                                    checked={this.state.newProducedPrimary}
                                    onChange={this.changePrimary}
                                    name="newProducedPrimary"
                                    error={errors[t('newProducedPrimary')] != null ? true : false}
                                    helperText={errors[t('newProducedPrimary')]}
                                    data-cy="primary"
                                    />
                                </Grid>
                                <Grid item sx={{width: 50}}>
                                    <IconButton aria-label="add prod" onClick={this.addProduction} data-cy="addProduction">
                                        <AddBoxTwoToneIcon  />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Aux>
                    }
                    {this.state.inventoryDetail != null &&
                        <Aux>
                            <SectionTitle title={t('inventory')}/>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('total')}</FormLabel>
                                        <NumericFormat displayType={'text'} value={this.state.inventory} thousandSeparator={true}
                                            decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                {this.state.count != null && this.state.count !== '' && this.state.count !== '0' && this.state.count !== 0 &&
                                    <Grid item xs={2} sm={1}>
                                        <FormControl>
                                            <FormLabel>{t('count')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={this.state.count} thousandSeparator={true} decimalScale={0}/>
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.fullDiscreteWeight != null && this.state.fullDiscreteWeight !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('fullDiscrete')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={this.state.fullDiscreteWeight} thousandSeparator={true}
                                                decimalScale={3} suffix={this.state.fullDiscreteWeightUOMSel != null ? this.state.fullDiscreteWeightUOMSel.label : ''}/>
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item sm={6}>
                                    <SubSectionHeading title={t('available')}/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('total')}</FormLabel>
                                                <NumericFormat value={this.state.inventoryDetail.available.total} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('available')}</FormLabel>
                                                <NumericFormat value={this.state.inventoryDetail.available.available} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('reserved')}</FormLabel>
                                                <NumericFormat value={this.state.inventoryDetail.reserved} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {this.state.inventoryDetail.available.types != null && this.state.inventoryDetail.available.types.length > 0 &&
                                        <Aux>
                                            <br/>
                                            <Grid container spacing={3} key="availHeader">
                                                <Grid item xs={4} sm={3}>
                                                    {t('type')}
                                                </Grid>
                                                <Grid item xs={4} sm={3}>
                                                    {t('quantity')}
                                                </Grid>
                                            </Grid>
                                            {this.state.inventoryDetail.available.types.map((row, i) =>
                                                <Grid container spacing={3} key={'avail' + i}>
                                                    <Grid item xs={4} sm={3}>
                                                        {row.type}
                                                    </Grid>
                                                    <Grid item xs={4} sm={3}>
                                                        <NumericFormat value={row.quantity} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Aux>
                                    }
                                </Grid>
                                <Grid item sm={6}>
                                    <SubSectionHeading title={t('unavailable')}/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('total')}</FormLabel>
                                                <NumericFormat value={this.state.inventoryDetail.unavailable.total} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('available')}</FormLabel>
                                                <NumericFormat value={this.state.inventoryDetail.unavailable.available} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                        {this.state.inventoryDetail.unavailable.quarantined != null && this.state.inventoryDetail.unavailable.quarantined > 0 &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('quarantined')}</FormLabel>
                                                    <NumericFormat value={this.state.inventoryDetail.unavailable.quarantined} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.inventoryDetail.unavailable.waste != null && this.state.inventoryDetail.unavailable.waste > 0 &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('waste')}</FormLabel>
                                                    <NumericFormat value={this.state.inventoryDetail.unavailable.waste} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                    </Grid>
                                    
                                    {this.state.inventoryDetail.unavailable.types != null && this.state.inventoryDetail.unavailable.types.length > 0 &&
                                        <Aux>
                                            <br/>
                                            <Grid container spacing={3} key="unavailHeader">
                                                <Grid item xs={4} sm={3}>
                                                    {t('type')}
                                                </Grid>
                                                <Grid item xs={4} sm={3}>
                                                    {t('quantity')}
                                                </Grid>
                                            </Grid>
                                            {this.state.inventoryDetail.unavailable.types.map((row, i) =>
                                                <Grid container spacing={3} key={'unavail' + i}>
                                                    <Grid item xs={4} sm={3}>
                                                        {row.type}
                                                    </Grid>
                                                    <Grid item xs={4} sm={3}>
                                                        <NumericFormat value={row.quantity} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Aux>
                                    }
                                </Grid>
                            </Grid>
                        </Aux>
                    }
                    {!locked && (this.state.category === this.props.auth.constants.itemCategories.drybulk || this.state.category === this.props.auth.constants.itemCategories.dryTrimBulk ||
                    this.state.category === this.props.auth.constants.itemCategories.dryShake) &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('dryingTypes')}/>
                            <Grid container spacing={3} key="drytimehead">
                                <Grid item xs={6} sm={3}>{t('type')}</Grid>
                                <Grid item xs={4} sm={2}>{t('days')}</Grid>
                            </Grid>
                            {this.state.dryingTimes.map((row, i) =>
                                <Grid container spacing={3} key={'drytime' + i}>
                                    <Grid item xs={8} sm={4}>
                                        <TextOnlyClickButton enabled={true} click={this.editDryingType(i)} name={row.typeSel.label}/>
                                    </Grid>
                                    <Grid item xs={4} sm={2}>{row.days}</Grid>
                                </Grid>
                            )}
                            <Button variant="outlined" color="primary" size="small" onClick={this.addDryingType}>{t('add')}</Button>
                            <br/>
                        </Aux>
                    }
                    {locked && this.state.dryingTimes != null && this.state.dryingTimes.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('dryingTypes')}/>
                            <Grid container spacing={3} key="typehead">
                                <Grid item xs={6} sm={3}>{t('type')}</Grid>
                                <Grid item xs={4} sm={2}>{t('days')}</Grid>
                            </Grid>
                            {this.state.dryingTimes.map((row, i) =>
                                <Grid container spacing={3} key={'type' + i}>
                                    <Grid item xs={6} sm={3}>{row.typeSel.label}</Grid>
                                    <Grid item xs={4} sm={2}>{row.days}</Grid>
                                </Grid>
                            )}
                            <br/>
                        </Aux>
                    }
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={5}>
                            {!locked &&
                                <Aux>
                                    <Button disabled={!this.state.enableSave} variant="contained" color="primary" size="small" onClick={() => this.save()} data-cy="save">
                                        {t('save')}
                                    </Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {this.state.security.update &&
                                <Aux>
                                    <Button variant="contained" color="secondary" size="small" onClick={this.unlock} data-cy="unlock">
                                        {locked ? t('unlock') : t('lock')}
                                    </Button>
                                    &nbsp;&nbsp;
                                </Aux>
                            }
                            {!locked && this.state.security.delete &&
                                <Button variant="contained" color="secondary" size="small" onClick={this.deleteClick} data-cy="delete">{t('delete')}</Button>
                            }
                            &nbsp;&nbsp;
                            {!locked && this.state.security.create &&
                                <Button variant="contained" color="secondary" size="small" onClick={this.toggleCopy} data-cy="copy">{t('copy')}</Button>
                            }
                        </Grid>
                    </Grid>
                    {this.state.boms != null && this.state.boms.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('usedIn')}/>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="Used in BOMS">
                                    <TableHead>
                                        <TableRow key="bomTableHeader">
                                            <TableCell>{t('productionBom')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.boms.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell>
                                                    <Link to={'/bom/' + row._id}>{row.number + '-' + row.description}</Link>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TablePagination count={this.state.allBoms.length}
                                        rowsPerPage={this.state.bomSize}
                                        onPageChange={this.handleBomChangePage}
                                        page = {this.state.bomPage}
                                        rowsPerPageOptions={common.rowOptions}
                                        onRowsPerPageChange={this.handleBomRowsPerPage}/>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Aux>
                    }
                    {this.state.totals != null && this.state.totals.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('inventoryBreakdown')}/>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="invTable">
                                    <TableHead>
                                        <TableRow key="invHeader">
                                            <TableCell>{t('type')}</TableCell>
                                            <TableCell align="right">{t('quantity')}</TableCell>
                                            <TableCell align="right">{t('available')}</TableCell>
                                            <TableCell align="right">{t('reserved')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.totals.map((row, i) => (
                                            <TableRow key={i}>
                                                <TableCell>{row.typeSel != null ? row.typeSel.label : ''}</TableCell>
                                                <TableCell align="right">
                                                    <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}decimalScale={2} fixedDecimalScale/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumericFormat displayType={'text'} value={row.available} thousandSeparator={true}decimalScale={2} fixedDecimalScale/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumericFormat displayType={'text'} value={row.reserved} thousandSeparator={true}decimalScale={2} fixedDecimalScale/>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br/>
                        </Aux>
                    }
                    {this.state.testDefinitions != null && this.state.testDefinitions.length > 0 &&
                        <ItemTests item={this.props.match.params.id}/>
                    }
                    <br/>
                    <ItemLots item={this.props.match.params.id} hasDiscrete={this.state.catSelected != null ? this.state.catSelected.hasDiscrete : false}/>
                    <ItemBatches item={this.props.match.params.id}/>
                    {this.state.sales != null && this.state.sales.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('sales')}/>
                            <TableContainer component={Paper}>
                                <Table aria-label="itemSales">
                                    <TableHead>
                                        <TableRow key="itemSalesHeader">
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('number')}
                                                    active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('date')}
                                                    active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('customer')}
                                                    active={this.state.orderBy === 'customer'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('customer')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('province')}
                                                    active={this.state.orderBy === 'province'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('province')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>{t('totalAmount')}</TableCell>
                                            <TableCell align='right'>
                                                <Tooltip title={t('shipments')}>
                                                    <ShipmentsIcon color='primary'/>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.sales.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell><Link to={'/sale/' + row._id}>{row.number}</Link></TableCell>
                                                <TableCell><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                                <TableCell>{row.customer != null ? row.customer.name : ''}</TableCell>
                                                <TableCell>{row.prov != null ? row.prov.name : ''}</TableCell>
                                                <TableCell>
                                                    <NumericFormat displayType={'text'} value={row.totalAmount} thousandSeparator={true}
                                                    decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right">{row.shipments != null ? row.shipments.length : 0}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow key="tableFooter">
                                            <TablePagination count={this.state.totalCount}
                                            rowsPerPage={this.state.listSize}
                                            onPageChange={this.handleChangePage}
                                            page = {this.state.page}
                                            rowsPerPageOptions={common.rowOptions}
                                            onRowsPerPageChange={this.handleRowsPerPage}/>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Aux>
                    }
                </TabPanel>
                <TabPanel value={this.state.tabKey} index={1}>
                    <ItemOutputValues id={this.props.match.params.id} security={this.props.permission}/>
                </TabPanel>
                <TabPanel value={this.state.tabKey} index={2}>
                    <ItemTransactions item={this.props.match.params.id} hasCount={this.state.catSelected != null ? this.state.catSelected.hasCount : false}/>
                </TabPanel>
                <TabPanel value={this.state.tabKey} index={3}>
                    <ItemTotals item={this.state._id}/>
                </TabPanel>
                {this.state.tracking != null && this.state.tracking !== '' &&
                    <TabPanel value={this.state.tabKey} index={4}>
                        <ItemContainers id={this.state._id}/>
                    </TabPanel>
                }
                {this.props.auth.user.neobi === true && this.state.salesData != null && this.state.salesData.length > 0 &&
                    <TabPanel value={this.state.tabKey} index={5}>
                        <ItemNeobiSales data={this.state.salesData}/>
                        <br/>
                        <ItemNeobiStores data={this.state.salesData}/>
                    </TabPanel>
                }
                <Dialog open={this.state.showDrying} onClose={this.clearDrying} center={true} maxWidth="sm" fullWidth>
                    <DialogTitle>
                        <SectionTitle title={t('dryingTypes')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={8} sm={4}>
                                <AutoCompleteField
                                    value={this.state.dryingTypeSel}
                                    options={this.state.dryingTypes}
                                    onChange={this.changeAuto('dryingTypeSel', 'dryingType')}
                                    error={errors[t('dryingType')] != null ? true : false}
                                    helperText={errors[t('dryingType')]}
                                    label={t('type')}
                                    disabled={locked}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TextField type="number" value={this.state.dryingDays} onChange={this.changeNumber} name="dryingDays"
                                size="medium" fullWidth={true} disabled={locked} label={t('durationD')} required
                                error={errors[t('dryingDays')] != null ? true : false} helperText={errors[t('dryingDays')]}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" size="small" onClick={this.saveDrying}>{t('save')}</Button>
                        <Button variant="contained" color="secondary" size="small" onClick={this.clearDrying}>{t('close')}</Button>
                        {this.state.dryingIndex >= 0 &&
                            <Button variant="contained" color="secondary" size="small" onClick={this.deleteDrying}>{t('delete')}</Button>
                        }
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.showCopy} onClose={this.toggleCopy} center={true} maxWidth="sm" fullWidth>
                    <DialogTitle>
                        <SectionTitle title={t('copy')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={8} sm={4}>
                                <TextField type="text" value={this.state.copyNumber} onChange={this.changeValue} name="copyNumber"
                                size="medium" fullWidth={true} disabled={locked} label={t('number')}
                                error={errors[t('copyNumber')] != null ? true : false} helperText={errors[t('copyNumber')]}/>
                            </Grid>
                            <Grid item xs={14} sm={8}>
                                <TextField type="text" value={this.state.copyName} onChange={this.changeValue} name="copyName"
                                size="medium" fullWidth={true} disabled={locked} label={t('name')} required
                                error={errors[t('copyName')] != null ? true : false} helperText={errors[t('copyName')]}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" size="small" onClick={this.copyClick}>{t('copy')}</Button>
                        <Button variant="contained" color="secondary" size="small" onClick={this.toggleCopy}>{t('close')}</Button>
                    </DialogActions>
                </Dialog>
                {this.state.showCategory &&
                    <ItemCategoryDialog
                        category={this.state.categoryObject}
                        openStatus={this.state.showCategory}
                        toggleDialog={() => this.categoryClick()}
                        viewingOnly={true}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.item}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Item));
