import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import ViewActivityIcon from '@mui/icons-material/FindInPageTwoTone';
import NoneIcon from '@mui/icons-material/NotInterested';
import EditButtonIcon from '@mui/icons-material/EditTwoTone';
import { useTranslation } from 'react-i18next';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

export default React.memo(function ActionButton(props) {
     const { href, label, onClick, security, style} = props;
     const { t } = useTranslation();
     const iconStyle = {
          '&:hover': {
               color: 'primary.main'
          }
     };
     const iconButton = {
          padding: 1
     };
     const disabled = {
          color: '#e8e8e8',
          padding: 1
     };
     const downArrow = {
          '&:hover': {
             color: 'secondary.main',
          }
     };
     const upArrow = {
          '&:hover': {
             color: "#00AC89",
          }
     };
     let icon = '';
     let click = onClick;
     let newLabel = label;
     const enabled = security ?? true;
     switch(label) {
          case t('schedule'): 
               newLabel = newLabel + " " + t('activity');
               icon = <ScheduleTwoToneIcon sx={iconStyle} />;
               break;
          case t('delete'):
               newLabel = newLabel + " " + t('activity');
               icon = <DeleteTwoToneIcon sx={iconStyle} />;
               break;
          case t('edit'):
          case t('editStage'):
               icon = <EditButtonIcon sx={iconStyle} />;
               break;
          case t('view'):
               newLabel = newLabel + " " + t('activity');
               icon = <ViewActivityIcon sx={iconStyle} />;
               break;
          case t('moveDown'):
               icon = <ArrowDownwardRoundedIcon sx={downArrow} />;
               break;
          case t('moveUp'):
               icon = <ArrowUpwardRoundedIcon sx={upArrow} />;
               break;
          default:
               icon = <NoneIcon sx={{ color: 'error.main' }} />;
               break;
     }

     if(href) {
          click = (e) => {
               e.preventDefault();
               window.location.href=`${href}`;
          }
     }

     return (
          <Tooltip title={newLabel}>
               <div>
                    <IconButton disabled={!enabled} component={enabled ? 'div' : undefined} aria-label={label} 
                                sx={enabled ? iconButton : disabled} onClick={click} style={style}>
                         {icon}
                    </IconButton>
               </div>
          </Tooltip>
     );
});