import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import TextField from '../UI/TextField/TextField';
import DateSelect from '../UI/DateSelect/DateSelect'
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class ChangeRequestList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            orderBy: 'number',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            number: '',
            status: '',
            statusSel: null,
            statuses: [],
            reason: '',
            reasonSel: null,
            reasons: [],
            requestor: '',
            requestorSel: null,
            users: [],
            date: '',
            security: []
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.status, common.cacheValues.changeReason], this.props.auth.constants, null, this.props.auth.user.tenant);
        const userValues = await ValueHelper.screenUsers(common.screens.changeRequestList, this.props.auth.user.tenant);
        let storage = sessionStorage.getItem('changeRequests');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        this.setState({
            statuses: cacheValues.statuses,
            reasons: cacheValues.changeReasons,
            users: userValues.create,
            security: this.props.permission,
            offset: storage != null ? storage.offset : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'number',
            ordDir: storage != null ? storage.ordDir : -1,
            date: storage != null && storage.date != null && storage.date !== '' ? new Date(storage.date) : '',
            status: storage != null ? storage.status : '',
            statusSel: storage != null ? storage.statusSel : null,
            reason: storage != null ? storage.reason : '',
            reasonSel: storage != null ? storage.reasonSel : null,
            requestor: storage != null ? storage.requestor : '',
            requestorSel: storage != null ? storage.requestorSel : null,
            number: storage != null ? storage.number : '',
            page: storage != null ? storage.page : 0
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('changeRequests'), screen: this.props.auth.screenDefs.ChangeRequestList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            date: this.state.date,
            number: this.state.number,
            status: this.state.status,
            statusSel: this.state.statusSel,
            reason: this.state.reason,
            reasonSel: this.state.reasonSel,
            requestor: this.state.requestor,
            requestorSel: this.state.requestorSel,
            page: this.state.page
        };
        sessionStorage.setItem('changeRequests', JSON.stringify(data));
    }

    loadRecords = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let parms = {
            size: this.state.listSize,
            offset: this.state.offset,
            date: this.state.date,
            status: this.state.status,
            reason: this.state.reason,
            requestor: this.state.requestor,
            number: this.state.number,
            sort: JSON.stringify(sort)
        };
        let result = await axios.get('/api/changerequests', {params: parms});
        this.setState({
            list: result.data.data,
            totalCount: result.data.totalCount
        });
        this.saveFilters();
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => this.loadRecords());
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    dateChange = e => {
        this.setState({date: e, page: 0, offset: 0}, () => this.loadRecords());
    };

    sortClick(name) {
        let orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/changerequest'
                        createAccess={security.create}
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.dateChange}
                                value={this.state.date}
                                label={t('date')}
                                format={this.props.auth.user.dateFormat}
                                data-cy='date'
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.number} onChange={this.changeValue} name="number"
                            size="medium" fullWidth={true} label={t('number')} data-cy='number'/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.statusSel}
                                options={this.state.statuses}
                                onChange={this.changeAuto('statusSel', 'status')}
                                label={t('status')}
                                data-cy='status'
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.reasonSel}
                                options={this.state.reasons}
                                onChange={this.changeAuto('reasonSel', 'reason')}
                                label={t('reason')}
                                data-cy='reason'
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.requestorSel}
                                options={this.state.users}
                                onChange={this.changeAuto('requestorSel', 'requestor')}
                                label={t('requestor')}
                                data-cy='requestor'
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Change Requests">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('requestDate')}
                                        active={this.state.orderBy === 'requestDate'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('requestDate')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('managedDocument')}
                                        active={this.state.orderBy === 'managedDocument'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('document')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('reason')}
                                        active={this.state.orderBy === 'reason'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('reason')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('requestor')}
                                        active={this.state.orderBy === 'requestor'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('requestor')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('status')}
                                        active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/changerequest/' + row._id} name={'crRow' + i}>{row.number}</Link></TableCell>
                                        <TableCell><DateDisplay value={row.requestDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                        <TableCell>{row.managedDocument != null ? row.managedDocument.name : ''}</TableCell>
                                        <TableCell>{row.reason.label}</TableCell>
                                        <TableCell>{row.requestor.firstName + ' ' + row.requestor.lastName}</TableCell>
                                        <TableCell>{row.status.label}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(ChangeRequestList));
