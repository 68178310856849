import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import DateSelect from '../UI/DateSelect/DateSelect';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import TextField from '../UI/TextField/TextField';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class ProductionScheduleList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            outputItem: '',
            outputItemSel: null,
            items: [],
            deliveryDate: null,
            orderBy: 'number',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            listSize: 10,
            status: '',
            statuses: [],
            number: '',
            security: []
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.producedItem, common.cacheValues.productionScheduleCancelReason, 
            common.cacheValues.productionScheduleStatus], this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({
            items: cacheValues.producedItems,
            statuses: cacheValues.productionScheduleStatuses,
            security: this.props.permission
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('productionSchedules'), screen: this.props.auth.screenDefs.ProductionScheduleList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            outputItem: this.state.outputItem,
            deliveryDate: this.state.deliveryDate,
            status: this.state.status,
            number: this.state.number,
            sort: JSON.stringify(sort),
            size: this.state.listSize,
            offset: this.state.offset
        };
        let list = await axios.get('/api/productionschedules', {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        });
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value}, () => {this.loadRecords()});
    }

    dateChange = (name) => (e) => {
        this.setState({[name]: e}, () => {this.loadRecords();});
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.number} onChange={this.changeValue} name="number"
                            size="medium" fullWidth={true} label={t('number')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.outputItemSel}
                                options={this.state.items}
                                onChange={this.changeAuto('outputItemSel', 'outputItem')}
                                label={t('item')}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.dateChange('deliveryDate')}
                                value={this.state.deliveryDate}
                                label={t('deliveryDate')}
                                format={this.props.auth.user.dateFormat}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.statusSel}
                                options={this.state.statuses}
                                onChange={this.changeAuto('statusSel', 'status')}
                                label={t('status')}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Production Schedules">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('deliveryDate')}
                                        active={this.state.orderBy === 'deliveryDate'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('deliveryDate')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('outputItem')}
                                        active={this.state.orderBy === 'outputItem'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('item')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('status')}
                                        active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/productionschedule/' + row._id} name={'psRow' + i}>{row.number}</Link></TableCell>
                                        <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={row.productionPlan.deliveryDate}/></TableCell>
                                        <TableCell>{row.productionPlan.outputItem.number + ' - ' + row.productionPlan.outputItem.name}</TableCell>
                                        <TableCell>{row.productionPlan.outputQuantity + row.productionPlan.outputUOM.label}</TableCell>
                                        <TableCell>{row.status.label}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default withTranslation() (connect(mapStateToProps)(ProductionScheduleList));
