import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import isEmpty from '../../is-empty';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import ActionBar from '../UI/Buttons/ActionBar';
import { ClearButton, SaveButton, TextOnlyClickButton } from '../UI/Buttons/Buttons';
import { withTranslation } from 'react-i18next';
import ModalDialog from '../UI/Dialog/ModalDialog';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class ToteList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            orderBy: 'number',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            search: '',
            number: '',
            weight: '',
            weightUOM: '',
            uomSel: null,
            uoms: [],
            index: -1,
            _id: '',
            edit: false,
            security: [],
            enableSave: true
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.quantityUOM], this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({
            uoms: cacheValues.quantityUOMs,
            security: this.props.permission    
        })
        this.loadRecords();
    }

    loadRecords = async () => {
        const sort = {[this.state.orderBy]: this.state.ordDir};
        const params = {
            offset: this.state.offset,
            size: this.state.listSize,
            sort: JSON.stringify(sort),
            number: this.state.search
        }
        const list = await axios.get('/api/totes', {params: params});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount,
            index: -1
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('totes'), screen: this.props.auth.screenDefs.ToteList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeEdit = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords();});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeNumber = e => {
        const name = e.target.name;
        const value = e.target.value;
        if(isNaN(value)){
            return;
        }
        this.setState({
            [name]: value
        });
    };

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    editRow(index){
        const row = this.state.list[index];
        const uomSel = this.state.uoms.find(x => x.value === row.weightUOM._id);
        this.setState({
            number: row.number,
            _id: row._id,
            weight: row.weight,
            weightUOM: row.weightUOM._id,
            uomSel: uomSel,
            edit: true,
            indes: index
        });
    }

    addRow = (e) => {
        this.setState({edit: true});
    };

    clear = (e) => {
        this.setState({
            edit: false,
            _id: '',
            number: '',
            weight: '',
            weightUOM: '',
            uomSel: null,
            index: -1,
        })
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        const data = {
            _id: this.state._id,
            weight: this.state.weight,
            weightUOM: this.state.weightUOM
        };
        try {
            const result = await axios.post('/api/tote', data);
            this.setState({ enableSave: true }, () => {
                this.loadRecords();
                this.clear();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        if(this.state.weight == null || this.state.weight === '')
            errors[t('weight')] = t('required');
        if(this.state.weightUOM == null || this.state.weightUOM === '')
            errors[t('weightUOM')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    render(){
        const errors = this.props.errors;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createClick={this.addRow}
                        createAccess={security.create}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.search} onChange={this.changeValue} name="search"
                                size="medium" fullWidth={true} label={t('number')}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Totes">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Weight</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <TextOnlyClickButton enabled={true} click={() => this.editRow(i)} name={row.number} id={'toteLink' + i}/>
                                        </TableCell>
                                        <TableCell>{row.weight + row.weightUOM.label}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <ModalDialog 
                        buttonStack={[
                            <ClearButton clearClick={this.clear} enabled={security.create || security.complete || security.update} />,
                            <SaveButton saveClick={this.save} enabled={(security.create || security.complete || security.update) && this.state.enableSave} />
                        ]} 
                        title={t('tote')} toggleDialog={this.clear} dialogStatus={this.state.edit} fullWidth maxWidth='sm' >
                            <Grid container spacing={2}>
                                {this.state.number != null && this.state.number !== '' &&
                                 this.state.uomSel != null && this.state.uomSel !== '' &&
                                    <Grid item xs={4} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={4}>
                                    <TextField type="number" value={this.state.weight} onChange={this.changeNumber} name="weight"
                                    size="medium" fullWidth={true} label={t('weight')} required
                                    error={errors[t('weight')] != null ? true : false} helperText={errors[t('weight')]}/>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.uomSel}
                                        options={this.state.uoms}
                                        onChange={this.changeAuto('uomSel', 'weightUOM')}
                                        error={errors[t('weightUOM')] != null ? true : false}
                                        helperText={errors[t('weightUOM')]}
                                        label={t('uom')}
                                        required
                                    />
                                </Grid>
                            </Grid>
                    </ModalDialog>
                </Panel>
            </Aux>
        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
  });
  
 export default withTranslation() (connect(mapStateToProps)(ToteList));