import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Aux from '../../hoc/Auxilary/Auxilary';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';

class Prescriptions extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            pageCount: 1,
            listSize: 10,
            refresh: true,
            orderBy: 'expirationDate',
            ordDir: -1,
            totalCount: 0,
            page: 0
        }
    }

    async componentDidMount(){
        if(this.props.id != null && this.props.id !== ''){
            this.loadRecords();
        }
    }

    componentDidUpdate(props){
        const refresh = this.props.refresh;
        if(refresh !== props.refresh){ 
            this.loadRecords();
        }
    }

    async loadRecords(){
        var sort = {[this.state.orderBy]: this.state.ordDir};
        var data = {
            offset: this.state.offset,
            size: this.state.listSize,
            sort: JSON.stringify(sort)
        }
        var list = await axios.get('/api/prescriptions/' + this.props.id, {params: data});
        this.setState({
            list: list.data.data,
            pageCount: Math.ceil(list.data.totalCount / this.state.listSize),
            totalCount: list.data.totalCount
        })
    }

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    newPrescription(){
        this.props.open(null);
    }

    openPrescription(id){
        this.props.open(id);
    }

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <TableContainer component={Paper}>
                    <Table aria-label="Prescriptions">
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('expirationDate')}
                                    active={this.state.orderBy === 'expirationDate'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('expiration')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('practitioner')}
                                    active={this.state.orderBy === 'practitioner'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('practitioner')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('gramsPerDay')}
                                    active={this.state.orderBy === 'gramsPerDay'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('gramsPerDay')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('status')}
                                    active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list.map((row, i) => 
                                <TableRow key={row._id}>
                                    <TableCell>
                                        <button type="button" className="btn btn-link" onClick={() => this.openPrescription(row._id)}>
                                        <DateDisplay format={this.props.auth.user.dateFormat} value={row.expirationDate}/></button>
                                    </TableCell>
                                    <TableCell>{row.practitioner.lastName + ', ' + row.practitioner.firstName}</TableCell>
                                    <TableCell>{row.gramsPerDay}</TableCell>
                                    <TableCell>{row.status.label}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">    
                                <Button variant="outlined" color="primary" size="small" onClick={() => this.newPrescription()}>{t('add')}</Button>
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(Prescriptions));