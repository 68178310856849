import { SET_SOP_ID, SET_TEMPLATE_ID } from './types';

export const setSopId = sopId => ({
    type: SET_SOP_ID,
    payload: {
        sopId: sopId
    }
});


export const setTemplateId = templateId => ({
    type: SET_TEMPLATE_ID,
    payload: {
        templateId: templateId
    }
});