import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Panel from '../UI/Panel/Panel';
import Button from '@mui/material/Button'
import { SaveButton, TextOnlyClickButton } from '../UI/Buttons/Buttons';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import RegisterIcon from '@mui/icons-material/AppRegistration';
import Tooltip from '@mui/material/Tooltip';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import TextField from '../UI/TextField/TextField';
import {encryptString} from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import ObjectLog from '../General/ObjectLog';
import {FormControl, FormLabel} from '@mui/material';
import SelectField from '../UI/SelectField/SelectField';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { Table, TableBody, TableCell, TableContainer, 
         TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckTwoTone';
import common from '../../jsons/common.json';
import Link from '../UI/Link/Link';
import Skeleton from '@mui/material/Skeleton';
import SendIcon from '@mui/icons-material/Send';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import SectionTitle from '../UI/Typography/SectionTitle';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import DateSelect from '../UI/DateSelect/DateSelect';
import ModalDialog from '../UI/Dialog/ModalDialog';
import ValueHelper from '../../helpers/valueHelper';

class TenantUser extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            userid: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            roles: [],
            rolesSel: [],
            roleOptions: [],
            processes: [],
            processesSel: [],
            processOptions: [],
            department: [],
            departmentSel: [],
            departmentOptions: [],
            phoneNumber: '',
            defaultLocation: '',
            defaultLocationSel: null,
            locations: [],
            receiveEmails: false,
            security: [],
            changePassword: false,
            active: true,
            timezone: '',
            timezoneSel: null,
            timezones: [],
            locked: false,
            renderNum: Math.random(),
            language: '',
            languageSel: null,
            languages: [],
            hourlyRate: '',
            failedLogins: '',
            screens: [],
            offset: 0,
            listSize: 10,
            page: 0,
            totalCount: 0,
            list: [],
            uacLoading: true,
            helpdeskId: 0,
            helpdeskStatus: this.props.t('none'),
            tenantHDId: 0,
            certTypes: [],
            certType: '',
            certTypeSel: null,
            certExpDate: '',
            certNumber: '',
            certifications: [],
            showCert: false,
            certIndex: -1,
            optionsMenu: [],
            allAuthTypes: [],
            authType: '',
            authTypeSel: null,
            authTypes: []
        };
    }

    async componentDidMount(){
        const parms = {params: {tenant: this.props.auth.user.tenant}};
        const values = await axios.get('/api/uservalues', parms);
        let languageSel = values.data.languages.find(x => x.value === this.props.auth.common.languages.english.id);
        this.setState({
            roleOptions: values.data.roles,
            processOptions: values.data.processes,
            locations: values.data.locations,
            departmentOptions: values.data.departments,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            security: this.props.permission,
            timezones: values.data.timezones,
            languages: values.data.languages,
            languageSel: languageSel,
            language: this.props.auth.common.languages.english.id,
            certTypes: values.data.certTypes,
            allAuthTypes: ValueHelper.authOptions()
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/tenantusers', label: this.props.t('users'), screen: this.props.auth.screenDefs.TenantUsers},
                    { path: 'active', label: this.props.t('newUser'), screen: this.props.auth.screenDefs.TenantUser}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    };

    loadRecord = async () => {
        let record = await axios.get('/api/tenantuser/' + this.state._id);
        let defaultLocationSel = this.state.locations.find(x => x.value === record.data.defaultLocation);
        let processesSel = [];
        record.data.processes.forEach(row => {
            let val = this.state.processOptions.find(x => x.value === row);
            processesSel.push(val);
        });
        let rolesSel = [];
        record.data.roles.forEach(row => {
            let val = this.state.roleOptions.find(x => x.value === row);
            rolesSel.push(val);
        });
        let departmentSel = [];
        record.data.department.forEach(row => {
            let val = this.state.departmentOptions.find(x => x.value === row);
            departmentSel.push(val);
        });
        let timezoneSel = this.state.timezones.find(x => x.value === record.data.timezone);
        let languageSel = this.state.languages.find(x => x.value === record.data.language);
        let certifications = record.data.certifications != null ? record.data.certifications : [];
        if(certifications.length > 0) {
            for(let row of certifications) {
                let certTypeSel = this.state.certTypes.find(x => x.value === row.type);
                row.certTypeSel = certTypeSel;
            }
        }
        let authTypes = [];
        if(record.data.tenantAuthTypes != null && record.data.tenantAuthTypes.length > 0) {
            record.data.tenantAuthTypes.forEach(row => {
                let val = this.state.allAuthTypes.find(x => x.value === row);
                authTypes.push(val);
            });
        }
        let authTypeSel = authTypes.find(x => x.value === record.data.authType);
        const optionsMenu = [
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        this.setState({
            userid: record.data.userid,
            firstName: record.data.firstName,
            lastName: record.data.lastName,
            email: record.data.email,
            defaultLocation: record.data.defaultLocation,
            roles: record.data.roles,
            department: record.data.department,
            changePassword: record.data.changePassword,
            processes: record.data.processes,
            active: record.data.active,
            defaultLocationSel: defaultLocationSel,
            rolesSel: rolesSel,
            processesSel: processesSel,
            departmentSel: departmentSel,
            timezone: record.data.timezone,
            timezoneSel: timezoneSel,
            language: record.data.language,
            languageSel: languageSel,
            locked: record.data.locked,
            hourlyRate: record.data.hourlyRate,
            phoneNumber: record.data.phoneNumber,
            failedLogins: record.data.failedLogins,
            receiveEmails: record.data.receiveEmails === true ? true : false,
            renderNum: Math.random(),
            tenantHDId: record.data.tenantHDId,
            helpdeskId: record.data.helpdeskId,
            certifications: certifications,
            optionsMenu: optionsMenu,
            authType: record.data.authType,
            authTypeSel: authTypeSel,
            authTypes: authTypes
        }, async () => {
            const crumbs = [
                { path: '/#/tenantusers', label: this.props.t('users'), screen: this.props.auth.screenDefs.TenantUsers},
                { path: 'active', label: record.data.firstName + ' ' + record.data.lastName, screen: this.props.auth.screenDefs.TenantUser}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: this.props.t('options')}});
            let screens = await axios.get('/api/uactable/' + this.state._id);
            this.setState({ 
                screens: screens.data, 
                list: screens.data.slice(this.state.offset, this.state.offset + this.state.listSize),
                pages: screens.data != null && screens.data.length > 0 ? Math.ceil(screens.data.length / this.state.listSize): 0,
                totalCount: screens.data != null && screens.data.length > 0 ? screens.data.length : 0,
                uacLoading: false
            }, async () => {
                if( record.data.helpdeskId != null && record.data.helpdeskId !== '' && record.data.helpdeskId > 0 ) {
                    if(record.data.helpdeskStatus)
                        this.setState({helpdeskStatus: this.props.t('active')})
                    else {
                        const fdCheck = await axios.get('/api/checkfduser/' + record.data.email);
                        let status = this.props.t('none');
                        if(fdCheck.status === 200) {
                            if(isEmpty(fdCheck.data)) {
                                status = this.props.t('disabled');
                            }else {
                                status = fdCheck.data[0] != null && fdCheck.data[0].active === true ? 
                                        this.props.t('active') : this.props.t('inactive');
                            }
                        }
                        this.setState({helpdeskStatus: status});
                    }                        
                }else {
                    if(record.data.tenantHDId != null && record.data.tenantHDId !== '' && record.data.tenantHDId > 0) {
                        const fdCheck = await axios.get('/api/checkfduser/' + record.data.email);
                        let status = this.props.t('none');
                        if(fdCheck.status === 200) {
                            if(isEmpty(fdCheck.data)) {
                                status = this.props.t('none');
                            }else {
                                status = fdCheck.data[0] != null && fdCheck.data[0].active === true ? 
                                        this.props.t('active') : this.props.t('inactive');
                            }
                        }
                        this.setState({helpdeskStatus: status})      
                    }else {
                        this.setState({helpdeskStatus: this.props.t('noTenantIdFound')})
                    }
                }                    
            })
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    };

    changeMulti = (name, data, optName) => (e) => {
        let sels = this.state[name];
        let ids = this.state[data];
        let options = this.state[optName];
        let val = e.target.value;
        let value = val.pop();
        if(value != null){
            if(value === 'all'){
                if(options.length !== sels.length){
                    sels = [];
                    ids = [];
                    options.forEach(row => {
                        sels.push(row);
                        ids.push(row.value);
                    });
                } else {
                    sels = [];
                    ids = [];
                }
            } else {
                if(val.length === 0){
                    let sel = options.find(x => x.value === value);
                    ids.push(value);
                    sels.push(sel);
                } else {
                    if(!val.some(x => x.value === value)){
                        let sel = options.find(x => x.value === value);
                        sels.push(sel);
                        ids.push(sel.value);
                    } else {
                        let index = sels.findIndex(x => x.value === value);
                        sels.splice(index, 1);
                        ids.splice(index, 1);
                    }
                }
            }
            this.setState({[name]: sels, [data]: ids})
        }
    }

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    }

    deleteMulti = (name, data) => (e, value) => {
        let sels = this.state[name];
        let ids = this.state[data];
        let index = sels.findIndex(x => x.value === value.value);
        if(index > -1){
            sels.splice(index, 1);
            ids.splice(index, 1);
        }
        this.setState({[name]: sels, [data]: ids});
    }

    save = async (e) => {
        let valid = this.validate();
        if(!valid)
            return;
        let passwordHash = this.state.password != null && this.state.password !== '' ? encryptString(this.state.password) : null;
        let data = {
            _id: this.state._id,
            userid: this.state.userid,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            receiveEmails: this.state.receiveEmails,
            changePassword: this.state.changePassword,
            defaultLocation: this.state.defaultLocation,
            active: this.state.active,
            department: this.state.department,
            roles: this.state.roles,
            processes: this.state.processes,
            language: this.state.language,
            password: passwordHash,
            timezone: this.state.timezone != null && this.state.timezone !== '' ? this.state.timezone : null,
            hourlyRate: this.state.hourlyRate,
            certifications: this.state.certifications,
            authType: this.state.authType
        };
        try {
            let result = await axios.post('/api/tenantuser', data);
            this.setState({_id: result.data.id});
            this.props.history.replace('/tenantuser/' + result.data.id);
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    };

    validate(){
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.userid == null || state.userid === '')
            errors[t('userid')] = t('required');
        if(state.firstName == null || state.firstName === '')
            errors[t('firstName')] = t('required');
        if(state.lastName == null || state.lastName === '')
            errors[t('lastName')] = t('required');
        if(state.email == null || state.email === '')
            errors[t('email')] = t('required');
        if(state.roles == null || state.roles.length === 0)
            errors[t('roles')] = t('required');
        if(state.department == null || state.department.length === 0)
            errors[t('department')] = t('required');
        if(state.language == null || state.language === '')
            errors[t('language')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    unlock = async (e) => {
        const t = this.props.t;
        if(this.state.locked){
            let data = {user: this.state._id};
            if(!this.props.auth.user.internal){
                data.tenant = this.props.auth.user.tenant;
            }
            try {
                let result = await axios.post('/api/userunlock', data);
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            } catch(err){
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        }
    };

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)});
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize});
    };
    
    refreshHelpdesk = async () => {
        const t = this.props.t;
        try {
            const fdCheck = await axios.get('/api/checkfduser/' + this.state.data.email);
            let status = t('none');
            if(fdCheck.status === 200) {
                if(isEmpty(fdCheck.data)) {
                    status = t('none');
                }else {
                    status = fdCheck[0] != null && fdCheck[0].active === true ? 
                            t('active') : t('inactive');
                }
            }
            this.setState({helpdeskStatus: status});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('success'), title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: err.description, severity: 'error'}});
        }
    }

    registerHelpdesk = async () => {
        const t = this.props.t;
        if(this.state.tenantHDId != null && this.state.tenantHDId !== '' && this.state.tenantHDId > 0){
            try {
                let result = await axios.post('/api/registerhelpdeskuser', {user: this.state._id, company: this.state.tenantHDId});
                this.setState({helpdeskStatus: t('inactive')});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            } catch(err){
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: err.description, severity: 'error'}});
            }
        }else {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('noTenantFound'), title: t('error'), severity: 'error'}});
        }
    }

    sendHelpdeskEmail = async () => {
        const t = this.props.t;
        if(this.state.helpdeskStatus === t('inactive') && this.state.receiveEmails === true){
            try {
                let result = await axios.post('/api/sendhelpdeskemail', {user: this.state.helpdeskId});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data, title: t('success'), severity: 'success'}});
            } catch(err){
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: err.description, severity: 'error'}});
            }
        }else {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('anErrorHasOccured'), title: t('error'), severity: 'error'}});
        }
    }

    deleteHelpdesk = async () => {
        const t = this.props.t;
        if(this.state.helpdeskStatus === t('inactive') || this.state.helpdeskStatus === t('active')) {
            try {
                let result = await axios.post('/api/disablehelpdeskuser', {user: this.state.helpdeskId});
                this.setState({helpdeskStatus: t('disabled')});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            }catch (err) {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: err.description, severity: 'error'}});
            }
        }
    }

    restoreHelpdesk = async () => {
        const t = this.props.t;
        if(this.state.helpdeskStatus === t('disabled')) {
            try {
                let result = await axios.post('/api/restorehelpdeskuser', {user: this.state.helpdeskId});
                this.setState({helpdeskStatus: t('active')});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            }catch (err) {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: err.description, severity: 'error'}});
            }
        }
    }

    toggleCerts = () => {
        this.setState({
            showCert: !this.state.showCert,
            certIndex: -1,
            certType: '',
            certTypeSel: null,
            certExpDate: '',
            certNumber: ''
        });
    }

    editCert = (i) => (e) => {
        let certs = this.state.certifications;
        let cert = certs[i];
        certs[i] = cert;
        this.setState({
            certIndex: i,
            certType: cert.type,
            certTypeSel: cert.certTypeSel,
            certExpDate: cert.expirationDate,
            certNumber: cert.number,
            showCert: true
        });
    }

    saveCert = (e) => {
        let errors = {};
        const t = this.props.t;
        if(this.state.certType == null || this.state.certType === '')
            errors[t('certType')] = this.props.t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let certs = this.state.certifications;
        if(this.state.certIndex > -1){
            let cert = certs[this.state.certIndex];
            cert.type = this.state.certType;
            cert.certTypeSel = this.state.certTypeSel;
            cert.expirationDate = this.state.certExpDate;
            cert.number = this.state.certNumber;
        } else {
            certs.push({
                type: this.state.certType,
                certTypeSel: this.state.certTypeSel,
                expirationDate: this.state.certExpDate,
                number: this.state.certNumber
            });
        }
        this.setState({
            certifications: certs,
            showCert: false,
            certType: '',
            certTypeSel: null,
            certExpDate: '',
            certNumber: '',
            certIndex: -1
        })
    }

    render(){
        const { errors, t } = this.props;
        const security = this.state.security;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        printAccess={security.print}
                        printClick={'/#/useraccessreport/' + this.state._id}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.user}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={8} sm={4}>
                            <TextField value={this.state.userid} onChange={this.changeValue} name="userid"
                            size="medium" fullWidth={true} label={t('userid')} data-cy='userid' 
                            error={errors[t('userid')] != null ? true : false} helperText={errors[t('userid')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.firstName} onChange={this.changeValue} name="firstName"
                            size="medium" fullWidth={true} label={t('firstName')} data-cy='firstName' 
                            error={errors[t('firstName')] != null ? true : false} helperText={errors[t('firstName')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.lastName} onChange={this.changeValue} name="lastName"
                            size="medium" fullWidth={true} label={t('lastName')} data-cy='lastName' 
                            error={errors[t('lastName')] != null ? true : false} helperText={errors[t('lastName')]}/>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <TextField value={this.state.email} onChange={this.changeValue} name="email"
                            size="medium" fullWidth={true} label={t('email')} data-cy='email' 
                            error={errors[t('email')] != null ? true : false} helperText={errors[t('email')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <InputMaskField mask="999-999-9999" name="phoneNumber" value={this.state.phoneNumber}
                            onChange={this.changeValue} data-cy='phoneNumber' 
                            helperText={errors[t('phoneNumber')]}
                            error={errors[t('phoneNumber')] != null ? true : false}
                            label={t('phoneNumber')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField data-cy='receiveEmails'
                            checked={this.state.receiveEmails} onChange={this.changeBool('receiveEmails')}
                            label={t('receiveEmails')} name="receiveEmails"
                            />
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <TextField value={this.state.password} onChange={this.changeValue} name="password"
                            size="medium" fullWidth={true} label={t('password')} type="password" data-cy='password'
                            error={errors[t('password')] != null ? true : false} helperText={errors[t('password')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField
                            checked={this.state.changePassword} onChange={this.changeBool('changePassword')}
                            label={t('changePassword')} name="changePassword" data-cy='changePassword'
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                            value={this.state.languageSel}
                            options={this.state.languages}
                            onChange={this.changeAuto('languageSel', 'language')}
                            error={errors[t('language')] != null ? true : false}
                            helperText={errors[t('language')]} 
                            label={t('language')}
                            data-cy='language'
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                            value={this.state.defaultLocationSel}
                            options={this.state.locations}
                            onChange={this.changeAuto('defaultLocationSel', 'defaultLocation')}
                            error={errors[t('defaultLocation')] != null ? true : false}
                            helperText={errors[t('defaultLocation')]} 
                            label={t('defaultLocation')}
                            data-cy='defaultLocation'
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                            value={this.state.timezoneSel}
                            options={this.state.timezones}
                            onChange={this.changeAuto('timezoneSel', 'timezone')}
                            error={errors[t('timezone')] != null ? true : false}
                            helperText={errors[t('timezone')]} 
                            label={t('timezone')}
                            data-cy='timezone'
                            />
                        </Grid>
                        <Grid item>
                            <CheckboxField
                            checked={this.state.active} onChange={this.changeBool('active')}
                            label={t('active')} name="active" data-cy='active'
                            />
                        </Grid>
                        <Grid item>
                            <CheckboxField
                            checked={this.state.locked} disabled={true}
                            label={t('locked')} name="locked" data-cy='locked'
                            />
                        </Grid>
                        {this.state.failedLogins != null && this.state.failedLogins !== '' && this.state.failedLogins !== 0 &&
                            <Grid item xs={2} sm={1}>
                                <FormControl>
                                    <FormLabel>{t('tries')}</FormLabel>
                                    {this.state.failedLogins}
                                </FormControl>
                            </Grid>
                        }
                        {this.state._id != null && this.state._id !== '' &&
                            <Aux>
                                {this.state.helpdeskStatus === t('none') ?
                                    <Grid item>
                                        <Tooltip title="Register Helpdesk">
                                            <IconButton onClick={() => this.registerHelpdesk()}>
                                                <RegisterIcon sx={{ color: 'primary.main' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid> :
                                 this.state.helpdeskStatus === t('inactive') ?
                                    <Grid item>
                                        <Tooltip title="Send Invite">
                                            <IconButton onClick={() => this.sendHelpdeskEmail()}>
                                                <SendIcon sx={{ color: 'success.main' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid> : 
                                 this.state.helpdeskStatus === t('active') ?
                                    <Grid item>
                                        <Tooltip title="Refresh Helpdesk">
                                            <IconButton onClick={() => this.refreshHelpdesk()}>
                                                <RefreshIcon sx={{ color: 'secondary.main' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid> : 
                                 this.state.helpdeskStatus === t('disabled') ?
                                    <Grid item>
                                        <Tooltip title="Restore Helpdesk">
                                            <IconButton onClick={() => this.restoreHelpdesk()}>
                                                <RestoreIcon sx={{ color: 'info.main' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid> : null
                                }
                                <Grid item xs={2} sm={1}>
                                    <FormControl>
                                        <FormLabel>{t('helpdeskStatus')}</FormLabel>
                                        {this.state.helpdeskStatus != null ? this.state.helpdeskStatus : t('notFound')}
                                    </FormControl>
                                </Grid>
                                {this.state.helpdeskStatus === t('active') || this.state.helpdeskStatus === t('inactive') ?
                                    <Grid item>
                                        <Tooltip title="Delete Helpdesk">
                                            <IconButton onClick={() => this.deleteHelpdesk()}>
                                                <DeleteIcon sx={{ color: 'error.main' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid> : null
                                }
                            </Aux>                            
                        }                        
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.hourlyRate} onChange={this.changeValue} name="hourlyRate"
                            size="medium" fullWidth={true} label={t('hourlyRate')} type="number" data-cy='number'
                            error={errors[t('hourlyRate')] != null ? true : false} helperText={errors[t('hourlyRate')]}/>
                        </Grid>
                        <Grid item xs={4} sm={3}>
                            <AutoCompleteField
                            value={this.state.authTypeSel}
                            options={this.state.authTypes}
                            onChange={this.changeAuto('authTypeSel', 'authType')}
                            error={errors[t('authentication')] != null ? true : false}
                            helperText={errors[t('authentication')]} 
                            label={t('authentication')}
                            data-cy='authType'
                            />
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <SelectField
                                            selectAll
                                            value={this.state.rolesSel}
                                            options={this.state.roleOptions}
                                            label={t('roles')}
                                            selectAllLabel={t('selectAll')}
                                            error={errors[t('roles')] != null ? true : false}
                                            helperText={errors[t('roles')]} 
                                            onChange={this.changeMulti('rolesSel', 'roles', 'roleOptions')}
                                            onDelete={this.deleteMulti('rolesSel', 'roles')}
                                            data-cy='roles'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectField
                                            selectAll
                                            value={this.state.departmentSel}
                                            options={this.state.departmentOptions}
                                            label={t('departments')}
                                            selectAllLabel={t('selectAll')}
                                            error={errors[t('department')] != null ? true : false}
                                            helperText={errors[t('department')]} 
                                            onChange={this.changeMulti('departmentSel', 'department', 'departmentOptions')}
                                            onDelete={this.deleteMulti('departmentSel', 'department')}
                                            data-cy='departments'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectField
                                            selectAll
                                            value={this.state.processesSel}
                                            options={this.state.processOptions}
                                            label={t('businessProcesses')}
                                            selectAllLabel={t('selectAll')}
                                            error={errors[t('processes')] != null ? true : false}
                                            helperText={errors[t('processes')]} 
                                            onChange={this.changeMulti('processesSel', 'processes', 'processOptions')}
                                            onDelete={this.deleteMulti('processesSel', 'processes')}
                                            data-cy='businessProcesses'
                                        />
                                    </Grid>
                                </Grid>
                                <br/>
                                <Grid item xs={12}>
                                    <SectionTitle title={t('certifications')} addTitle={t('add')} add={this.toggleCerts} security={true}/>
                                </Grid>
                                {this.state.certifications != null && this.state.certifications.length > 0 &&
                                    <Aux>
                                        <Grid item xs={12}>
                                            <TableContainer sx={{ border: '2px solid rgba(224, 224, 224, 1)', borderRadius: 3}}>
                                                <Table size='small'>
                                                    <TableHead key='certificationsTableHeader'>
                                                        <TableRow>
                                                            <TableCell>{t('certification')}</TableCell>
                                                            <TableCell>{t('expires')}</TableCell>
                                                            <TableCell>{t('number')}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {this.state.certifications != null && this.state.certifications.length > 0 ? 
                                                        this.state.certifications.map((certification, i) => (
                                                        <TableRow key={'cert' + i}>
                                                            <TableCell>
                                                                <TextOnlyClickButton enabled={true} click={this.editCert(i)} name={certification.certTypeSel.label}/>
                                                            </TableCell>
                                                            <TableCell>
                                                                {certification.expirationDate != null && <DateDisplay value={certification.expirationDate} format={this.props.auth.user.dateFormat}/>}
                                                            </TableCell>
                                                            <TableCell>{certification.number != null ? certification.number : ''}</TableCell>
                                                        </TableRow> 
                                                    )) :
                                                        <TableRow>
                                                            <TableCell colSpan={2} sx={{ textAlign: 'center', fontWeight: 'bold' }}>{t('noCertificationsFound')}</TableCell>
                                                        </TableRow>
                                                    }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Aux>
                                }
                            </Grid>
                            <ModalDialog buttonStack={(<Aux>
                                    <Button color="secondary" size="small" variant='text' onClick={this.toggleCerts}>Close</Button>
                                    <Button color="primary" size="small" variant="text" onClick={this.saveCert}>Save</Button>
                                </Aux>
                                )} title={t('certification')} toggleDialog={this.toggleCerts}
                                            dialogStatus={this.state.showCert} fullWidth maxWidth='sm'>
                                <Grid container spacing={3}>
                                    <Grid item xs={10} sm={5}>
                                        <AutoCompleteField
                                            value={this.state.certTypeSel}
                                            options={this.state.certTypes}
                                            onChange={this.changeAuto('certTypeSel', 'certType')}
                                            error={errors[t('certType')] != null ? true : false}
                                            helperText={errors[t('certType')]} 
                                            label={t('type')}
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={5}>
                                        <DateSelect
                                            value={this.state.certExpDate}
                                            onChange={this.changeDate('certExpDate')}
                                            error={errors[t('certExpDate')] != null ? true : false}
                                            helperText={errors[t('certExpDate')]} 
                                            label={t('expires')}
                                            name="certExpDate"
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={5}>
                                        <TextField 
                                            value={this.state.certNumber} 
                                            onChange={this.changeValue} 
                                            name="certNumber" 
                                            label={t('number')} 
                                            data-cy='certNumber' 
                                            error={errors[t('certNumber')] != null ? true : false} helperText={errors[t('certNumber')]}
                                        />
                                    </Grid>
                                </Grid>
                                                
                            </ModalDialog>
                            {this.state._id != null && this.state._id !== '' &&
                                <Grid item xs={12} md={8}>
                                    {this.state.uacLoading ? 
                                        <Skeleton animation='wave' variant="rounded" height={400} /> :
                                        <TableContainer sx={{ border: '2px solid rgba(224, 224, 224, 1)', borderRadius: 3}}>
                                            <Table size='small'>
                                                <TableHead key='permissionsTableHeader'>
                                                    <TableRow>
                                                        <TableCell>{t('screen')}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{t('read')}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{t('create')}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{t('delete')}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{t('update')}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{t('complete')}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{t('review')}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{t('approve')}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{t('print')}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{t('unlock')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {this.state.screens != null && this.state.screens.length > 0 ? 
                                                    this.state.screens
                                                        .slice( this.state.page * this.state.listSize,
                                                                this.state.page * this.state.listSize + this.state.listSize)
                                                        .map((permission) => (
                                                    <TableRow key={permission.label}>
                                                        <TableCell>
                                                            <Link href={`/#/screen/${permission._id}`}>
                                                                {permission.label}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{permission.read ? <CheckIcon sx={{ color: 'success.main' }} /> : null}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{permission.create ? <CheckIcon sx={{ color: 'success.main' }} /> : null}</TableCell>   
                                                        <TableCell sx={{ textAlign: 'center' }}>{permission.delete ? <CheckIcon sx={{ color: 'success.main' }} /> : null}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{permission.update ? <CheckIcon sx={{ color: 'success.main' }} /> : null}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{permission.complete ? <CheckIcon sx={{ color: 'success.main' }} /> : null}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{permission.review ? <CheckIcon sx={{ color: 'success.main' }} /> : null}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{permission.approve ? <CheckIcon sx={{ color: 'success.main' }} /> : null}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{permission.print ? <CheckIcon sx={{ color: 'success.main' }} /> : null}</TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>{permission.unlock ? <CheckIcon sx={{ color: 'success.main' }} /> : null}</TableCell>
                                                    </TableRow> 
                                                )) :
                                                    <TableRow>
                                                        <TableCell colSpan={10} sx={{ textAlign: 'center', fontWeight: 'bold' }}>{t('noScreensFound')}</TableCell>
                                                    </TableRow>
                                                }
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow key="permissionsTableFooter">
                                                        <TablePagination 
                                                        count={this.state.totalCount}
                                                        rowsPerPage={this.state.listSize}
                                                        onPageChange={this.handleChangePage}
                                                        page={this.state.page}
                                                        rowsPerPageOptions={common.rowOptions}
                                                        onRowsPerPageChange={this.handleRowsPerPage}/>
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Grid>
                            }                            
                        </Grid>
                        
                        
                    </Grid>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            <SaveButton saveClick={this.save} enabled={true} data-cy='save'/>
                            {this.state.locked && this.props.permission.unlock &&
                                <Button variant="contained" color="secondary" size="small" onClick={this.unlock} data-cy='unlock'>Unlock</Button>
                            }
                        </Grid>
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <br/>
                            <ObjectLog id={this.state._id}  renderNum={this.state.renderNum}/>
                        </Aux>
                    }
                </Panel>
            </Aux>
        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(TenantUser));
