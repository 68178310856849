import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Aux from '../../hoc/Auxilary/Auxilary';
import isEmpty from '../../is-empty';
import { UnlockButton } from '../UI/Buttons/Buttons';
import { withTranslation } from 'react-i18next';
import { encryptString } from '../../helpers/securityHelper';
import UnlockDialog from './UnlockDialog';
class Unlock extends Component{
    constructor(props){
        super(props);
        this.state = {
            reason: '',
            comment: '',
            show: false,
            errors: {},
            pinValid: false,
            pin: ''
        }
    }

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    show = e => {
        this.setState({show: true});
    }

    hide = e => {
        this.setState({show: false});
    }

    unlockClick = async e => {
        const t = this.props.t;
        var valid = this.validate();
        if(valid){
            try {
                let pinEnc = encryptString(this.state.pin);
                let pinResult = await axios.post('/api/userpin', {userid: this.props.auth.user.id, pin: pinEnc});
                if(pinResult.data.signature != null && !pinResult.data.expired){
                    var data = {
                        objectType: this.props.objectType,
                        id: this.props.id,
                        reason: this.state.reason,
                        comment: this.state.comment
                    }
                    var result = await axios.post('/api/unlock', data);
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                    this.setState({show: false});
                    this.props.callback();
                    
                } else {
                    let errors = {pin: t('pinInvalid')};
                    this.setState({errors: errors});
                }
            } catch(err){
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        }
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
             return;
        this.setState({[name]: value});
   };

    validate(){
        const t = this.props.t;
        var errors = {};
        if(this.state.pin == null || this.state.pin === '')
            errors.pin = t('required');
        if(this.state.reason == null || this.state.reason === ''){
            errors.reason = t('required');
        }
        if (this.state.comment == null || this.state.comment === ''){
            errors.comment = t('required');
        }
        this.setState({errors: errors});
        return isEmpty(errors);
    }

    render(){
        const { show } = this.state;
        return(
            <Aux>
                {((this.props.auth.user.isSuper != null && this.props.auth.user.isSuper) || 
                    ((this.props.isDocument == null || !this.props.isDocument) && this.props.enable) ||
                    (this.props.isDocument != null && this.props.isDocument &&
                    (this.props.auth.user.isDocumentAuthor || this.props.auth.user.isDocumentReviewer))) && 
                    <Aux>
                        <UnlockButton unlockClick={this.show} enabled={true} key='UnLockButton' />
                        {show && <UnlockDialog callback={this.props.callback} show={show} toggle={this.hide} objectType={this.props.objectType} id={this.props.id}/>}
                    </Aux>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(Unlock));