import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { CSVDownload } from "react-csv";
import { NumericFormat } from 'react-number-format';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import IconButton from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

class ItemLedgerComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            orderBy: 'number',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            record: '',
            item: '',
            lot: '',
            lotSel: null,
            security: [],
            csvData: null,
            headers: null,
            activityDefinition: '',
            batch: '',
            productionOrder: '',
            productionBatch: '',
            processingType: '',
            container: '',
            objectType: ''
        };
    }

    async componentDidMount(){
        const csvSeparator = sessionStorage.getItem('csvSeparator');
        let record = '';
        if(this.props.record != null){
            record = this.props.record;
        }
        this.setState({
            security: this.props.permission,
            record: record,
            separator: csvSeparator != null ? csvSeparator : ',',
            item: this.props.item != null ? this.props.item : '',
            lot: this.props.lot != null ? this.props.lot : '',
            container: this.props.container != null ? this.props.container : '',
            productionOrder: this.props.productionOrder != null ? this.props.productionOrder : '',
            productionBatch: this.props.productionBatch != null ? this.props.productionBatch : '',
            batch: this.props.batch != null ? this.props.batch : '',
            objectType: this.props.objectType != null ? this.props.objectType : ''
        }, () => {
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            size: this.state.listSize,
            offset: this.state.offset,
            sort: JSON.stringify(sort),
            record: this.props.record != null && this.props.record !== '' ? this.props.record : this.state.record,
            productionOrder: this.state.productionOrder,
            productionBatch: this.state.productionBatch,
            batch: this.state.batch,
            item: this.state.item,
            lot: this.state.lot,
            container: this.state.container,
            objectType: this.state.objectType,
            headers: null
        };
        let list = await axios.get('/api/itemledgers', {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount,
            csvData: null
        });

    };

    async componentDidUpdate(prevProps, prevState){
        if (prevProps.record !== this.props.record || prevProps.renderNum !== this.props.renderNum) {
            this.loadRecords();
          }
    }

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    csvClick = async (e) => {
        this.setState({csvData: null});
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            sort: JSON.stringify(sort),
            record: this.state.record,
            productionOrder: this.state.productionOrder,
            productionBatch: this.state.productionBatch,
            batch: this.state.batch,
            item: this.state.item,
            lot: this.state.lot,
            container: this.state.container,
            objectType: this.state.objectType
        };
        let list = await axios.get('/api/itemledgerexport', {params: data});
        let headers = list.data.headers;
        let csvData = list.data.data;
        this.setState({csvData: csvData, headers: headers});
    }

    render(){
        const t = this.props.t;
        return(
            <Aux>
                {this.state.csvData != null &&
                    <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.headers} separator={this.state.separator}/>
                }
                <Panel>
                    
                    <TableContainer>
                        <Table aria-label="Item Ledgers">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('postingDate')}
                                        active={this.state.orderBy === 'postingDate'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('postingDate')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('item')}
                                        active={this.state.orderBy === 'item'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('item')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('category')}</TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('entryType')}
                                        active={this.state.orderBy === 'entryType'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('entryType')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('quantity')}</TableCell>
                                    <TableCell>{t('source')}</TableCell>
                                    <TableCell><TableSortLabel onClick={() => this.sortClick('lastUpdated')}
                                        active={this.state.orderBy === 'lastUpdated'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('updated')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('reason')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/itemledger/' + row._id} name={'itemLedgerLink' + i}>{row.number}</Link></TableCell>
                                        <TableCell><DateDisplay value={row.postingDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                        <TableCell><Link to={'/item/' + row.item._id}>{row.item.number + '-' + row.item.name}</Link></TableCell>
                                        <TableCell>{row.category}</TableCell>
                                        <TableCell>{row.entryType}</TableCell>
                                        <TableCell>
                                            <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}
                                            suffix={row.quantityUOM != null ? row.quantityUOM.label : ''} decimalScale={3}/>
                                        </TableCell>
                                        <TableCell>
                                            {row.objectType != null && row.record != null &&
                                                <Link to={row.objectType.link + row.record._id}>{row.objectType.text + '-' + row.record.number}</Link>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {row.lastUpdated != null && <DateDisplay value={row.lastUpdated} format={this.props.auth.common.dateTimeFormat}/> }
                                        </TableCell>
                                        <TableCell>{row.reason != null ? row.reason : ''}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TableCell>
                                        <Tooltip title={t('exportToCsv')} >
                                            <IconButton
                                                disabled={false}
                                                component={'div'}
                                                onClick={this.csvClick}
                                                name='actionCsv'
                                            >
                                                <ExcelIcon fontSize='medium'/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(ItemLedgerComponent));
