import React, { Component } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Aux from '../../hoc/Auxilary/Auxilary';
import AutoComplete from '../UI/AutoCompleteField/AutoCompleteField';
import DateSelect from '../UI/DateSelect/DateSelect';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import isEmpty from '../../is-empty';
import { encryptString } from '../../helpers/securityHelper';
import SectionTitle from '../UI/Typography/SectionTitle';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
class Signature extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: '',
            users: '',
            userSelected: null,
            signature: '',
            _id: null,
            object: '',
            objectType: '',
            type: '',
            date: new Date(),
            showSignature: false,
            tenant: this.props.auth.user.tenant,
            errors: {},
            pinSign: false,
            pin: '',
            enableSave: true
        }
        this.sigPad = {};
    }

    async componentDidMount(){
        if(this.props.id != null && this.props.id !== ''){
            var record = await axios.get('/api/signature/' + this.props.id);
            const userSelected = this.props.users.find(x => x.value === record.data.user);
            let userHasPin = false;
            if(userSelected){
                userHasPin = await axios.get('/api/userhaspin/' + userSelected.value);
            }
            this.setState({
                _id: record.data._id,
                signature: record.data.signature,
                user: record.data.user,
                object: record.data.object,
                objectType: record.data.objectType,
                type: record.data.type,
                users: this.props.users,
                date: record.data.date != null && record.data.date !== '' ? record.data.date : new Date(),
                userSelected: userSelected,
                pinSign: userHasPin
            })
        } else {
            let user = this.props.user;
            let userSelected = this.props.user != null && this.props.users != null ? this.props.users.find(x => x.value === this.props.user) : null;
            if(!userSelected && this.props.users.length === 1){
                userSelected = this.props.users[0];
                user = userSelected.value;
            }
            let userHasPin = false;
            if(userSelected){
                userHasPin = await axios.get('/api/userhaspin/' + userSelected.value);
            }
            this.setState({
                user: user,
                object: this.props.object,
                objectType: this.props.objectType,
                type: this.props.type,
                users: this.props.users,
                userSelected: userSelected,
                pinSign: userHasPin,
                date: new Date()
            })
        }
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.users !== this.props.users || (this.props.user != null && this.props.user !== prevProps.user)) {
            let userSelected = this.props.users.find(x => x.value === this.props.user);
            if(!userSelected){
                userSelected = this.props.users[0];
                this.setState({userSelected: userSelected, user: userSelected.value});
            }
        }
    }

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    changeSelect = (name, data) => async (e, value) => {
        this.setState({
            [name]: value,
            [data]: value != null ? value.value : null
        });
        if(data === "user"){
            if(value != null){
                let userHasPin = await axios.get('/api/userhaspin/' + value.value);
                this.setState({pinSign: userHasPin.data.hasPin});
            } else {
                this.setState({pinSign: false});
            }
        }
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    dateChange = () => e => {
        this.setState({date: e});
    };

    clearSig = () => {
        if(!this.state.pinSign){
            this.sigPad.clear();
        }

    };

    saveSig = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._saveSig());
    }

    _saveSig = async () => {
        const t = this.props.t;
        let valid = this.validateSignature();
        if(!valid){
            this.setState({enableSave: true});
            return;
        }
        if(this.props.validate != null){
            let error = this.props.validate(true);
            if(error != null && error !== '' && error !== true){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: error, title: t('error'), severity: 'error'}});
                return;
            }
        }
        let signature = null;
        if(this.state.pinSign){
            let pinEnc = encryptString(this.state.pin);
            let pinResult = await axios.post('/api/userpin', {userid: this.state.user, pin: pinEnc});
            if(pinResult.data.signature != null && !pinResult.data.expired){
                signature = pinResult.data.signature;
            } else {
                if(pinResult.data.expired){
                    this.setState({errors: { [t('pin')]: t('pinExp')}, enableSave: true});
                } else{
                    this.setState({errors: { [t('pin')]: t('pinInvalid')}, enableSave: true});
                }
                return;
            }
        } else {
            signature = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
        }
        if(signature == null || signature === ''){
            let errors = {};
            errors[t('signature')] = t('required');
            this.setState({errors: errors, enableSave: true});
            return;
        }
        let data = {
            _id: this.props.signature != null && this.props.signature._id != null && this.props.signature._id !== '' ?
                this.props.signature._id : null,
            signature: signature,
            user: this.state.user,
            object: this.props.object,
            objectType: this.state.objectType,
            type: this.state.type,
            date: this.state.date
        };
        try {
            let result = await axios.post('/api/signature', data);
            this.props.setId(result.data.id);
            this.hide();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: 'Signature saved', title: t('success'), severity: 'success'}});
        } catch(err){
            if(err.response != null)
                this.setState({enableSave: true})
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    validateSignature(){
        const t = this.props.t;
        let errors = {};
        let date = this.state.date;
        if(this.state.user == null || this.state.user === '' || this.state.userSelected == null)
            errors[t('user')] = t('required');
        if(date == null || date === '')
            date = new Date();
        if(this.state.pinSign){
            if(this.state.pin == null || this.state.pin === '')
                errors[t('pin')] = t('required');
        }
        this.setState({errors: errors, date: date});
        return isEmpty(errors);
    }

    sign = e => {
        let error = null;
        if(this.props.validate != null){
            error = this.props.validate(true);
            if(error != null && error !== true)
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: error, title: this.props.t('required'), severity: 'error'}});
        }
        if(error == null || error === true)
            this.setState({showSignature: true});
    };

    hide = e => {
        this.setState({showSignature: false});
    };

    render() {
        const errors = this.state.errors;
        const t = this.props.t;
        return(
            <Aux>
                {this.props.signature != null && this.props.signature._id !== '' && this.props.signature.user != null &&
                    <Grid container>
                        <Grid item xs={12} sm container>
                            <Grid item xs={12}>
                                <img src={this.props.signature.signature} alt={t('noSignature')} />
                            </Grid>
                            <Grid item xs={12}>
                                <b>Name:</b>&nbsp;&nbsp;
                                    {this.props.signature.user.firstName + ' ' +
                                        this.props.signature.user.lastName }<br/>
                                <b>Date:</b>&nbsp;&nbsp;
                                <DateDisplay format={this.props.auth.user.dateFormat} value={this.props.signature.date}/>
                            </Grid>
                            {this.props.status !== this.props.auth.constants.statuses.signed &&
                                this.props.status !== this.props.auth.constants.documentStatuses.approved &&
                                this.props.status !== this.props.auth.constants.documentReviewStatuses.signed &&
                                <Grid item><Button variant="contained" color="secondary" size="small" onClick={this.sign}>{t('edit')}</Button></Grid>
                            }
                        </Grid>
                    </Grid>
                }
                {(this.props.signature == null || this.props.signature._id === null) &&
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant="contained" color="primary" size="small" onClick={this.sign}>{this.props.name} {t('sign')}</Button>
                        </Grid>
                    </Grid>
                }
                <Dialog open={this.state.showSignature} onClose={this.hide} aria-labelledby={this.props.name + '-signature-dialog'} fullWidth={true}>
                    <DialogTitle aria-labelledby={this.props.name + '-signature-dialog-title'}>
                        <SectionTitle title={this.props.title}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <AutoComplete
                                    value={this.state.userSelected}
                                    options={this.props.users}
                                    error={errors[t('user')] != null ? true : false}
                                    getOptionLabel={(option) => option.label}
                                    onChange={this.changeSelect('userSelected', 'user')}
                                    renderInput={(params) => <TextField {...params} label={t('user')} />}
                                    helperText={errors[t('user')]}
                                    label={t('user')}
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DateSelect
                                    label={t('date')}
                                    value={Date.parse(this.state.date)}
                                    onChange={(event) => this.dateChange(event)}
                                    helperText={errors[t('date')]}
                                    error={errors[t('date')] != null ? true : false}
                                />
                            </Grid>
                        </Grid>
                        {this.state.userSelected != null && this.state.pinSign &&
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}>
                                    <TextField value={this.state.pin} onChange={this.changeNumber} name="pin"
                                    size="medium" fullWidth={true} disabled={false} label={t('pin')} type={'password'}
                                    error={errors[t('pin')] != null ? true : false} helperText={errors[t('pin')]}/>
                                </Grid>
                            </Grid>
                        }
                        {this.state.userSelected != null && !this.state.pinSign &&
                            <Box component="div" borderRadius="borderRadius" sx={{border: "1px solid #9E9E9E", marginTop: "16px"}}>
                                <SignatureCanvas penColor="#5BC6CC" canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                                ref={(ref) => {this.sigPad = ref}}/>
                            </Box>
                        }
                        <Typography>By entering clicking sign you acknowledge that this digital signature is equivalent to your ink signature in all ways.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" size="small" onClick={this.saveSig} disabled={!this.state.enableSave}>{t('save')}</Button>
                        <Button variant="contained" color="secondary" size="small" onClick={this.clearSig}>{t('clear')}</Button>
                        <Button variant="contained" color="secondary" size="small" onClick={this.hide}>{t('close')}</Button>
                    </DialogActions>
                </Dialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    profile: state.profile.profile,
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(Signature));
