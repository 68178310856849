import React, { Component } from 'react';
import { connect } from 'react-redux';
import Deviations from '../Widgets/Deviations';
import Ncrs from '../Widgets/Ncrs';
import Losses from '../Widgets/Losses';
import Incidents from '../Widgets/Incidents';
import Recalls from '../Widgets/Recalls';
// import TestResultLine from '../General/TestResultLine';
import BatchDeviationBar from '../General/BatchDeviationBar';
import ProductionScheduleGantt from '../General/ProductionScheduleGantt';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import HoldLots from '../General/HoldLots';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class QADashboard extends Component {
    componentDidMount = () => {
        const crumbs = [
            { path: 'active', label: this.props.t('qaDashboard'), screen: this.props.auth.screenDefs.CultivationDashboard}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
    };
    render(){
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar >
                    <ActionBar
                        helpPath={this.props.auth.user.helpUrl + '/'+ this.props.security.currentPath.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <HoldLots/>
                    {/* <TestResultLine category="5df9481b43fb1600264ef329" items="5df9492843fb1600264ef332" test="5e0f5c852c5fe4002aca8514"/> */}
                    <BatchDeviationBar/>
                    <Deviations listSize="5" title={t('deviations')}/>
                    <Incidents listSize="5" title={t('incidents')}/>
                    <Ncrs listSize="5" title={t('ncrs')}/>
                    <Losses title={t('lossesCannabis')} listSize="5"/>
                    <Recalls title={t('recalls')} listSize="5"/>
                    <ProductionScheduleGantt/>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile,
    security: state.security
  });
  
export default withTranslation() (connect(mapStateToProps)(QADashboard));


