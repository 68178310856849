import React from 'react';
import { NumericFormat } from 'react-number-format';
import ConversionHelper from '../../../helpers/conversionHelper';
import ReportTitle from '../../UI/Typography/ReportTitle';
import ReportSectionTitle from '../../UI/Typography/ReportSectionTitle';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';

import ActivityRecord from './ActivityRecord';

const boxStyle = {
    margin: '8px',
    width: '100%'
}

const thStyle = {
    borderBottom: '2px solid rgb(224, 224, 224)', 
    fontSize: '9pt', 
    textAlign: 'center'
}

const tdStyle = { 
    border: '0.5px solid rgb(224, 224, 224)', 
    fontSize: '8pt', 
    padding: '5px',
}

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse'
}

export default function CuringActivityTemplate(props) {
    const { dateFormat, record, sequence, t } = props;
    return (
        <div style={{ width: '8.5in', pageBreakBefore: 'always' }}>
            <div style={boxStyle}>
                <ReportTitle 
                    title={record.strain != null && record.strain !== '' ? 
                                record.strain.name + '(' + record.strain.shortCode + ') ' + t('curingActivity') : t('curingActivity')
                        }
                />
            </div>
            <div style={{ ...boxStyle, display: 'block' }}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '200px' }}>{t('activity')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('order')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('status')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('date')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.number != null && record.number !== '' ? record.number : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.order != null && record.order !== '' ? 
                                    record.order : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.status != null && record.status !== '' ? record.status : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.date != null ?
                                    <DateDisplay value={record.date} format={dateFormat}/> : t('na')
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('actions')} />
                </div>
                {record.actions != null && record.actions !== '' && record.actions.length > 0 ?
                    <div style={{ fontSize: '9pt', padding: '5px' }}>
                        {record.actions.map((action, i) => {
                            if(i === record.actions.length - 1)
                                return action.label
                            else
                                return action.label + ', '
                            })
                        }
                    </div> : t('na')                           
                }
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('equipment')} />
                </div>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '350px' }}>{t('type')}</th>
                            <th style={{ ...thStyle, width: '350px' }}>{t('id')}</th>
                            <th style={{ ...thStyle, width: '350px' }}>{t('name')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {record.equipments != null && record.equipments.length > 0 ?
                            record.equipments.map((equipment, i) => 
                                equipment.equipment != null && equipment.equipment !== '' && equipment.equipment.length > 0 &&
                                    equipment.equipment.map((eq, j) => {
                                        return (
                                            <tr key={'equipment-' + i}>
                                                <td style={tdStyle}>
                                                    {equipment.machineType != null && equipment.machineType !== '' ? equipment.machineType : t('na')}
                                                </td>
                                                <td style={tdStyle}>
                                                    {eq.identityNo != null && eq.identityNo !== '' ? 
                                                        eq.identityNo : t('na')
                                                    }
                                                </td>
                                                <td style={tdStyle}>
                                                    {eq.name != null && eq.name !== '' ?
                                                        eq.name : t('na')
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                )
                            ) : 
                            <tr key='noEquipment'>
                                <td style={{ ...tdStyle, textAlign: 'center' }} colSpan={3}>{t('noEquipmentUsed')}</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('comments')} />
                </div>
                <div style={{ fontSize: '9pt', padding: '5px' }}>
                    {record.comments != null && record.comments !== '' ?
                        record.comments : t('na')
                    }
                </div>
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('performedBy')} />
                </div>
                {record.users != null && record.users !== '' && record.users.length > 0 ?
                    <div style={{ fontSize: '9pt', padding: '5px' }}>
                        {record.users.map((user, i) => {
                            if(i === record.users.length - 1)
                                return user.name
                            else
                                return user.name + ', '
                            })
                        }
                    </div> : ''                             
                }
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('consumption')} />
                </div>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '375px' }}>{t('item')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('category')}</th>
                            <th style={{ ...thStyle, width: '75px' }}>{t('hcReportCat')}</th>
                            <th style={{ ...thStyle, width: '75px' }}>{t('amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={tdStyle}>
                                {record.consumption != null && record.consumption.item != null && record.consumption.item !== '' ?
                                    record.consumption.item.name : t('na')
                                }
                            </td>
                            <td style={tdStyle}>
                                {record.consumption != null && record.consumption.category != null && record.consumption.category !== '' ?
                                    record.consumption.category : t('na')
                                }
                            </td>
                            <td style={tdStyle}>
                                {record.consumption != null && record.consumption.hcCategory != null && record.consumption.hcCategory !== '' ?
                                    record.consumption.hcCategory : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'right', fontWeight: 'bold' }}>
                                {record.consumption != null && record.consumption.amount != null && record.consumption.amount !== '' ?
                                    record.consumption.amount + record.consumption.uom : t('na')
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('outputs')} />
                </div>
                <table style={{ width: '100%', borderCollapse: 'collapse' }} >
                    <thead>
                        <tr>
                            <td style={{ ...thStyle, width: '75px' }}><strong>{t('type')}</strong></td>
                            <td style={{ ...thStyle, width: '300px' }}><strong>{t('item')}</strong></td>
                            <td style={{ ...thStyle, width: '75px' }}><strong>{t('lot')}</strong></td>
                            <td style={{ ...thStyle, width: '75px' }}><strong>{t('container')}</strong></td>
                            <td style={{ ...thStyle, width: '75px' }}><strong>{t('quantity')}</strong></td>
                        </tr>
                    </thead>
                    <tbody>
                        {record.containers != null && record.containers.length > 0 ?
                            record.containers.map((row, i) => 
                            <tr key={'containerRow-' + i}>
                                <td style={tdStyle}>{row.type != null && row.type !== '' ? row.type : t('na')}</td>
                                <td style={tdStyle}>{row.item != null && row.item !== '' ? row.item : t('na')}</td>
                                <td style={tdStyle}>{row.lot != null && row.lot !== '' ? row.lot : t('na')}</td>
                                <td style={tdStyle}>{row.container != null && row.container !== '' ? row.container : t('na')}</td>
                                <td style={{ ...tdStyle, textAlign: 'right' }}>
                                    {row.amount != null && row.amount !== '' && row.uom != null && row.uom !== '' ? 
                                        <NumericFormat displayType={'text'} value={row.amount} thousandSeparator={true}
                                        decimalScale={3} suffix={row.uom}/> : t('na')
                                    }
                                </td>
                            </tr>
                        ) :
                            <tr>
                                <td colSpan={5} style={{ ...tdStyle, textAlign: 'center' }}>{t('none')}</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <hr style={{ width: '80%', margin: '16px auto' }} />  
            </div>
            {record.sops != null && record.sops.length > 0 &&
                record.sops.map((sop, i) => 
                    <div style={boxStyle}>
                        <ActivityRecord dateFormat={dateFormat} record={sop.workRecord} sop={sop.sop} t={t} 
                        sequence={sequence != null && sequence !== '' ? 
                            sequence + '.' + ConversionHelper.numberToRomanConverter(i + 1) : ConversionHelper.numberToLetterConverter(i + 1)
                        } />
                    </div>
                )
            }
        </div>
    );
}