import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import SectionTitle from '../UI/Typography/SectionTitle';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import ActionBar from '../UI/Buttons/ActionBar';
import Panel from '../UI/Panel/Panel';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Link } from 'react-router-dom';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ValueHelper from '../../helpers/valueHelper';
import { NumericFormat } from 'react-number-format';
import { completeSigSecurity,
    fieldSecurity } from '../../helpers/securityHelper';
import common from '../../jsons/common.json';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import PurchaseOrderInvoice from './Prints/PurchaseOrderInvoice';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import { SaveButton, DeleteButton, ReceivedButton, UnreceiveButton } from '../UI/Buttons/Buttons';
import Unlock from '../General/Unlock';
import ConfirmDelete from '../General/ConfirmDelete';

class PurchaseOrder extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            status: '',
            statusSel: null,
            statuses: [],
            vendor: '',
            vendorSel: null,
            vendors: [],
            items: [],
            contents: [],
            date: new Date(),
            users: [],
            user: '',
            userSel: null,
            amount: '',
            uoms: [],
            security: [],
            item: '',
            itemSel: null,
            quantity: '',
            uom: '',
            uomSel: null,
            unitCost: '',
            lineCost: '',
            tax: '',
            enableSave: true,
            form: '',
            hasForm: false,
            received: [],
            receipts: [],
            fieldAccess: false,
            completeSigSec: false,
            signer: null,
            flagForSignature: 0,
            optionsMenu: [],
            sigData: null,
            expectedDelivery: '',
            showInvoice: false,
            invoice: null,
            location: '',
            locationSel: null,
            locations: [],
            taxRate: '',
            gstRate: '',
            pstRate: '',
            hstRate: '',
            taxAmount: '',
            gstAmount: '',
            pstAmount: '',
            hstAmount: '',
            totalAmount: '',
            documents: [],
            renderNum: Math.random(),
            deleteOpen: false,
	        isForeign: false,
            allItems: [],
            serviceFee: '',
            shippingCost: ''
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.item, common.cacheValues.vendor, common.cacheValues.quantityUOM,
            common.cacheValues.purchaseOrderStatus, common.cacheValues.purchaseOrderForm, common.cacheValues.location, common.cacheValues.allItems], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let items = cacheValues.items;
        let vendors = cacheValues.vendors;
        const userValues = await ValueHelper.screenUsers(common.screens.purchaseOrder, this.props.auth.user.tenant);
        let statusSel = cacheValues.purchaseOrderStatuses.find(x => x.value === this.props.auth.constants.purchaseOrderStatuses.new);
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
            this.props.auth.user.defaultLocation : '';
        let locationSel = location !== '' ? cacheValues.locations.find(x => x.value === location) : null;
        // let taxRate = locationSel != null ? locationSel.taxRate : null;
        // let gstRate = locationSel != null ? locationSel.gst : null;
        // let pstRate = locationSel != null ? locationSel.pst : null;
        // let hstRate = locationSel != null ? locationSel.hst : null;
        let fieldAccess = fieldSecurity(this.props.permission, statusSel != null ? statusSel.value : '', this.props.auth.common, this.props.auth.constants, common.statusTypes.purchaseOrder);
        this.setState({
            statuses: cacheValues.purchaseOrderStatuses,
            vendors: vendors,
            items: items,
            allItems: cacheValues.allItems,
            status: this.props.auth.constants.purchaseOrderStatuses.new,
            statusSel: statusSel,
            uoms: cacheValues.quantityUOMs,
            security: this.props.permission,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            users: userValues.create,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            fieldAccess: fieldAccess,
            location: location,
            locationSel: locationSel,
            locations: cacheValues.locations
            // taxRate: taxRate,
            // hstRate: hstRate,
            // pstRate: pstRate,
            // gstRate: gstRate
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/purchaseorders', label: this.props.t('purchaseOrders'), screen: this.props.auth.screenDefs.PurchaseOrders},
                    { path: 'active', label: this.props.t('purchaseOrder'), screen: this.props.auth.screenDefs.PurchaseOrders}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async (e) => {
        const t = this.props.t;
        let record = await axios.get('/api/purchaseorder/' + this.state._id);
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let vendorSel = record.data.vendor != null ? this.state.vendors.find(x => x.value === record.data.vendor) : null;
        let isForeign = vendorSel != null && vendorSel.country != null && vendorSel.country !== common.canada ? true : false;
        let userSel = this.state.users.find(x => x.value === record.data.user);
        let locationSel = record.data.location != null ? this.state.locations.find(x => x.value === record.data.location) : null;
        let contents = record.data.contents;
        if(contents != null && contents.length > 0){
            contents.forEach(row => {
                let itemSel = this.state.allItems.find(x => x.value === row.item);
                row.itemSel = itemSel;
                let uomSel = this.state.uoms.find(x => x.value === row.uom);
                row.uomSel = uomSel;
            });
        }
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        const fieldAccess = fieldSecurity(this.props.permission, statusSel != null ? statusSel.value : '', this.props.auth.common, this.props.auth.constants, common.statusTypes.purchaseOrder);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel != null ? statusSel.value : '', common, this.props.auth.constants,
            common.statusTypes.purchaseOrder);
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: this.state.users, name: t('complete'), object: record.data._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.signer,
                objectType: this.props.auth.constants.objectIds.purchaseOrder, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, saveCallback: this.saveCallback
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        let gstRate = record.data.gstRate != null ? record.data.gstRate : !isForeign ? vendorSel?.address?.country?.gst : '';
        let pstRate = record.data.pstRate != null ? record.data.pstRate : !isForeign ? vendorSel?.address?.prov?.pst : '';
        let hstRate = record.data.hstRate != null ? record.data.hstRate : !isForeign ? vendorSel?.address?.prov?.hst : '';
        let taxRate = record.data.taxRate != null ? record.data.taxRate : !isForeign ? vendorSel?.address?.prov?.taxRate : '';
        const flagForSignature = record.data.status === this.props.auth.constants.purchaseOrderStatuses.inprogress ? 1 : 0;
        this.setState({
            number: record.data.number,
            status: record.data.status,
            statusSel: statusSel,
            vendor: record.data.vendor,
            vendorSel: vendorSel,
            date: record.data.date,
            user: record.data.user,
            userSel: userSel,
            amount: record.data.amount,
            contents: contents,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            received: record.data.received != null ? record.data.received : [],
            receipts: record.data.receipts,
            signer: record.data.signer,
            completeSigSec: completeSigSec,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            fieldAccess: fieldAccess,
            expectedDelivery: record.data.expectedDelivery,
            invoice: record.data.invoice,
            location: record.data.location,
            locationSel: locationSel,
            taxRate: taxRate,
            gstRate: gstRate,
            pstRate: pstRate,
            hstRate: hstRate,
            taxAmount: record.data.taxAmount,
            gstAmount: record.data.gstAmount,
            pstAmount: record.data.pstAmount,
            hstAmount: record.data.hstAmount,
            renderNum: Math.random(),
            documents: record.data.documents,
            isForeign: isForeign,
            serviceFee: record.data.serviceFee,
            shippingCost: record.data.shippingCost,
            totalAmount: record.data.totalAmount
        }, () => {
            const crumbs = [
                { path: '/#/purchaseorders', label: this.props.t('purchaseOrders'), screen: this.props.auth.screenDefs.PurchaseOrders},
                { path: 'active', label: this.props.t('purchaseOrder') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.PurchaseOrders}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value}, () => {
            if(e.target.name === 'taxRate'){
                this.calculateAmounts();
            }
        });
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
        });
        if(newValue != null){
            if(data === 'item'){
                let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                this.setState({uomSel: uomSel, uom: newValue.uom, unitCost: newValue.cost});
            } 
            // else if (data === 'location'){
            //     if(!this.state.isForeign){
            //         this.setState({
            //             taxRate: newValue.taxRate,
            //             gstRate: newValue.gst,
            //             pstRate: newValue.pst,
            //             hstRate: newValue.hst
            //         });
            //     }
            // } 
            else if (data === 'vendor'){
                if(newValue.country != null && newValue.country !== common.canada){
                    this.setState({
                        isForeign: true,
                        taxRate: '',
                        gstRate: '',
                        pstRate: '',
                        hstRate: '',
                        gstAmount: '',
                        pstAmount: '',
                        hstAmount: '',
                        taxAmount: ''
                    }, () => {
                        this.calculateAmounts();
                    })
                } else {
                    this.setState({
                        isForeign: false,
                        taxRate: newValue.taxRate,
                        gstRate: newValue.gstRate,
                        pstRate: newValue.pstRate,
                        hstRate: newValue.hstRate
                    }, () => {
                        this.calculateAmounts();
                    });
                }
            }
        } else {
            if(data === 'item'){
                this.setState({
                    uom: '',
                    uomSel: null
                });
            } else if (data === 'location' && !this.state.isForeign){
                this.setState({
                    taxRate: '',
                    gstRate: '',
                    pstRate: '',
                    hstRate: ''
                }, () => {
                    this.calculateAmounts()
                });
            }
        }
    };

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeLineValue = (name, i) => (e) => {
        let contents = this.state.contents;
        let line = contents[i];
        line[name] = e.target.value;
        this.setState({contents: contents});
    };

    changeLineNumber = (name, i) => (e) => {
        let contents = this.state.contents;
        let line = contents[i];
        const value = e.target.value;
        if(isNaN(value))
            return;
        line[name] = value;
        this.setState({contents: contents}, () => this.calculateAmounts());
    };

    changeLineAuto = (name, data, i) => (e, newValue) => {
        let contents = this.state.contents;
        let line = contents[i];
        line[name] = newValue;
        line[data] = newValue != null ? newValue.value : '';
        this.setState({contents: contents}, () => this.calculateAmounts());
    };

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.save()})
    };

    calculateAmounts = () => {
        let contents = this.state.contents;
        let amount = 0;
        let totalAmount = 0;
        let taxAmount = 0;
        let gstAmount = 0;
        let pstAmount = 0;
        let hstAmount = 0;
        let taxRate = this.state.taxRate != null && this.state.taxRate !== '' && this.state.taxRate !== 0 ? this.state.taxRate / 100 : null;
        let hstRate = this.state.hstRate === true ? taxRate : null;
        let gstRate = this.state.gstRate != null && this.state.gstRate !== '' && this.state.gstRate !== 0 ? this.state.gstRate / 100 : null;
        let pstRate = this.state.pstRate != null && this.state.pstRate !== '' && this.state.pstRate !== 0 ? this.state.pstRate / 100 : null;
        for(let row of contents){
            let lineCost = row.quantity != null && row.unitCost != null && row.quantity !== '' && row.unitCost !== '' ? row.quantity * row.unitCost : null;
            row.lineCost = lineCost;
            if(lineCost != null){
                amount += lineCost;
                let lineTax = 0;
                if(row.itemSel?.hcReportCategory != null && row.itemSel.hcReportCategory !== '') {
                    lineTax = gstRate != null ? lineCost * gstRate : null;
                    gstAmount += lineTax;
                }else {
                    if(hstRate === true){
                        lineTax = hstRate != null ? lineCost * hstRate : null;
                        hstAmount += lineTax;
                    }else {
                        if(gstRate != null){
                            lineTax = lineCost * gstRate;
                            gstAmount += lineTax;
                        }
                        if(pstRate != null){
                            lineTax = lineCost * pstRate;
                            pstAmount += lineTax;
                        }
                    }
                }
                row.tax = lineTax;
                row.lineCost = lineTax != null ? lineCost + lineTax : lineCost;
            }
        }
        if(this.state.serviceFee != null && this.state.serviceFee !== ''){
            let serviceFee = parseFloat(this.state.serviceFee);
            amount += serviceFee;
            if (hstRate === true){
                hstAmount += serviceFee * hstRate;
            } else {
                if(gstRate != null){
                    gstAmount += serviceFee * gstRate;
                }
                if(pstRate != null){
                    pstAmount += serviceFee * pstRate;
                }
            }
        }
        taxAmount = taxRate != null  && amount != null && amount !== 0 ? amount * taxRate : null;
        if(hstRate === true){
            taxAmount = hstAmount;
        } else {
            if(gstAmount != null && gstAmount !== ''){
                taxAmount = gstAmount;
            }
            if(pstAmount != null && pstAmount !== ''){
                taxAmount += pstAmount;
            }
        }
        if(this.state.shippingCost != null && this.state.shippingCost !== ''){
            amount += parseFloat(this.state.shippingCost);
        }
        totalAmount = amount;
        if(taxAmount != null && taxAmount !== ''){
            totalAmount += taxAmount;
        }
        this.setState({
            contents: contents,
            amount: amount,
            totalAmount: totalAmount,
            taxAmount: taxAmount,
            gstAmount: gstAmount !== 0 ? gstAmount : null,
            pstAmount: pstAmount !== 0 ? pstAmount : null,
            hstAmount: hstAmount !== 0 ? hstAmount : null
        });
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true })
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let data = {
            _id: this.state._id,
            status: this.state.status,
            date: this.state.date,
            user: this.state.user,
            vendor: this.state.vendor,
            amount: this.state.amount,
            contents: this.state.contents,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            sigData: this.state.sigData,
            expectedDelivery: this.state.expectedDelivery,
            location: this.state.location,
            taxAmount: this.state.taxAmount,
            gstAmount: this.state.gstAmount,
            pstAmount: this.state.pstAmount,
            hstAmount: this.state.hstAmount,
            hstRate: this.state.hstRate,
            pstRate: this.state.pstRate,
            gstRate: this.state.gstRate,
            totalAmount: this.state.totalAmount,
            shippingCost: this.state.shippingCost,
            serviceFee: this.state.serviceFee
        };
        try {
            let result = await axios.post('/api/purchaseorder', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/purchaseorder/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true, sigData: null}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    receiveClick = (e) => {
        if(this.state.enableSave){
            this.setState({enableSave: false}, () => this._receiveClick(e));
        }
    };

    _receiveClick = async (e) => {
        if(this.state.status === this.props.auth.constants.purchaseOrderStatuses.approved && this.props.permission.complete === true){
            try {
                let result = await axios.post('/api/purchaseorderreceived', {id: this.state._id});
                this.setState({enableSave: true}, () => {
                    this.loadRecord();
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
                });
            } catch (err){
                this.setState({ enableSave: true }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
                });
            }
        } else {
            this.setState({enableSave: true});
        }
    };

    approveClick = (e) => {
        if(this.state.enableSave){
            this.setState({enableSave: false}, () => this._approveClick(e));
        }
    }

    _approveClick = async (e) => {
        if(this.state.status === this.props.auth.constants.purchaseOrderStatuses.received && this.props.permission.complete === true){
            try {
                let result = await axios.post('/api/purchaseorderapproved', {id: this.state._id});
                this.setState({enableSave: true}, () => {
                    this.loadRecord();
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
                });
            } catch (err){
                this.setState({ enableSave: true }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
                });
            }
        } else {
            this.setState({enableSave: true});
        }
    }

    validate(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.user == null || state.user === '')
            errors[t('user')] = t('required');
        if(state.vendor == null || state.vendor === '')
            errors[t('vendor')] = t('required');
        if(state.status == null || state.status === '')
            errors[t('status')] = t('required');
        if((state.item != null && state.item !== '') || (state.quantity != null && state.quantity !== '') || (state.unitCost != null && state.unitCost !== '') ||
        (state.tax != null && state.tax !== '') || (state.lineCost != null && state.lineCost !== ''))
            errors[t('item')] = t('rowNotAdded');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    validateComplete = () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid)
            return t('notAllComplete');
        return null;
    }

    addLine = (e) => {
        let valid = this.validateLine();
        if(!valid)
            return;
        let contents = this.state.contents;
        contents.push({
            item: this.state.item,
            itemSel: this.state.itemSel,
            quantity: this.state.quantity,
            uom: this.state.uom,
            uomSel: this.state.uomSel,
            unitCost: this.state.unitCost,
            lineCost: this.state.lineCost,
            tax: this.state.tax
        });
        this.setState({
            contents: contents,
            item: '',
            itemSel: null,
            quantity: '',
            uom: '',
            uomSel: null,
            unitCost: '',
            lineCost: '',
            tax: ''
        }, () => this.calculateAmounts());
    };

    deleteLine = (i) => (e) => {
        let contents = this.state.contents;
        contents.splice(i, 1);
        this.setState({contents: contents});
    };

    validateLine(){
        const t = this.props.t;
        let errors = {};
        if(this.state.item == null || this.state.item === '')
            errors[t('item')] = t('required');
        if(this.state.quantity == null || this.state.quantity === '')
            errors[t('quantity')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    invoiceClick = async (e) => {
        this.setState({showInvoice: !this.state.showInvoice});
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick());
    }

    _deleteClick = async () => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/purchaseorder/' + this.state._id);
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.props.history.goBack();
        }
    };

    render(){
        const fieldAccess = this.state.fieldAccess;
        const errors = this.props.errors;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        invoiceClick={this.invoiceClick}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.purchaseOrder}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.invoice]}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <FormControl data-cy="number">
                                <FormLabel>{t('number')}</FormLabel>
                                {this.state.number != null && this.state.number !== '' && this.state.number}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControl data-cy="status">
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSel != null ? this.state.statusSel.label: ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} xl={1}>
                            {this.state.locations != null && this.state.locations.length > 1 ?
                                fieldAccess ?
                                    <AutoCompleteField
                                        value={this.state.locationSel}
                                        options={this.state.locations}
                                        onChange={this.changeAuto('locationSel', 'location')}
                                        error={errors[t('location')] != null ? true : false}
                                        helperText={errors[t('location')]}
                                        label={t('location')}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy='location'
                                    />
                                :
                                    <FormControl data-cy='location'>
                                        <FormLabel>{t('location')}</FormLabel>
                                        {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                    </FormControl>
                                :
                                    <FormControl data-cy='location'>
                                        <FormLabel>{t('location')}</FormLabel>
                                        {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                    </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <DateSelect
                                    onChange={this.changeDate('date')}
                                    value={this.state.date}
                                    helperText={errors[t('date')]}
                                    error={errors[t('date')] != null ? true : false}
                                    label={t('date')}
                                    disabled={!fieldAccess}
                                    format={this.props.auth.user.dateFormat}
                                    data-cy="date"
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('date')}</FormLabel>
                                    <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.userSel}
                                    options={this.state.users}
                                    onChange={this.changeAuto('userSel', 'user')}
                                    error={errors[t('user')] != null ? true : false}
                                    helperText={errors[t('user')]}
                                    label={t('user')}
                                    disabled={!fieldAccess}
                                    required
                                    data-cy="user"
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('user')}</FormLabel>
                                    {this.state.userSel != null ? this.state.userSel.label: ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.vendorSel}
                                    options={this.state.vendors}
                                    onChange={this.changeAuto('vendorSel', 'vendor')}
                                    error={errors[t('vendor')] != null ? true : false}
                                    helperText={errors[t('vendor')]}
                                    label={t('vendor')}
                                    disabled={!fieldAccess}
                                    required
                                    data-cy="vendor"
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('vendor')}</FormLabel>
                                    {this.state.vendorSel != null ? this.state.vendorSel.label: ''}
                                </FormControl>
                            }
                        </Grid>
                        {fieldAccess && 
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.serviceFee} onChange={this.changeNumber} name="serviceFee"
                                    size="medium" fullWidth={true} disabled={!fieldAccess} data-cy="serviceFee" label={t('serviceFee')}
                                    error={errors[t('serviceFee')] != null ? true : false} helperText={errors[t('serviceFee')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.serviceFee != null && this.state.serviceFee !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('serviceFee')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.serviceFee} thousandSeparator={true} decimalScale={2} 
                                    fixedDecimalScale={true} prefix="$"/>
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.shippingCost} onChange={this.changeNumber} name="shippingCost"
                                    size="medium" fullWidth={true} disabled={!fieldAccess} data-cy="shippingCost" label={t('shippingCost')}
                                    error={errors[t('shippingCost')] != null ? true : false} helperText={errors[t('shippingCost')]}/>
                            </Grid> 
                        }
                        {!fieldAccess && this.state.shippingCost != null && this.state.shippingCost !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('shippingCost')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.shippingCost} thousandSeparator={true} decimalScale={2} 
                                    fixedDecimalScale={true} prefix="$"/>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.amount != null && this.state.amount !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('amount')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.amount} thousandSeparator={true} decimalScale={2} 
                                    fixedDecimalScale={true} prefix="$"/>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.taxRate != null && this.state.taxRate !== '' && (!this.state.isForeign || !fieldAccess) &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('taxRate')}</FormLabel>
                                    {this.state.taxRate}
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess && this.state.isForeign &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.taxRate} onChange={this.changeNumber} name="taxRate"
                                    size="medium" fullWidth={true} disabled={!fieldAccess} data-cy="taxRate" label={t('taxRate')}
                                    error={errors[t('taxRate')] != null ? true : false} helperText={errors[t('taxRate')]}/>
                            </Grid>
                        }
                        {this.state.hstRate === true && !this.state.isForeign &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('hstRate')}</FormLabel>
                                    {this.state.taxRate}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.gstRate != null && this.state.gstRate !== '' && !this.state.isForeign &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('gstRate')}</FormLabel>
                                    {this.state.gstRate}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.pstRate != null && this.state.pstRate !== '' && !this.state.isForeign &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('pstRate')}</FormLabel>
                                    {this.state.pstRate}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.taxAmount != null && this.state.taxAmount !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('taxAmount')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.taxAmount} thousandSeparator={true} decimalScale={2} 
                                    fixedDecimalScale={true} prefix="$"/>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.hstAmount != null && this.state.hstAmount !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('hst')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.hstAmount} thousandSeparator={true} decimalScale={2} 
                                    fixedDecimalScale={true} prefix="$"/>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.gstAmount != null && this.state.gstAmount !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('gst')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.gstAmount} thousandSeparator={true} decimalScale={2} 
                                    fixedDecimalScale={true} prefix="$"/>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.pstAmount != null && this.state.pstAmount !== '' &&
                            <Grid item xs={4} sm={4}>
                                <FormControl>
                                    <FormLabel>{t('pst')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.pstAmount} thousandSeparator={true} decimalScale={2} 
                                    fixedDecimalScale={true} prefix="$"/>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.totalAmount != null && this.state.totalAmount !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('totalAmount')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.totalAmount} thousandSeparator={true} decimalScale={2} 
                                    fixedDecimalScale={true} prefix="$"/>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <DateSelect
                                    onChange={this.changeDate('expectedDelivery')}
                                    value={this.state.expectedDelivery}
                                    helperText={errors[t('expectedDelivery')]}
                                    error={errors[t('expectedDelivery')] != null ? true : false}
                                    label={t('expectedDelivery')}
                                    disabled={!fieldAccess}
                                    format={this.props.auth.user.dateFormat}
                                    data-cy="expectedDelivery"
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('expectedDelivery')}</FormLabel>
                                    <DateDisplay value={this.state.expectedDelivery} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    {this.state.hasForm === true &&
                        <Aux>
                            <br/>
                            {errors[t('form')] != null && errors[t('form')] !== '' &&
                                <div style={{color: 'red'}}>{errors[t('form')]}</div>
                            }
                            <Form renderer={renderer}
                                defaultFields={this.state.form}
                                onChange={this.formValueChanged}
                                disabled={!fieldAccess}/>
                        </Aux>
                    }
                    <br/>
                    <SectionTitle title={t("contents")}/>
                    {!fieldAccess &&
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow key="contentHead">
                                        <TableCell>{t('item')}</TableCell>
                                        <TableCell>{t('quantity')}</TableCell>
                                        <TableCell align="center">{t('unitCost')}</TableCell>
                                        <TableCell align="center">{t('tax')}</TableCell>
                                        <TableCell align="center">{t('lineCost')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.contents != null && this.state.contents.length > 0 && this.state.contents.map((row, i) =>
                                        <TableRow key={'line' + i}>
                                            <TableCell>{row.itemSel != null && <Link to={'/item/' + row.itemSel.value}>{row.itemSel.label}</Link>}</TableCell>
                                            <TableCell>
                                                <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}
                                                decimalScale={3} suffix={row.uomSel != null ? row.uomSel.label : ''}/>
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumericFormat displayType={'text'} value={row.unitCost} thousandSeparator={true} decimalScale={2} prefix="$"/>
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumericFormat displayType={'text'} value={row.tax} thousandSeparator={true} decimalScale={2} prefix="$"/>
                                            </TableCell>
                                            <TableCell align="right">
                                                <NumericFormat displayType={'text'} value={row.lineCost} thousandSeparator={true} decimalScale={2} prefix="$"/>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {fieldAccess &&
                        <Grid container spacing={3}>
                            <Grid item xs={3} sm={3}>{t('item')}</Grid>
                            <Grid item xs={2} sm={1}>{t('quantity')}</Grid>
                            <Grid item xs={2} sm={1}>{t('uom')}</Grid>
                            <Grid item xs={4} sm={2}>{t('unitCost')}</Grid>
                            <Grid item xs={2} sm={1} align="center">{t('tax')}</Grid>
                            <Grid item xs={4} sm={2} align="center">{t('lineCost')}</Grid>
                        </Grid>
                    }
                    {fieldAccess && this.state.contents != null && this.state.contents.length > 0 && this.state.contents.map((row, i) =>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                {fieldAccess &&
                                    <AutoCompleteField
                                        value={row.itemSel}
                                        options={this.state.items}
                                        onChange={this.changeLineAuto('itemSel', 'item', i)}
                                        error={errors[`${t('item')} (${t('line')} ${i})`] != null ? true : false}
                                        helperText={errors[`${t('item')} (${t('line')} ${i})`]}
                                        disabled={!fieldAccess}
                                        data-cy={"item" + i}
                                    />
                                }
                                {!fieldAccess && row.itemSel != null &&
                                    <Link to={'/item/' + row.itemSel.value}>{row.itemSel.label}</Link>
                                }
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                {fieldAccess &&
                                    <TextField type="number" value={row.quantity} onChange={this.changeLineNumber('quantity', i)} name={"quantity" + i}
                                    size="medium" fullWidth={true} disabled={!fieldAccess} data-cy={"quantity" + i}
                                    error={errors[`${t('quantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('quantity')} (${t('line')} ${i})`]}/>
                                }
                                {!fieldAccess && 
                                    <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true} decimalScale={2} />
                                }
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                {row.uomSel != null ? row.uomSel.label : ''}
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                {fieldAccess &&
                                    <TextField type="number" value={row.unitCost} onChange={this.changeLineNumber('unitCost', i)} name={"unitCost" + i}
                                    size="medium" fullWidth={true} disabled={!fieldAccess} data-cy={"unitCost" + i}
                                    error={errors[`${t('unitCost')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('unitCost')} (${t('line')} ${i})`]}/>
                                }
                                {!fieldAccess && 
                                    <NumericFormat displayType={'text'} value={row.unitCost} thousandSeparator={true} decimalScale={2} 
                                    fixedDecimalScale={true} prefix="$"/>
                                }
                            </Grid>
                            <Grid item xs={2} sm={1} align="right">
                                {row.tax != null && row.tax !== '' && row.tax !== 0 &&
                                    <NumericFormat displayType={'text'} value={row.tax} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix="$"/>
                                }
                            </Grid>
                            <Grid item xs={4} sm={2} align="right">
                                <NumericFormat displayType={'text'} value={row.lineCost} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} prefix="$"/>
                            </Grid>
                            {fieldAccess &&
                                <Grid item xs={2} sm={1}>
                                    <IconButton aria-label="delete line" onClick={this.deleteLine(i)} data-cy={"deleteLine" + i}>
                                        <RemoveCircleTwoToneIcon  />
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    )}
                    {fieldAccess &&
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.itemSel}
                                    options={this.state.items}
                                    onChange={this.changeAuto('itemSel', 'item')}
                                    error={errors[t('item')] != null ? true : false}
                                    helperText={errors[t('item')]}
                                    disabled={!fieldAccess}
                                    data-cy="item"
                                />
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <TextField type="number" value={this.state.quantity} onChange={this.changeNumber} name={"quantity"}
                                size="medium" fullWidth={true} disabled={!fieldAccess} data-cy="quantity"
                                error={errors[t('quantity')] != null ? true : false} helperText={errors[t('quantity')]}/>
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <AutoCompleteField
                                    value={this.state.uomSel}
                                    options={this.state.uoms}
                                    onChange={this.changeAuto('uomSel', 'uom')}
                                    error={errors[t('uom')] != null ? true : false}
                                    helperText={errors[t('uom')]}
                                    disabled={true}
                                    data-cy="uom"
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.unitCost} onChange={this.changeNumber} name={"unitCost"}
                                size="medium" fullWidth={true} disabled={!fieldAccess} data-cy="unitCost"
                                error={errors[t('unitCost')] != null ? true : false} helperText={errors[t('unitCost')]}/>
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <IconButton aria-label="add line" onClick={this.addLine} data-cy="addLine">
                                    <AddBoxTwoToneIcon  />
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                    {this.state.received != null && this.state.received.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('received')}/>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow key="receivedhead">
                                            <TableCell>{t('item')}</TableCell>
                                            <TableCell>{t('quantity')}</TableCell>
                                            <TableCell>{t('percent')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.received.map((row, i) => 
                                            <TableRow key={'received' + i}>
                                                <TableCell>{row.item.number + '-' + row.item.name}</TableCell>
                                                <TableCell>
                                                    <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}
                                                    decimalScale={3} suffix={row.uom != null ? row.uom : ''}/>
                                                </TableCell>
                                                {row.percent != null &&
                                                    <TableCell>
                                                        <NumericFormat displayType={'text'} value={row.percent} decimalScale={0}/>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Aux>
                    }
                    {this.state.receipts != null && this.state.receipts.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('receipts')}/>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow key="receiptsHead">
                                            <TableCell>{t('number')}</TableCell>
                                            <TableCell>{t('date')}</TableCell>
                                            <TableCell>{t('status')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.receipts.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell>
                                                    <Link to={'/receipt/' + row._id}>{row.number}</Link>
                                                </TableCell>
                                                <TableCell>
                                                    <DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/>
                                                </TableCell>
                                                <TableCell item xs={4} sm={2}>{row.status}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Aux>
                    }
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            {this.state.fieldAccess &&
                                <Aux>
                                    <SaveButton enabled={this.state.enableSave} saveClick={this.save} data-cy='save'/>
                                    {this.props.permission.delete && this.state._id != null && this.state._id !== '' && (this.state.receipts == null || this.state.receipts.length === 0) &&
                                        <DeleteButton deleteClick={this.deleteToggle} enabled={this.state.enableSave} data-cy='delete'/>
                                    }
                                </Aux>
                            }
                            {this.state.status === this.props.auth.constants.purchaseOrderStatuses.approved && this.props.permission.complete &&
                                <ReceivedButton receivedClick={() => this.receiveClick()} enabled={this.state.enableSave} data-cy='receive'/>
                            }
                            {this.state.status === this.props.auth.constants.purchaseOrderStatuses.received && this.props.permission.complete &&
                                <UnreceiveButton unreceivedClick={() => this.approveClick()} enabled={this.state.enableSave} data-cy='unreceive'/>
                            }
                            {this.state.status === this.props.auth.constants.purchaseOrderStatuses.approved && this.props.permission.unlock &&
                                <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.purchaseOrder} callback={this.loadRecord} enable={true} data-cy="unlock"/>
                            }
                        </Grid>
                    </Grid>
                </Panel>
                {this.state.showInvoice &&
                    <PurchaseOrderInvoice
                        id={this.state._id}
                        open={this.state.showInvoice}
                        onClose={this.invoiceClick}
                        callBack={this.loadRecord}
                    />
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.purchaseOrder}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(PurchaseOrder));
