import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import isEmpty from '../../is-empty';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import InputMaskField from '../UI/InputMaskField/InputMaskField'
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import { withTranslation } from 'react-i18next';

class HazardousWitness extends Component {
    constructor(props){
        super(props);

        this.state = {
            witnesses: [],
            _id: '',
            user: '',
            department: '',
            phoneNumber: '',
            users: [],
            edit: false,
            witnessSelected: null,
            enableSave: true
        }

        this.changeValue = this.changeValue.bind(this);
        this.saveClick = this.saveClick.bind(this);
        this.clearClick = this.clearClick.bind(this);
        this.deleteClick = this.deleteClick.bind(this);
        this.newClick = this.newClick.bind(this);
    }

    componentDidMount(){
        axios.get('/api/allusers')
        .then(users => {
            this.setState({users: users.data});
            this.loadWitnesses();
        })
        .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        });
    }

    loadWitnesses(){
        axios.get('/api/hazardouswitnesses/' + this.props.id)
        .then(list => {
            this.setState({
                witnesses: list.data != null && list.data.length > 0 ? list.data : [],
                _id: '',
                user: '',
                department: '',
                phoneNumber: '',
                edit: false
            });
        })
        .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        });
    }

    changeValue(e){
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({[name]: newValue, [data]: newValue != null ? newValue.value : null});
    };

    newClick(e){
        e.preventDefault();
        this.setState({edit: true});
    }

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = () => {
        const t = this.props.t;
        const valid = this.validateWitness();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        var data = {
            incidentId: this.props.id,
            _id: this.state._id,
            user: this.state.user,
            department: this.state.department,
            phoneNumber: this.state.phoneNumber
        }
        axios.post('/api/hazardouswitnesssave', data)
        .then(result => {
            this.setState({ enableSave: true }, () => {
                this.loadWitnesses();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        })
        .catch(err => {
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        });
    }

    validateWitness(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.user == null || state.user === '')
            errors[t('user')] = t('required');
        if(state.phoneNumber == null || state.phoneNumber === '')
            errors[t('phoneNumber')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    clearClick(e){
        e.preventDefault();
        this.setState({
            _id: '',
            user: '',
            department: '',
            phoneNumber: '',
            edit: false,
            userSelected: null
        })
    }

    deleteClick(e){
        const t = this.props.t;
        var data = {
            incidentId: this.props.id,
            _id: this.state._id
        }
        axios.post('/api/hazardouswitnessdelete', data)
        .then(result => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            this.loadWitnesses();
        })
        .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        });
    }

    editAction(id){
        var witness = null;
        for(var i = 0; i < this.state.witnesses.length; i++){
            if(this.state.witnesses[i]._id === id){
                witness = this.state.witnesses[i];
                break;
            }
        }
        if(witness != null){
            var witnessSelected = this.state.users.find(x => x.value === witness.user._id);
            this.setState({
                _id: witness._id,
                user: witness.user._id,
                department: witness.department,
                phoneNumber: witness.phoneNumber,
                edit: true,
                witnessSelected: witnessSelected
            });
        }
    }

    render(){
        const errors = this.props.errors;
        const signed = false;
        const t = this.props.t;
        return(
            <Aux>
                <TableContainer component={Paper}>
                    <Table aria-label="Witnesses">
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>{t('name')}</TableCell>
                                <TableCell>{t('department')}</TableCell>
                                <TableCell>{t('phoneNumber')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.witnesses.map((witness, i) =>
                                <TableRow key={witness._id}>
                                    <TableCell>
                                        <TextOnlyClickButton enabled={true} click={() => this.editAction(witness._id)} 
                                        name={witness.user.firstName + ' ' + witness.user.lastName}/>
                                    </TableCell>
                                    <TableCell>{witness.department}</TableCell>
                                    <TableCell><NumericFormat value={witness.phoneNumber} displayType={'text'} format={'### ### ####'}/></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.edit === false &&
                    <Button variant="contained" color="primary" size="small" onClick={this.newClick}>{t('add')}</Button>
                }
                {this.state.edit &&
                    <Aux>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.witnessSelected}
                                    options={this.state.users}
                                    onChange={this.changeAuto('witnessSelected', 'user')}
                                    error={errors[t('user')] != null ? true : false}
                                    helperText={errors[t('user')]}
                                    label={t('witness')}
                                    disabled={signed}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TextField value={this.state.department} onChange={this.changeValue} name="department"
                                size="medium" fullWidth={true} disabled={signed} label={t('department')}
                                error={errors[t('department')] != null ? true : false} helperText={errors[t('department')]}/>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <InputMaskField mask="999-999-9999" name="phoneNumber" value={this.state.phoneNumber}
                                onChange={this.changeValue} disabled={signed}
                                helperText={errors[t('phoneNumber')]}
                                error={errors[t('phoneNumber')] != null ? true : false}
                                label={t('phoneNumber')}
                                required
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={5}>
                                <Button variant="contained" color="primary" size="small" disabled={!this.state.enableSave}
                                    onClick={this.saveClick}>{t('save')}</Button>&nbsp;&nbsp;
                                <Button variant="contained" color="secondary" size="small"
                                    onClick={this.clearClick}>{t('close')}</Button>&nbsp;&nbsp;
                                <Button variant="contained" color="secondary" size="small"
                                    onClick={this.deleteClick} disabled={this.state._id === ''}>{t('delete')}</Button>
                            </Grid>
                        </Grid>
                    </Aux>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(HazardousWitness));
