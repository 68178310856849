import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty'; 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Aux from '../../hoc/Auxilary/Auxilary';
import { ClearButton, SaveButton, TextOnlyClickButton } from '../UI/Buttons/Buttons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SectionTitle from '../UI/Typography/SectionTitle';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Panel from '../UI/Panel/Panel';
import ActionBar from '../UI/Buttons/ActionBar';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class Countries extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            code: '',
            name: '',
            edit: false,
            gst: '',
            taxItems: [],
            taxItem: '',
            taxItemSel: null,
            taxItemList: [],
            flatRate: '',
            adValorem: '',
            type: '',
            typeSel: null,
            types: [],
            _id: '',
            enableSave: true
        };
    }

    async componentDidMount(){
        let values = await axios.get('/int/countryvalues');
        this.setState({
            taxItemList: values.data.taxItems,
            types: values.data.types
        }, () => {
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        let list = await axios.get('/int/countries');
        let countries = list.data;
        countries.forEach(country => {
            if(country.taxItems != null){
                country.taxItems.forEach(row => {
                    let taxItemSel = this.state.taxItemList.find(x => x.value === row.taxItem);
                    row.taxItemSel = taxItemSel;
                    let typeSel = this.state.types.find(x => x.value === row.type);
                    row.typeSel = typeSel;
                });
            }
        });
        this.setState({
            list: countries
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('countries'), screen: this.props.auth.screenDefs.Countries}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async (e) => {
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }            
        let data = {
            _id: this.state._id,
            code: this.state.code,
            name: this.state.name,
            gst: this.state.gst,
            taxItems: this.state.taxItems
        };
        try {
            const result = await axios.post('/int/country', data);
            this.setState({ enableSave: true }, () => {
                this.clear();
                this.loadRecords();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        if(this.state.code == null || this.state.code === '')
            errors[t('code')] = t('required');
        if(this.state.name == null || this.state.name === '')
            errors[t('name')] = t('required');
        for(let i = 0; i < this.state.taxItems.length; i++){
            let row = this.state.taxItems[i];
            if(row.taxItem == null || row.taxItem === '')
                errors[`${t('taxItem')} (${t('line')} ${i})`] = t('required');
            if(row.flatRate == null || row.flatRate === '')
                errors[`${t('flatRate')} (${t('line')} ${i})`] = t('required');
            if(row.type == null || row.type === '')
                errors[`${t('type')} (${t('line')} ${i})`] = t('required');
        }
        if((this.state.taxItem != null && this.state.taxItem !== '') || (this.state.flatRate != null && this.state.flatRate !== '') ||
        (this.state.adValorem != null && this.state.adValorem !== '') || (this.state.type != null && this.state.type !== ''))
            errors[t('taxItem')] = t('rowNotAdded');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    clear = (e) => {
        this.setState({
            code: '',
            _id: '',
            name: '',
            gst: '',
            edit: false,
            taxItem: '',
            taxItemSel: null,
            taxItems: [],
            flatRate: '',
            adValorem: '',
            type: '',
            typeSel: ''
        })
    };

    editRecord(index){
        let country = this.state.list[index];
        this.setState({
            _id: country._id,
            code: country.code,
            name: country.name,
            gst: country.gst,
            taxItems: country.taxItems,
            edit: true
        });
    }

    addRecord = (e) => {
        this.setState({edit: true});
    };

    addLine = (e) => {
        let valid = this.validateLine();
        if(!valid)
            return;
        let taxItems = this.state.taxItems != null ? this.state.taxItems : [];
        taxItems.push({
            taxItem: this.state.taxItem,
            taxItemSel: this.state.taxItemSel,
            flatRate: this.state.flatRate,
            adValorem: this.state.adValorem,
            type: this.state.type,
            typeSel: this.state.typeSel
        });
        this.setState({
            taxItems: taxItems,
            taxItem: '',
            taxItemSel: null,
            flatRate: '',
            adValorem: '',
            type: '',
            typeSel: null
        });
    };

    deleteLine = (index) => (e) => {
        let taxItems = this.state.taxItems;
        taxItems.splice(index, 1);
        this.setState({taxItems: taxItems});
    };

    validateLine(){
        const t = this.props.t;
        let errors = {};
        if(this.state.taxItem == null || this.state.taxItem === '')
            errors[t('taxItem')] = t('required');
        if(this.state.flatRate == null || this.state.taxItem === '')
            errors[t('taxItem')] = t('required');
        if(this.state.type == null || this.state.type === '')
            errors[t('type')] = t('required');
        let exists = this.state.taxItems.find(x => x.taxItem === this.state.taxItem);
        if(exists)
            errors[t('taxItem')] = t('rowAlreadyExists');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeLineNumber = (name, index) => (e) => {
        let taxItems = this.state.taxItems;
        let taxItem = taxItems[index];
        let value = e.target.value;
        if(isNaN(value))
            return;
            taxItem[name] = value;
            this.setState({taxItems: taxItems});
    };

    changeLineAuto = (name, data, index) => async (e, newValue) => {
        let taxItems = this.state.taxItems;
        let taxItem = taxItems[index];
        taxItem[name] = newValue;
        taxItem[data] = newValue != null ? newValue.value : null;
        this.setState({
            taxItems: taxItems
        });
    };

    render(){
        const { errors, t } = this.props;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createClick={this.addRecord}
                        createAccess={true}
                    />
                </BreadcrumbBar>
                <Panel>
                    <TableContainer component={Paper}>
                        <Table aria-label="countries">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>{t('code')}</TableCell>
                                    <TableCell>{t('name')}</TableCell>
                                    <TableCell>{t('gst')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <TextOnlyClickButton
                                                click={() => this.editRecord(i)}
                                                name={row.code}
                                                iteration={i}
                                                enabled={true}
                                            />
                                        </TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.gst}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Panel>
                <Dialog open={this.state.edit} onClose={this.clear} maxWidth="md" fullWidth={true}>
                    <DialogTitle>
                        <SectionTitle title={t('countryDetails')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={2} sm={1}>
                                <TextField value={this.state.code} onChange={this.changeValue} name="code"
                                size="medium" fullWidth={true} label={t('code')} required 
                                error={errors[t('code')] != null ? true : false} helperText={errors[t('code')]}/>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                size="medium" fullWidth={true} label={t('name')} required 
                                error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <TextField value={this.state.gst} onChange={this.changeValue} name="gst"
                                size="medium" fullWidth={true} label={t('gst')} 
                                error={errors[t('gst')] != null ? true : false} helperText={errors[t('gst')]}/>
                            </Grid>
                        </Grid>
                        <br/>
                        <SectionTitle title={t('itemRates')}/>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>{t('taxItem') + '*'}</Grid>
                            <Grid item xs={4} sm={2}>{t('flatRate')}</Grid>
                            <Grid item xs={4} sm={2}>{t('adValorem')}</Grid>
                            <Grid item xs={4} sm={2}>{t('type') + '*'}</Grid>
                        </Grid>
                        {this.state.taxItems != null && this.state.taxItems.length > 0 && this.state.taxItems.map((row, i) =>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={row.taxItemSel}
                                        options={this.state.taxItemList}
                                        onChange={this.changeLineAuto('taxItemSel', 'taxItem', i)}
                                        error={errors[`${t('taxItem')} (${t('line')} ${i})`] != null ? true : false}
                                        helperText={errors[`${t('taxItem')} (${t('line')} ${i})`]}
                                        disabled={false}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <TextField value={row.flatRate} onChange={this.changeLineNumber('flatRate', i)} name={"flatRate" + i}
                                    size="medium" fullWidth={true} disabled={false} required
                                    error={errors[`${t('flatRate')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('flatRate')} (${t('line')} ${i})`]}/>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <TextField value={row.adValorem} onChange={this.changeLineNumber('adValorem', i)} name={"adValorem" + i}
                                    size="medium" fullWidth={true} disabled={false} helperText={errors[`${t('adValorem')} (${t('line')} ${i})`]}
                                    error={errors[`${t('adValorem')} (${t('line')} ${i})`] != null ? true : false}/>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={row.typeSel}
                                        options={this.state.types}
                                        onChange={this.changeLineAuto('typeSel', 'type', i)}
                                        error={errors[`${t('type')} (${t('line')} ${i})`] != null ? true : false}
                                        helperText={errors[`${t('type')} (${t('line')} ${i})`]} 
                                        disabled={false}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <IconButton aria-label="delete line" onClick={this.deleteLine(i)}>
                                        <RemoveCircleTwoToneIcon  />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.taxItemSel}
                                    options={this.state.taxItemList}
                                    onChange={this.changeAuto('taxItemSel', 'taxItem')}
                                    error={errors[t('taxItem')] != null ? true : false}
                                    helperText={errors[t('taxItem')]} 
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.flatRate} onChange={this.changeNumber} name="flatRate"
                                size="medium" fullWidth={true} disabled={false} 
                                error={errors[t('flatRate')] != null ? true : false} helperText={errors[t('flatRate')]}/>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.adValorem} onChange={this.changeNumber} name="adValorem"
                                size="medium" fullWidth={true} disabled={false} 
                                error={errors[t('adValorem')] != null ? true : false} helperText={errors[t('adValorem')]}/>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <AutoCompleteField
                                    value={this.state.typeSel}
                                    options={this.state.types}
                                    onChange={this.changeAuto('typeSel', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]} 
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <IconButton aria-label="add line" onClick={this.addLine}>
                                    <AddBoxTwoToneIcon  />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton saveClick={this.save} enabled={this.state.enableSave} />
                        <ClearButton clearClick={this.clear} enabled={true} />
                    </DialogActions>
                </Dialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Countries));
