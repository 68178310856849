import React, { Component, Suspense } from 'react';
import axios from 'axios';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react'
import DynamicRouter from './containers/DynamicRouter';
import Layout from './hoc/Layout/Layout';
import CacheBuster from './CacheBuster';
import { ThemeProvider } from '@mui/material/styles';
import { jwtDecode } from 'jwt-decode';
import setAuthToken from './setAuthToken';
import { setCurrentUser, logoutUser, setUserProfile } from './actions/authentication';
import { setScreenLock, setBlurScreen } from './actions/screenLock';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee, faCaretDown, faCaretUp, faSearch, faCaretRight, faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { faFile, faFileImage, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import theme from './theme';
// import ErrorBoundary from './hoc/ErrorBoundary/ErrorBoundary';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-select/dist/css/bootstrap-select.min.css'
import './App.css';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'; 
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';



axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  if ((err.response.status === 401 || err.response.message === '401 Unauthorized') && window.location.hash !== '#/login') {
    store.dispatch(logoutUser());
    window.location.href = '/#/login'
  }
  return Promise.reject(err);
})

class App extends Component {
  componentDidMount(){
    library.add(fab, faCheckSquare, faCoffee, faCaretDown, faCaretUp, 
                faSearch, faCaretRight, faBoxOpen, faFile, faFileImage, faFilePdf);
    if(sessionStorage.jwtToken) {
      setAuthToken(sessionStorage.jwtToken);
      const decoded = jwtDecode(sessionStorage.jwtToken);;
      store.dispatch(setCurrentUser(decoded));
      const profileObj = sessionStorage.getItem('profile');
      var profile = {};
      if(profileObj != null){
        profile = JSON.parse(profileObj);
        store.dispatch(setUserProfile(profile));
      }
    }
    if(sessionStorage.screenLock != null){
        store.dispatch(setScreenLock(sessionStorage.screenLock));
        store.dispatch(setBlurScreen(sessionStorage.screenLock))
    }
  }

  render() {
    const queryClient = new QueryClient();
    return (
        <Suspense fallback="loading">
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    refreshCacheAndReload();
                }
                return (
                    <div>
                      {/* <ErrorBoundary> */}
                        <QueryClientProvider client={queryClient}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Provider store = { store }>
                                <PersistGate loading={null} persistor={persistor}>
                                    <ThemeProvider theme={ theme }>
                                        <Layout>
                                            <DynamicRouter />
                                        </Layout>
                                    </ThemeProvider>
                                </PersistGate>
                            </Provider>
                        </LocalizationProvider>
                        </QueryClientProvider>
                      {/* </ErrorBoundary> */}
                    </div>
                );
                }}
            </CacheBuster>
        </Suspense>
    );
  }
}

export default App;