import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import TextField from '../../UI/TextField/TextField';
import Button from '@mui/material/Button';
import AutoCompleteField from '../../UI/AutoCompleteField/AutoCompleteField';
import isEmpty from '../../../is-empty';

class ScreenDef extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            name: '',
            path: '',
            object: '',
            objSel: {},
            objects: [],
            category: '',
            catSel: {},
            categories: [],
            group: '',
            groupSel: {},
            groups: [],
            enableSave: true
        }
    }

    componentDidMount = () => {
        if(!isEmpty(this.props.settingsData)) {
            this.loadPropRecord();
        } 
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.settingsData !== this.props.settingsData) {
            this.loadPropRecord();
        }
    }

    loadPropRecord = () => {
        const settingsData = this.props.settingsData;
        const objSel = settingsData.objects.find(x => x.value === settingsData.object);
        const catSel = settingsData.categories.find(x => x.value === settingsData.category);
        const groupSel = settingsData.groups.find(x => x.value === settingsData.group);
        this.setState({
            _id: settingsData._id,
            name: settingsData.name, 
            path: settingsData.path,
            object: settingsData.object,
            objSel: objSel, 
            objects: settingsData.objects,
            category: settingsData.category,
            catSel: catSel, 
            categories: settingsData.categories,
            group: settingsData.group,
            groupSel: groupSel, 
            groups: settingsData.groups
        });
    }

    changeAuto = (name, data) => (e, newValue) => {
        let state = this.state;
        state[name] = newValue;
        state[data] = newValue != null ? newValue.value : null;
        this.setState(state);
    };

    changeValue = () => e => {
        const name = e.target.name;
        const value = e.target.value;
        let state = this.state;
        state[name] = value;
        this.setState(state);
    }

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const data = {
            _id: this.state._id,
            name: this.state.name,
            object: this.state.object,
            category: this.state.category,
            group: this.state.group,
            path: this.state.path
        }
        try {
            const result = await axios.post('/int/screendefinition', data);
            this.setState({ enableSave: true}, () => {
                this.props.callBack();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});    
            });            
        }
    };

    render() {
        const { t, toggleDialog } = this.props;
        let { name, path, objSel, objects, catSel, categories, groupSel, groups } = this.state;
        return (
            <Grid container spacing={2}>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={10}>
                        <TextField value={name != null && name !== '' ? name : ''} onChange={this.changeValue()} name="name"
                        size="medium" fullWidth={true} label="Name"/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField value={path != null && path !== '' ? path : ''} onChange={this.changeValue()} name="path"
                        size="medium" fullWidth={true} label="Path"/>
                    </Grid>
                    <Grid item xs={6}>
                        <AutoCompleteField
                            value={objSel != null && objSel !== '' ? objSel : ''}
                            options={objects != null && objects !== '' ? objects : []}
                            onChange={this.changeAuto('objSel', 'object')}
                            label="Object"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AutoCompleteField
                            value={catSel != null && catSel !== '' ? catSel : ''}
                            options={categories != null && categories !== '' ? categories : ''}
                            onChange={this.changeAuto('catSel', 'category')}
                            label="Category"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AutoCompleteField
                            value={groupSel != null && groupSel !== '' ? groupSel : ''}
                            options={groups != null && groups !== '' ? groups : ''}
                            onChange={this.changeAuto('groupSel', 'group')}
                            label="Group"
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} sx={{ justifyContent: 'flex-end' }} >
                    <Grid item>
                        <Button onClick={toggleDialog()} variant='text' color='secondary' key='screen-settings-close-btn'>Close</Button>
                        <Button onClick={this.save} 
                                variant='text' color='primary' 
                                disabled={!this.state.enableSave} key='screen-settings-submit-btn'>{t('save')}</Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    
});

export default withTranslation() (connect(mapStateToProps)(ScreenDef));