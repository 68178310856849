import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class EmailSentList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            orderBy: 'number',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            security: []
        };
    }

    async componentDidMount(){
        this.setState({security: this.props.permission});
        this.loadRecords();
    }

    loadRecords = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            size: this.state.listSize,
            offset: this.state.offset,
            sort: JSON.stringify(sort)
        };
        const result = await axios.get('/api/emailsents', {params: data});
        this.setState({
            list: result.data.data,
            totalCount: result.data.totalCount
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('emails'), screen: this.props.auth.screenDefs.EmailSentList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }
    
    handleRowsPerPage = e => {
          this.setState({listSize: parseInt(e.target.value)}, () => {
              this.loadRecords();
          });
    };
    
    handleChangePage = (event, newPage) => {
          this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
              this.loadRecords();
          });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar/>
                <Panel>
                    <TableContainer component={Paper}>
                        <Table aria-label="SentEmails">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                            active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('status')}
                                            active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('template')}
                                            active={this.state.orderBy === 'template'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('template')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('user')}
                                            active={this.state.orderBy === 'user'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('user')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('tenant')}
                                            active={this.state.orderBy === 'tenant'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('tenant')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/emailsent/' + row._id}>{row.number}</Link></TableCell>
                                        <TableCell>{row.status.label}</TableCell>
                                        <TableCell>{row.template.name}</TableCell>
                                        <TableCell>{row.user != null ? row.user.firstName + ' ' + row.user.lastName : ''}</TableCell>
                                        <TableCell>{row.tenant != null ? row.tenant.name : ''}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});
  
export default withTranslation() (connect(mapStateToProps)(EmailSentList));