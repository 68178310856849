import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setNotificationCount } from '../../actions/notification';
import PropTypes from 'prop-types';
import Link from '../UI/Link/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import DateSelect from '../UI/DateSelect/DateSelect'
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ModalDialog from '../UI/Dialog/ModalDialog';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';

class NotificationDialog extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            status: '',
            statuses: [],
            date: '',
            activity: '',
            activities: [],
            tenant: this.props.auth.user.tenant,
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            statusSel: null,
            actSel: null
        };
        this.dateChange = this.dateChange.bind(this);
    }

    async componentDidMount(){
        const values = await axios.get('/api/notificationlistvalues');
        this.setState({
            statuses: values.data.statuses,
            activities: values.data.activities
        });
        this.loadRecords();
    }

    async loadRecords(){
        const t = this.props.t;
        var sort = {[this.state.orderBy]: this.state.ordDir};
        var data = {
            size: this.state.listSize,
            offset: this.state.offset,
            date: this.state.date,
            status: this.state.status,
            activity: this.state.activity,
            sort: JSON.stringify(sort)
        }
        axios.get('/api/notifications', {params: data})
        .then(list => {
            this.setState({
                list: list.data.data,
                totalCount: list.data.totalCount
            });
        })
        .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        });
        axios.get('/api/notificationscount')
        .then(result => {
            this.props.setNotificationCount({count: result.data.totalCount != null && result.data.totalCount !== '' ? result.data.totalCount : 0,
                                             lastFive: result.data.lastFive != null && result.data.lastFive !== '' ? result.data.lastFive : []});
        })
        .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        });
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    dateChange(e){
        this.setState({date: e}, () => this.loadRecords());
    }

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    clickMessage = (i, link) => {
        let record = this.state.list[i];
        if(record.status._id !== this.props.auth.constants.notificationStatuses.read) {
            let status = record.status._id === this.props.auth.constants.notificationStatuses.new || 
                         record.status._id === this.props.auth.constants.notificationStatuses.unread ?
                         this.props.auth.constants.notificationStatuses.read : this.props.auth.constants.notificationStatuses.unread;
            axios.post('/api/notificationstatus/' + record._id, {status: status})
            .then(result => {
                this.props.history.push(link);
            })
            .catch(err => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            })
        }else{
            this.props.history.push(link);
        }
        this.props.toggleDialog();
    }

    changeStatus(i){
        var record = this.state.list[i];
        var status = record.status._id === this.props.auth.constants.notificationStatuses.new || 
            record.status._id === this.props.auth.constants.notificationStatuses.unread ?
            this.props.auth.constants.notificationStatuses.read : this.props.auth.constants.notificationStatuses.unread;
        axios.post('/api/notificationstatus/' + record._id, {status: status})
        .then(result => {
            this.loadRecords();
        })
        .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        })
    }

    render(){
        const { open, toggleDialog, t } = this.props;
        return (
            <ModalDialog title={t('notifications')} toggleDialog={() => toggleDialog()} 
                     dialogStatus={open} fullWidth maxWidth={'xl'} fScreen={'md'}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={3} lg={12}>
                                <DateSelect
                                    onChange={this.dateChange}
                                    value={this.state.date}
                                    label={t('date')}
                                    format={this.props.auth.user.dateFormat}
                                />
                            </Grid>
                            <Grid item xs={4} lg={12}>
                                <AutoCompleteField
                                    value={this.state.statusSel}
                                    options={this.state.statuses}
                                    onChange={this.changeAuto('statusSel', 'status')}
                                    label={t('status')}
                                />
                            </Grid>
                            <Grid item xs={4} lg={12}>
                                <AutoCompleteField
                                    value={this.state.actSel}
                                    options={this.state.activities}
                                    onChange={this.changeAuto('actSel', 'activity')}
                                    label={t('activity')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={3} xs={12} lg={9}>
                        <TableContainer component={Paper}>
                            <Table aria-label="Notifications">
                                <TableHead>
                                    <TableRow key="tableHeader">
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('message')}
                                            active={this.state.orderBy === 'message'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('message')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('date')}
                                            active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('status')}
                                            active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('relatedObject')}
                                            active={this.state.orderBy === 'relatedObject'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('activity')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.list.map((row, i) =>
                                        <TableRow key={row._id}>
                                            <TableCell>
                                                <Link onClick={() => this.clickMessage(i, row.link)} 
                                                    sx={{fontStyle: (row.status._id === this.props.auth.constants.notificationStatuses.unread ? 'italic' : null),
                                                         fontWeight: 'bold',
                                                         cursor: 'pointer' }}>
                                                    {row.message}
                                                </Link>
                                            </TableCell>
                                            <TableCell><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                            <TableCell>{row.status != null ? row.status.label : ''}</TableCell>
                                            <TableCell>{row.relatedObject != null ? row.relatedObject.text: ''}</TableCell>
                                            <TableCell>
                                                {row.status != null &&
                                                    <Button variant="outlined" color="primary" onClick={() => this.changeStatus(i)}>
                                                        {row.status._id === this.props.auth.constants.notificationStatuses.new || 
                                                            row.status._id === this.props.auth.constants.notificationStatuses.unread ? 
                                                            t('markRead') : t('markUnread')}
                                                    </Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow key="tableFooter">
                                        <TablePagination count={this.state.totalCount}
                                        rowsPerPage={this.state.listSize}
                                        onPageChange={this.handleChangePage}
                                        page = {this.state.page}
                                        rowsPerPageOptions={common.rowOptions}
                                        onRowsPerPageChange={this.handleRowsPerPage}/>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </ModalDialog>
        )
    }
}

NotificationDialog.propTypes = {
    setNotificationCount: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    notification: state.notifications,
    profile: state.profile
});

export default withTranslation() (connect(mapStateToProps, {setNotificationCount})(withRouter(NotificationDialog)));