import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
// import DocumentList from './DocumentList';
import isEmpty from '../../is-empty';
import base64 from 'base-64';
import TextField from '../UI/TextField/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { withTranslation } from 'react-i18next';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import ModalDialog from '../UI/Dialog/ModalDialog';
import Avatar from '../UI/Avatar/Avatar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import SelectField from '../UI/SelectField/SelectField';

const Comment = ({ avatar, key, name, date, text, format, onReply, reply, replyTo, taggedUsers, t }) => {
    return (
      <Paper elevation={3} sx={{ display: 'flex', p: 2, mb: 2, ml: reply ? 4 : 0 }}>
        <Avatar user={avatar} sx={{ mr: 2 }} />
        <Box sx={{ width: '100%', display: 'block' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', display: 'inline' }}>{name}</Typography>
            {replyTo && <Typography variant="body2" sx={{ fontStyle: 'italic', display: 'inline', ml: 2 }}>{t('replyingTo')} {replyTo}</Typography>}
            <Typography variant="body2" sx={{ ml: 2, display: 'inline' }} color="textSecondary">
                <DateDisplay format={format} value={date}/>
            </Typography>
            {taggedUsers?.length > 0 &&
                <Box sx={{ display: 'block', mb: 2 }}>
                    <Typography variant='subtitle2' sx={{ display: 'inline', mr: 1 }} color="textSecondary">
                        {t('taggedUsers')}: {taggedUsers.map(user => user.firstName + ' ' + user.lastName).join(', ')}
                    </Typography>
                </Box>
            }
            <Typography sx={{ mb: 2 }} variant="body1">{text}</Typography>
            <Link onClick={() => onReply(key)} 
                color='primary' 
                variant='button'
                sx={{ 
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    '&:hover': {
                        textDecoration: 'none',
                        color: 'secondary.main',
                        textTransform: 'uppercase'
                    }
                }}>Reply</Link>
        </Box>     
      </Paper>
    );
};

const CommentForm = ({ buttonLabel, label, addComment, users, t }) => {
    const [comment, setComment] = useState('');
    const [errors, setErrors] = useState({});
    const [saving, setSaving] = useState(false);
    const [tagged, setTagged] = useState({sels: [], ids: [], options: users});
    
    useEffect(() => {
        setTagged(prevTagged => ({ ...prevTagged, options: users}));
    }, [users]);

    const validate = () => {
        let error = {};
        if(comment == null || comment === '')
            error.comment = t('required');
        if(!isEmpty(error)) {
            setErrors(error);
            return false;
        }else{
            return true;
        }
    }

    const handleSubmit = () => {
        if(saving) {
            return;
        }else{
            if(!validate()) return;
            setSaving(true);
            let result = addComment(comment, tagged.ids);
            if(result){
                setComment('');
                setTagged({sels: [], ids: [], options: users});
            }
            setSaving(false);
        }
    }

    const changeMulti = () => (e, newValue) => {
        let ids = tagged.ids;
        let sels = tagged.sels;
        let options = tagged.options;
        let val = e.target.value;
        let value = val.pop();
        if(value != null){
            if(value === 'all') {
                if(users.length !== tagged.sels.length){
                    ids = [];
                    sels = [];
                    options.forEach(option => {
                        ids.push(option.value);
                        sels.push(option);
                    });
                }else {
                    ids = [];
                    sels = [];
                }
            }else {
                if(val.length === 0) {
                    let sel = options.find(option => option.value === value);
                    ids.push(value);
                    sels.push(sel);
                }else {
                    if(!val.some(x => x.value === value)) {
                        let sel = options.find(option => option.value === value);
                        ids.push(value);
                        sels.push(sel);
                    }else {
                        let index = ids.indexOf(value);
                        ids.splice(index, 1);
                        sels.splice(index, 1);
                    }
                }
            }
        }
        setTagged({ sels, ids, options });
    }

    const deleteMulti = () => (id) => {
        let ids = tagged.ids;
        let sels = tagged.sels;
        let options = tagged.options;
        let index = ids.indexOf(id);
        ids.splice(index, 1);
        sels.splice(index, 1);
        setTagged({sels: sels, ids: ids, options: options});
    }

  
    return (
      <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            label={label}
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            sx={{ mb: 2 }}
          />
            <SelectField
                selectAll
                value={tagged.sels}
                options={tagged.options}
                label={tagged.sels.length > 0 ? t('taggedUsers') : t('tagUsers')}
                selectAllLabel={t('selectAll')}
                error={errors.users != null ? true : false}
                helperText={errors.users}
                onChange={changeMulti()}
                onDelete={deleteMulti()}
                data-cy='taggedUsers'
                disabled={users?.length === 0 ? true : false}
            />
          <Button type="submit" variant="contained" color="primary" disabled={comment?.length === 0}>
            {buttonLabel}
          </Button>
        </Box>
      </Paper>
    );
};

class Comments extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            commentId: '',
            documents: [],
            users: [],
            offset: 0,
            pageCount: 0,
            listSize: 10,
            parentId: '',
            secondaryId: ''
        }
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleReplyFiles = this.handleReplyFiles.bind(this);
        // this.addComment = this.addComment.bind(this);
    }

    componentDidMount = async () => {
        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.user], this.props.auth.constants, null, this.props.auth.user.tenant);
        let allUsers = cacheValues.users;
        this.setState({users: allUsers}, () => this.loadComments());        
    }

    loadComments = async () => {
        let params = {
            offset: this.state.offset,
            size: this.state.listSize
        }
        if(this.props.entityId != null && this.props.entityId !== ''){
            let list = await axios.get('/api/comments/' + this.props.entityId, {params: params});
            this.setState({
                list: list.data.data
            });
        }
    }

    addComment = async (comment, users) => {
        const t = this.props.t;
        let data = {
            _id: this.state.commentId,
            parentId: this.state.parentId || null,
            secondaryId: this.state.secondaryId || null,
            comment: comment,
            object: this.props.object,
            objectId: this.props.entityId,
            documents: this.state.documents,
            taggedUsers: users,
            link: window.location.hash,
            tab: this.props.tab != null ? this.props.tab : null,
            screenDef: this.props.auth.screenDefs[this.props.screenDef]
        };
        try{
            const result = await axios.post('/api/comment', data);
            this.setState({ enableSave: {...this.state.enableSave, add: true }});            
            this.loadComments();
            this.clearComment();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        }catch(err){
            this.setState({ enableSave: {...this.state.enableSave, add: true }});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});        
        }
    }

    clearComment(){
        this.setState({
            commentId: '',
            documents: [],
            replyTo: '',
            parentId: '',
        })
    }

    handleFileUpload(file){
        var files = file.target.files;
        let reader = new FileReader();
        reader.readAsBinaryString(files[0]);
        reader.onload = () => {
            let vals = {
                file: base64.encode(reader.result),
                fileName: files[0].name,
                type: files[0].type
            }
          axios.post('/api/documents/add', vals)
          .then(data => {
            var docs = this.state.documents;
            docs.push({
                _id: data.data.id,
                name: files[0].name,
                type: files[0].type
            });
            this.setState({
                documents: docs
            })
          })
          .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
          });
        }
    }

    handleReplyFiles(file){
        var files = file.target.files;
        let reader = new FileReader();
        reader.readAsBinaryString(files[0]);
        reader.onload = () => {
            let vals = {
                file: base64.encode(reader.result),
                fileName: files[0].name,
                type: files[0].type
            };
          axios.post('/api/documents/add', vals)
          .then(data => {
            var docs = this.state.replyDocuments;
            docs.push({
                _id: data.data.id,
                name: files[0].name,
                type: files[0].type
            });
            this.setState({
                replyDocuments: docs
            })
          })
          .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
          });
        }
    }

    viewDocument(id){
        for(var i = 0; i < this.state.documents.length; i++){
            if(this.state.documents[i]._id === id){
                window.open("data:application/octet-stream;base64," + this.state.documents[i].document);
                break;
            }
        }
    }

    viewImage(id){
        var imageData = '';
        for(var i = 0; i < this.state.documents.length; i++){
            if(this.state.documents[i]._id === id){
                imageData = this.state.documents[i].document;
                break;
            }
        }
        this.setState({
            showImage: true,
            imageData: imageData
        })
    }

    closeImage(){
        this.setState({
            showImage: false,
            imageData: ''
        })
    }

    commentReplyClick(primaryId, secondaryId){
        let replyComment = {};
        const index = this.state.list.findIndex(comment => comment._id === primaryId);
        if(index > -1){
            replyComment = this.state.list[index];
        }else{
            this.state.list.forEach(comment => {
                const replyIndex = comment.replies.findIndex(reply => reply._id === primaryId);
                if(replyIndex > -1){
                    replyComment = comment.replies[replyIndex];
                }
            })
        }
        this.setState({
            replyTo: replyComment.createdBy.firstName + ' ' + replyComment.createdBy.lastName,
            parentId: primaryId,
            secondaryId: secondaryId,
            replyComment: replyComment
        })
    }

    // saveReply = () => {
    //     if(!this.state.enableSave.reply)
    //         return;
    //     this.setState({ enableSave: { ...this.state.enableSave, reply: false}}, () => this._saveReply());
    // }

    // _saveReply = async () => {
    //     var data = {
    //         comment: this.state.replytext,
    //         documents: this.state.replyDocuments,
    //         objectId: this.props.entityId,
    //         object: this.props.object,
    //         parentId: this.state.parentId,
    //         taggedUsers: this.state.replyTaggedUsers,
    //         screenDef: this.props.auth.screenDefs[this.props.screenDef]
    //     }
    //     try{
    //         const result = await axios.post('/api/comment', data);
    //         this.setState({ enableSave: {...this.state.enableSave, reply: true }}, () => {
    //             this.loadComments();
    //             this.clearReply();
    //             this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
    //         });
    //     }catch(err){
    //         this.setState({ enableSave: {...this.state.enableSave, reply: true }}, () => {
    //             this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
    //         });
            
    //     }
    // }

    render(){
        const t = this.props.t;
        const { openComments } = this.props;
        return (
            <ModalDialog buttonStack={[]} title={t('comments')} toggleDialog={() => this.props.dispatch({ type: 'TOGGLE_COMMENTS' })}
                            dialogStatus={openComments} fullWidth maxWidth='md'>
                <Grid container spacing={1} justifyContent='flex-end'>
                    <Grid item xs={12} sx={{ maxHeight: '450px', overflowY: 'auto' }}>
                        {this.state.list?.map((comment, index) => (
                            <>
                                <Comment
                                    key={index}
                                    avatar={comment.createdBy}
                                    name={comment.createdBy.firstName + ' ' + comment.createdBy.lastName}
                                    date={comment.createdOn}
                                    label={t('addComment')}
                                    text={comment.comment}
                                    format={this.props.auth.user.dateFormat + ' hh:mm a'}
                                    onReply={this.commentReplyClick.bind(this, comment._id)}
                                    taggedUsers={comment.taggedUsers}
                                    t={t}
                                />
                                {comment.replies?.map((reply, i) => (
                                    <Comment
                                        key={i}
                                        avatar={reply.createdBy}
                                        name={reply.createdBy.firstName + ' ' + reply.createdBy.lastName}
                                        replyTo={reply.parentId?.createdBy?.firstName + ' ' + reply.parentId?.createdBy?.lastName}
                                        date={reply.createdOn}
                                        text={reply.comment}
                                        format={this.props.auth.user.dateFormat + ' hh:mm a'}
                                        onReply={this.commentReplyClick.bind(this, reply._id, comment._id)}
                                        taggedUsers={reply.taggedUsers}
                                        reply={true}
                                        t={t}
                                    />   
                                ))}
                            </>                            
                        ))}
                    </Grid>
                    <Grid item xs={12} sx={{ border: '2px solid #e0e0e0', padding: 1.5, backgroundColor: '#f5f5f5' }}>
                        <CommentForm 
                            label={this.state.replyTo != null && this.state.replyTo !== '' ? t('replyTo') + ' ' + this.state.replyTo : t('addComment')}
                            buttonLabel={this.state.replyTo != null && this.state.replyTo !== '' ? t('addReply') : t('addComment')}
                            addComment={this.addComment} 
                            users={this.state.users}
                            t={t}
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item sm={9}>
                                <TextField value={this.state.comment} onChange={this.changeValue} name="comment" multiline={true} rows="3"
                                variant="outlined" size="medium" fullWidth={true} error={errors.comment != null ? true : false} helperText={errors.comment}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <input type="file" onChange={this.handleFileUpload}
                                    multiple={false}/>
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item sm={9}>
                                <AutoComplete
                                    multiple
                                    value={this.state.selectedUsers}
                                    options={this.state.users}
                                    getOptionLabel={(option) => option.label}
                                    onChange={this.changeMulti('selectedUsers', 'taggedUsers')}
                                    renderInput={(params) => <TextField {...params} helperText={errors.users} label={t('tagUsers')}
                                        error={errors.users != null ? true : false} 
                                    />}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Button variant="contained" disabled={!this.state.enableSave.add} color="secondary" size="small" onClick={() => this.addComment()}>{t('saveComment')}</Button>
                            </Grid>
                        </Grid>
                        <br/>
                        <br/>
                        <Aux>
                            {this.state.list != null && this.state.list.length > 0 && this.state.list.map((comment) =>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <p><strong><DateDisplay format={this.props.auth.user.dateFormat} value={comment.createdOn}/>&nbsp;&nbsp;
                                        {comment.createdBy.firstName + ' ' + comment.createdBy.lastName}</strong></p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p>{comment.comment}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="outlined" color="primary" size="small"
                                                    onClick={() => this.commentReplyClick(comment._id)}>{t('reply')}</Button>
                                    </Grid>
                                    {comment.taggedUsers != null && comment.taggedUsers.length > 0 &&
                                        <Grid item xs={12}>
                                            <p>
                                                <strong>{t('taggedUsers')}: </strong>
                                                {comment.taggedUsers.map((user) =>
                                                    <span key={user._id}>{user.firstName + ' ' + user.lastName + ', '}</span>
                                                )}
                                            </p>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <DocumentList documents={comment.documents != null ? comment.documents : []}/>
                                    </Grid>
                                    {comment.replies.map((reply, i) =>
                                    <Grid item container spacing={3} xs={12}>
                                        <Grid item xs={12}>
                                            <strong><DateDisplay format={this.props.auth.user.dateFormat} value={reply.createdOn}/>&nbsp;&nbsp;
                                            {reply.createdBy.firstName + ' ' + reply.createdBy.lastName}</strong>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p>{reply.comment}</p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="outlined" color="primary" size="small"
                                                        onClick={() => this.commentReplyClick(comment._id)}>{t('reply')}</Button>
                                        </Grid>
                                        <Grid item container spacing={3}>
                                            <Grid item xs={12}>
                                                {reply.taggedUsers != null && reply.taggedUsers.length > 0 &&
                                                    <p>
                                                        <strong>{t('taggedUsers')}: </strong>
                                                        {reply.taggedUsers.map((replyUser) =>
                                                            <span key={replyUser._id}>{replyUser.firstName + ' ' + replyUser.lastName + ', '}</span>
                                                        )}
                                                    </p>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DocumentList documents={reply.documents != null ? reply.documents : []}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    )}
                                </Grid>
                            )}

                        </Aux>
                    </Grid> */}
                </Grid>
            </ModalDialog>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    screenDef: state.navMenu.screenDef,
    openComments: state.optionMenu.openComments
});

export default withTranslation() (connect(mapStateToProps)(Comments));
