import React from 'react';
import ReportTitle from '../../UI/Typography/ReportTitle';
import ReportSectionTitle from '../../UI/Typography/ReportSectionTitle';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';

import CAPA from './CAPA';

const boxStyle = {
    margin: '8px',
    width: '100%'
}

const thStyle = {
    borderBottom: '2px solid rgb(224, 224, 224)', 
    fontSize: '9pt', 
    textAlign: 'center'
}

const tdStyle = { 
    border: '0.5px solid rgb(224, 224, 224)', 
    fontSize: '8pt', 
    padding: '5px',
}

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse'
}

export default function deviationTemplate(props) {
    const { dateFormat, record, sequence, t } = props;
    return (
        <div style={{ width: '8.5in', pageBreakBefore: 'always' }}>
            <div style={boxStyle}>
                <ReportTitle 
                    title={
                        (sequence != null && sequence !== '' ? 
                            sequence + '. ' + t('deviationRecord') + ': ' + 
                            (record.title != null && record.title !== '' ? 
                                record.title : null 
                            ) : t('deviationRecord') + ': ' +
                            (record.title != null && record.title !== '' ? 
                                record.title : null 
                            )
                        )
                    }
                />
            </div>
            <div style={{ ...boxStyle, display: 'block' }}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '100px' }}>{t('number')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('status')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('category')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('department')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('occurenceDate')}</th>
                            <th style={{ ...thStyle, width: '150px' }}>{t('location')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.number != null && record.number !== '' ? record.number : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.status != null && record.status !== '' ? record.status : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.category != null && record.category !== '' ? record.category : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.department != null && record.department !== '' ? record.department : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.occurrenceDate != null ?
                                    <DateDisplay value={record.occurrenceDate} format={dateFormat}/> : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.location != null && record.location !== '' ? record.location : t('na')}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ ...boxStyle, display: 'block' }}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '100px'}}>{t('dateInitiated')}</th>
                            <th style={{ ...thStyle, width: '100px'}}>{t('initiatedBy')}</th>
                            <th style={{ ...thStyle, width: '100px'}}>{t('type')}</th>
                            <th style={{ ...thStyle, width: '100px'}}>{t('relatedTo')}</th>
                            <th style={{ ...thStyle, width: '100px'}}>{t('affected')}</th>
                            <th style={{ ...thStyle, width: '100px'}}>{t('activity')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.initiatedOn != null ?
                                    <DateDisplay value={record.initiatedOn} format={dateFormat + ' hh:mm a'}/> : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.initiatedBy != null && record.initiatedBy !== '' ?
                                    record.initiatedBy : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.type != null && record.type !== '' ?
                                    record.type : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.entityType != null && record.entityType.text != null && record.entityType.text !== '' ?
                                    record.entityType.text : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.recordObjs != null && record.recordObjs.length > 0 ?
                                    record.recordObjs.map((recordObj, i) => {
                                        if(recordObj != null){
                                            if(i === record.recordObjs.length - 1)
                                                return recordObj.number
                                            else
                                                return recordObj.number + ', '
                                        }else {
                                            return t('na')
                                        }                                     
                                    }) : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.childRecordObj != null && record.childRecordObj !== '' && 
                                 record.childRecordObj.number != null && record.childRecordObj.number !== '' ?
                                    record.childRecordObj.number : t('na')
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ ...boxStyle, display: 'block', padding: '8px' }}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('description')} />
                </div>
                {record.description != null && record.description !== '' ?
                    <div style={{ fontSize: '9pt', padding: '5px' }}>
                        {record.description}
                    </div> : ''                             
                }
            </div>
            <div style={{ ...boxStyle, display: 'block' }}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('impactAssessment')} />
                </div>
                <div style={{ width: '100%', display: 'block' }}>
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={{ ...thStyle, width: '100px'}}>{t('impact')}</th>
                                <th style={{ ...thStyle, width: '100px'}}>{t('performedBy')}</th>
                                <th style={{ ...thStyle, width: '100px'}}>{t('assessmentDate')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ ...tdStyle, textAlign: 'center' }}>
                                    {record.impact != null && record.impact !== '' ?
                                        record.impact : t('na')
                                    }
                                </td>
                                <td style={{ ...tdStyle, textAlign: 'center' }}>
                                    {record.impactAssessmentUser != null && record.impactAssessmentUser !== '' ?
                                        record.impactAssessmentUser : t('na')
                                    }
                                </td>
                                <td style={{ ...tdStyle, textAlign: 'center' }}>
                                    {record.impactAssessmentDate != null ?
                                        <DateDisplay value={record.impactAssessmentDate} format={dateFormat}/> : t('na')
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ ...boxStyle, display: 'block', padding: '8px' }}>
                    <div style={{ width: '100%', display: 'block' }}>
                        <ReportSectionTitle title={t('explanation')} />
                    </div>
                    {record.explanation != null && record.explanation !== '' ?
                        <div style={{ fontSize: '9pt', padding: '5px' }}>
                            {record.explanation}
                        </div> : ''                             
                    }
                </div>
                <div style={{ ...boxStyle, display: 'block', padding: '8px' }}>
                    <div style={{ width: '100%', display: 'block' }}>
                        <ReportSectionTitle title={t('rootCause')} />
                    </div>
                    {record.rootCause != null && record.rootCause !== '' ?
                        <div style={{ fontSize: '9pt', padding: '5px' }}>
                            {record.rootCause}
                        </div> : ''                             
                    }
                </div>
                <div style={{ width: '75%', display: 'inline-block' }} />
                <div style={{ width: '25%', display: 'inline-block',  }}>
                    <div style={{ width: '100%', fontSize: '9pt', fontWeight: 'bold' }}>
                        {t('approvedBy')}
                    </div>
                    <div style={{ width: '100%' }}>
                        {record.rootCauseApprover != null && <img src={record.rootCauseApprover.signature} alt={t('noSignature')} height='60px' />}
                        <hr style={{ width: '80%', marginLeft: 0 }} />
                    </div>
                    <div style={{ width: '100%', fontSize: '9pt' }}>
                        {record.rootCauseApprover != null && record.rootCauseApprover.name != null && record.rootCauseApprover.name !== '' ? 
                            record.rootCauseApprover.name : ''
                        },&nbsp;
                        {record.rootCauseApprovalDate != null && 
                            <DateDisplay format={dateFormat} value={record.rootCauseApprovalDate}/>
                        }
                    </div>
                </div>
            </div>
            <div style={{ ...boxStyle, display: 'block', pageBreakBefore: 'always' }}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('qaAssessment')} />
                </div>
                <div style={{ ...boxStyle, display: 'block', padding: '8px', width: '50%' }}>
                    <div style={{ width: '100%', display: 'block' }}>
                        <ReportSectionTitle title={t('impactAgree')} />
                    </div>
                    {record.qaAgreeImpact != null && record.qaAgreeImpact !== '' ?
                        <div style={{ fontSize: '9pt', padding: '5px' }}>
                            {record.qaAgreeImpact === true ? t('yes') : t('no')}
                        </div> : ''                             
                    }
                    {record.qaAgreeImpact === false ?
                        <div style={{ ...boxStyle, display: 'block', padding: '8px', width: '50%' }}>
                            <div style={{ width: '100%', display: 'block' }}>
                                <ReportSectionTitle title={t('whyNot')} />
                            </div>
                            {record.qaDisagreeImpactText != null && record.qaDisagreeImpactText !== '' ?
                                <div style={{ fontSize: '9pt', padding: '5px' }}>
                                    {record.qaDisagreeImpactText}
                                </div> : t('na')
                            }
                        </div> : ''
                    }                    
                </div>
                <div style={{ ...boxStyle, display: 'block', padding: '8px', width: '50%' }}>
                    <div style={{ width: '100%', display: 'block' }}>
                        <ReportSectionTitle title={t('causeAgree')} />
                    </div>
                    {record.qaAgreeCause != null && record.qaAgreeCause !== '' ?
                        <div style={{ fontSize: '9pt', padding: '5px' }}>
                            {record.qaAgreeCause === true ? t('yes') : t('no')}
                        </div> : ''                             
                    }
                    {record.qaAgreeCause === false ?
                        <div style={{ ...boxStyle, display: 'block', padding: '8px', width: '50%' }}>
                            <div style={{ width: '100%', display: 'block' }}>
                                <ReportSectionTitle title={t('whyNot')} />
                            </div>
                            {record.qaDisagreeCauseText != null && record.qaDisagreeCauseText !== '' ?
                                <div style={{ fontSize: '9pt', padding: '5px' }}>
                                    {record.qaDisagreeCauseText}
                                </div> : t('na')
                            }
                        </div> : ''
                    }                    
                </div>
                <div style={{ ...boxStyle, display: 'block', width: '100%' }}>
                    <div style={{ width: '100%', display: 'block' }}>
                        <ReportSectionTitle title={t('correctiveMeasures')} />
                    </div>
                    <div style={{ fontSize: '9pt', padding: '5px' }}>
                        <table style={tableStyle}>
                            <thead>
                                <th style={{ ...thStyle, width: '100px'}}>{t('number')}</th>
                                <th style={{ ...thStyle, width: '100px'}}>{t('title')}</th>
                                <th style={{ ...thStyle, width: '100px'}}>{t('dueDate')}</th>
                                <th style={{ ...thStyle, width: '100px'}}>{t('status')}</th>
                                <th style={{ ...thStyle, width: '100px'}}>{t('assignee')}</th>
                            </thead>
                            <tbody>
                                {record.capaActions != null && record.capaActions.length > 0 ?
                                    record.capaActions.map((capa, i) => 
                                        <tr key={'CAPAActions-' + i}>
                                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                                {capa.number != null && capa.number !== '' ? capa.number : t('na')}
                                            </td>
                                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                                {capa.title != null && capa.title !== '' ? capa.title : t('na')}
                                            </td>
                                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                                {capa.dueDate != null ?
                                                    <DateDisplay value={capa.dueDate} format={dateFormat}/> : t('na')
                                                }
                                            </td>
                                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                                {capa.status != null && capa.status !== '' ? capa.status : t('na')}
                                            </td>
                                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                                {capa.assignee != null && capa.assignee !== '' ? capa.assignee : t('na')}
                                            </td>
                                        </tr>
                                    ) : <tr><td style={{ ...tdStyle, textAlign: 'center' }} colSpan='5' >{t('noData')}</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ width: '75%', display: 'inline-block' }} />
                <div style={{ width: '25%', display: 'inline-block' }}>
                    <div style={{ width: '100%', fontSize: '9pt', fontWeight: 'bold' }}>
                        {t('assessedBy')}
                    </div>
                    <div style={{ width: '100%' }}>
                        {record.assessor != null && <img src={record.assessor.signature} alt={t('noSignature')} height='60px' />}
                        <hr style={{ width: '80%', marginLeft: 0 }} />
                    </div>
                    <div style={{ width: '100%', fontSize: '9pt' }}>
                        {record.assessor != null && record.assessor.name != null && record.assessor.name !== '' ? 
                            record.assessor.name : ''
                        },&nbsp;
                        {record.assessor != null && record.assessor.date != null &&
                            <DateDisplay format={dateFormat} value={record.assessor.date}/>
                        }
                    </div>
                </div>
            </div>
            {record.capaActions != null && record.capaActions.length > 0 &&
                record.capaActions.map((capa, i) => 
                    <CAPA key={'CAPA-' + i} record={capa} dateFormat={dateFormat} t={t} sequence={i + 1} />
                )
            }
            <div style={{ ...boxStyle, display: 'block', padding: '8px', width: '100%', pageBreakBefore: 'always' }}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('conclusion')} />
                </div>
                <div style={{ ...boxStyle, display: 'block', padding: '8px' }}>
                    <div style={{ width: '100%', display: 'block' }}>
                        <ReportSectionTitle title={t('summary')} />
                    </div>
                    {record.summary != null && record.summary !== '' ?
                        <div style={{ fontSize: '9pt', padding: '5px' }}>
                            {record.summary}
                        </div> : t('na')
                    }
                </div>
                <div style={{ ...boxStyle, display: 'block', padding: '8px' }}>
                    <div style={{ width: '100%', display: 'block' }}>
                        <ReportSectionTitle title={t('finalQaDecision')} />
                    </div>
                    {record.finalQADecision != null && record.finalQADecision !== '' ?
                        <div style={{ fontSize: '9pt', padding: '5px' }}>
                            {record.finalQADecision}
                        </div> : t('na')
                    }
                </div>
                <div style={{ ...boxStyle, display: 'block', padding: '8px' }}>
                    <div style={{ width: '100%', display: 'block' }}>
                        <ReportSectionTitle title={t('productDisposition')} />
                    </div>
                    {record.disposition != null && record.disposition.length > 0 ?
                        <div style={{ fontSize: '9pt', padding: '5px' }}>
                            {record.disposition.map((disposition, i) => {
                                if(i === record.disposition.length - 1)
                                    return disposition.label
                                else
                                    return disposition.label + '. '
                                })
                            }
                        </div> : t('na')
                    }
                </div>
                <div style={{ width: '75%', display: 'inline-block' }} />
                <div style={{ width: '25%', display: 'inline-block' }}>
                    <div style={{ width: '100%', fontSize: '9pt', fontWeight: 'bold' }}>
                        {t('completedBy')}
                    </div>
                    <div style={{ width: '100%' }}>
                        {record.signer != null && <img src={record.signer.signature} alt={t('noSignature')} height='60px' />}
                        <hr style={{ width: '80%', marginLeft: 0 }} />
                    </div>
                    <div style={{ width: '100%', fontSize: '9pt' }}>
                        {record.signer != null && record.signer.name != null && record.signer.name !== '' ? 
                            record.signer.name : ''
                        },&nbsp;
                        {record.signer != null && record.signer.date != null &&
                            <DateDisplay format={dateFormat} value={record.signer.date}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}