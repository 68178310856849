import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DocumentList from '../General/DocumentList';
import base64 from 'base-64';
import ProfileImage from './ProfileImage';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import isEmpty from '../../is-empty';
import SignatureCanvas from 'react-signature-canvas';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import UserCerifications from './UserCertifications';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import DateSelect from '../UI/DateSelect/DateSelect';
import ModalDialog from '../UI/Dialog/ModalDialog';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import SectionTitle from '../UI/Typography/SectionTitle';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons'
import listHelper from '../../helpers/listHelper';
import { db } from '../../db';

class UserProfile extends Component {
    constructor(props){
        super(props);
        this.state = {
            userid: '',
            firstName: '',
            lastName: '',
            _id: '',
            roles: [],
            email: '',
            phoneNumber: '',
            location: '',
            locationSel: '',
            processes: [],
            receiveEmails: false,
            profileImage: null,
            documents: [],
            roleOptions: [],
            processOptions: [],
            departments: [],
            departmentOptions: [],
            locations: [],
            rolesSel: [],
            processesSel: [],
            departmentsSel: [],
            language: '',
            languageSel: null,
            languages: [],
            pin: '',
            signature: null,
            hideLaunchPad: true,
            tabKey: 0,
            enableSave: true,
            certTypes: [],
            certType: '',
            certTypeSel: null,
            certExpDate: '',
            certNumber: '',
            certifications: [],
            showCert: false,
            certIndex: -1,
            tabLabels: [{name: this.props.t('profile'), disabled: false},
                        {name: this.props.t('documentCertifications'), disabled: false}]
        };
        this.changeAuto = this.changeAuto.bind(this);
        this.sigPad = {};
    }

    async componentDidMount(){
        const values = await axios.get('/api/uservalues');
        this.setState({
            roleOptions: values.data.roles,
            processOptions: values.data.processes,
            departmentOptions: values.data.departments,
            locations: values.data.locations,
            languages: values.data.languages,
            certTypes: values.data.certTypes
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('userProfile'), screen: this.props.auth.screenDefs.UserProfile}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecord();
        });        
    }

    loadRecord = async () => {
        const t = this.props.t;
        const record = await axios.get('/api/users/' + this.props.auth.user.id);
        let roleOptions = this.state.roleOptions;
        let rolesSel = [];
        let tenant = record.data.tenants.find(x => x.tenant?._id != null && x.tenant?._id === this.props.auth.user.tenant);
        tenant.roles.forEach(role => {
            let roleFull = roleOptions.find(x => x.value === role);
            rolesSel.push(roleFull);
        });
        let processesSel = [];
        let processOptions = this.state.processOptions;
        tenant.processes.forEach(process => {
            let processFull = processOptions.find(x => x.value === process);
            processesSel.push(processFull);
        });
        let departmentsSel = [];
        let departmentOptions = this.state.departmentOptions;
        tenant.department.forEach(row => {
            let val = departmentOptions.find(x => x.value === row);
            departmentsSel.push(val);
        });
        let locations = this.state.locations;
        let locationSel = locations.find(x => x.value === tenant.defaultLocation);
        let languageSel = this.state.languages.find(x => x.value === record.data.language);
        let errors = {};
        let certifications = record.data.certifications != null ? record.data.certifications : [];
        if(certifications.length > 0){
            for(let row of certifications){
                let certTypeSel = this.state.certTypes.find(x => x.value === row.type);
                row.certTypeSel = certTypeSel;
            }
        }
        if(this.props.auth.user.firstLogin) 
            errors[t('pin')] = t('required');
        
        this.setState({
            userid: record.data.userid,
            firstName: record.data.firstName,
            lastName: record.data.lastName,
            _id: record.data._id,
            roles: tenant.roles,
            email: record.data.email,
            location: tenant.defaultLocation,
            phoneNumber: record.data.phoneNumber,
            processes: tenant.processes,
            receiveEmails: record.data.receiveEmails,
            documents: record.data.documents,
            rolesSel: rolesSel,
            processesSel: processesSel,
            departments: record.data.department,
            departmentsSel: departmentsSel,
            locationSel: locationSel,
            language: record.data.language,
            languageSel: languageSel,
            profileImage: record.data.profileImage != null ? record.data.profileImage : null,
            signature: record.data.signature ? record.data.signature : null,
            hideLaunchPad: record.data.hideLaunchPad != null && record.data.hideLaunchPad !== '' ? record.data.hideLaunchPad : true,
            errors: errors,
            certifications: certifications
        }, () => {
            const crumbs = [
                { path: 'active', label: record.data.firstName + ' ' + record.data.lastName, screen: this.props.auth.screenDefs.UserProfile}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeRoles(e, newValue) {
        let roles = [];
        if(newValue !== null){
            newValue.forEach(role => {
                roles.push(role.value);
            })
        }
        this.setState({
            rolesSel: newValue,
            roles: roles
        })
    }

    changeProcesses(e, newValue) {
        let processes = [];
        if(newValue !== null){
            newValue.forEach(process =>{
                processes.push(process.value);
            })
        }
        this.setState({
            processesSel: newValue,
            processes: processes
        })
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeSelect = (name, data) => value => {
        this.setState({[name]: value, [data]: value.value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    changeReceive = e => {
        this.setState({receiveEmails: !this.state.receiveEmails});
    };

    changeLauncher = e => {
        this.setState({hideLaunchPad: !this.state.hideLaunchPad});
    };

    handleFileUpload(file){
        let files = file.target.files;
        let reader = new FileReader();
        reader.readAsBinaryString(files[0]);
        reader.onload = () => {
            let body = {
                file: base64.encode(reader.result),
                fileName: files[0].name,
                type: files[0].type,
            };
          axios.post('/api/documents/add', body)
          .then(data => {
            let docs = this.state.documents;
            docs.push({
                _id: data.data.id,
                name: files[0].name,
                type: files[0].type
            });
            this.setState({
                documents: docs
            });
          })
          .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
          });
        };
    }

    save = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._save(e);
        });
    };

    _save = async e => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid)
            return;
        let docs = [];
        this.state.documents.forEach(doc => {
            docs.push(doc._id);
        });
        let data = {
            _id: this.state._id,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            location: this.state.location,
            receiveEmails: this.state.receiveEmails,
            documents: docs,
            pin: this.state.pin != null && this.state.pin !== '' ? this.state.pin : null,
            signature: this.state.signature,
            firstLogin: this.props.auth.user.firstLogin,
            language: this.state.language,
            hideLaunchPad: this.state.hideLaunchPad,
            certifications: this.state.certifications
        };
        try {
            const result = await axios.post('/api/userprofile', data);
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err, title: t('error'), severity: 'error'}});
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.firstName == null || state.firstName === '')
            errors[t('firstName')] = t('required');
        if(state.lastName == null || state.lastName === '')
            errors[t('lastName')] = t('required');
        if(state.email == null || state.email === '')
            errors[t('email')] = t('required');
        if(state.language == null || state.language === '')
            errors[t('language')] = t('required');
        if(state.pin != null && state.pin !== ''){
            if(state.pin.length < 4 || state.pin.length > 8){
                errors[t('pin')] = t('pinLength');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    return = e => {
        this.props.history.goBack();
    };

    refreshData = async () => {
        await this.loadRecord();
    };

    clearSig = () => {
        if(this.state.signature != null && this.state.signature !== ''){
            this.setState({signature: ''});
        } else {
            this.sigPad.clear();
        }
    };

    acceptSig = (e) => {
        let signature = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
        this.setState({signature: signature});
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    }

    toggleCerts = () => {
        this.setState({
            showCert: !this.state.showCert,
            certIndex: -1,
            certType: '',
            certTypeSel: null,
            certExpDate: '',
            certNumber: ''
        });
    }

    editCert = (i) => (e) => {
        let certs = this.state.certifications;
        let cert = certs[i];
        certs[i] = cert;
        this.setState({
            certIndex: i,
            certType: cert.type,
            certTypeSel: cert.certTypeSel,
            certExpDate: cert.expirationDate,
            certNumber: cert.number,
            showCert: true
        });
    }

    saveCert = (e) => {
        let errors = {};
        const t = this.props.t;
        if(this.state.certType == null || this.state.certType === '')
            errors[t('certType')] = this.props.t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let certs = this.state.certifications;
        if(this.state.certIndex > -1){
            let cert = certs[this.state.certIndex];
            cert.type = this.state.certType;
            cert.certTypeSel = this.state.certTypeSel;
            cert.expirationDate = this.state.certExpDate;
            cert.number = this.state.certNumber;
        } else {
            certs.push({
                type: this.state.certType,
                certTypeSel: this.state.certTypeSel,
                expirationDate: this.state.certExpDate,
                number: this.state.certNumber
            });
        }
        this.setState({
            certifications: certs,
            showCert: false,
            certType: '',
            certTypeSel: null,
            certExpDate: '',
            certNumber: '',
            certIndex: -1
        })
    }

    clearListSettings = async () => {
        await listHelper.clearList();
        this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: this.props.t('complete'), title: this.props.t('success'), severity: 'success'}});
    }

    clearCachedData = async () => {
        db.cache.clear();
        this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: this.props.t('complete'), title: this.props.t('success'), severity: 'success'}});
    }

    render(){
        const { profileImage, tabKey, tabLabels } = this.state;
        const { errors, t } = this.props;
        return(
            <Aux>
                <BreadcrumbBar />
                <Grid container spacing={2}>
                    <EvoraTabs tabLabels={tabLabels} tabKey={tabKey} changeTab={this.changeTab} />
                    <Grid item lg={8} md={8} xl={8} xs={12} order={{ xs: 1, md: 2 }}>
                        <TabPanel value={this.state.tabKey} index={0}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={7}>
                                    <TextField value={this.state.userid}  name="userid"
                                    size="medium" fullWidth={true} disabled={true} label={t('userid')}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField value={this.state.firstName} onChange={this.changeValue} name="firstName"
                                    size="medium" fullWidth={true} disabled={false} label={t('firstName')}
                                    error={errors[t('firstName')] != null ? true : false} helperText={errors[t('firstName')]}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField value={this.state.lastName} onChange={this.changeValue} name="lastName"
                                    size="medium" fullWidth={true} disabled={false} label={t('lastName')}
                                    error={errors[t('lastName')] != null ? true : false} helperText={errors[t('lastName')]}/>
                                </Grid>
                                <Grid item xs={8} sm={7} lg={6}>
                                    <TextField value={this.state.email} onChange={this.changeValue} name="email"
                                    size="medium" fullWidth={true} disabled={false} label={t('email')}
                                    error={errors[t('email')] != null ? true : false} helperText={errors[t('email')]}/>
                                </Grid>
                                <Grid item xs={4} sm={5} lg={3}>
                                    <CheckboxField
                                        checked={this.state.receiveEmails} onChange={this.changeReceive} 
                                        label={t('receiveEmails')} name="receiveEmails" disabled={false}
                                    />
                                </Grid>
                                {this.props.auth.user.isAdmin && this.props.auth.user.isSuper &&
                                    <Grid item xs={4} sm={5} lg={3}>
                                        <CheckboxField
                                            checked={this.state.hideLaunchPad} onChange={this.changeLauncher} 
                                            label={t('hideLaunchPad')} name="hideLaunchPad" disabled={false}
                                        />
                                    </Grid>
                                }                                
                                <Grid item xs={12} sm={6} lg={3}>
                                    <InputMaskField mask="999-999-9999" name="phoneNumber" value={this.state.phoneNumber}
                                    onChange={this.changeValue} disabled={false}
                                    helperText={errors[t('phoneNumber')]}
                                    error={errors[t('phoneNumber')] != null ? true : false} 
                                    label={t('phoneNumber')}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.languageSel}
                                        options={this.state.languages}
                                        onChange={this.changeAuto('languageSel', 'language')}
                                        error={errors[t('language')] != null ? true : false}
                                        helperText={errors[t('language')]}
                                        label={t('language')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <AutoCompleteField
                                        value={this.state.locationSel}
                                        options={this.state.locations}
                                        onChange={this.changeAuto('locationSel', 'location')}
                                        error={errors[t('location')] != null ? true : false}
                                        helperText={errors[t('location')]}
                                        label={t('location')}
                                        disabled={this.state.locations.length === 1 ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <TextField value={this.state.pin} onChange={this.changeNumber} name="pin"
                                    size="medium" fullWidth={true} disabled={false} label={t('pin')} type={'password'}
                                    error={errors[t('pin')] != null ? true : false} helperText={errors[t('pin')]}/>
                                </Grid>
                            </Grid>
                            {(this.state.signature == null || this.state.signature === '') &&
                            <Aux>
                                <br/>
                                <Grid container spacing={2}>
                                    <Grid item sm={8}>
                                    <FormControl>
                                    <FormLabel>{t('signature')}</FormLabel>
                                            <Box component="div" borderRadius="borderRadius" sx={{border: "1px solid #9E9E9E", marginTop: "16px"}}>
                                                <SignatureCanvas penColor="#5BC6CC" canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                                                ref={(ref) => {this.sigPad = ref}}/>
                                            </Box> 
                                    </FormControl>
                                    </Grid>
                                </Grid>
                            </Aux>
                            }
                            {this.state.signature != null && this.state.signature !== '' &&
                                <Aux>
                                    <br/>
                                    <Grid container spacing={2}>
                                        <Grid item sm={8}>
                                            <FormControl>
                                                <FormLabel>{t('signature')}</FormLabel>
                                                <img src={this.state.signature} alt={t('noSignature')} />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Aux>
                            }      
                            <Grid container spacing={2}>
                                <Grid item sm={8}>
                                    {(this.state.signature === undefined || this.state.signature == null || this.state.signature === '') &&
                                        <Button variant="contained" size="small" color="secondary" onClick={this.acceptSig}>{t('accept')}</Button>
                                    }
                                    <Button variant="contained" size="small" color="secondary" onClick={this.clearSig}>{t('clear')}</Button>
                                </Grid>
                            </Grid>
                            <br/>
                            <SectionTitle title={t('certifications')} addTitle={t('add')} add={this.toggleCerts} security={true}/>
                            {this.state.certifications != null && this.state.certifications.length > 0 &&
                                <Aux>
                                    <Grid item xs={12}>
                                        <TableContainer sx={{ border: '2px solid rgba(224, 224, 224, 1)', borderRadius: 3}}>
                                            <Table size='small'>
                                                <TableHead key='certificationsTableHeader'>
                                                    <TableRow>
                                                        <TableCell>{t('certification')}</TableCell>
                                                        <TableCell>{t('expires')}</TableCell>
                                                        <TableCell>{t('number')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {this.state.certifications != null && this.state.certifications.length > 0 ? 
                                                    this.state.certifications.map((certification, i) => (
                                                    <TableRow key={'cert' + i}>
                                                        <TableCell>
                                                            <TextOnlyClickButton enabled={true} click={this.editCert(i)} name={certification.certTypeSel != null ? certification.certTypeSel.label : ''}/>
                                                        </TableCell>
                                                        <TableCell>
                                                            {certification.expirationDate != null && <DateDisplay value={certification.expirationDate} format={this.props.auth.user.dateFormat}/>}
                                                        </TableCell>
                                                        <TableCell>{certification.number != null ? certification.number : ''}</TableCell>
                                                    </TableRow> 
                                                )) :
                                                    <TableRow>
                                                        <TableCell colSpan={2} sx={{ textAlign: 'center', fontWeight: 'bold' }}>{t('noCertificationsFound')}</TableCell>
                                                    </TableRow>
                                                }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Aux>
                            }
                            <ModalDialog buttonStack={(<Aux>
                                    <Button color="secondary" size="small" variant='text' onClick={this.toggleCerts}>Close</Button>
                                    <Button color="primary" size="small" variant="text" onClick={this.saveCert}>Save</Button>
                                </Aux>
                                )} title={t('certification')} toggleDialog={this.toggleCerts}
                                            dialogStatus={this.state.showCert} fullWidth maxWidth='sm'>
                                <Grid container spacing={3}>
                                    <Grid item xs={10} sm={5}>
                                        <AutoCompleteField
                                            value={this.state.certTypeSel}
                                            options={this.state.certTypes}
                                            onChange={this.changeAuto('certTypeSel', 'certType')}
                                            error={errors[t('certType')] != null ? true : false}
                                            helperText={errors[t('certType')]}
                                            label={t('type')}
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={5}>
                                        <DateSelect
                                            value={this.state.certExpDate}
                                            onChange={this.changeDate('certExpDate')}
                                            error={errors[t('certExpDate')] != null ? true : false}
                                            helperText={errors[t('certExpDate')]}
                                            label={t('expires')}
                                            name="certExpDate"
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={5}>
                                        <TextField 
                                            value={this.state.certNumber} 
                                            onChange={this.changeValue} 
                                            name="certNumber" 
                                            label={t('number')} 
                                            data-cy='certNumber'
                                            error={errors[t('certNumber')] != null ? true : false} helperText={errors[t('certNumber')]}
                                        />
                                    </Grid>
                                </Grid>      
                            </ModalDialog>
                            <br/>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Button variant="contained" color="primary" size="small" onClick={this.save}>{t('save')}</Button>&nbsp;&nbsp;
                                    <Button variant="outlined" color="primary" size="small" onClick={this.clearListSettings}>{t('clearListData')}</Button>&nbsp;&nbsp;
                                    <Button variant="outlined" color="primary" size="small" onClick={this.clearCachedData}>{t('clearCachedData')}</Button>&nbsp;&nbsp;
                                    <Button variant="outlined" color="primary" size="small" onClick={this.return}>{t('return')}</Button>&nbsp;&nbsp;
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <AutoCompleteField
                                        value={this.state.rolesSel}
                                        options={this.state.roleOptions}
                                        onChange={this.changeRoles}
                                        error={errors[t('roles')] != null ? true : false}
                                        helperText={errors[t('roles')]}
                                        label={t('roles')}
                                        disabled={true}
                                        multiple
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AutoCompleteField
                                        value={this.state.departmentsSel}
                                        options={this.state.departmentOptions}
                                        multiple={true}
                                        disabled={true}
                                        label={t('departments')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AutoCompleteField
                                        value={this.state.processesSel}
                                        options={this.state.processOptions}
                                        onChange={this.changeProcesses}
                                        error={errors[t('processes')] != null ? true : false}
                                        helperText={errors[t('processes')]}
                                        label={t('businessProcesses')}
                                        disabled={true}
                                        multiple
                                    />
                                </Grid>
                                <Grid item xs>
                                    <DocumentList documents={this.state.documents}/>
                                    <div className="form-group row">
                                        <label className="col-sm-2">{t('document')}</label>
                                        <div className="col-sm-7">
                                            <input type="file" onChange={this.handleFileUpload}
                                                multiple={false}/>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={this.state.tabKey} index={1}>
                            <UserCerifications id={this.props.auth.user.id}/>
                        </TabPanel>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} order={{ xs: 2, md: 1 }}>
                        <Panel>
                            <ProfileImage image={profileImage} id={this.props.auth.user.id} callback={this.refreshData}/>                            
                        </Panel>
                    </Grid>
                </Grid>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({auth: state.auth, errors: state.errors.list});

export default withTranslation() (connect(mapStateToProps)(UserProfile));