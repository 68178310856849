import React, { Component } from 'react';
import axios from 'axios';
import Aux from '../../hoc/Auxilary/Auxilary';
import { connect } from 'react-redux';
import SectionTitle from '../UI/Typography/SectionTitle';
import Chart from 'react-google-charts';
import { withRouter } from 'react-router-dom';
import DateSelect from '../UI/DateSelect/DateSelect';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';

class ProductionScheduleGantt extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            chartData: null,
            startDate: null,
            dueDate: null,
            item: null,
            items: [],
            itemSel: null,
            status: null,
            statusSel: null,
            statuses: [],
            chartHeight: 0,
            record: null,
            open: false
        }

        this.handleClick = this.handleClick.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.producedItem, common.cacheValues.productionBatchStatus], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        let dueDate = new Date();
        dueDate.setDate(dueDate.getDate() + 7);
        this.setState({
            items: cacheValues.producedItems,
            statuses: cacheValues.productionBatchStatuses,
            startDate: startDate,
            dueDate: dueDate
        }, () => {
            this.loadRecords();
            this.chartEvents = [
                {
                eventName : 'select',
                callback: this.handleClick
                }
            ];
        })
    }

    handleClick(chart){
        var selection = chart.chartWrapper.getChart().getSelection();
        var index = selection[0].row;
        this.setState({record: this.state.list[index], open: true});
    }

    close = () => {
        this.setState({open: false});
    }

    dateChange = (name) => (e) => {
        this.setState({[name]: e}, () => {this.loadRecords()});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            this.loadRecords();
        });
    };

    loadRecords = async () => {
        let columnFilters = [
            {id: 'item', value: this.state.item},
            {id: 'dueDate', value: [this.state.startDate, this.state.dueDate]},
            {id: 'status', value: this.state.status}
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: [],
            start: this.state.offset,
            size: this.state.listSize,
        };
        let list = await axios.get('/api/productionbatches', {params: params});
        let chartData = [
            [
                {type: 'string', label: 'Task ID'},
                {type: 'string', label: 'Task Name'},
                {type: 'date', label: 'Start Date'},
                {type: 'date', label: 'End Date'},
                {type: 'number', label: 'Duration'},
                {type: 'number', label: 'Percent Complete'},
                {type: 'string', label: 'Dependencies'},
                {type: 'string', label: 'Status'}
            ]
        ];
        var height = 0;
        if(list.data.data.length > 0){
            list.data.data.forEach(row => {
                var percent = 0;
                var completed = 0;
                row.activities.forEach(act => {
                    if(act.productionOrder != null && (act.productionOrder.status === this.props.auth.constants.productionOrderStatuses.finished || 
                        act.productionOrder.status === this.props.auth.constants.productionOrderStatuses.complete))
                        completed++;
                });
                if(completed > 0){
                    percent = Math.round((completed / row.activities.length) * 100);
                }
                chartData.push([
                    row._id,
                    row.number + ' - ' + row.outputItem,
                    new Date(row.activities[0].expectedStart),
                    new Date(row.dueDate),
                    null,
                    percent,
                    null,
                    row.status.label
                ]);
            });
            height = (list.data.data.length * 42) + 45;
        } else {
            chartData = null;
        }
        this.setState({
            list: list.data.data,
            chartData: chartData,
            chartHeight: height
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <SectionTitle title={t('productionBatches')}/>
                <Grid container spacing={3}>
                <Grid item xs={4} sm={2}>
                        <DateSelect
                            onChange={this.dateChange('startDate')}
                            value={this.state.startDate}
                            label={t('startDate')}
                            format={this.props.auth.user.dateFormat}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <DateSelect
                            onChange={this.dateChange('dueDate')}
                            value={this.state.dueDate}
                            label={t('endDate')}
                            format={this.props.auth.user.dateFormat}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                            value={this.state.itemSel}
                            options={this.state.items}
                            onChange={this.changeAuto('itemSel', 'item')}
                            label={t('outputItem')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                            value={this.state.statusSel}
                            options={this.state.statuses}
                            onChange={this.changeAuto('statusSel', 'status')}
                            label={t('status')}
                        />
                    </Grid>
                </Grid>
                {this.state.chartData != null &&
                    <Chart
                        width={'100%'}
                        height={this.state.chartHeight}
                        chartType="Gantt"
                        loader={<div>Loading Chart</div>}
                        data={this.state.chartData}
                        rootProps={{ 'data-testid': '1' }}
                        chartEvents={this.chartEvents}
                    />
                }
                {this.state.record != null &&
                    <Dialog open={this.state.open} onClose={this.close} center aria-labelledby="schedule">
                        <DialogTitle>
                            <SectionTitle title={this.state.record.number + ' - ' + this.state.record.outputItem.name}/>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}>
                                    <FormControl>
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.record.status.label}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <FormControl>
                                        <FormLabel>{t('dueDate')}</FormLabel>
                                        <DateDisplay value={this.state.record.dueDate} format={this.props.auth.user.dateFormat}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <FormControl>
                                        <FormLabel>{t('outputQuantity')}</FormLabel>
                                        {this.state.record.outputQuantity + this.state.record.uom.label}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="primary" href={'/#/productionbatch/' + this.state.record._id}>
                                {t('view')}
                            </Button>&nbsp;&nbsp;
                            <Button variant="contained" color="secondary" onClick={this.close}>{t('close')}</Button>
                        </DialogActions>
                    </Dialog>
                }
                
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default withTranslation() (connect(mapStateToProps)(withRouter(ProductionScheduleGantt)));
