import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import SectionTitle from '../UI/Typography/SectionTitle'
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import FormLabel from '@mui/material/FormLabel';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import EditIcon from '@mui/icons-material/EditTwoTone';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import { EvoraTabs, TabCanvas, TabPanel } from '../UI/Tabs/Tabs';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { approveSigSecurity, completeSigSecurity,
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import queryString from 'query-string';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ItemLedgerComponent from '../Inventory/ItemLedgerComponent';
import { NumericFormat } from 'react-number-format';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ConversionHelper from '../../helpers/conversionHelper';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import LabelPrint from '../UI/Buttons/LabelPrint';
import ConfirmDelete from '../General/ConfirmDelete';
import PrintView from '../Printing/PrintView';
import Confirmation from '../UI/Dialog/Confirmation';
import ProductionHelper from '../../helpers/productionHelper';

class ProductionOrder extends Component {
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            _id: '',
            number: '',
            foreignId: '',
            description: '',
            outputItem: '',
            quantity: 0,
            quantityUOM: {},
            plannedQuantity: '',
            startDate: '',
            plannedStart: new Date(),
            endDate: '',
            dueDate: '',
            finishedDate: '',
            status: {},
            location: {},
            sourceLots: [],
            outputLots: [],
            documents: [],
            analysisDocuments: [],
            testInstances: [],
            routing: {},
            productionBom: {},
            routingSel: null,
            statuses: [],
            lots: [],
            routings: [],
            boms: [],
            statusSel: {label: '', value: ''},
            lotSel: null,
            bomSel: null,
            items: [],
            allItems: [],
            outputItemSel: null,
            uoms: [],
            quantityUOMSel: null,
            locations: [],
            locSel: null,
            tabKey: 0,
            tabLabels: [
                {name: t('productionOrder'), disabled: false}
            ],
            routingObj: null,
            lines: [],
            inputs: [],
            outputBin: '',
            outputBinSel: null,
            bins: [],
            outputLot: null,
            outputLotTrans: null,
            outputContainer: null,
            outputContainerTransaction: null,
            inputItem: '',
            inputSel: null,
            inputAmount: '',
            inputUOM: '',
            inputUOMSel: null,
            inputLot: '',
            inputLotSel: null,
            inputLots: [],
            inputContainer: '',
            inputContainerSel: null,
            inputContainers: [],
            inputCount: '',
            outputs: [],
            newOutputItem: '',
            newOutputItemSel: null,
            newOutputAmount: '',
            newOutputUOM: '',
            newOutputUOMSel: null,
            newOutputTotalWeight: '',
            newOutputTotalWeightUOM: '',
            newOutputTotalWeightUOMSel: null,
            newOutputDiscrete: '',
            newOutputDiscreteUOM: '',
            newOutputDiscreteUOMSel: null,
            security: [],
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            userList: [],
            users: [],
            usersSel: [],
            reviewers: [],
            signers: [],
            user: null,
            reviewer: null,
            signer: null,
            productionSchedule: '',
            productionScheduleSel: null,
            productionSchedules: [],
            productionScheduleActivity: '',
            prodActSel: null,
            productionScheduleActivities: [],
            optionsMenu: [],
            flagForSignature: 0,
            inputHasCount: false,
            productionBatch: null,
            scheduleId: null,
            dialogOpen: false,
            enableUnlock: false,
            renderNum: Math.random(),
            enableSave: true,
            sales: [],
            outputCount: false,
            inputCost: '',
            labourCost: '',
            producedBy: '',
            producedBySel: null,
            producedByList: [],
            estimateEffort: false,
            estimatedEffort: '',
            estimatedEffortCost: '',
            estimatedInputCost: '',
            containerTypes: [],
            containerType: '',
            containerTypeSel: null,
            released: false,
            customLot: '',
            sigData: null,
            sopList: [],
            sops: [],
            sopsSel: [],
            equipmentOptions: [],
            lineEquipments: [],
            equipmentsSel: [],
            machineType: '',
            machineTypeSel: null,
            machineTypes: [],
            room: '',
            roomSel: null,
            rooms: [],
            name: '',
            lineDescription: '',
            seq: '',
            showLine: false,
            runEffort: '',
            line_id: '',
            notes: '',
            activeUsers: [],
            activeManagers: [],
            activeQas: [],
            form: '',
            hasForm: false,
            template: '',
            tareWeight: '',
            inputLines: [],
            outputLines: [],
            inputBin: '',
            inputBinSel: null,
            custom: false,
            outputLabels: [],
            activityOutputs: [],
            goBack: -1,
            itemLots: [],
            outputContainers: [],
            newLot: true,
            outputLotSel: null,
            outputContainerSel: null,
            processingLoss: null,
            inputCannabisTotal: null,
            outputCannabisTotal: null,
            deleteOpen: false,
            processingLossPercent: null,
            printViewer: false,
            finalDocument: null,
            showOutputMore: false,
            acceptOutputMore: false,
            currentAmount: null,
	        canDelete: false,
            newOutputCount: '',
            hasLedgers: false,
            fullItems: [],
            wasteTotal: null,
            returnedTotal: null,
            sampleTotal: null,
            availableCannabisTotal: null
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.item, common.cacheValues.user, common.cacheValues.bin, common.cacheValues.processingLocation,
                                common.cacheValues.productionOrderStatus, common.cacheValues.routing, common.cacheValues.bom, common.cacheValues.quantityUOM, common.cacheValues.allItems,
                                common.cacheValues.productionItem, common.cacheValues.containerType, common.cacheValues.processingRoom, common.cacheValues.routingType,
                                common.cacheValues.productionOrderForm, common.cacheValues.productionOrderSop, common.cacheValues.processingEquipment, common.cacheValues.workCenter,
                                common.cacheValues.productionSchedule, common.cacheValues.machineType], this.props.auth.constants, null, this.props.auth.user.tenant);
        let items = cacheValues.items;
        let allUsers = cacheValues.users;
        let bins = cacheValues.bins;
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.eproductionOrder, this.props.auth.user.tenant);
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
                    this.props.auth.user.defaultLocation : '';
        let params = queryString.parse(this.props.location.search);
        let scheduleId = params != null && params.schedule != null ? params.schedule : null;
        let goBack = -1;
        if(scheduleId != null)
            goBack = -2;
        let locSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locSel == null && locations.length === 1){
            locSel = locations[0];
            location = locSel.value;
        }
        let statusSel = cacheValues.productionOrderStatuses.find(x => x.value === this.props.auth.constants.productionOrderStatuses.new);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.productionOrder);
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let activeQas = [];
        userValues.approve.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeQas.push(row);
            }
        })
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        let gSel = cacheValues.quantityUOMs.find(x => x.value === this.props.auth.constants.quantityUOMs.g);
        this.setState({
            statuses: cacheValues.productionOrderStatuses,
            boms: cacheValues.boms,
            routings: cacheValues.routings,
            items: cacheValues.productionItems,
            allItems: items,
            uoms: cacheValues.quantityUOMs,
            locations: locations,
            bins: bins,
            status: this.props.auth.constants.productionOrderStatuses.new,
            statusSel: statusSel,
            userList: userValues.create,
            reviewers: userValues.review,
            signers: userValues.approve,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            _id: this.props.match.params.id,
            productionSchedules: cacheValues.productionSchedules,
            location: location,
            locSel: locSel,
            scheduleId: scheduleId,
            containerTypes: cacheValues.containerTypes,
            lineTypes: cacheValues.routingTypes,
            rooms: cacheValues.rooms,
            workCenters: cacheValues.workCenters,
            equipments: cacheValues.processingEquipments,
            sopList: cacheValues.sops,
            activeUsers: activeUsers,
            activeManagers: activeManagers,
            activeQas: activeQas,
            template: cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : null,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            allUsers: allUsers,
            g: this.props.auth.constants.quantityUOMs.g,
            gSel: gSel,
            machineTypes: cacheValues.machineTypes,
            goBack: goBack,
            fullItems: cacheValues.allItems
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/eproductionorders', label: this.props.t('productionOrders'), screen: this.props.auth.screenDefs.ProductionOrder},
                    { path: 'active', label: this.props.t('productionOrder'), screen: this.props.auth.screenDefs.ProductionOrder}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const constants = this.props.auth.constants;
        const internal = this.props.auth.user.internal;
        const record = await axios.get('/api/productionorder/' + this.state._id);
        let sales = [];
        if(record.data.status !== constants.productionOrderStatuses.inserted && record.data.status !== constants.productionOrderStatuses.released &&
            record.data.status !== constants.productionOrderStatuses.planned && record.data.status !== constants.productionOrderStatuses.new &&
            record.data.status !== constants.productionOrderStatuses.inprogress){
            let salesParms = {
                prodOrder: this.state._id
            }
            sales = await axios.get('/api/batchSales', {params: salesParms});
        }
        let released = record.data.status !== constants.productionOrderStatuses.planned && record.data.status !== constants.productionOrderStatuses.new &&
                        record.data.status !== constants.productionOrderStatuses.inserted;
        const statusSel = this.state.statuses.find(x => x.value === record.data.status);
        const routingSel = this.state.routings.find(x => x.value === record.data.routing);
        const bomSel = this.state.boms.find(x => x.value === record.data.productionBom);
        const outputItemSel = this.state.fullItems.find(x => x.value === record.data.outputItem);
        const quantityUOMSel = record.data.quantityUOM != null ? this.state.uoms.find(x => x.value === record.data.quantityUOM) : null;
        const locSel = record.data.location != null ? this.state.locations.find(x => x.value === record.data.location) : null;
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.productionOrder);
        let outputBinSel = record.data.output != null && record.data.output.bin != null ? this.state.bins.find(x => x.value === record.data.output.bin) : null;
        let inputs = record.data.inputs;
        let inputHasCount = false;
        let inputLines = [];
        let tallyItem = record.data.productionBatch != null && record.data.productionBatch.outputsTally != null ? 
                            record.data.productionBatch.outputsTally.find(x => x.item === record.data.outputItem) : null;
        if(record.data.inputs != null && record.data.inputs.length > 0){
            for(let row of record.data.inputs){
                if(row.item != null){
                    let line = inputLines.find(x => x.item === row.item);
                    let itemSel = this.state.fullItems.find(x => x.value === row.item);
                    let uomSel = this.state.uoms.find(x => x.value === row.uom);
                    let lotSel = row.lots != null && row.lots.length > 0 && row.lot != null ? row.lots.find(x => x.value === row.lot) : null;
                    let containerSel = row.containers != null && row.containers.length > 0 && row.container != null ?
                            row.containers.find(x => x.value === row.container) : null;
                    let binSel = row.bin != null ? this.state.bins.find(x => x.value === row.bin) : null;
                    if(itemSel.hasCount)
                        inputHasCount = true;
                    if(line == null){
                        inputLines.push({
                            itemSel: itemSel,
                            item: row.item,
                            uom: row.uom,
                            uomSel: uomSel,
                            count: row.count,
                            amount: row.amount,
                            containers: row.containers,
                            lots: row.lots,
                            estimatedAmount: row.estimatedAmount,
                            estimatedCost: row.estimatedCost,
                            bomQuantity: row.bomQuantity,
                            scrapPercent: row.scrapPercent,
                            lines: [{
                                _id: row._id,
                                amount: row.amount,
                                count: row.count,
                                lot: row.lot,
                                lotSel: lotSel,
                                container: row.container,
                                containerSel: containerSel,
                                containerTransaction: row.containerTransaction,
                                lotTransaction: row.lotTransaction,
                                itemLedger: row.itemLedger,
                                reservation: row.reservation,
                                bin: row.bin,
                                binSel: binSel
                            }]
                        })
                    } else {
                        line.amount = row.amount != null && line.amount != null ? parseFloat(row.amount) + parseFloat(line.amount) : row.amount != null ? row.amount : line.amount;
                        line.count = row.count != null && line.count != null ? parseInt(row.count) + parseInt(line.count) : row.count != null ? row.count : line.count;
                        line.lines.push({
                            __id: row._id,
                            amount: row.amount,
                            count: row.count,
                            lot: row.lot,
                            lotSel: lotSel,
                            container: row.container,
                            containerSel: containerSel,
                            containerTransaction: row.containerTransaction,
                            lotTransaction: row.lotTransaction,
                            itemLedger: row.itemLedger,
                            reservation: row.reservation,
                            bin: row.bin,
                            binSel: binSel
                        })
                    }
                }
            }
        }
        let outputs = record.data.outputs;
        let outputCount = false;
        let outputLines = [];
        let outputLabels = [];
        let itemLots = record.data.itemLots;
        if(outputs != null && outputs.length > 0){
            outputs.forEach(row => {
                if(row.item != null){
                    let line = outputLines.find(x => x.item === row.item);
                    let itemSel = this.state.fullItems.find(x => x.value === row.item);
                    let uomSel = this.state.uoms.find(x => x.value === row.uom);
                    let itemLotRow = itemLots.find(x => x.item === row.item);
                    let lots = itemLotRow != null ? itemLotRow.values : [];
                    let lotSel = lots != null && lots.length > 0 && row.lot != null ? lots.find(x => x.value === row.lot) : null;
                    let containers = [];
                    if(row.lot != null){
                        let lotRow = itemLotRow != null ? itemLotRow.lots.find(x => x.lot === row.lot) : null;
                        if(lotRow != null){
                            containers = lotRow.containers;
                        }
                    }
                    let newLot = row.newLot === true ? true : itemSel.customNumber !== true ? false : lots == null || lots.length === 0 ? true : false;
                    let containerSel = containers != null && containers.length > 0 && row.container != null ?
                        containers.find(x => x.value === row.container) : null;
                    let binSel = row.bin != null ? this.state.bins.find(x => x.value === row.bin) : null;
                    if(row.count != null && row.count !== '')
                        outputCount = true;
                    let containerTypeSel = row.containerType != null ? this.state.containerTypes.find(x => x.value === row.containerType) : null;
                    if(line == null){
                        let outputLength = 310;
                        if((row.count != null && row.count !== '' && row.count !== 0) || (row.itemSel != null && row.itemSel.hasCount === true)){
                            outputLength += 100;
                        }
                        if((row.totalWeight != null && row.totalWeight !== '' && row.totalWeight !== 0) || (row.itemSel != null && row.itenSel.discreteWeight === true)){
                            outputLength += 150
                            if(fieldAccess){
                                outputLength += 60
                            }
                        }
                        if((itemSel != null && itemSel.lotTracked) || (row.lot != null && row.lot !== '') || (row.customLot != null && row.customLot !== '')){
                            outputLength += 420;
                        }
                        if(itemSel != null && itemSel.tare === true){
                            outputLength += 120
                        }
                        if(row.discreteWeight != null && row.discreteWeight !== '' && !fieldAccess){
                            outputLength += 150;
                        }
                        outputLines.push({
                            item: row.item,
                            itemSel: itemSel,
                            amount: row.amount,
                            uom: row.uom,
                            uomSel: uomSel,
                            count: row.count,
                            totalWeight: row.totalWeight,
                            containers: row.containers,
                            lots: lots,
                            outputLength: outputLength,
                            lines: [{
                                _id: row._id,
                                container: row.container,
                                containerSel: containerSel,
                                containerTransaction: row.containerTransaction,
                                lot: row.lot,
                                lotSel: lotSel,
                                lotTransaction: row.lotTransaction,
                                bin: row.bin,
                                binSel: binSel,
                                containerType: row.containerType,
                                containerTypeSel: containerTypeSel,
                                amount: row.amount,
                                count: row.count,
                                itemLedger: row.itemLedger,
                                totalWeight: row.totalWeight,
                                discreteWeight: row.discreteWeight,
                                customLot: row.customLot,
                                tareWeight: row.tareWeight,
                                totalUOM: this.state.g,
                                totalUOMSel: this.state.gSel,
                                containers: containers,
                                newLot: newLot
                            }]
                        });
                    } else {
                        line.amount = line.amount != null && row.amount != null ? parseFloat(line.amount) + parseFloat(row.amount) : row.amount != null ? row.amount : line.amount;
                        line.count = row.count != null && line.count != null ? parseInt(row.count) + parseInt(line.count) : row.count != null ? row.count : line.count;
                        line.totalWeight = line.totalWeight != null && row.totalWeight != null ? parseFloat(line.totalWeight) + parseFloat(row.totalWeight) :
                            row.totalWeight != null ? row.totalWeight : line.totalWeight;
                        line.lines.push({
                            _id: row._id,
                            container: row.container,
                            containerSel: containerSel,
                            containerTransaction: row.containerTransaction,
                            lot: row.lot,
                            lotSel: lotSel,
                            lotTransaction: row.lotTransaction,
                            bin: row.bin,
                            binSel: binSel,
                            containerType: row.containerType,
                            containerTypeSel: containerTypeSel,
                            amount: row.amount,
                            count: row.count,
                            itemLedger: row.itemLedger,
                            totalWeight: row.totalWeight,
                            discreteWeight: row.discreteWeight,
                            customLot: row.customLot,
                            tareWeight: row.tareWeight,
                            totalUOM: this.state.g,
                            totalUOMSel: this.state.gSel,
                            containers: containers,
                            newLot: newLot
                        });
                    }
                    if(containers != null && containers.length > 0 && row.container != null && containerSel != null && containerTypeSel != null) {
                        outputLabels.push({
                            id: row.container,
                            container: containerSel.number,
                            lot: lotSel.label,
                            item: itemSel.label,
                            quantity: ConversionHelper.round3(row.amount),
                            uom: uomSel.label,
                            category: containerTypeSel.label,
                            itemNumber: itemSel.number,
                            date: record.data.endDate,
                            qrPath: `${window.location.origin}/#/container/${row.container}`,
                            strain: itemSel.strain != null && itemSel.strain !== '' ? itemSel.strain : '',
                            strainCode: itemSel.strainCode != null && itemSel.strainCode !== '' ? itemSel.strainCode : '',
			    dateFormat: this.props.auth.user.dateFormat
                        });
                    }
                }
            })
        };
        let estLine = record.data.lines.find(x => x.estimatedEffort != null);
        let estimateEffort = estLine != null;
        let usersSel = [];
        let users = record.data.users != null ? record.data.users : [];
        let lines = this.setupLines(record.data.lines);
        let userList = this.state.userList;
        if(users != null && users.length > 0){
            users.forEach(row => {
                let val = this.state.userList.find(x => x.value === row);
                if(val != null){
                    usersSel.push(val);
                } else {
                    val = this.state.allUsers.find(x => x.value === row);
                    if(val != null){
                        usersSel.push(val);
                        userList.push(val);
                    }
                }
            });
        }
        let productionSchedules = this.state.productionSchedules;
        if(record.data.productionScheduleValue != null){
            let exists = productionSchedules.find(x => x.value === record.data.productionScheduleValue.value);
            if(!exists){
                productionSchedules.push(record.data.productionScheduleValue);
            }
        }
        let prodActSel = record.data.productionScheduleActivity != null && 
                         record.data.productionSchedule?.activities != null && record.data.productionSchedule?.activities !== '' ?
                    record.data.productionSchedule.activities.find(x => x.value === record.data.productionScheduleActivity) : null;
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants,
            this.props.auth.common.statusTypes.productionOrder);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants,
            this.props.auth.common.statusTypes.productionOrder);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants,
            this.props.auth.common.statusTypes.productionOrder);
        let sigDeleteCallback = (record.data.status === this.props.auth.constants.productionOrderStatuses.released || 
            record.data.status === this.props.auth.constants.productionOrderStatuses.inprogress || record.data.status === this.props.auth.constants.productionOrderStatuses.new) && 
            record.data.user != null ? this.deleteSigCallback : null;
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: usersSel, name: t('complete'),
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user,
                objectType: this.props.auth.constants.objectIds.productionOrder, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, object: this.state._id, saveCallback: this.saveCallback, deleteCallback: sigDeleteCallback
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: !internal && this.state.reviewer == null ? this.state.activeManagers : this.state.reviewers,
                name: t('review'), title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.productionOrder, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: !internal && this.state.signer == null ? this.state.activeQas : this.state.signers,
                name: t('approve'), title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer,
                objectType: this.props.auth.constants.objectIds.productionOrder, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = record.data.status === this.props.auth.constants.productionOrderStatuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.productionOrderStatuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.productionOrderStatuses.reviewed ? 3 : 0;

        let tabLabels = [
            {name: t('productionOrder'), disabled: false}
        ];
        if(sales.data != null && sales.data.length > 0){
            tabLabels.push({name: t('sales'), disabled: false});
        }
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        let form = record.data.form != null && record.data.form !== '' ? JSON.parse(record.data.form) : this.state.template != null && this.state.template !== '' ? this.state.template : null;
        if(record.data.hasLedgers){
            tabLabels.push({name: t('itemLedgers'), disabled: false});
        }
        this.setState({
            _id: record.data._id,
            number: record.data.number,
            foreignId: record.data.foreignId,
            description: record.data.description,
            outputItem: record.data.outputItem,
            quantity: record.data.quantity != null && record.data.quantity !== '' ? record.data.quantity : 0,
            quantityUOM: record.data.quantityUOM,
            plannedQuantity: record.data.plannedQuantity,
            plannedStart: record.data.plannedStart,
            startDate: record.data.startDate,
            endDate: record.data.endDate,
            dueDate: record.data.dueDate,
            finishedDate: record.data.finishedDate,
            status: record.data.status,
            location: record.data.location,
            sourceLots: record.data.sourceLots,
            outputLots: record.data.outputLots,
            documents: record.data.documents,
            analysisDocuments: record.data.analysisDocuments,
            testInstances: record.data.testInstances,
            routing: record.data.routing,
            statusSel: statusSel,
            routingSel: routingSel,
            bomSel: bomSel,
            outputItemSel: outputItemSel,
            quantityUOMSel: quantityUOMSel,
            locSel: locSel,
            productionBom: record.data.productionBom,
            routingObj: record.data.routingObj,
            lines: lines,
            outputBin: record.data.output != null ? record.data.output.bin : null,
            outputBinSel: outputBinSel,
            inputs: inputs,
            outputs: outputs,
            users: record.data.users,
            usersSel: usersSel,
            fieldAccess: fieldAccess,
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            user: record.data.user,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            productionSchedule: record.data.productionSchedule,
            productionScheduleSel: record.data.productionScheduleValue,
            productionSchedules: productionSchedules,
            prodActSel: prodActSel,
            productionScheduleActivity: record.data.productionScheduleActivity,
            productionScheduleActivities: record.data.productionScheduleActivities,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            inputHasCount: inputHasCount,
            productionBatch: record.data.productionBatch,
            renderNum: Math.random(),
            sales: sales.data,
            tabLabels: tabLabels,
            outputCount: outputCount,
            inputCost: record.data.inputCost,
            labourCost: record.data.labourCost,
            estimateEffort: estimateEffort,
            estimatedEffort: record.data.estimatedEffort,
            estimatedEffortCost: record.data.estimatedEffortCost,
            estimatedInputCost: record.data.estimatedInputCost,
            released: released,
            notes: record.data.notes,
            scheduleId: record.data.schedule,
            form: form,
            hasForm: hasForm,
            userList: userList,
            inputLines: inputLines,
            outputLines: outputLines,
            custom: record.data.custom === true ? true : false,
            outputLabels: outputLabels,
            activityOutputs: record.data.activityOutputs,
            itemLots: itemLots,
            processingLoss: record.data.processingLoss,
            inputCannabisTotal: record.data.inputCannabisTotal,
            outputCannabisTotal: record.data.outputCannabisTotal,
            deleteOpen: false,
            processingLossPercent: record.data.processingLossPercent,
            finalDocument: record.data.finalDocument,
            showOutputMore: false,
            acceptOutputMore: false,
            currentAmount: tallyItem != null ? tallyItem.quantity : null,
            hasLedgers: record.data.hasLedgers,
            wasteTotal: record.data.wasteTotal !== 0 ? record.data.wasteTotal : null,
            returnedTotal: record.data.returnedTotal !== 0 ? record.data.returnedTotal : null,
            sampleTotal: record.data.sampleTotal !== 0 ? record.data.sampleTotal : null,
            availableCannabisTotal: record.data.availableCannabisTotal !== 0 ? record.data.availableCannabisTotal : null
        }, () => {
            const crumbs = [
                { path: '/#/eproductionorders', label: this.props.t('productionOrders'), screen: this.props.auth.screenDefs.ProductionOrder},
                { path: 'active', label: this.props.t('productionOrder') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.ProductionOrder}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.determineUnlock();
            this.determineDelete();
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    };

    determineUnlock() {
        let enableUnlock = false;
        let state = this.state;
        let constants = this.props.auth.constants;
        let batchLocked = false;
        if(state.productionBatch != null && state.productionBatch._id != null){
            if(state.productionBatch.status !== constants.productionBatchStatuses.inprogress )
                batchLocked = true;
        }
        if(!batchLocked){
            if(state.status === constants.productionOrderStatuses.complete){
                enableUnlock = true;
            } else if(state.status !== constants.productionOrderStatuses.released && this.state.status !== constants.productionOrderStatuses.ingprogress &&
                state.status !== constants.productionOrderStatuses.inserted){
                let outputsLock = false;
                if(state.outputs != null && state.outputs.length > 0){
                    for(let row of state.outputs){
                        if(row.containerSel != null){
                            if(ConversionHelper.round3(parseFloat(row.amount)) > ConversionHelper.round3(parseFloat(row.containerSel.quantity))){
                                outputsLock = true;
                                break;;
                            }
                        }
                    }
                }
                if(!outputsLock){
                    enableUnlock = true;
                }
            }
        }
        this.setState({enableUnlock: enableUnlock});
    }

    determineDelete(){
        let canDelete = false;
        let state = this.state;
        let constants = this.props.auth.constants;
        if(state.status === constants.productionOrderStatuses.new || state.status === constants.productionOrderStatuses.inserted || state.status === constants.productionOrderStatuses.released){
            canDelete = true;
        } else if(state.status === constants.productionOrderStatuses.inprogress){
            if(state.lines != null && state.lines.length > 0){
                let completed = false;
                for(let row of state.lines){
                    if(row.status != null && row.status._id !== constants.statuses.deleted && row.status._id !== constants.statuses.inprogress && row.status._id !== constants.statuses.inprogress){
                        completed = true;
                        break;
                    }
                }
                if(!completed){
                    canDelete = true;
                }
            } else {
                canDelete = true;
            }
        }
        this.setState({canDelete: canDelete});
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]}, () => {
            if(name === 'newLot'){
                this.setState({outputContainer: '', outputContainerSel: null, outputLot: '', outputLotSel: null})
            }
        });
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, async () => {
            if(data === 'outputItem' && newValue != null){
                let item = await axios.get('/api/item/' + newValue.value);
                let routingSel = null;
                let bomSel = null;
                let route = null;
                let routing = null;
                let productionBom  = null;
                let producedByList = [];
                if(!this.state.custom){
                    let itemProducedResult = await axios.get('/api/itemproducedby/' + newValue.value);
                    producedByList = itemProducedResult.data;
                    if(itemProducedResult.data.length === 1){
                        let row = itemProducedResult.data[0];
                        routingSel = this.state.routings.find(x => x.value === row.routing);
                        routing = row.routing;
                        bomSel = this.state.boms.find(x => x.value === row.productionBom);
                        productionBom = row.productionBom;
                        route = await axios.get('/api/routedetail/' + row.routing);
                    }
                }
                let quantityUOMSel = this.state.uoms.find(x => x.value === item.data.uom);
                let quantity = this.state.quantity != null && this.state.quantity !== '' ? this.state.quantity : 0;
                this.setState({
                    routing: routing,
                    productionBom: productionBom,
                    bomSel: bomSel,
                    routingSel: routingSel,
                    routingObj: route != null ? route.data : null,
                    quantity: Number(quantity),
                    quantityUOM: item.data.uom,
                    quantityUOMSel: quantityUOMSel,
                    producedByList: producedByList
                })
            } else if (data === 'outputItem' && newValue == null){
                this.setState({routing: '', routingSel: null, productionBom: '', bomSel: null, quantityUOM: '', quantityUOMSel: null, producedByList: []})
            } else if (data === 'inputBin' && this.props.auth.user.binFilter === true){
                if(newValue != null){
                    if(this.state.inputItem != null && this.state.inputItem !== ''){
                        let result = await axios.post('/api/itemcontainers', {items: [this.state.inputItem], bin: newValue.value, available: true, consume: true});
                        this.setState({inputContainers: result.data, inputContainer: '', inputContainerSel: null, inputLot: '', inputLotSel: null});
                    }
                } else {
                    this.setState({inputContainers: [], inputContainer: '', inputContainerSel: null, inputLot: '', inputLotSel: null})
                }
            } else if(newValue != null && (data === 'inputItem' || data === 'outputItem')){
                let poData = {
                    type: this.props.auth.constants.objectIds.productionOrder,
                    date: this.state.startDate
                };
                let item = await axios.post('/api/itemselect/' + newValue.value, poData);
                let uomSel = this.state.uoms.find(x => x.value === item.data.uom);
                if(data === 'inputItem'){
                    let inputHasCount = false;
                    if(this.state.inputLines != null && this.state.inputLines.length > 0){
                        this.state.inputLines.forEach(row => {
                            if(row.itemSel.hasCount)
                                inputHasCount = true;
                        });
                    }
                    if(newValue.hasCount)
                        inputHasCount = true;
                    this.setState({
                        inputContainers: item.data.containers,
                        inputUOM: item.data.uom,
                        inputUOMSel: uomSel,
                        inputHasCount: inputHasCount,
                        inputContainer: '',
                        inputContainerSel: null,
                        inputLot: null,
                        inputLotSel: null
                    });
                } else {
                    this.setState({outputLots: item.data.lots, outputUOM: item.data.uom, outputUOMSel: uomSel});
                }
            } else if(newValue != null && data === 'productionSchedule'){
                let data = {};
                if(this.state.routing != null && this.state.routing !== '')
                    data.routing = this.state.routing;
                let activities = await axios.get('/api/productionscheduleactivities/' + newValue.value, {params: data});
                this.setState({productionScheduleActivities: activities.data});
            } else if(newValue != null && data === 'producedBy'){
                let routing = newValue.routing;
                let productionBom = newValue.productionBom;
                let routingSel = this.state.routings.find(x => x.value === routing);
                let bomSel = this.state.boms.find(x => x.value === productionBom);
                let route = await axios.get('/api/routedetail/' + routing);
                this.setState({
                    routing: routing,
                    productionBom: productionBom,
                    bomSel: bomSel,
                    routingSel: routingSel,
                    routingObj: route != null ? route.data : null
                });
            } else if(data === 'outputLot'){
                if(newValue != null){
                    let itemLots = this.state.itemLots;
                    let itemRow = itemLots.find(x => x.item === this.state.newOutputItem);
                    if(itemRow != null){
                        let lotRow = itemRow.lots.find(x => x.lot === newValue.value);
                        if(lotRow != null){
                            this.setState({outputContainers: lotRow.containers, outputContainer: '', outputContainerSel: null});
                        }
                    }
                } else {
                    this.setState({outputContainers: [], outputContainer: '', outputContainerSel: null});
                }
            } else if(newValue != null && data === 'room'){
                let equips = await axios.get('/api/equipmentselect', {params: {room: newValue.value}});
                this.setState({equipmentOptions: equips.data});
            }
        });
    };

    changeNumber = (e) => {
        const name = e.target.name;
        const value = e.target.value
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value});
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    changeMulti = (name, data) => (e, newValue) => {
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.save()})
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async (e) => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let inputLines = this.state.inputLines;
        let inputs = [];
        for(let input of inputLines){
            for(let row of input.lines){
                inputs.push({
                    item: input.item,
                    amount: row.amount,
                    _id: row._id,
                    count: row.count,
                    uom: input.uom,
                    lot: row.lot !== '' ? row.lot : null,
                    container: row.container !== '' ? row.container : null,
                    lotTransaction: row.lotTransaction,
                    containerTransaction: row.containerTransaction,
                    reservation: row.reservation,
                    itemLedger: row.itemLedger,
                    estimatedAmount: input.estimatedAmount,
                    estimatedCost: input.estimatedCost,
                    bomQuantity: input.bomQuantity,
                    scrapPercent: input.scrapPercent,
                    bin: row.bin !== '' ? row.bin : null
                })
            }
        }
        let outputLines = this.state.outputLines;
        let outputs = [];
        for(let output of outputLines){
            for(let row of output.lines){
                outputs.push({
                    item: output.item,
                    itemSel: output.itemSel,
                    amount: row.amount,
                    uom: output.uom,
                    uomSel: output.uomSel,
                    count: row.count,
                    lot: row.lot !== '' ? row.lot : null,
                    lotTransaction: row.lotTransaction,
                    container: row.container !== '' ? row.container : null,
                    containerTransaction: row.containerTransaction,
                    bin: row.bin,
                    itemLedger: row.itemLedger,
                    totalWeight: row.totalWeight,
                    totalUOM: row.totalWeight != null && row.totalWeight !== '' ? this.state.g : null,
                    discreteWeight: output.discreteWeight,
                    discreteWeightUOM: row.totalWeight != null && row.totalWeight !== '' ? this.state.g : null,
                    containerType: row.containerType,
                    customLot: row.customLot,
                    tareWeight: row.tareWeight,
                    newLot: row.newLot
                });
            }
        }
        let data = {
            _id: this.state._id,
            description: this.state.description,
            location: this.state.location,
            outputItem: this.state.outputItem,
            routing: this.state.routing,
            productionBom: this.state.productionBom,
            plannedStart: this.state.plannedStart,
            dueDate: this.state.dueDate,
            status: this.state.status,
            inputs: inputs,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            outputs: outputs,
            users: this.state.users,
            productionSchedule: this.state.productionSchedule,
            productionScheduleActivity: this.state.productionScheduleActivity,
            quantity: this.state.quantity,
            quantityUOM: this.state.quantityUOM,
            plannedQuantity: this.state.plannedQuantity,
            sigData: this.state.sigData,
            notes: this.state.notes,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            custom: this.state.custom
        };
        try {
            let result = await axios.post('/api/productionorder', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/eproductionorder/' + result.data.id);
            this.setState({_id: result.data.id, number: result.data.number, enableSave: true, sigData: null}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    validate(full){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.description == null || state.description === '')
            errors[t('description')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.outputItem == null || state.outputItem === '')
            errors[t('outputItem')] = t('required');
        if(state.customer === false){
            if(state.routing == null || state.routing === '')
                errors[t('routing')] = t('required');
            if(state.productionBom == null || state.productionBom === '')
                errors[t('productionBom')] = t('required');
        }
        if(state.plannedStart == null || state.plannedStart === '')
            errors[t('plannedStart')] = t('required');
        /*if(state.productionSchedule != null && state.productionSchedule !== ''){
            if(state.productionScheduleActivity == null || state.productionScheduleActivity === ''){
                errors[t('productionSchedule')] = t('required');
            }
        }*/
        if((state.inputItem != null && state.inputItem !== '') || (state.inputBin != null && state.inputBin !== '') || 
        (state.inputAmount != null && state.inputAmount !== '') || (state.inputCount != null && state.inputCount !== '') ||
        (state.inputContainer != null && state.inputContainer !== '')){
            errors[t('inputItem')] = t('rowNotAdded');
        }
        if((state.newOutputItem != null && state.newOutputItem !== '') || (state.customLot != null && state.customLot !== '') || (state.outputLot != null && state.outputLot !== '') ||
        (state.outputContainer != null && state.outputContainer !== '') || (state.newOutputAmount != null && state.newOutputAmount !== '' && state.newOutputAmount !== 0) || 
        (state.outputBin != null && state.outputBin !== '') || (state.newOutputTotalWeight != null && state.newOutputTotalWeight !== '') || 
        (state.containerType != null && state.containerType !== '')){
            errors[t('newOutputItem')] = t('rowNotAdded');
        }
        if(full === true){
            if(this.state.endDate == null || this.state.endDate === ''){
                errors[t('endDate')] = t('required');
            } else {
                let validEndDate = ConversionHelper.validActivityDate(this.state.endDate, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
                if(!validEndDate){
                    errors[t('endDate')] = t('dateBeforeLock');
                }
            }
            if(this.state.inputLines == null || this.state.inputLines.length === 0)
                errors[t('inputItem')] = t('required');
            if(this.state.outputLines == null || this.state.outputLines.length === 0)
                errors[t('newOutputItem')] = t('required');
            let mainFound = false;
            for(let row of this.state.outputLines){
                if(row.item === this.state.outputItem){
                    if(row.lines != null && row.lines.length > 0 && row.lines[0].amount != null && row.lines[0].amount !== '' && 
                    row.lines[0].amount !== '0' && row.lines[0].amount !== 0){
                        mainFound = true;
                        break;
                    }
                }
            }
            if(!mainFound){
                errors[t('newOutputItem')] = t('outputItemMissing');
            }
            let totals = this.calculateCannabisQuantity();
            if(totals.inputTotal != null && totals.outputTotal != null && totals.inputTotal < totals.outputTotal && this.state.acceptOutputMore !== true){
                this.setState({showOutputMore: true});
                return false;
            }
        }
        if(isEmpty(errors)) {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }else {
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }
    }

    validateComplete = () => {
        let state = this.state;
        let valid = this.validate(true);
        const t = this.props.t;
        const constants = this.props.auth.constants;
        if(!valid)
            return t('notAllComplete');
        if(state.sops != null && state.sops.length > 0){
            for(let i = 0; i < state.sops.length; i++){
                let sop = state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        if(state.lines != null && state.lines.length > 0){
            for(let row of state.lines){
                if(row.activity != null && (row.activity.status === constants.statuses.new || row.activity.status === constants.statuses.inprogress)){
                    return t('notAllActivitiesSigned');
                }
            }
        }
        let errors = {};
        if(state.inputLines != null && state.inputLines.length > 0){
            for(let i = 0; i < state.inputLines.length; i++){
                let line = state.inputLines[i];
                for(let j = 0; j < line.lines.length; j++){
                    let validCat = ProductionHelper.validateHCCat(this.state.outputItemSel.hcReportCategory, line.itemSel.hcReportCategory, line.itemSel.hemp)
                    if(!validCat){
                        errors[`${t('inputAmount')} (${t('line')} ${i}, ${j})`] = t('invalidInputCategory');
                    }
                    let row = line.lines[j];
                    if(row.amount == null || row.amount === '' || row.amount === '0'){
                        errors[`${t('inputAmount')} (${t('line')} ${i}, ${j})`] = t('required');
                    } else {
                        if(line.itemSel != null && line.itemSel.lotTracked === true && (row.container == null || row.container === ''))
                            errors[`${t('container')} (${t('line')} ${i}, ${j})`] = t('required');
                        if(row.containerSel != null){
                            if(row.reservation == null && parseFloat(row.amount) > parseFloat(row.containerSel.quantity)){
                                errors[`${t('inputAmount')} (${t('line')} ${i}, ${j})`] = t('moreThanContainer');
                            } else if (row.reservation != null && parseFloat(row.amount) > parseFloat(row.containerSel.quantity) + parseFloat(row.reservedAmount)){
                                errors[`${t('inputAmount')} (${t('line')} ${i}, ${j})`] = t('moreThanContainer');
                            }
                        } else {
                            if(!line.itemSel.infinite){
                                if(parseInt(row.amount) > line.itemSel.inventory){
                                    errors[`${t('inputAmount')} (${t('line')} ${i}, ${j})`] = t('moreThanInventory');
                                }
                            }
                        }
                    }
                }
            }
        }
        if(state.outputLines != null && state.outputLines.length > 0){
            for(let i = 0; i < state.outputLines.length; i++){
                let output = state.outputLines[i];
                if(output.lines != null && output.lines.length > 0){
                    for(let j = 0; j < output.lines.length; j++){
                        let row = output.lines[j];
                        if(output.itemSel.customNumber === true && output.itemSel.lotTracked){
                            if((row.customLot == null || row.customLot === '') && row.lotSel == null){
                                errors[`${t('customLot')} (${t('line')} ${i}, ${j})`] = t('required');
                            } else {
                                if(row.customLot != null && row.customLot !== '' && output.item != null && output.item !== ''){
                                    let customOuts = state.outputLines.filter(x => x.item !== output.item && x.itemSel != null && x.itemSel.customNumber === true);
                                    for(let out of customOuts){
                                        let matchLots = out.lines.filter(x => x.customLot === row.customLot);
                                        if(matchLots != null && matchLots.length > 0){
                                            errors[`${t('customLot')} (${t('line')} ${i}, ${j})`] = t('lotNumberUsed');
                                        }
                                    }
                                }
                            }
                        }
                        if(row.amount == null || row.amount === ''){
                            errors[`${t('outputAmount')} (${t('line')} ${i}, ${j})`] = t('required');
                        }
                        if(row.amount != null && row.amount !== '' && row.amount !== '0' && output.itemSel != null &&
                        output.itemSel.category !== this.props.auth.constants.itemCategories.caExcise){
                            if(row.bin == null || row.bin === '')
                                errors[`${t('bin')} (${t('line')} ${i}, ${j})`] = t('required');
                        }
                        if(output.itemSel.discreteWeight){
                            if(row.totalWeight == null || row.totalWeight === '')
                                errors[`${t('totalWeight')} (${t('line')} ${i}, ${j})`] = t('required');
                        }
                        if(output.itemSel != null && output.itemSel.customNumber === true && (row.customLot == null || row.customLot === '') && row.lotSel == null)
                            errors[`${t('customLot')} (${t('line')} ${i}, ${j})`] = t('required');
                    }
                }
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return t('incomplete');
        } else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return null;
        }
    }

    release = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._release(e));
    };

    _release = async (e) => {
        const t = this.props.t;
        try {
            let data = {id: this.state._id};
            let result = await axios.post('/api/prodorderrelease', data);
            this.setState({enableSave: true});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    addActivity(i){
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._addActivity(i));
    };

    async _addActivity(i){
        const t = this.props.t;
        let data = {
            poId: this.state._id,
            index: i
        };
        try {
            let result = await axios.post('/api/poactivitycreate', data);
            this.setState({enableSave: true});
            this.props.history.push('/poactivity/' + result.data.id);
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    deleteInput = (index) => (e) => {
        let inputLines = this.state.inputLines;
        inputLines.splice(index, 1);
        let inputHasCount = false;
        if(this.state.inputLines != null && this.state.inputLines.length > 0){
            this.state.inputLines.forEach(row => {
                if(row.itemSel.hasCount)
                    inputHasCount = true;
            });
        }
        this.setState({inputLines: inputLines, inputHasCount: inputHasCount});
    };

    addInput = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.inputItem == null || this.state.inputItem === '')
            errors[t('inputItem')] = t('required');
        else {
            let validCat = ProductionHelper.validateHCCat(this.state.outputItemSel.hcReportCategory, this.state.inputSel.hcReportCategory, this.state.inputSel.hemp)
            if(!validCat){
                errors[t('inputItem')] = t('invalidInputCategory');
            }
        }
        if(this.state.inputAmount == null || this.state.inputAmount === '')
            errors[t('inputAmount')] = t('required');
        else {
            if(this.state.inputContainerSel != null){
                if(parseFloat(this.state.inputAmount) > parseFloat(this.state.inputContainerSel.quantity)){
                    errors[t('inputAmount')] = t('moreThanContainer');
                }
            } else if(this.state.inputItemSel != null){
                if(parseInt(this.state.inputAmount) > this.state.inputItemSel.inventory)
                    errors[t('inputAmount')] = t('moreThanInventory');
            }
        }
        if(this.props.auth.user.binFilter === true){
            if(this.state.inputBin == null || this.state.inputBin === '')
                errors[t('inputBin')] = t('required');
        }
        if(this.state.inputUOM == null || this.state.inputUOM === '')
            errors[t('inputUOM')] = t('required');
        if(this.state.inputItemSel != null && this.state.inputItemSel.lotTracked === true){
            if(this.state.inputContainer == null || this.state.inputContainer === '')
                errors[t('inputContainer')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let inputLines = this.state.inputLines;
        let existing = inputLines.find(x => x.item === this.state.inputItem);
        if(existing != null){
            existing.amount = parseFloat(existing.amount) + parseFloat(this.state.inputAmount);
            existing.count = existing.count != null && this.state.inputCount != null  && this.state.inputCount !== '' ? parseInt(existing.count) + parseInt(this.state.inputCount) :
                this.state.inputCount != null && this.state.inputCount !== '' ? this.state.inputCount : existing.count;
            let line = {
                amount: this.state.inputAmount,
                count: this.state.inputCount !== '' ? this.state.inputCount : null,
                container: this.state.inputContainer,
                containerSel: this.state.inputContainerSel,
                bin: this.state.inputBin !== '' ? this.state.inputBin : null,
                binSel: this.state.inputBinSel
            }
            existing.lines.push(line);
        } else {
            inputLines.push({
                item: this.state.inputItem,
                itemSel: this.state.inputSel,
                amount: this.state.inputAmount,
                count: this.state.inputCount !== '' ? this.state.inputCount : null,
                uom: this.state.inputUOM,
                uomSel: this.state.inputUOMSel,
                containers: this.state.inputContainers,
                lines: [{
                    amount: this.state.inputAmount,
                    count: this.state.inputCount !== '' ? this.state.inputCount : null,
                    bin: this.state.inputBin !== '' ? this.state.inputBin : null,
                    binSel: this.state.inputBinSel,
                    container: this.state.inputContainer,
                    containerSel: this.state.inputContainerSel
                }]
            })
        }
        this.setState({
            inputLines: inputLines,
            inputItem: '',
            inputSel: null,
            inputAmount: '',
            inputUOM: '',
            inputUOMSel: null,
            inputLot: '',
            inputLotSel: null,
            inputLots: [],
            inputCount: '',
            inputContainer: '',
            inputContainerSel: null,
            inputContainers: [],
            inputBin: '',
            inputBinSel: null,
            errors: {}
        })
    };

    addInputLine = (i) => {
        let inputLines = this.state.inputLines;
        let input = inputLines[i];
        if(input.lines == null){
            input.lines = [];
        }
        input.lines.push({
            container: '',
            containerSel: null,
            bin: '',
            binSel: null,
            lot: '',
            lotSel: null,
            amount: '',
            count: ''
        });
        this.setState({inputLines: inputLines});

    };

    addOutputLine = (i) => {
        let lines = this.state.outputLines;
        let row = lines[i];
        if(row.lines == null){
            row.lines = [];
        }
        row.lines.push({
            container: '',
            containerSel: null,
            lot: '',
            lotSel: null,
            amount: '',
            count: '',
            discreteWeight: '',
            bin: '',
            binSel: null,
            totalWeight: '',
            tareWeight: '',
            customLot: '',
            containerType: '',
            containerTypeSel: null,
            containers: []
        });
        this.setState({outputLines: lines});

    }

    addOutput = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.newOutputItem == null || this.state.newOutputItem === '')
            errors[t('newOutputItem')] = t('required');
        if(this.state.newOutputAmount == null || this.state.newOutputAmount === '')
            errors[t('newOutputItem')] = t('required');
        if(this.state.newOutputUOM == null || this.state.newOutputUOM === '')
            errors[t('newOutputUOM')] = t('required');
        if(this.state.newOutputItemSel != null && this.state.newOutputItemSel.category !== this.props.auth.constants.itemCategories.caExcise){
            if(this.state.outputBin == null || this.state.outputBin === '')
                errors[t('outputBin')] = t('required');
        }
        if(this.state.newOutputItemSel.discreteWeight ){
            if(this.state.newOutputTotalWeight == null || this.state.newOutputTotalWeight === '')
                errors[t('newOutputTotalWeight')] = t('required');
        }
        if(this.state.newOutputItemSel != null && this.state.newOutputItemSel.customNumber === true && this.state.outputLotSel == null){
            if(this.state.customLot == null || this.state.customLot === ''){
                errors[t('customLot')] = t('required');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let outputLines = this.state.outputLines;
        let existing = outputLines.find(x => x.item === this.state.newOutputItem);
        if(existing != null){
            existing.amount = existing.amount != null && this.state.newOutputAmount != null && this.state.newOutputAmount !== '' ?
                parseFloat(existing.amount) + parseFloat(this.state.newOutputAmount) : existing.amount == null ? this.state.newOutputAmount : existing.amount;
            existing.totalWeight = existing.totalWeight != null && this.state.newOutputTotalWeight != null && this.state.newOutputTotalWeight !== '' ?
                parseFloat(existing.totalWeight) + parseFloat(this.state.newOutputTotalWeight) : existing.totalWeight == null ? this.state.newOutputTotalWeight : existing.totalWeight;
            existing.count = existing.count != null && this.state.newOutputCount != null  && this.state.newOutputCount !== '' ? parseInt(existing.count) + parseInt(this.state.newOutputCount) : existing.count;
            existing.lines.push({
                amount: this.state.newOutputAmount,
                bin: this.state.outputBin,
                binSel: this.state.outputBinSel,
                totalWeight: this.state.newOutputTotalWeight,
                containerType: this.state.containerType,
                containerTypeSel: this.state.containerTypeSel,
                customLot: this.state.customLot,
                tareWeight: this.state.tareWeight,
                totalUOM: this.state.newOutputTotalWeightUOM !== '' ? this.state.newOutputTotalWeightUOM : null,
                totalUOMSel: this.state.newOutputTotalWeightUOMSel,
                lot: this.state.outputLot,
                lotSel: this.state.outputLotSel,
                container: this.state.outputContainer,
                containerSel: this.state.outputContainerSel,
                containers: this.state.outputContainers,
                newLot: this.state.newLot,
                count: this.state.newOutputCount
            })
        } else {
            outputLines.push({
                item: this.state.newOutputItem,
                itemSel: this.state.newOutputItemSel,
                uom: this.state.newOutputUOM,
                uomSel: this.state.newOutputUOMSel,
                discreteWeightUOM: this.state.g,
                discreteWeightUOMSel: this.state.gSel,
                lots: this.state.outputLots,
                count: this.state.newOutputCount !== '' && this.state.newOutputCount != null ? this.state.newOutputCount : null,
                lines: [{
                    amount: this.state.newOutputAmount,
                    bin: this.state.outputBin,
                    binSel: this.state.outputBinSel,
                    totalWeight: this.state.newOutputTotalWeight,
                    containerType: this.state.containerType,
                    containerTypeSel: this.state.containerTypeSel,
                    customLot: this.state.customLot,
                    tareWeight: this.state.tareWeight,
                    totalUOM: this.state.g,
                    totalUOMSel: this.state.gSel,
                    lot: this.state.outputLot,
                    lotSel: this.state.outputLotSel,
                    container: this.state.outputContainer,
                    containerSel: this.state.outputContainerSel,
                    count: this.state.newOutputCount !== '' && this.state.newOutputCount != null ? this.state.newOutputCount : null,
                    newLot: this.state.newLot
                }]
            });
        }
        let quantity = this.state.quantity;
        if(outputLines.length > 0) {
            let total = 0;
            outputLines.forEach(output => {
                if(output.item === this.state.outputItem) {
                    output.lines.forEach(row => {
                        total += Number(row.amount);
                    })
                }
            })
            quantity = total;
        }

        this.setState({
            outputLines: outputLines,
            newOutputItem: '',
            newOutputItemSel: null,
            newOutputAmount: 0,
            newOutputUOM: '',
            newOutputUOMSel: null,
            outputBin: '',
            outputBinSel: null,
            errors: {},
            quantity: quantity,
            newOutputDiscrete: '',
            newOutputDiscreteUOM: '',
            newOutputDiscreteUOMSel: null,
            newOutputTotalWeight: '',
            newOutputTotalWeightUOM: '',
            newOutputTotalWeightUOMSel: null,
            containerType: '',
            containerTypeSel: null,
            customLot: '',
            tareWeight: '',
            outputContainers: [],
            outputLots: [],
            outputLot: '',
            outputLotSel: null,
            outputContainer: '',
            outputContainerSel: null,
        });
    };

    changeOutputItem = async (e, newValue) => {
        if(newValue != null){
            let itemLots = this.state.itemLots;
            let lots = [];
            if(itemLots.length > 0){
                let itemRow = itemLots.find(x => x.item === newValue.value);
                if(itemRow != null){
                    lots = itemRow.values;
                }
            }
            let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
            this.setState({
                newOutputItem: newValue.value,
                newOutputItemSel: newValue,
                newOutputUOM: newValue.uom,
                newOutputUOMSel: uomSel,
                outputLots: lots,
                outputContainers: [],
                outputContainer: '',
                outputContainerSel: null,
                customLot: '',
                newLot: newValue.customNumber === true  && lots.length === 0 ? true : false
            });
        } else {
            this.setState({
                newOutputItem: '',
                newOutputItemSel: null,
                newOutputAmount: '',
                newOutputUOMSel: null,
                newOutputUOM: '',
                newOutputDiscrete: '',
                newOutputDiscreteUOM: '',
                newOutputDiscreteUOMSel: null,
                newOutputTotalWeight: '',
                newOutputTotalWeightUOM: '',
                newOutputTotalWeightUOMSel: null,
                outputLots: [],
                outputContainers: [],
                newLot: false,
                customLot: '',
                outputContainer: '',
                outputContainerSel: null
            });
        }
    };

    changeOutputNumber = (name, index) => (e) => {
        let outputs = this.state.outputs;
        let output = outputs[index];
        let value = e.target.value;
        if(isNaN(value))
            return;
        output[name] = value;
        let quantity = this.state.quantity;
        if(output.item === this.state.outputItem) {
            let total = 0
            outputs.forEach(row => {
                if(row.item === this.state.outputItem)
                    total += Number(row.amount);
            })
            quantity = total;
        }
        this.setState({outputs: outputs, quantity: quantity});
    };

    changeOutputValue = (name, index) => (e) => {
        let outputs = this.state.outputs;
        let output = outputs[index];
        output[name] = e.target.value;
        this.setState({outputs: outputs});
    }

    changeOutputAuto = (name, data, index) => (e, newValue) => {
        let outputs = this.state.outputs;
        let output = outputs[index];
        output[name] = newValue;
        output[data] = newValue != null ? newValue.value : null;
        this.setState({
            outputs: outputs
        });
    };

    deleteOutput = (index) => (e) => {
        let outputLines = this.state.outputLines;
        outputLines.splice(index, 1);
        let quantity = this.state.quantity;
        if(outputLines.length > 0) {
            let total = 0
            outputLines.forEach(output => {
                if(output.item === this.state.outputItem){
                    for(let line of output.lines){
                        total += Number(line.amount);
                    }
                }
            })
            quantity = total;
        }
        this.setState({outputLines: outputLines, quantity: quantity});
    };

    toggleDialog = () => {
        this.setState({ dialogOpen: !this.state.dialogOpen });
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e));
    }

    _deleteClick = async (e) => {
        if(this.state._id != null && this.state._id !== ''){
            const t = this.props.t;
            try {
                let result = await axios.delete('/api/productionOrder/' + this.state._id);
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                if(this.state.goBack === -1)
                    this.props.history.goBack();
                else
                    this.props.history.go(this.state.goBack);
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.setState({enableSave: true});
            if(this.state.goBack === -1)
                this.props.history.goBack();
            else
                this.props.history.go(this.state.goBack);
        }
    };

    toggleAddLine = () => {
        this.setState({showLine: !this.state.showLine}, () => {
            if(!this.state.showLine){
                this.clearLine();
            }
        });
    }

    saveLine = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._saveLine(e))
    }

    _saveLine = async (e) => {
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.seq == null || state.seq === '')
            errors[t('seq')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let equips = [];
        if(state.machineType != null && state.machineType !== ''){
            equips.push({machineType: state.machineType});
        } else if(state.lineEquipments != null && state.lineEquipments.length > 0){
            for(let row of state.lineEquipments){
                equips.push({equipment: row});
            }
        }
        let data = {
            _id: state._id,
            room: state.room,
            equipments: equips,
            name: state.name,
            description: state.lineDescription,
            sops: state.sops,
            runEffort: state.runEffort,
            line_id: state.line_id,
            seq: state.seq
        }
        try {
            let result = await axios.post('/api/productionorderaddline', data);
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            let lines = this.setupLines(result.data.lines);
            this.setState({lines: lines, enableSave: true});
            this.clearLine();
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    deleteLine = (index) => (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteLine(index, e));
    };

    _deleteLine = async (index, e) => {
        const t = this.props.t;
        const constants = this.props.auth.constants;
        let lines = this.state.lines;
        let line = lines[index];
        if(line.activity != null && line.activity.status !== constants.statuses.new && line.activity.status !== constants.statuses.inprogress){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('recordInUse'), title: t('error'), severity: 'error'}});
            return;
        }
        let data = {
            _id: this.state._id,
            index: index
        }
        try {
            let result = await axios.post('/api/productionorderdeleteline', data);
            let lines = this.setupLines(result.data.lines);
            this.setState({lines: lines, enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    setupLines(lines){
        let criticalUnfinished = false;
        const constants = this.props.auth.constants;
        lines.forEach(row => {
            if(row.room != null){
                row.detail = row.room.name;
            } else {
                row.detail = row.descripion != null && row.description !== '' ? row.description : ''
            }
            row.locked = criticalUnfinished;
            if(row.critical === true && (row.status === null || row.status._id === constants.statuses.new || row.status._id === constants.statuses.inprogress ||
                row.status._id === constants.statuses.complete)){
                criticalUnfinished = true;
            }
        });
        return lines;
    }

    editLine(index){
        let line = this.state.lines[index];
        let sopsSel = [];
        if(line.sops != null && line.sops.length > 0){
            line.sops.forEach(row => {
                let val = this.state.sopList.find(x => x.value === row);
                sopsSel.push(val);
            });
        }
        let equips = line.equipments;
        let equipsSel = [];
        for(let row of equips){
            let val = line.equipmentOptions.find(x => x.value === row);
            equipsSel.push(val);
        }
        this.setState({
            machineType: line.machineType,
            machineTypeSel: this.state.machineTypes.find(x => x.value === line.machineType),
            room: line.room != null ? line.room._id : null,
            roomSel: line.room != null ? this.state.rooms.find(x => x.value === line.room._id) : null,
            equipments: equips,
            equipmentSel: equipsSel,
            equipmentOptions: line.equipmentOptions,
            name: line.name,
            lineDescription: line.description,
            sops: line.sops,
            sopsSel: sopsSel,
            runEffort: line.estimatedEffort,
            showLine: true,
            line_id: line._id,
            seq: line.seq
        })
    };

    async clearLine(){
        let equips = await axios.get('/api/equipmentselect', {params: {notAll: true}});
        this.setState({
            lineType: '',
            lineTypeSel: null,
            room: '',
            roomSel: null,
            equipment: '',
            equipmentSel: null,
            workCenter: '',
            workCenterSel: null,
            lineDescription: '',
            runEffort: '',
            showLine: false,
            sops: [],
            sopsSel: [],
            line_id: '',
            errors: {},
            equipmentOptions: equips.data
        })
    }

    changeSubLineNumber = (name, i, j) => (e) => {
        let contents = this.state.inputLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        if(isNaN(value))
            return;
        row[name] = value;
        line = this.calculateLineTotals(line);
        this.setState({inputLines: contents});
    };

    changeSubLineValue = (name, i, j) => (e) => {
        let contents = this.state.inputLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        row[name] = value;
        this.setState({inputLines: contents});
    };

    changeSubLineAuto = (name, data, i, j) => async (e, newValue) => {
        let contents = this.state.inputLines;
        let line = contents[i];
        let row = line.lines[j];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : '';
        let hasCount = false;
        if(data === 'bin' && newValue != null){
            let binConts = await axios.post('/api/itemcontainers', {items: [line.item], bin: newValue.value, available: true, consume: true});
            line.containers = binConts.data;

        }
        this.setState({inputLines: contents, inputHasCount: hasCount});
    };

    changeOutLineNumber = (name, i, j) => (e) => {
        let contents = this.state.outputLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        if(isNaN(value))
            return;
        row[name] = value;
        line = this.calculateLineTotals(line);
        this.setState({outputLines: contents});
    };

    changeOutLineValue = (name, i, j) => (e) => {
        let contents = this.state.outputLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        row[name] = value;
        this.setState({outputLines: contents});
    };

    changeOutLineBool = (name, i, j) => (e) => {
        let outputs = this.state.outputLines;
        let output = outputs[i];
        let row = output.lines[j]
        row[name] = !row[name];
        if(name === 'newLot' && row[name] === true){
            row.lot = '';
            row.lotSel = null;
            row.container = '';
            row.containerSel = null;
        }
        this.setState({outputLines: outputs});
    };

    changeOutLineAuto = (name, data, i, j) => (e, newValue) => {
        let contents = this.state.outputLines;
        let line = contents[i];
        let row = line.lines[j];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : '';
        if(data === 'lot' && newValue != null){
            let itemLots = this.state.itemLots;
            let itemRow = itemLots.find(x => x.item === line.item);
            if(itemRow != null){
                let lotRow = itemRow.lots.find(x => x.lot === newValue.value);
                if(lotRow != null){
                    row.containers = lotRow.containers;
                }
            }
        } else if (data === 'lot' && newValue == null){
            row.containers = [];
            row.containerSel = null;
            row.container = '';
        }
        this.setState({outputLines: contents});
    };

    calculateLineTotals(line){
        let amount = 0.0;
        let count = 0;
        for(let row of line.lines){
            if(row.amount !== '')
                amount += parseFloat(row.amount);
            count += row.count != null && row.count !== '' ? parseInt(row.count) : 0;
        }
        line.amount = amount;
        line.count = count !== 0 ? count : null;
        return line;
    }

    deleteInputRow = (i, j) => (e) => {
        let contentLines = this.state.inputLines;
        if(i > -1 && i < contentLines.length){
            let line = contentLines[i];
            if(j > -1 && j < line.lines.length){
                line.lines.splice(j, 1);
                line = this.calculateLineTotals(line);
                this.setState({inputLines: contentLines});
            }
        }
    };

    deleteOutputRow = (i, j) => (e) => {
        let contentLines = this.state.outputLines;
        if(i > -1 && i < contentLines.length){
            let line = contentLines[i];
            if(j > -1 && j < line.lines.length){
                line.lines.splice(j, 1);
                line = this.calculateLineTotals(line);
                this.setState({outputLines: contentLines});
            }
        }
    };

    togglePrintViewer = () => {
        this.setState({printViewer: !this.state.printViewer});
    }

    calculateCannabisQuantity(){
        const constants = this.props.auth.constants;
        let inputs = this.state.inputLines;
        let outputs = this.state.outputLines;
        let inputTotal = 0;
        let outputTotal = 0;
        for(let input of inputs){
            for(let row of input.lines){
                if(input.itemSel.hcReportCategory == null){
                    continue;
                }
                let rowAmount = null;
                let caseQuantity = input.itemSel.caseQuantity != null ? input.itemSel.caseQuantity : 1;
                if(input.uom === constants.quantityUOMs.g && row.discreteWeight == null){
                    rowAmount = row.amount;
                } else {
                    if(input.itemSel.casedItem != null && input.itemSel.casedPackSize != null && input.itemSel.casedPackSize !== 0){
                        rowAmount = row.caseQuantity != null  ? row.amount * input.itemSel.casedPackSize * row.itemSel.caseQuantity : row.amount * input.itemSel.casedPackSize; 
                    } else if(input.itemSel.packSizeG != null && input.itemSel.packSizeG !== 0 && input.itemSel.caseQuantity != null && input.itemSel.caseQuantity !== 0){
                        rowAmount = row.amount * input.itemSel.packSizeG * input.itemSel.caseQuantity;
                    } else if(input.itemSel.packSizeG != null && input.itemSel.packSizeG !== 0){
                        rowAmount = row.amount * input.itemSel.packSizeG;
                    } else if (row.containerSel != null && row.containerSel.discreteWeight != null && row.containerSel.discreteWeight !== 0){
                        rowAmount = row.amount * row.containerSel.discreteWeight * caseQuantity;;
                    }
                }
                if(rowAmount != null){
                    inputTotal += rowAmount;
                }
            }
        }
        for(let row of outputs){
            if(row.itemSel.hcReportCategory == null){
                continue;
            }
            for(let line of row.lines){
                let rowAmount = null;
                if(line.totalWeight != null && line.totalWeight !== 0){
                    rowAmount = line.totalWeight;
                } else if(row.uom === constants.quantityUOMs.g){
                    rowAmount = line.amount;
                } else {
                    if(row.itemSel.casedItem != null && row.itemSel.casedPackSize != null && row.item.casedPackSize !== 0){
                        rowAmount = row.itemSel.casedPackSize != null ? line.amount * row.itemSel.casedPackSize * row.itemSel.caseQuantity : row.amount * row.itemSel.casedPackSize; 
                    } else if (row.itemSel.packSizeG != null && row.itemSel.caseQuantity != null){
                        rowAmount = line.amount * row.itemSel.packSizeG * row.itemSel.caseQuantity;
                    } else if(row.itemSel.packSizeG != null && row.itemSel.packSizeG !== 0){
                        rowAmount = line.amount * row.itemSel.packSizeG;
                    }
                }
                if(rowAmount != null){
                    outputTotal += rowAmount;
                }
            }   
        }
        this.setState({inputCannabisTotal: inputTotal, outputCannabisTotal: outputTotal});
        return {inputTotal: inputTotal, outputTotal: outputTotal};
    }

    toggleMore = () => {
        this.setState({showOutputMore: !this.state.showOutputMore});
    }

    acceptMore = () => {
        this.setState({showOutputMore: false, acceptOutputMore: true});
    }

    recalculateLoss = () => {
        if(this.state.status === this.props.auth.constants.productionOrderStatuses.finished || 
            this.state.status === this.props.auth.constants.productionOrderStatuses.reviewed || 
            this.state.status === this.props.auth.constants.productionOrderStatuses.complete){
            if(this.state.enableSave)
                this.setState({enableSave: false}, () => this._recalculateLoss());
        }
    }

    _recalculateLoss = async () => {
        const t = this.props.t;
        try {
            let result = await axios.post('/api/productionorderprocessingloss', {id: this.state._id});
            this.setState({enableSave: true});
            await this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    deleteSigCallback = async (type) => {
        try{
            let result = await axios.post('/api/productionordersigdelete', {type: type, id: this.state._id});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    render(){
        const locked = this.state.status === this.props.auth.constants.productionOrderStatuses.complete ||
                        this.state.status === this.props.auth.constants.productionOrderStatuses.erpFinished ||
                        this.state.status === this.props.auth.constants.productionOrderStatuses.finished ||
                        this.state.status === this.props.auth.constants.productionOrderStatuses.reviewed;
        const started = (this.state.status === this.props.auth.constants.productionOrderStatuses.released ||
                        this.status === this.props.auth.constants.productionOrderStatuses.erpFinished ||
                        this.state.status === this.props.auth.constants.productionOrderStatuses.finished ||
                        this.state.status === this.props.auth.constants.productionOrderStatuses.reviewed) ||
                        (this.state.productionBatch != null && this.state.productionBatch !== '') ||
                        (this.state.productionSchedule != null && this.state.productionSchedule !== '');
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const t = this.props.t;
        const constants = this.props.auth.constants;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        const internal = this.props.auth.user.internal;
        const custom = this.state.custom;
        const lockCustom = this.state.outputItem != null && this.state.outputItem !== '' ? true : false;
        const hasDevice = this.props.devices.hasRegisteredDevices;
        const preInventoryLabel = this.props.auth.user.preInventoryLabel;
        const micro = this.props.auth.user.micro;
        const security = this.state.security;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        fieldAccess={true}
                        scheduleClick={this.state.scheduleId != null && this.state.scheduleId !== '' ? this.toggleDialog : null}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        labelData={this.state.outputLabels}
                        labelAmount='multiple'
                        printViewClick={this.togglePrintViewer}
                        printViewTitle={t('productionOrder')}
                        enablePrintView={this.state.status === this.props.auth.constants.productionOrderStatuses.finished}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.productionOrder}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TabPanel key={"main"} value={this.state.tabKey} index={0}>
                            <Grid item container spacing={3}>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl data-cy="number">
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <FormControl data-cy="status">
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    {(!locked || fieldAccess) &&
                                        <TextField value={this.state.description} onChange={this.changeValue} name="description"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('description')} required
                                        error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}
                                        data-cy="description"/>
                                    }
                                    {!fieldAccess && locked &&
                                        <FormControl data-cy="descripion">
                                            <FormLabel>{t('description')}</FormLabel>
                                            {this.state.description}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess && !started &&
                                        <AutoCompleteField
                                            value={this.state.locSel}
                                            options={this.state.locations}
                                            onChange={this.changeAuto('locSel', 'location')}
                                            error={errors[t('location')] != null ? true : false}
                                            helperText={errors[t('location')]}
                                            label={t('location')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="location"
                                        />
                                    }
                                    {(!fieldAccess || started) &&
                                        <FormControl data-cy="location">
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locSel != null ? this.state.locSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                {fieldAccess && !started &&
                                    <Grid item xs={6} sm={3}>
                                        <CheckboxField
                                        checked={this.state.custom} onChange={this.changeBool('custom')}
                                        label={t('custom')} name="custom" disabled={!fieldAccess || lockCustom}
                                        data-cy="custom"
                                        />
                                    </Grid>
                                }
                                {(!fieldAccess || started) && this.state.custom &&
                                    <Grid item xs={6} sm={3}>
                                        <FormControl data-cy="custom">
                                            <FormLabel>{t('custom')}</FormLabel>
                                            {t('yes')}
                                        </FormControl>
                                    </Grid>
                                }
                                {!locked && !started &&
                                    <Grid item xs={9} sm={5}>
                                        <AutoCompleteField
                                            value={this.state.outputItemSel}
                                            options={this.state.custom !== true ? this.state.items : this.state.allItems}
                                            onChange={this.changeAuto('outputItemSel', 'outputItem')}
                                            error={errors[t('outputItem')] != null ? true : false}
                                            helperText={errors[t('outputItem')]}
                                            label={t('outputItem')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="outputItem"
                                        />
                                    </Grid>
                                }
                                {(locked || started) &&
                                    <Grid item xs={9} sm={5}>
                                        <FormControl data-cy="outputItem">
                                            <FormLabel>{t('outputItem')}</FormLabel>
                                            <Link to={'/item/' + this.state.outputItem}>{this.state.outputItemSel != null ? this.state.outputItemSel.label : ''}</Link>
                                        </FormControl>
                                    </Grid>
                                }
                                {!custom && this.state.routingSel == null && this.state.producedByList != null && this.state.producedByList.length > 0 &&
                                    <Grid item xs={10} sm={6}>
                                        <AutoCompleteField
                                            value={this.state.producedBySel}
                                            options={this.state.producedByList}
                                            onChange={this.changeAuto('producedBySel', 'producedBy')}
                                            error={errors[t('producedBy')] != null ? true : false}
                                            helperText={errors[t('producedBy')]}
                                            label={t('producedBy')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="producedBy"
                                        />
                                    </Grid>
                                }
                                {(this.state.status === constants.productionOrderStatuses.planned || this.state.status === constants.productionOrderStatuses.new ||
                                this.state.status === constants.productionOrderStatuses.inserted) &&
                                (this.state.productionBatch == null || this.state.productionBatch === '') &&
                                    <Grid item xs={4} sm={2}>
                                        <TextField type="number" value={this.state.plannedQuantity} onChange={this.changeNumber} name="plannedQuantity"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} required
                                        label={t('planned')} data-cy="plannedQuantity"
                                        error={errors[t('plannedQuantity')] != null ? true : false} helperText={errors[t('plannedQuantity')]}/>
                                    </Grid>
                                }
                                {this.state.status !== constants.productionOrderStatuses.inserted && this.state.status !== constants.productionOrderStatuses.new && this.state.status !== constants.productionOrderStatuses.planned &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('planned')}</FormLabel>
                                            <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={2}
                                                        value={this.state.plannedQuantity != null && this.state.plannedQuantity !== '' ? this.state.plannedQuantity : 0}/>
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.currentAmount != null &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('currentOutput')}</FormLabel>
                                            <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={2}
                                                    value={this.state.currentAmount}/>
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.routingSel != null &&
                                    <Aux>
                                        <Grid item xs={9} sm={5}>
                                            <FormControl>
                                                <FormLabel>{t('routing')}</FormLabel>
                                                <Link to={'/routing/' + this.state.routing}>{this.state.routingSel != null ? this.state.routingSel.label : ''}</Link>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={9} sm={5}>
                                            <FormControl>
                                                <FormLabel>{t('productionBom')}</FormLabel>
                                                <Link to={'/bom/' + this.state.productionBom}>{this.state.bomSel != null ? this.state.bomSel.label : ''}</Link>
                                            </FormControl>
                                        </Grid>
                                    </Aux>
                                }
                                {this.state.status !== constants.productionOrderStatuses.inserted && this.state.status !== constants.productionOrderStatuses.released &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('outputQuantity')+(this.state.quantityUOMSel != null && this.state.quantityUOMSel !== '' ? '('+this.state.quantityUOMSel.label+')' : '')}</FormLabel>
                                            <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                        value={this.state.quantity != null && this.state.quantity !== '' ? this.state.quantity : 0}
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <DateSelect
                                            onChange={this.dateChange('plannedStart')}
                                            value={this.state.plannedStart}
                                            helperText={errors[t('plannedStart')]}
                                            error={errors[t('plannedStart')] != null ? true : false}
                                            label={t('plannedStart')}
                                            disabled={!fieldAccess}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                            data-cy="plannedStart"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy="plannedStart">
                                            <FormLabel>{t('plannedStart')}</FormLabel>
                                            <DateDisplay value={this.state.plannedStart} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <DateSelect
                                            onChange={this.dateChange('startDate')}
                                            value={this.state.startDate}
                                            helperText={errors[t('startDate')]}
                                            error={errors[t('startDate')] != null ? true : false}
                                            label={t('startDate')}
                                            disabled={!fieldAccess}
                                            format={this.props.auth.user.dateFormat}
                                            data-cy="startDate"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy="startDate">
                                            <FormLabel>{t('startDate')}</FormLabel>
                                            <DateDisplay value={this.state.startDate} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <DateSelect
                                            onChange={this.dateChange('dueDate')}
                                            value={this.state.dueDate}
                                            helperText={errors[t('dueDate')]}
                                            error={errors[t('dueDate')] != null ? true : false}
                                            label={t('dueDate')}
                                            disabled={!fieldAccess}
                                            format={this.props.auth.user.dateFormat}
                                            data-cy="dueDate"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy="dueDate">
                                            <FormLabel>{t('dueDate')}</FormLabel>
                                            <DateDisplay value={this.state.dueDate} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                {!fieldAccess && this.state.endDate != null && this.state.endDate !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('endDate')}</FormLabel>
                                            <DateDisplay value={this.state.endDate} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    </Grid>
                                }
                                {fieldAccess &&
                                    <Grid item xs={4} sm={2}>
                                        <DateSelect
                                            onChange={this.dateChange('endDate')}
                                            value={this.state.endDate}
                                            helperText={errors[t('endDate')]}
                                            error={errors[t('endDate')] != null ? true : false}
                                            label={t('endDate')}
                                            disabled={!fieldAccess}
                                            format={this.props.auth.user.dateFormat}
                                            data-cy="endDate"
                                        />
                                    </Grid>
                                }
                                {this.state.productionBatch != null &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('batch')}</FormLabel>
                                            <Link to={'/productionbatch/' + this.state.productionBatch._id}>
                                                {this.state.productionBatch.customNumber != null && this.state.productionBatch.customNumber !== '' ?
                                                this.state.productionBatch.customNumber : this.state.productionBatch.number}
                                            </Link>
                                        </FormControl>
                                    </Grid>
                                }
                                {!this.state.custom &&
                                    <Grid item xs={6} sm={3}>
                                        {fieldAccess && !started &&
                                            <AutoCompleteField
                                                value={this.state.productionScheduleSel}
                                                options={this.state.productionSchedules}
                                                onChange={this.changeAuto('productionScheduleSel', 'productionSchedule')}
                                                error={errors[t('productionSchedule')] != null ? true : false}
                                                helperText={errors[t('productionSchedule')]}
                                                label={t('productionSchedule')}
                                                disabled={!fieldAccess}
                                                data-cy="productionSchedule"
                                            />
                                        }
                                        {(!fieldAccess || started) && this.state.productionScheduleSel != null &&
                                            <FormControl>
                                                <FormLabel>{t('productionSchedule')}</FormLabel>
                                                {this.state.productionScheduleSel != null &&
                                                    <Link to={'/productionschedule/' + this.state.productionScheduleSel.value}>
                                                        {this.state.productionScheduleSel.label}
                                                    </Link>
                                                }
                                            </FormControl>
                                        }
                                    </Grid>
                                }
                                {this.state.productionScheduleActivities != null &&
                                    this.state.productionScheduleActivities.length > 0 && !started &&
                                    <Grid item xs={4} sm={2}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                value={this.state.prodActSel}
                                                options={this.state.productionScheduleActivities}
                                                onChange={this.changeAuto('prodActSel', 'productionScheduleActivity')}
                                                error={errors[t('productionSchedule')] != null ? true : false}
                                                helperText={errors[t('productionSchedule')]}
                                                label={t('activity')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy="productionScheduleActivity"
                                            />
                                        }
                                        {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('activity')}</FormLabel>
                                            {this.state.prodActSel != null ? this.state.prodActSel.label : ''}
                                        </FormControl>
                                    }
                                    </Grid>
                                }
                            </Grid>
                            {this.state.hasForm === true &&
                                <Aux>
                                    <br/>
                                    {errors[t('form')] != null && errors[t('form')] !== '' &&
                                        <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                    }
                                    <Form renderer={renderer}
                                        defaultFields={this.state.form}
                                        onChange={this.formValueChanged}
                                        disabled={!fieldAccess}/>
                                </Aux>
                            }
                            {(this.state.status === this.props.auth.constants.productionOrderStatuses.complete ||
                                this.state.status === this.props.auth.constants.productionOrderStatuses.reviewed ||
                                this.state.status === this.props.auth.constants.productionOrderStatuses.finished) &&
                                <Aux>
                                    {((this.state.inputCost != null && this.state.inputCost !== '' && this.state.inputCost !== 0) ||
                                    (this.state.labourCost != null && this.state.labourCost !== '' && this.state.labourCost !== 0)) && !micro &&
                                        <Aux>
                                            <br/>
                                            <SectionTitle title={t('costs')}/>
                                            <Grid container spacing={3}>
                                                {this.state.inputCost != null && this.state.inputCost !== '' &&
                                                    <Grid item xs={4} sm={2}>
                                                        <FormControl>
                                                            <FormLabel>{t('costItems')}</FormLabel>
                                                            <NumericFormat displayType={'text'} value={this.state.inputCost} thousandSeparator={true}
                                                            decimalScale={2} prefix="$"/>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {this.state.labourCost != null && this.state.labourCost !== '' &&
                                                    <Grid item xs={4} sm={2}>
                                                        <FormControl>
                                                            <FormLabel>{t('labour')}</FormLabel>
                                                            <NumericFormat displayType={'text'} value={this.state.labourCost} thousandSeparator={true}
                                                            decimalScale={2} prefix="$"/>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {this.state.estimatedEffortCost != null && this.state.estimatedEffortCost !== '' &&
                                                    <Grid item xs={4} sm={2}>
                                                        <FormControl>
                                                            <FormLabel>{t('estimatedLabour')}</FormLabel>
                                                            <NumericFormat displayType={'text'} value={this.state.estimatedEffortCost} thousandSeparator={true}
                                                            decimalScale={2} prefix="$"/>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {this.state.estimatedInputCost != null && this.state.estimatedInputCost !== '' &&
                                                    <Grid item xs={4} sm={2}>
                                                        <FormControl>
                                                            <FormLabel>{t('estimatedInputs')}</FormLabel>
                                                            <NumericFormat displayType={'text'} value={this.state.estimatedInputCost} thousandSeparator={true}
                                                            decimalScale={2} prefix="$"/>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Aux>
                                    }
                                </Aux>
                            }
                            <br/>
                            <Grid container spacing={3}>
                                <Grid item sm={11}>
                                    <AutoCompleteField
                                        multiple={true} disabled={!fieldAccess}
                                        value={this.state.usersSel}
                                        options={fieldAccess && !internal ? this.state.activeUsers : this.state.userList}
                                        onChange={this.changeMulti('usersSel', 'users')}
                                        error={errors[t('users')] != null ? true : false}
                                        helperText={errors[t('users')]}
                                        label={t('users')}
                                        data-cy="users"
                                    />
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    {fieldAccess &&
                                        <TextField value={this.state.notes} onChange={this.changeValue} name="notes"
                                        multiline={true} maxRows="20" data-cy="notes"
                                        size="medium" fullWidth={true} label={t('notes')}
                                        error={errors[t('notes')] != null ? true : false} helperText={errors[t('notes')]}/>
                                    }
                                    {!fieldAccess && this.state.notes != null && this.state.notes !== '' &&
                                        <FormControl>
                                            <FormLabel>{t('notes')}</FormLabel>
                                            {this.state.notes}
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            <br/>
                            {((this.state.lines != null && this.state.lines.length > 0) || fieldAccess) &&
                                <Grid container spacing={3}>
                                    <Grid item sx={{ width: '100%' }}>
                                        <SectionTitle title={t('activities')} addTitle={t('addActivity')} 
                                                      add={fieldAccess ? this.toggleAddLine : null} security={fieldAccess && !locked} 
                                                      data-cy='addActivity' />
                                    </Grid>
                                </Grid>
                            }
                            {this.state.lines != null && this.state.lines.length > 0 &&
                                <Aux>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow key="activityLinehead">
                                                    <TableCell><FormLabel>{t('seq')}</FormLabel></TableCell>
                                                    <TableCell><FormLabel>{t('name')}</FormLabel></TableCell>
                                                    <TableCell><FormLabel>{t('activity')}</FormLabel></TableCell>
                                                    <TableCell><FormLabel>{t('status')}</FormLabel></TableCell>
                                                    <TableCell><FormLabel>{t('effort')}</FormLabel></TableCell>
                                                    {this.state.estimateEffort &&
                                                        <TableCell><FormLabel>{t('estEffort')}</FormLabel></TableCell>
                                                    }
                                                    <TableCell sx={{ display: fieldAccess ? 'table-cell' : 'none'}} />
                                                    <TableCell sx={{ display: fieldAccess ? 'table-cell' : 'none'}} />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.lines.map((row, i) =>
                                                    <TableRow key={'activities' + i}>
                                                        <TableCell>{row.sequence}</TableCell>
                                                        <TableCell>{row.description != null ? row.name + ' / ' + row.description : row.name}</TableCell>
                                                        <TableCell>
                                                            {row.activity != null && !row.locked &&
                                                                <Link to={'/poactivity/' + row.activity._id}>{row.activity.number}</Link>
                                                            }
                                                            {row.activity != null && row.locked &&
                                                                row.activity.number
                                                            }
                                                            {row.activity == null && !row.locked && (this.state.status === this.props.auth.constants.productionOrderStatuses.released ||
                                                            this.state.status === this.props.auth.constants.productionOrderStatuses.inprogress) &&
                                                                <Button variant="outlined" color="primary" size="sm" onClick={() => this.addActivity(i)} data-cy={"addActivity" + i}>
                                                                    {t('add')}
                                                                </Button>
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.status != null && row.status !== '' ? row.status.label : ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.activity != null && row.activity.effort != null ? row.activity.effort : ''}
                                                        </TableCell>
                                                        {this.state.estimateEffort &&
                                                            <TableCell>
                                                                {row.estimatedEffort != null ? row.estimatedEffort : ''}
                                                            </TableCell>
                                                        }
                                                        {((row.activity == null || row.activity === '') || (row.activity != null && row.activity !== '' &&
                                                        (row.activity.status === constants.statuses.new || row.activity.status === constants.statuses.inprogress)))
                                                        && fieldAccess && this.state.released && !row.critical === true &&
                                                            <TableCell>
                                                                <IconButton aria-label="delete line" disabled={!fieldAccess} onClick={this.deleteLine(i)} data-cy={"deleteLine" + i}>
                                                                    <RemoveCircleTwoToneIcon  />
                                                                </IconButton>
                                                            </TableCell>
                                                        }
                                                        {(row.activity == null || row.activity === '') && fieldAccess && row.added === true &&
                                                            <TableCell>
                                                                <IconButton aria-label="edit line" disabled={!fieldAccess} onClick={() => this.editLine(i)} data-cy={"editLine" + i}>
                                                                    <EditIcon  />
                                                                </IconButton>
                                                            </TableCell>
                                                        }
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Aux>
                            }
                            {this.state.released &&
                                <Aux>
                                    <SectionTitle title={t('inputs')}/>
                                    {this.state.inputLines != null && this.state.inputLines.length > 0 && this.state.inputLines.map((row, i) =>
                                        <Box borderColor="primary.main" {...defaultProps} key={'inputLine' + i} component={'div'}>
                                            {row.lines.length > 1 &&
                                                <Aux>
                                                    <Grid container spacing={3}>
                                                        <Grid item sx={{width: 350}}>
                                                            <FormControl>
                                                                <FormLabel>{t('item')}</FormLabel>
                                                                <Link to={'/item/' + row.item}>{row.itemSel != null ? row.itemSel.label : ''}</Link>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sx={{width: 150}}>
                                                            <FormControl>
                                                                <FormLabel>{t('quantity')}</FormLabel>
                                                                <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                    value={row.amount != null && row.amount !== '' ? row.amount : 0}
                                                                    suffix={row.uomSel != null ? row.uomSel.label : ''}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        {row.estimatedAmount != null && row.estimatedAmount !== 0 && row.estimatedAmount !== '0' &&
                                                            <Grid item sx={{width: 70}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('estimate')}</FormLabel>
                                                                    <NumericFormat displayType={'text'} value={row.estimatedAmount} thousandSeparator={true} decimalScale={3}/>
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        {row.count != null && row.count !== 0 &&
                                                            <Grid item sx={{width: 100}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('count')}</FormLabel>
                                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                        value={row.count != null && row.count !== '' ? row.count : 0}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        {this.props.auth.user.binFilter === true && row.binSel != null &&
                                                            <Grid item style={{width: 170}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('bin')}</FormLabel>
                                                                    {row.binSel.label}
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        {fieldAccess &&
                                                            <Grid item sx={{width: 50}}>
                                                                <IconButton aria-label="delete line" onClick={this.deleteInput(i)}>
                                                                    <RemoveCircleTwoToneIcon  />
                                                                </IconButton>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                    {row.lines != null && row.lines.length > 0 &&
                                                        <Grid container spacing={3} key={'ihead' + i}>
                                                            <Grid item sx={{width: 110}}>
                                                                <FormLabel>{t('quantity')}</FormLabel>
                                                            </Grid>
                                                            {row.itemSel.hasCount === true &&
                                                                <Grid item sx={{width: 100}}>
                                                                    <FormLabel>{t('count')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {row.itemSel.lotTracked && this.props.auth.user.binFilter === true &&
                                                                <Grid item style={{width: 170}}>
                                                                    <FormLabel>{t('bin')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {row.itemSel.lotTracked &&
                                                                <Aux>
                                                                    <Grid item sx={{width: 150}}>
                                                                        <FormLabel>{t('container')}</FormLabel>
                                                                    </Grid>
                                                                    {!fieldAccess &&
                                                                        <Grid item sx={{width: 140}}>
                                                                            <FormLabel>{t('lot')}</FormLabel>
                                                                        </Grid>
                                                                    }
                                                                </Aux>
                                                            }
                                                            {fieldAccess &&
                                                                <Grid item sx={{width: 50}}>
                                                                    <IconButton aria-label="add line" onClick={() => this.addInputLine(i)}>
                                                                        <AddBoxTwoToneIcon  />
                                                                    </IconButton>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    }
                                                    {row.lines != null && row.lines.length > 0 && row.lines.map((line, k) =>
                                                        <Aux key={'iline' + i + 'a' + k}>
                                                            {!fieldAccess &&
                                                                <Aux>
                                                                    <Grid container spacing={3} sx={{pb: 2}}>
                                                                        <Grid item sx={{width: 110, textAlign: 'center'}} >
                                                                            <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                                value={line.amount != null && line.amount !== '' ? line.amount : 0}
                                                                            />
                                                                        </Grid>
                                                                        {row.itemSel.hasCount === true &&
                                                                            <Grid item sx={{width: 100, textAlign: 'center'}}>
                                                                                <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                                    value={line.count != null && line.count !== '' ? line.count : 0}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.lotTracked && this.props.auth.user.binFilter === true &&
                                                                            <Grid item style={{width: 170}}>
                                                                                {line.binSel != null ? 
                                                                                    <Link to={'/bin/' + line.bin}>{line.binSel.label}</Link> : ''
                                                                                }
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.lotTracked &&
                                                                            <Aux>
                                                                                <Grid item sx={{width: 150}}>
                                                                                    {line.containerSel != null &&
                                                                                        <Link to={'/container/' + line.containerSel.value}>{line.containerSel.label}</Link>
                                                                                    }
                                                                                </Grid>
                                                                                <Grid item sx={{width: 140}}>
                                                                                    {line.lot!= null && row.lot !== '' &&
                                                                                        <Link to={'/lot/' + line.lot._id}>{line.lot.number}</Link>
                                                                                    }
                                                                                </Grid>
                                                                            </Aux>
                                                                        }
                                                                    </Grid>
                                                                </Aux>
                                                            }
                                                            {fieldAccess &&
                                                                <Aux key={'iline' + i + 'a' + k}>
                                                                    <Grid container spacing={3} key={'ilinea' + i + 'a' + k}>
                                                                        <Grid item sx={{width: 110}}>
                                                                            <TextField type="number" value={line.amount} onChange={this.changeSubLineNumber('amount', i, k)} name={"inputAmount" + i + 'a' + k}
                                                                            size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"inputAmount" + i +'a' + k}
                                                                            error={errors[`${t('inputAmount')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('inputAmount')} (${t('line')} ${i}, ${k})`]}/>
                                                                        </Grid>
                                                                        {row.itemSel.hasCount &&
                                                                            <Grid item sx={{width: 100}}>
                                                                                <TextField type="number" value={line.count} onChange={this.changeSubLineNumber('count', i, k)} name={"inpuCount" + i + 'a' + k}
                                                                                size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"inputCount" + i +'a' + k}
                                                                                error={errors[`${t('inputCount')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('inputCount')} (${t('line')} ${i}, ${k})`]}/>
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.lotTracked && this.props.auth.user.binFilter === true &&
                                                                            <Grid item style={{width: 170}}>
                                                                                <AutoCompleteField
                                                                                    value={line.binSel}
                                                                                    options={this.state.bins}
                                                                                    onChange={this.changeSubLineAuto('binSel', 'bin', i, k)}
                                                                                    error={errors[`${t('inputBin')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                    helperText={errors[`${t('inputBin')} (${t('line')} ${i}, ${k})`]}
                                                                                    disabled={!fieldAccess}
                                                                                    required
                                                                                    data-cy={"inputCount" + i +'a' + k}
                                                                                    groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                                                    noneMessage={t('noRoomSelected')}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.lotTracked &&
                                                                            <Grid item sx={{width: 250}}>
                                                                                <AutoCompleteField
                                                                                    value={line.containerSel}
                                                                                    options={row.containers}
                                                                                    onChange={this.changeSubLineAuto('containerSel', 'container', i, k)}
                                                                                    error={errors[`${t('container')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                    helperText={errors[`${t('container')} (${t('line')} ${i}, ${k})`]}
                                                                                    disabled={!fieldAccess}
                                                                                    required
                                                                                    data-cy={"inputContainer" + i +'a' + k}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        <Grid item style={{sx: 50}}>
                                                                            <IconButton aria-label="delete line" onClick={this.deleteInputRow(i, k)} data-cy={"deleteInput" + i +'a' + k}>
                                                                                <RemoveCircleTwoToneIcon  />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Aux>
                                                            }
                                                        </Aux>
                                                    )}
                                                </Aux>
                                            }
                                            {row.lines.length < 2 &&
                                                <Grid key={"inputs" + i} container spacing={3}>
                                                    <Grid item style={{sx: 350}}>
                                                        <FormControl>
                                                            <FormLabel>{t('item')}</FormLabel>
                                                            <Link to={'/item/' + row.item}>{row.itemSel != null ? row.itemSel.label : ''}</Link>
                                                        </FormControl>
                                                    </Grid>
                                                    {row.itemSel != null && row.itemSel.lotTracked === true && !locked && this.props.auth.user.binFilter === true &&
                                                        <Grid item style={{width: 180}}>
                                                            <AutoCompleteField
                                                                value={row.lines[0].binSel}
                                                                options={this.state.bins}
                                                                onChange={this.changeSubLineAuto('binSel', 'bin', i, 0)}
                                                                error={errors[`${t('inputBin')} (${t('line')} ${i}, 0)`] != null ? true : false}
                                                                helperText={errors[`${t('inputBin')} (${t('line')} ${i}, 0)`]}
                                                                disabled={!fieldAccess}
                                                                required
                                                                label={t('bin')}
                                                                data-cy={"inputBin" + i}
                                                                groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                                noneMessage={t('noRoomSelected')}
                                                            />
                                                        </Grid>
                                                    }
                                                    {locked && row.lines[0].binSel != null && this.props.auth.user.binFilter === true &&
                                                        <Grid item style={{width: 180}}>
                                                            <FormControl>
                                                                <FormLabel>{t('bin')}</FormLabel>
                                                                {row.lines[0].binSel != null ? 
                                                                    <Link to={'/bin/' + row.lines[0].bin}>{row.lines[0].binSel.label}</Link> : ''
                                                                }
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                    {row.lines[0].lot != null && row.lines[0].lot._id != null &&
                                                        <Grid item sx={{width: 220}}>
                                                            <FormControl>
                                                                <FormLabel>{t('lot')}</FormLabel>
                                                                <Link to={'/lot/' + row.lines[0].lot._id}>{row.lines[0].lot.number}</Link>
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                    {row.itemSel != null && row.itemSel.lotTracked === true && !locked &&
                                                        <Grid item sx={{width: 250}}>
                                                            <AutoCompleteField
                                                                value={row.lines[0].containerSel}
                                                                options={row.containers}
                                                                onChange={this.changeSubLineAuto('containerSel', 'container', i, 0)}
                                                                error={errors[`${t('container')} (${t('line')} ${i}, 0)`] != null ? true : false}
                                                                helperText={errors[`${t('container')} (${t('line')} ${i}, 0)`]}
                                                                disabled={!fieldAccess}
                                                                required
                                                                label={t('container')}
                                                                data-cy={"inputContainer" + i}
                                                                groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                                noneMessage={t('noLotAssigned')}
                                                            />
                                                        </Grid>
                                                    }
                                                    {row.lines[0].containerSel != null && locked &&
                                                        <Grid item sx={{width: 250}}>
                                                            <FormControl>
                                                                <FormLabel>{t('container')}</FormLabel>
                                                                <Link to={'/container/' + row.lines[0].container}>{row.lines[0].containerSel != null ? row.lines[0].containerSel.label : ''}</Link>
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                    <Grid item sx={{width: 125}}>
                                                        {fieldAccess &&
                                                            <TextField type="number" value={row.lines[0].amount} onChange={this.changeSubLineNumber('amount', i, 0)} name={"inputAmount" + i + 'a' + 0}
                                                            size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"inputAmount" + i}
                                                            label={t('amount')+(row.uomSel != null && row.uomSel !== '' ? ' ('+row.uomSel.label+')' : '')}
                                                            error={errors[`${t('inputAmount')} (${t('line')} ${i}, 0)`] != null ? true : false} helperText={errors[`${t('inputAmount')} (${t('line')} ${i}, 0)`]}/>
                                                        }
                                                        {!fieldAccess &&
                                                            <FormControl>
                                                                <FormLabel>{t('amount')+(row.uomSel != null && row.uomSel !== '' ? ' ('+row.uomSel.label+')' : '')}</FormLabel>
                                                                <NumericFormat displayType={'text'} value={row.amount} thousandSeparator={true} decimalScale={3}/>
                                                            </FormControl>
                                                        }
                                                    </Grid>
                                                    {row.estimatedAmount != null && row.estimatedAmount !== 0 && row.estimatedAmount !== '0' &&
                                                        <Grid item sx={{width: 70}}>
                                                            <FormControl>
                                                                <FormLabel>{t('estimate')}</FormLabel>
                                                                <NumericFormat displayType={'text'} value={row.estimatedAmount} thousandSeparator={true} decimalScale={3}/>
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                    {this.state.inputHasCount &&
                                                        <Grid item sx={{width: 125}}>
                                                            {fieldAccess &&
                                                                <TextField type="number" value={row.lines[0].count} onChange={this.changeSubLineNumber('count', i, 0)} name={"inputCount" + i + 'a' + 0}
                                                                size="medium" fullWidth={true} disabled={!fieldAccess || !row.itemSel.hasCount}
                                                                label={t('count')+' (ea)'} data-cy={"inputCount" + i} required
                                                                error={errors[`${t('inputCount')} (${t('line')} ${i}, 0)`] != null ? true : false} helperText={errors[`${t('inputCount')} (${t('line')} ${i}, 0)`]}/>
                                                            }
                                                            {!fieldAccess &&
                                                                <FormControl>
                                                                    <FormLabel>{t('count')+' (ea)'}</FormLabel>
                                                                    <NumericFormat displayType={'text'} value={row.lines[0].count} thousandSeparator={true} decimalScale={0}/>
                                                                </FormControl>
                                                            }
                                                        </Grid>
                                                    }
                                                    {fieldAccess &&
                                                        <Grid item sx={{width: 50}}>
                                                            <IconButton aria-label="delete input" disabled={!fieldAccess} onClick={this.deleteInput(i)} data-cy={"deleteInput" + i}>
                                                                <RemoveCircleTwoToneIcon  />
                                                            </IconButton>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            }
                                        </Box>
                                    )}
                                    {this.state.released && fieldAccess &&
                                        <Box borderColor="primary.main" {...defaultProps} component={'div'}>
                                        <Grid container spacing={3}>
                                            <Grid item sx={{width: 350}}>
                                                <AutoCompleteField
                                                    value={this.state.inputSel}
                                                    options={this.state.allItems}
                                                    onChange={this.changeAuto('inputSel', 'inputItem')}
                                                    error={errors[t('inputItem')] != null ? true : false}
                                                    helperText={errors[t('inputItem')]}
                                                    disabled={!fieldAccess}
                                                    label={t('item')}
                                                    data-cy="inputItem"
                                                />
                                            </Grid>
                                            {this.state.inputSel != null && this.state.inputSel.lotTracked === true && this.props.auth.user.binFilter &&
                                                <Grid item style={{width: 180}}>
                                                    <AutoCompleteField
                                                        value={this.state.inputBinSel}
                                                        options={this.state.bins}
                                                        onChange={this.changeAuto('inputBinSel', 'inputBin')}
                                                        error={errors[t('inputBin')] != null ? true : false}
                                                        helperText={errors[t('inputBin')]}
                                                        disabled={!fieldAccess}
                                                        required
                                                        label={t('bin')}
                                                        data-cy="inputBin"
                                                        groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                        noneMessage={t('noRoomSelected')}
                                                    />
                                                </Grid>
                                            }
                                            {this.state.inputSel != null && this.state.inputSel.lotTracked === true &&
                                                <Grid item sx={{width: 250}}>
                                                    <AutoCompleteField
                                                        value={this.state.inputContainerSel}
                                                        options={this.state.inputContainers}
                                                        onChange={this.changeAuto('inputContainerSel', 'inputContainer')}
                                                        error={errors[t('inputContainer')] != null ? true : false}
                                                        helperText={errors[t('inputContainer')]}
                                                        disabled={!fieldAccess}
                                                        required
                                                        label={t('container')}
                                                        data-cy="inputContainer"
                                                        groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                        noneMessage={t('noLotAssigned')}
                                                    />
                                                </Grid>
                                            }
                                            <Grid item sx={{width: 125}}>
                                                <TextField type="number" value={this.state.inputAmount} onChange={this.changeNumber} name={"inputAmount"}
                                                size="medium" fullWidth={true} disabled={!fieldAccess} data-cy="inputAmount"
                                                label={t('amount')+(this.state.inputUOMSel != null && this.state.inputUOMSel !== '' ? ' ('+this.state.inputUOMSel.label+')' : '')}
                                                error={errors[t('inputAmount')] != null ? true : false} helperText={errors[t('inputAmount')]}/>
                                            </Grid>
                                            {this.state.inputHasCount &&
                                                <Grid item xs={2} sm={1}>
                                                    <TextField type="number" value={this.state.inputCount} onChange={this.changeNumber} name={"inputCount"}
                                                    size="medium" fullWidth={true} disabled={!fieldAccess || this.state.inputSel == null || !this.state.inputSel.hasCount}
                                                    label={t('count') + ' (ea)'} data-cy="inputCount"
                                                    error={errors[t('inputCount')] != null ? true : false} helperText={errors[t('inputCount')]}/>
                                                </Grid>
                                            }
                                            <Grid item xs={2} sm={1}>
                                                <IconButton aria-label="add input" disabled={!fieldAccess} onClick={this.addInput} data-cy="addInput">
                                                    <AddBoxTwoToneIcon  />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        </Box>
                                    }
                                </Aux>
                            }
                            {this.state.processingLoss != null &&
                                <Aux>
                                    <br/>
                                    <SectionTitle title={t('inventoryChange')}/>
                                    <Grid container spacing={3}>
                                        {this.state.processingLoss != null && this.state.processingLoss !== '' &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('processingLoss')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.processingLoss} thousandSeparator={true}
                                                        decimalScale={3}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.processingLossPercent != null && this.state.processingLossPercent !== '' &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('percent')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.processingLossPercent} thousandSeparator={true}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.inputCannabisTotal != null && this.state.inputCannabisTotal !== '' &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('inputCannabis')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.inputCannabisTotal} thousandSeparator={true}
                                                        decimalScale={3}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.outputCannabisTotal != null && this.state.outputCannabisTotal !== '' &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('outputCannabis')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.outputCannabisTotal} thousandSeparator={true}
                                                        decimalScale={3}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.availableCannabisTotal != null && this.state.availableCannabisTotal !== '' &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('availableTotal')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.availableCannabisTotal} thousandSeparator={true}
                                                        decimalScale={3}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.sampleTotal != null && this.state.sampleTotal !== '' &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('sampleTotal')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.sampleTotal} thousandSeparator={true}
                                                        decimalScale={3}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.wasteTotal != null && this.state.wasteTotal !== '' &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('wasteTotal')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.wasteTotal} thousandSeparator={true}
                                                        decimalScale={3}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.returnedTotal != null && this.state.returnedTotal !== '' &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('returnedTotal')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.returnedTotal} thousandSeparator={true}
                                                        decimalScale={3}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {(this.state.status === this.props.auth.constants.productionOrderStatuses.complete || 
                                        this.state.status === this.props.auth.constants.productionOrderStatuses.reviewed ||
                                        this.state.status === this.props.auth.constants.productionOrderStatuses.finished)  && security.update &&
                                            <Grid item sm={2}>
                                                <Button variant="outlined" size="small" color="primary" onClick={this.recalculateLoss} data-cy="lossCalc">
                                                    {t('calculate')}
                                                </Button>
                                            </Grid>
                                        }
                                    </Grid>
                                    <br/>
                                </Aux>
                            }
                            {this.state.released &&
                                <Aux>
                                    <SectionTitle title={t('outputs')}/>
                                    {this.state.outputLines != null && this.state.outputLines.length > 0 && this.state.outputLines.map((row, i) =>
                                        <Box borderColor="primary.main" {...defaultProps} key={'outputLine' + i} component={'div'}>
                                            {row.lines.length > 1 &&
                                                <Aux>
                                                    <Grid container spacing={3}>
                                                        <Grid item sx={{width: 350}}>
                                                            <FormControl>
                                                                <FormLabel>{t('item')}</FormLabel>
                                                                <Link to={'/item/' + row.item}>{row.itemSel != null ? row.itemSel.label : ''}</Link>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sx={{width: 110}}>
                                                            <FormControl>
                                                                <FormLabel>{t('quantity')}</FormLabel>
                                                                 <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                    value={row.amount != null && row.amount !== '' ? row.amount : 0}
                                                                    suffix={row.uomSel != null ? row.uomSel.label : ''}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        {row.count != null && row.count !== 0 &&
                                                            <Grid item sx={{width: 100}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('count')}</FormLabel>
                                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                        value={row.count != null && row.count !== '' ? row.count : 0}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        {row.totalWeight != null && row.totalWeight !== 0 && row.totalWeight !== '' &&
                                                            <Grid item sx={{width: 150}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('totalWeight')}</FormLabel>
                                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                        value={row.totalWeight != null && row.totalWeight !== '' ? row.totalWeight : 0}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        {fieldAccess &&
                                                            <Grid item style={{width: 50}}>
                                                                <IconButton aria-label="delete line" onClick={this.deleteOutput(i)}>
                                                                    <RemoveCircleTwoToneIcon  />
                                                                </IconButton>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                    {row.lines != null && row.lines.length > 0 && row.outputLength < 1199 &&
                                                        <Grid container spacing={3} key={'ohead' + i} sx={{ mt: 0.75 }}>
                                                            <Grid item sx={{width: {xs: 100, sm: 150, md: 130}, p: 0}}>
                                                                <FormLabel>{t('quantity') + '(' + row.uomSel.label + ')'}</FormLabel>
                                                            </Grid>
                                                            {(row.itemSel.hasCount === true || row.count != null) &&
                                                                <Grid item sx={{width: 100}}>
                                                                    <FormLabel>{t('count')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {row.itemSel?.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                                <Grid item sx={{width: 140, display: {xs: 'none', sm: 'inline-grid'}}}>
                                                                    <FormLabel>{t('bin')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {fieldAccess && row.itemSel != null && row.itemSel.lotTracked &&
                                                                <Grid item sx={{width: 60}}>
                                                                    <FormLabel>{t('newLot')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {row.itemSel.lotTracked && (row.itemSel.customNumber === true || (row.lots != null && row.lots.length > 0)) &&
                                                                <Grid item sx={{width: 180, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                    <FormLabel>{t('lot')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {row.itemSel.lotTracked && row.lots != null && row.lots.length > 0 &&
                                                                <Aux>
                                                                    <Grid item sx={{width: {xs: 210, sm: 180, md: 220}}}>
                                                                        <FormLabel>{t('container')}</FormLabel>
                                                                    </Grid>
                                                                </Aux>
                                                            }
                                                            {row.itemSel.discreteWeight === true &&
                                                                <Grid item sx={{width: 110, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                    <FormLabel>{t('totalWeightG')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {row.itemSel.tare === true &&
                                                                <Grid item sx={{width: 120, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                    <FormLabel>{t('tareWeight')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {row.itemSel.lotTracked &&
                                                                <Grid item sx={{width: {xs: 125, lg: 150}}}>
                                                                    <FormLabel>{t('containerType')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {fieldAccess &&
                                                                <Grid item sx={{width: 50}}>
                                                                    <IconButton aria-label="delete line" onClick={() => this.addOutputLine(i)}>
                                                                        <AddBoxTwoToneIcon  />
                                                                    </IconButton>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    }
                                                    {row.lines != null && row.lines.length > 0 && row.lines.map((line, k) =>
                                                        <Aux key={'ol' + i + 'a' + k}>
                                                            {!fieldAccess && row.outputLength < 1199 &&
                                                                <Grid container spacing={3} sx={{pb: 2}}>
                                                                    <Grid item sx={{width: {xs: 100, sm: 150, md: 130}, textAlign: 'left'}} >
                                                                        <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                            value={line.amount != null && line.amount !== '' ? line.amount : 0}
                                                                        />
                                                                    </Grid>
                                                                    {(row.itemSel.hasCount === true || row.count != null) &&
                                                                        <Grid item sx={{width: 100, textAlign: 'center'}}>
                                                                            <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                                value={line.count != null && line.count !== '' ? line.count : 0}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel?.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                                        <Grid item sx={{width: 140, display: {xs: 'none', sm: 'inline-grid'}}}>
                                                                            {line.binSel != null &&
                                                                                <Link to={'/bin/' + line.binSel.value}>{line.binSel.label}</Link>
                                                                            }
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked && !fieldAccess && line.lotSel != null &&
                                                                        <Aux>
                                                                            <Grid item sx={{width: 180, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                                {line.lotSel != null &&
                                                                                    <Link to={'/lot/' + line.lotSel.value}>{line.lotSel.label}</Link>
                                                                                }
                                                                            </Grid>
                                                                            <Grid item sx={{width: {xs: 210, sm: 180, md: 220}}}>
                                                                                {line.containerSel != null &&
                                                                                    <Grid container>
                                                                                        <Grid item>
                                                                                            {hasDevice &&
                                                                                                <LabelPrint
                                                                                                    containerData={this.state.outputLabels != null && this.state.outputLabels.length > 0 ?
                                                                                                                this.state.outputLabels.find(x => x.id === line.containerSel.value) : null}
                                                                                                    printAmount='single'
                                                                                                />
                                                                                            }
                                                                                        </Grid>
                                                                                        <Grid item sx={{width: {xs: 210, sm: 180, md: 220}}}>
                                                                                            {line.containerSel != null &&
                                                                                                <Link to={'/container/' + line.containerSel.value}>{line.containerSel.label}</Link>
                                                                                            }
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                }
                                                                            </Grid>
                                                                        </Aux>
                                                                    }
                                                                    {row.itemSel.lotTracked && line.lotSel == null && row.itemSel.customNumber === true &&
                                                                        <Aux>
                                                                            <Grid item sx={{width: 180, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                                {line.customLot != null &&
                                                                                    line.customLot
                                                                                }
                                                                            </Grid>
                                                                            <Grid item sx={{width: {xs: 210, sm: 180, md: 220}}}></Grid>
                                                                        </Aux>
                                                                    }
                                                                    {row.itemSel.discreteWeight &&
                                                                        <Grid item sx={{width: 110, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                            <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                                value={line.totalWeight != null && line.totalWeight !== '' ? line.totalWeight : 0}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.tare === true &&
                                                                        <Grid item sx={{width: 120, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                            <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                                value={line.tareWeight != null && line.tareWeight !== '' ? line.tareWeight : 0}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked &&
                                                                        <Grid item sx={{width: {xs: 125, lg: 150}}}>
                                                                            {line.containerTypeSel != null ? line.containerTypeSel.label : ''}
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            }
                                                            {!fieldAccess && row.outputLength >= 1199 &&
                                                                <Box borderColor="primary.main" {...defaultProps}  key={'outputLine' + i + 'o' + k} component={'div'}>
                                                                    <Grid container spacing={3} sx={{pb: 8}}>
                                                                        <Grid item sx={{width: 110}} align="center">
                                                                            <FormControl>
                                                                                <FormLabel>{t('quantity')}</FormLabel>
                                                                                <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                                value={line.amount != null && line.amount !== '' ? line.amount : 0} suffix={row.uomSel != null && row.uomSel.label != null ? row.uomSel.label : ''}
                                                                            />
                                                                            </FormControl>
                                                                        </Grid>
                                                                        {(row.itemSel.hasCount === true || row.count != null) &&
                                                                            <Grid item sx={{width: 100, display: { xs: 'none', lg: 'inline-grid'}}} align="center">
                                                                                <FormControl>
                                                                                    <FormLabel>{t('count')}</FormLabel>
                                                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                                        value={line.count != null && line.count !== '' ? line.count : 0}
                                                                                    />
                                                                                </FormControl>
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.lotTracked && !fieldAccess &&
                                                                            <Aux>
                                                                                <Grid item sx={{width: 140, display: { xs: 'none', md: 'inline-grid'}}}>
                                                                                    <FormControl>
                                                                                        <FormLabel>{t('lot')}</FormLabel>
                                                                                        {line.lotSel != null &&
                                                                                            <Link to={'/lot/' + line.lotSel.value}>{line.lotSel.label}</Link>
                                                                                        }
                                                                                    </FormControl>
                                                                                </Grid>
                                                                                <Grid item sx={{width: {xs: 210, sm: 180, md: 220}}}>
                                                                                    <FormControl>
                                                                                        <FormLabel sx={{ ml: 2}}>{t('container')}</FormLabel>
                                                                                        <Grid container>
                                                                                            <Grid item>
                                                                                            {hasDevice &&
                                                                                                <LabelPrint
                                                                                                    containerData={this.state.outputLabels != null && this.state.outputLabels.length > 0 ?
                                                                                                                   this.state.outputLabels.find(x => x.id === line.container) : null}
                                                                                                    printAmount='single'
                                                                                                />
                                                                                            }
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                {line.containerSel != null &&
                                                                                                    <Link to={'/container/' + line.containerSel.value}>{line.containerSel.label}</Link>
                                                                                                }
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            </Aux>
                                                                        }
                                                                        {row.itemSel.discreteWeight &&
                                                                            <Grid item sx={{width: 110, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                                <FormControl>
                                                                                    <FormLabel>{t('totalWeightG')}</FormLabel>
                                                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                                        value={line.totalWeight != null && line.totalWeight !== '' ? line.totalWeight : 0}
                                                                                    />
                                                                                </FormControl>
                                                                            </Grid>
                                                                        }
                                                                        {line.discreteWeight != null && line.discreteWeight !== '' &&
                                                                            <Grid item sx={{width: 150, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                                <FormControl>
                                                                                    <FormLabel>{t('discreteG')}</FormLabel>
                                                                                        <NumericFormat displayType={'text'} value={line.discreteWeight} thousandSeparator={true} decimalScale={3} />
                                                                                </FormControl>
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.tare === true &&
                                                                            <Grid item sx={{width: 120, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                                <FormControl>
                                                                                    <FormLabel>{t('tareWeight')}</FormLabel>
                                                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                                    value={line.tareWeight != null && line.tareWeight !== '' ? line.tareWeight : 0}
                                                                                />
                                                                                </FormControl>
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel != null && row.itemSel.category !== this.props.auth.constants.itemCategories.caExcise && 
                                                                            <Grid item sx={{width: 200, display: { xs: 'none', sm: 'inline-grid'}}}>
                                                                                <FormControl>
                                                                                    <FormLabel>{t('bin')}</FormLabel>
                                                                                    {line.binSel != null &&
                                                                                        <Link to={'/bin/' + line.binSel.value}>{line.binSel.label}</Link>
                                                                                    }
                                                                                </FormControl>
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.lotTracked &&
                                                                            <Grid item sx={{width: {xs: 125, lg: 150}}}>
                                                                                <FormControl>
                                                                                    <FormLabel>{t('containerType')}</FormLabel>
                                                                                    {line.containerTypeSel != null ? line.containerTypeSel.label : ''}
                                                                                </FormControl>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Box>
                                                            }
                                                            {fieldAccess && row.outputLength < 1199 &&
                                                                <Grid container spacing={3}>
                                                                    <Grid item sx={{width: {xs: 100, sm: 150, md: 130}}}>
                                                                        <TextField type="number" value={line.amount} onChange={this.changeOutLineNumber('amount', i, k)} name={"outputAmount" + i + 'a' + k}
                                                                        size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"outputAmount" + i + 'a' + k}
                                                                        error={errors[`${t('outputAmount')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('outputAmount')} (${t('line')} ${i}, ${k})`]}/>
                                                                    </Grid>
                                                                    {(row.itemSel.hasCount !== true &&  ((row.count != null && row.count !== '' && row.count !== 0) || (line.count != null && line.count !== '' && line.count !== 0))) &&
                                                                        <Grid item sx={{width: 100}}>
                                                                            {line.count != null &&
                                                                                <NumericFormat displayType={'text'} value={line.count} thousandSeparator={true} decimalScale={0}/>
                                                                            }
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.hasCount && 
                                                                        <Grid item xs={{width: 110}}>
                                                                            <TextField type="number" value={line.count} onChange={this.changeOutLineNumber('count', i, k)} name={"outputCount" + i + 'a' + k}
                                                                            size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"outputCount" + i + 'a' + k}
                                                                            error={errors[`${t('outputCount')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('outputCount')} (${t('line')} ${i}, ${k})`]}/>
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel != null && row.itemSel.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                                        <Grid item sx={{width: 140, display: {xs: 'none', sm: 'inline-grid'}}}>
                                                                            <AutoCompleteField
                                                                                value={line.binSel}
                                                                                options={this.state.bins}
                                                                                onChange={this.changeOutLineAuto('binSel', 'bin', i, k)}
                                                                                error={errors[`${t('bin')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                helperText={errors[`${t('bin')} (${t('line')} ${i}, ${k})`]}
                                                                                disabled={!fieldAccess }
                                                                                required
                                                                                data-cy={"outputBin" + i + 'a' + k}
                                                                                groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                                                noneMessage={t('noRoomSelected')}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked &&
                                                                        <Grid item sx={{width: 60}}>
                                                                            <CheckboxField
                                                                            checked={line.newLot} onChange={this.changeOutLineBool('newLot', i, k)}
                                                                            name="newLot" disabled={!fieldAccess}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked && row.itemSel.customNumber && (line.newLot === true || row.lots == null || 
                                                                    row.lots.length === 0) &&
                                                                        <Grid item sx={{width: 180, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                            {row.itemSel != null && row.itemSel.customNumber === true &&
                                                                                <TextField type="text" value={line.customLot} onChange={this.changeOutLineValue('customLot', i, k)} name={"customLot" + i + 'a' + k}
                                                                                size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"outputCustomLot" + i + 'a' + k}
                                                                                error={errors[`${t('customLot')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('customLot')} (${t('line')} ${i}, ${k})`]}/>
                                                                            }
                                                                        </Grid>
                                                                    }
                                                                    {line.newLot === true && row.lots != null && row.lots.length > 0 &&
                                                                        <Grid item sx={{width: {xs: 210, sm: 180, md: 220}}}>
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked && line.newLot !== true && row.lots != null && row.lots.length > 0 &&
                                                                        <Aux>
                                                                            <Grid item sx={{width: 180, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                                <AutoCompleteField
                                                                                    value={line.lotSel}
                                                                                    options={row.lots}
                                                                                    onChange={this.changeOutLineAuto('lotSel', 'lot', i, k)}
                                                                                    error={errors[`${t('lot')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                    helperText={errors[`${t('lot')} (${t('line')} ${i}, ${k})`]}
                                                                                    disabled={!fieldAccess }
                                                                                    data-cy={"lot" + i + 'a' + k}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item sx={{width: {xs: 210, sm: 180, md: 220}}}>
                                                                                {line.containers != null && line.containers.length > 0 &&
                                                                                    <AutoCompleteField
                                                                                        value={line.containerSel}
                                                                                        options={line.containers}
                                                                                        onChange={this.changeOutLineAuto('containerSel', 'container', i, k)}
                                                                                        error={errors[`${t('container')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                        helperText={errors[`${t('container')} (${t('line')} ${i}, ${k})`]}
                                                                                        disabled={!fieldAccess }
                                                                                        data-cy={"container" + i + 'a' + k}
                                                                                    />
                                                                                }
                                                                            </Grid>
                                                                        </Aux>
                                                                    }
                                                                    {row.itemSel.discreteWeight === true &&
                                                                        <Aux>
                                                                            <Grid item sx={{width: 110, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                                <TextField type="number" value={line.totalWeight} onChange={this.changeOutLineNumber('totalWeight', i, k)} name={"totalWeight" + i + 'a' + k}
                                                                                size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"outputTotalWeight" + i + 'a' + k}
                                                                                error={errors[`${t('totalWeight')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('totalWeight')} (${t('line')} ${i}, ${k})`]}/>
                                                                            </Grid>
                                                                        </Aux>

                                                                    }
                                                                    {row.itemSel.tare === true &&
                                                                        <Grid item sx={{width: 120, display: { xs: 'none', lg: 'inline-grid'}}}>
                                                                            <TextField type="number" value={line.tareWeight} onChange={this.changeOutLineNumber('tareWeight', i, k)} name={"tareWeight" + i + 'a' + k}
                                                                            size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"outputTareWeight" + i + 'a' + k}
                                                                            error={errors[`${t('tareWeight')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('tareWeight')} (${t('line')} ${i}, ${k})`]}/>
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked &&
                                                                        <Grid item sx={{width: {xs: 125, lg: 150}}}>
                                                                            <AutoCompleteField
                                                                            value={line.containerTypeSel}
                                                                            options={this.state.containerTypes}
                                                                            onChange={this.changeOutLineAuto('containerTypeSel', 'containerType', i, k)}
                                                                            error={errors[`${t('containerType')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                            helperText={errors[`${t('containerType')} (${t('line')} ${i}, ${k})`]}
                                                                            disabled={!fieldAccess }
                                                                            required
                                                                            data-cy={"outputContainerType" + i + 'a' + k}
                                                                        />
                                                                        </Grid>
                                                                    }
                                                                    {fieldAccess &&
                                                                        <Grid item container sx={{width: 100}}>
                                                                            <Grid item>
                                                                                <IconButton aria-label="delete line" onClick={this.deleteOutputRow(i, k)} data-cy={"deleteOutput" + i + 'a' + k}>
                                                                                    <RemoveCircleTwoToneIcon  />
                                                                                </IconButton>
                                                                            </Grid>
                                                                            <Grid item sx={{ mt: 1 }}>
                                                                                {hasDevice && preInventoryLabel && line.amount != null && line.amount > 0 &&
                                                                                    <LabelPrint 
                                                                                        containerData={{
                                                                                            container: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                                        row.itemSel.customNumber && line.customLot != null && line.customLot !== '' ? line.customLot + '-' + (k + 1) : 
                                                                                                        line.previousLot != null && line.previousLot !== '' ? line.previousLot + '-' + (k + 1) : '',
                                                                                            lot: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                                    row.itemSel.customNumber && line.customLot != null && line.customLot !== '' ? line.customLot : 
                                                                                                    line.previousLot != null && line.previousLot !== '' ? line.previousLot : '',
                                                                                            item: row.itemSel != null && row.itemSel.label != null ? row.itemSel.label : '',
                                                                                            strain: row.itemSel != null && row.itemSel.strain != null ? row.itemSel.strain : '',
                                                                                            quantity: ConversionHelper.round3(line.amount),
                                                                                            uom: row.uomSel.label,
                                                                                            category: line.containerTypeSel != null && line.containerTypeSel !== '' ? line.containerTypeSel.label : t('regular'),
                                                                                            itemNumber: row.itemSel != null && row.itemSel.number != null ? row.itemSel.number : '',
                                                                                            date: this.state.endDate != null && this.state.endDate !== '' ? this.state.endDate : 
                                                                                                  this.state.startDate != null && this.state.startDate !== '' ? this.state.startDate : '',
                                                                                            preInventory: true
                                                                                        }}
                                                                                        printAmount='single'
                                                                                    />
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            }
                                                            {fieldAccess && row.outputLength >= 1199 &&
                                                                <Grid container spacing={3} >
                                                                    <Grid item sx={{width: 110}}>
                                                                        <TextField type="number" value={line.amount} onChange={this.changeOutLineNumber('amount', i, k)} name={"outputAmount" + i + 'a' + k}
                                                                        size="medium" fullWidth={true} disabled={!fieldAccess} required label={t('quantity')} data-cy={"outputAmount" + i + 'a' + k}
                                                                        error={errors[`${t('outputAmount')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('outputAmount')} (${t('line')} ${i}, ${k})`]}/>
                                                                    </Grid>
                                                                    {row.itemSel.hasCount !== true && line.count != null && line.count !== 0 &&
                                                                        <Grid item sx={{width: 100}}>
                                                                            {line.count != null &&
                                                                                <FormControl>
                                                                                    <FormLabel>{t('count')}</FormLabel>
                                                                                    <NumericFormat displayType={'text'} value={line.count} thousandSeparator={true} decimalScale={0}/>
                                                                                </FormControl>
                                                                            }
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.hasCount === true &&
                                                                        <Grid item sx={{width: 110}}>
                                                                            <TextField type="number" value={line.count} onChange={this.changeOutLineNumber('count', i, k)} name={"outputCount" + i + 'a' + k}
                                                                            size="medium" fullWidth={true} disabled={!fieldAccess} required label={t('count')} data-cy={"outputCount" + i + 'a' + k}
                                                                            error={errors[`${t('outputCount')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('outputCount')} (${t('line')} ${i}, ${k})`]}/>
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked &&
                                                                        <Grid item sx={{width: 60}}>
                                                                            <CheckboxField
                                                                            checked={line.newLot} onChange={this.changeOutLineBool('newLot', i, k)}
                                                                            name="newLot" disabled={!fieldAccess} label={t('newLot')}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.customNumber === true && line.newLot === true &&
                                                                        <Grid item sx={{width: 130}}>
                                                                            <TextField type="text" value={line.customLot} onChange={this.changeOutLineValue('customLot', i, k)} name={"customLot" + i + 'a' + k}
                                                                            size="medium" fullWidth={true} disabled={!fieldAccess} required label={t('lot')} data-cy={"outputCustomLot" + i + 'a' + k}
                                                                            error={errors[`${t('customLot')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('customLot')} (${t('line')} ${i}, ${k})`]}/>
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked && line.newLot !== true && row.lots != null && row.lots.length > 0 &&
                                                                        <Grid item sx={{width: 200}}>
                                                                            <AutoCompleteField
                                                                                value={line.lotSel}
                                                                                options={row.lots}
                                                                                onChange={this.changeOutLineAuto('lotSel', 'lot', i, k)}
                                                                                error={errors[`${t('lot')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                helperText={errors[`${t('lot')} (${t('line')} ${i}, ${k})`]}
                                                                                disabled={!fieldAccess }
                                                                                label={t('lot')}
                                                                                data-cy={"lot" + i + 'a' + k}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked && line.newLot !== true && line.containers != null && line.containers.length > 0 &&
                                                                        <Grid item sx={{width: 210}}>
                                                                            <AutoCompleteField
                                                                                value={line.containerSel}
                                                                                options={line.containers}
                                                                                onChange={this.changeOutLineAuto('containerSel', 'container', i, k)}
                                                                                error={errors[`${t('container')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                helperText={errors[`${t('container')} (${t('line')} ${i}, ${k})`]}
                                                                                disabled={!fieldAccess }
                                                                                label={t('container')}
                                                                                data-cy={"container" + i + 'a' + k}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.discreteWeight === true &&
                                                                        <Aux>
                                                                            <Grid item sx={{width: 100}}>
                                                                                <TextField type="number" value={line.totalWeight} onChange={this.changeOutLineNumber('totalWeight', i, k)} name={"totalWeight" + i + 'a' + k}
                                                                                size="medium" fullWidth={true} disabled={!fieldAccess} required label={t('totalWeightG')} data-cy={"outputTotalWeight" + i + 'a' + k}
                                                                                error={errors[`${t('totalWeight')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('totalWeight')} (${t('line')} ${i}, ${k})`]}/>
                                                                            </Grid>
                                                                        </Aux>
                                                                    }
                                                                    {row.itemSel.tare === true &&
                                                                        <Grid item sx={{width: 120}}>
                                                                            <TextField type="number" value={line.tareWeight} onChange={this.changeOutLineNumber('tareWeight', i, k)} name={"tareWeight" + i + 'a' + k}
                                                                            size="medium" fullWidth={true} disabled={!fieldAccess} required label={t('tareWeight')} data-cy={"outputTareWeight" + i + 'a' + k}
                                                                            error={errors[`${t('tareWeight')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('tareWeight')} (${t('line')} ${i}, ${k})`]}/>
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel != null && row.itemSel.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                                        <Grid item sx={{width: 200}}>
                                                                            <AutoCompleteField
                                                                                value={line.binSel}
                                                                                options={this.state.bins}
                                                                                onChange={this.changeOutLineAuto('binSel', 'bin', i, k)}
                                                                                error={errors[`${t('bin')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                helperText={errors[`${t('bin')} (${t('line')} ${i}, ${k})`]}
                                                                                disabled={!fieldAccess }
                                                                                required
                                                                                label={t('bin')}
                                                                                data-cy={"outputBin" + i + 'a' + k}
                                                                                groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                                                noneMessage={t('noRoomSelected')}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked &&
                                                                        <Grid item sx={{width: 180}}>
                                                                            <AutoCompleteField
                                                                            value={line.containerTypeSel}
                                                                            options={this.state.containerTypes}
                                                                            onChange={this.changeOutLineAuto('containerTypeSel', 'containerType', i, k)}
                                                                            error={errors[`${t('containerType')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                            helperText={errors[`${t('containerType')} (${t('line')} ${i}, ${k})`]}
                                                                            disabled={!fieldAccess }
                                                                            required
                                                                            label={t('containerType')}
                                                                            data-cy={"outputContainerType" + i + 'a' + k}
                                                                        />
                                                                        </Grid>
                                                                    }
                                                                    {fieldAccess &&
                                                                        <Grid item container sx={{width: 100}}>
                                                                            <Grid item>
                                                                                <IconButton aria-label="delete line" onClick={this.deleteOutputRow(i, k)} data-cy={"deleteOutput" + i + 'a' + k}>
                                                                                    <RemoveCircleTwoToneIcon  />
                                                                                </IconButton>
                                                                            </Grid>
                                                                            <Grid item sx={{ mt: 1 }}>
                                                                                {hasDevice && preInventoryLabel && line.amount != null && line.amount > 0 &&
                                                                                    <LabelPrint 
                                                                                        containerData={{
                                                                                            container: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                                        row.itemSel.customNumber && line.customLot != null && line.customLot !== '' ? line.customLot + '-' + (k + 1) : 
                                                                                                        line.previousLot != null && line.previousLot !== '' ? line.previousLot + '-' + (k + 1) : '',
                                                                                            lot: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                                    row.itemSel.customNumber && line.customLot != null && line.customLot !== '' ? line.customLot : 
                                                                                                    line.previousLot != null && line.previousLot !== '' ? line.previousLot : '',
                                                                                            item: row.itemSel != null && row.itemSel.label != null ? row.itemSel.label : '',
                                                                                            strain: row.itemSel != null && row.itemSel.strain != null ? row.itemSel.strain : '',
                                                                                            quantity: ConversionHelper.round3(line.amount),
                                                                                            uom: row.uomSel.label,
                                                                                            category: line.containerTypeSel != null && line.containerTypeSel !== '' ? line.containerTypeSel.label : t('regular'),
                                                                                            itemNumber: row.itemSel != null && row.itemSel.number != null ? row.itemSel.number : '',
                                                                                            date: this.state.endDate != null && this.state.endDate !== '' ? this.state.endDate : 
                                                                                                  this.state.startDate != null && this.state.startDate !== '' ? this.state.startDate : '',
                                                                                            preInventory: true
                                                                                        }}
                                                                                        printAmount='single'
                                                                                    />
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            }
                                                        </Aux>
                                                    )}
                                                </Aux>
                                            }
                                            {row.lines != null && row.lines.length < 2 &&
                                                <Grid container spacing={3}>
                                                    <Grid item sx={{width: 350}}>
                                                        <FormControl>
                                                            <FormLabel>{t('item')}</FormLabel>
                                                            {row.itemSel != null &&
                                                                <Link to={'/item/' + row.itemSel.value}>{row.itemSel.label}</Link>
                                                            }
                                                        </FormControl>
                                                    </Grid>
                                                    {row.itemSel != null && row.itemSel.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                        <Grid item sx={{width: 200}}>
                                                            {fieldAccess &&
                                                                <AutoCompleteField
                                                                    value={row.lines[0].binSel}
                                                                    options={this.state.bins}
                                                                    onChange={this.changeOutLineAuto('binSel', 'bin', i, 0)}
                                                                    error={errors[`${t('bin')} (${t('line')} ${i}, 0)`] != null ? true : false}
                                                                    helperText={errors[`${t('bin')} (${t('line')} ${i}, 0)`]}
                                                                    disabled={!fieldAccess }
                                                                    required
                                                                    label={t('bin')}
                                                                    data-cy={"outputBin" + i}
                                                                    groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                                    noneMessage={t('noRoomSelected')}
                                                                />
                                                            }
                                                            {!fieldAccess && row.lines[0].binSel != null &&
                                                                <FormControl>
                                                                    <FormLabel>{t('bin')}</FormLabel>
                                                                    <Link to={'/bin/' + row.lines[0].binSel.value}>{row.lines[0].binSel.label}</Link>
                                                                </FormControl>
                                                            }
                                                        </Grid>
                                                    }
                                                    {row.itemSel.lotTracked &&
                                                        <Grid item sx={{width: 130}}>
                                                            <CheckboxField
                                                            checked={row.lines[0].newLot} onChange={this.changeOutLineBool('newLot', i, 0)}
                                                            name="newLot" disabled={!fieldAccess} label={t('newLot')}
                                                            />
                                                        </Grid>
                                                    }
                                                    {fieldAccess && row.itemSel != null && row.itemSel.lotTracked && row.itemSel.customNumber && row.lines != null && (row.lines[0].newLot === true || row.lots == null || row.lots.length === 0) &&
                                                        <Grid item sx={{width: 150}}>
                                                            <TextField type="text" value={row.lines[0].customLot} onChange={this.changeOutLineValue('customLot', i, 0)} name={"customLot" + i + 'a' + 0}
                                                            size="medium" fullWidth={true} disabled={!fieldAccess}
                                                            label={t('lot')} data-cy={"outputCustomLot" + i}
                                                            error={errors[`${t('inputCount')} (${t('line')} ${i}, 0)`] != null ? true : false} helperText={errors[`${t('inputCount')} (${t('line')} ${i}, 0)`]}/>
                                                        </Grid>
                                                    }
                                                    {row.itemSel.lotTracked === true && row.lines[0].newLot !== true && row.lots != null && row.lots.length > 0 &&
                                                        <Grid item sx={{width: 210}}>
                                                            {fieldAccess &&
                                                                <AutoCompleteField
                                                                    value={row.lines[0].lotSel}
                                                                    onChange={this.changeOutLineAuto('lotSel', 'lot', i, 0)}
                                                                    options={row.lots}
                                                                    error={errors[`${t('lot')} (${t('line')} ${i}, 0)`] != null ? true : false}
                                                                    helperText={errors[`${t('lot')} (${t('line')} ${i}, 0)`]}
                                                                    disabled={!fieldAccess }
                                                                    label={t('lot')}
                                                                    data-cy={"outputLot" + i}
                                                                />
                                                            }
                                                            {!fieldAccess && row.lines[0].lotSel != null &&
                                                                <FormControl>
                                                                    <FormLabel>{t('lot')}</FormLabel>
                                                                    <Link to={'/lot/' + row.lines[0].lotSel.value}>{row.lines[0].lotSel.label}</Link>
                                                                </FormControl>
                                                            }
                                                        </Grid>
                                                    }
                                                    {row.itemSel.lotTracked === true && row.lines[0].newLot !== true && row.lines[0].containers != null && row.lines[0].containers.length > 0 &&
                                                        <Grid item sx={{width: 210}}>
                                                            {fieldAccess &&
                                                                <AutoCompleteField
                                                                    value={row.lines[0].containerSel}
                                                                    options={row.lines[0].containers}
                                                                    onChange={this.changeOutLineAuto('containerSel', 'container', i, 0)}
                                                                    error={errors[`${t('container')} (${t('line')} ${i}, 0)`] != null ? true : false}
                                                                    helperText={errors[`${t('container')} (${t('line')} ${i}, 0)`]}
                                                                    disabled={!fieldAccess }
                                                                    label={t('container')}
                                                                    data-cy={"container" + i}
                                                                />
                                                            }
                                                            {!fieldAccess && row.lines[0].containerSel != null &&
                                                                <FormControl>
                                                                    <FormLabel>{t('container')}</FormLabel>
                                                                    <Grid container>
                                                                        <Grid item>
                                                                            {hasDevice &&
                                                                                <LabelPrint
                                                                                    containerData={this.state.outputLabels != null && this.state.outputLabels.length > 0 ?
                                                                                                   this.state.outputLabels.find(x => x.id === row.lines[0].container) : null}
                                                                                    printAmount='single'
                                                                                />
                                                                            }
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Link to={'/container/' + row.lines[0].containerSel.value}>{row.lines[0].containerSel.label}</Link>
                                                                        </Grid>
                                                                    </Grid>
                                                                </FormControl>
                                                            }
                                                        </Grid>
                                                    }
                                                    <Grid item sx={{width: 125}}>
                                                        {fieldAccess &&
                                                            <TextField type="number" value={row.lines[0].amount} onChange={this.changeOutLineNumber('amount', i, 0)} name={"outputAmount" + i + 'a' + 0}
                                                            size="medium" fullWidth={true} disabled={!fieldAccess} data-cy={"outputAmount" + i}
                                                            label={t('amount')+(row.uomSel != null && row.uomSel !== '' ? ' ('+row.uomSel.label+')' : '')}
                                                            error={errors[`${t('outputAmount')} (${t('line')} ${i}, 0)`] != null ? true : false} helperText={errors[`${t('outputAmount')} (${t('line')} ${i}, 0)`]}/>
                                                        }
                                                        {!fieldAccess &&
                                                            <FormControl>
                                                                <FormLabel>{t('amount')+(row.uomSel != null && row.uomSel !== '' ? ' ('+row.uomSel.label+')' : '')}</FormLabel>
                                                                <NumericFormat displayType={'text'} value={row.lines[0].amount} thousandSeparator={true} decimalScale={3} />
                                                            </FormControl>
                                                        }
                                                    </Grid>
                                                    {this.state.outputCount && row.itemSel.hasCount !== true  &&
                                                        <Grid item sx={{width: 125}}>
                                                            {row.lines[0].count != null &&
                                                                <FormControl>
                                                                    <FormLabel>{t('count') + ' (ea)'}</FormLabel>
                                                                    <NumericFormat displayType={'text'} value={row.lines[0].count} thousandSeparator={true} decimalScale={0} />
                                                                </FormControl>
                                                            }
                                                        </Grid>
                                                    }
                                                    {row.itemSel.hasCount &&
                                                        <Grid item xs={{width: 125}}>
                                                            <TextField type="number" value={row.lines[0].count} onChange={this.changeOutLineNumber('count', i, 0)} name={"outputCount" + i + 'a' + 0}
                                                            size="medium" fullWidth={true} disabled={!fieldAccess} data-cy={"outputCount" + i} label={t('count')}                                                            
                                                            error={errors[`${t('outputCount')} (${t('line')} ${i}, 0)`] != null ? true : false} helperText={errors[`${t('outputCount')} (${t('line')} ${i}, 0)`]}/>
                                                        </Grid>
                                                    }
                                                    {row.itemSel != null && row.itemSel.tare === true &&
                                                        <Grid item sx={{width: 120}} align="right">
                                                            {fieldAccess &&
                                                                <TextField type="number" value={row.tareWeight} onChange={this.changeOutLineNumber('tareWeight', i, 0)} name={"tareWeight" + i + 'a' + 0}
                                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('tareWeight')} data-cy={"outputTareWeight" + i}
                                                                error={errors[`${t('tareWeight')} (${t('line')} ${i}, 0)`] != null ? true : false} helperText={errors[`${t('tareWeight')} (${t('line')} ${i}, 0)`]}/>
                                                            }
                                                            {!fieldAccess &&
                                                                <FormControl>
                                                                    <FormLabel>{t('tareWeight')}</FormLabel>
                                                                    <NumericFormat value={row.lines[0].tareWeight} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                                </FormControl>
                                                            }
                                                        </Grid>
                                                    }
                                                    {row.itemSel != null && row.itemSel.discreteWeight &&
                                                        <Aux>
                                                            <Grid item sx={{width: 140}}>
                                                                {fieldAccess &&
                                                                    <TextField type="number" value={row.lines[0].totalWeight}
                                                                    onChange={this.changeOutLineNumber('totalWeight', i, 0)}
                                                                    name={"totalWeight" + i + 'a' + 0}
                                                                    size="medium" fullWidth={true} disabled={!fieldAccess}
                                                                    label={t('totalWeightG')} required data-cy={"outputTotalWeight" + i}
                                                                    error={errors[`${t('totalWeight')} (${t('line')} ${i}, 0)`] != null ? true : false} helperText={errors[`${t('totalWeight')} (${t('line')} ${i}, 0)`]}/>
                                                                }
                                                                {!fieldAccess &&
                                                                    <FormControl>
                                                                        <FormLabel>{t('totalWeightG')}</FormLabel>
                                                                        <NumericFormat displayType={'text'} value={row.lines[0].totalWeight} thousandSeparator={true} decimalScale={3} />
                                                                    </FormControl>
                                                                }
                                                            </Grid>
                                                            {row.lines[0].discreteWeight != null && row.lines[0].discreteWeight !== '' &&
                                                                <Aux>
                                                                    <Grid item sx={{width: 150}}>
                                                                        <FormControl>
                                                                            <FormLabel>{t('discreteG')}</FormLabel>
                                                                                <NumericFormat displayType={'text'} value={row.lines[0].discreteWeight} thousandSeparator={true} decimalScale={3} />
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Aux>
                                                            }
                                                        </Aux>
                                                    }
                                                    {fieldAccess && row.itemSel != null && row.itemSel.lotTracked &&
                                                        <Grid item sx={{width: 150}}>
                                                            <AutoCompleteField
                                                                value={row.lines[0].containerTypeSel}
                                                                options={this.state.containerTypes}
                                                                onChange={this.changeOutLineAuto('containerTypeSel', 'containerType', i, 0)}
                                                                error={errors[`${t('containerType')} (${t('line')} ${i}, 0)`] != null ? true : false}
                                                                helperText={errors[`${t('containerType')} (${t('line')} ${i}, 0)`]}
                                                                disabled={!fieldAccess }
                                                                label={t('containerType')}
                                                                data-cy={"outputContainerType" + i}
                                                            />
                                                        </Grid>
                                                    }
                                                    {!fieldAccess && row.lines[0].containerTypeSel != null &&
                                                        <Grid item style={{width: 150}}>
                                                            <FormControl>
                                                                <FormLabel>{t('containerType')}</FormLabel>
                                                                {row.lines[0].containerTypeSel != null ? row.lines[0].containerTypeSel.label : ''}
                                                            </FormControl>
                                                        </Grid>
                                                    }
                                                    {fieldAccess &&
                                                        <Grid container item sx={{width: 100}}>
                                                            <Grid item>
                                                                <IconButton aria-label="delete output" onClick={this.deleteOutput(i)} disabled={!fieldAccess}>
                                                                    <RemoveCircleTwoToneIcon  />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item sx={{ mt: 1 }}>
                                                                {hasDevice && preInventoryLabel && row.lines[0].amount != null && row.lines[0].amount > 0 &&
                                                                    <LabelPrint 
                                                                        containerData={{
                                                                            container: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                        row.lines[0].customLot != null && row.lines[0].customLot !== '' ? row.lines[0].customLot + '- 1' : '',
                                                                            lot: row.itemSel != null && row.itemSel.lotTracked && 
                                                                                    row.lines[0].customLot != null && row.lines[0].customLot !== '' ? row.lines[0].customLot : '',
                                                                            item: row.itemSel != null && row.itemSel.label != null ? row.itemSel.label : '',
                                                                            strain: row.itemSel != null && row.itemSel.strain != null ? row.itemSel.strain : '',
                                                                            quantity: ConversionHelper.round3(row.lines[0].amount),
                                                                            uom: row.uomSel.label,
                                                                            category: row.lines[0].containerTypeSel != null && row.lines[0].containerTypeSel !== '' ? row.lines[0].containerTypeSel.label : t('regular'),
                                                                            itemNumber: row.itemSel != null && row.itemSel.number != null ? row.itemSel.number : '',
                                                                            date: this.state.endDate != null && this.state.endDate !== '' ? this.state.endDate : 
                                                                                  this.state.startDate != null && this.state.startDate !== '' ? this.state.startDate : '',
                                                                            preInventory: true
                                                                        }}
                                                                        printAmount='single'
                                                                    />
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            }
                                        </Box>
                                    )}
                                    {fieldAccess && this.state.status !== this.props.auth.constants.productionOrderStatuses.new &&
                                        <Box borderColor="primary.main" {...defaultProps} component={'div'} key="outNew">
                                        <Grid container spacing={3}>
                                            <Grid item sx={{width: 350}}>
                                                <AutoCompleteField
                                                    value={this.state.newOutputItemSel}
                                                    options={this.state.allItems}
                                                    onChange={this.changeOutputItem}
                                                    error={errors[t('newOutputItem')] != null ? true : false}
                                                    helperText={errors[t('newOutputItem')]}
                                                    disabled={!fieldAccess}
                                                    label={t('item')}
                                                    data-cy="outputItem"
                                                />
                                            </Grid>
                                            {this.state.newOutputItemSel != null && this.state.newOutputItemSel.lotTracked &&
                                                <Grid item sx={{width: 130}}>
                                                    <CheckboxField
                                                    checked={this.state.newLot} onChange={this.changeBool('newLot')}
                                                    name="newLot" disabled={!fieldAccess} label={t('newLot')}
                                                    />
                                                </Grid>
                                            }
                                            {this.state.newOutputItemSel != null && this.state.newOutputItemSel.lotTracked === true && this.state.newOutputItemSel.customNumber && 
                                            (this.state.newLot === true || this.state.outputLots == null || this.state.outputLots.length === 0) &&
                                                <Grid item sx={{width: 150}}>
                                                    <TextField type="text" value={this.state.customLot} onChange={this.changeValue} name={"customLot"}
                                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('lot')} data-cy="outputCustomLot"
                                                    error={errors[t('customLot')] != null ? true : false} helperText={errors[t('customLot')]}/>
                                                </Grid>
                                            }
                                            {this.state.newOutputItemSel != null && this.state.newOutputItemSel.lotTracked && this.state.newLot !== true && this.state.outputLots != null && this.state.outputLots.length > 0 &&
                                                <Grid item sx={{width: 210}}>
                                                    <AutoCompleteField
                                                        value={this.state.outputLotSel}
                                                        options={this.state.outputLots}
                                                        onChange={this.changeAuto('outputLotSel', 'outputLot')}
                                                        error={errors[t('outputLot')] != null ? true : false}
                                                        helperText={errors[t('outputLot')]}
                                                        disabled={!fieldAccess}
                                                        label={t('lot')}
                                                        data-cy="outputLot"
                                                    />
                                                </Grid>
                                            }
                                            {this.state.newOutputItemSel != null && this.state.newOutputItemSel.lotTracked && this.state.newLot !== true && this.state.outputContainers != null && this.state.outputContainers.length > 0 &&
                                                <Grid item sx={{width: 210}}>
                                                    <AutoCompleteField
                                                        value={this.state.outputContainerSel}
                                                        options={this.state.outputContainers}
                                                        onChange={this.changeAuto('outputContainerSel', 'outputContainer')}
                                                        error={errors[t('outputContainer')] != null ? true : false}
                                                        helperText={errors[t('outputContainer')]}
                                                        disabled={!fieldAccess}
                                                        label={t('container')}
                                                        data-cy="outputContainer"
                                                    />
                                                </Grid>
                                            }
                                            <Grid item sx={{width: 125}}>
                                                <TextField type="number" value={this.state.newOutputAmount} onChange={this.changeNumber} name={"newOutputAmount"}
                                                size="medium" fullWidth={true} disabled={!fieldAccess} data-cy="outputAmount"
                                                label={t('amount')+(this.state.newOutputUOMSel != null && this.state.newOutputUOMSel !== '' ? ' ('+this.state.newOutputUOMSel.label+')' : '')}
                                                error={errors[t('newOutputItem')] != null ? true : false} helperText={errors[t('newOutputItem')]}/>
                                            </Grid>
                                            {this.state.newOutputItemSel != null && this.state.newOutputItemSel.hasCount &&
                                                <Grid item sx={{width: 100}}>
                                                    <TextField type="number" value={this.state.newOutputCount} onChange={this.changeNumber} name={"newOutputCount"}
                                                    size="medium" fullWidth={true} disabled={!fieldAccess} data-cy="newOutputCount" label={t('count')}
                                                    error={errors[t('newOutputCount')] != null ? true : false} helperText={errors[t('newOutputCount')]}/>
                                                </Grid>
                                            }
                                            {this.state.newOutputItemSel != null && this.state.newOutputItemSel.tare === true &&
                                                <Grid item sx={{width: 130}} align="right">
                                                    <TextField type="number" value={this.state.tareWeight} onChange={this.changeNumber} name="tareWeight"
                                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('tareWeight')} data-cy="outputTareWeight"
                                                    error={errors[t('tareWeight')] != null ? true : false} helperText={errors[t('tareWeight')]}/>
                                                </Grid>
                                            }
                                            {this.state.newOutputItemSel != null && this.state.newOutputItemSel.category !== this.props.auth.constants.itemCategories.caExcise &&
                                                <Grid item sx={{width: 200}}>
                                                    <AutoCompleteField
                                                        value={this.state.outputBinSel}
                                                        options={this.state.bins}
                                                        onChange={this.changeAuto('outputBinSel', 'outputBin')}
                                                        error={errors[t('outputBin')] != null ? true : false}
                                                        helperText={errors[t('outputBin')]}
                                                        disabled={!fieldAccess}
                                                        required
                                                        label={t('bin')}
                                                        data-cy="outputBin"
                                                        groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                        noneMessage={t('noRoomSelected')}
                                                    />
                                                </Grid>
                                            }
                                            {this.state.newOutputItemSel != null && this.state.newOutputItemSel.discreteWeight &&
                                                <Aux>
                                                    <Grid item sx={{width: 125}}>
                                                        <TextField type="number" value={this.state.newOutputTotalWeight} onChange={this.changeNumber} name={"newOutputTotalWeight"}
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('totalWeightG')} data-cy="outputTotalWeight"
                                                        error={errors[t('newOutputTotalWeight')] != null ? true : false} helperText={errors[t('newOutputTotalWeight')]}/>
                                                    </Grid>
                                                </Aux>
                                            }
                                            {this.state.newOutputItemSel != null && this.state.newOutputItemSel.lotTracked &&
                                                <Grid item sx={{width: 200}}>
                                                    <AutoCompleteField
                                                        value={this.state.containerTypeSel}
                                                        options={this.state.containerTypes}
                                                        onChange={this.changeAuto('containerTypeSel', 'containerType')}
                                                        error={errors[t('containerType')] != null ? true : false}
                                                        helperText={errors[t('containerType')]}
                                                        disabled={!fieldAccess}
                                                        label={t('containerType')}
                                                        data-cy="outputContainerType"
                                                    />
                                                </Grid>
                                            }
                                            <Grid item sx={{width: 50}}>
                                                <IconButton aria-label="Add Output" onClick={this.addOutput} disabled={!fieldAccess} data-cy="addOutput">
                                                    <AddBoxTwoToneIcon  />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        </Box>
                                    }
                                </Aux>
                            }
                            {this.state.activityOutputs != null && this.state.activityOutputs.length > 0 &&
                                <Aux>
                                    <br/>
                                    <SectionTitle label={t('activityOutputs')} />
                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow key="actOutHead">
                                                    <TableCell>{t('activity')}</TableCell>
                                                    <TableCell>{t('item')}</TableCell>
                                                    <TableCell>{t('quantity')}</TableCell>
                                                    <TableCell>{t('lot')}</TableCell>
                                                    <TableCell>{t('container')}</TableCell>
                                                    <TableCell>{t('bin')}</TableCell>
                                                    <TableCell>{t('containerType')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.activityOutputs.map((row, i) =>
                                                    <TableRow key={'actOut' + i}>
                                                        <TableCell>{row.activity != null ? row.activity.number : ''}</TableCell>
                                                        <TableCell><Link to={'/item/' + row.item._id}>{row.item.number + '-' + row.item.name}</Link></TableCell>
                                                        <TableCell>
                                                            <NumericFormat value={row.amount} displayType={'text'} thousandSeparator={true} decimalScale={2} suffix={row.uom} />
                                                        </TableCell>
                                                        <TableCell>{row.lot != null  && <Link to={'/lot/' + row.lot._id}>{row.lot.number}</Link>}</TableCell>
                                                        <TableCell>{row.container != null && <Link to={'/container/' + row.container._id}>{row.container.number}</Link>}</TableCell>
                                                        <TableCell>{row.bin != null && <Link to={'/bin/' + row.bin._id}>{row.bin.name}</Link>}</TableCell>
                                                        <TableCell>{row.containerType}</TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Aux>
                            }
                            <hr />
                            <FormButtonStack
                                id={this.state._id}
                                objectType={this.props.auth.constants.objectIds.productionOrder}
                                callback={this.loadRecord}
                                saveClick={this.save}
                                deleteClick={this.state.canDelete ? this.deleteToggle : null}
                                status={this.state.status}
                                permissions={this.props.permission}
                                common={this.props.auth.common}
                                constants={this.props.auth.constants}
                                type={this.props.auth.common.statusTypes.productionOrder}
                                releaseClick={this.release}
                                enableUnlockProp={this.state.enableUnlock}
                                saveEnabled={this.state.enableSave}
                                activityDate={this.state.endDate}
                                lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                                isSuper={this.props.auth.user.isSuper}
                            />
                        </TabPanel>
                        {this.state.sales != null && this.state.sales.length > 0 &&
                            <TabPanel key={"sales"} value={this.state.tabKey} index={1}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="sales">
                                        <TableHead>
                                            <TableRow key="tableHeader">
                                                <TableCell>{t('number')}</TableCell>
                                                <TableCell>{t('date')}</TableCell>
                                                <TableCell>{t('customer')}</TableCell>
                                                <TableCell>{t('province')}</TableCell>
                                                <TableCell>{t('totalAmount')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.sales.map((row, i) =>
                                                <TableRow key={row._id}>
                                                    <TableCell><Link to={'/sale/' + row._id} name={'saleRow' + i}>{row.number}</Link></TableCell>
                                                    <TableCell><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                                    <TableCell>{row.customer.name}</TableCell>
                                                    <TableCell>{row.prov != null ? row.prov.name : ''}</TableCell>
                                                    <TableCell>{row.totalAmount}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                        }
                        {this.state.hasLedgers &&
                            <TabCanvas value={this.state.tabKey} index={this.state.tabLabels.length - 1}>
                                <ItemLedgerComponent record={this.state._id} permission={this.props.permission} auth={this.props.auth} renderNum={this.state.renderNum}
                                objectType={this.props.auth.constants.objectIds.productionOrder}/>
                            </TabCanvas>
                        }
                    </Grid>
                </Grid>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.scheduleId}
                        fieldAccess={fieldAccess}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
                <Dialog open={this.state.showLine} onClose={this.toggleAddLine} center maxWidth="md" fullWidth>
                    <DialogTitle>
                        <SectionTitle title={t('addLine')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.seq} onChange={this.changeNumber} name="seq"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('seq')}
                                error={errors[t('seq')] != null ? true : false} helperText={errors[t('seq')]}/>
                            </Grid>
                            {(!locked || fieldAccess) &&
                                <Grid item xs={10} sm={8}>
                                    <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('name')}
                                    error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                                </Grid>
                            }
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.roomSel}
                                    options={this.state.rooms}
                                    onChange={this.changeAuto('roomSel', 'room')}
                                    error={errors[t('room')] != null ? true : false}
                                    helperText={errors[t('room')]}
                                    label={t('room')}
                                    disabled={locked}
                                />
                            </Grid>
                            {(this.state.equipmentsSel == null || this.state.equipmentsSel.length === 0) &&
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.machineTypeSel}
                                        options={this.state.machineTypes}
                                        onChange={this.changeAuto('machineTypeSel', 'machineType')}
                                        error={errors[t('machineType')] != null ? true : false}
                                        helperText={errors[t('machineType')]}
                                        label={t('machineType')}
                                        disabled={locked}
                                    />
                                </Grid>
                            }
                            {this.state.machineTypeSel == null && this.state.equipmentOptions?.length > 0 &&
                                <Grid item sm={6}>
                                    <AutoCompleteField
                                        value={this.state.equipmentsSel}
                                        options={this.state.equipmentOptions}
                                        onChange={this.changeMulti('equipmentsSel', 'lineEquipments')}
                                        error={errors[t('equipment')] != null ? true : false}
                                        helperText={errors[t('equipment')]}
                                        label={t('equipment')}
                                        disabled={locked}
                                        multiple={true}
                                    />
                                </Grid>
                            }
                            {(!locked || fieldAccess) &&
                                <Grid item xs={4} sm={2}>
                                    <TextField value={this.state.runEffort} onChange={this.changeNumber} name="runEffort"
                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('runEffort')}
                                    error={errors[t('runEffort')] != null ? true : false} helperText={errors[t('runEffort')]}/>
                                </Grid>
                            }
                            {(!locked || fieldAccess) &&
                                <Grid item xs={10} sm={8}>
                                    <TextField value={this.state.lineDescription} onChange={this.changeValue} name="lineDescription"
                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('description')}
                                    error={errors[t('lineDescription')] != null ? true : false} helperText={errors[t('lineDescription')]}/>
                                </Grid>
                            }
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={11}>
                                <AutoCompleteField
                                    multiple={true} disabled={!fieldAccess}
                                    value={this.state.sopsSel}
                                    options={this.state.sopList}
                                    onChange={this.changeMulti('sopsSel', 'sops')}
                                    error={errors[t('sops')] != null ? true : false}
                                    helperText={errors[t('sops')]}
                                    label={t('workRecs')}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {fieldAccess &&
                            <Button variant="contained" color="primary" size="small" onClick={this.saveLine}>{t('save')}</Button>
                        }
                        <Button variant="contained" color="secondary" size="small" onClick={this.toggleAddLine}>{t('close')}</Button>
                    </DialogActions>
                </Dialog>
                {this.state.printViewer &&
                    <PrintView
                        id={this.state._id}
                        object={this.props.auth.constants.objectIds.productionOrder}
                        open={this.state.printViewer}
                        onClose={this.togglePrintViewer}
                        callBack={this.loadRecord}
                        printAccess={this.state.security.print}
                    />
                }
                 <Confirmation content={t('outputMoreThanInput')} title={t('confirm')} open={this.state.showOutputMore}
                        no={this.toggleMore} noButton={t('no')}
                        yes={this.acceptMore} yesButton={t('yes')} />
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.productionOrder}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    devices: state.devices,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ProductionOrder));
