import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';

class RoomCleanings extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 5,
            room: this.props.room,
            location: '',
            locations: [],
            locationSel: null,
            type: '',
            typeSel: null,
            types: [],
            status: '',
            statuses: [],
            orderBy: 'number',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            statusSel: null,
            security: [],
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location, common.cacheValues.room, common.cacheValues.roomCleaningType,
            common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        this.setState({
            types: cacheValues.roomCleaningTypes,
            locations: locations,
            rooms: cacheValues.rooms,
            statuses: cacheValues.statuses,
            security: this.props.permission
        }, () => {this.loadRecords();})
    }

    loadRecords = async () => {
        let sorting = [{'id': 'number', 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: 'room', value: this.props.room},
            {id: 'issue', value: this.state.issue !== '' ? this.state.issue : null}, 
            {id: 'status', value: this.state.status !== '' ? this.state.status : null}, 
            {id: 'equipment', value: this.props.equipment !== '' ? this.props.equipment : null}, 
            {id: 'type', value: this.state.type !== '' ? this.state.type : null}
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let list = await axios.get('/api/roomcleanings', {params: params});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        });
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords()});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            
            <Aux>
                {this.state.list != null && this.state.list.length > 0 &&
                    <Aux>
                        <SectionTitle title={t('cleanings')}/>
                        <Panel>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.typeSel}
                                        options={this.state.types}
                                        onChange={this.changeAuto('typeSel', 'type')}
                                        label={t('type')}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.statusSel}
                                        options={this.state.statuses}
                                        onChange={this.changeAuto('statusSel', 'status')}
                                        label={t('status')}
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper}>
                                <Table aria-label="Room Cleanings" size="small">
                                    <TableHead>
                                        <TableRow key="tableHeader">
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('number')}
                                                active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('date')}
                                                active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('type')}
                                                active={this.state.orderBy === 'type'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('type')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('status')}
                                                active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.list.map((row, i) => 
                                            <TableRow key={row._id}>
                                                <TableCell><Link to={'/roomcleaning/' + row._id}>{row.number}</Link></TableCell>
                                                <TableCell><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                                <TableCell>{row.type}</TableCell>
                                                <TableCell>{row.status}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow key="tableFooter">
                                            <TablePagination count={this.state.totalCount}
                                            rowsPerPage={this.state.listSize}
                                            onPageChange={this.handleChangePage}
                                            page = {this.state.page}
                                            rowsPerPageOptions={common.rowOptions}
                                            onRowsPerPageChange={this.handleRowsPerPage}/>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Panel>
                    </Aux>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(RoomCleanings));