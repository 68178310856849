import React from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { NumericFormat } from 'react-number-format';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';
import ReportTitle from '../../UI/Typography/ReportTitle';
import ReportSectionTitle from '../../UI/Typography/ReportSectionTitle';
import Aux from '../../../hoc/Auxilary/Auxilary';

import Attestation from './Attestation';
import ProductionOrder from './ProductionOrder';
import Cleaning from './Cleaning';

export default function ProductionBatchTemplate (props) {
    const { constants, dateFormat, printView, record, t } = props;
    const BORDERSTYLE = '0.5px solid rgb(224, 224, 224)';
    const batchRecordTemplate = (batchRecord, index) => {
        return (
            <div style={{ width: '8.5in', pageBreakBefore: 'always' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ReportTitle
                            title={
                                t('product') + ': ' + 
                                (batchRecord.outputItem != null && batchRecord.outputItem !== '' && batchRecord.outputItem.name !== null && batchRecord.outputItem.name !== '' ?
                                    batchRecord.outputItem.name : t('none')
                                )
                            }
                            description={
                                batchRecord.description != null && batchRecord.description !== '' ?
                                batchRecord.description : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <table style={{ width: '100%' }} >
                            <thead>
                                <tr>
                                    <th style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'center' }}>{t('batchNumber')}</th>
                                    <th style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'center' }}>{t('plannedStart')}</th>
                                    <th style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'center' }}>{t('actualStart')}</th>
                                    <th style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'center' }}>{t('dueDate')}</th>
                                    <th style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'center' }}>{t('completedOn')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {batchRecord.number != null && batchRecord.number !== '' ? batchRecord.number : t('none')}
                                    </td>
                                    <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {batchRecord.plannedStart != null && batchRecord.plannedStart !== '' ?
                                            <DateDisplay value={batchRecord.plannedStart} format={dateFormat} /> : t('na')
                                        }
                                    </td>
                                    <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {batchRecord.actualStart != null && batchRecord.actualStart !== '' ?
                                            <DateDisplay value={batchRecord.actualStart} format={dateFormat} /> : t('na')
                                        }
                                    </td>
                                    <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {batchRecord.dueDate != null && batchRecord.dueDate !== '' ?
                                            <DateDisplay value={batchRecord.dueDate} format={dateFormat} /> : t('na')
                                        }
                                    </td>
                                    <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {batchRecord.completeDate != null && batchRecord.completeDate !== '' ?
                                            <DateDisplay value={batchRecord.completeDate} format={dateFormat} /> : t('na')
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12}>
                        <table style={{ width: '100%' }} >
                            <thead>
                                <tr>
                                    <th style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'center' }}>{t('outputItem')}</th>
                                    <th style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'center' }}>{t('planned')}</th>
                                    <th style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'center' }}>{t('actual')}</th>
                                    <th style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'center' }}>{t('productionToTarget')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {batchRecord.outputItem != null && batchRecord.outputItem !== '' && batchRecord.outputItem.name != null && batchRecord.outputItem.name !== '' ? 
                                            batchRecord.outputItem.name : t('none')
                                        }
                                    </td>
                                    <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {batchRecord.outputQuantity != null && batchRecord.outputQuantity !== '' ?
                                            batchRecord.outputQuantity : t('na')
                                        }
                                    </td>
                                    <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {batchRecord.actualOutput != null && batchRecord.actualOutput !== '' ?
                                            batchRecord.actualOutput : t('na')
                                        }
                                    </td>
                                    <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {batchRecord.productionToTarget != null && batchRecord.productionToTarget !== '' ?
                                            <NumericFormat displayType={'text'} value={batchRecord.productionToTarget} thousandSeparator={true}
                                            decimalScale={2} suffix={'%'} fixedDecimalScale/> : t('na')
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12}>
                        <ReportSectionTitle title={t('deviations')} />
                        <table style={{ borderCollapse: 'collapse', width: '100%' }} >
                            <thead>
                                <th style={{ fontSize: '9pt', padding: '5px', textAlign: 'center', border: BORDERSTYLE }} >{t('recordNumber')}</th>
                                <th style={{ fontSize: '9pt', padding: '5px', textAlign: 'center', border: BORDERSTYLE }} >{t('occurred')}</th>
                                <th style={{ fontSize: '9pt', padding: '5px', textAlign: 'center', border: BORDERSTYLE }} >{t('title')}</th>
                                <th style={{ fontSize: '9pt', padding: '5px', textAlign: 'center', border: BORDERSTYLE }} >{t('type')}</th>
                                <th style={{ fontSize: '9pt', padding: '5px', textAlign: 'center', border: BORDERSTYLE }} >{t('category')}</th>
                                <th style={{ fontSize: '9pt', padding: '5px', textAlign: 'center', border: BORDERSTYLE }} >{t('impact')}</th>
                                <th style={{ fontSize: '9pt', padding: '5px', textAlign: 'center', border: BORDERSTYLE }} >{t('status')}</th>
                            </thead>
                            <tbody>
                                {record.deviations != null && record.deviations.length > 0 ?
                                    record.deviations.map((deviation, i) => 
                                        <tr style={{ border: '1px solid #ddd', padding: '5px' }} >
                                            <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px'}}>{deviation.number}</td>
                                            <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px'}}><DateDisplay format={props.dateFormat} value={deviation.occuranceDate}/></td>
                                            <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px'}}>{deviation.title}</td>
                                            <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px'}}>{deviation.type != null ? deviation.type.label : ''}</td>
                                            <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px'}}>{deviation.category != null ? deviation.category.label : ''}</td>
                                            <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px'}}>{deviation.impact ?? t('notKnown')}</td>
                                            <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px'}}>{deviation.status.label}</td>
                                        </tr>
                                    ) : <tr style={{ border: '1px solid #ddd', fontSize: '8pt', padding: '5px' }} ><td colSpan='7'><center>{t('noDeviations')}</center></td></tr>
                                }
                            </tbody>
                        </table>                
                    </Grid>
                    <Grid item xs={12}>
                        <ReportSectionTitle title={t('nonCorformances')} />
                        <table style={{ fontFamily: 'Open Sans', borderCollapse: 'collapse', width: '100%' }} >
                            <thead>
                                <th style={{ fontSize: '9pt', padding: '5px', textAlign: 'left', border: BORDERSTYLE }} >{t('recordNumber')}</th>
                                <th style={{ fontSize: '9pt', padding: '5px', textAlign: 'left', border: BORDERSTYLE }} >{t('occurred')}</th>
                                <th style={{ fontSize: '9pt', padding: '5px', textAlign: 'left', border: BORDERSTYLE }} >{t('title')}</th>
                                <th style={{ fontSize: '9pt', padding: '5px', textAlign: 'left', border: BORDERSTYLE }} >{t('status')}</th>
                            </thead>
                            <tbody>
                                {record.ncrs != null && record.ncrs.length > 0 ?
                                    record.ncrs.map((ncr, i) => 
                                        <tr style={{ border: '1px solid #ddd', fontSize: '8pt', padding: '5px' }} >
                                            <td>{ncr.number}</td>
                                            <td><DateDisplay format={props.dateFormat} value={ncr.occuranceDate}/></td>
                                            <td>{ncr.title}</td>
                                            <td>{ncr.status != null && ncr.status !== '' ? ncr.status.label : 'None'}</td>
                                        </tr>
                                    ) : <tr style={{ border: '1px solid #ddd', fontSize: '8pt', padding: '5px' }} ><td colspan='4'><center>{t('noNcrs')}</center></td></tr>
                                }
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12}>
                        <ReportSectionTitle title={t('batchSummary')} />
                        <table style={{ width: '100%' }} >
                            <thead>
                                <tr>
                                    <th style={{ border: BORDERSTYLE, width: '30px', fontSize: '9pt', textAlign: 'center' }}>#</th>
                                    <th style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'center' }}>{t('type')}</th>
                                    <th style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'center' }}>{t('record')}</th>
                                    <th style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'center' }}>{t('totalCannabisInputs')}</th>
                                    <th style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'center' }}>{t('totalCannabisOutputs')}</th>
                                    <th style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'center' }}>{t('processingLoss')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {batchRecord.activities != null && batchRecord.activities.length > 0 ?
                                    batchRecord.activities.sort((a, b) => a.sequence - b.sequence).map((order, i) => {
                                        if(order.productionOrder != null && order.productionOrder.hasCannabis === true){
                                            return (
                                                <Aux key={'auxBatchPO-' + i}>
                                                    <tr key={'batchProdOrder-' + i} >
                                                        <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px' }}>
                                                            {i + 1}
                                                        </td>
                                                        <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px' }}>
                                                            {t('productionOrder')}
                                                        </td>
                                                        <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                                            {order.productionOrder != null && order.productionOrder !== '' && order.productionOrder.number != null && order.productionOrder.number !== '' ? 
                                                                order.productionOrder.number : t('none')
                                                            }
                                                        </td>
                                                        <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                                            {order?.productionOrder?.inputCannabisTotal != null && order?.productionOrder?.inputCannabisTotal !== '' &&
                                                             order?.productionOrder?.status != null && order?.productionOrder?.status !== '' && order?.productionOrder?.status !== constants.productionOrderStatuses.deleted ? 
                                                                <NumericFormat displayType={'text'} value={order.productionOrder.inputCannabisTotal} thousandSeparator={true}
                                                                decimalScale={3} fixedDecimalScale/> : 0
                                                            }
                                                        </td>
                                                        <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                                            {order?.productionOrder?.outputCannabisTotal != null && order?.productionOrder?.outputCannabisTotal !== '' &&
                                                             order?.productionOrder?.status != null && order?.productionOrder?.status !== '' && order?.productionOrder?.status !== constants.productionOrderStatuses.deleted ? 
                                                                <NumericFormat displayType={'text'} value={order.productionOrder.outputCannabisTotal} thousandSeparator={true}
                                                                decimalScale={3} fixedDecimalScale/> : 0
                                                            }
                                                        </td>
                                                        <td style={{ border: BORDERSTYLE, fontSize: '8pt', fontWeight: 'bold', padding: '5px', textAlign: 'right' }}>
                                                            {order.productionOrder != null && order.productionOrder !== '' && order.productionOrder.processingLoss != null && order.productionOrder.processingLoss !== '' ? 
                                                                <NumericFormat displayType={'text'} value={order.productionOrder.processingLoss} thousandSeparator={true}
                                                                decimalScale={3} fixedDecimalScale/> : 0
                                                            }
                                                        </td>
                                                    </tr>
                                                    {i === batchRecord.activities.length - 1 ?
                                                        <tr key='batchProdOrderTotal'>
                                                            <td colSpan={3} style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', fontWeight: 'bold', textAlign: 'right' }}>{t('total')}</td>
                                                            <td style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'right' }}>
                                                                {batchRecord.totalCannabisInputs != null && batchRecord.totalCannabisInputs !== '' ? 
                                                                    <NumericFormat displayType={'text'} value={batchRecord.totalCannabisInputs} thousandSeparator={true}
                                                                    decimalScale={3} fixedDecimalScale/> : 0
                                                                }
                                                            </td>
                                                            <td style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', textAlign: 'right' }}>
                                                                {batchRecord.totalCannabisOutputs != null && batchRecord.totalCannabisOutputs !== '' ? 
                                                                    <NumericFormat displayType={'text'} value={batchRecord.totalCannabisOutputs} thousandSeparator={true}
                                                                    decimalScale={3} fixedDecimalScale/> : 0
                                                                }
                                                            </td>
                                                            <td style={{ border: BORDERSTYLE, width: '20%', fontSize: '9pt', fontWeight: 'bold', textAlign: 'right' }}>
                                                                {batchRecord.processingLoss != null && batchRecord.processingLoss !== '' ? 
                                                                    <NumericFormat displayType={'text'} value={batchRecord.processingLoss} thousandSeparator={true}
                                                                    decimalScale={3} fixedDecimalScale/> : 0
                                                                }
                                                            </td>
                                                        </tr>
                                                    : null}
                                                </Aux>
                                            )
                                        }else {
                                            return (
                                                <tr key={'batchProdOrder-' + i} >
                                                    <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px' }}>
                                                        {i + 1}
                                                    </td>
                                                    <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px' }}>
                                                        {order?.productionOrder != null ? t('productionOrder') : 
                                                         order?.preClean != null && order?.preClean === true ? t('preCleaning') : 
                                                         order?.postClean != null && order?.postClean === true ? t('postCleaning') :''}
                                                    </td>
                                                    <td style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                                        {order?.productionOrder?.number != null && order?.productionOrder?.number !== '' ?
                                                         order.productionOrder.number : order?.roomCleaning?.number != null && order?.roomCleaning?.number !== '' ?
                                                            order.roomCleaning.number : t('none')
                                                        }
                                                    </td>
                                                    <td colSpan={3} 
                                                        style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                                        {t('noCannabisProduction')}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    }
                                ) : 
                                <tr key={'batchProdOrderSummaryNoData'}>
                                    <td colSpan={4} 
                                        style={{ border: BORDERSTYLE, fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                        {t('noCannabisProduction')}
                                    </td>
                                </tr>    
                            }
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{ width: '80%', margin: '0 auto' }} />
                    </Grid>
                </Grid>
                {batchRecord.activities != null && batchRecord.activities.length > 0 &&
                    <Grid container spacing={2}>
                        {batchRecord.activities.sort((a,b) => a.sequence - b.sequence).map((activity, i) => {
                            if(activity.productionOrder != null && activity.productionOrder !== ''){
                                return (
                                    <Grid item xs={12} key={'activityRecord-' + i}>
                                        <ProductionOrder record={activity.productionOrder} t={t} printView={printView} sequence={i + 1} dateFormat={dateFormat} constants={constants}
                                        batch={{ number: batchRecord.number != null && batchRecord.number !== '' ? batchRecord.number : null }} />
                                    </Grid>
                                );
                            }else if(activity.roomCleaning != null && activity.roomCleaning !== '') {
                                let order = '';
                                if(activity.preClean != null && activity.preClean === true && batchRecord.activities[i + 1]?.productionOrder?.number != null) {
                                    order = batchRecord.activities[i + 1]?.productionOrder.number;
                                }else if(activity.postClean != null && activity.postClean === true && batchRecord.activities[i - 1]?.productionOrder?.number != null) {
                                    order = batchRecord.activities[i - 1]?.productionOrder.number;
                                }
                                return (
                                    <Grid item xs={12} key={'activityRecord-' + i}>
                                        <Cleaning record={activity.roomCleaning} t={t} printView={printView} sequence={i + 1} dateFormat={dateFormat} constants={constants}
                                        batch={{ number: batchRecord.number != null && batchRecord.number !== '' ? batchRecord.number : null }}
                                        order={order}
                                        preClean={ activity.preClean != null && activity.preClean === true ? true : false } 
                                        postClean={ activity.postClean != null && activity.postClean === true ? true : false } />
                                    </Grid>
                                );
                            }else {
                                return null;
                            }                     
                        })}
                    </Grid>
                }
            </div>
        )
    }

    const batchRecordComponents = record.records != null && record.records.length > 0 ?
        record.records.map((batchRecord, index) => batchRecordTemplate(batchRecord, index)) : null;

  return (
    <div>
        {batchRecordComponents}
        <Attestation 
            name={record.documentParameters != null && record.documentParameters !== '' && 
            record.documentParameters.approvedBy != null && record.documentParameters.approvedBy !== '' ? 
                record.documentParameters.approvedBy : ''
            } t={t} 
        />
    </div>
  );
}