import { SET_SOP_ID, SET_TEMPLATE_ID } from '../actions/types';

const initialState = {
    sopId: '',
    templateId: ''
}

export default function sopReducer(state = initialState, action){
    switch(action.type){
        case SET_SOP_ID:{
            return {
                ...state,
                        sopId: action.payload.sopId,
                        ...state.templateId
                
            }
        }
        case SET_TEMPLATE_ID:{
            return {
                ...state,
                    ...state.sopId,
                    templateId: action.payload.templateId
            }
        }    
        default:
            return state;
    }
}