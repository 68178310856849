import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import { CSVDownload } from "react-csv";
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete';
import dateFormat from 'date-fns/format';
import { Checkbox, FormControlLabel } from '@mui/material';
import ActionBar from '../UI/Buttons/ActionBar';
import listHelper from '../../helpers/listHelper';
import ListMultiSelect from '../UI/ListMultiSelect/ListMultiSelect';

const Lots = (props) => {
    const isFirstRender = useRef(true);
    const [loadRefData, setLoadRefData] = useState(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [columnOrder, setColumnOrder] = useState([]);
    const [itemSel, setItemSel] = useState(null);
    const [csvOutput, setCsvOutput] = useState({headers: null, data: null});
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [values, setValues] = useState({items: [], categories: [], reportCategories: [], ratings: [], statuses: [], uoms: []});
    const { t } = useTranslation('translation');
    const csvSeparator = sessionStorage.getItem('csvSeparator');
    const tenant = props.auth.user.tenant;
    const [statusesSel, setStatusesSel] = useState({ids: [], values: []});
    const [categoriesSel, setCategoriesSel] = useState({ids: [], values: []});
    const [showSelect, setShowSelect] = useState(false);

    const crumbs = [
        { path: 'active', label: t('lots'), screen: props.auth.screenDefs.lotList}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        async function fetchData(){
            const columnFilters = await listHelper.getItem('LotsColumnFilters', tenant);
            const columnVisibility = await listHelper.getItem('LotsColumnVisibility', tenant);
            const density = await listHelper.getItem('LotsDensity', tenant);
            const globalFilter = await listHelper.getItem('LotsGlobalFilter', tenant);
            const showGlobalFilter = await listHelper.getItem('LotsShowGlobalFilter', tenant);
            const showColumnFilters = await listHelper.getItem('LotsShowColumnFilters', tenant);
            const sorting = await listHelper.getItem('LotsSorting', tenant);
            const pagination = await listHelper.getItem('LotsPagination', tenant);
            const tempColumnOrder = await listHelper.getItem('LotsColumnOrder', tenant);
            if (columnFilters) {
                let filters = JSON.parse(columnFilters);
                setColumnFilters(filters);
                let item = filters.find(x => x.id === 'item');
                if(item != null && values.items != null && values.items.length > 0) {
                    if(item.value != null){
                        let sel = values.items.find(x => x.value === item.value);
                        setItemSel(sel);
                    }
                }
                let status = filters.find(x => x.id === 'status');
                if(status != null){
                    let sel = {ids: status.value, values: []};
                    status.value.forEach(id => {
                        let val = values.statuses.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setStatusesSel(sel);
                }
                let cat = filters.find(x => x.id === 'category');
                if(cat != null){
                    let sel = {ids: cat.value, values: []};
                    cat.value.forEach(id => {
                        let val = values.categories.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setCategoriesSel(sel);
                }
            }
            if (columnVisibility) {
                setColumnVisibility(columnVisibility);
            }
            if (density) {
                setDensity(density);
            }
            if (globalFilter) {
                setGlobalFilter(globalFilter || undefined);
            }
            if (showGlobalFilter) {
                setShowGlobalFilter(showGlobalFilter);
            }
            if (showColumnFilters) {
                setShowColumnFilters(showColumnFilters);
            }
            if (sorting) {
                setSorting(sorting);
            }
            if (pagination) {
                setPagination(pagination);
            }
            if (tempColumnOrder != null && tempColumnOrder.length > 0) {
                setColumnOrder(tempColumnOrder);
            } else {
                setColumnOrder(['number', 'item', 'status', 'amount', 'amountUOM', 'available', 'reserved', 'vendorLot', 'created', 'expires', 'rating', 'discreteWeight', 'fullDiscreteWeight', 'category', 'reportCategory', 'packagedOn', 'costPerUnit', 'thcMg', 'totalThc', 'totalCbd', 'totalTerpenes']);
            }
            isFirstRender.current = false;
        }
        if(isFirstRender.current === true || columnOrder.length === 0){
            fetchData();
        }
    }, [values, tenant, columnOrder]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('LotsColumnFilters', tenant, JSON.stringify(columnFilters));
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('LotsColumnVisibility', tenant, columnVisibility);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnVisibility, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('LotsDensity', tenant, density);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [density, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('LotsGlobalFilter', tenant, globalFilter ?? '');
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [globalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('LotsShowGlobalFilter', tenant, showGlobalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [showGlobalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('LotsShowColumnFilters', tenant, showColumnFilters);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [showColumnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('LotsSorting', tenant, sorting);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [sorting, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('LotsPagination', tenant, pagination);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [pagination, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('LotsColumnOrder', tenant, columnOrder);
        }
        if (isFirstRender.current === true) return;
        fetchData();
    }, [columnOrder, tenant]);

    const resetState = async () => {
        await listHelper.removeItem('LotsColumnFilters', tenant);
        await listHelper.removeItem('LotsColumnVisibility', tenant);
        await listHelper.removeItem('LotsDensity', tenant);
        await listHelper.removeItem('LotsGlobalFilter', tenant);
        await listHelper.removeItem('LotsShowGlobalFilter', tenant);
        await listHelper.removeItem('LotsShowColumnFilters', tenant);
        await listHelper.removeItem('LotsSorting', tenant);
        await listHelper.removeItem('LotsPagination', tenant);
        await listHelper.removeItem('LotsColumnOrder', tenant);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.allItems, common.cacheValues.itemCategory, common.cacheValues.hcReportCategory, 
                common.cacheValues.lotRating, common.cacheValues.lotStatus, common.cacheValues.quantityUOM], props.auth.constants, null, props.auth.user.tenant);
            let item = columnFilters.find(x => x.id === 'item');
            if(item != null) {
                if(item.value != null){
                    let sel = cacheValues.allItems.find(x => x.value === item.value);
                    setItemSel(sel);
                }
            }
            setValues({
                items: cacheValues.allItems,
                categories: cacheValues.itemCategories,
                reportCategories: cacheValues.hcReportCategories,
                ratings: cacheValues.lotRatings,
                statuses: cacheValues.lotStatuses,
                uoms: cacheValues.quantityUOMs
            });
            let status = columnFilters != null ? columnFilters.find(x => x.id === 'status') : null;
            if(status != null){
                let sel = {ids: status.value, values: []};
                status.value.forEach(id => {
                    let val = cacheValues.lotStatuses.find(x => x.value === id);
                    sel.values.push(val);
                });
                setStatusesSel(sel);
            }
            let cat = columnFilters != null ? columnFilters.find(x => x.id === 'category') : null;
            if(cat != null){
                let sel = {ids: cat.value, values: []};
                cat.value.forEach(id => {
                    let val = cacheValues.itemCategories.find(x => x.value === id);
                    sel.values.push(val);
                });
                setCategoriesSel(sel);
            }
            setLoadRefData(false);
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, columnFilters, loadRefData]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'lots',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/lots', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });

    let columns = useMemo(() => [
        {
            accessorKey: 'number',
            id: 'number',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/lot/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.item.number}-${row.item.name}`,
            id: 'item',
            header: t('item'),
            Filter: ({ header, column }) =>
                    <AutoComplete 
                        options={values.items}
                        getOptionLabel={(option) => option.label}
                        onChange={(e, newValue) => {
                            setItemSel(newValue);
                            column.setFilterValue(newValue != null ? newValue.value : null);
                        }}
                        value={itemSel}
                        renderInput={(params) => <TextField {...params} variant="standard" />}
                    />,
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/item/' + cell.row.original?.item._id}>{cell.getValue()}</Link>
                </Box>
            ),
        },
        {
            accessorKey: 'status',
            id: 'status',
            header: t('status'),
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='status'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.statuses} 
                    setSelected={setStatusesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={statusesSel.ids} 
                    selectedSel={statusesSel.values}
                    columnFilters={columnFilters}
                    screen='Lots'
                    tenant={props.auth.user.tenant}/>
                </Box>
            ),
        },
        {
            accessorKey: 'amount',
            id: 'amount',
            header: t('quantity'),
            muiTableBodyCellProps: {
                align: 'right',
            },
            Filter: ({
                header,
                column
              }) => <FormControlLabel control={
                <Checkbox checked={column.getFilterValue() === 'true'} 
                    onChange={() => {
                        column.setFilterValue(
                            column.getFilterValue() === undefined || column.getFilterValue() === 'false' || column.getFilterValue() == null
                                ? 'true' : 'false'
                        )
                    }}/>
                } label={t('includeEmpty')} />,
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
            ),
        },
        {
            accessorKey: 'amountUOM',
            id: 'amountUOM',
            header: t('uom'),
            filterVariant: 'select',
            filterSelectOptions: values.uoms
        },
        {
            accessorKey: 'available',
            id: 'available',
            header: t('available'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
            ),
        }, 
        {
            accessorKey: 'reserved',
            id: 'reserved',
            header: t('reserved'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
            ),
        },
        {
            accessorKey: 'vendorLot',
            id: 'vendorLot',
            header: t('vendorLot')
        },
        {
            accessorKey: 'created',
            id: 'created',
            header: t('created'),
            enableColumnFilter: false,
            Cell: ({ cell, column }) => (
                <Box>
                    {dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat)}
                </Box>
            ),
        },
        {
            accessorKey: 'expires',
            id: 'expires',
            header: t('expiration'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() != null && cell.getValue() !== '' ? dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat) : null}
                </Box>
            ),
        },
        {
            accessorKey: 'rating',
            id: 'rating',
            header: t('rating'),
            filterVariant: 'select',
            filterSelectOptions: values.ratings
        },
        {
            accessorKey: 'discreteWeight',
            id: 'discreteWeight',
            header: t('discrete'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
            )
        },
        {
            accessorKey: 'fullDiscreteWeight',
            id: 'fullDiscreteWeight',
            header: t('fullDiscrete'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
            )
        },
        {
            accessorKey: 'category',
            id: 'category',
            header: t('category'),
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='category'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.categories} 
                    setSelected={setCategoriesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={categoriesSel.ids} 
                    selectedSel={categoriesSel.values}
                    columnFilters={columnFilters}
                    screen='Lots'
                    tenant={props.auth.user.tenant}/>
                </Box>
            ),
        },
        {
            accessorKey: 'reportCategory',
            id: 'reportCategory',
            header: t('reportCategory'),
            filterVariant: 'select',
            filterSelectOptions: values.reportCategories
        },
        {
            accessorKey: 'packagedOn',
            id: 'packagedOn',
            header: t('packagedOn'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() != null && cell.getValue() !== '' ? dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat) : null}
                </Box>
            ),
        },
        {
            accessorKey: 'costPerUnit',
            id: 'costPerUnit',
            header: t('costPerUnit'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
            ),
        },
        {
            accessorKey: 'thcMg',
            id: 'thcMg',
            header: t('thcMg'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
        },
        {
            accessorKey: 'totalThc',
            id: 'totalThc',
            header: t('totalThc'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
        },
        {
            accessorKey: 'totalCbd',
            id: 'totalCbd',
            header: t('totalCbd'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
        },
        {
            accessorKey: 'totalTerpenes',
            id: 'totalTerpenes',
            header: t('totalTerpenes'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
        }
    ], [t, values, itemSel, props, statusesSel, categoriesSel, showSelect, columnFilters]);

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true,  columnVisibility: {id: false}},
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: false,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()}>
                        <RefreshIcon color="primary"/>
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('export')}>
                    <IconButton onClick={() => csv()}>
                        <ExcelIcon color="primary"/>
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('clearListSettings')}>
                    <IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });

    const csv = async () => {
        setCsvOutput({headers: null, data: null});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axios.get('/api/lotscsv', {params: params});
        let headers = response.data.headers;
        let csvData = response.data.data;
        setCsvOutput({headers: headers, data: csvData});
    }

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs}>
                <ActionBar/>
            </BreadcrumbBar>
            {csvOutput != null && csvOutput.data != null &&
                <CSVDownload data={csvOutput.data} target="_blank" headers={csvOutput.headers} separator={csvSeparator}/>
            }
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
        </Aux>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(Lots);