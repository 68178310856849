import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import { RefreshButton, SaveButton } from '../UI/Buttons/Buttons';
import Panel from '../UI/Panel/Panel';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class SettingPrefix extends Component {
    constructor(props){
        super(props);
        this.state = {
            id: "",
            illnessPrefix: '',
            incidentPrefix: '',
            deviationPrefix: '',
            ncrPrefix: '',
            lossPrefix: '',
            spillPrefix: '',
            recallPrefix: '',
            vauditPrefix: '',
            cropDestructionPrefix: '',
            destructionPrefix: '',
            sopPrefix: '',
            satTestPrefix: '',
            cropTreatmentPrefix: '',
            extTreatmentPrefix: '',
            pestPrepPrefix: '',
            visitorPrefix: '',
            roomSettingPrefix: '',
            roomCheckPrefix: '',
            equipLogPrefix: '',
            riskAssPrefix: '',
            servReqPrefix: '',
            cleaningSolPrefix: '',
            feedLotPrefix: '',
            locInspPrefix: '',
            testResPrefix: '',
            prodPlanPrefix: '',
            prodSchedPrefix: '',
            changeReqPrefix: '',
            batchActionPrefix: '',
            wetWastePrefix: '',
            wetLotMid: '',
            dryLotMid: '',
            cloneLotMid: '',
            totePrefix: '',
            containerPrefix: '',
            trimmingPrefix: '',
            dryingPrefix: '',
            curingPrefix: '',
            productionOrderPrefix: '',
            productionOrderActivityPrefix: '',
            wipPrefix: '',
            itemLedgerPrefix: '',
            routingPrefix: '',
            bomPrefix: '',
            equipmentPrefix: '',
            itemPrefix: '',
            binPrefix: '',
            vendorPrefix: '',
            customerPrefix: '',
            workCenterPrefix: '',
            salePrefix: '',
            shipmentPrefix: '',
            sopCertPrefix: '',
            dryingActivityPrefix: '',
            trimmingActivityPrefix: '',
            curingActivityPrefix: '',
            emailTemplatePrefix: '',
            emailPrefix: '',
            roomCleaningPrefix: '',
            capaPrefix: '',
            batchObsPrefix: '',
            receiptPrefix: '',
            purchaseOrderPrefix: '',
            productionBatchPrefix: '',
            customerComplaintPrefix: '',
            managedDocumentTemplatePrefix: '',
            carPrefix: '',
            apiLogPrefix: '',
            personPrefix: '',
            announcementPrefix: '',
            roomFeedingPrefix: '',
            signinPrefix: '',
            ccxPostingPrefix: '',
            pestScoutingPrefix: '',
            exAdjPrefix: '',
            pesticideRecipePrefix: '',
            equipmentCalibrationPrefix: '',
            calibrationTypePrefix: '',
            availableInventoryPrefix: '',
            security: [],
            errors: {},
            enableSave: true,
            masterCasePrefix: '',
            hcReportPrefix: '',
            cleaningSolutionRecipePrefix: '',
        };
    }

    async componentDidMount(){
        this.loadSettings();  
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    loadSettings = async () => {
        let response = await axios.get('/api/settings');
        this.setState({
            id: response.data._id,
            illnessPrefix: response.data.illnessPrefix,
            incidentPrefix: response.data.incidentPrefix,
            deviationPrefix: response.data.deviationPrefix,
            ncrPrefix: response.data.ncrPrefix,
            lossPrefix: response.data.lossPrefix,
            spillPrefix: response.data.spillPrefix,
            recallPrefix: response.data.recallPrefix,
            vauditPrefix: response.data.vauditPrefix,
            cropDestructionPrefix: response.data.cropDestructionPrefix,
            destructionPrefix: response.data.destructionPrefix,
            sopPrefix: response.data.sopPrefix,
            satTestPrefix: response.data.satTestPrefix,
            cropTreatmentPrefix: response.data.cropTreatmentPrefix,
            extTreatmentPrefix: response.data.extTreatmentPrefix,
            pestPrepPrefix: response.data.pestPrepPrefix,
            visitorPrefix: response.data.visitorPrefix,
            roomSettingPrefix: response.data.roomSettingPrefix,
            roomCheckPrefix: response.data.roomCheckPrefix,
            equipLogPrefix: response.data.equipLogPrefix,
            riskAssPrefix: response.data.riskAssPrefix,
            servReqPrefix: response.data.servReqPrefix,
            cleaningSolPrefix: response.data.cleaningSolPrefix,
            feedLotPrefix: response.data.feedLotPrefix,
            locInspPrefix: response.data.locInspPrefix,
            testResPrefix: response.data.testResPrefix,
            prodPlanPrefix: response.data.prodPlanPrefix,
            prodSchedPrefix: response.data.prodSchedPrefix,
            changeReqPrefix: response.data.changeReqPrefix,
            batchActionPrefix: response.data.batchActionPrefix,
            wetWastePrefix: response.data.wetWastePrefix,
            wetLotMid: response.data.wetLotMid,
            dryLotMid: response.data.dryLotMid,
            cloneLotMid: response.data.cloneLotMid,
            totePrefix: response.data.totePrefix,
            containerPrefix: response.data.containerPrefix,
            trimmingPrefix: response.data.trimmingPrefix,
            dryingPrefix: response.data.dryingPrefix,
            curingPrefix: response.data.curingPrefix,
            productionOrderPrefix: response.data.productionOrderPrefix,
            productionOrderActivityPrefix: response.data.productionOrderActivityPrefix,
            wipPrefix: response.data.wipPrefix,
            itemLedgerPrefix: response.data.itemLedgerPrefix,
            routingPrefix: response.data.routingPrefix,
            bomPrefix: response.data.bomPrefix,
            equipmentPrefix: response.data.equipmentPrefix,
            itemPrefix: response.data.itemPrefix,
            vendorPrefix: response.data.vendorPrefix,
            binPrefix: response.data.binPrefix,
            customerPrefix: response.data.customerPrefix,
            workCenterPrefix: response.data.workCenterPrefix,
            salePrefix: response.data.salePrefix,
            shipmentPrefix: response.data.shipmentPrefix,
            sopCertPrefix: response.data.sopCertPrefix,
            dryingActivityPrefix: response.data.dryingActivityPrefix,
            trimmingActivityPrefix: response.data.trimmingActivityPrefix,
            curingActivityPrefix: response.data.curingActivityPrefix,
            emailPrefix: response.data.emailPrefix,
            emailTemplatePrefix: response.data.emailTemplatePrefix,
            roomCleaningPrefix: response.data.roomCleaningPrefix,
            capaPrefix: response.data.capaPrefix,
            batchObsPrefix: response.data.batchObsPrefix,
            receiptPrefix: response.data.receiptPrefix,
            purchaseOrderPrefix: response.data.purchaseOrderPrefix,
            productionBatchPrefix: response.data.productionBatchPrefix,
            customerComplaintPrefix: response.data.customerComplaintPrefix,
            managedDocumentTemplatePrefix: response.data.managedDocumentTemplatePrefix,
            carPrefix: response.data.carPrefix,
            apiLogPrefix: response.data.apiLogPrefix,
            personPrefix: response.data.personPrefix,
            announcementPrefix: response.data.announcementPrefix,
            roomFeedingPrefix: response.data.roomFeedingPrefix,
            signinPrefix: response.data.signinPrefix,
            ccxPostingPrefix: response.data.ccxPostingPrefix,
            pestScoutingPrefix: response.data.pestScoutingPrefix,
            exAdjPrefix: response.data.exAdjPrefix,
            pesticideRecipePrefix: response.data.pesticideRecipePrefix,
            equipmentCalibrationPrefix: response.data.equipmentCalibrationPrefix,
            calibrationTypePrefix: response.data.calibrationTypePrefix,
            availableInventoryPrefix: response.data.availableInventoryPrefix,
            security: this.props.permission,
            masterCasePrefix: response.data.masterCasePrefix,
            hcReportPrefix: response.data.hcReportPrefix,
            cleaningSolutionRecipePrefix: response.data.cleaningSolutionRecipePrefix
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('prefixes'), screen: this.props.auth.screenDefs.SettingPrefix}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    saveSettings = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveSettings());
    }

    _saveSettings = async () => {
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true }); 
            return;
        }
                   
        const settings = {
            _id: this.state.id,
            illnessPrefix: this.state.illnessPrefix,
            incidentPrefix: this.state.incidentPrefix,
            deviationPrefix: this.state.deviationPrefix,
            ncrPrefix: this.state.ncrPrefix,
            lossPrefix: this.state.lossPrefix,
            spillPrefix: this.state.spillPrefix,
            recallPrefix: this.state.recallPrefix,
            vauditPrefix: this.state.vauditPrefix,
            cropDestructionPrefix: this.state.cropDestructionPrefix,
            destructionPrefix: this.state.destructionPrefix,
            sopPrefix: this.state.sopPrefix,
            satTestPrefix: this.state.satTestPrefix,
            cropTreatmentPrefix: this.state.cropTreatmentPrefix,
            extTreatmentPrefix: this.state.extTreatmentPrefix,
            pestPrepPrefix: this.state.pestPrepPrefix,
            visitorPrefix: this.state.visitorPrefix,
            roomSettingPrefix: this.state.roomSettingPrefix,
            roomCheckPrefix: this.state.roomCheckPrefix,
            equipLogPrefix: this.state.equipLogPrefix,
            riskAssPrefix: this.state.riskAssPrefix,
            servReqPrefix: this.state.servReqPrefix,
            cleaningSolPrefix: this.state.cleaningSolPrefix,
            feedLotPrefix: this.state.feedLotPrefix,
            locInspPrefix: this.state.locInspPrefix,
            testResPrefix: this.state.testResPrefix,
            prodPlanPrefix: this.state.prodPlanPrefix,
            prodSchedPrefix: this.state.prodSchedPrefix,
            changeReqPrefix: this.state.changeReqPrefix,
            batchActionPrefix: this.state.batchActionPrefix,
            wetWastePrefix: this.state.wetWastePrefix,
            wetLotMid: this.state.wetLotMid,
            dryLotMid: this.state.dryLotMid,
            cloneLotMid: this.state.cloneLotMid,
            totePrefix: this.state.totePrefix,
            containerPrefix: this.state.containerPrefix,
            trimmingPrefix: this.state.trimmingPrefix,
            dryingPrefix: this.state.dryingPrefix,
            curingPrefix: this.state.curingPrefix,
            productionOrderPrefix: this.state.productionOrderPrefix,
            productionOrderActivityPrefix: this.state.productionOrderActivityPrefix,
            wipPrefix: this.state.wipPrefix,
            itemLedgerPrefix: this.state.itemLedgerPrefix,
            routingPrefix: this.state.routingPrefix,
            bomPrefix: this.state.bomPrefix,
            equipmentPrefix: this.state.equipmentPrefix,
            itemPrefix: this.state.itemPrefix,
            binPrefix: this.state.binPrefix,
            vendorPrefix: this.state.vendorPrefix,
            customerPrefix: this.state.customerPrefix,
            workCenterPrefix: this.state.workCenterPrefix,
            salePrefix: this.state.salePrefix,
            shipmentPrefix: this.state.shipmentPrefix,
            sopCertPrefix: this.state.sopCertPrefix,
            dryingActivityPrefix: this.state.dryingActivityPrefix,
            trimmingActivityPrefix: this.state.trimmingActivityPrefix,
            curingActivityPrefix: this.state.curingActivityPrefix,
            emailPrefix: this.state.emailPrefix,
            emailTemplatePrefix: this.state.emailTemplatePrefix,
            roomCleaningPrefix: this.state.roomCleaningPrefix,
            capaPrefix: this.state.capaPrefix,
            batchObsPrefix: this.state.batchObsPrefix,
            receiptPrefix: this.state.receiptPrefix,
            purchaseOrderPrefix: this.state.purchaseOrderPrefix,
            productionBatchPrefix: this.state.productionBatchPrefix,
            customerComplaintPrefix: this.state.customerComplaintPrefix,
            managedDocumentTemplatePrefix: this.state.managedDocumentTemplatePrefix,
            carPrefix: this.state.carPrefix,
            apiLogPrefix: this.state.apiLogPrefix,
            personPrefix: this.state.personPrefix,
            announcementPrefix: this.state.announcementPrefix,
            roomFeedingPrefix: this.state.roomFeedingPrefix,
            signinPrefix: this.state.signinPrefix,
            ccxPostingPrefix: this.state.ccxPostingPrefix,
	        pestScoutingPrefix: this.state.pestScoutingPrefix,
	        exAdjPrefix: this.state.exAdjPrefix,
            pesticideRecipePrefix: this.state.pesticideRecipePrefix,
            equipmentCalibrationPrefix: this.state.equipmentCalibrationPrefix,
            calibrationTypePrefix: this.state.calibrationTypePrefix,
            availableInventoryPrefix: this.state.availableInventoryPrefix,
            masterCasePrefix: this.state.masterCasePrefix,
            hcReportPrefix: this.state.hcReportPrefix,
            cleaningSolutionRecipePrefix: this.state.cleaningSolutionRecipePrefix
        }
        axios.post('/api/settingsprefixes', settings)
        .then(response => {
            this.setState({ enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: response.data.message, title: this.props.t('success'), severity: 'success'}});
            });            
        })
        .catch(err => {
            this.setState({ enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        })
    }

    validate = () => {
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.illnessPrefix == null || state.illnessPrefix === '')
            errors[t('employeeIllness')] = t('required');
        if(state.incidentPrefix == null || state.illnessPrefix === '')
            errors[t('incident')] = t('required');
        if(state.deviationPrefix == null || state.deviationPrefix === '')
            errors[t('deviation')] = t('required');
        if(state.ncrPrefix == null || state.ncrPrefix === '')
            errors[t('ncr')] = t('required');
        if(state.lossPrefix == null || state.lossPrefix === '')
            errors[t('loss')] = t('required');
        if(state.spillPrefix == null || state.spillPrefix === '')
            errors[t('hazardousSpill')] = t('required');
        if(state.recallPrefix == null || state.recallPrefix === '')
            errors[t('recall')] = t('required');
        if(state.vauditPrefix == null || state.vauditPrefix === '')
            errors[t('vendorAudit')] = t('required');
        if(state.cropDestructionPrefix == null || state.cropDestructionPrefix === '')
            errors[t('cropDestruction')] = t('required');
        if(state.destructionPrefix == null || state.destructionPrefix === '')
            errors[t('destruction')] = t('required');
        if(state.sopPrefix == null || state.sopPrefix === '')
            errors[t('workRecord')] = t('required');
        if(state.satTestPrefix == null || state.satTestPrefix === '')
            errors[t('saturationTest')] = t('required');
        if(state.cropTreatmentPrefix == null || state.cropTreatmentPrefix === '')
            errors[t('cropTreatment')] = t('required');
        if(state.extTreatmentPrefix == null || state.extTreatmentPrefix === '')
            errors[t('externalTreatment')] = t('required');
        if(state.pestPrepPrefix == null || state.pestPrepPrefix === '')
            errors[t('pesticidePreparation')] = t('required');
        if(state.visitorPrefix == null || state.visitorPrefix === '')
            errors[t('visitor')] = t('required');
        if(state.roomSettingPrefix == null || state.roomSettingPrefix === '')
            errors[t('roomSettings')] = t('required');
        if(state.roomCheckPrefix == null || state.roomCheckPrefix === '')
            errors[t('roomCheck')] = t('required');
        if(state.equipLogPrefix == null || state.equipLogPrefix === '')
            errors[t('maintenanceLog')] = t('required');
        if(state.riskAssPrefix == null || state.riskAssPrefix === '')
            errors[t('taskRiskAssessment')] = t('required');
        if(state.servReqPrefix == null || state.servReqPrefix === '')
            errors[t('serviceRequest')] = t('required');
        if(state.cleaningSolPrefix == null || state.cleaningSolPrefix === '')
            errors[t('cleaningSolution')] = t('required');
        if(state.feedLotPrefix == null || state.feedLotPrefix === '')
            errors[t('feedingLot')] = t('required');
        if(state.locInspPrefix == null || state.locInspPrefix === '')
            errors[t('locationInspection')] = t('required');
        if(state.testResPrefix == null || state.testResPrefix === '')
            errors[t('testResult')] = t('required');
        if(state.prodPlanPrefix == null || state.prodPlanPrefix === '')
            errors[t('productionPlan')] = t('required');
        if(state.prodSchedPrefix == null || state.prodSchedPrefix === '')
            errors[t('productionSchedule')] = t('required');
        if(state.changeReqPrefix == null || state.changeReqPrefix === '')
            errors[t('changeRequest')] = t('required');
        if(state.batchActionPrefix == null || state.batchActionPrefix === '')
            errors[t('batchActivity')] = t('required');
        if(state.wetWastePrefix == null || state.wetWastePrefix === '')
            errors[t('wetWaste')] = t('required');
        if(state.totePrefix == null || state.totePrefix === '')
            errors[t('tote')] = t('required');
        if(state.containerPrefix == null || state.containerPrefix === '')
            errors[t('container')] = t('required');
        if(state.trimmingPrefix == null || state.trimmingPrefix === '')
            errors[t('trimming')] = t('required');
        if(state.dryingPrefix == null || state.dryingPrefix === '')
            errors[t('drying')] = t('required');
        if(state.curingPrefix == null || state.curingPrefix === '')
            errors[t('curing')] = t('required');
        if(state.itemLedgerPrefix == null || state.itemLedgerPrefix === '')
            errors[t('itemLedger')] = t('required');
        if(state.routingPrefix == null || state.routingPrefix === '')
            errors[t('routing')] = t('required');
        if(state.bomPrefix == null || state.bomPrefix === '')
            errors[t('bom')] = t('required');
        if(state.equipmentPrefix == null || state.equipmentPrefix === '')
            errors[t('equipment')] = t('required');
        if(state.itemPrefix == null || state.itemPrefix === '')
            errors[t('item')] = t('required');
        if(state.binPrefix == null || state.binPrefix === '')
            errors[t('bin')] = t('required');
        if(state.vendorPrefix == null || state.vendorPrefix === '')
            errors[t('vendor')] = t('required');
        if(state.customerPrefix == null || state.customerPrefix === '')
            errors[t('customer')] = t('required');
        if(state.workCenterPrefix == null || state.workCenterPrefx === '')
            errors[t('workCenter')] = t('required');
        if(state.shipmentPrefix == null || state.shipmentPrefix === '')
            errors[t('shipment')] = t('required');
        if(state.salePrefix == null || state.salePrefix === '')
            errors[t('sale')] = t('required');
        if(state.sopCertPrefix == null || state.sopCertPrefix === '')
            errors[t('documentCertification')] = t('required');
        if(state.dryingActivityPrefix == null || state.dryingActivityPrefix === '')
            errors[t('dryingActivity')] = t('required');
        if(state.trimmingActivityPrefix == null || state.trimmingActivityPrefix === '')
            errors[t('trimmingActivity')] = t('required');
        if(state.curingActivityPrefix == null || state.curingActivityPrefix === '')
            errors[t('curingActivity')] = t('required');
        if(state.emailTemplatePrefix == null || state.emailTemplatePrefix === '')
            errors[t('emailTemplate')] = t('required');
        if(state.emailPrefix == null || state.emailPrefix === '')
            errors[t('email')] = t('required');
        if(state.roomCleaningPrefix == null || state.roomCleaningPrefix === '')
            errors[t('email')] = t('required');
        if(state.capaPrefix == null || state.capaPrefix === '')
            errors[t('capa')] = t('required');
        if(state.batchObsPrefix == null || state.batchObsPrefix === '')
            errors[t('batchObservation')] = t('required');
        if(state.receiptPrefix == null || state.receiptPrefix === '')
            errors[t('receipt')] = t('required');
        if(state.purchaseOrderPrefix == null || state.purchaseOrderPrefix === '')
            errors[t('purchaseOrder')] = t('required');
        if(state.productionBatchPrefix == null || state.productionBatchPrefix === '')
            errors[t('productionBatch')] = t('required');
        if(state.cloneLotMid == null || state.cloneLotMid === '')
            errors[t('cloneLot')] = t('required');
        if(state.dryLotMid == null || state.dryLotMid === '')
            errors[t('dryLot')] = t('required');
        if(state.productionOrderPrefix == null || state.productionOrderPrefix === '')
            errors[t('productionOrder')] = t('required');
        if(state.productionOrderActivityPrefix == null || state.productionOrderActivityPrefix === '')
            errors[t('productionOrderActivity')] = t('required');
        if(state.roomCleaningPrefix == null || state.roomCleaningPrefix === '')
            errors[t('roomCleaning')] = t('required');
        if(state.wetLotMid == null || state.wetLotMid === '')
            errors[t('wetLot')] = t('required');
        if(state.wipPrefix == null || state.wipPrefix === '')
            errors[t('wipLot')] = t('required');
        if(state.workCenterPrefix == null || state.workCenterPrefix === '')
            errors[t('workCenter')] = t('required');
        if(state.customerComplaintPrefix == null || state.customerComplaintPrefix === '')
            errors[t('customerComplaint')] = t('required');
        if(state.managedDocumentTemplatePrefix == null || state.managedDocumentTemplatePrefix === '')
            errors[t('documentTemplate')] = t('required');
        if(state.carPrefix == null || state.carPrefix === '')
            errors[t('car')] = t('required');
        if(state.apiLogPrefix == null || state.apiLogPrefix === '')
            errors[t('apiLog')] = t('required');
        if(state.personPrefix == null || state.personPrefix === '')
            errors[t('person')] = t('required');
        if(state.announcementPrefix == null || state.announcementPrefix === '')
            errors[t('announcement')] = t('required');
        if(state.roomFeedingPrefix == null || state.roomFeedingPrefix === '')
            errors[t('roomFeeding')] = t('required');
        if(state.signinPrefix == null || state.signinPrefix === '')
            errors[t('signin')] = t('required');
        if(state.ccxPostingPrefix == null || state.ccxPostingPrefix === '')
            errors[t('ccxPosting')] = t('required');
	    if(state.pestScoutingPrefix == null || state.pestScoutingPrefix === '')
            errors[t('pestScouting')] = t('required');
        if(state.exAdjPrefix == null || state.exAdjPrefix === '')
            errors[t('exciseAdjustment')] = t('required');
        if(state.pesticideRecipePrefix == null || state.pesticideRecipePrefix === '')
            errors[t('pesticideRecipe')] = t('required');
        if(state.equipmentCalibrationPrefix == null || state.equipmentCalibrationPrefix === '')
            errors[t('equipmentCalibrationCheck')] = t('required');
        if(state.calibrationTypePrefix == null || state.calibrationTypePrefix === '')
            errors[t('calibrationType')] = t('required');
        if(state.availableInventoryPrefix == null || state.availableInventoryPrefix === '')
            errors[t('availableInventory')] = t('required');
        if(state.masterCasePrefix == null || state.masterCasePrefix === '')
            errors[t('masterCase')] = t('required');
        if(state.hcReportPrefix == null || state.hcReportPrefix === '')
            errors[t('hcReport')] = t('required');
        if(state.cleaningSolutionRecipePrefix == null || state.cleaningSolutionRecipePrefix === '')
            errors[t('cleaningSolutionRecipe')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    refreshSettings = e => {
        e.preventDefault();
        this.loadSettings();
    }

    render() {
        const { errors, t } = this.props;
        const security = this.state.security;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.announcementPrefix} onChange={this.changeValue} name="announcementPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('announcement')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('announcement')] != null ? true : false} helperText={errors[t('announcement')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.apiLogPrefix} onChange={this.changeValue} name="apiLogPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('apiLog')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('apiLog')] != null ? true : false} helperText={errors[t('apiLog')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.availableInventoryPrefix} onChange={this.changeValue} name="availableInventoryPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('availableInventory')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('availableInventory')] != null ? true : false} helperText={errors[t('availableInventory')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.batchActionPrefix} onChange={this.changeValue} name="batchActionPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('batchActivity')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('batchActivity')] != null ? true : false} helperText={errors[t('batchActivity')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.batchObsPrefix} onChange={this.changeValue} name="batchObsPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('batchObservation')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('batchObservation')] != null ? true : false} helperText={errors[t('batchObservation')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.binPrefix} onChange={this.changeValue} name="binPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('bin')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('bin')] != null ? true : false} helperText={errors[t('bin')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.capaPrefix} onChange={this.changeValue} name="capaPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('capa')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('capa')] != null ? true : false} helperText={errors[t('capa')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.ccxPostingPrefix} onChange={this.changeValue} name="ccxPostingPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('ccx')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('ccxPosting')] != null ? true : false} helperText={errors[t('ccxPosting')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.changeReqPrefix} onChange={this.changeValue} name="changeReqPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('changeRequest')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('changeRequest')] != null ? true : false} helperText={errors[t('changeRequest')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.cleaningSolPrefix} onChange={this.changeValue} name="cleaningSolPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('cleaningSolution')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('cleaningSolution')] != null ? true : false} helperText={errors[t('cleaningSolution')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.cleaningSolutionRecipePrefix} onChange={this.changeValue} name="cleaningSolutionRecipe"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('cleaningSolutionRecipe')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('cleaningSolutionRecipe')] != null ? true : false} helperText={errors[t('cleaningSolutionRecipe')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.cloneLotMid} onChange={this.changeValue} name="cloneLotMid"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('cloneLot')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('cloneLot')] != null ? true : false} helperText={errors[t('cloneLot')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.containerPrefix} onChange={this.changeValue} name="containerPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('container')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('container')] != null ? true : false} helperText={errors[t('container')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.carPrefix} onChange={this.changeValue} name="carPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('correctiveActionRequest')} inputProps={{ maxLength: 3 }}
                            error={errors[t('car')] != null ? true : false} helperText={errors[t('car')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.cropDestructionPrefix} onChange={this.changeValue} name="cropDestructionPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('cropDestruction')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('cropDestruction')] != null ? true : false} helperText={errors[t('cropDestruction')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.curingPrefix} onChange={this.changeValue} name="curingPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('curing')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('curing')] != null ? true : false} helperText={errors[t('curing')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.curingActivityPrefix} onChange={this.changeValue} name="curingActivityPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('curingActivity')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('curingActivity')] != null ? true : false} helperText={errors[t('curingActivity')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.customerPrefix} onChange={this.changeValue} name="customerPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('customer')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('customer')] != null ? true : false} helperText={errors[t('customer')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.customerComplaintPrefix} onChange={this.changeValue} name="customerComplaintPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('customerComplaint')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('customerComplaint')] != null ? true : false} helperText={errors[t('customerComplaint')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.destructionPrefix} onChange={this.changeValue} name="destructionPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('destruction')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('destruction')] != null ? true : false} helperText={errors[t('destruction')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.deviationPrefix} onChange={this.changeValue} name="deviationPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('deviation')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('deviation')] != null ? true : false} helperText={errors[t('deviation')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.sopCertPrefix} onChange={this.changeValue} name="sopCertPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('documentCertification')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('documentCertification')] != null ? true : false} helperText={errors[t('documentCertification')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.managedDocumentTemplatePrefix} onChange={this.changeValue} name="managedDocumentTemplatePrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('documentTemplate')} inputProps={{ maxLength: 3 }}
                            error={errors[t('documentTemplate')] != null ? true : false} helperText={errors[t('documentTemplate')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.dryLotMid} onChange={this.changeValue} name="dryLotMid"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('dryLot')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('dryLot')] != null ? true : false} helperText={errors[t('dryLot')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.dryingPrefix} onChange={this.changeValue} name="dryingPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('drying')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('drying')] != null ? true : false} helperText={errors[t('drying')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.dryingActivityPrefix} onChange={this.changeValue} name="dryingActivityPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('dryingActivity')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('dryingActivity')] != null ? true : false} helperText={errors[t('dryingActivity')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.emailPrefix} onChange={this.changeValue} name="emailPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('email')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('email')] != null ? true : false} helperText={errors[t('email')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.emailTemplatePrefix} onChange={this.changeValue} name="emailTemplatePrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('emailTemplate')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('emailTemplate')] != null ? true : false} helperText={errors[t('emailTemplate')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.illnessPrefix} onChange={this.changeValue} name="illnessPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('employeeIllness')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('employeeIllness')] != null ? true : false} helperText={errors[t('employeeIllness')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.equipmentPrefix} onChange={this.changeValue} name="equipmentPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('equipment')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('equipment')] != null ? true : false} helperText={errors[t('equipment')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.equipmentCalibrationPrefix} onChange={this.changeValue} name="equipmentCalibrationPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('equipmentCalibrationCheck')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('equipmentCalibrationCheck')] != null ? true : false} helperText={errors[t('equipmentCalibrationCheck')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.calibrationTypePrefix} onChange={this.changeValue} name="calibrationTypePrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('equipmentCalibrationType')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('calibrationType')] != null ? true : false} helperText={errors[t('calibrationType')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.exAdjPrefix} onChange={this.changeValue} name="exAdjPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('exciseAdjustment')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('exciseAdjustment')] != null ? true : false} helperText={errors[t('exciseAdjustment')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.extTreatmentPrefix} onChange={this.changeValue} name="extTreatmentPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('externalTreatment')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('externalTreatment')] != null ? true : false} helperText={errors[t('externalTreatment')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.feedLotPrefix} onChange={this.changeValue} name="feedLotPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('feedingLot')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('feedingLot')] != null ? true : false} helperText={errors[t('feedingLot')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.spillPrefix} onChange={this.changeValue} name="spillPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('hazardousSpill')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('hazardousSpill')] != null ? true : false} helperText={errors[t('hazardousSpill')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.hcReportPrefix} onChange={this.changeValue} name="hcReportPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('hcReport')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('hcReport')] != null ? true : false} helperText={errors[t('hcReport')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.incidentPrefix} onChange={this.changeValue} name="incidentPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('incident')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('incident')] != null ? true : false} helperText={errors[t('incident')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.cropTreatmentPrefix} onChange={this.changeValue} name="cropTreatmentPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('cropTreatment')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('cropTreatment')] != null ? true : false} helperText={errors[t('cropTreatment')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.itemPrefix} onChange={this.changeValue} name="itemPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('item')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('item')] != null ? true : false} helperText={errors[t('item')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.itemLedgerPrefix} onChange={this.changeValue} name="itemLedgerPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('itemLedger')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('itemLedger')] != null ? true : false} helperText={errors[t('itemLedger')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.locInspPrefix} onChange={this.changeValue} name="locInspPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('locationInspection')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('locationInspection')] != null ? true : false} helperText={errors[t('locationInspection')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.lossPrefix} onChange={this.changeValue} name="lossPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('lossCannabis')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('loss')] != null ? true : false} helperText={errors[t('loss')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.equipLogPrefix} onChange={this.changeValue} name="equipLogPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('maintenanceLog')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('maintenanceLog')] != null ? true : false} helperText={errors[t('maintenanceLog')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.masterCasePrefix} onChange={this.changeValue} name="masterCasePrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('masterCase')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('masterCase')] != null ? true : false} helperText={errors[t('masterCase')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.ncrPrefix} onChange={this.changeValue} name="ncrPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('ncr')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('ncr')] != null ? true : false} helperText={errors[t('ncr')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.personPrefix} onChange={this.changeValue} name="personPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('person')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('person')] != null ? true : false} helperText={errors[t('person')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.pestScoutingPrefix} onChange={this.changeValue} name="pestScoutingPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('pestScouting')} inputProps={{ maxLength: 3 }} required
                            error={errors[t('pestScouting')] != null ? true : false} helperText={errors[t('pestScouting')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.pestPrepPrefix} onChange={this.changeValue} name="pestPrepPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('pesticidePreparation')} inputProps={{ maxLength: 3 }}
                            error={errors[t('pesticidePreparation')] != null ? true : false} helperText={errors[t('pesticidePreparation')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.pesticideRecipePrefix} onChange={this.changeValue} name="pesticideRecipePrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('pesticideRecipe')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('pesticideRecipe')] != null ? true : false} helperText={errors[t('pesticideRecipe')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.productionBatchPrefix} onChange={this.changeValue} name="productionBatchPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('productionBatch')} inputProps={{ maxLength: 3 }}
                            error={errors[t('productionBatch')] != null ? true : false} helperText={errors[t('productionBatch')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.bomPrefix} onChange={this.changeValue} name="bomPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('productionBom')} inputProps={{ maxLength: 3 }}
                            error={errors[t('bom')] != null ? true : false} helperText={errors[t('bom')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.productionOrderPrefix} onChange={this.changeValue} name="productionOrderPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('productionOrder')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('productionOrder')] != null ? true : false} helperText={errors[t('productionOrder')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.productionOrderActivityPrefix} onChange={this.changeValue} name="productionOrderActivityPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('productionOrderActivity')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('productionOrderActivity')] != null ? true : false} helperText={errors[t('productionOrderActivity')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.prodPlanPrefix} onChange={this.changeValue} name="prodPlanPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('productionPlan')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('productionPlan')] != null ? true : false} helperText={errors[t('productionPlan')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.prodSchedPrefix} onChange={this.changeValue} name="prodSchedPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('productionSchedule')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('productionSchedule')] != null ? true : false} helperText={errors[t('productionSchedule')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.purchaseOrderPrefix} onChange={this.changeValue} name="purchaseOrderPrefix"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('purchaseOrder')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('purchaseOrder')] != null ? true : false} helperText={errors[t('purchaseOrder')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.recallPrefix} onChange={this.changeValue} name="recallPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('recall')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('recall')] != null ? true : false} helperText={errors[t('recall')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.receiptPrefix} onChange={this.changeValue} name="receiptPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('receipt')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('receipt')] != null ? true : false} helperText={errors[t('receipt')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.roomCheckPrefix} onChange={this.changeValue} name="roomCheckPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label="Room Check" inputProps={{ maxLength: 3 }} 
                            error={errors[t('roomCheck')] != null ? true : false} helperText={errors[t('roomCheck')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.roomSettingPrefix} onChange={this.changeValue} name="roomSettingPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('roomSettings')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('roomSettings')] != null ? true : false} helperText={errors[t('roomSettings')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.roomCleaningPrefix} onChange={this.changeValue} name="roomCleaningPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('roomCleaning')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('roomCleaning')] != null ? true : false} helperText={errors[t('roomCleaning')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.roomFeedingPrefix} onChange={this.changeValue} name="roomFeedingPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('roomFeeding')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('roomFeeding')] != null ? true : false} helperText={errors[t('roomFeeding')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.routingPrefix} onChange={this.changeValue} name="routingPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('routing')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('routing')] != null ? true : false} helperText={errors[t('routing')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.salePrefix} onChange={this.changeValue} name="salePrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('sale')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('sale')] != null ? true : false} helperText={errors[t('sale')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.satTestPrefix} onChange={this.changeValue} name="satTestPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('saturationTest')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('saturationTest')] != null ? true : false} helperText={errors[t('saturationTest')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.servReqPrefix} onChange={this.changeValue} name="servReqPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('serviceRequest')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('serviceRequest')] != null ? true : false} helperText={errors[t('serviceRequest')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.shipmentPrefix} onChange={this.changeValue} name="shipmentPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('shipment')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('shipment')] != null ? true : false} helperText={errors[t('shipment')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.signinPrefix} onChange={this.changeValue} name="signinPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('signin')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('signin')] != null ? true : false} helperText={errors[t('signin')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.riskAssPrefix} onChange={this.changeValue} name="riskAssPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('taskRiskAssessment')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('taskRiskAssessment')] != null ? true : false} helperText={errors[t('taskRiskAssessment')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.testResPrefix} onChange={this.changeValue} name="testResPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('testResult')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('testResult')] != null ? true : false} helperText={errors[t('testResult')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.totePrefix} onChange={this.changeValue} name="totePrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('tote')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('tote')] != null ? true : false} helperText={errors[t('tote')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.trimmingPrefix} onChange={this.changeValue} name="trimmingPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('trimming')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('trimming')] != null ? true : false} helperText={errors[t('trimming')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.trimmingActivityPrefix} onChange={this.changeValue} name="trimmingActivityPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('trimmingActivity')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('trimmingActivity')] != null ? true : false} helperText={errors[t('trimmingActivity')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.vendorPrefix} onChange={this.changeValue} name="vendorPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('vendor')} inputProps={{ maxLength: 3 }}
                            error={errors[t('vendor')] != null ? true : false} helperText={errors[t('vendor')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.vauditPrefix} onChange={this.changeValue} name="vauditPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('vendorAudit')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('vendorAudit')] != null ? true : false} helperText={errors[t('vendorAudit')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.visitorPrefix} onChange={this.changeValue} name="visitorPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('visitor')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('visitor')] != null ? true : false} helperText={errors[t('visitor')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.wetLotMid} onChange={this.changeValue} name="wetLotMid" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('wetLot')} inputProps={{ maxLength: 3 }}
                            error={errors[t('wetLot')] != null ? true : false} helperText={errors[t('wetLot')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.wetWastePrefix} onChange={this.changeValue} name="wetWastePrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('wetWaste')} inputProps={{ maxLength: 3 }}
                            error={errors[t('wetWaste')] != null ? true : false} helperText={errors[t('wetWaste')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.wipPrefix} onChange={this.changeValue} name="wipPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('wipLot')} inputProps={{ maxLength: 3 }}
                            error={errors[t('wipLot')] != null ? true : false} helperText={errors[t('wipLot')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.workCenterPrefix} onChange={this.changeValue} name="workCenterPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('workCenter')} inputProps={{ maxLength: 3 }}
                            error={errors[t('workCenter')] != null ? true : false} helperText={errors[t('workCenter')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.sopPrefix} onChange={this.changeValue} name="sopPrefix" required
                            size="medium" fullWidth={true} disabled={!security.update} label={t('workRecord')} inputProps={{ maxLength: 3 }} 
                            error={errors[t('workRecord')] != null ? true : false} helperText={errors[t('workRecord')]}/>
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={4}>
                            <SaveButton saveClick={this.saveSettings} enabled={this.state.enableSave && (security.update || security.complete)} />
                            <RefreshButton refreshClick={this.refreshSettings} enabled={true}/>
                        </Grid>
                    </Grid>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(SettingPrefix));
