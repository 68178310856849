import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class Deviations extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            pages: 1,
            listSize: 5,
            orderBy: 'occurrenceDate',
            ordDir: -1,
            totalCount: 0,
            page: 0
        };
    }

    componentDidMount(){
        this.loadRecords();
    }

    async loadRecords(){
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: 'batch', value: this.props.batch},
            {id: 'ebatch', value: this.props.ebatch},
            {id: 'productionOrder', value: this.props.productionOrder},
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        var result = await axios.get('/api/deviations', {params: params});
        this.setState({
            list: result.data.data,
            pages: Math.ceil(result.data.totalCount / this.state.listSize),
            totalCount: result.data.totalCount
        });
    }

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Table>
                <TableHead>
                    <TableRow key='deviationsWidgetHead'>
                        <TableCell key='deviationsWidgetHead-number'>{t('number')}</TableCell>
                        <TableCell key='deviationsWidgetHead-date'>{t('occurred')}</TableCell>
                        <TableCell key='deviationsWidgetHead-type'>{t('type')}</TableCell>
                        <TableCell key='deviationsWidgetHead-impact'>{t('impact')}</TableCell>
                        <TableCell key='deviationsWidgetHead-status'>{t('status')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.list != null && this.state.list.length > 0 ?
                        this.state.list.map((row, i) => (
                        <TableRow>
                            <TableCell key={'deviationsWidgetnumber-' + i}><Link to={'/deviation/' + row._id}>{row.number}</Link></TableCell>
                            <TableCell key={'deviationsWidgetoccurred-' + i}><DateDisplay value={row.occurrenceDate} format={this.props.auth.user.dateFormat}/></TableCell>
                            <TableCell key={'deviationsWidgettype-' + i}>{row.type}</TableCell>
                            <TableCell key={'deviationsWidgetimpact-' + i}>{row.impact}</TableCell>
                            <TableCell key={'deviationsWidgetstatus-' + i}>{row.status}</TableCell>
                        </TableRow>
                    )):
                    <TableRow>
                        <TableCell colSpan={6} align='center'>{t('noData')}</TableCell>
                    </TableRow>
                    }
                </TableBody>
            </Table>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(Deviations));