import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import DateSelect from '../UI/DateSelect/DateSelect';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import SectionTitle from '../UI/Typography/SectionTitle';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';

class HazardousSpills extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            pages: 1,
            listSize: this.props.listSize ? this.props.listSize : 5,
            date: null,
            departments: [],
            department: '',
            locations: [],
            location: '',
            orderBy: 'dateOfIncident',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            depSel: null,
            locSel: null
        }
        this.dateChange = this.dateChange.bind(this);
    }

    async componentDidMount(){
        if(!this.props.auth.isAuthenticated) {
            this.props.history.push('/login');
        }
        var values = await axios.get("/api/hazardousvalues");
        this.setState({
            locations: values.data.locations,
            departments: values.data.departments
        });
        this.loadRecords();
    }

    loadRecords = async () => {
        var sort = {[this.state.orderBy]: this.state.ordDir};
        const data = {
            size: this.state.listSize,
            offset: this.state.offset,
            date: this.state.date,
            department: this.state.department,
            location: this.state.location,
            sort: JSON.stringify(sort)
        }
        const list = await axios.get('/api/hazardouslist', {params: data});
        this.setState({
            list: list.data.data,
            pages: Math.ceil(list.data.totalCount / this.state.listSize),
            totalCount: list.data.totalCount
        })
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    dateChange(e){
        this.setState({date: e, page: 0, offset: 0}, () => this.loadRecords());
    }

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.listSize);
        this.setState({ offset: offset }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <SectionTitle title={this.props.title}/>
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <DateSelect
                            className="form-control"
                            onChange={this.dateChange}
                            value={this.state.date}
                            label={t('date')}
                            format={this.props.auth.user.dateFormat}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <AutoCompleteField
                            value={this.state.depSel}
                            options={this.state.departments}
                            onChange={this.changeAuto('depSel', 'department')}
                            label={t('department')}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <AutoCompleteField
                            value={this.state.locSel}
                            options={this.state.locations}
                            onChange={this.changeAuto('locSel', 'location')}
                            label={t('location')}
                            disabled={this.state.locations.length === 1 ? true : false}
                        />
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="Injury/Incident from Hazardous Spill List">
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('dateOfIncident')}
                                        active={this.state.orderBy === 'dateOfIncident'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('occurred')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('department')}
                                        active={this.state.orderBy === 'department'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('department')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('completedBy')}
                                        active={this.state.orderBy === 'completedBy'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('completedBy')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('location')}
                                        active={this.state.orderBy === 'location'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('location')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list.map((record, i) =>
                                <TableRow key={record._id}>
                                    <TableCell><Link to={'/hazardous/' + record._id} name={'hazLink' + i}>{record.number }</Link></TableCell>
                                    <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={record.dateOfIncident}/></TableCell>
                                    <TableCell>{record.department.label}</TableCell>
                                    <TableCell>{record.completedBy != null ? record.completedBy.firstName + ' ' + record.completedBy.lastName : ''}</TableCell>
                                    <TableCell>{record.location.name}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Aux>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile
});

export default withTranslation() (connect(mapStateToProps)(HazardousSpills));
