import React, { Component } from 'react';
import axios from 'axios';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import ActivityWorkRecord from '../Cultivation/ActivityWorkRecord';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import { Link } from 'react-router-dom';
import { EvoraTabs, TabCanvas, TabPanel } from '../UI/Tabs/Tabs';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { approveSigSecurity, completeSigSecurity,
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import SectionTitle from '../UI/Typography/SectionTitle';
import { FormControl, FormLabel } from '@mui/material';
import { withTranslation } from 'react-i18next';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ItemLedgerComponent from '../Inventory/ItemLedgerComponent';
import { NumericFormat } from 'react-number-format';
import Box from '@mui/material/Box';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ConversionHelper from '../../helpers/conversionHelper';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import LabelPrint from '../UI/Buttons/LabelPrint';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import ConfirmDelete from '../General/ConfirmDelete';
import PrintView from '../Printing/PrintView';

class DryingActivity extends Component{
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            number: '',
            _id: '',
            status: '',
            statusSel: {value: '', label: ''},
            order: '',
            date: new Date(),
            users: [],
            usersSel: [],
            userList: [],
            effort: '',
            roomTemperature: '',
            tempUOM: '',
            tempUOMSel: null,
            tempUOMs: [],
            moistureContent: '',
            waterActivity: '',
            sopList: [],
            sopsSel: [],
            sopIds: [],
            sops: [],
            outputs: [],
            user: null,
            reviewer: null,
            signer: null,
            documents: null,
            itemList: [],
            uoms: [],
            tabKey: 0,
            tabLabels: [{name: t('dryingActivity'), disabled: false}],
            renderNum: Math.random(),
            signed: false,
            security: [],
            orderObj: {},
            item: '',
            itemSel: null,
            amount: '',
            uom: '',
            uomSel: null,
            bins: [],
            bin: '',
            binSel: null,
            reviewers: [],
            signers: [],
            optionsMenu: [],
            flagForSignature: 0,
            parentSigned: false,
            consumption: null,
            outputLot: '',
            outputLotSel: null,
            outputLots: [],
            enableUnlock: false,
            enableSave: true,
            enableDelete: false,
            customLot: '',
            sigData: null,
            activeUsers: [],
            activeManagers: [],
            activeQas: [],
            containerType: '',
            containerTypeSel: null,
            containerTypes: [],
            form: '',
            hasForm: false,
            tareWeight: '',
            equipment: [],
            equipsSel: [],
            equipmentList: [],
            strain: {},
            inputItems: [],
            inputItem: '',
            inputItemSel: null,
            inputAmount: '',
            inputContainers: [],
            inputContainer: '',
            inputContainerSel: null,
            inputs: [],
            inputUOM: '',
            inputUOMSel: null,
            inputCost: null,
            labourCost: null,
            totalCost: null,
            outputLabels: [],
            newLot: false,
            lots: [],
            container: '',
            containerSel: null,
            containers: [],
            deleteOpen: false,
            printViewer: false,
            finalDocument: null, 
            hasLedgers: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.user, common.cacheValues.status, common.cacheValues.quantityUOM,
                        common.cacheValues.containerType, common.cacheValues.bin, common.cacheValues.tempUOM, common.cacheValues.dryingActivityForm,
                        common.cacheValues.dryingActivitySop, common.cacheValues.ppeItem], this.props.auth.constants, null, this.props.auth.user.tenant);
        let allUsers = cacheValues.users;
        const userValues = await ValueHelper.screenUsers(common.screens.dryingActivity, this.props.auth.user.tenant);
        const params = queryString.parse(this.props.location.search);
        const order = params != null && params.order != null ? params.order : null;
        let query = {};
        if(order != null){
            query.order = order;
        }
        let values = await axios.get('/api/dryingactivityvalues', {params: query});
        let orderRec = null;
        let items = []
        if(order != null){
            orderRec = await axios.get('/api/dryingorder/' + order);
            let itemsResult = await axios.get('/api/dryingitemoutputs/' + orderRec.data.lotObj.item);
            items = [];
            itemsResult.data.forEach(row => {
                if(row != null && row._id != null){
                    items.push({
                        value: row._id,
                        label: row.number + '-' + row.name,
                        uom: row.uom,
                        lotTracked: row.tracking != null ? true : false,
                        customNumber: row.category.customLotNumber === true ? true : false,
                        tare: row.category != null && row.category.tareWeight === true ? true : false,
                        number: row.number,
                        strain: row.strain != null && row.strain !== '' ? row.strain.name : null,
                        strainCode: row.strain != null && row.strain !== '' ? row.strain.shortCode : null
                    });
                }
            });
        }

        let workRecords = [];
        let sopIds = [];
        let sopsSel = [];
        if(cacheValues.sops != null && cacheValues.sops.length > 0){
            cacheValues.sops.forEach(sop => {
                if(sop.required === true){
                    workRecords.push({
                        sop: sop.value,
                        original: true
                    });
                    sopIds.push(sop.value);
                    sopsSel.push(sop);
                }
            });
        }
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let activeQas = [];
        userValues.approve.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeQas.push(row);
            }
        })
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        this.setState({
            itemList: items,
            userList: userValues.create,
            uoms: cacheValues.quantityUOMs,
            tempUOMs: cacheValues.tempUOMs,
            order: order,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            sopList: cacheValues.sops,
            sopIds: sopIds,
            sopsSel: sopsSel,
            sops: workRecords,
            statuses: cacheValues.statuses,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            orderObj: orderRec != null ? orderRec.data : null,
            strain: orderRec != null ? orderRec.data.strain : null,
            bins: cacheValues.bins,
            reviewers: userValues.review,
            signers: userValues.approve,
            activeUsers: activeUsers,
            activeManagers: activeManagers,
            activeQas: activeQas,
            containerTypes: cacheValues.containerTypes,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            equipmentList: values.data.equipmentList != null ? values.data.equipmentList : [],
            allUsers: allUsers,
            inputItems: cacheValues.ppeItems,
            itemLots: values.data.itemLots != null ? values.data.itemLots : []
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/dryingorders', label: this.props.t('dryingOrders'), screen: this.props.auth.screenDefs.DryingOrder},
                    { path: '/#/dryingorder/' + orderRec.data._id, label: this.props.t('dryingOrder') + ' - ' + orderRec.data.number, screen: this.props.auth.screenDefs.DryingOrder},
                    { path: 'active', label: this.props.t('dryingActivity'), screen: this.props.auth.screenDefs.DryingActivity},
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        let record = await axios.get('/api/dryingactivity/' + this.state._id);
        let itemsResult = await axios.get('/api/dryingitemoutputs/' + record.data.orderObj.item);
        let items = [];
        itemsResult.data.forEach(row => {
            items.push({
                value: row._id,
                label: row.number + '-' + row.name,
                uom: row.uom,
                lotTracked: row.tracking != null ? true : false,
                customNumber: row.category.customLotNumber === true ? true : false,
                tare: row.category != null && row.category.tareWeight === true ? true : false,
                number: row.number,
                strain: row.strain != null && row.strain !== '' ? row.strain.name : null,
                strainCode: row.strain != null && row.strain !== '' ? row.strain.shortCode : null
            })
        });
        let equipsSel = [];
        if(record.data.equipment != null && record.data.equipment.length > 0){
            for(let row of record.data.equipment){
                let val = record.data.equipmentList.find(x => x.value === row);
                if(val != null){
                    equipsSel.push(val);
                }
            }
        }
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let tempUOMSel = this.state.tempUOMs.find(x => x.value === record.data.tempUOM);
        let usersSel = [];
        let userList = this.state.userList;
        record.data.users.forEach(row => {
            let val = this.state.userList.find(x => x.value === row);
            if(val != null){
                usersSel.push(val);
            } else {
                val = this.state.allUsers.find(x => x.value === row);
                if(val != null){
                    userList.push(val);
                    usersSel.push(val);
                }
            }
        });
        let consumption = record.data.consumption;
        if(consumption != null && consumption.item != null){
            consumption.uomSel = this.state.uoms.find(x => x.value === consumption.item.uom);
        }
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let tabLabels = [{name: t('dryingActivity'), disabled: false}];
        let sopIds = [];
        let sopsSel = [];
        record.data.sops.forEach(sop => {
            sopIds.push(sop.sop);
            let val = this.state.sopList.find(x => x.value === sop.sop);
            sopsSel.push(val);
            tabLabels.push({name: val.label, disabled: false});
        });
        if(record.data.hasLedgers){
            tabLabels.push({name: t('itemLedgers'), disabled: false});
        }
        let outputLabels = [];
        let itemLots = record.data.itemLots;
        let outputs = record.data.outputs;
        if(outputs != null && outputs.length > 0){
            outputs.forEach(row => {
                let itemSel = items.find(x => x.value === row.item);
                row.itemSel = itemSel;
                let uomSel = this.state.uoms.find(x => x.value === row.uom);
                row.uomSel = uomSel;
                let binSel = this.state.bins.find(x => x.value === row.bin);
                row.binSel = binSel;
                let itemLotRow = itemLots.find(x => x.item === row.item);
                let lots = itemLotRow != null ? itemLotRow.values : [];
                let containers = [];
                if(row.lot != null){
                    let lotRow = itemLotRow.lots != null ? itemLotRow.lots.find(x => x.lot === row.lot) : null;
                    if(lotRow != null){
                        containers = lotRow.containers;
                    }
                }
                let lotSel = row.lot != null ? lots.find(x => x.value === row.lot) : null;
                let containerSel = row.container != null ? containers.find(x => x.value === row.container) : null;
                let contType = this.state.containerTypes.find(x => x.value === row.containerType);
                row.containerTypeSel = contType;
                row.lotSel = lotSel;
                row.containerSel = containerSel;
                row.containers = containers;
                row.lots = lots;
                if(containerSel != null &&
                   uomSel != null && contType != null && itemSel != null) {
                    outputLabels.push({
                        id: row.container,
                        container: row.containerSel.number,
                        lot: row.customLot != null ? row.customLot :
                             lotSel != null ? lotSel.label : '',
                        item: itemSel.label,
                        quantity: ConversionHelper.round3(row.amount),
                        uom: uomSel.label,
                        category: contType.label,
                        itemNumber: itemSel.number,
                        date: record.data.date,
                        qrPath: `${window.location.origin}/#/container/${row.container}`,
                        strain: itemSel.strain,
                        strainCode: itemSel.strainCode,
                        dateFormat: this.props.auth.user.dateFormat
                    });
                }
            });
        }
        let inputs = record.data.inputs;
        if(inputs != null){
            for(let row of inputs){
                let itemSel = this.state.inputItems.find(x => x.value === row.item);
                row.itemSel = itemSel;
                let uomSel = this.state.uoms.find(x => x.value === row.uom);
                row.uomSel = uomSel;
                if(row.container != null){
                    let containerSel = row.containers.find(x => x.value === row.container);
                    row.containerSel = containerSel;
                }
            }
        } else {
            inputs = [];
        }
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: usersSel, name: t('complete'), object: record.data._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user,
                objectType: this.props.auth.constants.objectIds.dryingActivity, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, saveCallback: this.saveCallback
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: !internal && this.state.reviewer == null ? this.state.activeManagers : this.state.reviewers,
                name: t('review'), title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.dryingActivity, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: !internal && this.state.signer == null ? this.state.activeQas : this.state.signers,
                name: t('approve'), title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer,
                objectType: this.props.auth.constants.objectIds.dryingActivity, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        let parentSigned = record.data.orderObj.status === this.props.auth.constants.statuses.complete ||
                        record.data.orderObj.status === this.props.auth.constants.statuses.reviewed ||
                        record.data.orderObj.status === this.props.auth.constants.statuses.signed;
        let security = this.state.security;
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        let params = queryString.parse(this.props.location.search);
        let enableDelete = this.props.permission.delete && (record.data.status === this.props.auth.constants.statuses.inprogress ||
            record.data.status === this.props.auth.constants.statuses.new);
        this.setState({
            number: record.data.number,
            status: record.data.status,
            statusSel: statusSel,
            date: record.data.date,
            order: record.data.order,
            orderObj: record.data.orderObj,
            effort: record.data.effort,
            roomTemperature: record.data.roomTemperature,
            tempUOM: record.data.tempUOM,
            tempUOMSel: tempUOMSel,
            moistureContent: record.data.moistureContent,
            waterActivity: record.data.waterActivity,
            user: record.data.user,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            documents: record.data.documents,
            outputs: outputs,
            fieldAccess: fieldAccess,
            sopIds: sopIds,
            sopsSel: sopsSel,
            sops: record.data.sops,
            tabLabels: tabLabels,
            users: record.data.users,
            usersSel: usersSel,
            itemList: items,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            parentSigned: parentSigned,
            security: security,
            consumption: consumption,
            sigData: null,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            equipmentList: record.data.equipmentList,
            equipment: record.data.equipment,
            equipsSel: equipsSel,
            enableDelete: enableDelete,
            strain: record.data.strain,
            userList: userList,
            inputs: inputs,
            inputCost: record.data.inputCost,
            labourCost: record.data.labourCost,
            totalCost: record.data.totalCost,
            outputLabels: outputLabels,
            itemLots: itemLots,
            renderNum: Math.random(),
            deleteOpen: false,
            finalDocument: record.data.finalDocument,
            hasLedgers: record.data.hasLedgers
        }, () => {
            const crumbs = [
                { path: '/#/dryingorders', label: this.props.t('dryingOrders'), screen: this.props.auth.screenDefs.DryingOrder},
                { path: '/#/dryingorder/' + record.data.order, label: this.props.t('dryingOrder') + ' - ' + record.data.orderObj.number, screen: this.props.auth.screenDefs.DryingOrder},
                { path: 'active', label: this.props.t('dryingActivity') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.DryingActivity},
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.determineUnlock();
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            if(params.tab != null)
                this.openNotification(params);
        });
    }

    openNotification = (params) => {
        let tabKey = this.state.tabKey;
        if(params != null && params.tab != null)
            tabKey = Number(params.tab);
        this.setState({tabKey});
    }

    determineUnlock() {
        let enableUnlock = false;
        let state = this.state;
        let constants = this.props.auth.constants;
        if(!state.parentSigned){
            if(state.status !== constants.statuses.inprogress && state.status !== constants.statuses.new){
                let outputsLock = false;
                if(state.outputs != null && state.outputs.length > 0){
                    for(let row of state.outputs){
                        if(row.containerSel != null){
                            if(ConversionHelper.round3(parseFloat(row.amount)) > ConversionHelper.round3(parseFloat(row.containerSel.quantity))){
                                outputsLock = true;
                                break;;
                            }
                        }
                    }
                }
                if(!outputsLock){
                    enableUnlock = true;
                }
            }
        }
        this.setState({enableUnlock: enableUnlock});
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
        if(name === 'newLot'){
            this.setState({outputLot: '', outputLotSel: null, container: '', containerSel: null})
        }
    }

    changeAuto = (name, data) => async (e, newValue) => {
        let uom = this.state.uom;
        let uomSel = this.state.uomSel;
        let outputLots = this.state.outputLots;
        let inputUOMSel = this.state.inputUOMSel;
        let inputUOM = this.state.inputUOM;
        let inputContainers = this.state.inputContainers;
        let containers = this.state.containers;
        let container = this.state.container;
        let containerSel = this.state.containerSel;
        let outputLot = this.state.outputLot;
        let outputLotSel = this.state.outputLotSel;
        if(data === 'item'){
            outputLot = '';
            outputLotSel = null;
            container = '';
            containerSel = null;
            containers = [];
            if(newValue != null){
                uom = newValue.uom;
                uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                let itemLots = this.state.itemLots;
                if(itemLots.length > 0){
                    let itemRow = itemLots.find(x => x.item === newValue.value);
                    if(itemRow != null){
                        outputLots = itemRow.values;
                    }
                }
            } else {
                uom = '';
                uomSel = null;
                outputLots = [];
            }
        } else if (data === 'inputItem'){
            inputUOMSel = null;
            inputUOM = '';
            inputContainers = [];
            if(newValue != null){
                if(newValue.lotTracked === true){
                    let result = await axios.get('/api/itemcontainers', {items: [newValue.value], date: this.state.date, available: true, consume: true})
                    inputContainers = result.data;
                }
                inputUOM = newValue.uom;
                inputUOMSel = this.state.uoms.find(x => x.value === newValue.uom);
            }
        } else if (data === 'outputLot'){
            container = '';
            containerSel = null;
            if(newValue != null){
                outputLot = newValue.value
                outputLotSel = newValue;
                let itemLots = this.state.itemLots;
                let itemRow = itemLots.find(x => x.item === this.state.item);
                if(itemRow != null){
                    let lotRow = itemRow.lots != null ? itemRow.lots.find(x => x.lot === newValue.value) : null;
                    if(lotRow != null){
                        containers = lotRow.containers;
                    }
                }
            } else {
                containers = [];
            }
        } else if (data === 'container'){
            if(newValue != null){
                container = newValue.value;
                containerSel = newValue;
            } else {
                container = '';
                containerSel = null;
            }
        }
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            uom: uom,
            uomSel: uomSel,
            outputLots: outputLots,
            inputUOMSel: inputUOMSel,
            inputUOM: inputUOM,
            inputContainers: inputContainers,
            containers: containers,
            container: container,
            containerSel: containerSel,
            outputLotSel: outputLotSel,
            outputLot: outputLot
        });
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeMulti = (name, data) => (e, newValue) => {
        var val = newValue;
        var ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        });
    };

    changeSop = (e, newValue) => {
        let sops = this.state.sops;
        let tabLabels = this.state.tabLabels;
        if(newValue != null){
            if(sops.length > newValue.length){
                for(let i = 0; i < sops.length; i++){
                    let sop = sops[i];
                    let rec = newValue.find(x => x.value === sop.sop);
                    if(!rec){
                        sops.splice(i, 1);
                        tabLabels.splice(i + 1, 1);
                        break;
                    }
                }
            } else {
                for(let i = 0; i < newValue.length; i++){
                    let val = newValue[i];
                    let rec = sops.find(x => x.sop === val.value)
                    if(!rec){
                        sops.push({
                            sop: val.value,
                            workRecord: null,
                            original: false
                        });
                        if(this.state._id != null && this.state._id !== '')
                            tabLabels.push({name: val.label, disabled: false});
                    }
                }
            }
            let sopIds = [];
            newValue.forEach(val => {
                sopIds.push(val.value);
            });
            this.setState({
                sopIds: sopIds,
                sopsSel: newValue,
                sops: sops
            });
        } else {
            for(let i = 0; i < sops.length; i++){
                let sop = sops[i];
                if(sop.required)
                    return;
            }
            this.setState({sops: [], sopIds: [], sopsSel: [], tabLabels: [{name: this.props.t('drying'), disabled: false}]});
        }
    };

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.save()})
    };

    save = async () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let outputs = this.state.outputs;
        outputs.forEach(row => {
            if(row.lot === '')
                row.lot = null;
            row.container = row.container !== '' ? row.container : null;
        });
        let data = {
            _id: this.state._id,
            status: this.state.status,
            effort: this.state.effort,
            date: this.state.date,
            users: this.state.users,
            order: this.state.order,
            roomTemperature: this.state.roomTemperature,
            tempUOM: this.state.tempUOM,
            moistureContent: this.state.moistureContent,
            waterActivity: this.state.waterActivity,
            sops: this.state.sops,
            outputs: outputs,
            sigData: this.state.sigData,
            equipment: this.state.equipment,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            inputs: this.state.inputs
        };
        try {
            let result = await axios.post('/api/dryingactivity', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/dryingactivity/' + result.data.id);
            this.setState({_id: result.data.id, number: result.data.number, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.status == null || state.status === '')
            errors[t('status')] = t('required');
        if(state.date == null || state.date === ''){
            errors[t('date')] = t('required');
        } else {
            let validEndDate = ConversionHelper.validActivityDate(this.state.date, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
                if(!validEndDate){
                    errors[t('date')] = t('dateBeforeLock');
                }
        }
        if(state.users == null || state.users.length === 0)
            errors[t('date')] = t('required');
        if(state.effort == null || state.effort === '')
            errors[t('effort')] = t('required');
        if(state.roomTemperature != null && state.roomTemperature !== ''){
            if(state.tempUOM == null || state.tempUOM === '')
                errors[t('tempUOM')] = t('required');
        }
        let totalOutput = 0;
        this.state.outputs.forEach(row => {
            let floatAmount = parseFloat(row.amount);
            totalOutput += floatAmount;
        });
        if((this.state.item != null && this.state.item !== '' ) || (this.state.bin != null && this.state.bin !== '') ||
        (this.state.amount != null && this.state.amount !== '' && this.state.amount !== '0' && this.state.amount !== 0) ||
        (this.state.outputLot != null && this.state.outputLot !== ''))
            errors[t('item')] = t('rowNotAdded');
        if(totalOutput > this.state.orderObj.remainingAmount){
            errors[`${t('outputAmount')} (${t('line')} 0)`] = t('exceedRemaining') + " " + this.state.orderObj.remainingAmount;
        }
        for(let i = 0; i < state.outputs.length; i++){
            let output = state.outputs[i];
            if(output.item == null || output.item === '')
                errors[`${t('item')} (${t('line')} ${i})`] = t('required');
            if(output.amount == null || output.amount === '' || output.amount === 0){
                errors[`${t('outputAmount')} (${t('line')} ${i})`] = t('required');
            }
            if(output.bin == null || output.bin === '')
                errors[`${t('bin')} (${t('line')} ${i})`] = t('required');
            if(output.itemSel != null && output.itemSel.customNumber === true && (output.customLot == null || output.customLot === '') && output.lotSel == null)
                errors[`${t('customLot')} (${t('line')} ${i})`] = t('required');
            if(output.customLot != null && output.customLot !== '' && output.item != null && output.item !== ''){
                let matchLots = state.outputs.filter(x => x.customLot === output.customLot && x.item !== output.item);
                if(matchLots != null && matchLots.length > 0){
                    errors[`${t('customLot')} (${t('line')} ${i})`] = t('lotNumberUsed');
                }
            }
        }
        state.inputs.forEach((row, i) => {
            if(row.item == null || row.item === '')
                errors[`${t('inputItem')} (${t('line')} ${i})`] = t('required');
            if(row.amount == null || row.amount === '' || row.amount === 0 || row.amount === '0')
                errors[`${t('inputAmount')} (${t('line')} ${i})`] = t('required');
            if(row.itemSel != null && row.itemSel.lotTracked === true){
                if(row.container == null || row.container === '')
                    errors[`${t('inputContainer')} (${t('line')} ${i})`] = t('required');
            }
        });
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    changeOutputAuto = (name, data, index) => (e, newValue) => {
        let outputs = this.state.outputs;
        let output = outputs[index];
        output[name] = newValue;
        output[data] = newValue != null ? newValue.value : null;
        if(data === 'lot'){
            if(newValue != null){
                let itemLots = this.state.itemLots;
                let itemRow = itemLots.find(x => x.item === output.item);
                if(itemRow != null){
                    let lotRow = itemRow.lots != null ? itemRow.lots.find(x => x.lot === newValue.value) : null;
                    output.containers = lotRow != null ? lotRow.containers : [];
                }
            } else {
                output.containers = [];
                output.container = '';
                output.containerSel = null;
            }
        }
        this.setState({
            outputs: outputs
        });
    };

    changeOutputNumber = (name, index) => (e) => {
        let outputs = this.state.outputs;
        let output = outputs[index];
        let value = e.target.value;
        if(isNaN(value))
            return;
        output[name] = value;
        this.setState({outputs: outputs});
    };

    changeOutputValue = (name, index) => (e) => {
        let outputs = this.state.outputs;
        let output = outputs[index];
        let value = e.target.value;
        output[name] = value;
        this.setState({outputs: outputs});
    };

    changeOutputBool = (name, index) => (e) => {
        let outputs = this.state.outputs;
        let output = outputs[index];
        output[name] = !output[name]
        if(name === 'newLot'){
            if(output[name] === true){
                output.container = '';
                output.containerSel = null;
                output.lot = '';
                output.lotSel = null;
            } else {
                output.customLot = '';
            }
        }
        this.setState({outputs: outputs});
    }

    addOutput = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.item == null || this.state.item === '')
            errors[t('item')] = t('required');
        if(this.state.amount == null || this.state.amount === '')
            errors[t('amount')] = t('required');
        if(this.state.bin == null || this.state.bin === '')
            errors[t('bin')] = t('required');
        if(this.state.itemSel != null && this.state.itemSel.customNumber === true){
            if((this.state.customLot == null || this.state.customLot === '') && (this.state.newLot === true || this.state.outputLotSel == null)){
                errors[t('customLot')] = t('required');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let outputs = this.state.outputs;
        outputs.push({
            item: this.state.item,
            itemSel: this.state.itemSel,
            amount: this.state.amount,
            uom: this.state.uom,
            uomSel: this.state.uomSel,
            bin: this.state.bin,
            binSel: this.state.binSel,
            lot: this.state.outputLot,
            lotSel: this.state.outputLotSel,
            lots: this.state.outputLots,
            container: this.state.container,
            containerSel: this.state.containerSel,
            containers: this.state.containers,
            containerType: this.state.containerType,
            containerTypeSel: this.state.containerTypeSel,
            customLot: this.state.customLot != null && this.state.customLot !== '' ? this.state.customLot.toUpperCase() : null,
            tareWeight: this.state.tareWeight,
            newLot: this.state.newLot
        });
        this.setState({
            outputs: outputs,
            item: '',
            itemSel: null,
            amount: '',
            uom: '',
            uomSel: null,
            bin: '',
            binSel: null,
            outputLot: '',
            outputLotSel: null,
            outputLots: [],
            customLot: '',
            errors: {},
            containerType: '',
            containerTypeSel: null,
            tareWeight: '',
            newLot: false,
            container: '',
            containerSel: null,
            containers: []
        });
    };

    deleteOutput = (index) => (e) => {
        let outputs = this.state.outputs;
        outputs.splice(index, 1);
        this.setState({outputs: outputs});
    };

    validateComplete(){
        const t = this.props.t;
        let state = this.state;
        let valid = this.validate();
        if(!valid)
            return t('notAllComplete');
        if(state.sops != null && state.sops.length > 0){
            for(let i = 0; i < state.sops.length; i++){
                let sop = state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        return null;
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e));
    }

    _deleteClick = async (e) => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            if(this.state.status === this.props.auth.constants.statuses.new || this.state.status === this.props.auth.constants.statuses.inprogress){
                try {
                    let result = await axios.delete('/api/dryingactivity/' + this.state._id);
                    this.setState({enableSave: true});
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                    this.props.history.goBack()
                } catch(err){
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
                }
            } else {
                this.setState({enableSave: true});
                this.deleteToggle();
            }
        } else {
            this.props.history.goBack()
        }
    };

    changeInputAuto = (name, data, index) => async (e, newValue) => {
        let rows = this.state.inputs;
        let row = rows[index];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : null;
        if(data === 'item'){
            row.containers = [];
            row.container = '';
            row.containerSel = null;
            row.lot = null;
            row.reservation = null;
            row.uom = '';
            row.uomSel = null;
            if(newValue != null){
                if(newValue.lotTracked === true){
                    let result = await axios.get('/api/itemcontainers', {items: [newValue.value], date: this.state.date, available: true, consume: true});
                    row.containers = result.data;
                }
                row.uomSel = this.state.uoms.find(x => x.newValue.uom);
            }
        }
        this.setState({
            inputs: rows
        });
    };

    changeInputNumber = (name, index) => (e) => {
        let rows = this.state.inputs;
        let row = rows[index];
        let value = e.target.value;
        if(isNaN(value))
            return;
        row[name] = value;
        this.setState({inputs: rows});
    };

    changeInputValue = (name, index) => (e) => {
        let rows = this.state.inputs;
        let row = rows[index];
        let value = e.target.value;
        row[name] = value;
        this.setState({inputs: rows});
    };

    addInput = (e) => {
        let errors = {};
        const t = this.props.t;
        let state = this.state;
        if(state.inputItem == null || state.inputItem === '')
            errors[t('inputItem')] = t('required');
        if(state.inputAmount == null || state.inputAmount === '' || state.inputAmount === 0 || state.inputAmount === '0')
            errors[t('inputAmount')] = t('required');
        if(state.inputItemSel != null && state.inputItemSel.lotTracked === true){
            if(state.inputContainer == null || state.inputContainer === '')
                errors[t('inputContainer')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let inputs = state.inputs;
        inputs.push({
            item: state.inputItem,
            itemSel: state.inputItemSel,
            amount: state.inputAmount,
            uom: state.inputUOM,
            uomSel: state.inputUOMSel,
            container: state.inputContainer !== '' ? state.inputContainer : null,
            containerSel: state.inputContainer,
            containers: state.inputContainers
        });
        this.setState({
            inputs: inputs,
            inputItem: '',
            inputItemSel: null,
            inputAmount: '',
            inputUOM: '',
            inputUOMSel: null,
            inputContainer: '',
            inputContainerSel: null,
            inputContainers: []
        })
    }

    deleteInput = (index) => (e) => {
        let rows = this.state.inputs;
        rows.splice(index, 1);
        this.setState({inputs: rows});
    };

    togglePrintViewer = () => {
        this.setState({printViewer: !this.state.printViewer});
    }

    render(){
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const t = this.props.t;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        const internal = this.props.auth.user.internal;
        const hasDevices = this.props.devices.hasRegisteredDevices;
        const preInventoryLabel = this.props.auth.user.preInventoryLabel;
        const micro = this.props.auth.user.micro;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        labelData={this.state.outputLabels}
                        labelAmount='multiple'
                        printViewClick={this.togglePrintViewer}
                        printViewTitle={t('dryingActivity')}
                        enablePrintView={this.state.status === this.props.auth.constants.statuses.signed}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.dryingActivity}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <Grid container spacing={2}>
                    <Grid item lg={this.state.statusSel.label !== 'New' && this.state.completeSigSec ? 9 : 12} sm={12}>
                        <TabPanel key={0} value={this.state.tabKey} index={0}>
                            <Grid container spacing={3}>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <DateSelect
                                            onChange={this.dateChange('date')}
                                            value={this.state.date}
                                            helperText={errors[t('date')]}
                                            error={errors[t('plannedStart')] != null ? true : false}
                                            label={t('date')}
                                            disabled={!fieldAccess}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('date')}</FormLabel>
                                            <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                {this.state.orderObj != null && this.state.orderObj._id != null &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('order')}</FormLabel>
                                            <Link to={'/dryingorder/' + this.state.orderObj._id}>{this.state.orderObj.number}</Link>
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.strain != null && this.state.strain._id != null &&
                                    <Grid item xs={6} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('strain')}</FormLabel>
                                            <Link to={'/strain/' + this.state.strain._id}>{this.state.strain.name}</Link>
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.orderObj != null && this.state.orderObj.remainingAmount != null &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('remaining')}</FormLabel>
                                            <NumericFormat value={this.state.orderObj.remainingAmount} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField type="number" value={this.state.effort} onChange={this.changeNumber} name="effort"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('effortM')} required
                                        error={errors[t('effort')] != null ? true : false} helperText={errors[t('effort')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('effortM')}</FormLabel>
                                            {this.state.effort}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField type="number" value={this.state.roomTemperature} onChange={this.changeNumber} name="roomTemperature"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('roomTemp')}
                                        error={errors[t('roomTemperature')] != null ? true : false} helperText={errors[t('roomTemperature')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('roomTemp')}</FormLabel>
                                            {this.state.roomTemperature}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.tempUOMSel}
                                            options={this.state.tempUOMs}
                                            onChange={this.changeAuto('tempUOMSel', 'tempUOM')}
                                            error={errors[t('tempUOM')] != null ? true : false}
                                            helperText={errors[t('tempUOM')]}
                                            label={t('uom')}
                                            disabled={!fieldAccess}
                                            required={this.state.roomTemperature != null && this.state.roomTemperature !== ''}
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('uom')}</FormLabel>
                                            {this.state.tempUOMSel ? this.state.tempUOMSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField type="number" value={this.state.moistureContent} onChange={this.changeNumber} name="moistureContent"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('moisturePercent')}
                                        error={errors[t('moistureContent')] != null ? true : false} helperText={errors[t('moistureContent')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('moisturePercent')}</FormLabel>
                                            {this.state.moistureContent}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField type="number" value={this.state.waterActivity} onChange={this.changeNumber} name="waterActivity"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('waterActivity')}
                                        error={errors[t('waterActivity')] != null ? true : false} helperText={errors[t('waterActivity')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('waterActivity')}</FormLabel>
                                            {this.state.waterActivity}
                                        </FormControl>
                                    }
                                </Grid>
                                {this.state.equipmentList?.length > 0 ?
                                    <Grid item sm={6}>
                                        <AutoCompleteField
                                            multiple={true} disabled={!fieldAccess}
                                            value={this.state.equipsSel}
                                            options={this.state.equipmentList}
                                            onChange={this.changeMulti('equipsSel', 'equipment')}
                                            error={errors[t('equipment')] != null ? true : false}
                                            helperText={errors[t('equipment')]}
                                            label={t('equipment')}
                                        />
                                    </Grid> : null
                                }
                            </Grid>
                            <br/>
                            {this.state.hasForm === true &&
                                <Aux>
                                    <br/>
                                    {errors[t('form')] != null && errors[t('form')] !== '' &&
                                        <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                    }
                                    <Form renderer={renderer}
                                        defaultFields={this.state.form}
                                        onChange={this.formValueChanged}
                                        disabled={!fieldAccess}/>
                                </Aux>
                            }
                            <Grid container spacing={3}>
                                <Grid item sm={11}>
                                    <AutoCompleteField
                                        multiple={true} disabled={!fieldAccess}
                                        value={this.state.usersSel}
                                        options={fieldAccess && !internal ? this.state.activeUsers : this.state.userList}
                                        onChange={this.changeMulti('usersSel', 'users')}
                                        error={errors[t('date')] != null ? true : false}
                                        helperText={errors[t('date')]}
                                        label={t('users')}
                                        required
                                    />
                                </Grid>
                                {this.state.sopList != null && this.state.sopList.length > 0 &&
                                    <Grid item xs={11}>
                                        <AutoCompleteField
                                            multiple={true}
                                            value={this.state.sopsSel}
                                            options={this.state.sopList}
                                            onChange={this.changeSop}
                                            error={errors[t('sops')] != null ? true : false}
                                            helperText={errors[t('sops')]}
                                            label={t('workRecs')}
                                            disabled={!fieldAccess}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            {!fieldAccess && this.state.totalCost != null && this.state.totalCost !== '' && this.state.totalCost !== 0 &&
                                <Aux>
                                    <br/>
                                    <SectionTitle title={t('costs')}/>
                                    <Grid container spacing={3}>
                                        {this.state.inputCost != null && this.state.inputCost !== '' &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('costItems')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.inputCost} thousandSeparator={true}
                                                    decimalScale={2} prefix="$"/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.labourCost != null && this.state.labourCost !== '' &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('labour')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.labourCost} thousandSeparator={true}
                                                    decimalScale={2} prefix="$"/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.totalCost != null && this.state.totalCost !== '' &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('total')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.totalCost} thousandSeparator={true}
                                                    decimalScale={2} prefix="$"/>
                                                </FormControl>
                                            </Grid>
                                        }
                                    </Grid>
                                    <br/>
                                </Aux>
                            }
                            <br/>
                            <SectionTitle title={t('outputs')}/>
                            {this.state.outputs != null && this.state.outputs.length > 0 && this.state.outputs.map((row, i) =>
                                <Box borderColor="primary.main" {...defaultProps}  key={'outputs' + i} component={'div'}>
                                    <Grid container spacing={3} key={row._id}>
                                        <Grid item sx={{width: 350}}>
                                            <FormControl>
                                                <FormLabel>{t('item')}</FormLabel>
                                                {row.itemSel != null && <Link to={'/item/' + row.itemSel.value}>{row.itemSel.label}</Link>}
                                            </FormControl>
                                        </Grid>
                                        {this.state.status !== this.props.auth.constants.statuses.reviewed && this.state.status !== this.props.auth.constants.statuses.signed &&
                                            <Grid item sx={{width: 140}}>
                                                <CheckboxField label={t('newLot')}
                                                checked={row.newLot} onChange={this.changeOutputBool('newLot', i)}
                                                name="newLot" disabled={!fieldAccess}
                                                />
                                            </Grid>
                                        }
                                        {row.itemSel != null && row.itemSel.lotTracked && row.itemSel.customNumber && row.lotSel == null && (row.newLot === true || 
                                        row.lots == null || row.lots.length === 0) &&
                                            <Grid item sx={{width: 150}}>
                                                <TextField type="text" value={row.customLot} onChange={this.changeOutputValue('customLot', i)} name={"customLot" + i}
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('lot')}
                                                error={errors[`${t('customLot')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('customLot')} (${t('line')} ${i})`]}/>
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 110}} align="right">
                                            {fieldAccess &&
                                                <TextField type="number" value={row.amount} onChange={this.changeOutputNumber('amount', i)} name={"outputAmount" + i}
                                                size="medium" fullWidth={true} disabled={!fieldAccess} required label={t('quantity') + (row.uomSel != null && row.uomSel.label != null ? ` (${row.uomSel.label})` : '')}
                                                error={errors[`${t('outputAmount')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('outputAmount')} (${t('line')} ${i})`]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('quantity') + (row.uomSel != null && row.uomSel.label != null ? ` (${row.uomSel.label})` : '')}</FormLabel>
                                                    <NumericFormat value={row.amount} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        {row.itemSel != null && row.itemSel.tare === true &&
                                            <Grid item sx={{width: 130}} align="right">
                                                {fieldAccess &&
                                                    <TextField type="number" value={row.tareWeight} onChange={this.changeOutputNumber('tareWeight', i)} name={"tareWeight" + i}
                                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('tareWeight')}
                                                    error={errors[`${t('tareWeight')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('tareWeight')} (${t('line')} ${i})`]}/>
                                                }
                                                {!fieldAccess &&
                                                    <FormControl>
                                                        <FormLabel>{t('tareWeight')}</FormLabel>
                                                        <NumericFormat value={row.tareWeight} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                    </FormControl>
                                                }
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 250}}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={row.binSel}
                                                    options={this.state.bins}
                                                    onChange={this.changeOutputAuto('binSel', 'bin', i)}
                                                    error={errors[`${t('bin')} (${t('line')} ${i})`] != null ? true : false}
                                                    helperText={errors[`${t('bin')} (${t('line')} ${i})`]}
                                                    disabled={!fieldAccess }
                                                    required
                                                    label={t('bin')}
                                                    groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                    noneMessage={t('noRoomSelected')}
                                                />
                                            }
                                            {!fieldAccess && row.binSel != null &&
                                                <FormControl>
                                                    <FormLabel>{t('bin')}</FormLabel>
                                                    {row.binSel != null ? row.binSel.label : ''}
                                                </FormControl>
                                            }
                                        </Grid>
                                        {row.lotSel != null && !fieldAccess &&
                                            <Grid item sx={{width: 150}}>
                                                <FormControl>
                                                    <FormLabel>{t('lot')}</FormLabel>
                                                    <Link to={'/lot/' + row.lotSel.value}>{row.lotSel.label}</Link>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {row.lots != null && row.lots.length > 0 && fieldAccess && row.newLot !== true &&
                                            <Grid item sx={{width: 220}}>
                                                <AutoCompleteField
                                                    value={row.lotSel}
                                                    options={row.lots}
                                                    onChange={this.changeOutputAuto('lotSel', 'lot', i)}
                                                    error={errors[`${t('lot')} (${t('line')} ${i})`] != null ? true : false}
                                                    helperText={errors[`${t('lot')} (${t('line')} ${i})`]}
                                                    disabled={!fieldAccess }
                                                    label={t('lot')}
                                                />
                                            </Grid>
                                        }
                                        {row.containerSel != null && !fieldAccess &&
                                            <Grid item sx={{width: 220}}>
                                                <FormControl>
                                                    <FormLabel sx={{ ml: 4 }}>{t('container')}</FormLabel>
                                                    <Grid container>
                                                        <Grid item>
                                                            {hasDevices &&
                                                                <LabelPrint
                                                                    containerData={this.state.outputLabels != null && this.state.outputLabels.length > 0 ?
                                                                                   this.state.outputLabels.find(x => x.id === row.container) : null}
                                                                    printAmount='single'
                                                                />
                                                            }
                                                        </Grid>
                                                        <Grid item>
                                                            <Link to={'/container/' + row.containerSel.value} sx={{ display: 'inline' }}>{row.containerSel.label}</Link>
                                                        </Grid>
                                                    </Grid>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {fieldAccess && row.containers != null && row.containers.length > 0 && row.newLot !== true &&
                                             <Grid item sx={{width: 230}}>
                                                <AutoCompleteField
                                                    value={row.containerSel}
                                                    options={row.containers}
                                                    onChange={this.changeOutputAuto('containerSel', 'container', i)}
                                                    error={errors[`${t('container')} (${t('line')} ${i})`] != null ? true : false}
                                                    helperText={errors[`${t('container')} (${t('line')} ${i})`]}
                                                    disabled={!fieldAccess }
                                                    label={t('container')}
                                                />
                                            </Grid>
                                        }
                                        {fieldAccess &&
                                            <Grid item sx={{width: 150}}>
                                                <AutoCompleteField
                                                    value={row.containerTypeSel}
                                                    options={this.state.containerTypes}
                                                    onChange={this.changeOutputAuto('containerTypeSel', 'containerType', i)}
                                                    error={errors[`${t('containerType')} (${t('line')} ${i})`] != null ? true : false}
                                                    helperText={errors[`${t('containerType')} (${t('line')} ${i})`]}
                                                    disabled={!fieldAccess }
                                                    label={t('containerType')}
                                                />
                                            </Grid>
                                        }
                                        {!fieldAccess && row.containerTypeSel != null &&
                                            <Grid item sx={{width: 150}}>
                                                <FormControl>
                                                    <FormLabel>{t('containerType')}</FormLabel>
                                                    {row.containerTypeSel.label}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {fieldAccess &&
                                            <Grid container item sx={{ width: 100 }}>
                                                <Grid item>
                                                    <IconButton aria-label="delete output" onClick={this.deleteOutput(i)}>
                                                        <RemoveCircleTwoToneIcon  />
                                                    </IconButton>
                                                </Grid>
                                                <Grid item sx={{ mt: 1 }}>
                                                    {hasDevices && preInventoryLabel && row.amount != null && row.amount > 0 &&
                                                        <LabelPrint 
                                                            containerData={{
                                                                container: row.itemSel != null && row.itemSel.lotTracked && row.itemSel.customNumber &&
                                                                            row.customLot != null && row.customLot !== '' ? row.customLot + '-' + (i + 1) : '',
                                                                lot: row.itemSel != null && row.itemSel.lotTracked && 
                                                                        row.itemSel.customNumber && row.customLot != null && row.customLot !== '' ? row.customLot : '',
                                                                quantity: ConversionHelper.round3(row.amount),
                                                                uom: row.uomSel.label,
                                                                category: row.containerTypeSel != null && row.containerTypeSel !== '' ? row.containerTypeSel.label : t('regular'),
                                                                item: row.itemSel != null && row.itemSel !== '' && row.itemSel.label != null ? row.itemSel.label : '',
                                                                strain: row.itemSel != null && row.itemSel.strain != null ? row.itemSel.strain : '',
                                                                itemNumber: row.itemSel != null && row.itemSel.number != null ? row.itemSel.number : '',
                                                                date: this.state.date,
                                                                preInventory: true
                                                            }}
                                                            printAmount='single'
                                                        />
                                                    }
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            )}
                            {fieldAccess &&
                                <Box borderColor="primary.main" {...defaultProps}  component={'div'}>
                                    <Grid container spacing={3}>
                                        <Grid item sx={{width: 350}}>
                                            <AutoCompleteField
                                                value={this.state.itemSel}
                                                options={this.state.itemList}
                                                onChange={this.changeAuto('itemSel', 'item')}
                                                error={errors[t('item')] != null ? true : false}
                                                helperText={errors[t('item')]}
                                                disabled={!fieldAccess}
                                                required
                                                label={t('item')}
                                            />
                                        </Grid>
                                        <Grid item sx={{width: 140}}>
                                            <CheckboxField
                                            checked={this.state.newLot} onChange={this.changeBool('newLot')}
                                            label={t('newLot')} name="newLot" disabled={!fieldAccess} data-cy='newLot'
                                            />
                                        </Grid>
                                        {this.state.itemSel != null && this.state.itemSel.lotTracked && this.state.itemSel.customNumber && (this.state.newLot || 
                                        this.state.outputLots == null || this.state.outputLots.length === 0 ) &&
                                            <Grid item sx={{width: 150}}>
                                                <TextField type="text" value={this.state.customLot} onChange={this.changeValue} name="customLot"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('lot')}
                                                error={errors[t('customLot')] != null ? true : false} helperText={errors[t('customLot')]}/>
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 110}}>
                                            <TextField type="number" value={this.state.amount} onChange={this.changeNumber} name={"amount"}
                                            size="medium" fullWidth={true} disabled={!fieldAccess} required label={t('quantity')}
                                            error={errors[t('amount')] != null ? true : false} helperText={errors[t('amount')]}/>
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <FormControl>
                                                <FormLabel>{t('uom')}</FormLabel>
                                                {this.state.uomSel != null ? this.state.uomSel.label : ''}
                                            </FormControl>
                                        </Grid>
                                        {this.state.itemSel != null && this.state.itemSel.tare === true &&
                                            <Grid item sx={{width: 130}} align="right">
                                                <TextField type="number" value={this.state.tareWeight} onChange={this.changeNumber} name="tareWeight"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('tareWeight')} 
                                                error={errors[t('tareWeight')] != null ? true : false} helperText={errors[t('tareWeight')]}/>
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 250}}>
                                            <AutoCompleteField
                                                value={this.state.binSel}
                                                options={this.state.bins}
                                                onChange={this.changeAuto('binSel', 'bin')}
                                                error={errors[t('bin')] != null ? true : false}
                                                helperText={errors[t('bin')]}
                                                disabled={false}
                                                required
                                                label={t('bin')}
                                                groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                noneMessage={t('noRoomSelected')}
                                            />
                                        </Grid>
                                        {this.state.outputLots != null && this.state.outputLots.length > 0 && !this.state.newLot &&
                                            <Grid item sx={{width: 210}}>
                                                <AutoCompleteField
                                                    value={this.state.outputLotSel}
                                                    options={this.state.outputLots}
                                                    onChange={this.changeAuto('outputLotSel', 'outputLot')}
                                                    error={errors[t('outputLot')] != null ? true : false}
                                                    helperText={errors[t('outputLot')]}
                                                    disabled={false}
                                                    label={t('lot')}
                                                />
                                            </Grid>
                                        }
                                        {this.state.containers != null && this.state.containers.length > 0 && !this.state.newLot &&
                                            <Grid item sx={{width: 210}}>
                                                <AutoCompleteField
                                                    value={this.state.containerSel}
                                                    options={this.state.containers}
                                                    onChange={this.changeAuto('containerSel', 'container')}
                                                    error={errors[t('container')] != null ? true : false}
                                                    helperText={errors[t('container')]}
                                                    disabled={false}
                                                    label={t('container')}
                                                />
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 150}}>
                                            <AutoCompleteField
                                                value={this.state.containerTypeSel}
                                                options={this.state.containerTypes}
                                                onChange={this.changeAuto('containerTypeSel', 'containerType')}
                                                error={errors[t('containerType')] != null ? true : false}
                                                helperText={errors[t('containerType')]}
                                                disabled={false}
                                                label={t('containerType')}
                                            />
                                        </Grid>
                                        {fieldAccess &&
                                            <Grid item sx={{width: 50}}>
                                                <IconButton aria-label="Add Output" onClick={this.addOutput}>
                                                    <AddBoxTwoToneIcon  />
                                                </IconButton>
                                            </Grid>
                                        }
                                    </Grid>`
                                </Box>
                            }
                            {(this.state.fieldAccess || (this.state.inputs != null && this.state.inputs.length > 0)) && !micro &&
                                <Aux>
                                    <br/>
                                    <SectionTitle title={t('inputs')}/>
                                    <Grid container spacing={3} key="inputheader">
                                        <Grid item style={{width: 350}}>{t('item')}</Grid>
                                        <Grid item style={{width: 110}}>{t('amount')}</Grid>
                                        <Grid item style={{width: 50}}>{t('uom')}</Grid>
                                        <Grid item style={{width: 200}}>{t('container')}</Grid>
                                    </Grid>
                                    {this.state.inputs != null && this.state.inputs.length > 0 && this.state.inputs.map((row, i) =>
                                        <Grid container spacing={3} key={'input' + i}>
                                            <Grid item style={{width: 350}}>
                                                {row.itemSel != null && <Link to={'/item/' + row.itemSel.value}>{row.itemSel.label}</Link>}
                                            </Grid>
                                            <Grid item style={{width: 110}}>
                                                {fieldAccess &&
                                                    <TextField type="number" value={row.amount} onChange={this.changeInputNumber('amount', i)} name={"inputAmount" + i}
                                                    size="medium" fullWidth={true} disabled={!fieldAccess} required
                                                    error={errors[`${t('inputAmount')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('inputAmount')} (${t('line')} ${i})`]}/>
                                                }
                                                {!fieldAccess &&
                                                    <NumericFormat value={row.amount} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                }
                                            </Grid>
                                            <Grid item style={{width: 50}}>
                                                {row.uomSel != null ? row.uomSel.label : ''}
                                            </Grid>
                                            <Grid item style={{width: 200}}>
                                                {fieldAccess && row.itemSel != null && row.itemSel.lotTracked &&
                                                    <AutoCompleteField
                                                        value={row.containerSel}
                                                        options={row.containers}
                                                        onChange={this.changeInputAuto('containerSel', 'container', i)}
                                                        error={errors[`${t('inputContainer')} (${t('line')} ${i})`] != null ? true : false}
                                                        helperText={errors[`${t('inputContainer')} (${t('line')} ${i})`]}
                                                        disabled={!fieldAccess }
                                                    />
                                                }
                                                {!fieldAccess && row.containerSel != null &&
                                                    <Link to={'/container/' + row.container}>{row.containerSel.label}</Link>
                                                }
                                            </Grid>
                                            {fieldAccess &&
                                                <Grid item style={{width: 50}}>
                                                    <IconButton aria-label="delete input" onClick={this.deleteInput(i)}>
                                                        <RemoveCircleTwoToneIcon  />
                                                    </IconButton>
                                                </Grid>
                                            }
                                        </Grid>
                                    )}
                                    {fieldAccess &&
                                        <Grid container spacing={3} key='inputadd'>
                                            <Grid item style={{width: 350}}>
                                                <AutoCompleteField
                                                    value={this.state.inputItemSel}
                                                    options={this.state.inputItems}
                                                    onChange={this.changeAuto('inputItemSel', 'inputItem')}
                                                    error={errors[t('inputItem')] != null ? true : false}
                                                    helperText={errors[t('inputItem')]}
                                                    disabled={!fieldAccess}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item style={{width: 110}}>
                                                <TextField type="number" value={this.state.inputAmount} onChange={this.changeNumber} name={"inputAmount"}
                                                size="medium" fullWidth={true} disabled={!fieldAccess} required
                                                error={errors[t('inputAmount')] != null ? true : false} helperText={errors[t('inputAmount')]}/>
                                            </Grid>
                                            <Grid item style={{width: 50}}>{this.state.inputUOMSel != null ? this.state.inputUOMSel.label : ''}</Grid>
                                            <Grid item style={{width: 200}}>
                                                {this.state.inputItemSel != null && this.state.inputItemSel.lotTracked &&
                                                    <AutoCompleteField
                                                        value={this.state.inputContainerSel}
                                                        options={this.state.inputContainers}
                                                        onChange={this.changeAuto('inputContainerSel', 'inputContainer')}
                                                        error={errors[t('inputContainer')] != null ? true : false}
                                                        helperText={errors[t('inputContainer')]}
                                                        disabled={false}
                                                    />
                                                }
                                            </Grid>
                                            <Grid item style={{width: 50}}>
                                                <IconButton aria-label="Add Output" onClick={this.addInput}>
                                                    <AddBoxTwoToneIcon  />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    }
                                </Aux>
                            }
                            {this.state.consumption != null && this.state.consumption.item != null &&
                                <Aux>
                                    <br/>
                                    <SectionTitle title={t('consumption')}/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>{t('item')}</Grid>
                                        <Grid item xs={4} sm={2}>{t('amount')}</Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>
                                            <Link to={'/item/' + this.state.consumption.item._id}>
                                                {this.state.consumption.item.number + '-' + this.state.consumption.item.name}
                                            </Link>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <NumericFormat value={this.state.consumption.amount} displayType={'text'} thousandSeparator={true} decimalScale={3}
                                            suffix={this.state.consumption.uomSel.label}/>
                                        </Grid>
                                    </Grid>
                                </Aux>
                            }
                            <hr/>
                            <FormButtonStack
                                id={this.state._id}
                                objectType={this.props.auth.constants.objectIds.dryingActivity}
                                callback={this.loadRecord}
                                saveClick={this.save}
                                status={this.state.status}
                                permissions={this.state.security}
                                common={this.props.auth.common}
                                constants={this.props.auth.constants}
                                enableUnlockProp={this.state.enableUnlock}
                                saveEnabled={this.state.enableSave}
                                enableDelete={this.state.enableDelete}
                                deleteClick={this.deleteToggle}
                                activityDate={this.state.date}
                                lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                                isSuper={this.props.auth.user.isSuper}
                            />
                        </TabPanel>
                        {this.state._id != null && this.state._id !== '' && this.state.sopsSel != null && this.state.orderObj != null &&
                            this.state.sopsSel.length > 0 && this.state.sopsSel.map((sop, i) =>
                            <TabPanel key={i + 1} value={this.state.tabKey} index={i + 1}>
                                <ActivityWorkRecord sop={sop.value} objectType={this.props.auth.constants.objectIds.dryingActivity} tab={this.state.tabKey}
                                locationId={this.state.orderObj.location} recordId={this.state._id} id={this.state.sops[i].workRecord}
                                complete={this.loadRecord} renderNum={this.state.renderNum} users={this.state.users} date={this.state.date}/>
                            </TabPanel>
                        )}
                        {this.state.hasLedgers &&
                            <TabCanvas value={this.state.tabKey} index={this.state.tabLabels.length - 1}>
                                <ItemLedgerComponent record={this.state._id} permission={this.props.permission} auth={this.props.auth} renderNum={this.state.renderNum}/>
                            </TabCanvas>
                        }
                    </Grid>
                </Grid>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.state.printViewer &&
                    <PrintView
                        id={this.state._id}
                        object={this.props.auth.constants.objectIds.dryingActivity}
                        open={this.state.printViewer}
                        onClose={this.togglePrintViewer}
                        callBack={this.loadRecord}
                        printAccess={this.state.security.print}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.dryingActivity}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    devices: state.devices,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(DryingActivity));
