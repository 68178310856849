import React, { Component } from 'react';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import { Link } from 'react-router-dom';
import axios from 'axios';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import Signature from '../General/Signature';
import Unlock from '../General/Unlock';
import DocumentViewer from '../General/DocumentViewer';
//import TaskRiskAssessment from '../Facility/TaskRiskAssessment';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import ActionBar from '../UI/Buttons/ActionBar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SectionTitle from '../UI/Typography/SectionTitle';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class SopInstance extends Component {
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            template: [],
            instanceId: this.props.match.params.instanceId,
            value: {},
            sop: {},
            completedDate: new Date(),
            instance: {},
            comment: "",
            rooms: [],
            locations: [],
            batches: [],
            lots: [],
            location: '',
            batch: '',
            lot: '',
            room: '',
            productionOrder: '',
            productionOrders: [],
            workOrder: '',
            users: [],
            userOptions: [],
            reviewer: null,
            reviewers: [],
            signer: null,
            user: null,
            approvers: [],
            statuses: [],
            currentStatus: null,
            status: this.props.auth.constants.statuses.new,
            valuesRetrieved: false,
            documents: [],
            showImage: false,
            imageData: '',
            workOrders: [],
            cleaningLots: [],
            pesticideLots: [],
            cleaningLot: '',
            pesticideLot: '',
            schedule: null,
            cert: {},
            finalDocument: null,
            taskRisk: false,
            taskRiskAssessment: '',
            taskRisks: [],
            scheduleBase: null,
            tenant: this.props.auth.user.tenant,
            number: '',
            locationSelected: null,
            batchSelected: null,
            lotSelected: null,
            roomSelected: null,
            taskRiskAssessmentSel: null,
            woSelected: null,
            poSelected: null,
            cleaningLotSelected: null,
            pesticideLotSelected: null,
            tabKey: 0,
            tabLabels: [{name: t('workRecord'), disabled: false}],
            security: [],
            userCerts: [],
            certified: false,
            showCertify: false,
            certifyUser: '',
            certifyUserSel: null,
            dialogOpen: false,
            enableSave: true
        };
        this.sigPad = {};
        this.changeValue = this.changeValue.bind(this);
        this.valueChanged = this.valueChanged.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.completedDateChange = this.completedDateChange.bind(this);
        this.batchChange = this.batchChange.bind(this);
    }

    async componentDidMount(){
        let entityId = "";
        if(this.props.match.params.entityId != null){
            entityId = this.props.match.params.entityId;
        }
        let params = queryString.parse(this.props.location.search);
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        this.setState({
            schedule: scheduleId,
            status: this.props.auth.constants.statuses.inprogress
        }, () => {
            const crumbs = [
                { path: '/#/workrecords', label: this.props.t('workRecords'), screen: this.props.auth.screenDefs.SopInstances},
                { path: 'active', label: this.props.t('workRecord'), screen: this.props.auth.screenDefs.SopInstance}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
        if(this.state.instanceId != null && this.state.instanceId !== ''){
            this.loadRecord();
        } else {
            if(this.props.match.params.sopId != null && this.props.match.params.sopId !== ''){
                let values = await axios.get('/api/instancevalues/' + this.props.match.params.sopId);
                this.setState({
                    userOptions: values.data.users,
                    entityInstance: entityId,
                    reviewers: values.data.managers,
                    approvers: values.data.qas,
                    cleaningLots: values.data.cleaningSolutions,
                    pesticideLots: values.data.pesticides,
                    security: this.props.permission
                });
                let schedule = null;
                let locationSel = null;
                let location = '';
                let roomSel = null;
                let batchSelected = null;
                if(scheduleId != null){
                    schedule = await axios.get('api/schedulebase/' + scheduleId);
                    if(schedule.data.riskAssessmentRequired){
                        this.getTaskRiskAssessments(this.props.match.params.sopId);
                    }
                    roomSel = values.data.rooms.find(x => x.value === schedule.data.room);
                    locationSel = values.data.locations.find(x => x.value === schedule.data.location);
                    location  = schedule.data.location;
                    batchSelected = values.data.batches.find(x => x.value === schedule.data.batch);
                } else {
                    if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
                        location = this.props.auth.user.defaultLocation;
                        locationSel = values.data.locations.find(x => x.value === location);
                    } else {
                        if(values.data.locations.length === 1){
                            locationSel = values.data.locations[0];
                            location = locationSel.value;
                        }
                    }
                }
                let cert = await axios.get('/api/certbysop/' + this.props.match.params.sopId);
                this.setState({cert: cert.data})
                let url = '/api/sops/' + this.props.match.params.sopId;
                axios.get(url)
                .then(async result => {
                    let status = this.getStatusValues(this.props.auth.constants.inprogress, values.data.statuses);
                    let template = JSON.parse(result.data.template);
                    this.setState({
                        template: template,
                        sop: result.data,
                        completedDate: new Date(),
                        locations: values.data.locations,
                        rooms: values.data.rooms,
                        batches: values.data.batches,
                        lots: values.data.lots,
                        productionOrders: values.data.productionOrders,
                        currentStatus: status,
                        valuesRetrieved: true,
                        batch: schedule != null && schedule.data.batch != null ? schedule.data.batch : '',
                        location: location,
                        lot: schedule != null && schedule.data.lot != null ? schedule.data.lot : '',
                        room: schedule != null && schedule.data.room != null ? schedule.data.room : '',
                        productionOrder: schedule != null && schedule.data.productionOrder != null ? schedule.data.productionOrder : '',
                        workOrder: schedule != null && schedule.data.workOrder != null ? schedule.data.workOrder : '',
                        scheduleBase: schedule != null ? schedule.data : null,
                        taskRisk: schedule != null ? schedule.data.riskAssessmentRequired : false,
                        roomSelected: roomSel,
                        locationSelected: locationSel,
                        batchSelected: batchSelected
                    });
                })
                .catch(err => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: this.props.t('error'), severity: 'error'}});
                });
            }
        }
    }

    loadRecord = async () =>{
        const t = this.props.t;
        let url = '/api/instances/' + this.state.instanceId;
        let result = await axios.get(url);
        const values = await axios.get('/api/instancevalues/' + result.data.sop._id);
        let userids = [];
        result.data.users.forEach(row => {
            userids.push(row._id);
        });
        let body = {
            sop: result.data.sop._id,
            users: userids
        };
        let certResult = await axios.post('/api/usersopcerts', body);
        let certified = certResult.data != null && certResult.data.length === result.data.users.length;
        this.setState({userCerts: certResult.data, certified: certified})
        let template = JSON.parse(result.data.formValues);
        let users = [];
        let workOrders = [];
        if(result.data.batch != null && result.data.batch !== ''){
            workOrders = await axios.get('/api/workorders/' + result.data.batch);
        }
        if(result.data.users != null && result.data.users.length > 0){
            result.data.users.forEach(user => {
                users.push({'value': user._id, 'label': user.firstName + ' ' + user.lastName});
            });
        }
        let status = this.getStatusValues(result.data.status, values.data.statuses);
        let locationSelected = values.data.locations.find(x => x.value === result.data.location);
        let batchSelected = null;
        let woSelected = null;
        let lotSelected = null;
        let poSelected = null;
        let cleaningLotSelected  = null;
        let pesticideLotSelected = null;
        let roomSelected = null;
        if(result.data.batch != null && result.data.batch !== ''){
            batchSelected = values.data.batches.find(x => x.value === result.data.batch);
        }
        if(result.data.room != null && result.data.room !== ''){
            roomSelected = values.data.rooms.find(x => x.value === result.data.room);
        }
        if(result.data.workOrder != null && result.data.workOrder !== ''){
            woSelected = workOrders.data.find(x => x.value === result.data.workOrder);
        }
        if(result.data.lot != null && result.data.lot !== ''){
            lotSelected = values.data.lots.find(x => x.value === result.data.lot);
        }
        if(result.data.productionOrder != null && result.data.productionOrder !== ''){
            poSelected = values.data.productionOrders.find(x => x.value === result.data.productionOrder);
        }
        if(result.data.cleaningLot != null && result.data.cleaningLot !== ''){
            cleaningLotSelected = values.data.cleaningSolutions.find(x => x.value === result.data.cleaningLot);
        }
        if(result.data.pesticideLot != null && result.data.pesticideLot !== ''){
            pesticideLotSelected = values.data.pesticides.find(x => x.value === result.data.pesticideLot);
        }
        if(result.data.schedule != null && result.data.schedule.riskAssessmentRequired){
            await this.getTaskRiskAssessments(result.data.sop._id, result.data.taskRiskAssessment);
        }
        let taskRisk = result.data.schedule != null ? result.data.schedule.riskAssessmentRequired : false;
        let tabLabels = [{name: t('workRecord'), disabled: false}];
        if(taskRisk)
            tabLabels.push({name: t('taskRiskAssessment'), disabled: false});
        this.setState({
            template: template,
            completedDate: new Date(result.data.completedDate),
            instance: result.data,
            location: result.data.location,
            room: result.data.room,
            productionOrder: result.data.productionOrder,
            batch: result.data.batch,
            lot: result.data.lot,
            locations: values.data.locations,
            rooms: values.data.rooms,
            batches: values.data.batches,
            workOrder: result.data.workOrder != null ? result.data.workOrder : '',
            workOrders: workOrders.data,
            lots: values.data.lots,
            productionOrders: values.data.productionOrders,
            users: users,
            sop: result.data.sop,
            reviewer: result.data.reviewer,
            signer: result.data.signer,
            currentStatus: status,
            status: result.data.status,
            valuesRetrieved: true,
            documents: result.data.documents,
            cleaningLot : result.data.cleaningLot,
            pesticideLot: result.data.pesticideLot,
            userOptions: values.data.users,
            reviewers: values.data.managers,
            approvers: values.data.qas,
            cleaningLots: values.data.cleaningSolutions,
            pesticideLots: values.data.pesticides,
            user: result.data.user,
            locationSelected: locationSelected,
            batchSelected: batchSelected,
            woSelected: woSelected,
            lotSelected: lotSelected,
            poSelected: poSelected,
            cleaningLotSelected: cleaningLotSelected,
            pesticideLotSelected: pesticideLotSelected,
            roomSelected: roomSelected,
            finalDocument: result.data.finalDocument,
            scheduleBase: result.data.schedule,
            taskRisk: taskRisk,
            taskRiskAssessment: result.data.taskRiskAssessment,
            number: result.data.number,
            tabLabels: tabLabels
        }, () => {
            const crumbs = [
                { path: '/#/workrecords', label: this.props.t('workRecords'), screen: this.props.auth.screenDefs.SopInstances},
                { path: 'active', label: this.state.sop.name, screen: this.props.auth.screenDefs.SopInstance}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    async getTaskRiskAssessments(sopId, selected){
        const t = this.props.t;
        let data = {
            user: this.props.auth.user.id,
            objectType: this.props.auth.constants.objectIds.sopInstance,
            sop: sopId
        };
        const riskAsses = await axios.get('/api/taskriskselectlist', {params: data});
        let taskRiskSel = null;
        if(selected != null && selected !== '')
            taskRiskSel = riskAsses.data.find(x => x.value === selected);
        let tabLabels = [{name: t('workRecord'), disabled: false}, {name: t('taskRiskAssessment'), disabled: false}];
        this.setState({taskRisks: riskAsses.data, taskRiskAssessmentSel: taskRiskSel, tabLabels: tabLabels});
    }

    getStatusValues(status, statuses){
        let val = status != null && status !== '' ? status : this.props.auth.constants.statuses.inprogress;
        let response = statuses.find(x => x.value === val);
        return response;
    }

    formSubmit = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._formSubmit())
    }

    _formSubmit = async () =>{
        const t = this.props.t;
        let valid = this.validateForm();
        if(valid){
            let template = this.state.template;
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < template.length; i++){
                    let field = template[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let docIds = [];
            this.state.documents.forEach(doc => {
                docIds.push(doc._id);
            });
            let data = {
                formValues: JSON.stringify(template),
                sopId: this.state.sop._id,
                location: this.state.location,
                room: this.state.room,
                productionOrder: this.state.productionOrder,
                batch: this.state.batch,
                workOrder: this.state.workOrder,
                lot: this.state.lot,
                completedDate: this.state.completedDate,
                entityInstance: this.state.entityInstance,
                users: this.state.users,
                documents: docIds,
                cleaningLot: this.state.cleaningLot,
                pesticideLot: this.state.pesticideLot,
                schedule: this.state.schedule,
                taskRiskAssessment: this.state.taskRiskAssessment
            };
            let url = '';
            if(this.state.instanceId != null && this.state.instanceId !== ''){
                url = '/api/instances/' + this.state.instanceId;
            }else{
                url = "/api/instances/add";
            }
            axios.post(url, data)
            .then(response => {
                if(this.state._id == null || this.state._id === '')
                    this.props.history.replace('/instance/' + response.data.id);
                this.setState({instanceId: response.data.id, number: response.data.number, enableSave: true }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: response.data.message, title: t('success'), severity: 'success'}});
                });                
            })
            .catch(err => {
                this.setState({ enableSave: true }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
                });                
            });
        }
    }

    validateForm(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(this.state.completedDate == null || this.state.completedDate === "" )
            errors[t('completedDate')] = t('required');
        else {
            var completedDate = Date.parse(this.state.completedDate);
            if(completedDate == null)
                errors[t('completedDate')] = t('required');
        }
        if(this.state.location == null || this.state.location === '')
            errors[t('location')] = t('required');
        if(this.state.users == null || this.state.users === '' || this.state.users.length === 0){
            errors[t('users')] = t('required');
        }
        if(this.state.sop.cleaningRequired && (this.state.cleaningLot == null || this.state.cleaningLot === ''))
            errors[t('cleaningLot')] = t('required');
        if(this.state.sop.pesticideRequired && (this.state.pesticideLot == null || this.state.pesticideLot === ''))
            errors[t('pesticideLot')] = t('required');
        if(state.scheduleBase != null && state.scheduleBase.riskAssessmentRequired && (state.taskRiskAssessment == null ||
            state.taskRiskAssessment === ''))
            errors[t('taskRiskAssessment')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    completedDateChange(e){
        this.setState(
            {completedDate: e()}
        );
    }

    changeValue(e){
        let errors = {};
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]: value, errors: errors});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        })
    };

    valueChanged(e){
        this.setState({value: e});
    }

    usersChanged = async (e, newValue) => {
        let userids = [];
        let userCerts = this.state.userCerts;
        let certified = this.state.certified;
        if(newValue){
            newValue.forEach(row => {
                userids.push(row.value);
            });
            let body = {
                sop: this.state.sop._id,
                users: userids
            };
            let result = await axios.post('/api/usersopcerts', body);
            userCerts = result.data;
            certified = userCerts.length === userids.length;
        }
        this.setState({users: newValue, userCerts: userCerts, certified: certified});
    }

    async batchChange(e, newValue){
        let wos = null;
        let batch = newValue != null ? newValue.value : null;
        if(batch !== '' && batch != null){
            wos = await axios.get('/api/workorders/' + batch);
        }
        this.setState({
            batch: batch,
            batchSelected: newValue,
            workOrders: wos != null ? wos.data : []
        });
    }

    setUser = e => {
        this.setState({user: e});
        this.loadRecord();
    }

    setReviewer = e => {
        this.setState({reviewer: e});
        this.loadRecord();
    }

    setSigner = e => {
        this.setState({signer: e});
        this.loadRecord();
    };

    return = (e) => {
        if(this.state.schedule == null){
            this.props.history.goBack();
        } else {
            this.props.history.go(-2);
        }
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    certifyClick = (e) => {
        if(this.state.users != null && this.state.users.length > 0){ 
            if(this.state.users.length > 1){
                this.setState({showCertify: true})
            } else {
                this.processCertify(this.state.users[0].value);
            }
        }
    };

    submitCertify = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.certifyUser == null || this.state.certifyUser === '')
            errors[t('certifyUser')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        this.processCertify(this.state.certifyUser);
    };

    processCertify(user){
        let sopId = this.props.match.params.sopId != null ? this.props.match.params.sopId : this.state.sop._id;
        let link = '/cert/' + sopId + '?user=' + user;
        this.props.history.push(link);
    }

    closeCertify = (e) => {
        this.setState({showCertify: false});
    };

    toggleDialog = () => {
        this.setState({ dialogOpen: !this.state.dialogOpen });
    };

    render(){
        const errors = this.props.errors;
        let locked = this.state.status === this.props.auth.constants.statuses.complete ||
            this.state.status === this.props.auth.constants.statuses.reviewed ||
            this.state.status === this.props.auth.constants.statuses.signed;
        if (this.state.instance.comments != null && this.state.instance.comments.length > 0){
            this.state.instance.comments.map((comment) =>
                <div key={comment._id}>
                    <p><strong><DateDisplay format={this.props.auth.user.dateFormat} value={comment.createdOn}/>&nbsp;&nbsp;
                        {comment.createdBy.firstName}&nbsp;{comment.createdBy.lastName}</strong></p>
                    <p>{comment.comment}</p>
                </div>
            );
        };
        const t = this.props.t;
        const security = this.state.security;
        const certified = this.state.users == null || this.state.users.length === 0 ? true : this.state.certified;
        if(!certified)
            locked = true;
        const certify =
          <Button variant="contained" size="small" color="secondary" onClick={this.certifyClick}>
                {!certified ? t('certify') : t("review")}
            </Button>

        return (
            <Aux>
                <BreadcrumbBar >
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        id={this.state.instanceId}
                        objectType={this.props.auth.constants.objectIds.sopInstance}
                        enableAttachments={this.state.instanceId != null && this.state.instanceId !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabPanel value={this.state.tabKey} index={0}>
                    {certify}
                    <br/>
                    <br/>
                    <Grid container spacing={3}>
                        {this.state.instanceId != null && this.state.instanceId !== '' &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.number}  name="number"
                                size="medium" fullWidth="true" disabled={true} label={t('number')}/>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.currentStatus != null ? this.state.currentStatus.label : ''}  name="currentStatus"
                            size="medium" fullWidth="true" disabled={true} label={t('status')}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.completedDateChange}
                                value={this.state.completedDate}
                                helperText={errors[t('completedDate')]}
                                error={errors[t('completedDate')] != null ? true : false}
                                disabled={locked}
                                label={t('completed')}
                                format={this.props.auth.user.dateFormat}
                                required
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.locationSelected}
                                options={this.state.locations}
                                onChange={this.changeAuto('locationSelected', 'location')}
                                error={errors[t('location')] != null ? true : false}
                                helperText={errors[t('location')]}
                                label={t('location')}
                                disabled={locked}
                                required
                            />
                        </Grid>
                        {this.state.scheduleBase != null && this.state.scheduleBase.riskAssessmentRequired &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.taskRiskAssessmentSel}
                                    options={this.state.taskRisks}
                                    onChange={this.changeAuto('taskRiskAssessmentSel', 'taskRiskAssessment')}
                                    error={errors[t('taskRiskAssessment')] != null ? true : false}
                                    helperText={errors[t('taskRiskAssessment')]}
                                    label={t('riskAssessment')}
                                    disabled={locked}
                                    required
                                />
                            </Grid>
                        }
                        <Grid item xs={14} sm={7}>
                            <AutoCompleteField
                                multiple={true}
                                value={this.state.users}
                                options={this.state.userOptions}
                                onChange={this.usersChanged}
                                error={errors[t('users')] != null ? true : false}
                                helperText={errors[t('users')]}
                                label={t('users')}
                                disabled={locked}
                                required
                            />
                        </Grid>
                        {this.state.sop.cleaningRequired &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.cleaningLotSelected}
                                    options={this.state.cleaningLots}
                                    onChange={this.changeAuto('cleaningLotSelected', 'cleaningLot')}
                                    error={errors[t('cleaningLot')] != null ? true : false}
                                    helperText={errors[t('cleaningLot')]}
                                    label={t('cleaningLot')}
                                    disabled={locked}
                                />
                            </Grid>
                        }
                    </Grid>
                    <br/>
                    <Form renderer={renderer}
                        defaultFields={this.state.template}
                        onChange={this.valueChanged}
                        disabled={locked}>
                        <br/>
                        <Button disabled={!this.state.enableSave} variant="contained" color="primary" size="small" onClick={this.formSubmit}>{t('save')}</Button>
                        &nbsp;
                        <Link to={'/workrecords'}>
                            <Button variant="contained" size="small" color="secondary">{t('return')}</Button>
                        </Link>

                    </Form>
                    <br/>
                    <br/>
                    {this.state.instanceId != null && this.state.instanceId !== '' &&
                        <Signature users={this.state.users} name={t('user')} status={this.state.status}
                        title={t('userSignature')} setId={this.setUser} object={this.state.instanceId} signature={this.state.user}
                        objectType={this.props.auth.constants.objectIds.sopInstance} type={this.props.auth.constants.signatureTypes.user}
                        user={this.props.auth.user.id}/>
                    }
                    {this.state.user != null && this.state.user._id != null && this.state.user.signature != null &&
                    this.state.user.signature !== '' &&
                        <Signature users={this.state.reviewers} name={t('reviewer')} status={this.state.status}
                        title={t('reviewSignature')} setId={this.setReviewer} object={this.state.instanceId} signature={this.state.reviewer}
                        objectType={this.props.auth.constants.objectIds.sopInstance} type={this.props.auth.constants.signatureTypes.reviewer}
                        user={this.props.auth.user.id}/>
                    }
                    {this.state.reviewer != null && this.state.reviewer._id != null && this.state.reviewer.signature != null &&
                    this.state.reviewer.signature !== '' &&
                        <Signature users={this.state.approvers} name={t('signoff')} status={this.state.status}
                        title={t('signoffSignature')} setId={this.setSigner} object={this.state.instanceId} signature={this.state.signer}
                        objectType={this.props.auth.constants.objectIds.sopInstance} type={this.props.auth.constants.signatureTypes.signer}
                        user={this.props.auth.user.id}/>
                    }
                    {this.state.finalDocument != null &&
                        <div>
                            <DocumentViewer document={this.state.finalDocument} final={true}/>
                            <br/>
                            <br/>
                        </div>
                    }
                    {this.state.status === this.props.auth.constants.statuses.signed &&
                        <Grid container spacing={3}>
                            <Grid item sm={8}>
                                <Unlock id={this.state.instanceId} objectType={this.props.auth.constants.objectIds.sopInstance} callback={this.loadRecord}/>
                                <Button variant="contained" color="secondary" size="small" href={'/#/instanceprint/' + this.state.instanceId}>{t('print')}</Button>
                            </Grid>
                        </Grid>
                    }
                    {this.state.instanceId != null && this.state.instanceId !== '' &&
                        <div>
                            <Comments entityId={this.state.instanceId} object={this.props.auth.constants.objectIds.sopInstance}/>
                            <ObjectLog id={this.state.instanceId}/>
                        </div>
                    }
                </TabPanel>
                {/*this.state.taskRisk &&
                    <TabPanel value={this.state.tabKey} index={1}>
                        <br/>
                        <TaskRiskAssessment id={this.state.taskRiskAssessment} permission={this.props.permission} recordId={this.state.instanceId}/>
                    </TabPanel>
                */}
                <Dialog open={this.state.showCertify} onClose={this.closeCertify} center maxWidth="sm" fullWidth>
                    <DialogTitle>
                        <SectionTitle title={t('users')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={10} sm={8}>
                                <AutoCompleteField
                                    value={this.state.certifyUserSel}
                                    options={this.state.users}
                                    onChange={this.changeAuto('certifyUserSel', 'certifyUser')}
                                    error={errors[t('certifyUser')] != null ? true : false}
                                    helperText={errors[t('certifyUser')]}
                                    label={t('user')}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color='primary' size="small" onClick={this.submitCertify}>{t('certify')}</Button>
                        <Button variant="contained" color="secondary" aize="small" onClick={this.closeCertify}>{t('cancel')}</Button>
                    </DialogActions>
                </Dialog>
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
            </Aux>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    sop: state.sop,
    profile: state.profile.profile,
    errors: state.errors.list
  });

export default withTranslation() (connect(mapStateToProps)(SopInstance));
