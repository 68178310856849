import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import DateSelect from '../UI/DateSelect/DateSelect';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import DateTimePicker from '../UI/DateTimeSelect/DateTimeSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { FormLabel, Box } from '@mui/material';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { approveSigSecurity, completeSigSecurity,
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import ActivityWorkRecord from './ActivityWorkRecord';
import { EvoraTabs, TabPanel, TabCanvas } from '../UI/Tabs/Tabs';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-router-dom';
import ActionBar from '../UI/Buttons/ActionBar';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import SectionTitle from '../UI/Typography/SectionTitle';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import IconButton from '@mui/material/IconButton';
import {NumericFormat } from 'react-number-format';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ItemLedgerComponent from '../Inventory/ItemLedgerComponent';
import ValueHelper from '../../helpers/valueHelper';
import { List, ListItem } from '@mui/material';
import common from '../../jsons/common.json';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import PrintView from '../Printing/PrintView';
import ConversionHelper from '../../helpers/conversionHelper';
import SelectField from '../UI/SelectField/SelectField';

class CropTreatment extends Component {
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            _id: '',
            date: new Date(),
            batches: [],
            batchesSel: [],
            batchOptions: [],
            ebatches: [],
            ebatchesSel: [],
            ebatchOptions: [],
            room: '',
            rooms: [],
            issue: '',
            issues: [],
            user: null,
            userOptions: [],
            users: [],
            usersSel: [],
            pesticideItem: '',
            pesticideItemSel: null,
            pesticideItems: [],
            pesticide: '',
            pesticideOptions: [],
            pesticides: [],
            quantity: '',
            pesticideUOM: '',
            uoms: [],
            description: '',
            authorizedBy: '',
            managers: [],
            verifiedBy: '',
            qas: [],
            schedule: '',
            signed: false,
            location: '',
            locations: [],
            reviewer: null,
            signer: null,
            status: this.props.auth.constants.statuses.new,
            statuses: [],
            statusSel: {
                value: '',
                label: '',
            },
            finalDocument: null,
            taskRisk: false,
            taskRisks: [],
            taskRiskAssessment: '',
            scheduleBase: null,
            tenant: this.props.auth.user.tenant,
            treatmentType: '',
            type: '',
            types: [],
            treatmentTypes: [],
            applicationMethod: '',
            methods: [],
            startTime: new Date(),
            stopTime: null,
            documents: [],
            number: '',
            locationSelected: null,
            authorizedBySelected: null,
            typeSel: null,
            treatmentTypeSel: null,
            issueSelected: null,
            methodSel: null,
            roomSelected: null,
            preparation: null,
            pesticideUOMSel: null,
            security: [],
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            renderNum: Math.random(),
            sops: [],
            sopList: [],
            sopIds: [],
            sopsSel: [],
            tabKey: 0,
            tabLabels: [{name: t('cropTreatment'), disabled: false}],
            container: '',
            containerSel: null,
            containers: [],
            optionsMenu: [],
            flagForSignature: 0,
            dialogOpen: false,
            enableSave: true,
            activeUsers: [],
            activeManagers: [],
            activeQas: [],
            form: '',
            hasForm: false,
            cost: '',
            batchCosts: [],
            effort: '',
            goBack: -1,
            equipments: [],
            equipmentOptions: [],
            equipmentsSel: [],
            deleteOpen: false,
            printViewer: false,
            equipmentAll: [],
            hasLedgers: false,
            reason: ''
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.user, common.cacheValues.cultivationLocation, common.cacheValues.status,
                        common.cacheValues.quantityUOM, common.cacheValues.defaultGrowingRoom, common.cacheValues.pest, common.cacheValues.cropTreatmentSop,
                        common.cacheValues.cropTreatmentType, common.cacheValues.applicationMethod, common.cacheValues.cropTreatmentForm,
                        common.cacheValues.equipment, common.cacheValues.equipmentAll, common.cacheValues.cropTreatmentSubType], 
                        this.props.auth.constants, null, this.props.auth.user.tenant);
        let allUsers = cacheValues.users;
        let locations = cacheValues.locations;
        let statuses = cacheValues.statuses;
        const userValues = await ValueHelper.screenUsers(common.screens.cropTreatment, this.props.auth.user.tenant);
        let params = queryString.parse(this.props.location.search);
        let schedule = null;
        let locationSel = null;
        let location = '';
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        let goBack = -1;
        if(scheduleId != null){
            goBack = -2;
            schedule = await axios.get('api/schedulebase/' + scheduleId);
            if(schedule.data.riskAssessmentRequired){
                this.getTaskRiskAssessments();
            }
            locationSel = locations.find(x => x.value === schedule.data.location);
            location = schedule.data.location;
        } else {
            if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
                locationSel = locations.find(x => x.value === this.props.auth.user.defaultLocation);
                location = this.props.auth.user.defaultLocation;
            } else {
                if(locations.length === 1){
                    locationSel = locations[0];
                    location = locationSel.value;
                }
            }
        }
        let workRecords = [];
        let sopIds = [];
        let sopsSel = [];
        cacheValues.sops.forEach(sop => {
            if(sop.required){
                workRecords.push({
                    sop: sop.value,
                    original: true
                });
                sopIds.push(sop.value);
                sopsSel.push(sop);
            }

        });
        let statusSel = statuses.find(x => x.value ===this.props.auth.constants.statuses.new);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let activeQas = [];
        userValues.approve.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeQas.push(row);
            }
        })
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        this.setState({
            rooms: cacheValues.rooms,
            issues: cacheValues.pests,
            userOptions: userValues.create,
            uoms: cacheValues.quantityUOMs,
            managers: userValues.review,
            qas: userValues.approve,
            locations: locations,
            statuses: statuses,
            schedule: scheduleId,
            scheduleBase: schedule != null ? schedule.data : null,
            locationSelected: locationSel,
            location: location,
            treatmentTypes: cacheValues.cropTreatmentTypes,
            types: cacheValues.cropTreatmentSubTypes,
            methods: cacheValues.applicationMethods,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            sops: workRecords,
            sopList: cacheValues.sops,
            sopIds: sopIds,
            sopsSel: sopsSel,
            activeUsers: activeUsers,
            activeManagers: activeManagers,
            activeQas: activeQas,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            allUsers: allUsers,
            goBack: goBack,
            equipmentOptions: cacheValues.equipments,
            equipmentAll: cacheValues.equipmentAll
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/cropTreatments', label: this.props.t('cropTreatments'), screen: this.props.auth.screenDefs.CropTreatmentList},
                    { path: 'active', label: this.props.t('cropTreatment'), screen: this.props.auth.screenDefs.CropTreatment}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }  
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        const id = this.state._id != null && this.state._id !== '' ? this.state._id : this.props.match.params.id;
        const record = await axios.get('/api/croptreatment/' + id);
        let ebatchesSel = [];
        if(record.data.ebatchOptions != null && record.data.ebatchOptions.length > 0 && record.data.ebatches != null && record.data.ebatches.length > 0){
            record.data.ebatches.forEach(row => {
                let val = record.data.ebatchOptions.find(x => x.value === row);
                ebatchesSel.push(val);
            });
        }
        let pesticides = record.data.pesticides;
        for(let row of pesticides){
            let itemSel = record.data.items.find(x => x.value === row.item);
            row.itemSel = itemSel;
            let uomSel = this.state.uoms.find(x => x.value === row.uom);
            row.uomSel = uomSel;
            let containerSel = row.containers.find(x => x.value === row.container);
            row.containerSel = containerSel;
        }
        let equipmentsSel = [];
        if(record.data.equipments != null && record.data.equipments.length > 0){
            for(let row of record.data.equipments){
                let val = this.state.equipmentAll.find(x => x.value === row);
                if(val != null){
                    equipmentsSel.push(val);
                }
            }
        }
        const signed = record.data.status === this.props.auth.constants.statuses.signed ||
            record.data.status === this.props.auth.constants.statuses.reviewed;
        const locationSelected = this.state.locations.find(x => x.value === record.data.location);
        const authorizedBySelected = this.state.managers.find(x => x.value === record.data.authorizedBy);
        const roomSelected = record.data.rooms.find(x => x.value === record.data.room);
        const issueSelected = this.state.issues.find(x => x.value === record.data.issue);
        const typeSel = this.state.types.find(x => x.value === record.data.type);
        const treatmentTypeSel = this.state.treatmentTypes.find(x => x.value === record.data.treatmentType);
        const methodSel = record.data.applicationMethod != null ? this.state.methods.find(x => x.value === record.data.applicationMethod) : null;
        const statusSel = this.state.statuses.find(x => x.value === record.data.status);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        if(record.data.schedule != null && record.data.schedule.riskAssessmentRequired){
            await this.getTaskRiskAssessments(record.data.taskRiskAssessment);
        }
        let sopIds = [];
        let sopsSel = [];
        let tabLabels = [{name: t('cropTreatment'), disabled: false}];
        if(record.data.sops != null && record.data.sops.length > 0){
            record.data.sops.forEach(sop => {
                sopIds.push(sop.sop);
                let val = this.state.sopList.find(x => x.value === sop.sop);
                sopsSel.push(val);
                tabLabels.push({name: val.label, disabled: false});
            });
        }
        if(record.data.hasLedgers){
            tabLabels.push({name: t('itemLedgers'), disabled: false});
        }
        let usersSel = [];
        let userOptions = this.state.userOptions;
        if(record.data.users != null && record.data.users.length > 0){
            record.data.users.forEach(row => {
                let val = this.state.userOptions.find(x => x.value === row);
                if(val != null){
                    usersSel.push(val);
                } else {
                    val = this.state.allUsers.find(x => x.value === row);
                    if(val != null){
                        usersSel.push(val);
                        userOptions.push(val);
                    }
                }
            });
        }
        let sigDeleteCallback = (record.data.status === this.props.auth.constants.statuses.new || record.data.status === this.props.auth.constants.statuses.inprogress) && 
                        record.data.user != null ? this.deleteSigCallback : null;
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: usersSel, name: t('complete'), object: record.data._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user,
                objectType: this.props.auth.constants.objectIds.cropTreatment, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, deleteCallback: sigDeleteCallback
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: !internal && this.state.reviewer == null ? this.state.activeManagers : this.state.managers,
                name: t('review'), title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.cropTreatment, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: !internal && this.state.signer == null ? this.state.activeQas : this.state.qas,
                name: t('approve'), title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer,
                objectType: this.props.auth.constants.objectIds.cropTreatment, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
	let params = queryString.parse(this.props.location.search);
        this.setState({
            date: record.data.date,
            _id: record.data._id,
            ebatches: record.data.ebatches,
            ebatchesSel: ebatchesSel,
            pesticides: pesticides,
            room: record.data.room,
            issue: record.data.issue,
            user: record.data.user,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            description: record.data.description,
            authorizedBy: record.data.authorizedBy,
            verifiedBy: record.data.verifiedBy,
            verifiedSignature: record.data.verifiedSignature,
            status: record.data.status,
            signed: signed,
            location: record.data.location,
            finalDocument: record.data.finalDocument,
            locationSelected: locationSelected,
            authorizedBySelected: authorizedBySelected,
            roomSelected: roomSelected,
            issueSelected: issueSelected,
            taskRiskAssessment: record.data.taskRiskAssessment,
            scheduleBase: record.data.schedule != null ? record.data.schedule : null,
            taskRisk: record.data.schedule != null ? record.data.schedule.riskAssessmentRequired : false,
            typeSel: typeSel,
            treatmentTypeSel: treatmentTypeSel,
            methodSel: methodSel,
            treatmentType: record.data.treatmentType,
            type: record.data.type,
            applicationMethod: record.data.applicationMethod,
            startTime: record.data.startTime,
            stopTime: record.data.stopTime != null ? record.data.stopTime : new Date(),
            documents: record.data.documents,
            number: record.data.number,
            statusSel: statusSel,
            fieldAccess: fieldAccess,
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            sopIds: sopIds,
            sopsSel: sopsSel,
            sops: record.data.sops,
            renderNum: Math.random(),
            tabLabels: tabLabels,
            ebatchOptions: record.data.ebatchOptions != null ? record.data.ebatchOptions : [],
            users: record.data.users,
            usersSel: usersSel,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            pesticideItems: record.data.items,
            userOptions: userOptions,
            cost: record.data.cost,
            batchCosts: record.data.batchCosts,
            effort: record.data.effort,
            rooms: record.data.rooms,
            equipments: record.data.equipments != null ? record.data.equipments : [],
            equipmentsSel: equipmentsSel,
            deleteOpen: false,
            hasLedgers: record.data.hasLedgers,
            reason: record.data.reason
        }, () => {
            const crumbs = [
                { path: '/#/cropTreatments', label: this.props.t('cropTreatments'), screen: this.props.auth.screenDefs.CropTreatmentList},
                { path: 'active', label: (this.props.t('cropTreatment') + ' - ' + record.data.number), screen: this.props.auth.screenDefs.CropTreatment}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            if(params.tab != null)
                this.openNotification(params);
        });
    }

    openNotification = (params) => {
        let tabKey = this.state.tabKey;
        if(params != null && params.tab != null)
            tabKey = Number(params.tab);
        this.setState({tabKey});
    }

    async getTaskRiskAssessments(selected){
        const data = {
            user: this.props.auth.user.id,
            objectType: this.props.auth.constants.objectIds.cropTreatment
        };
        const riskAsses = await axios.get('/api/taskriskselectlist', {params: data});
        let taskRiskSel = null;
        if(selected != null && selected !== '')
            taskRiskSel = riskAsses.data.find(x => x.value === selected);
        this.setState({taskRisks: riskAsses.data, taskRiskAssessmentSel: taskRiskSel});
    }

    changeAuto = (name, data) => async (e, newValue) => {
        let taskRisk = this.state.taskRisk;
        let pesticideUOM = this.state.pesticideUOM;
        let pesticideUOMSel = this.state.pesticideUOMSel;
        let pesticide = this.state.pesticide;
        let preparation = this.state.preparation;
        let ebatchOptions = this.state.ebatchOptions;
        let pesticideItems = this.state.pesticideItems;
        let ebatches = this.state.ebatches;
        let ebatchesSel = this.state.ebatchesSel;
        if(data === 'taskRiskAssessment'){
            if (newValue != null && newValue.value != null && newValue.value !== "")
                taskRisk = true;
        } else if (data === 'container' && newValue != null){
            let prepValue = await axios.get('/api/pesticideprepforcontainer/' + newValue.value);
            if(prepValue.date != null){
                pesticide = prepValue.data.value;
                preparation = prepValue.data;
            }
        } else if(data === 'container' && newValue == null){
            preparation = null;
        } else if(data === 'pesticideItem' && newValue != null){
            pesticideUOM = newValue.uom;
            pesticideUOMSel = this.state.uoms.find(x => x.value === newValue.uom);
        } else if(data === 'room'){
            if(newValue != null) {
                let roomBatchResult = await axios.get('/api/roombatchsel/' + newValue.value);
                ebatchOptions = roomBatchResult.data;
            }else {
                ebatchOptions = [];
            }
            ebatches = [];
            ebatchesSel = [];
        } else if(data === 'type' && newValue != null){
            let type = newValue.value === this.props.auth.constants.cropTreatmentSubType.biological ?
                'croptreatmentbiologicals' :
                'croptreatmentpesticides';
            let itemResult = await axios.get('/api/' + type + '/');
            pesticideItems = itemResult.data;
        }
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            taskRisk: taskRisk,
            pesticideUOM: pesticideUOM,
            pesticideUOMSel: pesticideUOMSel,
            pesticide: pesticide,
            preparation: preparation,
            ebatchOptions: ebatchOptions,
            ebatches: ebatches,
            ebatchesSel: ebatchesSel,
            pesticideItems: pesticideItems
        }, async () => {
            if(data === 'pesticideItem' && newValue != null){
                let body = {positive: true, items: [newValue.value,], date: this.state.date, available: true, consume: true};
                let lots = await axios.post('/api/itemcontainers', body);
                this.setState({containers: lots.data});
            } else if (data === 'location'){
                if(newValue != null){
                    let roomResult = await axios.get('/api/roomselect', {params: {location: newValue.value}});
                    this.setState({
                        rooms: roomResult.data,
                        room: '',
                        roomSelected: null,
                        ebatchOptions: [],
                        ebatchSel: [],
                        ebatches: []
                    });
                } else {
                    this.setState({
                        room: '',
                        roomSelected: null,
                        rooms: [],
                        ebatchOptions: [],
                        ebatchSel: [],
                        ebatches: []
                    });
                }
            }
        });
    };

    changeMulti = (name, data) => (e, newValue) => {
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            [name]: newValue,
            [data]: ids
        })
    };

    changeSelectAll = (name, data, optName) => (e) => {
        let sels = this.state[name];
        let ids = this.state[data];
        let options = this.state[optName];
        let val = e.target.value;
        let value = val.pop();
        if(value != null){
            if(value === 'all'){
                if(options.length !== sels.length){
                    sels = [];
                    ids = [];
                    options.forEach(row => {
                        sels.push(row);
                        ids.push(row.value);
                    });
                } else {
                    sels = [];
                    ids = [];
                }
            } else {
                if(val.length === 0){
                    let sel = options.find(x => x.value === value);
                    ids.push(value);
                    sels.push(sel);
                } else {
                    if(!val.some(x => x.value === value)){
                        let sel = options.find(x => x.value === value);
                        sels.push(sel);
                        ids.push(sel.value);
                    } else {
                        let index = sels.findIndex(x => x.value === value);
                        sels.splice(index, 1);
                        ids.splice(index, 1);
                    }
                }
            }
            this.setState({[name]: sels, [data]: ids})
        }
    }

    deleteSelectAll = (name, data) => (e, value) => {
        let sels = this.state[name];
        let ids = this.state[data];
        let index = sels.findIndex(x => x.value === value.value);
        if(index > -1){
            sels.splice(index, 1);
            ids.splice(index, 1);
        }
        this.setState({[name]: sels, [data]: ids});
    }

    changeValue = e => {
        const name = e.target.name;
        const value = e.target.value
        if(name === "quantity" || name === "effort"){
            if(isNaN(value)){
                return;
            }
        }
        this.setState({[name]: value});
    };

    dateChange = e => {
        this.setState({date: e});
    };

    batchChange = e => {
        this.setState({batchesSel: e});
    };

    changeNumber = (name, e) => {

        this.setState({[name]: e.value});
    };

    startChanged = (e) => {
        this.setState({startTime: e});
    }

    stopChanged = (e) => {
        this.setState({stopTime: e});
    }

    valueChanged = (e) => {
        this.setState({value: e});
    };

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const t = this.props.t;
        const valid = this.validateForm();
        let status = this.state.status;
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        if( status === this.props.auth.constants.statuses.new ){
            status = this.props.auth.constants.statuses.inprogress;
        }
        const data = {
            _id: this.state._id,
            date: this.state.date,
            batches: this.state.batches,
            ebatches: this.state.ebatches,
            room: this.state.room,
            issue: this.state.issue,
            user: this.state.user,
            pesticides: this.state.pesticides,
            description: this.state.description,
            verifiedBy: this.state.verifiedBy,
            authorizedBy: this.state.authorizedBy,
            verifiedSignature: this.state.verifiedSignature,
            schedule: this.state.schedule,
            location: this.state.location,
            taskRiskAssessment: this.state.taskRiskAssessment,
            treatmentType: this.state.treatmentType,
            type: this.state.type,
            applicationMethod: this.state.applicationMethod,
            startTime: this.state.startTime,
            stopTime: this.state.stopTime,
            status: status,
            sops: this.state.sops,
            users: this.state.users,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            effort: this.state.effort,
            equipments: this.state.equipments,
            reason: this.state.reason
        }
        try {
            const result = await axios.post('/api/croptreatment', data);
            const statusSel = this.state.statuses.find(x => x.value === result.data.status);
            const completeSigSec = completeSigSecurity(this.props.permission.complete, result.data.user, result.data.status, this.props.auth.common, this.props.auth.constants);
            const reviewSigSec = reviewSigSecurity(this.props.permission.review, result.data.reviewer, result.data.status, this.props.auth.common, this.props.auth.constants);
            const approveSigSec = approveSigSecurity(this.props.permission.approve, result.data.signer, result.data.status, this.props.auth.common, this.props.auth.constants);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/croptreatment/' + result.data.id);
            this.setState({
                _id: result.data.id,
                number: result.data.number,
                status: result.data.status,
                statusSel: statusSel,
                completeSigSec: completeSigSec,
                reviewSigSec: reviewSigSec,
                approveSigSec: approveSigSec,
                enableSave: true
            }, () => {
                this.loadRecord();
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    validateForm(){
        const t = this.props.t;
        let errors = {}
        const state = this.state;
        if(state.date == null || state.date === ''){
            errors[t('date')] = t('required');
        } else {
            let validEndDate = ConversionHelper.validActivityDate(this.state.date, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
            if(!validEndDate){
                errors[t('date')] = t('dateBeforeLock');
            }
        }
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.room == null || state.room === '')
            errors[t('room')] = t('required');
        if(state.effort == null || state.effort === '')
            errors[t('effort')] = t('required');
        if(state.treatmentType == null || state.treatmentType === '')
            errors[t('treatmentType')] = t('required');
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(state.type !== this.props.auth.constants.cropTreatmentSubType.biological &&
            (state.applicationMethod == null || state.applicationMethod === ''))
            errors[t('applicationMethod')] = t('required');
        if(state.treatmentType === this.props.auth.constants.cropTreatmentTypes.treatment){
            if(state.issue == null || state.issue === '')
                errors[t('issue')] = t('required');
        }
        if(state.ebatches == null || state.ebatches.length === 0)
            errors[t('batches')] = t('required');
        if(state.users == null || state.users.length === 0)
            errors[t('users')] = t('required');
        if(state.pesticides == null || state.pesticides.length === 0){
            errors[t('pesticideItem')] = t('required');
        } else {
            let i = 0;
            for(let row of this.state.pesticides){
                if(row.item == null || row.item === '')
                    errors[`${t('item')} (${t('line')} ${i})`] = t('required');
                if(row.itemSel != null && row.itemSel.lotTracked === true){
                if(row.container == null || row.container === '')
                    errors[`${t('container')} (${t('line')} ${i})`] = t('required');
                }
                if(row.quantity == null || row.quantity === '')
                    errors[`${t('quantity')} (${t('line')} ${i})`] = t('required');
                else {
                    if((row.reservation == null || row.reservation === '') && row.containerSel != null && parseFloat(row.quantity) > parseFloat(row.containerSel.quantity)){
                        errors[`${t('quantity')} (${t('line')} ${i})`] = t('moreThanContainer');
                    } else if(row.reservation != null && row.reservation !== '' && row.containerSel != null && parseFloat(row.quantity) > parseFloat(row.reservedAmount) + parseFloat(row.containerSel.quantity)){
                        errors[`${t('quantity')} (${t('line')} ${i})`] = t('moreThanContainer');
                    } else if(parseFloat(row.quantity) > parseFloat(row.itemSel.inventory)){
                        errors[`${t('quantity')} (${t('line')} ${i})`] = t('moreThanInventory');
                     }
                }
                i++;
            }
        }
        if((state.pesticideItem != null && state.pesticideItem !== '') || (state.container != null && state.container !== '') ||
        (state.quantity != null && state.quantity !== '')){
            errors[t('pesticideItem')] = t('rowNotAdded');
        }
        if(state.authorizedBy == null || state.authorizedBy === '')
            errors[t('authorizedBy')] = t('required');
        if(state.scheduleBase != null && state.scheduleBase.riskAssessmentRequired && (state.taskRiskAssessment == null ||
            state.taskRiskAssessment === ''))
            errors[t('taskRiskAssessment')] = t('required');
        if(state.startTime == null || state.startTime === '')
            errors[t('startTime')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    refresh = e => {
        this.loadRecord();
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    validateComplete = () => {
        const t = this.props.t;
        let valid = this.validateForm();
        if(!valid)
            return t('notAllComplete');
        if(this.state.sops != null && this.state.sops.length > 0){
            for(let i = 0; i < this.state.sops.length; i++){
                let sop = this.state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        return null;
    };

    changeSop = (e, newValue) => {
        let sops = this.state.sops;
        let tabLabels = this.state.tabLabels;
        if(newValue != null){
            if(sops.length > newValue.length){
                for(let i = 0; i < sops.length; i++){
                    let sop = sops[i];
                    let rec = newValue.find(x => x.value === sop.sop);
                    if(!rec){
                        sops.splice(i, 1);
                        tabLabels.splice(i + 1, 1);
                        break;
                    }
                }
            } else {
                for(let i = 0; i < newValue.length; i++){
                    let val = newValue[i];
                    let rec = sops.find(x => x.sop === val.value)
                    if(!rec){
                        sops.push({
                            sop: val.value,
                            workRecord: null,
                            original: false
                        });
                        if(this.state._id != null && this.state._id !== '')
                            tabLabels.push({name: val.label, disabled: false});
                    }
                }
            }
            let sopIds = [];
            newValue.forEach(val => {
                sopIds.push(val.value);
            });
            this.setState({
                sopIds: sopIds,
                sopsSel: newValue,
                sops: sops
            });
        } else {
            for(let i = 0; i < sops.length; i++){
                let sop = sops[i];
                if(sop.required)
                    return;
            }
            this.setState({sops: [], sopIds: [], sopsSel: [], tabLabels: [{name: this.props.t('cropTreatment'), disabled: false}]});
        }
    };

    toggleDialog = () => {
        let dialogOpen = this.state.dialogOpen;
        this.setState({ dialogOpen: !dialogOpen });
    };

    changeLineValue = (name, index) => (e) => {
        let pesticides = this.state.pesticides;
        let row = pesticides[index];
        row[name] = e.target.value;
        this.setState({pesticides: pesticides});
    };

    changeLineAuto = (name, data, index) => async (e, newValue) => {
        let pesticides = this.state.pesticides;
        let row = pesticides[index];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : null;
        if(data === 'item'){
            row.containers = null;
            row.preparation = null;
            row.container = '';
            row.containerSel = null;
            if(newValue != null){
                let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                row.uom = newValue.uom;
                row.uomSel = uomSel;
                let body = {positive: true, items: [newValue.value,], date: this.state.date, available: true, consume: true};
                let lots = await axios.post('/api/itemcontainers', body);
                row.containers = lots.data;
                row.reservation = null;
            } else {
                row.reservation = null;
            }
        } else if(data === 'container'){
            row.reservation = null;
            if(newValue != null && this.state.type !== this.props.auth.constants.cropTreatmentSubType.biological){
                let prepValue = await axios.get('/api/pesticideprepforcontainer/' + newValue.value);
                row.preparation = prepValue.data;
            } else {
                row.preparation = null;
            }
        }
        this.setState({pesticides: pesticides});
    };

    deleteInput = (index) => (e) => {
        let inputs = this.state.pesticides;
        inputs.splice(index, 1);
        this.setState({pesticides: inputs});
    };

    addInput = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.pesticideItem == null || this.state.pesticideItem === '')
            errors[t('pesticideItem')] = t('required');
        if(this.state.quantity == null || this.state.quantity === '')
            errors[t('quantity')] = t('required');
        if(this.state.pesticideItemSel != null && this.state.pesticideItemSel.lotTracked === true){
            if(this.state.container == null || this.state.container === '')
                errors[t('container')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
        }
        let pesticides = this.state.pesticides;
        pesticides.push({
            item: this.state.pesticideItem,
            itemSel: this.state.pesticideItemSel,
            quantity: this.state.quantity,
            uom: this.state.pesticideUOM,
            uomSel: this.state.pesticideUOMSel,
            container: this.state.container,
            containerSel: this.state.containerSel,
            containers: this.state.containers,
            preparation: this.state.preparation
        });
        this.setState({
            pesticides: pesticides,
            pesticideItem: '',
            pesticideItemSel: null,
            quantity: '',
            container: '',
            containerSel: null,
            containers: [],
            preparation: null,
            pesticideUOM: '',
            pesticideUOMSel: null,
            errors: {}
        });
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    delete = () => {
        if(this.state._id == null || this.state._id === ''){
            this.setState({deleteOpen: false});
            this.props.history.goBack();
            return;
        }
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._delete());
    }

    async _delete(){
        const t = this.props.t;
        if(this.state.user != null || this.state.reviewer != null || this.state.signer != null){
            this.setState({enableSave: true, deleteOpen: false});
            return;
        }
        try {
            let result = await axios.delete('/api/croptreatment/' + this.state._id);
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            if(this.state.goBack === -1) {
                this.props.history.goBack();
            }else {
                this.props.history.go(this.state.goBack);
            }
        } catch(err){
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    togglePrintViewer = () => {
        this.setState({printViewer: !this.state.printViewer});
    }

    deleteSigCallback = async (type) => {
        try{
            let result = await axios.post('/api/croptreatmentsigdelete', {type: type, id: this.state._id});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    render() {
        const fieldAccess = this.state.fieldAccess;
        const errors = this.props.errors;
        const security = this.state.security;
        const t = this.props.t;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        const internal = this.props.auth.user.internal;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        constants={this.props.auth.constants}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        printViewClick={this.togglePrintViewer}
                        printViewTitle={t('trimmingActivity')}
                        enablePrintView={this.state.status === this.props.auth.constants.statuses.signed}
                        objectType={this.props.auth.constants.objectIds.cropTreatment}
                        id={this.state._id}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TabPanel value={this.state.tabKey} index={0}>
                            <Grid container spacing={3}>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                    </FormControl>
                                </Grid>
                                {this.state.scheduleBase != null && this.state.scheduleBase.riskAssessmentRequired &&
                                    <Grid item xs={12} sm={4}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                value={this.state.taskRiskAssessmentSel}
                                                options={this.state.taskRisks}
                                                onChange={this.changeAuto('taskRiskAssessmentSel', 'taskRiskAssessment')}
                                                error={errors[t('taskRiskAssessment')] != null ? true : false}
                                                helperText={errors[t('taskRiskAssessment')]}                                                
                                                label={t('riskAssessment')}
                                                disabled={!fieldAccess}
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('riskAssessment')}</FormLabel>
                                                {this.state.taskRiskAssessmentSel != null ? this.state.taskRiskAssessmentSel.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <DateSelect
                                            onChange={this.dateChange}
                                            value={this.state.date}
                                            helperText={errors[t('date')]}
                                            error={errors[t('date')] != null ? true : false}
                                            disabled={this.state.status !== this.props.auth.constants.statuses.new}
                                            label={t('date')}
                                            format={this.props.auth.user.dateFormat}
                                            name="date"
                                            data-cy="date"
                                            required
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl name="date">
                                            <FormLabel>{t('date')}</FormLabel>
                                            <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.locationSelected}
                                            options={this.state.locations}
                                            onChange={this.changeAuto('locationSelected', 'location')}
                                            error={errors[t('location')] != null ? true : false}
                                            helperText={errors[t('location')]} 
                                            label={t('location')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="location"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy="location">
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locationSelected != null ? this.state.locationSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.roomSelected}
                                            options={this.state.rooms}
                                            onChange={this.changeAuto('roomSelected', 'room')}
                                            error={errors[t('room')] != null ? true : false}
                                            helperText={errors[t('room')]} 
                                            label={t('room')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="room"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy="room">
                                            <FormLabel>{t('room')}</FormLabel>
                                            {this.state.roomSelected != null ? this.state.roomSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.authorizedBySelected}
                                            options={fieldAccess && !internal ? this.state.activeManagers : this.state.managers}
                                            onChange={this.changeAuto('authorizedBySelected', 'authorizedBy')}
                                            error={errors[t('authorizedBy')] != null ? true : false}
                                            helperText={errors[t('authorizedBy')]}
                                            label={t('authorizedBy')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="authorizedBy"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy="authorizedBy">
                                            <FormLabel>{t('authorizedBy')}</FormLabel>
                                            {this.state.authorizedBySelected != null ? this.state.authorizedBySelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.treatmentTypeSel}
                                            options={this.state.treatmentTypes}
                                            onChange={this.changeAuto('treatmentTypeSel', 'treatmentType')}
                                            error={errors[t('treatmentType')] != null ? true : false}
                                            helperText={errors[t('treatmentType')]}
                                            label={t('treatmentType')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="treatmentType"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy="treatmentType">
                                            <FormLabel>{t('treatmentType')}</FormLabel>
                                            {this.state.treatmentTypeSel != null ? this.state.treatmentTypeSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.typeSel}
                                            options={this.state.types}
                                            onChange={this.changeAuto('typeSel', 'type')}
                                            error={errors[t('type')] != null ? true : false}
                                            helperText={errors[t('type')]}
                                            label={t('type')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="type"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy="type">
                                            <FormLabel>{t('type')}</FormLabel>
                                            {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                {this.state.treatmentType === this.props.auth.constants.cropTreatmentTypes.treatment &&
                                    <Grid item xs={12} sm={6}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                value={this.state.issueSelected}
                                                options={this.state.issues}
                                                onChange={this.changeAuto('issueSelected', 'issue')}
                                                error={errors[t('issue')] != null ? true : false}
                                                helperText={errors[t('issue')]}
                                                label={t('issue')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy="issue"
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl data-cy="issue">
                                                <FormLabel>{t('issue')}</FormLabel>
                                                {this.state.issueSelected != null ? this.state.issueSelected.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                }
                                {fieldAccess && this.state.type != null && this.state.type !== this.props.auth.constants.cropTreatmentSubType.biological &&
                                    <Grid item xs={6} sm={6}>
                                        <AutoCompleteField
                                            value={this.state.methodSel}
                                            options={this.state.methods}
                                            onChange={this.changeAuto('methodSel', 'applicationMethod')}
                                            error={errors[t('applicationMethod')] != null ? true : false}
                                            helperText={errors[t('applicationMethod')]}
                                            label={t('applicationMethod')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="method"
                                        />
                                    </Grid>
                                }
                                {!fieldAccess && this.state.type != null && this.state.type !== this.props.auth.constants.cropTreatmentSubType.biological &&
                                    <Grid item xs={6} sm={6}>
                                        <FormControl>
                                            <FormLabel>{t('applicationMethod')}</FormLabel>
                                            {this.state.methodSel != null ? this.state.methodSel.label : ''}
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.equipmentOptions?.length > 0 ?
                                    <Grid item xs={10} sm={5}>
                                        <AutoCompleteField
                                            multiple={true}
                                            value={this.state.equipmentsSel}
                                            options={this.state.equipmentOptions}
                                            onChange={this.changeMulti('equipmentsSel', 'equipments')}
                                            error={errors[t('equipments')] != null ? true : false}
                                            helperText={errors[t('equipments')]}
                                            label={t('equipment')}
                                            disabled={!fieldAccess}
                                            data-cy="equipments"
                                        />
                                    </Grid> : null
                                }
                                {fieldAccess &&
                                    <Grid item xs={4} sm={2}>
                                        <TextField type="number" value={this.state.effort} onChange={this.changeValue} name="effort"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('effort')} required data-cy="effort"
                                        error={errors[t('effort')] != null ? true : false} helperText={errors[t('effort')]}/>
                                    </Grid>
                                }
                                {!fieldAccess && this.state.effort != null && this.state.effort !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl data-cy="effort">
                                            <FormLabel>{t('effort')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={this.state.effort} thousandSeparator={true} decimalScale={0}/>
                                        </FormControl>
                                    </Grid>
                                }
                                {fieldAccess &&
                                    <Grid item sm={10}>
                                        <TextField type="text" value={this.state.reason} onChange={this.changeValue} name="reason"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('reason')} data-cy="reason"
                                        error={errors[t('reason')] != null ? true : false} helperText={errors[t('reason')]}/>
                                    </Grid>
                                }
                                {!fieldAccess && this.state.reason != null && this.state.reason !== '' &&
                                    <Grid item sm={10}>
                                        <FormControl data-cy="reason">
                                            <FormLabel>{t('reason')}</FormLabel>
                                            {this.state.reason}
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                            {this.state.hasForm === true &&
                                <Aux>
                                    <br/>
                                    {errors[t('form')] != null && errors[t('form')] !== '' &&
                                        <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                    }
                                    <Form renderer={renderer}
                                        defaultFields={this.state.form}
                                        onChange={this.valueChanged}
                                        disabled={!fieldAccess}/>
                                </Aux>
                            }
                            <br/>
                            <Grid container spacing={3}>
                                {this.state.sopList != null && this.state.sopList.length > 0 &&
                                    <Grid item sm={11}>
                                        <AutoCompleteField
                                            multiple={true} disabled={!fieldAccess}
                                            value={this.state.sopsSel}
                                            options={this.state.sopList}
                                            onChange={this.changeSop}
                                            error={errors[t('sopIds')] != null ? true : false}
                                            helperText={errors[t('sopIds')]}
                                            label={t('workRecs')}
                                            data-cy="sops"
                                        />
                                    </Grid>
                                }
                                <Grid item xs={11}>
                                    <AutoCompleteField
                                        multiple={true}
                                        value={this.state.usersSel}
                                        options={fieldAccess && !internal ? this.state.activeUsers : this.state.userOptions}
                                        onChange={this.changeMulti('usersSel', 'users')}
                                        error={errors[t('users')] != null ? true : false}
                                        helperText={errors[t('users')]}
                                        label={t('users')}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy="users"
                                    />
                                </Grid>
                                {fieldAccess &&
                                    <Grid item xs={12}>
                                        <SelectField
                                            selectAll
                                            value={this.state.ebatchesSel}
                                            options={this.state.ebatchOptions}
                                            label={t('batches')}
                                            selectAllLabel={t('selectAll')}
                                            onChange={this.changeSelectAll('ebatchesSel', 'ebatches', 'ebatchOptions')}
                                            onDelete={this.deleteSelectAll('ebatchesSel', 'ebatches')}
                                            error={errors[t('batches')] != null ? true : false}
                                            helperText={errors[t('batches')]}                                            
                                            disabled={!fieldAccess || this.state.ebatchOptions.length === 0}
                                            data-cy="batches"
                                        />
                                    </Grid>
                                }
                                {!fieldAccess && this.state.ebatchesSel != null && this.state.ebatchesSel.length > 0 &&
                                    <Grid item sm={12}>
                                        <FormControl data-cy="batches">
                                            <FormLabel>{t('batches')}</FormLabel>
                                            <List style={{ display: 'flex', flexDirection: 'row', padding: 2 }}>
                                                {this.state.ebatchesSel.map((batch, j) =>
                                                    <ListItem disableGutters={true} disablePadding={true} key={'batch' + j}><Link to={'/evorabatch/' + batch.value}>{batch.label}</Link>&nbsp;&nbsp;</ListItem>
                                                )}
                                            </List>
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                            {this.state.cost !== '' && this.state.cost !== 0 && this.state.cost !== '0' && !fieldAccess &&
                                <Aux>
                                    <br/>
                                    <SectionTitle title={t('costs')}/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>
                                            <FormControl data-cy="total">
                                                <FormLabel>{t('total')}</FormLabel>
                                                <NumericFormat displayType={'text'} value={this.state.cost} thousandSeparator={true} decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {this.state.batchCosts != null && this.state.batchCosts.length > 0 &&
                                        <Aux>
                                            <TableContainer component={Paper}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow key="costHead">
                                                            <TableCell>{t('batch')}</TableCell>
                                                            <TableCell>{t('plants')}</TableCell>
                                                            <TableCell>{t('cost')}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.batchCosts.map((row, i) =>
                                                            <TableRow  key={'cost' + i}>
                                                                <TableCell>
                                                                    <Link to={'/evorabatch/' + row.batch._id}>{row.batch.number}</Link>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <NumericFormat displayType={'text'} value={row.plantCount} thousandSeparator={true} decimalScale={0}/>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <NumericFormat displayType={'text'} value={row.cost} thousandSeparator={true} decimalScale={2}/>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Aux>
                                    }
                                </Aux>
                            }
                            <br/>
                            <SectionTitle title={t('inputs')}/>
                            {this.state.pesticides.map((row, i) =>
                                <Box borderColor="primary.main" {...defaultProps}  key={'item' + i} component={'div'}>
                                    <Grid container spacing={3} key={row._id}>
                                        <Grid item sx={{width: 340}}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={row.itemSel}
                                                    options={this.state.pesticideItems}
                                                    onChange={this.changeLineAuto('itemSel', 'item', i)}
                                                    error={errors[`${t('item')} (${t('line')} ${i})`] != null ? true : false}
                                                    helperText={errors[`${t('item')} (${t('line')} ${i})`]}
                                                    disabled={!fieldAccess}
                                                    label={t('item')}
                                                    data-cy={'pesticideItem' + i}
                                                />
                                            }
                                            {!fieldAccess && row.itemSel != null &&
                                                <FormControl data-cy={'pesticideItem' + i}>
                                                    <FormLabel>{t('item')}</FormLabel>
                                                    <Link to={'/item/' + row.item}>{row.itemSel.label}</Link>
                                                </FormControl>
                                            }
                                        </Grid>
                                        {((row.itemSel != null && row.itemSel.lotTracked === true) || (row.containers != null && row.containers.length > 0)) &&
                                            <Grid item sx={{ width: 230 }}>
                                                {fieldAccess &&
                                                    <AutoCompleteField
                                                        value={row.containerSel}
                                                        options={row.containers}
                                                        onChange={this.changeLineAuto('containerSel', 'container', i)}
                                                        error={errors[`${t('container')} (${t('line')} ${i})`] != null ? true : false}
                                                        helperText={errors[`${t('container')} (${t('line')} ${i})`]}
                                                        disabled={!fieldAccess}
                                                        label={t('container')}
                                                        data-cy={'pesticideContainer' + i}
                                                        groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                        noneMessage={t('noLotAssigned')}
                                                    />
                                                }
                                                {!fieldAccess &&
                                                    <FormControl data-cy={'pesticideContainer' + i}>
                                                        <FormLabel>{t('container')}</FormLabel>
                                                        {row.containerSel != null ? 
                                                            <Link to={'/container/' + row.container}>{row.containerSel?.label}</Link> : 
                                                            t('notAssigned')
                                                        }
                                                    </FormControl>
                                                }
                                            </Grid>
                                        }
                                        {!fieldAccess && row.lot != null &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('lot')}</FormLabel>
                                                    <Link to={'/lot/' + row.lot._id}>{row.lot.number}</Link>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {row.preparation != null && row.preparation !== '' &&
                                            <Grid item sx={{ width: 120 }}>
                                                {row.preparation != null &&
                                                    <FormControl>
                                                        <FormLabel>{t('preparation')}</FormLabel>
                                                        <Link to={'/pesticideprep/' + row.preparation._id}>{row.preparation.number}</Link>
                                                    </FormControl>
                                                }
                                            </Grid>
                                        }
                                        <Grid item style={{width: 100}}>
                                            {fieldAccess &&
                                                <TextField type="number" value={row.quantity} onChange={this.changeLineValue('quantity', i)} name="quantity"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} data-cy={'quantity' + i}
                                                error={errors[`${t('quantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('quantity')} (${t('line')} ${i})`]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl data-cy={'quantity' + i}>
                                                    <FormLabel>{t('quantity')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true} decimalScale={3}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <FormControl>
                                                <FormLabel>{t('uom')}</FormLabel>
                                                {row.uomSel != null ? row.uomSel.label : ''}
                                            </FormControl>
                                        </Grid>
                                        {fieldAccess &&
                                            <Grid item sx={{width: 50}}>
                                                <IconButton aria-label="delete item" disabled={!fieldAccess} onClick={this.deleteInput(i)} data-cy={'removeItem' + i}>
                                                    <RemoveCircleTwoToneIcon  />
                                                </IconButton>
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            )}
                            {fieldAccess &&
                                <Box margin={1} borderColor="primary.main" {...defaultProps}  key={'newItem'} component={'div'}>
                                    <Grid container spacing={3} key={'pesticideEnter'}>
                                        <Grid item sx={{width: 340}}>
                                            <AutoCompleteField
                                                value={this.state.pesticideItemSel}
                                                options={this.state.pesticideItems}
                                                onChange={this.changeAuto('pesticideItemSel', 'pesticideItem')}
                                                error={errors[t('pesticideItem')] != null ? true : false}
                                                helperText={errors[t('pesticideItem')]} 
                                                disabled={!fieldAccess}
                                                label={t('item')}
                                                data-cy='pesticideItem'
                                            />
                                        </Grid>
                                        {((this.state.pesticideItemSel != null && this.state.pesticideItemSel.lotTracked === true) || (this.state.containers != null && this.state.containers.length > 0)) &&
                                            <Grid item sx={{width: 230}}>
                                                <AutoCompleteField
                                                    value={this.state.containerSel}
                                                    options={this.state.containers}
                                                    onChange={this.changeAuto('containerSel', 'container')}
                                                    error={errors[t('container')] != null ? true : false}
                                                    helperText={errors[t('container')]} 
                                                    disabled={!fieldAccess}
                                                    label={t('container')}
                                                    data-cy='pesticideContainer'
                                                    groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                    noneMessage={t('noLotAssigned')}
                                                />
                                            </Grid>
                                        }
                                        {this.state.preparation != null && this.state.preparation !== '' &&
                                            <Grid item sx={{width: 120}}>
                                                <FormControl>
                                                    <FormLabel>{t('preparation')}</FormLabel>
                                                    <Link to={'/pesticideprep/' + this.state.preparation._id}>{this.state.preparation.number}</Link>
                                                </FormControl>
                                                </Grid>
                                        }
                                        <Grid item sx={{width: 100}}>
                                            <TextField type="number" value={this.state.quantity} onChange={this.changeValue} name="quantity"
                                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} data-cy='pesticideQuantity'
                                            error={errors[t('quantity')] != null ? true : false} helperText={errors[t('quantity')]}/>
                                        </Grid>
                                        {this.state.pesticideUOM != null &&
                                            <Grid item sx={{width: 50}}>
                                                <FormControl>
                                                    <FormLabel></FormLabel>
                                                    {this.state.pesticideUOMSel != null ? this.state.pesticideUOMSel.label : ''}
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 50}}>
                                            <IconButton aria-label="add pesticide" disabled={!fieldAccess} onClick={this.addInput} data-cy='addPesticide'>
                                                <AddBoxTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                            <br/>
                            <SectionTitle title={t('treatment')}/>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {fieldAccess &&
                                        <TextField value={this.state.description} onChange={this.changeValue} name="description" multiline={true} minRows="3"
                                        variant="outlined" size="medium" fullWidth={true} disabled={!fieldAccess} label={t('description')} data-cy='description'
                                        error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy='description'>
                                            <FormLabel>{t('description')}</FormLabel>
                                            {this.state.description}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={9} sm={5} md={4} lg={3}>
                                    {fieldAccess &&
                                        <DateTimePicker
                                            onChange={this.startChanged}
                                            value={this.state.startTime}
                                            helperText={errors[t('startTime')]}
                                            error={errors[t('startTime')] != null ? true : false}
                                            disabled={!fieldAccess}
                                            label={t('startTime')}
                                            required
                                            data-cy='startTime'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy='startTime'>
                                            <FormLabel>{t('startTime')}</FormLabel>
                                            <DateDisplay value={this.state.startTime} format="yyyy/MM/dd h:mm a"/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={9} sm={5} md={4} lg={3}>
                                    {fieldAccess &&
                                        <DateTimePicker
                                            onChange={this.stopChanged}
                                            value={this.state.stopTime}
                                            helperText={errors[t('stopTime')]}
                                            error={errors[t('stopTime')] != null ? true : false}
                                            disabled={!fieldAccess}
                                            label={t('endTime')}
                                            data-cy='stopTime'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy='stopTime'>
                                            <FormLabel>{t('endTime')}</FormLabel>
                                            <DateDisplay value={this.state.stopTime} format="yyyy/MM/dd h:mm a"/>
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            <br/>
                            <FormButtonStack
                                id={this.state._id}
                                objectType={this.props.auth.constants.objectIds.cropTreatment}
                                callback={this.refresh}
                                saveClick={() => this.saveClick()}
                                status={this.state.status}
                                permissions={this.props.permission}
                                common={this.props.auth.common}
                                constants={this.props.auth.constants}
                                saveEnabled={this.state.enableSave}
                                deleteClick={this.deleteToggle}
                                activityDate={this.state.date}
                                lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                                isSuper={this.props.auth.user.isSuper}
                        />
                        </TabPanel>
                        {this.state._id != null && this.state._id !== '' && this.state.sopsSel != null &&
                            this.state.sopsSel.length > 0 && this.state.sopsSel.map((sop, i) =>
                            <TabPanel value={this.state.tabKey} index={i + 1} key={'sop' + i}>
                                <br/>
                                <ActivityWorkRecord users={this.state.users} sop={sop.value} batch={this.state.ebatches[0]} id={this.state.sops[i].workRecord}
                                objectType={this.props.auth.constants.objectIds.cropTreatment} recordId={this.state._id} date={this.state.date}
                                locationId={this.state.location} renderNum={this.state.renderNum} complete={this.loadRecord} tab={this.state.tabKey}/>
                            </TabPanel>
                        )}
                        {this.state.hasLedgers &&
                            <TabCanvas value={this.state.tabKey} index={this.state.tabLabels.length - 1}>
                                <ItemLedgerComponent record={this.state._id} permission={this.props.permission} auth={this.props.auth} renderNum={this.state.renderNum}/>
                            </TabCanvas>
                        }
                    </Grid>
                </Grid>
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.delete()} open={this.state.deleteOpen} />
                }
                {this.state.printViewer &&
                    <PrintView
                        id={this.state._id}
                        object={this.props.auth.constants.objectIds.cropTreatment}
                        open={this.state.printViewer}
                        onClose={this.togglePrintViewer}
                        callBack={this.loadRecord}
                        printAccess={this.state.security.print}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.cropTreatment}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(CropTreatment));
