import React from 'react';
import { NumericFormat } from 'react-number-format';
import Aux from '../../../hoc/Auxilary/Auxilary';
import ConversionHelper from '../../../helpers/conversionHelper';
import ReportTitle from '../../UI/Typography/ReportTitle';
import ReportSectionTitle from '../../UI/Typography/ReportSectionTitle';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';

import DryingActivity from './DryingActivity';

const boxStyle = {
    margin: '8px',
    width: '100%'
}

const thStyle = {
    borderBottom: '2px solid rgb(224, 224, 224)', 
    fontSize: '9pt', 
    textAlign: 'center'
}

const tdStyle = { 
    border: '0.5px solid rgb(224, 224, 224)', 
    fontSize: '8pt', 
    padding: '5px',
}

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse'
}

export default function DryingOrderTemplate(props) {
    const { dateFormat, record, sequence, t } = props;
    return (
        <div style={{ width: '8.5in', pageBreakBefore: 'always' }}>
            <div style={boxStyle}>
                <ReportTitle 
                    title={record.lot != null && record.lot !== '' && record.lot.evoraBatch != null && record.evoraBatch !== '' ? 
                                t('batch') + ' ' + record.lot.evoraBatch.number + ' - ' + t('dryingOrder') : 
                            record.lot != null && record.lot !== '' ? 
                                record.lot.number + ' - ' + t('dryingOrder') : t('dryingOrder')
                        }
                />
            </div>
            <div style={{ ...boxStyle, display: 'block' }}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '100px' }}>{t('number')}</th>
                            <th style={{ ...thStyle, width: '90px' }}>{t('status')}</th>
                            <th style={{ ...thStyle }}>{t('strain')}</th>
                            <th style={{ ...thStyle, width: '125px' }}>{t('room')}</th>
                            <th style={{ ...thStyle, width: '125px' }}>{t('location')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.number != null && record.number !== '' ? record.number : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.status != null && record.status !== '' ? record.status : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.strain != null && record.strain.name != null && record.strain.name !== '' &&
                                 record.strain.shortCode != null && record.strain.shortCode !== '' ? 
                                    record.strain.name + '(' + record.strain.shortCode + ')' : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.room != null && record.room !== '' ? record.room.name : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.location != null && record.location !== '' ? record.location.name : t('na')}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ ...boxStyle, display: 'block' }}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={thStyle}>{t('method')}</th>
                            <th style={thStyle}>{t('startDate')}</th>
                            <th style={thStyle}>{t('endDate')}</th>
                            <th style={thStyle}>{t('totalDays')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.type != null && record.type !== '' ?
                                    record.type : t('na')                                
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.startDate != null ?
                                    <DateDisplay value={record.startDate} format={dateFormat}/> : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.endDate != null ?
                                    <DateDisplay value={record.endDate} format={dateFormat}/> : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.days != null && record.days !== '' ? 
                                    record.days : t('na')    
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('activitySummary')} />
                </div>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '15px' }} />
                            <th style={{ ...thStyle, width: '50px' }}>{t('number')}</th>
                            <th style={{ ...thStyle, width: '80px' }}>{t('date')}</th>
                            <th style={{ ...thStyle, width: '60px' }}>{t('roomTemp')}</th>
                            <th style={{ ...thStyle, width: '60px' }}>{t('moisturePercent')}</th>
                            <th style={{ ...thStyle, width: '60px' }}>{t('waterActivity')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('totalInput')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('totalOutput')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {record.activities != null && record.activities.length > 0 ?
                            record.activities.map((activity, i) =>
                                <Aux key={'activityRowWrapper-' + i}>
                                    <tr key={'activityRow-' + i}>
                                        <td style={{ ...tdStyle, textAlign: 'right' }}>
                                        {sequence != null && sequence !== '' ? 
                                         sequence + '.' + ConversionHelper.numberToLetterConverter(i + 1) : i + 1 + '.' }
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'center' }}>
                                            {activity.number != null && activity.number !== '' ?
                                                activity.number : t('na')
                                            }
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'center' }}>
                                            {activity.date != null ?
                                                <DateDisplay value={activity.date} format={dateFormat}/> : t('na')
                                            }
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'center' }}>
                                            {activity.roomTemp != null && activity.roomTemp !== '' &&
                                            activity.roomTempUOM != null && activity.roomTempUOM !== '' ?
                                                activity.roomTemp + activity.roomTempUOM : t('na')
                                            }
                                        </td>
                                        {/* <td style={{ ...tdStyle, textAlign: 'center' }}>
                                            {activity.humidity != null && activity.humidity !== ''  ?
                                                activity.humidity + '%' : t('na')
                                            }
                                        </td> */}
                                        <td style={{ ...tdStyle, textAlign: 'center' }}>
                                            {activity.moistureContent != null && activity.moistureContent !== ''  ?
                                                <NumericFormat displayType={'text'} value={activity.moistureContent} thousandSeparator={true} 
                                                decimalScale={3} suffix={'%'}/> : t('na')
                                            }
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'center' }}>
                                            {activity.waterActivity != null && activity.waterActivity !== ''  ?
                                                <NumericFormat displayType={'text'} value={activity.waterActivity} thousandSeparator={true} 
                                                decimalScale={3} suffix={'%'}/> : t('na')
                                            }
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'right' }}>
                                            {activity.totalInput != null && activity.totalInput !== ''  ?
                                                <NumericFormat displayType={'text'} value={activity.totalInput} thousandSeparator={true} 
                                                decimalScale={3} suffix={activity.totalOutputUOM}/> : t('na')
                                            }
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'right' }}>
                                            {activity.totalOutput != null && activity.totalOutput !== '' &&
                                            activity.totalOutputUOM != null && activity.totalOutputUOM !== '' ?
                                                <NumericFormat displayType={'text'} value={activity.totalOutput} thousandSeparator={true} 
                                                decimalScale={3} suffix={activity.totalOutputUOM}/> : t('na')
                                            }
                                        </td>
                                    </tr>
                                    {record.activities.length - 1 === i &&
                                        <tr key='activitiesTotal'>
                                            <td colSpan={6} style={{ ...tdStyle, textAlign: 'right', fontWeight: 'bold', fontSize: '9pt' }}>{t('lossOnDrying')}</td>
                                            <td colSpan={2} style={{ ...tdStyle, textAlign: 'right', fontSize: '9pt', padding: '8px'  }}>
                                            {record.dryingLoss != null && record.dryingLoss !== '' &&
                                             record.startAmountUOM != null && record.startAmountUOM !== '' ?
                                                <Aux>
                                                    <NumericFormat displayType={'text'} value={record.startAmount} thousandSeparator={true} 
                                                    decimalScale={3} suffix={record.startAmountUOM}/>
                                                    {' - '}
                                                    <NumericFormat displayType={'text'} value={record.actualOutput} thousandSeparator={true} 
                                                    decimalScale={3} suffix={record.startAmountUOM}/>
                                                    {' = '}
                                                    <span style={{ fontWeight: 'bold' }}>
                                                        <NumericFormat displayType={'text'} value={record.dryingLoss} thousandSeparator={true} 
                                                                      decimalScale={3} suffix={record.startAmountUOM}/>
                                                        {' ('}
                                                        <NumericFormat displayType={'text'} value={record.dryingLossPercentage} thousandSeparator={true} 
                                                                      decimalScale={3} suffix={'%'}/>
                                                        {')'}
                                                    </span>
                                                </Aux> : t('na')
                                            }
                                            </td>
                                        </tr>
                                    }
                                </Aux>
                            ) :
                            <tr>
                                <td colSpan={6} style={{ ...tdStyle, textAlign: 'center' }}>{t('none')}</td>
                            </tr>
                        }
                        <tr>
                            
                        </tr>
                    </tbody>
                </table>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div>
            {/* <div style={boxStyle}>
                <div style={{ width: '100%', display: 'block' }}>
                    <ReportSectionTitle title={t('cannabisDetails')} />
                </div>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '200px' }}>{t('startingAmount')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('expectedOutput')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('actualOutput')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('lossOnDrying')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.startAmount != null && record.startAmount !== '' &&
                                 record.startAmountUOM != null && record.startAmountUOM !== '' ?
                                    <NumericFormat displayType={'text'} value={record.startAmount} thousandSeparator={true} 
                                                  decimalScale={3} suffix={record.startAmountUOM}/> : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.expectedOutput != null && record.expectedOutput !== '' &&
                                 record.startAmountUOM != null && record.startAmountUOM !== '' ?
                                    <NumericFormat displayType={'text'} value={record.expectedOutput} thousandSeparator={true} 
                                                  decimalScale={3} suffix={record.startAmountUOM}/> : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.actualOutput != null && record.actualOutput !== '' &&
                                 record.startAmountUOM != null && record.startAmountUOM !== '' ?
                                    <NumericFormat displayType={'text'} value={record.actualOutput} thousandSeparator={true} 
                                                  decimalScale={3} suffix={record.startAmountUOM}/> : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.dryingLoss != null && record.dryingLoss !== '' &&
                                 record.startAmountUOM != null && record.startAmountUOM !== '' ?
                                    <NumericFormat displayType={'text'} value={record.dryingLoss} thousandSeparator={true} 
                                                  decimalScale={3} suffix={record.startAmountUOM}/> : t('na')
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr style={{ width: '80%', margin: '16px auto' }} />
            </div> */}
            {record.activities != null && record.activities.length > 0 &&
                record.activities.map((activity, i) => 
                    <div style={boxStyle} key={'activityRecord-' + i}>
                        <DryingActivity dateFormat={dateFormat} record={activity} t={t} 
                        sequence={sequence != null && sequence !== '' ? 
                            sequence + '.' + ConversionHelper.numberToLetterConverter(i + 1) : i + 1 }
                        />
                    </div>
                )
            }
        </div>
    );
}