import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import { Link } from 'react-router-dom';
import ActionBar from '../UI/Buttons/ActionBar';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
class ObjectList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            index: -1,
            edit: false,
            nameSearch: '',
            name: '',
            orderBy: 'name',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            text: '',
            link: '',
            processes: [],
            processesSel: [],
            processOptions: [],
            departments: [],
            departmentsSel: [],
            departmentOptions: [],
            canSchedule: false,
            canItemLedger: false,
            relatedTo: '',
            relatedToSel: null,
            relatedToOptions: [],
            security: [],
            errors: {},
            _id: '',
            enableSave: true,
            parameterList: [],
            documentParameters: [],
            documentParametersSel: []
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.department], this.props.auth.constants, null, this.props.auth.user.tenant)
        const values = await axios.get('/int/objectvalues');
        this.setState({
            processOptions: values.data.processes,
            departmentOptions: cacheValues.departments,
            relatedToOptions: values.data.relatedTos,
            security: this.props.permission,
            parameterList: values.data.parameters
        }, () => {
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        const sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            name: this.state.nameSearch,
            offset: this.state.offset,
            size: this.state.listSize,
            sort: JSON.stringify(sort)
        };
        let list = await axios.get('/int/objects', {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        }, () => {
            const crumbs = [
                { path: 'active', label: 'Objects', screen: this.props.auth.screenDefs.ObjectList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeSearchValue = e => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords();});
    };

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render() {
        const security = this.state.security;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />    
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.nameSearch} onChange={this.changeSearchValue} name="nameSearch"
                            size="medium" fullWidth={true} label="Name"/>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="object-table">
                            <TableHead>
                                <TableRow key="objectHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('name')}
                                            active={this.state.orderBy === 'name'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('text')}
                                            active={this.state.orderBy === 'text'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Text
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('link')}
                                            active={this.state.orderBy === 'link'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Link
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Can Schedule</TableCell>
                                    <TableCell>Item Ledger</TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('exportName')}
                                            active={this.state.orderBy === 'exportName'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Export Name
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) => 
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <Link to={'/object/' + row._id}>{row.name}</Link>
                                        </TableCell>
                                        <TableCell>{row.text}</TableCell>
                                        <TableCell>{row.link}</TableCell>
                                        <TableCell>{row.canSchedule ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>{row.canItemLedger ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>{row.exportName}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(ObjectList);