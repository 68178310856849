import { HAS_DEVICES, SET_ZEBRA_DEFAULT, SET_ZEBRA_PRINTERS, TOGGLE_PRINTDIALOG,
  TOGGLE_DEVICEDIALOG, SET_DEVICE_STATUS, RESET_DEVICES } from '../actions/types';

const initialState = {
  hasRegisteredDevices: false,
  new: [],
  registered: [],
  notConnected: [],
  defaultDevice: {},
  deviceDialogOpen: false,
  openPrintDialog: false,
  labelType: null,
  labelData: null
}

function devices(state = initialState, action){
  switch(action.type){
    case HAS_DEVICES: {
      return {
        ...state,
        hasRegisteredDevices: action.payload
      }
    }
    case SET_ZEBRA_DEFAULT: {
      return {
        ...state,
        defaultDevice: action.payload
      }
    }
    case SET_ZEBRA_PRINTERS: {
      return {
        ...state,
        new: action.payload.printers != null && action.payload.printers.new != null ? action.payload.printers.new : [],
        registered: action.payload.printers != null && action.payload.printers.registered != null ? action.payload.printers.registered : [],
        notConnected: action.payload.printers != null && action.payload.printers.notConnected != null ? action.payload.printers.notConnected : [],
        defaultDevice: action.payload.printers != null && action.payload.printers.default != null ? action.payload.printers.default : [],
      }
    }
    case SET_DEVICE_STATUS: {
      return {
        ...state,
        registered: state.registered.map(device => {
          if(device._id === action.payload.printer._id){
            return {
              ...device,
              deviceStatus: action.payload.printer.deviceStatus
            }
          }
          return device;
        })
      }
    }
    case TOGGLE_DEVICEDIALOG: {
      return {
        ...state,
        deviceDialogOpen: !state.deviceDialogOpen
      }
    }
    case TOGGLE_PRINTDIALOG: {
      return {
        ...state,
        openPrintDialog: !state.openPrintDialog,
        labelType: action.payload != null && action.payload.labelType != null ? 
                   action.payload.labelType : null,
        labelData: action.payload != null && action.payload.labelData != null ?
                   action.payload.labelData : null
      }
    }
    case RESET_DEVICES: {
      return initialState;
    }
    default:
      return state;
  };
}

export default devices;