import React, { useEffect } from 'react';
import axios from 'axios';
import { HashRouter as Router, Route, 
         Switch, useLocation } from 'react-router-dom';
import DynamicRoute from '../components/Routing/DynamicRoute';
import Login from '../components/Login';
import routes from '../components/Routing/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { changePage, newPage } from '../actions/security';

export default function DynamicRouter() {
    const reduxDispatch = useDispatch();
    const securitySelector = useSelector(state => state.security);
    const helpSelector = useSelector(state => state.help);
    const isAuthenticated = useSelector(state => state.auth);
    const location = useLocation();
    let login = null;
    let loginRoute = null;
    login = Login;
    loginRoute = <Route exact path="/login" component={login}/>

    useEffect(() => {
        let source = axios.CancelToken.source();
        let path = '';
        if (location.pathname === '/') {
            path = null;
        }else {
            path = location.pathname.substring(1).split('/')[0];
        }

        const securityProfiler = async (path, source) => {
            if(!securitySelector.pathCache.some(permission => permission.path === path)) {
                try {
                    if(location.pathname !== '/') path = location.pathname;
                    const result = await axios.post('/api/screenaccess/', {
                        cancelToken: source.token, path: path
                    });
                    reduxDispatch(newPage(result.data, securitySelector.pathCache, helpSelector.cache, result.data.path));
                }catch (err) {
                    if (axios.isCancel(err)) {
                        console.info("caught cancel");
                    }else {
                        console.error(err);
                    }
                }
            }else {
                // reduxDispatch({ type: 'SET_SECURITY', payload: {current: securitySelector.pathCache.find(permission => permission.path === path)}})
                reduxDispatch(changePage(path, securitySelector.pathCache, helpSelector.cache));
            }
        };

        if(sessionStorage.jwtToken && location.pathname !== '/login' ) {
            if(securitySelector.currentPath.path !== path) {
                securityProfiler(path, source); 
            }
        }

        return () => {
            source.cancel();
        }
    }, [location.pathname, reduxDispatch, securitySelector, helpSelector]);

    return (
        <Router>
        <div className="App">
            <Switch>
            {loginRoute}
            {routes.map((route) => 
                <DynamicRoute
                    permission={securitySelector.currentPath}
                    dispatch={reduxDispatch}
                    key={location.pathname}
                    path={route.path}
                    exact={route.exact}
                    component={route.Component} 
                    navMenu={route.navMenu}
                    auth={isAuthenticated}
                    routeAuth={route.auth}
                    noPath={route.noPath}
                    />
            )}
            </Switch>
        </div>
        </Router>
    );
}