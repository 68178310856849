import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import DateSelect from '../UI/DateSelect/DateSelect';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import SectionTitle from '../UI/Typography/SectionTitle';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import { CSVDownload } from "react-csv";
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import IconButton from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

class Incidents extends Component {
    constructor(props){
        super(props);

        this.state = {
            list: [],
            offset: 0,
            pages: 1,
            listSize: this.props.listSize != null ? this.props.listSize : 5,
            user: '',
            users: [],
            type: '',
            types: [],
            date: null,
            status: '',
            statuses: [],
            department: '',
            departments: [],
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            userSelected: null,
            typeSelected: null,
            statusSelected: null,
            depSel: null,
            csvData: null,
            headers: null
        }
        this.dateChange = this.dateChange.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        const values = await axios.get('/api/majorlistvalues');
        let storage = sessionStorage.getItem('incidents');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        this.setState({
            users: values.data.users,
            types: values.data.types,
            statuses: cacheValues.statuses,
            departments: values.data.departments,
            offset: storage != null ? storage.offset : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'number',
            ordDir: storage != null ? storage.ordDir : -1,
            status: storage != null ? storage.status : '',
            statusSelected: storage != null ? storage.statusSelected : null,
            user: storage != null ? storage.user : '',
            userSelected: storage != null ? storage.userSelected : null,
            type: storage != null ? storage.type : '',
            typeSelected: storage != null ? storage.typeSelected : null,
            department: storage != null ? storage.department : '',
            depSel: storage != null ? storage.depSel : null,
            date: storage != null && storage.date != null && storage.date !== '' ? new Date(storage.date) : '',
            page: storage != null ? storage.page : 0
        }, () => {
            this.loadRecords();
        });
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            user: this.state.user,
            userSelected: this.state.userSelected,
            status: this.state.status,
            statusSelected: this.state.statusSelected,
            type: this.state.type,
            typeSelected: this.state.typeSelected,
            department: this.state.status,
            depSel: this.state.depSel,
            date: this.state.date,
            page: this.state.page
        };
        sessionStorage.setItem('incidents', JSON.stringify(data));
    }

    async loadRecords(){
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: 'number', value: this.state.search},
            {id: 'status', value: this.state.status},
            {id: 'date', value: this.state.date},
            {id: 'type', value: this.state.type},
            {id: 'department', value: this.state.department},
            {id: 'employee', value: this.state.user}
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        var list = await axios.get('/api/majors', {params: params});
        this.setState({
            list: list.data.data,
            pages: Math.ceil(list.data.totalCount / this.state.listSize),
            totalCount: list.data.totalCount
        });
        this.saveFilters();
    }

    csvClick = async () => {
        this.setState({csvData: null});
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: 'number', value: this.state.search},
            {id: 'status', value: this.state.status},
            {id: 'date', value: this.state.date},
            {id: 'type', value: this.state.type},
            {id: 'department', value: this.state.department},
            {id: 'employee', value: this.state.user}
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let result = await axios.get('/api/incidentscsv', {params: params});
        this.setState({csvData: result.data.data, headers: result.data.headers});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    dateChange(e){
        this.setState({date: e, page: 0, offset: 0}, () => this.loadRecords());
    }

    searchClick(){
        this.loadRecords();
    }

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                {this.props.title != null &&
                    <SectionTitle title={this.props.title}/>
                }
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <DateSelect
                            onChange={this.dateChange}
                            value={this.state.date}
                            label={t('date')}
                            format={this.props.auth.user.dateFormat}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                            value={this.state.userSelected}
                            options={this.state.users}
                            onChange={this.changeAuto('userSelected', 'user')}
                            label={t('user')}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <AutoCompleteField
                            value={this.state.typeSelected}
                            options={this.state.types}
                            onChange={this.changeAuto('typeSelected', 'type')}
                            label={t('type')}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <AutoCompleteField
                            value={this.state.statusSelected}
                            options={this.state.statuses}
                            onChange={this.changeAuto('statusSelected', 'status')}
                            label={t('status')}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <AutoCompleteField
                            value={this.state.depSel}
                            options={this.state.departments}
                            onChange={this.changeAuto('depSel', 'department')}
                            label={t('department')}
                        />
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="Incident Reports" size={this.props.title != null ? 'small' : 'medium'}>
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('number')}
                                    active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('employee')}
                                    active={this.state.orderBy === 'employee'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('employee')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('type')}
                                    active={this.state.orderBy === 'type'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('type')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('date')}
                                    active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('department')}
                                    active={this.state.orderBy === 'department'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('department')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('status')}
                                    active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list.map((record, i) =>
                                <TableRow key={record._id}>
                                    <TableCell><Link to={'/major/' + record._id} name={'incLink' + i}>{record.number} </Link></TableCell>
                                    <TableCell>{record.employee}</TableCell>
                                    <TableCell>{record.type}</TableCell>
                                    <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={record.date}/></TableCell>
                                    <TableCell>{record.department}</TableCell>
                                    <TableCell>{record.status}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableCell>
                                <Tooltip title={t('exportToCsv')} >
                                    <IconButton
                                        disabled={false}
                                        component={'div'}
                                        onClick={this.csvClick}
                                        name='actionCsv'
                                    >
                                        <ExcelIcon fontSize='medium'/>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableRow key="tableFooter">
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                {this.state.csvData != null &&
                    <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.headers} separator={this.state.separator}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile
});

export default withTranslation() (connect(mapStateToProps)(Incidents));
