import { SET_MESSAGE_COUNT } from '../actions/types';

const initialState = {
    messageCount: ''
}

export default function messageReducer(state = initialState, action){
    switch(action.type){
        case SET_MESSAGE_COUNT: {
            return {
                ...state,
                    messageCount: action.payload.messageCount.messageCount
            }
        }
        default:
            return state;
    }
}