import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import { useTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ActionBar from '../UI/Buttons/ActionBar';
import SectionTitle from '../UI/Typography/SectionTitle';
import common from '../../jsons/common.json';

const EquipmentCalibrationTypes = props => {
    const [values, setValues] = useState({
        name: '',
        number: '',
        offset: 0,
        listSize: 10,
        orderBy: 'number',
        ordDir: -1,
        page: 0,
        security: props.permission
    });

    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const { t } = useTranslation();
    const loadRecords = useCallback(async () => {
        const params = {
            equipment: values.equipment,
            status: values.status,
            type: values.type,
            offset: values.offset,
            size: values.listSize,
            sort: JSON.stringify({[values.orderBy]: values.ordDir})
        };
        const response = await axios.get('/api/equipmentcalibtypes', {params: params});
        setList(response.data.data);
        setTotalCount(response.data.totalCount);
        let filters = {
            number: values.number,
            name: values.name,
            offset: values.offset,
            listSize: values.listSize,
            orderBy: values.orderBy,
            ordDir: values.ordDir,
            page: values.page
        };
        sessionStorage.setItem('equipmentCalibrationTypes', JSON.stringify(filters));
    }, [values]);

    useEffect(() => {
        async function fetchData(){
            let storage = sessionStorage.getItem('equipmentCalibrationTypes');
            storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
            setValues({
                number: storage != null ? storage.number : '',
                name: storage != null ? storage.name : '',
                offset: storage != null ? storage.offset : 0,
                listSize: storage != null ? storage.listSize : 10,
                orderBy: storage != null ? storage.orderBy : 'number',
                ordDir: storage != null ? storage.ordDir : -1,
                page: storage != null ? storage.page : 0,
                security: props.permission
            });
            if(props.id == null){
                const crumbs = [
                    { path: 'active', label: t('calibrationTypes'), screen: props.auth.screenDefs.EquipmentCalibrationTypes}
                ]
                props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        }
        fetchData();
    }, [props, t]);

    useEffect(() => {
        loadRecords();
    }, [values, loadRecords]);

    const changeValue = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setValues({...values, [name]: value}, () => loadRecords());
    }

    const sortClick = (name) => {
        let ordBy = values.orderBy;
        let dir = values.ordDir;
        if(ordBy === name){
            if(dir > 0)
                dir = -1;
            else
                dir = 1;
        }

        setValues({...values, orderBy: name, ordDir: dir, offset: 0, page: 0}, () => {loadRecords();});
    }

    const handleRowsPerPage = e => {
        values({...values, listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            loadRecords();
        });
    };

    const handleChangePage = (event, newPage) => {
        setValues({...values, page: newPage, offset: newPage * values.listSize}, () => {
            loadRecords();
        });
    };

    return (
        <Aux>
            <BreadcrumbBar>
                <ActionBar
                    createLocation='/#/equipmentcalibrationtype'
                    createAccess={values.security.create}
                />
            </BreadcrumbBar>
            <SectionTitle title={t('calibrationTypes')} />
            <Panel>
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <TextField value={values.number} onChange={changeValue} name="number"
                            size="medium" fullWidth={true} label={t('number')} data-cy='number'/>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <TextField value={values.name} onChange={changeValue} name="name"
                            size="medium" fullWidth={true} label={t('name')} data-cy='name'/>
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="Equipment Calibration Types">
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => sortClick('number')}
                                    active={values.orderBy === 'number'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => sortClick('name')}
                                    active={values.orderBy === 'name'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('name')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((row, i) =>
                                <TableRow key={row._id}>
                                    <TableCell><Link to={'/equipmentcalibrationtype/' + row._id} name={'calibTypeLink' + i}>{row.number}</Link></TableCell>
                                    <TableCell>{row.name}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableHeader">
                                <TablePagination count={totalCount}
                                rowsPerPage={values.listSize}
                                onPageChange={handleChangePage}
                                page = {values.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Panel>
        </Aux>
    )

}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(EquipmentCalibrationTypes);