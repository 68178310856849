import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import SignatureCapture from '../General/SignatureCapture';
import Comments from '../General/Comments';
import isEmpty from '../../is-empty';
import base64 from 'base-64';
import ObjectLog from '../General/ObjectLog';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import Document from '../General/Document';
import { withTranslation } from 'react-i18next';

class Prescription extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            gramsPerDay: '',
            startDate: '',
            expirationDate: '',
            practitioner: '',
            practitioners: [],
            street1: '',
            street2: '',
            city: '',
            prov: '',
            provs: [],
            postal: '',
            country: '',
            countries: [],
            documents: [],
            practitionerMatch: false,
            clientMatch: false,
            medDocCompleted: false,
            status: '',
            statuses: [],
            verifier: '',
            users: [],
            managers: [],
            verifiedOn: '',
            verifierSignature: '',
            verifyOpen: false,
            verified: false,
            tenant: this.props.auth.user.tenant,
            statusSel: null,
            practitionerSel: null,
            provSel: null,
            countrySel: null,
            verifierSel: null,
            enableSave: true,
            renderNum: Math.random()
        }
        this.sigPad = {};
        this.changeValue = this.changeValue.bind(this);
        this.startChange = this.startChange.bind(this);
        this.expChange = this.expChange.bind(this);
        this.numberChange = this.numberChange.bind(this);
        this.changeBool = this.changeBool.bind(this);
        this.changeVerifyDate = this.changeVerifyDate.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
    }

    async componentDidMount(){
        var values = await axios.get('/api/prescriptionvalues');
        this.setState({
            practitioners: values.data.practitioners,
            provs: values.data.provs,
            countries: values.data.countries,
            users: values.data.users,
            managers: values.data.managers,
            statuses: values.data.statuses,
            _id: this.props.id != null ? this.props.id : ''
        }, () => {
            if(this.props.id != null && this.props.id !== ''){
                this.loadRecord();
            }
        });
        
    }

    loadRecord = async () => {
        var record = await axios.get('/api/prescription/' + this.state._id);
        var data = record.data;
        let verified = data.status === this.props.auth.constants.prescriptionStatuses.verified && (data.verifierSignature != null && 
                data.verifierSignature !== '');
        var statusSel = this.state.statuses.find(x => x.value === record.data.status);
        var practitionerSel = this.state.practitioners.find(x => x.value === record.data.practitioner);
        var provSel = record.data.provs.find(x => x.value === record.data.consultationAddress.prov);
        var countrySel = this.state.countries.find(x => x.value === record.data.consultationAddress.country);
        var verifierSel = this.state.users.find(x => x.value === record.data.verifiedBy);
        this.setState({
            _id: data._id,
            gramsPerDay: data.gramsPerDay,
            startDate: data.startDate,
            expirationDate: data.expirationDate,
            practitioner: data.practitioner,
            customer: this.props.customerId,
            street1: data.consultationAddress.street1,
            street2: data.consultationAddress.street2,
            city:data.consultationAddress.city,
            prov: data.consultationAddress.prov,
            provs: record.data.provs,
            postal: data.consultationAddress.postal,
            country: data.consultationAddress.country,
            documents: data.documents,
            practitionerMatch: data.practitionerMatch,
            clientMatch: data.clientMatch,
            medDocCompleted: data.medDocCompleted,
            status: data.status,
            verifier: data.verifier,
            verifiedOn: data.verifiedOn,
            verifierSignature: data.verifierSignature,
            verified: verified,
            statusSel: statusSel,
            practitionerSel: practitionerSel,
            provSel: provSel,
            countrySel: countrySel,
            verifierSel: verifierSel,
            renderNum: Math.random()
        });
    }

    changeValue(e){
        var name = e.target.name;
        var value = e.target.value;
        if(name === "gramsPerDay"){
            if(isNaN(value)){
                return;
            }
        }
        this.setState({
            [name]: value
        });
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(data === 'country' && newValue != null){
            let provs = await axios.get('/api/provinceselect', {params: {country: newValue.value}});
            this.setState({provs: provs.data});
        }
    };

    startChange(e){
        this.setState({startDate: e});
    }

    expChange(e){
        this.setState({expirationDate: e});
    }

    changeVerifyDate(e){
        this.setState({verifiedOn: e});
    }

    numberChange(name, e){
        this.setState({[name]: e.value});
    }

    changeBool(e){
        this.setState({[e.target.name]: !this.state[e.target.name]})
    }

    save = () => {
        this.setState({ enableSave: false }, () => this._save())
    }

    _save = async () => {
        const valid = this.validate();
        if(!valid){
            this.setState({ enableSave: true });
            return;
        }
            
        let docs = [];
        this.state.documents.forEach(doc => {
            docs.push(doc._id);
        })
        let data = {
            _id: this.state._id,
            gramsPerDay: this.state.gramsPerDay,
            startDate: this.state.startDate,
            expirationDate: this.state.expirationDate,
            practitioner: this.state.practitioner,
            customer: this.props.customerId,
            street1: this.state.street1,
            street2: this.state.street2,
            city: this.state.city,
            prov: this.state.prov,
            postal: this.state.postal,
            country: this.state.country,
            documents: docs,
            practitionerMatch: this.state.practitionerMatch,
            clientMatch: this.state.clientMatch,
            medDocCompleted: this.state.medDocCompleted,
            status: this.state.status,
            verifier: this.state.verifier,
            verifiedOn: this.state.verifiedOn,
            verifierSignature: this.state.verifierSignature
        }
        try {
            let result = await axios.post('/api/prescription', data);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
                this.props.close();
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        }
    }

    cancel(){
        this.props.close();
    }

    validate(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.gramsPerDay == null || state.gramsPerDay === '')
            errors[t('gramsPerDay')] = t('required');
        if(state.startDate == null || state.startDate === '')
            errors[t('startDate')] = t('required');
        if(state.expirationDate == null || state.expirationDate === "")
            errors[t('expirationDate')] = t('required');
        if(state.practitioner == null || state.practitioner === '')
            errors[t('practitioner')] = t('required');
        if(state.street1 == null || state.street1 === '')
            errors[t('street1')] = t('required');
        if(state.city == null || state.city === '')
            errors[t('city')] = t('required');
        if(state.prov == null || state.prov === '')
            errors[t('prov')] = t('required');
        if(state.postal == null || state.postal === '')
            errors[t('postal')] = t('required');
        if(state.country == null || state.country === '')
            errors[t('country')] = t('required');
        if(state.status === this.props.auth.constants.prescriptionStatuses.verified){
            if(!state.practitionerMatch)
                errors[t('practitioner')] = t('pracDocMatch');
            if(!state.clientMatch)
                errors[t('clientMatch')] = t('custDocMatch');
            if(!state.medDocCompleted)
                errors[t('medDocCompleted')] = t('medDocComplete');
        }
        if(state.verifier != null && state.verifier !== '' && (state.verifiedOn == null || state.verifiedOn === ''))
            errors[t('verifiedOn')] = t('required');

        if((state.verifier == null || state.verifier === '') && state.verifiedOn != null && state.verifiedOn !== ''){
            errors[t('verifier')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    handleFileUpload(file){
        let files = file.target.files;
        let reader = new FileReader();
        reader.readAsBinaryString(files[0]);
        reader.onload = () => {
            let body = {
                "file": base64.encode(reader.result),
                "type": files[0].type,
                "fileName": files[0].name
            }
          axios.post('/api/documents/add', body)
          .then(data => {
            var docs = this.state.documents;
            docs.push({
                _id: data.data.id,
                name: files[0].name,
                type: files[0].type
            });
            this.setState({
                documents: docs
            })
          })
          .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
          });
        }
    }

    signVerClick = () => {
        this.setState({verifyOpen: true});
    }

    saveVerSig = signature => {
        this.setState({verifierSignature: signature, verified: true});
    }

    closeVerSig = () => {
        this.setState({verifyOpen: false});
    }

    editVerSig = () => {
        this.setState({verifierSignature: '', verified: false});
    }

    render(){
        const errors = this.props.errors;
        const verified = false;
        const t = this.props.t;
        return(
            <Aux>
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <AutoCompleteField
                            value={this.state.statusSel}
                            options={this.state.statuses}
                            onChange={this.changeAuto('statusSel', 'status')}
                            error={errors[t('status')] != null ? true : false}
                            helperText={errors[t('status')]}
                            label={t('status')}
                            disabled={verified}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <TextField value={this.state.gramsPerDay} onChange={this.changeValue} name="gramsPerDay"
                        size="medium" fullWidth={true} disabled={verified} label={t('gramsPerDay')} required
                        error={errors[t('gramsPerDay')] != null ? true : false} helperText={errors[t('gramsPerDay')]}/>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <DateSelect 
                            onChange={this.startChange}
                            value={this.state.startDate}
                            helperText={errors[t('startDate')]}
                            error={errors[t('startDate')] != null ? true : false}
                            disabled={verified}
                            label={t('startDate')}
                            format={this.props.auth.user.dateFormat}
                            required
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <DateSelect 
                            onChange={this.expChange}
                            value={this.state.expirationDate}
                            helperText={errors[t('expirationDate')]}
                            error={errors[t('expirationDate')] != null ? true : false}
                            disabled={verified}
                            label={t('expirationDate')}
                            format={this.props.auth.user.dateFormat}
                            required
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                            value={this.state.practitionerSel}
                            options={this.state.practitioners}
                            onChange={this.changeAuto('practitionerSel', 'practitioner')}
                            error={errors[t('practitioner')] != null ? true : false}
                            helperText={errors[t('practitioner')]} 
                            label={t('practitioner')}
                            disabled={verified}
                            required
                        />
                    </Grid>
                </Grid>
                <br/>
                <h3>Consultation Address</h3>
                <Grid container spacing={3}>
                    <Grid item xs={8} sm={4}>
                        <TextField value={this.state.street1} onChange={this.changeValue} name="street1"
                        size="medium" fullWidth={true} disabled={verified} label={t('street')} required
                        error={errors[t('street1')] != null ? true : false} helperText={errors[t('street1')]}/>
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <TextField value={this.state.street2} onChange={this.changeValue} name="street2"
                        size="medium" fullWidth={true} disabled={verified} label={t('street2')} 
                        error={errors[t('street2')] != null ? true : false} helperText={errors[t('street2')]}/>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <TextField value={this.state.city} onChange={this.changeValue} name="city"
                        size="medium" fullWidth={true} disabled={verified} label={t('city')} required
                        error={errors[t('city')] != null ? true : false} helperText={errors[t('city')]}/>
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <AutoCompleteField
                            value={this.state.provSel}
                            options={this.state.provs}
                            onChange={this.changeAuto('practitionerSel', 'prov')}
                            error={errors[t('prov')] != null ? true : false}
                            helperText={errors[t('prov')]}
                            label={t('province')}
                            disabled={verified}
                            required
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <InputMaskField mask="a9a-9a9" name="postal" value={this.state.postal}
                        onChange={this.changeValue}
                        helperText={errors[t('postal')]}
                        error={errors[t('postal')] != null ? true : false} 
                        label={t('postal')}
                        disabled={verified}
                        required
                        />
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <AutoCompleteField
                            value={this.state.countrySel}
                            options={this.state.countries}
                            onChange={this.changeAuto('countrySel', 'country')}
                            error={errors[t('country')] != null ? true : false}
                            helperText={errors[t('country')]}
                            label={t('country')}
                            disabled={verified}
                            required
                        />
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <CheckboxField
                        checked={this.state.practitionerMatch} onChange={this.changeBool} required={this.state.status === this.props.auth.constants.prescriptionStatuses.verified}
                        label={t('pracDocMedDoc')} name="practitionerMatch" disabled={verified}
                        />
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <CheckboxField
                        checked={this.state.clientMatch} onChange={this.changeBool} required={this.state.status === this.props.auth.constants.prescriptionStatuses.verified}
                        label={t('custDocMedDoc')} name="clientMatch" disabled={verified}
                        />
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <CheckboxField
                        checked={this.state.medDocCompleted} onChange={this.changeBool} required={this.state.status === this.props.auth.constants.prescriptionStatuses.verified}
                        label={t('medDoc')} name="medDocCompleted" disabled={verified}
                        />
                    </Grid>
                    {this.state.status === this.props.auth.constants.prescriptionStatuses.verified &&
                        <Aux>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.verifierSel}
                                    options={this.state.users}
                                    onChange={this.changeAuto('verifierSel', 'verifier')}
                                    error={errors[t('verifier')] != null ? true : false}
                                    helperText={errors[t('prov')]}
                                    label={t('verifiedBy')}
                                    disabled={verified}
                                    required={(this.state.verifier == null || this.state.verifier === '') && (this.state.verifiedOn != null && this.state.verifiedOn !== '')}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <DateSelect
                                    onChange={this.changeVerifyDate}
                                    value={this.state.verifiedOn}
                                    helperText={errors[t('verifiedOn')]}
                                    error={errors[t('verifiedOn')] != null ? true : false}
                                    disabled={verified}
                                    label={t('verifiedDate')}
                                    format={this.props.auth.user.dateFormat}
                                    required={(this.state.verifier != null && this.state.verifier !== '') && (this.state.verifiedOn == null || this.state.verifiedOn === '')}
                                />
                            </Grid>
                            <Grid item sm={1}>
                                <Button variant="outlined" color="primary" onClick={this.signVerClick}>
                                    {this.state.verifierSignature == null || this.state.verifierSignature === '' ?
                                    t('sign'): t('view')}
                                </Button>
                            </Grid>
                            <SignatureCapture signature={this.state.verifierSignature} editSig={this.editVerSig}
                            heading={t('verificationSignature')} open={this.state.verifyOpen} saveSig={this.saveVerSig}
                            close={this.closeVerSig} clearSig={this.clearVerSig}/>
                        </Aux>
                    }
                </Grid>
                {this.state._id != null && this.state._id !== '' &&
                    <Document record={this.state._id} documents={this.state.documents} 
                    objectType={this.props.auth.constants.objectIds.prescription} callback={this.loadRecord}/>
                }
                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <Button variant="contained" disabled={!this.state.enableSave} color="primary" size="small" onClick={() => this.save()}>{t('save')}</Button>&nbsp;&nbsp;
                        <Button variant="contained" color="secondary" size="small" onClick={() => this.cancel()}>{t('cancel')}</Button>
                    </Grid>
                </Grid>
                {this.state._id != null && this.state._id !== '' &&
                    <Aux>
                        <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.prescription}/>
                        <ObjectLog id={this.state._id}  renderNum={this.state.renderNum}/>
                    </Aux>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Prescription));

