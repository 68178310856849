import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import Link from '../UI/Link/Link';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Panel from '../UI/Panel/Panel';
import TableSortLabel from '@mui/material/TableSortLabel';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import ItemCategoryDialog from './Dialogs/ItemCategory';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class ItemCategories extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            _id: '',
            code: '',
            description: '',
            strainRequired: false,
            dryingRequired: false,
            curingRequired: false,
            trimmingRequired: false,
            hasCount: false,
            exportName: '',
            index: -1,
            edit: false,
            security: [],
            hcReportCategory: '',
            hcReportCategorySel: null,
            hcReportCategories: [],
            searchCode: '',
            searchDescription: '',
            searchReportCategory: '',
            searchReportCategorySel: null,
            orderBy: 'code',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            offset: 0,
            listSize: 10,
            tareWeight: false,
            enableSave: true,
            pesticide: false,
            discreteWeight: false,
            infinite: false,
            customLotNumber: false,
            packagedOn: false,
            viewingRow: {},
            trackingTypes: [],
            tracking: '',
            trackingSel: null,
            growing: false,
            ppe: false
        }
    }

    componentDidMount = async () => {
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.hcReportCategory], this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({
            hcReportCategories: cacheValues.hcReportCategories, 
            security: this.props.permission
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('itemCategories'), screen: this.props.auth.screenDefs.ItemCategories}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        const sort = {[this.state.orderBy]: this.state.ordDir};
        const data = {
            offset: this.state.offset,
            size: this.state.listSize,
            code: this.state.searchCode,
            reportCategory: this.state.searchReportCategory,
            description: this.state.searchDescription,
            sort: JSON.stringify(sort)
        };
        const result = await axios.get('/api/itemcategories', {params: data});
        this.setState({list: result.data.data, totalCount: result.data.totalCount, security: this.props.permission});
    }

    toggleDialog = (index) => {
        let edit = this.state.edit;
        let viewingRow = {};
        if(!edit) {
            edit = true;
            if(index !== -1)
                viewingRow = this.state.list[index];
        }else {
            edit = false;
            viewingRow = {};
        }
        this.setState({edit, viewingRow});
    }

    changeSearchValue = (e) => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords();});
    }

    changeSearchAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick = (name) => {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render = () => {
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createClick={() => this.toggleDialog(-1)}
                        createAccess={security.create}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.searchCode} onChange={this.changeSearchValue} name="searchCode"
                                size="medium" fullWidth={true} label={t('code')}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.searchDescription} onChange={this.changeSearchValue} name="searchDescription"
                                size="medium" fullWidth={true} label={t('description')}/>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.searchReportCategorySel}
                                options={this.state.hcReportCategories}
                                onChange={this.changeSearchAuto('searchReportCategorySel', 'searchReportCategory')}
                                label={t('reportCategory')}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Item Categories">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('code')}
                                            active={this.state.orderBy === 'code'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('code')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('description')}
                                            active={this.state.orderBy === 'description'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('description')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('hcReportCategory')}
                                            active={this.state.orderBy === 'hcReportCategory'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('reportCategory')}
                                        </TableSortLabel>
                                    </TableCell>
                                    {this.props.auth.user.internal &&
                                        <TableCell>{t('exportName')}</TableCell>
                                    }
                                    {this.props.auth.user.customLotNumbers &&
                                        <TableCell>{t('customLot')}</TableCell>
                                    }
                                    <TableCell>{t('discreteWeight')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <Link
                                                onClick={() => this.toggleDialog(i)}
                                                color='primary'
                                            >{row.code}</Link>
                                        </TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell>{row.hcReportCategory != null ? row.hcReportCategory.label: ''}</TableCell>
                                        {this.props.auth.user.internal &&
                                            <TableCell>{row.exportName}</TableCell>
                                        }
                                        {this.props.auth.user.customLotNumbers &&
                                            <TableCell>{row.customLotNumber === true ? t('yes') : ''}</TableCell>
                                        }
                                        <TableCell>{row.discreteWeight === true ? t('yes') : ''}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
                {this.state.edit &&
                    <ItemCategoryDialog
                        callback={() => this.loadRecords()}
                        category={this.state.viewingRow}
                        openStatus={this.state.edit}
                        toggleDialog={() => this.toggleDialog()}
                        security={this.state.security}
                        viewingOnly={false}
                    />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(ItemCategories));
