import React from 'react';

export default function PageTitle (props) {
  const { text, title } = props;
  return (
      <div style={{ alignItems: 'center' }}>
          <span style={{
            color: '#00AC89',
            fontFamily: 'Ubuntu',
            fontSize: '1.75vw',
            fontWeight: 'semi-bold',
            fontVariant: 'small-caps',
            display: 'block'
          }}>{title}</span>
          <span style={{ 
            fontSize: '1.25vw',
            fontWeight: 'semi-bold',
            fontVariant: 'small-caps'
          }}>{text}</span>
      </div>
  );
}