import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ActionBar from '../UI/Buttons/ActionBar';
import SectionTitle from '../UI/Typography/SectionTitle';
import ListButtonStack from '../UI/Buttons/ListButtonStack';

const EquipmentCalibrationChecks = props => {
    const [values, setValues] = useState({
        equipment: props.id != null ? props.id : '',
        equipmentSel: null,
        status: '',
        statusSel: null,
        equipments: [],
        statuses: [],
        type: '',
        typeSel: null,
        types: [],
        offset: 0,
        listSize: 10,
        orderBy: 'number',
        ordDir: -1,
        page: 0,
        security: props.permission
    });

    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const { t } = useTranslation();

    const loadRecords = useCallback(async () => {
        const params = {
            equipment: values.equipment,
            status: values.status,
            type: values.type,
            offset: values.offset,
            size: values.listSize,
            sort: JSON.stringify({[values.orderBy]: values.ordDir})
        };
        const response = await axios.get('/api/equipmentcalibchecks', {params: params});
        setList(response.data.data);
        setTotalCount(response.data.totalCount);
        let filters = {
            equipment: values.equipment,
            equipmentSel: values.equipmentSel,
            status: values.status,
            statusSel: values.statusSel,
            type: values.type,
            typeSel: values.typeSel,
            offset: values.offset,
            listSize: values.listSize,
            orderBy: values.orderBy,
            ordDir: values.ordDir,
            page: values.page
        };
        sessionStorage.setItem('equipmentCalibrationChecks', JSON.stringify(filters));
    }, [values]);

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.equipment, common.cacheValues.status, common.cacheValues.equipmentCalibrationType], 
                props.auth.constants, null, props.auth.user.tenant);
            const statRes = await ValueHelper.getStatusesForObject(props.auth.constants.objectIds.equipmentCalibrationCheck, props.auth.user.tenant);
            let storage = sessionStorage.getItem('equipmentCalibrationChecks');
            storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
            setValues({
                equipment: storage != null ? storage.equipment : '',
                equipmentSel: storage != null ? storage.equipmentSel : null,
                status: storage != null ? storage.status : '',
                statusSel: storage != null ? storage.statusSel : null,
                type: storage != null ? storage.type : '',
                typeSel: storage != null ? storage.typeSel : null,
                offset: storage != null ? storage.offset : 0,
                listSize: storage != null ? storage.listSize : 10,
                orderBy: storage != null ? storage.orderBy : 'number',
                ordDir: storage != null ? storage.ordDir : -1,
                page: storage != null ? storage.page : 0,
                security: props.permission,
                equipments: cacheValues.equipments,
                types: cacheValues.equipmentCalibrationTypes,
                statuses: statRes
            });
            if(props.id == null){
                const crumbs = [
                    { path: 'active', label: t('equipmentCalibrationChecks'), screen: props.auth.screenDefs.EquipmentCalibrationChecks}
                ]
                props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        }
        fetchData();
    }, [props, t]);

    useEffect(() => {
        loadRecords();
    }, [values, loadRecords]);

    const changeAuto = (name, data) => (e, newValue) => {
        setValues({
            ...values,
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            loadRecords();
        });
    };

    const sortClick = (name) => {
        let ordBy = values.orderBy;
        let dir = values.ordDir;
        if(ordBy === name){
            if(dir > 0)
                dir = -1;
            else
                dir = 1;
        }

        setValues({...values, orderBy: name, ordDir: dir, offset: 0, page: 0}, () => {loadRecords();});
    }

    const handleRowsPerPage = e => {
        values({...values, listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            loadRecords();
        });
    };

    const handleChangePage = (event, newPage) => {
        setValues({...values, page: newPage, offset: newPage * values.listSize}, () => {
            loadRecords();
        });
    };

    return (
        <Aux>
            {props.id == null &&
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/equipmentcalibrationcheck'
                        createAccess={values.security.create}
                    />
                </BreadcrumbBar>
            }
            {props.id != null &&
                <Aux>
                    <SectionTitle title={t('calibrationChecks')} />
                    <ListButtonStack
                        createLocation={"/#/equipmentcalibrationcheck?equipment=" + props.id}
                        security={props.permission}
                    />
                </Aux>
            }
            <Panel>
                {props.id == null &&
                    <Grid container spacing={3}>
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={values.equipmentSel}
                                options={values.equipments}
                                onChange={changeAuto('equipmentSel', 'equipment')}
                                label={t('equipment')}
                                data-cy="equipment"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={values.typeSel}
                                options={values.types}
                                onChange={changeAuto('typeSel', 'type')}
                                label={t('type')}
                                data-cy="type"
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={values.statusSel}
                                options={values.statuses}
                                onChange={changeAuto('statusSel', 'status')}
                                label={t('status')}
                                data-cy="status"
                            />
                        </Grid>
                    </Grid>
                }
                <TableContainer component={Paper}>
                    <Table aria-label="Equipment Calibration Checks">
                        <TableHead>
                            <TableRow key="calibCheckHead">
                                <TableCell>
                                    <TableSortLabel onClick={() => sortClick('number')}
                                    active={values.orderBy === 'number'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => sortClick('date')}
                                    active={values.orderBy === 'date'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                    </TableSortLabel>
                                </TableCell>
                                {props.id == null &&
                                    <TableCell>
                                        <TableSortLabel onClick={() => sortClick('equipment')}
                                        active={values.orderBy === 'equipment'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('equipment')}
                                        </TableSortLabel>
                                    </TableCell>
                                }
                                <TableCell>
                                    <TableSortLabel onClick={() => sortClick('type')}
                                    active={values.orderBy === 'type'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('type')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => sortClick('status')}
                                    active={values.orderBy === 'status'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => sortClick('pass')}
                                    active={values.orderBy === 'pass'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('pass')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => sortClick('performedBy')}
                                    active={values.orderBy === 'performedBy'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('performedBy')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((row, i) =>
                                <TableRow key={row._id}>
                                    <TableCell>
                                        <Link to={'/equipmentcalibrationcheck/' + row._id}>{row.number}</Link>
                                    </TableCell>
                                    <TableCell>
                                        <DateDisplay value={row.date} format={props.auth.user.dateFormat}/>
                                    </TableCell>
                                    {props.id == null &&
                                        <TableCell>
                                            {row.equipment}
                                        </TableCell>
                                    }
                                    <TableCell>
                                        {row.type != null ? row.type.number + '-' + row.type.name : ''}
                                    </TableCell>
                                    <TableCell>
                                        {row.status}
                                    </TableCell>
                                    <TableCell>
                                        {row.pass === true ? t('yes') : t('no')}
                                    </TableCell>
                                    <TableCell>
                                        {row.performedBy != null ? row.performedBy.firstName + ' ' + row.performedBy.lastName : ''}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TablePagination count={totalCount}
                                rowsPerPage={values.listSize}
                                onPageChange={handleChangePage}
                                page = {values.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Panel>
        </Aux>
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(EquipmentCalibrationChecks);
