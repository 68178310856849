import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import LocationInspections from '../General/LocationInspections';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Panel from '../UI/Panel/Panel';
import Grid from '@mui/material/Grid';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class LocationDetail extends Component {
    constructor(props){
        super(props);
        this.state = {
            location: {},
            rooms: [],
            inspections: [],
            security: []
        }
    }

    async componentDidMount(){
        const location = await axios.get('/api/locationdetails/' + this.props.match.params.id);
        this.setState({
            location: location.data.location,
            rooms: location.data.rooms,
            inspections: location.data.inspections,
            security: this.props.permission
        }, () => {
            const crumbs = [
                { path: '/#/locations', label: this.props.t('locations'), screen: this.props.auth.screenDefs.LocationList},
                { path: 'active', label: this.props.t('locationDetails') + ' - ' + location.data.location.name, screen: this.props.auth.screenDefs.RoomCleaning}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    render(){
        const blank = isEmpty(this.state.location);
        const location = this.state.location;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar >
                    <ActionBar />
                </BreadcrumbBar>
                <Panel>
                    {!blank && 
                        <Aux>
                            <Grid container spacing={3}>
                                <Grid item xs={8} sm={4}>
                                    {location.street1}<br/>
                                    {this.state.street2 != null && this.state.street2 !== '' &&
                                        <Aux>
                                        {location.street2}<br/>
                                        </Aux>
                                    }
                                    {location.city}<br/>
                                    {location.prov != null ? location.prov.code : ''}<br/>
                                    {location.postal}<br/>
                                    {location.country != null ? location.country.code : ''}
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                <ul>
                                    {this.state.rooms.map((row, i) => 
                                        <li key={row._id}>{row.name}</li>
                                    )}
                                </ul>
                                </Grid>
                            </Grid>
                            {this.state.location.workDays != null && this.state.location.workDays.mon != null && this.state.location.workDays.tues != null &&
                            (this.state.location.workDays.mon.workDay === true || this.state.location.workDays.tues.workDay === true) &&
                                <Aux>
                                    <SectionTitle title={t('workDays')}/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} sm={2}>{t('day')}</Grid>
                                        <Grid item xs={4} sm={2}>{t('shifts')}</Grid>
                                        <Grid item xs={4} sm={2}>{t('start')}</Grid>
                                        <Grid item xs={4} sm={2}>{t('end')}</Grid>
                                    </Grid>
                                    {this.state.location.workDays.sun.workDay &&
                                        <Grid container spacing={3}>
                                            <Grid item xs={4} sm={2}>
                                                <strong>{t('day.sunday')}</strong>
                                            </Grid>
                                            <Grid item xs={4} sm={2}>{this.state.location.workDays.sun.shifts}</Grid>
                                            <Grid item xs={4} sm={2}>
                                                {this.state.location.workDays.sun.startTime.hour + ':' + this.state.location.workDays.sun.startTime.minute}
                                            </Grid>
                                            <Grid item xs={4} sm={2}>
                                                {this.state.location.workDays.sun.endTime.hour + ':' + this.state.location.workDays.sun.endTime.minute}
                                            </Grid>
                                        </Grid>
                                    }
                                    {this.state.location.workDays.mon.workDay &&
                                        <Grid container spacing={3}>
                                            <Grid item xs={4} sm={2}>
                                                <strong>{t('day.monday')}</strong>
                                            </Grid>
                                            <Grid item xs={4} sm={2}>{this.state.location.workDays.mon.shifts}</Grid>
                                            <Grid item xs={4} sm={2}>
                                                {this.state.location.workDays.mon.startTime.hour + ':' + this.state.location.workDays.mon.startTime.minute}
                                            </Grid>
                                            <Grid item xs={4} sm={2}>
                                                {this.state.location.workDays.mon.endTime.hour + ':' + this.state.location.workDays.mon.endTime.minute}
                                            </Grid>
                                        </Grid>
                                    }
                                    {this.state.location.workDays.tues.workDay &&
                                        <Grid container spacing={3}>
                                            <Grid item xs={4} sm={2}>
                                                <strong>{t('day.tuesday')}</strong>
                                            </Grid>
                                            <Grid item xs={4} sm={2}>{this.state.location.workDays.tues.shifts}</Grid>
                                            <Grid item xs={4} sm={2}>
                                                {this.state.location.workDays.tues.startTime.hour + ':' + this.state.location.workDays.tues.startTime.minute}
                                            </Grid>
                                            <Grid item xs={4} sm={2}>
                                                {this.state.location.workDays.tues.endTime.hour + ':' + this.state.location.workDays.tues.endTime.minute}
                                            </Grid>
                                        </Grid>
                                    }
                                    {this.state.location.workDays.wed.workDay &&
                                        <Grid container spacing={3}>
                                            <Grid item xs={4} sm={2}>
                                                <strong>{t('day.wednesday')}</strong>
                                            </Grid>
                                            <Grid item xs={4} sm={2}>{this.state.location.workDays.wed.shifts}</Grid>
                                            <Grid item xs={4} sm={2}>
                                                {this.state.location.workDays.wed.startTime.hour + ':' + this.state.location.workDays.wed.startTime.minute}
                                            </Grid>
                                            <Grid item xs={4} sm={2}>
                                                {this.state.location.workDays.wed.endTime.hour + ':' + this.state.location.workDays.wed.endTime.minute}
                                            </Grid>
                                        </Grid>
                                    }
                                    {this.state.location.workDays.thurs.workDay &&
                                        <Grid container spacing={3}>
                                            <Grid item xs={4} sm={2}>
                                                <strong>{t('day.thursday')}</strong>
                                            </Grid>
                                            <Grid item xs={4} sm={2}>{this.state.location.workDays.thurs.shifts}</Grid>
                                            <Grid item xs={4} sm={2}>
                                                {this.state.location.workDays.thurs.startTime.hour + ':' + this.state.location.workDays.thurs.startTime.minute}
                                            </Grid>
                                            <Grid item xs={4} sm={2}>
                                                {this.state.location.workDays.thurs.endTime.hour + ':' + this.state.location.workDays.thurs.endTime.minute}
                                            </Grid>
                                        </Grid>
                                    }
                                    {this.state.location.workDays.fri.workDay &&
                                        <Grid container spacing={3}>
                                            <Grid item xs={4} sm={2}>
                                                <strong>{t('day.friday')}</strong>
                                            </Grid>
                                            <Grid item xs={4} sm={2}>{this.state.location.workDays.fri.shifts}</Grid>
                                            <Grid item xs={4} sm={2}>
                                                {this.state.location.workDays.fri.startTime.hour + ':' + this.state.location.workDays.fri.startTime.minute}
                                            </Grid>
                                            <Grid item xs={4} sm={2}>
                                                {this.state.location.workDays.fri.endTime.hour + ':' + this.state.location.workDays.fri.endTime.minute}
                                            </Grid>
                                        </Grid>
                                    }
                                    {this.state.location.workDays.sat.workDay &&
                                        <Grid container spacing={3}>
                                            <Grid item xs={4} sm={2}>
                                                <strong>{t('day.saturday')}</strong>
                                            </Grid>
                                            <Grid item xs={4} sm={2}>{this.state.location.workDays.sat.shifts}</Grid>
                                            <Grid item xs={4} sm={2}>
                                                {this.state.location.workDays.sat.startTime.hour + ':' + this.state.location.workDays.sat.startTime.minute}
                                            </Grid>
                                            <Grid item xs={4} sm={2}>
                                                {this.state.location.workDays.sat.endTime.hour + ':' + this.state.location.workDays.sat.endTime.minute}
                                            </Grid>
                                        </Grid>
                                    }
                                    <br/>
                                </Aux>
                            }
                            <LocationInspections 
                                location={this.props.match.params.id}
                                security={this.state.security}
                            />
                        </Aux>
                    }
                </Panel>
            </Aux>            
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(LocationDetail));