import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import isEmpty from '../../is-empty';
import SignatureCapture from '../General/SignatureCapture';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import TextField from '../UI/TextField/TextField';
import DateSelect from '../UI/DateSelect/DateSelect';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';

class HazardousHr extends Component {
    constructor(props){
        super(props);
        this.state = {
            users: [],
            issueType: [],
            issueTypes: [],
            issueTypesSelect: [],
            furtherFollowUp: false,
            furtherActions: '',
            hrReviewer: '',
            hrSignature: '',
            hrReviewDate: '',
            sigOpen: false,
            hrSelected: null,
            enableSave: true
        }
        this.sigPad = {};
        this.changeValue = this.changeValue.bind(this);
        this.issueTypeChanged = this.issueTypeChanged.bind(this);
        this.followUpChanged = this.followUpChanged.bind(this);
        this.hrReviewDateChange = this.hrReviewDateChange.bind(this);
        this.saveClick = this.saveClick.bind(this);
    }

    async componentDidMount(){
        var values = await axios.get('/api/hazardoushrvalues');
        var incident = await axios.get('/api/hazardous/' + this.props.id);
        const { user } = this.props.auth;
        var issueTypesSelect = [];
        if(incident.data.issueType != null){
            for(var i = 0; i < incident.data.issueType.length; i++){
                for(var j = 0; j < values.data.issueTypes.length; j++){
                    if(incident.data.issueType[i] === values.data.issueTypes[j].value){
                        issueTypesSelect.push(values.data.issueTypes[j])
                    }
                }
            }
        }
        var hrSelected = values.data.hrs.find(x => x.value === incident.data.hrReviewer);
        this.setState({
            users: values.data.hrs,
            issueType: incident.data.issueType,
            issueTypesSelect: issueTypesSelect,
            issueTypes: values.data.issueTypes,
            furtherFollowUp: incident.data.furtherFollowUp,
            furtherActions: incident.data.furtherActions,
            hrSignature: incident.data.hrSignature,
            hrReviewer: incident.data.hrReviewer != null && incident.data.hrReviewer !== '' ? 
                incident.data.hrReviewer : user.id,
            hrReviewDate: incident.data.hrReviewDate,
            hrSelected: hrSelected
        });
    }

    changeValue(e){
        this.setState({[e.target.name]: e.target.value});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({[name]: newValue, [data]: newValue != null ? newValue.value : null});
    };

    hrReviewDateChange(e){
        this.setState({hrReviewDate: e});
    }

    followUpChanged(value){
        this.setState({furtherFollowUp: value});
    }

    issueTypeChanged(e, newValue){
        var issueType = [];
        if(newValue != null){
            newValue.forEach(type => {
                issueType.push(type.value);
            })
        }
        this.setState({
            issueType: issueType,
            issueTypesSelect: newValue
        })
    }

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const t = this.props.t;
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        var data = {
            _id: this.props.id,
            issueType: this.state.issueType,
            furtherFollowUp: this.state.furtherFollowUp,
            furtherActions: this.state.furtherActions,
            hrReviewer: this.state.hrReviewer,
            hrSignature: this.state.hrSignature,
            hrReviewDate: this.state.hrReviewDate
        }
        axios.post('/api/incidenthrsave', data)
        .then(result => {
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        })
        .catch(err => {
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        });
    }

    validateForm(){
        const t = this.props.t;
        let errors = {};
        if(this.state.issueType == null || this.state.issueType === '')
            errors[t('issueType')] = t('required');
        if(this.state.furtherFollowUp && (this.state.furtherActions == null || this.state.furtherActions === ''))
            errors[t('furtherActions')] = t('required');
        if(this.state.hrReviewer == null || this.state.hrReviewer === '')
            errors[t('hrReviewer')] = t('required');;
        if(this.state.hrReviewDate == null || this.state.hrReviewDate === '')
            errors[t('hrReviewDate')] = t('required');;
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    sigClick = () => {
        this.setState({sigOpen: true});
    } 
    
    sigClose = () => {
        this.setState({sigOpen: false});
    }

    clear = () => {
        this.sigPad.clear();
    }

    accept = signature => {
        this.setState({
            hrSignature: signature,
            sigOpen: false
        });
    }

    editSignature = () => {
        this.setState({hrSignature: ""});
    }

    render(){
        const errors = this.props.errors;
        const signed = false;
        const t = this.props.t;
        return(
            <Aux>
                <br/>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <AutoCompleteField
                            multiple={true}
                            value={this.state.issueTypesSelect}
                            options={this.state.issueTypes}
                            onChange={this.issueTypeChanged}
                            error={errors[t('issueType')] != null ? true : false}
                            helperText={errors[t('issueType')]}
                            label={t('issueType')}
                            disabled={signed}
                            required
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <label>{t('furtherFollowup')}</label>
                        <ToggleButtonGroup toggle name="furtherFollowUp" value={this.state.furtherFollowUp}
                            onChange={this.followUpChanged}
                            aria-label="Agree with Impact Assessment">
                            <ToggleButton type="button" value={true} className="btn btn-primary">{t('yes')}</ToggleButton>
                            <ToggleButton type="button" value={false} className="btn btn-primary">{t('no')}</ToggleButton>
                        </ToggleButtonGroup>
                        {errors[t('furtherFollowUp')] && <div><div className="error text-danger">{errors[t('furtherFollowUp')]}</div><br/></div>}
                    </Grid>
                </Grid>
                <br/>
                {this.state.furtherFollowUp &&
                    <Grid container spacing={3}>
                        <Grid item sm={11}>
                            <TextField value={this.state.furtherActions} onChange={this.changeValue} name="furtherActions" multiline={true} rows="3"
                            variant="outlined" size="medium" fullWidth={true} disabled={signed} 
                            label={t('furtherActions')} required
                            error={errors[t('furtherActions')] != null ? true : false} helperText={errors[t('furtherActions')]}/>
                        </Grid>
                    </Grid>
                }
                <Grid container spacing={3}>
                    <Grid item xs={8} sm={4}>
                        <AutoCompleteField
                            value={this.state.hrSelected}
                            options={this.state.users}
                            onChange={this.changeAuto('hrSelected', 'hrReviewer')}
                            error={errors[t('hrReviewer')] != null ? true : false}
                            helperText={errors[t('hrReviewer')]}
                            label={t('hrReviewer')}
                            disabled={signed}
                            required
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <DateSelect
                            onChange={this.hrReviewDateChange}
                            value={this.state.hrReviewDate}
                            helperText={errors[t('hrReviewDate')]}
                            error={errors[t('hrReviewDate')] != null ? true : false}
                            disabled={signed}
                            label={t('reviewDate')}
                            format={this.props.auth.user.dateFormat}
                            required
                        />
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <Button variant="outlined" size="small" color="primary" onClick={this.sigClick}>
                            {this.state.hrSignature != null && this.state.hrSignature !== '' ?
                            t('view') : t('sign')}
                        </Button>
                    </Grid>
                </Grid>
                <SignatureCapture signature={this.state.hrSignature} editSig={this.editSignature} heading={t('hrSignature')}
                open={this.state.sigOpen} saveSig={this.accept} close={this.sigClose} sigPad={this.sigPad}
                clearSig={this.clear}/>
                <Grid container spacing={3}>
                    <Grid item sm={5}>
                        <Button disabled={!this.state.enableSave} variant="contained" size="small" color="primary" onClick={this.saveClick}>{t('save')}</Button>
                    </Grid>
                </Grid>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(HazardousHr));