import { SET_OPTIONMENU, TOGGLE_OPTIONMENU, TOGGLE_LOGS, 
         TOGGLE_COMMENTS, RESET_OPTIONMENU } from '../actions/types';

const initialState = {
  options: [],
  open: false,
  title: null,
  openLogs: false,
  openComments: false
}

function optionMenu(state = initialState, action){
  switch(action.type){
    case SET_OPTIONMENU: {
      return {
        ...state,  
        options: action.payload.options,
        title: action.payload.title
      }
    }
    case TOGGLE_OPTIONMENU: {
      return {
        ...state,
        open: !state.open
      }
    }
    case TOGGLE_LOGS: {
      return {
        ...state,
        openLogs: !state.openLogs
      }
    }
    case TOGGLE_COMMENTS: {
      return {
        ...state,
        openComments: !state.openComments
      }
    }
    case RESET_OPTIONMENU: {
      return initialState;
    }
    default:
      return state;
  }
}

export default optionMenu;