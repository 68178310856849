import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import isEmpty from '../../is-empty'; 
import ActionBar from '../UI/Buttons/ActionBar';
import { ClearButton, SaveButton, TextOnlyClickButton, DeleteButton } from '../UI/Buttons/Buttons';
import { withTranslation } from 'react-i18next';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Switch from '../UI/Switch/Switch';
import ModalDialog from '../UI/Dialog/ModalDialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class BatchActivities extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            orderBy: 'code',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            code: '',
            search: '',
            searchDesc: '',
            description: '',
            index: -1,
            changeRoom: false,
            edit: false,
            perPlantEffort: '',
            includeStartItem: false,
            security: [],
            exportName: '',
            enableSave: true,
            protected: false,
            hide: false,
            showHidden: false,
            cleanRoomBefore: false,
            cleanRoomAfter: false
        };
    }

    componentDidMount(){
        this.loadRecords();
    }

    loadRecords = async () => {
        const sort = {[this.state.orderBy]: this.state.ordDir};
        const params = {
            offset: this.state.offset,
            size: this.state.listSize,
            sort: JSON.stringify(sort),
            code: this.state.search,
            description: this.state.searchDesc,
            hide: this.state.showHidden
        }
        const list = await axios.get('/api/batchactivitydefinitions', {params: params});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount,
            index: -1,
            security: this.props.permission
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('batchActivityDefs'), screen: this.props.auth.screenDefs.BatchActivityDefinitions },
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeEdit = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords();});
    };

    changeBool = (e) => {
        const name = e.target.name;
        const value = this.state[name];
        this.setState({[name]: !value}, () => {
            if(name === 'showHidden')
                this.loadRecords();
        });
    };

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };


    changeNumber = e => {
        const name = e.target.name;
        const value = e.target.value;
        if(isNaN(value)){
            return;
        }
        this.setState({
            [name]: value
        });
    };

    editRow(index){
        const row = this.state.list[index];
        this.setState({
            code: row.code,
            _id: row._id,
            description: row.description,
            changeRoom: row.changeRoom,
            perPlantEffort: row.perPlantEffort,
            index: index,
            exportName: row.exportName,
            includeStartItem: row.includeStartItem,
            edit: true,
            protected: row.protected,
            hide: row.hide,
            cleanRoomBefore: row.cleanRoomBefore === true ? true : false,
            cleanRoomAfter: row.cleanRoomAfter === true ? true : false
        })
    }

    addRow = (e) => {
        this.setState({
            edit: true
        })
    }

    clear = (e) => {
        this.setState({
            edit: false,
            _id: '',
            code: '',
            description: '',
            changeRoom: false,
            perPlantEffort: '',
            exportName: '',
            includeStartItem: false,
            index: -1,
            protected: false,
            hide: false,
            cleanRoomBefore: false,
            cleanRoomAfter: false
        });
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async (e) => {
        const t = this.props.t;
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        const data = {
            _id: this.state._id,
            code: this.state.code,
            description: this.state.description,
            changeRoom: this.state.changeRoom,
            perPlantEffort: this.state.perPlantEffort,
            includeStartItem: this.state.includeStartItem,
            exportName: this.state.exportName,
            protected: this.state.protected,
            hide: this.state.hide,
            cleanRoomBefore: this.state.cleanRoomBefore,
            cleanRoomAfter: this.state.cleanRoomAfter
        };
        try {
            const result = await axios.post('/api/batchactivitydefinition', data);
            this.setState({ enableSave: true }, () => {
                this.loadRecords();
                this.clear();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        if(this.state.code == null || this.state.code === '')
            errors[t('code')] = t('required');
        if(this.state.description == null || this.state.description === '')
            errors[t('description')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    delete = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._delete());
    };

    _delete = async (e) => {
        const t = this.props.t;
        try {
            if(this.state._id != null && this.state._id !== ''){
                let result = await axios.delete('/api/batchactivitydefinition/' + this.state._id);
                this.setState({ enableSave: true }, () => {
                    this.loadRecords();
                    this.clear();
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                });
            } else {
                this.setState({enableSave: true});
            }
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    buttonStack = () => {
        const security = this.state.security;
        const protect = this.state.protected;
        return (
            <Aux>
                {!protect && <DeleteButton deleteClick={this.delete} enabled={security.delete}/>}
                <ClearButton clearClick={this.clear} enabled={security.create || security.complete || security.update} name="clear"/>
                <SaveButton saveClick={this.save} enabled={(security.create || security.complete || security.update) && this.state.enableSave}
                name="save"/>
            </Aux>
        );
    }

    render() {
        const errors = this.props.errors;
        const security = this.state.security;
        const t = this.props.t;
        const micro = this.props.auth.user.micro;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createClick={this.addRow}
                        createAccess={security.create}
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={5} sm={4} md={2}>
                            <TextField value={this.state.search} onChange={this.changeValue} name="search"
                                size="medium" fullWidth={true} label={t('code')}
                            />
                        </Grid>
                        <Grid item xs={5} sm={4} md={2}>
                            <TextField value={this.state.searchDesc} onChange={this.changeValue} name="searchDesc"
                                size="medium" fullWidth={true} label={t('description')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={5} md={3}>
                            <FormControlLabel
                                control={<Switch checked={this.state.showHidden} onChange={this.changeBool} name="showHidden" />}
                                label={t('showHidden')}
                                labelPlacement="start"
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Batch Activities defs">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('code')}
                                        active={this.state.orderBy === 'code'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('code')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('description')}</TableCell>
                                    <TableCell>{t('changeRoom')}</TableCell>
                                    <TableCell>{t('perPlantEffort')}</TableCell>
                                    <TableCell>{t('includeStartItem')}</TableCell>
                                    <TableCell>{t('exportName')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <TextOnlyClickButton enabled={true} click={() => this.editRow(i)} name={row.code} id={'actdef' + i}/>
                                        </TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell>{row.changeRoom ? t('yes') : t('no')}</TableCell>
                                        <TableCell>{row.perPlantEffort}</TableCell>
                                        <TableCell>{row.includeStartItem ? t('yes') : t('no')}</TableCell>
                                        <TableCell>{row.exportName}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
                <ModalDialog buttonStack={this.buttonStack('observations')} title={t('batchActivityDef')} toggleDialog={this.clear}
                             dialogStatus={this.state.edit} fullWidth maxWidth='sm' >
                    <Grid container spacing={2}>
                        <Grid container item xs={12} sm={6}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t('details')}</FormLabel>
                                <Grid item xs={12}>
                                    <TextField value={this.state.code} onChange={this.changeEdit} name="code"
                                    size="medium" fullWidth label={t('code')} required 
                                    error={errors[t('code')] != null ? true : false} helperText={errors[t('code')]}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField value={this.state.description} onChange={this.changeEdit} name="description"
                                    size="medium" fullWidth label={t('description')} 
                                    error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField type="number" value={this.state.perPlantEffort} onChange={this.changeNumber} name="perPlantEffort"
                                    size="medium" label={t('perPlantEffort')} inputProps={{ maxLength: 4 }} 
                                    error={errors[t('perPlantEffort')] != null ? true : false} helperText={errors[t('perPlantEffort')]}/>
                                </Grid>
                                {this.props.auth.user.internal &&
                                    <Grid item xs={12} sx={{ mt: 1 }}>
                                        <TextField value={this.state.exportName} onChange={this.changeEdit} name="exportName"
                                        size="medium" fullWidth={true} label={t('exportName')} variant='outlined' helperText='Internal Only'/>
                                    </Grid>
                                }
                            </FormControl>
                        </Grid>
                        <Grid container item xs={12} sm={6}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t('options')}</FormLabel>
                                <FormControlLabel
                                    control={<Switch checked={this.state.changeRoom} onChange={this.changeBool} name="changeRoom" />}
                                    label={t('changeRoom')}
                                />
                                {!micro &&
                                    <Aux>
                                        <FormControlLabel
                                            control={<Switch checked={this.state.cleanRoomBefore} onChange={this.changeBool} name="cleanRoomBefore" />}
                                            label={t('cleanRoomBefore')}
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={this.state.cleanRoomAfter} onChange={this.changeBool} name="cleanRoomAfter" />}
                                            label={t('cleanRoomAfter')}
                                        />
                                    </Aux>
                                }
                                <FormControlLabel
                                    control={<Switch checked={this.state.includeStartItem} onChange={this.changeBool} name="includeStartItem" />}
                                    label={t('includeStartItem')}
                                />
                                <FormControlLabel
                                    control={<Switch checked={this.state.hide} onChange={this.changeBool} name="hide" />}
                                    label={t('hide')}
                                />
                                <FormControlLabel
                                    control={<Switch checked={this.state.protected} onChange={this.changeBool} name="protected" disabled={!this.props.auth.user.internal}/>}
                                    label={t('protected')}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </ModalDialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
  });

 export default withTranslation() (connect(mapStateToProps)(BatchActivities));
