import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import BatchObservation from './Forms/BatchObservation';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import Paper from '@mui/material/Paper';
import { AddBlankButton } from '../UI/Buttons/Buttons';
import TableContainer from '@mui/material/TableContainer';

class BatchObservations extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            showObservation: false,
            observation: ''
        };
        this.hideObservation = this.hideObservation.bind(this);
    }

    async componentDidMount(){
        this.loadRecords();
    }

    loadRecords = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let query = {
            batch: this.props.batch,
            sort: JSON.stringify(sort)
        }
        let records = await axios.get('/api/batchobservations', {params: query});
        this.setState({
            list: records.data.data,
            totalCount: records.data.totalCount
        }, () => {
            if(this.props.observationId != null && this.props.observationId !== '') {
                let index = records.data.data.findIndex(x => x._id === this.props.observationId);
                if(index > -1) {
                    let rec = records.data.data[index];
                    this.setState({
                        observation: rec._id,
                        showObservation: true
                    })
                }                    
            }
        });
    }

    showObservation = (i) => (e) => {
        let rec = this.state.list[i];
        this.setState({
            observation: rec._id,
            showObservation: true
        })
    };

    hideObservation(e){
        this.setState({
            observation: '',
            showObservation: false
        })
    }

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    addObservation = (e) => {
        this.setState({showObservation: true});
    }

    handleSave = () => {
        this.setState({ showObservation: false }, () => {
            this.loadRecords();
            this.props.callBack();
        });
    }

    render(){
        const t = this.props.t;
        return(
           <Grid container spacing={3} >
                <Grid item xs={12}>
                    <AddBlankButton addClick={this.addObservation} color='secondary' variant='contained' buttonStyle={{ float: 'right'}} 
                                    enabled={true} buttonName={t('createNew')}/>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="batchobservations" size='small'>
                            <TableHead key="obsevationHeader">
                                <TableRow >
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('date')}
                                        active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('type')}
                                        active={this.state.orderBy === 'type'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('type')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('activity')}</TableCell>
                                    <TableCell>{t('user')}</TableCell>
                                    <TableCell>{t('priority')}</TableCell>
                                </TableRow>                    
                            </TableHead>
                            <TableBody>
                                {this.state.list != null && this.state.list.length > 0 ? this.state.list.map((row, i) => 
                                    <TableRow key={'batchObservation-' + row._id}>
                                        <TableCell><TextOnlyClickButton click={this.showObservation(i)} enabled={true} name={row.number}/></TableCell>
                                        <TableCell><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                        <TableCell>{row.type != null ? row.type.label : ''}</TableCell>
                                        <TableCell>{row.activity != null ? row.activity.number : ''}</TableCell>
                                        <TableCell>{row.user != null ? row.user.firstName + ' ' + row.user.lastName : ''}</TableCell>
                                        <TableCell>{row.priority != null ? row.priority.label : ''}</TableCell>
                                    </TableRow>) :
                                    <TableRow key="observation-table-noData">
                                        <TableCell colSpan="6" sx={{ textAlign: 'center' }}>{t('noData')}</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Grid>                
                <BatchObservation
                    id={this.state.observation}
                    batch={this.props.batch}
                    open={this.state.showObservation}
                    user={this.props.auth.user.userid}
                    onClose={() => this.hideObservation}
                    tab={this.props.tab}
                    onSave={this.handleSave}
                />
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(BatchObservations));