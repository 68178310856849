import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty'; 
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AddBlankButton, ClearButton, DeleteButton, TextOnlyClickButton,
         SaveButton, ReturnButton } from '../UI/Buttons/Buttons';
import ActionBar from '../UI/Buttons/ActionBar';
import { removePath } from '../../actions/security';
import { withTranslation } from 'react-i18next';
import ObjectLog from '../General/ObjectLog';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class Screen extends Component {
    constructor(props){
        super(props);
        this.state = {
            screen: '',
            screenSel: null,
            _id: '',
            all: false,
            internal: false,
            read: [],
            create: [],
            update: [],
            delete: [],
            review: [],
            signoff: [],
            complete: [],
            unlock: [],
            print: [],
            documentDelete: [],
            documentUpload: [],
            screens: [],
            roles: [],
            departments: [],
            processes: [],
            edit: false,
            type: '',
            list: [],
            role: null,
            roleSel: null,
            depIds: [],
            depSels: [],
            process: '',
            procSel: null,
            security: [],
            enableSave: true,
            enableSaveRole: true,
            renderNum: Math.random()
        }
    }

    async componentDidMount(){
        const t = this.props.t;
        const values = await axios.get('/api/screenvalues');
        let roles = values.data.roles;
        roles.forEach(row => {
            row.label = t(row.label);
        });
        this.setState({
            screens: values.data.screens,
            roles: roles,
            departments: values.data.departments,
            processes: values.data.processes,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            security: this.props.permission
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/screens', label: this.props.t('screens'), screen: this.props.auth.screenDefs.Screens},
                    { path: 'active', label: this.props.t('screen'), screen: this.props.auth.screenDefs.Screen}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        })
    }

    loadRecord = async () => {
        const record = await axios.get('/api/screen/' + this.state._id);
        const screenSel = this.state.screens.find(x => x.value === record.data.screen._id);
        this.setState({
            screen: record.data.screen,
            all: record.data.all,
            internal: record.data.internal,
            screenSel: screenSel,
            read: record.data.read,
            create: record.data.create,
            update: record.data.update,
            delete: record.data.delete,
            complete: record.data.complete,
            review: record.data.review,
            signoff: record.data.signoff,
            unlock: record.data.unlock,
            print: record.data.print,
            documentDelete: record.data.documentDelete,
            documentUpload: record.data.documentUpload,
            renderNum: Math.random()
        }, () => {
            const crumbs = [
                { path: '/#/screens', label: this.props.t('screens'), screen: this.props.auth.screenDefs.Screens},
                { path: 'active', label: screenSel.label, screen: this.props.auth.screenDefs.Screen}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeMulti = (name, data) => (e, newValue) => {
        const val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false}, () => this._save());
    }

    _save = async (e) => {
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true});
            return;
        }
        const data = {
            screen: this.state.screen,
            all: this.state.all,
            internal: this.state.internal,
            _id: this.state._id
        };
        try {
            const result = await axios.post('/api/screen', data);
            this.clear();
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.loadRecord();
                if(this.state.screen.path != null || this.state.screen.path !== '') this.props.dispatch(removePath(this.state.screen.path, this.props.security.pathCache));
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    };

    validate(){
        let errors = {};
        const t = this.props.t;
        if(this.state.screen == null || this.state.screen === '')
            errors[t('screen')] = this.props.t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    returnClick = (e) => {
        this.props.history.goBack();
    };

    edit(type, index){
        let list = this.listForType(type);
        if(list != null){
            let row = list[index];
            const roleSel = this.state.roles.find(x => x.value === row.role._id);
            let depSels = [];
            let departments = [];
            if(row.departments != null && row.departments.length > 0){
                row.departments.forEach(dep => {
                    departments.push(dep._id);
                    const depSel = this.state.departments.find(x => x.value === dep._id);
                    depSels.push(depSel);
                });
            }
            const procSel = row.process != null && row.process !== '' ?
                this.state.processes.find(x => x.value === row.process) : null;
            this.setState({
                edit: true,
                type: type,
                list: list,
                depIds: departments,
                depSels: depSels,
                roleSel: roleSel,
                role: row.role._id,
                process: row.process,
                procSel: procSel
            })
        }
    }

    add(type){
        const list = this.listForType(type);
        if(list != null){
            this.setState({
                list: list,
                edit: true,
                type: type
            })
        }
    }

    deleteRole = async (e) => {
        const data = {
            type: this.state.type,
            role: this.state.role,
            screenId: this.state._id
        }
        try {
            const result = await axios.post('/api/screenroledelete', data);
            this.clear();
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    };

    listForType(type){
        switch(type){
            case 'read':
                return this.state.read;
            case 'create':
                return this.state.create;
            case 'update':
                return this.state.update;
            case 'delete':
                return this.state.delete;
            case 'complete':
                return this.state.complete;
            case 'review':
                return this.state.review;
            case 'signoff':
                return this.state.signoff;
            case 'unlock':
                return this.state.unlock;
            case 'print':
                return this.state.print;
            case 'documentDelete':
                return this.state.documentDelete;
            case 'documentUpload':
                return this.state.documentUpload;
            default:
                return null;
        }
    }

    saveRole = () => {
        if(!this.state.enableSaveRole)
            return;
        this.setState({ enableSaveRole: false }, () => this._saveRole());
    }

    _saveRole = async () => {
        const valid = this.validateRole();
        if(!valid) {
            this.setState({ enableSaveRole: true });
            return;
        }
        const data = {
            type: this.state.type,
            role: this.state.role,
            departments: this.state.depIds,
            screenId: this.state._id,
            process: this.state.process
        }
        try {
            const result = await axios.post('/api/screenrole', data);
            this.setState({ enableSaveRole: true }, () => {
                this.clear();
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSaveRole: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    };

    validateRole(){
        let errors = {};
        const t = this.props.t;
        if(this.state.type == null || this.state.type === ''){
            errors[t('type')] = "Type required";
        }
        if(this.state.role == null || this.state.role === ''){
            errors[t('role')] = "Role required";
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    clear = (e) => {
        this.setState({
            edit: false,
            type: null,
            role: null,
            roleSel: null,
            depIds: [],
            depSels: [],
            process: '',
            procSel: null
        })
    };

    render(){
        const { errors, t } = this.props;
        const security = this.state.security;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.screenSel}
                                options={this.state.screens}
                                onChange={this.changeAuto('screenSel', 'screen')}
                                error={errors[t('screen')] != null ? true : false}
                                helperText={errors[t('screen')]} 
                                label={t('screenDefinition')}
                                required
                                data-cy='screenDefinition'
                            />
                        </Grid>
                        {this.props.auth.user.internal &&
                            <Aux>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.all} onChange={this.changeBool('all')}
                                    label={t('all')} name="all" data-cy='all'
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.internal} onChange={this.changeBool('internal')}
                                    label={t('internal')} name="internal" data-cy='internal'
                                    />
                                </Grid>
                            </Aux>
                        }
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <SectionTitle title={t('read')} />
                            <TableContainer component={Paper}>
                                <Table aria-label="Read Roles">
                                    <TableHead>
                                        <TableRow key="readTableHeader">
                                            <TableCell sx={{ width: '10%'}}>{t('role')}</TableCell>
                                            <TableCell>{t('departments')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.read.map((read, i) =>
                                            <TableRow key={"read" + i}>
                                                <TableCell sx={{ width: '10%'}}>
                                                    {read != null && read.role != null &&
                                                        <TextOnlyClickButton enabled={true} click={() => this.edit('read', i)} name={read.role.label}/>
                                                    }
                                                </TableCell>
                                                <TableCell>{read.depString != null ? read.depString : ''}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <AddBlankButton
                                addClick={() => this.add('read')}
                                enabled={security.update || security.complete}
                                buttonName={t('add')} />
                            <br/>
                            <SectionTitle title={t('create')} />
                            <TableContainer component={Paper}>
                                <Table aria-label="Create Roles">
                                    <TableHead>
                                        <TableRow key="createTableHeader">
                                            <TableCell sx={{ width: '10%'}}>{t('role')}</TableCell>
                                            <TableCell>{t('departments')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.create.map((create, i) =>
                                            <TableRow key={"create" + i}>
                                                <TableCell sx={{ width: '10%'}}>
                                                    {create != null && create.role != null &&
                                                        <TextOnlyClickButton enabled={true} click={() => this.edit('create', i)} name={create.role.label}/>
                                                     }
                                                </TableCell>
                                                <TableCell>{create.depString != null ? create.depString : ''}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <AddBlankButton
                                addClick={() => this.add('create')}
                                enabled={security.update || security.complete}
                                buttonName={t('add')} />
                            <br/>
                            <SectionTitle title={t('update')} />
                            <TableContainer component={Paper}>
                                <Table aria-label="Update Roles">
                                    <TableHead>
                                        <TableRow key="updateTableHeader">
                                            <TableCell sx={{ width: '10%'}}>{t('role')}</TableCell>
                                            <TableCell>{t('departments')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.update.map((update, i) =>
                                            <TableRow key={"update" + i}>
                                                <TableCell sx={{ width: '10%'}}>
                                                    {update != null && update.role != null &&
                                                        <TextOnlyClickButton enabled={true} click={() => this.edit('update', i)} name={update.role.label}/>
                                                    }
                                                </TableCell>
                                                <TableCell>{update.depString != null ? update.depString : ''}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <AddBlankButton
                                addClick={() => this.add('update')}
                                enabled={security.update || security.complete}
                                buttonName={t('add')} />
                            <br/>
                            <SectionTitle title={t('delete')} />
                            <TableContainer component={Paper}>
                                <Table aria-label="Delete Roles">
                                    <TableHead>
                                        <TableRow key="deleteTableHeader">
                                            <TableCell sx={{ width: '10%'}}>{t('role')}</TableCell>
                                            <TableCell>{t('departments')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.delete.map((del, i) =>
                                            <TableRow key={"delete" + i}>
                                                <TableCell sx={{ width: '10%'}}>
                                                    {del != null && del.role != null &&
                                                        <TextOnlyClickButton enabled={true} click={() => this.edit('delete', i)} name={del.role.label}/>
                                                    }
                                                </TableCell>
                                                <TableCell>{del.depString != null ? del.depString : ''}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <AddBlankButton
                                addClick={() => this.add('delete')}
                                enabled={security.update || security.complete}
                                buttonName={t('add')} />
                            <br/>
                            <SectionTitle title={t('complete')} />
                            <TableContainer component={Paper}>
                                <Table aria-label="Complete Roles">
                                    <TableHead>
                                        <TableRow key="completeTableHeader">
                                            <TableCell sx={{ width: '10%'}}>{t('role')}</TableCell>
                                            <TableCell>{t('departments')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.complete.map((complete, i) =>
                                            <TableRow key={"complete" + i}>
                                                <TableCell sx={{ width: '10%'}}>
                                                    {complete != null && complete.role != null &&
                                                        <TextOnlyClickButton enabled={true} click={() => this.edit('complete', i)} name={complete.role.label}/>
                                                    }
                                                </TableCell>
                                                <TableCell>{complete.depString != null ? complete.depString : ''}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <AddBlankButton
                                addClick={() => this.add('complete')}
                                enabled={security.update || security.complete}
                                buttonName={t('add')} />
                            <br/>
                            <SectionTitle title={t('review')} />
                            <TableContainer component={Paper}>
                                <Table aria-label="Review Roles">
                                    <TableHead>
                                        <TableRow key="reviewTableHeader">
                                            <TableCell sx={{ width: '10%'}}>{t('role')}</TableCell>
                                            <TableCell>{t('departments')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.review.map((review, i) =>
                                            <TableRow key={"review" + i}>
                                                <TableCell sx={{ width: '10%'}}>
                                                    {review != null && review.role != null &&
                                                        <TextOnlyClickButton enabled={true} click={() => this.edit('review', i)} name={review.role.label}/>
                                                    }
                                                </TableCell>
                                                <TableCell>{review.depString != null ? review.depString : ''}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <AddBlankButton
                                addClick={() => this.add('review')}
                                enabled={security.update || security.complete}
                                buttonName={t('add')} />
                            <br/>
                            <SectionTitle title={t('signoff')} />
                            <TableContainer component={Paper}>
                                <Table aria-label="Signoff Roles">
                                    <TableHead>
                                        <TableRow key="signoffTableHeader">
                                            <TableCell sx={{ width: '10%'}}>{t('role')}</TableCell>
                                            <TableCell>{t('departments')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.signoff.map((signoff, i) =>
                                            <TableRow key={"signoff" + i}>
                                                <TableCell sx={{ width: '10%'}}>
                                                    {signoff != null && signoff.role != null &&
                                                        <TextOnlyClickButton enabled={true} click={() => this.edit('signoff', i)} name={signoff.role.label}/>
                                                    }
                                                </TableCell>
                                                <TableCell>{signoff.depString != null ? signoff.depString : ''}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <AddBlankButton
                                addClick={() => this.add('signoff')}
                                enabled={security.update || security.complete}
                                buttonName={t('add')} />
                            <br/>
                            <SectionTitle title={t('unlock')} />
                            <TableContainer component={Paper}>
                                <Table aria-label="Unlock Roles">
                                    <TableHead>
                                        <TableRow key="unlockTableHeader">
                                            <TableCell sx={{ width: '10%'}}>{t('role')}</TableCell>
                                            <TableCell>{t('departments')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.unlock.map((unlock, i) =>
                                            <TableRow key={"unlock" + i}>
                                                <TableCell sx={{ width: '10%'}}>
                                                    {unlock != null && unlock.role != null &&
                                                        <TextOnlyClickButton enabled={true} click={() => this.edit('unlock', i)} name={unlock.role.label}/>
                                                    }
                                                </TableCell>
                                                <TableCell>{unlock.depString != null ? unlock.depString : ''}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <AddBlankButton
                                addClick={() => this.add('unlock')}
                                enabled={security.update || security.complete}
                                buttonName={t('add')} />
                            <br/>
                            <SectionTitle title={t('print')} />
                            <TableContainer component={Paper}>
                                <Table aria-label="Print Documents">
                                    <TableHead>
                                        <TableRow key="printTableHeader">
                                            <TableCell sx={{ width: '10%'}}>{t('role')}</TableCell>
                                            <TableCell>{t('departments')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.print.map((print, i) =>
                                            <TableRow key={"print" + i}>
                                                <TableCell sx={{ width: '10%'}}>
                                                    {print != null && print.role != null &&
                                                        <TextOnlyClickButton enabled={true} click={() => this.edit('print', i)} name={print.role.label}/>
                                                    }
                                                </TableCell>
                                                <TableCell>{print.depString != null ? print.depString : ''}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <AddBlankButton
                                addClick={() => this.add('print')}
                                enabled={security.update || security.complete}
                                buttonName={t('add')} />
                            <br/>
                            <SectionTitle title={t('documentDelete')} />
                            <TableContainer component={Paper}>
                                <Table aria-label="Delete Documents">
                                    <TableHead>
                                        <TableRow key="docDeleteTableHeader">
                                            <TableCell sx={{ width: '10%'}}>{t('role')}</TableCell>
                                            <TableCell>{t('departments')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.documentDelete.map((doc, i) =>
                                            <TableRow key={"docDelete" + i}>
                                                <TableCell sx={{ width: '10%'}}>
                                                    {doc != null && doc.role != null &&
                                                        <TextOnlyClickButton enabled={true} click={() => this.edit('documentDelete', i)} name={doc.role.label}/>
                                                    }
                                                </TableCell>
                                                <TableCell>{doc.depString != null ? doc.depString : ''}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <AddBlankButton
                                addClick={() => this.add('documentDelete')}
                                enabled={security.update || security.complete}
                                buttonName={t('add')} />
                            <br/>
                            <SectionTitle title={t('documentUpload')} />
                            <TableContainer component={Paper}>
                                <Table aria-label="Upload Documents">
                                    <TableHead>
                                        <TableRow key="docUploadTableHeader">
                                            <TableCell sx={{ width: '10%'}}>{t('role')}</TableCell>
                                            <TableCell>{t('departments')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.documentUpload.map((doc, i) =>
                                            <TableRow key={"docUpload" + i}>
                                                <TableCell sx={{ width: '10%'}}>
                                                    {doc != null && doc.role != null &&
                                                        <TextOnlyClickButton enabled={true} click={() => this.edit('documentUpload', i)} name={doc.role.label}/>
                                                    }
                                                </TableCell>
                                                <TableCell>{doc.depString != null ? doc.depString : ''}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <AddBlankButton
                                addClick={() => this.add('documentUpload')}
                                enabled={security.update || security.complete}
                                buttonName={t('add')} />
                        </Aux>
                    }
                    <br/>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <SaveButton saveClick={this.save} enabled={this.state.enableSave && (security.update || security.complete || security.review || security.signoff)} />
                            <ReturnButton returnClick={this.returnClick} />
                        </Grid>
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <br/>
                            <ObjectLog id={this.state._id}  renderNum={this.state.renderNum}/>
                        </Aux>
                    }
                    <Dialog open={this.state.edit} onClose={this.clear} maxWidth={"md"} fullWidth={true}>
                        <DialogTitle aria-labelledby={this.props.name + '-dialog-title'}>
                            Role
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.roleSel}
                                        options={this.state.roles}
                                        onChange={this.changeAuto('roleSel', 'role')}
                                        error={errors[t('role')] != null ? true : false}
                                        helperText={errors[t('role')]} 
                                        label={t('role')}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.procSel}
                                        options={this.state.processes}
                                        onChange={this.changeAuto('procSel', 'process')}
                                        error={errors[t('process')] != null ? true : false}
                                        helperText={errors[t('process')]} 
                                        label={t('process')}
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <AutoCompleteField
                                        multiple={true}
                                        stayOpen={true}
                                        value={this.state.depSels}
                                        options={this.state.departments}
                                        onChange={this.changeMulti('depSels', 'depIds')}
                                        error={errors[t('departments')] != null ? true : false}
                                        helperText={errors[t('departments')]} 
                                        label={t('departments')}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <SaveButton saveClick={this.saveRole} enabled={this.state.enableSaveRole && (security.update || security.complete || security.review || security.signoff)} />
                            <ClearButton clearClick={this.clear} enabled={true}/>
                            <DeleteButton deleteClick={this.deleteRole} enabled={security.update || security.complete || security.review || security.signoff} />
                        </DialogActions>
                    </Dialog>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    security: state.security,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Screen));
