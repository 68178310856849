import React, { Component } from 'react';
import { connect} from 'react-redux';
import Aux from '../../hoc/Auxilary/Auxilary';
import Panel from '../UI/Panel/Panel';
import ScheduledActs from '../Widgets/ScheduledActs';
import queryString from 'query-string';
import ConversionHelper from '../../helpers/conversionHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class ScheduledActivities extends Component {
    constructor(props){
        super(props);
        this.state = {
            assignee: '',
            startDate: '',
            endDate: '',
            status: '',
            preDays: 7,
            postDays: -7,
            security: [],
            activity: ''
        };
    }

    async componentDidMount(){
        let params = this.props.location != null ? queryString.parse(this.props.location.search) : null;
        const user = params != null && params.assignee != null ? params.assignee : null;
        const status = params != null && params.status != null ? params.status : '';
        const activity = params != null && params.activity != null ? params.activity : '';
        const startDateString = params != null && params.startDate != null ? params.startDate : '';
        const endDateString = params != null && params.endDate != null ? params.endDate : '';
        let days = params != null && params.days != null ? params.days : null;
        let startDate = startDateString !== '' ? startDateString : ConversionHelper.dateSet(new Date(), this.state.preDays);
        let endDate = endDateString !== '' ? endDateString : ConversionHelper.dateSet(new Date(), this.state.postDays);
        if(days != null){
            days = parseInt(days);
            endDate = null;
            startDate = ConversionHelper.dateSet(new Date(), days);
        }
        this.setState({
            assignee: user,
            status: status,
            endDate: endDate,
            startDate: startDate,
            security: this.props.permission,
            activity: activity
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('scheduledActs'), screen: this.props.auth.screenDefs.ScheduledActs}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    render(){
        // const security = this.state.security;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        // createLocation='/#/scheduledActivity'
                        // createAccess={security.create}
                    />
                </BreadcrumbBar>
                <Panel>
                    <ScheduledActs assignee={this.state.assignee} startDate={this.state.startDate} endDate={this.state.endDate} 
                    status={this.state.status} listSize={10} activity={this.state.activity}/>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile
});

export default withTranslation() (connect(mapStateToProps)(ScheduledActivities));