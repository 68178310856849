import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import Grid from '@mui/material/Grid';
import Aux from '../../hoc/Auxilary/Auxilary';
import SectionTitle from '../UI/Typography/SectionTitle';
import { AddBlankButton, ClearButton, ReturnButton, TextOnlyClickButton,
         DeleteButton, SaveButton } from '../UI/Buttons/Buttons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '../UI/TextField/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import RegisterIcon from '@mui/icons-material/AppRegistration';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Panel from '../UI/Panel/Panel';
import Fieldset from '../UI/Fieldset/Fieldset';
import { EvoraTabs, TabCanvas } from '../UI/Tabs/Tabs';
import TenantUserCountTab from './TenantUserCountTab';
import Button from '@mui/material/Button';
// import {encryptString} from '../../helpers/securityHelper';
// import ActionBar from '../UI/Buttons/ActionBar';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import { withTranslation } from 'react-i18next';
import ValueHelper from '../../helpers/valueHelper';

class Tenant extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            name: '',
            erpType: '',
            erpSel: null,
            connectionPrefix: '',
            environmentIdentifier: '',
            erpUserid: '',
            erpPassword: '',
            endpoints: [],
            erpTypes: [],
            endpointTypes: [],
            endpoint: '',
            endValue: '',
            locations: [],
            endIndex: -1,
            editEnd: false,
            cultivator: false,
            sessionExpirationMinutes: '',
            sessionIdleMinutes: '',
            sessionIdleWarningMinutes: '',
            passwordExpirationMonths: '',
            pinExpirationMonths: '',
            security: [],
            fromTenant: '',
            fromTenantSel: null,
            tenants: [],
            showCopy: false,
            copySettings: false,
            copyScreens: false,
            copyValues: false,
            copyItemCategories: false,
            copyBatchActs: false,
            copySops: false,
            copyDocs: false,
            copyItems: false,
            copyTemplates: false,
            copyInternalUsers: false,
            copyStrains: false,
            copyEmailTemplates: false,
            copyBaseData: false,
            copyTestItemCategories: false,
            copyTestItems: false,
            copyObjects: false,
            locationName: '',
            defaultLocation: null,
            active: true,
            enableSave: true,
            erpAuthTypes: [],
            erpAuthType: '',
            erpAuthTypeSel: null,
            messageTypes: [],
            messageType: '',
            messageTypeSel: null,
            authTypes: [],
            authType: [],
            authTypeSels: [],
            cropType: '',
            cropTypes: [],
            cropTypeSel: null,
            customLotNumbers: false,
            licenseTypes: [],
            licenseType: '',
            licenseTypeSel: {},
            manualItemNumber: false,
            licenseFees: {
                advanced: 0,
                basic: 0,
                normal: 0,
                pack5: 0,
                pack10: 0,
            },
            contractLicenses: {
                advanced: 0,
                basic: 0,
                normal: 0,
                pack5: 0,
                pack10: 0,
                neobi: false
            },
            tabKey: 0,
            tabLabels: [
                {name: 'General', disabled: false},
                {name: 'Users', disabled: true},
                {name: 'ERP Information', disabled: false},
            ],
            helpdeskStatus: '',
            readOnly: false,
            lockHistory: false
        }
    }

    async componentDidMount(){
        const values = await axios.get('/int/tenantvalues');
        const authTypes = ValueHelper.authOptions();
        this.setState({
            erpTypes: values.data.erpTypes,
            endpointTypes: values.data.endpoints,
            security: this.props.permission,
            tenants: values.data.tenants,
            erpAuthTypes: values.data.authTypes,
            messageTypes: values.data.messageTypes,
            authTypes: authTypes,
            cropTypes: values.data.cropTypes,
            licenseTypes: values.data.licenseTypes,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/tenants', label: 'Tenants', screen: this.props.auth.screenDefs.Tenants},
                    { path: 'active', label: 'Tenant', screen: this.props.auth.screenDefs.Tenant}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
        
    }

    loadRecord = async () => {
        const id = this.state._id != null && this.state._id !== '' ? this.state._id : this.props.match.params.id;
        const record = await axios.get('/int/tenant/' + id);
        const erpSel = this.state.erpTypes.find(x => x.value === record.data.erp.type);
        let ends = [];
        let erpAuthTypeSel = record.data.erp.authType != null ? this.state.erpAuthTypes.find(x => x.value === record.data.erp.authType) : null;
        let messageTypeSel = record.data.erp.messageType != null ? this.state.messageTypes.find(x => x.value === record.data.erp.messageType) : null;
        record.data.erp.endpoints.forEach(end => {
            const endSel = this.state.endpointTypes.find(x => x.value === end.endpoint);
            ends.push({
                endpoint: endSel,
                value: end.value
            })
        });
        let authTypeSels = [];
        if(record.data.authType != null && record.data.authType.length > 0){
            for(let row of record.data.authType){
                let authTypeSel = this.state.authTypes.find(x => x.value === row);
                authTypeSels.push(authTypeSel);
            }
        }
        let cropTypeSel = this.state.cropTypes.find(x => x.value === record.data.cropType);
        let licenseTypeSel = this.state.licenseTypes.find(x => x.value === record.data.type);
        let tabLabels = this.state.tabLabels;
        tabLabels[1].disabled = false;
        this.setState({
            _id: record.data._id,
            name: record.data.name,
            erpType: record.data.erp.type,
            connectionPrefix: record.data.erp.connectionPrefix,
            environmentIdentifier: record.data.erp.environmentIdentifier,
            erpPassword: record.data.erp.password,
            erpUserid: record.data.erp.userid,
            endpoints: ends,
            locations: record.data.locations,
            erpSel: erpSel,
            cultivator: record.data.cultivator != null ? record.data.cultivator : false,
            defaultLocation: record.data.defaultLocation,
            active: record.data.active,
            sessionExpirationMinutes: record.data.sessionExpirationMinutes,
            sessionIdleMinutes: record.data.sessionIdleMinutes,
            sessionIdleWarningMinutes: record.data.sessionIdleWarningMinutes,
            passwordExpirationMonths: record.data.passwordExpirationMonths,
            pinExpirationMonths: record.data.pinExpirationMonths,
            messageType: record.data.erp.messageType,
            messageTypeSel: messageTypeSel,
            erpAuthType: record.data.erp.authType,
            erpAuthTypeSels: erpAuthTypeSel,
            authType: record.data.authType,
            authTypeSels: authTypeSels,
            cropType: record.data.cropType,
            cropTypeSel: cropTypeSel,
            customLotNumbers: record.data.customLotNumbers !== true ? false : true,
            licenseTypeSel: licenseTypeSel,
            licenseType: record.data.type,
            contractLicenses: record.data.contractLicenses,
            licenseFees: record.data.licenseFees,
            tabLabels: tabLabels,
            manualItemNumber: record.data.manualItemNumber === true ? true : false,
            readOnly: record.data.readOnly != null ? record.data.readOnly : false,
            lockHistory: record.data.lockHistory != null ? record.data.lockHistory : false
        }, async () => {
            const crumbs = [
                { path: '/#/tenants', label: 'Tenants', screen: this.props.auth.screenDefs.Tenants},
                { path: 'active', label: record.data.name, screen: this.props.auth.screenDefs.Tenant}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            if(record.data.helpdeskId == null || record.data.helpdeskId === '') {
                const fdCheck = await axios.get('/api/checkfdtenant/' + record.data.name.split(" ")[0]);
                let status = this.state.helpdeskStatus;
                if(fdCheck.status === 200) {
                    if(isEmpty(fdCheck.data.companies)) {
                        status = 'None';
                    }else {
                        let exists = fdCheck.data.companies.filter(company => company.name.includes(record.data.name));
                        status = exists != null ? 'Exists' : 'None';
                    }
                }
                this.setState({helpdeskStatus: status});
            }else {
                this.setState({helpdeskStatus: 'Exists'});
            }
        });
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
        });
    };

    changeMulti = (name, data) => (e, newValue) => {
        let ids = [];
        if(newValue != null && newValue.length > 0){
            for(let row of newValue){
                ids.push(row.value);
            }
        }
        this.setState({[name]: newValue, [data]: ids});
    }

    changeValue = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    changeSelect = (name, data) => value => {
        this.setState({[name]: value, [data]: value.value});
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    dateChange = e => {
        this.setState({date: e});
    };

    changeNumber = (e) => {
        let value = e.target.value;
        if(isNaN(value))
            return;
        let name = e.target.name;
        let result = '';
        if(name.includes('contractLicenses')) {
            result = name.replace('contractLicenses', '').toLowerCase();
            let contractLicenses = this.state.contractLicenses;
            contractLicenses[result] = value;
            this.setState({contractLicenses});
        }else if(name.includes('licenseFees')) {
            result = name.replace('licenseFees', '').toLowerCase();
            let licenseFees = this.state.licenseFees;
            licenseFees[result] = value;
            this.setState({licenseFees});
        }else {
            this.setState({[name]: value});
        }        
    }

    changeNeobi = (e) => {
        let neobi = this.state.contractLicenses.neobi === true ? true : false;
        this.setState({contractLicenses: {...this.state.contractLicenses, neobi: !neobi}});
    }

    clearCache = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._clearCache());
    };

    _clearCache = async () => {
        let data = {tenant: this.state._id};
        try {
            let result = await axios.post('/int/tenantcacheclear', data)
            this.setState({enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            }); 
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
            });            
        }
    }

    save = () => {        
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
            
        let ends = [];
        //this.state.endpoints.forEach(end => {
        //    ends.push({
        //        _id: end._id,
        //        endpoint: end.endpoint.value,
        //        value: end.value
        //    });
        //});
        const data = {
            _id: this.state._id,
            name: this.state.name,
            erpType: this.state.erpType,
            connectionPrefix: this.state.connectionPrefix,
            environmentIdentifier: this.state.environmentIdentifier,
            erpPassword: this.state.erpPassword,
            erpUserid: this.state.erpUserid,
            endpoints: ends,
            cultivator: this.state.cultivator,
            locationName: this.state.locationName !== '' && this.state.location != null ? this.state.locationName : null,
            active: this.state.active,
            sessionExpirationMinutes: this.state.sessionExpirationMinutes,
            sessionIdleMinutes: this.state.sessionIdleMinutes,
            sessionIdleWarningMinutes: this.state.sessionIdleWarningMinutes,
            passwordExpirationMonths: this.state.passwordExpirationMonths,
            pinExpirationMonths: this.state.pinExpirationMonths,
            authType: this.state.authType,
            erpAuthType: this.state.erpAuthType,
            messageType: this.state.messageType,
            cropType: this.state.cropType,
            customLotNumbers: this.state.customLotNumbers,
            licenseType: this.state.licenseType,            
            contractLicenses: this.state.contractLicenses,
            licenseFees: this.state.licenseFees,
            manualItemNumber: this.state.manualItemNumber,
            readOnly: this.state.readOnly,
            lockHistory: this.state.lockHistory
        };
        try {
            const result = await axios.post('/int/tenant', data);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
            });            
        }
    };

    returnClick = e => {
        this.props.history.goBack();
    };

    validate(){
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.name == null || state.name === '')
            errors[t('name')] = "Name required";
        if(state.authType == null || state.authType.length === 0)
            errors["Authentication"] = "Required";
        else {
            if(state.authType.length > 1){
                let db = state.authType.find(x => x === this.props.auth.common.auth.DB);
                if(db != null )
                    errors["Authentication"] = "Internal DB cannot be combined with other types";
            }
        }
        if(state.licenseType == null || state.licenseType === '')
            errors["Type"] = "Required";
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    editEndpoint = (index) => (e) => {
        const end = this.state.endpoints[index];
        this.setState({
            endpoint: end.endpoint.value,
            endSel: end.endpoint,
            endValue: end.value,
            endIndex: index,
            editEnd: true
        });
    };

    clearEndpoint = () => {
        this.setState({
            endIndex: -1,
            endSel: null,
            endpoint: '',
            endValue: '',
            editEnd: false
        });
    };

    validateEnd(){
        let errors = {};
        if(this.state.endpoint == null || this.state.endpoint === '')
            errors["Location"] = "Endpoint required";
        if(this.state.endValue == null || this.state.endValue === '')
            errors["Value"] = "Endpoint value required";
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    saveEndpoint = (e) => {
        const valid = this.validateEnd();
        if(!valid)
            return;
        const endOption = this.state.endpointTypes.find(x => x.value === this.state.endpoint);
        if(this.state.endIndex > -1){
            let end = this.state.endpoints[this.state.endIndex];
            end.endpoint = endOption;
            end.value = this.state.endValue;
        } else {
            this.state.endpoints.push({
                endpoint: endOption,
                value: this.state.endValue
            });
        }
        this.clearEndpoint();
    };

    deleteEndpoint = (e) => {
        if(this.state.endIndex < 0)
            return;
        let ends = this.state.endpoints;
        ends.splice(this.state.endIndex, 1);
        this.setState({endpoints: ends});
        this.clearEndpoint();
    };

    toggleEndpoint = (e) => {
        this.setState({editEnd: !this.state.editEnd});
    }

    copyScreensClick = (e) => {    
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._copyScreensClick());
    }

    _copyScreensClick = async (e) => {
        let errors = {};
        if(this.state.fromTenant == null || this.state.fromTenant === '')
            errors["From Tenant"] = "Required";
        if(this.state._id == null || this.state._id === '')
            errors["From Tenant"] = "Tenant must be saved first";
        if(this.state._id === this.state.fromTenant)
            errors["From Tenant"] = "Cannot be the this tenant";
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            this.setState({enableSave: true});
            return;
        }
        let body = {
            fromTenant: this.state.fromTenant,
            toTenant: this.state._id,
            copyScreens: this.state.copyScreens,
            copySettings: this.state.copySettings,
            copyValues: this.state.copyValues,
            copyItemCategories: this.state.copyItemCategories,
            copyItems: this.state.copyItems,
            copyBatchActs: this.state.copyBatchActs,
            copyTemplates: this.state.copyTemplates,
            copyDocs: this.state.copyDocs,
            copySops: this.state.copySops,
            copyInternalUsers: this.state.copyInternalUsers,
            copyStrains: this.state.copyStrains,
            copyEmailTemplates: this.state.copyEmailTemplates,
            copyBaseData: this.state.copyBaseData,
            copyTestItemCategories: this.state.copyTestItemCategories,
            copyTestItems: this.state.copyTestItems,
            copyObjects: this.state.copyObjects
        };
        try {
            let result = await axios.post('/int/tenantdatacopy', body);
            this.setState({enableSave: true});
            this.hideCopy();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
        }
    };

    generateJson = async (e) => {
        try{
            let result = await axios.get('/int/tenantjson/' + this.state._id);
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
        }
    };

    showCopy = (e) => {
        this.setState({showCopy: true});
    };

    hideCopy = (e) => {
        this.setState({
            showCopy: false,
            fromTenant: '',
            fromTenantSel: null,
            copyScreens: false,
            copySettings: false,
            copyValues: false,
            copyItemCategories: false,
            copyItems: false,
            copyBatchActs: false,
            copyTemplates: false,
            copyDocs: false,
            copySops: false,
            copyInternalUsers: false,
            copyStrains: false,
            copyEmailTemplates: false,
            copyBaseData: false,
            copyTestItemCategories: false,
            copyTestItems: false,
            copyObjects: false
        })
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    };

    registerHelpdesk = async () => {
        try {
            let result = await axios.post('/int/registertenanthelpdesk', {tenant: this.state._id});
            this.setState({helpdeskStatus: 'Exists'});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data.message, title: err.response.data.description, severity: 'error'}});
        }
    }

    refreshHelpdesk = async () => {
        try {
            const fdCheck = await axios.get('/api/checkfdtenant/' + this.state.name.split(" ")[0]);
            let status = this.state.helpdeskStatus;
            if(fdCheck.status === 200) {
                if(isEmpty(fdCheck.data.companies)) {
                    status = 'None';
                }else {
                    let exists = fdCheck.data.companies.filter(company => company.name.includes(this.state.name));
                    status = exists != null ? 'Exists' : 'None';
                }
            }
            this.setState({helpdeskStatus: status});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: "Success", title: 'Success', severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data.message, title: err.response.data.description, severity: 'error'}});
        }
    }

    render(){
        const { _id, active, editLicense, editEnd, enableSave, licenseType, tabKey, tabLabels, security } = this.state;
        const { auth, errors, t } = this.props;
        return(
            <Aux>
                <BreadcrumbBar />
                <EvoraTabs tabLabels={tabLabels} tabKey={tabKey} changeTab={this.changeTab} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TabCanvas value={tabKey} index={0}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Panel>
                                        <SectionTitle title="Details" />
                                        <Grid item xs={12}>
                                            <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                            size="medium" fullWidth label="Name" required 
                                            error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckboxField
                                            checked={this.state.active} onChange={this.changeBool('active')}
                                            label="Active" name="active"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckboxField
                                            checked={this.state.readOnly} onChange={this.changeBool('readOnly')}
                                            label="Read Only" name="readOnly"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckboxField
                                            checked={this.state.cultivator} onChange={this.changeBool('cultivator')}
                                            label="Cultivator" name="cultivator"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CheckboxField
                                            checked={this.state.customLotNumbers} onChange={this.changeBool('customLotNumbers')}
                                            label="Custom Numbers" name="customLotNumbers"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CheckboxField
                                            checked={this.state.manualItemNumber} onChange={this.changeBool('manualItemNumber')}
                                            label="Change Item Numbers" name="manualItemNumber"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CheckboxField
                                            checked={this.state.lockHistory} onChange={this.changeBool('lockHistory')}
                                            label="Lock History" name="lockHistory"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AutoCompleteField
                                            value={this.state.cropTypeSel}
                                            options={this.state.cropTypes}
                                            onChange={this.changeAuto('cropTypeSel', 'cropType')}
                                            error={errors["Crop Type"] != null ? true : false}
                                            helperText={errors["Crop Type"]} 
                                            label="Crop Type"
                                            disabled={!security.update || !security.complete}
                                            fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AutoCompleteField
                                            multiple
                                            value={this.state.authTypeSels}
                                            options={this.state.authTypes}
                                            onChange={this.changeMulti('authTypeSels', 'authType')}
                                            error={errors["Authentication"] != null ? true : false}
                                            helperText={errors["Authentication"]} 
                                            label="Authentication"
                                            disabled={!security.update || !security.complete}
                                            fullWidth
                                            />
                                        </Grid>
                                        {(this.state._id == null || this.state._id === '') &&
                                            <Grid item xs={12}>
                                                <TextField value={this.state.locationName} onChange={this.changeValue} name="locationName"
                                                size="medium" fullWidth={true} label="Initial Location" 
                                                error={errors["Initial Location"] != null ? true : false} helperText={errors["Initial Location"]}/>
                                            </Grid>
                                        }
                                        {(this.state._id == null || this.state._id === '') &&
                                            <Grid item xs={12}>
                                                <TextField value={this.state.locationName} onChange={this.changeValue} name="locationName"
                                                size="medium" fullWidth={true} label="Initial Location" 
                                                error={errors["Initial Location"] != null ? true : false} helperText={errors["Initial Location"]}/>
                                            </Grid>
                                        }
                                        {this.state.defaultLocation != null &&
                                            <Grid item xs={8} sm={4}>
                                                <FormControl>
                                                    <FormLabel>Initial Location</FormLabel>
                                                    {this.state.defaultLocation.name}
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Divider sx={{ margin: '8px' }} />
                                        {this.state._id != null && this.state._id !== '' &&
                                            <Grid item xs={12}>
                                                <AddBlankButton addClick={this.showCopy} enabled={security.complete && security.update} buttonName="Copy Data" />&nbsp;&nbsp;&nbsp;
                                                <AddBlankButton addClick={this.generateJson} enabled={security.complete && security.update} buttonName="Generate JSON" />
                                            </Grid>
                                        }
                                    </Panel>
                                </Grid>
                                <Grid item container xs={12} sm={6} md={4} spacing={2}>
                                    <Grid item xs={12}>
                                        <Panel>
                                            <SectionTitle title="Licensing" />
                                            <Grid item xs={12}>
                                                <AutoCompleteField
                                                    value={this.state.licenseTypeSel}
                                                    options={this.state.licenseTypes}
                                                    onChange={this.changeAuto('licenseTypeSel', 'licenseType')}
                                                    error={errors["Type"] != null ? true : false}
                                                    helperText={errors["Type"]} 
                                                    label="Type"
                                                    disabled={!security.update || !security.complete}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {this.state.licenseType != null && this.state.licenseType !== '' && 
                                                this.state.licenseType === auth.common.tenantTypes.legacy ? 
                                                <Grid container item xs={12} spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField value={this.state.contractLicenses != null ? this.state.contractLicenses.basic : 0} onChange={this.changeNumber} name="contractLicensesBasic"
                                                        size="medium" fullWidth label="Basic" />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField value={this.state.licenseFees != null ? this.state.licenseFees.basic : 0} onChange={this.changeNumber} name="licenseFeesBasic"
                                                        size="medium" fullWidth label="$/License" />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField value={this.state.contractLicenses != null ? this.state.contractLicenses.full : 0} onChange={this.changeNumber} name="contractLicensesFull"
                                                        size="medium" fullWidth label="Full" />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField value={this.state.licenseFees != null ? this.state.licenseFees.full : 0} onChange={this.changeNumber} name="licenseFeesFull"
                                                        size="medium" fullWidth label="$/License" />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField value={this.state.contractLicenses != null ? this.state.contractLicenses.advanced : 0} onChange={this.changeNumber} name="contractLicensesAdvanced"
                                                        size="medium" fullWidth label="Advanced" />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField value={this.state.licenseFees != null ? this.state.licenseFees.advanced : 0} onChange={this.changeNumber} name="licenseFeesAdvanced"
                                                        size="medium" fullWidth label="$/License" />
                                                    </Grid>
                                                </Grid> : 
                                                <Grid container item xs={12} spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField value={this.state.contractLicenses != null ? this.state.contractLicenses.single : 0} onChange={this.changeNumber} name="contractLicensesSingle"
                                                        size="medium" fullWidth label="Single"/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField value={this.state.licenseFees != null ? this.state.licenseFees.single : 0} onChange={this.changeNumber} name="licenseFeesSingle"
                                                        size="medium" fullWidth label="$/License"/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField value={this.state.contractLicenses != null ? this.state.contractLicenses.pack5 : 0} onChange={this.changeNumber} name="contractLicensesPack5"
                                                        size="medium" fullWidth label="5 Packs"/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField value={this.state.licenseFees != null ? this.state.licenseFees.pack5 : 0} onChange={this.changeNumber} name="licenseFeesPack5"
                                                        size="medium" fullWidth label="$/License"/>
                                                    </Grid>
                                                    {this.state.licenseType === auth.common.tenantTypes.standard && 
                                                        <Aux>
                                                            <Grid item xs={12} sm={6}>
                                                                <TextField value={this.state.contractLicenses != null ? this.state.contractLicenses.pack10 : 0}
                                                                size="medium" fullWidth label="10 Packs" onChange={this.changeNumber} name="contractLicensesPack10"/>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <TextField value={this.state.licenseFees != null ? this.state.licenseFees.pack10 : 0} onChange={this.changeNumber} name="licenseFeesPack10"
                                                                size="medium" fullWidth label="$/License" />
                                                            </Grid>
                                                        </Aux>
                                                    }
                                                    <Grid item xs={12} sm={6}>
                                                        <CheckboxField
                                                        checked={this.state.contractLicenses.neobi} onChange={this.changeNeobi}
                                                        label="Neobi" name="neobi"
                                                        />
                                                    </Grid>                                              
                                                </Grid>
                                            }
                                        </Panel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Panel>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <SectionTitle title="HelpDesk" />
                                                </Grid>
                                                {this.state.helpdeskStatus !== 'Exists' ?
                                                    <Grid item>
                                                        <Tooltip title="Register Helpdesk">
                                                            <IconButton onClick={() => this.registerHelpdesk()}>
                                                                <RegisterIcon sx={{ color: 'primary.main' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid> :
                                                    <Grid item>
                                                        <Tooltip title="Refresh Helpdesk">
                                                            <IconButton onClick={() => this.refreshHelpdesk()}>
                                                                <RefreshIcon sx={{ color: 'secondary.main' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                                <Grid item>
                                                    <FormControl>
                                                        <FormLabel>Helpdesk Status</FormLabel>
                                                        {this.state.helpdeskStatus != null ? this.state.helpdeskStatus : ''}
                                                    </FormControl>                                                 
                                                </Grid>
                                            </Grid>
                                        </Panel>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Panel>
                                        <SectionTitle title="Timers" />
                                        <Grid item xs={12}>
                                            <TextField value={this.state.sessionExpirationMinutes} onChange={this.changeNumber} name="sessionExpirationMinutes"
                                            size="medium" fullWidth label="Session Exp Min" 
                                            error={errors["Session Exp Min"] != null ? true : false} helperText={errors["Session Exp Min"]}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField value={this.state.sessionIdleMinutes} onChange={this.changeNumber} name="sessionIdleMinutes"
                                            size="medium" fullWidth label="Session Idle Min" 
                                            error={errors["Session Idle Min"] != null ? true : false} helperText={errors["Session Idle Min"]}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField value={this.state.sessionIdleWarningMinutes} onChange={this.changeNumber} name="sessionIdleWarningMinutes"
                                            size="medium" fullWidth label="Session Idle Warning Min" 
                                            error={errors["Session Warning Min"] != null ? true : false} helperText={errors["Session Warning Min"]}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField value={this.state.passwordExpirationMonths} onChange={this.changeNumber} name="passwordExpirationMonths"
                                            size="medium" fullWidth label="Password Exp Months" 
                                            error={errors["Session Exp Months"] != null ? true : false} helperText={errors["Session Exp Months"]}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField value={this.state.pinExpirationMonths} onChange={this.changeNumber} name="pinExpirationMonths"
                                            size="medium" fullWidth label="PIN Exp Months" 
                                            error={errors["PIN Exp Months"] != null ? true : false} helperText={errors["PIN Exp Months"]}/>
                                        </Grid>
                                    </Panel>
                                </Grid>
                            </Grid>
                        </TabCanvas>
                        <TabCanvas value={tabKey} index={1}>
                            {_id != null && _id !== '' && 
                                <TenantUserCountTab active={active} licenseType={licenseType} tenant={_id} />
                            }
                        </TabCanvas>
                        <TabCanvas value={tabKey} index={2}>
                            <Panel>
                                <Grid container spacing={3}>
                                    <Grid item container spacing={1} xs={12} md={6} direction="column" sx={{ justifyContent: "flex-start" }} >
                                        <Fieldset name="ERP Information">
                                            <Grid item>
                                                <SectionTitle title="ERP Information" />
                                            </Grid>
                                            <Grid item>
                                                <AutoCompleteField
                                                    value={this.state.erpSel}
                                                    options={this.state.erpTypes}
                                                    onChange={this.changeAuto('erpSel', 'erpType')}
                                                    error={errors["ERP"] != null ? true : false}
                                                    helperText={errors["ERP"]} 
                                                    label="ERP"
                                                    disabled={!security.update || !security.complete}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <AutoCompleteField
                                                    value={this.state.erpAuthTypeSel}
                                                    options={this.state.erpAuthTypes}
                                                    onChange={this.changeAuto('erpAuthTypeSel', 'erpAuthType')}
                                                    error={errors["Auth Type"] != null ? true : false}
                                                    helperText={errors["Auth Type"]} 
                                                    label="Auth Type"
                                                    disabled={!security.update || !security.complete}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <AutoCompleteField
                                                    value={this.state.messageTypeSel}
                                                    options={this.state.messageTypes}
                                                    onChange={this.changeAuto('messageTypeSel', 'messageType')}
                                                    error={errors["Message Type"] != null ? true : false}
                                                    helperText={errors["Message Type"]} 
                                                    label="Message Type"
                                                    disabled={!security.update || !security.complete}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField value={this.state.connectionPrefix} onChange={this.changeValue} name="connectionPrefix"
                                                size="medium" fullWidth={true} disabled={!security.update || !security.complete} label="Connection Prefix"
                                                error={errors["Connection Prefix"] != null ? true : false} helperText={errors["Connection Prefix"]}/>
                                            </Grid>
                                            <Grid item>
                                                <TextField value={this.state.environmentIdentifier} onChange={this.changeValue} name="environmentIdentifier"
                                                size="medium" fullWidth={true} disabled={!security.update || !security.complete} label="Environment Identifier"
                                                error={errors["Environment Identifier"] != null ? true : false} helperText={errors["Environment Identifier"]}/>
                                            </Grid>
                                            <Grid item>
                                                <TextField value={this.state.erpUserid} onChange={this.changeValue} name="erpUserid"
                                                size="medium" fullWidth={true} disabled={!security.update || !security.complete} label="ERP Userid"
                                                error={errors["ERP Userid"] != null ? true : false} helperText={errors["ERP Userid"]}/>
                                            </Grid>
                                            <Grid item>
                                                <TextField value={this.state.erpPassword} onChange={this.changeValue} name="erpPassword" type="password"
                                                size="medium" fullWidth={true} disabled={!security.update || !security.complete} label="ERP Password"
                                                error={errors["ERP Password"] != null ? true : false} helperText={errors["ERP Password"]}/>
                                            </Grid>
                                        </Fieldset>
                                    </Grid>
                                    <Grid item container spacing={1} xs={12} md={6}>
                                        <Fieldset name="ERP Endpoints">
                                            <Grid item xs={12}>
                                                <SectionTitle title="Endpoints" />
                                                {!this.state.editEnd &&
                                                    <Aux>
                                                        <br/>
                                                        <AddBlankButton addClick={this.toggleEndpoint} buttonName="Add Endpoint" enabled={security.update || security.complete} />
                                                        <br/>
                                                        <br/>
                                                    </Aux>
                                                }
                                            </Grid>
                                            <Grid item>
                                                {this.state.endpoints != null && this.state.endpoints.length > 0 &&
                                                    <TableContainer component={Paper}>
                                                        <Table aria-label="Endpoints">
                                                            <TableHead>
                                                                <TableRow key="endpointTableHeader">
                                                                    <TableCell>Name</TableCell>
                                                                    <TableCell>Value</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {this.state.endpoints.map((row, i) =>
                                                                    <TableRow key={row._id}>
                                                                        <TableCell>
                                                                            <TextOnlyClickButton enabled={true} click={this.editEndpoint(i)} 
                                                                                name={row.endpoint != null ? row.endpoint.label : ''}/>
                                                                        </TableCell>
                                                                        <TableCell>{row.value}</TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                }
                                            </Grid>
                                        </Fieldset>
                                    </Grid>
                                </Grid>
                                <Dialog open={this.state.editEnd} onClose={() => this.toggleEndpoint()} maxWidth="lg" fullWidth>
                                    <DialogTitle >
                                        <SectionTitle title="ERP Endpoint Details" />
                                    </DialogTitle>
                                    <DialogContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} sm={3}>
                                                <AutoCompleteField
                                                    value={this.state.endSel}
                                                    options={this.state.endpointTypes}
                                                    onChange={this.changeAuto('endSel', 'endpoint')}
                                                    error={errors["Location"] != null ? true : false}
                                                    helperText={errors["Location"]}
                                                    label="Location"
                                                    disabled={!security.update || !security.complete}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField value={this.state.endValue} onChange={this.changeValue} name="endValue"
                                                size="medium" fullWidth={true} disabled={!security.update || !security.complete} label="Value"
                                                error={errors["Value"] != null ? true : false} helperText={errors["Value"]}/>
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        <SaveButton saveClick={this.saveEndpoint} enabled={security.update || security.complete} />
                                        <ClearButton clearClick={this.clearEndpoint} enabled={true} />
                                        <DeleteButton deleteClick={this.deleteEndpoint} enabled={security.delete && this.state._id === ''} />
                                    </DialogActions>
                                </Dialog>
                            </Panel>
                        </TabCanvas>
                    </Grid>
                    {!editLicense && !editEnd &&
                        <Grid item xs={12}>
                            <SaveButton saveClick={this.save} enabled={enableSave && (security.update || security.complete)} />
                            <ReturnButton returnClick={this.returnClick} />
                            <Button variant="contained" color="secondary" size="small" onClick={this.clearCache} disabled={!enableSave}>Clear Cache</Button>
                        </Grid>
                    }
                    {_id != null && _id !== '' &&
                        <Aux>
                            <Comments entityId={_id} object={this.props.auth.constants.objectIds.tenant}/>
                            <ObjectLog id={_id}/>
                        </Aux>
                    }
                </Grid>
                <Dialog open={this.state.showCopy} onClose={this.hideCopy} maxWidth="md" fullWidth>
                    <DialogTitle>
                        <SectionTitle title="Copy Data"/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={10} sm={8}>
                                <AutoCompleteField
                                value={this.state.fromTenantSel}
                                options={this.state.tenants}
                                onChange={this.changeAuto('fromTenantSel', 'fromTenant')}
                                error={errors["From Tenant"] != null ? true : false}
                                helperText={errors["From Tenant"]}
                                label="From Tenant"
                                disabled={!security.update || !security.complete}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copyValues} onChange={this.changeBool('copyValues')}
                                label="Values" name="copyValues"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copyObjects} onChange={this.changeBool('copyObjects')}
                                label="Objects" name="copyObjects"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copyScreens} onChange={this.changeBool('copyScreens')}
                                label="Screens" name="copyScreens"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copySettings} onChange={this.changeBool('copySettings')}
                                label="Settings" name="copySettings"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copyInternalUsers} onChange={this.changeBool('copyInternalUsers')}
                                label="Internal Users" name="copyInternalUsers"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copyEmailTemplates} onChange={this.changeBool('copyEmailTemplates')}
                                label="Email Templates" name="copyEmailTemplates"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copyItemCategories} onChange={this.changeBool('copyItemCategories')}
                                label="Item Categories" name="copyItemCategories"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copyStrains} onChange={this.changeBool('copyStrains')}
                                label="Strains" name="copyStrains"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copyTestItemCategories} onChange={this.changeBool('copyTestItemCategories')}
                                label="Test Item Categories" name="copyTestItemCategories"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copyTestItems} onChange={this.changeBool('copyTestItems')}
                                label="Test Items" name="copyTestItems"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copyBaseData} onChange={this.changeBool('copyBaseData')}
                                label="Base Items" name="copyBaseData"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copyBatchActs} onChange={this.changeBool('copyBatchActs')}
                                label="Batch Act Defs" name="copyBatchActs"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copyItems} onChange={this.changeBool('copyItems')}
                                label="Items" name="copyItems"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copyTemplates} onChange={this.changeBool('copyTemplates')}
                                label="Batch Templates" name="copyTemplates"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copyDocs} onChange={this.changeBool('copyDocs')}
                                label="Documents" name="copyDocs"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                checked={this.state.copySops} onChange={this.changeBool('copySops')}
                                label="Work Records" name="copySops"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton saveClick={this.copyScreensClick} enabled={(security.update || security.complete) && enableSave} />
                        <ClearButton clearClick={this.hideCopy} enabled={true} />
                    </DialogActions>
                </Dialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Tenant));