import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Aux from '../../hoc/Auxilary/Auxilary';
import Title from '../UI/Typography/Title';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Panel from '../UI/Panel/Panel';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import DateDisplay from '../UI/DateDisplay/DateDisplay';

class TenantUserCount extends Component {
    constructor(props){
        super(props);
        this.state = {
            startDate: '',
            endDate: '',
            tenants: [],
            tenant: '',
            list: [],
            inputOpen: '',
            active: true
        };
    }

    async componentDidMount(){
        let values = await axios.get('/int/tenantcountvalues');
        this.setState({tenants: values.data.tenants}, () => {this.loadRecords()});
    }

    loadRecords = async () => {
        let data = {startDate: this.state.startDate, endDate: this.state.endDate, tenant: this.state.tenant, active: this.state.active};
        let list = await axios.get('/int/tenantusercount', {params: data});
        this.setState({list: list.data});
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    toggleDrawer = i => {
        if(this.state.inputOpen === i) {
            this.setState({ inputOpen: '' });
        }else {
            this.setState({ inputOpen: i})
        }
    }

    activeChanged = e => {
        this.setState({active: !this.state.active});
    }

    render(){
        const openDrawer = this.state.openDrawer;
        return(
            <Aux>
                <Title title="Tenant User Count"/>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.tenantSel}
                                options={this.state.tenants}
                                onChange={this.changeAuto('tenantSel', 'tenant')}
                                label="Tenant"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField
                            checked={this.state.active} onChange={this.activeChanged}
                            label="Active" name="active" disabled={false}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <Button variant="contained" size="small" color="primary" onClick={this.loadRecords}>Go</Button>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="tenants">
                            <TableHead>
                                <TableRow key="tenantHeader">
                                    <TableCell />
                                    <TableCell>Tenant</TableCell>
                                    <TableCell>Active Licenses</TableCell>
                                    <TableCell>Total Users</TableCell>
                                    <TableCell>Advanced</TableCell>
                                    <TableCell>Full</TableCell>
                                    <TableCell>Basic</TableCell>
                                    <TableCell>Active</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <Aux>
                                        <TableRow key={row.tenantId}>
                                            <TableCell>
                                                <IconButton aria-label="expand row" size="small" onClick={() => this.toggleDrawer(i)}>
                                                    {openDrawer ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>{row.tenantName}</TableCell>
                                            <TableCell>{row.count - row.inactive}</TableCell>
                                            <TableCell>{row.count}</TableCell>
                                            <TableCell>{row.advanced}</TableCell>
                                            <TableCell>{row.full}</TableCell>
                                            <TableCell>{row.basic}</TableCell>
                                            <TableCell>{row.active ? 'Yes' : 'No'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                                <Collapse in={this.state.inputOpen === i} timeout="auto" unmountOnExit>
                                                    <Box margin={1} component={'div'}>
                                                        <TableContainer component={Paper}>
                                                            <Table aria-label="tenantUsers">
                                                                <TableHead>
                                                                    <TableRow key={row.tenantId + 'users'}>
                                                                        <TableCell>Name</TableCell>
                                                                        <TableCell>Status</TableCell>
                                                                        <TableCell>Created On</TableCell>
                                                                        <TableCell>First Login</TableCell>
                                                                        <TableCell>Last Login</TableCell>
                                                                        <TableCell>Disabled On</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {row.users.map((user, k) =>
                                                                        <TableRow key={user._id}>
                                                                            <TableCell>{user.firstName + ' ' + user.lastName}</TableCell>
                                                                            <TableCell>{user.active ? user.type : 'Disabled'}</TableCell>
                                                                            <TableCell>
                                                                                {user.createdOn != null &&
                                                                                    <DateDisplay value={user.createdOn} format={this.props.auth.user.dateFormat}/>
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {user.firstLogin != null &&
                                                                                    <DateDisplay value={user.firstLogin} format={this.props.auth.user.dateFormat}/>
                                                                                }
                                                                            </TableCell> 
                                                                            <TableCell>
                                                                                {user.lastLogin != null &&
                                                                                    <DateDisplay value={user.lastLogin} format={this.props.auth.user.dateFormat}/>
                                                                                }
                                                                            </TableCell>    
                                                                            <TableCell>
                                                                                {user.disabledOn != null &&
                                                                                    <DateDisplay value={user.disabledOn} format={this.props.auth.user.dateFormat}/>
                                                                                }
                                                                            </TableCell>                                                                         
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>                                           
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </Aux>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(TenantUserCount);