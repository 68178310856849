import React from 'react';
import axios from 'axios';
import { pdfjs } from 'react-pdf';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

export default function DownloadButton(props) {
    const { disabled, record, t, type } = props;
    const documentClick = async e => {
        pdfjs.GlobalWorkerOptions.workerSrc = new URL(
            'pdfjs-dist/build/pdf.worker.min.mjs',
            import.meta.url,
          ).toString();
        let data = await axios.get('/api/document/' + record._id);
        let pdfData = data.data;

        let link = document.createElement("a");
        link.download = record.name;
        link.href = 'data:application/octet-stream;base64,' + pdfData;
        link.click();
    };

    if(type === 'icon') {
        return(
            <IconButton 
                color="primary" 
                size="small" 
                onClick={() => documentClick()}
                sx={{
                    color: 'grey.500',
                    '&:hover': {
                    color: 'primary.main',
                    },
                }}
            >
                <DownloadIcon />
            </IconButton>
        );    
    }else {
        return(
            <Button 
                disabled={disabled != null ? disabled : false}
                variant="text" 
                color="primary" 
                size="small" 
                onClick={() => documentClick()}>
                    {t('download')}
            </Button>
        ); 
    }
};