import axios from 'axios';
import { GET_ERRORS, SET_CURRENT_USER, SET_PROFILE, SET_MENU_OPTIONS, SET_CONSTANTS, SCREEN_LOCK, RESET_HELP,
         RESET_SECURITY, RESET_AUTH, RESET_NAVMENU, RESET_PROFILE, RESET_OPTIONMENU, BLUR_SCREEN, RESET_DEVICES } from './types';
import setAuthToken from '../setAuthToken';
import { jwtDecode } from 'jwt-decode';
import { DeviceHelper } from '../helpers/deviceHelper';
import common from '../jsons/common.json';
import languageHelper from '../helpers/languageHelper';
import { db } from '../db';

export const registerUser = (user, history) => dispatch => {
    axios.post('/users/register', user)
        .then(res => history.push('/login'))
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};

export const loginUser = (screens, screenDefs, token, json, from) => async dispatch => {
    try {
        sessionStorage.setItem('jwtToken', token);
        setAuthToken(token);
        const decoded = jwtDecode(token);
        sessionStorage.setItem('user', JSON.stringify(decoded));
        sessionStorage.setItem('userid', decoded.id);
        dispatch({type: SET_CONSTANTS, payload: {constants: json, screenDefs: screenDefs}});        
        try {
            let profile = await axios.get('/users/me/' + decoded.id);
            let locale = languageHelper.getBrowserLanguage();
            const csvSeparator = languageHelper.getLanguageCsvSeparator(locale);
            sessionStorage.setItem('csvSeparator', csvSeparator);
            sessionStorage.setItem('profile', JSON.stringify(profile.data));
            dispatch(setUserProfile(profile.data));
            dispatch(setCurrentUser(decoded, from));
            dispatch({type: SCREEN_LOCK, payload: {screenLock: false}});
            dispatch({type: BLUR_SCREEN, payload: {blurScreen: false}});
            dispatch({ type: SET_MENU_OPTIONS, payload: JSON.stringify(screens) });
            let printers = await DeviceHelper.getDevices();
            if(printers.hasDevices) {
                dispatch({ type: 'SET_ZEBRA_PRINTERS', payload: {printers}});
            }
            dispatch({ type: 'HAS_DEVICES', payload: printers.hasRegisteredDevices });
        }catch(err){
            dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: 'Error', severity: 'error'}});
        };
    } catch(err){
        dispatch({
            type: GET_ERRORS,
            payload: err.response != null && err.response.data != null ? err.response.data : ''
        });
    }
};

export const loginAzureUser = (msal, accessToken, userid, tenant, from, authType) => async dispatch => {
    sessionStorage.setItem('msal', msal);
    sessionStorage.setItem('azureToken', accessToken);
    let locale = languageHelper.getBrowserLanguage();
    const csvSeparator = languageHelper.getLanguageCsvSeparator(locale);
    sessionStorage.setItem('csvSeparator', csvSeparator);
    setAuthToken('Bearer ' + accessToken);
    try {
        let result = null;
        if(authType === common.auth.azure){
            result = await axios.post('/users/userdetails', {userid: userid, tenant});
        } else {
            result = await axios.post('/users/googleLogin', {userid: userid, tenant});
        }
        if(result.data.token != null){
            sessionStorage.setItem('jwtToken', result.data.token);
            setAuthToken(result.data.token);
            const decoded = jwtDecode(result.data.token);
            let profile = await axios.get('/users/me/' + decoded.id);
            sessionStorage.setItem('profile', JSON.stringify(profile.data));
            sessionStorage.setItem('user', JSON.stringify(decoded));
            sessionStorage.setItem('userid', decoded.id);
            dispatch(setUserProfile(profile.data));
            dispatch({type: SET_CONSTANTS, payload: {constants: result.data.json, screenDefs: result.data.screenDefinitions}});
            dispatch({type: SCREEN_LOCK, payload: {screenLock: false}});
            dispatch({type: BLUR_SCREEN, payload: {blurScreen: false}});
            dispatch(setCurrentUser(decoded, from));
            dispatch({ type: SET_MENU_OPTIONS, payload: JSON.stringify(result.data.screens) });
            let printers = await DeviceHelper.getDevices();
            if(printers.hasDevices) {
                dispatch({ type: 'SET_ZEBRA_PRINTERS', payload: {printers}});
            }
            dispatch({ type: 'HAS_DEVICES', payload: printers.hasRegisteredDevices });
        } else {
            dispatch({ type: 'CREATE_ALERT', payload: {message: result, title: 'Error', severity: 'error'}});
        }
    } catch(err){
        dispatch({ type: 'CREATE_ALERT', payload: {message: err, title: 'Error', severity: 'error'}});
    }
};

export const setCurrentUser = (decoded, referralLink) => {
    return{
        type: SET_CURRENT_USER,
        payload: {user: decoded, referralLink: referralLink}
    }
};

export const setUserProfile = profile => {
    return {
        type: SET_PROFILE,
        payload: profile
    }
};

export const logoutUser = (history) => dispatch => {
    sessionStorage.clear();
    localStorage.clear();
    db.cache.clear();
    setAuthToken(false);
    dispatch(setCurrentUser({}));
    dispatch(setUserProfile({}));
    dispatch({ type: RESET_DEVICES, payload: null });
    dispatch({ type: RESET_OPTIONMENU, payload: null });
    dispatch({ type: RESET_SECURITY, payload: null });
    dispatch({ type: RESET_AUTH, payload: null });
    dispatch({ type: RESET_NAVMENU, payload: null });
    dispatch({ type: RESET_PROFILE, payload: null });
    dispatch({ type: RESET_HELP, payload: null });
};