import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import { JsonViewer } from '@textea/json-viewer';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import ModalDialog from '../UI/Dialog/ModalDialog';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

class ObjectLogs extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            openLogDrawer: null
        }
    }

    async componentDidMount(){
        this.loadRecords();
    }

    async componentDidUpdate(prevProps, prevState){
        if (prevProps.renderNum !== this.props.renderNum && this.props.id != null && this.props.id !== '') {
            this.loadRecords();
          }
    }

    async loadRecords(){
        var sort = {[this.state.orderBy]: this.state.ordDir};
        var data = {
            size: this.state.listSize,
            start: this.state.offset,
            sort: JSON.stringify(sort)
        }
        var list = await axios.get("/api/logs/" + this.props.id, {params: data});
        this.setState({
            list: list.data.data,
            pageCount: Math.ceil(list.data.totalCount / this.state.listSize),
            totalCount: list.data.totalCount
        })
    }

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };


    toggleOpen = (i) => {
        if(this.state.openLogDrawer === i)
            this.setState({openLogDrawer: null});
        else
            this.setState({openLogDrawer: i});
    }

    render(){
        const { openLogs, t } = this.props;
        return(
            <ModalDialog buttonStack={[]} title={t('logs')} toggleDialog={() => this.props.dispatch({ type: 'TOGGLE_LOGS' })}
                            dialogStatus={openLogs} fullWidth maxWidth='lg'>
                <Grid container spacing={3} justifyContent='flex-end'>
                    <Grid item xs={12}>
                        <Table aria-label="Logs" size="small">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell />
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('date')}
                                        active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('transactionDate')}
                                        active={this.state.orderBy === 'transactionDate'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('effectiveDate')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('user')}
                                        active={this.state.orderBy === 'user'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('user')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('action')}
                                        active={this.state.orderBy === 'action'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('action')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('changes')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <Aux key={'logRows-' + i}>
                                        <TableRow key={'logRow-' + row._id}>
                                            <TableCell  sx={{ width: '20px' }}>
                                                <IconButton aria-label="expand row" size="small" onClick={() => this.toggleOpen(i)} >
                                                    {this.state.openLogDrawer === i ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                <DateDisplay format={this.props.auth.user.dateFormat} value={row.date}/>
                                            </TableCell>
                                            <TableCell>
                                                {row.transactionDate != null &&
                                                    <DateDisplay format={this.props.auth.user.dateFormat} value={row.transactionDate}/>
                                                }
                                            </TableCell>
                                            <TableCell>{row.user != null ? row.user.firstName + ' ' + row.user.lastName : ''}</TableCell>
                                            <TableCell>{row.action != null ? row.action.label : ''}</TableCell>
                                            <TableCell>{row.changeCount != null ? row.changeCount : 0}</TableCell>
                                        </TableRow>
                                        {this.state.openLogDrawer === i &&
                                            <TableRow key={'logRowDetails-' + row._id}>
                                                <TableCell colSpan={6}>
                                                    {row.diff != null &&
                                                        <JsonViewer value={JSON.parse(JSON.stringify(row.diff))}/>
                                                    }
                                                    {row.diff == null && row.reason != null &&
                                                        <span>{row.reason.label + ' - ' + row.comment}</span>
                                                    }
                                                    {row.diff == null && row.reason == null &&
                                                        <span>{row.comment}</span>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </Aux>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Grid>
                </Grid>
            </ModalDialog>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openLogs: state.optionMenu.openLogs
});

export default withTranslation() (connect(mapStateToProps)(ObjectLogs));
