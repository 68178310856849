import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import { CSVDownload } from "react-csv";
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import IconButton from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { NumericFormat } from 'react-number-format';

class ItemTransactions extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            item: this.props.item != null && this.props.item !== '' ? this.props.item : null,
            orderBy: 'postingDate',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            csvData: null,
            headers: null
        }
    }

    async componentDidMount(){
        const csvSeparator = sessionStorage.getItem('csvSeparator');
        this.setState({separator: csvSeparator != null ? csvSeparator : ','})
        this.loadRecords();
    }

    loadRecords = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        if(this.state.item != null && this.state.item !== ''){
            let params = {
                sort: JSON.stringify(sort),
                offset: this.state.offset,
                size: this.state.listSize,
                item: this.state.item
            };
            let list = await axios.get('/api/itemledgers', {params: params});
            this.setState({
                list: list.data.data,
                totalCount: list.data.totalCount
            });
        }
    }

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    csvClick = async (e) => {
        this.setState({csvData: null});
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let params = {
            sort: JSON.stringify(sort),
            item: this.state.item
        };
        let list = await axios.get('/api/itemledgerexport', {params: params});
        let headers = list.data.headers;
        let csvData = list.data.data;
        this.setState({csvData: csvData, headers: headers});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <TableContainer>
                <Table aria-label="Transactions">
                    <TableHead>
                        <TableRow key="transHeader">
                            <TableCell>
                                <TableSortLabel onClick={() => this.sortClick('number')}
                                active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel onClick={() => this.sortClick('postingDate')}
                                active={this.state.orderBy === 'postingDate'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel onClick={() => this.sortClick('quantity')}
                                active={this.state.orderBy === 'quantity'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('quantity')}
                                </TableSortLabel>
                            </TableCell>
                            {this.props.hasCount &&
                                <TableCell>{t('count')}</TableCell>
                            }
                            <TableCell>
                                <TableSortLabel onClick={() => this.sortClick('entryType')}
                                active={this.state.orderBy === 'entryType'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('type')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>{t('lot')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.list.map((row, i) => 
                            <TableRow key={row._id}>
                                <TableCell><Link to={'/itemledger/' + row._id}>{row.number}</Link></TableCell>
                                <TableCell><DateDisplay value={row.postingDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                <TableCell>
                                <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}
                                                    decimalScale={3} suffix={row.quantityUOM.label}/>
                                </TableCell>
                                {this.props.hasCount &&
                                    <TableCell>{row.count != null ? row.count : ''}</TableCell>
                                }
                                <TableCell>{row.entryType}</TableCell>
                                <TableCell>
                                    {row.lot != null && row.container != null ? row.lot.number + '/' + row.container.number : 
                                        row.lot != null ? row.lot.number : ''}
                                </TableCell>
                            </TableRow>
                        )} 
                    </TableBody>
                    <TableFooter>
                        {this.state.totalCount > 0 &&
                            <TableRow key="tableFooter">
                                <Tooltip title={t('exportToCsv')} >
                                    <IconButton
                                        disabled={false}
                                        component={'div'}
                                        onClick={this.csvClick}
                                        name='actionCsv'
                                    >
                                        <ExcelIcon fontSize='medium'/>
                                    </IconButton>
                                </Tooltip>
                                {this.state.csvData != null &&
                                    <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.headers} separator={this.state.separator}/>
                                }
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        }
                    </TableFooter>
                </Table>
            </TableContainer>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(ItemTransactions));