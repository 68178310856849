const colorHelper = {
    getRandomColor(){
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },

    colorList(){
        let colors = [
            "#6E63F9",
            "#A4C97E",
            "#658C9B",
            "#678FD4",
            "#965396",
            "#FAF0C1",
            "#82ca9d",
            "#8884d8",
            "#568640",
            "#5A4C35",
            "#89748C",
            "#6EB8F0",
            "#89748C",
            "#34EDBD",
            "#686AC4",
            "#117971",
            "#512C40",
            "#C3C808",
            "#6E63F9",
            "#220DE7",
            "#802A69",
            "#CA7794",
            "#A81074",
            "#7B37AF",
            "#D3C43C",
            "#1A6FBD",
            "#1FAEF0",
            "#D29B91",
            "#5D2B44",
            "#1B921F",
            "#9C375E",
            "#9F2695",
            "#484FEE",
            "#77C5EC",
            "#A4C97E",
            "#084B02",
            "#E4470E",
            "#678FD4",
            "#CBEE8A",
            "#658C9B",
            "#CE712A",
            "#5891D0",
            "#974A14",
            "#6EFC80",
            "#D9F61D",
            "#B46B1E",
            "#6285E2",
            "#CA2238",
            "#F4B259",
            "#F7A713",
            '#28C9FA',
            "#C81693",
            "#4DAF71",
            "#531C3A",
            "#4360EB",
            "#A6BE6F",
            "#E25D10",
            "#4CBD16",
            "#CF6F83",
            "#A5F16D",
            "#79F8A8",
            "#65A131",
            "#8CCAD7",
            "#4DDD9A",
            "#1C2C84",
            "#700312",
            "#E08E01",
            "#0DA712",
            "#8BA75F",
            "#4B35A1",
            "#7C7766",
            "#A6A869",
            "#98916C",
            "#5F74A8",
            "#7F29A3",
            "#8F2E31",
            "#7918E2",
            '#72480F',
            "#EC7533",
            "#E06263",
            "#EC7CD1",
            "#3A38EE",
            "#DE3F1C",
            "#A8DEA2",
            "#4F9B71",
            "#000963",
            "#FAF0C1",
            "#AC6571",
            "#44E127",
            "#960610",
            "#836E88",
            "#85F12A",
            "#DEA744",
            "#700ACE",
            "#783B42",
            "#9E8DF4",
            "#CCD7AE",
            "#739937",
            "#017FB6",
            "#2B89E1",
            "#8EAAEA",
            "#1FC086",
            "#C88D7D",
            "#708ECE",
            "#B28CA5",
            "#251AC2",
            "#8B6648",
            "#787737",
            "#4FCED4",
            "#017FB6"
        ];
        return colors;
    }
}

export default colorHelper;
