import common from '../jsons/common.json';

const ccxHelper = {
    deriveValues(type, locale){
        let name = type + '_' + locale;
        return common[name];
    },

    growMethod(roomType, constants){
        if(roomType === constants.growRoomTypes.indoor)
            return common.ccxGrowMethods.indoor;
        else if(roomType === constants.growRoomTypes.greenhouse)
            return common.ccxGrowMethods.greenhouse;
        else if(roomType === constants.growRoomTypes.outdoor)
            return common.ccxGrowMethods.outdoor;
        else if(roomType === constants.growRoomTypes.hybridGreenhouse)
            return common.ccxGrowMethods.hybridGreenhouse
        else return null;
    },

    growMethod1(roomType, constants){
        if(roomType === constants.growRoomTypes.indoor)
            return common.ccxGrowMethod1s.indoor;
        else if(roomType === constants.growRoomTypes.greenhouse)
            return common.ccxGrowMethod1s.greenhouse;
        else if(roomType === constants.growRoomTypes.outdoor)
            return common.ccxGrowMethod1s.outdoor;
        else if(roomType === constants.growRoomTypes.hybridGreenhouse)
            return common.ccxGrowMethod1s.hybridGreenhouse
        else return null;
    }
}

export default ccxHelper;