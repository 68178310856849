import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import { withTranslation } from 'react-i18next';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ActionBar from '../UI/Buttons/ActionBar';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import CheckboxField from '../UI/CheckboxField/CheckboxField';

class TenantReportList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            totalCount: 0,
            page: 0,
            security: {},
            description: '',
            orderBy: 'description',
            ordDir: 1,
            headers: [],
            header: '',
            headerSel: null,
            global: true
        };
    }
    async componentDidMount(){
        let storage = sessionStorage.getItem('tenantReport');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        let values = await axios.get('/api/tenantreportvalues');
        this.setState({
            headers: values.data.headers,
            reports: values.data.reports,
            security: this.props.permission,
            offset: storage != null ? storage.offset : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'description',
            ordDir: storage != null ? storage.ordDir : 1,
            description: storage != null ? storage.description : '',
            header: storage != null ? storage.header : '',
            headerSel: storage != null ? storage.headerSel : null,
            global: storage != null ? storage.global : true
        }, () => {
            this.loadRecords();
        });
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            description: this.state.description,
            header: this.state.header,
            headerSel: this.state.headerSel,
            global: this.state.global
        };
        sessionStorage.setItem('tenantReport', JSON.stringify(data));
    }

    loadRecords = async () => {
        const sort = {[this.state.orderBy]: this.state.ordDir};
        const data = {
            offset: this.state.offset,
            size: this.state.listSize,
            description: this.state.description,
            sort: JSON.stringify(sort),
            header: this.state.header,
            global: this.state.global
        };
        const list = await axios.get('/api/tenantreports', {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('reportSettings'), screen: this.props.auth.screenDefs.TenantReportList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
        this.saveFilters();
    };

    changeBool = (name) => {
        this.setState({[name]: !this.state[name], page: 0, offset: 0}, () => {this.loadRecords();});
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords();});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        // const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.description} onChange={this.changeValue} name="description"
                            size="medium" fullWidth={true} label={t('description')} data-cy='description'/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.headerSel}
                                options={this.state.headers}
                                onChange={this.changeAuto('headerSel', 'header')}
                                label={t('header')}
                                data-cy='header'
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField
                                checked={this.state.global} onChange={() => this.changeBool('global')}
                                label={t('global')} name="global" data-cy='global'
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="tenantreports">
                            <TableHead>
                                <TableRow key="tenantreportheader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('description')}
                                            active={this.state.orderBy === 'description'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('name')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('global')}</TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('report')}
                                            active={this.state.orderBy === 'report'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('report')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('header')}
                                            active={this.state.orderBy === 'header'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('header')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('watermark')}
                                            active={this.state.orderBy === 'watermark'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('waterMark')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('paperSize')}</TableCell>
                                    <TableCell>{t('documentExpires')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/tenantreport/' + row._id} data-cy={'report' + i}>{row.description}</Link></TableCell>
                                        <TableCell>{row.global ? t('yes') : t('no')}</TableCell>
                                        <TableCell>{row.report != null && row.report !== '' && row.report.label != null ? row.report.label : t('notSelected')}</TableCell>
                                        <TableCell>{row.header != null ? row.header.name : ''}</TableCell>
                                        <TableCell>{row.watermark}</TableCell>
                                        <TableCell>{row.report != null && row.report !== '' && row.report.paperSize != null ? row.report.paperSize : t('notSelected')}</TableCell>
                                        <TableCell>{row.useExpiration ? t('yes') : t('no')}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(TenantReportList));
