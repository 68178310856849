import { getMinutes, addMinutes, addDays, addHours, getHours, setHours, setMinutes, setSeconds, setMilliseconds,
    isSaturday, isSunday, differenceInCalendarDays } from 'date-fns';
import common from '../jsons/common.json';
import dayjs from 'dayjs';

const conversionHelper = {

    validActivityDate(date, lockDate, isSuper){
        if(isSuper){
            return true;
        }
        if(date == null){
            return false;
        }
        if(lockDate == null){
            return true;
        }
        let activityDate = Date.parse(date);
        let locked = Date.parse(lockDate);
        if(activityDate < locked){
            return false;
        }
        return true;
    },

    maxDate(date){
        let dateObj = new Date(Date.parse(date));
        dateObj = setHours(dateObj, 23);
        dateObj = setMinutes(dateObj, 59);
        dateObj = setSeconds(dateObj, 59);
        dateObj = setMilliseconds(dateObj, 999);
        return dateObj;
    },

    minDate(date){
        let dateObj = new Date(Date.parse(date));
        dateObj = setHours(dateObj, 0);
        dateObj = setMinutes(dateObj, 0);
        dateObj = setSeconds(dateObj, 0);
        dateObj = setMilliseconds(dateObj, 0);
        return dateObj;
    },

    dateSet(date, days){
        let dateObj = new Date(Date.parse(date));
        dateObj.setDate(dateObj.getDate() - days);
        return dateObj;
    },

    calculateDayString(totalTime, days){
        var daysTotal = Math.floor(totalTime / (60 * 8));
        var minRemainder = totalTime % (60 * 8);
        var hours = Math.floor(minRemainder / 60);
        minRemainder = minRemainder % 60;
        var weeks = Math.floor(daysTotal / 5);
        var daysRemainder = daysTotal % 5;
        if(days != null && days !== 0){
            var growWeeks = Math.floor(days / 7);
            weeks = weeks + growWeeks;
            var growDaysLeft = days % 7;
            daysRemainder = daysRemainder + growDaysLeft;
        }
        if(daysRemainder > 5){
            weeks = weeks++;
            daysRemainder = daysRemainder - 5;
        }
        let dayString = '';
        if(weeks > 0){
            dayString = weeks + ' weeks,  ' + daysRemainder + ' days, ' + hours + ' hours, ' + minRemainder + ' minutes';
        } else if (daysRemainder > 0){
            dayString = daysRemainder + ' days, ' + hours + ' hours, ' + minRemainder + ' minutes'
        } else if (hours > 0){
            dayString = hours + ' hours, ' + minRemainder + ' minutes'
        } else {
            dayString = minRemainder + ' minutes'
        }
        return dayString;
    },

    calculateWorkDate(startDate, timeMinutes){
        let sDay = Date.parse(startDate);
        let workDay = 8 * 60;
        let daysTotal = Math.floor(timeMinutes / workDay);
        let minRemaining = timeMinutes % workDay;
        let hours = Math.floor(minRemaining / 60);
        let minutes = minRemaining % 60;
        if(daysTotal > 0){
            sDay = addDays(sDay, daysTotal);
        }
        if(hours > 0){
            let currHour = getHours(sDay);
            if(currHour + hours > 16){
                let remainingHours = (currHour + hours) - 16;
                sDay = addDays(sDay, 1);
                sDay = setHours(sDay, 8 + remainingHours);
            } else {
                sDay = addHours(sDay, hours);
            }
        }
        if(minutes > 0){
            let currMinutes = getMinutes(sDay);
            let remainingMinutes = null;
            if(currMinutes + minutes > 60){
                if(getHours(sDay) + 1 > 16){
                    sDay = addDays(sDay, 1);
                    sDay = setHours(sDay, 8);
                    remainingMinutes = (currMinutes + minutes) - 60;
                    sDay = setMinutes(sDay, remainingMinutes);
                } else {
                    sDay = addHours(sDay, 1);
                    remainingMinutes = (currMinutes + minutes) - 60;
                    sDay = setMinutes(sDay, remainingMinutes);
                }
            } else {
                sDay = addMinutes(sDay, minutes);
            }
        }
        if(isSaturday(sDay) || isSunday(sDay)){
            sDay = addDays(sDay, 2);
        }
        return sDay;
    },

    convertToMinutes(amount, uom, constants){
        if(amount == null || uom == null){
            return 0;
        }
        switch(uom){
            case constants.timeUOMs.min:
                return amount;
            case constants.timeUOMs.hour:
                return amount * 60;
            case constants.timeUOMs.second:
                return amount / 60;
            case constants.timeUOMs.day:
                return amount * (8 * 60);
            default:
                return 0;
        }
    },

    convertToMilliseconds(amount, uom, constants) {
        if(amount == null || uom == null) {
            return 0;
        }
        switch(uom){
            case constants.timeUOMs.m:
                return amount * 60000;
            case constants.timeUOMs.h:
                return amount * 36000000;
            case constants.timeUOMs.s:
                return amount * 1000;
            case constants.timeUOMs.day:
                return amount * 86400000;
            default:
                return 0;
        }
    },

    calculateDaysBetweenDates(date1, date2){
        const day1 = new Date(date1).getTime();
        const day2 = new Date(date2).getTime();
        return Math.abs((day1 - day2) / (1000 * 3600 * 24));
    },

    dateDiffDays(endDate, startDate){
        let start = Date.parse(startDate);
        let end = Date.parse(endDate);
        let dayDiff = differenceInCalendarDays(end, start);
        return dayDiff;
    },

    addDateDays(date, days){
        let newDate = new Date(date);
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    },

    isExciseStamp(item, constants){
        if(item === constants.items.abExcise || item === constants.items.bcExcise || item === constants.items.mbExcise || item === constants.items.nbExcise ||
            item === constants.items.nlExcise || item === constants.items.nsExcise || item === constants.items.ntExcise || item === constants.items.nuExcise ||
            item === constants.items.onExcise || item === constants.items.peExcise || item === constants.items.qcExcise || item === constants.items.skExcise ||
            item === constants.items.ytExcise){
            return true;
        }
        return false;
    },

    itemCost(item, quantity){
        let cost = null;
        if(item.cost != null){
            if(item.purchaseUOM == null){
                cost = item.cost * quantity;
            } else {
                if(item.eachQuantity != null){
                    let unitCost = item.cost / item.eachQuantity;
                    cost = unitCost * quantity;
                }
            }
        }
        return cost;
    },

    determineConversion(startUOM, targetUOM, uoms){
        let conversionRate = 1;
        if(startUOM === targetUOM){
            return conversionRate;
        }
        if(targetUOM === uoms.l){
            if(startUOM === uoms.ml){
                return 1000;
            } else {
                return null;
            }
        }
        if(targetUOM === uoms.ml){
            if(startUOM === uoms.l){
                return 0.001
            } else {
                return null;
            }
        }
        if(targetUOM === uoms.kg){
            switch(startUOM){
                case uoms.mg:
                    return 0.000001;
                case uoms.g:
                    return 0.001;
                default:
                    return null;
            }
        }
        if(targetUOM === uoms.g){
            switch(startUOM){
                case uoms.mg:
                    return 0.001;
                case uoms.kg:
                    return 1000;
                default:
                    return null;
            }
        }
        if(targetUOM === uoms.mg){
            switch(startUOM){
                case uoms.g:
                    return 1000;
                case uoms.kg:
                    return 1000000;
                default:
                    return null;
            }
        }
    },

    round2(value){
        if(isNaN(value))
            return null;
        else {
            let newValue = Math.round(value * 100);
            return (newValue / 100);
        }
    },

    round3(value){
        if(isNaN(value))
            return null;
        else {
            let newValue = Math.round(value * 1000);
            return (newValue / 1000);
        }
    },

    round4(value){
        if(isNaN(value))
            return null;
        else {
            let newValue = Math.round(value * 10000);
            return (newValue / 10000);
        }
    },

    isPast(date){
        let today = new Date();
        let dateToCheck = new Date(date);
        if(dateToCheck < today)
            return true;
        else
            return false;
    },

    convertWeight(amount, current, target, constants){
        if(amount == null || amount === 0)
            return amount;
        if(target === constants.quantityUOMs.g){
            if(current === constants.quantityUOMs.kg){
                return  amount * 1000;
            } else if (current === constants.quantityUOMs.mg){
                return amount / 1000
            }
        } else if (target === constants.quantityUOMs.kg){
            if(current === constants.quantityUOMs.g){
                return amount / 1000;
            } else if(current === constants.quantityUOMs.mg){
                return amount / 1000000;
            }
        } else if (target === constants.quantityUOMs.mg){
            if(current === constants.quantityUOMs.g){
                return amount * 1000;
            } else if(current === constants.quantityUOMs.kg){
                return amount * 1000000;
            }
        }
        return amount;
    },

    numberToLetterConverter(number) {
        if (number < 1) {
            throw new Error('Number must be greater than 0');
        }
        let result = '';
        while (number > 0) {
            let mod = (number - 1) % 26;
            result = String.fromCharCode(97 + mod) + result;
            number = Math.floor((number - mod) / 26);
        }
        return result;
    },

    numberToRomanConverter(number) {
        if (number < 1 || number > 3999) {
            throw new Error('Number must be between 1 and 3999');
        }
        const numerals = [
            { value: 1000, symbol: 'm' },
            { value: 900, symbol: 'cm' },
            { value: 500, symbol: 'd' },
            { value: 400, symbol: 'cd' },
            { value: 100, symbol: 'c' },
            { value: 90, symbol: 'xc' },
            { value: 50, symbol: 'l' },
            { value: 40, symbol: 'xl' },
            { value: 10, symbol: 'x' },
            { value: 9, symbol: 'ix' },
            { value: 5, symbol: 'v' },
            { value: 4, symbol: 'iv' },
            { value: 1, symbol: 'i' },
        ];
        let result = '';
        for (let i = 0; i < numerals.length; i++) {
            while (number >= numerals[i].value) {
                result += numerals[i].symbol;
                number -= numerals[i].value;
            }
        }
        return result;
    },

    carObjects(objects, constants){
        let list = [];
        for(let row of common.carObjects){
            let val = objects.find(x => x.value === constants.objectIds[row]);
            if(val != null){
                list.push(val);
            }
        }
        list = list.sort((a, b) => a.label.localeCompare(b.label));
        return list;
    },

    filtersDateParse(filters){
        if(filters == null || filters.length === 0){
            return filters;
        }
        for(let row of filters){
            if(row.id === 'date' || row.id === 'startDate' || row.id === 'endDate' || row.id === 'dueDate' || row.id === 'plannedStart' || row.id === 'postingDate' || 
            row.id === 'lastUpdated' || row.id === 'actualStart' || row.id === 'requestDate' || row.id === 'expectedDelivery' || row.id === 'remittanceDate' ||
            row.id === 'created' || row.id === 'harvestDate' || row.id === 'packagedOn' || row.id === 'expires' || row.id === 'expectedHarvest'){
                if(row.value != null && row.value !== ''){
                    if(row.value[0] != null || row.value[1] != null){
                        if(row.value[0] != null && row.value[0] !== ''){
                            let start = dayjs(row.value[0]);
                            row.value[0] = start;
                        }
                        if(row.value[1] != null && row.value[1] !== ''){
                            let end = dayjs(row.value[1]);
                            row.value[1] = end;
                        }
                    } else {
                        row.value = dayjs(row.value);
                    }
                }
            } 
        }
        return filters;
    }
}

export default conversionHelper;
