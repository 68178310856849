import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from '../../hoc/Auxilary/Auxilary';
import axios from 'axios';
import GeneratePdf from '../General/GeneratePdf';
import ReactDOMServer from 'react-dom/server';
import Panel from '../UI/Panel/Panel';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import QRCode from '../UI/QRCode/QRCode';
import { NumericFormat } from 'react-number-format';

function Header(props){
    const { record, t, dateFormat, printView } = props;
    if(record != null) {
        return (
            <div style={{ width: '100%', margin: printView ? '0 30px' : null }}>
                <QRCode mono={true} size='sm' value={record.qrPath} />
                <table style={{ width: '91%', float: 'right' }}>
                    <tbody>
                        <tr>
                            <td>
                                <span style={{ fontSize: '10pt'}}><strong>{t('destruction')}: {record.number}</strong></span><br />
                                <span style={{ fontSize: '9pt' }}><strong>{t('date')}:&nbsp;</strong>
                                    {record.date != null ? <DateDisplay value={record.date} format={dateFormat}/> : ''}
                                </span>
                            </td>
                            <td style={{ textAlign: 'right'}}>
                                <span style={{ fontSize: '9pt' }}><strong>{t('location')}:&nbsp;</strong>
                                    {record.location != null ? record.location.name : ''}
                                </span><br />
                                <span style={{ fontSize: '9pt' }}><strong>{t('method')}:&nbsp;</strong>
                                    {record.method != null ? record.method : ''}
                                </span><br />
                            </td>
                        </tr>
                    </tbody>
                </table>             
            </div>
        )
    }else {
        return null;
    }
}

function Footer(props){
    const { record, t, dateFormat, printView } = props;
    if(record != null) {
        return (
            <div style={{ display: 'block', width: '100%', margin: printView ? '0 30px' : null }}>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td width='20%' height='150px' style={{ verticalAlign: 'top' }} >
                                <span style={{ fontSize: '9pt' }}><strong>{t('performedBy')}</strong></span><br />
                                <span style={{ fontSize: '8pt' }}>{record != null && record.users != null ? record.users : ''}</span><br />
                            </td>
                            <td width='40%' height='150px' style={{ verticalAlign: 'top' }}>
                                <span style={{ fontSize: '9pt' }}><strong>{t('witnesses')}</strong></span><br /> 
                                <div style={{ width: '50%', display: 'inline-block' }}>
                                    <div style={{ width: '100%' }}>
                                        {record.user != null && <img src={record.user.signature} alt={t('noSignature')} height='60px' />}
                                        <hr style={{ width: '80%', marginLeft: 0 }} />
                                    </div>
                                    <div style={{ width: '100%', fontSize: '9pt' }}>
                                        {record.user != null ? record.user.user.firstName + ' ' + record.user.user.lastName : ''}, &nbsp;
                                        {record.user != null && <DateDisplay format={dateFormat} value={record.user.date}/>}
                                    </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block' }}>
                                    <div style={{ width: '100%' }}>
                                        {record.witness != null && <img src={record.witness.signature} alt={t('noSignature')} height='60px' />}
                                        <hr style={{ width: '80%', marginLeft: 0 }} />
                                    </div>
                                    <div style={{ width: '100%', fontSize: '9pt' }}>
                                        {record.witness != null ? record.witness.user.firstName + ' ' + record.witness.user.lastName : ''}, &nbsp;
                                        {record.witness != null && <DateDisplay format={dateFormat} value={record.witness.date}/>}
                                    </div>
                                </div>
                                <div style={{ marginTop: '10px', display: 'inline-block' }}>
                                    <span style={{ fontSize: '8pt', marginTop: '8px' }}>{t('signDestructionWitness')}</span>
                                </div>
                                {printView &&
                                    <div style={{ marginTop: '10px', textAlign: 'center' }}>
                                        <div style={{ fontSize: '8pt' }}>{t('page')} <span class="pageNumber" /> {t('of')} <span class="totalPages" /></div>
                                    </div>
                                }                                
                            </td>
                            <td width='20%' height='150px' style={{ verticalAlign: 'top', textAlign: 'right' }}>
                                <span style={{ fontSize: '9pt' }}><strong>{t('signed')}</strong></span><br />
                                <div style={{ width: '100%' }}>
                                    {record.rpic != null && <img src={record.rpic.signature} alt={t('noSignature')} height='60px' />}
                                    <hr style={{ width: '80%', marginRight: 0 }} />
                                </div>
                                <div style={{ width: '100%', fontSize: '9pt' }}>
                                    {record.rpic != null ? record.rpic.user.firstName + ' ' + record.rpic.user.lastName : ''}, &nbsp;
                                    {record.rpic != null && <DateDisplay format={dateFormat} value={record.rpic.date}/>}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    } else {
        return null;
    }
    
}

function ComponentToPrint(props){
    const { cultivator, record, t, dateFormat, printView } = props;
    return(
        <div style={{ width: '11in'}}>
            {record != null && record.number != null &&
                <Aux>
                    {!printView &&
                        <Header {...props} />
                    }
                    <br/>
                    <span style={{ fontSize: '9pt' }}><strong>{t('summary')}</strong></span><br />
                    <table style={{ borderCollapse: 'collapse' }} >
                        <thead>
                            <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '700px', textAlign: 'center' }}><strong>{t('item')}</strong></td>
                            <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('containers')}</strong></td>
                            <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('total')}</strong></td>
                            {cultivator &&
                                <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('plants')}</strong></td>
                            }                                    
                        </thead>
                        <tbody>
                            {record.itemTotals.map((row, i) => 
                                <tr key={`itemTotals-${i}`}>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{row.item.number + ' - ' + row.item.name}</td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>{row.containerCount}</td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                        <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true} decimalScale={3} suffix={row.uom.label}/>
                                    </td>
                                    {cultivator &&
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>{row.cullPlants}</td>
                                    }
                                </tr>                                      
                            )}
                        </tbody>
                    </table>
                    {record.plantContainers?.length > 0 &&
                        <>
                            <br/>
                            <span style={{ fontSize: '9pt' }}><strong>{t('plants')}</strong></span><br />
                            <table style={{ borderCollapse: 'collapse' }} >
                                <thead>
                                    <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('date')}</strong></td>
                                    <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '150px', textAlign: 'center' }}><strong>{t('number')}</strong></td>
                                    <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '150px', textAlign: 'center' }}><strong>{t('batch')}</strong></td>
                                    <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '200px', textAlign: 'center' }}><strong>{t('source')}</strong></td>
                                    <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '350px', textAlign: 'center' }}><strong>{t('item')}</strong></td>
                                    <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('originalQuantity')}</strong></td>
                                    <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('quantity')}</strong></td>
                                    <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('plantCount')}</strong></td>
                                </thead>
                                <tbody>
                                    {record.plantContainers.map((row, i) => {
                                        return(
                                            <>
                                                <tr key={`plantContainers-${i}`}>
                                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}><DateDisplay value={row.transaction.date} format={dateFormat}/></td>
                                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{row.container.number}</td>
                                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{row.transRec.batch != null ? row.transRec.batch.number : row.transRec.productionBatch != null ? row.transRec.productionBatch.number : ''}</td>
                                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{row.object != null ? row.object.text + '-' + row.transRec.number : ''}</td>
                                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{row.item.number + '-' + row.item.name + (row.container?.cullItem?.name != null ? ' (' + row.container.cullItem.name + ')' : null)}</td>
                                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>{row.netWeight + row.netWeightUOM}</td>
                                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>{row.originalAmount + row.netWeightUOM}</td>
                                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>{row.container?.cullPlants}</td>
                                                </tr>
                                                {i === record.plantContainers.length - 1 &&
                                                    <tr key='plantTotals'>
                                                        <td colSpan='5' style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}><strong>{t('total')}</strong></td>
                                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}><strong>{record.plantContainers.reduce((a, b) => a + b.netWeight, 0) + record.plantContainers[0].netWeightUOM}</strong></td>
                                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}><strong>{record.plantContainers.reduce((a, b) => a + b.originalAmount, 0) + record.plantContainers[0].netWeightUOM}</strong></td>
                                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}><strong>{record.plantContainers.reduce((a, b) => a + b.container?.cullPlants, 0)}</strong></td>
                                                    </tr>
                                                }
                                            </>
                                        )}                                        
                                    )}
                                </tbody>
                            </table>
                        </>
                    }
                    <br/>
                    <span style={{ fontSize: '9pt' }}><strong>{t('containers')}</strong></span><br />
                    <table style={{ borderCollapse: 'collapse' }} >
                        <thead>
                            <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('date')}</strong></td>
                            <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '150px', textAlign: 'center' }}><strong>{t('number')}</strong></td>
                            <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '150px', textAlign: 'center' }}><strong>{t('batch')}</strong></td>
                            <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '200px', textAlign: 'center' }}><strong>{t('source')}</strong></td>
                            <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '350px', textAlign: 'center' }}><strong>{t('item')}</strong></td>
                            <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('originalQuantity')}</strong></td>
                            <td style={{ borderBottom: '1px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('quantity')}</strong></td>
                        </thead>
                        <tbody>
                            {record.containers.map((row, i) => 
                                <tr key={`wasteContainers-${i}`}>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}><DateDisplay value={row.transaction.date} format={dateFormat}/></td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{row.container.number}</td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{row.transRec.batch != null ? row.transRec.batch.number : row.transRec.productionBatch != null ? row.transRec.productionBatch.number : ''}</td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{row.object != null ? row.object.text + '-' + row.transRec.number : ''}</td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{row.item.number + '-' + row.item.name}</td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>{row.netWeight + row.netWeightUOM}</td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>{row.originalAmount + row.netWeightUOM}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>                    
                    {!printView &&
                        <Footer {...props} />
                    }
                </Aux>
            }
        </div>
    )
}

class DestructionPrint extends Component{
    constructor(props){
        super(props);
        this.state = {
            record: null
        }
    }

    async componentDidMount(){
        let result = await axios.get('/api/destructionprint/' + this.props.match.params.id);
        result.data.qrPath = window.location.origin + '/#/destruction/' + this.props.match.params.id;
        this.setState({record: result.data}, () => {
            const crumbs = [
                { path: '/#/destructions', label: this.props.t('destructions'), screen: this.props.auth.screenDefs.Destruction},
                { path: 'active', label: this.props.t('destruction') + ' - ' + result.data.number, screen: this.props.auth.screenDefs.Destruction}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    generateMarkup = () => {
        let htmlData = ReactDOMServer.renderToStaticMarkup(
            <ComponentToPrint record={this.state.record} t={this.props.t} auth={this.props.auth} printView={true} 
            dateFormat={this.props.auth.user.dateFormat} cultivator={this.props.auth.user.cultivator} />
        );
        return htmlData;
    }

    generateHeaderFooter = () => {
        let header = ReactDOMServer.renderToStaticMarkup(<Header record={this.state.record} t={this.props.t} dateFormat={this.props.auth.user.dateFormat} printView={true}/>);
        let footer = ReactDOMServer.renderToStaticMarkup(<Footer record={this.state.record} t={this.props.t} dateFormat={this.props.auth.user.dateFormat} printView={true} />);
        return { header, footer };
    }

    printSettings = () => {
        const header = this.generateHeaderFooter();
        let data = {
            margin: {
                top: '96px',
                bottom: '250px',
                left: '30px',
                right: '30px'
            },
            landscape: true,
            header
        }
        return {data};
    }

    render(){
        const t = this.props.t;
        return(
            <div style={{ width: '100%'}}>
                <BreadcrumbBar />
                <div style={{ padding: '10px' }}>
                    {this.state.record != null &&
                        <GeneratePdf markup={this.generateMarkup} id={this.props.match.params.id} objectType={this.props.auth.constants.objectIds.destruction}
                                     printSettingSel={this.printSettings()} />
                    }                    
                </div>
                <div style={{ width: '12in'}}>
                    <Panel>
                        <ComponentToPrint record={this.state.record} constants={this.props.auth.constants} printView={false} 
                        t={t} dateFormat={this.props.auth.user.dateFormat} cultivator={this.props.auth.user.cultivator} />
                    </Panel>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default withTranslation() (connect(mapStateToProps)(DestructionPrint));