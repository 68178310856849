import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { approveSigSecurity, completeSigSecurity, 
    fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Panel from '../UI/Panel/Panel';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import CapaAction from '../Investigations/CapaAction';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import Button from '@mui/material/Button';
import SectionTitle from '../UI/Typography/SectionTitle';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import ConversionHelper from '../../helpers/conversionHelper';

class Car extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            location: '',
            locationSel: null,
            locations: '',
            status: this.props.auth.constants.statuses.new,
            statusSel: {
                value: '',
                label: '',
            },
            statuses: [],
            receivedDate: new Date(),
            regulator: '',
            regulatorSel: null,
            regulators: [],
            auditorName: '',
            auditorEmail: '',
            auditorPhone: '',
            type: '',
            typeSel: null,
            types: [],
            priority: '',
            prioritySel: null,
            priorities: [],
            dueDate: '',
            details: '',
            resolution: '',
            assignee: '',
            users: [],
            assigneeSel: null,
            user: null,
            reviewer: null,
            managers: [],
            signer: null,
            qas: [],
            documents: [],
            capaActions: [],
            security: [],
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            renderNum: Math.random(),
            optionsMenu: [],
            flagForSignature: 0,
            enableDelete: false,
            editCapa: false,
            page: 0,
            orderBy: 'number',
            ordDir: 1,
            totalCount: 0,
            capaId: '',
            capaIndex: -1,
            listSize: 5,
            enableSave: true,
            deleteOpen: false,
            sourceType: '',
            sourceTypeSel: null,
            sourceTypes: [],
            sourceDescription: '',
            sourceReferenceNumber: '',
            dateInitiated: '',
            batchAffected: '',
            batchAffectedSel: null,
            batches: [],
            quantityAffected: '',
            notes: '',
            isInternal: false,
            reporter: '',
            reporterSel: null,
            objects: [],
            objectType: '',
            objectTypeSel: null,
            recordList: [],
            records: [],
            recordSels: []
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location, common.cacheValues.status, common.cacheValues.carType, 
            common.cacheValues.regulator, common.cacheValues.priority, common.cacheValues.carSourceType, common.cacheValues.object], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.correctiveActionRequest, this.props.auth.user.tenant);
        let locationSel = null;
        let location = '';
        if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
            location = this.props.auth.user.defaultLocation;
            locationSel = locations.find(x => x.value === location);
        } else {
            if(locations.length === 1){
                locationSel = locations[0];
                location = locationSel.value;
            }
        }
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new)
        let fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let objects = ConversionHelper.carObjects(cacheValues.objects, this.props.auth.constants);
        this.setState({
            location: location,
            locationSel: locationSel,
            statusSel: statusSel,
            locations: locations,
            types: cacheValues.carTypes,
            regulators: cacheValues.regulators,
            priorities: cacheValues.priorities,
            users: userValues.create,
            managers: userValues.review,
            qas: userValues.approve,
            statuses: cacheValues.statuses,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            sourceTypes: cacheValues.carSourceTypes,
            objects: objects,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/cars', label: this.props.t('correctiveActionRequests'), screen: this.props.auth.screenDefs.Cars},
                    { path: 'active', label: this.props.t('correctiveActionRequest'), screen: this.props.auth.screenDefs.Cars}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const constants = this.props.auth.constants;
        let record = await axios.get('/api/car/' + this.state._id);
        let locationSel = this.state.locations.find(x => x.value === record.data.location);
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let typeSel = this.state.types.find(x => x.value === record.data.type);
        let prioritySel = this.state.priorities.find(x => x.value === record.data.priority);
        let sourceTypeSel = this.state.sourceTypes.find(x => x.value === record.data.sourceType);
        let regulatorSel = this.state.regulators.find(x => x.value === record.data.regulator);
        let assigneeSel = record.data.assignee != null ? this.state.users.find(x => x.value === record.data.assignee) : null;
        let reporterSel = record.data.reporter != null ? this.state.users.find(x => x.value === record.data.reporter) : null;
        let objectTypeSel = record.data.objectType != null ? this.state.objects.find(x => x.value === record.data.objectType) : null;
        let records = record.data.records != null ? record.data.records : [];
        let recordSels = [];
        let recordList = [];
        if(record.data.objectType != null && record.data.objectType !== ''){
            let ents = await axios.get('/api/entitylist/' + record.data.objectType)
            recordList = ents.data;
            if(records != null && records.length > 0){
                for (let a = 0; a < records.length; a++){
                    let val = ents.data.find(x => x.value === records[a]);
                    if(val != null){
                        recordSels.push(val);
                    }
                }
            }
        }
        let enableDelete = this.props.permission.delete && (record.data.status === constants.statuses.new || record.data.status === constants.statuses.inprogress);
        let fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const optionsMenu = [
            { 
                security: completeSigSec, user: this.props.auth.user.id, users: this.state.users, name: t('complete'), object: record.data._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user, 
                objectType: this.props.auth.constants.objectIds.car, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, 
            },
            { 
                security: reviewSigSec, user: this.props.auth.user.id, users: this.state.managers, name: t('review'),
                title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id, 
                objectType: this.props.auth.constants.objectIds.car, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: this.state.qas, name: t('approve'),
                title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer, 
                objectType: this.props.auth.constants.objectIds.car, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        this.setState({
            number: record.data.number,
            status: record.data.status,
            statusSel: statusSel,
            location: record.data.location,
            locationSel: locationSel,
            receivedData: record.data.receivedDate,
            regulator: record.data.regulator,
            regulatorSel: regulatorSel,
            auditorName: record.data.auditorName,
            auditorEmail: record.data.auditorEmail,
            auditorPhone: record.data.autditorPhone,
            type: record.data.type,
            typeSel: typeSel,
            priority: record.data.priority,
            prioritySel: prioritySel,
            dueDate: record.data.dueDate,
            details: record.data.details,
            resolution: record.data.resolution,
            assignee: record.data.assignee,
            assigneeSel: assigneeSel,
            documents: record.data.documents,
            user: record.data.user,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            fieldAccess: fieldAccess,
            enableDelete: enableDelete,
            renderNum: Math.random(),
            deleteOpen: false,
            sourceType: record.data.sourceType,
            sourceTypeSel: sourceTypeSel,
            sourceDescription: record.data.sourceDescription,
            sourceReferenceNumber: record.data.sourceReferenceNumber,
            dateInitiated: record.data.dateInitiated,
            batchAffected: record.data.batchAffected,
            quantityAffected: record.data.quantityAffected,
            notes: record.data.notes,
            isInternal: record.data.sourceType === this.props.auth.constants.carSourceTypes.internal ? true : false,
            reporter: record.data.reporter,
            reporterSel: reporterSel,
            objectType: record.data.objectType,
            objectTypeSel: objectTypeSel,
            records: record.data.records,
            recordSels: recordSels,
            recordList: recordList
        }, () => {

            const crumbs = [
                { path: '/#/cars', label: this.props.t('correctiveActionRequests'), screen: this.props.auth.screenDefs.Cars},
                { path: 'active', label: this.props.t('correctiveActionRequest') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.Cars}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            this.loadActions();
        });
    };

    loadActions = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: this.props.auth.constants.objectIds.car, value: this.state._id},
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let enableDelete = this.props.permission.delete && (this.state.status === this.props.auth.constants.statuses.inprogress ||
            this.state.status === this.props.auth.constants.statuses.new);
        let result = await axios.get('/api/capas', {params: params});
        if(result.data.totalCount > 0)
            enableDelete = false;
        this.setState({
            capaActions: result.data.data,
            totalCount: result.data.totalCount,
            enableDelete: enableDelete
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
        }, async () => {
            if(data === 'sourceType'){
                this.setState({isInternal: newValue != null && newValue.value === this.props.auth.constants.carSourceTypes.internal ? true : false});
            } else if (data === 'objectType'){
                if(newValue != null){
                    let list = await axios.get('/api/entitylist/' + newValue.value);
                    this.setState({recordList: list.data, records: [], recordSels: []})
                } else {
                    this.setState({recordList: [], records: [], recordSels: []});
                }
            }
        
        });
    };

    changeMulti = (name, data) => (e, newValue) => {
        let ids = [];
        if(newValue != null && newValue.length > 0){
            ids = newValue.map(x => x.value);
        }
        this.setState({[name]: newValue, [data]: ids});
    }

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            _id: this.state._id,
            location: this.state.location,
            status: this.state.status,
            receivedDate: this.state.receivedDate,
            regulator: this.state.regulator,
            auditorName: this.state.auditorName,
            auditorEmail: this.state.auditorEmail,
            auditorPhone: this.state.auditorPhone,
            type: this.state.type,
            priority: this.state.priority,
            dueDate: this.state.dueDate,
            details: this.state.details,
            resolution: this.state.resolution,
            assignee: this.state.assignee,
            sourceDescription: this.state.sourceDescription,
            sourceType: this.state.sourceType,
            sourceReferenceNumber: this.state.sourceReferenceNumber,
            dateInitiated: this.state.dateInitiated,
            batchAffected: this.state.batchAffected,
            quantityAffected: this.state.quantityAffected,
            notes: this.state.notes,
            reporter: this.state.reporter,
            objectType: this.state.objectType,
            records: this.state.records
        };
        try {
            let result = await axios.post('/api/car', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/car/' + result.data.id);
            else {
                this.setState({enableSave: true}, () => {
                    this.loadRecord();
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                });
            }
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.receivedDate == null || state.receiedDate === '')
            errors[t('receivedDate')] = t('required');
        if(state.priority == null || state.priority === '')
            errors[t('priority')] = t('required');
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(state.dueDate == null || state.dueDate === '')
            errors[t('dueDate')] = t('required');
        if(state.sourceType == null || state.sourceType === '')
            errors[t('sourceType')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    validateComplete(){
        let errors = {};
        const t = this.props.t;
        if(this.state.assignee == null || this.state.assignee === '')
            errors[t('assignee')] = t('required');
        if(this.state.resolution == null || this.state.resolution === '')
            errors[t('resolution')] = t('requried');
        if(isEmpty(errors)){
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return null;
        }else{
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return t('incomplete');
        }
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    delete = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._delete(e));
    }

    _delete = async (e) => {
        const t = this.props.t;
        if(this.state._id != null && this.state.id !== ''){
            if(this.state.status === this.props.auth.constants.statuses.new || this.state.status === this.props.auth.constants.statuses.inprogress){
                try {
                    let result = await axios.delete('/api/car/' + this.state._id);
                    this.setState({enableSave: true, deleteOpen: false});
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                    this.props.history.goBack();
                } catch (err){
                    this.setState({enableSave: true, deleteOpen: false});
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
                }
            }
        } else {
            this.setState({enableSave: true, deleteOpen: false});
            this.props.history.goBack();
        }
    };

    addAction = (e) => {
        this.setState({editCapa: true})
    };

    closeDialog = (e) => {
        this.setState({
            editCapa: false,
            capaId: '',
            capaIndex: -1
        },() => {this.loadActions();});
    };

    editCapa = (i) => (e) => {
        let capa = this.state.capaActions[i];
        this.setState({capaId: capa._id, editCapa: true, capaIndex: i});
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadActions();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadActions();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadActions();
        });
    };

    render(){
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.car}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        {this.state.number != null && this.state.number !== '' &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            <FormControl>
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSel != null ? this.state.statusSel.label : ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.locationSel}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locationSel', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]}
                                    label={t('location')}
                                    disabled={!fieldAccess}
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('location')}</FormLabel>
                                    {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.sourceTypeSel}
                                    options={this.state.sourceTypes}
                                    onChange={this.changeAuto('sourceTypeSel', 'sourceType')}
                                    error={errors[t('sourceType')] != null ? true : false}
                                    helperText={errors[t('sourceType')]}
                                    label={t('sourceType')}
                                    disabled={!fieldAccess}
                                />
                            }
                            {!fieldAccess && this.state.sourceTypeSel != null && this.state.sourceTypeSel !== '' &&
                                <FormControl>
                                    <FormLabel>{t('sourceType')}</FormLabel>
                                    {this.state.sourceTypeSel.label}
                                </FormControl>
                            }
                        </Grid>
                        {!this.state.isInternal &&
                            <Aux>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <DateSelect
                                            onChange={this.changeDate('receivedDate')}
                                            value={this.state.receivedDate}
                                            helperText={errors[t('receivedDate')]}
                                            error={errors[t('receivedDate')] != null ? true : false}
                                            label={t('receivedDate')}
                                            disabled={!fieldAccess}
                                            format={this.props.auth.user.dateFormat}
                                        />
                                    }
                                    {!fieldAccess && this.state.receivedDate != null && this.state.receivedDate !== '' &&
                                        <FormControl>
                                            <FormLabel>{t('receivedDate')}</FormLabel>
                                            <DateDisplay value={this.state.receivedDate} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.regulatorSel}
                                            options={this.state.regulators}
                                            onChange={this.changeAuto('regulatorSel', 'regulator')}
                                            error={errors[t('regulator')] != null ? true : false}
                                            helperText={errors[t('regulator')]}
                                            label={t('regulator')}
                                            disabled={!fieldAccess}
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('regulator')}</FormLabel>
                                            {this.state.regulatorSel != null ? this.state.regulatorSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <TextField value={this.state.auditorName} onChange={this.changeValue} name="auditorName"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('auditorName')}
                                        error={errors[t('auditorName')] != null ? true : false} helperText={errors[t('auditorName')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('auditorName')}</FormLabel>
                                            {this.state.auditorName}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <TextField value={this.state.auditorEmail} onChange={this.changeValue} name="auditorEmail"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('email')}
                                        error={errors[t('auditorEmail')] != null ? true : false} helperText={errors[t('auditorEmail')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('email')}</FormLabel>
                                            {this.state.auditorEmail}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <InputMaskField mask="999-999-9999" name="auditorPhone" value={this.state.auditorPhone}
                                        onChange={this.changeValue}
                                        helperText={errors[t('auditorPhone')]}
                                        error={errors[t('auditorPhone')] != null ? true : false}
                                        label={t('phoneNumber')}
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('phoneNumber')}</FormLabel>
                                            {this.state.auditorPhone}
                                        </FormControl>
                                    }
                                </Grid>
                            </Aux>
                        }
                        {this.state.isInternal &&
                            <Aux>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.reporterSel}
                                            options={this.state.users}
                                            onChange={this.changeAuto('reporterSel', 'reporter')}
                                            error={errors[t('reporter')] != null ? true : false}
                                            helperText={errors[t('reporter')]}
                                            label={t('reportedBy')}
                                            disabled={!fieldAccess}
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('reportedBy')}</FormLabel>
                                            {this.state.reporterSel != null ? this.state.reporterSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.objectTypeSel}
                                            options={this.state.objects}
                                            onChange={this.changeAuto('objectTypeSel', 'objectType')}
                                            error={errors[t('objectType')] != null ? true : false}
                                            helperText={errors[t('objectType')]}
                                            label={t('relatedTo')}
                                            disabled={!fieldAccess}
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('relatedTo')}</FormLabel>
                                            {this.state.objectTypeSel != null ? this.state.objectTypeSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                {this.state.recordList != null && this.state.recordList.length > 0 &&
                                    <Grid item xs={12} sm={6}>
                                        <AutoCompleteField
                                            multiple={true}
                                            value={this.state.recordSels}
                                            options={this.state.recordList}
                                            onChange={this.changeMulti('recordSels', 'records')}
                                            error={errors[t('records')] != null ? true : false}
                                            helperText={errors[t('records')]}
                                            label={t('affected')}
                                            disabled={!fieldAccess}
                                        />
                                    </Grid>
                                }
                                <Grid item sm={12}>
                                    {fieldAccess &&
                                        <TextField value={this.state.sourceDescription} onChange={this.changeValue} name="sourceDescription"
                                        multiline={true} maxRows="10"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('description')}
                                        error={errors[t('sourceDescription')] != null ? true : false} helperText={errors[t('sourceDescription')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('sourceDescription')}</FormLabel>
                                            {this.state.sourceDescription}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <TextField value={this.state.sourceReferenceNumber} onChange={this.changeValue} name="sourceReferenceNumber"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('referenceNumber')}
                                        error={errors[t('sourceReferenceNumber')] != null ? true : false} helperText={errors[t('sourceReferenceNumber')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('referenceNumber')}</FormLabel>
                                            {this.state.sourceReferenceNumber}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <DateSelect
                                            onChange={this.changeDate('dateInitiated')}
                                            value={this.state.dateInitiated}
                                            helperText={errors[t('dateInitiated')]}
                                            error={errors[t('dateInitiated')] != null ? true : false}
                                            label={t('dateInitiated')}
                                            disabled={!fieldAccess}
                                            format={this.props.auth.user.dateFormat}
                                        />
                                    }
                                    {!fieldAccess && this.state.dateInitiated != null && this.state.dateInitiated !== '' &&
                                        <FormControl>
                                            <FormLabel>{t('dateInitiated')}</FormLabel>
                                            <DateDisplay value={this.state.dateInitiated} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                {/*}
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.batchAffected}
                                            options={this.state.batches}
                                            onChange={this.changeAuto('batchAffectedSel', 'batchAffected')}
                                            error={errors[t('batchAffected')] != null ? true : false}
                                            helperText={errors[t('batchAffected')]}
                                            label={t('batchAffected')}
                                            disabled={!fieldAccess}
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('batchAffected')}</FormLabel>
                                            {this.state.batchAffected}
                                        </FormControl>
                                    }
                                </Grid>
                                */}
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField value={this.state.quantityAffected} onChange={this.changeValue} name="quantityAffected"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantityAffected')} type="number"
                                        error={errors[t('quantityAffected')] != null ? true : false} helperText={errors[t('quantityAffected')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('quantityAffected')}</FormLabel>
                                            {this.state.quantityAffected}
                                        </FormControl>
                                    }
                                </Grid>
                            </Aux>
                        }
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.typeSel}
                                    options={this.state.types}
                                    onChange={this.changeAuto('typeSel', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]}
                                    label={t('type')}
                                    disabled={!fieldAccess}
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('type')}</FormLabel>
                                    {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.prioritySel}
                                    options={this.state.priorities}
                                    onChange={this.changeAuto('prioritySel', 'priority')}
                                    error={errors[t('priority')] != null ? true : false}
                                    helperText={errors[t('priority')]}
                                    label={t('priority')}
                                    disabled={!fieldAccess}
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('priority')}</FormLabel>
                                    {this.state.prioritySel != null ? this.state.prioritySel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <DateSelect
                                    onChange={this.changeDate('dueDate')}
                                    value={this.state.dueDate}
                                    helperText={errors[t('dueDate')]}
                                    error={errors[t('dueDate')] != null ? true : false}
                                    label={t('dueDate')}
                                    disabled={!fieldAccess}
                                    format={this.props.auth.user.dateFormat}
                                />
                            }
                            {!fieldAccess && this.state.dueDate != null && this.state.dueDate !== '' &&
                                <FormControl>
                                    <FormLabel>{t('dueDate')}</FormLabel>
                                    <DateDisplay value={this.state.dueDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.assigneeSel}
                                    options={this.state.users}
                                    onChange={this.changeAuto('assigneeSel', 'assignee')}
                                    error={errors[t('assignee')] != null ? true : false}
                                    helperText={errors[t('assignee')]}
                                    label={t('assignee')}
                                    disabled={!fieldAccess}
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('assignee')}</FormLabel>
                                    {this.state.assigneeSel != null ? this.state.assigneeSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            {fieldAccess &&
                                <TextField value={this.state.details} onChange={this.changeValue} name="details" 
                                multiline={true} maxRows="10"
                                size="medium" fullWidth={true} label={t('details')}
                                error={errors[t('details')] != null ? true : false} helperText={errors[t('details')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('details')}</FormLabel>
                                    {this.state.details}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item sm={12}>
                            {fieldAccess &&
                                <TextField value={this.state.notes} onChange={this.changeValue} name="notes" 
                                multiline={true} maxRows="10"
                                size="medium" fullWidth={true} label={t('notes')}
                                error={errors[t('notes')] != null ? true : false} helperText={errors[t('notes')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('resolution')}</FormLabel>
                                    {this.state.resolution}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item sm={12}>
                            {fieldAccess &&
                                <TextField value={this.state.resolution} onChange={this.changeValue} name="resolution" 
                                multiline={true} maxRows="10"
                                size="medium" fullWidth={true} label={t('resolution')}
                                error={errors[t('resolution')] != null ? true : false} helperText={errors[t('resolution')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('resolution')}</FormLabel>
                                    {this.state.resolution}
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('correctiveMeasures')}/>
                            <TableContainer component={Paper}>
                                <Table aria-label="capaActions">
                                    <TableHead>
                                        <TableRow key="capaHeader">
                                            <TableCell>{t('number')}</TableCell>
                                            <TableCell>{t('title')}</TableCell>
                                            <TableCell>{t('dueDate')}</TableCell>
                                            <TableCell>{t('status')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.capaActions.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell>
                                                    <TextOnlyClickButton enabled={true} click={this.editCapa(i)} name={row.number}/>
                                                </TableCell>
                                                <TableCell>{row.title}</TableCell>
                                                <TableCell><DateDisplay value={row.dueDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                                <TableCell>{row.status}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow key="tableFooter">
                                            <TablePagination count={this.state.totalCount}
                                            rowsPerPage={this.state.listSize}
                                            onPageChange={this.handleChangePage}
                                            page = {this.state.page}
                                            rowsPerPageOptions={common.rowOptions}
                                            onRowsPerPageChange={this.handleRowsPerPage}/>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={3}>
                                <Grid item sm={8}>
                                    <Button variant="outlined" color="primary" size="small" disabled={!fieldAccess} onClick={this.addAction}>{t('add')}</Button>
                                </Grid>
                            </Grid>
                            {this.state.editCapa &&
                                <CapaAction
                                    reload={this.loadActions}
                                    open={this.state.editCapa}
                                    onClose={this.closeDialog}
                                    car={this.state._id}
                                    id={this.state.capaId}
                                    users={this.state.users}
                                    qas={this.state.qas}
                                    location={this.state.location}
                                    managers={this.state.managers}
                                />
                            }
                        </Aux>
                    }
                    <br/>
                    <FormButtonStack
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.car}
                        callback={this.loadRecord}
                        saveClick={this.save}
                        status={this.state.status}
                        permissions={this.props.permission}
                        common={this.props.auth.common}
                        constants={this.props.auth.constants}
                        enableDelete={this.state.enableDelete}
                        deleteClick={this.deleteToggle}
                        saveEnabled={this.state.enableSave}
                    />
                </Panel>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.delete()} open={this.state.deleteOpen} />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.car}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Car));