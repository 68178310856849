import React, { Component } from 'react';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from '@mui/material/Button';
import Aux from '../../hoc/Auxilary/Auxilary';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { withTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';

class DocumentViewer extends Component {
    constructor(props){
        super(props);
        this.state = {
            showDoc: false,
            data: null,
            pageNumber: 1,
            numPages: null,
            showImage: false
        };
    }

    async componentDidMount(){

    }

    hideDoc = e => {
        this.setState({showDoc: false});
    }

    showDoc = e => {
        this.setState({showDoc: true});
    };

    onDocumentLoadSuccess = ({numPages}) => {
        // console.info(props)
        this.setState({numPages});
    };

    imageClick = async e => {
        pdfjs.GlobalWorkerOptions.workerSrc = new URL(
            'pdfjs-dist/build/pdf.worker.min.mjs',
            import.meta.url,
          ).toString();
        var data = await axios.get('/api/document/' + this.props.document._id);
        var pdfData = data.data;
        this.setState({
            data: pdfData,
            showImage: true
        });
    };

    closeImage = e => {
        this.setState({showImage: false});
    };

    documentClick = async e => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        let data = await axios.get('/api/document/' + this.props.document._id);
        let pdfData = data.data;
        let linkSource = null;
        if(this.props.document.type === 'text/csv'){
             linkSource = 'data:application/octet-stream;base64,' + pdfData;
        } else {
            linkSource = 'data:application/octet-stream;base64,' + pdfData;
        }
        this.setState({data: pdfData.data});
        let link = document.createElement("a");
        link.download = this.props.document.name;
        link.href = linkSource;
        link.click();
    };

    viewClick = async e => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        let data = await axios.get('/api/document/' + this.props.document._id);
        let pdfData = data.data;
        this.setState({showDoc: true, data: pdfData});
    };

    prevClick = e => {
        this.setState({pageNumber: this.state.pageNumber - 1});
    };

    nextClick = e => {
        this.setState({pageNumber: this.state.pageNumber + 1});
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                {this.props.document.thumbnail != null &&
                    <Aux>
                    <Grid container spacing={1}>
                        <Grid item style={{width: 300}}>
                            {this.props.document.name}
                        </Grid>
                        <Grid item style={{width: 100}}>
                            <img src={"data:application/octet-stream;base64," + this.props.document.thumbnail} alt={this.props.document.name}
                            height="auto" width="100" onClick={this.imageClick}/>
                        </Grid>
                        {this.props.deleteCallback != null &&
                            <IconButton aria-label="delete line" onClick={this.props.deleteCallback}>
                                <DeleteIcon  />
                            </IconButton>
                        }
                    </Grid>
                    </Aux>
                }
                <Dialog open={this.state.showImage} onClose={this.closeImage} aria-labelledby={'image-dialog'} fullWidth={false} maxWidth="md">
                    <DialogContent>
                        <img src={"data:application/octet-stream;base64," + this.state.data} alt={this.props.document.name}
                            height="auto" width="100%" />
                    </DialogContent>
                </Dialog>
                {this.props.document.thumbnail == null && this.props.document.type === 'application/pdf' &&
                    <div>
                        <TextOnlyClickButton enabled={true} click={this.viewClick}
                        name={this.props.final != null ? t('finalDocument'): this.props.name != null ? this.props.name : this.props.document.name}/>
                        {this.props.deleteCallback != null &&
                            <IconButton aria-label="delete line" onClick={this.props.deleteCallback}>
                                <DeleteIcon  />
                            </IconButton>
                        }
                        <Dialog open={this.state.showDoc} onClose={this.hideDoc} aria-labelledby={'document-dialog'} fullWidth={false} maxWidth="md">
                            <DialogContent>
                                <Document file={'data:application/octet-stream;base64,' + this.state.data} onLoadSuccess={this.onDocumentLoadSuccess}>
                                    <Page pageNumber={this.state.pageNumber}/>
                                </Document>
                            </DialogContent>
                            <DialogActions>
                                {this.state.pageNumber > 1 &&
                                    <Button variant="contained" color="secondary" size="small" onClick={this.prevClick}>{'<'}</Button>
                                }
                                &nbsp;&nbsp;<p>Page {this.state.pageNumber} of {this.state.numPages}</p>&nbsp;&nbsp;
                                {this.state.pageNumber < this.state.numPages &&
                                    <Button variant="contained" color="secondary" size="small" onClick={this.nextClick}>{'>'}</Button>
                                }
                                <Button variant="contained" color="primary" size="small" onClick={this.documentClick}>{t('download')}</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                }
                {this.props.document.thumbnail == null && this.props.document.type !== 'application/pdf' &&
                    <div>
                        <TextOnlyClickButton enabled={true} click={this.documentClick} name={this.props.document.name}/>
                        {this.props.deleteCallback != null &&
                            <IconButton aria-label="delete line" onClick={this.props.deleteCallback}>
                                <DeleteIcon  />
                            </IconButton>
                        }
                    </div>
                }
            </Aux>
        );
    }
}

export default withTranslation() (DocumentViewer);
