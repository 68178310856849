import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class PractitionerList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            prov: '',
            provs: [],
            lastName: '',
            orderBy: 'lastName',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            provSel: null,
            security: []
        }
        this.changeValue = this.changeValue.bind(this);
    }

    async componentDidMount(){
        var values = await axios.get('/api/practitionervalues');
        this.setState({provs: values.data.provs, security: this.props.permission}, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('practitioners'), screen: this.props.auth.screenDefs.PractitionerList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
    }

    async loadRecords(){
        var sort = {[this.state.orderBy]: this.state.ordDir};
        const data = {
            offset: this.state.offset,
            size: this.state.listSize,
            prov: this.state.prov,
            lastName: this.state.lastName,
            sort: JSON.stringify(sort)
        }
        var list = await axios.get('/api/practitioners', {params: data});
        this.setState({
            list: list.data.data,
            pageCount: Math.ceil(list.data.totalCount / this.state.listSize),
            totalCount: list.data.totalCount
        })
    }

    changeValue(e){
        this.setState({[e.target.name]: e.target.value, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    searchClick(e){
        this.loadRecords();
    }

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/practitioner'
                        createAccess={security.create}
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.lastName} onChange={this.changeValue} name="lastName"
                            size="medium" fullWidth={true} label={t('lastName')}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.provSel}
                                options={this.state.provs}
                                onChange={this.changeAuto('provSel', 'prov')}
                                label={t('province')}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Practitioner List">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('lastName')}
                                            active={this.state.orderBy === 'lastName'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('lastName')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('firstName')}
                                            active={this.state.orderBy === 'firstName'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('firstName')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('profession')}
                                            active={this.state.orderBy === 'profession'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('profession')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('prov')}
                                            active={this.state.orderBy === 'prov'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('province')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('license')}
                                            active={this.state.orderBy === 'license'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('license')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/practitioner/' + row._id} name={'practitionerRow' + i}>{row.lastName}</Link></TableCell>
                                        <TableCell>{row.firstName}</TableCell>
                                        <TableCell>{row.profession.label}</TableCell>
                                        <TableCell>{row.address != null && row.address.prov != null ? row.address.prov.label : ''}</TableCell>
                                        <TableCell>{row.licenseNumber}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(PractitionerList));
