import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Aux from '../../hoc/Auxilary/Auxilary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SectionTitle from '../UI/Typography/SectionTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next';

export default function SignatureCapture (props) {
    const { signature, editSig, heading, open, saveSig, close } = props;
    let sigPad = {};
    const { t } = useTranslation();
    const clearSig = () => {
        sigPad.clear();
    }

    const saveSignature = () => {
        var signature = sigPad.getTrimmedCanvas().toDataURL('image/png');
        saveSig(signature);
    }

    return(
        <Aux>
            <Dialog open={open} onClose={close} center aria-labelledby="injury-incident-signature-capture">
                <DialogTitle aria-labelledby="injury-incident-signature-title">
                    <SectionTitle title={heading}/>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">{t('signAck')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {(signature === null || signature === '') &&
                                <Aux>
                                    <Box component="div" borderRadius="borderRadius" sx={{border: "1px solid #9E9E9E", marginTop: "16px"}}>
                                        <SignatureCanvas penColor="#5BC6CC" canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                                        ref={(ref) => {sigPad = ref}}/>
                                    </Box>
                                </Aux>
                            }
                            {(signature !== null && signature !== '') &&
                                <Aux>
                                    <img src={signature} alt={t('noSignature')}/><br/><br/>
                                </Aux>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {(signature === null || signature === '') &&
                        <Aux>
                            <Button variant="contained" size="small" color="primary" onClick={saveSignature}>{t('accept')}</Button>&nbsp;&nbsp;
                            <Button variant="contained" size="small" color="secondary" onClick={clearSig}>{t('clear')}</Button>
                        </Aux>
                    }
                    {(signature !== null && signature !== '') &&
                        <Aux>
                            <Button variant="contained" size="small" color="primary" onClick={editSig}>{t('edit')}</Button>&nbsp;&nbsp;
                            <Button variant="contained" size="small" color="secondary" onClick={close}>{t('close')}</Button>
                        </Aux>
                    }
                </DialogActions>
            </Dialog>
        </Aux>
    )
};