import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI//DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import { connect } from 'react-redux';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';

class TestResults extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            pages: 1,
            listSize: 5,
            orderBy: 'number',
            ordDir: -1,
            totalCount: 0,
            page: 0
        };
    }

    componentDidMount(){
        this.loadRecords();
    }

    loadRecords = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [];
        if(this.props.vendor != null && this.props.vendor !== ''){
            columnFilters.push({id: 'vendor', value: this.props.vendor})
        }
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        var list = await axios.get('/api/testinstances', {params: params});
        this.setState({
            list: list.data.data,
            pages: Math.ceil(list.data.totalCount / this.state.listSize),
            totalCount: list.data.totalCount
        })
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                {this.state.list != null && this.state.list.length > 0 &&
                    <Aux>
                        <SectionTitle title={t('testResults')}/>
                        <TableContainer component={Paper}>
                        <Table aria-label="Test Results">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('date')}
                                        active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('testDefinition')}
                                        active={this.state.orderBy === 'testDefinition'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('test')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('item')}
                                        active={this.state.orderBy === 'item'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('item')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('type')}
                                        active={this.state.orderBy === 'type'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('type')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Batches/Lots</TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('result')}
                                        active={this.state.orderBy === 'result'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('result')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/testresult/' + row._id}>{row.number}</Link></TableCell>
                                        <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={row.date}/></TableCell>
                                        <TableCell>{row.testDefinition}</TableCell>
                                        <TableCell>{row.item}</TableCell>
                                        <TableCell>{row.type}</TableCell>
                                        <TableCell>{row.batchString !== '' && row.batchstring != null ? row.batchString : row.lot}</TableCell>
                                        <TableCell>{row.result}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <div className="col-sm-1">
                                        <Link to={'/testresult'} className="btn btn-primary">{t('new')}</Link>
                                    </div>
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                        </TableContainer>
                    </Aux>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(TestResults));