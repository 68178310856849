import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import { CSVDownload } from "react-csv";
import AddIcon from '@mui/icons-material/Add';
import ActionBar from '../UI/Buttons/ActionBar';
import listHelper from '../../helpers/listHelper';

const Vendors = (props) => {
    const isFirstRender = useRef(true);
    const [loadRefData, setLoadRefData] = useState(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [csvOutput, setCsvOutput] = useState({headers: null, data: null});
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [columnOrder, setColumnOrder] = useState([]);
    const [values, setValues] = useState({types: [], certificationTypes: [], qualificationStatuses: [], terms: [], licenseTypes: []});
    const { t } = useTranslation('translation');
    const csvSeparator = sessionStorage.getItem('csvSeparator');
    const tenant = props.auth.user.tenant;
    const crumbs = [
        { path: 'active', label: t('vendors'), screen: props.auth.screenDefs.VendorList}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        async function fetchData(){
            const columnFilters = await listHelper.getItem('VendorsColumnFilters', tenant);
            const columnVisibility = await listHelper.getItem('VendorsColumnVisibility', tenant);
            const density = await listHelper.getItem('VendorsDensity', tenant);
            const globalFilter = await listHelper.getItem('VendorsGlobalFilter', tenant);
            const showGlobalFilter = await listHelper.getItem('VendorsShowGlobalFilter', tenant);
            const showColumnFilters = await listHelper.getItem('VendorsShowColumnFilters', tenant);
            const sorting = await listHelper.getItem('VendorsSorting', tenant);
            const pagination = await listHelper.getItem('VendorsPagination', tenant);
            const tempColumnOrder = await listHelper.getItem('VendorsColumnOrder', tenant);
            if (columnFilters) {
                let filters = JSON.parse(columnFilters);
                setColumnFilters(filters);
            }
            if (columnVisibility) {
                setColumnVisibility(columnVisibility);
            }
            if (density) {
                setDensity(density);
            }
            if (globalFilter) {
                setGlobalFilter(globalFilter);
            }
            if (showGlobalFilter) {
                setShowGlobalFilter(showGlobalFilter);
            }
            if (showColumnFilters) {
                setShowColumnFilters(showColumnFilters);
            }
            if (sorting) {
                setSorting(sorting);
            }
            if (pagination) {
                setPagination(pagination);
            }
            if (tempColumnOrder) {
                setColumnOrder(tempColumnOrder);
            } else {
                setColumnOrder(['number', 'name', 'type', 'address', 'phoneNumber', 'terms', 'licenseType', 'licenseNumber', 'certifications', 'qualificationStatus']);
            }
            isFirstRender.current = false;
        }
        if(isFirstRender.current){
            fetchData();
        }
    }, [values, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('VendorsColumnFilters', tenant, JSON.stringify(columnFilters));
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('VendorsColumnVisibility', tenant, columnVisibility);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnVisibility, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('VendorsDensity', tenant, density);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [density, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('VendorsGlobalFilter', tenant, globalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [globalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('VendorsShowGlobalFilter', tenant, showGlobalFilter);
        }
        if (isFirstRender.current) return;
        fetchData()
    }, [showGlobalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('VendorsShowColumnFilters', tenant, showColumnFilters);
        }
        if (isFirstRender.current) return;
        fetchData();

    }, [showColumnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('VendorsSorting', tenant, sorting);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [sorting, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('VendorsPagination', tenant, pagination);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [pagination, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('VendorsColumnOrder', tenant, columnOrder);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnOrder, tenant]);

    const resetState = () => {
        listHelper.removeItem('VendorsColumnFilters', tenant);
        listHelper.removeItem('VendorsColumnVisibility', tenant);
        listHelper.removeItem('VendorsDensity', tenant);
        listHelper.removeItem('VendorsGlobalFilter', tenant);
        listHelper.removeItem('VendorsShowGlobalFilter', tenant);
        listHelper.removeItem('VendorsShowColumnFilters', tenant);
        listHelper.removeItem('VendorsSorting', tenant);
        listHelper.removeItem('VendorsPagination', tenant);
        listHelper.removeItem('VendorsColumnOrder', tenant);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.vendorType, common.cacheValues.qualificationStatus, common.cacheValues.certificationType,
                common.cacheValues.saleTerms, common.cacheValues.licenseType], props.auth.constants, null, props.auth.user.tenant);
            setValues({
                types: cacheValues.vendorTypes,
                qualificationStatuses: cacheValues.qualificationStatuses,
                certificationTypes: cacheValues.certificationTypes,
                terms: cacheValues.saleTerms,
                licenseTypes: cacheValues.licenseTypes
            });
            setLoadRefData(false);
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, columnFilters, loadRefData]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'vendors',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/vendors', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });

    const columns = useMemo(() => [
        {
            accessorKey: 'number',
            id: 'number',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/vendor/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            ),
        },
        {
            accessorKey: 'name',
            id: 'name',
            header: t('name')
        },
        {
            accessorKey: 'type',
            id: 'type',
            header: t('type'),
            filterVariant: 'select',
            filterSelectOptions: values.types,
        },
        {
            accessorKey: 'address',
            id: 'address',
            header: t('address'),
            enableColumnFilter: false,
            enableSorting: false
        },
        {
            accessorKey: 'phoneNumber',
            id: 'phoneNumber',
            header: t('phoneNumber'),
            enableColumnFilter: false,
            enableSorting: false
        },
        {
            accessorKey: 'terms',
            id: 'terms',
            header: t('terms'),
            filterVariant: 'select',
            filterSelectOptions: values.terms,
        },
        {
            accessorKey: 'licenseType',
            id: 'licenseType',
            header: t('licenseType'),
            filterVariant: 'select',
            filterSelectOptions: values.licenseTypes,
        },
        {
            accessorKey: 'licenseNumber', 
            id: 'licenseNumber', 
            header: t('licenseNumber'),
            enableColumnFilter: false,
            enableSorting: false
        },
        {
            accessorKey: 'certifications',
            id: 'certifications',
            header: t('certifications'),
            enableColumnFilter: false,
            enableSorting: false
        },
        {
            accessorKey: 'qualificationStatus',
            id: 'qualificationStatus',
            header: t('qualificationStatus'),
            enableColumnFilter: false,
            enableSorting: false
        }
    ], [t, values]);

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true,  columnVisibility: {id: false}},
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: false,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()}>
                        <RefreshIcon color="primary"/>
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => csv()}>
                        <ExcelIcon color="primary"/>
                    </IconButton>
                </Tooltip>
                {props.permission.create &&
                    <Tooltip arrow title={t('add')}>
                        <IconButton component={Link} to='/vendor'>
                            <AddIcon color="primary"/>
                        </IconButton>
                    </Tooltip>
                }
                <Tooltip arrow title={t('clearListSettings')}>
                    <IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });

    const csv = async () => {
        setCsvOutput({headers: null, data: null});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axios.get('/api/vendorscsv', {params: params});
        let headers = response.data.headers;
        let csvData = response.data.data;
        setCsvOutput({headers: headers, data: csvData});
    }

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs}>
                <ActionBar/>
            </BreadcrumbBar>
            {csvOutput != null && csvOutput.data != null &&
                <CSVDownload data={csvOutput.data} target="_blank" headers={csvOutput.headers} separator={csvSeparator}/>
            }
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
        </Aux>
    )
    
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Vendors);
