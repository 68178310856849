import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import axios from 'axios';
import Aux from '../../hoc/Auxilary/Auxilary';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

const DocumentDisplay = (props) => {
    const [documentData, setDocumentData] = useState();
    const [docRecord, setDocRecord] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState();

    const { t } = useTranslation('translation');

    useEffect(() => {
        async function fetchData(){
            if(props.document != null && props.document._id != null){
                setDocRecord(props.document);
            } else if (props.document != null && props.document._id == null){
                let docRes = await axios.get('/api/documentrecord/' + props.document);
                setDocRecord(docRes.data);
            }
            if(props.document != null && props.document !== ''){
                let docId = props.document._id != null ? props.document._id : props.document;
                let docData = await axios.get('/api/document/' + docId);
                pdfjs.GlobalWorkerOptions.workerSrc = new URL(
                    'pdfjs-dist/build/pdf.worker.min.mjs',
                    import.meta.url,
                  ).toString();
                setDocumentData(docData.data);
            }
        }
        fetchData();
    }, [props.document]);

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };

    const prevClick = (e) => {
        setPageNumber(pageNumber - 1);
    }

    const nextClick = (e) => {
        setPageNumber(pageNumber + 1);
    }

    const documentClick = (e) => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        let linkSource = null;
        if(props.document.type === 'text/csv'){
             linkSource = 'data:application/octet-stream;base64,' + documentData;
        } else {
            linkSource = 'data:application/octet-stream;base64,' + documentData;
        }
        let link = document.createElement("a");
        link.download = props.document.name;
        link.href = linkSource;
        link.click();
    }

    return(
        <Aux>
            {docRecord != null && docRecord.thumbnail == null &&
                <Aux>
                     <Document file={'data:application/octet-stream;base64,' + documentData} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber}/>
                    </Document>
                    <br/>
                    {numPages != null && numPages > 1 &&
                        <Grid container spacing={3}>
                            <Grid item sm={12}>
                                {pageNumber > 1 &&
                                    <Button variant="contained" color="secondary" size="small" onClick={prevClick}>{'<'}</Button>
                                }
                                &nbsp;&nbsp;{pageNumber} of {numPages}&nbsp;&nbsp;
                                {pageNumber < numPages &&
                                    <Aux>
                                        <Button variant="contained" color="secondary" size="small" onClick={nextClick}>{'>'}</Button>
                                        &nbsp;&nbsp;
                                    </Aux>
                                }
                                <Button variant="contained" color="secondary" size="small" onClick={documentClick}>{t('download')}</Button>
                            </Grid>
                        </Grid>
                    }
                </Aux>
            }
            {docRecord != null && docRecord.thumbnail != null &&
                <img src={"data:application/octet-stream;base64," + documentData} alt={docRecord.name}
                height="auto" width="100%" />
            }
            <br/>
        </Aux>
    )
}

export default DocumentDisplay;