import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
// import isEmpty from '../../is-empty';
import { withTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { NumericFormat } from 'react-number-format';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import common from '../../jsons/common.json';
import Paper from '@mui/material/Paper';
import Aux from '../../hoc/Auxilary/Auxilary';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import Panel from '../UI/Panel/Panel';
import Link from '../UI/Link/Link';
import ActionBar from '../UI/Buttons/ActionBar';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ValueHelper from '../../helpers/valueHelper';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { CSVDownload } from "react-csv";
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ConversionHelper from '../../helpers/conversionHelper';

class PastInventory extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            record: {},
            items: [],
            allItems: [],
            filteredItems: [],
            listSize: 10,
            orderBy: 'item',
            ordDir: 1,
            offset: 0,
            rowsPerPage: 10,
            page: 0,
            category: '',
            categorySel: null,
            categories: [],
            hcCategory: '',
            hcCategorySel: null,
            hcCategories: [],
            csvSeparator: ',',
            csvData: null,
            csvHeaders: null
        };
    }
    
    componentDidMount = async () => {
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.itemCategory, common.cacheValues.hcReportCategory],
            this.props.auth.constants, null, this.props.auth.user.tenant
        );
        let categories = [];
        for(let row of cacheValues.itemCategories){
            categories.push({value: row.label, label: row.label});
        }
        let hcCategories = [];
        for(let row of cacheValues.hcReportCategories){
            hcCategories.push({value: row.label, label: row.label});
        }
        const crumbs = [
            { path: '/#/pastinvetories', label: this.props.t('pastInventoryList'), screen: this.props.auth.screenDefs.PastInventoryList},
            { path: 'active', label: this.props.t('pastInventory'), screen: this.props.auth.screenDefs.PastInventory}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        this.setState({
            security: this.props.permission,
            categories: categories,
            hcCategories: hcCategories,
            csvSeparator: sessionStorage.getItem('csvSeparator')
        }, () => {            
            this.loadRecord();
        });
    }

    loadRecord = async () => {
        let result = await axios.get('/api/tenanttotal/' + this.props.match.params.id);
        this.setState({
            _id: this.props.match.params.id,
            record: result.data.record,
            items: result.data.items,
            allItems: result.data.items,
            filteredItems: result.data.items
        }, () => {
            const crumbs = [
                { path: '/#/pastinventories', label: this.props.t('pastInventoryList'), screen: this.props.auth.screenDefs.PastInventoryList},
                { path: 'active', label: this.props.t('pastInventory') + ' - ' + result.data.record.number, screen: this.props.auth.screenDefs.PastInventory}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.setList();
        });
    }

    setList = () => {
        let allItems = this.state.allItems;
        let ordDir = this.state.ordDir;
        let orderBy = this.state.orderBy;
        allItems.sort((a, b) => {
            let sortVal = 0;
            if(orderBy === 'item'){
                if(a.item.number > b.item.number)
                   sortVal = -1;
                else if(a.item.number < b.item.number)
                   sortVal = 1;
            } else if (orderBy === 'quantity'){
                if(a.quantity > b.quantity)
                   sortVal = -1;
                else if(a.quantity < b.quantity)
                   sortVal = 1;
            } else if(orderBy === 'category'){
                if(a.category > b.category)
                   sortVal = -1;
                else if(a.category < b.category)
                   sortVal = 1;
            } else if(orderBy === 'hcCategory'){
                if(a.hcCategory > b.hcCategory)
                   sortVal = -1;
                else if(a.hcCategory < b.hcCategory)
                   sortVal = 1;
            } else if(orderBy === 'count'){
                if(a.count > b.count)
                   sortVal = -1;
                else if(a.count < b.count)
                   sortVal = 1;
            } else if(orderBy === 'thc'){
                if(a.thc > b.thc)
                   sortVal = -1;
                else if(a.thc < b.thc)
                   sortVal = 1;
            } else if (orderBy === 'cannabis'){
                if(a.cannabis > b.cannabis)
                   sortVal = -1;
                else if(a.cannabis < b.cannabis)
                   sortVal = 1;
            } else if (orderBy === 'packages'){
                if(a.packages > b.packages)
                   sortVal = -1;
                else if(a.packages < b.packages)
                   sortVal = 1;
            } else if (orderBy === 'lots'){
                if(a.lots > b.lots)
                   sortVal = -1;
                else if(a.lots < b.lots)
                   sortVal = 1;
            }
            if(ordDir < 0)
                return sortVal;
            else
                return sortVal * -1;
        });
        let filteredItems = this.state.category != null && this.state.category !== '' ? allItems.filter(x => x.category === this.state.category) : 
                this.state.hcCategory != null && this.state.hcCategory !== '' ? allItems.filter(x => x.hcCategory === this.state.hcCategory) : allItems;

        let list = filteredItems.slice(this.state.offset, this.state.offset + this.state.listSize);
        this.setState({items: list, filteredItems: filteredItems});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({[name]: newValue, [data]: newValue != null ? newValue.value : null, page: 0, offset: 0}, () => {
            this.setList();
        });
    }

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.setList();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), offset: 0}, () => {
            this.setList();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: this.state.listSize * newPage}, () => {
            this.setList();
        });
    };

    csvClick = () => {
        const t = this.props.t;
        this.setState({csvData: null, csvHeaders: null}, () => {
            let filteredItems = this.state.filteredItems;
            let csvData = [];
            for(let row of filteredItems){
                csvData.push({
                    itemNumber: row.item.number,
                    item: row.item.name,
                    quantity: ConversionHelper.round2(row.quantity),
                    uom: row.uom,
                    category: row.category,
                    hcCategory: row.hcCategory,
                    count: Math.round(row.count),
                    thc: ConversionHelper.round2(row.thc),
                    cannabis: ConversionHelper.round2(row.cannabis),
                    packages: Math.round(row.packages),
                    lots: row.lots,
                    date: row.date
                });
            }
            let headers = [
                {label: t('itemNumber'), key: 'itemNumber'},
                {label: t('item'), key: 'item'},
                {label: t('quantity'), key: 'quantity'},
                {label: t('uom'), key: 'uom'},
                {label: t('category'), key: 'category'},
                {label: t('reportCategory'), key: 'hcCategory'},
                {label: t('count'), key: 'count'},
                {label: t('thc'), key: 'thc'},
                {label: t('cannabis'), key: 'cannabis'},
                {label: t('packages'), key: 'packages'},
                {label: t('lots'), key: 'lots'},
                {label: t('date'), key: 'date'}
            ];
            this.setState({csvData: csvData, csvHeaders: headers});
        });
    }

    lotCsv = async () => {
        this.setState({csvData: null, csvHeaders: null}, async () => {
            let result = await axios.get('/api/tenanttotallots/' + this.state._id);
            this.setState({csvData: result.data.data, csvHeaders: result.data.headers});
        });
    }

    render() {
        const { t } = this.props;
        return (
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl data-cy='number'>
                                <FormLabel>{t('number')}</FormLabel>
                                {this.state.record.number}
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl data-cy='date'>
                                <FormLabel>{t('date')}</FormLabel>
                                    <DateDisplay value={this.state.record.date} format={this.props.auth.user.dateFormat} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl data-cy='created'>
                                <FormLabel>{t('created')}</FormLabel>
                                    <DateDisplay value={this.state.record.created} format={this.props.auth.user.dateFormat} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl data-cy='type'>
                                <FormLabel>{t('type')}</FormLabel>
                                    {this.state.record.usePostingDate ? t('postingDate') : t('lastUpdated')}
                            </FormControl>
                        </Grid>
                    </Grid> 
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                            value={this.state.categorySel}
                            options={this.state.categories}
                            onChange={this.changeAuto('categorySel', 'category')}
                            label={t('category')}
                        />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                                value={this.state.hcCategorySel}
                                options={this.state.hcCategories}
                                onChange={this.changeAuto('hcCategorySel', 'hcCategory')}
                                label={t('reportCategory')}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Past Inventory">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('item')} active={this.state.orderBy === 'item'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>
                                            {t('item')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('quantity')} active={this.state.orderBy === 'quantity'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>
                                            {t('quantity')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('uom')}</TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('category')} active={this.state.orderBy === 'category'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>
                                            {t('category')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('hcCategory')} active={this.state.orderBy === 'hcCategory'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>
                                            {t('reportCategory')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel onClick={() => this.sortClick('count')}
                                        active={this.state.orderBy === 'count'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('count')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel onClick={() => this.sortClick('thc')}
                                        active={this.state.orderBy === 'thc'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('thc')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel onClick={() => this.sortClick('cannabis')}
                                        active={this.state.orderBy === 'cannabis'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('cannabis')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel onClick={() => this.sortClick('packages')}
                                        active={this.state.orderBy === 'packages'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('packages')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('lots')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.items.map((item, index) => 
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Link to={'/item/' + item.item._id}>{item.item.number + '-' + item.item.name}</Link>
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumericFormat displayType={'text'} value={item.quantity} thousandSeparator={true}
                                            decimalScale={2} fixedDecimalScale={true}/>
                                        </TableCell>
                                        <TableCell>
                                            {item.uom != null ? item.uom : ''}
                                        </TableCell>
                                        <TableCell>
                                            {item.category}
                                        </TableCell>
                                        <TableCell>
                                            {item.hcCategory}
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumericFormat displayType={'text'} value={item.count} thousandSeparator={true}
                                            decimalScale={0}/>
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumericFormat displayType={'text'} value={item.thc} thousandSeparator={true}
                                            decimalScale={2}/>
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumericFormat displayType={'text'} value={item.cannabis} thousandSeparator={true}
                                            decimalScale={2}/>
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumericFormat displayType={'text'} value={item.packages} thousandSeparator={true}
                                            decimalScale={0}/>
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumericFormat displayType={'text'} value={item.lots} thousandSeparator={true}
                                            decimalScale={0}/>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="transFoot">
                                    {this.state.filteredItems != null && this.state.filteredItems.length > 0 &&
                                        <Aux>
                                            <Tooltip title={t('exportToCsv')} >
                                                <IconButton
                                                    disabled={false}
                                                    component={'div'}
                                                    onClick={this.csvClick}
                                                    name='actionCsv'
                                                    color="primary"
                                                >
                                                    <ExcelIcon fontSize='medium'/>
                                                    </IconButton>
                                            </Tooltip>
                                            <Tooltip title={t('exportToCsv')} >
                                                <Button
                                                    disabled={false}
                                                    component={'div'}
                                                    onClick={this.lotCsv}
                                                    name='lotCsv'
                                                    color="primary"
                                                >
                                                    {t('lots')}
                                                </Button>
                                            </Tooltip>
                                            <TablePagination count={this.state.filteredItems.length}
                                            rowsPerPage={this.state.listSize}
                                            onPageChange={this.handleChangePage}
                                            page = {this.state.page}
                                            rowsPerPageOptions={common.rowOptions}
                                            onRowsPerPageChange={this.handleRowsPerPage}/>
                                        </Aux>
                                    }
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    {this.state.csvData != null &&
                        <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.csvHeaders} separator={this.state.csvSeparator}/>
                    }
                </Panel>
            </Aux>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile
});

export default withTranslation() (connect(mapStateToProps)(PastInventory));