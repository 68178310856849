import React from 'react';
import ConversionHelper from '../../../helpers/conversionHelper';
import ReportTitle from '../../UI/Typography/ReportTitle';
import ReportSectionTitle from '../../UI/Typography/ReportSectionTitle';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';
import ActivityRecord from './ActivityRecord';

const boxStyle = {
    margin: '8px',
    width: '100%'
}

const thStyle = {
    borderBottom: '2px solid rgb(224, 224, 224)', 
    fontSize: '9pt', 
    textAlign: 'center'
}

const tdStyle = { 
    border: '0.5px solid rgb(224, 224, 224)', 
    fontSize: '8pt', 
    padding: '5px', 
    textAlign: 'center' 
}

export default function cultivationBatchActivityTemplate(props) {
    const { batchNumber, dateFormat, record, t } = props;
    let title = '';
    if(record == null){
        return;
    }
    if(record.sequence != null && record.sequence !== ''){
        title += record.sequence + '. ';
    }
    if(record.definition != null && record.definition !== ''){
        title += record.definition.description;
    }else {
        title += t('cultivationActivity');
    }

    return (
        <div style={{ width: '8.5in', pageBreakBefore: 'always' }}>
            <div style={boxStyle}>
                <ReportTitle 
                    title={title} 
                    description={record.description != null && record.description !== '' ? record.description : null}
                />
            </div>
            <div style={boxStyle}>
                <table style={{ width: '800px' }}>
                    <thead>
                        <tr>
                            <th style={thStyle}>{t('batch')}</th>
                            <th style={thStyle}>{t('activity')}</th>
                            <th style={thStyle}>{t('status')}</th>
                            <th style={thStyle}>{t('date')}</th>
                            <th style={thStyle}>{t('effortM')}</th>
                            <th style={thStyle}>{t('completed')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={tdStyle}>
                                {batchNumber != null && batchNumber !== '' ? batchNumber : 
                                 record.batch != null && record.batch !== '' ? record.batch.number : t('na')}
                            </td>
                            <td style={tdStyle}>
                                {record.number != null && record.number !== '' ? record.number : t('na')}
                            </td>
                            <td style={tdStyle}>
                                {record.status != null && record.status !== '' ? record.status.label : t('na')}
                            </td>
                            <td style={tdStyle}>
                                {record.completedDate != null ?
                                    <DateDisplay value={record.completedDate} format={dateFormat}/> : t('na')
                                }
                            </td>
                            <td style={tdStyle}>
                                {record.effort != null && record.effort !== '' ? record.effort : t('na')}
                            </td>
                            <td style={tdStyle}>
                                {record.quantityCompleted != null && record.quantityCompleted !== '' ? record.quantityCompleted : t('na')}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={boxStyle}>
                <ReportSectionTitle title={t('roomsCompleted')} />
                <table style={{ borderCollapse: 'collapse', width: '100%' }} >
                    <thead>
                        <tr>
                            <th style={thStyle}>{t('item')}</th>
                            <th style={thStyle}>{t('room')}</th>
                            <th style={thStyle}>{t('count')}</th>
                            <th style={thStyle}>{t('quantity')}</th>
                            <th style={thStyle}>{t('effortM')}</th>
                            <th style={thStyle}>{t('averageM')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {record.roomsCompleted != null && record.roomsCompleted.length > 0 ?
                            record.roomsCompleted.map((room, i) =>
                                <tr>
                                    <td style={tdStyle}>
                                        {room.item != null && room.item !== '' ? room.item.name : t('na')}
                                    </td>
                                    <td style={tdStyle}>
                                        {room.room != null && room.room !== '' ? room.room.name : t('na')}
                                    </td>
                                    <td style={tdStyle}>
                                        {room.count != null && room.count !== '' ? room.count : t('na')}
                                    </td>
                                    <td style={tdStyle}>
                                        {room.quantityCompleted != null && room.quantityCompleted !== '' ? 
                                            room.quantityCompleted : t('na')
                                        }
                                    </td>
                                    <td style={tdStyle}>
                                        {room.effort != null && room.effort !== '' ? room.effort : t('na')}
                                    </td>
                                    <td style={tdStyle}>
                                        {room.quantityCompleted != null && room.quantityCompleted !== '' &&
                                         room.effort != null && room.effort !== '' ? 
                                            (parseFloat(room.effort) / parseFloat(room.quantityCompleted)) : t('na')
                                        }
                                    </td>
                                </tr>
                            ) :
                            <tr>
                                <td colSpan={6} style={tdStyle}>{t('none')}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            {record.harvestedRooms != null && record.harvestedRooms.length > 0 &&
                <div style={boxStyle}>
                    <ReportSectionTitle title={t('plantsHarvested')} />
                    <table style={{ borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <tr>
                                <th style={thStyle}>{t('room')}</th>
                                <th style={thStyle}>{t('count')}</th>
                                <th style={thStyle}>{t('remaining')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {record.harvestedRooms.map((room, i) =>
                                <tr>
                                    <td style={tdStyle}>
                                        {room.room != null && room.room !== '' ? room.room.name : t('na')}
                                    </td>
                                    <td style={tdStyle}>
                                        {room.ledger != null && room.ledger !== '' ? room.ledger.amount : t('na')}
                                    </td>
                                    <td style={{ ...tdStyle, fontStyle: 'bold' }}>
                                        {room.ledger != null && room.ledger !== '' ? room.ledger.currentAmount : t('na')}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            }
            <div style={boxStyle}>
                <ReportSectionTitle title={t('performedBy')} />
                {record.users != null && record.users !== '' && record.users.length > 0 ?
                    <div style={{ fontSize: '9pt', padding: '5px' }}>
                        {record.users.map((user, i) => {
                            if(i === record.users.length - 1)
                                return user.name
                            else
                                return user.name + ', '
                            })
                        }
                    </div> : ''                             
                }
            </div>
            <div style={{ width: '100%', padding: '16px' }}>
                <hr style={{ width: '80%', margin: '0 auto' }} />
            </div>
            {record.hasCannabis != null && record.hasCannabis !== '' && record.hasCannabis === true &&
                <div style={boxStyle}>
                    <ReportSectionTitle title={t('cannabisItemSummary')} />
                    <table style={{ borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <tr>
                                <th style={thStyle}>{t('item')}</th>
                                <th style={thStyle}>{t('reportCategory')}</th>
                                <th style={thStyle}>{t('containerType')}</th>
                                <th style={thStyle}>{t('total')}</th>
                                <th style={thStyle}>{t('containers')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {record.cannabisInputTotals != null && record.cannabisInputTotals.length > 0 ?
                                record.cannabisInputTotals.map((input, i) => {
                                    return input.typeContainers != null && input.typeContainers.length > 0 ?
                                        input.typeContainers.map((type, j) => {
                                            const typeLabel = Object.keys(type)[0];
                                            return (
                                                <tr>
                                                    <td style={tdStyle}>
                                                        {input.item != null && input.item !== '' ? input.item : t('na')}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {input.category != null && input.category !== '' ? input.category : t('na')}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {typeLabel != null && typeLabel !== '' ? typeLabel : t('na')}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {input.typeTotals[j][typeLabel] != null && input.typeTotals[j][typeLabel] !== '' &&
                                                         input.uom != null && input.uom !== '' ?
                                                            input.typeTotals[j][typeLabel] + input.uom : t('na')
                                                        }
                                                    </td>
                                                    <td style={tdStyle}>
                                                        { type != null && type !== '' ? type[typeLabel] : t('na') }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    ) : null
                                }) : null
                            }
                            {record.cannabisOutputTotals.length != null && record.cannabisOutputTotals.length > 0 ?
                                record.cannabisOutputTotals.map((output, i) => {
                                    return output.typeContainers != null && output.typeContainers.length > 0 ?
                                    output.typeContainers.map((type, j) => 
                                        <tr>
                                            <td style={tdStyle}>
                                                {output.item != null && output.item !== '' ? output.item : t('na')}
                                            </td>
                                            <td style={tdStyle}>
                                                {output.category != null && output.category !== '' ? output.category : t('na')}
                                            </td>
                                            <td style={tdStyle}>
                                                {type != null && type !== '' ? Object.keys(output.typeContainers[j])[0] : t('na')}
                                            </td>
                                            <td style={tdStyle}>
                                                {output.typeTotals != null && output.typeTotals.length === output.typeContainers.length ?
                                                    Object.values(output.typeTotals[j])[0] : t('na')
                                                }
                                            </td>
                                            <td style={tdStyle}>
                                                {output.typeContainers != null && output.typeContainers.length === output.typeTotals.length ?
                                                    Object.values(output.typeContainers[j])[0] : t('na')
                                                }
                                            </td>
                                        </tr>
                                    ) : null
                                }) : null
                            }
                        </tbody>
                    </table>
                </div>
            }
            {record.hasCannabis != null && record.hasCannabis !== '' && record.hasCannabis === true &&
                <div style={boxStyle}>
                    <ReportSectionTitle title={t('cannabisInputs')} />
                    <table style={{ borderCollapse: 'collapse', width: '100%'}} >
                        <thead>
                            <tr>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '125px', textAlign: 'center' }}><strong>{t('item')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '100px', textAlign: 'center' }}><strong>{t('lot')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '100px', textAlign: 'center' }}><strong>{t('container')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('quantity')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('count')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('discreteWeightG')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('totalWeightG')}</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {record.cannabisInputs != null && record.cannabisInputs.length > 0 ?
                                record.cannabisInputs.map((input, i) => 
                                    <tr key={`cannabisInputRow-${i}`} >
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                            {input.item != null && input.item !== '' ? input.item : ''}
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                            {input.lot != null && input.lot !== '' ? 
                                                input.lot : ''
                                            }    
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                            {input.container != null && input.container !== '' ?
                                                input.container : ''
                                            }
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                            {input.amount != null && input.amount !== '' && input.uom != null && input.uom !== '' ? 
                                                input.amount + input.uom : ''
                                            }
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                            {input.count != null && input.count !== '' ? input.count : t('na')}
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                            {input.discreteWeight != null && input.discreteWeight !== '' ? input.discreteWeight : t('na')}
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                            {input.fullDiscreteWeight != null && input.fullDiscreteWeight !== '' ? input.fullDiscreteWeight : t('na')}
                                        </td>
                                    </tr>
                                ) :
                                <tr key={'cannabisInputRow-noData'}>
                                    <td colSpan={7} style={{ textAlign: 'center', border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px' }}>{t('none')}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            }
            {record.hasCannabis != null && record.hasCannabis !== '' && record.hasCannabis === true &&
                <div style={boxStyle}>
                    <ReportSectionTitle title={t('cannabisOutputs')} />
                    <table style={{ borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <tr>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '60px', textAlign: 'center' }}><strong>{t('type')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '150px', textAlign: 'center' }}><strong>{t('item')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '100px', textAlign: 'center' }}><strong>{t('lot')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '100px', textAlign: 'center' }}><strong>{t('container')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('quantity')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('count')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('discreteWeightG')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('totalWeightG')}</strong></th>
                            </tr>                            
                        </thead>
                        <tbody>
                            {record.cannabisOutputs != null && record.cannabisOutputs.length > 0 ?
                                record.cannabisOutputs.map((output, i) => 
                                <tr key={'cannabisOutputs-' + i}>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                        {output.containerType != null && output.containerType !== '' ?
                                            output.containerType : ''
                                        }
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                        {output.item != null && output.item !== '' ?
                                            output.item : ''
                                        }    
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                        {output.lot != null && output.lot !== '' ?
                                            output.lot : ''
                                        }
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                        {output.container != null && output.container !== '' ?
                                            output.container : ''
                                        }
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                        {output.amount != null && output.amount !== '' && output.uom != null && output.uom !== '' ?
                                            output.amount + output.uom : ''
                                        }
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                        {output.count != null && output.count !== '' ? output.count : t('na')}
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                        {output.discreteWeight != null && output.discreteWeight !== '' ? output.discreteWeight : t('na')}
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                        {output.totalWeight != null && output.totalWeight !== '' ? output.totalWeight : t('na')}
                                    </td>
                                </tr>
                            ) :
                                <tr>
                                    <td colSpan={8} style={{ textAlign: 'center', border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px' }}>{t('none')}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            }
            {record.hasCannabis != null && record.hasCannabis !== '' && record.hasCannabis === true &&
                <div style={{ width: '100%', padding: '16px' }}>
                    <hr style={{ width: '80%', margin: '0 auto' }} />
                </div>
            }
            <div style={boxStyle}>
                <ReportSectionTitle title={record.hasCannabis != null && record.hasCannabis !== '' && record.hasCannabis === true ?
                    t('otherInputs') : t('inputs')
                }/>
                <table style={{ borderCollapse: 'collapse', width: '100%'}} >
                    <thead>
                        <tr>
                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '125px', textAlign: 'center' }}><strong>{t('item')}</strong></th>
                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '100px', textAlign: 'center' }}><strong>{t('lot')}</strong></th>
                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '100px', textAlign: 'center' }}><strong>{t('container')}</strong></th>
                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('quantity')}</strong></th>
                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('count')}</strong></th>
                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('vendorLot')}</strong></th>
                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('expiration')}</strong></th>
                        </tr>                            
                    </thead>
                    <tbody>
                        {record.otherInputs != null && record.otherInputs.length > 0 ?
                            record.otherInputs.map((input, i) => 
                                <tr key={`otherInputRow-${i}`}>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                        {input.item != null && input.item !== '' ? 
                                            input.item : t('na')
                                        }
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                        {input.lot != null && input.lot !== '' ? 
                                            input.lot : t('na')
                                        }    
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                        {input.container != null && input.container !== '' ? 
                                            input.container.number : t('na')
                                        }    
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                        {input.amount != null && input.amount !== '' && input.uom != null && input.uom !== '' ? 
                                            input.amount + input.uom : t('na')
                                        }
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                        {input.count != null && input.count !== '' ? input.count : t('na')}
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                        {input.vendorLot != null && input.vendorLot !== '' ? input.vendorLot : t('na')}
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                        {input.expiration != null && input.expiration !== '' ? input.expiration : t('na')}
                                    </td>
                                </tr>                          
                            ) :
                                <tr key={'inputRow-noData'}>
                                    <td colSpan={7} style={{ textAlign: 'center', border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px' }}>{t('none')}</td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div style={boxStyle}>
                <ReportSectionTitle title={record.hasCannabis != null && record.hasCannabis !== '' && record.hasCannabis === true ?
                    t('otherOutputs') : t('outputs')
                } />
                <table style={{ borderCollapse: 'collapse', width: '100%' }} >
                    <thead>
                        <tr>
                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '60px', textAlign: 'center' }}><strong>{t('type')}</strong></th>
                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '150px', textAlign: 'center' }}><strong>{t('item')}</strong></th>
                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '100px', textAlign: 'center' }}><strong>{t('lot')}</strong></th>
                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '100px', textAlign: 'center' }}><strong>{t('container')}</strong></th>
                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('quantity')}</strong></th>
                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('count')}</strong></th>
                        </tr>                            
                    </thead>
                    <tbody>
                        {record.otherOutputs != null && record.otherOutputs.length > 0 ?
                            record.otherOutputs.map((output, i) => 
                            <tr key={'otherOutputs-' + i}>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                    {output.containerType != null && output.containerType !== '' ?
                                        output.containerType : ''}
                                </td>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                    {output.item != null && output.item !== '' && output.item.name != null && output.item.name !== '' ?
                                        output.item.name : ''
                                    }    
                                </td>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                    {output.container != null && output.container !== '' && output.container.lot != null && output.container.lot !== '' && 
                                        output.container.lot.number != null && output.container.lot.number !== '' ?
                                        output.container.lot.number : ''
                                    }
                                </td>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                    {output.container != null && output.container !== '' && output.container.number != null && output.container.number !== '' ?
                                        output.container.number : ''
                                    }
                                </td>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                    {output.amount != null && output.amount !== '' && output.uom != null && output.uom !== '' ?
                                        output.amount + output.uom : ''
                                    }
                                </td>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                    {output.count != null && output.count !== '' ? output.count : t('na')}
                                </td>
                            </tr>
                        ) :
                            <tr>
                                <td colSpan={8} style={{ textAlign: 'center', border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px' }}>{t('none')}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            {record.sops != null && record.sops.length > 0 &&
                record.sops.map((sop, i) => 
                    <div key={'workRecord-' + i}>
                        <ActivityRecord dateFormat={dateFormat} record={sop.workRecord} sop={sop.sop} t={t} 
                        sequence={record.sequence != null && record.sequence !== '' ? 
                            record.sequence + '.' + ConversionHelper.numberToLetterConverter(i + 1) : ConversionHelper.numberToRomanConverter(i + 1)
                        } />
                    </div>    
                )
            }
        </div>
    )
}