import { createTheme } from '@mui/material/styles';
//Evora Palette
//Primary: #484A9A
//Secondary: #5BC6CC
//Green: #00AC89
//Yellow: #CBDB2F

const theme = createTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#484A9A', //Evora Colour #1: Purple
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: '#0066ff',
        main: '#5BC6CC', //Evora Colour #2: Teal
        // dark: will be calculated from palette.secondary.main,
        // contrastText: '#ffcc00',
      },
      // error: {
      //   light: '#e57373',
      //   main: '#f44336',
      //   dark: '#d32f2f',
      // },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    content: {
      flexGrow: 1,
      padding: '0 25px',
    },
    typography: {
      fontFamily: [
        'Open Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h3: {
        fontSize: '2.8rem',
        fontWeight: '500',
        color: '#00AC89',
        marginBottom: 10
      },
      h4: {
        color: '#00AC89',
        overflow: 'auto', 
        marginTop: '5px', 
        marginLeft: '7px',
        display: 'inline'
      },
      h5: {
        color: '#00AC89',
        display: 'inline'
      },
      h6: {
        color: '#00AC89',
        display: 'inline'
      },
      body1: {
        fontSize: '0.9rem',
      },
      body2: {
        fontSize: '0.8rem',
      }
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: "#db3131",
            "&$error": {
              color: "#db3131",
            },
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
          fontSize: '0.8rem'         
        }
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 600,
            borderBottom: '2px solid rgba(224, 224, 224, 1)',
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          selectLabel: {
            marginTop: 'auto'
          },
          displayedRows: {
            marginTop: 'auto'
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
              {
                display: 'none',
              },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: '0.85rem',
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            fontSize: '0.8rem',
          },
        },
      },
      MuiGridItem: {
        styleOverrides: {
          root: {
            fontSize: '0.8rem',
          },
        },
      }
    }
});

export default theme;