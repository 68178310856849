import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import dateFormat from 'date-fns/format';
import ActionBar from '../UI/Buttons/ActionBar';
import ConversionHelper from '../../helpers/conversionHelper';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import { CSVDownload } from "react-csv";
import listHelper from '../../helpers/listHelper';
import ListMultiSelect from '../UI/ListMultiSelect/ListMultiSelect';

const BatchActivities = (props) => {
    const isFirstRender = useRef(true);
    const [loadRefData, setLoadRefData] = useState(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [columnOrder, setColumnOrder] = useState(['number', 'status', 'batch', 'completedDate', 'definition', 'strain']);
    const [values, setValues] = useState({users: [], strains: [], statuses: [], batches: [], definitions: []});
    const [csvOutput, setCsvOutput] = useState({headers: null, data: null});
    const statuses = props.auth.constants.statuses;
    const csvSeparator = sessionStorage.getItem('csvSeparator');
    const { t } = useTranslation('translation');
    const tenant = props.auth.user.tenant;
    const [statusesSel, setStatusesSel] = useState({ids: [], values: []});
    const [showSelect, setShowSelect] = useState(false);
    const crumbs = [
        { path: 'active', label: t('batchActivities'), screen: props.auth.screenDefs.BatchActivities}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        async function fetchData(){
            const columnFilters = await listHelper.getItem('BatchActivitiesColumnFilters', tenant);
            const columnVisibility = await listHelper.getItem('BatchActivitiesColumnVisibility', tenant);
            const density = await listHelper.getItem('BatchActivitiesDensity', tenant);
            const globalFilter = await listHelper.getItem('BatchActivitiesGlobalFilter', tenant);
            const showGlobalFilter = await listHelper.getItem('BatchActivitiesShowGlobalFilter', tenant);
            const showColumnFilters = await listHelper.getItem('BatchActivitiesShowColumnFilters', tenant);
            const sorting = await listHelper.getItem('BatchActivitiesSorting', tenant);
            const pagination = await listHelper.getItem('BatchActivitiesPagination', tenant);
            const tempColumnOrder = await listHelper.getItem('BatchActivitiesColumnOrder', tenant);
            if (columnFilters) {
                let filters = ConversionHelper.filtersDateParse(JSON.parse(columnFilters));
                let status = filters.find(x => x.id === 'status');
                if(status != null){
                    let sel = {ids: status.value, values: []};
                    status.value.forEach(id => {
                        let val = values.statuses.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setStatusesSel(sel);
                }
                setColumnFilters(filters);
            }
            if (columnVisibility) {
                setColumnVisibility(columnVisibility);
            }
            if (density) {
                setDensity(density);
            }
            if (globalFilter) {
                setGlobalFilter(globalFilter);
            }
            if (showGlobalFilter) {
                setShowGlobalFilter(showGlobalFilter);
            }
            if (showColumnFilters) {
                setShowColumnFilters(showColumnFilters);
            }
            if (sorting) {
                setSorting(sorting);
            }
            if (pagination) {
                setPagination(pagination);
            }
            if (tempColumnOrder) {
                setColumnOrder(tempColumnOrder);
            } else {
                setColumnOrder(['number', 'status', 'feedingRecipe', 'date', 'lot', 'item', 'expirationDate']);
            }
            isFirstRender.current = false;
        }
        if(isFirstRender.current){
            fetchData();
        }
    }, [values, statuses, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('BatchActivitiesColumnFilters', tenant, JSON.stringify(columnFilters));
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('BatchActivitiesColumnVisibility', tenant, columnVisibility);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnVisibility, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('BatchActivitiesDensity', tenant, density);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [density, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('BatchActivitiesGlobalFilter', tenant, globalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [globalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('BatchActivitiesShowGlobalFilter', tenant, showGlobalFilter);
        }
        if (isFirstRender.current) return;
        fetchData()
    }, [showGlobalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('BatchActivitiesShowColumnFilters', tenant, showColumnFilters);
        }
        if (isFirstRender.current) return;
        fetchData();

    }, [showColumnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('BatchActivitiesSorting', tenant, sorting);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [sorting, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('BatchActivitiesPagination', tenant, pagination);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [pagination, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('BatchActivitiesColumnOrder', tenant, columnOrder);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnOrder, tenant]);

    const resetState = () => {
        listHelper.removeItem('BatchActivitiesColumnFilters', tenant);
        listHelper.removeItem('BatchActivitiesColumnVisibility', tenant);
        listHelper.removeItem('BatchActivitiesDensity', tenant);
        listHelper.removeItem('BatchActivitiesGlobalFilter', tenant);
        listHelper.removeItem('BatchActivitiesShowGlobalFilter', tenant);
        listHelper.removeItem('BatchActivitiesShowColumnFilters', tenant);
        listHelper.removeItem('BatchActivitiesSorting', tenant);
        listHelper.removeItem('BatchActivitiesPagination', tenant);
        listHelper.removeItem('BatchActivitiesColumnOrder', tenant);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.strain, common.cacheValues.batchActivityDef, common.cacheValues.user, common.cacheValues.cultivationBatch], 
                props.auth.constants, null, props.auth.user.tenant);
            const statuses = await ValueHelper.getStatusesForObject(props.auth.constants.objectIds.batchActivity, props.auth.user.tenant);
            let status = columnFilters != null ? columnFilters.find(x => x.id === 'status') : null;
            if(status != null){
                let sel = {ids: status.value, values: []};
                status.value.forEach(id => {
                    let val = statuses.find(x => x.value === id);
                    sel.values.push(val);
                });
                setStatusesSel(sel);
            }
            setValues({
                strains: cacheValues.strains,
                batches: cacheValues.batches,
                statuses: statuses,
                users: cacheValues.users,
                definitions: cacheValues.batchActivityDefs
            });
            setLoadRefData(false);
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, columnFilters, loadRefData]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'BatchActivities',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/batchactivities', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });

    let columns = useMemo(() => [
        {
            accessorKey: 'number',
            id: 'number',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/batchactivity/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            )
        },
        {
            accessorKey: 'status',
            id: 'status',
            header: t('status'),
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='status'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.statuses} 
                    setSelected={setStatusesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={statusesSel.ids} 
                    selectedSel={statusesSel.values}
                    screen='BatchActivities'
                    tenant={props.auth.user.tenant}
                    columnFilters={columnFilters}/>
                </Box>
            ),
        },
        {
            accessorKey: 'batch',
            id: 'batch',
            header: t('batch'),
            filterVariant: 'select',
            filterSelectOptions: values.batches
        },
        {
            accessorKey: 'completedDate',
            id: 'completedDate',
            header: t('date'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() != null ? dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat) : ''}
                </Box>
            )
        },
        {
            accessorKey: 'definition',
            id: 'definition',
            header: t('activity'),
            filterVariant: 'select',
            filterSelectOptions: values.definitions
        }, 
        {
            accessorKey: 'strain',
            id: 'strain',
            header: t('strain'),
            filterVariant: 'select',
            filterSelectOptions: values.strains
        },
        {
            accessorKey: 'effort',
            id: 'effort',
            header: t('effort'),
            enableColumnFilter: false,
            enableSorting: false
        },
        {
            accessorKey: 'quantityCompleted',
            id: 'quantityCompleted',
            header: t('completed'),
            enableColumnFilter: false,
            enableSorting: false
        }
    ], [values, t, props, columnFilters, statusesSel, showSelect]);

    const csv = async () => {
        setCsvOutput({headers: null, data: null});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axios.get('/api/batchactivitiescsv', {params: params});
        let headers = response.data.headers;
        let csvData = response.data.data;
        setCsvOutput({headers: headers, data: csvData});
    }

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true,  columnVisibility: {id: false}},
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: false,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()} color="primary">
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('csv')}>
                    <IconButton onClick={() => csv()}>
                        <ExcelIcon color='primary'/>
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('clearListSettings')}>
                    <IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs}>
                <ActionBar/>
            </BreadcrumbBar>
            {csvOutput != null && csvOutput.data != null &&
                <CSVDownload data={csvOutput.data} target="_blank" headers={csvOutput.headers} separator={csvSeparator}/>
            }
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
        </Aux>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(BatchActivities);