import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField'
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class ScheduledActivity extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            activity: '',
            activities: [],
            sop: '',
            sops: [],
            assignee: '',
            users: [],
            date: new Date(),
            endDate: null,
            isSop: false,
            note: '',
            entities: [],
            entity: '',
            complete: false,
            recordId: '',
            params: {},
            batch: '',
            batches: [],
            lot: '',
            lots: [],
            productionOrder: '',
            productionOrders: [],
            routing: '',
            routingSel: null,
            routings: [],
            equipment: '',
            equipments: [],
            location: '',
            locations: [],
            room: '',
            rooms: [],
            vendor: '',
            vendors: [],
            locked: false,
            workOrder: '',
            workOrders: [],
            riskAssessmentRequired: false,
            tenant: this.props.auth.user.tenant,
            activitySelected: null,
            locationSelected: null,
            assigneeSelected: null,
            sopSelected: null,
            roomSelected: null,
            batchSelected: null,
            lotSelected: null,
            poSelected: null,
            woSelected: null,
            vendorSelected: null,
            equipmentSelected: null,
            managedDocument: '',
            managedDocuments: [],
            manDocSel: null,
            activityDefinitions: [],
            activityDefinition: '',
            actDefSel: null,
            ebatches: [],
            ebatch: '',
            ebatchSel: null,
            security: [],
            enableSave: true
        };

        this.changeValue = this.changeValue.bind(this);
        this.dateChange = this.dateChange.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.activeLocation, common.cacheValues.routing, common.cacheValues.batchActivityDef,
                            common.cacheValues.object, common.cacheValues.userSop], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
            this.props.auth.user.defaultLocation : '';
        let locSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locSel == null && locations.length === 1){
            locSel = locations[0];
            location = locSel.value;
        }
        this.setState({
            activities: cacheValues.objects,
            locations: locations,
            location: location,
            locationSelected: locSel,
            sops: cacheValues.userSops,
            activityDefinitions: cacheValues.batchActivityDefs,
            routings: cacheValues.routings,
            security: this.props.permission
        }, async () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                const record = await axios.get('/api/schedule/' + this.props.match.params.id);
                if(record.data.recordId != null && record.data.recordId !== '' ){
                    this.performTrans(record.data);
                } else {
                    let isSop = record.data.activity === this.props.auth.constants.objectIds.sopInstance;
                    let locked = record.data.status === this.props.auth.constants.scheduleStatuses.complete;
                    let activitySelected = cacheValues.objects.find(x => x.value === record.data.activity);
                    let locationSelected = locations.find(x => x.value === record.data.location);
                    let sopSelected = cacheValues.userSops.find(x => x.value === record.data.sop);
                    let batchSelected = record.data.batches != null ? record.data.batches.find(x => x.value === record.data.batch) : null;
                    let ebatchSel = record.data.ebatches != null ? record.data.ebatches.find(x => x.value === record.data.ebatch) : null;
                    let lotSelected = record.data.lots != null ? record.data.lots.find(x => x.value === record.data.lot) : null;
                    let poSelected = record.data.productionOrders != null ? record.data.productionOrders.find(x => x.value === record.data.productionOrder) :
                                null;
                    let routingSel = record.data.routing != null ? cacheValues.routings.find(x => x.value === record.data.routing) : null;
                    let vendorSelected = record.data.vendors != null ? record.data.vendors.find(x => x.value === record.data.vendor) : null;
                    let manDocSel = record.data.managedDocument != null ? record.data.managedDocuments.find(x => x.value === record.data.managedDocument) : null;
                    let woSelected = record.data.workOrders != null ? record.data.workOrders.find(x => x.value === record.data.workOrder) : null;
                    let equipmentSelected = record.data.equipments != null ? record.data.equipments.find(x => x.value === record.data.equipment) : null;
                    let roomSelected = record.data.rooms != null ? record.data.rooms.find(x => x.value === record.data.room) : null;
                    let assigneeSelected = record.data.assignee != null ? record.data.users.find(x => x.value === record.data.assignee) : null;
                    let actDefSel = record.data.activityDefinition != null ?
                                        cacheValues.batchActivityDefs.find(x => x.value === record.data.activityDefinition) : null;
                    this.setState({
                        _id: record.data._id,
                        date: record.data.dueDate,
                        activity: record.data.activity,
                        sop: record.data.sop,
                        assignee: record.data.assignee,
                        note: record.data.note,
                        isSop: isSop,
                        users: record.data.users,
                        complete: record.data.complete,
                        recordId: record.data.recordId,
                        batch: record.data.batch,
                        batches: record.data.batches,
                        lot: record.data.lot,
                        lots: record.data.lots,
                        productionOrder: record.data.productionOrder,
                        productionOrders: record.data.productionOrders,
                        location: record.data.location,
                        equipment: record.data.equipment,
                        equipments: record.data.equipments,
                        room: record.data.room,
                        rooms: record.data.rooms,
                        vendor: record.data.vendor,
                        vendors: record.data.vendors,
                        locked: locked,
                        workOrder: record.data.workOrder,
                        workOrders: record.data.workOrders,
                        activitySelected: activitySelected,
                        locationSelected: locationSelected,
                        sopSelected: sopSelected,
                        batchSelected: batchSelected,
                        lotSelected: lotSelected,
                        poSelected: poSelected,
                        vendorSelected: vendorSelected,
                        woSelected: woSelected,
                        equipmentSelected: equipmentSelected,
                        roomSelected: roomSelected,
                        assigneeSelected: assigneeSelected,
                        riskAssessmentRequired: record.data.riskAssessmentRequired != null ? record.data.riskAssessmentRequired : false,
                        managedDocument: record.data.managedDocument,
                        manDocSel: manDocSel,
                        managedDocuments: record.data.managedDocuments,
                        activityDefinition: record.data.activityDefinition,
                        actDefSel: actDefSel,
                        ebatch: record.data.ebatch,
                        ebatchSel: ebatchSel,
                        ebatches: record.data.ebatches,
                        routingSel: routingSel,
                        routing: record.data.routing
                    }, () => {
                        if(this.state.recordId != null && this.state.recordId !== '');
                            this.performClick();
                    });
                }
            }
            const crumbs = [
                { path: '/#/scheduledActivities', label: this.props.t('scheduledActs'), screen: this.props.auth.screenDefs.ScheduledActivity},
                { path: 'active', label: this.props.t('scheduledActivity'), screen: this.props.auth.screenDefs.ScheduledActivity}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    async changeValue(e){
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value});
    }

    changeAutoComplete = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(newValue != null){
            this.processChange(data, newValue.value);
        }
    };

    async processChange(name, value){
        if(name === 'activity'){
            if(value === this.props.auth.constants.objectIds.sopInstance){
                this.setState({isSop: true, users: []});
            } else {
                if(value !== ''){
                    var data = await axios.get('/api/activityvalues/' + value);
                    const users = await axios.get('/api/activityusers/' + value);
                    this.setState({
                        isSop: false,
                        users: users.data,
                        batches: data.data.batches,
                        batch: '',
                        lots: data.data.lots,
                        lot: '',
                        productionOrders: data.data.productionOrders,
                        productionOrder: '',
                        rooms: data.data.rooms,
                        room: '',
                        equipments: data.data.equipments,
                        equipment: '',
                        vendor: '',
                        vendors: data.data.vendors,
                        managedDocument: '',
                        managedDocuments: data.data.documents
                    });
                } else {
                    this.setState({
                        isSop: false,
                        users: [],
                        user: '',
                        batches: [],
                        batch: '',
                        lots: [],
                        lot: '',
                        productionOrders: [],
                        productionOrder: '',
                        rooms: [],
                        room: '',
                        equipments: [],
                        equipment: '',
                        vendor: '',
                        vendors: [],
                        managedDocument: '',
                        managedDocuments: []
                    })
                }

            }
        }
    }

    dateChange(e){
        this.setState({date: e});
    }

    changeRisk = e => {
        this.setState({riskAssessmentRequired: !this.state.riskAssessmentRequired});
    }

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const t = this.props.t;
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        const data = {
            _id: this.state._id,
            date: this.state.date,
            activity: this.state.activity,
            sop: this.state.sop,
            batch: this.state.batch,
            lot: this.state.lot,
            location: this.state.location,
            productionOrder: this.state.productionOrder,
            room: this.state.room,
            equipment: this.state.equipment,
            vendor: this.state.vendor,
            assignee: this.state.assignee,
            note: this.state.note,
            workOrder: this.state.workOrder,
            riskAssessmentRequired: this.state.riskAssessmentRequired,
            managedDocument: this.state.managedDocument
        }
        try {
            var result = await axios.post('/api/schedule', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/scheduledActivity/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    validateForm(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.date == null || state.date === '')
            errors[t('date')] = t('required');
        if(state.activity == null || state.activity === '')
            errors[t('activity')] = t('required');
        else {
            if(state.activity === this.props.auth.constants.objectIds.sopInstance){
                if(state.sop == null || state.sop === '')
                    errors[t('sop')] = t('required');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteClick(){
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick());
    }

    async _deleteClick(){
        const t = this.props.t;
        if(this.state.recordId != null && this.state.recordId !== ''){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('scheduleDeleteCreated'), title: t('warning'), severity: 'warning'}});
        } else {
            if( this.state._id == null || this.state._id === ''){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('scheduleDeleteNoId'), title: 'Warning', severity: 'warning'}});
            } else {
                try {
                    const result = await axios.delete('/api/schedule/' + this.state._id);
                    this.setState({enableSave: true});
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                    this.props.history.goBack();
                } catch(err){
                    this.setState({enableSave: true});
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
                }
            }
        }
    }

    async performClick(){
        let obj = this.state.activities.find(x => x.value === this.state.activity);
        let link = '';
        if(this.state.recordId != null && this.state.recordId !== ''){
            if(this.state.activity === this.props.auth.constants.objectIds.locationInspection){
                link = obj.link + this.state.location + '/' + this.state.recordId + '?schedule=' + this.state._id;
            } else if(this.state.activity === this.props.auth.constants.objectIds.roomSetting){
                link = '/roomsettings/' + this.state.room + '/' + this.state.recordId + '?schedule=' + this.state._id;
            } else{
                link = obj.link + this.state.recordId + '?schedule=' + this.state._id;
            }
        }else{
            if(this.state.activity === this.props.auth.constants.objectIds.equipmentLog){
                link = '/equipmentlog/' + this.state.equipment + '?schedule=' + this.state._id;
            }else if (this.state.activity === this.props.auth.constants.objectIds.locationInspection){
                link = obj.link + this.state.location + '?schedule=' + this.state._id;
            } else if (this.state.activity === this.props.auth.constants.objectIds.roomSetting){
                link = '/roomsettings/' + this.state.room + '?schedule=' + this.state._id;
            } else if (this.state.activity === this.props.auth.constants.objectIds.deviation){
                link = '/deviation?schedule=' + this.state._id;
            } else if (this.state.activity === this.props.auth.constants.objectIds.ncr){
                link = '/ncr?schedule=' + this.state._id;
            } else {
                let url = this.state.activity !== this.props.auth.constants.objectIds.sopInstance ? obj.link : obj.link + 'new/' + this.state.sop;
                link = url + '?schedule=' + this.state._id;
            }

        }
        this.props.history.push(link);
    }

    async performTrans(data){
        let obj = this.state.activities.find(x => x.value === data.activity);
        let link = '';
        if(data.recordId != null && data.recordId !== ''){
            if(data.activity === this.props.auth.constants.objectIds.locationInspection){
                link = obj.link + data.location + '/' + data.recordId + '?schedule=' + data._id;
            } else if(data.activity === this.props.auth.constants.objectIds.roomSetting){
                link = '/roomsettings/' + data.room + '/' + data.recordId + '?schedule=' + data._id;
            } else{
                link = obj.link + data.recordId + '?schedule=' + data._id;
            }
        }else{
            if(data.activity === this.props.auth.constants.objectIds.equipmentLog){
                link = '/equipmentlog/' + data.equipment + '?schedule=' + data._id;
            }else if (data.activity === this.props.auth.constants.objectIds.locationInspection){
                link = obj.link + data.location + '?schedule=' + data._id;
            } else if (data.activity === this.props.auth.constants.objectIds.roomSetting){
                link = '/roomsettings/' + data.room + '?schedule=' + data._id;
            } else if (data.activity === this.props.auth.constants.objectIds.deviation){
                link = '/deviation?schedule=' + data._id;
            } else if (data.activity === this.props.auth.constants.objectIds.ncr){
                link = '/ncr?schedule=' + data._id;
            } else {
                let url = data.activity !== this.props.auth.constants.objectIds.sopInstance ? obj.link : obj.link + 'new/' + data.sop;
                link = url + '?schedule=' + data._id;
            }

        }
        this.props.history.push(link);
    }

    render(){
        const errors = this.props.errors;
        const locked = this.state.locked;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Panel>
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <DateSelect
                            onChange={this.dateChange}
                            value={this.state.date}
                            helperText={errors[t('date')]}
                            error={errors[t('date')] != null ? true : false}
                            disabled={locked}
                            label={t('date')}
                            format={this.props.auth.user.dateFormat}
                            required
                            data-cy='date'
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                            value={this.state.activitySelected}
                            options={this.state.activities}
                            onChange={this.changeAutoComplete('activitySelected', 'activity')}
                            error={errors[t('activity')] != null ? true : false}
                            helperText={errors[t('activity')]}
                            label={t('activity')}
                            disabled={locked || (this.state._id != null && this.state._id !== '')}
                            required
                            data-cy='activity'
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <AutoCompleteField
                            value={this.state.locationSelected}
                            options={this.state.locations}
                            onChange={this.changeAutoComplete('locationSelected', 'location')}
                            error={errors[t('location')] != null ? true : false}
                            helperText={errors[t('location')]}
                            label={t('location')}
                            disabled={locked}
                            data-cy='location'
                        />
                    </Grid>
                    {this.state.isSop &&
                        <Grid item xs={12} sm={6}>
                            <AutoCompleteField
                                value={this.state.sopSelected}
                                options={this.state.sops}
                                onChange={this.changeAutoComplete('sopSelected', 'sop')}
                                error={errors[t('sop')] != null ? true : false}
                                helperText={errors[t('sop')]}
                                label={t('workRecord')}
                                disabled={locked}
                                required
                                data-cy='sop'
                            />
                        </Grid>
                    }
                    {this.state.batches != null && this.state.batches.length > 0 &&
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.batchSelected}
                                options={this.state.batches}
                                onChange={this.changeAutoComplete('batchSelected', 'batch')}
                                error={errors[t('batch')] != null ? true : false}
                                helperText={errors[t('batch')]}
                                label={t('batch')}
                                disabled={locked}
                                data-cy='batch'
                            />
                        </Grid>
                    }
                    {this.state.activity === this.props.auth.constants.objectIds.sopInstance && this.state.workOrders.length > 0 &&
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.woSelected}
                                options={this.state.workOrders}
                                onChange={this.changeAutoComplete('woSelected', 'workOrder')}
                                error={errors[t('workOrder')] != null ? true : false}
                                helperText={errors[t('workOrder')]}
                                label={t('workOrder')}
                                disabled={locked}
                            />
                        </Grid>
                    }
                    {this.state.ebatches != null && this.state.ebatches.length > 0 &&
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.ebatchSel}
                                options={this.state.ebatches}
                                onChange={this.changeAutoComplete('ebatchSel', 'ebatch')}
                                error={errors[t('ebatch')] != null ? true : false}
                                helperText={errors[t('ebatch')]}
                                label={t('batch')}
                                disabled={locked}
                                data-cy='ebatch'
                            />
                    </Grid>
                    }
                    {this.state.ebatches != null &&  this.state.ebatches.length > 0 && this.state.activityDefinitions != null &&
                        this.state.activityDefinitions.length > 0 &&
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.actDefSel}
                                options={this.state.activityDefinitions}
                                onChange={this.changeAutoComplete('actDefSel', 'activityDefinition')}
                                error={errors[t('activityDefinition')] != null ? true : false}
                                helperText={errors[t('activityDefinition')]}
                                label={t('activity')}
                                disabled={locked || (this.state._id != null && this.state._id !== '')}
                                data-cy='activityDef'
                            />
                        </Grid>
                    }
                    {this.state.lots != null && this.state.lots.length > 0 &&
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.lotSelected}
                                options={this.state.lots}
                                onChange={this.changeAutoComplete('lotSelected', 'lot')}
                                error={errors[t('lot')] != null ? true : false}
                                helperText={errors[t('lot')]}
                                label={t('lot')}
                                disabled={locked}
                                data-cy='lot'
                            />
                        </Grid>
                    }
                    {this.state.productionOrders != null && this.state.productionOrders.length > 0 &&
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.poSelected}
                                options={this.state.productionOrders}
                                onChange={this.changeAutoComplete('poSelected', 'productionOrder')}
                                error={errors[t('productionOrder')] != null ? true : false}
                                helperText={errors[t('productionOrder')]}
                                label={t('productionOrder')}
                                disabled={locked}
                                data-cy='productionOrder'
                            />
                        </Grid>
                    }
                    {this.state.routing != null && this.state.routing !== '' &&
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.routingSel}
                                options={this.state.routings}
                                onChange={this.changeAutoComplete('routingSel', 'routing')}
                                error={errors[t('routing')] != null ? true : false}
                                helperText={errors[t('routing')]}
                                label={t('routing')}
                                disabled={true}
                                data-cy='routing'
                            />
                        </Grid>
                    }
                    {this.state.equipments?.length > 0 &&
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.equipmentSelected}
                                options={this.state.equipments}
                                onChange={this.changeAutoComplete('equipmentSelected', 'equipment')}
                                error={errors[t('equipment')] != null ? true : false}
                                helperText={errors[t('equipment')]}
                                label={t('equipment')}
                                disabled={locked}
                                data-cy='equipment'
                            />
                        </Grid>
                    }
                    {this.state.rooms != null && this.state.rooms.length > 0 &&
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.roomSelected}
                                options={this.state.rooms}
                                onChange={this.changeAutoComplete('roomSelected', 'room')}
                                error={errors[t('room')] != null ? true : false}
                                helperText={errors[t('room')]}
                                label={t('room')}
                                disabled={locked}
                                data-cy='room'
                            />
                        </Grid>
                    }
                    {this.state.vendors != null && this.state.vendors.length > 0 &&
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.vendorSelected}
                                options={this.state.vendors}
                                onChange={this.changeAutoComplete('vendorSelected', 'vendor')}
                                error={errors[t('vendor')] != null ? true : false}
                                helperText={errors[t('vendor')]}
                                label={t('vendor')}
                                disabled={locked}
                                data-cy='vendor'
                            />
                        </Grid>
                    }
                    {this.state.managedDocuments != null && this.state.managedDocuments.length > 0 &&
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.manDocSel}
                                options={this.state.managedDocuments}
                                onChange={this.changeAutoComplete('manDocSel', 'managedDocument')}
                                error={errors[t('managedDocument')] != null ? true : false}
                                helperText={errors[t('managedDocument')]}
                                label={t('document')}
                                disabled={locked}
                                data-cy='document'
                            />
                        </Grid>
                    }
                    <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                            value={this.state.assigneeSelected}
                            options={this.state.users}
                            onChange={this.changeAutoComplete('assigneeSelected', 'assignee')}
                            error={errors[t('assignee')] != null ? true : false}
                            helperText={errors[t('assignee')]}
                            label={t('assignee')}
                            disabled={locked}
                            data-cy='assignee'
                        />
                    </Grid>

                    <Grid item sm={10}>
                        <TextField value={this.state.note} onChange={this.changeValue} name="note" multiline={true} rows="3"
                        variant="outlined" size="medium" fullWidth={true} disabled={locked} label={t('note')} data-cy='note'
                        error={errors[t('note')] != null ? true : false} helperText={errors[t('note')]}/>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={10} sm={5}>
                        <Button variant="contained" color="primary" size="small" onClick={() => this.saveClick()} disabled={locked && !this.state.enableSave} data-cy='save'>
                            {t('save')}
                        </Button>&nbsp;&nbsp;
                        {(this.state.recordId == null || this.state.recordId === '') && this.state._id != null && this.state._id !== '' &&
                            <Aux>
                                <Button variant="contained" color="secondary" size="small" onClick={() => this.deleteClick()} data-cy='delete'>
                                    {t('delete')}
                                </Button>&nbsp;&nbsp;
                            </Aux>
                        }
                        {this.state._id != null && this.state._id !== '' &&
                            <Button variant="contained" color="secondary" size="small" onClick={() => this.performClick()} data-cy='view'>
                                {this.state.recordId != null && this.state.recordId !== '' ? t('view') : t('create')}
                            </Button>
                        }
                    </Grid>
                </Grid>
                {this.state._id != null && this.state._id !== '' &&
                    <div>
                        <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.schedule}/>
                        <ObjectLog id={this.state._id}/>
                    </div>
                }
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ScheduledActivity));
