import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateSelect from '../UI/DateSelect/DateSelect';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { FormLabel, Box, List, ListItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-router-dom';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import SectionTitle from '../UI/Typography/SectionTitle';
import Panel from '../UI/Panel/Panel';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { completeSigSecurity,
    fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import ConfirmDelete from '../General/ConfirmDelete';
import ConversionHelper from '../../helpers/conversionHelper';
import ScheduleDialog from '../General/ScheduleDialog';

class PestScouting extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            date: new Date(),
            location: '',
            locations: [],
            locationSel: null,
            roomList: [],
            rooms: [],
            security: {},
            fieldAccess: false,
            enableSave: true,
            priorities: [],
            pests: [],
            status: '',
            statusSel: null,
            statuses: [],
            reviewSigSec: false,
            completeSigSec: false,
            users: [],
            reviewers: [],
            allUsers: [],
            activeUsers: [],
            activeManagers: [],
            optionsMenu: [],
            flagForSignature: 0,
            user: null,
            reviewer: null,
            sigData: null,
            deleteOpen: false,
            schedule: null,
            scheduleOpen: false
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.user, common.cacheValues.location, common.cacheValues.status,
                                common.cacheValues.defaultRoom, common.cacheValues.pest, common.cacheValues.priority], this.props.auth.constants, null, this.props.auth.user.tenant);
        let allUsers = cacheValues.users;
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.pestScouting, this.props.auth.user.tenant);
        let location = '';
        let locationSel = null;
        if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
            locationSel = locations.find(x => x.value === this.props.auth.user.defaultLocation);
            location = this.props.auth.user.defaultLocation;
        } else {
            if(locations.length === 1){
                locationSel = locations[0];
                location = locationSel.value;
            }
        }
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        this.setState({
            locations: locations,
            location: location,
            locationSel: locationSel,
            roomList: cacheValues.rooms,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            priorities: cacheValues.priorities,
            pests: cacheValues.pests,
            statusSel: statusSel,
            status: this.props.auth.constants.statuses.new,
            statuses: cacheValues.statuses,
            users: userValues.create,
            reviewers: userValues.review,
            allUsers: allUsers,
            activeUsers: activeUsers,
            activeManagers: activeManagers,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : ''
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            } else {
                const crumbs = [
                    { path: '/#/pestscoutings', label: this.props.t('pestScouting'), screen: this.props.auth.screenDefs.PestScoutingList},
                    { path: 'active', label: this.props.t('pestScouting'), screen: this.props.auth.screenDefs.PestScouting}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
                this.generateFromRooms();
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        let record = await axios.get('/api/pestscouting/' + this.state._id);
        let rooms = record.data.rooms;
        for(let row of rooms){
            let pestsSel = [];
            for(let pest of row.pests){
                let val = this.state.pests.find(x => x.value === pest);
                pestsSel.push(val);
            }
            row.pestsSel = pestsSel;
            let threatLevelSel = this.state.priorities.find(x => x.value === row.threatLevel);
            row.threatLevelSel = threatLevelSel;
            let roomSel = this.state.roomList.find(x => x.value === row.room);
            row.roomSel = roomSel;
        }
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let locationSel = this.state.locations.find(x => x.value === record.data.location);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: !internal && this.state.user == null ? this.state.activeUsers : this.state.users,
                name: t('complete'), object: record.data._id, title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(),
                signature: record.data.user, objectType: this.props.auth.constants.objectIds.pestScouting, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, saveCallback: this.saveCallback
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: !internal && this.state.reviewer == null ? this.state.activeManagers : this.state.reviewers,
                name: t('review'), title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.pestScouting, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 : 0;
        this.setState({
            number: record.data.number,
            date: record.data.date,
            rooms: rooms,
            fieldAccess: fieldAccess,
            location: record.data.location,
            locationSel: locationSel,
            status: record.data.status,
            statusSel: statusSel,
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec,
            flagForSignature: flagForSignature,
            optionsMenu: optionsMenu,
            user: record.data.user,
            reviewer: record.data.reviewer,
            roomList: record.data.roomList,
            deleteOpen: false,
            schedule: record.data.schedule != null ? record.data.schedule : null
        }, () => {
            const crumbs = [
                { path: '/#/pestscoutings', label: this.props.t('pestScouting'), screen: this.props.auth.screenDefs.PestScoutingList},
                { path: 'active', label: (this.props.t('pestScouting') + ' - ' + record.data.number), screen: this.props.auth.screenDefs.PestScouting}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    };

    generateFromRooms = async() => {
        let prevRecord = await axios.get('/api/pestscoutingprev', {params: {date: this.state.date, location: this.state.location}});
        let roomList = this.state.roomList;
        let rooms = [];
        for(let row of roomList){
            let prevRoom = prevRecord.data.rooms != null && prevRecord.data.rooms.length > 0 ? prevRecord.data.rooms.find(x => x.room === row.value) : null;
            let roomBatchResult = await axios.get('/api/roombatchsel/' + row.value);
            let batchesSel = roomBatchResult.data;
            let batchIds = [];
            if(batchesSel == null){
                for(let batch of batchesSel){
                    batchIds.push(batch.value);
                }
            }
            let traps = [];
            if(row.pestTraps != null && row.pestTraps.length > 0){
                for(let trap of row.pestTraps){
                    let prevCount = null;
                    if(prevRoom != null && prevRoom.trapResults != null && prevRoom.trapResults.length > 0){
                        let prevTrap = prevRoom.trapResults.find(x => x.trapId === trap._id);
                        if(prevTrap != null){
                            prevCount = prevTrap.count;
                        }
                    }
                    traps.push({
                        trapId: trap._id,
                        item: trap.item,
                        trapDescription: trap.description,
                        count: 0,
                        prevCount: prevCount,
                        description: ''
                    });
                }
            }
            rooms.push({
                room: row.value,
                roomSel: row,
                pestsFound: false,
                pests: [],
                pestsSel: [],
                threatLevel: '',
                threatSel: null,
                description: '',
                treatmentRequired: false,
                documents: [],
                batches: batchIds,
                batchesSel: batchesSel,
                trapResults: traps
            })
        }
        this.setState({rooms: rooms});
    }

    changeValue = e => {
        const name = e.target.name;
        const value = e.target.value
        this.setState({[name]: value});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, async () => {
            if(data === 'location' && newValue != null){
                let rooms = await axios.get('/api/roomselect', {params: {location: newValue.value}});
                this.setState({roomList: rooms.data}, () => {
                    this.generateFromRooms();
                })
            } else if(data === 'location' && newValue == null){
                this.setState({roomList: [], rooms: []});
            }
        });
    }

    dateChange = e => {
        this.setState({date: e});
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.save()})
    };

    save = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    };

    _save = async () => {
        const t = this.props.t;
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let rooms = this.state.rooms;
        for(let row of rooms){
            if(row.trapResults != null && row.trapResults.length > 0){
                for(let trap of row.trapResults){
                    trap.item = trap.item._id;
                }
            }
        }
        let data = {
            _id: this.state._id,
            date: this.state.date,
            number: this.state.number,
            status: this.state.status,
            location: this.state.location,
            rooms: rooms,
            sigData: this.state.sigData,
            schedule: this.state.schedule
        };
        try {
            let result = await axios.post('/api/pestscouting', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/pestscouting/' + result.data.id);
            this.setState({
                _id: result.data.id,
                enableSave: true,
                sigData: null
            }, () => this.loadRecord())
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    validate(final){
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.date == null || state.date === ''){
            errors[t('date')] = t('required');
        } else {
            let validEndDate = ConversionHelper.validActivityDate(this.state.date, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
            if(!validEndDate){
                errors[t('date')] = t('dateBeforeLock');
            }
        }
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(final === true){
            for(let [i, row] of state.rooms.entries()){
                if(row.pestsFound === true){
                    if(row.pests == null || row.pests.length === 0)
                        errors[`${t('pests')} (${t('line')} ${i})`] = t('required');
                    if(row.threatLevel == null || row.threatLevel === '')
                        errors[`${t('threatLevel')} (${t('line')} ${i})`] = t('threatLevel');
                    if(row.description == null || row.description === '')
                        errors[`${t('description')} (${t('line')} ${i})`] = t('required');
                }
                i++;
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._deleteClick());
    };

    _deleteClick = async () => {
        const t = this.props.t;
        if(this.state._id == null || this.state._id === '')
            this.props.history.goBack();
        if(this.state.status !== this.props.auth.constants.statuses.inprogress){
            this.setState({ enableSave: true , deleteOpen: false});
            return;
        }
        try {
            let result = await axios.delete('/api/pestscouting/' + this.state._id);
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            this.props.history.goBack();
        } catch(err){
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    validateComplete = () => {
        const t = this.props.t;
        let valid = this.validate(true);
        if(!valid)
            return t('notAllComplete');
        return null;
    };

    changeLineAuto = (name, data, row) => (e, newValue) => {
        let rooms = this.state.rooms;
        let room = rooms[row];
        room[name] = newValue;
        room[data] = newValue != null ? newValue.value : null;
        this.setState({rooms: rooms});
    };

    changeLineValue = (row) => (e) => {
        let name = e.target.name;
        let value = e.target.value
        let rooms = this.state.rooms;
        let room = rooms[row];
        room[name] = value;
        this.setState({rooms: rooms});
    };

    changeTrapLineValue = (i, j) => (e) => {
        let name = e.target.name;
        let value = e.target.value
        let rooms = this.state.rooms;
        let room = rooms[i];
        let trap = room.trapResults[j];
        trap[name] = value;
        this.setState({rooms: rooms});
    };

    changeTrapLineNumber = (i, j) => (e) => {
        let name = e.target.name;
        let value = e.target.value
        if(isNaN(value)){
            return;
        }
        let rooms = this.state.rooms;
        let room = rooms[i];
        let trap = room.trapResults[j];
        trap[name] = value;
        this.setState({rooms: rooms});
    };

    changeLineBool = (name, row) => (e) => {
        let rooms = this.state.rooms;
        let room = rooms[row];
        room[name] = !room[name];
        this.setState({rooms: rooms});
    };

    changeLineMulti = (name, data, i) => (e, newValue) => {
        let rooms = this.state.rooms;
        let room = rooms[i];
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        room[name] = newValue;
        room[data] = ids;
        this.setState({rooms: rooms});
    };

    toggleDialog = () => {
        this.setState({ scheduleOpen: !this.state.scheduleOpen });
    };

    render(){
        const errors = this.props.errors;
        const security = this.state.security;
        const t = this.props.t;
        const fieldAccess = this.state.fieldAccess;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        fieldAccess={security.update}
                        constants={this.props.auth.constants}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.pestScouting}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        scheduleClick={this.state._id != null && this.state._id !== '' ? this.toggleDialog : null}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3} key="mainhead">
                        {this.state._id != null && this.state._id !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl data-cy='number'>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            <FormControl data-cy='status'>
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSel != null ? this.state.statusSel.label : ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.locationSel}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locationSel', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]} 
                                    label={t('location')}
                                    disabled={!fieldAccess}
                                    required
                                    data-cy='location'
                                />
                            }
                            {!fieldAccess &&
                                <FormControl data-cy='location'>
                                    <FormLabel>{t('location')}</FormLabel>
                                    {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            {fieldAccess &&
                                <DateSelect 
                                    onChange={this.dateChange}
                                    value={this.state.date}
                                    helperText={errors[t('date')]}
                                    error={errors[t('date')] != null ? true : false}
                                    disabled={!fieldAccess}
                                    label={t('date')}
                                    format={this.props.auth.user.dateFormat}
                                    required
                                    data-cy='date'
                                />
                            }
                            {!fieldAccess &&
                                <FormControl data-cy='date'>
                                    <FormLabel>{t('date')}</FormLabel>
                                    <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title={t('rooms')}/>
                    {this.state.rooms.map((row, i) =>
                        <Aux key={row.room + i}>
                            <Box borderColor="primary.main" {...defaultProps} component={'div'} key={'cl' + i}>
                                <Grid container spacing={2} key={'roomgrid' + i}>
                                    <Grid item xs={4} sm={2}>
                                        <FormControl data-cy={'room' + i}>
                                            <FormLabel>{t('room')}</FormLabel>
                                            <Link to={'/room/' + row.room}>{row.roomSel != null ? row.roomSel.label : ''}</Link>
                                        </FormControl>
                                    </Grid>
                                    {row.batchesSel != null && row.batchesSel.length > 0 &&
                                        <Grid item sm={10} sx={{ mt: 2 }}>
                                            <FormControl data-cy={'batches' + i}>
                                                <FormLabel>{t('batches')}</FormLabel>
                                                <List sx={{ display: 'flex', flexDirection: 'row', mt: -1.1 }}>
                                                    {row.batchesSel.map((batch, j) =>
                                                        <ListItem disableGutters={true} disablePadding={true} key={'batch' + i + 'a' + j}>
                                                            <Link to={'/evorabatch/' + batch.value}>{batch.label}</Link>&nbsp;&nbsp;
                                                        </ListItem>
                                                    )}
                                                </List>
                                            </FormControl>
                                        </Grid>
                                    }
                                    {(row.trapResults == null || row.trapResults.length === 0) &&
                                        <Grid item xs={4} sm={2}>
                                            <CheckboxField
                                            checked={row.pestsFound} onChange={this.changeLineBool('pestsFound', i)}
                                            label={t('pestsFound')} name="pestsFound" disabled={!fieldAccess}
                                            data-cy={'pestsFount' + i}
                                            />
                                        </Grid>
                                    }
                                    {(row.pestsFound === true || (row.trapResults != null && row.trapResults.length > 0)) &&
                                        <Aux>
                                            <Grid item xs={10} sm={5}>
                                                <AutoCompleteField
                                                    value={row.pestsSel}
                                                    multiple={true}
                                                    options={this.state.pests}
                                                    onChange={this.changeLineMulti('pestsSel', 'pests', i)}
                                                    error={errors[`${t('pests')} (${t('line')} ${i})`] != null ? true : false}
                                                    helperText={errors[`${t('pests')} (${t('line')} ${i})`]} 
                                                    label={t('pests')}
                                                    disabled={!fieldAccess}
                                                    data-cy={'pests' + i}
                                                />
                                            </Grid>
                                            <Grid item xs={4} sm={2}>
                                                {!fieldAccess &&
                                                    <FormControl data-cy={'threatLevel' + i}>
                                                        <FormLabel>{t('threatLevel')}</FormLabel>
                                                        {row.threatLevel != null ? row.threatLevelSel.label : ''}
                                                    </FormControl>
                                                }
                                                {fieldAccess &&
                                                    <AutoCompleteField
                                                        value={row.threatLevelSel}
                                                        options={this.state.priorities}
                                                        onChange={this.changeLineAuto('threatLevelSel', 'threatLevel', i)}
                                                        error={errors[`${t('threatLevel')} (${t('line')} ${i})`] != null ? true : false}
                                                        helperText={errors[`${t('threatLevel')} (${t('line')} ${i})`]} 
                                                        label={t('threatLevel')}
                                                        disabled={!fieldAccess}
                                                        data-cy={'threatLevel' + i}
                                                    />
                                                }
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <CheckboxField
                                                checked={row.treatmentRequired} onChange={this.changeLineBool('treatmentRequired', i)}
                                                label={t('treatmentRequired')} name="treatmentRequired" disabled={!fieldAccess}
                                                data-cy={'treatmentCheck' + i}
                                                />
                                            </Grid>
                                        </Aux>
                                    }
                                    {(row.trapResults == null || row.trapResults.length === 0) &&
                                        <Grid item sm={12}>
                                            <TextField value={row.description} onChange={this.changeLineValue(i)} name="description" multiline={true} minRows="3"
                                            variant="outlined" size="medium" fullWidth={true} disabled={!fieldAccess} label={t('description')} data-cy={'description' + i}
                                            error={errors[`${t('description')} (${t('line')} ${i})`]!= null ? true : false} helperText={errors[`${t('description')} (${t('line')} ${i})`]}/>
                                        </Grid>
                                    }
                                </Grid>
                                {row.trapResults != null && row.trapResults.length > 0 &&
                                    <Aux>
                                        <SubSectionHeading title={t('pestTraps')}/>
                                        <Grid container spacing={2}>
                                            <Grid item xs={10} sm={5}>{t('item')}</Grid>
                                            <Grid item xs={2} sm={1}>{t('prev')}</Grid>
                                            <Grid item xs={2} sm={1}>{t('count')}</Grid>
                                            <Grid item xs={8} sm={5}>{t('description')}</Grid>
                                        </Grid>
                                        {row.trapResults.map((trap, j) =>
                                            <Grid container spacing={2} key={'trap' + i + 'a' + j}>
                                                <Grid item xs={10} sm={5}>
                                                    {trap.item != null ? trap.item.number + '-' + trap.item.name + '-' + trap.trapDescription: ''}
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    {trap.prevCount != null && trap.prevCount !== 0 ? trap.prevCount : ''}
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    {fieldAccess &&
                                                        <TextField value={trap.count} onChange={this.changeTrapLineNumber(i, j)} name="count" 
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} type="number" data-cy={'count' + i + 'a' + j}
                                                        error={errors[`${t('count')} (${t('line')} ${i}, ${j})`]!= null ? true : false} helperText={errors[`${t('count')} (${t('line')} ${i}, ${j})`]}/>
                                                    }
                                                    {!fieldAccess &&
                                                        trap.count
                                                    }
                                                </Grid>
                                                <Grid item xs={8} sm={5}>
                                                    {fieldAccess &&
                                                        <TextField value={trap.description} onChange={this.changeTrapLineValue(i, j)} name="description" 
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} type="text" data-cy={'description' + i + 'a' + j}
                                                        error={errors[`${t('description')} (${t('line')} ${i}, ${j})`]!= null ? true : false} helperText={errors[`${t('description')} (${t('line')} ${i}, ${j})`]}/>
                                                    }
                                                    {!fieldAccess &&
                                                        trap.description
                                                    }
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Aux>
                                }
                                {(row.pestTraps == null || row.pestTraps.length === 0) &&
                                    <Aux>
                                        
                                    </Aux>
                                }
                            </Box>
                        </Aux>
                    )}
                    <br/>
                    <FormButtonStack
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.pestScouting}
                        callback={this.loadRecord}
                        saveClick={() => this.save()}
                        status={this.state.status}
                        permissions={this.props.permission}
                        common={this.props.auth.common}
                        constants={this.props.auth.constants}
                        saveEnabled={this.state.enableSave}
                        deleteClick={this.deleteToggle}
                        activityDate={this.state.date}
                        lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                        isSuper={this.props.auth.user.isSuper}
                    />
                </Panel>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.pestScouting}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
                {this.state.scheduleOpen &&
                    <ScheduleDialog
                        activity={this.props.auth.constants.objectIds.pestScouting}
                        id={this.state.schedule}
                        fieldAccess={fieldAccess}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.scheduleOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(PestScouting));
