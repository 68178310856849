import React from 'react';
import Confirmation from '../UI/Dialog/Confirmation';
import { useTranslation } from 'react-i18next';

export default function IdleCheck(props) {
    const { t } = useTranslation();
     const {cancel, open, toggleDialog, timeLeft} = props;
     const confirmationTitle = t('areYouThere');
     const content = t('logoffMessage', {timeLeft: timeLeft});
     const yesButton = t('yesContinue');
     const noButton = t('noLogoff');
     return (
          <Confirmation content={content} title={confirmationTitle} open={open}
                        no={cancel} noButton={noButton}
                        yes={toggleDialog} yesButton={yesButton}/>
     );         
} 