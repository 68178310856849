import React, { useLayoutEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

export default function StackedButton(props) {
     const [open, setOpen] = React.useState(false);
     const anchorRef = React.useRef(null);
     const [selectedIndex, setSelectedIndex] = React.useState(0);
     const [buttonStack, setButtonStack] = React.useState([]);

     useLayoutEffect(() => {
          setButtonStack(props.buttonStack);
     }, [props.buttonStack]);

     const handleButtonClick = () => {
          return buttonStack[selectedIndex].onClick();
     }

     const handleMenuItemClick = (event, index) => {
          setSelectedIndex(index);
          setOpen(false);
     };

     const handleToggle = () => {
          setOpen((prevOpen) => !prevOpen);
     };

     const handleClose = (event) => {
          if (anchorRef.current && anchorRef.current.contains(event.target)) {
               return;
          }
          setOpen(false);
     };

     return (
          <Grid container direction="column" sx={{ alignItems: "flex-end" }}>
               <Grid item xs={12}>
                    {buttonStack[selectedIndex] !== undefined &&
                         <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                              <Button 
                                   onClick={handleButtonClick} 
                                   startIcon={buttonStack[selectedIndex].icon}
                                   size="small"
                              >
                                   {buttonStack[selectedIndex].label}
                              </Button>
                              <Button
                              color="primary"
                              size="small"
                              aria-controls={open ? 'split-button-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-label="select merge strategy"
                              aria-haspopup="menu"
                              onClick={handleToggle}
                              >
                              <ArrowDropDownIcon />
                              </Button>
                         </ButtonGroup>
                    }                    
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                         {({ TransitionProps, placement }) => (
                         <Grow
                         {...TransitionProps}
                         sx={{
                              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                         }}
                         >
                         <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                              <MenuList id="split-button-menu">
                                   {buttonStack.map((button, index) => (
                                        <MenuItem
                                             key={`${button.label}-${index}`}
                                             disabled={button.disabled}
                                             selected={index === selectedIndex}
                                             onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                        {button.label}
                                        </MenuItem>
                                   ))}
                              </MenuList>
                              </ClickAwayListener>
                         </Paper>
                         </Grow>
                         )}
                    </Popper>
               </Grid>
          </Grid>
     );
}