import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import HazardousWitness from './HazardousWitness';
import HazardousProperty from './HazardousProperty';
import HazardousInvestigation from './HazardousInvestigation';
import HazardousHr from './HazardousHr';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import SignatureCapture from '../General/SignatureCapture';
import DateTimeSelect from '../UI/DateTimeSelect/DateTimeSelect';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import ActionBar from '../UI/Buttons/ActionBar';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class InjuryHazardous extends Component {
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            _id: '',
            peopleInvolved: [],
            peopleInvolvedSelect: [],
            users: [],
            dateOfIncident: null,
            department: '',
            departments: [],
            location: '',
            locations: [],
            occupation: '',
            medicalEvalDateTime: null,
            medicalEvaluator: [],
            medicalEvaluators: [],
            medicalEvaluatorSelect: [],
            injuryDetails: '',
            wcbForm: [],
            wcbFormOptions: [],
            incidentDescription: '',
            completedBy: '',
            completedSignature: '',
            completionDate: null,
            sigOpen: false,
            schedule: null,
            documents: [],
            number: '',
            depSel: null,
            locationSelected: null,
            wcbSelected: null,
            completedBySelected: null,
            tabKey: 0,
            tabLabels: [
                {name: t('incidentInformation'), disabled: false}, 
                {name: t('witnesses'), disabled: false}, 
                {name: t('propertyDamages'), disabled: false},
                {name: t('investigation'), disabled: false},
                {name: t('humanResources'), disabled: false}
            ],
            security: [],
            dialogOpen: false,
            enableSave: true,
            goBack: -1
        };
        this.sigPad = {};
        this.changeValue = this.changeValue.bind(this);
        this.peopleChanged = this.peopleChanged.bind(this);
        this.incidentDateChanged = this.incidentDateChanged.bind(this);
        this.medicalDateChanged = this.medicalDateChanged.bind(this);
        this.medicalEvaluatorChanged = this.medicalEvaluatorChanged.bind(this);
        this.saveClick = this.saveClick.bind(this);
        this.completedDateChanged = this.completedDateChanged.bind(this);
    }

    async componentDidMount(){
        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        let params = queryString.parse(this.props.location.search);
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        let goBack = -1;
        if(scheduleId != null)
            goBack = -2;
        var values = await axios.get("/api/hazardousvalues");
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ? 
            this.props.auth.user.defaultLocation : '';
        let locSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locSel == null && locations.length === 1){
            locSel = locations[0];
            location = locSel.value;
        }
        this.setState({
            users: values.data.users,
            wcbFormOptions: values.data.wcbForms,
            medicalEvaluators: values.data.medicalEvaluators,
            locations: locations,
            location: location,
            locationSelected: locSel,
            schedule: scheduleId,
            departments: values.data.departments,
            security: this.props.permission,
            goBack: goBack
        }, async () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord(values.data);
            }else {
                const crumbs = [
                    { path: '/#/hazardouslist', label: this.props.t('hazardousSpills'), screen: this.props.auth.screenDefs.InjuryHazardous},
                    { path: 'active', label: this.props.t('hazardousSpill'), screen: this.props.auth.screenDefs.InjuryHazardous}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async (values) => {
        const { user } = this.props.auth;
        var hazardous = await axios.get('/api/hazardous/' + this.props.match.params.id);
        var peopleInvolvedSelect = [];
        var users = values != null ? values.users : this.state.users;
        for(var a = 0; a < hazardous.data.peopleInvolved.length; a++){
            for (var b = 0; b < users.length; b++){
                if(hazardous.data.peopleInvolved[a] === users[b].value){
                    peopleInvolvedSelect.push(users[b]);
                    break;
                }
            }
        }
        var medicalEvaluators = values != null ? values.medicalEvaluators : this.state.medicalEvaluators;
        var medicalEvaluatorsSelect = [];
        if(hazardous.data.medicalEvaluator != null){
            for(var c = 0; c < hazardous.data.medicalEvaluator.length; c++){
                for(var d = 0; d < medicalEvaluators.length; d++){
                    if(hazardous.data.medicalEvaluator[c] === medicalEvaluators[d].value){
                        medicalEvaluatorsSelect.push(medicalEvaluators[d]);
                        break;
                    }
                }
            }
        }
        var locations = values != null ? values.locations : this.state.locations;
        var locationSelected = locations.find(x => x.value === hazardous.data.location);
        var wcbFormOptions = values != null ? values.wcbForms : this.state.wcbFormOptions;
        var wcbSelected = wcbFormOptions.find(x => x.value === hazardous.data.wcbForm);
        var completedBySelected = users.find(x => x.value === hazardous.data.completedBy);
        const deps = values != null ? values.departments : this.state.departments;
        var depSel = deps.find(x => x.value === hazardous.data.department);
        this.setState({
            _id: hazardous.data._id,
            peopleInvolved: hazardous.data.peopleInvolved,
            peopleInvolvedSelect: peopleInvolvedSelect,
            dateOfIncident: Date.parse(hazardous.data.dateOfIncident),
            department: hazardous.data.department,
            location: hazardous.data.location,
            medicalEvaluatorSelect: medicalEvaluatorsSelect,
            occupation: hazardous.data.occupation,
            medicalEvalDateTime: Date.parse(hazardous.data.medicalEvalDateTime),
            medicalEvaluator: hazardous.data.medicalEvaluator,
            injuryDetails: hazardous.data.injuryDetails,
            wcbForm: hazardous.data.wcbForm,
            incidentDescription: hazardous.data.incidentDescription,
            completedBy: hazardous.data.completedBy != null && hazardous.data.completedBy !== '' ?
                hazardous.data.completedBy : user.id,
            completedSignature: hazardous.data.completedSignature,
            completionDate: hazardous.data.completionDate != null && hazardous.data.completionDate !== '' ? 
                Date.parse(hazardous.data.completionDate) : null,
            locationSelected: locationSelected,
            wcbSelected: wcbSelected,
            completedBySelected: completedBySelected,
            documents: hazardous.data.documents,
            number: hazardous.data.number,
            depSel: depSel
        }, () => {
            const crumbs = [
                { path: '/#/hazardouslist', label: this.props.t('hazardousSpills'), screen: this.props.auth.screenDefs.InjuryHazardous},
                { path: 'active', label: this.props.t('hazardousSpill') + ' - ' + hazardous.data.number, screen: this.props.auth.screenDefs.InjuryHazardous}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    changeValue(e){
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    peopleChanged(e, newValue){
        var people = [];
        if(newValue != null){
            newValue.forEach(person => {
                people.push(person.value);
            });
        }
        this.setState({
            peopleInvolvedSelect: newValue,
            peopleInvolved: people
        });
    }

    medicalEvaluatorChanged(e, newValue){
        var evals = [];
        if(newValue != null){
            newValue.forEach(evaluator => {
                evals.push(evaluator.value);
            });
        }
        this.setState({
            medicalEvaluatorSelect: newValue,
            medicalEvaluator: evals
        });
    }

    incidentDateChanged(e){
        this.setState({dateOfIncident: e});
    }

    medicalDateChanged(e){
        this.setState({medicalEvalDateTime: e});
    }

    completedDateChanged(e){
        this.setState({completionDate: e});
    }

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = () => {
        const t = this.props.t;
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        var data = {
            _id: this.state._id,
            peopleInvolved: this.state.peopleInvolved,
            dateOfIncident: this.state.dateOfIncident,
            department: this.state.department,
            location: this.state.location,
            occupation: this.state.occupation,
            medicalEvalDateTime: this.state.medicalEvalDateTime,
            medicalEvaluator: this.state.medicalEvaluator,
            injuryDetails: this.state.injuryDetails,
            wcbForm: this.state.wcbForm,
            incidentDescription: this.state.incidentDescription,
            completedBy: this.state.completedBy,
            completedSignature: this.state.completedSignature,
            completionDate: this.state.completionDate,
            schedule: this.state.schedule
        }
        axios.post('/api/hazardoussave', data)
        .then(result => {
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/hazardous/' + result.data.id);
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });            
        })
        .catch(err => {
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        });
    }

    validateForm(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.peopleInvolved == null || state.peopleInvolved.length === 0)
            errors[t('peopleInvolved')] = t('required');
        if(state.dateOfIncident == null || state.dateOfIncident === '')
            errors[t('dateOfIncident')] = t('required');
        if(state.department == null || state.department === '')
            errors[t('department')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.completedBy == null || state.completedBy === '')
            errors[t('completedBy')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    sigClick = () => {
        this.setState({sigOpen: true});
    };
    
    sigClose = () => {
        this.setState({sigOpen: false});
    };

    clear = () => {
        this.sigPad.clear();
    };

    accept = signature => {
        this.setState({
            completedSignature: signature,
            sigOpen: false
        });
    };

    editSignature = () => {
        this.setState({completedSignature: ""});
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    toggleDialog = () => {
        this.setState({ dialogOpen: !this.state.dialogOpen });
    };

    render(){
        const { user } = this.props.auth;
        const errors = this.props.errors;
        const signed = false;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar goBack={this.state.goBack}>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.feedingRecipe}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>                
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabPanel value={this.state.tabKey} index={0}>
                    <Grid container spacing={3}>
                        {this.state._id != null && this.state._id !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            <DateTimeSelect
                                onChange={this.incidentDateChanged}
                                value={this.state.dateOfIncident}
                                helperText={errors[t('dateOfIncident')]}
                                error={errors[t('dateOfIncident')] != null ? true : false}
                                disabled={signed}
                                label={t('incidentDate')}
                                required
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.locationSelected}
                                options={this.state.locations}
                                onChange={this.changeAuto('locationSelected', 'location')}
                                error={errors[t('location')] != null ? true : false}
                                helperText={errors[t('location')]}
                                label={t('location')}
                                disabled={signed}
                                required
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.depSel}
                                options={this.state.departments}
                                onChange={this.changeAuto('depSel', 'department')}
                                error={errors[t('department')] != null ? true : false}
                                helperText={errors[t('department')]}
                                label={t('department')}
                                disabled={signed}
                                required
                            />
                        </Grid>
                        <Grid item sm={7}>
                            <AutoCompleteField
                                multiple={true}
                                value={this.state.peopleInvolvedSelect}
                                options={this.state.users}
                                onChange={this.peopleChanged}
                                error={errors[t('peopleInvolved')] != null ? true : false}
                                helperText={errors[t('peopleInvolved')]}
                                label={t('peopleInvolved')}
                                disabled={signed}
                                required
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.occupation} onChange={this.changeValue} name="occupation"
                            size="medium" fullWidth={true} disabled={signed} label={t('jobTitle')}
                            error={errors[t('occupation')] != null ? true : false} helperText={errors[t('occupation')]}/>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                            <AutoCompleteField
                                multiple={true}
                                value={this.state.medicalEvaluatorSelect}
                                options={this.state.medicalEvaluators}
                                onChange={this.medicalEvaluatorChanged}
                                error={errors[t('medicalEvaluatorSelect')] != null ? true : false}
                                helperText={errors[t('medicalEvaluatorSelect')]}
                                label={t('medicalEvaluator')}
                                disabled={signed}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateTimeSelect
                                onChange={this.medicalDateChanged}
                                value={this.state.medicalEvalDateTime}
                                helperText={errors[t('medicalEvalDateTime')]}
                                error={errors[t('medicalEvalDateTime')] != null ? true : false}
                                disabled={signed}
                                label={t('medicalEvalTime')}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.wcbSelected}
                                options={this.state.wcbFormOptions}
                                onChange={this.changeAuto('wcbSelected', 'wcbForm')}
                                error={errors[t('wcbForm')] != null ? true : false}
                                helperText={errors[t('wcbForm')]}
                                label={t('wcbForm')}
                                disabled={signed}
                            />
                        </Grid>
                        <Grid item sm={11}>
                            <TextField value={this.state.injuryDetails} onChange={this.changeValue} name="injuryDetails" multiline={true} rows="3"
                            variant="outlined" size="medium" fullWidth={true} disabled={signed} 
                            label={t('injuryDetails')}
                            error={errors[t('injuryDetails')] != null ? true : false} helperText={errors[t('injuryDetails')]}/>
                        </Grid>
                        <Grid item sm={11}>
                            <TextField value={this.state.incidentDescription} onChange={this.changeValue} name="incidentDescription" 
                            multiline={true} rows="3"
                            variant="outlined" size="medium" fullWidth={true} disabled={signed} 
                            label={t('incidentDescription')}
                            error={errors[t('incidentDescription')] != null ? true : false} helperText={errors[t('incidentDescription')]}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.completedBySelected}
                                options={this.state.users}
                                onChange={this.changeAuto('completedBySelected', 'completedBy')}
                                error={errors[t('completedBy')] != null ? true : false}
                                helperText={errors[t('completedBy')]}
                                label={t('completedBy')}
                                disabled={signed}
                                required
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.completedDateChanged}
                                value={this.state.completionDate}
                                helperText={errors[t('completionDate')]}
                                error={errors[t('completionDate')] != null ? true : false}
                                disabled={signed}
                                label={t('completedOn')}
                                format={this.props.auth.user.dateFormat}
                            />
                        </Grid>
                        {this.state.completedBy != null && this.state.completedBy !== '' && this.state.completionDate != null &&
                            this.state.completionDate !== '' &&
                            <Grid item xs={2} sm={1}>
                                <Button variant="outlined" color="primary" size="small"  onClick={this.sigClick}>
                                    {this.state.completedSignature != null && this.state.completedSignature !== '' ?
                                    t('view') : t('sign')}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                    <SignatureCapture signature={this.state.completedSignature} editSig={this.editSignature} heading={t('completedBy')}
                    open={this.state.sigOpen} saveSig={this.accept} close={this.sigClose} sigPad={this.sigPad}
                    clearSig={this.clear}/>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.hazardousSpill}/>
                            <ObjectLog id={this.state._id}/>
                        </Aux>
                    }
                    <Grid container spacing={3}>
                        <Grid item sm={5}>
                            <Button disabled={!this.state.enableSave} variant="contained" color="primary" size="small" onClick={this.saveClick}>{t('save')}</Button>
                        </Grid>
                    </Grid>
                </TabPanel>
                {this.state._id !== '' &&
                    <TabPanel value={this.state.tabKey} index={1}>
                        <HazardousWitness id={this.state._id} />
                    </TabPanel>
                }
                {this.state._id !== '' &&
                    <TabPanel value={this.state.tabKey} index={2}>
                        <HazardousProperty id={this.state._id} />
                    </TabPanel>
                }
                {this.state._id !== '' && user.isRPIC &&
                    <TabPanel value={this.state.tabKey} index={3}>
                        <HazardousInvestigation id={this.state._id} />
                    </TabPanel>
                }
                {this.state._id !== '' && user.isHr &&
                    <TabPanel value={this.state.tabKey} index={4}>
                        <HazardousHr id={this.state._id} />
                    </TabPanel>
                }
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(InjuryHazardous));