import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import SubSubHeading from '../UI/Typography/SubSubHeading'
import Aux from '../../hoc/Auxilary/Auxilary';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

const CannabisInventory = () => {
    const dispatch = useDispatch();
    const [ values, setValues ] = useState({
        data: null,
        strainOpen: true,
        itemOpen: true,
        strainItemsOpen: [],
        itemsOpen: []
    });
    const { t } = useTranslation();

    useEffect(() => {
        async function loadRecord(values){
            let data = await axios.get('/api/cannabisinventory');
            let strains = data.data.strains;
            let items = data.data.items;
            let strainItemsOpen = [];
            strains.forEach(strain => {
                let items = [];
                strain.items.forEach(row => {
                    items.push(false);
                });
                strainItemsOpen.push(items);
            });
            let itemsOpen = [];
            items.forEach(row => {
                itemsOpen.push(false);
            })
            setValues({...values, data: data.data, strainItemsOpen: strainItemsOpen, itemsOpen: itemsOpen});

            const crumbs = [
                { path: 'active', label: this.props.t('cannabisInventory'), screen: this.props.auth.screenDefs.CannabisInventory}
            ]
            dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        };
        loadRecord();
    }, [dispatch]);

    const setStrainOpen = (e) => {
        setValues({...values, strainOpen: !values.strainOpen});
    };

    const setItemOpen = (e) => {
        setValues({...values, itemOpen: !values.itemOpen});
    };

    function setStrainItemOpen(i, j){
        let strainItemsOpen = values.strainItemsOpen;
        strainItemsOpen[i][j] = !strainItemsOpen[i][j];
        setValues({...values, strainItemsOpen: strainItemsOpen});
    }

    function setItemsOpen(i){
        let itemsOpen = values.itemsOpen;
        itemsOpen[i] = !itemsOpen[i];
        setValues({...values, itemsOpen: itemsOpen});
    }

    return(
        <Aux>
            <BreadcrumbBar />
            <Panel>
                {values.data != null && values.data.strains != null && values.data.strains.length > 0 &&
                    <Aux>
                        <SectionTitle title={t('strains')}/>
                        <IconButton aria-label="expand row" size="small" onClick={() => setStrainOpen()}>
                            {values.strainOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        <Collapse in={!values.strainOpen} timeout="auto" unmountOnExit>
                        <br/>
                        {values.data.strains.map((row, i) =>
                            <Aux key={'strain' + i}>
                                <SubSectionHeading title={row.shortCode != null ? row.shortCode + ' - ' + row.name : row.name}/>
                                {(row.cloneBatchInventory !== 0 || (row.cloneInventory != null && row.cloneInventory !== 0) || row.vegBatchInventory !== 0 || 
                                (row.vegInventory != null && row.vegInventory !== 0) || row.flowerBatchInventory !== 0 || 
                                (row.flowerInventory != null && row.flowerInventory !== 0) || row.motherBatchInventory !== 0 || 
                                (row.motherInventory != null && row.motherInventory !== 0)) &&
                                    <Aux>
                                        <SubSubHeading title={t('plants')}/>
                                        <TableContainer commponent={Paper}>
                                            <Table arial-label={'strain' + i} size="small">
                                                <TableHead>
                                                    <TableRow key={'strainhead' + i}>
                                                        <TableCell sx={{width: 300}}>{t('item')}</TableCell>
                                                        <TableCell sx={{width: 100}} align="right">{t('inventory')}</TableCell>
                                                        <TableCell sx={{width: 100}} align="right">{t('planted')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {row.cloneItem != null && (row.cloneBatchInventory !== 0 || row.cloneInventory !== 0) &&
                                                        <TableRow key={'clones' + i}>
                                                            <TableCell sx={{width: 300}}>
                                                                <Link to={'/item/' + row.cloneItem._id}>{row.cloneItem.number + ' - ' + row.cloneItem.name}</Link>
                                                            </TableCell>
                                                            <TableCell sx={{width: 100}} align="right">
                                                                <NumericFormat value={row.cloneInventory} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <NumericFormat value={row.cloneBatchInventory} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {row.vegItem != null && (row.vegBatchInventory !== 0 || row.vegInventory !== 0) &&
                                                        <TableRow key={'veg' + i}>
                                                            <TableCell sx={{width: 300}}>
                                                                <Link to={'/item/' + row.vegItem._id}>{row.vegItem.number + ' - ' + row.vegItem.name}</Link>
                                                            </TableCell>
                                                            <TableCell sx={{width: 100}} align="right">
                                                                <NumericFormat value={row.vegInventory} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <NumericFormat value={row.vegBatchInventory} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                     {row.flowerItem != null && (row.flowerBatchInventory !== 0 || row.flowerInventory !== 0) &&
                                                        <TableRow key={'flower' + i}>
                                                            <TableCell sx={{width: 300}}>
                                                                <Link to={'/item/' + row.flowerItem._id}>{row.flowerItem.number + ' - ' + row.flowerItem.name}</Link>
                                                            </TableCell>
                                                            <TableCell sx={{width: 100}} align="right">
                                                                <NumericFormat value={row.flowerInventory} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <NumericFormat value={row.flowerBatchInventory} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Aux>
                                }
                                {row.items != null && row.items.length > 0 &&
                                    <Aux>
                                        <SubSectionHeading title={t('items')}/>
                                        <TableContainer component={Paper}>
                                            <Table arial-label={'strainitems' + i} size="small">
                                                <TableHead>
                                                    <TableRow key={'strainItemHead' + i}>
                                                        <TableCell sx={{width: 300}}></TableCell>
                                                        <TableCell sx={{width: 100}} align="right">{t('inventory')}</TableCell>
                                                        <TableCell sx={{width: 100}} align="right">{t('count')}</TableCell>
                                                        <TableCell sx={{width: 100}} align="right">{t('discreteWeight')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {row.items.map((item, j) =>
                                                        <Aux>
                                                            <TableRow key={'strainitem' + i + '-' + j}>
                                                                <TableCell sx={{width: 300}}>
                                                                    <IconButton aria-label="expand row" size="small" onClick={() => setStrainItemOpen(i, j)}>
                                                                        {values.strainItemsOpen[i][j] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                    </IconButton>
                                                                    <Link to={'/item/' + item.item._id}>{item.item.number + ' - ' + item.item.name}</Link>
                                                                </TableCell>
                                                                <TableCell sx={{width: 100}} align="right">
                                                                    <NumericFormat value={item.inventory} displayType={'text'} thousandSeparator={true}
                                                                    decimalScale={2} suffix={item.uom}/>
                                                                </TableCell>
                                                                <TableCell sx={{width: 100}} align="right">
                                                                    {item.count != null && item.count !== 0 &&
                                                                        <NumericFormat value={item.count} displayType={'text'} thousandSeparator={true}
                                                                        decimalScale={0}/>
                                                                    }
                                                                </TableCell>
                                                                <TableCell sx={{width: 100}} align="right">
                                                                    <NumericFormat value={item.fullDiscreteWeight} displayType={'text'} thousandSeparator={true}
                                                                    decimalScale={2} suffix={item.discreteUOM}/>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                                    <Collapse in={values.strainItemsOpen[i][j]} timeout="auto" unmountOnExit>
                                                                        <SubSubHeading title={t('lots')}/>
                                                                        <Table size="small" aria-label={"strainitemlots" + i}>
                                                                            <TableHead>
                                                                                <TableRow key={'strainitemlothead' + i}>
                                                                                    <TableCell sx={{width: 100}}>{t('number')}</TableCell>
                                                                                    <TableCell sx={{width: 100}} align="right">{t('inventory')}</TableCell>
                                                                                    <TableCell sx={{width: 100}} align="right">{t('count')}</TableCell>
                                                                                    <TableCell sx={{width: 100}} align="right">{t('discreteWeight')}</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {item.lots.map((lot, k) => 
                                                                                    <TableRow key={lot._id}>
                                                                                        <TableCell sx={{width: 100}}><Link to={'/lot/' + lot._id}>{lot.number}</Link></TableCell>
                                                                                        <TableCell sx={{width: 100}} align="right">
                                                                                            <NumericFormat value={lot.amount} displayType={'text'} thousandSeparator={true}
                                                                                            decimalScale={2} suffix={item.uom}/>
                                                                                        </TableCell>
                                                                                        <TableCell sx={{width: 100}} align="right">
                                                                                            {lot.count != null && lot.count !== 0 &&
                                                                                                <NumericFormat value={lot.count} displayType={'text'} thousandSeparator={true}
                                                                                                decimalScale={0}/>
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell sx={{width: 100}} align="right">
                                                                                            {lot.fullDiscreteWeight != null && lot.fullDiscreteWeight !== 0 &&
                                                                                                <NumericFormat value={lot.fullDiscreteWeight} displayType={'text'} thousandSeparator={true}
                                                                                                decimalScale={2} suffix={lot.discreteUOM}/>
                                                                                            }
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                )}
                                                                                <TableRow key={'strainLotTotal' + item._id}>
                                                                                    <TableCell sx={{width: 100}}></TableCell>
                                                                                    <TableCell sx={{width: 100}} align="right">
                                                                                        <NumericFormat value={item.lotTotal} displayType={'text'} thousandSeparator={true}
                                                                                        decimalScale={2} suffix={item.uom}/>
                                                                                    </TableCell>
                                                                                    <TableCell sx={{width: 100}} align="right"></TableCell>
                                                                                    <TableCell sx={{width: 100}} align="right"></TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </Collapse>
                                                                </TableCell>
                                                            </TableRow>
                                                        </Aux>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Aux>
                                }
                                <br/>
                            </Aux>
                        )}
                        </Collapse>
                    </Aux>
                }
                {values.data != null && values.data.items != null && values.data.items.length > 0 &&
                    <Aux>
                        <br/>
                        <SectionTitle title={t('items')}/>
                        <IconButton aria-label="expand row" size="small" onClick={() => setItemOpen()}>
                            {values.itemOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        <Collapse in={!values.itemOpen} timeout="auto" unmountOnExit>
                            <TableContainer component={Paper}>
                                <Table aria-label="otheritems" size="small">
                                    <TableHead>
                                        <TableRow key={"itemhead"}>
                                            <TableCell sx={{width: 300}}></TableCell>
                                            <TableCell sx={{width: 100}} align="right">{t('inventory')}</TableCell>
                                            <TableCell sx={{width: 100}} align="right">{t('count')}</TableCell>
                                            <TableCell sx={{width: 100}} align="right">{t('discreteWeight')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {values.data.items.map((row, i) =>
                                            <Aux>
                                                <TableRow key={'item' + i}>
                                                    <TableCell sx={{width: 300}}>
                                                        <IconButton aria-label="expand row" size="small" onClick={() => setItemsOpen(i)}>
                                                            {values.itemsOpen[i] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                        <Link to={'/item/' + row.item._id}>{row.item.number + ' - ' + row.item.name}</Link>
                                                    </TableCell>
                                                    <TableCell sx={{width: 100}} align="right">
                                                        <NumericFormat value={row.inventory} displayType={'text'} thousandSeparator={true}
                                                        decimalScale={2} suffix={row.uom}/>
                                                    </TableCell>
                                                    <TableCell sx={{width: 100}} align="right">
                                                        {row.count != null && row.count !== 0 &&
                                                            <NumericFormat value={row.count} displayType={'text'} thousandSeparator={true}
                                                            decimalScale={0}/>
                                                        }
                                                    </TableCell>
                                                    <TableCell sx={{width: 100}} align="right">
                                                        <NumericFormat value={row.fullDiscreteWeight} displayType={'text'} thousandSeparator={true}
                                                        decimalScale={2} suffix={row.discreteUOM}/>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Collapse in={values.itemsOpen[i]} timeout="auto" unmountOnExit>
                                                            <SubSubHeading title={t('lots')}/>
                                                            <Table size="small" aria-label={"itemlots" + i}>
                                                                <TableHead>
                                                                    <TableRow key={'itemlothead' + i}>
                                                                        <TableCell sx={{width: 100}}>{t('number')}</TableCell>
                                                                        <TableCell sx={{width: 100}} align="right">{t('inventory')}</TableCell>
                                                                        <TableCell sx={{width: 100}} align="right">{t('count')}</TableCell>
                                                                        <TableCell sx={{width: 100}} align="right">{t('discreteWeight')}</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {row.lots.map((lot, k) => 
                                                                        <TableRow key={lot._id}>
                                                                            <TableCell sx={{width: 100}}><Link to={'/lot/' + lot._id}>{lot.number}</Link></TableCell>
                                                                            <TableCell sx={{width: 100}} align="right">
                                                                                <NumericFormat value={lot.amount} displayType={'text'} thousandSeparator={true}
                                                                                decimalScale={2} suffix={row.uom}/>
                                                                            </TableCell>
                                                                            <TableCell sx={{width: 100}} align="right">
                                                                                {lot.count != null && lot.count !== 0 &&
                                                                                    <NumericFormat value={lot.count} displayType={'text'} thousandSeparator={true}
                                                                                    decimalScale={0}/>
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell sx={{width: 100}} align="right">
                                                                                {lot.fullDiscreteWeight != null && lot.fullDiscreteWeight !== 0 &&
                                                                                    <NumericFormat value={lot.fullDiscreteWeight} displayType={'text'} thousandSeparator={true}
                                                                                    decimalScale={2} suffix={lot.discreteUOM}/>
                                                                                }
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                    <TableRow key={'lotTotal' + row._id}>
                                                                        <TableCell sx={{width: 100}}></TableCell>
                                                                        <TableCell sx={{width: 100}} align="right">
                                                                            <NumericFormat value={row.lotTotal} displayType={'text'} thousandSeparator={true}
                                                                            decimalScale={2} suffix={row.uom}/>
                                                                        </TableCell>
                                                                        <TableCell sx={{width: 100}} align="right"></TableCell>
                                                                        <TableCell sx={{width: 100}} align="right"></TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </Aux>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Collapse>
                    </Aux>
                }
            </Panel>
        </Aux>
    )
}


const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(CannabisInventory);
