import React from 'react';
import Aux from '../../../hoc/Auxilary/Auxilary';
import InputMask from 'react-input-mask';
import TextField from '../TextField/TextField';

export default function InputMaskField(props) {
  const { mask, className, name, label, onChange, error, disabled,
          value, helperText, required} = props;

  return (
    <Aux>
        <InputMask 
            mask={mask} 
            name={name} 
            value={value}
            onChange={onChange} 
            disabled={disabled}
            className={className}
            required={required}>
                {(params) =>  
                    <TextField {...params} 
                        helperText={helperText}
                        error={error} label={label} 
                        disabled={disabled}
                        className={className}
                        required={required}
                    />
                }
        </InputMask>
    </Aux>
  )
}
