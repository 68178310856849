import React  from 'react';
import Aux from '../../../hoc/Auxilary/Auxilary';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ReportTitle from '../../UI/Typography/ReportTitle';
import ReportSectionTitle from '../../UI/Typography/ReportSectionTitle';

export default function ActivityRecordTemplate(props){
    const { dateFormat, record, sequence, sop, t } = props;

    return(
        <div style={{ width: '8.5in', pageBreakBefore: 'always' }}>
            {record != null && record.number != null &&
                <Aux>
                    {record._id != null && record._id !== '' &&
                        <Grid container spacing={2} sx={{ mt: 3 }}>
                            <Grid item xs={12}>
                                <ReportTitle title={
                                            sop != null && sop !== '' && sop.name != null && sequence != null && sequence !== '' ? 
                                            sequence + '. ' + t('workRecord') + ': ' + sop.name  : t('workRecord')
                                        } />
                                <table style={{ width: '800px' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', textAlign: 'center' }}>{t('number')}</th>
                                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', textAlign: 'center' }}>{t('date')}</th>
                                            <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', textAlign: 'center' }}>{t('location')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>{record.number != null ? record.number : t('na')}</td>
                                            <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>{record.completedDate != null ?
                                                <DateDisplay value={record.completedDate} format={dateFormat}/> : t('na')}
                                            </td>
                                            <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>{record.location != null ? record.location.name : t('na')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <ReportSectionTitle title={t('relatedSOP')} />
                                        <table style={{ width: '800px' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', textAlign: 'center', width: '350px' }}>{t('name')}</th>
                                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', textAlign: 'center', width: '100px' }}>{t('number')}</th>
                                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', textAlign: 'center', width: '150px' }}>{t('version')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr key={'sopDetails'}>
                                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                                        {sop != null && sop !== '' && sop.document != null && sop.document !== '' ?
                                                            sop.document.name : t('na')
                                                        }
                                                    </td>
                                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                                        {sop != null && sop !== '' && sop.document != null && sop.document !== '' ?
                                                            sop.document.number : t('na')
                                                        }
                                                    </td>
                                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                                        {sop != null && sop !== '' && sop.document != null && sop.document !== '' ?
                                                            sop.document.version : t('na')
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ReportSectionTitle title={t('performedBy')} />
                                        <table style={{ width: '800px' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', textAlign: 'center', width: '350px' }}>{t('name')}</th>
                                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', textAlign: 'center', width: '100px' }}>{t('sopVersion')}</th>
                                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', textAlign: 'center', width: '150px' }}>{t('expiration')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {record.userCerts != null && record.userCerts !== '' && record.userCerts.userCerts != null && 
                                                    record.userCerts.userCerts !== '' && record.userCerts.userCerts.length > 0 ?
                                                    record.userCerts.userCerts.map((user, i) => {
                                                        return(
                                                            <tr key={'user-' + i}>
                                                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{user.firstName + ' ' + user.lastName}</td>
                                                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>{user.cert != null && user.cert !== '' && user.cert.documentVersion}</td>
                                                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>{user.cert != null && user.cert !== '' && user.cert.expiration != null ? <DateDisplay value={user.cert.expiration} format={dateFormat}/> : t('na')}</td>
                                                            </tr>
                                                        )
                                                    }) :
                                                    <tr>
                                                        <td colSpan={3}>{t('na')}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ width: '80%', margin: '0 auto' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <br/>
                                <Form renderer={renderer}
                                    defaultFields={JSON.parse(record.formValues)}
                                    onChange={null}
                                    disabled={true}
                                    print={true}>
                                    <br/>
                                </Form>
                            </Grid>
                        </Grid>
                    }
                </Aux>
            }
        </div>
    )
}