import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import Unlock from '../General/Unlock';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import ActionBar from '../UI/Buttons/ActionBar';
import SectionTitle from '../UI/Typography/SectionTitle';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { fieldSecurity } from '../../helpers/securityHelper';

class ChangeRequest extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: null,
            status: null,
            statusSel: null,
            statuses: [],
            requestDate: new Date(),
            dueDate: null,
            requestor: null,
            requestorSel: null,
            users: [],
            managers: [],
            qas: [],
            managedDocument: null,
            docSel: null,
            managedDocuments: [],
            description: '',
            reason: null,
            reasonSel: null,
            reasons: [],
            assignee: null,
            assigneeSel: null,
            authors: [],
            changeDescription: '',
            completedDate: null,
            documents: [],
            requestSignature: null,
            reviewer: null,
            signer: null,
            signed: false,
            security: [],
            requested: false,
            approved: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            optionsMenu: [],
            flagForSignature: 0,
            enableSave: true,
            sigData: null,
            fieldAccess: false
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.status, common.cacheValues.changeReason, common.cacheValues.managedDocument,
            common.cacheValues.documentAuthor], this.props.auth.constants, null, this.props.auth.user.tenant);
        const userValues = await ValueHelper.screenUsers(common.screens.changeRequest, this.props.auth.user.tenant);
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        let requestorSel = userValues.create.find(x => x.value === this.props.auth.user.id);
        this.setState({
            _id: this.props.match.params.id,
            statuses: cacheValues.statuses,
            users: userValues.create,
            managers: userValues.review,
            qas: userValues.approve,
            reasons: cacheValues.changeReasons,
            managedDocuments: cacheValues.managedDocuments,
            authors: cacheValues.documentAuthors,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            requestor: this.props.auth.user.id,
            requestorSel: requestorSel,
            security: this.props.permission,
            fieldAccess: this.props.permission.create
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/changerequests', label: this.props.t('changeRequests'), screen: this.props.auth.screenDefs.ChangeRequests},
                    { path: 'active', label: this.props.t('changeRequest'), screen: this.props.auth.screenDefs.ChangeRequest}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        let record = await axios.get('/api/changerequest/' + this.state._id);
        let constants = this.props.auth.constants;
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let requestorSel = this.state.users.find(x => x.value === record.data.requestor);
        let reasonSel = this.state.reasons.find(x => x.value === record.data.reason);
        let docSel = this.state.managedDocuments.find(x => x.value === record.data.managedDocument);
        let assigneeSel = record.data.assignee != null ? this.state.authors.find(x => x.value === record.data.assignee) : null;
        let signed = record.data.status === constants.statuses.signed;
        const fieldAccess = record.data.status !== constants.statuses.released ? fieldSecurity(this.props.permission, record.data.status, this.props.auth.common, this.props.auth.constants) : false;
        let requested = record.data.status != null && record.data.status === constants.statuses.requested;
        let approved = record.data.status != null && record.data.status !== constants.statuses.new &&
                        record.data.status !== constants.statuses.requested &&
                        record.data.status !== constants.statuses.rejected;
        let completeSigSec = record.data.status != null && record.data.status !== constants.statuses.new && this.state.security?.complete === true ? true : false;
        let reviewSigSec = record.data.status != null && record.data.status !== constants.statuses.new && 
                           record.data.status !== constants.statuses.inprogress && record.data.status !== constants.statuses.requested && this.state.security?.review === true ? true : false;
        let approveSigSec = record.data.status != null && record.data.status !== constants.statuses.new && record.data.status !== constants.statuses.requested &&
                            record.data.status !== constants.statuses.inprogress && record.data.status !== constants.statuses.released && this.state.security?.approve === true ? true : false;
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: requestorSel != null ? [requestorSel] : [], name: t('request'), object: record.data._id,
                title: "Requested By", setId: () => this.loadRecord(), signature: record.data.requestSignature,
                objectType: this.props.auth.constants.objectIds.changeRequest, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, saveCallback: this.saveCallback
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: this.state.managers, name: t('approveReject'),
                title: "Reviewed By", setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.changeRequest, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: this.state.qas, name: t('signoff'),
                title: "Signed Off By", setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer,
                objectType: this.props.auth.constants.objectIds.changeRequest, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.new ? 1 :
                                    record.data.status === this.props.auth.constants.statuses.released ? 2 :
                                    record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        this.setState({
            number: record.data.number,
            status: record.data.status,
            requestDate: record.data.requestDate,
            dueDate: record.data.dueDate,
            requestor: record.data.requestor,
            managedDocument: record.data.managedDocument,
            description: record.data.description,
            reason: record.data.reason,
            assignee: record.data.assignee,
            changeDescription: record.data.changeDescription,
            completedDate: record.data.completedDate,
            documents: record.data.documents,
            requestSignature: record.data.requestSignature,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            statusSel: statusSel,
            requestorSel: requestorSel,
            reasonSel: reasonSel,
            docSel: docSel,
            assigneeSel: assigneeSel,
            signed: signed,
            requested: requested,
            approved: approved,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            sigData: record.data.sigData,
            fieldAccess: fieldAccess
        }, () => {
            const crumbs = [
                { path: '/#/changerequests', label: this.props.t('changeRequests'), screen: this.props.auth.screenDefs.ChangeRequests},
                { path: 'active', label: this.props.t('changeRequest') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.ChangeRequest}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    };

    approvalClick = (status) => (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._approvalClick(status, e));
    };

    _approvalClick = async (status, e) => {
        if(this.state._id != null && this.state._id !== '' && status != null && status !== ''){
            let data = {
                _id: this.state._id,
                status: status,
                changeDescription: this.state.changeDescription
            };
            try {
                let result = await axios.post('/api/changerequestapproval', data);
                this.setState({enableSave: true});
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            }
        }
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.save()})
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            _id: this.state._id,
            status: this.state.status,
            requestDate: this.state.requestDate,
            dueDate: this.state.dueDate,
            requestor: this.state.requestor,
            managedDocument: this.state.managedDocument,
            description: this.state.description,
            reason: this.state.reason,
            assignee: this.state.assignee,
            changeDescription: this.state.changeDescription,
            completedDate: this.state.completedDate,
            sigData: this.state.sigData
        };
        try {
            let result = await axios.post('/api/changerequest', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/changerequest/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.requestDate == null || state.requestDate === '')
            errors[t('requestDate')] = t('required');
        if(state.requestor == null || state.requestor === '')
            errors[t('requestor')] = t('required');
        if(state.managedDocument == null || state.manageDocument === '')
            errors[t('managedDocument')] = t('required');
        if(state.description == null || state.description === '')
            errors[t('description')] = t('required');
        if(state.reason == null || state.reason === '')
            errors[t('reason')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    render(){
        const errors = this.props.errors;
        const signed = this.state.signed;
        const approved = this.state.approved;
        const fieldAccess = this.state.fieldAccess;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        constants={this.props.auth.constants}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.changeRequest}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                    />
                </BreadcrumbBar>
                <Panel>
                    <SectionTitle title={t('request')}/>
                    <Grid container spacing={3}>
                        {this.state.number != null && this.state.number !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl data-cy='number'>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            <FormControl data-cy='status'>
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSel != null ? this.state.statusSel.label : ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect 
                                onChange={this.changeDate('requestDate')}
                                value={this.state.requestDate}
                                helperText={errors[t('requestDate')]}
                                error={errors[t('requestDate')] != null ? true : false}
                                disabled={!fieldAccess}
                                label={t('requestDate')}
                                format={this.props.auth.user.dateFormat}
                                data-cy='requestDate'
                            />
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.requestorSel}
                                options={this.state.users}
                                onChange={this.changeAuto('requestorSel', 'requestor')}
                                error={errors[t('requestor')] != null ? true : false}
                                helperText={errors[t('requestor')]}
                                label={t('requestor')}
                                disabled={!fieldAccess}
                                data-cy='requestor'
                            />
                        </Grid>
                        <Grid item xs={10} sm={6}>
                            <AutoCompleteField
                                value={this.state.docSel}
                                options={this.state.managedDocuments}
                                onChange={this.changeAuto('docSel', 'managedDocument')}
                                error={errors[t('managedDocument')] != null ? true : false}
                                helperText={errors[t('managedDocument')]}
                                label={t('document')}
                                disabled={!fieldAccess}
                                required
                                data-cy='document'
                            />
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.reasonSel}
                                options={this.state.reasons}
                                onChange={this.changeAuto('reasonSel', 'reason')}
                                error={errors[t('reason')] != null ? true : false}
                                helperText={errors[t('reason')]}
                                label={t('reason')}
                                disabled={!fieldAccess}
                                data-cy='reason'
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.changeDate('dueDate')}
                                value={this.state.dueDate}
                                helperText={errors[t('dueDate')]}
                                error={errors[t('dueDate')] != null ? true : false}
                                disabled={!fieldAccess}
                                label={t('dueDate')}
                                format={this.props.auth.user.dateFormat}
                                data-cy='dueDate'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={this.state.description} onChange={this.changeValue} name="description" multiline={true} rows="4"
                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('description')} data-cy='description'
                            error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                        </Grid>
                    </Grid>
                    {this.state.status === this.props.auth.constants.statuses.requested && !approved &&
                        <Grid container spacing={3}>
                            <Grid item sm={8}>
                                <Button variant="outlined" size="small" color="primary"  data-cy='release'
                                onClick={this.approvalClick(this.props.auth.constants.statuses.released)}>{t('release')}</Button>&nbsp;&nbsp;
                                <Button variant="outlined" size="small" color="primary" data-cy='reject'
                                onClick={this.approvalClick(this.props.auth.constants.statuses.rejected)}>{t('reject')}</Button>
                            </Grid>
                            <Grid item sm={12}>
                                <TextField value={this.state.changeDescription} onChange={this.changeValue} name="changeDescription"
                                multiline={true} rows="4"
                                size="medium" fullWidth={true} disabled={signed} label={t('changeDescription')} data-cy='changeDescription'
                                error={errors[t('changeDescription')] != null ? true : false} helperText={errors[t('changeDescription')]}/>
                            </Grid>
                        </Grid>
                    }
                    {approved && this.state.reviewer != null &&
                        <Aux>
                            <br/>
                            <br/>
                            <SectionTitle title={t('review')}/>
                            {this.state.status === this.props.auth.constants.statuses.rejected &&
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('rejectedBy')}</FormLabel>
                                            {this.state.reviewer.user.firstName + ' ' + this.state.reviewer.user.lastName}
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <TextField value={this.state.changeDescription} onChange={this.changeValue} name="changeDescription"
                                        multiline={true} rows="4" data-cy='changeDescription'
                                        size="medium" fullWidth={true} disabled={true} label={t('changeDescription')}
                                        error={errors[t('changeDescription')] != null ? true : false} helperText={errors[t('changeDescription')]}/>
                                    </Grid>
                                </Grid>
                            }
                            {this.state.status !== this.props.auth.constants.statuses.rejected &&
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('approvedBy')}</FormLabel>
                                            {this.state.reviewer.user.firstName + ' ' + this.state.reviewer.user.lastName}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            }
                        </Aux>
                    }
                    {approved && this.state.reviewer != null && this.state.status !== this.props.auth.constants.statuses.rejected &&
                        <Aux>
                            <br/>
                            <br/>
                            <SectionTitle title={t('action')}/>
                            <Grid container spacing={3}>
                                <Grid item xs={8} sm={4}>
                                    <AutoCompleteField
                                        value={this.state.assigneeSel}
                                        options={this.state.authors}
                                        onChange={this.changeAuto('assigneeSel', 'assignee')}
                                        error={errors[t('assignee')] != null ? true : false}
                                        helperText={errors[t('assignee')]}
                                        label={t('assignee')}
                                        disabled={signed}
                                        data-cy='assignee'
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <DateSelect
                                        onChange={this.changeDate('completedDate')}
                                        value={this.state.completedDate}
                                        helperText={errors[t('completedDate')]}
                                        error={errors[t('completedDate')] != null ? true : false}
                                        disabled={signed}
                                        label={t('completed')}
                                        format={this.props.auth.user.dateFormat}
                                        data-cy='completed'
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <TextField value={this.state.changeDescription} onChange={this.changeValue} name="changeDescription"
                                    multiline={true} rows="4" data-cy='changeDescription'
                                    variant="outlined" size="medium" fullWidth={true} disabled={signed} label={t('changeDescription')}
                                    error={errors[t('changeDescription')] != null ? true : false} helperText={errors[t('changeDescription')]}/>
                                </Grid>
                            </Grid>
                        </Aux>
                    }
                </Panel>
                <Grid container spacing={3}>
                    <Grid item xs={8} sm={4}>
                        <Button variant="contained" color="primary" onClick={this.save} disabled={signed && !this.state.enableSave} data-cy='save'>
                            {t('save')}
                        </Button>&nbsp;&nbsp;
                        {this.state.status === this.props.auth.constants.statuses.signed &&
                            <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.changeRequest} callback={this.loadRecord} data-cy='unlock'/>
                        }
                    </Grid>
                </Grid>
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.cleaningSolution}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ChangeRequest));
