import React, { Component } from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty'; 
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import Document from '../General/Document';
import DateSelect from '../UI/DateSelect/DateSelect';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import SectionTitle from '../UI/Typography/SectionTitle';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel, FormControlLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import TextField from '../UI/TextField/TextField';
import { Link } from 'react-router-dom'
import ActionBar from '../UI/Buttons/ActionBar';
import Panel from '../UI/Panel/Panel';
import ValueHelper from '../../helpers/valueHelper';
import { completeSigSecurity, fieldSecurity } from '../../helpers/securityHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import CcxHelper from '../../helpers/ccxHelper';
import Switch from '../UI/Switch/Switch';
import { NumericFormat } from 'react-number-format';
import ModalDialog from '../UI/Dialog/ModalDialog';
import ConfirmDelete from '../General/ConfirmDelete';

class CcxPosting extends Component{
    constructor(props){
        super(props);
        this.state = {
            number: '',
            _id: '',
            s2sClientId: '',
            ccxCompanyCode: '',
            item: '',
            sale: '',
            purchaseOrder: '',
            productName: '',
            lot: '',
            lotSel: null,
            lots: [],
            category1: '',
            category1Sel: null,
            category1s: [],
            category2: '',
            category2Sel: null,
            category2s: [],
            type: '',
            typeSel: null,
            types: [],
            harvestDate: '',
            periodStartDate: '',
            periodEndDate: '',
            strainType: '',
            strainTypeSel: null,
            strainTypes: [],
            listingVolume: '',
            all: '',
            allSel: null,
            alls: [],
            price: '',
            orderStatus: '',
            orderStatusSel: null,
            orderStatuses: [],
            thcSpot: '',
            cbdSpot: '',
            cbgSpot: '',
            cbnSpot: '',
            growMethod: '',
            growMethodSel: null,
            growMethod1: '',
            growMethod1Sel: '',
            growMethods: [],
            growMethod1s: [],
            growMedium: '',
            irradiated: '',
            irradiatedSel: null,
            irradiateds: [],
            metals: '',
            metalsSel: null,
            testedStatuses: [],
            microbials: '',
            notes: '',
            otherCannabinoids: '',
            cureTime: '',
            flushTime: '',
            packagingDate: '',
            terpenePercent: '',
            dryMethod: '',
            dryMethodSel: null,
            dryMethods: [],
            trimMethod: '',
            trimMethods: [],
            trimMethodSel: null,
            coaDate: '',
            photos: [],
            coas: [],
            videos: [],
            security: {},
            status: '',
            statusSel: null,
            statuses: [],
            certifications: [],
            certificationSel: [],
            certificationOptions: [],
            lotObj: null,
            batchObj: null,
            batch: '',
            enableSave: true,
            moisture: '',
            fieldAccess: false,
            completeSigSec: false,
            signer: null,
            flagForSignature: 0,
            optionsMenu: [],
            sigData: null,
            items: [],
            itemSel: null,
            itemObj: null,
            transactionTypes: [],
            bcDirectCertified: false,
            bcDirectQualified: false,
            orderingUnit: 'KG',
            enableDelete: false,
            flowerItems: [],
            trimItems: [],
            cbcSpot: '',
            thcMin: '',
            thcMax: '',
            cbdMin: '',
            cbdMax: '',
            lineage: '',
            budDistribution: '',
            delta8: '',
            cone: '',
            send: false,
            availableDate: '',
            rooms: [],
            internal: false,
            changeStatus: false,
            newStatus: '',
            newStatusSel: null,
            deleteOpen: false,
            uoms: []
        };
    }
    async componentDidMount(){
        const constants = this.props.auth.constants;
        const locale = this.props.auth.user.locale;
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.ccxFlowerItem, common.cacheValues.ccxTrimItem, common.cacheValues.ccxPostingStatus, 
            common.cacheValues.growingRoom, common.cacheValues.quantityUOM], this.props.auth.constants, null, this.props.auth.user.tenant);
        let statusSel = cacheValues.ccxPostingStatuses.find(x => x.value === constants.ccxPostingStatuses.new);
        let transactionTypes = CcxHelper.deriveValues(common.ccxValueTypes.transactionType, locale);
        let typeSel = transactionTypes.find(x => x.value === 'S');
        let fieldAccess = fieldSecurity(this.props.permission, statusSel != null ? statusSel.value : '', common, this.props.auth.constants, common.statusTypes.ccxPosting);
        this.setState({
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null,
            sale: this.props.sale != null && this.props.sale !== '' ? this.props.sale : null,
            strainTypes: CcxHelper.deriveValues(common.ccxValueTypes.strainTypes, locale),
            alls: CcxHelper.deriveValues(common.ccxValueTypes.allNoneValues, locale),
            dryMethods: CcxHelper.deriveValues(common.ccxValueTypes.dryType, locale),
            trimMethods: CcxHelper.deriveValues(common.ccxValueTypes.trimMethod, locale),
            irradiateds: CcxHelper.deriveValues(common.ccxValueTypes.irradiated, locale),
            testedStatuses: CcxHelper.deriveValues(common.ccxValueTypes.testedStatus, locale),
            certificationOptions: CcxHelper.deriveValues(common.ccxValueTypes.certification, locale),
            category1s: CcxHelper.deriveValues(common.ccxValueTypes.category1, locale),
            flowerSubCats: CcxHelper.deriveValues(common.ccxValueTypes.flowerCategory2, locale),
            trimSubCats: CcxHelper.deriveValues(common.ccxValueTypes.trimCategory2, locale),
            statuses: cacheValues.ccxPostingStatuses,
            statusSel: statusSel,
            status: constants.ccxPostingStatuses.new,
            category2s: CcxHelper.deriveValues(common.ccxValueTypes.flowerCategory2, locale),
            growMethods: CcxHelper.deriveValues(common.ccxValueTypes.growMethod, locale),
            growMethod1s: CcxHelper.deriveValues(common.ccxValueTypes.growMethod1, locale),
            transactionTypes: transactionTypes,
            type: 'S',
            typeSel: typeSel,
            fieldAccess,
            security: this.props.permission,
            //s2sClientId: this.props.auth.user.ccxClientId,
            ccxCompanyCode: this.props.auth.user.ccxCompanyCode,
            flowerItems: cacheValues.ccxFlowerItems,
            trimItems: cacheValues.ccxTrimItems,
            rooms: cacheValues.rooms,
            internal: this.props.auth.user.internal,
            uoms: cacheValues.quantityUOMs
        }, async () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/ccxpostings', label: this.props.t('ccxPostings'), screen: this.props.auth.screenDefs.ccxPostings},
                    { path: 'active', label: this.props.t('ccxPosting'), screen: this.props.auth.screenDefs.ccxPosting}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        })
    }

    loadRecord = async () => {
        const constants = this.props.auth.constants;
        let record = await axios.get('/api/ccxposting/' + this.state._id);
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let lots = record.data.lots;
        if(lots != null && lots.length > 0){
            for(let row of lots){
                let lotUOM = this.state.uoms.find(x => x.value === row.uom);
                row.label += ' (' + row.available + lotUOM.label + ')';
            }
        }
        let lotSel = record.data.lot != null ? record.data.lots.find(x => x.value === record.data.lot) : null;
        let strainTypeSel = this.state.strainTypes.find(x => x.value === record.data.strainType);
        let items = record.data.category1 === '11' ? this.state.flowerItems : record.data.category1 === '68' ? this.state.trimItems : [];
        let category1Sel = this.state.category1s.find(x => x.value === record.data.category1);
        let category2Sel = record.data.category2 != null ? this.state.category2s.find(x => x.value === record.data.category2) : null;
        let growMethodSel = this.state.growMethods.find(x => x.value === record.data.growMethod);
        let growMethod1Sel = this.state.growMethod1s.find(x => x.value === record.data.growMethod1);
        let allSel = this.state.alls.find(x => x.value === record.data.all);
        let irradiatedSel = this.state.irradiateds.find(x => x.value === record.data.irradiated);
        let dryMethodSel = this.state.dryMethods.find(x => x.value === record.data.dryMethod);
        let trimMethodSel = this.state.trimMethods.find(x => x.value === record.data.trimMethod);
        let metalsSel = this.state.testedStatuses.find(x => x.value === record.data.metals);
        let itemSel = record.data.item != null ? items.find(x => x.value === record.data.item) : null;
        let typeSel = this.state.transactionTypes.find(x => x.value === record.data.type);
        let certificationSel = [];
        if(record.data.certifications != null && record.data.certifications.length > 0){
            for(let row of record.data.certifications){
                certificationSel.push(this.state.certificationOptions.find(x => x.value === row));
            }
        }
        let enableDelete = false;
        if(this.props.permission.delete && (record.data.status === constants.ccxPostingStatuses.new || record.data.status === constants.ccxPostingStatuses.inprogress)){
            enableDelete = true;
        }
        const fieldAccess = fieldSecurity(this.props.permission, statusSel != null ? statusSel.value : '', common, this.props.auth.constants, common.statusTypes.ccxPosting);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, this.props.auth.user.id, statusSel != null ? statusSel.value : '', common, this.props.auth.constants, common.statusTypes.ccxPosting);
        this.setState({
            number: record.data.number,
            status: record.data.status,
            statusSel: statusSel,
            //s2sClientId: record.data.s2sClientId,
            ccxCompanyCode: record.data.ccxCompanyCode,
            item: record.data.item != null ? record.data.item : '',
            itemObj: record.data.itemObj,
            itemSel: itemSel,
            sale: record.data.sale,
            purchaseOrder: record.data.purchaseOrder,
            productName: record.data.productName,
            lot: record.data.lot,
            lotNumber: record.data.lotObj != null ? record.data.lotObj.number : '',
            category1: record.data.category1,
            category1Sel: category1Sel,
            category2: record.data.category2,
            category2Sel: category2Sel,
            type: record.data.type,
            typeSel: typeSel,
            harvestDate: record.data.harvestDate,
            periodStartDate: record.data.periodStartDate,
            periodEndDate: record.data.periodEndDate,
            strainType: record.data.strainType,
            strainTypeSel: strainTypeSel,
            listingVolume: record.data.listingVolume,
            all: record.data.all,
            price: record.data.price,
            orderStatus: record.data.orderStatus,
            thcSpot: record.data.thcSpot,
            cbdSpot: record.data.cbdSpot,
            cbgSpot: record.data.cbgSpot,
            cbnSpot: record.data.cbnSpot,
            cbcSpot: record.data.cbcSpot,
            growMethod: record.data.growMethod,
            growMedium: record.data.growMedium,
            metals: record.data.metals,
            microbials: record.data.microbials,
            moisture: record.data.moisture,
            notes: record.data.notes,
            otherCannabinoids: record.data.otherCannabinoids,
            cureTime: record.data.cureTime,
            flushTime: record.data.flushTime,
            packagingDate: record.data.packagingDate,
            terpenePercent: record.data.terpenePercent,
            dryMethod: record.data.dryMethod,
            trimMethod: record.data.trimMethod,
            coaDate: record.data.coaDate,
            attachments: record.data.attachments,
            coas: record.data.coas,
            photos: record.data.photos,
            videos: record.data.videos,
            lotSel: lotSel,
            lots: lots,
            certifications: record.data.certifications,
            lotObj: record.data.lotObj,
            batch: record.data.batch != null ? record.data.batch._id : null,
            batchObj: record.data.batch,
            certificationSel: certificationSel,
            growMethodSel: growMethodSel,
            allSel: allSel,
            irradiatedSel: irradiatedSel,
            irradiated: record.data.irradiated,
            dryMethodSel: dryMethodSel,
            trimMethodSel: trimMethodSel,
            metalsSel: metalsSel,
            fieldAccess: fieldAccess,
            completeSigSec: completeSigSec,
            bcDirectCertified: record.data.bcDirectCertified,
            bcDirectQualified: record.data.bcDirectQualified,
            orderingUnit: record.data.orderingUnit,
            enableDelete: enableDelete,
            items: items,
            thcMin: record.data.thcMin,
            thcMax: record.data.thcMax,
            cbdMin: record.data.cbdMin,
            cbdMax: record.data.cbdMax,
            lineage: record.data.lineage,
            budDistribution: record.data.budDistribution,
            delta8: record.data.delta8,
            cone: record.data.cone,
            availableDate: record.data.availableDate,
            growMethod1: record.data.growMethod1,
            growMethod1Sel: growMethod1Sel,
            deleteOpen: false
        }, () => {
            const crumbs = [
                { path: '/#/ccxpostings', label: this.props.t('ccxPostings'), screen: this.props.auth.screenDefs.ccxPostings},
                { path: 'active', label: this.props.t('ccxPosting') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.ccxPosting}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeValue = e => {
        const name = e.target.name;
        const value = e.target.value
        this.setState({[name]: value});
    };

    changeNumber = e => {
        const name = e.target.name;
        const value = e.target.value
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(data === 'lot'){
            let harvestDate = this.state.harvestDate;
            if(newValue != null){
                let result = await axios.get('/api/lot/' + newValue.value);
                let batchResult = await axios.get('/api/lotbatch/' + newValue.value);
                if(batchResult.data != null){
                    harvestDate = batchResult.data.harvestDate;
                }
                let first = true;
                let growMethod = null;
                let growMethod1 = null;
                if(batchResult.data.roomHistory != null){
                    for(let row of batchResult.data.roomHistory){
                        let room = this.state.rooms.find(x => x.value === row.room);
                        if(room.growRoomType != null){
                            if(first){
                                growMethod = CcxHelper.growMethod(room.growRoomType, this.props.auth.constants);
                                first = false;
                            } else {
                                growMethod1 = CcxHelper.growMethod1(room.growRoomType, this.props.auth.constants);
                                break;
                            }
                        }
                    }
                }
                let growMethodSel = this.state.growMethods.find(x => x.value === growMethod);
                let growMethod1Sel = this.state.growMethod1s.find(x => x.value === growMethod1);
                let packagingDate = this.state.packagingDate;
                if(newValue.packagedOn != null && newValue.packagedOn !== '')
                    packagingDate = newValue.packagedOn;
                this.setState({
                    lotObj: result.data, 
                    batchObj: batchResult.data, 
                    batch: batchResult.data != null ? batchResult.data._id : null,
                    harvestDate: harvestDate,
                    packagingDate: packagingDate,
                    growMethod: growMethod,
                    growMethodSel: growMethodSel,
                    growMethod1Sel: growMethod1Sel
                });
            } else {
                this.setState({lotObj: null, batchObj: null});
            }
        } else if(data === 'item'){
            if(newValue != null){
                let itemStrain = await axios.get('/api/itemstrain/' + newValue.value);
                let lotRes = await axios.get('/api/itemlots/' + newValue.value);
                let lots = lotRes.data;
                for(let row of lots){
                    if(row.available != null && row.available !== ''){
                        let lotUOM = this.state.uoms.find(x => x.value === row.uom);
                        row.label += ' (' + row.available + lotUOM.label + ')';
                    }
                }
                let lineage = this.state.lineage;
                if(itemStrain.data != null && itemStrain.data.strain != null){
                    if(lineage == null || lineage === ''){
                        lineage = itemStrain.data.strain.lineage;
                    }
                }
                let strainType = itemStrain.data.strain.type === this.props.auth.constants.strainTypes.sativa ? common.ccxStrainTypeValues.sativa : 
                    itemStrain.data.strain.type === this.props.auth.constants.strainTypes.indica ? common.ccxStrainTypeValues.indica : 
                    itemStrain.data.strain.type === this.props.auth.constants.strainTypes.hybrid ? common.ccxStrainTypeValues.hybrid : null;
                let strainTypeSel = this.state.strainTypes.find(x => x.value === strainType);
                this.setState({lots: lots, itemObj: itemStrain.data, lineage: lineage, strainType: strainType, strainTypeSel: strainTypeSel});
            } else {
                this.setState({lots: [], itemObj: null, lineage: '', batchObj: null, lotSel: null, lot: ''});
            }
        } else if (data === 'category1'){
            if(newValue != null){
                let items = []
                if(newValue.value === '11'){
                    items = this.state.flowerItems;
                } else if(newValue.value === '68'){
                    items = this.state.trimItems;
                }
                this.setState({items: items});
            } else {
                this.setState({items: []});
            }
        }
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    };

    changeMulti = (name, data) => (e, newValue) => {
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    send = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({send: true, enableSave: false}, () => {
            this._save();
        });
    }

    save = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false, send: false }, () => this._save());
    };

    _save = async (e) => {
        let valid = this.validate(this.state.send);
        if(!valid){
            this.setState({enableSave: true});
            return;
        }
        let data = {
            _id: this.state._id,
            status: this.state.status,
            //s2sClientId: this.state.s2sClientId,
            ccxCompanyCode: this.state.ccxCompanyCode,
            lot: this.state.lot,
            batch: this.state.batch,
            category1: this.state.category1,
            category2: this.state.category2,
            type: this.state.type,
            harvestDate: this.state.harvestDate,
            periodStartDate: this.state.periodStartDate,
            periodEndDate: this.state.periodEndDate,
            strainType: this.state.strainType,
            listingVolume: this.state.listingVolume,
            all: this.state.all,
            price: this.state.price,
            orderStatus: this.state.orderStatus,
            thcSpot: this.state.thcSpot,
            cbdSpot: this.state.cbdSpot,
            cbgSpot: this.state.cbgSpot,
            cbnSpot: this.state.cbnSpot,
            cbcSpot: this.state.cbcSpot,
            thcMin: this.state.thcMin,
            thcMax: this.state.thcMax,
            cbdMin: this.state.cbdMin,
            cbdMax: this.state.cbdMax,
            growMethod: this.state.growMethod,
            growMethod1: this.state.growMethod1,
            growMedium: this.state.growMedium,
            irradiated: this.state.irradiated,
            metals: this.state.metals,
            microbials: this.state.microbials,
            moisture: this.state.moisture,
            notes: this.state.notes,
            otherCannabinoids: this.state.otherCannabinoids,
            cureTime: this.state.cureTime,
            flushTime: this.state.flushTime,
            packagingDate: this.state.packagingDate,
            terpenePercent: this.state.terpenePercent,
            dryMethod: this.state.dryMethod,
            trimMethod: this.state.trimMethod,
            coaDate: this.state.coaDate,
            certifications: this.state.certifications,
            item: this.state.item,
            bcDirectCertified: this.state.bcDirectCertified,
            bcDirectQualified: this.state.bcDirectQualified,
            organic: this.state.organic,
            euGmpCertified: this.state.euGmpCertified,
            gmpCertified: this.state.gmpCertified,
            gacpCertified: this.state.gacpCertified,
            cumcsCertified: this.state.cumcsCertified,
            iqcGapCertified: this.state.iqcGapCertified,
            orderingUnit: this.state.orderingUnit,
            lineage: this.state.lineage,
            budDistribution: this.state.budDistribution,
            delta8: this.state.delta8,
            cone: this.state.cone,
            send: this.state.send,
            availableDate: this.state.availableDate
        };
        try {
            let result = await axios.post('/api/ccxposting', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/ccxposting/' + result.data.id);
            this.setState({_id: result.data.id != null ? result.data.id : this.state._id, enableSave: true, send: false}, () => {
                this.loadRecord();
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true, send: false }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });      
        }
    }

    validate(full){
        const t = this.props.t;
        const state = this.state;
        let errors = {};
        if(state.category1 == null || state.category1 === '')
            errors[t('category') + '1'] = t('required');
        if(state.item == null || state.item === '')
            errors[t('item')] = t('required');
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(full === true){
            if(state.category1 != null && state.category1 !== '' && state.category1 === 'Flower'){
                if(state.category2 == null || state.category2 === '')
                    errors[t('category') + '2'] = t('required');
            }
            if(state.lot == null || state.lot === '')
                errors[t('lot')] = t('required');
            if(state.type == null || state.type === '')
                errors[t('type')] = t('required');
            if(state.harvestDate == null || state.harvestDate === '')
                errors[t('harvestDate')] = t('required');
            if(state.strainType == null || state.strainType === '')
                errors[t('strainType')] = t('required');
            if(state.listingVolume == null || state.listingVolume === '')
                errors[t('listingVolume')] = t('required');
            if(state.thcSpot == null || state.thcSpot === '')
                errors[t('thcSpot')] = t('required');
            if(state.cbdSpot == null || state.cbdSpot === '')
                errors[t('cbdSpot')] = t('required');
            if(state.growMethod == null || state.growMethod === '')
                errors[t('growMethod')] = t('required');
            if(state.irradiated == null || state.irradiated === '')
                errors[t('irradiated')] = t('required');
            if(state.trimMethod == null || state.trimMethod === '')
                errors[t('trimMethod')] = t('required');
            if(state.availableDate == null || state.availableDate === '')
                errors[t('availableDate')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableDelete){
            this.setState({deleteOpen: false})
            return;
        }
        if(this.state._id == null || this.state._id === ''){
            this.props.history.goBack();
        }
        this.setState({'enableSave': false, deleteOpen: false}, async () => {
            try {
                let result = await axios.delete('/api/ccxposting/' + this.state._id);
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({ enableSave: true, deleteOpen: false }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
                });      
            }
        });
    };

    toggleChangeStatus = () => {
        this.setState({changeStatus: !this.state.changeStatus});
    }

    performChangeStatus = (e) => {
        if(!this.state.internal)
            return;
        this.setState({'enableSave': false}, async () => {
            if(this.state.newStatus == null || this.state.newStatus === ''){
                this.setState({errors: {newStatus: 'required'}, enableSave: true});
            }
            if(this.state.status === this.state.newStatus){
                this.setState({errors: {newStatus: 'This is the same as the current status'}, enableSave: true});
            }
            try {
                let result = await axios.post('/api/ccxpostingstatus', {_id: this.state._id, status: this.state.newStatus});
                this.setState({enableSave: true, changeStatus: false}, () => {
                    this.loadRecord();
                });
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            } catch(err){
                this.setState({ enableSave: true }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
                });   
            }
        });
    }

    render(){
        const t = this.props.t;
        const security = this.state.security;
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                        constants={this.props.auth.constants}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                    />
                </BreadcrumbBar>
                <Panel>
                    <SectionTitle title={t('basicInformation')}/>
                    <Grid container spacing={3}>
                        {this.state.number != null && this.state.number !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            <FormControl>
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSel != null ? this.state.statusSel.label : ''}
                            </FormControl>
                        </Grid>
                        {/*<Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <TextField value={this.state.s2sClientId} onChange={this.changeValue} name="s2sClientId"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('ccxClientId')}  
                                error={errors[t('s2sClientId')] != null ? true : false} helperText={errors[t('s2sClientId')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('ccxClientId')}</FormLabel>
                                    {this.state.s2sClientId}
                                </FormControl>
                            }
                        </Grid>*/}
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <TextField value={this.state.ccxCompanyCode} onChange={this.changeValue} name="ccxCompanyCode"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('ccxCompanyCode')} required
                                error={errors[t('ccxCompanyCode')] != null ? true : false} helperText={errors[t('ccxCompanyCode')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('ccxCompanyCode')}</FormLabel>
                                    {this.state.ccxCompanyCode}
                                </FormControl>
                            }
                        </Grid>
                        {this.state.orderStatusSel != null && 
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('orderStatus')}</FormLabel>
                                    {this.state.orderStatusSel.label}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.typeSel}
                                    options={this.state.transactionTypes}
                                    onChange={this.changeAuto('typeSel', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]} 
                                    label={t('type')}
                                    disabled={true}
                                    required
                                />
                            }
                            {!fieldAccess && this.state.typeSel != null &&
                                <FormControl>
                                    <FormLabel>{t('type')}</FormLabel>
                                    {this.state.typeSel.label}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.category1Sel}
                                    options={this.state.category1s}
                                    onChange={this.changeAuto('category1Sel', 'category1')}
                                    error={errors[t('category1')] != null ? true : false}
                                    helperText={errors[t('category1')]} 
                                    label={t('category')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            }
                            {!fieldAccess && this.state.category1Sel != null &&
                                <FormControl>
                                    <FormLabel>{t('category')}</FormLabel>
                                    {this.state.category1Sel.label}
                                </FormControl>
                            }
                        </Grid>
                        {this.state.category1Sel != null && this.state.category1 === '11' && fieldAccess &&
                            <Grid item xs={6} sm={3}>
                                 <AutoCompleteField
                                    value={this.state.category2Sel}
                                    options={this.state.flowerSubCats}
                                    onChange={this.changeAuto('category2Sel', 'category2')}
                                    error={errors[t('category') + '2'] != null ? true : false}
                                    helperText={errors[t('category') + '2']} 
                                    label={t('ccxFlowerSubCat')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            </Grid>
                        }
                        {this.state.category2Sel != null && !fieldAccess && this.state.category1Sel != null && this.state.category1 === '11' &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('ccxFlowerSubCat')}</FormLabel>
                                    {this.state.category2Sel.label}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.category1Sel != null && this.state.category1 === '68' && fieldAccess &&
                            <Grid item xs={6} sm={3}>
                               <AutoCompleteField
                                    value={this.state.category2Sel}
                                    options={this.state.trimSubCats}
                                    onChange={this.changeAuto('category2Sel', 'category2')}
                                    error={errors[t('category') + '2'] != null ? true : false}
                                    helperText={errors[t('category') + '2']} 
                                    label={t('ccxTrimSubCat')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            </Grid>
                        }
                        {this.state.category1Sel != null && this.state.category1 === '68' && !fieldAccess && this.state.category2Sel != null &&
                            <Grid item xs={6} sm={3}>
                               <FormControl>
                                    <FormLabel>{t('ccxTrimSubCat')}</FormLabel>
                                    {this.state.category2Sel.label}
                               </FormControl>
                            </Grid>
                        }
                        {this.state.category1Sel != null && this.state.items != null && this.state.items.length > 0 && fieldAccess &&
                            <Grid item xs={6} sm={4}>
                                <AutoCompleteField
                                    value={this.state.itemSel}
                                    options={this.state.items}
                                    onChange={this.changeAuto('itemSel', 'item')}
                                    error={errors[t('item')] != null ? true : false}
                                    helperText={errors[t('item')]} 
                                    label={t('item')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            </Grid>
                        }
                        {!fieldAccess && this.state.itemSel != null &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('item')}</FormLabel>
                                    <Link to={'/item/' + this.state.itemSel.value}>{this.state.itemSel.label}</Link>
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <AutoCompleteField
                                    value={this.state.strainTypeSel}
                                    options={this.state.strainTypes}
                                    onChange={this.changeAuto('strainTypeSel', 'strainType')}
                                    error={errors[t('strainType')] != null ? true : false}
                                    helperText={errors[t('strainType')]} 
                                    label={t('strainType')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            </Grid>
                        }
                        {!fieldAccess && this.state.strainTypeSel != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{this.state.strainTypeSel.label}</FormLabel>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.itemObj != null && this.state.itemObj.strain != null &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('strain')}</FormLabel>
                                    <Link to={'/strain/' + this.state.itemObj.strain._id}>{this.state.itemObj.strain.name}</Link>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.itemSel != null && this.state.lots != null && this.state.lots.length > 0 && fieldAccess &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.lotSel}
                                    options={this.state.lots}
                                    onChange={this.changeAuto('lotSel', 'lot')}
                                    error={errors[t('lot')] != null ? true : false}
                                    helperText={errors[t('lot')]} 
                                    label={t('lot')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            </Grid>
                        }
                        {this.state.lotSel != null && !fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('lot')}</FormLabel>
                                    <Link to={'/lot/' + this.state.lotSel.value}>{this.state.lotSel.label}</Link>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.batchObj != null && this.state.batchObj._id != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('batch')}</FormLabel>
                                    <Link to={'/evorabatch/' + this.state.batchObj._id}>{this.state.batchObj.number}</Link>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.harvestDate != null && this.state.harvestDate !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('harvested')}</FormLabel>
                                    <DateDisplay value={this.state.harvestDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.batchObj == null && this.state.lotObj != null && 
                            <Grid item xs={4} sm={2}>
                               <DateSelect 
                                    onChange={this.dateChange('harvestDate')}
                                    value={this.state.harvestDate}
                                    helperText={errors[t('harvestDate')]}
                                    error={errors[t('harvestDate')] != null ? true : false}
                                    label={t('harvested')}
                                    format={this.props.auth.user.dateFormat}
                                    disabled={!fieldAccess}
                                    required
                                />
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <DateSelect 
                                    onChange={this.dateChange('availableDate')}
                                    value={this.state.availableDate}
                                    helperText={errors[t('availableDate')]}
                                    error={errors[t('availableDate')] != null ? true : false}
                                    label={t('available')}
                                    format={this.props.auth.user.dateFormat}
                                    disabled={!fieldAccess}
                                    required
                                />
                            }
                            {!fieldAccess && this.state.availableDate !== '' &&
                                <FormControl>
                                    <FormLabel>{t('available')}</FormLabel>
                                    <DateDisplay value={this.state.availableDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <DateSelect 
                                    onChange={this.dateChange('periodStartDate')}
                                    value={this.state.periodStartDate}
                                    helperText={errors[t('periodStartDate')]}
                                    error={errors[t('periodStartDate')] != null ? true : false}
                                    label={t('startDate')}
                                    format={this.props.auth.user.dateFormat}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                        }
                        {!fieldAccess && this.state.periodStartDate != null && this.state.periodStartDate !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('startDate')}</FormLabel>
                                </FormControl>
                                <DateDisplay value={this.state.periodStartDate} format={this.props.auth.user.dateFormat}/>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <DateSelect 
                                    onChange={this.dateChange('periodEndDate')}
                                    value={this.state.periodEndDate}
                                    helperText={errors[t('periodEndDate')]}
                                    error={errors[t('periodEndDate')] != null ? true : false}
                                    label={t('endDate')}
                                    format={this.props.auth.user.dateFormat}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                        }
                        {fieldAccess && this.state.periodEndDate != null && this.state.periodEndDate !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('endDate')}</FormLabel>
                                </FormControl>
                                <DateDisplay value={this.state.periodEndDate} format={this.props.auth.user.dateFormat}/>
                            </Grid>
                        }
                        {this.state.item != null && this.state.item.strain != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('strain')}</FormLabel>
                                    {this.state.item.strain.name}
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item sm={12}>
                                <TextField value={this.state.lineage} onChange={this.changeValue} name="lineage"
                                size="medium" fullWidth={true}  label={t('lineage')} disabled={!fieldAccess} type="text"
                                error={errors[t('lineage')] != null ? true : false} helperText={errors[t('lineage')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.lineage != null && this.state.lineage !== '' &&
                            <Grid item sm={12}>
                                <FormControl>
                                    <FormLabel>{t('lineage')}</FormLabel>
                                    {this.state.lineage}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <TextField value={this.state.listingVolume} onChange={this.changeNumber} name="listingVolume"
                                size="medium" fullWidth={true}  label={t('quantity')} disabled={!fieldAccess} required type="number"
                                error={errors[t('listingVolume')] != null ? true : false} helperText={errors[t('listingVolume')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('quantity')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.listingVolume} thousandSeparator={true} decimalScale={2}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <FormControl>
                                <FormLabel>{t('uom')}</FormLabel>
                                {this.state.orderingUnit}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <TextField value={this.state.price} onChange={this.changeNumber} name="price"
                                size="medium" fullWidth={true}  label={t('price')} disabled={!fieldAccess} type="number"
                                error={errors[t('price')] != null ? true : false} helperText={errors[t('price')]}/>
                            }
                            {!fieldAccess && this.state.price != null && this.state.price !== '' &&
                                 <FormControl>
                                    <FormLabel>{t('price')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.price} thousandSeparator={true} decimalScale={2}/>
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title={t('details')}/>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.irradiatedSel}
                                    options={this.state.irradiateds}
                                    onChange={this.changeAuto('irradiatedSel', 'irradiated')}
                                    error={errors[t('irradiated')] != null ? true : false}
                                    helperText={errors[t('irradiated')]} 
                                    label={t('irradiated')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            }
                            {!fieldAccess && this.state.irradiatedSel != null &&
                                <FormControl>
                                    <FormLabel>{t('irradiated')}</FormLabel>
                                    {this.state.irradiatedSel.label}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.trimMethodSel}
                                    options={this.state.trimMethods}
                                    onChange={this.changeAuto('trimMethodSel', 'trimMethod')}
                                    error={errors[t('trimMethod')] != null ? true : false}
                                    helperText={errors[t('trimMethod')]} 
                                    label={t('trimType')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            }
                            {!fieldAccess && this.state.trimMethodSel != null &&
                                <FormControl>
                                    <FormLabel>{t('trimType')}</FormLabel>
                                    {this.state.trimMethodSel.label}
                                </FormControl>
                            }
                        </Grid>
                        {fieldAccess &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.growMethodSel}
                                    options={this.state.growMethods}
                                    onChange={this.changeAuto('growMethodSel', 'growMethod')}
                                    error={errors[t('growMethod')] != null ? true : false}
                                    helperText={errors[t('growMethod')]} 
                                    label={t('growMethod')}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                        } 
                        {!fieldAccess && this.state.growMethodSel != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('growMethod')}</FormLabel>
                                    {this.state.growMethodSel.label}
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.growMethod1Sel}
                                    options={this.state.growMethod1s}
                                    onChange={this.changeAuto('growMethod1Sel', 'growMethod1')}
                                    error={errors[t('growMethod') + ' ' + t('alternative')] != null ? true : false}
                                    helperText={errors[t('growMethod') + ' ' + t('alternative')]} 
                                    label={t('growMethod1')}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                        }
                        {!fieldAccess && this.state.growMethod1Sel != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('growMethod1')}</FormLabel>
                                    {this.state.growMethod1Sel}
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={8} sm={4}>
                                <TextField value={this.state.growMedium} onChange={this.changeValue} name="growMedium"
                                size="medium" fullWidth={true}  label={t('growMedium')} disabled={!fieldAccess} type="text"
                                error={errors[t('growMedium')] != null ? true : false} helperText={errors[t('growMedium')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.growMedium != null && this.state.growMedium !== '' &&
                            <Grid item xs={8} sm={4}>
                                <FormControl>
                                    <FormLabel>{t('growMedium')}</FormLabel>
                                    {this.state.growMedium}
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.metalsSel}
                                    options={this.state.testedStatuses}
                                    onChange={this.changeAuto('metalsSel', 'metals')}
                                    error={errors[t('metals')] != null ? true : false}
                                    helperText={errors[t('metals')]} 
                                    label={t('metals')}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                        }
                        {!fieldAccess && this.state.metalsSel &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('metals')}</FormLabel>
                                    {this.state.metalsSel.label}
                                </FormControl>
                            </Grid>
                        }
                        {/*fieldAccess &&
                            <Grid item xs={6} sm={3}>
                                <TextField value={this.state.microbials} onChange={this.changeValue} name="microbials"
                                size="medium" fullWidth={true}  label={t('microbials')} disabled={!fieldAccess} type="text"
                                error={errors[t('microbials')] != null ? true : false} helperText={errors[t('microbials')]}/>
                            </Grid>
                        */}
                        {/*!fieldAccess && this.state.microbials != null && this.state.microbbials !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('microbials')}</FormLabel>
                                    {this.state.microbials}
                                </FormControl>
                            </Grid>
                        */}
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.moisture} onChange={this.changeNumber} name="moisture"
                                size="medium" fullWidth={true}  label={t('moisture')} disabled={!fieldAccess} type="number"
                                error={errors[t('moisture')] != null ? true : false} helperText={errors[t('moisture')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.moisture != null && this.state.moisture !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('moisture')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.moisture} thousandSeparator={true} decimalScale={0}/>
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.cureTime} onChange={this.changeNumber} name="cureTime"
                                size="medium" fullWidth={true}  label={t('cureDays')} disabled={!fieldAccess} type="number"
                                error={errors[t('cureTime')] != null ? true : false} helperText={errors[t('cureTime')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.cureTime != null && this.state.cureTime !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('cureDays')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.cureTime} thousandSeparator={true} decimalScale={0}/>
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.flushTime} onChange={this.changeNumber} name="flushTime"
                                size="medium" fullWidth={true}  label={t('flushDays')} disabled={!fieldAccess} type="number"
                                error={errors[t('flushTime')] != null ? true : false} helperText={errors[t('flushTime')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.flushTime != null && this.state.flushTime !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('flushDays')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.flushTime} thousandSeparator={true} decimalScale={0}/>
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.dryMethodSel}
                                    options={this.state.dryMethods}
                                    onChange={this.changeAuto('dryMethodSel', 'dryMethod')}
                                    error={errors[t('dryMethod')] != null ? true : false}
                                    helperText={errors[t('dryMethod')]} 
                                    label={t('dryingType')}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                        }
                        {!fieldAccess && this.state.dryMethodSel != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('dryingType')}</FormLabel>
                                    {this.state.dryMethodSel.label}
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <DateSelect 
                                    onChange={this.dateChange('packagingDate')}
                                    value={this.state.packagingDate}
                                    helperText={errors[t('packagingDate')]}
                                    error={errors[t('packagingDate')] != null ? true : false}
                                    label={t('packagedOn')}
                                    format={this.props.auth.user.dateFormat}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                        }
                        {!fieldAccess && this.state.packagingDate != null && this.state.packagingDate !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('packagedOn')}</FormLabel>
                                    <DateDisplay value={this.state.packagingDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <DateSelect 
                                    onChange={this.dateChange('coaDate')}
                                    value={this.state.coaDate}
                                    helperText={errors[t('coaDate')]}
                                    error={errors[t('coaDate')] != null ? true : false}
                                    label={t('coaDate')}
                                    format={this.props.auth.user.dateFormat}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                        }
                        {!fieldAccess && this.state.coaDate != null && this.state.coaDate !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('coaDate')}</FormLabel>
                                    <DateDisplay value={this.state.coaDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.fieldAccess &&
                            <Grid item xs={6} sm={3}>
                                <TextField value={this.state.budDistribution} onChange={this.changeValue} name="budDistribution"
                                size="medium" fullWidth={true}  label={t('budDistribution')} disabled={!fieldAccess} type="text"
                                error={errors[t('budDistribution')] != null ? true : false} helperText={errors[t('budDistribution')]}/>
                            </Grid>
                        } 
                        {!fieldAccess && this.state.budDistribution != null && this.state.budDistribution !== '' &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('budDistribution')}</FormLabel>
                                    {this.state.budDistribution}
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={6} sm={3}>
                                <TextField value={this.state.cone} onChange={this.changeValue} name="cone"
                                size="medium" fullWidth={true}  label={t('cone')} disabled={!fieldAccess} type="text"
                                error={errors[t('cone')] != null ? true : false} helperText={errors[t('cone')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.cone != null && this.state.cone !== '' &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('cone')}</FormLabel>
                                    {this.state.cone}
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item sm={12}>
                                <TextField value={this.state.otherCannabinoids} onChange={this.changeValue} name="otherCannabinoids"
                                size="medium" fullWidth={true}  label={t('otherCannabinoids')} disabled={!fieldAccess} type="text"
                                error={errors[t('otherCannabinoids')] != null ? true : false} helperText={errors[t('otherCannabinoids')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.otherCannabinoids != null && this.state.otherCannabinoids !== '' &&
                            <Grid item sm={12}>
                                <FormControl>
                                    <FormLabel>{t('otherCannabinoids')}</FormLabel>
                                    {this.state.otherCannabinoids}
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item sm={12}>
                                <TextField value={this.state.notes} onChange={this.changeValue} name="notes" disabled={!fieldAccess}
                                size="medium" fullWidth={true}  label={t('notes')} maxRows={10} multiline type="text"
                                error={errors[t('notes')] != null ? true : false} helperText={errors[t('notes')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.notes != null && this.state.notes !== '' &&
                            <Grid item sm={12}>
                                <FormControl>
                                    <FormLabel>{this.state.notes}</FormLabel>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <br/>
                    <SectionTitle title={t("testResults")}/>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <TextField value={this.state.thcSpot} onChange={this.changeNumber} name="thcSpot"
                                size="medium" fullWidth={true}  label={t('thcSpot')} disabled={!fieldAccess} type="number" required
                                error={errors[t('thcSpot')] != null ? true : false} helperText={errors[t('thcSpot')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('thcSpot')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.thcSpot} thousandSeparator={true} decimalScale={2}/>
                                </FormControl>
                            }
                        </Grid>
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.thcMin} onChange={this.changeNumber} name="thcMin"
                                size="medium" fullWidth={true}  label={t('thcMin')} disabled={!fieldAccess} type="number"
                                error={errors[t('thcMin')] != null ? true : false} helperText={errors[t('thcMin')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.thcMin != null && this.state.thcMin !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('thcMin')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.thcMin} thousandSeparator={true} decimalScale={2}/>
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.thcMax} onChange={this.changeNumber} name="thcMax"
                                size="medium" fullWidth={true}  label={t('thcMax')} disabled={!fieldAccess} type="number"
                                error={errors[t('thcMax')] != null ? true : false} helperText={errors[t('thcMax')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.thcMax != null && this.state.thcMax !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('thcMax')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.thcMax} thousandSeparator={true} decimalScale={2}/>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <TextField value={this.state.cbdSpot} onChange={this.changeNumber} name="cbdSpot"
                                size="medium" fullWidth={true}  label={t('cbdSpot')} disabled={!fieldAccess} type="number" required
                                error={errors[t('cbdSpot')] != null ? true : false} helperText={errors[t('cbdSpot')]}/>
                            }
                             {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('cbdSpot')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.cbdSpot} thousandSeparator={true} decimalScale={2}/>
                                </FormControl>
                            }
                        </Grid>
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.cbdMin} onChange={this.changeNumber} name="cbdMin"
                                size="medium" fullWidth={true}  label={t('cbdMin')} disabled={!fieldAccess} type="number"
                                error={errors[t('cbdMin')] != null ? true : false} helperText={errors[t('cbdMin')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.cbdMin != null && this.state.cbdMin !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('cbdMin')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.cbdMin} thousandSeparator={true} decimalScale={2}/>
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.cbdMax} onChange={this.changeNumber} name="cbdMax"
                                size="medium" fullWidth={true}  label={t('cbdMax')} disabled={!fieldAccess} type="number"
                                error={errors[t('cbdMax')] != null ? true : false} helperText={errors[t('cbdMax')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.cbdMax != null && this.state.cbdMax !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('cbdMax')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.cbdMax} thousandSeparator={true} decimalScale={2}/>
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.cbgSpot} onChange={this.changeNumber} name="cbgSpot"
                                size="medium" fullWidth={true}  label={t('cbgSpot')} disabled={!fieldAccess} type="number"
                                error={errors[t('cbgSpot')] != null ? true : false} helperText={errors[t('cbgSpot')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.cbgSpot != null && this.state.cbgSpot !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('cbgSpot')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.cbgSpot} thousandSeparator={true} decimalScale={2}/>
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.cbnSpot} onChange={this.changeNumber} name="cbnSpot"
                                size="medium" fullWidth={true}  label={t('cbnSpot')} disabled={!fieldAccess} type="number"
                                error={errors[t('cbnSpot')] != null ? true : false} helperText={errors[t('cbnSpot')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.cbnSpot != null && this.state.cbnSpot !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('cbnSpot')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.cbnSpot} thousandSeparator={true} decimalScale={2}/>
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.cbcSpot} onChange={this.changeNumber} name="cbcSpot"
                                size="medium" fullWidth={true}  label={t('cbcSpot')} disabled={!fieldAccess} type="number"
                                error={errors[t('cbcSpot')] != null ? true : false} helperText={errors[t('cbcSpot')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.cbcSpot != null && this.state.cbcSpot !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('cbcSpot')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.cbcSpot} thousandSeparator={true} decimalScale={2}/>
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.delta8} onChange={this.changeNumber} name="delta8"
                                size="medium" fullWidth={true}  label={t('delta8')} disabled={!fieldAccess} type="number"
                                error={errors[t('delta8')] != null ? true : false} helperText={errors[t('delta8')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.delta8 != null && this.state.delta8 !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('delta8')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.delta8} thousandSeparator={true} decimalScale={2}/>
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.terpenePercent} onChange={this.changeNumber} name="terpenePercent"
                                size="medium" fullWidth={true}  label={t('terpenePercent')} disabled={!fieldAccess} type="number"
                                error={errors[t('terpenePercent')] != null ? true : false} helperText={errors[t('terpenePercent')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.terpenePercent != null && this.state.terpenePercent !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('terpenePercent')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.terpenePercent} thousandSeparator={true} decimalScale={0}/>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <br/>
                    <SectionTitle title={t('certifications')}/>
                    <Grid item sm={10}>
                            <AutoCompleteField
                                multiple
                                value={this.state.certificationSel}
                                options={this.state.certificationOptions}
                                onChange={this.changeMulti('certificationSel', 'certifications')}
                                error={errors[t('certifications')] != null ? true : false}
                                helperText={errors[t('certifications')]} 
                                label={t('certifications')}
                                disabled={!fieldAccess}
                            />
                        </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                disabled={!fieldAccess}
                                control={<Switch checked={this.state.bcDirectCertified} onChange={this.changeBool('bcDirectCertified')} name="bcDirectCertified" />}
                                label={t('bcDirectCertified')} 
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                disabled={!fieldAccess}
                                control={<Switch checked={this.state.bcDirectQualified} onChange={this.changeBool('bcDirectQualified')} name="bcDirectQualified" />}
                                label={t('bcDirectQualified')} 
                            />
                        </Grid>
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('coas')}/>
                            <Grid container spacing={3} key='coas'>
                                <Grid item sm={12}>
                                    <Document record={this.state._id} documents={this.state.coas} propName="coas" key='coasdoc'
                                    objectType={this.props.auth.constants.objectIds.ccxPosting} callback={this.loadRecord}/>
                                </Grid>
                            </Grid>
                            <br/>
                            <SectionTitle title={t('photos')}/>
                            <Grid container spacing={3} key="photos">
                                <Grid item sm={12}>
                                    <Document record={this.state._id} documents={this.state.photos} propName="photos" key="photosdoc"
                                    objectType={this.props.auth.constants.objectIds.ccxPosting} callback={this.loadRecord}/>
                                </Grid>
                            </Grid>
                            <br/>
                            <SectionTitle title={t('videos')}/>
                            <Grid container spacing={3} key="videos">
                                <Grid item sm={12}>
                                    <Document record={this.state._id} documents={this.state.videos} propName="videos" key="videosdoc"
                                    objectType={this.props.auth.constants.objectIds.ccxPosting} callback={this.loadRecord}/>
                                </Grid>
                            </Grid>
                            <br/>
                        </Aux>
                    }
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            <Button variant="contained" color="primary" size="small" disabled={!this.state.enableSave || !fieldAccess} onClick={this.save}>{t('save')}</Button>&nbsp;&nbsp;
                            <Button variant="contained" color="secondary" size="small" disabled={!this.state.enableSave || !fieldAccess} onClick={this.send}>{t('send')}</Button>&nbsp;&nbsp;
                            {this.state.enableDelete &&
                                <Button variant="contained" color="secondary" size="small" disabled={!this.state.enableSave || !fieldAccess} onClick={this.deleteToggle}>{t('delete')}</Button>
                            }
                            {this.state.internal && !this.state.enableDelete &&
                                <Button variant="contained" color="primary" size="small" disabled={!this.state.internal || !this.state.enableSave} onClick={this.toggleChangeStatus}>Change Status</Button>
                            }
                        </Grid>
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <div>
                            <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.ccxPosting}/>
                            <ObjectLog id={this.state._id} renderNum={this.state.renderNum}/>
                        </div>
                    }
                </Panel>
                <ModalDialog buttonStack={(<Aux>
                        <Button color="secondary" size="small" variant='text' onClick={this.toggleChangeStatus}>Close</Button>
                        <Button color="primary" size="small" variant="text" onClick={this.performChangeStatus}>Save</Button>
                    </Aux>
                )} title="Status Change" toggleDialog={this.toggleChangeStatus}
                                dialogStatus={this.state.changeStatus} fullWidth maxWidth='sm'>
                    <Grid container spacing={3}>
                        <Grid item xs={10} sm={5}>
                            <AutoCompleteField
                                value={this.state.newStatusSel}
                                options={this.state.statuses}
                                onChange={this.changeAuto('newStatusSel', 'newStatus')}
                                error={errors[t('newStatus')] != null ? true : false}
                                helperText={errors[t('newStatus')]} 
                                label='New Status'
                                disabled={!this.state.internal}
                            />
                        </Grid>
                    </Grid>
                                    
                </ModalDialog>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(withRouter(CcxPosting)));