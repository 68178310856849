import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import ObjectLog from '../General/ObjectLog';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Comments from '../General/Comments';
import EquipmentLogs from '../Widgets/EquipmentLogs';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateSelect from '../UI/DateSelect/DateSelect';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import RoomCleanings from './RoomCleanings';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import EquipmentCalibrationChecks from './EquipmentCalibrationChecks';
import ConfirmDelete from '../General/ConfirmDelete';
import Switch from '../UI/Switch/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

class Equipment extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            location: '',
            locations: [],
            name: '',
            type: '',
            types: [],
            room: '',
            rooms: [],
            identityNo: '',
            capacity: '',
            capacityUOM: '',
            capacityUOMSel: null,
            minProcessTime: '',
            minProcessTimeUOM: 0,
            minTimeUOMSel: null,
            maxProcessTime: '',
            maxProcessTimeUOM: '',
            maxTimeUOMSel: null,
            concurrentCapacities: '',
            setupTime: '',
            setupTimeUOM: '',
            setupUOMSel: null,
            waitTime: '',
            waitTimeUOM: '',
            waitUOMSel: null,
            moveTime: '',
            moveTimeUOM: '',
            moveUOMSel: null,
            locationSelected: null,
            roomSelected: null,
            typeSelected: null,
            timeUOMs: [],
            quantityUOMs: [],
            locked: false,
            documents: [],
            security: [],
            enableSave: true,
            isNew: false,
            vendorList: [],
            maintenanceVendors: [],
            vendorsSel: [],
            manufacturer: '',
            make: '',
            model: '',
            serialNo: '',
            purchaseDate: '',
            installationDate: '',
            warrantyExpires: '',
            use: '',
            tradeCertified: false,
            minWeight: '',
            maxWeight: '',
            weightUOM: '',
            weightUOMSel: null,
            calibrationDate: '',
            calibrationExpires: '',
            calibrationTypes: [],
            calibrationTypesSel: [],
            calibrationTypeOptions: [],
            deleteOpen: false,
            machineType: '',
            machineTypeSel: null,
            machineTypes: [],
            hide: false
        };

        this.changeValue = this.changeValue.bind(this);
        this.saveClick = this.saveClick.bind(this);
        this.deleteClick = this.deleteClick.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.activeLocation, common.cacheValues.quantityUOM, common.cacheValues.timeUOM, common.cacheValues.machineType,
                                common.cacheValues.defaultRoom, common.cacheValues.equipmentType, common.cacheValues.maintenanceVendor, common.cacheValues.equipmentCalibrationType], 
                                this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        let location = '';
        let locationSel = null;
        if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
            location = this.props.auth.user.defaultLocation;
            locationSel = locations.find(x => x.value === location);
        } else {
            if(locations.length === 1){
                locationSel = locations[0];
                location = locationSel.value;
            }
        }
        let isNew = (this.props.match.params.id == null || this.props.match.params.id === '') && this.props.permission.create;
        this.setState({
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            types: cacheValues.equipmentTypes,
            locations: locations,
            rooms: cacheValues.rooms,
            timeUOMs: cacheValues.timeUOMs,
            quantityUOMs: cacheValues.quantityUOMs,
            location: location,
            locationSelected: locationSel,
            security: this.props.permission,
            isNew: isNew,
            locked: isNew ? false : true,
            vendorList: cacheValues.maintenanceVendors,
            calibrationTypeOptions: cacheValues.equipmentCalibrationTypes,
            machineTypes: cacheValues.machineTypes
        }, () => {
            if(this.state._id!= null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/equipmentlist', label: this.props.t('equipmentList'), screen: this.props.auth.screenDefs.Equipment},
                    { path: 'active', label: this.props.t('equipment'), screen: this.props.auth.screenDefs.Equipment}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/equipment/' + this.state._id);
        let locationSelected = this.state.locations.find(x => x.value === record.data.location);
        let roomSelected = this.state.rooms.find(x => x.value === record.data.room);
        let typeSelected = this.state.types.find(x => x.value === record.data.type);
        let capacityUOMSel = record.data.capacityUOM != null ? this.state.quantityUOMs.find(x => x.value === record.data.capacityUOM) : null;
        let minTimeUOMSel = record.data.minProcessTimeUOM != null ? this.state.timeUOMs.find(x => x.value === record.data.minProcessTimeUOM) : null;
        let maxTimeUOMSel = record.data.maxProcessTimeUOM != null ? this.state.timeUOMs.find(x => x.value === record.data.maxProcessTimeUOM) : null;
        let setupUOMSel = record.data.setupTimeUOM != null ? this.state.timeUOMs.find(x => x.value === record.data.setupTimeUOM) : null;
        let waitUOMSel = record.data.waitTimeUOM != null ? this.state.timeUOMs.find(x => x.value === record.data.waitTimeUOM) : null;
        let moveUOMSel = record.data.moveTimeUOM != null ? this.state.timeUOMs.find(x => x.value === record.data.moveTimeUOM) : null;
        let weightUOMSel = record.data.weightUOM != null ? this.state.quantityUOMs.find(x => x.value === record.data.weightUOM) : null;
        let machineTypeSel = record.data.machineType != null ? this.state.machineTypes.find(x => x.value === record.data.machineType) : null;
        let vendorsSel = [];
        if(record.data.maintenanceVendors != null && record.data.maintenanceVendors.length > 0){
            record.data.maintenanceVendors.forEach(row => {
                let val = this.state.vendorList.find(x => x.value === row);
                vendorsSel.push(val);
            });
        }
        let calibrationTypesSel = [];
        if(record.data.calibrationTypes != null && record.data.calibrationTypes.length > 0){
            record.data.calibrationTypes.forEach(row => {
                let val = this.state.calibrationTypeOptions.find(x => x.value === row);
                if(val != null) calibrationTypesSel.push(val);
            });
        }
        this.setState({
            _id: record.data._id,
            name: record.data.name,
            type: record.data.type,
            identityNo: record.data.identityNo,
            location: record.data.location,
            room: record.data.room,
            locationSelected: locationSelected,
            roomSelected: roomSelected,
            typeSelected: typeSelected,
            capacity: record.data.capacity,
            capacityUOM: record.data.capacityUOM,
            capacityUOMSel: capacityUOMSel,
            minProcessTime: record.data.minProcessTime,
            minProcessTimeUOM: record.data.minProcessTimeUOM,
            minTimeUOMSel: minTimeUOMSel,
            maxProcessTime: record.data.maxProcessTime,
            maxProcessTimeUOM: record.data.maxProcessTimeUOM,
            maxTimeUOMSel: maxTimeUOMSel,
            concurrentCapacities: record.data.concurrentCapacities,
            setupTime: record.data.setupTime,
            setupTimeUOM: record.data.setupTimeUOM,
            setupUOMSel: setupUOMSel,
            waitTime: record.data.waitTime,
            waitTimeUOM: record.data.waitTimeUOM,
            waitUOMSel: waitUOMSel,
            moveTime: record.data.moveTime,
            moveTimeUOM: record.data.moveTimeUOM,
            moveUOMSel: moveUOMSel,
            documents: record.data.documents,
            make: record.data.make,
            manufacturer: record.data.manufacturer,
            model: record.data.model,
            serialNo: record.data.serialNo,
            purchaseDate: record.data.purchaseDate,
            installationDate: record.data.installationDate,
            warrantyExpires: record.data.warrantyExpires,
            use: record.data.use,
            tradeCertified: record.data.tradeCertified === true ? true : false,
            minWeight: record.data.minWeight,
            maxWeight: record.data.maxWeight,
            weightUOM: record.data.weightUOM,
            weightUOMSel: weightUOMSel,
            calibrationDate: record.data.calibrationDate,
            calibrationExpires: record.data.calibrationExpires,
            maintenanceVendors: record.data.maintenanceVendors,
            vendorsSel: vendorsSel,
            calibrationTypes: record.data.calibrationTypes != null ? record.data.calibrationTypes : [],
            calibrationTypesSel: calibrationTypesSel,
            deleteOpen: false,
            machineType: record.data.machineType,
            machineTypeSel: machineTypeSel,
            hide: record.data.hide === true || record.data.hide === 'true' ? true : false
        }, () => {
            const crumbs = [
                { path: '/#/equipmentlist', label: this.props.t('equipmentList'), screen: this.props.auth.screenDefs.Equipment},
                { path: 'active', label: this.props.t('equipment') + ' - ' + record.data.identityNo, screen: this.props.auth.screenDefs.Equipment}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { 
                options: [
                    { menuType: this.props.auth.constants.optionMenuTypes.comments },
                    { menuType: this.props.auth.constants.optionMenuTypes.logs }
                ],
                title: this.props.t('options')
            }});
        });
    }

    changeValue(e){
        this.setState({[e.target.name]: e.target.value});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeNumber = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value});
    };

    changeMulti = (name, data) => (e, newValue) => {
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            [name]: newValue,
            [data]: ids
        })
    };
    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    }

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = () => {
        const t = this.props.t;
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            _id: this.state._id,
            location: this.state.location,
            room: this.state.room,
            name: this.state.name,
            type: this.state.type,
            identityNo: this.state.identityNo,
            capacity: this.state.capacity,
            capacityUOM: this.state.capacityUOM,
            minProcessTime: this.state.minProcessTime,
            minProcessTimeUOM: this.state.minProcessTimeUOM,
            maxProcessTime: this.state.maxProcessTime,
            maxProcessTimeUOM: this.state.maxProcessTimeUOM,
            concurrentCapacities: this.state.concurrentCapacities,
            setupTime: this.state.setupTime,
            setupTimeUOM: this.state.setupTimeUOM,
            waitTime: this.state.waitTime,
            waitTimeUOM: this.state.waitTimeUOM,
            moveTime: this.state.moveTime,
            moveTimeUOM: this.state.moveTimeUOM,
            manufacturer: this.state.manufacturer,
            make: this.state.make,
            model: this.state.model,
            serialNo: this.state.serialNo,
            purchaseDate: this.state.purchaseDate,
            installationDate: this.state.installationDate,
            warrantyExpires: this.state.warrantyExpires,
            use: this.state.use,
            tradeCertified: this.state.tradeCertified,
            minWeight: this.state.minWeight,
            maxWeight: this.state.maxWeight,
            weightUOM: this.state.weightUOM,
            calibrationDate: this.state.calibrationDate,
            calibrationExpires: this.state.calibrationExpires,
            maintenanceVendors: this.state.maintenanceVendors,
            calibrationTypes: this.state.calibrationTypes,
            machineType: this.state.machineType,
            hide: this.state.hide
        };
        axios.post('/api/equipment', data)
        .then(result => {
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        })
        .catch(err => {
            this.setState({ enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        });
    }

    validateForm(){
        let errors = {};
        const t = this.props.t;
        let state = this.state;
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.name == null || state.name === '')
            errors[t('name')] = t('required');
        if((state.identityNo == null || state.identityNo === '') && 
           (state._id != null && state._id !== ''))
            errors[t('identityNo')] = t('required');
        if(state.capacity != null && state.capacity !== '' && state.capacity !== 0){
            if(state.capacityUOM == null || state.capacity === '')
                errors[t('capacityUOM')] =t('required');
        }
        if(state.minProcessTime != null && state.minProcessTime !== '' && state.minProcessTime !== 0){
            if(state.minProcessTimeUOM == null || state.minProcessTimeUOM === '')
                errors[t('minProcessTimeUOM')] = t('required');
        }
        if(state.maxProcessTime != null && state.maxProcessTime !== '' && state.maxProcessTime !== 0){
            if(state.maxProcessTimeUOM == null || state.maxProcessTimeUOM === '')
                errors[t('maxProcessTimeUOM')] = t('required');
        }
        if(state.setupTime != null && state.setupTime !== '' && state.setupTime !== 0){
            if(state.setupTimeUOM == null || state.setupTimeUOM === '')
                errors[t('setupTimeUOM')] = t('required');
        }
        if(state.waitTime != null && state.waitTime !== '' && state.waitTime !== 0){
            if(state.waitTimeUOM == null || state.waitTimeUOM === '')
                errors[t('waitTimeUOM')] = t('required');
        }
        if(state.moveTime != null && state.moveTime !== '' && state.moveTime !== 0){
            if(state.moveTimeUOM == null || state.moveTimeUOM === '')
                errors[t('moveTimeUOM')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick(){
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick());
    }

    async _deleteClick(){
        const t = this.props.t;
        try {
            let result = await axios.delete('/api/equipment/' + this.state._id);
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            this.props.history.goBack();
        } catch (err){
            this.setState({ enableSave: true, deleteOpen: false}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    unlock = (e) => {
        this.setState({locked: !this.state.locked});
    }

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    render(){
        const errors = this.props.errors;
        const locked = this.state.locked;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar 
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.equipment}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        optionsAvailable={this.state._id != null && this.state._id !== '' ? true : false}
                        flagForSignature={0}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            {!locked ?
                                <TextField value={this.state.identityNo} onChange={this.changeValue} name="identityNo"
                                size="medium" fullWidth={true} label={t('number')} disabled={locked} required data-cy='number'
                                error={errors[t('identityNo')] != null ? true : false} helperText={errors[t('identityNo')]}/> : 
                                <FormControl data-cy='number'>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.identityNo}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {!locked &&
                                <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                size="medium" fullWidth={true} label={t('name')} disabled={locked} required data-cy='name'
                                error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('name')}</FormLabel>
                                    {this.state.name}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.locationSelected}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locationSelected', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]}
                                    label={t('location')}
                                    disabled={locked}
                                    required
                                    data-cy='location'
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('location')}</FormLabel>
                                    {this.state.locationSelected != null ? this.state.locationSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        {!locked &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.roomSelected}
                                    options={this.state.rooms}
                                    onChange={this.changeAuto('roomSelected', 'room')}
                                    error={errors[t('room')] != null ? true : false}
                                    helperText={errors[t('room')]}
                                    label={t('room')}
                                    disabled={locked}
                                    data-cy='room'
                                />
                            </Grid>
                        }
                        {locked && this.state.roomSelected != null &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('room')}</FormLabel>
                                    {this.state.roomSelected != null ? this.state.roomSelected.label : ''}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.machineTypeSel}
                                    options={this.state.machineTypes}
                                    onChange={this.changeAuto('machineTypeSel', 'machineType')}
                                    error={errors[t('machineType')] != null ? true : false}
                                    helperText={errors[t('machineType')]}
                                    label={t('machineType')}
                                    disabled={locked}
                                    data-cy='machineType'
                                />
                            }
                            {locked && this.state.machineTypeSel != null &&
                                <FormControl>
                                    <FormLabel>{t('machineType')}</FormLabel>
                                    {this.state.machineTypeSel != null ? this.state.machineTypeSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.typeSelected}
                                    options={this.state.types}
                                    onChange={this.changeAuto('typeSelected', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]}
                                    label={t('type')}
                                    disabled={locked}
                                    data-cy='type'
                                />
                            }
                            {locked && this.state.typeSelected != null &&
                                <FormControl>
                                    <FormLabel>{t('type')}</FormLabel>
                                    {this.state.typeSelected != null ? this.state.typeSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            {!locked &&
                                <TextField type="number" value={this.state.capacity} onChange={this.changeNumber} name="capacity"
                                size="medium" fullWidth={true} label={t('capacity')} disabled={locked} data-cy='capacity'
                                error={errors[t('capacity')] != null ? true : false} helperText={errors[t('capacity')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('capacity')}</FormLabel>
                                    {this.state.capacity}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.capacityUOMSel}
                                    options={this.state.quantityUOMs}
                                    onChange={this.changeAuto('capacityUOMSel', 'capacityUOM')}
                                    error={errors[t('capacityUOM')] != null ? true : false}
                                    helperText={errors[t('capacityUOM')]}
                                    label={t('uom')}
                                    disabled={locked}
                                    required={this.state.capacity != null && this.state.capacity !== '' && this.state.capacity !== 0}
                                    data-cy='capacityUOM'
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('uom')}</FormLabel>
                                    {this.state.capacityUOMSel != null ? this.state.capacityUOMSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <TextField type="number" value={this.state.minProcessTime} onChange={this.changeNumber} name="minProcessTime"
                                size="medium" fullWidth={true} label={t('minProcessTime')} disabled={locked} data-cy='minProcessTime'
                                error={errors[t('minProcessTime')] != null ? true : false} helperText={errors[t('minProcessTime')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('minProcessTime')}</FormLabel>
                                    {this.state.minProcessTime}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.minTimeUOMSel}
                                    options={this.state.timeUOMs}
                                    onChange={this.changeAuto('minTimeUOMSel', 'minProcessTimeUOM')}
                                    error={errors[t('minProcessTimeUOM')] != null ? true : false}
                                    helperText={errors[t('minProcessTimeUOM')]}
                                    label={t('uom')}
                                    disabled={locked}
                                    required={this.state.minProcessTime != null && this.state.minProcessTime !== '' && this.state.minProcessTime !== 0}
                                    data-cy='minProcessTimeUOM'
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('uom')}</FormLabel>
                                    {this.state.minTimeUOMSel != null ? this.state.minTimeUOMSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <TextField type="number" value={this.state.maxProcessTime} onChange={this.changeNumber} name="maxProcessTime"
                                size="medium" fullWidth={true} label={t('maxProcessTime')} disabled={locked} data-cy='maxProcessTime'
                                error={errors[t('maxProcessTime')] != null ? true : false} helperText={errors[t('maxProcessTime')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('maxProcessTime')}</FormLabel>
                                    {this.state.maxProcessTime}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.maxTimeUOMSel}
                                    options={this.state.timeUOMs}
                                    onChange={this.changeAuto('maxTimeUOMSel', 'maxProcessTimeUOM')}
                                    error={errors[t('maxProcessTimeUOM')] != null ? true : false}
                                    helperText={errors[t('maxProcessTimeUOM')]}
                                    label={t('uom')}
                                    disabled={locked}
                                    required={this.state.maxProcessTime != null && this.state.maxProcessTime !== '' && this.state.maxProcessTime !== 0}
                                    data-cy='maxProcessTimeUOM'
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('uom')}</FormLabel>
                                    {this.state.maxTimeUOMSel != null ? this.state.maxTimeUOMSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <TextField type="number" value={this.state.concurrentCapacities} onChange={this.changeNumber} name="concurrentCapacities"
                                size="medium" fullWidth={true} label={t('concurrency')} disabled={locked} data-cy='concurrency'
                                error={errors[t('concurrentCapacities')] != null ? true : false} helperText={errors[t('concurrentCapacities')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('concurrency')}</FormLabel>
                                    {this.state.concurrentCapacities}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <TextField type="number" value={this.state.setupTime} onChange={this.changeNumber} name="setupTime"
                                size="medium" fullWidth={true} label={t('setupTime')} disabled={locked} data-cy='setupTime'
                                error={errors[t('setupTime')] != null ? true : false} helperText={errors[t('setupTime')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('setupTime')}</FormLabel>
                                    {this.state.setupTime}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.setupUOMSel}
                                    options={this.state.timeUOMs}
                                    onChange={this.changeAuto('setupUOMSel', 'setupTimeUOM')}
                                    error={errors[t('setupTimeUOM')] != null ? true : false}
                                    helperText={errors[t('setupTimeUOM')]}
                                    label={t('uom')}
                                    disabled={locked}
                                    required={this.state.setupTime != null && this.state.setupTime !== '' && this.state.setupTime !== 0}
                                    data-cy='setupTimeUOM'
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('uom')}</FormLabel>
                                    {this.state.setupUOMSel != null ? this.state.setupUOMSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <TextField type="number" value={this.state.waitTime} onChange={this.changeNumber} name="waitTime"
                                size="medium" fullWidth={true} label={t('waitTime')} disabled={locked} data-cy='waitTime'
                                error={errors[t('waitTime')] != null ? true : false} helperText={errors[t('waitTime')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('waitTime')}</FormLabel>
                                    {this.state.waitTime}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.waitUOMSel}
                                    options={this.state.timeUOMs}
                                    onChange={this.changeAuto('waitUOMSel', 'waitTimeUOM')}
                                    error={errors[t('waitTimeUOM')] != null ? true : false}
                                    helperText={errors[t('waitTimeUOM')]}
                                    label={t('uom')}
                                    disabled={locked}
                                    required={this.state.waitTime != null && this.state.waitTime !== '' && this.state.waitTime !== 0}
                                    data-cy='waitTimeUOM'
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('uom')}</FormLabel>
                                    {this.state.waitUOMSel != null ? this.state.waitUOMSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <TextField type="number" value={this.state.moveTime} onChange={this.changeNumber} name="moveTime"
                                size="medium" fullWidth={true} label={t('moveTime')} disabled={locked} data-cy='moveTime'
                                error={errors[t('moveTime')] != null ? true : false} helperText={errors[t('moveTime')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('moveTime')}</FormLabel>
                                    {this.state.moveTime}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.moveUOMSel}
                                    options={this.state.timeUOMs}
                                    onChange={this.changeAuto('moveUOMSel', 'moveTimeUOM')}
                                    error={errors[t('moveTimeUOM')] != null ? true : false}
                                    helperText={errors[t('moveTimeUOM')]}
                                    label={t('uom')}
                                    disabled={locked}
                                    required={this.state.moveTime != null && this.state.moveTime !== '' && this.state.moveTime !== 0}
                                    data-cy='moveTimeUOM'
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('uom')}</FormLabel>
                                    {this.state.moveUOMSel != null ? this.state.moveUOMSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="text" value={this.state.manufacturer} onChange={this.changeValue} name="manufacturer"
                                size="medium" fullWidth={true} label={t('manufacturer')} disabled={locked} data-cy='manufacturer'
                                error={errors[t('manufacturer')] != null ? true : false} helperText={errors[t('manufacturer')]}/>
                            </Grid>
                        }
                        {locked && this.state.manufacturer != null && this.state.manufacturer !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('manufacturer')}</FormLabel>
                                    {this.state.manufacturer}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="text" value={this.state.make} onChange={this.changeValue} name="make"
                                size="medium" fullWidth={true} label={t('make')} disabled={locked} data-cy='make'
                                error={errors[t('make')] != null ? true : false} helperText={errors[t('make')]}/>
                            </Grid>
                        }
                        {locked && this.state.make != null && this.state.make !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('make')}</FormLabel>
                                    {this.state.make}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="text" value={this.state.model} onChange={this.changeValue} name="model"
                                size="medium" fullWidth={true} label={t('model')} disabled={locked} data-cy='model'
                                error={errors[t('model')] != null ? true : false} helperText={errors[t('model')]}/>
                            </Grid>
                        }
                        {locked && this.state.model != null && this.state.model !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('model')}</FormLabel>
                                    {this.state.model}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="text" value={this.state.serialNo} onChange={this.changeValue} name="serialNo"
                                size="medium" fullWidth={true} label={t('serialNo')} disabled={locked} data-cy='serialNo'
                                error={errors[t('serialNo')] != null ? true : false} helperText={errors[t('serialNo')]}/>
                            </Grid>
                        }
                        {locked && this.state.serialNo != null && this.state.serialNo !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('serialNo')}</FormLabel>
                                    {this.state.serialNo}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <DateSelect 
                                    onChange={this.dateChange('purchaseDate')}
                                    value={this.state.purchaseDate}
                                    helperText={errors[t('purchaseDate')]}
                                    error={errors[t('purchaseDate')] != null ? true : false}
                                    disabled={this.state.locked}
                                    label={t('purchased')}
                                    format={this.props.auth.user.dateFormat}
                                    data-cy='purchaseDate'
                                />
                            </Grid>
                        }
                        {locked && this.state.purchaseDate != null && this.state.purchaseDate !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('purchased')}</FormLabel>
                                    <DateDisplay value={this.state.purchaseDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <DateSelect 
                                    onChange={this.dateChange('installationDate')}
                                    value={this.state.installationDate}
                                    helperText={errors[t('installationDate')]}
                                    error={errors[t('installationDate')] != null ? true : false}
                                    disabled={this.state.locked}
                                    label={t('installed')}
                                    format={this.props.auth.user.dateFormat}
                                    data-cy='installed'
                                />
                            </Grid>
                        }
                        {locked && this.state.installationDate != null && this.state.installationDate !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('installed')}</FormLabel>
                                    <DateDisplay value={this.state.installationDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={6} sm={3}>
                                <DateSelect 
                                    onChange={this.dateChange('warrantyExpires')}
                                    value={this.state.warrantyExpires}
                                    helperText={errors[t('warrantyExpires')]}
                                    error={errors[t('warrantyExpires')] != null ? true : false}
                                    disabled={this.state.locked}
                                    label={t('warrantyExpires')}
                                    format={this.props.auth.user.dateFormat}
                                    data-cy='warrantyExpires'
                                />
                            </Grid>
                        }
                        {locked && this.state.warrantyExpires != null && this.state.warrantyExpires !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('warrantyExpires')}</FormLabel>
                                    <DateDisplay value={this.state.warrantyExpires} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={8} sm={4}>
                                <TextField type="text" value={this.state.use} onChange={this.changeValue} name="use"
                                size="medium" fullWidth={true} label={t('equipmentUse')} disabled={locked} data-cy='use'
                                error={errors[t('use')] != null ? true : false} helperText={errors[t('use')]}/>
                            </Grid>
                        }
                        {locked && this.state.use != null && this.state.use !== '' &&
                            <Grid item xs={8} sm={4}>
                                <FormControl>
                                    <FormLabel>{t('equipmentUse')}</FormLabel>
                                    {this.state.use}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.minWeight} onChange={this.changeNumber} name="minWeight"
                                size="medium" fullWidth={true} label={t('minWeight')} disabled={locked} data-cy='minWeight'
                                error={errors[t('minWeight')] != null ? true : false} helperText={errors[t('minWeight')]}/>
                            </Grid>
                        }
                        {locked && this.state.minWeight != null && this.state.minWeight !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('minWeight')}</FormLabel>
                                    {this.state.minWeight}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.maxWeight} onChange={this.changeNumber} name="maxWeight"
                                size="medium" fullWidth={true} label={t('maxWeight')} disabled={locked} data-cy='maxWeight'
                                error={errors[t('maxWeight')] != null ? true : false} helperText={errors[t('maxWeight')]}/>
                            </Grid>
                        }
                        {locked && this.state.maxWeight != null && this.state.maxWeight !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('maxWeight')}</FormLabel>
                                    {this.state.maxWeight}
                                </FormControl>
                            </Grid>
                        }
                         {!locked &&
                            <Grid item xs={2} sm={1}>
                                <AutoCompleteField
                                    value={this.state.weightUOMSel}
                                    options={this.state.quantityUOMs}
                                    onChange={this.changeAuto('weightUOMSel', 'weightUOM')}
                                    error={errors[t('weightUOM')] != null ? true : false}
                                    helperText={errors[t('weightUOM')]} 
                                    label={t('uom')}
                                    disabled={locked}
                                    data-cy='weightUOM'
                                />
                            </Grid>
                        }
                        {locked && this.state.weightUOMSel != null &&
                            <Grid item xs={1}>
                                <FormControl>
                                    <FormLabel>{t('uom')}</FormLabel>
                                    {this.state.weightUOMSel != null ? this.state.weightUOMSel.label : ''}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={6} sm={3}>
                                <DateSelect 
                                    onChange={this.dateChange('calibrationDate')}
                                    value={this.state.calibrationDate}
                                    helperText={errors[t('calibrationDate')]}
                                    error={errors[t('calibrationDate')] != null ? true : false}
                                    disabled={this.state.locked}
                                    label={t('calibrated')}
                                    format={this.props.auth.user.dateFormat}
                                    data-cy='calibrationDate'
                                />
                            </Grid>
                        }
                        {locked && this.state.calibrationDate != null && this.state.calibrationDate !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('calibrated')}</FormLabel>
                                    <DateDisplay value={this.state.calibrationDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={6} sm={3}>
                                <DateSelect 
                                    onChange={this.dateChange('calibrationExpires')}
                                    value={this.state.calibrationExpires}
                                    helperText={errors[t('calibrationExpires')]}
                                    error={errors[t('calibrationExpires')] != null ? true : false}
                                    disabled={this.state.locked}
                                    label={t('calibrationExpires')}
                                    format={this.props.auth.user.dateFormat}
                                    data-cy='calibrationExpires'
                                />
                            </Grid>
                        }
                        {locked && this.state.calibrationExpires != null && this.state.calibrationExpires !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('calibrationExpires')}</FormLabel>
                                    <DateDisplay value={this.state.calibrationExpires} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                        }
                        {(!locked || this.state.vendorsSel.length > 0) &&
                            <Grid item sm={11}>
                                <AutoCompleteField
                                    multiple={true} disabled={locked}
                                    value={this.state.vendorsSel}
                                    options={this.state.vendorList}
                                    onChange={this.changeMulti('vendorsSel', 'maintenanceVendors')}
                                    error={errors[t('maintenanceVendors')] != null ? true : false}
                                    helperText={errors[t('maintenanceVendors')]} 
                                    label={t('maintenanceVendors')}
                                    data-cy='maintenanceVendors'
                                />
                            </Grid>
                        }
                        {(!locked || this.state.calibrationTypesSel.length > 0) &&
                            <Grid item sm={11}>
                                <AutoCompleteField
                                    multiple={true} disabled={locked}
                                    value={this.state.calibrationTypesSel}
                                    options={this.state.calibrationTypeOptions}
                                    onChange={this.changeMulti('calibrationTypesSel', 'calibrationTypes')}
                                    error={errors[t('calibrationTypes')] != null ? true : false}
                                    helperText={errors[t('calibrationTypes')]} 
                                    label={t('calibrationTypes')}
                                    data-cy='calibrationTypes'
                                />
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={6} sm={3}>
                                <FormControlLabel
                                    disabled={locked}
                                    control={<Switch checked={this.state.hide} onChange={this.changeBool('hide')} name="hide" />}
                                    label={t('hide')} 
                                />
                            </Grid>
                        }
                        {locked && this.state.hide === true &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('hide')}</FormLabel>
                                    {t('yes')}
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={5}>
                            {!locked &&
                                <Aux>
                                    <Button variant="contained" color="primary" size="small" onClick={this.saveClick} disabled={!this.state.enableSave} data-cy='save'>
                                        {t('save')}
                                    </Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {this.props.permission.unlock &&
                                <Aux>
                                    <Button variant="contained" color="secondary" size="small" onClick={this.unlock} data-cy='unlock'>
                                        {locked ? t('unlock') : t('lock')}
                                    </Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {!locked &&
                                <Button variant="contained" color="secondary" size="small" onClick={this.deleteToggle} data-cy='delete'>{t('delete')}</Button>
                            }

                        </Grid>
                    </Grid>
                    <br/>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <EquipmentCalibrationChecks id={this.state._id} permission={this.props.permission}/>
                            <br/>
                            <EquipmentLogs id={this.state._id} permission={this.props.permission}/>
                            <br/>
                            <RoomCleanings equipment={this.state._id}/>
                        </Aux>
                    }
                </Panel>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.equipment}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Equipment));
