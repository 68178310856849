import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import SectionTitle from '../UI/Typography/SectionTitle';
import { readOnlyFieldSecurity } from '../../helpers/securityHelper';
import { SaveButton } from '../UI/Buttons/Buttons';
import ActionBar from '../UI/Buttons/ActionBar';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ModalDialog from '../UI/Dialog/ModalDialog';
import { Link } from 'react-router-dom';
import ConfirmDelete from '../General/ConfirmDelete';

class Strain extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            name: '',
            description: '',
            type: '',
            types: [],
            foreignId: '',
            wetOutputPerPlant: '0',
            wetOutputUOM: '',
            uoms: [],
            attritionPercentProp: '0',
            attritionPercentVeg: '0',
            attritionPercentFlower: '0',
            outputCategories: [],
            itemCatOptions: [],
            typeSel: null,
            wetUOMSel: null,
            catSel: [],
            dryingPercentLoss: '0',
            trimmingPercentLoss: '',
            areaUOMs: [],
            densityUOM: '',
            densSel: null,
            propDensity: '',
            vegDensity: '',
            flowerDensity: '',
            shortCode: '',
            cloneItem: '',
            cloneItemSel: null,
            seedItem: '',
            seedItemSel: null,
            items: [],
            vegItem: '',
            vegItemSel: null,
            flowerItem: '',
            flowerItemSel: null,
            wetFlowerItem: '',
            wetFlowerSel: null,
            dryFlowerItem: '',
            dryFlowerSel: null,
            security: [],
            readOnly: false,
            rooms: [],
            cloneRooms: [],
            cloneRoomsSel: [],
            vegRooms: [],
            vegRoomsSel: [],
            flowerRooms: [],
            flowerRoomsSel: [],
            motherItem: '',
            motherItemSel: null,
            cureDays: '',
            curingPercentLoss: '',
            template: '',
            templateSel: null,
            templates: [],
            pollenItem: '',
            pollenItemSel: null,
            dryingShakePercent: '',
            trimmingShakePercent: '',
            curingShakePercent: '',
            enableSave: true,
            hemp: false,
            lineage: '',
            showGenerate: false,
            seeds: false,
            clones: false,
            veg: false,
            flower: false,
            mother: false,
            pollen: false,
            dryFlower: false,
            wetFlower: false,
            wetTrim: false,
            dryTrim: false,
            dryCured: false,
            dryTrimCure: false,
            wetShake: false,
            dryShake: false,
            kief: false,
            growingSample: false,
            plantlet: false,
            rootlet: false,
            weanlet: false,
            deleteOpen: false
        };
    }

    async componentDidMount(){
        const { areaUOMs, batchTemplates, testCategories, quantityUOMs, rooms, strainTypes } =
            await ValueHelper.getCachedValues([common.cacheValues.quantityUOM, common.cacheValues.growingRoom, common.cacheValues.batchTemplate, common.cacheValues.testCategory,
            common.cacheValues.strainType, common.cacheValues.testCategory, common.cacheValues.allItems, common.cacheValues.room, common.cacheValues.areaUOM ], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        const id = this.props.match.params.id;
        const readOnly = readOnlyFieldSecurity(this.props.permission);
        this.setState({
            types: strainTypes,
            uoms: quantityUOMs,
            itemCatOptions: testCategories,
            areaUOMs: areaUOMs,
            templates: batchTemplates,
            _id: id,
            security: this.props.permission,
            rooms: rooms,
            readOnly: readOnly
        }, () => {
            if(id != null && id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/strains', label: this.props.t('strains'), screen: this.props.auth.screenDefs.Strain},
                    { path: 'active', label: this.props.t('strain'), screen: this.props.auth.screenDefs.Strain}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        })
    }

    loadRecord = async () => {
        let id = this.state._id != null && this.state._id !== '' ? this.state._id : this.props.match.params.id;
        let record = await axios.get('/api/strain/' + id);
        let typeSel = this.state.types.find(x => x.value === record.data.type);
        let uoms = this.state.uoms;
        let wetUOMSel = uoms.find(x => x.value === record.data.wetOutputUOM);
        let itemCats = this.state.itemCatOptions;
        let cats = [];
        record.data.outputCategories.forEach(row => {
            let cat = itemCats.find(x => x.value === row.category._id);
            cats.push(cat);
        });
        let densSel = record.data.densityUOM != null ? this.state.areaUOMs.find(x => x.value === record.data.densityUOM) : null;
        let cloneItemSel = record.data.cloneItem != null ? record.data.items.find(x => x.value === record.data.cloneItem) : null;
        let vegItemSel = record.data.vegItem != null ? record.data.items.find(x => x.value === record.data.vegItem) : null;
        let flowerItemSel = record.data.flowerItem != null ? record.data.items.find(x => x.value === record.data.flowerItem) : null;
        let wetFlowerSel = record.data.wetFlowerItem != null ? record.data.items.find(x => x.value === record.data.wetFlowerItem) : null;
        let dryFlowerSel = record.data.dryFlowerItem != null ? record.data.items.find(x => x.value === record.data.dryFlowerItem) : null;
        let pollenItemSel = record.data.pollenItem != null ? record.data.items.find(x => x.value === record.data.pollenItem) : null;
        let templateSel = record.data.template != null ? this.state.templates.find(x => x.value === record.data.template) : null;
        let seedItemSel = record.data.seedItem != null ? record.data.items.find(x => x.value === record.data.seedItem) : null;
        let motherItemSel = record.data.motherItem != null ? record.data.items.find(x => x.value === record.data.motherItem) : null;
        let cloneRoomsSel = [];
        if(record.data.cloneRooms != null && record.data.cloneRooms.length > 0){
            record.data.cloneRooms.forEach(row => {
                let val = this.state.rooms.find(x => x.value === row);
                cloneRoomsSel.push(val);
            });
        }
        let vegRoomsSel = [];
        if(record.data.vegRooms != null && record.data.vegRooms.length > 0){
            record.data.vegRooms.forEach(row => {
                let val = this.state.rooms.find(x => x.value === row);
                vegRoomsSel.push(val);
            });
        }
        let flowerRoomsSel = [];
        if(record.data.flowerRooms != null && record.data.flowerRooms.length > 0){
            record.data.flowerRooms.forEach(row => {
                let val = this.state.rooms.find(x => x.value === row);
                flowerRoomsSel.push(val);
            });
        }
        this.setState({
            _id: record.data._id,
            name: record.data.name,
            description: record.data.description,
            type: record.data.type,
            typeSel: typeSel,
            foreignId: record.data.foreignId,
            wetOutputPerPlant: record.data.wetOutputPerPlant,
            wetOutputUOM: record.data.wetOutputUOM,
            attritionPercentProp: record.data.attritionPercentProp,
            attritionPercentVeg: record.data.attritionPercentVeg,
            attritionPercentFlower: record.data.attritionPercentFlower,
            outputCategories: record.data.outputCategories,
            dryingPercentLoss: record.data.dryingPercentLoss,
            trimmingPercentLoss: record.data.trimmingPercentLoss,
            wetUOMSel: wetUOMSel,
            catSel: cats,
            densityUOM: record.data.densityUOM,
            densSel: densSel,
            propDensity: record.data.propDensity,
            vegDensity: record.data.vegDensity,
            flowerDensity: record.data.flowerDensity,
            shortCode: record.data.shortCode,
            cloneItem: record.data.cloneItem,
            cloneItemSel: cloneItemSel,
            vegItem: record.data.vegItem,
            vegItemSel: vegItemSel,
            flowerItem: record.data.flowerItem,
            flowerItemSel: flowerItemSel,
            wetFlowerItem: record.data.wetFlowerItem,
            wetFlowerSel: wetFlowerSel,
            dryFlowerItem: record.data.dryFlowerItem,
            dryFlowerSel: dryFlowerSel,
            seedItem: record.data.seedItem,
            seedItemSel: seedItemSel,
            cloneRooms: record.data.cloneRooms,
            cloneRoomsSel: cloneRoomsSel,
            vegRooms: record.data.vegRooms,
            vegRoomsSel: vegRoomsSel,
            flowerRooms: record.data.flowerRooms,
            flowerRoomsSel: flowerRoomsSel,
            motherItem: record.data.motherItem,
            motherItemSel: motherItemSel,
            cureDays: record.data.cureDays,
            curingPercentLoss: record.data.curingPercentLoss,
            template: record.data.template,
            templateSel: templateSel,
            pollenItem: record.data.pollenItem,
            pollenItemSel: pollenItemSel,
            dryingShakePercent: record.data.dryingShakePercent,
            trimmingShakePercent: record.data.trimmingShakePercent,
            curingShakePercent: record.data.curingShakePercent,
            hemp: record.data.hemp,
            lineage: record.data.lineage,
            items: record.data.items,
            deleteOpen: false
        });
    }

    changeValue = e => {
        let name = e.target.name;
        let value = e.target.value;
        if(name === "wetOutputPerPlant" || name === "attritionPercentProp" || name === "attritionPercentVeg" || name === "attritionPercentFlower" ||
            name === "cureDays" || name === "dryingPercentLoss" || name === "propDensity" || name === "vegDensity" || name === "flowerDensity" ||
            name === "trimmingPercentLoss" || name === "curingPercentLoss"){
            if(isNaN(value)){
                return;
            }
        }
        if(name === 'shortCode')
            value = value.toUpperCase();
        this.setState({
            [name]: value
        });
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeSimpleNumber(name, e){
        this.setState({[name]: e.value});
    }

    changeCatNumber = (name, catIndex, itemIndex) => (e) => {
        const outputCategories = this.state.outputCategories;
        let cat = outputCategories[catIndex];
        cat.items[itemIndex][name] = e.target.value;
        this.setState({outputCategories: outputCategories});
    };

    changeMulti = (name, data) => (e, newValue) => {
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            [name]: newValue,
            [data]: ids
        })
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    };

    categoryChange = async (e, newValue) => {
        if(newValue != null && newValue.length > 0){
            let categories = this.state.outputCategories;
            if(newValue.length > this.state.outputCategories.length){
                const catItems = await axios.get('/api/straincategoryitems/' + newValue[newValue.length - 1].value);
                categories.push(catItems.data);
            } else {
                for(let i = 0; i < categories.length; i++){
                    let exists = newValue.find(x => x.value === categories[i].category._id);
                    if(!exists){
                        categories.splice(i, 1);
                        break;
                    }
                }
            }
            this.setState({
                catSel: newValue,
                outputCategories: categories
            })
        } else {
            this.setState({catSel: newValue, outputCategories: []})
        }

    };

    createItems = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: true}, () => {
            this._createItems(e);
        });
    }

    _createItems = async (e) => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            let data = {
                strainId: this.state._id,
                seeds: this.state.seeds,
                clones: this.state.clones,
                veg: this.state.veg,
                flower: this.state.flower,
                mother: this.state.mother,
                pollen: this.state.pollen,
                dryFlower: this.state.dryFlower,
                wetFlower: this.state.wetFlower,
                wetTrim: this.state.wetTrim,
                dryTrim: this.state.dryTrim,
                dryCured: this.state.dryCured,
                dryTrimCure: this.state.dryTrimCure,
                wetShake: this.state.wetShake,
                dryShake: this.state.dryShake,
                kief: this.state.kief,
                growingSample: this.state.growingSample,
                plantlet: this.state.plantlet,
                rootlet: this.state.rootlet,
                weanlet: this.state.weanlet
            };
            try {
                let result = await axios.post('/api/straincreateitems', data);
                let params = {strain: this.state._id};
                const values = await axios.get('/api/strainvalues', {params: params});
                this.setState({
                    items: values.data.items,
                });
                this.setState({enableSave: true});
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        }
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let cats = [];
        this.state.outputCategories.forEach(cat => {
            let rec = {};
            let items = [];
            rec.category = cat.category._id;
            cat.items.forEach(item => {
                items.push({
                    item: item.item._id,
                    expectedValue: item.expectedValue,
                    minValue: item.minValue,
                    maxValue: item.maxValue
                })
            });
            rec.items = items;
            cats.push(rec);
        });
        const data = {
            _id: this.state._id,
            name: this.state.name,
            description: this.state.description,
            type: this.state.type,
            foreignId: this.state.foreignId,
            wetOutputPerPlant: this.state.wetOutputPerPlant,
            wetOutputUOM: this.state.wetOutputUOM,
            attritionPercentProp: this.state.attritionPercentProp,
            attritionPercentVeg: this.state.attritionPercentVeg,
            attritionPercentFlower: this.state.attritionPercentFlower,
            outputCategories: cats,
            dryingPercentLoss: this.state.dryingPercentLoss,
            trimmingPercentLoss: this.state.trimmingPercentLoss,
            densityUOM: this.state.densityUOM,
            propDensity: this.state.propDensity,
            vegDensity: this.state.vegDensity,
            flowerDensity: this.state.flowerDensity,
            shortCode: this.state.shortCode,
            seedItem: this.state.seedItem,
            cloneItem: this.state.cloneItem,
            vegItem: this.state.vegItem,
            flowerItem: this.state.flowerItem,
            wetFlowerItem: this.state.wetFlowerItem,
            dryFlowerItem: this.state.dryFlowerItem,
            vegRooms: this.state.vegRooms,
            cloneRooms: this.state.cloneRooms,
            flowerRooms: this.state.flowerRooms,
            motherItem: this.state.motherItem,
            cureDays: this.state.cureDays,
            curingPercentLoss: this.state.curingPercentLoss,
            template: this.state.template,
            pollenItem: this.state.pollenItem,
            dryingShakePercent: this.state.dryingShakePercent,
            trimmingShakePercent: this.state.trimmingShakePercent,
            curingShakePercent: this.state.curingShakePercent,
            hemp: this.state.hemp,
            lineage: this.state.lineage
        }
        try {
            const result = await axios.post('/api/strain', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/strain/' + result.data.id);
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    validate(){
        const t = this.props.t;
        let errors = {};
        if(this.state.name == null || this.state.name === '')
            errors[t('name')] = t('required');
        if(this.state.shortCode == null || this.state.shortCode === '')
            errors[t('shortCode')] = t('required');
        if(this.state.wetOutputPerPlant != null && this.state.wetOutputPerPlant !== 0 && this.state.wetOutputPerPlant !== '' && this.state.wetOutputPerPlant !== '0'){
            if(this.state.wetOutputUOM == null || this.state.wetOutputUOM === '')
                errors[t('wetOutputUOM')] = t('required');
        }
        if(this.state.outputCategories != null && this.state.outputCategories.length > 0){
            for(let [rowCount, row] of this.state.outputCategories.entries()) {
                for(let [itemCount, item] of row.items.entries()) {
                    if(item.expectedValue == null || item.expectedValue === '')
                        errors[`${t('expectedValue')} (${t('line')} ${rowCount}, ${itemCount})`] = t('required');
                };
            };
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e));
    }

    _deleteClick = async (e) => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/strain/' + this.state._id);
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.setState({deleteOpen: false, enableSave: true});
            this.props.history.goBack();
        }
    }

    toggleGenerate = (e) => {
        this.setState({showGenerate: !this.state.showGenerate});
    }

    buttonStack = () => {
        let buttons = [
            <Button variant='text' onClick={this.clearAll} enabled 
                    key='clear' color='secondary' >{this.props.t('clear')}</Button>,
            <Button variant='text' onClick={this.selectGrowing} enabled 
                    key='growing' color='secondary' >{this.props.t('growing')}</Button>,
            <Button variant='text' onClick={this.selectProcessing} enabled 
                    key='processing' color='secondary' >{this.props.t('processing')}</Button>,
            <Button variant='text' onClick={this.selectTissue} enabled 
                    key='tissue' color='secondary' >{this.props.t('tissueCulture')}</Button>,
            <Button variant='text' onClick={this.toggleGenerate} enabled 
                    key='close' color='secondary' >{this.props.t('close')}</Button>,
            <SaveButton saveClick={this.createItems} enabled={!this.state.readOnly} key='generateItems'/>
        ];
        return buttons;
    }

    clearAll = (e) => {
        this.setState({
            seeds: false,
            clones: false,
            veg: false,
            flower: false,
            wetFlower: false,
            pollen: false,
            mother: false,
            dryFlower: false,
            wetTrim: false,
            dryTrim: false,
            dryCured: false,
            dryTrimCure: false,
            wetShake: false,
            dryShake: false,
            kief: false,
            growingSample: false,
            plantlet: false,
            rootlet: false,
            weanlet: false
        });
    }

    selectGrowing = (e) => {
        this.setState({
            seeds: true,
            clones: true,
            veg: true,
            flower: true,
            wetFlower: true,
            pollen: true,
            mother: true,
            growingSample: true
        });
    }

    selectProcessing = (e) => {
        this.setState({
            dryFlower: true,
            wetTrim: true,
            dryTrim: true,
            dryCured: true,
            dryTrimCure: true,
            wetShake: true,
            dryShake: true,
            kief: true
        });
    }

    selectTissue = (e) => {
        this.setState({
            plantlet: true,
            rootlet: true,
            weanlet: true
        });
    }

    render(){
        const errors = this.props.errors;
        const readOnly = this.state.readOnly;
        const t = this.props.t;
        const user = this.props.auth.user;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={8} sm={4}>
                            {!readOnly &&
                                <TextField value={this.state.name} onChange={this.changeValue} name="name" data-cy="strainname"
                                size="medium" fullWidth={true} disabled={readOnly} label={t('name')} required
                                error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                            }
                            {readOnly &&
                                <FormControl>
                                    <FormLabel>{t('name')}</FormLabel>
                                    {this.state.name}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!readOnly &&
                                <TextField value={this.state.shortCode} onChange={this.changeValue} name="shortCode"
                                size="medium" fullWidth={true} disabled={readOnly} label={t('code')} required
                                error={errors[t('shortCode')] != null ? true : false} helperText={errors[t('shortCode')]}/>
                            }
                            {readOnly &&
                                <FormControl>
                                    <FormLabel>{t('code')}</FormLabel>
                                    {this.state.code}
                                </FormControl>
                            }
                        </Grid>
                        {user.isCannabis && !user.isMushrooms &&
                            <Grid item xs={4} sm={2}>
                                {!readOnly &&
                                    <AutoCompleteField
                                        value={this.state.typeSel}
                                        options={this.state.types}
                                        onChange={this.changeAuto('typeSel', 'type')}
                                        error={errors[t('type')] != null ? true : false}
                                        helperText={errors[t('type')]} 
                                        label={t('type')}
                                        disabled={readOnly}
                                    />
                                }
                                {readOnly &&
                                    <FormControl>
                                        <FormLabel>{t('type')}</FormLabel>
                                        {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            <CheckboxField
                            checked={this.state.hemp} onChange={this.changeBool('hemp')}
                            label={t('hemp')} name="hemp" disabled={readOnly}
                            />
                        </Grid>
                        <Grid item sm={11}>
                            {!readOnly &&
                                <TextField value={this.state.description} onChange={this.changeValue} name="description" multiline={true} rows="3"
                                variant="outlined" size="medium" fullWidth={true} disabled={readOnly} label={t('description')} 
                                error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                            }
                            {readOnly &&
                                <FormControl>
                                    <FormLabel>{t('description')}</FormLabel>
                                    {this.state.description}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item sm={11}>
                            {!readOnly &&
                                <TextField value={this.state.lineage} onChange={this.changeValue} name="lineage" multiline={true} rows="2"
                                variant="outlined" size="medium" fullWidth={true} disabled={readOnly} label={t('lineage')} 
                                error={errors[t('lineage')] != null ? true : false} helperText={errors[t('lineage')]}/>
                            }
                            {readOnly &&
                                <FormControl>
                                    <FormLabel>{t('lineage')}</FormLabel>
                                    {this.state.lineage}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={10} sm={5}>
                            {!readOnly &&
                                <AutoCompleteField
                                    value={this.state.templateSel}
                                    options={this.state.templates}
                                    onChange={this.changeAuto('templateSel', 'template')}
                                    error={errors[t('template')] != null ? true : false}
                                    helperText={errors[t('template')]} 
                                    label={t('batchTemplate')}
                                    disabled={readOnly}
                                />
                            }
                            {readOnly &&
                                <FormControl>
                                    <FormLabel>{t('batchTemplate')}</FormLabel>
                                    {this.state.templateSel != null ? this.state.templateSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!readOnly &&
                                <TextField type="number" value={this.state.wetOutputPerPlant} onChange={this.changeValue} name="wetOutputPerPlant"
                                size="medium" fullWidth={true} disabled={readOnly} label={t('plantWetOutput')} 
                                error={errors[t('wetOutputPerPlant')] != null ? true : false} helperText={errors[t('wetOutputPerPlant')]}/>
                            }
                            {readOnly &&
                                <FormControl>
                                    <FormLabel>{t('plantWetOutput')}</FormLabel>
                                    {this.state.wetUOMSel != null ? this.state.wetOutputPerPlant + this.state.wetUOMSel.label : this.state.wetOutputPerPlant }
                                </FormControl>
                            }
                        </Grid>
                        {!readOnly &&
                            <Grid item xs={2} sm={1}>
                                <AutoCompleteField
                                    value={this.state.wetUOMSel}
                                    options={this.state.uoms}
                                    onChange={this.changeAuto('wetUOMSel', 'wetOutputUOM')}
                                    error={errors[t('wetOutputUOM')] != null ? true : false}
                                    helperText={errors[t('wetOutputUOM')]} 
                                    label={t('uom')}
                                    disabled={readOnly}
                                    required={this.state.wetOutputPerPlant != null && this.state.wetOutputPerPlant !== 0 &&
                                               this.state.wetOutputPerPlant !== '' && this.state.wetOutputPerPlant !== '0'}
                                />
                            </Grid>
                        }
                        {(user.isCannabis == null || user.isCannabis) &&
                            <Aux>
                                <Grid item xs={4} sm={2}>
                                    {!readOnly &&
                                        <TextField type="number" value={this.state.attritionPercentProp} onChange={this.changeValue} name="attritionPercentProp"
                                        size="medium" fullWidth={true} disabled={readOnly} label={t('propAttrPercent')} 
                                        error={errors[t('attritionPercentProp')] != null ? true : false} helperText={errors[t('attritionPercentProp')]}/>
                                    }
                                    {readOnly &&
                                        <FormControl>
                                            <FormLabel>{t('propAttrPercent')}</FormLabel>
                                            {this.state.attritionPercentProp}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!readOnly &&
                                        <TextField type="number" value={this.state.attritionPercentVeg} onChange={this.changeValue} name="attritionPercentVeg"
                                        size="medium" fullWidth={true} disabled={readOnly} label={t('vegAttrPercent')} 
                                        error={errors[t('attritionPercentVeg')] != null ? true : false} helperText={errors[t('attritionPercentVeg')]}/>
                                    }
                                    {readOnly &&
                                        <FormControl>
                                            <FormLabel>{t('vegAttrPercent')}</FormLabel>
                                            {this.state.attritionPercentVeg}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!readOnly &&
                                        <TextField type="number" value={this.state.attritionPercentFlower} onChange={this.changeValue} name="attritionPercentFlower"
                                        size="medium" fullWidth={true} disabled={readOnly} label={t('flowerAttrPercent')} 
                                        error={errors[t('attritionPercentFlower')] != null ? true : false} helperText={errors[t('attritionPercentFlower')]}/>
                                    }
                                    {readOnly &&
                                        <FormControl>
                                            <FormLabel>{t('flowerAttrPercent')}</FormLabel>
                                            {this.state.attritionPercentFlower}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!readOnly &&
                                        <TextField type="number" value={this.state.dryingPercentLoss} onChange={this.changeValue} name="dryingPercentLoss"
                                        size="medium" fullWidth={true} disabled={readOnly} label={t('dryingPercentLoss')} 
                                        error={errors[t('dryingPercentLoss')] != null ? true : false} helperText={errors[t('dryingPercentLoss')]}/>
                                    }
                                    {readOnly &&
                                        <FormControl>
                                            <FormLabel>{t('dryingPercentLoss')}</FormLabel>
                                            {this.state.dryingPercentLoss}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!readOnly &&
                                        <TextField type="number" value={this.state.trimmingPercentLoss} onChange={this.changeValue} name="trimmingPercentLoss"
                                        size="medium" fullWidth={true} disabled={readOnly} label={t('trimmingPercentLoss')} 
                                        error={errors[t('trimmingPercentLoss')] != null ? true : false} helperText={errors[t('trimmingPercentLoss')]}/>
                                    }
                                    {readOnly &&
                                        <FormControl>
                                            <FormLabel>{t('trimmingPercentLoss')}</FormLabel>
                                            {this.state.trimmingPercentLoss}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!readOnly &&
                                        <TextField type="number" value={this.state.cureDays} onChange={this.changeValue} name="cureDays"
                                        size="medium" fullWidth={true} disabled={readOnly} label={t('cureDays')} 
                                        error={errors[t('cureDays')] != null ? true : false} helperText={errors[t('cureDays')]}/>
                                    }
                                    {readOnly &&
                                        <FormControl>
                                            <FormLabel>{t('cureDays')}</FormLabel>
                                            {this.state.cureDays}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!readOnly &&
                                        <TextField type="number" value={this.state.curingPercentLoss} onChange={this.changeValue} name="curingPercentLoss"
                                        size="medium" fullWidth={true} disabled={readOnly} label={t('curingPercentLoss')} 
                                        error={errors[t('curingPercentLoss')] != null ? true : false} helperText={errors[t('curingPercentLoss')]}/>
                                    }
                                    {readOnly &&
                                        <FormControl>
                                            <FormLabel>{t('curingPercentLoss')}</FormLabel>
                                            {this.state.curingPercentLoss}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!readOnly &&
                                        <TextField type="number" value={this.state.dryingShakePercent} onChange={this.changeValue} name="dryingShakePercent"
                                        size="medium" fullWidth={true} disabled={readOnly} label={t('dryingShakePercent')} 
                                        error={errors[t('dryingShakePercent')] != null ? true : false} helperText={errors[t('dryingShakePercent')]}/>
                                    }
                                    {readOnly &&
                                        <FormControl>
                                            <FormLabel>{t('dryingShakePercent')}</FormLabel>
                                            {this.state.dryingShakePercent}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!readOnly &&
                                        <TextField type="number" value={this.state.trimmingShakePercent} onChange={this.changeValue} name="trimmingShakePercent"
                                        size="medium" fullWidth={true} disabled={readOnly} label={t('trimmingShakePercent')} 
                                        error={errors[t('trimmingShakePercent')] != null ? true : false} helperText={errors[t('trimmingShakePercent')]}/>
                                    }
                                    {readOnly &&
                                        <FormControl>
                                            <FormLabel>{t('trimmingShakePercent')}</FormLabel>
                                            {this.state.trimmingShakePercent}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!readOnly &&
                                        <TextField type="number" value={this.state.curingShakePercent} onChange={this.changeValue} name="curingShakePercent"
                                        size="medium" fullWidth={true} disabled={readOnly} label={t('curingShakePercent')} 
                                        error={errors[t('curingShakePercent')] != null ? true : false} helperText={errors[t('curingShakePercent')]}/>
                                    }
                                    {readOnly &&
                                        <FormControl>
                                            <FormLabel>{t('curingShakePercent')}</FormLabel>
                                            {this.state.curingShakePercent}
                                        </FormControl>
                                    }
                                </Grid>
                            </Aux>
                        }
                        {!user.isMushrooms &&
                            <Grid item xs={4} sm={2}>
                                {!readOnly &&
                                    <AutoCompleteField
                                        value={this.state.densSel}
                                        options={this.state.areaUOMs}
                                        onChange={this.changeAuto('densSel', 'densityUOM')}
                                        error={errors[t('densityUOM')] != null ? true : false}
                                        helperText={errors[t('densityUOM')]} 
                                        label={t('densityUom')}
                                        disabled={readOnly}
                                    />
                                }
                                {readOnly &&
                                    <FormControl>
                                        <FormLabel>{t('densityUom')}</FormLabel>
                                        {this.state.densSel != null ? this.state.densSel.label : ''}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {!user.isMushrooms &&
                            <Grid item xs={4} sm={2}>
                                {!readOnly &&
                                    <TextField type="number" value={this.state.propDensity} onChange={this.changeValue} name="propDensity"
                                    size="medium" fullWidth={true} disabled={readOnly} label={t('propDensity')} 
                                    error={errors[t('propDensity')] != null ? true : false} helperText={errors[t('propDensity')]}/>
                                }
                                {readOnly &&
                                    <FormControl>
                                        <FormLabel>{t('propDensity')}</FormLabel>
                                        {this.state.propDensity}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {!user.isMushrooms &&
                            <Grid item xs={4} sm={2}>
                                {!readOnly &&
                                    <TextField type="number" value={this.state.vegDensity} onChange={this.changeValue} name="vegDensity"
                                    size="medium" fullWidth={true} disabled={readOnly} label={t('vegDensity')} 
                                    error={errors[t('vegDensity')] != null ? true : false} helperText={errors[t('vegDensity')]}/>
                                }
                                {readOnly &&
                                    <FormControl>
                                        <FormLabel>{t('vegDensity')}</FormLabel>
                                        {this.state.vegDensity}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {!user.isMushrooms &&
                            <Grid item xs={4} sm={2}>
                                {!readOnly &&
                                    <TextField type="number" value={this.state.flowerDensity} onChange={this.changeValue} name="flowerDensity"
                                    size="medium" fullWidth={true} disabled={readOnly} label={t('flowerDensity')} 
                                    error={errors[t('flowerDensity')] != null ? true : false} helperText={errors[t('flowerDensity')]}/>
                                }
                                {readOnly &&
                                    <FormControl>
                                        <FormLabel>{t('flowerDensity')}</FormLabel>
                                        {this.state.flowerDensity}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {!user.isMushrooms &&
                            <Aux>
                                <Grid item sm={6}>
                                    <AutoCompleteField
                                        multiple={true}
                                        stayOpen={true}
                                        value={this.state.cloneRoomsSel}
                                        options={this.state.rooms}
                                        onChange={this.changeMulti('cloneRoomsSel', 'cloneRooms')}
                                        error={errors[t('cloneRooms')] != null ? true : false}
                                        helperText={errors[t('cloneRooms')]} 
                                        label={t('cloneRooms')}
                                        disabled={readOnly}
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <AutoCompleteField
                                        multiple={true}
                                        stayOpen={true}
                                        value={this.state.vegRoomsSel}
                                        options={this.state.rooms}
                                        onChange={this.changeMulti('vegRoomsSel', 'vegRooms')}
                                        error={errors[t('vegRooms')] != null ? true : false}
                                        helperText={errors[t('vegRooms')]} 
                                        label={t('vegRooms')}
                                        disabled={readOnly}
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <AutoCompleteField
                                        multiple={true}
                                        stayOpen={true}
                                        value={this.state.flowerRoomsSel}
                                        options={this.state.rooms}
                                        onChange={this.changeMulti('flowerRoomsSel', 'flowerRooms')}
                                        error={errors[t('flowerRooms')] != null ? true : false}
                                        helperText={errors[t('flowerRooms')]} 
                                        label={t('flowerRooms')}
                                        disabled={readOnly}
                                    />
                                </Grid>
                                
                            </Aux>
                        }
                    </Grid>
                    {this.state.items != null && this.state.items.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('items')}/>
                            <TableContainer component={Paper}>
                                <Table aria-label="Items" size="small">
                                    <TableBody>
                                        {this.state.items.map((row, i) => 
                                            <TableRow key={'item-'+i}>
                                                <TableCell><Link to={'/item/' + row.value}>{row.label}</Link></TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {this.state.items.map((row, i) =>
                                <Grid container spacing={2} key={'item-' + i}>
                                    <Grid item xs={8} sm={4}>
                                        <Link to={'/items/' + row._id}>{row.name}</Link>
                                    </Grid>
                                </Grid>
                            )}
                            <br/>
                        </Aux>
                    }
                    <Grid container spacing={3}>
                        <Grid item sm={11}>
                            <AutoCompleteField
                                multiple={true}
                                stayOpen={true}
                                value={this.state.catSel}
                                options={this.state.itemCatOptions}
                                onChange={this.categoryChange}
                                error={errors[t('outputCategories')] != null ? true : false}
                                helperText={errors[t('outputCategories')]} 
                                label={t('categories')}
                                disabled={readOnly}
                            />
                        </Grid>
                    </Grid>
                    {this.state.outputCategories != null && this.state.outputCategories.length > 0 && this.state.outputCategories.map((row, i) =>
                        <Aux key={'outputCategories-' + i}>
                            <br/>
                            <SectionTitle title={row.category.code}/>
                            <TableContainer component={Paper}>
                                <Table aria-label="Output categories">
                                    <TableHead>
                                        <TableRow key="tableHeader">
                                            <TableCell>{t('item')}</TableCell>
                                            <TableCell>{t('expectedValue')}</TableCell>
                                            <TableCell>{t('minValue')}</TableCell>
                                            <TableCell>{t('maxValue')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.items.map((item, k) =>
                                            <TableRow key={'outputCategories-'+ k + '-' + row._id}>
                                                <TableCell>
                                                    {item.item.name}
                                                </TableCell>
                                                <TableCell>
                                                    {!readOnly &&
                                                        <TextField type="number" onChange={this.changeCatNumber('expectedValue', i, k)}
                                                        name={'expectedValue' + i + k } value={item.expectedValue}
                                                        size="medium" fullWidth={true} disabled={readOnly} 
                                                        error={errors[`${t('expectedValue')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('expectedValue')} (${t('line')} ${i}, ${k})`]}/>
                                                    }
                                                    {readOnly && item.expectedValue}
                                                </TableCell>
                                                <TableCell>
                                                    {!readOnly &&
                                                        <TextField type="number" onChange={this.changeCatNumber('minValue', i, k)}
                                                        name={'minValue' + i + k } value={item.minValue}
                                                        size="medium" fullWidth={true} disabled={readOnly} 
                                                        error={errors[`${t('minValue')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('minValue')} (${t('line')} ${i}, ${k})`]}/>
                                                    }
                                                    {readOnly && item.minValue}
                                                </TableCell>
                                                <TableCell>
                                                    {!readOnly &&
                                                        <TextField type="number" onChange={this.changeCatNumber('maxValue', i, k)}
                                                        name={'maxValue' + i + k } value={item.maxValue}
                                                        size="medium" fullWidth={true} disabled={readOnly} 
                                                        error={errors[`${t('maxValue')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('maxValue')} (${t('line')} ${i}, ${k})`]}/>
                                                    }
                                                    {readOnly && item.maxValue}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Aux>
                    )}
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            <SaveButton saveClick={() => this.save()} enabled={!readOnly && this.state.enableSave} />
                            {this.state._id != null && this.state._id !== '' && !readOnly &&
                                <Aux>
                                    <Button variant="contained" color="secondary" size="small" onClick={this.toggleGenerate}>{t('generateItems')}</Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {this.state._id != null && this.state._id !== '' && !readOnly && this.props.permission.delete &&
                                <Button variant="contained" color="secondary" size="small" onClick={this.deleteToggle}>{t('delete')}</Button>
                            }
                        </Grid>
                    </Grid>
                </Panel>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                <ModalDialog buttonStack={this.buttonStack()} title={t('generateItems')} toggleDialog={this.toggleGenerate}
                dialogStatus={this.state.showGenerate} fullWidth maxWidth='md'>
                    <SectionTitle title={t('growingItems')}/>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField checked={this.state.seeds} onChange={this.changeBool('seeds')} label={t('seed')} name="seeds" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField checked={this.state.clones} onChange={this.changeBool('clones')} label={t('clone')} name="clones" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField checked={this.state.veg} onChange={this.changeBool('veg')} label={t('vegPlant')} name="veg" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField checked={this.state.flower} onChange={this.changeBool('flower')} label={t('flowerPlant')} name="flower" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField checked={this.state.wetFlower} onChange={this.changeBool('wetFlower')} label={t('freshBulk')} name="wetFlower" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField checked={this.state.mother} onChange={this.changeBool('mother')} label={t('motherPlant')} name="mother" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField checked={this.state.pollen} onChange={this.changeBool('pollen')} label={t('pollen')} name="pollen" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField checked={this.state.growingSample} onChange={this.changeBool('growingSample')} label={t('growingSample')} name="growingSample" disabled={readOnly}/>
                        </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title={t('processingItems')}/>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField checked={this.state.wetTrim} onChange={this.changeBool('wetTrim')} label={t('wetTrimBulk')} name="wetTrim" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField checked={this.state.dryFlower} onChange={this.changeBool('dryFlower')} label={t('dryBulk')} name="dryFlower" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField checked={this.state.dryTrim} onChange={this.changeBool('dryTrim')} label={t('dryTrimBulk')} name="dryTrim" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField checked={this.state.dryCured} onChange={this.changeBool('dryCured')} label={t('dryCuredBulk')} name="dryCured" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField checked={this.state.dryTrimCure} onChange={this.changeBool('dryTrimCure')} label={t('dryCureTrimBulk')} name="dryTrimCure" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField checked={this.state.wetShake} onChange={this.changeBool('wetShake')} label={t('wetShake')} name="wetShake" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField checked={this.state.dryShake} onChange={this.changeBool('dryShake')} label={t('dryShake')} name="dryShake" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField checked={this.state.kief} onChange={this.changeBool('kief')} label={t('kief')} name="kief" disabled={readOnly}/>
                        </Grid>
                        
                    </Grid>
                    <br/>
                    <SectionTitle title={t('tissueCulture')}/>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField checked={this.state.plantlet} onChange={this.changeBool('plantlet')} label={t('plantlet')} name="plantlet" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField checked={this.state.rootlet} onChange={this.changeBool('rootlet')} label={t('rootlet')} name="rootlet" disabled={readOnly}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField checked={this.state.weanlet} onChange={this.changeBool('weanlet')} label={t('weanlet')} name="weanlet" disabled={readOnly}/>
                        </Grid>
                    </Grid>
                </ModalDialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Strain));
