import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Box from '@mui/material/Box';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import SectionTitle from '../UI/Typography/SectionTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link } from 'react-router-dom';
import { fieldSecurity } from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import Switch from '../UI/Switch/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

class FeedingRecipe extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            description: '',
            lines: [],
            items: [],
            item: '',
            itemSel: null,
            uom: '',
            uomSel: null,
            uoms: [],
            quantity: '1',
            percent: '',
            security: {},
            fieldAccess: false,
            showCopy: false,
            newName: '',
            renderNum: Math.random(),
            enableSave: true,
            deleteOpen: false,
            hide: false
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.quantityUOM, common.cacheValues.feedingRecipeItem], this.props.auth.constants, null, this.props.auth.user.tenant);
        const fieldAccess = fieldSecurity(this.props.permission, null, this.props.auth.common, this.props.auth.constants);
        this.setState({
            items: cacheValues.feedingRecipeItems,
            uoms: cacheValues.quantityUOMs,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null
        }, () => {
            if(this.state._id != null){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/feedingrecipes', label: this.props.t('feedingRecipes'), screen: this.props.auth.screenDefs.FeedingRecipe},
                    { path: 'active', label: this.props.t('feedingRecipes'), screen: this.props.auth.screenDefs.FeedingRecipe}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/feedingrecipe/' + this.state._id);
        let lines = record.data.lines;
        for(let line of lines){
            line.itemSel = this.state.items.find(x => x.value === line.item);
            line.uomSel = line.uom != null && line.uom !== '' ? this.state.uoms.find(x => x.value === line.uom) : '';
        }
        let uomSel = this.state.uoms.find(x => x.value === record.data.uom);
        const fieldAccess = fieldSecurity(this.props.permission, null, this.props.auth.common, this.props.auth.constants);
        this.setState({
            number: record.data.number,
            description: record.data.description,
            lines: lines,
            fieldAccess: fieldAccess,
            quantity: record.data.quantity,
            uom: record.data.uom,
            uomSel: uomSel,
            renderNum: Math.random(),
            deleteOpen: false,
            hide: record.data.hide === true ? true : false
        }, () => {
            const crumbs = [
                { path: '/#/feedingrecipes', label: this.props.t('feedingRecipes'), screen: this.props.auth.screenDefs.FeedingRecipe},
                { path: 'active', label: (this.props.t('feedingRecipes') + ' - ' + record.data.number), screen: this.props.auth.screenDefs.FeedingRecipe}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    changeLineValue = (row) => (e) => {
        let name = e.target.name;
        let value = e.target.value
        let lines = this.state.lines;
        let line = lines[row];
        line[name] = value;
        this.setState({lines: lines});
    };

    changeNumber = (row) => (e) => {
        var lines = this.state.lines;
        var line = lines[row];
        var name = e.target.name;
        var value = e.target.value
        if(isNaN(value)){
            return;
        }
        line[name] = value;
        this.setState({lines: lines});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeLineAuto = (name, data, row) => (e, newValue) => {
        let lines = this.state.lines;
        let line = lines[row];
        line[name] = newValue;
        line[data] = newValue != null ? newValue.value : null;
        if(data === 'item'){
            if(newValue != null){
                line.uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                line.uom = newValue.uom;
            }else {
                line.uomSel = null;
                line.uom = '';
            }
        }
        this.setState({lines: lines});
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            _id: this.state._id,
            description: this.state.description,
            lines: this.state.lines,
            uom: this.state.uom,
            quantity: this.state.quantity,
            hide: this.state.hide
        };
        try {
            let result = await axios.post('/api/feedingrecipe', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/feedingrecipe/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.description == null || state.description === '' )
            errors[t('description')] = t('required');
        if(state.quantity == null || state.quantity === '')
            errors[t('quantity')] = t('required');
        if(state.uom == null || state.uom === '')
            errors[t('quantity')] = t('required');
        for(let i = 0; i < state.lines.length; i++){
            let line = state.lines[i];
            if(line.item == null || line.item === '')
                errors[`${t('item')} (${t('line')} ${i})`] = t('required');
            if(line.percent == null || line.percent === ''){
                if(line.quantity == null || line.quantity === '')
                    errors[`${t('quantity')} (${t('line')} ${i})`] = t('required');
                if(line.uom == null || line.uom === '')
                    errors[`${t('uom')} (${t('line')} ${i})`] = t('required');
            }
            if((line.percent == null || line.percent === '') && (line.quantity == null || line.quantity === ''))
                errors[`${t('precent')} (${t('line')} ${i})`] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    addLine = (e) => {
        let lines = this.state.lines;
        lines.push({});
        this.setState({lines: lines});
    };

    deleteLine = (index) => (e) => {
        let lines = this.state.lines;
        lines.splice(index, 1);
        this.setState({lines: lines});
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteRecord = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteRecord(e));
    };

    _deleteRecord = async (e) => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/feedingrecipe', {params: {id: this.state._id}});
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.setState({enableSave: true});
            this.props.history.goBack();
        }
    };

    copyClick = (e) => {
        this.setState({showCopy: true});
    };

    performCopy = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._performCopy(e))
    }

    _performCopy = async (e) => {
        const t = this.props.t;
        if(this.state.newName == null || this.state.newName === ''){
            let error = {};
            error[t('newName')] = t('required');
            this.props.dispatch({ type: 'CREATE_ERROR', payload: error});
            this.setState({ enableSave: true });
            return;
        }
        let data = {id: this.state._id, name: this.state.newName};
        try {
            let result = await axios.post('/api/feedingrecipecopy', data);
            this.setState({enableSave: true});
            this.closeCopy();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    closeCopy = (e) => {
        this.setState({showCopy: false, newName: ''})
    };

    render(){
        const errors = this.props.errors;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        const fieldAccess = this.state.fieldAccess;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar 
                        objectType={this.props.auth.constants.objectIds.feedingRecipe}
                        id={this.state._id}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                    />
                </BreadcrumbBar>
                <Panel>
                <Grid container spacing={3}>
                        {this.state.number != null && this.state.number !== '' &&
                            <Grid item xs={6} sm={3}>
                                <FormControl data-cy="number">
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <TextField value={this.state.description} onChange={this.changeValue} name="description"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('description')} required data-cy="description"
                                error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl data-cy="description">
                                    <FormLabel>{t('description')}</FormLabel>
                                    {this.state.description}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <FormControl data-cy="quantity">
                                <FormLabel>{t('quantity')}</FormLabel>
                                {this.state.quantity}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.uomSel}
                                    options={this.state.uoms}
                                    onChange={this.changeAuto('uomSel', 'uom')}
                                    error={errors[t('quantity')] != null ? true : false}
                                    helperText={errors[t('quantity')]} 
                                    label={t('uom')}
                                    disabled={!fieldAccess}
                                    required
                                    data-cy="uom"
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('uom')}</FormLabel>
                                    {this.state.uomSel != null ? this.state.uomSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={5} md={3}>
                            <FormControlLabel
                                control={<Switch checked={this.state.hide} onChange={this.changeBool('hide')} name="hide" />}
                                label={t('hide')}
                                labelPlacement="start"
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title={t('inputs')}/>
                    {this.state.lines.map((row, i) =>
                        <Box display="flex" borderColor="primary.main" {...defaultProps} key={row._id}>
                            <Grid container spacing={3} key={row._id}>
                                <Grid item xs={8} sm={4}>
                                    {!fieldAccess &&
                                        <FormControl data-cy={'item' + i}>
                                            <FormLabel>{t('item')}</FormLabel>
                                            {row.itemSel != null &&
                                                <Link to={'/item/' + row.item}>{row.itemSel.label}</Link>
                                            }
                                        </FormControl>
                                    }
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={row.itemSel}
                                            options={this.state.items}
                                            onChange={this.changeLineAuto('itemSel', 'item', i)}
                                            error={errors[`${t('item')} (${t('line')} ${i})`] != null ? true : false}
                                            helperText={errors[`${t('item')} (${t('line')} ${i})`]}
                                            label={t('item')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy={'item' + i}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField type="number" value={row.percent} onChange={this.changeNumber(i)} name="percent"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('percent')} data-cy={'percent' + i}
                                        error={errors[`${t('percent')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('percent')} (${t('line')} ${i})`]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy={'percent' + i}>
                                            <FormLabel>{t('percent')}</FormLabel>
                                            {row.percent}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField type="number" value={row.quantity} onChange={this.changeNumber(i)} name="quantity"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} data-cy={'quantity' + i}
                                        error={errors[`${t('quantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('quantity')} (${t('line')} ${i})`]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy={'quantity' + i}>
                                            <FormLabel>{t('quantity')}</FormLabel>
                                            {row.quantity}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <FormControl>
                                        <FormLabel>UOM</FormLabel>
                                        {row.uomSel != null ? row.uomSel.label : ''}
                                    </FormControl>
                                </Grid>
                                {fieldAccess &&
                                    <Grid item sm={3}>
                                        <Button variant="outlined" color="primary" size="small" onClick={this.deleteLine(i)} data-cy={'deleteItem' + i}>{t('deleteLine')}</Button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    )}
                    {fieldAccess &&
                        <Grid container spacing={3}>
                            <Grid item xm={8}>
                                <Button variant="outlined" color="primary" size="small" onClick={this.addLine} data-cy="addLine">{t('addLine')}</Button>&nbsp;&nbsp;
                            </Grid>
                        </Grid>
                    }
                    <Grid container spacing={3}>
                        <Grid item sm={4}>
                            {fieldAccess && this.state.security.update &&
                                <Aux>
                                    <Button disabled={!this.state.enableSave} variant="contained" color="primary" size="small" onClick={this.save} data-cy="save">
                                        {t('save')}
                                    </Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {this.state.security.delete &&
                                <Aux>
                                    <Button variant="contained" color="secondary" size="small" onClick={this.deleteToggle} data-cy="delete">
                                        {t('delete')}
                                    </Button>
                                    &nbsp;&nbsp;
                                </Aux>
                            }
                            {this.state.security.update && this.state._id != null && this.state._id !== '' &&
                                <Button variant="contained" color="secondary" size="small" onClick={this.copyClick} data-cy="copy">{t('copy')}</Button>
                            }
                        </Grid>
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.feedingRecipe}/>
                            <ObjectLog id={this.state._id} renderNum={this.state.renderNum}/>
                        </Aux>
                    }
                </Panel>
                <Dialog open={this.state.showCopy} onClose={this.closeCopy} maxWidth="sm" fullWidth>
                    <DialogTitle>
                        <SectionTitle title={t('copy')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item sm={10}>
                                <TextField value={this.state.newName} onChange={this.changeValue} name="newName"
                                size="medium" fullWidth={true} label={t('newDescription')} 
                                error={errors[t('newName')] != null ? true : false} helperText={errors[t('newName')]}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={this.performCopy}>{t('copy')}</Button>
                        <Button variant="contained" color="secondary" onClick={this.closeCopy}>{t('close')}</Button>
                    </DialogActions>
                </Dialog>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteRecord()} open={this.state.deleteOpen} />
                }
            </Aux>
        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(FeedingRecipe));
