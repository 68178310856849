import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import { approveSigSecurity, completeSigSecurity, reviewSigSecurity } from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Panel from '../UI/Panel/Panel';
import { EvoraVerticalTabs, VerticalTabCanvas } from '../UI/Tabs/Tabs';
import AddIcon from "@mui/icons-material/Add";
import ActionButton from '../UI/Buttons/Actions';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Link from '../UI/Link/Link';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Switch from '../UI/Switch/Switch';
import Tooltip from '@mui/material/Tooltip';
import ModalDialog from '../UI/Dialog/ModalDialog';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import ExpandIcon from '@mui/icons-material/HeightRounded';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteActivityIcon from '@mui/icons-material/ClearRounded';
import EditActivityIcon from '@mui/icons-material/MoreVertRounded';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';

class BatchTemplate extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            code: '',
            description: '',
            status: '',
            statusSel: null,
            statuses: [],
            stages: [],
            stage: '',
            stageSel: null,
            stageOptions: [],
            activity: '',
            actSel: null,
            activityCodes: [],
            duration: '',
            dateShift: '',
            sequence: '',
            inputItem: '',
            inputSel: null,
            items: [],
            outputItem: '',
            outputSel: null,
            inputQuantity: '',
            inputUOM: '',
            inputUOMSel: null,
            uoms: [],
            outputQuantity: '',
            outputUOM: '',
            outputUOMSel: null,
            showStage: false,
            stageIndex: -1,
            activityIndex: -1,
            inputIndex: -1,
            outputIndex: -1,
            showActivity: false,
            showInput: false,
            showOutput: false,
            locked: false,
            showCopy: false,
            newCode: '',
            sopList: [],
            sops: [],
            sopsSel: [],
            batchStartType: '',
            batchStartTypeSel: null,
            batchStartTypes: [],
            batchType: '',
            batchTypeSel: null,
            batchTypes: [],
            inputRequired: false,
            outputRequired: false,
            security: [],
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            enableSave: true,
            tabKey: 0,
            tabs: [{ name: "Add Stage", icon: <AddIcon color="secondary"/>, "data-cy": 'newStage',
                     onClick: () => this.addStage(), disabled: false, type: "button"}],
            totalDuration: 0,
            totalActivities: 0,
            totalMaterialCost: 0,
            totalPerPlantEffort: 0,
            allDrawersOpen: false,
            plantTypes: [],
            plantType: '',
            plantTypeSel: null,
            allStageOptions: [],
            allPlantTypes: [],
            deleteOpen: false,
            stageItems: [],
            feedingLines: [{
                id: 0,
                item: '',
                itemSel: null,
                uom: '',
                uomSel: null,
                daysFromStart: 0,
                perPlant: 0,
                frequency: '',
                frequencySel: null
            }],
            frequencies: [],
            feedingItems: [],
            feedColumnDefs: [],
            feedDefaultColDef: {
                editable: true,
                cellDateType: false
            },
            feedGridApi: null
        };
    }

    async componentDidMount(){
        const constants = this.props.auth.constants;
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.batchActivityItems, common.cacheValues.quantityUOM, common.cacheValues.batchStage,
            common.cacheValues.batchActivityDef, common.cacheValues.batchTemplateStatus, common.cacheValues.batchStartType, common.cacheValues.batchType,
            common.cacheValues.batchActivitySop, common.cacheValues.plantType, common.cacheValues.batchActivityDefRegular, common.cacheValues.batchActivityDefTissue,
            common.cacheValues.feedingFrequency, common.cacheValues.feedingLotItem], constants, null, this.props.auth.user.tenant);
        const statusSel = cacheValues.batchTemplateStatuses.find(x => x.value === this.props.auth.constants.batchTemplateStatuses.inactive);
        const fieldAccess = this.props.permission.update;
        let items = cacheValues.batchActivityItems;
        let stageItems = [];
        let germ = {
            stage: constants.batchStages.germination,
            items: JSON.parse(JSON.stringify(items))
        };
        germ.items = ValueHelper.stageItemSet(constants.batchStages.germination, germ.items, constants);
        stageItems.push(germ);
        let prop = {
            stage: constants.batchStages.prop,
            items: JSON.parse(JSON.stringify(items))
        };
        prop.items = ValueHelper.stageItemSet(constants.batchStages.prop, prop.items, constants);
        stageItems.push(prop);
        let veg = {
            stage: constants.batchStages.veg,
            items: JSON.parse(JSON.stringify(items))
        };
        veg.items = ValueHelper.stageItemSet(constants.batchStages.veg, veg.items, constants);
        stageItems.push(veg);
        let flower = {
            stage: constants.batchStages.flower,
            items: JSON.parse(JSON.stringify(items))
        };
        flower.items = ValueHelper.stageItemSet(constants.batchStages.flower, flower.items, constants);
        stageItems.push(flower);
        let mother = {
            stage: constants.batchStages.mother,
            items: JSON.parse(JSON.stringify(items))
        };
        mother.items = ValueHelper.stageItemSet(constants.batchStages.mother, mother.items, constants);
        stageItems.push(mother);
        let harvest = {
            stage: constants.batchStages.harvest,
            items: JSON.parse(JSON.stringify(items))
        };
        harvest.items = ValueHelper.stageItemSet(constants.batchStages.harvest, harvest.items, constants);
        stageItems.push(harvest);
        let harvestWaste = {
            stage: constants.batchStages.harvestWaste,
            items: JSON.parse(JSON.stringify(items))
        };
        harvestWaste.items = ValueHelper.stageItemSet(constants.batchStages.harvestWaste, harvestWaste.items, constants);
        stageItems.push(harvestWaste);
        let plantlet = {
            stage: constants.batchStages.plantlet,
            items: JSON.parse(JSON.stringify(items))
        };
        plantlet.items = ValueHelper.stageItemSet(constants.batchStages.plantlet, plantlet.items, constants);
        stageItems.push(plantlet);
        let rooting = {
            stage: constants.batchStages.germination,
            items: JSON.parse(JSON.stringify(items))
        };
        rooting.items = ValueHelper.stageItemSet(constants.batchStages.rooting, rooting.items, constants);
        stageItems.push(rooting);
        let weaning = {
            stage: constants.batchStages.germination,
            items: JSON.parse(JSON.stringify(items))
        };
        weaning.items = ValueHelper.stageItemSet(constants.batchStages.weaning, weaning.items, constants);
        stageItems.push(weaning);
        let feedColumnDefs = [
            {
                field: 'item', 
                headerName: this.props.t('item'),
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: cacheValues.feedingLotItems
                },
            }, {
                field: 'uom',
                headerName: this.props.t('uom'),
                editable: false

            }, {
                field: 'daysFromStart',
                headerName: this.props.t('daysFromStart'),
                cellEditor: "agNumberCellEditor"
            }, {
                field: 'perPlant', 
                headerName: this.props.t('perPlant'),
                cellEditor: "agNumberCellEditor"
            }, {
                field: 'frequency', 
                headerName: this.props.t('frequency'),
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: cacheValues.frequencies
                }
            }
        ];
        this.setState({
            statuses: cacheValues.batchTemplateStatuses,
            stageOptions: cacheValues.batchStages,
            allStageOptions: cacheValues.batchStages,
            activityCodes: cacheValues.batchActivityDefs,
            allActivityCodes: cacheValues.batchActivityDefs,
            regularActivities: cacheValues.batchActivityDefsReg,
            tissueActivities: cacheValues.batchActivityDefsTissue,
            items: items,
            uoms: cacheValues.quantityUOMs,
            status: this.props.auth.constants.batchTemplateStatuses.inactive,
            sopList: cacheValues.sops,
            statusSel: statusSel,
            batchStartTypes: cacheValues.batchStartTypes,
            batchTypes: cacheValues.batchTypes,
            plantTypes: cacheValues.plantTypes,
            allPlantTypes: cacheValues.plantTypes,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            stageItems: stageItems,
            frequencies: cacheValues.feedingFrequencies,
            feedingItems: cacheValues.feedingLotItems,
            feedColumnDefs: feedColumnDefs
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== '') {
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/batchtemplates', label: this.props.t('batchTemplates'), screen: this.props.auth.screenDefs.BatchTemplate},
                    { path: 'active', label: this.props.t('batchTemplate'), screen: this.props.auth.screenDefs.BatchTemplate}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const record = await axios.get('/api/batchtemplate/' + this.state._id);
        const locked = record.data.status === this.props.auth.constants.batchTemplateStatuses.active ? true : false;
        let tabs = this.state.tabs;
        let tabKey = this.state.tabKey;
        tabs[0].disabled = locked;
        let stages = record.data.stages;
        if(record.data.stages !== undefined){
            stages.forEach(stage => {
                let stageItem = this.state.stageItems.find(x => x.stage === stage.stage);
                if(stageItem!= null)
                    stage.items = stageItem.items;
                stage.plantTypeSel = stage.plantType != null ? this.state.allPlantTypes.find(x => x.value === stage.plantType) : null;
                stage.activities.forEach(act => {
                    act.accordion = true;
                });
                if(!tabs.some(tab => tab.name === stage.stageSel.label))
                    tabs.push({ name: stage.stageSel.label, disabled: false});
            });
            tabKey = 1;
        }

        const statusSel = this.state.statuses.find(x => x.value === record.data.status);
        const fieldAccess = this.props.permission.update;
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let batchStartTypeSel = this.state.batchStartTypes.find(x => x.value === record.data.batchStartType);
        let batchTypeSel = this.state.batchTypes.find(x => x.value === record.data.batchType);
        let stageOptions = this.state.stageOptions;
        let plantTypes = this.state.plantTypes;
        let activityCodes = this.state.activityCodes
        if(record.data.batchType === this.props.auth.constants.batchTypes.plantlet){
            let stages = this.state.allStageOptions.filter(x => x.value === this.props.auth.constants.batchStages.plantlet || 
                                                            x.value === this.props.auth.constants.batchStages.rooting ||
                                                            x.value === this.props.auth.constants.batchStages.weaning);
            stageOptions = stages;
            plantTypes = this.state.allPlantTypes.filter(x => x.value === this.props.auth.constants.plantTypes.plantlet || x.value === this.props.auth.constants.plantTypes.rootlet ||
                                                        x.value === this.props.auth.constants.plantTypes.weanlet);
            activityCodes = this.state.tissueActivities;
        } else {
            let stages = this.state.allStageOptions.filter(x => x.value !== this.props.auth.constants.batchStages.plantlet &&
                x.value !== this.props.auth.constants.batchStages.rooting &&
                x.value !== this.props.auth.constants.batchStages.weaning);
            stageOptions = stages;
            plantTypes = this.state.allPlantTypes.filter(x => x.value !== this.props.auth.constants.plantTypes.plantlet && x.value !== this.props.auth.constants.plantTypes.rootlet &&
                                                        x.value !== this.props.auth.constants.plantTypes.weanlet);
            activityCodes = this.state.regularActivities;
        }
        let feedingLines = record.data.feedingLines != null ? record.data.feedingLines : [];
        if(feedingLines != null && feedingLines.length > 0){
            let count = 0;
            for(let row of feedingLines){
                row.id = count;
                row.itemSel = row.item != null ? this.state.feedingItems.find(x => x.value === row.item) : null;
                row.uomSel = row.uom != null ? this.state.uoms.find(x => x.value === row.uom) : null;
                row.frequencySel = row.frequency != null ? this.state.frequencies.find(x => x.value === row.frequency) : null;
                count++;
            }
        } else {
            if(fieldAccess && !locked){
                feedingLines = [{
                    id: 0,
                    item: '',
                    itemSel: null,
                    uom: '',
                    uomSel: null,
                    daysFromStart: 0,
                    perPlant: 0,
                    frequency: '',
                    frequencySel: null
                }]
            }
        }
        this.setState({
            code: record.data.code,
            description: record.data.description,
            status: record.data.status,
            statusSel: statusSel,
            stages: stages,
            batchStartType: record.data.batchStartType,
            batchStartTypeSel: batchStartTypeSel,
            batchType: record.data.batchType,
            batchTypeSel: batchTypeSel,
            locked: locked,
            fieldAccess: fieldAccess,
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            tabs: tabs,
            tabKey: tabKey,
            totalDuration: record.data.totalDuration,
            totalActivities: record.data.totalActivities,
            stageOptions: stageOptions,
            plantTypes: plantTypes,
            activityCodes: activityCodes,
            deleteOpen: false,
            feedingLines: feedingLines != null ? feedingLines : [],
        }, () => {
            const crumbs = [
                { path: '/#/batchtemplates', label: this.props.t('batchTemplates'), screen: this.props.auth.screenDefs.BatchTemplate},
                { path: 'active', label: (this.props.t('batchTemplate') + ' - ' + record.data.code), screen: this.props.auth.screenDefs.BatchTemplate}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.updateTotals()}
        );
    };

    updateTotals = () => {
        let stages = this.state.stages;
        let totalMaterialCost = 0;
        let totalPerPlantEffort = 0;
        let totalActivities = 0;
        let totalDuration = 0;
        if(stages != null && stages !== '' && stages.length > 0) {
            stages.forEach(stage => {
                stage.totalCost = 0;
                stage.perPlantEffort = 0;
                stage.activityCount = 0;
                if(stage.activities != null && stage.activities !== '' && stage.activities.length > 0) {
                    stage.activities.forEach(activity => {
                        activity.totalCost = 0;
                        if(activity.inputs != null && activity.inputs !== '' && activity.inputs.length > 0) {
                            activity.inputs.forEach(input => {
                                input.totalCost = input.itemSel != null ? input.itemSel.cost * Number(input.quantity) : 0;
                                activity.totalCost += input.totalCost;
                            });
                        }
                        stage.totalCost += activity.totalCost;
                        stage.perPlantEffort += (activity.actSel != null ? activity.actSel.perPlantEffort : 0);
                    });
                    stage.activityCount = stage.activities.length;
                };
                totalMaterialCost += stage.totalCost;
                totalPerPlantEffort += stage.perPlantEffort;
                totalActivities += stage.activityCount;
                totalDuration += Number(stage.duration);
            });
        }
        this.setState({ stages, totalMaterialCost, totalPerPlantEffort, totalActivities, totalDuration });
    }

    changeValue = e => {
        const name = e.target.name;
        let value = e.target.value;
        if(name === 'code')
            value = value.toUpperCase();
        this.setState({
            [name]: value
        });
    };

    changeNumber = e => {
        const name = e.target.name;
        const value = e.target.value;
        if(isNaN(value)){
            return;
        }
        this.setState({
            [name]: value
        });
    };

    changeAuto = (name, data) => async (e, newValue) => {
        let inputUOM = this.state.inputUOM;
        let inputUOMSel = this.state.inputUOMSel;
        let outputUOM = this.state.outputUOM;
        let outputUOMSel = this.state.outputUOMSel;
        let stageOptions = this.state.stageOptions;
        let plantTypes = this.state.plantTypes;
        let activityCodes = this.state.activityCodes;
        if(data === 'inputItem' && newValue != null){
            let item = await axios.get('/api/item/' + newValue.value)
            inputUOM = item.data.uom;
            inputUOMSel = this.state.uoms.find(x => x.value === item.data.uom);
        }
        if(data === 'outputItem' && newValue != null){
            let item = await axios.get('/api/item/' + newValue.value)
            outputUOM = item.data.uom;
            outputUOMSel = this.state.uoms.find(x => x.value === item.data.uom);
        }
        
        if(data === 'batchType'){
            if(newValue != null){
                if(newValue.value === this.props.auth.constants.batchTypes.plantlet){
                    stageOptions = this.state.allStageOptions.filter(x => x.value === this.props.auth.constants.batchStages.plantlet || 
                                                                        x.value === this.props.auth.constants.batchStages.rooting || 
                                                                        x.value === this.props.auth.constants.batchStages.weaning);
                    plantTypes = this.state.allPlantTypes.filter(x => x.value === this.props.auth.constants.plantTypes.plantlet || 
                                                                    x.value === this.props.auth.constants.plantTypes.rootlet ||
                                                                    x.value === this.props.auth.constants.plantTypes.weanlet);
                    activityCodes = this.state.tissueActivities;
                } else {
                    stageOptions = this.state.allStageOptions.filter(x => x.value !== this.props.auth.constants.batchStages.plantlet &&
                                                                        x.value !== this.props.auth.constants.batchStages.rooting &&
                                                                        x.value !== this.props.auth.constants.batchStages.weaning);
                    plantTypes = this.state.allPlantTypes.filter(x => x.value !== this.props.auth.constants.plantTypes.plantlet && 
                                                                    x.value !== this.props.auth.constants.plantTypes.rootlet &&
                                                                    x.value !== this.props.auth.constants.plantTypes.weanlet);
                    activityCodes = this.state.regularActivities;
                }
            } else {
                stageOptions = this.state.allStageOptions;
            }
        }
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            inputUOM: inputUOM,
            inputUOMSel: inputUOMSel,
            outputUOM: outputUOM,
            outputUOMSel: outputUOMSel,
            stageOptions: stageOptions,
            plantTypes: plantTypes,
            activityCodes: activityCodes
        });
    };

    changeMulti = (name, data) => (e, newValue) => {
        var val = newValue;
        var ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    changeMultiActivity = (name, data, stageInd, actInd) => (e, newValue) => {
        var val = newValue;
        var ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        let stages = this.state.stages;
        let stage = stages[stageInd];
        let act = stage.activities[actInd];
        act.sopsSel = newValue;
        act.sops = ids;
        this.setState({
            stages: stages
        })
    };


    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]})
    };

    changeTab = (event, newValue) => {
        this.setState({ allDrawersOpen: true, tabKey: newValue });
    };

    switchActivate = () => {
        let status = this.state.status;
        if(status === this.props.auth.constants.batchTemplateStatuses.active) {
            status = this.props.auth.constants.batchTemplateStatuses.inactive;
        }else {
            status = this.props.auth.constants.batchTemplateStatuses.active;
        }
        this.setState({ status }, () => this.save());
    }

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        const data = {
            _id: this.state._id,
            code: this.state.code,
            description: this.state.description,
            status: this.state.status,
            stages: this.state.stages,
            batchStartType: this.state.batchStartType,
            batchType: this.state.batchType,
            feedingLines: this.state.feedingLines
        };
        try {
            const result = await axios.post('/api/batchtemplate', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/batchtemplate/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.loadRecord()
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        if(this.state.code == null || this.state.code === '')
            errors[t('code')] = t('required');
        if(this.state.description == null || this.state.description === '')
            errors[t('description')] = t('required');
        if(this.state.status == null || this.state.status === '')
            errors[t('status')] = t('required');
        if(this.state.batchStartType == null || this.state.batchStartType === '')
            errors[t('batchStartType')] = t('required');
        if(this.state.batchType == null || this.state.batchType === '')
            errors[t('batchType')] = t('required');
        if(this.state.stages != null && this.state.stages.length > 0){
            let plantTypesMissing = false;
            for(let i = 0; i < this.state.stages.length; i++){
                let row = this.state.stages[i];
                if((row.plantType == null || row.plantType === '') && row.stage !== this.props.auth.constants.batchStages.harvest){
                    errors[`${t('plantType')} (Line ${i})`] = t('required');
                    plantTypesMissing = true;
                }
            }
            if(plantTypesMissing){
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('plantTypesMissing'), title: t('error'), severity: 'error'}});
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    updateTabs = (action, i) => {
        const t = this.props.t;
        let tabs = this.state.tabs;
        let tabKey = this.state.tabKey;
        switch(action) {
            case 'add':
                if(this.state.stages != null && this.state.stages !== '') {
                    let stage = this.state.stages[i];
                    tabs.push({ name: stage.stageSel != null ? stage.stageSel.label : t('none'), disabled: false });
                    tabKey = i + 1;
                }
                break;
            case 'remove':
                tabs.splice((i+1),1);
                tabKey = i
                break;
            case 'move':
                const org = i.org + 1;
                const dest = i.dest + 1;
                let tab = tabs[org];
                tabs.splice(org,1);
                tabs.splice(dest, 0, tab);
                tabKey = dest;
                break;
            default:
                return;
        }
        this.setState({ tabKey, tabs });
    }

    addStage = (e) => {
        this.setState({showStage: true});
    };

    clearStage = (e) => {
        this.setState({
            showStage: false,
            stage: '',
            stageSel: null,
            duration: '',
            plantType: '',
            plantTypeSel: null,
            stageIndex: -1
        })
    };

    editStage = (index) => (e) => {
        const stage = this.state.stages[index];
        this.setState({
            stageSel: stage.stageSel,
            stage: stage.stage,
            duration: stage.duration,
            plantType: stage.plantType,
            plantTypeSel: stage.plantTypeSel,
            stageIndex: index,
            showStage: true
        })
    }

    moveStage = (org, dest) => {
        let stages = this.state.stages;
        let stage = stages[org];
        stages.splice(org, 1);
        stages.splice(dest, 0, stage);
        this.setState({stages});
        this.updateTabs('move', {org: org, dest: dest});
    }

    saveStage = (e) => {
        const valid = this.validateStage();
        if(!valid)
            return;
        let stages = this.state.stages != null ? this.state.stages : [];
        const exists = stages.some(x => x.stage === this.state.stage);
        if(this.state.stageIndex > -1){
            let stageRec = stages[this.state.stageIndex];
            stageRec.stage = this.state.stage;
            stageRec.stageSel = this.state.stageSel;
            stageRec.duration = this.state.duration;
            stageRec.plantType = this.state.plantType;
            stageRec.plantTypeSel = this.state.plantTypeSel;
        } else {
            stages.push({
                stage: this.state.stage,
                stageSel: this.state.stageSel,
                duration: this.state.duration,
                plantType: this.state.plantType,
                plantTypeSel: this.state.plantTypeSel
            });
        }
        const index = stages.findIndex(stage => stage.stage === this.state.stage);
        this.setState({ stages }, () => {
            this.updateTotals();
            if(!exists)
                this.updateTabs('add', index);
        });
        this.clearStage();
    };

    validateStage(){
        const t = this.props.t;
        let errors = {};
        const stages = this.state.stages;
        if(this.state.stage == null || this.state.stage === '')
            errors[t('stage')] = t('required');
        else {
            if(this.state.stage !== this.props.auth.constants.batchStages.harvest){
                if(this.state.plantType == null || this.state.plantType === '')
                    errors[t('plantType')] = t('required');
            }
        }
        if(this.state.duration == null || this.state.duration === '' || this.state.duration === 0)
            errors[t('duration')] = t('required');
        if(stages != null && stages.length > 0) {
            const index = stages.findIndex(stage => stage.stage === this.state.stage);
            if(index > -1 && this.state.stageIndex !== index)
                errors[t('stage')] = t('stageExists');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteStage = (e) => {
        //const t = this.props.t;
        if(this.state.stageIndex < 0)
            return;
        let stages = this.state.stages;
        let stage = stages[this.state.stageIndex];
        let totalDuration = this.state.totalDuration;
        let totalActivities = this.state.totalActivities;
        if(stage.activities != null && stage.activities.length > 0){
            totalActivities -= stage.activities.length;
        }
        // else {
        //     this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('stageDeleteError'), title: t('error'), severity: 'error'}});
        // }
        totalDuration -= stage.duration;
        stages.splice(this.state.stageIndex, 1);
        this.setState({stages, totalDuration, totalActivities});
        this.clearStage();
        this.updateTabs('remove', this.state.stageIndex);
        this.updateTotals();
    };

    toggleAccordion = (stageIndex, activityIndex) => {
        let allDrawersOpen = this.state.allDrawersOpen;
        let stages = this.state.stages;
        let stage = stages[stageIndex];
        let anyOpenDrawers = false;
        if(stage.activities != null && stage.activities !== '') {
            stage.activities.forEach((activity, i) => {
                if(activityIndex === i)
                    activity.accordion = !activity.accordion;
                if(activity.accordion && !anyOpenDrawers)
                    anyOpenDrawers = true;
            });
        }
        if(!anyOpenDrawers)
            allDrawersOpen = true;
        else
            allDrawersOpen = false;

        this.setState({ allDrawersOpen, stages });
    }

    toggleAllAccordions = (index) => {
        let allDrawersOpen = this.state.allDrawersOpen;
        let stages = this.state.stages;
        let stage = stages[index];
        if(stage.activities != null && stage.activities !== '' && stage.activities.length > 0) {
            stage.activities.forEach(act => {
                if(allDrawersOpen) {
                    act.accordion = true;
                } else {
                    act.accordion = false;
                }
            });
            allDrawersOpen = !this.state.allDrawersOpen;
        }
        this.setState({ allDrawersOpen, stages });
    }

    addActivity(stageIndex){
        this.setState({
            showActivity: true,
            stageIndex: stageIndex
        })
    }

    clearActivity = (e) => {
        this.setState({
            showActivity: false,
            sequence: '',
            activity: '',
            actSel: null,
            dateShift: '',
            sops: [],
            sopsSel: [],
            activityIndex: -1,
            stageIndex: -1
        });
    };

    editActivity(stageIndex, activityIndex){
        const stage = this.state.stages[stageIndex];
        const activity = stage.activities[activityIndex];
        this.setState({
            stageIndex: stageIndex,
            activityIndex: activityIndex,
            sequence: activity.sequence,
            actSel: activity.actSel,
            activity: activity.activity,
            dateShift: activity.dateShift,
            showActivity: true,
            sops: activity.sops,
            sopsSel: activity.sopsSel
        });
    }

    saveActivity = (e) => {
        const valid = this.validateActivity();
        if(!valid)
            return;
        const newSequence = parseInt(this.state.sequence);
        const stages = this.state.stages;
        let stage = stages[this.state.stageIndex];
        if(stage.activities == null)
            stage.activities = [];
        let activities = stage.activities;
        if(this.state.activityIndex === -1){
            let insert = false;
            activities.forEach(row => {
                if(row.sequence === newSequence){
                    insert = true;
                };
                if(insert){
                    row.sequence = row.sequence + 1;
                }
            });
        } else {
            let renum = false
            for(let i = 0; i < activities.length; i++){
                let row = activities[i];
                if(row.sequence === newSequence && i !== this.state.activityIndex){
                    renum = true;
                }
                if(renum){
                    row.sequence = row.sequence + 1;
                }
            }
        }
        if(this.state.activityIndex > -1){
            let act = activities[this.state.activityIndex];
            act.sequence = newSequence;
            act.actSel = this.state.actSel;
            act.activity = this.state.activity;
            act.dateShift = this.state.dateShift;
            act.sops = this.state.sops;
            act.sopsSel = this.state.sopsSel;
            act.accordion = true;
        } else {
            activities.push({
                sequence: newSequence,
                activity: this.state.activity,
                actSel: this.state.actSel,
                dateShift: this.state.dateShift,
                sops: this.state.sops,
                sopsSel: this.state.sopsSel,
                accordion: true
            });
        }
        activities.sort(function(a, b) {return a.sequence - b.sequence});
        stage.activities = activities;
        this.setState({stages: stages}, () => {
            this.updateTotals();
        });
        this.clearActivity();

    }

    validateActivity(){
        const t = this.props.t;
        let errors = {};
        if(this.state.sequence == null || this.state.sequence === '')
            errors[t('sequence')] = t('required');
        if(this.state.activity == null || this.state.activity === '')
            errors[t('activity')] = t('required');
        if(this.state.dateShift == null || this.state.dateShift === '')
            errors[t('dateShift')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteActivity = (stageIndex, activityIndex) => {
        let stages = this.state.stages;
        let stage = stages[stageIndex];
        stage.activities.splice(activityIndex, 1);
        for(let i = 0; i < stage.activities.length; i++){
            let act = stage.activities[i];
            act.sequence = i + 1;
        }
        this.setState({stages: stages});
        this.updateTotals();
    };

    addInput(stageIndex, activityIndex){
        this.setState({
            showInput: true,
            stageIndex: stageIndex,
            activityIndex: activityIndex,
            inputIndex: -1
        });
    }

    clearInput = (e) => {
        this.setState({
            showInput: false,
            stageIndex: -1,
            activityIndex: -1,
            inputItem: '',
            inputSel: null,
            inputQuantity: '',
            inputUOM: '',
            inputUOMSel: null,
            inputIndex: -1,
            inputRequired: false
        })
    };

    editInput(stageIndex, activityIndex, inputIndex){
        const stage = this.state.stages[stageIndex];
        const activity = stage.activities[activityIndex];
        const input = activity.inputs[inputIndex];

        this.setState({
            showInput: true,
            stageIndex: stageIndex,
            activityIndex: activityIndex,
            inputIndex: inputIndex,
            inputItem: input.item,
            inputSel: input.itemSel,
            inputQuantity: input.quantity,
            inputUOM: input.uom,
            inputUOMSel: input.uomSel,
            inputRequired: input.required
        })
    }

    saveInput = (e) => {
        const valid = this.validateInput();
        if(!valid)
            return;
        const stages = this.state.stages;
        let activity = stages[this.state.stageIndex].activities[this.state.activityIndex];
        activity.showItems = true;
        let input = {};
        if(activity.inputs == null)
            activity.inputs = [];
        if(this.state.inputIndex > -1){
            input = activity.inputs[this.state.inputIndex];
            input.item = this.state.inputItem;
            input.itemSel = this.state.inputSel;
            input.quantity = this.state.inputQuantity;
            input.uom = this.state.inputUOM;
            input.uomSel = this.state.inputUOMSel;
            input.required = this.state.inputRequired;
            input.cost = this.state.inputSel != null && this.state.inputSel !== '' ? this.state.inputSel.cost : 0;
        } else {
            input.item = this.state.inputItem;
            input.itemSel = this.state.inputSel;
            input.quantity = this.state.inputQuantity;
            input.uom = this.state.inputUOM;
            input.uomSel = this.state.inputUOMSel;
            input.required = this.state.inputRequired;
            input.cost = this.state.inputSel != null && this.state.inputSel !== '' ? this.state.inputSel.cost : 0;
            activity.inputs.push(input);
        }
        this.setState({stages: stages}, () => {
            this.updateTotals();
        });
        this.clearInput();
    };

    validateInput(){
        const t = this.props.t;
        let errors = {};
        if(this.state.inputItem == null || this.state.inputItem === '')
            errors[t('inputItem')] = t('required');
        if(this.state.inputQuantity == null || this.state.inputQuantity === '')
            errors[t('inputQuantity')] = t('required');
        if(this.state.inputUOM == null || this.state.inputUOM === '')
            errors[t('inputUOM')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteInput = (e) => {
        if(this.state.stageIndex < 0 || this.state.activityIndex < 0 || this.state.inputIndex < 0)
            return;
        let stages = this.state.stages;
        let stage = stages[this.state.stageIndex];
        let activity = stage.activities[this.state.activityIndex];
        activity.inputs.splice(this.state.inputIndex, 1);
        this.setState({stages: stages}, () => {
            this.updateTotals();
        });
        this.clearInput();
    };

    addOutput(stageIndex, activityIndex){
        this.setState({
            showOutput: true,
            stageIndex: stageIndex,
            activityIndex: activityIndex,
            outputIndex: -1
        });
    }

    clearOutput = (e) => {
        this.setState({
            showOutput: false,
            stageIndex: -1,
            activityIndex: -1,
            outputItem: '',
            outputSel: null,
            outputQuantity: '',
            outputUOM: '',
            outputUOMSel: null,
            outputIndex: -1,
            outputRequired: false
        })
    };

    editOutput(stageIndex, activityIndex, outputIndex){
        const stage = this.state.stages[stageIndex];
        const activity = stage.activities[activityIndex];
        const input = activity.outputs[outputIndex];
        this.setState({
            showOutput: true,
            stageIndex: stageIndex,
            activityIndex: activityIndex,
            outputIndex: outputIndex,
            outputItem: input.item,
            outputSel: input.itemSel,
            outputQuantity: input.quantity,
            outputUOM: input.uom,
            outputUOMSel: input.uomSel,
            outputRequired: input.required
        })
    }

    saveOutput = (e) => {
        const valid = this.validateOutput();
        if(!valid)
            return;
        let stages = this.state.stages;
        let activity = stages[this.state.stageIndex].activities[this.state.activityIndex];
        if(activity.outputs == null)
            activity.outputs = [];
            activity.showItems = true;
        if(this.state.outputIndex > -1){
            let output = activity.outputs[this.state.outputIndex];
            output.item = this.state.outputItem;
            output.itemSel = this.state.outputSel;
            output.quantity = this.state.outputQuantity;
            output.uom = this.state.outputUOM;
            output.uomSel = this.state.outputUOMSel;
            output.required = this.state.outputRequired;
        } else {
            let output = {};
            output.item = this.state.outputItem;
            output.itemSel = this.state.outputSel;
            output.quantity = this.state.outputQuantity;
            output.uom = this.state.outputUOM;
            output.uomSel = this.state.outputUOMSel;
            output.required = this.state.outputRequired;
            activity.outputs.push(output);
        }
        this.setState({stages: stages}, () => {
            this.updateTotals();
        });
        this.clearOutput();
    };

    deleteOutput = (e) => {
        if(this.state.stageIndex < 0 || this.state.activityIndex < 0 || this.state.outputIndex < 0)
            return;
        let stages = this.state.stages;
        let stage = stages[this.state.stageIndex];
        let activity = stage.activities[this.state.activityIndex];
        activity.outputs.splice(this.state.outputIndex, 1);
        this.setState({stages: stages}, () => {
            this.updateTotals();
        });
        this.clearOutput();
    };

    validateOutput(){
        const t = this.props.t;
        let errors = {};
        if(this.state.outputItem == null || this.state.outputItem === '')
            errors[t('outputItem')] = t('required');
        if(this.state.outputQuantity == null || this.state.outputQuantity === '')
            errors[t('outputQuantity')] = t('required');
        if(this.state.outputUOM == null || this.state.outputUOM === '')
            errors[t('outputUOM')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    copy = (e) => {
        this.setState({showCopy: true});
    };

    performCopy = async (e) => {
        const t = this.props.t;
        const valid = this.validateCopy();
        if(!valid)
            return;
        let data = {
            newCode: this.state.newCode,
            fromId: this.state._id
        };
        try {
            const result = await axios.post('/api/batchtemplatecopy', data);
            this.closeCopy();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    validateCopy(){
        const t = this.props.t;
        let errors = {};
        if(this.state.newCode == null || this.state.newCode === '')
            errors[t('newCode')] = this.props.t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    closeCopy = (e) => {
        this.setState({
            showCopy: false,
            newCode: ''
        });
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    delete = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._delete(e));
    };

    _delete = async (e) => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/batchtemplate/' + this.state._id);
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.props.history.goBack();
        }
    }

    buttonStack = (stack) => {
        const t = this.props.t;
        const locked = this.state.locked;
        const fieldAccess = this.state.fieldAccess;
        let buttons = '';
        switch(stack) {
            case 'stage':
                buttons = (
                    <Aux>
                        {!this.state.locked && this.state.fieldAccess &&
                            <Button size="small" variant='text' sx={{ color: 'error.main' }} onClick={this.deleteStage}>{t('delete')}</Button>
                        }
                        <Button variant='text' color="secondary" size="small" onClick={this.clearStage}>{t('close')}</Button>
                        {!this.state.locked && this.state.fieldAccess &&
                            <Button variant='contained' color="primary" size="small" onClick={this.saveStage}>{t('save')}</Button>
                        }
                    </Aux>
                );
                break;
            case 'activity':
                buttons = (
                    <Aux>
                        <Button variant='text' color="secondary" size="small" onClick={this.clearActivity}>{t('close')}</Button>
                        {!locked && fieldAccess &&
                            <Button variant='contained' color="primary" size="small" onClick={this.saveActivity}>{t('save')}</Button>
                        }
                    </Aux>
                );
                break;
            case 'inputs':
                buttons = (
                    <Aux>
                        {!locked && fieldAccess && this.state.inputIndex > -1 &&
                            <Button size="small" variant='text' sx={{ color: 'error.main' }} onClick={this.deleteInput}>{t('delete')}</Button>
                        }
                        <Button variant='text' color="secondary" size="small" onClick={this.clearInput}>{t('close')}</Button>
                        {!locked && fieldAccess &&
                            <Button variant='contained' color="primary" size="small" onClick={this.saveInput}>{t('save')}</Button>
                        }
                    </Aux>
                );
                break;
            case 'outputs':
                buttons = (
                    <Aux>
                        {!locked && fieldAccess && this.state.outputIndex > -1 &&
                            <Button variant='text' size="small" sx={{ color: 'error.main' }} onClick={this.deleteOutput}>{t('delete')}</Button>
                        }
                        <Button variant='text' color="secondary" size="small" onClick={this.clearOutput}>{t('close')}</Button>
                        {!locked && fieldAccess &&
                            <Button variant='contained' color="primary" size="small" onClick={this.saveOutput}>{t('save')}</Button>
                        }
                    </Aux>
                );
                break;
            case 'copy':
                buttons = (
                    <Aux>
                        <Button variant='text' color="secondary" size="small" onClick={this.closeCopy}>{t('close')}</Button>
                        {fieldAccess &&
                            <Button variant='text' color="primary" size="small" onClick={this.performCopy}>{t('copy')}</Button>
                        }
                    </Aux>
                );
                break;
            default:
                break;
        }
        return buttons;
    }

    StageTab = (row, i) => {
        const fieldAccess = this.state.fieldAccess;
        const locked = this.state.locked;
        const errors = this.props.errors;
        const { t } = this.props;
        // const iconStyle = {
        //     margin: 0,
        //     color: 'primary.main',
        //     minWidth: 0
        // };
        return (
            <Grid container item spacing={3}>
                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <Typography display='inline' variant="h4" sx={{color: '#00AC89', overflow: 'auto'}}>
                            {row.stageSel != null && row.stageSel !== '' && row.stageSel.label}
                        </Typography>
                        <Box sx={{float: 'right',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center'}}>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                {!locked && fieldAccess &&
                                    <Tooltip title={t('addActivity')}>
                                        <Button
                                            component={'div'}
                                            onClick={() => this.addActivity(i)}
                                            name={'stageActionAdd-' + i}
                                        >
                                            <AddIcon fontSize='small'/>
                                        </Button>
                                    </Tooltip>
                                }
                                {row.activities != null && row.activities !== '' && row.activities.length > 0 &&
                                    <Tooltip title={this.state.allDrawersOpen ? t('openAllDrawers') : t('closeAllDrawers')}>
                                        <Button
                                            component={'div'}
                                            onClick={() => this.toggleAllAccordions(i)}
                                            name={'stageActionExpandAll-' + i}
                                        >
                                            <ExpandIcon fontSize='small' color={this.state.allDrawersOpen ? 'secondary' : 'primary'}/>
                                        </Button>
                                    </Tooltip>
                                }
                            </ButtonGroup>
                        </Box>
                    </Grid>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow key={'stageHeadRow-' + i}>
                                    <TableCell sx={{ width: "5%"}} />
                                    <TableCell sx={{ width: "5%", display: { xs: 'none', sm: 'table-cell'}}}>{t('order')}</TableCell>
                                    <TableCell>{t('activity')}</TableCell>
                                    <TableCell sx={{ textAlign: 'right', display: { xs: 'none', sm: 'table-cell'}}}>{t('daysFromStart')}</TableCell>
                                    <TableCell sx={{ width: "5%", display: { xs: 'none', md: 'table-cell'}}}>{t('workRecs')}</TableCell>
                                    <TableCell sx={{ width: "5%", display: { xs: 'none', md: 'table-cell'}}}>{t('inputs')}</TableCell>
                                    <TableCell sx={{ width: "5%", display: { xs: 'none', md: 'table-cell'}}}>{t('outputs')}</TableCell>
                                    <TableCell sx={{ width: "10%", textAlign: 'right', display: { xs: 'none', sm: 'table-cell'}}}>{t('directMaterialCost')}</TableCell>
                                    <TableCell sx={{ width: "10%", textAlign: 'right', display: { xs: 'none', sm: 'table-cell'}}}>{t('directLabourM')}</TableCell>
                                    {!locked && fieldAccess &&
                                        <TableCell width="5%"></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(row.activities != null && row.activities.length > 0) ?
                                    row.activities.map((act, j) => {
                                        return (
                                            <Aux key={'activityAuxRow-' + i + '-' + j + 'a'}>
                                                <TableRow key={'activityRow-' + i + '-' + j + 'a'}>
                                                    <TableCell sx={{ width: "5%"}}>
                                                        <IconButton aria-label="expand row" size="small" onClick={() => this.toggleAccordion(i, j)}>
                                                            {!act.accordion ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell sx={{ width: "5%", display: { xs: 'none', sm: 'table-cell'}}}>{act.sequence}</TableCell>
                                                    <TableCell>{act.actSel != null ? act.actSel.label : ''}</TableCell>
                                                    <TableCell sx={{ textAlign: 'right', display: { xs: 'none', sm: 'table-cell'}}}>{act.dateShift}</TableCell>
                                                    {this.state.sopList != null && this.state.sopList.length > 0 &&
                                                        <TableCell sx={{ width: "5%", textAlign: 'center', display: { xs: 'none', md: 'table-cell'}}}>{act.sops != null && act.sops !== '' && act.sops.length > 0 ? <CheckRoundedIcon color="primary" /> : ''}</TableCell>
                                                    }
                                                    <TableCell sx={{ width: "5%", textAlign: 'center', display: { xs: 'none', md: 'table-cell'}}}>{act.inputs != null && act.inputs !== '' && act.inputs.length > 0 ? <CheckRoundedIcon color="primary" /> : ''}</TableCell>
                                                    <TableCell sx={{ width: "5%", textAlign: 'center', display: { xs: 'none', md: 'table-cell'}}}>{act.outputs != null && act.outputs !== '' && act.outputs.length > 0 ? <CheckRoundedIcon color="primary" /> : ''}</TableCell>
                                                    <TableCell sx={{ width: "10%", textAlign: 'right', display: { xs: 'none', sm: 'table-cell'}}}>${act.totalCost != null && act.totalCost !== '' ? act.totalCost.toFixed(2) : '0.00'}</TableCell>
                                                    <TableCell sx={{ width: "10%", textAlign: 'right', display: { xs: 'none', sm: 'table-cell'}}}>{act.actSel != null && act.actSel !== '' ? act.actSel.perPlantEffort.toFixed(1) : '0'}</TableCell>
                                                    {!locked && fieldAccess &&
                                                        <TableCell>
                                                            <Tooltip title={t('edit')} placement='right-start'>
                                                                <IconButton aria-label="edit row" size="small" onClick={() => this.editActivity(i, j)}>
                                                                    <EditActivityIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={t('delete')} placement='right-start'>
                                                                <IconButton aria-label="delete row" size="small" onClick={() => this.deleteActivity(i, j)}>
                                                                    <DeleteActivityIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                                <TableRow key={'activityRow-' + i + '-' + j + 'b'}>
                                                    <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={!locked && fieldAccess ? 10 : 9}>
                                                        <Collapse in={act.accordion} timeout="auto" unmountOnExit defaultExpanded expanded={act.accordion}>
                                                            <Box margin={1}>
                                                            <Typography variant="h5" gutterBottom component="div" sx={{color: '#00AC89'}}>
                                                                {t('activityDetails')}
                                                            </Typography>
                                                            <Divider />
                                                            <br />
                                                            <Grid container item xs={12} spacing={3}>
                                                                <Grid item xs={4} sm={2}>
                                                                    <FormControl>
                                                                        <FormLabel>{t('order')}</FormLabel>
                                                                        {act.sequence != null && act.sequence !== '' ? act.sequence : ''}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={8} sm={10} md={3}>
                                                                    <FormControl>
                                                                        <FormLabel>{t('activity')}</FormLabel>
                                                                        {act.actSel != null ? act.actSel.label : ''}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={6} sm={3} md={2}>
                                                                    <FormControl>
                                                                        <FormLabel>{t('daysFromStart')}</FormLabel>
                                                                        {act.dateShift != null && act.dateShift !== '' ? act.dateShift : ''}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={6} sm={3} md={2}>
                                                                    <FormControl>
                                                                        <FormLabel>{t('directMaterialCost')}</FormLabel>
                                                                        ${act.totalCost != null && act.totalCost !== '' ? act.totalCost.toFixed(2) : '0.00'}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={6} sm={3} md={2}>
                                                                    <FormControl>
                                                                        <FormLabel>{t('directLabourM')}</FormLabel>
                                                                        {act.actSel != null && act.actSel !== '' ? act.actSel.perPlantEffort.toFixed(1) : "0"}
                                                                    </FormControl>
                                                                </Grid>
                                                                {this.state.sopList != null && this.state.sopList.length > 0 &&
                                                                    <Grid item xs={12}>
                                                                        <AutoCompleteField
                                                                            multiple={true} disabled={locked || !fieldAccess}
                                                                            value={act.sopsSel}
                                                                            options={this.state.sopList}
                                                                            onChange={this.changeMultiActivity('sopsSel', 'sops', i, j)}
                                                                            error={errors[t('sops')] != null ? true : false}
                                                                            helperText={errors[t('sops')]}
                                                                            label={t('workRecs')}
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                            <Grid container item xs={12} spacing={3}>
                                                                <Grid item xs={12} xl={6}>
                                                                    <Grid container item xs={12} sx={{ justifyContent: 'flex-start' }}>
                                                                        <Grid item>
                                                                        <Typography variant="h6" sx={{color: '#00AC89', marginTop: '7px'}}>{t('inputs')}</Typography>
                                                                        </Grid>
                                                                        {!locked && fieldAccess &&
                                                                            <Grid item>
                                                                                <Tooltip title={t('addInput')} placement='right'>
                                                                                    <IconButton aria-label='addInput' onClick={() => this.addInput(i, j)}>
                                                                                        <AddIcon color="secondary" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                    <TableContainer component={Paper}>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow key={'inputsHeader-' + i + '-' + j}>
                                                                                    <TableCell width="5%"><strong>{t('required')}</strong></TableCell>
                                                                                    <TableCell><strong>{t('item')}</strong></TableCell>
                                                                                    <TableCell align="right" width="10%"><strong>{t('quantity')}</strong></TableCell>
                                                                                    <TableCell align="right" width="10%"><strong>{t('unitCost')}</strong></TableCell>
                                                                                    <TableCell align="right" width="10%"><strong>{t('totalCostDollar')}</strong></TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {act.inputs != null && act.inputs.length > 0 ? act.inputs.map((input, k) => {
                                                                                    return (
                                                                                        <TableRow key={'inputs-' + i + '-' + j + '-' + k}>
                                                                                            <TableCell align="center" width="5%">
                                                                                                {input.required ? <CheckRoundedIcon color="secondary"/> : null}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {!locked && input.itemSel != null ?
                                                                                                    <Link onClick={() => this.editInput(i, j, k)} color="primary">{input.itemSel.label}</Link> :
                                                                                                    input.itemSel != null ? input.itemSel.label : ''
                                                                                                }
                                                                                            </TableCell>
                                                                                            <TableCell align="right" width="10%">{input.quantity + input.uomSel.label}</TableCell>
                                                                                            <TableCell align="right" width="10%">${input.cost != null && input.cost !== '' ? Number(input.cost).toFixed(2) : "0.00"}</TableCell>
                                                                                            <TableCell align="right" width="10%">${input.totalCost != null && input.totalCost !== '' ? Number(input.totalCost).toFixed(2) : "0.00"}</TableCell>
                                                                                        </TableRow>
                                                                                    )}) :
                                                                                    <TableRow key={'inputs-' + i + '-' + j + '-1'}>
                                                                                        <TableCell colSpan={5} align="center">
                                                                                            {!locked ?
                                                                                                <Aux>
                                                                                                    <Link onClick={() => this.addInput(i, j)} color="primary">
                                                                                                        <strong>{t('clickHereMessage.1')}</strong>
                                                                                                    </Link>
                                                                                                    {t('clickHereMessage.2')}
                                                                                                </Aux> :
                                                                                                <strong>{t('none')}</strong>
                                                                                            }
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                }
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </Grid>
                                                                <Grid item xs={12} xl={6}>
                                                                    <Grid container item xs={12} sx={{ justifyContent: 'flex-start' }}>
                                                                        <Grid item>
                                                                            <Typography variant="h6" sx={{color: '#00AC89', marginTop: '7px'}}>{t('outputs')}</Typography>
                                                                        </Grid>
                                                                        {!locked && fieldAccess &&
                                                                            <Grid item>
                                                                                <Tooltip title={t('addOutput')} placement='right'>
                                                                                    <IconButton aria-label='addOutput' onClick={() => this.addOutput(i, j)}>
                                                                                        <AddIcon color="secondary" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                    <TableContainer component={Paper}>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow key={'outputsHeader-' + i + '-' + j}>
                                                                                    <TableCell width="5%"><strong>{t('required')}</strong></TableCell>
                                                                                    <TableCell><strong>{t('item')}</strong></TableCell>
                                                                                    <TableCell align="right" width="10%"><strong>{t('quantity')}</strong></TableCell>
                                                                                    {/* <TableCell align="right"><strong>{t('unitCost')}</strong></TableCell>
                                                                                    <TableCell align="right"><strong>{t('totalCostDollar')}</strong></TableCell> */}
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {act.outputs != null && act.outputs.length > 0 ? act.outputs.map((output, k) => {
                                                                                    return (
                                                                                        <TableRow key={'outputs-' + i + '-' + j + '-' + k}>
                                                                                            <TableCell align="center" width='5%'>
                                                                                                {output.required ? <CheckRoundedIcon color="secondary"/> : null}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {!locked ?
                                                                                                    <Link onClick={() => this.editOutput(i, j, k)} color="primary">{output.itemSel.label}</Link> :
                                                                                                    output.itemSel.label
                                                                                                }
                                                                                            </TableCell>
                                                                                            <TableCell align="right">{output.quantity + output.uomSel.label}</TableCell>
                                                                                            {/* <TableCell align="right">${input.cost}</TableCell>
                                                                                            <TableCell align="right">${input.totalCost}</TableCell> */}
                                                                                        </TableRow>
                                                                                    )}) :
                                                                                    <TableRow key={'outputs-' + i + '-' + j + '-1'}>
                                                                                        <TableCell colSpan={3} align="center">
                                                                                            {!locked ?
                                                                                                <Aux>
                                                                                                    <Link onClick={() => this.addOutput(i, j)} color="primary">
                                                                                                        <strong>{t('clickHereMessage.1')}</strong>
                                                                                                    </Link>
                                                                                                    {t('clickHereMessage.2')}
                                                                                                </Aux> :
                                                                                                <strong>{t('none')}</strong>
                                                                                            }
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                }
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </Grid>
                                                            </Grid>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </Aux>
                                        )
                                    }) : <TableRow>
                                            <TableCell colSpan={9} align='center'>
                                                {!locked ?
                                                    <Aux>
                                                        <strong>
                                                            <Link onClick={() => this.addActivity(i)} color="primary">
                                                                {t('clickHereMessage.1')}
                                                            </Link>
                                                        </strong>
                                                        {t('clickHereMessage.2')}
                                                    </Aux> :
                                                    <strong>{t('none')}</strong>
                                                }
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        );
    };

    StageTabs = () => {
        const stages = this.state.stages;
        const tabKey = this.state.tabKey;
        let tabs = [];
        if (stages != null && stages.length > 0) {
            tabs = stages.map((row, i) =>
                <VerticalTabCanvas value={tabKey} index={i+1} key={'stageCanvas-' + i}>
                    <Grid item container xs={12} spacing={3}>
                        {this.StageTab(row, i)}
                    </Grid>
                </VerticalTabCanvas>
            )
        }
        return tabs;
    };

    render(){
        const errors = this.props.errors;
        const fieldAccess = this.props.permission.create || this.props.permission.update;
        const locked = this.state.locked;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Grid container spacing={1} sx={{ justifyContent: 'flex-start' }} >
                    <Grid container item xs={12} md={4} lg={3} direction='column'>
                        <Panel>
                            <Grid container item xs={12} sx={{ justifyContent: 'space-between' }} >
                                <Grid item xs={6}>
                                    <Typography display='inline' variant="h5" sx={{color: '#00AC89'}}>{t('details')}</Typography>
                                </Grid>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item>
                                        <Tooltip title={locked ? t('deactivate') : t('activate')} >
                                            <Switch checked={this.state.locked} onChange={this.switchActivate} name="switchActivate" disabled={!fieldAccess}/>
                                        </Tooltip>
                                    </Grid>
                                }
                            </Grid>
                            <Divider />
                            <Grid item xs={12}>
                                {!locked && fieldAccess &&
                                    <TextField value={this.state.code} onChange={this.changeValue} name="code"
                                    size="medium" fullWidth label={t('code')} disabled={locked} required data-cy="code"
                                    error={errors[t('code')] != null ? true : false} helperText={errors[t('code')]}/>
                                }
                                {(locked || !fieldAccess) &&
                                    <FormControl name="code">
                                        <FormLabel>{t('code')}</FormLabel>
                                        {this.state.code}
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {!locked && fieldAccess &&
                                    <TextField value={this.state.description} onChange={this.changeValue} name="description"
                                    size="medium" fullWidth label={t('description')} disabled={locked} required data-cy="description"
                                    error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                                }
                                {locked &&
                                    <FormControl data-cy="description">
                                        <FormLabel>{t('description')}</FormLabel>
                                        {this.state.description}
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {!locked && fieldAccess &&
                                    <AutoCompleteField
                                        value={this.state.batchStartTypeSel}
                                        options={this.state.batchStartTypes}
                                        onChange={this.changeAuto('batchStartTypeSel', 'batchStartType')}
                                        error={errors[t('batchStartType')] != null ? true : false}
                                        helperText={errors[t('batchStartType')]}
                                        label={t('startType')}
                                        disabled={locked}
                                        required
                                        fullWidth
                                        data-cy="startType"
                                    />
                                }
                                {locked &&
                                    <FormControl data-cy="startType">
                                        <FormLabel>{t('startType')}</FormLabel>
                                        {this.state.batchStartTypeSel != null ? this.state.batchStartTypeSel.label : ''}
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {!locked && fieldAccess &&
                                    <AutoCompleteField
                                        value={this.state.batchTypeSel}
                                        options={this.state.batchTypes}
                                        onChange={this.changeAuto('batchTypeSel', 'batchType')}
                                        error={errors[t('batchType')] != null ? true : false}
                                        helperText={errors[t('batchType')]}
                                        label={t('type')}
                                        disabled={locked}
                                        required
                                        fullWidth
                                        data-cy="batchType"
                                    />
                                }
                                {locked &&
                                    <FormControl data-cy="batchType">
                                        <FormLabel>{t('type')}</FormLabel>
                                        {this.state.batchTypeSel != null ? this.state.batchTypeSel.label : ''}
                                    </FormControl>
                                }
                            </Grid>
                        </Panel>
                    </Grid>
                    <Grid item container xs={12} md={9} direction='column'>
                        <Panel>
                            <SubSectionHeading title={t('stageSummary')}/><br />
                            <TableContainer>
                                <Table size="small" aria-label="template-stats">
                                    <TableHead>
                                        <TableRow key="stageTableHeader">
                                            {!locked && fieldAccess &&
                                                <TableCell width="7%"></TableCell>
                                            }
                                            <TableCell><strong>{t('stage')}</strong></TableCell>
                                            <TableCell align="right"><strong>{t('durationD')}</strong></TableCell>
                                            <TableCell align="right"><strong>{t('activities')}</strong></TableCell>
                                            <TableCell align="right"><strong>{t('directMaterialCost')}</strong></TableCell>
                                            <TableCell align="right"><strong>{t('directLabourM')}</strong></TableCell>
                                            <TableCell align="right"><strong>{t('plantType')}</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(this.state.stages != null && this.state.stages !== '') ?
                                            this.state.stages.map((stage, i) =>
                                            <Aux key={'stageSummaryAux-' + i}>
                                                <TableRow key={'statsRow-' + i}>
                                                    {!locked && fieldAccess &&
                                                        <TableCell width="7%" sx={{ padding: 0 }}>
                                                            <Grid container sx={{ justifyContent: 'center' }} spacing={1}>
                                                                <Grid item>
                                                                    <ActionButton security={i !== 0} label={t('moveUp')} onClick={() => this.moveStage(i, (i - 1))}/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <ActionButton security={i !== (this.state.stages.length - 1)} label={t('moveDown')} onClick={() => this.moveStage(i, (i + 1))}/>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    }
                                                    <TableCell sx={{ borderRight: '1px solid #ddd' }}>
                                                        {!locked && fieldAccess ?
                                                            <Link onClick={this.editStage(i)} color="primary" >{stage.stageSel.label}</Link>
                                                            : stage.stageSel.label
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right">{stage.duration != null && stage.duration !== '' ? stage.duration : 0}</TableCell>
                                                    <TableCell align="right">{stage.activityCount != null && stage.activityCount !== '' ? stage.activityCount : 0}</TableCell>
                                                    <TableCell align="right">${stage.totalCost != null && stage.totalCost !== '' ? Number(stage.totalCost).toFixed(2) : "0.00"}</TableCell>
                                                    <TableCell align="right">{stage.perPlantEffort != null && stage.perPlantEffort !== '' ? stage.perPlantEffort.toFixed(1) : "0"}</TableCell>
                                                    <TableCell align="right">{stage.plantTypeSel != null ? stage.plantTypeSel.label : ''}</TableCell>
                                                </TableRow>
                                                {this.state.stages.length === (i + 1) &&
                                                    <TableRow key={'statsRow-' + (i + 1).toString()}>
                                                        {!locked && fieldAccess && <TableCell width="7%" sx={{ borderTop: '2px solid #ddd' }}></TableCell>}
                                                        <TableCell align="right" sx={{ borderRight: '1px solid #ddd', borderTop: '2px solid #ddd'}}><strong>{t('total')}</strong></TableCell>
                                                        <TableCell sx={{ borderTop: '2px solid #ddd' }} align="right"><strong>{this.state.totalDuration}</strong></TableCell>
                                                        <TableCell sx={{ borderTop: '2px solid #ddd' }} align="right"><strong>{this.state.totalActivities}</strong></TableCell>
                                                        <TableCell sx={{ borderTop: '2px solid #ddd' }} align="right"><strong>${this.state.totalMaterialCost.toFixed(2)}</strong></TableCell>
                                                        <TableCell sx={{ borderTop: '2px solid #ddd' }} align="right"><strong>{this.state.totalPerPlantEffort.toFixed(1)}</strong></TableCell>
                                                        <TableCell sx={{ borderTop: '2px solid #ddd' }} />
                                                    </TableRow>
                                                }
                                            </Aux>
                                            ) : <TableRow key='statsRow'>
                                                    <TableCell colSpan={!locked && fieldAccess ? 7 : 6} align="center">
                                                        {!locked ?
                                                            <Aux>
                                                                <strong>
                                                                    <Link data-cy="newStage" onClick={() => this.addStage()} color="primary">
                                                                        {t('clickHereMessage.1')}
                                                                    </Link>
                                                                    {t('clickHereMessage.2')}
                                                                </strong>
                                                            </Aux> :
                                                            <strong>{t('none')}</strong>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Panel>
                    </Grid>
                </Grid>
                <EvoraVerticalTabs
                    TabContents={this.StageTabs}
                    tabLabels={this.state.tabs}
                    tabKey={this.state.tabKey}
                    changeTab={this.changeTab}
                />
                <br />
                <Divider sx={{ width: '80%', margin: '0 auto'}}/>
                <br />
                <FormButtonStack
                    id={this.state._id}
                    objectType={this.props.auth.constants.objectIds.batchTemplate}
                    callback={this.loadRecord}
                    saveClick={this.save}
                    status={this.state.status}
                    permissions={this.props.permission}
                    common={this.props.auth.common}
                    constants={this.props.auth.constants}
                    type={this.props.auth.common.statusTypes.batchTemplate}
                    saveEnabled={this.state.enableSave}
                    copyClick={this.copy}
                    deleteClick={this.deleteToggle}
                    enableDelete={!locked && this.props.permission.delete}
                />
                {this.state._id != null && this.state._id !== '' &&
                    <div>
                        <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.batchTemplate}/>
                        <ObjectLog id={this.state._id}/>
                    </div>
                }
                <ModalDialog buttonStack={this.buttonStack('stage')} title={t('stage')} toggleDialog={this.clearStage}
                             dialogStatus={this.state.showStage} fullWidth maxWidth='sm' >
                    <Grid container spacing={1} sx={{ justifyContent: 'space-between' }} >
                        <Grid item xs={8}>
                            <AutoCompleteField
                                value={this.state.stageSel}
                                options={this.state.stageOptions}
                                onChange={this.changeAuto('stageSel', 'stage')}
                                error={errors[t('stage')] != null ? true : false}
                                helperText={errors[t('stage')]}
                                label={t('stage')}
                                required
                                fullWidth
                                data-cy="stage"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.duration} onChange={this.changeNumber} name="duration"
                            size="medium" fullWidth={true} label={t('durationD')} required data-cy="duration"
                            error={errors[t('duration')] != null ? true : false} helperText={errors[t('duration')]}/>
                        </Grid>
                        <Grid item xs={8}>
                            <AutoCompleteField
                                value={this.state.plantTypeSel}
                                options={this.state.plantTypes}
                                onChange={this.changeAuto('plantTypeSel', 'plantType')}
                                error={errors[t('plantType')] != null ? true : false}
                                helperText={errors[t('plantType')]}
                                label={t('plantType')}
                                fullWidth
                                data-cy="plantType"
                            />
                        </Grid>
                    </Grid>
                </ModalDialog>
                <ModalDialog buttonStack={this.buttonStack('activity')} title={t('activity')} toggleDialog={this.clearActivity}
                             dialogStatus={this.state.showActivity} fullWidth maxWidth='md' >
                    <Grid container spacing={1}>
                        <Grid item xs={4} sm={2}>
                            <TextField type="number" value={this.state.sequence} onChange={this.changeNumber} name="sequence"
                            size="medium" fullWidth={true} label={t('order')} required
                            error={errors[t('sequence')] != null ? true : false} helperText={errors[t('sequence')]}/>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.actSel}
                                options={this.state.activityCodes}
                                onChange={this.changeAuto('actSel', 'activity')}
                                error={errors[t('activity')] != null ? true : false}
                                helperText={errors[t('activity')]}
                                label={t('activity')}
                                required
                                data-cy="activity"
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField type="number" value={this.state.dateShift} onChange={this.changeNumber} name="dateShift"
                            size="medium" fullWidth={true} label={t('daysFromStart')} required data-cy="dateShift"
                            error={errors[t('dateShift')] != null ? true : false} helperText={errors[t('dateShift')]}/>
                        </Grid>
                        {this.state.sopList != null && this.state.sopList.length > 0 &&
                            <Grid item xs={12} sm={6}>
                                <AutoCompleteField
                                    multiple={true} disabled={!fieldAccess}
                                    value={this.state.sopsSel}
                                    options={this.state.sopList}
                                    onChange={this.changeSop}
                                    error={errors[t('sopIds')] != null ? true : false}
                                    helperText={errors[t('sopIds')]}
                                    label={t('workRecs')}
                                    data-cy="sops"
                                />
                            </Grid>
                        }
                    </Grid>
                </ModalDialog>
                <ModalDialog buttonStack={this.buttonStack('inputs')} title={t('inputItem')} toggleDialog={this.clearInput}
                             dialogStatus={this.state.showInput} fullWidth maxWidth='md' >
                    <Grid container spacing={1}>
                        {this.state.stages != null && this.state.stages.length > 0 && this.state.stageIndex > -1 &&
                            <Grid item xs={8} sm={4}>
                                <AutoCompleteField
                                    value={this.state.inputSel}
                                    options={this.state.stages[this.state.stageIndex].items}
                                    onChange={this.changeAuto('inputSel', 'inputItem')}
                                    error={errors[t('inputItem')] != null ? true : false}
                                    helperText={errors[t('inputItem')]}
                                    label={t('item')}
                                    required
                                    data-cy="input"
                                />
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            <TextField type="number" value={this.state.inputQuantity} onChange={this.changeNumber} name="inputQuantity"
                            size="medium" fullWidth={true} label={t('quantityPerPlant')} required data-cy="inputQuantity"
                            error={errors[t('inputQuantity')] != null ? true : false} helperText={errors[t('inputQuantity')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <FormControl>
                                <FormLabel>{t('uom')}</FormLabel>
                                {this.state.inputUOMSel != null && this.state.inputUOMSel !== '' && this.state.inputUOMSel.label}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField
                            checked={this.state.inputRequired} onChange={this.changeBool('inputRequired')}
                            label={t('required')} name="inputRequired" data-cy="inputRequired"
                            />
                        </Grid>
                    </Grid>
                </ModalDialog>
                <ModalDialog buttonStack={this.buttonStack('outputs')} title={t('outputItem')} toggleDialog={this.clearOutput}
                             dialogStatus={this.state.showOutput} fullWidth maxWidth='md' >
                    <Grid container spacing={1}>
                        {this.state.stages != null && this.state.stages.length > 0 && this.state.stageIndex > -1 &&
                            <Grid item xs={8} sm={4}>
                                <AutoCompleteField
                                    value={this.state.outputSel}
                                    options={this.state.stages[this.state.stageIndex].items}
                                    onChange={this.changeAuto('outputSel', 'outputItem')}
                                    error={errors[t('outputItem')] != null ? true : false}
                                    helperText={errors[t('outputItem')]}
                                    label={t('item')}
                                    required
                                    data-cy="output"
                                />
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            <TextField type="number" value={this.state.outputQuantity} onChange={this.changeNumber} name="outputQuantity"
                            size="medium" fullWidth={true} label={t('quantityPerPlant')} required data-cy="outputQuantity"
                            error={errors[t('outputQuantity')] != null ? true : false} helperText={errors[t('outputQuantity')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <FormControl>
                                <FormLabel>{t('uom')}</FormLabel>
                                {this.state.outputUOMSel != null && this.state.outputUOMSel !== '' && this.state.outputUOMSel.label}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField
                            checked={this.state.outputRequired} onChange={this.changeBool('outputRequired')}
                            label={t('required')} name="outputRequired" data-cy="outputRequired"
                            />
                        </Grid>
                    </Grid>
                </ModalDialog>
                <ModalDialog buttonStack={this.buttonStack('copy')} title={t('copy')} toggleDialog={this.closeCopy}
                             dialogStatus={this.state.showCopy} fullWidth maxWidth='sm' >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField value={this.state.newCode} onChange={this.changeValue} name="newCode"
                            size="medium" fullWidth label={t('newCode')} required data-cy="newCode"
                            error={errors[t('newCode')] != null ? true : false} helperText={errors[t('newCode')]}/>
                        </Grid>
                    </Grid>
                </ModalDialog>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.delete()} open={this.state.deleteOpen} />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(BatchTemplate));
