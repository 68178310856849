import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import DateDisplay from '../UI/DateDisplay/DateDisplay'
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import { CSVDownload } from "react-csv";
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import DateSelect from '../UI/DateSelect/DateSelect';

class MasterCaseList extends Component {
    constructor(props){
        super(props);
        this.state = {
            startDate: '',
            endDate: '',
            offset: 0,
            listSize: 10,
            orderBy: 'number',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            status: '',
            statusSel: null,
            statuses: [],
            number: '',
            security: [],
            list: [],
            csvData: null,
            headers: null,
            separator: ','
        }
    }

    async componentDidMount(){
        const statuses = await ValueHelper.getStatusesForObject(this.props.auth.constants.objectIds.masterCase, this.props.auth.user.tenant);
        let storage = sessionStorage.getItem('masterCases');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        const separator = sessionStorage.getItem('csvSeparator');
        this.setState({
            security: this.props.permission,
            statuses: statuses,
            offset: storage != null ? storage.offset : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'number',
            ordDir: storage != null ? storage.ordDir : -1,
            page: storage != null ? storage.page : 0,
            startDate: storage != null ? storage.startDate : '',
            endDate: storage != null ? storage.endDate : '',
            status: storage != null ? storage.status : '',
            number: storage != null ? storage.number : '',
            statusSel: storage != null ? storage.statusSel : null,
            separator: separator != null ? separator : ','
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('masterCases'), screen: this.props.auth.screenDefs.MasterCaseList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        const sort = {[this.state.orderBy]: this.state.ordDir};
        const data = {
            offset: this.state.offset,
            size: this.state.listSize,
            status: this.state.status,
            number: this.state.number,
            sort: JSON.stringify(sort),
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        };
        let result = await axios.get('/api/mastercases', {params: data});
        this.setState({
            list: result.data.data,
            totalCount: result.data.totalCount
        });
        this.saveFilters();
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            page: this.state.page,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            status: this.state.status,
            number: this.state.number,
            statusSel: this.state.statusSel
        };
        sessionStorage.setItem('masterCases', JSON.stringify(data));
    }

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    changeDate = (name) => (e) => {
        this.setState({[name]: e}, () => this.loadRecords());
    };

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    csvClick = async (e) => {
        this.setState({csvData: null});
        let sort = {[this.state.orderBy]: this.state.ordDir};
        const data = {
            status: this.state.status,
            number: this.state.number,
            sort: JSON.stringify(sort),
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        };
        let list = await axios.get('/api/mastercasesexport', {params: data});
        let headers = list.data.headers;
        let csvData = list.data.data;
        this.setState({csvData: csvData, headers: headers});
    };

    render(){
        const { t } = this.props;
        const security = this.state.security;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/mastercase'
                        createAccess={security.create}
                        csvClick={this.csvClick}
                    />
                </BreadcrumbBar>                
                {this.state.csvData != null &&
                    <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.headers} separator={this.state.separator}/>
                }
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.number} onChange={this.changeValue} name="number"
                            size="medium" fullWidth={true} label={t('number')} data-cy="number"/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.statusSel}
                                options={this.state.statuses}
                                onChange={this.changeAuto('statusSel', 'status')}
                                label={t('status')}
                                data-cy="status"
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.changeDate('startDate')}
                                value={this.state.endDate}
                                label={t('start')}
                                format={this.props.auth.user.dateFormat}
                                data-cy="start"
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.changeDate('endDate')}
                                value={this.state.endDate}
                                label={t('end')}
                                format={this.props.auth.user.dateFormat}
                                data-cy="end"
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="masterCaseTable">
                            <TableHead>
                                <TableRow key="masterCaseHead">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('status')}
                                        active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('date')}
                                        active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('outputs')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) => 
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <Link to={'/mastercase/' + row._id}>{row.number}</Link>
                                        </TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                        <TableCell>{row.plannedOutputs.length}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="masterCaseFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(MasterCaseList));