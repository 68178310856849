import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import TextField from '../UI/TextField/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import { withTranslation } from 'react-i18next';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import Aux from '../../hoc/Auxilary/Auxilary';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ActionBar from '../UI/Buttons/ActionBar';
import RichTextField from '../UI/RichTextField/RichTextField';
import RichText from '../../hoc/RichText/RichText';
import DateSelect from '../UI/DateSelect/DateSelect';
import Panel from '../UI/Panel/Panel';
import Button from '@mui/material/Button';
import '../Documents/draft.css';
import Document from '../General/Document';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class Announcement extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            status: '',
            statusSel: null,
            statuses: [],
            priority: '',
            prioritySel: null,
            priorities: [],
            depsSel: [],
            depOptions: [],
            startDate: new Date(),
            endDate: '',
            title: '',
            enableSave: true,
            security: {},
            locked: false,
            documents: [],
            type: '',
            typeSel: null,
            types: [],
            tenantList: [],
            tenants: [],
            tenantsSel: []
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.announcementStatus, common.cacheValues.announcementPriority, 
            common.cacheValues.department, common.cacheValues.announcementType], this.props.auth.constants, null, this.props.auth.user.tenant);
        let tenRes = await axios.get('/int/tenantselectlist');
        let statusSel = cacheValues.announcementStatuses.find(x => x.value === common.announcementStatuses.draft);
        this.setState({
            statuses: cacheValues.announcementStatuses,
            priorities: cacheValues.announcementPriorities,
            status: common.announcementStatuses.draft,
            statusSel: statusSel,
            security: this.props.permission,
            locked: this.props.permission.update ? false : true,
            types: cacheValues.announcementTypes,
            tenantList: tenRes.data,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/announcements', label: this.props.t('announcements'), screen: this.props.auth.screenDefs.Announcements},
                    { path: 'active', label: this.props.t('announcement'), screen: this.props.auth.screenDefs.Announcement}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/announcement/' + this.state._id);
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let typeSel = this.state.types.find(x => x.value === record.data.type);
        let prioritySel = this.state.priorities.find(x => x.value === record.data.priority);
        let tenantsSel = [];
        if(record.data.tenants != null && record.data.tenants.length > 0){
            record.data.tenants.forEach(row => {
                let val = this.state.tenantList.find(x => x.value === row);
                tenantsSel.push(val);
            });
        }
        let enableSave = this.state.security.update ? true : false;
        let locked = record.data.status === common.announcementStatuses.draft && enableSave ? false : true
        this.setState({
            number: record.data.number,
            status: record.data.status,
            statusSel: statusSel,
            priority: record.data.priority,
            prioritySel: prioritySel,
            title: record.data.title,
            body: record.data.body,
            startDate: record.data.startDate,
            endDate: record.data.endDate,
            enableSave: enableSave,
            locked: locked,
            documents: record.data.documents,
            type: record.data.type,
            typeSel: typeSel,
            tenants: record.data.tenants,
            tenantsSel: tenantsSel
        }, () => {
            const crumbs = [
                { path: '/#/announcements', label: this.props.t('announcements'), screen: this.props.auth.screenDefs.Announcements},
                { path: 'active', label: this.props.t('announcement') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.Announcement}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({[name]: newValue, [data]: newValue != null ? newValue.value : null});
    };

    changeMulti = (name, data) => (e, newValue) => {
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeRichText = async (index, newText, e) => {
        const content = newText;
        this.setState({body: content});    
    }

    save = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    };

    _save = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            _id: this.state._id,
            status: this.state.status,
            priority: this.state.priority,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            title: this.state.title,
            body: this.state.body,
            type: this.state.type,
            tenants: this.state.tenants
        }
        try {
            let result = await axios.post('/api/announcement', data);
            if(this.state._id == null || this.state._id === ''){
                this.props.history.replace('/announcement/' + result.data.id);
            }
            this.setState({_id: result.data.id, enableSave: true}, () => this.loadRecord());
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    validate(){
        let errors = {};
        const state = this.state;
        const t = this.props.t;
        if(state.status == null || state.status === '')
            errors[t('status')] = t('required');
        if(state.priority == null || state.priority === '')
            errors[t('priority')] = t('required');
        if(state.title == null || state.title === '')
            errors[t('title')] = t('required');
        if(state.body == null || state.body === '')
            errors[t('body')] = t('required');
        if(state.startDate == null || state.startDate === '')
            errors[t('startDate')] = t('required');
        if(state.endDate == null || state.endDate === '')
            errors[t('endDate')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    delete = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._delete());
    }

    _delete = async () => {
        const t = this.props.t;
        if(this.state._id == null || this.state._id === ''){
            this.setState({ enableSave: true });
            return;
        }
        if(this.state.status === common.announcementStatuses.published || this.state.status === common.announcementStatuses.deleted){
            this.setState({ enableSave: true });
            return;
        }
        try {
            let result = await axios.delete('/api/announcement/' + this.state._id);
            this.props.history.delete('/announcement/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => this.loadRecord());
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch (err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    publish = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._publish());
    }

    _publish = async () => {
        const t = this.props.t;
        if(this.state._id == null || this.state._id === ''){
            this.setState({ enableSave: true });
            return;
        }
        if(this.state.status === common.announcementStatuses.published || this.state.status === common.announcementStatuses.deleted){
            this.setState({ enableSave: true });
            return;
        }
        try {
            let result = await axios.post('/api/announcementpublish', {id: this.state._id});
            this.setState({enableSave: true}, () => this.loadRecord());
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch (err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    unpublish = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._unpublish());
    }

    _unpublish = async () => {
        const t = this.props.t;
        if(this.state._id == null || this.state._id === ''){
            this.setState({ enableSave: true });
            return;
        }
        if(this.state.status === common.announcementStatuses.draft || this.state.status === common.announcementStatuses.deleted){
            this.setState({ enableSave: true });
            return;
        }
        try {
            let result = await axios.post('/api/announcementunpublish',  {id: this.state._id});
            this.setState({enableSave: true}, () => this.loadRecord());
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch (err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    render(){
        const t = this.props.t;
        const enableSave = this.state.enableSave;
        const errors = this.props.errors;
        const security = this.state.security;
        const locked = this.state.locked;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        {this.state._id != null && this.state._id !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.statusSel != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('status')}</FormLabel>
                                    {this.state.statusSel.label}
                                </FormControl>
                            </Grid>
                        }
                        
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.typeSel}
                                options={this.state.types}
                                onChange={this.changeAuto('typeSel', 'type')}
                                error={errors[t('type')] != null ? true : false}
                                helperText={errors[t('type')]} 
                                label={t('type')}
                                disabled={locked}
                                required
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.prioritySel}
                                options={this.state.priorities}
                                onChange={this.changeAuto('prioritySel', 'priority')}
                                error={errors[t('priority')] != null ? true : false}
                                helperText={errors[t('priority')]} 
                                label={t('priority')}
                                disabled={locked}
                                required
                            />
                        </Grid>
                        <Grid item xs="4" sm={2}>
                            <DateSelect 
                                onChange={this.changeDate('startDate')}
                                value={this.state.startDate}
                                helperText={errors[t('startDate')]}
                                error={errors[t('startDate')] != null ? true : false}
                                label={t('startDate')}
                                disabled={locked}
                                format={this.props.auth.user.dateFormat}
                                required
                            />
                        </Grid>
                        <Grid item xs="4" sm={2}>
                            <DateSelect 
                                onChange={this.changeDate('endDate')}
                                value={this.state.endDate}
                                helperText={errors[t('endDate')]}
                                error={errors[t('endDate')] != null ? true : false}
                                label={t('endDate')}
                                disabled={locked}
                                format={this.props.auth.user.dateFormat}
                            />
                        </Grid>
                        {this.state.tenantList != null && this.state.tenantList.length > 0 &&
                            <Grid item sm={10}>
                                <AutoCompleteField
                                    multiple={true}
                                    value={this.state.tenantsSel}
                                    options={this.state.tenantList}
                                    onChange={this.changeMulti('tenantsSel', 'tenants')}
                                    error={errors[t('tenants')] != null ? true : false}
                                    helperText={errors[t('tenants')]} 
                                    label={t('tenants')}
                                    disabled={locked}
                                />
                            </Grid>
                        }
                        <Grid item sm={11}>
                            <TextField type="text" value={this.state.title} onChange={this.changeValue} name="title"
                            size="medium" fullWidth={true} disabled={locked} label={t('title')} required
                            error={errors[t('title')] != null ? true : false} helperText={errors[t('title')]}/>
                        </Grid>
                        <Grid item sm={11}>
                            {!locked &&
                                <RichTextField
                                    disabled={!enableSave}
                                    id='body'
                                    key='body'
                                    index={0}
                                    value={this.state.body != null ? this.state.body : ''}
                                    errors={errors}
                                    textAreaName='documentContent-richText'
                                    height={900}
                                    changeRichText={this.changeRichText}
                                /> 
                            }
                            {locked &&
                                <RichText>{this.state.body != null ? this.state.body : ''}</RichText>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {this.state._id != null && this.state._id !== '' &&
                                <Document record={this.state._id} documents={this.state.documents}
                                objectType={this.props.auth.constants.objectIds.announcement} callback={this.loadRecord}/>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            {!locked &&
                                <Aux>
                                    <Button variant="contained" color="primary" size="small" onClick={this.save} disabled={!enableSave}>{t('save')}</Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {this.state.status === common.announcementStatuses.draft && this.state._id != null && this.state._id !== '' &&
                                <Aux>
                                    <Button variant="contained" color="secondary" size="small" onClick={this.publish} disabled={!enableSave}>{t('publish')}</Button>&nbsp;&nbsp;
                                    <Button variant="contained" color="secondary" size="small" onClick={this.delete} disabled={!enableSave}>{t('delete')}</Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {this.state.status === common.announcementStatuses.published &&
                                <Button variant="contained" color="secondary" size="small" onClick={this.unpublish} disabled={!enableSave}>{t('unpublish')}</Button>
                            }
                        </Grid>
                    </Grid>
                </Panel>
                {this.state._id != null && this.state._id !== '' &&
                    <div>
                        <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.announcement}/>
                        <ObjectLog id={this.state._id}/>
                    </div>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Announcement));