import React from 'react';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import { NumericFormat } from 'react-number-format';

// const boxStyle = {
//     margin: '8px',
//     width: '100%'
// }

// const thStyle = {
//     borderBottom: '2px solid rgb(224, 224, 224)', 
//     fontSize: '9pt', 
//     textAlign: 'center'
// }

// const tdStyle = { 
//     border: '0.5px solid rgb(224, 224, 224)', 
//     fontSize: '8pt', 
//     padding: '5px',
// }

// const tableStyle = {
//     width: '100%',
//     borderCollapse: 'collapse'
// }

export default function BillOfLadingTemplate(props) {
    const { record, t } = props;
    return(
        <div style={{ width: '8.5in', pageBreakBefore: 'always', fontFamily: 'Open Sans' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                <div style={{ width: '68%', fontSize: '9pt' }}>
                    <span style={{ fontSize: '12pt', fontWeight: 'medium' }}><strong>{t('shipper')}</strong></span><br />
                    <span style={{ marginBottom: '0px' }}>
                        {record?.location?.name != null ? record.location.name : ''}
                    </span><br />
                    {record?.location?.license != null && record?.location?.license !== '' ?
                        <>
                            <span style={{ marginBottom: '0px' }}>
                                {t('license') + '#: ' + record.location.license}
                            </span><br />
                        </> : ''
                    }                    
                    <span style={{ marginBottom: '0px' }}>
                        {record?.location?.street1 != null ? record.location.street1 : ''}
                    </span><br />
                    {record?.location?.street2 != null && record?.location?.street2 !== '' &&
                        <>
                            <span style={{ marginBottom: '0px' }}>
                                {record?.location?.street2 != null ? record.location.street2 : ''}
                            </span><br />
                        </>             
                    }
                    <span style={{ marginBottom: '0px' }}>
                        {record?.location?.city != null && record?.location?.prov?.name != null ?
                            record.location.city + ', ' + record.location.prov.name : ''}
                    </span><br />
                    <span style={{ marginBottom: '0px' }}>
                        {record?.location?.postal != null ? record.location.postal : ''}
                    </span><br />
                    <span style={{ marginBottom: '0px' }}>
                        {record?.country?.name != null ? record.country.name : ''}
                    </span><br />
                </div>
                <div style={{ width: '32%', fontSize: '9pt' }}>
                    <span style={{ fontSize: '12pt', fontWeight: 'medium' }}><strong>{t('receiver')}</strong></span><br />
                    {record?.customer != null &&
                        <>
                            <span style={{ marginBottom: '0px' }}>
                                {record?.customer?.name != null ? record.customer.name : 
                                 record?.customer?.firstName != null ? record.customer.firstName + ' ' + record.customer.lastName : '' 
                                }
                            </span><br />
                            {record?.customer?.licenseNumber != null && record?.customer?.licenseNumber !== '' &&
                                <>
                                    <span style={{ marginBottom: '0px' }}>
                                        {t('license') + '#: ' + record.customer.licenseNumber}
                                    </span><br />
                                </>
                            }
                            <span style={{ marginBottom: '0px' }}>
                                {record?.address1 != null ? record.address1 : ''}
                            </span><br />
                            {record?.address2 != null && record?.address2 !== '' &&
                                <>
                                    <span style={{ marginBottom: '0px' }}>
                                        {record.address2}
                                    </span><br />
                                </>
                            }
                            <span style={{ marginBottom: '0px' }}>
                                {record?.city != null && record?.prov?.name != null ? 
                                    record.city + ', ' + record.prov.name : ''
                                }
                            </span><br />
                            <span style={{ marginBottom: '0px' }}>
                                {record?.postal != null ? record.postal : ''}
                            </span>
                            {record?.country?.name != null &&
                                <>
                                    <br />
                                        <span style={{ marginBottom: '0px' }}>
                                            {record.country.name}
                                        </span>
                                    <br />
                                </>
                            }
                        </>
                    }
                    {record?.vendor != null &&
                        <>
                            <span style={{ marginBottom: '0px' }}>
                                {record?.vendor?.name != null ? record.vendor.name : '' }
                            </span><br />
                            {record?.vendor?.licenseNumber != null && record?.vendor?.licenseNumber !== '' &&
                                <>
                                    <span style={{ marginBottom: '0px' }}>
                                        {t('license') + '#: ' + record.vendor.licenseNumber}
                                    </span><br />
                                </>
                            }
                            <span style={{ marginBottom: '0px' }}>
                                {record?.vendor.address.address1 != null ? record.vendor.address.address1 : ''}
                            </span><br />
                            {record?.vendor.address.address2 != null && record?.vendor.address.address2 !== '' &&
                                <>
                                    <span style={{ marginBottom: '0px' }}>
                                        {record.vendor.address.address2}
                                    </span><br />
                                </>
                            }
                            <span style={{ marginBottom: '0px' }}>
                                {record?.vendor?.address?.city != null && record?.vendor?.address?.prov?.name != null ? 
                                    record.vendor.address.city + ', ' + record.vendor.address.prov.name : ''
                                }
                            </span><br />
                            <span style={{ marginBottom: '0px' }}>
                                {record?.vendor.address.postal != null ? record.vendor.address.postal : ''}
                            </span>
                            {record?.country?.name != null &&
                                <>
                                    <br />
                                        <span style={{ marginBottom: '0px' }}>
                                            {record.vendor.address.country.name}
                                        </span>
                                    <br />
                                </>
                            }
                        </>
                    }
                </div>
            </div>
            {record.form != null &&
                <>
                    <hr style={{ width: '80%', margin: '0 auto', clear: 'both' }} />
                        <Form renderer={renderer}
                            defaultFields={record.form}
                            disabled={true}
                            print={props.print}/>
                    <br/>
                </>
            }
            <hr style={{ width: '80%', margin: '8 auto', clear: (record?.form != null ? null : 'both') }} />
            <span style={{ display: 'block', fontSize: '12pt', fontWeight: 'bold'}}>{t('contents')}</span>
            <table style={{ width: '100%', borderCollapse: 'collapse'}}>
                <thead>
                    <tr>
                        <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt' }} >{t('item')}</th>
                        <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt' }} >{t('packagedOn')}</th>
                        <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt' }} >{t('sku')}</th>
                        <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt' }} >{t('lot')}</th>
                        <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt' }} >{t('gtin')}</th>
                        <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt', textAlign: 'right' }} >{t('units')}</th>
                        <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt', textAlign: 'right' }} >{t('quantity')}</th>
                    </tr>
                </thead>
                <tbody>
                    {record?.contents?.length > 0 ?
                        record.contents.map((row, i) => 
                            <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt' }} >{row.item != null ? row.item.name : ''}</td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt', width: '75px' }} >{row.sourceContainer?.packagedOn != null ? <DateDisplay value={row.sourceContainer.packagedOn} format={props.dateFormat}/> : ''}</td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt', width: '75px' }} >
                                    {row.item != null && row.item.sku != null ? row.item.sku : 
                                        row.item != null && row.item.number != null ? row.item.number : ''}</td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt', width: '100px' }} >
                                    {/* {record.type._id === constants.shipmentTypes.sale ? '' :
                                        record.type._id === constants.shipmentTypes.return ? 
                                        row.lot != null && row.lot.vendorLot != null ? row.lot.vendorLot : 
                                            row.lot != null && row.lot.customNumber != null ? row.lot.customNumber : '' 
                                        : row.lot != null && row.lot.number != null ? row.lot.number : ''} */}
                                        { row.lotNumber != null && row.lotNumber !== '' ? row.lotNumber :
                                          row.lot?.vendorLot != null ? row.lot.vendorLot : 
                                          row.lot?.customNumber != null ? row.lot.customNumber :
                                          row.lot?.number != null ? row.lot.number : '' }
                                </td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt', width: '85px' }} >{row.item != null && row.item.gtin != null ? row.item.gtin : ''}</td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt', textAlign: 'right', width: '75px' }} >
                                    {row.units != null ? 
                                        <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={2}
                                            value={row.units} /> : row.quantity 
                                    }
                                </td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt', textAlign: 'right', width: '75px' }} >
                                    {record.gUom != null ? 
                                        <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={2}
                                            value={row.totalCannabis != null && row.totalCannabis !== '' ? row.totalCannabis : 0}
                                            suffix={record.gUom != null ? record.gUom.label : ''}
                                        /> : row.totalCannabis
                                    }
                                </td>
                            </tr>
                        ) : <tr style={{ border: '1px solid #ddd', padding: '2px' }} ><td colspan='6'><center>{t('noData')}</center></td></tr>
                    }
                </tbody>
            </table>
        </div>
    )
}