import React from 'react';
import { PrintButton, SaveButton, ReleaseButton, SplitButton, MoveButton, DestroyButton,
         CombineButton, DeleteButton, CopyButton, SimpleUnlockButton, UnreleaseButton } from './Buttons';
import Unlock from '../../General/Unlock';
import { printButtonSecurity, saveButtonSecurity, releaseButtonSecurity, destroyButtonSecurity,
         unlockButtonSecurity, splitButtonSecurity, moveButtonSecurity, combineButtonSecurity } from '../../../helpers/securityHelper';
import Grid from '@mui/material/Grid';

export default React.memo((props) => {
    const { id, objectType, callback, saveClick, saveEnabled,
            permissions, printClick, copyClick, locked,
            status, common, constants, type, releaseClick, splitClick, simpleUnlockClick,
            combineClick, moveClick, destroyClick, deleteClick, enableDelete, enableUnlockProp,
            activityDate, lockDate, isSuper, unreleaseClick, enableUnrelease} = props;
    let enableSave = saveButtonSecurity(permissions, status, common, constants, type);
    const enablePrint = printButtonSecurity(permissions, status, common, constants, type);
    const enableUnlock = enableUnlockProp != null && !enableUnlockProp ? false : unlockButtonSecurity(permissions, status, common, constants, type, activityDate, lockDate, isSuper);
    const enableRelease = releaseButtonSecurity(permissions, status, common, constants, type);
    const enableSplit = splitButtonSecurity(permissions, status, common, constants, type);
    const enableCombine = combineButtonSecurity(permissions, status, common, constants, type);
    const enableMove = moveButtonSecurity(permissions, status, common, constants, type);
    const enableDestroy = destroyButtonSecurity(permissions, status, common, constants, type);
    if(saveEnabled != null) {
        enableSave = enableSave && saveEnabled;
    }
    return (
        <Grid container >
            <Grid item xs={12} sx={{ mt: 2 }}>
                <SaveButton saveClick={saveClick} enabled={enableSave} data-cy="save"/>
                {printClick != null &&
                    <PrintButton printClick={printClick} enabled={enablePrint} data-cy="print"/>
                }
                {releaseClick != null && enableRelease &&
                    <ReleaseButton releaseClick={releaseClick} enabled={enableRelease} data-cy="release"/>
                }
                {unreleaseClick != null && enableUnrelease &&
                    <UnreleaseButton unreleaseClick={unreleaseClick} enabled={enableUnrelease} data-cy="release"/>
                }
                {splitClick != null &&
                    <SplitButton splitClick={splitClick} enabled={enableSplit} data-cy="splitButton"/>
                }
                {combineClick != null &&
                    <CombineButton combineClick={combineClick} enabled={enableCombine} data-cy="combine"/>
                }
                {moveClick != null &&
                    <MoveButton moveClick={moveClick} enabled={enableMove} data-cy="moveButton"/>
                }
                {enableDestroy && destroyClick != null &&
                    <DestroyButton destroyClick={destroyClick} enabled={enableDestroy} data-cy="destroy"/>
                }
                {enableUnlock && callback != null &&
                    <Unlock id={id} objectType={objectType} callback={callback} enable={enableUnlock} data-cy="unlock"/>
                }
                {simpleUnlockClick != null && permissions.unlock &&
                    <SimpleUnlockButton simpleUnlockClick={simpleUnlockClick} enabled={permissions.unlock} locked={locked} data-cy="simpleUnlock"/>
                }
                {enableSave && copyClick != null &&
                    <CopyButton copyClick={copyClick} enabled={enableSave} data-cy="copy"/>
                }
                {(enableDelete || enableDestroy) && deleteClick != null &&
                    <DeleteButton deleteClick={deleteClick} enabled={enableDelete || enableDestroy} data-cy="delete"/>
                }
            </Grid>
        </Grid>
    );
});
