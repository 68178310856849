import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import dateFormat from 'date-fns/format';
import AddIcon from '@mui/icons-material/Add';
import ActionBar from '../UI/Buttons/ActionBar';
import ConversionHelper from '../../helpers/conversionHelper';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';
import listHelper from '../../helpers/listHelper';
import ListMultiSelect from '../UI/ListMultiSelect/ListMultiSelect';

const IpmTreatments = (props) => {
    const isFirstRender = useRef(true);
    const [loadRefData, setLoadRefData] = useState(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [columnOrder, setColumnOrder] = useState([]);
    const [values, setValues] = useState({issues: [], rooms: [], statuses: [], applicationMethods: [], types: [], treatmentTypes: []});
    const { t } = useTranslation('translation');
    const statuses = props.auth.constants.statuses;
    const tenant = props.auth.user.tenant;
    const [statusesSel, setStatusesSel] = useState({ids: [], values: []});
    const [showSelect, setShowSelect] = useState(false);
    const crumbs = [
        { path: 'active', label: t('cropTreatments'), screen: props.auth.screenDefs.CropTreatmentList}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        async function fetchData(){
            const columnFilters = await listHelper.getItem('IPMTreatmentsColumnFilters', tenant);
            const columnVisibility = await listHelper.getItem('IPMTreatmentsColumnVisibility', tenant);
            const density = await listHelper.getItem('IPMTreatmentsDensity', tenant);
            const globalFilter = await listHelper.getItem('IPMTreatmentsGlobalFilter', tenant);
            const showGlobalFilter = await listHelper.getItem('IPMTreatmentsShowGlobalFilter', tenant);
            const showColumnFilters = await listHelper.getItem('IPMTreatmentsShowColumnFilters', tenant);
            const sorting = await listHelper.getItem('IPMTreatmentsSorting', tenant);
            const pagination = await listHelper.getItem('IPMTreatmentsPagination', tenant);
            const tempColumnOrder = await listHelper.getItem('IPMTreatmentsColumnOrder', tenant);
            if (columnFilters) {
                let filters = ConversionHelper.filtersDateParse(JSON.parse(columnFilters));
                setColumnFilters(filters);
                let status = filters.find(x => x.id === 'status');
                if(status != null){
                    let sel = {ids: status.value, values: []};
                    status.value.forEach(id => {
                        let val = values.statuses.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setStatusesSel(sel);
                }
            }
            if (columnVisibility) {
                setColumnVisibility(columnVisibility);
            }
            if (density) {
                setDensity(density);
            }
            if (globalFilter) {
                setGlobalFilter(globalFilter);
            }
            if (showGlobalFilter) {
                setShowGlobalFilter(showGlobalFilter);
            }
            if (showColumnFilters) {
                setShowColumnFilters(showColumnFilters);
            }
            if (sorting) {
                setSorting(sorting);
            }
            if (pagination) {
                setPagination(pagination);
            }
            if (tempColumnOrder != null && tempColumnOrder.length > 0) {
                setColumnOrder(tempColumnOrder);
            } else {
                setColumnOrder(['number', 'status', 'treatmentType', 'type', 'date', 'room', 'applicationMethod', 'pest']);
            }
            isFirstRender.current = false;
        }
        if(isFirstRender.current === true){
            fetchData();
        }
    }, [values, statuses, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('IPMTreatmentsColumnFilters', tenant, JSON.stringify(columnFilters));
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('IPMTreatmentsColumnVisibility', tenant, columnVisibility);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnVisibility, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('IPMTreatmentsDensity', tenant, density);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [density, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('IPMTreatmentsGlobalFilter', tenant, globalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [globalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('IPMTreatmentsShowGlobalFilter', tenant, showGlobalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [showGlobalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('IPMTreatmentsShowColumnFilters', tenant, showColumnFilters);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [showColumnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('IPMTreatmentsSorting', tenant, sorting);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [sorting, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('IPMTreatmentsPagination', tenant, pagination);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [pagination, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('IPMTreatmentsColumnOrder', tenant, columnOrder);
        }
        if (isFirstRender.current === true) return;
        fetchData();
    }, [columnOrder, tenant]);

    const resetState = async () => {
        await listHelper.removeItem('IPMTreatmentsColumnFilters', tenant);
        await listHelper.removeItem('IPMTreatmentsColumnVisibility', tenant);
        await listHelper.removeItem('IPMTreatmentsDensity', tenant);
        await listHelper.removeItem('IPMTreatmentsGlobalFilter', tenant);
        await listHelper.removeItem('IPMTreatmentsShowGlobalFilter', tenant);
        await listHelper.removeItem('IPMTreatmentsShowColumnFilters', tenant);
        await listHelper.removeItem('IPMTreatmentsSorting', tenant);
        await listHelper.removeItem('IPMTreatmentsPagination', tenant);
        await listHelper.removeItem('IPMTreatmentsColumnOrder', tenant);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.growingRoom, common.cacheValues.pest, common.cacheValues.cropTreatmentType,
                common.cacheValues.applicationMethod], props.auth.constants, null, props.auth.user.tenant);
            const statuses = await ValueHelper.getStatusesForObject(props.auth.constants.objectIds.cropTreatment, props.auth.user.tenant);
            setValues({
                treatmentTypes: cacheValues.cropTreatmentTypes,
                types: cacheValues.cropTreatmentSubTypes,
                rooms: cacheValues.rooms,
                issues: cacheValues.pests,
                statuses: statuses,
                applicationMethods: cacheValues.applicationMethods
            });
            let status = columnFilters != null ? columnFilters.find(x => x.id === 'status') : null;
            if(status != null){
                let sel = {ids: status.value, values: []};
                status.value.forEach(id => {
                    let val = statuses.find(x => x.value === id);
                    sel.values.push(val);
                });
                setStatusesSel(sel);
            }
            setLoadRefData(false);
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, columnFilters, loadRefData]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'IPMTreatments',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/croptreatments', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });

    let columns = useMemo(() => [
        {
            accessorKey: 'number',
            id: 'number',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/croptreatment/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            )
        },
        {
            accessorKey: 'status',
            id: 'status',
            header: t('status'),
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='status'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.statuses} 
                    setSelected={setStatusesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={statusesSel.ids} 
                    selectedSel={statusesSel.values}
                    screen='IPMTreatments'
                    tenant={props.auth.user.tenant}
                    columnFilters={columnFilters}/>
                </Box>
            ),
        },
        {
            accessorKey: 'treatmentType',
            id: 'treatmentType',
            header: t('treatmentType'),
            filterVariant: 'select',
            filterSelectOptions: values.treatmentTypes
        },
        {
            accessorKey: 'type',
            id: 'type',
            header: t('type'),
            filterVariant: 'select',
            filterSelectOptions: values.types
        },
        {
            accessorKey: 'date',
            id: 'date',
            header: t('date'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() != null ? dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat) : ''}
                </Box>
            )
        },
        {
            accessorKey: 'room.name',
            id: 'room',
            header: t('room'),
            filterVariant: 'select',
            filterSelectOptions: values.rooms
        },
        {
            accessorKey: 'applicationMethod',
            id: 'applicationMethod',
            header: t('applicationMethod'),
            filterVariant: 'select',
            filterSelectOptions: values.applicationMethods
        },
        {
            accessorKey: 'issue',
            id: 'issue',
            header: t('issue'),
            filterVariant: 'select',
            filterSelectOptions: values.issues
        },
    ], [values, t, props, statusesSel, columnFilters, showSelect]);

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true,  columnVisibility: {id: false}},
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: false,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()} color="primary">
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('clearListSettings')}>
                <   IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                {props.permission.create &&
                    <Tooltip arrow title={t('add')}>
                        <IconButton component={Link} to={'/croptreatment'} color="primary">
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs}>
                <ActionBar/>
            </BreadcrumbBar>
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
        </Aux>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(IpmTreatments);
