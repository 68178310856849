import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import Aux from '../../../hoc/Auxilary/Auxilary';

export default function CheckboxField(props) {
    const { checked, label, onChange, disabled, name, required } = props;
  
    return (
      <Aux>
        <FormControlLabel
            control={<Checkbox checked={checked || false} color='secondary' onChange={onChange} name={name} required={required}/>}
            label={label} disabled={disabled}
        />
      </Aux>
    );  
  };
