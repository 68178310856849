import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import SectionTitle from '../UI/Typography/SectionTitle';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import { FormControl, FormLabel } from '@mui/material';
import ModalDialog from '../UI/Dialog/ModalDialog';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';

class TestDefinition extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            name: '',
            testItems: [],
            index: -1,
            edit: false,
            testItem: '',
            testItemSelect: [],
            minValue: '',
            maxValue: '',
            required: false,
            requiredFinal: false,
            categories: [],
            testCategories: [],
            selectedCategories: [],
            uoms: [],
            copyOpen: false,
            copyName: '',
            security: [],
            locked: false,
            enableSave: true,
            deleteOpen: false,
            testValues: []
        }
    }

    async componentDidMount(){
        var vals = await axios.get('/api/testdefinitionvalues');
        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.testResultValue], this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({
            categories: vals.data.categories,
            types: vals.data.types,
            security: this.props.permission,
            testValues: cacheValues.testResultValues,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null
        }, () => {
            const crumbs = [
                { path: '/#/testdefinitions', label: this.props.t('testDefinitions'), screen: this.props.auth.screenDefs.TestDefinition},
                { path: 'active', label: this.props.t('testDefinition'), screen: this.props.auth.screenDefs.TestDefinition}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            if(this.state._id != null){
                this.loadRecord();
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/testdefinition/' + this.props.match.params.id);
        let cats = [];
        let testCategories = record.data.testCategories;
        testCategories.forEach(tc => {
            for(let cat of this.state.categories){
                if(tc.category._id === cat.value){
                    cats.push(cat);
                    break;
                }
            }
            for(let row of tc.testItems){
                if(row.value != null){
                    row.valueSel = this.state.testValues.find(x => x.value === row.value);
                }
            }
        });
        this.setState({
            _id: record.data._id,
            name: record.data.name,
            testCategories: testCategories,
            selectedCategories: cats,
            locked: this.props.permission.create || this.props.permission.update || this.props.permission.unlock ? false : true,
            deleteOpen: false
        });
    }

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let cats = [];
        this.state.testCategories.forEach(cat => {
            let rec = {};
            rec._id = cat._id;
            let items= [];
            rec.category = cat.category._id;
            cat.testItems.forEach(item => {
                items.push({
                    testItem: item.testItem._id,
                    minValue: item.minValue,
                    maxValue: item.maxValue,
                    required: item.required,
                    requiredFinal: item.requiredFinal,
                    value: item.value !== '' ? item.value : null
                })
            })
            rec.testItems = items;
            cats.push(rec);
        })
        let data = {
            _id: this.state._id,
            name: this.state.name,
            type: this.state.type,
            testCategories: cats
        }
        try {
            let result = await axios.post('/api/testdefinition', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/testDefinition/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            })            
        }
    }

    validate(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.name == null || state.name === '')
            errors[t('name')] = t('required');
        for(var i = 0; i < this.state.testCategories.length; i++){
            var cat = this.state.testCategories[i];
            for(var j = 0; j < cat.testItems.length; j++){
                var testItem = cat.testItems[j];
                if((testItem.maxValue == null || testItem.maxValue === '') && testItem.valueSel == null){
                    errors[`${t('maxValue')} (${t('line')} ${i})`] = t('required');
                }
                if(testItem.minValue != null && testItem.minValue !== '' && testItem.maxValue != null && testItem.maxValue !== ''){
                    var min = parseFloat(testItem.minValue);
                    var max = parseFloat(testItem.maxValue);
                    if (min > max){
                        errors[`${t('minValue')} (${t('line')} ${i})`] = t('minLessMax');
                    }
                }
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    categoryChange = async (e, newValue) => {
        if(newValue != null){
            let categories = this.state.testCategories;
            if(newValue.length > this.state.testCategories.length){
                let catItems = await axios.get('/api/testcategoryitems/' + newValue[newValue.length - 1].value);
                categories.push(catItems.data);
            } else {
                for(let i = 0; i < categories.length; i++){
                    let cat = categories[i];
                    let exists = newValue.find(x => x.value === cat.category._id);
                    if(!exists){
                        categories.splice(i, 1);
                        break;
                    }
                }
            }
            this.setState({
                selectedCategories: newValue,
                testCategories: categories
            })
        } else {
            this.setState({
                selectedCategories: [],
                testCategories: []
            })
        }
        
    }

    changeCatNumber = (name, catIndex, itemIndex) => (e) => {
        const testCategories = this.state.testCategories;
        let cat = testCategories[catIndex];
        cat.testItems[itemIndex][name] = e.target.value;
        this.setState({testCategories: testCategories});
    }

    changeCatAuto = (name, data, catIndex, itemIndex) => (e, newValue) => {
        const testCategories = this.state.testCategories;
        let cat = testCategories[catIndex];
        cat.testItems[itemIndex][name] = newValue;
        cat.testItems[itemIndex][data] = newValue != null ? newValue.value : '';
        this.setState({testCategories: testCategories});
    }

    changeSelect = (name, data) => value => {
        this.setState({[name]: value, [data]: value.value});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    requiredChanged(a, b){
        var testCategories = this.state.testCategories;
        testCategories[a].testItems[b].required = !testCategories[a].testItems[b].required;
        this.setState({testCategories: testCategories});
    }

    requiredFinalChanged(a, b){
        var testCategories = this.state.testCategories;
        testCategories[a].testItems[b].requiredFinal = !testCategories[a].testItems[b].requiredFinal;
        this.setState({testCategories: testCategories});
    }

    copy = e => {
        this.setState({copyOpen: true});
    }

    performCopy = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._performCopy(e));
    }

    _performCopy = async e => {
        const t = this.props.t;
        let data = {
            origId: this.state._id,
            name: this.state.copyName
        }
        try {
            let result = await axios.post('/api/testdefinitioncopy', data);
            this.setState({enableSave: true});
            this.componentDidMount();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    cancelCopy = e => {
        this.setState({
            copyOpen:false,
            copyName: ''
        })
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e));
    }

    _deleteClick = async (e) => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/testdefinition/' + this.state._id);
                this.setState({enableSave: true, deleteOpen: false});
                this.return();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            } catch (err){
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            } 
        } else {
            this.props.history.goBack();
        }
    }

    render(){
        const errors = this.props.errors;
        const security = this.state.security;
        const t = this.props.t;
        const locked = !security.create && !security.update;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={8} sm={4}>
                            {!locked &&
                                <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                size="medium" fullWidth={true} label={t('name')} required
                                error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('name')}</FormLabel>
                                    {this.state.name}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item sm={10}>
                            <AutoCompleteField
                                multiple={true}
                                stayOpen={true}
                                disabled={locked}
                                value={this.state.selectedCategories}
                                options={this.state.categories}
                                onChange={this.categoryChange}
                                error={errors[t('testCategories')] != null ? true : false}
                                helperText={errors[t('testCategories')]}
                                label={t('categories')}
                            />
                        </Grid>
                    </Grid>
                    {this.state.testCategories != null && this.state.testCategories.length > 0 && this.state.testCategories.map((row, i) =>
                        <Aux key={'testCategories-' + i}>
                            <br/>
                            <SectionTitle title={row.category.code}/>
                            <TableContainer component={Paper}>
                                <Table aria-label="Test Definition">
                                    <TableHead>
                                        <TableRow key="tableHeader">
                                            <TableCell>{t('testItem')}</TableCell>
                                            <TableCell>{t('minValue')}</TableCell>
                                            <TableCell>{t('maxValue')}</TableCell>
                                            <TableCell>{t('value')}</TableCell>
                                            <TableCell>{t('required')}</TableCell>
                                            <TableCell>{t('finalRequired')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.testItems.map((item, k) =>
                                            <TableRow key={"tableRow" + k}>
                                                <TableCell>{item.testItem.name}</TableCell>
                                                <TableCell>
                                                    {!locked &&
                                                        <TextField type="number" onChange={this.changeCatNumber('minValue', i, k)}
                                                        name={'minValue' + i + k } value={item.minValue}
                                                        size="medium" fullWidth={true}
                                                        error={errors[`${t('minValue')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('minValue')} (${t('line')} ${i}, ${k})`]}/>
                                                    }
                                                    {locked &&
                                                        item.minValue
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {!locked &&
                                                        <TextField type="number" onChange={this.changeCatNumber('maxValue', i, k)}
                                                        name={'maxValue' + i + k } value={item.maxValue} size="medium" fullWidth={true}                                                        
                                                        error={errors[`${t('maxValue')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('maxValue')} (${t('line')} ${i}, ${k})`]}/>
                                                    }
                                                    {locked && item.maxValue}
                                                </TableCell>
                                                <TableCell>
                                                    {!locked &&
                                                        <AutoCompleteField
                                                            value={item.valueSel}
                                                            options={this.state.testValues}
                                                            onChange={this.changeCatAuto('valueSel', 'value', i, k)}
                                                            error={errors[`${t('value')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                            helperText={errors[`${t('value')} (${t('line')} ${i}, ${k})`]}
                                                        />
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <CheckboxField checked={item.required} disabled={locked} onChange={() => this.requiredChanged(i, k)}
                                                    name={'required' + i + k}/>
                                                </TableCell>
                                                <TableCell>
                                                    <CheckboxField checked={item.requiredFinal} onChange={() => this.requiredFinalChanged(i, k)}
                                                    name={'requiredFinal' + i + k} disabled={locked}/>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Aux>
                    )}
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            <Button variant="contained" color="primary" size="small" disabled={locked && !this.state.enableSave} onClick={this.save}>{t('save')}</Button>&nbsp;&nbsp;
                            <Button variant="contained" color="secondary" size="small" disabled={locked} onClick={this.copy}>{t('copy')}</Button>&nbsp;&nbsp;
                            {security.delete &&
                                <Button variant="contained" color="secondary" size="small" disabled={locked} onClick={this.deleteToggle}>{t('delete')}</Button>
                            }
                        </Grid>
                    </Grid>
                    <ModalDialog 
                        buttonStack={[
                            <Button variant="contained" color="secondary" size="small" onClick={this.cancelCopy}>{t('cancel')}</Button>,
                            <Button variant="contained" color="primary" size="small" onClick={this.performCopy}>{t('copy')}</Button>
                        ]} 
                        title={t('copy')} toggleDialog={this.cancelCopy} dialogStatus={this.state.copyOpen} fullWidth maxWidth='lg' >
                        <Grid container spacing={1}>
                            <Grid item xs={5}>
                                <TextField onChange={this.changeValue} label={t('name')}
                                name={'copyName'} value={this.state.copyName}
                                size="medium" fullWidth={true} required />
                            </Grid>
                        </Grid>
                    </ModalDialog>
                {this.state._id != null && this.state._id !== '' &&
                    <Aux>
                        <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.testDefinition}/>
                        <ObjectLog id={this.state._id}/>
                    </Aux>
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(TestDefinition));
