import { SCREEN_LOCK, BLUR_SCREEN } from './types';

export const setScreenLock = screenLock => ({
    type: SCREEN_LOCK,
    payload: {screenLock: screenLock}
});

export const setBlurScreen = blurScreen => ({
    type: BLUR_SCREEN,
    payload: {blurScreen: blurScreen}
});