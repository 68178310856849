import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { withTranslation } from 'react-i18next';

class Ncrs extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            pages: 1,
            listSize: 5,
            orderBy: 'occurredOn',
            ordDir: -1,
            totalCount: 0,
            page: 0
        }
    }

    componentDidMount(){
        this.loadRecords();
    }

    async loadRecords(){
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: 'batch', value: this.props.batch},
            {id: 'ebatch', value: this.props.ebatch},
            {id: 'productionOrder', value: this.props.productionOrder},
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        var list = await axios.get('/api/ncrs', {params: params});
        this.setState({
            list: list.data.data,
            pages: Math.ceil(list.data.totalCount / this.state.listSize),
            totalCount: list.data.totalCount
        })
    }

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Table>
                <TableHead>
                    <TableRow key='ncrWidget-Head'>
                        <TableCell key='ncrWidget-Head-Number' align='left' onClick={() => this.sortClick('number')}>{t('number')}</TableCell>
                        <TableCell key='ncrWidget-Head-Title' align='left' onClick={() => this.sortClick('title')}>{t('title')}</TableCell>
                        <TableCell key='ncrWidget-Head-Occurred' align='left' onClick={() => this.sortClick('occurredOn')}>{t('occurred')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.list != null && this.state.list.length > 0 ?
                        this.state.list.map((row, i) => 
                            <TableRow key={'ncrWidget-' + row._id}>
                                <TableCell><Link to={'/ncr/' + row._id}>{row.number}</Link></TableCell>
                                <TableCell>{row.title}</TableCell>
                                <TableCell><DateDisplay value={row.occurredOn} format={this.props.auth.user.dateFormat}/></TableCell>
                            </TableRow>
                        ) :
                        <TableRow key='ncrWidget-Empty'>
                            <TableCell colSpan={3} sx={{ textAlign: 'center' }}>{t('noData')}</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
  });

export default withTranslation() (connect(mapStateToProps)(Ncrs));