import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class RoomList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            location: '',
            locations: [],
            offset: 0,
            listSize: 10,
            orderBy: 'name',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            locationSelected: null,
            type: '',
            typeSel: null,
            lightType: '',
            lightTypeSel: null,
            types: [],
            lightTypes: [],
            name: '',
            security: []
        };
        this.changeValue = this.changeValue.bind(this);
    }

    async componentDidMount(){
        try {
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.activeLocation, common.cacheValues.roomType, common.cacheValues.lightType], 
                this.props.auth.constants, null, this.props.auth.user.tenant);
            let locations = cacheValues.locations;
            let storage = sessionStorage.getItem('rooms');
            storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
            this.setState({
                locations: locations,
                types: cacheValues.roomTypes,
                lightTypes: cacheValues.lightTypes,
                security: this.props.permission,
                offset: storage != null ? storage.offset : 0,
                listSize: storage != null ? storage.listSize : 10,
                orderBy: storage != null ? storage.orderBy : 'number',
                ordDir: storage != null ? storage.ordDir : -1,
                location: storage != null ? storage.location : '',
                locationSelected: storage != null ? storage.locationSelected : null,
                type: storage != null ? storage.type : '',
                typeSel: storage != null ? storage.typeSel : null,
                lightType: storage != null ? storage.lightType : '',
                lightTypeSel: storage != null ? storage.lightTypeSel : null,
                name: storage != null ? storage.name : ''
            }, () => {
                this.loadRecords();
            });
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: this.props.t('error'), severity: 'error'}});
        }
    }

    async loadRecords(){
        var sort = {[this.state.orderBy]: this.state.ordDir};
        try {
            let data = {
                size: this.state.listSize,
                offset: this.state.offset,
                location: this.state.location,
                name: this.state.name,
                type: this.state.type,
                lightType: this.state.lightType,
                sort: JSON.stringify(sort)
            };
            let list = await axios.get('/api/rooms', {params: data});
            this.setState({
                list: list.data.data,
                totalCount: list.data.totalCount
            });
            const crumbs = [
                { path: 'active', label: this.props.t('rooms'), screen: this.props.auth.screenDefs.RoomList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.saveFilters();
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: this.props.t('error'), severity: 'error'}});
        }
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            name: this.state.name,
            location: this.state.location,
            locationSelected: this.state.locationSelected,
            type: this.state.type,
            typeSel: this.state.typeSel,
            lightType: this.state.lightType,
            lightTypeSel: this.state.lightTypeSel
        };
        sessionStorage.setItem('rooms', JSON.stringify(data));
    }

    changeValue(e){
        this.setState({[e.target.name]: e.target.value, offset: 0, page: 0}, () => this.loadRecords());
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    }

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    searchClick(e){
        this.loadRecords();
    }

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/room'
                        createAccess={security.create}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.name} onChange={this.changeValue} name="name"
                            size="medium" fullWidth={true} label={t('name')} data-cy="name"/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.locationSelected}
                                options={this.state.locations}
                                onChange={this.changeAuto('locationSelected', 'location')}
                                label={t('location')}
                                data-cy="location"
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.typeSel}
                                options={this.state.types}
                                onChange={this.changeAuto('typeSel', 'type')}
                                label={t('type')}
                                data-cy="type"
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.lightTypeSel}
                                options={this.state.lightTypes}
                                onChange={this.changeAuto('lightTypeSel', 'lightType')}
                                label={t('lightType')}
                                data-cy="lightType"
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Room List">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('name')}
                                        active={this.state.orderBy === 'name'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('name')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('location')}
                                        active={this.state.orderBy === 'location'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('location')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('type')}
                                        active={this.state.orderBy === 'type'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('type')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('lightType')}
                                        active={this.state.orderBy === 'lightType'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('lightType')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/room/' + row._id} name={'roomLink' + i}>{row.name}</Link></TableCell>
                                        <TableCell>{row.location.name}</TableCell>
                                        <TableCell>{row.type != null ? row.type.label : ''}</TableCell>
                                        <TableCell>{row.lightType != null ? row.lightType.label : ''}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(RoomList));
