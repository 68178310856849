import React from 'react';
import { QRCodeSVG } from 'qrcode.react';

export default React.memo((props) => {
     let { qrsize, value } = props;
     if(value == null) value = 'https://www.evoratechnologies.com/';
     // const evoraLogoPath = './evoraIcon.png';
     let size = null;
     switch(qrsize) {
          case 'sm':
               size = 45;
               break;
          case 'md':
               size = 75;
               break;
          case 'lg':
               size = 100;
               break;
          default:
               size = 45;
               break;
     }
     const imageSettings = {
          // src: evoraLogoPath
          width: size,
          height: size
     }

     let bgColour = '#FFF';
     let fgColour = '#000';

     return (
          <QRCodeSVG
               value={value}
               size={size}
               bgColor={bgColour}
               fgColor={fgColour}
               qrStyle='squares' //Dots or Squares
               includeMargin={false}
               // logoImage={evoraLogoPath}
               // logoWidth={90}
               // logoOpacity={0.45}
               imageSettings={imageSettings}
          />
     );
});