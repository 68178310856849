import React, { Component } from 'react';
import { connect } from 'react-redux';
import Hidden from '@mui/material/Hidden';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Top from '../../components/UI/AppBars/Top';
import Bottom from '../../components/UI/AppBars/Bottom';
import NavMenu from '../../components/Navigation/NavMenu/NavMenu';
import { LayoutToaster } from '../../components/UI/Toaster/Toaster';
import OptionMenu from '../../components/UI/OptionMenu/OptionMenu';
import IdleTimer from '../IdleTimer/IdleTimer';
import conversionHelper from '../../helpers/conversionHelper';
import IdleCheck from '../../components/General/IdleCheck';
import Lockscreen from '../../components/General/Lockscreen';
import { logoutUser } from '../../actions/authentication';
import { SCREEN_LOCK, BLUR_SCREEN } from '../../actions/types';
import Box from '@mui/material/Box';
import Labels from '../../components/UI/Buttons/Dialogs/Labels';
import ErrorFAB from '../../components/UI/FAB/Errors';

class Layout extends Component {
    constructor(props){
        super(props);
        this.state = {
            idleWarning: false
        }
    }

    componentDidMount = () => {
        if(this.props.auth.isAuthenticated && this.props.auth.constants != null) {
            this.timer = this.startTimer();
        }else if(!this.props.auth.isAuthenticated || this.props.auth.constants == null) {
            if(this.timer !== undefined && this.timer != null) this.timer.cleanUp();
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.auth.isAuthenticated !== this.props.auth.isAuthenticated) {
            if(this.props.auth.isAuthenticated  && this.props.auth.constants != null) {
                this.timer = this.startTimer();
            }else if(!this.props.auth.isAuthenticated) {
                if(this.timer !== undefined && this.timer != null) this.timer.cleanUp();
                this.logoutUser();
            }
        }
    }

    startTimer = () => {
        if(this.props.auth.constants != null){
            return new IdleTimer({
                idleWarning: this.state.idleWarning,
                idleWarningTimeout: conversionHelper.convertToMilliseconds(this.props.auth.user.sessionIdleWarningMinutes, this.props.auth.constants.timeUOMs.m, this.props.auth.constants),
                idleTimeout: conversionHelper.convertToMilliseconds(this.props.auth.user.sessionIdleMinutes, this.props.auth.constants.timeUOMs.m, this.props.auth.constants),
                expiry: conversionHelper.convertToMilliseconds(this.props.auth.user.sessionExpirationMinutes, this.props.auth.constants.timeUOMs.m, this.props.auth.constants),
                screenLock: this.props.lock.screenLock,
                onIdleWarning: () => {
                  if(!this.props.lock.screenLock && !this.state.idleWarning) {
                    this.props.dispatch({type: BLUR_SCREEN, payload: {blurScreen: true}});
                    this.setState({ idleWarning: true});
                  }
                },
                onIdleTimeout: () => {
                  if(this.state.idleWarning) {
                    this.setState({ idleWarning: false });
                  }
                  if(!this.props.lock.screenLock) {
                    this.props.dispatch({type: SCREEN_LOCK, payload: {screenLock: true}});
                    this.props.dispatch({type: BLUR_SCREEN, payload: {blurScreen: true}});
                  }
                },        
                onExpired: () => {
                    this.logoutUser();
                }
            })
        }
        return null;
    }

    toggleNavDrawer = () => {
        this.props.dispatch({ type: 'TOGGLE_NAV_DRAWER' });
    };
      
    toggleOptionMenu = () => {
        this.props.dispatch({ type: 'TOGGLE_OPTIONMENU' });
    };

    toggleIdleCheck = () => {
        this.setState({ idleWarning: false });
        this.props.dispatch({type: BLUR_SCREEN, payload: {blurScreen: false}});
    }
    
    toggleLockscreen = () => {
        this.props.dispatch({type: SCREEN_LOCK, payload: {screenLock: false}});
        this.props.dispatch({type: BLUR_SCREEN, payload: {blurScreen: false}});
    }
    
    logoutUser = () => {
        this.props.dispatch({type: SCREEN_LOCK, payload: {screenLock: false}});
        this.props.dispatch({type: BLUR_SCREEN, payload: {blurScreen: false}});
        this.setState({ idleWarning: false }, () => this.props.dispatch(logoutUser(window.history)));
    }

    render() {
        const auth = this.props.auth;
        const optionMenuState = this.props.optionMenu;
        const alert = this.props.alert;
        const blurScreen = this.props.lock.blurScreen;
        const navigation = this.props.navMenu.drawerOpen;
        const idleWarning = this.state.idleWarning;
        const screenLock = this.props.lock.screenLock;
        const DRAWERWIDTH = this.props.navMenu.drawerWidth;
                
        const contentStyle = {
            flex: "1 1 100%",  
            maxWidth: "100%",
            paddingTop: '145px',
            marginLeft: {xs: "auto", sm: "auto", md: 8, lg: 8},
            marginRight: 1,
            marginTop: 0,
            marginBottom: {xs: 10, sm: 10, md: 10, lg: 8}, //theme.spacing(10,10,10,8)
        };
        
        const contentShift = {
            flex: "1 1 100%",  
            maxWidth: "100%",
            paddingTop: '145px',
            marginLeft: {xs: "auto", sm: "auto", md: (DRAWERWIDTH + 8) + 'px', lg: (DRAWERWIDTH + 8) + 'px'},
            marginRight: 1,
            marginTop: 0,
            marginBottom: {xs: 4, sm: 6, md: 6, lg: 6}
        };
        
        const contentLogin = {
            flex: "1 1 100%",  
        };

        const optionMenu =  <OptionMenu
                                drawerWidth={DRAWERWIDTH}
                                open={optionMenuState.open}
                                clicked={this.toggleOptionMenu}
                                onOpen={this.toggleOptionMenu}
                            />;

        const navigationMenu =  <NavMenu
                                    drawerWidth={DRAWERWIDTH}
                                    open={navigation}
                                    clicked={this.toggleNavDrawer}
                                    user={auth.user}
                                />;
        const topBar = <Top 
                        drawerWidth={DRAWERWIDTH}
                        open={navigation}
                        clicked={this.toggleNavDrawer}
                        nodeEnv={this.props.auth.user.nodeEnv} 
                        />;

        const bottomBar = <Bottom 
                            open={navigation}
                            clicked={this.toggleNavDrawer}
                        />;
        
        const content = <Container maxWidth={false}>
                            {this.props.children}
                        </Container>;
        return (
            <Box sx={{
                display: "flow",
                minHeight: "100vh",
                maxWidth: '100vw',
                zIndex: 1,
                position: "relative",
                bgcolor: 'background.default',
                marginLeft: 'auto',
                marginRight: 'auto',
                filter: blurScreen ? "blur(25px)" : null
            }}>
              <CssBaseline />
              {auth.isAuthenticated && this.props.auth.constants != null ? topBar : null }
              {auth.isAuthenticated && this.props.auth.constants != null ? navigationMenu : null }
              {auth.isAuthenticated && this.props.auth.constants != null ? optionMenu : null }
              <Box sx={!auth.isAuthenticated ? contentLogin :
                              navigation ? contentShift : contentStyle }>
                {auth.isAuthenticated && this.props.auth.constants != null ? content : this.props.children }
                {/* {auth.isAuthenticated  && this.props.auth.constants != null? <Toaster alert={alert} /> : null } */}
                {auth.isAuthenticated && this.props.auth.constants != null && idleWarning ? 
                  <IdleCheck open={idleWarning} 
                             toggleDialog={() => this.toggleIdleCheck()} 
                             cancel={() => this.logoutUser()}
                             timeLeft={auth.user.sessionIdleWarningMinutes}
                             /> 
                : null }
                {auth.isAuthenticated && this.props.auth.constants != null && screenLock ?
                    <Lockscreen open={screenLock}
                                toggleDialog={() => this.toggleLockscreen()} />
                    : null
                }
              </Box>
              {auth.isAuthenticated && this.props.auth.constants != null && this.props.devices.openPrintDialog ? <Labels /> : null }
              {auth.isAuthenticated && this.props.auth.constants != null && <ErrorFAB /> }
              <LayoutToaster alert={alert} />
              {auth.isAuthenticated && this.props.auth.constants != null ? <Hidden mdUp>{bottomBar}</Hidden> : null }
            </Box>
          ); 
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    constants: state.auth.constants,
    alert: state.alert,
    optionMenu: state.optionMenu,
    lock: state.lock,
    navMenu: state.navMenu,
    devices: state.devices
});

export default connect(mapStateToProps)(Layout);