const batchPlantHelper = {

    determineStagePlantType(stage, constants){
        return determineStagePlantType(stage, constants);
    },

    determineStagePlant(strain, plantType, constants, items){
        return determineStagePlant(strain, plantType, constants, items);
    },

    determineStagePlantFull(strain, stage, constants, items){
        let stagePlantType = determineStagePlantType(stage, constants);
        if(stagePlantType != null){
            return determineStagePlant(strain, stagePlantType, constants, items);
        }
        return null;
    }
}

function determineStagePlantType(stage, constants){
    if(stage.plantType != null)
        return stage.plantType;
    else {
        let stageId = stage.stage._id != null ? stage.stage._id : stage.stage;
        if(stageId === constants.batchStages.prop)
            return constants.plantTypes.clone;
        else if (stageId === constants.batchStages.veg)
            return constants.plantTypes.veg;
        else if (stageId === constants.batchStages.mother)
            return constants.plantTypes.mother;
        else if (stageId === constants.batchStages.germination)
            return constants.plantTypes.seed;
        else if(stageId === constants.batchStages.flower || stageId === constants.batchStages.harvest)
            return constants.plantTypes.flower;
        else if(stageId === constants.batchStages.plantlet)
            return constants.plantTypes.plantlet;
        else if(stageId === constants.batchStages.rootlet)
            return constants.plantTypes.rootlet;
        else if(stageId === constants.batchStages.weanlet)
            return constants.plantTypes.weanlet;
    }
    return null;
}

function determineStagePlant(strain, plantType, constants, items){
    switch(plantType){
        case constants.plantTypes.seed:
            return strain.seedItem;
        case constants.plantTypes.clone: 
            return strain.cloneItem;
        case constants.plantTypes.veg:
            return strain.vegItem;
        case constants.plantTypes.flower:
            return strain.flowerItem;
        case constants.plantTypes.mother:
            return strain.motherItem;
        case constants.plantTypes.plantlet:
            if(items != null && items.length > 0){
                let item = items.find(x => x.category === constants.itemCategories.plantlet);
                if(item != null){
                    return item.value;
                }
            }
            return null;
        case constants.plantTypes.rootlet:
            if(items != null && items.length > 0){
                let item = items.find(x => x.category === constants.itemCategories.rootlet);
                if(item != null){
                    return item.value;
                }
            }
            return null;
        case constants.plantTypes.weanlet:
            if(items != null && items.length > 0){
                let item = items.find(x => x.category === constants.itemCategories.weanlet);
                if(item != null){
                    return item.value;
                }
            }
            return null;
        default:
            return null;
    }
}

export default batchPlantHelper;