import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { Link } from 'react-router-dom';
import ActionBar from '../UI/Buttons/ActionBar';
import isEmpty from '../../is-empty';
import { fieldSecurity } from '../../helpers/securityHelper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Box from '@mui/material/Box';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import SectionTitle from '../UI/Typography/SectionTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import axios from 'axios';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import ConfirmDelete from '../General/ConfirmDelete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const CleaningSolutionRecipe = (props) => {
    const { t } = useTranslation();
    const [values, setValues] = useState({items: [], uoms: []});
    const [fieldAccess, setFieldAccess] = useState(false);
    const [id, setId] = useState(null);
    const [form, setForm] = useState({item: '', itemSel: null, quantity: '1', percent: '', number: '', description : '', uom: '', uomSel: null, lines: []});
    const [loadRefData, setLoadRefData] = useState(true);
    const [enableSave, setEnableSave] = useState(true);
    const [errors, setErrors] = useState({});
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [showCopy, setShowCopy] = useState(false);
    const [newName, setNewName] = useState('');
    const security = props.permission;
    const [renderNum, setRenderNum] = useState(Math.random());

    const loadRecord = useCallback(async () => {
        if(id != null && id !== ''){
            const response = await axios.get('/api/cleaningrecipe/' + id);
            let lines = response.data.lines;
            for(let line of lines){
                line.itemSel = values.items.find(x => x.value === line.item);
                line.uomSel = values.uoms.find(x => x.value === line.uom);
            }
            let uomSel = values.uoms.find(x => x.value === response.data.uom);
            const fieldAccess = fieldSecurity(props.permission, null, props.auth.common, props.auth.constants);
            setFieldAccess(fieldAccess);
            setForm({
                lines: lines,
                number: response.data.number,
                description: response.data.description,
                uom: response.data.uom,
                uomSel: uomSel,
            })
            setRenderNum(Math.random());
            const crumbs = [
                { path: '/#/cleaningsolutionrecipes', label: props.t('cleaningSolutionRecipes'), screen: props.auth.screenDefs.CleaningSolutionRecipes},
                { path: 'active', label: (props.t('cleaningSolutionRecipes') + ' - ' + response.data.number), screen: props.auth.screenDefs.CleaningSolutionRecipe}
            ]
            props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        }
    }, [id, props, values]);

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.quantityUOM, common.cacheValues.cleanerItem], 
                props.auth.constants, null, props.auth.user.tenant);

            setValues({
                items: cacheValues.cleanerItems,
                uoms: cacheValues.quantityUOMs
            });
            setLoadRefData(false);
            if(props.match.params.id != null && props.match.params.id !== ''){
                setId(props.match.params.id);
            }
            const fieldAccess = fieldSecurity(props.permission, null, props.auth.common, props.auth.constants);
            setFieldAccess(fieldAccess);
            const crumbs = [
                { path: '/#/cleaningsolutionrecipes', label: t('cleaningSolutionRecipes'), screen: props.auth.screenDefs.CleaningSolutionRecipes},
                { path: 'active', label: t('cleaningSolutionRecipe'), screen: props.auth.screenDefs.CleaningSolutionRecipe}
            ]
            props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, loadRefData, form, t]);

    useEffect(() => {
        const callback = () => {
            if(props.match.params.id != null && props.match.params.id !== ''){
                loadRecord();
            }
        }
        callback();
    }, [props, loadRecord]);

    const save = () => {
        if(!enableSave){
            return;
        } else {
            setEnableSave(false);
            _save();
        }
    }

    const _save = async () => {
        let valid = validate();
        if(!valid){
            setEnableSave(true);
            return;
        }
        let vals = {
            _id: id,
            number: form.number,
            description: form.description,
            uom: form.uom,
            quantity: form.quantity,
            lines: form.lines
        }
        try {
            let result = await axios.post('/api/cleaningrecipe', vals);
            if(id == null || id === '')
                props.history.replace('/cleaningsolutionrecipe/' + result.data.id);
            setId(result.data.id);
            setEnableSave(true);
            loadRecord();
            props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            setEnableSave(true);
            props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    const validate = () => {
        let errors = {};
        if(form.description == null || form.description === '' )
            errors[t('description')] = t('required');
        if(form.quantity == null || form.quantity === '')
            errors[t('quantity')] = t('required');
        if(form.uom == null || form.uom === '')
            errors[t('uom')] = t('required');
        for(let i = 0; i < form.lines.length; i++){
            let line = form.lines[i];
            if(line.item == null || line.item === '')
                errors[`${t('item')} (${t('line')} ${i})`] = t('required');
            if(line.percent == null || line.percent === ''){
                if(line.quantity == null || line.quantity === '')
                    errors[`${t('quantity')} (${t('line')} ${i})`] = t('required');
                if(line.uom == null || line.uom === '')
                    errors[`${t('uom')} (${t('line')} ${i})`] = t('required');
            }
            if((line.percent == null || line.percent === '') && (line.quantity == null || line.quantity === ''))
                errors[`${t('percent')} (${t('line')} ${i})`] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    const defaultProps = {
        bgcolor: 'background.paper',
        m: 1,
        border: 1,
        style: {padding: '1rem'}
    };

    const changeValue = (e) => {
        setForm({...form, [e.target.name]: e.target.value});
    };

    const changeAuto = (name, data) => (e, newValue) => {
        setForm({...form, [data]: newValue != null ? newValue.value : null, [name]: newValue});
    };

    const changeLineAuto = (name, data, row) => (e, newValue) => {
        let lines = form.lines;
        let line = lines[row];
        line[name] = newValue;
        line[data] = newValue != null ? newValue.value : null;
        if(data === 'item'){
            if(newValue != null){
                line.uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                line.uom = newValue.uom;
            }else {
                line.uomSel = null;
                line.uom = '';
            }
        }
        setForm({...form, lines: lines});
    };

    const changeNumber = (row) => (e) => {
        let lines = form.lines;
        let line = lines[row];
        let name = e.target.name;
        let value = e.target.value
        if(isNaN(value)){
            return;
        }
        line[name] = value;
        setForm({...form, lines: lines});
    };

    const addLine = (e) => {
        let lines = form.lines;
        lines.push({});
        setForm({...form, lines: lines});
    };

    const deleteLine = (index) => (e) => {
        let lines = form.lines;
        lines.splice(index, 1);
        setForm({...form, lines: lines});
    };

    const deleteToggle = () =>{
        setDeleteOpen(!deleteOpen);
    }

    const deleteRecord = (e) => {
        if(!enableSave)
            return;
        setEnableSave(false);
        _deleteRecord(e);
    };

    const _deleteRecord = async (e) => {
        if(id != null && id !== ''){
            try {
                let result = await axios.delete('/api/cleaningrecipe', {params: {id: id}});
                setEnableSave(true);
                setDeleteOpen(false);
                props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                props.history.goBack();
            } catch(err){
                setEnableSave(true);
                setDeleteOpen(false);
                props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            setEnableSave(true);
            props.history.goBack();
        }
    };

    const copyClick = (e) => {
        setShowCopy(!showCopy);
    };

    const performCopy = (e) => {
        if(!enableSave)
            return;
        setEnableSave(false);
        _performCopy(e);
    };

    const _performCopy = async (e) => {
        if(newName == null || newName === ''){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: { [t('newName')]: t('required') }});
            setErrors({enableSave: true});
            return;
        }
        let data = {id: id, name: newName};
        try {
            let result = await axios.post('/api/cleaningrecipecopy', data);
            setEnableSave(true);
            closeCopy();
            props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            setEnableSave(true);
            props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    const closeCopy = (e) => {
        setShowCopy(false);
        setNewName('');
    };

    const changeNewName = (e) => {
        setNewName(e.target.value);
    };

    return(
        <Aux>
            <BreadcrumbBar>
                <ActionBar 
                    objectType={props.auth.constants.objectIds.cleaningSolutionRecipe}
                    id={id}
                    enableAttachments={id != null && id !== '' ? true : false}
                />
            </BreadcrumbBar>
            <Panel>
            <Grid container spacing={3}>
                    {form.number != null && form.number !== '' &&
                        <Grid item xs={6} sm={3}>
                            <FormControl data-cy="number">
                                <FormLabel>{t('number')}</FormLabel>
                                {form.number}
                            </FormControl>
                        </Grid>
                    }
                    <Grid item xs={6} sm={3}>
                        {fieldAccess &&
                            <TextField value={form.description} onChange={changeValue} name="description"
                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('description')} required data-cy="description"
                            error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                        }
                        {!fieldAccess &&
                            <FormControl data-cy="description">
                                <FormLabel>{t('description')}</FormLabel>
                                {form.description}
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <FormControl data-cy="quantity">
                            <FormLabel>{t('quantity')}</FormLabel>
                            {form.quantity}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        {fieldAccess &&
                            <AutoCompleteField
                                value={form.uomSel}
                                options={values.uoms}
                                onChange={changeAuto('uomSel', 'uom')}
                                error={errors[t('uom')] != null ? true : false}
                                helperText={errors[t('uom')]}
                                label={t('uom')}
                                disabled={!fieldAccess}
                                required
                                data-cy="uom"
                            />
                        }
                        {!fieldAccess &&
                            <FormControl>
                                <FormLabel>{t('uom')}</FormLabel>
                                {form.uomSel != null ? form.uomSel.label : ''}
                            </FormControl>
                        }
                    </Grid>
                </Grid>
                <br/>
                <SectionTitle title={t('inputs')}/>
                {form.lines.map((row, i) =>
                    <Box display="flex" borderColor="primary.main" {...defaultProps} key={row._id}>
                        <Grid container spacing={3} key={row._id}>
                            <Grid item xs={8} sm={4}>
                                {!fieldAccess &&
                                    <FormControl data-cy={'item' + i}>
                                        <FormLabel>{t('item')}</FormLabel>
                                        {row.itemSel != null &&
                                            <Link to={'/item/' + row.item}>{row.itemSel.label}</Link>
                                        }
                                    </FormControl>
                                }
                                {fieldAccess &&
                                    <AutoCompleteField
                                        value={row.itemSel}
                                        options={values.items}
                                        onChange={changeLineAuto('itemSel', 'item', i)}
                                        error={errors[`${t('item')} (${t('line')} ${i})`] != null ? true : false}
                                        helperText={errors[`${t('item')} (${t('line')} ${i})`]}
                                        label={t('item')}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy={'item' + i}
                                    />
                                }
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                {fieldAccess &&
                                    <TextField type="number" value={row.percent} onChange={changeNumber(i)} name="percent"
                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('percent')} data-cy={'percent' + i}
                                    error={errors[`${t('percent')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('percent')} (${t('line')} ${i})`]}/>
                                }
                                {!fieldAccess &&
                                    <FormControl data-cy={'percent' + i}>
                                        <FormLabel>{t('percent')}</FormLabel>
                                        {row.percent}
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                {fieldAccess &&
                                    <TextField type="number" value={row.quantity} onChange={changeNumber(i)} name="quantity"
                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} data-cy={'quantity' + i}
                                    error={errors[`${t('quantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('quantity')} (${t('line')} ${i})`]}/>
                                }
                                {!fieldAccess &&
                                    <FormControl data-cy={'quantity' + i}>
                                        <FormLabel>{t('quantity')}</FormLabel>
                                        {row.quantity}
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <FormControl>
                                    <FormLabel>UOM</FormLabel>
                                    {row.uomSel != null ? row.uomSel.label : ''}
                                </FormControl>
                            </Grid>
                            {fieldAccess &&
                                <Grid item sm={3}>
                                    <Button variant="outlined" color="primary" size="small" onClick={deleteLine(i)} data-cy={'deleteItem' + i}>{t('deleteLine')}</Button>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                )}
                {fieldAccess &&
                    <Grid container spacing={3}>
                        <Grid item xm={8}>
                            <Button variant="outlined" color="primary" size="small" onClick={addLine} data-cy="addLine">{t('addLine')}</Button>&nbsp;&nbsp;
                        </Grid>
                    </Grid>
                }
                <br/>
                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        {fieldAccess && security.update &&
                            <Aux>
                                <Button disabled={!enableSave} variant="contained" color="primary" size="small" onClick={save} data-cy="save">
                                    {t('save')}
                                </Button>&nbsp;&nbsp;
                            </Aux>
                        }
                        {security.delete &&
                            <Aux>
                                <Button variant="contained" color="secondary" size="small" onClick={deleteToggle} data-cy="delete">
                                    {t('delete')}
                                </Button>
                                &nbsp;&nbsp;
                            </Aux>
                        }
                        {security.update && id != null && id !== '' &&
                            <Button variant="contained" color="secondary" size="small" onClick={copyClick} data-cy="copy">{t('copy')}</Button>
                        }
                    </Grid>
                </Grid>
                {id != null && id !== '' &&
                    <Aux>
                        <Comments entityId={id} object={props.auth.constants.objectIds.cleaningSolutionRecipe}/>
                        <ObjectLog id={id} renderNum={renderNum}/>
                    </Aux>
                }
            </Panel>
            <Dialog open={showCopy} onClose={closeCopy} center maxWidth="sm" fullWidth>
                <DialogTitle>
                    <SectionTitle title={t('copy')}/>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            <TextField value={newName} onChange={changeNewName} name="newName"
                            size="medium" fullWidth={true} label={t('newDescription')} 
                            error={errors[t('newName')] != null ? true : false} helperText={errors[t('newName')]}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={performCopy}>{t('copy')}</Button>
                    <Button variant="contained" color="secondary" onClick={closeCopy}>{t('close')}</Button>
                </DialogActions>
            </Dialog>
            {deleteOpen &&
                <ConfirmDelete toggleDialog={() => deleteToggle()}
                    redirect={() => deleteRecord()} open={deleteOpen} />
            }
        </Aux>
    )

}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default connect(mapStateToProps)(CleaningSolutionRecipe);