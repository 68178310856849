import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import { SaveButton, ReturnButton } from '../UI/Buttons/Buttons';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import TextField from '../UI/TextField/TextField';
import { FormControl, FormLabel } from '@mui/material';
import { Alert } from '@mui/lab';
import { FormBuilder } from '@sop/react-forms-processor-formbuilder';
import {
  renderer as materialuiRenderer
} from '@sop/react-forms-processor-material-ui';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class ObjectScreen extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            name: '',
            text: '',
            link: '',
            processes: [],
            processesSel: [],
            processOptions: [],
            departments: [],
            departmentsSel: [],
            departmentOptions: [],
            canSchedule: false,
            canItemLedger: false,
            relatedTo: '',
            relatedToSel: null,
            relatedToOptions: [],
            security: [],
            enableSave: true,
            parameterList: [],
            documentParameters: [],
            documentParametersSel: [],
            canForm: '',
            exportName: '',
            form: '',
            formBuilderKey: 0,
            statusType: '',
            statusTypeSel: null,
            statusTypes: [],
            statusValues: [],
            statusValuesSel: [],
            statusOptions: []
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.department], this.props.auth.constants, null, this.props.auth.user.tenant);
        let types = await axios.get('/api/valuetypeselect');
        const values = await axios.get('/int/objectvalues');
        this.setState({
            processOptions: values.data.processes,
            departmentOptions: cacheValues.departments,
            relatedToOptions: values.data.relatedTos,
            security: this.props.permission,
            parameterList: values.data.parameters,
            statusTypes: types.data,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null
        }, () => {
            if(this.state._id != null){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/objects', label: 'Objects', screen: this.props.auth.screenDefs.ObjectList},
                    { path: 'active', label: 'Object', screen: this.props.auth.screenDefs.ObjectScreen}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/object/' + this.state._id);
        let processesSel = [];
        if(record.data.processes != null){
            for(let row of record.data.processes){
                let val = this.state.processOptions.find(x => x.value === row);
                processesSel.push(val);
            }
        }
        let departmentsSel = [];
        if(record.data.departments != null){
            for(let row of record.data.departments){
                let val = this.state.departmentOptions.find(x => x.value === row);
                departmentsSel.push(val);
            }
        }
        let documentParametersSel = [];
        if(record.data.documentParameters != null){
            for(let row of record.data.documentParameters){
                let val = this.state.parameterList.find(x => x.value === row);
                documentParametersSel.push(val);
            }
        }
        let relatedToSel = this.state.relatedToOptions.find(x => x.value === record.data.relatedTo);
        let statusTypeSel = record.data.statusType != null ? this.state.statusTypes.find(x => x.value === record.data.statusType) : null;
        let statusValuesSel = [];
        let statusOptions = [];
        if(record.data.statusType != null && record.data.statusType !== ''){
            let valueName = ValueHelper.getValueTypeKeyFromId(record.data.statusType);
            let statuses = await ValueHelper.getCachedValues([valueName], this.props.auth.constants, null, this.props.auth.user.tenant);
            let firstKey = Object.keys(statuses)[0];
            statusOptions = statuses[firstKey];
            if(record.data.statusValues != null && record.data.statusValues.length > 0){
                for(let row of record.data.statusValues){
                    let val = statusOptions.find(x => x.value === row);
                    statusValuesSel.push(val);
                }
            }
        }
        this.setState({
            name: record.data.name,
            link: record.data.link,
            text: record.data.text,
            processes: record.data.processes,
            processesSel: processesSel,
            departments: record.data.departments,
            departmentsSel: departmentsSel,
            canSchedule: record.data.canSchedule,
            canItemLedger: record.data.canItemLedger,
            relatedTo: record.data.relatedTo,
            relatedToSel: relatedToSel,
            documentParameters: record.data.documentParameters,
            documentParametersSel: documentParametersSel,
            canForm: record.data.canForm !== true ? false : true,
            exportName: record.data.exportName,
            form: record.data.form != null && record.data.form !== '' ? JSON.parse(record.data.form) : '',
            statusType: record.data.statusType,
            statusTypeSel: statusTypeSel,
            statusValues: record.data.statusValues,
            statusValuesSel: statusValuesSel,
            statusOptions: statusOptions
        }, () => {
            const crumbs = [
                { path: '/#/objects', label: 'Objects', screen: this.props.auth.screenDefs.ObjectList},
                { path: 'active', label: record.data.name, screen: this.props.auth.screenDefs.ObjectScreen}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        })
    }

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, async () => {
            if(data === 'statusType'){
                if(newValue != null){
                    let valueName = ValueHelper.getValueTypeKeyFromId(newValue.value);
                    let statuses = await ValueHelper.getCachedValues([valueName], this.props.auth.constants, null, this.props.auth.user.tenant);
                    var firstKey = Object.keys(statuses)[0];
                    this.setState({statusOptions: statuses[firstKey], statusValues: [], statusValuesSel: []});
                } else {
                    this.setState({statusOptions: [], statusValues: [], statusValuesSel: []});
                }
            }
        });
    };

    changeMulti = (name, data) => (e, newValue) => {
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false}, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        let errors = {};
        if(this.state.name == null || this.state.name === '')
            errors[t('name')] = t('required');
        if(this.state.text == null || this.state.text === '')
            errors[t('text')] = t('required');
        if(this.state.link == null || this.state.link === '')
            errors[t('link')] = t('required');
        if(!isEmpty(errors)){
            this.setState({errors: errors, enableSave: true}, () => {return});
        }
        let data = {
            _id: this.state._id,
            name: this.state.name,
            text: this.state.text,
            link: this.state.link,
            canSchedule: this.state.canSchedule,
            processes: this.state.processes,
            relatedTo: this.state.relatedTo,
            departments: this.state.departments,
            canItemLedger: this.state.canItemLedger,
            documentParameters: this.state.documentParameters,
            canForm: this.state.canForm,
            statusType: this.state.statusType,
            statusValues: this.state.statusValues,
            form: this.state.form != null && this.state.form !== '' ? JSON.stringify(this.state.form) : null
        };
        try {
            let result = await axios.post('/int/object', data);
            this.setState({ enableSave: true }, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
            });            
        }
    }

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    returnClick = (e) => {
        this.props.history.goBack();
    }

    onFormBuilderChange(value) {
        this.setState({form: value});
    }

    render(){
        const { errors, t } = this.props;
        const security = this.state.security;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.name} onChange={this.changeValue} name="name"
                            size="medium" fullWidth={true} label={t('name')} 
                            error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.text} onChange={this.changeValue} name="text"
                            size="medium" fullWidth={true} label={t('text')} 
                            error={errors[t('text')] != null ? true : false} helperText={errors[t('text')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.link} onChange={this.changeValue} name="link"
                            size="medium" fullWidth={true} label={t('link')} 
                            error={errors[t('link')] != null ? true : false} helperText={errors[t('link')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <FormControl>
                                <FormLabel>{t('exportName')}</FormLabel>
                                {this.state.exportName}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField
                            checked={this.state.canSchedule} onChange={this.changeBool('canSchedule')}
                            label={t('schedule')} name="canSchedule"
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField
                            checked={this.state.canItemLedger} onChange={this.changeBool('canItemLedger')}
                            label={t('itemLedger')} name="canItemLedger"
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField
                            checked={this.state.canForm} onChange={this.changeBool('canForm')}
                            label={t('form')} name="canForm"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.relatedToSel}
                                options={this.state.relatedToOptions}
                                onChange={this.changeAuto('relatedToSel', 'relatedTo')}
                                error={errors[t('relatedTo')] != null ? true : false}
                                helperText={errors[t('relatedTo')]} 
                                label={t('relatedTo')}
                            />
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.statusTypeSel}
                                options={this.state.statusTypes}
                                onChange={this.changeAuto('statusTypeSel', 'statusType')}
                                error={errors['Status Type'] != null ? true : false}
                                helperText={errors['Status Type']} 
                                label='Status Type'
                            />
                        </Grid>
                        {this.state.statusOptions != null && this.state.statusOptions.length > 0 &&
                            <Grid item sm={10}>
                                <AutoCompleteField
                                    multiple
                                    value={this.state.statusValuesSel}
                                    options={this.state.statusOptions}
                                    onChange={this.changeMulti('statusValuesSel', 'statusValues')}
                                    error={errors[t('statusValues')] != null ? true : false}
                                    helperText={errors[t('statusValues')]} 
                                    label='Status Values'
                                />
                            </Grid>
                        }
                        <Grid item sm={10}>
                            <AutoCompleteField
                                multiple
                                stayOpen
                                value={this.state.departmentsSel}
                                options={this.state.departmentOptions}
                                onChange={this.changeMulti('departmentsSel', 'departments')}
                                error={errors[t('departments')] != null ? true : false}
                                helperText={errors[t('departments')]} 
                                label={t('departments')}
                            />
                        </Grid>
                        <Grid item sm={10}>
                            <AutoCompleteField
                                multiple
                                stayOpen
                                value={this.state.processesSel}
                                options={this.state.processOptions}
                                onChange={this.changeMulti('processesSel', 'processes')}
                                error={errors[t('processes')] != null ? true : false}
                                helperText={errors[t('processes')]} 
                                label={t('processes')}
                            />
                        </Grid>
                        <Grid item sm={10}>
                            <AutoCompleteField
                                multiple
                                stayOpen
                                value={this.state.documentParametersSel}
                                options={this.state.parameterList}
                                onChange={this.changeMulti('documentParametersSel', 'documentParameters')}
                                error={errors[t('documentParameters')] != null ? true : false}
                                helperText={errors[t('documentParameters')]} 
                                label={t('documentParameters')}
                            />
                        </Grid>
                    </Grid>
                    {this.state.canForm === true &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('form')}/>
                            <Grid item sm={12}>
                                {errors[t('form')] &&
                                    <Alert variant="outlined" severity="error" sx={{ 'marginTop': '5px', 'marginBottom': '5px' }}>{errors[t('form')]}</Alert>
                                }
                                <FormBuilder
                                key={this.state.formBuilderKey}
                                renderer={materialuiRenderer}
                                value={this.state.form}
                                onChange={(value) => {
                                    this.onFormBuilderChange(value);
                                }}
                                editable={this.props.auth.user.internal}
                                />
                            </Grid>
                            <br/>
                        </Aux>
                    }
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            <SaveButton saveClick={this.save} enabled={this.state.enableSave} />
                            <ReturnButton returnClick={this.returnClick}/>
                        </Grid>
                    </Grid>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ObjectScreen));