import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import TextField from '../UI/TextField/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { withTranslation } from 'react-i18next';
import Signature from '../General/Signature';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import Unlock from '../General/Unlock';
import Document from '../General/Document';
import Aux from '../../hoc/Auxilary/Auxilary';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import Panel from '../UI/Panel/Panel';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import ModalDialog from '../UI/Dialog/ModalDialog';
import RichTextField from '../UI/RichTextField/RichTextField';
import { FormLabel, FormControl } from '@mui/material';
import DocumentDisplay from '../General/DocumentDisplay';
import FileUpload from './FileUpload';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import ConfirmDelete from '../General/ConfirmDelete';

class DraftDocument extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            name: '',
            number: '',
            type: '',
            typeSel: null,
            category: '',
            categorySel: null,
            categories: [],
            departments: [],
            depsSel: [],
            departmentList: [],
            status: '',   
            statusSel: null,
            statuses: [],
            version: '',
            managedDocuments: [],
            relatedDocuments: [],
            relatedSels: [],
            changeRequests: [],
            changeRequestsSel: [],
            changeRequestList: [],
            certificationMonths: '',
            security: {},
            author: null,
            signer: null,
            content: '',
            enableSave: true,
            format: '',
            formatSel: null,
            formats: [],
            internal: false,
            contentDocument: null,
            contentDocData: null,
            pageNumber: 1,
            numPages: null,
            location: '',
            locationSel: null,
            locations: [],
            deleteOpen: false,
            changeNotes: '',
            signed: false,
            errors: {}
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location, common.cacheValues.department, common.cacheValues.documentAuthor, 
            common.cacheValues.documentReviewer, common.cacheValues.documentCategory, common.cacheValues.documentFormat, common.cacheValues.documentStatus,
            common.cacheValues.documentType, common.cacheValues.managedDocumentTemplate, common.cacheValues.managedDocument], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        let statusSel = cacheValues.documentStatuses.find(x => x.value === this.props.auth.constants.documentStatuses.inprogress);
        let docs = cacheValues.managedDocuments;
        if(this.props.id != null && this.props.id !== ''){
            for(let i = 0; i < docs.length; i++){
                let doc = docs[i];
                if(doc.value === this.props.id){
                    docs.splice(i, 1);
                    break;
                }
            }
        }
        let locationSel = null;
        let location = '';
        if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
            locationSel = locations.find(x => x.value === this.props.auth.user.defaultLocation);
            location = this.props.auth.user.defaultLocation;
        } else {
            if(locations.length === 1){
                locationSel = locations[0];
                location = locationSel.value;
            }
        }
        this.setState({
            types: cacheValues.documentTypes,
            categories: cacheValues.documentCategories,
            statuses: cacheValues.documentStatuses,
            users: cacheValues.documentAuthors,
            qas: cacheValues.documentReviewers,
            departmentList: cacheValues.departments,
            managedDocuments: docs,
            _id: this.props.id != null && this.props.id !== '' ? this.props.id : null,
            status: this.props.auth.constants.documentStatuses.inprogress,
            statusSel: statusSel,
            security: this.props.permission,
            formats: cacheValues.documentFormats,
            locations: locations,
            location: location,
            locationsel: locationSel
        }, () => this.loadRecord());
    }

    async componentDidUpdate(prevProps, prevState){
        if (prevProps.id !== this.props.id && this.props.id != null && this.props.id !== '') {
            this.loadRecord();
          }
    }

    loadRecord = async () => {
        const t = this.props.t;
        if(this.props.id != null && this.props.id !== ''){
            let record = await axios.get('/api/manageddocdraft/' + this.props.id);
            let typeSel = this.state.types.find(x => x.value === record.data.type);
            let formatSel = this.state.formats.find(x => x.value === record.data.format);
            let categorySel = record.data.category != null ? this.state.categories.find(x => x.value === record.data.category) : null;
            let statusSel = this.state.statuses.find(x => x.value === record.data.status);
            let locationSel = this.state.locations.find(x => x.value === record.data.location);
            let depsSel = [];
            if(record.data.departments != null && record.data.departments.length > 0){
                record.data.departments.forEach(row => {
                    let sel = this.state.departmentList.find(x => x.value === row);
                    depsSel.push(sel);
                });
            }
            let relatedSels = [];
            if(record.data.relatedDocuments != null && record.data.relatedDocuments.length > 0){
                record.data.relatedDocuments.forEach(row => {
                    let val = this.state.managedDocuments.find(x => x.value === row);
                    relatedSels.push(val);
                });
            }
            let changeRequestsSel = [];
            if(record.data.changeRequests != null && record.data.changeRequests.length > 0){
                record.data.changeRequests.forEach(row => {
                    let sel = record.data.changeRequestList.find(x => x.value === row);
                    changeRequestsSel.push(sel);
                });
            }
            let signed = record.data.status !== this.props.auth.constants.documentStatuses.inprogress;
            let locked = record.data.status === this.props.auth.constants.documentStatuses.approved;

            const optionsMenu = [
                {
                    users: this.state.users, name: t('author'), status: this.state.status, title: t('authorSignature'),
                    setId: () => this.loadRecord(), object: this.state._id, signature: this.state.author, user: this.props.auth.user.id,
                    objectType: this.props.auth.constants.objectIds.managedDocumentChange, type: this.props.auth.constants.signatureTypes.user
                },
                {
                    users: this.state.qas, name: t('approver'), status: this.state.status, user: this.props.auth.user.id,
                    title: t('approverSignature'), setId: () => this.loadRecord(), object: this.state._id, signature: this.state.signer,
                    objectType: this.props.auth.constants.objectIds.managedDocumentChange, type: this.props.auth.constants.signatureTypes.signer
                },
                {
                    menuType: this.props.auth.constants.optionMenuTypes.comments
                },
                {
                    menuType: this.props.auth.constants.optionMenuTypes.logs
                }
            ]
            const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                     record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                     record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
            this.setState({
                name: record.data.name,
                number: record.data.number,
                type: record.data.type,
                typeSel: typeSel,
                category: record.data.category,
                categorySel: categorySel,
                status: record.data.status,
                statusSel: statusSel,
                version: record.data.version,
                links: record.data.links,
                documents: record.data.documents,
                author: record.data.author,
                signer: record.data.signer,
                signed: signed,
                locked: locked,
                changeRequests: record.data.changeRequests,
                changeRequestsSel: changeRequestsSel,
                changeRequestList: record.data.changeRequestList,
                relatedSels: relatedSels,
                relatedDocuments: record.data.relatedDocuments,
                departments: record.data.departments,
                depsSel: depsSel,
                certificationMonths: record.data.certificationMonths,
                optionsMenu: optionsMenu,
                flagForSignature: flagForSignature,
                content: record.data.content,
                format: record.data.format,
                formatSel: formatSel,
                internal: record.data.format === this.props.auth.constants.documentFormat.internal ? true : false,
                contentDocument: record.data.contentDocument,
                location: record.data.location,
                locationSel: locationSel,
                deleteOpen: false,
                changeNotes: record.data.notes
            }, async () => {
                this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: 'Options'}});
            });
        }
    };

    changeValue = (e) => {
        let name = e.target.name;
        let value = e.target.value
        if(name === "version"){
            if(isNaN(value)){
                return;
            }
        }
        this.setState({[name]: value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    changeAutoComplete = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeRelatedDocs = (e, newValue) => {
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            relatedSels: newValue,
            relatedDocuments: ids
        })
    };

    changeMultiAuto = (name, data) => (e, newValue) => {
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            [name]: newValue,
            [data]: ids
        })
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async (e) => {
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }            
        let data = {
            _id: this.state._id,
            name: this.state.name,
            number: this.state.number,
            type: this.state.type,
            category: this.state.category,
            departments: this.state.departments,
            status: this.state.status,
            version: this.state.version,
            links: this.state.links,
            relatedDocuments: this.state.relatedDocuments,
            changeRequests: this.state.changeRequests,
            certificationMonths: this.state.certificationMonths,
            content: this.state.content,
            format: this.state.format,
            location: this.state.location,
            contentDocument: this.state.contentDocument,
            changeNotes: this.state.changeNotes
        };
        try {
            let result = await axios.post('/api/manageddocdraft', data);
            this.setState({ enableSave: true }, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        }
    };

    validate = (final) => {
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.name == null || state.name === '')
            errors[t('name')] = t('required');
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(state.status == null || state.status === '')
            errors[t('status')] = t('required');
        if(state.version == null || state.version === '')
            errors[t('version')] = t('required');
        if(state.location == null || state.locatoin === '')
            errors[t('location')] = t('required');
        if(final === true && this.state.format === this.props.auth.constants.documentFormat.internal){
            if(this.state.content == null || this.state.content === '')
                errors[t('content')] = t('required');
        }
        if(final === true && this.state.format !== this.props.auth.constants.documentFormat.internal){
            if(this.state.contentDocument == null)
                errors[t('contentDocument')] = t('required');
        }
        if(state.departments == null || state.departments.length === 0)
            errors[t('departments')] = t('required');
        if(state.format == null || state.format === '')
            errors[t('format')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    newLink = (e) => {
        this.setState({editLink: true});
    };

    clearLink = (e) => {
        this.setState({
            editLink: false,
            linkIndex: -1,
            linkName: '',
            linkLink: ''
        });
    };

    editLink(index){
        let row = this.state.links[index];
        this.setState({
            linkIndex: index,
            editLink: true,
            linkName: row.name,
            linkLink: row.link
        })
    };

    saveLink = (e) => {
        const valid = this.validateLink();
        if(!valid)
            return;
        let links = this.state.links;
        if(this.state.linkIndex > -1){
            let link = links[this.state.linkIndex];
            link.name = this.state.linkName;
            link.link = this.state.linkLink;
        } else {
            let link = {
                name: this.state.linkName,
                link: this.state.linkLink
            };
            links.push(link);
        }
        this.setState({links: links});
        this.clearLink();
    };

    validateLink(){
        let errors = {};
        const t = this.props.t;
        if(this.state.linkName == null || this.state.linkName === '')
            errors[t('linkName')] = t('required');
        if(this.state.linkLink == null || this.state.linkLink === '')
            errors[t('linkLink')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteLink = (e) => {
        if(this.state.linkIndex != null && this.state.linkIndex > -1){
            let links = this.state.links;
            links.splice(this.state.linkIndex, 1);
            this.setState({links: links});
        }
        this.clearLink();
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteDraft = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._deleteDraft(e);
        });
    }

    _deleteDraft = async (e) => {
        let data = {_id: this.props.id};
        try {
            let result = await axios.post('/api/manageddocdeletedraft', data);
            this.setState({enableSave: true, deleteOpen: false});
            this.props.reload();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }

    };

    publishDraft = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._publishDraft(e);
        });
    }

    _publishDraft = async (e) => {
        let data = {_id: this.props.id};
        try {
            let result = await axios.post('/api/manageddocpublish', data);
            this.setState({enableSave: true});
            this.props.reload();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    changeRichText = (type) => async (index, newText, e) => {
        let content = newText
        this.setState({ [type]: content });    
    }

    render(){
        const { t } = this.props;
        const errors = this.props.errors;
        const signed = this.state.signed;
        const internal = this.state.internal;
        return(
            <Grid container spacing={1}>
                <Panel>
                    <Grid item container xs={12} spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.statusSel != null ? this.state.statusSel.label : ''}  name="status"
                            size="medium" fullWidth={true} disabled={true} label={t('status')}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.number} onChange={this.changeValue} name="number"
                            error={errors[t('number')] != null ? true : false} inputProps={{maxLength: 20}}
                            size="medium" disabled={signed} helperText={errors[t('number')]} 
                            label={t('number')}/>
                        </Grid>
                        <Grid item xs={6} md={4} xl={2}>
                            <AutoCompleteField
                                value={this.state.locationSel}
                                options={this.state.locations}
                                onChange={this.changeAutoComplete('locationSel', 'location')}
                                error={errors[t('location')] != null ? true : false}
                                helperText={errors[t('location')]} 
                                label={t('location')}
                                disabled={signed}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} xl={2}>
                            <AutoCompleteField
                                value={this.state.formatSel}
                                options={this.state.formats}
                                onChange={this.changeAutoComplete('formatSel', 'format')}
                                error={errors[t('format')] != null ? true : false}
                                helperText={errors[t('format')]} 
                                label={t('format')}
                                disabled={signed}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} xl={2}>
                            <AutoCompleteField
                                value={this.state.typeSel}
                                options={this.state.types}
                                onChange={this.changeAutoComplete('typeSel', 'type')}
                                error={errors[t('type')] != null ? true : false}
                                helperText={errors[t('type')]}
                                label={t('type')}
                                disabled={signed}
                            />
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <TextField value={this.state.name} onChange={this.changeValue} name="name"
                            error={errors[t('name')] != null ? true : false} inputProps={{maxLength: 50}}
                            size="medium" fullWidth={true} disabled={signed} helperText={errors[t('name')]} 
                            label={t('name')}/>
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <TextField value={this.state.version} onChange={this.changeValue} name="version"
                            error={errors[t('version')] != null ? true : false}
                            size="medium" fullWidth={true} disabled={true} helperText={errors[t('version')]}
                            label={t('version')}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.certificationMonths} onChange={this.changeNumber} name="certificationMonths"
                            error={errors[t('version')] != null ? true : false}
                            size="medium" fullWidth={true} disabled={signed} helperText={errors[t('certificationMonths')]} 
                            label={t('certMonths')}/>
                        </Grid>
                        <Grid item xs={11}>
                            <AutoCompleteField
                                multiple={true}
                                stayOpen={true}
                                value={this.state.depsSel}
                                options={this.state.departmentList}
                                onChange={this.changeMultiAuto('depsSel', 'departments')}
                                error={errors[t('departments')] != null ? true : false}
                                helperText={errors[t('departments')]}
                                label={t('departments')}
                                disabled={signed}
                            />
                        </Grid>
                        <Grid item xs={11}>
                            <AutoCompleteField
                                multiple={true}
                                value={this.state.changeRequestsSel}
                                options={this.state.changeRequestList}
                                onChange={this.changeMultiAuto('changeRequestsSel', 'changeRequests')}
                                error={errors[t('changeRequests')] != null ? true : false}
                                helperText={errors[t('changeRequests')]}
                                label={t('changeRequests')}
                                disabled={signed}
                            />
                        </Grid>
                        <Grid item xs={5} md={4} xl={2}>
                            <AutoCompleteField
                                value={this.state.categorySel}
                                options={this.state.categories}
                                onChange={this.changeAutoComplete('categorySel', 'category')}
                                error={errors[t('category')] != null ? true : false}
                                helperText={errors[t('category')]}
                                label={t('category')}
                                disabled={signed}
                            />
                        </Grid>
                        <Grid item sm={11}>
                            <AutoCompleteField
                                multiple={true}
                                stayOpen={true}
                                value={this.state.relatedSels}
                                options={this.state.managedDocuments}
                                onChange={this.changeRelatedDocs}
                                error={errors[t('relatedDocuments')] != null ? true : false}
                                helperText={errors[t('relatedDocuments')]}
                                label={t('relatedDocuments')}
                                disabled={signed}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={3}>
                        <Grid item xs={12}>
                            <SubSectionHeading add={this.newLink} addTitle={t('addLink')} addSecurity={signed} title={t('links')}/>
                            {this.state.links != null && this.state.links.length > 0 &&
                                <Aux>
                                    <TableContainer>
                                        <Table aria-label="Links">
                                            <TableHead>
                                                <TableRow key="tableHeader">
                                                    <TableCell>{t('name')}</TableCell>
                                                    <TableCell>{t('linkUrl')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.links.map((row, i) =>
                                                    <TableRow key={row._id}>
                                                        <TableCell>
                                                            <TextOnlyClickButton enabled={true} click={() => this.editLink(i)} name={row.name}/>
                                                        </TableCell>
                                                        <TableCell>{row.link}</TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Aux>
                            }
                            <ModalDialog 
                                buttonStack={[
                                    <Button color="error" size="large" onClick={this.deleteLink}>{t('delete')}</Button>,
                                    <Button color="secondary" size="large" onClick={this.clearLink}>{t('clear')}</Button>,
                                    <Button color="primary" size="large" onClick={this.saveLink}>{t('save')}</Button>
                                ]} 
                                title={t('addLink')} toggleDialog={this.clearLink} dialogStatus={this.state.editLink} fullWidth maxWidth='md'>
                                <Grid container spacing={3}>
                                    <Grid item xs={5}>
                                        <TextField value={this.state.linkName} onChange={this.changeValue} name="linkName"
                                        error={errors[t('linkName')] != null ? true : false} label={t('name')}
                                        size="medium" fullWidth={true} disabled={signed} helperText={errors[t('linkName')]}/>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <TextField value={this.state.linkLink} onChange={this.changeValue} name="linkLink"
                                        error={errors[t('linkLink')] != null ? true : false} label={t('linkUrl')}
                                        size="medium" fullWidth={true} disabled={signed} helperText={errors[t('linkLink')]}/>
                                    </Grid>
                                </Grid>
                            </ModalDialog>
                            <br />
                        </Grid>                    
                    </Grid>
                    {internal === true &&
                        <Grid item container xs={12} spacing={3}>
                            <Grid item xs={12}>
                                <SubSectionHeading title={t('documentContent')} />
                                <RichTextField
                                    disabled={signed}
                                    id='documentContent'
                                    key='documentContent'
                                    index={0}
                                    value={this.state.content != null ? this.state.content : ''}
                                    errors={errors}
                                    textAreaName='documentContent-richText'
                                    height={900}
                                    changeRichText={this.changeRichText('content')}
                                />
                            </Grid> 
                        </Grid>
                    }
                    {internal === false &&
                        <Aux>
                            <SubSectionHeading title={t('contentDocument')}/>
                            {this.state.contentDocument != null &&
                                <Aux>
                                    <br/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={10} sm={5}>
                                            <FormControl>
                                                <FormLabel>{this.state.contentDocument.name}</FormLabel>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Aux>
                            }
                            {!signed && this.state._id != null && this.state._id !== '' &&
                                <FileUpload callback={this.loadRecord} 
                                    record={this.state._id}
                                    _id={this.state.contentDocument != null ? this.state.contentDocument._id : ''}
                                    version={this.state.contentDocument != null ? this.state.contentDocument.version : ''}
                                    property='contentDocument'
                                    object={this.props.auth.constants.objectIds.managedDocumentChange}
                                />
                            }
                            <br/>
                            <br/>
                            {this.state.contentDocument != null && this.state.contentDocument._id != null &&
                                <Aux>
                                    <DocumentDisplay document={this.state.contentDocument}/>
                                </Aux>
                            }
                        </Aux>
                    }
                    <br />
                    <Grid item container xs={12} spacing={3}>
                        <Grid item xs={12}>
                            <SubSectionHeading title={t('changeNotes')} />
                            <RichTextField
                                disabled={signed}
                                id='changeNotes'
                                key='changeNotes'
                                index={0}
                                value={this.state.changeNotes != null ? this.state.changeNotes : ''}
                                errors={errors}
                                textAreaName='changeNotes-richText'
                                height={200}
                                changeRichText={this.changeRichText('changeNotes')}
                            />
                        </Grid> 
                    </Grid>
                    <br/>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                        <Signature users={this.state.users} name={t('author')} status={this.state.status} validate={this.validate}
                            title={t('authorSignature')} setId={this.loadRecord} object={this.state._id} signature={this.state.author}
                            objectType={this.props.auth.constants.objectIds.managedDocumentChange} type={this.props.auth.constants.signatureTypes.user}
                            user={this.props.auth.user.id}
                        />
                        <br/>
                        </Aux>
                    }
                    {(this.state.status === this.props.auth.constants.documentStatuses.complete ||
                        this.state.status === this.props.auth.constants.documentStatuses.approved) &&
                        <Aux>
                            <Signature users={this.state.qas} name={t('reviewer')} status={this.state.status} validate={this.validate}
                                title={t('reviewerSignature')} setId={this.loadRecord} object={this.state._id} signature={this.state.signer}
                                objectType={this.props.auth.constants.objectIds.managedDocumentChange} type={this.props.auth.constants.signatureTypes.signer}
                                user={this.props.auth.user.id}/>
                            <br/>
                        </Aux>
                    }
                    {this.state._id != null && this.state._id !== '' &&
                        <Document record={this.state._id} documents={this.state.documents}
                        objectType={this.props.auth.constants.objectIds.managedDocumentChange} callback={this.loadRecord}/>
                    }
                    <Grid item container xs={12} spacing={3}>
                        <Grid item sm={6}>
                            {!signed &&
                                <Aux>
                                    <Button variant="contained" color="primary" size="small" onClick={this.save}>{t('save')}</Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            <Button variant="contained" color="secondary" size="small" onClick={this.deleteToggle}>{t('delete')}</Button>&nbsp;&nbsp;
                            {signed &&
                                <Aux>
                                    <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.managedDocumentChange} 
                                    callback={this.loadRecord} isDocument={true}/>&nbsp;&nbsp;
                                </Aux>
                            }
                            {signed &&
                                <Button variant="contained" color="secondary" size="small" onClick={this.publishDraft}>{t('publish')}</Button>
                            }
                        </Grid>
                    </Grid>
                </Panel>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteDraft()} open={this.state.deleteOpen} />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.managedDocumentChange}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation()(connect(mapStateToProps)(DraftDocument));
