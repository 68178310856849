import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useSelector } from 'react-redux';
import isEmpty from '../../../is-empty';

export default function ErrorFAB(props) {
  const errors = useSelector(state => state.errors.list);
  const display = isEmpty(errors) ? 'none' : 'block';

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
        setAnchorEl(null);
    }, 5000);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const button = () => {
    return (
        <div>
          <Fab color="error" variant='circular' aria-label="Errors found" {...props} 
               sx={{ 
                  zIndex: 1000, 
                  position: 'fixed', 
                  bottom: {xs: 85, md: 40}, 
                  right: 40,
                  display: display,
               }}
               onClick={handleClick}
          >
            <ErrorRoundedIcon fontSize="large" />
          </Fab>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
            <List sx={{ width: 500 }}>
                {Object.keys(errors).map((key, index) => (
                <ListItem key={index}>
                    <strong>{key}:</strong>&nbsp;{errors[key]}
                </ListItem>
                ))}
            </List>
          </Popover>
        </div>
      );
  }

  if(!isEmpty(errors)) {
    return button();
  }else
    return null;
  
}