import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import Link from '../UI/Link/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DateTimePicker from '../UI/DateTimeSelect/DateTimeSelect';
import TextField from '../UI/TextField/TextField';
import AutoComplete from '@mui/lab/Autocomplete';
import Panel from '../UI/Panel/Panel';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import { approveSigSecurity, completeSigSecurity, 
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { AddBlankButton, ClearButton, TextOnlyClickButton,
         DeleteButton, SaveButton } from '../UI/Buttons/Buttons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Fieldset from '../UI/Fieldset/Fieldset';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class TaskRiskAssessment extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            objectType: '',
            sop: '',
            location: '',
            locations: [],
            date: new Date(),
            user: '',
            users: [],
            reviewer: '',
            managers: [],
            signer: '',
            qas: [],
            schedule: '',
            objectId: '',
            activities: [],
            activityOptions: [],
            equipment: [],
            equipmentOptions: [],
            concerns: [],
            concernOptions: [],
            ppes: [],
            ppeOptions: [],
            lifeCriticals: [],
            lifeCriticalOptions: [],
            safetyMaterialRequired: false,
            safetyMaterialAvailable: false,
            employees: [],
            description: '',
            tasks: [],
            editTask: false,
            step: '',
            hazards: '',
            actions: '',
            index: -1,
            objects: [],
            sops: [],
            isSop: false,
            status: this.props.auth.constants.statuses.new,
            statuses: [],
            statusSel: {value: '', label: ''},
            statusString: '',
            tenant: this.props.auth.user.tenant,
            number: '',
            locationSel: null,
            objectTypeSel: null,
            equipmentSels: [],
            activitiesSel: [],
            concernsSel: [],
            ppesSel: [],
            lifeCritsSel: [],
            employeesSel: [],
            fieldAccess: false, 
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            security: [],
            attached: false,
            attachedObjectType: '',
            attachedObjectId: '',
            attachedLink: null,
            optionsMenu: [],
            flagForSignature: 0,
            enableSave: true
        };
    }

    async componentDidMount(){
        const values = await axios.get('/api/taskriskvalues');
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ? 
            this.props.auth.user.defaultLocation : '';
        let locSel = location !== '' ? values.data.locations.find(x => x.value === location) : null;
        if(locSel == null && values.data.locations.length === 1){
            locSel = values.data.locations[0];
            location = locSel.value;
        }
        const statusSel = values.data.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        const statusString = statusSel.label;
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        this.setState({
            activityOptions: values.data.activities,
            concernOptions: values.data.hseConcerns,
            equipmentOptions: values.data.equipments,
            ppeOptions: values.data.specialPpes,
            lifeCriticalOptions: values.data.lifeCriticals,
            users: values.data.users,
            managers: values.data.managers,
            qas: values.data.qas,
            locations: values.data.locations,
            objectTypes: values.data.objects,
            sops: values.data.sops,
            statuses: values.data.statuses,
            signed: false,
            statusSel: statusSel,
            statusString: statusString,
            fieldAccess: fieldAccess,
            security: this.props.permission,
            location: location,
            locationSel: locSel,
            _id: this.props.match != null && this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : this.props.id != null && this.props.id !== '' ? 
                this.props.id : ''
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord(values.data);
            }else {
                const crumbs = [
                    { path: '/#/taskrisks', label: this.props.t('riskAssessments'), screen: this.props.auth.screenDefs.TaskRiskAssessments},
                    { path: 'active', label: this.props.t('riskAssessment'), screen: this.props.auth.screenDefs.TaskRiskAssessment}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    async loadRecord(values){
        const t = this.props.t;
        const id = this.state._id != null && this.state._id !== '' ? this.state._id : 
            this.props.match != null && this.props.match.params.id != null ? this.props.match.params.id : this.props.id;
        const record = await axios.get('/api/taskrisk/' + id);
        const isSop = record.data.objectType === this.props.auth.constants.objectIds.sopInstance;
        const locations = values != null ? values.locations : this.state.locations;
        const locationSel = locations.find(x => x.value === record.data.location);
        const equipmentOptions = values != null ? values.equipments : this.state.equipmentOptions;
        const equipmentSels = [];
        for(let i = 0; i < record.data.equipment.length; i++){
            const equipment = record.data.equipment[i];
            const equipmentSel = equipmentOptions.find(x => x.value === equipment);
            equipmentSels.push(equipmentSel);
        }
        const activityOptions = values != null ? values.activities : this.state.activityOptions;
        let activitiesSel = [];
        for(let i = 0; i < record.data.activities.length; i++){
            const activity = activityOptions.find(x => x.value === record.data.activities[i]);
            activitiesSel.push(activity);
        }
        const concernOptions = values != null ? values.hseConcerns : this.state.concernOptions;
        let concernsSel = [];
        for(let i = 0; i < record.data.hseConcerns.length; i++){
            const concern = concernOptions.find(x => x.value === record.data.hseConcerns[i]);
            concernsSel.push(concern);
        }
        const ppeOptions = values != null ? values.specialPpes : this.state.ppeOptions;
        let ppesSel = [];
        for(let i = 0; i < record.data.specialPpes.length; i++){
            const ppe = ppeOptions.find(x => x.value === record.data.specialPpes[i]);
            ppesSel.push(ppe);
        }
        const lifeCritOptions = values != null ? values.lifeCriticals : this.state.lifeCriticalOptions;
        let lifeCritsSel = [];
        for(let i = 0; i < record.data.lifeCriticals.length; i++){
            const sel = lifeCritOptions.find(x => x.value === record.data.lifeCriticals[i]);
            lifeCritsSel.push(sel);
        }
        const users = values != null ? values.users : this.state.users;
        let employeesSel = [];
        for(let i = 0; i < record.data.employees.length; i++){
            const emp = users.find(x => x.value === record.data.employees[i]);
            employeesSel.push(emp);
        }
        const objectTypes = values != null ? values.objects : this.state.objectTypes;
        const objectTypeSel = objectTypes.find(x => x.value === record.data.objectType);
        const sops = values != null ? values.sops : this.state.sops;
        const sopSel = sops.find(x => x.value === record.data.sop);
        const statuses = values != null ? values.statuses : this.state.statuses;
        const status = record.data.status != null ? record.data.status : this.props.auth.constants.statuses.inprogress;
        const statusSel = statuses.find(x => x.value === status);
        const signed = record.data.status === this.props.auth.constants.statuses.reviewed || 
            record.data.status === this.props.auth.constants.statuses.signed;
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const attachmentType = objectTypes.find(x => x.value === record.data.attachedObjectType);
        const attachmentTypes = [
            { label: this.props.t('serviceRequest'), path: '/#/maintenancerequest/' },
        ];
        let link = null;
        if(record.data.attached) {
            link = attachmentTypes.find(x => x.value === record.data.attachedObjectType.label);
        }
        const optionsMenu = [
            { 
                security: completeSigSec, user: this.props.auth.user.id, users: this.state.users, name: t('complete'), 
                title: t('completedBy'), setId: () => this.loadRecord(), signature: record.data.user, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.taskRisk, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, 
            },
            { 
                security: reviewSigSec, user: this.props.auth.user.id, users: this.state.managers, name: t('review'),
                title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id, 
                objectType: this.props.auth.constants.objectIds.taskRisk, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: this.state.qas, name: t('approve'),
                title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer, 
                objectType: this.props.auth.constants.objectIds.taskRisk, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        this.setState({
            _id: record.data._id,
            location: record.data.location,
            date: record.data.date,
            user: record.data.user,
            equipment: record.data.equipment,
            activites: record.data.activities,
            concerns: record.data.hseConcerns,
            ppes: record.data.specialPpes,
            lifeCriticals: record.data.lifeCriticals,
            safetyMaterialAvailable: record.data.safetyMaterialRequired,
            safetyMaterialRequired: record.data.safetyMaterialRequired,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            activities: record.data.activities,
            locationSel: locationSel,
            equipmentSels: equipmentSels,
            activitiesSel: activitiesSel,
            concernsSel: concernsSel,
            ppesSel: ppesSel,
            lifeCritsSel: lifeCritsSel,
            employeesSel: employeesSel,
            description: record.data.description,
            tasks: record.data.tasks,
            objectType: record.data.objectType,
            objectId: record.data.objectId,
            objectTypeSel: objectTypeSel,
            isSop: isSop,
            sop: record.data.sop,
            sopSel: sopSel,
            status: record.data.status,
            statusString: statusSel.label,
            signed: signed,
            number: record.data.number,
            employees: record.data.employees,
            statusSel: statusSel,
            fieldAccess: fieldAccess, 
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            attached: record.data.attached,
            attachedObjectType: attachmentType,
            attachedObjectId: record.data.attachedObjectId,
            attachedLink: link,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature
        }, () => {
            const crumbs = [
                { path: '/#/taskrisks', label: this.props.t('riskAssessments'), screen: this.props.auth.screenDefs.TaskRiskAssessments},
                { path: 'active', label: this.props.t('riskAssessment') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.TaskRiskAssessment}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    }

    changeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    dateChange = e => {
        this.setState({date: e});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(data === 'objectType'){
            if(newValue != null && newValue.value === this.props.auth.constants.objectIds.sopInstance){
                this.setState({isSop: true});
            } else {
                this.setState({isSop: false});
            }
        }
    };

    changeMulti = (name, data) => value => {
        let vals = [];
        value.forEach(val => {
            vals.push(val.value);
        });
        this.setState({[name]: value, [data]: vals});
    };

    changeAutoMulti = (name, data) => (e, newValue) => {
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            [name]: newValue,
            [data]: ids
        })
    };

    requiredChange = e => {
        this.setState({safetyMaterialRequired: !this.state.safetyMaterialRequired});
    };

    availableChange = e => {
        this.setState({safetyMaterialAvailable: !this.state.safetyMaterialAvailable});
    };

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async e => {
        const valid = this.validate();
        let status = this.state.status;
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        if( status === this.props.auth.constants.statuses.new ){
            status = this.props.auth.constants.statuses.inprogress;
        }
        const data = {
            _id: this.state._id,
            location: this.state.location,
            date: this.state.date,
            objectType: this.state.objectType,
            sop: this.state.sop,
            equipment: this.state.equipment,
            activities: this.state.activities,
            concerns: this.state.concerns,
            ppes: this.state.ppes,
            lifeCriticals: this.state.lifeCriticals,
            safetyMaterialAvailable: this.state.safetyMaterialAvailable,
            safetyMaterialRequired: this.state.safetyMaterialRequired,
            employees: this.state.employees,
            description: this.state.description,
            tasks: this.state.tasks,
            status: status
        }
        try {
            const result = await axios.post('/api/taskrisk', data);
            if(this.props.setId != null) this.props.setId(result.data.id);
            const statusSel = this.state.statuses.find(x => x.value === result.data.status);
            const completeSigSec = completeSigSecurity(this.props.permission.complete, result.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
            const reviewSigSec = reviewSigSecurity(this.props.permission.review, result.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
            const approveSigSec = approveSigSecurity(this.props.permission.approve, result.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/taskrisk/' + result.data.id);
            this.setState({
                _id: result.data.id, 
                number: result.data.number,
                status: result.data.status,
                statusSel: statusSel,
                completeSigSec: completeSigSec,
                reviewSigSec: reviewSigSec,
                approveSigSec: approveSigSec,
                enableSave: true
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            this.loadRecord();
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.date == null || state.date === '')
            errors[t('date')] = t('required');
        if(state.objectType == null || state.objectType === ''){
            errors[t('objectType')] = t('required');
        } else {
            if(state.objectType === this.props.auth.constants.objectIds.sopInstance){
                if(state.sop == null || state.sop === '')
                    errors[t('sop')] = t('required');
            }
        }
        if(state.activities == null || state.activities === '')
            errors[t('activities')] = t('required');
        if(state.description == null || state.description === '')
            errors[t('description')] = t('required');
        if(state.tasks == null || state.tasks.length === 0)
            errors[t('tasks')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    toggleTask = e => {
        this.setState({editTask: !this.state.editTask});
    };
    
    editTask(index){
        const task = this.state.tasks[index];
        this.setState({
            editTask: true,
            step: task.step,
            hazards: task.hazards,
            actions: task.actions,
            index: index
        });
    }

    clearTask = () => {
        this.setState({
            step: '',
            hazards: '',
            actions: '',
            index: -1
        });
    };

    deleteTask = e => {
        let tasks = this.state.tasks;
        if(this.state.index > -1)
            tasks.splice(this.state.index, 1)
        this.setState({tasks: tasks});
        this.clearTask();
        this.toggleTask();
    };

    saveTask = e => {
        const valid = this.validateTask();
        if(!valid)
            return;
        let tasks = this.state.tasks;
        if(this.state.index === -1){
            tasks.push({
                step: this.state.step,
                hazards: this.state.hazards,
                actions: this.state.actions
            });
        } else {
            let task = tasks[this.state.index];
            task.step = this.state.step;
            task.hazards = this.state.hazards;
            task.actions = this.state.actions;
        }
        this.setState({tasks: tasks});
        this.clearTask();
        this.toggleTask();
    };

    validateTask(){
        const t = this.props.t;
        let errors = {};
        if(this.state.step == null || this.state.step === '')
            errors[t('step')] = t('required');
        if(this.state.hazards != null && this.state.hazards !== ''){
            if(this.state.actions == null || this.state.actions === '')
                errors[t('actions')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    render(){
        const errors = this.props.errors;
        const signed = this.state.signed;
        const fieldAccess = this.state.fieldAccess;
        const link = this.state.attachedLink;
        const t = this.props.t;
        return(
            <Aux>
                {(this.props.recordId == null || this.props.recordId === '') &&
                    <BreadcrumbBar>
                        <ActionBar
                            optionsAvailable={this.state.optionsMenu.length !== 0}
                            flagForSignature={this.state.flagForSignature}
                        />
                    </BreadcrumbBar>
                }
                
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Panel>
                            <Grid container item spacing={2}>
                                <Grid item xs={6} sm={4}>
                                    <TextField value={this.state.statusSel != null ? this.state.statusSel.label : ''}  name="status"
                                    size="medium" fullWidth={true} disabled={true} label={t('status')}/>
                                </Grid>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={6} sm={4}>
                                        <TextField value={this.state.number}  name="number"
                                        size="medium" fullWidth={true} disabled={true} label={t('number')}/>
                                    </Grid>
                                }
                                <Grid item xs={6} sm={3}>
                                    <DateTimePicker
                                        onChange={this.dateChange}
                                        value={this.state.date}
                                        helperText={errors[t('date')]}
                                        error={errors[t('date')] != null ? true : false}
                                        disabled={!fieldAccess}
                                        label={t('date')}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}>
                                    <AutoComplete
                                        value={this.state.locationSel}
                                        options={this.state.locations}
                                        getOptionLabel={(option) => option.label}
                                        onChange={this.changeAuto('locationSel', 'location')}
                                        renderInput={(params) => <TextField {...params} helperText={errors[t('location')]}
                                            error={errors[t('location')] != null ? true : false} required
                                        />}
                                        disabled={!fieldAccess}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <AutoComplete
                                        value={this.state.objectTypeSel}
                                        options={this.state.objectTypes}
                                        getOptionLabel={(option) => option.label}
                                        onChange={this.changeAuto('objectTypeSel', 'objectType')}
                                        renderInput={(params) => <TextField {...params} helperText={errors[t('objectType')]}
                                            error={errors[t('objectType')] != null ? true : false} required
                                        />}
                                        disabled={!fieldAccess}
                                        required
                                    />
                                </Grid>
                                {this.state.attached &&
                                    <Grid item xs={6} sm={4}>
                                        <Fieldset>
                                            <FormControl>
                                                <FormLabel>{t('attachedTo')}</FormLabel>
                                                <Typography align='center'>
                                                    <Link href={link.path + this.state.attachedObjectId}>
                                                        {link.label}
                                                    </Link>
                                                </Typography>
                                            </FormControl>
                                        </Fieldset>
                                    </Grid>
                                }
                            </Grid>
                            {this.state.isSop &&
                                <Grid item xs={12} sm={6}>
                                        <AutoComplete
                                            value={this.state.sopSel}
                                            options={this.state.sops}
                                            getOptionLabel={(option) => option.label}
                                            onChange={this.changeAuto('sopSel', 'sop')}
                                            renderInput={(params) => <TextField {...params} helperText={errors[t('sop')]}
                                                error={errors[t('sop')] != null ? true : false} required={this.state.objectType === this.props.auth.constants.objectIds.sopInstance}
                                            />}
                                            disabled={!fieldAccess}
                                        />
                                </Grid>
                            }
                            <Grid item xs={12} sm={10}>
                                <AutoComplete
                                    multiple
                                    value={this.state.employeesSel}
                                    options={this.state.users}
                                    getOptionLabel={(option) => option.label}
                                    onChange={this.changeAutoMulti('employeesSel', 'employees')}
                                    renderInput={(params) => <TextField {...params} helperText={errors[t('employees')]}
                                        error={errors[t('employees')] != null ? true : false} label={t('employees')}
                                    />}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <AutoComplete
                                    multiple
                                    value={this.state.equipmentSels}
                                    options={this.state.equipmentOptions}
                                    getOptionLabel={(option) => option.label}
                                    onChange={this.changeAutoMulti('equipmentSels', 'equipment')}
                                    renderInput={(params) => <TextField {...params} helperText={errors[t('equipment')]}
                                        error={errors[t('equipment')] != null ? true : false} label={t('equipment')}
                                    />}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <AutoComplete
                                    multiple
                                    value={this.state.activitiesSel}
                                    options={this.state.activityOptions}
                                    getOptionLabel={(option) => option.label}
                                    onChange={this.changeAutoMulti('activitiesSel', 'activities')}
                                    renderInput={(params) => <TextField {...params} helperText={errors[t('activities')]}
                                        error={errors[t('activities')] != null ? true : false} label={t('activities')}
                                    />}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <AutoComplete
                                    multiple
                                    value={this.state.concernsSel}
                                    options={this.state.concernOptions}
                                    getOptionLabel={(option) => option.label}
                                    onChange={this.changeAutoMulti('concernsSel', 'concerns')}
                                    renderInput={(params) => <TextField {...params} helperText={errors[t('concerns')]}
                                        error={errors[t('concerns')] != null ? true : false} label={t('otherHseConcerns')}
                                    />}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <AutoComplete
                                    multiple
                                    value={this.state.ppesSel}
                                    options={this.state.ppeOptions}
                                    getOptionLabel={(option) => option.label}
                                    onChange={this.changeAutoMulti('ppesSel', 'ppes')}
                                    renderInput={(params) => <TextField {...params} helperText={errors[t('ppes')]}
                                        error={errors[t('ppes')] != null ? true : false} label={t('specializedPpe')}
                                    />}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <AutoComplete
                                    multiple
                                    value={this.state.lifeCritsSel}
                                    options={this.state.lifeCriticalOptions}
                                    getOptionLabel={(option) => option.label}
                                    onChange={this.changeAutoMulti('lifeCritsSel', 'lifeCriticals')}
                                    renderInput={(params) => <TextField {...params} helperText={errors[t('lifeCriticals')]}
                                        error={errors[t('lifeCriticals')] != null ? true : false} label={t('lifeCriticalOps')}
                                    />}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                            <Grid item xs={8} sm={10}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.safetyMaterialRequired}
                                        onChange={this.requiredChange}
                                        name="safetyMaterialRequired"
                                    />
                                    }
                                    label={t('materialSafetyDatasheet')}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                            <Grid item xs={8} sm={10}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={this.state.safetyMaterialAvailable}
                                        onChange={this.availableChange}
                                        name="safetyMaterialRequired"
                                    />
                                    }
                                    label={t('materialSafetyAvailable')}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField value={this.state.description} onChange={this.changeValue} name="description" multiline rows="4"
                                variant="outlined" size="medium" fullWidth={true} disabled={!fieldAccess} label={t('taskDescription')}
                                error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                            </Grid>
                            <hr />
                            <Grid container item spacing={3}>
                                <Grid item xs={6} sm={3}>
                                    <SubSectionHeading title={t('tasks')} />
                                </Grid>
                                {!this.state.editTask && !signed &&
                                    <Grid item xs={4} sm={2}>
                                        <AddBlankButton size="small" addClick={this.toggleTask} buttonName="Add Task" enabled={fieldAccess}/>
                                    </Grid>
                                }
                            </Grid>
                            <TableContainer component={Paper}>
                                <Table aria-label="Tasks">
                                    <TableHead>
                                        <TableRow key="tableHeader">
                                            <TableCell>{t('step')}</TableCell>
                                            <TableCell>{t('hazards')}</TableCell>
                                            <TableCell>{t('actions')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.tasks.map((row, i) => 
                                            <TableRow key={row._id}>
                                                <TableCell>
                                                    <TextOnlyClickButton enabled={true} click={() => this.editTask(i)} name={row.step}/>
                                                </TableCell>
                                                <TableCell>{row.hazards}</TableCell>
                                                <TableCell>{row.actions}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {this.state.editTask && 
                                <Aux>
                                    <Dialog open={this.state.editTask} onClose={() => this.toggleTask()} aria-labelledby="dialog-edit-task">
                                        <DialogTitle aria-labelledby="dialog-edit-task-title">{t('edit')}</DialogTitle>
                                        <DialogContent>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={10}>
                                                    <TextField value={this.state.step} onChange={this.changeValue} name="step"
                                                    size="medium" fullWidth={true} label={t('step')} disabled={!fieldAccess} required
                                                    error={errors[t('step')] != null ? true : false} helperText={errors[t('step')]}/>
                                                </Grid>
                                                <Grid item xs={12} sm={10}>
                                                    <TextField value={this.state.hazards} onChange={this.changeValue} name="hazards"
                                                    size="medium" fullWidth={true} label={t('hazards')} disabled={!fieldAccess}
                                                    error={errors[t('hazards')] != null ? true : false} helperText={errors[t('hazards')]}/>
                                                </Grid>
                                                <Grid item xs={12} sm={10}>
                                                    <TextField value={this.state.actions} onChange={this.changeValue} name="actions"
                                                    size="medium" fullWidth={true} label={t('actions')} disabled={!fieldAccess}
                                                    required={this.state.hazards != null && this.state.hazards !== ''}
                                                    error={errors[t('actions')] != null ? true : false} helperText={errors[t('actions')]}/>
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions>
                                            <SaveButton 
                                                saveClick={() => this.saveTask()} 
                                                enabled={fieldAccess} 
                                            />
                                            <ClearButton clearClick={() => this.clearTask()} enabled={fieldAccess} />
                                            <DeleteButton deleteClick={() => this.deleteTask()} enabled={fieldAccess} />
                                        </DialogActions>
                                    </Dialog>
                                </Aux>
                            }
                        </Panel>
                    </Grid>
                </Grid>
                {!this.state.editTask &&
                    <FormButtonStack 
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.taskRisk}
                        callback={this.loadRecord}
                        saveClick={() => this.saveClick()}
                        status={this.state.status}
                        permissions={this.state.security}
                        common={this.props.auth.common}
                        constants={this.props.auth.constants}
                        saveEnabled={this.state.enableSave}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.TaskRiskAssessment}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(TaskRiskAssessment));