import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import TextField from '../UI/TextField/TextField';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import { SaveButton } from '../UI/Buttons/Buttons';
import DateSelect from '../UI/DateSelect/DateSelect';
import ActionBar from '../UI/Buttons/ActionBar';
import SectionTitle from '../UI/Typography/SectionTitle';
import FormLabel from '@mui/material/FormLabel';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import isEmpty from '../../is-empty';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class Location extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            name: '',
            street1: '',
            street2: '',
            city: '',
            prov: '',
            provSel: null,
            provs: [],
            postal: '',
            country: '',
            countrySel: null,
            countries: [],
            number: '',
            rpics: [],
            spic: '',
            spicSel: null,
            spics: [],
            users: [],
            qap: '',
            qapSel: null,
            qas: [],
            security: [],
            fieldAccess: false,
            license: '',
            licenseType: '',
            licenseTypeSel: null,
            licenseExpiry: '',
            licenseTypes: [],
            typeValues: [],
            types: [],
            typeSels: [],
            timezone: '',
            timezoneSel: null,
            timezones: [],
            managementCount: '',
            adminCount: '',
            salesCount: '',
            productionCount: '',
            otherCount: '',
            indoorGrowingArea: '',
            processingArea: '',
            totalBuildingArea: '',
            outdoorGrowingArea: '',
            gstNumber: '',
            workDays: {
                sun: {
                    workDay: false,
                    shifts: 0,
                    startTime: {
                        hour: 0,
                        minute: 0,
                    },
                    endTime: {
                        hour: 0,
                        minute: 0
                    }
                },
                mon: {
                    workDay: false,
                    shifts: 0,
                    startTime: {
                        hour: 0,
                        minute: 0,
                    },
                    endTime: {
                        hour: 0,
                        minute: 0
                    }
                },
                tues: {
                    workDay: false,
                    shifts: 0,
                    startTime: {
                        hour: 0,
                        minute: 0,
                    },
                    endTime: {
                        hour: 0,
                        minute: 0
                    }
                },
                wed: {
                    workDay: false,
                    shifts: 0,
                    startTime: {
                        hour: 0,
                        minute: 0,
                    },
                    endTime: {
                        hour: 0,
                        minute: 0
                    }
                },
                thurs: {
                    workDay: false,
                    shifts: 0,
                    startTime: {
                        hour: 0,
                        minute: 0,
                    },
                    endTime: {
                        hour: 0,
                        minute: 0
                    }
                },
                fri: {
                    workDay: false,
                    shifts: 0,
                    startTime: {
                        hour: 0,
                        minute: 0,
                    },
                    endTime: {
                        hour: 0,
                        minute: 0
                    }
                },
                sat: {
                    workDay: false,
                    shifts: 0,
                    startTime: {
                        hour: 0,
                        minute: 0,
                    },
                    endTime: {
                        hour: 0,
                        minute: 0
                    }
                }
            }, 
            enableSave: true
        };
    }

    async componentDidMount(){
        const values = await axios.get('/api/locationvalues');
        const fieldAccess = this.props.permission.update;
        this.setState({
            provs: values.data.provs,
            countries: values.data.countries,
            users: values.data.users,
            rpics: values.data.rpics != null ? values.data.rpics : [],
            spics: values.data.spics != null ? values.data.spics : [],
            qas: values.data.qas,
            typeValues: values.data.locationTypes,
            licenseTypes: values.data.licenseTypes,
            timezones: values.data.timezones,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null,
            fieldAccess: fieldAccess,
            security: this.props.permission
        }, () => {
            if(this.state._id != null){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/locations', label: this.props.t('locations'), screen: this.props.auth.screenDefs.Location},
                    { path: 'active', label: this.props.t('location'), screen: this.props.auth.screenDefs.Location}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const record = await axios.get('/api/location/' + this.state._id);
        let provSel = record.data.provs.find(x => x.value === record.data.prov);
        let countrySel = this.state.countries.find(x => x.value === record.data.country);
        let spicSel = record.data.spic != null ? this.state.users.find(x => x.value === record.data.spic) : null;
        let qapSel = record.data.qap != null ? this.state.qas.find(x => x.value === record.data.qap) : null;
        let licenseTypeSel = this.state.licenseTypes.find(x => x.value === record.data.licenseType);
        let timezoneSel = this.state.timezones.find(x => x.value === record.data.timezone);
        let typeSels = [];
        if(record.data.types != null && record.data.types.length > 0){
            record.data.types.forEach(row => {
                let val = this.state.typeValues.find(x => x.value === row);
                typeSels.push(val);
            });
        }
        this.setState({
            name: record.data.name,
            street1: record.data.street1,
            street2: record.data.street2,
            city: record.data.city,
            prov: record.data.prov,
            provSel: provSel,
            postal: record.data.postal,
            country: record.data.country,
            countrySel: countrySel,
            number: record.data.number,
            spic: record.data.spic,
            spicSel: spicSel,
            qap: record.data.qap,
            qapSel: qapSel,
            license: record.data.license,
            licenseType: record.data.licenseType,
            licenseTypeSel: licenseTypeSel,
            licenseExpiry: record.data.licenseExpiry,
            types: record.data.types,
            typeSels: typeSels,
            timezone: record.data.timezone,
            timezoneSel: timezoneSel,
            workDays: record.data.workDays,
            provs: record.data.provs,
            managementCount: record.data.managementCount != null ? record.data.managementCount : '',
            salesCount: record.data.salesCount != null ? record.data.salesCount : '',
            adminCount: record.data.adminCount != null ? record.data.adminCount : '',
            productionCount: record.data.productionCount != null ? record.data.productionCount : '',
            otherCount: record.data.otherCount != null ? record.data.otherCount : '',
            indoorGrowingArea: record.data.indoorGrowingArea != null ? record.data.indoorGrowingArea : '',
            processingArea: record.data.processingArea != null ? record.data.processingArea : '',
            totalBuildingArea: record.data.totalBuildingArea != null ? record.data.totalBuildingArea : '',
            outdoorGrowingArea: record.data.outdoorGrowingArea != null ? record.data.outdoorGrowingArea : '',
            gstNumber: record.data.gstNumber
        }, () => {
            const crumbs = [
                { path: '/#/locations', label: this.props.t('locations'), screen: this.props.auth.screenDefs.Location},
                { path: 'active', label: this.props.t('location') + ' - ' + record.data.name, screen: this.props.auth.screenDefs.Location}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value 
        })
    };

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(data === 'country' && newValue != null){
            let provs = await axios.get('/api/provinceselect', {params: {country: newValue.value}});
            this.setState({provs: provs.data});
        }
    };

    changeMulti = (name, data) => (e, newValue) => {
        var val = newValue;
        var ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        const data = {
            _id: this.state._id,
            name: this.state.name,
            street1: this.state.street1,
            street2: this.state.street2,
            city: this.state.city,
            prov: this.state.prov,
            postal: this.state.postal,
            country: this.state.country,
            number: this.state.number,
            spic: this.state.spic,
            qap: this.state.qap,
            license: this.state.license,
            licenseType: this.state.licenseType,
            licenseExpiry: this.state.licenseExpiry,
            types: this.state.types,
            timezone: this.state.timezone,
            workDays: this.state.workDays,
            managementCount: this.state.managementCount,
            salesCount: this.state.salesCount,
            adminCount: this.state.adminCount,
            productionCount: this.state.productionCount,
            otherCount: this.state.otherCount,
            indoorGrowingArea: this.state.indoorGrowingArea,
            processingArea: this.state.processingArea,
            totalBuildingArea: this.state.totalBuildingArea,
            outdoorGrowingArea: this.state.outdoorGrowingArea,
            gstNumber: this.state.gstNumber
        };
        try {
            const result = await axios.post('/api/location', data);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        }
    };

    validate(){
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.name == null || state.name === '')
            errors[t('name')] = t('required');
        if(state.street1 == null || state.street1 === '')
            errors[t('street1')] = t('required');
        if(state.city == null || state.city === '')
            errors[t('city')] = t('required');
        if(state.prov == null || state.prov === '')
            errors[t('prov')] = t('required');
        if(state.country == null || state.country === '')
            errors[t('country')] = t('required');
        if(state.postal == null || state.postal === '')
            errors[t('postal')] = t('required');
        if(this.state.license != null && this.state.license !== ''){
            if(this.state.licenseType == null || this.state.licenseType === '')
                errors[t('licenseType')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeTimezone = (timezoneName, timezoneOffset) => {
    };

    changeMinute = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        let intVal = parseInt(value);
        if(intVal >= 0 && intVal < 60){
            this.setState({[name]: intVal});
        }
    };

    getWorkDay(name, workDays){
        const t = this.props.t;
        switch(name){
            case t('day.sun'): 
                return workDays.sun;
            case t('day.mon'):
                return workDays.mon;
            case t('day.tues'):
                return workDays.tues;
            case t('day.wed'):
                return workDays.wed;
            case t('day.thurs'):
                return workDays.thurs
            case t('day.fri'):
                return workDays.fri;
            case t('day.sat'):
                return workDays.sat;
            default: 
                return null;
        }
    }

    changeShifts = (name) => (e) => {
        let workDays = this.state.workDays;
        let value = e.target.value;
        if(isNaN(value))
            return;
        let intValue = parseInt(value);
        if(isNaN(intValue))
            return
        let day = this.getWorkDay(name, workDays);
        day.shifts = intValue;
        this.setState({workDays: workDays});
    };

    changeStartMinute = (name) => (e) => {
        let workDays = this.state.workDays;
        let value = e.target.value;
        if(isNaN(value))
            return;
        let intValue = parseInt(value);
        if(isNaN(intValue))
            return
        let day = this.getWorkDay(name, workDays);
        if(intValue < 0 || intValue > 59){
            return;
        }
        day.startTime.minute = intValue;
        this.setState({workDays: workDays});
    };

    changeStartHour = (name) => (e) => {
        let workDays = this.state.workDays;
        let value = e.target.value;
        if(isNaN(value))
            return;
        let intValue = parseInt(value);
        if(isNaN(intValue))
            return
        let day = this.getWorkDay(name, workDays);
        if(intValue <- 0 || intValue > 25){
            return;
        }
        day.startTime.hour = intValue;
        this.setState({workDays: workDays});
    };

    changeEndMinute = (name) => (e) => {
        let workDays = this.state.workDays;
        let value = e.target.value;
        if(isNaN(value))
            return;
        let intValue = parseInt(value);
        if(isNaN(intValue))
            return
        let day = this.getWorkDay(name, workDays);
        if(intValue < 0 || intValue > 59){
            return;
        }
        day.endTime.minute = intValue;
        this.setState({workDays: workDays});
    };

    changeEndHour = (name) => (e) => {
        let workDays = this.state.workDays;
        let value = e.target.value;
        if(isNaN(value))
            return;
        let intValue = parseInt(value);
        if(isNaN(intValue))
            return
        let day = this.getWorkDay(name, workDays);
        if(intValue <- 0 || intValue > 25){
            return;
        }
        day.endTime.hour = intValue;
        this.setState({workDays: workDays});
    };

    changeWorkDay = (name) => (e) => {
        let workDays = this.state.workDays;
        let day = this.getWorkDay(name, workDays);
        day.workDay = !day.workDay;
        this.setState({workDays: workDays});
    };

    render(){
        const errors = this.props.errors;
        const fieldAccess = !this.state.fieldAccess;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar >
                    <ActionBar />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.name} onChange={this.changeValue} name="name"
                            size="medium" fullWidth={true} label={t('name')} disabled={fieldAccess} required
                            error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}
                            />
                        </Grid>
                        <Grid item xs={10} sm={3}>
                            <TextField value={this.state.street1} onChange={this.changeValue} name="street1"
                            size="medium" fullWidth={true} label={t('street')} disabled={fieldAccess} required
                            error={errors[t('street1')] != null ? true : false} helperText={errors[t('street1')]}
                            />
                        </Grid>
                        <Grid item xs={10} sm={3}>
                            <TextField value={this.state.street2} onChange={this.changeValue} name="street2"
                            size="medium" fullWidth={true} label={t('street2')}disabled={fieldAccess}
                            error={errors[t('street2')] != null ? true : false} helperText={errors[t('street2')]}
                            />
                        </Grid>
                        <Grid item xs={10} sm={3}>
                            <TextField value={this.state.city} onChange={this.changeValue} name="city"
                            size="medium" fullWidth={true} label={t('city')} disabled={fieldAccess} required
                            error={errors[t('city')] != null ? true : false} helperText={errors[t('city')]}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.provSel}
                                options={this.state.provs}
                                onChange={this.changeAuto('provSel', 'prov')}
                                error={errors[t('prov')] != null ? true : false}
                                helperText={errors[t('prov')]}
                                label={t('province')}
                                disabled={fieldAccess}
                                required
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <InputMaskField mask="a9a-9a9" name="postal" value={this.state.postal}
                                onChange={this.changeValue}
                                helperText={errors[t('postal')]}
                                error={errors[t('postal')] != null ? true : false} 
                                label={t('postal')}
                                disabled={fieldAccess}
                                required
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.countrySel}
                                options={this.state.countries}
                                onChange={this.changeAuto('countrySel', 'country')}
                                error={errors[t('country')] != null ? true : false}
                                helperText={errors[t('country')]}
                                label={t('country')}
                                disabled={fieldAccess}
                                required
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.timezoneSel}
                                options={this.state.timezones}
                                onChange={this.changeAuto('timezoneSel', 'timezone')}
                                error={errors[t('timezone')] != null ? true : false}
                                helperText={errors[t('timezone')]}
                                label={t('timezone')}
                                disabled={fieldAccess}
                            />
                        </Grid>
                        <Grid item xs={10} sm={3}>
                            <TextField value={this.state.number} onChange={this.changeValue} name="number"
                            size="medium" fullWidth={true} label={t('erpId')} disabled={fieldAccess}
                            error={errors[t('number')] != null ? true : false} helperText={errors[t('number')]}
                            />
                        </Grid>
                        <Grid item xs={10} sm={3}>
                            <TextField value={this.state.gstNumber} onChange={this.changeValue} name="gstNumber"
                            size="medium" fullWidth={true} label={t('gstNumber')} disabled={fieldAccess}
                            error={errors[t('gstNumber')] != null ? true : false} helperText={errors[t('gstNumber')]}
                            />
                        </Grid>
                        <Grid item xs={10} sm={3}>
                            <AutoCompleteField
                                value={this.state.spicSel}
                                options={this.state.spics}
                                onChange={this.changeAuto('spicSel', 'spic')}
                                error={errors[t('spic')] != null ? true : false}
                                helperText={errors[t('spic')]}
                                label={t('spic')}
                                disabled={fieldAccess}
                            />
                        </Grid>
                        <Grid item xs={10} sm={3}>
                            <AutoCompleteField
                                value={this.state.qapSel}
                                options={this.state.qas}
                                onChange={this.changeAuto('qapSel', 'qap')}
                                error={errors[t('qap')] != null ? true : false}
                                helperText={errors[t('qap')]}
                                label={t('qap')}
                                disabled={fieldAccess}  
                            />
                        </Grid>
                        <Grid item xs={10} sm={3}>
                            <TextField value={this.state.license} onChange={this.changeValue} name="license"
                            size="medium" fullWidth={true} label={t('licenseNumber')} disabled={fieldAccess}
                            error={errors[t('license')] != null ? true : false} helperText={errors[t('license')]}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.licenseTypeSel}
                                options={this.state.licenseTypes}
                                onChange={this.changeAuto('licenseTypeSel', 'licenseType')}
                                error={errors[t('licenseType')] != null ? true : false}
                                helperText={errors[t('licenseType')]}
                                label={t('licenseType')}
                                disabled={fieldAccess}
                                required={this.state.license != null && this.state.license !== ''}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.changeDate('licenseExpiry')}
                                value={this.state.licenseExpiry}
                                helperText={errors[t('licenseExpiry')]}
                                error={errors[t('licenseExpiry')] != null ? true : false}
                                disabled={fieldAccess}
                                label={t('licenseExpiry')}
                                format={this.props.auth.user.dateFormat}
                            />
                        </Grid>
                        <Grid item sm={11}>
                            <AutoCompleteField
                                multiple={true} disabled={fieldAccess}
                                value={this.state.typeSels}
                                options={this.state.typeValues}
                                onChange={this.changeMulti('typeSels', 'types')}
                                error={errors[t('types')] != null ? true : false}
                                helperText={errors[t('types')]}
                                label={t('types')}
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title={t('employees')}/>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}/>
                        <Grid item xs={4} sm={2}>{t('ftes')}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>{t('management')}</Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.managementCount} onChange={this.changeValue} name="managementCount" type="number"
                            size="medium" fullWidth={true} disabled={fieldAccess}
                            error={errors[t('managementCount')] != null ? true : false} helperText={errors[t('managementCount')]}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>{t('administrative')}</Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.adminCount} onChange={this.changeValue} name="adminCount" type="number"
                            size="medium" fullWidth={true} disabled={fieldAccess}
                            error={errors[t('adminCount')] != null ? true : false} helperText={errors[t('adminCount')]}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>{t('sales')}</Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.salesCount} onChange={this.changeValue} name="salesCount" type="number"
                            size="medium" fullWidth={true} disabled={fieldAccess}
                            error={errors[t('salesCount')] != null ? true : false} helperText={errors[t('salesCount')]}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>{t('production')}</Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.productionCount} onChange={this.changeValue} name="productionCount" type="number"
                            size="medium" fullWidth={true} disabled={fieldAccess}
                            error={errors[t('productionCount')] != null ? true : false} helperText={errors[t('productionCount')]}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>{t('other')}</Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.otherCount} onChange={this.changeValue} name="otherCount" type="number"
                            size="medium" fullWidth={true} disabled={fieldAccess}
                            error={errors[t('otherCount')] != null ? true : false} helperText={errors[t('otherCount')]}
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title={t('capacity')}/>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}/>
                        <Grid item xs={4} sm={2}>{t('squareMetres')}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>{t('licensedIndoorGrowing')}</Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.indoorGrowingArea} onChange={this.changeValue} name="indoorGrowingArea" type="number"
                            size="medium" fullWidth={true} disabled={fieldAccess}
                            error={errors[t('indoorGrowingArea')] != null ? true : false} helperText={errors[t('indoorGrowingArea')]}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>{t('licensedProcessing')}</Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.processingArea} onChange={this.changeValue} name="processingArea" type="number"
                            size="medium" fullWidth={true} disabled={fieldAccess}
                            error={errors[t('processingArea')] != null ? true : false} helperText={errors[t('processingArea')]}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>{t('totalBuildingArea')}</Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.totalBuildingArea} onChange={this.changeValue} name="totalBuildingArea" type="number"
                            size="medium" fullWidth={true} disabled={fieldAccess}
                            error={errors[t('totalBuildingArea')] != null ? true : false} helperText={errors[t('totalBuildingArea')]}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}/>
                        <Grid item xs={4} sm={2}>{t('hectares')}</Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>{t('licensedOutdoorGrowing')}</Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.outdoorGrowingArea} onChange={this.changeValue} name="outdoorGrowingArea" type="number"
                            size="medium" fullWidth={true} disabled={fieldAccess}
                            error={errors[t('outdoorGrowingArea')] != null ? true : false} helperText={errors[t('outdoorGrowingArea')]}
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    {this.state.workDays != null &&
                        <Aux>
                            <SectionTitle title={t('workDays')}/>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <FormLabel>{t('day.sunday')}</FormLabel>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.workDays.sun.workDay} onChange={this.changeWorkDay(t('day.sun'))}
                                    label={t('workDay')} name="sunWorkDay" disabled={false}
                                    />
                                </Grid>
                                {this.state.workDays.sun.workDay &&
                                    <Aux>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.sun.shifts} onChange={this.changeShifts(t('day.sun'))} name="sunShifts"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('shifts')}
                                            error={errors[t('sunShifts')] != null ? true : false} helperText={errors[t('sunShifts')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.sun.startTime.hour} onChange={this.changeStartHour(t('day.sun'))} name="sunStartHour"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('startHour')}
                                            error={errors[t('sunStartHour')] != null ? true : false} helperText={errors[t('sunStartHour')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.sun.startTime.minute} onChange={this.changeStartMinute(t('day.sun'))} name="sunStartMinute"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('startMinute')}
                                            error={errors[t('sunStartMinute')] != null ? true : false} helperText={errors[t('sunStartMinute')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.sun.endTime.hour} onChange={this.changeEndHour(t('day.sun'))} name="sunEndHour"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('endHour')}
                                            error={errors[t('sunEndHour')] != null ? true : false} helperText={errors[t('sunEndHour')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField vtype="number" alue={this.state.workDays.sun.endTime.minute} onChange={this.changeEndMinute(t('day.sun'))} name="sunEndMinute"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('endMinute')}
                                            error={errors[t('sunEndMinute')] != null ? true : false} helperText={errors[t('sunEndMinute')]}/>
                                        </Grid>
                                    </Aux>
                                }
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <FormLabel>{t('day.monday')}</FormLabel>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.workDays.mon.workDay} onChange={this.changeWorkDay(t('day.mon'))}
                                    label={t('workDay')} name="monWorkDay" disabled={false}
                                    />
                                </Grid>
                                {this.state.workDays.mon.workDay &&
                                    <Aux>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.mon.shifts} onChange={this.changeShifts(t('day.mon'))} name="monShifts"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('shifts')}
                                            error={errors[t('monShifts')] != null ? true : false} helperText={errors[t('monShifts')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.mon.startTime.hour} onChange={this.changeStartHour(t('day.mon'))} name="monStartHour"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('startHour')}
                                            error={errors[t('monStartHour')] != null ? true : false} helperText={errors[t('monStartHour')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.mon.startTime.minute} onChange={this.changeStartMinute(t('day.mon'))} name="monStartMinute"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('startMinute')}
                                            error={errors[t('monStartMinute')] != null ? true : false} helperText={errors[t('monStartMinute')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.mon.endTime.hour} onChange={this.changeEndHour(t('day.mon'))} name="monEndHour"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('endHour')}
                                            error={errors[t('monEndHour')] != null ? true : false} helperText={errors[t('monEndHour')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.mon.endTime.minute} onChange={this.changeEndMinute(t('day.mon'))} name="monEndMinute"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('endMinute')}
                                            error={errors[t('monEndMinute')] != null ? true : false} helperText={errors[t('monEndMinute')]}/>
                                        </Grid>
                                    </Aux>
                                }
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <FormLabel>{t('day.tuesday')}</FormLabel>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.workDays.tues.workDay} onChange={this.changeWorkDay(t('day.tues'))}
                                    label={t('workDay')} name="tuesWorkDay" disabled={false}
                                    />
                                </Grid>
                                {this.state.workDays.tues.workDay &&
                                    <Aux>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.tues.shifts} onChange={this.changeShifts(t('day.tues'))} name="tuesShifts"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('shifts')}
                                            error={errors[t('tuesShifts')] != null ? true : false} helperText={errors[t('tuesShifts')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.tues.startTime.hour} onChange={this.changeStartHour(t('day.tues'))} name="tuesStartHour"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('startHour')}
                                            error={errors[t('tuesStartHour')] != null ? true : false} helperText={errors[t('tuesStartHour')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.tues.startTime.minute} onChange={this.changeStartMinute(t('day.tues'))} name="tuesStartMinute"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('startMinute')}
                                            error={errors[t('tuesStartMinute')] != null ? true : false} helperText={errors[t('tuesStartMinute')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.tues.endTime.hour} onChange={this.changeEndHour(t('day.tues'))} name="tuesEndHour"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('endHour')}
                                            error={errors[t('tuesEndHour')] != null ? true : false} helperText={errors[t('tuesEndHour')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.tues.endTime.minute} onChange={this.changeEndMinute(t('day.tues'))} name="tuesEndMinute"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('endMinute')}
                                            error={errors[t('tuesEndMinute')] != null ? true : false} helperText={errors[t('tuesEndMinute')]}/>
                                        </Grid>
                                    </Aux>
                                }
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <FormLabel>{t('day.wednesday')}</FormLabel>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.workDays.wed.workDay} onChange={this.changeWorkDay(t('day.wed'))}
                                    label={t('workDay')} name="wedWorkDay" disabled={false}
                                    />
                                </Grid>
                                {this.state.workDays.wed.workDay &&
                                    <Aux>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.wed.shifts} onChange={this.changeShifts(t('day.wed'))} name="wedShifts"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('shifts')}
                                            error={errors[t('wedShifts')] != null ? true : false} helperText={errors[t('wedShifts')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.wed.startTime.hour} onChange={this.changeStartHour(t('day.wed'))} name="wedStartHour"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('startHour')}
                                            error={errors[t('wedStartHour')] != null ? true : false} helperText={errors[t('wedStartHour')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.wed.startTime.minute} onChange={this.changeStartMinute(t('day.wed'))} name="wedStartMinute"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('startMinute')}
                                            error={errors[t('wedStartMinute')] != null ? true : false} helperText={errors[t('wedStartMinute')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.wed.endTime.hour} onChange={this.changeEndHour(t('day.wed'))} name="wedEndHour"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('endHour')}
                                            error={errors[t('wedEndHour')] != null ? true : false} helperText={errors[t('wedEndHour')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.wed.endTime.minute} onChange={this.changeEndMinute(t('day.wed'))} name="wedEndMinute"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('endMinute')}
                                            error={errors[t('wedEndMinute')] != null ? true : false} helperText={errors[t('wedEndMinute')]}/>
                                        </Grid>
                                    </Aux>
                                }
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <FormLabel>{t('day.thursday')}</FormLabel>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.workDays.thurs.workDay} onChange={this.changeWorkDay(t('day.thurs'))}
                                    label={t('workDay')} name="thursWorkDay" disabled={false}
                                    />
                                </Grid>
                                {this.state.workDays.thurs.workDay &&
                                    <Aux>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.thurs.shifts} onChange={this.changeShifts(t('day.thurs'))} name="thursShifts"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('shifts')}
                                            error={errors[t('thursShifts')] != null ? true : false} helperText={errors[t('thursShifts')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.thurs.startTime.hour} onChange={this.changeStartHour(t('day.thurs'))} name="thursStartHour"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('startHour')}
                                            error={errors[t('thursStartHour')] != null ? true : false} helperText={errors[t('thursStartHour')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.thurs.startTime.minute} onChange={this.changeStartMinute(t('day.thurs'))} name="thursStartMinute"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('startMinute')}
                                            error={errors[t('thursStartMinute')] != null ? true : false} helperText={errors[t('thursStartMinute')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.thurs.endTime.hour} onChange={this.changeEndHour(t('day.thurs'))} name="thursEndHour"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('endHour')}
                                            error={errors[t('thursEndHour')] != null ? true : false} helperText={errors[t('thursEndHour')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.thurs.endTime.minute} onChange={this.changeEndMinute(t('day.thurs'))} name="thursEndMinute"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('endMinute')}
                                            error={errors[t('thursEndMinute')] != null ? true : false} helperText={errors[t('thursEndMinute')]}/>
                                        </Grid>
                                    </Aux>
                                }
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <FormLabel>{t('day.friday')}</FormLabel>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.workDays.fri.workDay} onChange={this.changeWorkDay(t('day.fri'))}
                                    label={t('workDay')} name="friWorkDay" disabled={false}
                                    />
                                </Grid>
                                {this.state.workDays.fri.workDay &&
                                    <Aux>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.fri.shifts} onChange={this.changeShifts(t('day.fri'))} name="friShifts"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('shifts')}
                                            error={errors[t('friShifts')] != null ? true : false} helperText={errors[t('friShifts')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.fri.startTime.hour} onChange={this.changeStartHour(t('day.fri'))} name="friStartHour"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('startHour')}
                                            error={errors[t('friStartHour')] != null ? true : false} helperText={errors[t('friStartHour')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.fri.startTime.minute} onChange={this.changeStartMinute(t('day.fri'))} name="friStartMinute"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('startMinute')}
                                            error={errors[t('friStartMinute')] != null ? true : false} helperText={errors[t('friStartMinute')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.fri.endTime.hour} onChange={this.changeEndHour(t('day.fri'))} name="friEndHour"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('endHour')}
                                            error={errors[t('friEndHour')] != null ? true : false} helperText={errors[t('friEndHour')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.fri.endTime.minute} onChange={this.changeEndMinute(t('day.fri'))} name="friEndMinute"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('endMinute')}
                                            error={errors[t('friEndMinute')] != null ? true : false} helperText={errors[t('friEndMinute')]}/>
                                        </Grid>
                                    </Aux>
                                }
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <FormLabel>{t('day.saturday')}</FormLabel>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.workDays.sat.workDay} onChange={this.changeWorkDay(t('day.sat'))}
                                    label={t('workDay')} name="satWorkDay" disabled={false}
                                    />
                                </Grid>
                                {this.state.workDays.sat.workDay &&
                                    <Aux>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.sat.shifts} onChange={this.changeShifts(t('day.sat'))} name="satShifts"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('shifts')}
                                            error={errors[t('satShifts')] != null ? true : false} helperText={errors[t('satShifts')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.sat.startTime.hour} onChange={this.changeStartHour(t('day.sat'))} name="satStartHour"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('startHour')}
                                            error={errors[t('satStartHour')] != null ? true : false} helperText={errors[t('satStartHour')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.sat.startTime.minute} onChange={this.changeStartMinute(t('day.sat'))} name="satStartMinute"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('startMinute')}
                                            error={errors[t('satStartMinute')] != null ? true : false} helperText={errors[t('satStartMinute')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.sat.endTime.hour} onChange={this.changeEndHour(t('day.sat'))} name="satEndHour"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('endHour')}
                                            error={errors[t('satEndHour')] != null ? true : false} helperText={errors[t('satEndHour')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={this.state.workDays.sat.endTime.minute} onChange={this.changeEndMinute(t('day.sat'))} name="satEndMinute"
                                            size="medium" fullWidth={true} disabled={!security.update} label={t('endMinute')}
                                            error={errors[t('satEndMinute')] != null ? true : false} helperText={errors[t('satEndMinute')]}/>
                                        </Grid>
                                    </Aux>
                                }
                            </Grid>
                        </Aux>
                    }
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <SaveButton saveClick={this.save} enabled={this.state.enableSave && !fieldAccess} />
                        </Grid>
                    </Grid>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Location));