import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import DateSelect from '../UI/DateSelect/DateSelect';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";

const BatchDeviationBar = (props) => {
    const [values, setValues] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 160)),
        endDate: new Date(),
        ncrs: true,
        deviations: true
    });
    const [chartData, setChartData] = useState([]);

    const { t } = useTranslation('translation');

    const loadRecords = useCallback(async () => {
        let reqData = {
            startDate: values.startDate,
            endDate: values.endDate,
            ncrs: values.ncrs,
            deviations: values.deviations
        }
        let data = await axios.get('/api/batchdeviations', {params: reqData});
        setChartData(data.data.rows);
    }, [values]);

    useEffect(() => {
        loadRecords();
    }, [values, loadRecords]);

    const changeBool = e => {
        let name = e.target.name;
        setValues({...values, [name]: !values[name]}, () => {loadRecords()});
    }

    const startChange = e => {
        setValues({...values, startDate: e}, () => {loadRecords()});
    }

    const endChange = e => {
        setValues({...values, endDate: e}, () => {loadRecords()});
    }

    const barClick = (e, f) => {
        let batch = e.activePayload != null && e.activePayload.length > 0 && e.activePayload[0].payload.id != null ? e.activePayload[0].payload.id : null;
        if(batch != null)
            props.history.push('/cultivationbatch/' + batch);
    }

    return(
        <Aux>
            <SectionTitle title={t('batchInv')}/>
            <Grid container spacing={3}>
                <Grid item xs={4} sm={2}>
                    <DateSelect
                        className='form-control'
                        onChange={startChange}
                        value={values.startDate}
                        label={t('startDate')}
                        format={props.auth.user.dateFormat}
                    />
                </Grid>
                <Grid item xs={4} sm={2}>
                    <DateSelect
                        className='form-control'
                        onChange={endChange}
                        value={values.endDate}
                        label={t('endDate')}
                        format={props.auth.user.dateFormat}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={values.deviations}
                            onChange={changeBool}
                            name="deviations"
                        />
                        }
                        label={t('deviations')}
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={values.ncrs}
                            onChange={changeBool}
                            name="ncrs"
                        />
                        }
                        label={t('ncrs')}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                            width={1000}
                            height={300}
                            data={chartData}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                            onClick={barClick}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="status" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Deviations" stackId="a" fill="#484A9A" />
                            <Bar dataKey="NCRs" stackId="a" fill="#5BC6CC" />
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </Aux>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(withRouter(BatchDeviationBar));