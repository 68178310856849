import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class HeadersFootersList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            name: '',
            security: []
        };
    }

    async componentDidMount(){
       this.setState({
            security: this.props.permission
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('headersAndFooters'), screen: this.props.auth.screenDefs.HeadersFootersList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        let query = {
            name: this.state.name
        }
        let result = await axios.get('/api/headersandfooters', {params: query});
        this.setState({list: result.data});
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value}, () => {this.loadRecords();})
    };

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.name} onChange={this.changeValue} name="name"
                            size="medium" fullWidth={true} label={t('name')} data-cy='name'/>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="headersList">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('name')}</TableCell>
                                    <TableCell>{t('global')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/headersfooters/' + row._id} data-cy={'row' + i}>{row.name}</Link></TableCell>
                                        <TableCell>{row.global ? t('yes') : t('no')}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(HeadersFootersList));
