import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import conversionHelper from '../../helpers/conversionHelper';
import isEmpty from '../../is-empty';
import queryString from 'query-string';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import SubSubHeading from '../UI/Typography/SubSubHeading';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Deviations from '../Data/Deviations';
import Ncrs from '../Data/Ncrs';
import CleaningRecords from '../Data/CleaningRecords';
import { approveSigSecurity, destroyButtonSecurity, fieldSecurity,
         printButtonSecurity, releaseButtonSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import BatchLedgers from './BatchLedgers';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import Panel from '../UI/Panel/Panel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
// import TablePagination from '@mui/material/TablePagination';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ActionBar from '../UI/Buttons/ActionBar';
import BatchRecordForm from './Forms/BatchRecord';
import { AddActivityButton, CompleteButton, ReleaseButton,
         DestroyButton, SaveButton } from '../UI/Buttons/Buttons';
import ActionButton from '../UI/Buttons/Actions';
import PassFailIcons from '../UI/Icons/PassFail';
import BatchObservations from './BatchObservations';
import ConfirmDelete from '../General/ConfirmDelete';
import { withTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import ModalDialog from '../UI/Dialog/ModalDialog';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import BatchPlantHelper from '../../helpers/batchPlantHelper';
import BoxTitle from '../UI/Typography/BoxTitle';
import CloneIcon from 'mdi-material-ui/Sprout';
import VegIcon from 'mdi-material-ui/FlowerTulipOutline';
import FlowerIcon from 'mdi-material-ui/FlowerTulip';
import MotherIcon from 'mdi-material-ui/Cannabis';
import PrintView from '../Printing/PrintView';
import ConversionHelper from '../../helpers/conversionHelper';

const bottomTableDesign = {
    border: 2, 
    borderColor: 'grey.300', 
    borderBottomLeftRadius: 10, 
    borderBottomRightRadius: 10, 
    p: 1
}
class CultivationBatch extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            description: '',
            status: '',
            statusSel: null,
            statuses: [],
            location: '',
            locSel: null,
            locations: [],
            strain: '',
            strainSel: null,
            strains: [],
            startItem: '',
            startItemSel: null,
            outputItem: '',
            outputItemSel: null,
            items: [],
            template: '',
            templateSel: null,
            templates: [],
            startQuantity: '0',
            currentQuantity : '0',
            plannedStart: new Date(),
            actualStart: '',
            vegStart: '',
            vegCount: '',
            expectedVegCount: '',
            flowerStart: '',
            flowerCount: '',
            expectedFlowerCount: '',
            harvestDate: '',
            expectedHarvest: '',
            expectedYield: '',
            expectedYieldUOM: null,
            expectedUOMSel: null,
            uoms: [],
            harvestedCount: '',
            expectedHarvestCount: '',
            currentRooms: [],
            roomHistory: [],
            templateObject: null,
            showActivity: false,
            sequence: '',
            activity: '',
            actSel: null,
            dateShift: '',
            sops: [],
            sopsSel: [],
            activities: [],
            sopList: [],
            stage: '',
            stageSel: null,
            stages: [],
            stageOptions: [],
            documents: [],
            plantedQuantity: '',
            reviewer: null,
            signer: null,
            reviewers: [],
            signers: [],
            signed: false,
            reviewed: false,
            cloneSource: [],
            outputLots: [],
            security: [],
            fieldAccess: false,
            enableRelease: false,
            reviewSigSec: false,
            approveSigSec: false,
            showContainer: false,
            containerAmount: '',
            containerItem: '',
            containerItemSel: null,
            containerItems: [],
            containerDate: new Date(),
            expectedYieldPerPlant: '',
            outputPerHarvestedPlant: '',
            outputPerPlantedPlant: '',
            productionSchedule: '',
            productionScheduleSel: null,
            productionSchedules: [],
            productionScheduleActivity: '',
            prodActSel: null,
            productionScheduleActivities: [],
            rooms: [],
            cloneRoomOptions: [],
            vegRoomOptions: [],
            flowerRoomOptions: [],
            released: false,
            trimmings: [],
            dryings: [],
            curings: [],
            strainObject: null,
            strainItems: [],
            showDestroy: false,
            deviations: [],
            deviation: '',
            deviationSel: null,
            createDeviation: false,
            destructionReason: '',
            destructionReasonSel: null,
            destructionReasons: [],
            destructionReasonDescription: '',
            activityDate: new Date(),
            qrPath: '',
            finalDocument: null,
            daysRemaining: 0,
            daysTranspired: 0,
            daysInStage: 0,
            strainRecord: null,
            batchType: '',
            dialogOpen: false,
            inputs: [],
            outputs: [],
            itemCategories: [],
            destroyAccess: false,
            printAccess: false,
            totalLabour: 0,
            testResults: [],
            optionsMenu: [],
            flagForSignature: 0,
            deleteOpen: false,
            stageIndex: null,
            activityIndex: null,
            sales: [],
            inputTotal: 0,
            costs: {},
            estimatedLabourCost: '',
            expectedInputCosts: '',
            feedings: [],
            showUnlock: false,
            notiObservationId: '',
            enableSave: true,
            inputRows: 10,
            outputRows: 10,
            inputPage: 0,
            outputPage: 0,
            latestDate: null,
            openActivityDrawer: '',
            strainDialog: '',
            roomDialog: '',
            lengthUOMs: [],
            lightTypes: [],
            observationDialog: '',
            observationCount: 0,
            roomFeedingDialog: '',
            investigationCount: 0,
            treatments: { crop: [], external: [], count: 0 },
            treatmentsDialog: '',
            treatmentsCount: 0,
            roomCleaningCount: 0,
            roomCleaningDialog: '',
            testResultCount: 0,
            testResultDialog: '',
            batchLedgerDialog: '',
            openActivityDetailDrawer: '',
            cgsDialog: '',
            createNewObservation: false,
            stagesOpen: [],
            allActivities: [],
            regActivities: [],
            tissueActivities: [],
            printViewer: false,
            addRoomCleaning: false,
            newActivityTypes: [],
            newAct: '',
            newActSel: null,
            showSequence: false,
            newSequence: '',
            lastSequence: null,
            processingOrder: []
        };
    }

    async componentDidMount(){
        const { activeBatchTemplates, batchStatuses, batchActivityDefs, batchDestructionReasons, batchStages, locations, strains, itemCategories, lengthUOMs, lightTypes, productionSchedules, quantityUOMs, rooms, sops, 
                batchActivityDefsReg, batchActivityDefsTissue} = 
            await ValueHelper.getCachedValues([common.cacheValues.cultivationLocation, common.cacheValues.strain, common.cacheValues.itemCategory,
            common.cacheValues.quantityUOM, common.cacheValues.defaultGrowingRoom, common.cacheValues.batchStatus, common.cacheValues.batchStage,
            common.cacheValues.batchTemplateActive, common.cacheValues.batchActivityDef, common.cacheValues.batchDestructionReason, common.cacheValues.batchActivityDefRegular, 
            common.cacheValues.batchActivityDefTissue, common.cacheValues.batchActivitySop, common.cacheValues.productionSchedule, common.cacheValues.lengthUOM, 
            common.cacheValues.lightType], this.props.auth.constants, null, this.props.auth.user.tenant);
        
        let statusSel = batchStatuses.find(x => x.value === this.props.auth.constants.batchStatuses.inserted);
        let stageSel = batchStages.find(x => x.value === this.props.auth.constants.batchStages.prop);
        let newActivityTypes = [
            {label: this.props.t('batchActivity'), value: this.props.auth.constants.objectIds.batchActivity},
            {label: this.props.t('roomCleaning'), value: this.props.auth.constants.objectIds.roomCleaning},
        ];
        let newActSel = newActivityTypes.find(x => x.value === this.props.auth.constants.objectIds.batchActivity);
        const { approve, review } = await ValueHelper.screenUsers(common.screens.cultivationBatch, this.props.auth.user.tenant);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.batch);
        const enableRelease = releaseButtonSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.batch);
        this.setState({
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            templates: activeBatchTemplates,
            statuses: batchStatuses,
            strains: strains,
            locations: locations,
            uoms: quantityUOMs,
            sopList: sops,
            activities: batchActivityDefs,
            allActivities: batchActivityDefs,
            statusSel: statusSel,
            status: this.props.auth.constants.batchStatuses.planned,
            stageOptions: batchStages,
            stage: this.props.auth.constants.batchStages.prop,
            stageSel: stageSel,
            reviewers: review,
            signers: approve,
            fieldAccess: fieldAccess,
            enableRelease: enableRelease,
            productionSchedules: productionSchedules,
            rooms: rooms,
            destructionReasons: batchDestructionReasons,
            security: this.props.permission,
            qrPath: window.location.href,
            itemCategories: itemCategories,
            lengthUOMs: lengthUOMs,
            lightTypes: lightTypes,
            regActivities: batchActivityDefsReg,
            tissueActivities: batchActivityDefsTissue,
            newActivityTypes: newActivityTypes,
            newAct: this.props.auth.constants.objectIds.batchActivity,
            newActSel: newActSel
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/cultivationbatches', label: this.props.t('batches'), screen: this.props.auth.screenDefs.CultivationBatch},
                    { path: 'active', label: this.props.t('batch'), screen: this.props.auth.screenDefs.CultivationBatch}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }   
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const constants = this.props.auth.constants;
        let storage = sessionStorage.getItem('batchStagesOpen');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        let stagesOpen = [];
        let stagesSet = false;
        if(storage != null && storage.batch === this.state._id){
            stagesOpen = storage.stagesOpen;
            stagesSet = true;
        }
        let record = await axios.get('/api/ebatch/' + this.state._id);
        let salesParms = {
            cultBatch: this.state._id
        }
        let activities = this.state.allActivities;
        if(record.data.templateObject != null && record.data.templateObject.batchType != null && record.data.templateObject.batchType._id !== constants.batchTypes.plantlet){
            activities = this.state.regActivities;
        } else if (record.data.templateObject != null && record.data.templateObject.batchType != null && record.data.templateObject.batchType._id === constants.batchTypes.plantlet){
            activities = this.state.tissueActivities;
        }
        let sales = await axios.get('api/batchSales', {params: salesParms});
        let items = record.data.items;
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let locSel = this.state.locations.find(x => x.value === record.data.location);
        let strainSel = this.state.strains.find(x => x.value === record.data.strain);
        let startItemSel = items.find(x => x.value === record.data.startItem);
        let templateSel = this.state.templates.find(x => x.value === record.data.template);
        let expectedUOMSel = record.data.expectedYieldUOM != null ? this.state.uoms.find(x => x.value === record.data.expectedYieldUOM._id) : null;
        let stageSel = this.state.stageOptions.find(x => x.value === record.data.stage);
        let signed = record.data.status === this.props.auth.constants.batchStatuses.complete ||
                    record.data.status === this.props.auth.constants.batchStatuses.review ||
                    record.data.status === this.props.auth.constants.batchStatuses.signedOff;
        let reviewed = record.data.status === this.props.auth.constants.batchStatuses.review ||
                    record.data.status === this.props.auth.constants.batchStatuses.signedOff;
        const fieldAccess = fieldSecurity(this.props.permission, record.data.status, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.batch);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, record.data.status, this.props.auth.common, this.props.auth.constants,
            this.props.auth.common.statusTypes.batch);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, record.data.status, this.props.auth.common, this.props.auth.constants,
            this.props.auth.common.statusTypes.batch);
        const destroyAccess = destroyButtonSecurity(this.props.permission, record.data.status, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.batch);
        const printAccess = printButtonSecurity(this.props.permission, record.data.status, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.batch);
        let productionSchedules = this.state.productionSchedules;
        if(record.data.productionScheduleValue != null){
            let exists = productionSchedules.find(x => x.value === record.data.productionScheduleValue.value);
            if(!exists){
                productionSchedules.push(record.data.productionScheduleValue);
            }
        }
        let prodActSel = null;
        if(record.data.productionScheduleActivity != null){
            prodActSel =
                    record.data.productionScheduleActivities.find(x => x.value === record.data.productionScheduleActivity);
        }
        let cloneRoomOptions = [];
        if(record.data.cloneRooms != null && record.data.cloneRooms.length > 0){
            record.data.cloneRooms.forEach(row => {
                let val = this.state.rooms.find(x => x.value === row);
                cloneRoomOptions.push(val);
            });
        }
        let vegRoomOptions = [];
        if(record.data.vegRooms != null && record.data.vegRooms.length > 0){
            record.data.vegRooms.forEach(row => {
                let val = this.state.rooms.find(x => x.value === row);
                vegRoomOptions.push(val);
            });
        }
        let flowerRoomOptions = [];
        if(record.data.flowerRooms != null && record.data.flowerRooms.length > 0){
            record.data.flowerRooms.forEach(row => {
                let val = this.state.rooms.find(x => x.value === row);
                flowerRoomOptions.push(val);
            });
        }

        let stages = record.data.stages;
        let inputs = record.data.inputs;
        let outputs = record.data.outputs;
        if(inputs != null && inputs.length > 0){
            inputs.forEach((input) => {
                input.uom = this.state.uoms.find(x => x.value === input.uom);
                input.category = this.state.itemCategories.find(x => x.value === input.category);
            });
        }
        if(outputs != null && outputs.length > 0){
            outputs.forEach((output) => {
                output.uom = this.state.uoms.find(x => x.value === output.uom);
                output.category = this.state.itemCategories.find(x => x.value === output.category);
            });
        }

        let latestDate = record.data.actualStart;
        let openActivityDrawer = '';
        let currentPlants = [];
        for(let room of record.data.currentRooms){
            for(let row of room.items){
                if(row.count != null && row.count > 0){
                    let plantIndex = currentPlants.findIndex(x => x === row.item._id);
                    if(plantIndex === -1){
                        currentPlants.push(row.item._id);
                    }
                }
            }
        }
        stages.forEach((row, i) => {
            let stagePlant = BatchPlantHelper.determineStagePlantFull(record.data.strainObject, row, constants, items);
            let plantIndex = currentPlants.findIndex(x => x === stagePlant)
            if(!stagesSet){
                stagesOpen.push({open: false});
                if(plantIndex > -1){
                    if(row.stage._id !== constants.batchStages.harvest){
                        stagesOpen[i].open = true;
                    } else {
                        if(record.data.status === constants.batchStatuses.harvested){
                            stagesOpen[i].open = true
                        } else {
                            stagesOpen[i].open = false;
                        }
                    }
                } else {
                    stagesOpen[i].open = false
                }
            }
            let roomSel = row.room != null && row.room !== '' ? this.state.rooms.find(x => x.value === row.room) : null;
            row.roomSel = roomSel;
            row.allowAdd = (row.activities.length > 0 && row.activities[row.activities.length - 1].actualDate != null) ? false : true;

            switch(row.stage._id){
                case this.props.auth.constants.batchStages.veg:
                    row.attrition = record.data.expectedVegCount;
                    break;
                case this.props.auth.constants.batchStages.flower:
                    row.attrition = record.data.expectedFlowerCount;
                    break;
                case this.props.auth.constants.batchStages.harvest:
                    row.attrition = record.data.expectedHarvestCount;
                    break;
                default:
                    break;
            }
            if(latestDate != null && latestDate !== ''){
                row.activities.forEach(act => {
                    if(act.activity != null && act.activity.status != null && (act.activity.status._id === constants.statuses.reviewed || act.activity.status._id === constants.statuses.signed)){
                        if(act.actualDate != null && act.actualDate !== ''){
                            if(act.actualDate > latestDate){
                                latestDate = act.actualDate;
                            }
                        }
                    }
                })
            }
            if(!stagesSet){
                if(currentPlants.length === 0){
                    if(record.data.status === constants.batchStatuses.harvested){
                        stages[stages.length - 1].open = true;
                    } else if (record.data.status === constants.batchStatuses.released){
                        stages[0].open = true;
                    }
                }
            }
            let latest = null;
            if(latestDate != null && latestDate !== ''){
                latest = new Date(Date.parse(latestDate));
            }
            if(latest != null){
                latest.setHours(0, 0, 0, 0);
            }
        });
        if(!stagesSet){
            if(currentPlants.length === 0 && (record.data.status === constants.batchStatuses.new || record.data.status === constants.batchStatuses.released)){
                stagesOpen[0].open = true;
            } else if (record.data.stage === constants.batchStages.harvest){
                stagesOpen[stages.length - 1].open = true;
            }
        }

        let released = record.data.status !== this.props.auth.constants.batchStatuses.inserted ? true : false;
        let destructionReasonSel = record.data.destructionReason != null ? this.state.destructionReasons.find(x => x.value === record.data.destructionReason) : null;

        let daysRemaining = 0;
        let daysTranspired = 0;
        if(record.data.status !== this.props.auth.constants.batchStatuses.signedOff) {
            daysRemaining = Math.round(conversionHelper.calculateDaysBetweenDates(record.data.expectedHarvest, Date.now()));
            if(record.data.actualStart) daysTranspired = Math.round(conversionHelper.calculateDaysBetweenDates(record.data.actualStart, Date.now()));
        }else {
            daysRemaining = 0;
            daysTranspired = Math.round(conversionHelper.calculateDaysBetweenDates(record.data.actualStart, record.data.harvestDate));
        }

        let costs = record.data.costs;
        if(costs == null){
            costs = {};
        }
        costs.inputItems = costs.inputItems == null ? '' : costs.inputItems;
        costs.labour = costs.labour == null ? '' : costs.labour;
        costs.water = costs.water == null ? '' : costs.water;
        costs.electricity = costs.electricity == null ? '' : costs.electricity;
        costs.gas = costs.gas == null ? '' : costs.gas;
        costs.nutrients = costs.nutrients == null ? '' : costs.nutrients;
        costs.overhead = costs.overhead == null ? '' : costs.overhead;
        costs.feedings = costs.feedings == null ? '' : costs.feedings;

        const optionsMenu = [
            {
              security: reviewSigSec, user: this.props.auth.user.id, users: this.state.reviewers, name: t('reviewer'),
              title: t('reviewed'), setId: () => this.loadRecord(), object: this.state._id, signature: record.data.reviewer,
              objectType: this.props.auth.constants.objectIds.evoraBatch, type: this.props.auth.constants.signatureTypes.reviewer,
              menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
              security: approveSigSec, user: this.props.auth.user.id, users: this.state.signers, name: t('signoff'),
              title: t('approved'), setId: () => this.loadRecord(), object: this.state._id, signature: record.data.signer,
              objectType: this.props.auth.constants.objectIds.evoraBatch, type: this.props.auth.constants.signatureTypes.signer,
              menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = record.data.status === this.props.auth.constants.batchStatuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.batchStatuses.review ? 2 : 0;
        let params = queryString.parse(this.props.location.search);
        let currentRooms = record.data.currentRooms;
        if(currentRooms != null && currentRooms.length > 0){
            currentRooms.forEach((room) => {
                room.room.lengthUOM = room.room.lengthUOM != null ? this.state.lengthUOMs.find(uom => uom.value === room.room.lengthUOM) : null;
                room.room.widthUOM = room.room.widthUOM != null ? this.state.lengthUOMs.find(uom => uom.value === room.room.widthUOM) : null;
                room.room.lightType = room.room.lightType != null ? this.state.lightTypes.find(light => light.value === room.room.lightType) : null;
        })};
        this.setState({
            _id: record.data._id,
            number: record.data.number,
            description: record.data.description,
            status: record.data.status,
            statusSel: statusSel,
            location: record.data.location,
            locSel: locSel,
            strain: record.data.strain,
            strainSel: strainSel,
            startItem: record.data.startItem,
            startItemSel: startItemSel,
            outputItem: record.data.outputItem,
            outputItemSel: {label: record.data.outputItem.name, value: record.data.outputItem._id},
            template: record.data.template,
            templateSel: templateSel,
            startQuantity: record.data.startQuantity,
            currentQuantity: record.data.currentQuantity,
            plannedStart: record.data.plannedStart,
            actualStart: record.data.actualStart,
            vegStart: record.data.vegStart,
            vegCount: record.data.vegCount,
            expectedVegCount: record.data.expectedVegCount,
            flowerStart: record.data.flowerStart,
            flowerCount: record.data.flowerCount,
            expectedFlowerCount: record.data.expectedFlowerCount,
            harvestDate: record.data.harvestDate,
            expectedHarvest: record.data.expectedHarvest,
            expectedHarvestCount: record.data.expectedHarvestCount,
            expectedYield: record.data.expectedYield,
            expectedYieldUOM: record.data.expectedYieldUOM,
            expectedUOMSel: expectedUOMSel,
            harvestedCount: record.data.harvestedCount,
            currentRooms: currentRooms,
            roomHistory: record.data.roomHistory,
            templateObject: record.data.templateObject,
            items: items,
            stages: stages,
            stage: record.data.stage,
            stageSel: stageSel,
            documents: record.data.documents,
            plantedQuantity: record.data.plantedQuantity,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            signed: signed,
            reviewed: reviewed,
            cloneSource: record.data.cloneSource,
            outputLots: record.data.outputLots,
            treatments: record.data.treatments,
            fieldAccess: fieldAccess,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            expectedYieldPerPlant: record.data.expectedYieldPerPlant,
            outputPerHarvestedPlant: record.data.outputPerHarvestedPlant,
            outputPerPlantedPlant: record.data.outputPerPlantedPlant,
            productionSchedule: record.data.productionSchedule,
            productionScheduleSel: record.data.productionScheduleValue,
            productionSchedules: productionSchedules,
            prodActSel: prodActSel,
            productionScheduleActivity: record.data.productionScheduleActivity,
            productionScheduleActivities: record.data.productionScheduleActivities,
            cloneRoomOptions: cloneRoomOptions,
            vegRoomOptions: vegRoomOptions,
            flowerRoomOptions: flowerRoomOptions,
            released: released,
            dryings: record.data.dryings,
            trimmings: record.data.trimmings,
            curings: record.data.curings,
            destructionReason: record.data.destructionReason,
            destructionReasonSel: destructionReasonSel,
            destructionReasonDescription: record.data.destructionReasonDescription,
            finalDocument: record.data.finalDocument,
            daysRemaining: Math.round(daysRemaining),
            daysTranspired: Math.round(daysTranspired),
            strainRecord: record.data.strainRecord,
            batchType: record.data.templateObject.batchType._id,
            daysInStage: record.data.daysInStage,
            createdOn: record.data.createdOn,
            destroyAccess: destroyAccess,
            printAccess: printAccess,
            totalLabour: record.data.totalLabour,
            testResults: record.data.testResults,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            activityIndex: null,
            stageIndex: null,
            deleteOpen: false,
            sales: sales.data,
            costs: costs,
            estimatedLabourCost: record.data.estimatedLabourCost,
            expectedInputCosts: record.data.expectedInputCosts,
            feedings: record.data.feedings,
            latestDate: latestDate,
            inputs: inputs,
            outputs: outputs,
            inputTotal: record.data.totalInputCost,
            observationCount: record.data.observationCount,
            investigationCount: record.data.investigationCount,
            roomCleaningCount: record.data.roomCleaningCount,
            openActivityDrawer: openActivityDrawer,
            stagesOpen: stagesOpen,
            activities: activities,
            lastSequence: record.data.lastSequence,
            processingOrder: record.data.processingOrder
        }, () => {
            const crumbs = [
                { path: '/#/cultivationbatches', label: this.props.t('batches'), screen: this.props.auth.screenDefs.CultivationBatch},
                { path: 'active', label: (this.state.description + " - " + this.state.number), screen: this.props.auth.screenDefs.CultivationBatch}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            if(params != null)
                this.openNotification(params);
        });
        
    };

    openNotification = (params) => {
        let showObservation = null;
        let notiObservationId = '';
        if(params != null && params.observation != null)
            showObservation = params.observation;
        if(params != null && params.observationId != null && showObservation)
            notiObservationId = params.observationId;
        this.setState({notiObservationId});
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeCost = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState(prevState => ({
            costs: {
                ...prevState.costs,
                [name]: value
            }
        }))
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if((isNaN(value)))
            return;
        this.setState({[name]: value});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e}, () => {
            if(this.state.templateObject != null && this.state.templateObject._id != null)
                this.calculateActivityDates();
        });
    };

    calculateActivityDates(){
        let stages = this.state.stages;
        let startDate = this.state.plannedStart != null && this.state.plannedStart !== '' ? this.state.plannedStart : this.state.createdOn;
        if(stages != null && stages.length > 0){
            for(let i = 0; i < stages.length; i++){
                let stage = stages[i];
                for(let j = 0; j < stage.activities.length; j++){
                    let act = stage.activities[j];
                    let date = new Date(startDate);
                    date.setDate(date.getDate() + act.dateShift);
                    act.plannedDate = date;
                    if(act.activityDefinition.changeRoom || act.activityDefinition.definitionId === this.props.auth.constants.batchActivityDefs.harvest)
                        stage.plannedDate = date;
                    if((act.activityDefinition.changeRoom || act.activityDefinition.definitionId === this.props.auth.constants.batchActivityDefs.harvest) && act.actualDate )
                        stage.actualDate = act.actualDate;
                }
            }
        }
        this.setState({stages: stages});
    }

    release = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false}, () => this._release());
    }

    _release = async (e) => {
        const t = this.props.t;
        try {
            let result = await axios.post('/api/releasebatch', {id: this.state._id});
            this.setState({enableSave: true})
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    return = (e) => {
        this.props.history.goBack();
    };

    addActivity = (stageIndex) => (e) => {
        this.setState({stageIndex: stageIndex, showActivity: true});
    };

    clearActivity = (e) => {
        this.setState({
            stageIndex: -1,
            activity: '',
            actSel: null,
            sequence: '',
            dateShift: '',
            sops: [],
            sopsSel: [],
            showActivity: false,
            activityDate: new Date(),
            addRoomCleaning: false,
            newAct: this.props.auth.constants.objectIds.batchActivity,
            newActSel: this.state.newActivityTypes.find(x => x.value === this.props.auth.constants.objectIds.batchActivity)
        })
    };

    changeMulti = (name, data) => (e, newValue) => {
        var val = newValue;
        var ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    saveActivity = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false, }, () => {
            this._saveActivity(e);
        });
    }

    _saveActivity = async (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.newAct === this.props.auth.constants.objectIds.batchActivity){
            if(this.state.activity == null || this.state.activity === '')
                errors[t('activity')] = t('required');
            if(this.state.activityDate == null || this.state.activityDate === '')
                errors[t('activityDate')]  = t('required');
            else {
                if(this.state.latestDate != null && this.state.latestDate !== '' && Date.parse(this.state.activityDate) < this.state.latestDate){
                    errors[t('activityDate')]  = t("activityTooEarly");
                }
            } 
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return;
        }
        let data = {
            batch: this.state._id,
            activity: this.state.activity,
            sops: this.state.sops,
            activityDate: this.state.activityDate,
            stageIndex: this.state.stageIndex,
            activityType: this.state.newAct
        }
        try {
            let result = await axios.post('/api/ebatchaddact', data);
            this.setState({enableSave: true})
            this.clearActivity();
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch (err){
            this.setState({enableSave: true})
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    deleteActivity = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._deleteActivity(e);
        });
    }

    async _deleteActivity(){
        const t = this.props.t;
        if(this.state.stageIndex != null && this.state.activityIndex != null){
            let data = {
                batch: this.state._id,
                stageIndex: this.state.stageIndex,
                activityIndex: this.state.activityIndex
            }
            try {
                let result = await axios.post('/api/ebatchdeleteact', data);
                this.setState({enableSave: true});
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            } catch (err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        }
    };

    deleteClick(stageIndex, activityIndex){
        if(this.state.deleteOpen){
            this.setState({deleteOpen: false, activityIndex : null, stageIndex: null});
        } else {
            this.setState({deleteOpen: true, stageIndex : stageIndex, activityIndex: activityIndex});
        }

    }

    scheduleActivity(stageIndex, activityIndex){
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._scheduleActivity(stageIndex, activityIndex);
        });
    };

    async _scheduleActivity(stageIndex, activityIndex){
        const t = this.props.t;
        let data = {};
        let stages = this.state.stages;
        let act = stages[stageIndex].activities[activityIndex];
        if(act.cleaning !== true && act.preClean !== true && act.postClean !== true){
            data._id = this.state._id;
            data.stageIndex = stageIndex;
            data.activityIndex = activityIndex;
            try {
                let result = await axios.post('/api/ebatchscheduleact', data);
                this.setState({enableSave: true});
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.props.history.push('/roomcleaning?cultivationBatch=' + this.state._id +'&cultivationActivity=' + act._id);
        }
    };

    unlock = (e) => {
        this.toggleUnlock();
    }

    toggleUnlock = () => {
        this.setState({showUnlock: !this.state.showUnlock});
    }

    handleInputPage = (event, newPage) => {
        this.setState({inputPage: newPage}, () => {
            this.sliceInputs();
        });
    };

    sliceInputs(){
        let inputs = this.state.allInputs.slice(this.state.inputRows * this.state.inputPage, (this.state.inputPage * this.state.inputRows) + this.state.inputRows);
        this.setState({inputs: inputs})
    }

    handleInputsPerPage = e => {
        this.setState({inputRows: parseInt(e.target.value), inputPage: 0}, () => {
            this.sliceInputs();
        });
    };

    handleOutputPage = (event, newPage) => {
        this.setState({outputPage: newPage}, () => {
            this.sliceOutputs();
        });
    };

    sliceOutputs(){
        let outputs = this.state.allOutputs.slice(this.state.outputRows * this.state.outputPage, (this.state.outputPage * this.state.outputRows) + this.state.outputRows);
        this.setState({outputs: outputs})
    }

    handleOutputsPerPage = e => {
        this.setState({outputRows: parseInt(e.target.value), outputPage: 0}, () => {
            this.sliceOutputs();
        });
    };

    destroy = async (e) => {
        let query = {ebatch: this.state._id};
        let deviations = this.state.deviations;
        if(deviations == null || deviations.length === 0){
            let devData = await axios.get('/api/deviationselect', {params: query});
            deviations = devData.data;
        }
        this.setState({
            showDestroy: true,
            deviations: deviations
        })
    };

    deviationChange = (e, newValue) => {
        this.setState({
            deviation: newValue != null ? newValue.value : '',
            deviationSel: newValue,
            createDeviation: newValue != null ? false : this.state.createDeviation
        })
    };

    performDestruction = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._performDestruction(e);
        });
    }

    _performDestruction = async (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.destructionReason == null || this.state.destructionReason === '')
            errors[t('destructionReason')]  = t('required');
        if(this.state.destructionReasonDescription == null || this.state.destructionReasonDescription === '')
            errors[t('destructionReasonDescription')]  = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return;
        }
        let data = {
            _id: this.state._id,
            destructionReason: this.state.destructionReason,
            destructionReasonDescription: this.state.destructionReasonDescription,
            detructionDeviation: this.state.deviation,
            createDeviation: this.state.createDeviation
        };
        try {
            let result = await axios.post('/api/ebatchdestroy', data);
            this.setState({showDestroy: false, enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            if(result.data.action === 1){
                this.loadRecord();
            } else if(result.data.action === 2){
                this.props.history.push('/evorabatches');
            }
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    closeDestruction = (e) => {
        this.setState({
            showDestroy: false,
            deviation: '',
            deviationSel: null
        })
    };

    changeCreateDev = (e) => {
        this.setState({createDeviation: !this.state.createDeviation});
    }

    completeBatch = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._completeBatch();
        })
    };

    _completeBatch = async () => {
        const t = this.props.t;
        const body = {_id: this.state._id};
        try {
            const result = await axios.post('/api/ebatchcomplete', body);
            this.setState({enableSave: true});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    cgsCalc = async () => {
        const t = this.props.t;
        let costs = this.state.costs;
        costs._id = this.state._id;
        try {
            let result = await axios.post('/api/batchcgs', costs);
            this.setState({costs: result.data});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    buttonStack = (stack) => {
        const t = this.props.t;
        const fieldAccess = this.state.fieldAccess;
        let buttons = '';        
        switch(stack) {
            case 'activity':
                buttons = [
                    <Button variant="text" size='small' color="secondary" onClick={this.clearActivity}>{t('close')}</Button>,
                    <SaveButton saveClick={() => this.saveActivity()} enabled={fieldAccess}/>
                ];
                break; 
            case 'batchLedger':
                buttons = (
                    <Button color="secondary" size="small" variant='text' onClick={() => this.toggleOpen('batchLedgerDialog', false)}>{t('close')}</Button>
                );
                break;
            case 'cgs':
                buttons = [
                    <Button variant="text" size='small' color="secondary" onClick={() => this.toggleOpen('cgsDialog', false)}>{t('close')}</Button>,
                    <Button variant='contained' size='small' color='primary' onClick={() => this.cgsCalc()} disabled={!this.state.security.update}>{t('calculate')}</Button>
                ];
                break;
            case 'destroyBatch':
                buttons = [
                    <Button variant="text" color="secondary" size="small" onClick={this.closeDestruction}>{t('close')}</Button>,
                    <DestroyButton destroyClick={this.performDestruction} enabled={fieldAccess && this.state.security.delete}/>
                ];
                break;                    
            case 'investigations':
                buttons = (
                    <Button color="secondary" size="small" variant='text' onClick={() => this.toggleOpen('investigationDialog', false)}>{t('close')}</Button>
                );
                break;  
            case 'observations':
                buttons = (
                    <Button color="secondary" size="small" variant='text' onClick={() => this.toggleOpen('observationDialog', false)}>{t('close')}</Button>
                );
                break;  
            case 'strain':
                buttons = (
                    <Button color="secondary" size="small" variant='text' onClick={() => this.toggleOpen('strainDialog', false)}>{t('close')}</Button>
                );
                break;    
            case 'room':
                buttons = (
                    <Button color="secondary" size="small" variant='text' onClick={() => this.toggleOpen('roomDialog', false)}>{t('close')}</Button>
                );
                break;     
            case 'roomCleaning':
                buttons = (
                    <Button color="secondary" size="small" variant='text' onClick={() => this.toggleOpen('roomCleaningDialog', false)}>{t('close')}</Button>
                );
                break; 
            case 'roomFeedings':
                buttons = (
                    <Button color="secondary" size="small" variant='text' onClick={() => this.toggleOpen('roomFeedingDialog', false)}>{t('close')}</Button>
                );
                break;  
            case 'testResults':
                buttons = (
                    <Button color="secondary" size="small" variant='text' onClick={() => this.toggleOpen('testResultsDialog', false)}>{t('close')}</Button>
                );
                break;  
            case 'treatments':
                buttons = (
                    <Button color="secondary" size="small" variant='text' onClick={() => this.toggleOpen('treatmentsDialog', false)}>{t('close')}</Button>
                );
                break;  
            case 'sequence':
                buttons = [
                    <Button color="secondary" size="small" variant="text" onClick={() => this.toggleSequence()}>{t('close')}</Button>,
                    <Button color="primary" size="small" variant="contained" onClick={this.editLastSequence}>{t('save')}</Button>
                ];
                break;
            default:
                break;
        }
        return buttons;
    }

    toggleSequence = (e) => {
        this.setState({showSequence: !this.state.showSequence});
    }

    toggleOpen = (drawer, open) => this.setState({ [drawer]: open === this.state[drawer] ? '' : open });

    toggleStage = (i) => {
        let rows = this.state.stagesOpen;
        rows[i].open = !rows[i].open;
        this.setState({stagesOpen: rows});
        sessionStorage.setItem('batchStagesOpen', JSON.stringify({batch: this.state._id, stagesOpen: rows}));
    }

    togglePrintViewer = () => {
        this.setState({printViewer: !this.state.printViewer});
    }

    editLastSequence = async (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.newSequence === '' || this.state.newSequence == null || isNaN(this.state.newSequence)){
            errors[t('newSequence')] = this.props.t('required');
            this.props.dispatch({ type: 'SET_ERRORS', errors: errors });
            return;
        }
        let newSequence = parseInt(this.state.newSequence);
        let lastSequence = this.state.lastSequence;
        if(newSequence === lastSequence){
            errors[t('newSequence')] = this.props.t('sequenceNotMatch');
            this.props.dispatch({ type: 'SET_ERRORS', errors: errors });
            return;
        }
        let highestSequence = 0;
        for(let stage of this.state.stages){
            for(let act of stage.activities){
                if(act.activity != null && act.activity.sequence != null){
                    let actSequence = parseInt(act.activity.sequence);
                    if(actSequence > highestSequence){
                        highestSequence = actSequence;
                    }
                }
            }
        }
        if(newSequence !== highestSequence){
            errors[t('newSequence')] = this.props.t('sequenceMatchHighest');
            this.props.dispatch({ type: 'SET_ERRORS', errors: errors });
            return;
        }
        try {
            let result = await axios.post('/api/ebatchsequence', {id: this.state._id, sequence: newSequence});
            this.setState({showSequence: false, newSequence: ''});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    render(){
        const { constants, destroyAccess, disableActions, enableRelease, fieldAccess, processingOrder,
                inputs, outputs, printAccess, security, stages, status, transferOnly, treatments } = this.state;
        const { errors, t } = this.props;
        const micro = this.props.auth.user.micro;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        destroyClick={this.destroy}
                        editClick={() => this.toggleOpen('dialogOpen', true)}
                        unlockClick={this.unlock}
                        destroyAccess={destroyAccess}
                        fieldAccess={fieldAccess}
                        status={status}
                        constants={this.props.auth.constants}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        unlockAccess={security.unlock}
                        unlockCallback={this.loadRecord}
                        toggleUnlock={this.toggleUnlock}
                        showUnlock={this.state.showUnlock}
                        objectType={this.props.auth.constants.objectIds.evoraBatch}
                        id={this.state._id}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.finalDocument]}
                        printViewClick={this.togglePrintViewer}
                        printViewTitle={t('batchRecord')}
                        editSequenceClick={this.props.auth.user.internal ? this.toggleSequence : null}
                        enablePrintView={this.state.status === this.props.auth.constants.batchStatuses.signedOff || 
                                         this.state.status === this.props.auth.constants.batchStatuses.destroyed }
                    />
                </BreadcrumbBar>
                <Grid container spacing={2} sx={{ ml: -0.75, mt: 1}}>
                    <Grid direction='column' item container xs={12} spacing={1} sx={{ display: { xs: 'none', md: 'grid'}}}>
                        <Grid container spacing={1} sx={{ alignContent: 'flex-start' }}>
                            <Grid item xs={2}>
                                <Box sx={{ bgcolor: (status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released ? 'grey.300' : 'primary.main'), borderRadius: 3.75, p: 1, height: '125px', textAlign: 'center'}}>
                                    <Typography variant='body2' 
                                            sx={{
                                                fontWeight: 'bold',
                                                color: (status === this.props.auth.constants.batchStatuses.inserted || 
                                                        status === this.props.auth.constants.batchStatuses.released  ?
                                                        'primary.main' : '#FFF')
                                                }}>
                                        {t('observations')}
                                    </Typography>
                                    <br />
                                    <Button
                                        disableRipple
                                        disabled={status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released}
                                        variant='text'
                                        onClick={() => this.toggleOpen('observationDialog', true)} 
                                        sx={{ color: '#FFF', fontWeight: 'bold', fontSize: '2.8rem' }} 
                                    >{this.state.observationCount}</Button>
                                </Box>                                
                            </Grid>
                            {!micro &&
                                <Aux>
                                    <Grid item xs={2}>
                                        <Box sx={{ bgcolor: (status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released ? 'grey.300' : 'primary.main'), borderRadius: 3.75, p: 1, height: '125px', textAlign: 'center'}}>
                                            <Typography variant='body2' sx={{ fontWeight: 'bold', color: (status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released ? 'primary.main' : '#FFF')}}>
                                                {t('roomFeedings')}
                                            </Typography>
                                            <br />
                                            <Button
                                                disableRipple
                                                disabled={status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released}
                                                variant='text'
                                                onClick={() => this.toggleOpen('roomFeedingDialog', true)} 
                                                sx={{ color: '#FFF', fontWeight: 'bold', fontSize: '2.8rem' }} 
                                            >{this.state.feedings != null ? this.state.feedings.length : 0}</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box sx={{ bgcolor: (status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released ? 'grey.300' : 'primary.main'), borderRadius: 3.75, p: 1, height: '125px', textAlign: 'center'}}>
                                            <Typography variant='body2' sx={{ fontWeight: 'bold', color: (status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released ? 'primary.main' : '#FFF')}}>
                                                {t('treatments')}
                                            </Typography>
                                            <br/>
                                            <Button
                                                disableRipple
                                                disabled={status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released}
                                                variant='text'
                                                onClick={() => this.toggleOpen('treatmentsDialog', true)} 
                                                sx={{ color: '#FFF', fontWeight: 'bold', fontSize: '2.8rem' }} 
                                            >{this.state.treatments.count}</Button>
                                        </Box>                                
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Box sx={{ bgcolor: (status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released ? 'grey.300' : 'primary.main'), borderRadius: 3.75, p: 1, height: '125px', textAlign: 'center'}}>
                                            <Typography variant='body2' sx={{ fontWeight: 'bold', color: (status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released ? 'primary.main' : '#FFF')}}>
                                                {t('cleaningRecords')}
                                            </Typography>
                                            <br />
                                            <Button
                                                disableRipple
                                                disabled={status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released}
                                                variant='text'
                                                onClick={() => this.toggleOpen('roomCleaningDialog', true)} 
                                                sx={{ color: '#FFF', fontWeight: 'bold', fontSize: '2.8rem' }} 
                                            >{this.state.roomCleaningCount}</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Box sx={{ bgcolor: (status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released ? 'grey.300' : 'primary.main'), borderRadius: 3.75, p: 1, height: '125px', textAlign: 'center'}}>
                                            <Typography variant='body2' sx={{ fontWeight: 'bold', color: (status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released ? 'primary.main' : '#FFF')}}>
                                                {t('investigations')}
                                            </Typography>
                                            <br />
                                            <Button
                                                disableRipple
                                                disabled={status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released}
                                                variant='text'
                                                onClick={() => this.toggleOpen('investigationDialog', true)} 
                                                sx={{ color: '#FFF', fontWeight: 'bold', fontSize: '2.8rem' }} 
                                            >{this.state.investigationCount}</Button>
                                        </Box>                                
                                    </Grid>
                                </Aux>
                            } 
                            <Grid item xs={2} >
                                <Box sx={{ bgcolor: (status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released ? 'grey.300' : 'primary.main'), borderRadius: 3.75, p: 1, height: '125px', textAlign: 'center'}}>
                                    <Typography variant='body2' sx={{ fontWeight: 'bold', color: (status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released ? 'primary.main' : '#FFF')}}>
                                        {t('testResults')}
                                    </Typography>
                                    <br />
                                    <Button
                                        disableRipple
                                        disabled={status === this.props.auth.constants.batchStatuses.inserted || status === this.props.auth.constants.batchStatuses.released}
                                        variant='text'
                                        onClick={() => this.toggleOpen('testResultsDialog', true)} 
                                        sx={{ color: '#FFF', fontWeight: 'bold', fontSize: '2.8rem' }} 
                                    >{this.state.testResults != null && this.state.testResults.length != null && this.state.testResults.length > 0 ? this.state.testResults.length : 0}</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid direction='column' item container xs={12} lg={2} spacing={1} >
                        <Panel>
                            <Grid container spacing={0} >
                                <Grid item xs={12} sx={{ mb: 1 }}>
                                    <BoxTitle title={t('details')} />
                                </Grid>
                                {status === this.props.auth.constants.batchStatuses.inserted &&
                                    <Grid item xs={12} sx={{ border: 2, borderColor: 'grey.300', p: 1, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                                        <ReleaseButton fullWidth releaseClick={this.release} enabled={enableRelease}/>
                                    </Grid>                                        
                                }
                                {(status === this.props.auth.constants.batchStatuses.harvested || (status === this.props.auth.constants.batchStatuses.growing &&
                                    (this.state.currentQuantity === 0 || this.state.currentQuantity === '0'))) &&
                                    <Grid item xs={12} sx={{ border: 2, borderColor: 'grey.300', p: 1, borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
                                        <CompleteButton fullWidth={true} completeClick={this.completeBatch} enabled={security.complete} buttonName={t('completeBatch')}/>
                                    </Grid>
                                }
                                <Grid item xs={12} sx={{ border: 2, borderColor: 'grey.300', p: 1, 
                                                         borderTopLeftRadius: (status === this.props.auth.constants.batchStatuses.harvested || (status === this.props.auth.constants.batchStatuses.growing &&
                                                                              (this.state.currentQuantity === 0 || this.state.currentQuantity === '0'))) ? 0 : 10,
                                                         borderTopRightRadius: (status === this.props.auth.constants.batchStatuses.harvested || (status === this.props.auth.constants.batchStatuses.growing &&
                                                                               (this.state.currentQuantity === 0 || this.state.currentQuantity === '0'))) ? 0 : 10 }}>
                                    <FormControl>
                                        <FormLabel>{t('strain')}
                                            <IconButton disableRipple aria-label="strain dialog" size="small" onClick={() => this.toggleOpen('strainDialog', true)} >
                                                <OpenInNewRoundedIcon color='primary' fontSize='small' sx={{ mb: 0.75 }}/>
                                            </IconButton>
                                        </FormLabel>
                                        {this.state.strainSel !== null ?
                                            <Link to={'/strain/' + this.state.strainRecord._id}>
                                                {this.state.strainSel.label}
                                            </Link> : ''
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sx={{ borderBottomLeftRadius: (status === this.props.auth.constants.batchStatuses.harvested || (status === this.props.auth.constants.batchStatuses.growing &&
                                                                              (this.state.currentQuantity === 0 || this.state.currentQuantity === '0'))) ? 10 : 0,
                                                         borderBottomRightRadius: (status === this.props.auth.constants.batchStatuses.harvested || (status === this.props.auth.constants.batchStatuses.growing &&
                                                                               (this.state.currentQuantity === 0 || this.state.currentQuantity === '0'))) ? 10 : 0,
                                                         border: 2, borderColor: 'grey.300', p: 1}}>
                                    <FormControl>
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.statusSel != null && this.state.statusSel !== '' ? this.state.statusSel.label : ''}
                                    </FormControl>
                                </Grid>
                                {status !== this.props.auth.constants.batchStatuses.inserted &&
                                    status !== this.props.auth.constants.batchStatuses.released &&
                                    status !== this.props.auth.constants.batchStatuses.destroyed &&
                                    status !== this.props.auth.constants.batchStatuses.finished &&
                                    status !== this.props.auth.constants.batchStatuses.harvested &&
                                    status !== this.props.auth.constants.batchStatuses.signedOff &&
                                    status !== this.props.auth.constants.batchStatuses.review &&
                                    status !== this.props.auth.constants.batchStatuses.complete &&
                                    <Grid container item>
                                        <Grid item xs={8} sx={{ border: 2, borderColor: 'grey.300', p: 1}}>
                                            <FormControl>
                                                <FormLabel>{t('growthStage')}</FormLabel>
                                                {this.state.stageSel != null ? this.state.stageSel.label : ''}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}  sx={{ border: 2, borderColor: 'grey.300', p: 1, textAlign: 'right' }}>
                                            <FormControl >
                                                <FormLabel >{t('day')}</FormLabel>
                                                <Typography>{this.state.daysInStage ?? 0}</Typography>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sx={(status === this.props.auth.constants.batchStatuses.complete ||
                                                                status === this.props.auth.constants.batchStatuses.review ||
                                                                status === this.props.auth.constants.batchStatuses.signedOff) ? 
                                                                { border: 2, borderColor: 'grey.300', p: 1 } : bottomTableDesign}>
                                            <FormControl>
                                                <FormLabel>
                                                    {t('rooms')}
                                                    <IconButton disableRipple aria-label="room dialog" size="small" onClick={() => this.toggleOpen('roomDialog', true)} >
                                                        <OpenInNewRoundedIcon color='primary' fontSize='small' sx={{ mb: 0.75 }}/>
                                                    </IconButton>
                                                </FormLabel>
                                                {/* 
                                                
                                                    <div style={{ display: 'flex' }}>
                                                    {this.state.currentRooms != null && this.state.currentRooms.map((room, i) => {
                                                        if(i === this.state.currentRooms.length - 1)
                                                            return <Typography sx={{ display: 'inline' }} key={'currentRoom-' + i}><Link to={'/room/' + room.room._id}>{room.room.name + ' (' + room.count + ')'}</Link></Typography>;
                                                        else
                                                            return (<Typography sx={{ display: 'inline', mr: 0.3 }} key={'currentRoom-' + i}><Link to={'/room/' + room.room._id}>{room.room.name + ' (' + room.count + ')'}</Link>{','}</Typography>);
                                                    })}
                                                </div> */}
                                            </FormControl>
                                            <TableContainer >
                                                <Table size='small' sx={{ width: '100%'}}>
                                                    <TableBody>
                                                        {this.state.currentRooms != null && this.state.currentRooms.length > 0 && this.state.currentRooms.map((room, i) => 
                                                            <TableRow key={'room-' + i} sx={{ borderBottom: 'hidden' }}>
                                                                <TableCell key={'room-' + i + '-name'} sx={{ borderRight: '2px solid rgba(224, 224, 224, 1)' }}>
                                                                    {room.room !=null && room.room._id != null && room.room.name != null ?
                                                                        <Link to={'/room/' + room.room._id}>{room.room.name}</Link> : ''
                                                                    }
                                                                </TableCell>
                                                                <TableCell key={'room-' + i + '-plants'} sx={{ width: { xs: '50%', lg: '45%'}, textAlign: 'right' }}>
                                                                    {room.items != null && room.items.length > 0 && room.items.map((item, j) => {
                                                                        if(item.isMother != null && item.isMother === true) {
                                                                            return (
                                                                                <Aux key={'roomItemType-' + j}>
                                                                                    <Typography key={'room-' + i + '-plants-' + j} variant='body2' sx={{ fontWeight: 'bold', display: 'inline', mt: 2.5, mr: 1}}>{item.count}</Typography>
                                                                                    <Tooltip title={t('mothers')} placement='right' ><MotherIcon sx={{ color: '#00AC89'}} /></Tooltip>
                                                                                    {room.items.length > 1 && j < room.items.length - 1 ? <br /> : ''}
                                                                                </Aux>
                                                                            )
                                                                        }else if(item.isClone != null && item.isClone === true){
                                                                            return (
                                                                                <Aux key={'roomItemType-' + j}>
                                                                                    <Typography key={'room-' + i + '-plants-' + j} variant='body2' sx={{ fontWeight: 'bold', display: 'inline', mt: 2.5, mr: 1}}>{item.count}</Typography>
                                                                                    <Tooltip title={t('clones')} placement='right' ><CloneIcon color='success' /></Tooltip>
                                                                                    {room.items.length > 1 && j < room.items.length - 1 ? <br /> : ''}
                                                                                </Aux>
                                                                            )
                                                                        }else if(item.isVeg != null && item.isVeg === true){
                                                                            return (
                                                                                <Aux key={'roomItemType-' + j}>
                                                                                    <Typography key={'room-' + i + '-plants-' + j} variant='body2' sx={{ fontWeight: 'bold', display: 'inline', mt: 2.5, mr: 1}}>{item.count}</Typography>
                                                                                    <Tooltip title={t('veg')} placement='right' ><VegIcon color='secondary' /></Tooltip>
                                                                                    {room.items.length > 1 && j < room.items.length - 1 ? <br /> : ''}
                                                                                </Aux>
                                                                            )
                                                                        }else if(item.isFlower != null && item.isFlower === true){
                                                                            return (
                                                                                <Aux key={'roomItemType-' + j}>
                                                                                    <Typography key={'room-' + i + '-plants-' + j} variant='body2' sx={{ fontWeight: 'bold', display: 'inline', mt: 2.5, mr: 1}}>{item.count}</Typography>
                                                                                    <Tooltip title={t('flowers')} placement='right' ><FlowerIcon color='primary' /></Tooltip>
                                                                                    {room.items.length > 1 && j < room.items.length - 1 ? <br /> : ''}
                                                                                </Aux>
                                                                            )
                                                                        }else{
                                                                            return (
                                                                                <Aux key={'roomItemType-' + j}>
                                                                                    <Typography key={'room-' + i + '-plants-' + j} variant='body2' sx={{ fontWeight: 'bold', display: 'inline'}}>{t('none')}</Typography>
                                                                                </Aux>
                                                                            )
                                                                        }
                                                                        
                                                                    })}
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                }
                                {(status === this.props.auth.constants.batchStatuses.complete ||
                                    status === this.props.auth.constants.batchStatuses.review ||
                                    status === this.props.auth.constants.batchStatuses.signedOff) && !this.props.auth.user.micro &&
                                    <Grid item xs={12} sx={bottomTableDesign}>
                                        <FormControl>
                                            <FormLabel>
                                                {t('costPerGram')}
                                                <IconButton disableRipple aria-label="room dialog" size="small" onClick={() => this.toggleOpen('cgsDialog', true)} >
                                                    <OpenInNewRoundedIcon color='primary' fontSize='small' sx={{ mb: 0.75 }}/>
                                                </IconButton>
                                            </FormLabel>
                                            <NumericFormat displayType={'text'} value={this.state.costs.costPerGram ?? 0} thousandSeparator={true} decimalScale={2} prefix={'$'} />
                                        </FormControl>
                                    </Grid>
                                }                                        
                            </Grid>
                        </Panel>
                    </Grid>
                    <Grid direction='column' item container xs={12} lg={9} spacing={1} >
                        <Panel>
                            <SubSectionHeading title={t('activitySchedule')} />
                            <Grid container spacing={1} sx={{ mb: 2 }}>
                                <Grid item xs={12}>
                                    <TableContainer>
                                        <Table size="small" stickyHeader >
                                            <TableHead>
                                                <TableRow key='stages-header'>
                                                    <TableCell sx={{ width: '30px'}} />
                                                    <TableCell key='stages-stageName-header' sx={{ width: '50px'}}>{t('stage')}</TableCell>
                                                    <TableCell key='stages-date-header' sx={{ width: '50px'}}>{t('date')}</TableCell>
                                                    <TableCell key='stages-activities-header' sx={{ textAlign: 'right', width: '40px' }} >{t('activities')}</TableCell>
                                                    <TableCell key='stages-adjustment-header' sx={{ textAlign: 'right', width: '40px' }} >{t('adjustment')}</TableCell>
                                                    <TableCell key='stages-count-header' sx={{ width: '40px' }} >{t('count')}</TableCell>
                                                    <TableCell key='stages-estimatedLabour-header' sx={{ textAlign: 'right', width: '40px', display: { xs: 'none', xl: 'table-cell' } }} >{t('estLabour') + ' (h)'}</TableCell>
                                                    <TableCell key='stages-actualLabour-header' sx={{ textAlign: 'right', width: '40px', display: { xs: 'none', xl: 'table-cell' } }} >{t('actualLabour') + ' (h)'}</TableCell>
                                                    {/* <TableCell key='activities--header'>{t('')}</TableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {stages != null && stages.map((stage, i) => 
                                                    <Aux key={'stage-Arow-' + i} >
                                                        <TableRow key={'stage-row-' + i} >
                                                            <TableCell sx={{ width: '30px'}}>
                                                                <IconButton aria-label="expand row" size="small" onClick={() => this.toggleStage(i)} >
                                                                    {this.state.stagesOpen[i].open === true ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell key={'stages-stageName-' + i} sx={{ width: '50px'}}>{stage.stage != null && stage.stage.label != null && stage.stage.label !== '' ? stage.stage.label : ''}</TableCell>                                                                    
                                                            <TableCell key={'stages-date-' + i} sx={{ width: '50px'}}>
                                                                {stage.actualDate != null && stage.actualDate !== '' ? 
                                                                    <DateDisplay format={this.props.auth.user.dateFormat} value={stage.actualDate}/> :
                                                                    stage.plannedDate != null && stage.plannedDate !== '' ?
                                                                    <em><DateDisplay format={this.props.auth.user.dateFormat} value={stage.plannedDate}/></em> : ''
                                                                }
                                                            </TableCell>                                                                    
                                                            <TableCell key={'stages-activities-' + i} sx={{ textAlign: 'right', width: '35px' }} >{stage.activities != null ? stage.activities.length : 0}</TableCell>
                                                            <TableCell key={'stages-adjustment-' + i} sx={{ textAlign: 'right', width: '40px' }} >{stage.totalAdjustments != null && stage.totalAdjustments !== '' ? stage.totalAdjustments : 0} </TableCell>
                                                            <TableCell key={'stages-count-' + i} sx={{ textAlign: 'right', width: '40px' }} >{stage.plantCount != null && stage.plantCount !== '' ? stage.plantCount : 0}</TableCell>
                                                            <TableCell key={'stages-estimatedLabour-' + i} sx={{ textAlign: 'right', width: '40px', display: { xs: 'none', xl: 'table-cell' } }} >
                                                                {stage.estimatedLabour != null && stage.estimatedLabour !== '' ? 
                                                                    <NumericFormat displayType={'text'} value={stage.estimatedLabour} thousandSeparator={true} decimalScale={2} />
                                                                    : 0
                                                                }
                                                            </TableCell>
                                                            <TableCell key={'stages-actualLabour-' + i} sx={{ textAlign: 'right', width: '40px', display: { xs: 'none', xl: 'table-cell' } }} >
                                                                {stage.actualLabour != null && stage.actualLabour !== '' ? 
                                                                    <NumericFormat displayType={'text'} value={stage.actualLabour} thousandSeparator={true} decimalScale={2} />
                                                                    : 0
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        {this.state.stagesOpen[i].open === true &&
                                                            <TableRow key={'stage-drawer-' + i}>
                                                                <TableCell sx={{paddingBottom: 0, paddingTop: 0, bgcolor: '#eeeeee' }} colSpan={8}>
                                                                    <Collapse in={this.state.stagesOpen[i].open === true} timeout="auto" unmountOnExit>
                                                                        <Box margin={1} component={'div'}>
                                                                            <Grid container spacing={2}>
                                                                                <Grid item container xs={12} sx={{ justifyContent: 'space-between'}}>
                                                                                    <Grid item xs={6} >
                                                                                        <FormLabel>
                                                                                            {stage.stage != null && stage.stage.label != null && stage.stage.label !== '' ? stage.stage.label : ''}
                                                                                        </FormLabel>
                                                                                    </Grid>
                                                                                    <Grid item >
                                                                                        <Tooltip title={t('addActivity')}>
                                                                                            <AddActivityButton 
                                                                                                aria-label="Add Activity" 
                                                                                                onClick={this.addActivity(i)} 
                                                                                                enabled={this.state.status === this.props.auth.constants.batchStatuses.growing || 
                                                                                                            this.state.status === this.props.auth.constants.batchStatuses.harvested}
                                                                                            />
                                                                                        </Tooltip>
                                                                                    </Grid>                                                                                            
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <TableContainer component={Paper} >
                                                                                        <Table aria-label={'stage-' + i + '-activities'}>
                                                                                            <TableHead>
                                                                                                <TableRow key='activities-header'>
                                                                                                    <TableCell key='activities-accordionBtn-header' sx={{ width: '20px' }} />
                                                                                                    
                                                                                                    <TableCell key='activities-sequence-header' sx={{ width: '20px' }} />
                                                                                                    <TableCell key='activities-date-header' sx={{ width: '50px', display: {xs: 'table-cell', xl: 'none'}  }}>{t('date')}</TableCell>
                                                                                                    <TableCell key='activities-plannedDate-header' sx={{ width: '50px', display: {xs: 'none', xl: 'table-cell'}  }}>{t('planned')}</TableCell>
                                                                                                    <TableCell key='activities-startDate-header' sx={{ width: '50px', display: {xs: 'none', xl: 'table-cell'}  }}>{t('actual')}</TableCell>
                                                                                                    <TableCell key='activities-number-header' sx={{ width: '75px' }}>{t('number')}</TableCell>
                                                                                                    <TableCell key='activities-name-header'>{t('name')}</TableCell>
                                                                                                    <TableCell key='activities-adjustment-header' sx={{ textAlign: 'right', width: '20px', display: {xs: 'none', xl: 'table-cell'} }} >{t('adjustment')}</TableCell>
                                                                                                    <TableCell key='activities-count-header' sx={{ textAlign: 'right', width: '20px', display: {xs: 'none', lg: 'table-cell'} }} >{t('count')}</TableCell>
                                                                                                    <TableCell key='activities-estimatedLabour-header' sx={{ textAlign: 'right', display: {xs: 'none', xl: 'table-cell'} }} >{t('estimatedLabour') + ' (h)'}</TableCell>
                                                                                                    <TableCell key='activities-actualLabour-header' sx={{ textAlign: 'right', display: {xs: 'none', xl: 'table-cell'} }} >{t('actualLabour') + ' (h)'}</TableCell>
                                                                                                    <TableCell key='activities-status-header' >{t('status')}</TableCell>
                                                                                                    <TableCell key='activities-buttons-header' />
                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody>
                                                                                                {stage.activities != null && stage.activities !== '' && stage.activities.map((activity, j) => 
                                                                                                    <Aux key={'stage-' + i + '-activity-' + j}>
                                                                                                        <TableRow key={'row-stage-' + i + '-activity-' + j}>
                                                                                                            <TableCell  sx={{ width: '20px' }}>
                                                                                                                <IconButton aria-label="expand row" size="small" onClick={() => this.toggleOpen('openActivityDetailDrawer', i + '-' + j)} >
                                                                                                                    {this.state.openActivityDetailDrawer === i + '-' + j ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                                                                </IconButton>
                                                                                                            </TableCell>
                                                                                                            <TableCell key={'activities-sequence-' + i + j} sx={{ width: '20px' }}>{activity.activity != null && activity.activity.sequence != null ? activity.activity.sequence : ''}</TableCell>
                                                                                                            <TableCell key={'activities-plannedDate-' + i + j} sx={{ width: '50px' }} ><DateDisplay format={this.props.auth.user.dateFormat} value={activity.plannedDate}/></TableCell>
                                                                                                            <TableCell key={'activities-startDate-' + i + j} sx={{ width: '50px' }} >
                                                                                                                {activity.actualDate != null && activity.actualDate !== '' ? <DateDisplay format={this.props.auth.user.dateFormat} value={activity.actualDate}/> : '' }
                                                                                                            </TableCell>
                                                                                                            <TableCell key={'activities-number-' + i + j}  sx={{ width: '75px' }}
                                                                                                                >{activity.activity != null && activity.activity !== '' ? <Link to={'/batchactivity/' + activity.activity._id} >{activity.activity.number}</Link> : 
                                                                                                                activity.roomCleaning != null ? <Link to={'/roomcleaning/' + activity.roomCleaning._id}>{activity.roomCleaning.number}</Link> : ''}
                                                                                                            </TableCell>
                                                                                                            <TableCell key={'activities-name-' + i + j} >
                                                                                                                {((activity.activity != null && disableActions === false) || (activity.activity != null && transferOnly === true && 
                                                                                                                  (activity.activityDefinition.changeRoom === true || 
                                                                                                                   activity.activityDefinition.definitionId === constants.batchActivityDefs.harvest))) ? 
                                                                                                                    <Link to={'/batchactivity/' + activity.activity._id} >{activity.activityDefinition.description}</Link> :
                                                                                                                    activity.activityDefinition.description
                                                                                                                }
                                                                                                            </TableCell>
                                                                                                            <TableCell key={'activities-adjustment-' + i + j} sx={{ textAlign: 'right', display: {xs: 'none', xl: 'table-cell'} }} >{activity.ledgers != null && activity.ledgers.results != null ? activity.ledgers.results.totalAdjustment : 0}</TableCell>
                                                                                                            <TableCell key={'activities-count-' + i + j} sx={{ textAlign: 'right', display: {xs: 'none', lg: 'table-cell'} }} >{activity.ledgers != null && activity.ledgers.results != null ? activity.ledgers.results.plantCount : 0}</TableCell>
                                                                                                            <TableCell key={'activities-estimatedLabour-' + i + j} sx={{ textAlign: 'right', display: {xs: 'none', xl: 'table-cell'} }} >
                                                                                                                <NumericFormat 
                                                                                                                    displayType={'text'} 
                                                                                                                    value={activity.estimatedLabour != null ? activity.estimatedLabour : 0}
                                                                                                                    thousandSeparator={true} 
                                                                                                                    decimalScale={2} 
                                                                                                                />
                                                                                                            </TableCell>
                                                                                                            <TableCell key={'activities-actualLabour-' + i + j} sx={{ textAlign: 'right', display: {xs: 'none', xl: 'table-cell'} }} >
                                                                                                                <NumericFormat 
                                                                                                                    displayType={'text'} 
                                                                                                                    value={activity.actualLabour != null ? activity.actualLabour : 0}
                                                                                                                    thousandSeparator={true} 
                                                                                                                    decimalScale={2} 
                                                                                                                />
                                                                                                            </TableCell>
                                                                                                            <TableCell key={'activities-status-' + i + j} sx={{ width: '50px'}}>
                                                                                                                {activity.activity !== null && activity.activity !== undefined ? activity.activity.status.label : activity.roomCleaning !== undefined ? 
                                                                                                                    activity.roomCleaning.status.label : t('pending')}
                                                                                                            </TableCell>
                                                                                                            <TableCell key={'activities-buttons-' + i + j} sx={{ width: '30px'}} >
                                                                                                            {this.state.status !== this.props.auth.constants.batchStatuses.inserted && activity.activity == null && activity.schedule == null &&
                                                                                                                <ActionButton label={t('schedule')} enabled={this.state.enableSave} onClick={() => this.scheduleActivity(i, j)} />
                                                                                                            }
                                                                                                            {this.state.status !== this.props.auth.constants.batchStatuses.beingDestroyed && 
                                                                                                                ((activity.added && activity.activity == null && activity.roomCleaning == null && activity.schedule == null) ||
                                                                                                                (activity.activity != null && (activity.activity.status._id === this.props.auth.constants.statuses.new || 
                                                                                                                activity.activity.status._id === this.props.auth.constants.statuses.inprogress)) || (activity.roomCleaning != null && 
                                                                                                                (activity.roomCleaning.status._id === this.props.auth.constants.statuses.new || activity.roomCleaning.status._id === this.props.auth.constants.statuses.inprogress)) ||
                                                                                                                (activity.activity == null && activity.roomCleaning == null)) &&
                                                                                                                <ActionButton label={t('delete')} enabled={this.state.enableSave} onClick={() => this.deleteClick(i, j)} />
                                                                                                            }
                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                        {this.state.openActivityDetailDrawer === i + '-' + j &&
                                                                                                            <TableRow key={'activity-detailsDrawer-' + i + j}>
                                                                                                                <TableCell sx={{paddingBottom: 0, paddingTop: 0, bgcolor: '#d4d4d4' }} colSpan={11}>
                                                                                                                    <Box margin={1} component={'div'}>
                                                                                                                        <Grid container spacing={2}>
                                                                                                                            <Grid item container xs={12} sx={{ justifyContent: 'space-between'}} spacing={2}>
                                                                                                                                <Grid item xs={9} >
                                                                                                                                    <Panel>
                                                                                                                                        <Typography variant="h6" gutterBottom component="div">
                                                                                                                                            {t('ledgers')}
                                                                                                                                        </Typography>
                                                                                                                                        <Table size='small' aria-label="batch-ledgers">
                                                                                                                                            <TableHead>
                                                                                                                                                <TableRow>
                                                                                                                                                    <TableCell>{t('date')}</TableCell>
                                                                                                                                                    <TableCell>{t('transDate')}</TableCell>
                                                                                                                                                    <TableCell>{t('item')}</TableCell>
                                                                                                                                                    <TableCell sx={{ textAlign: 'right' }}>{t('adjustment')}</TableCell>
                                                                                                                                                    <TableCell sx={{ textAlign: 'right' }}>{t('count')}</TableCell>
                                                                                                                                                    <TableCell>{t('room')}</TableCell>
                                                                                                                                                </TableRow>
                                                                                                                                            </TableHead>
                                                                                                                                            <TableBody>
                                                                                                                                                {activity.ledgers != null && activity.ledgers.ledgers != null && activity.ledgers.ledgers.length > 0 ?
                                                                                                                                                    activity.ledgers.ledgers.map((ledger, k) => {
                                                                                                                                                        return (
                                                                                                                                                            <TableRow key={'activity-ledger-' + i + j + k}>
                                                                                                                                                                <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={ledger.date}/></TableCell>
                                                                                                                                                                <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={ledger.transactionDate}/></TableCell>
                                                                                                                                                                <TableCell>{ledger.item.name}</TableCell>
                                                                                                                                                                <TableCell sx={{ textAlign: 'right' }}>{ledger.amount}</TableCell>
                                                                                                                                                                <TableCell sx={{ textAlign: 'right' }}>{ledger.currentAmount}</TableCell>
                                                                                                                                                                <TableCell>{ledger.room.name}</TableCell>
                                                                                                                                                            </TableRow>
                                                                                                                                                        )
                                                                                                                                                    }) : 
                                                                                                                                                    <TableRow>
                                                                                                                                                        <TableCell colSpan={7} sx={{ textAlign: 'center' }}>{t('noLedgers')}</TableCell>
                                                                                                                                                    </TableRow>
                                                                                                                                                }
                                                                                                                                            </TableBody>
                                                                                                                                        </Table>
                                                                                                                                    </Panel>
                                                                                                                                </Grid>
                                                                                                                                <Grid item xs={3} >
                                                                                                                                    <Panel>
                                                                                                                                        <Typography variant="h6" gutterBottom component="div">
                                                                                                                                            {t('summary')}
                                                                                                                                        </Typography>
                                                                                                                                        <Table size="small" aria-label="batch-ledger-summary">
                                                                                                                                            <TableHead>
                                                                                                                                                <TableRow>
                                                                                                                                                    <TableCell>{t('item')}</TableCell>
                                                                                                                                                    <TableCell>{t('count')}</TableCell>
                                                                                                                                                </TableRow>
                                                                                                                                            </TableHead>
                                                                                                                                            <TableBody>
                                                                                                                                                {activity.ledgers != null && activity.ledgers.results != null && activity.ledgers.results.items != null && activity.ledgers.results.items.length > 0 ?
                                                                                                                                                    activity.ledgers.results.items.map((item, k) => {
                                                                                                                                                        return (
                                                                                                                                                            <TableRow key={'activity-ledger-item-' + i + j + k}>
                                                                                                                                                                <TableCell>{item.label}</TableCell>
                                                                                                                                                                <TableCell>{item.count}</TableCell>
                                                                                                                                                            </TableRow>
                                                                                                                                                        );
                                                                                                                                                    }) :
                                                                                                                                                    <TableRow>
                                                                                                                                                        <TableCell colSpan={2} sx={{ textAlign: 'center' }}>{t('noLedgers')}</TableCell>
                                                                                                                                                    </TableRow>
                                                                                                                                                }
                                                                                                                                            </TableBody>
                                                                                                                                        </Table>
                                                                                                                                    </Panel>
                                                                                                                                </Grid>
                                                                                                                            </Grid>
                                                                                                                        </Grid>
                                                                                                                    </Box>
                                                                                                                </TableCell>
                                                                                                            </TableRow>
                                        }
                                                                                                        {j === stage.activities.length - 1 &&
                                                                                                            <TableRow sx={{ display: {xs: 'none', xl: 'table-row'}}} key='totalStageCostsRow'>
                                                                                                                <TableCell colSpan={7} ><Typography style={{ fontWeight: 'bold', textAlign: 'right'}}>{t('total') + ':'}</Typography></TableCell>
                                                                                                                <TableCell sx={{ textAlign: 'right', display: { xs: 'none', lg: 'table-cell' } }} >
                                                                                                                    <Typography style={{ fontWeight: 'bold', textAlign: 'right'}}>
                                                                                                                        <NumericFormat 
                                                                                                                            displayType={'text'} 
                                                                                                                            value={stage.activities != null && stage.activities !== '' ? stage.activities.reduce((total, activity) => total + (activity.estimatedLabour ?? 0), 0) : 0}
                                                                                                                            thousandSeparator={true} 
                                                                                                                            decimalScale={2} 
                                                                                                                        />
                                                                                                                    </Typography>
                                                                                                                </TableCell>
                                                                                                                <TableCell sx={{ textAlign: 'right' }} >
                                                                                                                    <Typography style={{ fontWeight: 'bold', textAlign: 'right'}}>
                                                                                                                        <NumericFormat 
                                                                                                                            displayType={'text'} 
                                                                                                                            value={stage.activities != null && stage.activities !== '' ? stage.activities.reduce((total, activity) => total + (activity.actualLabour ?? 0), 0) : 0}
                                                                                                                            thousandSeparator={true} 
                                                                                                                            decimalScale={2} 
                                                                                                                        />
                                                                                                                    </Typography>
                                                                                                                </TableCell>
                                                                                                                <TableCell colSpan={2} />
                                                                                                            </TableRow>
                                                                                                        }
                                                                                                    </Aux>
                                                                                                )}
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </TableContainer>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Collapse>
                                                                </TableCell>
                                                            </TableRow>
                                                        }
                                                        {i === stages.length - 1 && this.state.openActivityDrawer === '' && 
                                                            <TableRow sx={{ display: {xs: 'none', xl: 'table-row'} }} key='activityResultsRow'>
                                                                <TableCell colSpan={6}><Typography style={{ fontWeight: 'bold', textAlign: 'right'}}>{t('total') + ':'}</Typography></TableCell>
                                                                <TableCell sx={{ textAlign: 'right', display: { xs: 'none', xl: 'table-cell'}}} >
                                                                    <Typography style={{ fontWeight: 'bold', textAlign: 'right'}}>
                                                                        <NumericFormat 
                                                                            displayType={'text'} 
                                                                            value={stages != null && stages !== '' ? stages.reduce((total, sstage) => total + (sstage.estimatedLabour ?? 0), 0) : 0} 
                                                                            thousandSeparator={true} 
                                                                            decimalScale={2} 
                                                                        />
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell sx={{ textAlign: 'right' }} >
                                                                    <Typography style={{ fontWeight: 'bold', textAlign: 'right'}}>
                                                                        <NumericFormat 
                                                                            displayType={'text'} 
                                                                            value={stages != null && stages !== '' ? stages.reduce((total, sstage) => total + (sstage.actualLabour ?? 0), 0) : 0}
                                                                            thousandSeparator={true} 
                                                                            decimalScale={2} 
                                                                        />
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        }
                                                    </Aux>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            {processingOrder?.length > 0 &&
                                <Aux>
                                    <SubSectionHeading title={t('processingHistory')} />
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TableContainer>
                                                <Table size='small' stickyHeader>
                                                    <TableHead>
                                                        <TableRow key='stages-header'>
                                                            <TableCell>{t('process')}</TableCell>
                                                            <TableCell>{t('order')}</TableCell>
                                                            <TableCell>{t('status')}</TableCell>
                                                            <TableCell>{t('startDate')}</TableCell>
                                                            <TableCell>{t('endDate')}</TableCell>
                                                            <TableCell>{t('duration')}</TableCell>
                                                            <TableCell>{t('type')}</TableCell>
                                                            <TableCell sx={{ textAlign: 'right' }}>{t('totalCannabisInputs')}</TableCell>
                                                            <TableCell sx={{ textAlign: 'right' }}>{t('totalCannabisOutputs')}</TableCell>
                                                            <TableCell sx={{ textAlign: 'right' }}>{t('processingLoss')}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {processingOrder?.length > 0 ? 
                                                            processingOrder.map((process, i) => {
                                                                const cureTableRow = this.state.curings.map((curing, cn) => {
                                                                    let duration = ConversionHelper.calculateDaysBetweenDates(curing.startDate, curing.endDate);
                                                                    if(cn === 0) {
                                                                        return (
                                                                            <TableRow key={'cureTableRow-' + cn}>
                                                                                <TableCell rowSpan={this.state.curings.length}>{t('cure')}</TableCell>
                                                                                <TableCell>
                                                                                    <Link to={'/curing/' + curing._id}>{curing.number}</Link>
                                                                                </TableCell>
                                                                                <TableCell>{curing.status?.label}</TableCell>
                                                                                <TableCell>
                                                                                    {curing.startDate != null && curing.startDate !== '' &&
                                                                                        <DateDisplay format={this.props.auth.user.dateFormat} value={curing.startDate}/>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {curing.endDate != null && curing.endDate !== '' &&
                                                                                        <DateDisplay format={this.props.auth.user.dateFormat} value={curing.endDate}/>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <NumericFormat displayType={'text'} value={duration || 0} thousandSeparator decimalScale={1} suffix={' ' + t('days')}/>
                                                                                </TableCell>
                                                                                <TableCell>{t('na')}</TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={curing.startAmount || 0} thousandSeparator decimalScale={3} suffix={curing.startAmountUOM?.label}/>
                                                                                </TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={curing.actualOutput || 0} thousandSeparator decimalScale={3} suffix={curing.startAmountUOM?.label}/>
                                                                                </TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={curing.dryingLoss || 0} thousandSeparator decimalScale={3} suffix={curing.startAmountUOM?.label}/>
                                                                                    <NumericFormat displayType={'text'} value={((curing.dryingLoss / curing.startAmount) * 100) || 0} thousandSeparator decimalScale={2} prefix=' (' suffix='%)'/>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <TableRow key={'cureTableRow-' + cn}>
                                                                                <TableCell>
                                                                                    <Link to={'/curing/' + curing._id}>{curing.number}</Link>
                                                                                </TableCell>
                                                                                <TableCell>{curing.status?.label}</TableCell>
                                                                                <TableCell>
                                                                                    {curing.startDate != null && curing.startDate !== '' &&
                                                                                        <DateDisplay format={this.props.auth.user.dateFormat} value={curing.startDate}/>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {curing.endDate != null && curing.endDate !== '' &&
                                                                                        <DateDisplay format={this.props.auth.user.dateFormat} value={curing.endDate}/>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <NumericFormat displayType={'text'} value={duration || 0} thousandSeparator decimalScale={1} suffix={' ' + t('days')}/>
                                                                                </TableCell>
                                                                                <TableCell>{t('na')}</TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={curing.startAmount || 0} thousandSeparator decimalScale={3} suffix={curing.startAmountUOM?.label}/>
                                                                                </TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={curing.actualOutput || 0} thousandSeparator decimalScale={3} suffix={curing.startAmountUOM?.label}/>
                                                                                </TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={curing.dryingLoss || 0} thousandSeparator decimalScale={3} suffix='%'/>
                                                                                    <NumericFormat displayType={'text'} value={((curing.dryingLoss / curing.startAmount) * 100) || 0} thousandSeparator decimalScale={2} prefix=' (' suffix='%)'/>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                });   
                                                                const dryTableRow = this.state.dryings.map((drying, dn) => {
                                                                    let duration = ConversionHelper.calculateDaysBetweenDates(drying.startDate, drying.endDate);
                                                                    if(dn === 0) {
                                                                        return (
                                                                            <TableRow key={'dryTableRow-' + dn}>
                                                                                <TableCell rowSpan={this.state.dryings.length}>{t('dry')}</TableCell>
                                                                                <TableCell>
                                                                                    <Link to={'/drying/' + drying._id}>{drying.number}</Link>
                                                                                </TableCell>
                                                                                <TableCell>{drying.status?.label}</TableCell>
                                                                                <TableCell>
                                                                                    {drying.startDate != null && drying.startDate !== '' &&
                                                                                        <DateDisplay format={this.props.auth.user.dateFormat} value={drying.startDate}/>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {drying.endDate != null && drying.endDate !== '' &&
                                                                                        <DateDisplay format={this.props.auth.user.dateFormat} value={drying.endDate}/>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <NumericFormat displayType={'text'} value={duration || 0} thousandSeparator decimalScale={1} suffix={' ' + t('days')}/>
                                                                                </TableCell>
                                                                                <TableCell>{drying.type?.label}</TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={drying.startAmount || 0} thousandSeparator decimalScale={3} suffix={drying.startAmountUOM?.label}/>
                                                                                </TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={drying.actualOutput || 0} thousandSeparator decimalScale={3} suffix={drying.startAmountUOM?.label}/>
                                                                                </TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={drying.dryingLoss || 0} thousandSeparator decimalScale={3} suffix={drying.startAmountUOM?.label}/>
                                                                                    <NumericFormat displayType={'text'} value={((drying.dryingLoss / drying.startAmount) * 100) || 0} thousandSeparator decimalScale={2} prefix=' (' suffix='%)'/>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <TableRow key={'dryTableRow-' + dn}>
                                                                                <TableCell>
                                                                                    <Link to={'/drying/' + drying._id}>{drying.number}</Link>
                                                                                </TableCell>
                                                                                <TableCell>{drying.status?.label}</TableCell>
                                                                                <TableCell>
                                                                                    {drying.startDate != null && drying.startDate !== '' &&
                                                                                        <DateDisplay format={this.props.auth.user.dateFormat} value={drying.startDate}/>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {drying.endDate != null && drying.endDate !== '' &&
                                                                                        <DateDisplay format={this.props.auth.user.dateFormat} value={drying.endDate}/>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <NumericFormat displayType={'text'} value={duration || 0} thousandSeparator decimalScale={1} suffix={' ' + t('days')}/>
                                                                                </TableCell>
                                                                                <TableCell>{drying.type?.label}</TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={drying.startAmount || 0} thousandSeparator decimalScale={3} suffix={drying.startAmountUOM?.label}/>
                                                                                </TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={drying.actualOutput || 0} thousandSeparator decimalScale={3} suffix={drying.startAmountUOM?.label}/>
                                                                                </TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={drying.dryingLoss || 0} thousandSeparator decimalScale={3} suffix={drying.startAmountUOM?.label}/>
                                                                                    <NumericFormat displayType={'text'} value={((drying.dryingLoss / drying.startAmount) * 100) || 0} thousandSeparator decimalScale={2} prefix=' (' suffix='%)'/>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                });   
                                                                const trimTableRow = this.state.trimmings.map((trim, tn) => {
                                                                    let duration = ConversionHelper.calculateDaysBetweenDates(trim.startDate, trim.endDate);
                                                                    if(tn === 0) {
                                                                        return (
                                                                            <TableRow key={'trimTableRow-' + tn}>
                                                                                <TableCell rowSpan={this.state.trimmings.length}>{t('trim')}</TableCell>
                                                                                <TableCell>
                                                                                    <Link to={'/trimming/' + trim._id}>{trim.number}</Link>
                                                                                </TableCell>
                                                                                <TableCell>{trim.status?.label}</TableCell>
                                                                                <TableCell>
                                                                                    {trim.startDate != null && trim.startDate !== '' &&
                                                                                        <DateDisplay format={this.props.auth.user.dateFormat} value={trim.startDate}/>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {trim.endDate != null && trim.endDate !== '' &&
                                                                                        <DateDisplay format={this.props.auth.user.dateFormat} value={trim.endDate}/>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <NumericFormat displayType={'text'} value={duration || 0} thousandSeparator decimalScale={1} suffix={' ' + t('days')}/>
                                                                                </TableCell>
                                                                                <TableCell>{trim.type?.map(type => type.label).join(', ')}</TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={trim.startAmount || 0} thousandSeparator decimalScale={3} suffix={trim.startAmountUOM?.label}/>
                                                                                </TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={trim.actualOutput || 0} thousandSeparator decimalScale={3} suffix={trim.startAmountUOM?.label}/>
                                                                                </TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={trim.dryingLoss || 0} thousandSeparator decimalScale={3} suffix={trim.startAmountUOM?.label}/>
                                                                                    <NumericFormat displayType={'text'} value={((trim.dryingLoss / trim.startAmount) * 100) || 0} thousandSeparator decimalScale={2} prefix=' (' suffix='%)'/>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <TableRow key={'trimTableRow-' + tn}>
                                                                                <TableCell>
                                                                                    <Link to={'/trimming/' + trim._id}>{trim.number}</Link>
                                                                                </TableCell>
                                                                                <TableCell>{trim.status?.label}</TableCell>
                                                                                <TableCell>
                                                                                    {trim.startDate != null && trim.startDate !== '' &&
                                                                                        <DateDisplay format={this.props.auth.user.dateFormat} value={trim.startDate}/>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {trim.endDate != null && trim.endDate !== '' &&
                                                                                        <DateDisplay format={this.props.auth.user.dateFormat} value={trim.endDate}/>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <NumericFormat displayType={'text'} value={duration || 0} thousandSeparator decimalScale={1} suffix={' ' + t('days')}/>
                                                                                </TableCell>
                                                                                <TableCell>{trim.type?.map(type => type.label).join(', ')}</TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={trim.startAmount || 0} thousandSeparator decimalScale={3} suffix={trim.startAmountUOM?.label}/>
                                                                                </TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={trim.actualOutput || 0} thousandSeparator decimalScale={3} suffix={trim.startAmountUOM?.label}/>
                                                                                </TableCell>
                                                                                <TableCell sx={{ textAlign: 'right' }}>
                                                                                    <NumericFormat displayType={'text'} value={trim.dryingLoss || 0} thousandSeparator decimalScale={3} suffix={trim.startAmountUOM?.label}/>
                                                                                    <NumericFormat displayType={'text'} value={((trim.dryingLoss / trim.startAmount) * 100) || 0} thousandSeparator decimalScale={2} prefix=' (' suffix='%)'/>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                });                                                          
                                                                if(process === 'dry')
                                                                    return dryTableRow;
                                                                else if(process === 'trim')
                                                                    return trimTableRow;
                                                                else if(process === 'cure')
                                                                    return cureTableRow;
                                                                else
                                                                    return (
                                                                        <TableRow>
                                                                            <TableCell colSpan='10' sx={{ textAlign: 'center' }}>{t('none')}</TableCell>
                                                                        </TableRow>
                                                                    );
                                                            }) : 
                                                            <TableRow>
                                                                <TableCell colSpan='10' sx={{ textAlign: 'center' }}>{t('none')}</TableCell>
                                                            </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Aux>
                            }
                        </Panel>
                        {this.state.deleteOpen &&
                            <ConfirmDelete toggleDialog={() => this.deleteClick()}
                                redirect={() => this.deleteActivity()} open={this.state.deleteOpen} />
                        }
                    </Grid>
                    <Grid direction='column' item container xs={12} lg={this.props.navMenu.drawerOpen != null && this.props.navMenu.drawerOpen === true ? 11 : 6} spacing={1}>
                        <Panel>
                            <SubSectionHeading title={t('inputs')} />
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow key='input-header'>
                                                <TableCell key='input-date-header' sx={{ width: '50px' }}>{t('date')}</TableCell>
                                                <TableCell key='input-itemCategory-header' sx={{ width: '100px' }}>{t('category')}</TableCell>
                                                <TableCell key='input-itemName-header'>{t('name')}</TableCell>
                                                <TableCell key='input-itemAmount-header' sx={{ textAlign: 'right', width: '100px' }}>{t('amount')}</TableCell>
                                                <TableCell key="input-itemUnitcost-header" sx={{ textAlign: 'right', width: '100px' }}>{t('unitCost')}</TableCell>
                                                <TableCell key="input-itemLinecost-header" sx={{ textAlign: 'right', width: '100px' }}>{t('lineCost')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {inputs.length > 0 ? inputs.map((input, i) => (
                                                input.date !== undefined &&
                                                    <TableRow key={'input-row-' + i} sx={{ width: '100%'}} >
                                                        <TableCell key={'input-date-' + i}>
                                                            <DateDisplay format={this.props.auth.user.dateFormat} value={input.date}/>
                                                        </TableCell>
                                                        <TableCell key={'input-itemCategory-' + i} >{input.category != null && input.category.label != null ? input.category.label : ''}</TableCell>
                                                        <TableCell key={'input-itemName-' + i} ><Link to={'/item/' + input._id}>{input.name}</Link></TableCell>
                                                        <TableCell key={'input-itemAmount-' + i} sx={{ textAlign: 'right' }}>{input.uom != null && input.uom.label != null ? input.amount + " " + input.uom.label : input.amount}</TableCell>
                                                        <TableCell key={'input-itemUnitCost-' + i} sx={{ textAlign: 'right' }}>
                                                            <NumericFormat displayType={'text'} value={input.cost != null ? input.cost.toFixed(2) : 0} thousandSeparator={true}
                                                                            decimalScale={2} prefix="$"/>
                                                        </TableCell>
                                                        <TableCell key={'input-itemLineCost-' + i} sx={{ textAlign: 'right' }}>
                                                            <NumericFormat displayType={'text'} value={input.cost != null ? (input.cost * input.amount).toFixed(2) : 0} thousandSeparator={true}
                                                                            decimalScale={2} prefix="$"/>
                                                        </TableCell>
                                                    </TableRow>
                                            )) :
                                                <TableRow>
                                                    <TableCell colSpan={6} align='center'>{t('noData')}</TableCell>
                                                </TableRow>
                                            }
                                            {this.state.inputTotal != null &&
                                                <TableRow key="inputTotal">
                                                    <TableCell colSpan={5}><Typography style={{ fontWeight: 'bold', textAlign: 'right'}}>{t('totalCost') + ':'}</Typography></TableCell>
                                                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'right'}}>
                                                        <NumericFormat displayType={'text'} value={this.state.inputTotal.toFixed(2)} thousandSeparator={true} decimalScale={2} prefix="$"/>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                        {this.state.inputs != null && this.state.inputs.length > 0 &&
                                            <TableFooter>
                                                {/* <TablePagination count={this.state.allInputs.length}
                                                rowsPerPage={this.state.inputRows}
                                                onPageChange={this.handleInputPage}
                                                page = {this.state.inputPage}
                                                rowsPerPageOptions={common.rowOptions}
                                                onRowsPerPageChange={this.handleInputsPerPage}/> */}
                                            </TableFooter>
                                        }
                                    </Table>
                                </Grid>
                            </Grid>
                        </Panel>
                    </Grid>
                    <Grid direction='column' item container xs={12} lg={this.props.navMenu.drawerOpen != null && this.props.navMenu.drawerOpen === true ? 11 : 5} spacing={1}>
                        <Panel>
                            <SubSectionHeading title={t('outputs')} />
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Table stickyHeader >
                                        <TableHead>
                                                <TableRow key='output-header'>
                                                    <TableCell key='output-itemDate-header' sx={{ width: '50px'}}>{t('date')}</TableCell>
                                                    <TableCell key='output-itemCategory-header' sx={{ width: '100px'}}>{t('category')}</TableCell>
                                                    <TableCell key='output-itemName-header'>{t('name')}</TableCell>
                                                    <TableCell key='output-itemAmount-header' sx={{ textAlign: 'right', width: '100px'}}>{t('amount')}</TableCell>
                                                </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {outputs.length > 0 ? outputs.map((output, i) => (
                                                output.date !== undefined &&
                                                    <TableRow key={'output-row' + i} >
                                                        <TableCell key={'output-itemDate-' + i} ><DateDisplay value={output.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                                        <TableCell key={'output-itemCategory-' + i} >{output.category != null ? output.category.label : ''}</TableCell>
                                                        <TableCell key={'output-itemName-' + i} ><Link to={'/item/' + output.id}>{output.name}</Link></TableCell>
                                                        <TableCell key={'output-itemAmount-' + i} sx={{textAlign: 'right'}} >{output.amount + " " + output.uom.label}</TableCell>
                                                    </TableRow>
                                            )) :
                                                <TableRow>
                                                    <TableCell colSpan={4} align='center'>{t('noData')}</TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                        {this.state.outputs != null && this.state.outputs.length > 0 &&
                                            <TableFooter>
                                                {/* <TablePagination count={this.state.allOutputs.length}
                                                rowsPerPage={this.state.outputRows}
                                                onPageChange={this.handleOutputPage}
                                                page = {this.state.outputPage}
                                                rowsPerPageOptions={common.rowOptions}
                                                onRowsPerPageChange={this.handleOutputsPerPage}/> */}
                                            </TableFooter>
                                        }
                                    </Table>
                                </Grid>
                            </Grid>
                        </Panel>
                    </Grid>                    
                    {/* <Grid direction='column'
                            container
                            xs={12}
                            sm={status === this.props.auth.constants.batchStatuses.inserted ? 8 :
                                (status === this.props.auth.constants.batchStatuses.complete ||
                                status === this.props.auth.constants.batchStatuses.harvested ||
                                status === this.props.auth.constants.batchStatuses.signedOff ) ? 8 : 12}
                            md={status === this.props.auth.constants.batchStatuses.inserted ? 10 :
                                (status === this.props.auth.constants.batchStatuses.released ||
                                status === this.props.auth.constants.batchStatuses.harvested ||
                                status === this.props.auth.constants.batchStatuses.complete ||
                                status === this.props.auth.constants.batchStatuses.signedOff ) ? 10 : 8}
                            lg={ status === this.props.auth.constants.batchStatuses.inserted ? 4 :
                                (status === this.props.auth.constants.batchStatuses.complete ||
                                status === this.props.auth.constants.batchStatuses.signedOff ) ? 6 : 10}
                            xl={status === this.props.auth.constants.batchStatuses.inserted ? 3 :
                                (status === this.props.auth.constants.batchStatuses.complete ||
                                status === this.props.auth.constants.batchStatuses.signedOff ) ? 6 : 6}
                            spacing={1}
                    >
                        {(status !== this.props.auth.constants.batchStatuses.beingDestroyed) && 
                            <Panel>
                                <SubSectionHeading title={t('insights')} />
                                <Grid container spacing={3}>
                                    {(status === this.props.auth.constants.batchStatuses.harvested ||
                                    status === this.props.auth.constants.batchStatuses.complete ||
                                    status === this.props.auth.constants.batchStatuses.signedOff) &&
                                        <Grid container xs={12} lg={6}>
                                            <Grid xs={6}>
                                                <FormDisplay
                                                    align='left'
                                                    label={t('gramsPerPlant')}
                                                    borderLeft={0}
                                                    borderRight={1}
                                                    borderTop={0}
                                                    borderBottom={0}
                                                    value={this.state.outputPerHarvestedPlant ?? t('notCaptured')}
                                                />
                                            </Grid>
                                            <Grid xs={6}>
                                                <FormDisplay
                                                    align='right'
                                                    label={t('gramsPerHarvestedPlant')}
                                                    borderLeft={1}
                                                    borderRight={0}
                                                    borderTop={0}
                                                    borderBottom={0}
                                                    value={this.state.outputPerHarvestedPlant ?? t('notCaptured')}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Panel>
                        }
                    </Grid> */}
                </Grid>
                {this.state.strainDialog &&
                    <ModalDialog buttonStack={this.buttonStack('strain')} title={t('strain')} toggleDialog={() => this.toggleOpen('strainDialog', false)} 
                    dialogStatus={this.state.strainDialog} fullWidth maxWidth='sm' >
                        <Grid container spacing={0}>
                            <Grid item xs={12} sx={{ border: 1, borderColor: 'grey.300', borderWidth: '2px', borderTopLeftRadius: 10, borderTopRightRadius: 10, p: 1}}>
                                <FormControl>
                                    <FormLabel>{t('strain')}</FormLabel>
                                    {this.state.strainRecord != null &&
                                        <Link to={'/strain/' + this.state.strainRecord._id}>
                                            {this.state.strainSel !== null ? this.state.strainSel.label : ''}
                                        </Link>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{ border: 1, borderColor: 'grey.300', borderWidth: '2px', p: 1}}>
                                <FormControl>
                                    <FormLabel>{t('description')}</FormLabel>
                                    {this.state.strainRecord != null ? this.state.strainRecord.description : t('none')}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sx={{ border: 1, borderColor: 'grey.300', borderWidth: '2px', borderBottomLeftRadius: 10, p: 1}}>
                                <FormControl>
                                    <FormLabel>{t('startItem')}</FormLabel>
                                    {this.state.strainRecord != null && this.state.startItem != null &&
                                        <Link to={'/item/' + this.state.startItem}>
                                            {this.state.startItemSel != null ? this.state.startItemSel.label : ''}
                                        </Link>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sx={{ border: 1, borderColor: 'grey.300', borderWidth: '2px', borderBottomRightRadius: 10, p: 1}}>
                                <FormControl>
                                    <FormLabel>{t('harvestItem')}</FormLabel>
                                    {this.state.strainRecord != null && this.state.outputItem != null &&
                                        <Link to={'/item/' + this.state.outputItem._id}>
                                            {this.state.outputItemSel != null ? this.state.outputItemSel.label : ''}
                                        </Link>
                                    }
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ModalDialog>
                }
                {this.state.roomDialog &&
                    <ModalDialog buttonStack={this.buttonStack('room')} title={t('rooms')} toggleDialog={() => this.toggleOpen('roomDialog', false)} 
                    dialogStatus={this.state.roomDialog} fullWidth 
                    maxWidth={this.state.currentRooms != null && this.state.currentRooms.length > 0 && this.state.currentRooms.length < 2 ? 'xs' : 
                                                                 this.state.currentRooms.length > 0 && this.state.currentRooms.length < 4 ? 'sm' : 'md'} >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell/>
                                            {this.state.currentRooms != null && this.state.currentRooms.length > 0 &&
                                                this.state.currentRooms.map((room, i) => (
                                                    <TableCell key={'room-' + i + '-header'} sx={{ border: '2px solid rgba(224, 224, 224, 1)'}}>
                                                        {room.room != null && <Link to={'/room/' + room.room._id}>{room.room.name + ' (' + room.count + ')'}</Link>}
                                                    </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key='roomDialog-plants-row'>
                                            <TableCell sx={{ typography: 'body2', fontWeight: 'bold', borderRight: '2px solid rgba(224, 224, 224, 1)'}}>{t('plantCount')}</TableCell>
                                            {this.state.currentRooms != null && this.state.currentRooms.length > 0 &&
                                                this.state.currentRooms.map((room, i) => (
                                                    <TableCell key={'room-' + i + '-plants'} sx={{ borderRight: '2px solid rgba(224, 224, 224, 1)' }}>
                                                        {room.items != null && room.items.length > 0 && room.items.map((item, j) => {
                                                            if(item.isMother != null && item.isMother === true) {
                                                                return (
                                                                    <Aux key={'roomItemType-' + j}>
                                                                        <Typography key={'room-' + i + '-plants-' + j} variant='body2' sx={{ fontWeight: 'bold', display: 'inline', mt: 2.5, mr: 1}}>{item.count}</Typography>
                                                                        <Tooltip title={t('mothers')} placement='right' ><MotherIcon sx={{ color: '#00AC89'}} /></Tooltip>
                                                                        {room.items.length > 1 && j < room.items.length - 1 ? <br /> : ''}
                                                                    </Aux>
                                                                )
                                                            }else if(item.isClone != null && item.isClone === true){
                                                                return (
                                                                    <Aux key={'roomItemType-' + j}>
                                                                        <Typography key={'room-' + i + '-plants-' + j} variant='body2' sx={{ fontWeight: 'bold', display: 'inline', mt: 2.5, mr: 1}}>{item.count}</Typography>
                                                                        <Tooltip title={t('clones')} placement='right' ><CloneIcon color='success' /></Tooltip>
                                                                        {room.items.length > 1 && j < room.items.length - 1 ? <br /> : ''}
                                                                    </Aux>
                                                                )
                                                            }else if(item.isVeg != null && item.isVeg === true){
                                                                return (
                                                                    <Aux key={'roomItemType-' + j}>
                                                                        <Typography key={'room-' + i + '-plants-' + j} variant='body2' sx={{ fontWeight: 'bold', display: 'inline', mt: 2.5, mr: 1}}>{item.count}</Typography>
                                                                        <Tooltip title={t('veg')} placement='right' ><VegIcon color='secondary' /></Tooltip>
                                                                        {room.items.length > 1 && j < room.items.length - 1 ? <br /> : ''}
                                                                    </Aux>
                                                                )
                                                            }else if(item.isFlower != null && item.isFlower === true){
                                                                return (
                                                                    <Aux key={'roomItemType-' + j}>
                                                                        <Typography key={'room-' + i + '-plants-' + j} variant='body2' sx={{ fontWeight: 'bold', display: 'inline', mt: 2.5, mr: 1}}>{item.count}</Typography>
                                                                        <Tooltip title={t('flowers')} placement='right' ><FlowerIcon color='primary' /></Tooltip>
                                                                        {room.items.length > 1 && j < room.items.length - 1 ? <br /> : ''}
                                                                    </Aux>
                                                                )
                                                            }else{
                                                                return (
                                                                    <Aux key={'roomItemType-' + j}>
                                                                        <Typography key={'room-' + i + '-plants-' + j} variant='body2' sx={{ fontWeight: 'bold', display: 'inline'}}>{t('none')}</Typography>
                                                                    </Aux>
                                                                )
                                                            }
                                                        })}
                                                    </TableCell>
                                                )
                                            )}
                                        </TableRow>
                                        <TableRow key='roomDialog-dimensions-row'>
                                            <TableCell sx={{ typography: 'body2', fontWeight: 'bold', borderRight: '2px solid rgba(224, 224, 224, 1)'}}>{t('dimensions')}</TableCell>
                                            {this.state.currentRooms != null && this.state.currentRooms.length > 0 &&
                                                this.state.currentRooms.map((room, i) => {
                                                    if(room.hasDimensions) {
                                                        return (
                                                            <TableCell key={'room-' + i + '-dimensions'} sx={{ borderRight: '2px solid rgba(224, 224, 224, 1)' }}>
                                                                {t('length') + ': '}<Typography variant='body2' sx={{ fontWeight: 'bold', display: 'inline'}}>{room.room.length != null && room.room.lengthUOM != null ? room.room.length + ' ' + room.room.lengthUOM.label : 0}</Typography><br />
                                                                {t('width') + ': '}<Typography variant='body2' sx={{ fontWeight: 'bold', display: 'inline'}}>{room.room.width != null && room.room.widthUOM != null ? room.room.width + ' ' + room.room.widthUOM.label : 0}</Typography><br />
                                                                {t('totalArea') + ': '}<Typography variant='body2' sx={{ fontWeight: 'bold', display: 'inline'}}>{room.room.length != null && room.room.width != null && room.room.widthUOM != null ? room.room.length * room.room.width + ' ' + room.room.widthUOM.label: 0}</Typography><br />
                                                            </TableCell>
                                                        )                                                        
                                                    }else{
                                                        return <TableCell key={'room-' + i + '-dimensions'} sx={{ borderRight: '2px solid rgba(224, 224, 224, 1)' }}>{t('none')}</TableCell>
                                                    }
                                            })}
                                        </TableRow>
                                        <TableRow key='roomDialog-lights-row'>
                                            <TableCell sx={{ typography: 'body2', fontWeight: 'bold', borderRight: '2px solid rgba(224, 224, 224, 1)'}}>{t('Lights')}</TableCell>
                                            {this.state.currentRooms != null && this.state.currentRooms.length > 0 &&
                                                this.state.currentRooms.map((room, i) => {
                                                    if(room.hasLighting) {
                                                        return (
                                                            <TableCell key={'room-' + i + '-lights'} sx={{ borderRight: '2px solid rgba(224, 224, 224, 1)' }}>
                                                                {t('lightType') + ': '}<Typography variant='body2' sx={{ fontWeight: 'bold', display: 'inline'}}>{room.room.lightType != null ? room.room.lightType.label : t('none')}</Typography><br />
                                                                {t('lightCount') + ': '}<Typography variant='body2' sx={{ fontWeight: 'bold', display: 'inline'}}>{room.room.lightCount != null ? room.room.lightCount : 0}</Typography><br />
                                                                {t('targetPAR') + ': '}<Typography variant='body2' sx={{ fontWeight: 'bold', display: 'inline'}}>{room.room.settings != null && room.room.settings.par != null ? room.room.settings.par : 0}</Typography><br />
                                                            </TableCell>
                                                        )                                                        
                                                    }else{
                                                        return <TableCell key={'room-' + i + '-lights'} sx={{ borderRight: '2px solid rgba(224, 224, 224, 1)' }}>{t('none')}</TableCell>
                                                    }                                                    
                                            })}
                                        </TableRow>
                                        <TableRow key='roomDialog-environment-row'>
                                            <TableCell sx={{ typography: 'body2', fontWeight: 'bold', borderRight: '2px solid rgba(224, 224, 224, 1)'}}>{t('environmentTargets')}</TableCell>
                                            {this.state.currentRooms != null && this.state.currentRooms.length > 0 &&
                                                this.state.currentRooms.map((room, i) => {
                                                    if(room.hasEnvironment) {
                                                        return (
                                                            <TableCell key={'room-' + i + '-environment'} sx={{ borderRight: '2px solid rgba(224, 224, 224, 1)' }}>
                                                                {t('temperature') + ': '}<Typography variant='body2' sx={{ fontWeight: 'bold', display: 'inline'}}>{room.room.settings != null && room.room.settings.temperature != null ? room.room.settings.temperature : 0}</Typography><br />
                                                                {t('humidity') + ': '}<Typography variant='body2' sx={{ fontWeight: 'bold', display: 'inline'}}>{room.room.settings != null && room.room.settings.humidity != null ? room.room.settings.humidity : 0}</Typography><br />
                                                                {t('co2') + ': '}<Typography variant='body2' sx={{ fontWeight: 'bold', display: 'inline'}}>{room.room.settings != null && room.room.settings.co2Concentration != null && room.room.settings.co2Concentration !== '' ? room.room.settings.co2Concentration : 0}</Typography><br />
                                                            </TableCell>
                                                        )                                                        
                                                    }else{
                                                        return <TableCell key={'room-' + i + '-environment'} sx={{ borderRight: '2px solid rgba(224, 224, 224, 1)' }}>{t('none')}</TableCell>
                                                    }
                                            })}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </ModalDialog>
                }
                {this.state.observationDialog &&
                    <ModalDialog buttonStack={this.buttonStack('observations')} title={t('observations')} toggleDialog={() => this.toggleOpen('observationDialog', false)}
                                 dialogStatus={this.state.observationDialog} fullWidth maxWidth='md'>
                        <BatchObservations batch={this.props.match.params.id} observationId={this.state.notiObservationId} callBack={this.loadRecord}/>
                    </ModalDialog>
                }
                {this.state.roomFeedingDialog &&
                    <ModalDialog buttonStack={this.buttonStack('roomFeedings')} title={t('roomFeedings')} toggleDialog={() => this.toggleOpen('roomFeedingDialog', false)}
                                 dialogStatus={this.state.roomFeedingDialog} fullWidth maxWidth='sm'>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow key="roomFeedHead">
                                                <TableCell>{t('number')}</TableCell>
                                                <TableCell>{t('date')}</TableCell>
                                                <TableCell>{t('status')}</TableCell>
                                                <TableCell>{t('type')}</TableCell>
                                                <TableCell>{t('cost')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.feedings != null && this.state.feedings.length > 0 ? this.state.feedings.map((row, i) => (
                                                <Aux key={'roomFeedCollector-' + i}>
                                                    <TableRow key={'roomFeedings-' + row._id}>
                                                        <TableCell><Link to={'/roomfeeding/' + row._id}>{row.number}</Link></TableCell>
                                                        <TableCell>
                                                            <DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/>
                                                        </TableCell>
                                                        <TableCell>{row.status}</TableCell>
                                                        <TableCell>{row.type}</TableCell>
                                                        <TableCell sx={{ textAlign: 'right' }}>
                                                            <NumericFormat displayType={'text'} value={(row.cost ?? 0).toFixed(2)} thousandSeparator={true}
                                                            decimalScale={2} prefix="$"/>
                                                        </TableCell>
                                                    </TableRow>
                                                    {i === this.state.feedings.length - 1 &&
                                                        <TableRow key="roomFeedFoot">
                                                            <TableCell colSpan={4} sx={{ fontWeight: 'bold', textAlign: 'right'}}>{t('total') + ': '}</TableCell>
                                                            <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                                <NumericFormat displayType={'text'} value={this.state.feedings.reduce((total, feed) => total + (feed.cost ?? 0), 0)} thousandSeparator={true}
                                                                decimalScale={2} prefix="$"/>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </Aux>                                                
                                            )) : 
                                                <TableRow key="roomFeedEmpty">
                                                    <TableCell colSpan={5} sx={{ textAlign: 'center' }}>{t('noData')}</TableCell>
                                                </TableRow>
                                            }                                            
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </ModalDialog>
                }
                {this.state.treatmentsDialog &&
                    <ModalDialog buttonStack={this.buttonStack('treatments')} title={t('treatments')} toggleDialog={() => this.toggleOpen('treatmentsDialog', false)}
                                 dialogStatus={this.state.treatmentsDialog} fullWidth maxWidth='lg'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={5} justifyContent='flex-start' justifyItems='flex-start'>
                                <Grid item xs={12}>
                                    <SubSectionHeading title={t('cropTreatments')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Table>
                                        <TableHead>
                                            <TableRow key='cropTreatment-header'>
                                                <TableCell key='cropTreatment-Date-header'>{t('date')}</TableCell>
                                                <TableCell key='cropTreatment-Number-header'>{t('number')}</TableCell>
                                                <TableCell key='cropTreatment-Room-header'>{t('room')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {treatments.crop.length > 0 ? treatments.crop.map((ctreat, i) => (
                                                ctreat.date !== undefined &&
                                                    <TableRow key={'cropTreatment-row-' + i} >
                                                        <TableCell key={'cropTreatment-Date-' + i} ><DateDisplay value={ctreat.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                                        <TableCell key={'cropTreatment-Number-' + i} ><Link to={'/croptreatment/' + ctreat._id}>{ctreat.number}</Link></TableCell>
                                                        <TableCell key={'cropTreatment-Room-' + i} ><Link to={'/roomdetails/' + ctreat.room._id}>{ctreat.room.name}</Link></TableCell>
                                                    </TableRow>
                                            )) :
                                                <TableRow>
                                                    <TableCell colSpan={4} align='center'>{t('noData')}</TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={7} justifyContent='flex-start' justifyItems='flex-start'>
                                <Grid item xs={12}>
                                    <SubSectionHeading title={t('externalTreatments')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Table>
                                        <TableHead>
                                            <TableRow key='extTreat-header'>
                                                <TableCell key='extTreat-Date-header'>{t('date')}</TableCell>
                                                <TableCell key='extTreat-Number-header'>{t('number')}</TableCell>
                                                <TableCell key='extTreat-Type-header'>{t('type')}</TableCell>
                                                <TableCell key='extTreat-Vendor-header'>{t('vendor')}</TableCell>
                                                <TableCell key='extTreat-Status-header'>{t('status')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {treatments.external.length > 0 ? treatments.external.map((extTreat, i) => (
                                                extTreat.requestDate !== undefined &&
                                                    <TableRow key={'extTreat-row-' + i}>
                                                        <TableCell key={'extTreat-Date-' + i} ><DateDisplay value={extTreat.requestDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                                        <TableCell key={'extTreat-Number-' + i} ><Link to={'/exttreat/' + extTreat._id}>{extTreat.number}</Link></TableCell>
                                                        <TableCell key={'extTreat-Type-' + i} >{extTreat.sterilizationType.label}</TableCell>
                                                        <TableCell key={'extTreat-Vendor-' + i} >{extTreat.vendor.name}</TableCell> :
                                                        <TableCell key={'extTreat-Status-' + i} >{extTreat.status.label}</TableCell>
                                                    </TableRow>
                                            )) :
                                                <TableRow>
                                                    <TableCell colSpan={5} align='center'>{t('noData')}</TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ModalDialog>
                }
                {this.state.investigationDialog &&
                    <ModalDialog buttonStack={this.buttonStack('investigations')} title={t('investigations')} toggleDialog={() => this.toggleOpen('investigationDialog', false)}
                                 dialogStatus={this.state.investigationDialog} fullWidth maxWidth='md'>
                        <Grid container spacing={2}>
                            <Grid item container xs={12} md={7}>
                                <Grid item xs={12}>
                                    <SubSectionHeading title={t('deviations')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Deviations ebatch={this.state._id}/>
                                </Grid>                                 
                            </Grid>
                            <Grid item container xs={12} md={5}>
                                <Grid item xs={12}>
                                    <SubSectionHeading title={t('ncrs')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Ncrs ebatch={this.state._id}/>
                                </Grid>                                
                            </Grid>
                        </Grid>
                    </ModalDialog>
                }
                {this.state.roomCleaningDialog &&
                    <ModalDialog buttonStack={this.buttonStack('roomCleaning')} title={t('roomCleaning')} toggleDialog={() => this.toggleOpen('roomCleaningDialog', false)}
                                 dialogStatus={this.state.roomCleaningDialog} fullWidth maxWidth='md'>
                        <Grid container spacing={2}>
                            <CleaningRecords id={this.props.match.params.id}/>
                        </Grid>
                    </ModalDialog>
                }
                {this.state.testResultsDialog &&
                    <ModalDialog buttonStack={this.buttonStack('testResults')} title={t('testResults')} toggleDialog={() => this.toggleOpen('testResultsDialog', false)}
                                 dialogStatus={this.state.testResultsDialog} fullWidth maxWidth='lg'>
                        {this.state.testResults != null && this.state.testResults.length > 0 && this.state.testResults.map((row, i) => 
                            <Grid container spacing={3} key={row.test._id}>
                                <Grid item xs={2} sm={1}>
                                    <FormControl>
                                        <FormLabel>{t('number')}</FormLabel>
                                        <Link to={'/testresult/' + row.test._id}>{row.test.number}</Link>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <FormControl>
                                        <FormLabel>{t('item')}</FormLabel>
                                        <Link to={'/item/' + row.item._id}>{row.item.number + '-' + row.item.name}</Link>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <FormControl>
                                        <FormLabel>{t('date')}</FormLabel>
                                        <DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <FormControl>
                                        <FormLabel>{t('result')}</FormLabel>
                                        <PassFailIcons constants={this.props.auth.constants.testResults} status={row.test.result} />
                                    </FormControl>
                                </Grid>
                                {row.results.map((result, j) =>
                                    <Grid item xs={4} sm={2} key={result._id}>
                                        <FormControl>
                                            <FormLabel>{result.testItem.name}</FormLabel>
                                            {result.score + ' ' + result.testItem.uom}
                                        </FormControl>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        {(this.state.testResults == null || this.state.testResults.length === 0) &&
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    {t('noData')}
                                </Grid>
                            </Grid>
                        }
                        
                    </ModalDialog>
                }
                {this.state.batchLedgerDialog &&
                    <ModalDialog buttonStack={this.buttonStack('batchLedger')} title={t('batchLedger')} toggleDialog={() => this.toggleOpen('batchLedgerDialog', false)}
                                    dialogStatus={this.state.batchLedgerDialog} fullWidth maxWidth='md'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <BatchLedgers batch={this.state._id}/>
                            </Grid>
                        </Grid>
                    </ModalDialog>
                }
                {this.state.showActivity &&
                    <ModalDialog buttonStack={this.buttonStack('activity')} title={t('addActivity')} toggleDialog={this.clearActivity}
                                 dialogStatus={this.state.showActivity} fullWidth maxWidth='md'>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.newActSel}
                                    options={this.state.newActivityTypes}
                                    onChange={this.changeAuto('newActSel', 'newAct')}
                                    error={errors[t('newAct')] != null ? true : false}
                                    helperText={errors[t('newAct')]} 
                                    label={t('type')}
                                />
                            </Grid>
                            {this.state.newAct === this.props.auth.constants.objectIds.batchActivity &&
                                <Grid item xs={8} sm={4}>
                                    <AutoCompleteField
                                        value={this.state.actSel}
                                        options={this.state.activities}
                                        onChange={this.changeAuto('actSel', 'activity')}
                                        error={errors[t('activity')] != null ? true : false}
                                        helperText={errors[t('activity')] } 
                                        label={t('activity')}
                                    />
                                </Grid>
                            }
                            <Grid item xs={4} sm={2}>
                                <DateSelect 
                                    onChange={this.dateChange('activityDate')}
                                    value={this.state.activityDate}
                                    helperText={errors[t('activityDate')] }
                                    error={errors[t('activityDate')]  != null ? true : false}
                                    label={t('date')}
                                    format={this.props.auth.user.dateFormat}
                                />
                            </Grid>
                            {this.state.sopList.length > 0 &&
                                <Grid item xs={12}>
                                    <AutoCompleteField
                                        multiple={true}
                                        value={this.state.sopsSel}
                                        options={this.state.sopList}
                                        onChange={this.changeMulti('sopsSel', 'sops')}
                                        error={errors[t('sops')] != null ? true : false}
                                        helperText={errors[t('sops')]} 
                                        label={t('workRecs')}
                                    />
                                </Grid>
                            }                            
                        </Grid>
                    </ModalDialog>
                }
                {this.state.showDestroy &&
                    <ModalDialog buttonStack={this.buttonStack('destroyBatch')} title={t('destroyBatch')} toggleDialog={this.closeDestruction}
                                    dialogStatus={this.state.showDestroy} fullWidth maxWidth='md'>
                        <Grid container spacing={2}>
                            <Grid item sm={10}>
                                <p>{t('destroyMessage')}</p>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.destructionReasonSel}
                                    options={this.state.destructionReasons}
                                    onChange={this.changeAuto('destructionReasonSel', 'destructionReason')}
                                    error={errors[t('destructionReason')]  != null ? true : false}
                                    helperText={errors[t('destructionReason')] } 
                                    label={t('reason')}
                                    required
                                />
                            </Grid>
                            <Grid item sm={11}>
                                <TextField value={this.state.destructionReasonDescription} onChange={this.changeValue} name="destructionReasonDescription"
                                multiline={true} maxRows="4"
                                size="medium" fullWidth={true} label={t('reasonDetail')} 
                                error={errors[t('destructionReasonDescription')]  != null ? true : false} helperText={errors[t('destructionReasonDescription')] }/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.deviationSel}
                                    options={this.state.deviations}
                                    onChange={this.deviationChange}
                                    error={errors[t('deviation')] != null ? true : false}
                                    helperText={errors[t('deviation')]} 
                                    label={t('deviation')}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckboxField
                                    checked={this.state.createDeviation} onChange={this.changeCreateDev}
                                    label={t('addDeviation')} name="createDeviation"
                                />
                            </Grid>
                        </Grid>
                    </ModalDialog>
                }
                { this.state.showSequence &&
                    <ModalDialog buttonStack={this.buttonStack('sequence')} title={t('sequence')} toggleDialog={() => this.toggleSequence()}
                    dialogStatus={this.state.showSequence} fullWidth maxWidth='md'>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('lastSequence')}</FormLabel>
                                    <Typography>{this.state.lastSequence}</Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('newSequence')}</FormLabel>
                                    <TextField value={this.state.newSequence} onChange={this.changeValue} name="newSequence"
                                    size="medium" fullWidth={true} type="number" 
                                    error={errors[t('newSequence')] != null ? true : false} helperText={errors[t('newSequence')]}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ModalDialog>
                }
                {this.state.cgsDialog &&
                    <ModalDialog buttonStack={this.buttonStack('cgs')} title={t('costs')} toggleDialog={() => this.toggleOpen('cgsDialog', false)}
                                 dialogStatus={this.state.cgsDialog} fullWidth maxWidth='md'>
                        <Grid container spacing={1}>
                            <Grid item xs={12} >
                                {this.state.costs.costPerGram != null && this.state.costs.costsPerGram !== '' &&
                                    <Grid container spacing={3}>
                                        <Grid item xs={8} sm={4}><SubSubHeading title={t('cpg')}/></Grid>
                                        <Grid item xs={6} sm={3}>
                                            <NumericFormat displayType={'text'} value={this.state.costs.costPerGram} thousandSeparator={true}
                                            decimalScale={2} prefix="$"/>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container spacing={3}>
                                    <Grid item xs={8} sm={4}></Grid>
                                    <Grid item xs={6} sm={3}><SubSubHeading title={t('actual')}/></Grid>
                                    <Grid item xs={6} sm={3}><SubSubHeading title={t('estimate')}/></Grid>
                                </Grid>
                                {this.state.costs.feedings != null && this.state.costs.feedings !== '' && this.state.costs.feedings !== 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item xs={8} sm={4}>{t('feedings')}</Grid>
                                        <Grid item xs={6} sm={3}>
                                            <NumericFormat displayType={'text'} value={this.state.costs.feedings} thousandSeparator={true}
                                            decimalScale={2} prefix="$"/>
                                        </Grid>
                                    </Grid>
                                }
                                {this.state.costs.inputItems != null && this.state.costs.inputItems !== '' &&
                                    <Grid container spacing={3}>
                                        <Grid item xs={8} sm={4}>{t('costItems')}</Grid>
                                        <Grid item xs={6} sm={3}>
                                            <NumericFormat displayType={'text'} value={this.state.costs.inputItems} thousandSeparator={true}
                                            decimalScale={2} prefix="$"/>
                                        </Grid>
                                        {this.state.expectedInputCosts != null && this.state.expectedInputCosts !== '' && 
                                        this.state.expectedInputCosts !== 0 && this.state.expectedInputCosts !== '0' &&
                                        <Grid item xs={6} sm={3}>
                                            <NumericFormat displayType={'text'} value={this.state.expectedInputCosts} thousandSeparator={true}
                                            decimalScale={2} prefix="$"/>
                                        </Grid>
                                        }
                                    </Grid>
                                }
                                {this.state.costs.labour != null && this.state.costs.labour !== '' &&
                                    <Grid container spacing={3}>
                                        <Grid item xs={8} sm={4}>{t('labour')}</Grid>
                                        <Grid item xs={6} sm={3}>
                                            <NumericFormat displayType={'text'} value={this.state.costs.labour} thousandSeparator={true}
                                            decimalScale={2} prefix="$"/>
                                        </Grid>
                                        {this.state.estimatedLabourCost != null && this.state.estimatedLabourCost !== '' && this.state.estimatedLabourCost !== 0 &&
                                        this.state.estimatedLabourCost !== '0' &&
                                            <Grid item xs={6} sm={3}>
                                                <NumericFormat displayType={'text'} value={this.state.estimatedLabourCost} thousandSeparator={true}
                                                decimalScale={2} prefix="$"/>
                                            </Grid>
                                        }
                                    </Grid>
                                }
                                <Grid container spacing={3}>
                                    <Grid item xs={8} sm={4}>{t('water')}</Grid>
                                    <Grid item xs={6} sm={3}>
                                        {this.state.security.update && !this.state.reviewed &&
                                            <TextField value={this.state.costs.water} onChange={this.changeCost} name="water"
                                            size="medium" fullWidth={true} type="number" 
                                            error={errors[t('water')] != null ? true : false} helperText={errors[t('water')]}/>
                                        }
                                        {this.state.reviewed && this.state.costs.water !== '' &&
                                            <NumericFormat displayType={'text'} value={this.state.costs.water} thousandSeparator={true}
                                            decimalScale={2} prefix="$"/>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={8} sm={4}>{t('electricity')}</Grid>
                                    <Grid item xs={6} sm={3}>
                                        {this.state.security.update && !this.state.reviewed &&
                                            <TextField value={this.state.costs.electricity} onChange={this.changeCost} name="electricity"
                                            size="medium" fullWidth={true} type="number" 
                                            error={errors[t('electricity')] != null ? true : false} helperText={errors[t('electricity')]}/>
                                        }
                                        {this.state.reviewed && this.state.costs.electricity !== '' &&
                                            <NumericFormat displayType={'text'} value={this.state.costs.electricity} thousandSeparator={true}
                                            decimalScale={2} prefix="$"/>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={8} sm={4}>{t('gas')}</Grid>
                                    <Grid item xs={6} sm={3}>
                                        {this.state.security.update && !this.state.reviewed &&
                                            <TextField value={this.state.costs.gas} onChange={this.changeCost} name="gas"
                                            size="medium" fullWidth={true} type="number" 
                                            error={errors[t('gas')] != null ? true : false} helperText={errors[t('gas')]}/>
                                        }
                                        {this.state.reviewed && this.state.costs.electricity !== '' &&
                                            <NumericFormat displayType={'text'} value={this.state.costs.gas} thousandSeparator={true}
                                            decimalScale={2} prefix="$"/>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={8} sm={4}>{t('nutrients')}</Grid>
                                    <Grid item xs={6} sm={3}>
                                        {this.state.security.update && !this.state.reviewed &&
                                            <TextField value={this.state.costs.nutrients} onChange={this.changeCost} name="nutrients"
                                            size="medium" fullWidth={true} type="number" 
                                            error={errors[t('nutrients')] != null ? true : false} helperText={errors[t('nutrients')]}/>
                                        }
                                        {this.state.reviewed && this.state.costs.nutrients !== '' &&
                                            <NumericFormat displayType={'text'} value={this.state.costs.nutrients} thousandSeparator={true}
                                            decimalScale={2} prefix="$"/>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={8} sm={4}>{t('overhead')}</Grid>
                                    <Grid item xs={6} sm={3}>
                                        {this.state.security.update && !this.state.reviewed &&
                                            <TextField value={this.state.costs.overhead} onChange={this.changeCost} name="overhead"
                                            size="medium" fullWidth={true} type="number" 
                                            error={errors[t('overhead')] != null ? true : false} helperText={errors[t('overhead')]}/>
                                        }
                                        {this.state.reviewed && this.state.costs.overhead !== '' &&
                                            <NumericFormat displayType={'text'} value={this.state.costs.overhead} thousandSeparator={true}
                                            decimalScale={2} prefix="$"/>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ModalDialog>
                }
                {this.state.dialogOpen &&
                    <BatchRecordForm
                        id={this.state._id}
                        reload={this.loadRecord}
                        templateObject={this.state.templateObject}
                        permission={security}
                        fieldAccess={fieldAccess}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleOpen('dialogOpen', false)}
                    />
                }
                {this.state.printViewer &&
                    <PrintView
                        id={this.state._id}
                        object={this.props.auth.constants.objectIds.evoraBatch}
                        open={this.state.printViewer}
                        onClose={this.togglePrintViewer}
                        callBack={this.loadRecord}
                        printAccess={printAccess}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.evoraBatch}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    security: state.security,
    navMenu: state.navMenu,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(CultivationBatch));
