import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Aux from '../../hoc/Auxilary/Auxilary';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import DateSelect from '../UI/DateSelect/DateSelect';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Grid from '@mui/material/Grid';
import { withTranslation } from 'react-i18next';
import IconButton from '@mui/material/Button';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import Title from '../UI/Typography/Title';
import SectionTitle from '../UI/Typography/SectionTitle';
import SubSubHeading from '../UI/Typography/SubSubHeading';

class ExciseStampAudit extends Component {
    constructor(props){
        super(props);
        this.state = {
            start: '',
            end: '',
            stampRes: null,
            enableSave: true
        }
    }

    componentDidMount(){
        let data = JSON.parse(sessionStorage.getItem('exciseStampAudit'));
        if(data != null){
            this.setState({stampRes: data.stampRes, start: data.start, end: data.end});
        }
    }

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    }

    submit = () =>{
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._submit();
        });
    }

    _submit = async () => {
        const t = this.props.t;
        if(this.state.start === '' || this.state.end === '' || this.state.start === null || this.state.end === null){
            this.setState({enableSave: true}, () => {
                alert(t('datesRequired'));
            });
            return;
        }
        let data = {startDate: this.state.start, endDate: this.state.end};
        try {
            let result = await axios.get('/api/excisecounts', {params: data});
            let storageData = {
                stampRes: result.data,
                start: this.state.start,
                end: this.state.end
            }
            sessionStorage.setItem('exciseStampAudit', JSON.stringify(storageData));
            this.setState({enableSave: true, stampRes: result.data}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar />
                <Panel>
                    <Title>{this.props.t('exciseStampAudit')}</Title>
                    <Grid container spacing={2}>
                        <Grid item sm={3}>
                            <DateSelect
                                onChange={this.changeDate('start')}
                                value={this.state.start}
                                label={t('startDate')}
                                format={this.props.auth.user.dateFormat}
                                data-cy='start'
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <DateSelect
                                onChange={this.changeDate('end')}
                                value={this.state.end}
                                label={t('endDate')}
                                format={this.props.auth.user.dateFormat}
                                data-cy='end'
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={this.submit} variant="contained" disabled={!this.state.enableSave} color="primary">{this.props.t('submit')}</IconButton>
                        </Grid>
                    </Grid>
                    {this.state.stampRes != null && this.state.stampRes.details != null && this.state.stampRes.details.length > 0 &&
                        <Aux>
                            <SubSectionHeading>{this.props.t('results')}</SubSectionHeading>
                            <br/>
                            <SectionTitle title={t('results')}/>
                            {this.state.stampRes.details.map((row, i) =>
                                <Aux key={'provAux' + i}>
                                    <SubSectionHeading title={row.prov}/>
                                    {row.receivedTotal != null && row.receivedTotal > 0 &&
                                        <Grid container spacing={3} key={'received' + i}>
                                            <Grid item xs={6} sm={3}><SubSubHeading title={t('received')}/></Grid>
                                            <Grid item xs={6} sm={3}><NumericFormat value={row.receivedTotal} displayType='text' thousandSeparator={true}/></Grid>
                                        </Grid>
                                    }
                                    {row.received != null && row.received.length > 0 &&
                                        <Aux>
                                            <Grid container spacing={3} key={'receivedTitle' + i}>
                                                <Grid item xs={6} sm={3}>{t('lines')}</Grid>
                                            </Grid>
                                            <TableContainer component={Paper}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow key={'receivedHead' + i}> 
                                                            <TableCell>{t('receipt')}</TableCell>
                                                            <TableCell align="right">{t('quantity')}</TableCell>
                                                            <TableCell>{t('date')}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {row.received.map((line, j) =>
                                                            <TableRow key={'receivedRow' + i + 'a' + j}>
                                                                <TableCell><Link to={'/receipt/' + line.id}>{line.number}</Link></TableCell>
                                                                <TableCell align="right"><NumericFormat value={line.quantity} displayType='text' thousandSeparator={true}/></TableCell>
                                                                <TableCell><DateDisplay value={line.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Aux>
                                    }
                                    {row.consumedTotal != null && row.consumedTotal > 0 &&
                                        <Aux>
                                            <br/>
                                            <Grid container spacing={3} key={'consumed' + i}>
                                                <Grid item xs={6} sm={3}><SubSubHeading title={t('consumed')}/></Grid>
                                                <Grid item xs={6} sm={3}><NumericFormat value={row.consumedTotal} displayType='text' thousandSeparator={true}/></Grid>
                                            </Grid>
                                        </Aux>
                                    }
                                    {row.consumed != null && row.consumed.length > 0 &&
                                        <Aux>
                                        <Grid container spacing={3} key={'consumedTitle' + i}>
                                            <Grid item xs={6} sm={3}>{t('lines')}</Grid>
                                        </Grid>
                                        <TableContainer component={Paper}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow key={'consumedHead' + i}> 
                                                        <TableCell>{t('productionOrder')}</TableCell>
                                                        <TableCell align="right">{t('quantity')}</TableCell>
                                                        <TableCell>{t('date')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {row.consumed.map((line, j) =>
                                                        <TableRow key={'consumedRow' + i + 'a' + j}>
                                                            <TableCell><Link to={'/eproductionorder/' + line.id}>{line.number}</Link></TableCell>
                                                            <TableCell align="right"><NumericFormat value={line.quantity} displayType='text' thousandSeparator={true}/></TableCell>
                                                            <TableCell><DateDisplay value={line.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Aux>
                                    }
                                    {row.destroyedTotal != null && row.destroyedTotal > 0 &&
                                        <Aux>
                                            <br/>
                                            <Grid container spacing={3} key={'destroyed' + i}>
                                                <Grid item xs={6} sm={3}><SubSubHeading title={t('destroyed')}/></Grid>
                                                <Grid item xs={6} sm={3}><NumericFormat value={row.destroyedTotal} displayType='text' thousandSeparator={true}/></Grid>
                                            </Grid>
                                        </Aux>
                                    }
                                    {row.destroyed != null && row.destroyed.length > 0 &&
                                        <Aux>
                                        <Grid container spacing={3} key={'destroyedTitle' + i}>
                                            <Grid item xs={6} sm={3}>{t('lines')}</Grid>
                                        </Grid>
                                        <TableContainer component={Paper}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow key={'destoyedHead' + i}> 
                                                        <TableCell>{t('itemLedger')}</TableCell>
                                                        <TableCell align="right">{t('quantity')}</TableCell>
                                                        <TableCell>{t('date')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {row.destroyed.map((line, j) =>
                                                        <TableRow key={'destroyedRow' + i + 'a' + j}>
                                                            <TableCell><Link to={'/itemledger/' + line.id}>{line.number}</Link></TableCell>
                                                            <TableCell align="right"><NumericFormat value={line.quantity} displayType="text" thousandSeparator={true}/></TableCell>
                                                            <TableCell><DateDisplay value={line.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Aux>
                                    }
                                    {row.salesTotal != null && row.salesTotal > 0 &&
                                        <Aux>
                                            <br/>
                                            <Grid container spacing={3} key={'sales' + i}>
                                                <Grid item xs={6} sm={3}><SubSubHeading title={t('salesShipments')}/></Grid>
                                                <Grid item xs={6} sm={3}><NumericFormat value={row.salesTotal} displayType='text' thousandSeparator={true}/></Grid>
                                            </Grid>
                                        </Aux>
                                    }
                                    {row.sales != null && row.sales.length > 0 &&
                                        <Aux>
                                        <Grid container spacing={3} key={'SalesTitle' + i}>
                                            <Grid item xs={6} sm={3}>{t('lines')}</Grid>
                                        </Grid>
                                        <TableContainer component={Paper}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow key={'salesHead' + i}> 
                                                        <TableCell>{t('shipment')}</TableCell>
                                                        <TableCell align="right">{t('quantity')}</TableCell>
                                                        <TableCell>{t('item')}</TableCell>
                                                        <TableCell>{t('date')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {row.sales.map((line, j) =>
                                                        <TableRow key={'salesRow' + i + 'a' + j}>
                                                            <TableCell><Link to={'/shipment/' + line.id}>{line.number}</Link></TableCell>
                                                            <TableCell align="right"><NumericFormat value={line.quantity} displayType='text' thousandSeparator={true}/></TableCell>
                                                            <TableCell><Link to={'/item/' + line.itemId}>{line.item}</Link></TableCell>
                                                            <TableCell><DateDisplay value={line.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Aux>
                                    }
                                </Aux>
                            )}
                        </Aux>
                    }
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(ExciseStampAudit));