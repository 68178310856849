import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Aux from '../../hoc/Auxilary/Auxilary';
import RoomBatches from './RoomBatches';
import RoomTables from './RoomTables';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import { Link } from 'react-router-dom';
import SectionTitle from '../UI/Typography/SectionTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RoomDetails from './RoomDetails';
import RoomChecks from '../General/RoomChecks';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import RoomCleanings from './RoomCleanings';
import MaintenanceLogs from './/MaintenanceLogs';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import ConfirmDelete from '../General/ConfirmDelete';

class Room extends Component{
    constructor(props){
        super(props);

        this.state = {
            _id: '',
            name: '',
            location: '',
            locations: [],
            type: '',
            types: [],
            number: '',
            canDelete: false,
            locationSelected: null,
            typeSelected: null,
            lightTypes: [],
            lightType: '',
            lightTypeSel: null,
            lightCount: null,
            width: '',
            widthUOM: '',
            widthUOMSel: null,
            length: '',
            lengthUOM: '',
            lengthUOMSel: null,
            uoms: [],
            cloneCount: '',
            vegCount: '',
            flowerCount: '',
            locked: false,
            section: '',
            growing: false,
            tabLabels: [{name: this.props.t('details'), disabled: false}],
            tabKey: 0,
            workCenters: [],
            security: [],
            enableSave: true,
            isNew: false,
            bins: [],
            equipments: [],
            foreignId: '',
            growRoomType: '',
            growRoomTypeSel: null,
            growRoomTypes: [],
            pestTraps: [],
            pestTrapItems: [],
            trapItem: '',
            trapItemSel: null,
            trapDescription: '',
            deleteOpen: false,
            minHumidity: '',
            maxHumidity: '',
            minTemperature: '',
            maxTemperature: '',
            tempUOM: '',
            tempUOMSel: null,
            tempUOMs: []
        };
        this.changeValue = this.changeValue.bind(this);
    }

    async componentDidMount(){
        try {
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.activeLocation, common.cacheValues.roomType, common.cacheValues.lightType, 
                common.cacheValues.lengthUOM, common.cacheValues.growRoomType, common.cacheValues.pestTrapItem, common.cacheValues.tempUOM], 
                this.props.auth.constants, null, this.props.auth.user.tenant);
            let locations = cacheValues.locations;
            let locationSel = null;
            let location = '';
            if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
                location = this.props.auth.user.defaultLocation;
                locationSel = locations.find(x => x.value === location);
            } else {
                if(locations.length === 1){
                    locationSel = locations[0];
                    location = locationSel.value;
                }
            }
            let isNew = (this.props.match.params.id == null || this.props.match.params.id === '') && this.props.permission.create;
            this.setState({
                locations: locations,
                location: location,
                locationSelected: locationSel,
                types: cacheValues.roomTypes,
                uoms: cacheValues.lengthUOMs,
                lightTypes: cacheValues.lightTypes,
                _id: this.props.match.params.id,
                security: this.props.permission,
                isNew: isNew,
                locked: isNew ? false : true,
                growRoomTypes: cacheValues.growRoomTypes,
                pestTrapItems: cacheValues.pestTrapItems,
                tempUOMs: cacheValues.tempUOMs
            }, async () => {
                if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                    await this.loadRecord();
                }else {
                    const crumbs = [
                        { path: '/#/rooms', label: this.props.t('rooms'), screen: this.props.auth.screenDefs.Room},
                        { path: 'active', label: this.props.t('roomDetails'), screen: this.props.auth.screenDefs.Room}
                    ]
                    this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
                }
            });
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: this.props.t('error'), severity: 'error'}});
        }
    }

    loadRecord = async () => {
        const t = this.props.t;
        let room = await axios.get('/api/room/' + this.state._id);
        let canDelete = room.changes == null || room.changes.length === 0;
        let locationSelected = this.state.locations.find(x => x.value === room.data.location);
        let typeSelected = this.state.types.find(x => x.value === room.data.type);
        let lightTypeSel = room.data.lightType != null ? this.state.lightTypes.find(x => x.value === room.data.lightType) : null;
        let widthUOMSel = room.data.widthUOM != null ? this.state.uoms.find(x => x.value === room.data.widthUOM) : null;
        let lengthUOMSel = room.data.lengthUOM != null ? this.state.uoms.find(x => x.value === room.data.lengthUOM) : null;
        let growRoomTypeSel = room.data.growRoomType != null ? this.state.growRoomTypes.find(x => x.value === room.data.growRoomType) : null;
        let tempUOMSel = room.data.tempUOM != null ? this.state.tempUOMs.find(x => x.value === room.data.tempUOM) : null;
        let growing = (room.data.type != null && room.data.type !== '' &&
            room.data.type === this.props.auth.constants.roomTypes.growing)
        let tabLabels = [{name: t('details'), disabled: false}];
        if(room.data.type === this.props.auth.constants.roomTypes.growing){
            tabLabels.push({name: t('tables'), disabled: false}, {name: t('settings'), disabled: false}, {name: t('checks'), disabled: false});
        } else if (room.data.type === this.props.auth.constants.roomTypes.production){
            tabLabels.push({name: t('settings'), disabled: false}, {name: t('checks'), disabled: false})
        }
        let pestTraps = room.data.pestTraps;
        if(pestTraps != null && pestTraps.length > 0){
            for(let row of pestTraps){
                let itemSel = this.state.pestTrapItems.find(x => x.value === row.item);
                row.itemSel = itemSel;
            }
        }
        this.setState({
            _id: room.data._id,
            name: room.data.name,
            location: room.data.location,
            type: room.data.type,
            canDelete: canDelete,
            number: room.data.number,
            locationSelected: locationSelected,
            typeSelected: typeSelected,
            lightType : room.data.lightType,
            lightTypeSel: lightTypeSel,
            lightCount: room.data.lightCount,
            width: room.data.width,
            widthUOM: room.data.widthUOM,
            widthUOMSel: widthUOMSel,
            length: room.data.length,
            lengthUOM: room.data.lengthUOM,
            lengthUOMSel: lengthUOMSel,
            cloneCount: room.data.cloneCount,
            vegCount: room.data.vegCount,
            flowerCount: room.data.flowerCount,
            section: room.data.section,
            growing: growing,
            tables: room.data.tables,
            tabLabels: tabLabels,
            workCenters: room.data.workCenters != null ? room.data.workCenters : [],
            bins: room.data.bins,
            equipments: room.data.equipments,
            foreignId: room.data.foreignId,
            growRoomType: room.data.growRoomType,
            growRoomTypeSel: growRoomTypeSel,
            pestTraps: pestTraps != null ? pestTraps : [],
            deleteOpen: false,
            tempUOM: room.data.tempUOM,
            tempUOMSel:tempUOMSel,
            minHumidity: room.data.minHumidity,
            maxHumidity: room.data.maxHumidity,
            minTemperature: room.data.minTemperature,
            maxTemperature: room.data.maxTemperature
        });
    };

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

   _saveClick = async () => {
        const t = this.props.t;
        let valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let pestTraps = this.state.pestTraps;
        for(let [i, row] of pestTraps.entries()){
            row.sequence = i;
        }
        let data = {
            _id: this.state._id,
            name: this.state.name,
            location: this.state.location,
            type: this.state.type,
            foreignId: this.state.foreignId,
            lightType: this.state.lightType,
            lightCount: this.state.lightCount,
            length: this.state.length,
            lengthUOM: this.state.lengthUOM,
            width: this.state.width,
            widthUOM: this.state.widthUOM,
            section: this.state.section,
            cloneCount: this.state.cloneCount,
            vegCount: this.state.vegCount,
            flowerCount: this.state.flowerCount,
            growRoomType: this.state.growRoomType,
            pestTraps: pestTraps,
            tempUOM: this.state.tempUOM,
            minHumidity: this.state.minHumidity,
            maxHumidity: this.state.maxHumidity,
            minTemperature: this.state.minTemperature,
            maxTemperature: this.state.maxTemperature
        };
        try {
            let response = await axios.post('/api/room', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/room/' + response.data.id);
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: response.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            })
        }
    }

    validateForm(){
        const t = this.props.t;
        let errors = {};
        if(this.state.name == null || this.state.name === '')
            errors[t('name')] = t('required');
        if(this.state.location == null || this.state.location === '')
            errors[t('location')] = t('required');
        if(this.state.type == null || this.state.type === '')
            errors[t('type')] = t('required');
        if(this.state.length != null && this.state.length !== '' && this.state.length !== 0){
            if(this.state.lengthUOM == null || this.state.lengthUOM === '')
                errors[t('lengthUOM')] = t('required')
        }
        if(this.state.width != null && this.state.width !== '' && this.state.width !== 0){
            if(this.state.widthUOM == null || this.state.widthUOM === '')
                errors[t('widthUOM')] = t('required');
        }
        if((this.state.trapItem != null && this.state.trapItem !== '') || (this.state.trapDescription != null && this.state.trapDescription !== '')){
            errors[t('trapItem')] = t('rowNotAdded');
        }
        if(this.state.pestTraps != null && this.state.pestTraps.length > 0){
            for(let [i, row] of this.state.pestTraps.entries()){
                if(row.item == null || row.item === '')
                    errors[`${t('item')} (${t('line')} ${i})`] = t('required');
                if(row.description == null || row.description === '')
                    errors[`${t('description')} (${t('line')} ${i})`] = t('required');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeValue(e){
        var name = e.target.name;
        var value = e.target.value;
        if(name === 'lightCount' || name === "width" || name === "length"){
            if(isNaN(value)){
                return;
            }
        }
        this.setState({
            [name]: value
        });
    }

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.garget.value;
        if(isNaN(value)){
            return;
        }
        this.setState({
            [name]: value
        });
    }

    changeAuto = (name, data) => (e, newValue) => {
        var growing = this.state.growing;
        if(data === 'type'){
            if(newValue == null){
                growing = false;
            } else {
                growing = newValue.value === this.props.auth.constants.roomTypes.growing;
            }
        }
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            growing: growing
        });
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick(){
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick());
    }

    async _deleteClick(){
        if(this.state._id == null || this.state._id === ''){
            this.setState({enableSave: true, deleteOpen: false});
            this.props.history.goBack();
            return;
        }
        try{
            let result = await axios.delete('/api/room/' + this.state._id);
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            this.props.history.goBack();
        } catch(err){
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    unlock = (e) => {
        this.setState({locked: !this.state.locked});
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    changeLineAuto = (name, data, i) => (e, newValue) => {
        let pestTraps = this.state.pestTraps;
        let row = pestTraps[i];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : null;
        this.setState({pestTraps: pestTraps});
    };

    changeLineValue = (i) => (e) => {
        let pestTraps = this.state.pestTraps;
        let row = pestTraps[i];
        row[e.target.name] = e.target.value;
        this.setState({pestTraps: pestTraps});
    }

    addLine = () => {
        const t = this.props.t;
        let pestTraps = this.state.pestTraps;
        let errors = {};
        if(this.state.trapItem == null || this.state.trapItem === '')
            errors[t('trapItem')] = t('required');
        if(this.state.trapDescription == null || this.state.trapDescription === '')
            errors[t('trapDescription')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return;
        }
        pestTraps.push({
            item: this.state.trapItem,
            itemSel: this.state.trapItemSel,
            description: this.state.trapDescription
        });
        this.setState({
            pestTraps: pestTraps,
            trapItem: '',
            trapItemSel: null,
            trapDescription: '',
            errors: {}
        })
    };

    deleteLine = (i) => (e) => {
        let pestTraps = this.state.pestTraps;
        pestTraps.splice(i, 1);
        this.setState({pestTraps: pestTraps});
    };

    render(){
        const locked = this.state.locked;
        const errors = this.props.errors;
        const t = this.props.t;
        const micro = this.props.auth.user.micro;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabPanel key={0} value={this.state.tabKey} index={0}>
                    <Grid container spacing={3}>
                        {this.state.number != null && this.state.number !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl data-cy="number">
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={8} sm={4}>
                            {!locked &&
                                <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                size="medium" fullWidth={true} label={t('name')} disabled={locked} required
                                error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}
                                data-cy="name"/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('name') + '*'}</FormLabel>
                                    {this.state.name}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.locationSelected}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locationSelected', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]}
                                    label={t('location')}
                                    disabled={locked}
                                    required
                                    data-cy="location"
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('location') + '*'}</FormLabel>
                                    {this.state.locationSelected != null &&
                                        <Link to={'/locationdetail/' + this.state.locationSelected.value}>{this.state.locationSelected.label}</Link>
                                    }
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.typeSelected}
                                    options={this.state.types}
                                    onChange={this.changeAuto('typeSelected', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]}
                                    label={t('type')}
                                    disabled={locked}
                                    required
                                    data-cy="type"
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('type') + '*'}</FormLabel>
                                    {this.state.typeSelected != null ? this.state.typeSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        {this.state.growing &&
                            <Aux>
                                {!locked &&
                                    <Grid item xs={4} sm={2}>
                                        <AutoCompleteField
                                            value={this.state.growRoomTypeSel}
                                            options={this.state.growRoomTypes}
                                            onChange={this.changeAuto('growRoomTypeSel', 'growRoomType')}
                                            error={errors[t('growRoomType')] != null ? true : false}
                                            helperText={errors[t('growRoomType')]}
                                            label={t('growRoomType')}
                                            disabled={locked}
                                            required={this.state.length != null && this.state.length !== '' && this.state.length !== 0}
                                            data-cy="growRoomType"
                                        />
                                    </Grid>
                                }
                                {locked && this.state.growRoomTypeSel != null &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('growRoomType')}</FormLabel>
                                            {this.state.growRoomTypeSel.label}
                                        </FormControl>
                                    </Grid>
                                }
                            </Aux>
                        }
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <TextField value={this.state.foreignId} onChange={this.changeValue} name="foreignId"
                                size="medium" fullWidth={true} label={t('erpId')} disabled={locked} data-cy="foreignId"
                                error={errors[t('foreignId')] != null ? true : false} helperText={errors[t('foreignId')]}/>
                            }
                            {locked && this.state.foreignId != null && this.state.foreignId !== '' &&
                                <FormControl>
                                    <FormLabel>{t('erpId')}</FormLabel>
                                    {this.state.foreignId != null && this.state.foreignId !== '' ? this.state.foreignId : ''}
                                </FormControl>
                            }
                        </Grid>
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.length} onChange={this.changeValue} name="length"
                                size="medium" fullWidth={true} label={t('length')} disabled={locked} data-cy="length"
                                error={errors[t('length')] != null ? true : false} helperText={errors[t('length')]}/>
                            </Grid>
                        }
                        {locked && this.state.length != null && this.state.length !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('length')}</FormLabel>
                                    {this.state.lengthUOMSel != null ? this.state.length + this.state.lengthUOMSel.label : this.state.length}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <AutoCompleteField
                                    value={this.state.lengthUOMSel}
                                    options={this.state.uoms}
                                    onChange={this.changeAuto('lengthUOMSel', 'lengthUOM')}
                                    error={errors[t('lengthUOM')] != null ? true : false}
                                    helperText={errors[t('lengthUOM')]}
                                    label={t('uom')}
                                    disabled={locked}
                                    required={this.state.length != null && this.state.length !== '' && this.state.length !== 0}
                                    data-cy="lengthUOM"
                                />
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.width} onChange={this.changeValue} name="width"
                                size="medium" fullWidth={true} label={t('width')} disabled={locked} data-cy="width"
                                error={errors[t('width')] != null ? true : false} helperText={errors[t('width')]}/>
                            </Grid>
                        }
                        {locked && this.state.width != null && this.state.width !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('width')}</FormLabel>
                                    {this.state.widthUOMSel != null ? this.state.width + this.state.widthUOMSel.label : this.state.width}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <AutoCompleteField
                                    value={this.state.widthUOMSel}
                                    options={this.state.uoms}
                                    onChange={this.changeAuto('widthUOMSel', 'widthUOM')}
                                    error={errors[t('widthUOM')] != null ? true : false}
                                    helperText={errors[t('widthUOM')]}
                                    label={t('uom')}
                                    disabled={locked}
                                    required={this.state.width != null && this.state.width !== '' && this.state.width !== 0}
                                    data-cy="widthUOM"
                                />
                            </Grid>
                        }
                    </Grid>
                    <br/>
                    <Grid container spacing={3}>
                        {!locked &&
                             <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.minTemperature} onChange={this.changeValue} name="minTemperature"
                                size="medium" fullWidth={true} label={t('minTemperature')} disabled={locked} data-cy="minTemperature"
                                error={errors[t('minTemperature')] != null ? true : false} helperText={errors[t('minTemperature')]}/>
                            </Grid>
                        }
                        {locked && this.state.minTemperature != null && this.state.minTemperature !== '' &&
                             <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('minTemperature')}</FormLabel>
                                    {this.state.tempUOMSel != null ? this.state.minTemperature + ' ' + this.state.tempUOMSel.label : this.state.minTemperature}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.maxTemperature} onChange={this.changeValue} name="maxTemperature"
                                size="medium" fullWidth={true} label={t('maxTemperature')} disabled={locked} data-cy="maxTemperature"
                                error={errors[t('maxTemperature')] != null ? true : false} helperText={errors[t('maxTemperature')]}/>
                            </Grid>
                        }
                        {locked && this.state.maxTemperature != null && this.state.maxTemperature !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('maxTemperature')}</FormLabel>
                                    {this.state.tempUOMSel != null ? this.state.maxTemperature + ' ' + this.state.tempUOMSel.label : this.state.maxTemperature}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <AutoCompleteField
                                    value={this.state.tempUOMSel}
                                    options={this.state.tempUOMs}
                                    onChange={this.changeAuto('tempUOMSel', 'tempUOM')}
                                    error={errors[t('tempUOM')] != null ? true : false}
                                    helperText={errors[t('tempUOM')]}
                                    label={t('tempUOM')}
                                    disabled={locked}
                                    required={(this.state.minTemperature != null && this.state.minTemperature !== '' && this.state.minTemperature !== 0) ||
                                    (this.state.maxTemperature != null && this.state.maxTemperature !== '' && this.state.maxTemperature !== 0)}
                                    data-cy="tempUOM"
                                />
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.minHumidity} onChange={this.changeValue} name="minHumidity"
                                size="medium" fullWidth={true} label={t('minHumidity')} disabled={locked} data-cy="minHumidity"
                                error={errors[t('minHumidity')] != null ? true : false} helperText={errors[t('minHumidity')]}/>
                            </Grid>
                        }
                        {locked && this.state.minHumidity != null && this.state.minHumidity !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('minHumidity')}</FormLabel>
                                    {this.state.minHumidity}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.maxHumidity} onChange={this.changeValue} name="maxHumidity"
                                size="medium" fullWidth={true} label={t('maxHumidity')} disabled={locked} data-cy="maxHumidity"
                                error={errors[t('maxHumidity')] != null ? true : false} helperText={errors[t('maxHumidity')]}/>
                            </Grid>
                        }
                        {locked && this.state.maxHumidity != null && this.state.maxHumidity !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('maxHumidity')}</FormLabel>
                                    {this.state.maxHumidity}
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <Grid container spacing={3}>
                        {this.state.growing &&
                            <Aux>
                                <Grid item xs={6} sm={3}>
                                    {!locked &&
                                        <TextField value={this.state.section} onChange={this.changeValue} name="section"
                                        size="medium" fullWidth={true} label={t('section')} disabled={locked} data-cy="section"
                                        error={errors[t('section')] != null ? true : false} helperText={errors[t('section')]}/>
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('section')}</FormLabel>
                                            {this.state.section}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <AutoCompleteField
                                            value={this.state.lightTypeSel}
                                            options={this.state.lightTypes}
                                            onChange={this.changeAuto('lightTypeSel', 'lightType')}
                                            error={errors[t('lightType')] != null ? true : false}
                                            helperText={errors[t('lightType')]}
                                            label={t('lightType')}
                                            disabled={locked}
                                            data-cy="lightType"
                                        />
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('lightType')}</FormLabel>
                                            {this.state.lightTypeSel != null ? this.state.lightTypeSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <TextField type="number" value={this.state.lightCount} onChange={this.changeValue} name="lightCount"
                                        size="medium" fullWidth={true} label={t('lightCount')} disabled={locked} data-cy="lightCount"
                                        error={errors[t('lightCount')] != null ? true : false} helperText={errors[t('lightCount')]}/>
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('lightCount')}</FormLabel>
                                            {this.state.lightCount}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    {!locked &&
                                        <TextField type="number" value={this.state.cloneCount} onChange={this.changeValue} name="cloneCount"
                                        size="medium" fullWidth={true} label={t('cloneCount')} disabled={locked} data-cy="cloneCount"
                                        required={this.state.type === this.props.auth.constants.roomTypes.growing}
                                        error={errors[t('cloneCount')] != null ? true : false} helperText={errors[t('cloneCount')]}/>
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('cloneCount')}</FormLabel>
                                            {this.state.cloneCount}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    {!locked &&
                                        <TextField type="number" value={this.state.vegCount} onChange={this.changeValue} name="vegCount"
                                        size="medium" fullWidth={true} label={t('vegCount')} disabled={locked} data-cy="vegCount"
                                        required={this.state.type === this.props.auth.constants.roomTypes.growing}
                                        error={errors[t('vegCount')] != null ? true : false} helperText={errors[t('vegCount')]}/>
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('vegCount')}</FormLabel>
                                            {this.state.vegCount}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={3}>
                                    {!locked &&
                                        <TextField type="number" value={this.state.flowerCount} onChange={this.changeValue} name="flowerCount"
                                        size="medium" fullWidth={true} label={t('flowerCount')} disabled={locked} data-cy="flowerCount"
                                        required={this.state.type === this.props.auth.constants.roomTypes.growing}
                                        error={errors[t('flowerCount')] != null ? true : false} helperText={errors[t('flowerCount')]}/>
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('flowerCount')}</FormLabel>
                                            {this.state.flowerCount}
                                        </FormControl>
                                    }
                                </Grid>
                            </Aux>
                        }
                    </Grid>
                    {!micro && !locked && this.state.pestTrapItems != null && this.state.pestTrapItems.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('pestTraps')}/>
                                <Grid container spacing={2} key='editPestHead'>
                                    <Grid item xs={6} sm={3}>{t('item')}</Grid>
                                    <Grid item xs={12} sm={6}>{t('description')}</Grid>
                                </Grid>
                                {this.state.pestTraps.map((row, i) => 
                                    <Grid container spacing={2} key={'editPest' + i}>
                                        <Grid item xs={6} sm={3}>
                                            <AutoCompleteField
                                                value={row.itemSel}
                                                options={this.state.pestTrapItems}
                                                onChange={this.changeLineAuto('itemSel', 'item', i)}
                                                error={errors[`${t('item')} (${t('line')} ${i})`] != null ? true : false}
                                                helperText={errors[`${t('item')} (${t('line')} ${i})`]}
                                                disabled={locked}
                                                data-cy={['item' + i]}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField type="text" value={row.description} onChange={this.changeLineValue(i)} name={'description'}
                                            size="medium" fullWidth={true} disabled={locked} data-cy={'description' + i}
                                            required={this.state.type === this.props.auth.constants.roomTypes.growing}
                                            error={errors[`${t('description')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('description')} (${t('line')} ${i})`]}/>
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <IconButton aria-label="delete line" onClick={this.deleteLine(i)}>
                                                <RemoveCircleTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={3}>
                                        <AutoCompleteField
                                            value={this.state.trapItemSel}
                                            options={this.state.pestTrapItems}
                                            onChange={this.changeAuto('trapItemSel', 'trapItem')}
                                            error={errors[t('trapItem')] != null ? true : false}
                                            helperText={errors[t('trapItem')]}
                                            disabled={locked}
                                            data-cy="trapItem"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type="text" value={this.state.trapDescription} onChange={this.changeValue} name="trapDescription"
                                        size="medium" fullWidth={true} disabled={locked} data-cy="trapDescription"
                                        required
                                        error={errors[t('trapDescription')] != null ? true : false} helperText={errors[t('trapDescription')]}/>
                                    </Grid>
                                    <Grid item sx={{width: 50}}>
                                        <IconButton aria-label="add line" onClick={() => this.addLine()}>
                                            <AddBoxTwoToneIcon  />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                        </Aux>
                    }
                    {!micro && locked && this.state.pestTraps != null && this.state.pestTraps.length > 0 &&
                        <Aux>
                            <br/>
                            <br/>
                            <br/>
                            <SectionTitle title={t('pestTraps')}/>
                            <TableContainer component={Paper}>
                                <Table aria-label="PestTraps" size="small">
                                    <TableHead>
                                        <TableRow key="pesttraphead">
                                            <TableCell>{t('item')}</TableCell>
                                            <TableCell>{t('description')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.pestTraps.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell><Link to={'/item/' + row.item}>{row.itemSel.label}</Link></TableCell>
                                                <TableCell>{row.description}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Aux>
                    }
                    <br/>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={7}>
                            {!locked &&
                                <Aux>
                                    <Button color="primary" variant="contained" size="small" onClick={() => this.saveClick(this)}>
                                        {t('save')}
                                    </Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {this.props.permission.unlock &&
                                <Aux>
                                    <Button color="secondary" variant="contained" size="small" onClick={this.unlock}>
                                        {locked ? t('unlock') : t('lock')}
                                    </Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {!locked &&
                                <Button color="secondary" variant="contained" size="small" onClick={() => this.deleteToggle()}>
                                    {t('delete')}
                                </Button>
                            }

                        </Grid>
                    </Grid>
                    {this.state.equipments != null && this.state.equipments.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('equipment')}/>
                            <TableContainer component={Paper}>
                                <Table aria-label="Equipment" size="small">
                                    <TableHead>
                                        <TableRow key="equiphead">
                                            <TableCell>{t('number')}</TableCell>
                                            <TableCell>{t('manufacturer')}</TableCell>
                                            <TableCell>{t('model')}</TableCell>
                                            <TableCell>{t('purchased')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.equipments.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell>
                                                    <Link to={'/equipment/' + row._id}>{row.identityNo + ' - ' + row.name}</Link>
                                                </TableCell>
                                                <TableCell>{row.manufacturer != null ? row.manufacturer : ''}</TableCell>
                                                <TableCell>{row.model != null ? row.model : ''}</TableCell>
                                                <TableCell>{row.purchaseDate != null && <DateDisplay value={row.purchaseDate} format={this.props.auth.user.dateFormat}/> }
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Aux>
                    }
                    {this.state.workCenters != null && this.state.workCenters.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('workCenters')}/>
                            <TableContainer component={Paper}>
                                <Table aria-label="Work Centers" size="small">
                                    <TableHead>
                                        <TableRow key="tableHeader">
                                             <TableCell>{t('number')}</TableCell>
                                             <TableCell>{t('name')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.workCenters.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell><Link to={'/workcenter/' + row._id}>{row.number}</Link></TableCell>
                                                <TableCell>{row.name}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Aux>
                    }
                    {this.state.bins != null && this.state.bins.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('bins')}/>
                            <TableContainer component={Paper}>
                                <Table aria-label="bins" size="small">
                                    <TableHead>
                                        <TableRow key="binhead">
                                            <TableCell>{t('number')}</TableCell>
                                            <TableCell>{t('type')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.bins.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell>
                                                    <Link to={'/bin/' + row._id}>{row.code + ' - ' + row.description}</Link>
                                                </TableCell>
                                                <TableCell>{row.type != null ? row.type : ''}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Aux>
                    }
                    {this.state.growing && this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <br/>
                            <RoomBatches room={this.props.match.params.id} auth={this.props.auth}/>
                        </Aux>
                    }
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <br/>
                            <RoomCleanings room={this.state._id}/>
                        </Aux>
                    }
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <br/>
                            <MaintenanceLogs room={this.state._id}/>
                        </Aux>
                    }
                </TabPanel>
                {this.state.tabLabels.length > 1 &&
                    <Aux>
                        {this.state.type != null && this.state.type === this.props.auth.constants.roomTypes.growing &&
                            <Aux>
                                <TabPanel key={1} value={this.state.tabKey} index={1}>
                                    <RoomTables room={this.props.match.params.id} locked={this.state.locked} permission={this.props.permission}/>
                                </TabPanel>
                                <TabPanel key={2} value={this.state.tabKey} index={2}>
                                    <RoomDetails id={this.state._id} permission={this.props.permission}/>
                                </TabPanel>
                                <TabPanel key={3} value={this.state.tabKey} index={3}>
                                    <RoomChecks room={this.props.match.params.id} permission={this.props.permission}/>
                                </TabPanel>
                            </Aux>
                        }
                        {this.state.type != null && this.state.type === this.props.auth.constants.roomTypes.production &&
                            <Aux>
                                <TabPanel key={1} value={this.state.tabKey} index={1}>
                                    <RoomDetails id={this.state._id}/>
                                </TabPanel>
                                <TabPanel key={2} value={this.state.tabKey} index={2}>
                                    <RoomChecks room={this.props.match.params.id} permission={this.props.permission}/>
                                </TabPanel>
                            </Aux>
                        }
                    </Aux>
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Room));
