import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import { CSVDownload } from "react-csv";
import dateFormat from 'date-fns/format';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete';
import BatchRecordForm from './Forms/BatchRecord';
import ActionBar from '../UI/Buttons/ActionBar';
import ConversionHelper from '../../helpers/conversionHelper';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';
import listHelper from '../../helpers/listHelper';
import ListMultiSelect from '../UI/ListMultiSelect/ListMultiSelect';

const CultivationBatches = (props) => {
    const isFirstRender = useRef(true);
    const [loadRefData, setLoadRefData] = useState(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [csvOutput, setCsvOutput] = useState({headers: null, data: null});
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [columnOrder, setColumnOrder] = useState([]);
    const [values, setValues] = useState({locations: [], strains: [], rooms: [], templates: [], stages: [], statuses: []});
    const [ strain, setStrain ] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const { t } = useTranslation('translation');
    const csvSeparator = sessionStorage.getItem('csvSeparator');
    const tenant = props.auth.user.tenant;
    const batchStatuses = props.auth.constants.batchStatuses;
    const [statusesSel, setStatusesSel] = useState({ids: [], values: []});
    const [stagesSel, setStagesSel] = useState({ids: [], values: []});
    const [showSelect, setShowSelect] = useState(false);
    const crumbs = [
        { path: 'active', label: t('batches'), screen: props.auth.screenDefs.CultivationBatch}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        async function fetchData(){
            const columnFilters = await listHelper.getItem('CultivationBatchesColumnFilters', tenant);
            const columnVisibility = await listHelper.getItem('CultivationBatchesColumnVisibility', tenant);
            const density = await listHelper.getItem('CultivationBatchesDensity', tenant);
            const globalFilter = await listHelper.getItem('CultivationBatchesGlobalFilter', tenant);
            const showGlobalFilter = await listHelper.getItem('CultivationBatchesShowGlobalFilter', tenant);
            const showColumnFilters = await listHelper.getItem('CultivationBatchesShowColumnFilters', tenant);
            const sorting = await listHelper.getItem('CultivationBatchesSorting', tenant);
            const pagination = await listHelper.getItem('CultivationBatchesPagination', tenant);
            const tempColumnOrder = await listHelper.getItem('CultivationBatchesColumnOrder', tenant);
            if (columnFilters) {
                let filters = ConversionHelper.filtersDateParse(JSON.parse(columnFilters));
                let strain = filters.find(x => x.id === 'strain');
                if(strain?.value != null && values.strains?.length > 0) {
                    let sel = values.strains.find(x => x.value === strain.value);
                    setStrain(sel);
                }
                let status = filters.find(x => x.id === 'status');
                if(status != null){
                    let sel = {ids: status.value, values: []};
                    status.value.forEach(id => {
                        let val = values.statuses.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setStatusesSel(sel);
                }
                let stage = filters.find(x => x.id === 'stage');
                if(stage != null){
                    let sel = {ids: stage.value, values: []};
                    stage.value.forEach(id => {
                        let val = values.stages.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setStagesSel(sel);
                }
                setColumnFilters(filters);
            }
            if (columnVisibility) {
                setColumnVisibility(columnVisibility);
            }
            if (density) {
                setDensity(density);
            }
            if (globalFilter) {
                setGlobalFilter(globalFilter);
            }
            if (showGlobalFilter) {
                setShowGlobalFilter(showGlobalFilter);
            }
            if (showColumnFilters) {
                setShowColumnFilters(showColumnFilters);
            }
            if (sorting) {
                setSorting(sorting);
            }
            if (pagination) {
                setPagination(pagination);
            }
            if (tempColumnOrder != null && tempColumnOrder.length > 0) {
                setColumnOrder(tempColumnOrder);
            } else {
                setColumnOrder(['number', 'status', 'strain', 'stage', 'template', 'plannedStart', 'actualStart', 'quantity', 'currentRooms', 'expectedHarvest', 'harvestDate']);
            }
            isFirstRender.current = false;
        }
        if(isFirstRender.current === true || columnOrder.length === 0){
            fetchData();
        }
    }, [batchStatuses, values, tenant, columnOrder]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('CultivationBatchesColumnFilters', tenant, JSON.stringify(columnFilters));
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('CultivationBatchesColumnVisibility', tenant, columnVisibility);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnVisibility, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('CultivationBatchesDensity', tenant, density);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [density, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('CultivationBatchesGlobalFilter', tenant, globalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [globalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('CultivationBatchesShowGlobalFilter', tenant, showGlobalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [showGlobalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('CultivationBatchesShowColumnFilters', tenant, showColumnFilters);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [showColumnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('CultivationBatchesSorting', tenant, sorting);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [sorting, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('CultivationBatchesPagination', tenant, pagination);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [pagination, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('CultivationBatchesColumnOrder', tenant, columnOrder);
        }
        if (isFirstRender.current === true) return;
        fetchData();
    }, [columnOrder, tenant]);

    const resetState = async () => {
        await listHelper.removeItem('CultivationBatchesColumnFilters', tenant);
        await listHelper.removeItem('CultivationBatchesColumnVisibility', tenant);
        await listHelper.removeItem('CultivationBatchesDensity', tenant);
        await listHelper.removeItem('CultivationBatchesGlobalFilter', tenant);
        await listHelper.removeItem('CultivationBatchesShowGlobalFilter', tenant);
        await listHelper.removeItem('CultivationBatchesShowColumnFilters', tenant);
        await listHelper.removeItem('CultivationBatchesSorting', tenant);
        await listHelper.removeItem('CultivationBatchesPagination', tenant);
        await listHelper.removeItem('CultivationBatchesColumnOrder', tenant);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.strain, common.cacheValues.cultivationLocation, common.cacheValues.growingRoom,
                common.cacheValues.batchStage, common.cacheValues.batchTemplate], props.auth.constants, null, props.auth.user.tenant);
            const statuses = await ValueHelper.getStatusesForObject(props.auth.constants.objectIds.evoraBatch, props.auth.user.tenant);
            let strain = columnFilters.find(x => x.id === 'strain');
            if(strain != null) {
                if(strain.value != null){
                    let sel = cacheValues.strains.find(x => x.value === strain.value);
                    setStrain(sel);
                }
            }
            let status = columnFilters != null ? columnFilters.find(x => x.id === 'status') : null;
            if(status != null){
                let sel = {ids: status.value, values: []};
                status.value.forEach(id => {
                    let val = statuses.find(x => x.value === id);
                    sel.values.push(val);
                });
                setStatusesSel(sel);
            }
            let stage = columnFilters != null ? columnFilters.find(x => x.id === 'stage') : null;
            if(stage != null){
                let sel = {ids: stage.value, values: []};
                stage.value.forEach(id => {
                    let val = cacheValues.batchStages.find(x => x.value === id);
                    sel.values.push(val);
                });
                setStagesSel(sel);
            }
            setValues({
                strains: cacheValues.strains,
                locations: cacheValues.locations,
                rooms: cacheValues.rooms,
                stages: cacheValues.batchStages,
                templates: cacheValues.batchTemplates,
                statuses: statuses
            });
            setLoadRefData(false);
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, columnFilters, loadRefData]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'CultivationBatches',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/ebatches', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });

    let columns = useMemo(() => [
        {
            accessorKey: 'number',
            id: 'number',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/cultivationbatch/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            )
        },
        {
            accessorKey: 'status',
            id: 'status',
            header: t('status'),
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='status'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.statuses} 
                    setSelected={setStatusesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={statusesSel.ids} 
                    selectedSel={statusesSel.values}
                    screen='CultivationBatches'
                    tenant={props.auth.user.tenant}
                    columnFilters={columnFilters}/>
                </Box>
            ),
        },
        {
            accessorKey: 'strain',
            id:'strain',
            header: t('strain'),
            Filter: ({ header, column }) =>
                <AutoComplete 
                    options={values.strains}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, newValue) => {
                        setStrain(newValue);
                        column.setFilterValue(newValue != null ? newValue.value : null);
                    }}
                    value={strain}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                />,
        },
        {
            accessorKey: 'stage',
            id: 'stage',
            header: t('stage'),
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='stage'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.stages} 
                    setSelected={setStagesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={stagesSel.ids} 
                    selectedSel={stagesSel.values}
                    screen='CultivationBatches'
                    tenant={props.auth.user.tenant}
                    columnFilters={columnFilters}/>
                </Box>
            ),
        },
        {
            accessorKey: 'template',
            id: 'template',
            header: t('template'),
            filterVariant: 'select',
            filterSelectOptions: values.templates
        },
        {
            accessorKey: 'plannedStart',
            id: 'plannedStart',
            header: t('plannedStart'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() != null ? dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat) : ''}
                </Box>
            )
        },
        {
            accessorKey: 'actualStart',
            id: 'actualStart',
            header: t('actualStart'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() != null ? dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat) : ''}
                </Box>
            )
        },
        {
            accessorKey: 'quantity',
            id: 'quantity',
            header: t('quantity'),
            enableColumnFilter: false
        },
        {
            accessorKey: 'currentRooms',
            id: 'rooms',
            header: t('rooms'),
            filterVariant: 'select',
            filterSelectOptions: values.rooms
        },
        {
            accessorKey: 'expectedHarvest',
            id: 'expectedHarvest',
            header: t('expectedHarvest'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() != null ? dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat) : ''}
                </Box>
            )
        },
        {
            accessorKey: 'harvestDate',
            id: 'harvestDate',
            header: t('harvested'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() != null ? dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat) : ''}
                </Box>
            )
        }
    ], [values, t, strain, props, showSelect, statusesSel, columnFilters, stagesSel]);

    const csv = async () => {
        setCsvOutput({headers: null, data: null});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axios.get('/api/ebatchesexport', {params: params});
        let headers = response.data.headers;
        let csvData = response.data.data;
        setCsvOutput({headers: headers, data: csvData});
    }

    const toggleDialog = () => {
       setDialogOpen(!dialogOpen);
    }

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { 
            showColumnFilters: true, columnVisibility: {id: false}
        },
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: false,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()} color="primary">
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('csv')}>
                    <IconButton onClick={() => csv()}>
                        <ExcelIcon color='primary'/>
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('clearListSettings')}>
                    <IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                {props.permission.create &&
                    <Tooltip arrow title={t('add')}>
                        <IconButton component={Button} onClick={toggleDialog} color="primary" data-cy='actionCreate'>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs} >
                <ActionBar/>
            </BreadcrumbBar>
            
            {csvOutput != null && csvOutput.data != null &&
                <CSVDownload data={csvOutput.data} target="_blank" headers={csvOutput.headers} separator={csvSeparator}/>
            }
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
            {dialogOpen &&
                <BatchRecordForm
                    fieldAccess={props.permission.create}
                    reload={refetch}
                    permission={props.permission}
                    open={dialogOpen}
                    onClose={() => toggleDialog()}
                />
            }
        </Aux>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(CultivationBatches);