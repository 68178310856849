import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from '../../hoc/Auxilary/Auxilary';
import axios from 'axios';
import GeneratePdf from '../General/GeneratePdf';
import ReactDOMServer from 'react-dom/server';
import QRCode from '../UI/QRCode/QRCode';
import Panel from '../UI/Panel/Panel';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { withTranslation } from 'react-i18next';
import EvoraBatchPrintMarkup from '../Cultivation/EvoraBatchPrintMarkup';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

function Header(props){ 
    const { record, t, printView } = props;
    if(record != null) {
        return (
            <div style={{ width: '100%', margin: printView ? '0 30px' : null }}>
                <QRCode mono={true} size='sm' value={record.qrPath} />
                <table style={{ width: '91%', float: 'right' }}>
                    <tbody>
                        <tr>
                            <td>
                                <span style={{ fontSize: '10pt'}}><strong>{t('Certificate Of Production')}</strong></span><br />
                                <div style={{ width: '350px'}}>
                                    <span style={{ fontSize: '9pt' }}><strong>{t('item')}:&nbsp;</strong>
                                        {record.outputItem != null ? record.outputItem.name : ''}
                                    </span><br />
                                </div>
                                <span style={{ fontSize: '9pt' }}><strong>{t('Status')}:&nbsp;</strong>
                                    {record.status != null ? record.status.label : ''}
                                </span>
                            </td>
                            <td style={{ textAlign: 'right'}}>
                                <span style={{ fontSize: '9pt' }}><strong>{t('batch')}:&nbsp;</strong>
                                    {record.customNumber != null && record.customNumber !== '' ? record.customNumber : record.number}
                                </span><br/>    
                                <span style={{ fontSize: '9pt' }}><strong>{t('productionSite')}:&nbsp;</strong>
                                    {record.location != null && record.location.name != null ? record.location.name : ''}
                                </span><br />                            
                            </td>
                        </tr>
                    </tbody>
                </table>             
            </div>
        )
    }else {
        return null;
    }
}

function Footer(props){
    const { record, t, dateFormat, printView } = props;
    if(record != null) {
        return (
            <div style={{ display: 'block', width: '100%', margin: printView ? '0 30px' : null }}>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ verticalAlign: 'top' }} >
                                <span style={{ fontSize: '9pt' }}><strong>{t('reviewedBy')}</strong></span><br /> 
                                <div style={{ width: '50%', display: 'inline-block' }}>
                                    <div style={{ width: '100%' }}>
                                        {record.reviewer != null && record.reviewer.signature != null && <img src={record.reviewer.signature} alt={t('noSignature')} height='60px' />}
                                        <hr style={{ width: '80%', marginLeft: 0 }} />
                                    </div>
                                    <div style={{ width: '100%', fontSize: '9pt' }}>
                                        {record.reviewer != null && record.reviewer.user != null ? record.reviewer.user.firstName + ' ' + record.reviewer.user.lastName : ''}, &nbsp;
                                        {record.reviewer != null && <DateDisplay format={dateFormat} value={record.reviewer.date}/>}
                                    </div>
                                </div>
                            </td>
                            <td style={{ verticalAlign: 'top', textAlign: 'right' }}>
                                <span style={{ fontSize: '9pt' }}><strong>{t('approvedBy')}</strong></span><br /> 
                                <div style={{ width: '50%', display: 'inline-block' }}>
                                    <div style={{ width: '100%' }}>
                                        {record.signer != null && record.signer.signature != null && <img src={record.signer.signature} alt={t('noSignature')} height='60px' />}
                                        <hr style={{ width: '80%', marginRight: 0}} />
                                    </div>
                                    <div style={{ width: '100%', fontSize: '9pt' }}>
                                        {record.signer != null && record.signer.user != null ? record.signer.user.firstName + ' ' + record.signer.user.lastName : ''}, &nbsp;
                                        {record.signer != null && <DateDisplay format={dateFormat} value={record.signer.date}/>}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    } else {
        return null;
    }    
}

function ComponentToPrint(props) {
    const { dateFormat, records, print, t } = props;
    return(
        <div style={{ width: '8.5in' }}>
            {records != null && records.map((data, i) =>
                <Aux>
                {!print && 
                    <Header record={data} t={t} printView={true}/>
                }
                <br />
                <span style={{ color: '#757575', fontSize: '9pt' }}><strong>{t('deviations')}</strong></span><br />
                <table style={{ borderCollapse: 'collapse', width: '100%' }} >
                    <thead>
                        <th style={{ fontSize: '9pt', padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('recordNumber')}</th>
                        <th style={{ fontSize: '9pt', padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('occurred')}</th>
                        <th style={{ fontSize: '9pt', padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('title')}</th>
                        <th style={{ fontSize: '9pt', padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('type')}</th>
                        <th style={{ fontSize: '9pt', padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('category')}</th>
                        <th style={{ fontSize: '9pt', padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('impact')}</th>
                        <th style={{ fontSize: '9pt', padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('status')}</th>
                    </thead>
                    <tbody>
                        {data.deviations.length > 0 ?
                            data.deviations.map((deviation, i) => 
                                <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{deviation.number}</td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}><DateDisplay format={props.dateFormat} value={deviation.occuranceDate}/></td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{deviation.title}</td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{deviation.type != null ? deviation.type.label : ''}</td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{deviation.category != null ? deviation.category.label : ''}</td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{deviation.impact ?? t('notKnown')}</td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>{deviation.status.label}</td>
                                </tr>
                            ) : <tr style={{ border: '1px solid #ddd', fontSize: '8pt', padding: '5px' }} ><td colSpan='7'><center>{t('noDeviations')}</center></td></tr>
                        }
                    </tbody>
                </table>
                <hr style={{ width: '95%' }} />
                <span style={{ color: '#757575', display: 'block', fontSize: '25pt', fontWeight: 'bold' }}>{t('nonCorformances')}</span>
                <table style={{ fontFamily: 'Open Sans', borderCollapse: 'collapse', width: '100%' }} >
                    <thead>
                        <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('recordNumber')}</th>
                        <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('occurred')}</th>
                        <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('title')}</th>
                        <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('status')}</th>
                    </thead>
                    <tbody>
                        {data.ncrs.length > 0 ?
                            data.ncrs.map((ncr, i) => 
                                <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                    <td>{ncr.number}</td>
                                    <td><DateDisplay format={props.dateFormat} value={ncr.occuranceDate}/></td>
                                    <td>{ncr.title}</td>
                                    <td>{ncr.status != null && ncr.status !== '' ? ncr.status.label : 'None'}</td>
                                </tr>
                            ) : <tr style={{ border: '1px solid #ddd', padding: '2px' }} ><td colspan='4'><center>{t('noNcrs')}</center></td></tr>
                        }
                    </tbody>
                </table>
                <hr style={{ width: '95%' }} />
                <span style={{ color: '#757575', display: 'block', fontSize: '25pt', fontWeight: 'bold' }}>{t('batchProductionHistory')}</span>
                {data.activities != null && data.activities.length > 0 && data.activities.map(act => (
                    <fieldset style={{ border: '2px solid #ddd', padding: '0px 15px 15px 15px' }}>
                        <legend><strong>{t('productionOrder')}: </strong>{act.productionOrder != null && act.productionOrder.description}</legend>
                        <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('completed')} </strong><DateDisplay format={props.dateFormat} value={act.completedDate}/></span><span style={{ display: 'inline-block', marginLeft: '40pt' }} />
                        <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('item')} </strong>{act.outputItem.name}</span><span style={{ display: 'inline-block', marginLeft: '40pt' }} />
                        <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('quantity')} </strong>{act.outputQuantity + act.outputUOM.label}</span><span style={{ display: 'inline-block', marginLeft: '40pt' }} />
                        <br/>
                        <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('completedBy')} </strong>{act.productionOrder != null && act.productionOrder.user != null ? act.productionOrder.user.user.firstName.charAt(0) + ' ' + act.productionOrder.user.user.lastName : ''}</span><span style={{ display: 'inline-block', marginLeft: '40pt' }} />
                        <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('reviewedBy')} </strong>{act.productionOrder != null && act.productionOrder.reviewer != null ? act.productionOrder.reviewer.user.firstName.charAt(0) + ' ' + act.productionOrder.reviewer.user.lastName : ''}</span><span style={{ display: 'inline-block', marginLeft: '40pt' }} />
                        <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('approvedBy')} </strong>{act.productionOrder != null && act.productionOrder.signer != null ? act.productionOrder.signer.user.firstName.charAt(0) + ' ' + act.productionOrder.signer.user.lastName : ''}</span><br />
                        <hr style={{ width: '95%' }}/>
                        {act.productionOrder != null && act.productionOrder.inputs.length > 0 &&
                            <div style={{width: '100%', display: 'inline-block'}}>
                                <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('inputs')}</strong></span><br />
                                <table style={{ fontFamily: 'Open Sans', borderCollapse: 'collapse', width: '100%' }} >
                                    <thead>
                                        <th style={{ padding: '2px', textAlign: 'left', border: '2px solid rgb(187, 186, 186)' }} >{t('description')}</th>
                                        <th style={{ padding: '2px', textAlign: 'left', border: '2px solid rgb(187, 186, 186)', width: '115px' }} >{t('lot')}</th>
                                        <th style={{ padding: '2px', textAlign: 'right', border: '2px solid rgb(187, 186, 186)', width: '85px' }} >{t('amount')}</th>
                                    </thead>
                                    <tbody>
                                        {act.productionOrder.inputs.map(input => (
                                            <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                                <td style={{ padding: '5px', fontSize: '9.5pt', border: '1px solid rgb(187, 186, 186)' }}>{input.item.name}</td>
                                                <td style={{ padding: '5px', fontSize: '9.5pt', border: '1px solid rgb(187, 186, 186)' }}>{input.lot != null && input.lot !== '' ? input.lot.number : t('none')}</td>
                                                <td style={{ padding: '5px', fontSize: '9.5pt', border: '1px solid rgb(187, 186, 186)', textAlign: 'right' }}>{input.amount + ' ' + input.uom.label}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                        {act.productionOrder != null && act.productionOrder.outputs.length > 0 &&
                            <div style={{width: '100%', display: 'inline-block', }}>
                                <span style={{ fontSize: '12pt', fontWeight: 'medium' }}><strong>{t('outputs')}</strong></span><br />
                                <table style={{ fontFamily: 'Open Sans', borderCollapse: 'collapse', width: '100%' }} >
                                    <thead>
                                        <th style={{ padding: '2px', textAlign: 'left', border: '2px solid rgb(187, 186, 186)', width: '85px' }} >{t('type')}</th>
                                        <th style={{ padding: '2px', textAlign: 'left', border: '2px solid rgb(187, 186, 186)' }} >{t('description')}</th>
                                        <th style={{ padding: '2px', textAlign: 'left', border: '2px solid rgb(187, 186, 186)', width: '115px' }} >{t('lot')}</th>
                                        <th style={{ padding: '2px', textAlign: 'right', border: '2px solid rgb(187, 186, 186)', width: '85px' }} >{t('amount')}</th>
                                    </thead>
                                    <tbody>
                                        {act.productionOrder.outputs.map(output => (
                                            <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                                <td style={{ padding: '5px', fontSize: '9.5pt', border: '1px solid rgb(187, 186, 186)' }}>
                                                    { output.containerType != null ? 
                                                      output.containerType : '' }
                                                </td>
                                                <td style={{ padding: '5px', fontSize: '9.5pt', border: '1px solid rgb(187, 186, 186)' }}>{output.item.name}</td>
                                                <td style={{ padding: '5px', fontSize: '9.5pt', border: '1px solid rgb(187, 186, 186)' }}>{output.lot != null && output.lot !== '' ? output.lot.number : t('none')}</td>
                                                <td style={{ padding: '5px', fontSize: '9.5pt', border: '1px solid rgb(187, 186, 186)', textAlign: 'right' }}>{output.amount + ' ' + output.uom.label}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </fieldset>
                ))}
                <hr style={{ width: '95%' }} />
                <span style={{ color: '#757575', display: 'block', fontSize: '25pt', fontWeight: 'bold' }}>{t('cleaningRecords')}</span>
                <table style={{ fontFamily: 'Open Sans', borderCollapse: 'collapse', width: '100%' }} >
                    <thead>
                        <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('number')}</th>
                        <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('completed')}</th>
                        <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('type')}</th>
                        <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('room')}</th>
                        <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('status')}</th>
                    </thead>
                    <tbody>
                        {data.roomCleanings != null && data.roomCleanings.length > 0 ?
                            data.roomCleanings.map((cleaning, i) =>
                                <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                    <td>{cleaning.number}</td>
                                    <td>{cleaning.date != null && props.dateFormat != null && <DateDisplay format={props.dateFormat} value={cleaning.date}/>}</td>
                                    <td>{cleaning.type.label}</td>
                                    <td>{cleaning.room.name}</td>
                                    <td>{cleaning.status.label}</td>
                                </tr>
                            ) : <tr style={{ border: '1px solid #ddd', padding: '2px' }} ><td colspan='5'><center>{t('noCleaningRecords')}</center></td></tr>
                        }
                    </tbody>
                </table>
                <hr style={{ width: '95%' }} />
                <br/>
                <br/>             
                {!print &&
                    <Footer record={data} t={t} printView={true} dateFormat={dateFormat} />
                }
                </Aux>
            )}
        </div>
    )
}

class ProductionBatchPrint extends Component {
    constructor(props){
        super(props);
        this.state = {
            statuses: [],
            records: [],
            cultRecords: []
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.productionBatchStatus], this.props.auth.constants, null, this.props.auth.user.tenant);
        const result = await axios.get('/api/productionbatchrecord/' + this.props.match.params.id);
        let recs = result.data.records;
        let cultRecords = result.data.cultRecords;
        if(cultRecords != null && cultRecords.length > 0){
            for(let row of cultRecords){
                row.dateFormat = this.props.auth.user.dateFormat;
                let qrPath = window.location.origin + '/#/evorabatch/' + row.id;
                row.qrPath = qrPath;
            }
        }
        for(let row of recs){
            let qrPath = window.location.origin + '/#/productionbatch/' + row._id;
            row.qrPath = qrPath;
        }
        
        this.setState({
            statuses: cacheValues.productionBatchStatuses,
            records: recs,
            cultRecords: cultRecords
        });
    }

    generateMarkup = () => {
        var htmlData = ReactDOMServer.renderToStaticMarkup(<ComponentToPrint records={this.state.records} constants={this.props.auth.constants} print t={this.props.t} dateFormat={this.props.auth.user.dateFormat}/>);
        return htmlData;
    }
    generateHeaderFooter = () => {
        let header = ReactDOMServer.renderToStaticMarkup(<Header record={this.state.records} t={this.props.t} dateFormat={this.props.auth.user.dateFormat} printView={true}/>);
        let footer = ReactDOMServer.renderToStaticMarkup(<Footer record={this.state.records} t={this.props.t} dateFormat={this.props.auth.user.dateFormat} printView={true} />);
        return { header, footer };
    }

    printSettings = () => {
        const header = this.generateHeaderFooter();
        let data = {
            margin: {
                top: '96px',
                bottom: '250px',
                left: '30px',
                right: '30px'
            },
            landscape: false,
            header
        }
        return {data};
    }

    render(){
        const t = this.props.t;
        return(
            <div style={{ width: '80%'}}>
                <BreadcrumbBar />
                <div className="row">
                    <GeneratePdf markup={this.generateMarkup} id={this.props.match.params.id} objectType={this.props.auth.constants.objectIds.productionBatch}
                                 printSettingSel={this.printSettings()} />
                </div>
                <br />
                <br />
                <div style={{ width: '9in'}}>
                    <Panel>
                        <ComponentToPrint records={this.state.records} constants={this.props.auth.constants} print={false} t={t} dateFormat={this.props.auth.user.dateFormat}/>
                        {this.state.cultRecords != null && this.state.cultRecords.length > 0 && this.state.cultRecords.map((row, i) => 
                            <EvoraBatchPrintMarkup data={row} constants={this.props.auth.constants} t={t} print={false}/>
                        )}
                    </Panel>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default withTranslation() (connect(mapStateToProps)(ProductionBatchPrint));