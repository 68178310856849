import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

const persistConfig = {
        key: 'evora-session-storage',
        storage: storageSession,
        blacklist: ['activity', 'alert', 'errors', 'message', 'notification', 'sop'],
        whitelist: ['auth', 'help', 'navMenu', 'security', 'profile', 'devices']
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = composeEnhancer(applyMiddleware(thunk));
const store = createStore(persistedReducer, middleware);
let persistor = persistStore(store);
export { persistor, store };