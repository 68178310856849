import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import TextField from '../UI/TextField/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import isEmpty from '../../is-empty';
import ModalDialog from '../UI/Dialog/ModalDialog';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import { encryptString } from '../../helpers/securityHelper';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';

function UnlockDialog(props){
    const [pin, setPin] = useState(''); 
    const [reasons, setReasons] = useState([]);
    const [reasonSel, setReasonSel] = useState();
    const [comment, setComment] = useState('');
    const [errors, setErr] = useState({});
    const [enableSave, setEnableSave] = useState(true);
    const { t } = useTranslation('translation');

    useEffect(() => {
        async function loadData() {
            const cacheValues = await ValueHelper.getPermCachedValues([common.cacheValues.editReason], props.auth.user.tenant);
            setReasons(cacheValues.editReasons);
        }
        if(props.show)
            loadData();
    }, [props])

    const unlockClick = (e) => {
        if(!enableSave)
            return;
        setEnableSave(false);
        _unlockClick();
    }

    const _unlockClick = async (e) => {
        let valid = validate();
        if(valid){
            try {
                let pinEnc = encryptString(pin);
                let pinResult = await axios.post('/api/userpin', {userid: props.auth.user.id, pin: pinEnc});
                if(pinResult.data.signature != null && !pinResult.data.expired){
                    let data = {
                        objectType: props.objectType,
                        id: props.id,
                        reason: reasonSel.value,
                        comment: comment
                    }
                    let result = await axios.post('/api/unlock', data);
                    props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                    setEnableSave(true);
                    props.toggle();
                    props.callback();
                    
                } else {
                    setEnableSave(true);
                    let err = {pin: t('pinInvalid')};
                    setErr(err)
                }
            } catch(err){
                setEnableSave(true);
                props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            setEnableSave(true);
        }
    };

    const validate = () => {
        let err = {};
        if(pin == null || pin === '')
            err.pin = t('required');
        if(reasonSel == null){
            err.reason = t('required');
        }
        if (comment == null || comment === ''){
            err.comment = t('required');
        }
        setErr(err);
        return isEmpty(err);
    }

    const changeAuto = (name) => (e, newValue) => {
        setReasonSel(newValue)
    };

    const buttonStack = () => {
        const buttons = [
            <Button variant="text" color="secondary" onClick={props.toggle}>{t('cancel')}</Button>,
            <Button variant="contained" color="primary" onClick={unlockClick}>{t('confirm')}</Button>
        ];
        return buttons;
    }

    return(
        <ModalDialog buttonStack={buttonStack()} toggleDialog={props.toggle} title={props.recordName != null ? t('unlock') + ' ' + props.recordName : t('unlock')}
                     dialogStatus={props.show != null ? props.show : false} fullWidth maxWidth="sm" >
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <AutoCompleteField
                        value={reasonSel}
                        options={reasons}
                        onChange={changeAuto('reasonSel')}
                        error={errors.reason != null ? true : false}
                        helperText={errors.reason}
                        label={t('reason')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField value={comment} onChange={e => setComment(e.target.value)} name="comment" multiline={true} rows="3"
                    variant="outlined" size="medium" fullWidth label={t('comment')}
                    error={errors.comment != null ? true : false} helperText={errors.comment}/>
                </Grid>
                <Grid item xs={8}>
                    <FormControl>
                        <FormLabel>{t('user')}</FormLabel>
                        {props.auth.user.firstName + ' ' + props.auth.user.lastName}
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <TextField value={pin} onChange={e => setPin(e.target.value)} name="pin"
                    size="medium" fullWidth disabled={false} label={t('pin')} type={'password'}
                    error={errors.pin != null ? true : false} helperText={errors.pin}/>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 0.75 }}>
                <Grid item>
                    <Typography variant="body2" color="textSecondary">
                        {t('unlockConsent')}
                    </Typography>
                </Grid>
            </Grid>
        </ModalDialog>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(UnlockDialog);

//export default connect(UnlockDialog);