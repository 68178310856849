import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

export default function TenantLicensesTable(props) {
    const { t, tenant, type, types } = props;
    const [licenses, setLicenses] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getLicenses = async () => {
            const data = {tenant: tenant};
            let result = await axios.get('/api/tenantlicenses', {params: data});
            setLicenses(result.data);
            setLoading(false);
        }
        if(tenant != null && tenant !== ''){
            setLoading(true);
            getLicenses();
        }            
    }, [tenant]);

    if(!loading) {
        return (
            <TableContainer component={Paper}>
                <Table aria-label="licenses" size="small">
                    {types != null && types.legacy === type ? 
                        <TableBody>
                            <TableRow key="activeLicenses">
                                <TableCell colSpan={2}>{t('licenses')}</TableCell>
                            </TableRow>
                            <TableRow key="advancedLicenses">
                                <TableCell>{t('advanced')}</TableCell>
                                <TableCell>{licenses.advanced + ' (' + licenses.purchasedAdv + ')'}</TableCell>
                            </TableRow>
                            <TableRow key='fullLicenses'>
                                <TableCell>{t('full')}</TableCell>
                                <TableCell>{licenses.full + ' (' + licenses.purchasedFull + ')'}</TableCell>
                            </TableRow>
                            <TableRow key="basicLicenses">
                                <TableCell>{t('basic')}</TableCell>
                                <TableCell>{licenses.basic + ' (' + licenses.purchasedBasic + ')'}</TableCell>
                            </TableRow>
                            <TableRow key='totalLicenses'>
                                <TableCell>{t('totalLicenses')}</TableCell>
                                <TableCell>{licenses.totalCount - licenses.inactive + ' (' + (licenses.purchasedAdv + licenses.purchasedFull + licenses.purchasedBasic) + ')'}</TableCell>
                            </TableRow>
                        </TableBody>
                    :
                        <TableBody>
                            <TableRow key ='licenseHeading'>
                                <TableCell colSpan={2}>{t('licenses')}</TableCell>
                            </TableRow>
                            <TableRow key="purchasedLicenses">
                                <TableCell>{t('purchased')}</TableCell>
                                <TableCell>{licenses != null && licenses.purchasedLicenses != null ? licenses.purchasedLicenses : 0}</TableCell>
                            </TableRow>
                            <TableRow key='usedLicenses'>
                                <TableCell>{t('used')}</TableCell>
                                <TableCell>{licenses != null && licenses.activeLicenses != null ? licenses.activeLicenses : 0}</TableCell>
                            </TableRow>
                            <TableRow key="availableLicenses">
                                <TableCell>{t('available')}</TableCell>
                                <TableCell>{licenses != null && licenses.availableLicenses != null ? licenses.availableLicenses : 0}</TableCell>
                            </TableRow>                        
                        </TableBody>
                    }
                </Table>
            </TableContainer>
        );
    }else{
        return <Skeleton variant='rounded' height={125} width={325}/>;
    }
    
}