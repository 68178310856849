import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Aux from '../../hoc/Auxilary/Auxilary';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import TextField from '../UI/TextField/TextField';
import Grid from '@mui/material/Grid';
import { withTranslation } from 'react-i18next';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class BinSummary extends Component {
    constructor(props){
        super(props);
        this.state = {
            binRows: [],
            open: [],
            locations: [],
            location: '',
            locSel: null,
            bin: '',
            items: [],
            item: '',
            itemSel: null,
            binOpen: []
        }
    }

    async componentDidMount(){
        let storage = sessionStorage.getItem('binSummary');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.allItems, common.cacheValues.location], this.props.auth.constants, null, this.props.auth.user.tenant);
        let items = cacheValues.allItems;
        let locations = cacheValues.locations;
        this.setState({
            locations: locations,
            items: items,
            location: storage != null ? storage.location : '',
            locSel: storage != null ? storage.locSel : null,
            bin: storage != null ? storage.bin : ''
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('binSummary'), screen: this.props.auth.screenDefs.BinSummary}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
        
    }

    loadRecords = async () => {
        let query = {
            location: this.state.location, 
            bin: this.state.bin,
            item: this.state.item
        }
        let result = await axios.get('/api/binsummary', {params: query});
        let open = [];
        let binOpen = [];
        if(result.data.length > 0){
            result.data.forEach(row => {
                binOpen.push(false);
                let items = [];
                row.itemRows.forEach(itemRow => {
                    items.push(false);
                });
                open.push(items);
            });
        }
        this.setState({binRows: result.data, open: open, binOpen: binOpen});
        this.saveFilter();
    };

    saveFilter(){
        let data = {
            location: this.state.location,
            locSel: this.state.locSel,
            bin: this.state.bin
        };
        sessionStorage.setItem('binSummary', JSON.stringify(data));
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value}, () => this.loadRecords());
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            this.loadRecords();
        });
    };

    setOpen(i, j){
        let open = this.state.open;
        open[i][j] = !open[i][j];
        this.setState({open: open});
    }

    setBinOpen(i){
        let binOpen = this.state.binOpen;
        binOpen[i] = !binOpen[i];
        this.setState({binOpen: binOpen})
    }

    render(){
        const open = this.state.open;
        const binOpen = this.state.binOpen;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar />
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.locSel}
                                options={this.state.locations}
                                onChange={this.changeAuto('locSel', 'location')}
                                label={t('location')}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.bin} onChange={this.changeValue} name="bin" label={t('bin')}/>
                        </Grid>
                    </Grid>
                    {this.state.binRows.map((row, i) => 
                        <Aux key={'binRow-' + i}>
                            <br/>
                            <br/>
                            <Grid container spacing={3}>
                                <SectionTitle title={row.bin.description}/>
                                <IconButton aria-label="expand row" size="small" onClick={() => this.setBinOpen(i)}>
                                    {binOpen[i] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </Grid>
                            <Collapse in={binOpen[i]} timeout="auto" unmountOnExit>
                                <br/>
                                <TableContainer component={Paper}>
                                    <Table aria-label={'table' + row.bin.code}>
                                        <TableHead>
                                            <TableRow key={'head' + row.bin.code}>
                                                <TableCell sx={{ width: 50 }}></TableCell>
                                                <TableCell sx={{ width: 500 }}>{t('item')}</TableCell>
                                                <TableCell>{t('amount')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {row.itemRows.map((itemRow, j) => 
                                                <Aux key={i +'-itemRow-' + itemRow.item._id + '-' + j}>
                                                    <TableRow key={row.bin.code + itemRow.item._id}>
                                                        <TableCell sx={{ width: 50 }}>
                                                            <IconButton aria-label="expand row" size="small" onClick={() => this.setOpen(i, j)}>
                                                                {open[i][j] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell sx={{ width: 500 }}>{itemRow.item.number + '-' + itemRow.item.name}</TableCell>
                                                        <TableCell><NumericFormat value={itemRow.total} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                            {itemRow.item.uom.label}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                            <Collapse in={open[i][j]} timeout="auto" unmountOnExit>
                                                                <Box margin={1}>
                                                                    <SubSectionHeading title="Containers"/>
                                                                    <Table size="small" aria-label={"containers" + i + j}>
                                                                        <TableHead>
                                                                            <TableRow key={"contTable" + i + j}>
                                                                                <TableCell>{t('number')}</TableCell>
                                                                                <TableCell>{t('lot')}</TableCell>
                                                                                <TableCell>{t('amount')}({itemRow.item.uom.label})</TableCell>
                                                                                <TableCell>{t('type')}</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {itemRow.containers.map((cont, i) =>
                                                                                <TableRow key={cont._id}>
                                                                                    <TableCell><Link to={'/container/' + cont._id}>{cont.number}</Link></TableCell>
                                                                                    <TableCell><Link to={'/lot/' + cont.lot._id}>{cont.lot.number}</Link></TableCell>
                                                                                    <TableCell>
                                                                                        <NumericFormat value={cont.quantity} displayType={'text'} 
                                                                                        thousandSeparator={true} decimalScale={3}/>
                                                                                    </TableCell>
                                                                                    <TableCell>{cont.type != null ? cont.type.label : ''}</TableCell>
                                                                                </TableRow>
                                                                            )}
                                                                        </TableBody>
                                                                    </Table>
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </Aux>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Collapse>
                        </Aux>
                    )}
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(BinSummary));