import { db } from '../db';

const listHelper = {

    async setItem(name, tenant, value){
        let key = name + tenant;
        let record = await db.lists.where({id: key}).first();
        if(record == null){
            try {
                await db.lists.add({id: key, data: value});
            } catch(err){
                console.error('add ' + key);
                console.error(err);
            }
        } else {
            try {
                await db.lists.update(key, {data: value});
            } catch(err){
                console.error('update ' + name);
                console.error(err);
            }
        }
    },
    
    async getItem(name, tenant){
        let key = name + tenant;
        let value = await db.lists.where({id: key}).first();
        return value != null ? value.data : null;
    }, 

    async removeItem(name, tenant){
        let key = name + tenant;
        await db.lists.where({id: key}).delete();
    },

    async clearList(){
        await db.lists.clear();
    }
}

export default listHelper;