import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Aux from '../../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import SectionTitle from '../../UI/Typography/SectionTitle';
import DateSelect from '../../UI/DateSelect/DateSelect';
import Grid from '@mui/material/Grid';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";

const ItemNeobiSales = (props) => {
    const { t } = useTranslation('translation');
    const baseData = props.data;
    const [chartData, setChartData] = useState([]);
    const [values, setValues] = useState({
        startDate: null,
        endDate: new Date()
    });

    useEffect(() => {
        if (baseData != null && baseData.length > 0) {
            let sorted = baseData.sort((a, b) => {
                if(a.ccc < b.ccc) { return 1; }
                else return -1;
            });
            let data = [];
            let currStor = null;
            let currName = null;
            let saleCount = 0;
            let start = values.startDate != null ? new Date(values.startDate) : null;
            let end = values.endDate != null ? new Date(values.endDate) : null;
            for(let row of sorted) {
                if(start != null && new Date(row.dateMax).getTime() < start.getTime()){
                    continue;
                }
                if(end != null && new Date(row.dateMax).getTime() > end.getTime()){
                    continue;
                }
                if(currStor !== row.ccc){
                    if(currStor !== null && saleCount > 0){
                        data.push({x: currName, y: saleCount});
                    }
                    currStor = row.ccc;
                    currName = row.storeAlias;
                    saleCount = 0;
                } 
                if(row.closingQty < row.closingQtyLastWeek){
                    saleCount += row.closingQtyLastWeek - row.closingQty;
                }
            }
            if(currStor !== null){
                data.push({x: currName, 'y': saleCount});
            }
            setChartData(data);
        }
    }, [baseData, chartData, t, values]);

    const dateChange = (name) => (e) => {
        setValues({...values, [name]: e});
    };

    return(
        <Aux>
            <SectionTitle title={t('storeSales')}/>
            {chartData != null && chartData.length > 0  &&
                <Aux>
                    <Grid container spacing={2}>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                className="form-control"
                                name="startDate"
                                onChange={dateChange('startDate')}
                                value={values.startDate}
                                label={t('startDate')}
                                format={props.auth.user.dateFormat}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                className="form-control"
                                id="endDate"
                                onChange={dateChange('endDate')}
                                value={values.endDate}
                                label={t('endDate')}
                                format={props.auth.user.dateFormat}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    width={1000}
                                    height={300}
                                    data={chartData}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="x" />
                                    <YAxis />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="y" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Grid>
                    </Grid>
                </Aux>
            }
        </Aux>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(ItemNeobiSales);