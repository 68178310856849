import React, { useState, useEffect, useCallback } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ReferenceLine,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { zonedTimeToUtc } from 'date-fns-tz';
import axios from 'axios';
import ConversionHelper from '../../helpers/conversionHelper';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DateSelect from '../UI/DateSelect/DateSelect';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Aux from '../../hoc/Auxilary/Auxilary';
import SectionTitle from '../UI/Typography/SectionTitle';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';

const BatchCostPerGram = (props) => {
    const [values, setValues] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 320)),
        endDate: new Date(),
        strain: '',
        strainSel: null
    });
    const [strains, setStrains] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [expected, setExpected] = useState(0);

    const { t } = useTranslation('translation');

    const loadRecords = useCallback(async () => {
        const data = {
            strain: values.strain,
            startDate: zonedTimeToUtc(ConversionHelper.minDate(values.startDate)),
            endDate: zonedTimeToUtc(ConversionHelper.maxDate(values.endDate)),
        };
        let result = await axios.get('/api/batchcpg', { params: data });
        setChartData(result.data.data[0].data);
        setExpected(result.data.expectedValues[0]);
    }, [values]);

    useEffect(() => {
        const getData = async () => {
            let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.strain], props.auth.constants, null, props.auth.user.tenant);
            setStrains(cacheValues.strains);
        }
        getData();
    }, [props, values]);

    useEffect(() => {
        loadRecords();
    }, [values, loadRecords]);

    const changeAuto = (name, data) => (e, newValue) => {
        setValues(
            {...values,
                [name]: newValue,
                [data]: newValue != null ? newValue.value : ''
            }, () => {loadRecords()});
    };

    const dateChange = (name) => (e) => {
        setValues({...values, [name]: e}, () => {loadRecords()});
    };

    return (
        <Aux>
            <SectionTitle title={t('costPerGram')}/>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <AutoCompleteField
                        value={values.strainSel}
                        options={strains}
                        onChange={changeAuto('strainSel', 'strain')}
                        label={t('strain')}
                    />
                </Grid>
                <Grid item xs={4} sm={2}>
                    <DateSelect
                        className="form-control"
                        name="startDate"
                        onChange={dateChange('startDate')}
                        value={values.startDate}
                        label={t('startDate')}
                        format={props.auth.user.dateFormat}
                    />
                </Grid>
                <Grid item xs={4} sm={2}>
                    <DateSelect
                        className="form-control"
                        id="endDate"
                        onChange={dateChange('endDate')}
                        value={values.endDate}
                        label={t('endDate')}
                        format={props.auth.user.dateFormat}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                            width={1000}
                            height={300}
                            data={chartData}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="x" />
                            <YAxis />
                            <Tooltip />
                            {expected !== 0 &&
                                <ReferenceLine y={expected} stroke="red" />
                            }
                            <Line type="monotone" dataKey="y" stroke="#8884d8" />
                        </LineChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>
        </Aux>
    );
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(withRouter(BatchCostPerGram));
