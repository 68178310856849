import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import dateFormat from 'date-fns/format';
import AddIcon from '@mui/icons-material/Add';
import ActionBar from '../UI/Buttons/ActionBar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Typography from '@mui/material/Typography';
import ConversionHelper from '../../helpers/conversionHelper';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';
import listHelper from '../../helpers/listHelper';
import ListMultiSelect from '../UI/ListMultiSelect/ListMultiSelect';

const Destructions = (props) => {
    const isFirstRender = useRef(true);
    const [loadRefData, setLoadRefData] = useState(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [columnOrder, setColumnOrder] = useState([]);
    const [values, setValues] = useState({locations: [], users: [], statuses: [], methods: []});
    const [contentPage, setContentPage] = useState(0);
    const [contentListSize, setContentListSize] = useState(5);
    const { t } = useTranslation('translation');
    const tenant = props.auth.user.tenant;
    const [statusesSel, setStatusesSel] = useState({ids: [], values: []});
    const [showSelect, setShowSelect] = useState(false);
    const crumbs = [
        { path: 'active', label: t('destructions'), screen: props.auth.screenDefs.DestructionList}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        async function fetchData(){
            const columnFilters = await listHelper.getItem('DestructionsColumnFilters', tenant);
            const columnVisibility = await listHelper.getItem('DestructionsColumnVisibility', tenant);
            const density = await listHelper.getItem('DestructionsDensity', tenant);
            const globalFilter = await listHelper.getItem('DestructionsGlobalFilter', tenant);
            const showGlobalFilter = await listHelper.getItem('DestructionsShowGlobalFilter', tenant);
            const showColumnFilters = await listHelper.getItem('DestructionsShowColumnFilters', tenant);
            const sorting = await listHelper.getItem('DestructionsSorting', tenant);
            const pagination = await listHelper.getItem('DestructionsPagination', tenant);
            const tempColumnOrder = await listHelper.getItem('DestructionsColumnOrder', tenant);
            if (columnFilters) {
                let filters = ConversionHelper.filtersDateParse(JSON.parse(columnFilters));
                setColumnFilters(filters);
                let status = filters.find(x => x.id === 'status');
                if(status != null){
                    let sel = {ids: status.value, values: []};
                    status.value.forEach(id => {
                        let val = values.statuses.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setStatusesSel(sel);
                }
            }
            if (columnVisibility) {
                setColumnVisibility(columnVisibility);
            }
            if (density) {
                setDensity(density);
            }
            if (globalFilter) {
                setGlobalFilter(globalFilter);
            }
            if (showGlobalFilter) {
                setShowGlobalFilter(showGlobalFilter);
            }
            if (showColumnFilters) {
                setShowColumnFilters(showColumnFilters);
            }
            if (sorting) {
                setSorting(sorting);
            }
            if (pagination) {
                setPagination(pagination);
            }
            if (tempColumnOrder) {
                setColumnOrder(tempColumnOrder);
            } else {
                setColumnOrder(['mrt-row-expand', 'number', 'status', 'date', 'location', 'method', 'containers', 'netQuantity', 'originalQuantity', 'count', 'user']);
            }
            isFirstRender.current = false;
        }
        if(isFirstRender.current){
            fetchData();
        }
    }, [values, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('DestructionsColumnFilters', tenant, JSON.stringify(columnFilters));
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('DestructionsColumnVisibility', tenant, columnVisibility);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnVisibility, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('DestructionsDensity', tenant, density);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [density, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('DestructionsGlobalFilter', tenant, globalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [globalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('DestructionsShowGlobalFilter', tenant, showGlobalFilter);
        }
        if (isFirstRender.current) return;
        fetchData()
    }, [showGlobalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('DestructionsShowColumnFilters', tenant, showColumnFilters);
        }
        if (isFirstRender.current) return;
        fetchData();

    }, [showColumnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('DestructionsSorting', tenant, sorting);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [sorting, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('DestructionsPagination', tenant, pagination);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [pagination, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('DestructionsColumnOrder', tenant, columnOrder);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnOrder, tenant]);

    const resetState = () => {
        listHelper.removeItem('DestructionsColumnFilters', tenant);
        listHelper.removeItem('DestructionsColumnVisibility', tenant);
        listHelper.removeItem('DestructionsDensity', tenant);
        listHelper.removeItem('DestructionsGlobalFilter', tenant);
        listHelper.removeItem('DestructionsShowGlobalFilter', tenant);
        listHelper.removeItem('DestructionsShowColumnFilters', tenant);
        listHelper.removeItem('DestructionsSorting', tenant);
        listHelper.removeItem('DestructionsPagination', tenant);
        listHelper.removeItem('DestructionsColumnOrder', tenant);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.activeLocation, common.cacheValues.users, common.cacheValues.destructionMethod], 
                props.auth.constants, null, props.auth.user.tenant);
            const statuses = await ValueHelper.getStatusesForObject(props.auth.constants.objectIds.destruction, props.auth.user.tenant);
            setValues({
                users: cacheValues.users,
                locations: cacheValues.locations,
                statuses: statuses,
                methods: cacheValues.destructionMethods
            });
            let status = columnFilters != null ? columnFilters.find(x => x.id === 'status') : null;
            if(status != null){
                let sel = {ids: status.value, values: []};
                status.value.forEach(id => {
                    let val = statuses.find(x => x.value === id);
                    sel.values.push(val);
                });
                setStatusesSel(sel);
            }
            setLoadRefData(false);
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, columnFilters, loadRefData]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'Destructions',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/destructions', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });

    let columns = useMemo(() => [
        {
            accessorKey: 'number',
            id: 'number',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/destruction/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            )
        },
        {
            accessorKey: 'status',
            id: 'status',
            header: t('status'),
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='status'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.statuses} 
                    setSelected={setStatusesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={statusesSel.ids} 
                    selectedSel={statusesSel.values}
                    columnFilters={columnFilters}
                    screen='Destructions'
                    tenant={props.auth.user.tenant}/>
                </Box>
            ),
        },
        {
            accessorKey: 'date',
            id: 'date',
            header: t('date'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() != null ? dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat) : ''}
                </Box>
            )
        },
        {
            accessorKey: 'location',
            id: 'location',
            header: t('location'),
            filterVariant: 'select',
            filterSelectOptions: values.locations
        },
        {
            accessorKey: 'method',
            id: 'method',
            header: t('method'),
            filterVariant: 'select',
            filterSelectOptions: values.methods
        },
        {
            accessorKey: 'containers.length',
            id: 'containers',
            header: t('containers'),
            enableColumnFilter: false,
            enableSorting: false
        },
        {
            accessorFn: (row) => row.totalNetWeight.toString() + (row.weightUOM != null ? row.weightUOM : 'g'),
            id: 'netQuantity',
            header: t('netQuantity'),
            enableColumnFilter: false,
            enableSorting: false
        },
        {
            accessorFn: (row) => row.totalOriginalWeight.toString() + (row.weightUOM != null ? row.weightUOM : 'g'),
            id: 'originalQuantity',
            header: t('originalQuantity'),
            enableColumnFilter: false,
            enableSorting: false
        },
        {
            accessorFn: (row) => row.totalCount > 0 && row.totalCount.toString() + ' ' + (row.countUOM != null ? row.countUOM : 'ea'),
            id: 'count',
            header: t('count'),
            enableColumnFilter: false,
            enableSorting: false
        },
        {
            accessorFn: (row) => row.user != null && row.user.user != null ? row.user.user.firstName + ' ' + row.user.user.lastName: '',
            id: 'user',
            header: t('user'),
            filterVariant: 'select',
            filterSelectOptions: values.users
        }
    ], [values, t, props, statusesSel, columnFilters, showSelect]);

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true,  columnVisibility: {id: false}},
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: false,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()} color="primary">
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('clearListSettings')}>
                    <IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                {props.permission.create &&
                    <Tooltip arrow title={t('add')}>
                        <IconButton component={Link} to={'/destruction'} color="primary">
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        ),
        renderDetailPanel: ({ row }) => (
            <Box margin={1} component={'div'}>
                <Typography variant="h6" gutterBottom component="div">
                    {t('containers')}
                </Typography>
                <TableContainer component={Paper}>
                    <Table aria-label={row.original?._id + 'containers'}>
                        <TableHead >
                            <TableRow key={row.original?._id + 'containers'}>
                                <TableCell>{t('item')}</TableCell>
                                <TableCell>{t('containerID')}</TableCell>
                                <TableCell align='right'>{t('netQuantity')}</TableCell>
                                <TableCell align='right'>{t('originalQuantity')}</TableCell>
                                <TableCell align='right'>{t('count')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {row.original?.containers
                                            .slice( contentPage * contentListSize,
                                                    contentPage * contentListSize + contentListSize )
                                            .map((cont, k) =>
                                <TableRow key={cont._id}>
                                    <TableCell>
                                        {cont.item != null &&
                                            <Link to={'/item/' + cont.item._id}>{cont.item.name != null ? cont.item.name : ''}</Link>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {cont.container != null &&
                                            <Link to={'/container/' + cont.container._id}>{cont.container != null ? cont.container.number: ''}</Link>
                                        }
                                    </TableCell>
                                    <TableCell align='right'>{cont.netWeight != null ? cont.netWeight.toString() + (row.original?.weightUOM != null ? row.original?.weightUOM : 'g') : ''}</TableCell>
                                    <TableCell align='right'>{cont.originalAmount != null ? cont.originalAmount.toString() + (row.original?.weightUOM != null ? row.original?.weightUOM : 'g'): ''}</TableCell>
                                    <TableCell align='right'>{cont.count != null ? cont.count.toString() +' '+ (row.original?.countUOM != null ? row.original?.countUOM : 'ea') : ''}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key={"destructionContentsFooter-" + row.original?._id}>
                                <TablePagination count={row.original?.containers.length}
                                rowsPerPage={contentListSize}
                                onPageChange={handleContentChangePage}
                                page = {contentPage}
                                rowsPerPageOptions={props.auth.common.rowOptions}
                                onRowsPerPageChange={handleContentRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
          ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });

    const handleContentRowsPerPage = e => {
        setContentListSize(parseInt(e.target.value));
        refetch();
    };

    const handleContentChangePage = (event, newPage) => {
        setContentPage(newPage);
    };

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs}>
                <ActionBar/>
            </BreadcrumbBar>
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
        </Aux>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Destructions);