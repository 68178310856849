import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ReturnIcon from '@mui/icons-material/KeyboardReturnRounded';
import NavigateNextIcon from '@mui/icons-material/NavigateNextRounded';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/HomeRounded';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

function HideOnScroll(props) {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  );
}

const BreadcrumbBar = (props) => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const crumbList = useSelector(state => state.navMenu.crumbs);
  const drawerOpen = useSelector(state => state.navMenu.drawerOpen);
  const drawerWidth = useSelector(state => state.navMenu.drawerWidth);
  const { goBack } = props;

  const appBar = {
    paddingLeft: '65px', // keep right padding when drawer closed
    backgroundColor: theme.palette.grey[300],
    marginTop: '63px',
    //zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };
  const appBarShift = {
    padding: 0,
    backgroundColor: theme.palette.grey[300],
    marginTop: '63px',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen, }
      ),
  }};

  const authToolbar = (
    <React.Fragment>      
      <HideOnScroll {...props}>
        <AppBar 
          position="fixed"
          sx={drawerOpen ? appBarShift : appBar}
          >
          <Toolbar>
            {location.pathname !== '/home' && 
              <React.Fragment>
                  <Button color="primary" startIcon={<ReturnIcon color="primary" />} data-cy="globalReturnButton"
                          onClick={goBack != null ? () => history.go(goBack) : () => history.goBack()} sx={{ mr: 2.5 }} >
                      {t('return')}
                  </Button>
                  <Divider orientation='vertical' flexItem={true} variant='middle' />          
              </React.Fragment>
            }
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} maxItems={3} aria-label="breadcrumb" sx={{ ml: 2.5, mr: 'auto' }}>
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/#/home"
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {t('home')}
                </Link>
                {crumbList != null && crumbList.length > 0 && crumbList.map((crumb, index) => {
                    if(crumb.path === 'active') {
                        return (
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                                key={'breadcrumb-' + index}
                            >
                                {crumb.label}
                            </Typography>
                        )
                    }else {
                        return (
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href={crumb.path}
                                key={'breadcrumb-' + index}
                            >
                                {crumb.label}
                            </Link>
                        )
                    }
                })}
            </Breadcrumbs>
            {props.children}
          </Toolbar>
        </AppBar>
      </HideOnScroll>      
    </React.Fragment>    
  );

  return authToolbar;
}

export default BreadcrumbBar;