import React, { useEffect, useState } from 'react';
import ModalDialog from '../UI/Dialog/ModalDialog';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import PrinterIcon from '@mui/icons-material/PrintTwoTone';
import Typography from '@mui/material/Typography';
import SelectField from '../UI/SelectField/SelectField';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import { toggleDeviceDialog } from '../../actions/devices';
import TextField from '../UI/TextField/TextField';
import isEmpty from '../../is-empty';
import { DeviceHelper } from '../../helpers/deviceHelper';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from 'react-redux';

const DEVICETEMPLATE = {
    name: '',
    deviceType: '',
    uid: '',
    connection: '',
    manufacturer: '',
    provider: '',
    version: '',
    quality: '',
    qualitySel: '',
    count: 1,
    pauseCount: 0,
    labels: [],
    status: 'template'
}
const DeviceManager = (props) => {
    const dispatch = useDispatch();
    const hasRegisteredDevices = useSelector(state => state.devices.hasRegisteredDevices);
    const constants = useSelector(state => state.auth.constants);
    const newDevices = useSelector(state => state.devices.new);
    const deviceList = useSelector(state => state.devices.registered);
    const discoDevices = useSelector(state => state.devices.notConnected);
    // const defaultDevice = useSelector(state => state.devices.defaultDevice);
    const openDialog = useSelector(state => state.devices.deviceDialogOpen);
    const [enableSave, setEnableSave] = useState(true);
    const { t } = useTranslation();
    const [values, setValues] = useState({
        labels: [],
        labelJustifications: [],
        labelRotations: [],
        labelPrintQualities: [],
        labelPrintTypes: []
    });
    const [errors, setErrors] = useState({});
    const [selectedDevice, setSelectedDevice] = useState(DEVICETEMPLATE);
 
    useEffect(() => {
        // Do something with new devices..
        async function fetchData() {
            const { 
                labels, labelJustifications, labelRotations,
                labelPrintQualities, labelPrintTypes 
            } = await ValueHelper.getCachedValues([
                    common.cacheValues.labelPrintQuality, common.cacheValues.label, 
                    common.cacheValues.labelPrintType, common.cacheValues.labelJustification,
                    common.cacheValues.labelRotation
            ], props.auth.constants, null, props.auth.user.tenant);

            setValues({
                labels: labels,
                labelRotations: labelRotations,
                labelPrintQualities: labelPrintQualities,
                labelPrintTypes: labelPrintTypes,
                labelJustifications: labelJustifications
            });
        }
        fetchData();
        
        // return () => {
        //     // Cleanup
        // }
    }, [props]);

    const changeBool = (index, type) => {
        let labels = selectedDevice.labels;
        labels[index][type] = !labels[index][type];
        setSelectedDevice({ ...selectedDevice, labels: labels });
    }

    const changeValue = (e) => { 
        setSelectedDevice({ ...selectedDevice, [e.target.name]: e.target.value });
    }

    const changeQuality = (e) => {
        setSelectedDevice({ ...selectedDevice, quality: e.target.value, qualitySel: e });
    }

    const cancelDevice = () => {
        setSelectedDevice(DEVICETEMPLATE);
    }

    const selectDevice = (event, device, status) => {
        let newDevice = {};
        if (device != null) {
            let template = {
                label: '',
                count: 1,
                eachPlant: false,
                labelPreview: false,
                promptForCount: false 
            };
            if((device.labels == null || device.labels.length === 0) &&
                values.labelPrintTypes != null && values.labelPrintTypes.length > 0) {
                let labels = [];
                values.labelPrintTypes.forEach(label => {
                    labels.push({ ...template , type: label.value })
                });
                device.labels = labels;
            }
            newDevice = {
                ...device,
                status: status,
            };
        }        
        setSelectedDevice(device != null ? newDevice : {});
    }

    const selectLabel = (e, type) => {
        let labels = isEmpty(selectedDevice.labels) ? [] : selectedDevice.labels;
        if(e.target.value != null) {
            let index = labels.findIndex(x => x.type === type)
            if (index === -1) {
                labels.push({ type: type, label: e.target.value });
            }else {
                labels[index].label = e.target.value;
            }
        }
        setSelectedDevice({ ...selectedDevice, labels: labels });
    }

    const saveDevice = async () => {
        setEnableSave(false);
        try {
            if (!validation()) {
                setEnableSave(true);
                return;
            }
            const result = await axios.post('/api/device', selectedDevice);
            setSelectedDevice({ ...selectedDevice, _id: result.data._id });
            let devices = await DeviceHelper.getDevices();
            dispatch({ type: 'SET_ZEBRA_PRINTERS', payload: {printers: devices}});
            if(devices.hasRegisteredDevices !== hasRegisteredDevices) 
                dispatch({ type: 'HAS_DEVICES', payload: devices.hasRegisteredDevices });
            dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        }catch(err) {
            dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
        setEnableSave(true);
    }

    const validation = () => {
        let errors = {};
        if (isEmpty(selectedDevice.name)) {
            errors[t('name')] = t('required');
        }
        if (isEmpty(selectedDevice.quality)) {
            errors[t('quality')] = t('required');
        }
        if (isEmpty(selectedDevice.labels)) {
            errors[t('labels')] = t('required');
        }
        setErrors(errors);
        return isEmpty(errors);
    }

    const refreshDevices = async () => {
        setEnableSave(false);
        let devices = await DeviceHelper.getDevices();
        setEnableSave(true);
        dispatch({ type: 'SET_ZEBRA_PRINTERS', payload: {printers: devices}});
        if(devices.hasRegisteredDevices !== hasRegisteredDevices) 
            dispatch({ type: 'HAS_DEVICES', payload: devices.hasRegisteredDevices });
    }

    // const getDeviceStatus = async () => {
    //     setEnableSave(false);
    //     let printer = selectedDevice;
    //     let status = await DeviceHelper.getDeviceStatus(selectedDevice);
        
    //     if(status !== {} && !status.ok) {
    //         printer.deviceStatus = status.statusText;
    //         dispatch({ type: 'CREATE_ALERT', payload: {message: status.statusText, title: status.status + ' ' + t('error'), severity: 'error'}});
    //     }else if(status !== {} && status.ok) {
    //         printer.deviceStatus = 'Online';
    //         dispatch({ type: 'CREATE_ALERT', payload: {message: t('success'), title: t('success'), severity: 'success'}});
    //     }else {
    //         printer.deviceStatus = 'Unknown';
    //         dispatch({ type: 'CREATE_ALERT', payload: {message: t('warning'), title: t('warning'), severity: 'warning'}});
    //     }
    //     dispatch({ type: 'SET_DEVICE_STATUS', payload: {printer}});
    //     setEnableSave(true);
    // }

    // const testPrint = async () => {
    //     setEnableSave(false);
    //     let result = await DeviceHelper.testPrint(selectedDevice);
    //     console.info(result);
    //     dispatch({ type: 'CREATE_ALERT', payload: {message: result != null && result.errors != null ? result.errors : t('success'), title: t('success'), severity: 'success'}});
    //     setEnableSave(true);
    // }

    const buttonStack = () => {
        let buttons = [];
        buttons = [
            <Button variant='text' color='secondary' onClick={() => dispatch(toggleDeviceDialog(!openDialog))} key='closeDeviceDialog' disabled={!enableSave}>{t('close')}</Button>,
            <Button variant='text' color='primary' onClick={refreshDevices} key='refreshDeviceDialog' disabled={!enableSave}>{t('refresh')}</Button>,
        ];
        return buttons;
    }

    const deleteClick = async (event, device) => {
        //TODO:
        // - Confirmation dialog
        try {
            let result = await axios.delete('/api/device/' + device._id);
            if(result.status === 200){
                dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                setEnableSave(false);
                let devices = await DeviceHelper.getDevices();
                setEnableSave(true);
                dispatch({ type: 'SET_ZEBRA_PRINTERS', payload: {printers: devices}});
            }else{
                dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('error'), severity: 'error'}});
            }
        }catch (err) {
            dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data.message, title: t('error'), severity: 'error'}});
        }        
    }

    return (
        <ModalDialog buttonStack={buttonStack()} title={t('deviceManager')} toggleDialog={() => dispatch(toggleDeviceDialog(!openDialog))} 
                    dialogStatus={openDialog} fullWidth maxWidth='md' >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                        {newDevices != null && newDevices.length > 0 && 
                            newDevices.map((device, index) => {
                                return (
                                    <ListItemButton key={'newMoreIcon-' + index} onClick={(e) => selectDevice(e, device, 'new')}
                                                    sx={{ border: '1px grey solid'}}>
                                        <ListItemAvatar>
                                            {device.deviceType === 'printer' ?
                                                <PrinterIcon sx={{ color: (device.uid === selectedDevice.uid && 
                                                                           device.name === selectedDevice.name ? 'secondary.main' : 'success.main'), fontSize: '2.2rem', alignSelf: 'center' }}/> :
                                                null
                                            }
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={device.name}
                                            secondary={device.connection != null && device.connection !== '' ? 
                                                    device.connection + ' - ' + device.uid : device.uid
                                                    }
                                        />
                                    </ListItemButton>                                    
                                )
                            })
                        }
                        {deviceList != null && deviceList.length > 0 &&
                            deviceList.map((device, index) => {
                                return (
                                    <ListItemButton key={'deviceOptions-' + index} onClick={(e) => selectDevice(e, device, 'registered')}
                                                    sx={{ border: '1px grey solid'}}>
                                        <ListItemAvatar>
                                            {device.deviceType === 'printer' ?
                                                <PrinterIcon sx={{color: (device.uid === selectedDevice.uid && device.name === selectedDevice.name ? 'secondary.main' : 'primary.main'), 
                                                                         fontSize: '2.2rem', alignSelf: 'center' }} /> :
                                                null
                                            }
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={device.name}
                                            secondary={device.connection != null && device.connection !== '' ? 
                                                    device.connection + ' - ' + device.uid : device.uid
                                                    }
                                        />
                                        <ListItemSecondaryAction>
                                            <ListItemSecondaryAction>
                                                <IconButton key={'deviceOptions-' + index} onClick={(e) => deleteClick(e, device)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>                                        
                                        </ListItemSecondaryAction>
                                    </ListItemButton>
                                )
                            })
                        }
                        {discoDevices != null && discoDevices.length > 0 && 
                            discoDevices.map((device, index) => {
                                return (
                                    <ListItemButton sx={{ border: '1px grey solid'}}>
                                        <ListItemAvatar>
                                            {device.deviceType === 'printer' ?
                                                <PrinterIcon sx={{ color: 'grey[500]', fontSize: '2.2rem', alignSelf: 'center' }} /> :
                                                null
                                            }
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={device.name}
                                            secondary={device.connection != null && device.connection !== '' ? 
                                                    device.connection + ' - ' + device.uid : device.uid
                                                    }
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton key={'discoDeviceOptions-' + index} onClick={(e) => deleteClick(e, device)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItemButton>
                                )
                            })
                        }
                    </List>
                </Grid> 
                <Grid item xs={6}>
                    <Grid container spacing={2} >
                    {/* deviceType: "printer",
                        uid: "127.0.0.1:9101",
                        provider: "com.zebra.ds.webdriver.desktop.provider.DefaultDeviceProvider",
                        name: "Chrome",
                        connection: "network",
                        version: 4,
                        manufacturer: "Zebra Technologies" */}
                        <Grid item xs={12} sx={{ my: 2.75, border: '1px solid grey', borderRadius: 2, p: 1}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography sx={{ p: 0.5, fontSize: '0.8rem'}}>{t('deviceName') + ': '} {selectedDevice.name || t('none')}</Typography>
                                    <Typography sx={{ p: 0.5, fontSize: '0.8rem'}}>{t('deviceType') + ': '} {selectedDevice.deviceType || t('none')}</Typography>
                                    <Typography sx={{ p: 0.5, fontSize: '0.8rem' }}>{t('connection') + ': '} {selectedDevice.connection || t('none')}</Typography>
                                    <Typography sx={{ p: 0.5, fontSize: '0.8rem' }}>{t('address') + ': '} {selectedDevice.uid || t('none')}</Typography>
                                    <Typography sx={{ p: 0.5, fontSize: '0.8rem' }}>{t('manufacturer') + ': '} {selectedDevice.manufacturer || t('none')}</Typography>
                                    <Typography sx={{ p: 0.5, fontSize: '0.8rem' }}>{t('deviceDriver') + ': '} {selectedDevice.provider || t('none')}</Typography>
                                    <Typography sx={{ p: 0.5, fontSize: '0.8rem' }}>{t('version') + ': '} {selectedDevice.version || t('none')}</Typography>
                                    <Typography sx={{ p: 0.5, fontSize: '0.8rem' }}>{t('Ready to Print') + ': '} {selectedDevice.deviceStatus != null ? selectedDevice.deviceStatus : t('unknown')}</Typography>
                                    {selectedDevice.deviceStatus != null && selectedDevice.deviceStatus.isReadyToPrint != null && selectedDevice.deviceStatus.isReadyToPrint === false &&
                                        <Typography sx={{ p: 0.5, fontSize: '0.8rem' }}>{selectedDevice.deviceStatus != null && selectedDevice.deviceStatus.errors}</Typography>
                                    }
                                </Grid>
                                {selectedDevice != null && selectedDevice.status !== 'template' &&
                                    <Grid container item spacing={2} sx={{ p: 1 }}>
                                        <Grid item xs={12} sm={6}>
                                            <SelectField
                                                error={errors[t('quality')]}
                                                className="form-control"
                                                label={t('printerQuality')}
                                                name="quality"
                                                options={values.labelPrintQualities}
                                                onChange={(e) => changeQuality(e)}
                                                value={selectedDevice.quality || ''}
                                                defaultValue={null}
                                                data-cy='printerQuality'
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField value={selectedDevice.pauseCount} onChange={(e) => changeValue(e)} name='pauseCount' 
                                            size="medium" fullWidth disabled={false} label={t('pauseCount')} type='number' data-cy='pauseCount'
                                            error={errors[t('pauseCount')] != null ? true : false} helperText={errors[t('pauseCount')]}/>
                                        </Grid>
                                        <Grid container item xs={12} sx={{ mx: 2, mt: 2, borderTop: '2px solid grey'}}>
                                            <Grid item xs={12}>
                                                <Typography sx={{ fontSize: '0.8rem' }}>{t('labels')}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sx={{ alignContent: 'center', height: '250px', overflow: 'scroll', ml: 2 }}>
                                                {values != null && values.labelPrintTypes != null && values.labelPrintTypes.length > 0 && 
                                                    values.labelPrintTypes.map((type, index) => {
                                                        let label = selectedDevice != null && selectedDevice.labels != null && 
                                                                    selectedDevice.labels.findIndex((label) => label.type === type.value);
                                                        let value = label !== -1 ? 
                                                                    selectedDevice != null && selectedDevice.labels != null && selectedDevice.labels[index] : '';
                                                        let options = values != null && values.labels != null &&
                                                                    values.labels.filter((label) => label.label.includes(type.label));
                                                        return (
                                                            <Grid container item xs={12} key={'labelType-' + index} spacing={2} 
                                                                sx={{ borderBottom: '2px solid grey', p: 2 }}>
                                                                <Grid item xs={6}>
                                                                    <SelectField
                                                                        error={errors[type.label]}
                                                                        className="form-control"
                                                                        label={type.label}
                                                                        name={type.label.toLowerCase()}
                                                                        options={options}
                                                                        onChange={(e) => selectLabel(e, type.value)}
                                                                        value={value.label || ''}
                                                                        fullWidth
                                                                        data-cy={type.label}
                                                                        disabled={options.length === 0}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} sm={4}>
                                                                    <TextField value={selectedDevice.count || 1} onChange={(e) => changeValue(e)} name='count' 
                                                                        size="medium" fullWidth 
                                                                        disabled={(options != null && options.length === 0) || (selectedDevice != null && selectedDevice.labels != null && selectedDevice.labels.length > 0 &&
                                                                                    selectedDevice.labels[index] != null && selectedDevice.labels[index].promptForCount) || 
                                                                                    (type.value === constants.labelPrintTypes.plant && selectedDevice != null && selectedDevice.labels != null && selectedDevice.labels.length > 0 &&
                                                                                    selectedDevice.labels[index] != null && selectedDevice.labels[index].eachPlant)}
                                                                        label={t('count')} type='number' error={errors[t('count')] != null ? true : false} helperText={errors[t('count')]}/>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <CheckboxField data-cy='promptForCount' disabled={(options == null || options.length === 0) || (type.value === constants.labelPrintTypes.plant && 
                                                                                                                    (selectedDevice != null && selectedDevice.labels != null && 
                                                                                                                    selectedDevice.labels.length > 0 && selectedDevice.labels[index] != null && selectedDevice.labels[index].eachPlant))}
                                                                                    checked={selectedDevice != null && selectedDevice.labels != null && selectedDevice.labels.length > 0 &&
                                                                                                selectedDevice.labels[index] != null && selectedDevice.labels[index].promptForCount} onChange={() => changeBool(index, 'promptForCount')}
                                                                                    label={t('promptForCount')} name="promptForCount"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <CheckboxField data-cy='labelPreview' disabled={options == null || options.length === 0}
                                                                                    checked={selectedDevice != null && selectedDevice.labels != null && selectedDevice.labels.length > 0 &&
                                                                                                selectedDevice.labels[index] != null && selectedDevice.labels[index].labelPreview} onChange={() => changeBool(index, 'labelPreview')}
                                                                                    label={t('labelPreview')} name="labelPreview"
                                                                    />
                                                                </Grid>
                                                                {type.value === constants.labelPrintTypes.plant &&
                                                                    <Grid item xs={12}>
                                                                        <CheckboxField data-cy='oneForEachPlant'
                                                                                    checked={selectedDevice != null && selectedDevice.labels != null && selectedDevice.labels.length > 0 &&
                                                                                                selectedDevice.labels[index] != null && selectedDevice.labels[index].eachPlant} onChange={() => changeBool(index, 'eachPlant')}
                                                                                    label={t('oneForEachPlant')} name="oneForEachPlant"
                                                                        />
                                                                    </Grid>
                                                                }                                                                
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant='contained' color='primary' disabled={!enableSave} onClick={() => saveDevice()}>{t('save')}</Button>
                                            <Button variant='text' color='secondary' disabled={!enableSave} onClick={() => cancelDevice()}>{t('cancel')}</Button>
                                            {/* <Button variant='text' sx={{ color: 'information.main' }} disabled={!enableSave} onClick={() => getDeviceStatus()}>{t('checkStatus')}</Button> */}
                                            {/* <Button variant='text' sx={{ color: 'success.main' }} disabled={!enableSave} onClick={() => testPrint()}>{t('testPrint')}</Button> */}
                                        </Grid>
                                    </Grid>                                
                                }
                            </Grid>
                        </Grid>     
                    </Grid>
                </Grid>
            </Grid>
        </ModalDialog>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(DeviceManager);