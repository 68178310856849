import common from '../jsons/common.json';

const productionHelper = {
    validateHCCat(outputHC, inputHC, hemp){
        if(inputHC == null){
            return true;
        } else if(inputHC != null && outputHC == null){
            return false;
        } else if(inputHC === outputHC){
            return true;
        }
        const hcCats = common.hcReportCategories;
        switch(outputHC){
            case hcCats.dried:
                return inputHC === hcCats.dried || inputHC === hcCats.fresh || hcCats === hcCats.driedPacked  || hcCats.pureIntermediaries || inputHC === hcCats.hemp ? true : false;
            case hcCats.driedPacked:
                return inputHC === hcCats.driedPacked || inputHC === hcCats.dried || inputHC === hcCats.hemp ? true : false;
            case hcCats.fresh:
                return inputHC === hcCats.fresh || inputHC === hcCats.freshPacked ? true : false;
            case hcCats.freshPacked:
                return inputHC === hcCats.freshPacked || inputHC === hcCats.fresh ? true : false;
            case hcCats.hemp:
                return inputHC === hcCats.hemp  || hemp === true? true : false;
            case hcCats.ingest:
                return inputHC === hcCats.ingest || inputHC === hcCats.ingestPacked || inputHC === hcCats.pureIntermediaries ? true : false;
            case hcCats.ingestPacked:
                return inputHC === hcCats.ingestPacked || inputHC === hcCats.ingest ? true : false;
            case hcCats.inhaled:
                return inputHC === hcCats.inhaled || inputHC === hcCats.inhaledPacked || inputHC === hcCats.pureIntermediaries || inputHC === hcCats.dried ? true : false;
            case hcCats.inhaledPacked:
                return inputHC === hcCats.inhaledPacked || inputHC === hcCats.inhaled ? true : false;
            case hcCats.nonSolids:
                return inputHC === hcCats.nonSolids || inputHC === hcCats.nonSolidsPacked || inputHC === hcCats.pureIntermediaries ? true : false;
            case hcCats.nonSolidsPacked:
                return inputHC === hcCats.nonSolidsPacked || inputHC === hcCats.nonSolids ? true : false;
            case hcCats.other:
                return inputHC === hcCats.other || inputHC === hcCats.otherPacked || inputHC === hcCats.pureIntermediaries ? true : false;
            case hcCats.otherPacked:
                return inputHC === hcCats.otherPacked || inputHC === hcCats.other ? true : false;
            case hcCats.pureIntermediaries:
                return inputHC === hcCats.hemp || hcCats.dried || hcCats.fresh || hcCats.pureIntermediaries ? true : false;
            case hcCats.seeds:
                return inputHC === hcCats.seeds || inputHC === hcCats.seedsPacked ? true : false;
            case hcCats.seedsPacked:
                return inputHC === hcCats.seedsPacked || inputHC === hcCats.seeds ? true : false;
            case hcCats.solids:
                return inputHC === hcCats.solids || inputHC === hcCats.solidsPacked || inputHC === hcCats.pureIntermediaries ? true : false;
            case hcCats.solidsPacked:
                return inputHC === hcCats.solidsPacked || inputHC === hcCats.solids ? true : false;
            case hcCats.topical:
                return inputHC === hcCats.topical || inputHC === hcCats.topicalPacked|| inputHC === hcCats.pureIntermediaries ? true : false;
            case hcCats.topicalPacked:
                return inputHC === hcCats.topicalPacked || inputHC === hcCats.topical ? true : false;
            case hcCats.vegPlant:
                return inputHC === hcCats.vegPlant || inputHC === hcCats.vegPlantPacked ? true : false;
            case hcCats.vegPlantPacked:
                return inputHC === hcCats.vegPlantPacked || inputHC === hcCats.vegPlant ? true : false;
            case hcCats.wholePlant:
                return inputHC === hcCats.wholePlant ? true : false;
            default:
                return false;
        }
    }
}

export default productionHelper;
