import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import Unlock from '../General/Unlock';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ActionBar from '../UI/Buttons/ActionBar';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import PrintView from '../Printing/PrintView';
import Panel from '../UI/Panel/Panel';
import Title from '../UI/Typography/Title';
import SectionTitle from '../UI/Typography/SectionTitle';
import CapaAction from './CapaAction';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import ConfirmDelete from '../General/ConfirmDelete';

class Deviation2 extends Component {
    constructor(props){
        super(props);
        this.state = {
            title: '',
            number: '',
            location: '',
            status: '',
            records: [],
            recordsSel: [],
            initiator: '',
            initiatedOn: '',
            occurrenceDate: '',
            department: '',
            entityType: '',
            category: '',
            description: '',
            impact: '',
            impactExplanation: '',
            impactAssessmentUser: '',
            impactAssessmentDate: '',
            rootCause: '',
            rootCauseApprover: '',
            rootCauseSignature: null,
            rootCauseApprovalDate: '',
            qaAgreeImpact: false,
            qaDisagreeImpactText: '',
            qaDisagreeCauseText: '',
            disposition: '',
            finalQADecision: '',
            signer: null,
            schedule: null,
            finalDocument: null,
            assessor: '',
            documents: [],
            summary: '',
            qaAgreeCause: false,
            childEntityType: null,
            childRecord: '',
            childRecordSel: null,
            type: '',
            id: "",
            qas: [],
            managers: [],
            users: [],
            dispositionsSelected: [],
            dispositions: [],
            statuses: [],
            types: [],
            impacts: [],
            entityTypes: [],
            locations: [],
            rootSigOpen: false,
            assessed: false,
            signed: false,
            tenant: this.props.auth.user.tenant,
            departments: [],
            categories: [],
            locationSelected: null,
            initiatorSelected: null,
            entityTypeSelected: null,
            typeSelected: null,
            catSel: null,
            depSel: null,
            impactSelected: null,
            impactUserSelected: null,
            rootCauseApproverSelected: null,
            actSel: null,
            batchActivities:[],
            poActivities: [],
            poActSel: null,
            security: [],
            dialogOpen: false,
            enableSave: true,
            form: '',
            hasForm: false,
            goBack: -1,
            printViewer: false,
            capaActions: [],
            offset: 0,
            listSize: 10,
            page: 0,
            orderBy: 'number',
            ordDir: 1,
            totalCount: 0,
            capaId: '',
            capaIndex: -1,
            editCapa: false,
            optionsMenu: [],
            flagForSignature: 0,
            deleteOpen: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location, common.cacheValues.deviationStatus, common.cacheValues.deviationCategory, 
            common.cacheValues.deviationType, common.cacheValues.deviationImpact, common.cacheValues.userDepartment, common.cacheValues.object, 
            common.cacheValues.deviationDisposition, common.cacheValues.deviationForm], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.deviation, this.props.auth.user.tenant);
        let params = queryString.parse(this.props.location.search);
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        let goBack = -1;
        if(scheduleId != null)
            goBack = -2;
        let recordType = params != null && params.entityType != null ? params.entityType : null;
        let record = params != null && params.record != null ? params.record : null;
        const ebatch = params != null && params.ebatch != null ? params.ebatch : null;
        const externalTreatment = params != null && params.externalTreatment != null ? params.externalTreatment : null;
        const batchActivity = params != null && params.batchActivity != null ? params.batchActivity : null;
        const productionOrder = params != null && params.productionOrder != null ? params.productionOrder : null;
        const poActivity = params != null && params.poActivity != null ? params.poActivity : null;
        let query = {};
        if(ebatch){
            query.ebatch = ebatch
        }
        if(productionOrder)
            query.productionOrder = productionOrder;
        if(externalTreatment)
            query.externalTreatment = externalTreatment;
        if(recordType != null && record != null){
            query.entityType = recordType;
            query.record = record;
        }
        let values = await axios.get('/api/value/deviation', {params: query});
        let statusSelected = cacheValues.deviationStatuses.find(x =>x.value === this.props.auth.constants.deviationStatuses.open);
        let childRecordSel = null;
        let entityType = '';
        let childEntityType = null;
        let entityTypeSelected = null;
        let entitiesSelected = [];
        let selectedEntityIds = [];
        let childRecord = null;
        let department = '';
        let depSel = null;
        if(ebatch){
            entityType = this.props.auth.constants.objectIds.evoraBatch;
            entityTypeSelected = cacheValues.objects.find(x => x.value === this.props.auth.constants.objectIds.evoraBatch);
            childRecordSel = values.data.activities.find(x => x.value === batchActivity);
            childRecord = batchActivity;
            selectedEntityIds.push(ebatch);
            let batchSel = values.data.ebatches.find(x => x.value === ebatch);
            entitiesSelected.push(batchSel);
            childEntityType = this.props.auth.constants.objectIds.batchActivity;
            department = this.props.auth.constants.departments.cultivation;
            depSel = cacheValues.userDepartments.find(x => x.value === department);
        } else if(productionOrder && poActivity){
            entityType = this.props.auth.constants.objectIds.productionOrder;
            entityTypeSelected = cacheValues.objects.find(x => x.value === this.props.auth.common.sopRelatedTos.productionOrder);
            childRecordSel = values.data.poActivities.find(x => x.value === poActivity);
            childRecord = poActivity;
            selectedEntityIds.push(productionOrder);
            let poSel = values.data.productionOrders.find(x => x.value === productionOrder);
            entitiesSelected.push(poSel);
            childEntityType = this.props.auth.constants.objectIds.poActivity;
            department = this.props.auth.constants.departments.production;
            depSel = cacheValues.userDepartments.find(x => x.value === department);
        } else if(externalTreatment){
            entityType = this.props.auth.constants.objectIds.externalTreatment;
            entityTypeSelected = cacheValues.objects.find(x => x.value === this.props.auth.common.sopRelatedTos.externalTreatment);
            selectedEntityIds.push(externalTreatment);
            let externalTreatmentSel = values.data.externalTreatments.find(x => x.value === externalTreatment);
            entitiesSelected.push(externalTreatmentSel);
        } else if (recordType != null && record != null){
            entityType = recordType;
            entityTypeSelected = cacheValues.objects.find(x => x.value === recordType);
            selectedEntityIds.push(record);
            let sel = values.data.records.find(x => x.value === record);
            entitiesSelected.push(sel);
        }
        let type = '';
        let typeSelected = null;
        if(childRecord != null){
            type = this.props.auth.constants.deviationTypes.unplanned;
            typeSelected = cacheValues.deviationTypes.find(x => x.value === type);
        }
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
            this.props.auth.user.defaultLocation : '';
        let locSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locSel == null && locations.length === 1){
            locSel = locations[0];
            location = locSel.value;
        }
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        this.setState({
            title: '',
            status: '',
            impactAssessmentDate: null,
            description: '',
            finalQADecision: '',
            summary: '',
            type: type,
            typeSelected: typeSelected,
            department: department,
            depSel: depSel,
            initiatedOn: new Date(),
            occurrenceDate: new Date(),
            location: location,
            childEntityType: childEntityType,
            childRecord: childRecord,
            entityType: entityType,
            statuses: cacheValues.deviationStatuses,
            types: cacheValues.deviationTypes,
            impacts: cacheValues.deviationImpacts,
            dispositions: cacheValues.deviationDispositions,
            entityTypes: cacheValues.objects,
            locations: locations,
            locationSelected: locSel,
            users: userValues.create,
            qas: userValues.approve,
            managers: userValues.review,
            schedule: scheduleId,
            statusSelected: statusSelected,
            departments: cacheValues.userDepartments,
            categories: cacheValues.deviationCategories,
            batchActivities: values.data.activities != null ? values.data.activities : [],
            poActivities: values.data.poActivities != null ? values.data.poActivities : [],
            entities : values.data.ebatches != null ? values.data.ebatches : values.data.records != null ? values.data.records : [],
            entityTypeSelected: entityTypeSelected,
            entitiesSelected: entitiesSelected,
            records: selectedEntityIds,
            security: this.props.permission,
            childRecordSel: childRecordSel,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            _id: this.props.match.params.id != null ? this.props.match.params.id : '',
            goBack: goBack
        }, async () => {
            if(this.props.match.params.id != null){
                this.loadRecord();
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        let response = await axios.get('/api/deviation/' + this.state._id);
        let signed = response.data.status === this.props.auth.constants.deviationStatuses.complete;
        let assessed = response.data.status === this.props.auth.constants.deviationStatuses.assessmentComplete ||
            response.data.status === this.props.auth.constants.deviationStatuses.complete;
        let dispositionsSelected = [];
        let dispOptions = this.state.dispositions;
        if(response.data.disposition != null && response.data.disposition.length > 0 && response.data.disposition[0] != null){
            for(let i = 0; i < response.data.disposition.length; i++){
                for(let j = 0; j < dispOptions.length; j++){
                    if(response.data.disposition[i] === dispOptions[j].value){
                        dispositionsSelected.push(dispOptions[j]);
                        break;
                    }
                }
            }
        }
        let entities = response.data.records;
        let entityIds = response.data.records;
        let statuses = this.state.statuses;
        let statusSelected = statuses.find(x => x.value === response.data.status);
        let locations = this.state.locations;
        let locationSelected = locations.find(x => x.value === response.data.location);
        let users = this.state.users;
        let managers = this.state.managers;
        let qas = this.state.qas;
        let initiatorSelected = users.find(x => x.value === response.data.initiator);
        let entityTypes = this.state.entityTypes;
        let entityTypeSelected = entityTypes.find(x => x.value === response.data.entityType);
        let types = this.state.types;
        let typeSelected = types.find(x => x.value === response.data.type);
        let impacts = this.state.impacts;
        let impactSelected = impacts.find(x => x.value === response.data.impact);
        let impactUserSelected = users.find(x => x.value === response.data.impactAssessmentUser);
        let rootCauseApproverSelected = managers.find(x => x.value === response.data.rootCauseApprover);
        let qaAssessmentUserSelected = qas.find(x => x.value === response.data.qaAssessmentSignoffUser);
        let qaSignoffUserSelected = qas.find(x => x.value === response.data.qaSignoffUser);
        const departments = this.state.departments;
        let depSel = response.data.department != null ? departments.find(x => x.value === response.data.department) : null;
        const categories = this.state.categories;
        let catSel = response.data.category != null ? categories.find(x => x.value === response.data.category) : null;
        let childRecordSel = null;
        if(response.data.childEntityType != null){
            if(response.data.childEntityType === this.props.auth.constants.objectIds.batchActivity){
                childRecordSel = response.data.batchActivities.find(x => x.value === response.data.childRecord);
            } else if (response.data.childEntityType === this.props.auth.constants.objectIds.poActivity){
                childRecordSel = response.data.poActivities.find(x => x.value === response.data.childRecord);
            }
        }
        let childEntityType = response.data.childEntityType;
        if(childEntityType == null){
            childEntityType = response.data.entityType === this.props.auth.constants.objectIds.evoraBatch ? 
                this.props.auth.constants.batchActivity : response.data.entityType === this.props.auth.constants.objectIds.productionOrder ?
                this.props.auth.constants.objectIds.poActivity : null;
        }
        const optionsMenu = [
            {
                security: this.state.security.create && !assessed && !signed, user: response.data.rootCauseApprover, users: users, name: t('rootCause'), object: response.data._id,
                title: t('rootCause'), setId: () => this.loadRecord(), validate: () => this.validateComplete(2), signature: response.data.rootCauseSignature,
                objectType: this.props.auth.constants.objectIds.deviation, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature,
            },
            {
                security: this.state.security.review && !assessed && !signed && response.data.rootCauseSignature != null, user: response.data.impactAssessmentUser, users: this.state.qas,
                name: t('assessment'), title: t('assessment'), setId: () => this.loadRecord(), signature: response.data.assessor, object: response.data._id,
                objectType: this.props.auth.constants.objectIds.deviation, type: this.props.auth.constants.signatureTypes.reviewer, validate: () => this.validateComplete(3),
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: this.state.security.approve && assessed && !signed, user: this.props.auth.user.id, users: this.state.qas,
                name: t('complete'), title: t('complete'), setId: () => this.loadRecord(), object: response.data._id, signature: response.data.signer,
                objectType: this.props.auth.constants.objectIds.deviation, type: this.props.auth.constants.signatureTypes.signer, validate: () => this.validateComplete(4),
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = response.data.status === this.props.auth.constants.deviationStatuses.open && response.data.rootCauseSignature == null ? 1 :
                                 response.data.status === this.props.auth.constants.deviationStatuses.open && response.data.rootCauseSignature != null ? 2 :
                                 response.data.status === this.props.auth.constants.deviationStatuses.assessed && response.data.assessor != null ? 3 : 0;
        let hasForm = this.state.hasForm;
        this.setState({
            disposition: response.data.disposition,
            title: response.data.title,
            number: response.data.number,
            location: response.data.location,
            status: response.data.status,
            initiator: response.data.initiator,
            initiatedOn: response.data.initiatedOn,
            occurrenceDate: response.data.occurrenceDate,
            entityType: response.data.entityType,
            records: response.data.records,
            childEntityType: childEntityType,
            type: response.data.type,
            category: response.data.category,
            description: response.data.description,
            impact: response.data.impact,
            impactExplanation: response.data.impactExplanation,
            impactAssessmentUser: response.data.impactAssessmentUser,
            impactAssessmentDate: response.data.impactAssessmentDate,
            rootCause: response.data.rootCause,
            rootCauseApprover: response.data.rootCauseApprover,
            rootCauseSignature: response.data.rootCauseSignature,
            rootCauseApprovalDate: response.data.rootCauseApprovalDate,
            qaDisagreeImpactText: response.data.qaDisagreeImpactText,
            qaDisagreeCauseText: response.data.qaDisagreeCauseText,
            finalQADecision: response.data.finalQADecision,
            signer: response.data.signer,
            schedule: response.data.schedule,
            finalDocument: response.data.finalDocument,
            assessor: response.data.assessor,
            documents: response.data.documents,
            summary: response.data.summary,
            department: response.data.department,
            form: response.data.form,
            dispositionsSelected: dispositionsSelected,
            selectedEntityIds: entityIds,
            signed: signed,
            statusSelected: statusSelected,
            locationSelected: locationSelected,
            initiatorSelected: initiatorSelected,
            entityTypeSelected: entityTypeSelected,
            typeSelected: typeSelected,
            impactSelected: impactSelected,
            impactUserSelected: impactUserSelected,
            rootCauseApproverSelected: rootCauseApproverSelected,
            qaAssessmentUserSelected: qaAssessmentUserSelected,
            qaSignoffUserSelected: qaSignoffUserSelected,
            assessed: assessed,
            depSel:depSel,
            catSel: catSel,
            qaAgreeImpact: response.data.qaAgreeImpact ? "yes" : !response.data.qaAgreeImpact ? "no" : null,
            qaAgreeCause: response.data.qaAgreeCause ? 'yes' : !response.data.qaAgreeCause ? 'no' : null,
            batchActivities: response.data.batchActivities,
            childRecord: response.data.childRecord,
            childRecordSel: childRecordSel,
            poActivities: response.data.poActivities,
            hasForm: hasForm,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature
        }, () => {
            const crumbs = [
                { path: '/#/deviations', label: this.props.t('deviations'), screen: this.props.auth.screenDefs.DeviationList},
                { path: 'active', label: this.props.t('deviation') + ' - ' + response.data.number, screen: this.props.auth.screenDefs.Deviation}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            this.loadCapas();
        });
        if(response.data.entityType != null && response.data.entityType !== ''){
            let ents = await axios.get('/api/entitylist/' + response.data.entityType)
            let selectedFull = [];
            if(entities != null && entities.length > 0){
                for (let a = 0; a < entities.length; a++){
                    let val = ents.data.find(x => x.value === entities[a]);
                    if(val != null){
                        selectedFull.push(val);
                    }
                }
            }
            this.setState({
                entities: ents.data,
                entitiesSelected: selectedFull
            });
        }
    }

    loadCapas = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: this.props.auth.constants.objectIds.deviation, value: this.state._id},
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let capaRes = await axios.get('/api/capas', {params: params});
        this.setState({
            capaActions: capaRes.data.data,
            totalCount: capaRes.data.totalCount
        });
    }

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = async () => {
        if(!this.state.security.delete || !this.state.enableSave)
            return;
        if(this.state._id == null || this.state._id === '')
            this.props.history.goBack();
        this.setState({ enableSave: false }, () => this._deleteClick());
    };

    _deleteClick = async () => {
        try {
            let response = await axios.delete('/api/deviation/' + this.state._id);
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: response.data.message, title: this.props.t('success'), severity: 'success'}});
            this.setState({deleteOpen: false, enableSave: true});
            this.props.history.goBack();
        } catch (err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    }

    saveDeviation = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveDeviation());
    };

    _saveDeviation = async () => {
        const devStatuses = this.props.auth.constants.deviationStatuses;
        const t = this.props.t;
        let valType = (this.state._id == null || this.state._id === '') || this.state.status === devStatuses.open ? null : 
                        this.state.status === devStatuses.assessed && this.state.assessor == null ? 3 : 4;
        const valid = this.validateForm(valType);
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let url = '/api/deviation';
        if(this.state._id != null)
            url = '/api/deviation/' + this.state._id;
        let dev = {
            title: this.state.title,
            number: this.state.number,
            location: this.state.location,
            status: this.state.status,
            initiator: this.state.initiator,
            initiatedOn: this.state.initiatedOn,
            occurrenceDate: this.state.occurrenceDate,
            entityType: this.state.entityType,
            records: this.state.records,
            type: this.state.type,
            category: this.state.category,
            description: this.state.description,
            impact: this.state.impact,
            impactExplanation: this.state.impactExplanation,
            impactAssessmentDate: this.state.impactAssessmentDate,
            impactAssessmentUser: this.state.impactAssessmentUser,
            rootCause: this.state.rootCause,
            rootCauseApprover: this.state.rootCauseApprover,
            rootCauseApprovalDate: this.state.rootCauseApprovalDate,
            qaAgreeCause: this.state.qaAgreeCause,
            qaDisagreeCauseText: this.state.qaDisagreeCauseText,
            qaAgreeImpact: this.state.qaAgreeImpact,
            qaDisagreeImpactText: this.state.qaDisagreeImpactText,
            disposition: this.state.disposition,
            finalQADecision: this.state.finalQADecision,
            summary: this.state.summary,
            form: this.state.form,
            department: this.state.department,
            childEntityType: this.state.childEntityType,
            childRecord: this.state.childRecord
        };
        try {
            let result = await axios.post(url, dev);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/deviation/' + result.data.id);
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            this.setState({
                number: result.data.number,
                enableSave: true,
                _id: result.data.id
            }, () => {
                this.loadRecord();
            });
        } catch (err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });    
        }
    };

    validateForm(type){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.title == null || state.title === '')
            errors[t('title')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.initiator == null || state.initator === '')
            errors[t('initiator')] = t('required')
        if(state.initiatedOn == null || state.initiatedOn === '')
            errors[t('initiatedOn')] = t('required');
        if(state.occurrenceDate == null || state.occurrenceDate === '')
            errors[t('occurrenceDate')] = t('required');
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(state.description == null || state.description === '')
            errors[t('description')] = t('required');
        if(state.department == null || state.department === '')
            errors[t('department')] = t('required');
        if(type != null && type >- 2){
            if(state.impact == null || state.impact === '')
                errors[t('impact')] = t('required');
            if(state.impactExplanation == null || state.impactExplanation === '')
                errors[t('impactExplanation')] = t('required');
            if(state.impactAssessmentUser == null || state.impactAssessmentUser === '')
                errors[t('impactAssessmentUser')] = t('required');
            if(state.impactAssessmentDate == null || state.impactAssessmentDate === '')
                errors[t('impactAssessmentDate')] = t('required');
            if(state.rootCause == null || state.rootCause === '')
                errors[t('rootCause')] = t('required');
            if(state.rootCauseApprover == null || state.rootCauseApprover === '')
                errors[t('rootCauseApprover')] = t('required');
            if(state.rootCauseApprovalDate == null || state.rootCauseApprovalDate === '')
                errors[t('rootCauseApprovalDate')] = t('required');
        }
        if(type != null && type >= 3){
            if(state.qaAgreeImpact == null || state.qaAgreeImpact === '')
                errors[t('qaAgreeImpact')] = t('required');
            if(!state.qaAgreeImpact === 'no' && (state.qaDisagreeImpactText == null || state.qaDisagreeImpactText === ''))
                errors[t('qaDisagreeImpactText')] = t('required');
            if(state.qaAgreeCause == null || state.qaAgreeCause === '')
                errors[t('qaAgreeCause')] = t('required');
            if(!state.qaAgreeCause === 'no' && (state.qaDisagreeCauseText == null || state.qaDisagreeCauseText === ''))
                errors[t('qaDisagreeCauseText')] = t('required');
        }
        if(type != null && type === 4){
            if(state.dispositionsSelected == null || state.dispositionsSelected.length === 0)
                errors[t('dispositionsSelected')] = t('required');
            if(state.finalQADecision == null || state.finalQADecision === '')
                errors[t('finalQADecision')] = t('required');
            if(state.summary == null || state.summary === '')
                errors[t('summary')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    validateComplete = (valType) => {
        let valid = this.validateForm(valType);
        if(!valid){
            return this.props.t('errors');
        }
        return null;

    }

    changeValue = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        this.setState({[name]: value});
    }

    changeAuto = (name, data) => async (e, newValue) => {
        let batchActivities = this.state.batchActivities;
        if(data === 'ebatch' && newValue != null){
            let actResult = await axios.get('/api/batchactivityselect/' + [newValue.value]);
            batchActivities = actResult.data;
        }
        this.setState({
            [data]: newValue != null ? newValue.value : null,
            [name]: newValue,
            batchActivities: batchActivities
        })
        if(data === 'entityType'){
            this.changeEntityType(newValue != null ? newValue.value : null);
        }
    };

    async changeEntityType(value){
        if(value != null){
            let list = await axios.get('/api/entitylist/' + value);
            this.setState({
                childEntityType: value === this.props.auth.constants.objectIds.evoraBatch ? this.props.auth.constants.objectIds.batchActivity :
                value === this.props.auth.constants.objectIds.productionOrder ? this.props.auth.constants.objectIds.poActivity : null,
                childRecord: '',
                childRecordSel: null,
                entities: list.data,
                entitiesSelected: [],
                selectedEntityIds: []
            });
        } else {
            this.setState({
                childEntityType: null,
                childRecord: '',
                childRecordSel: null,
                entitiesSelected: [],
                selectedEntityIds: []
            });
        }
    }

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    };

    entitiesChanged = async (e, newValue) => {
        let selectedEntities = [];
        if(newValue != null){
            newValue.forEach(entity => {
                selectedEntities.push(entity.value);
            })
        }
        let activities = this.state.batchActivities;
        let poActivities = this.state.poActivities;
        if(this.state.entityType === this.props.auth.constants.objectIds.evoraBatch){
            let acts = await axios.get('/api/batchactivityselect/' + selectedEntities);
            activities = acts.data;
        } else if (this.state.entityType === this.props.auth.constants.objectIds.productionOrder){
            let acts = await axios.get('/api/productionorderactselect/' + selectedEntities );
            poActivities = acts.data;
        }
        this.setState({
            records: selectedEntities,
            entitiesSelected: newValue,
            batchActivities: activities,
            poActivities: poActivities
        });
    }

    changeMulti = (name, data) => (e, newValue) => {
        let selected = [];
        if(newValue != null){
            newValue.forEach(entity => {
                selected.push(entity.value);
            })
        }
        this.setState({
            [name]: newValue,
            [data]: selected
        });
    }

    changeBool = (name) => (e) => {
        this.setState({[name]: e.target.value});
    }

    addAction = (e) => {
        this.setState({editCapa: true})
    };

    closeDialog = (e) => {
        this.setState({
            editCapa: false,
            capaId: '',
            capaIndex: -1
        },() => {this.loadCapas();});
    };

    editCapa = (i) => (e) => {
        let capa = this.state.capaActions[i];
        this.setState({capaId: capa._id, editCapa: true, capaIndex: i});
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadCapas();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadCapas();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadCapas();
        });
    };

    togglePrintViewer = () => {
        this.setState({ printViewer: !this.state.printViewer });
    }

    render(){
        let signed = this.state.signed;
        let assessed = this.state.assessed;
        const errors = this.props.errors;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar goBack={this.state.goBack}>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        flagForSignature={this.state.flagForSignature}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        printViewClick={this.togglePrintViewer}
                        printViewTitle={t('deviationRecord')}
                        enablePrintView={this.state.status === this.props.auth.constants.deviationStatuses.complete}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.deviation}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Title title={t('deviation') + this.state.number != null ? ' ' + this.state.number : ''} />
                    <Grid container spacing={3}>
                        {this.state._id != null && this.state._id !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            <FormControl>
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSelected != null ? this.state.statusSelected.label : ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {!assessed &&
                                <TextField value={this.state.title} onChange={this.changeValue} name="title"
                                size="medium" fullWidth={true} disabled={assessed} label={t('title')} required
                                error={errors[t('title')] != null ? true : false} helperText={errors[t('title')]}/>
                            }
                            {assessed &&
                                <FormControl>
                                    <FormLabel>{t('title')}</FormLabel>
                                    {this.state.title}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!assessed &&
                                <AutoCompleteField
                                    value={this.state.locationSelected}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locationSelected', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]}
                                    label={t('location')}
                                    disabled={assessed}
                                    required
                                />
                            }
                            {assessed &&
                                <FormControl>
                                    <FormLabel>{t('location')}</FormLabel>
                                    {this.state.locationSelected != null ? this.state.locationSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!assessed &&
                                <AutoCompleteField
                                    value={this.state.initiatorSelected}
                                    options={this.state.users}
                                    onChange={this.changeAuto('initiatorSelected', 'initiator')}
                                    error={errors[t('initiator')] != null ? true : false}
                                    helperText={errors[t('initiator')]}
                                    label={t('initiatedBy')}
                                    disabled={assessed}
                                    required
                                />
                            }
                            {assessed &&
                                <FormControl>
                                    <FormLabel>{t('initiatedBy')}</FormLabel>
                                    {this.state.initiatorSelected != null ? this.state.initiatorSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!assessed &&
                                <DateSelect
                                    onChange={this.changeDate('initiatedOn')}
                                    value={this.state.initiatedOn}
                                    helperText={errors[t('initiatedOn')]}
                                    error={errors[t('initiatedOn')] != null ? true : false}
                                    label={t('dateInitiated')}
                                    disabled={assessed}
                                    format={this.props.auth.user.dateFormat}
                                    required
                                />
                            }
                            {assessed &&
                                <FormControl>
                                    <FormLabel>{t('dateInitiated')}</FormLabel>
                                    <DateDisplay value={this.state.initiatedOn} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!assessed &&
                                <DateSelect
                                    onChange={this.changeDate('occurenceDate')}
                                    value={this.state.occurrenceDate}
                                    helperText={errors[t('occurrenceDate')]}
                                    error={errors[t('occurrenceDate')] != null ? true : false}
                                    label={t('occurrenceDate')}
                                    disabled={assessed}
                                    format={this.props.auth.user.dateFormat}
                                    required
                                />
                            }
                            {assessed &&
                                <FormControl>
                                    <FormLabel>{t('occurrenceDate')}</FormLabel>
                                    <DateDisplay value={this.state.occurrenceDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!assessed &&
                                <AutoCompleteField
                                    value={this.state.entityTypeSelected}
                                    options={this.state.entityTypes}
                                    onChange={this.changeAuto('entityTypeSelected', 'entityType')}
                                    error={errors[t('entityType')] != null ? true : false}
                                    helperText={errors[t('entityType')]}
                                    label={t('relatedTo')}
                                    disabled={assessed}
                                />
                            }
                            {assessed &&
                                <FormControl>
                                    <FormLabel>{t('relatedTo')}</FormLabel>
                                    {this.state.entityTypeSelected != null ? this.state.entityTypeSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AutoCompleteField
                                multiple={true}
                                value={this.state.entitiesSelected}
                                options={this.state.entities}
                                onChange={this.entitiesChanged}
                                error={errors[t('entitiesSelected')] != null ? true : false}
                                helperText={errors[t('entitiesSelected')]}
                                label={t('affected')}
                                disabled={assessed}
                            />
                        </Grid>
                        {this.state.batchActivities != null && this.state.batchActivities.length > 0 &&
                            <Grid item xs={6} sm={3}>
                                {!assessed &&
                                    <AutoCompleteField
                                        value={this.state.childRecordSel}
                                        options={this.state.batchActivities}
                                        onChange={this.changeAuto('childRecordSel', 'childRecord')}
                                        error={errors[t('childRecord')] != null ? true : false}
                                        helperText={errors[t('childRecord')]}
                                        label={t('activity')}
                                        disabled={assessed}
                                    />
                                }
                                {assessed && this.state.childRecordSel != null &&
                                    <FormControl>
                                        <FormLabel>{t('activity')}</FormLabel>
                                        {this.state.childRecordSel != null ? this.state.childRecordSel.label : ''}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {this.state.poActivities != null && this.state.poActivities.length > 0 &&
                            <Grid item xs={6} sm={3}>
                                {!assessed &&
                                    <AutoCompleteField
                                        value={this.state.childRecordSel}
                                        options={this.state.poActivities}
                                        onChange={this.changeAuto('childRecordSel', 'childRecord')}
                                        error={errors[t('childRecord')] != null ? true : false}
                                        helperText={errors[t('childRecord')]}
                                        label={t('activity')}
                                        disabled={assessed}
                                    />
                                }
                                {assessed && this.state.childRecordSel != null &&
                                    <FormControl>
                                        <FormLabel>{t('activity')}</FormLabel>
                                        {this.state.childRecordSel != null ? this.state.childRecordSel.label : ''}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            {!assessed &&
                                <AutoCompleteField
                                    value={this.state.typeSelected}
                                    options={this.state.types}
                                    onChange={this.changeAuto('typeSelected', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]}
                                    label={t('type')}
                                    disabled={assessed}
                                    required
                                />
                            }
                            {assessed &&
                                <FormControl>
                                    <FormLabel>{t('type')}</FormLabel>
                                    {this.state.typeSelected != null ? this.state.typeSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!assessed &&
                                <AutoCompleteField
                                    value={this.state.catSel}
                                    options={this.state.categories}
                                    onChange={this.changeAuto('catSel', 'category')}
                                    error={errors[t('category')] != null ? true : false}
                                    helperText={errors[t('category')]}
                                    label={t('category')}
                                    disabled={assessed}
                                />
                            }
                            {assessed &&
                                <FormControl>
                                    <FormLabel>{t('category')}</FormLabel>
                                    {this.state.catSel != null ? this.state.catSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!assessed &&
                                <AutoCompleteField
                                    value={this.state.depSel}
                                    options={this.state.departments}
                                    onChange={this.changeAuto('depSel', 'department')}
                                    error={errors[t('department')] != null ? true : false}
                                    helperText={errors[t('department')]}
                                    label={t('department')}
                                    disabled={assessed}
                                    required
                                />
                            }
                            {assessed &&
                                <FormControl>
                                    <FormLabel>{t('department')}</FormLabel>
                                    {this.state.depSel != null ? this.state.depSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item sm={11}>
                            {!assessed &&
                                <TextField value={this.state.description} onChange={this.changeValue} name="description"
                                multiline={true} rows="3"
                                variant="outlined" size="medium" fullWidth={true} disabled={assessed}
                                label={t('description')} required
                                error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                            }
                            {assessed &&
                                <FormControl>
                                    <FormLabel>{t('description')}</FormLabel>
                                    {this.state.description}
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('impactAssessment')} />
                            {this.state.hasForm === true &&
                                <Aux>
                                    <br/>
                                    {errors[t('form')] != null && errors[t('form')] !== '' &&
                                        <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                    }
                                    <Form renderer={renderer}
                                        defaultFields={this.state.form}
                                        onChange={this.formValueChanged}
                                        disabled={assessed}/>
                                </Aux>
                            }
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    {!assessed &&
                                        <AutoCompleteField
                                            value={this.state.impactSelected}
                                            options={this.state.impacts}
                                            onChange={this.changeAuto('impactSelected', 'impact')}
                                            error={errors[t('impact')] != null ? true : false}
                                            helperText={errors[t('impact')]}
                                            label={t('impact')}
                                            disabled={assessed}
                                            required
                                        />
                                    }
                                    {assessed &&
                                        <FormControl>
                                            <FormLabel>{t('impact')}</FormLabel>
                                            {this.state.impactSelected != null ? this.state.impactSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!assessed &&
                                        <AutoCompleteField
                                            value={this.state.impactUserSelected}
                                            options={this.state.users}
                                            onChange={this.changeAuto('impactUserSelected', 'impactAssessmentUser')}
                                            error={errors[t('impactAssessmentUser')] != null ? true : false}
                                            helperText={errors[t('impactAssessmentUser')]}
                                            label={t('performedBy')}
                                            disabled={assessed}
                                            required
                                        />
                                    }
                                    {assessed &&
                                        <FormControl>
                                            <FormLabel>{t('performedBy')}</FormLabel>
                                            {this.state.impactUserSelected != null ? this.state.impactUserSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!assessed &&
                                        <DateSelect
                                            onChange={this.changeDate('impactAssessmentDate')}
                                            value={this.state.impactAssessmentDate}
                                            helperText={errors[t('impactAssessmentDate')]}
                                            error={errors[t('impactAssessmentDate')] != null ? true : false}
                                            label={t('assessmentDate')}
                                            disabled={assessed}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                        />
                                    }
                                    {assessed &&
                                        <FormControl>
                                            <FormLabel>{t('assessmentDate')}</FormLabel>
                                            <DateDisplay value={this.state.impactAssessmentDate} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item sm={11}>
                                    {!assessed &&
                                        <TextField value={this.state.impactExplanation} onChange={this.changeValue} name="impactExplanation"
                                        multiline={true} rows="3"
                                        variant="outlined" size="medium" fullWidth={true} disabled={assessed}
                                        label={t('explanation')} required
                                        error={errors[t('impactExplanation')] != null ? true : false} helperText={errors[t('impactExplanation')]}/>
                                    }
                                    {assessed &&
                                        <FormControl>
                                            <FormLabel>{t('explanation')}</FormLabel>
                                            {this.state.impactExplanation}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item sm={11}>
                                    {!assessed &&
                                        <TextField value={this.state.rootCause} onChange={this.changeValue} name="rootCause"
                                        multiline={true} rows="3"
                                        variant="outlined" size="medium" fullWidth={true} disabled={assessed}
                                        label={t('rootCause')} required
                                        error={errors[t('rootCause')] != null ? true : false} helperText={errors[t('rootCause')]}/>
                                    }
                                    {assessed &&
                                        <FormControl>
                                            <FormLabel>{t('rootCause')}</FormLabel>
                                            {this.state.rootCause}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    {!assessed &&
                                        <AutoCompleteField
                                            value={this.state.rootCauseApproverSelected}
                                            options={this.state.managers}
                                            onChange={this.changeAuto('rootCauseApproverSelected', 'rootCauseApprover')}
                                            error={errors[t('rootCauseApprover')] != null ? true : false}
                                            helperText={errors[t('rootCauseApprover')]}
                                            label={t('approvedBy')}
                                            disabled={assessed}
                                            required
                                        />
                                    }
                                    {assessed &&
                                        <FormControl>
                                            <FormLabel>{t('approvedBy')}</FormLabel>
                                            {this.state.rootCauseApproverSelected != null ? this.state.rootCauseApproverSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!assessed &&
                                        <DateSelect
                                            onChange={this.changeDate('rootCauseApprovalDate')}
                                            value={this.state.rootCauseApprovalDate}
                                            helperText={errors[t('rootCauseApprovalDate')]}
                                            error={errors[t('rootCauseApprovalDate')] != null ? true : false}
                                            label={t('approvalDate')}
                                            disabled={assessed}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                        />
                                    }
                                    {assessed &&
                                        <FormControl>
                                            <FormLabel>{t('approvalDate')}</FormLabel>
                                            <DateDisplay value={this.state.rootCauseApprovalDate} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            <br/>
                            <SectionTitle title={t('qaAssessment')} />
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl component="fieldset" required>
                                        <FormLabel component="legend">{t('impactAgree')}</FormLabel>
                                        <RadioGroup aria-label="QA Assessment Agree" name="qaAgreeImpact" value={this.state.qaAgreeImpact}
                                        onChange={this.changeBool('qaAgreeImpact')} row>
                                            <FormControlLabel value="yes" control={<Radio color='secondary' disabled={assessed}/>} label={t('yes')} />
                                            <FormControlLabel value="no" control={<Radio color='secondary' disabled={assessed}/>} label={t('no')} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {errors[t('qaAgreeImpact')] && <div><div className="error text-danger">{errors[t('qaAgreeImpact')]}</div><br/></div>}
                            {this.state.qaAgreeImpact != null && this.state.qaAgreeImpact === "no" &&
                                <Grid container spacing={3}>
                                    <Grid item sm={11}>
                                        {!assessed &&
                                            <TextField value={this.state.qaDisagreeImpactText} onChange={this.changeValue} name="qaDisagreeImpactText"
                                            multiline={true} rows="3"
                                            variant="outlined" size="medium" fullWidth={true} disabled={assessed}
                                            label={t('whyNot')} required
                                            error={errors[t('qaDisagreeImpactText')] != null ? true : false} helperText={errors[t('qaDisagreeImpactText')]}/>
                                        }
                                        {assessed &&
                                            <FormControl>
                                                <FormLabel>{t('whyNot')}</FormLabel>
                                                {this.state.qaDisagreeImpactText}
                                            </FormControl>
                                        }
                                    </Grid>
                                </Grid>
                            }
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl component="fieldset" required>
                                            <FormLabel component="legend">{t('causeAgree')}</FormLabel>
                                            <RadioGroup aria-label="QA Root Cause Agree" name="qaAgreeCause" value={this.state.qaAgreeCause}
                                            onChange={this.changeBool('qaAgreeCause')} row>
                                                <FormControlLabel value="yes" control={<Radio color='secondary' disabled={assessed}/>} label={t('yes')} />
                                                <FormControlLabel value="no" control={<Radio color='secondary' disabled={assessed}/>} label={t('no')} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {errors[t('qaAgreeCause')] && <div><div className="error text-danger">{errors[t('qaAgreeCause')]}</div><br/></div>}
                            {this.state.qaAgreeCause != null && this.state.qaAgreeCause === 'no' &&
                                    <Grid container spacing={3}>
                                    <Grid item sm={11}>
                                        {!assessed &&
                                            <TextField value={this.state.qaDisagreeCauseText} onChange={this.changeValue} name="qaDisagreeCauseText"
                                            multiline={true} rows="3"
                                            variant="outlined" size="medium" fullWidth={true} disabled={assessed}
                                            label={t('whyNot')} required
                                            error={errors[t('qaDisagreeCauseText')] != null ? true : false} helperText={errors[t('qaDisagreeCauseText')]}/>
                                        }
                                        {assessed &&
                                            <FormControl>
                                                <FormLabel>{t('whyNot')}</FormLabel>
                                                {this.state.qaDisagreeCauseText}
                                            </FormControl>
                                        }
                                    </Grid>
                                </Grid>
                            }
                            <br/>
                            <SectionTitle title={t('correctiveMeasures')}/>
                            <TableContainer component={Paper}>
                                <Table aria-label="capaActions">
                                    <TableHead>
                                        <TableRow key="capaHeader">
                                            <TableCell>{t('number')}</TableCell>
                                            <TableCell>{t('title')}</TableCell>
                                            <TableCell>{t('dueDate')}</TableCell>
                                            <TableCell>{t('status')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.capaActions.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell>
                                                    <TextOnlyClickButton enabled={true} click={this.editCapa(i)} name={row.number}/>
                                                </TableCell>
                                                <TableCell>{row.title}</TableCell>
                                                <TableCell><DateDisplay value={row.dueDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                                <TableCell>{row.status}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow key="tableFooter">
                                            <TablePagination count={this.state.totalCount}
                                            rowsPerPage={this.state.listSize}
                                            onPageChange={this.handleChangePage}
                                            page = {this.state.page}
                                            rowsPerPageOptions={common.rowOptions}
                                            onRowsPerPageChange={this.handleRowsPerPage}/>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={3}>
                                <Grid item sm={8}>
                                    <Button variant="outlined" color="primary" size="small" onClick={this.addAction}>{t('add')}</Button>
                                </Grid>
                            </Grid>
                            {this.state.editCapa &&
                                <CapaAction
                                    reload={this.loadCapas}
                                    open={this.state.editCapa}
                                    onClose={this.closeDialog}
                                    deviation={this.state._id}
                                    id={this.state.capaId}
                                    users={this.state.users}
                                    qas={this.state.qas}
                                    managers={this.state.managers}
                                />
                            }
                            {assessed && 
                                <Aux>
                                    <br/>
                                    <SectionTitle title={t('conclusion')} />
                                    <Grid container spacing={3}>
                                        <Grid item sm={11}>
                                            {!signed &&
                                                <TextField value={this.state.summary} onChange={this.changeValue} name="summary"
                                                multiline={true} rows="3"
                                                variant="outlined" size="medium" fullWidth={true} disabled={signed}
                                                label={t('summary')} required
                                                error={errors[t('summary')] != null ? true : false} helperText={errors[t('summary')]}/>
                                            }
                                            {signed &&
                                                <FormControl>
                                                    <FormLabel>{t('summary')}</FormLabel>
                                                    {this.state.summary}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item sm={11}>
                                            {!signed &&
                                                <TextField value={this.state.finalQADecision} onChange={this.changeValue} name="finalQADecision"
                                                multiline={true} rows="3"
                                                variant="outlined" size="medium" fullWidth={true} disabled={signed}
                                                label={t('finalQaDecision')} required
                                                error={errors[t('finalQADecision')] != null ? true : false} helperText={errors[t('finalQADecision')]}/>
                                            }
                                            {signed &&
                                                <FormControl>
                                                    <FormLabel>{t('finalQaDecision')}</FormLabel>
                                                    {this.state.finalQADecision}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item sm={8}>
                                            <AutoCompleteField
                                                multiple={true}
                                                value={this.state.dispositionsSelected}
                                                options={this.state.dispositions}
                                                onChange={this.changeMulti('dispositionsSelected', 'disposition')}
                                                error={errors[t('dispositionsSelected')] != null ? true : false}
                                                helperText={errors[t('dispositionsSelected')]}
                                                label={t('productDisposition')}
                                                disabled={signed}
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                </Aux>
                            }
                        </Aux>
                    }
                    
                    <br/>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={8}>
                            {!this.state.signed &&
                                <Aux>
                                    <Button variant="contained" onClick={this.saveDeviation} size="small" color="primary" disabled={signed && !this.state.enableSave}>
                                        {t('save')}
                                    </Button>&nbsp;&nbsp;
                                    {this.state.security.delete &&
                                        <Button variant="contained" onClick={this.deleteToggle} size="small" color="secondary" disabled={signed && !this.state.enableSave}>
                                            {t('delete')}
                                        </Button>
                                    }
                                </Aux>
                            }
                            {(this.state.signed || this.state.assessed || this.state.rootCauseSignature != null) && security.unlock && 
                                <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.deviation} callback={this.loadRecord}/>
                            }
                        </Grid>
                    </Grid>
                </Panel>
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
                {this.state.printViewer &&
                    <PrintView
                        id={this.state._id}
                        object={this.props.auth.constants.objectIds.deviation}
                        open={this.state.printViewer}
                        onClose={this.togglePrintViewer}
                        callBack={this.loadRecord}
                        printAccess={this.state.security.print}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.deviation}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Deviation2));