import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import { CSVDownload } from "react-csv";
import dateFormat from 'date-fns/format';
import AddIcon from '@mui/icons-material/Add';
import { Grid, FormControl, FormLabel} from '@mui/material';
import Typography from '@mui/material/Typography';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, TablePagination, Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete';
import { NumericFormat } from 'react-number-format';
import ActionBar from '../UI/Buttons/ActionBar';
import ConversionHelper from '../../helpers/conversionHelper';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import listHelper from '../../helpers/listHelper';
import ListMultiSelect from '../UI/ListMultiSelect/ListMultiSelect';

const Sales = (props) => {
    const isFirstRender = useRef(true);
    const [loadRefData, setLoadRefData] = useState(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [csvOutput, setCsvOutput] = useState({headers: null, data: null});
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [columnOrder, setColumnOrder] = useState([]);
    const [contentPage, setContentPage] = useState(0);
    const [contentListSize, setContentListSize] = useState(5);
    const [values, setValues] = useState({customers: [], types: [], provs: [], countries: [], statuses: []});
    const [ customer, setCustomer ] = useState(null);
    const { t } = useTranslation('translation');
    const statuses = props.auth.constants.statuses;
    const tenant = props.auth.user.tenant;
    const csvSeparator = sessionStorage.getItem('csvSeparator');
    const [statusesSel, setStatusesSel] = useState({ids: [], values: []});
    const [showSelect, setShowSelect] = useState(false);
    const crumbs = [
        { path: 'active', label: t('sales'), screen: props.auth.screenDefs.ShipmentList}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        async function fetchData(){
            const columnFilters = await listHelper.getItem('SalesColumnFilters', tenant);
            const columnVisibility = await listHelper.getItem('SalesColumnVisibility', tenant);
            const density = await listHelper.getItem('SalesDensity', tenant);
            const globalFilter = await listHelper.getItem('SalesGlobalFilter', tenant);
            const showGlobalFilter = await listHelper.getItem('SalesShowGlobalFilter', tenant);
            const showColumnFilters = await listHelper.getItem('SalesShowColumnFilters', tenant);
            const sorting = await listHelper.getItem('SalesSorting', tenant);
            const pagination = await listHelper.getItem('SalesPagination', tenant);
            const tempColumnOrder = await listHelper.getItem('SalesColumnOrder', tenant);
            if (columnFilters) {
                let filters = ConversionHelper.filtersDateParse(JSON.parse(columnFilters));
                setColumnFilters(filters);
                let customer = filters.find(x => x.id === 'customer');
                if(customer != null && values.customers != null && values.customers.length > 0) {
                    if(customer.value != null){
                        let sel = values.customers.find(x => x.value === customer.value);
                        setCustomer(sel);
                    }
                }
                let status = filters.find(x => x.id === 'status');
                if(status != null){
                    let sel = {ids: status.value, values: []};
                    status.value.forEach(id => {
                        let val = values.statuses.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setStatusesSel(sel);
                }
            }
            if (columnVisibility) {
                setColumnVisibility(columnVisibility);
            }
            if (density) {
                setDensity(density);
            }
            if (globalFilter) {
                setGlobalFilter(globalFilter);
            }
            if (showGlobalFilter) {
                setShowGlobalFilter(showGlobalFilter);
            }
            if (showColumnFilters) {
                setShowColumnFilters(showColumnFilters);
            }
            if (sorting) {
                setSorting(sorting);
            }
            if (pagination) {
                setPagination(pagination);
            }
            if (tempColumnOrder) {
                setColumnOrder(tempColumnOrder);
            } else {
                setColumnOrder(['mrt-row-expand', 'mrt-row-select', 'number', 'date', 'status', 'type', 'expectedDelivery', 'customer', 'prov', 'country', 'purchaseOrder', 'unitsSold', 'totalAmount', 'shipments', 'attachments']);
            }
            isFirstRender.current = false;
        }
        if(isFirstRender.current){
            fetchData();
        }
    }, [values, statuses, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('SalesColumnFilters', tenant, JSON.stringify(columnFilters));
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('SalesColumnVisibility', tenant, columnVisibility);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnVisibility, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('SalesDensity', tenant, density);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [density, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('SalesGlobalFilter', tenant, globalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [globalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('SalesShowGlobalFilter', tenant, showGlobalFilter);
        }
        if (isFirstRender.current) return;
        fetchData()
    }, [showGlobalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('SalesShowColumnFilters', tenant, showColumnFilters);
        }
        if (isFirstRender.current) return;
        fetchData();

    }, [showColumnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('SalesSorting', tenant, sorting);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [sorting, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('SalesPagination', tenant, pagination);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [pagination, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('SalesColumnOrder', tenant, columnOrder);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnOrder, tenant]);

    const resetState = () => {
        listHelper.removeItem('SalesColumnFilters', tenant);
        listHelper.removeItem('SalesColumnVisibility', tenant);
        listHelper.removeItem('SalesDensity', tenant);
        listHelper.removeItem('SalesGlobalFilter', tenant);
        listHelper.removeItem('SalesShowGlobalFilter', tenant);
        listHelper.removeItem('SalesShowColumnFilters', tenant);
        listHelper.removeItem('SalesSorting', tenant);
        listHelper.removeItem('SalesPagination', tenant);
        listHelper.removeItem('SalesColumnOrder', tenant);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const provs = await ValueHelper.getCanadianProvs();
            const countries = await ValueHelper.getCountries();
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.customer, common.cacheValues.saleType], props.auth.constants, null, props.auth.user.tenant);
            const statuses = await ValueHelper.getStatusesForObject(props.auth.constants.objectIds.sale, props.auth.user.tenant);
            let customer = columnFilters.find(x => x.id === 'customer');
            if(customer != null && cacheValues.customers != null && cacheValues.customers.length > 0) {
                if(customer.value != null){
                    let sel = cacheValues.customers.find(x => x.value === customer.value);
                    setCustomer(sel);
                }
            }
            let status = columnFilters != null ? columnFilters.find(x => x.id === 'status') : null;
            if(status != null){
                let sel = {ids: status.value, values: []};
                status.value.forEach(id => {
                    let val = statuses.find(x => x.value === id);
                    sel.values.push(val);
                });
                setStatusesSel(sel);
            }
            setValues({
                customers: cacheValues.customers,
                types: cacheValues.saleTypes,
                provs: provs,
                statuses: statuses,
                countries: countries
            });
            setLoadRefData(false);
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, columnFilters, loadRefData]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'sales',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/sales', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });

    let columns = useMemo(() => [
        {
            accessorKey: 'number',
            id: 'number',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/sale/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            )
        },
        {
            accessorKey: 'date',
            id: 'date',
            header: t('date'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat)}
                </Box>
            )
        },
        {
            accessorKey: 'status',
            id: 'status',
            header: t('status'),
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='status'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.statuses} 
                    setSelected={setStatusesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={statusesSel.ids} 
                    selectedSel={statusesSel.values}
                    screen='Sales'
                    tenant={props.auth.user.tenant}
                    columnFilters={columnFilters}/>
                </Box>
            ),
        },
        {
            accessorKey: 'type',
            id: 'type',
            header: t('type'),
            filterVariant: 'select',
            filterSelectOptions: values.types
        },
        {
            accessorKey: 'expectedDelivery',
            id: 'expectedDelivery',
            header: t('expectedDelivery'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() != null ? dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat) : ''}
                </Box>
            )
        },
        {
            accessorFn: (row) => (row.customer != null ? row.customer.number + '-' + row.customer.name : ''),
            id:'customer',
            header: t('customer'),
            Filter: ({ header, column }) =>
                <AutoComplete 
                    options={values.customers}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, newValue) => {
                        setCustomer(newValue);
                        column.setFilterValue(newValue != null ? newValue.value : null);
                    }}
                    value={customer}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                />,
        },
        {
            accessorKey: 'prov',
            id: 'prov',
            header: t('prov'),
            filterVariant: 'select',
            filterSelectOptions: values.provs
        },
        {
            accessorKey: 'country',
            id: 'country',
            header: t('country'),
            filterVariant: 'select',
            filterSelectOptions: values.countries
        },
        {
            accessorKey: 'purchaseOrder',
            id: 'purchaseOrder',
            header: t('purchaseOrder'),
        },
        {
            accessorKey: 'unitsSold',
            id: 'unitsSold',
            header: t('unitsSold'),
            enableColumnFilter: false,
            enableSorting: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
            ),
        },
        {
            accessorKey: 'totalAmount',
            id: 'totalAmount',
            header: t('totalAmount'),
            enableColumnFilter: false,
            enableSorting: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
            ),
        },
        {
            accessorFn: (row) =>  row.shipments  != null ? row.shipments.length : '',
            id: 'shipments',
            header: t('shipments'),
            enableColumnFilter: false,
            enableSorting: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
            ),
        },
        {
            accessorFn: (row) => row.documents != null ? row.documents.length : '',
            id: 'attachments',
            header: t('attachments'),
            enableColumnFilter: false,
            enableSorting: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
            ),
        }
    ], [values, t, customer, props, statusesSel, columnFilters, showSelect]);

    const csv = async () => {
        setCsvOutput({headers: null, data: null});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axios.get('/api/salescsv', {params: params});
        let headers = response.data.headers;
        let csvData = response.data.data;
        setCsvOutput({headers: headers, data: csvData});
    }

    const fixTaxData = async () => {
        let ids = [];
        let selectedRows = table.getSelectedRowModel().rows;
        selectedRows.forEach((record) => {
            if(record.original.statusId === props.auth.constants.statuses.shipped || record.original.statusId === props.auth.constants.statuses.signed ) {
                ids.push(record.original._id);
            }else{
                props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('onlySignedSales'), title: t('warning'), severity: 'warning'}});
            }
        });
        if(ids?.length > 0) {
            let data = {
                ids: ids
            }
            let result = await axios.post('/api/refreshsaletax', data);
            if(result.data.message != null){
                props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                refetch();
            }else {
                props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data, title: t('error'), severity: 'error'}});
            }
        }
    }

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true,  columnVisibility: {id: false}},
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: props.auth.user.internal,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()} color='primary'>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('csv')} color="primary">
                    <IconButton onClick={() => csv()}>
                        <ExcelIcon color='primary'/>
                    </IconButton>
                </Tooltip>
                {Object.keys(table.getState().rowSelection).length > 0 && props.auth.user.internal === true &&
                    <Tooltip title={t('refreshSalesTax')} >
                        <IconButton
                            disabled={props.auth.user.internal === false}
                            component={'div'}
                            onClick={fixTaxData}
                            name='refreshSalesTax'
                            sx={{ m: 'auto 0' }}
                        >
                            <CalculateOutlinedIcon color="primary"/>
                        </IconButton>
                    </Tooltip>
                }
                <Tooltip arrow title={t('clearListSettings')}>
                    <IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                {props.permission.create &&
                    <Tooltip arrow title={t('add')}>
                        <IconButton component={Link} to='/sale'>
                            <AddIcon color="primary"/>
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        ),
        renderDetailPanel: ({ row }) => (
            <Box margin={1} component={'div'}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4} md={3} sx={{marginBottom: '16px'}}>
                        <FormControl>
                            <FormLabel>{t('customerAddress')}</FormLabel>
                        </FormControl>
                        <Typography variant='body2'>{row.original?.address1 != null && row.original?.address1}</Typography>
                        {row.address2 != null && row.original?.address2 !== '' ? <Typography variant='body2'>{row.original?.address2}</Typography> : null}
                        <Typography variant='body2'>{row.original?.address1 != null && row.original?.city}, {row.original?.prov != null && row.original?.prov.code}</Typography>
                        <Typography variant='body2'>{row.original?.postal != null && row.original?.postal}</Typography>
                        <br />
                        <FormControl>
                            <FormLabel>{t('expectedDelivery')}</FormLabel>
                        </FormControl>
                        <Typography variant='body2'>
                            {row.original?.expectedDelivery != null ? dateFormat(Date.parse(row.original?.expectedDelivery), props.auth.user.dateFormat) : t('none')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel>{t('shipments')}</FormLabel>
                        <TableContainer component={Paper} sx={{ maxWidth: 450}} >
                            <Table aria-label={row.original?._id + 'saleShipments'}>
                                <TableHead >
                                    <TableRow key={row._id + 'saleShipmentsHeader'}>
                                        <TableCell>{t('number')}</TableCell>
                                        <TableCell>{t('dateShipped')}</TableCell>
                                        <TableCell>{t('status')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.original?.shipments != null && row.original?.shipments.length > 0 ?
                                        row.original?.shipments.map((shipment, k) =>
                                            <TableRow key={shipment._id}>
                                                <TableCell><Link to={'/shipment/' + shipment._id} name={'salesShipmentLink-' + shipment._id}>{shipment.number}</Link></TableCell>
                                                <TableCell>
                                                    {shipment.date != null ? dateFormat(Date.parse(shipment.date), props.auth.user.dateFormat) : t('none') }
                                                </TableCell>
                                                <TableCell>
                                                    {shipment.status != null && shipment.status !== '' ? shipment.status.label : t('none')}
                                                </TableCell>
                                            </TableRow>
                                        ) :
                                        <TableRow>
                                            <TableCell colspan='3' align='center'>{t('noRecordsFound')}</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

                <FormLabel>{t('contents')}</FormLabel>
                <TableContainer component={Paper}>
                    <Table aria-label={row.original?._id + 'saleContents'}>
                        <TableHead >
                            <TableRow key={row.original?._id + 'saleContentsHeader'}>
                                <TableCell sx={{ width: '125px'}}>{t('number')}</TableCell>
                                <TableCell>{t('item')}</TableCell>
                                <TableCell sx={{ width: '125px'}} align='right'>{t('quantity')}</TableCell>
                                <TableCell sx={{ width: '125px'}} align='right'>{t('unitPrice')}</TableCell>
                                <TableCell sx={{ width: '125px'}} align='right'>{t('unitTax')}</TableCell>
                                <TableCell sx={{ width: '125px'}} align='right'>{t('totalPrice')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {row.original?.lines
                                .slice( contentPage * contentListSize,
                                        contentPage * contentListSize + contentListSize )
                                .map((item, k) =>
                                    <TableRow key={item._id}>
                                        <TableCell>{item.item != null && item.item.number != null ? item.item.number : ''}</TableCell>
                                        <TableCell><Link to={'/item/' + item.item._id} name={'itemLink' + k}>{item.item.name}</Link></TableCell>
                                        <TableCell align='right'>
                                            {item.uom != null ? item.quantity.toString() + item.uom.label : item.quantity}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {item.price != null ? <NumericFormat value={item.price} prefix={'$'} displayType={'text'} thousandSeparator={true} decimalScale={2}/> : '$0'}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {item.tax != null ? <NumericFormat value={item.tax} prefix={'$'} displayType={'text'} thousandSeparator={true} decimalScale={2}/> : '$0'}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {item.total != null ? <NumericFormat value={item.total} prefix={'$'} displayType={'text'} thousandSeparator={true} decimalScale={2}/> : '$0'}
                                        </TableCell>
                                    </TableRow>
                            )}
                        </TableBody>
                        {row.original?.lines.length > 0 &&
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={row.original?.lines.length}
                                    rowsPerPage={contentListSize}
                                    onPageChange={handleContentChangePage}
                                    page = {contentPage}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={handleContentRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        }
                    </Table>
                </TableContainer>
            </Box>
          ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });

    const handleContentRowsPerPage = e => {
        setContentListSize(parseInt(e.target.value));
        refetch();
    };

    const handleContentChangePage = (event, newPage) => {
        setContentPage(newPage);
    };

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs}>
                <ActionBar/>
            </BreadcrumbBar>
            {csvOutput != null && csvOutput.data != null &&
                <CSVDownload data={csvOutput.data} target="_blank" headers={csvOutput.headers} separator={csvSeparator}/>
            }
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
        </Aux>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Sales);