// TODO


class IdleTimer {
    constructor({ idleWarning, idleWarningTimeout, idleTimeout, expiry,
                  screenLock, onIdleWarning, onIdleTimeout, onExpired }) {
        this.idleWarning = idleWarning;
        this.idleWarningTimeout = idleWarningTimeout;
        this.idleTimeout = idleTimeout;
        this.expiry = expiry;
        this.screenLock = screenLock;
        this.onIdleWarning = onIdleWarning;
        this.onIdleTimeout = onIdleTimeout;
        this.onExpired = onExpired;

        const expiredTime = parseInt(sessionStorage.getItem('_expiredTime'), 10);
        if (expiredTime > 0 && expiredTime < Date.now()) {
            onExpired();
            return;
        }

        this.eventHandler = this.updateExpiredTime.bind(this);
        this.tracker();
        this.startInterval();
    }

    startInterval() {
        this.updateExpiredTime();
        this.interval = setInterval(() => {
            const time = Date.now();
            const idleWarnTime = sessionStorage.getItem("_idleWarn");
            const idleTime = sessionStorage.getItem("_idleTime");
            const expirationTime = sessionStorage.getItem("_expiredTime");
            //console.info(`Expired?: ${time >= expirationTime}`);
            if(time >= expirationTime) {
                //Logout
                //console.info('Timer - Expired');
                if (this.onExpired) {
                    this.onExpired();
                    this.cleanUp();
                }
                return;
            }else if(!this.screenLock && (time < expirationTime && time >= idleTime)){
                //Screen Lock
                //console.info('Timer - Screen lock');
                if (this.onIdleTimeout) {
                    this.onIdleTimeout();
                }
                return;
            }else if (!this.idleWarning && (time >= idleWarnTime && time < idleTime)) {
                //Idle Warning
                //console.info('Timer - Idle Warning');
                if(this.onIdleWarning) {
                    this.onIdleWarning();
                }
                return; 
            } 
        }, 60500);
    }

    updateExpiredTime() {
        if (this.timeoutTracker) {
            clearTimeout(this.timeoutTracker);
        }
        this.timeoutTracker = setTimeout(() =>{
            const time = Date.now();
            const expiredTime = time + this.expiry;
            const idleTime = time + this.idleTimeout;
            const warnTime = idleTime - this.idleWarningTimeout;
            
            sessionStorage.setItem("_idleWarn", warnTime);
            sessionStorage.setItem("_idleTime", idleTime);
            sessionStorage.setItem("_expiredTime", expiredTime);            
        }, 300);        
    }

    tracker() {
        window.addEventListener("mousemove", this.eventHandler);
        window.addEventListener("scroll", this.eventHandler);
        window.addEventListener("keydown", this.eventHandler);
    }

    cleanUp() {
        clearInterval(this.interval);
        sessionStorage.removeItem("_expiredTime");
        sessionStorage.removeItem("_idleTime");
        sessionStorage.removeItem("_idleWarn");
        window.removeEventListener("mousemove", this.eventHandler);
        window.removeEventListener("scroll", this.eventHandler);
        window.removeEventListener("keydown", this.eventHandler);
    }
}

export default IdleTimer;