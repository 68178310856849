import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import SectionTitle from '../UI/Typography/SectionTitle';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';

class Recalls extends Component {
    constructor(props){
        super(props);

        this.state = {
            recalls: [],
            offset: 0,
            listSize: this.props.listSize ? this.props.listSize : 5,
            pageCount: 1,
            date: null,
            number: '',
            status: '',
            statuses: [],
            orderBy: 'initiatedDate',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            statusSel: null
        }
        this.dateChange = this.dateChange.bind(this);
        this.changeValue = this.changeValue.bind(this);
    }

    async componentDidMount(){
        if(!this.props.auth.isAuthenticated) {
            this.props.history.push('/login');
        }
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({statuses: cacheValues.statuses}, () => {
            this.loadRecords();
        });

    }

    loadRecords(){
        var sort = {[this.state.orderBy]: this.state.ordDir};
        var url = '/api/recalls';
        const data = {
            offset: this.state.offset,
            size: this.state.listSize,
            date: this.state.date,
            number: this.state.number,
            status: this.state.status,
            sort: JSON.stringify(sort)
        }
        axios.get(url, {params: data})
        .then(result => {
            this.setState({
                recalls: result.data.data,
                pageCount: Math.ceil(result.data.totalCount / this.state.listSize),
                totalCount: result.data.totalCount
            });

        })
        .catch(err => {
          this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        });
    }

    changeValue(e){
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => this.loadRecords());
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    dateChange(e){
        this.setState({date: e, page: 0, offset: 0}, () => this.loadRecords());
    }

    searchClick(){
        this.loadRecords();
    }

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return (
            <Aux>
                {this.props.title != null &&
                    <SectionTitle title={this.props.title}/>
                }
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <DateSelect
                            onChange={this.dateChange}
                            value={this.state.date}
                            label={t('date')}
                            format={this.props.auth.user.dateFormat}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <TextField value={this.state.number} onChange={this.changeValue} name="number" size="medium"
                        label={t('number')}/>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <AutoCompleteField
                            value={this.state.statusSelected}
                            options={this.state.statuses}
                            onChange={this.changeAuto('statusSelected', 'status')}
                            label={t('status')}
                        />
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="Recall Log" size={this.props.title != null ? 'small' : 'medium'}>
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('number')}
                                    active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('initiatedDate')}
                                    active={this.state.orderBy === 'initiatedDate'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('actualCompletion')}
                                    active={this.state.orderBy === 'actualCompletion'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('close')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('status')}
                                    active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.recalls.map((recall, i) =>
                                <TableRow key={recall._id}>
                                    <TableCell><Link to={"/recall/" + recall._id} name={'recallLink' + i}>{recall.number}</Link></TableCell>
                                    <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={recall.initiatedDate}/></TableCell>
                                    <TableCell>{recall.description}</TableCell>
                                    <TableCell>{recall.actualCompletion != null ? <DateDisplay format={this.props.auth.user.dateFormat} value={recall.actualCompletion}/> : ''}</TableCell>
                                    <TableCell>{recall.status.label}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(Recalls));
