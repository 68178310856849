import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ModalDialog from '../UI/Dialog/ModalDialog';
import { EvoraTabs, TabCanvas } from '../UI/Tabs/Tabs';
import ScreenDef from './Components/ScreenDef';
import HelpSettings from './Components/Help';
import Screen from './Components/Screen';

class ScreenSettings extends Component { 
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            enableSave: true,
            screenName: '',
            tabKey: 0,
            tabLabels: [
                {name: t('definition'), disabled: false},
                {name: t('help'), disabled: false},
                {name: t('security'), disabled: false}
            ],
            general: {},
            help: {},
            security: {},
        }
    }

    componentDidMount = () => {
        this.loadRecord();
    }

    loadRecord = async () => {
        const data = { path: window.location.hash };
        const values = await axios.post('/int/screensettings', data);

        this.setState({
            general: values.data.general,
            help: values.data.help,
            security: values.data.security,
            screenName: values.data.general.name
        });
    }

    changeSettingsTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    render() {
        const { open, toggleDialog, t } = this.props;
        let { general, help, security, tabKey, tabLabels, screenName } = this.state;
        return (
            <ModalDialog title={screenName + ' ' + t('settings')} toggleDialog={toggleDialog()}
                         dialogStatus={open} fullWidth maxWidth={tabKey === 0 ? 'sm' : tabKey === 1 ? 'lg' : 'md'}>
                <EvoraTabs tabLabels={tabLabels} tabKey={tabKey} changeTab={this.changeSettingsTab} />
                <TabCanvas value={tabKey} index={0}>
                    <ScreenDef settingsData={general} callBack={() => this.loadRecord()} toggleDialog={() => toggleDialog()} />
                </TabCanvas>
                <TabCanvas value={tabKey} index={1}>
                    <HelpSettings settingsData={help} callBack={() => this.loadRecord()} toggleDialog={() => toggleDialog()} />
                </TabCanvas>
                <TabCanvas value={tabKey} index={2}>
                    <Screen settingsData={security} callBack={() => this.loadRecord()} toggleDialog={() => toggleDialog()} />
                </TabCanvas>
            </ModalDialog>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(ScreenSettings));