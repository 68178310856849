import React, { Component } from 'react';
import axios from 'axios';
import SopClientInstances from './SopClientInstances';
import { connect } from 'react-redux';
import { setSopId } from '../../actions/sop';
import { withTranslation } from 'react-i18next';

class SopClient extends Component {
    constructor(props){
        super(props);
        const {params} = this.props.match;
        this.state = {
            sop: {},
            sopId: params.id
        }
    }

    componentDidMount(){
        const {params} = this.props.match;
        this.props.setSopId(params.id);
        axios.get('/api/sops/' + params.id + "?pageNo=1,size=10")
        .then(response => {
            this.setState({
                sop: response.data
            });
        })
        .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        })
    }

    render(){
        return(
            <div className="container">
                <h3>{this.state.sop.name}</h3>
                <br/>
                <SopClientInstances sopId={this.state.sopId}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
  });
  
  export default withTranslation() (connect(mapStateToProps, { setSopId })(SopClient));