import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { Grid, FormControl, FormLabel } from '@mui/material';
import UserCertifications from '../General/UserCertifications';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ActionBar from '../UI/Buttons/ActionBar';
class ManageUser extends Component{
    constructor(props){
        super(props);
        this.state = {
            user: null
        }
    }

    async componentDidMount(){
        let user = await axios.get('/api/users/' + this.props.match.params.id);
        this.setState({
            user: user.data
        }, () => {
            const crumbs = [
                { path: '/#/manageusers', label: this.props.t('users'), screen: this.props.auth.screenDefs.ManageUsers},
                { path: 'active', label: user.data.firstName + ' ' + user.data.lastName, screen: this.props.auth.screenDefs.ManageUser}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />                
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <FormControl>
                                <FormLabel>{t('user')}</FormLabel>
                                {this.state.user != null ? this.state.user.firstName + ' ' + this.state.user.lastName : ''}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <UserCertifications id={this.props.match.params.id}/>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(ManageUser));