import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Panel from '../UI/Panel/Panel';
import TextField from '../UI/TextField/TextField';
import isEmpty from '../../is-empty';
import Title from '../UI/Typography/Title';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import { encryptString } from '../../helpers/securityHelper';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';

class ChangePassword extends Component {
    constructor(props){
        super(props)

        this.state = {
            originalPassword: '',
            password1: '',
            password2: '',
            errors: {}
        }

    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const t = this.props.t;
        let errors = {};
        if(this.state.originalPassword == null || this.state.originalPassword === '')
            errors.originalPassword = t('required');
        if(this.state.password1 == null || this.state.password1 === '')
            errors.password1 = t('required');
        else {
            if(this.state.password1.length < 8 || this.state.password1.length > 15)
                errors.password1 = t('passwordLength');
        }
        if(this.state.password2 == null || this.state.password2 === '')
            errors.password2 = t('required');
        else {
            if(this.state.password1 !== this.state.password2)
                errors.password1 = t('passwordNotMatch');
            if(this.state.originalPassword === this.state.password1)
                errors.password1 = t('passwordOrigError');
        }
        
        if(!isEmpty(errors)){
            this.setState({errors: errors});
            return;
        }
        let passwordHash = encryptString(this.state.originalPassword);
        let newPasswordHash = encryptString(this.state.password1);
        let data = {
            _id: this.props.auth.user.id,
            password: passwordHash,
            newPassword: newPasswordHash
        }
        axios.post('/api/user/changepassword', data)
        .then(result => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            this.props.history.goBack();
        })
        .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        })
    }

    returnClick = (e) => {
        this.props.history.goBack();
    }

    render(){
        const errors = this.state.errors;
        const t = this.props.t;
        return(
            <Aux>
                <Title title={t('changePassword')}/>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.originalPassword} onChange={this.changeValue} name="originalPassword"
                            label={t('originalPassword')} margin="normal" variant="outlined" type="password" autoComplete="original-password"
                            helperText={errors.originalPassword}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField required value={this.state.password1} onChange={this.changeValue} name="password1"
                            label={t('newPassword')} margin="normal" variant="outlined" type="password" autoComplete="new-password"
                            helperText={errors.password1}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField required value={this.state.password2} onChange={this.changeValue} name="password2"
                            label={t('repeatPassword')} margin="normal" variant="outlined" type="password" autoComplete="repeat-password"
                            helperText={errors.password2}/>
                        </Grid>
                    </Grid>
                    <br/>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <Button variant="contained" color="primary" size="small" onClick={this.handleSubmit}>{t('changePassword')}</Button>&nbsp;&nbsp;
                            <Button variant="contained" color="secondary" size="small" onClick={this.returnClick}>{t('return')}</Button>
                        </Grid>
                    </Grid>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(ChangePassword));