import React, { Component } from 'react'
import axios from 'axios';
import isEmpty from '../is-empty';
import TextField from './UI/TextField/TextField';
import Title from './UI/Typography/Title';
import Aux from '../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import { encryptString } from '../helpers/securityHelper';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import { withTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

class ResetPassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            repeatPassword: '',
            emailVerified: false,
            errors: {}
        }
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    checkEmail = async (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.email == null || this.state.email === '')
            errors.email = t('required');
        if(!isEmpty(errors)){
            this.setState({errors: errors});
            return;
        }
        let result = await axios.post('/users/resetemail', {email: this.state.email});
        if(result.data.valid){
            this.setState({emailVerified: true});
        } else {
            this.setState({errors: {email: t('invalidEmail')}});
        }
    };

    doReset = async (e) => {
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.password == null || state.password === '')
            errors.password = t('required');
        else {
            if(state.password.length < 8 || state.password.length > 15)
              errors.password = t('passwordLength');
            }
        if(state.repeatPassword == null || state.repeatPassword === '')
            errors.repeatPassword = t('required');
        else {
            if(state.password !== state.repeatPassword)
                errors.repeatPassword = t('passwordNotMatch');
        }
        if(!isEmpty(errors)){
            this.setState({errors: errors});
            return;
        }
        let passwordHash = encryptString(state.password);
        let data = {email: state.email, password: passwordHash};
        try {
            await axios.post('/users/completereset', data);
            this.props.history.push('/');
        } catch(err){
            this.setState({errors: {password: err.response.data}});
        }
    };

    cancel = (e) => {
        this.props.history.push('/');
    };

    render(){
        const errors = this.state.errors;
        const t = this.props.t;
        return(
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} sx={(theme) => ({
                    backgroundImage: `url(${process.env.PUBLIC_URL}/Tablet4K.jpg)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor:
                        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                })} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box sx={(theme) => ({margin: theme.spacing(8, 4),
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center'
                    })}>
                        <Title title={t('resetPassword')}/>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={8}>
                                <TextField value={this.state.email} onChange={this.changeValue} name="email"
                                size="medium" fullWidth={true} label={t('email')} 
                                error={errors.email != null ? true : false} helperText={errors.email}/>
                            </Grid>
                            {!this.state.emailVerified &&
                                <Grid item xs={4} sm={3}>
                                    <Button variant="contained" color="primary" size="small" onClick={this.checkEmail}>{t('verifyEmail')}</Button>
                                </Grid>
                            }
                        </Grid>
                        {this.state.emailVerified &&
                            <Aux>
                                <Grid container spacing={3}>
                                    <Grid item xs={8} sm={4}>
                                        <TextField value={this.state.password} onChange={this.changeValue} name="password"
                                        size="medium" fullWidth={true} label={t('newPassword')} type="password" 
                                        error={errors.password != null ? true : false} helperText={errors.password}/>
                                    </Grid>
                                    <Grid item xs={8} sm={4}>
                                        <TextField value={this.state.repeatPassword} onChange={this.changeValue} name="repeatPassword"
                                        size="medium" fullWidth={true} label={t('repeatPassword')} type="password" 
                                        error={errors.repeatPassword != null ? true : false} helperText={errors.repeatPassword}/>
                                    </Grid>
                                </Grid>
                                <br/>
                                <Grid container spacing={3}>
                                    <Grid item sm={10}>
                                        <Button variant="contained" color="primary" size="small" onClick={this.doReset}>{t('submit')}</Button>&nbsp;&nbsp;
                                        <Button variant="contained" color="secondary" size="small" onClick={this.cancel}>{t('cancel')}</Button>
                                    </Grid>
                                </Grid>
                                <br/>
                                <br/>
                            </Aux>
                        }
                    </Box>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation()(ResetPassword);