import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { destructionFieldSecurity } from '../../helpers/securityHelper';
import { DeleteButton, TextOnlyClickButton, SaveButton } from '../UI/Buttons/Buttons';
import Unlock from '../General/Unlock';
import SectionTitle from '../UI/Typography/SectionTitle';
import { EvoraTabs, TabCanvas, TabPanel } from '../UI/Tabs/Tabs';
import ActivityWorkRecord from '../Cultivation/ActivityWorkRecord';
import ActionBar from '../UI/Buttons/ActionBar';
import { Link } from 'react-router-dom';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel, IconButton } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ItemLedgerComponent from '../Inventory/ItemLedgerComponent';
import { NumericFormat } from 'react-number-format';
import ValueHelper from '../../helpers/valueHelper';
import Button from '@mui/material/Button';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import common from '../../jsons/common.json';
import ModalDialog from '../UI/Dialog/ModalDialog';
import Panel from '../UI/Panel/Panel';
import CopyIcon from '@mui/icons-material/ContentCopyTwoTone';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import ConversionHelper from '../../helpers/conversionHelper';

class Destruction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            original: {},
            _id: '',
            date: new Date(),
            substanceTypes: [],
            containers: [],
            netWeight: '',
            netWeightUOM: '',
            location: '',
            method: '',
            user: null,
            witness: null,
            rpic: null,
            rpics: [],
            users: [],
            userList: [],
            usersSel: [],
            documentId: '',
            containerList: [],
            items: [],
            item: '',
            itemSel: null,
            allContainers: [],
            locations: [],
            methods: [],
            container: '',
            uoms: [],
            editContainer: false,
            containerIndex: -1,
            schedule: null,
            taskRisk: false,
            taskRisks: [],
            taskRiskAssessment: '',
            scheduleBase: null,
            tenant: this.props.auth.user.tenant,
            originalAmount: '',
            originalAmountUOM: '',
            number: '',
            locationSelection: null,
            methodSelection: null,
            taskRiskAssessmentSel: null,
            containerSel: null,
            security: [],
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            sopIds: [],
            sopsSel: [],
            sopList: [],
            tabKey: 0,
            tabLabels: [{name: this.props.t('general'), disabled: false}],
            renderNum: Math.random(),
            containerId: '',
            optionsMenu: [],
            flagForSignature: 0,
            count: '',
            hasCount: false,
            containerHasCount: false,
            itemTotals: [],
            dialogOpen: false,
            enableSave: true,
            showBin: false,
            binOptions: [],
            bins: [],
            binsSel: [],
            notWeight: false,
            sigData: null,
            activeUsers: [],
            activeManagers: [],
            activeQas: [],
            form: '',
            hasForm: false,
            enableDelete: false,
            containerType: '',
            containerTypeSel: null,
            containerTypes: [],
            printClick: null,
            finalDocument: null,
            goBack: -1,
            status: '',
            statusSel: null,
            statuses: [],
            deleteOpen: false,
            enableUnlock: false,
            hasLedgers: false
        }
        this.sigPad = {};
        this.dateChanged = this.dateChanged.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.substancesChanged = this.substancesChanged.bind(this);
        this.userDateChanged = this.userDateChanged.bind(this);
        this.witnessDateChanged = this.witnessDateChanged.bind(this);
        this.saveClick = this.saveClick.bind(this);
        this.changeNumber = this.changeNumber.bind(this);
        this.lotsChanged = this.lotsChanged.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.allItems, common.cacheValues.activeLocation, common.cacheValues.quantityUOM, common.cacheValues.user,
                                common.cacheValues.containerType, common.cacheValues.destructionForm, common.cacheValues.destructionMethod, common.cacheValues.destructionSop, 
                                common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        let items = cacheValues.allItems;
        let allUsers = cacheValues.users;
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.destruction, this.props.auth.user.tenant);
        let params = queryString.parse(this.props.location.search);
        let scheduleId = params != null && params.schedule != null ? params.schedule : null;
        let schedule = null;
        let locationSel = null;
        let location = '';
        let goBack = -1;
        if(scheduleId != null){
            goBack = -2;
            schedule = await axios.get('api/schedulebase/' + scheduleId);
            if(schedule.data.riskAssessmentRequired){
                this.getTaskRiskAssessments();
            }
            locationSel = locations.find(x => x.value === schedule.data.location);
            location = schedule.data.location;
        } else {
            if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
                location = this.props.auth.user.defaultLocation;
                locationSel = locations.find(x => x.value === location);
            } else {
                if(locations.length === 1){
                    locationSel = locations[0];
                    location = locationSel.value;
                }
            }
        }
        let methodSelected = ValueHelper.valueSelect(null, cacheValues.destructionMethodss);
        let workRecords = [];
        let sopIds = [];
        let sopsSel = [];
        cacheValues.sops.forEach(sop => {
            if(sop.required === true){
                workRecords.push({
                    sop: sop.value,
                    original: true
                });
                sopIds.push(sop.value);
                sopsSel.push(sop);
            }
        });
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let activeQas = [];
        userValues.approve.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeQas.push(row);
            }
        })
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        let fieldAccess = this.props.permission.create === true ? true : false;
        this.setState({
            _id: this.props.match.params.id != null || this.props.match.params.id !== '' ? this.props.match.params.id : '',
            userList: userValues.create,
            locations: locations,
            items: items,
            methods: cacheValues.destructionMethods,
            containerTypes: cacheValues.containerTypes,
            rpics: userValues.review,
            uoms: cacheValues.quantityUOMs,
            schedule: params != null && params.schedule != null ? params.schedule : null,
            scheduleBase: schedule != null ? schedule.data : null,
            locationSelection: locationSel,
            location: location,
            sops: workRecords,
            sopList: cacheValues.sops,
            sopIds: sopIds,
            sopsSel: sopsSel,
            security: this.props.permission,
            methodSelection: methodSelected != null ? methodSelected : null,
            method: methodSelected != null ? methodSelected.value : '',
            activeUsers: activeUsers,
            activeManagers: activeManagers,
            activeQas: activeQas,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            allUsers: allUsers,
            goBack: goBack,
            statuses: cacheValues.statuses,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            fieldAccess: fieldAccess
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/destructions', label: this.props.t('destructions'), screen: this.props.auth.screenDefs.Destruction},
                    { path: 'active', label: this.props.t('destruction'), screen: this.props.auth.screenDefs.Destruction}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        let id = this.state._id;
        const internal = this.props.auth.user.internal;
        const  destruction = await axios.get('/api/destruction/' + id);
        let locationSelection = this.state.locations.find(x => x.value === destruction.data.location);
        let methodSelection = ValueHelper.valueSelect(destruction.data.method, this.state.methods);
        let statusSel = this.state.statuses.find(x => x.value === destruction.data.status);
        let fieldAccess = destructionFieldSecurity(destruction.data.status, this.props.permission, this.props.auth.constants.statuses);
        if(destruction.data.schedule != null  && destruction.data.schedule.riskAssessmentRequired){
            await this.getTaskRiskAssessments(destruction.data.taskRiskAssessment);
        }
        let sopIds = [];
        let sopsSel = [];
        let tabLabels = [
            {name: t('general'), disabled: false}
        ];
        destruction.data.sops.forEach(sop => {
            sopIds.push(sop.sop);
            let val = this.state.sopList.find(x => x.value === sop.sop);
            sopsSel.push(val);
            tabLabels.push({name: val.label, disabled: false});
        });
        if(destruction.data.hasLedgers != null){
            tabLabels.push({name: t('itemLedgers'), disabled: false});
        }
        let usersSel = [];
        let userList = this.state.userList;
        if(destruction.data.users != null && destruction.data.users.length > 0){
            destruction.data.users.forEach(row => {
                let val = this.state.userList.find(x => x.value === row);
                if(val != null){
                    usersSel.push(val);
                } else {
                    val = this.state.allUsers.find(x => x.value === row);
                    if(val != null){
                        usersSel.push(val);
                        userList.push(val);
                    }
                }
            });
        }
        let enableDelete = destruction.data._id != null && destruction.data._id !== '' && this.props.permission.delete === true && destruction.data.user == null &&
            destruction.data.witness == null && destruction.data.rpic == null ? true : false;
        const completeSigSec = destruction.data._id != null && destruction.data._id !== '';
        const witnessSigSec = destruction.data.user != null && destruction.data.user._id != null &&
                              destruction.data.user.signature != null && destruction.data.user.signature !== '';
        const rpicSigSec = destruction.data.witness != null && destruction.data.witness._id != null &&
                           destruction.data.witness.signature != null && destruction.data.witness.signature !== '';
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: usersSel, name: t('complete'), object: this.state._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: destruction.data.user,
                objectType: this.props.auth.constants.objectIds.destruction, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, saveCallback: this.saveCallback
            },
            {
                security: witnessSigSec, user: this.props.auth.user.id, users: !internal && this.state.witness == null ? this.state.rpics : this.state.userList, name: t('witness'),
                title: t('witnessed'), setId: () => this.loadRecord(), signature: destruction.data.witness, object: this.state._id,
                objectType: this.props.auth.constants.objectIds.destruction, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: rpicSigSec, user: this.props.auth.user.id, users: !internal && this.state.rpic == null ? this.state.activeManagers : this.state.rpics, name: t('approve'),
                title: t('authorizedBy'), setId: () => this.loadRecord(), object: destruction.data._id, signature: destruction.data.rpic,
                objectType: this.props.auth.constants.objectIds.destruction, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        let hasForm = this.state.hasForm;
        if(!hasForm && destruction.data.form != null && destruction.data.form !== '')
            hasForm = true;
        const dotLevel1 = (destruction.data._id != null && destruction.data._id !== '') &&
                          (destruction.data.user == null && destruction.data.witness == null && destruction.data.rpic == null);
        const dotLevel2 = (destruction.data._id != null && destruction.data._id !== '') &&
                          (destruction.data.user != null && destruction.data.witness == null && destruction.data.rpic == null);
        const dotLevel3 = (destruction.data._id != null && destruction.data._id !== '') &&
                          (destruction.data.user != null && destruction.data.witness != null && destruction.data.rpic == null);
        const flagForSignature = dotLevel1 ? 1 :
                                 dotLevel2 ? 2 :
                                 dotLevel3 ? 3 : 0;
        let params = queryString.parse(this.props.location.search);
        this.setState({
            original: destruction.data,
            _id: destruction.data._id,
            date: destruction.data.date,
            location: destruction.data.location,
            method: destruction.data.method,
            documentId: destruction.data.documentId,
            user: destruction.data.user,
            witness: destruction.data.witness,
            containers: destruction.data.containers,
            rpic: destruction.data.rpic,
            locationSelection: locationSelection,
            methodSelection: methodSelection,
            scheduleBase: destruction.data.schedule != null ? destruction.data.schedule : null,
            taskRisk: destruction.data.schedule != null ? destruction.data.schedule.riskAssessmentRequired : false,
            number: destruction.data.number,
            fieldAccess: fieldAccess,
            sops: destruction.data.sops,
            sopIds: sopIds,
            sopsSel: sopsSel,
            tabLabels: tabLabels,
            renderNum: Math.random(),
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            users: destruction.data.users != null ? destruction.data.users : [],
            usersSel: usersSel,
            itemTotals: destruction.data.itemTotals,
            sigData: null,
            form: destruction.data.form != null ? JSON.parse(destruction.data.form) : null,
            hasForm: hasForm,
            enableDelete: enableDelete,
            userList: userList,
            printClick: '/#/destructionprint/' + destruction.data._id,
            finalDocument: destruction.data.finalDocument,
            status: destruction.data.status,
            statusSel: statusSel,
            deleteOpen: false,
            hasLedgers: destruction.data.hasLedgers
        }, () => {
            const crumbs = [
                { path: '/#/destructions', label: this.props.t('destructions'), screen: this.props.auth.screenDefs.Destruction},
                { path: 'active', label: this.props.t('destruction') + ' - ' + destruction.data.number, screen: this.props.auth.screenDefs.Destruction}
            ]
            this.determineUnlock();
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            if(params.tab != null)
            this.openNotification(params);
        });
    }

    determineUnlock = () => {
        let enableUnlock = false;
        if(this.props.permission.unlock && !this.state.fieldAccess){
            if(this.props.auth.user.lockHistory !== true || this.props.auth.user.isSuper){
                enableUnlock = true;
            } else {
                let actDate = Date.parse(this.state.date);
                let lockDate = Date.parse(this.props.auth.user.lockDate);
                if(actDate > lockDate)
                    enableUnlock = true;
            }
        }
        this.setState({enableUnlock: enableUnlock});
    }

    openNotification = (params) => {
        let tabKey = this.state.tabKey;
        if(params != null && params.tab != null)
            tabKey = Number(params.tab);
        this.setState({tabKey});
    }

    async getTaskRiskAssessments(selected){
        let data = {
            user: this.props.auth.user.id,
            objectType: this.props.auth.constants.objectIds.destruction
        };
        const riskAsses = await axios.get('/api/taskriskselectlist', {params: data});
        let taskRiskSel = null;
        if(selected != null && selected !== '')
            taskRiskSel = riskAsses.data.find(x => x.value === selected);
        this.setState({taskRisks: riskAsses.data, taskRiskAssessmentSel: taskRiskSel});
    }

    changeValue(e){
        let name = e.target.name;
        let value = e.target.value;
        if(name === "netWeight"){
            if(isNaN(value)){
                return;
            }
        }
        this.setState({
            [name]: value
        });
    }

    changeAuto = (name, data) => async (e, newValue) => {
        const t = this.props.t;
        const constants = this.props.auth.constants;
        let taskRisk = this.state.taskRisk;
        let notWeight = this.state.notWeight;
        let netWeight = this.state.netWeight;
        if(data === 'taskRiskAssessment'){
            if (newValue != null && newValue.value != null && newValue.value !== "")
                taskRisk = true;
        }
        let originalAmount = this.state.originalAmount;
        let originalAmountUOM = this.state.originalAmountUOM;
        let netWeightUOM = this.state.netWeightUOM;
        let allContainers = this.state.allContainers;
        let containerHasCount = this.state.containerHasCount;
        let count = this.state.count;
        if(data === 'container' && newValue != null){
            let container = await axios.get('/api/container/' + newValue.value);
            originalAmount = container.data.quantity;
            originalAmountUOM = container.data.uom;
            netWeightUOM = container.data.uom;
            let containers = this.state.containers;
            let existing = containers.findIndex(x => x.container._id === newValue.value);
            if(existing !== this.state.containerIndex){
                this.props.dispatch({
                    type: 'SET_ERROR', 
                    payload: {
                        [t('container')]: t('alreadyExists')
                    }
                })
                return;
            }
            if(container.data.uom._id === constants.quantityUOMs.each || container.data.uom._id === constants.quantityUOMs.case){
                netWeight = container.data.quantity;
                notWeight = true;
            } else {
                notWeight = false;
            }
        } else if (data === 'item' && newValue != null){
            let containerRecs = await axios.post('/api/itemcontainers', {items: [newValue.value], available: false});
            allContainers = containerRecs.data;
            containerHasCount = newValue.hasCount;
        }
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            taskRisk: taskRisk,
            originalAmount: originalAmount,
            originalAmountUOM: originalAmountUOM,
            netWeightUOM: netWeightUOM,
            allContainers: allContainers,
            containerHasCount: containerHasCount,
            count: count,
            notWeight: notWeight,
            netWeight: netWeight
        })
    };

    changeMulti = (name, data) => (e, newValue) => {
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            [name]: newValue,
            [data]: ids
        })
    };

    changeSop = (e, newValue) => {
        let sops = this.state.sops;
        let tabLabels = this.state.tabLabels;
        if(newValue != null){
            if(sops.length > newValue.length){
                for(let i = 0; i < sops.length; i++){
                    let sop = sops[i];
                    let rec = newValue.find(x => x.value === sop.sop);
                    if(!rec){
                        sops.splice(i, 1);
                        tabLabels.splice(i + 1, 1);
                        break;
                    }
                }
            } else {
                for(let i = 0; i < newValue.length; i++){
                    let val = newValue[i];
                    let rec = sops.find(x => x.sop === val.value)
                    if(!rec){
                        sops.push({
                            sop: val.value,
                            workRecord: null,
                            original: false
                        });
                        if(this.state._id != null && this.state._id !== '')
                            tabLabels.push({name: val.label, disabled: false});
                    }
                }
            }
            let sopIds = [];
            newValue.forEach(val => {
                sopIds.push(val.value);
            });
            this.setState({
                sopIds: sopIds,
                sopsSel: newValue,
                sops: sops
            });
        } else {
            for(let i = 0; i < sops.length; i++){
                let sop = sops[i];
                if(sop.required)
                    return;
            }
            this.setState({sops: [], sopIds: [], sopsSel: [], tabLabels: [{name: this.props.t('clones'), disabled: false}]});
        }
    };

    changeNumber(name, e){
        this.setState({[name]: e.value});
    }

    dateChanged(e){
        this.setState({date: e});
    }

    userDateChanged(e){
        this.setState({userDate: e});
    }

    witnessDateChanged(e){
        this.setState({witnessDate: e});
    }

    substancesChanged(e, newValue){
        let selected = [];
        newValue.forEach(value => {
            selected.push(value.value);
        })
        this.setState({
            substanceTypes: selected,
            substancesSelected: newValue
        });
    }

    lotsChanged(e, newValue){
        let selected = [];
        newValue.forEach(value => {
            selected.push(value.value);
        })
        this.setState({
            lots: selected,
            lotsSelected: newValue
        });
    }

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.saveClick()})
    };

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const t = this.props.t;
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        const data = {
            _id: this.state._id,
            date: this.state.date,
            item: this.state.item,
            location: this.state.location,
            method: this.state.method,
            schedule: this.state.schedule,
            taskRiskAssessment: this.state.taskRiskAssessment,
            sops: this.state.sops,
            users: this.state.users,
            sigData: this.state.sigData,
            form: form != null && form !== '' ? JSON.stringify(form) : null
        }
        try{
            let result = await axios.post('/api/destruction', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/destruction/' + result.data.id);
            this.setState({
                _id: result.data.id,
                number: result.data.number,
                enableSave: true,
                sigData: null
            }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success' }});
                this.loadRecord();
            });
        } catch(err) {
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    validateForm(){
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(this.state.date == null || this.state.date === '')
            errors[t('date')] = t('required');
        else {
            let date = Date.parse(this.state.date);
            if(date == null){
                errors[t('date')] = t('required');
            } else {
                let validEndDate = ConversionHelper.validActivityDate(this.state.date, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
                if(!validEndDate){
                    errors[t('date')] = t('dateBeforeLock');
                }
            }
        }
        if(this.state.users == null || this.state.users.length === 0)
            errors[t('users')] = t('required');
        if(this.state.location == null || this.state.location === '')
            errors[t('location')] = t('required');
        if(this.state.method == null || this.state.method === '')
            errors[t('method')] = t('required');
        if(this.state.user != null && this.state.user !== ''){
            if(this.state.witness != null && this.state.witness !== '' && this.state.user === this.state.witness)
                errors[t('witness')] = t('witnessError');
            if(this.state.rpic != null && this.state.rpic !== '' && this.state.rpic === this.state.user)
                errors[t('rpic')] = t('rpicError');
        }
        if(this.state.witness != null && this.state.witness !== '' && this.state.rpic != null && this.state.rpic !== ''){
            if(this.state.witness === this.state.rpic)
                errors[t('rpic')] = t('rpicError');
        }
        if(state.scheduleBase != null && state.scheduleBase.riskAssessmentRequired && (state.taskRiskAssessment == null ||
            state.taskRiskAssessment === ''))
            errors[t('taskRiskAssessment')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    saveContainer(){
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._saveContainer());
    }

    async _saveContainer(){
        let errors = {};
        const t = this.props.t;
        if(this.state.container == null || this.state.container === '')
            errors[t('container')] = t('required');
        if(this.state.item == null || this.state.item === '')
            errors[t('item')] = t('required');
        if(this.state.netWeight == null || this.state.netWeight === '' || this.state.netWeight === 0)
            errors[t('netWeight')] = t('required');
        let containers = this.state.containers;
        let existing = containers.findIndex(x => x.container._id === this.state.container);
        if(existing !== this.state.containerIndex){
            errors[t('container')] = t('alreadyExists');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            this.setState({enableSave: true});
            return;
        }
        let data = {
            _id: this.state._id,
            item: this.state.item,
            containerId: this.state.containerId,
            container: this.state.container,
            netWeight: this.state.netWeight,
            netWeightUOM: this.state.netWeightUOM._id,
            originalAmount: this.state.originalAmount,
            count: this.state.count
        };
        try {
            let result = await axios.post('/api/destructioncontainer', data);
            this.clearContainer();
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        } finally {
            this.setState({enableSave: true});
        }

    }

    clearContainers(){
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._clearContainers());
    }

    async _clearContainers(){
        const t = this.props.t;
        try {
            let result = await axios.post('/api/destructioncontainersdelete', {_id: this.state._id});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        } finally {
            this.setState({enableSave: true});
        }
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    delete(){
        if(this.state._id == null || this.state._id === ''){
            this.setState({deleteOpen: false});
            this.props.hostory.goBack();
            return;
        }
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._delete());
    }

    async _delete(){
        const t = this.props.t;
        if(this.state.user != null || this.state.witness != null || this.state.rpic != null){
            this.setState({enableSave: true});
            return;
        }
        try {
            let result = await axios.delete('/api/destructiondelete/' + this.state._id);
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            if(this.state.goBack === -1)
                this.props.history.goBack();
            else
                this.props.history.go(this.state.goBack);
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        } finally {
            this.setState({enableSave: true, deleteOpen: false});
        }
    }

    editContainer(){
        this.setState({editContainer: true});
    }

    addFromBin = async () => {
        let bins = await axios.get('/api/binselect', {params: {type: this.props.auth.constants.binTypes.waste}})
        this.setState({showBin: true, binOptions: bins.data});
    };

    processBins = async () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._processBins());
    }

    async _processBins(){
        const t = this.props.t;
        if(this.state.bins == null || this.state.bins.length === 0){
            this.setState({errors: {bins: t('required')}, enableSave: true});
            return;
        }
        try {
            let result = await axios.post('/api/destructionbins', {_id: this.state._id, bins: this.state.bins, containerType: this.state.containerType, item: this.state.item});
            this.clearContainer();
            this.toggleBins();
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        } finally {
            this.setState({enableSave: true})
        }
    }

    editClick(index){
        const constants = this.props.auth.constants;
        let container = this.state.containers[index];
        let allContainers = container.containerList;
        let containerSel = allContainers.find(x => x.value === container.container._id);
        let itemSel = this.state.items.find(x => x.value === container.item._id)
        let notWeight = container.netWeightUOM._id === constants.quantityUOMs.each || container.netWeightUOM._id === constants.quantityUOMs.case;
        this.setState({
            item: container.item._id,
            itemSel: itemSel,
            container: container.container._id,
            allContainers: allContainers,
            containerSel: containerSel,
            netWeight: container.netWeight,
            netWeightUOM: container.netWeightUOM,
            editContainer: true,
            containerIndex: index,
            originalAmount: container.originalAmount,
            originalAmountUOM: container.originalAmountUOM,
            count: container.count,
            containerId: container._id,
            hasCount: itemSel.hasCount,
            notWeight: notWeight
        });
    }

    toggleDialog(){
        this.setState({
            editContainer: !this.state.editContainer
        });
        this.clearContainer();
    }

    toggleBins(){
        this.setState({
            showBin: !this.state.showBin
        });
    }

    clearContainer(){
        this.setState({
            netWeight: '',
            netWeightUOM: '',
            container: '',
            containerIndex: -1,
            containerSel: null,
            originalAmount: '',
            originalAmountUOM: '',
            editContainer: false,
            allContainers: [],
            hasCount: false,
            count: '',
            item: '',
            itemSel: null,
            errors: {},
            notWeight: false,
            containerType: '',
            containerTypeSel: null
        });
    }

    async deleteContainer(index){
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._deleteContainer(index);
        })
    }

    async _deleteContainer(i){
        try {
            const index = this.state.containerIndex > -1 ? this.state.containerIndex :
                          i != null ? i : -1;
            if(index > -1){
                let data = {
                    _id: this.state._id,
                    container: this.state.containers[index]._id
                };
                await axios.post('/api/destructioncontainerdelete', data);
                this.loadRecord();
            }
            this.clearContainer();
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        } finally {
            this.setState({enableSave: true}); 
        }

    }

    refresh = e => {
        this.loadRecord();
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    validateComplete = () => {
        let valid = this.validateForm();
        if(!valid)
            return this.props.t('notAllComplete');
        if(this.state.sops != null && this.state.sops.length > 0){
            for(let i = 0; i < this.state.sops.length; i++){
                let sop = this.state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return this.props.t('notAllWorkRecords');
                }
            }
        }
        return null;
    };

    toggleSchedule = () => {
        let dialogOpen = this.state.dialogOpen;
        this.setState({ dialogOpen: !dialogOpen });
    };

    buttonStack = (type) => {
        let buttons = [];
        const t = this.props.t;
        const fieldAccess = this.state.fieldAccess;
        switch(type){
            case 'bin':
                buttons = [
                    <Button variant="text" size="small" color="secondary" disabled={!this.state.enableSave} onClick={() => this.toggleBins()} key="close">{t('close')}</Button>,
                    <Button variant="contained" size="small" color="primary" onClick={this.processBins} disabledBy={!this.state.enableSave} key="continue">{t('continue')}</Button>
                ]
            break;
            case 'container':
                buttons = [
                    <Button variant='text' size='small' sx={{ color: 'error.main' }} disabled={!fieldAccess} onClick={() => this.deleteContainer()} key="delete">{t('delete')}</Button>,
                    <Button variant='text' size='small' color='secondary' onClick={() => this.clearContainer()} key="clear">{t('clear')}</Button>,
                    <SaveButton saveClick={() => this.saveContainer()} enabled={fieldAccess && this.state.lotSelected !== '' && this.state.lotSelected !== null} key='save'/>
                ];
            break;
            default:
                break;
        }
        return buttons;
    }

    copyWeight = () => {
        this.setState({ netWeight: this.state.originalAmount });
    }

    render(){
        const t = this.props.t;
        const errors = this.props.errors;
        let fieldAccess = this.state.fieldAccess;
        const security = this.state.security;
        const containerHasCount = this.state.containerHasCount;
        let hasCount = false;
        this.state.containers.forEach(row => {
            if(row.count != null && row.count !== '' && row.count !== 0)
                hasCount = true;
        })
        const internal = this.props.auth.user.internal;
        return(
            <Aux>
                <BreadcrumbBar goBack={this.state.goBack}>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        printClick={this.state.printClick}
                        printAccess={this.state.printClick != null ? true : false}
                        objectType={this.props.auth.constants.objectIds.destruction}
                        id={this.state._id}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabCanvas value={this.state.tabKey} index={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={5} md={3}>
                            <Panel>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl data-cy='number'>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state._id != null && this.state._id !== '' ? this.state.number : t('new')}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl data-cy='status'>
                                            <FormLabel>{t('status')}</FormLabel>
                                            {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fieldAccess &&
                                            <DateSelect
                                                onChange={this.dateChanged}
                                                value={this.state.date}
                                                helperText={errors[t('date')]}
                                                error={errors[t('date')] != null ? true : false}
                                                disabled={!fieldAccess}
                                                label={t('date')}
                                                format={this.props.auth.user.dateFormat}
                                                required
                                                data-cy='date'
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl data-cy='date'>
                                                <FormLabel>{t('date')}</FormLabel>
                                                <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                value={this.state.locationSelection}
                                                options={this.state.locations}
                                                onChange={this.changeAuto('locationSelection', 'location')}
                                                error={errors[t('location')] != null ? true : false}
                                                helperText={errors[t('location')]}
                                                label={t('location')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy='location'
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('location')}</FormLabel>
                                                {this.state.locationSelection != null ? this.state.locationSelection.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                value={this.state.methodSelection}
                                                options={this.state.methods}
                                                onChange={this.changeAuto('methodSelection', 'method')}
                                                error={errors[t('method')] != null ? true : false}
                                                helperText={errors[t('method')]}
                                                label={t('method')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy='method'
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('method')}</FormLabel>
                                                {this.state.methodSelection != null ? this.state.methodSelection.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                    {this.state.scheduleBase != null && this.state.scheduleBase.riskAssessmentRequired &&
                                        <Grid item xs={12}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={this.state.taskRiskAssessmentSel}
                                                    options={this.state.taskRisks}
                                                    onChange={this.changeAuto('taskRiskAssessmentSel', 'taskRiskAssessment')}
                                                    error={errors[t('taskRiskAssessment')] != null ? true : false}
                                                    helperText={errors[t('taskRiskAssessment')]}
                                                    label={t('riskAssessment')}
                                                    disabled={!fieldAccess}
                                                    required
                                                />
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('riskAssessment')}</FormLabel>
                                                    {this.state.taskRiskAssessmentSel != null ? this.state.taskRiskAssessmentSel.label : ''}
                                                </FormControl>
                                            }
                                        </Grid>
                                    }
                                    {this.state.hasForm === true &&
                                        <Grid item xs={12}>
                                            <br/>
                                            {errors[t('form')] != null && errors[t('form')] !== '' &&
                                                <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                            }
                                            <Form renderer={renderer}
                                                defaultFields={this.state.form}
                                                onChange={this.formValueChanged}
                                                disabled={!fieldAccess}/>
                                        </Grid>
                                    }
                                </Grid>
                            </Panel>
                        </Grid>
                        <Grid item xs={12} sm={7} md={4}>
                            <Panel>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <AutoCompleteField
                                            multiple={true}
                                            value={this.state.usersSel}
                                            options={fieldAccess && !internal ? this.state.activeUsers : this.state.userList}
                                            onChange={this.changeMulti('usersSel', 'users')}
                                            error={errors[t('users')] != null ? true : false}
                                            helperText={errors[t('users')]}
                                            label={t('users')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy='users'
                                        />
                                    </Grid>
                                    {this.state.sopList != null && this.state.sopList.length > 0 &&
                                        <Grid item xs={12}>
                                            <AutoCompleteField
                                                multiple={true} disabled={!fieldAccess}
                                                value={this.state.sopsSel}
                                                options={this.state.sopList}
                                                onChange={this.changeSop}
                                                error={errors[t('sopIds')] != null ? true : false}
                                                helperText={errors[t('sopIds')]}
                                                label={t('workRecs')}
                                                data-cy='sops'
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Panel>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Panel>
                                <TableContainer>
                                    <Table>
                                        <TableHead key='destructionTotalTableHead'>
                                            <TableRow>
                                                <TableCell>{t('item')}</TableCell>
                                                <TableCell sx={{ textAlign: 'right' }}>{t('containers')}</TableCell>
                                                <TableCell sx={{ textAlign: 'right' }}>{t('quantity')}</TableCell>
                                                {this.props.auth.user.cultivator && 
                                                    <TableCell sx={{ textAlign: 'right' }}>{t('plants')}</TableCell>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.itemTotals != null && this.state.itemTotals.length > 0 ?
                                                this.state.itemTotals.map((row, i) =>
                                                    <Aux key={'destructionRowAuxKey-'+i}>
                                                        <TableRow key={'destructionTotalRow-' + i}>
                                                            <TableCell><Link to={'/item/' + row.item._id}>{row.item.number + '-' + row.item.name}</Link></TableCell>
                                                            <TableCell sx={{ textAlign: 'right' }}>{row.containerCount}</TableCell>
                                                            <TableCell sx={{ textAlign: 'right' }}>{row.quantity.toString() + row.uom.label}</TableCell>
                                                            {this.props.auth.user.cultivator && 
                                                                <TableCell sx={{ textAlign: 'right' }}>{row.cullPlants}</TableCell>
                                                            }
                                                        </TableRow>
                                                        {i === this.state.itemTotals.length - 1 &&
                                                            <TableRow key={'destructionTotalRow-' + (i + 1)}>
                                                                <TableCell sx={{ textAlign: 'right' }}><b>{t('total')}</b></TableCell>
                                                                <TableCell sx={{ textAlign: 'right' }}><b>{this.state.itemTotals.reduce((total, item) => total + (item.containerCount ?? 0), 0)}</b></TableCell>
                                                                <TableCell sx={{ textAlign: 'right' }}><b>{this.state.itemTotals.reduce((total, item) => total + (item.quantity ?? 0), 0)}</b></TableCell>
                                                                {this.props.auth.user.cultivator &&
                                                                    <TableCell sx={{ textAlign: 'right' }}><b>{this.state.itemTotals.reduce((total, item) => total + (item.cullPlants ?? 0), 0)}</b></TableCell>
                                                                }
                                                            </TableRow>
                                                        }
                                                    </Aux>
                                                ) :
                                                <TableRow key='destructionTotalsNoDataRow'>
                                                    <TableCell colSpan={3} sx={{ textAlign: 'center' }}>{t('noData')}</TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Panel>
                        </Grid>
                        <Grid item xs={12}>
                            <Panel>
                                <SectionTitle title={t('containers')} />
                                <Grid container spacing={3} alignItems='flex-end'>
                                    <Grid item sm={10}>
                                        <Button variant="outlined" size="small" color="primary" onClick={() => this.editContainer()} data-cy="addContainer"
                                        disabled={this.state._id == null || this.state._id === '' || this.state.editContainer || this.state.showBin || !fieldAccess}>
                                            {t('add')}
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Button variant="outlined" size="small" color="primary" onClick={this.addFromBin} data-cy='addBin'
                                        disabled={this.state._id == null || this.state._id === '' || this.state.editContainer || this.state.showBin || !fieldAccess}>
                                            {t('addWasteBin')}
                                        </Button>&nbsp;&nbsp;
                                        {this.state.containers != null && this.state.containers.length > 0 &&
                                            <Button variant="outlined" size="small" color="primary" onClick={() => this.clearContainers()} 
                                                disabled={this.state._id == null || this.state._id === '' || this.state.editContainer || this.state.showBin || !fieldAccess} data-cy='clearContainers'>
                                                {t('clear')}
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Table aria-label="destructionContainers">
                                        <TableHead>
                                            <TableRow key="destructionContainersTableHeader">
                                                <TableCell>{t('item')}</TableCell>
                                                <TableCell>{t('number')}</TableCell>
                                                <TableCell>{t('lot')}</TableCell>
                                                <TableCell>{t('amount')}</TableCell>
                                                <TableCell>{t('originalAmount')}</TableCell>
                                                {hasCount &&
                                                    <TableCell>{t('count')}</TableCell>
                                                }
                                                <TableCell>{t('containerType')}</TableCell>
                                                {this.props.auth.user.cultivator &&
                                                    <TableCell>{t('plants')}</TableCell>
                                                }
                                                {fieldAccess &&
                                                    <TableCell />
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.containers != null && this.state.containers.length > 0 ?
                                                this.state.containers.map((row, i) => {
                                                    return (
                                                        <TableRow key={'container' + i}>
                                                            <TableCell>
                                                                {row.item != null &&
                                                                    <TextOnlyClickButton enabled={true} click={() => this.editClick(i)} name={row.item.number + '-' + row.item.name}/>
                                                                }
                                                                {row.container?.cullItem != null && '  (' + row.container.cullItem.name + ')'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.container != null &&
                                                                    <Link to={'/container/' + row.container._id}>{row.container.number}</Link>
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.container != null && row.container.lot != null &&
                                                                    <Link to={'/lot/' + row.container.lot._id}>{row.container.lot.number}</Link>
                                                                }
                                                            </TableCell>
                                                            <TableCell><NumericFormat displayType={'text'} value={row.netWeight} thousandSeparator={true} decimalScale={3} suffix={row.netWeightUOM.label}/></TableCell>
                                                            <TableCell><NumericFormat displayType={'text'} value={row.originalAmount} thousandSeparator={true} decimalScale={3} suffix={row.netWeightUOM.label}/></TableCell>
                                                            {hasCount &&
                                                                <TableCell>{row.count != null ? row.count : ''}</TableCell>
                                                            }
                                                            <TableCell>{row.container != null && row.container.type != null ? row.container.type : ''}</TableCell>
                                                            {this.props.auth.user.cultivator &&
                                                                <TableCell>{row.container?.cullPlants != null ? row.container.cullPlants : ''}</TableCell>
                                                            }
                                                            {fieldAccess &&
                                                                <TableCell>
                                                                    <IconButton aria-label="delete" onClick={() => this.deleteContainer(i)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            }
                                                        </TableRow>
                                                    );
                                                }).sort((a, b) => {
                                                    if(this.props.auth.user.cultivator) {
                                                        //sort by row.container.cullPlants
                                                        if(a.props.children[7].props.children > b.props.children[7].props.children)
                                                            return -1;
                                                        if(a.props.children[7].props.children < b.props.children[7].props.children)
                                                            return 1;
                                                        return 0;
                                                    }
                                                    return 0;
                                                }) :
                                                <TableRow key="destructionContainersNoDataRow">
                                                    <TableCell colSpan={7} sx={{ textAlign: 'center' }}>{t('noData')}</TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                                {errors[t('lots')] && <div><div className="error text-danger">{errors[t('lots')]}</div><br/></div>}
                            </Panel>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={10} sm={6}>
                            <SaveButton saveClick={() => this.saveClick()} enabled={fieldAccess && this.state.enableSave} />
                            {this.state.enableDelete &&
                                <DeleteButton deleteClick={() => this.deleteToggle()} enabled={fieldAccess} />
                            }
                            {this.state.enableUnlock && 
                                <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.destruction} enable={this.props.permission.unlock} callback={this.loadRecord}/>
                            }
                        </Grid>
                    </Grid>
                </TabCanvas>
                {this.state.sopsSel != null && this.state.sopsSel.length > 0 && this.state.sopsSel.map((sop, i) =>
                    <TabPanel value={this.state.tabKey} index={i + 1} key={'sop' + i}>
                        <br/>
                        <ActivityWorkRecord sop={sop.value} id={this.state.sops[i].workRecord} date={this.state.date} users={this.state.users}
                        objectType={this.props.auth.constants.objectIds.destruction} recordId={this.state._id} complete={this.loadRecord}
                        locationId={this.state.location} renderNum={this.state.renderNum} tab={this.state.tabKey}/>
                    </TabPanel>
                )}
                {this.state.hasLedgers &&
                    <TabCanvas value={this.state.tabKey} index={this.state.tabLabels.length - 1}>
                        <ItemLedgerComponent record={this.state._id} permission={this.props.permission} auth={this.props.auth} renderNum={this.state.renderNum}/>
                    </TabCanvas>
                }
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleSchedule()}
                    />
                }
                {this.state.showBin &&
                    <ModalDialog buttonStack={this.buttonStack('bin')} title={t('binSelect')} toggleDialog={() => this.toggleBins()}
                                    dialogStatus={this.state.showBin} fullWidth maxWidth='sm' aria-labelledby="dialog-destruction">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AutoCompleteField
                                    multiple={true}
                                    value={this.state.binsSel}
                                    options={this.state.binOptions}
                                    onChange={this.changeMulti('binsSel', 'bins')}
                                    error={errors[t('bins')] != null ? true : false}
                                    helperText={errors[t('bins')]}
                                    label={t('bins')}
                                    disabled={!fieldAccess}
                                    required
                                    data-cy='bins'
                                />
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <AutoCompleteField
                                    value={this.state.containerTypeSel}
                                    options={this.state.containerTypes}
                                    onChange={this.changeAuto('containerTypeSel', 'containerType')}
                                    error={errors[t('containerType')] != null ? true : false}
                                    helperText={errors[t('containerType')]}
                                    label={t('containerType')}
                                    disabled={!fieldAccess}
                                    data-cy='containerType'
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <AutoCompleteField
                                    value={this.state.itemSel}
                                    options={this.state.items}
                                    onChange={this.changeAuto('itemSel', 'item')}
                                    error={errors[t('item')] != null ? true : false}
                                    helperText={errors[t('item')]}
                                    label={t('item')}
                                    disabled={!fieldAccess}
                                    data-cy='addItem'
                                />
                            </Grid>
                        </Grid>
                    </ModalDialog>
                }
                {this.state.editContainer &&
                    <ModalDialog buttonStack={this.buttonStack('container')} title={t('containerSelect')} toggleDialog={() => this.toggleDialog()}
                                 dialogStatus={this.state.editContainer} fullWidth maxWidth='sm' aria-labelledby="dialog-destruction">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <AutoCompleteField
                                    value={this.state.itemSel}
                                    options={this.state.items}
                                    onChange={this.changeAuto('itemSel', 'item')}
                                    error={errors[t('item')] != null ? true : false}
                                    helperText={errors[t('item')]}
                                    label={t('item')}
                                    disabled={!fieldAccess}
                                    multiline
                                    data-cy='item'
                                />
                            </Grid>
                            {this.state.allContainers != null && this.state.allContainers.length > 0 &&
                                <Grid item xs={9}>
                                    <AutoCompleteField
                                        value={this.state.containerSel}
                                        options={this.state.allContainers}
                                        onChange={this.changeAuto('containerSel', 'container')}
                                        error={errors[t('container')] != null ? true : false}
                                        helperText={errors[t('container')]}
                                        label={t('container')}
                                        disabled={!fieldAccess}
                                        data-cy='container'
                                        groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                        noneMessage={t('noLotAssigned')}
                                    />
                                </Grid>
                            }
                            {this.state.itemSel != null &&
                                <Grid item container xs={12}>
                                    <Grid item xs={4}>
                                        <TextField type="number" value={this.state.originalAmount != null && this.state.originalAmount !== '' ?
                                        this.state.originalAmount : ''}
                                        name="statusString" data-cy='originalAmount'
                                        size="medium" fullWidth={true} disabled={true} label={t('originalAmount') + ( this.state.netWeightUOM != null && this.state.netWeightUOM !== '' ?
                                                                                            ' (' + this.state.netWeightUOM.label + ')' : '' )}/>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => this.copyWeight()} disabled={!fieldAccess || this.state.notWeight || this.state.originalAmount === ''} data-cy='copyButton'>
                                            <CopyIcon size='small' sx={{ color: (!fieldAccess || this.state.notWeight || this.state.itemSel === null || this.state.originalAmount === '' ? null : 'primary.main') }} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField type="number" value={this.state.netWeight} onChange={this.changeValue} name="netWeight" data-cy='netWeight'
                                        size="medium" fullWidth={true} disabled={!fieldAccess || this.state.notWeight || this.state.originalAmount === ''} label={t('netAmount') + ( this.state.netWeightUOM != null && this.state.netWeightUOM !== '' ?
                                                                                                                        ' (' + this.state.netWeightUOM.label + ')' : '' )}
                                        error={errors[t('netWeight')] != null ? true : false} helperText={errors[t('netWeight')]}/>
                                    </Grid>
                                    {containerHasCount &&
                                        <Grid item xs={4}>
                                            <TextField type="number" value={this.state.count} onChange={this.changeValue} name="count"
                                            size="medium" fullWidth={true} disabled={true} label={t('count')} data-cy='count'
                                            error={errors[t('count')] != null ? true : false} helperText={errors[t('count')]}/>
                                        </Grid>
                                    }
                                </Grid>
                            }
                        </Grid>
                    </ModalDialog>
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.delete()} open={this.state.deleteOpen} />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.destruction}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Destruction));
