import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Aux from '../../hoc/Auxilary/Auxilary';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Grid from '@mui/material/Grid';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import IconButton from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import Title from '../UI/Typography/Title';
import SectionTitle from '../UI/Typography/SectionTitle';
import SubSubHeading from '../UI/Typography/SubSubHeading';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '@mui/material';

class AvailableInventory extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            record: null,
            hcCats: [],
            hcCatsSel: [],
            hcCatList: []
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.hcReportCategory], this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({
            hcCatList: cacheValues.hcReportCategories,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null
        }, () => {
            if(this.state._id != null){
                this.loadRecord();
            } else {
                this.generateRecord();
            }
            const crumbs = [
                { path: 'active', label: this.props.t('availableInventory'), screen: this.props.auth.screenDefs.AvailableInventory}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    loadRecord = async () => {
        const response = await axios.get('/api/availableinventory/' + this.state._id);
        let record = response.data;
        if(record.data != null && record.data.length > 0){
            for(let row of record.data){
                row.hcCatSel = this.state.hcCatList.find(x => x.value === row.hcCategory);
                row.show = false;
                row.categories.forEach(cat => {
                    cat.show = false;
                    cat.items.forEach(item => { 
                        item.show = false;
                        item.listSize = 10;
                        item.page = 0;
                        item.offset = 0;
                        item.displayLots = item.lots.slice(0, item.listSize);
                    });
                });
            }
        }
        this.setState({record: record});
    }

    generateRecord = async () => {
        try {
            let res = await axios.post('api/availableinventory', {_id: this.state._id});
            if(this.state._id == null){
                this.props.history.replace('/availableinventory/' + res.data.id);
            } else {
                this.loadRecord();
            }
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }

    }

    setCatsOpen = (i) => {
        let record = this.state.record;
        record.data[i].show = !record.data[i].show;
        this.setState({record: record});
    }

    setItemsOpen = (i, j) => {
        let record = this.state.record;
        record.data[i].categories[j].show = !record.data[i].categories[j].show;
        this.setState({record: record});
    }

    setLotsOpen = (i, j, k) => {
        let record = this.state.record;
        record.data[i].categories[j].items[k].show = !record.data[i].categories[j].items[k].show;
        this.setState({record: record});
    }

    handleRowsPerPage = (i, j, k) => (e) => {
        let record = this.state.record;
        let listSize = parseInt(e.target.value);
        record.data[i].categories[j].items[k].listSize = listSize;
        let list = this.listSlice(record.data[i].categories[j].items[k].lots, record.data[i].categories[j].items[k].offset, listSize);
        record.data[i].categories[j].items[k].displayLots = list;
        this.setState({record: record})
    };

    handleChangePage = (i, j, k) => (event, newPage) => {
        let record = this.state.record;
        record.data[i].categories[j].items[k].page = newPage;
        record.data[i].categories[j].items[k].offset = newPage * record.data[i].categories[j].items[k].listSize;
        let list = this.listSlice(record.data[i].categories[j].items[k].lots, record.data[i].categories[j].items[k].offset, record.data[i].categories[j].items[k].listSize);
        record.data[i].categories[j].items[k].displayLots = list;
        this.setState({record: record});
    };

    listSlice = (list, offset, listSize) => {
        return list.slice(offset, offset + listSize);
    }

    render(){
        const t = this.props.t;
        const data = this.state.record != null ? this.state.record.data : null;
        return(
            <Aux>
                <BreadcrumbBar />
                <Panel>
                    <Title title={t('availableInventory')}/>
                    {this.state.record != null &&
                        <Grid container spacing={3}>
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    <span>{this.state.record.number}</span>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <DateDisplay value={this.state.record.date} format={this.props.auth.user.dateFormat}/>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" size="small" color="primary" onClick={this.generateRecord}>{t('generate')}</Button>
                            </Grid>
                        </Grid>
                    }
                    {data != null && data.length > 0 && data.map((hcCat, i) =>
                        <Aux key={hcCat.hcCat}>
                            <SectionTitle title={t('reportCategory') + ': ' + hcCat.hcCatSel.label} />
                            <Grid container spacing={2}>
                                {hcCat.totalGrams > 0 && 
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('grams')}</FormLabel>
                                            <NumericFormat value={hcCat.totalGrams} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                        </FormControl>
                                    </Grid>
                                }
                                {hcCat.availableGrams > 0 &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('available') + ' (g)'}</FormLabel>
                                            <NumericFormat value={hcCat.availableGrams} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                        </FormControl>
                                    </Grid>
                                }
                                {hcCat.totalPackages > 0 &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('packages')}</FormLabel>
                                            <NumericFormat value={hcCat.totalPackages} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                        </FormControl>
                                    </Grid>
                                }
                                {hcCat.availablePackages > 0 &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('availablePackages')}</FormLabel>
                                            <NumericFormat value={hcCat.availablePackages} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                            <br/>
                            <Grid container spacing={2}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <SubSectionHeading title={t('itemCategories')} />
                                <IconButton aria-label="expand row" size="small" onClick={() => this.setCatsOpen(i)}>
                                    {data[i].show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </Grid>
                            <Collapse in={data[i].show} timeout="auto" unmountOnExit>
                                {hcCat.categories.map((cat, j) =>
                                    <Aux key={cat.itemCategory._id}>
                                        <SubSubHeading title={cat.itemCategory.description} />
                                        <Grid container spacing={2}>
                                            {cat.totalGrams > 0 && 
                                                <Grid item xs={4} sm={2}>
                                                    <FormControl>
                                                        <FormLabel>{t('grams')}</FormLabel>
                                                        <NumericFormat value={cat.totalGrams} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                    </FormControl>
                                                </Grid>
                                            }
                                            {cat.availableGrams > 0 &&
                                                <Grid item xs={4} sm={2}>
                                                    <FormControl>
                                                        <FormLabel>{t('available') + ' (g)'}</FormLabel>
                                                        <NumericFormat value={cat.availableGrams} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                    </FormControl>
                                                </Grid>
                                            }
                                            {cat.totalPackages > 0 &&
                                                <Grid item xs={4} sm={2}>
                                                    <FormControl>
                                                        <FormLabel>{t('packages')}</FormLabel>
                                                        <NumericFormat value={cat.totalPackages} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                    </FormControl>
                                                </Grid>
                                            }
                                            {cat.availablePackages > 0 && 
                                                <Grid item xs={4} sm={2}>
                                                    <FormControl>
                                                        <FormLabel>{t('availablePackages')}</FormLabel>
                                                        <NumericFormat value={cat.availablePackages} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                    </FormControl>
                                                </Grid>
                                            }
                                        </Grid>
                                        <br/>
                                        <Grid container spacing={2}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <SubSubHeading title={t('items')} />
                                            <IconButton aria-label="expand row" size="small" onClick={() => this.setItemsOpen(i, j)}>
                                                {data[i].categories[j].show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </Grid>
                                        <Collapse in={data[i].categories[j].show} timeout="auto" unmountOnExit>
                                            {cat.items.map((item, k) =>
                                                <Aux key={item.item._id}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={10}>
                                                            <Link to={'/item/' + item.item._id}>{item.item.number + ' - ' + item.item.name}</Link>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        {item.totalGrams > 0 && 
                                                            <Grid item xs={4} sm={2}>
                                                                <FormControl>
                                                                    <FormLabel>{t('grams')}</FormLabel>
                                                                    <NumericFormat value={item.totalGrams} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        {item.availableGrams > 0 &&
                                                            <Grid item xs={4} sm={2}>
                                                                <FormControl>
                                                                    <FormLabel>{t('available') + ' (g)'}</FormLabel>
                                                                    <NumericFormat value={item.availableGrams} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        {item.totalPackages > 0 &&
                                                            <Grid item xs={4} sm={2}>
                                                                <FormControl>
                                                                    <FormLabel>{t('packages')}</FormLabel>
                                                                    <NumericFormat value={item.totalPackages} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        {item.availablePackages > 0 &&
                                                            <Grid item xs={4} sm={2}>
                                                                <FormControl>
                                                                    <FormLabel>{t('availablePackages')}</FormLabel>
                                                                    <NumericFormat value={item.availablePackages} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                    <br/>
                                                    <Grid container spacing={2}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <strong>{t('lots')}</strong>
                                                        <IconButton aria-label="expand row" size="small" onClick={() => this.setLotsOpen(i, j, k)}>
                                                            {data[i].categories[j].items[k].show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                    </Grid>
                                                    <Collapse in={data[i].categories[j].items[k].show} timeout="auto" unmountOnExit>
                                                        <TableContainer component={Paper}>
                                                            <Table size="small">
                                                                <TableHead>
                                                                    <TableRow key={'lotHeader' + i + 'a' + j + 'b' + k}>
                                                                        <TableCell>{t('number')}</TableCell>
                                                                        <TableCell>{t('status')}</TableCell>
                                                                        <TableCell align="right">{t('grams')}</TableCell>
                                                                        <TableCell align="right">{t('available') + ' (g)'}</TableCell>
                                                                        <TableCell align="right">{t('packages')}</TableCell>
                                                                        <TableCell align="right">{t('availablePackages')}</TableCell>
                                                                        <TableCell>{t('created')}</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {item.displayLots.map((lot, l) =>
                                                                        <TableRow key={lot._id}>
                                                                            <TableCell><Link to={'/lot/' + lot.lot._id}>{lot.lot.number}</Link></TableCell>
                                                                            <TableCell>{lot.status}</TableCell>
                                                                            <TableCell align="right"><NumericFormat value={lot.totalGrams} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale/></TableCell>
                                                                            <TableCell align="right"><NumericFormat value={lot.availableGrams} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale/></TableCell>
                                                                            <TableCell align="right"><NumericFormat value={lot.totalPackages} displayType={'text'} thousandSeparator={true} decimalScale={0}/></TableCell>
                                                                            <TableCell align="right"><NumericFormat value={lot.availablePackages} displayType={'text'} thousandSeparator={true} decimalScale={0}/></TableCell>
                                                                            <TableCell>
                                                                                {lot.lot.transactions != null && lot.lot.transactions.length > 0 ? <DateDisplay value={lot.lot.transactions[0].date} format={this.props.auth.user.dateFormat}/> : ''}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                                <TableFooter>
                                                                    <TableRow key={'lotFooter' + i + 'a' + j + 'b' + k}>
                                                                        <TablePagination count={data[i].categories[j].items[k].lots.length}
                                                                        rowsPerPage={data[i].categories[j].items[k].listSize}
                                                                        onPageChange={this.handleChangePage(i, j, k)}
                                                                        page = {data[i].categories[j].items[k].page}
                                                                        rowsPerPageOptions={common.rowOptions}
                                                                        onRowsPerPageChange={this.handleRowsPerPage(i, j, k)}/>
                                                                    </TableRow>
                                                                </TableFooter>
                                                            </Table>
                                                        </TableContainer>
                                                    </Collapse>
                                                    <br/>
                                                </Aux>
                                            )}
                                        </Collapse>
                                    </Aux>
                                )}
                            </Collapse>
                            <br/>
                        </Aux>
                    )}
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(AvailableInventory));