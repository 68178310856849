import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';
import dateFormat from 'date-fns/format';
import AddIcon from '@mui/icons-material/Add';
import ActionBar from '../UI/Buttons/ActionBar';
import { Checkbox, FormControlLabel } from '@mui/material';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import { CSVDownload } from "react-csv";
import ConversionHelper from '../../helpers/conversionHelper';
import { NumericFormat } from 'react-number-format';
import listHelper from '../../helpers/listHelper';
import ListMultiSelect from '../UI/ListMultiSelect/ListMultiSelect';

const ProductionOrders = (props) => {
    const isFirstRender = useRef(true);
    const [loadRefData, setLoadRefData] = useState(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({
        _id: false,
        inputCannabisTotal: false,
        outputCannabisTotal: false,
        processingLoss: false,
        processingLossPercent: false,
        inputCost: false,
        labourCost: false,
        returnedTotal: false,
        wasteTotal: false,
        sampleTotal: false
    });
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [columnOrder, setColumnOrder] = useState([]);
    const [values, setValues] = useState({locations: [], items: [], statuses: [], routings: [], boms: []});
    const { t } = useTranslation('translation');
    const csvSeparator = sessionStorage.getItem('csvSeparator');
    const [csvOutput, setCsvOutput] = useState({headers: null, data: null});
    const statuses = props.auth.constants.productionOrderStatus;
    const tenant = props.auth.user.tenant;
    const [statusesSel, setStatusesSel] = useState({ids: [], values: []});
    const [showSelect, setShowSelect] = useState(false);
    const crumbs = [
        { path: 'active', label: t('productionOrders'), screen: props.auth.screenDefs.ProductionOrderList}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        async function fetchData(){
            const columnFilters = await listHelper.getItem('ProductionOrdersColumnFilters', tenant);
            const columnVisibility = await listHelper.getItem('ProductionOrdersColumnVisibility', tenant);
            const density = await listHelper.getItem('ProductionOrdersDensity', tenant);
            const globalFilter = await listHelper.getItem('ProductionOrdersGlobalFilter', tenant);
            const showGlobalFilter = await listHelper.getItem('ProductionOrdersShowGlobalFilter', tenant);
            const showColumnFilters = await listHelper.getItem('ProductionOrdersShowColumnFilters', tenant);
            const sorting = await listHelper.getItem('ProductionOrdersSorting', tenant);
            const pagination = await listHelper.getItem('ProductionOrdersPagination', tenant);
            const tempColumnOrder = await listHelper.getItem('ProductionOrdersColumnOrder', tenant);
            if (columnFilters) {
                let filters = ConversionHelper.filtersDateParse(JSON.parse(columnFilters));
                let status = filters.find(x => x.id === 'status');
                if(status != null){
                    let sel = {ids: status.value, values: []};
                    status.value.forEach(id => {
                        let val = values.statuses.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setStatusesSel(sel);
                }
                setColumnFilters(filters);
            }
            if (columnVisibility) {
                setColumnVisibility(columnVisibility);
            }
            if (density) {
                setDensity(density);
            }
            if (globalFilter) {
                setGlobalFilter(globalFilter);
            }
            if (showGlobalFilter) {
                setShowGlobalFilter(showGlobalFilter);
            }
            if (showColumnFilters) {
                setShowColumnFilters(showColumnFilters);
            }
            if (sorting) {
                setSorting(sorting);
            }
            if (pagination) {
                setPagination(pagination);
            }
            if (tempColumnOrder) {
                setColumnOrder(tempColumnOrder);
            } else {
                setColumnOrder(['number', 'description', 'status', 'outputItem', 'plannedQuantity', 'quantity', 'outputLotString', 'plannedStart', 'dueDate', 'endDate', 'location', 'productionBatch', 'routing', 'productionBom', 'custom', 'inputCannabisTotal', 'outputCannabisTotal', 'processingLoss', 'processingLossPercent', 'inputCost', 'labourCost', 'returnedTotal', 'wasteTotal', 'sampleTotal']);
            }
            isFirstRender.current = false;
        }
        if(isFirstRender.current){
            fetchData();
        }
    }, [values, statuses, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ProductionOrdersColumnFilters', tenant, JSON.stringify(columnFilters));
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ProductionOrdersColumnVisibility', tenant, columnVisibility);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnVisibility, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ProductionOrdersDensity', tenant, density);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [density, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ProductionOrdersGlobalFilter', tenant, globalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [globalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ProductionOrdersShowGlobalFilter', tenant, showGlobalFilter);
        }
        if (isFirstRender.current) return;
        fetchData()
    }, [showGlobalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ProductionOrdersShowColumnFilters', tenant, showColumnFilters);
        }
        if (isFirstRender.current) return;
        fetchData();

    }, [showColumnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ProductionOrdersSorting', tenant, sorting);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [sorting, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ProductionOrdersPagination', tenant, pagination);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [pagination, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ProductionOrdersColumnOrder', tenant, columnOrder);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnOrder, tenant]);

    const resetState = () => {
        listHelper.removeItem('ProductionOrdersColumnFilters', tenant);
        listHelper.removeItem('ProductionOrdersColumnVisibility', tenant);
        listHelper.removeItem('ProductionOrdersDensity', tenant);
        listHelper.removeItem('ProductionOrdersGlobalFilter', tenant);
        listHelper.removeItem('ProductionOrdersShowGlobalFilter', tenant);
        listHelper.removeItem('ProductionOrdersShowColumnFilters', tenant);
        listHelper.removeItem('ProductionOrdersSorting', tenant);
        listHelper.removeItem('ProductionOrdersPagination', tenant);
        listHelper.removeItem('ProductionOrdersColumnOrder', tenant);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.workLocation, common.cacheValues.productionItem, common.cacheValues.bom, common.cacheValues.routing], 
                props.auth.constants, null, props.auth.user.tenant);
            const valueStatuses = await ValueHelper.getStatusesForObject(props.auth.constants.objectIds.productionOrder, props.auth.user.tenant);
            setValues({
                items: cacheValues.productionItems,
                locations: cacheValues.locations,
                statuses: valueStatuses,
                boms: cacheValues.boms,
                routings: cacheValues.routings
            });
            let status = columnFilters != null ? columnFilters.find(x => x.id === 'status') : null;
            if(status != null){
                let sel = {ids: status.value, values: []};
                status.value.forEach(id => {
                    let val = valueStatuses.find(x => x.value === id);
                    sel.values.push(val);
                });
                setStatusesSel(sel);
            }
            setLoadRefData(false);
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, columnFilters, loadRefData]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'ProductionOrders',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/productionorders', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });

    let columns = useMemo(() => [
        {
            accessorKey: 'number',
            id: 'number',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/eproductionorder/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            )
        },
        {
            id: 'description',
            accessorKey: 'description',
            header: t('description')
        },
        {
            accessorKey: 'status',
            id: 'status',
            header: t('status'),
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='status'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.statuses} 
                    setSelected={setStatusesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={statusesSel.ids} 
                    selectedSel={statusesSel.values}
                    columnFilters={columnFilters}
                    screen='ProductionOrders'
                    tenant={props.auth.user.tenant}/>
                </Box>
            ),
        },
        {
            accessorKey: 'outputItem',
            id: 'outputItem',
            header: t('item'),
            filterVariant: 'select',
            filterSelectOptions: values.items
        },
        {
            accessorKey: 'plannedQuantity',
            id: 'plannedQuantity',
            header: t('planned'),
            enableColumnFilter: false
        },
        {
            accessorKey: 'quantity',
            id: 'quantity',
            header: t('quantity'),
            enableColumnFilter: false
        },
        {
            accessorKey: 'outputLotString',
            id: 'outputLotString',
            header: t('lot'),
            enableColumnFilter: false
        },
        {
            accessorKey: 'plannedStart',
            id: 'plannedStart',
            header: t('plannedStart'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() != null ? dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat) : ''}
                </Box>
            )
        },
        {
            accessorKey: 'dueDate',
            id: 'dueDate',
            header: t('dueDate'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() != null ? dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat) : ''}
                </Box>
            )
        },
        {
            accessorKey: 'endDate',
            id: 'endDate',
            header: t('endDate'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() != null ? dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat) : ''}
                </Box>
            )
        },
        {
            accessorKey: 'location',
            id: 'location',
            header: t('location'),
            filterVariant: 'select',
            filterSelectOptions: values.locations
        },
        {
            accessorKey: 'productionBatch',
            id: 'productionBatch',
            header: t('batch'),
            Filter: ({
                header,
                column
              }) => <FormControlLabel control={
                <Checkbox checked={column.getFilterValue() === 'true'} 
                    onChange={() => {
                        column.setFilterValue(
                            column.getFilterValue() === undefined || column.getFilterValue() === 'false' || column.getFilterValue() == null
                                ? 'true' : 'false'
                        )
                    }}/>
                } label={t('standalone')} />,
            Cell: ({ cell, column }) => (
                cell.getValue()
            ),
        },
        {
            accessorKey: 'routing',
            id: 'routing',
            header: t('routing'),
            filterVariant: 'select',
            filterSelectOptions: values.routings
        },
        {
            accessorKey: 'productionBom',
            id: 'productionBom',
            header: t('productionBom'),
            filterVariant: 'select',
            filterSelectOptions: values.boms
        },
        {
            accessorKey: 'custom',
            id: 'custom',
            header: t('custom'),
            filterVariant: 'checkbox',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() ? t('yes') : ''}
                </Box>
            )
        },
        {
            accessorKey: 'inputCannabisTotal',
            id: 'inputCannabisTotal',
            header: t('inputCannabis'),
            enableColumnFilter: false,
            Cell: ({ cell, column }) => (
                <Box>
                    <NumericFormat displayType={'text'} 
                                  value={cell.getValue() ? cell.getValue() : 0}
                                  thousandSeparator={true}
                                  decimalScale={3}/>
                </Box>
            )
        },
        {
            accessorKey: 'outputCannabisTotal',
            id: 'outputCannabisTotal',
            header: t('outputCannabis'),
            enableColumnFilter: false,
            Cell: ({ cell, column }) => (
                <Box>
                    <NumericFormat displayType={'text'} 
                                  value={cell.getValue() ? cell.getValue() : 0}
                                  thousandSeparator={true}
                                  decimalScale={3}/>
                </Box>
            )
        },
        {
            accessorKey: 'processingLoss',
            id: 'processingLoss',
            header: t('processingLoss'),
            enableColumnFilter: false,
            Cell: ({ cell, column }) => (
                <Box>
                    <NumericFormat displayType={'text'} 
                                  value={cell.getValue() ? cell.getValue() : 0}
                                  thousandSeparator={true}
                                  decimalScale={3}/>
                </Box>
            )
        },
        {
            accessorKey: 'processingLossPercent',
            id: 'processingLossPercent',
            header: t('processingLoss') + ' ' + t('percent'),
            enableColumnFilter: false,
            Cell: ({ cell, column }) => (
                <Box>
                    <NumericFormat displayType={'text'} 
                                  value={cell.getValue() ? cell.getValue() : 0}
                                  thousandSeparator={true}
                                  decimalScale={3}/>
                </Box>
            )
        },
        {
            accessorKey: 'inputCost',
            id: 'inputCost',
            header: t('costItems'),
            enableColumnFilter: false,
            Cell: ({ cell, column }) => (
                <Box>
                    <NumericFormat displayType={'text'} 
                                  value={cell.getValue() ? cell.getValue() : 0}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  prefix='$'/>
                </Box>
            )
        },
        {
            accessorKey: 'labourCost',
            id: 'labourCost',
            header: t('labour'),
            enableColumnFilter: false,
            Cell: ({ cell, column }) => (
                <Box>
                    <NumericFormat displayType={'text'} 
                                  value={cell.getValue() ? cell.getValue() : 0}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  prefix='$'/>
                </Box>
            )
        },
        {
            accessorKey: 'returnedTotal',
            id: 'returnedTotal',
            header: t('returnedTotal'),
            enableColumnFilter: false,
            Cell: ({ cell, column }) => (
                <Box>
                    <NumericFormat displayType={'text'} 
                                  value={cell.getValue() ? cell.getValue() : 0}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}/>
                </Box>
            )
        },
        {
            accessorKey: 'wasteTotal',
            id: 'wasteTotal',
            header: t('wasteTotal'),
            enableColumnFilter: false,
            Cell: ({ cell, column }) => (
                <Box>
                    <NumericFormat displayType={'text'} 
                                  value={cell.getValue() ? cell.getValue() : 0}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}/>
                </Box>
            )
        },
        {
            accessorKey: 'sampleTotal',
            id: 'sampleTotal',
            header: t('sampleTotal'),
            enableColumnFilter: false,
            Cell: ({ cell, column }) => (
                <Box>
                    <NumericFormat displayType={'text'} 
                                  value={cell.getValue() ? cell.getValue() : 0}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}/>
                </Box>
            )
        },
    ], [values, t, props, columnFilters, statusesSel, showSelect]);

    const csv = async () => {
        setCsvOutput({headers: null, data: null});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axios.get('/api/productionordersexport', {params: params});
        let headers = response.data.headers;
        let csvData = response.data.data;
        setCsvOutput({headers: headers, data: csvData});
    }

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true,  columnVisibility: {id: false}},
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: false,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiSkeletonProps: {
            animation: 'none',
        },
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()}>
                        <RefreshIcon color="priary"/>
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('csv')}>
                    <IconButton onClick={() => csv()}>
                        <ExcelIcon color='primary'/>
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('clearListSettings')}>
                    <IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                {props.permission.create &&
                    <Tooltip arrow title={t('add')}>
                        <IconButton component={Link} to={'/eproductionorder'}>
                            <AddIcon color="primary"/>
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs}>
                <ActionBar/>
            </BreadcrumbBar>
            {csvOutput != null && csvOutput.data != null &&
                <CSVDownload data={csvOutput.data} target="_blank" headers={csvOutput.headers} separator={csvSeparator}/>
            }
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
        </Aux>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(ProductionOrders);
