import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { withTranslation } from 'react-i18next';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class Message extends Component{
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            title: '',
            body: '',
            recipients: [],
            userList: [],
            users: [],
            userListSel: [],
            messageId: '',
            sequence: '',
            sender: {},
            sending: true,
            replyList: [],
            replyListSel: [],
            read: true
        };
    }

    async componentDidMount(){
        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.user], this.props.auth.constants, null, this.props.auth.user.tenant);
        let allUsers = cacheValues.users;
        const messageId = this.props.match.params.id;
        this.setState({
            users: allUsers,
        }, () => {
            const crumbs = [
                { path: '/#/messages', label: this.props.t('messages'), screen: this.props.auth.screenDefs.Messages},
                { path: 'active', label: this.props.t('message'), screen: this.props.auth.screenDefs.Message}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
        if(messageId != null && messageId !== ''){
            var message = await axios.get('/api/message/' + messageId);
            var userListSel = [];
            var userList = [];
            var replyList = [];
            replyList.push(message.data.sender._id);
            var replyListSel = [];
            replyListSel.push({value: message.data.sender._id, label: message.data.sender.firstName + ' ' + message.data.sender.lastName});
            for(let recipient of message.data.recipients){
                let iteration = recipient;
                var userVal = allUsers.find(x => x.value === iteration.user._id);
                if(userVal != null){
                    userList.push(iteration.user._id)
                    userListSel.push(userVal);
                    if(iteration.user._id !== this.props.auth.user.id){
                        replyList.push(iteration.user._id);
                        replyListSel.push(userVal);
                    }
                }
                
            }
            this.setState({
                _id: message.data._id,
                message: message.data,
                userListSel: userListSel,
                userList: userList,
                title: message.data.title,
                body: message.data.body,
                messageId: message.data.messageId,
                sequence: message.data.sequence,
                sender: message.data.sender,
                sending: false,
                replyList: replyList,
                replyListSel: replyListSel
            });
            if(!message.data.read){
                this.markRead(message.data._id);
            }
        }
    }

    markRead = async id => {
        this.setState({read: true});
        await this._changeRead(id, true);
    };

    markUnread = async () => {
        this.setState({read: false});
        await this._changeRead(this.state._id, false);
    }

    async _changeRead(id, read){
        var data = {
            _id: id,
            user: this.props.auth.user.id,
            read: read
        }
        try {
            await axios.post('/api/messageread', data);
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeSelect = (name, data) => (e, value) => {
        var vals = [];
        if(value != null){
            value.forEach(val => {
                vals.push(val.value);
            });
        }
        this.setState({
            [name]: value, 
            [data]: vals
        });
    };

    send = async e => {
        var isValid = this.validate();
        if(!isValid)
            return;
        
        var data = {
            messageId: this.state.messageId,
            sequence: this.state.sequence,
            sender: this.props.auth.user.id,
            recipients: this.state.replyList,
            body: this.state.body,
            title: this.state.title
        }
        try{
            var result = await axios.post('/api/message', data);
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            this.props.history.goBack();
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    };

    validate(){
        const t = this.props.t;
        var errors = {};
        var state = this.state;
        if(state.title == null || state.title === '')
            errors[t('title')] = t('required');
        if(state.body == null || state.body === '')
            errors[t('body')] = t('required');
        if(state.replyList == null || state.replyList.length === 0)
            errors[t('userList')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    cancel = e => {
        this.props.history.goBack();
    };

    replyClick = e => {
        this.setState({sending: !this.state.sending});
    }

    render(){
        const errors = this.props.errors;
        const sending = this.state.sending;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar />
                <Panel>
                    <Grid container spacing={3}>
                        {!this.state.sending &&
                            <Aux>
                                <Grid item xs={11}>
                                    <FormControl>
                                        <FormLabel>{t('sender')}</FormLabel>
                                        {this.state.sender.firstName + ' ' + this.state.sender.lastName}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={11}>
                                    <AutoCompleteField
                                        multiple={true}
                                        value={this.state.userListSel}
                                        options={this.state.users}
                                        onChange={this.changeSelect('userListSel', 'userList')}
                                        error={errors[t('userList')] != null ? true : false}
                                        helperText={errors[t('userList')]}
                                        label={t('recipients')}
                                        disabled={!sending}
                                    />
                                </Grid>
                            </Aux>
                        }
                        {this.state.sending &&
                            <Grid item xs={11}>
                                <AutoCompleteField
                                    multiple={true}
                                    value={this.state.replyListSel}
                                    options={this.state.users}
                                    onChange={this.changeSelect('replyListSel', 'replyList')}
                                    error={errors[t('replyList')] != null ? true : false}
                                    helperText={errors[t('replyList')]}
                                    label={t('recipients')}
                                    disabled={!sending}
                                />
                            </Grid>
                        }
                        <Grid item xs={11}>
                            <TextField value={this.state.title} onChange={this.changeValue} name="title"
                            size="medium" fullWidth={true} disabled={!sending} label={t('title')}
                            error={errors[t('title')] != null ? true : false} helperText={errors[t('title')]}/>
                        </Grid>
                        <Grid item xs={11}>
                            <TextField value={this.state.body} onChange={this.changeValue} name="body"
                            multiline={true} rows="15"
                            variant="outlined" size="medium" fullWidth={true} disabled={!sending}
                            error={errors[t('body')] != null ? true : false} helperText={errors[t('body')]}/>
                        </Grid>
                    </Grid>
                    {sending &&
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button variant="contained" color="primary" size="small" onClick={this.send}>{t('send')}</Button>&nbsp;&nbsp;
                                <Button variant="contained" color="secondary" size="small" onClick={this.replyClick}>{t('cancel')}</Button>
                            </Grid>
                        </Grid>
                    }
                    {!sending &&
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <Button variant="contained" color="primary" size="small" onClick={this.replyClick}>{t('reply')}</Button>&nbsp;&nbsp;
                                <Button variant="contained" color="secondary" size="small" onClick={this.cancel}>{t('return')}</Button>&nbsp;&nbsp;
                                <Button variant="contained" color="secondary" size="small" onClick={this.markUnread}>{t('unread')}</Button>
                            </Grid>
                        </Grid>
                    }
                </Panel>
            </Aux>
        )
    }

}

const mapStateToProps = state => ({auth: state.auth, errors: state.errors.list});

export default withTranslation() (connect(mapStateToProps)(Message));