import { BrowserMultiFormatReader } from '@zxing/library';
import React, { useEffect, useMemo, useRef } from 'react';

const useZxing = ({
  constraints = {
    audio: false,
    video: {
      facingMode: 'environment',
    },
  },
  hints,
  timeBetweenDecodingAttempts = 300,
  onResult = () => {},
  onError = () => {},
}) => {
  const ref = useRef(null);

  const reader = useMemo(() => {
    const instance = new BrowserMultiFormatReader(hints);
    instance.timeBetweenDecodingAttempts = timeBetweenDecodingAttempts;
    return instance;
  }, [hints, timeBetweenDecodingAttempts]);

  useEffect(() => {
    if (!ref.current) return;
    reader.decodeFromConstraints(constraints, ref.current, (result, error) => {
      if (result) onResult(result);
      if (error) onError(error);
    });
    return () => {
      reader.reset();
    };
  }, [ref, reader, constraints, onResult, onError]);

  return { ref };
};

export const Scannerz = ({
    onResult = () => {},
    onError = () => {},
  }) => {
    const { ref } = useZxing({ onResult, onError });
    return (
        <div style={{ position: "relative" }}>
            <video ref={ref} width='100%' height='100%' />
            <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", border: "100px solid rgba(0, 0, 0, 0.5)", zIndex: 1 }}></div>
            <div style={{ position: "absolute", top: "100px", left: "100px", width: "calc(100% - 200px)", height: "calc(100% - 200px)", border: "2px solid #fff", zIndex: 2 }}></div>
        </div>        
    );
  };