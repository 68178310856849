import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import TextField from '../../UI/TextField/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Link from '../../UI/Link/Link';
import DeleteLinkIcon from '@mui/icons-material/ClearRounded';
import FormControlLabel from '@mui/material/FormControlLabel';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import isEmpty from '../../../is-empty';
import AutoCompleteField from '../../UI/AutoCompleteField/AutoCompleteField';
import { EvoraVerticalTabs, VerticalTabCanvas } from '../../UI/Tabs/Tabs';
import Aux from '../../../hoc/Auxilary/Auxilary';
import SectionTitle from '../../UI/Typography/SubSectionHeading';
import Switch from '../../UI/Switch/Switch';

class HelpSettings extends Component { 
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            enableSave: true,
            errors: {},
            screenName: '',
            tabLabels: [
                {name: t('general'), disabled: false},
            ],
            tabKey: 0,
            _id: '',
            name: '',
            screenDef: '',
            relatedLinks: [],
            global: true,
            tenants: [],
            tenantsSel: [],
            tenantList: [],
            widget: null,
            createdOn: '',
            createdBy: '',
            updatedOn: '',
            updatedBy: '',
            __v: ''
        }
    }

    componentDidMount = () => {
        if(!isEmpty(this.props.settingsData))
            this.loadRecord();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.settingsData !== this.props.settingsData) {
            this.loadRecord();
        }
    }

    loadRecord = () => {
        let relatedLinks = [];
        if(this.props.settingsData.relatedLinks != null && this.props.settingsData.relatedLinks !== '' && this.props.settingsData.relatedLinks.length > 0) {                
            this.props.settingsData.relatedLinks.forEach(link => {
                if(link.accordion === undefined)
                    link.accordion = false;
                if(link.type != null && link.type !== '')
                    link.typeSel = this.props.settingsData.types.find(x => x.value === link.type)
                relatedLinks.push(link);
            });
        }
        let tenantsSel = [];
        if(this.props.settingsData.tenants.length > 0) {
            this.props.settingsData.tenants.forEach(tenant => tenantsSel.push(this.props.settingsData.tenantList.find(x => x.value === tenant)));
        }
        this.setState({
            _id: this.props.settingsData._id,
            name: this.props.settingsData.name,
            screenDef: this.props.settingsData.screenDef,
            relatedLinks: relatedLinks,
            global: this.props.settingsData.global,
            tenants: this.props.settingsData.tenants,
            tenantsSel: tenantsSel,
            createdOn: this.props.settingsData.createdOn,
            createdBy: this.props.settingsData.createdBy,
            updatedOn: this.props.settingsData.updatedOn,
            updatedBy: this.props.settingsData.updatedBy,
            __v: this.props.settingsData.__v,
            initialLoad: true,
            types: this.props.settingsData.types,
            tenantList: this.props.settingsData.tenantList
        });
    }

    addLink = () => {
        let relatedLinks = this.state.relatedLinks;
        relatedLinks.push({
            accordion: true,
            type: '',
            typeSel: {},
            url: '',
            documentId: '',
            labels: [
                {locale: 'en', label: ''},
                {locale: 'fr', label: ''},
                {locale: 'sp', label: ''}
            ],
        });
        this.setState({relatedLinks});
    }

    changeBool = () => {
        let global = !this.state.global;
        this.setState({global});
    }

    changeAuto = (name, data, index) => (e, newValue) => {
        if(data === 'type') {
            let relatedLinks = this.state.relatedLinks;
            relatedLinks[index][name] = newValue;
            relatedLinks[index][data] = newValue != null ? newValue.value : null;
            this.setState({relatedLinks});
        }else if(data === 'tenants') {
            let tenants = [];
            let tenantsSel = [];
            if(newValue != null){
                newValue.forEach((row, i) => {
                    tenantsSel.push({value: row.value, label: row.label});
                    tenants.push(row.value);
                })
            }
            this.setState({tenants, tenantsSel});
        }
    };

    changeLabelValue = (index, labelIndex) => (e) => {
        let relatedLinks = this.state.relatedLinks;
        relatedLinks[index].labels[labelIndex][e.target.name] = e.target.value;
        this.setState({relatedLinks});
    }

    changeValue = (index) => (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let state = this.state;
        if(index != null && index !== '') 
            state.relatedLinks[index][name] = value;
        else
            state[name] = value;
        this.setState(state);
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    deleteLink = index => {
        let relatedLinks = this.state.relatedLinks;
        relatedLinks.splice(index, 1);
        this.setState({relatedLinks});
    }

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false}, () => this._save());
    }

    _save = async (e) => {
        const valid = this.validate();
        if(!valid){
            this.setState({ enableSave: true }, () => {return});
        }
        let relatedLinks = this.state.relatedLinks;
        const data = {
            _id: this.state._id,
            name: this.state.name,
            global: this.state.global,
            relatedLinks: relatedLinks,
            tenants: this.state.tenants,
            widget: this.state.widget,
            screenDef: this.state.screenDef
        };
        try {
            const result = await axios.post('/int/saveHelp', data);
            if(relatedLinks.length > 0) {
                relatedLinks.forEach(link => link.accordion = false);
            }
            this.setState({enableSave: true, relatedLinks}, () => {                
                this.props.callBack();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        }
    };

    validate = () => {
        const state = this.state;
        const t = this.props.t;
        let errors = {};
        if(state.name == null || state.name === '')
            errors[t('name')] = t('required');
        if(state.relatedLinks == null || state.relatedLinks === '')
            errors[t('name')] = t('required');
        if(state.relatedLinks.length > 0) {
            state.relatedLinks.forEach((link, index) => {
                let linkErrors = {};
                if(link.url == null || link.url === '')
                    linkErrors.url = t('required');
                if(link.type == null || link.type === '') 
                    linkErrors.type = t('required');
                if(link.labels.length > 0) {
                    link.labels.forEach((label, i) => {
                        let labelErrors = {};
                        if(label.locale == null || label.locale === '')
                            labelErrors.locale = t('required')
                        if(label.label == null || label.label === '')
                            labelErrors.label = t('required')
                        label.errors = labelErrors;
                    })
                }
                link.errors = linkErrors;
            })
        }
        if(!isEmpty(errors))
            this.setState({errors});
        return isEmpty(errors);
    }

    toggleLinkAccordion = (index) => {
        let relatedLinks = this.state.relatedLinks;
        relatedLinks[index].accordion = !relatedLinks[index].accordion
        this.setState({relatedLinks});
    }

    helpTabs = () => {            
        let tabs = [];
        const { t } = this.props;
        const help = this.state;
        tabs.push(
            <VerticalTabCanvas value={this.state.tabKey} index={0} key={'help-settings-' + 0}>
                <Grid item container xs={12} spacing={2}>
                    <Grid container item xs={12} spacing={2} sx={{ justifyContent: 'space-between' }} >
                        <SectionTitle title={t('details')} />
                        <hr />
                        <Grid item container xs={12} spacing={1} sx={{ justifyContent: 'space-between' }} >
                            <Grid item xs={9}>
                                <TextField value={help.name} onChange={this.changeValue()} name="name"
                                size="medium" fullWidth={true} label={t('name')} key='help-name' />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Switch checked={help.global} onChange={() => this.changeBool()} name="internal" />}
                                    label={t('global')}
                                    labelPlacement="start"
                                />
                            </Grid> 
                            <Grid item xs={1}>
                                <FormControl>
                                    <FormLabel>{t('version')}</FormLabel>
                                    {help.__v != null && help.__v !== '' ? help.__v : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={11}>
                                <AutoCompleteField
                                    multiple={true}
                                    stayOpen={true}
                                    value={help.tenantsSel}
                                    options={help.tenantList}
                                    getOptionLabel={(option) => option.label ? option.label : ''}
                                    onChange={this.changeAuto('tenantsSel', 'tenants')}
                                    label={t('tenants')}
                                    disabled={help.global}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <SectionTitle title={t('links')} 
                                    add={() => this.addLink()} 
                                    addTitle={t('add')} 
                                    />
                        <hr />
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow key={"help-TableHeader"} >
                                            <TableCell sx={{ width: '5%'}} />
                                            <TableCell sx={{ width: '5%'}}>{t('type')}</TableCell>
                                            <TableCell sx={{ width: '15%'}}>{t('label')}</TableCell>
                                            <TableCell>{t('URL')}</TableCell>
                                            <TableCell sx={{ width: '5%'}}>{t('documentId')}</TableCell>
                                            <TableCell sx={{ width: '5%'}} />
                                        </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(help.relatedLinks != null && help.relatedLinks !== '' && help.relatedLinks.length > 0) ?
                                        help.relatedLinks.map((link, i) => {
                                            return (
                                                <Aux key={'help-links-Auxrow-' + i}>
                                                    <TableRow key={'help-links-row-' + i + '-a'}>
                                                        <TableCell width="5%">
                                                            <IconButton aria-label="expand row" size="small" onClick={() => this.toggleLinkAccordion(i)}>
                                                                {!link.accordion ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell width="5%">
                                                            {link.typeSel != null && link.typeSel !== '' ? link.typeSel.label : ''}
                                                        </TableCell>
                                                        <TableCell sx={{ width: '15%'}}>{link.labels != null && link.labels !== '' ? link.labels[0].label : ''}</TableCell>
                                                        <TableCell>{link.url != null && link.url !== '' ? link.url : ''}</TableCell>
                                                        <TableCell width="5%">
                                                            {link.documentId}
                                                        </TableCell>
                                                        <TableCell width="5%">
                                                            <IconButton aria-label={"delete link-" + i} size="small" onClick={() => this.deleteLink(i)}>
                                                                <DeleteLinkIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={'help-links-row-' + i + '-b'}>
                                                        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                                                            <Collapse in={link.accordion} timeout="auto" unmountOnExit>
                                                                <Box margin={1}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={3}>
                                                                            <AutoCompleteField
                                                                                value={link.typeSel != null && link.typeSel !== '' ? link.typeSel : ''}
                                                                                options={help.types != null && help.types !== '' ? help.types : []}
                                                                                onChange={this.changeAuto('typeSel', 'type', i)}
                                                                                label={t('type')}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={9}>
                                                                            <TextField value={link.url} onChange={this.changeValue(i)} name="url"
                                                                                    size="medium" fullWidth={true} label={t('url')} key={`help-${i}-url`} />
                                                                        </Grid>
                                                                        <Grid item xs={9}>
                                                                            <TextField value={link.documentId} onChange={this.changeValue(i)} name="documentId"
                                                                                    size="medium" fullWidth={true} label={t('documentId')} key={`help-${i}-documentId`} />
                                                                        </Grid>
                                                                        {link.labels.map((label, li) => {
                                                                            return (
                                                                                <Grid item container xs={12} spacing={1}>
                                                                                    <Grid item xs={2}>
                                                                                        <TextField value={label.locale} onChange={this.changeLabelValue(i, li)} name="locale"
                                                                                                    size="medium" fullWidth={true} label={t('locale')}  key={`help-${i}-locale-${li}`}/>
                                                                                    </Grid>
                                                                                    <Grid item xs={9}>
                                                                                        <TextField value={label.label} onChange={this.changeLabelValue(i, li)} name="label"
                                                                                                    size="medium" fullWidth={true} label={t('label')} key={`help-${i}-label-${li}`}/>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )})
                                                                        }
                                                                    </Grid>
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow> 
                                                </Aux>
                                            )
                                        }) : <TableRow key={'help-links-row-1'}>
                                                <TableCell colSpan={4} align='center'>
                                                    <strong>
                                                        <Link color="primary" onClick={() => this.addLink()}>
                                                            {t('clickHereMessage.1')}
                                                        </Link>
                                                    </strong>                                                        
                                                    {t('clickHereMessage.2')}
                                                </TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>    
                    </Grid>
                    <Grid container item xs={12} sx={{ justifyContent: 'space-between' }} >
                        <Grid item xs={5}>
                            <FormControl>
                                <FormLabel>{t('created')}</FormLabel>
                                {help.createdBy != null ? help.createdBy.fullName : ''}<br />
                                {help.createdOn != null ? help.createdOn : ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl>
                                <FormLabel>{t('updated')}</FormLabel>
                                {help.updatedBy != null ? help.updatedBy.fullName : ''}<br />
                                {help.updatedOn != null ? help.createdOn : ''}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sx={{ justifyContent: 'flex-end' }} >
                        <Grid item>
                            <Button onClick={this.props.toggleDialog()} variant='text' color='secondary' key='screen-settings-close-btn'>Close</Button>
                            <Button onClick={() => this.save()}
                                    variant='text' color='primary' 
                                    disabled={!this.state.enableSave} key='screen-settings-help-submit-btn'>{this.props.t('save')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </VerticalTabCanvas>
        );
        return tabs;
    };

    render() {
        const { tabLabels, tabKey } = this.state;
        return (
            <Aux>
                <Grid container spacing={2}>
                    <br/>
                    <EvoraVerticalTabs TabContents={this.helpTabs} tabLabels={tabLabels} tabKey={tabKey} changeTab={this.changeTab} />                
                </Grid>
            </Aux>            
        );
    }
}

const mapStateToProps = state => ({});

export default withTranslation() (connect(mapStateToProps)(HelpSettings));