import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import dateFormat from 'date-fns/format';
import ActionBar from '../UI/Buttons/ActionBar';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import { CSVDownload } from "react-csv";
import ConversionHelper from '../../helpers/conversionHelper';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';

const Logs = (props) => {
    const isFirstRender = useRef(true);
    const [loadRefData, setLoadRefData] = useState(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [values, setValues] = useState({objectTypes: [], actions: [], users: [], tenants: []});
    const csvSeparator = sessionStorage.getItem('csvSeparator');
    const [csvOutput, setCsvOutput] = useState({headers: null, data: null});
    const { t } = useTranslation('translation');
    const crumbs = [
        { path: 'active', label: t('logs'), screen: props.auth.screenDefs.LogList}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        const columnFilters = localStorage.getItem('LogsColumnFilters');
        const columnVisibility = localStorage.getItem('LogsColumnVisibility',);
        const density = localStorage.getItem('LogsDensity');
        const globalFilter = localStorage.getItem('LogsGlobalFilter');
        const showGlobalFilter = localStorage.getItem('LogsShowGlobalFilter',);
        const showColumnFilters = localStorage.getItem('LogsShowColumnFilters',);
        const sorting = localStorage.getItem('LogsSorting');
        const pagination = localStorage.getItem('LogsPagination');
        if (columnFilters) {
            let filters = JSON.parse(columnFilters);
            filters = ConversionHelper.filtersDateParse(filters);
            setColumnFilters(filters);
        }
        if (columnVisibility) {
            setColumnVisibility(JSON.parse(columnVisibility));
        }
        if (density) {
            setDensity(JSON.parse(density));
        }
        if (globalFilter) {
            setGlobalFilter(JSON.parse(globalFilter) || undefined);
        }
        if (showGlobalFilter) {
            setShowGlobalFilter(JSON.parse(showGlobalFilter));
        }
        if (showColumnFilters) {
            setShowColumnFilters(JSON.parse(showColumnFilters));
        }
        if (sorting) {
            setSorting(JSON.parse(sorting));
        }
        if (pagination) {
            setPagination(JSON.parse(pagination));
        }
        isFirstRender.current = false;
    }, [values]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('LogsColumnFilters', JSON.stringify(columnFilters),);
    }, [columnFilters]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('LogsColumnVisibility', JSON.stringify(columnVisibility),);
    }, [columnVisibility]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('LogsDensity', JSON.stringify(density));
    }, [density]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('LogsGlobalFilter', JSON.stringify(globalFilter ?? ''),);
    }, [globalFilter]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('LogsShowGlobalFilter', JSON.stringify(showGlobalFilter),);
    }, [showGlobalFilter]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('LogsShowColumnFilters', JSON.stringify(showColumnFilters),);
    }, [showColumnFilters]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('LogsSorting', JSON.stringify(sorting));
    }, [sorting]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('LogsPagination', JSON.stringify(pagination));
    }, [pagination]);

    const resetState = () => {
        localStorage.removeItem('LogsColumnFilters');
        localStorage.removeItem('LogsColumnVisibility');
        localStorage.removeItem('LogsDensity');
        localStorage.removeItem('LogsGlobalFilter');
        localStorage.removeItem('LogsShowGlobalFilter');
        localStorage.removeItem('LogsShowColumnFilters');
        localStorage.removeItem('LogsSorting');
        localStorage.removeItem('LogsPagination');
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.action, common.cacheValues.object, common.cacheValues.user], 
                props.auth.constants, null, props.auth.user.tenant);
            const values = await axios.get('/api/logvalues');
            setValues({
                objectTypes: cacheValues.objects,
                actions: cacheValues.actions,
                users: cacheValues.users,
                tenants: values.data.tenants
            });
            setLoadRefData(false);
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, columnFilters, loadRefData]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'Logs',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/logsquery', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });
    let columns = useMemo(() => {
        if(props.auth.user.internal){
            return [
                {
                    accessorKey: 'date',
                    id: 'date',
                    header: t('date'),
                    filterVariant: 'date-range',
                    Cell: ({ cell, column }) => (
                        <Box>
                            <Link to={cell.row.original?.link}>{dateFormat(Date.parse(cell.getValue()), common.dateTimeFormat)}</Link>
                        </Box>
                    )
                },
                {
                    accessorFn: (row) => row.objectType.text != null ? row.objectType.text : row.objectType,
                    id: 'objectType',
                    header: t('object'),
                    filterVariant: 'select',
                    filterSelectOptions: values.objectTypes
                },
                {
                    accessorKey: 'action',
                    id: 'action',
                    header: t('action'),
                    filterVariant: 'select',
                    filterSelectOptions: values.actions
                },
                {
                    accessorKey: 'user',
                    id: 'user',
                    header: t('user'),
                    filterVariant: 'select',
                    filterSelectOptions: values.users
                },
                {
                    accessorKey: 'tenant',
                    id: 'tenant',
                    header: t('tenant'),
                    filterVariant: 'select',
                    filterSelectOptions: values.tenants
                }
            ]
        } else {
            return [
                {
                    accessorKey: 'date',
                    id: 'date',
                    header: t('date'),
                    filterVariant: 'date-range',
                    Cell: ({ cell, column }) => (
                        <Box>
                            <Link to={cell.row.original?.link}>{dateFormat(Date.parse(cell.getValue()), common.dateTimeFormat)}</Link>
                        </Box>
                    )
                },
                {
                    accessorKey: 'objectType',
                    id: 'objectType',
                    header: t('object'),
                    filterVariant: 'select',
                    filterSelectOptions: values.objectTypes
                },
                {
                    accessorKey: 'action',
                    id: 'action',
                    header: t('action'),
                    filterVariant: 'select',
                    filterSelectOptions: values.actions
                },
                {
                    accessorKey: 'user',
                    id: 'user',
                    header: t('user'),
                    filterVariant: 'select',
                    filterSelectOptions: values.users
                }
            ]
        }
    }, [values, t, props.auth.user.internal]);

    const csv = async () => {
        setCsvOutput({headers: null, data: null});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axios.get('/api/logsexport', {params: params});
        let headers = response.data.headers;
        let csvData = response.data.data;
        setCsvOutput({headers: headers, data: csvData});
    }

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true,  columnVisibility: {id: false}},
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: false,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiSkeletonProps: {
            animation: 'none',
        },
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()}>
                        <RefreshIcon color='primary'/>
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('csv')}>
                    <IconButton onClick={() => csv()}>
                        <ExcelIcon color='primary'/>
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('clearListSettings')}>
                    <IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
    });

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs}>
                <ActionBar/>
            </BreadcrumbBar>
            {csvOutput != null && csvOutput.data != null &&
                <CSVDownload data={csvOutput.data} target="_blank" headers={csvOutput.headers} separator={csvSeparator}/>
            }
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
        </Aux>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Logs);