import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class WorkCenterList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            orderBy: 'number',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            room: '',
            roomSel: null,
            rooms: [],
            number: '',
            name: '',
            security: []
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.room], this.props.auth.constants, null, this.props.auth.user.tenant);
        let storage = sessionStorage.getItem('workCenters');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        this.setState({
            rooms: cacheValues.rooms,
            security: this.props.permission,
            offset: storage != null ? storage.offset : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'number',
            ordDir: storage != null ? storage.ordDir : -1,
            room: storage != null ? storage.room : '',
            roomSel: storage != null ? storage.roomSel : null,
            number: storage != null ? storage.number : '',
            name: storage != null ? storage.name : '',
            page: storage != null ? storage.page : 0
        }, () => {
            this.loadRecords();
        });
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            number: this.state.number,
            room: this.state.room,
            roomSel: this.state.roomSel,
            name: this.state.name,
            page: this.state.page
        };
        sessionStorage.setItem('workCenters', JSON.stringify(data));
    }

    loadRecords = async () => {
        var sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            offset: this.state.offset,
            size: this.state.listSize,
            sort: JSON.stringify(sort),
            room: this.state.room,
            number: this.state.number,
            name: this.state.name
        };
        let list = await axios.get('/api/workcenters', {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        });
        const crumbs = [
            { path: 'active', label: this.props.t('workCenters'), screen: this.props.auth.screenDefs.WorkCenterList}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        this.saveFilters();
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value, offset: 0, page: 0}, () => this.loadRecords());
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render() {
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/workcenter'
                        createAccess={security.create}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.number} onChange={this.changeValue} name="number"
                            size="medium" fullWidth={true} label={t('number')}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.name} onChange={this.changeValue} name="name"
                            size="medium" fullWidth={true} label={t('name')}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.roomSel}
                                options={this.state.rooms}
                                onChange={this.changeAuto('roomSel', 'room')}
                                label={t('room')}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="workCenterListTable">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('name')}
                                        active={this.state.orderBy === 'name'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('name')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('room')}
                                        active={this.state.orderBy === 'room'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('room')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) => 
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/workcenter/' + row._id} name={'workCenterLink' + i}>{row.number}</Link></TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.room != null ? row.room.name : ''}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(WorkCenterList));