import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../../is-empty';
import Comments from '../../General/Comments';
import ObjectLog from '../../General/ObjectLog';
import Document from '../../General/Document';
import DateSelect from '../../UI/DateSelect/DateSelect';
import TextField from '../../UI/TextField/TextField';
import Aux from '../../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../../UI/AutoCompleteField/AutoCompleteField';
import { SaveButton } from '../../UI/Buttons/Buttons';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import common from '../../../jsons/common.json';
import ValueHelper from '../../../helpers/valueHelper';
import ModalDialog from '../../UI/Dialog/ModalDialog';

class BatchObservation extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            batch: this.props.batch,
            activity: this.props.activity,
            date: new Date(),
            batchDays: '',
            user: '',
            userSel: null,
            users: [],
            observation: '',
            documents: [],
            errors: {},
            onClose: this.props.onClose,
            openStatus: this.props.open,
            rooms: [],
            roomsSel: [],
            roomList: [],
            tables: [],
            tablesSel: [],
            tableList: [],
            subTypeList: [],
            type: '',
            typeSel: null,
            types: [],
            subTypes: [],
            subTypesSel: [],
            priorities: [],
            priority: '',
            prioritySel: null,
            locked: false,
            enableSave: true,
            renderNum: Math.random(),
            form: '',
            hasForm: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.observationType, common.cacheValues.priority, common.cacheValues.batchObservationForm], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        const userValues = await ValueHelper.screenUsers(common.screens.batchActivity, this.props.auth.user.tenant);
        if(this.props.batch != null && this.props.batch !== ''){
            let values = await axios.get('/api/batchobservationvalues', {params: {batch: this.props.batch}});
            let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
            this.setState({
                _id: this.props.id,
                batch: this.props.batch,
                date: this.props.date != null ? this.props.date : new Date(),
                activity: this.props.activity,
                users: userValues.create,
                roomList: values.data.rooms,
                tableList: values.data.tables,
                types: cacheValues.observationTypes,
                priorities: cacheValues.priorities,
                form: (this.props.id == null || this.props.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
                hasForm: hasForm
            }, () => {
                if(this.state._id != null && this.state._id !== '' && this.state.openStatus)
                    this.loadRecord();
            });
        } else {
            this.setState({users: userValues.create});
        }
        
    }

    async componentDidUpdate(prevProps){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.observationType, common.cacheValues.priority, common.cacheValues.batchObservationForm], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        if(prevProps.batch !== this.props.batch){
            let values = await axios.get('/api/batchobservationvalues', {params: {batch: this.props.batch}});
            this.setState({
                _id: this.props.id,
                batch: this.props.batch,
                activity: this.props.activity,
                date: this.props.date != null ? this.props.date : new Date(),
                roomList: values.data.rooms,
                tableList: values.data.tables,
                types: cacheValues.observationTypes,
                priorities: cacheValues.priorities
            });
        }
        if(prevProps.open !== this.props.open || prevProps.id !== this.props.id){
            this.setState({
                _id: this.props.id,
                batch: this.props.batch,
                activity: this.props.activity,
                openStatus: this.props.open
            }, () => {
                this.loadRecord();
            });
        }
    }

    loadRecord = async() => {
        if(this.state._id != null && this.state._id !== undefined && this.state._id !== ''){
            let roomList = this.state.roomList;
            let tableList = this.state.tableList;
            let record = await axios.get('/api/batchobservation/' + this.state._id);
            record.data.roomList.forEach(row => {
                let exists = roomList.find(x => x.value === row.value);
                if(!exists)
                    roomList.push(row);
            });
            record.data.tableList.forEach(row => {
                let exists = tableList.find(x => x.value === row.value);
                if(!exists)
                    tableList.push(row);
            });
            let userSel = this.state.users.find(x => x.value === record.data.user);
            let prioritySel = record.data.priority != null ? this.state.priorities.find(x => x.value === record.data.priority) : null;
            let subTypesSel = [];
            if(record.data.subTypes != null && record.data.subTypes.length > 0){
                record.data.subTypes.forEach(row => {
                    let val = record.data.subTypeList.find(x => x.value === row);
                    subTypesSel.push(val);
                });
            };
            let typeSel = record.data.type != null ? this.state.types.find(x => x.value === record.data.type) : null;
            let roomsSel = [];
            if(record.data.rooms != null && record.data.rooms.length > 0){
                record.data.rooms.forEach(row => {
                    let val = roomList.find(x => x.value === row);
                    roomsSel.push(val);
                });
            }
            let tablesSel = [];
            if(record.data.tables != null && record.data.tables.length > 0){
                record.data.tables.forEach(row => {
                    let val = tableList.find(x => x.value === row);
                    tablesSel.push(val);
                });
            }
            let hasForm = this.state.hasForm;
            if(!hasForm && record.data.form != null && record.data.form !== '')
                hasForm = true;
            let locked = record.data.activity != null && record.data.activity.status !== this.props.auth.constants.statuses.new && 
                            record.data.activity.status !== this.props.auth.constants.statuses.inprogress;
            this.setState({
                _id: record.data._id,              
                number: record.data.number,
                batch: record.data.batch,
                activity: record.data.activity,
                date: record.data.date,
                batchDays: record.data.batchDays,
                user: record.data.user,
                userSel: userSel,
                observation: record.data.observation,
                documents: record.data.documents,
                priority: record.data.priority,
                prioritySel: prioritySel,
                subTypeList: record.data.subTypeList,
                subTypes: record.data.pests,
                subTypesSel: subTypesSel,
                type: record.data.type,
                typeSel: typeSel,
                rooms: record.data.rooms,
                roomsSel: roomsSel,
                tables: record.data.tables,
                tablesSel: tablesSel,
                roomList: roomList,
                tableList: tableList,
                locked: locked,
                renderNum: Math.random(),
                form: record.data.form != null ? JSON.parse(record.data.form) : null,
                hasForm: hasForm
            });
        }
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(data === 'type'){
            if(newValue != null){
                let values = await axios.get('/api/subvalues/' + this.props.auth.common.observationType + '/' + newValue.value);
                this.setState({subTypeList: values.data, subTypes: [], subTypesSel: []});
            } else {
                this.setState({subTypeList: [], subTypes: [], subTypesSel: []});
            }
        }
    };

    changeMulti = (name, data) => (e, newValue) => {
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    valueChanged = (e) => {
        this.setState({value: e});
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async (e) => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = this.state.errors;
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.setState({errors: errors, enableSave: true});
                return;
            }
        }         
        let data = {
            _id: this.state._id,
            batch: this.state.batch,
            activity: this.state.activity != null && this.state.activity !== '' ? this.state.activity._id : null,
            date: this.state.date,
            user: this.state.user,
            observation: this.state.observation,
            priority: this.state.priority,
            type: this.state.type,
            subTypes: this.state.subTypes,
            rooms: this.state.rooms,
            tables: this.state.tables,
            form: form != null && form !== '' ? JSON.stringify(form) : null
        };
        try {
            let result = await axios.post('/api/batchobservation', data);
            this.setState({_id: result.data.id, number: result.data.number, enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.onClose();
            });            
        } catch(err){
            this.setState({ enableSave: true }, () =>{
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        if(this.state.user == null || this.state.user === '')
            errors[t('user')] = t('required');
        if(this.state.date == null || this.state.date === '')
            errors[t('date')] = t('required');
        if(this.state.observation == null || this.state.observation === '')
            errors[t('observation')] = t('required');
        if(this.state.rooms == null || this.state.rooms.length === 0)
            errors[t('rooms')] = t('required');        
        if(this.state.pests != null && this.state.pests.length > 0){
            if(this.state.priority == null || this.state.priority === '')
                errors[t('priority')] = t('required');
        }
        this.setState({errors: errors});
        return isEmpty(errors);
    }

    buttonStack = () => {
        const { locked, enableSave } = this.state;
        let buttons = [
            <Button variant='text' onClick={this.state.onClose(this.state._id)} enabled 
                    key='batchObservationClearButton' color='secondary' >{this.props.t('clear')}</Button>,
            <SaveButton saveClick={this.save} enabled={!locked && enableSave} key='batchObservationSaveButton'/>
        ];
        return buttons;
    }

    render(){
        const errors = this.state.errors;
        const locked = this.state.locked;
        const t = this.props.t;
        return(
            <ModalDialog buttonStack={this.buttonStack()} title={t('batchObservation')} toggleDialog={this.state.onClose(this.state._id)} 
                         dialogStatus={this.state.openStatus} maxWidth="sm" fullWidth>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl>
                            <FormLabel>{t('number')}</FormLabel>
                            {this.state._id != null && this.state._id !== '' ? this.state.number : t('none')}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl>
                            <FormLabel>{t('activity')}</FormLabel>
                            {this.state.activity != null && this.state.activity.number !== '' ? this.state.activity.number : t('none')}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        {!locked &&
                            <DateSelect
                                onChange={this.dateChange('date')}
                                value={this.state.date}
                                helperText={errors[t('date')]}
                                error={errors[t('date')] != null ? true : false}
                                label={t('date')}
                                format={this.props.auth.user.dateFormat}
                                disabled={locked}
                                required
                                fullWidth
                            />
                        }
                        {locked &&
                            <FormControl>
                                <FormLabel>{t('date')}</FormLabel>
                                <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {!locked &&
                            <AutoCompleteField
                                value={this.state.userSel}
                                options={this.state.users}
                                onChange={this.changeAuto('userSel', 'user')}
                                error={errors[t('user')] != null ? true : false}
                                helperText={errors[t('user')]}
                                label={t('user')}
                                disabled={locked}
                                required
                            />
                        }
                        {locked &&
                            <FormControl>
                                <FormLabel>{t('user')}</FormLabel>
                                {this.state.userSel != null ? this.state.userSel.label : ''}
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {!locked &&
                            <AutoCompleteField
                                value={this.state.typeSel}
                                options={this.state.types}
                                onChange={this.changeAuto('typeSel', 'type')}
                                error={errors[t('type')] != null ? true : false}
                                helperText={errors[t('type')]}
                                label={t('type')}
                                disabled={locked}
                            />
                        }
                        {locked &&
                            <FormControl>
                                <FormLabel>{t('type')}</FormLabel>
                                {this.state.typeSel != null ? this.state.typeSel.label : ''}
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {!locked &&
                            <AutoCompleteField
                                value={this.state.prioritySel}
                                options={this.state.priorities}
                                onChange={this.changeAuto('prioritySel', 'priority')}
                                error={errors[t('priority')] != null ? true : false}
                                helperText={errors[t('priority')]}
                                label={t('priority')}
                                disabled={locked}
                            />
                        }
                        {locked &&
                            <FormControl>
                                <FormLabel>{t('priority')}</FormLabel>
                                {this.state.prioritySel != null ? this.state.prioritySel.label : ''}
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <AutoCompleteField
                            stayOpen
                            multiple
                            value={this.state.subTypesSel}
                            options={this.state.subTypeList}
                            onChange={this.changeMulti('subTypesSel', 'subTypes')}
                            error={errors[t('subTypes')] != null ? true : false}
                            helperText={errors[t('subTypes')]}
                            label={t('subTypes')}
                            disabled={locked || this.state.subTypeList == null || this.state.subTypeList.length === 0}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutoCompleteField
                            multiple={true}
                            value={this.state.roomsSel}
                            options={this.state.roomList}
                            onChange={this.changeMulti('roomsSel', 'rooms')}
                            error={errors[t('rooms')] != null ? true : false}
                            helperText={errors[t('rooms')]}
                            label={t('rooms')}
                            disabled={locked}
                            required
                        />
                    </Grid>
                    {this.state.tableList != null && this.state.tableList.length > 0 &&
                        <Grid item xs={12}>
                            <AutoCompleteField
                                multiple={true}
                                value={this.state.tablesSel}
                                options={this.state.tableList}
                                onChange={this.changeMulti('tablesSel', 'tables')}
                                error={errors[t('tables')] != null ? true : false}
                                helperText={errors[t('tables')]}
                                label={t('tables')}
                                disabled={locked}
                            />
                        </Grid>
                    }
                </Grid>
                {this.state.hasForm === true &&
                    <Aux>
                        <br/>
                        {errors[t('form')] != null && errors[t('form')] !== '' &&
                            <div style={{color: 'red'}}>{errors[t('form')]}</div>
                        }
                        <Form renderer={renderer}
                            defaultFields={this.state.form}
                            onChange={this.valueChanged}
                            disabled={locked}/>
                    </Aux>
                }
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        {!locked &&
                            <TextField value={this.state.observation} onChange={this.changeValue} name="observation"
                            size="medium" fullWidth={true} label={t('observation')} disabled={locked} required multiline rows="4"
                            error={errors[t('observation')] != null ? true : false} helperText={errors[t('observation')]}/>
                        }
                        {locked &&
                            <FormControl>
                                <FormLabel>{t('observation')}</FormLabel>
                                {this.state.observation}
                            </FormControl>
                        }
                    </Grid>
                </Grid>
                {this.state._id != null && this.state._id !== '' &&
                    <Aux>
                        <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.batchObservation} tab={this.props.tab}/>
                        <ObjectLog id={this.state._id} renderNum={this.state.renderNum}/>
                        <Document record={this.state._id} documents={this.state.documents}
                            objectType={this.props.auth.constants.objectIds.batchObservation} callback={this.loadRecord}/>
                    </Aux>
                }
            </ModalDialog>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(BatchObservation));