import React, { Component } from 'react';
import axios from 'axios';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import { withTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import GeneratePdf from '../General/GeneratePdf';
import RichText from '../../hoc/RichText/RichText';
import Panel from '../UI/Panel/Panel';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import SectionTitle from '../UI/Typography/SectionTitle';
import DocumentDisplay from '../General/DocumentDisplay';
import { DialogTitle } from '../UI/Dialog/ModalDialog';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import { ModalToaster } from '../UI/Toaster/Toaster';
import DownloadButton from './Buttons/Download';
import SelectField from '../UI/SelectField/SelectField';
import TextField from '../UI/TextField/TextField';

import BillOfLading from './Templates/BillOfLading';
import ChainOfCustody from './Templates/ChainOfCustody';
import CultivationBatch from './Templates/CultivationBatch';
import CultivationActivity from './Templates/CultivationActivity';
import CuringActivity from './Templates/CuringActivity';
import CuringOrder from './Templates/CuringOrder';
import DeviationRecord from './Templates/Deviation';
import DryingActivity from './Templates/DryingActivity';
import DryingOrder from './Templates/DryingOrder';
import Invoice from './Templates/Invoice';
import IPMTreatment from './Templates/IPMTreatment';
import ManagedDocument from './Templates/ManagedDocument';
import ProductionOrderActivity from './Templates/ProductionOrderActivity';
import ProductionOrder from './Templates/ProductionOrder';
import ProductionBatch from './Templates/ProductionBatch';
import RoomCleaning from './Templates/Cleaning';
import RoomFeeding from './Templates/RoomFeeding';
import TrimmingActivity from './Templates/TrimmingActivity';
import TrimmingOrder from './Templates/TrimmingOrder';

function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props}/>
      </Draggable>
    );
}

class PrintView extends Component{
    constructor(props){
        super(props);
        this.state = {
            id: '',
            object: '',
            record: null,
            printSetting: '',
            printSettingSel: {},
            printSettings: [],
            internal: false, 
            open: false,
            printAccess: false,
            downloadFile: null,
            systemMessage: null,
            form: '',
            tenantReport: '',
            fileName: '',
            errors: {}
        }
    }

    componentDidMount(){
        this.setState({
            open: this.props.open,
            id: this.props.id,
            object: this.props.object,
            printAccess: this.props.printAccess != null ? this.props.printAccess : false,
            form: this.props.form != null ? this.props.form : null,
            tenantReport: this.props.tenantReport != null ? this.props.tenantReport : null
        }, () => {
            this.loadRecord();
        });
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.open !== this.props.open){
            this.setState({
                open: this.props.open,
                id: this.props.id,
                object: this.props.object,
                printAccess: this.props.printAccess != null ? this.props.printAccess : false,
                form: this.props.form != null ? this.props.form : null,
                tenantReport: this.props.tenantReport != null ? this.props.tenantReport : null
            }, () => {
                this.loadRecord();
            });
        }
    }

    completeTransaction = (data) => {
        this.setState({ 
            downloadFile: data.finalDocument,
            systemMessage: {
                message: data.message, 
                title: this.props.t('success'), 
                severity: 'success',
                onClose: () => this.setState({ systemMessage: null })
            }
        });

        if(this.props.callBack != null){
            this.props.callBack();
        }
    }

    loadRecord = async () => {
        const constants = this.props.auth.constants;
        const t = this.props.t;
        let urlString = '/api/printView/' + this.state.object + '/' + this.state.id + 
                        (this.state.tenantReport != null && this.state.tenantReport !== '' ? '/' + this.state.tenantReport : '/none');
        const values = await axios.get(urlString);

        let { printSetting, printSettingSel } = this.state;
        let printSettings = [];
        if(this.state.tenantReport != null && this.state.tenantReport !== '' && 
           values?.data?.reports != null && values?.data?.reports !== '' && values.data.reports.length > 1) {
            for(let report of values.data.reports) {
                if(this.state.tenantReport === 'bol' && 
                   report.data.report.value === this.props.auth.common.tenantReports.billOfLading) {
                    printSettings.push(report);
                }
                if(this.state.tenantReport === 'coc' && 
                   report.data.report.value === this.props.auth.common.tenantReports.chainOfCustody) {
                    printSettings.push(report);
                }
                if(this.state.tenantReport === 'invoice' && 
                   report.data.report.value === this.props.auth.common.tenantReports.saleOrderInvoice) {
                    printSettings.push(report);
                }
            }
        }else {
            printSettings = values.data.reports;
        }
        let internal = this.state.object === constants.objectIds.managedDocument && 
                    values.data.record.format !== constants.documentFormat.internal ? false : true;
        if(printSetting === '') {
            if(printSettings > 1) {
                if(printSettings.some(x => x.default === true && x.global === false)) {
                    printSettingSel = printSettings.find(x => x.default === true && x.global === false);
                }else if(printSettings.some(x => x.default === true && x.global === true)) {
                    printSettingSel = printSettings.find(x => x.default === true && x.global === true);
                }else {
                    printSettingSel = printSettings[0];
                }
            }else {
                printSettingSel = printSettings[0];
            }
            printSetting = printSettingSel != null && printSettingSel !== '' ? printSettingSel.value : '';
        }
        let dialogTitle = '';
        switch(this.state.object) {
            case constants.objectIds.evoraBatch: {
                dialogTitle = t('cultivationBatch') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.batchActivity: {
                dialogTitle = t('batchActivity') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.cropTreatment: {
                dialogTitle = t('cropTreatment') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.curingActivity: {
                dialogTitle = t('curingActivity') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.curing: {
                dialogTitle = t('curingOrder') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.deviation: {
                dialogTitle = t('deviationRecord') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.dryingActivity: {
                dialogTitle = t('dryingActivity') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.dryingOrder: {
                dialogTitle = t('dryingOrder') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.managedDocument: {
                dialogTitle = t('managedDocument') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.invoice: {
                dialogTitle = t('invoice') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.sale: {
                dialogTitle = t('saleOrder') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.productionBatch: {
                dialogTitle = t('productionBatch') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.productionOrder: {
                dialogTitle = t('productionOrder') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.poActivity: {
                dialogTitle = t('poActivity') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.roomCleaning: {
                dialogTitle = t('roomCleaning') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.roomFeeding: {
                dialogTitle = t('roomFeeding') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.shipment: {
                switch(this.props.tenantReport) {
                    case 'bol': {
                        dialogTitle = t('billOfLading') + ' - ' + values.data.record.number;
                        break;
                    }
                    case 'coc': {
                        dialogTitle = t('chainOfCustody') + ' - ' + values.data.record.number;
                        break;
                    }
                    default: 
                        dialogTitle = t('printViewer');
                        break;
                }
                break;
            }
            case constants.objectIds.trimmingActivity: {
                dialogTitle = t('trimmingActivity') + ' - ' + values.data.record.number;
                break;
            }
            case constants.objectIds.trimmingOrder: {
                dialogTitle = t('trimmingOrder') + ' - ' + values.data.record.number;
                break;
            }
            default: {
                dialogTitle = t('printViewer');
                break;
            }
        }
        let downloadFile = null;
        if(this.state.object === constants.objectIds.sale && this.state.tenantReport != null && this.state.tenantReport !== '') {
            if(values?.data?.record?.invoices != null && 
               values?.data?.record?.invoices !== '' && 
               values?.data?.record?.invoices.length > 0) {
                let result = values.data.record.invoices.find(x => x.shipment === this.state.tenantReport);
                if(result != null && result !== '') {
                    downloadFile = result.invoice;
                }
            }else if(values?.data?.record?.invoice != null && values?.data?.record?.invoice !== '') {
                downloadFile = values.data.record.invoice;
            }
        }else if(this.state.object === constants.objectIds.shipment && this.state.tenantReport != null && this.state.tenantReport !== '') {
            if(this.state.tenantReport === 'bol') {
                downloadFile = values?.data?.record?.bol != null && values?.data?.record?.bol !== '' ?
                              values.data.record.bol : null;
            }else if(this.state.tenantReport === 'coc') {
                downloadFile = values?.data?.record?.coc != null && values?.data?.record?.coc !== '' ?
                              values.data.record.coc : null;
            }else {
                downloadFile = values?.data?.record?.finalDocument != null && values?.data?.record?.finalDocument !== '' ?
                                values.data.record.finalDocument : null;
            }
        }else {
            downloadFile = values?.data?.record?.finalDocument != null && values?.data?.record?.finalDocument !== '' ?
                            values.data.record.finalDocument : null;
        }
        
        this.setState({
            dialogTitle: dialogTitle,
            printSetting: printSetting,
            printSettingSel: printSettingSel,
            printSettings: printSettings,
            record: values.data.record,
            internal: internal,
            downloadFile: downloadFile,
            fileName: downloadFile?.name != null && downloadFile?.name !== '' ? 
                      downloadFile.name : dialogTitle + '.pdf'
        });
    }

    generateMarkup(){
        const document = this.getDocument(true);
        var htmlData = ReactDOMServer.renderToString(document);
        return htmlData;
    }

    getDocument = (print) => {
        const constants = this.props.auth.constants.objectIds;
        switch(this.state.object) {
            case constants.evoraBatch: {
                return (
                    <CultivationBatch record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            case constants.batchActivity: {
                return (
                    <CultivationActivity record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            case constants.curingActivity: {
                return (
                    <CuringActivity record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            case constants.curing: {
                return (
                    <CuringOrder record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            case constants.deviation: {
                return (
                    <DeviationRecord record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            case constants.cropTreatment: {
                return (
                    <IPMTreatment record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }            
            case constants.dryingActivity: {
                return (
                    <DryingActivity record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            case constants.dryingOrder: {
                return (
                    <DryingOrder record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            case constants.invoice: {
                return (
                    <Invoice record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat} />
                )
            }
            case constants.managedDocument: {
                if(this.state.internal){
                    return (
                        <ManagedDocument auth={this.props.auth} data={this.state} t={this.props.t} dateFormat={this.props.auth.user.dateFormat}/>
                    );
                }
                return;
            }
            case constants.productionBatch: {
                return (
                    <ProductionBatch record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            case constants.productionOrder: {
                return (
                    <ProductionOrder record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            case constants.poActivity: {
                return (
                    <ProductionOrderActivity record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            case constants.roomCleaning: {
                return (
                    <RoomCleaning record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            case constants.roomFeeding: {
                return (
                    <RoomFeeding record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            case constants.sale: {
                return (
                    <Invoice record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            case constants.shipment: {
                let record = this.state.record;
                switch(this.props.tenantReport) {
                    case 'bol': {
                        if(record?.record?.documentParameters?.title != null && record?.record?.documentParameters?.title !== '') 
                            record.record.documentParameters.title = this.props.t('billOfLading');
                        return (
                            <BillOfLading record={record} t={this.props.t} constants={this.props.auth.constants}
                                          printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                        )
                    }
                    case 'coc': {
                        if(record?.record?.documentParameters?.title != null && record?.record?.documentParameters?.title !== '') 
                            record.record.documentParameters.title = this.props.t('chainOfCustody');
                        return (
                            <ChainOfCustody record={record} t={this.props.t} constants={this.props.auth.constants}
                                            printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                        )
                    }
                    default: 
                        return;
                }
            }
            case constants.trimmingActivity: {
                return (
                    <TrimmingActivity record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            case constants.trimmingOrder: {
                return (
                    <TrimmingOrder record={this.state.record} t={this.props.t} constants={this.props.auth.constants}
                    printView={print} dateFormat={this.props.auth.user.dateFormat}/>
                )
            }
            default:
                return;
        }
    }

    changeSettings = event => {
        const printSettingSel = this.state.printSettings.find(x => x.value === event.target.value);
        this.setState({
            printSetting: printSettingSel.value,
            printSettingSel: printSettingSel,
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    render(){
        const { dialogTitle, downloadFile, errors, id, internal, fileName, object, open, printSetting, 
                printSettings, printSettingSel, record } = this.state;
        const t = this.props.t;
        const document = this.getDocument(false);
        return(
            <Dialog
                open={open}  
                onClose={() => this.props.onClose()} 
                aria-labelledby="draggable-dialog-title" 
                PaperComponent={PaperComponent}
                fullWidth
                maxWidth='md'
                scroll='paper'
                >
                    <DialogTitle id="draggable-dialog-title" sx={{ m: 0, p: 1, cursor: 'move', backgroundColor: '#e5e3e3' }} onClose={() => this.props.onClose()} >
                        {dialogTitle}
                    </DialogTitle>
                    <DialogContent dividers sx={{ p: 2 }}>
                        {internal &&
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Panel>
                                        <SubSectionHeading title={t('header')} /><br />
                                        {printSettingSel != null && printSettingSel.data != null && printSettingSel.data.header != null && printSettingSel.data.header.header &&
                                            <RichText>
                                                {printSettingSel.data.header.header != null && printSettingSel.data.header.header !== '' && printSettingSel.data.header.header}
                                            </RichText>
                                        }
                                    </Panel>
                                </Grid>
                                <Grid item xs={12}>
                                    <Panel>
                                        <SectionTitle title={t('content')} />
                                        {document}
                                    </Panel>
                                </Grid>
                                <Grid item xs={12}>
                                    <Panel>
                                        <SubSectionHeading title={t('footer')} /><br />
                                        {printSettingSel != null && printSettingSel.data != null && printSettingSel.data.header != null && printSettingSel.data.header.footer &&
                                            <RichText>
                                                {printSettingSel.data.header.footer != null && printSettingSel.data.header.footer !== '' && printSettingSel.data.header.footer}
                                            </RichText>
                                        }
                                    </Panel>
                                </Grid>
                            </Grid>
                        }
                        {!internal && record != null && record.contentDocument != null &&
                            <DocumentDisplay document={record.contentDocument}/>
                        }
                        <ModalToaster alert={this.state.systemMessage} />
                    </DialogContent>
                    <DialogActions sx={{ m: 0, p: 1 }}>
                        <Grid container spacing={2} justifyContent='space-between'>
                            <Grid item xs={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={5}>
                                        <SelectField
                                            label={t('printSetting')}
                                            name="printSetting"
                                            options={printSettings}
                                            onChange={this.changeSettings}
                                            value={printSetting}
                                            fullWidth
                                            disabled={printSettings.length < 2}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField value={fileName} onChange={this.changeValue} name="fileName"
                                        size="medium" fullWidth label={t('fileName')} required
                                        error={errors[t('fileName')] != null ? true : false} helperText={errors[t('fileName')]}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ mt: 1 }}>
                                {this.state.downloadFile != null && this.state.downloadFile !== '' ?
                                    <DownloadButton record={this.state.downloadFile} t={this.props.t}/> : null
                                }
                                <Button 
                                    name='printClose'
                                    variant="text" 
                                    size='small' 
                                    color="secondary" 
                                    onClick={() => this.props.onClose()}>{this.props.t('close')}</Button>
                                <GeneratePdf
                                    callBack={(file) => this.completeTransaction(file)}
                                    clearDownload={() => this.setState({ downloadFile: null})}
                                    file={downloadFile == null ? false : true}
                                    form={this.state.form}
                                    tenantReport={this.state.tenantReport}
                                    id={id}
                                    objectType={object}
                                    markup={() => this.generateMarkup()}  
                                    printSetting={printSetting} 
                                    printSettingSel={printSettingSel} 
                                    printSettings={printSettings}  
                                    fileName={fileName} />
                            </Grid>
                        </Grid>
                    </DialogActions>
            </Dialog>       
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile
});

export default withTranslation() (connect(mapStateToProps)(PrintView));
