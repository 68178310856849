import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import Aux from '../../../hoc/Auxilary/Auxilary';
import DateDisplay from '../DateDisplay/DateDisplay';
import AutoComplete from '../AutoCompleteField/AutoCompleteField';
import DateSelect from '../DateSelect/DateSelect';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '../TextField/TextField';
import isEmpty from '../../../is-empty';
import { encryptString } from '../../../helpers/securityHelper';
import ModalDialog from '../Dialog/ModalDialog';
import IconButton from '@mui/material/IconButton';
import SignatureSignIcon from '@mui/icons-material/BorderColorTwoTone';
import SignatureViewIcon from '@mui/icons-material/PageviewTwoTone';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

class Signature extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            user: '',
            users: '',
            userSelected: null,
            signature: '',
            _id: null,
            object: '',
            objectType: '',
            type: '',
            date: new Date(),
            showSignature: false,
            tenant: this.props.auth.user.tenant,
            errors: {},
            pinSign: false,
            pin: '',
            title: null,
            name: null,
            isOpen: false,
            enableSave: true,
            wasteWitness: false
        }
        this.sigPad = {};
    }

    componentDidMount(){
        this.setSignature(this.props);
    }

    componentDidUpdate(prevProps) {
        if(this.props.signature !== prevProps.signature || this.props.users !== prevProps.users) {
            this.setSignature(this.props);
        }
    }

    setSignature = async data => {
        if(data.signature != null && data.signature !== ''){
            this.setState({
                _id: data.signature._id,
                signature: data.signature,
                user: data.user,
                object: data.object,
                objectType: data.objectType,
                type: data.type,
                users: data.users,
                date: data.date != null && data.date !== '' ? data.date : new Date(),
                pinSign: false,
                title: data.title, 
                name: data.name,
                wasteWitness: this.props.wasteWitness
            })
        } else {
            let user = data.user;
            let userSelected = data.users.find(x => x.value === data.user);
            if(!userSelected && data.users.length === 1){
                userSelected = data.users[0];
                user = data.users[0].value;
            }
            let userHasPin = false;
            if(userSelected){
                const result = await axios.get('/api/userhaspin/' + userSelected.value);
                userHasPin = result.data.hasPin;
            }               
            this.setState({
                user: user,
                object: data.object,
                objectType: data.objectType,
                type: data.type,
                users: data.users,
                userSelected: userSelected,
                pinSign: userHasPin,
                title: data.title, 
                name: data.name, 
                date: new Date(), 
                wasteWitness: this.props.wasteWitness
            });
        }
    }

    createButtonStack = () => {
        const t = this.props.t;
        let buttonStack = [];
        let name = this.state.name;
        let pinExists = this.state.pinSign || false;
        if((this.props.signature == null && this.props.signature === '') && pinExists === false){
            buttonStack.push(<Button key={name+'-clearButton'} variant="text" color="secondary" size="small" onClick={this.clearSig}>{t('clear')}</Button>);
        }
        buttonStack.push([
            <Button key={name+'-hideButton'} variant='text' color="primary" size="small" onClick={this.toggleDialog}>{t('close')}</Button>,
            
        ]);
        if(this.props.signature == null || this.props.signature._id === '') {
            buttonStack.push(<Button key={name+'-saveButton'} variant="contained" color="secondary" size="small" onClick={this.saveSig} disabled={!this.state.enableSave}>{t('sign')}</Button>);
        }
        return buttonStack;
    }

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    changeSelect = (name, data) => async (e, value) => {
        this.setState({
            [name]: value,
            [data]: value != null ? value.value : null
        });
        if(data === "user"){
            if(value != null){
                let userHasPin = await axios.get('/api/userhaspin/' + value.value);
                this.setState({pinSign: userHasPin.data.hasPin});
            } else {
                this.setState({pinSign: false});
            }
        }
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    dateChange = e => {
        this.setState({date: e});
    };

    clearSig = () => {
        if(!this.state.pinSign){
            this.sigPad.clear();
        }
    };

    saveSig = () => {
        if(this.state.enableSave === false)
            return;
        this.setState({enableSave: false}, () => {
            this._saveSig();
        });
    };

    _saveSig = async () => {
        const t = this.props.t;
        let valid = this.validateSignature();
        if(!valid){
            this.setState({enableSave: true});
            return;
        }
        if(this.props.validate != null){
            let error = this.props.validate();
            if(error != null && error !== ''){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: error, title: this.props.t('error'), severity: 'error'}});
                return;
            }
        }
        let signature = null;
        if(this.state.pinSign){
            let pinEnc = encryptString(this.state.pin);
            let pinResult = await axios.post('/api/userpin', {userid: this.state.user, pin: pinEnc});
            if(pinResult.data.signature != null && !pinResult.data.expired){
                signature = pinResult.data.signature;
            } else {
                if(pinResult.data.expired){
                    this.setState({errors: { [t('pin')]: t('pinExpired')}, enableSave: true});
                } else{
                    this.setState({errors: { [t('pin')]: t('pinInvalid')}, enableSave: true});
                }
                return;
            }
        } else {
            signature = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
        }
        if(signature == null || signature === ''){
            let errors = {};
            errors[t('signature')] = t("required");
            this.setState({errors: errors, enableSave: true});
            return;
        }
        let data = {
            _id: this.props.signature != null && this.props.signature._id != null && this.props.signature._id !== '' ?
                this.props.signature._id : null,
            signature: signature,
            user: this.state.user,
            object: this.props.object,
            objectType: this.state.objectType,
            type: this.state.type,
            date: this.state.date,
            complete: this.props.complete
        };
        if(this.props.saveCallback == null){
            try {
                let result = await axios.post('/api/signature', data);
                this.props.setId(result.data.id);
                this.setState({enableSave: true});
                this.toggleDialog();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('signatureSaved'), title: t('success'), severity: 'success'}});
            } catch(err){
                this.setState({enableSave: true});
                if(err.response != null)
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.props.saveCallback(data);
            this.setState({ showSignature: false, enableSave: true });
        }
    };

    validateSignature(){
        const t = this.props.t;
        let errors = {};
        let date = this.state.date;
        if(this.state.user == null || this.state.user === '' || this.state.userSelected == null)
            errors[t('user')] = t('required');
        if(date == null || date === '')
            date = new Date();
        if(this.state.pinSign){
            if(this.state.pin == null || this.state.pin === '')
                errors[t('pin')] = t('required');
        }
        this.setState({errors: errors, date: date});
        return isEmpty(errors);
    }

    sign = e => {
        const t = this.props.t;
        let error = null;
        if(this.props.validate != null){
            error = this.props.validate();
            if(error != null)
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: error, title: t('error'), severity: 'error'}});
        }
        if(error == null)
            this.setState({showSignature: true});
    };

    toggleDialog = e => {
        if(this.state.showSignature){
            this.setState({ showSignature: false, pin: '', errors: {} })
        }else {
            this.setState({ showSignature: true });
        }      
    };

    render() {
        const { date, errors, name, pinSign, showSignature, signature, title, users, userSelected, wasteWitness } = this.state;
        const t = this.props.t;

        const signIconStyle = {
            color: 'primary.main',
            fontSize: '0.9em'
        };
        
        const signIconDisabledStyle = {
            color: 'grey.500',
            fontSize: '0.9em'
        };
        
        const viewIconStyle =  {
            color: 'secondary.main',
            fontSize: '0.9em'
        };
        
        const viewIconDisabledStyle =  {
            color: 'grey.500',
            fontSize: '0.9em'
        };
        
        const labelStyle =  {
            color: 'grey.600',
            fontFamily: 'Ubuntu',
            fontSize: '1.5em',
            fontWeight: 'bold',
            fontVariant: 'small-caps',
        };
        
        const dataStyle = {
            ml: 1,
            fontSize: '1.35em',
            fontWeight: 'semi-bold',
            fontVariant: 'small-caps',
        };
        return(
            <Aux>
                <IconButton edge="end" aria-label={name + '-signature-action'} onClick={this.toggleDialog} disabled={this.props.disabled}>
                    {this.props.signature != null && this.props.signature._id !== '' && this.props.signature.user != null ? 
                        <SignatureViewIcon sx={this.props.disabled ? viewIconDisabledStyle : viewIconStyle} /> : 
                        <SignatureSignIcon sx={this.props.disabled ? signIconDisabledStyle : signIconStyle}/>
                    }
                </IconButton>
                 <ModalDialog 
                    buttonStack={this.createButtonStack()}
                    title={title}
                    toggleDialog={this.toggleDialog}
                    dialogStatus={showSignature}
                    fullWidth >
                        {this.props.signature != null && this.props.signature._id !== '' && this.props.signature.user != null ? 
                            <Grid container>
                                <Grid item xs={12} md container>
                                    <Grid item xs={5}>
                                        <span style={labelStyle}>Name:</span><br />
                                        <span style={dataStyle}>{signature != null && signature !== '' &&
                                        signature.user.firstName + ' ' +
                                            signature.user.lastName }</span><br/>
                                        <span style={labelStyle}>Date:</span><br/>
                                        <span style={dataStyle}><DateDisplay format={this.props.auth.user.dateFormat + ' hh:mm aa'} value={signature != null && signature !== '' ? signature.date : null}/></span>
                                    </Grid>
                                    <Grid item xs={7}>
                                        {signature != null && signature !== '' &&
                                            <img src={signature.signature} alt={t('noSignature')} height={'80%'} width={'80%'}/>
                                        }
                                    </Grid>
                                    {wasteWitness &&
                                        <Grid item xs={12}>
                                            <br />
                                            <Typography variant='body2' >{t('signDestructionWitness')}</Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid> : 
                            <Aux>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <AutoComplete
                                            value={userSelected}
                                            options={users}
                                            error={errors[t('user')] != null ? true : false}
                                            getOptionLabel={(option) => option.label}
                                            onChange={this.changeSelect('userSelected', 'user')}
                                            renderInput={(params) => <TextField {...params} label="User" />}
                                            helperText={errors[t('user')]}
                                            label={t('user')}
                                            disabled={false}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <DateSelect
                                            label={t('date')}
                                            value={date}
                                            onChange={this.dateChange}
                                            helperText={errors[t('date')]}
                                            error={errors[t('date')] != null ? true : false}
                                            format={this.props.auth.user.dateFormat}
                                            fullWidth
                                        />
                                    </Grid>
                                    {pinSign &&
                                        <Grid item xs={12} md={2}>
                                            <TextField value={this.state.pin} onChange={this.changeNumber} name="pin"
                                            size="medium" fullWidth={true} disabled={false} label={t('pin')} type={'password'}
                                            error={errors[t('pin')] != null ? true : false} helperText={errors[t('pin')]}/>
                                        </Grid>
                                    }
                                </Grid>
                                {!pinSign &&
                                    <Box component="div" borderRadius="borderRadius" sx={{border: "1px solid #9E9E9E", marginTop: "16px"}}>
                                        <SignatureCanvas penColor="#5BC6CC" canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                                        ref={(ref) => {this.sigPad = ref}}/>
                                    </Box>
                                }
                                {wasteWitness &&
                                    <Typography variant='body2' style={{ fontWeight:'bold' }}><br />{t('signDestructionWitness')}</Typography>
                                }
                                <br />
                                <Typography variant='caption' style={{ fontStyle:'italic' }}>{t('signatureDisclaimer')}</Typography>
                            </Aux>
                        }
                    </ModalDialog>
               </Aux>
          )
     }
}

const mapStateToProps = state => ({
    profile: state.profile.profile,
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(Signature));
