import React, { Component } from 'react';
import axios from 'axios';
import qs from 'qs';
import DateSelect from '../UI/DateSelect/DateSelect';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ReferenceLine,
    Tooltip,
    ResponsiveContainer
} from "recharts";

class TestResultLine extends Component{
    constructor(props){
        super(props);
        this.state = {
            category: '',
            categories: [],
            itemOptions: [],
            items: [],
            data: [],
            tests: [],
            test: '',
            expectedValues: [],
            startDate: '',
            endDate: new Date(),
            testSel: null,
            catSel: null,
            itemSel: []
        };
    }

    async componentDidMount(){
        var values = await axios.get('/api/testlinevalues');
        var items = [];
        var cat = this.props.category;
        var parmItems  = this.props.items ?? [];
        var test = this.props.test;
        var catSel = null;
        var itemSel = [];
        if(cat != null && cat !== ''){
            items = await axios.get('/api/testcategoryitemselect/' + cat);
            catSel = values.data.categories.find(x => x.value === cat);
            if(parmItems != null && parmItems !== '' && parmItems.length > 0){
                for(let item of parmItems) {
                    var itemVal = items.data.find(x => x.value === item);
                    if(itemVal != null){
                        itemSel.push(itemVal);
                    }
                };
            }
        }
        var testSel = null;
        if(test != null && test !== ''){
            testSel = values.data.tests.find(x => x.value === test);
        }
        var timeDiff = this.props.cookies != null ? this.props.cookies.get('testLineDateDiff') : 365;
        var date = new Date();
        var startDate = date.setDate(date.getDate() - timeDiff);
        this.setState({
            categories: values.data.categories,
            tests: values.data.tests,
            category: cat != null ? cat : '',
            itemOptions: items != null && items.data != null ? items.data : [],
            items: parmItems,
            catSel: catSel,
            itemSel: itemSel,
            test: test != null ? test : '',
            testSel: testSel,
            startDate: startDate
        }, () => this.loadValues());
    }

    loadValues = async () => {
        if(this.state.category != null && this.state.category !== '' && this.state.items != null && this.state.items.length > 0 &&
            this.state.test != null && this.state.test !== '' && this.state.startDate != null && this.state.startDate !== '' &&
            this.state.endDate != null && this.state.endDate !== ''){
            let items = [];
            if(!Array.isArray(this.state.items))
                items.push(this.state.items);
            else
                items = this.state.items;
            var query = {
                test: this.state.test,
                items: items,
                category: this.state.category,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            };
            var result = await axios.get('/api/testitemscore', {params: query, paramsSerializer: params =>
                 {return qs.stringify(params, {arrayForamt: 'brackets'})}});
            this.setState({
                data: result.data.data, 
                expectedValues: result.data.expectedValues, 
                chartData: result.data != null && result.data.data != null && result.data.data[0] != null ? result.data.data[0].data : null, 
                expected: result.data.expectedValues != null && result.data.expectedValues[0] != null ? result.data.expectedValues[0] : 0});
        }
    }

    changeCategory = async (e, newValue) => {
        var value = newValue != null ? newValue.value : null;
        if(value != null){
            var items = await axios.get('/api/testcategoryitemselect/' + value);
            this.setState({
                category: value,
                itemOptions: items.data,
                items: [],
                itemSel: [],
                catSel: newValue
            }, () => {this.loadValues()});
        } else {
            this.setState({items: [], itemSel: [], itemOptions: []})
        }
    };

    changeItem = async (e, newValue) => {
        var ids = [];
        newValue.forEach(row => {
            ids.push(row.value);
        });
        this.setState({items: ids, itemSel: newValue}, () => {this.loadValues()});
    }

    changeTest = (e, newValue) => {
        var value = newValue;
        this.setState({test: newValue != null ? newValue.value : null, testSel: value}, () => {this.loadValues()});
    }

    changeStart = e => {
        //var dayDiff = this.calculateDateDiff(e, this.state.endDate);
        this.setState({startDate: e}, () => {this.loadValues()});
    }

    changeEnd = e => {
        //var dayDiff = this.calculateDateDiff(this.state.startDate, e);
        this.setState({endDate: e}, () => {this.loadValues()});
    }

    calculateDateDiff(startDate, endDate){
        if(startDate == null || startDate === '' || endDate == null || endDate === '')
            return null;
        var timeDiff = endDate.getTime() - startDate.getTime();
        var dayDiff = timeDiff / (1000 * 3600 * 24);
        return dayDiff;
    }

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <SectionTitle title={t('testResultLine')}/>
                <Grid container spacing={3}>
                    {this.state.tests != null && this.state.tests.length > 0 &&
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.testSel}
                                options={this.state.tests}
                                onChange={this.changeTest}
                                label={t('test')}
                            />
                        </Grid>
                    }
                    {this.state.categories != null && this.state.categories.length > 0 &&
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.catSel}
                                options={this.state.categories}
                                onChange={this.changeCategory}
                                label={t('category')}
                            />
                        </Grid>
                    }
                    <Grid item xs={4} sm={2}>
                        <DateSelect
                            className='form-control'
                            onChange={this.changeStart}
                            value={this.state.startDate}
                            label={t('startDate')}
                            format={this.props.auth.user.dateFormat}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <DateSelect
                            className='form-control'
                            onChange={this.changeEnd}
                            value={this.state.endDate}
                            label={t('endDate')}
                            format={this.props.auth.user.dateFormat}
                        />
                    </Grid>
                    {this.state.itemOptions != null && this.state.itemOptions.length > 0 &&
                        <Grid item sm={10}>
                            <AutoCompleteField
                                multiple
                                limitTags={5}
                                value={this.state.itemSel}
                                options={this.state.itemOptions}
                                onChange={this.changeItem}
                                label={t('items')}
                            />
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={3}>
                    <Grid item sm={12}>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart
                                width={1000}
                                height={300}
                                data={this.state.chartData}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="x" />
                                <YAxis />
                                <Tooltip />
                                {this.state.expected != null && this.state.expected !== 0 &&
                                    <ReferenceLine y={this.state.expected} stroke="red" />
                                }
                                <Line type="monotone" dataKey="y" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    </Grid>
                </Grid>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(TestResultLine));
