import React, { Component } from 'react';
import axios from 'axios';
import GeneratePdf from '../General/GeneratePdf';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

class ComponentToPrint extends Component {
    
    render(){
        const t = this.props.t;
        return(
            <div>
                <h2>{t('cropTreatment')}: {this.props.data.number}</h2>
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('status')}</th>
                            <th width="200">{t('date')}</th>
                            <th width="200">{t('treatmentType')}</th>
                            {this.props.data.issue != null &&
                                <th>{t('issue')}</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data != null ? this.props.data.status : ''}</td>
                            <td><DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.date}/></td>
                            <td>{this.props.data.treatmentType}</td>
                            {this.props.data.issue != null &&
                                <td>{this.props.data.issue}</td>
                            }
                        </tr>
                    </tbody>
                </table>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('room')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.room}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th width="300">{t('pesticide')}</th>
                            <th width="150">{t('preparation')}</th>
                            <th width="150">{t('container')}</th>
                            <th>{t('quantity')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.pesticides.map((row, i) =>
                            <tr>
                                <td>{row.item.number + '-' + row.item.name}</td>
                                <td>{row.preparation != null ? row.preparation.number : ''}</td>
                                <td>{row.container != null ? row.container.number : ''}</td>
                                <td>{row.quantity + row.uom}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <br/>
                {this.props.data.ebatches != null && this.props.data.ebatches.length > 0 &&
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('batches')}</th>
                            <th width="200">{t('authorizedBy')}</th>
                            <th>{t('location')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.ebatches.map((row) =>
                                <div>{row.number}&nbsp;</div>
                            )}</td>
                            <td>{this.props.data.authorizedBy}</td>
                            <td>{this.props.data.location}</td>
                        </tr>
                    </tbody>
                </table>
                }
                <br/>
                   
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('applicationMethod')}</th>
                            <th width="200">{t('startTime')}</th>
                            <th>{t('endTime')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.applicationMethod}</td>
                            <td><DateDisplay format="yyyy/MM/dd hh:mm a" value={this.props.data.startTime}/></td>
                            <td><DateDisplay format="yyyy/MM/dd hh:mm a" value={this.props.data.stopTime}/></td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <div><strong>{t('description')}</strong></div>
                <div>{this.props.data.description}</div>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('receiver')}</th>
                            <th width="200">{t('reviewer')}</th>
                            <th>{t('signoff')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.user != null ? this.props.data.user.user.firstName + ' ' +
                            this.props.data.user.user.lastName : ''}</td>
                            <td>{this.props.data.reviewer != null ? this.props.data.reviewer.user.firstName + ' ' +
                            this.props.data.reviewer.user.lastName : ''}</td>
                            <td>{this.props.data.signer != null ? this.props.data.signer.user.firstName + ' ' +
                            this.props.data.signer.user.lastName : ''}</td>
                        </tr>
                        <tr>
                            <td>{this.props.data.user != null && <DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.user.date}/>}</td>
                            <td>{this.props.data.reviewer != null && <DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.reviewer.date}/>}</td>
                            <td>{this.props.data.signer != null && <DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.signer.date}/>}</td>
                        </tr>
                        <tr>
                            <td>{this.props.data.user != null && <img src={this.props.data.user.signature} alt={t('noSignature')}/>}</td>
                            <td>{this.props.data.reviewer != null && <img src={this.props.data.reviewer.signature} alt={t('noSignature')}/>}</td>
                            <td>{this.props.data.signer != null && <img src={this.props.data.signer.signature} alt={t('noSignature')}/>}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
            </div>
        )
    }
}

class CropTreatmentPrint extends Component {
    constructor(props){
        super(props);
        this.state = {
             _id: '',
            date: new Date(),
            ebatches: [],
            status: '',
            room: '',
            issue: '',
            user: null,
            pesticide: {},
            pesticides: [],
            quantity: '',
            quantityUOM: '',
            description: '',
            authorizedBy: '',
            location: '',
            reviewer: null,
            signer: null,
            treatmentType: '',
            applicationMethod: '',
            startTime: '',
            stopTime: '',
            number: ''
        };
    }

    async componentDidMount(){
        var record = await axios.get('/api/croptreatmentprint/' + this.props.match.params.id);
        this.setState({
            _id: record.data._id,
            date: record.data.date,
            ebatches: record.data.ebatches,
            room: record.data.room,
            issue: record.data.issue,
            user: record.data.user,
            pesticides: record.data.pesticides,
            description: record.data.description,
            authorizedBy: record.data.authorizedBy,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            status: record.data.status,
            location: record.data.location,
            treatmentType: record.data.treatmentType,
            applicationMethod: record.data.applicationMethod,
            startTime: record.data.startTime,
            stopTime: record.data.stopTime,
            number: record.data.number
        });
    }

    returnClick () {
        this.props.history.goBack();
    }

    generateMarkup(){
        var htmlData = ReactDOMServer.renderToStaticMarkup(<ComponentToPrint data={this.state} t={this.props.t} auth={this.props.auth}/>);
        return htmlData;
    }

    render(){
        const t = this.props.t;
        return(
            <div className="container">
                <ComponentToPrint data={this.state} t={t} auth={this.props.auth}/>
                <div className="row">
                    <Button variant="contained" size="small" color="primary" onClick={() => this.returnClick()}>{t('return')}</Button>&nbsp;&nbsp;
                    <GeneratePdf markup={() => this.generateMarkup()} id={this.props.match.params.id} objectType={this.props.auth.constants.objectIds.cropTreatment}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default withTranslation() (connect(mapStateToProps)(CropTreatmentPrint));

