import React from 'react';
import ReportTitle from '../../UI/Typography/ReportTitle';
// import ReportSectionTitle from '../../UI/Typography/ReportSectionTitle';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';

const boxStyle = {
    margin: '8px',
    width: '100%'
}

const thStyle = {
    borderBottom: '2px solid rgb(224, 224, 224)', 
    fontSize: '9pt', 
    textAlign: 'center'
}

const tdStyle = { 
    border: '0.5px solid rgb(224, 224, 224)', 
    fontSize: '8pt', 
    padding: '5px',
}

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse'
}

export default function capaTemplate(props) {
    const { dateFormat, record, sequence, t } = props;
    return (
        <div style={{ width: '8.5in', 
                      pageBreakBefore: sequence !== false && sequence > 2 ? 'always' : 'avoid' }}>
            <div style={boxStyle}>
                <ReportTitle 
                    title={
                        (sequence != null && sequence !== '' ? 
                            sequence + '. ' + t('correctiveMeasure') + ': ' + 
                            (record.title != null && record.title !== '' ? 
                                record.title : null 
                            ) : t('correctiveMeasure') + ': ' +
                            (record.title != null && record.title !== '' ? 
                                record.title : null 
                            )
                        )
                    }
                />
            </div>
            <div style={{ ...boxStyle, display: 'block' }}>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '100px' }}>{t('number')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('status')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('category')}</th>
                            <th style={{ ...thStyle, width: '200px' }}>{t('department')}</th>
                            <th style={{ ...thStyle, width: '100px' }}>{t('occurenceDate')}</th>
                            <th style={{ ...thStyle, width: '150px' }}>{t('location')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.number != null && record.number !== '' ? record.number : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.status != null && record.status !== '' ? record.status : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.category != null && record.category !== '' ? record.category : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.department != null && record.department !== '' ? record.department : t('na')}
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.occurrenceDate != null ?
                                    <DateDisplay value={record.occurrenceDate} format={dateFormat}/> : t('na')
                                }
                            </td>
                            <td style={{ ...tdStyle, textAlign: 'center' }}>
                                {record.location != null && record.location !== '' ? record.location : t('na')}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}