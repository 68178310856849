import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ModalDialog from '../UI/Dialog/ModalDialog';
import Button from '@mui/material/Button';
import DateSelect from '../UI/DateSelect/DateSelect';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { MoveButton } from '../UI/Buttons/Buttons';
import isEmpty from '../../is-empty';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import { CSVDownload } from "react-csv";
import { Checkbox, FormControlLabel } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import AutoComplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import dateFormat from 'date-fns/format';
import ActionBar from '../UI/Buttons/ActionBar';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';
import { connect } from 'react-redux';
import listHelper from '../../helpers/listHelper';
import ListMultiSelect from '../UI/ListMultiSelect/ListMultiSelect';

const ContainerList = (props) => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const isFirstRender = useRef(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({
        id: false,
        packagedOn: false
    });
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [columnOrder, setColumnOrder] = useState([]);
    const [values, setValues] = useState({items: [], bins: [], types: [], categories: [], reportCategories: [], ratings: [], uoms: []});
    const [openMoveDialog, setOpenMoveDialog] = useState(false);
    const [enableSave, setEnableSave] = useState(true);
    const [moveBinSel, setMoveBinSel] = useState(null);
    const [moveDate, setMoveDate] = useState(new Date());
    const [errors, setErrors] = useState({});
    let fileName = dateFormat(new Date(), props.auth.user.dateFormat) + '-' + t('containers') + '.csv';
    fileName = fileName.replace(/\//g, '-');
    const [csvOutput, setCsvOutput] = useState({headers: null, data: null, fileName: fileName});
    const [itemSel, setItemSel] = useState(null);
    const csvSeparator = sessionStorage.getItem('csvSeparator');
    const tenant = props.auth.user.tenant;
    const [categoriesSel, setCategoriesSel] = useState({ids: [], values: []});
    const [showSelect, setShowSelect] = useState(false);
    const crumbs = [
        { path: 'active', label: t('containers'), screen: props.auth.screenDefs.ContainerList}
    ]
    dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        async function fetchData(){
            const columnFilters = await listHelper.getItem('ContainersColumnFilters', tenant);
            const columnVisibility = await listHelper.getItem('ContainersColumnVisibility', tenant);
            const density = await listHelper.getItem('ContainersDensity', tenant);
            const globalFilter = await listHelper.getItem('ContainersGlobalFilter', tenant);
            const showGlobalFilter = await listHelper.getItem('ContainersShowGlobalFilter', tenant);
            const showColumnFilters = await listHelper.getItem('ContainersShowColumnFilters', tenant);
            const sorting = await listHelper.getItem('ContainersSorting', tenant);
            const pagination = await listHelper.getItem('ContainersPagination', tenant);
            const tempColumnOrder = await listHelper.getItem('ContainersColumnOrder', tenant);
            if (columnFilters) {
                let filters = JSON.parse(columnFilters);
                setColumnFilters(filters);
                let cat = filters.find(x => x.id === 'category');
                if(cat != null){
                    let sel = {ids: cat.value, values: []};
                    cat.value.forEach(id => {
                        let val = values.categories.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setCategoriesSel(sel);
                }
            }
            if (columnVisibility) {
                setColumnVisibility(columnVisibility);
            }
            if (density) {
                setDensity(density);
            }
            if (globalFilter) {
                setGlobalFilter(globalFilter);
            }
            if (showGlobalFilter) {
                setShowGlobalFilter(showGlobalFilter);
            }
            if (showColumnFilters) {
                setShowColumnFilters(showColumnFilters);
            }
            if (sorting) {
                setSorting(sorting);
            }
            if (pagination) {
                setPagination(pagination);
            }
            if (tempColumnOrder != null) {
                setColumnOrder(tempColumnOrder);
            } else {
                setColumnOrder(['mrt-row-select', 'number', 'item', 'lot.number', 'quantity', 'uom', 'available', 'reserved', 'bin', 'type', 'expires', 'rating', 'category', 'count', 'reportCategory', 'packagedOn']);
            }
            isFirstRender.current = false;
        }
        if(isFirstRender.current === true){
            fetchData();
        }
    }, [values, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ContainersColumnFilters', tenant, JSON.stringify(columnFilters));
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ContainersColumnVisibility', tenant, columnVisibility);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnVisibility, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ContainersDensity', tenant, density);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [density, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ContainersGlobalFilter', tenant, globalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [globalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ContainersShowGlobalFilter', tenant, showGlobalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [showGlobalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ContainersShowColumnFilters', tenant, showColumnFilters);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [showColumnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ContainersSorting', tenant, sorting);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [sorting, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ContainersPagination', tenant, pagination);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [pagination, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ContainersColumnOrder', tenant, columnOrder);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnOrder, tenant]);

    const resetState = async () => {
        await listHelper.removeItem('ContainersColumnFilters', tenant);
        await listHelper.removeItem('ContainersColumnVisibility', tenant);
        await listHelper.removeItem('ContainersDensity', tenant);
        await listHelper.removeItem('ContainersGlobalFilter', tenant);
        await listHelper.removeItem('ContainersShowGlobalFilter', tenant);
        await listHelper.removeItem('ContainersShowColumnFilters', tenant);
        await listHelper.removeItem('ContainersSorting', tenant);
        await listHelper.removeItem('ContainersPagination', tenant);
        await listHelper.removeItem('ContainersColumnOrder', tenant);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.item, common.cacheValues.containerType, common.cacheValues.bin, 
                common.cacheValues.lotRating, common.cacheValues.itemCategory, common.cacheValues.hcReportCategory, common.cacheValues.quantityUOM], 
                props.auth.constants, null, props.auth.user.tenant);
            let item = columnFilters.find(x => x.id === 'item');
            if(item != null) {
                if(item.value != null){
                    let sel = cacheValues.items.find(x => x.value === item.value);
                    setItemSel(sel);
                }
            }
            setValues({
                items: cacheValues.items,
                types: cacheValues.containerTypes,
                bins: cacheValues.bins,
                ratings: cacheValues.lotRatings,
                categories: cacheValues.itemCategories,
                reportCategories: cacheValues.hcReportCategories,
                uoms: cacheValues.quantityUOMs
            });
            let cat = columnFilters != null ? columnFilters.find(x => x.id === 'category') : null;
            if(cat != null){
                let sel = {ids: cat.value, values: []};
                cat.value.forEach(id => {
                    let val = cacheValues.itemCategories.find(x => x.value === id);
                    sel.values.push(val);
                });
                setCategoriesSel(sel);
            }
        }
        fetchData();
    }, [columnFilters, props]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'containerList',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/containers', {params: params});
            const json = await response.data.data;
            json.forEach((row) => {
                row.bin = row.bin ? row.bin.description : '';
                row.type = row.type.label;
                row.rating = row.rating != null ? row.rating.label : null
            });
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });
    let columns = useMemo(() => [
        {
            accessorKey: 'number',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/container/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.item.number}-${row.item.name}`,
            id: 'item',
            header: t('item'),
            Filter: ({ header, column }) =>
                    <AutoComplete 
                        options={values.items}
                        getOptionLabel={(option) => option.label}
                        onChange={(e, newValue) => {
                            setItemSel(newValue);
                            column.setFilterValue(newValue != null ? newValue.value : null);
                        }}
                        value={itemSel}
                        renderInput={(params) => <TextField {...params} variant="standard" />}
                    />,
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/item/' + cell.row.original?.item._id}>{cell.getValue()}</Link>
                </Box>
            ),
        },
        {
            accessorKey: 'lot.number',
            header: t('lot'),
            enableColumnFilter: false,
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/lot/' + cell.row.original?.lot._id}>{cell.getValue()}</Link>
                </Box>
            ),
        },
        {
            accessorKey: 'quantity',
            header: t('quantity'),
            muiTableBodyCellProps: {
                align: 'right',
            },
            Filter: ({
                header,
                column
              }) => <FormControlLabel control={
                <Checkbox checked={column.getFilterValue() === 'true'} 
                    onChange={() => {
                        column.setFilterValue(
                            column.getFilterValue() === undefined || column.getFilterValue() === 'false' || column.getFilterValue() == null
                                ? 'true' : 'false'
                        )
                    }}/>
                } label={t('includeEmpty')} />,
            Cell: ({ cell, column }) => (
                <Box>
                    <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                </Box>
            ),
        },
        {
            accessorKey: 'uom',
            header: t('uom'),
            filterVariant: 'select',
            filterSelectOptions: values.uoms,
        },
        {
            accessorKey: 'available',
            header: t('available'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
            ),
        },
        {
            accessorKey: 'reserved',
            header: t('reserved'),
            muiTableBodyCellProps: {
                align: 'right',
            },
            enableColumnFilter: false,
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
            ),
        },
        {
            accessorKey: 'bin',
            header: t('bin'),
            filterVariant: 'select',
            filterSelectOptions: values.bins,
        },
        {
            accessorKey: 'type',
            header: t('type'),
            filterVariant: 'select',
            filterSelectOptions: values.types,
        },
        {
            accessorKey: 'expires',
            header: t('expires'),
            filterVariant: 'date',
        },
        {
            accessorKey: 'rating',
            header: t('rating'),
            filterVariant: 'select',
            filterSelectOptions: values.ratings,
        },
        {
            accessorKey: 'category',
            header: t('category'),
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='category'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.categories} 
                    setSelected={setCategoriesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={categoriesSel.ids} 
                    selectedSel={categoriesSel.values}
                    columnFilters={columnFilters}
                    screen='Containers'
                    tenant={props.auth.user.tenant}/>
                </Box>
            ),
        },
        {
            accessorKey: 'count',
            header: t('count'),
            enableColumnFilter: false,
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
            ),
        },
        {
            accessorKey: 'reportCategory',
            header: t('reportCategory'),
            filterVariant: 'select',
            filterSelectOptions: values.reportCategories
        },
        {
            accessorKey: 'packagedOn',
            id: 'packagedOn',
            header: t('packagedOn'),
            Cell: ({ cell, column }) => (
                cell.getValue() != null ?
                <Box>
                    {dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat)}
                </Box> : ''
            ),
        }
        
    ],
    [t, values, itemSel, props, categoriesSel, showSelect, columnFilters]);
    
    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true },
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: true,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()} color="primary">
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                {Object.keys(table.getState().rowSelection).length > 0 &&
                    <MoveButton
                        color="primary"
                        moveClick={() => openMove()}
                        enabled={true}
                        data-cy="create"
                    /> 
                }
                <Tooltip arrow title={t('export')}>
                    <IconButton onClick={() => csv()} color="primary">
                        <ExcelIcon />
                    </IconButton>
                </Tooltip>
                    {/* <CSVLink 
                        filename={csvOutput.fileName} 
                        // data={csvOutput.data ?? []} 
                        data={csvDataFromState}
                        target="_blank" 
                        // headers={csvOutput.headers ?? []} 
                        headers={csvHeadersFromState}
                        separator={csvSeparator}
                        asyncOnClick={true}
                        onClick={csv}
                    >
                        <ExcelIcon />
                    </CSVLink> */}
                <Tooltip arrow title={t('clearListSettings')}>
                    <IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });
    const buttonStack = (dialog) => {
        let buttons = [];
        switch(dialog) {
            case 'move': {
                buttons = [
                    <Button variant="text" color="secondary" onClick={clearMove} data-cy="moveClose">{t('close')}</Button>,
                    <Button variant="contained" color="primary" onClick={saveMove} data-cy="moveSave">{t('save')}</Button>
                ];
                break;
            }
            default: 
                break;
        }
        return buttons;
    }

    const csv = async () => {
        setCsvOutput({ ...csvOutput, headers: null, data: null});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axios.get('/api/containersexport', {params: params});
        let headers = response.data.headers;
        let csvData = response.data.data;
        setCsvOutput({ ...csvOutput, headers: headers, data: csvData });
    }

    const clearMove = () => {
        setOpenMoveDialog(false);
        setMoveBinSel(null);
    }

    const openMove = () => {
        setOpenMoveDialog(true);
    }

    const saveMove = () => {
        if(enableSave){
            setEnableSave(false);
            _saveMove();
        }
    }

    const _saveMove = async () => {
        let errors = {};
        if(moveBinSel == null)
            errors[t('moveBin')] = t('required');
        if(moveDate == null || moveDate === '')
            errors[t('moveDate')] = t('required');
        let selectedRows = table.getSelectedRowModel().rows;
        if(selectedRows.length === 0) {
            dispatch({ type: 'CREATE_ALERT', payload: {message: t('noContainersSelected'), title: t('error'), severity: 'error'}});
            setEnableSave(true);
            return;
        }
        if(!isEmpty(errors)) {
            dispatch({ type: 'CREATE_ERROR', payload: errors });
            setEnableSave(true);
            return;
        }
        setErrors({});
        let containers = [];
        selectedRows.forEach((row) => {
            containers.push(row.original._id);
        });
        let data = {
            bin: moveBinSel.value,
            containers: containers,
            date: moveDate
        };

        try {
            await axios.post('/api/bulkcontainermove', data);
            clearMove();
            refetch();
            dispatch({ type: 'CREATE_ALERT', payload: {message: t('containersMovedSuccessfully'), title: t('success'), severity: 'success'}});
        }catch(err) {
            dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }finally {
            setEnableSave(true);
        }
    }

    const setBin = (e, newValue) => {
        setMoveBinSel(newValue);
    }


    return (
        <Aux>
            <BreadcrumbBar>
                <ActionBar/>
            </BreadcrumbBar>
            {csvOutput != null && csvOutput.data != null &&
                <CSVDownload filename={csvOutput.fileName} data={csvOutput.data} target="_blank" headers={csvOutput.headers} separator={csvSeparator}/>
            }
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
            {openMoveDialog &&
                <ModalDialog buttonStack={buttonStack('move')} title={t('moveContainers')} toggleDialog={clearMove} 
                                dialogStatus={openMoveDialog} fullWidth maxWidth='xs'>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <DateSelect
                                onChange={setMoveDate}
                                value={moveDate}
                                helperText={errors[t('date')]}
                                error={errors[t('date')] != null ? true : false}
                                label={t('date')}
                                disabled={!props.permission.update}
                                format={props.auth.user.dateFormat}
                                required
                                data-cy="moveDate"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AutoCompleteField
                                value={moveBinSel}
                                options={values.bins}
                                onChange={setBin}
                                error={errors[t('moveBin')] != null ? true : false}
                                helperText={errors[t('moveBin')]}
                                label={t('bin')}
                                disabled={!props.permission.update}
                                data-cy="moveBin"
                                groupBy={props.auth.user.groupBin != null && props.auth.user.groupBin === true ? 'room' : null}
                                noneMessage={t('noRoomSelected')}
                            />
                        </Grid>
                    </Grid>
                </ModalDialog>
            }
        </Aux>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(ContainerList);
