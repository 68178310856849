import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import Unlock from '../General/Unlock';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import SelectField from '../UI/SelectField/SelectField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import ActionBar from '../UI/Buttons/ActionBar';
import SectionTitle from '../UI/Typography/SectionTitle';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import CapaAction from './CapaAction';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class EmployeeIllness extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            employee: '',
            location: '',
            locations: [],
            date: new Date(),
            gender: '',
            genders: [],
            users: [],
            lengthOfService: '',
            lengthInCurrentPosition: '',
            occupation: '',
            symptoms: [],
            symptomsList: [],
            symptomsSelected: [],
            comments: '',
            existing: '',
            allergies: '',
            healthExaminer: '',
            reviewer: null,
            user: null,
            managers: [],
            qas: [],
            reviewDate: '',
            description: '',
            assignee: '',
            illnessId: '',
            signed: false,
            schedule: null,
            status: this.props.auth.constants.statuses.new,
            statuses: [],
            tenant: this.props.auth.user.tenant,
            documents: [],
            number: '',
            locationSelected: null,
            userSelected: null,
            genderSelected: null,
            age: '',
            capaActions: [],
            editCapa: false,
            offset: 0,
            listSize: 5,
            page: 0,
            orderBy: 'number',
            ordDir: 1,
            totalCount: 0,
            capaId: '',
            capaIndex: -1,
            security: [],
            optionsMenu: [],
            flagForSignature: 0,
            completeSigSec: false,
            reviewSigSec: false,
            dialogOpen: false,
            enableSave: true,
            goBack: -1
        }
        this.changeValue = this.changeValue.bind(this);
        this.reviewDateChange = this.reviewDateChange.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.saveClick = this.saveClick.bind(this);
        this.symptomsChanged = this.symptomsChanged.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location, common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations
        let params = queryString.parse(this.props.location.search);
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        let goBack = -1;
        if(scheduleId != null)
            goBack = -2;
        const values = await axios.get("/api/illnessvalues");
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ? 
            this.props.auth.user.defaultLocation : '';
        let locSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locSel == null && locations.length === 1){
            locSel = locations[0];
            location = locSel.value;
        }
        const statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        this.setState({
            users: values.data.users,
            managers: values.data.managers,
            qas: values.data.qas,
            genders: values.data.genders,
            symptomsList: values.data.symptoms,
            locations: locations,
            location: location,
            locationSelected: locSel,
            date: new Date(),
            schedule: scheduleId,
            statuses: cacheValues.statuses,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            security: this.props.permission,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            goBack: goBack
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/illnesses', label: this.props.t('employeeIllnesses'), screen: this.props.auth.screenDefs.EmployeeIllness},
                    { path: 'active', label: this.props.t('employeeIllness'), screen: this.props.auth.screenDefs.RoomCleaning}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async ()=> {
        var record = await axios.get("/api/illness/" + this.state._id);
        var symptomsSelected = [];
        var symptoms = this.state.symptomsList;
        for(var i = 0; i < record.data.symptoms.length; i++){
            for(var j = 0; j < symptoms.length; j++){
                if(record.data.symptoms[i] === symptoms[j].value){
                    symptomsSelected.push(symptoms[j]);
                    break;
                }
            }
        }
        var locationSelected = this.state.locations.find(x => x.value === record.data.location);
        var userSelected = this.state.users.find(x => x.value === record.data.employee);
        var genderSelected = this.state.genders.find(x => x.value === record.data.gender);
        var signed = record.data.status === this.props.auth.constants.statuses.complete || record.data.status === this.props.auth.constants.statuses.signed;
        var statusSel = this.state.statuses.find(x => x.value === record.data.status);
        this.loadActions();
        const completeSigSec = this.state._id != null && this.state._id !== '';
        const reviewSigSec = (record.data.user != null && record.data.user._id != null && 
                              record.data.user.signature != null && record.data.user.signature !== '');
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 3 : 0;
        this.setState({
            _id: record.data._id,
            employee: record.data.employee,
            location: record.data.location,
            date: record.data.date,
            age: record.data.age,
            gender: record.data.gender,
            lengthOfService: record.data.lengthOfService,
            lengthInCurrentPosition: record.data.lengthInCurrentPosition,
            occupation: record.data.occupation,
            symptoms: record.data.symptoms,
            comments: record.data.comments,
            existing: record.data.existing,
            allergies: record.data.allergies,
            correctiveMeasures: record.data.correctiveMeasures,
            healthExaminer: record.data.healthExaminer,
            user: record.data.user,
            reviewer: record.data.reviewer,
            symptomsSelected: symptomsSelected,
            signed: signed,
            status: record.data.status != null && record.data.status !== '' ? record.data.status : this.props.auth.constants.statuses.inprogress,
            locationSelected: locationSelected,
            userSelected: userSelected,
            genderSelected: genderSelected,
            documents: record.data.documents,
            number: record.data.number,
            statusSel: statusSel,
            flagForSignature: flagForSignature,
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec
        }, () => {
            const crumbs = [
                { path: '/#/illnesses', label: this.props.t('employeeIllnesses'), screen: this.props.auth.screenDefs.EmployeeIllness},
                { path: 'active', label: this.props.t('employeeIllness') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.RoomCleaning}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadOptionsMenu();
        });
    };

    loadActions = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: this.props.auth.constants.objectIds.illness, value: this.state._id},
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let result = await axios.get('/api/capas', {params: params});
        this.setState({
            capaActions: result.data.data,
            totalCount: result.data.totalCount
        });
    };

    loadOptionsMenu = () => {
        const t = this.props.t;
        let userList = [];
        const employee = this.state.employee;
        const employeeSelected = this.state.users.find(x => x.value === employee);
        userList.push(employeeSelected);
        const optionsMenu = [
            { 
                security: this.state.completeSigSec, user: employee, users: userList, name: t('employee'), object: this.state._id,
                title: t('employee'), setId: () => this.setUser(), signature: this.state.user, 
                objectType: this.props.auth.constants.objectIds.illness, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, 
            },
            { 
                security: this.state.reviewSigSec, user: this.props.auth.user.id, users: this.state.managers, name: t('review'),
                title: t('reviewedBy'), setId: () => this.setReviewer(), signature: this.state.reviewer, object: this.state._id, 
                objectType: this.props.auth.constants.objectIds.illness, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        this.setState({optionsMenu});
        this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
    }

    changeValue(e){
        var name = e.target.name;
        var value = e.target.value
        if(name === "age"){
            if(isNaN(value)){
                return;
            }
        }
        this.setState({[name]: value});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            if(name === 'userSelected') this.loadOptionsMenu();
        });
    };

    changeMulti = (name, data, optName) => (e) => {
        let sels = this.state[name];
        let ids = this.state[data];
        let options = this.state[optName];
        let val = e.target.value;
        let value = val.pop();
        if(value != null){
            if(value === 'all'){
                if(options.length !== sels.length){
                    sels = [];
                    ids = [];
                    options.forEach(row => {
                        sels.push(row);
                        ids.push(row.value);
                    });
                } else {
                    sels = [];
                    ids = [];
                }
            } else {
                if(val.length === 0){
                    let sel = options.find(x => x.value === value);
                    ids.push(value);
                    sels.push(sel);
                } else {
                    if(!val.some(x => x.value === value)){
                        let sel = options.find(x => x.value === value);
                        sels.push(sel);
                        ids.push(sel.value);
                    } else {
                        let index = sels.findIndex(x => x.value === value);
                        sels.splice(index, 1);
                        ids.splice(index, 1);
                    }
                }
            }
            this.setState({[name]: sels, [data]: ids})
        }
    }

    deleteMulti = (name, data) => (e, value) => {
        let sels = this.state[name];
        let ids = this.state[data];
        let index = sels.findIndex(x => x.value === value.value);
        if(index > -1){
            sels.splice(index, 1);
            ids.splice(index, 1);
        }
        this.setState({[name]: sels, [data]: ids});
    }

    reviewDateChange(e){
        this.setState({reviewDate: e});
    }

    dateChange(e){
        this.setState({date: e});
    }

    symptomsChanged(e, newValue){
        var symptoms = [];
        if(newValue != null){
            newValue.forEach(val => {
                symptoms.push(val.value);
            })
        }
        this.setState({
            symptoms: symptoms,
            symptomsSelected: newValue
        })
    }

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const t = this.props.t;
        var valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        var data = {
            _id: this.state._id,
            employee: this.state.employee,
            location: this.state.location,
            date: this.state.date,
            age: this.state.age,
            gender: this.state.gender,
            lengthOfService: this.state.lengthOfService,
            lengthInCurrentPosition: this.state.lengthInCurrentPosition,
            occupation: this.state.occupation,
            symptoms: this.state.symptoms,
            comments: this.state.comments,
            existing: this.state.existing,
            allergies: this.state.allergies,
            healthExaminer: this.state.healthExaminer,
            status: this.state.status,
            schedule: this.state.schedule
        }
        axios.post("/api/illnesssave", data)
        .then(result => {
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/illness/' + result.data.id);
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            this.setState({
                _id: result.data.id,
                number: result.data.number,
                enableSave: true
            })
        })
        .catch(err => {
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        })
    }

    validateForm(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.employee == null || state.employee === '')
            errors[t('employee')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.date == null || state.date === '')
            errors[t('date')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    setUser = e => {
        this.setState({user: e});
        this.loadRecord();
    }

    setReviewer = e => {
        this.setState({reviewer: e});
        this.loadRecord();
    };

    addAction = (e) => {
        this.setState({editCapa: true})
    };

    closeDialog = (e) => {
        this.setState({
            editCapa: false,
            capaId: '',
            capaIndex: -1
        },() => {this.loadActions();});
    };

    editCapa = (i) => (e) => {
        let capa = this.state.capaActions[i];
        this.setState({capaId: capa._id, editCapa: true, capaIndex: i});
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadActions();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadActions();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadActions();
        });
    };

    toggleDialog = () => {
        let dialogOpen = this.state.dialogOpen;
        this.setState({ dialogOpen: !dialogOpen });
    };

    render(){
        const errors = this.props.errors;
        const signed = this.state.signed;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar goBack={this.state.goBack}>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.illness}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Panel>
                            <Grid container spacing={3}>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!signed &&
                                        <AutoCompleteField
                                            value={this.state.locationSelected}
                                            options={this.state.locations}
                                            onChange={this.changeAuto('locationSelected', 'location')}
                                            error={errors[t('location')] != null ? true : false}
                                            helperText={errors[t('location')]}
                                            label={t('location')}
                                            disabled={signed}
                                            required
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locationSelected != null ? this.state.locationSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    {!signed &&
                                        <AutoCompleteField
                                            value={this.state.userSelected}
                                            options={this.state.users}
                                            onChange={this.changeAuto('userSelected', 'employee')}
                                            error={errors[t('employee')] != null ? true : false}
                                            helperText={errors[t('employee')]}
                                            label={t('employee')}
                                            disabled={signed}
                                            required
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('employee')}</FormLabel>
                                            {this.state.userSelected != null ? this.state.userSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!signed &&
                                        <DateSelect
                                            onChange={this.dateChange}
                                            value={this.state.date}
                                            helperText={errors[t('date')]}
                                            error={errors[t('date')] != null ? true : false}
                                            disabled={signed}
                                            label={t('date')}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('date')}</FormLabel>
                                            {this.state.date != null && this.state.date !== '' &&
                                                <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                            }
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    {!signed &&
                                        <TextField value={this.state.age} onChange={this.changeValue} name="age"
                                        size="medium" fullWidth={true} disabled={signed} label={t('age')}
                                        error={errors[t('age')] != null ? true : false} helperText={errors[t('age')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('age')}</FormLabel>
                                            {this.state.age}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!signed &&
                                        <AutoCompleteField
                                            value={this.state.genderSelected}
                                            options={this.state.genders}
                                            onChange={this.changeAuto('genderSelected', 'gender')}
                                            error={errors[t('gender')] != null ? true : false}
                                            helperText={errors[t('gender')]}
                                            label={t('gender')}
                                            disabled={signed}
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('gender')}</FormLabel>
                                            {this.state.genderSelected != null ? this.state.genderSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!signed &&
                                        <TextField value={this.state.lengthOfService} onChange={this.changeValue} name="lengthOfService"
                                        size="medium" fullWidth={true} disabled={signed} label={t('lengthOfService')}
                                        error={errors[t('lengthOfService')] != null ? true : false} helperText={errors[t('lengthOfService')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('lengthOfService')}</FormLabel>
                                            {this.state.lengthOfService}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!signed &&
                                        <TextField value={this.state.occupation} onChange={this.changeValue} name="occupation"
                                        size="medium" fullWidth={true} disabled={signed} label={t('jobTitle')}
                                        error={errors[t('occupation')] != null ? true : false} helperText={errors[t('occupation')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('jobTitle')}</FormLabel>
                                            {this.state.occupation}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!signed &&
                                        <TextField value={this.state.lengthInCurrentPosition} onChange={this.changeValue} name="lengthInCurrentPosition"
                                        size="medium" fullWidth={true} disabled={signed} label={t('lengthInPosition')}
                                        error={errors[t('lengthInCurrentPosition')] != null ? true : false} helperText={errors[t('lengthInCurrentPosition')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('lengthInPosition')}</FormLabel>
                                            {this.state.lengthInCurrentPosition}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item sm={10}>
                                    <SelectField
                                        selectAll
                                        selectAllLabel={t('selectAll')}
                                        value={this.state.symptomsSelected}
                                        options={this.state.symptomsList}
                                        onChange={this.changeMulti('symptomsSelected', 'symptoms', 'symptomsList')}
                                        onDelete={this.deleteMulti('symptomsSelected', 'symptoms')}
                                        error={errors[t('symptomsSelected')] != null ? true : false}
                                        helperText={errors[t('symptomsSelected')]}
                                        label={t('symptoms')}
                                        disabled={signed}
                                    />
                                </Grid>
                                <Grid item sm={11}>
                                    {!signed &&
                                        <TextField value={this.state.comments} onChange={this.changeValue} name="comments" multiline={true} rows="3"
                                        variant="outlined" size="medium" fullWidth={true} disabled={signed} label={t('comments')}
                                        error={errors[t('comments')] != null ? true : false} helperText={errors[t('comments')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('comments')}</FormLabel>
                                            {this.state.comments}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item sm={11}>
                                    {!signed &&
                                        <TextField value={this.state.existing} onChange={this.changeValue} name="existing" multiline={true} rows="3"
                                        variant="outlined" size="medium" fullWidth={true} disabled={signed} label={t('existingHealthConditions')}
                                        error={errors[t('existing')] != null ? true : false} helperText={errors[t('existing')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('existingHealthConditions')}</FormLabel>
                                            {this.state.existing}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item sm={11}>
                                    {!signed &&
                                        <TextField value={this.state.allergies} onChange={this.changeValue} name="allergies" multiline={true} rows="3"
                                        variant="outlined" size="medium" fullWidth={true} disabled={signed} label={t('allergies')}
                                        error={errors[t('allergies')] != null ? true : false} helperText={errors[t('allergies')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('allergies')}</FormLabel>
                                            {this.state.allergies}
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            <Aux>
                                <SectionTitle title={t('capaPlan')}/>
                                <TableContainer component={Paper}>
                                    <Table aria-label="capaActions">
                                        <TableHead>
                                            <TableRow key="capaHeader">
                                                <TableCell>{t('number')}</TableCell>
                                                <TableCell>{t('title')}</TableCell>
                                                <TableCell>{t('dueDate')}</TableCell>
                                                <TableCell>{t('status')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.capaActions.map((row, i) =>
                                                <TableRow key={row._id}>
                                                    <TableCell>
                                                        <TextOnlyClickButton enabled={true} click={this.editCapa(i)} name={row.number}/>
                                                    </TableCell>
                                                    <TableCell>{row.title}</TableCell>
                                                    <TableCell><DateDisplay value={row.dueDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                                    <TableCell>{row.status}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow key="tableFooter">
                                                <TablePagination count={this.state.totalCount}
                                                rowsPerPage={this.state.listSize}
                                                onPageChange={this.handleChangePage}
                                                page = {this.state.page}
                                                rowsPerPageOptions={common.rowOptions}
                                                onRowsPerPageChange={this.handleRowsPerPage}/>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                                <Grid container spacing={3}>
                                    <Grid item sm={8}>
                                        <Button variant="outlined" color="primary" size="small" disabled={signed} onClick={this.addAction}>{t('add')}</Button>
                                    </Grid>
                                </Grid>
                                {this.state.editCapa &&
                                    <CapaAction
                                        reload={this.loadRecords}
                                        open={this.state.editCapa}
                                        onClose={this.closeDialog}
                                        employeeIllness={this.state._id}
                                        id={this.state.capaId}
                                        users={this.state.users}
                                        qas={this.state.qas}
                                        managers={this.state.managers}
                                    />
                                }
                            </Aux>
                            <br/>
                            <Grid container spacing={3}>
                                <Grid item xs={8} sm={4}>
                                    {!signed &&
                                        <TextField value={this.state.healthExaminer} onChange={this.changeValue} name="healthExaminer"
                                        size="medium" fullWidth={true} disabled={signed} label={t('healthExaminationBy')}
                                        error={errors[t('healthExaminer')] != null ? true : false} helperText={errors[t('healthExaminer')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('healthExaminationBy')}</FormLabel>
                                            {this.state.healthExaminer}
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                        </Panel>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        {!signed &&
                            <Aux>
                                <Button variant="contained" size="small" color="primary" onClick={this.saveClick} disabled={signed && !this.state.enableSave}>{t('save')}</Button>&nbsp;&nbsp;
                            </Aux>
                        }
                        {this.state.status === this.props.auth.constants.statuses.signed &&
                            <Unlock callback={this.loadRecord} id={this.state._id} objectType={this.props.auth.constants.objectIds.illness}/>
                        }
                    </Grid>
                </Grid>
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.illness}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(EmployeeIllness));
