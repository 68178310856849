import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import SectionTitle from '../UI/Typography/SectionTitle';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import Box from '@mui/material/Box';
import ValueHelper from '../../helpers/valueHelper';
import Panel from '../UI/Panel/Panel';
import { completeSigSecurity,
    fieldSecurity } from '../../helpers/securityHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConversionHelper from '../../helpers/conversionHelper';
import ConfirmDelete from '../General/ConfirmDelete';
import { CreateButton, SaveButton, DeleteButton, ShipButton, UnshipButton } from '../UI/Buttons/Buttons';
import Unlock from '../General/Unlock';
import PrintView from '../Printing/PrintView';
import DownloadButton from '../Printing/Buttons/Download';

class Sale extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            customer: '',
            customerSel: null,
            customers: [],
            address1: '',
            address2: '',
            city: '',
            prov: '',
            provSel: null,
            provs: [],
            postal: '',
            country: '',
            countrySel: null,
            countries: [],
            date: new Date(),
            postingDate: '',
            location: '',
            locationSel: null,
            locations: [],
            amount: '',
            taxRate: '',
            taxAmount: '',
            totalAmount: '',
            lines: [],
            shipped: [],
            shipments: [],
            items: [],
            item: '',
            itemSel: null,
            quantity: '',
            price: '',
            tax: '',
            container: '',
            containerSel: null,
            containers: [],
            showAddress: false,
            addresses: [],
            address: '',
            addressSel: null,
            security: [],
            enableSave: true,
            renderNum: Math.random(),
            notes: '',
            form: '',
            hasForm: false,
            documents: [],
            invoice: null,
            invoices: [],
            purchaseOrder: '',
            expectedDelivery: null,
            types: [],
            type: '',
            typeSel: null,
            ccxPosting: '',
            ccxPostingSel: null,
            ccxPostings: [],
    	    status: '',
            statusSel: null,
            statuses: [],
            fieldAccess: false,
            completeSigSec: false,
            signer: null,
            flagForSignature: 0,
            optionsMenu: [],
            sigData: null,
            users: [],
            ccxPostingObj: null,
            deleteOpen: false,
            terms: '',
            termsSel: null,
            saleTerms: [],
            hstRate: '',
            pstRate: '',
            gstRate: '',
            pstAmount: '',
            gstAmount: '',
            hstAmount: '',
            isCanada: true,
            remittanceDate: '',
            discountAmount: '',
            preDiscount: '',
            invoiceNotes: '',
            shippingCost: '',
            creator: '',
            creatorSel: null,
            hasShipping: false,
            finalDocuments: [],
            printViewer: false,
            shipmentsSel: [],
            printType: '',
            printRecord: '',
            casedContainer: '',
            casedContainerSel: null,
            casedContainers: [],
            masterCasings: [],
	        allItems: [],
            hasShipments: false,
            aglcInvoice: ''
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.item, common.cacheValues.customer, common.cacheValues.activeLocation, common.cacheValues.status,
            common.cacheValues.saleForm, common.cacheValues.saleType, common.cacheValues.saleTerms, common.cacheValues.allItems], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        const provs = await ValueHelper.getCanadianProvs();
        const countries = await ValueHelper.getCountries();
        let items = cacheValues.items;
        let customers = cacheValues.customers;
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.sale, this.props.auth.user.tenant);
        let creatorSel = userValues.create.find(x => x.value === this.props.auth.user.id);
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
            this.props.auth.user.defaultLocation : '';
        let locationSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locationSel == null){
            if(locations.length === 1){
                locationSel = locations[0];
                location = locations[0].value;
            }
        }
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
	    let typeSel = cacheValues.saleTypes.find(x => x.default === true);
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        let fieldAccess = fieldSecurity(this.props.permission, statusSel != null ? statusSel.value : '', this.props.auth.common, this.props.auth.constants);
        this.setState({
            provs: provs,
            countries: countries,
            locations: locations,
            customers: customers,
            items: items,
            allItems: cacheValues.allItems,
            location: location,
            locationSel: locationSel,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            security: this.props.permission,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            statuses: cacheValues.statuses,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            fieldAccess: fieldAccess,
            types: cacheValues.saleTypes,
            typeSel: typeSel,
            type: this.props.auth.constants.saleTypes.regular,
            saleTerms: cacheValues.saleTerms,
            creatorSel: creatorSel,
            creator: creatorSel != null ? creatorSel.value : '',
            users: userValues.create
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/sales', label: this.props.t('sales'), screen: this.props.auth.screenDefs.SaleList},
                    { path: 'active', label: this.props.t('sale'), screen: this.props.auth.screenDefs.Sale}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        let record = await axios.get('/api/sale/' + this.state._id);
        let customerSel = this.state.customers.find(x => x.value === record.data.customer);
        let provSel = record.data.provs.find(x => x.value === record.data.prov);
        let countrySel = this.state.countries.find(x => x.value === record.data.country);
        let locationSel = this.state.locations.find(x => x.value === record.data.location);
        let typeSel = this.state.types.find(x => x.value === record.data.type);
	    let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let termsSel = record.data.terms != null ? this.state.saleTerms.find(x => x.value === record.data.terms) : null;
        let creatorSel = record.data.creator != null ? this.state.users.find(x => x.value === record.data.creator) : null;
        let ccxPostingSel = null;
        if(record.data.ccxPosting != null && record.data.ccxPosting !== '' && record.data.ccxPostings != null && record.data.ccxPostings.length > 0){
            ccxPostingSel = record.data.ccxPostings.find(x => x.value === record.data.ccxPosting);
        }
        let lines = record.data.lines;
        lines.forEach(row => {
            let itemSel = this.state.allItems.find(x => x.value === row.item);
            row.itemSel = itemSel;
            if(row.container != null){
                let contSel = row.containers.find(x => x.value === row.container);
                row.containerSel = contSel;
            }
            if(row.casedContainer != null){
                let casedContSel = row.casedContainers.find(x => x.value === row.casedContainer);
                row.casedContainerSel = casedContSel;
            }
        });
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        let hasShipments = false;
        if(record.data.shipments != null && record.data.shipments.length > 0){
            for(let row of record.data.shipments){
                if(row.status._id !== this.props.auth.constants.statuses.deleted){
                    hasShipments = true;
                    break;
                }
            }
        }
        const fieldAccess = fieldSecurity(this.props.permission, statusSel != null ? statusSel.value : '', this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel != null ? statusSel.value : '', common, this.props.auth.constants);
        let sigDeleteCallback = (record.data.status === this.props.auth.constants.statuses.new || record.data.status === this.props.auth.constants.statuses.inprogress) && 
                        record.data.user != null ? this.deleteSigCallback : null;
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: this.state.users, name: t('complete'), object: record.data._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user,
                objectType: this.props.auth.constants.objectIds.sale, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, saveCallback: this.saveCallback, deleteCallback: sigDeleteCallback
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 : 0;
        let isCanada = record.data.country == null || record.data.country === common.canada ? true : false;
        let shipped = record.data.shipped;
        if(shipped != null){
            for(let row of shipped){
                let shippedContainers = [];
                for(let shipment of record.data.shipments){
                    for(let line of shipment.contents){
                        if(line.item === row.item._id){
                            if(line.sourceContainer != null){
                                shippedContainers.push(line.sourceContainer);
                            }
                        }
                    }
                }
                row.shippedContainers = shippedContainers;
            }
        }
        let finalDocuments = [];
        if(record.data.invoice?._id != null && record.data.invoice?._id !== '')
            finalDocuments.push(record.data.invoice._id);
        this.setState({
            number: record.data.number,
            customer: record.data.customer,
            customerSel: customerSel,
            address1: record.data.address1,
            address2: record.data.address2,
            city: record.data.city,
            prov: record.data.prov,
            provSel: provSel,
            postal: record.data.postal,
            country: record.data.country,
            countrySel: countrySel,
            date: record.data.date,
            postingDate: record.data.postingDate,
            location: record.data.location,
            locationSel: locationSel,
            amount: record.data.amount,
            taxRate: record.data.taxRate,
            taxAmount: record.data.taxAmount,
            totalAmount: record.data.totalAmount,
            lines: lines,
            shipments: record.data.shipments,
            provs: record.data.provs,
            renderNum: Math.random(),
            notes: record.data.notes,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            documents: record.data.documents != null ? record.data.documents : [],
            invoice: record.data.invoice,
            invoices: record.data.invoices,
            purchaseOrder: record.data.purchaseOrder,
            expectedDelivery: record.data.expectedDelivery,
            type: record.data.type,
            typeSel: typeSel,
            ccxPosting: record.data.ccxPosting,
	        shipped: shipped,
            hasShipping: record.data.shipped != null && record.data.shipped.length > 0 ? true : false,
            status: record.data.status,
            statusSel: statusSel,
            user: record.data.user,
            completeSigSec: completeSigSec,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            fieldAccess: fieldAccess,
            sigData: null,
            ccxPostingSel: ccxPostingSel,
            ccxPostings: record.data.ccxPostings != null && record.data.ccxPostings.length > 0 ? record.data.ccxPostings : [],
            ccxPostingObj: record.data.ccxPostingObj != null ? record.data.ccxPostingObj : null,
            deleteOpen: false,
            terms: record.data.terms,
            termsSel: termsSel,
            gstRate: record.data.gstRate,
            pstRate: record.data.pstRate,
            hstRate: record.data.hstRate,
            pstAmount: record.data.pstAmount,
            gstAmount: record.data.gstAmount,
            hstAmount: record.data.hstAmount,
            isCanada: isCanada,
            remittanceDate: record.data.remittanceDate,
            discountAmount: record.data.discountAmount,
            preDiscount: record.data.preDiscount != null ? record.data.preDiscount : record.data.amount,
            invoiceNotes: record.data.invoiceNotes,
            shippingCost: record.data.shippingCost,
            creator: record.data.creator, 
            creatorSel: creatorSel,
            finalDocuments: finalDocuments,
            masterCasings: record.data.masterCasings != null ? record.data.masterCasings : [],
            hasShipments: hasShipments,
            aglcInvoice: record.data.aglcInvoice
        }, () => {
            const crumbs = [
                { path: '/#/sales', label: this.props.t('sales'), screen: this.props.auth.screenDefs.SaleList},
                { path: 'active', label: this.props.t('sale') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.Sale}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeNumber = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value}, () => {
            if(name === 'quantity' || name === 'taxRate' || name === "discountAmount" || name === 'shippingCost'){
                this.calculateTotals();
            }
        });
    };

    changeAuto = (name, data) => async (e, newValue) => {
        let customer = this.state.customer;
        let customerSel = this.state.customerSel;
        let showAddress = this.state.showAddress;
        let addresses = this.state.addresses;
        let provs = this.state.provs;
        let price = this.state.price;
        let containers = this.state.containers;
        let gstRate = this.state.gstRate;
        let pstRate = this.state.pstRate;
        let hstRate = this.state.hstRate;
        let taxRate = this.state.taxRate;
        let terms = this.state.terms;
        let termsSel = this.state.termsSel;
        let isCanada = this.state.isCanada;
        let casedContainers = this.state.casedContainers;
        if(data === 'customer' && newValue != null){
            let customerRes = await axios.get('/api/customeraddresses/' + newValue.value);
            customer = newValue.value;
            customerSel = newValue;
            addresses = customerRes.data;
            showAddress = true;
            if(newValue.terms != null && termsSel == null){
                terms = newValue.terms;
                termsSel = this.state.saleTerms.find(x => x.value === newValue.terms);
            }
        } else if(data === 'item'){
            if(newValue != null){
                let itemRes = await axios.get('/api/item/' + newValue.value);
                price = itemRes.data.price;
                let contRes = await axios.post('/api/itemcontainers', {items: [newValue.value], available: true, ship: true});
                containers = contRes.data;
                if(newValue.casedItem != null){
                    let caseContRes = await axios.post('/api/itemcontainers', {items: [newValue.casedItem], available: true, ship: true});
                    casedContainers = caseContRes.data;
                }
            } else {
                price = '';
                containers = [];
                casedContainers = [];
            }

        } else if(data === 'country' && newValue != null){
            isCanada = newValue.value === common.canada ? true : false;
            let provData = await axios.get('/api/provinceselect', {params: {country: newValue.value}})
            provs = provData.data;
        } else if (data === 'type'){
            if(newValue != null && newValue.value === this.props.auth.constants.saleTypes.ccx){
                let ccxRes = await axios.get('/api/ccxpostingselect');
                this.setState({ccxPostings: ccxRes.data, ccxPostingSel: null, ccxPosting: ''});
            } else {
                this.setState({ccxPosting: '', ccxPostingSel: null, ccxPostings: []})
            }
        } else if (data === 'ccxPosting') {
            if(newValue != null){
                let result = await axios.get('/api/ccxposting/' + newValue.value);
                let lines = [];
                let itemSel = this.state.items.find(x => x.value === result.data.item);
                let quantity = result.data.listingVolume != null ? 
                    ConversionHelper.convertWeight(result.data.listingVolume, this.props.auth.constants.quantityUOMs.kg, itemSel.uom, this.props.auth.constants) : '';
                lines.push({
                    item: result.data.item, 
                    itemSel: itemSel, 
                    quantity: quantity,
                    containers: result.data.containers != null && result.data.containers.length > 0 ? result.data.containers: []
                });
                this.setState({ccxPostingObj: result.data, lines: lines});
            } else {
                this.setState({ccxPostingObj: null});
            }
            
        } else if (data === 'prov'){
            if(this.state.isCanada){
                if(newValue != null){
                    if(customerSel?.foreignId !== 'aglc'){
                        taxRate = newValue.gst != null && newValue.pst != null ? newValue.pst + newValue.gst : newValue.gst != null ? newValue.gst : 0;
                        if(newValue.hst === true){
                            hstRate = taxRate;
                        }
                        gstRate = newValue.gst;
                        pstRate = newValue.pst;
                    }else {
                        gstRate = 0;
                    }   
                } else {
                    taxRate = null;
                    hstRate = null;
                    gstRate = null;
                    pstRate = null;
                }
            }
        } else if (data === 'terms'){
            termsSel = newValue;
            terms = newValue != null ? newValue.value : '';
        }
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : '',
            customer: customer,
            customerSel: customerSel,
            showAddress: showAddress,
            price: price,
            addresses: addresses,
            provs: provs,
            containers: containers,
            gstRate: gstRate,
            pstRate: pstRate,
            hstRate: hstRate,
            taxRate: taxRate,
            terms: terms,
            termsSel: termsSel,
            isCanada: isCanada,
            casedContainers: casedContainers
        }, () => {
            this.calculateTaxRate();
        });
    };

    addressSelect = async (e) => {
        let addresses = this.state.addresses;
        if(this.state.address != null && this.state.address !== ''){
            let address = addresses.find(x => x.value === this.state.address);
            let country = address.country != null ? address.country : common.canada;
            let provData = await axios.get('/api/provinceselect', {params: {country: country}})
            let provs = provData.data;
            let provSel = provs.find(x => x.value === address.prov);
            let isCanada = address.country == null || address.country === common.canada ? true : false;
            let taxRate = this.state.taxRate;
            let hstRate = this.state.hstRate;
            let pstRate = this.state.pstRate;
            let gstRate = this.state.gstRate;
            if(isCanada){
                if(this.state.customerSel?.foreignId !== 'aglc'){
                    taxRate = provSel.gst != null && provSel.pst != null ? provSel.pst + provSel.gst : provSel.gst != null ? provSel.gst : 0;
                    hstRate = provSel.hst === true ? taxRate : null;
                    gstRate = provSel.gst;
                    pstRate = provSel.pst;
                }else {
                    gstRate = 0;
                }
            }
            let countrySel = this.state.countries.find(x => x.value === address.country);
            this.setState({
                address1: address.street1,
                address2: address.street2,
                city: address.city,
                prov: address.prov,
                provSel: provSel,
                postal: address.postal,
                country: address.country,
                countrySel: countrySel,
                showAddress: false,
                gstRate: gstRate,
                pstRate: pstRate,
                hstRate: hstRate,
                taxRate: taxRate,
                isCanada: isCanada,
                provs: provs
            }, () => {this.calculateTaxRate()});
        }
    };

    calculateTaxRate = () => {
        if(this.state.isCanada){
            let taxRate = 0;
            if(this.state.customerSel?.foreignId !== 'aglc'){
                if(this.state.hstRate != null && this.state.hstRate !== '' && this.state.hstRate !== 0){
                    taxRate = this.state.hstRate;
                } else if(this.state.gstRate != null && this.state.gstRate !== '' && this.state.gstRate !== 0){
                    taxRate = this.state.gstRate;
                    if(this.state.pstRate != null && this.state.pstRate !== '' && this.state.pstRate !== 0){
                        taxRate += this.state.pstRate;
                    }
                }
            }
            this.setState({taxRate: taxRate}, () => {
                this.calculateTotals();
            });            
        } else {
            this.calculateTotals();
        }
    };

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeItemValue = (index) => (e) => {
        let lines = this.state.lines;
        let line = lines[index];
        line[e.target.name] = e.target.value;
        this.setState({lines: lines});
    };

    changeItemNumber = (name, i) => (e) => {
        let lines = this.state.lines;
        let line = lines[i];
        const value = e.target.value;
        if(isNaN(value)){
            return;
        }
        line[name] = value;
        this.setState({lines: lines}, () => {
            this.calculateTotals();
        });
    };

    changeItemAuto = (name, data, i) => async (e, newValue)=> {
        let lines = this.state.lines;
        let line = lines[i];
        if(newValue != null){
            line[name] = newValue;
            line[data] = newValue.value;
        } else {
            line[name] = null;
            line[data] = '';
        }
        if(data === 'item' && newValue != null){
            let itemRes = await axios.get('/api/item/' + newValue.value);
            let contRes = await axios.post('/api/itemcontainers', {items: [newValue.value], available: true, ship: true});
            line.containers = contRes.data;
            line.price = itemRes.data.price;
        }
        if(data === 'item' || data === 'container'){
            line.reservation = null;
        }
        this.setState({lines: lines}, () => {
            this.calculateTotals();
        });
    };

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.save()})
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async (e) => {
        const t = this.props.t;
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let lines = this.state.lines;
        for(let row of lines){
            if(row.container === ''){
                row.container = null;
            }
            if(row.price === '')
                row.price = null;
            if(row.casedContainer === '')
                row.casedContainer = null;
        }
        let data = {
            _id: this.state._id,
            number: this.state.number,
            date: this.state.date,
            location: this.state.location,
            customer: this.state.customer,
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            prov: this.state.prov,
            postal: this.state.postal,
            country: this.state.country,
            amount: this.state.amount,
            taxRate: this.state.taxRate,
            taxAmount: this.state.taxAmount,
            totalAmount: this.state.totalAmount,
            lines: lines,
            notes: this.state.notes,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            shipments: this.state.shipments,
            purchaseOrder: this.state.purchaseOrder,
            expectedDelivery: this.state.expectedDelivery,
            type: this.state.type,
	        status: this.state.status,
            sigData: this.state.sigData,
            ccxPosting: this.state.ccxPosting,
            pstRate: this.state.pstRate,
            gstRate: this.state.gstRate,
            hstRate: this.state.hstRate,
            pstAmount: this.state.pstAmount,
            gstAmount: this.state.gstAmount,
            hstAmount: this.state.hstAmount,
            remittanceDate: this.state.remittanceDate,
            preDiscount: this.state.preDiscount,
            discountAmount: this.state.discountAmount,
            invoiceNotes: this.state.invoiceNotes,
            terms: this.state.terms,
            shippingCost: this.state.shippingCost,
            creator: this.state.creator,
            aglcInvoice: this.state.aglcInvoice
        };
        try {
            let result = await axios.post('/api/sale', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/sale/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true, sigData: null}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    };

    shipClick = (e) => {
        if(this.state.enableSave){
            this.setState({enableSave: false}, () => this._shipClick(e));
        }
    }

    _shipClick = async (e) => {
        if(this.state.status === this.props.auth.constants.statuses.signed && this.props.permission.complete === true){
            try {
                let result = await axios.post('/api/saleship', {id: this.state._id});
                this.setState({enableSave: true}, () => {
                    this.loadRecord();
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
                });
            } catch (err){
                this.setState({ enableSave: true }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
                });
            }
        } else {
            this.setState({enableSave: true});
        }
    }

    signClick = (e) => {
        if(this.state.enableSave){
            this.setState({enableSave: false}, () => this._signClick(e));
        }
    }

    _signClick = async (e) => {
        if(this.state.status === this.props.auth.constants.statuses.shipped && this.props.permission.complete === true){
            try {
                let result = await axios.post('/api/salesign', {id: this.state._id});
                this.setState({enableSave: true}, () => {
                    this.loadRecord();
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
                });
            } catch (err){
                this.setState({ enableSave: true }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
                });
            }
        } else {
            this.setState({enableSave: true});
        }
    }

    validate(){
        const t = this.props.t;
        const constants = this.props.auth.constants;
        let errors = {};
        const state = this.state;
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(state.customer == null || state.customer === '')
            errors[t('customer')] = t('required');
        if(state.address1 == null || state.address1 === '')
            errors[t('street')] = t('required');
        if(state.city == null || state.city === '')
            errors[t('city')] = t('required');
        if(state.prov == null || state.prov === '')
            errors[t('prov')] = t('required');
        if(state.postal == null || state.postal === '')
            errors[t('postal')] = t('required');
        if(state.country == null || state.country === '')
            errors[t('country')] = t('required');
        if(state.date == null || state.date === '')
            errors[t('date')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.lines == null || state.lines.length === 0)
            errors[t('item')] = t('required')
        if(state.type === constants.saleTypes.ccx){
            if(state.lines.length > 1){
                errors[t('item')] = t('ccxOnlyOneItem');
            }
        }
        if(state.creator == null || state.creator === '')
            errors[t('creator')] = t('required');
        for(let [i, row] of state.lines.entries()){
            if(row.item == null || row.item === '')
                errors[`${t('item')} (${t('line')} ${i})`] = t('required');
            if(row.quantity == null || row.quantity === '')
                errors[`${t('quantity')} (${t('line')} ${i})`] = t('required');
            if(row.reservation == null && row.containerSel != null && row.containerSel !== '' &&
               row.containerSel.quantity < row.quantity)
               errors[`${t('quantity')} (${t('line')} ${i})`] = t('moreThanContainer');
        }
        
        if((state.item != null && state.item !== '') || (state.container != null && state.container !== '') || (state.price != null && state.price !== '' && state.price !== 0) ||
        (state.quantity != null && state.quantity !== '') || (state.tax != null && state.tax !== '')){
            errors[t('item')] = t('rowNotAdded');
        }         
        if(state.customerSel?.foreignId === 'aglc' && (state.aglcInvoice == null || state.aglcInvoice === '')){
            errors[t('aglcInvoice')] = t('required');
        }   
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    validateComplete = () => {
        const t = this.props.t;
        let valid = this.validate();
        
        if(!valid)
            return t('notAllComplete');
        return null;
    }

    addLine = (e) => {
        let valid = this.validateLine();
        if(!valid)
            return
        let lines = this.state.lines;
        lines.push({
            item: this.state.item,
            itemSel: this.state.itemSel,
            quantity: this.state.quantity,
            price: this.state.price,
            tax: this.state.tax,
            container: this.state.container,
            containerSel: this.state.containerSel,
            containers: this.state.containers,
            casedContainer: this.state.casedContainer,
            casedContainerSel: this.state.casedContainerSel,
            casedContainers: this.state.casedContainers
        });
        this.setState({
            lines: lines,
            item: '',
            itemSel: null,
            quantity: '',
            price: '',
            tax: '',
            container: '',
            containerSel: null,
            containers: [],
            casedContainer: '',
            casedContainerSel: null,
            casedContainers: []
        }, () => {
            this.calculateTotals();
        });
    };

    deleteLine = (i) => (e) => {
        let lines = this.state.lines;
        lines.splice(i, 1);
        this.setState({lines: lines}, () => {
            this.calculateTotals();
        });
    };

    validateLine(){
        const t = this.props.t;
        let errors = {};
        if(this.state.item == null || this.state.item === '')
            errors[t('item')] = t('required');
        if(this.state.quantity == null || this.state.quantity === '')
            errors[t('quantity')] = t('required');
        if(this.state.containerSel != null && this.state.containerSel !== '' &&
           this.state.containerSel.quantity < this.state.quantity)
            errors[t('quantity')] = t('moreThanContainer');
        if(this.state.price == null || this.state.price === '')
            errors[t('price')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    calculateTotals() {
        let lines = this.state.lines;
        let preTax = 0;
        let discount = this.state.discountAmount != null && this.state.discountAmount !== '' ? parseFloat(this.state.discountAmount) : 0;
        let fullAmount = 0;
        let taxTotal = 0;
        let total = 0;
        let gstAmount = 0;
        let pstAmount = 0;
        let hstAmount = 0;
        let taxRate = this.state.taxRate != null && this.state.taxRate !== '' && this.state.taxRate !== '0' && this.state.taxRate !== 0 ? parseFloat(this.state.taxRate) / 100 : null;
        let gstRate = this.state.gstRate != null && this.state.gstRate !== '' && this.state.gstRate !== '0' && this.state.gstRate !== 0 ? parseFloat(this.state.gstRate) / 100 : null;
        let pstRate = this.state.pstRate != null && this.state.pstRate !== '' && this.state.pstRate !== '0' && this.state.pstRate !== 0 ? parseFloat(this.state.pstRate) / 100 : null;
        let hstRate = this.state.hstRate != null && this.state.hstRate !== '' && this.state.hstRate !== '0' && this.state.hstRate !== 0 ? parseFloat(this.state.hstRate) / 100 : null;
        lines.forEach(row => {
            let lineTotal = row.quantity * row.price;
            fullAmount = fullAmount + lineTotal;
            let taxLine = 0;
            let gstLine = 0;
            let pstLine = 0;
            let hstLine = 0;
            if(taxRate != null){
                taxLine = taxRate * lineTotal;
                taxLine = parseFloat(taxLine.toFixed(2));
            }
            if(gstRate != null){
                gstLine = gstRate * lineTotal;
                gstLine = parseFloat(gstLine.toFixed(2));
            }
            if(pstRate != null){
                pstLine = pstRate * lineTotal;
                pstLine = parseFloat(pstLine.toFixed(2));
            }
            if(hstRate != null){
                hstLine = hstRate * lineTotal;
                hstLine = parseFloat(hstLine.toFixed(2));
            }
            row.tax = taxLine;
            row.gst = gstLine;
            row.pst = pstLine;
            row.hst = hstLine;
            row.amount = lineTotal;
        });
        preTax = fullAmount - discount;
        if(taxRate != null){
            taxTotal = preTax * taxRate;
            if(this.state.shippingCost != null && this.state.shippingCost !== '' && this.state.shippingCost !== 0 && gstRate != null){
                taxTotal = taxTotal + (gstRate * parseFloat(this.state.shippingCost));
            }
            taxTotal = parseFloat(taxTotal.toFixed(2));
            total = preTax + taxTotal + (this.state.shippingCost != null && this.state.shippingCost !== '' ? parseFloat(this.state.shippingCost) : 0);
        }
        if(gstRate != null){
            gstAmount = preTax * gstRate;
            gstAmount = parseFloat(gstAmount.toFixed(2));
        }
        if(pstRate != null){
            pstAmount = preTax * pstRate;
            pstAmount = parseFloat(pstAmount.toFixed(2));
        }
        if(hstRate != null){
            hstAmount = preTax * hstRate;
            hstAmount = parseFloat(hstAmount.toFixed(2));
        }
        this.setState({
            lines: lines,
            preDiscount: fullAmount,
            taxAmount: taxTotal,
            totalAmount: total,
            pstAmount: pstAmount,
            gstAmount: gstAmount,
            hstAmount: hstAmount,
            amount: preTax
        });
    }

    closeAddressPick = () => {
        this.setState({showAddress: false});
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick());
    }

    _deleteClick = async () => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/sale/' + this.state._id);
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.props.history.goBack();
        }
    };

    togglePrintViewer = (type, record) => {
        if(this.state.printViewer === true) {
            this.setState({
                printViewer: false,
                printType: '',
                printRecord: ''
            });
        }else {
            this.setState({
                printViewer: true,
                printType: type,
                printRecord: record
            });
        }
        return;
    }

    selectShipment = (event, id) => {
        const selectedIndex = this.state.shipmentsSel.indexOf(id);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(this.state.shipmentsSel, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(this.state.shipmentsSel.slice(1));
        } else if (selectedIndex === this.state.shipmentsSel.length - 1) {
          newSelected = newSelected.concat(this.state.shipmentsSel.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            this.state.shipmentsSel.slice(0, selectedIndex),
            this.state.shipmentsSel.slice(selectedIndex + 1),
          );
        }

        this.setState({ shipmentsSel: newSelected});
    };

    selectAllShipments = (event) => {
        if(event.target.checked) {
            const newSelected = this.state.shipments.map((x) => x._id);
            this.setState({ shipmentsSel: newSelected });
            return;
          }
          this.setState({ shipmentsSel: [] });
    }

    createInvoice = async (shipments) => {
        const t = this.props.t;
        let invoice = null;
        try {
            let result = await axios.post('/api/createinvoice', { sale: this.state._id, shipments: shipments });
            invoice = result.data;
            this.setState({invoice: invoice.id, invoices: [...this.state.invoices, invoice.id], shipmentsSel: []}, () => {
                this.props.history.replace('/invoice/' + invoice.id);
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: invoice.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    deleteSigCallback = async (type) => {
        try{
            let result = await axios.post('/api/salesigdelete', {type: type, id: this.state._id});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    render(){
        const fieldAccess = this.state.fieldAccess;
        const errors = this.props.errors;
        const t = this.props.t;
        const ccx = this.props.auth.user.ccx;
        const isCanada = this.state.isCanada;
        const security = this.state.security;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        objectType={this.props.auth.constants.objectIds.sale}
                        id={this.state._id}
                        constants={this.props.auth.constants}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        printViewClick={() => this.togglePrintViewer('sale', this.state._id)}
                        printViewTitle={t('salesOrder')}
                        enablePrintView={
                            (this.state.invoices == null || this.state.invoices.length === 0) &&
                            (this.state.status === this.props.auth.constants.statuses.signed ||
                            this.state.status === this.props.auth.constants.statuses.shipped)
                        }
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={this.state.finalDocuments}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={2}>
                        <Grid item container spacing={2} sx={{ justifyContent: 'flex-start' }}>
                            {!fieldAccess && this.state.number != null && this.state.number !== '' &&
                                <Grid item xs={6} sm={3} md={2} xl={1}>
                                    <FormControl data-cy='number'>
                                        <FormLabel>{t('number')}</FormLabel>
                                        {this.state.number}
                                    </FormControl>
                                </Grid>
                            }
                            {fieldAccess &&
                                <Grid item xs={4} sm={2}>
                                    <TextField value={this.state.number} onChange={this.changeValue} name="number"
                                    size="medium" fullWidth disabled={!fieldAccess} label={t('number')}
                                    data-cy='number'
                                    error={errors[t('number')] != null ? true : false} helperText={errors[t('number')]}/>
                                </Grid>
                            }
			                <Grid item xs={4} sm={2}>
                                <FormControl data-cy='status'>
                                    <FormLabel>{t('status')}</FormLabel>
                                    {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={3} md={2} xl={1}>
                                {fieldAccess &&
                                    <DateSelect
                                        onChange={this.changeDate('date')}
                                        value={this.state.date}
                                        helperText={errors[t('date')]}
                                        error={errors[t('date')] != null ? true : false}
                                        label={t('date')}
                                        disabled={!fieldAccess}
                                        format={this.props.auth.user.dateFormat}
                                        data-cy='date'
                                    />
                                }
                                {!fieldAccess &&
                                    <FormControl data-cy='date'>
                                        <FormLabel>{t('date')}</FormLabel>
                                        <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                    </FormControl>
                                }
                            </Grid>
                            {ccx === true &&
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.typeSel}
                                            options={this.state.types}
                                            onChange={this.changeAuto('typeSel', 'type')}
                                            error={errors[t('type')] != null ? true : false}
                                            helperText={errors[t('type')]}
                                            label={t('type')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy='type'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy='type'>
                                            <FormLabel>{t('type')}</FormLabel>
                                            {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                            }
                            {ccx === true && this.state.ccxPostings != null && this.state.ccxPostings.length > 0 &&
                                <Aux>
                                    <Grid item xs={4} sm={2}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                value={this.state.ccxPostingSel}
                                                options={this.state.ccxPostings}
                                                onChange={this.changeAuto('ccxPostingSel', 'ccxPosting')}
                                                error={errors[t('ccxPosting')] != null ? true : false}
                                                helperText={errors[t('ccxPosting')]}
                                                label={t('ccxPosting')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy='ccxPosting'
                                            />
                                        }
                                        {!fieldAccess && this.state.ccxPostingSel != null &&
                                            <FormControl>
                                                <FormLabel>{t('ccxPosting')}</FormLabel>
                                                <Link to={'/ccxposting/' + this.state.ccxPostingSel.value}>{this.state.ccxPostingSel.label}</Link>
                                            </FormControl>
                                        }
                                    </Grid>
                                    {this.state.ccxPostingObj != null && this.state.ccxPostingObj.lotObj != null &&
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('lot')}</FormLabel>
                                                <Link to={'/lot/' + this.state.ccxPostingObj.lotObj._id}>{this.state.ccxPostingObj.lotObj.number}</Link>
                                            </FormControl>
                                        </Grid>
                                    }
                                    {this.state.ccxPostingObj != null &&
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('quantity')}</FormLabel>
                                                <NumericFormat displayType={'text'} value={this.state.ccxPostingObj.listingVolume} thousandSeparator={true}
                                                decimalScale={2} suffix='kg'/>
                                            </FormControl>
                                        </Grid>
                                    }
                                </Aux>
                            }
                            <Grid item xs={6} sm={3} md={2} xl={1}>
                                {this.state.locations != null && this.state.locations.length > 1 ?
                                    fieldAccess ?
                                        <AutoCompleteField
                                            value={this.state.locationSel}
                                            options={this.state.locations}
                                            onChange={this.changeAuto('locationSel', 'location')}
                                            error={errors[t('location')] != null ? true : false}
                                            helperText={errors[t('location')]}
                                            label={t('location')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy='location'
                                        />
                                    :
                                        <FormControl data-cy='location'>
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                        </FormControl>
                                    :
                                        <FormControl data-cy='location'>
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                        </FormControl>
                                }
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                {fieldAccess &&
                                    <AutoCompleteField
                                        value={this.state.creatorSel}
                                        options={this.state.users}
                                        onChange={this.changeAuto('creatorSel', 'creator')}
                                        error={errors[t('creator')] != null ? true : false}
                                        helperText={errors[t('creator')]} 
                                        label={t('user')}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy='creator'
                                    />
                                }
                                {!fieldAccess && this.state.creatorSel != null &&
                                    <FormControl data-cy='creator'>
                                        <FormLabel>{t('user')}</FormLabel>
                                        {this.state.creatorSel.label}
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={10} sm={4} xl={3}>
                                {fieldAccess &&
                                    <AutoCompleteField
                                        value={this.state.customerSel}
                                        options={this.state.customers}
                                        onChange={this.changeAuto('customerSel', 'customer')}
                                        error={errors[t('customer')] != null ? true : false}
                                        helperText={errors[t('customer')]} 
                                        label={t('customer')}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy='customer'
                                    />
                                }
                                {!fieldAccess && this.state.customerSel != null &&
                                    <FormControl data-cy='customer'>
                                        <FormLabel>{t('customer')}</FormLabel>
                                        <Link to={'/customer/' + this.state.customerSel.value}>{this.state.customerSel.label}</Link>
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={6} sm={3} md={2} xl={1}>
                                {fieldAccess &&
                                    <TextField value={this.state.purchaseOrder} onChange={this.changeValue} name="purchaseOrder"
                                    size="medium" fullWidth disabled={!fieldAccess} label={t('purchaseOrder')} data-cy='purchaseOrder'
                                    error={errors[t('purchaseOrder')] != null ? true : false} helperText={errors[t('purchaseOrder')]}/>
                                }
                                {!fieldAccess &&
                                    <FormControl data-cy='purchaseOrder'>
                                        <FormLabel>{t('purchaseOrder')}</FormLabel>
                                        {this.state.purchaseOrder != null ? this.state.purchaseOrder : null}
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={6} sm={3} lg={2} xl={1}>
                                {fieldAccess &&
                                    <AutoCompleteField
                                        value={this.state.termsSel}
                                        options={this.state.saleTerms}
                                        onChange={this.changeAuto('termsSel', 'terms')}
                                        error={errors[t('terms')] != null ? true : false}
                                        helperText={errors[t('terms')]} 
                                        label={t('terms')}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy='terms'
                                    />
                                }
                                {!fieldAccess && this.state.termsSel != null &&
                                    <FormControl data-cy='terms'>
                                        <FormLabel>{t('terms')}</FormLabel>
                                        {this.state.termsSel.label}
                                    </FormControl>
                                }
                            </Grid>
                            {this.state.customerSel?.foreignId === 'aglc' &&
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <TextField value={this.state.aglcInvoice} onChange={this.changeValue} name="aglcInvoice" required
                                        size="medium" fullWidth disabled={!fieldAccess} label={t('aglcInvoice')} data-cy='aglcInvoice'
                                        error={errors[t('aglcInvoice')] != null ? true : false} helperText={errors[t('aglcInvoice')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy='aglcInvoice'>
                                            <FormLabel>{t('aglcInvoice')}</FormLabel>
                                            {this.state.aglcInvoice != null ? this.state.aglcInvoice : null}
                                        </FormControl>
                                    }
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            {fieldAccess &&
                                <TextField value={this.state.address1} onChange={this.changeValue} name="address1"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('street')} required
                                error={errors[t('street')] != null ? true : false} helperText={errors[t('street')]}
                                data-cy='address1'/>
                            }
                            {!fieldAccess &&
                                <FormControl data-cy='address1'>
                                    <FormLabel>{t('street')}</FormLabel>
                                    {this.state.address1}
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            {fieldAccess &&
                                <TextField value={this.state.address2} onChange={this.changeValue} name="address2"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('street2')} data-cy='address2'
                                error={errors[t('address2')] != null ? true : false} helperText={errors[t('address2')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl data-cy='address2'>
                                    <FormLabel>{t('street2')}</FormLabel>
                                    {this.state.address2}
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.countrySel}
                                    options={this.state.countries}
                                    onChange={this.changeAuto('countrySel', 'country')}
                                    error={errors[t('country')] != null ? true : false}
                                    helperText={errors[t('country')]} 
                                    label={t('country')}
                                    disabled={!fieldAccess}
                                    required
                                    data-cy='country'
                                />
                            }
                            {!fieldAccess &&
                                <FormControl data-cy='country'>
                                    <FormLabel>{t('country')}</FormLabel>
                                    {this.state.countrySel != null ? this.state.countrySel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <TextField value={this.state.city} onChange={this.changeValue} name="city"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('city')} required
                                error={errors[t('city')] != null ? true : false} helperText={errors[t('city')]}
                                data-cy='city'/>
                            }
                            {!fieldAccess &&
                                <FormControl data-cy='city'>
                                    <FormLabel>{t('city')}</FormLabel>
                                    {this.state.city}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.provSel}
                                    options={this.state.provs}
                                    onChange={this.changeAuto('provSel', 'prov')}
                                    error={errors[t('prov')] != null ? true : false}
                                    helperText={errors[t('prov')]} 
                                    label={t('province')}
                                    disabled={!fieldAccess}
                                    required
                                    data-cy='prov'
                                />
                            }
                            {!fieldAccess &&
                                <FormControl data-cy='prov'>
                                    <FormLabel>{t('province')}</FormLabel>
                                    {this.state.provSel != null ? this.state.provSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        {(this.state.country == null || this.state.country === '' || this.state.country === this.props.auth.constants.countries.CA) &&
                            <Grid item xs={4} sm={2}>
                                {fieldAccess &&
                                    <InputMaskField mask="a9a-9a9" name="postal" value={this.state.postal}
                                    onChange={this.changeValue} 
                                    helperText={errors[t('postal')]}
                                    error={errors[t('postal')] != null ? true : false}
                                    label={t('postal')}
                                    disabled={!fieldAccess}
                                    required
                                    data-cy='postal'
                                    />
                                }
                                {!fieldAccess &&
                                    <FormControl data-cy='postal'>
                                        <FormLabel>{t('postal')}</FormLabel>
                                        {this.state.postal}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {this.state.country != null && this.state.country !== '' && this.state.country === this.props.auth.constants.countries.US &&
                            <Grid item xs={4} sm={2}>
                                {fieldAccess &&
                                    <InputMaskField mask="99999" name="postal" value={this.state.postal}
                                    onChange={this.changeValue} 
                                    helperText={errors[t('postal')]}
                                    error={errors[t('postal')] != null ? true : false}
                                    label={t('zipCode')}
                                    disabled={!fieldAccess}
                                    required
                                    data-cy='postal'
                                    />
                                }
                                {!fieldAccess &&
                                    <FormControl data-cy='postal'>
                                        <FormLabel>{t('zipCode')}</FormLabel>
                                        {this.state.postal}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {this.state.country != null && this.state.country !== '' && this.state.country !== this.props.auth.constants.countries.CA &&
                        this.state.country !== this.props.auth.constants.countries.US &&
                            <Grid item xs={4} sm={2}>
                                {fieldAccess &&
                                    <TextField value={this.state.postal} onChange={this.changeValue} name="postal"
                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('postal')} data-cy='postal'
                                    error={errors[t('postal')] != null ? true : false} helperText={errors[t('postal')]}/>
                                }
                                {!fieldAccess &&
                                    <FormControl data-cy='postal'>
                                        <FormLabel>{t('zipCode')}</FormLabel>
                                        {this.state.postal}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item sm={12}>
                                <TextField value={this.state.notes} onChange={this.changeValue} name="notes"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('notes')} data-cy='notes'
                                error={errors[t('notes')] != null ? true : false} helperText={errors[t('notes')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.notes != null && this.state.notes !== '' &&
                            <Grid item xs={12}>
                                <FormControl data-cy='notes'>
                                    <FormLabel>{t('notes')}</FormLabel>
                                    {this.state.notes}
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item sm={12}>
                                <TextField value={this.state.invoiceNotes} onChange={this.changeValue} name="invoiceNotes"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('invoiceNotes')} data-cy='invoiceNotes'
                                error={errors[t('invoiceNotes')] != null ? true : false} helperText={errors[t('invoiceNotes')]}/>
                            </Grid>
                        }
                        {!fieldAccess && this.state.invoiceNotes != null && this.state.invoiceNotes !== '' &&
                            <Grid item xs={12}>
                                <FormControl data-cy='invoiceNotes'>
                                    <FormLabel>{t('invoiceNotes')}</FormLabel>
                                    {this.state.invoiceNotes}
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <br/>
                    <Grid container spacing={3}>
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.shippingCost} onChange={this.changeNumber} name="shippingCost"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('shippingCost')} data-cy='shippingCost'
                                error={errors[t('shippingCost')] != null ? true : false} helperText={errors[t('shippingCost')]}/>
                            </Grid>
                         }
                         {!fieldAccess && this.state.shippingCost != null && this.state.shippingCost !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('shippingCost')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.shippingCost} thousandSeparator={true} decimalScale={2} prefix="$"/>
                                </FormControl>
                            </Grid>
                         }
                        {this.state.preDiscount != null && this.state.preDiscount !== '' && this.state.preDiscount !== '0' && this.state.preDiscount !== 0 &&
                            <Grid item xs={4} sm={2}>
                                <FormControl data-cy='preDiscount'>
                                    <FormLabel>{t('fullAmount')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.preDiscount} thousandSeparator={true}
                                    decimalScale={2} prefix="$"/>
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.discountAmount} onChange={this.changeNumber} name="discountAmount"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('discountAmount')} data-cy='discountAmount'
                                error={errors[t('discountAmount')] != null ? true : false} helperText={errors[t('discountAmount')]}/>
                            </Grid>
                         }
                         {!fieldAccess && this.state.discountAmount != null && this.state.discountAmount !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('discountAmount')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.discountAmount} thousandSeparator={true} decimalScale={2} prefix="$"/>
                                </FormControl>
                            </Grid>
                         }
                         {this.state.amount != null && this.state.amount !== '' && this.state.amount !== '0' && this.state.amount !== 0 &&
                            <Grid item xs={4} sm={2}>
                                <FormControl data-cy='amount'>
                                    <FormLabel>{t('preTax')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.amount} thousandSeparator={true}
                                    decimalScale={2} prefix="$"/>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            {fieldAccess && !isCanada &&
                                <TextField value={this.state.taxRate} onChange={this.changeNumber} name="taxRate"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('taxRate')} data-cy='taxRate'
                                error={errors[t('taxRate')] != null ? true : false} helperText={errors[t('taxRate')]}/>
                            }
                            {(!fieldAccess || isCanada) &&
                                <FormControl data-cy='taxRate'>
                                    <FormLabel>{t('taxRate')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.taxRate} thousandSeparator={true}
                                    decimalScale={2} />
                                </FormControl>
                            }
                        </Grid>
                        {isCanada &&
                            <Aux>
                                {this.state.hstRate != null && this.state.hstRate !== '' && this.state.hstRate !== '0' && this.state.hstRate !== 0 &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('hstRate')}</FormLabel>
                                            {this.state.hstRate}
                                        </FormControl>
                                    </Grid>
                                }
                                {(this.state.hstRate == null || this.state.hstRate === '' || this.state.hstRate === '0' || this.state.hstRate === 0) &&
                                    <Aux>
                                        {this.state.gstRate != null && this.state.gstRate !== '' && this.state.gstRate !== '0' && this.state.gstRate !== 0 &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('gstRate')}</FormLabel>
                                                    {this.state.gstRate}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.pstRate != null && this.state.pstRate !== '' && this.state.pstRate !== '0' && this.state.pstRate !== 0 &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('pstRate')}</FormLabel>
                                                    {this.state.pstRate}
                                                </FormControl>
                                            </Grid>
                                        }
                                    </Aux>
                                }
                            </Aux>
                        }
                        <Grid item xs={4} sm={2}>
                            <FormControl data-cy='taxAmount'>
                                <FormLabel>{t('taxAmount')}</FormLabel>
                                <NumericFormat displayType={'text'} value={this.state.taxAmount} thousandSeparator={true} decimalScale={2} prefix="$"/>
                            </FormControl>
                        </Grid>
                        {this.state.hstAmount != null && this.state.hstAmount !== '' && this.state.hstAmount !== '0' && this.state.hstAmount !== 0 &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('hst')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.hstAmount} thousandSeparator={true} decimalScale={2} prefix='$'/>
                                </FormControl>
                            </Grid>
                        }
                        {(this.state.hstAmount == null || this.state.hstAmount === '' || this.state.hstAmount === '0' || this.state.hstAmount === 0) &&
                            <Aux>
                                {this.state.gstAmount != null && this.state.gstAmount !== '' && this.state.gstAmount !== '0' && this.state.gstAmount !== 0 &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('gst')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={this.state.gstAmount} thousandSeparator={true} decimalScale={2} prefix='$'/>
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.pstAmount != null && this.state.pstAmount !== '' && this.state.pstAmount !== '0' && this.state.pstAmount !== 0 &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('pst')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={this.state.pstAmount} thousandSeparator={true} decimalScale={2} prefix='$'/>
                                        </FormControl>
                                    </Grid>
                                }
                            </Aux>
                        }
                        <Grid item xs={4} sm={2}>
                            <FormControl data-cy='totalAmount'>
                                <FormLabel>{t('totalAmount')}</FormLabel>
                                <NumericFormat displayType={'text'} value={this.state.totalAmount} thousandSeparator={true}
                                decimalScale={2} prefix="$"/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <DateSelect 
                                    onChange={this.changeDate('expectedDelivery')}
                                    value={this.state.expectedDelivery}
                                    helperText={errors[t('expectedDelivery')]}
                                    error={errors[t('expectedDelivery')] != null ? true : false}
                                    label={t('expectedDelivery')}
                                    disabled={!fieldAccess}
                                    format={this.props.auth.user.dateFormat}
                                    data-cy='expectedDelivery'
                                />
                            }
                            {!fieldAccess &&
                                <FormControl data-cy='expectedDelivery'>
                                    <FormLabel>{t('expectedDelivery')}</FormLabel>
                                    {this.state.expectedDelivery != null ?
                                        <DateDisplay value={this.state.expectedDelivery} format={this.props.auth.user.dateFormat}/>
                                        : t('none')
                                    }
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <DateSelect 
                                    onChange={this.changeDate('remittanceDate')}
                                    value={this.state.remittanceDate}
                                    helperText={errors[t('remittanceDate')]}
                                    error={errors[t('remittanceDate')] != null ? true : false}
                                    label={t('remittanceDue')}
                                    disabled={!fieldAccess}
                                    format={this.props.auth.user.dateFormat}
                                    data-cy='remittanceDate'
                                />
                            }
                            {!fieldAccess &&
                                <FormControl data-cy='remittanceDate'>
                                    <FormLabel>{t('remittanceDue')}</FormLabel>
                                    {this.state.expectedDelivery != null ?
                                        <DateDisplay value={this.state.remittanceDate} format={this.props.auth.user.dateFormat}/>
                                        : t('none')
                                    }
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    {this.state.hasForm === true &&
                        <Aux>
                            <br/>
                            {errors[t('form')] != null && errors[t('form')] !== '' &&
                                <div style={{color: 'red'}}>{errors[t('form')]}</div>
                            }
                            <Form renderer={renderer}
                                defaultFields={this.state.form}
                                onChange={this.formValueChanged}
                                disabled={!fieldAccess}/>
                        </Aux>
                    }
                    <br/>
                    <SectionTitle title="Lines"/>
                    {this.state.lines.map((row, i) =>
                        <Box borderColor="primary.main" {...defaultProps}  key={'item' + i} component={'div'}>
                            <Grid container spacing={3}>
                                {row.itemSel != null &&
                                    <Grid item xs={8} sm={4}>
                                        <FormControl data-cy={'item' + i}>
                                            <FormLabel>{t('item')}</FormLabel>
                                            <Link to={'/item/' + row.itemSel.value}>{row.itemSel.label}</Link>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField value={row.quantity} onChange={this.changeItemNumber('quantity', i)} name={"quantity" + i}
                                        size="medium" fullWidth={true} disabled={!fieldAccess} required label={t('quantity')} data-cy={'quantity' + i}
                                        error={errors[`${t('quantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('quantity')} (${t('line')} ${i})`]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy={'quantity' + i}>
                                            <FormLabel>{t('quantity')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}
                                            decimalScale={2}/>
                                        </FormControl>
                                    }
                                </Grid>
                                {fieldAccess && row.containers != null && row.containers.length > 0 &&
                                    <Grid item xs={6} sm={3}>
                                        <AutoCompleteField
                                            value={row.containerSel}
                                            options={row.containers}
                                            onChange={this.changeItemAuto('containerSel', 'container', i)}
                                            error={errors[`${t('container')} (${t('line')} ${i})`] != null ? true : false}
                                            helperText={errors[`${t('container')} (${t('line')} ${i})`]} 
                                            disabled={!fieldAccess}
                                            label={t('container')}
                                            data-cy={'container' + i}
                                            groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                            noneMessage={t('noLotAssigned')}
                                        />
                                    </Grid>
                                }
                                {!fieldAccess && row.containerSel != null &&
                                    <Grid item xs={6} sm={3}>
                                        <FormControl data-cy={'container' + i}>
                                            <FormLabel>{t('container')}</FormLabel>
                                            <Link to={'/container/' + row.containerSel.value}>{row.containerSel.label}</Link>
                                        </FormControl>
                                    </Grid>
                                }
                                {fieldAccess && row.casedContainers != null && row.casedContainers.length > 0 &&
                                    <Grid item xs={6} sm={3}>
                                        <AutoCompleteField
                                            value={row.casedContainerSel}
                                            options={row.casedContainers}
                                            onChange={this.changeItemAuto('casedContainerSel', 'casedContainer', i)}
                                            error={errors[`${t('casedContainer')} (${t('line')} ${i})`] != null ? true : false}
                                            helperText={errors[`${t('casedContainer')} (${t('line')} ${i})`]} 
                                            disabled={!fieldAccess}
                                            label={t('casedContainer')}
                                            data-cy={'casedContainer' + i}
                                            groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                            noneMessage={t('noLotAssigned')}
                                        />
                                    </Grid>
                                }
                                {!fieldAccess && row.casedContainerSel != null &&
                                    <Grid item xs={6} sm={3}>
                                        <FormControl data-cy={'casedContainer' + i}>
                                            <FormLabel>{t('casedContainer')}</FormLabel>
                                            <Link to={'/container/' + row.casedContainerSel.value}>{row.casedContainerSel.label}</Link>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField value={row.price} onChange={this.changeItemNumber('price', i)} name={"price" + i}
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('priceDollar')} data-cy={'price' + i}
                                        error={errors[`${t('price')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('price')} (${t('line')} ${i})`]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy={'price' + i}>
                                            <FormLabel>{t('priceDollar')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={row.price} thousandSeparator={true}
                                            decimalScale={6}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <FormControl data-cy={'tax' + i}>
                                        <FormLabel>{t('taxDollar')}</FormLabel>
                                        <NumericFormat displayType={'text'} value={row.tax} thousandSeparator={true}
                                        decimalScale={2} prefix="$"/>
                                    </FormControl>
                                </Grid>
                                {!fieldAccess && row.quantity != null && row.quantity !== '' && row.price != null && row.price !== '' && row.tax != null && row.tax !== '' &&
                                    <Aux>
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('preTax')}</FormLabel>
                                                <NumericFormat displayType={'text'} value={parseFloat(row.quantity) * parseFloat(row.price)} thousandSeparator={true}
                                                decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('total')}</FormLabel>
                                                <NumericFormat displayType={'text'} value={parseFloat(row.quantity) * parseFloat(row.price) + parseFloat(row.tax)} thousandSeparator={true}
                                                decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                    </Aux>
                                }
                                {fieldAccess &&
                                    <Grid item xs={2} sm={1}>
                                        <IconButton aria-label="delete line" onClick={this.deleteLine(i)} data-cy={'deleteItem' + i}>
                                            <RemoveCircleTwoToneIcon  />
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    )}
                    {fieldAccess &&
                        <Box borderColor="primary.main" {...defaultProps}  key={'item'} component={'div'}>
                            <Grid container spacing={3}>
                                <Grid item xs={8} sm={4}>
                                    <AutoCompleteField
                                        value={this.state.itemSel}
                                        options={this.state.items}
                                        onChange={this.changeAuto('itemSel', 'item')}
                                        error={errors[t('item')] != null ? true : false}
                                        helperText={errors[t('item')]} 
                                        disabled={!fieldAccess}
                                        required
                                        label={t('item')}
                                        data-cy='item'
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <TextField value={this.state.quantity} onChange={this.changeNumber} name="quantity"
                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} data-cy='quantity'
                                    error={errors[t('quantity')] != null ? true : false} helperText={errors[t('quantity')]}/>
                                </Grid>
                                {this.state.containers != null && this.state.containers.length > 0 &&
                                    <Grid item xs={6} sm={3}>
                                        <AutoCompleteField
                                            value={this.state.containerSel}
                                            options={this.state.containers}
                                            onChange={this.changeAuto('containerSel', 'container')}
                                            error={errors[t('container')] != null ? true : false}
                                            helperText={errors[t('container')]} 
                                            disabled={!fieldAccess}
                                            label={t('container')}
                                            data-cy='container'
                                            groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                            noneMessage={t('noLotAssigned')}
                                        />
                                    </Grid>
                                }
                                {this.state.casedContainers != null && this.state.casedContainers.length > 0 &&
                                    <Grid item xs={6} sm={3}>
                                        <AutoCompleteField
                                            value={this.state.casedContainerSel}
                                            options={this.state.casedContainers}
                                            onChange={this.changeAuto('casedContainerSel', 'casedContainer')}
                                            error={errors[t('casedContainer')] != null ? true : false}
                                            helperText={errors[t('casedContainer')]} 
                                            disabled={!fieldAccess}
                                            label={t('casedContainer')}
                                            data-cy='casedContainer'
                                            groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                            noneMessage={t('noLotAssigned')}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <TextField value={this.state.price} onChange={this.changeNumber} name="price"
                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('priceDollar')} data-cy='price'
                                    error={errors[t('price')] != null ? true : false} helperText={errors[t('price')]}/>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('taxDollar')}</FormLabel>
                                        <NumericFormat displayType={'text'} value={this.state.tax} thousandSeparator={true} decimalScale={2}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <IconButton aria-label="add line" onClick={this.addLine} data-cy='addLine'>
                                        <AddBoxTwoToneIcon  />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {this.state.shipped != null && this.state.shipped.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('shipped')}/>
                            <Grid container spacing={3} key="shippedHead">
                                <Grid item xs={8} sm={4}>{t('item')}</Grid>
                                <Grid item xs={4} sm={2}>{t('quantity')}</Grid>
                                <Grid item xs={4} sm={2}>{t('percent')}</Grid>
                                <Grid item xs={8} sm={4}>{t('containers')}</Grid>
                            </Grid>
                            {this.state.shipped.map((row, i) =>
                                <Grid container spacing={3} key={'shipped' + i}>
                                    <Grid item xs={8} sm={4}>{row.item.number + '-' + row.item.name}</Grid>
                                    <Grid item xs={4} sm={2}>
                                        <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}
                                        decimalScale={2} suffix={row.uom != null ? row.uom : ''}/>
                                    </Grid>
                                    {}
                                    {row.percent != null &&
                                        <Grid item xs={4} sm={2}>
                                            <NumericFormat displayType={'text'} value={row.percent} decimalScale={0}/>
                                        </Grid>
                                    }
                                    {row.shippedContainers != null && row.shippedContainers.length > 0 &&
                                        <Grid item xs={8} sm={4} sx={{ wordWrap: 'initial'}}>
                                            <FormControl data-cy={'sourceContainers' + i}>
                                            <span style={{wordWrap: 'initial', width: '300px'}}>
                                                {row.shippedContainers.reduce((prev, cont, i) => {
                                                    return [...prev, i > 0 ? <span>, </span> : null, <span key={cont._id}><Link to={'/container/' + cont._id}>{cont.number}</Link></span>]
                                                }, [])}
                                            </span>
                                            </FormControl>
                                        </Grid>
                                    }
                                </Grid>
                            )}
                        </Aux>
                    }
                    {this.state.shipments != null && this.state.shipments.length > 0 &&
                        <Aux>
                            <br/>
                            {/* <SectionTitle title={this.props.title != null && this.props.title !== '' ? this.props.title : t('shipments')}/> */}
                            <Toolbar
                                sx={{
                                    pl: { sm: 2 },
                                    pr: { xs: 1, sm: 1 },
                                    ...(this.state.shipmentsSel?.length > 0 && {
                                    bgcolor: (theme) =>
                                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                                    }),
                                }}
                            >
                                {this.state.shipmentsSel?.length > 0 ? (
                                    <Typography sx={{ flex: '1 1 80%' }} color="inherit" variant="subtitle1" component="div" >
                                        {this.state.shipmentsSel?.length + ' ' + t('selected')}
                                    </Typography>
                                ) : (
                                    <Typography sx={{ flex: '1 1 80%' }} variant="h6" id="tableTitle" component="div">
                                        {t('shipments')}
                                    </Typography>
                                )}

                                {this.state.shipmentsSel?.length > 0 ? 
                                    <CreateButton
                                        color="secondary"
                                        createClick={() => this.createInvoice(this.state.shipmentsSel)}
                                        security={true}
                                        data-cy="create"
                                    /> : null
                                }
                            </Toolbar>
                            <TableContainer component={Paper}>
                                <Table aria-label={t('shipments')} size="small">
                                    <TableHead key={'shipmentHeader'}>
                                        <TableRow>
                                            <TableCell padding="checkbox" sx={{ display: this.state.shipments?.length > 1 ? 'table-cell' : 'none'}}>
                                                <Checkbox
                                                    color="primary"
                                                    indeterminate={this.state.shipmentsSel?.length > 0 && this.state.shipmentsSel?.length < this.state.shipments?.length}
                                                    checked={this.state.shipments?.length > 0 && this.state.shipmentsSel?.length === this.state.shipments?.length}
                                                    onChange={(event) => this.selectAllShipments(event)}
                                                    inputProps={{
                                                    'aria-label': 'select all desserts',
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>{t('number')}</TableCell>
                                            <TableCell>{t('date')}</TableCell>
                                            <TableCell>{t('status')}</TableCell>
                                            <TableCell>{t('invoice')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.shipments.map((row, i) => {
                                            const moreThanOne = this.state.shipments?.length > 1;
                                            const isSelected = this.state.shipmentsSel?.indexOf(row._id) !== -1;
                                            const labelId = `enhanced-table-checkbox-${i}`;
                                            const shipped = row.status._id === this.props.auth.constants.statuses.signed;
                                            return (
                                                <TableRow 
                                                    key={row._id}
                                                    hover
                                                    onClick={moreThanOne && shipped ? (event) => this.selectShipment(event, row._id) : null}
                                                    role="checkbox"
                                                    aria-checked={isSelected}
                                                    tabIndex={-1}
                                                    selected={isSelected}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell padding="checkbox" sx={{ display: moreThanOne ? 'table-cell' : 'none'}}>
                                                        <Checkbox
                                                            color="primary"
                                                            disabled={!shipped}
                                                            checked={isSelected}
                                                            inputProps={{
                                                            'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell><Link to={'/shipment/' + row._id} name={'shipmentLink' + i}>{row.number}</Link></TableCell>
                                                    <TableCell><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                                    <TableCell>{row.status.label}</TableCell>
                                                    {shipped ? 
                                                        <TableCell>
                                                        {this.state.invoices?.length > 0 ? ((() => {
                                                            let invoice = this.state.invoices.find((invoice) => {
                                                                return invoice?.shipments != null && invoice?.shipments.length > 0 ? 
                                                                       invoice?.shipments.find((shipment) => shipment === row._id) : null;
                                                            });
                                                            const enableDownload = invoice?.finalDocument != null && invoice?.finalDocument !== '';
                                                            return invoice ? (
                                                                <ButtonGroup
                                                                    variant="contained"
                                                                    aria-label="split button"
                                                                    color="primary"
                                                                    size="small"
                                                                >
                                                                    <Button
                                                                        onClick={() => this.togglePrintViewer('invoice', invoice._id)}
                                                                        data-cy={'invoice' + i}
                                                                    >
                                                                        {t('viewInvoice')}
                                                                    </Button>
                                                                    <DownloadButton type="icon" record={invoice.finalDocument} t={t} disabled={!enableDownload} />
                                                                </ButtonGroup>
                                                            ) : (
                                                                !isSelected &&
                                                                <CreateButton
                                                                    color="secondary"
                                                                    // createClick={() => this.togglePrintViewer(row._id)}
                                                                    createClick={() => this.createInvoice([row._id])}
                                                                    security={true}
                                                                    data-cy="create"
                                                                />
                                                            );
                                                        })()) : this.state.invoice != null && this.state.invoice !== '' ?
                                                            <DownloadButton type="button" record={this.state.invoice} t={t} />
                                                        : (
                                                            !isSelected && <CreateButton
                                                                color="secondary"
                                                                // createClick={() => this.togglePrintViewer(row._id)}
                                                                createClick={() => this.createInvoice([row._id])}
                                                                security={true}
                                                                data-cy="create"
                                                            />
                                                        )}
                                                        </TableCell> :
                                                        <TableCell />
                                                    }
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Aux>
                    }
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            {this.state.fieldAccess &&
                                <Aux>
                                    <SaveButton enabled={this.state.enableSave} saveClick={this.save} data-cy='save'/>
                                    {this.props.permission.delete && this.state._id != null && this.state._id !== '' && 
                                    !this.state.hasShipments &&
                                        <DeleteButton deleteClick={this.deleteToggle} enabled={this.state.enableSave} data-cy='delete'/>
                                    }
                                </Aux>
                            }
                            {this.state.status === this.props.auth.constants.statuses.signed && this.props.permission.complete && this.state.hasShipping &&
                                <ShipButton shipClick={() => this.shipClick()} enabled={this.state.enableSave} data-cy='ship'/>
                            }
                            {this.state.status === this.props.auth.constants.statuses.shipped && this.props.permission.complete &&
                                <UnshipButton unshipClick={() => this.signClick()} enabled={this.state.enableSave} data-cy='unship'/>
                            }
                            {this.state.status === this.props.auth.constants.statuses.signed && this.props.permission.unlock &&
                                <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.sale} callback={this.loadRecord} enable={true} data-cy="unlock"/>
                            }
                        </Grid>
                    </Grid>
                    {this.state.masterCasings != null && this.state.masterCasings.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('masterCases')}/>
                            <Grid container spacing={3} key="masterCasingHead">
                                <Grid item xs={4} sm={2}>{t('number')}</Grid>
                                <Grid item xs={4} sm={2}>{t('date')}</Grid>
                                <Grid item xs={4} sm={2}>{t('status')}</Grid>
                            </Grid>
                            {this.state.masterCasings.map((row, i) =>  
                                <Grid container spacing={3} key={'masterCasing' + i}>
                                    <Grid item xs={4} sm={2}><Link to={'/mastercase/' + row._id} name={'masterCasingLink' + i}>{row.number}</Link></Grid>
                                    <Grid item xs={4} sm={2}><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></Grid>
                                    <Grid item xs={4} sm={2}>{row.status}</Grid>
                                </Grid>
                            )}
                        </Aux>
                    }
                </Panel>
                <Dialog open={this.state.showAddress} onClose={this.closeAddressPick} maxWidth="sm" fullWidth>
                    <DialogTitle>
                        <SectionTitle title={t('addressSelect')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item sm={10}>
                                <AutoCompleteField
                                    value={this.state.addressSel}
                                    options={this.state.addresses}
                                    onChange={this.changeAuto('addressSel', 'address')}
                                    error={errors[t('address')] != null ? true : false}
                                    helperText={errors[t('address')]} 
                                    label={t('address')}
                                    disabled={!fieldAccess}
                                    data-cy="addressSel"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={this.addressSelect} data-cy="addressSelectButton">
                            {t('select')}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={this.closeAddressPick} data-cy="addressClose">
                            {t('close')}
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.state.printViewer &&
                    <PrintView
                        id={this.state.printRecord}
                        object={this.props.auth.constants.objectIds[this.state.printType]}
                        open={this.state.printViewer}
                        onClose={this.togglePrintViewer}
                        callBack={this.loadRecord}
                        printAccess={security.print}
                    />
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.sale}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Sale));
