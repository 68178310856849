import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import Unlock from '../General/Unlock';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ActionBar from '../UI/Buttons/ActionBar';
import SectionTitle from '../UI/Typography/SectionTitle';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import CapaAction from './CapaAction';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class Incident extends Component{
    constructor(props){
        super(props);
        this.state = ({
            _id: '',
            employee: '',
            location: '',
            locations: [],
            type: '',
            types: [],
            date: new Date(),
            reviewDate: null,
            users: [],
            age: '',
            gender: '',
            genders: [],
            lengthOfService: '',
            lengthInCurrentPosition: '',
            occupation: '',
            nature: '',
            employeeDescription: '',
            basicCause: '',
            correctiveMeasures: [],
            outsideRecommendation: '',
            inspectionDepartment: '',
            managers: [],
            description: '',
            assignee: '',
            correctiveId: '',
            majorType: '',
            edit: false,
            signed: false,
            schedule: null,
            status: this.props.auth.constants.statuses.new,
            statuses: [],
            employeeSignature: null,
            reviewer: null,
            signer: null,
            qas: [],
            finalDocument: null,
            tenant: this.props.auth.user.tenant,
            documents: [],
            number: '',
            cameras: '',
            department: '',
            departments: [],
            userSelected: null,
            typeSelected: null,
            locationSelected: null,
            genderSelected: null,
            depSel: null,
            assigneeSelected: null,
            security: [],
            capaActions: [],
            editCapa: false,
            offset: 0,
            listSize: 5,
            page: 0,
            orderBy: 'number',
            ordDir: 1,
            totalCount: 0,
            capaId: '',
            capaIndex: -1,
            optionsMenu: [],
            flagForSignature: 0,
            dialogOpen: false,
            enableSave: true,
            goBack: -1
        })
        this.sigPad = {};
        this.changeValue = this.changeValue.bind(this);
        this.reviewDateChange = this.reviewDateChange.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.changeNumber = this.changeNumber.bind(this);
        this.saveClick = this.saveClick.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location, common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations
        let params = queryString.parse(this.props.location.search);
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        let goBack = -1;
        if(scheduleId != null)
            goBack = -2;
        let values = await axios.get("/api/majorvalues");
        let majorType = '';
        for(var i = 0; i < values.data.incidentTypes.length; i++){
            if (values.data.incidentTypes[i].label === "Major"){
                majorType = values.data.incidentTypes[i].value;
                break;
            }
        }
        var schedule = null;
        if(scheduleId != null)
            schedule = await axios.get('/api/schedulebase/' + scheduleId);
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
            this.props.auth.user.defaultLocation : '';
        let locSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locSel == null && locations.length === 1){
            locSel = locations[0];
            location = locSel.value;
        }
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        this.setState({
            users: values.data.users,
            managers: values.data.managers,
            genders: values.data.genders,
            types: values.data.incidentTypes,
            majorType: majorType,
            locations: locations,
            locationSelected: locSel,
            date: new Date(),
            schedule: scheduleId,
            statuses: cacheValues.statuses,
            qas: values.data.qas,
            departments: values.data.departments,
            location: schedule != null && schedule.data.location != null ? schedule.data.location : location,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            security: this.props.permission,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            goBack: goBack
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else { 
                const crumbs = [
                    { path: '/#/majors', label: this.props.t('incidents'), screen: this.props.auth.screenDefs.IncidentList},
                    { path: 'active', label: this.props.t('incident'), screen: this.props.auth.screenDefs.Incident}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        let major = await axios.get("/api/major/" + this.state._id)
        this.loadActions();
        let userData = await this.loadUsers(major.data.department);
        let locationSelected = this.state.locations.find(x => x.value === major.data.location);
        let typeSelected = this.state.types.find(x => x.value === major.data.type);
        let userSelected = this.state.users.find(x => x.value === major.data.employee);
        let genderSelected = this.state.genders.find(x => x.value === major.data.gender);
        let signed = major.data.status === this.props.auth.constants.statuses.complete ||
                     major.data.status === this.props.auth.constants.statuses.signed ||
                     major.data.status === this.props.auth.constants.statuses.reviewed;
        const depSel = major.data.department != null ? this.state.departments.find(x => x.value === major.data.department) : null;
        let statusSel = this.state.statuses.find(x => x.value === major.data.status);
        const completeSigSec = this.state._id != null && this.state._id !== '';
        const reviewSigSec = major.data.employeeSignature != null && major.data.employeeSignature._id != null &&
                             major.data.employeeSignature.signature != null && major.data.employeeSignature.signature !== '';
        const approveSigSec = major.data.reviewer != null && major.data.reviewer._id != null && 
                              major.data.reviewer.signature != null && major.data.reviewer.signature !== '';
        const optionsMenu = [
            { 
                security: completeSigSec, user: major.data.employee, users: this.state.users, name: t('employee'), object: major.data._id,
                title: t('employee'), setId: () => this.setSignature(), signature: major.data.employeeSignature, 
                objectType: this.props.auth.constants.objectIds.incident, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, 
            },
            { 
                security: reviewSigSec, user: this.props.auth.user.id, users: this.state.managers, name: t('review'),
                title: t('reviewedBy'), setId: () => this.setSignature(), signature: major.data.reviewer, object: major.data._id, 
                objectType: this.props.auth.constants.objectIds.incident, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: this.state.qas, name: t('approve'),
                title: t('approvedBy'), setId: () => this.setSignature(), object: major.data._id, signature: major.data.signer, 
                objectType: this.props.auth.constants.objectIds.incident, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = major.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 major.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 major.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        this.setState({
            _id: major.data._id,
            employee: major.data.employee,
            location: major.data.location,
            type: major.data.type,
            date: major.data.date,
            age: major.data.age,
            gender: major.data.gender,
            lengthOfService: major.data.lengthOfService,
            lengthInCurrentPosition: major.data.lengthInCurrentPosition,
            occupation: major.data.occupation,
            nature: major.data.nature,
            employeeDescription: major.data.employeeDescription,
            basicCause: major.data.basicCause,
            correctiveMeasures: major.data.correctiveMeasures,
            outsideRecommendation: major.data.outsideRecommendation,
            inspectionDepartment: major.data.inspectionDepartment,
            reviewDate: major.data.reviewDate,
            status: major.data.status != null ? major.data.status : this.props.auth.constants.statuses.inprogress,
            employeeSignature: major.data.employeeSignature,
            reviewer: major.data.reviewer,
            signer: major.data.signer,
            signed: signed,
            finalDocument: major.data.finalDocument,
            locationSelected: locationSelected,
            typeSelected: typeSelected,
            userSelected: userSelected,
            genderSelected: genderSelected,
            documents: major.data.documents,
            number: major.data.number,
            cameras: major.data.cameras,
            department: major.data.department,
            depSel: depSel,
            statusSel: statusSel,
            users: userData.create,
            managers: userData.review,
            qas: userData.approve,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature
        }, () => {
            const crumbs = [
                { path: '/#/majors', label: this.props.t('incidents'), screen: this.props.auth.screenDefs.IncidentList},
                { path: 'active', label: this.props.t('incident') + ' - ' + major.data.number, screen: this.props.auth.screenDefs.Incident}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    };

    loadActions = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: this.props.auth.constants.objectIds.incident, value: this.state._id},
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let result = await axios.get('/api/capas', {params: params});
        this.setState({
            capaActions: result.data.data,
            totalCount: result.data.totalCount
        });
    };

    async loadUsers(department){
        let parms = {
            name: 'Incident',
            department: department
        }
        var users = await axios.get('/api/screenusers', {params: parms});
        return users.data;
    }

    changeValue(e){
        let name = e.target.name;
        let value = e.target.value
        if(name === "age"){
            if(isNaN(value)){
                return;
            }
        }
        this.setState({[name]: value});
    }

    changeSelect = (name, data) => value => {
        this.setState({[name]: value, [data]: value.value});
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(data === 'department' && newValue != null){
            let userData = await this.loadUsers(newValue.value);
            this.setState({
                users: userData.create,
                managers: userData.review,
                qas: userData.approve
            });
        }
    };

    reviewDateChange(e){
        this.setState({reviewDate: e});
    }

    dateChange(e){
        this.setState({date: e});
    }

    changeNumber(name, e){
        this.setState({[name]: e.value});
    }

    saveClick = async () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const t = this.props.t;
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            _id: this.state._id,
            type: this.state.type,
            location: this.state.location,
            employee: this.state.employee,
            date: this.state.date,
            age: this.state.age,
            gender: this.state.gender,
            lengthOfService: this.state.lengthOfService,
            lengthInCurrentPosition: this.state.lengthInCurrentPosition,
            occupation: this.state.occupation,
            nature: this.state.nature,
            employeeDescription: this.state.employeeDescription,
            basicCause: this.state.basicCause,
            outsideRecommendation: this.state.outsideRecommendation,
            inspectionDepartment: this.state.inspectionDepartment,
            schedule: this.state.schedule,
            status: this.state.status,
            cameras: this.state.cameras,
            department: this.state.department
        }
        try {
            const result = await axios.post("/api/majorsave", data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/major/' + result.data.id);
            this.setState({
                _id: result.data.id,
                number: result.data.number,
                enableSave: true
            }, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    }

    validateForm(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.employee == null || state.employee === '')
            errors[t('employee')] = t('required');
        if(state.date == null || state.date === '')
            errors[t('date')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    addAction = (e) => {
        this.setState({editCapa: true})
    };

    closeDialog = (e) => {
        this.setState({
            editCapa: false,
            capaId: '',
            capaIndex: -1
        },() => {this.loadActions();});
    };

    editCapa = (i) => (e) => {
        let capa = this.state.capaActions[i];
        this.setState({capaId: capa._id, editCapa: true, capaIndex: i});
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadActions();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadActions();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadActions();
        });
    };

    setSignature = e => {
        this.loadRecord();
    };

    toggleDialog = () => {
        let dialogOpen = this.state.dialogOpen;
        this.setState({ dialogOpen: !dialogOpen });
    };

    render(){
        const errors = this.props.errors;
        const signed = this.state.signed;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar goBack={this.state.goBack}>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.incident}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Panel>
                            <Grid container spacing={3}>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number} 
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!signed &&
                                        <AutoCompleteField
                                            value={this.state.locationSelected}
                                            options={this.state.locations}
                                            onChange={this.changeAuto('locationSelected', 'location')}
                                            error={errors[t('location')] != null ? true : false}
                                            helperText={errors[t('location')]}
                                            label={t('location')}
                                            disabled={signed}
                                            required
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locationSelected != null ? this.state.locationSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!signed &&
                                        <AutoCompleteField
                                            value={this.state.typeSelected}
                                            options={this.state.types}
                                            onChange={this.changeAuto('typeSelected', 'type')}
                                            error={errors[t('type')] != null ? true : false}
                                            helperText={errors[t('type')]}
                                            label={t('type')}
                                            disabled={signed}
                                            required
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('type')}</FormLabel>
                                            {this.state.typeSelected != null ? this.state.typeSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    {!signed &&
                                        <AutoCompleteField
                                            value={this.state.userSelected}
                                            options={this.state.users}
                                            onChange={this.changeAuto('userSelected', 'employee')}
                                            error={errors[t('employee')] != null ? true : false}
                                            helperText={errors[t('employee')]}
                                            label={t('employee')}
                                            disabled={signed}
                                            required
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('employee')}</FormLabel>
                                            {this.state.userSelected != null ? this.state.userSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!signed &&
                                        <DateSelect
                                            onChange={this.dateChange}
                                            value={this.state.date}
                                            helperText={errors[t('date')]}
                                            error={errors[t('date')] != null ? true : false}
                                            disabled={signed}
                                            label={t('date')}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('date')}</FormLabel>
                                            <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    {!signed &&
                                        <TextField value={this.state.age} onChange={this.changeValue} name="age"
                                        size="medium" fullWidth={true} disabled={signed} label={t('age')}
                                        error={errors[t('age')] != null ? true : false} helperText={errors[t('age')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('age')}</FormLabel>
                                            {this.state.age} 
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!signed &&
                                        <AutoCompleteField
                                            value={this.state.genderSelected}
                                            options={this.state.genders}
                                            onChange={this.changeAuto('genderSelected', 'gender')}
                                            error={errors[t('gender')] != null ? true : false}
                                            helperText={errors[t('gender')]}
                                            label={t('gender')}
                                            disabled={signed}
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('gender')}</FormLabel>
                                            {this.state.genderSelected != null ? this.state.genderSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!signed &&
                                        <TextField value={this.state.lengthOfService} onChange={this.changeValue} name="lengthOfService"
                                        size="medium" fullWidth={true} disabled={signed} label={t('lengthOfService')}
                                        error={errors[t('lengthOfService')] != null ? true : false} helperText={errors[t('lengthOfService')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('lengthOfService')}</FormLabel>
                                            {this.state.lengthOfService} 
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!signed &&
                                        <TextField value={this.state.lengthInCurrentPosition} onChange={this.changeValue} name="lengthInCurrentPosition"
                                        size="medium" fullWidth={true} disabled={signed} label={t('lengthInPosition')}
                                        error={errors[t('lengthInCurrentPosition')] != null ? true : false} helperText={errors[t('lengthInCurrentPosition')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('lengthInPosition')}</FormLabel>
                                            {this.state.lengthInCurrentPosition} 
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!signed &&
                                        <TextField value={this.state.occupation} onChange={this.changeValue} name="occupation"
                                        size="medium" fullWidth={true} disabled={signed} label={t('jobTitle')}
                                        error={errors[t('occupation')] != null ? true : false} helperText={errors[t('occupation')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('jobTitle')}</FormLabel>
                                            {this.state.occupation} 
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!signed &&
                                        <AutoCompleteField
                                            value={this.state.depSel}
                                            options={this.state.departments}
                                            onChange={this.changeAuto('depSel', 'department')}
                                            error={errors[t('gender')] != null ? true : false}
                                            helperText={errors[t('department')]}
                                            label={t('department')}
                                            disabled={signed}
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('department')}</FormLabel>
                                            {this.state.depSel != null ? this.state.depSel.label : ''} 
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!signed &&
                                        <TextField value={this.state.cameras} onChange={this.changeValue} name="cameras"
                                        size="medium" fullWidth={true} disabled={signed} label={t('camerasInArea')}
                                        error={errors[t('cameras')] != null ? true : false} helperText={errors[t('cameras')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('camerasInArea')}</FormLabel>
                                            {this.state.cameras} 
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item sm={11}>
                                    {!signed &&
                                        <TextField value={this.state.nature} onChange={this.changeValue} name="nature" multiline={true} rows="3"
                                        variant="outlined" size="medium" fullWidth={true} disabled={signed} label={t('incidentDescription')}
                                        error={errors[t('nature')] != null ? true : false} helperText={errors[t('nature')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('incidentDescription')}</FormLabel>
                                            {this.state.nature} 
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item sm={11}>
                                    {!signed &&
                                        <TextField value={this.state.employeeDescription} onChange={this.changeValue} name="employeeDescription"
                                        multiline={true} rows="3"
                                        variant="outlined" size="medium" fullWidth={true} disabled={signed}
                                        label={t('incidentEmployeeDesc')}
                                        error={errors[t('employeeDescription')] != null ? true : false} helperText={errors[t('employeeDescription')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('incidentEmployeeDesc')}</FormLabel>
                                            {this.state.employeeDescription} 
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item sm={11}>
                                    {!signed &&
                                        <TextField value={this.state.basicCause} onChange={this.changeValue} name="basicCause"
                                        multiline={true} rows="3"
                                        variant="outlined" size="medium" fullWidth={true} disabled={signed}
                                        label={t('rootCause')}
                                        error={errors[t('basicCause')] != null ? true : false} helperText={errors[t('basicCause')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('rootCause')}</FormLabel>
                                            {this.state.basicCause} 
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            <Aux>
                                <br/>
                                <SectionTitle title={t('correctiveMeasures')}/>
                                <TableContainer component={Paper}>
                                    <Table aria-label="capaActions">
                                        <TableHead>
                                            <TableRow key="capaHeader">
                                                <TableCell>{t('number')}</TableCell>
                                                <TableCell>{t('title')}</TableCell>
                                                <TableCell>{t('dueDate')}</TableCell>
                                                <TableCell>{t('status')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.capaActions.map((row, i) =>
                                                <TableRow key={row._id}>
                                                    <TableCell>
                                                        <TextOnlyClickButton enabled={true} click={this.editCapa(i)} name={row.number}/>
                                                    </TableCell>
                                                    <TableCell>{row.title}</TableCell>
                                                    <TableCell><DateDisplay value={row.dueDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                                    <TableCell>{row.status}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow key="tableFooter">
                                                <TablePagination count={this.state.totalCount}
                                                rowsPerPage={this.state.listSize}
                                                onPageChange={this.handleChangePage}
                                                page = {this.state.page}
                                                rowsPerPageOptions={common.rowOptions}
                                                onRowsPerPageChange={this.handleRowsPerPage}/>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                                <Grid container spacing={3}>
                                    <Grid item sm={8}>
                                        <Button variant="outlined" color="primary" size="small" disabled={signed} onClick={this.addAction}>{t('add')}</Button>
                                    </Grid>
                                </Grid>
                                {this.state.editCapa &&
                                    <CapaAction
                                        reload={this.loadRecords}
                                        open={this.state.editCapa}
                                        onClose={this.closeDialog}
                                        incident={this.state._id}
                                        id={this.state.capaId}
                                        users={this.state.users}
                                        qas={this.state.qas}
                                        managers={this.state.managers}
                                    />
                                }
                            </Aux>
                            <br/>
                            <Grid container spacing={3}>
                                {this.state.type !== '' && this.state.type === this.state.majorType &&
                                    <Grid item sm={11}>
                                        {!signed &&
                                            <TextField value={this.state.outsideRecommendation} onChange={this.changeValue} name="outsideRecommendation"
                                            multiline={true} rows="3"
                                            variant="outlined" size="medium" fullWidth={true} disabled={signed}
                                            label={t('outsideMedicalRecommendations')}
                                            error={errors[t('outsideRecommendation')] != null ? true : false} helperText={errors[t('outsideRecommendation')]}/>
                                        }
                                        {signed &&
                                            <FormControl>
                                                <FormLabel>{t('outsideMedicalRecommendations')}</FormLabel>
                                                {this.state.outsideRecommendation} 
                                            </FormControl>
                                        }
                                    </Grid>
                                }
                                <Grid item sm={11}>
                                    {!signed &&
                                        <TextField value={this.state.inspectionDepartment} onChange={this.changeValue} name="inspectionDepartment"
                                        multiline={true} rows="3"
                                        variant="outlined" size="medium" fullWidth={true} disabled={signed}
                                        label={t('inspectionDepartment')}
                                        error={errors[t('inspectionDepartment')] != null ? true : false} helperText={errors[t('inspectionDepartment')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('inspectionDepartment')}</FormLabel>
                                            {this.state.inspectionDepartment} 
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                        </Panel>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={10} sm={5}>
                        {!signed &&
                            <Aux>
                                <Button variant="contained" color="primary" size="small" onClick={this.saveClick} disabled={signed && !this.state.enableSave}>{t('save')}</Button>&nbsp;&nbsp;
                            </Aux>
                        }
                        {signed &&
                            <Aux>
                                <Button variant="contained" size="small" href={'/#/incidentprint/' + this.state._id} color="secondary">{t('print')}</Button>&nbsp;&nbsp;
                            </Aux>
                        }
                        {signed &&
                            <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.incident} callback={this.setSignature}/>
                        }
                    </Grid>
                </Grid>
                {this.state.dialogOpen &&
                    <ScheduleDialog 
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security} 
                        open={this.state.dialogOpen} 
                        onClose={() => this.toggleDialog()}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.incident}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Incident));
