import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DocumentViewer from '../General/DocumentViewer';
import ActionBar from '../UI/Buttons/ActionBar';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { withTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { FormControl, FormLabel } from '@mui/material';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BoxTitle from '../UI/Typography/BoxTitle';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TableFooter, TablePagination } from '@mui/material';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { CSVDownload } from "react-csv";
import { Link } from 'react-router-dom';
import ModalDialog from '../UI/Dialog/ModalDialog';
import SectionTitle from '../UI/Typography/SectionTitle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

class HCReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            license: '',
            licenseSel: null,
            licenses: [],
            requestor: this.props.auth.user.id,
            requestorSel: null,
            users: [],
            requestDate: new Date(),
            startDate: '',
            endDate: '',
            document: null,
            security: [],
            reportData: null,
            enableSave: true,
            month: '',
            monthSel: null,
            months: [],
            year: '',
            yearSel: null,
            years: [],
            tabLabels: [{name: this.props.t('craB300'), disabled: false}],
            tabKey: 0,
            allTaxLines: [],
            taxLines: [],
            taxListSize: 10,
            taxListPage: 0,
            csvData: null,
            locked: false,
            submitted: false,
            submittedBy: null,
            is2024: false,
            ctls: null,
            showLedgers: false,
            ledgers: [],
            ids: [],
            negLedgers: null,
            negIds: null,
            clean: false,
            previous: '',
            previousSel: null,
            previouses: [],
            status: '',
            statusSel: null,
            statuses: []
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.user, common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        const vals = await axios.get('/api/hcreportvalues');
        let licenseSel = null;
        if(vals.data.licenses != null && vals.data.licenses.length === 1){
            licenseSel = vals.data.licenses[0];
        }
        let requestorSel = cacheValues.users.find(x => x.value === this.state.requestor);
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        let curr = new Date();
        let currYear = curr.getFullYear();
        let currMonth = curr.getMonth() + 1;
        let years = [];
        years.push({value: (currYear + 1).toString(), label: (currYear + 1).toString()});
        for(let i = currYear; i > 2016; i--){
            years.push({value: i.toString(), label: i.toString()});
        }
        let yearSel = years.find(x => x.value === currYear.toString());
        let months = [{value: '1', label: '1'}, {value: '2', label: '2'}, {value: '3', label: '3'}, {value: '4', label: '4'}, {value: '5', label: '5'}, {value: '6', label: '6'}, 
                    {value: '7', label: '7'}, {value: '8', label: '8'}, {value: '9', label: '9'}, {value: '10', label: '10'}, {value: '11', label: '11'}, {value: '12', label: '12'}];
        this.setState({
            licenses: vals.data.licenses,
            users: cacheValues.users,
            requestorSel: requestorSel,
            security: this.props.permission,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null,
            year: currYear.toString(),
            month: currMonth.toString(),
            yearSel: yearSel,
            years: years,
            months: months,
            statuses: cacheValues.statuses,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            licenseSel: licenseSel,
            license: licenseSel != null ? licenseSel.value : ''
        }, () => {
            const crumbs = [
                { path: '/#/hcreports', label: this.props.t('hcReports'), screen: this.props.auth.screenDefs.HCReportList},
                { path: 'active', label: this.props.t('hcReport'), screen: this.props.auth.screenDefs.HCReport}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            if(this.state._id != null){
                this.loadRecord();
            }
        });

    }

    async loadRecord(){
        const t = this.props.t;
        let record = await axios.get('/api/hcreport/' + this.state._id);
        let licenses = this.state.licenses;
        let licenseSel = licenses.find(x => x.value === record.data.license);
        let users = this.state.users;
        let requestorSel = users.find(x => x.value === record.data.requestor);
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let year = record.data.year != null ? record.data.year.toString() : '';
        let month = record.data.month != null ? record.data.month.toString() : '';
        let yearSel = year !== '' ? this.state.years.find(x => x.value === year) : null;
        let is2024 = false;
        if(year !== ''){
            let yearInt = parseInt(year);
            if(yearInt > 2023)
                is2024 = true;
        }
        let monthSel = month !== '' ? this.state.months.find(x => x.value === month) : null;
        let reportData = record.data;
        let ctls = reportData.ctls;
        if(ctls != null && ctls.unpackaged.opening.vegPlant === 0 && ctls.unpackaged.opening.wholePlant === 0 && ctls.unpackaged.opening.seeds === 0 && 
            ctls.unpackaged.opening.fresh === 0 && ctls.unpackaged.opening.dried === 0 && ctls.unpackaged.opening.pureIntermediaries === 0 && ctls.unpackaged.opening.solid === 0 &&
            ctls.unpackaged.opening.nonSolid === 0 && ctls.unpackaged.opening.topical === 0 && ctls.unpackaged.opening.ingest === 0 && ctls.unpackaged.opening.inhaled === 0 &&
            ctls.unpackaged.closing.vegPlant === 0 && ctls.unpackaged.closing.wholePlant === 0 && ctls.unpackaged.closing.seeds === 0 && ctls.unpackaged.closing.fresh === 0 && 
            ctls.unpackaged.closing.dried === 0 && ctls.unpackaged.closing.pureIntermediaries === 0 && ctls.unpackaged.closing.solid === 0 && ctls.unpackaged.closing.nonSolid === 0 &&
            ctls.unpackaged.closing.topical === 0 && ctls.unpackaged.closing.ingest === 0 && ctls.unpackaged.closing.inhaled === 0){
            ctls = null;
        } else if(ctls != null){
            let unAdd = ctls.unpackaged.additions;
            unAdd.total = {
                seeds: 0, 
                vegPlant: 0, 
                wholePlant: 0, 
                fresh: 0, 
                dried: 0,
                hemp: 0,
                pureIntermediaries: 0, 
                solid: 0, 
                nonSolid: 0, 
                topical: 0, 
                ingest: 0, 
                inhaled: 0, 
                otherExtract: 0, 
                other: 0
            };
            unAdd.total.seeds = unAdd.produced.seeds + unAdd.imported.seeds + unAdd.other.seeds + unAdd.received.seeds + unAdd.return.seeds;
            unAdd.total.vegPlant = unAdd.produced.vegPlant + unAdd.imported.vegPlant + unAdd.other.vegPlant + unAdd.received.vegPlant + unAdd.return.vegPlant;
            unAdd.total.wholePlant = unAdd.produced.wholePlant + unAdd.imported.wholePlant + unAdd.other.wholePlant + unAdd.received.wholePlant + unAdd.return.wholePlant;
            unAdd.total.fresh = unAdd.produced.fresh + unAdd.imported.fresh + unAdd.other.fresh + unAdd.received.fresh + unAdd.return.fresh;
            unAdd.total.dried = unAdd.produced.dried + unAdd.imported.dried + unAdd.other.dried + unAdd.received.dried + unAdd.return.dried;
            unAdd.total.hemp = unAdd.imported.hemp + unAdd.other.hemp + unAdd.received.hemp;
            unAdd.total.pureIntermediaries = unAdd.produced.pureIntermediaries + unAdd.imported.pureIntermediaries + unAdd.other.pureIntermediaries + unAdd.received.pureIntermediaries + unAdd.return.pureIntermediaries;
            unAdd.total.solid = unAdd.produced.solid + unAdd.imported.solid + unAdd.other.solid + unAdd.received.solid + unAdd.return.solid;
            unAdd.total.nonSolid = unAdd.produced.nonSolid + unAdd.imported.nonSolid + unAdd.other.nonSolid + unAdd.received.nonSolid + unAdd.return.nonSolid;
            unAdd.total.topical = unAdd.produced.topical + unAdd.imported.topical + unAdd.other.topical + unAdd.received.topical + unAdd.return.topical;
            unAdd.total.ingest = unAdd.produced.ingest + unAdd.imported.ingest + unAdd.other.ingest + unAdd.received.ingest + unAdd.return.ingest;
            unAdd.total.inhaled = unAdd.produced.inhaled + unAdd.imported.inhaled + unAdd.other.inhaled + unAdd.received.inhaled + unAdd.return.inhaled;
            unAdd.total.otherExtract = unAdd.produced.otherExtract + unAdd.imported.otherExtract + unAdd.other.otherExtract + unAdd.received.otherExtract + unAdd.return.otherExtract;
            unAdd.total.other = unAdd.produced.other + unAdd.imported.other + unAdd.other.other + unAdd.received.other + unAdd.return.other;
            let unRed = ctls.unpackaged.reductions;
            unRed.total = {
                seeds: 0,
                vegPlant: 0,
                wholePlant: 0,
                fresh: 0,
                dried: 0,
                hemp: 0,
                pureIntermediaries: 0,
                solid: 0,
                nonSolid: 0,
                topical: 0,
                ingest: 0,
                inhaled: 0,
                otherExtract: 0,
                other: 0
            };
            unRed.total.seeds = unRed.processed.seeds + unRed.packaged.seeds + unRed.shipped.seeds + unRed.test.seeds + unRed.research.seeds + 
                unRed.exported.seeds + unRed.returned.seeds + unRed.destroyed.seeds + unRed.lost.seeds + unRed.other.seeds;
            unRed.total.vegPlant = unRed.processed.vegPlant + unRed.packaged.vegPlant + unRed.shipped.vegPlant + unRed.test.vegPlant + unRed.research.vegPlant + 
                unRed.exported.vegPlant + unRed.returned.vegPlant + unRed.destroyed.vegPlant + unRed.lost.vegPlant + unRed.other.vegPlant;
            unRed.total.wholePlant = unRed.processed.wholePlant + unRed.packaged.wholePlant + unRed.shipped.wholePlant + unRed.test.wholePlant + unRed.research.wholePlant +
                unRed.exported.wholePlant + unRed.returned.wholePlant + unRed.destroyed.wholePlant + unRed.lost.wholePlant + unRed.other.wholePlant;
            unRed.total.fresh = unRed.processed.fresh + unRed.packaged.fresh + unRed.shipped.fresh + unRed.test.fresh + unRed.research.fresh + 
                unRed.exported.fresh + unRed.returned.fresh + unRed.processingLoss.fresh + unRed.destroyed.fresh + unRed.lost.fresh + unRed.other.fresh;
            unRed.total.dried = unRed.processed.dried + unRed.packaged.dried + unRed.shipped.dried + unRed.test.dried + unRed.research.dried + 
                unRed.exported.dried + unRed.returned.dried + unRed.processingLoss.dried + unRed.destroyed.dried + unRed.lost.dried + unRed.other.dried;
            unRed.total.hemp = unRed.processed.hemp + unRed.packaged.hemp + unRed.shipped.hemp + unRed.test.hemp + unRed.research.hemp + 
                unRed.exported.hemp + unRed.returned.hemp + unRed.processingLoss.hemp + unRed.destroyed.hemp + unRed.lost.hemp + unRed.other.hemp;
            unRed.total.pureIntermediaries = unRed.shipped.pureIntermediaries + unRed.test.pureIntermediaries + unRed.research.pureIntermediaries + 
                unRed.exported.pureIntermediaries + unRed.returned.pureIntermediaries + unRed.processingLoss.pureIntermediaries + unRed.destroyed.pureIntermediaries + 
                unRed.lost.pureIntermediaries + unRed.other.pureIntermediaries + unRed.processed.pureSolid + unRed.processed.pureNonSolid + unRed.processed.pureIngest + 
                unRed.processed.pureInhaled + unRed.processed.pureExtract + unRed.processed.pureTopical;
            unRed.total.solid = unRed.processed.solid + unRed.packaged.solid + unRed.shipped.solid + unRed.test.solid + unRed.research.solid + 
                unRed.exported.solid + unRed.returned.solid + unRed.processingLoss.solid + unRed.destroyed.solid + unRed.lost.solid + unRed.other.solid;
            unRed.total.nonSolid = unRed.processed.nonSolid + unRed.packaged.nonSolid + unRed.shipped.nonSolid + unRed.test.nonSolid + unRed.research.nonSolid + 
                unRed.exported.nonSolid + unRed.returned.nonSolid + unRed.processingLoss.nonSolid + unRed.destroyed.nonSolid + unRed.lost.nonSolid + unRed.other.nonSolid;
            unRed.total.inhaled = unRed.processed.inhaled + unRed.packaged.inhaled + unRed.shipped.inhaled + unRed.test.inhaled + unRed.research.inhaled + 
                unRed.exported.inhaled + unRed.returned.inhaled + unRed.processingLoss.inhaled + unRed.destroyed.inhaled + unRed.lost.inhaled + unRed.other.inhaled;
            unRed.total.ingest = unRed.processed.ingest + unRed.packaged.ingest + unRed.shipped.ingest + unRed.test.ingest + unRed.research.ingest + 
                unRed.exported.ingest + unRed.returned.ingest + unRed.processingLoss.ingest + unRed.destroyed.ingest + unRed.lost.ingest + unRed.other.ingest;
            unRed.total.otherExtract = unRed.processed.otherExtract + unRed.packaged.otherExtract + unRed.shipped.otherExtract + unRed.test.otherExtract + unRed.research.otherExtract +
                unRed.exported.otherExtract + unRed.returned.otherExtract + unRed.processingLoss.otherExtract + unRed.destroyed.otherExtract +  
                unRed.lost.otherExtract + unRed.other.otherExtract;
            unRed.total.topical = unRed.processed.topical + unRed.packaged.topical + unRed.shipped.topical + unRed.test.topical + unRed.research.topical + 
                unRed.exported.topical + unRed.returned.topical + unRed.processingLoss.topical + unRed.destroyed.topical + unRed.lost.topical + unRed.other.topical;
            unRed.total.other = unRed.processed.other + unRed.packaged.other + unRed.shipped.other + unRed.test.other + unRed.research.other + 
                unRed.exported.other + unRed.returned.other + unRed.processingLoss.other + unRed.destroyed.other + unRed.lost.other + unRed.other.other;
            ctls.unpackaged.check = {};
            let unCheck = ctls.unpackaged.check;
            unCheck.seeds = ctls.unpackaged.opening.seeds + unAdd.total.seeds - unRed.total.seeds;
            unCheck.vegPlant = ctls.unpackaged.opening.vegPlant + unAdd.total.vegPlant - unRed.total.vegPlant;
            unCheck.wholePlant = ctls.unpackaged.opening.wholePlant + unAdd.total.wholePlant - unRed.total.wholePlant;
            unCheck.fresh = ctls.unpackaged.opening.fresh + unAdd.total.fresh - unRed.total.fresh;
            unCheck.dried = ctls.unpackaged.opening.dried + unAdd.total.dried - unRed.total.dried;
            unCheck.hemp = ctls.unpackaged.opening.hemp + unAdd.total.hemp - unRed.total.hemp;
            unCheck.pureIntermediaries = ctls.unpackaged.opening.pureIntermediaries + unAdd.total.pureIntermediaries - unRed.total.pureIntermediaries;
            unCheck.solid = ctls.unpackaged.opening.solid + unAdd.total.solid - unRed.total.solid;
            unCheck.nonSolid = ctls.unpackaged.opening.nonSolid + unAdd.total.nonSolid - unRed.total.nonSolid;
            unCheck.topical = ctls.unpackaged.opening.topical + unAdd.total.topical - unRed.total.topical;
            unCheck.ingest = ctls.unpackaged.opening.ingest + unAdd.total.ingest - unRed.total.ingest;
            unCheck.inhaled = ctls.unpackaged.opening.inhaled + unAdd.total.inhaled - unRed.total.inhaled;
            unCheck.otherExtract = ctls.unpackaged.opening.otherExtract + unAdd.total.otherExtract - unRed.total.otherExtract;
            unCheck.other = ctls.unpackaged.opening.other + unAdd.total.other - unRed.total.other;
            let packAdd = ctls.packaged.additions;
            packAdd.total = {
                seeds: 0,
                vegPlant: 0,
                fresh: 0,
                dried: 0,
                solid: 0,
                nonSolid: 0,
                topical: 0,
                ingest: 0,
                inhaled: 0,
                otherExtract: 0,
                other: 0
            };
            packAdd.total.seeds = packAdd.packaged.seeds + packAdd.received.seeds + packAdd.return.seeds + packAdd.other.seeds;
            packAdd.total.vegPlant = packAdd.packaged.vegPlant + packAdd.received.vegPlant + packAdd.return.vegPlant + packAdd.other.vegPlant;
            packAdd.total.fresh = packAdd.packaged.fresh + packAdd.received.fresh + packAdd.return.fresh + packAdd.other.fresh;
            packAdd.total.dried = packAdd.packaged.dried + packAdd.received.dried + packAdd.return.dried + packAdd.other.dried;
            packAdd.total.solid = packAdd.packaged.solid + packAdd.received.solid + packAdd.return.solid + packAdd.other.solid;
            packAdd.total.nonSolid = packAdd.packaged.nonSolid + packAdd.received.nonSolid + packAdd.return.nonSolid + packAdd.other.nonSolid;
            packAdd.total.topical = packAdd.packaged.topical + packAdd.received.topical + packAdd.return.topical + packAdd.other.topical;
            packAdd.total.ingest = packAdd.packaged.ingest + packAdd.received.ingest + packAdd.return.ingest + packAdd.other.ingest;
            packAdd.total.inhaled = packAdd.packaged.inhaled + packAdd.received.inhaled + packAdd.return.inhaled + packAdd.other.inhaled;
            packAdd.total.otherExtract = packAdd.packaged.otherExtract + packAdd.received.otherExtract + packAdd.return.otherExtract + packAdd.other.otherExtract;
            packAdd.total.other = packAdd.packaged.other + packAdd.received.other + packAdd.return.other + packAdd.other.other;
            let packRed = ctls.packaged.reductions;
            packRed.total = {
                seeds: 0,
                vegPlant: 0,
                fresh: 0,
                dried: 0,
                solid: 0,
                nonSolid: 0,
                topical: 0,
                ingest: 0,
                inhaled: 0,
                otherExtract: 0,
                other: 0
            };
            packRed.total.seeds = packRed.shipped.seeds + packRed.returned.seeds + packRed.destroyed.seeds + packRed.lost.seeds + packRed.other.seeds;
            packRed.total.vegPlant = packRed.shipped.vegPlant + packRed.returned.vegPlant + packRed.destroyed.vegPlant + packRed.lost.vegPlant + packRed.other.vegPlant;
            packRed.total.fresh = packRed.shipped.fresh + packRed.returned.fresh + packRed.destroyed.fresh + packRed.lost.fresh + packRed.other.fresh;
            packRed.total.dried = packRed.shipped.dried + packRed.returned.dried + packRed.destroyed.dried + packRed.lost.dried + packRed.other.dried;
            packRed.total.solid = packRed.shipped.solid + packRed.returned.solid + packRed.destroyed.solid + packRed.lost.solid + packRed.other.solid;
            packRed.total.nonSolid = packRed.shipped.nonSolid + packRed.returned.nonSolid + packRed.destroyed.nonSolid + packRed.lost.nonSolid + packRed.other.nonSolid;
            packRed.total.topical = packRed.shipped.topical + packRed.returned.topical + packRed.destroyed.topical + packRed.lost.topical + packRed.other.topical;
            packRed.total.ingest = packRed.shipped.ingest + packRed.returned.ingest + packRed.destroyed.ingest + packRed.lost.ingest + packRed.other.ingest;
            packRed.total.inhaled = packRed.shipped.inhaled + packRed.returned.inhaled + packRed.destroyed.inhaled + packRed.lost.inhaled + packRed.other.inhaled;
            packRed.total.otherExtract = packRed.shipped.otherExtract + packRed.returned.otherExtract + packRed.destroyed.otherExtract + packRed.lost.otherExtract + packRed.other.otherExtract;
            packRed.total.other = packRed.shipped.other + packRed.returned.other + packRed.destroyed.other + packRed.lost.other + packRed.other.other;
            ctls.packaged.check = {};
            let check = ctls.packaged.check;
            check.seeds = ctls.packaged.opening.seeds + packAdd.total.seeds - packRed.total.seeds;
            check.vegPlant = ctls.packaged.opening.vegPlant + packAdd.total.vegPlant - packRed.total.vegPlant;
            check.fresh = ctls.packaged.opening.fresh + packAdd.total.fresh - packRed.total.fresh;
            check.dried = ctls.packaged.opening.dried + packAdd.total.dried - packRed.total.dried;
            check.solid = ctls.packaged.opening.solid + packAdd.total.solid - packRed.total.solid;
            check.nonSolid = ctls.packaged.opening.nonSolid + packAdd.total.nonSolid - packRed.total.nonSolid;
            check.topical = ctls.packaged.opening.topical + packAdd.total.topical - packRed.total.topical;
            check.ingest = ctls.packaged.opening.ingest + packAdd.total.ingest - packRed.total.ingest;
            check.inhaled = ctls.packaged.opening.inhaled + packAdd.total.inhaled - packRed.total.inhaled;
            check.otherExtract = ctls.packaged.opening.otherExtract + packAdd.total.otherExtract - packRed.total.otherExtract;
            check.other = ctls.packaged.opening.other + packAdd.total.other - packRed.total.other;
        }
        let dutiesByTypeProv = reportData.dutiesByTypeProv;
        let flowerTotals = null;
        let flowerDuties = null;
        if(reportData.dutyTotals != null){
             flowerTotals= reportData.dutyTotals.find(x => x.taxType === 'flower');
            flowerDuties = dutiesByTypeProv.find(x => x.taxType === 'flower');
            if(reportData.unpackaged.openingInventory.flower === 0 && reportData.unpackaged.openingInventory.nonFlower === 0 && reportData.unpackaged.openingInventory.wholePlants === 0 && 
                reportData.unpackaged.openingInventory.seeds === 0 && reportData.unpackaged.openingInventory.vegPlants === 0 && reportData.unpackaged.openingInventory.pureIntermediaries === 0 &&
                reportData.unpackaged.openingInventory.extractsTHC === 0 && reportData.unpackaged.openingInventory.edibleTHC === 0 && reportData.unpackaged.openingInventory.topicalTHC === 0 &&
                reportData.unpackaged.closingInventory.flower === 0 && reportData.unpackaged.closingInventory.nonFlower === 0 && reportData.unpackaged.closingInventory.wholePlants === 0 && 
                reportData.unpackaged.closingInventory.seeds === 0 && reportData.unpackaged.closingInventory.vegPlants === 0 && reportData.unpackaged.closingInventory.pureIntermediaries === 0 &&
                reportData.unpackaged.closingInventory.extractsTHC === 0 && reportData.unpackaged.closingInventory.edibleTHC === 0 && reportData.unpackaged.closingInventory.topicalTHC === 0){
                reportData = null;
            }
        } else {
            reportData = null;
        }
        if(reportData != null){
            reportData.flowerDuties = flowerDuties != null && flowerDuties.provs != null ? flowerDuties.provs : null;
            if(reportData.flowerDuties != null){
                reportData.flowerDuties.sort(function(a, b){
                    return a.prov.name > b.prov.name ? 1 : -1;
                });
            }
            reportData.flowerTotal = flowerTotals;
            let seedTotals = reportData.dutyTotals.find(x => x.taxType === 'seed');
            let seedDuties = dutiesByTypeProv.find(x => x.taxType === 'seed');
            reportData.seedDuties = seedDuties != null && seedDuties.provs != null ? seedDuties.provs : null;
            if(reportData.seedDuties != null){
                reportData.seedDuties.sort(function(a, b){
                    return a.prov.name > b.prov.name ? 1 : -1;
                });
            }
            reportData.seedTotal = seedTotals;
            let extractTotals = reportData.dutyTotals.find(x => x.taxType === 'extract');
            let extractDuties = dutiesByTypeProv.find(x => x.taxType === 'extract');
            reportData.extractDuties = extractDuties != null && extractDuties.provs != null ? extractDuties.provs : null;
            if(reportData.extractDuties != null){
                reportData.extractDuties.sort(function(a, b){
                    return a.prov.name > b.prov.name ? 1 : -1;
                });
            }
            reportData.extractTotal = extractTotals;
            let edibleTotals = reportData.dutyTotals.find(x => x.taxType === 'edible');
            let edibleDuties = dutiesByTypeProv.find(x => x.taxType === 'edible');
            reportData.edibleDuties = edibleDuties != null && edibleDuties.provs != null ? edibleDuties.provs : null;
            if(reportData.edibleDuties != null){
                reportData.edibleDuties.sort(function(a, b){
                    return a.prov.name > b.prov.name ? 1 : -1;
                });
            }
            reportData.edibleTotal = edibleTotals;
            let nonfFlowerTotals = reportData.dutyTotals.find(x => x.taxType === 'nonFlower');
            let nonFlowerDuties = dutiesByTypeProv.find(x => x.taxType === 'nonFlower');
            reportData.nonFlowerDuties = nonFlowerDuties != null && nonFlowerDuties.provs != null ? nonFlowerDuties.provs : null;
            if(reportData.nonFlowerDuties != null){
                reportData.nonFlowerDuties.sort(function(a, b){
                    return a.prov.name > b.prov.name ? 1 : -1;
                });
            }
            reportData.nonFlowerTotal = nonfFlowerTotals;
            let topicalTotals = reportData.dutyTotals.find(x => x.taxType === 'topical');
            let topicalDuties = dutiesByTypeProv.find(x => x.taxType === 'topical');
            reportData.topicalDuties = topicalDuties != null && topicalDuties.provs != null ? topicalDuties.provs : null;
            if(reportData.topicalDuties != null){
                reportData.topicalDuties.sort(function(a, b){
                    return a.prov.name > b.prov.name ? 1 : -1;
                });
            }
            reportData.topicalTotal = topicalTotals;
        }
        let tabLabels = [];
        if(ctls != null){
            tabLabels.push({name: t('unpackaged'), disabled: false});
            tabLabels.push({name: t('packaged'), disabled: false});
        }
        if(reportData != null){
            tabLabels.push({name: t('craB300'), disabled: false});
            if(reportData.taxLines != null && reportData.taxLines.length > 0){
                tabLabels.push({name: t('exciseLines'), disabled: false});
            }
        }
        let locked = record.data.submitted === true && (this.props.permission.create || this.props.permission.update) ? true : false;
        this.setState({
            _id: record.data._id,
            number: record.data.number,
            license: record.data.license,
            requestor: record.data.requestor,
            requestDate: record.data.requestDate,
            startDate: record.data.startDate,
            endDate: record.data.endDate,
            document: record.data.document,
            licenseSel: licenseSel,
            requestorSel: requestorSel,
            reportData: reportData,
            month: month,
            year: year,
            monthSel: monthSel,
            yearSel: yearSel,
            tabLabels: tabLabels,
            submitted: record.data.submitted,
            submittedBy: record.data.submittedBy,
            locked: locked,
            is2024: is2024,
            ctls: ctls,
            csvData: null,
            headers: null,
            ids: [],
            previous: record.data.previous,
            previouses: record.data.previousReports,
            previousSel: record.data.previous != null ? record.data.previousReports.find(x => x.value === record.data.previous) : null,
            clean: record.data.clean,
            status: record.data.status,
            statusSel: statusSel
        }, () => {
            this.getTaxLines();
        });
    }

    getTaxLines = async () => {
        let result = await axios.get('/api/hcreport/taxlines/' + this.state._id);
        let taxLines = result.data != null && result.data.taxLines != null && result.data.taxLines.length > 0 ? result.data.taxLines.slice(0, this.state.taxListSize) : [];
        let tabLabels = this.state.tabLabels;
        if(result.data.taxLines != null && result.data.taxLines.length > 0){
            tabLabels.push({name: this.props.t('exciseLines'), disabled: false});
        }
        this.setState({taxLines: taxLines, allTaxLines: result.data.taxLines != null ? result.data.taxLines : [], tabLabels: tabLabels});
    }

    changeSelect = (name, data) => (e, value) => {
        this.setState({[name]: value, [data]: value.value}, () => {
            if(data === 'year' || data === 'month')
                this.calculateDate();
        });
    };

    calculateDate(){
        if(this.state.year != null && this.state.year !== '' && this.state.month != null && this.state.month !== ''){
            let year = parseInt(this.state.year);
            let month = parseInt(this.state.month);
            month = month - 1;
            let startDate = new Date(year, month, 1, 10, 0);
            let endDate = new Date(year, month + 1, 0, 10, 0);
            this.setState({startDate: startDate, endDate: endDate});
        }
    }

    dateChange = (name) => value => {
        this.setState({[name]: value});
    }

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        const data = {
            _id: this.state._id,
            license: this.state.license,
            requestor: this.state.requestor,
            requestDate: this.state.requestDate,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            month: this.state.month,
            year: this.state.year,
            previous: this.state.previous
        }
        try {
            let result = await axios.post('/api/hcreport', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/hcreport/' + result.data.id);
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    validate(){
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.license == null || state.license === '')
            errors[t('license')] = t('required');
        if(state.requestor == null || state.requestor === '')
            errors[t('requestor')] = t('required');
        if(state.requestDate == null || state.requestDate === '')
            errors[t('requestDate')] = t('required');
        if(state.startDate == null || state.startDate === '')
            errors[t('startDate')] = t('required');
        if(state.endDate == null || state.endDate === '')
            errors[t('endDate')] = t('required');
        if(state.year == null || state.year === '')
            errors[t('year')] = t('required');
        if(state.month == null || state.year === '')
            errors[t('month')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    markSubmittted = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._markSubmitted());
    }

    _markSubmitted = async () => {
        const t = this.props.t;
        try {
            let result = await axios.post('/api/hcreportsubmit', {_id: this.state._id});
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
            this.loadRecord();
        } catch (err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    markNotSubmittted = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._markNotSubmitted());
    }

    _markNotSubmitted = async () => {
        const t = this.props.t;
        try {
            let result = await axios.post('/api/hcreportnotsubmit', {_id: this.state._id});
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
            this.loadRecord();
        } catch (err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    generate = async (e) => {
        const t = this.props.t;
        if(this.state.clean !== true && (this.state.previous == null || this.state.previous === '')){
            this.setState({errors: {previous: t('required')}});
            return;
        }
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false, errors: {}}, async () => {
            try{
                let result = await axios.post('/api/hcreportrun', {_id: this.state._id, clean: this.state.clean, previous: this.state.previous});
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.setState({enableSave: true});
            } catch(err){
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
                this.setState({enableSave: true});
            }
        });

    }

    changeBool = (name) => (e) => {
        this.setState({[name]: e.target.checked}, () => {
            if(this.state.clean === true){
                this.setState({previous: null, previousSel: null});
            }
        });
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    handleChangePage = (event, newPage) => {
        this.setState({taxListPage: newPage}, () => {
            this.sliceTransactions();
        });
    };

    sliceTransactions(){
        let transactions = this.state.allTaxLines.slice(this.state.taxListSize * this.state.taxListPage, (this.state.taxListPage * this.state.taxListSize) + this.state.taxListSize);
        this.setState({taxLines: transactions})
    }

    handleRowsPerPage = e => {
        this.setState({taxListSize: parseInt(e.target.value)}, () => {
            this.sliceTransactions();
        });
    };

    taxCsvClick = () => {
        const t = this.props.t;
        this.setState({csvData: null});
        let taxLines = this.state.allTaxLines;
        let data = [];
        for(let row of taxLines){
            data.push({
                shipment: row.shipment != null ? row.shipment.number : '',
                sale: row.sale != null ? row.sale.number : '',
                prov: row.prov != null ? row.prov.code : '',
                date: row.date,
                item: row.item.number,
                itemName: row.item.name,
                sku: row.item.sku != null && row.item.sku !== '' ? row.item.sku : row.item.name,
                taxType: row.taxType,
                unitPrice: row.unitPrice,
                quantity: row.quantity,
                units: row.units,
                unitGrams: row.unitGrams,
                unitThcMg: row.unitThcMg,
                totalGrams: row.totalGrams,
                totalThcMg: row.totalThcMg,
                grossValue: row.grossValue,
                adValorem: row.adValorem,
                baseAmount: row.baseAmount,
                regularDuty: row.regularDuty,
                additionalDuty: row.additionalDuty,
                adjustedDuty: row.adjustedDuty,
                totalDuty: row.totalDuty
            });
        }
        let headers = [
            {label: t('shipment'), key: 'shipment'},
            {label: t('sale'), key: 'sale'},
            {label: t('prov'), key: 'prov'},
            {label: t('date'), key: 'date'},
            {label: t('item'), key: 'item'},
            {label: t('itemName'), key: 'itemName'},
            {label: t('sku'), key: 'sku'},
            {label: t('taxType'), key: 'taxType'},
            {label: t('unitPrice'), key: 'unitPrice'},
            {label: t('quantity'), key: 'quantity'},
            {label: t('units'), key: 'units'},
            {label: t('unitGrams'), key: 'unitGrams'},
            {label: t('unitThcMg'), key: 'unitThcMg'},
            {label: t('totalGrams'), key: 'totalGrams'},
            {label: t('totalThcMg'), key: 'totalThcMg'},
            {label: t('cost'), key: 'grossValue'},
            {label: t('adValorem'), key: 'adValorem'},
            {label: t('baseAmount'), key: 'baseAmount'},
            {label: t('duty'), key: 'regularDuty'},
            {label: t('additional'), key: 'additionalDuty'},
            {label: t('adjusted'), key: 'adjustedDuty'},
            {label: t('total'), key: 'totalDuty'}
        ];
        this.setState({csvData: data, headers: headers});
    };

    toggleLedgers = () => {
        this.setState({showLedgers: !this.state.showLedgers, ledgers: [], ids: [], negLedgers: null, negIds: null, csvData: null});
    }

    sectionClick = (section, negSection) => async(e) => {
        if(section != null && section !== '' && section.length > 0){
            let result = await axios.post('/api/itemledgerids',{ids: section});
            let negLedgers = null;
            if(negSection != null){
                let negResult = await axios.post('/api/itemledgerids',{ids: negSection});
                negLedgers = negResult.data.data;
            }
            this.setState({ledgers: result.data.data, showLedgers: true, ids: section, negLedgers: negLedgers, negIds: negSection});
        }   
    }
    ledgersCsv = async (e) => {
        this.setState({csvData: null})
        if(this.state.ids != null && this.state.ids.length > 0){
            let result = await axios.post('/api/itemledgercsvids', {ids: this.state.ids});
            this.setState({csvData: result.data.data, headers: result.data.headers});
        }
    }

    negativeCsv = async (e) => {
        this.setState({csvData: null})
        if(this.state.negIds != null && this.state.negIds.length > 0){
            let result = await axios.post('/api/itemledgercsvids', {ids: this.state.negIds});
            this.setState({csvData: result.data.data, headers: result.data.headers});
        }
    }

    displayValue = (value, decimals, ledgers, negLedgers) => {
        if(((ledgers != null && ledgers.length > 0 )|| (negLedgers != null && negLedgers.length > 0))){
            return <Button size="small" variant="outlined" color="primary" onClick={this.sectionClick(ledgers, negLedgers)}>
                    <NumericFormat value={value} displayType={'text'} thousandSeparator={true} decimalScale={decimals} fixedDecimalScale={true}/>
                </Button>
        } else {
            return <NumericFormat value={value} displayType={'text'} thousandSeparator={true} decimalScale={decimals} fixedDecimalScale={true}/>
        }
    }

    render(){
        const errors = this.props.errors;
        const reportData = this.state.reportData;
        const ctls = this.state.ctls;
        const t = this.props.t;
        const locked = this.state.locked;
        const is2024 = this.state.is2024;
        const cellStyle = {backgroundColor: "#FFFFFF", border: 1};
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <BoxTitle title={t('reportDetails')}/>
                        </Grid>
                        {this.state.number != null && this.state.number !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.statusSel != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('status')}</FormLabel>
                                    {this.state.statusSel.label}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <AutoCompleteField
                                value={this.state.licenseSel}
                                options={this.state.licenses}
                                onChange={this.changeSelect('licenseSel', 'license')}
                                error={errors[t('license')] != null ? true : false}
                                helperText={errors[t('license')]}
                                label={t('license')}
                                required
                                disabled={locked}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AutoCompleteField
                                value={this.state.requestorSel}
                                options={this.state.users}
                                onChange={this.changeSelect('requestorSel', 'requestor')}
                                error={errors[t('requestor')] != null ? true : false}
                                helperText={errors[t('requestor')]}
                                label={t('requestor')}
                                required
                                disabled = {locked}
                            />
                        </Grid>
                        <Grid item xs={4}>
                                <FormControl>
                                    <FormLabel>{t('requestDate')}</FormLabel>
                                    <DateDisplay value={this.state.requestDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <AutoCompleteField
                                value={this.state.yearSel}
                                options={this.state.years}
                                onChange={this.changeSelect('yearSel', 'year')}
                                error={errors[t('year')] != null ? true : false}
                                helperText={errors[t('year')]}
                                label={t('year')}
                                required
                                disabled={locked}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AutoCompleteField
                                value={this.state.monthSel}
                                options={this.state.months}
                                onChange={this.changeSelect('monthSel', 'month')}
                                error={errors[t('month')] != null ? true : false}
                                helperText={errors[t('month')]}
                                label={t('month')}
                                required
                                disabled={locked}
                            />
                        </Grid>
                        {this.state.startDate != null && this.state.startDate !== '' &&
                            <Grid item xs={6}>
                                <FormControl>
                                    <FormLabel>{t('startDate')}</FormLabel>
                                    <DateDisplay value={this.state.startDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.endDate != null && this.state.endDate !== '' &&
                            <Grid item xs={6}>
                                <FormControl>
                                    <FormLabel>{t('endDate')}</FormLabel>
                                    <DateDisplay value={this.state.endDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.submitted === true &&
                            <Grid item xs={6}>
                                <FormControl>
                                    <FormLabel>{t('submitted')}</FormLabel>
                                    {t('yes')}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.submittedBy != null &&
                            <Grid item xs={6}>
                                <FormControl>
                                    <FormLabel>{t('submittedBy')}</FormLabel>
                                    {this.state.submittedBy != null ? this.state.submittedBy.firstName + ' ' + this.state.submittedBy.lastName : ''}
                                </FormControl>
                            </Grid>
                        }
                        {this.state._id != null && this.state._id !== '' &&
                            <Grid item xs={5}>
                                <FormControlLabel
                                    disabled={locked}
                                    control={<Switch checked={this.state.clean} onChange={this.changeBool('clean')} name="clean" />}
                                    label={t('cleanReport')} 
                                />
                            </Grid>
                        }
                        {this.state._id != null && this.state._id !== '' && this.state.previouses != null && this.state.previouses.length > 0 && this.state.clean !== true &&
                            <Grid item xs={6} sm={4}>
                                <AutoCompleteField
                                    value={this.state.previousSel}
                                    options={this.state.previouses}
                                    onChange={this.changeSelect('previousSel', 'previous')}
                                    error={errors[t('previous')] != null ? true : false}
                                    helperText={errors[t('previous')]}
                                    label={t('previous')}
                                    disabled={locked}
                                />
                            </Grid>
                         }
                        <Grid item xs={12}>
                            <Button disabled={!this.state.enableSave || locked} variant="contained" color="primary" size="small" onClick={this.save}>{t('save')}</Button>&nbsp;&nbsp;
                            {this.state._id != null && this.state._id !== '' &&
                                <Aux>
                                    <Button variant="contained" color="secondary" size="small" disabled={!this.state.enableSave || locked} onClick={this.generate}>{t('generateReport')}</Button>
                                    &nbsp;&nbsp;
                                </Aux>
                            }
                            {!locked && this.props.permission != null && this.props.permission.complete && !this.state.submitted && reportData != null &&
                                <Button variant="contained" color="secondary" size="small" disabled={!this.state.enableSave} 
                                onClick={() => this.markSubmittted()}>{t('markSubmitted')}</Button>
                            }
                            {locked && this.props.permission != null &&  this.props.permission.complete && this.state.submitted &&
                                <Button variant="contained" color="secondary" size="small" disabled={!this.state.enableSave} 
                                onClick={() => this.markNotSubmittted()}>{t('markNotSubmitted')}</Button>
                            }
                        </Grid>
                    </Grid>
                    {this.state.document != null && this.state.enableSave &&
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={5}>
                                <Panel>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <BoxTitle title={t('attachments')} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DocumentViewer document={this.state.document} final={false}/>
                                        </Grid>
                                    </Grid>
                                </Panel>
                            </Grid>
                        </Grid>
                    }
                </Panel>
                {(reportData != null && this.state.enableSave) &&
                    <Aux>
                    <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                    {ctls != null && 
                        <Aux>
                            <TabPanel key={0} value={this.state.tabKey} index={0}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="Unpackaged" size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('seeds')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('veg')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('wholePlants')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('fresh')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('dried')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('hemp')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('pureIntermediaries')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('solids')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('nonSolids')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('inhaled')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('ingested')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('otherExtract')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('topical')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('other')}</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('openingInventory')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.opening.seeds} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.opening.vegPlant} displayType={'text'} thousandSeparator={true} decimalScale={0} />
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.opening.wholePlant} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.opening.fresh} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.opening.dried} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.opening.hemp} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.opening.pureIntermediaries} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.opening.solid} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.opening.nonSolid} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.opening.inhaled} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.opening.ingest} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.opening.otherExtract} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.opening.topical} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.opening.other} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={15} sx={cellStyle}><strong>{t('additionsInventory')}</strong></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('produced')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.produced.seeds, 3, ctls.unpackaged.additions.produced.seedLedgers, ctls.unpackaged.additions.produced.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.produced.vegPlant, 0, ctls.unpackaged.additions.produced.vegLedgers, ctls.unpackaged.additions.produced.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.produced.wholePlant, 0, ctls.unpackaged.additions.produced.wpLedgers, ctls.unpackaged.additions.produced.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.produced.fresh, 3, ctls.unpackaged.additions.produced.freshLedgers, ctls.unpackaged.additions.produced.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.produced.dried, 3, ctls.unpackaged.additions.produced.driedLedgers, ctls.unpackaged.additions.produced.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.produced.pureIntermediaries, 3, ctls.unpackaged.additions.produced.pureLedgers, ctls.unpackaged.additions.produced.pureLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.produced.solid, 3, ctls.unpackaged.additions.produced.solidLedgers, ctls.unpackaged.additions.produced.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.produced.nonSolid, 3, ctls.unpackaged.additions.produced.nonLedgers, ctls.unpackaged.additions.produced.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.produced.inhaled, 3, ctls.unpackaged.additions.produced.inhaledLedgers, ctls.unpackaged.additions.produced.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.produced.ingest, 3, ctls.unpackaged.additions.produced.ingestLedgers, ctls.unpackaged.additions.produced.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.produced.otherExtract, 3, ctls.unpackaged.additions.produced.extractLedgers, ctls.unpackaged.additions.produced.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.produced.topical, 3, ctls.unpackaged.additions.produced.topicalLedgers, ctls.unpackaged.additions.produced.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.produced.other, 3, ctls.unpackaged.additions.produced.otherLedgers, ctls.unpackaged.additions.produced.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('received')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.received.seeds, 3, ctls.unpackaged.additions.received.seedLedgers, ctls.unpackaged.additions.received.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.received.vegPlant, 0, ctls.unpackaged.additions.received.vegLedgers, ctls.unpackaged.additions.received.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.received.wholePlant, 0, ctls.unpackaged.additions.received.wpLedgers, ctls.unpackaged.additions.received.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.received.fresh, 3, ctls.unpackaged.additions.received.freshLedgers, ctls.unpackaged.additions.received.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.received.dried, 3, ctls.unpackaged.additions.received.driedLedgers, ctls.unpackaged.additions.received.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.received.hemp, 3, ctls.unpackaged.additions.received.hempLedgers, ctls.unpackaged.additions.received.hempLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.received.pureIntermediaries, 3, ctls.unpackaged.additions.received.pureLedgers, ctls.unpackaged.additions.received.pureLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.received.solid, 3, ctls.unpackaged.additions.received.solidLedgers, ctls.unpackaged.additions.received.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.received.nonSolid, 3, ctls.unpackaged.additions.received.nonLedgers, ctls.unpackaged.additions.received.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.received.inhaled, 3, ctls.unpackaged.additions.received.inhaledLedgers, ctls.unpackaged.additions.received.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.received.ingest, 3, ctls.unpackaged.additions.received.ingestLedgers, ctls.unpackaged.additions.received.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.received.otherExtract, 3, ctls.unpackaged.additions.received.extractLedgers, ctls.unpackaged.additions.received.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.received.topical, 3, ctls.unpackaged.additions.received.topicalLedgers, ctls.unpackaged.additions.received.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.received.other, 3, ctls.unpackaged.additions.received.otherLedgers, ctls.unpackaged.additions.received.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('imported')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.imported.seeds, 3, ctls.unpackaged.additions.imported.seedLedgers, ctls.unpackaged.additions.imported.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.imported.vegPlant, 0, ctls.unpackaged.additions.imported.vegLedgers, ctls.unpackaged.additions.imported.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.imported.wholePlant, 0, ctls.unpackaged.additions.imported.wpLedgers, ctls.unpackaged.additions.imported.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.imported.fresh, 3, ctls.unpackaged.additions.imported.freshLedgers, ctls.unpackaged.additions.imported.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.imported.dried, 3, ctls.unpackaged.additions.imported.driedLedgers, ctls.unpackaged.additions.imported.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.imported.hemp, 3, ctls.unpackaged.additions.imported.hempLedgers, ctls.unpackaged.additions.imported.hempLedgers)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.imported.pureIntermediaries, 3, ctls.unpackaged.additions.imported.pureLedgers, ctls.unpackaged.additions.imported.pureLedgersNeg)} 
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.imported.solid, 3, ctls.unpackaged.additions.imported.solidLedgers, ctls.unpackaged.additions.imported.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.imported.nonSolid, 3, ctls.unpackaged.additions.imported.nonLedgers, ctls.unpackaged.additions.imported.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.imported.inhaled, 3, ctls.unpackaged.additions.imported.inhaledLedgers, ctls.unpackaged.additions.imported.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.imported.ingest, 3, ctls.unpackaged.additions.imported.ingestLedgers, ctls.unpackaged.additions.imported.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.imported.otherExtract, 3, ctls.unpackaged.additions.imported.extractLedgers, ctls.unpackaged.additions.imported.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.imported.topical, 3, ctls.unpackaged.additions.imported.topicalLedgers, ctls.unpackaged.additions.imported.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.imported.other, 3, ctls.unpackaged.additions.imported.otherLedgers, ctls.unpackaged.additions.imported.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('returns')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.return.seeds, 3, ctls.unpackaged.additions.return.seedLedgers, ctls.unpackaged.additions.return.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.return.vegPlant, 0, ctls.unpackaged.additions.return.vegLedgers, ctls.unpackaged.additions.return.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.return.wholePlant, 0, ctls.unpackaged.additions.return.wpLedgers, ctls.unpackaged.additions.return.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.return.fresh, 3, ctls.unpackaged.additions.return.freshLedgers, ctls.unpackaged.additions.return.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.return.dried, 3, ctls.unpackaged.additions.return.driedLedgers, ctls.unpackaged.additions.return.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.return.pureIntermediaries, 3, ctls.unpackaged.additions.return.pureLedgers, ctls.unpackaged.additions.return.pureLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.return.solid, 3, ctls.unpackaged.additions.return.solidLedgers, ctls.unpackaged.additions.return.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.return.nonSolid, 3, ctls.unpackaged.additions.return.nonLedgers, ctls.unpackaged.additions.return.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.return.inhaled, 3, ctls.unpackaged.additions.return.inhaledLedgers, ctls.unpackaged.additions.return.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.return.ingest, 3, ctls.unpackaged.additions.return.ingestLedgers, ctls.unpackaged.additions.return.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.return.otherExtract, 3, ctls.unpackaged.additions.return.extractLedgers, ctls.unpackaged.additions.return.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.return.topical, 3, ctls.unpackaged.additions.return.topicalLedgers, ctls.unpackaged.additions.return.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.return.other, 3, ctls.unpackaged.additions.return.otherLedgers, ctls.unpackaged.additions.return.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('other')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.other.seeds, 3, ctls.unpackaged.additions.other.seedLedgers, ctls.unpackaged.additions.other.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.other.vegPlant, 0, ctls.unpackaged.additions.other.vegLedgers, ctls.unpackaged.additions.other.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.other.wholePlant, 0, ctls.unpackaged.additions.other.wpLedgers, ctls.unpackaged.additions.other.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.other.fresh, 3, ctls.unpackaged.additions.other.freshLedgers, ctls.unpackaged.additions.other.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.other.dried, 3, ctls.unpackaged.additions.other.driedLedgers, ctls.unpackaged.additions.other.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.other.hemp, 3, ctls.unpackaged.additions.other.hempLedgers, ctls.unpackaged.additions.other.hempLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.other.pureIntermediaries, 3, ctls.unpackaged.additions.other.pureLedgers, ctls.unpackaged.additions.other.pureLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.other.solid, 3, ctls.unpackaged.additions.other.solidLedgers, ctls.unpackaged.additions.other.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.other.nonSolid, 3, ctls.unpackaged.additions.other.nonLedgers, ctls.unpackaged.additions.other.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.other.inhaled, 3, ctls.unpackaged.additions.other.inhaledLedgers, ctls.unpackaged.additions.other.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.other.ingest, 3, ctls.unpackaged.additions.other.ingestLedgers, ctls.unpackaged.additions.other.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.other.otherExtract, 3, ctls.unpackaged.additions.other.extractLedgers, ctls.unpackaged.additions.other.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.other.topical, 3, ctls.unpackaged.additions.other.topicalLedgers, ctls.unpackaged.additions.other.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.additions.other.other, 3, ctls.unpackaged.additions.other.otherLedgers, ctls.unpackaged.additions.other.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('total')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.additions.total.seeds} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.additions.total.vegPlant} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.additions.total.wholePlant} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.additions.total.fresh} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.additions.total.dried} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.additions.total.hemp} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.additions.total.pureIntermediaries} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.additions.total.solid} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.additions.total.nonSolid} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.additions.total.inhaled} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.additions.total.ingest} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.additions.total.otherExtract} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.additions.total.topical} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.additions.total.other} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={15} sx={cellStyle}><strong>{t('reductionsInventory')}</strong></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('quantityProcessed')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processed.seeds, 3, ctls.unpackaged.reductions.processed.seedLedgers, ctls.unpackaged.reductions.processed.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processed.vegPlant, 0, ctls.unpackaged.reductions.processed.vegLedgers, ctls.unpackaged.reductions.processed.vegLedgersNeg)}   
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processed.wholePlant, 0, ctls.unpackaged.reductions.processed.wpLedgers, ctls.unpackaged.reductions.processed.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processed.fresh, 3, ctls.unpackaged.reductions.processed.freshLedgers, ctls.unpackaged.reductions.processed.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processed.dried, 3, ctls.unpackaged.reductions.processed.driedLedgers, ctls.unpackaged.reductions.processed.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processed.hemp, 3, ctls.unpackaged.reductions.processed.hempLedgers, ctls.unpackaged.reductions.processed.hempLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processed.other, 3, ctls.unpackaged.reductions.processed.otherLedgers, ctls.unpackaged.reductions.processed.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('intoSolids')}</TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processed.pureSolid, 3, ctls.unpackaged.reductions.processed.pureSolidLedgers, ctls.unpackaged.reductions.processed.pureSolidLedgersNeg)}
                                                </TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('intoNonSolids')}</TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processed.pureNonSolid, 3, ctls.unpackaged.reductions.processed.pureNonLedgers, ctls.unpackaged.reductions.processed.pureNonLedgersNeg)}
                                                </TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('intoIngest')}</TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processed.pureIngest, 3, ctls.unpackaged.reductions.processed.pureIngestLedgers, ctls.unpackaged.reductions.processed.pureIngestLedgersNeg)}
                                                </TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('intoInhaled')}</TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processed.pureInhaled, 3, ctls.unpackaged.reductions.processed.pureInhaledLedgers, ctls.unpackaged.reductions.processed.pureInhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('intoOtherExtract')}</TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processed.pureExtract, 3, ctls.unpackaged.reductions.processed.pureExtractLedgers, ctls.unpackaged.reductions.processed.pureExtractLedgersNeg)}
                                                </TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('intoTopical')}</TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processed.pureTopical, 3, ctls.unpackaged.reductions.processed.pureTopicalLedgers, ctls.unpackaged.reductions.processed.pureTopicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('packaged')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.packaged.seeds, 3, ctls.unpackaged.reductions.packaged.seedLedgers, ctls.unpackaged.reductions.packaged.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.packaged.vegPlant, 0, ctls.unpackaged.reductions.packaged.vegLedgers, ctls.unpackaged.reductions.packaged.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.packaged.wholePlant, 0, ctls.unpackaged.reductions.packaged.wpLedgers, ctls.unpackaged.reductions.packaged.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.packaged.fresh, 3, ctls.unpackaged.reductions.packaged.freshLedgers, ctls.unpackaged.reductions.packaged.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.packaged.dried, 3, ctls.unpackaged.reductions.packaged.driedLedgers, ctls.unpackaged.reductions.packaged.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.packaged.hemp, 3, ctls.unpackaged.reductions.packaged.hempLedgers, ctls.unpackaged.reductions.packaged.hempLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}></TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.packaged.solid, 3, ctls.unpackaged.reductions.packaged.solidLedgers, ctls.unpackaged.reductions.packaged.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.packaged.nonSolid, 3, ctls.unpackaged.reductions.packaged.nonLedgers, ctls.unpackaged.reductions.packaged.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.packaged.inhaled, 3, ctls.unpackaged.reductions.packaged.inhaledLedgers, ctls.unpackaged.reductions.packaged.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.packaged.ingest, 3, ctls.unpackaged.reductions.packaged.ingestLedgers, ctls.unpackaged.reductions.packaged.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.packaged.otherExtract, 3, ctls.unpackaged.reductions.packaged.extractLedgers, ctls.unpackaged.reductions.packaged.extractLedgers)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.packaged.topical, 3, ctls.unpackaged.reductions.packaged.topicalLedgers, ctls.unpackaged.reductions.packaged.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.packaged.other, 3, ctls.unpackaged.reductions.packaged.otherLedgers, ctls.unpackaged.reductions.packaged.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('test')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.test.seeds, 3, ctls.unpackaged.reductions.test.seedLedgers, ctls.unpackaged.reductions.test.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.test.vegPlant, 0, ctls.unpackaged.reductions.test.vegLedgers, ctls.unpackaged.reductions.test.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.test.wholePlant, 0, ctls.unpackaged.reductions.test.wpLedgers, ctls.unpackaged.reductions.test.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.test.fresh, 3, ctls.unpackaged.reductions.test.freshLedgers, ctls.unpackaged.reductions.test.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.test.dried, 3, ctls.unpackaged.reductions.test.driedLedgers, ctls.unpackaged.reductions.test.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.test.hemp, 3, ctls.unpackaged.reductions.test.hempLedgers, ctls.unpackaged.reductions.test.hempLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.test.pureIntermediaries, 3, ctls.unpackaged.reductions.test.pureLedgers, ctls.unpackaged.reductions.test.pureLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.test.solid, 3, ctls.unpackaged.reductions.test.solidLedgers, ctls.unpackaged.reductions.test.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.test.nonSolid, 3, ctls.unpackaged.reductions.test.nonLedgers, ctls.unpackaged.reductions.test.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.test.inhaled, 3, ctls.unpackaged.reductions.test.inhaledLedgers, ctls.unpackaged.reductions.test.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.test.ingest, 3, ctls.unpackaged.reductions.test.ingestLedgers, ctls.unpackaged.reductions.test.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.test.otherExtract, 3, ctls.unpackaged.reductions.test.extractLedgers, ctls.unpackaged.reductions.test.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.test.topical, 3, ctls.unpackaged.reductions.test.topicalLedgers, ctls.unpackaged.reductions.test.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.test.other, 3, ctls.unpackaged.reductions.test.otherLedgers, ctls.unpackaged.reductions.test.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('research')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.research.seeds, 3, ctls.unpackaged.reductions.research.seedLedgers, ctls.unpackaged.reductions.research.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.research.vegPlant, 0, ctls.unpackaged.reductions.research.vegLedgers, ctls.unpackaged.reductions.research.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.research.wholePlant, 0, ctls.unpackaged.reductions.research.wpLedgers, ctls.unpackaged.reductions.research.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.research.fresh, 3, ctls.unpackaged.reductions.research.freshLedgers, ctls.unpackaged.reductions.research.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.research.dried, 3, ctls.unpackaged.reductions.research.driedLedgers, ctls.unpackaged.reductions.research.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.research.hemp, 3, ctls.unpackaged.reductions.research.hempLedgers, ctls.unpackaged.reductions.research.hempLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.research.pureIntermediaries, 3, ctls.unpackaged.reductions.research.pureLedgers, ctls.unpackaged.reductions.research.pureLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.research.solid, 3, ctls.unpackaged.reductions.research.solidLedgers, ctls.unpackaged.reductions.research.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.research.nonSolid, 3, ctls.unpackaged.reductions.research.nonLedgers, ctls.unpackaged.reductions.research.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.research.inhaled, 3, ctls.unpackaged.reductions.research.inhaledLedgers, ctls.unpackaged.reductions.research.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.research.ingest, 3, ctls.unpackaged.reductions.research.ingestLedgers, ctls.unpackaged.reductions.research.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.research.otherExtract, 3, ctls.unpackaged.reductions.research.extractLedgers, ctls.unpackaged.reductions.research.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.research.topical, 3, ctls.unpackaged.reductions.research.topicalLedgers, ctls.unpackaged.reductions.research.topicalLedgersNeg)}
                    
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.research.other, 3, ctls.unpackaged.reductions.research.otherLedgers, ctls.unpackaged.reductions.research.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('intraIndustry')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                   {this.displayValue(ctls.unpackaged.reductions.shipped.seeds, 3, ctls.unpackaged.reductions.shipped.seedLedgers, ctls.unpackaged.reductions.shipped.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.shipped.vegPlant, 0, ctls.unpackaged.reductions.shipped.vegLedgers, ctls.unpackaged.reductions.shipped.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.shipped.wholePlant, 0, ctls.unpackaged.reductions.shipped.wpLedgers, ctls.unpackaged.reductions.shipped.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.shipped.fresh, 3, ctls.unpackaged.reductions.shipped.freshLedgers, ctls.unpackaged.reductions.shipped.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.shipped.dried, 3, ctls.unpackaged.reductions.shipped.driedLedgers, ctls.unpackaged.reductions.shipped.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.shipped.hemp, 3, ctls.unpackaged.reductions.shipped.hempLedgers, ctls.unpackaged.reductions.shipped.hempLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.shipped.pureIntermediaries, 3, ctls.unpackaged.reductions.shipped.pureLedgers, ctls.unpackaged.reductions.shipped.pureLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.shipped.solid, 3, ctls.unpackaged.reductions.shipped.solidLedgers, ctls.unpackaged.reductions.shipped.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.shipped.nonSolid, 3, ctls.unpackaged.reductions.shipped.nonLedgers, ctls.unpackaged.reductions.shipped.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.shipped.inhaled, 3, ctls.unpackaged.reductions.shipped.inhaledLedgers, ctls.unpackaged.reductions.shipped.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.shipped.ingest, 3, ctls.unpackaged.reductions.shipped.ingestLedgers, ctls.unpackaged.reductions.shipped.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.shipped.otherExtract, 3, ctls.unpackaged.reductions.shipped.extractLedgers, ctls.unpackaged.reductions.shipped.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.shipped.topical, 3, ctls.unpackaged.reductions.shipped.topicalLedgers, ctls.unpackaged.reductions.shipped.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.shipped.seeds, 3, ctls.unpackaged.reductions.shipped.seedLedgers, ctls.unpackaged.reductions.shipped.seedLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('exported')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.exported.seeds, 3, ctls.unpackaged.reductions.exported.seedLedgers, ctls.unpackaged.reductions.exported.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.exported.vegPlant, 0, ctls.unpackaged.reductions.exported.vegLedgers, ctls.unpackaged.reductions.exported.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.exported.wholePlant, 0, ctls.unpackaged.reductions.exported.wpLedgers, ctls.unpackaged.reductions.exported.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.exported.fresh, 3, ctls.unpackaged.reductions.exported.freshLedgers, ctls.unpackaged.reductions.exported.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.exported.dried, 3, ctls.unpackaged.reductions.exported.driedLedgers, ctls.unpackaged.reductions.exported.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.exported.hemp, 3, ctls.unpackaged.reductions.exported.hempLedgers, ctls.unpackaged.reductions.exported.hempLedgersNeg)} 
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.exported.pureIntermediaries, 3, ctls.unpackaged.reductions.exported.pureLedgers, ctls.unpackaged.reductions.exported.pureLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.exported.solid, 3, ctls.unpackaged.reductions.exported.solidLedgers, ctls.unpackaged.reductions.exported.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.exported.nonSolid, 3, ctls.unpackaged.reductions.exported.nonLedgers, ctls.unpackaged.reductions.exported.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.exported.inhaled, 3, ctls.unpackaged.reductions.exported.inhaledLedgers, ctls.unpackaged.reductions.exported.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.exported.ingest, 3, ctls.unpackaged.reductions.exported.ingestLedgers, ctls.unpackaged.reductions.exported.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.exported.otherExtract, 3, ctls.unpackaged.reductions.exported.extractLedgers, ctls.unpackaged.reductions.exported.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.exported.topical, 3, ctls.unpackaged.reductions.exported.topicalLedgers, ctls.unpackaged.reductions.exported.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.exported.other, 3, ctls.unpackaged.reductions.exported.otherLedgers, ctls.unpackaged.reductions.exported.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('returned')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.returned.seeds, 3, ctls.unpackaged.reductions.returned.seedLedgers, ctls.unpackaged.reductions.returned.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.returned.vegPlant, 0, ctls.unpackaged.reductions.returned.vegLedgers, ctls.unpackaged.reductions.returned.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.returned.wholePlant, 0, ctls.unpackaged.reductions.returned.wpLedgers, ctls.unpackaged.reductions.returned.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.returned.fresh, 3, ctls.unpackaged.reductions.returned.freshLedgers, ctls.unpackaged.reductions.returned.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.returned.dried, 3, ctls.unpackaged.reductions.returned.driedLedgers, ctls.unpackaged.reductions.returned.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.returned.hemp, 3, ctls.unpackaged.reductions.returned.hempLedgers, ctls.unpackaged.reductions.returned.hempLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.returned.pureIntermediaries, 3, ctls.unpackaged.reductions.returned.pureLedgers, ctls.unpackaged.reductions.returned.pureLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.returned.solid, 3, ctls.unpackaged.reductions.returned.solidLedgers, ctls.unpackaged.reductions.returned.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.returned.nonSolid, 3, ctls.unpackaged.reductions.returned.nonLedgers, ctls.unpackaged.reductions.returned.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.returned.inhaled, 3, ctls.unpackaged.reductions.returned.inhaledLedgers, ctls.unpackaged.reductions.returned.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.returned.ingest, 3, ctls.unpackaged.reductions.returned.ingestLedgers, ctls.unpackaged.reductions.returned.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.returned.otherExtract, 3, ctls.unpackaged.reductions.returned.extractLedgers, ctls.unpackaged.reductions.returned.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.returned.topical, 3, ctls.unpackaged.reductions.returned.topicalLedgers, ctls.unpackaged.reductions.returned.topicalLedgers)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.returned.other, 3, ctls.unpackaged.reductions.returned.otherLedgersNeg, ctls.unpackaged.reductions.returned.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('processingLoss')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processingLoss.vegPlant, 0, ctls.unpackaged.reductions.processingLoss.vegLedgers, ctls.unpackaged.reductions.processingLoss.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processingLoss.wholePlant, 0, ctls.unpackaged.reductions.processingLoss.wholeLedgers, ctls.unpackaged.reductions.processingLoss.wholeLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processingLoss.fresh, 3, ctls.unpackaged.reductions.processingLoss.freshLedgers, ctls.unpackaged.reductions.processingLoss.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processingLoss.dried, 3, ctls.unpackaged.reductions.processingLoss.driedLedgers, ctls.unpackaged.reductions.processingLoss.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processingLoss.hemp, 3, ctls.unpackaged.reductions.processingLoss.hempLedgers, ctls.unpackaged.reductions.processingLoss.hempLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processingLoss.pureIntermediaries, 3, ctls.unpackaged.reductions.processingLoss.pureLedgers, ctls.unpackaged.reductions.processingLoss.pureLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processingLoss.solid, 3, ctls.unpackaged.reductions.processingLoss.solidLedgers, ctls.unpackaged.reductions.processingLoss.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processingLoss.nonSolid, 3, ctls.unpackaged.reductions.processingLoss.nonLedgers, ctls.unpackaged.reductions.processingLoss.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processingLoss.inhaled, 3, ctls.unpackaged.reductions.processingLoss.inhaledLedgers, ctls.unpackaged.reductions.processingLoss.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processingLoss.seeds, 3, ctls.unpackaged.reductions.processingLoss.seedLedgers, ctls.unpackaged.reductions.processingLoss.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processingLoss.otherExtract, 3, ctls.unpackaged.reductions.processingLoss.extractLedgers, ctls.unpackaged.reductions.processingLoss.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processingLoss.topical, 3, ctls.unpackaged.reductions.processingLoss.topicalLedgers, ctls.unpackaged.reductions.processingLoss.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.processingLoss.other, 3, ctls.unpackaged.reductions.processingLoss.otherLedgers, ctls.unpackaged.reductions.processingLoss.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('destroyed')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.destroyed.seeds, 3, ctls.unpackaged.reductions.destroyed.seedLedgers, ctls.unpackaged.reductions.destroyed.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.destroyed.vegPlant, 0, ctls.unpackaged.reductions.destroyed.vegLedgers, ctls.unpackaged.reductions.destroyed.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.destroyed.wholePlant, 0, ctls.unpackaged.reductions.destroyed.wpLedgers, ctls.unpackaged.reductions.destroyed.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.destroyed.fresh, 3, ctls.unpackaged.reductions.destroyed.freshLedgers, ctls.unpackaged.reductions.destroyed.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.destroyed.dried, 3, ctls.unpackaged.reductions.destroyed.driedLedgers, ctls.unpackaged.reductions.destroyed.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.destroyed.hemp, 3, ctls.unpackaged.reductions.destroyed.hempLedgers, ctls.unpackaged.reductions.destroyed.hempLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.destroyed.pureIntermediaries, 3, ctls.unpackaged.reductions.destroyed.pureLedgers, ctls.unpackaged.reductions.destroyed.pureLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.destroyed.solid, 3, ctls.unpackaged.reductions.destroyed.solidLedgers, ctls.unpackaged.reductions.destroyed.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.destroyed.nonSolid, 3, ctls.unpackaged.reductions.destroyed.nonLedgers, ctls.unpackaged.reductions.destroyed.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.destroyed.inhaled, 3, ctls.unpackaged.reductions.destroyed.inhaledLedgers, ctls.unpackaged.reductions.destroyed.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.destroyed.ingest, 3, ctls.unpackaged.reductions.destroyed.ingestLedgers, ctls.unpackaged.reductions.destroyed.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.destroyed.otherExtract, 3, ctls.unpackaged.reductions.destroyed.extractLedgers, ctls.unpackaged.reductions.destroyed.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.destroyed.topical, 3, ctls.unpackaged.reductions.destroyed.topicalLedgers, ctls.unpackaged.reductions.destroyed.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.destroyed.other, 3, ctls.unpackaged.reductions.destroyed.otherLedgers, ctls.unpackaged.reductions.destroyed.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('lost')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.lost.seeds, 3, ctls.unpackaged.reductions.lost.seedLedgers, ctls.unpackaged.reductions.lost.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.lost.vegPlant, 0, ctls.unpackaged.reductions.lost.vegLedgers, ctls.unpackaged.reductions.lost.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.lost.wholePlant, 0, ctls.unpackaged.reductions.lost.wpLedgers, ctls.unpackaged.reductions.lost.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.lost.fresh, 3, ctls.unpackaged.reductions.lost.freshLedgers, ctls.unpackaged.reductions.lost.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.lost.dried, 3, ctls.unpackaged.reductions.lost.driedLedgers, ctls.unpackaged.reductions.lost.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.lost.hemp, 3, ctls.unpackaged.reductions.lost.hempLedgers, ctls.unpackaged.reductions.lost.hempLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.lost.pureIntermediaries, 3, ctls.unpackaged.reductions.lost.pureLedgers, ctls.unpackaged.reductions.lost.pureLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.lost.solid, 3, ctls.unpackaged.reductions.lost.solidLedgers, ctls.unpackaged.reductions.lost.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.lost.nonSolid, 3, ctls.unpackaged.reductions.lost.nonLedgers, ctls.unpackaged.reductions.lost.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.lost.inhaled, 3, ctls.unpackaged.reductions.lost.inhaledLedgers, ctls.unpackaged.reductions.lost.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.lost.ingest, 3, ctls.unpackaged.reductions.lost.ingestLedgers, ctls.unpackaged.reductions.lost.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.lost.otherExtract, 3, ctls.unpackaged.reductions.lost.extractLedgers, ctls.unpackaged.reductions.lost.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.lost.topical, 3, ctls.unpackaged.reductions.lost.topicalLedgers, ctls.unpackaged.reductions.lost.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.lost.other, 3, ctls.unpackaged.reductions.lost.otherLedgers, ctls.unpackaged.reductions.lost.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('other')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.other.seeds, 3, ctls.unpackaged.reductions.other.seedLedgers, ctls.unpackaged.reductions.other.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.other.vegPlant, 0, ctls.unpackaged.reductions.other.vegLedgers, ctls.unpackaged.reductions.other.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.other.wholePlant, 0, ctls.unpackaged.reductions.other.wpLedgers, ctls.unpackaged.reductions.other.wpLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.other.fresh, 3, ctls.unpackaged.reductions.other.freshLedgers, ctls.unpackaged.reductions.other.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.other.dried, 3, ctls.unpackaged.reductions.other.driedLedgers, ctls.unpackaged.reductions.other.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.other.hemp, 3, ctls.unpackaged.reductions.other.hempLedgers, ctls.unpackaged.reductions.other.hempLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.other.pureIntermediaries, 3, ctls.unpackaged.reductions.other.pureLedgers, ctls.unpackaged.reductions.other.pureLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.other.solid, 3, ctls.unpackaged.reductions.other.solidLedgers, ctls.unpackaged.reductions.other.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.other.nonSolid, 3, ctls.unpackaged.reductions.other.nonLedgers, ctls.unpackaged.reductions.other.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.other.inhaled, 3, ctls.unpackaged.reductions.other.inhaledLedgers, ctls.unpackaged.reductions.other.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.other.ingest, 3, ctls.unpackaged.reductions.other.ingestLedgers, ctls.unpackaged.reductions.other.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.other.otherExtract, 3, ctls.unpackaged.reductions.other.extractLedgers, ctls.unpackaged.reductions.other.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.other.topical, 3, ctls.unpackaged.reductions.other.topicalLedgers, ctls.unpackaged.reductions.other.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.unpackaged.reductions.other.other, 3, ctls.unpackaged.reductions.other.otherLedgers, ctls.unpackaged.reductions.other.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('total')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.reductions.total.seeds} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.reductions.total.vegPlant} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.reductions.total.wholePlant} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.reductions.total.fresh} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.reductions.total.dried} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.reductions.total.hemp} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.reductions.total.pureIntermediaries} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.reductions.total.solid} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.reductions.total.nonSolid} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.reductions.total.inhaled} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.reductions.total.ingest} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.reductions.total.otherExtract} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.reductions.total.topical} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.reductions.total.other} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={15} sx={cellStyle}><strong>{t('closing')}</strong></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('inventory')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closing.seeds} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closing.vegPlant} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closing.wholePlant} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closing.fresh} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closing.dried} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closing.hemp} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closing.pureIntermediaries} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closing.solid} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closing.nonSolid} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closing.inhaled} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closing.ingest} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closing.otherExtract} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closing.topical} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closing.other} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('value')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closingValue.seeds} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closingValue.vegPlant} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closingValue.wholePlant} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closingValue.fresh} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closingValue.dried} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closingValue.hemp} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closingValue.pureIntermediaries} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closingValue.solid} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closingValue.nonSolid} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closingValue.inhaled} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closingValue.ingest} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closingValue.otherExtract} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closingValue.topical} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.closingValue.other} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('check')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.check.seeds} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.check.vegPlant} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.check.wholePlant} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.check.fresh} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.check.dried} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.check.hemp} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.check.pureIntermediaries} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.check.solid} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.check.nonSolid} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.check.inhaled} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.check.ingest} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.check.otherExtract} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.check.topical} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.unpackaged.check.other} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                            <TabPanel key={1} value={this.state.tabKey} index={1}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="packaged" size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('seeds')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('veg')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('fresh')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('dried')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('solids')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('nonSolids')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('inhaled')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('ingest')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('otherExtract')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('topical')}</strong></TableCell>
                                                <TableCell sx={cellStyle}><strong>{t('other')}</strong></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('openingInventory')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.opening.seeds} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.opening.vegPlant} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.opening.fresh} displayType={'text'} thousandSeparator={true} decimalScale={3} />
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.opening.dried} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.opening.solid} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.opening.nonSolid} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.opening.inhaled} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.opening.ingest} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.opening.otherExtract} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.opening.topical} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.opening.other} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="12" sx={cellStyle}><strong>{t('additionsInventory')}</strong></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('packaged')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.packaged.seeds, 0, ctls.packaged.additions.packaged.seedLedgers, ctls.packaged.additions.packaged.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.packaged.vegPlant, 0, ctls.packaged.additions.packaged.vegLedgers, ctls.packaged.additions.packaged.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.packaged.fresh, 0, ctls.packaged.additions.packaged.freshLedgers, ctls.packaged.additions.packaged.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.packaged.dried, 0, ctls.packaged.additions.packaged.driedLedgers, ctls.packaged.additions.packaged.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.packaged.solid, 0, ctls.packaged.additions.packaged.solidLedgers, ctls.packaged.additions.packaged.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.packaged.nonSolid, 0, ctls.packaged.additions.packaged.nonLedgers, ctls.packaged.additions.packaged.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.packaged.inhaled, 0, ctls.packaged.additions.packaged.inhaledLedgers, ctls.packaged.additions.packaged.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.packaged.ingest, 0, ctls.packaged.additions.packaged.ingestLedgers, ctls.packaged.additions.packaged.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.packaged.otherExtract, 0, ctls.packaged.additions.packaged.extractLedgers, ctls.packaged.additions.packaged.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.packaged.topical, 0, ctls.packaged.additions.packaged.topicalLedgers, ctls.packaged.additions.packaged.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.packaged.other, 0, ctls.packaged.additions.packaged.otherLedgers, ctls.packaged.additions.packaged.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('received')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.received.seeds, 0, ctls.packaged.additions.received.seedLedgers, ctls.packaged.additions.received.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.received.vegPlant, 0, ctls.packaged.additions.received.vegLedgers, ctls.packaged.additions.received.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.received.fresh, 0, ctls.packaged.additions.received.freshLedgers, ctls.packaged.additions.received.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.received.dried, 0, ctls.packaged.additions.received.driedLedgers, ctls.packaged.additions.received.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.received.solid, 0, ctls.packaged.additions.received.solidLedgers, ctls.packaged.additions.received.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.received.nonSolid, 0, ctls.packaged.additions.received.nonLedgers, ctls.packaged.additions.received.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.received.inhaled, 0, ctls.packaged.additions.received.inhaledLedgers, ctls.packaged.additions.received.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.received.ingest, 0, ctls.packaged.additions.received.ingestLedgers, ctls.packaged.additions.received.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.received.otherExtract, 0, ctls.packaged.additions.received.extractLedgers, ctls.packaged.additions.received.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.received.topical, 0, ctls.packaged.additions.received.topicalLedgers, ctls.packaged.additions.received.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.received.other, 0, ctls.packaged.additions.received.otherLedgers, ctls.packaged.additions.received.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('return')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.return.seeds, 0, ctls.packaged.additions.return.seedLedgers, ctls.packaged.additions.return.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.return.vegPlant, 0, ctls.packaged.additions.return.vegLedgers, ctls.packaged.additions.return.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.return.fresh, 0, ctls.packaged.additions.return.freshLedgers, ctls.packaged.additions.return.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.return.dried, 0, ctls.packaged.additions.return.driedLedgers, ctls.packaged.additions.return.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.return.solid, 0, ctls.packaged.additions.return.solidLedgers, ctls.packaged.additions.return.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.return.nonSolid, 0, ctls.packaged.additions.return.nonLedgers, ctls.packaged.additions.return.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.return.inhaled, 0, ctls.packaged.additions.return.inhaledLedgers, ctls.packaged.additions.return.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.return.ingest, 0, ctls.packaged.additions.return.ingestLedgers, ctls.packaged.additions.return.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.return.otherExtract, 0, ctls.packaged.additions.return.extractLedgers, ctls.packaged.additions.return.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.return.topical, 0, ctls.packaged.additions.return.topicalLedgers, ctls.packaged.additions.return.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.return.other, 0, ctls.packaged.additions.return.otherLedgers, ctls.packaged.additions.return.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('other')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.other.seeds, 0, ctls.packaged.additions.other.seedLedgers, ctls.packaged.additions.other.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.other.vegPlant, 0, ctls.packaged.additions.other.vegLedgers, ctls.packaged.additions.other.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.other.fresh, 0, ctls.packaged.additions.other.freshLedgers, ctls.packaged.additions.other.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.other.dried, 0, ctls.packaged.additions.other.driedLedgers, ctls.packaged.additions.other.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.other.solid, 0, ctls.packaged.additions.other.solidLedgers, ctls.packaged.additions.other.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.other.nonSolid, 0, ctls.packaged.additions.other.nonLedgers, ctls.packaged.additions.other.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.other.inhaled, 0, ctls.packaged.additions.other.inhaledLedgers, ctls.packaged.additions.other.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.other.ingest, 0, ctls.packaged.additions.other.ingestLedgers, ctls.packaged.additions.other.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.other.otherExtract, 0, ctls.packaged.additions.other.extractLedgers, ctls.packaged.additions.other.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.other.topical, 0, ctls.packaged.additions.other.topicalLedgers, ctls.packaged.additions.other.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.additions.other.other, 0, ctls.packaged.additions.other.otherLedgers, ctls.packaged.additions.other.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('total')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.additions.total.seeds} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.additions.total.vegPlant} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.additions.total.fresh} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.additions.total.dried} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.additions.total.solid} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.additions.total.nonSolid} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.additions.total.inhaled} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.additions.total.ingest} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.additions.total.otherExtract} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.additions.total.topical} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.additions.total.other} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="12" sx={cellStyle}><strong>{t('reductionsInventory')}</strong></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('shipped')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.shipped.seeds, 0, ctls.packaged.reductions.shipped.seedLedgers, ctls.packaged.reductions.shipped.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.shipped.vegPlant, 0, ctls.packaged.reductions.shipped.vegLedgers, ctls.packaged.reductions.shipped.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.shipped.fresh, 0, ctls.packaged.reductions.shipped.freshLedgers, ctls.packaged.reductions.shipped.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.shipped.dried, 0, ctls.packaged.reductions.shipped.driedLedgers, ctls.packaged.reductions.shipped.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.shipped.solid, 0, ctls.packaged.reductions.shipped.solidLedgers, ctls.packaged.reductions.shipped.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.shipped.nonSolid, 0, ctls.packaged.reductions.shipped.nonLedgers, ctls.packaged.reductions.shipped.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.shipped.inhaled, 0, ctls.packaged.reductions.shipped.inhaledLedgers, ctls.packaged.reductions.shipped.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.shipped.ingest, 0, ctls.packaged.reductions.shipped.ingestLedgers, ctls.packaged.reductions.shipped.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.shipped.otherExtract, 0, ctls.packaged.reductions.shipped.extractLedgers, ctls.packaged.reductions.shipped.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.shipped.topical, 0, ctls.packaged.reductions.shipped.topicalLedgers, ctls.packaged.reductions.shipped.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.shipped.other, 0, ctls.packaged.reductions.shipped.otherLedgers, ctls.packaged.reductions.shipped.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('returned')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.returned.seeds, 0, ctls.packaged.reductions.returned.seedLedgers, ctls.packaged.reductions.returned.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.returned.vegPlant, 0, ctls.packaged.reductions.returned.vegLedgers, ctls.packaged.reductions.returned.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.returned.fresh, 0, ctls.packaged.reductions.returned.freshLedgers, ctls.packaged.reductions.returned.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.returned.dried, 0, ctls.packaged.reductions.returned.driedLedgers, ctls.packaged.reductions.returned.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.returned.solid, 0, ctls.packaged.reductions.returned.solidLedgers, ctls.packaged.reductions.returned.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.returned.nonSolid, 0, ctls.packaged.reductions.returned.nonLedgers, ctls.packaged.reductions.returned.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.returned.inhaled, 0, ctls.packaged.reductions.returned.inhaledLedgers, ctls.packaged.reductions.returned.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.returned.ingest, 0, ctls.packaged.reductions.returned.ingestLedgers, ctls.packaged.reductions.returned.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.returned.otherExtract, 0, ctls.packaged.reductions.returned.extractLedgers, ctls.packaged.reductions.returned.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.returned.topical, 0, ctls.packaged.reductions.returned.topicalLedgers, ctls.packaged.reductions.returned.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.returned.other, 0, ctls.packaged.reductions.returned.otherLedgers, ctls.packaged.reductions.returned.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('destroyed')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.destroyed.seeds, 0, ctls.packaged.reductions.destroyed.seedLedgers, ctls.packaged.reductions.destroyed.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.destroyed.vegPlant, 0, ctls.packaged.reductions.destroyed.vegLedgers, ctls.packaged.reductions.destroyed.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.destroyed.fresh, 0, ctls.packaged.reductions.destroyed.freshLedgers, ctls.packaged.reductions.destroyed.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.destroyed.dried, 0, ctls.packaged.reductions.destroyed.driedLedgers, ctls.packaged.reductions.destroyed.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.destroyed.solid, 0, ctls.packaged.reductions.destroyed.solidLedgers, ctls.packaged.reductions.destroyed.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.destroyed.nonSolid, 0, ctls.packaged.reductions.destroyed.nonLedgers, ctls.packaged.reductions.destroyed.nonLedgers)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.destroyed.inhaled, 0, ctls.packaged.reductions.destroyed.inhaledLedgers, ctls.packaged.reductions.destroyed.inhaledLedgers)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.destroyed.ingest, 0, ctls.packaged.reductions.destroyed.ingestLedgers, ctls.packaged.reductions.destroyed.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.destroyed.otherExtract, 0, ctls.packaged.reductions.destroyed.extractLedgers, ctls.packaged.reductions.destroyed.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.destroyed.topical, 0, ctls.packaged.reductions.destroyed.topicalLedgers, ctls.packaged.reductions.destroyed.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.destroyed.other, 0, ctls.packaged.reductions.destroyed.otherLedgers, ctls.packaged.reductions.destroyed.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('lost')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.lost.seeds, 0, ctls.packaged.reductions.lost.seedLedgers, ctls.packaged.reductions.lost.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.lost.vegPlant, 0, ctls.packaged.reductions.lost.vegLedgers, ctls.packaged.reductions.lost.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.lost.fresh, 0, ctls.packaged.reductions.lost.freshLedgers, ctls.packaged.reductions.lost.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.lost.dried, 0, ctls.packaged.reductions.lost.driedLedgers, ctls.packaged.reductions.lost.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.lost.solid, 0, ctls.packaged.reductions.lost.solidLedgers, ctls.packaged.reductions.lost.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.lost.nonSolid, 0, ctls.packaged.reductions.lost.nonLedgers, ctls.packaged.reductions.lost.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.lost.inhaled, 0, ctls.packaged.reductions.lost.inhaledLedgers, ctls.packaged.reductions.lost.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.lost.ingest, 0, ctls.packaged.reductions.lost.ingestLedgers, ctls.packaged.reductions.lost.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.lost.otherExtract, 0, ctls.packaged.reductions.lost.extractLedgers, ctls.packaged.reductions.lost.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.lost.topical, 0, ctls.packaged.reductions.lost.topicalLedgers, ctls.packaged.reductions.lost.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.lost.other, 0, ctls.packaged.reductions.lost.otherLedgers, ctls.packaged.reductions.lost.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('other')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.other.seeds, 0, ctls.packaged.reductions.other.seedLedgers, ctls.packaged.reductions.other.seedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.other.vegPlant, 0, ctls.packaged.reductions.other.vegLedgers, ctls.packaged.reductions.other.vegLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.other.fresh, 0, ctls.packaged.reductions.other.freshLedgers, ctls.packaged.reductions.other.freshLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.other.dried, 0, ctls.packaged.reductions.other.driedLedgers, ctls.packaged.reductions.other.driedLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.other.solid, 0, ctls.packaged.reductions.other.solidLedgers, ctls.packaged.reductions.other.solidLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.other.nonSolid, 0, ctls.packaged.reductions.other.nonLedgers, ctls.packaged.reductions.other.nonLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.other.inhaled, 0, ctls.packaged.reductions.other.inhaledLedgers, ctls.packaged.reductions.other.inhaledLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.other.ingest, 0, ctls.packaged.reductions.other.ingestLedgers, ctls.packaged.reductions.other.ingestLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.other.otherExtract, 0, ctls.packaged.reductions.other.extractLedgers, ctls.packaged.reductions.other.extractLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.other.topical, 0, ctls.packaged.reductions.other.topicalLedgers, ctls.packaged.reductions.other.topicalLedgersNeg)}
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    {this.displayValue(ctls.packaged.reductions.other.other, 0, ctls.packaged.reductions.other.otherLedgers, ctls.packaged.reductions.other.otherLedgersNeg)}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('total')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.reductions.total.seeds} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.reductions.total.vegPlant} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.reductions.total.fresh} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.reductions.total.dried} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.reductions.total.solid} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.reductions.total.nonSolid} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.reductions.total.inhaled} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.reductions.total.ingest} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.reductions.total.otherExtract} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.reductions.total.topical} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.reductions.total.other} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="12" sx={cellStyle}><strong>{t('closing')}</strong></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('inventory')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closing.seeds} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closing.vegPlant} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closing.fresh} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closing.dried} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closing.solid} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closing.nonSolid} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closing.inhaled} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closing.ingest} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closing.otherExtract} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closing.topical} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closing.other} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('value')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingValue.seeds} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingValue.vegPlant} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingValue.fresh} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingValue.dried} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingValue.solid} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingValue.nonSolid} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingValue.inhaled} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingValue.ingest} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingValue.otherExtract} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingValue.topical} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingValue.other} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'$'}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('weight')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingWeight.seeds} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingWeight.vegPlant} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingWeight.fresh} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingWeight.dried} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingWeight.solid} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingWeight.nonSolid} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingWeight.inhaled} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingWeight.ingest} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingWeight.otherExtract} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingWeight.topical} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.closingWeight.other} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}>{t('check')}</TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.check.seeds} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.check.vegPlant} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.check.fresh} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.check.dried} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.check.solid} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.check.nonSolid} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.check.inhaled} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.check.ingest} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.check.otherExtract} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.check.topical} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={ctls.packaged.check.other} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                        </Aux>
                    }
                    <TabPanel key={ctls != null ? 0 : 2} value={this.state.tabKey} index={ctls == null ? 0 : 2}>
                        {reportData != null &&
                            <Aux>
                                <BoxTitle title={t('craB300')}/>
                                <SubSectionHeading title={t('unpackaged')} />
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="b330 unpackaged">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}>{t('kgMaterial')}</TableCell>
                                                <TableCell sx={cellStyle}>{t('kgMaterial')}</TableCell>
                                                <TableCell sx={cellStyle}>{t('unitsPlants')}</TableCell>
                                                <TableCell sx={cellStyle}>{t('unitsSeeds')}</TableCell>
                                                <TableCell sx={cellStyle}>{t('unitsPlants')}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}>{t('floweringMaterial')}</TableCell>
                                                <TableCell sx={cellStyle}>{t('nonFloweringMaterial')}</TableCell>
                                                <TableCell sx={cellStyle}>{t('wholePlants')}</TableCell>
                                                <TableCell sx={cellStyle}>{t('viableSeeds')}</TableCell>
                                                <TableCell sx={cellStyle}>{t('vegPlants')}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}><strong>{t('openingInventory')}</strong></TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={reportData.unpackaged.openingInventory.flower} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={reportData.unpackaged.openingInventory.nonFlower} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={reportData.unpackaged.openingInventory.wholePlants} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={reportData.unpackaged.openingInventory.seeds} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={reportData.unpackaged.openingInventory.vegPlants} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                </TableCell>
                                            </TableRow>
                                            {reportData.unpackaged.additions != null &&
                                                <Aux>
                                                    <TableRow>
                                                        <TableCell colSpan="6" sx={cellStyle}><strong>{t('additionsInventory')}</strong></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}>{t('totalProduction')}</TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.totalProduction.flower, 3, reportData.unpackaged.additions.totalProduction.flowerLedgers, reportData.unpackaged.additions.totalProduction.flowerLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.totalProduction.nonFlower, 3, reportData.unpackaged.additions.totalProduction.nonFlowerLedgers, reportData.unpackaged.additions.totalProduction.nonFlowerLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.totalProduction.seeds, 0, reportData.unpackaged.additions.totalProduction.seedLedgers, reportData.unpackaged.additions.totalProduction.seedLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.totalProduction.vegPlants, 0, reportData.unpackaged.additions.totalProduction.vegLedgers, reportData.unpackaged.additions.totalProduction.vegLedgersNeg)}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}>{t('receivedCanada')}</TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.received.flower, 3, reportData.unpackaged.additions.received.flowerLedgers, reportData.unpackaged.additions.received.flowerLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.received.nonFlower, 3, reportData.unpackaged.additions.received.nonFlowerLedgers, reportData.unpackaged.additions.received.nonFlowerLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.received.wholePlants, 0, reportData.unpackaged.additions.received.wholePlantLedgers, reportData.unpackaged.additions.received.wholePlantLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.received.seeds, 0, reportData.unpackaged.additions.received.seedLedgers, reportData.unpackaged.additions.received.seedLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.received.vegPlants, 0, reportData.unpackaged.additions.received.vegLedgers, reportData.unpackaged.additions.received.vegLedgersNeg)}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}>{t('receivedImport')}</TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.imported.flower, 3, reportData.unpackaged.additions.imported.flowerLedgers, reportData.unpackaged.additions.imported.flowerLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.imported.nonFlower, 3, reportData.unpackaged.additions.imported.nonFlowerLedgers, reportData.unpackaged.additions.imported.nonFlowerLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.imported.seeds, 0, reportData.unpackaged.additions.imported.seedLedgers, reportData.unpackaged.additions.imported.seedLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.imported.vegPlants, 0, reportData.unpackaged.additions.imported.vegLedgers, reportData.unpackaged.additions.imported.vegLedgersNeg)}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}>{t('vegTransferred')}</TableCell>
                                                        <TableCell sx={cellStyle}></TableCell>
                                                        <TableCell sx={cellStyle}></TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.wholePlantsFromVeg.wholePlants, 0, reportData.unpackaged.additions.wholePlantsFromVeg.wholePlantLedgers, reportData.unpackaged.additions.wholePlantsFromVeg.wholePlantLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell sx={cellStyle}></TableCell>
                                                        <TableCell sx={cellStyle}></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}><strong>{t('totalAdditions')}</strong></TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.unpackaged.additions.total.flower} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.unpackaged.additions.total.nonFlower} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.unpackaged.additions.total.wholePlants} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.unpackaged.additions.total.seeds} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.unpackaged.additions.total.vegPlants} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                        </TableCell>
                                                    </TableRow>
                                                </Aux>
                                            }
                                            {reportData.unpackaged.reductions != null &&
                                                <Aux>
                                                    <TableRow>
                                                        <TableCell colSpan="6" sx={cellStyle}><strong>{t('reductionsInventory')}</strong></TableCell>
                                                    </TableRow>
                                                    {reportData.unpackaged.reductions.packaged != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('quantityPackaged')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.packaged.flower, 3, reportData.unpackaged.reductions.packaged.flowerLedgers, reportData.unpackaged.reductions.packaged.flowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.packaged.nonFlower, 3, reportData.unpackaged.reductions.packaged.nonFlowerLedgers, reportData.unpackaged.reductions.packaged.nonFlowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.packaged.seeds, 0, reportData.unpackaged.reductions.packaged.seedLedgers, reportData.unpackaged.reductions.packaged.seedLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.packaged.vegPlants, 0, reportData.unpackaged.reductions.packaged.vegLedgers, reportData.unpackaged.reductions.packaged.vegLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.processed != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('furtherProcessed')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.processed.flower, 3, reportData.unpackaged.reductions.processed.flowerLedgers, reportData.unpackaged.reductions.processed.flowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.processed.nonFlower, 3, reportData.unpackaged.reductions.processed.nonFlowerLedgers, reportData.unpackaged.reductions.processed.nonFlowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.processed.seeds, 0, reportData.unpackaged.reductions.processed.seedLedgers, reportData.unpackaged.reductions.processed.seedLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.processed.vegPlants, 0, reportData.unpackaged.reductions.processed.vegLedgers, reportData.unpackaged.reductions.processed.vegLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.harvested  != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('plantsHarvested')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.harvested.wholePlants, 0, reportData.unpackaged.reductions.harvested.wholePlantLedgers, reportData.unpackaged.reductions.harvested.wholePlantLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.shipped != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t(is2024 ? 'deliveredCanada2024' :'deliveredCanada')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.shipped.flower, 3, reportData.unpackaged.reductions.shipped.flowerLedgers, reportData.unpackaged.reductions.shipped.flowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.shipped.nonFlower, 3, reportData.unpackaged.reductions.shipped.nonFlowerLedgers, reportData.unpackaged.reductions.shipped.nonFlowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.shipped.wholePlants, 0, reportData.unpackaged.reductions.shipped.wholePlantLedgers, reportData.unpackaged.reductions.shipped.wholePlantLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.shipped.seeds, 0, reportData.unpackaged.reductions.shipped.seedLedgers, reportData.unpackaged.reductions.shipped.seedLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.shipped.vegPlants, 0, reportData.unpackaged.reductions.shipped.vegLedgers, reportData.unpackaged.reductions.shipped.vegLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.exported != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('exportedQuantity')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.exported.flower, 3, reportData.unpackaged.reductions.exported.flowerLedgers, reportData.unpackaged.reductions.exported.flowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.exported.nonFlower, 3, reportData.unpackaged.reductions.exported.nonFlowerLedgers, reportData.unpackaged.reductions.exported.nonFlowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.exported.wholePlants, 0, reportData.unpackaged.reductions.exported.wholePlantLedgers, reportData.unpackaged.reductions.exported.wholePlantLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.exported.seeds, 0, reportData.unpackaged.reductions.exported.seedLedgers, reportData.unpackaged.reductions.exported.seedLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.exported.vegPlants, 0, reportData.unpackaged.reductions.exported.vegLedgers, reportData.unpackaged.reductions.exported.vegLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.processingLoss != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('quantityProcessLoss')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.processingLoss.flower, 3, reportData.unpackaged.reductions.processingLoss.flowerLedgers, reportData.unpackaged.reductions.processingLoss.flowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.processingLoss.nonFlower, 3, reportData.unpackaged.reductions.processingLoss.nonFlowerLedgers, reportData.unpackaged.reductions.processingLoss.nonFlowerLedgers)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.destroyed != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('quantityDestroyed')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.destroyed.flower, 3, reportData.unpackaged.reductions.destroyed.flowerLedgers, reportData.unpackaged.reductions.destroyed.flowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.destroyed.nonFlower, 3, reportData.unpackaged.reductions.destroyed.nonFlowerLedgers, reportData.unpackaged.reductions.destroyed.nonFlowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.destroyed.wholePlants, 0, reportData.unpackaged.reductions.destroyed.wholePlantLedgers, reportData.unpackaged.reductions.destroyed.wholePlantLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.destroyed.seeds, 0, reportData.unpackaged.reductions.destroyed.seedLedgers, reportData.unpackaged.reductions.destroyed.seedLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.destroyed.vegPlants, 0, reportData.unpackaged.reductions.destroyed.vegLedgers, reportData.unpackaged.reductions.destroyed.vegLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.analysis != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('quantityAnalysis')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.analysis.flower, 3, reportData.unpackaged.reductions.analysis.flowerLedgers, reportData.unpackaged.reductions.analysis.flowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.analysis.nonFlower, 3, reportData.unpackaged.reductions.analysis.nonFlowerLedgers, reportData.unpackaged.reductions.analysis.nonFlowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.analysis.wholePlants, 0, reportData.unpackaged.reductions.analysis.wholePlantLedgers, reportData.unpackaged.reductions.analysis.wholePlantLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.analysis.seeds, 0, reportData.unpackaged.reductions.analysis.seedLedgers, reportData.unpackaged.reductions.analysis.seedLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.analysis.vegPlants, 0, reportData.unpackaged.reductions.analysis.vegLedgers, reportData.unpackaged.reductions.analysis.vegLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.transferredToWhole != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('wpTransferred')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.transferredToWhole.vegPlants, 0, reportData.unpackaged.reductions.transferredToWhole.vegLedgers, reportData.unpackaged.reductions.transferredToWhole.vegLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.lowTHCUse != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('quantityLowThc')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.lowTHCUse.flower, 3, reportData.unpackaged.reductions.lowTHCUse.flowerLedgers, reportData.unpackaged.reductions.lowTHCUse.flowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.lowTHCUse.nonFlower, 3, reportData.unpackaged.reductions.lowTHCUse.nonFlowerLedgers, reportData.unpackaged.reductions.lowTHCUse.nonFlowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.other != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('other')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.other.flower, 3, reportData.unpackaged.reductions.other.flowerLedgers, reportData.unpackaged.reductions.other.flowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.other.nonFlower, 3, reportData.unpackaged.reductions.other.nonFlowerLedgers, reportData.unpackaged.reductions.other.nonFlowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.other.wholePlants, 0, reportData.unpackaged.reductions.other.wholePlantLedgers, reportData.unpackaged.reductions.other.wholePlantLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.other.seeds, 0, reportData.unpackaged.reductions.other.seedLedgers, reportData.unpackaged.reductions.other.seedLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.other.vegPlants, 0, reportData.unpackaged.reductions.other.vegLedgers, reportData.unpackaged.reductions.other.vegLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.total != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}><strong>{t('totalReductions')}</strong> </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.reductions.total.flower} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.reductions.total.nonFlower} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.reductions.total.wholePlants} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.reductions.total.seeds} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.reductions.total.vegPlants} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackagedAdjustments != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}><strong>{t('inventoryAdjustments')}</strong> </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackagedAdjustments.flower, 2, reportData.unpackagedAdjustments.flowerLedgers, reportData.unpackagedAdjustments.flowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackagedAdjustments.nonFlower, 2, reportData.unpackagedAdjustments.nonFlowerLedgers, reportData.unpackagedAdjustments.nonFlowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackagedAdjustments.wholePlants, 2, reportData.unpackagedAdjustments.wholePlantLedgers, reportData.unpackagedAdjustments.wholePlantLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackagedAdjustments.seeds, 2, reportData.unpackagedAdjustments.seedLedgers, reportData.unpackagedAdjustments.seedLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackagedAdjustments.vegPlants, 2, reportData.unpackagedAdjustments.vegLedgers, reportData.unpackagedAdjustments.vegLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.closingInventory != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}><strong>{t('closingInventory')}</strong> </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.closingInventory.flower} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.closingInventory.nonFlower} displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.closingInventory.wholePlants} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.closingInventory.seeds} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.closingInventory.vegPlants} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </Aux>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <br/>
                                <br/>
                                <SubSectionHeading title={t('unpackagedCont')}/>
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="b330 unpackaged2">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}>{t('kgMaterial')}</TableCell>
                                                <TableCell sx={cellStyle}>{t('mgThc')}</TableCell>
                                                <TableCell sx={cellStyle}>{t('mgThc')}</TableCell>
                                                <TableCell sx={cellStyle}>{t('mgThc')}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}></TableCell>
                                                <TableCell sx={cellStyle}><b>{t('pureIntermediaries')}</b></TableCell>
                                                <TableCell sx={cellStyle}><b>{t('finishedExtracts')}</b></TableCell>
                                                <TableCell sx={cellStyle}><b>{t('finishedEdibles')}</b></TableCell>
                                                <TableCell sx={cellStyle}><b>{t('finishedTopicals')}</b></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={cellStyle}><strong>{t('openingInventory')}</strong></TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={reportData.unpackaged.openingInventory.pureIntermediaries } displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={reportData.unpackaged.openingInventory.extractsTHC } displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={reportData.unpackaged.openingInventory.edibleTHC } displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right" sx={cellStyle}>
                                                    <NumericFormat value={reportData.unpackaged.openingInventory.topicalTHC } displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                            </TableRow>
                                            {reportData.unpackaged.additions != null &&
                                                <Aux>
                                                    <TableRow>
                                                        <TableCell colSpan="6" sx={cellStyle}><strong>{t('additionsInventory')}</strong></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}>{t('totalProduction')}</TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.totalProduction.pureIntermediaries, 2, reportData.unpackaged.additions.totalProduction.pureLedgers, reportData.unpackaged.additions.totalProduction.pureLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.totalProduction.extractsTHC, 2, reportData.unpackaged.additions.totalProduction.extractLedgers, reportData.unpackaged.additions.totalProduction.extractLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.totalProduction.edibleTHC, 2, reportData.unpackaged.additions.totalProduction.edibleLedgers, reportData.unpackaged.additions.totalProduction.edibleLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.totalProduction.topicalTHC, 2, reportData.unpackaged.additions.totalProduction.topicalLedgers, reportData.unpackaged.additions.totalProduction.topicalLedgersNeg)}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}>{t('receivedCanada')}</TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.received.pureIntermediaries, 2, reportData.unpackaged.additions.received.pureLedgers, reportData.unpackaged.additions.received.pureLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.received.extractsTHC, 2, reportData.unpackaged.additions.received.extractLedgers, reportData.unpackaged.additions.received.extractLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.received.edibleTHC, 2, reportData.unpackaged.additions.received.edibleLedgers, reportData.unpackaged.additions.received.edibleLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.received.topicalTHC, 2, reportData.unpackaged.additions.received.topicalLedgers, reportData.unpackaged.additions.received.topicalLedgersNeg)}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}>{t('receivedImport')}</TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.imported.pureIntermediaries, 2, reportData.unpackaged.additions.imported.pureLedgers, reportData.unpackaged.additions.imported.pureLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.imported.extractsTHC, 2, reportData.unpackaged.additions.imported.extractLedgers, reportData.unpackaged.additions.imported.extractLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.imported.edibleTHC, 2, reportData.unpackaged.additions.imported.edibleLedgers, reportData.unpackaged.additions.imported.edibleLedgersNeg)}
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            {this.displayValue(reportData.unpackaged.additions.imported.topicalTHC, 2, reportData.unpackaged.additions.imported.topicalLedgers, reportData.unpackaged.additions.imported.topicalLedgersNeg)}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}>{t('vegTransferred')}</TableCell>
                                                        <TableCell sx={cellStyle}></TableCell>
                                                        <TableCell sx={cellStyle}></TableCell>
                                                        <TableCell sx={cellStyle}></TableCell>
                                                        <TableCell sx={cellStyle}></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}><strong>{t('totalAdditions')}</strong></TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.unpackaged.additions.total.pureIntermediaries } displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.unpackaged.additions.total.extractsTHC } displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.unpackaged.additions.total.edibleTHC } displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.unpackaged.additions.total.topicalTHC } displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                        </TableCell>
                                                    </TableRow>
                                                </Aux>
                                            }
                                            {reportData.unpackaged.reductions != null &&
                                                <Aux>
                                                    <TableRow>
                                                        <TableCell colSpan="6" sx={cellStyle}><strong>{t('reductionsInventory')}</strong></TableCell>
                                                    </TableRow> 
                                                    {reportData.unpackaged.reductions.packaged != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('quantityPackaged')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.packaged.extractsTHC, 2, reportData.unpackaged.reductions.packaged.extractLedgers, reportData.unpackaged.reductions.packaged.extractLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.packaged.edibleTHC, 2, reportData.unpackaged.reductions.packaged.edibleLedgers, reportData.unpackaged.reductions.packaged.edibleLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.packaged.topicalTHC, 2, reportData.unpackaged.reductions.packaged.topicalLedgers, reportData.unpackaged.reductions.packaged.topicalLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.processed != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('furtherProcessed')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.processed.pureIntermediaries, 2, reportData.unpackaged.reductions.processed.pureLedgers, reportData.unpackaged.reductions.processed.pureLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.harvested  != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('plantsHarvested')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.shipped != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t(is2024 ? 'deliveredCanada2024' :'deliveredCanada')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.shipped.pureIntermediaries, 2, reportData.unpackaged.reductions.shipped.pureLedgers, reportData.unpackaged.reductions.shipped.pureLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.shipped.extractsTHC, 2, reportData.unpackaged.reductions.shipped.extractLedgers, reportData.unpackaged.reductions.shipped.extractLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.shipped.edibleTHC, 2, reportData.unpackaged.reductions.shipped.edibleLedgers, reportData.unpackaged.reductions.shipped.edibleLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.shipped.topicalTHC, 2, reportData.unpackaged.reductions.shipped.topicalLedgers, reportData.unpackaged.reductions.shipped.topicalLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.exported != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('exportedQuantity')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.exported.pureIntermediaries, 2, reportData.unpackaged.reductions.exported.pureLedgers, reportData.unpackaged.reductions.exported.pureLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.exported.extractsTHC, 2, reportData.unpackaged.reductions.exported.extractLedgers, reportData.unpackaged.reductions.exported.extractLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.exported.edibleTHC, 2, reportData.unpackaged.reductions.exported.edibleLedgers, reportData.unpackaged.reductions.exported.edibleLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.exported.topicalTHC, 2, reportData.unpackaged.reductions.exported.topicalLedgers, reportData.unpackaged.reductions.exported.topicalLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.processingLoss != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('quantityProcessLoss')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.processingLoss.pureIntermediaries, 2, reportData.unpackaged.reductions.processingLoss.pureLedgers, reportData.unpackaged.reductions.processingLoss.pureLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.processingLoss.extractsTHC, 2, reportData.unpackaged.reductions.processingLoss.extractLedgers, reportData.unpackaged.reductions.processingLoss.extractLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.processingLoss.edibleTHC, 2, reportData.unpackaged.reductions.processingLoss.edibleLedgers, reportData.unpackaged.reductions.processingLoss.edibleLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.processingLoss.topicalTHC, 2, reportData.unpackaged.reductions.processingLoss.topicalLedgers, reportData.unpackaged.reductions.processingLoss.topicalLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.destroyed != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('quantityDestroyed')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.destroyed.pureIntermediaries, 2, reportData.unpackaged.reductions.destroyed.pureLedgers, reportData.unpackaged.reductions.destroyed.pureLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.destroyed.extractsTHC, 2, reportData.unpackaged.reductions.destroyed.extractLedgers, reportData.unpackaged.reductions.destroyed.extractLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.destroyed.edibleTHC, 2, reportData.unpackaged.reductions.destroyed.edibleLedgers, reportData.unpackaged.reductions.destroyed.edibleLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.destroyed.topicalTHC, 2, reportData.unpackaged.reductions.destroyed.topicalLedgers, reportData.unpackaged.reductions.destroyed.topicalLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.analysis != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('quantityAnalysis')} </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.analysis.pureIntermediaries, 2, reportData.unpackaged.reductions.analysis.pureLedgers, reportData.unpackaged.reductions.analysis.pureLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.analysis.extractsTHC, 2, reportData.unpackaged.reductions.analysis.extractLedgers, reportData.unpackaged.reductions.analysis.extractLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.analysis.edibleTHC, 2, reportData.unpackaged.reductions.analysis.edibleLedgers, reportData.unpackaged.reductions.analysis.edibleLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.analysis.topicalTHC, 2, reportData.unpackaged.reductions.analysis.topicalLedgers, reportData.unpackaged.reductions.analysis.topicalLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.transferredToWhole != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('wpTransferred')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.lowTHCUse != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('quantityLowThc')}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.lowTHCUse.pureIntermediaries, 2, reportData.unpackaged.reductions.lowTHCUse.pureLedgers, reportData.unpackaged.reductions.lowTHCUse.pureLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                            <TableCell align="right" sx={cellStyle}></TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.other != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}>{t('other')} </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.other.pureIntermediaries, 2, reportData.unpackaged.reductions.other.pureLedgers, reportData.unpackaged.reductions.other.pureLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.other.extractsTHC, 2, reportData.unpackaged.reductions.other.extractLedgers, reportData.unpackaged.reductions.other.extractLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.other.edibleTHC, 2, reportData.unpackaged.reductions.other.edibleLedgers, reportData.unpackaged.reductions.other.edibleLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackaged.reductions.other.topicalTHC, 2, reportData.unpackaged.reductions.other.topicalLedgers, reportData.unpackaged.reductions.other.topicalLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.reductions.total != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}><strong>{t('totalReductions')}</strong> </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.reductions.total.pureIntermediaries } displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.reductions.total.extractsTHC } displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.reductions.total.edibleTHC } displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.reductions.total.topicalTHC } displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackagedAdjustments != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}><strong>{t('inventoryAdjustments')}</strong> </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackagedAdjustments.pureIntermediaries, 2, reportData.unpackagedAdjustments.pureLedgers, reportData.unpackagedAdjustments.pureLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackagedAdjustments.extractsTHC, 2, reportData.unpackagedAdjustments.extractLedgers, reportData.unpackagedAdjustments.extractLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackagedAdjustments.edibleTHC, 2, reportData.unpackagedAdjustments.edibleLedgers, reportData.unpackagedAdjustments.edibleLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.unpackagedAdjustments.topicalTHC, 2, reportData.unpackagedAdjustments.topicalLedgers, reportData.unpackagedAdjustments.topicalLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.unpackaged.closingInventory != null &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}><strong>{t('closingInventory')}</strong> </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.closingInventory.pureIntermediaries } displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.closingInventory.extractsTHC } displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.closingInventory.edibleTHC } displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={reportData.unpackaged.closingInventory.topicalTHC } displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </Aux>
                                            }
                                        </TableBody>
                                        </Table>
                                </TableContainer>
                                {reportData.packaged != null && reportData.packaged.openingInventory != null &&
                                    <Aux>
                                        <br/>
                                        <br/>
                                        <SubSectionHeading title={t('packaged')}/>
                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="b330 packaged">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}></TableCell>
                                                        <TableCell sx={cellStyle}>{t('kgMaterial')}</TableCell>
                                                        <TableCell sx={cellStyle}>{t('unitsSeeds')}</TableCell>
                                                        <TableCell sx={cellStyle}>{t('unitsPlants')}</TableCell>
                                                        <TableCell sx={cellStyle}>{t('mgThc')}</TableCell>
                                                        <TableCell sx={cellStyle}>{t('mgThc')}</TableCell>
                                                        <TableCell sx={cellStyle}>{t('mgThc')}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}></TableCell>
                                                        <TableCell sx={cellStyle}><b>{t('driedFresh')}</b></TableCell>
                                                        <TableCell sx={cellStyle}><b>{t('plantSeeds')}</b></TableCell>
                                                        <TableCell sx={cellStyle}><b>{t('cannabisPlants')}</b></TableCell>
                                                        <TableCell sx={cellStyle}><b>{t('cannabisExtracts')}</b></TableCell>
                                                        <TableCell sx={cellStyle}><b>{t('edibleCannabis')}</b></TableCell>
                                                        <TableCell sx={cellStyle}><b>{t('cannabisTopicals')}</b></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}><strong>{t('openingInventory')}</strong></TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.packaged.openingInventory.flower } displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.packaged.openingInventory.seeds } displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.packaged.openingInventory.plants} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.packaged.openingInventory.extractsTHC} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.packaged.openingInventory.edibleTHC} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.packaged.openingInventory.topicalTHC} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                        </TableCell>
                                                    </TableRow>
                                                    {reportData.packaged.additions != null && this.state.is2024 && 
                                                        <Aux>
                                                            <TableRow>
                                                                <TableCell colSpan="7" sx={cellStyle}><strong>{t('additionsInventory')}</strong></TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell sx={cellStyle}>{t('quantityPackaged')}</TableCell>
                                                                <TableCell align="right" sx={cellStyle}>
                                                                    {this.displayValue(reportData.packaged.additions.packaged.flower, 3, reportData.packaged.additions.packaged.flowerLedgers, reportData.packaged.additions.packaged.flowerLedgersNeg)}
                                                                </TableCell>
                                                                <TableCell align="right" sx={cellStyle}>
                                                                    {this.displayValue(reportData.packaged.additions.packaged.seeds, 2, reportData.packaged.additions.packaged.seedLedgers, reportData.packaged.additions.packaged.seedLedgersNeg)}
                                                                </TableCell>
                                                                <TableCell align="right" sx={cellStyle}>
                                                                    {this.displayValue(reportData.packaged.additions.packaged.plants, 0, reportData.packaged.additions.packaged.plantLedgers, reportData.packaged.additions.packaged.plantLedgersNeg)}
                                                                </TableCell>
                                                                <TableCell align="right" sx={cellStyle}>
                                                                    {this.displayValue(reportData.packaged.additions.packaged.extractsTHC, 2, reportData.packaged.additions.packaged.extractLedgers, reportData.packaged.additions.packaged.extractLedgersNeg)}
                                                                </TableCell>
                                                                <TableCell align="right" sx={cellStyle}>
                                                                    {this.displayValue(reportData.packaged.additions.packaged.edibleTHC, 2, reportData.packaged.additions.packaged.edibleLedgers, reportData.packaged.additions.packaged.edibleLedgersNeg)}
                                                                </TableCell>
                                                                <TableCell align="right" sx={cellStyle}>
                                                                    {this.displayValue(reportData.packaged.additions.packaged.topicalTHC, 2, reportData.packaged.additions.packaged.topicalLedgers, reportData.packaged.additions.packaged.topicalLedgersNeg)}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell sx={cellStyle}>{t('quantityPurchasedUnstamped')}</TableCell>
                                                                <TableCell align="right" sx={cellStyle}>
                                                                    {this.displayValue(reportData.packaged.additions.purchasedUnstamped.flower, 3, reportData.packaged.additions.purchasedUnstamped.flowerLedgers, reportData.packaged.additions.purchasedUnstamped.flowerLedgersNeg)}
                                                                </TableCell>
                                                                <TableCell align="right" sx={cellStyle}>
                                                                    {this.displayValue(reportData.packaged.additions.purchasedUnstamped.seeds, 0, reportData.packaged.additions.purchasedUnstamped.seedLedgers, reportData.packaged.additions.purchasedUnstamped.seedLedgersNeg)}
                                                                </TableCell>
                                                                <TableCell align="right" sx={cellStyle}>
                                                                    {this.displayValue(reportData.packaged.additions.purchasedUnstamped.plants, 0, reportData.packaged.additions.purchasedUnstamped.plantLedgers, reportData.packaged.additions.purchasedUnstamped.plantLedgersNeg)}
                                                                </TableCell>
                                                                <TableCell align="right" sx={cellStyle}>
                                                                    {this.displayValue(reportData.packaged.additions.purchasedUnstamped.extractsTHC, 2, reportData.packaged.additions.purchasedUnstamped.extractLedgers, reportData.packaged.additions.purchasedUnstamped.extractLedgersNeg)}
                                                                </TableCell>
                                                                <TableCell align="right" sx={cellStyle}>
                                                                    {this.displayValue(reportData.packaged.additions.purchasedUnstamped.edibleTHC, 2, reportData.packaged.additions.purchasedUnstamped.edibleLedgers, reportData.packaged.additions.purchasedUnstamped.edibleLedgersNeg)}
                                                                </TableCell>
                                                                <TableCell align="right" sx={cellStyle}>
                                                                    {this.displayValue(reportData.packaged.additions.purchasedUnstamped.topicalTHC, 2, reportData.packaged.additions.purchasedUnstamped.topicalLedgers, reportData.packaged.additions.purchasedUnstamped.topicalLedgersNeg)}
                                                                </TableCell>
                                                            </TableRow>
                                                        </Aux>
                                                    }
                                                    {reportData.packaged.additions != null && !is2024 &&
                                                        <TableRow>
                                                            <TableCell sx={cellStyle}><strong>{t('additionsInventory')}</strong></TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.packaged.additions.flower, 3, reportData.packaged.additions.flowerLedgers, reportData.packaged.additions.flowerLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.packaged.additions.seeds, 0, reportData.packaged.additions.seedLedgers, reportData.packaged.additions.seedLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.packaged.additions.plants, 0, reportData.packaged.additions.plantLedgers, reportData.packaged.additions.plantLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.packaged.additions.extractsTHC, 2, reportData.packaged.additions.extractLedgers, reportData.packaged.additions.extractLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.packaged.additions.edibleTHC, 2, reportData.packaged.additions.edibleLedgers, reportData.packaged.additions.edibleLedgersNeg)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(reportData.packaged.additions.topicalTHC, 2, reportData.packaged.additions.topicalLedgers, reportData.packaged.additions.topicalLedgersNeg)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {reportData.packaged.reductions != null &&
                                                        <Aux>
                                                            <TableRow>
                                                                <TableCell colSpan="7" sx={cellStyle}><strong>{t('reductionsInventory')}</strong></TableCell>
                                                            </TableRow>
                                                            {reportData.packaged.reductions.shipped != null &&
                                                                <TableRow>
                                                                    <TableCell sx={cellStyle}>{t(is2024 ? 'deliveredStampedCanada' :'deliveredCanada')}</TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.shippedStamped.flower, 3, reportData.packaged.reductions.shippedStamped.flowerLedgers, reportData.packaged.reductions.shippedStamped.flowerLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.shippedStamped.seeds, 0, reportData.packaged.reductions.shippedStamped.seedLedgers, reportData.packaged.reductions.shippedStamped.seedLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.shippedStamped.p0ants, 3, reportData.packaged.reductions.shippedStamped.plantLedgers, reportData.packaged.reductions.shippedStamped.plantLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.shippedStamped.extractsTHC, 2, reportData.packaged.reductions.shippedStamped.extractLedgers, reportData.packaged.reductions.shippedStamped.extractLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.shippedStamped.edibleTHC, 2, reportData.packaged.reductions.shippedStamped.edibleLedgers, reportData.packaged.reductions.shippedStamped.edibleLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.shippedStamped.topicalTHC, 2, reportData.packaged.reductions.shippedStamped.topicalLedgers, reportData.packaged.reductions.shippedStamped.topicalLedgersNeg)}
                                                                    </TableCell>
                                                                </TableRow>
                                                            }
                                                            {reportData.packaged.reductions.shippedUnstamped != null && is2024 && 
                                                                <TableRow>
                                                                    <TableCell sx={cellStyle}>{t('quantitySoldUnstamped')}</TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.shippedUnstamped.flower, 3, reportData.packaged.reductions.shippedUnstamped.flowerLedgers, reportData.packaged.reductions.shippedUnstamped.flowerLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.shippedUnstamped.seeds, 0, reportData.packaged.reductions.shippedUnstamped.seedLedgers, reportData.packaged.reductions.shippedUnstamped.seedLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.shippedUnstamped.plants, 0, reportData.packaged.reductions.shippedUnstamped.plantLedgers, reportData.packaged.reductions.shippedUnstamped.plantLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.shippedUnstamped.extractsTHC, 2, reportData.packaged.reductions.shippedUnstamped.extractLedgers, reportData.packaged.reductions.shippedUnstamped.extractLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.shippedUnstamped.edibleTHC, 2, reportData.packaged.reductions.shippedUnstamped.edibleLedgers, reportData.packaged.reductions.shippedUnstamped.edibleLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.shippedUnstamped.topicalTHC, 2, reportData.packaged.reductions.shippedUnstamped.topicalLedgers, reportData.packaged.reductions.shippedUnstamped.topicalLedgersNeg)}
                                                                    </TableCell>
                                                                </TableRow>
                                                            }
                                                            {reportData.packaged.reductions.destroyed != null &&
                                                                <TableRow>
                                                                    <TableCell sx={cellStyle}>{t('quantityDestroyed')}</TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.destroyed.flower, 3, reportData.packaged.reductions.destroyed.flowerLedgers, reportData.packaged.reductions.destroyed.flowerLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.destroyed.seeds, 0, reportData.packaged.reductions.destroyed.seedLedgers, reportData.packaged.reductions.destroyed.seedLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.destroyed.plants, 0, reportData.packaged.reductions.destroyed.plantLedgers, reportData.packaged.reductions.destroyed.plantLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.destroyed.extractsTHC, 2, reportData.packaged.reductions.destroyed.extractLedgers, reportData.packaged.reductions.destroyed.extractLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.destroyed.edibleTHC, 2, reportData.packaged.reductions.destroyed.edibleLedgers, reportData.packaged.reductions.destroyed.edibleLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.destroyed.topicalTHC, 3, reportData.packaged.reductions.destroyed.topicalLedgers, reportData.packaged.reductions.destroyed.topicalLedgersNeg)}
                                                                    </TableCell>
                                                                </TableRow>
                                                            }
                                                            {reportData.packaged.reductions.other != null &&
                                                                <TableRow>
                                                                    <TableCell sx={cellStyle}>{t('other')}</TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.other.flower, 3, reportData.packaged.reductions.other.flowerLedgers, reportData.packaged.reductions.other.flowerLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.other.seeds, 0, reportData.packaged.reductions.other.seedLedgers, reportData.packaged.reductions.other.seedLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.other.plants, 0, reportData.packaged.reductions.other.plantLedgers, reportData.packaged.reductions.other.plantLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.other.extractsTHC, 2, reportData.packaged.reductions.other.extractLedgers, reportData.packaged.reductions.other.extractLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.other.edibleTHC, 2, reportData.packaged.reductions.other.edibleLedgers, reportData.packaged.reductions.other.edibleLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packaged.reductions.other.topicalTHC, 3, reportData.packaged.reductions.other.topicalLedgers, reportData.packaged.reductions.other.topicalLedgersNeg)}
                                                                    </TableCell>
                                                                </TableRow>
                                                            }
                                                            {reportData.packaged.reductions.total != null &&
                                                                <TableRow>
                                                                    <TableCell sx={cellStyle}><strong>{t('totalReductions')}</strong></TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        <NumericFormat value={reportData.packaged.reductions.total.flower } 
                                                                        displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        <NumericFormat value={reportData.packaged.reductions.total.seeds } 
                                                                        displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        <NumericFormat value={reportData.packaged.reductions.total.plants } 
                                                                        displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        <NumericFormat value={reportData.packaged.reductions.total.extractsTHC } 
                                                                        displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        <NumericFormat value={reportData.packaged.reductions.total.edibleTHC } 
                                                                        displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        <NumericFormat value={reportData.packaged.reductions.total.topicalTHC } 
                                                                        displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                                    </TableCell>
                                                                </TableRow>
                                                            }
                                                            {reportData.packagedAdjustments != null &&
                                                                <TableRow>
                                                                     <TableCell sx={cellStyle}><strong>{t('inventoryAdjustments')}</strong></TableCell>
                                                                     <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packagedAdjustments.flower, 3, reportData.packagedAdjustments.flowerLedgers, reportData.packagedAdjustments.flowerLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packagedAdjustments.seeds, 0, reportData.packagedAdjustments.seedLedgers, reportData.packagedAdjustments.seedLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packagedAdjustments.plants, 0, reportData.packagedAdjustments.plantLedgers, reportData.packagedAdjustments.plantLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packagedAdjustments.extractsTHC, 2, reportData.packagedAdjustments.extractLedgers, reportData.packagedAdjustments.extractLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packagedAdjustments.edibleTHC, 2, reportData.packagedAdjustments.edibleLedgers, reportData.packagedAdjustments.edibleLedgersNeg)}
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        {this.displayValue(reportData.packagedAdjustments.topicalTHC, 3, reportData.packagedAdjustments.topicalLedgers, reportData.packagedAdjustments.topicalLedgersNeg)}
                                                                    </TableCell>
                                                                </TableRow>
                                                            }
                                                            {reportData.packaged.closingInventory != null &&
                                                                <TableRow>
                                                                    <TableCell sx={cellStyle}><strong>{t('closingInventory')}</strong></TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        <NumericFormat value={reportData.packaged.closingInventory.flower } 
                                                                        displayType={'text'} thousandSeparator={true} decimalScale={3} fixedDecimalScale={true}/>
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        <NumericFormat value={reportData.packaged.closingInventory.seeds } 
                                                                        displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        <NumericFormat value={reportData.packaged.closingInventory.plants } 
                                                                        displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        <NumericFormat value={reportData.packaged.closingInventory.extractsTHC } 
                                                                        displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        <NumericFormat value={reportData.packaged.closingInventory.edibleTHC } 
                                                                        displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={cellStyle}>
                                                                        <NumericFormat value={reportData.packaged.closingInventory.topicalTHC } 
                                                                        displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                                    </TableCell>
                                                                </TableRow>
                                                            }
                                                        </Aux>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Aux>
                                }
                                {reportData.stamps != null && reportData.stamps.length > 0 &&
                                    <Aux>
                                        <br/>
                                        <br/>
                                        <SubSectionHeading title={t('exciseInventory')}/>
                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="b330 stamps">
                                                <TableBody>
                                                    <TableRow key="stampHead">
                                                        <TableCell sx={cellStyle}><strong>{t('jurisdiction')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('openingInventory')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('stampsReceived')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('stampsUsed')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('unusable')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('adjustment')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('closingInventory')}</strong></TableCell>
                                                    </TableRow>
                                                    {reportData.stamps.map((row, i) =>
                                                        <TableRow key={'stamps' + i}>
                                                            <TableCell sx={cellStyle}>{row.prov != null ? row.prov.name : ''}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.openingInventory}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(row.received, 0, row.receivedLedgers)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(row.used, 0, row.usedLedgers)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(row.unusable, 0, row.unusableLedgers)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                {this.displayValue(row.adjustments, 0, row.adjustmentsLedgers)}
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.closingInventory}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={0}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Aux>
                                }
                                {reportData.flowerDuties != null && reportData.flowerDuties.length > 0 &&
                                    <Aux>
                                        <br/>
                                        <br/>
                                        <SubSectionHeading title={t('driedFresh')}/>
                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="b330 flower">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}><strong>{t('provinceSale')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('salesDollars')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('salesKg')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('packagesSold')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('cannabidDuty')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('additionalDuty')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('adjustmentDuty')}</strong></TableCell>
                                                    </TableRow>
                                                    {reportData.flowerDuties.map((row, i) =>
                                                        <TableRow key={'flower' + i}>
                                                            <TableCell sx={cellStyle}>{row.prov != null ? row.prov.name : ''}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.salesValue} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.salesWeight} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.packages} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.duty} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.additionalDuty} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.adjustment} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    <TableRow>
                                                        <TableCell colspan="4"></TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.flowerTotal.duty} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.flowerTotal.additionalDuty} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.flowerTotal.adjustment} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Aux>
                                }
                                {reportData.seedDuties != null && reportData.seedDuties.length > 0 &&
                                    <Aux>
                                        <br/>
                                        <br/>
                                        <SubSectionHeading title={t('plantSeeds')}/>
                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="b330 seeds">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}><strong>{t('provinceSale')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('salesDollars')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('salesSeeds')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('packagesSold')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('cannabidDuty')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('additionalDuty')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('adjustmentDuty')}</strong></TableCell>
                                                    </TableRow>
                                                    {reportData.seedDuties.map((row, i) =>
                                                        <TableRow key={'seeds' + i}>
                                                            <TableCell sx={cellStyle}>{row.prov != null ? row.prov.name : ''}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.salesValue} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.salesWeight} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.packages} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.duty} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.additionalDuty} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.adjustment} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    <TableRow>
                                                        <TableCell colspan="4"></TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.seedTotal.duty} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.seedTotal.additionalDuty} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.seedTotal.adjustment} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Aux>
                                }
                                {reportData.plantDuties != null && reportData.plantDuties.length > 0 &&
                                    <Aux>
                                        <br/>
                                        <br/>
                                        <SubSectionHeading title={t('cannabisPlants')}/>
                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="b330 plants">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}><strong>{t('provinceSale')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('salesDollars')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('salesPlants')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('packagesSold')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('cannabidDuty')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('additionalDuty')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('adjustmentDuty')}</strong></TableCell>
                                                    </TableRow>
                                                    {reportData.plantDuties.map((row, i) =>
                                                        <TableRow key={'plants' + i}>
                                                            <TableCell sx={cellStyle}>{row.prov != null ? row.prov.name : ''}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.salesValue} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.salesWeight} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.packages} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.duty} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.additionalDuty} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.adjustment} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    <TableRow>
                                                        <TableCell colspan="4"></TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.plantTotal.duty} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.plantTotal.additionalDuty} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.plantTotal.adjustment} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Aux>
                                }
                                {reportData.extractDuties != null && reportData.extractDuties.length > 0 &&
                                    <Aux>
                                        <br/>
                                        <br/>
                                        <SubSectionHeading title={t('cannabisExtracts')}/>
                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="b330 extracts">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}><strong>{t('provinceSale')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('salesDollars')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('salesPlants')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('packagesSold')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('cannabidDuty')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('additionalDuty')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('adjustmentDuty')}</strong></TableCell>
                                                    </TableRow>
                                                    {reportData.extractDuties.map((row, i) =>
                                                        <TableRow key={'extracts' + i}>
                                                            <TableCell sx={cellStyle}>{row.prov != null ? row.prov.name : ''}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.salesValue} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.salesWeight} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.packages} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.duty} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.additionalDuty} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.adjustment} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    <TableRow>
                                                        <TableCell colspan="4"></TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.extractTotal.duty} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.extractTotal.additionalDuty} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.extractTotal.adjustment} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Aux>
                                }
                                {reportData.edibleDuties != null && reportData.edibleDuties.length > 0 &&
                                    <Aux>
                                        <br/>
                                        <br/>
                                        <SubSectionHeading title={t('edibleCannabis')}/>
                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="b330 edibles">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}><strong>{t('provinceSale')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('salesDollars')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('salesPlants')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('packagesSold')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('cannabidDuty')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('additionalDuty')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('adjustmentDuty')}</strong></TableCell>
                                                    </TableRow>
                                                    {reportData.edibleDuties.map((row, i) =>
                                                        <TableRow key={'edibles' + i}>
                                                            <TableCell sx={cellStyle}>{row.prov != null ? row.prov.name : ''}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.salesValue} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.salesWeight} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.packages} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.duty} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.additionalDuty} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.adjustment} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    <TableRow>
                                                        <TableCell colspan="4"></TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.edibleTotal.duty} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.edibleTotal.additionalDuty} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.edibleTotal.adjustment} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Aux>
                                }
                                {reportData.topicalDuties != null && reportData.topicalDuties.length > 0 &&
                                    <Aux>
                                        <br/>
                                        <br/>
                                        <SubSectionHeading title={t('cannabisTopicals')}/>
                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="b330 topicals">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={cellStyle}><strong>{t('provinceSale')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('salesDollars')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('salesPlants')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('packagesSold')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('cannabidDuty')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('additionalDuty')}</strong></TableCell>
                                                        <TableCell sx={cellStyle}><strong>{t('adjustmentDuty')}</strong></TableCell>
                                                    </TableRow>
                                                    {reportData.topicalDuties.map((row, i) =>
                                                        <TableRow key={'edibles' + i}>
                                                            <TableCell sx={cellStyle}>{row.prov != null ? row.prov.name : ''}</TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.salesValue} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.salesWeight} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.packages} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.duty} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.additionalDuty} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                            <TableCell align="right" sx={cellStyle}>
                                                                <NumericFormat value={row.adjustment} fixedDecimalScale={true}
                                                                displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    <TableRow>
                                                        <TableCell colspan="4"></TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.topicalTotal.duty} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.topicalTotal.additionalDuty} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                        <TableCell align="right" sx={cellStyle}>
                                                            <NumericFormat value={reportData.topicalTotal.adjustment} fixedDecimalScale={true}
                                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Aux>
                                }
                            </Aux>
                        }
                            {this.state.totalDutyPayable != null && this.state.totalDutyPayable !== '' &&
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <SubSectionHeading title={t('totalNetAmount')}/>
                                    </Grid>
                                    <Grid item container spacing={0}>
                                        <Grid item sm={8}>
                                            <b>{t('cannabisDutyPayable')}</b>
                                        </Grid>
                                        <Grid item xs={2} sm={1} sx={{ border: "1px solid grey", padding:"5px" }} align="right">
                                            <NumericFormat value={reportData.totalDutyPayable} 
                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={0}>
                                        <Grid item sm={8}>
                                            <b>{t('additionalDutyPayable')}</b>
                                        </Grid>
                                        <Grid item xs={2} sm={1} sx={{ border: "1px solid grey", padding:"5px" }} align="right">
                                            <NumericFormat value={reportData.totalAdditionalDutyPayable} 
                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={0}>
                                        <Grid item sm={8}>
                                            <b>{t('adjustmentDutyPayable')}</b>
                                        </Grid>
                                        <Grid item xs={2} sm={1} sx={{ border: "1px solid grey", padding:"5px" }} align="right">
                                            <NumericFormat value={reportData.totalDutyAdjustments} 
                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={0}>
                                        <Grid item sm={8}>
                                            <b>{t('total')}</b>
                                        </Grid>
                                        <Grid item xs={2} sm={1} sx={{ border: "1px solid grey", padding:"5px" }} align="right">
                                            <NumericFormat value={reportData.grossTotalDuty} 
                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={0}>
                                        <Grid item sm={8}>
                                            <b>{t('refund')}</b>
                                        </Grid>
                                        <Grid item xs={2} sm={1} sx={{ border: "1px solid grey", padding:"5px" }} align="right">
                                            <NumericFormat value={reportData.refund} 
                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={0}>
                                        <Grid item sm={8}>
                                            <b>{t('netAmount')}</b>
                                        </Grid>
                                        <Grid item xs={2} sm={1} sx={{ border: "1px solid grey", padding:"5px" }} align="right">
                                            <NumericFormat value={reportData.netDutyAmount} 
                                            displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }  
                        <br/>
                        <br/>
                        {reportData.totalDutyPayable != null &&
                            <Grid container spacing={3}>
                                <Grid item xs={8} sm={6} align="right">
                                    <strong>{t('totalDutyPayable')}</strong>
                                </Grid>
                                <Grid item xs={4} sm={2} align="right">
                                    <NumericFormat value={reportData.totalDutyPayable} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                </Grid>
                            </Grid>    
                        }      
                        {reportData.totalAdditionalDuty != null &&
                            <Grid container spacing={3}>
                                <Grid item xs={8} sm={6} align="right">
                                    <strong>{t('additionalDutyPayable')}</strong>
                                </Grid>
                                <Grid item xs={4} sm={2} align="right">
                                    <NumericFormat value={reportData.totalAdditionalDuty} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                </Grid>
                            </Grid>
                        }   
                        {reportData.totalDutyAdjustments != null &&
                            <Grid container spacing={3}>
                                <Grid item xs={8} sm={6} align="right">
                                    <strong>{t('adjustmentDutyPayable')}</strong>
                                </Grid>
                                <Grid item xs={4} sm={2} align="right">
                                    <NumericFormat value={reportData.totalDutyAdjustments} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                </Grid>
                            </Grid>
                        }
                        {reportData.grossTotalDuty != null &&
                            <Grid container spacing={3}>
                                <Grid item xs={8} sm={6} align="right">
                                    <strong>{t('total')}</strong>
                                </Grid>
                                <Grid item xs={4} sm={2} align="right">
                                    <NumericFormat value={reportData.grossTotalDuty} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                </Grid>
                            </Grid>
                        } 
                        {reportData.refund != null &&
                            <Grid container spacing={3}>
                                <Grid item xs={8} sm={6} align="right">
                                    <strong>{t('refund')}</strong>
                                </Grid>
                                <Grid item xs={4} sm={2} align="right">
                                    <NumericFormat value={reportData.refund} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                </Grid>
                            </Grid>
                        }   
                        {reportData.netDutyAmount != null &&
                            <Grid container spacing={3}>
                                <Grid item xs={8} sm={6} align="right">
                                    <strong>{t('netAmount')}</strong>
                                </Grid>
                                <Grid item xs={4} sm={2} align="right">
                                    <NumericFormat value={reportData.grossTotalDuty} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                </Grid>
                            </Grid>
                        }   
                    </TabPanel>
                    {this.state.taxLines != null && this.state.taxLines.length > 0 &&
                        <TabPanel key={ctls != null ? 3 : 1} value={this.state.tabKey} index={ctls != null ? 3 : 1}>
                            <TableContainer component={Paper}>
                                <Table aria-label="tax lines">
                                    <TableHead>
                                        <TableRow key={'taxLinesHeader'}>
                                            <TableCell>{t('shipment')}</TableCell>
                                            <TableCell>{t('sale')}</TableCell>
                                            <TableCell>{t('prov')}</TableCell>
                                            <TableCell>{t('date')}</TableCell>
                                            <TableCell>{t('sku')}</TableCell>
                                            <TableCell>{t('taxType')}</TableCell>
                                            <TableCell align="center">{t('unitPrice')}</TableCell>
                                            <TableCell align="center">{t('quantity')}</TableCell>
                                            <TableCell align="center">{t('units')}</TableCell>
                                            <TableCell align="center">{t('unitAmount')}</TableCell>
                                            <TableCell align="center">{t('totalQuantity')}</TableCell>
                                            <TableCell align="center">{t('value')}</TableCell>
                                            <TableCell align="center">{t('adValorem')}</TableCell>
                                            <TableCell align="center">{t('baseAmount')}</TableCell>
                                            <TableCell align="center">{t('duty')}</TableCell>
                                            <TableCell align="center">{t('additional')}</TableCell>
                                            <TableCell align="center">{t('adjusted')}</TableCell>
                                            <TableCell align="center">{t('total')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.taxLines.map((row, i) => 
                                            <TableRow key={row._id}>
                                                <TableCell>
                                                    {row.shipment != null &&
                                                        <Link to={'/shipment/' + row.shipment._id}>{row.shipment != null ? row.shipment.number : ''}</Link>
                                                    }
                                                </TableCell>
                                                <TableCell><Link to={'/sale/' + row.sale._id}>{row.sale != null ? row.sale.number : ''}</Link></TableCell>
                                                <TableCell>{row.prov != null ? row.prov.code : ''}</TableCell>
                                                <TableCell>
                                                    <DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/>
                                                </TableCell>
                                                <TableCell>
                                                    <Link to={'/item/' + row.item._id}>{row.item.sku != null && row.item.sku !== '' ? row.item.sku : row.item.name}</Link>
                                                </TableCell>
                                                <TableCell>{row.taxType}</TableCell>
                                                <TableCell align="right">
                                                    <NumericFormat value={row.unitPrice} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumericFormat value={row.quantity} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell>
                                                    <NumericFormat value={row.units} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={false}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumericFormat value={row.unitGrams != null ? row.unitGrams : row.unitThcMg != null ? row.unitThcMg : ''} 
                                                    displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumericFormat value={row.totalGrams != null ? row.totalGrams : row.totalThcMg != null ? row.totalThcMg : ''} 
                                                    displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumericFormat value={row.grossValue} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.adValorem}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumericFormat value={row.baseAmount} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumericFormat value={row.regularDuty} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumericFormat value={row.additionalDuty} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumericFormat value={row.adjustedDuty} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumericFormat value={row.totalDuty} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}/>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <Tooltip title={t('exportToCsv')} >
                                            <IconButton
                                                disabled={false}
                                                component={'div'}
                                                onClick={this.taxCsvClick}
                                                name='actionCsv'
                                            >
                                                <ExcelIcon fontSize='medium'/>
                                                </IconButton>
                                        </Tooltip>
                                        <TablePagination count={this.state.allTaxLines.length}
                                        rowsPerPage={this.state.taxListSize}
                                        onPageChange={this.handleChangePage}
                                        page = {this.state.taxListPage}
                                        rowsPerPageOptions={common.rowOptions}
                                        onRowsPerPageChange={this.handleRowsPerPage}/>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                            {this.state.csvData != null &&
                                <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.headers} separator={this.state.separator}/>
                            }
                        </TabPanel>
                    }
                    </Aux>
                }
                <ModalDialog buttonStack={(<Aux>
                        <Button variant="outlined" color="primary" size="small" onClick={this.ledgersCsv} startIcon={<ExcelIcon />}>{t('ledgers')}</Button>
                        {this.state.negIds != null && this.state.negIds.length > 0 &&
                             <Button variant="outlined" color="primary" size="small" onClick={this.negativeCsv} startIcon={<ExcelIcon />}>{t('negative')}</Button>
                        }
                        <Button color="primary" size="small" variant='text' onClick={this.toggleLedgers}>{t('close')}</Button>
                    </Aux>
                    )} title={t('itemLedgers')} toggleDialog={this.toggleLedgers}
                                dialogStatus={this.state.showLedgers} fullWidth maxWidth='lg'>
                    <Aux>
                        <TableContainer component={Paper}>
                            <Table aria-label="item ledgers">
                                <TableHead>
                                    <TableRow key={'itemLedgersHeader'}>
                                        <TableCell>{t('number')}</TableCell>
                                        <TableCell>{t('postingDate')}</TableCell>
                                        <TableCell>{t('item')} </TableCell>
                                        <TableCell>{t('category')}</TableCell>
                                        <TableCell>{t('entryType')}</TableCell>
                                        <TableCell>{t('quantity')}</TableCell>
                                        <TableCell>{t('source')}</TableCell>
                                        <TableCell>{t('updated')}</TableCell>
                                        <TableCell>{t('reason')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.ledgers.map((row, i) =>
                                        <TableRow key={row._id}>
                                            <TableCell>{row.number}</TableCell>
                                            <TableCell><DateDisplay value={row.postingDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                            <TableCell>{row.item.number + '-' + row.item.name}</TableCell>
                                            <TableCell>{row.category}</TableCell>
                                            <TableCell>{row.entryType}</TableCell>
                                            <TableCell>
                                                <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}
                                                suffix={row.quantityUOM != null ? row.quantityUOM.label : ''} decimalScale={3}/>
                                            </TableCell>
                                            <TableCell>
                                                {row.objectType != null && row.record != null ?
                                                    row.objectType.text + '-' + row.record.number : ''
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {row.lastUpdated != null && <DateDisplay value={row.lastUpdated} format={this.props.auth.common.dateTimeFormat}/> }
                                            </TableCell>
                                            <TableCell>{row.reason != null ? row.reason : ''}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {this.state.negLedgers != null && this.state.negLedgers.length > 0 &&
                            <Aux>
                                <br/>
                                <br/>
                                <SectionTitle title={t('negative')} />
                                <TableContainer compnent={Paper}>
                                    <Table aria-label="negative ledgers">
                                        <TableHead>
                                            <TableRow key={'negItemLedgersHeader'}>
                                                <TableCell>{t('number')}</TableCell>
                                                <TableCell>{t('postingDate')}</TableCell>
                                                <TableCell>{t('item')} </TableCell>
                                                <TableCell>{t('category')}</TableCell>
                                                <TableCell>{t('entryType')}</TableCell>
                                                <TableCell>{t('quantity')}</TableCell>
                                                <TableCell>{t('source')}</TableCell>
                                                <TableCell>{t('updated')}</TableCell>
                                                <TableCell>{t('reason')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.negLedgers.map((row, i) => 
                                                <TableRow key={row._id}>
                                                    <TableCell>{row.number}</TableCell>
                                                    <TableCell><DateDisplay value={row.postingDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                                    <TableCell>{row.item.number + '-' + row.item.name}</TableCell>
                                                    <TableCell>{row.category}</TableCell>
                                                    <TableCell>{row.entryType}</TableCell>
                                                    <TableCell>
                                                        <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}
                                                        suffix={row.quantityUOM != null ? row.quantityUOM.label : ''} decimalScale={3}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.objectType != null && row.record != null ?
                                                            row.objectType.text + '-' + row.record.number : ''
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.lastUpdated != null && <DateDisplay value={row.lastUpdated} format={this.props.auth.common.dateTimeFormat}/> }
                                                    </TableCell>
                                                    <TableCell>{row.reason != null ? row.reason : ''}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Aux>
                        }
                    </Aux>
                    {this.state.csvData != null &&
                        <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.headers} separator={this.state.separator}/>
                    }
                </ModalDialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(HCReport));
