import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Signature from '../General/Signature';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import Document from '../General/Document';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SaveButton } from '../UI/Buttons/Buttons';
import Typography from '@mui/material/Typography';
import Unlock from '../General/Unlock';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import ModalDialog from '../UI/Dialog/ModalDialog';
import ConfirmDelete from '../General/ConfirmDelete';

class CapaAction extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            location: '',
            number: '',
            status: '',
            statusSel: null,
            statuses: [],
            title: '',
            details: '',
            created: '',
            dueDate: '',
            completed: '',
            assignee: '',
            assigneeSel: null,
            users: [],
            usersSel: [],
            completionDetails: '',
            ncr: null,
            deviation: null,
            employeeIllness: null,
            incident: null,
            loss: null,
            injuryHazardous: null,
            customerComplaint: null,
            car: null,
            recal: null,
            documents: [],
            user: null,
            reviewer: null,
            signer: null,
            userList: [],
            qas: [],
            managers: [],
            security: [],
            onClose: this.props.onClose,
            openStatus: this.props.open,
            signed: false,
            enableSave: true,
            deleteOpen: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.user, common.cacheValues.status, common.cacheValues.manager, common.cacheValues.qa], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let allUsers = cacheValues.users;
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        let managers = this.props.managers != null && this.props.managers.length > 0 ? this.props.managers : cacheValues.managers;
        let qas = this.props.qas != null && this.props.qas.length > 0 ? this.props.qas : cacheValues.qas;
        this.setState({
            userList: allUsers,
            qas: qas,
            statuses: cacheValues.statuses,
            managers: managers,
            ncr: this.props.ncr !== '' ? this.props.ncr : null,
            deviation: this.props.deviation !== '' ? this.props.deviation : null,
            employeeIllness: this.props.employeeIllness !== '' ? this.props.employeeIllness : null,
            incident: this.props.incident !== '' ? this.props.incident : null,
            loss: this.props.loss !== '' ? this.props.loss : null,
            location: this.props.location,
            injuryHazardous: this.props.injuryHazardous !== '' ? this.props.injuryHazardous : null,
            customerComplaint: this.props.customerComplaint !== '' ? this.props.customerComplaint : null,
            car: this.props.car !== '' ? this.props.car : null,
            recall: this.props.recall !== '' ? this.props.recall : null,
            security: this.props.permission,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            _id: this.props.id,
            allUsers: allUsers
        }, () => {
            if(this.state._id != null && this.state._id !== '')
                this.loadRecord();
        });
    }

    async componentDidUpdate(prevProps){
        if(this.props.id !== prevProps.id && this.props.id != null && this.props.id !== ''){
            this.setState({
                _id: this.props.id,
                userList: this.props.users,
                qas: this.props.qas,
                managers: this.props.managers
            }, () => {this.loadRecord();});
        }
    }

    loadRecord = async () => {
        let record = await axios.get('/api/capaaction/' + this.state._id);
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let usersSel = [];
        let userList = this.state.userList;
        if(record.data.users != null && record.data.users.length > 0){
            record.data.users.forEach(row => {
                let val = this.state.userList.find(x => x.value === row);
                if(val != null){
                    usersSel.push(val);
                } else {
                    val = this.state.allUsers.find(x => x.value === row);
                    if(val != null){
                        userList.push(val);
                        usersSel.push(val);
                    }
                }
            });
        }
        let assigneeSel = this.state.userList.find(x => x.value === record.data.assignee);
        let signed = !(record.data.status === this.props.auth.constants.statuses.new || record.data.status === this.props.auth.constants.statuses.inprogress);
        this.setState({
            number: record.data.number,
            location: record.data.location,
            status: record.data.status,
            statusSel: statusSel,
            users: record.data.users,
            usersSel: usersSel,
            title: record.data.title,
            details: record.data.details,
            created: record.data.created,
            dueDate: record.data.dueDate,
            completed: record.data.completed,
            assignee: record.data.assignee,
            assigneeSel: assigneeSel,
            completionDetails: record.data.completionDetails,
            ncr: record.data.ncr,
            deviation: record.data.deviation,
            employeeIllness: record.data.employeeIllness,
            incident: record.data.incident,
            loss: record.data.loss,
            injuryHazardous: record.data.injuryHazardous,
            customerComplaint: record.data.customerComplaint,
            car: record.data.car,
            recall: record.data.recall,
            documents: record.data.documents,
            user: record.data.user,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            signed: signed,
            userList: userList,
            deleteOpen: false
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeMulti = (name, data) => (e, newValue) => {
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }            
        let data = {
            _id: this.state._id,
            number: this.state.number,
            location: this.state.location,
            status: this.state.status,
            title: this.state.title,
            details: this.state.details,
            dueDate: this.state.dueDate,
            completed: this.state.completed,
            assignee: this.state.assignee,
            users: this.state.users,
            completionDetails: this.state.completionDetails,
            ncr: this.state.ncr,
            deviation: this.state.deviation,
            employeeIllness: this.state.employeeIllness,
            incident: this.state.incident,
            loss: this.state.loss,
            injuryHazardous: this.state.injuryHazardous,
            customerComplaint: this.state.customerComplaint,
            car: this.state.car,
            recall: this.state.recall
        };
        try {
            let result = await axios.post('/api/capaaction', data);
            this.setState({_id: result.data.id, number: result.data.number, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });            
        }catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.title == null || state.title === '')
            errors[t('title')] = t('required');
        if(state.details == null || state.details === '')
            errors[t('details')] = t('required');
        if(state.dueDate == null || state.dueDate === '')
            errors[t('dueDate')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    delete = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._delete());
    }

    _delete = async () => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/capaaction/' + this.state._id);
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            } catch(err){
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        }
        this.state.onClose();
    }

    buttonStack = () => {
        const { onClose, t } = this.props;
        let buttons = [];
        if(this.state.signed) {
            buttons.push(<Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.capaAction} callback={this.loadRecord}/>);
        }
        buttons.push([
            <Button variant='text' sx={{ color: 'error.main' }} onClick={this.deleteToggle} disabled={this.state.status !== this.props.auth.constants.statuses.new} key='CAPADeleteButton' >{t('delete')}</Button>,
            <Button variant='text' sx={{ color: 'secondary.main' }} onClick={onClose} key='CAPACloseButton'>{t('close')}</Button>,
            <SaveButton saveClick={this.save} enabled={!this.state.signed && this.state.enableSave} key='CAPASaveButton' />
        ]);
        return buttons;
    }

    render(){
        const errors = this.props.errors;
        const signed = this.state.signed;
        const { onClose, open, t, } = this.props;
        return(
            <ModalDialog buttonStack={this.buttonStack()} title={t('capaAction')} toggleDialog={onClose} 
                         dialogStatus={open} fullWidth maxWidth='sm' >
                    <Grid container spacing={2}>
                        <Grid item xs={this.state._id != null && this.state._id !== '' ? 6 : 12}>
                            <FormControl>
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSel != null ? this.state.statusSel.label : ''}
                            </FormControl>
                        </Grid>
                        {this.state._id != null && this.state._id !== '' &&
                            <Grid item xs={6}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            {!signed &&
                                <TextField value={this.state.title} onChange={this.changeValue} name="title"
                                size="medium" fullWidth label={t('title')} disabled={signed} required
                                error={errors[t('title')] != null ? true : false} helperText={errors[t('title')]}/>
                            }
                            {signed &&
                                <FormControl>
                                    <FormLabel>{t('title')}</FormLabel>
                                    {this.state.title}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {!signed &&
                                <DateSelect
                                    onChange={this.dateChange('dueDate')}
                                    value={this.state.dueDate}
                                    helperText={errors[t('dueDate')]}
                                    error={errors[t('dueDate')] != null ? true : false}
                                    label={t('dueDate')}
                                    format={this.props.auth.user.dateFormat}
                                    disabled={signed}
                                    required
                                    fullWidth
                                />
                            }
                            {signed &&
                                <FormControl>
                                    <FormLabel>{t('dueDate')}</FormLabel>
                                    <DateDisplay value={this.state.dueDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        {this.state.userList != null && this.state.userList.length > 0 &&
                            <Grid item xs={12}>
                                {!signed &&
                                    <AutoCompleteField
                                        value={this.state.assigneeSel}
                                        options={this.state.userList}
                                        onChange={this.changeAuto('assigneeSel', 'assignee')}
                                        error={errors[t('assignee')] != null ? true : false}
                                        helperText={errors[t('assignee')]}
                                        label={t('assignee')}
                                        disabled={signed}
                                    />
                                }
                                {signed &&
                                    <FormControl>
                                        <FormLabel>{t('assignee')}</FormLabel>
                                        {this.state.assigneeSel != null ? this.state.assigneeSel.label : ''}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        <Grid item xs={12}>
                            {!signed &&
                                <TextField value={this.state.details} onChange={this.changeValue} name="details"
                                size="medium" fullWidth label={t('details')} disabled={signed} required multiline rows="3"
                                error={errors[t('details')] != null ? true : false} helperText={errors[t('details')]}/>
                            }
                            {signed &&
                                <FormControl>
                                    <FormLabel>{t('details')}</FormLabel>
                                    {this.state.details}
                                </FormControl>
                            }
                        </Grid>
                        {this.state._id != null && this.state._id !== '' && this.state.userList != null && this.state.userList.length > 0 &&
                            <Aux key='CAPAUserList'>
                                <Grid item xs={12}>
                                    <AutoCompleteField
                                        multiple
                                        value={this.state.usersSel}
                                        options={this.state.userList}
                                        onChange={this.changeMulti('usersSel', 'users')}
                                        error={errors[t('users')] != null ? true : false}
                                        helperText={errors[t('users')]}
                                        label={t('users')}
                                        disabled={signed}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {!signed &&
                                        <TextField value={this.state.completionDetails} onChange={this.changeValue} name="completionDetails"
                                        size="medium" fullWidth label={t('completionDetails')} disabled={signed} multiline rows="3"
                                        error={errors[t('completionDetails')] != null ? true : false} helperText={errors[t('completionDetails')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('completionDetails')}</FormLabel>
                                            {this.state.completionDetails}
                                        </FormControl>
                                    }
                                </Grid>
                            </Aux>
                        }
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux key='CAPAExistsDetails'>
                            <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.capaAction}/>
                            <ObjectLog id={this.state._id}/>
                            <Document record={this.state._id} documents={this.state.documents}
                                objectType={this.props.auth.constants.objectIds.capaAction} callback={this.loadRecord}/>
                            {this.state.status !== this.props.auth.constants.statuses.new &&
                                <Aux key='CAPACompleteSig'>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-usersig"
                                            id="panel-usersig-header"
                                        >
                                            <Typography>Completed By</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Signature users={this.state.usersSel != null && this.state.usersSel.length > 0 ? this.state.usersSel : this.state._id.allUsers} name={t('completedBy')} status={this.state.status}
                                            title={t('completedBy')} setId={this.loadRecord} object={this.state._id} signature={this.state.user}
                                            objectType={this.props.auth.constants.objectIds.capaAction} type={this.props.auth.constants.signatureTypes.user}
                                            user={this.props.auth.user.id}/>
                                        </AccordionDetails>
                                    </Accordion>
                                    {this.state.status !== this.props.auth.constants.statuses.inprogress &&
                                        <Aux key='CAPAReviewAndSignSig'>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1b-reviewsig"
                                                    id="panel-reviewsig-header"
                                                >
                                                    <Typography>{t('reviewedBy')}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Signature users={this.state.managers} name={t('reviewer')} status={this.state.status}
                                                    title={t('reviewedBy')} setId={this.loadRecord} object={this.state._id} signature={this.state.reviewer}
                                                    objectType={this.props.auth.constants.objectIds.capaAction} type={this.props.auth.constants.signatureTypes.reviewer}
                                                    user={this.props.auth.user.id}/>
                                                </AccordionDetails>
                                            </Accordion>
                                            {this.state.status !== this.props.auth.constants.statuses.complete &&
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1c-signoff"
                                                        id="panel-signoff-header"
                                                    >
                                                        <Typography>{t('approvedBy')}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Signature users={this.state.qas} name={t('signoff')} status={this.state.status}
                                                        title={t('approvedBy')} setId={this.loadRecord} object={this.state._id} signature={this.state.signer}
                                                        objectType={this.props.auth.constants.objectIds.capaAction} type={this.props.auth.constants.signatureTypes.signer}
                                                        user={this.props.auth.user.id}/>
                                                    </AccordionDetails>
                                                </Accordion>
                                            }
                                        </Aux>
                                    }
                                </Aux>
                            }
                        </Aux>
                    }
                    {this.state.deleteOpen &&
                        <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                            redirect={() => this.delete()} open={this.state.deleteOpen} />
                    }
            </ModalDialog>
        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(CapaAction));