import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import SectionTitle from '../UI/Typography/SectionTitle';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';

class RoomChanges extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            listSize: 5,
            offset: 0,
            orderBy: 'number',
            ordDir: -1,
            totalCount: 0,
            page: 0
        };
    }

    componentDidMount(){
        this.loadRecords();
    }

    loadRecords = async () => {
        var sort = {[this.state.orderBy]: this.state.ordDir};
        var data = {
            offset: this.state.offset,
            size: this.state.listSize,
            room: this.props.room,
            sort: JSON.stringify(sort)
        };
        var list = await axios.get('/api/roomchanges/', {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        })
    }

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <div>
                {this.state.list != null && this.state.list.length > 0 &&
                    <div>
                        <SectionTitle title={t('changes')}/>
                        <TableContainer component={Paper}>
                            <Table aria-label="Room Changes" size="small">
                                <TableHead>
                                    <TableRow key="tableHeader">
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('number')}
                                            active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('createdOn')}
                                            active={this.state.orderBy === 'createdOn'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('createdBy')}
                                            active={this.state.orderBy === 'createdBy'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('user')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>Approver</TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('status')}
                                            active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.list.map((row, i) =>
                                        <TableRow key={row._id}>
                                            <TableCell>
                                                <Link to={'/roomsettings/' + this.props.room + '/' + row._id}>
                                                    {row.number}
                                                </Link>
                                            </TableCell>
                                            <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={row.createdOn}/></TableCell>
                                            <TableCell>{row.createdBy.firstName + ' ' + row.createdBy.lastName}</TableCell>
                                            <TableCell>
                                                {row.reviewer != null ? row.reviewer.user.firstName + ' ' + row.reviewer.user.lastName : ''}
                                            </TableCell>
                                            <TableCell>{row.status.label}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow key="tableFooter">
                                        <TablePagination count={this.state.totalCount}
                                        rowsPerPage={this.state.listSize}
                                        onPageChange={this.handleChangePage}
                                        page = {this.state.page}
                                        rowsPerPageOptions={common.rowOptions}
                                        onRowsPerPageChange={this.handleRowsPerPage}/>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </div>
                }
                

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(RoomChanges));