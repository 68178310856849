import React from 'react';
import axios from 'axios';
import Aux from '../../hoc/Auxilary/Auxilary';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import base64 from 'base-64';
import { useDispatch } from 'react-redux';

const FileUpload = (props) => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();

    const contentFileSelect = async file => {
        let files = file.target.files;
        let reader = new FileReader();
        let filerec = files[0];
        let lastIndex = filerec.name.lastIndexOf('.');
        let docType = filerec.name.substring(lastIndex + 1);
        let upperType = docType.toUpperCase()
        if(upperType !== 'PDF' && upperType !== 'PNG' && upperType !== 'JPG' && upperType !== 'JPEG' && upperType !== 'GIF' && upperType !== 'TIFF'){
            dispatch({ type: 'CREATE_ALERT', payload: {message: t('validDocFormats'), title: t('error'), severity: 'error'}});
            return;
        }
        reader.readAsBinaryString(files[0]);
        reader.onload = async () => {
            let data = {
                file: base64.encode(reader.result),
                fileName: files[0].name,
                type: files[0].type,
                objectId: props.record,
                objectType: props.object,
                property: props.property,
                id: props._id != null ? props._id : '',
                version: props.version != null ? props.version : ''
            };
            try {
                await axios.post('/api/documents/add', data)
                props.callback();
            } catch(err){
                dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            }
        };
    }

    return(
        <Aux>
            <label htmlFor="btn-docupload">
                <input
                    id="btn-docupload"
                    name="btn-docupload"
                    style={{ display: 'none' }}
                    type="file"
                    multiple={false}
                    onChange={contentFileSelect}
                />
                <Button variant="contained" color="primary" component="span">{t('chooseFile')}</Button>
            </label>
        </Aux>
    )
}

export default FileUpload;