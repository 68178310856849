import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
//import TaskRiskAssessment from '../General/TaskRiskAssessment';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import DateTimePicker from '../UI/DateTimeSelect/DateTimeSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { StartButton, StopButton } from '../UI/Buttons/Buttons';
import { approveSigSecurity, completeSigSecurity,
         fieldSecurity, reviewSigSecurity } from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import SectionTitle from '../UI/Typography/SectionTitle';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import IconButton from '@mui/material/IconButton';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import ConversionHelper from '../../helpers/conversionHelper';

class MaintenanceRequest extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            date: new Date(),
            requestor: '',
            assignee: '',
            users: [],
            category: '',
            categories: [],
            priority: '',
            priorities: [],
            location: '',
            locations: [],
            room: '',
            rooms: [],
            equipment: '',
            equipments: [],
            status: this.props.auth.constants.statuses.new,
            statuses: [],
            statusSel: {},
            description: '',
            fixDescription: '',
            user: null,
            reviewer: null,
            documents: [],
            taskRisk: false,
            taskRisks: [],
            taskRiskAssessment: '',
            managers: [],
            signed: false,
            completedDate : '',
            completedBy: null,
            startDate: '',
            startedBy: null,
            riskAssessmentRequired: false,
            tenant:this.props.auth.user.tenant,
            number: '',
            requestorSel: null,
            completedBySel: null,
            startedBySel: null,
            catSel: null,
            prioritySel: null,
            locationSel: null,
            roomSel: null,
            equipmentSel: null,
            assigneeSel: null,
            security: [],
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            tabKey: 0,
            tabLabels: [
                { name: this.props.t('request'), disabled: false }
            ],
            optionsMenu: [],
            flagForSignature: 0,
            enableSave: true,
            sigData: null,
            activeUsers: [],
            activeManagers: [],
            form: '',
            hasForm: false,
            inputs: [],
            item: '',
            itemSel: null,
            items: [],
            container: '',
            containerSel: null,
            contaienrs: [],
            quantity: '',
            uom: '',
            uomSel: null,
            uoms: [],
            trapItems: [],
            trapItem: '',
            trapItemSel: null,
            trapDescription: '',
            changeTrap: false,
            pestTraps: [],
            trapContainer: '',
            trapContainerSel: null,
            trapContainers: [],
            trapHasContainer: false,
            deleteOpen: false,
            hasLedgers: false,
            allItems: []
        };
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.activeLocation, common.cacheValues.status, common.cacheValues.defaultRoom,
            common.cacheValues.maintenanceRequestCategory, common.cacheValues.priority, common.cacheValues.maintenanceRequestForm, common.cacheValues.item, 
            common.cacheValues.quantityUOM, common.cacheValues.pestTrapItem, common.cacheValues.allItems], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.maintenanceRequest, this.props.auth.user.tenant);
        const requestorSel = userValues.create.find(x => x.value === this.props.auth.user.id);
        const statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
            this.props.auth.user.defaultLocation : '';
        let locSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locSel == null){
            if(locations.length === 1){
                locSel = locations[0];
                location = locSel.value;
            }
        }
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        this.setState({
            items: cacheValues.items,
            allItems: cacheValues.allItems,
            uoms: cacheValues.quantityUOMs,
            users: userValues.create,
            categories: cacheValues.maintenanceRequestCategories,
            priorities: cacheValues.priorities,
            locations: locations,
            location: location,
            locationSel: locSel,
            rooms: cacheValues.rooms,
            statuses: cacheValues.statuses,
            requestor: this.props.auth.user.id,
            requestorSel: requestorSel,
            managers: userValues.review,
            statusSel: statusSel,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            activeUsers: activeUsers,
            activeManagers: activeManagers,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            trapItems: cacheValues.pestTrapItems,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null,
            trapContainers: false
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/maintenancerequests', label: this.props.t('serviceRequests'), screen: this.props.auth.screenDefs.MaintenanceRequest},
                    { path: 'active', label: this.props.t('serviceRequest'), screen: this.props.auth.screenDefs.MaintenanceRequest}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });

    }

    loadRecord = async () => {
        const internal = this.props.auth.user.internal;
        const record = await axios.get('/api/maintenancerequest/' + this.state._id);
        const locationSel = this.state.locations.find(x => x.value === record.data.location);
        const statusSel = this.state.statuses.find(x => x.value === record.data.status);
        const catSel = this.state.categories.find(x => x.value === record.data.category);
        const prioritySel = this.state.priorities.find(x => x.value === record.data.priority);
        const roomSel = this.state.rooms.find(x => x.value === record.data.room);
        const equipmentSel = record.data.equipment != null ? record.data.equipments.find(x => x.value === record.data.equipment) : null;
        let pestTraps = record.data.pestTraps != null ? record.data.pestTraps : [];
        let trapHasContainer = false;
        if(pestTraps != null && pestTraps.length > 0){
            for(let row of pestTraps){
                let itemSel = this.state.trapItems.find(x => x.value === row.item);
                row.itemSel = itemSel;
                if(itemSel.lotTracked === true){
                    trapHasContainer = true;
                }
                let containerSel = null;
                if(row.container != null && row.containers != null && row.containers.length > 0){
                    containerSel = row.containers.find(x => x.value === row.container);
                    row.containerSel = containerSel;
                }
            }
        }
        const requestorSel = this.state.users.find(x => x.value === record.data.requestor);
        const assigneeSel = this.state.users.find(x => x.value === record.data.assignee);
        const completedBySel = this.state.users.find(x => x.value === record.data.completedBy);
        const startedBySel = this.state.users.find(x => x.value === record.data.startedBy);
        let inputs = record.data.inputs != null && record.data.inputs.length > 0 ? record.data.inputs : [];
        for(let row of inputs){
            let itemSel = this.state.allItems.find(x => x.value === row.item);
            row.itemSel = itemSel;
            let uomSel = this.state.uoms.find(x => x.value === row.uom);
            row.uomSel = uomSel;
            row.containerSel = row.containers.find(x => x.value === row.container);
        }
        const signed = record.data.status === this.props.auth.constants.statuses.complete ||
            record.data.status === this.props.auth.constants.statuses.reviewed ||
        record.data.status === this.props.auth.constants.statuses.signed;
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, null, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        if(record.data.riskAssessmentRequired) this.openRiskAssessment();
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: !internal && this.state.user == null ? this.state.activeUsers : this.state.users,
                name: this.props.t('complete'), title: this.props.t('completedBy'), setId: () => this.loadRecord(),  signature: record.data.user, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.maintenanceRequest, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, saveCallback: this.saveCallback
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: !internal && this.state.reviewer == null ? this.state.activeManagers : this.state.managers, name: this.props.t('review'),
                title: this.props.t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.maintenanceRequest, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 3 : 0;
        this.setState({
            _id: record.data._id,
            date: record.data.date,
            requestor: record.data.requestor,
            assignee: record.data.assignee,
            category: record.data.category,
            priority: record.data.priority,
            location: record.data.location,
            room: record.data.room,
            equipment: record.data.equipment,
            status: record.data.status,
            description: record.data.description,
            fixDescription: record.data.fixDescription,
            user: record.data.user,
            reviewer: record.data.reviewer,
            documents: record.data.documents,
            taskRiskAssessment: record.data.taskRiskAssessment,
            locationSel: locationSel,
            statusSel: statusSel,
            catSel: catSel,
            prioritySel: prioritySel,
            roomSel: roomSel,
            equipmentSel: equipmentSel,
            equipments: record.data.equipments,
            requestorSel: requestorSel,
            assigneeSel: assigneeSel,
            completedDate: record.data.completedDate,
            completedBy: record.data.completedBy,
            completedBySel: completedBySel,
            startDate: record.data.startDate,
            startedBy: record.data.startedBy,
            startedBySel: startedBySel,
            riskAssessmentRequired: record.data.riskAssessmentRequired,
            signed: signed,
            number: record.data.number,
            fieldAccess: fieldAccess,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            sigData: null,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            inputs: inputs,
            pestTraps: pestTraps,
            trapHasContainer: trapHasContainer,
            deleteOpen: false,
            hasLedgers: record.data.hasLedgers
        }, () => {
            const crumbs = [
                { path: '/#/maintenancerequests', label: this.props.t('serviceRequests'), screen: this.props.auth.screenDefs.MaintenanceRequest},
                { path: 'active', label: this.props.t('serviceRequest') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.MaintenanceRequest}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: this.props.t('options')}});
        });
    };

    changeSelect = (name, data) => value => {
        let taskRisk = this.state.taskRisk;
        if(data === 'taskRiskAssessment'){
            if (value.value != null && value.value !== "")
                taskRisk = true;
        }
        this.setState({[name]: value, [data]: value.value, taskRisk: taskRisk});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        let taskRisk = this.state.taskRisk;
        let equipments = this.state.equipments;
        if(data === 'taskRiskAssessment'){
            if (newValue.value != null && newValue.value !== "")
                taskRisk = true;
                this.setState({taskRisk: taskRisk});
        } else if(data === 'room'){
            if(newValue != null){
                let equipRes = await axios.get('/api/equipmentselect/', {params: {room: newValue.value}});
                equipments = equipRes.data;
                let pestTraps = [];
                if(newValue.pestTraps != null && newValue.pestTraps.length > 0){
                    pestTraps = newValue.pestTraps != null ? newValue.pestTraps : [];
                    for(let row of pestTraps){
                        let itemSel = this.state.trapItems.find(x => x.value === row.item._id);
                        row.itemSel = itemSel;
                        row.changeTrap = false;
                        row.trapId = row._id;
                    }
                }
                this.setState({equipments: equipments, equipment: '', equipmentSel: null, pestTraps: pestTraps})
            } else {
                this.setState({equipments: [], equipment: '', equipmentSel: null, pestTraps: []})
            }
        } else if(data === 'item'){
            if(newValue != null){
                let body = {positive: true, items: [newValue.value], available: true, consume: true};
                let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                let containers = [];
                if(newValue.lotTracked === true){
                    let contRes = await axios.post('/api/itemcontainers', body);
                    containers = contRes.data;
                }
                this.setState({containers: containers, uom: newValue.uom, uomSel: uomSel,});
            } else {
                this.setState({container: '', containerSel: null, containers: [], uom: '', uomSel: null});
            }
        } else if(data === 'trapItem'){
            if(newValue != null){
                let trapHasContainer = this.state.trapHasContainer;
                if(newValue.lotTracked === true){
                    trapHasContainer = true;
                    let containers = [];
                    if(newValue.lotTracked === true){
                        let body = {positive: true, items: [newValue.value], available: true, consume: true};
                        let contRes = await axios.post('/api/itemcontainers', body);
                        containers = contRes.data;
                    }
                    this.setState({trapContainers: containers, trapContainer: '', trapContainerSel: null, trapHasContainer: trapHasContainer});
                } else {
                    let trapHasContainer = false;
                    for(let row of this.state.pestTraps){
                        if(row.itemSel.lotTracked === true){
                            trapHasContainer = true;
                            break;
                        }
                    }
                    this.setState({trapContainers: [], trapContainer: '', trapContainerSel: null, trapHasContainer: trapHasContainer});
                }
            }
        } else if (data === 'trapContainer'){
            if(newValue != null){
                this.setState({changeTrap: true});
            }
        }
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    dateChange = e => {
        this.setState({date: e});
    };

    startTimer = e => {
        const user = this.props.auth.user.id;
        const startedBySel = this.state.users.find(x => x.value === user);
        const statusSel = this.state.statuses.find(x => x.value === this.props.auth.constants.statuses.inprogress);
        this.setState({
            startDate: new Date(),
            startedBy: user,
            startedBySel: startedBySel,
            status: this.props.auth.constants.statuses.inprogress,
            statusSel: statusSel
        }, () => {
            this.save(e);
        });
    };

    stopTimer = e => {
        const valid = this.validate();
        if(!valid) return;

        const user = this.props.auth.user.id;
        const completedBySel = this.state.users.find(x => x.value === user);
        this.setState({
            completedDate: new Date(),
            completedBy: user,
            completedBySel: completedBySel,
        }, () => {
            this.save(e);
        });
    };

    openRiskAssessment() {
        const tabLabels = this.state.tabLabels;
        const index = tabLabels.findIndex(x => x.name === this.props.t('riskAssessment'));
        tabLabels[index].disabled = false;
        this.setState({
            tabLabels: tabLabels
        });
    }

    toggleRiskAssessmentTab() {
        const tabLabels = this.state.tabLabels;
        const index = tabLabels.findIndex(x => x.name === this.props.t('riskAssessment'));
        tabLabels[index].disabled = !tabLabels[index].disabled;
        this.setState({
            tabLabels: tabLabels
        });
    }

    riskAssChanged = e => {
        this.setState({
            riskAssessmentRequired: !this.state.riskAssessmentRequired
        });
        this.toggleRiskAssessmentTab();
    };

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.save()})
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let status = this.state.status;
        let docs = [];
        let date = this.state.date;

        if (date === '' || date === null) date = new Date();
        this.state.documents.forEach(doc => {
            docs.push(doc._id);
        });
        let pestTraps = this.state.pestTraps;
        const data = {
            _id: this.state._id,
            date: date,
            category: this.state.category,
            priority: this.state.priority,
            requestor: this.state.requestor,
            assignee: this.state.assignee,
            location: this.state.location,
            room: this.state.room,
            equipment: this.state.equipment,
            status: status,
            description: this.state.description,
            fixDescription: this.state.fixDescription,
            documents: docs,
            completedDate: this.state.completedDate,
            completedBy: this.state.completedBy,
            startDate: this.state.startDate,
            startedBy: this.state.completedBy,
            riskAssessmentRequired: this.state.riskAssessmentRequired,
            sigData: this.state.sigData,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            inputs: this.state.inputs,
            pestTraps: pestTraps
        };
        try {
            const result = await axios.post('/api/maintenancerequest', data);
            const statusSel = this.state.statuses.find(x => x.value === result.data.status);
            const completeSigSec = completeSigSecurity(this.props.permission.complete, result.data.receiver, result.data.status, this.props.auth.common, this.props.auth.constants);
            const reviewSigSec = reviewSigSecurity(this.props.permission.review, result.data.reviewer, result.data.status, this.props.auth.common, this.props.auth.constants);
            const approveSigSec = approveSigSecurity(this.props.permission.approve, result.data.signer, result.data.status, this.props.auth.common, this.props.auth.constants);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/maintenancerequest/' + result.data.id);
            this.setState({
                _id: result.data.id,
                number: result.data.number,
                date: date,
                status: result.data.status,
                statusSel: statusSel,
                completeSigSec: completeSigSec,
                reviewSigSec: reviewSigSec,
                approveSigSec: approveSigSec,
                enableSave: true
            }, () => {
                this.loadRecord();
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    };

    validate = e => {
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.date == null || state.date === ''){
            errors[t('date')] = t('required');
        } else {
            let validEndDate = ConversionHelper.validActivityDate(this.state.date, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
            if(!validEndDate){
                errors[t('date')] = t('dateBeforeLock');
            }
        }
        if(state.requestor == null || state.requestor === '')
            errors[t('requestor')] = t('required');
        if(state.category == null || state.category === '')
            errors[t('category')] = t('required');
        if(state.priority == null || state.priority === '')
            errors[t('priority')] = t('required');
        if(state.description == null || state.description === '')
            errors[t('description')] = t('required');
        if(state.status !== this.props.auth.constants.statuses.new &&
           (state.fixDescription === null || state.fixDescription === ''))
            errors[t('fixDescription')] = t('required')
        if((this.state.item != null && this.state.item !== '') || (this.state.quantity != null && this.state.quantity !== '') ||
        (this.state.container != null && this.state.container !== '')){
            errors[t('item')] = t('rowNotAdded');
        }
        let i = 0;
        for(let row of state.inputs){
            if(row.quantity == null || row.quantity === '' || row.quantity === 0 || row.quantity === '0'){
                errors[`${t('quantity')} (${t('line')} ${i})`] = t('required');
            } else if(row.quantity != null && row.quantity !== ''){
                if(row.containerSel == null || row.itemSel.lotTracked === false){
                    if(parseFloat(row.quantity) > parseFloat(row.itemSel.inventory))
                        errors[`${t('quantity')} (${t('line')} ${i})`] = t('moreThanInventory');
                } else if(row.containerSel != null && row.itemSel.lotTracked === true){
                    if(parseFloat(row.quantity) > parseFloat(row.containerSel.quantity)){
                        errors[`${t('quantity')} (${t('line')} ${i})`] = t('moreThanContainer');
                    }
                }
            }
            i++;
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._deleteClick());
    };

    _deleteClick = async () => {
        const constants = this.props.auth.constants;
        if(this.state._id == null || this.state._id === ''){
            this.props.history.goBack();
            return;
        }
        if(this.state.status === constants.statuses.new || this.state.status === constants.statuses.inprogress){
            try {
                let result = await axios.delete('/api/maintenancerequest/' + this.state._id);
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            } finally {
                this.setState({enableSave: true, deleteOpen: false});
            }
        } else {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: this.props.t('deleteStatusInvalid'), title: this.props.t('warning'), severity: 'warning'}});
            this.setState({enableSave: true, deleteOpen: false});
            return;
        };
    }

    refresh = e => {
        this.loadRecord();
    };

    setRiskAssessment = async (riskAssessment) => {
        if(this.state._id !== null || this.state._id !== '') {
            try {
                const result = await axios.post('/api/maintenancerequestrisk', {_id: this.state._id, riskAssessment: riskAssessment});
                if(this.state.taskRiskAssessment == null || this.state.taskRiskAssessment === ''){
                    this.setState({ taskRiskAssessment: result.data.id });
                }
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            } catch(err) {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            }
        } else {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: this.props.t('saveRecord'), title: this.props.t('error'), severity: 'error'}});
        }
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    }

    saveInput = (e) => {
        const valid = this.validateInput();
        let inputs = this.state.inputs;
        if(!valid) return;
        inputs.push({
            item: this.state.item,
            itemSel: this.state.itemSel,
            quantity: this.state.quantity,
            uom: this.state.uom,
            uomSel: this.state.uomSel,
            container: this.state.container,
            containerSel: this.state.containerSel
        })
         this.setState({
             inputs: inputs,
             item: '',
             itemSel: null,
             quantity: '',
             uom: '',
             uomSel: null,
             container: '',
             containerSel: null
         });
    }

    validateInput(){
        let errors = {};
        const t = this.props.t;
        if(this.state.item == null || this.state.item === '')
            errors[t('item')] = t('required');
        if(this.state.quantity == null || this.state.quantity === '')
            errors[t('quantity')] = t('required');
        if(this.state.itemSel != null && this.state.itemSel !== '' && this.state.itemSel.lotTracked === false){
            if(parseInt(this.state.quantity) > this.state.itemSel.inventory)
                errors[t('quantity')] = t('moreThanInventory');
        }
        if(this.state.itemsel != null && this.state.itemSel.lotTracked){
            if(this.state.container == null || this.state.container === ''){
                errors[t('container')] = t('required');
            }
            if(parseFloat(this.state.quantity) > parseFloat(this.state.containerSel.quantity)){
                errors[t('quantity')] = t('moreThanContainer');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeLineValue = (name, index) => (e) => {
        let inputs = this.state.inputs;
        let row = inputs[index];
        row[name] = e.target.value;
        this.setState({inputs: inputs});
    };

    changeLineAuto = (name, data, index) => async (e, newValue) => {
        let inputs = this.state.inputs;
        let row = inputs[index];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : null;
        if(data === 'item' || data === 'container'){
            row.reservation = null;
        }
        if(data === 'item' && newValue != null){
            let body = {positive: true, items: [newValue.value], available: true, consume: true};
            let contRes = await axios.post('/api/itemcontainers', body);
            row.containers = contRes.data;
        }
        this.setState({inputs: inputs});
    };

    deleteInput = (index) => (e) => {
        let inputs = this.state.inputs;
        inputs.splice(index, 1);
        this.setState({inputs: inputs});
    };

    changeTrapAuto = (name, data, i) => (e, newValue) => {
        let pestTraps = this.state.pestTraps;
        let row = pestTraps[i];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : null;
        let trapHasContainer = false;
        if(data === 'item'){
            for(let row of pestTraps){
                if(row.itemSel.lotTracked === true){
                    trapHasContainer = true;
                    break;
                }
            }
        } else if (data === 'container'){
            if(newValue != null){
                row.changeTrap = true;
            }
        }
        this.setState({pestTraps: pestTraps, trapHasContainer: trapHasContainer});
    };

    changeTrapValue = (i) => (e) => {
        let pestTraps = this.state.pestTraps;
        let row = pestTraps[i];
        row[e.target.name] = e.target.value;
        this.setState({pestTraps: pestTraps});
    };

    changeTrapBool = (name, i) => (e) => {
        let pestTraps = this.state.pestTraps;
        let row = pestTraps[i];
        row[name] = !row[name];
        this.setState({pestTraps: pestTraps});
    }

    addTrap = () => {
        const t = this.props.t;
        let pestTraps = this.state.pestTraps;
        let errors = {};
        if(this.state.trapItem == null || this.state.trapItem === '')
            errors[t('trapItem')] = t('required');
        if(this.state.trapDescription == null || this.state.trapDescription === '')
            errors[t('trapDescription')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        pestTraps.push({
            item: this.state.trapItem,
            itemSel: this.state.trapItemSel,
            description: this.state.trapDescription,
            changeTrap: this.state.changeTrap,
            container: this.state.trapContainer,
            containerSel: this.state.trapContainerSel,
            containers: this.state.trapContainers
        });
        this.setState({
            pestTraps: pestTraps,
            trapItem: '',
            trapItemSel: null,
            trapDescription: '',
            errors: {},
            changeTrap: false,
            trapContainer: '',
            trapContainerSel: null,
            trapContainers: []
        })
    };

    deleteTrap = (i) => (e) => {
        let pestTraps = this.state.pestTraps;
        pestTraps.splice(i, 1);
        this.setState({pestTraps: pestTraps});
    };

    render(){
        const fieldAccess = this.state.fieldAccess;
        const errors = this.props.errors;
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.maintenanceRequest}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TabPanel value={this.state.tabKey} index={0}>
                            <Grid container spacing={3}>
                                <Grid container item spacing={1}>
                                    {this.state._id != null && this.state._id !== '' &&
                                        <Aux>
                                            <Grid item xs={6} sm={4}>
                                                <FormControl data-cy='number'>
                                                    <FormLabel>{t('number')}</FormLabel>
                                                    {this.state.number}
                                                </FormControl>
                                            </Grid>
                                        </Aux>
                                    }
                                    <Grid item xs={6} sm={4}>
                                        <FormControl data-cy='status'>
                                            <FormLabel>{t('status')}</FormLabel>
                                            {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    {fieldAccess &&
                                        <DateTimePicker
                                            onChange={this.dateChange}
                                            value={this.state.date}
                                            helperText={errors[t('date')]}
                                            error={errors[t('date')] != null ? true : false}
                                            disabled={!fieldAccess}
                                            label={t('requestReceived')}
                                            data-cy='date'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('requestReceived')}</FormLabel>
                                            <DateDisplay value={this.state.date} format="yyyy/MM/dd h:mm a"/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.locationSel}
                                            options={this.state.locations}
                                            onChange={this.changeAuto('locationSel', 'location')}
                                            error={errors[t('location')] != null ? true : false}
                                            helperText={errors[t('location')]}
                                            label={t('location')}
                                            disabled={!fieldAccess}
                                            data-cy='location'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.roomSel}
                                            options={this.state.rooms}
                                            onChange={this.changeAuto('roomSel', 'room')}
                                            error={errors[t('room')] != null ? true : false}
                                            helperText={errors[t('room')]}
                                            label={t('room')}
                                            disabled={!fieldAccess}
                                            data-cy='room'
                                        />
                                    }
                                    {!fieldAccess && this.state.roomSel != null &&
                                        <FormControl>
                                            <FormLabel>{t('room')}</FormLabel>
                                            <Link to={'/room/' + this.state.roomSel.value}>{this.state.roomSel.label}</Link>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.requestorSel}
                                            options={fieldAccess && !internal ? this.state.activeUsers : this.state.users}
                                            onChange={this.changeAuto('requestorSel', 'requestor')}
                                            error={errors[t('requestor')] != null ? true : false}
                                            helperText={errors[t('requestor')]}
                                            label={t('requestor')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy='requestor'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('requestor')}</FormLabel>
                                            {this.state.requestorSel != null ? this.state.requestorSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.catSel}
                                            options={this.state.categories}
                                            onChange={this.changeAuto('catSel', 'category')}
                                            error={errors[t('category')] != null ? true : false}
                                            helperText={errors[t('category')]}
                                            label={t('category')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy='category'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('category')}</FormLabel>
                                            {this.state.catSel != null ? this.state.catSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.prioritySel}
                                            options={this.state.priorities}
                                            onChange={this.changeAuto('prioritySel', 'priority')}
                                            error={errors[t('priority')] != null ? true : false}
                                            helperText={errors[t('priority')]}
                                            label={t('priority')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy='priority'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('priority')}</FormLabel>
                                            {this.state.prioritySel != null ? this.state.prioritySel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                {this.state.equipments?.length > 0 ? 
                                    <Grid item xs={12} sm={6} md={4}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                value={this.state.equipmentSel}
                                                options={this.state.equipments}
                                                onChange={this.changeAuto('equipmentSel', 'equipment')}
                                                error={errors[t('room')] != null ? true : false}
                                                helperText={errors[t('equipment')]}
                                                label={t('equipment')}
                                                disabled={!fieldAccess}
                                                data-cy='equipment'
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('equipment')}</FormLabel>
                                                {this.state.equipmentSel != null ? this.state.equipmentSel.label : ''}
                                            </FormControl>
                                        }
                                    </Grid> : null
                                }
                                <Grid item xs={12}>
                                    {fieldAccess &&
                                        <TextField value={this.state.description} onChange={this.changeValue} name="description" multiline={true} rows="4"
                                        variant="outlined" size="medium" fullWidth={true} disabled={!fieldAccess} label={t('description')} required data-cy='description'
                                        error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('description')}</FormLabel>
                                            {this.state.description}
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            {this.state.hasForm === true &&
                                <Aux>
                                    <br/>
                                    {errors[t('form')] != null && errors[t('form')] !== '' &&
                                        <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                    }
                                    <Form renderer={renderer}
                                        defaultFields={this.state.form}
                                        onChange={this.formValueChanged}
                                        disabled={!fieldAccess}/>
                                </Aux>
                            }
                            <Grid container spacing={3}>
                                { this.props.permission.complete &&
                                    <Grid item xs={12} sm={6} md={4}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                value={this.state.assigneeSel}
                                                options={this.state.users}
                                                onChange={this.changeAuto('assigneeSel', 'assignee')}
                                                error={errors[t('assignee')] != null ? true : false}
                                                helperText={errors[t('assignee')]}
                                                label={t('assignee')}
                                                disabled={!fieldAccess}
                                                data-cy='assignee'
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('assignee')}</FormLabel>
                                                {this.state.assigneeSel != null ? this.state.assigneeSel.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                }
                                {this.props.permission.complete && ( this.state._id !== '' && this.state._id !== null) &&
                                    (this.state.assignee !== null && this.state.assignee !== '') &&
                                    <Aux>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel
                                                control={
                                                <Checkbox
                                                    checked={this.state.riskAssessmentRequired}
                                                    onChange={this.riskAssChanged}
                                                    name="riskAssessmentRequired"
                                                />
                                                }
                                                label={t('riskAssRequired')}
                                                disabled={!fieldAccess}
                                            />
                                        </Grid>
                                        { (this.state.startDate === null || this.state.startDate === '') ?
                                            <Grid item xs={12} sm={4}>
                                                <StartButton
                                                    startClick={this.startTimer}
                                                    startEnabled={true} />
                                            </Grid> :
                                            <Grid container item spacing={3}>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    {fieldAccess &&
                                                        <DateTimePicker
                                                            onChange={this.startDateChange}
                                                            value={this.state.startDate}
                                                            helperText={errors[t('startDate')]}
                                                            error={errors[t('startDate')] != null ? true : false}
                                                            disabled={true}
                                                            label={t('startedOn')}
                                                            data-cy='startDate'
                                                        />
                                                    }
                                                    {!fieldAccess &&
                                                        <FormControl>
                                                            <FormLabel>{t('startedOn')}</FormLabel>
                                                            <DateDisplay value={this.state.startDate} format="yyyy/MM/dd h:mm a"/>
                                                        </FormControl>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    {fieldAccess &&
                                                        <AutoCompleteField
                                                            value={this.state.startedBySel}
                                                            options={this.state.users}
                                                            onChange={this.changeAuto('startedBySel', 'startedBy')}
                                                            error={errors[t('startedBy')] != null ? true : false}
                                                            helperText={errors[t('startedBy')]}
                                                            label={t('startedBy')}
                                                            disabled={true}
                                                            data-cy='startedBy'
                                                        />
                                                    }
                                                    {!fieldAccess &&
                                                        <FormControl>
                                                            <FormLabel>{t('startedBy')}</FormLabel>
                                                            {this.state.startedBySel != null ? this.state.startedBySel.label : ''}
                                                        </FormControl>
                                                    }
                                                </Grid>
                                            </Grid>
                                        }
                                        { (this.state.startDate !== null && this.state.startDate !== '') &&
                                            <Grid item xs={12}>
                                                {fieldAccess &&
                                                    <TextField value={this.state.fixDescription} onChange={this.changeValue} name="fixDescription" multiline={true} rows="4"
                                                    variant="outlined" size="medium" fullWidth={true} disabled={!fieldAccess} label={t('fixDescription')} required
                                                    error={errors[t('fixDescription')] != null ? true : false} helperText={errors[t('fixDescription')]}
                                                    data-cy='fixDescription'/>
                                                }
                                                {!fieldAccess &&
                                                    <FormControl>
                                                        <FormLabel>{t('fixDescription')}</FormLabel>
                                                        {this.state.fixDescription}
                                                    </FormControl>
                                                }
                                            </Grid>
                                        }
                                        { this.state.status !== this.props.auth.constants.statuses.new &&
                                            (this.state.completedDate === null || this.state.completedDate === '') &&
                                            <Grid item xs={12} sm={4}>
                                                <StopButton
                                                    stopClick={this.stopTimer}
                                                    stopEnabled={true} />
                                            </Grid>
                                        }
                                        { this.state.completedDate !== null && this.state.completedDate !== '' &&
                                            <Grid container item spacing={3}>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    {fieldAccess &&
                                                        <DateTimePicker
                                                                onChange={this.completedDateChange}
                                                                value={this.state.completedDate}
                                                                helperText={errors[t('completedDate')]}
                                                                error={errors[t('completedDate')] != null ? true : false}
                                                                disabled={true}
                                                                label={t('completedOn')}
                                                                data-cy='completedDate'
                                                            />
                                                    }
                                                    {!fieldAccess &&
                                                        <FormControl>
                                                            <FormLabel>{t('completedOn')}</FormLabel>
                                                            <DateDisplay value={this.state.completedDate} format="yyyy/MM/dd h:mm a"/>
                                                        </FormControl>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    {fieldAccess &&
                                                        <AutoCompleteField
                                                            value={this.state.completedBySel}
                                                            options={this.state.users}
                                                            onChange={this.changeAuto('completedBySel', 'completedBy')}
                                                            error={errors[t('completedBy')] != null ? true : false}
                                                            helperText={errors[t('completedBy')]}
                                                            label={t('completedBy')}
                                                            disabled={true}
                                                            data-cy='completedBy'
                                                        />
                                                    }
                                                    {!fieldAccess &&
                                                        <FormControl>
                                                            <FormLabel>{t('completedBy')}</FormLabel>
                                                            {this.state.completedBySel != null ? this.state.completedBySel.label : ''}
                                                        </FormControl>
                                                    }
                                                </Grid>
                                            </Grid>
                                        }
                                    </Aux>
                                }
                            </Grid>
                            {(fieldAccess || (this.state.inputs != null && this.state.inputs.length > 0)) &&
                                 <SectionTitle title={t('inputs')}/>
                            }
                            {this.state.inputs.map((row, i) =>
                                <Box borderColor="primary.main" {...defaultProps}  key={'item' + i} component={'div'}>
                                    <Grid container spacing={3} key={'item' + i}>
                                        <Grid item sx={{width: 350}}>
                                            <FormControl>
                                                <FormLabel>{t('item')}</FormLabel>
                                                <Link to={'/item/' + row.item}>{row.itemSel.label}</Link>
                                            </FormControl>
                                        </Grid>
                                        {row.itemSel != null && row.itemSel.lotTracked === true &&
                                            <Aux>
                                                <Grid item sx={{width: 250}}>
                                                    {fieldAccess &&
                                                        <AutoCompleteField
                                                            value={row.containerSel}
                                                            options={row.containers}
                                                            onChange={this.changeLineAuto('containerSel', 'container', i)}
                                                            error={errors[`${t('container')} (${t('line')} ${i})`] != null ? true : false}
                                                            helperText={errors[`${t('container')} (${t('line')} ${i})`]}
                                                            disabled={!fieldAccess}
                                                            label={t('container')}
                                                            data-cy={"inputContainer" + i}
                                                            groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                            noneMessage={t('noLotAssigned')}
                                                        />
                                                    }
                                                    {!fieldAccess && row.containerSel != null &&
                                                        <FormControl>
                                                            <FormLabel>{t('container')}</FormLabel>
                                                            <Link to={'/container/' + row.container}>{row.containerSel.label}</Link>
                                                        </FormControl>
                                                    }
                                                </Grid>
                                            </Aux>
                                        }
                                        <Grid item sx={{width: 150}}>
                                            {fieldAccess &&
                                                <TextField type="number" value={row.quantity} onChange={this.changeLineValue('quantity', i)} name="quantity"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} data-cy={"inputAmount" + i}
                                                error={errors[`${t('quantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('quantity')} (${t('line')} ${i})`]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('quantity')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true} decimalScale={3}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <FormControl>
                                                <FormLabel></FormLabel>
                                                {row.uomSel != null ? row.uomSel.label : ''}
                                            </FormControl>
                                        </Grid>
                                        {fieldAccess &&
                                            <Grid item sx={{width: 50}}>
                                                <IconButton aria-label="delete item" disabled={!fieldAccess} onClick={this.deleteInput(i)} data-cy={"deleteInput" + i}>
                                                    <RemoveCircleTwoToneIcon  />
                                                </IconButton>
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            )}
                            {fieldAccess &&
                                <Box borderColor="primary.main" {...defaultProps} component={'div'}>
                                    <Grid container spacing={3}>
                                        <Grid item sx={{width: 350}}>
                                            <AutoCompleteField
                                                value={this.state.itemSel}
                                                options={this.state.items}
                                                onChange={this.changeAuto('itemSel', 'item')}
                                                error={errors[t('item')] != null ? true : false}
                                                helperText={errors[t('item')]}
                                                label={t('item')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy="inputItem"
                                            />
                                        </Grid>
                                        {this.state.itemSel != null && this.state.itemSel.lotTracked && 
                                            <Grid item sx={{width: 250}}>
                                                <AutoCompleteField
                                                    value={this.state.containerSel}
                                                    options={this.state.containers}
                                                    onChange={this.changeAuto('containerSel', 'container')}
                                                    error={errors[t('container')] != null ? true : false}
                                                    helperText={errors[t('container')]}
                                                    label={t('container')}
                                                    disabled={!fieldAccess}
                                                    required
                                                    data-cy="inputContainer"
                                                    groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                    noneMessage={t('noLotAssigned')}
                                                />
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 150}}>
                                            <TextField type="number" value={this.state.quantity} onChange={this.changeValue} name="quantity"
                                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} required data-cy="inputAmount"
                                            error={errors[t('quantity')] != null ? true : false} helperText={errors[t('quantity')]}/>
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <FormControl>
                                                <FormLabel></FormLabel>
                                                {this.state.uomSel != null ? this.state.uomSel.label : ''}
                                            </FormControl>
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <IconButton aria-label="add" disabled={!fieldAccess} onClick={this.saveInput} data-cy="addInput">
                                                <AddBoxTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                            {(fieldAccess || (this.state.pestTraps != null && this.state.pestTraps.length > 0)) &&
                                <Aux>
                                    <br/>
                                    <SectionTitle title={t('pestTraps')}/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>{t('item')}</Grid>
                                        <Grid item sx={{width: 400}}>{t('description')}</Grid>
                                        {this.state.trapHasContainer &&
                                            <Grid item xs={6} sm={3}>{t('container')}</Grid>
                                        }
                                        <Grid item xs={2} sm={1}>{t('replace')}</Grid>
                                    </Grid>
                                </Aux>
                                 
                            }
                            {this.state.pestTraps.map((row, i) =>
                                <Aux  key={'trap' + i}>
                                    <Grid container spacing={3} key={'trapg' + i}>
                                        <Grid item xs={6} sm={3}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={row.itemSel}
                                                    options={this.state.trapItems}
                                                    onChange={this.changeTrapAuto('itemSel', 'item', i)}
                                                    error={errors[`${t('item')} (${t('line')} ${i})`] != null ? true : false}
                                                    helperText={errors[`${t('item')} (${t('line')} ${i})`]}
                                                    disabled={!fieldAccess}
                                                    data-cy={['item' + i]}
                                                />
                                            }
                                            {!fieldAccess &&
                                                row.itemSel != null ? row.itemSel.label : ''
                                            }
                                         </Grid>
                                         <Grid item sx={{width: 400}}>
                                            {fieldAccess &&
                                                <TextField type="text" value={row.description} onChange={this.changeTrapValue(i)} name={'description'}
                                                size="medium" fullWidth={true} disabled={!fieldAccess} data-cy={'description' + i}
                                                required={this.state.type === this.props.auth.constants.roomTypes.growing}
                                                error={errors[`${t('description')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('description')} (${t('line')} ${i})`]}/>
                                            }
                                            {!fieldAccess &&
                                                row.description
                                            }
                                        </Grid>
                                        {this.state.trapHasContainer &&
                                            <Grid item xs={6} sm={3}>
                                                {fieldAccess && row.containers != null && row.containers.length > 0 &&
                                                    <AutoCompleteField
                                                        value={row.containerSel}
                                                        options={row.containers}
                                                        onChange={this.changeTrapAuto('containerSel', 'container', i)}
                                                        error={errors[`${t('container')} (${t('line')} ${i})`] != null ? true : false}
                                                        helperText={errors[`${t('container')} (${t('line')} ${i})`]}
                                                        disabled={!fieldAccess}
                                                        data-cy={['container' + i]}
                                                    />
                                                }
                                                {!fieldAccess && row.containerSel != null &&
                                                    <Link to={'/container/' + row.container}>{row.containerSel.label}</Link>
                                                }
                                            </Grid>
                                        }
                                        <Grid item xs={2} sm={1}>
                                            <Checkbox
                                                checked={row.changeTrap}
                                                onChange={this.changeTrapBool('changeTrap', i)}
                                                name="changeTrap"
                                                disabled={!fieldAccess}
                                            />
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <IconButton aria-label="delete line" onClick={this.deleteTrap(i)}>
                                                <RemoveCircleTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Aux>
                            )}
                            {fieldAccess &&
                                <Grid container spacing={3} key='newTrap'>
                                    <Grid item xs={6} sm={3}>
                                        <AutoCompleteField
                                            value={this.state.trapItemSel}
                                            options={this.state.trapItems}
                                            onChange={this.changeAuto('trapItemSel', 'trapItem')}
                                            error={errors[t('trapItem')] != null ? true : false}
                                            helperText={errors[t('trapItem')]}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="trapItem"
                                        />
                                    </Grid>
                                    <Grid item sx={{width: 400}}>
                                        <TextField type="text" value={this.state.trapDescription} onChange={this.changeValue} name="trapDescription"
                                            size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy="trapDescription"
                                            error={errors[t('trapDescription')] != null ? true : false} helperText={errors[t('trapDescription')]}/>
                                    </Grid>
                                    {this.state.trapHasContainer &&
                                        <Grid item xs={6} sm={3}>
                                            {this.state.trapContainers != null && this.state.trapContainers.length > 0 &&
                                                <AutoCompleteField
                                                    value={this.state.trapContainerSel}
                                                    options={this.state.trapContainers}
                                                    onChange={this.changeAuto('trapContainerSel', 'trapContainer')}
                                                    error={errors[t('trapContainer')] != null ? true : false}
                                                    helperText={errors[t('trapContainer')]}
                                                    disabled={!fieldAccess}
                                                    required
                                                    data-cy="trapContainer"
                                                />
                                            }
                                        </Grid>
                                    }
                                    <Grid item xs={2} sm={1}>
                                        <Checkbox
                                            checked={this.state.changeTrap}
                                            onChange={this.changeBool('changeTrap')}
                                            name="changeTrap"
                                            disabled={!fieldAccess}
                                        />
                                    </Grid>
                                    <Grid item sx={{width: 50}}>
                                        <IconButton aria-label="addTrap" disabled={!fieldAccess} onClick={this.addTrap} data-cy="addTrap">
                                            <AddBoxTwoToneIcon  />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            }
                        </TabPanel>
                    </Grid>
                </Grid>
                <FormButtonStack
                    id={this.state._id}
                    objectType={this.props.auth.constants.objectIds.maintenanceRequest}
                    callback={this.refresh}
                    saveClick={() => this.save()}
                    status={this.state.status}
                    permissions={this.props.permission}
                    common={this.props.auth.common}
                    constants={this.props.auth.constants}
                    saveEnabled={this.state.enableSave}
                    deleteClick={this.deleteToggle}
                    activityDate={this.state.date}
                    lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                    isSuper={this.props.auth.user.isSuper}
                />
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.maintenanceRequest}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default connect(mapStateToProps) (withTranslation() (MaintenanceRequest));
