import React, { Component } from 'react';
import axios from 'axios';
import isEmpty from '../../is-empty';
import { withRouter} from 'react-router-dom';
import SelectField from '../UI/SelectField/SelectField';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper'
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import ColorHelper from '../../helpers/colorHelper';

class SopByStatusChart extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: {},
            fill: [],
            days: '7',
            statuses: [],
            department: this.props.department
        }
        this.changeValue = this.changeValue.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.scheduleStatus], this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({statuses: cacheValues.scheduleStatuses});
        this.loadValues();
    }

    async loadValues(){
        var params = {
            days: this.state.days,
            department: this.state.department
        };
        var data = await axios.get('/api/schedulestatuscounts', {params: params});
        var list = [];
        var fill = [];
        data.data.forEach(row => {
            if(row.count > 0){
                list.push({id: row.id, label: row.name, value: row.count, color: "hsl(210, 70%, 50%)"});
            }
        })
        this.setState({data: list, fill: fill})
    }

    changeValue(e){
        this.setState({days: e.target.value}, () => { this.loadValues();});
    }

    onClick(e){
        this.props.history.push('/scheduledActivities?status=' + e.id + '&days=' + this.state.days);
    }

    render(){
        const colors = ColorHelper.colorList();
        return(
            <Aux>
                <SectionTitle title={this.props.t('workRecsByStatus')}/>
                <Grid container spacing={3}>
                    <Grid item xs={8} sm={4}>
                        <SelectField
                            className="form-control"
                            label={this.props.t('days')}
                            name="days"
                            options={this.props.auth.common.dayOptions}
                            onChange={this.changeValue}
                            value={this.state.days}
                        />
                    </Grid>
                </Grid>
                {!isEmpty(this.state.data) &&
                    <PieChart width={400} height={400}>
                        <Pie
                            data={this.state.data}
                            cx='50%'
                            cy='50%'
                            innerRadius={60}
                            outerRadius={120}
                            fill="#8884d8"
                            paddingAngle={3}
                            dataKey="value"
                            nameKey="label"
                            label
                            onClick={this.onClick}
                        >
                            {this.state.data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index]} />
                            ))}
                        </Pie>
                        <Legend />
                        <Tooltip/>
                  </PieChart>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default withTranslation() (connect(mapStateToProps)(withRouter(SopByStatusChart)));
