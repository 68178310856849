import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
//import TaskRiskAssessment from './TaskRiskAssessment';
import DateSelect from '../UI/DateSelect/DateSelect';
import DateTimePicker from '../UI/DateTimeSelect/DateTimeSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { fieldSecurity } from '../../helpers/securityHelper';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
//+import ActivityWorkRecord from '../Cultivation/ActivityWorkRecord';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import ActionBar from '../UI/Buttons/ActionBar';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import SectionTitle from '../UI/Typography/SectionTitle';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import IconButton from '@mui/material/IconButton';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import ConversionHelper from '../../helpers/conversionHelper';

class EquipmentLog extends Component{
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            equipment: null,
            equipments: [],
            equipmentSel: null,
            equipmentId: '',
            date: new Date(),
            description: '',
            user: '',
            users: [],
            activeUsers: [],
            organization: '',
            organizations: [],
            conductedExecution: '',
            startTimestamp: null,
            endTimestamp: null,
            reviewer: null,
            signer: null,
            signed: false,
            schedule: null,
            qas: [],
            reviewers: [],
            status: this.props.auth.constants.statuses.new,
            statuses: [],
            statusSel: { value: '', label: '' },
            statusString: 'New',
            taskRisk: false,
            taskRisks: [],
            taskRiskAssessment: '',
            scheduleBase: null,
            tenant: this.props.auth.user.tenant,
            documents: [],
            number: '',
            orgSelected: null,
            userSelected: null,
            reviewDate: null,
            security: [],
            fieldAccess: false,
            tabKey: 0,
            tabLabels: [
                {name: this.props.t('maintenanceLog'), disabled: false}
            ],
            optionsMenu: [],
            flagForSignature: 0,
            dialogOpen: false,
            enableSave: true,
            sigData: null,
            form: '',
            hasForm: false,
            internal: false,
            inputs: [],
            item: '',
            itemSel: null,
            items: [],
            container: '',
            containerSel: null,
            contaienrs: [],
            quantity: '',
            uom: '',
            uomSel: null,
            uoms: [],
            goBack: -1,
            type: '',
            typeSel: null,
            types: [],
            deleteOpen: false,
            equipmentAll: [],
            allItems: []
        };
        this.changeValue = this.changeValue.bind(this);
        this.saveClick = this.saveClick.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.startChange = this.startChange.bind(this);
        this.endChange = this.endChange.bind(this);
        this.reviewDateChange = this.reviewDateChange.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.status, common.cacheValues.equipment, common.cacheValues.maintenanceVendor,
            common.cacheValues.equipmentLogForm, common.cacheValues.item, common.cacheValues.quantityUOM, common.cacheValues.maintenanceLogType, common.cacheValues.equipmentAll,
            common.cacheValues.allItems], this.props.auth.constants, null, this.props.auth.user.tenant);
        const userValues = await ValueHelper.screenUsers(common.screens.equipmentLog, this.props.auth.user.tenant);
        const params = queryString.parse(this.props.location.search);
        const equipment = params != null && params.equipment != null ? params.equipment : null;
        let equipmentSel = equipment != null && equipment !== '' ? cacheValues.equipmentAll.find(x => x.value === equipment) : null;
        let schedule = null;
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        let goBack = -1;
        if(params != null && params.schedule != null)
            goBack = -2;
        this.setState({
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null,
            organizations: cacheValues.maintenanceVendors,
            users: userValues.create,
            reviewers: userValues.review,
            statuses: cacheValues.statuses,
            statusSel: statusSel,
            qas: userValues.approve,
            schedule: params != null && params.schedule != null ? params.schedule : null,
            scheduleBase: schedule != null ? schedule.data : null,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            equipments: cacheValues.equipments,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            items: cacheValues.items,
            allItems: cacheValues.allItems,
            uoms: cacheValues.quantityUOMs,
            equipment: equipment,
            equipmentSel: equipmentSel,
            goBack: goBack,
            types: cacheValues.maintenanceLogTypes,
            equipmentAll: cacheValues.equipmentAll

        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/maintenancelogs', label: this.props.t('maintenanceLogs'), screen: this.props.auth.screenDefs.MaintenanceRequest},
                    { path: 'active', label: this.props.t('maintenanceLog'), screen: this.props.auth.screenDefs.MaintenanceRequest}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
        if(this.props.match.params.equipment != null && this.props.match.params.equipment !== ''){
            if(this.props.match.params.equipment != null){
                const equipment = await axios.get('/api/equipment/' + this.props.match.params.equipment);
                this.setState({equipment: equipment.data});
            }
        }
    }

    loadRecord = async () => {
        const t = this.props.t;
        const log = await axios.get('/api/equipmentlog/' + this.props.match.params.id);
        const signed = log.data.status === this.props.auth.constants.statuses.reviewed ||
            log.data.status === this.props.auth.constants.statuses.signed;
        const orgs = this.state.organizations;
        const orgSelected = orgs.find(x => x.value === log.data.organization);
        const userSelected = log.data.users.find(x => x.value === log.data.user);
        let users = log.data.users;
        let activeUsers = [];
        if(orgSelected != null){
            activeUsers = users;
        } else {
            users.forEach(row => {
                if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                    activeUsers.push(row);
                }
            });
        }
        if(log.data.schedule != null && log.data.schedule.riskAssessmentRequired){
            await this.getTaskRiskAssessments(log.data.taskRiskAssessment);
        }
        let inputs = log.data.inputs != null && log.data.inputs.length > 0 ? log.data.inputs : [];
        for(let row of inputs){
            let itemSel = this.state.allItems.find(x => x.value === row.item);
            row.itemSel = itemSel;
            let uomSel = this.state.uoms.find(x => x.value === row.uom);
            row.uomSel = uomSel;
            row.containerSel = row.containers.find(x => x.value === row.container);
        }
        const statuses = this.state.statuses;
        const statusSel = statuses.find(x => x.value === log.data.status);
        const typeSel = this.state.types.find(x => x.value === log.data.type);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = (log.data._id != null && log.data._id !== '');
        const approveSigSec = (log.data.reviewer != null && log.data.reviewer._id != null &&
                               log.data.reviewer.signature != null && log.data.reviewer.signature !== '');
        let equipmentSel = this.state.equipmentAll.find(x => x.value === log.data.equipment._id);
        const optionsMenu = [
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: this.state.reviewers, name: t('review'), object: log.data._id,
                title: t('reviewedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: log.data.reviewer,
                objectType: this.props.auth.constants.objectIds.equipmentLog, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature, saveCallback: this.saveCallback
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: this.state.qas, name: t('approve'),
                title: t('approvedBy'), setId: () => this.loadRecord(), object: log.data._id, signature: log.data.signer,
                objectType: this.props.auth.constants.objectIds.equipmentLog, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        let hasForm = this.state.hasForm;
        if(!hasForm && log.data.form != null && log.data.form !== '')
            hasForm = true;
        const flagForSignature = log.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 log.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 log.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        this.setState({
            _id: log.data._id,
            date: log.data.date,
            description: log.data.description,
            user: log.data.user,
            organization: log.data.organization,
            conductedExecution: log.data.conductedExecution,
            startTimestamp: log.data.startTimestamp,
            endTimestamp: log.data.endTimestamp,
            reviewer: log.data.reviewer,
            signer: log.data.signer,
            status: log.data.status,
            statusString: statusSel.label,
            reviewDate: log.data.reviewDate,
            reviewSignature: log.data.reviewSignature,
            equipment: log.data.equipment,
            equipmentId: log.data.equipment._id,
            equipmentSel: equipmentSel,
            signed: signed,
            users: users,
            activeUsers: activeUsers,
            orgSelected: orgSelected,
            userSelected: userSelected,
            taskRiskAssessment: log.data.taskRiskAssessment,
            scheduleBase: log.data.schedule != null ? log.data.schedule : null,
            taskRisk: log.data.schedule != null ? log.data.schedule.riskAssessmentRequired : false,
            documents: log.data.documents,
            number: log.data.number,
            statusSel: statusSel,
            fieldAccess: fieldAccess,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            sigData: null,
            form: log.data.form != null ? JSON.parse(log.data.form) : null,
            hasForm: hasForm,
            internal: log.data.internal === true || log.data.internal === 'true' ? true : false,
            inputs: inputs,
            type: log.data.type,
            typeSel: typeSel,
            deleteOpen: false
        }, () => {
            const crumbs = [
                { path: '/#/maintenancelogs', label: this.props.t('maintenanceLogs'), screen: this.props.auth.screenDefs.MaintenanceRequest},
                { path: 'active', label: this.props.t('maintenanceLog') + ' - ' + log.data.number, screen: this.props.auth.screenDefs.MaintenanceRequest}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    }

    async getTaskRiskAssessments(selected){
        const data = {
            user: this.props.auth.user.id,
            objectType: this.props.auth.constants.objectIds.equipmentLog
        };
        const riskAsses = await axios.get('/api/taskriskselectlist', {params: data});
        let taskRiskSel = null;
        if(selected != null && selected !== '')
            taskRiskSel = riskAsses.data.find(x => x.value === selected);
        this.setState({taskRisks: riskAsses.data, taskRiskAssessmentSel: taskRiskSel});
    }

    async loadUsers(vendor){
        const id = vendor != null ? vendor : this.state.organization;
        const users = await axios.get('/api/vendoremployeeselect/' + id);
        return users.data;
    }

    async changeValue(e){
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        });
    }

    changeSelect = (name, data) => value => {
        let taskRisk = this.state.taskRisk;
        if(data === 'taskRiskAssessment'){
            if (value.value != null && value.value !== "")
                taskRisk = true;
        }
        this.setState({[name]: value, [data]: value.value, taskRisk: taskRisk});
        if(data === 'organization')
            this.performChange(data, value.value);
    };

    changeBool = (name) => async (e) => {
        let value = !this.state[name];
        this.setState({[name]: value});
        if(name === 'internal' && value === true){
            let users = await axios.get('/api/departmentusers/' + this.props.auth.constants.departments.facilities);
            let activeUsers = [];
            users.data.forEach(row => {
                if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                    activeUsers.push(row);
                }
            })
            this.setState({users: users.data, organization: '', orgSelected: null, user: '', userSelected: null, activeUsers: activeUsers});
        } else if (name === 'internal' && value === false){
            this.setState({user: '', userSelected: null, users: [], activeUsers: []})
        }
    }

    changeAuto = (name, data) => (e, newValue) => {
        let taskRisk = this.state.taskRisk;
        if(data === 'taskRiskAssessment'){
            if (newValue.value != null && newValue.value !== "")
                taskRisk = true;
        }
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            taskRisk: taskRisk
        }, async () => {
            if(data === 'organization'){
                this.performChange(data, newValue != null ? newValue.value : null);
            } else if(data === 'item'){
                if(newValue != null){
                    let body = {positive: true, items: [newValue.value], available: true, consume: true};
                    let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                    let contRes = await axios.post('/api/itemcontainers', body);
                    this.setState({containers: contRes.data, uom: newValue.uom, uomSel: uomSel});
                } else {
                    this.setState({container: '', containerSel: null, containers: [], uom: '', uomSel: null});
                }
            }
        });
    };

    async performChange(name, value){
        let users = this.state.users;
        let user = this.state.user;
        let userSelected = this.state.userSelected;
        if(name === 'organization'){
            if(value != null && value !== ''){
                const result = await this.loadUsers(value);
                users = result;
            } else {
                users = [];

            }
            user = '';
            userSelected = null;
        }
        this.setState({
            users: users,
            user: user,
            userSelected: userSelected
        });
    }

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.saveClick()})
    };

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    };

    _saveClick = async () => {
        const t = this.props.t;
        const valid = this.validateForm();
        if(valid){
            let form = this.state.form;
            if(this.state.hasForm && this.state.value != null){
                for (let key in this.state.value){
                    let val = this.state.value[key];
                    for (let i = 0; i < form.length; i++){
                        let field = form[i];
                        if (field.name === key){
                            field.value = val;
                            break;
                        }
                    }
                }
                let errors = {};
                let error = false;
                for(let row of form){
                    if(row.required === true && (row.value == null || row.value === '')){
                        error = true;
                        break;
                    }
                }
                if(error === true){
                    errors[t('form')] = t('fieldsIncomplete');
                    this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                    this.setState({enableSave: true});
                    return;
                }
            }
            let status = this.state.status;
            if( status === this.props.auth.constants.statuses.new ){
                status = this.props.auth.constants.statuses.inprogress;
            }
            const data = {
                _id: this.state._id,
                equipment: this.state.equipmentId,
                date: this.state.date,
                description: this.state.description,
                user: this.state.user,
                organization: this.state.organization,
                conductedExecution: this.state.conductedExecution,
                startTimestamp: this.state.startTimestamp,
                endTimestamp: this.state.endTimestamp,
                reviewer: this.state.reviewer,
                reviewDate: this.state.reviewDate,
                reviewSignature: this.state.reviewSignature,
                schedule: this.state.schedule,
                status: status,
                taskRiskAssessment: this.state.taskRiskAssessment,
                sigData: this.state.sigData,
                internal: this.state.internal,
                form: form != null && form !== '' ? JSON.stringify(form) : null,
                inputs: this.state.inputs,
                type: this.state.type
            }
            try{
                const result = await axios.post('/api/equipmentlog', data);
                const statusSel = this.state.statuses.find(x => x.value === result.data.status);
                if(this.state._id == null || this.state._id === '')
                    this.props.history.replace('/equipmentlog/' + this.state.equipmentId + '/' + result.data.id);
                this.setState({
                    _id: result.data.id,
                    number: result.data.number,
                    status: result.data.status,
                    statusSel: statusSel,
                    enableSave: true,
                    sigData: null
                }, () => {
                    this.loadRecord();
                });
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            }catch(err) {
                this.setState({ enableSave: true }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
                });
            }
        } else {
            this.setState({enableSave: true});
        }
    }

    validateForm(){
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.equipmentId == null || state.equipmentId === '')
            errors[t('equipmentId')] = t('required');
        if(this.state.date == null || this.state.date === ''){
            errors[t('date')] = t('required');
        } else {
            let validEndDate = ConversionHelper.validActivityDate(this.state.date, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
            if(!validEndDate){
                errors[t('date')] = t('dateBeforeLock');
            }
        }
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(this.state.description == null || this.state.description === '')
            errors[t('description')] = t('required');
        if(this.state.user == null || this.state.user === '')
            errors[t('user')] = t('required');
        if(this.state.internal === false && (this.state.organization == null || this.state.organization === ''))
            errors[t('organization')] = t('required');
        if(state.scheduleBase != null && state.scheduleBase.riskAssessmentRequired && (state.taskRiskAssessment == null ||
            state.taskRiskAssessment === '')){
            errors[t('taskRiskAssessment')] = t('required');
        }
        if((this.state.item != null && this.state.item !== '') || (this.state.quantity != null && this.state.quantity !== '') ||
        (this.state.container != null && this.state.container !== '')){
            errors[t('item')] = t('rowNotAdded');
        }
        for(let [i, row] of state.inputs.entries()){
            if(row.quantity == null || row.quantity === '' || row.quantity === 0 || row.quantity === '0'){
                errors[`${t('quantity')} (${t('line')} ${i})`] = t('required');
            } else if(row.quantity != null && row.quantity !== ''){
                if(row.containerSel == null || row.itemSel.lotTracked === false){
                    if(parseFloat(row.quantity) > parseFloat(row.itemSel.inventory))
                        errors[`${t('quantity')} (${t('line')} ${i})`] = t('moreThanInventory');
                } else if(row.containerSel != null && row.itemSel.lotTracked === true){
                    if(parseFloat(row.quantity) > parseFloat(row.containerSel.quantity)){
                        errors[`${t('quantity')} (${t('line')} ${i})`] = t('moreThanContainer');
                    }
                }
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    validateComplete(){
        const t = this.props.t;
        let state = this.state;
        let valid = this.validateForm();
        if(!valid)
            return t('notAllComplete');
        if(state.sops != null && state.sops.length > 0){
            for(let i = 0; i < state.sops.length; i++){
                let sop = state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        return null;
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._deleteClick());
    };

    _deleteClick = async () => {
        const constants = this.props.auth.constants;
        if(this.state._id == null || this.state._id === ''){
            this.props.history.goBack();
            return;
        }
        if(this.state.status === constants.statuses.new || this.state.status === constants.statuses.inprogress){
            try {
                let result = await axios.delete('/api/equipmentlog/' + this.state._id);
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({ enableSave: true, deleteOpen: false }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
                });
            }
        } else {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: this.props.t('deleteStatusInvalid'), title: this.props.t('warning'), severity: 'warning'}});
            this.setState({enableSave: true, deleteOpen: false});
        }
    }

    dateChange(e){
        this.setState({date: e});
    }

    startChange(e){
        this.setState({startTimestamp: e});
    }

    endChange(e){
        this.setState({endTimestamp: e});
    }

    reviewDateChange(e){
        this.setState({reviewDate: e});
    }

    refresh = e => {
        this.setState({reviewer: e});
        this.loadRecord();
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    toggleDialog = () => {
        let dialogOpen = this.state.dialogOpen;
        this.setState({ dialogOpen: !dialogOpen });
    };

    saveInput = (e) => {
        const valid = this.validateInput();
        let inputs = this.state.inputs;
        if(!valid) return;
        inputs.push({
            item: this.state.item,
            itemSel: this.state.itemSel,
            quantity: this.state.quantity,
            uom: this.state.uom,
            uomSel: this.state.uomSel,
            container: this.state.container,
            containerSel: this.state.containerSel
        })
         this.setState({
             inputs: inputs,
             item: '',
             itemSel: null,
             quantity: '',
             uom: '',
             uomSel: null,
             container: '',
             containerSel: null
         });
    }

    validateInput(){
        let errors = {};
        const t = this.props.t;
        if(this.state.item == null || this.state.item === '')
            errors[t('item')] = t('required');
        if(this.state.quantity == null || this.state.quantity === '')
            errors[t('quantity')] = t('required');
        if(this.state.itemSel != null && this.state.itemSel !== '' && this.state.itemSel.lotTracked === false){
            if(parseInt(this.state.quantity) > this.state.itemSel.inventory)
                errors[t('quantity')] = t('moreThanInventory');
        }
        if(this.state.itemsel != null && this.state.itemSel.lotTracked){
            if(this.state.container == null || this.state.container === ''){
                errors[t('container')] = t('required');
            }
            if(parseFloat(this.state.quantity) > parseFloat(this.state.containerSel.quantity)){
                errors[t('quantity')] = t('moreThanContainer');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeLineValue = (name, index) => (e) => {
        let inputs = this.state.inputs;
        let row = inputs[index];
        row[name] = e.target.value;
        this.setState({inputs: inputs});
    };

    changeLineAuto = (name, data, index) => async (e, newValue) => {
        let inputs = this.state.inputs;
        let row = inputs[index];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : null;
        if(data === 'item' || data === 'container'){
            row.reservation = null;
        }
        if(data === 'item' && newValue != null){
            let body = {positive: true, items: [newValue.value], available: true, consume: true};
            let contRes = await axios.post('/api/itemcontainers', body);
            row.containers = contRes.data;
        }
        this.setState({inputs: inputs});
    };

    deleteInput = (index) => (e) => {
        let inputs = this.state.inputs;
        inputs.splice(index, 1);
        this.setState({inputs: inputs});
    };

    render(){
        const fieldAccess = this.state.fieldAccess;
        const errors = this.props.errors;
        const security = this.state.security;
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.equipmentLog}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TabPanel value={this.state.tabKey} index={0}>
                            <Grid container spacing={2}>
                                <Grid item container spacing={3}>
                                    <Grid item xs={6} sm={4}>
                                        <FormControl data-cy='number'>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state._id != null && this.state._id !== '' && this.state.number != null && this.state.number !== '' ? this.state.number : ''}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <FormControl data-cy='status'>
                                            <FormLabel>{t('status')}</FormLabel>
                                            {this.state.statusString}
                                        </FormControl>
                                    </Grid>
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        {fieldAccess &&
                                            <DateSelect
                                                onChange={this.dateChange}
                                                value={this.state.date}
                                                helperText={errors[t('date')]}
                                                error={errors[t('date')] != null ? true : false}
                                                label={t('date')}
                                                disabled={!fieldAccess}
                                                format={this.props.auth.user.dateFormat}
                                                data-cy='date'
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('date')}</FormLabel>
                                                <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                value={this.state.equipmentSel}
                                                options={this.state.equipments}
                                                onChange={this.changeAuto('equipmentSel', 'equipmentId')}
                                                error={errors[t('equipmentId')] != null ? true : false}
                                                helperText={errors[t('equipmentId')]}
                                                label={t('equipment')}
                                                disabled={!fieldAccess}
                                                data-cy='equipment'
                                                required
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('equipment')}</FormLabel>
                                                {this.state.equipmentSel != null ? this.state.equipmentSel.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                value={this.state.typeSel}
                                                options={this.state.types}
                                                onChange={this.changeAuto('typeSel', 'type')}
                                                error={errors[t('type')] != null ? true : false}
                                                helperText={errors[t('type')]}
                                                label={t('type')}
                                                disabled={!fieldAccess}
                                                data-cy='type'
                                                required
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('type')}</FormLabel>
                                                {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                    {this.state.scheduleBase != null && this.state.scheduleBase.riskAssessmentRequired &&
                                        <Grid item xs={4} sm={2}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={this.state.taskRiskAssessmentSel}
                                                    options={this.state.taskRisks}
                                                    onChange={this.changeAuto('taskRiskAssessmentSel', 'taskRiskAssessment')}
                                                    error={errors[t('taskRiskAssessment')] != null ? true : false}
                                                    helperText={errors[t('taskRiskAssessment')]}
                                                    label={t('riskAssessment')}
                                                    disabled={!fieldAccess}
                                                    data-cy='taskRiskAssessment'
                                                />
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('riskAssessment')}</FormLabel>
                                                    {this.state.taskRiskAssessmentSel != null ? this.state.taskRiskAssessmentSel.label : ''}
                                                </FormControl>
                                            }
                                        </Grid>
                                    }
                                    <Grid item xs={4} sm={2}>
                                        <CheckboxField
                                        checked={this.state.internal} onChange={this.changeBool('internal')}
                                        label={t('internal')} name="internal" disabled={!fieldAccess}
                                        />
                                    </Grid>
                                    {this.state.internal === false &&
                                        <Grid item xs={6} sm={4}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={this.state.orgSelected}
                                                    options={this.state.organizations}
                                                    onChange={this.changeAuto('orgSelected', 'organization')}
                                                    error={errors[t('organization')] != null ? true : false}
                                                    helperText={errors[t('organization')]}
                                                    label={t('organization')}
                                                    disabled={!fieldAccess}
                                                    data-cy='organization'
                                                    required
                                                />
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('organization')}</FormLabel>
                                                    {this.state.orgSelected != null ? this.state.orgSelected.label : ''}
                                                </FormControl>
                                            }
                                        </Grid>
                                    }
                                    <Grid item xs={6} sm={4}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                value={this.state.userSelected}
                                                options={fieldAccess && !internal ? this.state.activeUsers : this.state.users}
                                                onChange={this.changeAuto('userSelected', 'user')}
                                                error={errors[t('user')] != null ? true : false}
                                                helperText={errors[t('user')]}
                                                label={t('performedBy')}
                                                disabled={!fieldAccess}
                                                data-cy='performedBy'
                                                required
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('performedBy')}</FormLabel>
                                                {this.state.userSelected != null ? this.state.userSelected.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                </Grid>
                                {this.state.hasForm === true &&
                                    <Aux>
                                        <br/>
                                        {errors[t('form')] != null && errors[t('form')] !== '' &&
                                            <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                        }
                                        <Form renderer={renderer}
                                            defaultFields={this.state.form}
                                            onChange={this.formValueChanged}
                                            disabled={!fieldAccess}/>
                                    </Aux>
                                }
                            <Grid item container spacing={2} sx={{ mb:1.5, mt: 1 }}>
                                <Grid item xs={11}>
                                    {fieldAccess &&
                                        <TextField value={this.state.description} onChange={this.changeValue} name="description" multiline rows="3"
                                            variant="outlined" size="medium" fullWidth={true} disabled={!fieldAccess} label={t('procedureOccurrence')}
                                            data-cy='description' required
                                            error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('procedureOccurrence')}</FormLabel>
                                            {this.state.description}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={11}>
                                    {fieldAccess &&
                                        <TextField value={this.state.conductedExecution} onChange={this.changeValue} name="conductedExecution"
                                        multiline rows="3" label={t('conductedExecution')} data-cy='conductedExecution'
                                        variant="outlined" size="medium" fullWidth={true} disabled={!fieldAccess}
                                        error={errors[t('conductedExecution')] != null ? true : false} helperText={errors[t('conductedExecution')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('conductedExecution')}</FormLabel>
                                            {this.state.conductedExecution}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    {fieldAccess &&
                                        <DateTimePicker
                                            onChange={this.startChange}
                                            value={this.state.startTimestamp}
                                            helperText={errors[t('startTimestamp')]}
                                            error={errors[t('startTimestamp')] != null ? true : false}
                                            disabled={!fieldAccess}
                                            label={t('startTime')}
                                            data-cy='startTime'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('startTime')}</FormLabel>
                                            <DateDisplay value={this.state.startTimestamp} format="yyyy/MM/dd h:mm a"/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    {fieldAccess &&
                                        <DateTimePicker
                                            onChange={this.endChange}
                                            value={this.state.endTimestamp}
                                            helperText={errors[t('endTimestamp')]}
                                            error={errors[t('endTimestamp')] != null ? true : false}
                                            disabled={!fieldAccess}
                                            label={t('endTime')}
                                            data-cy='endTime'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('endTime')}</FormLabel>
                                            <DateDisplay value={this.state.endTimestamp} format="yyyy/MM/dd h:mm a"/>
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            <SectionTitle title={t('inputs')}/>
                            {this.state.inputs.map((row, i) =>
                                <Box borderColor="primary.main" {...defaultProps}  key={'item' + i} component={'div'}>
                                    <Grid container spacing={3} key={'item' + i}>
                                        <Grid item sx={{width: 350}}>
                                            <FormControl>
                                                <FormLabel>{t('item')}</FormLabel>
                                                <Link to={'/item/' + row.item}>{row.itemSel.label}</Link>
                                            </FormControl>
                                        </Grid>
                                        {row.itemSel != null && row.itemSel.lotTracked === true &&
                                            <Aux>
                                                <Grid item sx={{width: 250}}>
                                                    {fieldAccess &&
                                                        <AutoCompleteField
                                                            value={row.containerSel}
                                                            options={row.containers}
                                                            onChange={this.changeLineAuto('containerSel', 'container', i)}
                                                            error={errors[`${t('container')} (${t('line')} ${i})`] != null ? true : false}
                                                            helperText={errors[`${t('container')} (${t('line')} ${i})`]}
                                                            disabled={!fieldAccess}
                                                            label={t('container')}
                                                            data-cy={"inputContainer" + i}
                                                            groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                            noneMessage={t('noLotAssigned')}
                                                        />
                                                    }
                                                    {!fieldAccess && row.containerSel != null &&
                                                        <FormControl>
                                                            <FormLabel>{t('container')}</FormLabel>
                                                            <Link to={'/container/' + row.container}>{row.containerSel.label}</Link>
                                                        </FormControl>
                                                    }
                                                </Grid>
                                            </Aux>
                                        }
                                        <Grid item sx={{width: 150}}>
                                            {fieldAccess &&
                                                <TextField type="number" value={row.quantity} onChange={this.changeLineValue('quantity', i)} name="quantity"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} data-cy={"inputAmount" + i}
                                                error={errors[`${t('quantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('quantity')} (${t('line')} ${i})`]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('quantity')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true} decimalScale={3}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <FormControl>
                                                <FormLabel></FormLabel>
                                                {row.uomSel != null ? row.uomSel.label : ''}
                                            </FormControl>
                                        </Grid>
                                        {fieldAccess &&
                                            <Grid item sx={{width: 50}}>
                                                <IconButton aria-label="delete item" disabled={!fieldAccess} onClick={this.deleteInput(i)} data-cy={"deleteInput" + i}>
                                                    <RemoveCircleTwoToneIcon  />
                                                </IconButton>
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            )}
                            {fieldAccess &&
                                <Box borderColor="primary.main" {...defaultProps} component={'div'}>
                                    <Grid container spacing={3}>
                                        <Grid item sx={{width: 350}}>
                                            <AutoCompleteField
                                                value={this.state.itemSel}
                                                options={this.state.items}
                                                onChange={this.changeAuto('itemSel', 'item')}
                                                error={errors[t('item')] != null ? true : false}
                                                helperText={errors[t('item')]}
                                                label={t('item')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy="inputItem"
                                            />
                                        </Grid>
                                        {this.state.itemSel != null && this.state.itemSel.lotTracked &&
                                            <Grid item sx={{width: 250}}>
                                                <AutoCompleteField
                                                    value={this.state.containerSel}
                                                    options={this.state.containers}
                                                    onChange={this.changeAuto('containerSel', 'container')}
                                                    error={errors[t('container')] != null ? true : false}
                                                    helperText={errors[t('container')]}
                                                    label={t('container')}
                                                    disabled={!fieldAccess}
                                                    required
                                                    data-cy="inputContainer"
                                                    groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                    noneMessage={t('noLotAssigned')}
                                                />
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 150}}>
                                            <TextField type="number" value={this.state.quantity} onChange={this.changeValue} name="quantity"
                                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} required data-cy="inputAmount"
                                            error={errors[t('quantity')] != null ? true : false} helperText={errors[t('quantity')]}/>
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <FormControl>
                                                <FormLabel></FormLabel>
                                                {this.state.uomSel != null ? this.state.uomSel.label : ''}
                                            </FormControl>
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <IconButton aria-label="add" disabled={!fieldAccess} onClick={this.saveInput} data-cy="addInput">
                                                <AddBoxTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                        </TabPanel>
                    </Grid>
                </Grid>
                <FormButtonStack
                    id={this.state._id}
                    objectType={this.props.auth.constants.objectIds.equipmentLog}
                    callback={this.refresh}
                    saveClick={this.saveClick}
                    //printClick={'/#/equipmentlogprint/' + this.state._id}
                    status={this.state.status}
                    permissions={this.props.permission}
                    common={this.props.auth.common}
                    constants={this.props.auth.constants}
                    deleteClick={this.deleteToggle}
                    activityDate={this.state.date}
                    lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                    isSuper={this.props.auth.user.isSuper}
                />
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.equipmentLog}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(EquipmentLog));
