import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import isEmpty from '../../../is-empty';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '../../UI/Switch/Switch';
import FormLabel from '@mui/material/FormLabel';
import TextField from '../../UI/TextField/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { ClearButton, DeleteButton, SaveButton } from '../../UI/Buttons/Buttons';
import ModalDialog from '../../UI/Dialog/ModalDialog';
import AutoCompleteField from '../../UI/AutoCompleteField/AutoCompleteField';
import Aux from '../../../hoc/Auxilary/Auxilary';
import common from '../../../jsons/common.json';
import ValueHelper from '../../../helpers/valueHelper';
import CcxHelper from '../../../helpers/ccxHelper';
import ObjectLog from '../../General/ObjectLog';

class ItemCategoryDialog extends Component {
    constructor(props){
        super(props);
        this.state = {
            errors: {},
            buttonStack: [],
            open: false,
            index: -1,
            _id: '',
            code: '',
            description: '',
            strainRequired: false,
            dryingRequired: false,
            curingRequired: false,
            trimmingRequired: false,
            hasCount: false,
            exportName: '',
            hcReportCategory: '',
            hcReportCategorySel: null,
            tareWeight: false,
            discreteWeight: false,
            pesticide: false,
            infinite: false,
            customLotNumber: false,
            packagedOn: false,
            security: [],
            enableSave: true,
            viewingOnly: false,
            caseLot: false,
            trackingTypes: [],
            tracking: '',
            trackingSel: null,
            ppe: false,
            growing: false,
            cleaner: false,
            cleaningSolution: false,
            pesticideSolution: false,
            biological: false,
            feeding: false,
            feedingSolution: false,
            feedingPremix: false,
            ccxCategories: [],
            ccxCategory: '',
            ccxCatSel: null,
            pestTrap: false,
            itemThcMg: false,
            lotThcMg: false,
            itemName: '',
            hcRequired: false,
            hcExcluded: false,
            isCannabis: false,
            isExtract: false,
            isFlower: false,
            isPackaged: false,
            isPollen: false
        }
    }

    componentDidMount = async () => {
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.hcReportCategory, common.cacheValues.itemTrackingType], this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({
            hcReportCategories: cacheValues.hcReportCategories,
            trackingTypes: cacheValues.itemTrackingTypes,
            security: this.props.security,
            open: this.props.openStatus,
            viewingOnly: this.props.viewingOnly,
            ccxCategories: CcxHelper.deriveValues(common.ccxValueTypes.category1, this.props.auth.user.locale)
        }, () => {
            if(this.props.category != null && this.props.category !== '')
                this.loadRecord();
        });
    }

    loadRecord = () => {
        const details = this.props.category;
        let hcReportCategorySel = details.hcReportCategory != null ?
            this.state.hcReportCategories.find(x => x.value === details.hcReportCategory._id) : null;
        let trackingSel = details.tracking != null ? this.state.trackingTypes.find(x => x.value === details.tracking._id) : null;
        let ccxCatSel = details.ccxCategory != null && details.ccxCategory !== '' ? this.state.ccxCategories.find(x => x.value === details.ccxCategory) : null;
        let isCannabis = hcReportCategorySel != null ? true : false;
        if(!isCannabis){
            if(details.exportName === 'pollen' || details.exportName === 'growSamples' || details.exportName === 'plantlet' || details.exportName === 'rootlet'){
                isCannabis = true;
            }
        }
        this.setState({
            _id: details._id,
            code: details.code,
            description: details.description,
            strainRequired: details.strainRequired != null ? details.strainRequired: false,
            dryingRequired: details.dryingRequired != null ? details.dryingRequired: false,
            curingRequired: details.curingRequired != null ? details.curingRequired : false,
            trimmingRequired: details.trimmingRequired != null ? details.trimmingRequired : false,
            hasCount: details.hasCount != null ? details.hasCount : false,
            hcReportCategory: details.hcReportCategory != null ? details.hcReportCategory._id : null,
            hcReportCategorySel: hcReportCategorySel,
            exportName: details.exportName,
            tareWeight: details.tareWeight,
            discreteWeight: details.discreteWeight,
            pesticide: details.pesticide,
            infinite: details.infinite != null ? details.infinite : false,
            customLotNumber: details.customLotNumber === true ? true : false,
            packagedOn: details.packagedOn === true ? true : false,
            caseLot: details.caseLot === true ? true : false,
            trackingSel: trackingSel,
            tracking: details.tracking,
            ppe: details.ppe === true ? true : false,
            growing: details.growing === true ? true : false,
            cleaner: details.cleaner === true ? true : false,
            cleaningSolution: details.cleaningSolution === true ? true : false,
            pesticideSolution: details.pesticideSolution === true ? true : false,
            biological: details.biological === true ? true : false,
            feeding: details.feeding === true ? true : false,
            feedingSolution: details.feedingSolution === true ? true : false,
            feedingPremix: details.feedingPremix === true ? true : false,
            ccxCategory: details.ccxCategory,
            ccxCatSel: ccxCatSel,
            pestTrap: details.pestTrap === true ? true : false,
            itemThcMg: details.itemThcMg === true ? true : false,
            lotThcMg: details.lotThcMg === true ? true : false,
            itemName: details.itemName != null ? details.itemName : '',
            lowThcLiquid: details.lowThcLiquid === true ? true : false,
            lowThcSolid: details.lowThcSolid === true ? true : false,
            isCannabis: isCannabis,
            isExtract: hcReportCategorySel != null && common.thcHCCategories.find(x => x === details.hcReportCategory._id) != null ? true : hcReportCategorySel != null && details.hcReportCategory._id === common.hcReportCategories.pureIntermediaries ? true : false,
            isFlower: hcReportCategorySel != null && common.flowerHCCategories.find(x => x === details.hcReportCategory._id) != null ? true : false,
            isPackaged: hcReportCategorySel != null && common.packagedHCCategories.find(x => x === details.hcReportCategory._id) != null ? true : false,
            isPollen: details.exportName === 'pollen' ? true : false
        }, () => {
            this.checkHcRequired();
        });
    }
    checkHcRequired = () => {
        let hcRequired = false;
        const state = this.state;
        if(state.strainRequired === true || state.dryingRequired === true || state.curingRequired === true || state.trimmingRequired === true || state.itemThcMg === true || 
            state.lotThcMg === true || state.discreteWeight === true || state.packagedOn === true || state.caseLot === true || state.lowThcLiquid === true || state.lowThcSolid === true){
            hcRequired = true;
        }
        let hcExcluded = false;
        if(state.infinite === true || state.pesticide === true || state.growing === true || state.ppe === true || state.cleaner === true || state.cleaningSolution === true ||
            state.pesticideSolution === true || state.biological === true || state.feeding === true || state.feedingSolution === true || state.feedingPremix === true || state.pestTrap === true){
                hcExcluded = true;
        }
        this.setState({hcRequired: hcRequired, hcExcluded: hcExcluded});
    }

    buttonStack = () => {
        const security = this.state.security;
        const t = this.props.t;
        if(this.state.viewingOnly) {
            return <Button variant="contained" color="secondary" size="small" onClick={this.clear}>{t('close')}</Button>
        }else {
            return (
                <Aux>
                    <DeleteButton deleteClick={this.delete} enabled={security.update} />
                    <ClearButton clearClick={this.clear} enabled={security.update} />
                    <SaveButton saveClick={this.save} enabled={security.update && this.state.enableSave} />
                </Aux>
            );
        }        
    }

    clear = () => {
        this.setState({
            _id: '',
            code: '',
            description: '',
            strainRequired: false,
            dryingRequired: false,
            curingRequired: false,
            trimmingRequired: false,
            hasCount: false,
            exportName: '',
            edit: false,
            hcReportCategory: '',
            hcReportCategorySel: null,
            tareWeight: false,
            discreteWeight: false,
            pesticide: false,
            infinite: false,
            customLotNumber: false,
            packagedOn: false,
            caseLot: false,
            tracking: '',
            trackingSel: null,
            ppe: false,
            growing: false,
            cleaner: false,
            feedingPremix: false,
            ccxCategory: '',
            ccxCatSel: null,
            pestTrap: false,
            itemThcMg: false,
            lotThcMg: false,
            itemName: '',
            lowThcLiquid: false,
            lowThcSolid: false,
            isCannabis: false,
            isExtract: false,
            isFlower: false
        }, () => this.props.toggleDialog());
    };

    delete = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._delete());
    }

    _delete = async () => {
        if(this.state._id != null && this.state._id !== ''){
            try{
                let result = await axios.delete('/api/itemcategory/' + this.state._id);
                this.setState({enableSave: true});
                this.clear();
                this.props.callback();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            }

        } else {
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: this.props.t('unsavedDelete'), title: this.props.t('warning'), severity: 'warning'}});
        }
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    boolChange = (name) => () => {
        if(name === 'itemThcMg' || name === 'lotThcMg'){
            let other = name === 'itemThcMg' ? this.state.lotThcMg : this.state.itemThcMg;
            let curr = name === 'itemThcMg' ? this.state.itemThcMg : this.state.lotThcMg;
            if(curr === false && other === true){
                this.props.dispatch({
                    type: "CREATE_ALERT",
                    payload: {
                        message: this.props.t('onlyOneThcMg'),
                        title: this.props.t("error"),
                        severity: "error",
                    },
                });
                return;
            }
        }
        if(name === 'lowThcSolid' || name === 'lowThcLiquid'){
            let other = name === 'lowThcSolid' ? this.state.lowThcLiquid : this.state.lowThcSolid;
            let curr = name === 'lowThcSolid' ? this.state.lowThcSolid : this.state.lowThcLiquid;
            if(curr === false && other === true){
                this.props.dispatch({
                    type: "CREATE_ALERT",
                    payload: {
                        message: this.props.t('onlyOneLowTHC'),
                        title: this.props.t("error"),
                        severity: "error",
                    },
                });
                return;
            }
        }
        this.setState({[name]: !this.state[name]}, () => {
            this.checkHcRequired();
        });
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            if(data === 'hcReportCategory'){
                if(newValue != null){
                    let isExtract = common.thcHCCategories.find(x => x === newValue.value) != null ? true : false;
                    if(isExtract === false){
                        if(newValue.value === common.hcReportCategories.pureIntermediaries){
                            isExtract = true;
                        }
                    }
                    let isFlower = common.flowerHCCategories.find(x => x === newValue.value) != null ? true : false;
                    let isPackaged = common.packagedHCCategories.find(x => x === newValue.value) != null ? true : false;
                    this.setState({isExtract: isExtract, isFlower: isFlower, isCannabis: true, isPackaged: isPackaged});
                    if(isExtract){
                        this.setState({strainRequired: false, dryingRequired: false, curingRequired: false, trimmingRequired: false});
                    } else if(isFlower){
                        this.setState({itemThcMg: false, lotThcMg: false, lowThcLiquid: false, lowThcSolid: false, discreteWeight: false});
                    }
                    if(!isPackaged){
                        this.setState({caseLot: false})
                    }
                } else {
                    this.setState({
                        isExtract: false, 
                        isFlower: false, 
                        isCannabis: false, 
                        isPackaged: false, 
                        caseLot: false,
                        strainRequired: false,
                        dryingRequired: false,
                        curingRequired: false,
                        trimmingRequired: false,
                        discreteWeight: false,
                        packagedOn: false,
                        ccxCategory: '',
                        ccxCatSel: null,
                        itemThcMg: false,
                        lotThcMg: false,
                        lowThcLiquid: false,
                        lowThcSolid: false
                    });
                }
            }
        });
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        const data = {
            _id: this.state._id,
            code: this.state.code,
            description: this.state.description,
            strainRequired: this.state.strainRequired,
            dryingRequired: this.state.dryingRequired,
            trimmingRequired: this.state.trimmingRequired,
            curingRequired: this.state.curingRequired,
            hcReportCategory: this.state.hcReportCategory,
            exportName: this.state.exportName,
            hasCount: this.state.hasCount,
            tareWeight: this.state.tareWeight,
            discreteWeight: this.state.discreteWeight,
            pesticide: this.state.pesticide,
            infinite: this.state.infinite,
            customLotNumber: this.state.customLotNumber,
            packagedOn: this.state.packagedOn,
            caseLot: this.state.caseLot,
            tracking: this.state.tracking,
            growing: this.state.growing,
            ppe: this.state.ppe,
            cleaner: this.state.cleaner,
            cleaningSolution: this.state.cleaningSolution,
            pesticideSolution: this.state.pesticideSolution,
            biological: this.state.biological,
            feeding: this.state.feeding,
            feedingSolution: this.state.feedingSolution,
            feedingPremix: this.state.feedingPremix,
            ccxCategory: this.state.ccxCategory !== '' ? this.state.ccxCategory : null,
            pestTrap: this.state.pestTrap,
            itemThcMg: this.state.itemThcMg,
            lotThcMg: this.state.lotThcMg,
            itemName: this.state.itemName !== '' ? this.state.itemName : null,
            lowThcLiquid: this.state.lowThcLiquid,
            lowThcSolid: this.state.lowThcSolid
        };
        try {
            const result = await axios.post('/api/itemcategory', data);
            this.setState({ enableSave: true }, () => {
                this.clear();
                this.props.callback();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () =>{
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    }

    validate = () => {
        let errors = {};
        const state = this.state;
        const t = this.props.t;
        if(state.code == null || state.code === '')
            errors[t('code')] = t('required');
        if(state.description == null || state.description === '')
            errors[t('description')] = t('required');
        if(state.infinite){
            if(state.strainRequired || state.dryingRequired || state.curingRequired ||  state.tareWeight || state.discreteWeight || state.pesticide || state.packagedOn || 
                state.caseLot || state.pesticide || state.growing || state.ppe || state.cleaner || state.cleaningSolution || state.pesticideSolution || 
                state.biological || state.feeding || state.feedingSolution || state.feedingPremix || state.pestTrap || state.itemThcMg || state.lotThcMg || 
                state.hcReportCategorySel != null || state.trackingSel != null){
                    errors[t('infinite')] = t('onlyInfinite');
                }
        }
        if(state.hcRequired === true && state.hcReportCategorySel == null){
            errors[t('hcReportCategory')] = t('required');
        }
        if(state.hcExcluded === true && state.hcReportCategorySel != null){
            errors[t('hcReportCategory')] = t('notAllowedField');
        }
        if(state.strainRequired === true){
            if(state.itemName == null || state.itemName === '')
                errors[t('itemName')] = t('required');
        }
    
        this.setState({errors: errors});
        let empty = isEmpty(errors);
        let errorString = '';
        for(let row in errors){
            errorString += errors[row] + '\n';
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: errorString, title: t('error'), severity: 'error'}});
        }
        return empty;
    }

    render() {
        const { t } = this.props;
        const { errors } = this.state;
        const micro = this.props.auth.user.micro;
        const isCannabis = this.state.isCannabis;
        const isExtract = this.state.isExtract;
        const isFlower = this.state.isFlower;
        return (
          <ModalDialog
            buttonStack={this.buttonStack()}
            title={t("itemCategory")}
            toggleDialog={this.clear}
            dialogStatus={this.state.open}
            fullWidth
            maxWidth="md"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t("details")}</FormLabel>
                  {this.state.viewingOnly ? (
                    <Grid item container xs={12} spacing="1">
                      <Grid item xs={12}>
                        <FormControl>
                          <FormLabel>{t("description")}</FormLabel>
                          {this.state.description != null
                            ? this.state.description
                            : ""}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <FormLabel>{t("code")}</FormLabel>
                          {this.state.code != null ? this.state.code : ""}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <FormLabel>{t("reportCategory")}</FormLabel>
                          {this.state.hcReportCategorySel != null
                            ? this.state.hcReportCategorySel.label
                            : t("none")}
                        </FormControl>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item container xs={12} spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          value={this.state.description}
                          onChange={this.changeValue}
                          name="description"
                          size="medium"
                          fullWidth={true}
                          label={t("description")}
                          required
                          error={errors[t('description')] != null ? true : false}
                          helperText={errors[t('description')]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          value={this.state.code}
                          onChange={this.changeValue}
                          name="code"
                          size="medium"
                          fullWidth={true}
                          label={t("code")}
                          required
                          error={errors[t('code')] != null ? true : false}
                          helperText={errors[t('code')]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AutoCompleteField
                          value={this.state.hcReportCategorySel}
                          options={this.state.hcReportCategories}
                          onChange={this.changeAuto(
                            "hcReportCategorySel",
                            "hcReportCategory"
                          )}
                          error={errors[t('hcReportCategory')] != null ? true : false}
                          helperText={errors[t('hcReportCategory')]}
                          label={t("reportCategory")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AutoCompleteField
                          value={this.state.trackingSel}
                          options={this.state.trackingTypes}
                          onChange={this.changeAuto("trackingSel", "tracking")}
                          error={errors[t('tracking')] != null ? true : false}
                          helperText={errors[t('tracking')]}
                          label={t("lotType")}
                        />
                      </Grid>
                      {this.props.auth.user.ccx === true && isCannabis &&
                        this.state.strainRequired === true && (
                          <Grid item xs={12}>
                            <AutoCompleteField
                              value={this.state.ccxCatSel}
                              options={this.state.ccxCategories}
                              onChange={this.changeAuto(
                                "ccxCatSel",
                                "ccxCategory"
                              )}
                              error={errors[t('ccxCategory')] != null ? true : false}
                              helperText={errors[t('ccxCategory')]}
                              label={t("ccxCategory")}
                            />
                          </Grid>
                        )}
                          {this.state.strainRequired === true && (
                            <Grid item xs={12}>
                                <TextField
                                    value={this.state.itemName}
                                    onChange={this.changeValue}
                                    name="itemName"
                                    size="medium"
                                    fullWidth={true}
                                    label={t("itemName")}
                                    required
                                    error={errors[t('itemName')] != null ? true : false}
                                    helperText={errors[t('itemName')]}
                                />
                            </Grid>
                        )}
                        {this.props.auth.user.internal && (
                            <Grid item xs={12}>
                            <TextField
                                value={this.state.exportName}
                                onChange={this.changeValue}
                                name="exportName"
                                size="medium"
                                fullWidth={true}
                                label={t("exportName")}
                                variant="outlined"
                                helperText="Internal Only"
                            />
                            </Grid>
                        )}
                    </Grid>
                  )}
                </FormControl>
              </Grid>
              <Grid item container xs={12} md={4}>
                {isCannabis &&
                    <FormControl component="fieldset">
                        <FormLabel component="legend">
                            {t("cannabisOptions")}
                        </FormLabel>
                        {(isFlower || this.state.isPollen) &&
                            <FormControlLabel
                                disabled={this.state.viewingOnly}
                                control={
                                <Switch
                                    checked={this.state.strainRequired}
                                    onChange={this.boolChange("strainRequired")}
                                    name="strainRequired"
                                />
                                }
                                label={t("strain")}
                            />
                        }
                        {isFlower && 
                            <FormControlLabel
                                disabled={this.state.viewingOnly}
                                control={
                                <Switch
                                    checked={this.state.dryingRequired}
                                    onChange={this.boolChange("dryingRequired")}
                                    name="dryingRequired"
                                />
                                }
                                label={t("drying")}
                            />
                        }
                        {isFlower &&
                            <FormControlLabel
                                disabled={this.state.viewingOnly}
                                control={
                                <Switch
                                    checked={this.state.trimmingRequired}
                                    onChange={this.boolChange("trimmingRequired")}
                                    name="trimmingRequired"
                                />
                                }
                                label={t("trimming")}
                            />
                        }
                        {isFlower &&
                            <FormControlLabel
                                disabled={this.state.viewingOnly}
                                control={
                                <Switch
                                    checked={this.state.curingRequired}
                                    onChange={this.boolChange("curingRequired")}
                                    name="curingRequired"
                                />
                                }
                                label={t("curing")}
                            />
                        }
                        {isExtract &&
                            <FormControlLabel
                                disabled={this.state.viewingOnly}
                                control={
                                <Switch
                                    checked={this.state.itemThcMg}
                                    onChange={this.boolChange("itemThcMg")}
                                    name="itemThcMg"
                                />
                                }
                                label={t("thcMgItem")}
                            />
                        }
                        {isExtract &&
                            <FormControlLabel
                                disabled={this.state.viewingOnly}
                                control={
                                <Switch
                                    checked={this.state.lotThcMg}
                                    onChange={this.boolChange("lotThcMg")}
                                    name="lotThcMg"
                                />
                                }
                                label={t("thcMgLot")}
                            />
                        }
                        {isExtract &&
                            <FormControlLabel
                                disabled={this.state.viewingOnly}
                                control={
                                <Switch
                                    checked={this.state.lowThcLiquid}
                                    onChange={this.boolChange("lowThcLiquid")}
                                    name="lowThcLiquid"
                                />
                                }
                                label={t("lowThcLiquid")}
                            />
                        }
                        {isExtract &&
                            <FormControlLabel
                                disabled={this.state.viewingOnly}
                                control={
                                <Switch
                                    checked={this.state.lowThcSolid}
                                    onChange={this.boolChange("lowThcSolid")}
                                    name="lowThcSolid"
                                />
                                }
                                label={t("lowThcSolid")}
                            />
                        }
                         {this.state.isPackaged &&
                            <FormControlLabel
                            disabled={this.state.viewingOnly}
                            control={
                                <Switch
                                checked={this.state.caseLot}
                                onChange={this.boolChange("caseLot")}
                                name="caseLot"
                                />
                            }
                            label={t("caseLot")}
                            style={{ width: "100%" }}
                            />
                        }
                    </FormControl>
                }
              </Grid>
              <Grid item container xs={12} md={4}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {t("inventoryOptions")}
                  </FormLabel>
                  {this.props.auth.user.customLotNumbers === true && (
                    <FormControlLabel
                      disabled={this.state.viewingOnly}
                      control={
                        <Switch
                          checked={this.state.customLotNumber}
                          onChange={this.boolChange("customLotNumber")}
                          name="customLotNumber"
                        />
                      }
                      label={t("customLotNumber")}
                    />
                  )}
                  {!isCannabis &&
                    <FormControlLabel
                        disabled={this.state.viewingOnly}
                        control={
                        <Switch
                            checked={this.state.infinite}
                            onChange={this.boolChange("infinite")}
                            name="infinite"
                        />
                        }
                        label={t("infinite")}
                    />
                    }
                    {!isFlower &&
                        <FormControlLabel
                            disabled={this.state.viewingOnly}
                            control={
                            <Switch
                                checked={this.state.hasCount}
                                onChange={this.boolChange("hasCount")}
                                name="hasCount"
                            />
                            }
                            label={t("includeCount")}
                        />
                    }
                  <FormControlLabel
                    disabled={this.state.viewingOnly}
                    control={
                      <Switch
                        checked={this.state.tareWeight}
                        onChange={this.boolChange("tareWeight")}
                        name="tareWeight"
                      />
                    }
                    label={t("tareWeight")}
                  />
                  {isCannabis && !isFlower &&
                    <FormControlLabel
                        disabled={this.state.viewingOnly}
                        control={
                        <Switch
                            checked={this.state.discreteWeight}
                            onChange={this.boolChange("discreteWeight")}
                            name="discreteWeight"
                        />
                        }
                        label={t("discreteWeight")}
                    />
                    }
                    {isCannabis && 
                        <FormControlLabel
                            disabled={this.state.viewingOnly}
                            control={
                            <Switch
                                checked={this.state.packagedOn}
                                onChange={this.boolChange("packagedOn")}
                                name="packagedOn"
                            />
                            }
                            label={t("packagedOn")}
                        />
                    }
                </FormControl>
              </Grid>
            </Grid>
            {!isCannabis && 
                <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid item container xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">
                        {t("inventoryType")}
                        </FormLabel>
                        <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            {!micro && 
                                <Aux>
                                    <FormControlLabel
                                        disabled={this.state.viewingOnly}
                                        control={
                                            <Switch
                                            checked={this.state.growing}
                                            onChange={this.boolChange("growing")}
                                            name="growing"
                                            />
                                        }
                                        label={t("growingSupplies")}
                                        style={{ width: "100%" }}
                                    />
                                    <FormControlLabel
                                        disabled={this.state.viewingOnly}
                                        control={
                                            <Switch
                                            checked={this.state.pesticide}
                                            onChange={this.boolChange("pesticide")}
                                            name="pesticide"
                                            />
                                        }
                                        label={t("pesticide")}
                                        sx={{ width: "100%" }}
                                    />
                                    <FormControlLabel
                                        disabled={this.state.viewingOnly}
                                        control={
                                            <Switch
                                            checked={this.state.feeding}
                                            onChange={this.boolChange("feeding")}
                                            name="feeding"
                                            />
                                        }
                                        label={t("feedingSupplies")}
                                        style={{ width: "100%" }}
                                    />
                                    <FormControlLabel
                                        disabled={this.state.viewingOnly}
                                        control={
                                            <Switch
                                            checked={this.state.feedingPremix}
                                            onChange={this.boolChange("feedingPremix")}
                                            name="feedingPremix"
                                            />
                                        }
                                        label={t("feedingPremix")}
                                        style={{ width: "100%" }}
                                    />
                                    <FormControlLabel
                                        disabled={this.state.viewingOnly}
                                        control={
                                            <Switch
                                            checked={this.state.biological}
                                            onChange={this.boolChange("biological")}
                                            name="biological"
                                            />
                                        }
                                        label={t("biological")}
                                        style={{ width: "100%" }}
                                    />
                                </Aux>
                            }
                        </Grid>
                        {!micro &&
                            <Grid item xs={12} md={4}>
                                <FormControlLabel
                                disabled={this.state.viewingOnly}
                                control={
                                    <Switch
                                    checked={this.state.cleaningSolution}
                                    onChange={this.boolChange("cleaningSolution")}
                                    name="cleaningSolution"
                                    />
                                }
                                label={t("cleaningSolution")}
                                style={{ width: "100%" }}
                                />
                                <FormControlLabel
                                disabled={this.state.viewingOnly}
                                control={
                                    <Switch
                                    checked={this.state.cleaner}
                                    onChange={this.boolChange("cleaner")}
                                    name="cleaner"
                                    />
                                }
                                label={t("cleaningSupplies")}
                                style={{ width: "100%" }}
                                />
                                <FormControlLabel
                                disabled={this.state.viewingOnly}
                                control={
                                    <Switch
                                    checked={this.state.pesticideSolution}
                                    onChange={this.boolChange("pesticideSolution")}
                                    name="pesticideSolution"
                                    />
                                }
                                label={t("pesticideSolution")}
                                style={{ width: "100%" }}
                                />
                                <FormControlLabel
                                disabled={this.state.viewingOnly}
                                control={
                                    <Switch
                                    checked={this.state.feedingSolution}
                                    onChange={this.boolChange("feedingSolution")}
                                    name="feedingSolution"
                                    />
                                }
                                label={t("feedingSolution")}
                                style={{ width: "100%" }}
                                />
                                <FormControlLabel
                                disabled={this.state.viewingOnly}
                                control={
                                    <Switch
                                    checked={this.state.ppe}
                                    onChange={this.boolChange("ppe")}
                                    name="ppe"
                                    />
                                }
                                label={t("ppe")}
                                style={{ width: "100%" }}
                                />
                                <FormControlLabel
                                disabled={this.state.viewingOnly}
                                control={
                                    <Switch
                                    checked={this.state.pestTrap}
                                    onChange={this.boolChange("pestTrap")}
                                    name="pestTrap"
                                    />
                                }
                                label={t("pestTrap")}
                                style={{ width: "100%" }}
                                />
                            </Grid>
                            }
                        <Grid item />
                        </Grid>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    </Grid>
                </Grid>
                </Grid>
            }
            <br/>
            {this.state._id != null && this.state._id !== '' &&
                <Aux>
                    <ObjectLog id={this.state._id}/>
                </Aux>
            }
          </ModalDialog>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(ItemCategoryDialog));
