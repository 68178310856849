import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel'
import Panel from '../UI/Panel/Panel';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import parse from 'html-react-parser';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class EmailSent extends Component{
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            status: null,
            date: '',
            sentDate: '',
            template: null,
            content: '',
            user: null,
            tenant: null
        };
    }

    async componentDidMount(){
        if(this.props.match.params.id != null && this.props.match.params.id !== ''){
            let record = await axios.get('/api/emailsent/' + this.props.match.params.id);
            this.setState({
                _id: record.data._id,
                number: record.data.number,
                status: record.data.status,
                date: record.data.date,
                sentDate: record.data.sentDate,
                template: record.data.template,
                content: record.data.content,
                user: record.data.user,
                tenant: record.data.tenant
            }, () => {
                const crumbs = [
                    { path: '/#/emailssent', label: this.props.t('emails'), screen: this.props.auth.screenDefs.EmailSentList},
                    { path: 'active', label: this.props.t('email') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.EmailSent}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            });
        }else {
            const crumbs = [
                { path: '/#/emailssent', label: this.props.t('emails'), screen: this.props.auth.screenDefs.EmailSentList},
                { path: 'active', label: this.props.t('email'), screen: this.props.auth.screenDefs.EmailSent}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        }
        
    }

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar />
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <FormControl>
                                <FormLabel>{t('number')}</FormLabel>
                                {this.state.number}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <FormControl>
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.status != null ? this.state.status.label : ''}
                            </FormControl>
                        </Grid>
                        {this.state.date != null && this.state.date !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('date')}</FormLabel>
                                    <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.sentDate != null && this.state.sentDate !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('sentDate')}</FormLabel>
                                    <DateDisplay value={this.state.sentDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.template != null &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('template')}</FormLabel>
                                    {this.state.template.name}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.user != null &&
                            <Grid item xs={6} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('user')}</FormLabel>
                                    {this.state.user.firstName + ' ' + this.state.user.lastName}
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    {this.state.content != null && this.state.content !== '' &&
                        <Grid container spacing={3}>
                            <Grid item sm={12}>
                                <FormControl>
                                    <FormLabel>{t('content')}</FormLabel>
                                    {parse(this.state.content)}
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(EmailSent));