import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import ActionBar from '../UI/Buttons/ActionBar';
import TextField from '../UI/TextField/TextField';
import Grid from '@mui/material/Grid';
import { withTranslation } from 'react-i18next';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import common from '../../jsons/common.json';
import fileDownload from 'js-file-download';
import Excel from 'exceljs';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class Routes extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            pages: 1,
            listSize: 10,
            orderBy: 'number',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            security: [],
            number: '',
            name: '',
            types: [],
            type: '',
            typeSel: null,
            location: '',
            locationSel: null,
            locations: []
        };
    }
    
    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location, common.cacheValues.processingType, common.cacheValues.routingType], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        let storage = sessionStorage.getItem('routings');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        this.setState({
            types: cacheValues.processingTypes,
            locations: locations,
            offset: storage != null ? storage.offset : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'number',
            ordDir: storage != null ? storage.ordDir : 1,
            number: storage != null ? storage.number : '',
            name: storage != null ? storage.name : '',
            type: storage != null ? storage.type : '',
            typeSel: storage != null ? storage.typeSel : null,
            page: storage != null ? storage.page : 0,
            location: storage != null ? storage.location : '',
            locationSel: storage != null ? storage.locationSel : null
        }, () => {
            this.loadRecords();
        });
    }

    async loadRecords(){
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            offset: this.state.offset,
            size: this.state.listSize,
            sort: JSON.stringify(sort),
            name: this.state.name,
            number: this.state.number,
            type: this.state.type,
            location: this.state.location
        }
        const list = await axios.get('api/routes', {params: data});
        this.setState({
            list: list.data.data,
            pages: Math.ceil(list.data.totalCount / this.state.listSize),
            totalCount: list.data.totalCount,
            security: this.props.permission
        })
        const crumbs = [
            { path: 'active', label: this.props.t('routings'), screen: this.props.auth.screenDefs.Routings}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        this.saveFilters();
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            name: this.state.name,
            number: this.state.number,
            type: this.state.type,
            typeSel: this.state.typeSel,
            page: this.state.page,
            location: this.state.location,
            locationSel: this.state.locationSel
        };
        sessionStorage.setItem('routings', JSON.stringify(data));
    }

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => this.loadRecords());
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => { this.loadRecords();});
    };

    sortClick(name) {
        let orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.listSize);

        this.setState({ offset: offset }, () => {
          this.loadRecords();
        });
    }

    download = async (e) => {
        const t = this.props.t;
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            sort: JSON.stringify(sort),
            name: this.state.name,
            number: this.state.number,
            type: this.state.type,
            location: this.state.location
        }
        const result = await axios.get('api/routingexport', {params: data});
        let excelData = result.data;
        const workbook = new Excel.Workbook();
        workbook.created = new Date();
        workbook.properties.date1904 = true;
        workbook.views = [
            {
            x: 0, y: 0, width: 25000, height: 15000,
            firstSheet: 0, activeTab: 1, visibility: 'visible'
            }
        ];
        const sheet = workbook.addWorksheet('Routings');
        sheet.state = 'visible';
        let startRow = 1;
        for(let record of excelData){
            startRow = this.routingPrint(startRow, record, sheet, t);
            sheet.getCell('A' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('B' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('C' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('D' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('E' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('F' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('G' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('H' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('I' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('J' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('K' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('L' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('M' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('N' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('O' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('P' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            startRow++;
        }
        let buffer = await workbook.xlsx.writeBuffer();
        fileDownload(buffer, 'EvoraRoutings.xlsx');
    };

    routingPrint(startRow, record, sheet, t){
        let currRow = startRow;
        sheet.mergeCells('A' + currRow + ':J' + currRow);
        sheet.getCell('A' + currRow).value = record.number + '-' + record.name;
        sheet.getCell('C' + currRow).value = record.location;
        currRow++;
        sheet.getCell('A' + currRow).value = t('type');
        sheet.getCell('B' + currRow).value = record.type;
        currRow++;
        sheet.getCell('A' + currRow).value = t('lines');
        currRow++;
        sheet.getCell('A' + currRow).value = t('number');
        sheet.getCell('B' + currRow).value = t('runEffort');
        sheet.getCell('C' + currRow).value = t('room');
        sheet.getCell('D' + currRow).value = t('machineType');
        sheet.getCell('E' + currRow).value = t('equipment');
        sheet.getCell('F' + currRow).value = t('critical');
        sheet.getCell('G' + currRow).value = t('setupTime');
        sheet.getCell('H' + currRow).value = t('runTime');
        sheet.getCell('I' + currRow).value = t('waitTime');
        sheet.getCell('J' + currRow).value = t('moveTime');
        sheet.getCell('K' + currRow).value = t('fixedScrapAmount');
        sheet.getCell('L' + currRow).value = t('scrapPercent');
        sheet.getCell('M' + currRow).value = t('minProcessTime');
        sheet.getCell('N' + currRow).value = t('maxProcessTime');
        sheet.getCell('O' + currRow).value = t('cleanRoomBefore');
        sheet.getCell('P' + currRow).value = t('cleanRoomAfter');
        currRow++
        for(let row of record.steps){
            sheet.getCell('A' + currRow).value = row.sequence;
            sheet.getCell('B' + currRow).value = row.runEffort;
            sheet.getCell('C' + currRow).value = row.room != null ? row.room.name : '';
            sheet.getCell('F' + currRow).value = row.critical === true ? t('yes') : t('no');
            sheet.getCell('G' + currRow).value = row.setupTime != null ? row.setupTime + row.setupTimeUOM : '';
            sheet.getCell('H' + currRow).value = row.runTime != null ? row.runTime + row.runTimeUOM : '';
            sheet.getCell('I' + currRow).value = row.waitTime != null ? row.waitTime + row.waitTimeUOM : '';
            sheet.getCell('J' + currRow).value = row.moveTime != null ? row.moveTime + row.moveTimeUOM : '';
            sheet.getCell('K' + currRow).value = row.scrapQuantity != null ? row.fixedScrapQuantity + row.fixedScrapUOM : '';
            sheet.getCell('L' + currRow).value = row.scrapPercent;
            sheet.getCell('M' + currRow).value = row.minProcessTime != null ? row.minProcessTime + row.minProcessTimeUOM : '';
            sheet.getCell('N' + currRow).value = row.maxProcessTime != null ? row.maxProcessTime + row.maxProcessTimeUOM : '';
            sheet.getCell('O' + currRow).value = row.cleanRoomBefore === true ? t('yes') : t('no');
            sheet.getCell('P' + currRow).value = row.cleanRoomAfter === true ? t('yes') : t('no');
            for(let equip of row.equipments){
                sheet.getCell('D' + currRow).value = equip.machineType;
                sheet.getCell('E' + currRow).value = equip.equipment != null ? equip.equipment.identityNo + '-' + equip.equipment.name : '';
                currRow++;
            }
        }
        return currRow;
    }

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/routing'
                        createAccess={security.create}
                        excelClick={this.download}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.number} onChange={this.changeValue} name="number"
                            size="medium" fullWidth={true} label={t('number')} data-cy="number"/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.name} onChange={this.changeValue} name="name"
                            size="medium" fullWidth={true} label={t('name')} data-cy="name"/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.typeSel}
                                options={this.state.types}
                                onChange={this.changeAuto('typeSel', 'type')}
                                label={t('type')}
                                data-cy="type"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.locationSel}
                                options={this.state.locations}
                                onChange={this.changeAuto('locationSel', 'location')}
                                label={t('location')}
                                data-cy="location"
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Routing List">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('name')}
                                        active={this.state.orderBy === 'name'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('name')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('type')}
                                        active={this.state.orderBy === 'type'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('type')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('location')}
                                        active={this.state.orderBy === 'location'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('location')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/routing/' + row._id} name={'routingLink' + i}>{row.number}</Link></TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.type != null ? row.type.label : ''}</TableCell>
                                        <TableCell>{row.location != null ? row.location.name : ''}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(Routes));