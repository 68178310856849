import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Logo from './Logo/Logo';
import Profile from './Buttons/Profile';
// import Activity from './Buttons/Activity';
import Notifications from './Buttons/Notifications';
import Mail from './Buttons/Mail';
import Aux from '../../../hoc/Auxilary/Auxilary';
import BarcodeScanner from './Buttons/BarcodeScanner';

export default function Top(props) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const location = 'top';
  const { clicked, open } = props;
  const topBar = (
    <AppBar
        enableColorOnDark
        position="fixed"
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 1,
          width: open ? `calc(100% - ${props.drawerWidth}px)` : '100%vw',
          marginLeft: open ? props.drawerWidth : null,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen, }
          )
        })}
    >
      <Toolbar>
        {!isMobile &&
          <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={clicked}
              sx={{
                marginRight: 2,
                display: open ? 'none' : null
              }} 
          >
              <MenuIcon/>
          </IconButton>
        }
        <Logo height="30" width="auto" alt="logo" maxHeight='50px'/>
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
        </Typography>
        {!isMobile &&
          <Aux>
            <BarcodeScanner />
            {/* <Activity location={location} /> */}
            <Mail location={location} />
            <Notifications location={location} />
          </Aux>          
        }        
        <Profile nodeEnv={props.nodeEnv}/>
      </Toolbar>
    </AppBar>
  );

  return topBar;
}