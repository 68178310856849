import React, { useCallback, useEffect, useState } from 'react';
import isEmpty from '../../../../is-empty';
import base64 from 'base-64';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import { pdfjs } from 'react-pdf';
import { Dropzone, FileMosaic } from "@files-ui/react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import DownloadIcon from '@mui/icons-material/DownloadTwoTone';
import ToolTip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Confirmation from '../../Dialog/Confirmation';
import ModalDialog from '../../Dialog/ModalDialog';
import DateDisplay from '../../DateDisplay/DateDisplay';
import Link from '../../Link/Link';
import { EvoraTabs, TabCanvas } from '../../Tabs/Tabs';
import { UploadButton } from '../Buttons';
import common from '../../../../jsons/common.json';
import AttachmentViewer from './AttachmentViewer';
import { ModalToaster } from '../../Toaster/Toaster';
import EditIcon from '@mui/icons-material/EditTwoTone';
import TextField from '../../TextField/TextField';
import CheckIcon from '@mui/icons-material/CheckRounded';
import ClearIcon from '@mui/icons-material/ClearRounded';

export default function Attachments(props) {
    const { finalDocuments, objectType, recordId, toggleDialog, open } = props;
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const [openViewer, setOpenViewer] = useState(false);
    const [viewing, setViewing] = useState({});
    const [dataFetched, setDataFetched] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState({
        status: false,
        type: ''
    });
    const [index, setIndex] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [uploads, setUploads] = useState([]);
    const [tabKey, setTabKey] = useState(0);
    const [systemMessage, setSystemMessage] = useState(null);
    const [tableControls, setTableControls] = useState({
        totalCount: 0,
        page: 0,
        offset: 0,
        pageCount: 1,
        size: 5,
        documentCount: 0
    });
    const [editAttachment, setEditAttachment] = useState(null);
    const [editDocument, setEditDocument] = useState(null);
    const [newFileName, setNewFileName] = useState('');
    const [errors, setErrors] = useState({});
    const dateFormat = useSelector(state => state.auth.user.dateFormat);
    const security = useSelector(state => state.security.currentPath);
    const tabLabels = [
        { name: t('finalDocuments'), disabled: false },
        { name: t('attachments'), disabled: false },
        { name: t('upload'), disabled: !security.documentUpload }
    ]

    const apiCall = useCallback(async () => {
        try {
            const data = {
                ...tableControls,
                finalDocuments: finalDocuments
            }
            const attachmentList = await axios.post('/api/documentlist/' + objectType + '/' + recordId, data);
            setAttachments(attachmentList.data.attachments);
            setDocuments(attachmentList.data.documents);
            setTableControls(prevControls => ({
                ...prevControls, 
                totalCount: attachmentList.data.attachmentCount,
                documentCount: attachmentList.data.documentCount
            }));
            setDataFetched(true);
        } catch(err) {
            console.error(err);            
        }
    }, [finalDocuments, objectType, recordId, tableControls ]);
    
    useEffect(() => {
        if (!dataFetched) {
            apiCall();
        }
    }, [objectType, recordId, apiCall, dataFetched]);

    const handleOpenViewer = record => {
        setOpenViewer(true);
        setViewing(record);
    }

    const handleDownload = async (record) => {
        pdfjs.GlobalWorkerOptions.workerSrc = new URL(
            'pdfjs-dist/build/pdf.worker.min.mjs',
            import.meta.url,
          ).toString();
        let data = await axios.get('/api/document/' + record._id);
        let link = document.createElement("a");
        link.download = record.name;
        link.href = 'data:application/octet-stream;base64,' + data.data;
        link.click();
    }

    const changeTab = (event, newValue) => {
        if(tabKey === 1) {
            setTableControls({
                ...tableControls, 
                page: 0, 
                offset: 0
            });
            setDataFetched(false);
            if(editDocument != null)
                setEditDocument(null);
        }else if(tabKey === 2) {
            if(editAttachment != null)
                setEditAttachment(null);
        }
        setTabKey(newValue);
    };

    const toggleConfirm = (type, i) => {
        setIndex(i != null ? i : '');
        setConfirmDelete({ status: !confirmDelete.status, type: type });
    }

    const confirmClick = (answer) => async (e) => {
        if(answer === 'yes'){
            let doc = {};
            if(confirmDelete.type === 'documents')
                doc = documents[index];
            else if(confirmDelete.type === 'attachments')
                doc = attachments[index];
            if(!isEmpty(doc)){
                let data = {
                    objectType: objectType,
                    recordId: recordId,
                    _id: doc._id,
                    property: props.propName
                };
                try {
                    let result = await axios.post('/api/documents/delete', data)
                    if(result.status === 200) {
                        setSystemMessage({ 
                            message: result.data.message, 
                            title: t('success'), 
                            severity: 'success',
                            onClose: () => setSystemMessage(null)
                        });
                        setDataFetched(false);
                    }
                } catch(err){
                    setSystemMessage({ 
                        message: err.response.data, 
                        title: t('error'), 
                        severity: 'error',
                        onClose: () => setSystemMessage(null)
                    });
                }
            }else{
                setSystemMessage({ 
                    message: t('noAttachmentSelected'), 
                    title: t('error'), 
                    severity: 'error',
                    onClose: () => setSystemMessage(null)
                });
            }            
        }
        setConfirmDelete({ status: !confirmDelete, type: '' });
    }

    const handleFileUpload = async () => {
        if(!uploading){
            setUploading(true);
            await _handleFileUpload();
        }
    }   

    const _handleFileUpload = async () => {
        try {
            let body = {count: uploads.length};
            let fileReadPromises = uploads.map((upload, index) => {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.onload = () => {
                        body['file' + index] = base64.encode(reader.result);
                        body['fileName' + index] = upload.name;
                        body['type' + index] = upload.type;
                        body['objectId' + index] = recordId;
                        body['objectType' + index] = objectType;
                        if(props.row != null)
                            body['row' + index] = props.row;
                        if(props.propName != null)
                            body['property' + index] = props.propName;
                        resolve();
                    };
                    reader.onerror = reject;
                    reader.readAsBinaryString(upload.file);
                });
            });    
            await Promise.all(fileReadPromises);
            let result = await axios.post('/api/uploadattachments', body);
            if(result.status === 200) {
                setSystemMessage({ 
                    message: result.data.message, 
                    title: t('success'), 
                    severity: 'success',
                    onClose: () => setSystemMessage(null)
                });
                setDataFetched(false);
                setTabKey(1);
                setUploads([]);
            }
        } catch(err){
            setSystemMessage({ 
                message: err.response.data, 
                title: t('error'), 
                severity: 'error',
                onClose: () => setSystemMessage(null)
            });
        }
        setUploading(false);
    }

    const removeFile = (id) => {
        setUploads(uploads.filter((x) => x.id !== id));
    };

    const handleDrop = (incommingFiles) => {
        setUploads(incommingFiles);
    };

    const handleChangePage = (event, newPage) => {
        setTableControls({
            ...tableControls, 
            page: newPage, 
            offset: newPage * tableControls.size
        });
        setDataFetched(false);
    }

    const handleRowsPerPage = e => {
        setTableControls({
            ...tableControls, 
            size: parseInt(e.target.value)
        });
        setDataFetched(false);
    }

    const buttonStack = () => {
        let buttons = [];
        if(tabKey !== 2){
            buttons = [
                <Button key='cancelButton' variant="text" size='small' color="secondary" onClick={toggleDialog}>{t('close')}</Button>,
            ]
        }else{ 
            buttons = [
                <Button key='cancelButton' variant="text" size='small' color="secondary" onClick={toggleDialog}>{t('close')}</Button>,
                <UploadButton key='uploadButton' uploadClick={() => handleFileUpload()} enabled={!uploading}/>
            ]
        };
        return buttons;
    }

    const changeValue = (e) => {
        setNewFileName(e.target.value);
    }

    const saveFileName = async (i, type) => {
        if(newFileName === ''){
            setErrors({fileName: t('required')});
        }else{
            let originalName = type === 'documents' ? documents[i].name : attachments[i].name;
            let originalExtension = originalName.substring(originalName.lastIndexOf('.') + 1);
            let newExtension = newFileName.substring(newFileName.lastIndexOf('.') + 1);
            if(originalExtension !== newExtension){
                setErrors({fileName: t('extensionMismatch')});
                return;
            }
            let data = {
                _id: type === 'documents' ? documents[i]._id : attachments[i]._id,
                name: newFileName
            };
            try {
                let result = await axios.post('/api/documents/updatefilename', data)
                if(result.status === 200) {
                    setSystemMessage({ 
                        message: result.data.message, 
                        title: t('success'), 
                        severity: 'success',
                        onClose: () => setSystemMessage(null)
                    });
                    setNewFileName('');
                    if(type === 'documents')
                        setEditDocument(null);
                    else
                        setEditAttachment(null);
                    setDataFetched(false);
                }
            } catch(err){
                setSystemMessage({ 
                    message: err.response.data, 
                    title: t('error'), 
                    severity: 'error',
                    onClose: () => setSystemMessage(null)
                });
            }
        }
    }

    const clearFileName = () => {
        if(newFileName !== '')
            setNewFileName('');
        if(editDocument != null)
            setEditDocument(null);
        else
            setEditAttachment(null);
    }

    const editFileName = (row, type) => {
        if(type === 'documents'){
            setNewFileName(documents[row].name);
            setEditDocument(row);
        }else{
            setNewFileName(attachments[row].name);
            setEditAttachment(row);
        }
    }
    
    return (
        <>
            <ModalDialog buttonStack={buttonStack()} title={t('attachments')} toggleDialog={() => toggleDialog()}
                        dialogStatus={open} fullWidth maxWidth='md'>
                <EvoraTabs tabLabels={tabLabels} tabKey={tabKey} changeTab={changeTab} />
                <TabCanvas value={tabKey} index={0}>
                    <Grid container spacing={3} sx={{ minHeight: '450px' }}>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table size='small'>
                                    <TableHead>
                                        <TableCell />
                                        <TableCell sx={{ width: '500px'}}>{t('name')}</TableCell>
                                        <TableCell>{t('lastUpdated')}</TableCell>
                                        <TableCell />
                                    </TableHead>
                                    <TableBody>
                                        {documents?.length > 0 ?
                                            documents.map((row, i) => (
                                                <TableRow 
                                                    hover
                                                    key={i}
                                                    >
                                                    <TableCell sx={{ width: '10px' }}>
                                                        <ToolTip title={row.type} placement='right-start'>
                                                            <div>
                                                                {row.type === 'application/pdf' ?
                                                                    <FontAwesomeIcon icon={["far", "file-pdf"]} size='2x' color={theme.palette.primary.main} /> :
                                                                row.type === 'image/png' ?
                                                                    <FontAwesomeIcon icon={["far", "file-image"]} size='2x' color={theme.palette.secondary.main} /> :
                                                                    <FontAwesomeIcon icon={["far", "file"]} size='2x' color={theme.palette.secondary.main} />
                                                                }
                                                            </div>
                                                        </ToolTip>                                                    
                                                    </TableCell>
                                                    <TableCell>
                                                        {editDocument === null || editDocument !== i ? 
                                                            <Link onClick={(event) => handleOpenViewer(row)} name={'openAttachment' + i}>
                                                                {row.name}
                                                            </Link> :
                                                            <>
                                                                <TextField value={newFileName} onChange={(event) => changeValue(event, 'documents')} name="fileName"
                                                                size="medium" label={t('fileName')} required sx={{ width: '375px' }}
                                                                error={errors[t('fileName')] != null ? true : false} helperText={errors[t('fileName')]}/>
                                                                <IconButton 
                                                                    id={'saveFileName' + i}
                                                                    name={'saveFileName' + i}
                                                                    onClick={() => saveFileName(i, 'documents')}
                                                                    sx={{ display: 'inline' }} >
                                                                        <CheckIcon fontSize='small' />
                                                                </IconButton>
                                                                <IconButton 
                                                                    id={'clearFileName' + i}
                                                                    name={'clearFileName' + i}
                                                                    onClick={() => clearFileName()}
                                                                    sx={{ display: 'inline' }} >
                                                                        <ClearIcon fontSize='small' />
                                                                </IconButton>
                                                            </>
                                                        }                                                
                                                    </TableCell>
                                                    <TableCell>
                                                        <DateDisplay value={row.lastUpdated} format={dateFormat}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        {security.update && isEmpty(editDocument) &&
                                                            <IconButton 
                                                                id={'editDocument' + i}
                                                                name={'editDocument' + i}
                                                                onClick={() => editFileName(i, 'documents')}
                                                                sx={{ display: 'inline' }} >
                                                                    <EditIcon fontSize='medium' />
                                                            </IconButton>
                                                        }
                                                        <IconButton
                                                            id={'downloadAttachment' + i}
                                                            name={'downloadAttachment' + i}
                                                            onClick={() => handleDownload(row)}
                                                            sx={{ display: 'inline' }}>
                                                                <DownloadIcon fontSize='medium' />
                                                        </IconButton>
                                                        {security.documentDelete &&
                                                            <IconButton
                                                                id={'deleteAttachment' + i}
                                                                name={'deleteAttachment' + i}
                                                                onClick={() => toggleConfirm('documents', i)}
                                                                sx={{ display: 'inline' }}>
                                                                    <DeleteIcon fontSize='medium' />
                                                            </IconButton>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )) :
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{ textAlign: 'center' }}>{t('noFinalDocuments')}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </TabCanvas>
                <TabCanvas value={tabKey} index={1}>
                    <Grid container spacing={3} sx={{ minHeight: '450px' }}>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table size='small'>
                                    <TableHead>
                                        <TableCell />
                                        <TableCell sx={{ width: '500px'}}>{t('name')}</TableCell>
                                        <TableCell>{t('lastUpdated')}</TableCell>
                                        <TableCell />
                                    </TableHead>
                                    <TableBody>
                                        {attachments?.length > 0 ?
                                            attachments.map((row, i) => (
                                                <TableRow 
                                                    hover
                                                    key={i}
                                                    >
                                                    <TableCell sx={{ width: '10px' }}>
                                                        <ToolTip title={row.type} placement='right-start'>
                                                            <div>
                                                                {row.type === 'application/pdf' ?
                                                                    <FontAwesomeIcon icon={["far", "file-pdf"]} size='2x' color={row.isGenerated ? theme.palette.primary.main : theme.palette.secondary.main} /> :
                                                                row.type === 'image/png' ?
                                                                    <FontAwesomeIcon icon={["far", "file-image"]} size='2x' color={theme.palette.secondary.main} /> :
                                                                    <FontAwesomeIcon icon={["far", "file"]} size='2x' color={theme.palette.secondary.main} />
                                                                }
                                                            </div>
                                                        </ToolTip>                                                    
                                                    </TableCell>
                                                    <TableCell>
                                                        {editAttachment === null || editAttachment !== i ?     
                                                            <Link onClick={(event) => handleOpenViewer(row)} name={'openAttachment' + i}>
                                                                {row.name}
                                                            </Link> :
                                                            <>
                                                                <TextField value={newFileName} onChange={(event) => changeValue(event, 'attachments')} name="fileName"
                                                                size="medium" label={t('fileName')} required sx={{ width: '375px' }}
                                                                error={errors[t('fileName')] != null ? true : false} helperText={errors[t('fileName')]}/>
                                                                <IconButton 
                                                                    id={'saveFileName' + i}
                                                                    name={'saveFileName' + i}
                                                                    onClick={() => saveFileName(i, 'attachments')}
                                                                    sx={{ display: 'inline' }} >
                                                                        <CheckIcon fontSize='small' />
                                                                </IconButton>
                                                                <IconButton 
                                                                    id={'clearFileName' + i}
                                                                    name={'clearFileName' + i}
                                                                    onClick={() => clearFileName()}
                                                                    sx={{ display: 'inline' }} >
                                                                        <ClearIcon fontSize='small' />
                                                                </IconButton>
                                                            </>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <DateDisplay value={row.lastUpdated} format={dateFormat}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        {security.update && isEmpty(editAttachment) &&
                                                            <IconButton 
                                                                id={'editAttachment' + i}
                                                                name={'editAttachment' + i}
                                                                onClick={() => editFileName(i, 'attachments')}
                                                                sx={{ display: 'inline' }} >
                                                                    <EditIcon fontSize='medium' />
                                                            </IconButton>
                                                        }
                                                        <IconButton
                                                            id={'downloadAttachment' + i}
                                                            name={'downloadAttachment' + i}
                                                            onClick={() => handleDownload(row)}
                                                            sx={{ display: 'inline' }}>
                                                                <DownloadIcon fontSize='medium' />
                                                        </IconButton>
                                                        <IconButton
                                                            id='deleteAttachment'
                                                            name='deleteAttachment'
                                                            onClick={() => toggleConfirm('attachments', i)}
                                                            sx={{ display: 'inline' }}>
                                                                <DeleteIcon fontSize='medium' />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )) :
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{ textAlign: 'center' }}>{t('noAttachments')}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow key="tableFooter">
                                            <TablePagination count={tableControls.totalCount}
                                            rowsPerPage={tableControls.size}
                                            onPageChange={handleChangePage}
                                            page = {tableControls.page}
                                            rowsPerPageOptions={common.rowOptions}
                                            onRowsPerPageChange={handleRowsPerPage}/>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </TabCanvas>
                <TabCanvas value={tabKey} index={2}>
                    <Grid container spacing={3} sx={{ minHeight: '450px' }}>
                        <Grid item xs={12} sm={12}>
                            <Dropzone onChange={handleDrop} value={uploads} minHeight='400px'>
                                {uploads.map((file, i) => (
                                    <FileMosaic 
                                        {...file} 
                                        key={'uploadMosiac'+i} 
                                        preview 
                                        onDelete={removeFile} 
                                        info
                                    />
                                ))}
                            </Dropzone>
                        </Grid>
                    </Grid>
                </TabCanvas>
                <ModalToaster alert={systemMessage} />
            </ModalDialog>
            {confirmDelete.status && 
                <Confirmation
                    open={confirmDelete.status}
                    no={confirmClick('no')}
                    noButton={t('no')}
                    yes={confirmClick('yes')}
                    yesButton={t('yes')}
                    title={t('areYouSure')}
                    content={t('deleteDocumentMessage')}
                    toggleDialog={() => setConfirmDelete({
                        status: false,
                        type: ''
                    })}
                />
            }
            {openViewer &&
                <AttachmentViewer
                    open={openViewer}
                    toggleDialog={() => setOpenViewer(!openViewer)}
                    attachment={viewing}
                />
            }
        </>
    )
}