import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { NumericFormat } from 'react-number-format';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Aux from '../../hoc/Auxilary/Auxilary';
import TextField from '../UI/TextField/TextField';
import DateSelect from '../UI/DateSelect/DateSelect';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Grid from '@mui/material/Grid';
import SectionTitle from '../UI/Typography/SectionTitle';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import ActionBar from '../UI/Buttons/ActionBar';
import { SaveButton, DeleteButton } from '../UI/Buttons/Buttons';
import Unlock from '../General/Unlock';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel, Button } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { Link } from 'react-router-dom';
import { List, ListItem } from '@mui/material';

class TestResults extends Component{
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            date: new Date(),
            dateComplete: '',
            vendor: '',
            vendors: [],
            item: '',
            items: [],
            lots: [],
            lotSelections: [],
            testDefinition: '',
            testDefinitions: [],
            result: '',
            results: [],
            categories: [],
            qas: [],
            verifiedSignature: null,
            documents: [],
            signed: false,
            signatureOpen: false,
            managers: [],
            defObject: {},
            type: '',
            types: [],
            number: '',
            authSelected: null,
            vendorSelected: null,
            itemSelected: null,
            defSelected: null,
            typeSelected: null,
            resultSelected: null,
            container: '',
            containerSel: '',
            containers: [],
            tabKey: 0,
            tabLabels: [{name: 'Details', disabled: false}],
            security: [],
            optionsMenu: [],
            flagForSignature: 0,
            enableSave: true,
            status: '',
            statusSel: null,
            statuses: [],
            showComplete: false,
            lotStatus: '',
            lotStatusSel: null,
            lotStatuses: [],
            rating: '',
            ratingSel: null,
            ratings: [],
            form: '',
            hasForm: false,
            overallResults: null,
            testResultValues: [],
            itemObj: null
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.lotStatus, common.cacheValues.testResultValue, common.cacheValues.testableItem], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let vals = await axios.get('/api/testinstancevalues');
        let statusSel = vals.data.statuses.find(x => x.value === this.props.auth.constants.testStatuses.new);
        let hasForm = vals.data.form != null && vals.data.form !== '' ? true : false;
        this.setState({
            vendors: vals.data.vendors,
            items: cacheValues.testableItems,
            lotSelections: vals.data.lots != null ? vals.data.lots : [],
            results: vals.data.results,
            qas: vals.data.qas,
            managers: vals.data.managers,
            types: vals.data.types,
            security: this.props.permission, 
            statuses: vals.data.statuses,
            statusSel: statusSel,
            ratings: vals.data.ratings,
            lotStatuses: cacheValues.lotStatuses,
            status: this.props.auth.constants.testStatuses.new,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && vals.data.form != null && vals.data.form !== '' ? JSON.parse(vals.data.form) : '',
            hasForm: hasForm,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            testResultValues: cacheValues.testResultValues
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/testresults', label: this.props.t('testResults'), screen: this.props.auth.screenDefs.TestResults},
                    { path: 'active', label: this.props.t('testResult'), screen: this.props.auth.screenDefs.TestResults}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });        
    }

    loadRecord = async() => {
        const t = this.props.t;
        let record = await axios.get('/api/testinstance/' + this.state._id);
        let lots = [];
        if(record.data.lots != null && record.data.lots.length > 0){
            for(let lot of record.data.lots){
                for(let val2 of record.data.lotSelections){
                    if(lot === val2.value){
                        lots.push(val2);
                        break;
                    }
                }
            }
        }
        let signed = record.data.verifiedSignature != null;
        let authSelected = this.state.managers.find(x => x.value === record.data.authorizedBy);
        let vendorSelected = this.state.vendors.find(x => x.value === record.data.vendor);
        let itemSelected = this.state.items.find(x => x.value === record.data.item);
        let defSelected = record.data.testDefinitions.find(x => x.value === record.data.testDefinition);
        let typeSelected = this.state.types.find(x => x.value === record.data.type);
        let resultSelected = this.state.results.find(x => x.value === record.data.result);
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let containerSel = null;
        if(record.data.container != null && record.data.containers != null && record.data.containers.length > 0){
            containerSel = record.data.containers.find(x => x.value === record.data.container);
        }
        let tabLabels = [{name: t('details'), disabled: false}];
        record.data.categories.forEach(row => {
            tabLabels.push({name: row.category.description, disabled: false});
        });    
        let categories = record.data.categories;
        for(let cat of categories){
            for(let row of cat.itemResults){
                if(row.result != null){
                    let resultSel = this.state.results.find(x => x.value === row.result);
                    row.resultSel = resultSel;
                }
                if(row.value != null){
                    row.valueSel = this.state.testResultValues.find(x => x.value === row.value);
                }
            }
        }
        const optionsMenu = [
            { 
                security: true, user: this.props.auth.user.id, users: this.state.qas, name: t('verified'), object: record.data._id,
                title: t('verifiedBy'), setId: () => this.loadRecord(), signature: record.data.verifiedSignature, 
                objectType: this.props.auth.constants.objectIds.testInstance, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, 
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = !signed ? 3 : 0;
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        this.setState({
            _id: record.data._id,
            date: record.data.date,
            dateComplete: record.data.dateComplete,
            vendor: record.data.vendor,
            item: record.data.item,
            lots: lots,
            testDefinition: record.data.testDefinition,
            testDefinitions: record.data.testDefinitions,
            defObject: record.data.defObject,
            lotSelections: record.data.lotSelections,
            result: record.data.result,
            categories: categories,
            verifiedSignature: record.data.verifiedSignature,
            documents: record.data.documents,
            signed: signed,
            authorizedBy: record.data.authorizedBy,
            type: record.data.type,
            authSelected: authSelected,
            vendorSelected: vendorSelected,
            itemSelected: itemSelected,
            defSelected: defSelected,
            typeSelected: typeSelected,
            resultSelected: resultSelected,
            number: record.data.number,
            container: record.data.container,
            containerSel: containerSel,
            containers: record.data.containers,
            tabLabels: tabLabels,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            status: record.data.status,
            statusSel: statusSel,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            overallResults: record.data.overallResults != null ? record.data.overallResults : null,
            itemObj: record.data.itemObj
        }, () => {
            const crumbs = [
                { path: '/#/testresults', label: this.props.t('testResults'), screen: this.props.auth.screenDefs.TestResults},
                { path: 'active', label: this.props.t('testResult') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.TestResults}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    };

    changeValue = async e => {
        const name = e.target.name;
        const value = e.target.value;
        let testDefinitions = this.state.testDefinitions;
        let lotSelections = this.state.lotSelections;
        let defObject = this.state.defObject;
        if(name === 'item'){
            if(value != null && value !== ''){
                var defs = await axios.get('/api/itemtestdefinitions/' + value);
                testDefinitions = defs.data;
                var lots = await axios.get('/api/itemlots/' + value);
                lotSelections = lots.data;
            } else {
                testDefinitions = [];
                lotSelections = [];
            }
        }
        let categories = this.state.categories;
        if(name === 'testDefinition'){
            if(value != null && value !== ''){
                var data = await axios.get('/api/generatetestinstance/' + value);
                categories = data.data.categories;
                defObject = data.data.testDefinition;
            } else {
                categories = [];
                defObject = {};
            }
        }
        this.setState({
            [name]: value, 
            testDefinitions: testDefinitions,
            lotSelections: lotSelections,
            categories: categories,
            defObject: defObject
        });
    };

    changeSelect = (name, data) => value => {
        this.setState({[name]: value, [data]: value.value});
        this.processChange(data, value.value);
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            if(newValue != null){
                this.processChange(data, newValue.value)
            }
        });
    };

    async processChange(name, value){
        let testDefinitions = this.state.testDefinitions;
        let lotSelections = this.state.lotSelections;
        let defObject = this.state.defObject;
        let tabLabels = this.state.tabLabels;
        if(name === 'item'){
            if(value != null && value !== ''){
                let defs = await axios.get('/api/itemtestdefinitions/' + value);
                testDefinitions = defs.data;
                let lots = await axios.get('/api/itemlots/' + value, {params: {includeEmpty: true}});
                lotSelections = lots.data;
            } else {
                testDefinitions = [];
                lotSelections = [];
            }
        }
        let categories = this.state.categories;
        if(name === 'testDefinition'){
            if(value != null && value !== ''){
                let data = await axios.post('/api/generatetestinstance', {test: value, item: this.state.item});
                categories = data.data.categories;
                defObject = data.data.testDefinition;
                tabLabels = [{name: 'Details', disabled: false}];
                categories.forEach(row => {
                    tabLabels.push({name: row.category.description, disabled: false});
                });
            } else {
                categories = [];
                defObject = {};
            }
        }
        this.setState({
            testDefinitions: testDefinitions,
            lotSelections: lotSelections,
            categories: categories,
            defObject: defObject,
            tabLabels: tabLabels
        });
    }

    dateChange = e => {
        this.setState({date: e});
    };

    dateCompleteChange = e => {
        this.setState({dateComplete: e});
    };

    changeNumber = (name, catIndex, itemIndex) => (e) => {
        let value = e.target.value;
        let categories = this.state.categories;
        let cat = categories[catIndex];
        let devCat = this.state.defObject.testCategories.find(x => x._id === cat.definitionCategory);
        let expectedCats = this.state.itemObj.outputCategories != null & this.state.itemObj.outputCategories.length > 0 ?
                            this.state.itemObj.outputCategories : this.state.itemObj.strain != null && 
                            this.state.itemObj.strain.outputCategories != null && this.state.itemObj.strain.outputCategories.length > 0 ?
                            this.state.itemObj.strain.outputCategories : []
        let strainCat = expectedCats.find(x => x.category === devCat.category._id);
        cat.itemResults[itemIndex][name] = value;
        let def = this.state.defObject.testCategories[catIndex].testItems[itemIndex];
        let valFloat = parseFloat(value);
        let minFloat = parseFloat(def.minValue);
        let maxFloat = parseFloat(def.maxValue);
        let valid = valFloat >= minFloat && valFloat <= maxFloat;
        if(strainCat != null){
            let strainItem = strainCat.items.find(x => x.item === cat.itemResults[itemIndex].baseItem);
            let expectedFloat = parseFloat(strainItem.expectedValue);

            let difference = valFloat - expectedFloat;
            cat.itemResults[itemIndex].difference = difference.toFixed(3);
        }
        cat.itemResults[itemIndex].result = valid ? this.props.auth.constants.testResults.pass : 
            this.props.auth.constants.testResults.fail;
        let resultSel = this.state.results.find(x => x.value === cat.itemResults[itemIndex].result);
        cat.itemResults[itemIndex].resultSel = resultSel;
        this.setState({categories: categories});
    };

    changeResultAuto = (name, data, catIndex, itemIndex) => (e, newValue) => {
        let categories = this.state.categories;
        let cat = categories[catIndex];
        cat.itemResults[itemIndex][name] = newValue;
        cat.itemResults[itemIndex][data] = newValue != null ? newValue.value : '';
        let def = this.state.defObject.testCategories[catIndex].testItems[itemIndex];
        if(def.value != null && newValue != null){
            cat.itemResults[itemIndex].result = def.value === newValue.value ? this.props.auth.constants.testResults.pass : this.props.auth.constants.testResults.fail; 
            let resultSel = this.state.results.find(x => x.value === cat.itemResults[itemIndex].result);
            cat.itemResults[itemIndex].resultSel = resultSel;           
        } else {
            cat.itemResults[itemIndex].result = '';
            cat.itemResults[itemIndex].resultSel = null;
        }
        this.setState({categories: categories});
    }

    lotChange = async (e, newValue) => {
        let containers = [];
        if(newValue != null && newValue.length > 0){
            for(let row of newValue){
                let lotConts = await axios.get('/api/containerselect', {params: {lot: row.value, available: false}});
                if(lotConts != null && lotConts.data.length > 0){
                    lotConts.data.forEach(cont => {
                        containers.push(cont);
                    });
                }
            }
        }
        
        this.setState({lots: newValue, containers: containers});
    };

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let lots = [];
        this.state.lots.forEach(lot => {
            lots.push(lot.value);
        })
        let docs = [];
        if(this.state.documents != null && this.state.documents.length > 0){
            this.state.documents.forEach(doc => {
                docs.push(doc._id);
            })
        }
        let categories = [];
        this.state.categories.forEach(cat => {
            let rec = {};
            rec.category = cat.category._id;
            rec.definitionCategory = cat.definitionCategory;
            let results = [];
            cat.itemResults.forEach(result => {
                results.push({
                    item: result.item._id,
                    itemDef: result.itemDef,
                    score: result.score,
                    difference: result.difference,
                    result: result.result,
                    value: result.value !== '' ? result.value : null
                })
            })
            rec.itemResults = results;
            categories.push(rec);
        })
        let data = {
            _id: this.state._id,
            testDefinition: this.state.testDefinition,  
            date: this.state.date,
            dateComplete: this.state.dateComplete,
            authorizedBy: this.state.authorizedBy,
            vendor: this.state.vendor,
            item: this.state.item,
            lots: lots,
            result: this.state.result,
            categories: categories,
            documents: this.state.documents,
            type: this.state.type,
            container: this.state.container,
            status: this.state.status,
            number: this.state.number,
            form: form != null && form !== '' ? JSON.stringify(form) : null
        }
        try {
            let result = await axios.post('/api/testinstance', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/testresult/' + result.data.id);
            this.setState({_id: result.data.id, number: result.data.number, enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });           
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        const isFinal = state.defObject._id != null && state.defObject.type === this.props.auth.constants.testResults.final;
        if(state.date == null || state.date === '')
            errors[t('date')] = t('required');
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(state.testDefinition == null || state.testDefinition === '')
            errors[t('testDefinition')] = t('required');
        if(state.authorizedBy == null || state.authorizedBy === '')
            errors[t('authorizedBy')] = t('required');
        if(state.vendor == null || state.vendor === '')
            errors[t('vendor')] = t('required');
        if(state.item == null || state.item === '')
            errors[t('item')] = t('required');
        if(state.lots == null || state.lots.length === 0)
            errors[t('lots')] = t('required');
        if(isFinal){
            if(state.lots != null && state.lots.length > 1)
                errors[t('lots')] = t('testOneLot');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e));
    }

    _deleteClick = async (e) => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== '' && this.state.security.delete){
            try {
                let result = await axios.delete('/api/testinstance/' + this.state._id);
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.setState({enableSave: true});
        }
    };

    complete = async (e) => {
        this.setState({showComplete: true})
    };

    closeComplete = () => {
        this.setState({showComplete: false})
    };

    processComplete = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._processComplete(e));
    };

    _processComplete = async () => {
        const t = this.props.t;
        if((this.state.lotStatus != null && this.state.lotStatus !== '') || (this.state.rating != null && this.state.rating !== '')){
            let data = {_id: this.state._id, lotStatus: this.state.lotStatus, rating: this.state.rating};
            try {
                let result = await axios.post('/api/testinstancecomplete', data);
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.setState({showComplete: false, enableSave: true});
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        }
    };
    
    render(){
        const signed = this.state.signed;
        const errors = this.props.errors;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.testInstance}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabPanel value={this.state.tabKey} index={0} key={'tabPanel-' + 0}>
                    <Grid container spacing={3}>
                        {!signed &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="text" value={this.state.number} disabled={signed} onChange={this.changeValue}
                                size="medium" fullWidth={true} label={t('number')} name="number"
                                error={errors[t('number')] != null ? true : false} helperText={errors[t('number')]}/>
                            </Grid>
                        }
                        {signed &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            <FormControl>
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSel != null ? this.state.statusSel.label : ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!signed &&
                                <DateSelect
                                    onChange={this.dateChange}
                                    value={this.state.date}
                                    helperText={errors[t('date')]}
                                    error={errors[t('date')] != null ? true : false}
                                    disabled={signed}
                                    label={t('dateSent')}
                                    format={this.props.auth.user.dateFormat}
                                    required
                                />
                            }
                            {signed &&
                                 <FormControl>
                                    <FormLabel>{t('dateSent')}</FormLabel>
                                    <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!signed &&
                                <AutoCompleteField
                                    value={this.state.authSelected}
                                    options={this.state.managers}
                                    onChange={this.changeAuto('authSelected', 'authorizedBy')}
                                    error={errors[t('authorizedBy')] != null ? true : false}
                                    helperText={errors[t('authorizedBy')]}
                                    label={t('authorizedBy')}
                                    disabled={signed}
                                    required
                                />
                            }
                            {signed &&
                                 <FormControl>
                                    <FormLabel>{t('authorizedBy')}</FormLabel>
                                    {this.state.authSelected != null ? this.state.authSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!signed &&
                                <AutoCompleteField
                                    value={this.state.vendorSelected}
                                    options={this.state.vendors}
                                    onChange={this.changeAuto('vendorSelected', 'vendor')}
                                    error={errors[t('vendor')] != null ? true : false}
                                    helperText={errors[t('vendor')]}
                                    label={t('vendor')}
                                    disabled={signed}
                                    required
                                />
                            }
                            {signed && this.state.vendorSelected != null &&
                                 <FormControl>
                                    <FormLabel>{t('vendor')}</FormLabel>
                                    <Link to={'/vendor/' + this.state.vendorSelected.value}>{this.state.vendorSelected != null ? this.state.vendorSelected.label : ''}</Link>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!signed &&
                                <AutoCompleteField
                                    value={this.state.itemSelected}
                                    options={this.state.items}
                                    onChange={this.changeAuto('itemSelected', 'item')}
                                    error={errors[t('item')] != null ? true : false}
                                    helperText={errors[t('item')]}
                                    label={t('item')}
                                    disabled={signed}
                                    required
                                />
                            }
                            {signed && this.state.itemSelected != null &&
                                 <FormControl>
                                    <FormLabel>{t('item')}</FormLabel>
                                    <Link to={'/item/' + this.state.itemSelected.value}>{this.state.itemSelected != null ? this.state.itemSelected.label : ''}</Link>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!signed &&
                                <AutoCompleteField
                                    value={this.state.defSelected}
                                    options={this.state.testDefinitions}
                                    onChange={this.changeAuto('defSelected', 'testDefinition')}
                                    error={errors[t('testDefinition')] != null ? true : false}
                                    helperText={errors[t('testDefinition')]}
                                    label={t('test')}
                                    disabled={signed}
                                    required
                                />
                            }
                            {signed && this.state.defSelected != null &&
                                 <FormControl>
                                    <FormLabel>{t('test')}</FormLabel>
                                    <Link to={'/testDefinition/' + this.state.defSelected.value}>{this.state.defSelected != null ? this.state.defSelected.label : ''}</Link>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!signed &&
                                <AutoCompleteField
                                    value={this.state.typeSelected}
                                    options={this.state.types}
                                    onChange={this.changeAuto('typeSelected', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]}
                                    label={t('type')}
                                    disabled={signed}
                                    required
                                />
                            }
                            {signed &&
                                 <FormControl>
                                    <FormLabel>{t('type')}</FormLabel>
                                    {this.state.typeSelected != null ? this.state.typeSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item sm={6}>
                            {!signed &&
                                <AutoCompleteField
                                    multiple={true}
                                    value={this.state.lots}
                                    options={this.state.lotSelections}
                                    onChange={this.lotChange}
                                    error={errors[t('lots')] != null ? true : false}
                                    helperText={errors[t('lots')]}
                                    label={t('lots')}
                                    disabled={signed}
                                    required
                                />
                            }
                            {signed && this.state.lots != null && this.state.lots.length > 0 &&
                                <FormControl>
                                    <FormLabel>{t('lots')}</FormLabel>
                                    <List style={{ display: 'flex', flexDirection: 'row', padding: 2 }}>
                                        {this.state.lots.map((row, j) =>
                                            <ListItem disableGutters={true} disablePadding={true} key={'lot' + j}><Link to={'/lot/' + row.value}>{row.label}</Link>&nbsp;&nbsp;</ListItem>
                                        )}
                                    </List>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!signed &&
                                <AutoCompleteField
                                    value={this.state.containerSel}
                                    options={this.state.containers}
                                    onChange={this.changeAuto('containerSel', 'container')}
                                    error={errors[t('container')] != null ? true : false}
                                    helperText={errors[t('container')]}
                                    label={t('container')}
                                    disabled={signed}
                                    groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                    noneMessage={t('noLotAssigned')}
                                />
                            }
                            {signed && this.state.containerSel != null &&
                                 <FormControl>
                                    <FormLabel>{t('container')}</FormLabel>
                                    <Link to={'/container/' + this.state.containerSel.value}>{this.state.containerSel.label}</Link>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!signed &&
                                <DateSelect
                                    onChange={this.dateCompleteChange}
                                    value={this.state.dateComplete}
                                    helperText={errors[t('dateComplete')]}
                                    error={errors[t('dateComplete')] != null ? true : false}
                                    disabled={signed}
                                    label={t('dateReturned')}
                                    format={this.props.auth.user.dateFormat} 
                                />
                            }
                            {signed &&
                                 <FormControl>
                                    <FormLabel>{t('dateReturned')}</FormLabel>
                                    <DateDisplay value={this.state.dateComplete} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!signed &&
                                <AutoCompleteField
                                    value={this.state.resultSelected}
                                    options={this.state.results}
                                    onChange={this.changeAuto('resultSelected', 'result')}
                                    error={errors[t('result')] != null ? true : false}
                                    helperText={errors[t('result')]}
                                    label={t('result')}
                                    disabled={signed}
                                />
                            }
                            {signed &&
                                 <FormControl>
                                    <FormLabel>{t('result')}</FormLabel>
                                    {this.state.resultSelected != null ? this.state.resultSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                    </Grid> 
                    {signed && this.state.overallResults != null && this.state.overallResults.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('result')}/>
                            <Grid container spacing={3}>
                                {this.state.overallResults.map((row, i) => 
                                    <Grid item xs={5} sm={3} key={row._id}>
                                        <FormControl>
                                            <FormLabel>{row.testItem.name}</FormLabel>
                                            {row.score + ' ' + row.testItem.uom}
                                        </FormControl>
                                    </Grid>
                                )}
                            </Grid>
                        </Aux>
                    }
                    <Grid containser spacing={3}>

                    </Grid>
                    {this.state.hasForm === true &&
                        <Aux>
                            <br/>
                            {errors[t('form')] != null && errors[t('form')] !== '' &&
                                <div style={{color: 'red'}}>{errors[t('form')]}</div>
                            }
                            <Form renderer={renderer}
                                defaultFields={this.state.form}
                                onChange={this.formValueChanged}
                                disabled={signed}/>
                        </Aux>
                    }
                </TabPanel>
                {this.state.categories != null && this.state.categories.length > 0 && this.state.categories.map((row, i) =>
                    <TabPanel value={this.state.tabKey} index={i + 1} key={'tabPanel-' + (1 + i)}>
                        <SectionTitle title={row.category.code + ' - ' + row.category.description}/>
                        <TableContainer component={Paper}>
                            <Table aria-label="Test Result">
                                <TableHead>
                                    <TableCell>{t('testItem')}</TableCell>
                                    <TableCell>{t('score')}</TableCell>
                                    <TableCell>{t('value')}</TableCell>
                                    {row.hasExpected &&
                                        <TableCell>{t('difference')}</TableCell>
                                    }
                                    <TableCell>{t('result')}</TableCell>
                                </TableHead>
                                <TableBody>
                                    {row.itemResults.map((item, j) =>
                                        <TableRow key={item.item._id}>
                                            <TableCell>{item.item.name + ' - (' + item.item.uom.label + ')'}</TableCell>
                                            <TableCell>
                                                {!signed &&
                                                    <TextField type="number" value={item.score} disabled={signed} onChange={this.changeNumber('score', i, j)}
                                                    size="medium" fullWidth={true}
                                                    error={errors[`${t('maxValue')} (${t('line')} ${i}, ${j})`] != null ? true : false} helperText={errors[`${t('maxValue')} (${t('line')} ${i}, ${j})`]}/>
                                                }
                                                {signed &&
                                                    item.score
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {!signed &&
                                                    <AutoCompleteField
                                                        value={item.valueSel}
                                                        options={this.state.testResultValues}
                                                        onChange={this.changeResultAuto('valueSel', 'value', i, j)}
                                                        error={errors[`${t('result')} (${t('line')} ${i}, ${j})`] != null ? true : false}
                                                        helperText={errors[`${t('result')} (${t('line')} ${i}, ${j})`]}
                                                        disabled={signed}
                                                    />
                                                }
                                                {signed &&
                                                    item.valueSel != null ? item.valueSel.label : ''
                                                }
                                            </TableCell>
                                            {row.hasExpected &&
                                                <TableCell><NumericFormat value={item.difference} displayType={'text'} /></TableCell>
                                            }
                                            <TableCell>
                                                {item.resultSel != null ? item.resultSel.label : ''}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                )}
                <Grid container spacing={3}>
                    <Grid item sm={10}>
                        <SaveButton enabled={!signed && this.state.enableSave} saveClick={this.save}/>
                        {signed &&
                            <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.testInstance} callback={this.loadRecord}/>
                        }
                        {this.state._id != null && this.state._id !== '' && this.props.permission.delete && (this.state.result == null || this.state.result === '') &&
                            <DeleteButton enabled={this.state.security.delete} deleteClick={this.deleteClick}/>
                        }
                        {!signed && this.state.result != null && this.state.result !== '' && this.props.permission.update &&
                            <Button variant="contained" color="secondary" size="small" onClick={this.complete}>{t('complete')}</Button>
                        }
                    </Grid>
                </Grid> 
                <Dialog open={this.state.showComplete} onClose={this.closeComplete} maxWidth="sm" fullWidth={true}>
                    <DialogTitle>
                        <SectionTitle title={t('complete')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={10} sm={5}>
                                <AutoCompleteField
                                    value={this.state.lotStatusSel}
                                    options={this.state.lotStatuses}
                                    onChange={this.changeAuto('lotStatusSel', 'lotStatus')}
                                    error={errors[t('lotStatus')] != null ? true : false}
                                    helperText={errors[t('lotStatus')]}
                                    label={t('lotStatus')}
                                    disabled={signed}
                                />
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <AutoCompleteField
                                    value={this.state.ratingSel}
                                    options={this.state.ratings}
                                    onChange={this.changeAuto('ratingSel', 'rating')}
                                    error={errors[t('rating')] != null ? true : false}
                                    helperText={errors[t('rating')]}
                                    label={t('lotRating')}
                                    disabled={signed}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" size="small" color="primary" onClick={this.processComplete}>{t('process')}</Button>
                        <Button variant="contained" size="small" color="secondary" onClick={this.closeComplete}>{t('close')}</Button>
                    </DialogActions>
                </Dialog>
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.testInstance}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(TestResults));