import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, 
         ListItemSecondaryAction, ListSubheader } from '@mui/material/';
import Divider from '@mui/material/Divider';
import CloseMenu from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Avatar from '../Avatar/Avatar';
import Signature from '../Signature/Signature';
import { useTranslation } from 'react-i18next';
import CommentIcon from '@mui/icons-material/CommentTwoTone';
import LogIcon from '@mui/icons-material/HistoryTwoTone';

export const MenuContent = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const optionMenu = useSelector(state => state.optionMenu);
  const constants = useSelector(state => state.auth.constants);
  const currentPath = useSelector(state => state.security.currentPath.path);
  const internal = useSelector(state => state.auth.user.internal);
  const { isMobile, onClose } = props;

  const desktopSubHeader = (
    <ListSubheader sx={{
      marginTop: '64px',
      color: 'palette.grey[350]',
      fontFamily: 'Open Sans',
      fontSize: '1.15em',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      display: 'block'
    }}>
      {optionMenu.title}
      <Box sx={{ 
            color: 'palette.grey[350]',
            display: 'inline',
            float: 'right',
            justifyContent: 'flex-end'
          }}
      >
          <IconButton 
            onClick={onClose}
            aria-label="close drawer"
            size="small"
            >
            <CloseMenu />
          </IconButton>
      </Box>
      <Divider/>
    </ListSubheader>
  );

  const mobileSubHeader = (
    <ListSubheader sx={{
      color: 'palette.grey[350]',
      fontFamily: 'Open Sans',
      fontSize: '1.15em',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      display: 'block'
    }}>
      {optionMenu.title}
      <Divider/>
    </ListSubheader>
  );

  return (
    <List subheader={!isMobile ? desktopSubHeader : mobileSubHeader} sx={{ width: '100%', maxWidth: 360, backgroundColor: 'background.paper'}}>
      {optionMenu.options.map((option, i) => {
        switch(option.menuType) {
          case constants.optionMenuTypes.signature:
            let wasteWitness = false
            if(currentPath != null && currentPath !== '' && currentPath === 'destruction'){
              if((option.title != null && option.title !== '') && (option.title === t('completedBy') || option.title === t('witnessed'))){
                wasteWitness = true
              }
            }            
            return (
              <ListItem key={'signature-'+option.name} >
                <ListItemAvatar>
                  <Avatar user={option.signature != null ? option.signature.user : null} />
                </ListItemAvatar>
                <ListItemText
                  primary={option.title ?? 'Blank'}
                  secondary={option.signature != null && option.signature.user != null ? option.signature.user.firstName + ' ' + option.signature.user.lastName : null}
                />
                <ListItemSecondaryAction>
                  <Signature users={option.users} name={option.name} signature={option.signature} disabled={!option.security} wasteWitness={wasteWitness}
                             title={option.title} setId={option.setId} object={option.object} validate={option.validate} complete={option.complete}
                             objectType={option.objectType} type={option.type} user={option.user} saveCallback={option.saveCallback}/>
                </ListItemSecondaryAction>
                {internal && option.deleteCallback != null &&
                    <ListItemButton 
                        onClick={() => option.deleteCallback(option.type)}
                        sx={{ 
                        ml: -1, 
                        '&:hover': {
                            backgroundColor: '#FFF'
                        }}}
                    >
                        <ListItemIcon>
                        <CloseMenu />
                        </ListItemIcon>
                    </ListItemButton>
                }
              </ListItem>
            );
          case constants.optionMenuTypes.comments:
            return (
              <ListItem key='comments' >
                <ListItemButton
                  onClick={() => dispatch({ type: 'TOGGLE_COMMENTS' })}
                  sx={{ 
                      ml: -1, 
                    '&:hover': {
                      backgroundColor: '#FFF'
                  }}} 
                >
                  <ListItemIcon>
                    <CommentIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('comments')}
                    sx={{ ml: -1}}
                  />
                </ListItemButton>
              </ListItem>
            );
          case constants.optionMenuTypes.logs:
            return (
              <ListItem key='logs' >
                <ListItemButton 
                    onClick={() => dispatch({ type: 'TOGGLE_LOGS' })}
                    sx={{ 
                      ml: -1, 
                    '&:hover': {
                      backgroundColor: '#FFF'
                    }}}
                  >
                  <ListItemIcon>
                    <LogIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('logs')}
                    sx={{ ml: -1}}
                  />
                </ListItemButton>
              </ListItem>
            );          
          default: 
            return null;
        }
      })}
    </List>
  );
}
