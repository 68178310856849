import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Aux from '../../hoc/Auxilary/Auxilary';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SectionTitle from '../UI/Typography/SectionTitle';
import Grid from '@mui/material/Grid';
import Panel from '../UI/Panel/Panel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import { withTranslation } from 'react-i18next';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import DateSelect from '../UI/DateSelect/DateSelect';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Button, FormControl, FormLabel } from '@mui/material';
import TextField from '../UI/TextField/TextField';
import { CSVDownload } from "react-csv";
import ActionBar from '../UI/Buttons/ActionBar';
import common from '../../jsons/common.json';
import CheckboxField from '../UI/CheckboxField/CheckboxField'; 
import isEmpty from '../../is-empty';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class ApiLogs extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            listSize: 10,
            page: 0,
            number: '',
            startDate: '',
            endDate: '',
            status: '',
            statusSel: null,
            statuses: [],
            api: '',
            apiSel: null,
            apis: [],
            showDetail: false,
            record: {},
            csvData: null,
            headers: null,
            security: {},
            tenant: '',
            tenantSel: null,
            tenants: [],
            locked: true,
            resolved: false,
            resolvedBy: null,
            resolutionDate: '',
            note: '',
            resolvedSearch: false
        };
    }

    async componentDidMount(){
        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        let values = await axios.get('/api/apilogvalues');
        let storage = sessionStorage.getItem('apiLogs');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        let startDate = new Date();
        startDate.setDate(startDate.getDate() - 14);
        let endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        const csvSeparator = sessionStorage.getItem('csvSeparator');
        this.setState({
            statuses: cacheValues.statuses,
            apis: values.data.apis,
            offset: storage != null ? storage.offset : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'date',
            ordDir: storage != null ? storage.ordDir : -1,
            api: storage != null ? storage.api : '',
            apiSel: storage != null ? storage.apiSel : null,
            tenant: storage != null ? storage.tenant : '',
            tenantSel: storage != null ? storage.tenantSel : null,
            status: storage != null ? storage.status : '',
            statusSel: storage != null ? storage.statusSel : null,
            startDate: storage != null && storage.startDate != null && storage.startDate !== '' ? new Date(storage.startDate) : startDate,
            endDate: storage != null && storage.endDate != null && storage.endDate !== '' ? new Date(storage.endDate) : endDate,
            number: storage != null ? storage.number : '',
            security: this.props.permission,
            tenants: values.data.tenants,
            separator: csvSeparator != null ? csvSeparator : ','
        }, () => { 
            const crumbs = [
                { path: 'active', label: this.props.t('apiLogs'), screen: this.props.auth.screenDefs.ApiLogs}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            api: this.state.api,
            apiSel: this.state.apiSel,
            status: this.state.status,
            statusSel: this.state.statusSel,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            number: this.state.number,
            tenant: this.state.tenant,
            tenantSel: this.state.tenantSel
        };
        sessionStorage.setItem('apiLogs', JSON.stringify(data));
    }

    loadRecords = async () => {
        const sort = {[this.state.orderBy]: this.state.ordDir};
        const data = {
            offset: this.state.offset,
            size: this.state.listSize,
            number: this.state.number,
            api: this.state.api,
            status: this.state.status,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            tenant: this.state.tenant,
            sort: JSON.stringify(sort),
            resolved: this.state.resolvedSearch
        };
        let list = await axios.get('/api/apilogs', {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        })
        this.saveFilters();
    };

    csvClick = async (e) => {
        this.setState({csvData: null});
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            number: this.state.number,
            api: this.state.api,
            status: this.state.status,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            tenant: this.state.tenant,
            sort: JSON.stringify(sort),
            resolved: this.state.resolvedSearch
        };
        let list = await axios.get('/api/apilogsexport', {params: data});
        let headers = list.data.headers;
        let csvData = list.data.data;
        this.setState({csvData: csvData, headers: headers});
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    changeSearchBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]}, () => {
            this.loadRecords();
        })
    }

    changeInput = (e) => {
        this.setState({[e.target.name] : e.target.value});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e}, () => { this.loadRecords(); });
    };

    resolutionDateChange = (e) => {
        this.setState({resolutionDate: e});
    }

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    async rowClick(i){
        let id = this.state.list[i]._id;
        let record = await axios.get('/api/apilog/' + id);
        this.setState({
            record: record.data,
            showDetail: true,
            resolved: record.data.resolved === true ? true : false,
            resolvedBy: record.data.resolvedBy,
            resolutionDate: record.data.resolutionDate,
            note: record.data.note
        });
    };

    closeDetail = () => {
        this.setState({
            record: {},
            showDetail: false,
            resolved: false,
            resolvedBy: null,
            resolutionDate: '',
            note: '',
            locked: true
        });
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]}, () => {
            if(name === 'resolved'){
                let resolutionDate = this.state.resolved === true ? new Date() : '';
                this.setState({resolutionDate: resolutionDate});
            }
        });
    }

    save = async (e) => {
        const t = this.props.t;
        let state = this.state;
        let errors = {};
        if(state.resolved === true){
            if(state.resolutionDate == null || state.resolutionDate === '')
                errors[t('resolutionDate')] = t('required');
            if(state.note == null || state.note === '')
                errors[t('note')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let data = {
            _id: state.record._id,
            resolved: state.resolved,
            resolutionDate: state.resolutionDate,
            note: state.note
        }
        try {
            let result = await axios.post('/api/apilog', data);
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    render(){
        const t = this.props.t;
        const auth = this.props.auth;
        const security = this.state.security;
        const locked = this.state.locked;
        const errors = this.props.errors;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        constants={auth.constants}
                        helpPath={auth.user.helpUrl + '/'+ security.helpId}
                        csvClick={this.csvClick}
                    />
                </BreadcrumbBar>
                {this.state.csvData != null &&
                    <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.headers} separator={this.state.separator}/>
                }
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={2} sm={2}>
                            <TextField value={this.state.number} onChange={this.changeValue} name="number"
                            size="medium" fullWidth={true} label={t('number')} data-cy='number'/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.statusSel}
                                options={this.state.statuses}
                                onChange={this.changeAuto('statusSel', 'status')}
                                label={t('status')}
                                data-cy='status'
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.apiSel}
                                options={this.state.apis}
                                onChange={this.changeAuto('apiSel', 'api')}
                                label={t('endpoint')}
                                data-cy='endpoint'
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.dateChange('startDate')}
                                value={this.state.startDate}
                                label={t('startDate')}
                                format={this.props.auth.user.dateFormat}
                                data-cy='startDate'
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.dateChange('endDate')}
                                value={this.state.endDate}
                                label={t('endDate')}
                                format={this.props.auth.user.dateFormat}
                                data-cy='endDate'
                            />
                        </Grid>
                        {this.props.auth.user.internal &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.tenantSel}
                                    options={this.state.tenants}
                                    onChange={this.changeAuto('tenantSel', 'tenant')}
                                    label={t('tenant')}
                                    data-cy='tenant'
                                />
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            <CheckboxField
                                checked={this.state.resolvedSearch} onChange={this.changeSearchBool('resolvedSearch')}
                                label={t('resolved')} name="resolvedSearch"
                                data-cy='resolvedSearch'
                                />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table axia-label="apilogs">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    {this.props.auth.user.internal &&
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('tenant')}
                                                active={this.state.orderBy === 'tenant'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('tenant')}
                                            </TableSortLabel>
                                        </TableCell>
                                    }
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('date')}
                                        active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('api')}
                                        active={this.state.orderBy === 'api'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('endpoint')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('status')}
                                        active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('errors')}</TableCell>
                                    <TableCell>{t('resolved')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <TextOnlyClickButton click={() => this.rowClick(i)} name={row.number} enabled={true} data-cy={'apilog' + i}/>
                                        </TableCell>
                                        {this.props.auth.user.internal &&
                                            <TableCell>{row.tenant != null ? row.tenant.name : ''}</TableCell>
                                        }
                                        <TableCell>
                                            <DateDisplay value={row.date} format={this.props.auth.common.dateTimeFormat}/>
                                        </TableCell>
                                        <TableCell>{row.api}</TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell>{row.failedRows != null ? row.failedRows.length : ''}</TableCell>
                                        <TableCell>{row.resolved === true ? t('yes') : ''}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
                <Dialog open={this.state.showDetail} onClose={this.closeDetail} maxWidth="lg" fullWidth={true}>
                    <DialogTitle>
                        <SectionTitle title={t('apiLog')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.record.number}
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('date')}</FormLabel>
                                    <DateDisplay value={this.state.record.date} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('endpoint')}</FormLabel>
                                    {this.state.record.api}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('user')}</FormLabel>
                                    {this.state.record.user != null ? this.state.record.user.firstName + ' ' + this.state.record.user.lastName : ''}
                                </FormControl>
                            </Grid>
                            {this.state.record.error != null && this.state.record.error !== '' &&
                                <Grid item xs={12} sm={6}>
                                    <FormControl>
                                        <FormLabel>{t('error')}</FormLabel>
                                        {this.state.record.error}
                                    </FormControl>
                                </Grid>
                            }
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={4} sm={2}>
                                <CheckboxField
                                    checked={this.state.resolved} onChange={this.changeBool('resolved')}
                                    label={t('resolved')} name="resolved" disabled={locked}
                                    />
                            </Grid>
                            {this.state.resolvedBy != null && this.state.resolvedBy !== '' &&
                                <Grid item xs={8} sm={4}>
                                    <FormControl>
                                        <FormLabel>{t('resolvedBy')}</FormLabel>
                                        {this.state.resolvedBy != null ? this.state.resolvedBy.firstName + ' ' + this.state.resolvedBy.lastName : ''}
                                    </FormControl>
                                </Grid>
                            }
                            {this.state.resolutionDate != null && this.state.resolutionDate !== '' && locked &&
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('resolutionDate')}</FormLabel>
                                        <DateDisplay value={this.state.resolutionDate} format={this.props.auth.common.dateTimeFormat}/>
                                    </FormControl>
                                </Grid>
                            }
                            {!locked &&
                                <Grid item xs={4} sm={2}>
                                    <DateSelect
                                        onChange={this.resolutionDateChange}
                                        value={this.state.resolutionDate}
                                        label={t('resolutionDate')}
                                        format={this.props.auth.user.dateFormat}
                                        error={errors[t('resolutionDate')] != null ? true : false} helperText={errors[t('resolutionDate')]}
                                    />
                                </Grid>
                            }
                            {locked && this.state.note != null && this.state.note !== '' &&
                                <Grid item sm={10}>
                                    <FormControl>
                                        <FormLabel>{t('note')}</FormLabel>
                                        {this.state.note}
                                    </FormControl>
                                </Grid>
                            }
                            {!locked &&
                                <Grid item sm={10}>
                                    <TextField value={this.state.note} onChange={this.changeValue} name="note"
                                    multiline={true} maxRows="10"
                                    size="medium" fullWidth={true} label={t('note')}
                                    error={errors[t('note')] != null ? true : false} helperText={errors[t('note')]}/>
                                </Grid>
                            }
                        </Grid>
                        {this.state.record.failedRows != null && this.state.record.failedRows.length > 0 &&
                            <Aux>
                                <br/>
                                <SectionTitle title={t('errors')}/>
                                <TableContainer component={Paper}>
                                    <Table axia-label="errors">
                                        <TableHead>
                                            <TableRow key="errorHeader">
                                                <TableCell>{t('error')}</TableCell>
                                                <TableCell>{t('record')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.record.failedRows.map((failed, j) =>
                                                <TableRow key={failed._id}>
                                                    <TableCell>{failed.error}</TableCell>
                                                    <TableCell>{JSON.stringify(failed.row)}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <br/>
                            </Aux>
                        }
                        <Grid container spacing={3}>
                            <Grid item sm={12}>
                                <FormControl>
                                    <FormLabel>{t('message')}</FormLabel>
                                    {JSON.stringify(this.state.record.message)}
                                </FormControl>
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" size="small" color="primary" onClick={this.closeDetail}>{t('close')}</Button>&nbsp;&nbsp;
                        {security.update === true &&
                            <Aux>
                                <Button variant="contained" size="small" color="secondary" onClick={this.changeBool('locked')}>
                                    {locked ? t('unlock') : t('lock')}
                                </Button>
                                &nbsp;&nbsp;
                            </Aux>
                        }
                        {!locked &&
                            <Button variant="contained" size="small" color="primary" onClick={this.save}>{t('save')}</Button>
                        }
                    </DialogActions>
                </Dialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ApiLogs));
