import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ClearButton, SaveButton, TextOnlyClickButton } from '../UI/Buttons/Buttons';
import ActionBar from '../UI/Buttons/ActionBar';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

class ScreenDefinitions extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            index: -1,
            edit: false,
            search: '',
            objectSearch: '',
            objSearchSel: null,
            objects: [],
            orderBy: 'name',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            object: '',
            objSel: null,
            name: '',
            _id: '',
            catSearch: '',
            catSearchSel: null,
            categories: [],
            groupSearch: '',
            groupSearchSel: null,
            groups: [],
            category: '',
            catSel: null,
            group: '',
            groupSel: null,
            path: '',
            pathSearch: '',
            security: [],
            enableSave: true,
            micro: false
        };
    }

    async componentDidMount(){
        const values = await axios.get('/int/screendefinitionvalues');
        this.setState({
            objects: values.data.objects,
            groups: values.data.groups,
            categories: values.data.categories,
            security: this.props.permission
        }, () => {
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        const sort = {[this.state.orderBy]: this.state.ordDir};
        const data = {
            name: this.state.search,
            object: this.state.objectSearch,
            category: this.state.catSearch,
            group: this.state.groupSearch,
            offset: this.state.offset,
            size: this.state.listSize,
            path: this.state.pathSearch,
            sort: JSON.stringify(sort)
        };
        const list = await axios.get('/int/screendefinitions', {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        }, () => {
            const crumbs = [
                { path: 'active', label: 'Screen Definitions', screen: this.props.auth.screenDefs.ScreenDefinitions}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0});
    };

    changeSearchValue = e => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords();});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        });
    };

    changeSearchAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    closeClick = e => {
        this.setState({edit: false})
    };

    newClick = e => {
        this.setState({edit: true})
    };

    clear = () => {
        this.setState({
            object: '',
            objSel: null,
            group: '',
            groupSel: null,
            category: '',
            catSel: null,
            name: '',
            index: -1,
            path: '',
            _id: '',
            edit: false,
            micro: false
        })
    };

    edit(index){
        const row = this.state.list[index];
        const objSel = row.object != null ? this.state.objects.find(x => x.value === row.object._id) : null;
        const groupSel = row.group != null ? this.state.groups.find(x => x.value === row.group._id) : null;
        const catSel = row.category != null ? this.state.categories.find(x => x.value === row.category._id) : null;
        this.setState({
            _id: row._id,
            name: row.name,
            object: row.object,
            objSel: objSel,
            index: index,
            edit: true,
            group: row.group,
            groupSel: groupSel,
            category: row.category,
            catSel: catSel,
            path: row.path,
            micro: row.micro
        })
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const data = {
            _id: this.state._id,
            name: this.state.name,
            object: this.state.object,
            category: this.state.category,
            group: this.state.group,
            path: this.state.path
        }
        try {
            const result = await axios.post('/int/screendefinition', data);
            this.setState({ enableSave: true}, () => {
                this.loadRecords();
                this.clear();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});    
            });            
        }
    };

    render(){
        const security = this.state.security;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createClick={this.newClick}
                        createAccess={security.create}
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.search} onChange={this.changeSearchValue} name="search"
                            size="medium" fullWidth={true} label="Name"/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.pathSearch} onChange={this.changeSearchValue} name="pathSearch"
                            size="medium" fullWidth={true} label="Path"/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.objSearchSel}
                                options={this.state.objects}
                                onChange={this.changeSearchAuto('objSearchSel', 'objectSearch')}
                                label="Object"
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.catSearchSel}
                                options={this.state.categories}
                                onChange={this.changeSearchAuto('catSearchSel', 'catSearch')}
                                label="Category"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.groupSearchSel}
                                options={this.state.groups}
                                onChange={this.changeAuto('groupSearchSel', 'groupSearch')}
                                label="Group"
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Screen Definitions">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('name')}
                                            active={this.state.orderBy === 'name'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('path')}
                                            active={this.state.orderBy === 'path'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Path
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('object')}
                                            active={this.state.orderBy === 'object'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Object
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('category')}
                                            active={this.state.orderBy === 'category'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Category
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('group')}
                                            active={this.state.orderBy === 'group'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Group
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <TextOnlyClickButton enabled={true} click={() => this.edit(i)} name={row.name}/>
                                        </TableCell>
                                        <TableCell>{row.path}</TableCell>
                                        <TableCell>{row.object != null ? row.object.text : ''}</TableCell>
                                        <TableCell>{row.category != null ? row.category.label : ''}</TableCell>
                                        <TableCell>{row.group != null ? row.group.label : ''}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <Dialog open={this.state.edit} onClose={() => this.clear()} maxWidth="lg" fullWidth>
                        <DialogTitle>
                            <SectionTitle title="Screen Definition" />
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={3}>
                                    <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                    size="medium" fullWidth={true} label="Name"/>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <TextField value={this.state.path} onChange={this.changeValue} name="path"
                                    size="medium" fullWidth={true} label="Path"/>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.objSel}
                                        options={this.state.objects}
                                        onChange={this.changeAuto('objSel', 'object')}
                                        label="Object"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.catSel}
                                        options={this.state.categories}
                                        onChange={this.changeAuto('catSel', 'category')}
                                        label="Category"
                                    />
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    <AutoCompleteField
                                        value={this.state.groupSel}
                                        options={this.state.groups}
                                        onChange={this.changeAuto('groupSel', 'group')}
                                        label="Group"
                                    />
                                </Grid>
                                {this.state.micro &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>Micro</FormLabel>
                                            {this.state.micro ? 'Yes' : ''}
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <SaveButton saveClick={this.save} enabled={this.state.enableSave && security.update} />
                            <ClearButton clearClick={this.clear} enabled={true} />
                            </DialogActions>
                    </Dialog>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(ScreenDefinitions);
