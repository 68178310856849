import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Link from '../../Link/Link';
import ModalDialog from '../../Dialog/ModalDialog';
import axios from 'axios';
import Aux from '../../../../hoc/Auxilary/Auxilary';
import RichText from '../../../../hoc/RichText/RichText';

class Help extends Component { 
    constructor(props){
        super(props);
        this.state = {
            showDetail: false,
            helpRec: null,
            htmlString: null,
            fullURL: null
        };
    }

    showHelp = (docId, url) => async (e) => {
        if(docId != null){
            let result = await axios.get('/api/helparticle/' + docId)
            if(result != null && result.data != null && result.data.description != null){
                const parser = new DOMParser();
                const doc = parser.parseFromString(result.data.description, 'text/html');
                let tocs = doc.getElementsByClassName('fd-toc');
                let tocsArray = Array.from(tocs);
                tocsArray.forEach(function (element) {
                    element.parentNode.removeChild(element);
                });
                //first UL should be contents of TOC
                const ulElements = doc.getElementsByTagName('ul');
                for(let row of ulElements){
                    row.parentNode.removeChild(row);
                    break;
                }
                this.setState({showDetail: true, helpRec: result.data, htmlString: doc.body.innerHTML, fullURL: url});
            }
        }
    }
    
    toggleHelp = () => {
        this.setState({showDetail: !this.state.showDetail})
    }
    
    render() {
        const { help, open, toggleDialog, t } = this.props;
        const { showDetail, helpRec, htmlString } = this.state;

        return (
            <Aux>
                <ModalDialog title={help.current.name + ' ' + t('support')} toggleDialog={toggleDialog()}
                            dialogStatus={open} fullWidth maxWidth='xs'>
                    <Grid container spacing={2}>
                        <Grid item container xs={12}>
                            <List sx={{ 
                                        width: '100%',
                                        maxWidth: 360,
                                        backgroundColor: 'background.paper'
                                    }}>
                                <ListItem key={help.current.path + '-support-page'} >
                                    {help.current != null && help.current !== '' && help.current.links.main.documentId != null && help.current.links.main.documentId !== '' &&
                                        <ListItemText 
                                            primary={
                                                <Link onClick={this.showHelp(help.current.links.main.documentId, help.current != null && help.current !== '' && help.current.links.main.value != null ? 
                                                help.current.links.main.value : '')} component="button">
                                                    {help.current.name + ' ' + t('knowledgeBaseArticle')}
                                                </Link>
                                            }
                                            secondary={help.current != null && help.current !== '' ? help.current.links.main.label : ''}
                                        />
                                    }
                                    {help.current != null && help.current !== '' && (help.current.links.main.documentId == null || help.current.links.main.documentId === '') &&
                                        <ListItemText
                                            primary={
                                                <Link href={help.current != null && help.current !== '' && help.current.links.main.value != null ? 
                                                            help.current.links.main.value : ''}
                                                    target="_blank" >
                                                    {help.current.name + ' ' + t('knowledgeBaseArticle')}
                                                </Link>                                        
                                            }
                                            secondary={help.current != null && help.current !== '' ? help.current.links.main.label : ''}
                                        />
                                    }
                                </ListItem>
                                <Divider />
                                {help.current != null && help.current !== '' && help.current.links.faqs.length > 0 &&
                                    help.current.links.faqs.map((faq, i) => {
                                        return (
                                            <ListItem key={help.path+'-faq-'+i} >
                                                {faq.documentId != null && faq.documentId !== '' &&
                                                    <ListItemText
                                                    primary={
                                                        <Link onClick={this.showHelp(faq.documentId, faq.value != null && faq.value !== '' ? faq.value : '')} component="button">
                                                            {faq.label != null && faq.label !== '' ? faq.label : t('none')}
                                                        </Link>                                                
                                                    }
                                                    />
                                                }
                                                {(faq.documentId == null || faq.documentId === '') &&
                                                    <ListItemText
                                                    primary={
                                                        <Link href={faq.value != null && faq.value !== '' ? faq.value : ''}
                                                            target="_blank" >
                                                            {faq.label != null && faq.label !== '' ? faq.label : t('none')}
                                                        </Link>                                                
                                                    }
                                                    />
                                                }
                                            </ListItem>
                                        )                                   
                                    }
                                )}
                            </List>
                        </Grid>
                    </Grid>
                </ModalDialog>
                <ModalDialog title={helpRec != null && helpRec !== '' ? helpRec.title : ''} toggleDialog={this.toggleHelp}
                            dialogStatus={showDetail} fullWidth maxWidth='lg'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Link href={this.state.fullURL} target="_blank" ><strong>{t('fullHelpDocument')}</strong></Link>
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RichText>{htmlString}</RichText>
                        </Grid>
                    </Grid>
                </ModalDialog>
            </Aux>
        );
    }
}

const mapStateToProps = state => ({
    help: state.help
});

export default withTranslation() (connect(mapStateToProps)(Help));