import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Aux from '../../hoc/Auxilary/Auxilary';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import DateSelect from '../UI/DateSelect/DateSelect';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Grid from '@mui/material/Grid';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import { FormControl, FormLabel, Button } from '@mui/material';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import { CSVDownload } from "react-csv";
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import IconButton from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class InventoryBreakdown extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: {},
            startDate: '',
            endDate: '',
            reportCategory: '',
            catSel: null,
            categories: [],
            produced: false,
            shipped: false,
            exported: false,
            destroyed: false,
            tested: false,
            otherRed: false,
            otherAdd: false,
            imported: false,
            received: false,
            processed: false,
            gram: '',
            ea: '',
            entryTypes: [],
            tabLabels: [],
            tabKey: 0,
            csvData: null
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.hcReportCategory], this.props.auth.constants, null, this.props.auth.user.tenant);
        const csvSeparator = sessionStorage.getItem('csvSeparator');
        let values = await axios.get('/api/breakdownvalues');
        let storage = sessionStorage.getItem('inventoryBreakdown');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        let params = queryString.parse(this.props.location.search);
        let startDate = params != null && params.startDate != null ? params.startDate : '';
        if(startDate === ''){
            let currDate = new Date();
            let month = currDate.getMonth() - 1;
            let year = currDate.getFullYear();
            startDate = new Date(year, month, 1);
        }
        let endDate = params != null && params.endDate != null ? params.endDate : '';
        if(endDate === ''){
            endDate = new Date();
            endDate.setDate(0);
        }
        let reportCategory = params != null && params.reportCategory != null ? params.reportCategory : '';
        let catSel = reportCategory !== '' ? cacheValues.hcReportCategories.find(x => x.value === reportCategory) : null;
        this.setState({
            startDate: storage != null && storage.startDate != null && storage.startDate !== '' ? new Date(storage.startDate) : startDate,
            endDate: storage != null && storage.endDate != null && storage.endDate !== '' ? new Date(storage.endDate) : endDate,
            reportCategory: storage != null && storage.reportCategory != null && storage.reportCategory !== '' ? storage.reportCategory : reportCategory,
            categories: cacheValues.hcReportCategories,
            catSel: storage != null && storage.catSel != null ? storage.catSel : catSel,
            gram: values.data.gram,
            ea: values.data.ea,
            entryTypes: values.data.entryTypes,
            separator: csvSeparator != null ? csvSeparator : ','
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('inventoryBreakdown'), screen: this.props.auth.screenDefs.InventoryBreakdown}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadData()
        });
    }

    saveFilters(){
        let data = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            reportCategory: this.state.reportCategory,
            catSel: this.state.catSel
        };
        sessionStorage.setItem('inventoryBreakdown', JSON.stringify(data));
    }

    loadData = async () => {
        const t = this.props.t;
        if(this.state.startDate != null && this.state.startDate !== '' && this.state.endDate != null && this.state.startDate !== '' &&
            this.state.reportCategory != null && this.state.reportCategory !== ''){
            let startDate = new Date(this.state.startDate);
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);
            let endDate = new Date(this.state.endDate);
            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds(59);
            endDate.setMilliseconds(999)
            let result = await axios.get('/api/inventorybreakdown/', {params: {startDate: startDate, endDate: endDate, reportCategory: this.state.reportCategory}});
            let tabDatas = [];
            let tabLabels = []
            if(result.data.receivedLedgers != null && result.data.receivedLedgers.length > 0){
                tabLabels.push({name: t('received'), disabled: false});
                tabDatas.push({
                    data: result.data.receivedLedgers.slice(0, 10),
                    all: result.data.receivedLedgers,
                    total: result.data.received,
                    count: result.data.receivedCount,
                    page: 0,
                    pageRows: 10
                });
            }
            if(result.data.producedLedgers != null && result.data.producedLedgers.length > 0){
                tabLabels.push({name: t('produced'), disabled: false});
                tabDatas.push({
                    data: result.data.producedLedgers.slice(0, 10),
                    all: result.data.producedLedgers,
                    total: result.data.produced,
                    count: result.data.producedCount,
                    page: 0,
                    pageRows: 10
                });
            }
            if(result.data.processedLedgers != null && result.data.processedLedgers.length > 0){
                tabLabels.push({name: t('processed'), disabled: false});
                tabDatas.push({
                    data: result.data.processedLedgers.slice(0, 10),
                    all: result.data.processedLedgers,
                    total: result.data.processed,
                    count: result.data.processedCount,
                    page: 0,
                    pageRows: 10
                });
            }
            if(result.data.shippedLedgers != null && result.data.shippedLedgers.length > 0){
                tabLabels.push({name: t('shipped'), disabled: false});
                tabDatas.push({
                    data: result.data.shippedLedgers.slice(0, 10),
                    all: result.data.shippedLedgers,
                    total: result.data.shipped,
                    count: result.data.shippedCount,
                    page: 0,
                    pageRows: 10
                });
            }
            if(result.data.destroyedLedgers != null && result.data.destroyedLedgers.length > 0){
                tabLabels.push({name: t('destroyed'), disabled: false});
                tabDatas.push({
                    data: result.data.destroyedLedgers.slice(0, 10),
                    all: result.data.destroyedLedgers,
                    total: result.data.destroyed,
                    count: result.data.destroyedCount,
                    page: 0,
                    pageRows: 10
                });
            }
            if(result.data.testedLedgers != null && result.data.testedLedgers.length > 0){
                tabLabels.push({name: t('tested'), disabled: false});
                tabDatas.push({
                    data: result.data.testedLedgers.slice(0, 10),
                    all: result.data.testedLedgers,
                    total: result.data.tested,
                    count: result.data.testedCount,
                    page: 0,
                    pageRows: 10
                });
            }
            if(result.data.otherAddLedgers != null && result.data.otherAddLedgers.length > 0){
                tabLabels.push({name: t('otherAdditions'), disabled: false});
                tabDatas.push({
                    data: result.data.otherAddLedgers.slice(0, 10),
                    all: result.data.otherAddLedgers,
                    total: result.data.otherAdditions,
                    count: result.data.otherAddCount,
                    page: 0,
                    pageRows: 10
                });
            }
            if(result.data.otherRedLedgers != null && result.data.otherRedLedgers.length > 0){
                tabLabels.push({name: t('otherReductions'), disabled: false});
                tabDatas.push({
                    data: result.data.otherRedLedgers.slice(0, 10),
                    all: result.data.otherRedLedgers,
                    total: result.data.otherReductions,
                    count: result.data.otherRedCount,
                    page: 0,
                    pageRows: 10
                });
            }
            if(result.data.receivedReturnedLedgers != null && result.data.receivedReturnedLedgers.length > 0){
                tabLabels.push({name: t('returned'), disabled: false});
                tabDatas.push({
                    data: result.data.receivedReturnedLedgers.slice(0, 10),
                    all: result.data.receivedReturnedLedgers,
                    total: result.data.receivedReturned,
                    count: result.data.receivedReturnedCount,
                    page: 0,
                    pageRows: 10
                })
            }
            let uom = '';
            if(this.state.reportCategory === common.hcReportCategories.vegPlant || this.state.reportCategory === common.hcReportCategories.vegPlantPacked ||
                this.state.reportCategory === common.hcReportCategories.wholePlant){
                uom = this.state.ea;
            }else{
                uom = this.state.gram;
            }
            this.setState({data: result.data, tabDatas: tabDatas, tabLabels: tabLabels, tabKey: 0, uom: uom});
            this.saveFilters();
        }
    };

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    loadClick = async (e) => {
        this.loadData();
    };

    setOpen(name){
        this.setState({[name]: !this.state[name]});
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    generateCSV = () => {
        this.setState({csvData: null}, () => {
            const t = this.props.t;
            let tabData = this.state.tabDatas[this.state.tabKey];
            let csv = [];
            csv.push([t('number'), t('date'), t('item'), t('description'), t('lot'), t('container'), t('cannabisWeight'), t('uom'), t('quantity'), t('uom'),
                t('discreteWeight'), t('uom'), t('fullDiscreteWeight'), t('count'), t('activity'), t('record'), t('processingType'), t('manual'), t('ctlsExclude'),
                t('shipmentType'), t('lastUpdated')]);
            for(let row of tabData.all){
                csv.push([
                    row.number, row.postingDate, row.item.number + '-' + row.item.name, row.description, row.lot != null ? row.lot.number : '',
                    row.container != null ? row.container.number : '', row.cannabisWeight, row.discreteWeightUOM !== '' ? row.discreteWeightUOM : row.quantityUOM,
                    row.quantity, row.quantityUOM, row.discreteWeight, row.discreteWeightUOM, row.fullDiscreteWeight, row.count,
                    row.objectType != null ? row.objectType.text : '', row.recordNumber, row.processingType, row.manual === true ? t('yes') : t('no'),
                    row.ctlsExclude === true ? t('yes') : t('no'), row.shipmentType, row.lastUpdated
                ])
            }
            this.setState({csvData: csv});
        });
    };

    handleChangePage = (i) => (event, newPage) => {
        let tabDatas = this.state.tabDatas;
        let data = tabDatas[i];
        data.page = newPage;
        this.setState({tabDatas: tabDatas}, () => {
            this.sliceRows(i);
        });
    };

    sliceRows(i){
        let tabDatas = this.state.tabDatas;
        let data = tabDatas[i];
        let rows = data.all.slice(data.pageRows * data.page, (data.pageRows * data.page) + data.pageRows);
        data.data = rows;
        this.setState({tabDatas: tabDatas});
    }

    handleRowsPerPage = (i) => (e) => {
        let tabDatas = this.state.tabDatas
        let pageRows = parseInt(e.target.value);
        let data = tabDatas[i];
        data.pageRows = pageRows;
        data.page = 0;
        this.setState({tabDatas: tabDatas}, () => {
            this.sliceRows(i);
        });
    };

    renderLedgers = (tabData, i) => {
        const t = this.props.t;
        return(
            <Aux>
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <FormControl>
                            <FormLabel>{t('quantity')}</FormLabel>
                            <NumericFormat 
                                displayType={'text'} 
                                value={tabData.total} 
                                thousandSeparator={true} 
                                decimalScale={3}
                                suffix={
                                    tabData?.data[0]?.fullDiscreteWeightUOM != null && tabData?.data[0]?.fullDiscreteWeightUOM !== '' ? 
                                        tabData.data[0].fullDiscreteWeightUOM : 
                                    tabData?.data[0]?.quantityUOM != null && tabData?.data[0]?.quantityUOM !== '' ? 
                                        tabData.data[0].quantityUOM : ''
                                } 
                            />
                        </FormControl>
                    </Grid>
                    {tabData.count !== 0 &&
                        <FormControl>
                            <FormLabel>{t('count')}</FormLabel>
                            <NumericFormat displayType={'text'} value={tabData.count} thousandSeparator={true} decimalScale={0}/>
                        </FormControl>
                    }
                </Grid>
                <br/>
                {tabData.data != null && tabData.data.length > 0 &&
                    <Box margin={1} key={'box' + i}>
                        <Grid container spacing={3}>
                            &nbsp;&nbsp;<SubSectionHeading title={t('ledgers')}/>&nbsp;&nbsp;
                            <IconButton onClick={this.generateCSV}><ExcelIcon fontSize='medium'/></IconButton>
                        </Grid>
                        <br/>
                        <TableContainer component={Paper}>
                            <Table aria-label="Item Ledgers" size="small">
                                <TableHead>
                                    <TableRow key="tableHeader">
                                        <TableCell>{t('number')}</TableCell>
                                        <TableCell>{t('postingDate')}</TableCell>
                                        <TableCell>{t('item')}</TableCell>
                                        <TableCell>{t('entryType')}</TableCell>
                                        <TableCell>{t('cannabisWeight')}</TableCell>
                                        <TableCell>{t('record')}</TableCell>
                                        <TableCell>{t('updated')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tabData.data.map((row, j) =>
                                        <TableRow key={row._id}>
                                            <TableCell><Link to={'/itemledger/' + row._id} name={'itemLedgerLink' + i + 'a' + j}>{row.number}</Link></TableCell>
                                            <TableCell><DateDisplay value={row.postingDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                            <TableCell>{row.item.number + '-' + row.item.name}</TableCell>
                                            <TableCell>{row.entryType}</TableCell>
                                            <TableCell sx={{ textAlign: 'right' }}>
                                                <NumericFormat 
                                                    displayType={'text'} value={row.cannabisWeight} thousandSeparator={true}
                                                    suffix={row.fullDiscreteWeightUOM != null && row.fullDiscreteWeightUOM !== '' ? 
                                                        row.fullDiscreteWeightUOM : row.quantityUOM
                                                    } decimalScale={3}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {row.objectType != null && row.record != null &&
                                                    <Link to={row.objectType.link + row.record}>{row.recordNumber != null ? row.recordNumber : ''}</Link>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {row.lastUpdated != null && <DateDisplay value={row.lastUpdated} format={this.props.auth.common.dateTimeFormat}/> }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TablePagination count={tabData.all.length}
                                    rowsPerPage={tabData.pageRows}
                                    onPageChange={this.handleChangePage(i)}
                                    page = {tabData.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage(i)}/>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Box>
                }
            </Aux>
        )
    }

    render(){
        const t = this.props.t;
        const data = this.state.data;
        const uom = '(' + this.state.uom + ')';
        return(
            <Aux>
                <BreadcrumbBar />
                {this.state.csvData != null &&
                    <CSVDownload data={this.state.csvData} target="_blank" separator={this.state.separator}/>
                }
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.changeDate('startDate')}
                                value={this.state.startDate}
                                label={t('startDate')}
                                format={this.props.auth.user.dateFormat}
                                required
                                data-cy="startDate"
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.changeDate('endDate')}
                                value={this.state.endDate}
                                label={t('endDate')}
                                format={this.props.auth.user.dateFormat}
                                required
                                data-cy="endDate"
                            />
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.catSel}
                                options={this.state.categories}
                                onChange={this.changeAuto('catSel', 'reportCategory')}
                                label={t('reportCategory')}
                                required
                                data-cy="reportCategory"
                            />
                        </Grid>
                        <Grid item sm={1}>
                            <Button variant="contained" color="primary" size="small" onClick={this.loadClick} data-cy="run">{t('load')}</Button>
                        </Grid>
                    </Grid>
                    <br/>
                    {data != null && data.opening != null &&
                        <Aux>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2} align="right">
                                    <FormControl>
                                        <FormLabel>{t('openingBalance') + uom}</FormLabel>
                                        <NumericFormat displayType={'text'} value={data.opening} thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                {data.openingCount !== 0 &&
                                    <Grid item xs={4} sm={2} align="right">
                                        <FormControl>
                                            <FormLabel>{t('openingCount')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={data.openingCount} thousandSeparator={true} decimalScale={2}/>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2} align="right">
                                    <FormControl>
                                        <FormLabel>{t('closingBalance')  + uom}</FormLabel>
                                        <NumericFormat displayType={'text'} value={data.closing} thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                {data.closingCount !== 0 &&
                                    <Grid item xs={4} sm={2} align="right">
                                        <FormControl>
                                            <FormLabel>{t('closingCount')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={data.closingCount} thousandSeparator={true} decimalScale={0}/>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2} align="right">
                                    <FormControl>
                                        <FormLabel>{t('change') + uom}</FormLabel>
                                        <NumericFormat displayType={'text'} value={data.closing - data.opening} thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={2} align="right">
                                    <FormControl>
                                        <FormLabel>{t('received') + uom}</FormLabel>
                                        <NumericFormat displayType={'text'} value={data.received} thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                {data.receivedCount !== 0 &&
                                    <Grid item xs={4} sm={2} align="right">
                                        <FormControl>
                                            <FormLabel>{t('receivedCount')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={data.receivedCount} thousandSeparator={true} decimalScale={0}/>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2} align="right">
                                    <FormControl>
                                        <FormLabel>{t('produced') + uom}</FormLabel>
                                        <NumericFormat displayType={'text'} value={data.produced} thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                {data.producedCount !== 0 &&
                                    <Grid item xs={4} sm={2} align="right">
                                        <FormControl>
                                            <FormLabel>{t('producedCount')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={data.producedCount} thousandSeparator={true} decimalScale={3}/>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2} align="right">
                                    <FormControl>
                                        <FormLabel>{t('returned') + uom}</FormLabel>
                                        <NumericFormat displayType={'text'} value={data.receivedReturned} thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                {data.receivedReturnedCount !== 0 &&
                                    <Grid item xs={4} sm={2} align="right">
                                        <FormControl>
                                            <FormLabel>{t('returnedCount')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={data.receivedReturnedCount} thousandSeparator={true} decimalScale={3}/>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2} align="right">
                                    <FormControl>
                                        <FormLabel>{t('processed') + uom}</FormLabel>
                                        <NumericFormat displayType={'text'} value={data.processed} thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                {data.processedCount !== 0 &&
                                    <Grid item xs={4} sm={2} align="right">
                                        <FormControl>
                                            <FormLabel>{t('processedCount')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={data.processedCount} thousandSeparator={true} decimalScale={3}/>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2} align="right">
                                    <FormControl>
                                        <FormLabel>{t('shipped') + uom}</FormLabel>
                                        <NumericFormat displayType={'text'} value={data.shipped} thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                {data.shippedCount !== 0 &&
                                    <Grid item xs={4} sm={2} align="right">
                                        <FormControl>
                                            <FormLabel>{t('shippedCount')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={data.shippedCount} thousandSeparator={true} decimalScale={3}/>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2} align="right">
                                    <FormControl>
                                        <FormLabel>{t('destroyed') + uom}</FormLabel>
                                        <NumericFormat displayType={'text'} value={data.destroyed} thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                {data.shippedCount !== 0 &&
                                    <Grid item xs={4} sm={2} align="right">
                                        <FormControl>
                                            <FormLabel>{t('destroyedCount')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={data.destroyedCount} thousandSeparator={true} decimalScale={0}/>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2} align="right">
                                    <FormControl>
                                        <FormLabel>{t('tested') + uom}</FormLabel>
                                        <NumericFormat displayType={'text'} value={data.tested} thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                {data.shippedCount !== 0 &&
                                    <Grid item xs={4} sm={2} align="right">
                                        <FormControl>
                                            <FormLabel>{t('testedCount')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={data.testedCount} thousandSeparator={true} decimalScale={0}/>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2} align="right">
                                    <FormControl>
                                        <FormLabel>{t('otherAdditions') + uom}</FormLabel>
                                        <NumericFormat displayType={'text'} value={data.otherAdditions} thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                {data.shippedCount !== 0 &&
                                    <Grid item xs={4} sm={2} align="right">
                                        <FormControl>
                                            <FormLabel>{t('otherAddCount')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={data.otherAddCount} thousandSeparator={true} decimalScale={0}/>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2} align="right">
                                    <FormControl>
                                        <FormLabel>{t('otherReductions') + uom}</FormLabel>
                                        <NumericFormat displayType={'text'} value={data.otherReductions} thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                {data.shippedCount !== 0 &&
                                    <Grid item xs={4} sm={2} align="right">
                                        <FormControl>
                                            <FormLabel>{t('otherRedCount')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={data.otherRedCount} thousandSeparator={true} decimalScale={0}/>
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                        </Aux>
                    }
                    <br/>
                    <br/>
                    <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                    {this.state.tabDatas != null && this.state.tabDatas.length > 0 && this.state.tabDatas.map((row, i) =>
                        <TabPanel value={this.state.tabKey} index={i} key={'tab' + i}>
                            {this.renderLedgers(row, i)}
                        </TabPanel>
                    )}
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(InventoryBreakdown));
