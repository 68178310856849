import React from 'react';
import { NumericFormat } from 'react-number-format';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';

// const boxStyle = {
//     margin: '8px',
//     width: '100%'
// }

// const thStyle = {
//     borderBottom: '2px solid rgb(224, 224, 224)', 
//     fontSize: '9pt', 
//     textAlign: 'center'
// }

// const tdStyle = { 
//     border: '0.5px solid rgb(224, 224, 224)', 
//     fontSize: '8pt', 
//     padding: '5px',
// }

// const tableStyle = {
//     width: '100%',
//     borderCollapse: 'collapse'
// }

export default function ChainOfCustodyTemplate(props) {
    const { dateFormat, record, t } = props;
    return(
        <div style={{ width: '8.5in', pageBreakBefore: 'always', fontFamily: 'Open Sans' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                <div style={{ width: '68%', fontSize: '9pt' }}>
                    <span style={{ fontSize: '12pt', fontWeight: 'medium' }}><strong>{t('shipper')}</strong></span><br />
                    <span style={{ marginBottom: '0px' }}>
                        {record?.location?.name != null ? record.location.name : ''}
                    </span><br />
                    {record?.location?.license != null && record?.location?.license !== '' ?
                        <>
                            <span style={{ marginBottom: '0px' }}>
                                {t('license') + '#: ' + record.location.license}
                            </span><br />
                        </> : ''
                    }                    
                    <span style={{ marginBottom: '0px' }}>
                        {record?.location?.street1 != null ? record.location.street1 : ''}
                    </span><br />
                    {record?.location?.street2 != null && record?.location?.street2 !== '' &&
                        <>
                            <span style={{ marginBottom: '0px' }}>
                                {record?.location?.street2 != null ? record.location.street2 : ''}
                            </span><br />
                        </>             
                    }
                    <span style={{ marginBottom: '0px' }}>
                        {record?.location?.city != null && record?.location?.prov?.name != null ?
                            record.location.city + ', ' + record.location.prov.name : ''}
                    </span><br />
                    <span style={{ marginBottom: '0px' }}>
                        {record?.location?.postal != null ? record.location.postal : ''}
                    </span><br />
                    <span style={{ marginBottom: '0px' }}>
                        {record?.country?.name != null ? record.country.name : ''}
                    </span><br />
                </div>
                <div style={{ width: '32%', fontSize: '9pt' }}>
                    <span style={{ fontSize: '12pt', fontWeight: 'medium' }}><strong>{t('receiver')}</strong></span><br />
                    {record?.customer != null &&
                        <>
                            <span style={{ marginBottom: '0px' }}>
                                {record?.customer?.name != null ? record.customer.name : 
                                 record?.customer?.firstName != null ? record.customer.firstName + ' ' + record.customer.lastName : '' 
                                }
                            </span><br />
                            {record?.customer?.licenseNumber != null && record?.customer?.licenseNumber !== '' &&
                                <>
                                    <span style={{ marginBottom: '0px' }}>
                                        {t('license') + '#: ' + record.customer.licenseNumber}
                                    </span><br />
                                </>
                            }
                            <span style={{ marginBottom: '0px' }}>
                                {record?.address1 != null ? record.address1 : ''}
                            </span><br />
                            {record?.address2 != null && record?.address2 !== '' &&
                                <>
                                    <span style={{ marginBottom: '0px' }}>
                                        {record.address2}
                                    </span><br />
                                </>
                            }
                            <span style={{ marginBottom: '0px' }}>
                                {record?.city != null && record?.prov?.name != null ? 
                                    record.city + ', ' + record.prov.name : ''
                                }
                            </span><br />
                            <span style={{ marginBottom: '0px' }}>
                                {record?.postal != null ? record.postal : ''}
                            </span>
                            {record?.country?.name != null &&
                                <>
                                    <br />
                                        <span style={{ marginBottom: '0px' }}>
                                            {record.country.name}
                                        </span>
                                    <br />
                                </>
                            }
                        </>
                    }
                    {record?.vendor != null &&
                        <>
                            <span style={{ marginBottom: '0px' }}>
                                {record?.vendor?.name != null ? record.vendor.name : '' }
                            </span><br />
                            {record?.vendor?.licenseNumber != null && record?.vendor?.licenseNumber !== '' &&
                                <>
                                    <span style={{ marginBottom: '0px' }}>
                                        {t('license') + '#: ' + record.vendor.licenseNumber}
                                    </span><br />
                                </>
                            }
                            <span style={{ marginBottom: '0px' }}>
                                {record?.vendor.address.address1 != null ? record.vendor.address.address1 : ''}
                            </span><br />
                            {record?.vendor.address.address2 != null && record?.vendor.address.address2 !== '' &&
                                <>
                                    <span style={{ marginBottom: '0px' }}>
                                        {record.vendor.address.address2}
                                    </span><br />
                                </>
                            }
                            <span style={{ marginBottom: '0px' }}>
                                {record?.vendor?.address?.city != null && record?.vendor?.address?.prov?.name != null ? 
                                    record.vendor.address.city + ', ' + record.vendor.address.prov.name : ''
                                }
                            </span><br />
                            <span style={{ marginBottom: '0px' }}>
                                {record?.vendor.address.postal != null ? record.vendor.address.postal : ''}
                            </span>
                            {record?.country?.name != null &&
                                <>
                                    <br />
                                        <span style={{ marginBottom: '0px' }}>
                                            {record.vendor.address.country.name}
                                        </span>
                                    <br />
                                </>
                            }
                        </>
                    }
                </div>
            </div>
            <hr style={{ clear: 'both' }} />
            <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
                <span style={{ fontSize: '10pt', fontWeight: 'medium', textAlign: 'center'}}>{t('chainOfCustody.statement')}</span>
            </div>
            <span style={{ display: 'block', fontSize: '12pt', fontWeight: 'bold'}}>{t('contents')}</span>
            <table style={{ width: '100%', borderCollapse: 'collapse'}}>
                <thead>
                <tr>
                    <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt', width: '50px' }} >{t('skid')}</th>
                    <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt', width: '100px' }} >{t('sku')}</th>
                    <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt' }} >{t('productDescription')}</th>
                    <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt', width: '100px' }} >{t('lot')}</th>
                    <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt', textAlign: 'right', width: '75px' }} >{t('units')}</th>
                    <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt', textAlign: 'right', width: '100px' }} >{t('quantity')}</th>
                </tr>
                </thead>
                <tbody>
                    {record.contents != null && record.contents.length > 0 ?
                        record.contents.map((row, i) => 
                            <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)' }} />
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt' }} >
                                    {row.item != null && row.item.sku != null && row.item.sku !== '' ? row.item.sku : 
                                        row.item != null && row.item.number != null && row.item.number !== '' ? row.item.number : ''}
                                </td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt' }} >
                                    {row.item != null ? row.item.name : ''}
                                </td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt' }}>
                                    { row.lotNumber != null && row.lotNumber !== '' ? row.lotNumber :
                                      row.lot?.vendorLot != null ? row.lot.vendorLot : 
                                      row.lot?.customNumber != null ? row.lot.customNumber :
                                      row.lot?.number != null ? row.lot.number : '' }
                                </td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt', textAlign: 'right' }} >
                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0} value={row.units}
                                        //  suffix={row.uom != null ? row.uom.label : ''}
                                    />
                                </td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt', textAlign: 'right' }} >
                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={2} value={row.totalCannabis} suffix={row.gUom != null ? row.gUom.label : 'g'}/>
                                </td>
                            </tr>
                        ) : <tr style={{ border: '1px solid #ddd', padding: '2px' }} ><td colspan='6'><center>{t('noData')}</center></td></tr>
                    }
                </tbody>
                {record?.contents?.length > 0 &&
                    <tfoot>
                        <tr>
                            <td colSpan='4' style={{ textAlign: 'right', padding: '2px', fontSize: '10pt' }}><strong>{t('total')}:&nbsp;</strong></td>
                            <td style={{ padding: '2px', fontSize: '10pt', textAlign: 'right' }}>
                                <strong> 
                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                        value={record?.units}
                                    />
                                </strong>
                            </td>
                            <td style={{ padding: '2px', fontSize: '10pt', textAlign: 'right' }}>
                                <strong> 
                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={2} value={record?.totalCannabis} suffix={record?.gUom != null ? record.gUom.label : 'g'} />
                                </strong>
                            </td>
                        </tr>
                    </tfoot>
                }
            </table>
            <hr />
            <div style={{ marginTop: '20px' }}>
                <div style={{ pageBreakInside: 'avoid' }}>
                    <span style={{ fontSize: '10pt' }}>
                        {t('dispatchedFrom')} <strong>{record.tenant != null ? record.tenant : '' }</strong> by&nbsp;
                            <strong>{record.user != null && record.user.user != null && record.user.user.firstName != null && record.user.user.lastName != null ?
                            record.user.user.firstName + ' ' + record.user.user.lastName : '' }</strong>:
                    </span><br/>
                    <div style={{ display: 'inline-block', width: '67.5%' }}>
                        <div style={{ borderBottom: '1px solid #000', marginTop: '25px', marginLeft: '15%', width: '400px'}}>
                            <img src={record.user != null && record.user.signature != null ? record.user.signature : null} 
                                    style={{ maxWidth: '20%', maxHeight: '20%', marginLeft: '10%'}}
                                    alt={( record.user != null && record.user.user != null && record.user.user.firstName != null && record.user.user.lastName != null ?
                                        record.user.user.firstName + ' ' + record.user.user.lastName : '' )}
                            />
                        </div>
                        <span style={{ fontSize: '9pt', fontStyle: 'italic', marginLeft: '15%', marginTop: '5px' }}>{t('signature')}</span>
                    </div>
                    <div style={{ display: 'inline-block', width: '32.5%' }}>
                        <div style={{ borderBottom: '1px solid #000', marginTop: '20px', width: '180px' }} >
                            <DateDisplay value={record.user.date} format={dateFormat}/>
                        </div>
                        <span style={{ fontSize: '9pt', fontStyle: 'italic' }}>{t('date')}</span>
                    </div>
                </div><br />
                <div style={{ pageBreakInside: 'avoid' }}>
                    <span style={{ fontSize: '10pt'}}>
                        {t('transportedBy')} <strong>{record.courier != null ? record.courier.name : ''}</strong>: 
                    </span><br />
                    <div style={{ display: 'inline-block', width: '67.5%' }}>
                        <div style={{ borderBottom: '1px solid #000', marginTop: '50px', marginLeft: '15%', width: '400px'}} />
                        <span style={{ fontSize: '9pt', fontStyle: 'italic', marginLeft: '15%', marginTop: '5px' }}>{t('signature')}</span>
                    </div>
                    <div style={{ display: 'inline-block', width: '32.5%' }}>
                        <div style={{ borderBottom: '1px solid #000', marginTop: '50px', width: '180px' }} />
                        <span style={{ fontSize: '9pt', fontStyle: 'italic' }}>{t('date')}</span>
                    </div>
                </div><br />
                <div style={{ pageBreakInside: 'avoid' }}>
                    <span style={{ fontSize: '10pt'}}>
                        {t('receivedBy')} <strong>
                            {record.customer != null ? record.customer.name != null ? record.customer.name : 
                                record.customer.firstName != null ? record.customer.firstName + ' ' + record.customer.lastName : 
                                record.vendor != null ? record.vendor.name : '' : ''
                            }
                        </strong>:
                    </span><br />
                    <div style={{ display: 'inline-block', width: '67.5%' }}>
                        <div style={{ borderBottom: '1px solid #000', marginTop: '50px', marginLeft: '15%', width: '400px'}} />
                        <span style={{ fontSize: '9pt', fontStyle: 'italic', marginLeft: '15%', marginTop: '5px' }}>{t('signature')}</span>
                    </div>
                    <div style={{ display: 'inline-block', width: '32.5%' }}>
                        <div style={{ borderBottom: '1px solid #000', marginTop: '50px', width: '180px' }} />
                        <span style={{ fontSize: '9pt', fontStyle: 'italic' }}>{t('date')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}