import { FormBuilder } from '@sop/react-forms-processor-formbuilder';
import { connect } from 'react-redux';
import {
    renderer as materialuiRenderer
  } from "@sop/react-forms-processor-material-ui";
import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Aux from '../../hoc/Auxilary/Auxilary';
import Title from '../UI/Typography/Title';
import Panel from '../UI/Panel/Panel';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';

class FormComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            formBuilderValue: []
        };
    }

    onFormBuilderChange(value) {
        this.setState(
          {formBuilderValue: value}
        );
    }

    render(){
        const security = this.props.permission;
        const t = this.props.t;
        return (
            <Aux>
                <ActionBar 
                    helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                    helpUser={this.props.auth.user.helpUser}
                    helpPassword={this.props.auth.user.helpPassword}
                />
                <Title title={t('formBuilder')}/>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormBuilder 
                                renderer={materialuiRenderer}
                                value={this.state.formBuilderValue}
                                onChange={(value) => {
                                    this.onFormBuilderChange(value);
                                }}
                            />                            
                        </Grid>
                    </Grid>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(FormComponent));