import React from 'react';
import axios from 'axios';
import base64 from 'base-64';
import DocumentList from './DocumentList';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import SubSectionHeading from '../UI//Typography/SubSectionHeading';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

const Document = (props) => {

    const { t } = useTranslation('translation');
    const keyVal = props.propName != null ? props.propName + 'doc' : props.row == null ? 'docsaux' : 'docsaux' + props.row;

    let handleFileUpload = async file => {
        let files = file.target.files;
        let reader = new FileReader();
        reader.readAsBinaryString(files[0]);
        reader.onload = async () => {
            let data = {
                file: base64.encode(reader.result),
                fileName: files[0].name,
                type: files[0].type,
                objectId: props.record,
                objectType: props.objectType
            };
            if(props.row != null){
                data.row = props.row;
            }
            if(props.propName != null)
                data.property = props.propName;
            try {
                await axios.post('/api/documents/add', data)
                props.callback()
            } catch(err){
                props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        };
    };

    return(
        <Aux key={keyVal}>
            {props.record != null && props.record !== '' &&
                <Aux>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <SubSectionHeading title={props.title == null ? t('documents') : props.title}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={9}>
                            <DocumentList documents={props.documents} recordId={props.record} objectType={props.objectType} callback={props.callback} propName={props.propName}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={9}>
                            <label htmlFor={props.row == null ? "btn-upload" + keyVal : "btn-upload" + props.row}>
                                <input
                                    id={props.row == null ? "btn-upload" + keyVal : "btn-upload" + props.row}
                                    name={props.row == null ? "btn-upload" + keyVal : "btn-upload" + props.row}
                                    style={{ display: 'none' }}
                                    type="file"
                                    multiple={false}
                                    onChange={handleFileUpload}
                                />
                                <Button variant="outlined" color="primary" size="small" component="span">{t('chooseFile')}</Button>
                            </label>
                        </Grid>
                    </Grid>
                    <br/>
                </Aux>
            }
        </Aux>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Document);
