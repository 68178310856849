import { SET_ACTIVITY_COUNT } from '../actions/types';

const initialState = {
    activityCount: ''
}

export default function activityReducer(state = initialState, action){
    switch(action.type){
        case SET_ACTIVITY_COUNT: {
            return {
                ...state,
                    activityCount: action.payload.activityCount.activityCount
            }
        }
        default:
            return state;
    }
}