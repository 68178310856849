import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class SigninList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            locations: [],
            location: '',
            offset: 0,
            listSize: 10,
            pageCount: 1,
            date: null,
            lastName: '',
            orderBy: 'number',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            locationSelected: null,
            security: []
        }
        this.dateChange = this.dateChange.bind(this);
    }

    async componentDidMount(){
        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        this.setState({
            locations: locations,
            security: this.props.permission
        }, () => {
            this.loadRecords();
        });

    }

    async loadRecords(){
        const sort = {[this.state.orderBy]: this.state.ordDir};
        const data = {
            offset: this.state.offset,
            size: this.state.listSize,
            date: this.state.date,
            lastName: this.state.lastName,
            sort: JSON.stringify(sort)
        }
        try{
            const url = this.state.location !== '' ? '/api/signins/' + this.state.location : '/api/signins';
            const list = await axios.get(url, {params: data});
            this.setState({
                list: list.data.data,
                pageCount: Math.ceil(list.data.totalCount / this.state.listSize),
                totalCount: list.data.totalCount
            });
        }catch(err) {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
        const crumbs = [
            { path: 'active', label: this.props.t('signins'), screen: this.props.auth.screenDefs.SigninList}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
    }

    locationChanged = e => {
        this.setState({location: e.target.value, page: 0, offset: 0}, () => this.loadRecords());
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => this.loadRecords());
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    dateChange(e) {
        this.setState({date: e, page: 0, offset: 0}, () => this.loadRecords());
    }

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar >
                    <ActionBar
                        createLocation={this.state.location !== '' ? '/#/signinlocation/' + this.state.location : '/#/signin'}
                        createAccess={security.create}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} md={2}>
                            <DateSelect
                                onChange={this.dateChange}
                                value={this.state.date}
                                label={t('date')}
                                format={this.props.auth.user.dateFormat}
                            />
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <TextField value={this.state.lastName} onChange={this.changeValue} name="lastName"
                            size="medium" fullWidth label={t('lastName')} />
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <AutoCompleteField
                                value={this.state.locationSelected}
                                options={this.state.locations}
                                onChange={this.changeAuto('locationSelected', 'location')}
                                label={t('location')}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Signins">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('signinDateTime')}
                                        active={this.state.orderBy === 'signinDateTime'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('signin')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('lastName')}
                                        active={this.state.orderBy === 'lastName'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('name')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('signoutDateTime')}
                                        active={this.state.orderBy === 'signoutDateTime'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('signout')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('location')}
                                        active={this.state.orderBy === 'location'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('location')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/signin/' + row._id} data-cy={'signinLink' + i}>{row.number}</Link></TableCell>
                                        <TableCell>
                                            <DateDisplay value={row.signinDateTime} format="yyyy/MM/dd hh:mm a"/>
                                        </TableCell>
                                        <TableCell>{row.firstName + ' ' + row.lastName}</TableCell>
                                        <TableCell><DateDisplay value={row.signoutDateTime} format="yyyy/MM/dd hh:mm a"/></TableCell>
                                        <TableCell>{row.location.name}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(SigninList));
