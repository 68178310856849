import React from 'react';
import Grid from '@mui/material/Grid';
import ReportTitle from '../../UI/Typography/ReportTitle';
import ReportSectionTitle from '../../UI/Typography/ReportSectionTitle';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';
import Divider from '@mui/material/Divider';

import ActivityRecord from './ActivityRecord'

// const boxStyle = {
//     margin: '8px',
//     width: '100%'
// }

const thStyle = {
    borderBottom: '2px solid rgb(224, 224, 224)', 
    fontSize: '9pt', 
    textAlign: 'center'
}

const tdStyle = { 
    border: '0.5px solid rgb(224, 224, 224)', 
    fontSize: '8pt', 
    padding: '5px',
}

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse'
}

export default function CleaningRecordTemplate (props) {
    const { dateFormat, order, record, preClean, postClean, sequence, t } = props;
    let titleText = '';
    if(preClean != null && preClean === true) {
        if(order != null && order !== '')
            titleText = t('productionOrder') + ' #' + order + ' ' + t('preCleaning').toLowerCase();
        else
            titleText = t('preCleaning').toLowerCase();
    }else if(postClean != null && postClean === true) {
        if(order != null && order !== '')
            titleText = t('productionOrder') + ' #' + order + ' ' + t('postCleaning').toLowerCase();
        else
            titleText = t('postCleaning').toLowerCase();
    }else {
        titleText = t('cleaningRecord').toLowerCase();
    }

    return (
        <div style={{ width: '8.5in', pageBreakBefore: 'always' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ReportTitle title={
                        (sequence != null && sequence !== '' ? 
                            sequence + (
                                record.room != null && record.room.name != null && record.room.name !== '' ?
                                    '. ' + record.room.name + ' ' + t('room').toLowerCase() + ' ' : ''
                            ) + titleText
                            : 
                                titleText
                        )} />
                </Grid>
                <Grid item xs={12}>
                    <table style={tableStyle} >
                        <thead>
                            <tr>
                                <th style={thStyle}>{t('batch')}</th>
                                <th style={thStyle}>{t('number')}</th>
                                <th style={thStyle}>{t('type')}</th>
                                <th style={thStyle}>{t('status')}</th>
                                <th style={thStyle}>{t('date')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ ...tdStyle, textAlign: 'center' }}>
                                    {record.productionBatch != null && record.productionBatch.number !== '' ? record.productionBatch.number : t('none')}
                                </td>
                                <td style={{ ...tdStyle, textAlign: 'center' }}>
                                    {record.number != null && record.number !== '' ?
                                        record.number : t('na')
                                    }
                                </td>
                                <td style={{ ...tdStyle, textAlign: 'center' }}>
                                    {record.type != null && record.type !== '' ?
                                        record.type : t('na')
                                    }
                                </td>
                                <td style={{ ...tdStyle, textAlign: 'center' }}>
                                    {record.status != null && record.status !== '' ?
                                        record.status : t('na')
                                    }
                                </td>
                                <td style={{ ...tdStyle, textAlign: 'center' }}>
                                    {record.date != null && record.date !== '' ?
                                        <DateDisplay value={record.date} format={dateFormat} /> : t('na')
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
                <Grid item xs={12}>
                    <ReportSectionTitle title={t('performedBy')} />
                    {record.users != null && record.users !== '' && record.users.length > 0 ?
                        <div style={{ fontSize: '8pt', padding: '5px' }}>
                            {record.users.map((user, i) => {
                                if(i === record.users.length - 1)
                                    return user.name
                                else
                                    return user.name + ', '
                                })
                            }
                        </div> : ''                             
                    }
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ m: '0 auto', width: '80%' }} />
                </Grid>
                <Grid item xs={12}>
                    <ReportSectionTitle title={t('cleanersUsed')}/>
                    <table style={tableStyle} >
                        <thead>
                            <tr>
                                <th style={thStyle}>{t('item')}</th>
                                <th style={{ ...thStyle, width: '125px' }}>{t('lot')}</th>
                                <th style={{ ...thStyle, width: '125px' }}>{t('container')}</th>
                                <th style={{ ...thStyle, width: '100px' }}>{t('quantity')}</th>
                            </tr>                            
                        </thead>
                        <tbody>
                            {record.inputContainers != null && record.inputContainers.length > 0 ?
                                record.inputContainers.map((cleaner, i) => 
                                    <tr key={`cleanerRow-${i}`}>
                                        <td style={tdStyle}>
                                            {cleaner.item != null && cleaner.item !== '' ? cleaner.item : t('na')}
                                        </td>
                                        <td style={tdStyle}>
                                            {cleaner.lot != null && cleaner.lot !== '' ? 
                                                cleaner.lot : t('na')
                                            }    
                                        </td>
                                        <td style={tdStyle}>
                                            {cleaner.container != null && cleaner.container !== '' ? cleaner.container : t('na')}    
                                        </td>
                                        <td style={{ ...tdStyle, textAlign: 'right' }}>
                                            {cleaner.quantity != null && cleaner.quantity !== '' && cleaner.uom != null && cleaner.uom !== '' ? 
                                                cleaner.quantity + cleaner.uom : t('na')
                                            }
                                        </td>
                                    </tr>                          
                                ) :
                                    <tr key={'inputRow-noData'}>
                                        <td colSpan={4} style={tdStyle}>{t('none')}</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ m: '0 auto', width: '80%' }} />
                </Grid>
                <Grid item xs={12}>
                    <ReportSectionTitle title={t('outputs')} />
                    <table style={tableStyle} >
                        <thead>
                            <tr>
                                <td style={{ ...thStyle, width: '100px' }}>{t('type')}</td>
                                <td style={thStyle}>{t('item')}</td>
                                <td style={{ ...thStyle, width: '125px' }}>{t('lot')}</td>
                                <td style={{ ...thStyle, width: '125px' }}>{t('container')}</td>
                                <td style={{ ...thStyle, width: '75px' }}>{t('quantity')}</td>
                            </tr>
                        </thead>
                        <tbody>
                            {record.outputContainers != null && record.outputContainers.length > 0 ?
                                record.outputContainers.map((output, i) => 
                                <tr key={'outputRow-' + i}>
                                    <td style={tdStyle}>{output.type != null && output.type !== '' ? output.type : ''}</td>
                                    <td style={tdStyle}>{output.item != null && output.item !== '' ? output.item : ''}</td>
                                    <td style={tdStyle}>{output.lot != null && output.lot !== '' ? output.lot : ''}</td>
                                    <td style={tdStyle}>{output.container != null && output.container !== '' ? output.container : ''}</td>
                                    <td style={{ ...tdStyle, textAlign: 'right' }}>{output.quantity != null && output.quantity !== '' && output.uom != null && output.uom !== '' ? output.quantity + output.uom : ''}</td>
                                </tr>
                            ) :
                                <tr>
                                    <td colSpan={8} style={{ textAlign: 'center', ...tdStyle }}>{t('none')}</td>
                                </tr>
                            }
                        </tbody>
                    </table>      
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ m: '0 auto', width: '80%' }} />
                </Grid>
                {record.sops != null && record.sops.length > 0 &&
                    record.sops.map((sop, i) => 
                        <Grid item xs={12} key={'workRecord-' + i}>
                            <ActivityRecord dateFormat={dateFormat} record={sop.workRecord} sop={sop.sop} t={t} sequence={i + 1} />
                        </Grid>
                    )
                }
            </Grid>
        </div>
    );
}