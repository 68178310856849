import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import TextField from '../UI/TextField/TextField';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';

class MessagesSent extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            user: '',
            title: '',
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            userSel: null
        };
    }

    async componentDidMount(){
        this.loadRecords();
    }

    loadRecords = async () => {
        var sort = {[this.state.orderBy]: this.state.ordDir};
        var data = {
            offset: this.state.offset,
            size: this.state.listSize,
            sent: true,
            to: this.state.user,
            title: this.state.title,
            sort: JSON.stringify(sort)
        };
        var list = await axios.get('/api/messages/' + this.props.user, {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        });
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value}, () => this.loadRecords());
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    changeSelect = (name, data) => value => {
        this.setState({[name]: value, [data]: value.value});
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <TextField value={this.state.title} onChange={this.changeValue} name="title"
                        size="medium" fullWidth={true} label={t('title')}/>
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <AutoCompleteField
                            value={this.state.userSel}
                            options={this.state.users}
                            onChange={this.changeAuto('userSel', 'user')}
                            label={t('from')}
                        />
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="Sent Messages">
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('title')}
                                    active={this.state.orderBy === 'title'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('message')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('sender')}
                                    active={this.state.orderBy === 'sender'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('from')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Recipients</TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('createdOn')}
                                    active={this.state.orderBy === 'createdOn'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>
                                        {t('date')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list.map((row, i) =>
                                <TableRow key={row._id}>
                                    <TableCell><Link to={'/message/' + row._id}>{row.title}</Link></TableCell>
                                    <TableCell>{row.sender.firstName + ' ' + row.sender.lastName}</TableCell>
                                    <TableCell>{row.recipientString}</TableCell>
                                    <TableCell><DateDisplay format="yyyy/MM/dd hh:mm" value={row.createdOn}/></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(MessagesSent));