import React, { Component } from 'react';
import axios from 'axios';
import Aux from '../../hoc/Auxilary/Auxilary';
import { connect } from 'react-redux';
import SectionTitle from '../UI/Typography/SectionTitle';
import Chart from 'react-google-charts';
import { withRouter } from 'react-router-dom';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { withTranslation } from 'react-i18next';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';

class EvoraBatchGantt extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            strain: null,
            strainSel: null,
            strains: [],
            statuses: [],
            statusSel: null,
            location: null,
            status: null,
            locations: [],
            chartData: null,
            chartHeight: 0,
            batch: null,
            open: false,
            stage: '',
            stageSel: null,
            stages: []
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.strain, common.cacheValues.batchStatus, common.cacheValues.batchStage, 
                                common.cacheValues.cultivationLocation], this.props.auth.constants, null, this.props.auth.user.tenant);
        let strains = cacheValues.strains;
        let statusSel = cacheValues.batchStatuses.find(x => x.value === this.props.auth.constants.batchStatuses.growing);
        this.setState({
            statuses: cacheValues.batchStatuses,
            strains: strains,
            locations: cacheValues.locations,
            statusSel: statusSel,
            stages: cacheValues.batchStages,
            status: this.props.auth.constants.batchStatuses.growing
        }, () => {
            this.loadRecords();
        });
        this.chartEvents = [
            {
              eventName : 'select',
              callback: this.handleClick
            }
        ];
    }

    loadRecords = async () => {
        let columnFilters = [
            {id: 'strain', value: this.state.strain},
            {id: 'status', value: this.state.status},
            {id: 'location', value: this.state.location},
            {id: 'stage', value: this.state.stage}
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: [],
            start: this.state.offset,
            size: this.state.listSize,
        };
        let list = await axios.get('/api/ebatches', {params: params});
        let chartData = [
            [
                {type: 'string', label: 'Task ID'},
                {type: 'string', label: 'Task Name'},
                {type: 'date', label: 'Start Date'},
                {type: 'date', label: 'End Date'},
                {type: 'number', label: 'Duration'},
                {type: 'number', label: 'Percent Complete'},
                {type: 'string', label: 'Dependencies'}
            ]
        ];
        let height = 0;
        if(list.data.data.length > 0){
            list.data.data.forEach(row => {
                let startTime = row.actualStart != null ? new Date(row.actualStart).getTime() : new Date(row.plannedStart).getTime();
                let endTime = new Date(row.expectedHarvest).getTime();
                let currTime = new Date().getTime();
                let totalDays = (endTime - startTime) / (1008 * 3600 * 24);
                let completeDays = (currTime - startTime) / (1000 * 3600 * 24);
                let completePercent = Math.round((completeDays / totalDays) * 100);
                chartData.push([
                    row._id,
                    row.number + ' - ' + row.description,
                    row.actualStart != null ? new Date(row.actualStart) : new Date(row.plannedStart),
                    new Date(row.expectedHarvest),
                    null,
                    completePercent,
                    null
                ])
            });
            height = (list.data.data.length * 42) + 45;
        } else {
            chartData = null;
        }
        this.setState({
            list: list.data.data,
            chartData: chartData,
            chartHeight: height
        })
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            this.loadRecords();
        });
    };

    handleClick = (chart) => {
        var selection = chart.chartWrapper.getChart().getSelection();
        var index = selection[0].row;
        this.setState({batch: this.state.list[index], open: true});
    };

    close = () => {
        this.setState({open: false});
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <SectionTitle title={t('batches')}/>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                            value={this.state.strainSel}
                            options={this.state.strains}
                            onChange={this.changeAuto('strainSel', 'strain')}
                            label={t('strain')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                            value={this.state.statusSel}
                            options={this.state.statuses}
                            onChange={this.changeAuto('statusSel', 'status')}
                            label={t('status')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                            value={this.state.stageSel}
                            options={this.state.stages}
                            onChange={this.changeAuto('stageSel', 'stage')}
                            label={t('stage')}
                        />
                    </Grid>
                </Grid>
                <br/>
                {this.state.chartData != null &&
                    <Chart
                        width={'100%'}
                        height={this.state.chartHeight}
                        chartType="Gantt"
                        loader={<div>Loading Chart</div>}
                        data={this.state.chartData}
                        rootProps={{ 'data-testid': '1' }}
                        chartEvents={this.chartEvents}
                    />
                }
                <Dialog open={this.state.open} onClose={this.close} center aria-labelledby="batch-id">
                    <DialogTitle>
                        {this.state.batch != null &&
                            <SectionTitle title={this.state.batch.number + ' - ' + this.state.batch.description}/>
                        }
                    </DialogTitle>
                    <DialogContent>
                        {this.state.batch != null &&
                            <Aux>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('status')}</FormLabel>
                                            {this.state.batch.status}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('strain')}</FormLabel>
                                            {this.state.batch.strain}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('room')}</FormLabel>
                                            {this.state.batch.currentRooms != null ? this.state.batch.currentRooms : ''}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('quantity')}</FormLabel>
                                            {this.state.batch.quantity}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Aux>
                        }
                    </DialogContent>
                    <DialogActions>
                        {this.state.batch != null &&
                            <Aux>
                                <Button variant="contained" color="primary" href={'/#/evorabatch/' + this.state.batch._id}>{t('view')}</Button>
                                <Button variant="contained" color="secondary" onClick={this.close}>{t('close')}</Button>
                            </Aux>
                        }
                    </DialogActions>
                </Dialog> 
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default withTranslation() (connect(mapStateToProps)(withRouter(EvoraBatchGantt)));