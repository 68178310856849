import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Aux from '../../hoc/Auxilary/Auxilary';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import SubSubHeading from '../UI/Typography/SubSubHeading';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import TextField from '../UI/TextField/TextField';
import Grid from '@mui/material/Grid';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class RoomSummary extends Component {
    constructor(props){
        super(props);
        this.state = {
            locations: [],
            open: [],
            locationOptions: [],
            location: '',
            locSel: null,
            room: ''
        }
    }

    async componentDidMount(){
        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        let storage = sessionStorage.getItem('roomSummary');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        this.setState({
            locationOptions: locations,
            location: storage != null ? storage.location : '',
            locSel: storage != null ? storage.locSel : null,
            room: storage != null ? storage.room : ''
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('roomSummary'), screen: this.props.auth.screenDefs.RoomSummary}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        let query = {
            location: this.state.location,
            room: this.state.room
        }
        let result = await axios.get('/api/roomsummary', {params: query});
        let open = [];
        if(result.data.length > 0){
            result.data.forEach(row => {
                let rooms = [];
                row.rooms.forEach(roomRow => {
                    let items = [];
                    roomRow.items.forEach(row => {
                        items.push(false);
                    });
                    rooms.push(items);
                });
                open.push(rooms);
            });
        }
        this.setState({locations: result.data, open: open});
        this.saveFilters();
    };

    saveFilters(){
        let data = {
            location: this.state.location,
            locSel: this.state.locSel,
            room: this.state.room
        };
        sessionStorage.setItem('roomSummary', JSON.stringify(data));
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value}, () => this.loadRecords());
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            this.loadRecords();
        });
    }

    setOpen(i, j, k){
        let open = this.state.open;
        open[i][j][k] = !open[i][j][k];
        this.setState({open: open});
    }

    render(){
        const t = this.props.t;
        const open = this.state.open;
        return(
            <Aux>
                <BreadcrumbBar/>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.locSel}
                                options={this.state.locationOptions}
                                onChange={this.changeAuto('locSel', 'location')}
                                label={t('location')}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.room} onChange={this.changeValue} name="room" label={t('room')}/>
                        </Grid>
                    </Grid>
                    {this.state.locations.map((row, i) => 
                        <Aux key={'locations-' + i}>
                            <br/>
                            <br/>
                            <SectionTitle title={row.location.name}/>
                            {row.rooms != null && row.rooms.length > 0 && row.rooms.map((roomRow, j) => 
                                <Aux key={i + '-locationRooms-' + j}>
                                    <SubSectionHeading title={roomRow.name + ' - ' + roomRow.plantCount}/>
                                    <TableContainer component={Paper}>
                                        <Table aria-label={'roomitem' + i + j}>
                                            <TableHead>
                                                <TableRow key={'header' + i + j}>
                                                    <TableCell sx={{width: 50}}></TableCell>
                                                    <TableCell>{t('item')}</TableCell>
                                                    <TableCell>{t('plants')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {roomRow.items != null && roomRow.items.length > 0 && roomRow.items.map((itemRow, k) =>
                                                    <Aux key={i + '-locationRooms-' + j + '-item-' + k}>
                                                        <TableRow key={itemRow.item._id + i + j + k}>
                                                            <TableCell sx={{ width: 50 }}>
                                                                <IconButton aria-label="expand row" size="small" onClick={() => this.setOpen(i, j, k)}>
                                                                    {open[i][j][k] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell>{itemRow.item.number + '-' + itemRow.item.name}</TableCell>
                                                            <TableCell>{itemRow.count}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                                <Collapse in={open[i][j][k]} timeout="auto" unmountOnExit>
                                                                    <Box margin={1}>
                                                                        <SubSubHeading title={t('batches')}/>
                                                                        <Table size="small" aria-label={'batches' + i + j + k}>
                                                                            <TableHead>
                                                                                <TableRow key={"batchrow" + i + j + k}>
                                                                                    <TableCell>{t('number')}</TableCell>
                                                                                    <TableCell>{t('plants')}</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {itemRow.batches.map((batchRow, l) =>
                                                                                    <TableRow key={"batchRec" + i + j + k + l}>
                                                                                        <TableCell>
                                                                                            <Link to={"/evorabatch/" + batchRow.batch._id}>{batchRow.batch.number}</Link>
                                                                                        </TableCell>
                                                                                        <TableCell>{batchRow.count}</TableCell>
                                                                                    </TableRow>
                                                                                )}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </Box>
                                                                </Collapse>
                                                            </TableCell>
                                                        </TableRow>
                                                    </Aux>
                                                )}
                                            </TableBody>                                            
                                        </Table>
                                    </TableContainer>
                                </Aux>
                            )}
                        </Aux>
                    )}
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(RoomSummary));