import React from 'react';
import MuiAvatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function Avatar(props) {
     const { user } = props;
     const thumb = user != null && user.profileImage != null && user.profileImage.thumbnail != null ? 
     "data:application/octet-stream;base64," + user.profileImage.thumbnail : null;

     if(thumb != null && thumb !== '')
          return <MuiAvatar src={thumb} alt={user != null && user.name != null ? user.name : null }/>;
     else if(user != null && user.name != null)
          return <MuiAvatar alt={user.name}/>;
     else
          return <MuiAvatar><AccountCircleIcon /></MuiAvatar>;
}