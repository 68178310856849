import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Box from '@mui/material/Box';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import SectionTitle from '../UI/Typography/SectionTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link } from 'react-router-dom';
import { fieldSecurity } from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withTranslation } from 'react-i18next';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Switch from '../UI/Switch/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

class Routing extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            foreignId: '',
            type: '',
            typeSel: null,
            types: [],
            documents: [],
            location:'',
            locationSel: null,
            locations: [],
            steps: [],
            timeUOMs: [],
            quantityUOMs: [],
            locked: false,
            sopOptions: [],
            fieldAccess: false,
            security: [],
            showCopy: false,
            newName: '',
            renderNum: Math.random(),
            isNew: false,
            enableSave: true,
            equipments: [],
            machineTypes: [],
            deleteOpen: false,
            rooms: [],
            addStepOpen: false,
            newSequence: '',
            equipmentAll: [],
            usedInItems: []
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location, common.cacheValues.quantityUOM, common.cacheValues.defaultRoom,
                                common.cacheValues.machineType, common.cacheValues.timeUOM, common.cacheValues.processingType, common.cacheValues.equipment,
                                common.cacheValues.equipmentAll], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        let values = await axios.get('/api/routingvalues');
        const fieldAccess = fieldSecurity(this.props.permission, null, this.props.auth.common, this.props.auth.constants);
        let isNew = (this.props.match.params.id == null || this.props.match.params.id === '') && this.props.permission.create;
        let location = '';
        let locationSel = null;
        if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
            locationSel = locations.find(x => x.value === this.props.auth.user.defaultLocation);
            location = this.props.auth.user.defaultLocation;
        } else {
            if(locations.length === 1){
                locationSel = locations[0];
                location = locationSel.value;
            }
        }
        this.setState({
            types: cacheValues.processingTypes,
            locations: locations,
            location: location,
            locationSel: locationSel,
            rooms: cacheValues.rooms,
            timeUOMs: cacheValues.timeUOMs,
            quantityUOMs: cacheValues.quantityUOMs,
            sopOptions: values.data.sops,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            isNew: isNew,
            locked: isNew ? false : true,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            workCenters: values.data.workCenters,
            machineTypes: cacheValues.machineTypes,
            equipments: cacheValues.equipments,
            equipmentAll: cacheValues.equipmentAll
        }, () => {
            if(this.state._id != null && this.state._id !== '') {
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/routings', label: this.props.t('routings'), screen: this.props.auth.screenDefs.Routing},
                    { path: 'active', label: this.props.t('routing'), screen: this.props.auth.screenDefs.Routing}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
                
                if(fieldAccess)
                    this.setState({locked: false});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('api/route/' + this.state._id);
        let locationSel = this.state.locations.find(x => x.value === record.data.location);
        let typeSel = this.state.types.find(x => x.value === record.data.type);
        let steps = record.data.steps;
        for(let row of steps){
            let roomSel = this.state.rooms.find(x => x.value === row.room);
            row.roomSel = roomSel;
            let setupTimeUOMSel = this.state.timeUOMs.find(x => x.value === row.setupTimeUOM);
            row.setupTimeUOMSel = setupTimeUOMSel;
            let runTimeUOMSel = this.state.timeUOMs.find(x => x.value === row.runTimeUOM);
            row.runTimeUOMSel = runTimeUOMSel;
            let waitTimeUOMSel = this.state.timeUOMs.find(x => x.value === row.waitTimeUOM);
            row.waitTimeUOMSel = waitTimeUOMSel;
            row.moveTimeUOMSel = this.state.timeUOMs.find(x => x.value === row.moveTimeUOM);
            row.fixedScrapUOMSel = row.fixedScrapUOM != null && row.fixedScrapUOM !== '' ? this.state.quantityUOMs.find(x => x.value === row.fixedScrapUOM) : null;
            let capacityUOMSel = this.state.quantityUOMs.find(x => x.value === row.capacityUOM);
            row.capacityUOMSel = capacityUOMSel;
            if(row.sops != null && row.sops.length > 0){
                let sopSels = [];
                for(let sop of row.sops){
                    let sopSel = this.state.sopOptions.find(x => x.value === sop);
                    sopSels.push(sopSel);
                }
                row.sopSels = sopSels;
            }
            if(row.equipments != null && row.equipments.length > 0){
                for(let equip of row.equipments){
                    equip.equipmentSel = equip.equipment != null ? this.state.equipmentAll.find(x => x.value === equip.equipment) : null;
                    equip.machineTypeSel = equip.machineType != null ? this.state.machineTypes.find(x => x.value === equip.machineType) : null;
                    if(equip.sops != null && equip.sops.length > 0){
                        let sopSels = [];
                        for(let sop of equip.sops){
                            let sopSel = this.state.sopOptions.find(x => x.value === sop);
                            sopSels.push(sopSel);
                        }
                        equip.sopSels = sopSels;
                    }
                }
            }
        }
        this.setState({
            number: record.data.number,
            name: record.data.name,
            type: record.data.type,
            typeSel: typeSel,
            location: record.data.location,
            locationSel: locationSel,
            documents: record.data.documents,
            steps: steps,
            usedInItems: record.data.usedInItems
        });
    }

    changeValue = (e) => {
        let name = e.target.name;
        let value = e.target.value
        this.setState({[name]: value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value});
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(data === 'location'){
            if(newValue != null){
                let roomResult = await axios.get('/api/roomselect', {params: {location: newValue.value}});
                this.setState({rooms: roomResult.data})
            }
        }
    };

    changeStepValue = (name, i) => (e) => {
        let steps = this.state.steps;
        let step = steps[i];
        step[name] = e.target.value;
        this.setState({steps: steps});
    }

    changeStepNumber = (name, i) => (e) => {
        let value = e.target.value;
        if(isNaN(value)){
            return;
        }
        let steps = this.state.steps;
        let step = steps[i];
        step[name] = e.target.value;
        this.setState({steps: steps});
    }

    changeStepAuto = (name, data, i) => async (e, newValue) => {
        let steps = this.state.steps;
        let step = steps[i];
        step[name] = newValue;
        step[data] = newValue != null ? newValue.value : null;
        this.setState({steps: steps});
    }

    changeStepBool = (name, i) => {
        let steps = this.state.steps;
        let step = steps[i];
        step[name] = !step[name];
        this.setState({steps: steps});
    } 

    changeStepMulti = (name, data, i) => (e, newValue) => {
        let steps = this.state.steps;
        let step = steps[i];
        var val = newValue;
        var ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        step[name] = newValue;
        step[data] = ids
        this.setState({steps: steps});
    };

    changeEquipStepMulti = (name, data, i, j) => (e, newValue) => {
        let steps = this.state.steps;
        let step = steps[i];
        let equip = step.equipments[j];
        var val = newValue;
        var ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        equip[name] = newValue;
        equip[data] = ids
        this.setState({steps: steps});
    };

    changeEquipStepAuto = (name, data, i, j) => async (e, newValue) => {
        let steps = this.state.steps;
        let step = steps[i];
        let equip = step.equipments[j];
        equip[name] = newValue;
        equip[data] = newValue != null ? newValue.value : null;
        this.setState({steps: steps});
    };

    save = () => {
        if(!this.state.enableSave || this.state.locked) return;
        this.setState({enableSave: false}, async () => {this._save()})
    }

    _save = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid){
            this.setState({enableSave: true});
            return;
        }
        try {
            let steps = this.state.steps;
            for(let step of steps){
                for(let row of step.equipments){
                    row.equipment = row.equipment !== '' ? row.equipment : null;
                    row.machineType = row.machineType !== '' ? row.machineType : null;
                }
            }
            let data = {
                _id: this.state._id,
                number: this.state.number,
                name: this.state.name,
                foreignId: this.state.foreignId,
                type: this.state.type,
                location: this.state.location,
                steps: steps,
                cleanRoomBefore: this.state.cleanRoomBefore,
                cleanRoomAfter: this.state.cleanRoomAfter
            }
            let result = await axios.post('/api/route', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/routing/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    validate(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.name == null || state.name === '')
            errors[t('name')] = t('required');
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        let steps = state.steps;
        if(steps == null || steps.length === 0){
        } else {
            for(let [i, row] of steps.entries()){
                if(row.name == null || row.name === '')
                    errors[`${t('name')} (${t('line')} ${i})`] = t('required');
                if(row.room == null || row.room === '')
                    errors[`${t('room')} (${t('line')} ${i})`] = t('required');
                if(row.capacity != null && row.capacity !== '' && (row.capacityUOM == null || row.capacityUOM === ''))
                    errors[`${t('capacityUOM')} (${t('line')} ${i})`] = t('required');
                if(row.setupTime != null && row.setupTime !== '' && (row.setupTimeUOM == null || row.setupTimeUOM === ''))
                    errors[`${t('setupTimeUOM')} (${t('line')} ${i})`] = t('required');
                if(row.runTime != null && row.runTime !== '' && (row.runTimeUOM == null || row.runTimeUOM === ''))
                    errors[`${t('runTimeUOM')} (${t('line')} ${i})`] = t('required');
                if(row.waitTime != null && row.waitTime !== '' && (row.waitTimeUOM == null || row.waitTimeUOM === ''))
                    errors[`${t('waitTimeUOM')} (${t('line')} ${i})`] = t('required');
                if(row.moveTime != null && row.moveTime !== '' && (row.moveTimeUOM == null || row.moveTimeUOM === ''))
                    errors[`${t('moveTimeUOM')} (${t('line')} ${i})`] = t('required');
                if(row.fixedScrapQuantity != null && row.fixedScrapQuantity !== '' && row.fixedScrapQuantity !== 0 && (row.fixedScrapUOM == null || row.fixedScrapUOM === ''))
                    errors[`${t('fixedScrapUOM')} (${t('line')} ${i})`] = t('required');
                if((row.setupTime == null || row.setupTime === '') && row.setupTimeUOM != null && row.setupTimeUOM !== '')
                    errors[`${t('setupTime')} (${t('line')} ${i})`] = t('required');
                if((row.runTime == null || row.runTime === '') && row.runTimeUOM != null && row.runTimeUOM !== '')
                    errors[`${t('runTime')} (${t('line')} ${i})`] = t('required');
                if((row.waitTime == null || row.waitTime === '') && row.waitTimeUOM != null && row.waitTimeUOM !== '')
                    errors[`${t('waitTime')} (${t('line')} ${i})`] = t('required');
                if((row.moveTime == null || row.moveTime === '') && row.moveTimeUOM != null && row.moveTimeUOM !== '')
                    errors[`${t('moveTime')} (${t('line')} ${i})`] = t('required');
                if((row.fixedScrapQuantity == null || row.fixedScrapQuantity === '') && row.fixedScrapUOM != null && row.fixedScrapUOM !== '')
                    errors[`${t('fixedScrapQuantity')} (${t('line')} ${i})`] = t('required');
                if(row.equipments != null && row.equipments.length > 0){
                    for(let [j, equip] of row.equipments.entries()){
                        if((equip.equipment == null || equip.equipment === '') && (equip.machineType == null || equip.machineType === ''))
                            errors[`${t('equipment')} (${t('line')} ${i}, ${j})`] = t('equipmentOrTypeRequired');
                        if(equip.equipment != null && equip.equipment !== '' && equip.machineType != null && equip.machineType !== '')
                            errors[`${t('equipment')} (${t('line')} ${i}, ${j})`] = t('equipmentOrType');
                    }
                }
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteToggle = () => {
        this.setState({deleteOpen: !this.state.deleteOpen})
    }
    
    deleteRecord = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._deleteRecord(e);
        });
    }

    _deleteRecord = async (e) => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/route/' + this.state._id);
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.setState({enableSave: true});
            this.props.history.goBack();
        }
    };

    addStep = (e) => {
        let steps = this.state.steps;
        let newStep = {
            sequence: this.state.newSequence,
            name:'',
            room: '',
            roomSel: null,
            equipments: [],
            setupTime: '',
            setupTimeUOM: '',
            setupTimeUOMSel: null,
            runTime: '',
            runTimeUOM: '',
            runtimeUOMSel: null,
            waitTime: '',
            waitTimeUOM: '',
            waitTimeUOMSel: null,
            moveTime: '',
            moveTimeUOMSel: null,
            sops: [],
            fixedScrapQuantity: '',
            fixedScrapUOM: '',
            fixedScrapUOMSel: null,
            scrapPercent: '',
            runEffort: '',
            description: '',
            critical: false,
            cleanRoomAfter: false,
            cleanRoomBefore: false,
            capacity: '',
            capacityUOM: '',
            capacityUOMSel: null
        };
        steps.splice(this.state.newSequence - 1, 0, newStep);
        steps = this.resequnce(steps);
        this.setState({steps: steps, addStepOpen: false, newSequence: ''});
    };

    moveStep = (dir, i) => (e) => {
        let steps = this.state.steps;
        let toInd = dir === '+1' ? i - 1 : i + 1;
        steps.splice(toInd, 0, steps.splice(i, 1)[0]);
        steps = this.resequnce(steps);
        this.setState({steps: steps});
    }

    resequnce = (steps) => {
        for(let i = 0; i < steps.length; i++){
            steps[i].sequence = i + 1;
        }
        return steps;
    }

    removeStep = (i) => (e) => {
        let steps = this.state.steps;
        steps.splice(i, 1);
        steps = this.resequnce(steps);
        this.setState({steps: steps});
    }

    toggleAddStep = () => {
        this.setState({addStepOpen: !this.state.addStepOpen});
    }

    unlock= () => {
        this.setState({locked: !this.state.locked});
    }

    removeEquipStep = (i, j) => (e) => {
        let steps = this.state.steps;
        let step = steps[i];
        step.equipments.splice(j, 1);
        this.setState({steps: steps});
    }

    addEquipStep = (i) => (e) => {
        let steps = this.state.steps;
        let step = steps[i];
        step.equipments.push({
            equipment: '',
            equipmentSel: null,
            machineType: '',
            machineTypeSel: null,
            sops: [],
            sopSels: []
        });
        this.setState({steps: steps});
    }

    toggleCopy = (e) => {
        this.setState({showCopy: !this.state.showCopy});
    };

    performCopy = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._performCopy(e);
        })
    }

    _performCopy = async (e) => {
        const t = this.props.t;
        if(this.state.newName == null || this.state.newName === ''){
            this.setState({errors: {newName: t('required')}});
            return;
        }
        let data = {id: this.state._id, name: this.state.newName};
        try {
            let result = await axios.post('/api/routecopy', data);
            this.setState({enableSave: true, showCopy: false, newName: ''});
            this.closeCopy();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    render(){
        const locked = this.state.locked;
        const errors = this.props.errors;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        const t = this.props.t;
        const micro = this.props.auth.user.micro;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar 
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.feedingRecipe}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={2}>
                        {this.state.number != null && this.state.number !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl data-cy="number">
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={8} sm={4}>
                            {!locked &&
                                <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                size="medium" fullWidth={true}
                                disabled={locked}
                                label={t('name')}
                                error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}
                                required
                                data-cy="name"
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('name')}</FormLabel>
                                    {this.state.name}
                                </FormControl>
                            }
                        </Grid>
                        
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.foreignId} onChange={this.changeValue} name="foreignId"
                                size="medium" fullWidth={true} disabled={locked} label={t('erpId')} data-cy="foreignId"
                                error={errors[t('foreignId')] != null ? true : false} helperText={errors[t('foreignId')]}/>
                            </Grid>
                        }
                        {locked && this.state.foreignId != null && this.state.foreignId !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('erpId')}</FormLabel>
                                    {this.state.foreignId}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.typeSel}
                                    options={this.state.types}
                                    onChange={this.changeAuto('typeSel', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]}
                                    label={t('type')}
                                    disabled={locked}
                                    required
                                    data-cy="type"
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('type')}</FormLabel>
                                    {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.locationSel}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locationSel', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]}
                                    label={t('location')}
                                    disabled={locked}
                                    required
                                    data-cy="location"
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('location')}</FormLabel>
                                    {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title={t('steps')} add={!locked ? this.toggleAddStep : null} 
                                  security={this.props.permission} data-cy='addLine' />
                    {this.state.steps.map((row, i) => 
                        <Box borderColor="primary.main" {...defaultProps} key={row._id != null ? row._id + i : 'newLineId'}>
                            <Grid container spacing={2}>
                                <Grid item xs={2} sm={1}>
                                    <FormControl>
                                        <FormLabel>{t('seq')}</FormLabel>
                                        {row.sequence}
                                    </FormControl>
                                </Grid>
                                {!locked &&
                                    <Grid item xs={6} sm={3}>
                                        <TextField value={row.name} onChange={this.changeStepValue('name', i)} name={"name" + i}
                                        size="medium" fullWidth={true} disabled={locked} label={t('name')} required data-cy={"name" + i}
                                        error={errors[`${t('name')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('name')} (${t('line')} ${i})`]}/>
                                    </Grid>
                                }
                                {locked &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('name')}</FormLabel>
                                            {row.name}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={row.critical} onChange={() => this.changeStepBool('critical', i)}
                                    label={t('critical')} name="critical" disabled={locked} data-cy={"critical" + i}
                                    />
                                </Grid>
                                {!locked &&
                                    <Grid item xs={6} sm={3}>
                                         <AutoCompleteField
                                            value={row.roomSel}
                                            options={this.state.rooms}
                                            onChange={this.changeStepAuto('roomSel', 'room', i)}
                                            error={errors[`${t('room')} (${t('line')} ${i})`] != null ? true : false}
                                            helperText={errors[`${t('room')} (${t('line')} ${i})`]}
                                            label={t('room')}
                                            disabled={locked}
                                            required
                                            data-cy={"room" + i}
                                        />
                                    </Grid>
                                }
                                {locked && row.roomSel != null &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('room')}</FormLabel>
                                            <Link to={'/room/' + row.roomSel.value}>{row.roomSel.label}</Link>
                                        </FormControl>
                                    </Grid>
                                }
                                {!locked &&
                                    <Aux>
                                        <Grid item xs={10} sm={5}>
                                            <TextField value={row.description} onChange={this.changeStepValue('description', i)} name={"description" + i}
                                            size="medium" fullWidth={true} disabled={locked} label={t('description')} data-cy={"description" + i}
                                            error={errors[`${t('description')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('description')} (${t('line')} ${i})`]}/>
                                        </Grid>
                                        {!micro &&
                                            <Aux>
                                                <Grid item xs={2} sm={1}>
                                                    <TextField value={row.capacity} onChange={this.changeStepNumber('capacity', i)} name={"capacity" + i}
                                                    size="medium" fullWidth={true} disabled={locked} label={t('capacity')} data-cy={"capacity" + i}
                                                    error={errors[`${t('capacity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('capacity')} (${t('line')} ${i})`]}/>
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    <AutoCompleteField
                                                        value={row.capacityUOMSel}
                                                        options={this.state.quantityUOMs}
                                                        onChange={this.changeStepAuto('capacityUOMSel', 'capacityUOM', i)}
                                                        error={errors[`${t('capacityUOM')} (${t('line')} ${i})`] != null ? true : false}
                                                        helperText={errors[`${t('capacityUOM')} (${t('line')} ${i})`]}
                                                        label={t('uom')}
                                                        disabled={locked}
                                                        data-cy={"capacityUOM" + i}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={2}>
                                                    <TextField value={row.runEffort} onChange={this.changeStepNumber('runEffort', i)} name={"runEffort" + i}
                                                    size="medium" fullWidth={true} disabled={locked} label={t('runEffort')} data-cy={"runEffort" + i}
                                                    error={errors[`${t('runEffort')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('runEffort')} (${t('line')} ${i})`]}/>
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    <TextField value={row.setupTime} onChange={this.changeStepNumber('setupTime', i)} name={"setupTime" + i}
                                                    size="medium" fullWidth={true} disabled={locked} label={t('setupTime')} data-cy={"setupTime" + i}
                                                    error={errors[`${t('setupTime')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('setupTime')} (${t('line')} ${i})`]}/>
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    <AutoCompleteField
                                                        value={row.setupTimeUOMSel}
                                                        options={this.state.timeUOMs}
                                                        onChange={this.changeStepAuto('setupTimeUOMSel', 'setupTimeUOM', i)}
                                                        error={errors[`${t('setupTimeUOM')} (${t('line')} ${i})`] != null ? true : false}
                                                        helperText={errors[`${t('setupTimeUOM')} (${t('line')} ${i})`]}
                                                        label={t('uom')}
                                                        disabled={locked}
                                                        data-cy={"setupTimeUOM" + i}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    <TextField value={row.runTime} onChange={this.changeStepNumber('runTime', i)} name={"runTime" + i}
                                                    size="medium" fullWidth={true} disabled={locked} label={t('runTime')} data-cy={"runTime" + i}
                                                    error={errors[`${t('runTime')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('runTime')} (${t('line')} ${i})`]}/>
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    <AutoCompleteField
                                                        value={row.runTimeUOMSel}
                                                        options={this.state.timeUOMs}
                                                        onChange={this.changeStepAuto('runTimeUOMSel', 'runTimeUOM', i)}
                                                        error={errors[`${t('runTimeUOM')} (${t('line')} ${i})`] != null ? true : false}
                                                        helperText={errors[`${t('runTimeUOM')} (${t('line')} ${i})`]}
                                                        label={t('uom')}
                                                        disabled={locked}
                                                        data-cy={"runTimeUOM" + i}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    <TextField value={row.waitTime} onChange={this.changeStepNumber('waitTime', i)} name={"waitTime" + i}
                                                    size="medium" fullWidth={true} disabled={locked} label={t('waitTime')} data-cy={"waitTime" + i}
                                                    error={errors[`${t('waitTime')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('waitTime')} (${t('line')} ${i})`]}/>
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    <AutoCompleteField
                                                        value={row.waitTimeUOMSel}
                                                        options={this.state.timeUOMs}
                                                        onChange={this.changeStepAuto('waitTimeUOMSel', 'waitTimeUOM', i)}
                                                        error={errors[`${t('waitTimeUOM')} (${t('line')} ${i})`] != null ? true : false}
                                                        helperText={errors[`${t('waitTimeUOM')} (${t('line')} ${i})`]}
                                                        label={t('uom')}
                                                        disabled={locked}
                                                        data-cy={"waitTimeUOM" + i}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    <TextField value={row.moveTime} onChange={this.changeStepNumber('moveTime', i)} name={"moveTime" + i}
                                                    size="medium" fullWidth={true} disabled={locked} label={t('moveTime')} data-cy={"moveTime" + i}
                                                    error={errors[`${t('moveTime')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('moveTime')} (${t('line')} ${i})`]}/>
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    <AutoCompleteField
                                                        value={row.moveTimeUOMSel}
                                                        options={this.state.timeUOMs}
                                                        onChange={this.changeStepAuto('moveTimeUOMSel', 'moveTimeUOM', i)}
                                                        error={errors[`${t('moveTimeUOM')} (${t('line')} ${i})`] != null ? true : false}
                                                        helperText={errors[`${t('moveTimeUOM')} (${t('line')} ${i})`]}
                                                        label={t('uom')}
                                                        disabled={locked}
                                                        data-cy={"moveTimeUOM" + i}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    <TextField value={row.scrapPercent} onChange={this.changeStepNumber('scrapPercent', i)} name={"scrapPercent" + i}
                                                    size="medium" fullWidth={true} disabled={locked} label={t('scrapPercent')} data-cy={"scrapPercent" + i}
                                                    error={errors[`${t('scrapPercent')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('scrapPercent')} (${t('line')} ${i})`]}/>
                                                </Grid>
                                                <Grid item xs={4} sm={2}>
                                                    <TextField value={row.fixedScrapQuantity} onChange={this.changeStepNumber('fixedScrapQuantity', i)} name={"fixedScrapQuantity" + i}
                                                    size="medium" fullWidth={true} disabled={locked} label={t('fixedScrapAmount')} data-cy={"fixedScrapQuantity" + i}
                                                    error={errors[`${t('fixedScrapQuantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('fixedScrapQuantity')} (${t('line')} ${i})`]}/>
                                                </Grid>
                                                <Grid item xs={2} sm={1}>
                                                    <AutoCompleteField
                                                        value={row.fixedScrapUOMSel}
                                                        options={this.state.quantityUOMs}
                                                        onChange={this.changeStepAuto('fixedScrapUOMSel', 'fixedScrapUOM', i)}
                                                        error={errors[`${t('fixedScrapUOM')} (${t('line')} ${i})`] != null ? true : false}
                                                        helperText={errors[`${t('fixedScrapUOM')} (${t('line')} ${i})`]}
                                                        label={t('uom')}
                                                        disabled={locked}
                                                        data-cy={"fixedScrapUOM" + i}
                                                    />
                                                </Grid>
                                            </Aux>
                                        }
                                    </Aux>
                                }
                                {locked &&
                                    <Aux>
                                        {row.description != null && row.description !== '' &&
                                            <Grid item xs={10} sm={5}>
                                                <FormControl>
                                                    <FormLabel>{t('description')}</FormLabel>
                                                    {row.description}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {!micro &&
                                            <Aux>
                                                {row.capacity != null && row.capacity !== '' &&
                                                    <Grid item xs={2} sm={1}>
                                                        <FormControl>
                                                            <FormLabel>{t('capacity')}</FormLabel>
                                                            {row.capacityUOMSel != null ? row.capacity + row.capacityUOMSel.label : row.capacity}
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {row.runEffort != null && row.runEffort !== '' &&
                                                    <Grid item xs={2} sm={1}>
                                                        <FormControl>
                                                            <FormLabel>{t('runEffort')}</FormLabel>
                                                            {row.runEffort}
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {row.setupTime != null && row.setupTime !== '' && 
                                                    <Grid item xs={2} sm={1}>
                                                        <FormControl>
                                                            <FormLabel>{t('setupTime')}</FormLabel>
                                                            {row.setupTimeUOMSel != null ? row.setupTime + row.setupTimeUOMSel.label : row.setupTime}
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {row.runTime != null &&  row.runTime !== '' &&
                                                    <Grid item xs={2} sm={1}>
                                                        <FormControl>
                                                            <FormLabel>{t('runTime')}</FormLabel>
                                                            {row.runTimeUOMSel != null ? row.runTime + row.runTimeUOMSel.label : row.runTime}
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {row.waitTime != null &&  row.waitTime !== '' &&
                                                    <Grid item xs={2} sm={1}>
                                                        <FormControl>
                                                            <FormLabel>{t('waitTime')}</FormLabel>
                                                            {row.waitTimeUOMSel != null ? row.waitTime + row.waitTimeUOMSel.label : row.waitTime}
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {row.moveTime != null &&  row.moveTime !== '' &&
                                                    <Grid item xs={2} sm={1}>
                                                        <FormControl>
                                                            <FormLabel>{t('moveTime')}</FormLabel>
                                                            {row.moveTimeUOMSel != null ? row.moveTime + row.moveTimeUOMSel.label : row.moveTime}
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {row.scrapPercent != null &&  row.scrapPercent !== '' &&
                                                    <Grid item xs={2} sm={1}>
                                                        <FormControl>
                                                            <FormLabel>{t('scrapPercent')}</FormLabel>
                                                            {row.scrapPercent}
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {row.fixedScrapQuantity != null &&  row.fixedScrapQuantity !== '' &&
                                                    <Grid item xs={4} sm={2}>
                                                        <FormControl>
                                                            <FormLabel>{t('fixedScrapAmount')}</FormLabel>
                                                            {row.fixedScrapUOMSel != null ? row.fixedScrapQuantity + row.fixedScrapUOMSel.label : row.fixedScrapQuantity}
                                                        </FormControl>
                                                    </Grid>
                                                }
                                            </Aux>
                                        }
                                    </Aux>
                                }
                                {!micro &&
                                    <Aux>
                                         <Grid item xs={6} sm={3}>
                                            <FormControlLabel
                                                control={<Switch checked={row.cleanRoomBefore} onChange={() => this.changeStepBool('cleanRoomBefore', i)} name={"cleanRoomBefore" + i} 
                                                disabled={locked}/>} label={t('cleanRoomBefore')}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <FormControlLabel
                                                control={<Switch checked={row.cleanRoomAfter} onChange={() => this.changeStepBool('cleanRoomAfter', i)} name={"cleanRoomAfter" + i}
                                                disabled={locked}/>} label={t('cleanRoomAfter')}
                                            />
                                        </Grid>
                                        <Grid item sm={12}>
                                            <AutoCompleteField
                                                value={row.sopSels}
                                                multiple={true}
                                                options={this.state.sopOptions}
                                                onChange={this.changeStepMulti('sopSels', 'sops', i)}
                                                error={errors[`${t('sops')} (${t('line')} ${i})`] != null ? true : false}
                                                helperText={errors[`${t('sops')} (${t('line')} ${i})`]}
                                                label={t('workRecs')}
                                                disabled={locked}
                                                data-cy={"sops" + i}
                                            />
                                        </Grid>
                                    </Aux>
                                }
                            </Grid>
                            <br/>
                            <SubSectionHeading title={t('equipment')} />
                            <Grid container spacing={2} key={'equipHead' + i}>
                                <Grid item xs={6} sm={3}>{t('machineType')}</Grid>
                                <Grid item xs={6} sm={3}>{t('equipment')}</Grid>
                                {!micro &&
                                    <Grid item xs={10} sm={5}>{t('workRecs')}</Grid>
                                }
                                {!locked &&
                                    <Grid item xs={2} sm={1}>
                                        <IconButton onClick={this.addEquipStep(i)} color="secondary" data-cy={"addEquip" + i}>
                                            <AddBoxTwoToneIcon />
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>
                            {row.equipments != null && row.equipments.map((equip, j) => 
                                <Grid container spacing={2} key={'equip' + i + 'r' + j} sx={{ mt: 1 }}>
                                    {!locked &&
                                        <Aux>
                                            <Grid item xs={6} sm={3}>
                                                <AutoCompleteField
                                                    value={equip.machineTypeSel}
                                                    options={this.state.machineTypes}
                                                    onChange={this.changeEquipStepAuto('machineTypeSel', 'machineType', i, j)}
                                                    error={errors[`${t('machineType')} (${t('line')} ${i}, ${j})`] != null ? true : false}
                                                    helperText={errors[`${t('machineType')} (${t('line')} ${i}, ${j})`]}
                                                    disabled={locked}
                                                    data-cy={"machineType" + i + 'a' + j}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <AutoCompleteField
                                                    value={equip.equipmentSel}
                                                    options={this.state.equipments}
                                                    onChange={this.changeEquipStepAuto('equipmentSel', 'equipment', i, j)}
                                                    error={errors[`${t('equipment')} (${t('line')} ${i}, ${j})`] != null ? true : false}
                                                    helperText={errors[`${t('equipment')} (${t('line')} ${i}, ${j})`]}
                                                    disabled={locked}
                                                    data-cy={"equipment" + i + 'a' + j}
                                                />
                                            </Grid>
                                        </Aux>
                                    }
                                    {locked &&
                                        <Aux>
                                            <Grid item xs={6} sm={3}>
                                                <FormControl>
                                                    {equip.machineTypeSel != null ? equip.machineTypeSel.label : ''}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                {equip.equipmentSel != null &&
                                                    <FormControl>
                                                        <Link to={'/equipment/' + equip.equipmentSel.value}>{equip.equipmentSel.label}</Link>
                                                    </FormControl>
                                                }
                                            </Grid>
                                        </Aux>
                                    }
                                    {(!locked || (equip.sopSels != null && equip.sopSels.length > 0)) && !micro &&
                                        <Grid item xs={10} sm={5}>
                                            <AutoCompleteField
                                                value={equip.sopSels}
                                                multiple={true}
                                                options={this.state.sopOptions}
                                                onChange={this.changeEquipStepMulti('sopSels', 'sops', i, j)}
                                                error={errors[`${t('sops')} (${t('line')} ${i}, ${j})`] != null ? true : false}
                                                helperText={errors[`${t('sops')} (${t('line')} ${i}, ${j})`]}
                                                disabled={locked}
                                                data-cy={"sops" + i + 'a' + j}
                                            />
                                        </Grid>
                                    }
                                    {!locked &&
                                        <Grid item xs={2} sm={1}>
                                            <IconButton onClick={this.removeEquipStep(i, j)} color="secondary" data-cy={"removeEquip" + i + 'a' + j}>
                                                <RemoveCircleTwoToneIcon />
                                            </IconButton>
                                        </Grid>
                                    }
                                </Grid>
                            )}
                            {!locked &&
                                <Grid container spacing={3} key={"stepButtons" + i}>
                                    <Grid item sm={10}>
                                        <IconButton onClick={this.removeStep(i)} color="secondary" data-cy={"remove" + i}>
                                            <RemoveCircleTwoToneIcon />
                                        </IconButton>
                                        <IconButton onClick={this.moveStep(i, '+1')} color="secondary" data-cy={"remove" + i}>
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton onClick={this.moveStep(i, '-1')} color="secondary" data-cy={"remove" + i}>
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            }
                        </Box>
                    )}
                    <Aux>
                        <br/>
                        {!locked &&
                            <Aux>
                                <Button disabled={!this.state.enableSave} variant="contained" color="primary" size="small" onClick={this.save} data-cy="sate">
                                    {t('save')}
                                </Button>&nbsp;&nbsp;
                            </Aux>
                        }
                        {this.props.permission.unlock &&
                            <Button variant="contained" color="secondary" size="small" onClick={this.unlock} data-cy="unlock">
                                {locked ? t('unlock') : t('lock')}
                            </Button>
                        }&nbsp;&nbsp;
                        {this.state.security.delete && !locked &&
                            <Aux>
                                <Button variant="contained" color="secondary" size="small" onClick={this.deleteToggle} data-cy="delete">
                                    {t('delete')}
                                </Button>
                                &nbsp;&nbsp;
                            </Aux>
                        }
                        {this.state.security.update && locked && this.state._id != null && this.state._id !== '' &&
                            <Button variant="contained" color="secondary" size="small" onClick={this.toggleCopy} data-cy="copy">{t('copy')}</Button>
                        }
                        {this.state.usedInItems != null && this.state.usedInItems.length > 0 &&
                            <Aux>
                                <br/>
                                <br/>
                                <SectionTitle title={t('usedIn')} />
                                <Grid container spacing={3} key="itemHeader" sx={{pb: 1}}>
                                    <Grid item xs={8} sm={4}>{t('items')}</Grid>
                                </Grid>
                                {this.state.usedInItems.map((row, i) =>
                                    <Grid container spacing={3} key={row._id} sx={{pb: 2}}>
                                            <Grid item xs={8} sm={4}>
                                                <Link to={'/item/' + row._id}>{row.number + '-' + row.name}</Link>
                                            </Grid>
                                    </Grid>
                                )}
                            </Aux>
                        }
                        {this.state._id != null && this.state._id !== '' &&
                            <Aux>
                                <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.routing}/>
                                <ObjectLog id={this.state._id} renderNum={this.state.renderNum}/>
                            </Aux>
                        }
                    </Aux>
                </Panel>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteRecord()} open={this.state.deleteOpen} />
                }
                <Dialog open={this.state.addStepOpen} onClose={this.toggleAddStep} center maxWidth="sm">
                    <DialogTitle>
                        <SectionTitle title={t('Add')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item sm={6}>
                                <TextField value={this.state.newSequence} onChange={this.changeNumber} name="newSequence"
                                size="medium" fullWidth={true} label={t('seq')} data-cy="newSequence"
                                error={errors[t('newSequence')] != null ? true : false} helperText={errors[t('newSequence')]}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button name='cancelLineInSequence' variant="contained" color="secondary" onClick={this.closeCopy}>{t('close')}</Button>
                        <Button name='addLineInSequence' variant="contained" color="primary" onClick={this.addStep}>{t('add')}</Button>
                    </DialogActions>
                </Dialog>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteRecord()} open={this.state.deleteOpen} />
                }
                <Dialog open={this.state.showCopy} onClose={this.toggleCopy} center maxWidth="sm" fullWidth>
                    <DialogTitle>
                        <SectionTitle title={t('copy')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item sm={10}>
                                <TextField value={this.state.newName} onChange={this.changeValue} name="newName"
                                size="medium" fullWidth={true} label={t('newName')}
                                error={errors[t('newName')] != null ? true : false} helperText={errors[t('newName')]}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={this.performCopy}>{t('copy')}</Button>
                        <Button variant="contained" color="secondary" onClick={this.toggleCopy}>{t('close')}</Button>
                    </DialogActions>
                </Dialog>
            </Aux>
        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Routing));