import React from 'react';
import FormControl from '@mui/material/FormControl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';
import { useTheme } from '@mui/material/styles';

function isISO8601(dateString) {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(([+-]\d{2}:\d{2})|Z)?$/;
  return regex.test(dateString);
}

export default function TimeSelect(props) {
  const { className, label, onChange, value, helperText, error, disabled, required } = props;
  const theme = useTheme();

  let timeValue = null;
  if(value != null && value !== ''){
    const adapter = new AdapterDateFns();
    if(isISO8601(value)) {
        timeValue = adapter.parseISO(value);
    }else if(value instanceof Date){
        timeValue = value;
    }else {
        timeValue = null;
        onChange(null)
    }
  }

  return (
    <FormControl className={className}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          format={'h:mm a'}
          id={"date-picker-inline-" + label}
          label={label}
          value={timeValue}
          onChange={onChange}
          autoOk="true"
          disabled={disabled}
          slotProps={{
            textField: { 
              helperText: helperText,
              variant: 'standard', 
              required: required,
              error: error,
              clearable: true, 
              onClear: () => onChange(null)
            },
            openPickerIcon: {
              style: { color: error ? theme.palette.error.main : 'grey' }              
            }
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );  
};