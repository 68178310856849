import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Link from '../UI/Link/Link';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import Aux from '../../hoc/Auxilary/Auxilary';
// import ActionBar from '../UI/Buttons/ActionBar';
import Panel from '../UI/Panel/Panel';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import ProgressBar from '../UI/Progress/ProgressBar';
import { EvoraTabs, TabCanvas } from '../UI/Tabs/Tabs';
import UncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckedIcon from '@mui/icons-material/CheckCircleTwoTone';
import TenantUsersIcon from '@mui/icons-material/PeopleOutlineTwoTone';
import LocationsIcon from '@mui/icons-material/LocationOnTwoTone';
import RoomsIcon from '@mui/icons-material/MeetingRoomTwoTone';
import BinIcon from '@mui/icons-material/AllInboxTwoTone';
import ItemCategoryIcon from '@mui/icons-material/ViewColumnTwoTone';
import ItemIcon from '@mui/icons-material/ListAltTwoTone';
import CustomerIcon from '@mui/icons-material/RecentActorsTwoTone';
import VendorIcon from '@mui/icons-material/ContactsTwoTone';
import ActivityDefinitionIcon from 'mdi-material-ui/FolderText';
import BatchTemplateIcon from 'mdi-material-ui/FolderMultiple';
import StrainIcon from '@mui/icons-material/LocalFloristTwoTone';
import EquipmentIcon from '@mui/icons-material/ListAltTwoTone';
import RoutingIcon from '@mui/icons-material/DeviceHubTwoTone';
import ProductionBOMIcon from '@mui/icons-material/BallotTwoTone';
import WorkCenterIcon from 'mdi-material-ui/WindowShutterOpen';
import StarFilled from '@mui/icons-material/StarTwoTone';
import StarEmpty from '@mui/icons-material/StarBorderTwoTone';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';


class LaunchPad extends Component {
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            core: {
                activityDefinitions: {},
                batchTemplates: {},
                strains: {},
                locations: {},
                users: {},
                rooms: {},
                bins: {},
                items: {},
                customers: {},
                vendors: {},
                equipment: {},
                productionBOMs: {},
                routes: {},
                workCenters: {},
                stage1Status: 0,
                stage2Status: 0,
                stage3Status: 0,
                milestones: []
            },
            quality: {
                milestones: []
            },
            tabKey: 0,
            tabLabels: [
                {name: t('core'), disabled: false},
                //{name: t('quality'), disabled: false}
            ],
            style: {
                avatar: {
                    fontSize: '40px'
                },
                avatarDisabled: {
                    color: 'red'
                },
                status1: {
                    color: 'secondary.main'
                },
                status2: {
                    color: 'primary.main'
                },
                activeText: {
                    display: 'inline'
                },
                disabledText: {
                    color: 'text.disabled',
                    cursor: 'default'
                },
                completeText: {
                    display: 'inline',
                    textDecoration: 'line-through',
                },
                checkbox: {
                    display: 'inline-block'
                },
                info: {
                    color: 'info.main',
                    display: 'inline-block'
                },
                progressText: {
                    mt: 1,
                    color: 'text.secondary'
                },
                starFilled2: {
                    color: 'primary.main'
                },
                starFilled1: {
                    color: 'secondary.main'
                },
                starEmpty: {
                    color: 'text.disabled'
                },
                titleLink: {
                    fontWeight: 'bold',
                    cursor: 'pointer'
                }
            }
        }
    }

    componentDidMount(){
        const crumbs = [
            { path: 'active', label: this.props.t('launchPad'), screen: this.props.auth.screenDefs.LaunchPad}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        this.loadRecord();
    }

    loadRecord = async () => {
        const results = await axios.get('/api/launchStatus');
        const core = results.data.core;
        const quality = results.data.quality;
        this.setState({core, quality});
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    coreMilestones = () => {
        const { t } = this.props;
        const { ctlsReports, destructions, productionBatches, productionOrders, purchases, 
                receipts, salesOrders, shipments } = this.state.core.milestones;
        const classes = this.state.style;
        return (
            <Panel>
                <SubSectionHeading title={t('keyMilestones')} />
                <Grid container item spacing={0}>
                    <Grid item xs={12}>
                        <List sx={{ width: '100%',
                                    backgroundColor: 'background.paper'
                                  }}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    {purchases != null && purchases !== '' && purchases > 0 ? 
                                        <StarFilled sx={purchases === 2 ? classes.starFilled2 : classes.starFilled1} /> :
                                        <StarEmpty sx={classes.starEmpty} />
                                    }
                                </ListItemAvatar>
                                <ListItemText
                                    primary={t('launchPad.core.milestone1')}
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    {receipts != null && receipts !== '' && receipts > 0 ? 
                                        <StarFilled sx={receipts === 2 ? classes.starFilled2 : classes.starFilled1} /> :
                                        <StarEmpty sx={classes.starEmpty} />
                                    }
                                </ListItemAvatar>
                                <ListItemText
                                    primary={t('launchPad.core.milestone2')}
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    {productionOrders != null && productionOrders !== '' && productionOrders > 0 ? 
                                        <StarFilled sx={productionOrders === 2 ? classes.starFilled2 : classes.starFilled1} /> :
                                        <StarEmpty sx={classes.starEmpty} />
                                    }
                                </ListItemAvatar>
                                <ListItemText
                                    primary={t('launchPad.core.milestone3')}
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    {productionBatches != null && productionBatches !== '' && productionBatches > 0 ? 
                                        <StarFilled sx={productionBatches === 2 ? classes.starFilled2 : classes.starFilled1} /> :
                                        <StarEmpty sx={classes.starEmpty} />
                                    }
                                </ListItemAvatar>
                                <ListItemText
                                    primary={t('launchPad.core.milestone4')}
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    {salesOrders != null && salesOrders !== '' && salesOrders > 0 ? 
                                        <StarFilled sx={salesOrders === 2 ? classes.starFilled2 : classes.starFilled1} /> :
                                        <StarEmpty sx={classes.starEmpty} />
                                    }
                                </ListItemAvatar>
                                <ListItemText
                                    primary={t('launchPad.core.milestone5')}
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    {shipments != null && shipments !== '' && shipments > 0 ? 
                                        <StarFilled sx={shipments === 2 ? classes.starFilled2 : classes.starFilled1} /> :
                                        <StarEmpty sx={classes.starEmpty} />
                                    }
                                </ListItemAvatar>
                                <ListItemText
                                    primary={t('launchPad.core.milestone6')}
                                />
                            </ListItem>                            
                            <Divider variant="inset" component="li" />
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    {destructions != null && destructions !== '' && destructions > 0 ? 
                                        <StarFilled sx={destructions === 2 ? classes.starFilled2 : classes.starFilled1} /> :
                                        <StarEmpty sx={classes.starEmpty} />
                                    }
                                </ListItemAvatar>
                                <ListItemText
                                    primary={t('launchPad.core.milestone7')}
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    {ctlsReports != null && ctlsReports !== '' && ctlsReports > 0 ? 
                                        <StarFilled sx={ctlsReports === 2 ? classes.starFilled2 : classes.starFilled1} /> :
                                        <StarEmpty sx={classes.starEmpty} />
                                    }
                                </ListItemAvatar>
                                <ListItemText
                                    primary={t('launchPad.core.milestone8')}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Panel>
        );
    }

    coreContent = () => {
        const { auth, t } = this.props;
        const { activityDefinitions, batchTemplates, bins, customers, equipment, items,  
                locations, productionBOMs, routes, rooms, strains, users, vendors, workCenters } = this.state.core;
        const classes = this.state.style;
        return (
            <Grid container spacing={1} sx={{ justifyContent: 'flex-start' }} >
                <Grid container item direction='column' xs={12} md={6} xl={4}>
                    <Panel>
                        <SubSectionHeading title={t('stage1Objectives')} />
                        <Typography
                            component="span"
                            variant="h6"
                            color="textPrimary"
                            sx={classes.progressText}
                        >
                            {t('progress')}
                        </Typography>
                        <ProgressBar variant="determinate" value={this.state.core.stage1Status}/>
                        <hr />
                        <Grid container item spacing={0}>
                            <Grid item xs={12}>
                                <List sx={{ width: '100%',
                                            backgroundColor: 'background.paper'
                                        }}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <TenantUsersIcon 
                                                sx={
                                                    users.status != null && users.status !== '' && users.status === 2 ? `${classes.avatar} ${classes.status2}` :
                                                    users.status != null && users.status !== '' && users.status === 1 ? `${classes.avatar} ${classes.status1}` :  
                                                    classes.avatar
                                                }                                                    
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    component="span"
                                                    color="textPrimary"
                                                >
                                                    {t('launchPad.create')} <Link href='/#/tenantusers' 
                                                    sx={classes.titleLink}>{t('launchPad.s1.user.title')}</Link>
                                                </Typography>                                                
                                            }
                                            secondary={
                                                <Grid container spacing={3}>
                                                    <Grid item xs={1}>
                                                        {users.part1 != null && users.part1 !== '' && users.part1 > 0 ? 
                                                            <CheckedIcon sx={`${classes.checkbox} ${users.part1 === 1 ? classes.status1 : classes.status2}`} /> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={users.part1 != null && users.part1 !== '' && 
                                                                        users.part1 === 2 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s1.user.step1')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        {users.part2 != null && users.part2 !== '' && users.part2 > 0 ? 
                                                            <CheckedIcon sx={`${classes.checkbox} ${users.part2 === 1 ? classes.status1 : classes.status2}`} /> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={users.part2 != null && users.part2 !== '' && 
                                                                        users.part2 === 2 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s1.user.step2')}
                                                        </Typography>
                                                    </Grid>                                                 
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <LocationsIcon sx={locations.count != null && locations.count !== '' && 
                                                                        locations.count > 0 ? `${classes.avatar} ${classes.status2}` : classes.avatar }
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    component="span"
                                                    color="textPrimary"
                                                >
                                                    {t('launchPad.create')} <Link href='/#/locations' 
                                                    sx={classes.titleLink}>{t('launchPad.s1.location.title')}</Link>
                                                </Typography>                                                
                                            }
                                            secondary={
                                                <Grid container spacing={3}>
                                                    <Grid item xs={1}>
                                                        {locations.count != null && locations.count !== '' && locations.count > 0 ? 
                                                            <CheckedIcon sx={`${classes.checkbox} ${classes.status2}`}/> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={locations.count != null && locations.count !== '' && 
                                                                        locations.count > 0 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s1.location.step1')}
                                                        </Typography>
                                                    </Grid>                                               
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <RoomsIcon sx={rooms.status != null && rooms.status !== '' && 
                                                                    rooms.status > 0 ? `${classes.avatar} ${classes.status2}` : 
                                                                    rooms.status != null && rooms.status !== '' && 
                                                                    rooms.status === -1 ? `${classes.avatarDisabled} ${classes.avatar}`: classes.avatar }/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    component='span'
                                                    color="textPrimary"
                                                    sx={rooms.status === -1 ? classes.disabledText : classes.activeText}
                                                >
                                                    {rooms.status > -1 ? 
                                                        <Aux>
                                                            {t('launchPad.create')} <Link href='/#/rooms' sx={classes.titleLink}>{t('launchPad.s1.room.title')}</Link>
                                                        </Aux> : 
                                                            t('launchPad.create') + " " + t('launchPad.s1.room.title')
                                                    }
                                                </Typography>                                                
                                            }
                                            secondary={rooms.status != null && rooms.status !== '' && rooms.status !== -1 &&
                                                <Grid container spacing={3}>
                                                    <Grid item xs={1}>
                                                        {rooms.part1 != null && rooms.part1 !== '' && rooms.part1 > 0 ? 
                                                            <CheckedIcon sx={`${classes.checkbox} ${classes.status2}`}/> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={rooms.part1 != null && rooms.part1 !== '' && 
                                                                        rooms.part1 > 0 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s1.room.step1')}
                                                        </Typography>
                                                    </Grid>                                               
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <BinIcon sx={bins.status != null && bins.status !== '' && 
                                                                bins.status > 0 ? `${classes.avatar} ${classes.status2}` : 
                                                                bins.status != null && bins.status !== '' && 
                                                                bins.status === -1 ? `${classes.avatarDisabled} ${classes.avatar}`: classes.avatar }/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    component='span'
                                                    color="textPrimary"
                                                    sx={bins.status === -1 ? classes.disabledText : classes.activeText}
                                                >
                                                    {bins.status > -1 ? 
                                                        <Aux>
                                                            {t('launchPad.create')} <Link href='/#/bins' sx={classes.titleLink}>{t('launchPad.s1.bin.title')}</Link>
                                                        </Aux> : 
                                                            t('launchPad.create') + " " + t('launchPad.s1.bin.title')
                                                    }
                                                </Typography>                                                
                                            }
                                            secondary={bins.status != null && bins.status !== '' && bins.status !== -1 &&
                                                <Grid container spacing={3}>
                                                    <Grid item xs={1}>
                                                        {bins.part1 > 0 ?
                                                            <CheckedIcon sx={`${classes.checkbox} ${classes.status2}`}/> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={bins.part1 != null && bins.part1 !== '' && 
                                                                        bins.part1 > 0 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s1.bin.step1')}
                                                        </Typography>
                                                    </Grid>                                               
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <ItemCategoryIcon sx={items.categories != null && items.categories !== '' && 
                                                                            items.categories.status > 0 ? `${classes.avatar} ${classes.status2}` : 
                                                                            items.categories != null && items.categories !== '' && 
                                                                            items.categories.status === -1 ? `${classes.avatarDisabled} ${classes.avatar}`: classes.avatar }/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    component='span'
                                                    color="textPrimary"
                                                    sx={items.categories != null && items.categories !== '' && 
                                                               items.categories.status === -1 ? classes.disabledText : classes.activeText}
                                                >
                                                    {items.categories != null && items.categories !== '' && 
                                                     items.categories.status !== -1 ? 
                                                        <Aux>
                                                            {t('launchPad.createUpdate')} <Link href='/#/itemcategories' sx={classes.titleLink}>{t('launchPad.s1.itemCategory.title')}</Link>
                                                        </Aux> : 
                                                            t('launchPad.createUpdate') + " " + t('launchPad.s1.itemCategory.title')
                                                    }
                                                </Typography>                                                
                                            }
                                            secondary={items.categories != null && items.categories !== '' && items.categories.status !== -1 &&
                                                <Grid container spacing={3}>
                                                    <Grid item xs={1}>
                                                        {items.categories.part1 > 0 ?
                                                            <CheckedIcon sx={`${classes.checkbox} ${classes.status2}`}/> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={items.categories.part1 != null && items.categories.part1 !== '' && 
                                                                        items.categories.part1 > 0 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s1.itemCategory.step1')}
                                                        </Typography>
                                                    </Grid>                                               
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <ItemIcon sx={items.items != null && items.items !== '' && 
                                                                    items.items.status === 2 ? `${classes.avatar} ${classes.status2}` : 
                                                                    items.items != null && items.items !== '' && 
                                                                    items.items.status === 1 ? `${classes.avatar} ${classes.status1}` : 
                                                                    items.items != null && items.items !== '' && 
                                                                    items.items.status === -1 ? `${classes.avatarDisabled} ${classes.avatar}`: classes.avatar }/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    component='span'
                                                    color="textPrimary"
                                                    sx={items.items != null && items.items !== '' && 
                                                               items.items.status === -1 ? classes.disabledText : classes.activeText}
                                                >
                                                    {items.items != null && items.items !== '' && 
                                                     items.items.status !== -1 ? 
                                                        <Aux>
                                                            {t('launchPad.create')} <Link href='/#/itemlist' sx={classes.titleLink}>{t('launchPad.s1.item.title')}</Link>
                                                        </Aux> : 
                                                            t('launchPad.create') + " " + t('launchPad.s1.item.title')
                                                    }
                                                </Typography>                                                
                                            }
                                            secondary={items.items != null && items.items !== '' && items.items.status !== -1 &&
                                                <Grid container spacing={3}>
                                                    <Grid item xs={1}>
                                                        {items.items.part1 > 0 ?
                                                            <CheckedIcon sx={`${classes.checkbox} ${classes.status2}`}/> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={items.items.part1 != null && items.items.part1 !== '' && 
                                                                        items.items.part1 > 0 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s1.item.step1')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        {items.items.part2 > 0 ?
                                                            <CheckedIcon sx={`${classes.checkbox} ${classes.status2}`}/> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={items.items.part2 != null && items.items.part2 !== '' && 
                                                                        items.items.part2 > 0 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s1.item.step2')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        {items.items.part3 > 0 ?
                                                            <CheckedIcon sx={`${classes.checkbox} ${classes.status2}`}/> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={items.items.part3 != null && items.items.part3 !== '' && 
                                                                        items.items.part3 > 0 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s1.item.step3')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <CustomerIcon sx={customers != null && customers !== '' && 
                                                                        customers.status > 0 ? `${classes.avatar} ${classes.status2}` : 
                                                                        customers != null && customers !== '' && 
                                                                        customers.status === -1 ? `${classes.avatarDisabled} ${classes.avatar}`: classes.avatar }/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    component='span'
                                                    color="textPrimary"
                                                    sx={customers != null && customers !== '' && 
                                                               customers.status === -1 ? classes.disabledText : classes.activeText}
                                                >
                                                    {customers != null && customers !== '' && 
                                                     customers.status !== -1 ? 
                                                        <Aux>
                                                            {t('launchPad.create')} <Link href='/#/customers' sx={classes.titleLink}>{t('launchPad.s1.customer.title')}</Link>
                                                        </Aux> : 
                                                            t('launchPad.create') + " " + t('launchPad.s1.customer.title')
                                                    }
                                                </Typography>                                                
                                            }
                                            secondary={customers != null && customers !== '' && customers.status !== -1 &&
                                                <Grid container spacing={3}>
                                                    <Grid item xs={1}>
                                                        {customers.part1 > 0 ?
                                                            <CheckedIcon sx={`${classes.checkbox} ${classes.status2}`}/> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={customers.part1 != null && customers.part1 !== '' && 
                                                                        customers.part1 > 0 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s1.customer.step1')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <VendorIcon sx={vendors != null && vendors !== '' && 
                                                                    vendors.status > 0 ? `${classes.avatar} ${classes.status2}` : 
                                                                    vendors != null && vendors !== '' && 
                                                                    vendors.status === -1 ? `${classes.avatarDisabled} ${classes.avatar}`: classes.avatar }/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    component='span'
                                                    color="textPrimary"
                                                    sx={vendors != null && vendors !== '' && 
                                                                vendors.status === -1 ? classes.disabledText : classes.activeText}
                                                >
                                                    {vendors != null && vendors !== '' && 
                                                    vendors.status !== -1 ? 
                                                        <Aux>
                                                            {t('launchPad.create')} <Link href='/#/vendors' sx={classes.titleLink}>{t('launchPad.s1.vendor.title')}</Link>
                                                        </Aux> : 
                                                            t('launchPad.create') + " " + t('launchPad.s1.vendor.title')
                                                    }
                                                </Typography>                                                
                                            }
                                            secondary={vendors != null && vendors !== '' && vendors.status !== -1 &&
                                                <Grid container spacing={3}>
                                                    <Grid item xs={1}>
                                                        {vendors.part1 > 0 ?
                                                            <CheckedIcon sx={`${classes.checkbox} ${classes.status2}`}/> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={vendors.part1 != null && vendors.part1 !== '' && 
                                                                        vendors.part1 > 0 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s1.vendor.step1')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Panel>
                </Grid>
                {auth.user != null && auth.user !== '' && auth.user.cultivator &&
                    <Grid container item direction='column' xs={12} md={6} xl={4}>
                        <Panel>
                            <SubSectionHeading title={t('stage2Objectives')} />
                            <Typography
                                component="span"
                                variant="h6"
                                color="textPrimary"
                                sx={classes.progressText}
                            >
                                {t('progress')}
                            </Typography>
                            <ProgressBar variant="determinate" value={this.state.core.stage2Status}/>
                            <hr />
                            <Grid container item spacing={0}>
                                <Grid item xs={12}>
                                    <List sx={{ width: '100%',
                                                backgroundColor: 'background.paper'
                                            }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <ActivityDefinitionIcon sx={
                                                        activityDefinitions.status != null && activityDefinitions.status !== '' && activityDefinitions.status === 2 ? `${classes.avatar} ${classes.status2}` :
                                                        activityDefinitions.status != null && activityDefinitions.status !== '' && activityDefinitions.status === 1 ? `${classes.avatar} ${classes.status1}` : 
                                                        activityDefinitions.status != null && activityDefinitions.status !== '' && activityDefinitions.status === -1 ? `${classes.avatar} ${classes.avatarDisabled}` : 
                                                        classes.avatar
                                                    }/>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography
                                                        component='span'
                                                        color="textPrimary"
                                                        sx={activityDefinitions != null && activityDefinitions !== '' && 
                                                                    activityDefinitions.status === -1 ? classes.disabledText : classes.activeText}
                                                    >
                                                        {activityDefinitions != null && activityDefinitions !== '' && 
                                                        activityDefinitions.status !== -1 ? 
                                                            <Aux>
                                                                {t('launchPad.create')} <Link href='/#/activityDefinitions' sx={classes.titleLink}>{t('launchPad.s2.activityDefinition.title')}</Link>
                                                            </Aux> : 
                                                                t('launchPad.create') + " " + t('launchPad.s2.activityDefinition.title')
                                                        }
                                                    </Typography>                                                
                                                }
                                                secondary={activityDefinitions.status != null && activityDefinitions.status !== '' && activityDefinitions.status !== -1 &&
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={1}>
                                                            {activityDefinitions.part1 != null && activityDefinitions.part1 !== '' && activityDefinitions.part1 > 0 ? 
                                                                <CheckedIcon sx={`${classes.checkbox} ${activityDefinitions.part1 === 1 ? classes.status1 : classes.status2}`} /> : <UncheckedIcon sx={classes.checkbox}/>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                sx={activityDefinitions.part1 != null && activityDefinitions.part1 !== '' && 
                                                                        activityDefinitions.part1 === 2 ? classes.completeText : classes.activeText}
                                                                color="textPrimary"
                                                            >
                                                                {t('launchPad.s2.activityDefinition.step1')}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <BatchTemplateIcon sx={
                                                        batchTemplates.status != null && batchTemplates.status !== '' && batchTemplates.status === 2 ? `${classes.avatar} ${classes.status2}` :
                                                        batchTemplates.status != null && batchTemplates.status !== '' && batchTemplates.status === 1 ? `${classes.avatar} ${classes.status1}` : 
                                                        batchTemplates.status != null && batchTemplates.status !== '' && batchTemplates.status === -1 ? `${classes.avatar} ${classes.avatarDisabled}` : 
                                                        classes.avatar
                                                    }/>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography
                                                        component='span'
                                                        color="textPrimary"
                                                        sx={batchTemplates != null && batchTemplates !== '' && 
                                                                   batchTemplates.status === -1 ? classes.disabledText : classes.activeText}
                                                    >
                                                        {batchTemplates != null && batchTemplates !== '' && 
                                                        batchTemplates.status !== -1 ? 
                                                            <Aux>
                                                                {t('launchPad.create')} <Link href='/#/batchTemplates' sx={classes.titleLink}>{t('launchPad.s2.batchTemplate.title')}</Link>
                                                            </Aux> : 
                                                                t('launchPad.create') + " " + t('launchPad.s2.batchTemplate.title')
                                                        }
                                                    </Typography>                                                
                                                }
                                                secondary={batchTemplates.status != null && batchTemplates.status !== '' && batchTemplates.status !== -1 &&
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={1}>
                                                            {batchTemplates.part1 != null && batchTemplates.part1 !== '' && batchTemplates.part1 > 0 ? 
                                                                <CheckedIcon sx={`${classes.checkbox} ${batchTemplates.part1 === 1 ? classes.status1 : classes.status2}`} /> : <UncheckedIcon sx={classes.checkbox}/>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                sx={batchTemplates.part1 != null && batchTemplates.part1 !== '' && 
                                                                        batchTemplates.part1 === 2 ? classes.completeText : classes.activeText}
                                                                color="textPrimary"
                                                            >
                                                                {t('launchPad.s2.batchTemplate.step1')}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <StrainIcon sx={
                                                        strains.status != null && strains.status !== '' && strains.status === 2 ? `${classes.avatar} ${classes.status2}` :
                                                        strains.status != null && strains.status !== '' && strains.status === 1 ? `${classes.avatar} ${classes.status1}` : 
                                                        strains.status != null && strains.status !== '' && strains.status === -1 ? `${classes.avatar} ${classes.avatarDisabled}` : 
                                                        classes.avatar}/>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography
                                                        component='span'
                                                        color="textPrimary"
                                                        sx={strains != null && strains !== '' && 
                                                        strains.status === -1 ? classes.disabledText : classes.activeText}
                                                    >
                                                        {strains != null && strains !== '' && 
                                                        strains.status !== -1 ? 
                                                            <Aux>
                                                                {t('launchPad.create')} <Link href='/#/strains' sx={classes.titleLink}>{t('launchPad.s2.strain.title')}</Link>
                                                            </Aux> : 
                                                                t('launchPad.create') + " " + t('launchPad.s2.strain.title')
                                                        }
                                                    </Typography>                                                
                                                }
                                                secondary={strains.status != null && strains.status !== '' && strains.status !== -1 &&
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={1}>
                                                            {strains.part1 != null && strains.part1 !== '' && strains.part1 > 0 ? 
                                                                <CheckedIcon sx={`${classes.checkbox} ${strains.part1 === 1 ? classes.status1 : classes.status2}`} /> : <UncheckedIcon sx={classes.checkbox}/>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                sx={strains.part1 != null && strains.part1 !== '' && 
                                                                        strains.part1 === 2 ? classes.completeText : classes.activeText}
                                                                color="textPrimary"
                                                            >
                                                                {t('launchPad.s2.strain.step1')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            {strains.part2 != null && strains.part2 !== '' && strains.part2 > 0 ? 
                                                                <CheckedIcon sx={`${classes.checkbox} ${strains.part2 === 1 ? classes.status1 : classes.status2}`} /> : <UncheckedIcon sx={classes.checkbox}/>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                sx={strains.part2 != null && strains.part2 !== '' && 
                                                                        strains.part2 === 2 ? classes.completeText : classes.activeText}
                                                                color="textPrimary"
                                                            >
                                                                {t('launchPad.s2.strain.step2')}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        </ListItem>
                                        <Divider variant="inset" component="li" />
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <ItemIcon sx={
                                                        strains.part3 != null && strains.part3 !== '' && strains.part3 === 2 ? `${classes.avatar} ${classes.status2}` :
                                                        strains.part3 != null && strains.part3 !== '' && strains.part3 === 1 ? `${classes.avatar} ${classes.status1}` : 
                                                        strains.part3 != null && strains.part3 !== '' && strains.part3 === -1 ? `${classes.avatar} ${classes.avatarDisabled}` : 
                                                        classes.avatar}/>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography
                                                        component='span'
                                                        color="textPrimary"
                                                        sx={strains != null && strains !== '' && 
                                                        strains.part3 === -1 ? classes.disabledText : classes.activeText}
                                                    >
                                                        {strains != null && strains !== '' && 
                                                        strains.part3 !== -1 ? 
                                                            <Aux>
                                                                {t('launchPad.create')} <Link href='/#/strains' sx={classes.titleLink}>{t('launchPad.s2.item.title')}</Link>
                                                            </Aux> : 
                                                                t('launchPad.create') + " " + t('launchPad.s2.item.title')
                                                        }
                                                    </Typography>                                                
                                                }
                                                secondary={strains.part3 != null && strains.part3 !== '' && strains.part3 !== -1 &&
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={1}>
                                                            {strains.part3 != null && strains.part3 !== '' && strains.part3 > 0 ? 
                                                                <CheckedIcon sx={`${classes.checkbox} ${strains.part3 === 1 ? classes.status1 : classes.status2}`} /> : <UncheckedIcon sx={classes.checkbox}/>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                sx={strains.part3 != null && strains.part3 !== '' && 
                                                                        strains.part3 === 2 ? classes.completeText : classes.activeText}
                                                                color="textPrimary"
                                                            >
                                                                {t('launchPad.s2.item.step1')}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Panel>
                    </Grid>
                }                    
                <Grid container item direction='column' xs={12} md={6} xl={4}>
                    <Panel>
                        <SubSectionHeading title={auth.user.cultivator ? t('stage3Objectives') : t('stage2Objectives')} />
                        <Typography
                            component="span"
                            variant="h6"
                            color="textPrimary"
                            sx={classes.progressText}
                        >
                            {t('progress')}
                        </Typography>
                        <ProgressBar variant="determinate" value={this.state.core.stage3Status}/>
                        <hr />
                        <Grid container item spacing={0}>
                            <Grid item xs={12}>
                                <List sx={{ width: '100%',
                                            backgroundColor: 'background.paper'
                                        }}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <EquipmentIcon sx={equipment.status != null && equipment.status !== '' && equipment.status === 2 ? `${classes.avatar} ${classes.status2}` :
                                                                        equipment.status != null && equipment.status !== '' && equipment.status === 1 ? `${classes.avatar} ${classes.status1}` : 
                                                                        equipment.status != null && equipment.status !== '' && equipment.status === -1 ? `${classes.avatar} ${classes.avatarDisabled}` : 
                                                                        classes.avatar
                                                }/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    component='span'
                                                    color="textPrimary"
                                                    sx={equipment != null && equipment !== '' && 
                                                    equipment.status === -1 ? classes.disabledText : classes.activeText}
                                                >
                                                    {equipment != null && equipment !== '' && 
                                                    equipment.status !== -1 ? 
                                                        <Aux>
                                                            {t('launchPad.create')} <Link href='/#/equipmentlist' sx={classes.titleLink}>{t('launchPad.s3.equipment.title')}</Link>
                                                        </Aux> : 
                                                            t('launchPad.create') + " " + t('launchPad.s3.equipment.title')
                                                    }
                                                </Typography>                                                
                                            }
                                            secondary={equipment.status != null && equipment.status !== '' && equipment.status !== -1 &&
                                                <Grid container spacing={1}>
                                                    <Grid item xs={1}>
                                                        {equipment.part1 != null && equipment.part1 !== '' && equipment.part1 > 0 ? 
                                                            <CheckedIcon sx={`${classes.checkbox} ${equipment.part1 === 1 ? classes.status1 : classes.status2}`} /> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={equipment.part1 != null && equipment.part1 !== '' && 
                                                                        equipment.part1 === 2 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s3.equipment.step1')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <WorkCenterIcon sx={workCenters.status != null && workCenters.status !== '' && workCenters.status === 2 ? `${classes.avatar} ${classes.status2}` :
                                                                        workCenters.status != null && workCenters.status !== '' && workCenters.status === 1 ? `${classes.avatar} ${classes.status1}` : 
                                                                        workCenters.status != null && workCenters.status !== '' && workCenters.status === -1 ? `${classes.avatar} ${classes.avatarDisabled}` : 
                                                                        classes.avatar}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    component='span'
                                                    color="textPrimary"
                                                    sx={workCenters != null && workCenters !== '' && 
                                                    workCenters.status === -1 ? classes.disabledText : classes.activeText}
                                                >
                                                    {workCenters != null && workCenters !== '' && 
                                                    workCenters.status !== -1 ? 
                                                        <Aux>
                                                            {t('launchPad.create')} <Link href='/#/workCenters' sx={classes.titleLink}>{t('launchPad.s3.workCenter.title')}</Link>
                                                        </Aux> : 
                                                            t('launchPad.create') + " " + t('launchPad.s3.workCenter.title')
                                                    }
                                                </Typography>                                                
                                            }
                                            secondary={workCenters.status != null && workCenters.status !== ''  && workCenters.status !== -1 &&
                                                <Grid container spacing={1}>
                                                    <Grid item xs={1}>
                                                        {workCenters.part1 != null && workCenters.part1 !== '' && workCenters.part1 > 0 ? 
                                                            <CheckedIcon sx={`${classes.checkbox} ${workCenters.part1 === 1 ? classes.status1 : classes.status2}`} /> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={workCenters.part1 != null && workCenters.part1 !== '' && 
                                                                        workCenters.part1 === 2 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s3.workCenter.step1')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <RoutingIcon sx={routes.status != null && routes.status !== '' && routes.status === 2 ? `${classes.avatar} ${classes.status2}` :
                                                                    routes.status != null && routes.status !== '' && routes.status === 1 ? `${classes.avatar} ${classes.status1}` : 
                                                                    routes.status != null && routes.status !== '' && routes.status === -1 ? `${classes.avatar} ${classes.avatarDisabled}` : 
                                                                    classes.avatar}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    component='span'
                                                    color="textPrimary"
                                                    sx={routes != null && routes !== '' && 
                                                    routes.status === -1 ? classes.disabledText : classes.activeText}
                                                >
                                                    {routes != null && routes !== '' && 
                                                    routes.status !== -1 ? 
                                                        <Aux>
                                                            {t('launchPad.create')} <Link href='/#/routings' sx={classes.titleLink}>{t('launchPad.s3.routing.title')}</Link>
                                                        </Aux> : 
                                                            t('launchPad.create') + " " + t('launchPad.s3.routing.title')
                                                    }
                                                </Typography>                                                
                                            }
                                            secondary={routes.status != null && routes.status !== ''  && routes.status !== -1 &&
                                                <Grid container spacing={1}>
                                                    <Grid item xs={1}>
                                                        {routes.part1 != null && routes.part1 !== '' && routes.part1 > 0 ? 
                                                            <CheckedIcon sx={`${classes.checkbox} ${routes.part1 === 1 ? classes.status1 : classes.status2}`} /> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={routes.part1 != null && routes.part1 !== '' && 
                                                                        routes.part1 === 2 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s3.routing.step1')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <ProductionBOMIcon sx={productionBOMs.status != null && productionBOMs.status !== '' && productionBOMs.status === 2 ? `${classes.avatar} ${classes.status2}` :
                                                                            productionBOMs.status != null && productionBOMs.status !== '' && productionBOMs.status === 1 ? `${classes.avatar} ${classes.status1}` : 
                                                                            productionBOMs.status != null && productionBOMs.status !== '' && productionBOMs.status === -1 ? `${classes.avatar} ${classes.avatarDisabled}` : 
                                                                            classes.avatar}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    component='span'
                                                    color="textPrimary"
                                                    sx={productionBOMs != null && productionBOMs !== '' && 
                                                    productionBOMs.status === -1 ? classes.disabledText : classes.activeText}
                                                >
                                                    {productionBOMs != null && productionBOMs !== '' && 
                                                    productionBOMs.status !== -1 ? 
                                                        <Aux>
                                                            {t('launchPad.create')} <Link href='/#/boms' sx={classes.titleLink}>{t('launchPad.s3.productionBOM.title')}</Link>
                                                        </Aux> : 
                                                            t('launchPad.create') + " " + t('launchPad.s3.productionBOM.title')
                                                    }
                                                </Typography>                                                
                                            }
                                            secondary={productionBOMs.status != null && productionBOMs.status !== '' && productionBOMs.status !== -1 &&
                                                <Grid container spacing={1}>
                                                    <Grid item xs={1}>
                                                        {productionBOMs.part1 != null && productionBOMs.part1 !== '' && productionBOMs.part1 > 0 ? 
                                                            <CheckedIcon sx={`${classes.checkbox} ${productionBOMs.part1 === 1 ? classes.status1 : classes.status2}`} /> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={productionBOMs.part1 != null && productionBOMs.part1 !== '' && 
                                                                        productionBOMs.part1 === 2 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s3.productionBOM.step1')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <ItemIcon sx={items.stage3 != null && items.stage3 !== '' && items.stage3.status === 2 ? `${classes.avatar} ${classes.status2}` :
                                                                    items.stage3 != null && items.stage3 !== '' && items.stage3.status === 1 ? `${classes.avatar} ${classes.status1}` : 
                                                                    items.stage3 != null && items.stage3 !== '' && items.stage3.status === -1 ? `${classes.avatar} ${classes.avatarDisabled}` : 
                                                                    classes.avatar}/>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    component='span'
                                                    color="textPrimary"
                                                    sx={items.stage3 != null && items.stage3 !== '' && 
                                                    items.stage3.status === -1 ? classes.disabledText : classes.activeText}
                                                >
                                                    {items.stage3 != null && items.stage3 !== '' && 
                                                    items.stage3.status !== -1 ? 
                                                        <Aux>
                                                            {t('launchPad.update')} <Link href='/#/itemlist' sx={classes.titleLink}>{t('launchPad.s3.itemUpdate.title')}</Link>
                                                        </Aux> : 
                                                            t('launchPad.update') + " " + t('launchPad.s3.itemUpdate.title')
                                                    }
                                                </Typography>                                                
                                            }
                                            secondary={items.stage3 != null && items.stage3 !== '' && items.stage3.status !== -1 &&
                                                <Grid container spacing={1}>
                                                    <Grid item xs={1}>
                                                        {items.stage3.route != null && items.stage3.route !== '' && items.stage3.route > 0 ? 
                                                            <CheckedIcon sx={`${classes.checkbox} ${items.stage3.route === 1 ? classes.status1 : classes.status2}`} /> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={items.stage3 != null && items.stage3 !== '' && 
                                                                        items.stage3.route === 2 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s3.itemUpdate.step1')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        {items.stage3.productionBOM != null && items.stage3.productionBOM !== '' && items.stage3.productionBOM > 0 ? 
                                                            <CheckedIcon sx={`${classes.checkbox} ${items.stage3.productionBOM === 1 ? classes.status1 : classes.status2}`} /> : <UncheckedIcon sx={classes.checkbox}/>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            sx={items.stage3.productionBOM != null && items.stage3.productionBOM !== '' && 
                                                                        items.stage3.productionBOM === 2 ? classes.completeText : classes.activeText}
                                                            color="textPrimary"
                                                        >
                                                            {t('launchPad.s3.itemUpdate.step2')}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Panel>
                </Grid>
                <Grid container item direction='column' xs={12} md={6} xl={4}>
                    {this.coreMilestones()}
                </Grid>
            </Grid>
        );
    }

    render() {
        const { tabLabels, tabKey } = this.state;
        return (
            <Aux>
                <BreadcrumbBar />
                <EvoraTabs tabLabels={tabLabels} tabKey={tabKey} changeTab={this.changeTab} />
                <TabCanvas value={tabKey} index={0}>{this.coreContent()}</TabCanvas>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(LaunchPad));