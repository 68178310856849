import React from 'react';
import Grid from '@mui/material/Grid';
import { CreateButton } from './Buttons';

export default function ListButtonStack(props) {
  const { createLocation, security, onClick} = props;
  let locationProvided = false;
  const locationButton = <CreateButton location={createLocation} security={security != null ? security.create : false} />;
  const onClickButton = <CreateButton createClick={onClick} security={security != null ? security.create : false} />;

  if(createLocation !== undefined) locationProvided = true;

  return (
    <Grid container spacing={2}>
        <Grid item sx={{ m: 1 }}>
            {locationProvided ? locationButton : onClickButton}
        </Grid>
    </Grid>
  );
}