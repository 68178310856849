import { SET_NAVMENU_LOCATION, OPEN_NEW_NAVMENU, OPEN_NEW_NAVSUBMENU, TOGGLE_NAV_DRAWER,
         SET_NAV_CRUMBS, TOGGLE_NAVMENU, TOGGLE_NAVSUBMENU, RESET_NAVMENU } from '../actions/types';
const initialState = {
    category: '',
    crumbs: [],
    index: '',
    openMenu: '',
    openSubMenu: '',
    isOpen: false,
    subIsOpen: false,
    screenDef: '',
    drawerOpen: true,
    drawerWidth: 240
}

function navMenu(state = initialState, action){
    switch(action.type){
        case SET_NAVMENU_LOCATION: {
            return {
                ...state,
                category: action.payload.category,
                index: action.payload.index,
                screenDef: action.payload.screenDef
            }
        }
        case SET_NAV_CRUMBS: {
            return {
                ...state,
                crumbs: action.payload
            }
        }
        case OPEN_NEW_NAVMENU: {
            return {
                ...state,
                openMenu: action.payload,
                isOpen: true
            }
        }
        case OPEN_NEW_NAVSUBMENU: {
            return {
                ...state,
                openSubMenu: action.payload,
                subIsOpen: true
            }
        }
        case TOGGLE_NAV_DRAWER: {
            return {
                ...state,
                drawerOpen: !state.drawerOpen
            }
        }
        case TOGGLE_NAVMENU: {
            return {
                ...state,
                isOpen: !state.isOpen
            }
        }
        case TOGGLE_NAVSUBMENU: {
            return {
                ...state,
                subIsOpen: !state.subIsOpen
            }
        }
        case RESET_NAVMENU: {
            return initialState;
        }
        default:
            return state;
    }
}

export default navMenu;