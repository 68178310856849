import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class ManageUsers extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            orderBy: 'lastName',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            security: [],
            email: '',
            firstName: '',
            lastName: ''
        }
    }

    async componentDidMount(){
        this.setState({
            security: this.props.permission
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('users'), screen: this.props.auth.screenDefs.ManageUsers}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            size: this.state.listSize,
            offset: this.state.offset,
            search: this.state.search,
            email: this.state.email,
            sort: JSON.stringify(sort),
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            active: this.state.active != null ? this.state.active : null
        };
        const result = await axios.get('/api/tenantusers', {params: data});
        this.setState({
            list: result.data.data,
            totalCount: result.data.totalCount
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords();});
    };
    
    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }
    
    handleRowsPerPage = e => {
          this.setState({listSize: parseInt(e.target.value)}, () => {
              this.loadRecords();
          });
    };
    
    handleChangePage = (event, newPage) => {
          this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
              this.loadRecords();
          });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.search} onChange={this.changeValue} name="search"
                            size="medium" fullWidth label={t('lastName')} />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.firstName} onChange={this.changeValue} name="firstName"
                            size="medium" fullWidth label={t('firstName')} />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.email} onChange={this.changeValue} name="email"
                            size="medium" fullWidth label={t('email')} />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="User List">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('userid')}
                                            active={this.state.orderBy === 'userid'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('userid')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('firstName')}
                                            active={this.state.orderBy === 'firstName'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('firstName')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('lastName')}
                                            active={this.state.orderBy === 'lastName'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('lastName')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('email')}
                                            active={this.state.orderBy === 'email'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('email')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Active</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                <TableRow key={row._id}>
                                    <TableCell><Link to={'/manageuser/' + row._id}>{row.userid}</Link></TableCell>
                                    <TableCell>{row.firstName}</TableCell>
                                    <TableCell>{row.lastName}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{row.active != null && row.active ? t('yes') : row.active != null && !row.active ? t('no') : ''}</TableCell>
                                </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
  });
  
  export default withTranslation() (connect(mapStateToProps)(ManageUsers));