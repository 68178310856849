export function fieldSecurity(permissions, status, constants){
    if(readOnlyFieldSecurity(permissions)) return false;
    if(status === constants.statuses.new && permissions.create === true){
        return true;
    } 
    if((status === constants.statuses.released || status === constants.statuses.shipped || 
        status === constants.statuses.returned) && permissions.update === true){
        return true;
    }
    return false;
}

export function readOnlyFieldSecurity(permissions) {
    if( permissions.read === true &&
        permissions.create !== true && permissions.update !== true &&
        permissions.review !== true && permissions.approve !== true ) {
      return true;
    }else {
      return false;
    }
}

export function saveButtonSecurity(permissions, status, constants){
    if(status === constants.statuses.new && permissions.create === true){
        return true;
    } 
    if((status === constants.statuses.released || status === constants.statuses.shipped || 
        status === constants.statuses.returned) && permissions.update === true){
        return true;
    }
    return false;
}

export function unlockButtonSecurity(permissions, status, constants){
    if(status === constants.statuses.released && permissions.create === true){
        return true;
    }
    if((status === constants.statuses.reviewed || status === constants.statuses.signed) && permissions.unlock === true)
        return true;
    return false;
}

export function reviewSigSecurity(permissions, signature, status, constants){
    if(status === constants.statuses.returned || status === constants.statuses.reviewed ||
        status === constants.statuses.signed){
        if((signature === undefined && permissions) || signature !== undefined)
            return true;
    }
    return false;
}

export function approveSigSecurity(permissions, signature, status, constants){
    if(status === constants.statuses.reviewed || status === constants.statuses.signed){
        if((signature === undefined && permissions) || signature !== undefined)
            return true;
    }
    return false;
}

export function releaseButtonSecurity(permissions, status, constants){
    if(status === constants.statuses.new && permissions.create)
        return true;
    return false;
}

export function shipButtonSecurity(permissions, status, constants){
    if(status === constants.statuses.released && permissions.update)
        return true;
    return false;
}