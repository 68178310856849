// Deprecated: FormEntry,
import HeadersFootersList from '../Admin/HeadersFootersList';
import { Register, Home, SopList, Sop, BinSummary, InventoryBreakdown,
         SopInstance, UserList, User, SopClient, CcxPosting, CcxPostingList, ExciseAdjustmentList,
         SopClientList, SopClientInstances, DocumentCertification, EquipmentCalibrationChecks, EquipmentCalibrationCheck,
         AdminSettings, Deviation, DeviationList, PesticideRecipes, PesticideRecipe, BatchActivities,
         NonConformance, NcrList, ChangePassword, FormComponent, EquipmentCalibrationTypes, EquipmentCalibrationType,
         DestructionList, Destruction, ValueTypes, Values, AnnouncementList, Announcement, 
         InjuryHazardous, InjuryHazardousList, IncidentList, Incident, EmployeeIllnessList, 
         EmployeeIllness, LossCannabisList, LossCannabis, RecallList, Recall, AvailableInventory, AvailableInventoryList,
         PesticidePreparationList, PesticidePreparation, VistorList, Visitor, SigninList, 
         Signin, EquipmentList, Equipment, EquipmentLogs, EquipmentLog, HeadersAndFooters,
         SettingPrefix, SopInstancePrint, CannabisInventory, FeedingRecipeList, FeedingRecipe,
         RoomSettings, LocationList, LocationDetail, LocationInspection, RoomSummary, PastInventoryList, PastInventory,
         VendorList, Vendor, VendorAuditList, VendorAudit, CleaningSolutionList, MasterCaseList, MasterCase,
         CleaningSolution, SaturationTestList, SaturationTest, FeedingLotList, RoomFeedingList, RoomFeeding,
         FeedingLot, PractitionerList, Practitioner, CustomerList, Customer, LogList, ScheduledActivities, 
         ScheduledActivity, RoomCheckList, RoomCheck, ItemCategories, ItemList, Item, Strains, 
         CropTreatmentList, CropTreatment, TestItemCategories, TestItemList, TestDefinitionList, 
         TestDefinition, TestResultList, TestResults, LotList, Lot, PestScoutingList, PestScouting,
         Rooms, Room, Routings, Routing, ProductionBoms, ProductionBom, CleaningSolutionRecipe, CleaningSolutionRecipes,
         CropTreatmentPrint, IncidentPrint, NcrPrint, LossPrint, ExciseStampAudit,
         WorkRecords, MessageList, Message, UserProfile, TaskRiskAssessmentList, RiskAssessments, RiskAssessment,
         TaskRiskAssessment, MaintenanceRequestList, MaintenanceRequest, TenantList,
         Tenant, HCReportList, HCReport, QADashboard, DataUploadList, DestructionPrint,
         Strain, CultivationDashboard, ManagedDocumentList, ManagedDocument, CapaList,
         DocumentReviewList, DocumentReview, ProductionPlan, ProductionPlanList , ProductionScheduleList,
         ProductionSchedule, ChangeRequestList, ChangeRequest, ScreenDefinitions, Screens, Screen, Location, ProcessingDashboard,
         BatchTemplates, BatchTemplate, BatchActivityDefinitions, CultivationBatchList, CultivationBatch, EvoraBatchPrint, BatchActivity,
         ToteList, BinList, Bin, ContainerList, Container, TenantUsers, TenantUser, ObjectList, ObjectScreen, 
         EProductionOrderList, EProductionOrder, ProductionOrderActivity, CuringList, Curing,
         ExternalTreatmentList, ExternalTreatment, ItemLedgers, ItemLedger, SaleList, Sale, 
         WorkCenterList, WorkCenter, ShipmentList, Shipment, DocumentCertificationList, DryingOrderList, DryingOrder,
         DryingActivity, TrimmingOrderList, TrimmingOrder, TrimmingActivity, CuringActivity,
         EmailTemplateList, EmailTemplate, ResetPassword, EmailSentList, EmailSent, RoomCleaningList, RoomCleaning, 
         TenantReportList, TenantReport, Management, ReceiptList, Receipt, InvoiceList, Invoice,
         PurchaseOrderList, PurchaseOrder, Countries, Provinces, DestructionReport, ProductionBatches, ProductionBatch,
         ProductionBatchPrint, ManageUsers, ManageUser, CustomerComplaints, CustomerComplaint, UacReport,
         ManagedDocumentTemplates, ManagedDocumentTemplate, Cars, Car, ApiLogs, DataUpload, LaunchPad, PrintView } from './Components';

const routes = [
  { exact: true, path: "/home", name: "Home", Component: Home, navMenu: { category: "home", index: "home", screenDef: 'Home' }, auth: { required: true, internal: false }},
  { exact: true, path: "/register", name: "Register", Component: Register, navMenu: { category: '', index: '', screenDef: '' }, auth: { required: true, internal: false }},
  { exact: true, path: "/sops", name: "SOP List", Component: SopList, navMenu: { category: "documents", index: "sops", screenDef: 'SopList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/instance/new/:sopId", name: "SOP Instance", Component: SopInstance, navMenu: { category: "document", index: "", screenDef: 'SopInstance' }, auth: { required: true, internal: false }},
  { exact: true, path: "/sop/:id", name: "SOP", Component: Sop, navMenu: { category: "documents", index: "sops", screenDef: 'SopInstance' }, auth: { required: true, internal: false }},
  { exact: true, path: "/sop/", name: "SOP", Component: Sop, navMenu: { category: "document", index: "sops", screenDef: 'SopInstance' }, auth: { required: true, internal: false }},
  { exact: true, path: "/instances/:sopId", name: "SOP Client Instance", Component: SopClientInstances, navMenu: { category: "", index: "", screenDef: 'WorkRecords' }, auth: { required: true, internal: false }},
  { exact: true, path: "/instances/:sopId", name: "SOP Instance", Component: SopInstance, navMenu: { category: "", index: "", screenDef: 'WorkRecords' }, auth: { required: true, internal: false }},
  { exact: true, path: "/instance/new/:sopId/:entityId", name: "SOP Instance", Component: SopInstance, navMenu: { category: "", index: "", screenDef: 'WorkRecords' }, auth: { required: true, internal: false }},
  { exact: true, path: "/instance/:instanceId", name: "SOP Instance", Component: SopInstance, navMenu: { category: "documents", index: "workrecords", screenDef: 'WorkRecords' }, auth: { required: true, internal: false }},
  { exact: true, path: "/instanceprint/:instanceId", name: "SOP Instance Print", Component: SopInstancePrint, navMenu: { category: "documents", index: "workrecords", screenDef: 'WorkRecords' }, auth: { required: true, internal: false }},
  { exact: true, path: "/users", name: "User List", Component: UserList, navMenu: { category: "config", index: "users", screenDef: 'UserList' }, auth: { required: true, internal: true }},
  { exact: true, path: "/user/:id", name: "User", Component: User, navMenu: { category: "config", index: "users", screenDef: 'User' }, auth: { required: true, internal: true }},
  { exact: true, path: "/user/", name: "User", Component: User, navMenu: { category: "config", index: "users", screenDef: 'User' }, auth: { required: true, internal: true }},
  { exact: true, path: "/sopclient/:id", name: "SOP Client", Component: SopClient, navMenu: { category: "", index: "", screenDef: '' }, auth: { required: true, internal: false }},
  { exact: true, path: "/sopclient", name: "SOP Client List", Component: SopClientList, navMenu: { category: "", index: "", screenDef: '' }, auth: { required: true, internal: false }},
  { exact: true, path: "/cert/:sopId", name: "Document Certification", Component: DocumentCertification, navMenu: { category: "", index: "", screenDef: 'DocumentCertification' }, auth: { required: true, internal: false }},
  { exact: true, path: "/cert", name: "Document Certification", Component: DocumentCertification, navMenu: {category: "", index: "", screenDef: 'DocumentCertification' }, auth: { required: true, internal: false }},
  { exact: true, path: "/certid/:id", name: "Document Certification", Component: DocumentCertification, navMenu: {category: "", index: "", screenDef: 'DocumentCertification' }, auth: { required: true, internal: false }},
  { exact: false, path: "/certs", name: "Document Certifications", Component: DocumentCertificationList, navMenu: { category: "documents", index: "documentCertifications", screenDef: 'DocumentCertificationList' }, auth: { required: true, internal: false }},
  { exact: false, path: "/settings", name: "Admin Settings", Component: AdminSettings, navMenu: { category: "admin", index: "settings", screenDef: 'AdminSettings' }, auth: { required: true, internal: false }},
  { exact: true, path: "/deviation/:id", name: "Deviation", Component: Deviation, navMenu: { category: "investigations", index: "deviations", screenDef: 'Deviation' }, auth: { required: true, internal: false }},
  { exact: true, path: "/deviation", name: "Deviation Add", Component: Deviation, navMenu: { category: "investigations", index: "deviations", screenDef: 'Deviation' }, auth: { required: true, internal: false }},
  { exact: true, path: "/deviation/:entityId/:type", name: "Deviation Add Type", Component: Deviation, navMenu: { category: "investigations", index: "deviations", screenDef: 'Deviation' }, auth: { required: true, internal: false }},
  { exact: false, path: "/deviations", name: "Deviations List", Component: DeviationList, navMenu: { category: "investigations", index: "deviations", screenDef: 'DeviationList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/ncr/:id", name: "Non Conformance", Component: NonConformance, navMenu: { category: "investigations", index: "ncrs", screenDef: 'NonConformance' }, auth: { required: true, internal: false }},
  { exact: false, path: "/ncrprint/:id", name: "Non Conformance Print", Component: NcrPrint, navMenu: { category: "investigations", index: "ncrs", screenDef: '' }, auth: { required: true, internal: false }},
  { exact: false, path: "/ncrs", name: "Non Conformance List", Component: NcrList, navMenu: { category: "investigations", index: "ncrs", screenDef: 'NonConformanceList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/ncr", name: "Non Conformance Add", Component: NonConformance, navMenu: { category: "investigations", index: "ncrs", screenDef: 'NonConformance' }, auth: { required: true, internal: false }},
  { exact: true, path: "/ncr/:entityId/:type", name: "Non Conformance Report", Component: NonConformance, navMenu: { category: "investigations", index: "ncrs", screenDef: 'NonConformance' }, auth: { required: true, internal: false }},
  { exact: false, path: "/changepassword", name: "Change Password", Component: ChangePassword, navMenu: { category: "", index: "", screenDef: '' }, auth: { required: true, internal: false }},
  { exact: false, path: "/formComponent", name: "Form Component", Component: FormComponent, navMenu: { category: "documents", index: "formComponent", screenDef: '' }, auth: { required: true, internal: false }},
  { exact: false, path: "/recalls", name: "Recalls List", Component: RecallList, navMenu: { category: "investigations", index: "recalls", screenDef: 'RecallList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/recall", name: "Recall", Component: Recall, navMenu: { category: "investigations", index: "recalls", screenDef: 'Recall' }, auth: { required: true, internal: false }},
  { exact: true, path: "/recall/:id", name: "Recall", Component: Recall, navMenu: { category: "investigations", index: "recalls", screenDef: 'Recall' }, auth: { required: true, internal: false }},
  { exact: false, path: "/destructions", name: "Destructions List", Component: DestructionList, navMenu: { category: "facility", index: "destructions", screenDef: 'DestructionList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/destruction", name: "Destruction", Component: Destruction , navMenu: { category: "facility", index: "destructions", screenDef: 'Destruction' }, auth: { required: true, internal: false }},
  { exact: true, path: "/destructionadd/:entityId", name: "Destruction", Component: Destruction, navMenu: { category: "formfacilitys", index: "destructions", screenDef: 'Destruction' }, auth: { required: true, internal: false }},
  { exact: true, path: "/destruction/:id", name: "Destruction", Component: Destruction, navMenu: { category: "facility", index: "destructions", screenDef: 'Destruction' }, auth: { required: true, internal: false }},
  { exact: false, path: "/destructionprint/:id", name: "Destruction Print", Component: DestructionPrint, navMenu: { category: "facility", index: "destructions", screenDef: '' }, auth: { required: true, internal: false }},
  { exact: false, path: "/valueTypes", name: "Value Types", Component: ValueTypes, navMenu: { category: "admin", index: "valueTypes", screenDef: 'ValueTypes' }, auth: { required: true, internal: false }},
  { exact: false, path: "/values/:type", name: "Values", Component: Values, navMenu: { category: "admin", index: "valueTypes", screenDef: 'Values' }, auth: { required: true, internal: false }},
  { exact: false, path: "/hazardouslist", name: "Injury Hazardous List", Component: InjuryHazardousList, navMenu: { category: "investigations", index: "hazardouslist", screenDef: 'InjuryHazardousList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/hazardous", name: "Injury Hazardous", Component: InjuryHazardous, navMenu: { category: "investigations", index: "hazardouslist", screenDef: 'InjuryHazardous' }, auth: { required: true, internal: false }},
  { exact: true, path: "/hazardous/:id", name: "Injury Hazardous", Component: InjuryHazardous, navMenu: { category: "investigations", index: "hazardouslist", screenDef: 'InjuryHazardous' }, auth: { required: true, internal: false }},
  { exact: false, path: "/majors", name: "Incident List", Component: IncidentList, navMenu: { category: "investigations", index: "majors", screenDef: 'IncidentList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/major", name: "Incident", Component: Incident, navMenu: { category: "investigations", index: "majors", screenDef: 'Incident' }, auth: { required: true, internal: false }},
  { exact: true, path: "/major/:id", name: "Incident", Component: Incident, navMenu: { category: "investigations", index: "majors", screenDef: 'Incident' }, auth: { required: true, internal: false }},
  { exact: false, path: "/incidentprint/:id", name: "Incident Print", Component: IncidentPrint, navMenu: { category: "investigations", index: "majors", screenDef: '' }, auth: { required: true, internal: false }},
  { exact: false, path: "/illnesses", name: "Employee Illness List", Component: EmployeeIllnessList, navMenu: { category: "investigations", index: "illnesses", screenDef: 'EmployeeIllnessList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/illness", name: "Employee Illness", Component: EmployeeIllness, navMenu: { category: "investigations", index: "illnesses", screenDef: 'EmployeeIllness' }, auth: { required: true, internal: false }},
  { exact: true, path: "/illness/:id", name: "Employee Illness", Component: EmployeeIllness, navMenu: { category: "investigations", index: "illnesses", screenDef: 'EmployeeIllness' }, auth: { required: true, internal: false }},
  { exact: false, path: "/losses", name: "Loss Cannabis List", Component: LossCannabisList, navMenu: { category: "investigations", index: "losses", screenDef: 'LossCannabisList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/loss", name: "Loss Cannabis", Component: LossCannabis, navMenu: { category: "investigations", index: "losses", screenDef: 'LossCannabis' }, auth: { required: true, internal: false }},
  { exact: true, path: "/loss/:id", name: "Loss Cannabis", Component: LossCannabis, navMenu: { category: "investigations", index: "losses", screenDef: 'LossCannabis' }, auth: { required: true, internal: false }},
  { exact: false, path: "/lossprint/:id", name: "Loss Print", Component: LossPrint, navMenu: { category: "investigations", index: "losses", screenDef: '' }, auth: { required: true, internal: false }},
  { exact: false, path: "/pesticidepreps", name: "IPM Solutions List", Component: PesticidePreparationList, navMenu: { category: "cultivation", index: "ipmSolutions", screenDef: 'IPMSolutionList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/pesticideprep", name: "IPM Solution", Component: PesticidePreparation, navMenu: { category: "cultivation", index: "ipmSolutions", screenDef: 'IPMSolution' }, auth: { required: true, internal: false }},
  { exact: true, path: "/pesticideprep/:id", name: "IPM Solution", Component: PesticidePreparation, navMenu: { category: "cultivation", index: "ipmSolutions", screenDef: 'IPMSolution' }, auth: { required: true, internal: false }},
  { exact: false, path: "/visitors", name: "Vistor List", Component: VistorList, navMenu: { category: "security", index: "visitors", screenDef: 'VisitorList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/visitor", name: "Visitor", Component: Visitor, navMenu: { category: "security", index: "visitors", screenDef: 'Visitor' }, auth: { required: true, internal: false }},
  { exact: true, path: "/visitor/:id", name: "Visitor", Component: Visitor, navMenu: { category: "security", index: "visitors", screenDef: 'Visitor' }, auth: { required: true, internal: false }},
  { exact: false, path: "/signins", name: "Sign In List", Component: SigninList, navMenu: { category: "security", index: "signins", screenDef: 'SigninList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/signin", name: "Sign In", Component: Signin, navMenu: { category: "security", index: "signins", screenDef: 'Signin' }, auth: { required: true, internal: false }},
  { exact: true, path: "/signin/:id", name: "Sign In", Component: Signin, navMenu: { category: "security", index: "signins", screenDef: 'Signin' }, auth: { required: true, internal: false }},
  { exact: true, path: "/signinlocation/:location", name: "Sign In Location", Component: Signin, navMenu: { category: "", index: "", screenDef: '' }, auth: { required: true, internal: false }},
  { exact: true, path: "/equipmentlogs/:id", name: "Equipment Logs", Component: EquipmentLogs, navMenu: { category: "facility", index: "equipmentlogs", screenDef: 'EquipmentLog' }, auth: { required: true, internal: false }},
  { exact: true, path: "/equipmentlogs", name: "Equipment Logs", Component: EquipmentLogs, navMenu: { category: "facility", index: "equipmentlogs", screenDef: 'EquipmentLogs' }, auth: { required: true, internal: false }},
  { exact: true, path: "/equipmentlog", name: "Equipment Log", Component: EquipmentLog, navMenu: { category: "facility", index: "equipmentlogs", screenDef: 'EquipmentLog' }, auth: { required: true, internal: false }},
  { exact: true, path: "/equipmentlog/:equipment", name: "Equipment Log", Component: EquipmentLog, navMenu: { category: "facility", index: "equipmentlogs", screenDef: 'EquipmentLog' }, auth: { required: true, internal: false }},
  { exact: true, path: "/equipmentlog/:equipment/:id", name: "Equipment Log", Component: EquipmentLog, navMenu: { category: "facility", index: "equipmentlogs", screenDef: 'EquipmentLog' }, auth: { required: true, internal: false }},
  { exact: true, path: "/equipmentlogedit/:id", name: "Equipment Log", Component: EquipmentLog, navMenu: { category: "facility", index: "equipmentlogs", screenDef: 'EquipmentLog' }, auth: { required: true, internal: false }},
  { exact: false, path: "/exttreats", name: "External Treatment List", Component: ExternalTreatmentList, navMenu: { category: "quality", index: "externaltreatments", screenDef: 'ExternalTreatmentList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/exttreat", name: "External Treatment", Component: ExternalTreatment, navMenu: { category: "quality", index: "externaltreatments", screenDef: 'ExternalTreatment' }, auth: { required: true, internal: false }},
  { exact: true, path: "/exttreat/:id", name: "External Treatment", Component: ExternalTreatment, navMenu: { category: "quality", index: "externaltreatments", screenDef: 'ExternalTreatment' }, auth: { required: true, internal: false }},
  { exact: false, path: "/rooms", name: "Room List", Component: Rooms, navMenu: { category: "facility", index: "rooms", screenDef: 'Rooms' }, auth: { required: true, internal: false }},
  { exact: true, path: "/roomsettings/:roomId", name: "Room Settings", Component: RoomSettings, navMenu: { category: "facility", index: "rooms", screenDef: 'RoomSettings' }, auth: { required: true, internal: false }},
  { exact: true, path: "/roomsettings/:roomId/:id", name: "Room Settings", Component: RoomSettings, navMenu: { category: "facility", index: "rooms", screenDef: 'RoomSettings' }, auth: { required: true, internal: false }},
  { exact: true, path: "/roomsettingsedit/:id", name: "Room Settings", Component: RoomSettings, navMenu: { category: "facility", index: "rooms", screenDef: 'RoomSettings' }, auth: { required: true, internal: false }},
  { exact: false, path: "/locations", name: "Location List", Component: LocationList, navMenu: { category: "facility", index: "locations", screenDef: 'LocationList' }, auth: { required: true, internal: false }},
  { exact: false, path: "/locationdetail/:id", name: "Location Detail", Component: LocationDetail, navMenu: { category: "facility", index: "locations", screenDef: 'LocationDetail' }, auth: { required: true, internal: false }},
  { exact: true, path: "/locationinspection/:locationId", name: "Location Inspection", Component: LocationInspection, navMenu: { category: "facility", index: "locations", screenDef: 'LocationInspection' }, auth: { required: true, internal: false }},
  { exact: true, path: "/locationinspection/:locationId/:id", name: "Location Inspection", Component: LocationInspection, navMenu: { category: "facility", index: "locations", screenDef: 'LocationInspection' }, auth: { required: true, internal: false }},
  { exact: false, path: "/vendors", name: "Vendor List", Component: VendorList, navMenu: { category: "inventory", index: "vendors", screenDef: 'VendorList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/vendor", name: "Vendor", Component: Vendor, navMenu: { category: "inventory", index: "vendors", screenDef: 'Vendor' }, auth: { required: true, internal: false }},
  { exact: true, path: "/vendor/:id", name: "Vendor", Component: Vendor, navMenu: { category: "inventory", index: "vendors", screenDef: 'Vendor' }, auth: { required: true, internal: false }},
  { exact: false, path: "/vendoraudits", name: "Vendor Audits List", Component: VendorAuditList, navMenu: { category: "investigations", index: "vendoraudits", screenDef: 'VendorAuditList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/vendoraudit", name: "Vendor Audit", Component: VendorAudit, navMenu: { category: "investigations", index: "vendoraudits", screenDef: 'VendorAudit' }, auth: { required: true, internal: false }},
  { exact: true, path: "/vendoraudit/:id", name: "Vendor Audit", Component: VendorAudit, navMenu: { category: "investigations", index: "vendoraudits", screenDef: 'VendorAudit' }, auth: { required: true, internal: false }},
  { exact: false, path: "/cleaningsolutions", name: "Cleaning Solutions List", Component: CleaningSolutionList, navMenu: { category: "facility", index: "cleaningsolutions", screenDef: 'CleaningSolutionList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/cleaningsolution", name: "Cleaning Solution", Component: CleaningSolution, navMenu: { category: "facility", index: "cleaningsolutions", screenDef: 'CleaningSolution' }, auth: { required: true, internal: false }},
  { exact: true, path: "/cleaningsolution/:id", name: "Cleaning Solution", Component: CleaningSolution, navMenu: { category: "facility", index: "cleaningsolutions", screenDef: 'CleaningSolution' }, auth: { required: true, internal: false }},
  { exact: false, path: "/saturationtests", name: "Saturation Test List", Component: SaturationTestList, navMenu: { category: "cultivation", index: "saturationtests", screenDef: 'SaturationTestList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/saturationtest", name: "Saturation Test", Component: SaturationTest, navMenu: { category: "cultivation", index: "saturationtests", screenDef: 'SaturationTest' }, auth: { required: true, internal: false }},
  { exact: true, path: "/saturationtest/:id", name: "Saturation Test", Component: SaturationTest, navMenu: { category: "cultivation", index: "saturationtests", screenDef: 'SaturationTest' }, auth: { required: true, internal: false }},
  { exact: false, path: "/feedinglots", name: "Feeding Lot List", Component: FeedingLotList, navMenu: { category: "cultivation", index: "feedinglots", screenDef: 'FeedingLotList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/feedinglot", name: "Feeding Lot", Component: FeedingLot, navMenu: { category: "cultivation", index: "feedinglots", screenDef: 'FeedingLot' }, auth: { required: true, internal: false }},
  { exact: true, path: "/feedinglot/:id", name: "Feeding Lot", Component: FeedingLot, navMenu: { category: "cultivation", index: "feedinglots", screenDef: 'FeedingLot' }, auth: { required: true, internal: false }},
  { exact: false, path: "/practitioners", name: "Practitioner List", Component: PractitionerList, navMenu: { category: "crm", index: "practitioners", screenDef: 'PractitionerList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/practitioner", name: "Practitioner", Component: Practitioner, navMenu: { category: "crm", index: "practitioners", screenDef: 'Practitioner' }, auth: { required: true, internal: false }},
  { exact: true, path: "/practitioner/:id", name: "Practitioner", Component: Practitioner, navMenu: { category: "crm", index: "practitioners", screenDef: 'Practitioner' }, auth: { required: true, internal: false }},
  { exact: false, path: "/customers", name: "Customer List", Component: CustomerList, navMenu: { category: "crm", index: "customers", screenDef: 'CustomerList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/customer", name: "Customer", Component: Customer, navMenu: { category: "crm", index: "customers", screenDef: 'Customer' }, auth: { required: true, internal: false }},
  { exact: true, path: "/customer/:id", name: "Customer", Component: Customer, navMenu: { category: "crm", index: "customers", screenDef: 'Customer' }, auth: { required: true, internal: false }},
  { exact: false, path: "/loglist", name: "Log List", Component: LogList, navMenu: { category: "admin", index: "loglist", screenDef: 'LogList' }, auth: { required: true, internal: false }},
  { exact: false, path: "/scheduledActivities", name: "Scheduled Activities", Component: ScheduledActivities, navMenu: { category: "production", index: "scheduledActivities", screenDef: 'ScheduledActivities' }, auth: { required: true, internal: false }},
  { exact: true, path: "/scheduledActivity", name: "Scheduled Activity", Component: ScheduledActivity, navMenu: { category: "production", index: "scheduledActivities", screenDef: 'ScheduledActivity' }, auth: { required: true, internal: false }},
  { exact: true, path: "/scheduledActivity/:id", name: "Scheduled Activity", Component: ScheduledActivity, navMenu: { category: "production", index: "scheduledActivities", screenDef: 'ScheduledActivity' }, auth: { required: true, internal: false }},
  { exact: false, path: "/roomchecklist", name: "Room Checklist", Component: RoomCheckList, navMenu: { category: "facility", index: "roomchecklist", screenDef: 'RoomCheckList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/roomcheck", name: "Room Check", Component: RoomCheck, navMenu: { category: "facility", index: "roomchecklist", screenDef: 'RoomCheck' }, auth: { required: true, internal: false }},
  { exact: true, path: "/roomcheck/:id", name: "Room Check", Component: RoomCheck, navMenu: { category: "facility", index: "roomchecklist", screenDef: 'RoomCheck' }, auth: { required: true, internal: false }},
  { exact: false, path: "/itemcategories", name: "Item Categories", Component: ItemCategories, navMenu: { category: "inventory", index: "itemcategories", screenDef: 'ItemCategories' }, auth: { required: true, internal: false }},
  { exact: false, path: "/itemlist", name: "Items List", Component: ItemList, navMenu: { category: "inventory", index: "itemlist", screenDef: 'ItemList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/item", name: "Item", Component: Item, navMenu: { category: "inventory", index: "itemlist", screenDef: 'Item' }, auth: { required: true, internal: false }},
  { exact: true, path: "/item/:id", name: "Item", Component: Item, navMenu: { category: "inventory", index: "itemlist", screenDef: 'Item' }, auth: { required: true, internal: false }},
  { exact: false, path: "/strains", name: "Strains", Component: Strains, navMenu: { category: "cultivation", index: "strains", screenDef: 'Strains' }, auth: { required: true, internal: false }},
  { exact: true, path: "/strain", name: 'Strain', Component: Strain, navMenu: { category: "cultivation", index: "strains", screenDef: 'Strain' }, auth: { required: true, internal: false }},
  { exact: true, path: "/strain/:id", name: "Strain", Component: Strain, navMenu: { category: "cultivation", index: "strains", screenDef: 'Strain' }, auth: { required: true, internal: false }},
  { exact: false, path: "/croptreatments", name: "IPM Treatment List", Component: CropTreatmentList, navMenu: { category: "cultivation", index: "ipmTreatments", screenDef: 'IPMTreatmentList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/croptreatment", name: "IPM Treatment", Component: CropTreatment, navMenu: { category: "cultivation", index: "ipmTreatments", screenDef: 'IPMTreatment' }, auth: { required: true, internal: false }},
  { exact: true, path: "/croptreatment/:id", name: "IPM Treatment", Component: CropTreatment, navMenu: { category: "cultivation", index: "ipmTreatments", screenDef: 'IPMTreatment' }, auth: { required: true, internal: false }},
  { exact: false, path: "/croptreatmentprint/:id", name: "IPM Treatment Print", Component: CropTreatmentPrint, navMenu: { category: "cultivation", index: "ipmTreatments", screenDef: 'IPMTreatment' }, auth: { required: true, internal: false }},
  { exact: false, path: "/testItemCategories", name: "Test Item Categories", Component: TestItemCategories, navMenu: { category: "tests", index: "testItemCategories", screenDef: 'TestItemCategories' }, auth: { required: true, internal: false }},
  { exact: false, path: "/testItems", name: "Test Items List", Component: TestItemList, navMenu: { category: "tests", index: "testItems", screenDef: 'TestItemList' }, auth: { required: true, internal: false }},
  { exact: false, path: "/testdefinitions", name: "Test Definition List", Component: TestDefinitionList, navMenu: { category: "tests", index: "testdefinitions", screenDef: 'TestDefinitionList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/testdefinition", name: "Test Definition", Component: TestDefinition, navMenu: { category: "tests", index: "testdefinitions", screenDef: 'TestDefinition' }, auth: { required: true, internal: false }},
  { exact: true, path: "/testdefinition/:id", name: "Test Definition", Component: TestDefinition, navMenu: { category: "tests", index: "testdefinitions", screenDef: 'TestDefinition' }, auth: { required: true, internal: false }},
  { exact: false, path: "/testresultlist", name: "Test Result List", Component: TestResultList, navMenu: { category: "tests", index: "testresultlist", screenDef: 'TestResultList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/testresult", name: "Test Results", Component: TestResults, navMenu: { category: "tests", index: "testresultlist", screenDef: 'TestResults' }, auth: { required: true, internal: false }},
  { exact: true, path: "/testresult/:id", name: "Test Results", Component: TestResults, navMenu: { category: "tests", index: "testresultlist", screenDef: 'TestResults' }, auth: { required: true, internal: false }},
  { exact: false, path: "/lots", name: "Lots List", Component: LotList, navMenu: { category: "inventory", index: "lots", screenDef: 'LotList' }, auth: { required: true, internal: false }},
  { exact: false, path: "/lot/:id", name: "Lot", Component: Lot, navMenu: { category: "inventory", index: "lots", screenDef: 'Lot' }, auth: { required: true, internal: false }},
  { exact: false, path: "/rooms", name: "Data Rooms", Component: Rooms, navMenu: { category: "facility", index: "rooms", screenDef: 'Rooms' }, auth: { required: true, internal: false }},
  { exact: true, path: "/room", name: "Data Room", Component: Room, navMenu: { category: "facility", index: "rooms", screenDef: 'Room' }, auth: { required: true, internal: false }},
  { exact: true, path: "/room/:id", name: "Data Room", Component: Room, navMenu: { category: "facility", index: "rooms", screenDef: 'Room' }, auth: { required: true, internal: false }},
  { exact: false, path: "/equipmentlist", name: "Data Equipment List", Component: EquipmentList, navMenu: { category: "facility", index: "equipmentlist", screenDef: 'EquipmentList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/equipment", name: "Data Equipment", Component: Equipment, navMenu: { category: "facility", index: "equipmentlist", screenDef: 'Equipment' }, auth: { required: true, internal: false }},
  { exact: true, path: "/equipment/:id", name: "Data Equipment", Component: Equipment, navMenu: { category: "facility", index: "equipmentlist", screenDef: 'Equipment' }, auth: { required: true, internal: false }},
  { exact: false, path: "/routings", name: "Routings", Component: Routings, navMenu: { category: "processing", index: "routings", screenDef: 'Routings' }, auth: { required: true, internal: false }},
  { exact: true, path: "/routing/:id", name: "Routing", Component: Routing, navMenu: { category: "processing", index: "routings", screenDef: 'Routing' }, auth: { required: true, internal: false }},
  { exact: true, path: "/routing", name: "Routing", Component: Routing, navMenu: { category: "processing", index: "routings", screenDef: 'Routing' }, auth: { required: true, internal: false }},
  { exact: false, path: "/boms", name: "Production BOMs", Component: ProductionBoms, navMenu: { category: "processing", index: "boms", screenDef: 'ProductionBoms' }, auth: { required: true, internal: false }},
  { exact: true, path: "/bom/:id", name: "Production BOM", Component: ProductionBom, navMenu: { category: "processing", index: "boms", screenDef: 'ProductionBom' }, auth: { required: true, internal: false }},
  { exact: true, path: "/bom", name: "Production BOM", Component: ProductionBom, navMenu: { category: "processing", index: "boms", screenDef: 'ProductionBom' }, auth: { required: true, internal: false }},
  { exact: false, path: "/workrecords", name: "Work Records", Component: WorkRecords, navMenu: { category: "documents", index: "workrecords", screenDef: 'WorkRecords' }, auth: { required: true, internal: false }},
  { exact: false, path: "/messages", name: "Message List", Component: MessageList, navMenu: { category: "", index: "", screenDef: 'MessageList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/message", name: "Message", Component: Message, navMenu: { category: "", index: "", screenDef: 'Message' }, auth: { required: true, internal: false }},
  { exact: true, path: "/message/:id", name: "Message", Component: Message, navMenu: { category: "", index: "", screenDef: 'Message' }, auth: { required: true, internal: false }},
  { exact: false, path: "/taskrisks", name: "Task Risk Assessment List", Component: TaskRiskAssessmentList, navMenu: { category: "facility", index: "taskrisks", screenDef: 'TaskRiskAssessmentList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/taskrisk", name: "Task Risk Assessment", Component: TaskRiskAssessment, navMenu: { category: "facility", index: "taskrisks", screenDef: 'TaskRiskAssessment' }, auth: { required: true, internal: false }},
  { exact: true, path: "/taskrisk/:id", name: "Task Risk Assessment", Component: TaskRiskAssessment, navMenu: { category: "facility", index: "taskrisks", screenDef: 'TaskRiskAssessment' }, auth: { required: true, internal: false }},
  { exact: false, path: "/maintenancerequests", name: "Maintenance Request List", Component: MaintenanceRequestList, navMenu: { category: "facility", index: "maintenancerequests", screenDef: 'MaintenanceRequestList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/maintenancerequest", name: "Maintenance Request", Component: MaintenanceRequest, navMenu: { category: "facility", index: "maintenancerequests", screenDef: 'MaintenanceRequest' }, auth: { required: true, internal: false }},
  { exact: true, path: "/maintenancerequest/:id", name: "Maintenance Request", Component: MaintenanceRequest, navMenu: { category: "facility", index: "maintenancerequests", screenDef: 'MaintenanceRequest' }, auth: { required: true, internal: false }},
  { exact: false, path: "/userprofile", name: "User Profile", Component: UserProfile, navMenu: { category: "", index: "", screenDef: '' }, auth: { required: true, internal: false }},
  { exact: false, path: "/tenants", name: "", Component: TenantList, navMenu: { category: "config", index: "tenants", screenDef: 'TenantList' }, auth: { required: true, internal: true }},
  { exact: true, path: "/tenant", name: "Tenant", Component: Tenant, navMenu: { category: "config", index: "tenants", screenDef: 'Tenant' }, auth: { required: true, internal: true }},
  { exact: true, path: "/tenant/:id", name: "Tenant", Component: Tenant, navMenu: { category: "config", index: "tenants", screenDef: 'Tenant' }, auth: { required: true, internal: true }},
  { exact: false, path: "/hcreports", name: "Health Canada Reports", Component: HCReportList, navMenu: { category: "reporting", index: "hcreports", screenDef: 'HCReportList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/hcreport", name: "Health Canada Report", Component: HCReport, navMenu: { category: "reporting", index: "hcreports", screenDef: 'HCReport' }, auth: { required: true, internal: false }},
  { exact: true, path: "/hcreport/:id", name: "Health Canada Report", Component: HCReport, navMenu: { category: "reporting", index: "hcreports", screenDef: 'HCReport' }, auth: { required: true, internal: false }},
  { exact: false, path: "/qadashboard", name: "QA Dashboard", Component: QADashboard, navMenu: { category: "dashboard", index: "qadashboard", screenDef: 'QADashboard' }, auth: { required: true, internal: false }},
  { exact: false, path: "/cultivationdashboard", name: "Cultivation Dashboard", Component: CultivationDashboard, navMenu: { category: "dashboard", index: "cultivationdashboard", screenDef: 'CultivationDashboard' }, auth: { required: true, internal: false }},
  { exact: false, path: "/manageddocs", name: "Document List", Component: ManagedDocumentList, navMenu: { category: "documents", index: "manageddocs", screenDef: 'ManagedDocumentList' }, auth: { required: true, internal: false }},
  { exact: true, path: "/manageddoc", name: "Document", Component: ManagedDocument, navMenu: { category: "documents", index: "manageddocs", screenDef: 'ManagedDocument' }, auth: { required: true, internal: false }},
  { exact: true, path: '/manageddoc/:id', name: "Document", Component: ManagedDocument, navMenu: { category: "documents", index: "manageddocs", screenDef: 'ManagedDocument' }, auth: { required: true, internal: false }},
  { exact: false, path: '/manageddocreviews', name: 'Document Reviews', Component: DocumentReviewList, navMenu: { category: "documents", index: "manageddocreviews", screenDef: 'DocumentReviewList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/manageddocreview', name: 'Document Review', Component: DocumentReview, navMenu: { category: "documents", index: "manageddocreviews", screenDef: 'DocumentReview' }, auth: { required: true, internal: false }},
  { exact: true, path: '/manageddocreview/:id', name: 'Document Review', Component: DocumentReview, navMenu: { category: "documents", index: "manageddocreviews", screenDef: 'DocumentReview' }, auth: { required: true, internal: false }},
  { exact: false, path: '/productionplans', name: 'Production Plans', Component: ProductionPlanList, navMenu: { category: "production", index: "productionplans", screenDef: 'ProductionPlanList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/productionplan', name: 'Production Plan', Component: ProductionPlan, navMenu: { category: "production", index: "productionplans", screenDef: 'ProductionPlan' }, auth: { required: true, internal: false }},
  { exact: true, path: '/productionplan/:id', name: 'Production Plan', Component: ProductionPlan, navMenu: { category: "production", index: "productionplans", screenDef: 'ProductionPlan' }, auth: { required: true, internal: false }},
  { exact: false, path: '/productionschedules', name: 'Production Plans', Component: ProductionScheduleList, navMenu: { category: "production", index: "productionschedules", screenDef: 'ProductionScheduleList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/productionschedule/:id', name: 'Production Plan', Component: ProductionSchedule, navMenu: { category: "production", index: "productionschedules", screenDef: 'ProductionSchedule' }, auth: { required: true, internal: false }},
  { exact: false, path: '/changerequests', name: 'Change Requests', Component: ChangeRequestList, navMenu: { category: "documents", index: "changerequests", screenDef: 'ChangeRequestList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/changerequest', name: 'Change Request', Component: ChangeRequest, navMenu: { category: "documents", index: "changerequests", screenDef: 'ChangeRequest' }, auth: { required: true, internal: false }},
  { exact: true, path: '/changerequest/:id', name: 'Change Request', Component: ChangeRequest, navMenu: { category: "documents", index: "changerequests", screenDef: 'ChangeRequest' }, auth: { required: true, internal: false }},
  { exact: false, path: '/screendefinitions', name: 'Screen Definitions', Component: ScreenDefinitions, navMenu: { category: "config", index: "screendefinitions", screenDef: 'ScreenDefinitions' }, auth: { required: true, internal: true }},
  { exact: false, path: '/screens', name: 'Screens', Component: Screens, navMenu: { category: "admin", index: "screens", screenDef: 'Screens' }, auth: { required: true, internal: false }},
  { exact: true, path: '/screen', name: 'Screen', Component: Screen, navMenu: { category: "admin", index: "screens", screenDef: 'Screen' }, auth: { required: true, internal: true }},
  { exact: true, path: '/screen/:id', name: 'Screen', Component: Screen, navMenu: { category: "admin", index: "screens", screenDef: 'Screen' }, auth: { required: true, internal: false }},
  { exact: true, path: '/location', name: 'Location', Component: Location, navMenu: { category: "facility", index: "locations", screenDef: 'LocationDetail' }, auth: { required: true, internal: false }},
  { exact: true, path: '/location/:id', name: 'Location', Component: Location, navMenu: { category: "facility", index: "locations", screenDef: 'LocationDetail' }, auth: { required: true, internal: false }},
  { exact: true, path: '/processingdashboard', name: 'Processing Dashboard', Component: ProcessingDashboard, navMenu: { category: "dashboard", index: 'processingdashboard', screenDef: 'ProcessingDashboard'}, auth: { required: true, internal: false }},
  { exact: false, path: '/batchtemplates', name: 'Batch Templates', Component: BatchTemplates, navMenu: { category: "cultivation", index: "batchtemplates", screenDef: 'BatchTemplates' }, auth: { required: true, internal: false }},
  { exact: true, path: '/batchtemplate', name: 'Batch Template', Component: BatchTemplate, navMenu: { category: "cultivation", index: "batchtemplates", screenDef: 'BatchTemplate' }, auth: { required: true, internal: false }},
  { exact: true, path: '/batchtemplate/:id', name: 'Batch Template', Component: BatchTemplate, navMenu: { category: "cultivation", index: "batchtemplates", screenDef: 'BatchTemplate' }, auth: { required: true, internal: false }},
  { exact: false, path: '/batchactivitydefinitions', name: 'Batch Activity Definitions', Component: BatchActivityDefinitions, navMenu: { category: "cultivation", index: "batchactivitydefinitions", screenDef: 'BatchActivityDefinitions' }, auth: { required: true, internal: false }},
  { exact: false, path: '/cultivationbatches', name: 'Batches', Component: CultivationBatchList, navMenu: { category: "cultivation", index: "cultivationbatch", screenDef: 'CultivationBatchList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/cultivationbatch/:id', name: 'Batch', Component: CultivationBatch, navMenu: { category: "cultivation", index: "cultivationbatch", screenDef: 'CultivationBatch' }, auth: { required: true, internal: false }},
  { exact: true, path: '/evorabatch/:id', name: 'Batch', Component: CultivationBatch, navMenu: { category: "cultivation", index: "cultivationbatch", screenDef: 'CultivationBatch' }, auth: { required: true, internal: false }}, //Duplicate route for EvoraBatch to allow for backwards compatibility
  { exact: true, path: '/evorabatchprint/:id', name: 'Batch', Component: EvoraBatchPrint, navMenu: { category: "cultivation", index: "cultivationbatch", screenDef: 'EvoraBatch' }, auth: { required: true, internal: false }},
  { exact: true, path: '/batchactivity', name: 'Batch Activity', Component: BatchActivity, navMenu: { category: "cultivation", index: "cultivationbatch", screenDef: 'BatchActivity' }, auth: { required: true, internal: false }},
  { exact: true, path: '/batchactivity/:id', name: 'Batch Activity', Component: BatchActivity, navMenu: { category: "cultivation", index: "cultivationbatch", screenDef: 'BatchActivity' }, auth: { required: true, internal: false }},
  { exact: false, path: '/totes', name: 'Totes', Component: ToteList, navMenu: { category: "facility", index: "totes", screenDef: 'ToteList' }, auth: { required: true, internal: false }},
  { exact: false, path: '/bins', name: 'Bins', Component: BinList, navMenu: { category: "facility", index: "bins", screenDef: 'BinList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/bin', name: 'Bin', Component: Bin, navMenu: { category: "facility", index: "bins", screenDef: 'Bin' }, auth: { required: true, internal: false }},
  { exact: true, path: '/bin/:id', name: 'Bin', Component: Bin, navMenu: { category: "facility", index: "bins", screenDef: 'Bin' }, auth: { required: true, internal: false }},
  { exact: false, path: '/containers', name: 'Containers', Component: ContainerList, navMenu: { category: "inventory", index: "containers", screenDef: 'ContainerList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/container', name: 'Container', Component: Container, navMenu: { category: "inventory", index: "containers", screenDef: 'Container' }, auth: { required: true, internal: false }},
  { exact: true, path: '/container/:id', name: 'Container', Component: Container, navMenu: { category: "inventory", index: "containers", screenDef: 'Container' }, auth: { required: true, internal: false }},
  { exact: false, path: '/eproductionorders', name: 'Production Orders', Component: EProductionOrderList, navMenu: { category: "processing", index: "productionorders", screenDef: 'ProductionOrderList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/eproductionorder', name: 'Production Order', Component: EProductionOrder, navMenu: { category: "processing", index: "productionorders", screenDef: 'ProductionOrder' }, auth: { required: true, internal: false }},
  { exact: true, path: '/eproductionorder/:id', name: 'Production Order', Component: EProductionOrder, navMenu: { category: "processing", index: "productionorders", screenDef: 'ProductionOrder' }, auth: { required: true, internal: false }},
  { exact: true, path: '/poactivity/:id', name: 'Production Order Activity', Component: ProductionOrderActivity, navMenu: { category: "processing", index: "productionorders", screenDef: 'ProductionOrderActivity' }, auth: { required: true, internal: false }},
  { exact: false, path: '/curings', name: 'Curings', Component: CuringList, navMenu: { category: "processing", index: "curings", screenDef: 'CuringList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/curing', name: 'Curing', Component: Curing, navMenu: { category: "processing", index: "curings", screenDef: 'Curing' }, auth: { required: true, internal: false }},
  { exact: true, path: '/curing/:id', name: 'Curing', Component: Curing, navMenu: { category: "processing", index: "curings", screenDef: 'Curing' }, auth: { required: true, internal: false }},
  { exact: false, path: '/itemledgers', name: 'Item Ledgers', Component: ItemLedgers, navMenu: { category: "inventory", index: "itemledgers", screenDef: 'ItemLedgers' }, auth: { required: true, internal: false }},
  { exact: true, path: '/itemledger', name: 'Item Ledger', Component: ItemLedger, navMenu: { category: "inventory", index: "itemledgers", screenDef: 'ItemLedger' }, auth: { required: true, internal: false }},
  { exact: true, path: '/itemledger/:id', name: 'Item Ledger', Component: ItemLedger, navMenu: { category: "inventory", index: "itemledgers", screenDef: 'ItemLedger' }, auth: { required: true, internal: false }},
  { exact: false, path: '/sales', name: 'Sales', Component: SaleList, navMenu: { category: "crm", index: "sales", screenDef: 'SalesList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/sale', name: 'Sale', Component: Sale, navMenu: { category: "crm", index: "sales", screenDef: 'Sales' }, auth: { required: true, internal: false }},
  { exact: true, path: '/sale/:id', name: 'Sale', Component: Sale, navMenu: { category: "crm", index: "sales", screenDef: 'Sales' }, auth: { required: true, internal: false }},
  { exact: false, path: '/workcenters', name: 'Work Centers', Component: WorkCenterList, navMenu: { category: "facility", index: "workcenters", screenDef: 'WorkCenterList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/workcenter', name: 'Work Center', Component: WorkCenter, navMenu: { category: "facility", index: "workcenters", screenDef: 'WorkCenter' }, auth: { required: true, internal: false }},
  { exact: true, path: '/workcenter/:id', name: 'Work Center', Component: WorkCenter, navMenu: { category: "facility", index: "workcenters", screenDef: 'WorkCenter' }, auth: { required: true, internal: false }},
  { exact: false, path: '/shipments', name: 'Shipments', Component: ShipmentList, navMenu: { category: "inventory", index: "shipments", screenDef: 'ShipmentList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/shipment', name: 'Shipment', Component: Shipment, navMenu: { category: "inventory", index: "shipments", screenDef: 'Shipment' }, auth: { required: true, internal: false }},
  { exact: true, path: '/shipment/:id', name: 'Shipment', Component: Shipment, navMenu: { category: "inventory", index: "shipments", screenDef: 'Shipment' }, auth: { required: true, internal: false }},
  { exact: false, path: '/receipts', name: 'Receipts', Component: ReceiptList, navMenu: { category: "inventory", index: "receipts", screenDef: 'ReceiptList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/receipt', name: 'Receipt', Component: Receipt, navMenu: { category: "inventory", index: "receipts", screenDef: 'Receipt' }, auth: { required: true, internal: false }},
  { exact: true, path: '/receipt/:id', name: 'Receipt', Component: Receipt, navMenu: { category: "inventory", index: "receipts", screenDef: 'Receipt' }, auth: { required: true, internal: false }},
  { exact: false, path: '/settingprefix', name: 'Setting Prefix', Component: SettingPrefix, navMenu: { category: "admin", index: "settingprefix", screenDef: 'SettingPrefix' }, auth: { required: true, internal: false }},
  { exact: false, path: '/dryingorders', name: 'Drying Orders', Component: DryingOrderList, navMenu: { category: 'processing', index: 'drying', screenDef: 'DryingOrderList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/dryingorder', name: 'Drying Order', Component: DryingOrder, navMenu: { category: "processing", index: "drying", screenDef: 'DryingOrder' }, auth: { required: true, internal: false }},
  { exact: true, path: '/dryingorder/:id', name: 'Drying Order', Component: DryingOrder, navMenu: { category: "processing", index: "drying", screenDef: 'DryingOrder' }, auth: { required: true, internal: false }},
  { exact: true, path: '/dryingactivity', name: 'Drying Activity', Component: DryingActivity, navMenu: {category: "processing", index: "drying", screenDef: 'DryingActivity' }, auth: { required: true, internal: false }},
  { exact: true, path: '/dryingactivity/:id', name: 'Drying Activity', Component: DryingActivity, navMenu: {category: "processing", index: "drying", screenDef: 'DryingActivity' }, auth: { required: true, internal: false }},
  { exact: false, path: '/trimmingorders', name: 'Trimming Orders', Component: TrimmingOrderList, navMenu: { category: 'processing', index: 'trimmings', screenDef: 'TrimmingOrderList'}, auth: { required: true, internal: false }},
  { exact: true, path: '/trimmingorder', name: 'Trimming Order', Component: TrimmingOrder, navMenu: { category: "processing", index: "trimmings", screenDef: 'TrimmingOrder' }, auth: { required: true, internal: false }},
  { exact: true, path: '/trimmingorder/:id', name: 'Trimming Order', Component: TrimmingOrder, navMenu: { category: "processing", index: "trimmings", screenDef: 'TrimmingOrder' }, auth: { required: true, internal: false }},
  { exact: true, path: '/trimmingactivity', name: 'Trimming Activity', Component: TrimmingActivity, navMenu: {category: "processing", index: "trimmings", screenDef: 'TrimmingActivity' }, auth: { required: true, internal: false }},
  { exact: true, path: '/trimmingactivity/:id', name: 'Trimming Activity', Component: TrimmingActivity, navMenu: {category: "processing", index: "trimmings", screenDef: 'TrimmingActivity' }, auth: { required: true, internal: false }},
  { exact: false, path: '/tenantusers', name: 'Users', Component: TenantUsers, navMenu: { category: "admin", index: "tenantUsers", screenDef: 'TenantUsers' }, auth: { required: true, internal: false }},
  { exact: true, path: '/tenantuser', name: 'User', Component: TenantUser, navMenu: { category: "admin", index: "tenantUsers", screenDef: 'TenantUser' }, auth: { required: true, internal: false }},
  { exact: true, path: '/tenantuser/:id', name: 'User', Component: TenantUser, navMenu: { category: "admin", index: "tenantUsers", screenDef: 'TenantUser' }, auth: { required: true, internal: false }},
  { exact: true, path: '/curingactivity', name: 'Curing Activity', Component: CuringActivity, navMenu: {category: "processing", index: "curings", screenDef: 'CuringActivity' }, auth: { required: true, internal: false }},
  { exact: true, path: '/curingactivity/:id', name: 'Curing Activity', Component: CuringActivity, navMenu: {category: "processing", index: "curings", screenDef: 'CuringActivity' }, auth: { required: true, internal: false }},
  { exact: false, path: '/objects', name: 'Objects', Component: ObjectList, navMenu: { category: "config", index: "objects", screenDef: 'Objects' }, auth: { required: true, internal: true }},
  { exact: false, path: '/object/:id', name: 'Object', Component: ObjectScreen, navMenu: { category: "config", index: "objects", screenDef: 'ObjectScreen' }, auth: { required: true, internal: true }},
  { exact: false, path: '/emailtemplates', name: 'Email Templates', Component: EmailTemplateList, navMenu: { category: "config", index: "emailtemplates", screenDef: 'EmailTemplateList' }, auth: { required: true, internal: true }},
  { exact: true, path: '/emailtemplate', name: 'Email Template', Component: EmailTemplate, navMenu: { category: "config", index: "emailtemplates", screenDef: 'EmailTemplate' }, auth: { required: true, internal: true }},
  { exact: true, path: '/emailtemplate/:id', name: 'Email Template', Component: EmailTemplate, navMenu: { category: "config", index: "emailtemplates", screenDef: 'EmailTemplate' }, auth: { required: true, internal: true }},
  { exact: false, path: '/emailssent', name: 'Sent Emails', Component: EmailSentList, navMenu: { category: "config", index: "emailssent", screenDef: 'EmailSentList' }, auth: { required: true, internal: true }},
  { exact: true, path: '/emailsent/:id', name: 'Sent Email', Component: EmailSent, navMenu: { category: "config", index: "emailssent", screenDef: 'EmailSent' }, auth: { required: true, internal: true }},
  { exact: false, path: '/roomcleanings', name: 'Room Cleanings', Component: RoomCleaningList, navMenu: { category: "facility", index: "roomcleanings", screenDef: 'RoomCleaningList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/roomcleaning', name: 'Room Cleaning', Component: RoomCleaning, navMenu: { category: "facility", index: "roomcleanings", screenDef: 'RoomCleaning' }, auth: { required: true, internal: false }},
  { exact: true, path: '/roomcleaning/:id', name: 'Room Cleaning', Component: RoomCleaning, navMenu: { category: "facility", index: "roomcleanings", screenDef: 'RoomCleaning' }, auth: { required: true, internal: false }},
  { exact: false, path: '/resetpassword', name: 'Reset Password', Component: ResetPassword, auth: { required: false, internal: false }},
  { exact: false, path: '/headersfooterslist', name: 'Headers and Footers List', Component: HeadersFootersList, navMenu: { category: "admin", index: "headersfooters", screenDef: 'HeadersFootersList' }, auth: {required: true, internal: false}},
  { exact: true, path: '/headersfooters/:id', name: 'Headers and Footers', Component: HeadersAndFooters, navMenu: { category: "admin", index: "headersfooters", screenDef: 'HeadersFooters' }, auth: { required: true, internal: false }},
  { exact: true, path: '/headersfooters', name: 'Headers and Footers', Component: HeadersAndFooters, navMenu: { category: "admin", index: "headersfooters", screenDef: 'HeadersFooters' }, auth: { required: true, internal: false }},
  { exact: false, path: '/binsummary', name: 'Bin Summary', Component: BinSummary, navMenu: { category: "inventory", index: "binSummary", screenDef: 'BinSummary' }, auth: { required: true, internal: false }},
  { exact: false, path: '/roomsummary', name: 'Room Summary', Component: RoomSummary, navMenu: { category: "inventory", index: "roomSummary", screenDef: 'RoomSummary' }, auth: { required: true, internal: false }},
  { exact: false, path: '/tenantreports', name: 'Report Settings List', Component: TenantReportList, navMenu: { category: "admin", index: "tenantReports", screenDef: 'TenantReportList' }, auth: {required: true, internal: false}},
  { exact: true, path: '/tenantreport', name: 'Report Settings', Component: TenantReport, navMenu: { category: "admin", index: "tenantReports", screenDef: 'TenantReport' }, auth: { required: true, internal: false }},
  { exact: true, path: '/tenantreport/:id', name: 'Report Settings', Component: TenantReport, navMenu: { category: "admin", index: "tenantReports", screenDef: 'TenantReport' }, auth: { required: true, internal: false }},
  { exact: false, path: '/management', name: 'Management', Component: Management, navMenu: { category: "config", index: "management", screenDef: 'Management' }, auth: {required: true, internal: true}},
  { exact: false, path: '/purchaseorders', name: 'Purchase Orders', Component: PurchaseOrderList, navMenu: { category: "inventory", index: "purchaseOrders", screenDef: 'PurchaseOrderList' }, auth: { required: true, internal: false }},
  { exact: true, path: '/purchaseorder', name: 'Purchase Order', Component: PurchaseOrder, navMenu: { category: "inventory", index: "purchaseOrders", screenDef: 'PurchaseOrder' }, auth: { required: true, internal: false }},
  { exact: true, path: '/purchaseorder/:id', name: 'Purchase Order', Component: PurchaseOrder, navMenu: { category: "inventory", index: "purchaseOrders", screenDef: 'PurchaseOrder' }, auth: { required: true, internal: false }},
  { exact: false, path: '/countries', name: 'Countries', Component: Countries, navMenu: { category: "config", index: "countries", screenDef: 'Countries' }, auth: { required: true, internal: false }},
  { exact: false, path: '/provinces', name: 'Provinces', Component: Provinces, navMenu: { category: "config", index: "provinces", screenDef: 'Provinces' }, auth: { required: true, internal: false }},
  { exact: false, path: '/destructionreport', name: 'Destruction Report', Component: DestructionReport, navMenu: { category: "inventory", index: "destructionReport", screenDef: 'DestructionReport' }, auth: { required: true, internal: false }},
  { exact: false, path: '/productionbatches', name: 'Production Batches', Component: ProductionBatches, navMenu: { category: "processing", index: "productionBatches", screenDef: 'ProductionBatches' }, auth: { required: true, internal: false }},
  { exact: true, path: '/productionbatch', name: 'Production Batch', Component: ProductionBatch, navMenu: { category: "processing", index: "productionBatches", screenDef: 'ProductionBatch' }, auth: { required: true, internal: false }},
  { exact: true, path: '/productionbatch/:id', name: 'Production Batch', Component: ProductionBatch, navMenu: { category: "processing", index: "productionBatches", screenDef: 'ProductionBatch' }, auth: { required: true, internal: false }},
  { exact: true, path: '/productionbatchprint/:id', name: 'Production Batch Print', Component: ProductionBatchPrint, navMenu: { category: "processing", index: "productionBatches", screenDef: '' }, auth: { required: true, internal: false }},
  { exact: false, path: '/manageusers', name: 'Manage Users', Component: ManageUsers, navMenu: { category: "hrmanagement", index: "manageusers", screenDef: 'ManageUsers' }, auth: { required: true, internal: false }},
  { exact: true, path: '/manageuser', name: 'Manage User', Component: ManageUser, navMenu: { category: "hrmanagement", index: "manageusers", screenDef: 'ManageUser' }, auth: { required: true, internal: false }},
  { exact: true, path: '/manageuser/:id', name: 'Manage User', Component: ManageUser, navMenu: { category: "hrmanagement", index: "manageusers", screenDef: 'ManageUser' }, auth: { required: true, internal: false }},
  { exact: false, path: '/customercomplaints', name: 'Customer Complaints', Component: CustomerComplaints, navMenu: { category: "crm", index: "customerComplaints", screenDef: 'CustomerComplaints' }, auth: { required: true, internal: false }},
  { exact: true, path: '/customercomplaint', name: 'Customer Complaint', Component: CustomerComplaint, navMenu: { category: "crm", index: "customerComplaints", screenDef: 'CustomerComplaint' }, auth: { required: true, internal: false }},
  { exact: true, path: '/customercomplaint/:id', name: 'Customer Complaint', Component: CustomerComplaint, navMenu: { category: "crm", index: "customerComplaints", screenDef: 'CustomerComplaint' }, auth: { required: true, internal: false }},
  { exact: false, path: '/manageddocumenttemplates', name: 'Document Templates', Component: ManagedDocumentTemplates, navMenu: { category: "documents", index: "managedDocumentTemplates", screenDef: 'ManagedDocumentTemplates' }, auth: { required: true, internal: false }},
  { exact: true, path: '/manageddocumenttemplate', name: 'Document Template', Component: ManagedDocumentTemplate, navMenu: { category: "documents", index: "manageDocumentTemplates", screenDef: 'ManagedDocumentTemplate' }, auth: { required: true, internal: false }},
  { exact: true, path: '/manageddocumenttemplate/:id', name: 'Document Template', Component: ManagedDocumentTemplate, navMenu: { category: "documents", index: "managedDocumentTemplates", screenDef: 'ManagedDocumentTemplate' }, auth: { required: true, internal: false }},
  { exact: false, path: '/cars', name: 'Corrective Action Requests', Component: Cars, navMenu: { category: "investigations", index: "correctiveActionRequests", screenDef: 'CorrectiveActionRequests' }, auth: { required: true, internal: false }},
  { exact: true, path: '/car', name: 'Corrective Action Request', Component: Car, navMenu: { category: "investigations", index: "correctiveActionRequests", screenDef: 'CorrectiveActionRequest' }, auth: { required: true, internal: false }},
  { exact: true, path: '/car/:id', name: 'Corrective Action Request', Component: Car, navMenu: { category: "investigations", index: "correctiveActionRequests", screenDef: 'CorrectiveActionRequest' }, auth: { required: true, internal: false }},
  { exact: false, path: '/apilogs', name: 'API Logs', Component: ApiLogs, navMenu: { category: "admin", index: "apiLogs", screenDef: 'ApiLogs' }, auth: { required: true, internal: false }},
  { exact: false, path: '/datauploads', name: 'Data Uploads', Component: DataUploadList, navMenu: { category: "config", index: "dataUpload", screenDef: 'dataUploads' }, auth: { required: true, internal: true }},
  { exact: true, path: '/dataupload', name: 'Data Upload', Component: DataUpload, navMenu: { category: "config", index: "dataUpload", screenDef: 'DataUpload' }, auth: { required: true, internal: true }},
  { exact: true, path: '/dataupload/:id', name: 'Data Upload', Component: DataUpload, navMenu: { category: "config", index: "dataUpload", screenDef: 'DataUpload' }, auth: { required: true, internal: true }},
  { exact: false, path: '/launchpad', name: 'Launch Pad', Component: LaunchPad, navMenu: { category: "launch", index: "launchPad", screenDef: 'LaunchPad' }, auth: { required: true, internal: false }},
  { exact: false, path: '/cannabisinventory', name: 'Cannabis Invenotory', Component: CannabisInventory, navMenu: { category: "inventory", index: "CannabisInventory", screenDef: 'CannabisInventory' }, auth: { required: true, internal: false }},
  { exact: true, path: '/printview/:object/:id', name: 'Document View', Component: PrintView, navMenu: { category: "printView", index: "printView", screenDef: '' }, auth: { required: true, internal: false }},
  { exact: false, path: '/inventorybreakdown', name: 'InvenotoryBreakdown', Component: InventoryBreakdown, navMenu: { category: "reporting", index: "InventoryBreakdown", screenDef: 'InventoryBreakdown' }, auth: { required: true, internal: false }},
  { exact: false, path: '/announcements', name: 'Announcements', Component: AnnouncementList, navMenu: { category: "config", index: "announcements" }, auth: { required: true, internal: false }},
  { exact: true, path: '/announcement', name: 'Announcement', Component: Announcement, navMenu: { category: "config", index: "announcements" }, auth: { required: true, internal: false }},
  { exact: true, path: '/announcement/:id', name: 'Announcement', Component: Announcement, navMenu: { category: "config", index: "announcements" }, auth: { required: true, internal: false }},
  { exact: false, path: '/roomfeedings', name: 'RoomFeedings', Component: RoomFeedingList, navMenu: { category: "cultivation", index: "roomFeedings" }, auth: { required: true, internal: false }},
  { exact: true, path: '/roomfeeding', name: 'RoomFeeding', Component: RoomFeeding, navMenu: { category: "cultivation", index: "roomFeedings" }, auth: { required: true, internal: false }},
  { exact: true, path: '/roomfeeding/:id', name: 'RoomFeeding', Component: RoomFeeding, navMenu: { category: "cultivation", index: "roomFeedings" }, auth: { required: true, internal: false }},
  { exact: false, path: '/feedingrecipes', name: 'FeedingRecipes', Component: FeedingRecipeList, navMenu: { category: "cultivation", index: "feedRecipes" }, auth: { required: true, internal: false }},
  { exact: true, path: '/feedingrecipe', name: 'FeedingRecipe', Component: FeedingRecipe, navMenu: { category: "cultivation", index: "feedRecipes" }, auth: { required: true, internal: false }},
  { exact: true, path: '/feedingrecipe/:id', name: 'FeedingRecipe', Component: FeedingRecipe, navMenu: { category: "cultivation", index: "feedRecipes" }, auth: { required: true, internal: false }},
  { exact: false, path: '/pestscoutings', name: 'PestScoutings', Component: PestScoutingList, navMenu: { category: "cultivation", index: "pestScoutings" }, auth: { required: true, internal: false }},
  { exact: true, path: '/pestscouting', name: 'PestScouting', Component: PestScouting, navMenu: { category: "cultivation", index: "pestScoutings" }, auth: { required: true, internal: false }},
  { exact: true, path: '/pestscouting/:id', name: 'PestScouting', Component: PestScouting, navMenu: { category: "cultivation", index: "pestScoutings" }, auth: { required: true, internal: false }},
  { exact: false, path: '/ccxpostings', name: 'CCXPostings', Component: CcxPostingList, navMenu: { category: "crm", index: "CcxPostings" }, auth: { required: true, internal: false }},
  { exact: true, path: '/ccxposting', name: 'CCXPosting', Component: CcxPosting, navMenu: { category: "crm", index: "CcxPostings" }, auth: { required: true, internal: false }},
  { exact: true, path: '/ccxposting/:id', name: 'CCXPosting', Component: CcxPosting, navMenu: { category: "crm", index: "CcxPostings" }, auth: { required: true, internal: false }},
  { exact: false, path: '/exciseadjustments', name: 'ExciseAdjustments', Component: ExciseAdjustmentList, navMenu: { category: "inventory", index: "ExciseAdjustments" }, auth: { required: true, internal: false }},
  { exact: false, path: '/useraccessreport', name: 'UserAccessReport', Component: UacReport, navMenu: { category: "admin", index: "user" }, auth: { required: true, internal: false }},
  { exact: false, path: '/tenantaccessreport', name: 'UserAccessReport', Component: UacReport, navMenu: { category: "admin", index: "user" }, auth: { required: true, internal: false }},
  { exact: false, path: '/pesticiderecipes', name: 'PesticideRecipes', Component: PesticideRecipes, navMenu: { category: "cultivation", index: "pesticideRecipes" }, auth: { required: true, internal: false }},
  { exact: true, path: '/pesticiderecipe', name: 'PesticideRecipe', Component: PesticideRecipe, navMenu: { category: "cultivation", index: "pesticideRecipes" }, auth: { required: true, internal: false }},
  { exact: true, path: '/pesticiderecipe/:id', name: 'PesticideRecipe', Component: PesticideRecipe, navMenu: { category: "cultivation", index: "pesticideRecipes" }, auth: { required: true, internal: false }},
  { exact: false, path: '/equipmentcalibrationchecks', name: 'EquipmentCalibrationChecks', Component: EquipmentCalibrationChecks, navMenu: { category: "facility", index: "equipmentCalibrationChecks" }, auth: { required: true, internal: false }},
  { exact: true, path: '/equipmentcalibrationcheck', name: 'EquipmentCalibrationCheck', Component: EquipmentCalibrationCheck, navMenu: { category: "facility", index: "equipmentCalibrationChecks" }, auth: { required: true, internal: false }},
  { exact: true, path: '/equipmentcalibrationcheck/:id', name: 'EquipmentCalibrationCheck', Component: EquipmentCalibrationCheck, navMenu: { category: "facility", index: "equipmentCalibrationChecks" }, auth: { required: true, internal: false }},
  { exact: false, path: '/equipmentcalibrationtypes', name: 'EquipmentCalibrationTypes', Component: EquipmentCalibrationTypes, navMenu: { category: "facility", index: "equipmentCalibrationTypes" }, auth: { required: true, internal: false }},
  { exact: true, path: '/equipmentcalibrationtype', name: 'EquipmentCalibrationType', Component: EquipmentCalibrationType, navMenu: { category: "facility", index: "equipmentCalibrationTypes" }, auth: { required: true, internal: false }},
  { exact: true, path: '/equipmentcalibrationtype/:id', name: 'EquipmentCalibrationType', Component: EquipmentCalibrationType, navMenu: { category: "facility", index: "equipmentCalibrationTypes" }, auth: { required: true, internal: false }},
  { exact: true, path: '/invoice', name: 'Invoice', Component: Invoice, navMenu: { category: "crm", index: "invoices" }, auth: { required: true, internal: false }},
  { exact: true, path: '/invoice/:id', name: 'Invoice', Component: Invoice, navMenu: { category: "crm", index: "invoices" }, auth: { required: true, internal: false }},
  { exact: true, path: '/invoices', name: 'InvoiceList', Component: InvoiceList, navMenu: { category: "crm", index: "invoices" }, auth: { required: true, internal: false }},
  { exact: false, path: '/availableinventories', name: 'AvailableInventoryList', Component: AvailableInventoryList, navMenu: { category: "reporting", index: "availableInventory" }, auth: { required: true, internal: false }},
  { exact: true, path: '/availableinventory/:id', name: 'AvailableInventory', Component: AvailableInventory, navMenu: { category: "reporting", index: "availableInventory" }, auth: { required: true, internal: false }},
  { exact: true, path: '/availableinventory', name: 'AvailableInventory', Component: AvailableInventory, navMenu: { category: "reporting", index: "availableInventory" }, auth: { required: true, internal: false }},
  { exact: true, path: '/excisestampaudit', name: 'ExciseStampAudit', Component: ExciseStampAudit, navMenu: { category: "reporting", index: "exciseStampAudit" }, auth: { required: true, internal: false }},
  { exact: false, path: '/mastercases', name: 'MasterCaseList', Component: MasterCaseList, navMenu: { category: "processing", index: "masterCasing" }, auth: { required: true, internal: false }},
  { exact: true, path: '/mastercase/:id', name: 'MasterCase', Component: MasterCase, navMenu: { category: "processing", index: "masterCasing" }, auth: { required: true, internal: false }},
  { exact: true, path: '/mastercase', name: 'MasterCase', Component: MasterCase, navMenu: { category: "processing", index: "masterCasing" }, auth: { required: true, internal: false }},
  { exact: false, path: '/capas', name: 'CapaList', Component: CapaList, navMenu: { category: "investigations", index: "capas" }, auth: { required: true, internal: false }},
  { exact: false, path: '/batchactivities', name: 'BatchActivities', Component: BatchActivities, navMenu: { category: "cultivation", index: "batchActivities" }, auth: { required: true, internal: false }},
  { exact: false, path: '/cleaningsolutionrecipes', name: 'CleaningSolutionRecipes', Component: CleaningSolutionRecipes, navMenu: { category: "facility", index: "cleaningSolutionRecipes" }, auth: { required: true, internal: false }},
  { exact: true, path: '/cleaningsolutionrecipe', name: 'CleaningSolutionRecipe', Component: CleaningSolutionRecipe, navMenu: { category: "facility", index: "cleaningSolutionRecipes" }, auth: { required: true, internal: false }},
  { exact: true, path: '/cleaningsolutionrecipe/:id', name: 'CleaningSolutionRecipe', Component: CleaningSolutionRecipe, navMenu: { category: "facility", index: "cleaningSolutionRecipes" }, auth: { required: true, internal: false }},
  { exact: false, path: '/riskassessments', name: 'RiskAssessments', Component: RiskAssessments, navMenu: { category: "quanlity", index: "riskAssessments" }, auth: { required: true, internal: false }},
  { exact: true, path: '/riskassessment', name: 'RiskAssessment', Component: RiskAssessment, navMenu: { category: "quanlity", index: "riskAssessment" }, auth: { required: true, internal: false }},
  { exact: true, path: '/riskassessment/:id', name: 'RiskAssessment', Component: RiskAssessment, navMenu: { category: "quanlity", index: "riskAssessment" }, auth: { required: true, internal: false }},
  { exact: false, path: '/pastinventories', name: 'PastInventoryList', Component: PastInventoryList, navMenu: { category: "inventory", index: "PastInventoryList" }, auth: { required: true, internal: false }},
  { exact: true, path: '/pastinventory/:id', name: 'PastInventory', Component: PastInventory, navMenu: { category: "inventory", index: "PastInventoryList" }, auth: { required: true, internal: false }},
  { exact: false, path: '*', name: 'No Match', noPath: true},
]

export default routes;
