import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ClearButton, CreateButton, DeleteButton,
         ReturnButton, SaveButton, TextOnlyClickButton } from '../UI/Buttons/Buttons';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import { withTranslation } from 'react-i18next';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import common from '../../jsons/common.json';
import isEmpty from '../../is-empty'; 
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import ValueHelper from '../../helpers/valueHelper';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';

class Values extends Component {
    constructor(props){
        super(props);
        this.state = {
            values: [],
            all: [],
            _id: '',
            type: {},
            foreignId: '',
            edit: false,
            orderBy: 'labels[0].label',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            global: false,
            exportName: '',
            default: false,
            hide: false,
            security: [],
            negative: false,
            labels: [],
            enableSave: true,
            types: [],
            childType: '',
            childTypeSel: null,
            errors: {},
            clientManaged: false,
            deleteOpen: false,
            relatedValues: [],
            internal: false,
            filter: ''
        }

        this.newClick = this.newClick.bind(this);
        this.clearClick = this.clearClick.bind(this);
        this.deleteClick = this.deleteClick.bind(this);
    }

   componentDidMount =  async () => {
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.valueType], this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({types: cacheValues.valueTypes, internal: this.props.auth.user.internal});
        this.loadValues();
    }

    loadValues = async () => {
        let type = await axios.get('/api/valuetype/' + this.props.match.params.type);
        let values = await axios.get('/api/valueslist/' + this.props.match.params.type);
        let valueList = values.data;
        valueList.sort((a, b) => (a.labels[0].label > b.labels[0].label) ? 1 : -1);
        this.setState({
            type: type.data,
            values: valueList,
            all: valueList,
            _id: '',
            labels: [],
            foreignId: '',
            edit: false,
            global: type.data.global,
            security: this.props.permission,
            clientManaged: false,
            deleteOpen: false
        }, () => {
            const crumbs = [
                { path: '/#/valuetypes', label: this.props.t('valueTypes'), screen: this.props.auth.screenDefs.RoomCleaning},
                { path: 'active', label: this.props.t('valuesFor') + this.state.type.name, screen: this.props.auth.screenDefs.RoomCleaning}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    newClick(e){
        e.preventDefault();
        let labels = [{
            locale: common.languages.english.locale,
            label: '',
        },{
            locale: common.languages.french.locale,
            label: ''
        },
        {
            locale: common.languages.spanish.locale,
            label: ''
        }];

        this.setState({edit: true, labels: labels, clientManaged: this.props.auth.user.internal ? false : true});
    }

    editClick = (index) => (e) => {
        let row = this.state.values[index];
        let labels = row.labels;
        if(labels == null || labels.length === 0){
            common.shortLocales.forEach(row => {
                labels.push({
                    locale: row,
                    label: ''
                })
            });
        }
        let relatedValues = row.relatedValues != null ? row.relatedValues : [];
        if(relatedValues.length > 0 || (this.state.type.relatedTypes != null && this.state.type.relatedTypes.length > 0)){
            for(let type of this.state.type.relatedTypes){
                let exists = relatedValues.find(x => x.type === type);
                let typeSel = this.state.types.find(x => x.value === type);
                let typeObj = this.state.type.relatedTypeObjs.find(x => x._id === type);
                if(!exists){
                    relatedValues.push({
                        type: type,
                        typeSel: typeSel,
                        value: [],
                        valueSel: [],
                        values: typeObj.values
                    });
                } else {
                    exists.typeSel = typeSel;
                    exists.values = typeObj.values;
                    let valueSels = [];
                    if(exists.value != null && exists.value.length > 0){
                        for(let row of exists.value){
                            let sel = typeObj.values.find(x => x.value === row);
                            valueSels.push(sel);
                        }
                    }
                    exists.valueSels = valueSels;
                }
            }

        }
        let childTypeSel = row.childType != null ? this.state.types.find(x => x.value === row.childType) : null;
        this.setState({
            _id: row._id,
            foreignId: row.foreignId,
            exportName: row.exportName,
            negative: row.negative ? true : false,
            labels: labels,
            childType: row.childType,
            childTypeSel: childTypeSel,
            edit: true,
            default: row.default != null ? row.default : false,
            hide: row.hide != null ? row.hide : false,
            clientManaged: row.clientManaged,
            relatedValues: relatedValues
        });
    }

    saveClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick(e));
    }

    _saveClick = async (e) =>{
        e.preventDefault();
        const t = this.props.t;
        let valid = this.validate();
        if(!valid){
            this.setState({enableSave: true});
            return;
        }
        let relatedValues = [];
        for(let row of this.state.relatedValues){
            relatedValues.push({
                type: row.type,
                value: row.value !== '' ? row.value : null
            })
        }
        const data = {
            _id: this.state._id,
            type: this.props.match.params.type,
            foreignId: this.state.foreignId,
            exportName: this.state.exportName,
            global: this.state.global,
            negative: this.state.negative,
            labels: this.state.labels,
            childType: this.state.childType,
            default: this.state.default,
            hide: this.state.hide,
            clientManaged: this.state.clientManaged,
            relatedValues: relatedValues
        }
        try {
            let result = await axios.post('/api/valuesave', data);
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.loadValues();
            });

        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
            });
        }
    }

    validate(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.default === true){
            let defaultRow = state.values.find(x => x.default != null && x.default === true);
            if(defaultRow != null && defaultRow._id !== state._id){
                errors[t('default')] = t('oneDefault');
                alert(t('oneDefault'))
            }
        }
        if(state.type.clientManaged === false && state.clientManaged === true){
            errors[t('clientManaged')] = "Type not client managed";
            alert('Type not client managed');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    clearClick = () => {
        this.setState({
            _id: '',
            foreignId: '',
            exportName: '',
            edit: false,
            negative: false,
            labels: [],
            childType: '',
            default: false,
            hide: false,
            childTypeSel: null,
            clientManaged: false
        })
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    async deleteClick(e){
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            try{
                let result = await axios.delete('/api/valuedelete/' + this.state._id);
                this.setState({deleteOpen: false, _id: ''})
                this.loadValues();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            } catch (err){
                this.setState({deleteOpen: false})
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        }
    }

    changeLineText = (index) => (e) => {
        let labels = this.state.labels;
        let row = labels[index];
        let value = e.target.value;
        row.label = value;
        this.setState({labels: labels});
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeRelAuto = (index, name, data) => async (e, newValue) => {
        let relatedValues = this.state.relatedValues;
        let row = relatedValues[index];
        let ids = [];
        if(newValue != null){
            newValue.forEach(x => {
                ids.push(x.value);
            });
        }
        row[name] = newValue;
        row[data] = ids;
        this.setState({relatedValues: relatedValues});
    }

    returnClick(){
        this.props.history.goBack();
    }

    changeFilter = (e) => {
        let value = e.target.value;
        let values = [];
        if(value == null || value === ''){
            values = this.state.all;
        } else {
            values = this.state.all.filter(x => x.labels[0].label.toLowerCase().includes(value.toLowerCase()));
        }
        this.setState({filter: value, values: values});
    }

    render(){
        const security = this.state.security;
        const t = this.props.t;
        const errors = this.state.errors;
        const internal = this.props.auth.user.internal;
        const clientAdd = this.state.type.clientManaged != null && this.state.type.clientManaged === true && this.state.type.clientAdd === true ? true : false;
        return(
            <Aux>
                <BreadcrumbBar/>
                <Panel>
                    {(internal || clientAdd) &&
                        <CreateButton createClick={this.newClick} security={security.create} data-cy='create'/>
                    }
                    <ReturnButton returnClick={() => this.returnClick()} data-cy='return'/>
                    <hr />
                    <Grid container spacing={3}>
                        <Grid item sm={2}>
                            <TextField value={this.state.filter} onChange={this.changeFilter} name="filter"
                            size="medium" fullWidth={true} label={t('filter')}
                            data-cy='filter'/>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Values">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>{t('label')}</TableCell>
                                    {internal &&
                                        <TableCell>{t('exportName')}</TableCell>
                                    }
                                    <TableCell>{t('default')}</TableCell>
                                    <TableCell>{t('hide')}</TableCell>
                                    <TableCell>{t('clientManaged')}</TableCell>
                                    {this.state.internal &&
                                        <TableCell>_id</TableCell>
                                    }
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.values.map((value, i) =>
                                    <TableRow key={"tableRow"+i}>
                                        <TableCell>{value.labels[0].label}</TableCell>
                                        {internal &&
                                            <TableCell>{value.exportName}</TableCell>
                                        }
                                        <TableCell>{value.default != null && value.default === true ? t('yes') : ''}</TableCell>
                                        <TableCell>{value.hide != null && value.hide === true ? t('yes') : ''}</TableCell>
                                        <TableCell>{value.clientManaged != null && value.clientManaged === true ? t('yes') : ''}</TableCell>
                                        {this.state.internal &&
                                            <TableCell>{value._id}</TableCell>
                                        }
                                        <TableCell>
                                            <TextOnlyClickButton
                                            click={this.editClick(i)}
                                            enabled={security.update}
                                            iteration={i}
                                            name={t("edit")}
                                            data-cy='edit'/>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Dialog open={this.state.edit} onClose={this.clearClick} center={true} maxWidth="lg" fullWidth>
                        <DialogTitle>
                            <SectionTitle title={t('details')} />
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>{t('language')}</Grid>
                                <Grid item xs={6} sm={3}>{t('label')}</Grid>
                            </Grid>
                            {this.state.labels.map((row, i) =>
                                <Grid container spacing={3}>
                                    <Grid item xs={4} sm={2}>{row.locale}</Grid>
                                    <Grid item xs={6} sm={3}>
                                        <TextField value={row.label} onChange={this.changeLineText(i)} name={"label" + row.locale}
                                        size="medium" fullWidth={true} data-cy={'label' + i}/>
                                    </Grid>
                                </Grid>
                            )}
                            <br />
                            <Grid container spacing={3}>
                                {internal &&
                                    <Grid item sm={3}>
                                        <TextField value={this.state.exportName} onChange={this.changeValue} name="exportName"
                                        size="medium" fullWidth={true} label={t('exportName')} variant='outlined' error
                                        data-cy='exportName'/>
                                    </Grid>
                                }
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.childTypeSel}
                                        options={this.state.types}
                                        onChange={this.changeAuto('childTypeSel', 'childType')}
                                        label={t('childType')}
                                        disabled={!internal}
                                        data-cy='childType'
                                    />
                                </Grid>
                                <Grid item sm={7}>
                                    <TextField value={this.state.foreignId} onChange={this.changeValue} name="foreignId"
                                    size="medium" fullWidth={true} label={t('erpId')} disabled={!internal} data-cy='foreignId'/>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.negative} onChange={this.changeBool('negative')}
                                    label={t('negative')} name="negative" disabled={!internal} data-cy='negative'
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.default} onChange={this.changeBool('default')}
                                    label={t('default')} name="default"
                                    error={errors[t('default')] != null ? true : false}
                                    helperText={errors[t('default')]} 
                                    data-cy='default'
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.hide} onChange={this.changeBool('hide')}
                                    label={t('hide')} name="hide"
                                    error={errors[t('hide')] != null ? true : false}
                                    helperText={errors[t('hide')]} 
                                    data-cy='hide'
                                    />
                                </Grid>
                                {this.state.type.clientManaged && internal &&
                                    <Grid item xs={4} sm={2}>
                                        <CheckboxField
                                        checked={this.state.clientManaged} onChange={this.changeBool('clientManaged')}
                                        label={t('clientManaged')} name="clientManaged"
                                        error={errors[t('clientManaged')] != null ? true : false}
                                        helperText={errors[t('clientManaged')]} 
                                        disabled={!internal}
                                        data-cy='clientManaged'
                                        />
                                    </Grid>
                                }
                            </Grid>
                            {this.state.relatedValues != null && this.state.relatedValues.length > 0 && 
                                <Aux>
                                    <br/>
                                    <SubSectionHeading title={t('relatedValues')} />
                                    <Grid container spacing={3} key="relHead">
                                        <Grid item xs={4} sm={2}>{t('type')}</Grid>
                                        <Grid item xs={6} sm={3}>{t('value')}</Grid>
                                    </Grid>
                                    {this.state.relatedValues.map((row, i) =>  
                                        <Grid container spacing={3} key={'rl' + row.value} sx={{py: 1}}>
                                            <Grid item xs={4} sm={2}>
                                                {row.typeSel != null ? row.typeSel.label : ''}
                                            </Grid>
                                            <Grid item xs={10} sm={6}>
                                                <AutoCompleteField
                                                    multiple={true}
                                                    value={row.valueSels}
                                                    options={row.values}
                                                    onChange={this.changeRelAuto(i, 'valueSels', 'value')}
                                                    disabled={!internal}
                                                    data-cy={'value' + i}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Aux>
                            }
                        </DialogContent>
                        <DialogActions>
                            <SaveButton saveClick={this.saveClick} enabled={this.state.enableSave} data-cy='save'/>
                            <ClearButton clearClick={this.clearClick} enabled={true} data-cy='clear'/>
                            {internal  &&
                                <DeleteButton deleteClick={this.deleteToggle} enabled={this.state._id !== '' && security.delete} data-cy='delete'/>
                            }
                        </DialogActions>
                    </Dialog>
                </Panel>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(Values));
