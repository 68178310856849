import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/EditTwoTone';
import SequenceIcon from '@mui/icons-material/LooksOneTwoTone';
import ScheduleIcon from '@mui/icons-material/CalendarTodayTwoTone';
import PrintIcon from '@mui/icons-material/PictureAsPdfTwoTone';
import UnlockIcon from '@mui/icons-material/LockOpen';
import DestroyIcon from '@mui/icons-material/DeleteTwoTone';
import HelpIcon from '@mui/icons-material/HelpOutlineTwoTone';
import OptionMenuIcon from '@mui/icons-material/MenuOpenRounded';
import CreateIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ObservationIcon from '@mui/icons-material/VisibilityTwoTone';
//import CommentsIcon from '@mui/icons-material/QuestionAnswerTwoTone';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import CalculatorIcon from 'mdi-material-ui/CalculatorVariant';
import AttachmentsIcon from '@mui/icons-material/AttachFile';
// import Help from '../../General/Help';
import Help from './Dialogs/Help';
import Badge from '@mui/material/Badge';
//import PrintAction from './Actions/Print';
import { useTranslation } from 'react-i18next';
import BolIcon from '@mui/icons-material/ReceiptOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import ScreenSettings from '../../Settings/Settings';
import UnlockDialog from '../../General/UnlockDialog';
import CachedIcon from '@mui/icons-material/Cached';
import PrintButton from './PrintButton';
import ExportButton from './ExportButton'
import LabelPrint from './LabelPrint';
import isEmpty from '../../../is-empty';
import Attachments from './Dialogs/Attachments';

export default function ActionBar(props) {
  const dispatch = useDispatch();
  const internal = useSelector(state => state.auth.user.internal);
  const constants = useSelector(state => state.auth.constants);
  const hasRegisteredDevices = useSelector(state => state.devices.hasRegisteredDevices);
  const { optionsAvailable, createAccess, createClick, createLocation, destroyClick, objectType, enableAttachments,
          destroyAccess, editClick, observationClick, fieldAccess, printClick, unlockCallback, id, finalDocuments,
          printAccess, status, unlockClick, unlockAccess, flagForSignature, csvClick, scheduleClick, editSequenceClick,
          observationAccess, screen, showBreakdown, breakdownCallback, showUnlock, toggleUnlock, excelClick, enablePrintView,
          cacheClick, invoiceClick, printList, labelData, labelAmount, plantLabel, exportList, printViewClick, printViewTitle } = props;
  const createButton = (createClick || createLocation) ? true : false;
  const { t } = useTranslation();
  const icon = {
    margin: 0,
    color: 'primary.main',
    minWidth: 0,
  };
  const disabledIcon = {
    margin: 0,
    color: '#e8e8e8',
    minWidth: 0,
  };
  const helpIcon = {
    margin: 0,
    color: 'info.main',
    minWidth: 0,
  };
  const settingsIcon = {
    margin: 0,
    color: 'error.main',
    minWidth: 0,
  };
  const dotLevel0 = {
    '& .MuiBadge-dot': {
      backgroundColor: '#FFF'
    }
  }
  const dotLevel1 = {
    '& .MuiBadge-dot': {
      backgroundColor: '#00AC89'
    }   
  }
  const dotLevel2 = {
    '& .MuiBadge-dot': {
      backgroundColor: '#CBDB2F'
    }
  }
  const dotLevel3 = {
    '& .MuiBadge-dot': {
      backgroundColor: 'secondary.main'
    }
  };
  const dotLevel4 = {
    '& .MuiBadge-dot': {
      backgroundColor: 'success.main'
    }
  };  
  
  const create =(e) => {
    if(createClick) {
      createClick();
    }else if(createLocation) {
      e.preventDefault();
      window.location.href=`${createLocation}`;
    }
  }

  const print = (e) => {
    e.preventDefault();
    window.location.href=`${printClick}`;
  }

  const [ attachmentsOpen, setAttachmentsOpen ] = React.useState(false);
  const clickAttachments = () => {
    setAttachmentsOpen(!attachmentsOpen);
  }

  const [ helpOpen, setHelpOpen ] = React.useState(false);
  const helpClick = () => {
    setHelpOpen(!helpOpen);
  }

  const [ settingsOpen, setSettingsOpen ] = React.useState(false);
  const clickSettings = () => {
    setSettingsOpen(!settingsOpen);
  }

  const toggleOptionMenu = () => {
    dispatch({ type: 'TOGGLE_OPTIONMENU' });
  }

  const signatureFlag = flagForSignature === 1 ? dotLevel1 :
                        flagForSignature === 2 ? dotLevel2 :
                        flagForSignature === 3 ? dotLevel3 :
                        flagForSignature === 4 ? dotLevel4 : dotLevel0;
  return (
    <div style={{float: 'right', display: 'flex', flexDirection: 'column', alignItems: 'center',
    '& > *': {
      margin: 1
    },}}>
      <ButtonGroup variant="text" color="primary">
        {internal && editSequenceClick &&
          <Tooltip title={t('editSequence')} >
            <div>
              <IconButton
                disabled={false}
                component={'div'}
                onClick={editSequenceClick}
                sx={icon}
                name='actionEditSequence'
              >
                  <SequenceIcon fontSize='medium'/>
              </IconButton>
            </div>
          </Tooltip>
        }
        {createButton &&
          <Tooltip title={t('createNew')}>
            <div  >
              <IconButton
                disabled={!createAccess}
                component={createAccess ? 'div' : undefined}
                onClick={create}
                sx={createAccess ? icon : disabledIcon}
                name='actionCreate'
              >
                  <CreateIcon fontSize='medium'/>
              </IconButton>
            </div>
          </Tooltip>
        }
        {editClick &&
          <Tooltip title={t('edit')}>
            <div  >
              <IconButton
                disabled={!fieldAccess}
                component={fieldAccess ? 'div' : undefined}
                onClick={editClick}
                sx={fieldAccess ? icon : disabledIcon}
                name='actionEdit'
              >
                  <EditIcon fontSize='medium'/>
              </IconButton>
            </div>
          </Tooltip>
        }
        {scheduleClick &&
          <Tooltip title={t('schedule')}>
              <div  >
              <IconButton
                  disabled={!fieldAccess}
                  component={fieldAccess ? 'div' : undefined}
                  onClick={scheduleClick}
                  sx={fieldAccess ? icon : disabledIcon}
                  name='actionSchedule'
              >
                  <ScheduleIcon fontSize='medium'/>
              </IconButton>
              </div>
          </Tooltip>
        }
        {printClick &&
          <Tooltip title={t('createPdf')} >
            <div >
              <IconButton
                disabled={!printAccess}
                component={printAccess ? 'div' : undefined}
                onClick={print}
                sx={printAccess === true ? icon : disabledIcon}
                name='actionCreatePDF'
              >
                  <PrintIcon fontSize='medium'/>
              </IconButton>
            </div>
          </Tooltip>
        }
        {hasRegisteredDevices && labelData != null && labelAmount != null &&
        (!isEmpty(labelData) || !isEmpty(plantLabel)) &&
          <LabelPrint 
            containerData={labelData}
            printAmount={labelAmount}
            plantLabel={plantLabel}
          />
        }
        {destroyClick &&
          <Tooltip title={t('destroy')} >
            <div>
              <IconButton
                disabled={!destroyAccess}
                component={destroyAccess ? 'div' : undefined}
                onClick={destroyClick}
                sx={destroyAccess ? icon : disabledIcon}
                name='actionDestroy'
              >
                  <DestroyIcon fontSize='medium'/>
              </IconButton>
            </div>
          </Tooltip>
        }
        {unlockClick && (status === constants.batchStatuses.signedOff || status === constants.batchStatuses.complete || status === constants.batchStatuses.review || 
        status === constants.productionBatchStatuses.signedOff || status === constants.productionBatchStatuses.complete || 
        status === constants.productionBatchStatuses.reviewed) &&
          <Tooltip title={t('unlock')} >
            <div>
              <IconButton
                disabled={!unlockAccess}
                component={unlockAccess ? 'div' : undefined}
                onClick={unlockClick}
                sx={unlockAccess ? icon : disabledIcon}
                name='actionUnlock'
              >
                  <UnlockIcon fontSize='medium'/>
              </IconButton>
            </div>
          </Tooltip>
        }
        {observationClick &&
          <Tooltip title={t('observation')} >
          <div>
            <IconButton
              disabled={!observationAccess}
              component={'div'}
              onClick={observationClick}
              sx={observationAccess ? icon : disabledIcon}
              name='actionObservation'
            >
                <ObservationIcon fontSize='medium'/>
            </IconButton>
          </div>
        </Tooltip>
        }
        {breakdownCallback && showBreakdown === true &&
          <Tooltip title={t('breakdown')} >
              <div>
                  <IconButton
                      disabled={false}
                      component={'div'}
                      onClick={breakdownCallback}
                      sx={icon}
                      name='breakdown'
                  >
                      <CalculatorIcon fontSize='medium'/>
                  </IconButton>
              </div>
          </Tooltip>
        }
        {csvClick &&
          <Tooltip title={t('exportToCsv')} >
              <div>
                  <IconButton
                      disabled={false}
                      component={'div'}
                      onClick={csvClick}
                      sx={icon}
                      name='actionCsv'
                  >
                      <ExcelIcon fontSize='medium'/>
                  </IconButton>
              </div>
          </Tooltip>
        }
        {excelClick &&
          <Tooltip title={t('exportToExcel')} >
              <div>
                  <IconButton
                      disabled={false}
                      component={'div'}
                      onClick={excelClick}
                      sx={icon}
                      name='actionExcel'
                  >
                      <ExcelIcon fontSize='medium'/>
                  </IconButton>
              </div>
          </Tooltip>
        }
        {cacheClick &&
          <Tooltip title={t('cacheReset')} >
              <div>
                  <IconButton
                      disabled={false}
                      component={'div'}
                      onClick={cacheClick}
                      sx={icon}
                      name='actionCache'
                  >
                      <CachedIcon fontSize='medium'/>
                  </IconButton>
              </div>
          </Tooltip>
        }
        {printList &&
            <PrintButton list={printList} />
        }
        {exportList && 
            <ExportButton list={exportList} />
        }
        {printViewClick &&
            <Tooltip title={printViewTitle} >
                <div>
                    <IconButton
                        disabled={!enablePrintView}
                        component={'div'}
                        onClick={printViewClick}
                        sx={icon}
                        name='printViewTitle'
                    >
                        <PrintIcon fontSize='medium'/>
                    </IconButton>
                </div>
            </Tooltip>
        }
        {invoiceClick &&
            <Tooltip title={t('invoice')} >
                <div>
                    <IconButton
                        disabled={false}
                        component={'div'}
                        onClick={invoiceClick}
                        sx={icon}
                        name='invoice'
                    >
                        <BolIcon fontSize='medium'/>
                    </IconButton>
                </div>
            </Tooltip>
        }
        {optionsAvailable &&
          <Tooltip title={t('options')} >
            <div>
              <IconButton
                disabled={false}
                //component={destroyAccess ? 'div' : undefined}
                onClick={toggleOptionMenu}
                sx={icon}
                name='actionOptionsMenu'
              >
                <Badge  sx={signatureFlag}
                        variant="dot"
                        invisible={flagForSignature === 0}>
                  <OptionMenuIcon fontSize='medium'/>
                </Badge>
              </IconButton>
            </div>
          </Tooltip>
        }
        {enableAttachments &&
          <Tooltip title={t('attachments')} >
            <div>
                <IconButton
                    disabled={false}
                    component={'div'}
                    onClick={clickAttachments}
                    sx={icon}
                    name='actionAttachments'
                >
                    <AttachmentsIcon fontSize='medium'/>
                </IconButton>
            </div>
          </Tooltip>
        }
        {internal &&
          <Tooltip title={t('screenSettings')} >
              <div>
                  <IconButton
                      disabled={false}
                      component={'div'}
                      onClick={clickSettings}
                      sx={settingsIcon}
                      name='actionSettings'
                  >
                      <SettingsIcon fontSize='medium'/>
                  </IconButton>
              </div>
          </Tooltip>
        }        
        <Tooltip title={t('help')} >
          <div>
            <IconButton
              disabled={false}
              onClick={helpClick}
              sx={helpIcon}
              name='actionHelp'
            >
                <HelpIcon fontSize='medium'/>
            </IconButton>
          </div>
        </Tooltip>
      </ButtonGroup>
      {helpOpen &&
          <Help toggleDialog={() => helpClick} open={helpOpen} />
      }
      {internal && settingsOpen &&
          <ScreenSettings screen={screen} open={settingsOpen} toggleDialog={() => clickSettings} />
      }
      {attachmentsOpen &&
          <Attachments
            finalDocuments={finalDocuments}
            objectType={objectType}
            recordId={id}
            toggleDialog={clickAttachments}
            open={attachmentsOpen}
          />
      }
      <UnlockDialog callback={unlockCallback} show={showUnlock} toggle={toggleUnlock} objectType={objectType} id={id}/>
    </div>
  );
}
