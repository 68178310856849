import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Avatar, Dialog, DialogContent, Grid, 
         TextField, Typography, Paper } from '@mui/material';
import LockIcon from '@mui/icons-material/LockTwoTone';
import { SignInClickButton } from '../UI/Buttons/Buttons';
import { encryptString } from '../../helpers/securityHelper';
import { withTranslation } from 'react-i18next';

class Lockscreen extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: this.props.lock.screenLock,
            toggleDialog: '',
            errors: {},
            values: {},
            pin: '',
            enableButton: true
        }
    }

    componentDidMount = () => {
        this.setState({
            open: this.props.lock.screenLock,
            toggleDialog: this.props.toggleDialog
        })
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.lock.screenLock !== this.props.lock.screenLock){
            this.setState({open: this.props.lock.screenLock});
        }
    }

    handleSubmit = () => {
        if(!this.state.enableButton)
            return;
        this.setState({ enableButton: false }, () => this._handleSubmit());
    }

    _handleSubmit = async () => {
        const t = this.props.t;
        if(this.state.pin == null || this.state.pin === '') this.setState({ enableButton: true }, () => {return});
        let pinHash = encryptString(this.state.pin);
        try {
            const data = { userid: this.props.auth.user.id, pin: pinHash }
            let pinResult = await axios.post('/api/userpinunlock', data);
            this.setState({ enableButton: true }, () => {
                if(!pinResult.data.expired) {
                    if(pinResult.data.confirmed) {
                        this.props.toggleDialog();
                        this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('screenUnlocked'), title: t('success'), severity: 'success'}});
                    }else if(!pinResult.data.confirmed) {
                        let errors = { pin: t('pinIncorrect')};
                        this.setState({errors});
                        this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('pinIncorrect'), title: t('error'), severity: 'error'}});
                    }
                }else if(pinResult.data.expired) {
                    let errors = { pin: t('pinExpired')};
                    this.setState({errors});
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('pinExpired'), title: t('error'), severity: 'error'}});
                }
            });            
        }catch (error) {
            let errors = { pin: error.response.data };
            this.setState({ enableButton: true, errors }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: error.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    }

    handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        const { profile, t } = this.props;
        const { errors, open, pin } = this.state;
        
        return( 
            <Dialog open={open} maxWidth='sm' fullWidth component={Paper}>
                <DialogContent>
                    <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                        <Grid item xs={12}>
                            <Avatar sx={{
                                backgroundColor: 'secondary.main',
                                color: '#FFF',
                                m: '0 auto',
                                width: '75px',
                                height: '75px'
                            }}>
                                <LockIcon sx={{ fontSize: '65px' }} />
                            </Avatar>
                            <Typography sx={{ fontSize: '24px',
                                              fontWeight: 'bold',
                                              color: 'grey[800]'
                                              }}>{t('user')}:</Typography>
                            <Typography sx={{ fontFamily: 'Josefin Sans',
                                              fontSize: '45px',
                                              color: 'primary.main'}}>{profile.profile.name}</Typography>
                            <TextField required value={pin} onChange={this.handleInputChange} name="pin"
                            fullWidth={true} label={t('pin')} margin="normal" variant="outlined" type="password"
                            helperText={errors[t('pin')]} error={errors[t('pin')] != null ? true : false}/>
                            <SignInClickButton enabled={this.state.enableButton} onClick={() => this.handleSubmit()} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
};

const mapStateToProps = state => ({
    auth: state.auth,
    lock: state.lock,
    profile: state.profile
});

export default withTranslation() (connect(mapStateToProps)(Lockscreen));