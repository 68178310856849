import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import { CSVDownload } from "react-csv";
import { NumericFormat } from 'react-number-format';
import AddIcon from '@mui/icons-material/Add';
import ActionBar from '../UI/Buttons/ActionBar';
import listHelper from '../../helpers/listHelper';
import ListMultiSelect from '../UI/ListMultiSelect/ListMultiSelect';

const Items = (props) => {
    const isFirstRender = useRef(true);
    const [loadRefData, setLoadRefData] = useState(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({ctlsExclude: false, b300Exclude: false, hide: false});
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [csvOutput, setCsvOutput] = useState({headers: null, data: null});
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [columnOrder, setColumnOrder] = useState([]);
    const [values, setValues] = useState({strains: [], categories: [], routings: [], boms: [], uoms: [], reportCategories: []});
    const { t } = useTranslation('translation');
    const csvSeparator = sessionStorage.getItem('csvSeparator');
    const tenant = props.auth.user.tenant;
    const [categoriesSel, setCategoriesSel] = useState({ids: [], values: []});
    const [showSelect, setShowSelect] = useState(false);
    const crumbs = [
        { path: 'active', label: t('items'), screen: props.auth.screenDefs.ItemList}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        async function fetchData(){
            const columnFilters = await listHelper.getItem('ItemsColumnFilters', tenant);
            const columnVisibility = await listHelper.getItem('ItemsColumnVisibility', tenant);
            const density = await listHelper.getItem('ItemsDensity', tenant);
            const globalFilter = await listHelper.getItem('ItemsGlobalFilter', tenant);
            const showGlobalFilter = await listHelper.getItem('ItemsShowGlobalFilter', tenant);
            const showColumnFilters = await listHelper.getItem('ItemsShowColumnFilters', tenant);
            const sorting = await listHelper.getItem('ItemsSorting', tenant);
            const pagination = await listHelper.getItem('ItemsPagination', tenant);
            const tempColumnOrder = await listHelper.getItem('ItemsColumnOrder', tenant);
            if (columnFilters) {
                let filters = JSON.parse(columnFilters);
                setColumnFilters(filters);
                let cat = filters.find(x => x.id === 'category');
                if(cat != null){
                    let sel = {ids: cat.value, values: []};
                    cat.value.forEach(id => {
                        let val = values.categories.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setCategoriesSel(sel);
                }
            }
            if (columnVisibility) {
                setColumnVisibility(columnVisibility);
            }
            if (density) {
                setDensity(density);
            }
            if (globalFilter) {
                setGlobalFilter(globalFilter);
            }
            if (showGlobalFilter) {
                setShowGlobalFilter(showGlobalFilter);
            }
            if (showColumnFilters) {
                setShowColumnFilters(showColumnFilters);
            }
            if (sorting) {
                setSorting(sorting);
            }
            if (pagination) {
                setPagination(pagination);
            }
            if (tempColumnOrder != null && tempColumnOrder.length > 0){ 
                setColumnOrder(tempColumnOrder);
            } else {
                setColumnOrder(['number', 'name', 'category', 'inventory', 'uom', 'count', 'strain', 'unitPrice', 'thcMg', 'packSizeG', 'packCount', 'caseQuantity', 'avgWeight', 'reportCategory', 'gtin', 'ctlsExclude', 'b300Exclude', 'isCasedItem', 'isPackagedItem', 'hide']);
            }
            isFirstRender.current = false;
        }
        if(isFirstRender.current === true){
            fetchData();
        }
    }, [values, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ItemsColumnFilters', tenant, JSON.stringify(columnFilters));
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ItemsColumnVisibility', tenant, columnVisibility);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnVisibility, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ItemsDensity', tenant, density);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [density, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ItemsGlobalFilter', tenant, globalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [globalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ItemsShowGlobalFilter', tenant, showGlobalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [showGlobalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ItemsShowColumnFilters', tenant, showColumnFilters);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [showColumnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ItemsSorting', tenant, sorting);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [sorting, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ItemsPagination', tenant, pagination);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [pagination, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('ItemsColumnOrder', tenant, columnOrder);
        }
        if (isFirstRender.current === true) return;
        fetchData();
    }, [columnOrder, tenant]);

    const resetState = async () => {
        await listHelper.removeItem('ItemsColumnFilters', tenant);
        await listHelper.removeItem('ItemsColumnVisibility', tenant);
        await listHelper.removeItem('ItemsDensity', tenant);
        await listHelper.removeItem('ItemsGlobalFilter', tenant);
        await listHelper.removeItem('ItemsShowGlobalFilter', tenant);
        await listHelper.removeItem('ItemsShowColumnFilters', tenant);
        await listHelper.removeItem('ItemsSorting', tenant);
        await listHelper.removeItem('ItemsPagination', tenant);
        await listHelper.removeItem('ItemsColumnOrder', tenant);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.strain, common.cacheValues.itemCategory, common.cacheValues.routing,
                common.cacheValues.bom, common.cacheValues.quantityUOM, common.cacheValues.hcReportCategory], props.auth.constants, null, props.auth.user.tenant);
            setValues({
                strains: cacheValues.strains,
                categories: cacheValues.itemCategories,
                routings: cacheValues.routings,
                boms: cacheValues.boms,
                uoms: cacheValues.quantityUOMs,
                reportCategories: cacheValues.hcReportCategories
            });
            let cat = columnFilters != null ? columnFilters.find(x => x.id === 'category') : null;
            if(cat != null){
                let sel = {ids: cat.value, values: []};
                cat.value.forEach(id => {
                    let val = cacheValues.itemCategories.find(x => x.value === id);
                    sel.values.push(val);
                });
                setCategoriesSel(sel);
            }
            setLoadRefData(false);
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, columnFilters, loadRefData]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'items',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/items', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });

    let columns = useMemo(() => [
        {
            accessorKey: 'number',
            id: 'number',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/item/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            ),
        },
        {
            accessorKey: 'name',
            id: 'name',
            header: t('name')
        },
        {
            accessorKey: 'category.description',
            id: 'category',
            header: t('category'),
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='category'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.categories} 
                    setSelected={setCategoriesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={categoriesSel.ids} 
                    selectedSel={categoriesSel.values}
                    columnFilters={columnFilters}
                    screen='Items'
                    tenant={props.auth.user.tenant}/>
                </Box>
            ),
        },
        {
            accessorKey: 'inventory',
            id: 'inventory',
            header: t('inventory'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
            ),
        },
        {
            accessorKey: 'uom',
            id: 'uom',
            header: t('uom'),
            filterVariant: 'select',
            filterSelectOptions: values.uoms
        },
        {
            accessorKey: 'count',
            id: 'count',
            header: t('count'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
            )
        },
        {
            accessorFn: (row) => (row.strain != null ? row.strain.name : ''),
            id: 'strain',
            header: t('strain'),
            filterVariant: 'select',
            filterSelectOptions: values.strains
        },
        {
            accessorKey: 'unitPrice',
            id: 'unitPrice',
            header: t('unitPrice'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
            )
        },
        {
            accessorKey: 'price',
            id: 'price',
            header: t('price'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
            )
        },
        {
            accessorKey: 'thcMg',
            id: 'thcMg',
            header: t('thcMg'),
            enableColumnFilter: false,
        },
        {
            accessorKey: 'packSizeG',
            id: 'packSizeG',
            header: t('packSize'),
            enableColumnFilter: false
        },
        {
            accessorKey: 'packCount',
            id: 'packCount',
            header: t('packageCount'),
            enableColumnFilter: false
        },
        {
            accessorKey: 'caseQuantity',
            id: 'caseQuantity',
            header: t('caseQuantity'),
            enableColumnFilter: false
        },
        {
            accessorKey: 'avgWeight',
            id: 'avgWeight',
            header: t('avgWeight'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
            )
        },
        {
            accessorKey: 'reportCategory',
            id: 'reportCategory',
            header: t('reportCategory'),
            filterVariant: 'select',
            filterSelectOptions: values.reportCategories
        },
        {
            accessorKey: 'gtin',
            id: 'gtin',
            header: t('gtin')
        },
        {
            accessorKey: 'ctlsExclude',
            id: 'ctlsExclude',
            header: t('ctlsExclude'),
            filterVariant: 'checkbox',
            Cell: ({ cell, column }) => (
                cell.row.original?.ctlsExclude === true ? t('yes') : ''
            )
        },
        {
            accessorKey: 'b300Exclude',
            id: 'b300Exclude',
            header: t('b300Exclude'),
            filterVariant: 'checkbox',
            Cell: ({ cell, column }) => (
                cell.row.original?.b300Exclude === true ? t('yes') : ''
            )
        },
        {   accessorKey: 'isCasedItem',
            id: 'isCasedItem',
            header: t('casedItem'),
            filterVariant: 'checkbox',
            Cell: ({ cell, column }) => (
                cell.row.original?.isCasedItem === true ? t('yes') : ''
            )
        },
        {
            accessorKey: 'isPackagedItem',
            id: 'isPackagedItem',
            header: t('packagedItem'),
            filterVariant: 'checkbox',
            Cell: ({ cell, column }) => (
                cell.row.original?.isPackagedItem === true ? t('yes') : ''
            )
        },
        {
            accessorKey: 'hide',
            id: 'hide',
            header: t('hide'),
            filterVariant: 'checkbox',
            Cell: ({ cell, column }) => (
                cell.row.original?.hide === true ? t('yes') : ''
            )
        }
    ], [t, values, props, columnFilters, categoriesSel, showSelect]);

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true,  columnVisibility: {id: false}},
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: false,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()}>
                        <RefreshIcon color="primary"/>
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('csv')}>
                    <IconButton onClick={() => csv()}>
                        <ExcelIcon color="primary"/>
                    </IconButton>
                </Tooltip>
                {props.permission.create &&
                    <Tooltip arrow title={t('add')}>
                        <IconButton component={Link} to='/item'>
                            <AddIcon color="primary"/>
                        </IconButton>
                    </Tooltip>
                }
                <Tooltip arrow title={t('clearListSettings')}>
                    <IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });

    const csv = async () => {
        setCsvOutput({headers: null, data: null});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axios.get('/api/itemscsv', {params: params});
        let headers = response.data.headers;
        let csvData = response.data.data;
        setCsvOutput({headers: headers, data: csvData});
    }

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs}>
                <ActionBar/>
            </BreadcrumbBar>
            {csvOutput != null && csvOutput.data != null &&
                <CSVDownload data={csvOutput.data} target="_blank" headers={csvOutput.headers} separator={csvSeparator}/>
            }
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
        </Aux>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Items);