import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Link } from 'react-router-dom';
import common from '../../jsons/common.json';
import { fieldSecurity, completeSigSecurity } from '../../helpers/securityHelper';
import DateSelect from '../UI/DateSelect/DateSelect';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { withTranslation } from 'react-i18next';
import ValueHelper from '../../helpers/valueHelper';
import { FormControl, FormLabel } from '@mui/material';
import TextField from '../UI/TextField/TextField';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ActionBar from '../UI/Buttons/ActionBar';
import Switch from '../UI/Switch/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import isEmpty from '../../is-empty';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import ActivityWorkRecord from '../Cultivation/ActivityWorkRecord';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import ConfirmDelete from '../General/ConfirmDelete';
import ConversionHelper from '../../helpers/conversionHelper';
import Comments from '../General/Comments';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';

class EquipmentCalibrationCheck extends Component {
    constructor(props){
        super(props);
        this.state = {
            number: '',
            equipment: [],
            equipmentSel: [],
            status: '',
            statusSel: null,
            type: '',
            typeSel: null,
            date: new Date(),
            comments: '',
            pass: false,
            performedBy: '',
            performedBySel: null,
            signature: null,
            documents: [],
            security: {},
            flagForSignature: 0,
            template: null,
            sops: [],
            sopIds: [],
            sopsSel: [],
            activeUsers: [],
            tabKey: 0,
            tabLabels: [],
            location: '',
            locationSel: null,
            statuses: [],
            equipments: [],
            types: [],
            userOptions: [],
            sopList: [],
            locations: [],
            sigData: null,
            fieldAccess: false,
            enableSave: true,
            _id: null,
            optionsMenu: [],
            allCalibrationTypes: [],
            renderNum: Math.random(),
            deleteOpen: false,
            equipmentAll: [],
            checks: []
        }
    }

    async componentDidMount(){
        const t = this.props.t
        const params = queryString.parse(this.props.location.search);
        const equipment = params != null && params.equipment != null ? params.equipment : '';
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.equipment, common.cacheValues.status, common.cacheValues.equipmentCalibrationType,
            common.cacheValues.equipmentCalibrationSop, common.cacheValues.location, common.cacheValues.equipmentAll], this.props.auth.constants, null, this.props.auth.user.tenant);
        const userValues = await ValueHelper.screenUsers(common.screens.equipmentCalibrationCheck, this.props.auth.user.tenant);
        let equipmentSel = equipment !== '' ? [cacheValues.equipmentAll.find(x => x.value === equipment)] : [];
        let types = [];
        if(equipmentSel[0] != null && equipmentSel[0].calibrationTypes != null && equipmentSel[0].calibrationTypes.length > 0){
            for(let row of equipmentSel[0].calibrationTypes){
                let val = cacheValues.equipmentCalibrationTypes.find(x => x.value === row);
                types.push(val);
            }
        } else {
            types = cacheValues.equipmentCalibrationTypes;
        }
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        let locationSel = null;
        let location = '';
        if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
            location = this.props.auth.user.defaultLocation;
            locationSel = cacheValues.locations.find(x => x.value === location);
        } else {
            if(cacheValues.locations.length === 1){
                locationSel = cacheValues.locations[0];
                location = locationSel.value;
            }
        }
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, common, this.props.auth.constants);
        let workRecords = [];
        let sopIds = [];
        let sopsSel = [];
        cacheValues.sops.forEach(sop => {
            if(sop.required === true){
                workRecords.push({
                    sop: sop.value,
                    original: true
                });
                sopIds.push(sop.value);
                sopsSel.push(sop);
            }
        });
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        this.setState({
            security: this.props.permission,
            status: statusSel.value,
            statusSel: statusSel,
            sopIds: sopIds,
            sopsSel: sopsSel,
            sopList: cacheValues.sops,
            sops: workRecords,
            activeUsers: activeUsers,
            tabKey: 0,
            tabLabels: [{name: t('equipmentCalibrationCheck'), disabled: false}],
            location: location,
            locationSel: locationSel,
            statuses: cacheValues.statuses,
            allCalibrationTypes: cacheValues.equipmentCalibrationTypes,
            equipments: cacheValues.equipments,
            userOptions: userValues.create,
            locations: cacheValues.locations,
            fieldAccess: fieldAccess,
            equipment: equipment,
            equipmentSel: equipmentSel,
            _id: this.props.match.params.id !== '' ? this.props.match.params.id : null,
            equipmentAll: cacheValues.equipmentAll,
            types: types
        }, () => {
            if(this.state._id !== '' && this.state._id != null)
                this.loadRecord();
            else {
                const crumbs = [
                    { path: '/#/equipmentcalibrationchecks', label: this.props.t('calibrationChecks'), screen: this.props.auth.screenDefs.EquipmentCalibrationChecks},
                    { path: 'active', label: this.props.t('calibrationCheck'), screen: this.props.auth.screenDefs.EquipmentCalibrationCheck}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });

    }

    loadRecord = async () => {
        const t = this.props.t;
        let record = await axios.get('/api/equipmentcalibcheck/' + this.state._id);
        let performedBySel = this.state.userOptions.find(x => x.value === record.data.performedBy);
        const fieldAccess = fieldSecurity(this.props.permission, record.data.status, common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, this.props.auth.user.id, record.data.status, common, this.props.auth.constants);
        const flagSig = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 : 0;
        let equipmentSel = [];
        for(let row of record.data.equipment){
            let val = this.state.equipmentAll.find(x => x.value === row);
            equipmentSel.push(val);
        }
        let checks = record.data.checks;
        for(let row of checks){
            let equipmentSel = this.state.equipmentAll.find(x => x.value === row.equipment);
            row.equipmentSel = equipmentSel;
            row.form = row.form != null && row.form !== '' ? JSON.parse(row.form) : null;
        }
        let types = [];
        if(equipmentSel != null && equipmentSel.length > 0 && equipmentSel[0].calibrationTypes != null && equipmentSel[0].calibrationTypes.length > 0){
            for(let row of equipmentSel[0].calibrationTypes){
                let val = this.state.allCalibrationTypes.find(x => x.value === row);
                types.push(val);
            }
        }
        let sopIds = [];
        let sopsSel = [];
        let tabLabels = [{name: t('equipmentCalibrationCheck'), disabled: false}];
        if(record.data.sops != null && record.data.sops.length > 0){
            record.data.sops.forEach(sop => {
                sopIds.push(sop.sop);
                let val = this.state.sopList.find(x => x.value === sop.sop);
                sopsSel.push(val);
                tabLabels.push({name: val.label, disabled: false});
            });
        }
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: [performedBySel], name: t('complete'), object: this.state._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: this.validateComplete, signature: record.data.signature,
                objectType: this.props.auth.constants.objectIds.equipmentCalibrationCheck, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        let template = record.data.typeObj != null && record.data.typeObj !== '' ? JSON.parse(record.data.typeObj.form) : '';
        this.setState({
            number: record.data.number,
            date: record.data.date,
            equipment: record.data.equipment,
            equipmentSel: equipmentSel,
            status: record.data.status,
            statusSel: this.state.statuses.find(x => x.value === record.data.status),
            type: record.data.type,
            types: types,
            typeSel: this.state.allCalibrationTypes.find(x => x.value === record.data.type),
            comments: record.data.comments,
            pass: record.data.pass === true || record.data.pass === 'true' ? true : false,
            performedBy: record.data.performedBy,
            performedBySel: performedBySel,
            user: record.data.user,
            documents: record.data.documents,
            flagForSignature: flagSig,
            sopIds: sopIds,
            sopsSel: sopsSel,
            sops: record.data.sops,
            tabLabels: tabLabels,
            location: record.data.location,
            locationSel: this.state.locations.find(x => x.value === record.data.location),
            optionsMenu: optionsMenu,
            sigData: null,
            fieldAccess: fieldAccess,
            signature: record.data.signature,
            renderNum: Math.random(),
            deleteOpen: false,
            checks: checks,
            equipments: record.data.equipmentList,
            template: template
        }, () => {
            const crumbs = [
                { path: '/#/equipmentcalibrationchecks', label: this.props.t('calibrationChecks'), screen: this.props.auth.screenDefs.EquipmentCalibrationChecks},
                { path: 'active', label: this.props.t('calibrationCheck') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.EquipmentCalibrationCheck}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    }

    changeValue = e => {
        const {name, value} = e.target;
        this.setState({[name]: value });
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({[name]: newValue, [data]: newValue != null ? newValue.value : null });
        if (data === 'type'){
            let equipments = this.state.equipments;
            if(newValue != null){
                this.loadType(newValue.value);
            } else {
                equipments = this.state.allEquipments;
            }
            this.setState({equipment: [], equipmentSel: [], checks: [], equipments: equipments, sopIds: [], sops: [], sopsSel: []});
        }
    };

    changeMulti = (name, data) => async (e, newValue) => {
        let checks = this.state.checks;
        let values = [];
        if(newValue != null){
            for(let row of newValue){
                values.push(row.value);
            }
        }
        if(data === 'equipment'){
            if(this.state.typeSel == null && newValue != null && newValue.length > 0){
                let equipRes = await axios.get('/api/equipment/' + newValue[0].value);
                let typeIds = equipRes.data.calibrationTypes != null ? equipRes.data.calibrationTypes : [];
                let types = [];
                for(let row of typeIds){
                    let val = this.state.allCalibrationTypes.find(x => x.value === row);
                    if(val != null){
                        types.push(val);
                    }
                }
                let type = types.length === 1 ? types[0].value : '';
                let typeSel = types.length === 1 ? types[0] : null;
                this.setState({types: types, type: type, typeSel: typeSel}, () => {
                    if(type !== '')
                        this.loadType(type);
                });
            }
            for(let row of newValue){
                let exists = checks.find(x => x.equipment === row.value);
                if(exists == null){
                    checks.push({equipment: row.value, equipmentSel: row, form: this.state.template});
                }
            }
            for(let row of checks){
                let exists = newValue.find(x => x.value === row.equipment);
                if(exists == null){
                    let index = checks.findIndex(x => x.equipment === row.equipment);
                    checks.splice(index, 1);
                }
            }
        }
        this.setState({[name]: newValue, [data]: values, checks: checks});
    };

    loadType = async (type) => {
        let typeRes = await axios.get('/api/equipmentcalibtype/' + type);
        let equipRes = await axios.get('/api/equipmentselect', {params: {calibrationType: type}});
        let sopIds = this.state.sopIds;
        let sops = this.state.sops;
        let sopsSel = this.state.sopsSel;
        let form = typeRes.data.form != null && typeRes.data.form !== '' ? JSON.parse(typeRes.data.form) : '';
        let typeSel = this.state.types.find(x => x.value === type);
        if(typeRes.data.sops != null && typeRes.data.sops.length > 0){
            for(let row of typeRes.data.sops){
                let exists = sopIds.find(x => x === row);
                if(exists == null){
                    let val = this.state.sopList.find(x => x.value === row);
                    if(val != null){
                        sopsSel.push(val);
                        sopIds.push(row);
                        sops.push({
                            sop: row,
                            workRecord: null,
                            original: false
                        });
                    }
                }
            }
        }
        if(this.state.checks != null && this.state.checks.length > 0){
            for(let check of this.state.checks){
                if(check.form === '' || check.form == null){
                    check.form = form;
                }
            }
        }
        this.setState({template: form, sopIds: sopIds, sopsSel: sopsSel, sops: sops, equipments: equipRes.data, type: type, typeSel: typeSel});
    }

    changeDate = (name) => (e) => {
        this.setState({[name]: e });
    }

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.saveClick()})
    };

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._saveClick();
        });
    }

    _saveClick = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid){
            this.setState({enableSave: true});
            return;
        }
        let i = 0;
        let checks = this.state.checks;
        for(let check of checks){
            let form = check.form;
            if(form !== '' && form != null){
                for (let key in check.value){
                    let val = check.value[key];
                    for (let i = 0; i < form.length; i++){
                        let field = form[i];
                        if (field.name === key){
                            field.value = val;
                            break;
                        }
                    }
                }
                let errors = {};
                let error = false;
                for(let row of form){
                    if(row.required === true && (row.value == null || row.value === '')){
                        error = true;
                        break;
                    }
                }
                if(error === true){
                    errors[`${t('form')} (${t('line')} ${i})`] = t('fieldsIncomplete');
                    this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                    this.setState({enableSave: true});
                    return;
                }
            }
            i++
        }
        let checksCopy = JSON.parse(JSON.stringify(checks));
        for(let row of checksCopy){
            row.form = JSON.stringify(row.form);
            row.value = null;
        }
        let data = {
            _id: this.state._id,
            status: this.state.status,
            date: this.state.date,
            equipment: this.state.equipment,
            type: this.state.type,
            comments: this.state.comments,
            pass: this.state.pass,
            performedBy: this.state.performedBy,
            location: this.state.location,
            sigData: this.state.sigData,
            sops: this.state.sops,
            checks: checksCopy
        }
        try {
            let result = await axios.post('/api/equipmentcalibcheck', data);
            if(this.state._id == null || this.state._id === ''){
                this.props.history.replace('/equipmentcalibrationcheck/' + result.data.id);
            } else {
                this.loadRecord();
            }
            this.setState({enableSave: true, sigData: null});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    validate = () => {
        const t = this.props.t;
        const state = this.state;
        let errors = {};
        if(state.date == null || state.date === ''){
            errors[t('date')] = t('required');
        } else {
            let validEndDate = ConversionHelper.validActivityDate(this.state.date, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
            if(!validEndDate){
                errors[t('date')] = t('dateBeforeLock');
            }
        }
        if(state.equipment == null || state.equipment === '')
            errors[t('equipment')] = t('required');
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(state.performedBy == null || state.performedBy === '')
            errors[t('performedBy')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    validateComplete = () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid)
            return t('notAllComplete');
        if(this.state.sops != null && this.state.sops.length > 0){
            for(let i = 0; i < this.state.sops.length; i++){
                let sop = this.state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        return null;
    };

    formValueChanged = (i) => (e) => {
        let checks = this.state.checks;
        checks[i].value = e;
        this.setState({checks: checks});
    };

    changeSop = (e, newValue) => {
        const t = this.props.t;
        let sops = this.state.sops;
        let tabLabels = this.state.tabLabels;
        if(newValue != null){
            if(sops.length > newValue.length){
                for(let i = 0; i < sops.length; i++){
                    let sop = sops[i];
                    let rec = newValue.find(x => x.value === sop.sop);
                    if(!rec){
                        sops.splice(i, 1);
                        let tabIndex = i + 1;
                        tabLabels.splice(tabIndex, 1);
                        break;
                    }
                }
            } else {
                for(let i = 0; i < newValue.length; i++){
                    let val = newValue[i];
                    let rec = sops.find(x => x.sop === val.value)
                    if(!rec){
                        sops.push({
                            sop: val.value,
                            workRecord: null,
                            original: false
                        });
                        if(this.state._id != null && this.state._id !== '')
                            tabLabels.push({name: val.label, disabled: false});
                    }
                }
            }
            let sopIds = [];
            newValue.forEach(val => {
                sopIds.push(val.value);
            });
            this.setState({sopsSel: newValue, sops: sops, sopIds: sopIds, tabLabels: tabLabels});
        } else {
            for(let i = 0; i < sops.length; i++){
                let sop = sops[i];
                if(sop.required)
                    return;
            }
            let tabLabels = [{name: t('equipmentCalibrationCheck'), disabled: false}];
            this.setState({sopsSel: [], sops: [], sopIds: [], tabLabels: tabLabels});
        }
    };

    changeTab = (event, newValue) => {
        this.setState({tabKey: newValue });
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._deleteClick();
        });
    };

    _deleteClick = async () => {
        const t = this.props.t;
        if(this.state._id == null || this.state._id === '' || !this.state.fieldAccess){
            this.setState({enableSave: true, deteleOpen: false});
            this.props.history.goBack();
            return;
        }
        try {
            let result = await axios.delete('/api/equipmentcalibcheck/' + this.state._id);
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            this.props.history.goBack();
        } catch(err){
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    render() {
        const t = this.props.t;
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        fieldAccess={this.state.security.update}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.equipmentCalibrationCheck}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabPanel value={this.state.tabKey} index={0}>
                    <Grid container spacing={3}>
                        {this.state.number != null && this.state.number !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            <FormControl>
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSel != null && this.state.statusSel !== '' ? this.state.statusSel.label : ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.locationSel}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locationSel', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]}
                                    label={t('location')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('location')}</FormLabel>
                                    {this.state.locationSel != null  &&
                                        <Link to={'/location/' + this.state.locationSel.value}>{this.state.locationSel.label}</Link>
                                    }
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <DateSelect
                                    onChange={this.changeDate('date')}
                                    value={this.state.date}
                                    helperText={errors[t('date')]}
                                    error={errors[t('date')] != null ? true : false}
                                    disabled={!fieldAccess}
                                    label={t('date')}
                                    format={this.props.auth.user.dateFormat}
                                    required
                                    data-cy="date"
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('date')}</FormLabel>
                                    <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat} label={t('date')} />
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.typeSel}
                                    options={this.state.types}
                                    onChange={this.changeAuto('typeSel', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]}
                                    label={t('type')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('type')}</FormLabel>
                                    {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    multiple={true}
                                    value={this.state.equipmentSel}
                                    options={this.state.equipments}
                                    onChange={this.changeMulti('equipmentSel', 'equipment')}
                                    error={errors[t('equipment')] != null ? true : false}
                                    helperText={errors[t('equipment')]}
                                    label={t('equipment')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            }
                            {!fieldAccess && this.state.equipmentSel != null && this.state.equipmentSel.length > 0 &&
                                <FormControl>
                                    <FormLabel>{t('equipment')}</FormLabel>
                                    {this.state.equipmentSel != null  &&
                                        <Link to={'/equipment/' + this.state.equipmentSel[0].value}>{this.state.equipmentSel[0].label}</Link>
                                    }
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.performedBySel}
                                    options={this.state.userOptions}
                                    onChange={this.changeAuto('performedBySel', 'performedBy')}
                                    error={errors[t('performedBy')] != null ? true : false}
                                    helperText={errors[t('performedBy')]}
                                    label={t('performedBy')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('performedBy')}</FormLabel>
                                    {this.state.performedBySel != null ? this.state.performedBySel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                disabled={!fieldAccess}
                                control={
                                <Switch
                                    checked={this.state.pass}
                                    onChange={this.changeBool("pass")}
                                    name="pass"
                                />
                                }
                                label={t("pass")}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            {fieldAccess &&
                                <TextField value={this.state.comments} onChange={this.changeValue} name="comments"
                                multiline={true} maxRows="5"
                                size="medium" fullWidth={true} label={t('comments')}
                                error={errors[t('comments')] != null ? true : false} helperText={errors[t('comments')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('comments')}</FormLabel>
                                    {this.state.comments}
                                </FormControl>
                            }
                        </Grid>
                        {this.state.sopList != null && this.state.sopList.length > 0 &&
                            <Grid item xs={11}>
                                <AutoCompleteField
                                    multiple={true} disabled={!fieldAccess}
                                    value={this.state.sopsSel}
                                    options={this.state.sopList}
                                    onChange={this.changeSop}
                                    error={errors[t('sopIds')] != null ? true : false}
                                    helperText={errors[t('sopIds')]}
                                    label={t('workRecs')}
                                    data-cy="sops"
                                />
                            </Grid>
                        }
                    </Grid>
                    <br/>
                    {this.state.checks.map((check, i) =>
                        <Grid container spacing={3} key={'check' + i}>
                            <Grid item sm={12}>
                                <SubSectionHeading title={check.equipmentSel.label} />
                                {check.form !== '' && check.form != null &&
                                    <Aux>
                                        <br/>
                                        {errors[`${t('form')} (${t('line')} ${i})`] != null && errors[`${t('form')} (${t('line')} ${i})`] !== '' &&
                                            <div style={{color: 'red'}}>{errors[`${t('form')} (${t('line')} ${i})`]}</div>
                                        }
                                        <Form renderer={renderer}
                                            defaultFields={check.form}
                                            onChange={this.formValueChanged(i)}
                                            disabled={!fieldAccess}/>
                                    </Aux>
                                }
                                <br/>
                            </Grid>
                        </Grid>
                    )}
                    <FormButtonStack
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.equipmentCalibrationCheck}
                        callback={this.loadRecord}
                        saveClick={() => this.saveClick()}
                        status={this.state.status}
                        permissions={this.props.permission}
                        common={common}
                        constants={this.props.auth.constants}
                        saveEnabled={this.state.enableSave}
                        deleteClick={this.deleteToggle}
                        activityDate={this.state.date}
                        lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                        isSuper={this.props.auth.user.isSuper}
                    />
                </TabPanel>
                {this.state._id != null && this.state._id !== '' &&
                    <Aux>
                        {this.state.sopsSel != null && this.state.sopsSel.length > 0 && this.state.sopsSel.map((sop, i) =>
                            <TabPanel value={this.state.tabKey} index={i + 1} key={'sop' + i}>
                                <ActivityWorkRecord sop={sop.value} id={this.state.sops[i]?.workRecord} users={[this.state.performedBy]} tab={this.state.tabKey}
                                objectType={this.props.auth.constants.objectIds.equipmentCalibrationCheck} recordId={this.state._id} complete={this.loadRecord}
                                locationId={this.state.location} date={this.state.date} renderNum={this.state.renderNum}/>
                            </TabPanel>
                        )}
                    </Aux>
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.equipmentCalibrationCheck}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(EquipmentCalibrationCheck));
