import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import SectionTitle from '../UI/Typography/SectionTitle';
import { RefreshButton, SaveButton } from '../UI/Buttons/Buttons';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Panel from '../UI/Panel/Panel';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import Document from '../General/Document';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import Switch from '../UI/Switch/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

class AdminSettings extends Component {
    constructor(props){
        super(props);
        this.state = {
            id: "",
            certificationsExpire: false,
            certificationExpiration: null,
            sopReviewRequired: false,
            sopReviewMonths: null,
            budWaterActivity: '',
            flowerWaterPercent: '',
            shakeWaterPercent: '',
            defaultDrying: '',
            defaultDryingSel: null,
            dryingTypes: [],
            defaultTrimming: '',
            defaultTrimmingSel: null,
            trimmingTypes: [],
            defaultTrimmer: '',
            defaultTrimmerSel: null,
            equipments: [],
            sops: [],
            cleaningRoomSop: '',
            cleaningRoomSopSel: null,
            documentExpirationDays: '',
            versionNumber: '',
            versionNumberSel: null,
            versionNumbers: [],
            security: [],
            logo: null,
            dateFormat: '',
            dateFormatSel: null,
            locale: '',
            localeSel: null,
            processingHourlyRate: '',
            cultivationHourlyRate: '',
            binFilter: false,
            tabKey: 0,
            tabLabels: [
                {name: this.props.t('general'), disabled: false},
                {name: this.props.t('idPrefixes'), disabled: false}
            ],
            expiryLimits: [{value: 0, label: "0"}, {value: 30, label: "30"},{value: 60, label: "60"}, {value: 90, label: "90"},{value: 120, label: "120"},
                    {value: 150, label: "150"}, {value: 180, label: "180"}, {value: 210, label: "210"}, {value: 240, label: "240"}, {value: 270, label: "270"},
                    {value: 300, label: "300"} ,{value: 330, label: "330"}, {value: 360, label: "360"} ],
            sopReviewOptions: [{value: 0, label: "0"},{value: 3, label: 3},{value: 6, label: "6"},{value: 9, label: "9"}, {value: 12, label: "12"}, 
                    {value: 18, label: "18"}, {value: 24, labeel: "24"},{value: 36, label: "36"}, {value: 48, label: "48"}, {value: 60, label: "60"}],
            ccxCustomer: false,
            ccxClientId: '',
            ccxCompanyCode: '',
            groupBin: false,
            groupContainer: false,
            preInventoryLabel: false,
            siteId: '',
            locationCode: '',
            excludeRetainedValue: false,
            excludeSampleValue: false,
            taxShippingCosts: false,
            verificationDuration: '',
            verifiationDurationSel: null,
            days: [{value: 1, label: "1"}, {value: 2, label: "2"}, {value: 3, label: "3"}, {value: 4, label: "4"}, {value: 5, label: "5"},{value: 6, label: "6"},
                {value: 7, label: "7"},{value: 8, label: "8"},{value: 9, label: "9"},{value: 10, label: "10"}, {value: 11, label: "11"}, {value: 12, label: "12"},
                {value: 13, label: "13"},{value: 14, label: "14"},],
        };
    }

    async componentDidMount(){
        let values = await axios.get('/api/settingvalues');
        this.setState({
            dryingTypes: values.data.dryingTypes,
            trimmingTypes: values.data.trimmingTypes,
            equipments: values.data.equipments,
            sops: values.data.sops,
            versionNumbers: values.data.versionNumbers,
            security: this.props.permission
        }, () => {
            this.loadSettings();
        });
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    expiresChanged = e => {
        this.setState({certificationsExpire: !this.state.certificationsExpire});
    }

    sopReviewChanged = e => {
        this.setState({sopReviewRequired: !this.state.sopReviewRequired});
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    loadSettings = async () => {
        let response = await axios.get('/api/settings');
        let defaultDryingSel = response.data.defaultDrying != null ?
            this.state.dryingTypes.find(x => x.value === response.data.defaultDrying) : null;
        let defaultTrimmingSel = response.data.defaultTrimming != null ?
            this.state.trimmingTypes.find(x => x.value === response.data.defaultTrimming) : null;
        let defaultTrimmerSel = response.data.defaultTrimmer != null ?
            this.state.equipments.find(x => x.value === response.data.defaultTrimmer) : null;
        let cleaningRoomSopSel = response.data.cleaningRoomSop != null ? this.state.sops.find(x => x.value === response.data.cleaningRoomSop) : null;
        let dateFormatSel = this.props.auth.common.dateFormats.find(x => x.value === response.data.dateFormat);
        let localeSel = this.props.auth.common.locales.find(x => x.value === response.data.locale);
        let versionNumberSel = response.data.versionNumber != null ? this.state.versionNumbers.find(x => x.value === response.data.versionNumber) : null;
        let sopReviewMonths = this.state.sopReviewOptions.find(x => x.value === response.data.sopReviewMonths);
        let certificationExpiration = this.state.expiryLimits.find(x => x.value === response.data.certificationExpiration);
        let verificationDurationSel = this.state.days.find(x => x.value === response.data.verificationDuration);
        this.setState({
            id: response.data._id,
            certificationsExpire: response.data.certificationsExpire,
            certExpiration: response.data.certificationExpiration,
            certificationExpiration: certificationExpiration,
            sopReviewRequired: response.data.sopReviewRequired != null ? response.data.sopReviewRequired : false,
            sopReviewMonth: response.data.sopReviewMonths != null ? response.data.sopReviewMonths: 0,
            sopReviewMonths: sopReviewMonths,
            budWaterActivity: response.data.budWaterActivity,
            flowerWaterPercent: response.data.flowerWaterPercent,
            shakeWaterPercent: response.data.shakeWaterPercent,
            cleaningRoomSop: response.data.cleaningRoomSop,
            cleaningRoomSopSel: cleaningRoomSopSel,
            security: this.props.permission,
            defaultDryingSel: defaultDryingSel,
            defaultTrimmingSel: defaultTrimmingSel,
            defaultTrimmer: response.data.defaultTrimmer,
            defaultTrimmerSel: defaultTrimmerSel,
            documentExpirationDays: response.data.documentExpirationDays,
            versionNumber: response.data.versionNumber,
            versionNumberSel: versionNumberSel,
            logo: response.data.logo,
            dateFormat: response.data.dateFormat,
            dateFormatSel: dateFormatSel,
            locale: response.data.locale,
            localeSel: localeSel,
            cultivationHourlyRate: response.data.cultivationHourlyRate,
            processingHourlyRate: response.data.processingHourlyRate,
            ccxCustomer: response.data.ccxCustomer === true || response.data.ccxCustomer === 'true' ? true : false,
            ccxClientId: response.data.ccxClientId,
            ccxCompanyCode: response.data.ccxCompanyCode,
	        binFilter: response.data.binFilter === true ? true : false,
            groupBin: response.data.groupBin === true || response.data.groupBin === 'true' ? true : false,
            groupContainer: response.data.groupContainer === true || response.data.groupContainer === 'true' ? true : false,
            preInventoryLabel: response.data.preInventoryLabel === true || response.data.preInventoryLabel === 'true' ? true : false,
            siteId: response.data.siteId,
            locationCode: response.data.locationCode,
            excludeRetainedValue: response.data.excludeRetainedValue === true || response.data.excludeRetainedValue === 'true' ? true : false,
            excludeSampleValue: response.data.excludeSampleValue === true || response.data.excludeSampleValue === 'true' ? true : false,
            taxShippingCosts: response.data.taxShippingCosts === true || response.data.taxShippingCosts === 'true' ? true : false,
            verificationDuration: response.data.verificationDuration,
            verificationDurationSel: verificationDurationSel
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('settings'), screen: this.props.auth.screenDefs.AdminSettings}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    saveSettings = async e => {
        e.preventDefault();
        const valid = this.validate();
        if(!valid)
            return;
        const settings = {
            _id: this.state.id,
            certificationsExpire: this.state.certificationsExpire,
            certificationExpiration: this.state.certExpiration,
            sopReviewRequired: this.state.sopReviewRequired,
            sopReviewMonths: this.state.sopReviewMonth,
            budWaterActivity: this.state.budWaterActivity,
            flowerWaterPercent: this.state.flowerWaterPercent,
            shakeWaterPercent: this.state.shakeWaterPercent,
            cleaningRoomSop: this.state.cleaningRoomSop,
            defaultDrying: this.state.defaultDrying,
            defaultTrimming: this.state.defaultTrimming,
            defaultTrimmer: this.state.defaultTrimmer,
            documentExpirationDays: this.state.documentExpirationDays,
            versionNumber: this.state.versionNumber,
            dateFormat: this.state.dateFormat,
            locale: this.state.locale,
            processingHourlyRate: this.state.processingHourlyRate,
            cultivationHourlyRate: this.state.cultivationHourlyRate,
            ccxCustomer: this.state.ccxCustomer,
            ccxClientId: this.state.ccxClientId,
            ccxCompanyCode: this.state.ccxCompanyCode, 
	        binFilter: this.state.binFilter,
            groupBin: this.state.groupBin,
            groupContainer: this.state.groupContainer,
            preInventoryLabel: this.state.preInventoryLabel,
            siteId: this.state.siteId,
            locationCode: this.state.locationCode,
            excludeRetainedValue: this.state.excludeRetainedValue,
            excludeSampleValue: this.state.excludeSampleValue,
            taxShippingCosts: this.state.taxShippingCosts,
            verificationDuration: this.state.verificationDuration
        }
        axios.post('/api/settings', settings)
        .then(response => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: response.data.message, title: this.props.t('success'), severity: 'success'}});
        })
        .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        })
    }

    validate = () => {
        let errors = {};
        const t = this.props.t;
        if(this.state.dateFormat == null || this.state.dateFormat === '')
            errors[t('dateFormat')] = t('required');
        if(this.state.locale == null || this.state.locale === '')
            errors[t('locale')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    refreshSettings = e => {
        e.preventDefault();
        this.loadSettings();
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    render() {
        const errors = this.props.errors;
        const security = this.state.security;
        const t = this.props.t;
        return (
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Panel value={this.state.tabKey} index={0}>
                    <SectionTitle title={t('basic')}/>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                            value={this.state.dateFormatSel}
                            options={this.props.auth.common.dateFormats}
                            onChange={this.changeAuto('dateFormatSel', 'dateFormat')}
                            error={errors[t('dateFormat')] != null ? true : false}
                            helperText={errors[t('dateFormat')]}
                            label="Date Format"
                            disabled={!security.update || !security.complete}
                            required
                            data-cy='dateFormat'
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                            value={this.state.localeSel}
                            options={this.props.auth.common.locales}
                            onChange={this.changeAuto('localeSel', 'locale')}
                            error={errors[t('locale')] != null ? true : false}
                            helperText={errors[t('locale')]}
                            label="Locale"
                            disabled={!security.update || !security.complete}
                            required
                            data-cy='locale'
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.siteId} onChange={this.changeValue} name="siteId"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('siteId')} data-cy='siteId'
                            error={errors[t('siteId')] != null ? true : false} helperText={errors[t('siteId')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.locationCode} onChange={this.changeValue} name="locationCode"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('locationCode')} data-cy='locationCode'
                            error={errors[t('locationCode')] != null ? true : false} helperText={errors[t('locationCode')]}/>
                        </Grid>
                    </Grid>
                    <Document record={this.state.id} documents={this.state.logo != null ? [this.state.logo] : null} title={t('logo')}
                                 callback={this.loadSettings} objectType={this.props.auth.constants.objectIds.settings}/>
                    <SectionTitle title={t('ccx')}/>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                disabled={false}
                                control={<Switch checked={this.state.ccxCustomer} onChange={this.changeBool('ccxCustomer')} name="ccxCustomer" />}
                                label={t('ccxCustomer')} 
                            />
                        </Grid>
                        {/*<Grid item xs={4} sm={2}>
                            <TextField value={this.state.ccxClientId} onChange={this.changeValue} name="ccxClientId"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('ccxClientId')} data-cy='ccxClientId'
                            error={errors[t('ccxClientId')] != null ? true : false} helperText={errors[t('ccxClientId')]}/>
                        </Grid>*/}
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.ccxCompanyCode} onChange={this.changeValue} name="ccxCompanyCode"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('ccxCompanyCode')} data-cy='ccxCompanyCode'
                            error={errors[t('ccxCompanyCode')] != null ? true : false} helperText={errors[t('ccxCompanyCode')]}/>
                        </Grid>
                    </Grid>
                    <SectionTitle title={t('curingSettings')}/>
                    <Grid container spacing={3}>
                        <Grid item xs={8} sm={3}>
                            <TextField value={this.state.flowerWaterPercent} onChange={this.changeNumber} name="flowerWaterPercent"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('flowerWaterPercent')} data-cy='flowerWaterPercent'
                            error={errors[t('flowerWaterPercent')] != null ? true : false} helperText={errors[t('flowerWaterPercent')]}/>
                        </Grid>
                        <Grid item xs={8} sm={3}>
                            <TextField value={this.state.shakeWaterPercent} onChange={this.changeNumber} name="shakeWaterPercent"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('shakeWaterPercent')} data-cy='shakeWaterPercent'
                            error={errors[t('shakeWaterPercent')] != null ? true : false} helperText={errors[t('shakeWaterPercent')]}/>
                        </Grid>
                        <Grid item xs={8} sm={3}>
                            <TextField value={this.state.budWaterActivity} onChange={this.changeNumber} name="budWaterActivity"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('waterActivityAmount')} data-cy='waterActivityAmount'
                            error={errors[t('budWaterActivity')] != null ? true : false} helperText={errors[t('budWaterActivity')]}/>
                        </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title={t('sopUserCert')}/>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                disabled={false}
                                control={<Switch checked={this.state.certificationsExpire} onChange={this.changeBool('certificationsExpire')} name="certificationsExpire" />}
                                label={t('certExpires')} 
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.certificationExpiration}
                                options={this.state.expiryLimits}
                                onChange={this.changeAuto('certificationExpiration', 'certExpiration')}
                                helperText={errors[t('certificationExpiration')]}
                                error={errors[t('certificationExpiration')] != null ? true : false}
                                label={t('certExpireDays')}
                                disabled={!security.update && !this.state.certificationsExpire}
                                required
                                data-cy='certificationExpiration'
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title={t('documentReview')}/>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                disabled={false}
                                control={<Switch checked={this.state.sopReviewRequired} onChange={this.changeBool('sopReviewRequired')} name="sopReviewRequired" />}
                                label={t('reviewRequired')} 
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <AutoCompleteField
                                value={this.state.sopReviewMonths}
                                options={this.state.sopReviewOptions}
                                onChange={this.changeAuto('sopReviewMonths', 'sopReviewMonth')}
                                helperText={errors[t('sopReviewMonths')]}
                                error={errors[t('sopReviewMonths')] != null ? true : false}
                                label={t('sopReviewInterval')}
                                disabled={!security.update && !this.state.sopReviewRequired}
                                required
                                data-cy='sopReviewMonths'
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.versionNumberSel}
                                    options={this.state.versionNumbers}
                                    onChange={this.changeAuto('versionNumberSel', 'versionNumber')}
                                    error={errors[t('versionNumber')] != null ? true : false}
                                    helperText={errors[t('versionNumber')]}
                                    label={t('documentVersionFormat')}
                                    disabled={!security.update}
                                    data-cy='versionNumber'
                                />
                            </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title={t('productionPlanningSettings')}/>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.defaultDryingSel}
                                options={this.state.dryingTypes}
                                onChange={this.changeAuto('defaultDryingSel', 'defaultDrying')}
                                error={errors[t('defaultDrying')] != null ? true : false}
                                helperText={errors[t('defaultDrying')]} 
                                label={t('defaultDryingType')}
                                disabled={!security.update}
                                data-cy='defaultDrying'
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.defaultTrimmingSel}
                                options={this.state.trimmingTypes}
                                onChange={this.changeAuto('defaultTrimmingSel', 'defaultTrimming')}
                                error={errors[t('defaultTrimming')] != null ? true : false}
                                helperText={errors[t('defaultTrimming')]} 
                                label={t('defaultTrimType')}
                                disabled={!security.update}
                                data-cy='defaultTrimming'
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.defaultTrimmerSel}
                                options={this.state.equipments}
                                onChange={this.changeAuto('defaultTrimmerSel', 'defaultTrimmer')}
                                error={errors[t('defaultTrimmer')] != null ? true : false}
                                helperText={errors[t('defaultTrimmer')]} 
                                label={t('defaultTrimmer')}
                                disabled={!security.update}
                                data-cy='defaultTrimmer'
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title="Other"/>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.cleaningRoomSopSel}
                                options={this.state.sops}
                                onChange={this.changeAuto('cleaningRoomSopSel', 'cleaningRoomSop')}
                                error={errors[t('cleaningRoomSop')] != null ? true : false}
                                helperText={errors[t('cleaningRoomSop')]} 
                                label={t('cleaningRoomSOP')}
                                disabled={!security.update}
                                data-cy='cleaningRoomSOP'
                            />
                        </Grid>
                        <Grid item xs={8} sm={3}>
                            <TextField value={this.state.documentExpirationDays} onChange={this.changeNumber} name="documentExpirationDays"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('documentExpDays')} data-cy='documentExpirationDays'
                            error={errors[t('documentExpDays')] != null ? true : false} helperText={errors[t('documentExpDays')]}/>
                        </Grid>
                        <Grid item xs={8} sm={3}>
                            <TextField value={this.state.cultivationHourlyRate} onChange={this.changeNumber} name="cultivationHourlyRate"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('hourlyCultivation')} data-cy='cultivationHourlyRate'
                            error={errors[t('cultivationHourlyRate')] != null ? true : false} helperText={errors[t('cultivationHourlyRate')]}/>
                        </Grid>
                        <Grid item xs={8} sm={3}>
                            <TextField value={this.state.processingHourlyRate} onChange={this.changeNumber} name="processingHourlyRate"
                            size="medium" fullWidth={true} disabled={!security.update} label={t('hourlyProcessing')} data-cy='processingHourlyRate'
                            error={errors[t('processingHourlyRate')] != null ? true : false} helperText={errors[t('processingHourlyRate')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                disabled={false}
                                control={<Switch checked={this.state.binFilter} onChange={this.changeBool('binFilter')} name="binFilter" />}
                                label={t('binFilter')} 
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                disabled={false}
                                control={<Switch checked={this.state.groupBin} onChange={this.changeBool('groupBin')} name="groupBin" />}
                                label={t('groupBin')} 
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                disabled={false}
                                control={<Switch checked={this.state.groupContainer} onChange={this.changeBool('groupContainer')} name="groupContainer" />}
                                label={t('groupContainer')} 
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                disabled={false}
                                control={<Switch checked={this.state.preInventoryLabel} onChange={this.changeBool('preInventoryLabel')} name="preInventoryLabel" />}
                                label={t('preInventoryLabel')} 
                            />
                        </Grid>
                        <Grid item xs={10} sm={4}>
                            <FormControlLabel
                                disabled={false}
                                control={<Switch checked={this.state.excludeRetainedValue} onChange={this.changeBool('excludeRetainedValue')} name="excludeRetainedValue" />}
                                label={t('excludeRetainedB300')} 
                            />
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <FormControlLabel
                                disabled={false}
                                control={<Switch checked={this.state.excludeSampleValue} onChange={this.changeBool('excludeSampleValue')} name="excludeSampleValue" />}
                                label={t('exclueSampleB300')} 
                            />
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <FormControlLabel
                                disabled={false}
                                control={<Switch checked={this.state.taxShippingCosts} onChange={this.changeBool('taxShippingCosts')} name="taxShippingCosts" />}
                                label={t('taxShippingCosts')} 
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.verificationDurationSel}
                                options={this.state.days}
                                onChange={this.changeAuto('verificationDurationSel', 'verificationDuration')}
                                error={errors[t('verificationDuration')] != null ? true : false}
                                helperText={errors[t('verificationDuration')]} 
                                label={t('verificationDuration')}
                                disabled={!security.update}
                                data-cy='verificationDuration'
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={4}>
                            <SaveButton saveClick={this.saveSettings} enabled={security.update || security.complete} data-cy='save'/>
                            <RefreshButton refreshClick={this.refreshSettings} enabled={true} data-cy='refresh'/>
                        </Grid>
                    </Grid>
                </Panel>
            </Aux>
        )
    }

}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(AdminSettings));
