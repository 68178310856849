import { RESET_NOTIFICATION, SET_NOTIFICATION_COUNT } from '../actions/types';

const initialState = {
    count: '',
    lastFive: []
}

export default function notificationReducer(state = initialState, action){
    switch(action.type){
        case SET_NOTIFICATION_COUNT: {
            return {
                ...state,
                count: action.payload.count.count,
                lastFive: action.payload.count.lastFive
            }
        }
        case RESET_NOTIFICATION: {
            return initialState;
        }
        default:
            return state;
    }
}