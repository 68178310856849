import React, { useEffect } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import RichText from '../../hoc/RichText/RichText';
import common from '../../jsons/common.json';
import TablePagination from '@mui/material/TablePagination';

const Announcements = (props) => {
    const [list, setList] = React.useState([]);
    const [displayList, setDisplayList] = React.useState([]);
    const [announcement, setAnnouncement] = React.useState(null);
    const [totalCount, setTotalCount] = React.useState(0);
    const [listSize, setListSize] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation('translation');


    useEffect(() => {
        async function fetchData(){
            let sort = {'endDate': -1};
            let data = {sort: JSON.stringify(sort), type: props.type};
            let list = await axios.get('/api/announcementsdisplay', {params: data});
            setList(list.data.data);
            setDisplayList(list.data.data.length > 0 ? list.data.data.slice(0, listSize) : []);
            setTotalCount(list.data.totalCount);
        }
        if(props.type != null && props.type !== '')
            fetchData();
    }, [props.type, listSize]);

    const rowClick = (i) => {
        setAnnouncement(list[i]);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setAnnouncement(null);
    }

    const sliceList = (newPage) => {
        let newList = list.slice(listSize * newPage, listSize * (newPage + 1));
        setDisplayList(newList);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        sliceList(newPage);
    }

    const handleRowsPerPage = e => {
        setListSize(parseInt(e.target.value));
        sliceList(0);
    };
    

    return(
        <Aux>
            {list != null && list.length > 0 &&
                <Aux>
                    <SubSectionHeading title={t('announcements')} />
                    {displayList.map((row, i) => 
                        <Grid container spacing={1} key={row._id}>
                            <Grid item sm={10}>
                                <TextOnlyClickButton 
                                    click={() => rowClick(i)}
                                    color={row.priority === common.announcementPriorities.high ? 'error' : 'primary'}
                                    name={row.title} 
                                    enabled={true} 
                                    data-cy={'announcement' + i}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {totalCount > listSize &&
                        <TablePagination count={list.length}
                            rowsPerPage={listSize}
                            onPageChange={handleChangePage}
                            page = {page}
                            rowsPerPageOptions={common.rowOptions}
                            onRowsPerPageChange={handleRowsPerPage}
                        />
                    }
                </Aux>
            }
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
                <DialogTitle>
                    <SubSectionHeading title={announcement != null ? announcement.title : ''} />
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            <RichText>{announcement != null ? announcement.body : ''}</RichText>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Aux>
    )
}

export default Announcements;