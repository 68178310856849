import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import Aux from '../../hoc/Auxilary/Auxilary';
import Button from '@mui/material/Button';
import base64 from 'base-64';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import SectionTitle from '../UI/Typography/SectionTitle';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { FormControl, FormLabel } from '@mui/material';
import ModalDialog from '../UI/Dialog/ModalDialog';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { withTranslation } from 'react-i18next';
import DateSelect from '../UI/DateSelect/DateSelect';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

class Management extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            hcTemplateObj: null,
            craTemplateObj: null,
            craTemplate: '',
            hcTemplate: '',
            enableSave: true,
            nullRecords: [],
            items: [],
            itemSel: null,
            item: '',
            uom: '',
            uomSel: null,
            uoms: [],
            changeUOMResult: null,
            showItemUOM: false,
            uomChanges: [],
            origUOM: null,
            showChange: false,
            changeIndex: -1,
            change: null,
            countItem: '',
            countItemSel: null,
            showCount: false,
            inventoryitem: '',
            inventoryItemSel: null,
            inventoryDate: '',
            showItemGenerate: false,
            allTenants: false,
            useLastUpdated: false,
            usePostingDate: true
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.item, common.cacheValues.quantityUOM], this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({
            items: cacheValues.items,
            uoms: cacheValues.quantityUOMs
        })
        this.loadRecord();
    }

    loadRecord = async () => {
        let record = await axios.get('/int/management');
        let changeResult = await axios.get('/int/uomchanges');
        let uomChanges = changeResult.data;
        let storage = sessionStorage.getItem('exciseCounts');
        const t = this.props.t;
        if(uomChanges != null){
            for(let row of uomChanges){
                row.result.showLedgers = false;
                row.result.showLots = false;
                row.result.showContainers = false;
                row.result.showReceipts = false;
                row.result.showShipments = false;
                row.result.showProductionOrders = false;
                row.result.showProductionBatches = false;
                row.result.showBatchActivities = false;
                row.result.showDestructions = false;
                row.result.showDryingOrders = false;
                row.result.showDryingActivities = false;
                row.result.showTrimmingOrders = false;
                row.result.showTrimmingActivities = false;
                row.result.showCurings = false;
                row.result.showCuringActivities = false;
                row.result.showPurchaseOrders = false;
                row.result.showSales = false;
                row.result.showCropTreatments = false;
                row.result.showPesticidePreparations = false;
                row.result.showRoomFeedings = false;
                row.result.showFeedingLots = false;
                row.result.showRoomCleanings = false;
                row.result.showCleaningSolutions = false;
                row.result.showFeedingRecipes = false;
                row.result.showProductionBoms = false;
                row.result.showCultivationBatches = false;
                row.result.showEquipmentLogs = false;
                row.result.showMaintenanceRequests = false;
            }
        }
        this.setState({
            _id: record.data._id,
            hcTemplateObj: record.data.hcTemplate,
            craTemplateObj: record.data.craTemplate,
            hcTemplate: record.data.hcTemplate != null ? record.data.hcTemplate._id : '',
            craTemplate: record.data.craTemplate != null ? record.data.craTemplate._id : '',
	        uomChanges: uomChanges != null ? uomChanges : [],
            stampRes: storage != null ? JSON.parse(storage) : null
        }, () => {
            const crumbs = [
                { path: 'active', label: t('management'), screen: this.props.auth.screenDefs.Management}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    generateJSONs = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._generateJSONs(e);
        });
    };

    _generateJSONs = async (e) => {
        try {
            let result = await axios.get('/int/generatealljsons');
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
        }
    };

    handleFileUpload = (name) => (file) => {
        let files = file.target.files;
        let reader = new FileReader();
        reader.readAsBinaryString(files[0]);
        reader.onload = async () => {
            let vals = {file: base64.encode(reader.result), fileName: files[0].name, type: files[0].type};
            try {
                let data = await axios.post('/api/documents/add', vals);
                let docId = data.data.id;
                let postData = {
                    _id: this.state._id,
                    hcTemplate: name === 'hcTemplate' ? docId : null,
                    craTemplate: name === 'craTemplate' ? docId : null
                };
                let result = await axios.post('/int/managementsavedoc', postData);
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            } catch(err){
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
            }
        }
    }

    clearCaches = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._clearCaches();
        });
    }

    _clearCaches = async () => {
        try {
            let result = await axios.post('/int/tenantcacheclear', {allTenants: true});
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
        }
    };

    findNullNumbers = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._findNullNumbers();
        });
    };

    _findNullNumbers = async () => {
        try {
            let result = await axios.get('/int/nullidreport');
            this.setState({nullRecords: result.data, enableSave: true})
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
        }
    }

    fixNulNumbers = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._fixNulNumbers();
        });
    }

    _fixNulNumbers = async () => {
        try {
            let result = await axios.post('/int/fixnullids', this.state.nullRecords);
            await this._findNullNumbers();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
        }
    }

    toggleUOM = () => {
        this.setState({showItemUOM: !this.state.showItemUOM});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({[name]: newValue, [data]: newValue != null ? newValue.value : ''}, () => {
            if(data === 'item'){
                if(newValue != null){
                    let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                    this.setState({origUOM: uomSel});
                } else {
                    this.setState({uomSel: null});
                }
            }
        });
    }

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    }

    doUOM = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, async () => {
            if(this.state.item == null || this.state.item === ''){
                this.setState({enableSave: true});
                alert('item required');
                return;
            } 
            if(this.state.uom == null || this.state.uom === ''){
                this.setState({enableSave: true});
                alert('UOM required');
                return
            }
            if(this.state.itemSel.uom === this.state.uom){
                this.setState({enableSave: true});
                alert('That is the same UOM as it has already');
                return;
            }
            if(this.state.origUOM == null){
                alert('Original UOM is required, is item selected?')
            }
            try {
                let data = {item: this.state.item, uom: this.state.uom, origUOM: this.state.origUOM.value};
                let result = await axios.post('/int/changeitemuom', data);
                this.setState({changeUOMResult: result.data.result, enableSave: true, item: '', itemSel: null, uom: '', uomSel: null});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
            }
        });
    }

    toggleChange = (e) => {
        this.setState({showChange: false, changeIndex: -1, change: null});
    }

    showChange(i){
        this.setState({showChange: true, changeIndex: i, change: this.state.uomChanges[i].result});
    }

    showDetails = (name) => (e) => {
        let change = this.state.change;
        change[name] = !change[name];
        this.setState({change: change});
    }

    fixProductionOrders = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._fixProductionOrders();
        });
    }

    _fixProductionOrders = async () => {
        try {
            let result = await axios.post('/int/fixproductionorders');
            this.setState({enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            });
        } catch(err){
            this.setState({enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
            });
        }
    }

    toggleCount = () => {
        this.setState({showCount: !this.state.showCount});
    }

    saveCount = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._saveCount();
        });
    }

    _saveCount = async () => {
        try{
            let result = await axios.post('/int/clearitemcount', {item: this.state.countItem});
            this.setState({enableSave: true, showCount: false, countItem: '', countItemSel: null}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            });
        } catch(err){
            this.setState({enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
            });
        }
    }

    toggleInventory = () => {
        this.setState({showItemGenerate: !this.state.showItemGenerate});
    }

    generateInventory = () => {
        if(this.state.usePostingDate === true && this.state.useLastUpdated === true){
            alert('Cannot use both posting date and last updated date');
            return;
        }
        if(this.state.usePostingDate === false && this.state.useLastUpdated === false){
            alert('Must use either posting date or last updated date');
            return;
        }
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._generateInventory();
        });
    }

    _generateInventory = async () => {
        try {
            let result = await axios.post('/int/inventorygenerate', {item: this.state.inventoryItem, date: this.state.inventoryDate, allTenants: this.state.allTenants, usePostingDate: this.state.usePostingDate, useLastUpdated: this.state.useLastUpdated});
            this.setState({enableSave: true, showItemGenerate: false, tenant: '', inventoryItem: '', inventoryItemSel: null, inventoryDate: ''}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            });
        } catch(err){
            this.setState({enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
            });
        }
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    tenantTotals = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._tenantTotals();
        });
    }

    _tenantTotals = async () => {
        try {
            let result = await axios.post('/int/tenanttotalgenerate');
            this.setState({enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            });
        } catch(err){
            this.setState({enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
            });
        }
    }

    render(){
        return(
            <Aux>
                <BreadcrumbBar />
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <Button variant="contained" color="primary" size="small" onClick={this.generateJSONs} disabled={!this.state.enableSave}>Generate Tenant JSONs</Button>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Button variant="contained" color="primary" size="small" onClick={this.clearCaches} disabled={!this.state.enableSave}>Clear all tenant Caches</Button>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Button variant="contained" color="primary" size="small" onClick={this.toggleInventory} disabled={!this.state.enableSave}>Generate Inventory</Button>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Button variant="contained" color="primary" size="small" onClick={this.tenantTotals} disabled={!this.state.enableSave}>Generate Tenant Totals</Button>
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <label>Health Canada Template</label>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <input type="file" onChange={this.handleFileUpload('hcTemplate')} multiple={false}/>
                    </Grid>
                    {this.state.hcTemplateObj != null &&
                        <Grid item xs={6} sm={4}>
                            {this.state.hcTemplateObj.name}
                        </Grid>
                    }
                </Grid>
                <br/>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <label>CRA Template</label>
                    </Grid>
                    <Grid item xs={4} sm={3}>
                        <input type="file" onChange={this.handleFileUpload('craTemplate')} multiple={false}/>
                    </Grid>
                    {this.state.craTemplateObj != null &&
                        <Grid item xs={6} sm={4}>
                            {this.state.craTemplateObj.name}
                        </Grid>
                    }
                </Grid>
                <br/>
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <Button variant="contained" color="primary" disabled={!this.state.enableSave} onClick={this.findNullNumbers}>Find Null Numbers</Button>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Button variant="contained" color="primary" onClick={this.toggleUOM} disabled={!this.state.enableSave}>Change Item UOM</Button>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Button variant="contained" color="primary" onClick={this.fixProductionOrders} disabled={!this.state.enableSave}>Relabel Production Activities</Button>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Button variant="contained" color="primary" onClick={this.toggleCount} disabled={!this.state.enableSave}>Clear Item Count</Button>
                    </Grid>
                </Grid>
                {this.state.uomChanges != null && this.state.uomChanges.length > 0 &&
                    <Aux>
                        <br/>
                        <SectionTitle title="UOMs Changed"/>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow key='uomhead'>
                                        <TableCell>Item</TableCell>
                                        <TableCell>New UOM</TableCell>
                                        <TableCell>Old UOM</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>User</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.uomChanges.map((row, i) =>
                                        <TableRow key={row._id}>
                                            <TableCell>{row.item != null && <Link to={'/item/' + row.item._id}>{row.item.number + '-' + row.item.name}</Link>}</TableCell>
                                            <TableCell>{row.uom}</TableCell>
                                            <TableCell>{row.originalUom}</TableCell>
                                            <TableCell>
                                                <DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/>
                                            </TableCell>
                                            <TableCell>{row.user != null ? row.user.firstName + ' ' + row.user.lastName : ''}</TableCell>
                                            <TableCell><Button variant='text' size='medium' color='primary' onClick={() => this.showChange(i)}>Show</Button></TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Aux>
                }
                {this.state.nullRecords != null && this.state.nullRecords.length > 0 &&
                    <Aux>
                        <SectionTitle title="Null records"/>
                        <br/>
                        {this.state.nullRecords.map((row, i) =>
                            <Aux key={'aux' + row.id}>
                                <Grid container spacing={3} key={row.id}>
                                    <Grid item xs={10} sm={5}>{row.name}</Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={10} sm={5}>
                                        Table
                                    </Grid>
                                </Grid>
                                {row.tables.map((table, j) =>
                                    <Grid container spacing={3} key={'tables' + i + 'a' + j}>
                                        <Grid item xs={6} sm={3}>{table.table}</Grid>
                                        <Grid item xs={4} sm={2}>{table.count}</Grid>
                                    </Grid>
                                )}
                                <br/>
                            </Aux>
                        )}
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item xs={8} sm={4}>
                                <Button variant="contained" color="secondary" disabled={!this.state.enableSave} onClick={this.fixNulNumbers}>Fix Null Numbers</Button>
                            </Grid>
                        </Grid>
                    </Aux>
                }
                {this.state.showItemUOM === true &&
                    <Aux>
                        <br/>
                        <div
                            >Weight to Weight (g, mg, kg) or Volume to Volume (ml, L) will convert quantity. If anything else such as Weight to Volume or 
                            Each to Weight will only change the UOM and leave the quantities alone
                         </div>
                        <SectionTitle title="Change UOM"/>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={4}>
                                <AutoCompleteField
                                    value={this.state.itemSel}
                                    options={this.state.items}
                                    onChange={this.changeAuto('itemSel', 'item')}
                                    label='Item'
                                />
                            </Grid>
                            {this.state.origUOM != null &&
                                <Grid item sm={2} xs={1}>
                                    <FormControl>
                                        <FormLabel>Current UOM</FormLabel>
                                        {this.state.origUOM.label}
                                    </FormControl>
                                </Grid>
                            }
                            <Grid item xs={4} sm={2}>
                                <AutoCompleteField
                                    value={this.state.uomSel}
                                    options={this.state.uoms}
                                    onChange={this.changeAuto('uomSel', 'uom')}
                                    label='UOM'
                                />
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item sm={12}>
                                <Button variant="contained" color="secondary" disabled={!this.state.enableSave} onClick={this.doUOM}>Change UOM</Button>
                            </Grid>
                        </Grid>
                        <br/>
                        {this.state.changeUOMResult != null &&
                            <Aux>
                                <SubSectionHeading title="Records changed"/>
                                <Grid container spacing={3}>
                                    <Grid item sm={4}><b>Name</b></Grid>
                                    <Grid item sm={1}><b>Count</b></Grid>
                                </Grid>
                                {this.state.changeUOMResult.itemLedgers.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Item Ledgers</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.itemLedgers.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.lots.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Lots</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.lots.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.containers.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Containers</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.containers.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.receipts.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Receipts</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.receipts.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.shipments.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Shipments</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.shipments.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.batchActivities.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Batch Activities</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.batchActivities.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.cultivationBatches.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Cultivation Batches</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.cultivationBatches.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.destructions.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Destructions</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.destructions.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.cleaningSolutions.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Cleaning Solutions</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.cleaningSolutions.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.productionOrders.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Production Orders</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.productionOrders.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.productionBatches.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Production Batches</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.productionBatches.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.curingActivities.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Curing Activities</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.curingActivities.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.curings.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Curings</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.curings.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.dryingActivities.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Drying Activities</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.dryingActivities.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.dryingOrders.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Drying Orders</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.dryingOrders.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.trimmingActivities.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Trimming Activities</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.trimmingActivities.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.trimmingOrders.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Trimming Orders</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.trimmingOrders.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.feedingLots.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Feeding Lots</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.feedingLots.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.pesticidePreparations.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Pesticide Preparations</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.pesticidePreparations.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.roomCleanings.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Room Cleanings</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.roomCleanings.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.cropTreatments.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Crop Treatments</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.cropTreatments.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.roomFeedings.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Room Feedings</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.roomFeedings.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.sales.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Sales</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.sales.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.purchaseOrders.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Purchase Orders</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.purchaseOrders.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.feedingRecipes.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Feeding Recipes</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.feedingRecipes.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.productionBoms.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Production BOMs</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.productionBoms.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.equipmentLogs != null && this.state.changeUOMResult.equipmentLogs.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Maintenance Logs</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.equipmentLogs.length}</Grid>
                                    </Grid>
                                }
                                {this.state.changeUOMResult.maintenanceRewuests != null && this.state.changeUOMResult.maintenanceRequests.length > 0 &&
                                    <Grid container spacing={3}>
                                        <Grid item sm={4}>Service Requests</Grid>
                                        <Grid item sm={1}>{this.state.changeUOMResult.maintenanceRequests.length}</Grid>
                                    </Grid>
                                }
                            </Aux>
                        }
                    </Aux>
                }
                <ModalDialog buttonStack={(<Button color="secondary" size="small" variant='text' onClick={this.toggleChange}>Close</Button>
                )} title="UOM change" toggleDialog={this.toggleChange}
                                dialogStatus={this.state.showChange} fullWidth maxWidth='md'>
                    <Aux>
                        {this.state.change != null &&
                            <Aux>
                                {this.state.change.itemLedgers != null && this.state.change.itemLedgers.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Item Ledgers: ' + this.state.change.itemLedgers.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showLedgers')}>
                                                    {this.state.change.showLedgers === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showLedgers &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='ilhead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.itemLedgers.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/itemledger/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                 {this.state.change.lots != null && this.state.change.lots.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Lots: ' + this.state.change.lots.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showLots')}>
                                                    {this.state.change.showLots === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showLots &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='lothead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.lots.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/lot/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                 {this.state.change.containers != null && this.state.change.containers.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Containers: ' + this.state.change.containers.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showContainers')}>
                                                    {this.state.change.showContainers === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showContainers &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='containerhead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.containers.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/container/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.receipts != null && this.state.change.receipts.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Receipts: ' + this.state.change.receipts.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showReceipts')}>
                                                    {this.state.change.showReceipts === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showReceipts &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='receipthead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.receipts.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/receipt/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.shipments != null && this.state.change.shipments.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Shipments: ' + this.state.change.shipments.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showShipments')}>
                                                    {this.state.change.showShipments === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showShipments &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='shipmenthead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.shipments.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/shipment/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.productionOrders != null && this.state.change.productionOrders.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Production Orders: ' + this.state.change.productionOrders.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showProductinoOrders')}>
                                                    {this.state.change.showProductinoOrders === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showProductinoOrders &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='prodordhead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.productionOrders.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/eproductionorder/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.productinoBatches != null && this.state.change.productionBatches.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title= {'Production Batches: ' + this.state.change.productionBatches.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showProductionBatches')}>
                                                    {this.state.change.showProductionBatches === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showProductionBatches &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='pbatchdhead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.productionBatches.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/productionbatch/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.cultivationBatches != null && this.state.change.cultivationBatches.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Cultivation Batches: ' + this.state.change.cultivationBatches.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showCultivationBatches')}>
                                                    {this.state.change.showCultivationBatches === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showCultivationBatches &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='cbatchdhead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.cultivationBatches.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/cultivationbatch/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.batchActivities != null && this.state.change.batchActivities.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Batch Activities: ' + this.state.change.batchActivities.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showBatchActivities')}>
                                                    {this.state.change.showBatchActivities === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showBatchActivities &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='batchacthead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.batchActivities.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/batchactivity/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.destructions != null && this.state.change.destructions.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Destructions: ' + this.state.change.destructions.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showDestructions')}>
                                                    {this.state.change.showDestructions === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showDestructions &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='desthead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.destructions.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/destruction/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.dryingOrders != null && this.state.change.dryingOrders.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Dryig Orders: ' + this.state.change.dryingOrders.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showDryingOrders')}>
                                                    {this.state.change.showDryingOrders === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showDryingOrders &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='dohead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.dryingOrders.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/dryingorder/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.dryingActivities != null && this.state.change.dryingActivities.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Drying Activities: ' + this.state.change.dryingActivities.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showDryingActivities')}>
                                                    {this.state.change.showDryingActivities === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showDryingActivities &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='dacthead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.dryingActivities.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/dryingactivity/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.trimmingOrders != null && this.state.change.trimmingOrders.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Trimming orders: ' + this.state.change.trimmingOrders.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showTrimmingOrders')}>
                                                    {this.state.change.showTrimmingOrders === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showTrimmingOrders &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='tohead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.trimmingOrders.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/trimmingorder/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.trimmingActivities != null && this.state.change.trimmingActivities.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Trimming Activities: ' + this.state.change.trimmingActivities.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showTrimmingActivities')}>
                                                    {this.state.change.showTrimmingActivities === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showTrimmingActivities &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='tacthead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.trimmingActivities.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/trimmingactivity/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.curings != null && this.state.change.curings.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Curings: ' + this.state.change.curings.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showCurings')}>
                                                    {this.state.change.showCurings === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showCurings &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='curehead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.curings.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/curing/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.curingActivities != null && this.state.change.curingActivities.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Curing Activities: ' + this.state.change.curingActivities.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showCuringActivities')}>
                                                    {this.state.change.showCuringActivities === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showCuringActivities &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='cacthead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.curingActivities.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/curingactivity/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.cropTreatments != null && this.state.change.cropTreatments.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Crop Treatments: ' + this.state.change.cropTreatments.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showCropTreatments')}>
                                                    {this.state.change.showCropTreatments === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showCropTreatments &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='ctreathead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.cropTreatments.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/croptreatment/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.pesticidePreparations != null && this.state.change.pesticidePreparations.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Pesticide Preparations: ' + this.state.change.pesticidePreparations.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showPesticidePreparations')}>
                                                    {this.state.change.showPesticidePreparations === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showPesticidePreparations &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='pphead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.pesticidePreparations.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/pesticideprep/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.roomFeedings != null && this.state.change.roomFeedings.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Room Feedings: ' + this.state.change.roomFeedings.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showRoomFeedings')}>
                                                    {this.state.change.showRoomFeedings === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showRoomFeedings &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='rfeedhead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.roomFeedings.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/rooomfeeding/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.feedingLots != null && this.state.change.feedingLots.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Feeding Lots: ' + this.state.change.feedingLots.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showFeedingLots')}>
                                                    {this.state.change.showFeedingLots === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showFeedingLots &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='flothead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.feedingLots.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/feedinglot/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.feedingRecipes != null && this.state.change.feedingRecipes.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Feeding Recipes: ' + this.state.change.feedingRecipes.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showFeedingRecipes')}>
                                                    {this.state.change.showFeedingRecipes === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showFeedingRecipes &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='frephead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.feedingRecipes.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/feedingrecipe/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.roomCleanings != null && this.state.change.roomCleanings.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Room Cleanings: ' + this.state.change.roomCleanings.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showRoomCleanings')}>
                                                    {this.state.change.showRoomCleanings === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showRoomCleanings &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='fclhead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.roomCleanings.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/roomcleaning/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.cleaningSolutions != null && this.state.change.cleaningSolutions.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Cleaning Solutions: ' + this.state.change.cleaningSolutions.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showCleaningSolutions')}>
                                                    {this.state.change.showCleaningSolutions === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showCleaningSolutions &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='clsolhead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.cleaningSolutions.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/cleaningsolution/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.productionBoms != null && this.state.change.productionBoms.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Production Boms: ' + this.state.change.productionBoms.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showProductionBoms')}>
                                                    {this.state.change.showProductionBoms === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showProductionBoms &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='bomhead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.productionBoms.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/bom/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.purchaseOrders != null && this.state.change.purchaseOrders.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Purchase Orders: ' + this.state.change.purchaseOrders.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showPurchaseOrders')}>
                                                    {this.state.change.showPurchaseOrders === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showPurchaseOrders &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='pohead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.purchaseOrders.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/purchaseorder/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.sales != null && this.state.change.sales.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Sales: ' + this.state.change.sales.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showSales')}>
                                                    {this.state.change.showSales === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showSales &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='salehead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.sales.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/sale/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.equipmentLogs != null && this.state.change.equipmentLogs.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'MaintenanceLogs: ' + this.state.change.equipmentLogs.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showEquipmentLogs')}>
                                                    {this.state.change.showEquipmentLogs === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showEquipmentLogs &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='eloghead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.equipmentLogs.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/equipmentlog/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                                {this.state.change.maintenanceRequests != null && this.state.change.maintenanceRequests.length > 0 &&
                                    <Aux>
                                        <Grid container spacing={3}>
                                            <Grid item sm={5}>
                                                <SubSectionHeading title={'Service Requests: ' + this.state.change.maintenanceRequests.length}/>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton aria-label="delete line" onClick={this.showDetails('showMaintenanceRequests')}>
                                                    {this.state.change.showMaintenanceRequests === false ? <AddIcon/> : <RemoveIcon/>}
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {this.state.change.showMaintenanceRequests &&
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow key='mrhead'>
                                                            <TableCell>Number</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.change.maintenanceRequests.map((row, i) =>
                                                            <TableRow key={row.id}>
                                                                <TableCell><Link to={'/maintenancerequest/' + row.id}>{row.number}</Link></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </Aux>
                                }
                            </Aux>
                        }
                    </Aux>
                    </ModalDialog>
                    <ModalDialog buttonStack={(<Aux>
                            <Button color="secondary" size="small" variant='text' onClick={this.toggleCount}>Close</Button>
                            <Button color="primary" size="small" variant="text" onClick={this.saveCount} disabled={!this.state.enableSave}>Save</Button>
                        </Aux>
                        )} title='Clear item counts' toggleDialog={this.toggleCount}
                                    dialogStatus={this.state.showCount} fullWidth maxWidth='sm'>
                        <Grid container spacing={3}>
                            <Grid item sm={10}>
                                <AutoCompleteField
                                    value={this.state.countItemSel}
                                    options={this.state.items}
                                    onChange={this.changeAuto('countItemSel', 'countItem')}
                                    label='Item'
                                />
                            </Grid>
                        </Grid>
                    </ModalDialog>
                    <ModalDialog buttonStack={(<Aux>
                            <Button color="secondary" size="small" variant='text' onClick={this.toggleInventory}>Close</Button>
                            <Button color="primary" size="small" variant="text" onClick={this.generateInventory} disabled={!this.state.enableSave}>Run</Button>
                        </Aux>
                        )} title='generate inventory' toggleDialog={this.toggleInventory}
                                    dialogStatus={this.state.showItemGenerate} fullWidth maxWidth='sm'>
                        <Grid container spacing={3}>
                            <Grid item sm={10}>
                                <AutoCompleteField
                                    value={this.state.inventoryItemSel}
                                    options={this.state.items}
                                    onChange={this.changeAuto('inventoryItemSel', 'inventoryItem')}
                                    label='Item'
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <DateSelect 
                                    value={this.state.inventoryDate}
                                    onChange={this.changeDate('inventoryDate')}
                                    label='Date'
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControlLabel
                                    disabled={false}
                                    control={<Switch checked={this.state.allTenants} onChange={this.changeBool('allTenants')} name="allTenants" />}
                                    label='All Tenants'
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControlLabel
                                    disabled={false}
                                    control={<Switch checked={this.state.useLastUpdated} onChange={this.changeBool('useLastUpdated')} name="useLastUpdated" />}
                                    label='Last Update Date'
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControlLabel
                                    disabled={false}
                                    control={<Switch checked={this.state.usePostingDate} onChange={this.changeBool('usePostingDate')} name="usePostingDate" />}
                                    label='Posting Date'
                                />
                            </Grid>
                        </Grid>
                    </ModalDialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(Management));
