import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateSelect from '../UI/DateSelect/DateSelect';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { approveSigSecurity, completeSigSecurity,
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Link } from 'react-router-dom';
import ConversionHelper from '../../helpers/conversionHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import ItemLedgerComponent from '../Inventory/ItemLedgerComponent';
import { EvoraTabs, TabCanvas } from '../UI/Tabs/Tabs';
import BoxTitle from '../UI/Typography/BoxTitle';
import { NumericFormat } from 'react-number-format';
import { withTranslation } from 'react-i18next';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import SelectField from '../UI/SelectField/SelectField';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import Panel from '../UI/Panel/Panel';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import TextField from '../UI/TextField/TextField';
import Button from '@mui/material/Button';
import ConfirmDelete from '../General/ConfirmDelete';
import PrintView from '../Printing/PrintView';

class TrimmingOrder extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            status: '',
            statusSel: {
                value: '',
                label: '',
            },
            statuses: [],
            startDate: new Date(),
            endDate: '',
            location: '',
            locSel: null,
            locations: [],
            item: '',
            itemSel: null,
            items: [],
            lot: '',
            lotSel: null,
            lots: [],
            startAmount: '',
            startAmountUOM: '',
            startAmountUOMSel: null,
            remainingAmount: '',
            uoms: [],
            type: '',
            typeSel: [],
            types: [],
            room: '',
            roomSel: null,
            rooms: [],
            outputs: [],
            signed: false,
            approvers: [],
            signers: [],
            users: [],
            documents: [],
            lotObj: null,
            security: [],
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            bins: [],
            containers: [],
            containersSel: [],
            containerOptions: [],
            containerData: [],
            activities: [],
            strain: {},
            expectedOutput: '',
            outputItem: '',
            actualOutput: '',
            remaining: '',
            optionsMenu: [],
            flagForSignature: 0,
            enableDelete: false,
            enableUnlock: false,
            renderNum: Math.random(),
            enableSave: true,
            started: false,
            tabKey: 0,
            tabLabels: [
                {name: this.props.t('general'), disabled: false}
            ],
            sigData: null,
            activeUsers: [],
            activeManagers: [],
            activeQas: [],
            form: '',
            hasForm: false,
            batch: null,
            dryingLoss: null,
            consumedAmount: 0,
            openItemDrawer: '',
            outputItems: [],
            electricity: '',
            gas: '',
            overhead: '',
            deleteOpen: false,
            finalDocument: null,
            printViewer: false,
            hasLedgers: false,
	    consumptionTypes: []
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.workLocation, common.cacheValues.status, common.cacheValues.quantityUOM,
            common.cacheValues.defaultRoom, common.cacheValues.trimmingStartItem, common.cacheValues.trimType, common.cacheValues.trimmingOrderForm,
            common.cacheValues.containerTypeConsumption], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.trimmingOrder, this.props.auth.user.tenant);
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let location = '';
        let locSel = null;
        if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
            location = this.props.auth.user.defaultLocation;
            locSel = locations.find(x => x.value === location);
        }
        if(locSel == null && locations.length === 1){
            locSel = locations[0];
            location = locSel.value;
        }
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let activeQas = [];
        userValues.approve.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeQas.push(row);
            }
        })
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        this.setState({
            statuses: cacheValues.statuses,
            locations: locations,
            location: location,
            locSel: locSel,
            items: cacheValues.trimStartItems,
            uoms: cacheValues.quantityUOMs,
            types: cacheValues.trimTypes,
            rooms: cacheValues.rooms,
            statusSel: statusSel,
            reviewers: userValues.review,
            users: userValues.create,
            signers: userValues.approve,
            status: this.props.auth.constants.statuses.new,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            activeUsers: activeUsers,
            activeManagers: activeManagers,
            activeQas: activeQas,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            consumptionTypes: cacheValues.consumptionContainerTypes
        }, () => {
            if(this.state._id != null && this.state._id !== '') {
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/trimmingorders', label: this.props.t('trimmings'), screen: this.props.auth.screenDefs.TrimmingOrder},
                    { path: 'active', label: this.props.t('trimmingOrder'), screen: this.props.auth.screenDefs.TrimmingOrder}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        let record = await axios.get('/api/trimmingorder/' + this.state._id);
        let locSel = this.state.locations.find(x => x.value === record.data.location);
        let itemSel = this.state.items.find(x => x.value === record.data.item);
        let roomSel = this.state.rooms.find(x => x.value === record.data.room);
        let typeSel = [];
        if(record.data.type != null && record.data.type.length > 0){
            for(let row of record.data.type){
                let val = this.state.types.find(x => x.value === row);
                if(val != null){
                    typeSel.push(val);
                }
            }
        }
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let startAmountUOMSel = this.state.uoms.find(x => x.value === record.data.startAmountUOM);
        let signed = record.data.status === this.props.auth.constants.statuses.complete ||
                    record.data.status === this.props.auth.constants.statuses.reviewed ||
                    record.data.status === this.props.auth.constants.statuses.signed;
        let lotSel = record.data.lots.find(x => x.value === record.data.lot);
        let containers = [];
        let containersSel = [];
        let containerOptions = [];
        record.data.lotObj.containers.forEach(row => {
            let used = record.data.containers.find(x => x.container === row.container._id);
            if(used || (row.container.quantity > 0 && ValueHelper.inList(row.container.type, this.state.consumptionTypes))){
                containerOptions.push({
                    value: row.container._id,
                    label: row.container.number,
                    quantity: row.container.quantity
                });
            }
        });
        if(record.data.containers != null && record.data.containers.length > 0){
            record.data.containers.forEach(row => {
                let val = containerOptions.find(x => x.value === row.container);
                if(val != null){
                    containersSel.push(val);
                    containers.push(row.container);
                }

            });
        }
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.receiver, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let enableDelete = this.props.permission.delete && record.data.status === this.props.auth.constants.statuses.inprogress &&
                            (record.data.activities == null || record.data.activities.length === 0);
        if(record.data.activities == null || record.data.activities.length === 0 || record.data.outputs == null || record.data.outputs.length === 0){
            completeSigSec = false;
        }
        let started = (record.data.outputs != null && record.data.outputs.length > 0) || (record.data.activities != null && record.data.activities.length > 0) ? true : false;
        let consumed = 0;
        let outputItems = [];
        record.data.outputs.forEach(row => {
            consumed = consumed + row.amount;
            let oItemIndex = outputItems.findIndex(x => x.value === row.item._id);
            if(oItemIndex === -1)
                outputItems.push({ value: row.item._id, name: row.item.name, number: row.item.number, uom: row.uom.label });
        });
        if((record.data.status === this.props.auth.constants.statuses.reviewed || record.data.status === this.props.auth.constants.statuses.signed) &&
           (record.data.consumedAmount != null && record.data.consumedAmount !== '')) {
            consumed = record.data.consumedAmount;
        }
        let remaining = null;
        if(record.data.status === this.props.auth.constants.statuses.new || record.data.status === this.props.auth.constants.statuses.inprogress){
            remaining = fieldAccess ? record.data.startAmount - consumed : 0;
        } else if((record.data.status === this.props.auth.constants.statuses.reviewed || record.data.status === this.props.auth.constants.statuses.signed) &&
                  (record.data.remainingAmount != null && record.data.remainingAmount !== '')) {
            remaining = record.data.remainingAmount;
        } else {
            remaining = record.data.startAmount - record.data.actualOutput;
        }
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: !internal ? this.state.activeUsers : this.state.users, name: t('complete'),
                object: record.data._id, title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user,
                objectType: this.props.auth.constants.objectIds.trimmingOrder, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, saveCallback: this.saveCallback
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: !internal && this.state.reviewer == null ? this.state.activeManagers : this.state.reviewers,
                name: t('review'), title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.trimmingOrder, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: !internal && this.state.signer == null ? this.state.activeQas : this.state.signers,
                name: t('approve'), title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer,
                objectType: this.props.auth.constants.objectIds.trimmingOrder, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        let tabLabels = [
            {name: t('general'), disabled: false}
        ];
        if(record.data.hasLedgers || record.data.status === this.props.auth.constants.statuses.reviewed || record.data.status === this.props.auth.constants.statuses.signed){
            tabLabels.push({name: t('itemLedgers'), disabled: false});
        }
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        let lots = record.data.lots;
        if(lots != null && lots.length > 0)
            lots.forEach((lot) => lot.label = lot.available != null ? lot.label + ' (' + lot.available + ')' : lot.label);
        else
            lots = [];
        let activities = record.data.activities;
        for(let row of activities){
            let typeSel = this.state.types.find(x => x.value === row.type);
            row.typeSel = typeSel;
        }
        activities.sort((a, b) => (a.type > b.type) ? 1 : -1);
        this.setState({
            number: record.data.number,
            status: record.data.status,
            startDate: record.data.startDate,
            endDate: record.data.endDate,
            location: record.data.location,
            locSel: locSel,
            itemSel: itemSel,
            roomSel: roomSel,
            typeSel: typeSel,
            statusSel: statusSel,
            item: record.data.item,
            lot: record.data.lot,
            lotSel: lotSel,
            startAmount: record.data.startAmount,
            startAmountUOM: record.data.startAmountUOM,
            startAmountUOMSel: startAmountUOMSel,
            remainingAmount: remaining,
            type: record.data.type,
            room: record.data.room,
            activities: activities,
            outputs: record.data.outputs,
            signed: signed,
            lotObj: record.data.lotObj,
            lots: lots,
            containerData: record.data.containers,
            containers: containers,
            containersSel: containersSel,
            containerOptions: containerOptions,
            documents: record.data.documents,
            user: record.data.user,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            fieldAccess: fieldAccess,
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            expectedEnd: record.data.expectedEnd,
            expectedOutput: record.data.expectedOutput,
            strain: record.data.strain,
            outputItem: record.data.outputItem,
            actualOutput: record.data.actualOutput,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            enableDelete: enableDelete,
            started: started,
            renderNum: Math.random(),
            tabLabels: tabLabels,
            sigData: null,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            batch: record.data.batch,
            dryingLoss: record.data.dryingLoss,
            consumedAmount: consumed,
            outputItems: outputItems,
            electricity: record.data.costs != null ? record.data.costs.electricity : '',
            gas: record.data.costs != null ? record.data.costs.gas : '',
            overhead: record.data.costs != null ? record.data.costs.overhead : '',
            costs: record.data.costs,
            deleteOpen: false,
            finalDocument: record.data.finalDocument,
            hasLedgers: record.data.hasLedgers
        }, () => {
            const crumbs = [
                { path: '/#/trimmingorders', label: this.props.t('trimmings'), screen: this.props.auth.screenDefs.TrimmingOrder},
                { path: 'active', label: (this.props.t('trimming') + ' - ' + record.data.number), screen: this.props.auth.screenDefs.TrimmingOrder}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.determineUnlock();
            if(this.state.status === this.props.auth.constants.statuses.inprogress){
                this.calculateAmounts();
            }
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    };

    calculateAmounts = () => {
        let startAmount = 0;
        if(this.state.containersSel != null && this.state.containersSel.length > 0){
            for(let row of this.state.containersSel){
                startAmount += row.quantity;
            }
            if(startAmount === 0 && this.state.startAmount > 0)
                startAmount = this.state.startAmount;
        }
        let remainingAmount = this.state.consumedAmount != null && this.state.consumedAmount !== '' ? 
            startAmount - this.state.consumedAmount : startAmount;
        let expectedOutput = 0;
        if(this.state.strain.trimmingPercentLoss != null && this.state.strain.trimmingPercentLoss !== 0)
            expectedOutput = startAmount - (startAmount * (this.state.strain.trimmingPercentLoss / 100));
        expectedOutput.toFixed(2);
        this.setState({startAmount: startAmount, remainingAmount: remainingAmount, expectedOutput: expectedOutput});
    }

    determineUnlock() {
        let enableUnlock = false;
        let state = this.state;
        let constants = this.props.auth.constants;
        if(state.status === constants.statuses.reviewed || state.status === constants.statuses.signed || state.status === constants.statuses.complete){
            let outputsLock = false;
            if(state.outputs != null && state.outputs.length > 0){
                for(let row of state.outputs){
                    if(row.container != null){
                        if(ConversionHelper.round3(parseFloat(row.amount)) > ConversionHelper.round3(parseFloat(row.container.quantity))){
                            outputsLock = true;
                            break;;
                        }
                    }
                    if(row.lot != null){
                        if(row.lot.status === constants.lotStatuses.inprogress || row.lot.status === constants.lotStatuses.onhold){
                            outputsLock = true;
                            break;
                        }
                    }
                }
            }
            if(!outputsLock){
                enableUnlock = true;
            }
        }
        this.setState({enableUnlock: enableUnlock});
    }

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, async () => {
            if(data === "item" && newValue != null){
                const itemCategories = this.props.auth.constants.itemCategories;
                let query = {positive: true, available: true};
                let lots = await axios.get('/api/itemlots/' + newValue.value, {params: query});
                let itemOutputs = await axios.get('/api/trimitemoutputs/' + newValue.value);
                let outputItem = newValue.category === itemCategories.drybulk ? itemOutputs.data.find(x => x.category._id === itemCategories.dryTrimBulk) :
                                newValue.category === itemCategories.dryCured ? itemOutputs.data.find(x => x.category._id === itemCategories.dryTrimCured) : 
                                newValue.category === itemCategories.wetbulk ? itemOutputs.data.find(x => x.category._id === itemCategories.wetTrimBulk) : 
                                newValue.category === itemCategories.dryhempbulk ? itemOutputs.data.find(x => x.category._id === itemCategories.hempTrimBulk): null;
                let strain = await axios.get('/api/itemstrain/' + newValue.value);
                if(lots.data != null && lots.data.length > 0)
                    lots.data.forEach((lot) => lot.label = lot.available != null ? lot.label + ' (' + lot.available + ')' : lot.label);
                this.setState({
                    lots: lots.data,
                    outputItem: outputItem._id,
                    strain: strain.data.strain,
                    lot: '',
                    lotSel: null,
                    lotObj: null,
                    startAmount: '',
                    startAmountUOM: '',
                    startAmountUOMSel: null,
                    expectedOutput: '',
                    remainingAmount: '',
                    containersSel: [],
                    containers: [],
                    containerOptions: [],
                    containerData: [],
                    batch: null
                });
            } else if(data === "item" && newValue == null){
                this.setState({
                    lot: '',
                    lotSel: null,
                    lotObj: null,
                    startAmount: '',
                    startAmountUOM: '',
                    startAmountUOMSel: null,
                    expectedOutput: '',
                    remainingAmount: '',
                    containersSel: [],
                    containers: [],
                    containerOptions: [],
                    containerData: [],
                    batch: null
                });
            } else if (data === 'lot'  && newValue != null){
                let lot = await axios.get('/api/lotdetail/' + newValue.value);
                let containerOptions = [];
                if(lot.data.containers != null && lot.data.containers.length > 0){
                    lot.data.containers.forEach(row => {
                        if(row.quantity > 0 && ValueHelper.inList(row.container.typeId, this.state.consumptionTypes)){
                            containerOptions.push({
                                value: row.container._id,
                                label: row.container.number,
                                quantity: row.quantity
                            })
                        }
                    });
                }
                let startAmountUOMSel = this.state.uoms.find(x => x.value === lot.data.amountUOM);
                this.setState({
                    lotObj: lot.data,
                    startAmount: '',
                    startAmountUOM: lot.data.amountUOM,
                    startAmountUOMSel: startAmountUOMSel,
                    expectedOutput: '',
                    remainingAmount: '',
                    containers: [],
                    containersSel: [],
                    containerOptions: containerOptions,
                    containerData: JSON.parse(JSON.stringify(lot.data.containers)),
                    batch: null
                });
            } else if(data === 'lot' && newValue == null){
                this.setState({
                    lotObj: null,
                    startAmount: '',
                    expectedOutput: '',
                    remainingAmount: '',
                    containers: [],
                    containersSel: [],
                    batch: null
                });
            } else if(data === 'type' && newValue != null){
                if(newValue.value === this.props.auth.constants.trimTypes.machine){
                    let rooms = await axios.get('/api/machinerooms');
                    this.setState({
                        rooms: rooms.data,
                        room: '',
                        roomSel: null
                    });
                } else {
                    let rooms = await axios.get('/api/roomselect');
                    this.setState({
                        rooms: rooms.data
                    })
                }
            }
        });
    };

    dateChange = (name) => (e) => {
        let expectedEnd = this.state.expectedEnd;
        if(this.state.machineTime != null && this.state.machineTime !== ''){
            expectedEnd = ConversionHelper.calculateWorkDate(e, this.state.machineTime);
        }
        this.setState({[name]: e, expectedEnd: expectedEnd});

    };

    changeMultiReg = (name, data) => (e, newValue) => {
        var val = newValue;
        var ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        });
    };

    changeMulti = (event) => {
        const t = this.props.t;
        let val = event.target.value;
        let value = val.pop();
        let ids = this.state.containers;
        let containerData = this.state.containerData;
        let options = this.state.containerOptions;
        let sels = this.state.containersSel;
        if(value != null){
            if(value === 'all') {
                if(val.length === options.length) {
                    if(this.state.started === false){
                        sels = [];
                        ids = [];
                    } else {
                        return;
                    }
                }else {
                    sels = [];
                    ids = [];
                    options.forEach(option => {
                        ids.push(option.value);
                        sels.push(option)
                    });
                }
                this.setState({
                    containersSel: sels,
                    containers: ids
                }, () => {
                    this.calculateAmounts();
                });
                return;
            }else {
                if(val.length === 0) {
                    let container = options.find(x => x.value === value);
                    ids.push(value);
                    val.push(container);
                }else {
                    if(!val.some(x => x.value === value)) {
                        let container = options.find(x => x.value === value);
                        ids.push(value);
                        val.push(container);
                    }else {
                        let index = val.findIndex(x => x.value === value);
                        val.splice(index, 1);
                        ids.splice(index,1);
                    }
                }
                if(this.state.started && containerData != null && containerData.length > 0){
                    for(let row of containerData){
                        let found = val.find(x => x.value === row.container);
                        if(found == null || found === undefined){
                            this.props.dispatch({ type: 'CREATE_ERROR', payload: { [t('containers')]: t('required') }});
                            return;
                        }
                    }
                }
                this.setState({
                    containersSel: val,
                    containers: ids,
                    containerData: containerData
                }, () => {
                    this.calculateAmounts();
                });
                return;
            }
        } else {
            if(this.state.started === false){
                containerData = [];
            } else {
                return;
            }
        }
        this.setState({
            containersSel: val,
            containers: ids,
            containerData: containerData
        }, () => {
            this.calculateAmounts();
        });
    };

    deleteMulti = (e, value) => {
        e.preventDefault();
        let containers = this.state.containers;
        let containersSel = this.state.containersSel;
        let dataIndex = this.state.started === true && this.state.containerData != null ? this.state.containerData.findIndex(x => x.container === value.value) : -1;
        if(dataIndex === -1){
            let index = containersSel.findIndex(x => x.value === value.value);
            containers.splice(index, 1);
            containersSel.splice(index, 1);
            this.setState({
                containersSel: containersSel,
                containers: containers
            }, () => {
                this.calculateAmounts();
            });
        }
    }

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.save()})
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let containers = this.state.containers;
        let containerData = [];
        for(let i = 0; i < containers.length; i++){
            let container = containers[i];
            let baseCont = this.state.lotObj.containers.find(x => x.container._id === container);
            let dataRec = containerData.find(x => x.container === container);
            if(!dataRec){
                containerData.push({
                    container: container,
                    quantity: baseCont.quantity
                })
            }
        }
        let data = {
            _id: this.state._id,
            status: this.state.status,
            location: this.state.location,
            room: this.state.room,
            type: this.state.type,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            expectedEnd: this.state.expectedEnd,
            item: this.state.item,
            expectedOutput: this.state.expectedOutput,
            startAmount: this.state.startAmount,
            startAmountUOM: this.state.startAmountUOM,
            lot: this.state.lot,
            containers: containerData,
            outputItem: this.state.outputItem,
            sigData: this.state.sigData,
            remainingAmount: this.state.remainingAmount,
            form: form != null && form !== '' ? JSON.stringify(form) : null
        }
        try {
            let result = await axios.post('/api/trimmingorder', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/trimmingorder/' + result.data.id);
            this.setState({_id: result.data.id, number: result.data.number, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    validate(final){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.item == null || state.item === '')
            errors[t('item')] = t('required');
        if(state.lot == null || state.lot === '')
            errors[t('lot')] = t('required');
        if(state.room == null || state.room === '')
            errors[t('room')] = t('required');
        if(state.startDate == null || state.startDate === '')
            errors[t('startDate')] = t('required');
        if(state.type == null || state.type.length === 0)
            errors[t('type')] = t('required');
        if(final === true){
            if(state.endDate == null || state.endDate === ''){
                errors[t('endDate')] = t('required');
            } else {
                let validEndDate = ConversionHelper.validActivityDate(this.state.endDate, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
                if(!validEndDate){
                    errors[t('endDate')] = t('dateBeforeLock');
                }
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    addActivity = (e) => {
        const t = this.props.t;
        let valid = true;
        if(this.state.activities == null || this.state.activities.length === 0){
            let verifyStart = 0;
            if(this.state.containersSel != null && this.state.containersSel.length > 0){
                for(let row of this.state.containersSel){
                    verifyStart += row.quantity;
                }
                if(parseFloat(verifyStart) !== parseFloat(this.state.startAmount)){
                    valid = false;
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('containerAmountChanged'), title: t('error'), severity: 'error'}});
                }
            }
        }
        if(valid === true){
            this.props.history.push('/trimmingactivity?order=' + this.state._id);
        }
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e));
    }

    _deleteClick = async (e) => {
        const t = this.props.t;
        if((this.state.activities == null || this.state.activities.length === 0) && this.state.status === this.props.auth.constants.statuses.inprogress){
            try {
                let result = await axios.delete('/api/trimmingorder/' + this.state._id);
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.setState({enableSave: true, deleteOpen: false});
        }
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    validateComplete(){
        const t = this.props.t;
        const constants = this.props.auth.constants;
        let state = this.state;
        let valid = this.validate(true);
        if(!valid)
            return t('notAllComplete');
        if(state.sops != null && state.sops.length > 0){
            for(let i = 0; i < state.sops.length; i++){
                let sop = state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        let unsigned = false;
        for(let row of state.activities){
            if(row.status._id !== constants.statuses.signed){
                unsigned = true;
                break;
            }
        }
        if(unsigned === true){
            return t('notAllActivitiesSigned');
        }
        if(state.activities == null || state.activities.length === 0 || state.outputs == null || state.outputs.length === 0){
            return t('incomplete')
        }
        return null;
    }

    toggleOpen = (drawer, open) => this.setState({ [drawer]: open === this.state[drawer] ? '' : open });

    saveCosts = async (e) => {
        const t = this.props.t;
        let data = {
            _id: this.state._id,
            electricity: this.state.electricity,
            gas: this.state.gas,
            overhead: this.state.overhead
        };
        try {
            let result = await axios.post('/api/trimmingordercosts/', data);
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    changeCostNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value}, () => {
            let inputItems = this.state.costs.inputItems != null && this.state.costs.inputItems !== '' ? this.state.costs.inputItems : 0;
            let labour  = this.state.costs.labour != null && this.state.costs.labour !== '' ? this.state.costs.labour : 0;
            let electricity = this.state.electricity != null && this.state.electricity !== '' ? this.state.electricity : 0;
            let gas = this.state.gas != null && this.state.gas !== '' ? this.state.gas : 0;
            let overhead = this.state.overhead != null && this.state.overhead !== '' ? this.state.overhead : 0;
            let totalCost = parseFloat(inputItems) + parseFloat(labour) + parseFloat(electricity) + parseFloat(gas) + parseFloat(overhead);
            let actualOutput = this.state.actualOutput != null && this.state.actualOutput !== '' ? this.state.actualOutput : 0;
            let costPerGram = totalCost !== 0 && actualOutput !== 0 ? parseFloat(totalCost) / parseFloat(actualOutput) : null;
            let costs = this.state.costs;
            costs.totalCost = totalCost;
            costs.costPerGram = costPerGram;
            this.setState({costs: costs});
        });
    };

    togglePrintViewer = () => {
        this.setState({ printViewer: !this.state.printViewer });
    }

    render(){
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const t = this.props.t;
        const constants = this.props.auth.constants;
        const micro = this.props.auth.user.micro;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        printViewClick={this.togglePrintViewer}
                        printViewTitle={t('trimmingOrder')}
                        enablePrintView={this.state.status === this.props.auth.constants.statuses.signed}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.trimmingOrder}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabCanvas key='main' value={this.state.tabKey} index={0} >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <Panel>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <BoxTitle title={t('recordDetails')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl data-cy='status'>
                                            <FormLabel>{t('status')}</FormLabel>
                                            {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AutoCompleteField
                                            multiple={true} disabled={!fieldAccess || this.state.started}
                                            value={this.state.typeSel}
                                            options={this.state.types}
                                            onChange={this.changeMultiReg('typeSel', 'type')}
                                            error={errors[t('type')] != null ? true : false}
                                            helperText={errors[t('type')]}
                                            label={t('type')}
                                            data-cy='type'
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fieldAccess && !this.state.started && this.state.locations != null && this.state.locations.length > 1 ?
                                            <AutoCompleteField
                                                value={this.state.locSel}
                                                options={this.state.locations}
                                                onChange={this.changeAuto('locSel', 'location')}
                                                error={errors[t('location')] != null ? true : false}
                                                helperText={errors[t('location')]}
                                                label={t('location')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy='location'
                                            /> : 
                                            <FormControl data-cy='location'>
                                                <FormLabel>{t('location')}</FormLabel>
                                                {this.state.locSel != null ? this.state.locSel.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fieldAccess ?
                                            <AutoCompleteField
                                                value={this.state.roomSel}
                                                options={this.state.rooms}
                                                onChange={this.changeAuto('roomSel', 'room')}
                                                error={errors[t('room')] != null ? true : false}
                                                helperText={errors[t('room')]}
                                                label={t('room')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy='room'
                                            /> : 
                                            <FormControl data-cy='room'>
                                                <FormLabel>{t('room')}</FormLabel>
                                                {this.state.roomSel != null ? this.state.roomSel.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {fieldAccess ?
                                            <DateSelect
                                                onChange={this.dateChange('startDate')}
                                                value={this.state.startDate}
                                                helperText={errors[t('startDate')]}
                                                error={errors[t('startDate')] != null ? true : false}
                                                label={t('startDate')}
                                                format={this.props.auth.user.dateFormat}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy='startDate'
                                            /> :
                                            <FormControl>
                                                <FormLabel>{t('startDate')}</FormLabel>
                                                <DateDisplay value={this.state.startDate} format={this.props.auth.user.dateFormat}/>
                                            </FormControl>
                                        }
                                    </Grid>
                                    {this.state.expectedEnd != null && this.state.expectedEnd !== '' &&
                                        <Grid item xs={12} sm={6}>
                                            {fieldAccess ?
                                                <DateSelect
                                                    onChange={this.dateChange('expectedEnd')}
                                                    value={this.state.expectedEnd}
                                                    helperText={errors[t('expectedEnd')]}
                                                    error={errors[t('expectedEnd')] != null ? true : false}
                                                    label={t('expectedEnd')}
                                                    disabled={true}
                                                    data-cy='expectedEnd'
                                                /> : 
                                                <FormControl data-cy='expectedEnd'>
                                                    <FormLabel>{t('expectedEnd')}</FormLabel>
                                                    <DateDisplay value={this.state.expectedEnd} format={this.props.auth.user.dateFormat}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={6}>
                                        {fieldAccess ?
                                            <DateSelect
                                                onChange={this.dateChange('endDate')}
                                                value={this.state.endDate}
                                                helperText={errors[t('endDate')]}
                                                error={errors[t('endDate')] != null ? true : false}
                                                label={t('endDate')}
                                                format={this.props.auth.user.dateFormat}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy='endDate'
                                            /> :
                                            <FormControl data-cy='endDate'>
                                                <FormLabel>{t('endDate')}</FormLabel>
                                                {this.state.endDate != null && this.state.endDate !== '' ? <DateDisplay value={this.state.endDate} format="yyyy/MM/dd"/> : t('none')}
                                            </FormControl>
                                        }
                                    </Grid>
                                </Grid>
                            </Panel>
                        </Grid>
                        <Grid item xs={12} md={6} lg={5}>
                            <Panel>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <BoxTitle title={t('itemDetails')}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fieldAccess && !this.state.started ?
                                            <AutoCompleteField
                                                value={this.state.itemSel}
                                                options={this.state.items}
                                                onChange={this.changeAuto('itemSel', 'item')}
                                                error={errors[t('item')] != null ? true : false}
                                                helperText={errors[t('item')]}
                                                label={t('item')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy='item'
                                                multiline
                                            /> :
                                            <FormControl data-cy='item'>
                                                <FormLabel>{t('item')}</FormLabel>
                                                {this.state.itemSel != null && this.state.itemSel !== '' ? 
                                                    <Link to={'/item/' + this.state.itemSel.value}>{this.state.itemSel.label}</Link> : 
                                                    t('none')
                                                }
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl>
                                            <FormLabel>{t('strain')}</FormLabel>
                                            {this.state.strain != null && this.state.strain !== '' ? 
                                                <Link to={'/strain/' + this.state.strain._id}>{this.state.strain.name}</Link> : 
                                                t('none')
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        {(this.state.status === this.props.auth.constants.statuses.new || this.state.status === this.props.auth.constants.statuses.inprogress) && !this.state.started ?
                                            <AutoCompleteField
                                                value={this.state.lotSel}
                                                options={this.state.lots}
                                                onChange={this.changeAuto('lotSel', 'lot')}
                                                error={errors[t('lot')] != null ? true : false}
                                                helperText={errors[t('lot')]}
                                                label={t('lot')}
                                                disabled={!fieldAccess ? true :
                                                        this.state.itemSel !== null && this.state.itemSel !== '' &&
                                                        this.state.lots != null && this.state.lots.length > 0 ? false : true}
                                                required
                                                data-cy='lot'
                                            /> : 
                                            <FormControl data-cy='lot'>
                                                <FormLabel>{t('lot')}</FormLabel>
                                                <Link to={'/lot/' + this.state.lot}>{this.state.lotSel != null ? this.state.lotSel.label : ''}</Link>
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('batch')}</FormLabel>
                                            {this.state.batch != null && this.state.batch._id != null ? 
                                                <Link to={'/evorabatch/' + this.state.batch._id}>{this.state.batch.number}</Link> :
                                                t('none')
                                            }
                                        </FormControl>
                                    </Grid>
                                    {this.state.containerOptions != null && this.state.containerOptions.length > 0 &&
                                        <Grid item xs={12}>
                                            <SelectField
                                                selectAll
                                                value={this.state.containersSel}
                                                options={this.state.containerOptions}
                                                label={t('containers')}
                                                selectAllLabel={t('selectAll')}
                                                error={errors[t('containers')] != null ? true : false}
                                                helperText={errors[t('containers')]}
                                                disabled={fieldAccess === false }
                                                onChange={this.changeMulti}
                                                onDelete={this.deleteMulti}
                                                data-cy="containers"
                                                linkTo='/#/container/'
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Panel>
                        </Grid>
                        {this.state.hasForm === true &&
                            <Grid item xs={12} md={5} lg={4}>
                                <Panel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <BoxTitle title={t('form')}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                        {errors[t('form')] != null && errors[t('form')] !== '' &&
                                            <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                        }
                                        <Form renderer={renderer}
                                              defaultFields={this.state.form}
                                              onChange={this.formValueChanged}
                                              disabled={!fieldAccess}/>
                                        </Grid>
                                    </Grid>
                                </Panel>                                    
                            </Grid>
                        }
                        <Grid item xs={12} md={3}>
                            <Panel>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <BoxTitle title={t('quantities')}/>
                                    </Grid>
                                    <Grid item xs={3} md={12}>
                                        <FormControl>
                                            <FormLabel>{t('startAmount')}</FormLabel>
                                            {this.state.startAmount != null && this.state.startAmount !== '' && this.state.startAmountUOMSel != null && this.state.startAmountUOMSel !== '' ?
                                                <NumericFormat displayType={'text'} value={this.state.startAmount} thousandSeparator={true}
                                                decimalScale={3} suffix={this.state.startAmountUOMSel.label}/> :
                                                '0g'
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} md={12}>
                                        <FormControl>
                                            <FormLabel>{t('expectedOutput')}</FormLabel>
                                            {this.state.expectedOutput != null && this.state.expectedOutput !== '' && this.state.startAmountUOMSel != null ?
                                                <NumericFormat displayType={'text'} value={this.state.expectedOutput}
                                                thousandSeparator={true} decimalScale={3} suffix={this.state.startAmountUOMSel.label}/> :
                                                '0g'
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} md={12}>
                                        <FormControl>
                                            <FormLabel>{t('actualOutput')}</FormLabel>
                                            {this.state.actualOutput != null && this.state.actualOutput !== '' && this.state.startAmountUOMSel != null ?
                                                <NumericFormat displayType={'text'} value={this.state.actualOutput} thousandSeparator={true}
                                                decimalScale={3} suffix={this.state.startAmountUOMSel.label}/> :
                                                '0g'
                                            }
                                        </FormControl>
                                    </Grid>
                                    {this.state.remainingAmount != null && this.state.remainingAmount !== '' && this.state.startAmountUOMSel != null && this.state.startAmountUOMSel !== '' && 
                                        <Grid item xs={3} md={12}>
                                            <FormControl>
                                            <FormLabel>{this.state.status === this.props.auth.constants.statuses.reviewed ||
                                                        this.state.status === this.props.auth.constants.statuses.signed ? t('lossOnDrying') : t('remaining')}</FormLabel>
                                                {(this.state.status === this.props.auth.constants.statuses.reviewed || this.state.status === this.props.auth.constants.statuses.signed) &&
                                                    <NumericFormat displayType={'text'} value={this.state.dryingLoss}
                                                    thousandSeparator={true} decimalScale={3} suffix={this.state.startAmountUOMSel.label}/>
                                                }
                                                {this.state.status !== this.props.auth.constants.statuses.reviewed && this.state.status !== this.props.auth.constants.statuses.signed &&
                                                    <NumericFormat displayType={'text'} value={this.state.remainingAmount}
                                                    thousandSeparator={true} decimalScale={3} suffix={this.state.startAmountUOMSel.label}/>
                                                }
                                            </FormControl>
                                        </Grid>
                                    }
                                </Grid>
                            </Panel>
                        </Grid>
                        {this.state.status !== constants.statuses.new && this.state.status !== constants.statuses.inprogress && this.state.costs != null &&
                        this.state.costs.totalCost != null && this.state.costs.totalCost !== '' && !micro &&
                            <Grid item sm={12}>
                                <Panel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <BoxTitle title={t('costs')}/>
                                        </Grid>
                                        {this.state.costs.inputItems != null &&
                                            <Grid item xs={2} md={1}>
                                                <FormControl>
                                                    <FormLabel>{t('inputs')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.costs.inputItems}
                                                    thousandSeparator={true} decimalScale={2} prefix="$"/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.costs.labour != null &&
                                                <Grid item xs={2} md={1}>
                                                    <FormControl>
                                                        <FormLabel>{t('labour')}</FormLabel>
                                                        <NumericFormat displayType={'text'} value={this.state.costs.labour}
                                                            thousandSeparator={true} decimalScale={2}  prefix="$"/>
                                                        </FormControl>
                                                </Grid>
                                        }
                                        {this.state.electricity != null && (this.state.status === constants.statuses.reviewed || this.state.status === constants.statuses.signed) &&
                                            <Grid item xs={2} md={1}>
                                                <FormControl>
                                                    <FormLabel>{t('electricity')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.electricity}
                                                    thousandSeparator={true} decimalScale={2}  prefix="$"/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.status === constants.statuses.complete && this.state.security.update &&
                                            <Grid item xs={2} md={1}>
                                                <TextField label={t('electricity')} value={this.state.electricity} onChange={this.changeCostNumber} name="electricity" />
                                            </Grid>
                                        }
                                        {this.state.costs.gas != null && (this.state.status === constants.statuses.reviewed || this.state.status === constants.statuses.signed) &&
                                            <Grid item xs={2} md={1}>
                                                <FormControl>
                                                    <FormLabel>{t('gas')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.gas}
                                                    thousandSeparator={true} decimalScale={2}  prefix="$"/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.status === constants.statuses.complete && this.state.security.update &&
                                            <Grid item xs={2} md={1}>
                                                <TextField label={t('gas')} value={this.state.gas} onChange={this.changeCostNumber} name="gas" />
                                            </Grid>
                                        }
                                        {this.state.costs.gas != null && (this.state.status === constants.statuses.reviewed || this.state.status === constants.statuses.signed) &&
                                            <Grid item xs={2} md={1}>
                                                <FormControl>
                                                    <FormLabel>{t('overhead')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.overhead}
                                                    thousandSeparator={true} decimalScale={2}  prefix="$"/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.status === constants.statuses.complete &&
                                            <Grid item xs={2} md={1}>
                                                <TextField label={t('overhead')} value={this.state.overhead} onChange={this.changeCostNumber} name="overhead" />
                                            </Grid>
                                        }
                                        {this.state.costs.totalCost != null &&
                                            <Grid item xs={2} md={1}>
                                                <FormControl>
                                                    <FormLabel>{t('total')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.costs.totalCost}
                                                    thousandSeparator={true} decimalScale={2}  prefix="$"/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.costs.costPerGram != null &&
                                            <Grid item xs={4} md={2}>
                                                <FormControl>
                                                    <FormLabel>{t('costPerGram')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.costs.costPerGram}
                                                    thousandSeparator={true} decimalScale={2}  prefix="$"/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.status === constants.statuses.complete && this.state.security.update &&
                                            <Grid item xs={4} sm={2}>
                                                <Button variant="contained" color="primary" onClick={this.saveCosts}>{t('saveCosts')}</Button>
                                            </Grid>
                                        }
                                    </Grid>
                                </Panel>
                            </Grid>
                        }
                    </Grid> 
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={5}>
                            <Panel>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <BoxTitle title={t('activities')}
                                            addTitle={t('add')} 
                                            add={this.addActivity} 
                                            addSecurity={this.state._id === '' || 
                                                        (this.state.status !== this.props.auth.constants.statuses.new &&
                                                        this.state.status !== this.props.auth.constants.statuses.inprogress)}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TableContainer component={Paper}>
                                            <Table size='small'>
                                                <TableHead>
                                                    <TableRow key='activityTableHead'>
                                                        <TableCell>{t('number')}</TableCell>
                                                        <TableCell>{t('date')}</TableCell>
                                                        <TableCell>{t('type')}</TableCell>
                                                        <TableCell>{t('status')}</TableCell>
                                                        <TableCell sx={{ textAlign: 'right' }} >{t('roomTemp')}</TableCell>
                                                        <TableCell sx={{ textAlign: 'right' }} >{t('humidity')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.activities != null && this.state.activities.length > 0 ?
                                                        this.state.activities.map((row, i) =>
                                                            <TableRow key={'activityRow-' + i}>
                                                                <TableCell><Link to={'/trimmingactivity/' + row._id} name={'activityLink-' + row._id}>{row.number}</Link></TableCell>
                                                                <TableCell><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                                                <TableCell>{row.typeSel != null ? row.typeSel.label : ''}</TableCell>
                                                                <TableCell>{row.status != null && row.status.label != null ? row.status.label : ''}</TableCell>
                                                                <TableCell sx={{ textAlign: 'right' }} >
                                                                    {row.roomTemperature != null && row.tempUOM != null && row.tempUOM.label != null ?
                                                                        row.roomTemperature + (row.tempUOM.label === 'C' ? '\u2103' : '\u2109' ) : ''
                                                                    }
                                                                </TableCell>
                                                                <TableCell sx={{ textAlign: 'right' }} >{row.humidity ?? 0}%</TableCell>
                                                            </TableRow>
                                                        ) :
                                                        <TableRow key='noActivitiesRow'>
                                                            <TableCell colSpan={5} sx={{ textAlign: 'center', fontWeight: 'bold' }}>{t('noData')}</TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Panel>                            
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <Panel>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <BoxTitle title={t('outputs')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TableContainer component={Paper}>
                                            <Table size='small'>
                                                <TableHead>
                                                    <TableRow key='outputHeader'>
                                                        <TableCell />
                                                        <TableCell>{t('item')}</TableCell>
                                                        <TableCell sx={{ textAlign: 'right' }}>{t('containers')}</TableCell>
                                                        <TableCell sx={{ textAlign: 'right' }}>{t('totalAmount')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.outputItems != null && this.state.outputItems.length > 0 ?
                                                        this.state.outputItems.map((oItem, i) => {
                                                            return (
                                                                <React.Fragment key={'outfrag' + i}>
                                                                    <TableRow key={'outputItem-' + oItem.value + i}>
                                                                        <TableCell>
                                                                            <IconButton aria-label="expand row" size="small" onClick={() => this.toggleOpen('openItemDrawer', i)} >
                                                                                {this.state.openItemDrawer === i ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                            </IconButton>
                                                                        </TableCell>
                                                                        <TableCell><Link to={'/item/' + oItem.value}>{oItem.number + '-' + oItem.name}</Link></TableCell>
                                                                        <TableCell align='right'>
                                                                            {this.state.outputs != null && this.state.outputs.length > 0 ?
                                                                                this.state.outputs.reduce((total, j) => total + (oItem.value === j.item._id ? 1 : 0), 0) 
                                                                                : 0
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <NumericFormat 
                                                                                displayType={'text'} 
                                                                                value={
                                                                                    this.state.outputs != null && this.state.outputs.length > 0 ? 
                                                                                        this.state.outputs.reduce((total, j) => total + (oItem.value === j.item._id ? j.amount : 0), 0) 
                                                                                        : 0
                                                                                }
                                                                                thousandSeparator={true} 
                                                                                suffix={oItem.uom != null ? oItem.uom : ''} 
                                                                                decimalScale={3}
                                                                            />                                                                    
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    {this.state.openItemDrawer === i &&
                                                                        <TableRow key={'outputItemDrawer-' +  i }>
                                                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                                                                <Collapse in={this.state.openItemDrawer === i} timeout="auto" unmountOnExit>
                                                                                    <Box margin={1} key={'outbox' + i}>
                                                                                        <Table size="small" aria-label="outputContainers" key={"outTable" + i}>
                                                                                            <TableHead>
                                                                                                <TableRow key={'outputContainerHeader' + i}>
                                                                                                    <TableCell>{t('lot')}</TableCell>
                                                                                                    <TableCell>{t('container')}</TableCell>
                                                                                                    <TableCell align="right">{t('amount')}</TableCell>
                                                                                                    <TableCell>{t('type')}</TableCell>
                                                                                                    <TableCell>{t('bin')}</TableCell>
                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody>
                                                                                                {this.state.outputs != null && this.state.outputs.length > 0 ?
                                                                                                    this.state.outputs.map((o, j) => {
                                                                                                        if(oItem.value === o.item._id) {
                                                                                                            return (
                                                                                                                <TableRow key={'outputContainer-' + i + 'a' + j}>
                                                                                                                    <TableCell>
                                                                                                                        {o.lot != null && o.lot.number != null ?
                                                                                                                            <Link to={'/lot/' + o.lot._id}>{o.lot.number}</Link>
                                                                                                                            : ''
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                    <TableCell>
                                                                                                                        {o.container != null && o.container.number != null ?
                                                                                                                            <Link to={'/container/' + o.container._id}>{o.container.number + ' (' + o.container.available + ')'}</Link>
                                                                                                                            : ''
                                                                                                                        }
                                                                                                                    </TableCell>
                                                                                                                    <TableCell align="right">
                                                                                                                        <NumericFormat 
                                                                                                                            displayType={'text'} 
                                                                                                                            value={o.amount} 
                                                                                                                            thousandSeparator={true} 
                                                                                                                            suffix={oItem.uom != null ? oItem.uom : ''} 
                                                                                                                            decimalScale={3}
                                                                                                                        />
                                                                                                                    </TableCell>
                                                                                                                    <TableCell>{o.container != null && o.container.type != null && o.container.type.label != null ? o.container.type.label : t('none')}</TableCell>
                                                                                                                    <TableCell>
                                                                                                                        <Link to={'/bin/' + o.bin._id}>{o.bin.description}</Link>
                                                                                                                    </TableCell>
                                                                                                                </TableRow>
                                                                                                            )
                                                                                                        }else {
                                                                                                            return null;
                                                                                                        }
                                                                                                    }) :
                                                                                                    <TableRow key={'outputContainer-0' + i}>
                                                                                                        <TableCell colSpan={4}>{t('noData')}</TableCell>
                                                                                                    </TableRow>
                                                                                                }
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </Box>
                                                                                </Collapse>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    }
                                                                </React.Fragment>
                                                            )
                                                        }) :
                                                        <TableRow key='noOutputs'>
                                                            <TableCell colSpan={4} sx={{ textAlign: 'center', fontWeight: 'bold' }}>{t('noData')}</TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Panel>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <FormButtonStack
                                id={this.state._id}
                                objectType={this.props.auth.constants.objectIds.trimmingOrder}
                                callback={this.loadRecord}
                                saveClick={this.save}
                                status={this.state.status}
                                permissions={this.props.permission}
                                common={this.props.auth.common}
                                constants={this.props.auth.constants}
                                enableDelete={this.state.enableDelete}
                                deleteClick={this.deleteToggle}
                                enableUnlockProp={this.state.enableUnlock}
                                saveEnabled={this.state.enableSave}
                                activityDate={this.state.endDate}
                                lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                                isSuper={this.props.auth.user.isSuper}
                            />
                        </Grid>
                    </Grid>
                </TabCanvas>
                {(this.state.hasLedgers || this.state.status === this.props.auth.constants.statuses.reviewed || this.state.status === this.props.auth.constants.statuses.signed) &&
                    <TabCanvas value={this.state.tabKey} index={this.state.tabLabels.length - 1}>
                        <ItemLedgerComponent 
                            record={this.state._id} 
                            permission={this.props.permission} 
                            auth={this.props.auth} 
                            renderNum={this.state.renderNum}
                            objectType={this.props.auth.constants.objectIds.trimmingOrder}
                        />
                    </TabCanvas>
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.state.printViewer &&
                    <PrintView
                        id={this.state._id}
                        object={this.props.auth.constants.objectIds.trimmingOrder}
                        open={this.state.printViewer}
                        onClose={this.togglePrintViewer}
                        callBack={this.loadRecord}
                        printAccess={this.state.security.print}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.trimmingOrder}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(TrimmingOrder));
