import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import classnames from 'classnames';
import ObjectLog from '../General/ObjectLog';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Grid from '@mui/material/Grid';
import Comments from '../General/Comments';
import { fieldSecurity } from '../../helpers/securityHelper';
import DateSelect from '../UI/DateSelect/DateSelect';
import { SaveButton, SimpleUnlockButton } from '../UI/Buttons/Buttons';
import ActionBar from '../UI/Buttons/ActionBar';
import { FormControl, FormLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { NumericFormat } from 'react-number-format';
import ConversionHelper from '../../helpers/conversionHelper';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import ValueHelper from '../../helpers/valueHelper';
import Button from '@mui/material/Button';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class ItemLedger extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            postingDate: new Date(),
            entryType: '',
            entryTypeSel: null,
            entryTypes: [],
            item: '',
            itemSel: null,
            items: [],
            description: '',
            lot: '',
            lotSel: null,
            lots: [],
            lotTransaction: '',
            lotTransactions: [],
            lotTransSel: null,
            location: '',
            locationSel: null,
            locations: [],
            quantity: '',
            quantityUOM: '',
            quantityUOMSel: null,
            uoms: [],
            count: '',
            remainingQuantity: '',
            objectType: '',
            objectTypeSel: null,
            objectTypeObject: null,
            objectTypes: [],
            record: '',
            recordSel: null,
            records: [],
            reason: '',
            reasonSel: null,
            reasons: [],
            container: '',
            containerSel: null,
            containers: [],
            containerTransaction: '',
            containerTransSel: null,
            containerTransactions: [],
            security: [],
            fieldAccess: false,
            bin: '',
            binSel: null,
            bins: [],
            itemObj: null,
            locked: false,
            hasCount: false,
            manual: true,
            dateOnly: true,
            lotObject: null,
            containerObject: null,
            renderNum: Math.random(),
            user: null,
            processingType: '',
            processingTypeSel: null,
            processingTypes: [],
            enableSave: true,
            ctlsExclude: false,
            discreteWeight: '',
            discreteWeightUOM: '',
            discreteWeightSel: null,
            fullDiscreteWeight: '',
            fullDiscreteWeightUOM: '',
            fullDiscreteWeightUOMSel: null,
            hasDiscrete: false,
            customLot: '',
            isolated: false,
            limitedItems: [],
            dataUnlock: false,
            calcDiscrete: false,
            isHemp: false,
            reverse: false,
            reversedRecord: null,
            cannabisWeight: null,
            thcAmount: null,
            packages: null
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.allItems, common.cacheValues.bin, common.cacheValues.location, common.cacheValues.quantityUOM,
                                common.cacheValues.itemLedgerObject, common.cacheValues.ledgerEntryType, common.cacheValues.adjustmentReason,
                                common.cacheValues.processingType, common.cacheValues.object], this.props.auth.constants, null, this.props.auth.user.tenant);
        let items = cacheValues.allItems;
        let bins = cacheValues.bins;
        let locations = cacheValues.locations;
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
                this.props.auth.user.defaultLocation : '';
        let locationSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locationSel == null && locations.length === 1){
            locationSel = locations[0];
            location = locationSel.value;
        }
        let entryTypes = cacheValues.ledgerEntryTypes;
        const constants = this.props.auth.constants;
        if(this.props.match.params.id == null || this.props.match.params.id === ''){
            let unlockPos = entryTypes.findIndex(x => x.value === constants.entryTypes.unlockPositive);
            if(unlockPos >= 0){
                entryTypes.splice(unlockPos, 1);
            }
            let unlockNeg = entryTypes.findIndex(x => x.value === constants.entryTypes.unlockNegative);
            if(unlockNeg >= 0){
                entryTypes.splice(unlockNeg, 1);
            }
        }
        const fieldAccess = fieldSecurity(this.props.permission, null, this.props.auth.common, this.props.auth.constants);
        let gSel = cacheValues.quantityUOMs.find(x => x.value === constants.quantityUOMs.g);
        this.setState({
            entryTypes: entryTypes,
            items: items,
            locations: locations,
            location: location,
            locationSel: locationSel,
            objectTypes: cacheValues.objects,
            uoms: cacheValues.quantityUOMs,
            reasons: cacheValues.adjustmentReasons,
            bins: bins,
            processingTypes: cacheValues.processingTypes,
            fieldAccess: fieldAccess,
            security: this.props.permission,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            g: constants.quantityUOMs.g,
            gSel: gSel
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            } else {
                const crumbs = [
                    { path: '/#/itemledgers', label: this.props.t('itemLedgers'), screen: this.props.auth.screenDefs.ItemLedger},
                    { path: 'active', label: this.props.t('itemLedger'), screen: this.props.auth.screenDefs.ItemLedger}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
                this.setState({objectTypes: cacheValues.itemLedgerObjects});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/itemledger/' + this.state._id);
        let entryTypeSel = this.state.entryTypes.find(x => x.value === record.data.entryType);
        let itemSel = this.state.items.find(x => x.value === record.data.item);
        let locationSel = this.state.locations.find(x => x.value === record.data.location);
        let objectTypeSel = this.state.objectTypes.find(x => x.value === record.data.objectType);
        let quantityUOMSel = this.state.uoms.find(x => x.value === record.data.quantityUOM);
        let manual = record.data.manual === true || record.data.manual === 'true' ? true : false;
        let lotSel = manual === true && record.data.lot != null ? record.data.lots.find(x => x.value === record.data.lot) : null;
        let calcDiscrete = lotSel != null && lotSel.discreteWeight != null ? true : false;
        let containerSel = manual === true && record.data.container != null && record.data.containers != null ? record.data.containers.find(x => x.value === record.data.container) : null;
        let reasonSel = this.state.reasons.find(x => x.value === record.data.reason);
        let binSel = record.data.bin != null ? this.state.bins.find(x => x.value === record.data.bin) : null;
        let recordSel = record.data.record != null && record.data.records != null && record.data.records.length > 0 ?
            record.data.records.find(x => x.value === record.data.record) : null;
        let processingTypeSel = record.data.processingType != null ? this.state.processingTypes.find(x => x.value === record.data.processingType) : null;
        let discreteWeightUOMSel = record.data.discreteWeightUOM != null ? this.state.gSel : null;
        let fullDiscreteWeightUOMSel = record.data.fullDiscreteWeightUOM != null ? this.state.gSel: null;
        let objectTypeObj = this.state.objectTypes.find(x => x.value === record.data.objectType);
        let dateOnly = true;
        if(record.data.manual && record.data.lotObject != null && record.data.lotObject.amount > 0)
            dateOnly = false;
        let entryTypes = this.state.entryTypes;
        const constants = this.props.auth.constants;
        if(record.data.manual){
            let unlockPos = entryTypes.findIndex(x => x.value === constants.entryTypes.unlockPositive);
            if(unlockPos >= 0){
                entryTypes.splice(unlockPos, 1);
            }
            let unlockNeg = entryTypes.findIndex(x => x.value === constants.entryTypes.unlockNegative);
            if(unlockNeg >= 0){
                entryTypes.splice(unlockNeg, 1);
            }
        }
        this.setState({
            number: record.data.number,
            postingDate: record.data.postingDate,
            entryType: record.data.entryType,
            entryTypeSel: entryTypeSel,
            item: record.data.item,
            itemSel: itemSel,
            description: record.data.description,
            lot: record.data.lot,
            lotSel: lotSel,
            lots: record.data.lots,
            lotTransaction: record.data.lotTransaction,
            location: record.data.location,
            locationSel: locationSel,
            quantity: record.data.quantity,
            quantityUOMSel: quantityUOMSel,
            count: record.data.count,
            remainingQuantity: record.data.remainingQuantity,
            objectType: record.data.objectType,
            objectTypeSel: objectTypeSel,
            objectTypeObj: objectTypeObj,
            record: record.data.record,
            container: record.data.container,
            containerSel: containerSel,
            containers: record.data.containers,
            containerTransaction: record.data.containerTransaction,
            reason: record.data.reason,
            reasonSel: reasonSel,
            recordSel: recordSel,
            records: record.data.records,
            quantityUOM: record.data.quantityUOM,
            bin: record.data.bin,
            binSel: binSel,
            itemObj: record.data.itemObj,
            locked: true,
            hasCount: itemSel.hasCount === true ? true : false,
            hasDiscrete: itemSel.discreteWeight === true ? true : false,
            manual: manual,
            dateOnly: dateOnly,
            lotObject: record.data.lotObject,
            containerObject: record.data.containerObject,
            renderNum: Math.random(),
            user: record.data.user,
            entryTypes: entryTypes,
            processingType: record.data.processingType,
            processingTypeSel: processingTypeSel,
            ctlsExclude: record.data.ctlsExclude,
            discreteWeight: record.data.discreteWeight,
            discreteWeightUOM: record.data.discreteWeightUOM,
            discreteWeightUOMSel: discreteWeightUOMSel,
            fullDiscreteWeight: record.data.fullDiscreteWeight,
            fullDiscreteWeightUOMSel: fullDiscreteWeightUOMSel,
            fullDiscreteWeightUOM: record.data.fullDiscreteWeightUOM,
            customLot: record.data.customLot,
            isolated: record.data.isolated === true || record.data.isolated === 'true' ? true : false,
	        calcDiscrete: calcDiscrete,
            isHemp: record.data.isHemp === true || record.data.isHemp === 'true' ? true : false,
            reverse: record.data.reverse === true || record.data.reverse === 'true' ? true : false,
            reversedRecord: record.data.reversedRecord,
            cannabisWeight: record.data.cannabisWeight !== 0 ? record.data.cannabisWeight : null,
            thcAmount: record.data.thcAmount !== 0 ? record.data.thcAmount : null,
            packages: record.data.packages !== 0 ? record.data.packages : null
        }, () => {
            const crumbs = [
                { path: '/#/itemledgers', label: this.props.t('itemLedgers'), screen: this.props.auth.screenDefs.ItemLedger},
                { path: 'active', label: this.props.t('itemLedger') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.ItemLedger}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(value !== '-'){
            if(isNaN(value)){
                return;
            }
        }
        this.setState({[name]: value}, () => {
            if(name === 'quantity' && this.state.lotSel != null){
                let fullDiscrete = this.calculateDiscrete(this.state.lotSel, value);
                this.setState({fullDiscreteWeight: fullDiscrete});
            }
        });
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, async () => {
            if(newValue != null){
                if(data === 'item'){
                    let quantityUOMSel = this.state.uoms.find(x => x.value === newValue.uom);
                    let lots = [];
                    let item = await axios.get('/api/item/' + newValue.value);
                    if(this.state.record == null || this.state.record === ''){
                        let lotResult = await axios.get('/api/itemlots/' + newValue.value, {params: {includeEmpty: true}});
                        lots = lotResult.data;
                    } else {
                        let lotResult = await axios.get('/api/recorditemlots', {params: {objectType: this.state.objectType, id: this.state.record, item: newValue.value}});
                        lots = lotResult.data;
                    }
                    this.setState({
                        lots: lots,
                        quantityUOM: newValue.uom,
                        quantityUOMSel: quantityUOMSel,
                        itemObj: item.data,
                        hasCount: newValue.hasCount === true ? true : false,
                        hasDiscrete: newValue.discreteWeight === true ? true : false
                    });
                } else if(data === 'lot'){
                    let containers = [];
                    if(this.state.record == null || this.state.record === ''){
                        let containerResult = await axios.get('/api/lotcontainers/' + newValue.value);
                        containers = containerResult.data;
                    } else {
                        let containerResult = await axios.get('/api/recordilotcontainers', {params: {objectType: this.state.objectType, id: this.state.record, lot: newValue.value}});
                        containers = containerResult.data;
                    }
                    let fullDiscrete = this.calculateDiscrete(newValue, this.state.quantity);
                    this.setState({containers: containers, fullDiscreteWeight : fullDiscrete, calcDiscrete: newValue.discreteWeight != null ? true : false});
                } else if(data === 'objectType'){
                    let records = await axios.get('/api/objectrecords/' + newValue.value);
                    this.setState({records: records.data});
                } else if (data === 'container'){
                    let container = await axios.get('/api/container/' + newValue.value);
                    let binSel = this.state.bins.find(x => x.value === container.data.bin._id);
                    this.setState({binSel: binSel, bin: container.data.bin._id})
                } else if (data === 'record'){
                    let itemResult = await axios.get('/api/recorditems', {params: {objectType: this.state.objectType, id: newValue.value}});
                    this.setState({limitedItems: itemResult.data, item: '', itemSel: null});
                }
            } else {
                if(data === 'record'){
                    this.setState({limitedItems: [], containers: [], lots: [], itemSel: null, item: '', lot: '', lotSel: null, container: '', containerSel: null})
                } else if(data === 'lot'){
                    this.setState({
                        containers: [],
                        container: '',
                        containerSel: null,
                        fullDiscreteWeight: ''
                    })
                } else if(data === 'item'){
                    this.setState({lots: [], lot: '', lotSel: null, containers: [], containerSel: null, container: ''})
                }
            }
        });
    };

    calculateDiscrete(lotSel, quantity){
        if(lotSel != null && lotSel.discreteWeight != null && quantity != null && quantity !== ''){
            return parseFloat(lotSel.discreteWeight) * parseFloat(quantity);
        } else {
            return '';
        }
    }

    changeDate = (name) => (e) => {
        this.setState({[name]: e})
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async (e) => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            _id: this.state._id,
            entryType: this.state.entryType,
            postingDate: this.state.postingDate,
            item: this.state.item,
            description: this.state.description,
            lot: this.state.lot,
            location: this.state.location,
            quantity: this.state.quantity,
            quantityUOM: this.state.quantityUOM,
            objectType: this.state.objectType,
            record: this.state.record,
            container: this.state.container,
            containerTransaction: this.state.containerTransaction,
            lotTransaction: this.state.lotTransaction,
            reason: this.state.reason,
            fullDiscreteWeight: this.state.fullDiscreteWeight,
            fullDiscreteWeightUOM: this.state.fullDiscreteWeight != null && this.state.fullDiscreteWeight !== '' ? this.state.g : null,
            bin: this.state.bin,
            manual: this.state.manual,
            customLot: this.state.customLot,
            isolated: this.state.isolated
        };
        if(this.state.hasCount)
            data.count = this.state.count;
        try {
            let result = await axios.post('/api/itemledger', data);
            this.props.history.replace('/itemledger/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true }, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    validate = (e) => {
        const t = this.props.t;
        const constants = this.props.auth.constants;
        let state = this.state;
        let errors = {};
        if(state.entryType == null || state.entryType === '')
            errors[t('entryType')] = t('required');
        else {
            if(state.entryTypeSel.negative){
                if(state.quantity != null && state.quantity !== ''){
                    if(parseInt(state.quantity) > 0)
                        errors[t('quantity')] = t('negativeNumber');
                }
                if(state.fullDiscreteWeight != null && state.fullDiscreteWeight !== ''){
                    if(parseFloat(state.fullDiscreteWeight) > 0){
                        errors[t('fullDiscreteWeight')] = t('negativeNumber');
                    }
                }
                if(state.reason === constants.adjustmentReasons.exciseDestroyed || state.reason === constants.adjustmentReasons.exciseLost){
                    if(!ConversionHelper.isExciseStamp(state.item, constants)){
                        errors[t('reason')] = t('onlyExcise');
                    }
                }
                if(state.itemSel != null && state.itemSel.lotTracked){
                    if(state.lot == null || state.lot === ''){
                        errors[t('lot')] = t('required');
                    }
                    if(state.container == null || state.container === ''){
                        errors[t('container')] = t('required')
                    }
                    if(state.quantity != null && state.quantity !== '' && state.container != null && state.container !== ''){
                        let changeAmount = ConversionHelper.round4(parseFloat(state.quantity * -1));
                        if(changeAmount > ConversionHelper.round4(parseFloat(state.containerSel.quantity))){
                            errors[t('quantity')] = t('moreThanContainer');
                        }
                    }
                } else if(state.itemSel != null && state.itemSel.lotTracked === false) {
                    if(state.quantity != null && state.quantity !== ''){
                        if(parseFloat(state.quantity) * -1 > parseFloat(this.state.itemSel.inventory))
                            errors[t('quantity')] = t('moreThanInventory')
                    }
                }
            } else {
                if(state.quantity != null && state.quantity !== ''){
                    if(parseInt(state.quantity) < 0)
                        errors[t('quantity')] = t('positiveNumber');
                }
                if(state.fullDiscreteWeight != null && state.fullDiscreteWeight !== ''){
                    if(parseFloat(state.fullDiscreteWeight) < 0){
                        errors[t('fullDiscreteWeight')] = t('positiveNumber');
                    }
                }
                if(state.reason === constants.adjustmentReasons.exciseDestroyed){
                    errors[t('reason')] = t('onlyNegative');
                }
            }
            if(state.postingDate == null || state.postingDate === ''){
                errors[t('postingDate')] = t('required');
            } else {
                let validEndDate = ConversionHelper.validActivityDate(this.state.postingDate, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
                if(!validEndDate){
                    errors[t('postingDate')] = t('dateBeforeLock');
                }
            }
            if(state.item == null || state.item === '')
                errors[t('item')] = t('required');
            if(state.description == null || state.description === '')
                errors[t('description')] = t('required');
            if(state.location == null || state.location === '')
                errors[t('location')] = t('required');
            if(state.quantity == null || state.quantity === '')
                errors[t('quantity')] = t('required');
            if(state.reason == null || state.reason === '')
                errors[t('reason')] = t('required');
            if(state.objectType == null || state.objectType === ''){
                if(state.objectType !== constants.objectIds.batchActivity && (state.bin == null || state.bin === '')){
                    errors[t('bin')] = t('required');
                } else if(state.objectType === constants.objectIds.batchActivity){
                    if(state.itemObj.category !== constants.itemCategories.clones && state.itemObj.category !== constants.itemCategories.flowerPlants
                    && state.itemObj.category !== constants.itemCategories.seeds && state.itemObj.category !== constants.itemCategories.vegPlants){
                        errors[t('bin')] = t('required');
                    }
                }
            }
            if(state.entryType === constants.entryTypes.positive){
                if(state.itemSel != null && state.itemSel.lotTracked && state.itemSel.customNumber === true){
                    if((state.customLot == null || state.customLot === '') && (state.lot == null || state.lot === '')){
                        errors[t('customLot')] = t('required');
                    }
                }
            }
            if(state.objectType != null && state.objectType !== ''){
                if(state.record == null || state.record === '')
                    errors[t('record')] = t('required');
            }
            if(state.hasDiscrete){
                if(state.fullDiscreteWeight == null || state.fullDiscreteWeight === '')
                    errors[t('fullDiscreteWeight')] = t('required');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    };

    saveDate = (e) => {
        if(this.props.auth.user.internal){
            if(!this.state.enableSave)
                return;
            this.setState({ enableSave: false }, () => this._saveDate());
        }
    };

    _saveDate = async (e) => {
        const t = this.props.t;
        if(this.state.postingDate != null && this.state.postingDate !== ''){
            let data = {
                _id: this.state._id,
                postingDate: this.state.postingDate,
                processingType: this.state.processingType
            }
            try {
                let result = await axios.post('/api/itemledgerdate', data);
                this.props.history.replace('/itemledger/' + result.data.id);
                this.setState({_id: result.data.id, enableSave: true }, () => {
                    this.loadRecord();
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                });
            } catch(err){
                this.setState({ enableSave: true }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
                });
            }
        } else {
            this.setState({errors: {postingDate: t('required')}, enableSave: true});
        }
    }

    unlock = (e) => {
        if(this.state.manual){
            this.setState({locked: !this.state.locked})
        }
    }

    unlockDate = (e) => {
        if(this.props.auth.user.internal){
            this.setState({dateUnlock: !this.state.dateUnlock});
        }
    }

    render(){
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const locked = this.state.locked;
        const baseLocked = this.state._id != null && this.state._id !== '';
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        const dateUnlock = this.state.dateUnlock;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        {this.state._id != null && this.state._id !== '' &&
                            <Grid item xs={6} sm={4}>
                                <FormControl data-cy="number">
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            {fieldAccess && (!locked || dateUnlock) &&
                                <DateSelect
                                    onChange={this.changeDate('postingDate')}
                                    value={this.state.postingDate}
                                    helperText={errors[t('postingDate')]}
                                    error={errors[t('postingDate')] != null ? true : false}
                                    label={t('postingDate')}
                                    disabled={!fieldAccess || (locked && !dateUnlock)}
                                    format={this.props.auth.user.dateFormat}
                                    required
                                    data-cy="postingDate"
                                />
                            }
                            {(!fieldAccess || (locked && !dateUnlock)) &&
                                <FormControl>
                                    <FormLabel>{t('postingDate')}</FormLabel>
                                    <DateDisplay value={this.state.postingDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess && !locked &&
                                <AutoCompleteField
                                    value={this.state.locationSel}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locationSel', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]}
                                    className={classnames('', {'is-invalid': errors[t('location')]})}
                                    label={t('location')}
                                    disabled={(!fieldAccess || locked)}
                                    required
                                    data-cy="location"
                                />
                            }
                            {(!fieldAccess || locked) &&
                                <FormControl>
                                    <FormLabel>{t('location')}</FormLabel>
                                    {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess && !locked &&
                                <AutoCompleteField
                                    value={this.state.entryTypeSel}
                                    options={this.state.entryTypes}
                                    onChange={this.changeAuto('entryTypeSel', 'entryType')}
                                    error={errors[t('entryType')] != null ? true : false}
                                    helperText={errors[t('entryType')]}
                                    className={classnames('', {'is-invalid': errors[t('entryType')]})}
                                    label={t('entryType')}
                                    disabled={!fieldAccess || locked}
                                    required
                                    data-cy="entryType"
                                />
                            }
                            {(!fieldAccess || locked) &&
                                <FormControl>
                                    <FormLabel>{t('entryType')}</FormLabel>
                                    {this.state.entryTypeSel != null ? this.state.entryTypeSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess && !locked &&
                                <AutoCompleteField
                                    value={this.state.reasonSel}
                                    options={this.state.reasons}
                                    onChange={this.changeAuto('reasonSel', 'reason')}
                                    error={errors[t('reason')] != null ? true : false}
                                    helperText={errors[t('reason')]}
                                    label={t('reason')}
                                    disabled={!fieldAccess || locked}
                                    required
                                    data-cy="reason"
                                />
                            }
                            {(!fieldAccess || locked) &&
                                <FormControl>
                                    <FormLabel>{t('reason')}</FormLabel>
                                    {this.state.reasonSel != null ? this.state.reasonSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess && !locked &&
                                <AutoCompleteField
                                    value={this.state.objectTypeSel}
                                    options={this.state.objectTypes}
                                    onChange={this.changeAuto('objectTypeSel', 'objectType')}
                                    error={errors[t('objectType')] != null ? true : false}
                                    helperText={errors[t('objectType')]} 
                                    label={t('activity')}
                                    disabled={!fieldAccess || baseLocked}
                                    data-cy="objectType"
                                />
                            }
                            {(!fieldAccess || locked) &&
                                <FormControl>
                                    <FormLabel>{t('activity')}</FormLabel>
                                    {this.state.objectTypeSel != null ? this.state.objectTypeSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        {this.state.objectType != null && this.state.objectType !== '' && this.state.records != null && this.state.records.length > 0 &&
                            <Aux>
                                {fieldAccess && !baseLocked &&
                                    <Grid item xs={6} sm={3}>
                                        <AutoCompleteField
                                            value={this.state.recordSel}
                                            options={this.state.records}
                                            onChange={this.changeAuto('recordSel', 'record')}
                                            error={errors[t('record')] != null ? true : false}
                                            helperText={errors[t('record')]}
                                            className={classnames('', {'is-invalid': errors[t('record')]})}
                                            label={t('record')}
                                            disabled={!fieldAccess || baseLocked}
                                            data-cy="record"
                                        />
                                    </Grid>
                                }
                                {(!fieldAccess || baseLocked) && this.state.recordSel != null && this.state.objectTypeObj != null &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('record')}</FormLabel>
                                            <Link to={this.state.objectTypeObj.link + this.state.recordSel.value}>{this.state.recordSel.label}</Link>
                                        </FormControl>
                                    </Grid>
                                }
                            </Aux>
                        }
                        {fieldAccess && !baseLocked &&
                             <Grid item xs={8} sm={4}>
                                <AutoCompleteField
                                    value={this.state.itemSel}
                                    options={this.state.limitedItems != null && this.state.limitedItems.length > 0 ? this.state.limitedItems : this.state.items}
                                    onChange={this.changeAuto('itemSel', 'item')}
                                    error={errors[t('item')] != null ? true : false}
                                    helperText={errors[t('item')]}
                                    className={classnames('', {'is-invalid': errors[t('item')]})}
                                    label={t('item')}
                                    disabled={!fieldAccess || baseLocked}
                                    required
                                    data-cy="item"
                                />
                            </Grid>
                        }
                        {(!fieldAccess || baseLocked) && this.state.itemSel != null &&
                                <Grid item xs={8} sm={4}>
                                    <FormControl>
                                        <FormLabel>Item</FormLabel>
                                        <Link to={'/item/' + this.state.itemSel.value}>{this.state.itemSel.label}</Link>
                                    </FormControl>
                                </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            {fieldAccess && !locked &&
                                <TextField type="number" value={this.state.quantity} onChange={this.changeNumber} name="quantity"
                                size="medium" fullWidth={true} disabled={!fieldAccess || locked} label={t('quantity')} required data-cy="quantity"
                                error={errors[t('quantity')] != null ? true : false} helperText={errors[t('quantity')]}/>
                            }
                            {(!fieldAccess || locked) &&
                                <FormControl>
                                    <FormLabel>{t('quantity')}</FormLabel>
                                    {this.state.quantity != null && this.state.quantityUOMSel != null &&
                                        <NumericFormat displayType={'text'} value={this.state.quantity} thousandSeparator={true}
                                        decimalScale={3} suffix={this.state.quantityUOMSel.label}/>
                                    }
                                </FormControl>
                            }
                        </Grid>
                        {fieldAccess && !locked &&
                            <Grid item xs={2} sm={1}>
                                <AutoCompleteField
                                    value={this.state.quantityUOMSel}
                                    options={this.state.uoms}
                                    onChange={this.changeAuto('quantityUOMSel', 'quantityUOM')}
                                    error={errors[t('quantity')] != null ? true : false}
                                    helperText={errors[t('quantity')]}
                                    label={t('uom')}
                                    disabled={true}
                                    data-cy="quantityUOM"
                                />
                            </Grid>
                        }
                        {(!fieldAccess && !locked) &&
                            <Grid item xs={2} sm={1}>
                                <FormControl>
                                    <FormLabel>{t('uom')}</FormLabel>
                                    {this.state.quantityUOMSel != null ? this.state.quantityUOMSel.label : ''}
                                </FormControl>
                            </Grid>
                        }
                        {(!fieldAccess || locked) && this.state.discreteWeight != null && this.state.discreteWeight !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('discreteWeightG')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.discreteWeight} thousandSeparator={true}
                                        decimalScale={4} />
                                </FormControl>
                            </Grid>
                        }
                        {fieldAccess && !locked && this.state.hasDiscrete && !this.state.calcDiscrete &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.fullDiscreteWeight} onChange={this.changeNumber} name="fullDiscreteWeight"
                                size="medium" fullWidth={true} disabled={!fieldAccess || locked || this.state.calcDiscrete} label={t('fullDiscreteG')}
                                required data-cy="fullDiscreteWeight"
                                error={errors[t('fullDiscreteWeight')] != null ? true : false} helperText={errors[t('fullDiscreteWeight')]}/>
                            </Grid>

                        }
                        {(!fieldAccess || locked || this.state.calcDiscrete) && this.state.fullDiscreteWeight != null && this.state.fullDiscreteWeight !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('fullDiscreteG')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.fullDiscreteWeight} thousandSeparator={true}
                                        decimalScale={3} />
                                </FormControl>
                            </Grid>
                        }
                        {this.state.hasCount &&
                            <Grid item xs={4} sm={2}>
                                {fieldAccess && !locked &&
                                    <TextField type="number" value={this.state.count} onChange={this.changeNumber} name="count"
                                    size="medium" fullWidth={true} disabled={!fieldAccess || locked} label={t('count')} data-cy="count"
                                    error={errors[t('count')] != null ? true : false} helperText={errors[t('count')]}/>
                                }
                            </Grid>
                        }
                        {(!fieldAccess || locked) && this.state.count != null && this.state.count !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('count')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.count} thousandSeparator={true}
                                    decimalScale={0}/>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.isHemp === true &&
                            <Grid item xs={2} sm={1}>
                                <FormControl>
                                    <FormLabel>{t('hemp')}</FormLabel>
                                    {t('yes')}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.item != null && this.state.item !== '' && this.state.lots != null && this.state.lots.length > 0 &&
                            <Aux>
                                {fieldAccess && !baseLocked && this.state.manual &&
                                    <Grid item xs={6} sm={3}>
                                        <AutoCompleteField
                                            value={this.state.lotSel}
                                            options={this.state.lots}
                                            onChange={this.changeAuto('lotSel', 'lot')}
                                            error={errors[t('lot')] != null ? true : false}
                                            helperText={errors[t('lot')]}
                                            className={classnames('', {'is-invalid': errors[t('lot')]})}
                                            label={t('lot')}
                                            disabled={!fieldAccess || baseLocked}
                                            data-cy="lot"
                                        />
                                    </Grid>
                                }
                            </Aux>
                        }
                         {(!fieldAccess || baseLocked) && this.state.lotObject != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('lot')}</FormLabel>
                                    <Link to={'/lot/' + this.state.lotObject._id}>{this.state.lotObject.number}</Link>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.entryType === this.props.auth.constants.entryTypes.positive && this.state.itemSel != null && this.state.itemSel.lotTracked && 
                        this.state.itemSel.customNumber && fieldAccess && !locked && this.state.lotSel == null &&
                            <Grid item xs={4} sm={2}>
                                <TextField type="text" value={this.state.customLot} onChange={this.changeValue} name="customLot"
                                size="medium" fullWidth={true} disabled={!fieldAccess || locked}
                                label={t('customLotNumber')} data-cy="customLot"
                                className={classnames('', {'is-invalid': errors[t('customLot')]})}
                                error={errors[t('customLot')] != null ? true : false} helperText={errors[t('customLot')]}/>
                            </Grid>
                        }
                        {this.state.lot != null && this.state.lot !== '' && this.state.containers != null && this.state.containers.length > 0 &&
                            <Aux>
                                {fieldAccess && !baseLocked && this.state.manual &&
                                    <Grid item xs={8} sm={4}>
                                        <AutoCompleteField
                                            value={this.state.containerSel}
                                            options={this.state.containers}
                                            onChange={this.changeAuto('containerSel', 'container')}
                                            error={errors[t('container')] != null ? true : false}
                                            helperText={errors[t('container')]}
                                            className={classnames('', {'is-invalid': errors[t('container')]})}
                                            label={t('container')}
                                            disabled={!fieldAccess || baseLocked}
                                            data-cy="container"
                                            groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                            noneMessage={t('noLotAssigned')}
                                        />
                                    </Grid>
                                }
                            </Aux>
                        }
                        {(!fieldAccess || baseLocked) && this.state.containerObject != null &&
                            <Grid item xs={8} sm={4}>
                                <FormControl>
                                    <FormLabel>{t('container')}</FormLabel>
                                    <Link to={'/container/' + this.state.containerObject._id}>{this.state.containerObject.number}</Link>
                                </FormControl>
                            </Grid>
                        }
                        {(this.state.container == null || this.state.container === '') &&
                            <Grid item xs={8} sm={4}>
                                <AutoCompleteField
                                    value={this.state.binSel}
                                    options={this.state.bins}
                                    onChange={this.changeAuto('binSel', 'bin')}
                                    error={errors[t('bin')] != null ? true : false}
                                    helperText={errors[t('bin')]}
                                    label={t('bin')}
                                    disabled={!fieldAccess || locked}
                                    required
                                    data-cy="bin"
                                    groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                    noneMessage={t('noRoomSelected')}
                                />
                            </Grid>
                        }
                        {this.state.container != null && this.state.container !== '' && this.state.binSel != null &&
                            <Grid item xs={8} sm={4}>
                                <FormControl>
                                    <FormLabel>{t('bin')}</FormLabel>
                                    <Link to={'/bin/' + this.state.binSel.value}>{this.state.binSel.label}</Link>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.remainingQuantity != null && this.state.remainingQuantity !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('remaining')}</FormLabel>
                                    {this.state.remainingQuantity}
                                </FormControl>
                            </Grid>
                        }
                        {dateUnlock &&
                            <Grid item xs={6} sm={4}>
                                <AutoCompleteField
                                    value={this.state.processingTypeSel}
                                    options={this.state.processingTypes}
                                    onChange={this.changeAuto('processingTypeSel', 'processingType')}
                                    error={errors[t('processingType')] != null ? true : false}
                                    helperText={errors[t('processingType')]} 
                                    label={t('processingType')}
                                    disabled={!fieldAccess || !dateUnlock}
                                    data-cy="processingType"
                                />
                            </Grid>
                        }
                        {this.state.processingTypeSel != null && !dateUnlock &&
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <FormLabel>{t('processingType')}</FormLabel>
                                    {this.state.processingTypeSel.label}
                                </FormControl>
                            </Grid>
                        }
                        {locked && this.state.manual &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('manual')}</FormLabel>
                                    {t('yes')}
                                </FormControl>
                            </Grid>
                        }
                        {locked && this.state.ctlsExclude &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('ctlsExclude')}</FormLabel>
                                    {t('yes')}
                                </FormControl>
                            </Grid>
                        }
                        {this.props.auth.user.hasERP === true &&
                            <Grid item xs={6} sm={3}>
                                <CheckboxField data-cy="isolated"
                                checked={this.state.isolated} onChange={this.changeBool('isolated')}
                                label={t('isolated')} name="isolated" disabled={!fieldAccess || locked}
                                />
                            </Grid>
                        }
                        {this.state.reverse && 
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{this.state.reversedRecord != null && this.state.reversedRecord !== '' ? t('reverse') : t('reversed')}</FormLabel>
                                    {t('yes')}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.reversedRecord != null && this.state.reversedRecord !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('reversedRecord')}</FormLabel>
                                    <Link to={'/itemledger/' + this.state.reversedRecord._id}>{this.state.reversedRecord.number}</Link>
                                </FormControl>
                            </Grid>
                        }
                        {this.state.cannabisWeight != null && this.state.cannabisWeight !== '' && this.state.cannabisWeight !== 0 &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('cannabisWeight')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.cannabisWeight} thousandSeparator={true}
                                        decimalScale={3} />
                                </FormControl>
                            </Grid>
                        }
                        {this.state.thcAmount != null && this.state.thcAmount !== '' && this.state.thcAmount !== 0 &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('thc')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.thcAmount} thousandSeparator={true}
                                        decimalScale={3} />
                                </FormControl>
                            </Grid>
                        }
                        {this.state.packages != null && this.state.packages !== '' && this.state.packages !== 0 &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('packages')}</FormLabel>
                                    <NumericFormat displayType={'text'} value={this.state.packages} thousandSeparator={true}
                                        decimalScale={0} />
                                </FormControl>
                            </Grid>
                        }
                        {locked &&
                            <Grid item xs={8} sm={4}>
                                <FormControl>
                                    <FormLabel>{t('user')}</FormLabel>
                                    {this.state.user != null ? this.state.user.firstName + ' ' + this.state.user.lastName : ''}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item sm={12}>
                            {fieldAccess && !locked &&
                                <TextField value={this.state.description} onChange={this.changeValue} name="description"
                                size="medium" fullWidth={true} disabled={!fieldAccess || locked} label={t('description')}
                                className={classnames('', {'is-invalid': errors[t('description')]})} data-cy="description"
                                error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                            }
                            {(!fieldAccess || locked) &&
                                <FormControl>
                                    <FormLabel>{t('description')}</FormLabel>
                                    {this.state.description}
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            <SaveButton saveClick={this.save} enabled={fieldAccess && !locked && this.state.enableSave}/>
                            {this.state.manual && this.props.permission.unlock && locked &&
                                <Aux>
                                    <SimpleUnlockButton enabled={true} simpleUnlockClick={this.unlock}/>&nbsp;&nbsp;
                                </Aux>
                            }
                            {internal && locked &&
                                <Aux>
                                        <Button variant="contained" size="small" color="secondary" onClick={this.unlockDate} data-cy="unlockDate">
                                            {dateUnlock ? 'Lock' : 'Unlock'}
                                        </Button>&nbsp;&nbsp;
                                        {dateUnlock &&
                                            <Button variant="contained" size="small" color="secondary" onClick={this.saveDate} data-cy="saveDate">
                                                Save Update
                                            </Button>
                                        }
                                </Aux>
                            }
                        </Grid>
                    </Grid>
                </Panel>
                {this.state._id != null && this.state._id !== '' &&
                    <Aux>
                        <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.itemLedger}/>
                        <ObjectLog id={this.state._id}  renderNum={this.state.renderNum}/>
                    </Aux>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ItemLedger));
