import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import { CSVDownload } from "react-csv";
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete';
import dateFormat from 'date-fns/format';
import AddIcon from '@mui/icons-material/Add';
import ActionBar from '../UI/Buttons/ActionBar';
import ConversionHelper from '../../helpers/conversionHelper';
import listHelper from '../../helpers/listHelper';
import ListMultiSelect from '../UI/ListMultiSelect/ListMultiSelect';

const ItemLedgers = (props) => {
    const isFirstRender = useRef(true);
    const [loadRefData, setLoadRefData] = useState(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [itemSel, setItemSel] = useState(null);
    const [csvOutput, setCsvOutput] = useState({headers: null, data: null});
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [columnOrder, setColumnOrder] = useState([]);
    const [values, setValues] = useState({items: [], categories: [], reportCategories: [], entryTypes: [], locations: [], adjustmentReasons: [], processingTypes: [], objectTypes: []});
    const { t } = useTranslation('translation');
    const csvSeparator = sessionStorage.getItem('csvSeparator');
    const tenant = props.auth.user.tenant;
    const [categoriesSel, setCategoriesSel] = useState({ids: [], values: []});
    const [entryTypesSel, setEntryTypesSel] = useState({ids: [], values: []});
    const [showSelect, setShowSelect] = useState(false);
    const crumbs = [
        { path: 'active', label: t('itemLedgers'), screen: props.auth.screenDefs.ItemLedgerList}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        async function fetchData(){
            const columnFilters = await listHelper.getItem('ItemLedgerListColumnFilters', tenant);
            const columnVisibility = await listHelper.getItem('ItemLedgerListColumnVisibility', tenant);
            const density = await listHelper.getItem('ItemLedgerListDensity', tenant);
            const globalFilter = await listHelper.getItem('ItemLedgerListGlobalFilter', tenant);
            const showGlobalFilter = await listHelper.getItem('ItemLedgerListShowGlobalFilter', tenant);
            const showColumnFilters = await listHelper.getItem('ItemLedgerListShowColumnFilters', tenant);
            const sorting = await listHelper.getItem('ItemLedgerListSorting', tenant);
            const pagination = await listHelper.getItem('ItemLedgerListPagination', tenant);
            const tempColumnOrder = await listHelper.getItem('ItemLedgersColumnOrder', tenant);
            if (columnFilters) {
                let filters = ConversionHelper.filtersDateParse(JSON.parse(columnFilters));
                setColumnFilters(filters);
                let item = filters.find(x => x.id === 'item');
                if(item != null && values.items != null && values.items.length > 0) {
                    if(item.value != null){
                        let sel = values.items.find(x => x.value === item.value);
                        setItemSel(sel);
                    }
                }
                let cat = filters.find(x => x.id === 'category');
                if(cat != null){
                    let sel = {ids: cat.value, values: []};
                    cat.value.forEach(id => {
                        let val = values.categories.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setCategoriesSel(sel);
                }
                let entryType = filters.find(x => x.id === 'entryType');
                if(entryType != null){
                    let sel = {ids: entryType.value, values: []};
                    entryType.value.forEach(id => {
                        let val = values.entryTypes.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setEntryTypesSel(sel);
                }
            }
            if (columnVisibility) {
                setColumnVisibility(columnVisibility);
            }
            if (density) {
                setDensity(density);
            }
            if (globalFilter) {
                setGlobalFilter(globalFilter);
            }
            if (showGlobalFilter) {
                setShowGlobalFilter(showGlobalFilter);
            }
            if (showColumnFilters) {
                setShowColumnFilters(showColumnFilters);
            }
            if (sorting) {
                setSorting(sorting);
            }
            if (pagination) {
                setPagination(pagination);
            }
            if (tempColumnOrder && tempColumnOrder.length > 0) {
                setColumnOrder(tempColumnOrder);
            } else {
                setColumnOrder(['number', 'postingDate', 'quantity', 'item', 'category', 'hcReportCategory', 'entryType', 'objectType', 'lastUpdated', 'reason', 'processingType', 'location','cannabisWeight', 'thcAmount', 'packages', 'manual', 'reverse']);
            }
            isFirstRender.current = false;
        }
        if(isFirstRender.current && columnOrder.length === 0){
            fetchData();
        }
    }, [values, columnOrder, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ItemLedgerListColumnFilters', tenant, JSON.stringify(columnFilters));
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ItemLedgerListColumnVisibility', tenant, columnVisibility);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnVisibility, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ItemLedgerListDensity', tenant, density);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [density, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ItemLedgerListGlobalFilter', tenant, globalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [globalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ItemLedgerListShowGlobalFilter', tenant, showGlobalFilter);
        }
        if (isFirstRender.current) return;
        fetchData()
    }, [showGlobalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ItemLedgerListShowColumnFilters', tenant, showColumnFilters);
        }
        if (isFirstRender.current) return;
        fetchData();

    }, [showColumnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ItemLedgerListSorting', tenant, sorting);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [sorting, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ItemLedgerListPagination', tenant, pagination);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [pagination, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ItemLedgersColumnOrder', tenant, columnOrder);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnOrder, tenant]);

    const resetState = () => {
        listHelper.removeItem('ItemLedgerListColumnFilters', tenant);
        listHelper.removeItem('ItemLedgerListColumnVisibility', tenant);
        listHelper.removeItem('ItemLedgerListDensity', tenant);
        listHelper.removeItem('ItemLedgerListGlobalFilter', tenant);
        listHelper.removeItem('ItemLedgerListShowGlobalFilter', tenant);
        listHelper.removeItem('ItemLedgerListShowColumnFilters', tenant);
        listHelper.removeItem('ItemLedgerListSorting', tenant);
        listHelper.removeItem('ItemLedgerListPagination', tenant);
        listHelper.removeItem('ItemLedgersColumnOrder', tenant);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.allItems, common.cacheValues.location, common.cacheValues.itemCategory, common.cacheValues.hcReportCategory,
                common.cacheValues.object, common.cacheValues.ledgerEntryType, common.cacheValues.adjustmentReason, common.cacheValues.processingType],
                props.auth.constants, null, props.auth.user.tenant);
            let item = columnFilters.find(x => x.id === 'item');
            if(item != null) {
                if(item.value != null){
                    let sel = cacheValues.allItems.find(x => x.value === item.value);
                    setItemSel(sel);
                }
            }
            let cat = columnFilters != null ? columnFilters.find(x => x.id === 'category') : null;
            if(cat != null){
                let sel = {ids: cat.value, values: []};
                cat.value.forEach(id => {
                    let val = cacheValues.itemCategories.find(x => x.value === id);
                    sel.values.push(val);
                });
                setCategoriesSel(sel);
            }
            let entryType = columnFilters != null ? columnFilters.find(x => x.id === 'entryType') : null;
            if(entryType != null){
                let sel = {ids: entryType.value, values: []};
                entryType.value.forEach(id => {
                    let val = cacheValues.ledgerEntryTypes.find(x => x.value === id);
                    sel.values.push(val);
                });
                setEntryTypesSel(sel);
            }
            setValues({
                items: cacheValues.allItems,
                locations: cacheValues.locations,
                entryTypes: cacheValues.ledgerEntryTypes,
                adjustmentReasons: cacheValues.adjustmentReasons,
                categories: cacheValues.itemCategories,
                reportCategories: cacheValues.hcReportCategories,
                processingTypes: cacheValues.processingTypes,
                objectTypes: cacheValues.objects,
            });
            setLoadRefData(false);
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, columnFilters, loadRefData]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'itemLedgers',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/itemledgerquery', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });

    let columns = useMemo(() => [
        {
            accessorKey: 'number',
            id: 'number',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/itemledger/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            ),
        },{
            accessorKey: 'postingDate',
            id: 'postingDate',
            header: t('postingDate'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat)}
                </Box>
            ),
        },
        {
            accessorKey: 'quantity',
            id: 'quantity',
            header: t('quantity'),
            enableColumnFilter: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
            ),
        },
        {
            accessorFn: (row) => `${row.item.number}-${row.item.name}`,
            id: 'item',
            header: t('item'),
            Filter: ({ header, column }) =>
                    <AutoComplete 
                        options={values.items}
                        getOptionLabel={(option) => option.label}
                        onChange={(e, newValue) => {
                            setItemSel(newValue);
                            column.setFilterValue(newValue != null ? newValue.value : null);
                        }}
                        value={itemSel}
                        renderInput={(params) => <TextField {...params} variant="standard" />}
                    />,
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/item/' + cell.row.original?.item._id}>{cell.getValue()}</Link>
                </Box>
            ),
        },
        {
            accessorKey: 'category',
            header: t('category'),
            id: 'category',
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='category'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.categories} 
                    setSelected={setCategoriesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={categoriesSel.ids} 
                    selectedSel={categoriesSel.values}
                    columnFilters={columnFilters}
                    screen='ItemLedgerList'
                    tenant={props.auth.user.tenant}/>
                </Box>
            ),
        },
        {
            accessorKey: 'hcReportCategory',
            header: t('reportCategory'),
            filterVariant: 'select',
            filterSelectOptions: values.reportCategories
        },
        {
            accessorKey: 'entryType',
            id: 'entryType',
            header: t('entryType'),
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='entryType'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.entryTypes} 
                    setSelected={setEntryTypesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={entryTypesSel.ids} 
                    selectedSel={entryTypesSel.values}
                    columnFilters={columnFilters}
                    screen='ItemLedgerList'
                    tenant={props.auth.user.tenant}/>
                </Box>
            ),
        },
        {
            accessorFn: (row) => row.objectType != null && row.record != null ? `${row.objectType.text}-${row.record.number}` : '',
            header: t('source'),
            id: 'objectType',
            filterVariant: 'select',
            filterSelectOptions: values.objectTypes,
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.row.original?.objectType != null && cell.row.original?.record != null ? <Link to={cell.row.original?.objectType.link + cell.row.original?.record._id}>{cell.getValue()}</Link> :  ''}
                </Box>
            ),
        },
        {
            accessorKey: 'lastUpdated',
            id: 'lastUpdated',
            header: t('updated'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() != null && cell.getValue !== '' ? dateFormat(Date.parse(cell.getValue()), common.dateTimeFormat): ''}
                </Box>
            ),
        }, 
        {
            accessorKey: 'reason',
            header: t('reason'),
            filterVariant: 'select',
            filterSelectOptions: values.adjustmentReasons
        },
        {
            accessorKey: 'processingType',
            header: t('processingType'),
            filterVariant: 'select',
            filterSelectOptions: values.processingTypes
        },
        {
            accessorKey: 'location',
            header: t('location'),
            filterVariant: 'select',
            filterSelectOptions: values.locations
        },
        {
            accessorKey: 'cannabisWeight',
            id: 'cannabisWeight',
            header: t('cannabisWeight'),
            enableColumnFilter: false,
            enableSorting: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
            ),
        }, 
        {
            accessorKey: 'thcAmount',
            id: 'thcAmount',
            header: t('thc'),
            enableColumnFilter: false,
            enableSorting: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={3}/>
            ),
        },
        {
            accessorKey: 'packages',
            id: 'packages',
            header: t('packages'),
            enableColumnFilter: false,
            enableSorting: false,
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ cell, column }) => (
                <NumericFormat value={cell.getValue()} displayType={'text'} thousandSeparator={true} decimalScale={0}/>
            ),
        },
        {
            accessorKey: 'manual',
            header: t('manual'),
            filterVariant: 'checkbox',
            Cell: ({ cell, column }) => (
                cell.getValue() === true ? t('yes') : ''
            ),
        }, {
            accessorKey: 'reverse',
            header: t('reverse'),
            filterVariant: 'checkbox',
            Cell: ({ cell, column }) => (
                cell.getValue() === true ? t('yes') : ''
            ),
        }
    ], [t, values, itemSel, props, categoriesSel, entryTypesSel, showSelect, columnFilters]);

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true,  columnVisibility: {id: false}},
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: false,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()} color="primary">
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('csv')}>
                    <IconButton onClick={() => csv()} color="primary">
                        <ExcelIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('clearListSettings')}>
                    <IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                {props.permission.create &&
                    <Tooltip arrow title={t('add')}>
                        <IconButton component={Link} to='/itemledger' color="primary">
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });

    const csv = async () => {
        setCsvOutput({headers: null, data: null});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axios.get('/api/itemledgercsv', {params: params});
        let headers = response.data.headers;
        let csvData = response.data.data;
        setCsvOutput({headers: headers, data: csvData});
    }

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs}>
                <ActionBar/>
            </BreadcrumbBar>
            {csvOutput != null && csvOutput.data != null &&
                <CSVDownload data={csvOutput.data} target="_blank" headers={csvOutput.headers} separator={csvSeparator}/>
            }
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
        </Aux>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(ItemLedgers);