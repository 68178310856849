import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Signature from '../General/Signature';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import TimeSelect from '../UI/TimeSelect/TimeSelect';
import TextField from '../UI/TextField/TextField';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Fieldset from '../UI/Fieldset/Fieldset';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField'
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AddBlankButton, ClearButton, TextOnlyClickButton,
         DeleteButton, SaveButton } from '../UI/Buttons/Buttons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { approveSigSecurity, completeSigSecurity, 
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import { format } from 'date-fns';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class RoomSettings extends Component {
    constructor(props){
        super(props);
        this.state = {
            room: {},
            _id: '',
            lightsOnTime: '',
            lightsOffTime: '',
            par: '',
            temperature: '',
            temperatureUOM: '',
            tempUoms: [],
            humidity: '',
            co2: false,
            co2Concentration: '',
            ingredients: [],
            ingredientId: '',
            ingredient: '',
            ingredientAmount: '',
            ingredientAmountUOM: '',
            waterTemperature: '',
            ph: '',
            ecPpm: '',
            ingredientSelect: [],
            managers: [],
            editIngredient: false,
            waterUOM: '',
            amountUOM: '',
            amountUoms: [],
            timeUoms: [],
            signed: false,
            feedings: [],
            editFeeding: false,
            feedingId: '',
            feedingTime: '',
            feedingDuration: '',
            feedingDurationUOM: '',
            feedingAmount: '',
            feedingAmountUOM: '',
            user: null,
            users: [],
            reviewer: null,
            signer: null,
            qas: [],
            status: '',
            statuses: [],
            statusSel: {
                value: '',
                label: ''
            },
            tenant: this.props.auth.user.tenant,
            number: '',
            tempUOMSelected: null,
            ingredientAmountUOMSelected: null,
            ingredientSelected: null,
            durationUOMSelected: null,
            amountUOMSelected: null,
            waterUOMSelected: null,
            lightInformation: '',
            fieldAccess: false, 
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false, 
            security: [],
            enableSave: true
        };
        this.sigPad = {};
        this.changeValue = this.changeValue.bind(this);
        this.setLightsOn = this.setLightsOn.bind(this);
        this.setLightsOff = this.setLightsOff.bind(this);
        this.setFeedingTime = this.setFeedingTime.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.status, common.cacheValues.quantityUOM, common.cacheValues.timeUOM], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        const params = queryString.parse(this.props.location.search);
        try {
            const values = await axios.get('/api/roomchangevalues');
            const status = this.props.auth.constants.statuses.new;
            const statusSel = cacheValues.statuses.find(x => x.value === status);
            const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
            this.setState({
                ingredientSelect: values.data.ingredients,
                managers: values.data.managers,
                tempUoms: values.data.tempUoms,
                amountUoms: cacheValues.quantityUOMs,
                timeUoms: cacheValues.timeUOMs,
                users: values.data.users,
                qas: values.data.qas,
                statuses: cacheValues.statuses,
                status: status,
                statusSel: statusSel,
                security: this.props.permission,
                fieldAccess: fieldAccess
            }, () => {
                if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                    this.loadRecord();
                }else {
                    const crumbs = [
                        { path: '/#/rooms', label: this.props.t('rooms'), screen: this.props.auth.screenDefs.Rooms},
                        { path: '/#/room/' + this.props.match.params.roomId, label: this.props.t('room'), screen: this.props.auth.screenDefs.Room },
                        { path: 'active', label: this.props.t('roomtSettings'), screen: this.props.auth.screenDefs.RoomSettings}
                    ]
                    this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
                }
            });
            let room = null;
            if(this.props.match.params.roomId != null && this.props.match.params.roomId !== ''){
                room = await axios.get('api/room/' + this.props.match.params.roomId);
                this.setState({room: room.data});
            }
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
            } else {
                let ingredients = [];
                const data = room.data;
                for(let i = 0; i < data.feeding.ingredients.length; i++){
                    ingredients.push({
                        _id: data.feeding.ingredients[i]._id,
                        amount: data.feeding.ingredients[i].amount,
                        amountUOM: {value: data.feeding.ingredients[i].amountUOM._id, 
                            label: data.feeding.ingredients[i].amountUOM.label},
                        ingredient: {value: data.feeding.ingredients[i].ingredient._id, 
                            label: data.feeding.ingredients[i].ingredient.label }
                    });
                }
                let feedings = [];
                if(data.feeding != null && data.feeding.feedings != null){
                    for(let i = 0; i < data.feeding.feedings.length; i++){
                        feedings.push({
                            _id: data.feeding.feedings[i]._id,
                            time: data.feeding.feedings[i].time,
                            duration: data.feeding.feedings[i].duration,
                            durationUOM: data.feeding.feedings[i].durationUOM != null ? {value: data.feeding.feedings[i].durationUOM._id,
                                label: data.feeding.feedings[i].durationUOM.label} : '',
                            amount: data.feeding.feedings[i].amount,
                            amountUOM: data.feeding.feedings[i].amountUOM != null ? {value: data.feeding.feedings[i].amountUOM._id, 
                                label: data.feeding.feedings[i].amountUOM.label} : ''
                        });
                    }
                }
                let tempUOMSelected = null;
                if(data.settings != null && data.settings.temperatureUOM != null)
                    tempUOMSelected = values.data.tempUoms.find(x => x.value === data.settings.temperatureUOM);
                let waterUOMSelected = null;
                if(data.feeding != null && data.feeding.waterUOM != null)
                    waterUOMSelected = values.data.tempUoms.find(x => x.value === data.feeding.waterUOM);
                this.setState({
                    lightsOnTime: data.settings != null && data.settings.lightsOnTime != null ?
                        data.settings.lightsOnTime : '',
                    lightsOffTime: data.settings != null && data.settings.lightsOffTime != null ?
                        data.settings.lightsOffTime : '',
                    par: data.settings != null && data.settings.par != null ? data.settings.par : '',
                    temperature: data.settings != null && data.settings.temperature != null ?
                        data.settings.temperature : '',
                    humidity: data.settings != null && data.settings.humidity != null ?
                        data.settings.humidity : '',
                    co2: data.settings != null && data.settings.co2 != null ? data.settings.co2 : '',
                    co2Concentration: data.settings != null && data.settings.co2Concentration != null ?
                        data.settings.co2Concentration : '',
                    ingredients: ingredients,
                    feedings: feedings != null ? feedings : [],
                    waterTemperature: data.feeding.waterTemperature != null ? 
                        data.feeding.waterTemperature : '',
                    ph: data.feeding.ph != null ? data.feeding.ph : '',
                    ecPpm: data.feeding.ecPpm != null ? data.feeding.ecPpm : '',
                    temperatureUOM: data.settings != null && data.settings.temperatureUOM != null ?
                        data.settings.temperatureUOM : '',
                    waterUOM: data.feeding.waterUOM != null ? data.feeding.waterUOM : '',
                    schedule: params != null && params.schedule != null ? params.schedule : null,
                    status: this.props.auth.constants.statuses.inprogress,
                    tempUOMSelected: tempUOMSelected,
                    waterUOMSelected: waterUOMSelected,
                    lightInformation: data.settings != null && data.settings.lightInformation != null && data.settings.lightInformation !== '' ?
                        data.settings.lightInformation : ''
                });
            }
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: this.props.t('error'), severity: 'error'}});
        }
    }

    async loadRecord(){
        const params = queryString.parse(this.props.location.search);
        const id = this.state._id != null && this.state._id !== '' ? this.state._id : this.props.match.params.id;
        const record = await axios.get('/api/roomchange/' + id);
        let ingredients = [];
        for(let i = 0; i < record.data.ingredients.length; i++){
            ingredients.push({
                _id: record.data.ingredients[i]._id,
                amount: record.data.ingredients[i].amount,
                ingredient: {value: record.data.ingredients[i].ingredient._id, 
                    label: record.data.ingredients[i].ingredient.label},
                amountUOM: {value: record.data.ingredients[i].amountUOM._id, 
                    label: record.data.ingredients[i].amountUOM.label}
            });
        }
        let feedings = [];
        for(let i = 0; i < record.data.feedings.length; i++){
            feedings.push({
                _id: record.data.feedings[i]._id,
                time: record.data.feedings[i].time,
                duration: record.data.feedings[i].duration,
                durationUOM: record.data.feedings[i].durationUOM != null ? {value: record.data.feedings[i].durationUOM._id, 
                    label: record.data.feedings[i].durationUOM.label} : '',
                amount: record.data.feedings[i].amount,
                amountUOM: record.data.feedings[i].amountUOM != null ? {value: record.data.feedings[i].amountUOM._id, 
                    label: record.data.feedings[i].amountUOM.label} : ''
            });
        }
        const signed = record.data.status === this.props.auth.constants.statuses.signed || 
            record.data.status === this.props.auth.constants.statuses.reviewed;
        const waterUOMSelected = this.state.tempUoms.find(x => x.value === record.data.waterUOM);
        const tempUOMSelected = this.state.tempUoms.find(x => x.value === record.data.temperatureUOM);
        const statusSel = this.state.statuses.find(x => x.value === record.data.status);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        this.setState({
            _id: record.data._id,
            lightsOnTime: record.data.lightsOnTime,
            lightsOffTime: record.data.lightsOffTime,
            par: record.data.par,
            temperature: record.data.temperature,
            humidity: record.data.humidity,
            co2: record.data.co2,
            co2Concentration: record.data.co2Concentration,
            ingredients: ingredients,
            feedings: feedings,
            waterTemperature: record.data.waterTemperature,
            waterUOM: record.data.waterUOM,
            ph: record.data.ph,
            ecPpm: record.data.ecPpm,
            temperatureUOM: record.data.temperatureUOM,
            signed: signed,
            status: record.data.status != null ? record.data.status : this.props.auth.constants.statuses.inprogress,
            schedule: params != null && params.schedule != null ? params.schedule : null,
            user: record.data.user,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            waterUOMSelected: waterUOMSelected,
            tempUOMSelected: tempUOMSelected,
            number: record.data.number,
            statusSel: statusSel,
            lightInformation: record.data.lightInformation,
            fieldAccess: fieldAccess,
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec
        }, () => {
            const crumbs = [
                { path: '/#/rooms', label: this.props.t('rooms'), screen: this.props.auth.screenDefs.Rooms},
                { path: '/#/room/' + this.props.match.params.roomId, label: record.data.name, screen: this.props.auth.screenDefs.Room },
                { path: 'active', label: this.props.t('roomtSettings'), screen: this.props.auth.screenDefs.RoomSettings}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    changeValue(e){
        const name = e.target.name;
        const value = e.target.value
        if(name === "par" || name === "temperature" || name === "humidity" || name === "co2Concentration" ||
            name === "ingredientAmount" || name === "feedingDuration" || name === "feedingAmount" || name === "waterTemperature" ||
            name === "ph" || name === "ecPpm"){
            if(isNaN(value)){
                return;
            }
        }
        this.setState({[name]: value});
    }

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let status = this.state.status;
        if( status === this.props.auth.constants.statuses.new ){
            status = this.props.auth.constants.statuses.inprogress;
        }
        let ingredients = [];
        this.state.ingredients.forEach(ingredient => {
            ingredients.push({
                ingredient: ingredient.ingredient.value,
                amount: ingredient.amount,
                amountUOM: ingredient.amountUOM.value
            });
        });
        let feedings = [];
        this.state.feedings.forEach(feeding => {
            feedings.push({
                time: feeding.time,
                duration: feeding.duration,
                durationUOM: feeding.durationUOM.value,
                amount: feeding.amount,
                amountUOM: feeding.amountUOM.value
            });
        });
        const data = {
            roomId: this.props.match.params.roomId,
            _id: this.state._id,
            lightsOnTime: this.state.lightsOnTime,
            lightsOffTime: this.state.lightsOffTime,
            par: this.state.par,
            temperature: this.state.temperature,
            humidity: this.state.humidity,
            co2: this.state.co2,
            co2Concentration: this.state.co2Concentration,
            ingredients: ingredients,
            feedings: feedings,
            waterTemperature: this.state.waterTemperature,
            ph: this.state.ph,
            ecPpm: this.state.ecPpm,
            temperatureUOM: this.state.temperatureUOM,
            waterUOM: this.state.waterUOM,
            schedule: this.state.schedule,
            status: status,
            lightInformation: this.state.lightInformation
        };
        try{
            const result = await axios.post('/api/roomchange', data);
            const statusSel = this.state.statuses.find(x => x.value === result.data.status);
            const completeSigSec = completeSigSecurity(this.props.permission.complete, result.data.user, result.data.status, this.props.auth.common, this.props.auth.constants);
            const reviewSigSec = reviewSigSecurity(this.props.permission.review, result.data.reviewer, result.data.status, this.props.auth.common, this.props.auth.constants);
            const approveSigSec = approveSigSecurity(this.props.permission.approve, result.data.signer, result.data.status, this.props.auth.common, this.props.auth.constants);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/roomsettings/' + this.state.room._id + '/' + result.data.id);
            this.setState({
                _id: result.data.id, 
                number: result.data.number,
                status: result.data.status,
                statusSel: statusSel, 
                completeSigSec: completeSigSec,
                reviewSigSec: reviewSigSec,
                approveSigSec: approveSigSec,
                enableSave: true
            })
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        }
    }

    validateForm(){
        let errors = {};
        const t = this.props.t;
        const blankMessage = t('required');
        if(this.state.par != null && this.state.par !== ''){
            const par = parseInt(this.state.par);
            if(par < 0 || par > 999){
                errors[t('par')] = t('parError');
            }
        }else {
            errors[t('par')] = blankMessage;
        }
        if(this.state.humidity != null && this.state.humidity !== ''){
            const humidity = parseInt(this.state.humidity);
            if(humidity < 0 || humidity > 100)
                errors[t('humidity')] = t('humidityError');
        }else {
            errors[t('humidity')] = blankMessage;
        }
        if(this.state.ph != null && this.state.ph !== ''){
            const ph = parseFloat(this.state.ph);
            if(ph < 0 || ph > 14)
                errors[t('ph')] = t('phError');
        }else {
            errors[t('ph')] = blankMessage;
        }
        if(this.state.temperature === null || this.state.temperature === '')
            errors[t('temperature')] = blankMessage;
        if(this.state.waterTemperature === null || this.state.waterTemperature === '')
            errors[t('waterTemperature')] = blankMessage;
        if(this.state.temperature != null && this.state.temperature !== '' && 
            (this.state.temperatureUOM == null || this.state.temperatureUOM === ''))
                errors[t('temperatureUOM')] = blankMessage;
        if(this.state.waterTemperature != null && this.state.waterTemperature !== '' && 
            (this.state.waterUOM == null || this.state.waterUOM === ''))
            errors[t('waterUOM')] = blankMessage;
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    setLightsOn(date){
        this.setState({lightsOnTime: date});
    }

    setLightsOff(date){
        this.setState({lightsOffTime: date});
    }

    setFeedingTime(date){
        this.setState({feedingTime: date});
    }

    changeNumber(name, e){
        this.setState({[name]: e.value});
    }

    changeSelect = (name, data) => value => {
        this.setState({[name]: value, [data]: value.value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };
    
    co2Changed(){
        this.setState({co2: !this.state.co2});
    }

    toggleIngredient(){
        this.setState({editIngredient: !this.state.editIngredient});
    }

    toggleFeeding(){
        this.setState({editFeeding: !this.state.editFeeding});
    }

    saveIngredient(){
        const valid = this.validateIngredient();
        if(valid){
            let ingredientType = {};
            for(let i = 0; i < this.state.ingredientSelect.length; i++){
                if(this.state.ingredientSelect[i].value === this.state.ingredient){
                    ingredientType = this.state.ingredientSelect[i];
                    break;
                }
            }
            let uomType = {};
            for (let i = 0; i < this.state.amountUoms.length; i++){
                if(this.state.amountUoms[i].value === this.state.ingredientAmountUOM){
                    uomType = this.state.amountUoms[i];
                    break;
                }
            }
            let ingredients = this.state.ingredients;
            let ingredient = null;
            let isNew = false;
            if(this.state.ingredientId !== ''){
                for(let i = 0; i < ingredients.length; i++){
                    if(ingredients[i]._id === this.state.ingredientId){
                        ingredient = ingredients[i];
                        break;
                    }
                }
            } 
            if(ingredient == null){
                isNew = true;
                ingredient = {};
                ingredient._id = 'a' + Math.floor(10000 * Math.random());
            }
            ingredient.ingredient = ingredientType;
            ingredient.amount = this.state.ingredientAmount;
            ingredient.amountUOM = uomType;
            if(isNew){
                ingredients.push(ingredient);
            }
            this.setState({ingredients: ingredients});
            this.clearIngredient();
        }
    }

    validateIngredient(){
        let errors = {};
        const t = this.props.t;
        if(this.state.ingredient == null || this.state.ingredient === ''){
            errors[t('ingredient')] = t('required');
        }
        if(this.state.ingredientAmount == null || this.state.ingredientAmount === ''){
            errors[t('ingredientAmount')] = t('required');
        }
        if(this.state.ingredientAmountUOM == null || this.state.ingredientAmountUOM === ''){
            errors[t('ingredientAmountUOM')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    saveFeeding(){
        const valid = this.validateFeeding();
        if(!valid)
            return;
        let uomType = {};
        for(let i = 0; i < this.state.amountUoms.length; i++){
            if(this.state.feedingAmountUOM === this.state.amountUoms[i].value){
                uomType = this.state.amountUoms[i];
                break;
            }
        }
        let durationUomType = {};
        for(let i = 0; i < this.state.timeUoms.length; i++){
            if(this.state.feedingDurationUOM === this.state.timeUoms[i].value){
                durationUomType = this.state.timeUoms[i];
                break;
            }
        }
        let feedings = this.state.feedings;
        let feeding = null;
        let isNew = false;
        if(this.state.feedingId !== ''){
            for(let i = 0; i < feedings.length; i++){
                if(this.state.feedingId === feedings[i]._id){
                    feeding = feedings[i];
                    break;
                }
            }
        }
        if(feeding == null){
            isNew = true;
            feeding = {};
            feeding._id = 'a' + Math.floor(10000 * Math.random());
        }
        feeding.time = this.state.feedingTime;
        feeding.duration = this.state.feedingDuration;
        feeding.durationUOM = durationUomType;
        feeding.amount = this.state.feedingAmount;
        feeding.amountUOM = uomType;
        if(isNew){
            feedings.push(feeding);
        }
        this.setState({feedings: feedings});
        this.clearFeeding();
    }

    validateFeeding(){
        let errors = {};
        const t = this.props.t;
        if(this.state.feedingDuration != null && this.state.feedingDuration !== '' && (
            this.state.feedingDurationUOM == null || this.state.feedingDurationUOM === ''))
            errors[t('feedingDurationUOM')] = t('required');
        if(this.state.feedingAmount != null && this.state.feedingAmount !== '' && (
            this.state.feedingAmountUOM == null || this.state.feedingAmountUOM === ''))
            errors[t('feedingAmountUOM')] = t('required');
        if((this.state.feedingTime == null || this.state.feedingTime === '') && 
            (this.state.feedingDuration == null || this.state.feedingDuration === '') &&
            (this.state.feedingAmount == null || this.state.feedingAmount === ''))
            errors[t('feedingTime')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    editClick(id){
        let selected = {};
        for(let i = 0; i < this.state.ingredients.length; i++){
            if(this.state.ingredients[i]._id === id){
                selected = this.state.ingredients[i];
                break;
            }
        }
        this.setState({
            editIngredient: true,
            ingredientId: selected._id,
            ingredient: selected.ingredient.value,
            ingredientAmount: selected.amount,
            ingredientAmountUOM: selected.amountUOM.value,
            ingredientAmountUOMSelected: selected.amountUOM,
            ingredientSelected: selected.ingredient
        });
    }

    editFeedingClick(id){
        let selected = {};
        for(let i = 0; i < this.state.feedings.length; i++){
            if(this.state.feedings[i]._id === id){
                selected = this.state.feedings[i];
                break;
            }
        }
        this.setState({
            editFeeding: true,
            feedingId: selected._id,
            feedingTime: selected.time,
            feedingDuration: selected.duration,
            feedingDurationUOM: selected.durationUOM.value,
            feedingAmount: selected.amount,
            feedingAmountUOM: selected.amountUOM.value,
            durationUOMSelected: selected.durationUOM,
            amountUOMSelected: selected.amountUOM
        });
    }

    clearIngredient(){
        this.setState({
            ingredient: '',
            ingredientId: '',
            ingredientAmount: '',
            ingredientAmountUOM: '',
            ingredientAmountUOMSelected: null,
            ingredientSelected: null
        });
    }

    clearFeeding(){
        this.setState({
            feedingId: '',
            feedingTime: '',
            feedingDuration: '',
            feedingDurationUOM: '',
            feedingAmount: '',
            feedingAmountUOM: '',
            durationUOMSelected: null,
            amountUOMSelected: null
        });
    }

    deleteIngredient(){
        let ingredients = this.state.ingredients;
        for(let i = 0; i < ingredients.length; i++){
            if(ingredients[i]._id === this.state.ingredientId){
                ingredients.splice(i, 1);
                break;
            }
        }
        this.setState({ingredients: ingredients, editIngredient: false});
        this.clearIngredient();
    }

    deleteFeeding(){
        let feedings = this.state.feedings;
        for(let i = 0; i < feedings.length; i++){
            if(feedings[i]._id === this.state.feedingId){
                feedings.splice(i, 1);
                break;
            }
        }
        this.setState({feedings: feedings, editFeeding: false});
        this.clearFeeding();
    }

    refresh = e => {
        this.setState({user: e});
        this.loadRecord();
    }

    render(){
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const completeSigSec = this.state.completeSigSec;
        const reviewSigSec = this.state.reviewSigSec;
        const approveSigSec = this.state.approveSigSec;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Grid container spacing={2}>
                    <Grid item lg={this.state.statusSel.label !== t('new') && 
                                this.state.completeSigSec ? 
                                9 : 12} sm={12} >
                        <Panel>
                            <SectionTitle title={t('roomSettings') + " - " + this.state.room.name} />
                            <Grid container spacing={3}>
                                <Grid container item spacing={2}>
                                    <Grid item xs={6} sm={3}>
                                        <TextField value={this.state.statusSel != null ? this.state.statusSel.label : ''}  name="status"
                                        size="medium" fullWidth={true} disabled={true} label={t('status')}/>
                                    </Grid>
                                    {this.state._id != null && this.state._id !== '' &&
                                        <Grid item xs={6} sm={3}>
                                            <TextField value={this.state.number}  name="number"
                                            size="medium" fullWidth={true} disabled={true} label={t('number')}/>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Fieldset name={t('lightSettings')}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} sm={4}>
                                                <TimeSelect
                                                    onChange={date => this.setLightsOn(date)}
                                                    value={this.state.lightsOnTime}
                                                    helperText={errors[t('lightsOnTime')]}
                                                    error={errors[t('lightsOnTime')] != null ? true : false}
                                                    disabled={!fieldAccess}
                                                    label={t('lightsOn')}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={4}>
                                                <TimeSelect
                                                    onChange={date => this.setLightsOff(date)}
                                                    value={this.state.lightsOffTime}
                                                    helperText={errors[t('lightsOffTime')]}
                                                    error={errors[t('lightsOffTime')] != null ? true : false}
                                                    disabled={!fieldAccess}
                                                    label={t('lightsOff')}
                                                />
                                            </Grid>
                                            <Grid item xs={4} sm={3}>
                                                <TextField type="number" value={this.state.par} onChange={this.changeValue} name="par"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('par')} required
                                                error={errors[t('par')] != null ? true : false} helperText={errors[t('par')]}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField value={this.state.lightInformation} onChange={this.changeValue} name="lightInformation"
                                                multiline={true} rows="3"
                                                variant="outlined" size="medium" fullWidth={true} disabled={!fieldAccess}
                                                label={t('lightInformation')}
                                                error={errors[t('lightInformation')] != null ? true : false} helperText={errors[t('lightInformation')]}/>
                                            </Grid>
                                        </Grid>
                                    </Fieldset>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Fieldset name="Environment Settings">
                                        <Grid container spacing={2}>
                                            <Grid container item xs={12} spacing={2}>
                                                <Grid item xs={4} md={3}>
                                                    <TextField type="number" value={this.state.temperature} onChange={this.changeValue} name="temperature"
                                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('temperature')} required
                                                    error={errors[t('temperature')] != null ? true : false} helperText={errors[t('temperature')]}/>
                                                </Grid>
                                                <Grid item xs={4} md={3}>
                                                    <AutoCompleteField
                                                        value={this.state.tempUOMSelected}
                                                        options={this.state.tempUoms}
                                                        onChange={this.changeAuto('tempUOMSelected', 'temperatureUOM')}
                                                        error={errors[t('temperatureUOM')] != null ? true : false}
                                                        helperText={errors[t('temperatureUOM')]}
                                                        label={t('uom')}
                                                        disabled={!fieldAccess}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid item xs={4} md={3}>
                                                    <TextField type="number" value={this.state.humidity} onChange={this.changeValue} name="humidity"
                                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('humidityPercent')} required
                                                    error={errors[t('humidity')] != null ? true : false} helperText={errors[t('humidity')]}/>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <CheckboxField 
                                                    checked={this.state.co2} onChange={() => this.co2Changed()}
                                                    label={t('co2')} name="co2" disabled={!fieldAccess}
                                                />
                                            </Grid>
                                            {this.state.co2 &&
                                                <Grid item xs={8} sm={4}>
                                                    <TextField type="number" value={this.state.co2Concentration} onChange={this.changeValue} name="co2Concentration"
                                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('co2Concentration')} 
                                                    error={errors[t('co2Concentration')] != null ? true : false} helperText={errors[t('co2Concentration')]}/>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Fieldset>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Fieldset name={t('fertigationSettings')}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} sm={3}>
                                                <TextField type="number" value={this.state.waterTemperature} onChange={this.changeValue} name="waterTemperature"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('waterTemp')} required
                                                error={errors[t('waterTemperature')] != null ? true : false} helperText={errors[t('waterTemperature')]}/>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <AutoCompleteField
                                                    value={this.state.waterUOMSelected}
                                                    options={this.state.tempUoms}
                                                    onChange={this.changeAuto('waterUOMSelected', 'waterUOM')}
                                                    error={errors[t('waterUOM')] != null ? true : false}
                                                    helperText={errors[t('waterUOM')]}
                                                    label={t('uom')}
                                                    disabled={!fieldAccess}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <TextField type="number" value={this.state.ph} onChange={this.changeValue} name="ph"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('ph')} required
                                                error={errors[t('ph')] != null ? true : false} helperText={errors[t('ph')]}/>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <TextField type="number" value={this.state.ecPpm} onChange={this.changeValue} name="ecPpm"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('ecPpm')} required
                                                error={errors[t('ecPpm')] != null ? true : false} helperText={errors[t('ecPpm')]}/>
                                            </Grid>
                                        </Grid>
                                        <br />
                                        <SubSectionHeading title={t('ingredients')}/>
                                        {this.state.ingredients.length > 0 &&
                                            <Aux>
                                                <TableContainer component={Paper}>
                                                    <Table aria-label="Ingredients">
                                                        <TableHead>
                                                            <TableRow key="tableHeader">
                                                                <TableCell variant="head">{t('ingredient')}</TableCell>
                                                                <TableCell variant="head">{t('amount')}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.ingredients.map((row, i) =>
                                                                <TableRow key={row._id}>
                                                                    <TableCell>
                                                                        <TextOnlyClickButton enabled={true} click={() => this.editClick(row._id)} name={row.ingredient.label}/>
                                                                    </TableCell>
                                                                    <TableCell>{row.amount + row.amountUOM.label}</TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <br/>
                                            </Aux>
                                        }
                                        {!this.state.editIngredient && fieldAccess &&
                                            <Aux>
                                                <AddBlankButton 
                                                    addClick={() => this.toggleIngredient()} 
                                                    enabled={fieldAccess} 
                                                    size="small" 
                                                    buttonName={t('add')} />
                                                <br/>
                                            </Aux>
                                        }
                                        {this.state.editIngredient &&
                                            <Aux>
                                                <Dialog open={this.state.editIngredient} onClose={() => this.toggleIngredient()} aria-labelledby="dialog-ingredient">
                                                    <DialogTitle aria-labelledby="dialog-ingredient-title">{t('ingredient')}</DialogTitle>
                                                    <DialogContent>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={10}>
                                                                <AutoCompleteField
                                                                    value={this.state.ingredientSelected}
                                                                    options={this.state.ingredientSelect}
                                                                    onChange={this.changeAuto('ingredientSelected', 'ingredient')}
                                                                    error={errors[t('ingredient')] != null ? true : false}
                                                                    helperText={errors[t('ingredient')]}
                                                                    label={t('ingredient')}
                                                                    disabled={!fieldAccess}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField type="number" value={this.state.ingredientAmount} onChange={this.changeValue} name="ingredientAmount"
                                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('amount')}required
                                                                error={errors[t('ingredientAmount')] != null ? true : false} helperText={errors[t('ingredientAmount')]}/>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <AutoCompleteField
                                                                    value={this.state.ingredientAmountUOMSelected}
                                                                    options={this.state.amountUoms}
                                                                    onChange={this.changeAuto('ingredientAmountUOMSelected', 'ingredientAmountUOM')}
                                                                    error={errors[t('ingredientAmountUOM')] != null ? true : false}
                                                                    helperText={errors[t('ingredientAmountUOM')]}
                                                                    label={t('uom')}
                                                                    disabled={!fieldAccess}
                                                                    required
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <SaveButton 
                                                            saveClick={() => this.saveIngredient()} 
                                                            enabled={fieldAccess} 
                                                        />
                                                        <ClearButton clearClick={() => this.clearIngredient()} enabled={fieldAccess} />
                                                        <DeleteButton deleteClick={() => this.deleteIngredient()} enabled={fieldAccess} />
                                                    </DialogActions>
                                                </Dialog>
                                            </Aux>
                                        }
                                        <br />
                                        <SubSectionHeading title={t('feedings')} />
                                        {this.state.feedings.length > 0 &&
                                            <Aux>
                                                <TableContainer component={Paper}>
                                                    <TableHead>
                                                        <TableRow key="feedingTableHeader">
                                                            <TableCell variant="head">{t('time')}</TableCell>
                                                            <TableCell variant="head">{t('duration')}</TableCell>
                                                            <TableCell variant="head">{t('amount')}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.feedings.map((row, i) =>
                                                            <TableRow key={row._id}>
                                                                <TableCell>
                                                                    {row.time != null && row.time !== '' &&
                                                                        <TextOnlyClickButton enabled={true} click={() => this.editFeedingClick(row._id)} 
                                                                        name={format(Date.parse(row.time), 'h:m aa')}/>
                                                                    }
                                                                </TableCell>
                                                                <TableCell>{row.duration + row.durationUOM.label}</TableCell>
                                                                <TableCell>{row.amount + row.amountUOM.label}</TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </TableContainer>
                                            </Aux>
                                        }
                                        {!this.state.editFeeding && fieldAccess &&
                                            <Aux>
                                                <br />
                                                <AddBlankButton 
                                                    addClick={() => this.toggleFeeding()} 
                                                    size="small" 
                                                    enabled={fieldAccess} 
                                                    buttonName={t('add')} />
                                            </Aux>
                                        }
                                        {this.state.editFeeding &&
                                            <Aux>
                                            <Dialog open={this.state.editFeeding} onClose={() => this.toggleFeeding()} aria-labelledby="dialog-feeding">
                                                <DialogTitle aria-labelledby="dialog-feeding-title">{t('feeding')}</DialogTitle>
                                                <DialogContent>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={4}>
                                                            <TimeSelect
                                                                onChange={date => this.setFeedingTime(date)}
                                                                value={this.state.feedingTime}
                                                                helperText={errors[t('feedingTime')]}
                                                                error={errors[t('feedingTime')] != null ? true : false}
                                                                disabled={!fieldAccess}
                                                                label={t('time')}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField type="number" value={this.state.feedingDuration} onChange={this.changeValue} name="feedingDuration"
                                                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('duration')} required
                                                            error={errors[t('feedingDuration')] != null ? true : false} helperText={errors[t('feedingDuration')]}/>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <AutoCompleteField
                                                                value={this.state.durationUOMSelected}
                                                                options={this.state.timeUoms}
                                                                onChange={this.changeAuto('durationUOMSelected', 'feedingDurationUOM')}
                                                                error={errors[t('feedingDurationUOM')] != null ? true : false}
                                                                helperText={errors[t('feedingDurationUOM')]}
                                                                label={t('uom')}
                                                                disabled={!fieldAccess}
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField type="number" value={this.state.feedingAmount} onChange={this.changeValue} name="feedingAmount"
                                                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('amount')} required
                                                            error={errors[t('feedingAmount')] != null ? true : false} helperText={errors[t('feedingAmount')]}/>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <AutoCompleteField
                                                                value={this.state.amountUOMSelected}
                                                                options={this.state.amountUoms}
                                                                onChange={this.changeAuto('amountUOMSelected', 'feedingAmountUOM')}
                                                                error={errors[t('feedingAmountUOM')] != null ? true : false}
                                                                helperText={errors[t('feedingAmountUOM')]}
                                                                label={t('uom')}
                                                                disabled={!fieldAccess}
                                                                required
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </DialogContent>
                                                <DialogActions>
                                                    <SaveButton 
                                                        saveClick={() => this.saveFeeding()} 
                                                        enabled={fieldAccess} 
                                                    />
                                                    <ClearButton clearClick={() => this.clearFeeding()} enabled={fieldAccess} />
                                                    <DeleteButton deleteClick={() => this.deleteFeeding()} enabled={fieldAccess} />
                                                </DialogActions>
                                            </Dialog>
                                        </Aux>
                                        }
                                    </Fieldset>
                                </Grid>
                            </Grid>
                            {this.state._id != null && this.state._id !== '' &&
                                <Aux>
                                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.roomSetting}/>
                                    {(this.props.permission.review || this.props.permission.approve) &&
                                        <ObjectLog id={this.state._id}/>
                                    }
                                </Aux>
                            }
                        </Panel>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        {completeSigSec &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="rs-panel1a-completesig"
                                    id="rs-panel-completesig-header"
                                >
                                    <Typography>Completed By</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Signature users={this.state.users} name={t('completedBy')}status={this.state.status}
                                    title={t('completionSignature')} setId={this.refresh} object={this.state._id} signature={this.state.user}
                                    objectType={this.props.auth.constants.objectIds.roomSetting} type={this.props.auth.constants.signatureTypes.user}
                                    user={this.props.auth.user.id} permission={this.props.permission.complete}/>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {reviewSigSec &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1b-reviewersig"
                                    id="panel-reviewersig-header"
                                >
                                    <Typography>{t('reviewedBy')}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Signature users={this.state.managers} name={t('reviewer')} status={this.state.status}
                                    title={t('reviewSignature')}setId={this.refresh} object={this.state._id} signature={this.state.reviewer}
                                    objectType={this.props.auth.constants.objectIds.roomSetting} type={this.props.auth.constants.signatureTypes.reviewer}
                                    user={this.props.auth.user.id} permission={this.props.permission.review }/>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {approveSigSec &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1c-approversig"
                                    id="panel-approversig-header"
                                >
                                    <Typography>{t('approvedBy')}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Signature users={this.state.qas} name={t('signoff')} status={this.state.status}
                                    title={t('signoffSignature')} setId={this.refresh} object={this.state._id} signature={this.state.signer}
                                    objectType={this.props.auth.constants.objectIds.roomSetting} type={this.props.auth.constants.signatureTypes.signer}
                                    user={this.props.auth.user.id} permission={this.props.permission.approve} />
                                </AccordionDetails>
                            </Accordion>
                        }
                    </Grid>
                </Grid>
                <FormButtonStack 
                    id={this.state._id}
                    objectType={this.props.auth.constants.objectIds.roomSetting}
                    callback={this.refresh}
                    saveClick={() => this.saveClick()}
                    // printClick={'/#/roomsettingsprint/' + this.state._id}
                    status={this.state.status}
                    permissions={this.props.permission}
                    common={this.props.auth.common}
                    constants={this.props.auth.constants}
                    saveEnabled={this.state.enableSave}
                />
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(RoomSettings));