import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux'; 
import ObjectLog from '../General/ObjectLog';
import isEmpty from '../../is-empty';
import Paper from '@mui/material/Paper';
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import { AddBlankButton, ClearButton, DeleteButton, TextOnlyClickButton,
         ReturnButton, SaveButton } from '../UI/Buttons/Buttons';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import TextField from '../UI/TextField/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {encryptString} from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import Button from '@mui/material/Button';
import { FormControl, FormLabel } from '@mui/material';
import SelectField from '../UI/SelectField/SelectField';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import DateSelect from '../UI/DateSelect/DateSelect';
import ModalDialog from '../UI/Dialog/ModalDialog';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ValueHelper from '../../helpers/valueHelper';

class User extends Component {
    constructor(props){
        super(props);

        this.state = {
            userid: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            _id: '',
            roles: [],
            userRoles: '',
            roleOptions: [],
            user: {},
            processes: [],
            processOptions: [],
            userProcesses: [],
            phoneNumber: '',
            location: '',
            locations: [],
            receiveEmails: false,
            internal: false,
            tenantOptions: [],
            tenants: [],
            department: [],
            departmentOptions: [],
            editTenant: false,
            tenantIndex: -1,
            tenSel: null,
            locSel: null,
            security: [],
            changePassword: false,
            active: false,
            timezone: '',
            timezoneSel: null,
            timezones: [],
            locked: false,
            enableSave: true,
            isSystem: false,
            languages: [],
            language: '',
            languageSel: null,
            enableSaveTenant: true,
            hourlyRate: '',
            failedLogins: '',
            depSels: [],
            roleSels: [],
            procSels: [],
            certTypes: [],
            certType: '',
            certTypeSel: null,
            certExpDate: '',
            certNumber: '',
            certifications: [],
            showCert: false,
            certIndex: -1,
            optionsMenu: [],
            allAuthTypes: [],
            authType: '',
            authTypeSel: null,
            authTypes: []
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.handleProcessChange = this.handleProcessChange.bind(this);

    }

    async componentDidMount(){
        let values = await axios.get('/api/uservalues');
        let tenantOptions = null;
        if(this.props.auth.user.internal){
            const tenResult = await axios.get('/int/tenantselectlist');
            tenantOptions = tenResult.data;
        }
        const {params} = this.props.match;
        const authTypes = ValueHelper.authOptions();
        this.setState({
            _id: params.id,
            tenantOptions: tenantOptions,
            security: this.props.permission,
            languages: values.data.languages,
            departmentOptions: values.data.departments,
            roles: values.data.roles,
            processes: values.data.processes,
            timezones: values.data.timezones,
            certTypes: values.data.certTypes,
            allAuthTypes: authTypes
        }, () => {
            if(params.id != null && params.id !== ""){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/users', label: this.props.t('users'), screen: this.props.auth.screenDefs.ManageUsers},
                    { path: 'active', label: this.props.t('user'), screen: this.props.auth.screenDefs.ManageUser}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const user = await axios.get('/int/users/' + this.state._id);
        let languageSel = this.state.languages.find(x => x.value === user.data.language);
        let certifications = user.data.certifications != null ? user.data.certifications : [];
        if(certifications.length > 0){
            for(let row of certifications){
                let certTypeSel = this.state.certTypes.find(x => x.value === row.type);
                row.certTypeSel = certTypeSel;
            }
        }
        let optionsMenu = [
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ]
        this.setState({
            userid: user.data.userid,
            firstName: user.data.firstName,
            lastName: user.data.lastName,
            email: user.data.email,
            _id: user.data._id,
            user: user.data,
            phoneNumber: user.data.phoneNumber,
            receiveEmails: user.data.receiveEmails,
            internal: user.data.internal,
            tenants:  user.data.tenants,
            changePassword: user.data.changePassword,
            locked: user.data.locked,
            isSystem: user.data.isSystem,
            language: user.data.language,
            languageSel: languageSel,
            failedLogins: user.data.failedLogins,
            certifications: certifications,
            optionsMenu: optionsMenu
        }, () => {
            const crumbs = [
                { path: '/#/users', label: this.props.t('users'), screen: this.props.auth.screenDefs.ManageUsers},
                { path: 'active', label: user.data.firstName + ' ' + user.data.lastName, screen: this.props.auth.screenDefs.ManageUser}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: this.props.t('options')}});
        });
    };

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    changeSelect = (name, data) => value => {
        if(name ==='tenSel'){
            this.loadTenantValues(value.value);
        }
        this.setState({[name]: value, [data]: value.value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(name ==='tenSel'){
            if(newValue != null)
                this.loadTenantValues(newValue.value);
        }
    };

    changeMulti = (name, data, optName) => (e) => {
        let sels = this.state[name];
        let ids = this.state[data];
        let options = this.state[optName];
        let val = e.target.value;
        let value = val.pop();
        if(value != null){
            if(value === 'all'){
                if(options.length !== sels.length){
                    sels = [];
                    ids = [];
                    options.forEach(row => {
                        sels.push(row);
                        ids.push(row.value);
                    });
                } else {
                    sels = [];
                    ids = [];
                }
            } else {
                if(val.length === 0){
                    let sel = options.find(x => x.value === value);
                    ids.push(value);
                    sels.push(sel);
                } else {
                    if(!val.some(x => x.value === value)){
                        let sel = options.find(x => x.value === value);
                        sels.push(sel);
                        ids.push(sel.value);
                    } else {
                        let index = sels.findIndex(x => x.value === value);
                        sels.splice(index, 1);
                        ids.splice(index, 1);
                    }
                }
            }
            this.setState({[name]: sels, [data]: ids})
        }
    }

    deleteMulti = (name, data) => (e, value) => {
        let sels = this.state[name];
        let ids = this.state[data];
        let index = sels.findIndex(x => x.value === value.value);
        if(index > -1){
            sels.splice(index, 1);
            ids.splice(index, 1);
        }
        this.setState({[name]: sels, [data]: ids});
    }

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    changeReceive = e => {
        this.setState({receiveEmails: !this.state.receiveEmails});
    };

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    }

    handleRoleChange(e){
        this.setState({userRoles: e})
    }

    handleProcessChange(e){
        this.setState({userProcesses: e});
    }

    returnClick(){
        this.props.history.goBack();
    }

    handleSubmit = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false}, () => this._handleSubmit());
    }

    _handleSubmit = async () => {
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }            
        let roleIds = [];
        if(this.state.userRoles.length > 0){
            this.state.userRoles.forEach(element => {
                roleIds.push(element.value);
            });
        }
        let processes = [];
        if(this.state.userProcesses.length > 0){
            this.state.userProcesses.forEach(process => {
                processes.push(process.value);
            });
        }
        let passwordHash = encryptString(this.state.password);
        const data = {
            _id: this.state._id,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            userid: this.state.userid,
            email: this.state.email,
            password: passwordHash,
            phoneNumber: this.state.phoneNumber,
            receiveEmails: this.state.receiveEmails,
            internal: this.state.internal,
            changePassword: this.state.changePassword,
            isSystem: this.state.isSystem,
            language: this.state.language,
            certifications: this.state.certifications
        };
        let url = '/int/users/add';
        if(this.state._id != null && this.state._id !== "")
            url = '/int/users/' + this.state._id;
        try {
            let result = await axios.post(url, data)
            if(this.state._id == null || this.state._id === ''){
                this.props.history.replace('/user/' + result.data.id);
            }   
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            });      
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
            });            
        }
    }

    validateForm(){
        let errors = {};
        const state = this.state;
        const t = this.props.t;
        if(state.userid == null || state.userid === '')
            errors[t('userid')] = "Required";
        if(state.firstName == null || state.firstName === '')
            errors[t('firstName')] = "Required";
        if(state.lastName == null || state.lastName === '')
            errors[t('lastName')] = "Required";
        if(state.email == null || state.email === '')
            errors[t('email')] = "Required";
        if(state.language == null || state.language === '')
            errors[t('language')] = "Required";
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    toggleTenant = (e) => {
        this.setState({editTenant: !this.state.editTenant});
    };

    clearTenant = () => {
        this.setState({
            editTenant: false,
            department: [],
            location: '',
            roles: [],
            processes: [],
            tenantIndex: -1,
            tenant: null,
            depSels: [],
            locSel: null,
            roleSels: [],
            procSels: [],
            tenSel: null,
            active: false,
            timezone: '',
            timezoneSel: null,
            hourlyRate: '',
            authTypes: [],
            authType: '',
            authTypeSel: null
        });
    };

    loadTenantValues = async (tenant) => {
        if(tenant != null){
            const values = await axios.get('/api/uservalues', {params: {tenant: tenant}});
            let data = values.data;
            let tenantAuthTypes = [];
            if(values.data.authTypes != null && values.data.authTypes.length > 0){
                values.data.authTypes.forEach(row => {
                    let authType = this.state.allAuthTypes.find(x => x.value === row);
                    if(authType != null)
                        tenantAuthTypes.push(authType);
                });
            }
            data.authTypes = tenantAuthTypes;
            this.setState({
                roleOptions: values.data.roles,
                processOptions: values.data.processes,
                locations: values.data.locations,
                departmentOptions: values.data.departments,
                timezones: values.data.timezones,
                languages: values.data.languages,
                authTypes: tenantAuthTypes
            });
            return data;
        }
        return null;
    }

    editTenantClick = index => async e => {
        e.preventDefault();
        const tenant = this.state.tenants[index];
        const values = await this.loadTenantValues(tenant.tenant._id);
        let depSels = [];
        if(tenant.department != null){
            tenant.department.forEach(row => {
                let dep = values.departments.find(x => x.value === row);
                if(dep != null)
                    depSels.push(dep);
            });
        }
        let locSel = null;
        if(tenant.defaultLocation != null){
            locSel = values.locations.find(x => x.value === tenant.defaultLocation);
        }
        let roleSels = [];
        if(tenant.roles != null){
            tenant.roles.forEach(row => {
                let role = values.roles.find(x => x.value === row);
                if(role != null)
                    roleSels.push(role);
            });
        }
        let procSels = [];
        if(tenant.processes != null){
            tenant.processes.forEach(row => {
                let process = values.processes.find(x => x.value === row);
                if(process != null)
                    procSels.push(process);
            });
        }
        const tenSel = this.state.tenantOptions.find(x => x.value === tenant.tenant._id)
        let timezoneSel = this.state.timezones.find(x => x.value === tenant.timezone);
        this.setState({
            tenantIndex: index,
            tenant: tenSel.value,
            depSels: depSels != null ? depSels : [],
            deoartments: tenant.departments,
            locSel: locSel,
            roleSels: roleSels != null ? roleSels : [],
            roles: tenant.roles,
            procSels: procSels != null ? procSels : [],
            processes: tenant.processes,
            tenSel: tenSel,
            editTenant: true,
            departmentOptions: values.departments,
            roleOptions: values.roles,
            processOptions: values.processes,
            active: tenant.active,
            timezone: tenant.timezone,
            timezoneSel: timezoneSel,
            hourlyRate: tenant.hourlyRate,
            authType: tenant.authType,
            authTypeSel: values.authTypes.find(x => x.value === tenant.authType),
            authTypes: values.authTypes
        });
    };

    saveTenant = () => {
        if(!this.state.enableSaveTenant)
            return;
        this.setState({ enableSave: false }, () => this._saveTenant());
    }

    _saveTenant = async () => {
        const valid = this.validateTenant();
        if(!valid) {
            this.setState({ enableSaveTenant: true });
            return;
        }
        let tenants = this.state.tenants;
        let tenant = null;
        if(this.state.tenantIndex > -1){
            tenant = tenants[this.state.tenantIndex];
        } else {
            tenant = {};
            tenant.tenant = {};
        }
        tenant.tenant._id = this.state.tenSel.value;
        tenant.tenant.name = this.state.tenSel.label;
        let department = [];
        this.state.depSels.forEach(row => {
            department.push(row.value);
        });
        tenant.department = department;
        if(this.state.locSel != null)
            tenant.defaultLocation = this.state.locSel.value;
        let roles = [];
        this.state.roleSels.forEach(row => {
            roles.push(row.value);
        });
        tenant.roles = roles;
        let processes = [];
        if(this.state.procSels != null && this.state.procSels.length > 0){
            this.state.procSels.forEach(row => {
                processes.push(row.value);
            });
        }
        tenant.processes = processes;
        tenant.active = this.state.active;
        tenant.timezone = this.state.timezone;
        if(this.state.tenantIndex < 0){
            tenants.push(tenant);
        }
        let data = {
            _id: this.state._id,
            tenant: this.state.tenSel.value,
            roles: tenant.roles,
            department: tenant.department,
            processes: tenant.processes,
            timezone: tenant.timezone,
            active: tenant.active,
            hourlyRate: this.state.hourlyRate,
            authType: this.state.authType
        };
        try {
            let result = await axios.post('/int/usertenantonly', data);
            this.clearTenant();
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
        }
    };

    validateTenant(){
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.tenant == null || state.tenant === '')
            errors[t('tenant')] = "The tenant is required";
        if(state.depSels == null || state.depSels.length === 0)
            errors[t('departments')] = "At least 1 department is required";
        if(state.roleSels == null || state.roleSels.length === 0)
            errors[t('roles')] = "At least 1 role is required";
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteTenant = async (e) => {
        if(this.state.tenantIndex > -1){
            let data = {
                _id: this.state._id,
                tenant: this.state.tenSel.value
            };
            try {
                let result = await axios.post('/int/usertenantdelete', data);
                this.clearTenant();
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            } catch(err){
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
            }
        } else {
            this.clearTenant();
        }
        
    };

    changeInternal = (e) => {
        this.setState({internal: !this.state.internal});
    };

    changePassword = (e) => {
        this.setState({changePassword: !this.state.changePassword});
    };

    unlock = async (e) => {
        if(this.state.locked){
            let data = {user: this.state._id};
            try {
                let result = await axios.post('/api/userunlock', data);
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: 'Success', severity: 'success'}});
            } catch(err){
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
            }  
        }
    };

    toggleCerts = () => {
        this.setState({
            showCert: !this.state.showCert,
            certIndex: -1,
            certType: '',
            certTypeSel: null,
            certExpDate: '',
            certNumber: ''
        });
    }

    editCert = (i) => (e) => {
        let certs = this.state.certifications;
        let cert = certs[i];
        certs[i] = cert;
        this.setState({
            certIndex: i,
            certType: cert.type,
            certTypeSel: cert.certTypeSel,
            certExpDate: cert.expirationDate,
            certNumber: cert.number,
            showCert: true
        });
    }

    saveCert = (e) => {
        let errors = {};
        const t = this.props.t;
        if(this.state.certType == null || this.state.certType === '')
            errors[t('certType')] = this.props.t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let certs = this.state.certifications;
        if(this.state.certIndex > -1){
            let cert = certs[this.state.certIndex];
            cert.type = this.state.certType;
            cert.certTypeSel = this.state.certTypeSel;
            cert.expirationDate = this.state.certExpDate;
            cert.number = this.state.certNumber;
        } else {
            certs.push({
                type: this.state.certType,
                certTypeSel: this.state.certTypeSel,
                expirationDate: this.state.certExpDate,
                number: this.state.certNumber
            });
        }
        this.setState({
            certifications: certs,
            showCert: false,
            certType: '',
            certTypeSel: null,
            certExpDate: '',
            certNumber: '',
            certIndex: -1
        })
    }

    render(){
        const { errors, t } = this.props;
        const security = this.state.security;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.user}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                    />
                </BreadcrumbBar>
                <Panel>
                    <SectionTitle title={t('user')} />
                    <Grid container spacing={3}>
                        <Grid item xs={8} sm={4}>
                            <TextField value={this.state.userid} onChange={this.handleInputChange} name="userid"
                            size="medium" fullWidth={true} label={t('userid')} required 
                            error={errors[t('userid')] != null ? true : false} helperText={errors[t('userid')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.firstName} onChange={this.handleInputChange} name="firstName"
                            size="medium" fullWidth={true} label={t('firstName')} required 
                            error={errors[t('firstName')] != null ? true : false} helperText={errors[t('firstName')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.lastName} onChange={this.handleInputChange} name="lastName"
                            size="medium" fullWidth={true} label={t('lastName')}required 
                            error={errors[t('lastName')] != null ? true : false} helperText={errors[t('lastName')]}/>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <TextField value={this.state.email} onChange={this.handleInputChange} name="email"
                            size="medium" fullWidth={true} label={t('email')} required 
                            error={errors[t('email')] != null ? true : false} helperText={errors[t('email')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <InputMaskField mask="999-999-9999" name="phoneNumber" value={this.state.phoneNumber}
                            onChange={this.handleInputChange} 
                            helperText={errors[t('phoneNumber')]}
                            error={errors[t('phoneNumber')] != null ? true : false}
                            label={t('phoneNumber')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField
                            checked={this.state.receiveEmails} onChange={this.changeReceive}
                            label={t('receiveEmails')} name="receiveEmails"
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                            value={this.state.languageSel}
                            options={this.state.languages}
                            onChange={this.changeAuto('languageSel', 'language')}
                            error={errors[t('language')] != null ? true : false}
                            helperText={errors[t('language')]} 
                            label={t('language')}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <CheckboxField
                            checked={this.state.internal} onChange={this.changeInternal}
                            label={t('internal')} name="internal"
                            />
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <TextField value={this.state.password} onChange={this.handleInputChange} name="password"
                            size="medium" fullWidth={true} label={t('password')} type="password" required 
                            error={errors[t('password')] != null ? true : false} helperText={errors[t('password')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField
                            checked={this.state.changePassword} onChange={this.changePassword}
                            label={t('changePassword')} name="changePassword"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField
                            checked={this.state.locked}
                            label={t('locked')} name="locked" disabled={true}
                            />
                        </Grid>
                        {this.state.failedLogins != null && this.state.failedLogins !== '' && this.state.failedLogins !== 0 &&
                            <Grid item xs={2} sm={1}>
                                <FormControl>
                                    <FormLabel>{t('tries')}</FormLabel>
                                    {this.state.failedLogins}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            <CheckboxField
                            checked={this.state.isSystem} onChange={this.changeBool('isSystem')}
                            label={t('isSystem')} name="isSystem"
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title={t('certifications')} addTitle={t('add')} add={this.toggleCerts} security={true} data-cy='addCertification' />
                    {this.state.certifications != null && this.state.certifications.length > 0 &&
                        <Aux>
                            <Grid item xs={12}>
                                <TableContainer sx={{ border: '2px solid rgba(224, 224, 224, 1)', borderRadius: 3}}>
                                    <Table size='small'>
                                        <TableHead key='certificationsTableHeader'>
                                            <TableRow>
                                                <TableCell>{t('certification')}</TableCell>
                                                <TableCell>{t('expires')}</TableCell>
                                                <TableCell>{t('number')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {this.state.certifications != null && this.state.certifications.length > 0 ? 
                                            this.state.certifications.map((certification, i) => (
                                            <TableRow key={'cert' + i}>
                                                <TableCell>
                                                    <TextOnlyClickButton enabled={true} click={this.editCert(i)} name={certification.certTypeSel.label}/>
                                                </TableCell>
                                                <TableCell>
                                                    {certification.expirationDate != null && <DateDisplay value={certification.expirationDate} format={this.props.auth.user.dateFormat}/>}
                                                </TableCell>
                                                <TableCell>{certification.number != null ? certification.number : ''}</TableCell>
                                            </TableRow> 
                                        )) :
                                            <TableRow>
                                                <TableCell colSpan={2} sx={{ textAlign: 'center', fontWeight: 'bold' }}>{t('noCertificationsFound')}</TableCell>
                                            </TableRow>
                                        }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Aux>
                    }
                    <ModalDialog buttonStack={(<Aux>
                            <Button color="secondary" size="small" variant='text' onClick={this.toggleCerts}>Close</Button>
                            <Button color="primary" size="small" variant="text" onClick={this.saveCert}>Save</Button>
                        </Aux>
                        )} title={t('certification')} toggleDialog={this.toggleCerts}
                                    dialogStatus={this.state.showCert} fullWidth maxWidth='sm'>
                        <Grid container spacing={3}>
                            <Grid item xs={10} sm={5}>
                                <AutoCompleteField
                                    value={this.state.certTypeSel}
                                    options={this.state.certTypes}
                                    onChange={this.changeAuto('certTypeSel', 'certType')}
                                    error={errors[t('certType')] != null ? true : false}
                                    helperText={errors[t('certType')]} 
                                    label={t('type')}
                                />
                            </Grid>
                            <Grid item xs={10} sm={5}>
                                <DateSelect
                                    value={this.state.certExpDate}
                                    onChange={this.changeDate('certExpDate')}
                                    error={errors[t('certExpDate')] != null ? true : false}
                                    helperText={errors[t('certExpDate')]} 
                                    label={t('expires')}
                                    name="certExpDate"
                                />
                            </Grid>
                            <Grid item xs={10} sm={5}>
                                <TextField 
                                    value={this.state.certNumber} 
                                    onChange={this.handleInputChange} 
                                    name="certNumber" 
                                    label={t('number')} 
                                    data-cy='certNumber' 
                                    error={errors[t('certNumber')] != null ? true : false} helperText={errors[t('certNumber')]}
                                />
                            </Grid>
                        </Grid>
                                        
                    </ModalDialog>
                    <br/>
                    {this.state._id != null && this.state._id !== '' &&
                        <SectionTitle title={t('tenants')} />
                    }
                    {this.state.tenants != null && this.state.tenants !== '' && this.state.tenants.length > 0 &&
                        <TableContainer component={Paper}>
                            <Table aria-label="Tenants">
                                <TableBody>
                                    {this.state.tenants.map((row, i) =>
                                        <TableRow key={row._id}>
                                            <TableCell>
                                                {row.tenant != null &&
                                                    <TextOnlyClickButton enabled={true} click={this.editTenantClick(i)} name={row.tenant.name}/>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {errors[t('tenants')] && <div><div className="error text-danger">{errors[t('tenants')]}</div><br/></div>}
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <br />
                            <AddBlankButton size="small" addClick={this.toggleTenant} buttonName={t('addTenant')} enabled={true} />
                        </Aux>
                    }
                     <br/>
                    <Dialog open={this.state.editTenant} onClose={this.clearTenant} center maxWidth="md" fullWidth>
                        <DialogTitle>
                            <SectionTitle title={t('tenantDetails')} />
                        </DialogTitle>
                        <DialogContent>
                        <Grid container spacing={2}>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                    value={this.state.tenSel}
                                    options={this.state.tenantOptions}
                                    onChange={this.changeAuto('tenSel', 'tenant')}
                                    error={errors[t('tenant')] != null ? true : false}
                                    helperText={errors[t('tenant')]} 
                                    label={t('tenant')}
                                    required
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                    value={this.state.locSel}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locSel', 'location')}
                                    error={errors[t('locations')] != null ? true : false}
                                    helperText={errors[t('locations')]} 
                                    label={t('defaultLocation')}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                    value={this.state.timezoneSel}
                                    options={this.state.timezones}
                                    onChange={this.changeAuto('timezoneSel', 'timezone')}
                                    error={errors[t('timezone')] != null ? true : false}
                                    helperText={errors[t('timezone')]} 
                                    label={t('timezone')}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                    value={this.state.authTypeSel}
                                    options={this.state.authTypes}
                                    onChange={this.changeAuto('authTypeSel', 'authType')}
                                    error={errors[t('authentication')] != null ? true : false}
                                    helperText={errors[t('authentication')]} 
                                    label={t('authentication')}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.active} onChange={this.changeBool('active')}
                                    label={t('active')} name="active"
                                    />
                                </Grid>
                                {this.state.departmentOptions != null && this.state.departmentOptions.length > 0 &&
                                    <Grid item xs={12} sm={6}>
                                        <SelectField
                                            selectAll
                                            value={this.state.depSels}
                                            options={this.state.departmentOptions}
                                            label={t('departments')}
                                            selectAllLabel={t('selectAll')}
                                            error={errors[t('departments')] != null ? true : false}
                                            helperText={errors[t('departments')]} 
                                            onChange={this.changeMulti('depSels', 'department', 'departmentOptions')}
                                            onDelete={this.deleteMulti('depSels', 'department')}
                                        />
                                    </Grid>
                                }
                                {this.state.roleOptions != null && this.state.roleOptions.length > 0 &&
                                    <Grid item xs={12} sm={6}>
                                        <SelectField
                                            selectAll
                                            value={this.state.roleSels}
                                            options={this.state.roleOptions}
                                            label={t('roles')}
                                            selectAllLabel={t('selectAll')}
                                            error={errors[t('roles')] != null ? true : false}
                                            helperText={errors[t('roles')]} 
                                            onChange={this.changeMulti('roleSels', 'roles', 'roleOptions')}
                                            onDelete={this.deleteMulti('roleSels', 'roles')}
                                        />
                                    </Grid>
                                }
                                {this.state.processOptions != null && this.state.processOptions.length > 0 &&
                                    <Grid item xs={12} sm={6}>
                                        <SelectField
                                            selectAll
                                            value={this.state.procSels}
                                            options={this.state.processOptions}
                                            label={t('businessProcesses')}
                                            selectAllLabel={t('selectAll')}
                                            error={errors[t('processes')] != null ? true : false}
                                            helperText={errors[t('processes')]} 
                                            onChange={this.changeMulti('procSels', 'processes', 'processOptions')}
                                            onDelete={this.deleteMulti('procSels', 'processes')}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <TextField value={this.state.hourlyRate} onChange={this.handleInputChange} name="hourlyRate"
                                    size="medium" fullWidth={true} label={t('hourlyRate')} required 
                                    error={errors[t('hourlyRate')] != null ? true : false} helperText={errors[t('hourlyRate')]}/>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <SaveButton saveClick={this.saveTenant} enabled={this.state.enableSaveTenant} />
                            <ClearButton clearClick={this.clearTenant} enabled={true} />
                            <DeleteButton deleteClick={this.deleteTenant} enabled={true} />
                        </DialogActions>
                    </Dialog>
                    {!this.state.editTenant &&
                        <Grid container spacing={3}>
                            <Grid item sm={6}>
                                <SaveButton saveClick={() => this.handleSubmit()} enabled={this.state.enableSave} />
                                <ReturnButton returnClick={() => this.returnClick()} />
                                {this.state.locked &&
                                    <Button variant="contained" color="secondary" size="small" onClick={this.unlock}>{t('unlock')}</Button>
                                }
                            </Grid>
                        </Grid>
                    }
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <ObjectLog id={this.state._id}/>
                        </Aux>
                    }
                </Panel>
            </Aux>
        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(User));
