import React from 'react';
import SelectField from '../SelectField/SelectField';
import Button from '@mui/material/Button';
import Aux from '../../../hoc/Auxilary/Auxilary';
import listHelper from '../../../helpers/listHelper';

export default function ListMultiSelect(props) {
    const {show, setShow, selected, selectedSel, options, t, setSelected, setColumnFilters, name, columnFilters, tenant, screen} = props;
    const buttonText = selected != null && selected.length > 0 ? t('on') : t('off');

    const change = (e) => {
        let sels = selectedSel;
        let ids = selected;;
        let val = e.target.value;
        let value = val.pop();
        if(value != null){
            if(value === 'all'){
                if(options.length !== sels.length){
                    sels = [];
                    ids = [];
                    options.forEach(row => {
                        sels.push(row);
                        ids.push(row.value);
                    });
                } else {
                    sels = [];
                    ids = [];
                }
            } else {
                if(val.length === 0){
                    let sel = options.find(x => x.value === value);
                    ids.push(value);
                    sels.push(sel);
                } else {
                    if(!val.some(x => x.value === value)){
                        let sel = options.find(x => x.value === value);
                        sels.push(sel);
                        ids.push(sel.value);
                    } else {
                        let index = sels.findIndex(x => x.value === value);
                        sels.splice(index, 1);
                        ids.splice(index, 1);
                    }
                }
            }
            let nameFilters = columnFilters.find(x => x.id === name);
            if(ids.length > 0 &&  nameFilters != null){
                nameFilters.value = ids;
            } else if(ids.length > 0 && nameFilters == null){
                columnFilters.push({id: name, value: ids});
            } else {
                let ind = columnFilters.findIndex(x => x.id === name);
                if(ind > -1){
                    columnFilters.splice(ind, 1);
                }
            }
            setSelected({ids: ids, values: sels});
            setColumnFilters(columnFilters);
            listHelper.setItem(screen + 'ColumnFilters', tenant, JSON.stringify(columnFilters));


        }
    }
    const onDelete = (e, value) => {
        let sels = selectedSel;
        let ids = selected;
        let index = sels.findIndex(x => x.value === value.value);
        if(index > -1){
            sels.splice(index, 1);
            ids.splice(index, 1);
        }
        let nameFilters = columnFilters.find(x => x.id === name);
        nameFilters.value = ids;
        setSelected({ids: ids, values: sels});
        setColumnFilters(columnFilters);
        listHelper.setItem(screen + 'ColumnFilters', tenant, JSON.stringify(columnFilters));
    }

    return (
        <Aux>
            {!show &&
                <Button size="small" onClick={() => setShow()}>{t('filters') + ' ' + buttonText}</Button>
            }
            {show &&
                <Aux>
                    <Button size="small" onClick={() => setShow()}>{t('close')}</Button>
                    <SelectField
                    selectAll
                    value={selectedSel}
                    options={options}
                    selectAllLabel={t('selectAll')}
                    onChange={change}
                    onDelete={onDelete}                        
                    disabled={false}
                    />
                </Aux>
            }
        </Aux>
    );
}
