import React, { Component } from 'react';
import axios from 'axios';
import ReactDOMServer from 'react-dom/server';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Logo from '../UI/AppBars/Logo/EVT-100-EvoraTech_Logotype_TealLime_RGB.png';

class GeneratePdf extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            printSetting: '',
            printSettingSel: {},
            logo: '',
            saving: false,
            tenantReport: '',
            fileName: ''
        }
    }

    componentDidMount = async () => {
        this.setState({
            printSetting: this.props.printSetting != null ? this.props.printSetting : '',
            printSettingSel: this.props.printSettingSel != null ? this.props.printSettingSel : {},
            tenantReport: this.props.tenantReport != null ? this.props.tenantReport : null,
            fileName: this.props.fileName != null ? this.props.fileName : ''
        });
    }

    componentDidUpdate = async (prevProps) => {
        if(this.props.printSetting !== prevProps.printSetting) {
            this.setState({printSetting: this.props.printSetting});
        }
        if(this.props.printSettingSel !== prevProps.printSettingSel) {
            this.setState({printSettingSel: this.props.printSettingSel});
        }
        if(this.props.tenantReport !== prevProps.tenantReport) {
            this.setState({tenantReport: this.props.tenantReport});
        }
        if(this.props.fileName !== prevProps.fileName) {
            this.setState({fileName: this.props.fileName});
        }
    }

    generate = async e => {
        if(this.props.clearDownload != null) {
            this.props.clearDownload();
        }
        let printSetting = JSON.parse(JSON.stringify(this.state.printSettingSel));
        if(printSetting?.data?.margin?.bottom) {
            let bottomMargin = parseInt(printSetting.data.margin.bottom, 10);
            bottomMargin += 20;
            printSetting.data.margin.bottom = `${bottomMargin}px`;
        }
        async function getBase64Image(imgUrl) {
            const response = await fetch(imgUrl);
            const blob = await response.blob();
        
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        }
        
        let logoBase64 = await getBase64Image(Logo);
        let eFooter = ReactDOMServer.renderToStaticMarkup(
            <div style={{textAlign: 'center', fontSize: '11px', fontStyle: 'italic', display: 'block', width: '100%'}}>
                {this.props.t('preparedBy')} <img src={logoBase64} height='10px' alt='evora logo'/>
            </div>
        );
        
        if(printSetting?.data?.header?.footer) {
            printSetting.data.header.footer = `
                <div style="display: flex; flex-direction: column; width: 100%;">
                    ${printSetting.data.header.footer}
                    ${eFooter}
                </div>`;
        }else if(printSetting?.data?.header?.header) {
            printSetting.data.header.footer = 
                `<div style="display: flex; flex-direction: column; width: 100%;">
                    ${eFooter}
                </div>`;
        }

        let fileName = this.state.fileName;
        if(fileName != null && fileName !== '') {
            if(!fileName.endsWith('.pdf')) {
                fileName = `${fileName}.pdf`;
            }
        }

        let data = {
          htmlString: this.props.markup(),
          id: this.props.id,
          objectType: this.props.objectType,
          printSetting: printSetting,
          tenantReport: this.state.tenantReport,
          fileName: fileName
        };
        try{
            let result = await axios.post('/api/generatepdf', data);
            this.setState({ saving: false }, () => {
                if(this.props.callBack != null){
                    this.props.callBack(result.data);
                }
            });
        } catch(err){
            this.setState({ saving: false }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    };

    render(){
        const { saving } = this.state;
        const t = this.props.t;
        if(saving) {
            return (
                <LoadingButton
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                    size='small'
                >
                    {t('generatingPdf')}
                </LoadingButton>
            )
        }else {
            return (
                <Button 
                    variant="contained" 
                    color="secondary" 
                    size="small" 
                    onClick={() => this.setState({saving: true}, () => this.generate())} 
                    disabled={this.state.printSettingSel == null || this.state.printSettingSel === ''}
                >
                    {t('generatePdf')}
                </Button>
            )
        }
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(GeneratePdf));
