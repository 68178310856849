import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { NumericFormat } from 'react-number-format';
import SectionTitle from '../UI/Typography/SectionTitle';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';

class HoldLots extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            number: '',
            item: '',
            items: [],
            orderBy: 'created',
            ordDir: -1,
            category: '',
            catSel: null,
            categories: [],
            itemSel: null,
            totalCount: 0,
            security: [],
            page: 0
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.allItems, common.cacheValues.itemCategory], this.props.auth.constants, null, this.props.auth.user.tenant);
        let items = cacheValues.allItems;
        this.setState({
            items: items,
            categories: cacheValues.itemCategories,
            security: this.props.permission
        });
        this.loadRecords();
    }

    loadRecords = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: 'item', value: this.state.item !== '' ? this.state.item : null},
            {id: 'status', value: this.props.auth.constants.lotStatuses.onhold}, 
            {id: 'category', value: this.state.category !== '' ? this.state.category : null}
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let list = await axios.get('/api/lots', {params: params});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        });
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <SectionTitle title={t('lotsOnhold')}/>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                            value={this.state.itemSel}
                            options={this.state.items}
                            onChange={this.changeAuto('itemSel', 'item')}
                            label={t('item')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                            value={this.state.catSel}
                            options={this.state.categories}
                            onChange={this.changeAuto('catSel', 'category')}
                            label={t('category')}
                        />
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="HeldLots" size="small">
                        <TableHead>
                            <TableRow key="heldDeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('item')}
                                    active={this.state.orderBy === 'item'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('item')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    {t('category')}
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('rating')}
                                    active={this.state.orderBy === 'rating'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('rating')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>{t('amount')}</TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('created')}
                                    active={this.state.orderBy === 'created'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('created')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('customNumber')}
                                    active={this.state.orderBy === 'customNumber'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('customNumber')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list.map((row, i) =>
                                <TableRow key={row._id}>
                                    <TableCell><Link to={'/lot/' + row._id} name={'lotLink' + i}>{row.number}</Link></TableCell>
                                    <TableCell>{row.item.number + ' - ' + row.item.name}</TableCell>
                                    <TableCell>{row.item.category.description}</TableCell>
                                    <TableCell>{row.rating != null ? row.rating.label : ''}</TableCell>
                                    <TableCell>
                                        <NumericFormat displayType={'text'} value={row.amount} thousandSeparator={true}
                                        suffix={row.amountUOM.label} decimalScale={3}/>
                                    </TableCell>
                                    <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={row.created}/></TableCell>
                                    <TableCell>{row.customNumber != null ? row.customNumber : ''}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(HoldLots));