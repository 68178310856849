import { Buffer } from 'buffer';

export function encryptString(string) {
    let encrypted = null;
    let buff = new Buffer.from(string);
    encrypted = buff.toString('base64');
    return encrypted;
}

export function decryptString(string) {
    let buff = Buffer.from(string, 'base64');
    let bareString = buff.toString('ascii');
    return bareString;
};

export function fieldSecurity(permissions, status, common, constants, type) {
    if (readOnlyFieldSecurity(permissions)) return false;
    if (type == null || type === common.statusTypes.standard) {
        if ((status === constants.statuses.inprogress || status === constants.statuses.new || status == null || status === constants.statuses.released) &&
            (permissions.complete === true || permissions.update === true || permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }

    if (type === common.statusTypes.productionOrder) {
        if ((status === constants.productionOrderStatuses.released || status === constants.productionOrderStatuses.new ||
            status === constants.productionOrderStatuses.inserted || status === constants.productionOrderStatuses.inprogress) &&
            (permissions.complete === true || permissions.update === true || permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }

    if (type === common.statusTypes.batch) {
        if ((status === constants.batchStatuses.inserted || status === constants.batchStatuses.released ||
                status === constants.batchStatuses.growing || status === constants.batchStatuses.harvested) &&
            (permissions.complete === true || permissions.update === true || permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }

    if (type === common.statusTypes.container) {
        if (status !== constants.containerStatuses.destroyed && (permissions.update === true || permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }

    if (type === common.statusTypes.lot) {
        if (permissions.update === true || permissions.create === true) {
            return true;
        } else {
            return false;
        }
    }

    if (type === common.statusTypes.productionBatch) {
        if ((status === constants.productionBatchStatuses.inserted || status === constants.productionBatchStatuses.released ||
                status === constants.productionBatchStatuses.inprogress) &&
            (permissions.complete === true || permissions.update === true || permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }

    if (type === common.statusTypes.purchaseOrder) {
        if((status === constants.purchaseOrderStatuses.new || status === constants.purchaseOrderStatuses.inprogress) &&
        (permissions.complete === true || permissions.update === true || permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }

    if(type === common.statusTypes.ccxPosting){
        if((status === constants.ccxPostingStatuses.new || status === constants.ccxPostingStatuses.inprogress || status === constants.ccxPostingStatuses.posted) && 
        (permissions.create === true || permissions.update === true || permissions.complete === true)){
            return true;
        } else {
            return false;
        }
    }
    return false;
}

export function readOnlyFieldSecurity(permissions) {
    if (permissions.read === true && permissions.complete !== true &&
        permissions.create !== true && permissions.update !== true &&
        permissions.review !== true && permissions.approve !== true) {
        return true;
    } else {
        return false;
    }
}

export function printButtonSecurity(permissions, status, common, constants, type) {
    if (type == null || type === common.statusTypes.standard)
        if (permissions.print) {
            return true;
        } else {
            return false;
        }
    if (type === common.statusTypes.batch) {
        if ((status !== constants.batchStatuses.inserted &&
                status !== constants.batchStatuses.released) &&
            permissions.print) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.productionBatch) {
        if ((status !== constants.productionBatchStatuses.inserted &&
                status !== constants.productionBatchStatuses.released) &&
            permissions.print) {
            return true;
        } else {
            return false;
        }
    }
}

export function saveButtonSecurity(permissions, status, common, constants, type) {
    if (type == null || type === common.statusTypes.standard) {
        if ((status === constants.statuses.inprogress || status === constants.statuses.new || status === constants.statuses.released) &&
            (permissions.complete === true || permissions.update === true ||
                permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.productionOrder) {
        if ((status === constants.productionOrderStatuses.new || status === constants.productionOrderStatuses.inserted ||
            status === constants.productionOrderStatuses.inprogress || status === constants.productionOrderStatuses.released ||
            status === constants.productionOrderStatuses.planned) &&
            (permissions.complete === true || permissions.update === true || permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.batch) {
        if ((status === constants.batchStatuses.planned || status === constants.batchStatuses.flower ||
                status === constants.batchStatuses.harvested || status === constants.batchStatuses.planned ||
                status === constants.batchStatuses.veg || status === constants.batchStatuses.growing) &&
            (permissions.complete === true || permissions.update === true || permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.container) {
        if (status !== constants.containerStatuses.destroyed && (permissions.update === true || permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.lot) {
        if (status !== constants.lotStatuses.destroyed && status !== constants.lotStatuses.finished && (permissions.update === true || permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.productionBatch) {
        if ((status === constants.productionBatchStatuses.inserted || status === constants.productionBatchStatuses.released ||
            status === constants.productionBatchStatuses.inprogress) &&
            (permissions.complete === true || permissions.update === true || permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.batchTemplate) {
        if (status === constants.batchTemplateStatuses.inactive &&
            (permissions.complete === true || permissions.update === true || permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }

    if (type === common.statusTypes.purchaseOrder) {
        if((status === constants.purchaseOrderStatuses.new || status === constants.purchaseOrderStatuses.inprogress) &&
        (permissions.complete === true || permissions.update === true || permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.ccxPosting) {
        if ((status === constants.ccxPostingStatuses.inprogress || status === constants.ccxPostingStatuses.new) &&
            (permissions.complete === true || permissions.update === true ||
                permissions.create === true)) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

export function unlockButtonSecurity(permissions, status, common, constants, type, activityDate, lockDate, isSuper) {
    if(lockDate != null && activityDate != null){
        let act = Date.parse(activityDate);
        let locked = Date.parse(lockDate);
        if(act < locked && !isSuper){
            return false;
        }
    }
    if (type == null || type === undefined || type === common.statusTypes.standard) {
        if ((status === constants.statuses.complete ||
                status === constants.statuses.reviewed ||
                status === constants.statuses.signed) && permissions.unlock) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.productionOrder) {
        if ((status === constants.productionOrderStatuses.finished ||
                status === constants.productionOrderStatuses.reviewed ||
                status === constants.productionOrderStatuses.complete) && permissions.unlock) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.batch) {
        if ((status === constants.batchStatuses.signed ||
                status === constants.batchStatuses.complete ||
                status === constants.batchStatuses.reviewed) && permissions.unlock) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.productionBatch) {
        if ((status === constants.productionBatchStatuses.signed ||
                status === constants.productionBatchStatuses.complete ||
                status === constants.productionBatchStatuses.review) && permissions.unlock) {
            return true;
        } else {
            return false;
        }
    }

    if (type === common.statusTypes.purchaseOrder) {
        if((status === constants.purchaseOrderStatuses.approved) && (permissions.unlock)) {
            return true;
        } else {
            return false;
        }
    }
    if (type === undefined || type === common.statusTypes.ccxPosting) {
        if ((status === constants.ccxPostingStatuses.complete ||
                status === constants.ccxPostingStatuses.reviewed ||
                status === constants.ccxPostingStatuses.signed) && permissions.unlock) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

export function completeSigSecurity(permission, signature, status, common, constants, type) {
    if (type == null || type === common.statusTypes.standard) {
        if (status === constants.statuses.inprogress || status === constants.statuses.complete ||
            status === constants.statuses.reviewed || status === constants.statuses.signed || 
            status === constants.statuses.released) {
            if (signature === undefined || signature == null) {
                return true;
            } else if (signature !== undefined) {
                return true;
            }
        }
    }

    if (type === common.statusTypes.productionOrder) {
        if (status === constants.productionOrderStatuses.released || status === constants.productionOrderStatuses.inprogress ||
            status === constants.productionOrderStatuses.finished || status === constants.productionOrderStatuses.reviewed ||
            status === constants.productionOrderStatuses.complete) {
            if (signature === undefined) {
                return true;
            } else if (signature !== undefined) {
                return true;
            }
        }
    }

    if (type === common.statusTypes.purchaseOrder) {
        if(status === constants.purchaseOrderStatuses.new || status === constants.purchaseOrderStatuses.inprogress){
            return true;
        } else {
            return false;
        }
    }

    if (type === common.statusTypes.ccxPosting) {
        if (status === constants.ccxPostingStatuses.inprogress || status === constants.ccxPostingStatuses.complete ||
            status === constants.ccxPostingStatuses.reviewed || status === constants.ccxPostingStatuses.signed) {
            if (signature === undefined || signature == null) {
                return true;
            } else if (signature !== undefined) {
                return true;
            }
        }
    }

    return false;
}

export function reviewSigSecurity(permission, signature, status, common, constants, type) {
    if (type == null || type === common.statusTypes.standard) {
        if (status === constants.statuses.complete ||
            status === constants.statuses.reviewed ||
            status === constants.statuses.signed) {
            if ((signature === undefined) || signature !== undefined) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.productionOrder) {
        if (status === constants.productionOrderStatuses.complete || status === constants.productionOrderStatuses.reviewed ||
            status === constants.productionOrderStatuses.finished) {
            if ((signature === undefined) || signature !== undefined) {
                return true
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.batch) {
        if (status === constants.batchStatuses.complete || status === constants.batchStatuses.review ||
            status === constants.batchStatuses.signedOff) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.productionBatch) {
        if (status === constants.productionBatchStatuses.complete || status === constants.productionBatchStatuses.reviewed ||
            status === constants.productionBatchStatuses.signedOff) {
            return true;
        } else {
            return false;
        }
    }
}

export function approveSigSecurity(permission, signature, status, common, constants, type) {
    if (type == null || type === common.statusTypes.standard) {
        if (status === constants.statuses.reviewed || status === constants.statuses.signed) {
            if ((signature === undefined) || signature !== undefined) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.productionOrder) {
        if (status === constants.productionOrderStatuses.reviewed || status === constants.productionOrderStatuses.finished) {
            if ((signature === undefined) || signature !== undefined) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.batch) {
        if (status === constants.batchStatuses.review || status === constants.batchStatuses.signedOff) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.productionBatch) {
        if (status === constants.productionBatchStatuses.reviewed || status === constants.productionBatchStatuses.signedOff) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

export function destructionFieldSecurity(status, permissions, statuses) {
    if(status !== statuses.inprogress && status !== statuses.new){
        return false
    }
    if (permissions.read === true && permissions.complete !== true &&
        permissions.create !== true && permissions.update !== true &&
        permissions.review !== true && permissions.approve !== true &&
        permissions.unlock !== true) {
        return false;
    }
    return true;
}

export function releaseButtonSecurity(permissions, status, common, constants, type) {
    if (type === common.statusTypes.productionOrder) {
        if (status === constants.productionOrderStatuses.inserted && permissions.update) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.batch) {
        if (status === constants.batchStatuses.inserted && permissions.update) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.productionBatch) {
        if (status === constants.productionBatchStatuses.inserted && permissions.update) {
            return true;
        } else {
            return false;
        }
    }
    if(status === constants.statuses.new && permissions.update){
        return true;
    }
    return false;
}

export function splitButtonSecurity(permissions, status, common, constants, type) {
    if (type == null || type === common.statusTypes.standard)
        return false;
    if (type === common.statusTypes.container) {
        if (status !== constants.containerStatuses.destroyed && permissions.update) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.lot) {
        if (status !== constants.lotStatuses.destroyed && status !== constants.lotStatuses.finished && status !== constants.lotStatuses.inprogress && permissions.update) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

export function combineButtonSecurity(permissions, status, common, constants, type) {
    if (type == null || type === common.statusTypes.standard)
        return false;
    if (type === common.statusTypes.container) {
        if (status !== constants.containerStatuses.destroyed && permissions.update) {
            return true;
        } else {
            return false;
        }
    }
    if (type === common.statusTypes.lot) {
        if (status !== constants.lotStatuses.destroyed && status !== constants.lotStatuses.finished && status !== constants.lotStatuses.inprogress && permissions.update) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

export function moveButtonSecurity(permissions, status, common, constants, type) {
    if (type == null || type === common.statusTypes.standard)
        return false;
    if (type === common.statusTypes.container) {
        if (status !== constants.containerStatuses.destroyed && permissions.update) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

export function destroyButtonSecurity(permissions, status, common, constants, type) {
    let result = false;
   if (type === common.statusTypes.batch) {
        if ((status === constants.batchStatuses.inserted || status === constants.batchStatuses.released || status === constants.batchStatuses.growing) && permissions.delete) {
            result = true;
        } else {
            result = false;
        }
    } else if (type === common.statusTypes.productionBatch) {
        if ((status === constants.productionBatchStatuses.inserted || status === constants.productionBatchStatuses.released ||
                status === constants.productionBatchStatuses.inprogress) && permissions.delete) {
            result = true
        } else {
            result = false;
        }
    } else if (type === common.statusTypes.productionOrder){
        if((status === constants.productionOrderStatuses.inserted || status === constants.productionOrderStatuses.planned ||
            status === constants.productionOrderStatuses.released || status === constants.productionOrderStatuses.new ||
            status === constants.productionOrderStatuses.inprogress) && permissions.delete){
            result = true;
        } else {
            result = false;
        }
    } else {
        if(status === constants.statuses.complete || status === constants.statuses.reviewed || status === constants.statuses.signed || status === constants.statuses.deleted){
            result = false;
        } else {
            if(permissions.delete){
                result = true;
            }
        }
    }
    return result;
}
