import React from 'react';
import Typography from '@mui/material/Typography';
import Aux from '../../../hoc/Auxilary/Auxilary';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddButton from '@mui/icons-material/AddBoxTwoTone';
import Divider from '@mui/material/Divider';

export default function SectionTitle (props) {  
  const add = props.add ?? false;
  return (
    <Aux>
      <Typography variant="h4">
        {props.title}
      </Typography>
      {add &&
        <Tooltip title={props.addTitle} placement='right'>
            <div style={{ display: 'inline' }}>
              <IconButton 
                  data-cy={props['data-cy']}
                  aria-label={props.addTitle} onClick={() => add()} sx={{ mt: -1.5 }} disabled={!props.security}>
                <AddButton sx={{ color: (props.addSecurity ? 'grey.300' : 'primary.main' )}} />
              </IconButton>
            </div>
        </Tooltip>
      } 
      <Divider sx={{ marginBottom: 1 }}/>
    </Aux>
  );
}