
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import Grid from '@mui/material/Grid';
import isEmpty from '../../../is-empty';

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/help';
import 'tinymce/plugins/imagetools';

export default function RichTextField(props) {
    const { changeRichText, disabled, errors, height, id, 
            index, textAreaName, value } = props;

    // const uploadImage = async (data, path) => {
    //   return;
    // }
    console.error(errors);

    return (
      <Grid container spacing={2}>
          <Grid item xs={12}>
              <div
                style={{
                  position: 'relative',
                  border: !isEmpty(errors) ? '1px solid red' : 'none',
                  paddingBottom: !isEmpty(errors) ? '30px' : '0',
                }}
              >
                <Editor
                    disabled={disabled}
                    id={id}
                    key={id + '-' + index}
                    textAreaName={textAreaName}
                    value={value}
                    onEditorChange={(newValue, editor) => changeRichText(index, newValue)}
                    init={{
                        skin: false,
                        content_css: false,
                        height: height,
                        width: '100%',
                        menubar: false,
                        image_uploadtab: true,
                        images_reuse_filename: true,
                        image_title: true,
                        image_caption: true,
                        file_picker_types: 'image',
                        images_dataimg_filter: function(img) {
                          return img.hasAttribute('internal-blob');
                        },
                        /* and here's our custom image picker*/
                        file_picker_callback: function (cb, value, meta) {
                          var input = document.createElement('input');
                          input.setAttribute('type', 'file');
                          input.setAttribute('accept', 'image/*');
                      
                          /*
                            Note: In modern browsers input[type="file"] is functional without
                            even adding it to the DOM, but that might not be the case in some older
                            or quirky browsers like IE, so you might want to add it to the DOM
                            just in case, and visually hide it. And do not forget do remove it
                            once you do not need it anymore.
                          */
                      
                          input.onchange = function () {
                            var file = this.files[0];
                      
                            var reader = new FileReader();
                            reader.onload = function () {
                              /*
                                Note: Now we need to register the blob in TinyMCEs image blob
                                registry. In the next release this part hopefully won't be
                                necessary, as we are looking to handle it internally.
                              */
                              var id = 'blobid' + (new Date()).getTime();
                              var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                              var base64 = reader.result.split(',')[1];
                              var blobInfo = blobCache.create(id, file, base64);
                              blobCache.add(blobInfo);
                      
                              /* call the callback and populate the Title field with the file name */
                              cb(blobInfo.blobUri(), { title: file.name });
                            };
                            reader.readAsDataURL(file);
                          };
                      
                          input.click();
                        },
                        // imagetools_fetch_image: async function (img) {
                        //   return new tinymce.util.Promise(async function (resolve) {
                        //     const blobImage = await axios.get('/api/document/' + img);
                        //     console.info(blobImage);
                        //     // resolve(new Blob(...));
                        //   });
                        // },
                        // images_upload_handler: async function(
                        //   blobInfo: any,
                        //   success: any,
                        //   failure: any
                        // ) {
                        //   let blob = blobInfo.blob();
                        //   console.info(blob);
                        //   console.info(blobInfo);
                        //   let formData = new FormData();
                        //   let reader = new FileReader();
                        //   reader.readAsBinaryString(blobInfo.blob());
                        //   reader.onload = async () => {
                        //       formData.append("file", blob);
                        //       formData.append("fileName", blob.name);
                        //       formData.append("type", blob.type);
                        //       formData.append('objectId', documentId);
                        //       formData.append('objectType', objectType);
                        //       try {
                        //           const result = await axios.post('/api/documents/add', formData)
                        //           imageCallback(index, result.data);
                        //           success("data:application/octet-stream;base64," + blobInfo.base64());
                        //       } catch(err){
                        //           //this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
                        //           failure(err);
                        //       }
                        //   };
                        // },
                        // imagetools_fetch_image: async function (img) {
                        //   return new tinymce.util.Promise(async function (resolve) {
                        //     const blobImage = await axios.get('/api/document/' + img);
                        //     console.info(blobImage);
                        //     // resolve(new Blob(...));
                        //   });
                        // },
                        plugins: [
                          'advlist autolink lists link image charmap anchor searchreplace fullscreen insertdatetime media table help wordcount',
                          'imagetools code'
                        ],
                        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | forecolor backcolor removeformat | ' +
                        'alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pagebreak | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore ' +
                        'tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | removeformat code image | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                />
                {!isEmpty(errors) && (
                    <div
                      style={{
                        position: 'absolute',
                        left: '0',
                        width: '100%',
                        color: '#721c24',
                        padding: '5px',
                      }}
                    >
                          {errors}
                    </div>
                )}
              </div>
          </Grid>
      </Grid>
    );
}