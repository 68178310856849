import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import TextField from '../UI/TextField/TextField';
import ActionBar from '../UI/Buttons/ActionBar';
import DateSelect from '../UI/DateSelect/DateSelect';
import { CSVDownload } from "react-csv";
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

const ExciseAdjustmentList = (props) => {
    const [values, setValues] = useState({
        item: '',
        itemSel: null,
        items: [],
        number: '',
        startDate: '',
        endDate: '',
        offset: 0,
        listSize: 10,
        orderBy: 'number',
        ordDir: -1,
        page: 0,
        security: {},
        type: '',
        typeSel: null,
        types: []
    });
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [csv, setCsv] = useState({csvData: null, headers: null});
    const separator = sessionStorage.getItem('csvSeparator');

    const { t } = useTranslation('translation');

    const loadRecords = useCallback(async () => {
        let sort = {[values.orderBy]: values.ordDir};
        let end = values.endDate;
        if(end != null && end !== ''){
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            end.setMilliseconds(999);
        }
        let start = values.startDate;
        if(start != null && start !== ''){
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
        }
        let data = {
            startDate: start,
            endDate: end,
            sort: JSON.stringify(sort),
            size: values.listSize,
            offset: values.offset,
            number: values.number,
            item: values.item,
            type: values.type
        }
        let list = await axios.get('/api/exciseadjustments', {params: data});
        setList(list.data.data);
        setTotalCount(list.data.totalCount);
        let filters = {
            startDate: start,
            endDate: end,
            item: values.item,
            itemSel: values.itemSel,
            number: values.number,
            offset: values.offset,
            listSize: values.listSize,
            orderBy: values.orderBy,
            ordDir: values.ordDir,
            page: values.page
        };
        sessionStorage.setItem('exciseAdjustments', JSON.stringify(filters));
    }, [values]);

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.exciseItems, common.cacheValues.exciseAdjustmentType], props.auth.constants, null, props.auth.user.tenant);
            let storage = sessionStorage.getItem('exciseAdjustments');
            storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
            setValues({
                item: storage != null ? storage.item : '',
                itemSel: storage != null ? storage.itemSel : null,
                number: storage != null ? storage.number : null,
                startDate: storage != null ? storage.startDate : '',
                endDate: storage != null ? storage.endDate : '',
                offset: storage != null ? storage.offset : 0,
                listSize: storage != null ? storage.listSize : 10,
                orderBy: storage != null ? storage.orderBy : 'number',
                ordDir: storage != null ? storage.ordDir : -1,
                page: storage != null ? storage.page : 0,
                type: storage != null ? storage.type : '',
                typeSel: storage != null ? storage.typeSel : null,
                security: props.permission,
                items: cacheValues.exciseItems,
                types: cacheValues.exciseAdjustmentTypes
            }, () => {
                const crumbs = [
                    { path: 'active', label: props.t('exciseAdjustments'), screen: props.auth.screenDefs.ExciseAdjustmentList}
                ]
                props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            });
        }
        fetchData();
    }, [props]);

    useEffect(() => {
        loadRecords();
    }, [values, loadRecords]);

    const csvClick = async () => {
        setCsv({...csv, csvData: null});
        let sort = {[values.orderBy]: values.ordDir};
        let end = values.endDate;
        if(end != null && end !== ''){
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            end.setMilliseconds(999);
        }
        let start = values.startDate;
        if(start != null && start !== ''){
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            start.setMilliseconds(0);
        }
        let data = {
            startDate: start,
            endDate: end,
            sort: JSON.stringify(sort),
            size: values.listSize,
            offset: values.offset,
            number: values.number,
            item: values.item,
            type: values.type
        }
        let list = await axios.get('/api/exciseadjustmentexport', {params: data});
        setCsv({...values, csvData: list.data.data, headers: list.data.headers});
    };

    const changeValue = e => {
        const {name, value} = e.target;
        setValues({ ...values, [name]: value });
    };

    const changeAuto = (name, data) => (e, newValue) => {
        setValues({
            ...values,
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            loadRecords();
        });
    };

    const changeDate = (name) => (e) => {
        setValues({
            ...values,
            [name]: e
        })
    }

    const sortClick = (name) => {
        let ordBy = values.orderBy;
        let dir = values.ordDir;
        if(ordBy === name){
            if(dir > 0)
                dir = -1;
            else
                dir = 1;
        }

        setValues({...values, orderBy: name, ordDir: dir, offset: 0, page: 0}, () => {loadRecords();});
    }

    const handleRowsPerPage = e => {
        values({...values, listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            loadRecords();
        });
    };

    const handleChangePage = (event, newPage) => {
        setValues({...values, page: newPage, offset: newPage * values.listSize}, () => {
            loadRecords();
        });
    };

    return(
        <Aux>
            <BreadcrumbBar>
                <ActionBar
                    csvClick={csvClick}
                />
            </BreadcrumbBar>
            {csv.csvData != null &&
                <CSVDownload data={csv.csvData} target="_blank" headers={csv.headers} separator={separator}/>
            }
            <Panel>
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <TextField value={values.number} onChange={changeValue} name="number"
                                    size="medium" fullWidth={true} label={t('number')}/>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <DateSelect
                            onChange={changeDate('startDate')}
                            value={values.startDate}
                            label={t('startDate')}
                            format={props.auth.user.dateFormat}
                            data-cy="startDate"
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <DateSelect
                            onChange={changeDate('endDate')}
                            value={values.endDate}
                            label={t('endDate')}
                            format={props.auth.user.dateFormat}
                            data-cy="endDate"
                        />
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <AutoCompleteField
                            value={values.itemSel}
                            options={values.items}
                            onChange={changeAuto('itemSel', 'item')}
                            label={t('item')}
                            data-cy="item"
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <AutoCompleteField
                            value={values.typeSel}
                            options={values.types}
                            onChange={changeAuto('typeSel', 'type')}
                            label={t('type')}
                            data-cy="type"
                        />
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="Excise Adjustments">
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => sortClick('number')}
                                    active={values.orderBy === 'number'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => sortClick('date')}
                                    active={values.orderBy === 'date'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => sortClick('item')}
                                    active={values.orderBy === 'item'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('item')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>{t('quantity')}</TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => sortClick('record')}
                                    active={values.orderBy === 'record'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('record')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell><TableSortLabel onClick={() => sortClick('lastUpdated')}
                                    active={values.orderBy === 'lastUpdated'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('updated')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell><TableSortLabel onClick={() => sortClick('type')}
                                    active={values.orderBy === 'type'} direction={values.ordDir > 0 ? 'asc' : 'desc'}>{t('type')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((row, i) =>
                                <TableRow key={row._id}>
                                    <TableCell>{row.number}</TableCell>
                                    <TableCell><DateDisplay value={row.date} format={props.auth.user.dateFormat}/></TableCell>
                                    <TableCell>{row.item.number + '-' + row.item.name}</TableCell>
                                    <TableCell>
                                        <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}
                                        suffix={row.uom != null ? row.uom : ''} decimalScale={0}/>
                                    </TableCell>
                                    <TableCell>
                                        {row.objectType != null && row.record != null &&
                                            <Link to={row.objectType.link + row.record._id}>
                                                {row.objectType.text + '-' + row.record.number}
                                            </Link>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {row.lastUpdated != null && <DateDisplay value={row.lastUpdated} format={props.auth.common.dateTimeFormat}/> }
                                    </TableCell>
                                    <TableCell>{row.type != null ? row.type : ''}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TablePagination count={totalCount}
                                rowsPerPage={values.listSize}
                                onPageChange={handleChangePage}
                                page = {values.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Panel>
        </Aux>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(ExciseAdjustmentList);
