import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Aux from '../../hoc/Auxilary/Auxilary';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import TextField from '../UI/TextField/TextField';
import SectionTitle from '../UI/Typography/SectionTitle';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';

class BinContents extends Component {
    constructor(props){
        super(props);
        this.state = {
            offset: 0,
            listSize: 10,
            item: '',
            itemSel: null,
            items: [],
            orderBy: 'number',
            ordDir: 1,
            page: 0,
            list: [],
            totalCount: 0,
            number: '',
            status: '',
            statusSel: null,
            statuses: [],
            type: '',
            typeSel: null,
            types: []
        };
    }
    
    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.containerType, common.cacheValues.allItems, common.cacheValues.containerStatus], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({
            items: cacheValues.allItems,
            statuses: cacheValues.containerStatuses,
            types: cacheValues.containerTypes
        });
        this.loadRecords();
    }  

    loadRecords = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: 'bin', value: this.props.bin},
        ];
        if(this.state.item != null && this.state.item !== '')
            columnFilters.push({id: 'item', value: this.state.item});
        if(this.state.number != null && this.state.number !== '')
            columnFilters.push({id: 'number', value: this.state.number});
        if(this.state.status != null && this.state.status !== '')
            columnFilters.push({id: 'status', value: this.state.status !== '' ? this.state.status : null});
        if(this.state.type != null && this.state.type !== '')
            columnFilters.push({id: 'type', value: this.state.type !== '' ? this.state.type : null});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let results = await axios.get('/api/containers', {params: params});
        this.setState({
            list: results.data.data,
            totalCount: results.data.totalCount
        })
    };

    changeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.loadRecords();
        });
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
        
    };

    sortClick(name){
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({
            orderBy: name, 
            ordDir: ordDir, 
            offset: 0, 
            page: 0
        }, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return (
            <Aux>
                {this.state.list != null && this.state.list.length > 0 &&
                    <Aux>
                        <SectionTitle title={t('containers')}/>
                        <Grid container spacing={3}>
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.number} onChange={this.changeValue} name="number"
                                size="medium" fullWidth={true} label={t('number')}/>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <AutoCompleteField
                                    value={this.state.itemSel}
                                    options={this.state.items}
                                    onChange={this.changeAuto('itemSel', 'item')}
                                    label={t('item')}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.typeSel}
                                    options={this.state.types}
                                    onChange={this.changeAuto('typeSel', 'type')}
                                    label={t('type')}
                                />
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table axia-label="Containers" size="small">
                                <TableHead>
                                    <TableRow key="tableHeader">
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('number')}
                                            active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('item')}
                                            active={this.state.orderBy === 'item'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('item')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('lot')}
                                            active={this.state.orderBy === 'lot'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('lot')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('quantity')}
                                            active={this.state.orderBy === 'quantity'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('quantity')}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel onClick={() => this.sortClick('type')}
                                            active={this.state.orderBy === 'type'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('type')}
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.list.map((row, i) =>
                                        <TableRow key={row._id}>
                                            <TableCell><Link to={'/container/' + row._id}>{row.number}</Link></TableCell>
                                            <TableCell><Link to={'/item/' + row.item._id}>{row.item.number + '-' + row.item.name}</Link></TableCell>
                                            <TableCell><Link to={'/lot/' + row.lot._id}>{row.lot.number}</Link></TableCell>
                                            <TableCell>{row.uom != null ? row.quantity + row.uom : row.quantity}</TableCell>
                                            <TableCell>{row.type != null ? row.type.label : ''}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow key="tableFooter">
                                        <TablePagination count={this.state.totalCount}
                                        rowsPerPage={this.state.listSize}
                                        onPageChange={this.handleChangePage}
                                        page = {this.state.page}
                                        rowsPerPageOptions={common.rowOptions}
                                        onRowsPerPageChange={this.handleRowsPerPage}/>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Aux>
                }
            </Aux>
        )            
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(BinContents));