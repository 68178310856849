import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import React, { Component } from 'react';
import { format } from 'date-fns';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import parse from 'html-react-parser';
import { connect } from 'react-redux';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Button from '@mui/material/Button';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import DocumentDisplay from '../General/DocumentDisplay';
import RichText from '../../hoc/RichText/RichText';

class DocumentVersions extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            page: 0,
            listSize: 10,
            orderBy: 'updatedOn',
            ordDir: -1,
            totalCount: 0,
            showRecord: false,
            record: null
        }
    }

    componentDidMount(){
        this.loadRecords();
    }

    loadRecords = async () => {
        var sort = {[this.state.orderBy]: this.state.ordDir};
        var data = {
            offset: this.state.offset,
            size: this.state.listSize,
            sort: JSON.stringify(sort),
            document: this.props.document
        }
        var list = await axios.get('/api/manageddocchanges', {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        });
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    async recordClick(index){
        if(index != null && index !== ''){
            var row = this.state.list[index];
            var record = await axios.get('/api/manageddocchange/' + row._id);
            this.setState({
                showRecord: true,
                record: record.data
            })
        }
    }

    hide = e => {
        this.setState({showRecord: false});
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <SectionTitle title={t('versions')}/>
                <TableContainer component={Paper}>
                    <Table aria-label="Document Versions">
                        <TableHead>
                            <TableRow key="tableHeaderVersion">
                                <TableCell sx={{ width: '150px' }}>
                                    <TableSortLabel onClick={() => this.sortClick('updatedOn')}
                                        active={this.state.orderBy === 'updatedOn'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('updatedOn')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{ width: '150px' }}>
                                    <TableSortLabel onClick={() => this.sortClick('version')}
                                        active={this.state.orderBy === 'version'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('version')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{ width: '250px' }}>
                                    <TableSortLabel onClick={() => this.sortClick('updatedBy')}
                                        active={this.state.orderBy === 'updatedBy'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('updatedBy')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                    {t('notes')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list.map((row, i) =>
                                <TableRow key={row._id}>
                                    <TableCell>
                                        <TextOnlyClickButton enabled={true} click={() => this.recordClick(i)} 
                                        name={format(Date.parse(row.updatedOn != null ? row.updatedOn : new Date()), this.props.auth.user.dateFormat)}/>
                                    </TableCell>
                                    <TableCell>{row.version}</TableCell>
                                    <TableCell>
                                        {row.author != null && row.author.user != null ? row.author.user.firstName + ' ' + 
                                        row.author.user.lastName : ''}
                                    </TableCell>
                                    <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                                        {row.notes != null && row.notes !== '' ? 
                                            <RichText>
                                                {row.notes}
                                            </RichText> : ''
                                        }
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooterVersions">
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                {this.state.record != null &&
                    <Modal size="lg" centered show={this.state.showRecord} onHide={this.hide}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.state.record.number + ' - ' + this.state.record.name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.record != null && this.state.record.content != null && this.state.record.content !== '' &&
                                <div>
                                    <div>{parse(this.state.record.content)}</div>
                                    <br/>
                                </div>
                            }
                            {this.state.record != null && this.state.record.contentDocument != null &&
                                <DocumentDisplay document={this.state.record.contentDocument}/>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="contained" color="primary" size="small" onClick={this.hide}>{t('close')}</Button>
                        </Modal.Footer>
                    </Modal>
                }
                    
            </Aux>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(DocumentVersions));