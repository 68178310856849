import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Button from '@mui/material/Button';
import ActionBar from '../UI/Buttons/ActionBar';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ModalDialog from '../UI/Dialog/ModalDialog';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import Switch from '../UI/Switch/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ValueHelper from '../../helpers/valueHelper';

class TestItemList extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            index: -1,
            edit: false,
            search: '',
            searchCat: '',
            category: '',
            categories: [],
            name: '',
            uom: '',
            uoms: [],
            orderBy: 'category',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            catSelected: null,
            searchCatSelected: null,
            security: [],
            enableSave: true,
            total: false,
            totalType: '',
            totalTypeSel: null,
            totalTypes: [],
            exportName: ''
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.testTotalType], this.props.auth.constants, null, this.props.auth.user.tenant);
        let vals = await axios.get('/api/testitemvalues');
        this.setState({
            categories: vals.data.categories,
            uoms: vals.data.uoms,
            security: this.props.permission,
            totalTypes: cacheValues.testTotalTypes
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('testItems'), screen: this.props.auth.screenDefs.TestItemList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });        
    }

    async loadRecords(){
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            offset: this.state.offset,
            size: this.state.listSize,
            category: this.state.searchCat,
            name: this.state.search,
            sort: JSON.stringify(sort)
        }
        let list = await axios.get('/api/testitems', {params: data});
        let records = list.data.data;
        for(let row of records){
            if(row.totalType != null){
                row.totalTypeSel = this.state.totalTypes.find(x => x.value === row.totalType);
            }
        }
        this.setState({
            list: records,
            totalCount: list.data.totalCount
        })
    }

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords();});
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    search = e => {
        this.loadRecords();
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    closeClick = e => {
        this.setState({edit: false})
    };

    newClick = e => {
        this.setState({edit: true})
    };

    clear = () => {
        this.setState({
            edit: false,
            index: -1,
            category: '',
            name: '',
            uom: '',
            _id: '',
            catSelected: null,
            uomSelected: null,
            total: false,
            totalType: '',
            totalTypeSel: null,
            exportName: ''
        });
    }

    edit(index) {
        let row = this.state.list[index];
        let catSelected = this.state.categories.find(x => x.value === row.category._id);
        let uomSelected = this.state.uoms.find(x => x.value === row.uom._id);
        this.setState({
            _id: row._id,
            index: index,
            name: row.name,
            category: row.category._id,
            uom: row.uom._id,
            edit: true,
            catSelected: catSelected,
            uomSelected: uomSelected,
            total: row.total,
            totalType: row.totalType,
            totalTypeSel: row.totalTypeSel,
            exportName: row.exportName
        })
    }

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        let data = {
            _id: this.state._id,
            name: this.state.name,
            category: this.state.category,
            uom: this.state.uom,
            total: this.state.total,
            totalType: this.state.totalType,
            exportName: this.state.exportName
        };
        try{
            let result = await axios.post('/api/testitem', data);
            this.setState({ enableSave: true }, () => {
                this.loadRecords();
                this.clear();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    }

    delete = (e) => {
        if(!this.state.enableSave && (this.state.exportName == null || this.state.exportName === ''))
            return;
        this.setState({enableSave: false}, () => this._delete(e));
    }

    _delete = async e => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/testitem/' + this.state._id);
                this.setState({enableSave: true});
                this.loadRecords();
                this.clear();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.setState({enableSave: true});
            this.clear();
        }
    };

    buttonStack = () => {
        const t = this.props.t;
        const buttons = [
            <Button variant='text' sx={{ color: 'error.main' }} onClick={this.delete} size="small" disabled={this.state._id === ''} key='testItemDeleteButton' >{t('delete')}</Button>,
            <Button variant="text" sx={{ color: 'secondary.main' }} name="Clear" size="small" onClick={this.clear} key='testItemCancelButton' >{t('cancel')}</Button>,            
            <Button variant="contained" size="small" disabled={!this.state.enableSave} onClick={this.save} key='testItemSaveButton' >{t('save')}</Button>,
        ];

        return buttons;
    }

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createClick={this.newClick}
                        createAccess={security.create}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.search} onChange={this.changeValue} name="search"
                            size="medium" fullWidth={true} label="Name"/>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.searchCatSelected}
                                options={this.state.categories}
                                onChange={this.changeAuto('searchCatSelected', 'searchCat')}
                                label={t('category')}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Test Items">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('name')}
                                            active={this.state.orderBy === 'name'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('name')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('category')}
                                            active={this.state.orderBy === 'category'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('category')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('uom')}
                                            active={this.state.orderBy === 'uom'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('uom')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('total')}
                                            active={this.state.orderBy === 'total'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('total')}
                                        </TableSortLabel>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <TextOnlyClickButton enabled={true} click={() => this.edit(i)} name={row.name} id={'testItem' + i}/>
                                        </TableCell>
                                        <TableCell>{row.category.code}</TableCell>
                                        <TableCell>{row.uom.label}</TableCell>
                                        <TableCell>{row.total ? t('yes') : t('no')}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <ModalDialog title={t('testItem')} dialogStatus={this.state.edit} fullWidth maxWidth='xs' toggleDialog={this.clear} buttonStack={this.buttonStack()}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField value={this.state.name} onChange={this.changeValue} name="name" size="medium" fullWidth label={t('name')}/>
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteField
                                    value={this.state.catSelected}
                                    options={this.state.categories}
                                    onChange={this.changeAuto('catSelected', 'category')}
                                    label={t('category')}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoCompleteField
                                    value={this.state.uomSelected}
                                    options={this.state.uoms}
                                    onChange={this.changeAuto('uomSelected', 'uom')}
                                    label={t('uom')}
                                    fullWidth
                                />
                            </Grid>
                            {this.props.auth.user.internal &&
                                <Grid item xs={5}>
                                    <TextField value={this.state.exportName} onChange={this.changeValue} name="exportName" size="medium" fullWidth label={t('exportName')}/>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormControlLabel
                                        disabled={false}
                                        control={
                                        <Switch
                                            checked={this.state.total}
                                            onChange={this.changeBool("total")}
                                            name="total"
                                        />
                                        }
                                        label={t("total")}
                                    />
                                </FormControl>
                            </Grid>
                            {this.state.total &&
                                <Grid item xs={12}>
                                     <AutoCompleteField
                                        value={this.state.totalTypeSel}
                                        options={this.state.totalTypes}
                                        onChange={this.changeAuto('totalTypeSel', 'totalType')}
                                        label={t('type')}
                                        fullWidth
                                    />
                                </Grid>
                            }
                        </Grid>
                    </ModalDialog>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(TestItemList));
