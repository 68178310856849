import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import fileDownload from 'js-file-download';
import Excel from 'exceljs';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { CSVDownload } from "react-csv";

class ProductionBoms extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            pages: 1,
            listSize: 10,
            orderBy: 'number',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            number: '',
            description: '',
            security: [],
            csvData: null,
            separator: ',',
            exportList: []
        }
    }

    componentDidMount(){
        const t = this.props.t;
        let storage = sessionStorage.getItem('boms');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        const csvSeparator = sessionStorage.getItem('csvSeparator');
        let exportList = [
            {label: t('export'), onClick: this.download, enabled: this.state.security.print},
            {label: t('csv'), onClick: this.csv, enabled: this.state.security.print}
        ];
        this.setState({
            offset: storage != null ? storage.offset : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'number',
            ordDir: storage != null ? storage.ordDir : 1,
            number: storage != null ? storage.number : '',
            description: storage != null ? storage.description : '',
            separator: csvSeparator != null ? csvSeparator : ',',
            page: storage != null ? storage.page : 0,
            exportList: exportList
        }, () => {
            this.loadRecords();
        });
    }

    async loadRecords(){
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            offset: this.state.offset,
            size: this.state.listSize,
            number: this.state.number,
            description: this.state.description,
            sort: JSON.stringify(sort)
        };
        const list = await axios.get('api/boms', {params: data});
        this.setState({
            list: list.data.data,
            pages: Math.ceil(list.data.totalCount / this.state.listSize),
            totalCount: list.data.totalCount,
            security: this.props.permission
        });
        const crumbs = [
            { path: 'active', label: this.props.t('productionBoms'), screen: this.props.auth.screenDefs.ProductionBoms}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        this.saveFilters();
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            number: this.state.number,
            description: this.state.description,
            page: this.state.page
        };
        sessionStorage.setItem('boms', JSON.stringify(data));
    }

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => this.loadRecords());
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    download = async (e) => {
        const t = this.props.t;
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            number: this.state.number,
            description: this.state.description,
            sort: JSON.stringify(sort)
        };
        let result = await axios.get('/api/bomexport', {params: data});
        let excelData = result.data;
        const workbook = new Excel.Workbook();
        workbook.created = new Date();
        workbook.properties.date1904 = true;
        workbook.views = [
            {
            x: 0, y: 0, width: 25000, height: 15000,
            firstSheet: 0, activeTab: 1, visibility: 'visible'
            }
        ];
        const sheet = workbook.addWorksheet('Production BOMs');
        sheet.state = 'visible';
        let startRow = 1;
        for(let record of excelData){
            startRow = this.bomPrint(startRow, record, sheet, t)
            sheet.getCell('A' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('B' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('C' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('D' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('E' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('F' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('G' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('H' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('I' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('J' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('K' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('L' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('M' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            startRow++;
        }
        let buffer = await workbook.xlsx.writeBuffer();
        fileDownload(buffer, 'EvoraProductionBoms.xlsx');
    };

    bomPrint(startRow, record, sheet, t){
        let currRow = startRow;
        sheet.mergeCells('A' + currRow + ':J' + currRow);
        sheet.getCell('A' + currRow).value = record.number + '-' + record.description;
        currRow++;
        sheet.getCell('A' + currRow).value = t('uom');
        sheet.getCell('B' + currRow).value = record.uom;
        currRow++;
        sheet.getCell('A' + currRow).value = t('produces');
        sheet.getCell('B' + currRow).value = record.item != null ? record.item.number : '';
        sheet.getCell('C' + currRow).value = record.item != null ? record.item.name : '';
        currRow++;
        sheet.getCell('A' + currRow).value = t('inputs');
        currRow++;
        sheet.getCell('A' + currRow).value = t('number');
        sheet.getCell('B' + currRow).value = t('type');
        sheet.getCell('C' + currRow).value = t('item');
        sheet.getCell('D' + currRow).value = t('productionBom');
        sheet.getCell('E' + currRow).value = t('quantity');
        sheet.getCell('F' + currRow).value = t('uom');
        sheet.getCell('G' + currRow).value = t('scrapPercent');
        sheet.getCell('H' + currRow).value = t('preMade');
        currRow++
        for(let row of record.lines){
            sheet.getCell('A' + currRow).value = row.number;
            sheet.getCell('B' + currRow).value = row.type;
            sheet.getCell('C' + currRow).value = row.item != null ? row.item.number + '-' + row.item.name : '';
            sheet.getCell('D' + currRow).value = row.productionBom != null ? row.productionBom.number + '-' + row.productionBom.description : '';
            sheet.getCell('E' + currRow).value = row.quantity;
            sheet.getCell('F' + currRow).value = row.uom;
            sheet.getCell('G' + currRow).value = row.scrapPercent;
            sheet.getCell('H' + currRow).value = row.preMade === true ? t('yes') : t('no');
            currRow++
        }
        if(record.outputs != null && record.outputs.length > 0){
            currRow++;
            sheet.getCell('A' + currRow).value = t('outputs');
            currRow++;
            sheet.getCell('A' + currRow).value = t('item');
            sheet.getCell('B' + currRow).value = t('quantity');
            currRow++;
            for(let row of record.outputs){
                sheet.getCell('A' + currRow).value = row.item.number + '-' + row.item.name;
                sheet.getCell('B' + currRow).value = row.quantity;
                currRow++;
            }
        }
        return currRow;
    }

    csv = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            number: this.state.number,
            description: this.state.description,
            sort: JSON.stringify(sort)
        };
        const result = await axios.get('api/bomcsv', {params: data});
        let headers = result.data.headers;
        let csvData = result.data.data;
        this.setState({csvData: csvData, headers: headers});
    }

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/bom'
                        createAccess={security.create}
                        exportList={this.state.exportList}
                    />
                </BreadcrumbBar>
                {this.state.csvData != null &&
                    <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.headers} separator={this.state.separator}/>
                }  
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.number} onChange={this.changeValue} name="number"
                            size="medium" fullWidth={true} label={t('number')} data-cy="number"/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.description} onChange={this.changeValue} name="description"
                            size="medium" fullWidth={true} label={t('description')} data-cy="description"/>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Production BOMs">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('description')}
                                        active={this.state.orderBy === 'description'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('description')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/bom/' + row._id} name={'bomLink' + i}>{row.number}</Link></TableCell>
                                        <TableCell>{row.description}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(ProductionBoms));
