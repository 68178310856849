import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import DateSelect from '../UI/DateSelect/DateSelect';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import { CSVDownload } from "react-csv";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import ActionBar from '../UI/Buttons/ActionBar';
import { NumericFormat } from 'react-number-format';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class DestructionReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            location: '',
            locations: [],
            date: null,
            endDate: new Date(),
            user: '',
            users: [],
            batch: '',
            batches: [],
            lot: '',
            lots: [],
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            locationSel: null,
            csvData: null,
            headers: null
        };
    }

    async componentDidMount(){
        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location, common.cacheValues.user], this.props.auth.constants, null, this.props.auth.user.tenant);
        let endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        let storage = sessionStorage.getItem('destructionReport');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        const csvSeparator = sessionStorage.getItem('csvSeparator');
        this.setState({
            locations: cacheValues.locations,
            security: this.props.permission,
            location: storage != null ? storage.location : '',
            locationSel: storage != null ? storage.locationSel : null,
            date: storage != null && storage.date != null && storage.date !== '' ? new Date(storage.date) : '',
            endDate: storage != null && storage.endDate != null && storage.endDate !== '' ? new Date(storage.endDate) : endDate,
            separator: csvSeparator != null ? csvSeparator : ','
        }, () => {
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let date = this.state.date;
        if(date != null && date !== ''){
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
        }
        let endDate = this.state.endDate;
        if(endDate != null && endDate !== ''){
            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds(59);
            endDate.setMilliseconds(999);
        }
        let data = {
            offset: this.state.offset,
            size: this.state.listSize,
            location: this.state.location,
            user: this.state.user,
            date: date,
            endDate: endDate,
            sort: JSON.stringify(sort)
        };
        let list = await axios.get('/api/destructionreport', {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('destructionReport'), screen: this.props.auth.screenDefs.DestructionReport}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
        this.saveFilters();
    };

    saveFilters(){
        let data = {
            location: this.state.location,
            locationSel: this.state.locationSel,
            date: this.state.date,
            endDate: this.state.endDate
        };
        sessionStorage.setItem('destructionReport', JSON.stringify(data));
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e, offset: 0, page: 0}, () => {this.loadRecords()});
    }

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    csvClick = async (e) => {
        this.setState({csvData: null});
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let date = this.state.date;
        if(date != null && date !== ''){
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
        }
        let endDate = this.state.endDate;
        if(endDate != null && endDate !== ''){
            endDate.setHours(23);
            endDate.setMinutes(59);
            endDate.setSeconds((59));
            endDate.setMilliseconds(999);
        }
        let data = {
            location: this.state.location,
            user: this.state.user,
            date: date,
            endDate: endDate,
            sort: JSON.stringify(sort)
        };
        let list = await axios.get('/api/destructionreport', {params: data});
        let headers = list.data.headers;
        let csvData = list.data.data;
        this.setState({csvData: csvData, headers: headers});
    }

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        csvClick={this.csvClick}
                        createAccess={this.props.permission.create}
                    />
                </BreadcrumbBar>                
                {this.state.csvData != null &&
                    <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.headers} separator={this.state.separator}/>
                }
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.locationSel}
                                options={this.state.locations}
                                onChange={this.changeAuto('locationSel', 'location')}
                                label={t('location')}
                                data-cy="location"
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                className="form-control"
                                onChange={this.dateChange('date')}
                                value={this.state.date}
                                label={t('startDate')}
                                format={this.props.auth.user.dateFormat}
                                data-cy="date"
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                className="form-control"
                                onChange={this.dateChange('endDate')}
                                value={this.state.endDate}
                                label={t('endDate')}
                                format={this.props.auth.user.dateFormat}
                                data-cy="endDate"
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table  aria-label="DetructionReport">
                            <TableHead>
                                <TableRow key="DestRepHead">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('date')}
                                        active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('item')}</TableCell>
                                    <TableCell>{t('container')}</TableCell>
                                    <TableCell>{t('amount')}</TableCell>
                                    <TableCell>{t('strain')}</TableCell>
                                    <TableCell>{t('batch')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row.container + i}>
                                        <TableCell><Link to={'/destruction/' + row._id}>{row.number}</Link></TableCell>
                                        <TableCell><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                        <TableCell>{row.item}</TableCell>
                                        <TableCell>{row.container}</TableCell>
                                        <TableCell>
                                            <NumericFormat displayType={'text'} value={row.amount} thousandSeparator={true}
                                            suffix={row.uom} decimalScale={3}/>
                                        </TableCell>
                                        <TableCell>{row.strain != null ? row.strain : ''}</TableCell>
                                        <TableCell>{row.batch != null ? row.batch : ''}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(DestructionReport));
