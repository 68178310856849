import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// import Activity from './Buttons/Activity';
import Notifications from './Buttons/Notifications';
import Mail from './Buttons/Mail';
import BarcodeScanner from './Buttons/BarcodeScanner';

export default function Bottom(props) {
    // fabButton: {
    //     position: 'absolute',
    //     zIndex: 1,
    //     top: -30,
    //     left: 0,
    //     right: 0,
    //     margin: '0 auto',
    // },

    const { clicked } = props;
    const location = 'bottom';
    const bottomBar = (
        <AppBar position="fixed" color="secondary" sx={{ top: 'auto', bottom: 0 }}>
            <Toolbar>
                <IconButton edge="start" aria-label="open drawer" onClick={clicked} sx={{ color: '#FFF', marginRight: 'auto' }}>
                    <MenuIcon />
                </IconButton>
                <BarcodeScanner />
                {/* <Activity location={location} /> */}
                <Mail location={location} />
                <Notifications location={location} />
            </Toolbar>
        </AppBar>
    );

    return bottomBar;
}