import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Aux from '../../hoc/Auxilary/Auxilary';
import SectionTitle from '../UI/Typography/SectionTitle';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class BatchLedgers extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: []
        };
    }

    componentDidMount(){
        this.loadRecords();
    }

    loadRecords = async () => {
        let params = this.props.activity != null && this.props.activity !== '' ? {activity: this.props.activity} : null;
        let records = null;
        if(params == null){
            records = await axios.get('/api/batchledgers/' + this.props.batch);
        } else {
            records = await axios.get('/api/batchledgers/' + this.props.batch, {params: params});
        }
        this.setState({list: records.data});
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <SectionTitle title={t('ledgers')}/>
                <TableContainer component={Paper}>
                    <Table aria-label="Batch Ledgers">
                        <TableHead key="Batch Ledger">
                            <TableRow>
                                <TableCell>{t('date')}</TableCell>
                                <TableCell>{t('item')}</TableCell>
                                <TableCell>{t('room')}</TableCell>
                                <TableCell>{t('type')}</TableCell>
                                <TableCell>{t('activity')}</TableCell>
                                <TableCell>{t('amount')}</TableCell>
                                <TableCell>{t('updated')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list.map((row, i) =>
                                <TableRow key={row._id}>
                                    <TableCell><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                    <TableCell><Link to={'/item/' + row.item._id}>{row.item.number + '-' + row.item.name}</Link></TableCell>
                                    <TableCell>{row.room != null ? row.room.name : ''}</TableCell>
                                    <TableCell>{row.type.label}</TableCell>
                                    <TableCell><Link to={'/batchactivity/' + row.activity._id}>{row.activity.number}</Link></TableCell>
                                    <TableCell>{row.amount}</TableCell>
                                    <TableCell><DateDisplay value={row.transactionDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Aux>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(BatchLedgers));
