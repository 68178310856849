
import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../../is-empty';
import DateSelect from '../../UI/DateSelect/DateSelect';
import TextField from '../../UI/TextField/TextField';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../../UI/AutoCompleteField/AutoCompleteField';
import FormLabel from '@mui/material/FormLabel';
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import { fieldSecurity } from '../../../helpers/securityHelper';
import { ClearButton, SaveButton } from '../../UI/Buttons/Buttons';
import StackedButton from '../../UI/Buttons/StackedButton';
import SaveOpenIcon from 'mdi-material-ui/ContentSaveEditOutline';
import SaveCloseIcon from 'mdi-material-ui/ContentSaveMoveOutline';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import ValueHelper from '../../../helpers/valueHelper';
import common from '../../../jsons/common.json';
import ModalDialog from '../../UI/Dialog/ModalDialog';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';

class EvoraBatch extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            description: '',
            status: '',
            statusSel: null,
            statuses: [],
            location: '',
            locSel: null,
            locations: [],
            strain: '',
            strainSel: null,
            strains: [],
            startItem: '',
            startItemSel: null,
            outputItem: '',
            outputItemSel: null,
            items: [],
            template: '',
            templateSel: null,
            templates: [],
            startQuantity: '0',
            currentQuantity : '0',
            plannedStart: new Date(),
            actualStart: '',
            vegStart: '',
            vegCount: '',
            expectedVegCount: '',
            flowerStart: '',
            flowerCount: '',
            expectedFlowerCount: '',
            harvestDate: '',
            expectedHarvest: '',
            expectedYield: '',
            expectedYieldUOM: '',
            expectedUOMSel: null,
            uoms: [],
            actualYield: '',
            actualYieldUOM: '',
            actualUOMSel: null,
            harvestedCount: '',
            expectedHarvestCount: '',
            currentRooms: [],
            roomHistory: [],
            templateObject: this.props.templateObject,
            showActivity: false,
            sequence: '',
            activity: '',
            actSel: null,
            dateShift: '',
            stageIndex: -1,
            sops: [],
            sopsSel: [],
            activities: [],
            sopList: [],
            stage: '',
            stageSel: null,
            stageOptions: [],
            stages: [],
            documents: [],
            plantedQuantity: '',
            reviewer: null,
            signer: null,
            reviewers: [],
            signers: [],
            signed: false,
            signatureStatus: this.props.auth.constants.statuses.inprogress,
            expectedDryYield: '',
            expectedDryLoss: '',
            cloneSource: [],
            outputLots: [],
            cropTreatments: [],
            externalTreatments: [],
            fieldAccess: false,
            showContainer: false,
            containerAmount: '',
            containerItem: '',
            containerItemSel: null,
            containerItems: [],
            containerDate: new Date(),
            expectedYieldPerPlant: '',
            outputPerHarvestedPlant: '',
            outputPerPlantedPlant: '',
            productionSchedule: '',
            productionScheduleSel: null,
            productionSchedules: [],
            productionScheduleActivity: '',
            prodActSel: null,
            productionScheduleActivities: [],
            rooms: [],
            cloneRoomOptions: [],
            vegRoomOptions: [],
            flowerRoomOptions: [],
            released: false,
            trimmings: [],
            dryings: [],
            curings: [],
            strainObject: null,
            strainItems: [],
            security: [],
            openStatus: this.props.open,
            toggleDialog: this.props.onClose,
            buttonStack: [],
            enableSave: true
        };
    }

    async componentDidMount(){
        const t = this.props.t;
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.strain, common.cacheValues.cultivationLocation, common.cacheValues.quantityUOM,
                                common.cacheValues.defaultGrowingRoom, common.cacheValues.batchStatus, common.cacheValues.batchStage, common.cacheValues.batchTemplateActive,
                                common.cacheValues.batchActivitySop, common.cacheValues.batchActivityDef, common.cacheValues.productionSchedule], 
                                this.props.auth.constants, null, this.props.auth.user.tenant);
        const userValues = await ValueHelper.screenUsers(common.screens.cultivationBatch, this.props.auth.user.tenant);
        let strains = cacheValues.strains;
        let locations = cacheValues.locations;
        const statusSel = cacheValues.batchStatuses.find(x => x.value === this.props.auth.constants.batchStatuses.inserted);
        const stageSel = cacheValues.batchStages.find(x => x.value === this.props.auth.constants.batchStages.prop);
        const fieldAccess = this.props.fieldAccess ?? false;
        const buttonStack = [
             {label: t('saveOpen'), onClick: this.saveOpen, disabled: !this.state.enableSave, icon: <SaveOpenIcon /> },
             {label: t('saveClose'), onClick: this.saveClose, disabled: !this.state.enableSave, icon: <SaveCloseIcon />},
        ]
        let location = '';
        let locSel = null;
        if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
            location = this.props.auth.user.defaultLocation;
            locSel = locations.find(x => x.value === location);
        } else {
            if(locations.length === 1){
                locSel = locations[0];
                location = locSel.value;
            }
        }
        this.setState({
            templates: cacheValues.activeBatchTemplates,
            statuses: cacheValues.batchStatuses,
            strains: strains,
            locations: locations,
            location: location,
            locSel: locSel,
            uoms: cacheValues.quantityUOMs,
            sopList: cacheValues.sops,
            activities: cacheValues.batchActivityDefs,
            statusSel: statusSel,
            status: this.props.auth.constants.batchStatuses.inserted,
            stageOptions: cacheValues.batchStages,
            stage: this.props.auth.constants.batchStages.prop,
            stageSel: stageSel,
            reviewers: userValues.review,
            signers: userValues.approve,
            fieldAccess: fieldAccess,
            productionSchedules: cacheValues.productionSchedules,
            rooms: cacheValues.rooms,
            reload: this.props.reload,
            security: this.props.permission,
            openStatus: this.props.open,
            toggleDialog: this.props.onClose,
            buttonStack: buttonStack
        }, () => {
               if(this.props.id != null && this.props.id !== '')
               this.loadRecord();
        });
    }

    loadRecord = async () => {
        const id = this.state._id != null && this.state._id !== '' ? this.state._id :
                   this.props.id != null && this.props.id != null ? this.props.id : null;
        let record = await axios.get('/api/ebatch/' + id);
        let items = record.data.items;
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let locSel = this.state.locations.find(x => x.value === record.data.location);
        let strainSel = this.state.strains.find(x => x.value === record.data.strain);
        let startItemSel = items.find(x => x.value === record.data.startItem);
        let outputItemSel = items.find(x => x.value === record.data.outputItem._id);
        let templateSel = this.state.templates.find(x => x.value === record.data.template);
        let stageSel = this.state.stageOptions.find(x => x.value === record.data.stage);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.batch);
        let productionSchedules = this.state.productionSchedules;
        if(record.data.productionScheduleValue != null){
            let exists = productionSchedules.find(x => x.value === record.data.productionScheduleValue.value);
            if(!exists){
                productionSchedules.push(record.data.productionScheduleValue);
            }
        }
        let prodActSel = null;
        if(record.data.productionScheduleActivity != null){
            prodActSel =
                    record.data.productionScheduleActivities.find(x => x.value === record.data.productionScheduleActivity);
        }
        let cloneRoomOptions = [];
        if(record.data.cloneRooms != null && record.data.cloneRooms.length > 0){
            record.data.cloneRooms.forEach(row => {
                let val = this.state.rooms.find(x => x.value === row);
                cloneRoomOptions.push(val);
            });
        }
        let vegRoomOptions = [];
        if(record.data.vegRooms != null && record.data.vegRooms.length > 0){
            record.data.vegRooms.forEach(row => {
                let val = this.state.rooms.find(x => x.value === row);
                vegRoomOptions.push(val);
            });
        }
        let flowerRoomOptions = [];
        if(record.data.flowerRooms != null && record.data.flowerRooms.length > 0){
            record.data.flowerRooms.forEach(row => {
                let val = this.state.rooms.find(x => x.value === row);
                flowerRoomOptions.push(val);
            });
        }
        let stages = record.data.stages;
        stages.forEach(row => {
            let roomSel = row.room != null && row.room !== '' ? this.state.rooms.find(x => x.value === row.room) : null;
            row.roomSel = roomSel;
        });
        this.setState({
            _id: record.data._id,
            number: record.data.number,
            description: record.data.description,
            status: record.data.status,
            statusSel: statusSel,
            location: record.data.location,
            locSel: locSel,
            strain: record.data.strain,
            strainObject: record.data.strainObject,
            strainSel: strainSel,
            startItem: record.data.startItem,
            startItemSel: startItemSel,
            outputItem: record.data.outputItem,
            outputItemSel: outputItemSel,
            template: record.data.template,
            templateSel: templateSel,
            startQuantity: record.data.startQuantity,
            plannedStart: record.data.plannedStart,
            currentQuantity: record.data.currentQuantity,
            stages: stages,
            stage: record.data.stage,
            stageSel: stageSel,
            fieldAccess: fieldAccess,
            productionSchedule: record.data.productionSchedule,
            productionScheduleSel: record.data.productionScheduleValue,
            productionSchedules: productionSchedules,
            prodActSel: prodActSel,
            productionScheduleActivity: record.data.productionScheduleActivity,
            productionScheduleActivities: record.data.productionScheduleActivities,
            cloneRoomOptions: cloneRoomOptions,
            vegRoomOptions: vegRoomOptions,
            flowerRoomOptions: flowerRoomOptions,
            items: record.data.items,
            actualStart: record.data.actualStart != null ? record.data.actualStart : '',
            strainItems: record.data.strainItems,
        }, () => {
            if(record.data.templateObject != null)
                this.calculateActivityDates();
        });
    };

    async getTemplate(){
        if(this.state.template != null && this.state.template !== ''){
            const template = await axios.get('/api/batchtemplatebatch/' + this.state.template);
            let stages = [];
            template.data.stages.forEach(row => {
                let stage = {
                    stage: row.stage,
                    duration: row.duration,
                    _id: row._id
                };
                let activities = [];
                row.activities.forEach(act => {
                    activities.push({
                        sequence: act.sequence,
                        defId: act.activity._id,
                        dateShift: act.dateShift,
                        activityDefinition: {
                            changeRoom: act.activity.changeRoom,
                            code: act.activity.code,
                            description: act.activity.description,
                            definitionId: act.activity._id
                        }
                    });
                });
                stage.activities = activities;
                stages.push(stage);
            });
            this.setState({templateObject: template.data, stages}, () => {
                this.setItems();
                this.calculateActivityDates();
            });
        }
    };

    setStrainItems = async () => {
        if(this.state.strain != null && this.state.strain !== ''){
            let result = await axios.get('/api/strainitems/' + this.state.strain);
            let strain = result.data.strain;
            let cloneRoomOptions = [];
            if(strain.cloneRooms != null && strain.cloneRooms.length > 0){
                strain.cloneRooms.forEach(row => {
                    let val = this.state.rooms.find(x => x.value === row);
                    cloneRoomOptions.push(val);
                });
            }
            let vegRoomOptions = [];
            if(strain.vegRooms != null && strain.vegRooms.length > 0){
                strain.vegRooms.forEach(row => {
                    let val = this.state.rooms.find(x => x.value === row);
                    vegRoomOptions.push(val);
                });
            }
            let flowerRoomOptions = [];
            if(strain.flowerRooms != null && strain.flowerRooms.length > 0){
                strain.flowerRooms.forEach(row => {
                    let val = this.state.rooms.find(x => x.value === row);
                    flowerRoomOptions.push(val);
                });
            }
            let templateSel = this.state.templateSel;
            let template = this.state.template;
            if(templateSel == null){
                template = strain.template;
                templateSel = this.state.templates.find(x => x.value === strain.template);
            }
            this.setState({
                items: result.data.items,
                strainObject: result.data.strain,
                cloneRoomOptions: cloneRoomOptions,
                vegRoomOptions: vegRoomOptions,
                flowerRoomOptions: flowerRoomOptions,
                template: template,
                templateSel: templateSel,
                strainItems: result.data.items
            }, () => {
                this.setItems();
                this.getTemplate();
                this.forceUpdate();
            });
        }
    }

    setItems = () => {
        if(this.state.templateObject != null && this.state.strainObject != null){
            let startItem = '';
            let startItemSel = null;
            if(this.state.templateObject.batchStartType === this.props.auth.constants.batchStartTypes.clone){
                if(this.state.strainObject.cloneItem != null && this.state.strainObject.cloneItem !== ''){
                    startItem = this.state.strainObject.cloneItem;
                    startItemSel = this.state.items.find(x => x.value === this.state.strainObject.cloneItem);
                } else {
                    startItemSel = this.state.strainItems.find(x => x.category === this.props.auth.constants.itemCategories.clone);
                    if(startItemSel != null){
                        startItem = startItemSel.value;
                    }
                }
            } else if(this.state.templateObject.batchStartType === this.props.auth.constants.batchStartTypes.seed){
                if(this.state.strainObject.seedItem != null && this.state.strainObject.seedItem !== ''){
                    startItem = this.state.strainObject.seedItem;
                    startItemSel = this.state.items.find(x => x.value === this.state.strainObject.seedItem);
                } else {
                    startItemSel = this.state.strainItems.find(x => x.category === this.props.auth.constants.itemCategories.seeds);
                    if(startItemSel != null){
                        startItem = startItemSel.value;
                    }
                }
            } else if(this.state.templateObject.batchStartType === this.props.auth.constants.batchStartTypes.vegPlant){
                if(this.state.strainObject.vegItem != null && this.state.strainObject.vegItem !== ''){
                    startItem = this.state.strainObject.vegItem;
                    startItemSel = this.state.items.find(x => x.value === this.state.strainObject.vegItem);
                } else {
                    startItemSel = this.state.strainItems.find(x => x.category === this.props.auth.constants.itemCategories.vegPlants);
                    if(startItemSel != null){
                        startItem = startItemSel.value;
                    }
                }
            } else if(this.state.templateObject.batchStartType === this.props.auth.constants.batchStartTypes.mother){
                if(this.state.strainObject.motherItem != null && this.state.strainObject.motherItem !== ''){
                    startItem = this.state.strainObject.motherItem;
                    startItemSel = this.state.items.find(x => x.value === this.state.strainObject.motherItem);
                } else {
                    startItemSel = this.state.strainItems.find(x => x.category === this.props.auth.constants.itemCategories.ms);
                    if(startItemSel != null){
                        startItem = startItemSel.value;
                    }
                }
            } else if (this.state.templateObject.batchStartType === this.props.auth.constants.batchStartTypes.plantlet){
                let plantlet = this.state.items.find(x => x.category === this.props.auth.constants.itemCategories.plantlet);
                startItem = plantlet.value;
                startItemSel = plantlet;
            }
            let outputItemSel = null;
            let outputItem = null;
            if(this.state.templateObject.batchType._id === this.props.auth.constants.batchTypes.flower){
                if(this.state.strainObject.wetFlowerItem != null && this.state.strainObject.wetFlowerItem !== ''){
                    outputItemSel = this.state.items.find(x => x.value === this.state.strainObject.wetFlowerItem);
                    outputItem = this.state.strainObject.wetFlowerItem;
                } else {
                    outputItemSel = this.state.items.find(x => x.category === this.props.auth.constants.itemCategories.wetbulk);
                    if(outputItemSel != null){
                        outputItem = outputItemSel.value;
                    }
                }
            } else if(this.state.templateObject.batchType._id === this.props.auth.constants.batchTypes.mother){
                if(this.state.strainObject.cloneItem != null && this.state.strainObject.cloneItem !== ''){
                    outputItemSel = this.state.items.find(x => x.value === this.state.strainObject.cloneItem);
                    outputItem = this.state.strainObject.cloneItem;
                } else {
                    outputItemSel = this.state.strainItems.find(x => x.category === this.props.auth.constants.itemCategories.clones);
                    if(outputItemSel != null){
                        outputItem = outputItemSel.value;
                    }
                }
            } else if(this.state.templateObject.batchType._id === this.props.auth.constants.batchTypes.plantlet){
                if(this.state.strainObject.cloneItem != null && this.state.strainObject.cloneItem !== ''){
                    outputItemSel = this.state.items.find(x => x.value === this.state.strainObject.cloneItem);
                    outputItem = this.state.strainObject.cloneItem;
                } else {
                    outputItemSel = this.state.strainItems.find(x => x.category === this.props.auth.constants.itemCategories.clones);
                    if(outputItemSel != null){
                        outputItem = outputItemSel.value;
                    }
                }
            }
            this.setState({
                startItem: startItem,
                startItemSel: startItemSel,
                outputItem: outputItem,
                outputItemSel: outputItemSel
            })
        }
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if((isNaN(value)))
            return;
        this.setState({[name]: value});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, async () => {
            if(data === 'strain' && newValue != null)
                this.setStrainItems();
            if(data === 'template' && newValue != null)
                this.getTemplate();
                this.forceUpdate();
            if(newValue != null && data === 'productionSchedule'){
                let params = {};
                if(this.state.template != null && this.state.template !== ''){
                    params.template = this.state.template;
                }
                let activities = await axios.get('/api/productionscheduleactivities/' + newValue.value, {params: params});
                this.setState({productionScheduleActivities: activities.data});
            }
        });
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e}, () => {
            if(this.state.templateObject != null && this.state.templateObject._id != null){
                this.calculateActivityDates();
            }
        });
    };

    calculateActivityDates(){
        let stages = this.state.stages;
        var startDate = this.state.plannedStart != null && this.state.plannedStart !== '' ? this.state.plannedStart : new Date();
        if(stages != null && stages.length > 0){
            for(let i = 0; i < stages.length; i++){
                let stage = stages[i];
                for(let j = 0; j < stage.activities.length; j++){
                    let act = stage.activities[j];
                    let date = new Date(startDate);
                    date.setDate(date.getDate() + act.dateShift);
                    act.plannedDate = date;
                    if(act.activityDefinition.changeRoom || act.activityDefinition.definitionId === this.props.auth.constants.batchActivityDefs.harvest) stage.plannedDate = date;
                }
            }
        }
        this.setState({stages: stages});
    }

    saveOpen = async (e) => {
        const t = this.props.t;
        const status = await this.save();
        if(status == null || !status[0]) return;
        window.location.replace('/#/evorabatch/' + status[1]);
        this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: status[2], title: t('success'), severity: 'success'}});
    }

    saveClose = async (e) => {
        const t = this.props.t;
        const toggleDialog = this.state.toggleDialog;
        const reload = this.state.reload;
        const status = await this.save();
        if(status == null || !status[0]) return;
        toggleDialog();
        reload();
        this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: status[2], title: t('success'), severity: 'success'}});
    }

    save = async () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false });
        return this._save();
    }

    _save = async () => {
        const t = this.props.t;
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        var data = {
            _id: this.state._id,
            number: this.state.number,
            status: this.state.status,
            description: this.state.description,
            location: this.state.location,
            strain: this.state.strain,
            template: this.state.template,
            startItem: this.state.startItem,
            outputItem: this.state.outputItem,
            startQuantity: this.state.startQuantity,
            currentQuantity: this.state.currentQuantity,
            plannedStart: this.state.plannedStart,
            stage: this.state.stage,
            productionSchedule: this.state.productionSchedule,
            productionScheduleActivity: this.state.productionScheduleActivity
        }
        if(this.state._id == null || this.state._id === '' ||
            this.state.status === this.props.auth.constants.batchStatuses.inserted){
            data.stages = this.state.stages
        }
        try {
            let result = await axios.post('/api/ebatch', data);
            this.setState({_id: result.data.id, number: result.data.number, enableSave: true});
            return [ true, result.data.id, result.data.message ];
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
                return false;
            });
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.description == null || state.description === '')
            errors[t('description')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.strain == null || state.strain === '')
            errors[t('strain')] = t('required');
        if(state.template == null || state.template === '')
            errors[t('template')] = t('required');
        if(state.outputItem == null || state.outputItem === '')
            errors[t('outputItem')] = t('required');
        if(state.startQuantity == null || state.startQuantity === '')
            errors[t('startQuantity')] = t('required');
        if(state.currentQuantity == null || state.currentQuantity === '')
            errors[t('currentQuantity')] = t('required');
        if(state.plannedStart == null || state.plannedStart === '')
            errors[t('plannedStart')] = t('required');
        if(state.number != null && state.number !== ''){
            if(state.number.length < 3 || state.number.length > 50){
                errors[t('number')] = t('length6to20')
            }
        }
        if(state._id != null && state._id !== '' && (state.number == null || state.number === '')){
            errors[t('number')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    return = (e) => {
        this.props.history.goBack();
    };

    changeRoom = (stageIndex) => (e, newValue) => {
        let stages = this.state.stages;
        let stage = stages[stageIndex];
        stage.room = newValue != null ? newValue.value : null;
        stage.roomSel = newValue;
        this.setState({stages: stages});
    };

    unrelease = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._unrelease(e));
    }

    _unrelease = async (e) => {
        const t = this.props.t;
        try {
            let data = {
                id: this.state._id
            }
            let result = await axios.post('/api/unreleasebatch', data);
            this.setState({enableSave: true});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    dialogButtons = () => {
        const t = this.props.t;
        let buttons = [];
        if(this.state._id === ''){
            buttons.push(<StackedButton buttonStack={this.state.buttonStack} data-cy="save"/>);
        }else {
            buttons.push(<SaveButton saveClick={this.saveClose} enabled={this.state.enableSave} data-cy="save"/>);
        }
        if(this.state.status === this.props.auth.constants.batchStatuses.released) {
            buttons.push(<Button variant="contained" color="secondary" size="small" onClick={this.unrelease}data-cy="unrelease">
                {t('unrelease')}
            </Button>);
        }
        buttons.push(<ClearButton clearClick={this.state.toggleDialog} enabled={this.state.fieldAccess} data-cy="clear"/>);

        return buttons;
    }

    render(){
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const openStatus = this.state.openStatus;
        const toggleDialog = this.state.toggleDialog;
        const stages = this.state.stages;
        const t = this.props.t;
        return(
            <ModalDialog buttonStack={this.dialogButtons()} title={(this.state._id != null && this.state._id !== '' ? t('edit') : t('create')) + ' ' + t('batch')} toggleDialog={() => toggleDialog()}
                    dialogStatus={openStatus} fullWidth maxWidth='md' aria-labelledby="dialog-new-batch-record">
                    <Grid container spacing={4} sx={{ p: 2}}>
                        <Grid item xs={12} md={6} container direction='column' spacing={2}>
                            <Grid container spacing={2} sx={{ alignContent: 'flex-start' }}>
                                <Grid item xs={12} sm={6}>
                                    {fieldAccess ?
                                        <TextField value={this.state.number} onChange={this.changeValue} name="number"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={this.state._id == null || this.state._id === '' ? t('customNumber') : t('number')}
                                        error={errors[t('number')] != null ? true : false} helperText={errors[t('number')]}/> :
                                        <FormControl data-cy='number'>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {this.state.locations.length > 1 && fieldAccess ?
                                        <AutoCompleteField
                                        value={this.state.locSel}
                                        options={this.state.locations}
                                        onChange={this.changeAuto('locSel', 'location')}
                                        error={errors[t('location')] != null ? true : false}
                                        helperText={errors[t('location')]}
                                        label={t('location')}
                                        disabled={!fieldAccess || this.state.locations.length < 2}
                                        required
                                        data-cy='location'
                                        /> :
                                        <FormControl>
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locSel != null ? this.state.locSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item container xs={12}>
                                        <Grid item xs={6} data-cy='status'>
                                                <FormControl>
                                                <FormLabel>{t('status')}</FormLabel>
                                                {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                                </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                                <FormControl>
                                                <FormLabel>{t('growthStage')}</FormLabel>
                                                {this.state.stageSel != null ? this.state.stageSel.label : ''}
                                                </FormControl>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={6}>
                                    {this.state.status === this.props.auth.constants.batchStatuses.inserted && fieldAccess ?
                                        <DateSelect
                                        onChange={this.dateChange('plannedStart')}
                                        value={this.state.plannedStart}
                                        helperText={errors[t('plannedStart')]}
                                        error={errors[t('plannedStart')] != null ? true : false}
                                        label={t('plannedStart')}
                                        format={this.props.auth.user.dateFormat}
                                        required
                                        data-cy='plannedStart'
                                        /> :
                                        <FormControl>
                                            <FormLabel>{t('plannedStart')}</FormLabel>
                                            <DateDisplay format={this.props.auth.user.dateFormat} value={this.state.plannedStart}/>
                                        </FormControl>
                                    }

                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl>
                                        <FormLabel>{t('actualStart')}</FormLabel>
                                        {this.state.actualStart != null ? 
                                            <DateDisplay format={this.props.auth.user.dateFormat} value={this.state.actualStart}/> : ''
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {this.state.status === this.props.auth.constants.batchStatuses.inserted && fieldAccess ?
                                        <TextField type="number" value={this.state.startQuantity} onChange={this.changeNumber} name="startQuantity"
                                        size="medium" fullWidth={true} label={t('planned')} required data-cy='startQuantity'
                                        error={errors[t('startQuantity')] != null ? true : false} helperText={errors[t('startQuantity')]}/> :
                                        <FormControl>
                                            <FormLabel>{t('planned')}</FormLabel>
                                            {this.state.startQuantity}
                                        </FormControl>
                                    }
                                </Grid>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={12} sm={6}>
                                        <FormControl>
                                            <FormLabel>{t('currentQuantity')}</FormLabel>
                                            {this.state.currentQuantity}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <AutoCompleteField
                                    value={this.state.productionScheduleSel}
                                    options={this.state.productionSchedules}
                                    onChange={this.changeAuto('productionScheduleSel', 'productionSchedule')}
                                    error={errors[t('productionSchedule')] != null ? true : false}
                                    helperText={errors[t('productionSchedule')]}
                                    label={t('productionSchedule')}
                                    disabled={!fieldAccess}
                                    data-cy='productionSchedule'
                                    />
                                </Grid>
                                {this.state.productionScheduleActivities != null && this.state.productionScheduleActivities.length > 0 &&
                                    <Grid item xs={12}>
                                        <AutoCompleteField
                                                value={this.state.prodActSel}
                                                options={this.state.productionScheduleActivities}
                                                onChange={this.changeAuto('prodActSel', 'productionScheduleActivity')}
                                                error={errors[t('productionScheduleActivity')] != null ? true : false}
                                                helperText={errors[t('productionScheduleActivity')]}
                                                label={t('activity')}
                                                disabled={this.state.productionScheduleActivities.length === 0 ? true : !fieldAccess}
                                                data-cy='productionScheduleActivity'
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} container direction='column' spacing={2}>
                            <Grid container spacing={2} sx={{ alignContent: 'flex-start' }}>
                                <Grid item xs={12}>
                                    {this.state.status === this.props.auth.constants.batchStatuses.inserted && fieldAccess ?
                                        <AutoCompleteField
                                            value={this.state.strainSel}
                                            options={this.state.strains}
                                            onChange={this.changeAuto('strainSel', 'strain')}
                                            error={errors[t('strain')] != null ? true : false}
                                            helperText={errors[t('strain')]}
                                            label={t('strain')}
                                            required
                                            multiline
                                            data-cy='strain'
                                        /> :
                                        <FormControl>
                                            <FormLabel>{t('strain')}</FormLabel>
                                            {this.state.strainSel != null ? this.state.strainSel.label : t('none')}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.status === this.props.auth.constants.batchStatuses.inserted && fieldAccess ?
                                        <AutoCompleteField
                                            value={this.state.templateSel}
                                            options={this.state.templates}
                                            onChange={this.changeAuto('templateSel', 'template')}
                                            error={errors[t('template')] != null ? true : false}
                                            helperText={errors[t('template')]}
                                            label={t('template')}
                                            required
                                            data-cy='template'
                                        /> :
                                        <FormControl>
                                            <FormLabel>{t('template')}</FormLabel>
                                            {this.state.templateSel != null ? this.state.templateSel.label : t('none')}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl>
                                        <FormLabel>{t('startItem')}</FormLabel>
                                        <Link to={'/item/' + this.state.startItem}>
                                                {this.state.startItemSel != null ? this.state.startItemSel.label : ''}
                                        </Link>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl>
                                        <FormLabel>{t('outputItem')}</FormLabel>
                                        <Link to={'/item/' + this.state.outputItem}>
                                                {this.state.outputItemSel != null ? this.state.outputItemSel.label : ''}
                                        </Link>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField value={this.state.description} onChange={this.changeValue} name="description"
                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('description')} minLines={4} maxLines={6}
                                    required multiline data-cy='description'
                                    error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {stages.length > 0 &&
                                <Grid container spacing={2} direction='row' >
                                    {this.state.stages.map((row, i) => {
                                        if((row.stage._id === this.props.auth.constants.batchStages.prop ||
                                            row.stage._id === this.props.auth.constants.batchStages.veg ||
                                            row.stage._id === this.props.auth.constants.batchStages.flower)) {
                                            return (
                                                <Grid item xs={6} sm={4}>
                                                    <AutoCompleteField
                                                        value={row.roomSel}
                                                        options={
                                                            row.stage._id === this.props.auth.constants.batchStages.prop ? this.state.cloneRoomOptions :
                                                            row.stage._id === this.props.auth.constants.batchStages.veg ? this.state.vegRoomOptions :
                                                            row.stage._id === this.props.auth.constants.batchStages.flower ? this.state.flowerRoomOptions :
                                                            this.state.rooms}
                                                        onChange={this.changeRoom(i)}
                                                        error={errors[`${t('room')} (${t('line')} ${i})`] != null ? true : false}
                                                        helperText={errors[`${t('room')} (${t('line')} ${i})`]}
                                                        label={row.stage.label + " " + t('room')}
                                                        disabled={!fieldAccess}
                                                        data-cy={'room' + i}
                                                    />
                                                </Grid>
                                            )
                                        }else {
                                            return null;
                                        }
                                    })}
                                </Grid>
                            }
                        </Grid>
                    </Grid>
            </ModalDialog>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(EvoraBatch));
