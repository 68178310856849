import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import { withTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import RichTextField from '../UI/RichTextField/RichTextField';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Aux from '../../hoc/Auxilary/Auxilary';
import { CopyButton, DeleteButton, SaveButton } from '../UI/Buttons/Buttons';
import Panel from '../UI/Panel/Panel';
import isEmpty from '../../is-empty';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import SelectField from '../UI/SelectField/SelectField';
import Switch from '../UI/Switch/Switch';

class HeadersAndFooters extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            header: '',
            footer: '',
            name: '',
            global: false,
            parameters: [],
            parametersSel: [],
            parameterOptions: [],
            security: [],
            enableSave: true,
            reports: [], 
        }
    }

    async componentDidMount(){
        const values = await axios.get('/api/headervalues');
        this.setState({
            parameterOptions: values.data.parameters,
            security: this.props.permission,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : ''
        }, () => {
            if(this.state._id != null && this.state._id !== '') {
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/headersfooterslist', label: this.props.t('headersAndFooters'), screen: this.props.auth.screenDefs.HeadersAndFooters},
                    { path: 'active', label: this.props.t('headersAndFooters'), screen: this.props.auth.screenDefs.HeadersAndFooter}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });

    }

    loadRecord = async (e) => {
        const record = await axios.get('/api/headersandfooters/' + this.state._id);
        let parametersSel = [];
        if(record.data.parameters != null && record.data.parameters.length > 0){
            record.data.parameters.forEach(row => {
                let val = this.state.parameterOptions.find(x => x.value === row);
                parametersSel.push(val);
            });
        }
        this.setState({
            header: record.data.header,
            footer: record.data.footer,
            name: record.data.name,
            global: record.data.global,
            parameters: record.data.parameters,
            parametersSel: parametersSel,
        }, () => {
            const crumbs = [
                { path: '/#/headersfooterslist', label: this.props.t('headersAndFooters'), screen: this.props.auth.screenDefs.HeadersAndFooters},
                { path: 'active', label: record.data.name, screen: this.props.auth.screenDefs.HeadersAndFooter}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        })
    };

    changeBool = (name) => {
        this.setState({[name]: !this.state[name] });
    }

    changeRichText = type => (index, content) => {
        let state = this.state;
        state[type] = content;
        this.setState(state);
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeMulti = (name, data, optName) => (e) => {
        let sels = this.state[name];
        let ids = this.state[data];
        let options = this.state[optName];
        let val = e.target.value;
        let value = val.pop();
        if(value != null){
            if(value === 'all'){
                if(options.length !== sels.length){
                    sels = [];
                    ids = [];
                    options.forEach(row => {
                        sels.push(row);
                        ids.push(row.value);
                    });
                } else {
                    sels = [];
                    ids = [];
                }
            } else {
                if(val.length === 0){
                    let sel = options.find(x => x.value === value);
                    ids.push(value);
                    sels.push(sel);
                } else {
                    if(!val.some(x => x.value === value)){
                        let sel = options.find(x => x.value === value);
                        sels.push(sel);
                        ids.push(sel.value);
                    } else {
                        let index = sels.findIndex(x => x.value === value);
                        sels.splice(index, 1);
                        ids.splice(index, 1);
                    }
                }
            }
            this.setState({[name]: sels, [data]: ids})
        }
    }

    deleteMulti = (name, data) => (e, value) => {
        let sels = this.state[name];
        let ids = this.state[data];
        let index = sels.findIndex(x => x.value === value.value);
        if(index > -1){
            sels.splice(index, 1);
            ids.splice(index, 1);
        }
        this.setState({[name]: sels, [data]: ids});
    }

    _copy = () => {
        this.setState({enableSave: false}, () => this.copy());
    }

    copy = async (e) => {
        let data = {
            _id: this.state._id
        };
        try {
            let result = await axios.post('/api/copyheadersandfooters', data);
            this.props.history.replace('/headersfooters/' + result.data.id);
            this.setState({ _id: result.data.id, enableSave: true, }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
                this.loadRecord();
            });
        }catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    }

    _save = () => {
        this.setState({enableSave: false}, () => this.save());
    }

    save = async (e) => {
        let errors = {};
        const t = this.props.t;
        if(this.state.name == null || this.state.name === '')
            errors[t('name')] = this.props.t('required');
        if(this.state.parameters == null || this.state.parameters === '')
            errors[t('parameters')] = this.props.t('required');
        let headerErrors = this.validateParameters(this.state.header);
        if(headerErrors.length > 0)
            errors[t('header')] = headerErrors;
        let footerErrors = this.validateParameters(this.state.footer);
        if(footerErrors.length > 0)
            errors[t('footer')] = footerErrors;
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            this.setState({enableSave: true})
            return;
        }
        let data = {
            _id: this.state._id,
            name: this.state.name,
            parameters: this.state.parameters,
            header: this.state.header,
            footer: this.state.footer,
            global: this.state.global
        };
        try {
            let result = await axios.post('/api/headersandfooters', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/headersfooters/' + result.data.id);
            this.setState({ _id: result.data.id, enableSave: true, }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    };

    validateParameters(value){
        let parametersSel = this.state.parametersSel;
        let errors = [];
        let more = true;
        let startPosition = 0;
        while(more){
            let firstIndex = value.indexOf("%%",  startPosition);
            if(firstIndex > -1){
                let secondIndex = value.indexOf("%%", firstIndex + 2);
                if(secondIndex > -1){
                    let parm = value.substring(firstIndex + 2, secondIndex);
                    let foundName = parametersSel.find(x => x.label.toUpperCase().replace(/\s+/g,'') === parm);
                    if(foundName == null){
                        errors.push(parm + ' ' + this.props.t('notValid'));
                    }
                    startPosition = secondIndex + 2;
                } else {
                    more = false;
                }
            } else {
                more = false;
            }
        }

        return errors;
    }

    _delete = async () => {
        this.setState({enableSave: false}, () => this.delete());
    }

    delete = async () => {
        try {
            let result = await axios.delete('/api/deleteheaderfooters/' + this.state._id);
            this.setState({ _id: '', enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}})
                this.props.history.push('/headersfooterslist');
            });
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    render(){
        const { global, security } = this.state;
        const { errors, t } = this.props;
        const fieldAccess = global === true && this.props.auth.user.internal === true ? true : 
                            global === true && this.props.auth.user.internal === false ? false : 
                            global === false && this.state.security.update === true ? true : false;
        return(
            <Aux>
                <BreadcrumbBar />
                <Panel>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={4} sm={3} md={2}>
                            <TextField value={this.state.name} onChange={this.changeValue} name="name"
                            size="medium" fullWidth disabled={!fieldAccess} label={t('name')} data-cy='name'                            
                            error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={global} onChange={() => this.changeBool('global')}
                                        name="global" disabled={!fieldAccess} data-cy='global'
                                    />
                                }
                                label={t('global')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectField
                                disabled={!fieldAccess}
                                selectAll
                                value={this.state.parametersSel}
                                options={this.state.parameterOptions}
                                label={t('parameters')}
                                selectAllLabel={t('selectAll')}
                                error={errors[t('parameters')] != null ? true : false}
                                helperText={errors[t('parameters')]}
                                onChange={this.changeMulti('parametersSel', 'parameters', 'parameterOptions')}
                                onDelete={this.deleteMulti('parametersSel', 'parameters')}
                                data-cy='parameters'
                            />
                        </Grid>
                        <Grid item xs={11}>
                            <SubSectionHeading title={t('header')} /><br />
                            <RichTextField
                                disabled={!fieldAccess}
                                id={'headerField'}
                                key={'headerField'}
                                index={1}
                                value={this.state.header != null ? this.state.header : ''}
                                errors={errors[t('header')]}
                                textAreaName={'headerTemplateRTF'}
                                height={200}
                                changeRichText={this.changeRichText('header')}
                                data-cy='header'
                            />
                        </Grid>
                        <Grid item xs={11}>
                            <SubSectionHeading title={t('footer')} /><br />
                            <RichTextField
                                disabled={!fieldAccess}
                                id={'footerField'}
                                key={'footerField'}
                                index={1}
                                value={this.state.footer != null ? this.state.footer : ''}
                                errors={errors[t('footer')]}
                                textAreaName={'footerTemplateRTF'}
                                height={200}
                                changeRichText={this.changeRichText('footer')}
                                data-cy='footer'
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            {this.state.global === true && this.props.auth.user.internal === true ?
                                <SaveButton saveClick={() => this._save()} enabled={this.state.enableSave} data-cy='save'/> :
                             this.state.global === true && this.props.auth.user.internal === false ? null :
                             this.state.global === false && this.state._id != null && this.state._id !== '' && security.update === true ?
                                <SaveButton saveClick={() => this._save()} enabled={this.state.enableSave} data-cy='save'/> : null
                            }
                            <CopyButton copyClick={() => this._copy()} enabled={this.state.enableSave} data-cy='copy' color='secondary' />
                            {this.state.global === false && this.state._id != null && this.state._id !== '' &&
                                <DeleteButton deleteClick={() => this._delete()} enabled={this.state.enableSave} data-cy='delete' />
                            }
                        </Grid>
                    </Grid>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(HeadersAndFooters));
