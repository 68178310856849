import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import DateTimePicker from '../UI/DateTimeSelect/DateTimeSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { approveSigSecurity, completeSigSecurity,
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import ActivityWorkRecord from '../Cultivation/ActivityWorkRecord';
import { EvoraTabs, TabCanvas, TabPanel } from '../UI/Tabs/Tabs';
import { Link } from 'react-router-dom';
import ActionBar from '../UI/Buttons/ActionBar';
import SectionTitle from '../UI/Typography/SectionTitle';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import IconButton from '@mui/material/IconButton';
import { NumericFormat } from 'react-number-format';
import Box from '@mui/material/Box';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import ItemLedgerComponent  from '../Inventory/ItemLedgerComponent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import PrintView from '../Printing/PrintView';
import ConversionHelper from '../../helpers/conversionHelper';

class RoomCleaning extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            date: new Date(),
            status: '',
            statusSel: {
                value: '',
                label: '',
            },
            statuses: [],
            location: '',
            locationSel: null,
            locations: [],
            room: '',
            roomSel: null,
            rooms: [],
            type: '',
            typeSel: null,
            types: [],
            userOptions: [],
            users: [],
            usersSel: [],
            uoms: [],
            items: [],
            effort: '',
            sops: [],
            cleaners: [],
            comments: '',
            user: null,
            reviewer: null,
            signer: null,
            documents: null,
            managers: [],
            qas: [],
            schecule: null,
            security: [],
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            renderNum: Math.random(),
            sopList: [],
            sopIds: [],
            sopsSel: [],
            tabKey: 0,
            tabLabels: [{name: this.props.t('roomCleaning'), disabled: false}],
            item: '',
            itemSel: null,
            lot: '',
            lotSel: null,
            lots: [],
            container: '',
            containerSel: null,
            containers: [],
            amount: '',
            uom: '',
            uomSel: null,
            editContainer: false,
            index: -1,
            outputs: [],
            outputItem: '',
            outputItemSel: null,
            outputAmount: '',
            outputUOM: '',
            outputUOMSel: null,
            wasteItems: [],
            bins: [],
            bin: '',
            binSel: null,
            showOutput: false,
            outputIndex: -1,
            optionsMenu: [],
            flagForSignature: 0,
            dialogOpen: false,
            enableSave: true,
            sigData: null,
            activeUsers: [],
            activeManagers: [],
            activeQas: [],
            form: '',
            hasForm: false,
            equipments: [],
            equipmentsSel: [],
            equipmentList: [],
            totalCost: '',
            labourCost: '',
            inputCost: '',
            batchCosts: [],
            goBack: -1,
            deleteOpen: false,
            productionBatch: '',
            productionBatchLine: '',
            printViewer: false,
            finalDocument: null,
            cultivationBatch: null,
            cultivationActivity: null,
            hasLedgers: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.user, common.cacheValues.location, common.cacheValues.status, common.cacheValues.quantityUOM,
                                common.cacheValues.defaultRoom, common.cacheValues.bin, common.cacheValues.roomCleaningType, common.cacheValues.roomCleaningForm,
                                common.cacheValues.roomCleaningSop, common.cacheValues.wasteItems, common.cacheValues.cleaningSolutionItem, common.cacheValues.ppeItem],
                                this.props.auth.constants, null, this.props.auth.user.tenant);
        let items = cacheValues.cleaningSolutionItems;
        for(let row of cacheValues.ppeItems){
            items.push(row);
        }
        let allUsers = cacheValues.users;
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.roomCleaning, this.props.auth.user.tenant);
        let params = queryString.parse(this.props.location.search);
        let schedule = null;
        let locationSel = null;
        let location = '';
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        const room = params != null && params.room != null ? params.room : '';
        let roomSel = room !== '' ? cacheValues.rooms.find(x => x.value === room) : null;
        let equipmentList = [];
        if(roomSel != null){
            let equipResult = await axios.get('/api/equipmentselect', {params: {room: room}});
            equipmentList = equipResult.data;
        }
        const productionBatch = params != null && params.productionBatch != null ? params.productionBatch : null;
        const productionBatchLine = params != null && params.lineId != null ? params.lineId : null;
        const cultivationBatch = params != null && params.cultivationBatch != null ? params.cultivationBatch : null;
        const cultivationActivity = params != null && params.cultivationActivity != null ? params.cultivationActivity : null;
        let goBack = -1;
        if(scheduleId != null){
            goBack = -2;
            schedule = await axios.get('api/schedulebase/' + scheduleId);
            locationSel = locations.find(x => x.value === schedule.data.location);
            location = schedule.data.location;
        } else {
            if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
                locationSel = locations.find(x => x.value === this.props.auth.user.defaultLocation);
                location = this.props.auth.user.defaultLocation;
            } else {
                if(locations.length === 1){
                    locationSel = locations[0];
                    location = locationSel.value;
                }
            }
        }
        let workRecords = [];
        let sopIds = [];
        let sopsSel = [];
        cacheValues.sops.forEach(sop => {
            if(sop.required === true){
                workRecords.push({
                    sop: sop.value,
                    original: true
                });
                sopIds.push(sop.value);
                sopsSel.push(sop);
            }
        });
        let statusSel = cacheValues.statuses.find(x => x.value ===this.props.auth.constants.statuses.new);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let activeQas = [];
        userValues.approve.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeQas.push(row);
            }
        })
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        this.setState({
            types: cacheValues.roomCleaningTypes,
            userOptions: userValues.create,
            uoms: cacheValues.quantityUOMs,
            managers: userValues.review,
            qas: userValues.approve,
            locations: locations,
            statuses: cacheValues.statuses,
            items: items,
            schedule: scheduleId,
            scheduleBase: schedule != null ? schedule.data : null,
            locationSel: locationSel,
            location: location,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            sops: workRecords,
            sopList: cacheValues.sops,
            sopIds: sopIds,
            sopsSel: sopsSel,
            wasteItems: cacheValues.wasteItems,
            bins: cacheValues.bins,
            activeUsers: activeUsers,
            activeManagers: activeManagers,
            activeQas: activeQas,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            allUsers: allUsers,
            rooms: cacheValues.rooms,
            goBack: goBack,
            room: room,
            roomSel: roomSel,
            productionBatch: productionBatch,
            productionBatchLine: productionBatchLine,
            equipmentList: equipmentList,
            cultivationBatch: cultivationBatch,
            cultivationActivity: cultivationActivity
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/roomcleanings', label: this.props.t('roomCleanings'), screen: this.props.auth.screenDefs.RoomCleaning},
                    { path: 'active', label: this.props.t('roomCleaning'), screen: this.props.auth.screenDefs.RoomCleaning}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        const record = await axios.get('/api/roomcleaning/' + this.state._id);
        const locationSel = this.state.locations.find(x => x.value === record.data.location);
        const statusSel = this.state.statuses.find(x => x.value === record.data.status);
        const roomSel = record.data.rooms.find(x => x.value === record.data.room);
        const typeSel = this.state.types.find(x => x.value === record.data.type);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let sopIds = [];
        let sopsSel = [];
        let tabLabels = [{name: t('roomCleaning'), disabled: false}];
        if(record.data.sops != null && record.data.sops.length > 0){
            record.data.sops.forEach(sop => {
                sopIds.push(sop.sop);
                let val = this.state.sopList.find(x => x.value === sop.sop);
                sopsSel.push(val);
                tabLabels.push({name: val.label, disabled: false});
            });
        }
        if(record.data.hasLedgers){
            tabLabels.push({name: t('itemLedgers'), disabled: false});
        }
        let usersSel = [];
        let userOptions = this.state.userOptions;
        if(record.data.users != null && record.data.users.length > 0){
            record.data.users.forEach(row => {
                let val = this.state.userOptions.find(x => x.value === row);
                if(val != null){
                    usersSel.push(val);
                } else {
                    val = this.state.allUsers.find(x => x.value === row);
                    if(val != null){
                        usersSel.push(val);
                        userOptions.push(val);
                    }
                }
            });
        }
        let cleaners = record.data.cleaners;
        if(cleaners != null && cleaners.length > 0){
            cleaners.forEach(row => {
                if(row.container != null){
                    let containerSel = row.containers.find(x => x.value === row.container);
                    row.containerSel = containerSel;
                }
                let uomSel = this.state.uoms.find(x => x.value === row.uom);
                row.uomSel = uomSel;
                let itemSel = this.state.items.find(x => x.value === row.item);
                row.itemSel = itemSel;
            });
        }
        let outputs = record.data.outputs;
        if(outputs != null && outputs.length > 0){
            outputs.forEach(row => {
                let itemSel = this.state.wasteItems.find(x => x.value === row.item);
                row.itemSel = itemSel;
                let uomSel = this.state.uoms.find(x => x.value === row.uom);
                row.uomSel = uomSel;
                let binSel = this.state.bins.find(x => x.value === row.bin);
                row.binSel = binSel;
            });
        }
        let equipmentsSel = [];
        if(record.data.equipments != null && record.data.equipments.length > 0){
            for(let row of record.data.equipments){
                let sel = record.data.equipmentList.find(x => x.value === row);
                if(sel != null){
                    equipmentsSel.push(sel);
                }
            }
        }
        let sigDeleteCallback = (record.data.status === this.props.auth.constants.statuses.new || record.data.status === this.props.auth.constants.statuses.inprogress) && 
                        record.data.user != null ? this.deleteSigCallback : null;
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: usersSel, name: t('complete'), object: record.data._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user,
                objectType: this.props.auth.constants.objectIds.roomCleaning, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, saveCallback: this.saveCallback, deleteCallback: sigDeleteCallback
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: !internal && this.state.reviewer == null ? this.state.activeManagers : this.state.managers,
                name: t('review'), title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.roomCleaning, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: !internal && this.state.signer == null ? this.state.activeQas : this.state.qas,
                name: t('approve'), title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer,
                objectType: this.props.auth.constants.objectIds.roomCleaning, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
         let hasForm = this.state.hasForm;
         if(!hasForm && record.data.form != null && record.data.form !== '')
             hasForm = true;
	let params = queryString.parse(this.props.location.search);
        this.setState({
            number: record.data.number,
            date: record.data.date,
            location: record.data.location,
            locationSel: locationSel,
            room: record.data.room,
            roomSel: roomSel,
            rooms: record.data.rooms,
            status: record.data.status,
            statusSel: statusSel,
            type: record.data.type,
            typeSel: typeSel,
            effort: record.data.effort,
            cleaners: cleaners,
            comments: record.data.comments,
            users: record.data.users,
            usersSel: usersSel,
            sopIds: sopIds,
            sopsSel: sopsSel,
            sops: record.data.sops,
            documents: record.data.documents,
            user: record.data.user,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            tabLabels: tabLabels,
            fieldAccess: fieldAccess,
            outputs: outputs,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            renderNum: Math.random(),
            sigData: null,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            equipments: record.data.equipments,
            equipmentList: record.data.equipmentList,
            equipmentsSel: equipmentsSel,
            userOptions: userOptions,
            totalCost: record.data.totalCost,
            inputCost: record.data.inputCost,
            labourCost: record.data.labourCost,
            batchCosts: record.data.batchCosts,
            deleteOpen: false,
            productionBatch: record.data.productionBatch,
            productionBatchLine: record.data.productionBatchLine,
            cultivationBatch: record.data.cultivationBatch,
            cultivationActivity: record.data.cultivationActivity,
            finalDocument: record.data.finalDocument,
            hasLedgers: record.data.hasLedgers
        }, () => {
            const crumbs = [
                { path: '/#/roomcleanings', label: this.props.t('roomCleanings'), screen: this.props.auth.screenDefs.RoomCleaning},
                { path: 'active', label: this.props.t('roomCleaning') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.RoomCleaning}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            if(params.tab != null)
            this.openNotification(params);
        });
    }

    openNotification = (params) => {
        let tabKey = this.state.tabKey;
        if(params != null && params.tab != null)
            tabKey = Number(params.tab);
        this.setState({tabKey});
    }

    changeMulti = (name, data) => (e, newValue) => {
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            [name]: newValue,
            [data]: ids
        })
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
        });
        if(newValue != null){
            if(data === 'item'){
                let containers = [];
                if(newValue.lotTracked === true){
                    let contResult = await axios.post('/api/itemcontainers', {items: [newValue.value], date: this.state.date, available: true, consume: true});
                    containers = contResult.data;
                }
                let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                this.setState({containers: containers, uom: newValue.uom, uomSel: uomSel});
            } else if(data === 'outputItem'){
                let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                this.setState({outputUOM: newValue.uom, outputUOMSel: uomSel});
            } else if(data === 'room'){
                let equipResult = await axios.get('/api/equipmentselect', {params: {room: newValue.value}});
                this.setState({equipmentList: equipResult.data});
            } else if (data === 'location'){
                let roomResult = await axios.get('/api/roomselect', {params: {location: newValue.value}});
                this.setState({rooms: roomResult.data});
            }
        } else {
            if(data === 'location'){
                this.setState({
                    rooms: [],
                    room: '',
                    roomSel: null
                })
            }
        }

    }

    changeValue = e => {
        const name = e.target.name;
        const value = e.target.value
        this.setState({[name]: value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    dateChange = e => {
        this.setState({date: e});
    };

    valueChanged = (e) => {
        this.setState({value: e});
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.save()})
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
                this.setState({enableSave: true});
                return;
            }
        }
        let data = {
            _id: this.state._id,
            date: this.state.date,
            status: this.state.status,
            location: this.state.location,
            room: this.state.room,
            type: this.state.type,
            users: this.state.users,
            effort: this.state.effort,
            sops: this.state.sops,
            cleaners: this.state.cleaners,
            comments: this.state.comments,
            outputs: this.state.outputs,
            sigData: this.state.sigData,
            equipments: this.state.equipments,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            productionBatch: this.state.productionBatch,
            productionBatchLine: this.state.productionBatchLine,
            cultivationBatch: this.state.cultivationBatch,
            cultivationActivity: this.state.cultivationActivity
        };
        try {
            let result = await axios.post('/api/roomcleaning', data);
            const statusSel = this.state.statuses.find(x => x.value === result.data.status);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/roomcleaning/' + result.data.id);
            this.setState({
                _id: result.data.id,
                number: result.data.number,
                status: result.data.status,
                statusSel: statusSel,
                enableSave: true
            }, () => {
                this.loadRecord();
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.date == null || state.date === ''){
            errors[t('date')] = t('required');
        } else {
            let validEndDate = ConversionHelper.validActivityDate(this.state.date, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
            if(!validEndDate){
                errors[t('date')] = t('dateBeforeLock');
            }
        }
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.room == null || state.room === '')
            errors[t('room')] = t('required');
        if(state.users == null || state.users.length === 0)
            errors[t('users')] = t('required');
        if(state.effort == null || state.effort === '' || state.effort === 0)
            errors[t('effort')] = t('required');
        if((state.container != null && state.container !== '') || (state.item != null && state.item !== '') ||
        (state.amount != null && state.amount !== ''))
            errors[t('item')] = t('rowNotAdded');
        for(let i = 0; i < state.cleaners.length; i++){
            let row = state.cleaners[i];
            if(row.item == null || row.item === '')
                errors[`${t('item')} (${t('line')} {i})`] = t('required');
            if(row.amount == null || row.amount === '')
                errors[`${t('amount')} (${t('line')} {i})`] = t('required');
            if(row.itemSel != null && row.itemSel.lotTracked === true){
                if(row.container == null || row.container === '')
                    errors[`${t('container')} (${t('line')} {i})`] = t('required');
                if(row.amount != null && row.amount !== '' && row.containerSel != null){
                    if(row.reservation == null && parseFloat(row.amount) > parseFloat(row.containerSel.quantity)){
                        errors[`${t('amount')} (${t('line')} {i})`] = t('moreThanContainer');
                    } else if(row.reservation != null && parseFloat(row.amount) > parseFloat(row.containerSel.quantity) + parseFloat(row.reservedAmount)){
                        errors[`${t('amount')} (${t('line')} {i})`] = t('moreThanContainer');
                    }
                }
            }
        }
        if((state.outputItem != null && state.outputItem !== '') || (state.outputAmount != null && state.outputAmount !== '') ||
        (state.bin != null && state.bin !== ''))
            errors[t('outputItem')] = t('rowNotAdded');
        for(let i = 0; i < state.outputs.length; i++){
            let row = state.outputs[i];
            if(row.item == null || row.item === '')
                errors[`${t('item')} (${t('line')} {i})`] = t('required');
            if(row.amount == null || row.amount === '')
                errors[`${t('amount')} (${t('line')} {i})`] = t('required');
            if( row.bin == null || row.bin === '')
                errors[`${t('bin')} (${t('line')} {i})`] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    validateComplete = () => {
        let valid = this.validate();
        const t = this.props.t;
        if(!valid)
            return t('notAllComplete');
        if(this.state.sops != null && this.state.sops.length > 0){
            for(let i = 0; i < this.state.sops.length; i++){
                let sop = this.state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        return null;
    };

    changeSop = (e, newValue) => {
        let sops = this.state.sops;
        let tabLabels = this.state.tabLabels;
        if(newValue != null){
            if(sops.length > newValue.length){
                for(let i = 0; i < sops.length; i++){
                    let sop = sops[i];
                    let rec = newValue.find(x => x.value === sop.sop);
                    if(!rec){
                        sops.splice(i, 1);
                        tabLabels.splice(i + 1, 1);
                        break;
                    }
                }
            } else {
                for(let i = 0; i < newValue.length; i++){
                    let val = newValue[i];
                    let rec = sops.find(x => x.sop === val.value)
                    if(!rec){
                        sops.push({
                            sop: val.value,
                            workRecord: null,
                            original: false
                        });
                        if(this.state._id != null && this.state._id !== '')
                            tabLabels.push({name: val.label, disabled: false});
                    }
                }
            }
            let sopIds = [];
            newValue.forEach(val => {
                sopIds.push(val.value);
            });
            this.setState({
                sopIds: sopIds,
                sopsSel: newValue,
                sops: sops
            });
        } else {
            for(let i = 0; i < sops.length; i++){
                let sop = sops[i];
                if(sop.required)
                    return;
            }
            this.setState({sops: [], sopIds: [], sopsSel: [], tabLabels: [{name: this.props.t('roomCleaning'), disabled: false}]});
        }
    };

    editCleanerRow(i){
        let cleaner = this.state.cleaners[i];
        this.setState({
            editCleaner: true,
            index: i,
            item: cleaner.item,
            itemSel: cleaner.itemSel,
            container: cleaner.container,
            containerSel: cleaner.containerSel,
            amount: cleaner.amount,
            uom: cleaner.uom,
            uomSel: cleaner.uomSel,
            containers: cleaner.containers
        })
    }

    editOutput(i){
        let output = this.state.outputs[i];
        this.setState({
            editOutput: true,
            outputIndex: i,
            outputItem: output.item,
            outputItemSel: output.itemSel,
            outputAmount: output.amount,
            outputUOM: output.uom,
            outputUOMSel: output.uomSel,
            bin: output.bin,
            binSel: output.binSel
        });
    }

    toggleCleaner(){
        this.setState({editCleaner: !this.state.editCleaner});
    }

    addOutput = (e) => {
        this.setState({editOutput: true});
    }

    saveCleaner = (e) => {
        let valid = this.validateCleaner();
        if(!valid)
            return;
        let cleaners = this.state.cleaners;
        let cleaner = this.state.index > -1 ? cleaners[this.state.index] : {};
        cleaner.item = this.state.item;
        cleaner.itemSel = this.state.itemSel;
        cleaner.container = this.state.container;
        cleaner.containerSel = this.state.containerSel;
        cleaner.amount = this.state.amount;
        cleaner.uom = this.state.uom;
        cleaner.uomSel = this.state.uomSel;
        cleaner.containers = this.state.containers;
        if(this.state.index === -1){
            cleaners.push(cleaner);
        }
        this.setState({cleaners: cleaners});
        this.clearCleaner();
    };

    saveOutput = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.outputItem == null || this.state.outputItem === '')
            errors[t('outputItem')] = t('required');
        if(this.state.outputAmount == null || this.state.outputAmount === '' || this.state.outputAmount === 0)
            errors[t('outputAmount')] = t('required');
        if(this.state.bin == null || this.state.bin === '')
            errors[t('bin')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return;
        }
        let outputs = this.state.outputs;
        let output = this.state.outputIndex > -1 ? outputs[this.state.outputIndex] : {};
        output.item = this.state.outputItem;
        output.itemSel = this.state.outputItemSel;
        output.amount = this.state.outputAmount;
        output.uom = this.state.outputUOM;
        output.uomSel = this.state.outputUOMSel;
        output.bin = this.state.bin;
        output.binSel = this.state.binSel;
        if(this.state.outputIndex === -1)
            outputs.push(output);
        this.setState({outputs: outputs})
        this.clearOutput();
    };

    validateCleaner(){
        const t = this.props.t;
        let errors = {};
        if(this.state.item == null || this.state.item === '')
            errors[t('item')] = t('required');
        if(this.state.itemSel.lotTracked === true){
            if(this.state.container == null || this.state.container === '')
                errors[t('container')] = t('required');
        }
        if(this.state.amount == null || this.state.amount === '')
            errors[t('amount')] = t('required');
        else if(this.state.container != null && this.state.container !== ''){
            if(parseFloat(this.state.amount) > parseFloat(this.state.containerSel.quantity))
                errors[t('amount')] = t('moreThanContainer');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteCleaner = (e) => {
        if(this.state.index > -1){
            let cleaners = this.state.cleaners;
            cleaners.splice(this.state.index, 1);
            this.setState({cleaners: cleaners});
        }
        this.clearCleaner();
    };

    clearCleaner = (e) => {
        this.setState({
            item: '',
            itemSel: null,
            index: -1,
            amount: '',
            uomSel: null,
            uom: '',
            container: '',
            containerSel: null,
            editCleaner: false
        });
    };

    clearOutput = (e) => {
        this.setState({
            outputIndex: -1,
            outputItem: '',
            outputItemSel: null,
            outputAmount: '',
            outputUOM: '',
            outputUOMSel: null,
            bin: '',
            binSel: null,
            editOutput: false
        });
    };

    changeLineValue = (name, index) => (e) => {
        let cleaners = this.state.cleaners;
        let row = cleaners[index];
        row[name] = e.target.value;
        this.setState({cleaners: cleaners});
    };

    changeLineAuto = (name, data, index) => async (e, newValue) => {
        let cleaners = this.state.cleaners;
        let row = cleaners[index];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : null;
        if(data === 'item' || data === 'lot' || data === 'container'){
            row.reservation = null;
        }
        this.setState({cleaners: cleaners});
        if(data === 'item'){
            row.container = '';
            row.containerSel = null;
            row.uom = '';
            row.uomSel = null;
            if(newValue != null){
                let contResult = await axios.post('/api/itemcontainers', {items: [newValue.value], date: this.state.date, available: true, consume: true});
                let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                row.uomSel = uomSel;
                row.uom = newValue.uom;
                row.containers = contResult.data;
            }
            this.setState({cleaners: cleaners});
        }
    };

    deleteInput = (index) => (e) => {
        let inputs = this.state.cleaners;
        inputs.splice(index, 1);
        this.setState({cleaners: inputs});
    };

    changeOutputValue = (name, index) => (e) => {
        let cleaners = this.state.cleaners;
        let row = cleaners[index];
        row[name] = e.target.value;
        this.setState({cleaners: cleaners});
    };

    changeOutputAuto = (name, data, index) => async (e, newValue) => {
        let outputs = this.state.outputs;
        let row = outputs[index];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : null;
        if(data === 'item'){
            row.uom = '';
            row.uomSel = null;
            if(newValue != null){
                let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                row.uomSel = uomSel;
                row.uom = newValue.uom;
            }
        }
        this.setState({outputs: outputs});
    };

    deleteOutput = (index) => (e) => {
        let outputs = this.state.outputs;
        outputs.splice(index, 1);
        this.setState({outputs: outputs});
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    delete = () => {
        if(this.state._id == null || this.state._id === ''){
            this.props.history.goBack();
            return;
        }
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._delete());
    }

    async _delete(){
        const t = this.props.t;
        if(this.state.user != null || this.state.reviewer != null || this.state.signer != null){
            this.setState({enableSave: true, deleteOpen: false});
            return;
        }
        try {
            let result = await axios.delete('/api/roomcleaning/' + this.state._id);
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            if(this.state.goBack === -1)
                this.props.history.goBack();
            else
                this.props.history.go(this.state.goBack);
        } catch(err){
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    togglePrintViewer = () => {
        this.setState({printViewer: !this.state.printViewer});
    }

    deleteSigCallback = async (type) => {
        try{
            let result = await axios.post('/api/roomcleaningsigdelete', {type: type, id: this.state._id});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    render(){
        const fieldAccess = this.state.fieldAccess;
        const errors = this.props.errors;
        const security = this.state.security;
        const t = this.props.t;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        const internal = this.props.auth.user.internal;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        printViewClick={this.togglePrintViewer}
                        printViewTitle={t('roomCleaning')}
                        enablePrintView={this.state.status === this.props.auth.constants.statuses.signed}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.roomCleaning}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TabPanel value={this.state.tabKey} index={0}>
                            <Grid container spacing={3}>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={6} sm={3}>
                                        <FormControl data-cy="number">
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={6} sm={4}>
                                    <FormControl data-cy="status">
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <DateTimePicker
                                            onChange={this.dateChange}
                                            value={this.state.date}
                                            helperText={errors[t('date')]}
                                            error={errors[t('date')] != null ? true : false}
                                            disabled={!fieldAccess}
                                            label={t('date')}
                                            data-cy="date"
                                            format={this.props.auth.user.dateFormat}
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('date')}</FormLabel>
                                            <DateDisplay value={this.state.date} format="yyyy/MM/dd h:mm a"/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.locationSel}
                                            options={this.state.locations}
                                            onChange={this.changeAuto('locationSel', 'location')}
                                            error={errors[t('location')] != null ? true : false}
                                            helperText={errors[t('location')]}
                                            label={t('location')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="location"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.roomSel}
                                            options={this.state.rooms}
                                            onChange={this.changeAuto('roomSel', 'room')}
                                            error={errors[t('room')] != null ? true : false}
                                            helperText={errors[t('room')]}
                                            label={t('room')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="room"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('room')}</FormLabel>
                                            {this.state.roomSel != null ? this.state.roomSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.typeSel}
                                            options={this.state.types}
                                            onChange={this.changeAuto('typeSel', 'type')}
                                            error={errors[t('type')] != null ? true : false}
                                            helperText={errors[t('type')]}
                                            label={t('type')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="type"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('type')}</FormLabel>
                                            {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField type="number" value={this.state.effort} onChange={this.changeNumber} name="effort"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('effort')} required data-cy="effort"
                                        error={errors[t('effort')] != null ? true : false} helperText={errors[t('effort')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('effort')}</FormLabel>
                                            {this.state.effort}
                                        </FormControl>
                                    }
                                </Grid>
                                {this.state.equipmentList != null && this.state.equipmentList.length > 0 ?
                                    <Grid item xs={11}>
                                        <AutoCompleteField
                                            multiple={true}
                                            value={this.state.equipmentsSel}
                                            options={this.state.equipmentList}
                                            onChange={this.changeMulti('equipmentsSel', 'equipments')}
                                            error={errors[t('equipments')] != null ? true : false}
                                            helperText={errors[t('equipments')]}
                                            label={t('equipment')}
                                            disabled={!fieldAccess}
                                            data-cy="equipment"
                                        />
                                    </Grid> : null
                                }
                                <Grid item xs={11}>
                                    <AutoCompleteField
                                        multiple={true}
                                        value={this.state.usersSel}
                                        options={fieldAccess && !internal ? this.state.activeUsers : this.state.userOptions}
                                        onChange={this.changeMulti('usersSel', 'users')}
                                        error={errors[t('users')] != null ? true : false}
                                        helperText={errors[t('users')]}
                                        label={t('users')}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy="users"
                                    />
                                </Grid>
                                {this.state.sopList != null && this.state.sopList.length > 0 &&
                                    <Grid item sm={11}>
                                        <AutoCompleteField
                                            multiple={true} disabled={!fieldAccess}
                                            value={this.state.sopsSel}
                                            options={this.state.sopList}
                                            onChange={this.changeSop}
                                            error={errors[t('sopIds')] != null ? true : false}
                                            helperText={errors[t('sopIds')]}
                                            label={t('workRecs')}
                                            data-cy="sops"
                                        />
                                    </Grid>
                                }
                            </Grid>
                            {this.state.hasForm === true &&
                                <Aux>
                                    <br/>
                                    {errors[t('form')] != null && errors[t('form')] !== '' &&
                                        <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                    }
                                    <Form renderer={renderer}
                                        defaultFields={this.state.form}
                                        onChange={this.valueChanged}
                                        disabled={!fieldAccess}/>
                                </Aux>
                            }
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {fieldAccess &&
                                        <TextField value={this.state.comments} onChange={this.changeValue} name="comments" multiline={true} rows="3"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('comments')} data-cy="comments"
                                        error={errors[t('comments')] != null ? true : false} helperText={errors[t('comments')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('comments')}</FormLabel>
                                            {this.state.comments}
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            {this.state.status !== this.props.auth.constants.statuses.new && this.state.status !== this.props.auth.constants.statuses.inprogress &&
                            this.state.totalCost != null && this.state.totalCost !== '' &&
                                <Aux>
                                    <SectionTitle title={t('costs')}/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('total')}</FormLabel>
                                                <NumericFormat displayType={'text'} value={this.state.totalCost} thousandSeparator={true} decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('inputs')}</FormLabel>
                                                <NumericFormat displayType={'text'} value={this.state.inputCost} thousandSeparator={true} decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('labour')}</FormLabel>
                                                <NumericFormat displayType={'text'} value={this.state.labourCost} thousandSeparator={true} decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {this.state.batchCosts != null && this.state.batchCosts.length > 0 &&
                                        <Aux>
                                            <TableContainer component={Paper}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow key="costHead">
                                                            <TableCell>{t('batch')}</TableCell>
                                                            <TableCell>{t('plants')}</TableCell>
                                                            <TableCell>{t('cost')}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.batchCosts.map((row, i) => 
                                                            <TableRow key={row.batch._id}>
                                                                <TableCell>
                                                                    <Link to={'/evorabatch/' + row.batch._id}>{row.batch.number}</Link>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <NumericFormat displayType={'text'} value={row.plantCount} thousandSeparator={true} decimalScale={0}/>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <NumericFormat displayType={'text'} value={row.cost} thousandSeparator={true} decimalScale={2}/>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Aux>
                                    }
                                </Aux>
                            }
                            <br/>
                            <SectionTitle title={t('inputs')}/>
                            {this.state.cleaners.map((row, i) =>
                                <Box borderColor="primary.main" {...defaultProps}  key={'item' + i} component={'div'}>
                                    <Grid container spacing={3} key={'item' + i}>
                                        <Grid item sx={{width: 350}}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={row.itemSel}
                                                    options={this.state.items}
                                                    onChange={this.changeLineAuto('itemSel', 'item', i)}
                                                    error={errors[`${t('item')} (${t('line')} {i})`] != null ? true : false}
                                                    helperText={errors[`${t('item')} (${t('line')} {i})`]}
                                                    disabled={!fieldAccess}
                                                    label={t('item')}
                                                    data-cy={"item" + i}
                                                />
                                            }
                                            {!fieldAccess && row.itemSel != null &&
                                                <FormControl>
                                                    <FormLabel>{t('item')}</FormLabel>
                                                    <Link to={'/item/' + row.item}>{row.itemSel.label}</Link>
                                                </FormControl>
                                            }
                                        </Grid>
                                        {row.itemSel != null && row.itemSel.lotTracked === true &&
                                            <Grid item sx={{width: 250}}>
                                                {fieldAccess &&
                                                    <AutoCompleteField
                                                        value={row.containerSel}
                                                        options={row.containers}
                                                        onChange={this.changeLineAuto('containerSel', 'container', i)}
                                                        error={errors[`${t('container')} (${t('line')} {i})`] != null ? true : false}
                                                        helperText={errors[`${t('container')} (${t('line')} {i})`]}
                                                        disabled={!fieldAccess}
                                                        label={t('container')}
                                                        data-cy={"container" + i}
                                                        groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                        noneMessage={t('noLotAssigned')}
                                                    />
                                                }
                                                {!fieldAccess && row.containerSel != null &&
                                                    <FormControl>
                                                        <FormLabel>{t('container')}</FormLabel>
                                                        <Link to={'/container/' + row.container}>{row.containerSel.label}</Link>
                                                    </FormControl>
                                                }
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 100}}>
                                            {fieldAccess &&
                                                <TextField type="number" value={row.amount} onChange={this.changeLineValue('amount', i)} name="amount"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} data-cy={"quantity" + i}
                                                error={errors[`${t('amount')} (${t('line')} {i})`] != null ? true : false} helperText={errors[`${t('amount')} (${t('line')} {i})`]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('quantity')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={row.amount} thousandSeparator={true} decimalScale={3}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <FormControl>
                                                <FormLabel></FormLabel>
                                                {row.uomSel != null ? row.uomSel.label : ''}
                                            </FormControl>
                                        </Grid>
                                        {fieldAccess &&
                                            <Grid item sx={{width: 50}}>
                                                <IconButton aria-label="delete item" disabled={!fieldAccess} onClick={this.deleteInput(i)} data-cy={"deleteInput" + i}>
                                                    <RemoveCircleTwoToneIcon  />
                                                </IconButton>
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            )}
                            {errors[t('cleaners')] && <div><div className="error text-danger">{errors[t('cleaners')]}</div><br/></div>}
                            {fieldAccess &&
                                <Box borderColor="primary.main" {...defaultProps} component={'div'}>
                                    <Grid container spacing={3}>
                                        <Grid item sx={{width: 350}}>
                                            <AutoCompleteField
                                                value={this.state.itemSel}
                                                options={this.state.items}
                                                onChange={this.changeAuto('itemSel', 'item')}
                                                error={errors[t('item')] != null ? true : false}
                                                helperText={errors[t('item')]} 
                                                label={t('item')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy="item"
                                            />
                                        </Grid>
                                        {this.state.itemSel != null && this.state.itemSel.lotTracked === true &&
                                            <Grid item sx={{width: 250}}>
                                                <AutoCompleteField
                                                    value={this.state.containerSel}
                                                    options={this.state.containers}
                                                    onChange={this.changeAuto('containerSel', 'container')}
                                                    error={errors[t('container')] != null ? true : false}
                                                    helperText={errors[t('container')]}
                                                    label={t('container')}
                                                    disabled={!fieldAccess}
                                                    required
                                                    data-cy="container"
                                                    groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                    noneMessage={t('noLotAssigned')}
                                                />
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 100}}>
                                            <TextField type="number" value={this.state.amount} onChange={this.changeNumber} name="amount"
                                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} required data-cy="amount"
                                            error={errors[t('amount')] != null ? true : false} helperText={errors[t('amount')]}/>
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <FormControl>
                                                <FormLabel>{t('uom')}</FormLabel>
                                                {this.state.uomSel != null ? this.state.uomSel.label : ''}
                                            </FormControl>
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <IconButton aria-label="add" disabled={!fieldAccess} onClick={this.saveCleaner} data-cy="addInput">
                                                <AddBoxTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                            <br/>
                            <br/>
                            <SectionTitle title={t('waste')}/>
                            {this.state.outputs.map((row, i) =>
                                <Box borderColor="primary.main" {...defaultProps}  key={'output' + i} component={'div'}>
                                    <Grid container spacing={3} key={'item' + i}>
                                        <Grid item sx={{width: 350}}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={row.itemSel}
                                                    options={this.state.wasteItems}
                                                    onChange={this.changeOutputAuto('itemSel', 'item', i)}
                                                    error={errors[`${t('item')} (${t('line')} {i})`] != null ? true : false}
                                                    helperText={errors[`${t('item')} (${t('line')} {i})`]}
                                                    disabled={!fieldAccess}
                                                    label={t('item')}
                                                    data-cy={"outputItem" + i}
                                                />
                                            }
                                            {!fieldAccess && row.itemSel != null &&
                                                <FormControl>
                                                    <FormLabel>{t('item')}</FormLabel>
                                                    <Link to={'/item/' + row.item}>{row.itemSel.label}</Link>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item sx={{width: 100}}>
                                            {fieldAccess &&
                                                <TextField type="number" value={row.amount} onChange={this.changeOutputValue('amount', i)} name="amount"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} data-cy={"outputAmount" + i}
                                                error={errors[`${t('amount')} (${t('line')} {i})`] != null ? true : false} helperText={errors[`${t('amount')} (${t('line')} {i})`]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('quantity')}{row.uomSel != null ? '(' + row.uomSel.label + ')' : ''}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={row.amount} thousandSeparator={true} decimalScale={2}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        {fieldAccess && 
                                            <Grid item sx={{width: 50}}>
                                                <FormControl>
                                                    <FormLabel>{t('uom')}</FormLabel>
                                                    {row.uomSel != null ? row.uomSel.label : ''}
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 250}}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={row.binSel}
                                                    options={this.state.bins}
                                                    onChange={this.changeOutputAuto('binSel', 'bin', i)}
                                                    error={errors[`${t('bin')} (${t('line')} {i})`] != null ? true : false}
                                                    helperText={errors[`${t('bin')} (${t('line')} {i})`]}
                                                    disabled={!fieldAccess}
                                                    label={t('bin')}
                                                    data-cy={"outputBin" + i}
                                                    groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                    noneMessage={t('noRoomSelected')}
                                                />
                                            }
                                            {!fieldAccess && row.binSel != null &&
                                                <FormControl>
                                                    <FormLabel>{t('bin')}</FormLabel>
                                                    <Link to={'/bin/' + row.binSel.value}>{row.binSel != null ? row.binSel.label : ''}</Link>
                                                </FormControl>
                                            }
                                        </Grid>
                                        {!fieldAccess && row.container != null &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('container')}</FormLabel>
                                                    <Link to={'/container/' + row.container._id}>{row.container.number}</Link>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {!fieldAccess && row.lot != null &&
                                            <Grid item xs={2} sm={1}>
                                                <FormControl>
                                                    <FormLabel>{t('lot')}</FormLabel>
                                                    <Link to={'/lot/' + row.lot._id}>{row.lot.number}</Link>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {fieldAccess &&
                                            <Grid item sx={{width: 50}}>
                                                <IconButton aria-label="delete item" disabled={!fieldAccess} onClick={this.deleteOutput(i)} data-cy={"deleteOutput" + i}>
                                                    <RemoveCircleTwoToneIcon  />
                                                </IconButton>
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            )}
                            {errors[t('outputs')] && <div><div className="error text-danger">{errors[t('outputs')]}</div><br/></div>}
                            {fieldAccess &&
                                <Box borderColor="primary.main" {...defaultProps} component={'div'}>
                                    <Grid container spacing={3}>
                                        <Grid item sx={{width: 350}}>
                                            <AutoCompleteField
                                                value={this.state.outputItemSel}
                                                options={this.state.wasteItems}
                                                onChange={this.changeAuto('outputItemSel', 'outputItem')}
                                                error={errors[t('outputItem')] != null ? true : false}
                                                helperText={errors[t('outputItem')]}
                                                label={t('item')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy="outputItem"
                                            />
                                        </Grid>
                                        <Grid item sx={{width: 100}}>
                                            <TextField type="number" value={this.state.outputAmount} onChange={this.changeNumber} name="outputAmount"
                                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} required data-cy="outputQuantity"
                                            error={errors[t('outputAmount')] != null ? true : false} helperText={errors[t('outputAmount')]}/>
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <FormControl>
                                                <FormLabel>{t('uom')}</FormLabel>
                                                {this.state.outputUOMSel ? this.state.outputUOMSel.label : ''}
                                            </FormControl>
                                        </Grid>
                                        <Grid item sx={{width: 250}}>
                                            <AutoCompleteField
                                                value={this.state.binSel}
                                                options={this.state.bins}
                                                onChange={this.changeAuto('binSel', 'bin')}
                                                error={errors[t('bin')] != null ? true : false}
                                                helperText={errors[t('bin')]}
                                                label={t('bin')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy="outputBin"
                                                groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                noneMessage={t('noRoomSelected')}
                                            />
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <IconButton aria-label="add" disabled={!fieldAccess} onClick={this.saveOutput} data-cy="addOutput">
                                                <AddBoxTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                            <FormButtonStack
                                id={this.state._id}
                                objectType={this.props.auth.constants.objectIds.roomCleaning}
                                callback={this.loadRecord}
                                saveClick={this.save}
                                status={this.state.status}
                                permissions={this.props.permission}
                                common={this.props.auth.common}
                                constants={this.props.auth.constants}
                                saveEnabled={this.state.enableSave}
                                deleteClick={this.deleteToggle}
                                activityDate={this.state.date}
                                lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                                isSuper={this.props.auth.user.isSuper}
                            />
                        </TabPanel>
                        {this.state._id != null && this.state._id !== '' &&
                            <Aux>
                                {this.state.sopsSel != null && this.state.sopsSel.length > 0 && this.state.sopsSel.map((sop, i) =>
                                    <TabPanel value={this.state.tabKey} index={i + 1} key={'sop' + i}>
                                        <ActivityWorkRecord sop={sop.value} id={this.state.sops[i].workRecord} users={this.state.users} tab={this.state.tabKey}
                                        objectType={this.props.auth.constants.objectIds.roomCleaning} recordId={this.state._id} date={this.state.date}
                                        locationId={this.state.location} complete={this.loadRecord} renderNum={this.state.renderNum}/>
                                    </TabPanel>
                                )}
                            </Aux>
                        }
                        {this.state.hasLedgers &&
                            <TabCanvas value={this.state.tabKey} index={this.state.tabLabels.length - 1}>
                                <ItemLedgerComponent record={this.state._id} permission={this.props.permission} auth={this.props.auth} renderNum={this.state.renderNum}/>
                            </TabCanvas>
                        }
                    </Grid>
                </Grid>
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.delete()} open={this.state.deleteOpen} />
                }
                {this.state.printViewer &&
                    <PrintView
                        id={this.state._id}
                        object={this.props.auth.constants.objectIds.roomCleaning}
                        open={this.state.printViewer}
                        onClose={this.togglePrintViewer}
                        callBack={this.loadRecord}
                        printAccess={this.state.security.print}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.roomCleaning}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(RoomCleaning));
