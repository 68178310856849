import React from 'react';
import { NumericFormat } from 'react-number-format';

const boxStyle = {
    margin: '8px',
    width: '100%'
}

const thStyle = {
    borderBottom: '2px solid rgb(224, 224, 224)', 
    fontSize: '9pt', 
    textAlign: 'center'
}

const tdStyle = { 
    border: '0.5px solid rgb(224, 224, 224)', 
    fontSize: '8pt', 
    padding: '5px',
}

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse'
}

export default function invoiceTemplate(props) {
    const { record, t } = props;
    return (
        <div style={{ width: '8.5in', pageBreakBefore: 'always' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                <div style={{ width: '71%' }}>
                    <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}><strong>{t('customerShipTo')}</strong></span>
                    <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{record?.customer?.name != null ? record.customer.name : ''}</span>
                    {record?.customer?.licenseNumber != null && record?.customer?.licenseNumber !== '' &&
                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>
                            {t('licenseNumber') + '# ' + record.customer.licenseNumber}
                        </span>
                    }                    
                    <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{record?.shippingAddress?.address1 != null ? record.shippingAddress.address1 : ''}</span>
                    {record?.shippingAddress?.address2 != null && record?.shippingAddress?.address2 !== '' &&
                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{record.shippingAddress.address2}</span>
                    }
                    <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{record.shippingAddress?.city != null && record.shippingAddress?.prov != null ? record.shippingAddress.city + ', ' + record.shippingAddress.prov  : ''}</span>
                    <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{record.shippingAddress?.postal != null ? record.shippingAddress.postal : ''}</span>
                    <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{record.shippingAddress?.country != null ? record.shippingAddress.country : ''}</span>
                    {record?.phoneNumber != null && record?.phoneNumber !== '' &&
                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}><strong>P:</strong> <NumericFormat displayType={'text'} value={record.phoneNumber} format='###-###-####'/></span>
                    }
                    {record?.customer?.gstNumber != null && record?.customer?.gstNumber !== '' &&
                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>
                            <strong>{t('gst') + ': '}</strong>{record.customer.gstNumber}
                        </span>
                    }
                </div>
                <div style={{ width: '29%' }}>
                    <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}><strong>{t('customerBillTo')}</strong></span>
                    <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{record?.customer?.name != null ? record.customer.name : ''}</span>
                    {record?.customer?.licenseNumber != null && record?.customer?.licenseNumber !== '' &&
                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>
                            {t('licenseNumber') + '# ' + record.customer.licenseNumber}
                        </span>
                    }                    
                    <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{record?.billingAddress?.address1 != null ? record.billingAddress.address1 : ''}</span>
                    {record?.billingAddress?.address2 != null && record?.billingAddress?.address2 !== '' &&
                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{record.billingAddress.address2}</span>
                    }
                    <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{record.billingAddress?.city != null && record.billingAddress?.prov != null? record.billingAddress.city + ', ' + record.billingAddress.prov  : ''}</span>
                    <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{record.billingAddress?.postal != null ? record.billingAddress.postal : ''}</span>
                    <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{record.billingAddress?.country != null ? record.billingAddress.country : ''}</span>
                    {record?.phoneNumber != null && record?.phoneNumber !== '' &&
                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}><strong>P:</strong> <NumericFormat displayType={'text'} value={record.phoneNumber} format='###-###-####'/></span>
                    }
                    {record?.customer?.gstNumber != null && record?.customer?.gstNumber !== '' &&
                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>
                            <strong>{t('gst') + ': '}</strong>{record.customer.gstNumber}
                        </span>
                    }
                </div>
            </div>
            <div style={boxStyle}>
            <span style={{ display: 'block', fontSize: '9pt', fontWeight: 'bold'}}>{t('contents')}</span>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...thStyle, width: '100px'}} >
                                {record?.customer?.foreignId === 'aglc' ? t('aglcCBN') : t('item')}
                            </th>
                            <th style={{ ...thStyle }} >{t('description')}</th>
                            {record?.customer?.foreignId === 'aglc' ? 
                                <th style={{ ...thStyle }}>{t('supplierSKU')}</th> : null
                            }
                            <th style={{ ...thStyle, width: '100px' }} >{t('lotNumber')}</th>
                            <th style={{ ...thStyle, width: '75px' }} >{t('quantity')}</th>
                            <th style={{ ...thStyle, width: '75px' }} >{t('price')}</th>
                            <th style={{ ...thStyle, width: '100px' }} >{t('amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {record.invoiceLines != null && record.invoiceLines.length > 0 &&
                            record.invoiceLines.map((row, i) => 
                            <tr key={'invoiceContent-' + i} >
                                <td style={tdStyle}>
                                    {record?.customer?.foreignId === 'aglc' && row.sku != null ? row.sku :
                                        row.item != null ? row.item : ''
                                    }
                                </td>
                                <td style={tdStyle}>{row.itemName != null ? row.itemName : ''}</td>
                                {record?.customer?.foreignId === 'aglc' ?
                                    <td style={tdStyle}>{row.gtin != null ? row.gtin : ''}</td> : null
                                }
                                <td style={tdStyle}>{row.lotNumber != null ? row.lotNumber : t('none')}</td>
                                <td style={{ ...tdStyle, textAlign: 'right' }} >
                                    <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true} 
                                            decimalScale={2} suffix={row.uom}/>
                                </td>
                                <td style={{ ...tdStyle, textAlign: 'right' }} >
                                    <NumericFormat displayType={'text'} value={row.price} thousandSeparator={true} 
                                            decimalScale={2} fixedDecimalScale={true} prefix='$'/>
                                </td>
                                <td style={{ ...tdStyle, textAlign: 'right' }} >
                                    <NumericFormat displayType={'text'} value={row.total} thousandSeparator={true} 
                                            decimalScale={2} fixedDecimalScale={true} prefix='$'/>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div style={{ width: '100%', display: 'block', marginTop: '15px'}}>
                    <div style={{ width: '70%', display: 'inline-block', verticalAlign: 'top', fontSize: '9pt' }}>
                        <span style={{ display: 'block', width: 100}}><strong>{t('notes')}</strong></span>
                        <span style={{ display: 'block', width: 580, wordWrap: 'normal'}}>
                            {record?.invoiceNotes != null && record?.invoiceNotes !== '' ? record.invoiceNotes : ''}
                        </span>
                    </div>
                    <div style={{ width: '30%', display: 'inline-block'  }}>
                        <div style={{ width: '40%', display: 'inline-block', fontWeight: 'bold', fontSize: '9pt'}}>
                            {record?.discountAmount != null && record?.discountAmount > 0 && <>{t('totalAmount')}<br /></>}
                            {record?.discountAmount != null && record?.discountAmount > 0 && <>{t('discount')}<br /></>}
                            {t('subTotal')}<br />
                            {record?.taxShippingCosts != null && record?.taxShippingCosts === true && record?.shippingCost != null && record?.shippingCost > 0 && <>{t('shipping')}<br /></>}
                            {record?.hstAmount != null && record?.hstAmount > 0 && <>{t('hst')}<br /></>}
                            {(record?.hstAmount == null || record?.hstAmount === '' || record?.hstAmount.toString() === '0.00' || record?.hstAmount === 0) && 
                              record?.gstAmount != null && record?.gstAmount > 0 && <>{t('gst')}<br /></>}
                            {(record?.hstAmount == null || record?.hstAmount === '' || record?.hstAmount.toString() === '0.00') && 
                              record?.pstAmount != null && record?.pstAmount > 0 && <>{record.isQC != null && record.isQC === true ? t('qst') : t('pst')}<br /></>}
                            {record?.taxShippingCosts != null && record?.taxShippingCosts === false && record?.shippingCost != null && record?.shippingCost > 0 && <>{t('shipping')}<br /></>}
                            {t('total')}
                        </div>
                        <div style={{ width: '60%', display: 'inline-block', textAlign: 'right', fontSize: '9pt'}}>
                            {record?.discountAmount != null && record?.discountAmount > 0 &&
                                <><NumericFormat displayType={'text'} value={record.preDiscount} thousandSeparator={true} 
                                fixedDecimalScale={true} decimalScale={2} prefix='$'/><br /></>
                            }
                            {record?.discountAmount != null && record?.discountAmount > 0 &&
                                <><NumericFormat displayType={'text'} value={record.discountAmount} thousandSeparator={true} 
                                fixedDecimalScale={true} decimalScale={2} prefix='$'/><br /></>
                            }
                            <NumericFormat displayType={'text'} value={record.amount} thousandSeparator={true} 
                            fixedDecimalScale={true} decimalScale={2} prefix='$'/><br />
                            {record?.taxShippingCosts != null && record?.taxShippingCosts === true && record?.shippingCost != null && record?.shippingCost > 0 && 
                                <>
                                    <NumericFormat displayType={'text'} value={record.shippingCost} thousandSeparator={true}
                                    fixedDecimalScale={true} decimalScale={2} prefix='$'/><br />
                                </>
                            }
                            {record?.hstAmount != null && record?.hstAmount > 0 &&
                                <>
                                    <NumericFormat displayType={'text'} value={record.hstAmount} thousandSeparator={true} 
                                    fixedDecimalScale={true} decimalScale={2} prefix='$'/><br />
                                </>
                            }
                            {(record?.hstAmount == null || record?.hstAmount === '' || record?.hstAmount.toString() === '0.00' || record?.hstAmount === 0) && 
                              record.gstAmount != null && record.gstAmount !== '' &&
                                <>
                                    <NumericFormat displayType={'text'} value={record.gstAmount} thousandSeparator={true} 
                                    fixedDecimalScale={true} decimalScale={2} prefix='$'/><br />
                                </>
                            }
                            {(record?.hstAmount == null || record?.hstAmount === '' || record?.hstAmount.toString() === '0.00') && 
                              record?.pstAmount != null && record?.pstAmount !== '' && isNaN(record?.pstRate) &&
                                <>
                                    <NumericFormat displayType={'text'} value={record.pstAmount} thousandSeparator={true} 
                                    fixedDecimalScale={true} decimalScale={2} prefix='$'/><br />
                                </>
                            }
                            {record?.taxShippingCosts != null && record?.taxShippingCosts === false && record?.shippingCost != null && record?.shippingCost > 0 && 
                                <>
                                    <NumericFormat displayType={'text'} value={record.shippingCost} thousandSeparator={true}
                                    fixedDecimalScale={true} decimalScale={2} prefix='$'/><br />
                                </>
                            }
                            <NumericFormat displayType={'text'} value={record.totalAmount} 
                            thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} prefix='$'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}