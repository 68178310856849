import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';

class ItemReorderList extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            orderBy: 'number',
            ordDir: 1,
            totalCount: 0,
            page: 0,
        }
    }

    async componentDidMount(){
        this.loadRecords();
    }

    loadRecords = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            offset: this.state.offset,
            size: this.state.listSize,
            sort: JSON.stringify(sort),
        };
        let list = await axios.get('/api/itemreorders', {params: data});
        this.setState({
            list: list.data.data,
            pages: Math.ceil(list.data.totalCount / this.state.listSize),
            totalCount: list.data.totalCount
        });
    };

    sortClick(name) {
        let orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <SectionTitle title={t('itemReorders')}/>
                <Grid container spacing={3} sx={{ my: 1 }}>
                    <Grid item xs={12}>
                        <Table aria-label="Item List" size="small">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('name')}
                                        active={this.state.orderBy === 'name'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('name')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('category')}
                                        active={this.state.orderBy === 'category'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('category')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>{t('uom')}</TableCell>
                                    <TableCell>{t('inventory')}</TableCell>
                                    <TableCell>{t('count')}</TableCell>
                                    <TableCell>{t('minimumQuantity')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/item/' + row._id} name={'itemLink' + i}>{row.number}</Link></TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.category != null ? row.category.code : ''}</TableCell>
                                        <TableCell>{row.uom}</TableCell>
                                        <TableCell>
                                            {row.inventory !== '0' && <NumericFormat displayType={'text'} value={row.inventory} thousandSeparator={true} decimalScale={3}/>}
                                        </TableCell>
                                        <TableCell>
                                            {row.count != null && row.count !== '' && row.count !== '0' && row.count !== 0 &&
                                                <NumericFormat displayType={'text'} value={row.count} thousandSeparator={true} decimalScale={0}/>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {row.minimumQuantity !== '0' &&
                                            <NumericFormat displayType={'text'} value={row.minimumQuantity} thousandSeparator={true} decimalScale={2}/>}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Grid>
                </Grid>
            </Aux>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(ItemReorderList));