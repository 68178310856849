import React, { Component } from 'react';
import axios from 'axios';
import Aux from '../../hoc/Auxilary/Auxilary';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// import TreeView from '@mui/lab/TreeView';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';

class LotLife extends Component {
    constructor(props){
        super(props);
        this.state = {
            id: this.props.lot,
            data: {}
        };
    }

    componentDidMount(){
        if(this.props.lot !== undefined){
            this.loadRecords();
        }
    }

    loadRecords = async () => {
        let result = await axios.get('/api/lotlife/' + this.props.lot);
        this.setState({
           data: result.data
        })
    };

    recordString = (record, object) => {
        const t = this.props.t;
        if(record.definition != null)
            return record.number + ' - ' + record.definition.description + ' ' + t('batch') + '-' + record.batch.number;
        else 
            return record.number + ' - ' + object.text;
    }

    treeItems = (rows) => {
        return(
            <Aux>
                {rows != null && rows.length > 0 && rows.map((row, i) =>
                    <TreeItem nodeId={row.recordObj.toString()} label={row.recordObj != null ? row.recordObj.number + ' - ' + row.objectObj.text : ''}>
                        {row.steps != null && row.steps.length > 0 && row.steps.map((step, j) =>
                            <TreeItem key={'stepId-' + i + '-' + j} nodeId={step.record + '-' + j} label={step.recordObj != null ? step.recordObj.number + ' - ' + step.objectObj.text : ''}>
                                {row.steps != null && row.steps.length > 0 && this.treeItems(row.steps)}
                            </TreeItem>
                        )}
                    </TreeItem>
                )}
            </Aux>
        )
    }

    subItems = (rows, level) => {
        const t = this.props.t;
        let width = level * 10;
        let newLevel = level + 1;
        return(
            <Aux>
                {rows != null && rows.length > 0 && rows.map((row, i) =>
                    <Aux>
                        <Grid container spacing={3} sx={{pb: 1}}>
                            {row.recordObj != null && row.objectObj != null &&
                                <Aux>
                                    <Grid item xs={10} sm={6}>
                                        <Grid container>
                                            <Grid item sx={{width: width}}></Grid>
                                            <Grid item >
                                                <Link to={row.objectObj.link + row.record}>{this.recordString(row.recordObj, row.objectObj)}</Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={1}>
                                        <DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/>
                                    </Grid>
                                    {row.lotObj != null &&
                                        <Aux>
                                            <Grid item xs={4} sm={2}>
                                                <Link to={'/lot/' + row.lot}>{t('lot') + ' - ' + row.lotObj.number}</Link>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Link to={'/item/' + row.lotObj.item._id}>{t('item') + ' ' + row.lotObj.item.number + '-' +row.lotObj.item.name}</Link>
                                            </Grid>
                                        </Aux>
                                    }
                                </Aux>
                            }
                        </Grid>
                        {row.steps != null && row.steps.length > 0 && 
                            this.subItems(row.steps, newLevel)
                        }
                    </Aux>
                )}                
            </Aux>
        )
    }

    render(){
        //const { classes } = this.props;
        return(
            <Grid container spacing={2}>
                {this.state.data != null && this.state.data.recordObj != null &&
                    <Grid item container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <Link to={this.state.data.objectObj.link + this.state.data.record}>{this.recordString(this.state.data.recordObj, this.state.data.objectObj)}</Link>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateDisplay value={this.state.data.date} format={this.props.auth.user.dateFormat}/>
                        </Grid>
                        {this.state.data.steps != null && this.state.data.steps.length > 0 && this.subItems(this.state.data.steps, 1)}
                    </Grid>
                    // <Grid item xs={12}>
                    //     <TreeView
                    //             sx={{ height: 600,
                    //                 flexGrow: 1,
                    //                 maxWidth: 400,
                    //                 overflowY: 'auto',  }}
                    //             defaultCollapseIcon={<ExpandMoreIcon />}
                    //             defaultExpandIcon={<ChevronRightIcon />}
                    //             defaultExpanded={['start']}
                    //         >
                    //             <TreeItem nodeId="start" label={this.state.data.recordObj.number + ' - ' + this.state.data.objectObj.text}>
                    //                 {this.state.data.steps != null && this.state.data.steps.length > 0 && this.treeItems(this.state.data.steps)} 
                    //             </TreeItem>
                    //     </TreeView>                        
                    // </Grid>
                }
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default withTranslation()(connect(mapStateToProps)(LotLife));