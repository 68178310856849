import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setCurrentUser } from '../actions/authentication';
import { setNavCrumbs } from '../actions/navigation';
import SopByStatusChart from './General/SopByStatusChart';
import SopByTypeChart from './General/SopByTypeChart';
import CalendarSchedule from './General/CalendarSchedule';
import ScheduledActs from './Widgets/ScheduledActs';
import Aux from '../hoc/Auxilary/Auxilary';
import Panel from './UI/Panel/Panel';
import Grid from '@mui/material/Grid';
import ActionBar from './UI/Buttons/ActionBar';
import OutstandingCertifications from './Widgets/OutstandingCertifications';
import { withTranslation } from 'react-i18next';
import Approvals from './General/Approvals';
import common from '../jsons/common.json';
import BreadcrumbBar from './Navigation/Breadcrumbs/Breadcrumb';
import ScheduleBarChart from './General/ScheduleBarChart';
import Announcements from './General/Announcements';

class Home extends Component{

    componentDidMount = async () => {
        this.loadCrumbs();
    }

    loadCrumbs = () => {
        const t = this.props.t;
        const crumbs = [
            { path: 'active', label: t('welcome') + ', ' + this.props.profile.name, screen: this.props.auth.screenDefs.Home}
        ]
        this.props.setNavCrumbs(crumbs);
    }

  render(){
      const t = this.props.t;
      const micro = this.props.auth.user.micro;
        return(
            <Aux>
                {this.props.auth.isAuthenticated &&
                <Aux name="home">
                    <BreadcrumbBar>
                        <ActionBar
                            helpPath={this.props.auth.user.helpUrl + '/'+ this.props.security.currentPath.helpId}
                            helpUser={this.props.auth.user.helpUser}
                            helpPassword={this.props.auth.user.helpPassword}
                        />
                    </BreadcrumbBar>                    
                    <Panel>
                        <Announcements type={common.announcementTypes.regular}/>
                        <br />
                        <CalendarSchedule/>
                        <br/>
                        <ScheduleBarChart />
                        <br/>
                        <ScheduledActs assignee={this.props.auth.user.id} title={this.props.t('myActivities')}/>
                        <br/>
                        {!micro &&
                            <Aux>
                                <OutstandingCertifications title={this.props.t('outstandingCerts')}/>
                                <br/>
                            </Aux>
                        }
                        <Grid container>
                            <Grid item sm={6}>
                            <SopByStatusChart/>
                            </Grid>
                            <Grid item sm={6}>
                            <SopByTypeChart/>
                            </Grid>
                        </Grid>
                        {this.props.auth.user.isManager && this.props.auth.constants != null &&
                            <Aux>
                                <br/>
                                <Approvals title={t('reviews')} status={this.props.auth.constants.scheduleStatuses.complete}/>
                            </Aux>
                        }
                        {this.props.auth.user.isQa && this.props.auth.constants != null &&
                            <Aux>
                                <br/>
                                <Approvals title={t('signoffs')} status={this.props.auth.constants.scheduleStatuses.reviewed}/>
                            </Aux>
                        }
                    </Panel>
                </Aux>
                }
            </Aux>
        )
    }
}

Home.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
  setNavCrumbs: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile.profile,
  security: state.security
});

export default withTranslation() (connect(mapStateToProps,{ setCurrentUser, setNavCrumbs }) (Home));
