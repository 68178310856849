import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import DateSelect from '../UI/DateSelect/DateSelect';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Panel from '../UI/Panel/Panel';
import TextField from '../UI/TextField/TextField';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { CSVDownload } from "react-csv";

class LossCannabisList extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: this.props.listSize ? this.props.listSize : 5,
            date: null,
            type: '',
            types: [],
            status: '',
            statuses: [],
            orderBy: 'lossDate',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            typeSelected: null,
            statusSelected: null,
            number: '',
            security: [],
            csvData: null,
            headers: null
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        const values = await axios.get('/api/losslistvalues');
        let storage = sessionStorage.getItem('losses');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        this.setState({
            types: values.data.types,
            statuses: cacheValues.statuses,
            security: this.props.permission,
            offset: storage != null ? storage.offset : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'number',
            ordDir: storage != null ? storage.ordDir : -1,
            status: storage != null ? storage.status : '',
            statusSelected: storage != null ? storage.statusSelected : null,
            type: storage != null ? storage.type : '',
            typeSelected: storage != null ? storage.typeSelected : null,
            number: storage != null ? storage.number : '',
            date: storage != null && storage.date != null && storage.date !== '' ? new Date(storage.date) : '',
            page: storage != null ? storage.page : 0
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('lossesCannabis'), screen: this.props.auth.screenDefs.LossCannabisList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            number: this.state.number,
            date: this.state.date,
            type: this.state.type,
            typeSelected: this.state.typeSelected,
            status: this.state.status,
            statusSelected: this.state.statusSelected,
            page: this.state.page
        };
        sessionStorage.setItem('losses', JSON.stringify(data));
    }

    loadRecords = async () => {
        var sort = {[this.state.orderBy]: this.state.ordDir};
        const data = {
            offset: this.state.offset,
            size: this.state.listSize,
            date: this.state.date,
            type: this.state.type,
            status: this.state.status,
            number: this.state.number,
            sort: JSON.stringify(sort)
        }
        const list = await axios.get('/api/losses', {params: data});
        this.setState({
            list: list.data.data,
            pages: Math.ceil(list.data.totalCount / this.state.listSize),
            totalCount: list.data.totalCount
        })
        this.saveFilters();
    };

    csvClick = async () => {
        this.setState({csvData: null, headers: null});
        const data = {
            date: this.state.date,
            type: this.state.type,
            status: this.state.status,
            number: this.state.number,
        }
        const list = await axios.get('/api/lossescsv', {params: data});
        this.setState({
            csvData: list.data.data,
            headers: list.data.headers
        })
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value}, () => {
            this.loadRecords();
        });
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    dateChange = (e) => {
        this.setState({date: e, page: 0, offset: 0}, () => this.loadRecords());
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/loss'
                        createAccess={security.create}
                        csvClick={this.csvClick}
                    />
                </BreadcrumbBar>
                {this.state.csvData != null &&
                    <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.headers} separator={this.state.separator}/>
                }
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.number} onChange={this.changeValue} name="number"
                                size="medium" fullWidth={true} label={t('number')}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                className="form-control"
                                onChange={this.dateChange}
                                value={this.state.date}
                                label={t('date')}
                                format={this.props.auth.user.dateFormat}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.typeSelected}
                                options={this.state.types}
                                onChange={this.changeAuto('typeSelected', 'type')}
                                label={t('type')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.statusSelected}
                                options={this.state.statuses}
                                onChange={this.changeAuto('statusSelected', 'status')}
                                label={t('status')}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                    <Table aria-label="Loss or Theft of Cannabis Reports">
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('number')}
                                    active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('lossDate')}
                                    active={this.state.orderBy === 'lossDate'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('type')}
                                    active={this.state.orderBy === 'type'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('type')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('status')}
                                    active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('location')}
                                    active={this.state.orderBy === 'location'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('location')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list.map((record, i) =>
                                <TableRow key={record._id}>
                                    <TableCell><Link to={'/loss/' + record._id} name={'lossRow' + i}>{record.number}</Link></TableCell>
                                    <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={record.lossDate}/></TableCell>
                                    <TableCell>{record.type != null ? record.type.label : ''}</TableCell>
                                    <TableCell>{record.status != null ? record.status.label : ''}</TableCell>
                                    <TableCell>{record.location.name}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(LossCannabisList));
