import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel, FormControlLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Panel from '../UI/Panel/Panel';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import Box from '@mui/material/Box';
import Switch from '../UI/Switch/Switch';
import { approveSigSecurity, completeSigSecurity,
    fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import CapaAction from './CapaAction';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';

class Recall extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: null,
            number: '',
            status: '',
            statusSel: null,
            statuses: [],
            items: [],
            description: '',
            initiatedDate: '',
            affectedLots: [],
            regulatoryNotifications: [],
            suppliers: [],
            vendors: [],
            finalDocument: null,
            documents: null,
            initiator: '',
            initiatorSel: null,
            users: [],
            qas: [],
            managers: [],
            retulators: [],
            uoms: [],
            products: [],
            security: {},
            enableSave: true,
            location: '',
            locationSel: null,
            locations: [],
            item: '',
            itemSel: null,
            lots: [],
            lotsSel: [],
            itemLots: [],
            regulator: '',
            regulatorSel: null,
            notifier: '',
            notifierSel: null,
            date: '',
            writtenDate: '',
            method: [],
            methodSel: [],
            methods: [],
            customers: [],
            supplierIssue: false,
            supplier: '',
            supplierSel: null,
            suppMethod: [],
            suppMethodSel: [],
            suppItem: '',
            suppItemSel: null,
            suppLot: '',
            suppReturned: '',
            suppDate: '',
            suppConfDate: '',
            user: null,
            reviewer: null,
            signer: null,
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            optionsMenu: [],
            flagForSignature: 0,
            completionDetails: '',
            type: '',
            typeSel: null,
            types: [],
            riskAssessment: '',
            expectedCompletion: '',
            actualCompletion: '',
            deviations: [],
            capaActions: [],
            totalCount: 0,
            listSize: 10,
            page: 0,
            capaId: '',
            capaIndex: -1,
            editCapa: false,
        }
    }

    async componentDidMount(){
        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.allItems, common.cacheValues.vendor, common.cacheValues.activeLocation, common.cacheValues.status,
                            common.cacheValues.quantityUOM], this.props.auth.constants, null, this.props.auth.user.tenant);
        let items = cacheValues.allItems;
        let vendors = cacheValues.vendors;
        let locations = cacheValues.locations;
        let values = await axios.get('/api/recallvalues');
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
                this.props.auth.user.defaultLocation : '';
        let locationSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locationSel == null && locations.length === 1){
            locationSel = locations[0];
            location = locationSel.value;
        }
        let fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        this.setState({
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            statuses: cacheValues.statuses,
            vendors: vendors,
            regulators: values.data.regulators,
            uoms: cacheValues.quantityUOMs,
            users: values.data.users,
            qas: values.data.qas,
            managers: values.data.managers,
            security: this.props.permission,
            locations: locations,
            items: items,
            location: location,
            locationSel: locationSel,
            methods: values.data.methods,
            types: values.data.types,
            fieldAccess: fieldAccess,
            _id: this.props.match.params.id  != null && this.props.match.params.id !== '' ? this.props.match.params.id : null
        }, () => {
            if(this.state._id != null){
                this.loadRecord();
            } else {
                const crumbs = [
                    { path: '/#/recalls', label: this.props.t('recalls'), screen: this.props.auth.screenDefs.Recalls},
                    { path: 'active', label: this.props.t('recall'), screen: this.props.auth.screenDefs.Recall}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () =>{
        const t = this.props.t;
        let record = await axios.get('/api/recall/' + this.state._id);
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let initiatorSel = this.state.users.find(x => x.value === record.data.initiator);
        let locationSel = this.state.locations.find(x => x.value === record.data.location);
        let typeSel = this.state.types.find(x => x.value === record.data.type);
        let products = record.data.products;
        if(products != null && products.length > 0){
            for(let row of products){
                let itemSel = this.state.items.find(x => x.value === row.item);
                row.itemSel = itemSel;
                let lotsSel = [];
                if(row.lots != null && row.lots.length > 0){
                    for(let lot of row.lots){
                        let lotVal = row.itemLots.find(x => x.value === lot);
                        lotsSel.push(lotVal);
                    }
                }
                row.lotsSel = lotsSel;
            }
        }
        let regulatoryNotifications = record.data.regulatoryNotifications;
        if(regulatoryNotifications != null && regulatoryNotifications.length > 0){
            for(let row of regulatoryNotifications){
                let regVal = this.state.regulators.find(x => x.value === row.regulator);
                row.regulatorSel = regVal;
                let notifierSel = this.state.users.find(x => x.value === row.notifier);
                row.notifierSel = notifierSel;
                let methodSel = []
                if(row.method != null && row.method.length > 0){
                    row.method.forEach(meth => {
                        let methVal = this.state.methods.find(x => x.value === meth);
                        methodSel.push(methVal);
                    })
                }
                row.methodSel = methodSel;
            }
        }
        let customers = record.data.customers;
        if(customers != null && customers.length > 0){
            for(let row of customers){
                let notifSel = row.notifiedBy != null ? this.state.users.find(x => x.value === row.notifiedBy) : null;
                row.notifiedBySel = notifSel;
            }
        }
        let suppliers = record.data.suppliers;
        if(suppliers != null && suppliers.length > 0){
            for(let row of suppliers){
                let itemSel = this.state.items.find(x => x.value === row.item);
                row.itemSel = itemSel;
                let supplierSel = this.state.vendors.find(x => x.value === row.supplier);
                row.supplierSel = supplierSel;
                let methodSel = [];
                if(row.method != null && row.method.length > 0){
                    row.method.forEach(meth => {
                        let val = this.state.methods.find(x => x.value === meth);
                        methodSel.push(val);
                    });
                }
                row.methodSel = methodSel;
            }
        }
        let fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: this.state.users, name: t('complete'), object: record.data._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user,
                objectType: this.props.auth.constants.objectIds.recall, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: this.state.managers, name: t('review'),
                title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.recall, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: this.state.qas, name: t('approve'),
                title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer,
                objectType: this.props.auth.constants.objectIds.recall, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        this.setState({
            number: record.data.number,
            status: record.data.status,
            statusSel: statusSel,
            description: record.data.description,
            initiatedDate: record.data.initiatedDate,
            affectedLots: record.data.affectedLots,
            products: products,
            regulatoryNotifications: regulatoryNotifications,
            suppliers: suppliers,
            finalDocument: record.data.finalDocument,
            documents: record.data.documents,
            initiator: record.data.initiator,
            initiatorSel: initiatorSel,
            location: record.data.location,
            locationSel: locationSel,
            customers: customers,
            supplierIssue: record.data.supplierIssue === true ? true : false,
            fieldAccess: fieldAccess,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            completionDetails: record.data.completionDetails,
            type: record.data.type,
            typeSel: typeSel,
            expectedCompletion: record.data.expectedCompletion,
            actualCompletion: record.data.actualCompletion,
            riskAssessment: record.data.riskAssessment,
            deviations: record.data.deviations
        }, () => {
            const crumbs = [
                { path: '/#/recalls', label: this.props.t('recalls'), screen: this.props.auth.screenDefs.Recalls},
                { path: 'active', label: this.props.t('recall') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.Recall}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            this.loadCapas();
        });
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
        });
        if(data === 'item'){
            if(newValue != null){
                let lotRes = await axios.get('/api/itemlots/' + newValue.value, {params: {includeEmpty: true}});
                this.setState({itemLots: lotRes.data, lots: [], lotsSel: []});
            } else {
                this.setState({itemLots: [], lotsSel: [], lots: []});
            }
        }
    };

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeMulti = (name, data) => (e, newValue) => {
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    save = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    };

    _save = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            _id: this.state._id,
            description: this.state.description,
            initiator: this.state.initiator,
            initiatedDate: this.state.initiatedDate,
            products: this.state.products,
            regulatoryNotifications: this.state.regulatoryNotifications,
            suppliers: this.state.suppliers,
            location: this.state.location,
            customers: this.state.customers,
            supplierIssue: this.state.supplierIssue,
            completionDetails: this.state.completionDetails,
            type: this.state.type,
            expectedCompletion: this.state.expectedCompletion,
            riskAssessment: this.state.riskAssessment
        }
        try {
            let result = await axios.post('/api/recall', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/recall/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true, sigData: null}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
            this.loadRecord();
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    };

    validate(){
        const t = this.props.t;
        let state = this.state;
        let errors = {};
        if(state.description == null || state.description === '')
            errors[t('description')] = t('required');
        if(state.products == null || state.products.length === 0)
            errors[t('item')] = t("required");
        if(state.initiatedDate == null || state.initiatedDate === '')
            errors[t('initiatedDate')] = t('required');
        if(state.initiator == null || state.initiator === '')
            errors[t('initiator')] = t('required');
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(state.products != null && state.products.length > 0){
            for(let i = 0; i < state.products.length; i++){
                let prod = state.products[i];
                if(prod.item == null || prod.item === '')
                    errors[`${t('item')} (${t('line')} ${i})`] = t('required');
                if(prod.lots == null || prod.lots.length === 0)
                    errors[`${t('lots')} (${t('line')} ${i})`] = t('required');
            }
        }
        if(state.regulatoryNotifications != null && state.regulatoryNotifications.length > 0){
            for(let i = 0; i < state.regulatoryNotifications.length; i++){
                let reg = state.regulatoryNotifications[i];
                if(reg.regulator == null || reg.regulator === '')
                    errors[`${t('regulator')} (${t('line')} ${i})`] = t('required');
                if(reg.notifier == null || reg.notifier === '')
                    errors[`${t('notifier')} (${t('line')} ${i})`] = t('required');
                if(reg.date == null || reg.date === '')
                    errors[`${t('date')} (${t('line')} ${i})`] = t('required');
            }
        }
        if(state.suppliers != null && state.suppliers.length > 0){
            for(let i = 0; i < state.suppliers.length; i++){
                let row = state.suppliers[i];
                if(row.supplier == null || row.supplier === '')
                    errors[`${t('supplier')} (${t('line')} ${i})`] = t('required');
                if(row.item == null || row.item === '')
                    errors[`${t('item')} (${t('line')} ${i})`] = t('required');
            }
        }
        if((state.item != null && state.item !== '') || (state.lots != null && state.lots.length > 0))
            errors[t('item')] = t('rowNotAdded');
        if((state.regulator != null && state.regulator !== '') || (state.notifier != null && state.notifier !== '') || (state.date != null && state.date !== '') ||
        (state.writtenDate != null && state.writtenDate !== '') || (state.method != null && state.method.length > 0))
            errors[t('regulator')] = t('rowNotAdded');
        if((state.supplier != null && state.supplier !== '') || (state.suppItem != null && state.suppItem !== '') || (state.suppLot != null && state.suppLot !== '') ||
        (state.suppReturned != null && state.suppReturned !== '') || (state.suppDate != null && state.suppDate !== '') ||
        (state.suppConfDate != null && state.suppConfDate !== '') || (state.suppMethod != null && state.suppMethod.length > 0))
            errors[t('supplier')] = t('rowNotAdded');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    validateComplete = () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid)
            return t('notAllComplete');
        return null;
    };

    changeLineAuto = (name, data, i) => async (e, newValue) => {
        let products = this.state.products;
        let line = products[i];
        line[name] = newValue;
        line[data] = newValue != null ? newValue.value : '';
        if(data === 'item'){
            line.lots = [];
            line.lotsSel = [];
            if(newValue != null){
                let lots = await axios.get('/api/itemlots/' + newValue.value + '?includeEmpty=true');
                line.itemLots = lots.data;
            } else {
                line.itemLots = [];
            }
        }
        this.setState({products: products});
    };

    changeLineMulti = (name, data, i) => (e, newValue) => {
        let products = this.state.products;
        let line = products[i];
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        line[name] = newValue;
        line[data] = ids;
        this.setState({products: products});
    };

    addLine = (e) => {
        let valid = this.validateLine();
        if(!valid)
            return;
        let products = this.state.products;
        products.push({item: this.state.item, itemSel: this.state.itemSel, lots: this.state.lots, lotsSel: this.state.lotsSel, itemLots: this.state.itemLots});
        this.setState({products: products, lots: [], lotsSel: [], itemLots: [], item: '', itemSel: null});
    };

    deleteLine = (i) => (e) => {
        let products = this.state.products;
        products.splice(i, 1);
        this.setState({products: products});
    }

    validateLine(){
        let errors = {};
        const t = this.props.t;
        if(this.state.item == null || this.state.item === '')
            errors[t('item')] = t('required');
        if(this.state.lots == null || this.state.lots.length === 0)
            errors[t('lots')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeRegAuto = (name, data, i) => async (e, newValue) => {
        let regulatoryNotifications = this.state.regulatoryNotifications;
        let line = regulatoryNotifications[i];
        line[name] = newValue;
        line[data] = newValue != null ? newValue.value : '';
        this.setState({regulatoryNotifications: regulatoryNotifications});
    };

    changeRegMulti = (name, data, i) => (e, newValue) => {
        let regulatoryNotifications = this.state.regulatoryNotifications;
        let line = regulatoryNotifications[i];
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        line[name] = newValue;
        line[data] = ids;
        this.setState({regulatoryNotifications: regulatoryNotifications});
    };

    addReg = (e) => {
        let valid = this.validateReg();
        if(!valid)
            return;
        let regulatoryNotifications = this.state.regulatoryNotifications;
        regulatoryNotifications.push({
            regulator: this.state.regulator, 
            regulatorSel: this.state.regulatorSel, 
            method: this.state.method, 
            methodSel: this.state.methodSel,
            date: this.state.date,
            writtenDate: this.state.writtenDate,
            notifier: this.state.notifier,
            notifierSel: this.state.notifierSel
        });
        this.setState({
            regulatoyrNotifications: regulatoryNotifications, 
            regulator: '',
            regulatorSel: null,
            method: [],
            methodSel: [],
            date: '',
            writtenDate: '',
            notifier: '',
            notifierSel: null
        });
    };

    changeRegDate = (name, i) => (e) => {
        let regulatoryNotifications = this.state.regulatoryNotifications;
        let line = regulatoryNotifications[i];
        line[name] = e;
        this.setState({regulatoryNotifications: regulatoryNotifications});
    };

    deleteReg = (i) => (e) => {
        let regulatoryNotifications = this.state.regulatoryNotifications;
        regulatoryNotifications.splice(i, 1);
        this.setState({regulatoryNotifications: regulatoryNotifications});
    }

    validateReg(){
        let errors = {};
        const t = this.props.t;
        if(this.state.regulator == null || this.state.regulator === '')
            errors[t('regulator')] = t('required');
        if(this.state.notifier == null || this.state.notifier === '')
            errors[t('notifier')] = t('required');
        if(this.state.date == null || this.state.date === '')
            errors[t('date')] = t('required');
        if(this.state.method == null || this.state.method.length === 0)
            errors[t('method')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    addSupplier = (e) => {
        let valid = this.validateSupplier();
        if(!valid)
            return;
        let suppliers = this.state.suppliers;
        suppliers.push({
            supplier: this.state.supplier,
            supplierSel: this.state.supplierSel,
            method: this.state.suppMethod,
            methodSel: this.state.suppMethodSel,
            item: this.state.suppItem,
            itemSel: this.state.suppItemSel,
            lot: this.state.suppLot,
            quantityReturned: this.state.suppReturned,
            quantityUOM: this.state.suppItemSel.uom,
            date: this.state.suppDate,
            confirmationDate: this.state.suppConfDate
        });
        this.setState({
            suppliers: suppliers,
            supplier: '',
            supplierSel: null,
            suppMethod: [],
            suppMethodSel: [],
            suppItem: '',
            suppItemSel: null,
            suppLot: '',
            suppReturned: '',
            suppDate: '',
            suppConfDate: ''
        });
    }

    validateSupplier(){
        const t = this.props.t;
        const state = this.state;
        let errors = {};
        if(state.supplier == null || state.supplier === '')
            errors[t('supplier')] = t('required');
        if(state.suppItem == null || state.suppItem === '')
            errors[t('suppItem')] = t('required')
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteSupplier = (i) => (e) => {
        let suppliers = this.state.suppliers;
        suppliers.splice(i, 1);
        this.setState({suppliers: suppliers});
    };

    changeRowDate = (array, name, i) => (e) => {
        let values = this.state[array];
        let line = values[i];
        line[name] = e;
        this.setState({[array]: values});
    };

    changeRowAuto = (array, name, data, i) => async (e, newValue) => {
        let values = this.state[array];
        let line = values[i];
        line[name] = newValue;
        line[data] = newValue != null ? newValue.value : '';
        this.setState({[array]: values});
    };

    changeRowNumber = (array, name, i) => (e) => {
        let values = this.state[array];
        let line = values[i];
        const value = e.target.value;
        if(isNaN(value))
            return;
        line[name] = value;
        this.setState({[array]: values});
    };

    changeRowValue = (array, name, i) => (e) => {
        let values = this.state[array];
        let line = values[i];
        const value = e.target.value;
        line[name] = value;
        this.setState({[array]: values});
    };

    changeRowMulti = (array, name, data, i) => (e, newValue) => {
        let products = this.state[array];
        let line = products[i];
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        line[name] = newValue;
        line[data] = ids;
        this.setState({[array]: products});
    };

    changeBool(name){
        this.setState({[name]: !this.state[name]});
    };

    lotLinks = (lots) => {
        return (
            <Aux>
                {lots != null && lots.length > 0 && lots.map((row, i) =>
                    <Aux key={'lot' + i}>
                        {i > 0 &&
                            <Aux>&nbsp;&nbsp;</Aux>
                        }
                        <Link to={'/lot/' + row.value}>{row.label}</Link>
                    </Aux>
                )}
            </Aux>
            
        )
    }

    addDeviation = (e) => {
        this.props.history.push('/deviation?entityType=' + this.props.auth.constants.objectIds.recall + '&record=' + this.state._id);
    };

    loadCapas = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: this.props.auth.constants.objectIds.recall, value: this.state._id},
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let capaRes = await axios.get('/api/capas', {params: params});
        this.setState({
            capaActions: capaRes.data.data,
            totalCount: capaRes.data.totalCount
        });
    }

    addAction = (e) => {
        this.setState({editCapa: true})
    };

    closeDialog = (e) => {
        this.setState({
            editCapa: false,
            capaId: '',
            capaIndex: -1
        },() => {this.loadCapas();});
    };

    editCapa = (i) => (e) => {
        let capa = this.state.capaActions[i];
        this.setState({capaId: capa._id, editCapa: true, capaIndex: i});
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadCapas();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadCapas();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadCapas();
        });
    };

    render(){
        const t = this.props.t;
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.recall}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        {this.state.number != null && this.state.number !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.statusSel != null && 
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('status')}</FormLabel>
                                    {this.state.statusSel.label}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.locationSel}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locationSel', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]}
                                    label={t('location')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('location')}</FormLabel>
                                    {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.typeSel}
                                    options={this.state.types}
                                    onChange={this.changeAuto('typeSel', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]}
                                    label={t('type')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            } 
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('type')}</FormLabel>
                                    {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <DateSelect
                                    onChange={this.changeDate('initiatedDate')}
                                    value={this.state.initiatedDate}
                                    helperText={errors[t('initiatedDate')]}
                                    error={errors[t('initiatedDate')] != null ? true : false}
                                    label={t('initiatedOn')}
                                    disabled={!fieldAccess}
                                    format={this.props.auth.user.dateFormat}
                                    required
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('initiatedOn')}</FormLabel>
                                    <DateDisplay value={this.state.initiatedDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.initiatorSel}
                                    options={this.state.users}
                                    onChange={this.changeAuto('initiatorSel', 'initiator')}
                                    error={errors[t('initiator')] != null ? true : false}
                                    helperText={errors[t('initiator')]}
                                    label={t('initiator')}
                                    disabled={!fieldAccess}
                                    required
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('initiator')}</FormLabel>
                                    {this.state.initiatorSel != null ? this.state.initiatorSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <DateSelect
                                    onChange={this.changeDate('expectedCompletion')}
                                    value={this.state.expectedCompletion}
                                    helperText={errors[t('expectedCompletion')]}
                                    error={errors[t('expectedCompletion')] != null ? true : false}
                                    label={t('expectedEnd')}
                                    disabled={!fieldAccess}
                                    format={this.props.auth.user.dateFormat}
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('expectedEnd')}</FormLabel>
                                    <DateDisplay value={this.state.expectedCompletion} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        {this.state.actualCompletion != null && this.state.actualCompletion !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('completed')}</FormLabel>
                                    <DateDisplay value={this.state.actualCompletion} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item sm={12}>
                            {fieldAccess &&
                                <TextField value={this.state.description} onChange={this.changeValue} name="description"
                                multiline={true} maxRows="20" disabled={!fieldAccess}
                                size="medium" fullWidth={true} label={t('description')}
                                error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('description')}</FormLabel>
                                    {this.state.description}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item sm={12}>
                            {fieldAccess &&
                                <TextField value={this.state.riskAssessment} onChange={this.changeValue} name="riskAssessment"
                                multiline={true} maxRows="20"  disabled={!fieldAccess}
                                size="medium" fullWidth={true} label={t('riskAssessment')}
                                error={errors[t('riskAssessment')] != null ? true : false} helperText={errors[t('riskAssessment')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('riskAssessment')}</FormLabel>
                                    {this.state.riskAssessment}
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    <SectionTitle title={t('items')}/>
                    <Grid container spacing={3}>
                        <Grid item xs={8} sm={4}>{t('item')}</Grid>
                        <Grid item xs={12} sm={6}>{t('lots')}</Grid>
                    </Grid>
                    {this.state.products != null && this.state.products.length > 0 && this.state.products.map((row, i) =>
                        <Grid container spacing={3} key={row.item}>
                            <Grid item xs={8} sm={4}>
                                {fieldAccess &&
                                    <AutoCompleteField
                                        value={row.itemSel}
                                        options={this.state.items}
                                        onChange={this.changeLineAuto('itemSel', 'item', i)}
                                        error={errors[`${t('item')} (${t('line')} ${i})`] != null ? true : false}
                                        helperText={errors[`${t('item')} (${t('line')} ${i})`]}
                                        disabled={!fieldAccess}
                                        required
                                    />
                                }
                                {!fieldAccess &&
                                    <FormControl>
                                        <Link to={'/item/' + row.item}>{row.itemSel.label}</Link>
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {fieldAccess &&
                                    <AutoCompleteField
                                        multiple={true}
                                        value={row.lotsSel}
                                        options={row.itemLots}
                                        onChange={this.changeLineMulti('lotsSel', 'lots', i)}
                                        error={errors[`${t('lots')} (${t('line')} ${i})`] != null ? true : false}
                                        helperText={errors[`${t('lots')} (${t('line')} ${i})`]}
                                        disabled={!fieldAccess}
                                    />
                                }
                                {!fieldAccess && this.lotLinks(row.lotsSel)}
                            </Grid>
                            {fieldAccess &&
                                <Grid item sx={{width: 50}}>
                                    <IconButton aria-label="delete line" onClick={this.deleteLine(i)}>
                                        <RemoveCircleTwoToneIcon  />
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    )}
                    {fieldAccess &&
                        <Grid container spacing={3}>
                            <Grid item xs={8} sm={4}>
                                <AutoCompleteField
                                    value={this.state.itemSel}
                                    options={this.state.items}
                                    onChange={this.changeAuto('itemSel', 'item')}
                                    error={errors[t('item')] != null ? true : false}
                                    helperText={errors[t('item')]}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AutoCompleteField
                                    multiple={true}
                                    value={this.state.lotsSel}
                                    options={this.state.itemLots}
                                    onChange={this.changeMulti('lotsSel', 'lots')}
                                    error={errors[t('lots')] != null ? true : false}
                                    helperText={errors[t('lots')]}
                                    disabled={!fieldAccess}
                                />
                            </Grid>
                            <Grid item sx={{width: 50}}>
                                <IconButton aria-label="add line" onClick={this.addLine}>
                                    <AddBoxTwoToneIcon  />
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                    <br/>
                    {this.state.affectedLots != null && this.state.affectedLots.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('lots')}/>
                            <Grid container spacing={3} sx={{pb: 1}}>
                                <Grid item xs={4} sm={2}>{t('number')}</Grid>
                                <Grid item xs={8} sm={4}>{t('item')}</Grid>
                                <Grid item xs={4} sm={2} align="right">{t('remaining')}</Grid>
                            </Grid>
                            {this.state.affectedLots.map((row, i) =>
                                <Grid container spacing={3} key={'affLot' + i} sx={{pb: 2}}>
                                    <Grid item xs={4} sm={2}><Link to={'/lot/' + row._id}>{row.number}</Link></Grid>
                                    <Grid item xs={8} sm={4}><Link to={'/item/' + row.item._id}>{row.item.number + '-' + row.item.name}</Link></Grid>
                                    <Grid item xs={4} sm={2} align="right">
                                        <NumericFormat displayType={'text'} value={row.amount} thousandSeparator={true} suffix={row.amountUOM}/>
                                    </Grid>
                                </Grid>
                            )}
                        </Aux>
                    }
                    <br/>
                    <SectionTitle title={t('regulatoryNotifications')}/>
                    {this.state.regulatoryNotifications != null && this.state.regulatoryNotifications.length > 0 && this.state.regulatoryNotifications.map((row, i) =>
                        <Box borderColor="primary.main" {...defaultProps} component={'div'} key={'regNotif' + i}>
                            <Grid container spacing={3} key={row._id}>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={row.regulatorSel}
                                            options={this.state.regulators}
                                            onChange={this.changeRegAuto('regulatorSel', 'regulator', i)}
                                            error={errors[`${t('regulator')} (${t('line')} ${i})`] != null ? true : false}
                                            helperText={errors[`${t('regulator')} (${t('line')} ${i})`]}
                                            disabled={!fieldAccess}
                                            label={t('regulator')}
                                            required
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('regulator')}</FormLabel>
                                            {row.regulatorSel.label}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={row.notifierSel}
                                            options={this.state.users}
                                            onChange={this.changeRegAuto('notifierSel', 'notifier', i)}
                                            error={errors[`${t('notifier')} (${t('line')} ${i})`] != null ? true : false}
                                            helperText={errors[`${t('notifier')} (${t('line')} ${i})`]}
                                            disabled={!fieldAccess}
                                            label={t('notifier')}
                                            required
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('notifier')}</FormLabel>
                                            {row.notifierSel.label}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <DateSelect
                                            onChange={this.changeRegDate('date', i)}
                                            value={row.date}
                                            helperText={errors[`${t('date')} (${t('line')} ${i})`]}
                                            error={errors[`${t('date')} (${t('line')} ${i})`] != null ? true : false}
                                            disabled={!fieldAccess}
                                            format={this.props.auth.user.dateFormat}
                                            label={t('date')}
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('date')}</FormLabel>
                                            <DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <DateSelect
                                            onChange={this.changeRegDate('writtenDate', i)}
                                            value={row.writtenDate}
                                            helperText={errors[`${t('writtenDate')} (${t('line')} ${i})`]}
                                            error={errors[`${t('writtenDate')} (${t('line')} ${i})`] != null ? true : false}
                                            disabled={!fieldAccess}
                                            format={this.props.auth.user.dateFormat}
                                            label={t('writtenDate')}
                                        />
                                    }
                                    {!fieldAccess && row.writtenDate != null && row.writtenDate !== '' &&
                                        <FormControl>
                                            <FormLabel>{t('writtenDate')}</FormLabel>
                                            <DateDisplay value={row.writtenDate} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    <AutoCompleteField
                                        multiple={true}
                                        value={row.methodSel}
                                        options={this.state.methods}
                                        onChange={this.changeRegMulti('methodSel', 'method', i)}
                                        error={errors[`${t('method')} (${t('line')} ${i})`] != null ? true : false}
                                        helperText={errors[`${t('method')} (${t('line')} ${i})`]}
                                        disabled={!fieldAccess}
                                        label={t('method')}
                                    />
                                </Grid>
                                {fieldAccess &&
                                    <Grid item sx={{width: 50}}>
                                        <IconButton aria-label="delete line" onClick={this.deleteReg(i)}>
                                            <RemoveCircleTwoToneIcon  />
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    )}
                    {fieldAccess &&
                        <Box borderColor="primary.main" {...defaultProps} component={'div'}>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.regulatorSel}
                                        options={this.state.regulators}
                                        onChange={this.changeAuto('regulatorSel', 'regulator')}
                                        error={errors[t('regulator')] != null ? true : false}
                                        helperText={errors[t('regulator')]}
                                        disabled={!fieldAccess}
                                        label={t('regulator')}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.notifierSel}
                                        options={this.state.users}
                                        onChange={this.changeAuto('notifierSel', 'notifier')}
                                        error={errors[t('notifier')] != null ? true : false}
                                        helperText={errors[t('notifier')]}
                                        disabled={!fieldAccess}
                                        label={t('notifier')}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <DateSelect
                                        onChange={this.changeDate('date')}
                                        value={this.state.date}
                                        helperText={errors[t('date')]}
                                        error={errors[t('date')] != null ? true : false}
                                        disabled={!fieldAccess}
                                        format={this.props.auth.user.dateFormat}
                                        label={t('date')}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <DateSelect
                                        onChange={this.changeDate('writtenDate')}
                                        value={this.state.writtenDate}
                                        helperText={errors[t('writtenDate')]}
                                        error={errors[t('writtenDate')] != null ? true : false}
                                        disabled={!fieldAccess}
                                        format={this.props.auth.user.dateFormat}
                                        label={t('writtenDate')}
                                    />
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    <AutoCompleteField
                                        multiple={true}
                                        value={this.state.methodSel}
                                        options={this.state.methods}
                                        onChange={this.changeMulti('methodSel', 'method')}
                                        error={errors[t('method')] != null ? true : false}
                                        helperText={errors[t('method')]}
                                        disabled={!fieldAccess}
                                        label={t('method')}
                                    />
                                </Grid>
                                <Grid item sx={{width: 50}}>
                                    <IconButton aria-label="add line" onClick={this.addReg}>
                                        <AddBoxTwoToneIcon  />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {this.state.customers != null && this.state.customers.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('customers')}/>
                            {this.state.customers.map((row, i) => 
                                <Box borderColor="primary.main" {...defaultProps} component={'div'} key={'cust' + i}>
                                    <Grid container spacing={3} key={"cust" + i}>
                                        <Grid item xs={6} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('customer')}</FormLabel>
                                                <Link to={'/customer/' + row.customer._id}>{row.customer.name}</Link>
                                            </FormControl>
                                        </Grid>
                                        {row.item != null &&
                                            <Aux>
                                                <Grid item xs={6} sm={3}>
                                                    <FormControl>
                                                        <FormLabel>{t('item')}</FormLabel>
                                                        <Link to={'/item/' + row.item._id}>{row.item.number + '-' + row.item.name}</Link>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2} sm={1} align="right">
                                                    <FormControl>
                                                        <FormLabel>{t('quantity')}</FormLabel>
                                                        <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true} decimalScale={3} suffix={row.item.uom}/>
                                                    </FormControl>
                                                </Grid>
                                            </Aux>
                                        }
                                        <Grid item xs={4} sm={2}>
                                            {fieldAccess &&
                                                <DateSelect
                                                    onChange={this.changeRowDate('customers', 'notificationDate', i)}
                                                    value={row.notificationDate}
                                                    helperText={errors[`${t('notificationDate')} (${t('line')} ${i})`]}
                                                    error={errors[`${t('notificationDate')} (${t('line')} ${i})`] != null ? true : false}
                                                    disabled={!fieldAccess}
                                                    format={this.props.auth.user.dateFormat}
                                                    label={t('notified')}
                                                />
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('notified')}</FormLabel>
                                                    <DateDisplay value={row.notificationDate} format={this.props.auth.user.dateFormat}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={row.notifiedBySel}
                                                    options={this.state.users}
                                                    onChange={this.changeRowAuto('customers', 'notifiedBySel', 'notifiedBy', i)}
                                                    error={errors[`${t('notifiedBy')} (${t('line')} ${i})`] != null ? true : false}
                                                    helperText={errors[`${t('notifiedBy')} (${t('line')} ${i})`]}
                                                    disabled={!fieldAccess}
                                                    label={t('notifier')}
                                                />
                                            }
                                            {!fieldAccess && row.notifiedBySel != null &&
                                                <FormControl>
                                                    <FormLabel>{t('notifier')}</FormLabel>
                                                    {row.notifiedBySel.label}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            {fieldAccess &&
                                                <TextField type="number" value={row.returned} onChange={this.changeRowNumber('customers', 'returned', i)} name={"returned" + i}
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('returned')}
                                                error={errors[`${t('returned')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('returned')} (${t('line')} ${i})`]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl align="right">
                                                    <FormLabel>{t('returned')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={row.returned} thousandSeparator={true} decimalScale={3}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </Aux>
                    }
                    {fieldAccess && this.state._id != null && this.state._id !== '' &&
                        <Grid container spacing={3}>
                            <Grid item sm={10}>
                                <Button variant="outlined" color="primary" size="small" onClick={this.addDeviation}>{t('addDeviation')}</Button>
                            </Grid>
                        </Grid>
                    }
                     {this.state.deviations != null && this.state.deviations.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('deviations')}/>
                            <Grid container spacing={3} key="devhead">
                                <Grid item xs={4} sm={2}>{t('number')}</Grid>
                                <Grid item xs={6} sm={3}>{t('title')}</Grid>
                                <Grid item xs={4} sm={2}>{t('date')}</Grid>
                            </Grid>
                            {this.state.deviations.map((row, i) =>
                                <Grid container spacing={3} key={'dev' + i}>
                                    <Grid item xs={4} sm={2}>
                                        <Link to={'/deviation/' + row._id}>{row.number}</Link>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>{row.title}</Grid>
                                    <Grid item xs={4} sm={2}><DateDisplay value={row.occurrenceDate} format={this.props.auth.user.dateFormat}/></Grid>
                                </Grid>
                            )}
                            <br/>
                        </Aux>
                    }
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('correctiveMeasures')}/>
                            <TableContainer component={Paper}>
                                <Table aria-label="capaActions">
                                    <TableHead>
                                        <TableRow key="capaHeader">
                                            <TableCell>{t('number')}</TableCell>
                                            <TableCell>{t('title')}</TableCell>
                                            <TableCell>{t('dueDate')}</TableCell>
                                            <TableCell>{t('status')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.capaActions.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell>
                                                    <TextOnlyClickButton enabled={true} click={this.editCapa(i)} name={row.number}/>
                                                </TableCell>
                                                <TableCell>{row.title}</TableCell>
                                                <TableCell><DateDisplay value={row.dueDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                                <TableCell>{row.status}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow key="tableFooter">
                                            <TablePagination count={this.state.totalCount}
                                            rowsPerPage={this.state.listSize}
                                            onPageChange={this.handleChangePage}
                                            page = {this.state.page}
                                            rowsPerPageOptions={common.rowOptions}
                                            onRowsPerPageChange={this.handleRowsPerPage}/>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={3}>
                                <Grid item sm={8}>
                                    <Button variant="outlined" color="primary" size="small" disabled={!fieldAccess} onClick={this.addAction}>{t('add')}</Button>
                                </Grid>
                            </Grid>
                            {this.state.editCapa &&
                                <CapaAction
                                    reload={this.loadCapas}
                                    open={this.state.editCapa}
                                    onClose={this.closeDialog}
                                    recall={this.state._id}
                                    id={this.state.capaId}
                                    users={this.state.users}
                                    qas={this.state.qas}
                                    location={this.state.location}
                                    managers={this.state.managers}
                                />
                            }
                            <br/>
                        </Aux>
                    }
                    <FormControlLabel
                        control={<Switch checked={this.state.supplierIssue} onChange={() => this.changeBool('supplierIssue')} name="supplierIssue" />}
                        label={t('supplierIssue')} disabled={!fieldAccess}
                    />
                    {this.state.supplierIssue === true &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('suppliers')}/>
                            {this.state.suppliers != null && this.state.suppliers.length > 0 && this.state.suppliers.map((row, i) =>
                                <Box borderColor="primary.main" {...defaultProps} component={'div'} key={'supp' + i}>
                                     <Grid container spacing={3}>
                                        {fieldAccess &&
                                            <Grid item xs={8} sm={4}>
                                                <AutoCompleteField
                                                    value={row.supplierSel}
                                                    options={this.state.vendors}
                                                    onChange={this.changeRowAuto('suppliers', 'supplierSel', 'supplier', i)}
                                                    error={errors[`${t('supplier')} (${t('line')} ${i})`] != null ? true : false}
                                                    helperText={errors[`${t('supplier')} (${t('line')} ${i})`]}
                                                    disabled={!fieldAccess}
                                                    label={t('vendor')}
                                                />
                                            </Grid>
                                        }
                                        {!fieldAccess &&
                                            <Grid item xs={8} sm={4}>
                                                <FormControl>
                                                    <FormLabel>{t('vendor')}</FormLabel>
                                                    <Link to={'/vendor/' + row.supplier}>{row.supplierSel.label}</Link>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {fieldAccess &&
                                            <Grid item xs={8} sm={4}>
                                                <AutoCompleteField
                                                    value={row.itemSel}
                                                    options={this.state.items}
                                                    onChange={this.changeRowAuto('suppliers', 'itemSel', 'item', i)}
                                                    error={errors[`${t('supplierItem')} (${t('line')} ${i})`] != null ? true : false}
                                                    helperText={errors[`${t('supplierItem')} (${t('line')} ${i})`]}
                                                    disabled={!fieldAccess}
                                                    label={t('item')}
                                                />
                                            </Grid>
                                        }
                                        {!fieldAccess &&
                                            <Grid item xs={8} sm={4}>
                                                <FormControl>
                                                    <FormLabel>{t('item')}</FormLabel>
                                                    <Link to={'/item/' + row.item}>{row.itemSel.label}</Link>
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item xs={4} sm={2}>
                                            {fieldAccess &&
                                                <TextField type="text" value={row.lot} onChange={this.changeRowValue('suppliers', 'lot', i)} name={"supplierLot" + i}
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('lot')}
                                                error={errors[`${t('supplierLot')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('supplierLot')} (${t('line')} ${i})`]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('lot')}</FormLabel>
                                                    {row.lot}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            {fieldAccess &&
                                                <TextField type="number" value={row.quantityReturned} onChange={this.changeRowNumber('suppliers', 'quantityReturned', i)} 
                                                name={"quantityReturned" + i} size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')}
                                                error={errors[`${t('quantityReturned')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('quantityReturned')} (${t('line')} ${i})`]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl align="right">
                                                    <FormLabel>{t('quantity')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={row.quantityReturned} thousandSeparator={true} decimalScale={3}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            {fieldAccess &&
                                                <DateSelect
                                                    onChange={this.changeRowDate('suppliers', 'date', i)}
                                                    value={row.date}
                                                    helperText={errors[`${t('date')} (${t('line')} ${i})`]}
                                                    error={errors[`${t('date')} (${t('line')} ${i})`] != null ? true : false}
                                                    disabled={!fieldAccess}
                                                    format={this.props.auth.user.dateFormat}
                                                    label={t('date')}
                                                />
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('date')}</FormLabel>
                                                    <DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            {fieldAccess &&
                                                <DateSelect
                                                    onChange={this.changeRowDate('suppliers', 'confirmationDate', i)}
                                                    value={row.confirmationDate}
                                                    helperText={errors[`${t('confirmationDate')} (${t('line')} ${i})`]}
                                                    error={errors[`${t('confirmationDate')} (${t('line')} ${i})`] != null ? true : false}
                                                    disabled={!fieldAccess}
                                                    format={this.props.auth.user.dateFormat}
                                                    label={t('confirmed')}
                                                />
                                            }
                                            {!fieldAccess && row.confirmationDate != null && row.confirmationDate !== '' &&
                                                <FormControl>
                                                    <FormLabel>{t('confirmed')}</FormLabel>
                                                    <DateDisplay value={row.confirmationDate} format={this.props.auth.user.dateFormat}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={8} sm={4}>
                                            <AutoCompleteField
                                                multiple={true}
                                                value={row.methodSel}
                                                options={this.state.methods}
                                                onChange={this.changeRowMulti('suppliers', 'methodSel', 'method', i)}
                                                error={errors[`${t('suppMethod')} (${t('line')} ${i})`] != null ? true : false}
                                                helperText={errors[`${t('suppMethod')} (${t('line')} ${i})`]}
                                                disabled={!fieldAccess}
                                                label={t('method')}
                                            />
                                        </Grid>
                                        {fieldAccess &&
                                            <Grid item sx={{width: 50}}>
                                                <IconButton aria-label="delete line" onClick={this.deleteSupplier(i)}>
                                                    <RemoveCircleTwoToneIcon  />
                                                </IconButton>
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            )}
                            {fieldAccess &&
                                <Box borderColor="primary.main" {...defaultProps} component={'div'}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={8} sm={4}>
                                            <AutoCompleteField
                                                value={this.state.supplierSel}
                                                options={this.state.vendors}
                                                onChange={this.changeAuto('supplierSel', 'supplier')}
                                                error={errors[t('supplier')] != null ? true : false}
                                                helperText={errors[t('supplier')]}
                                                disabled={!fieldAccess}
                                                label={t('supplier')}
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={4}>
                                            <AutoCompleteField
                                                value={this.state.suppItemSel}
                                                options={this.state.items}
                                                onChange={this.changeAuto('suppItemSel', 'suppItem')}
                                                error={errors[t('suppItem')] != null ? true : false}
                                                helperText={errors[t('suppItem')]}
                                                disabled={!fieldAccess}
                                                label={t('item')}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <TextField type="text" value={this.state.suppLot} onChange={this.changeValue} name="suppLot"
                                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('lot')}
                                            error={errors[t('suppLot')] != null ? true : false} helperText={errors[t('suppLot')]}/>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <TextField type="number" value={this.state.suppReturned} onChange={this.changeNumber} 
                                            name={"suppReturned"} size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')}
                                            error={errors[t('suppReturned')] != null ? true : false} helperText={errors[t('suppReturned')]}/>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <DateSelect
                                                onChange={this.changeDate('suppDate')}
                                                value={this.state.suppDate}
                                                helperText={errors[t('suppDate')]}
                                                error={errors[t('suppDate')] != null ? true : false}
                                                disabled={!fieldAccess}
                                                format={this.props.auth.user.dateFormat}
                                                label={t('date')}
                                            />                                          
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <DateSelect
                                                onChange={this.changeDate('suppConfDate')}
                                                value={this.state.suppConfDate}
                                                helperText={errors[t('suppConfDate')]}
                                                error={errors[t('suppConfDate')] != null ? true : false}
                                                disabled={!fieldAccess}
                                                format={this.props.auth.user.dateFormat}
                                                label={t('confirmed')}
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={4}>
                                            <AutoCompleteField
                                                multiple={true}
                                                value={this.state.suppMethodSel}
                                                options={this.state.methods}
                                                onChange={this.changeMulti('suppMethodSel', 'suppMethod')}
                                                error={errors[t('suppMethod')] != null ? true : false}
                                                helperText={errors[t('suppMethod')]}
                                                disabled={!fieldAccess}
                                                label={t('method')}
                                            />
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <IconButton aria-label="add line" onClick={this.addSupplier}>
                                                <AddBoxTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                        </Aux>
                    }
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            <TextField value={this.state.completionDetails} onChange={this.changeValue} name="completionDetails"
                            multiline={true} maxRows="20" disabled={!fieldAccess}
                            size="medium" fullWidth={true} label={t('completionDetails')}
                            error={errors[t('completionDetails')] != null ? true : false} helperText={errors[t('completionDetails')]}/>
                        </Grid>
                    </Grid>
                    <br/>
                    <FormButtonStack
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.recall}
                        callback={this.loadRecord}
                        saveClick={() => this.save()}
                        status={this.state.status}
                        permissions={this.props.permission}
                        common={this.props.auth.common}
                        constants={this.props.auth.constants}
                        saveEnabled={this.state.enableSave}
                    />
                </Panel>
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.recall}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});
  
export default withTranslation() (connect(mapStateToProps)(Recall));