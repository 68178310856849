import React, { Component } from 'react';
import axios from 'axios';
import ConversionHelper from '../../helpers/conversionHelper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import DateSelect from '../UI/DateSelect/DateSelect';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import SectionTitle from '../UI/Typography/SectionTitle';
import { connect } from 'react-redux';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import IconButton from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { CSVDownload } from "react-csv";

class ScheduledActs extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            pageCount: 1,
            listSize: 10,
            date: '',
            endDate: '',
            activity: '',
            activities: [],
            location: '',
            locations: [],
            assignee: '',
            users: [],
            dangerDays: 2,
            status: '',
            statuses: [],
            incomplete: true,
            preDays: 7,
            postDays: -7,
            tenant: this.props.auth.user.tenant,
            orderBy: 'dueDate',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            locationSelected: null,
            activitySelected: null,
            assigneeSelected: null,
            statusSelected: null,
            showAssignee: true,
            includeComplete: false,
            activityDefs: [],
            activityDef: '',
            activityDefSel: null,
            csvData: null,
            headers: null
        };
    }

    async componentDidMount(){
        const csvSeparator = sessionStorage.getItem('csvSeparator');
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.activeLocation, common.cacheValues.object, common.cacheValues.user,
            common.cacheValues.scheduleStatus, common.cacheValues.batchActivityDef], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        const status = this.props.status;
        const user = this.props.assignee != null && this.props.assignee !== '' ? this.props.assignee : '';
        const incomplete = status !== '' ? false : true;
        let activity = this.props.activity;
        let activitySel = cacheValues.objects.find(x => x.value === activity);
        let startDate = this.props.startDate;
        if(startDate == null){
            startDate = ConversionHelper.dateSet(new Date(), this.state.preDays)
        }
        let endDate = this.props.endDate;
        if(endDate == null){
            endDate = ConversionHelper.dateSet(new Date(), this.state.postDays);
        }
        let assigneeSelected = cacheValues.users.find(x => x.value === user);
        let statusSelected = cacheValues.scheduleStatuses.find(x => x.value === status);
        this.setState({
            activities: cacheValues.objects,
            activity: activity,
            activitySelected: activitySel,
            locations: locations,
            statuses: cacheValues.scheduleStatuses,
            users: cacheValues.users,
            date: startDate,
            endDate: endDate,
            assignee: user,
            listSize: this.props.listSize ? this.props.listSize : 5,
            showAssignee: this.props.listSize,
            status: status,
            incomplete: incomplete,
            assigneeSelected: assigneeSelected,
            statusSelected: statusSelected,
            includeComplete: this.props.status != null && this.props.status !== '' ? true : false,
            activityDefs: cacheValues.batchActivityDefs,
            separator: csvSeparator != null ? csvSeparator : ','
        }, () => {
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        let sortName = this.state.orderBy;
        let ordDir = this.state.ordDir;
        let sort = {[sortName]: ordDir};
        let data = {
            size: this.state.listSize,
            offset: this.state.offset,
            date: this.state.date !== '' ? ConversionHelper.minDate(this.state.date).toUTCString() : new Date(),
            endDate: this.state.endDate !== '' ? ConversionHelper.maxDate(this.state.endDate).toUTCString() : new Date(),
            activity: this.state.activity,
            assignee: this.state.assignee,
            status: this.state.status,
            location: this.state.location,
            sort: JSON.stringify(sort),
            department: this.props.department,
            includeComplete: this.state.includeComplete,
            batchActivityDef: this.state.activityDef
        };
        const result = await axios.get('/api/schedules', {params: data});
        let list = result.data.data;
        list.forEach(row => {
            let text = ''
            if(row.sop != null){
                text = row.sop.name
            } else if (row.activityDefinition != null){
                text = row.activityDefinition.description;
            } else if(row.capaAction != null){
                text = "CAPA " + row.capaAction.number;
                if(row.deviation != null){
                    text = text + '-' + row.activity.text + '-' + row.deviation.number;
                } else if(row.ncr != null){
                    text = text + '-' + row.activity.text + '-' + row.ncr.number;
                } else if(row.employeeIllness != null){
                    text = text + '-' + row.activity.text + '-' + row.employeeIllness.number;
                } else if(row.incident != null){
                    text = text + '-' + row.activity.text + '-' + row.incident.number;
                } else if(row.loss != null){
                    text = text + '-' + row.activity.text + '-' + row.loss.number;
                } else if(row.injuryHazardous != null){
                    text = text + '-' + row.activity.text + '-' + row.injuryHazardous.number;
                }
            } else if(row.productionOrder != null && row.routing != null){
                text = row.routing.name;
            } else {
                text = row.activity.text
            }
            if(row.batch != null){
                text = text + ' - ' + row.batch.number;
            } else if (row.lot != null){
                text = text + ' - ' + row.lot.number;
            } else if (row.ebatch != null){
                text = text + ' - ' + row.ebatch.number;
            } else if(row.productionOrder != null){
                text = text + ' - ' + row.productionOrder.number;
            } else if (row.room != null){
                text = text + ' - ' + row.room.name;
            } else if (row.equipment != null){
                text = text + ' - ' + row.equipment.name
            } else if (row.location != null){
                text = text + ' - ' + row.location.name;
            }
            row.text = text;
        });
        this.setState({
            list: list,
            pageCount: Math.ceil(result.data.totalCount / this.state.listSize),
            orderBy: sortName,
            ordDir: ordDir,
            totalCount: result.data.totalCount
        });
    }

    csvClick = async () => {
        this.setState({csvData: null});
        let data = {
            date: this.state.date !== '' ? ConversionHelper.minDate(this.state.date).toUTCString() : new Date(),
            endDate: this.state.endDate !== '' ? ConversionHelper.maxDate(this.state.endDate).toUTCString() : new Date(),
            activity: this.state.activity,
            assignee: this.state.assignee,
            status: this.state.status,
            location: this.state.location,
            department: this.props.department,
            includeComplete: this.state.includeComplete,
            batchActivityDef: this.state.activityDef
        };
        let list = await axios.get('/api/schedulescsv', {params: data});
        let headers = list.data.headers;
        let csvData = list.data.data;
        this.setState({csvData: csvData, headers: headers});
    }

    changeAuto = (name, data) => (e, newValue) => {
        let activityDef = this.state.activityDef;
        let activityDefSel = this.state.activityDefSel;
        if(data === 'activity'){
            activityDef = '';
            activityDefSel = null;
        } else if (data === 'activityDef'){
            activityDef = newValue.value;
            activityDefSel = newValue;
        }
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            activityDef: activityDef,
            activityDefSel: activityDefSel,
            page: 0,
            offset: 0
        }, () => {this.loadRecords()});
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e, page: 0, offset: 0}, () => {this.loadRecords()});
    };

    sortClick(name) {
        let orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    }

    changeComplete = (e) => {
        this.setState({includeComplete: !this.state.includeComplete}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    searchClick(e){
        this.setState({offset: 0});
        this.loadRecords();
    }

    deriveStyle = row => {
        if(row.status._id === this.props.auth.constants.scheduleStatuses.signed || row.status._id === this.props.auth.constants.scheduleStatuses.reviewed)
            return '';
        var date = ConversionHelper.maxDate(new Date());
        var dangerDate = ConversionHelper.maxDate(new Date());
        dangerDate.setDate(dangerDate.getDate() - this.state.dangerDays);
        var dueDate = ConversionHelper.maxDate(row.dueDate);
        if(dueDate < dangerDate)
            return '#DC143C';
        else if(dueDate < date)
            return '#1434A4';
        else
            return '#000000';
    }

    incompleteCheck(){
        this.setState({incomplete: !this.state.incomplete})
    }

    render(){
        const t = this.props.t;
        return(
            <Aux>
                {this.props.title != null &&
                    <SectionTitle title={this.props.title}/>
                }
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <DateSelect
                            className="form-control"
                            id="date"
                            onChange={this.dateChange('date')}
                            value={this.state.date}
                            label={t('startDate')}
                            format={this.props.auth.user.dateFormat}
                            data-cy='startDate'
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <DateSelect
                            className="form-control"
                            id="endDate"
                            onChange={this.dateChange('endDate')}
                            value={this.state.endDate}
                            label={t('endDate')}
                            format={this.props.auth.user.dateFormat}
                            data-cy='endDate'
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                            value={this.state.activitySelected}
                            options={this.state.activities}
                            onChange={this.changeAuto('activitySelected', 'activity')}
                            label={t('activity')}
                            data-cy='activity'
                        />
                    </Grid>
                    {this.state.activity != null && this.state.activity === this.props.auth.constants.objectIds.batchActivity &&
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.activityDefSel}
                                options={this.state.activityDefs}
                                onChange={this.changeAuto('activityDefSel', 'activityDef')}
                                label={t('activityType')}
                                data-cy='batchActivity'
                            />
                        </Grid>
                    }
                    {this.state.showAssignee &&
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.assigneeSelected}
                                options={this.state.users}
                                onChange={this.changeAuto('assigneeSelected', 'assignee')}
                                label={t('assignee')}
                                data-cy='assignee'
                            />
                        </Grid>
                    }
                    <Grid item xs={4} sm={2}>
                        <AutoCompleteField
                            value={this.state.statusSelected}
                            options={this.state.statuses}
                            onChange={this.changeAuto('statusSelected', 'status')}
                            label={t('status')}
                            data-cy='status'
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                            <CheckboxField
                            checked={this.state.includeComplete} onChange={this.changeComplete}
                            label={t('completed')} name="includeComplete"
                            data-cy='includeCompleted'
                            />
                        </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="Activities" size={this.props.title != null && this.props.title !== '' ? 'small' : "medium"}>
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('dueDate')}
                                    active={this.state.orderBy === 'dueDate'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('dueDate')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>{t('activity')}</TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('assignee')}
                                    active={this.state.orderBy === 'assignee'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('assignee')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('status')}
                                    active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list != null && this.state.list !== '' ?
                                this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/scheduledActivity/' + row._id} name={'schedActLink' + i}className={this.deriveStyle(row)}>
                                            <DateDisplay format={this.props.auth.user.dateFormat} value={row.dueDate}/></Link>
                                        </TableCell>
                                        <TableCell style={{color: this.deriveStyle(row)}}>{row.text}</TableCell>
                                        <TableCell style={{color: this.deriveStyle(row)}}>
                                            {row.assignee != null ? row.assignee.firstName + ' ' + row.assignee.lastName : ''}
                                        </TableCell>
                                        <TableCell style={{color: this.deriveStyle(row)}}>{row.status.label}</TableCell>
                                    </TableRow>
                                ) : <TableRow><TableCell colSpan={4}>{t('noRecords')}</TableCell></TableRow>
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TableCell>
                                    <Tooltip title={t('exportToCsv')} >
                                        <IconButton
                                            disabled={false}
                                            component={'div'}
                                            onClick={this.csvClick}
                                            name='actionCsv'
                                        >
                                            <ExcelIcon fontSize='medium'/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                {this.state.csvData != null &&
                    <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.headers} separator={this.state.separator}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile
});

export default withTranslation() (connect(mapStateToProps)(ScheduledActs));
