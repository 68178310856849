import React from 'react';
import AutoComplete from '@mui/material/Autocomplete';
import TextField from '../TextField/TextField';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { lighten, styled } from '@mui/system';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85)
  }));
  
  const GroupItems = styled('ul')({
    padding: 0,
  });

export default function AutoCompleteField(props) {
    const { className, label, onChange, error, disabled, style, required, multiline, renderOption,
            minRows, value, options, helperText, multiple, fullWidth, stayOpen, groupBy, noneMessage } = props;
    
    return !stayOpen ?
            <AutoComplete
                multiple={multiple}
                defaultValue={multiple === true || multiple === undefined ? [] : null}
                value={value != null && value !== '' ? value : 
                       multiple === true || multiple === undefined ? [] : null}
                options={options || []}
                style={style}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={onChange}
                renderInput={(params) => <TextField {...params} helperText={helperText}
                    error={error} label={label} 
                    className={className}
                    required={required}
                    multiline={multiline}
                    minRows={minRows}
                />}
                getOptionDisabled={(option) => option.disabled == null ? false : option.disabled}
                disabled={disabled}
                fullWidth={fullWidth}
                required={required}
                renderOption={renderOption || null}
                freeSolo
                forcePopupIcon
                groupBy={(option) => option[groupBy]}
                renderGroup={groupBy != null ? (params) => (
                    <li key={params.key}>
                      <GroupHeader>{params.group === 'none' ? noneMessage : params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                ) : null}
            /> :
            <AutoComplete
                multiple={multiple}
                disableCloseOnSelect
                defaultValue={multiple === true || multiple === undefined ? [] : null}
                value={value != null && value !== '' ? value : 
                       multiple === true || multiple === undefined ? [] : null}  
                options={options || []}
                style={style}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.value === value.value || value.value === '' || 
                option.value === null || value.value === {} || option.value === []}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            sx={{ marginRight: 8, color: 'secondary.main' }}
                            checked={selected}
                        />
                        {option.label}
                    </li>
                )}
                onChange={onChange}
                renderInput={(params) => <TextField {...params} helperText={helperText}
                    error={error} label={label} 
                    className={className}
                    multiline={multiline}
                    minRows={minRows}
                />}
                disabled={disabled}
                fullWidth={fullWidth}
                required={required}
                freeSolo
                forcePopupIcon
                groupBy={(option) => option[groupBy]}
                renderGroup={groupBy != null ? (params) => (
                    <li key={params.key}>
                      <GroupHeader>{params.group === 'none' ? noneMessage : params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                ) : null}
            />
}
