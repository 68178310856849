import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Aux from '../../../hoc/Auxilary/Auxilary';
import RichText from '../../../hoc/RichText/RichText';

class ManagedDocumentTemplate extends Component{  
    body = (auth, data, docLink) => {
        const t = this.props.t;

        return (
            <Aux>
                {data.record != null && data.record.content != null &&
                    <RichText>{data.record.content}</RichText>
                }
                {data.record != null && data.record.links != null && data.record.links.length > 0 &&
                    <div>
                        <h3>{t('links')}</h3>
                        {data.record.links.map((row, i) =>
                            <div><a href={row.link} target="_blank" rel="noopener noreferrer">{row.name}</a> - {row.link}</div>
                        )}
                        <br/>
                    </div>
                }
                {data.record != null && data.record.relatedDocuments != null && data.record.relatedDocuments.length > 0 &&
                    <h3>{t('relatedDocuments')}</h3>
                }
                {data.record != null && docLink != null && data.record.relatedDocuments.map((row, i) =>
                    <div>
                        <button className="btn btn-link" onClick={docLink(row._id)}>{row.number + ' - ' + row.name}</button><br/>
                    </div>
                )}
                {/* {data.record != null && data.record.author != null &&
                    <div>
                        <br/>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="50%">
                                        <table>
                                            <tbody>
                                                <tr><td><b>{t('author')}</b></td></tr>
                                                <tr><td>
                                                    <img src={data.record.author.signature} alt={t('noSignature')} height={'80%'} width={'80%'}/>
                                                </td></tr>
                                                <tr><td>{data.record.author.user.firstName + ' ' + data.record.author.user.lastName}</td></tr>
                                                <tr><td><DateDisplay format={auth.user.dateFormat} value={data.record.author.date}/></td></tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    {data.record.signer != null &&
                                        <td>
                                            <table>
                                                <tbody>
                                                    <tr><td><b>{t('reviewer')}</b></td></tr>
                                                    <tr><td>
                                                        <img src={data.record.signer.signature} alt={t('noSignature')}height={'80%'} width={'80%'}/>
                                                    </td></tr>
                                                    <tr><td>{data.record.signer.user.firstName + ' ' + data.record.signer.user.lastName}</td></tr>
                                                    <tr><td><DateDisplay format={auth.user.dateFormat} value={data.record.signer.date}/></td></tr>
                                                </tbody>                                                
                                            </table>
                                        </td>
                                    }
                                </tr>
                            </tbody>                            
                        </table>
                    </div>
                } */}
            </Aux>
        );
    }

    render(){
        const auth = this.props.auth;
        const body = this.body(auth, this.props.data, this.props.docLink);
        return body;
    }
}

export default withTranslation() (ManagedDocumentTemplate);
