import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Signature from '../General/Signature';
import base64 from 'base-64';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import Unlock from '../General/Unlock';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SectionTitle from '../UI/Typography/SectionTitle';
import DateSelect from '../UI/DateSelect/DateSelect';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import ActionBar from '../UI/Buttons/ActionBar';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class VendorAudit extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            vendor: '',
            vendorSel: '',
            site: '',
            sites: [],
            siteSel: '',
            component: '',
            vendors: [],
            date: new Date(),
            type: '',
            types: [],
            typeSel: '',
            lead: '',
            leadSel: '',
            users: [],
            selectedUsers: [],
            userOptions: [],
            number: '',
            category: '',
            categories: [],
            selectedCategory: [],
            periodStart: '',
            periodEnd: '',
            purpose: '',
            scope: '',
            rationale: '',
            standard: '',
            standards: [],
            selectedStandards: [],
            documents: [],
            interviewees: [],
            companyBackground: '',
            proceduresReviewed: [],
            equipmentList: [],
            observations: '',
            rating: '',
            ratings: [],
            ratingSel: '',
            qas: [],
            managers: [],
            intervieweeId: '',
            intervieweeIndex: -1,
            firstName: '',
            lastName: '',
            role: '',
            interviewDate: '',
            interviewer: '',
            interviewerSel: '',
            editInterviewee: false,
            editProcedure: false,
            editEquipment: false,
            procedure: '',
            equipment: '',
            procedureIndex: -1,
            equipmentIndex: -1,
            signatureOpen: false,
            signed: false,
            showImage: false,
            imageData: '',
            schedule: null,
            status: this.props.auth.constants.statuses.new,
            statuses: [],
            statusSel: null,
            user: null,
            reviewer: null,
            signer: null,
            tenant: this.props.auth.user.tenant,
            department: '',
            departments: [],
            depSel: null,
            tabKey: 0,
            tabLabels: [
                {name: 'General', disabled: false},
                {name: 'Interviews', disabled: false},
                {name: 'Procedures', disabled: false},
                {name: 'Equipment', disabled: false}
            ],
            security: [],
            dialogOpen: false,
            enableSave: true,
            goBack: -1
        }

        this.sigpad = {};
        this.changeDate = this.changeDate.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.startChange = this.startChange.bind(this);
        this.endChange = this.endChange.bind(this);
        this.interviewDateChange = this.interviewDateChange.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.changeVendor = this.changeVendor.bind(this);
        this.changeUsers = this.changeUsers.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.changeStandards = this.changeStandards.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.vendor, common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        let vendors = cacheValues.vendors;
        let params = queryString.parse(this.props.location.search);
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        let goBack = -1;
        if(scheduleId != null)
            goBack = -2;
        let values = await axios.get('/api/vendorauditvalues');
        let schedule = null;
        if(scheduleId != null)
            schedule = await axios.get('/api/schedulebase/' + scheduleId);
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.inprogress);
        this.setState({
            vendors: vendors,
            vendor: schedule != null && schedule.data.vendor != null ? schedule.data.vendor : '',
            types: values.data.types,
            userOptions: values.data.users,
            qas: values.data.qas,
            managers: values.data.managers,
            categories: values.data.categories,
            ratings: values.data.ratings,
            standards: values.data.standards,
            statuses: cacheValues.statuses,
            schedule: scheduleId,
            departments: values.data.departments,
            statusSel: statusSel,
            security: this.props.permission,
            goBack: goBack
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord(values);
            }else {
                const crumbs = [
                    { path: '/#/vendoraudits', label: this.props.t('vendorAudits'), screen: this.props.auth.screenDefs.VendorAudits},
                    { path: 'active', label: this.props.t('vendorAudit'), screen: this.props.auth.screenDefs.VendorAudit}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    async loadRecord(values){
        let id = this.state._id != null && this.state._id !== '' ? this.state._id : this.props.match.params.id;
        let audit = await axios.get('/api/vendoraudit/' + id);
        let selectedUsers = [];
        let sites = await axios.get('/api/vendorsiteselect/' + audit.data.vendor);
        let users = values != null ? values.data.users : this.state.userOptions;
        for(let i = 0; i < audit.data.users.length; i++){
            for(let j = 0; j < users.length; j++){
                if(audit.data.users[i] === users[j].value){
                    selectedUsers.push(users[j]);
                    break;
                }
            }
        }
        let selectedStandards = [];
        let standards = values != null ? values.data.standards : this.state.standards;
        for(let i = 0; i < audit.data.standard.length; i++){
            for(let j = 0; j < standards.length; j++){
                if(audit.data.standard[i] === standards[j].value){
                    selectedStandards.push(standards[j]);
                    break;
                }
            }
        }
        let selectedCategory = [];
        let categories = values != null ? values.data.categories : this.state.categories;
        for(let i = 0; i < audit.data.category.length; i++){
            for(let j = 0; j < categories.length; j++){
                if(audit.data.category[i] === categories[j].value){
                    selectedCategory.push(categories[j]);
                    break;
                }
            }
        }
        let signed = audit.data.status === this.props.auth.constants.statuses.signed || 
            audit.data.status === this.props.auth.constants.statuses.reviewed;
        let statusSel = this.state.statuses.find(x => x.value === audit.data.status);
        let vendorSel = this.state.vendors.find(x => x.value === audit.data.vendor);
        let siteSel = sites.data.find(x => x.value === audit.data.site);
        let typeSel = this.state.types.find(x => x.value === audit.data.type);
        let leadSel = selectedUsers.find(x => x.value === audit.data.lead);
        let ratingSel = this.state.ratings.find(x => x.value === audit.data.rating);
        let depSel = audit.data.department != null ? this.state.departments.find(x => x.value === audit.data.department) : null;
        this.setState({
            _id: audit.data._id,
            vendor: audit.data.vendor,
            site: audit.data.site,
            date: audit.data.date,
            type: audit.data.type,
            lead: audit.data.lead,
            users: audit.data.users,
            selectedUsers: selectedUsers,
            number: audit.data.number,
            component: audit.data.component,
            category: audit.data.category,
            selectedCategory: selectedCategory,
            periodStart: audit.data.periodStart,
            periodEnd: audit.data.periodEnd,
            purpose: audit.data.purpose,
            scope: audit.data.scope,
            rationale: audit.data.rationale,
            standard: audit.data.standard,
            selectedStandards: selectedStandards,
            documents: audit.data.documents,
            interviewees: audit.data.interviewees,
            companyBackground: audit.data.companyBackground,
            proceduresReviewed: audit.data.proceduresReviewed,
            equipmentList: audit.data.equipmentList,
            observations: audit.data.observations,
            rating: audit.data.rating,
            signoffUser: audit.data.signoffUser,
            signoffDate: audit.data.signoffDate,
            signoffSignature: audit.data.signoffSignature,
            signed: signed,
            sites: sites.data,
            status: audit.data.status != null ? audit.data.status : this.props.auth.constants.statuses.inprogress,
            user: audit.data.user,
            reviewer: audit.data.reviewer,
            signer: audit.data.signer,
            statusSel: statusSel,
            vendorSel: vendorSel,
            siteSel: siteSel,
            typeSel: typeSel,
            leadSel: leadSel,
            ratingSel: ratingSel,
            department: audit.data.department,
            depSel: depSel
        }, () => {
            const crumbs = [
                { path: '/#/vendoraudits', label: this.props.t('vendorAudits'), screen: this.props.auth.screenDefs.VendorAudits},
                { path: 'active', label: this.props.t('vendorAudit') + ' - ' + audit.data.number, screen: this.props.auth.screenDefs.VendorAudit}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    changeDate(e){
        this.setState({date: e});
    }


    changeUsers(e, newValue) {
        let users = [];
        if(newValue != null){
            newValue.forEach(user => {
                users.push(user.value);
            })
        }
        this.setState({
            selectedUsers: newValue, 
            users: users
        })
    }

    changeStandards(e, newValue) {
        let standards = [];
        if(newValue != null){
            newValue.forEach(standard => {
                standards.push(standard.value);
            })
        }
        this.setState({
            selectedStandards: newValue, 
            standard: standards
        })
    }

    changeCategory(e, newValue) {
        let category = [];
        if(newValue != null){
            newValue.forEach(cat => {
                category.push(cat.value);
            })
        }
        this.setState({
            selectedCategory: newValue, 
            category: category
        })
    }
    
    changeVendor = async (e, newValue) => {
        let value = newValue != null ? newValue.value : null;
        let sites = await axios.get('/api/vendorsiteselect/' + value);
        this.setState({
            vendor: value,
            vendorSel:  newValue,
            sites: sites.data,
            site: ''
        })
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeValue(e){
        let name = e.target.name;
        let value = e.target.value;

        this.setState({
            [name]: value
        });
    }

    startChange(e){
        this.setState({periodStart: e});
    }

    endChange(e){
        this.setState({periodEnd: e});
    }

    interviewDateChange(e){
        this.setState({interviewDate: e});
    }

    saveForm = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveForm());
    }

    _saveForm = async () => {
        const t = this.props.t;
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        var docs = [];
        for(const doc of this.state.documents){
            docs.push({
                _id: doc._id,
                name: doc.name,
                type: doc.type
            })
        }
        const data = {
            _id: this.state._id,
            date: this.state.date,
            vendor: this.state.vendor,
            component: this.state.component,
            site: this.state.site,
            type: this.state.type,
            lead: this.state.lead,
            users: this.state.users,
            category: this.state.category,
            periodStart: this.state.periodStart,
            periodEnd: this.state.periodEnd,
            purpose: this.state.purpose,
            scope: this.state.scope,
            rationale: this.state.rationale,
            standard: this.state.standard,
            documents: docs,
            interviewees: this.state.interviewees,
            companyBackground: this.state.companyBackground,
            proceduresReviewed: this.state.proceduresReviewed,
            equipmentList: this.state.equipmentList,
            observations: this.state.observations,
            rating: this.state.rating,
            status: this.state.status,
            schedule: this.state.schedule,
            department: this.state.department
        }
        try {
            let result = await axios.post('/api/vendoraudit', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/vendoraudit/' + result.data.id);
            this.setState({_id: result.data.id, number: result.data.number, enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    }

    validateForm(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.vendor == null || state.vendor === '')
            errors[t('vendor')] = t('required');
        if(state.date == null || state.date === '')
            errors[t('date')] = t('required');
        if(state.site == null || state.site === '')
            errors[t('site')] = t('required');
        if(state.component == null || state.component === '')
            errors[t('component')] = t('required');
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(state.lead == null || state.lead === '')
            errors[t('lead')] = t('required');
        if(state.selectedUsers == null || state.selectedUsers.length === 0)
            errors[t('selectedUsers')] = t('required');
        if(state.category == null || state.category.length === 0)
            errors[t('category')] = t('required');
        if(state.standard == null || state.standard.length === 0)
            errors[t('standard')] = t('required');
        if(state.department == null || state.department === '')
            errors[t('department')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    editInterviewee(id){
        let person = null;
        let index = 0;
        for(const interviewee of this.state.interviewees){
            if(interviewee._id === id){
                person = interviewee;
                break;
            }
            index++;
        }
        if(person != null){
            let interviewerSel = this.state.selectedUsers.find(x => x.value === person.interviewer);
            this.setState({
                editInterviewee: true,
                firstName: person.firstName,
                lastName: person.lastName,
                role: person.role,
                interviewDate: person.date,
                interviewer: person.interviewer,
                interviewerSel: interviewerSel,
                intervieweeId: person._id,
                intervieweeIndex: index
            })
        }
    }

    addInterviewee(){
        this.clearInterviewee();
        this.setState({editInterviewee: true, intervieweeIndex: -1});
    }

    clearInterviewee(){
        this.setState({
            interviewId: '',
            firstName: '',
            lastName: '',
            role: '',
            interviewDate: '',
            interviewer: '',
            interviewerSel: ''
        })
    }

    closeInterviewee(){
        this.setState({
            editInterviewee: false,
            intervieweeIndex: -1
        })
    }

    hideInterviewee(){
        this.setState({editInterviewee: false});
    }

    saveInterviewee(){
        const valid = this.validateInterviewee();
        if(!valid)
            return;
        let interviewees = this.state.interviewees;
        let data = {
            _id: this.state.intervieweeId,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            role: this.state.role,
            date: this.state.interviewDate,
            interviewer: this.state.interviewer,
        }
        if(this.state.intervieweeIndex !== -1){
            interviewees[this.state.intervieweeIndex] = data;
        } else {
            interviewees.push(data);
        }
        this.setState({
            interviewees: interviewees,
        })
        this.clearInterviewee();
        this.closeInterviewee();
    }

    validateInterviewee(){
        const t = this.props.t;
        let errors = {};
        if(this.state.firstName == null || this.state.firstName === '')
            errors[t('firstName')] = t('required');
        if(this.state.lastName == null || this.state.lastName === '')
            errors[t('lastName')] = t('required');
        if(this.state.role == null || this.state.role === '')
            errors[t('role')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteInterviewee(){
        if(this.state.intervieweeIndex === -1)
            return;
        let interviewees = this.state.interviewees;
        interviewees.splice(this.state.intervieweeIndex, 1);
        this.setState({interviewees: interviewees});
        this.clearInterviewee();
    }

    editProcedure(i){
        let prod = this.state.proceduresReviewed[i];
        this.setState({
            editProcedure: true,
            procedure: prod,
            procedureIndex: i
        })
    }

    addProcedure(){
       this.setState({editProcedure: true, procedureIndex: -1});
    }

    clearProcedure(){
        this.setState({
            //editProcedure: false,
            procedure: '',
            //procedureIndex: -1
        })
    }

    hideProcedure(){
        this.setState({editProcedure: false});
    }

    saveProcedure(){
        const valid = this.validateProcedure();
        if(!valid)
            return;
        let procedures = this.state.proceduresReviewed;
        if(this.state.procedureIndex === -1)
            procedures.push(this.state.procedure);
        else {
            procedures[this.state.procedureIndex] = this.state.procedure;
        }
        this.setState({
            editProcedure: false,
            proceduresReviewed: procedures,
            procedure: ''
        })
    }

    validateProcedure(){
        let errors = {};
        const t = this.props.t;
        if(this.state.procedure == null || this.state.procedure === '')
            errors[t('procedure')] = this.props.t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteProcedure(){
        if(this.state.procedureIndex === -1)
            return;
        let procedures = this.state.proceduresReviewed
        procedures.splice(this.state.procedureIndex, 1);
        this.setState({
            proceduresReviewed: procedures,
            procedure: '',
            editProcedure: false,
            procedureIndex: -1
        })
    }

    editEquipment(i){
        let equip = this.state.equipmentList[i];
        this.setState({
            editEquipment: true,
            equipment: equip,
            equipmentIndex: i
        })
    }

    addEquipment(){
        this.setState({editEquipment: true, equipmentIndex: -1});
    }

    clearEquipment(){
        this.setState({
            editEquipment: false,
            equipment: '',
            equipmentIndex: -1

        })
    }

    hideEquipment(){
        this.setState({editEquipment: false});
    }

    deleteEquipment(){
        if(this.state.equipmentIndex === -1)
            return;
        let equipmentList = this.state.equipmentList
        equipmentList.splice(this.state.equipmentIndex, 1);
        this.setState({
            equipmentList: equipmentList,
            equipment: '',
            editEquipment: false,
            equipmentIndex: -1
        })
    }

    saveEquipment(){
        const valid = this.validateEquipment();
        if(!valid)
            return;
        let equipmentList = this.state.equipmentList;
        if(this.state.equipmentIndex === -1)
            equipmentList.push(this.state.equipment);
        else {
            equipmentList[this.state.equipmentIndex] = this.state.equipment;
        }
        this.setState({
            editEquipment: false,
            equipmentList: equipmentList,
            equipment: ''
        })
    }

    validateEquipment(){
        let errors = {};
        const t = this.props.t;
        if(this.state.equipment == null || this.state.equipment === '')
            errors[t('equipment')] = this.props.t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    refresh = e => {
        this.loadRecord();
    };

    handleFileUpload(file){
        let files = file.target.files;
        let reader = new FileReader();
        reader.readAsBinaryString(files[0]);
        reader.onload = () => {
            let body = {
                file: base64.encode(reader.result),
                fileName: files[0].name,
                type: files[0].type,
            };
          axios.post('/api/documents/add', body)
          .then(data => {
            let docs = this.state.documents;
            docs.push({
                _id: data.data.id,
                name: files[0].name,
                type: files[0].type
            });
            this.setState({
                documents: docs
            })
          })
          .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
          });
        }
    }

    viewDocument(id){
        for(let i = 0; i < this.state.documents.length; i++){
            if(this.state.documents[i]._id === id){
                window.open("data:application/octet-stream;base64," + this.state.documents[i].document);
                break;
            }
        }
    }

    viewImage(id){
        let imageData = '';
        for(var i = 0; i < this.state.documents.length; i++){
            if(this.state.documents[i]._id === id){
                imageData = this.state.documents[i].document;
                break;
            }
        }
        this.setState({
            showImage: true,
            imageData: imageData
        })
    }

    closeImage(){
        this.setState({
            showImage: false,
            imageData: ''
        })
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    toggleDialog = () => {
        let dialogOpen = this.state.dialogOpen;
        this.setState({ dialogOpen: !dialogOpen });
    };

    render(){
        const errors = this.props.errors;
        const signed = this.state.signed;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar goBack={this.state.goBack}>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.VendorAudit}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <Grid container spacing={2}>
                    <Grid item lg={9} md={12}>
                        <TabPanel value={this.state.tabKey} index={0}>
                            <SectionTitle title={t('general')} />
                            <Grid container spacing={4}>
                                <Grid item xs={6} sm={4}>
                                    <TextField value={this.state.statusSel != null ? this.state.statusSel.label : ''}  name="status"
                                    size="medium" fullWidth={true} disabled={true} label={t('status')}/>
                                </Grid>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={6} sm={3}>
                                        <TextField value={this.state.number}  name="number"
                                        size="medium" fullWidth={true} disabled={true} label={t('number')}/>
                                    </Grid>
                                }
                                <Grid item xs={7} sm={4}>
                                    <DateSelect
                                        onChange={this.changeDate}
                                        value={this.state.date}
                                        helperText={errors[t('date')]}
                                        error={errors[t('date')] != null ? true : false}
                                        disabled={signed}
                                        label={t('date')}
                                        format={this.props.auth.user.dateFormat}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.depSel}
                                        options={this.state.departments}
                                        onChange={this.changeAuto('depSel', 'department')}
                                        error={errors[t('department')] != null ? true : false}
                                        helperText={errors[t('department')]}
                                        label={t('department')}
                                        disabled={signed}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.vendorSel}
                                        options={this.state.vendors}
                                        onChange={this.changeVendor}
                                        error={errors[t('vendor')] != null ? true : false}
                                        helperText={errors[t('vendor')]}
                                        label={t('vendor')}
                                        disabled={signed}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6} sm={5}>
                                    <AutoCompleteField
                                        value={this.state.siteSel}
                                        options={this.state.sites}
                                        onChange={this.changeAuto('siteSel', 'site')}
                                        error={errors[t('site')] != null ? true : false}
                                        helperText={errors[t('site')]}
                                        label="Site"
                                        disabled={signed}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={10} sm={4}>
                                    <TextField value={this.state.component} onChange={this.changeValue} name="component"
                                    size="medium" fullWidth={true} disabled={signed} label={t('component')} required
                                    error={errors[t('component')] != null ? true : false} helperText={errors[t('component')]}/>
                                </Grid>
                                <Grid item xs={10} sm={6}>
                                    <AutoCompleteField
                                        value={this.state.typeSel}
                                        options={this.state.types}
                                        onChange={this.changeAuto('typeSel', 'type')}
                                        error={errors[t('type')] != null ? true : false}
                                        helperText={errors[t('type')]}
                                        label={t('type')}
                                        disabled={signed}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={10} sm={10}>
                                    <AutoCompleteField
                                        value={this.state.selectedUsers}
                                        options={this.state.userOptions}
                                        onChange={this.changeUsers}
                                        error={errors[t('selectedUsers')] != null ? true : false}
                                        helperText={errors[t('selectedUsers')]}
                                        label={t('users')}
                                        multiple={true}
                                        disabled={signed}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={10} sm={6} md={5}>
                                    <AutoCompleteField
                                        value={this.state.leadSel}
                                        options={this.state.selectedUsers}
                                        onChange={this.changeAuto('leadSel', 'lead')}
                                        error={errors[t('lead')] != null ? true : false}
                                        helperText={errors[t('lead')]}
                                        label={t('lead')}
                                        disabled={signed}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={10} sm={10}>
                                    <AutoCompleteField
                                        value={this.state.selectedCategory}
                                        options={this.state.categories}
                                        onChange={this.changeCategory}
                                        error={errors[t('category')] != null ? true : false}
                                        helperText={errors[t('category')]}
                                        label={t('category')}
                                        multiple={true}
                                        disabled={signed}
                                    />
                                </Grid>
                                <Grid item xs={10} sm={10}>
                                    <AutoCompleteField
                                        value={this.state.selectedStandards}
                                        options={this.state.standards}
                                        onChange={this.changeStandards}
                                        error={errors[t('standard')] != null ? true : false}
                                        helperText={errors[t('standard')]}
                                        label={t('standard')}
                                        multiple={true}
                                        disabled={signed}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6} sm={5}>
                                    <DateSelect
                                        onChange={this.startChange}
                                        value={this.state.periodStart}
                                        helperText={errors[t('periodStart')]}
                                        error={errors[t('periodStart')] != null ? true : false}
                                        disabled={signed}
                                        label={t('startDate')}
                                        format={this.props.auth.user.dateFormat}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={5}>
                                    <DateSelect
                                        onChange={this.endChange}
                                        value={this.state.periodEnd}
                                        helperText={errors[t('periodEnd')]}
                                        error={errors[t('periodEnd')] != null ? true : false}
                                        disabled={signed}
                                        label={t('endDate')}
                                        format={this.props.auth.user.dateFormat}
                                    />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField value={this.state.companyBackground} onChange={this.changeValue} name="companyBackground" multiline={true} rows="3"
                                    variant="outlined" size="medium" fullWidth={true} disabled={signed} label={t('background')}
                                    error={errors[t('companyBackground')] != null ? true : false} helperText={errors[t('companyBackground')]}/>
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField value={this.state.purpose} onChange={this.changeValue} name="purpose" multiline={true} rows="3"
                                    variant="outlined" size="medium" fullWidth={true} disabled={signed} label={t('purpose')}
                                    error={errors[t('purpose')] != null ? true : false} helperText={errors[t('purpose')]}/>
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField value={this.state.scope} onChange={this.changeValue} name="scope" multiline={true} rows="3"
                                    variant="outlined" size="medium" fullWidth={true} disabled={signed} label={t('scope')}
                                    error={errors[t('scope')] != null ? true : false} helperText={errors[t('scope')]}/>
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField value={this.state.rationale} onChange={this.changeValue} name="rationale" multiline={true} rows="3"
                                    variant="outlined" size="medium" fullWidth={true} disabled={signed} label={t('rationale')}
                                    error={errors[t('rationale')] != null ? true : false} helperText={errors[t('rationale')]}/>
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField value={this.state.observations} onChange={this.changeValue} name="observations" multiline={true} rows="3"
                                    variant="outlined" size="medium" fullWidth={true} disabled={signed} label={t('observations')}
                                    error={errors[t('observations')] != null ? true : false} helperText={errors[t('observations')]}/>
                                </Grid>
                                <Grid item md={4} xs={6}>
                                    <AutoCompleteField
                                        value={this.state.ratingSel}
                                        options={this.state.ratings}
                                        onChange={this.changeAuto('ratingSel', 'rating')}
                                        error={errors[t('rating')] != null ? true : false}
                                        helperText={errors[t('rating')]}
                                        label={t('rating')}
                                        disabled={signed}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={this.state.tabKey} index={1}>
                            <SectionTitle title={t('peopleInterviewed')} />
                            {this.state.interviewees != null && this.state.interviewees.length > 0 &&
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow key="peopleInterviewedTableHeader">
                                                <TableCell>{t('name')}</TableCell>
                                                <TableCell>{t('role')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.interviewees.map((row, i) =>
                                                <TableRow key={row._id}>
                                                    <TableCell>
                                                        <TextOnlyClickButton enabled={true} click={() => this.editInterviewee(row._id)} 
                                                        name={row.firstName + ' ' + row.lastName}/>
                                                    </TableCell>
                                                    <TableCell>{row.role}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                            {!this.state.editInterviewee && !this.state.signed &&
                                <Aux>
                                    <br />
                                    <Button variant="contained" size="small" color="secondary" onClick={() => this.addInterviewee()}
                                    disabled={signed}>{t('add')}</Button>
                                </Aux>
                            }
                            {this.state.editInterviewee &&
                                <Aux>
                                    <Dialog open={this.state.editInterviewee} onClose={() => this.hideInterviewee()} aria-labelledby="dialog-interiewee">
                                        <DialogTitle aria-label="dialog-interviewee-title">Add / Edit Interviewee</DialogTitle>
                                        <DialogContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <TextField value={this.state.firstName} onChange={this.changeValue} name="firstName"
                                                    size="medium" fullWidth={true} disabled={signed} label={t('firstName')} required
                                                    error={errors[t('firstName')] != null ? true : false} helperText={errors[t('firstName')]}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField value={this.state.lastName} onChange={this.changeValue} name="lastName"
                                                    size="medium" fullWidth={true} disabled={signed} label={t('lastName')} required
                                                    error={errors[t('lastName')] != null ? true : false} helperText={errors[t('lastName')]}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField value={this.state.role} onChange={this.changeValue} name="role"
                                                    size="medium" fullWidth={true} disabled={signed} label={t('role')} required
                                                    error={errors[t('role')] != null ? true : false} helperText={errors[t('role')]}/>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <DateSelect
                                                        onChange={this.interviewDateChange}
                                                        value={this.state.interviewDate}
                                                        helperText={errors[t('interviewDate')]}
                                                        error={errors[t('interviewDate')] != null ? true : false}
                                                        disabled={signed}
                                                        label={t('date')}
                                                        format={this.props.auth.user.dateFormat}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <AutoCompleteField
                                                        value={this.state.interviewerSel}
                                                        options={this.state.selectedUsers}
                                                        onChange={this.changeAuto('interviewerSel', 'interviewer')}
                                                        error={errors[t('interviewer')] != null ? true : false}
                                                        helperText={errors[t('interviewer')]}
                                                        label={t('interviewer')}
                                                        disabled={signed}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button variant="contained" size="small" color="primary" onClick={() => this.saveInterviewee()}
                                                disabled={signed}>{t('save')}</Button>&nbsp;&nbsp;
                                            <Button variant="contained" size="small" color="secondary" onClick={() => this.clearInterviewee()}>
                                                {t('clear')}
                                            </Button>&nbsp;&nbsp;
                                            {this.state.procedureIndex !== -1 && 
                                                <Aux>
                                                    <Button variant="contained" size="small" color="secondary" onClick={() => this.deleteInterviewee()}
                                                    disabled={signed}>{t('delete')}</Button>
                                                </Aux>
                                            }
                                        </DialogActions>
                                    </Dialog>
                                </Aux>
                            }
                        </TabPanel>
                        <TabPanel value={this.state.tabKey} index={2}>
                            <SectionTitle title={t('procedures')} />
                            {this.state.proceduresReviewed != null && this.state.proceduresReviewed.length > 0 &&
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow key="procedureReviewTableHeader">
                                                <TableCell>{t('name')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.proceduresReviewed.map((row, i) =>
                                                <TableRow key={row._id}>
                                                    <TableCell>
                                                        <TextOnlyClickButton enabled={true} click={() => this.editProcedure(i)} name={row}/>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                            {!this.state.editProcedure && !this.state.signed &&
                                <Aux>
                                    <br />
                                    <Button variant="contained" size="small" color="secondary" onClick={() => this.addProcedure()}
                                    disabled={signed}>{t('add')}</Button>
                                </Aux>
                            }
                            {this.state.editProcedure &&
                                <Aux>
                                    <Dialog open={this.state.editProcedure} onClose={() => this.hideProcedure()} aria-labelledby="dialog-interiewee">
                                        <DialogTitle aria-label="dialog-interviewee-title">{this.state.procedureIndex === -1 ? t('add') : t('edit')} 
                                        {t('procedure')}</DialogTitle>
                                        <DialogContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField value={this.state.procedure} onChange={this.changeValue} name="procedure"
                                                    size="medium" fullWidth={true} disabled={signed} label={t('name')} required
                                                    error={errors[t('procedure')] != null ? true : false} helperText={errors[t('procedure')]}/>
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button variant="contained" size="small" color="primary" onClick={() => this.saveProcedure()}
                                            disabled={signed}>{t('save')}</Button>
                                            <Button variant="contained" size="small" color="secondary" onClick={() => this.clearProcedure()}>{t('close')}</Button>
                                            <Button variant="contained" size="small" color="secondary" onClick={() => this.deleteProcedure()}
                                            disabled={signed}>{t('delete')}</Button>
                                        </DialogActions>
                                    </Dialog>
                                </Aux>
                            }
                        </TabPanel>
                        <TabPanel value={this.state.tabKey} index={3}>
                            <SectionTitle title={t('equipment')} />
                            {this.state.equipmentList != null && this.state.equipmentList.length > 0 &&
                                <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow key="equipmentInspectionTableHeader">
                                            <TableCell>{t('equipment')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.equipmentList.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell>
                                                    <TextOnlyClickButton enabled={true} click={() => this.editEquipment(i)} name={row}/>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            }
                            {!this.state.editEquipment &&
                                <Aux>
                                    <br />
                                    <Button variant="contained" size="small" color="secondary" onClick={() => this.addEquipment()}
                                    disabled={signed}>{t('add')}</Button>
                                </Aux>
                            }
                            {this.state.editEquipment &&
                                <Aux>
                                    <Dialog open={this.state.editEquipment} onClose={() => this.hideEquipment()} aria-labelledby="dialog-interiewee">
                                        <DialogTitle aria-label="dialog-interviewee-title">{this.state.equipmentIndex === -1 ? t('add') : t('edit')} 
                                        {t('equipment')}</DialogTitle>
                                        <DialogContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField value={this.state.equipment} onChange={this.changeValue} name="equipment"
                                                    size="medium" fullWidth={true} disabled={signed} label={t('name')} 
                                                    error={errors[t('equipment')] != null ? true : false} helperText={errors[t('equipment')]}/>
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button variant="contained" size="small" color="primary" onClick={() => this.saveEquipment()}
                                            disabled={signed}>{t('save')}</Button>
                                            <Button variant="contained" size="small" color="secondary" onClick={() => this.clearEquipment()}>{t('close')}</Button>
                                            <Button variant="contained" size="small" color="secondary" onClick={() => this.deleteEquipment()}
                                            disabled={signed}>{t('delete')}</Button>
                                        </DialogActions>
                                    </Dialog>
                                </Aux>
                            }
                        </TabPanel>
                        {this.state._id != null && this.state._id !== '' &&
                            <Aux>
                                <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.vendorAudit}/>
                                <ObjectLog id={this.state._id}/>
                            </Aux>
                        }
                    </Grid>
                    <Grid item lg={3} xs={12}>
                        {this.state._id != null && this.state._id !== '' &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-usersig"
                                    id="panel-usersig-header"
                                >
                                    <Typography>{t('preparedBy')}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Signature users={this.state.selectedUsers} name={t('preparedBy')}status={this.state.status}
                                    title={t('preparationSignature')} setId={this.refresh} object={this.state._id} signature={this.state.user}
                                    objectType={this.props.auth.constants.objectIds.vendorAudit} type={this.props.auth.constants.signatureTypes.user}
                                    user={this.props.auth.user.id}/>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {this.state.user != null && this.state.user._id != null && this.state.user.signature != null &&
                        this.state.user.signature !== '' &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1b-reviewersig"
                                    id="panel-reviewersig-header"
                                >
                                    <Typography>{t('reviewedBy')}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Signature users={this.state.managers} name={t('reviewer')} status={this.state.status}
                                    title={t('reviewSignature')} setId={this.refresh} object={this.state._id} signature={this.state.reviewer}
                                    objectType={this.props.auth.constants.objectIds.vendorAudit} type={this.props.auth.constants.signatureTypes.reviewer}
                                    user={this.props.auth.user.id}/>
                                </AccordionDetails>
                            </Accordion>
                        }
                        {this.state.reviewer != null && this.state.reviewer._id != null && this.state.reviewer.signature != null &&
                        this.state.reviewer.signature !== '' &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1c-approvalsig"
                                    id="panel-approvalsig-header"
                                >
                                    <Typography>{t('approvedBy')}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Signature users={this.state.qas} name={t('signoff')} status={this.state.status}
                                    title={t('signoffSignature')} setId={this.refresh} object={this.state._id} signature={this.state.signer}
                                    objectType={this.props.auth.constants.objectIds.vendorAudit} type={this.props.auth.constants.signatureTypes.signer}
                                    user={this.props.auth.user.id}/>
                                </AccordionDetails>
                            </Accordion>
                        }
                    </Grid>
                </Grid>
                <Grid item sm={5}>
                    {!signed &&
                        <Aux>
                            <Button variant="contained" size="small" color="primary" onClick={() => this.saveForm()}>
                                {t('save')}
                            </Button>&nbsp;&nbsp;
                        </Aux>
                        
                    }
                    {this.state.status === this.props.auth.constants.statuses.signed &&
                        <Aux>
                            <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.vendorAudit} callback={this.refresh}/>
                            <br/>
                        </Aux>
                    }
                </Grid>
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(VendorAudit));
