import React, { Component } from 'react';
import Chart from 'react-google-charts';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Aux from '../../hoc/Auxilary/Auxilary';
import { connect } from 'react-redux';
import SectionTitle from '../UI/Typography/SectionTitle';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { withTranslation } from 'react-i18next';

class BatchRoomScheduleWidget extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            chartData: null,
            chartHeight: 200,
            open: false,
            productionOrder: null,
            trimming: null,
            curing: null,
            drying: null,
            serviceRequest: null,
            batch: null,
            status: null
        }
    }

    componentDidMount(){
        this.loadRecords();
        this.chartEvents = [
            {
              eventName : 'select',
              callback: this.handleClick
            }
        ];
    }

    loadRecords = async () => {
        let list = await axios.get('/api/roomeqschedules/' + this.props.auth.common.scheduleTypes.growRoom);
        let data = this.generateChartData(list.data);
        let height = 0;
        height = (list.data.length * 20) + 120;
        this.setState({list: list.data, chartData: data, chartHeight: height});
    };

    generateChartData(list){
        let data = [
            [
                {type: 'string', id: 'Room/Equipment'}, 
                {type: 'string', id: 'Activity'}, 
                {type: 'date', id: 'Start'},
                {type: 'date', id: 'End'}
            ]
        ];
        list.forEach(row => {
            if(row.startDate < row.endDate){
                data.push([
                    row.room != null ? row.room.name : row.equipment.name,
                    row.batch != null ? row.batch.number : row.productionOrder != null ? row.productionOrder.number : row.drying != null ? 
                        row.drying.number : row.trimming != null ? row.trimming.number : row.curing != null ? row.curing.number : '',
                    new Date(row.startDate),
                    new Date(row.endDate)
                ]);
            }
        });
        return data;
    }

    handleClick = (chart) => {
        var selection = chart.chartWrapper.getChart().getSelection();
        var index = selection[0].row;
        let batch = this.state.list[index].batch;
        let status = this.state.list[index].status;
        let room = this.state.list[index].room;
        this.setState({batch: batch, status: status, room: room, open: true});
    };

    close = () => {
        this.setState({open: false});
    }

    render() {
        const t = this.props.t;
        return(
            <Aux>
                <SectionTitle title={t('roomSchedules')}/>
                {this.state.chartData != null &&
                    <Chart
                        width={'100%'}
                        height={this.state.chartHeight}
                        chartType="Timeline"
                        loader={<div>Loading Chart</div>}
                        data={this.state.chartData}
                        chartEvents={this.chartEvents}
                        rootProps={{ 'data-testid': '3' }}
                    />
                }
                <Dialog open={this.state.open} onClose={this.close} center aria-labelledby="batch-id" maxWidth="md" fullWidth>
                    <DialogTitle>
                        {this.state.batch != null &&
                            <SectionTitle title={this.state.batch.number + ' - ' + this.state.batch.description}/>
                        }
                    </DialogTitle>
                    <DialogContent>
                        {this.state.batch != null &&
                            <Aux>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('status')}</FormLabel>
                                            {this.state.status}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('stage')}</FormLabel>
                                            {this.state.batch.stage}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('strain')}</FormLabel>
                                            {this.state.batch.strain.name}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('room')}</FormLabel>
                                            {this.state.room != null ? this.state.room.name : ''}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('quantity')}</FormLabel>
                                            {this.state.batch.currentQuantity}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Aux>
                        }
                    </DialogContent>
                    <DialogActions>
                        {this.state.batch != null &&
                            <Aux>
                                <Button variant="contained" color="primary" href={'/#/evorabatch/' + this.state.batch._id}>{t('view')}</Button>&nbsp;&nbsp;
                                <Button variant="contained" color="secondary" onClick={this.close}>{t('close')}</Button>
                            </Aux>
                        }
                    </DialogActions>
                </Dialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default withTranslation() (connect(mapStateToProps)(withRouter(BatchRoomScheduleWidget)));