import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import MessagesSent from './MessagesSent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import TextField from '../UI/TextField/TextField';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class MessageList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            users: [],
            user: '',
            title: '',
            orderBy: 'createdOn',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            userSel: null,
            tabKey: 0,
            tabLabels: [{name: this.props.t('received'), disabled: false}, {name: this.props.t('sent'), disabled: false}]
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.user], this.props.auth.constants, null, this.props.auth.user.tenant)
        this.setState({users: cacheValues.users}, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('messages'), screen: this.props.auth.screenDefs.MessageList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
        await this.loadRecords();
    }

    loadRecords = async () => {
        var sort = {[this.state.orderBy]: this.state.ordDir};
        var data = {
            offset: this.state.offset,
            size: this.state.listSize,
            from: this.state.user,
            title: this.state.title,
            sort: JSON.stringify(sort)
        };
        var list = await axios.get('/api/messages/' + this.props.auth.user.id, {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        });
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value}, () => {this.loadRecords()});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar />
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabPanel value={this.state.tabKey} index={0}>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.title} onChange={this.changeValue} name="title"
                            size="medium" fullWidth={true} label={t('title')}/>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.userSel}
                                options={this.state.users}
                                onChange={this.changeAuto('userSel', 'user')}
                                label={t('from')}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Messages">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('title')}
                                        active={this.state.orderBy === 'title'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('message')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('sender')}
                                        active={this.state.orderBy === 'sender'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('from')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Recipients</TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('createdOn')}
                                        active={this.state.orderBy === 'createdOn'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>
                                            {t('date')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        {!row.read && 
                                            <TableCell><strong><Link to={'/message/' + row._id}>{row.title}</Link></strong></TableCell>
                                        }   
                                        {!row.read && 
                                            <TableCell><strong>{row.sender.firstName + ' ' + row.sender.lastName}</strong></TableCell>
                                        }
                                        {!row.read && 
                                            <TableCell><strong>{row.recipientString}</strong></TableCell>
                                        }
                                        {!row.read && 
                                            <TableCell><strong><DateDisplay format="yyyy/MM/dd hh:mm" value={row.createdOn}/></strong>
                                            </TableCell>
                                        }
                                        {row.read &&
                                            <TableCell><Link to={'/message/' + row._id}>{row.title}</Link></TableCell>
                                        }
                                        {row.read &&
                                            <TableCell>{row.sender.firstName + ' ' + row.sender.lastName}</TableCell>
                                        }
                                        {row.read &&
                                            <TableCell>{row.recipientString}</TableCell>
                                        }
                                        {row.read &&
                                            <TableCell><DateDisplay format="yyyy/MM/dd hh:mm" value={row.createdOn}/></TableCell>
                                        }
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <IconButton aria-label="add record" href="/#/message">
                                        <AddBoxTwoToneIcon  />
                                    </IconButton>
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </TabPanel>
                <TabPanel value={this.state.tabKey} index={1}> 
                    <MessagesSent user={this.props.auth.user.id} users={this.state.users}/>
                </TabPanel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({auth: state.auth});

export default withTranslation() (connect(mapStateToProps)(MessageList));
