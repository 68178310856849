import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI//DateDisplay/DateDisplay';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import SectionTitle from '../UI/Typography/SectionTitle';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import { CSVDownload } from "react-csv";
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import IconButton from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

class Ncrs extends Component {
    constructor(props){
        super(props);

        this.state = {
            ncrs: [],
            offset: 0,
            listSize: this.props.listSize != null ? this.props.listSize : 5,
            pageCount: 1,
            search: '',
            date: null,
            location: '',
            locations: [],
            department: '',
            departments: [],
            orderBy: 'occurrenceDate',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            depSel: null,
            statuses: [],
            status: '',
            statusSel: null,
            csvData: null,
            headers: null
        }
        this.dateChange = this.dateChange.bind(this);
        this.loadRecords = this.loadRecords.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);

    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location, common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        if(!this.props.auth.isAuthenticated) this.props.history.push('/login');
        const values = await axios.get('/api/ncrlistvalues');
        let storage = sessionStorage.getItem('ncrs');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        this.setState({
            locations: cacheValues.locations,
            departments: values.data.departments,
            statuses: cacheValues.statuses,
            security: this.props.permission,
            ffset: storage != null ? storage.offset : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'occurrenceDate',
            ordDir: storage != null ? storage.ordDir : -1,
            status: storage != null ? storage.status : '',
            statusSel: storage != null ? storage.statusSel : null,
            department: storage != null ? storage.department : '',
            depSel: storage != null ? storage.depSel : null,
            search: storage != null ? storage.search : '',
            date: storage != null && storage.date != null && storage.date !== '' ? new Date(storage.date) : '',
            page: storage != null ? storage.page : 0
        });
        this.loadRecords();
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            date: this.state.date,
            search: this.state.search,
            department: this.state.department,
            depSel: this.state.depSel,
            status: this.state.status,
            statusSel: this.state.statusSel,
            page: this.state.page
        };
        sessionStorage.setItem('ncrs', JSON.stringify(data));
    }

    loadRecords(){
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: 'number', value: this.state.search},
            {id: 'title', value: this.state.title},
            {id: 'status', value: this.state.status},
            {id: 'location', value: this.state.location},
            {id: 'type', value: this.state.type},
            {id: 'department', value: this.state.department},
            {id: 'occurrenceDate', value: [this.state.date, null]}
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        var url = '/api/ncrs';
        axios.get(url, {params: params})
        .then(list => {
            this.setState({
                ncrs: list.data.data,
                pageCount: Math.ceil(list.data.totalCount / this.state.listSize),
                totalCount: list.data.totalCount
            });
            this.saveFilters();
        })
        .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
        })
    }

    csvClick = async () => {
        this.setState({csvData: null});
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: 'number', value: this.state.search},
            {id: 'title', value: this.state.title},
            {id: 'status', value: this.state.status},
            {id: 'location', value: this.state.location},
            {id: 'type', value: this.state.type},
            {id: 'department', value: this.state.department},
            {id: 'occurrenceDate', value: [this.state.date, null]}
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
        };
        let result = await axios.get('/api/ncrscsv', {params: params});
        this.setState({csvData: result.data.data, headers: result.data.headers});
    }

    handleSearchChange(e){
        this.setState({search: e.target.value, page: 0, offset: 0}, () => this.loadRecords());
    }

    dateChange(e){
        this.setState({date: e, page: 0, offset: 0}, () => this.loadRecords());
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                {this.props.title != null &&
                    <SectionTitle title={this.props.title}/>
                }
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <TextField value={this.state.search} onChange={this.handleSearchChange} name="search" size="medium" className="form-control"
                        label={t('title')}/>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <DateSelect
                            onChange={this.dateChange}
                            value={this.state.date}
                            label={t('date')}
                            format={this.props.auth.user.dateFormat}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <AutoCompleteField
                            value={this.state.depSel}
                            options={this.state.departments}
                            onChange={this.changeAuto('depSel', 'department')}
                            label={t('department')}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <AutoCompleteField
                            value={this.state.statusSel}
                            options={this.state.statuses}
                            onChange={this.changeAuto('statusSel', 'status')}
                            label={t('status')}
                        />
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="NCR Reports" size={this.props.title != null ? 'small' : 'medium'}>
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('number')}
                                    active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('title')}
                                    active={this.state.orderBy === 'title'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('title')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>{t('relatedTo')}</TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('department')}
                                    active={this.state.orderBy === 'department'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('department')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('occurrenceDate')}
                                    active={this.state.orderBy === 'occurrenceDate'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('occurred')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('status')}
                                    active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.ncrs.map((ncr, i) =>
                                <TableRow key={ncr._id}>
                                    <TableCell><Link to={'/ncr/' + ncr._id} name={'ncrLink' + i}>{ncr.number}</Link></TableCell>
                                    <TableCell>{ncr.title}</TableCell>
                                    <TableCell>
                                        {ncr.entityType}
                                    </TableCell>
                                    <TableCell>{ncr.department}</TableCell>
                                    <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={ncr.occurrenceDate}/></TableCell>
                                    <TableCell>{ncr.status}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TableCell>
                                    <Tooltip title={t('exportToCsv')} >
                                        <IconButton
                                            disabled={false}
                                            component={'div'}
                                            onClick={this.csvClick}
                                            name='actionCsv'
                                        >
                                            <ExcelIcon fontSize='medium'/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                {this.state.csvData != null &&
                    <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.headers} separator={this.state.separator}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile
});

export default withTranslation() (connect(mapStateToProps)(Ncrs));
