import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from '../../../hoc/Auxilary/Auxilary';
import axios from 'axios';
import GeneratePdf from '../../General/GeneratePdf';
import ReactDOMServer from 'react-dom/server';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';
import { NumericFormat } from 'react-number-format';
import ConversionHelper from '../../../helpers/conversionHelper';

function ComponentToPrint(props){
    const { data, t, dateFormat, logo } = props;
    return(
        <Aux>
             <div style={{ fontFamily: 'Open Sans', padding: '20px', width: '1000px' }}>
                <span style={{ display: 'block', fontSize: '16pt', fontWeight: 'bold', 
                               textAlign: 'center', color: '#333', marginBottom: '20px', textDecoration: 'underline' }}>{t('purchaseOrder')}</span>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                    <div style={{ width: '45%' }}>
                        {logo != null &&
                            <img src={"data:application/octet-stream;base64," + logo} alt='tenant' height="100px"/>
                        }
                    </div>
                    <div style={{ width: '45%'}}>
                        <br/>
                        <br/>
                        <br/>
                        <span style={{width: "100px", display: 'inline-block'}}/>
                        <span style={{ width: '200px', display: 'inline-block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                            <strong>{t('purchaseOrderInvoice') + ': '}</strong>
                        </span>
                        <span style={{display: 'inline-block', textAlign: 'right', width: '130px'}}>
                            {data.number}
                        </span>
                        <br/>
                        <span style={{width: "100px", display: 'inline-block'}}/>
                        <span style={{ width: '100px', display: 'inline-block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                            <strong>{t('date') + ': '}</strong>
                        </span>
                        <span style={{display: 'inline-block', textAlign: 'right', width: '230px'}}>
                            <DateDisplay value={data.date} format={dateFormat}/>
                        </span>
                        <br/>
                    </div>
                </div>
                <br/>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                    <div style={{ width: '45%' }}>
                        <span style={{ display: 'block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}><strong>{t('purchaser')}</strong></span>
                        <span style={{ display: 'block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>{data.location != null ? data.location.name : ''}</span>
                        {data.vendor != null && data.vendor.licenseNumber != null && data.vendor.licenseNumber !== '' &&
                         data.location != null && data.location.license != null && data.location.license !== '' &&
                            <span style={{ display: 'block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                                <strong>{t('License#')}:</strong>&nbsp;{data.location.license}
                            </span>
                        }
                        <span style={{ display: 'block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                            {data.location != null ? data.location.street1 : ''}
                        </span>
                        {data.location != null && data.location.street2 != null && data.location.street2 !== '' &&
                            <span style={{ display: 'block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                                {data.location.street2}
                            </span>
                        }
                        <span style={{ display: 'block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                            {data.location != null ? data.location.city : ''},&nbsp;
                            {data.location != null && data.location.prov != null ? data.location.prov.name : ''}
                        </span>
                        <span style={{ display: 'block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                            {data.location != null ? data.location.postal : ''}
                        </span>
                        {data.location != null && data.location.country != null &&
                            <span style={{ display: 'block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                                {data.location.country.name}
                            </span>
                        }
                        {data.location != null && data.location.gstNumber != null  &&
                            <span style={{ display: 'block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                                <strong>{t('gst')}:</strong>&nbsp;{data.location.gstNumber}
                            </span>
                        }
                    </div>
                    <div style={{ width: '45%' }}>
                        <span style={{width: "100px", display: 'inline-block'}}/>
                        <span style={{ width: '200px', display: 'inline-block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                            <strong>{t('vendor') + ': '}</strong>
                        </span>
                        <br/>
                        <span style={{width: "100px", display: 'inline-block'}}/>
                        <span style={{ width: '200px', display: 'inline-block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>{data.vendor != null ? data.vendor.name : ''}</span>
                        {data.vendor != null && data.vendor.licenseNumber != null && data.vendor.licenseNumber !== '' &&
                            <Aux>
                                <br/>
                                <span style={{width: "100px", display: 'inline-block'}}/>
                                <span style={{ display: 'inline-block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                                    <strong>{t('License#')}:</strong>&nbsp;{data.vendor.licenseNumber}
                                </span>
                            </Aux>
                        }
                        <br/>
                        <span style={{width: "100px", display: 'inline-block'}}/>
                        <span style={{ display: 'inline-block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                            {data.vendor != null && data.vendor.address != null && data.vendor.address.address1 != null ? data.vendor.address.address1 : ''}
                        </span>
                        {data.address != null && data.vendor != null && data.vendor.address != null && data.vendor.address.address2 != null && data.vendor.address.address2 !== '' &&
                            <Aux>
                                <br/>
                                <span style={{width: "100px", display: 'inline-block'}}/>
                                <span style={{ display: 'inlin-block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                                    {data.vendor.address.address2}
                                </span>
                            </Aux>
                        }
                        <br/>
                        <span style={{width: "100px", display: 'inline-block'}}/>
                        <span style={{ display: 'inlin-block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                            {data.vendor != null && data.vendor.address != null ? data.vendor.address.city : ''},&nbsp;
                            {data.vendor != null && data.vendor.address != null && data.vendor.address.prov != null ? data.vendor.address.prov.name : ''}
                        </span>
                        <br/>
                        <span style={{width: "100px", display: 'inline-block'}}/>
                        <span style={{ display: 'inline-block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                            {data.vendor != null && data.vendor.address != null ? data.vendor.address.postal : ''}
                        </span>
                        {data.vendor != null && data.vendor.address != null && data.vendor.address.country != null &&
                            <Aux>
                                <br/>
                                <span style={{width: "100px", display: 'inline-block'}}/>
                                <span style={{ display: 'inline-block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                                    {data.vendor.address != null && data.vendor.address.country != null ? data.vendor.address.country.name : ''}
                                </span>
                            </Aux>
                        }
                        <br/>
                        <span style={{width: "100px", display: 'inline-block'}}/>
                        <span style={{ display: 'inline-block', fontSize: '12pt', textAlign: 'left', marginBottom: '0px'}}>
                            {data.vendor != null && data.vendor.phoneNumber != null &&<NumericFormat displayType={'text'} value={data.phoneNumber} format='###-###-####'/>}
                        </span>
                    </div>
                </div>
                <br/>
                <span style={{ display: 'block', fontSize: '12pt', fontWeight: 'bold'}}>{t('contents')}</span>
                <table style={{ width: '100%', borderCollapse: 'collapse'}}>
                    <thead>
                        <tr>
                            <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt', textAlign: "left", width: '100px'}} >{t('itemNumber')}</th>
                            <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt', textAlign: "left"}} >{t('itemName')}</th>
                            <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt', textAlign: "center" }} >{t('quantity')}</th>
                            <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt', textAlign: "center" }} >{t('unitCost')}</th>
                            <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt', textAlign: "center" }} >{t('tax')}</th>
                            <th style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '10pt', textAlign: "center" }} >{t('lineCost')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.contents != null && data.contents.map((row, i) => 
                            <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt'}}>
                                    {row.item != null && row.item.sku != null && row.item.sku !== '' ? row.item.sku :
                                     row.item != null && row.item.number != null && row.item.number !== '' ? row.item.number : ''
                                    }
                                </td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt'}}>{row.item != null ? row.item.name : ''}</td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt', textAlign: 'right', width: '110px' }} >
                                    <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true} 
                                            decimalScale={2} suffix={row.uom}/>
                                </td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt', textAlign: 'right', width: '110px' }} >
                                    <NumericFormat displayType={'text'} value={row.unitCost} thousandSeparator={true} fixedDecimalScale={true}
                                            decimalScale={2} prefix='$'/>
                                </td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt', textAlign: 'right', width: '110px' }} >
                                    <NumericFormat displayType={'text'} value={row.tax} thousandSeparator={true} fixedDecimalScale={true}
                                            decimalScale={2} prefix='$'/>
                                </td>
                                <td style={{ padding: '5px', border: '1px solid rgb(187, 186, 186)', fontSize: '9pt', textAlign: 'right', width: '110px' }} >
                                    <NumericFormat displayType={'text'} value={row.lineCost} thousandSeparator={true} fixedDecimalScale={true}
                                            decimalScale={2} prefix='$'/>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <br/>
                <br/>
                {data.shippingCost != null && data.shippingCost !== 0 &&
                    <Aux>
                        <span style={{ display: 'inline-block', width: 620}}/>
                        <span style={{ display: 'inline-block', width: 120}}><strong>{t('shipping')}</strong></span>
                        <span style={{ display: 'inline-block', width: 220, textAlign: 'right'}}>
                            <NumericFormat displayType={'text'} value={data.shippingCost} thousandSeparator={true} decimalScale={2} prefix='$' fixedDecimalScale={true}/>
                        </span>
                        <br/>
                    </Aux>
                }
                {data.serviceFee != null && data.serviceFee !== 0 &&
                    <Aux>
                        <span style={{ display: 'inline-block', width: 620}}/>
                        <span style={{ display: 'inline-block', width: 120}}><strong>{t('serviceFee')}</strong></span>
                        <span style={{ display: 'inline-block', width: 220, textAlign: 'right'}}>
                            <NumericFormat displayType={'text'} value={data.serviceFee} thousandSeparator={true} decimalScale={2} prefix='$' fixedDecimalScale={true}/>
                        </span>
                        <br/>
                    </Aux>
                }
                <span style={{ display: 'inline-block', width: 620}}/>
                <span style={{ display: 'inline-block', width: 120}}><strong>{t('subTotal')}</strong></span>
                <span style={{ display: 'inline-block', width: 220, textAlign: 'right'}}>
                    <NumericFormat displayType={'text'} value={data.amount} thousandSeparator={true} decimalScale={2} prefix='$' fixedDecimalScale={true}/>
                </span>
                <br/>
                {data.hstAmount != null && data.hstAmount !== 0 && 
                    <Aux>
                        <span style={{ display: 'inline-block', width: 620}}/>
                        <span style={{ display: 'inline-block', width: 120}}><strong>{t('hst')}</strong></span>
                        <span style={{ display: 'inline-block', width: 220, textAlign: 'right'}}>
                            <NumericFormat displayType={'text'} value={data.hstAmount} thousandSeparator={true} decimalScale={2} prefix='$' fixedDecimalScale={true}/>
                        </span>
                        <br/>
                    </Aux>
                }
                {data.gstAmount != null && data.gstAmount !== 0 && 
                    <Aux>
                        <span style={{ display: 'inline-block', width: 620}}/>
                        <span style={{ display: 'inline-block', width: 120}}><strong>{t('gst')}</strong></span>
                        <span style={{ display: 'inline-block', width: 220, textAlign: 'right'}}>
                            <NumericFormat displayType={'text'} value={data.gstAmount} thousandSeparator={true} decimalScale={2} prefix='$' fixedDecimalScale={true}/>
                        </span>
                        <br/>
                    </Aux>
                }
                {data.pstAmount != null && data.pstAmount !== 0 && 
                    <Aux>
                        <span style={{ display: 'inline-block', width: 620}}/>
                        <span style={{ display: 'inline-block', width: 120}}><strong>{t('pst')}</strong></span>
                        <span style={{ display: 'inline-block', width: 220, textAlign: 'right'}}>
                            <NumericFormat displayType={'text'} value={data.pstAmount} thousandSeparator={true} decimalScale={2} prefix='$' fixedDecimalScale={true}/>
                        </span>
                        <br/>
                    </Aux>
                }
                <span style={{ display: 'inline-block', width: 620}}/>
                <span style={{ display: 'inline-block', width: 120}}><strong>{t('taxes')}</strong></span>
                <span style={{ display: 'inline-block', width: 220, textAlign: 'right'}}>
                    <NumericFormat displayType={'text'} value={data.taxTotal} thousandSeparator={true} decimalScale={2} prefix='$' fixedDecimalScale={true}/>
                </span>
                <br/>
                <span style={{ display: 'inline-block', width: 620}}/>
                <span style={{ display: 'inline-block', width: 120}}><strong>{t('total')}</strong></span>
                <span style={{ display: 'inline-block', width: 220, textAlign: 'right'}}>
                    <NumericFormat displayType={'text'} value={data.totalAmount} thousandSeparator={true} decimalScale={2} prefix='$' fixedDecimalScale={true}/>
                </span>
                <br/>
                <br/>
            </div>
        </Aux>
    )
}

class PurchaseOrderInvoice extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: {}
        }
    }

    async componentDidMount(){
        let record = await axios.get('/api/purchaseorderinvoice/' + this.props.id);
        let data = record.data;
        if(data.shippingCost != null || data.serviceFee != null){
            let calcCosts = 0;
            for(let row of data.contents){
                calcCosts += ConversionHelper.round2(row.lineCost);
            }
            if(data.taxTotal != null || data.taxTotal !== 0){
                calcCosts += ConversionHelper.round2(parseFloat(data.taxTotal));
            }
            if(data.shippingCost != null || data.shippingCost !== 0){
                calcCosts += ConversionHelper.round2(parseFloat(data.shippingCost));
            }
            if(data.serviceFee != null || data.serviceFee !== 0){
                calcCosts += ConversionHelper.round2(parseFloat(data.serviceFee));
            }
            if(data.totalAmount != null && ConversionHelper.round2(parseFloat(data.totalAmount)) !== calcCosts){
                data.totalAmount = calcCosts;
            }
        } else {
            if(data.totalAmount == null){
                data.totalAmount = data.amount + data.taxTotal;
            }
        }
        this.setState({
            data: data
        });
    }

    generateCallback  = () => {
        this.props.callBack()
        this.props.onClose();
    };

    generateMarkup = () => {
        let htmlData = ReactDOMServer.renderToStaticMarkup(<ComponentToPrint data={this.state.data} constants={this.props.auth.constants} print t={this.props.t} 
            dateFormat={this.props.auth.user.dateFormat} logo={this.props.profile.logo}/>);
        return htmlData;
    }

    render(){
        const t = this.props.t;
        return(
            <Dialog open={this.props.open} onClose={this.props.onClose} aria-labelledby="dialog-new-batch-record" maxWidth="lg">
                <DialogContent>
                    <ComponentToPrint data={this.state.data} constants={this.props.auth.constants} print={false} t={t} 
                        dateFormat={this.props.auth.user.dateFormat} logo={this.props.profile.logo}/>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" size="small" onClick={this.props.onClose}>{t('close')}</Button>
                    <GeneratePdf
                        markup={this.generateMarkup}
                        id={this.props.id}
                        objectType={this.props.auth.constants.objectIds.purchaseOrder}
                        dateFormat={this.props.auth.user.dateFormat}
                        callBack={this.generateCallback}
                    />
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile
});

export default withTranslation() (connect(mapStateToProps)(PurchaseOrderInvoice));