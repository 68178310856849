import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Aux from '../../hoc/Auxilary/Auxilary';
import { ClearButton, SaveButton, TextOnlyClickButton } from '../UI/Buttons/Buttons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SectionTitle from '../UI/Typography/SectionTitle';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import Panel from '../UI/Panel/Panel';
import ActionBar from '../UI/Buttons/ActionBar';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class Provinces extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            code: '',
            name: '',
            edit: false,
            country: '',
            countrySel: null,
            countries: [],
            pst: '',
            hst: false,
            taxItems: [],
            taxItem: '',
            taxItemSel: null,
            taxItemList: [],
            types: [],
            type: '',
            typeSel: null,
            flatRate: '',
            adValorem: '',
            _id: '',
            searchCountry: '',
            searchCountrySel: null,
            searchName: '',
            orderBy: 'name',
            ordDir: 1,
            totalCount: 0,
            pageCount: 1,
            listSize: 10,
            page: 0,
            enableSave: true
        };
    }

    async componentDidMount(){
        let values = await axios.get('/int/provincevalues');
        this.setState({
            countries: values.data.countries,
            types: values.data.types,
            taxItemList: values.data.taxItems
        }, () => {
            this.loadRecords();
        });
    }

    loadRecords = async (e) => {
        let data = {
            offset: this.state.offset,
            size: this.state.listSize,
            country: this.state.searchCountry,
            name: this.state.searchName
        };
        let list = await axios.get('/int/provinces', {params: data});
        let records = list.data.data;
        records.forEach(row => {
            let countrySel = this.state.countries.find(x => x.value === row.country);
            row.countrySel = countrySel;
            row.taxItems.forEach(item => {
                let taxItemSel = this.state.taxItemList.find(x => x.value === item.taxItem);
                item.taxItemSel = taxItemSel;
                let typeSel = this.state.types.find(x => x.value === item.type);
                item.typeSel = typeSel;
            })
        });
        this.setState({list: records, totalCount: list.data.totalCount}, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('provinces'), screen: this.props.auth.screenDefs.Provinces}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeSearchAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            this.loadRecords();
        });
    };

    changeSearchValue = e => {
        this.setState({[e.target.name]: e.target.value}, () => {this.loadRecords();});
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async (e) => {
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }            
        let data = {
            code: this.state.code,
            name: this.state.name,
            country: this.state.country,
            pst: this.state.pst,
            hst: this.state.hst,
            taxItems: this.state.taxItems,
            _id: this.state._id
        }
        try {
            const result = await axios.post('/int/province', data);
            this.setState({ enableSave: true }, () => {
                this.clear();
                this.loadRecords();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        }
    };

    validate(){
        let errors = {};
        const t = this.props.t;
        if(this.state.code == null || this.state.code === '')
            errors[t('code')] = t('required');
        if(this.state.name == null || this.state.name === '')
            errors[t('name')] = t('required');
        if(this.state.country == null || this.state.country === '')
            errors[t('country')] = t('required');
        if(this.state.pst == null || this.state.pst === '')
            errors[t('pst')] = t('required');
        for(let i = 0; i < this.state.taxItems.length; i++){
            let row = this.state.taxItems[i];
            if(row.taxItem == null || row.taxItem === '')
                errors[`${t('taxItem')} (${t('line')} ${i})`] = t('required');
            if(row.flatRate == null || row.flatRate === '')
                errors[`${t('flatRate')} (${t('line')} ${i})`] = t('required');
            if(row.type == null || row.type === '')
                errors[`${t('type')} (${t('line')} ${i})`] = t('required');
        }
        if((this.state.taxItem != null && this.state.taxItem !== '') || (this.state.flatRate != null && this.state.flatRate !== '') ||
        (this.state.adValorem != null && this.state.adValorem !== '') || (this.state.type != null && this.state.type !== ''))
            errors[t('taxItem')] = t('rowNotAdded');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    clear = (e) => {
        this.setState({
            _id: '',
            code: '',
            name: '',
            country: '',
            countrySel: null,
            pst: '',
            hst: false,
            taxItems: [],
            flatRate: '',
            adValorem: '',
            type: '',
            typeSel: null,
            taxItem: '',
            taxItemSel: null,
            edit: false,
            errors: {}
        })
    };

    editRecord(index){
        let province = this.state.list[index];
        this.setState({
            _id: province._id,
            code: province.code,
            name: province.name,
            country: province.country,
            countrySel: province.countrySel,
            pst: province.pst,
            hst: province.hst,
            taxItems: province.taxItems,
            edit: true
        })
    }

    addRecord = (e) => {
        this.setState({edit: true});
    };

    addLine = (e) => {
        let valid = this.validateLine();
        if(!valid)
            return;
        let taxItems = this.state.taxItems != null ? this.state.taxItems : [];
        taxItems.push({
            taxItem: this.state.taxItem,
            taxItemSel: this.state.taxItemSel,
            flatRate: this.state.flatRate,
            adValorem: this.state.adValorem,
            type: this.state.type,
            typeSel: this.state.typeSel
        });
        this.setState({
            taxItems: taxItems,
            taxItem: '',
            taxItemSel: null,
            flatRate: '',
            adValorem: '',
            type: '',
            typeSel: null
        });
    };

    validateLine(){
        const t = this.props.t;
        let errors = {};
        if(this.state.taxItem == null || this.state.taxItem === '')
            errors[t('taxItem')] = t('required');
        if(this.state.flatRate == null || this.state.taxItem === '')
            errors[t('taxItem')] = t('required');
        if(this.state.type == null || this.state.type === '')
            errors[t('type')] = t('required');
        let exists = this.state.taxItems.find(x => x.taxItem === this.state.taxItem);
        if(exists)
            errors[t('taxItem')] = t('rowAlreadyExits');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteLine = (index) => (e) => {
        let taxItems = this.state.taxItems;
        taxItems.splice(index, 1);
        this.setState({taxItems: taxItems});
    };

    changeLineNumber = (name, index) => (e) => {
        let taxItems = this.state.taxItems;
        let taxItem = taxItems[index];
        let value = e.target.value;
        if(isNaN(value))
            return;
            taxItem[name] = value;
            this.setState({taxItems: taxItems});
    };

    changeLineAuto = (name, data, index) => async (e, newValue) => {
        let taxItems = this.state.taxItems;
        let taxItem = taxItems[index];
        taxItem[name] = newValue;
        taxItem[data] = newValue != null ? newValue.value : null;
        this.setState({
            taxItems: taxItems
        });
    };

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const { errors, t } = this.props;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createClick={this.addRecord}
                        createAccess={true}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                    value={this.state.searchCountrySel}
                                    options={this.state.countries}
                                    onChange={this.changeSearchAuto('searchCountrySel', 'searchCountry')}
                                    label={t('country')}
                                />
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <TextField value={this.state.searchName} onChange={this.changeSearchValue} name="searchName"
                            size="medium" fullWidth={true} label={t('name')}/>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="provinces">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>{t('code')}</TableCell>
                                    <TableCell>{t('name')}</TableCell>
                                    <TableCell>{t('country')}</TableCell>
                                    <TableCell>{t('pst')}</TableCell>
                                    <TableCell>{t('hst')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <TextOnlyClickButton
                                                click={() => this.editRecord(i)}
                                                name={row.code}
                                                iteration={i}
                                                enabled={true}
                                            />
                                        </TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.countrySel.label}</TableCell>
                                        <TableCell>{row.pst}</TableCell>
                                        <TableCell>{row.hst ? t('yes') : t('no')}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
                <Dialog open={this.state.edit} onClose={this.clear} maxWidth="md" fullWidth={true}>
                    <DialogTitle>
                        <SectionTitle title={t('province')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={2} sm={1}>
                                <TextField value={this.state.code} onChange={this.changeValue} name="code"
                                size="medium" fullWidth={true} label={t('code')} required 
                                error={errors[t('code')] != null ? true : false} helperText={errors[t('code')]}/>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                size="medium" fullWidth={true} label={t('name')} required 
                                error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <AutoCompleteField
                                    value={this.state.countrySel}
                                    options={this.state.countries}
                                    onChange={this.changeAuto('countrySel', 'country')}
                                    error={errors[t('country')] != null ? true : false}
                                    helperText={errors[t('country')]} 
                                    disabled={false}
                                    label={t('country')}
                                    required
                                />
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <TextField value={this.state.pst} onChange={this.changeValue} name="pst"
                                size="medium" fullWidth={true} label={t('pst')} required 
                                error={errors[t('pst')] != null ? true : false} helperText={errors[t('pst')]}/>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <CheckboxField
                                checked={this.state.hst} onChange={this.changeBool('hst')}
                                label={t('hst')} name="hst" disabled={false}
                                />
                            </Grid>
                        </Grid>
                        <br/>
                        <SectionTitle title={t('itemRates')}/>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>{t('taxItem')}</Grid>
                            <Grid item xs={4} sm={2}>{t('flatRate')}</Grid>
                            <Grid item xs={4} sm={2}>{t('adValorem')}</Grid>
                            <Grid item xs={4} sm={2}>{t('type')}</Grid>
                        </Grid>
                        {this.state.taxItems != null && this.state.taxItems.length > 0 && this.state.taxItems.map((row, i) =>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={row.taxItemSel}
                                        options={this.state.taxItemList}
                                        onChange={this.changeLineAuto('taxItemSel', 'taxItem', i)}
                                        error={errors[`${t('taxItem')} (${t('line')} ${i})`] != null ? true : false}
                                        helperText={errors[`${t('taxItem')} (${t('line')} ${i})`]} 
                                        disabled={false}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <TextField value={row.flatRate} onChange={this.changeLineNumber('flatRate', i)} name={"flatRate" + i}
                                    size="medium" fullWidth={true} disabled={false} required
                                    error={errors[`${t('flatRate')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('flatRate')} (${t('line')} ${i})`]}/>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <TextField value={row.adValorem} onChange={this.changeLineNumber('adValorem', i)} name={"adValorem" + i}
                                    size="medium" fullWidth={true} disabled={false}
                                    error={errors[`${t('adValorem')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('adValorem')} (${t('line')} ${i})`]}/>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={row.typeSel}
                                        options={this.state.types}
                                        onChange={this.changeLineAuto('typeSel', 'type', i)}
                                        error={errors[`${t('type')} (${t('line')} ${i})`] != null ? true : false}
                                        helperText={errors[`${t('type')} (${t('line')} ${i})`]} 
                                        disabled={false}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <IconButton aria-label="delete line" onClick={this.deleteLine(i)}>
                                        <RemoveCircleTwoToneIcon  />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.taxItemSel}
                                    options={this.state.taxItemList}
                                    onChange={this.changeAuto('taxItemSel', 'taxItem')}
                                    error={errors[t('taxItem')] != null ? true : false}
                                    helperText={errors[t('taxItem')]} 
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.flatRate} onChange={this.changeNumber} name="flatRate"
                                size="medium" fullWidth={true} disabled={false} 
                                error={errors[t('flatRate')] != null ? true : false} helperText={errors[t('flatRate')]}/>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.adValorem} onChange={this.changeNumber} name="adValorem"
                                size="medium" fullWidth={true} disabled={false} 
                                error={errors[t('adValorem')] != null ? true : false} helperText={errors[t('adValorem')]}/>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <AutoCompleteField
                                    value={this.state.typeSel}
                                    options={this.state.types}
                                    onChange={this.changeAuto('typeSel', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]} 
                                    disabled={false}
                                />
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <IconButton aria-label="add line" onClick={this.addLine}>
                                    <AddBoxTwoToneIcon  />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton saveClick={this.save} enabled={this.state.enableSave} />
                        <ClearButton clearClick={this.clear} enabled={true} />
                    </DialogActions>
                </Dialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Provinces));