import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import isEmpty from '../../is-empty';
import SignatureCapture from '../General/SignatureCapture';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import DateSelect from '../UI/DateSelect/DateSelect';
import SectionTitle from '../UI/Typography/SectionTitle';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import CapaAction from './CapaAction';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';

class HazardousInvestigation extends Component {
    constructor(props){
        super(props);

        this.state = {
            whyHappened: '',
            capaActions: [],
            investigatedBy: '',
            investigatorTitle: '',
            investigatorPhone: '',
            investigatorDate: null,
            investigatorSignature: '',
            user: '',
            users: [],
            edit: false,
            investigatorSelected: null,
            userSelected: null,
            qas: [],
            managers: [],
            editCapa: false,
            offset: 0,
            listSize: 10,
            page: 0,
            orderBy: 'number',
            ordDir: 1,
            totalCount: 0,
            capaId: '',
            capaIndex: -1,
            enableSave: true
        }
        this.changeValue = this.changeValue.bind(this);
        this.investigatorDateChange = this.investigatorDateChange.bind(this);
        this.saveFormClick = this.saveFormClick.bind(this);
    }

    async componentDidMount(){
        let values = await axios.get('/api/hazardousinvestigationvalues');
        var incident = await axios.get('/api/hazardous/' + this.props.id);
        const { user } = this.props.auth;
        var investigatorSelected = values.data.users.find(x => x.value === incident.data.investigatedBy);
        if(this.props.id != null && this.props._id !== '')
            this.loadRecords();
        this.setState({
            users: values.data.users,
            managers: values.data.managers,
            qas: values.data.qas,
            correctiveActions: incident.data.correctiveActions,
            whyHappened: incident.data.whyHappened,
            investigatedBy: incident.data.investigatedBy != null && incident.data.investigatedBy !== '' ?
            incident.data.investigatedBy : user.id,
            investigatorSignature: incident.data.investigatorSignature,
            investigatorDate: incident.data.investigatorDate != null ? Date.parse(incident.data.investigatorDate) : null,
            investigatorPhone: incident.data.investigatorPhone,
            investigatorTitle: incident.data.investigatorTitle,
            investigatorSelected: investigatorSelected
        });
    }

    loadRecords = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: this.props.auth.constants.objectIds.hazardousSpill, value: this.props.id},
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let result = await axios.get('/api/capas', {params: params});
        this.setState({
            capaActions: result.data.data,
            totalCount: result.data.totalCount
        })
    };

    changeValue(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    investigatorDateChange(e){
        this.setState({investigatorDate: e});
    }

    addAction = (e) => {
        this.setState({editCapa: true})
    };

    closeDialog = (e) => {
        this.setState({
            editCapa: false,
            capaId: '',
            capaIndex: -1
        },() => {this.loadRecords();});
    };

    editCapa = (i) => (e) => {
        let capa = this.state.capaActions[i];
        this.setState({capaId: capa._id, editCapa: true, capaIndex: i});
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    saveFormClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveFormClick());
    }

    _saveFormClick = async () => {
        const t = this.props.t;
        var valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        var data = {
            _id: this.props.id,
            whyHappened: this.state.whyHappened,
            investigatedBy: this.state.investigatedBy,
            investigatorSignature: this.state.investigatorSignature,
            investigatorTitle: this.state.investigatorTitle,
            investigatorPhone: this.state.investigatorPhone,
            investigatorDate: this.state.investigatorDate,
        }
        axios.post('/api/incidentinvestigationsave', data)
        .then(result => {
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        })
        .catch(err => {
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        })
    }

    validateForm(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.whyHappened == null || state.whyHappened === '')
            errors[t('whyHappened')] = t('required');
        if(state.investigatedBy == null || state.investigatedBy === '')
            errors[t('investigatedBy')] = t('required');
        if(state.investigatorPhone == null || state.investigatorPhone === '')
            errors[t('investigatorPhone')] = t('required');
        if(state.investigatorDate == null || state.investigatorDate === '')
            errors[t('investigatorDate')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({[name]: newValue, [data]: newValue != null ? newValue.value : null});
    }

    render(){
        const errors = this.props.errors;
        const signed = false;
        const t = this.props.t;
        return(
            <Aux>
                <br/>
                <Grid container spacing={3}>
                    <Grid item sm={11}>
                        <TextField value={this.state.whyHappened} onChange={this.changeValue} name="whyHappened" multiline={true} rows="3"
                        variant="outlined" size="medium" fullWidth={true} disabled={signed} 
                        label={t('cause')} required
                        error={errors[t('whyHappened')] != null ? true : false} helperText={errors[t('whyHappened')]}/>
                    </Grid>
                </Grid>
                <SectionTitle title={t('correctiveMeasures')}/>
                <TableContainer component={Paper}>
                    <Table aria-label={t('capaPlan')}>
                        <TableHead>
                            <TableRow key="capaHeader">
                                <TableCell>{t('number')}</TableCell>
                                <TableCell>{t('title')}</TableCell>
                                <TableCell>{t('dueDate')}</TableCell>
                                <TableCell>{t('status')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.capaActions.map((row, i) =>
                                <TableRow key={row._id}>
                                    <TableCell>
                                        <TextOnlyClickButton enabled={true} click={this.editCapa(i)} name={row.number}/>
                                    </TableCell>
                                    <TableCell>{row.title}</TableCell>
                                    <TableCell><DateDisplay value={row.dueDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                    <TableCell>{row.status}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Grid container spacing={3}>
                    <Grid item sm={8}>
                        <Button variant="outlined" color="primary" size="small" onClick={this.addAction}>{t('add')}</Button>
                    </Grid>
                </Grid>
                {this.state.editCapa &&
                    <CapaAction
                        reload={this.loadRecords}
                        open={this.state.editCapa}
                        onClose={this.closeDialog}
                        injuryHazardous={this.props.id}
                        id={this.state.capaId}
                        users={this.state.users}
                        qas={this.state.qas}
                        managers={this.state.managers}
                    />
                }      
                <br/>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                            value={this.state.investigatorSelected}
                            options={this.state.managers}
                            onChange={this.changeAuto('investigatorSelected', 'investigatedBy')}
                            error={errors[t('investigatedBy')] != null ? true : false}
                            helperText={errors[t('investigatedBy')]}
                            label={t('investigatedBy')}
                            disabled={signed}
                            required
                        />
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <TextField value={this.state.investigatorTitle} onChange={this.changeValue} name="investigatorTitle"
                        size="medium" fullWidth={true} disabled={signed} label={t('title')}
                        error={errors[t('investigatorTitle')] != null ? true : false} helperText={errors[t('investigatorTitle')]}/>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <InputMaskField mask="999-999-9999" name="investigatorPhone" value={this.state.investigatorPhone}
                        onChange={this.changeValue} disabled={signed}
                        helperText={errors[t('investigatorPhone')]} required
                        error={errors[t('investigatorPhone')] != null ? true : false} 
                        label={t('phoneNumber')}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <DateSelect
                            onChange={this.investigatorDateChange}
                            value={this.state.investigatorDate}
                            helperText={errors[t('investigatorDate')]}
                            error={errors[t('investigatorDate')] != null ? true : false}
                            label={t('date')}
                            disabled={signed}
                            format={this.props.auth.user.dateFormat}
                            required
                        />
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        <Button variant="outlined" color="primary" size="small" onClick={this.sigClick}>
                            {this.state.investigatorSignature != null && this.state.investigatorSignature !== '' ?
                            t('view'): t('sign')}
                        </Button>
                    </Grid>
                </Grid>
                <SignatureCapture signature={this.state.investigatorSignature} editSig={this.editSignature} heading={t('investigatorSignature')}
                open={this.state.sigOpen} saveSig={this.accept} close={this.sigClose} sigPad={this.sigPad}
                clearSig={this.clear}/>
                <Grid container spacing={3}>
                    <Grid item sm={2}>
                        <Button variant="contained" size="small" color="primary" onClick={this.saveFormClick} disabled={!this.state.enableSave}>{t('save')}</Button>
                    </Grid>
                </Grid>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(HazardousInvestigation));