import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import SectionTitle from '../UI/Typography/SectionTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Aux from '../../hoc/Auxilary/Auxilary';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import TextField from '../UI/TextField/TextField';
import isEmpty from '../../is-empty';

class ItemOutputValues extends Component {
    constructor(props){
        super(props);
        this.state = {
            outputCategories: [],
            itemCatOptions: [],
            catSel: [],
            locked: true,
            enableSave: true
        };
    }

    async componentDidMount(){
        const values = await axios.get('/api/testitemcatselect');
        this.setState({
            itemCatOptions: values.data.itemCategories,
            locked: this.props.security.create || this.props.security.update ? false : true
        }, () => {
            if(this.props.id != null && this.props.id !== '')
                this.loadRecord();
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/itemoutputvalues/' + this.props.id);
        let itemCats = this.state.itemCatOptions;
        let cats = [];
        record.data.outputCategories.forEach(row => {
            let cat = itemCats.find(x => x.value === row.category._id);
            cats.push(cat);
        });
        this.setState({
            catSel: cats,
            outputCategories: record.data.outputCategories
        });
    };

    categoryChange = async (e, newValue) => {
        let categories = this.state.outputCategories;
        if(newValue != null && newValue.length > 0){
            let catItems = await axios.get('/api/testcategoryitems2/' + newValue[newValue.length - 1].value);
            categories.push(catItems.data);
            this.setState({
                catSel: newValue,
                outputCategories: categories
            });
        } else {
            this.setState({
                catSel: [],
                outputCategories: []
            });
        }
    };

    changeNumber(name, catIndex, itemIndex, e){
        let outputCategories = this.state.outputCategories;
        let cat = outputCategories[catIndex];
        cat.items[itemIndex][name] = e.value;
        this.setState({outputCategories: outputCategories});
    }

    changeCatNumber = (name, catIndex, itemIndex) => (e) => {
        const outputCategories = this.state.outputCategories;
        let cat = outputCategories[catIndex];
        cat.items[itemIndex][name] = e.target.value;
        this.setState({outputCategories: outputCategories});
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let cats = [];
        this.state.outputCategories.forEach(cat => {
            var rec = {};
            var items = [];
            rec.category = cat.category._id;
            cat.items.forEach(item => {
                items.push({
                    item: item.item._id,
                    expectedValue: item.expectedValue,
                    minValue: item.minValue,
                    maxValue: item.maxValue
                })
            });
            rec.items = items;
            cats.push(rec);
        });
        let data = {outputCategories: cats, _id: this.props.id};
        try {
            let result = await axios.post('/api/itemoutputvalues', data);
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    }

    validate(){
        const t = this.props.t;
        let state = this.state;
        let errors = {};
        for(let i = 0; i < state.outputCategories.length; i++){
            let cat = state.outputCategories[i];
            for(let j = 0; j < cat.items.length; j++){
                let row = cat.items[j];
                if(row.expectedValue == null || row.expectedValue === '')
                errors[`${t('expectedValue')} (${t('category')} ${i}, ${t('item')} ${j})`] = this.props.t('required');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    toggleSave = () => {
        this.setState({ enableSave: !this.state.enableSave });
    }

    render(){
        const errors = this.props.errors;
        const t = this.props.t;
        const locked = this.state.locked;
        return(
            <Aux>
                <Grid container spacing={3}>
                    <Grid item sm={7}>
                        <AutoCompleteField
                            multiple={true}
                            stayOpen={true}
                            value={this.state.catSel}
                            options={this.state.itemCatOptions}
                            onChange={this.categoryChange}
                            error={errors[t('outputCategories')] != null ? true : false}
                            helperText={errors[t('outputCategories')]}
                            label={t('categories')}
                            disabled={locked}
                        />
                    </Grid>
                </Grid>
                {this.state.outputCategories != null && this.state.outputCategories.length > 0 && this.state.outputCategories.map((row, i) =>
                    <Aux key={i}>
                        <br/>
                        <SectionTitle title={row.category.code}/>
                        <TableContainer component={Paper}>
                            <Table aria-label="Output categories">
                                <TableHead>
                                    <TableRow key="itemValHeader">
                                        <TableCell>{t('item')}</TableCell>
                                        <TableCell>{t('expectedValue')}</TableCell>
                                        <TableCell>{t('minValue')}</TableCell>
                                        <TableCell>{t('maxValue')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.items.map((item, k) =>
                                        <TableRow key={item._id}>
                                            <TableCell>
                                                {item.item.name}
                                            </TableCell>
                                            <TableCell>
                                                {!locked &&
                                                    <TextField type="number" onChange={this.changeCatNumber('expectedValue', i, k)}
                                                    name={'expectedValue' + i + k } value={item.expectedValue}
                                                    size="medium" fullWidth={true} required
                                                    error={errors[`${t('expectedValue')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('expectedValue')} (${t('line')} ${i}, ${k})`]}/>
                                                }
                                                {locked &&
                                                    item.expectedValue
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {!locked &&
                                                    <TextField type="number" onChange={this.changeCatNumber('minValue', i, k)}
                                                    name={'minValue' + i + k } value={item.minValue}
                                                    size="medium" fullWidth={true}
                                                    error={errors[`${t('minValue')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('minValue')} (${t('line')} ${i}, ${k})`]}/>
                                                }
                                                {locked &&
                                                    item.minValue
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {!locked &&
                                                    <TextField type="number" onChange={this.changeCatNumber('maxValue', i, k)}
                                                    name={'maxValue' + i + k } value={item.maxValue}
                                                    size="medium" fullWidth={true}
                                                    error={errors[`${t('maxValue')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('maxValue')} (${t('line')} ${i}, ${k})`]}/>
                                                }
                                                {locked &&
                                                    item.maxValue
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Aux>
                )}
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item>
                        <Button variant="contained" color="primary" disabled={locked && !this.state.enableSave} onClick={this.save}>Save</Button>
                    </Grid>
                </Grid>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ItemOutputValues));