import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Table} from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class TableHead extends Component{
    render(){
        const t = this.props.t;
        return (
            <thead>
            <tr>
                <th>{t('completed')}</th>
                <th>{t('location')}</th>
                <th>{t('related')}</th>
                <th>{t('status')}</th>
            </tr>
            </thead>
        );
    }
}

class TableRow extends Component {
    render() {
        const t = this.props.t;
        return (
            <tr>
            <td><Link to={'/instance/' + this.props.data._id}>
                    <DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.completedDate}/>
                </Link>
            </td>
            <td>{this.props.data.location != null ? this.props.data.location.name : ""}</td>
            <td>{this.props.data.batch != null ? t('batch') + ": " + this.props.data.batch.number :
                this.props.data.lot != null ? " " +  t('lot') + ": " + this.props.data.lot.number :
                this.props.data.room != null ? " " + t('room') + ": " + this.props.data.room.name :
                this.props.data.productionOrder != null ? + " " + t('productionOrder') +  ": " + this.props.data.productionOrder.number : ''
            }</td>
            <td>{this.props.data.status != null ? this.props.data.status.label : ''}</td>
            </tr>
        );
    }
}

class SopClientInstances extends Component {

    constructor(props){
        super(props);

        this.state = {
            sop: {},
            instances: [],
            didLoad: false,
            sopId: this.props.match.params.sopId,
            cert: {}
        }
        this.loadInstances = this.loadInstances.bind(this);
    }

    loadInstances(){
        const t = this.props.t;
        if(this.state.sopId != null && this.state.sopId !== ""){
            const url = "/api/instances/desc/" + this.state.sopId;
            axios.get(url)
            .then(result => {
            this.setState({
                instances: result.data,
                didLoad: true
            });
            })
            .catch(function(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        } 
    }

    componentDidMount(){
        const t = this.props.t;
        if(!this.props.auth.isAuthenticated) {
            this.props.history.push('/login');
        }
        axios.get('/api/sops/' + this.state.sopId)
        .then(sop => {
            axios.get('/api/certification/' + this.state.sopId)
            .then(cert => {
            this.setState({
                cert: cert.data,
                sop: sop.data
            });
            this.loadInstances();
            })
        })
        .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        });
        const crumbs = [
            { path: 'active', label: this.props.t('sopClientInstances'), screen: this.props.auth.screenDefs.SopClientInstances}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
    }

    componentDidUpdate(prevProps, prevState){
      if(!this.state.didLoad || (prevState.templateId !== this.state.templateId)){
        this.loadInstances();
      }
    }

    returnClick(e){
      e.preventDefault();
      this.props.history.goBack();
    }

    render(){
        const t = this.props.t;
        const showInstances = this.state.sopId != null && this.state.sopid !== '' ? true : false;
        const addRecord = this.state.cert.length < 1 ? "" :
          <Link to={'/instance/new/' + this.props.match.params.sopId}>
            <button className="btn btn-secondary">{t('add')}</button>
          </Link>
        const certify =
          <Link to={'/cert/' + this.props.match.params.sopId}>
            <button className="btn btn-secondary">{t('certify')}</button>
          </Link>

        const show = (
          <div className="container">
              <BreadcrumbBar />
              {addRecord}
              {certify}
              <Table striped bordered hover>
                  <TableHead />
                  <tbody>
                      {this.state.instances.map((instance, i) => <TableRow key={instance._id} data={instance} />)}
                  </tbody>
              </Table>
          </div>
        );

        const hide = (<div></div>);

        return(
            <div>
              {showInstances ? show : hide }
            </div>
        );
    }
}

const mapStateToProps = state => ({
  auth: state.auth,
  sop: state.sop
});

export default withTranslation() (connect(mapStateToProps)(SopClientInstances));