import { SET_MENU_OPTIONS, SAVE_SECURITY, SET_SECURITY, RESET_SECURITY, SAVE_CACHE } from '../actions/types';

const initialState = {
  menuOptions: [],
  currentPath: {
    approve: false,
    complete: false,
    create: false,
    delete: false,
    read: true,
    review: false,
    unlock: false,
    update: false,
    print: false,
    documentDelete: false,
    documentUpload: false,
    path: 'login'
  },
  pathCache: [],
}

function security(state = initialState, action){
  switch(action.type){
    case SET_MENU_OPTIONS: {
      return {
        ...state, menuOptions: JSON.parse(action.payload)
      }
    }
    case SAVE_SECURITY: {
      return {
        ...state,
        currentPath: action.payload.current, 
        pathCache: action.payload.cache
      }
    }
    case SET_SECURITY: {
      return {
        ...state, currentPath: action.payload.current
      }
    }  
    case SAVE_CACHE: {
      return {
        ...state, pathCache: action.payload.cache
      }
    }
    case RESET_SECURITY: {
      return {
        currentPath: {
          approve: false,
          complete: false,
          create: false,
          delete: false,
          read: true,
          review: false,
          unlock: false,
          update: false,
          print: false,
          documentDelete: false,
          documentUpload: false,
          path: 'login'
        },
        pathCache: [],
        menuOptions: []
      };
    }
    default:
      return state;
  }
}

export default security;