import React from 'react';
import { useDispatch } from 'react-redux';
import Aux from '../../../hoc/Auxilary/Auxilary';
import Snackbar from '@mui/material/Snackbar';
import AlertTitle from '@mui/material/AlertTitle';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} 
                   sx={{ mb: props.type === 'modal' ? 8 : null, 
                         width: { xs: '75vw', md: '400px' }
                        }}/>;
});

export function LayoutToaster(props) {
  const { message, title, severity, isOpen } = props.alert;
  const dispatch = useDispatch();

  return(
    <Aux>
      <Snackbar 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}} 
        autoHideDuration={5000}
        disableWindowBlurListener={true}
        key={`${severity}-alert`}
        onClose={() => dispatch({ type: 'CLEAR_ALERT' })}
        open={isOpen}
        TransitionComponent={SlideTransition}
      >
        <Alert severity={severity} type='layout'>
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </Aux>
  )
}

export function ModalToaster(props) {
  if(props.alert == null || props.alert === '')
    return null;
  const { message, onClose, title, severity } = props.alert;

  const isOpen = props?.alert != null && props?.alert !== '' ? true : false;

  return(
    <Aux>
      <Snackbar 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}} 
        autoHideDuration={5000}
        disableWindowBlurListener={true}
        key={`${severity}-alert`}
        onClose={() => onClose()}
        open={isOpen}
        TransitionComponent={SlideTransition}
      >
        <Alert severity={severity} type='modal'>
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </Aux>
  )
}