import { SET_ZEBRA_PRINTERS, SET_DEVICE_STATUS, SET_ZEBRA_DEFAULT, 
         HAS_DEVICES, TOGGLE_DEVICEDIALOG, TOGGLE_PRINTDIALOG } from './types';

export const setZebraPrinters = deviceList => ({
  type: SET_ZEBRA_PRINTERS,
  payload: {
    new: deviceList.new,
    registered: deviceList.registered,
    notConnected: deviceList.notConnected
  }
});

export const setDeviceStatus = device => ({
  type: SET_DEVICE_STATUS,
  payload: {
    device: device
  }
});

export const setZebraDefault = device => ({
  type: SET_ZEBRA_DEFAULT,
  payload: {
    default: device
  }
});

export const hasDevices = confirmation => ({
  type: HAS_DEVICES,
  payload: {
    hasRegisteredDevices: confirmation
  }
});

export const toggleDeviceDialog = () => ({
  type: TOGGLE_DEVICEDIALOG
});

export const togglePrintDialog = values => ({
  type: TOGGLE_PRINTDIALOG,
  payload: {
    labelType: values.type,
    labelData: values.labelData
  }
});