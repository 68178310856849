import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import DateSelect from '../UI/DateSelect/DateSelect';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import TextField from '../UI/TextField/TextField';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Button from '@mui/material/Button';
import isEmpty from '../../is-empty';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { fieldSecurity} from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import { NumericFormat } from 'react-number-format';
import { withTranslation } from 'react-i18next';
import TablePagination from '@mui/material/TablePagination';
import LotLife from './LotLife';
import { EvoraTabs, TabCanvas, TabPanel } from '../UI/Tabs/Tabs';
import common from '../../jsons/common.json';
import ItemLedgerComponent from './ItemLedgerComponent';
import ValueHelper from '../../helpers/valueHelper';
import Panel from '../UI/Panel/Panel';
import BoxTitle from '../UI/Typography/BoxTitle';
import ModalDialog from '../UI/Dialog/ModalDialog';
import Divider from '@mui/material/Divider';
import Switch from '../UI/Switch/Switch';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { CSVDownload } from "react-csv";
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import Tooltip from '@mui/material/Tooltip';

class Lot extends Component {
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            _id: '',
            number: '',
            item: null,
            product: {},
            evoraBatch: null,
            workOrder: '',
            productionOrder: null,
            amount: '',
            amountUOM: {},
            expires: '',
            vendorLot: '',
            created: '',
            documents: [],
            ledgers: [],
            externalTreatments: [],
            testInstances: [],
            allDestructions: [],
            allProductionOrders: [],
            amountUOMSel: null,
            workOrders: [],
            woSel: null,
            uoms: [],
            lotTransactions: [],
            split: false,
            splitDate: new Date(),
            splitAmount: '',
            splitContainers: [],
            splitCount: '',
            containers: [],
            container: '',
            containerSel: null,
            containerOptions: [],
            containerAmount: '',
            containerCount: '',
            bin: '',
            binSel: null,
            bins: [],
            rating: '',
            ratingSel: '',
            ratings: [],
            status: '',
            statusSel: {value : '', label: ''},
            statuses: [],
            security: [],
            fieldAccess: false,
            locked: true,
            count: '',
            strain: null,
            combine: false,
            combineContainers: [],
            combineAmount: '',
            newContainer: false,
            existingContainer: '',
            existingContainerSel: null,
            existingContainers: [],
            fromContainer: '',
            fromContainerSel: null,
            fromAmount: '',
            combineBin: '',
            combineBinSel: null,
            combineDate: new Date(),
            combineCount: '',
            fromCount: '',
            sourceLot: '',
            sourceLotSel: null,
            sourceLots: [],
            sourceContainers: [],
            enableSave: true,
            discreteWeight: '',
            fullDiscreteWeight: '',
            transPage: 0,
            rowsPerPage: 10,
            transCount: 0,
            transactions: [],
            destPage: 0,
            destPageRows: 10,
            destCount: 0,
            destructions: [],
            poPage: 0,
            poPageRows: 10,
            poCount: 0,
            productionOrders: [],
            foreignId: '',
            splitNumber: '',
            tabLabels: [{name: t('detail'), disabled: false}, {name: t('lifeCycle'), disabled: false}, {name: t('itemLedgers'), disabled: false}],
            tabKey: 0,
            itemCategory: null,
            packagedOn: '',
            available: '',
            availableCount: '',
            renderNum: Math.random(),
            reserved: '',
            reservedCount: '',
            allContainer: false,
            splitLotDialog: false,
            combineDialog: false,
            csvData: null,
            thcMg: '',
            unitThc: '',
            hasThc: false,
            testResult: null,
            isHemp: false,
            costPerUnit: '',
            coas: [],
            casedLot: null,
            thc: '',
            cbd: '',
            thca: '',
            cbda: '',
            totalThc: '',
            totalCbd: '',
            totalTerpenes: '',
            rtv: false,
            isCannabis: false,
            isFlower: false,
            shippedLot: null
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.bin, common.cacheValues.quantityUOM, common.cacheValues.lotStatus, common.cacheValues.lotRating],
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let bins = cacheValues.bins;
        let statusSel = cacheValues.lotStatuses.find(x => x.value === this.props.auth.constants.lotStatuses.available);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.lot);
        this.setState({
            uoms: cacheValues.quantityUOMs,
            bins: bins,
            ratings: cacheValues.lotRatings,
            statuses: cacheValues.lotStatuses,
            status: this.props.auth.constants.lotStatuses.available,
            statusSel: statusSel,
            security: this.props.permission,
            fieldAccess: fieldAccess
        }, () => {
            this.loadRecord();
        });
    }

    loadRecord = async () => {
        const record = await axios.get('/api/lotdetail/' + this.props.match.params.id);
        let amountUOMSel = this.state.uoms.find(x => x.value === record.data.amountUOM);
        let containerOptions = [];
        if(record.data.containers != null && record.data.containers.length > 0){
            record.data.containers.forEach(row => {
                containerOptions.push({
                    value: row.container._id,
                    label: row.container.number + ' (' + row.quantity + ')'
                })
            });
        }
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let ratingSel = record.data.rating != null ? this.state.ratings.find(x => x.value === record.data.rating) : null;
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.lot);
        let hasThc = record.data.itemCategory.lotThcMg === true;
        let isFlower = false;
        if(record.data.item != null && record.data.item.category != null){
            let found = common.flowerHCCategories.find(x => x === record.data.item.category.hcReportCategory);
            if(found != null){
                isFlower = true;
            }
        }
        this.setState({
            _id: record.data._id,
            number: record.data.number,
            item: record.data.item,
            product: record.data.product,
            evoraBatch: record.data.evoraBatch,
            productionOrder: record.data.productionOrder,
            amount: record.data.amount,
            amountUOM: record.data.amountUOM,
            expires: record.data.expires,
            created: record.data.created,
            vendorLot: record.data.vendorLot,
            documents: record.data.documents != null ? record.data.documents : [],
            ledgers: record.data.ledgers,
            amountUOMSel: amountUOMSel,
            containers: record.data.containers,
            containerOptions: containerOptions,
            rating: record.data.rating,
            ratingSel: ratingSel,
            status: record.data.status,
            statusSel: statusSel,
            fieldAccess: fieldAccess,
            count: record.data.count,
            strain: record.data.strain,
            discreteWeight: record.data.discreteWeight,
            fullDiscreteWeight: record.data.fullDiscreteWeight,
            foreignId: record.data.foreignId,
            itemCategory: record.data.itemCategory,
            packagedOn: record.data.packagedOn,
            available: record.data.available,
            availableCount: record.data.availableCount,
            renderNum: Math.random(),
            reserved: record.data.reserved,
            reservedCount: record.data.reservedCount,
            thcMg: record.data.thcMg,
            unitThc: record.data.unitThc,
            hasThc: hasThc,
            testResult: record.data.testResult != null ? record.data.testResult : null,
            isHemp: record.data.isHemp === true ? true : false,
            costPerUnit: record.data.costPerUnit,
            coas: record.data.coas != null ? record.data.coas : [],
            casedLot: record.data.casedLot,
            rtv: record.data.rtv === true ? true : false,
            totalThc: record.data.totalThc,
            totalCbd: record.data.totalCbd,
            thc: record.data.thc,
            cbd: record.data.cbd,
            thca: record.data.thca,
            cbda: record.data.cbda,
            totalTerpenes: record.data.totalTerpenes,
            isCannabis: record.data.item != null && record.data.item.category != null && record.data.item.category.hcReportCategory !== null ? true : false,
            isFlower: isFlower,
            shippedLot: record.data.shippedLot
        }, () => {
            const crumbs = [
                { path: '/#/lots', label: this.props.t('lots'), screen: this.props.auth.screenDefs.LotList},
                { path: 'active', label: this.props.t('lot') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.Lot}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { 
                options: [
                    { menuType: this.props.auth.constants.optionMenuTypes.comments },
                    { menuType: this.props.auth.constants.optionMenuTypes.logs }
                ],
                title: this.props.t('options')
            }});
        });
        this.loadTransactions();
    };

    loadTransactions = async () => {
        const record = await axios.get('/api/lottransactions/' + this.props.match.params.id);
        let allTrans = record.data.transactions;
        if(allTrans != null){
            allTrans.sort(function(a, b){
                if(a.lastUpdated < b.lastUpdated){
                    return 1;
                }
                if(a.lastUpdated > b.lastUpdated){
                    return -1
                }
                return 0;
            });
        }
        let transactions = allTrans != null ? allTrans.slice(0, this.state.rowsPerPage) : [];
        let allDestructions = record.data.destructions;
        if(allDestructions != null){
            allDestructions.sort(function(a, b){
                if(a.date < b.date){
                    return 1;
                }
                if(a.date > b.date){
                    return -1
                }
                return 0;
            });
        }
        let destructions = allDestructions != null ? allDestructions.slice(0, this.state.destPageRows) : [];
        let allProductionOrders = record.data.productionOrders;
        if(allProductionOrders != null){
            allProductionOrders.sort(function(a, b){
                if(a.plannedStart < b.plannedStart){
                    return 1;
                }
                if(a.plannedStart > b.plannedStart){
                    return -1
                }
                return 0;
            });
        }
        let productionOrders = allProductionOrders != null ? allProductionOrders.slice(0, this.state.poPageRows) : [];
        this.setState({
            transCount: allTrans != null ? allTrans.length : 0,
            transPage: 0,
            transactions: transactions,
            lotTransactions: allTrans,
            externalTreatments: record.data.externalTreatments,
            testInstances: record.data.testInstances,
            allDestructions: record.data.destructions,
            allProductionOrders: record.data.productionOrders,
            destructions: destructions,
            destCount: record.data.destructions != null ? record.data.destructions.length : 0,
            productionOrders: productionOrders,
            poCount: record.data.productionOrders != null ? record.data.productionOrders.length : 0,
            poPage: 0,
        });
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value})
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(newValue != null && data === 'sourceLot'){
            let result = await axios.get('/api/lotcontainers/' + newValue.value);
            this.setState({sourceContainers: result.data});
        }
    };

    changeNumber = (e) => {
        var name = e.target.name;
        var value = e.target.value
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value}, () => {
            if(value != null && value !== ''){
                if(name === 'thcMg'){
                    let unitThc = null;
                    if(this.state.item != null && this.state.item.packSizeG != null && this.state.item.packSizeG > 1){
                        unitThc = parseFloat(value) * this.state.item.packSizeG;
                    } else {
                        unitThc = value;
                    }
                    this.setState({unitThc: unitThc});
                } else if (name === 'unitThc'){
                    let thcMg = null;
                    if(this.state.item != null && this.state.item.packSizeG != null && this.state.item.packSizeG > 1){
                        thcMg = parseFloat(value) / this.state.item.packSizeG;
                    } else {
                        thcMg = value;
                    }
                    this.setState({thcMg: thcMg});
                }
            }
        });
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    openSplit = (e) => {
        this.setState({splitLotDialog: true});
    };

    clearSplit = (e) => {
        this.setState({
            splitLotDialog: false,
            splitAmount: 0,
            splitCount: 0,
            splitContainers: []
        });
    };

    changeLock = (e) => {
        this.setState({locked: !this.state.locked});
    }

    saveSplit = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveSplit());
    }

    _saveSplit = async () => {
        const t = this.props.t;
        let valid = this.validateSplit();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let splitContainers = this.state.splitContainers;
        for(let i = 0; i < splitContainers.length; i++){
            let splitContainer = splitContainers[i];
            let container = this.state.containers.find(x => x.container._id === splitContainer.container);
            splitContainer.originalBin = container.bin._id;
        }
        let data = {
            lot: this.state._id,
            batch: this.state.batch,
            amount: this.state.splitAmount,
            count: this.state.splitCount,
            date: this.state.splitDate,
            containers: splitContainers,
            splitNumber: this.state.splitNumber != null && this.state.splitNumber !== '' ? this.state.splitNumber.toUpperCase() : null
        };
        try {
            let result = await axios.post('/api/lotsplit', data);
            this.loadRecord();
            this.clearSplit();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        } finally {
            this.setState({ enableSave: true});
        }
    };

    validateSplit(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(this.state.item.category != null && this.state.item.category.customLotNumber){
            if(this.state.splitNumber == null || this.state.splitNumber === '')
                errors[t('splitNumber')] = t('required');
        }
        if(state.splitContainers == null || state.splitContainers.length === 0){
            errors[t('splitnumber')] = t('noContainersCreated');
        } else {
            let totalAmount = 0;
            let totalCount = 0;
            let containerTotals = [];
            for(let i = 0; i < state.splitContainers.length; i++){
                let row = state.splitContainers[i];
                if(row.container == null || row.container === '')
                    errors[`${t('container')} (${t('line')} {i})`] = t('required');
                if(row.containerAmount == null || row.containerAmount === '')
                    errors[`${t('containerAmount')} (${t('line')} ${i})`] = t('required');
                if((this.state.item.avgWeight == null || this.state.avgWeight === 0) && (row.containerCount == null || row.containerCount === '') && this.state.count != null && this.state.count !== '' &&
                    parseInt(this.state.count) > 0){
                    errors[`${t('containerCount')} (${t('line')} {i})`] = t('required');
                }
                if(row.bin == null || row.bin === '')
                    errors[`${t('bin')} (${t('line')} {i})`] = t('required');
                totalAmount = totalAmount + parseFloat(row.containerAmount);
                if(row.containerAmount != null && row.containerAmount !== '')
                    totalCount += parseInt(row.containerCount);
                let contRow = containerTotals.find(x => x.container === row.container)
                if(contRow){
                    contRow.amount = contRow.amount + parseFloat(row.containerAmount);
                } else {
                    containerTotals.push({
                        container: row.container,
                        amount: parseFloat(row.containerAmount)
                    })
                }
            }
            containerTotals.forEach(row => {
                let origCont = state.containers.find(x => x.container._id === row.container);
                if(origCont != null){
                    if(parseFloat(origCont.available) < row.amount){
                        errors[t('splitNumber')] = t('moreThanContainer');
                    }
                }
            });
            if(totalAmount > parseFloat(state.amount))
                errors[t('splitNumber')] = t('moreThanTotal');
            if(totalCount > 0 && state.count != null && state.count !== ''){
                if(totalCount > parseInt(state.count))
                    errors[t('splitNumber')] = t('moreThanCount');
            }

        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    addContainer = (e) => {
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(this.state.container == null || this.state.container === '')
            errors[t('splitnumber')] = t('required');
        if(this.state.containerAmount == null || this.state.containerAmount === '')
            errors[t('splitNumber')] = t('required');
        if((state.item.avgWeight == null || state.item.avgWeight === 0) && state.count != null && state.count !== '' && (state.containerCount == null || state.containerCount === ''))
            errors[t('splitNumber')] = t('required');
        if(state.container != null && state.container !== '' && state.containerAmount != null && state.containerAmount !== ''){
            let containerTotal = parseFloat(state.containerAmount);
            let containerCount = 0;
            state.splitContainers.forEach(row => {
                if(row.container === state.container){
                    containerTotal = containerTotal + parseFloat(row.containerAmount);
                    if(row.containerCount != null && row.containerCount !== ''){
                        containerCount += parseInt(row.containerCount);
                    }
                }
            });
            let origContainer = state.containers.find(x => x.container._id === state.container);
            if(containerTotal > parseFloat(origContainer.quantity)){
                errors[t('splitNumber')] = t('moreThanContainer');
            }
            if(containerCount > parseInt(origContainer.count)){
                errors[t('splitNumber')] = t('moreThanCount');
            }
        }
        if(this.state.bin == null || this.state.bin === '')
            errors[t('bin')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
        }
        let splitContainers = this.state.splitContainers;
        splitContainers.push({
            container: this.state.container,
            containerAmount: this.state.containerAmount,
            bin: this.state.bin,
            binSel: this.state.binSel,
            containerSel: this.state.containerSel,
            containerCount: this.state.containerCount
        });
        let splitCounts = this.calculateSplitAmount(splitContainers);
        let amount = splitCounts.amount;
        let count = splitCounts.count;
        this.setState({
            splitContainers: splitContainers,
            binSel: null,
            bin: '',
            container: '',
            containerSel: null,
            containerAmount: '',
            containerCount: '',
            splitAmount: amount,
            splitCount: count,
            errors: {}
        });
    };

    changeContainerAuto = (name, data, index) => (e, newValue) => {
        let splitContainers = this.state.splitContainers;
        let container = splitContainers[index];
        container[name] = newValue;
        container[data] = newValue != null ? newValue.value : null;
        this.setState({
            splitContainers: splitContainers
        });
    };

    changeContainerNumber = (name, index) => (e) => {
        let splitContainers = this.state.splitContainers;
        let container = splitContainers[index];
        let value = e.target.value;
        if(isNaN(value))
            return;
        container[name] = value;
        this.setState({splitContainers: splitContainers});
    };

    deleteContainer = (index) => (e) => {
        let splitContainers = this.state.splitContainers;
        splitContainers.splice(index, 1);
        let totals = this.calculateSplitAmount(splitContainers);
        this.setState({
            splitContainers: splitContainers,
            splitAmount: totals.amount,
            splitCount: totals.count
        });
    };

    calculateSplitAmount(splitContainers){
        let amount = 0;
        let count = 0;
        splitContainers.forEach(row => {
            amount = amount + parseFloat(row.containerAmount);
            if(row.containerCount != null && row.containerCount !== '')
                count += parseInt(row.containerCount);
        });
        return {amount: amount, count: count > 0 ? count : null};
    }

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        let errors = {};
        if(this.state.status == null || this.state.status === '')
            errors[t('status')] = t('required');
        if(this.state.number == null || this.state.number === ''){
            errors[t('number')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            this.setState({enableSave: true});
            return;
        }
        let data = {
            _id: this.state._id,
            rating: this.state.rating,
            status: this.state.status,
            expires: this.state.expires,
            number: this.state.number.toUpperCase(),
            discreteWeight: this.state.discreteWeight,
            vendorLot: this.state.vendorLot,
            foreignId: this.state.foreignId,
            packagedOn: this.state.packagedOn,
            thcMg: this.state.thcMg,
            unitThc: this.state.unitThc,
            thc: this.state.thc,
            cbd: this.state.cbd,
            thca: this.state.thca,
            cbda: this.state.cbda,
            totalThc: this.state.totalThc,
            totalCbd: this.state.totalCbd,
            totalTerpenes: this.state.totalTerpenes
        }
        try {
            let result = await axios.post('/api/lot', data);
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            this.loadRecord();
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        } finally {
            this.setState({ enableSave: true});
        }
    }

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]}, () => {
            if(name === 'allContainer' && this.state.allContainer === true){
                this.setState({
                    fromAmount: this.state.fromContainerSel.quantity,
                    fromCount: this.state.fromContainerSel.count
                });
            }
        });
    };

    changeRowBool = (name, index) => (e) => {
        let combineContainers = this.state.combineContainers;
        let row = combineContainers[index];
        row[name] = !row[name];
        if(name === 'allContainer' && row.allContainer === true){
            row.quantity = row.sourceContainerSel.quantity;
            row.count = row.sourceContainerSel.count;
        }
        this.setState({combineContainers: combineContainers});
    };

    changeRowNumber = (name, index) => (e) => {
        const t = this.props.t;
        let combineContainers = this.state.combineContainers;
        let row = combineContainers[index];
        let value = e.target.value;
        if(isNaN(value))
            return;
        let errors = {};
        if(name === 'amount'){
            if(parseFloat(value) > parseFloat(row.containerSel.quantity)){
                errors[`${t('amount')} (${t('line')} {i})`] = t('moreThanContainer');
            }
        }
        if(this.state.item.category != null && this.state.item.category.hasCount && name === 'count'){
            if(parseInt(value) > parseInt(row.containerSel.count)){
                errors[`${t('count')} (${t('line')} {i})`] = t('moreThanContainer');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        row[name] = value;
        let combineAmount = this.calculateCombineAmount(combineContainers);
        this.setState({combineContainers: combineContainers, combineAmount: combineAmount.amount, combineCount: combineAmount.count});
    };

    openCombine = async (e) => {
        let combineLotOptions = this.state.combineLotOptions;
        if(combineLotOptions == null || combineLotOptions.length === 0){
            let lotOptions = await axios.get('/api/itemlots/' + this.state.item._id);
            combineLotOptions = lotOptions.data;
        }
        let existingContainers = [];
        let sourceContainers = [];
        this.state.containers.forEach(row => {
            existingContainers.push({
                value: row.container._id,
                label: row.container.number + '(' + row.quantity + ')',
                quantity: row.quantity,
                count: row.count
            });
            sourceContainers.push({
                value: row.container._id,
                label: row.container.number + '(' + row.quantity + ')',
                quantity: row.quantity,
                count: row.count
            });
        });
        let sourceLotSel = combineLotOptions.find(x => x.value === this.state._id);
        this.setState({
            combineDialog: true,
            existingContainers: existingContainers,
            sourceLots: combineLotOptions,
            sourceLot: this.state._id,
            sourceLotSel: sourceLotSel,
            sourceContainers: sourceContainers
        });
    };

    clearCombine = (e) => {
        this.setState({
            combineDialog: false,
            combineContainers: [],
            combineAmount: '',
            existingContainer: '',
            existingContainerSel: null,
            fromContainer: '',
            fromContainerSel: null,
            fromAmount: '',
            newContainer: false,
            errors: {},
            combineBin: '',
            combineBinSel: null,
            combineDate: new Date(),
            combineCount: '',
            sourceContainers: [],
            sourceLots: [],
            sourceLot: '',
            sourceLotSel: null
        });
    };

    saveCombine = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveCombine());
    }

    _saveCombine = async () => {
        const t = this.props.t;
        let valid = this.validateCombine();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            lot: this.state._id,
            sourceLot: this.state.sourceLot,
            newContainer: this.state.newContainer,
            bin: this.state.combineBin,
            containers: this.state.combineContainers,
            existingContainer: this.state.existingContainer,
            amount: this.state.combineAmount,
            date: this.state.combineDate,
            count: this.state.combineCount
        };
        try {
            let result = await axios.post('/api/lotcombinecontainers', data);
            this.loadRecord();
            this.clearCombine();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        } finally {
            this.setState({ enableSave: true});
        }
    };

    validateCombine(){
        const t = this.props.t;
        let errors = {};
        if((this.state.existingContainer == null || this.state.existingContainer === '') && !this.state.newContainer)
            errors[t('existingContainer')] = t('containerRequired');
        if(this.state.combineContainers == null || this.state.combineContainers.length === 0)
            errors[t('fromContainer')] = t('containerNotSelected');
        if(this.state.fromContainerSel != null || (this.state.fromAmount != null && this.state.fromAmount !== ''))
            errors[t('fromContainer')] = t('rowNotAdded');
        if(this.state.newContainer && (this.state.combineBin == null || this.state.combineBin === ''))
            errors[t('combineBin')] = t('required');
        if(this.state.combineDate == null || this.state.combineDate === '')
            errors[t('combineDate')] = t('required');
        if(this.state.sourceLotSel != null && this.state.sourceLotSel.discreteWeight != null && this.state.sourceLotSel.discreteWeight !== '' &&
        this.state.discreteWeight != null && this.state.discreteWeight !== ''){
            if(parseFloat(this.state.sourceLotSel.discreteWeight) !== parseFloat(this.state.discreteWeight)){
                errors[t('sourceLot')] = t('discreteWeightMatch');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    addCombineRow = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.fromContainer == null || this.state.fromContainer === ''){
            errors[t('fromContainer')] = t('required');
        } else {
            if(this.state.existingContainer != null && this.state.existingContainer !== '' && this.state.existingContainer === this.state.fromContainer){
                errors[t('fromContainer')] = t('sameContainer');
            }
            let found = this.state.combineContainers.find(x => x.container === this.state.fromContainer);
            if(found){
                errors[t('fromContainer')] = t('containerUsed');
            }
        }
        if(this.state.fromAmount == null || this.state.fromAmount === ''){
            errors[t('fromAmount')] = t('required');
        } else {
            if(this.state.fromContainerSel != null){
                if(parseFloat(this.state.fromAmount) > parseFloat(this.state.fromContainerSel.quantity))
                    errors[t('fromAmount')] = t('moreThanContainer');
            }
        }
        if(this.state.item.category != null && this.state.item.category.hasCount){
            if(parseInt(this.state.fromCount) > parseInt(this.state.fromContainerSel.count)){
                errors[t('fromCount')] = t('moreThanCount');
            }
        }
        if(this.state.sourceLotSel != null && this.state.sourceLotSel.discreteWeight != null && this.state.sourceLotSel.discreteWeight !== '' &&
        this.state.discreteWeight != null && this.state.discreteWeight !== ''){
            if(parseFloat(this.state.sourceLotSel.discreteWeight) !== parseFloat(this.state.discreteWeight)){
                errors[t('fromContainer')] = t('discreteWeightMatch');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let combineContainers = this.state.combineContainers;
        combineContainers.push({
            container: this.state.fromContainer,
            containerSel: this.state.fromContainerSel,
            amount: this.state.fromAmount,
            count: this.state.fromCount,
            allContainer: this.state.allContainer
        });
        let combineAmount = this.calculateCombineAmount(combineContainers);
        this.setState({
            combineContainers: combineContainers,
            combineAmount: combineAmount.amount,
            combineCount: combineAmount.count,
            fromContainer: '',
            fromContainerSel: null,
            fromAmount: '',
            fromCount: '',
            allContainer: false,
            errors: {}
        })
    };

    deleteCombineRow = (index) => (e) => {
        let combineContainers = this.state.combineContainers;
        combineContainers.splice(index, 1);
        let combineAmount = this.calculateCombineAmount(combineContainers);
        this.setState({combineContainers: combineContainers, combineAmount: combineAmount.amount, combineCount: combineAmount.count});
    }

    calculateCombineAmount(containers){
        let combineAmount = 0;
        let count = 0;
        if(this.state.existingContainerSel != null){
            combineAmount += parseFloat(this.state.existingContainerSel.quantity);
            if(this.state.item.category != null && this.state.item.category.hasCount){
                count += parseInt(this.state.existingContainerSel.count);
            }
        }
        containers.forEach(row => {
            combineAmount += parseFloat(row.amount);
            if(this.state.item.category != null && this.state.item.category.hasCount){
                count += parseInt(row.count);
            }
        });
        return {amount: combineAmount.toFixed(2), count: count};
    }

    handleChangePage = (event, newPage) => {
        this.setState({transPage: newPage}, () => {
            this.sliceTransactions();
        });
    };

    sliceTransactions(){
        let transactions = this.state.lotTransactions.slice(this.state.rowsPerPage * this.state.transPage, (this.state.transPage * this.state.rowsPerPage) + this.state.rowsPerPage);
        this.setState({transactions: transactions})
    }

    handleRowsPerPage = e => {
        this.setState({rowsPerPage: parseInt(e.target.value)}, () => {
            this.sliceTransactions();
        });
    };

    destChangePage = (event, newPage) => {
        this.setState({destPage: newPage}, () => {
            this.sliceDestructions();
        });
    };

    sliceDestructions(){
        let destructions = this.state.allDestructions.slice(this.state.destPageRows * this.state.destPage, (this.state.destPage * this.state.destPageRows) + this.state.destPageRows);
        this.setState({destructions: destructions})
    }

    destRowsPerPage = e => {
        this.setState({destPageRows: parseInt(e.target.value)}, () => {
            this.sliceDestructions();
        });
    };

    poChangePage = (event, newPage) => {
        this.setState({poPage: newPage}, () => {
            this.slicePos();
        });
    };

    slicePos(){
        let productionOrders = this.state.allProductionOrders.slice(this.state.poPageRows * this.state.poPage, (this.state.poPage * this.state.poPageRows) + this.state.poPageRows);
        this.setState({productionOrders: productionOrders})
    }

    poRowsPerPage = e => {
        this.setState({poPageRows: parseInt(e.target.value)}, () => {
            this.slicePos();
        });
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    buttonStack = (dialog) => {
        const t = this.props.t;
        let buttons = [];
        switch(dialog) {
            case 'combine': {
                buttons = [
                    <Button variant="text" color="secondary" onClick={this.clearCombine} data-cy="combineClose" key='combineButtonClose'>{t('close')}</Button>,
                    <Button disabled={!this.state.enableSave} variant="contained" color="primary" onClick={this.saveCombine} data-cy="combineSave" key='combineButtonSave'>{t('combine')}</Button>
                ];
                break;
            }
            case 'splitLot': {
                buttons = [
                    <Button variant="text" color="secondary" onClick={this.clearSplit} data-cy="closeSplit" key='splitButtonClose'>{t('close')}</Button>,
                    <Button disabled={!this.state.enableSave} variant="contained" color="primary" onClick={this.saveSplit} data-cy="saveSplit" key='splitButtonSave'>{t('split')}</Button>
                ];
                break;
            }
            default:
                break;
        }
        return buttons;
    }

    csvClick = async (e) => {
        const t = this.props.t;
        this.setState({csvData: null});
        let transactions = this.state.lotTransactions;
        let data = [];
        for(let row of transactions){
            data.push({
                date: row.date,
                amount: row.amount,
                count: row.count,
                discrete: row.fullDiscreteWeight,
                record: row.objet != null ? row.object.text + '-' + row.record != null ? row.record.number : '' : '',
                lastUpdated: row.lastUpdated
            })
        }
        let headers = [
            {label: t('date'), key: 'date'},
            {label: t('amount'), key: 'amount'},
            {label: t('count'), key: 'count'},
            {label: t('discreteWeightG'), key: 'discrete'},
            {label: t('record'), key: 'record'},
            {label: t('lastUpdated'), key: 'lastUpdated'}
        ];
        this.setState({csvData: data, headers: headers});
    }

    render(){
        const fieldAccess = this.state.fieldAccess;
        const errors = this.props.errors;
        const locked = this.state.locked;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar 
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.lot}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        optionsAvailable={this.state._id != null && this.state._id !== '' ? true : false}
                        flagForSignature={0}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabCanvas value={this.state.tabKey} index={0}>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} md={6} spacing={2} alignContent='flex-start'>
                            <Grid item xs={12} sm={6}>
                                <Panel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <BoxTitle title={t('details')} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {locked &&
                                                <FormControl data-cy="number">
                                                    <FormLabel>{t('number')}</FormLabel>
                                                    {this.state.number}
                                                </FormControl>
                                            }
                                            {!locked &&
                                                <TextField value={this.state.number} onChange={this.changeValue} name="number"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('number')} data-cy="number"
                                                error={errors[t('number')] != null ? true : false} helperText={errors[t('number')]}
                                                />
                                            }
                                        </Grid>
                                        {this.state.vendorLot != null && this.state.vendorLot !== '' && (!fieldAccess || locked) &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('vendorLot')}</FormLabel>
                                                    {this.state.vendorLot}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {fieldAccess && !locked &&
                                            <Grid item xs={6}>
                                                <TextField value={this.state.vendorLot} onChange={this.changeValue} name="vendorLot"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('vendorLot')} data-cy="vendorLot"
                                                error={errors[t('vendorLot')] != null ? true : false} helperText={errors[t('vendorLot')]}
                                                />
                                            </Grid>
                                        }
                                        {locked && this.state.statusSel != null &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('status')}</FormLabel>
                                                    {this.state.statusSel.label}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {!locked && fieldAccess &&
                                            <Grid item xs={8}>
                                                    <AutoCompleteField
                                                        value={this.state.statusSel}
                                                        options={this.state.statuses}
                                                        onChange={this.changeAuto('statusSel', 'status')}
                                                        error={errors[t('status')] != null ? true : false}
                                                        helperText={errors[t('status')]}
                                                        label={t('status')}
                                                        disabled={!fieldAccess}
                                                        required
                                                        data-cy="status"
                                                    />
                                            </Grid>
                                        }
                                        <Grid item xs={6} lg={locked || !fieldAccess ? 5 : 6}>
                                            {(locked || !fieldAccess) &&
                                                <FormControl>
                                                    <FormLabel>{t('created')}</FormLabel>
                                                    <DateDisplay format={this.props.auth.user.dateFormat} value={this.state.created}/>
                                                </FormControl>
                                            }
                                            {!locked && fieldAccess &&
                                                <DateSelect
                                                    onChange={this.dateChange('created')}
                                                    value={this.state.created}
                                                    helperText={errors[t('created')]}
                                                    error={errors[t('created')] != null ? true : false}
                                                    disabled={!fieldAccess}
                                                    label={t('created')}
                                                    format={this.props.auth.user.dateFormat}
                                                    data-cy="created"
                                                />
                                            }
                                        </Grid>
                                        {(!fieldAccess || locked) && this.state.expires != null && this.state.expires !== '' &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('expires')}</FormLabel>
                                                    {this.state.expires != null && this.state.expires !== '' && <DateDisplay format={this.props.auth.user.dateFormat} value={this.state.expires}/>}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {fieldAccess && !locked &&
                                            <Grid item xs={6}>
                                                <DateSelect
                                                    onChange={this.dateChange('expires')}
                                                    value={this.state.expires}
                                                    helperText={errors[t('expires')]}
                                                    error={errors[t('expires')] != null ? true : false}
                                                    disabled={!fieldAccess}
                                                    label={t('expires')}
                                                    format={this.props.auth.user.dateFormat}
                                                    data-cy="expires"
                                                />
                                            </Grid>
                                        }
                                        {fieldAccess && !locked && this.state.itemCategory != null && this.state.itemCategory.packagedOn === true &&
                                            <Grid item xs={6}>
                                                <DateSelect
                                                    onChange={this.dateChange('packagedOn')}
                                                    value={this.state.packagedOn}
                                                    helperText={errors[t('packagedOn')]}
                                                    error={errors[t('packagedOn')] != null ? true : false}
                                                    disabled={!fieldAccess}
                                                    label={t('packagedOn')}
                                                    format={this.props.auth.user.dateFormat}
                                                    data-cy="packagedOn"
                                                />
                                            </Grid>
                                        }
                                        {(!fieldAccess || locked) && this.state.packagedOn != null && this.state.packagedOn !== '' &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('packagedOn')}</FormLabel>
                                                    {this.state.packagedOn != null && this.state.packagedOn !== '' && <DateDisplay format={this.props.auth.user.dateFormat} value={this.state.packagedOn}/>}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {!locked && fieldAccess ?
                                            <Grid item xs={12}>
                                                <TextField value={this.state.foreignId} onChange={this.changeValue} name="foreignId"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('erpId')}
                                                error={errors[t('foreignId')] != null ? true : false} helperText={errors[t('foreignId')]}
                                                data-cy="foreignId"
                                                />
                                            </Grid> :
                                            locked && this.state.foreignId != null && this.state.foreignId !== '' ?
                                            <Grid item xs={12}>
                                                <FormControl>
                                                    <FormLabel>{t('erpId')}</FormLabel>
                                                    {this.state.foreignId}
                                                </FormControl>
                                            </Grid> : null
                                        }
                                        {this.state.rtv === true &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('rtv')}</FormLabel>
                                                    {t('yes')}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {!locked && fieldAccess &&
                                            <Grid item xs={6} md={6} lg={6}>
                                                <AutoCompleteField
                                                    value={this.state.ratingSel}
                                                    options={this.state.ratings}
                                                    onChange={this.changeAuto('ratingSel', 'rating')}
                                                    error={errors[t('rating')] != null ? true : false}
                                                    helperText={errors[t('rating')]}
                                                    label={t('rating')}
                                                    disabled={!fieldAccess}
                                                    data-cy="rating"
                                                />
                                            </Grid>
                                        }
                                        {this.state.ratingSel != null && (!fieldAccess || locked) &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('rating')}</FormLabel>
                                                    {this.state.ratingSel.label}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {(locked || !fieldAccess) && this.state.thc != null && this.state.thc !== '' &&
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <FormLabel>{t('thc')}</FormLabel>
                                                    {this.state.thc}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {(locked || !fieldAccess) && this.state.thca != null && this.state.thca !== '' &&
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <FormLabel>{t('thca')}</FormLabel>
                                                    {this.state.thca}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {(locked || !fieldAccess) && this.state.totalThc != null && this.state.totalThc !== '' &&
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <FormLabel>{t('totalThc')}</FormLabel>
                                                    {this.state.totalThc}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {(locked || !fieldAccess) && this.state.cbd != null && this.state.cbd !== '' &&
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <FormLabel>{t('cbd')}</FormLabel>
                                                    {this.state.cbd}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {(locked || !fieldAccess) && this.state.cbda != null && this.state.cbda !== '' &&
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <FormLabel>{t('cbda')}</FormLabel>
                                                    {this.state.cbda}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {(locked || !fieldAccess) && this.state.totalCbd != null && this.state.totalCbd !== '' &&
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <FormLabel>{t('totalCbd')}</FormLabel>
                                                    {this.state.totalCbd}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {(locked || !fieldAccess) && this.state.totalTerpenes != null && this.state.totalTerpenes !== '' &&
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <FormLabel>{t('totalTerpenes')}</FormLabel>
                                                    {this.state.totalTerpenes}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.isFlower && 
                                            <Aux>
                                                {!locked && fieldAccess &&
                                                    <Grid item xs={4}>
                                                        <TextField value={this.state.thc} onChange={this.changeValue} name="thc"
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('thc')} type="text"
                                                        error={errors[t('thc')] != null ? true : false} helperText={errors[t('thc')]}
                                                        data-cy="thc"
                                                    />
                                                    </Grid>
                                                }
                                                {!locked && fieldAccess &&
                                                    <Grid item xs={4}>
                                                        <TextField value={this.state.thca} onChange={this.changeValue} name="thca"
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('thca')} type="text"
                                                        error={errors[t('thca')] != null ? true : false} helperText={errors[t('thca')]}
                                                        data-cy="thca"
                                                    />
                                                    </Grid>
                                                }
                                                {!locked && fieldAccess &&
                                                    <Grid item xs={4}>
                                                        <TextField value={this.state.totalThc} onChange={this.changeValue} name="totalThc"
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('totalThc')} type="text"
                                                        error={errors[t('totalThc')] != null ? true : false} helperText={errors[t('totalThc')]}
                                                        data-cy="totalThc"
                                                    />
                                                    </Grid>
                                                }
                                                {!locked && fieldAccess &&
                                                    <Grid item xs={4}>
                                                        <TextField value={this.state.cbd} onChange={this.changeValue} name="cbd"
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('cbd')} type="text"
                                                        error={errors[t('cbd')] != null ? true : false} helperText={errors[t('cbd')]}
                                                        data-cy="cbd"
                                                    />
                                                    </Grid>
                                                }
                                                {!locked && fieldAccess &&
                                                    <Grid item xs={4}>
                                                        <TextField value={this.state.cbda} onChange={this.changeValue} name="cbda"
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('cbda')} type="text"
                                                        error={errors[t('cbda')] != null ? true : false} helperText={errors[t('cbda')]}
                                                        data-cy="cbda"
                                                    />
                                                    </Grid>
                                                }
                                                {!locked && fieldAccess &&
                                                    <Grid item xs={4}>
                                                        <TextField value={this.state.totalCbd} onChange={this.changeValue} name="totalCbd"
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('totalCbd')} type="text"
                                                        error={errors[t('totalCbd')] != null ? true : false} helperText={errors[t('totalCbd')]}
                                                        data-cy="totalCbd"
                                                    />
                                                    </Grid>
                                                }
                                                {!locked && fieldAccess &&
                                                    <Grid item xs={4}>
                                                        <TextField value={this.state.totalTerpenes} onChange={this.changeValue} name="totalTerpenes"
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('totalTerpenes')} type="text"
                                                        error={errors[t('totalTerpenes')] != null ? true : false} helperText={errors[t('totalTerpenes')]}
                                                        data-cy="totalTerpenes"
                                                    />
                                                    </Grid>
                                                }
                                            </Aux>
                                        }
                                        {this.state.testResult != null &&
                                            <Aux>
                                                {this.state.testResult.results.map((row, index) => 
                                                    <Grid item xs={12} key={row._id}>
                                                        <FormControl>
                                                            <FormLabel>{row.testItem.totalType != null ? row.testItem.totalType + '-' + row.testItem.name : row.testItem.name}</FormLabel>
                                                            {row.score + ' ' + row.testItem.uom}
                                                        </FormControl>
                                                    </Grid>
                                                )}
                                            </Aux>
                                        }
                                        {this.state.shippedLot != null && 
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <FormLabel>{t('shippedLot')}</FormLabel>
                                                    <Link to={'/lot/' + this.state.shippedLot._id}>{this.state.shippedLot.number}</Link>
                                                </FormControl>
                                            </Grid>
                                        }
                                    </Grid>
                                </Panel>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Panel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <BoxTitle title={t('quantities')} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <FormLabel>{t('total') + (this.state.amountUOMSel != null && this.state.amountUOMSel.label != null ? ' (' + this.state.amountUOMSel.label + ')' : '')}</FormLabel>
                                                <NumericFormat displayType={'text'}
                                                                value={this.state.amount != null && this.state.amount !== '' ? this.state.amount : 0}
                                                                thousandSeparator={true} decimalScale={3} />
                                            </FormControl>
                                        </Grid>
                                        {this.state.count != null && this.state.count !== '' && this.state.availableCount != null && this.state.availableCount !== '' && this.state.count !== this.state.availableCount &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('count')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.count} thousandSeparator={true} decimalScale={0}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item xs={4} md={6}>
                                            <FormControl>
                                                <FormLabel>{t('available') + (this.state.amountUOMSel != null && this.state.amountUOMSel.label != null ? ' (' + this.state.amountUOMSel.label + ')' : '')}</FormLabel>
                                                <NumericFormat displayType={'text'} value={this.state.available != null && this.state.available !== '' ? this.state.available : this.state.amount}
                                                            thousandSeparator={true} decimalScale={3} />
                                            </FormControl>
                                        </Grid>
                                        {((this.state.availableCount != null && this.state.availableCount !== '') || (this.state.count != null && this.state.count !== '')) &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('availableCount')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.availableCount != null && this.state.availableCount !== '' ? this.state.availableCount : this.state.count}
                                                                thousandSeparator={true} decimalScale={0}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <FormLabel>{t('reserved') + (this.state.amountUOMSel != null && this.state.amountUOMSel.label != null ? ' (' + this.state.amountUOMSel.label + ')' : '')}</FormLabel>
                                                <NumericFormat displayType={'text'} value={this.state.reserved != null && this.state.reserved !== '' && this.state.reserved !== 0 ? this.state.reserved : 0}
                                                            thousandSeparator={true} decimalScale={3} />
                                            </FormControl>
                                        </Grid>
                                        {(this.state.reservedCount != null && this.state.reservedCount !== '' && this.state.reservedCount !== 0) &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('reservedCount')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.reservedCount}
                                                    thousandSeparator={true} decimalScale={0}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.discreteWeight != null && this.state.discreteWeight !== '' && this.state.discreteUOMSel != null &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('discreteWeight') + ' (' + this.state.discreteUOMSel.label + ')'}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.discreteWeight}
                                                    thousandSeparator={true} decimalScale={3}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.fullDiscreteWeight != null && this.state.fullDiscreteWeight !== '' && this.state.fullDiscreteWeight !== 0 && this.state.discreteUOMSel != null &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('fullDiscrete') + ' (' + this.state.discreteUOMSel.label + ')'}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.fullDiscreteWeight}
                                                    thousandSeparator={true} decimalScale={3}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.costPerUnit != null && this.state.costPerUnit !== '' && this.state.costPerUnit !== 0 &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('costPerUnit')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.costPerUnit}
                                                    thousandSeparator={true} decimalScale={2} prefix="$"/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.hasThc && locked && (this.state.thcMg != null && this.state.thcMg !== '') &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('thcMg')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.thcMg} thousandSeparator={true} decimalScale={3}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.hasThc && !locked && fieldAccess &&
                                            <Grid item xs={6}>
                                                <TextField value={this.state.thcMg} onChange={this.changeNumber} name="thcMg"
                                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('thcMg')} type="number"
                                                    error={errors[t('thcMg')] != null ? true : false} helperText={errors[t('thcMg')]}
                                                    data-cy="thcMg"
                                                />
                                            </Grid>
                                        }
                                        {this.state.hasThc && locked && (this.state.unitThc != null && this.state.unitThc !== '') &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('unitThc')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.unitThc} thousandSeparator={true} decimalScale={3}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.hasThc && !locked && fieldAccess &&
                                            <Grid item xs={6}>
                                                <TextField value={this.state.unitThc} onChange={this.changeNumber} name="unitThc"
                                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={t('unitThc')} type="number"
                                                    error={errors[t('unitThc')] != null ? true : false} helperText={errors[t('unitThc')]}
                                                    data-cy="unitThc"
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                </Panel>
                            </Grid>
                            <Grid item xs={12}>
                                <Panel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <BoxTitle title={t('containers')} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow key="containerHead">
                                                            <TableCell>{t('container')}</TableCell>
                                                            <TableCell>{t('bin')}</TableCell>
                                                            <TableCell align="right">{t('amount') + (this.state.amountUOMSel != null && this.state.amountUOMSel.label != null ? ' (' + this.state.amountUOMSel.label + ')' : '')}</TableCell>
                                                            <TableCell>{t('type')}</TableCell>
                                                            {this.state.count != null && this.state.count !== '' &&
                                                                <TableCell align="right">{t('count')}</TableCell>
                                                            }
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.containers != null && this.state.containers.length > 0 ?
                                                            this.state.containers.map((row, i) =>
                                                                <TableRow key={'containerRow-' + row._id + i}>
                                                                    <TableCell><Link to={'/container/' + row.container._id}>{row.container.number}</Link></TableCell>
                                                                    <TableCell><Link to={'/bin/' + row.bin._id}>{row.bin.code}</Link></TableCell>
                                                                    <TableCell align="right">
                                                                        <NumericFormat displayType={'text'} value={row.quantity}
                                                                        thousandSeparator={true} decimalScale={3}/>
                                                                    </TableCell>
                                                                    <TableCell>{row.container.type != null ? row.container.type : ''}</TableCell>
                                                                    {this.state.count != null && this.state.count !== '' &&
                                                                        <TableCell align="right">
                                                                            <NumericFormat displayType={'text'} value={row.count}
                                                                            thousandSeparator={true} decimalScale={0}/>
                                                                        </TableCell>
                                                                    }
                                                                </TableRow>
                                                            ) :
                                                            <TableRow>
                                                                <TableCell colSpan={5}>{t('noContainers')}</TableCell>
                                                            </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Panel>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={6} spacing={2} alignContent='flex-start'>
                            <Grid item xs={12}>
                                <Panel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <BoxTitle title={t('item')} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl>
                                                <FormLabel>{t('item')}</FormLabel>
                                                {this.state.item != null &&<Link to={'/item/' + this.state.item._id}>{this.state.item.number + '-' + this.state.item.name}</Link>}
                                            </FormControl>
                                        </Grid>
                                        {this.state.strain != null &&
                                            <Grid item xs={12}>
                                                <FormControl>
                                                    <FormLabel>{t('strain')}</FormLabel>
                                                    {this.state.strain.name}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.evoraBatch != null && this.state.evoraBatch !== '' &&
                                            <Grid item xs={12}>
                                                <FormControl>
                                                    <FormLabel>{t('batch')}</FormLabel>
                                                    {this.state.evoraBatch != null &&<Link to={'/cultivationbatch/' + this.state.evoraBatch._id}>{this.state.evoraBatch.number}</Link>}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.productionOrder != null && this.state.productionOrder !== '' &&
                                            <Grid item xs={8}>
                                                <FormControl>
                                                    <FormLabel>{t('productionOrder')}</FormLabel>
                                                    {this.state.productionOrder != null &&<Link to={'/eproductionorder/' + this.state.productionOrder._id}>{this.state.productionOrder.number}</Link>}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.casedLot != null && this.state.casedLot !== '' &&
                                            <Grid item xs={8}>
                                                <FormControl>
                                                    <FormLabel>{t('casedLot')}</FormLabel>
                                                    <Link to={'/lot/' + this.state.casedLot._id}>{this.state.casedLot.number}</Link>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.isHemp === true &&
                                            <Grid item xs={4}>
                                                <FormControl>
                                                    <FormLabel>{t('hemp')}</FormLabel>
                                                    {t('yes')}
                                                </FormControl>
                                            </Grid>
                                        }
                                    </Grid>
                                </Panel>
                            </Grid>
                            <Grid item xs={12}>
                                <Panel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <BoxTitle title={t('transactions')} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow key="transHead">
                                                            <TableCell>{t('date')}</TableCell>
                                                            <TableCell align="right">{t('amount') + (this.state.amountUOMSel != null && this.state.amountUOMSel.label != null ? ' (' + this.state.amountUOMSel.label + ')' : '')}</TableCell>
                                                            {this.state.discreteWeight != null && this.state.discreteWeight !== '' &&
                                                                <TableCell align="right">{t('fullDiscreteG')}</TableCell>
                                                            }
                                                            {this.state.count != null && this.state.count !== '' &&
                                                                <TableCell align="right">{t('count')}</TableCell>
                                                            }
                                                            <TableCell>{t('source')}</TableCell>
                                                            <TableCell>{t('lastUpdated')}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.transactions.map((row, i) =>
                                                            <TableRow key={'transactionRow-' + row._id + i}>
                                                                <TableCell><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                                                <TableCell align="right">
                                                                    <NumericFormat displayType={'text'} value={row.amount}
                                                                    thousandSeparator={true} decimalScale={3}/>
                                                                </TableCell>
                                                                {this.state.discreteWeight != null && this.state.discreteWeight !== '' &&
                                                                    <TableCell align="right">
                                                                        <NumericFormat displayType={'text'} value={row.fullDiscreteWeight}
                                                                        thousandSeparator={true} decimalScale={3}/>
                                                                    </TableCell>
                                                                }
                                                                {this.state.count != null && this.state.count !== '' &&
                                                                    <TableCell align="right">
                                                                        <NumericFormat displayType={'text'} value={row.count}
                                                                        thousandSeparator={true} decimalScale={0}/>
                                                                    </TableCell>
                                                                }
                                                                <TableCell>
                                                                    {row.record != null && row.object != null &&
                                                                        <Link to={row.object.link + row.record._id}>
                                                                            {row.object._id === this.props.auth.constants.objectIds.batchActivity ? row.record.batch.number + ' - ' +
                                                                            row.record.definition.description + ' - ' + row.record.number :
                                                                            row.object.text + ' - ' + row.record.number
                                                                            }
                                                                        </Link>
                                                                    }
                                                                    {row.record == null && row.object != null ? row.object.text : ''}
                                                                </TableCell>
                                                                <TableCell><DateDisplay value={row.lastUpdated} format={this.props.auth.common.dateTimeFormat}/></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow key="transFoot">
                                                            {this.state.lotTransactions != null && this.state.lotTransactions.length > 0 &&
                                                                <Aux>
                                                                    <Tooltip title={t('exportToCsv')} >
                                                                        <IconButton
                                                                            disabled={false}
                                                                            component={'div'}
                                                                            onClick={this.csvClick}
                                                                            name='actionCsv'
                                                                        >
                                                                            <ExcelIcon fontSize='medium'/>
                                                                            </IconButton>
                                                                    </Tooltip>
                                                                    <TablePagination count={this.state.lotTransactions.length}
                                                                    rowsPerPage={this.state.rowsPerPage}
                                                                    onPageChange={this.handleChangePage}
                                                                    page = {this.state.transPage}
                                                                    rowsPerPageOptions={common.rowOptions}
                                                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                                                </Aux>
                                                            }
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>
                                            {this.state.csvData != null &&
                                                <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.headers} separator={this.state.separator}/>
                                            }
                                        </Grid>
                                    </Grid>
                                </Panel>
                            </Grid>
                            {this.state.testInstances != null && this.state.testInstances.length > 0 &&
                                <Grid item xs={12}>
                                    <Panel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <BoxTitle title={t('testResults')} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableContainer component={Paper}>
                                                    <Table size='small'>
                                                        <TableHead>
                                                            <TableRow key="testResultTableHeader">
                                                                <TableCell>{t('number')}</TableCell>
                                                                <TableCell>{t('date')}</TableCell>
                                                                <TableCell>{t('test')}</TableCell>
                                                                <TableCell>{t('vendor')}</TableCell>
                                                                <TableCell>{t('result')}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.testInstances.map((row, i) =>
                                                                <TableRow key={'testIrow-' + row._id + i}>
                                                                    <TableCell><Link to={'/testresult/' + row._id}>{row.number}</Link></TableCell>
                                                                    <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={row.date}/></TableCell>
                                                                    <TableCell>{row.testDefinition.name + ' - ' + row.type.label}</TableCell>
                                                                    <TableCell>{row.vendor.name}</TableCell>
                                                                    <TableCell>{row.result != null ? row.result.label : ''}</TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </Panel>
                                </Grid>
                            }
                            {this.state.externalTreatments != null && this.state.externalTreatments.length > 0 &&
                                <Grid item xs={12}>
                                    <Panel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <BoxTitle title={t('externalTreatments')} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableContainer component={Paper}>
                                                    <Table size='small'>
                                                        <TableHead>
                                                            <TableRow key="externalTreatmentTableHeader">
                                                                <TableCell>{t('number')}</TableCell>
                                                                <TableCell>{t('date')}</TableCell>
                                                                <TableCell>{t('location')}</TableCell>
                                                                <TableCell>{t('type')}</TableCell>
                                                                <TableCell>{t('vendor')}</TableCell>
                                                                <TableCell>{t('quantity')}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.externalTreatments.map((row, i) =>
                                                                <TableRow key={'extTreatRow-' + row._id + i}>
                                                                    <TableCell><Link to={'/exttreat/' + row._id}>{row.number}</Link></TableCell>
                                                                    <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={row.dateLeft}/></TableCell>
                                                                    <TableCell>{row.location.name}</TableCell>
                                                                    <TableCell>{row.treatmentType.label}</TableCell>
                                                                    <TableCell>{row.vendor.name}</TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </Panel>
                                </Grid>
                            }
                            {this.state.destructions != null && this.state.destructions.length > 0 &&
                                <Grid item xs={12}>
                                    <Panel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <BoxTitle title={t('destructions')} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableContainer component={Paper}>
                                                    <Table size='small'>
                                                        <TableHead>
                                                            <TableRow key="destructionTableHeader">
                                                                <TableCell>{t('number')}</TableCell>
                                                                <TableCell>{t('date')}</TableCell>
                                                                <TableCell>{t('method')}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.destructions.map((dest, i) =>
                                                                <TableRow key={'destructionRow-' + dest._id + i}>
                                                                    <TableCell><Link to={'/destruction/' + dest._id}>{dest.number}</Link></TableCell>
                                                                    <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={dest.date}/></TableCell>
                                                                    <TableCell>{dest.method.label}</TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                        {this.state.allDestructions != null && this.state.allDestructions.length > 0 &&
                                                            <TableFooter>
                                                                <TableRow key='destructionTableFooter'>
                                                                    <TablePagination count={this.state.allDestructions.length}
                                                                    rowsPerPage={this.state.destPageRows}
                                                                    onPageChange={this.destChangePage}
                                                                    page = {this.state.destPage}
                                                                    rowsPerPageOptions={common.rowOptions}
                                                                    onRowsPerPageChange={this.destRowsPerPage}/>
                                                                </TableRow>
                                                            </TableFooter>
                                                        }
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </Panel>
                                </Grid>
                            }
                            {this.state.productionOrders != null && this.state.productionOrders.length > 0 &&
                                <Grid item xs={12}>
                                    <Panel>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <BoxTitle title={t('productionOrders')} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableContainer component={Paper}>
                                                    <Table size='small'>
                                                        <TableHead>
                                                            <TableRow key="productionOrderTableHeader">
                                                                <TableCell>{t('number')}</TableCell>
                                                                <TableCell>{t('status')}</TableCell>
                                                                <TableCell>{t('item')}</TableCell>
                                                                <TableCell>{t('output')}</TableCell>
                                                                <TableCell>{t('completed')}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.productionOrders.map((row, i) =>
                                                                <TableRow key={'prodOrderRow-' + row._id + i}>
                                                                    <TableCell><Link to={'/eproductionorder/' + row._id}>{row.number + ' - ' + row.description}</Link></TableCell>
                                                                    <TableCell>{row.status.label}</TableCell>
                                                                    <TableCell>{row.outputItem.number + ' - ' + row.outputItem.name}</TableCell>
                                                                    <TableCell>{row.quantityUOM != null ? row.quantity + row.quantityUOM.label : ''}</TableCell>
                                                                    <TableCell>{row.finishedDate != null ? <DateDisplay format={this.props.auth.user.dateFormat} value={row.finishedDate}/> : ''}</TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                        {this.state.allProductionOrders != null && this.state.allProductionOrders.length > 0 &&
                                                            <TableFooter>
                                                                <TableRow key='productionOrderTableFooter'>
                                                                    <TablePagination count={this.state.allProductionOrders.length}
                                                                    rowsPerPage={this.state.poPageRows}
                                                                    onPageChange={this.poChangePage}
                                                                    page = {this.state.poPage}
                                                                    rowsPerPageOptions={common.rowOptions}
                                                                    onRowsPerPageChange={this.poRowsPerPage}/>
                                                                </TableRow>
                                                            </TableFooter>
                                                        }
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </Panel>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormButtonStack
                                id={this.state._id}
                                objectType={this.props.auth.constants.objectIds.lot}
                                callback={this.loadRecord}
                                saveClick={this.save}
                                status={this.state.status}
                                permissions={this.props.permission}
                                common={this.props.auth.common}
                                constants={this.props.auth.constants}
                                type={this.props.auth.common.statusTypes.lot}
                                splitClick={this.openSplit}
                                combineClick={() => this.openCombine()}
                                simpleUnlockClick={this.changeLock}
                                saveEnabled={this.state.enableSave}
                                locked={locked}
                            />
                        </Grid>
                    </Grid>
                </TabCanvas>
                <TabPanel value={this.state.tabKey} index={1}>
                    {this.state._id != null && this.state._id !== '' &&
                        <LotLife lot={this.state._id}/>
                    }
                </TabPanel>
                <TabCanvas value={this.state.tabKey} index={this.state.tabLabels.length - 1}>
                    <ItemLedgerComponent lot={this.state._id} permission={this.props.permission} auth={this.props.auth} renderNum={this.state.renderNum}/>
                </TabCanvas>
                {this.state.splitLotDialog &&
                    <ModalDialog buttonStack={this.buttonStack('splitLot')} title={t('splitLot')} toggleDialog={this.clearSplit}
                                dialogStatus={this.state.splitLotDialog} fullWidth maxWidth='md' fScreen='md'>
                        <Grid container spacing={2}>
                            <Grid item container spacing={2}>
                                <Grid item xs={4} sm={2}>
                                    <DateSelect
                                        onChange={this.dateChange('splitDate')}
                                        value={this.state.splitDate}
                                        helperText={errors[t('splitDate')]}
                                        error={errors[t('splitDate')] != null ? true : false}
                                        label={t('date')}
                                        format={this.props.auth.user.dateFormat}
                                        data-cy="splitDate"
                                    />
                                </Grid>
                                {this.state.item != null && this.state.item.category != null && this.state.item.category.customLotNumber &&
                                    <Grid item xs={4} sm={2}>
                                        <TextField type="text" value={this.state.splitNumber} onChange={this.changeValue} name="splitNumber"
                                        size="medium" fullWidth={true} label={t('newNumber')} data-cy="splitNumber"
                                        error={errors[t('splitNumber')] != null ? true : false} helperText={errors[t('splitNumber')]}/>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2} sx={{ textAlign: 'right' }}>
                                    <FormControl>
                                        <FormLabel>{t('amount') + (this.state.amountUOMSel != null ? ' (' + this.state.amountUOMSel.label + ')' : null)}</FormLabel>
                                        <NumericFormat displayType={'text'} value={this.state.splitAmount != null ? this.state.splitAmount : 0 }
                                                      thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                {this.state.item != null && (this.state.item.avgWeight == null || this.state.item.avgWeight === 0) && this.state.count != null && this.state.count !== '' &&
                                    <Grid item xs={4} sm={2} sx={{ textAlign: 'right' }}>
                                        <FormControl>
                                            <FormLabel>{t('count')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={this.state.splitCount != null ? this.state.splitCount : 0 }
                                                        thousandSeparator={true} decimalScale={1}/>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                            <Grid item container spacing={2}>
                                <Grid item xs={6} sm={4}>{t('container')}</Grid>
                                <Grid item xs={4} sm={2}>{t('amount') + (this.state.amountUOMSel != null ? ' (' + this.state.amountUOMSel.label + ')' : null)}</Grid>
                                {this.state.item != null && (this.state.item.avgWeight == null || this.state.item.avgWeight === 0) && this.state.count != null && this.state.count !== '' &&
                                    <Grid item xs={2} sm={1}>{t('count')}</Grid>
                                }
                                <Grid item xs={6} sm={3}>{t('bin')}</Grid>
                            </Grid>
                            {this.state.splitContainers != null && this.state.splitContainers.length > 0 && this.state.splitContainers.map((row, i) =>
                                <Grid item container spacing={2} key={'splitRow-' + row._id + i}>
                                    <Grid item xs={6} sm={4}>
                                        <AutoCompleteField
                                            value={row.containerSel}
                                            options={this.state.containerOptions}
                                            onChange={this.changeContainerAuto('containerSel', 'container', i)}
                                            error={errors[`${t('container')} (${t('line')} {i})`] != null ? true : false}
                                            helperText={errors[`${t('container')} (${t('line')} {i})`]}
                                            required
                                            data-cy={"splitContainer" + i}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <TextField type="number" value={row.containerAmount} onChange={this.changeContainerNumber('containerAmount', i)} name={"containerAmount" + i}
                                        size="medium" fullWidth={true} required data-cy={"splitContainerAmount" + i}
                                        error={errors[`${t('containerAmount')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('containerAmount')} (${t('line')} ${i})`]}/>
                                    </Grid>
                                    {this.state.item != null && (this.state.item.avgWeight == null || this.state.item.avgWeight === 0) && this.state.count != null && this.state.count !== '' &&
                                        <Grid item xs={2} sm={1}>
                                            <TextField type="number" value={row.containerCount} onChange={this.changeContainerNumber('containerCount', i)} name={"containerCount" + i}
                                            size="medium" fullWidth={true} required data-cy={"splitContainerCount" + i}
                                            error={errors[`${t('containerCount')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('containerCount')} (${t('line')} ${i})`]}/>
                                        </Grid>
                                    }
                                    <Grid item xs={6} sm={3}>
                                        <AutoCompleteField
                                            value={row.binSel}
                                            options={this.state.bins}
                                            onChange={this.changeContainerAuto('binSel', 'bin', i)}
                                            error={errors[`${t('bin')} (${t('line')} {i})`] != null ? true : false}
                                            helperText={errors[`${t('bin')} (${t('line')} {i})`]}
                                            required
                                            data-cy={"splitBin" + i}
                                            groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                            noneMessage={t('noRoomSelected')}
                                        />
                                    </Grid>
                                    <Grid item xs={2} sm={1}>
                                        <IconButton aria-label="delete container" onClick={this.deleteContainer(i)} data-cy={"deleteSplitContainer" + i}>
                                            <RemoveCircleTwoToneIcon  />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item container spacing={2}>
                                <Grid item xs={6} sm={4}>
                                    <AutoCompleteField
                                        value={this.state.containerSel}
                                        options={this.state.containerOptions}
                                        onChange={this.changeAuto('containerSel', 'container')}
                                        error={errors[t('splitnumber')] != null ? true : false}
                                        helperText={errors[t('splitnumber')]}
                                        required
                                        data-cy="splitContainer"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <TextField type="number" value={this.state.containerAmount} onChange={this.changeNumber} name={"containerAmount"}
                                    size="medium" fullWidth={true} required data-cy="splitContainerAmount" 
                                    error={errors[t('splitNumber')] != null ? true : false} helperText={errors[t('splitNumber')]}/>
                                </Grid>
                                {this.state.item != null && (this.state.item.avgWeight == null || this.state.item.avgWeight === 0) && this.state.count != null && this.state.count !== '' &&
                                    <Grid item xs={2} sm={1}>
                                        <TextField type="number" value={this.state.containerCount} onChange={this.changeNumber} name={"containerCount"}
                                        size="medium" fullWidth={true} required data-cy="splitContainerCount"
                                        error={errors[t('splitNumber')] != null ? true : false} helperText={errors[t('splitNumber')]}/>
                                    </Grid>
                                }
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.binSel}
                                        options={this.state.bins}
                                        onChange={this.changeAuto('binSel', 'bin')}
                                        error={errors[t('bin')] != null ? true : false}
                                        helperText={errors[t('bin')]} data-cy="splitBin"
                                        required
                                        groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                        noneMessage={t('noRoomSelected')}
                                    />
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <IconButton aria-label="add container" onClick={this.addContainer} data-cy="addSplitContainer">
                                        <AddBoxTwoToneIcon  />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ModalDialog>
                }
                {this.state.combineDialog &&
                    <ModalDialog buttonStack={this.buttonStack('combine')} title={t('combineContainers')} toggleDialog={this.clearCombine}
                                 dialogStatus={this.state.combineDialog} fullWidth maxWidth='md' fScreen='md'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={4} sm={3} lg={2}>
                                        <DateSelect
                                            onChange={this.dateChange('combineDate')}
                                            value={this.state.combineDate}
                                            helperText={errors[t('combineDate')]}
                                            error={errors[t('combineDate')] != null ? true : false}
                                            disabled={!fieldAccess}
                                            label={t('date')}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                            data-cy="combineDate"
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4} lg={3}>
                                        <AutoCompleteField
                                            value={this.state.existingContainerSel}
                                            options={this.state.existingContainers}
                                            disabled={this.state.newContainer}
                                            onChange={this.changeAuto('existingContainerSel', 'existingContainer')}
                                            error={errors[t('existingContainer')] != null ? true : false}
                                            helperText={errors[t('existingContainer')]} label={t('targetContainer')}
                                            required
                                            data-cy="existingContainer"
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4} lg={2}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.newContainer} onChange={this.changeBool('newContainer')}
                                                    name="newContainer" disabled={this.state.existingContainer != null && this.state.existingContainer !== ''} data-cy='newContainerSwitch'
                                                />
                                            }
                                            label={t('newContainer')}
                                        />
                                    </Grid>
                                    <Grid item xs={6} lg={3} sx={{ textAlign: 'right', display: { xs: 'none', lg: 'inline-grid'}}}>
                                        <FormControl>
                                            <FormLabel>
                                                {t('combineAmount') + (this.state.amountUOMSel != null && this.state.amountUOMSel.label != null ? ' (' + this.state.amountUOMSel.label + ')' : '')}
                                            </FormLabel>
                                                <NumericFormat displayType={'text'}
                                                                value={this.state.amount != null && this.state.amount !== '' ? this.state.amount : 0}
                                                                thousandSeparator={true} decimalScale={3} />
                                        </FormControl>
                                    </Grid>
                                    {this.state.item != null && (this.state.item.avgWeight == null || this.state.item.avgWeight === 0) && this.state.item != null && this.state.item.category != null && this.state.item.category.hasCount &&
                                        <Grid item xs={6} lg={2} sx={{ textAlign: 'right', display: { xs: 'none', lg: 'inline-grid'}}}>
                                            <FormControl>
                                                <FormLabel>
                                                    {t('combineCount')}
                                                </FormLabel>
                                                    <NumericFormat displayType={'text'}
                                                                    value={this.state.combineCount != null && this.state.combineCount !== '' ? this.state.combineCount : 0}
                                                                    thousandSeparator={true} decimalScale={0} />
                                            </FormControl>
                                        </Grid>
                                    }
                                    <Grid item xs={8} sm={6} lg={4}>
                                        <AutoCompleteField
                                            value={this.state.sourceLotSel}
                                            options={this.state.sourceLots}
                                            disabled={this.state.combineContainers != null && this.state.combineContainers.length > 0}
                                            onChange={this.changeAuto('sourceLotSel', 'sourceLot')}
                                            error={errors[t('sourceLot')] != null ? true : false}
                                            helperText={errors[t('sourceLot')]} label={t('sourceLot')}
                                            data-cy="sourceLot"
                                        />
                                    </Grid>
                                    {this.state.newContainer &&
                                        <Grid item xs={6} lg={3}>
                                            <AutoCompleteField
                                                value={this.state.combineBinSel}
                                                options={this.state.bins}
                                                onChange={this.changeAuto('combineBinSel', 'combineBin')}
                                                error={errors[t('combineBin')] != null ? true : false}
                                                helperText={errors[t('combineBin')]} label={t('bin')}
                                                required
                                                data-cy="combineBin"
                                                groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                noneMessage={t('noRoomSelected')}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                                <br/>
                                <BoxTitle title={t('sourceContainers')}/>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={6}>{t('container')}</Grid>
                                    <Grid item xs={4} sm={2}>{t('amount')}</Grid>
                                    {this.state.item != null && (this.state.item.avgWeight == null || this.state.item.avgWeight === 0) && this.state.item.category != null && this.state.item.category.hasCount &&
                                        <Grid item xs={4} sm={2}>{t('count')}</Grid>
                                    }
                                </Grid>
                                {this.state.combineContainers.length > 0 && this.state.combineContainers.map((row, i) =>
                                    <Grid container spacing={3} key={'combineRow-' + row._id + i}>
                                        <Grid item xs={6} sm={4}>
                                            {row.containerSel.label}
                                        </Grid>
                                        <Grid item xs={2} sm={2}>
                                            <CheckboxField
                                            checked={row.allContainer} onChange={this.changeRowBool('allContainer', i)}
                                            label={t('all')} name="allContainer"
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <TextField type="number" value={row.amount} onChange={this.changeRowNumber('amount', i)} name={"amount" + i}
                                            size="medium" fullWidth={true} disabled={row.allContainer}
                                            error={errors[`${t('amount')} (${t('line')} {i})`] != null ? true : false} helperText={errors[`${t('amount')} (${t('line')} {i})`]}/>
                                        </Grid>
                                        {this.state.item != null && (this.state.item.avgWeight == null || this.state.item.avgWeight === 0) && this.state.item.category != null &&  this.state.item.category.hasCount &&
                                            <Grid item xs={4} sm={2}>
                                                <TextField type="number" value={row.count} onChange={this.changeRowNumber('count', i)} name={"count" + i}
                                                size="medium" fullWidth={true} disable={row.allContainer}
                                                error={errors[`${t('count')} (${t('line')} {i})`] != null ? true : false} helperText={errors[`${t('count')} (${t('line')} {i})`]}/>
                                            </Grid>
                                        }
                                        <Grid item xs={2} sm={1}>
                                            <IconButton aria-label="delete row" onClick={this.deleteCombineRow(i)}>
                                                <RemoveCircleTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={4}>
                                        <AutoCompleteField
                                            value={this.state.fromContainerSel}
                                            options={this.state.sourceContainers}
                                            onChange={this.changeAuto('fromContainerSel', 'fromContainer')}
                                            error={errors[t('fromContainer')] != null ? true : false}
                                            helperText={errors[t('fromContainer')]} 
                                            required
                                            data-cy="combineFromContainer"
                                        />
                                    </Grid>
                                    <Grid item xs={2} sm={2}>
                                        <CheckboxField
                                        checked={this.state.allContainer} onChange={this.changeBool('allContainer')}
                                        label={t('all')} name="allContainer" disabled={this.state.fromContainer == null || this.state.fromContainer === ''}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <TextField type="number" value={this.state.fromAmount} onChange={this.changeNumber} name={"fromAmount"}
                                        size="medium" fullWidth={true} required
                                        error={errors[t('fromAmount')] != null ? true : false} helperText={errors[t('fromAmount')]}/>
                                    </Grid>
                                    {this.state.item != null && (this.state.item.avgWeight == null || this.state.item.avgWeight === 0) && this.state.item.category != null && this.state.item.category.hasCount &&
                                        <Grid item xs={4} sm={2}>
                                            <TextField type="number" value={this.state.fromCount} onChange={this.changeNumber} name={"fromCount"}
                                            size="medium" fullWidth={true} required
                                            error={errors[t('fromCount')] != null ? true : false} helperText={errors[t('fromCount')]}/>
                                        </Grid>
                                    }
                                    <Grid item xs={2} sm={1}>
                                        <IconButton aria-label="Add Row" onClick={this.addCombineRow} data-cy="addCombineRow">
                                            <AddBoxTwoToneIcon  />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} direction='row-reverse'>
                                    <Grid item xs={3} lg={3} sx={{ textAlign: 'right', display: { xs: 'inline-grid', lg: 'none' }}} >
                                        <FormControl>
                                            <FormLabel>
                                                {t('combineAmount') + (this.state.amountUOMSel != null && this.state.amountUOMSel.label != null ? ' (' + this.state.amountUOMSel.label + ')' : '')}
                                            </FormLabel>
                                                <NumericFormat displayType={'text'}
                                                                value={this.state.amount != null && this.state.amount !== '' ? this.state.amount : 0}
                                                                thousandSeparator={true} decimalScale={3} />
                                        </FormControl>
                                    </Grid>
                                    {this.state.item != null && (this.state.item.avgWeight == null || this.state.item.avgWeight === 0) && this.state.item != null && this.state.item.category != null && this.state.item.category.hasCount &&
                                        <Grid item xs={3} lg={2} sx={{ textAlign: 'right', display: { xs: 'inline-grid', lg: 'none' }} }>
                                            <FormControl>
                                                <FormLabel>
                                                    {t('combineCount')}
                                                </FormLabel>
                                                    <NumericFormat displayType={'text'}
                                                                    value={this.state.combineCount != null && this.state.combineCount !== '' ? this.state.combineCount : 0}
                                                                    thousandSeparator={true} decimalScale={0} />
                                            </FormControl>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </ModalDialog>
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.lot}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Lot));
