import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import { setNotificationCount } from '../../../../actions/notification';
import axios from 'axios';
import Link from '../../Link/Link';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import NotificationsIcon from '@mui/icons-material/NotificationsTwoTone';
import NoneIcon from '@mui/icons-material/NotInterested';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Aux from '../../../../hoc/Auxilary/Auxilary';
import DateDisplay from '../../DateDisplay/DateDisplay';
import Icon from '../../Icons/Icons';
import NotificationDialog from '../../../General/NotificationDialog';

export default function Notifications(props) {
    const history = useHistory();
    const anchorRef = useRef(null);
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const notification = useSelector(state => state.notification);
    const dateFormat = auth.user.dateFormat;
    const constants = auth.constants;
    const screenDefs = auth.screenDefs;
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);
    const { t } = useTranslation('translation');
    const { location } = props;

    const textNew = {
        fontWeight: 'bold'
    }
    const textUnread = {
        fontStyle: 'italic',
        fontWeight: 'bold'
    }
    const iconNew = {
        color: 'primary.main'
    }

    const loadData = useCallback(async () => {
        await axios.get('/api/notificationscount')
        .then(result => {
            dispatch(setNotificationCount({count: result.data.totalCount != null && result.data.totalCount !== '' ? result.data.totalCount : 0,
                                            lastFive: result.data.lastFive != null && result.data.lastFive !== '' ? result.data.lastFive : []}));
        })
        .catch(err => {
            dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        });
    }, [dispatch, t]);

    useEffect(() => {
        if(!initialLoad){
            loadData();
            setInitialLoad(true);
        }
        const interval = setInterval(() => {
            loadData();
        }, 120000);
        return () => clearInterval(interval);
    }, [initialLoad, loadData]);    

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const toggleDialog = () => {
        setOpenDialog(prevOpenDialog => !prevOpenDialog);
    };

    const clickMessage = (i, link) => {
        let record = notification.lastFive[i];
        if(record.status !== constants.notificationStatuses.read) {
            axios.post('/api/notificationstatus/' + record._id, {status: constants.notificationStatuses.read})
            .then(result => {
                history.push(link);
                loadData();
            })
            .catch(err => {
                dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            })
        }else{
            history.push(link);
        }
        handleClose();
    }

    
    const button = (
        <Aux>
            <IconButton onClick={handleToggle} id='notificationIcon' aria-haspopup='true' sx={{ color: '#FFF' }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}>
                <Badge overlap="rectangular" badgeContent={notification.count} color={location === 'bottom' ? 'primary' : 'secondary'} >
                    {notification.count > 0 ? <NotificationsActiveIcon /> : <NotificationsIcon /> }
                </Badge>
            </IconButton>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                sx={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                        <Grid container sx={{ justifyContent: "space-between" }}>
                            <Grid item>
                                <Typography variant='h6' sx={{padding: '3px 0px 0px 7px'}}>{t('notifications')}</Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    startIcon={<NotificationsIcon />}
                                    sx={{padding: '5px'}}
                                    onClick={() => toggleDialog()}
                                >
                                    {t('more')}
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider variant={'fullWidth'} />
                        <ClickAwayListener onClickAway={() => handleClose()}>
                            <List sx={{ width: '100%', maxWidth: 360, backgroundColor: 'background.paper' }}>
                                {notification.lastFive.length > 0 ? 
                                    notification.lastFive.map((x, i) => {
                                        return (
                                            <Aux key={'notification-list-' + i}>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar sx={{ bgColor: 'rgba(0, 0, 0, 0.12)' }}>
                                                            <Icon sx={x.status === constants.notificationStatuses.new || x.status === constants.notificationStatuses.unread ? 
                                                                      iconNew : null} screenDefs={screenDefs} screenDef={x.screen != null && x.screen !== '' ? x.screen : ''} />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        disableTypography
                                                        primary={
                                                            <Typography sx={x.status === constants.notificationStatuses.new ? textNew :
                                                                            x.status === constants.notificationStatuses.unread ? textUnread : null}
                                                                        >
                                                                <Link onClick={() => clickMessage(i, x.link)} sx={{ cursor: 'pointer' }}>{x.message}</Link>
                                                            </Typography>
                                                        } 
                                                        secondary={
                                                            <Typography color='textSecondary' variant='subtitle2'>
                                                                <DateDisplay value={x.date} format={dateFormat}/>
                                                            </Typography>
                                                        } 
                                                    />
                                                </ListItem>
                                                {i !== 4 &&
                                                    <Divider variant={'middle'} />
                                                }
                                            </Aux>                                        
                                        )                                       
                                    }) : 
                                    <ListItem>
                                        <ListItemAvatar>
                                        <Avatar>
                                            <NoneIcon />
                                        </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={t('none')} secondary={t('noNotifsAvailable')}/>
                                    </ListItem>
                                }
                            </List>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
            </Popper>
            {openDialog &&
                <NotificationDialog open={openDialog} toggleDialog={() => toggleDialog()} />
            }            
        </Aux>
    );

    return button;
}