import React, {Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import CheckboxField from '../UI/CheckboxField/CheckboxField'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';
import { SimpleUnlockButton, ShipButton, DeviationButton, SaveButton, TextOnlyClickButton,
    ReleaseButton, ClearButton, DeleteButton } from '../UI/Buttons/Buttons';
import { shipButtonSecurity, saveButtonSecurity,
    fieldSecurity, releaseButtonSecurity} from '../../helpers/externalTreatmentSecurityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { NumericFormat } from 'react-number-format';
class ExternalTreatment extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            lots: [],
            lotList: [],
            lotsSel: [],
            requestor: '',
            requestorSel: null,
            requestDate: new Date(),
            containers: [],
            containerList: [],
            amount: '',
            amountUOM: null,
            amountUOMSel: null,
            sendDate: new Date(),
            sendShipper: '',
            sendShipperSel: null,
            sendShipmentNumber: '',
            shippers: [],
            returnDate: new Date(),
            returnShipper: '',
            returnShipperSel: null,
            returnShipmentNumber: '',
            vendor: '',
            vendorSel: null,
            vendors: [],
            treatmentType: '',
            treatmentTypeSel: null,
            treatmentTypes: [],
            sterilizationType: '',
            sterilizationTypeSel: null,
            sterilizationTypes: [],
            radiationAmount: '',
            status: '',
            statusSel: null,
            statuses: [],
            documents: [],
            sops: [],
            location: '',
            locationSel: null,
            locations: [],
            uoms: [],
            users: [],
            managers: [],
            qas: [],
            sites: [],
            vendorPeople: [],
            released : false,
            showContainer: false,
            container: '',
            containerSel: null,
            containerAmount: '',
            containerAmountUOM: '',
            containerAmountUOMSel: null,
            originalAmount: '',
            containerId: '',
            shippingContainers: [],
            showShipping: false,
            shippingNumber: '',
            shipContainers: [],
            shipContainersSel: [],
            shippingId: '',
            shipped: false,
            returnLocation: '',
            returnLocationSel: null,
            returnBin: '',
            returnBinSel: null,
            bins: [],
            returnComments: '',
            returned: false,
            reviewer: null,
            signer: null,
            shipSignature: null,
            receiveSignature: null,
            reviewed: false,
            signed: false,
            issues: [],
            returnHasIssues: false,
            returnIssues: [],
            returnIssuesSel: [],
            deviations: [],
            fieldAccess: false,
            reviewSigSec: false,
            approveSigSec: false,
            shipSec: false,
            releaseSec: false,
            saveSec: false,
            security: [],
            items: [],
            item: '',
            itemSel: null,
            optionsMenu: [],
            flagForSignature: 0,
            renderNum: Math.random(),
            enableSave: true,
            form: '',
            hasForm: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.bin, common.cacheValues.activeLocation, common.cacheValues.status, common.cacheValues.quantityUOM,
                                common.cacheValues.item, common.cacheValues.cropTreatmentVendor, common.cacheValues.treatmentType, 
                                common.cacheValues.externalTreatmentIssue, common.cacheValues.sterilizationType, common.cacheValues.shippingVendor,
                                common.cacheValues.externalTreatmentForm], this.props.auth.constants, null, this.props.auth.user.tenant);
        let bins = cacheValues.bins;
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.externalTreatment, this.props.auth.user.tenant);
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        let requestorSel = userValues.create.find(x => x.value === this.props.auth.user.id);
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ? 
            this.props.auth.user.defaultLocation : '';
        let locSel = location !== '' ? locations.find(x => x.value === location) : null;
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.constants);
        const saveSec = saveButtonSecurity(this.props.permission, statusSel.value, this.props.auth.constants);
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        this.setState({
            locations: locations,
            location: location,
            locationSel: locSel,
            uoms: cacheValues.quantityUOMs,
            items: cacheValues.items,
            users: userValues.create,
            treatmentTypes: cacheValues.treatmentTypes,
            vendors: cacheValues.cropTreatmentVendors,
            issues: cacheValues.externalTreatmentIssues,
            shippers: cacheValues.shippingVendors,
            managers: userValues.review,
            qas: userValues.approve,
            statuses: cacheValues.statuses,
            sterilizationTypes: cacheValues.sterilizationTypes,
            bins: bins,
            statusSel: statusSel,
            status: this.props.auth.constants.statuses.new,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            requestor: this.props.auth.user.id,
            requestorSel: requestorSel,
            fieldAccess: fieldAccess,
            saveSec: saveSec,
            security: this.props.permission,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/exttreats', label: this.props.t('externalTreatments'), screen: this.props.auth.screenDefs.ExternalTreatment},
                    { path: 'active', label: this.props.t('externalTreatments'), screen: this.props.auth.screenDefs.ExternalTreatment}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        let result = await axios.get('/api/exttreat/' + this.state._id);
        let statusSel = this.state.statuses.find(x => x.value === result.data.status);
        let lotsSel = [];
        result.data.lots.forEach(row => {
            let val = result.data.lotOptions.find(x => x.value === row);
            lotsSel.push(val);
        });
        let amountUOMSel = result.data.amountUOM != null ? this.state.uoms.find(x => x.value === result.data.amountUOM) : null;
        let requestorSel = this.state.users.find(x => x.value === result.data.requestor);
        let vendorSel = this.state.vendors.find(x => x.value === result.data.vendor);
        let treatmentTypeSel = this.state.treatmentTypes.find(x => x.value === result.data.treatmentType);
        let sterilizationTypeSel = this.state.sterilizationTypes.find(x => x.value === result.data.sterilizationType);
        let locationSel = this.state.locations.find(x => x.value === result.data.location);
        let released = result.data.status !== this.props.auth.constants.statuses.new;
        let shipped = result.data.status !== this.props.auth.constants.statuses.new && 
            result.data.status !== this.props.auth.constants.statuses.released;
        let shippingContainers = result.data.shippingContainers;
        shippingContainers.forEach(row => {
            if(row.containers != null && row.containers.length > 0){
                let containersSel = [];
                row.containers.forEach(container => {
                    let value = result.data.containerList.find(x => x.value === container);
                    containersSel.push(value);
                });
                row.containersSel = containersSel;
            }
        });
        let sendShipperSel = result.data.sendShipper != null ? this.state.shippers.find(x => x.value === result.data.sendShipper) : null;
        let returnShipperSel = result.data.returnShipper != null ? this.state.shippers.find(x => x.value === result.data.returnShipper) : null;
        let returnLocationSel = result.data.returnLocation != null ? 
                this.state.locations.find(x => x.value === result.data.returnLocation) : null;
        let returnBinSel = result.data.returnBin != null ? this.state.bins.find(x => x.value === result.data.returnBin) : null;
        let returned = result.data.status !== this.props.auth.constants.statuses.new && 
                result.data.status !== this.props.auth.constants.statuses.released && 
                result.data.status !== this.props.auth.constants.statuses.shipped;
        let reviewed = result.data.status === this.props.auth.constants.statuses.reviewed || 
            result.data.status === this.props.auth.constants.statuses.signed;
        let signed = result.data.status === this.props.auth.constants.statuses.signed;
        let returnIssuesSel = [];
        if(result.data.returnIssues != null && result.data.returnIssues.length > 0){
            result.data.returnIssues.forEach(row => {
                let val = this.state.issues.find(x => x.value === row);
                returnIssuesSel.push(val);
            });
        }
        let itemSel = this.state.items.find(x => x.value === result.data.item);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.constants);
        const shipSigSec = (result.data._id != null && result.data._id !== '' && released);
        const receiveSigSec = (result.data._id != null && result.data._id !== '' && shipped);
        const reviewSigSec = (result.data._id != null && result.data._id !== '' && result.data.receiveSignature != null);
        const approveSigSec = (result.data.reviewer != null && result.data.reviewer._id != null && result.data.reviewer.signature != null &&
                               result.data.reviewer.signature !== '');
        //const reviewSigSec = reviewSigSecurity(this.props.permission.review, result.data.reviewer, statusSel.value, this.props.auth.constants);
        //const approveSigSec = approveSigSecurity(this.props.permission.approve, result.data.signer, statusSel.value, this.props.auth.constants);
        const saveSec = saveButtonSecurity(this.props.permission, statusSel.value, this.props.auth.constants);
        const releaseSec = releaseButtonSecurity(this.props.permission, statusSel.value, this.props.auth.constants);
        const shipSec = shipButtonSecurity(this.props.permission, statusSel.value, this.props.auth.constants);
        const optionsMenu = [
            { 
                security: shipSigSec, user: this.props.auth.user.id, users: this.state.users, name: t('shipper'), 
                title: t('shippedBy'), setId: () => this.loadRecord(), signature: result.data.shipSignature, object: result.data._id,
                objectType: this.props.auth.constants.objectIds.externalTreatment, type: this.props.auth.constants.signatureTypes.shipper,
                menuType: this.props.auth.constants.optionMenuTypes.signature, 
            },
            { 
                security: receiveSigSec, user: this.props.auth.user.id, users: this.state.users, name: t('receiver'), 
                title: t('receivedBy'), setId: () => this.loadRecord(), signature: result.data.receiveSignature, object: result.data._id,
                objectType: this.props.auth.constants.objectIds.externalTreatment, type: this.props.auth.constants.signatureTypes.receiver,
                menuType: this.props.auth.constants.optionMenuTypes.signature, 
            },
            { 
                security: reviewSigSec, user: this.props.auth.user.id, users: this.state.managers, name: t('review'),
                title: t('reviewedBy'), setId: () => this.loadRecord(), signature: result.data.reviewer, object: result.data._id, 
                objectType: this.props.auth.constants.objectIds.externalTreatment, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: this.state.qas, name: t('approve'),
                title: t('approvedBy'), setId: () => this.loadRecord(), object: result.data._id, signature: result.data.signer, 
                objectType: this.props.auth.constants.objectIds.externalTreatment, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        let hasForm = this.state.hasForm;
        if(!hasForm && result.data.form != null && result.data.form !== '')
            hasForm = true;
        const flagForSignature = result.data.status === this.props.auth.constants.statuses.released ? 1 :
                                 result.data.status === this.props.auth.constants.statuses.shipped ? 2 :
                                 result.data.status === this.props.auth.constants.statuses.returned ? 3 :
                                 result.data.status === this.props.auth.constants.statuses.reviewed ? 4 : 0;
        this.setState({
            number: result.data.number,
            status: result.data.status,
            statusSel: statusSel,
            lots: result.data.lots,
            lotsSel: lotsSel,
            amount: result.data.amount,
            amountUOM: result.data.amountUOM,
            amountUOMSel: amountUOMSel,
            sendDate: result.data.sendDate != null && result.data.sendDate !== '' ? result.data.sendDate : new Date(),
            sendShipper: result.data.sendShipper,
            sendShipperSel: sendShipperSel,
            sendShipmentNumber: result.data.sendShipmentNumber,
            returnDate: result.data.returnDate != null && result.data.returnDate !== '' ? result.data.returnDate : new Date(),
            returnShipper: result.data.returnShipper,
            returnShipperSel: returnShipperSel,
            returnShipmentNumber: result.data.returnShipmentNumber,
            returnComments: result.data.returnComents,
            requestor: result.data.requestor,
            requestorSel: requestorSel,
            requestDate: result.data.requestDate,
            containers: result.data.containers,
            vendor: result.data.vendor,
            vendorSel: vendorSel,
            treatmentType: result.data.treatmentType,
            treatmentTypeSel: treatmentTypeSel,
            sterilizationType: result.data.sterilizationType,
            sterilizationTypeSel: sterilizationTypeSel,
            location: result.data.location,
            locationSel: locationSel,
            radiationAmount: result.data.radiationAmount,
            released: released,
            containerList: result.data.containerList,
            shippingContainers: shippingContainers,
            shipped: shipped,
            returnLocationSel: returnLocationSel,
            returnLocation: result.data.returnLocation,
            returnBin: result.data.returnBin,
            returnBinSel: returnBinSel,
            returned: returned,
            documents: result.data.documents,
            reviewer: result.data.reviewer,
            signer: result.data.signer,
            shipSignature: result.data.shipSignature,
            receiveSignature: result.data.receiveSignature,
            reviewed: reviewed,
            signed: signed,
            returnHasIssues: result.data.returnHasIssues != null && result.data.returnHasIssues ? true : false,
            returnIssues: result.data.returnIssues,
            returnIssuesSel: returnIssuesSel,
            deviations: result.data.deviations,
            fieldAccess: fieldAccess,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            saveSec: saveSec,
            releaseSec: releaseSec,
            shipSec: shipSec,
            item: result.data.item,
            itemSel: itemSel,
            lotList: result.data.lotOptions,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            renderNum: Math.random(),
            form: result.data.form != null ? JSON.parse(result.data.form) : null,
            hasForm: hasForm
        }, () => {
            const crumbs = [
                { path: '/#/exttreats', label: this.props.t('externalTreatments'), screen: this.props.auth.screenDefs.ExternalTreatment},
                { path: 'active', label: this.props.t('externalTreatments') + ' - ' + result.data.number, screen: this.props.auth.screenDefs.ExternalTreatment}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(newValue != null)
            this.processChange(data, newValue.value);
    };

    async processChange(name, value){
        if(name === 'vendor' && value !== ''){
            var siteData = await axios.get('/api/vendorsiteselect/' + value);
            var sites = siteData.data
            var peopleData = await axios.get('/api/vendoremployeeselect/' + value);
            var vendorPeople = peopleData.data;
            this.setState({
                sites: sites,
                vendorPeople: vendorPeople
            });
        } else if(name === 'container'){
            let contReq = await axios.get('/api/container/' + value);
            let amountUOMSel = contReq.data.uom != null ? this.state.uoms.find(x => x.value === contReq.data.uom._id) : null;
            this.setState({
                originalAmount: contReq.data.quantity,
                containerAmountUOMSel: amountUOMSel,
                containerAmountUOM: amountUOMSel != null ? amountUOMSel.value : ''
            });
        } else if(name === 'item'){
            let lots = await axios.get('/api/itemlots/' + value);
            this.setState({lotList: lots.data, lots: [], lotsSel: []});
        }
    };

    changeMulti = (name, data) => (e, newValue) => {
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        })
    };

    changeIssues = (e) => {
        this.setState({returnHasIssues: !this.state.returnHasIssues});
    }

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value});
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async (e) => {
        const t = this.props.t;
        if(this.state.status === this.props.auth.constants.statuses.new){
            let valid = this.validate();
            if(!valid) {
                this.setState({ enableSave: true });
                return;
            }
            let form = this.state.form;
            if(this.state.hasForm && this.state.value != null){
                for (let key in this.state.value){
                    let val = this.state.value[key];
                    for (let i = 0; i < form.length; i++){
                        let field = form[i];
                        if (field.name === key){
                            field.value = val;
                            break;
                        }
                    }
                }
                let errors = {};
                let error = false;
                for(let row of form){
                    if(row.required === true && (row.value == null || row.value === '')){
                        error = true;
                        break;
                    }
                }
                if(error === true){
                    errors[t('form')] = t('fieldsIncomplete');
                    this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                    this.setState({enableSave: true});
                    return;
                }
            }            
            let data = {
                _id: this.state._id,
                location: this.state.location,
                vendor: this.state.vendor,
                requestor: this.state.requestor,
                requestDate: this.state.requestDate,
                treatmentType: this.state.treatmentType,
                sterilizationType: this.state.sterilizationType,
                radiationAmount: this.state.radiationAmount,
                lots: this.state.lots,
                status: this.state.status,
                item: this.state.item,
                form: form != null && form !== '' ? JSON.stringify(form) : null
            };
            try {
                let result = await axios.post('/api/exttreatreq', data);
                if(this.state._id == null || this.state._id === '')
                    this.props.history.replace('/exttreat/' + result.data.id);
                this.setState({_id: result.data.id, enableSave: true}, () => {
                    this.loadRecord();
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                });                
            } catch(err){
                this.setState({ enableSave: true }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
                });                
            }
        } else if (this.state.status === this.props.auth.constants.statuses.released){
            let valid = this.validateShippingSave();
            if(!valid) {
                this.setState({ enableSave: true });
                return;
            }
            let data = {
                _id: this.state._id,
                sendDate: this.state.sendDate,
                sendShipper: this.state.sendShipper,
                sendShipmentNumber: this.state.sendShipmentNumber
            }
            try {
                let result = await axios.post('/api/exttreatshipsave', data);
                this.setState({ enableSave: true }, () => {
                    this.loadRecord();
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                });                
            } catch(err){
                this.setState({ enableSave: true }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
                });                
            }
        } else if(this.state.status === this.props.auth.constants.statuses.shipped || 
                this.state.status === this.props.auth.constants.statuses.returned){
            let valid = this.validateReturn();
            if(!valid) {
                this.setState({ enableSave: true });
                return;
            }
            let data = {
                _id: this.state._id,
                returnDate: this.state.returnDate,
                returnLocation: this.state.returnLocation,
                returnBin: this.state.returnBin,
                returnShipper: this.state.returnShipper,
                returnShipmentNumber: this.state.returnShipmentNumber,
                returnComments: this.state.returnComments,
                returnHasIssues: this.state.returnHasIssues,
                returnIssues: this.state.returnIssues
            };
            try {
                let result = await axios.post('/api/exttreatreturnsave', data);
                this.setState({ enableSave: true }, () => {
                    this.loadRecord();
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                });                
            } catch(err){
                this.setState({ enableSave: true }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
                });
            }
        }
    };

    validate(){
        const t = this.props.t;
        let state = this.state;
        let errors = {};
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.requestor == null || state.requestor === '')
            errors[t('requestor')] = t('required');
        if(state.requestDate == null || state.requestDate === '')
            errors[t('requestDate')] = t('required');
        if(state.vendor == null || state.vendor === '')
            errors[t('vendor')] = t('required');
        if(state.treatmentType == null || state.treatmentType === '')
            errors[t('treatmentType')] = t('required');
        if(state.sterilizationType == null || state.sterilizationType === '')
            errors[t('sterilizationType')] = t('required');
        if(state.radiationAmount == null || state.radiationAmount === '' || state.radiationAmount === '0')
            errors[t('radiationAmount')] = t('required');
        if(state.item == null || state.item === '')
            errors[t('item')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    };

    validateShippingSave(){
        const t = this.props.t;
        let errors = {};
        if(this.state.sendDate == null || this.state.sendDate === '')
            errors[t('sendDate')] = t('required');
        if(this.state.sendShipper == null || this.state.sendShipper === '')
            errors[t('sendShipper')] = t('required');
        if(this.state.sendShipmentNumber == null || this.state.sendShipmentNumber === '')
            errors[t('sendShipmentNumber')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    };

    validateReturn(){
        const t = this.props.t;
        let errors = {};
        if(this.state.returnDate == null || this.state.returnDate === '')
            errors[t('returnDate')] = t('required');
        if(this.state.returnLocation == null || this.state.returnLocation === '')
            errors[t('returnLocation')] = t('required');
        if(this.state.returnBin == null || this.state.returnBin === '')
            errors[t('returnBin')] = t('required');
        if(this.state.returnShipper == null || this.state.returnShipper === '')
            errors[t('returnShipper')] = null;
        if(this.state.returnShipmentNumber == null || this.state.returnShipmentNumber === '')
            errors[t('returnShipmentNumber')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    release = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._release(e));
    }

    _release = async (e) => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid || this.state._id == null || this.state._id === '')
            return;
        let data = {
            _id: this.state._id
        }
        try {
            let result = await axios.post('/api/exttreatrelease', data);
            this.setState({enableSave: true});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title:t('error'), severity: 'error'}});
        }
    };

    ship = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: true}, () => this._ship(e));
    };

    _ship = async (e) => {
        const t = this.props.t;
        let valid = this.validateShip();
        if(!valid)
            return;
        let data = {
            _id: this.state._id
        }
        try {
            let result = await axios.post('/api/exttreatship', data);
            this.setState({enableSave: true});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    validateShip(){
        const t = this.props.t;
        let errors= {};
        let state = this.state;
        if(state.shippingContainers == null || state.shippingContainers.length === 0)
            errors[t('shippingContainers')] = t('required');
        if(state.containers == null || state.containers.length === 0)
            errors[t('containers')] = t('required');
        if(state.sendDate == null || state.sendDate === '')
            errors[t('sendDate')] = t('required');
        if(state.sendShipmentNumber == null || state.sendShipmentNumber === '')
            errors[t('sendShipmentNumber')] = t('required');
        if(state.sendShipper == null || state.sendShipper === '')
            errors[t('sendShipper')] = t('required');
        else {
            for(let row of state.containers){
                let found = false;
                for(let shipRow of state.shippingContainers){
                    let shipContainer = shipRow.containers.find(x => x === row.container._id);
                    if(shipContainer != null && shipContainer !== ''){
                        found = true;
                        break;
                    }
                } 
                if(!found){
                    errors[t('shippingContainers')] = t('notAllContainersShipped');
                    break;
                }
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    addContainer = (e) => {
        this.setState({showContainer: true});
    }

    editContainer = (index) => (e) => {
        let container = this.state.containers[index];
        let containerSel = this.state.containerList.find(x => x.value === container.container._id);
        let containerAmountUOMSel = this.state.uoms.find(x => x.value === container.uom._id);
        this.setState({
            showContainer: true,
            containerId: container._id,
            container: container.container._id,
            containerSel: containerSel,
            containerAmount: container.amount,
            containerAmountUOM: container.uom._id,
            containerAmountUOMSel: containerAmountUOMSel,
            originalAmount: container.originalAmount
        });
    };

    clearContainer = (e) => {
        this.setState({
            showContainer: false,
            containerId: '',
            container: '',
            containerSel: null,
            containerAmount: '',
            containerAmountUOM: '',
            containerAmounUOMSel: null,
            originalAmount: ''
        });
    };

    deleteContainer = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteContainer(e));
    }

    _deleteContainer = async (e) => {
        const t = this.props.t;
        if(this.state.containerId != null && this.state.containerId !== ''){
            let data = {
                _id: this.state._id,
                containerId: this.state.containerId
            };
            try {
                let result = await axios.post('/api/exttreatcontainerdel', data);
                let containerResult = await axios.get('/api/exttreatcontainers/' + this.state._id);
                let amountUOMSel = containerResult.data.amountUOM != null ? this.state.uoms.find(x => x.value === containerResult.data.amountUOM) : null;
                this.setState({
                    containers: containerResult.data.containers,
                    amount: containerResult.amount,
                    amountUOM: containerResult.data.amountUOM,
                    amountUOMSel: amountUOMSel,
                    enableSave: true
                });
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.clearContainer();
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.clearContainer();
        }
        
    };

    saveContainer = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._saveContainer(e));
    };

    _saveContainer = async (e) => {
        const t = this.props.t;
        let valid = this.validateContainer();
        if(!valid)
            return;
        let data = {
            _id: this.state._id,
            containerId: this.state.containerId,
            container: this.state.container,
            amount: this.state.containerAmount,
            amountUOM: this.state.containerAmountUOM,
            originalAmount: this.state.originalAmount
        };
        try {
            let result = await axios.post('/api/exttreatcontainer', data);
            let containerResult = await axios.get('/api/exttreatcontainers/' + this.state._id);
            let amountUOMSel = containerResult.data.amountUOM != null ? this.state.uoms.find(x => x.value === containerResult.data.amountUOM) : null;
            this.setState({
                containers: containerResult.data.containers,
                amount: containerResult.amount,
                amountUOM: containerResult.data.amountUOM,
                amountUOMSel: amountUOMSel,
                enableSave: true
            });
            this.clearContainer();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    validateContainer(){
        const t = this.props.t;
        let errors = {};
        if(this.state.container == null || this.state.container === '')
            errors[t('container')] = t('required');
        if(this.state.containerAmount == null || this.state.containerAmount === '')
            errors[t('containerAmount')] = t('required');
        if(this.state.originalAmount == null || this.state.originalAmount === '')
            errors[t('originalAmount')] = t('required');
        if(this.state.containerAmountUOM == null || this.state.containerAmountUOM === '')
            errors[t('containerAmountUOM')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    unlock = async (e) => {
        const t = this.props.t;
        let data = {_id: this.state._id};
        try {
            let result = await axios.post('/api/exttreatunlock', data);
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    addShippingContainer = (e) => {
        this.setState({showShipping: true});
    };

    editShippingContainer = (index) => (e) => {
        let container = this.state.shippingContainers[index];
        this.setState({
            showShipping: true,
            shipContainers: container.containers,
            shippingNumber: container.number,
            shipContainersSel: container.containersSel,
            shippingId: container._id
        });
    };

    clearShippingContainer = (e) => {
        this.setState({
            showShipping: false,
            shipContainers: [],
            shippingNumber: '',
            shipContainersSel: [],
            shippingId: ''
        });
    };

    saveShippingContainer = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._saveShippingContainer(e));
    };

    _saveShippingContainer = async (e) => {
        const t = this.props.t;
        let valid = this.validateShipping();
        if(!valid)
            return;
        let data = {
            _id: this.state._id,
            shippingId: this.state.shippingId,
            shippingNumber: this.state.shippingNumber,
            containers: this.state.shipContainers
        };
        try {
            let result = await axios.post('/api/exttreatshipping', data);
            this.setState({enableSave: true});
            this.loadRecord();
            this.clearShippingContainer()
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    validateShipping(){
        const t = this.props.t;
        let errors = {};
        if(this.state.shippingNumber == null || this.state.shippingNumber === '')
            errors[t('shippingNumber')] = t('required');
        if(this.state.shipContainers == null || this.state.shipContainers.length === 0)
            errors[t('shipContainers')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteShippingContainer = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteShippingContainer(e));
    }

    _deleteShippingContainer = async (e) => {
        const t = this.props.t;
        let data = {
            _id: this.state._id,
            shippingId: this.state.shippingId
        };
        try {
            let result = await axios.post('/api/exttreatshipdel', data);
            this.setState({enableSave: true});
            this.loadRecord();
            this.clearShippingContainer();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    addDeviation = (e) => {
        this.props.history.push('/deviation?externalTreatment=' + this.state._id);
    };
    
    render(){
        let errors = this.props.errors;
        let released = this.state.released;
        let shipped = this.state.shipped;
        let reviewed = this.state.reviewed;
        let fieldAccess = this.state.fieldAccess;
        const t = this.props.t;
        const micro = this.props.auth.user.micro;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.externalTreatment}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Panel>
                            <Grid container spacing={3}>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.locationSel}
                                            options={this.state.locations}
                                            onChange={this.changeAuto('locationSel', 'location')}
                                            error={errors[t('location')] != null ? true : false}
                                            helperText={errors[t('location')]}
                                            label={t('location')}
                                            disabled={released || fieldAccess ? false : true}
                                            required
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.requestorSel}
                                            options={this.state.managers}
                                            onChange={this.changeAuto('requestorSel', 'requestor')}
                                            error={errors[t('requestor')] != null ? true : false}
                                            helperText={errors[t('requestor')]}
                                            label={t('requestor')}
                                            disabled={released || !fieldAccess}
                                            required
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('requestor')}</FormLabel>
                                            {this.state.requestorSel != null ? this.state.requestorSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <DateSelect
                                            onChange={this.dateChange('requestDate')}
                                            value={this.state.requestDate}
                                            helperText={errors[t('requestDate')]}
                                            error={errors[t('requestDate')] != null ? true : false}
                                            disabled={released || !fieldAccess}
                                            label={t('requestDate')}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('requestDate')}</FormLabel>
                                            <DateDisplay value={this.state.requestDate} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.vendorSel}
                                            options={this.state.vendors}
                                            onChange={this.changeAuto('vendorSel', 'vendor')}
                                            error={errors[t('vendor')] != null ? true : false}
                                            helperText={errors[t('vendor')]}
                                            label={t('vendor')}
                                            disabled={released || !fieldAccess}
                                            required
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('vendor')}</FormLabel>
                                            <Link to={'/vendor/' + this.state.vendor}>
                                                {this.state.vendorSel != null ? this.state.vendorSel.label : ''}
                                            </Link>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.treatmentTypeSel}
                                            options={this.state.treatmentTypes}
                                            onChange={this.changeAuto('treatmentTypeSel', 'treatmentType')}
                                            error={errors[t('treatmentType')] != null ? true : false}
                                            helperText={errors[t('treatmentType')]}
                                            label={t('treatmentType')}
                                            disabled={released || !fieldAccess}
                                            required
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('treatmentType')}</FormLabel>
                                            {this.state.treatmentTypeSel != null ? this.state.treatmentTypeSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.sterilizationTypeSel}
                                            options={this.state.sterilizationTypes}
                                            onChange={this.changeAuto('sterilizationTypeSel', 'sterilizationType')}
                                            error={errors[t('sterilizationType')] != null ? true : false}
                                            helperText={errors[t('sterilizationType')]}
                                            label={t('sterilizationType')}
                                            disabled={released || !fieldAccess}
                                            required
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('sterilizationType')}</FormLabel>
                                            {this.state.sterilizationTypeSel != null ? this.state.sterilizationTypeSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField type="number" value={this.state.radiationAmount} onChange={this.changeNumber} name="radiationAmount"
                                        size="medium" fullWidth={true} label={t('radiationDose')} disabled={released} required
                                        error={errors[t('radiationAmount')] != null ? true : false} helperText={errors[t('radiationAmount')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('radiationDose')}</FormLabel>
                                            {this.state.radiationAmount}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={10} sm={6}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.itemSel}
                                            options={this.state.items}
                                            onChange={this.changeAuto('itemSel', 'item')}
                                            error={errors[t('item')] != null ? true : false}
                                            helperText={errors[t('item')]}
                                            label={t('item')}
                                            disabled={released || !fieldAccess}
                                            required
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('item')}</FormLabel>
                                            <Link to={'/item/' + this.state.item}>
                                                {this.state.itemSel != null ? this.state.itemSel.label : ''}
                                            </Link>
                                        </FormControl>
                                    }
                                </Grid>
                                {this.state.lotList != null && this.state.lotList.length > 0 &&
                                    <Grid item sm={8}>
                                        <AutoCompleteField
                                            multiple={true}
                                            value={this.state.lotsSel}
                                            options={this.state.lotList}
                                            onChange={this.changeMulti('lotsSel', 'lots')}
                                            error={errors[t('lots')] != null ? true : false}
                                            helperText={errors[t('lots')]}
                                            label={t('lots')}
                                            disabled={released || !fieldAccess}
                                        />
                                    </Grid>
                                }
                            </Grid>
                            {this.state.hasForm === true &&
                                <Aux>
                                    <br/>
                                    {errors[t('form')] != null && errors[t('form')] !== '' &&
                                        <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                    }
                                    <Form renderer={renderer}
                                        defaultFields={this.state.form}
                                        onChange={this.formValueChanged}
                                        disabled={!fieldAccess}/>
                                </Aux>
                            }
                            {released &&
                                <Aux>
                                    <br/>
                                    <SectionTitle title={t('containers')}/>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={3}>{t('container')}</Grid>
                                        <Grid item xs={4} sm={2} align="right">{t('amount')}</Grid>
                                        <Grid item xs={4} sm={2} align="right">{t('original')}</Grid>
                                    </Grid>
                                    {this.state.containers != null && this.state.containers.length > 0 && this.state.containers.map((row, i) =>
                                        <Grid container spacing={3} key={row._id}>
                                            <Grid item xs={6} sm={3}>
                                                <TextOnlyClickButton enabled={true} click={this.editContainer(i)} name={row.container.number}/>
                                            </Grid>
                                            <Grid item xs={4} sm={2} align="right">
                                                <NumericFormat displayType={'text'} value={row.amount} thousandSeparator={true} decimalScale={2} suffix={row.uom.label}/>
                                            </Grid>
                                            <Grid item xs={4} sm={2} align="right">
                                                <NumericFormat displayType={'text'} value={row.originalAmount} thousandSeparator={true} decimalScale={2} suffix={row.uom.label}/>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {errors[t('containers')] != null &&
                                        <Grid container spacing={2}>
                                            <Grid item sm={6}>
                                                {errors[t('containers')]}
                                            </Grid>
                                        </Grid>
                                    }
                                    {!shipped &&
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} sm={3}>
                                                <Button variant="outlined" color="primary" size="sm" onClick={this.addContainer}>{t('add')}</Button>
                                            </Grid>
                                        </Grid>
                                    }
                                    <br/>
                                    <SectionTitle title={t('shippingContainers')}/>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={3}>{t('number')}</Grid>
                                    </Grid>
                                    {this.state.shippingContainers != null && this.state.shippingContainers.length > 0 &&
                                    this.state.shippingContainers.map((row, i) =>
                                        <Grid container spacing={3} key={row._id}>
                                            <Grid item xs={6} sm={3}>
                                                <TextOnlyClickButton enabled={true} click={this.editShippingContainer(i)} name={row.number}/>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {errors[t('shippingContainers')] != null &&
                                        <Grid container spacing={2}>
                                            <Grid item sm={6}>
                                                {errors[t('shippingContainers')]}
                                            </Grid>
                                        </Grid>
                                    }
                                    {!shipped &&
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} sm={3}>
                                                <Button variant="outlined" color="primary" size="sm" onClick={this.addShippingContainer}>{t('add')}</Button>
                                            </Grid>
                                        </Grid>
                                    }
                                    <br/>
                                    <SectionTitle title={t('shippingInformation')}/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} sm={2}>
                                            {fieldAccess &&
                                                <DateSelect
                                                    onChange={this.dateChange('sendDate')}
                                                    value={this.state.sendDate}
                                                    helperText={errors[t('sendDate')]}
                                                    error={errors[t('date')] != null ? true : false}
                                                    label={t('date')}
                                                    disabled={shipped || !fieldAccess}
                                                    format={this.props.auth.user.dateFormat}
                                                    required
                                                />
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('date')}</FormLabel>
                                                    <DateDisplay value={this.state.sendDate} format={this.props.auth.user.dateFormat}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={8} sm={4}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={this.state.sendShipperSel}
                                                    options={this.state.shippers}
                                                    onChange={this.changeAuto('sendShipperSel', 'sendShipper')}
                                                    error={errors[t('sendShipper')] != null ? true : false}
                                                    helperText={errors[t('sendShipper')]}
                                                    label={t('shipper')}
                                                    disabled={shipped || !fieldAccess}
                                                    required
                                                />
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('shipper')}</FormLabel>
                                                    <Link to={'/vendor/' + this.state.sendShipper}>
                                                        {this.state.sendShipperSel != null ? this.state.sendShipperSel.label : ''}
                                                    </Link>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            {fieldAccess &&
                                                <TextField value={this.state.sendShipmentNumber} onChange={this.changeValue} name={"sendShipmentNumber"}
                                                size="medium" fullWidth={true} label={t('shipmentNumber')} disabled={shipped || !fieldAccess} required
                                                error={errors[t('sendShipmentNumber')] != null ? true : false} helperText={errors[t('sendShipmentNumber')]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('shipmentNumber')}</FormLabel>
                                                    {this.state.sendShipmentNumber}
                                                </FormControl>
                                            }
                                        </Grid>
                                    </Grid>
                                </Aux>
                            }
                            {shipped &&
                                <Aux>
                                    <br/>
                                    <SectionTitle title={t('returnInformation')}/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} sm={2}>
                                            {fieldAccess &&
                                                <DateSelect
                                                    onChange={this.dateChange('returnDate')}
                                                    value={this.state.returnDate}
                                                    helperText={errors[t('returnDate')]}
                                                    error={errors[t('date')] != null ? true : false}
                                                    label={t('date')}
                                                    disabled={reviewed}
                                                    format={this.props.auth.user.dateFormat}
                                                    required
                                                />
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('date')}</FormLabel>
                                                    <DateDisplay value={this.state.returnDate} format={this.props.auth.user.dateFormat}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={8} sm={4}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={this.state.returnShipperSel}
                                                    options={this.state.shippers}
                                                    onChange={this.changeAuto('returnShipperSel', 'returnShipper')}
                                                    error={errors[t('returnShipper')] != null ? true : false}
                                                    helperText={errors[t('returnShipper')]}
                                                    label={t('shipper')}
                                                    disabled={reviewed}
                                                    required
                                                />
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('shipper')}</FormLabel>
                                                    <Link to={'/vendor/' + this.state.returnShipper}>
                                                        {this.state.returnShipperSel != null ? this.state.returnShipperSel.label : ''}
                                                    </Link>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            {fieldAccess &&
                                                <TextField value={this.state.returnShipmentNumber} onChange={this.changeValue} name={"returnShipmentNumber"}
                                                size="medium" fullWidth={true} label={t('shipmentNumber')} disabled={reviewed} required
                                                error={errors[t('returnShipmentNumber')] != null ? true : false} helperText={errors[t('returnShipmentNumber')]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('shipmentNumber')}</FormLabel>
                                                    {this.state.returnShipmentNumber}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={this.state.returnLocationSel}
                                                    options={this.state.locations}
                                                    onChange={this.changeAuto('returnLocationSel', 'returnLocation')}
                                                    error={errors[t('returnLocation')] != null ? true : false}
                                                    helperText={errors[t('returnLocation')]}
                                                    label={t('location')}
                                                    disabled={reviewed}
                                                    required
                                                />
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('location')}</FormLabel>
                                                    {this.state.returnLocationSel != null ? this.state.returnLocationSel.label : ''}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={3} sm={3}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={this.state.returnBinSel}
                                                    options={this.state.bins}
                                                    onChange={this.changeAuto('returnBinSel', 'returnBin')}
                                                    error={errors[t('returnBin')] != null ? true : false}
                                                    helperText={errors[t('returnBin')]}
                                                    label={t('bin')}
                                                    disabled={reviewed}
                                                    required
                                                    groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                                    noneMessage={t('noRoomSelected')}
                                                />
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('bin')}</FormLabel>
                                                    {this.state.returnBinSel != null ? this.state.returnBinSel.label : ''}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <CheckboxField
                                            checked={this.state.returnHasIssues} onChange={this.changeIssues}
                                            label={t('issues')} name="returnHasIssues" disabled={reviewed}
                                            />
                                        </Grid>
                                        {this.state.returnHasIssues &&
                                            <Grid item sm={8}>
                                            <AutoCompleteField
                                                multiple={true}
                                                value={this.state.returnIssuesSel}
                                                options={this.state.issues}
                                                onChange={this.changeMulti('returnIssuesSel', 'returnIssues')}
                                                error={errors[t('returnIssues')] != null ? true : false}
                                                helperText={errors[t('returnIssues')]}
                                                label={t('issues')}
                                                disabled={reviewed}
                                            />
                                        </Grid>
                                        }
                                        <Grid item sm={12}>
                                            {fieldAccess &&
                                                <TextField value={this.state.returnComments} onChange={this.changeValue} name={"returnComments"}
                                                size="medium" fullWidth={true} label={t('comments')} disabled={reviewed} multiline maxRows={4}
                                                error={errors[t('returnComments')] != null ? true : false} helperText={errors[t('returnComments')]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('comments')}</FormLabel>
                                                    {this.state.returnComments}
                                                </FormControl>
                                            }
                                        </Grid>
                                    </Grid>
                                </Aux>
                            }
                            {this.state.deviations != null && this.state.deviations.length > 0 && !micro &&
                                <Aux>
                                    <br/>
                                    <SectionTitle title={t('deviations')}/>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={3}>{t('number')}</Grid>
                                        <Grid item xs={6} sm={3}>{t('title')}</Grid>
                                    </Grid>
                                    {this.state.deviations.map((row, i) =>
                                        <Grid container spacing={3} key={row._id}>
                                            <Grid item xs={6} sm={3}>
                                                <Link to={'/deviation/' + row._id}>{row.number}</Link>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>{row.title}</Grid>
                                        </Grid>
                                    )}
                                    <br/>
                                </Aux>
                            }
                            <br/>
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    <SaveButton saveClick={this.save} enabled={this.state.saveSec && this.state.enableSave}/>
                                    {this.state._id != null && this.state._id !== '' && !released &&
                                        <ReleaseButton releaseClick={this.release} enabled={this.state.releaseSec}/>
                                    }
                                    {this.state.status === this.props.auth.constants.statuses.released && this.state.shipSignature != null &&
                                        <ShipButton shipClick={this.ship} enabled={this.state.shipSec}/>
                                    }
                                    {this.state._id != null && this.state._id !== '' && 
                                    this.state.status !== this.props.auth.constants.statuses.new && 
                                        this.state.status !== this.props.auth.constants.statuses.reviewed &&
                                        this.state.status !== this.props.auth.constants.statuses.signed &&
                                        <SimpleUnlockButton simpleUnlockClick={this.unlock} enabled={true}/>
                                    }
                                    {this.state._id != null && this.state._id !== '' && !micro &&
                                        <DeviationButton deviationClick={this.addDeviation} enabled={true}/>
                                    }
                                    {(this.state.status === this.props.auth.constants.statuses.reviewed ||
                                        this.state.status === this.props.auth.constants.statuses.signed) &&
                                        <Button variant="contained" color="secondary" size="small" onClick={this.unlock}>{t('unlock')}</Button>
                                    }
                                </Grid>
                            </Grid>
                        </Panel>
                    </Grid>
                </Grid>
                <Dialog open={this.state.showContainer} onClose={this.clearContainer} center maxWidth="md" fullWidth>
                    <DialogTitle>
                        <SectionTitle title={t('container')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.containerSel}
                                    options={this.state.containerList}
                                    onChange={this.changeAuto('containerSel', 'container')}
                                    error={errors[t('container')] != null ? true : false}
                                    helperText={errors[t('container')]}
                                    label={t('container')}
                                    disabled={shipped}
                                    groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                    noneMessage={t('noLotAssigned')}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.containerAmount} onChange={this.changeNumber} name={"containerAmount"}
                                size="medium" fullWidth={true} label={t('amount')} disabled={shipped}
                                error={errors[t('containerAmount')] != null ? true : false} helperText={errors[t('containerAmount')]}/>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.containerAmountUOMSel}
                                    options={this.state.uoms}
                                    onChange={this.changeAuto('containerAmountUOMSel', 'containerAmountUOM')}
                                    error={errors[t('containerAmountUOM')] != null ? true : false}
                                    helperText={errors[t('containerAmountUOM')]}
                                    label={t('uom')}
                                    disabled={shipped}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.originalAmount} onChange={this.changeNumber} name={"originalAmount"}
                                size="medium" fullWidth={true} label={t('originalAmount')} disabled={true}
                                error={errors[t('originalAmount')] != null ? true : false} helperText={errors[t('originalAmount')]}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton saveClick={this.saveContainer} enabled={!shipped && fieldAccess}/>
                        <ClearButton clearClick={this.clearContainer} enabled={true}/>
                        <DeleteButton deleteClick={this.deleteContainer} enabled={!shipped && fieldAccess}/>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.showShipping} onClose={this.clearShippingContainer} center maxWidth="md" fullWidth>
                    <DialogTitle>
                        <SectionTitle title={t('shippingContainers')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <TextField value={this.state.shippingNumber} onChange={this.changeValue} name={"shippingNumber"}
                                size="medium" fullWidth={true} label={t('number')} disabled={shipped} 
                                error={errors[t('shippingNumber')] != null ? true : false} helperText={errors[t('shippingNumber')]}/>
                            </Grid>
                            <Grid item sm={8}>
                                <AutoCompleteField
                                    multiple={true}
                                    value={this.state.shipContainersSel}
                                    options={this.state.containerList}
                                    onChange={this.changeMulti('shipContainersSel', 'shipContainers')}
                                    error={errors[t('shipContainers')] != null ? true : false}
                                    helperText={errors[t('shipContainers')]}
                                    label={t('containers')}
                                    disabled={shipped}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton saveClick={this.saveShippingContainer} enabled={!shipped && fieldAccess}/>
                        <ClearButton clearClick={this.clearShippingContainer} enabled={true}/>
                        <DeleteButton deleteClick={this.deleteShippingContainer} enabled={!shipped && fieldAccess}/>
                    </DialogActions>
                </Dialog>
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.externalTreatment}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ExternalTreatment));