import common from '../jsons/common.json';

const languageHelper = {
    getBrowserLanguage(){
        const browserLocales =
            navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;
        if (!browserLocales) {
            return undefined;
        }
        let language = browserLocales[0].trim();
        if(language != null){
            return language.split(/-|_/)[0]
        }
        return null;
    },

    getLanguageCsvSeparator(language){
        return language !== 'fr' ? ',' : common.frenchCsvDelimeter;
    }
}

export default languageHelper;