import React, { Component } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ConversionHelper from '../../helpers/conversionHelper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import ToolTip from '@mui/material/Tooltip';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { connect } from 'react-redux';
import SectionTitle from '../UI/Typography/SectionTitle';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import Grid from '@mui/material/Grid';
import Aux from '../../hoc/Auxilary/Auxilary';

class CalendarSchedule extends Component{
    constructor(props){
        super(props);
        this.state = {
            date: new Date(),
            list: [],
            status: '',
            statusSelected: null,
            statuses: [],
            location: '',
            locationSelected: null,
            locations: [],
            activity: '',
            activitySelected: null,
            activities: [],
            assignee: '',
            users: [],
            offset: 0,
            listSize: 10,
            pages: 1,
            orderBy: 'dueDate',
            ordDir: 1,
            totalCount: 0,
            page: 0

        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location, common.cacheValues.object, common.cacheValues.user, common.cacheValues.scheduleStatus], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ? 
            this.props.auth.user.defaultLocation : '';
        let locSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locSel == null && locations.length === 1){
            locSel = locations[0];
            location = locSel.value;
        }
        this.setState({
            activities: cacheValues.objects,
            locations: locations,
            users: cacheValues.users,
            statuses: cacheValues.scheduleStatuses,
            location: location,
            locationSelected: locSel,
            assignee: this.props.assignee != null && this.props.assignee !== '' ? this.props.assignee : '',
            status: this.state.status
        });
        this.loadRecords();
    }

    async loadRecords(date){
        let sortName = this.state.orderBy;
        let ordDir = this.state.ordDir;
        let sort = {[sortName]: ordDir};
        let data = {
            size: this.state.listSize,
            offset: this.state.offset,
            date: date != null ? ConversionHelper.minDate(date).toUTCString() : ConversionHelper.minDate(this.state.date).toUTCString(),
            endDate: date != null ? ConversionHelper.maxDate(date).toUTCString() : 
                                    ConversionHelper.maxDate(this.state.date).toUTCString(),
            activity: this.state.activity,
            assignee: this.state.assignee,
            status: this.state.status,
            incomplete: this.state.incomplete,
            location: this.state.location,
            sort: JSON.stringify(sort)
        }
        const result = await axios.get('/api/calendarschedules', {params: data});
        this.setState({
            list: result.data.data,
            pages: Math.ceil(result.data.totalCount / this.state.listSize),
            totalCount: result.data.totalCount
        })
    }

    onChange = date => {
        this.setState({date: date});
        this.loadRecords(date);
    }

    changeAutoComplete = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            offset: 0, page: 0
        }, () => {
            this.loadRecords();
        })
    };

    sortClick(name) {
        let orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.listSize);
        this.setState({ offset: offset }, () => {
          this.loadRecords();
        });
    };

    render(){
        const t = this.props.t;
        return(
            <Aux>
                <SectionTitle title={t('dailyActivities')}/>
                <Grid container spacing={2}>
                    <Grid item sm={4}>
                        <Calendar onChange={this.onChange} value={this.state.date}/>
                    </Grid>
                    <Grid item sm={8}>
                        <Grid container spacing={2}>
                            <Grid item sm={2}>
                                <AutoCompleteField
                                    value={this.state.locationSelected}
                                    options={this.state.locations}
                                    onChange={this.changeAutoComplete('locationSelected', 'location')}
                                    label={t('location')}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <AutoCompleteField
                                    value={this.state.statusSelected}
                                    options={this.state.statuses}
                                    onChange={this.changeAutoComplete('statusSelected', 'status')}
                                    label={t('status')}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <AutoCompleteField
                                    value={this.state.assigneeSelected}
                                    options={this.state.users}
                                    onChange={this.changeAutoComplete('assigneeSelected', 'assignee')}
                                    label={t('assignee')}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <AutoCompleteField
                                    value={this.state.activitySelected}
                                    options={this.state.activities}
                                    onChange={this.changeAutoComplete('activitySelected', 'activity')}
                                    label={t('activity')}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item sm={8}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="instances" size="small">
                                        <TableHead>
                                            <TableRow key="tableHeader">
                                                <TableCell style={{ width: '40%' }} >Activity</TableCell>
                                                <TableCell style={{ width: '15%' }} >
                                                    <TableSortLabel onClick={() => this.sortClick('assignee')}
                                                    active={this.state.orderBy === 'assignee'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('assignee')}
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell style={{ width: '10%' }} >
                                                    <TableSortLabel onClick={() => this.sortClick('status')}
                                                    active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell style={{ width: '10%' }} >{t('room')}</TableCell>
                                                <TableCell style={{ width: '20%' }} >
                                                    <TableSortLabel onClick={() => this.sortClick('location')}
                                                    active={this.state.orderBy === 'location'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('location')}
                                                    </TableSortLabel>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.list.map((row, i) =>
                                                <TableRow key={row._id}>
                                                    <TableCell>
                                                        {row.productionOrder != null && row.productionOrder !== '' ?
                                                            <ToolTip title={row.productionOrder.description} placement='top-end'>
                                                                <Link to={'/scheduledActivity/' + row._id}>{row.label}</Link>
                                                            </ToolTip> :
                                                            <Link to={'/scheduledActivity/' + row._id}>{row.label}</Link>
                                                        }
                                                    </TableCell>
                                                    <TableCell>{row.assignee != null ? row.assignee.firstName + ' ' + row.assignee.lastName : ''}</TableCell>
                                                    <TableCell>{row.status.label}</TableCell>
                                                    <TableCell>{row.room != null && row.room !== '' ? row.room.name : ''}</TableCell>
                                                    <TableCell>{row.location != null ? row.location.name : ''}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow key="tableFooter">
                                                <TablePagination count={this.state.totalCount}
                                                rowsPerPage={this.state.listSize}
                                                onPageChange={this.handleChangePage}
                                                page = {this.state.page}
                                                rowsPerPageOptions={common.rowOptions}
                                                onRowsPerPageChange={this.handleRowsPerPage}/>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(CalendarSchedule));