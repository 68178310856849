import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import '../../App.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Title from '../UI/Typography/Title';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ActionBar from '../UI/Buttons/ActionBar';
import common from '../../jsons/common.json';
import { withTranslation } from 'react-i18next';

class UserList extends Component {
  constructor(){
      super();

    this.state = {
      users: [],
      offset: 0,
      listSize: 10,
      pageCount: 1,
      search: '',
      email: '',
      locations: [],
      processes: [],
      departments: [],
      roles: [],
      location: '',
      process: '',
      department: '',
      role: '',
      orderBy: 'lastName',
      ordDir: 1,
      totalCount: 0,
      page: 0,
      security: [],
      tenant: '',
      tenantSel: null,
      tenants: [],
      internal: false,
      locked: false,
      firstName: ''
    }
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  async componentDidMount(){
    const values = await axios.get('/api/uservalues');
    let storage = sessionStorage.getItem('users');
    storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
    this.setState({
      locations: values.data.locations,
      processes: values.data.processes,
      departments: values.data.departments,
      roles: values.data.roles,
      security: this.props.permission,
      tenants: values.data.tenants,
      offset: storage != null ? storage.offset : 0,
        listSize: storage != null ? storage.listSize : 10,
        orderBy: storage != null ? storage.orderBy : 'lastName',
        ordDir: storage != null ? storage.ordDir : 1,
        search: storage != null ? storage.search : '',
        email: storage != null ? storage.email : '',
        tenant: storage != null ? storage.tenant : '',
        tenantSel: storage != null ? storage.tenantSel : null,
        internal: storage != null ? storage.internal : false,
        locked: storage != null ? storage.locked : false
    })
    this.loadRecords();
  }

  saveFilters(){
    let data = {
        offset: this.state.offset,
        listSize: this.state.listSize,
        orderBy: this.state.orderBy,
        ordDir: this.state.ordDir,
        search: this.state.search,
        email: this.state.email,
        tenant: this.state.tenant,
        tenantSel: this.state.tenantSel,
        internal: this.state.internal,
        locked: this.state.locked,
        firstName: this.state.firstName,
    };
    sessionStorage.setItem('users', JSON.stringify(data));
  }

  async loadRecords(){
    let url = '/int/users';
    let sort = {[this.state.orderBy]: this.state.ordDir};
    let data = {
      size: this.state.listSize,
      offset: this.state.offset,
      search: this.state.search,
      firstName: this.state.firstName,
      email: this.state.email,
      tenant: this.state.tenant,
      internal: this.state.internal,
      locked: this.state.locked,
      sort: JSON.stringify(sort)
    };
    const result = await axios.get(url, {params: data});
    this.setState({
      users: result.data.data,
      pageCount: Math.ceil(result.data.totalCount / this.state.listSize),
      totalCount: result.data.totalCount
    });
    this.saveFilters();
  }

  changeValue = (e) => {
    this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords();});
  };

  changeBool = (name) => (e) => {
    this.setState({[name]: !this.state[name]}, () => {this.loadRecords()});
  };

  changeAuto = (name, data) => (e, newValue) => {
    this.setState({
        [name]: newValue,
        [data]: newValue != null ? newValue.value : null,
        page: 0,
        offset: 0
    }, () => {
        this.loadRecords();
    });
};

  handleSearchChange(e){
    this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords();});
  }

  sortClick(name) {
    const orderBy = this.state.orderBy;
    let ordDir = this.state.ordDir;
    if(orderBy === name){
        if(ordDir > 0)
            ordDir = -1;
        else
            ordDir = 1;
    }
    this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
        this.loadRecords();
    });
  }

  handleRowsPerPage = e => {
      this.setState({listSize: parseInt(e.target.value)}, () => {
          this.loadRecords();
      });
  };

  handleChangePage = (event, newPage) => {
      this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
          this.loadRecords();
      });
  };

  render(){
    const security = this.state.security;
    const t = this.props.t;
    return(
      <Aux>
        <ActionBar
            createLocation='/#/user'
            createAccess={security.create}
            helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
            helpUser={this.props.auth.user.helpUser}
            helpPassword={this.props.auth.user.helpPassword}
        />
        <Title title={t('users')}/>
        <Panel>
          <Grid container spacing={3}>
            <Grid item xs={4} sm={2}>
              <TextField value={this.state.search} onChange={this.handleSearchChange} name="search"
              size="medium" fullWidth label={t('lastName')} />
            </Grid>
            <Grid item xs={4} sm={2}>
              <TextField value={this.state.firstName} onChange={this.handleSearchChange} name="firstName"
              size="medium" fullWidth label={t('firstName')}/>
            </Grid>
            <Grid item xs={4} sm={2}>
              <TextField value={this.state.email} onChange={this.changeValue} name="email"
              size="medium" fullWidth label={t('email')} />
            </Grid>
            <Grid item xs={6} sm={3}>
                <AutoCompleteField
                    value={this.state.tenantSel}
                    options={this.state.tenants}
                    onChange={this.changeAuto('tenantSel', 'tenant')}
                    label={t('tenant')}
                />
            </Grid>
            <Grid item xs={4} sm={2}>
                <CheckboxField
                checked={this.state.internal} onChange={this.changeBool('internal')}
                label={t('internal')} name="internal"
                />
            </Grid>
            <Grid item xs={4} sm={2}>
                <CheckboxField
                checked={this.state.locked} onChange={this.changeBool('locked')}
                label={t('locked')} name="locked"
                />
            </Grid>
          </Grid>
          <br />
          <TableContainer component={Paper}>
            <Table aria-label="User List">
              <TableHead>
                <TableRow key="tableHeader">
                  <TableCell>
                    <TableSortLabel onClick={() => this.sortClick('userid')}
                        active={this.state.orderBy === 'userid'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('userid')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel onClick={() => this.sortClick('firstName')}
                        active={this.state.orderBy === 'firstName'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('firstName')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel onClick={() => this.sortClick('lastName')}
                        active={this.state.orderBy === 'lastName'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('lastName')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel onClick={() => this.sortClick('email')}
                        active={this.state.orderBy === 'email'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('email')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>{t('internal')}</TableCell>
                  <TableCell>{t('locked')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.users.map((row, i) =>
                  <TableRow key={row._id}>
                    <TableCell><Link to={'/user/' + row._id}>{row.userid}</Link></TableCell>
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.internal ? t('yes') : ''}</TableCell>
                    <TableCell>{row.locked ? t('yes') : ''}</TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow key="tableFooter">
                  <TablePagination count={this.state.totalCount}
                  rowsPerPage={this.state.listSize}
                  onPageChange={this.handleChangePage}
                  page = {this.state.page}
                  rowsPerPageOptions={common.rowOptions}
                  onRowsPerPageChange={this.handleRowsPerPage}/>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Panel>
      </Aux>
    );
  }

}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default withTranslation() (connect(mapStateToProps)(UserList));
