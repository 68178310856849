import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import queryString from 'query-string';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import DateTimePicker from '../UI/DateTimeSelect/DateTimeSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { FormLabel, Box } from '@mui/material';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { approveSigSecurity, completeSigSecurity,
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import ActivityWorkRecord from './ActivityWorkRecord';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-router-dom';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import SectionTitle from '../UI/Typography/SectionTitle';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import IconButton from '@mui/material/IconButton';
import { NumericFormat } from 'react-number-format';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ItemLedgerComponent from '../Inventory/ItemLedgerComponent';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ScheduleDialog from '../General/ScheduleDialog';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import SelectField from '../UI/SelectField/SelectField';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import ConfirmDelete from '../General/ConfirmDelete';
import PrintView from '../Printing/PrintView';
import ConversionHelper from '../../helpers/conversionHelper';

class RoomFeeding extends Component {
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            _id: '',
            number: '',
            date: new Date(),
            location: '',
            locationSel: null,
            locations: [],
            batches: [],
            batchesSel: [],
            batchOptions: [],
            room: [],
            roomSel: [],
            rooms: [],
            status: '',
            statusSel: null,
            statuses: [],
            userOptions: [],
            managers: [],
            qas: [],
            users: [],
            usersSel: [],
            user: null,
            reviewer: null,
            signer: null,
            documents: [],
            startTime: '',
            stopTime: '',
            sops: [],
            feeds: [],
            item: '',
            itemSel: null,
            items: [],
            feedingLot: null,
            lot: '',
            lotSel: null,
            lots: [],
            container: '',
            containerSel: null,
            containers: [],
            quantity: '',
            uom: '',
            uomSel: null,
            uoms: [],
            type: '',
            typeSel: null,
            types: [],
            security: {},
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            renderNum: Math.random(),
            sopList: [],
            sopIds: [],
            sopsSel: [],
            tabKey: 0,
            tabLabels: [{name: t('roomFeeding'), disabled: false}],
            optionsMenu: [],
            flagForSignature: 0,
            enableSave: true,
            activeUsers: [],
            activeManagers: [],
            activeQas: [],
            form: '',
            hasForm: false,
            signed: false,
            enableUnlock: false,
            cost: '',
            effort: '',
            ec: '',
            ph: '',
            goBack: -1,
            schedule: null,
            dialogOpen: false,
            roomCosts: [],
            openCosts: false,
            deleteOpen: false,
            finalDocument: null,
            printViewer: false,
            hasLedgers: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.user, common.cacheValues.cultivationLocation, common.cacheValues.status,
                            common.cacheValues.quantityUOM, common.cacheValues.defaultGrowingRoom, common.cacheValues.feedingType, common.cacheValues.roomFeedingItem,
                            common.cacheValues.roomFeedingForm, common.cacheValues.roomFeedingSop], this.props.auth.constants, null, this.props.auth.user.tenant);
        let allUsers = cacheValues.users;
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.roomFeeding, this.props.auth.user.tenant);
        let locationSel = null;
        let location = '';
        if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
            locationSel = locations.find(x => x.value === this.props.auth.user.defaultLocation);
            location = this.props.auth.user.defaultLocation;
        } else {
            if(locations.length === 1){
                locationSel = locations[0];
                location = locationSel.value;
            }
        }
        let workRecords = [];
        let sopIds = [];
        let sopsSel = [];
        cacheValues.sops.forEach(sop => {
            if(sop.required === true){
                workRecords.push({
                    sop: sop.value,
                    original: true
                });
                sopIds.push(sop.value);
                sopsSel.push(sop);
            }

        });
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let activeQas = [];
        userValues.approve.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeQas.push(row);
            }
        })
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;

        let params = queryString.parse(this.props.location.search);
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        var schedule = null;
        let goBack = -1;
        if(scheduleId != null){
            goBack = -2;
            schedule = await axios.get('/api/schedulebase/' + scheduleId);
        }
        this.setState({
            rooms: cacheValues.rooms,
            types: cacheValues.feedingTypes,
            statuses: cacheValues.statuses,
            items: cacheValues.roomFeedingIngredients,
            userOptions: userValues.create,
            uoms: cacheValues.quantityUOMs,
            managers: userValues.review,
            qas: userValues.approve,
            locations: locations,
            location: location,
            locationSel: locationSel,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            sops: workRecords,
            sopList: cacheValues.sops,
            sopIds: sopIds,
            sopsSel: sopsSel,
            activeUsers: activeUsers,
            activeManagers: activeManagers,
            activeQas: activeQas,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null,
            allUsers: allUsers,
            schedule: schedule,
            goBack: goBack
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/roomfeedings', label: this.props.t('roomFeedings'), screen: this.props.auth.screenDefs.RoomFeedings},
                    { path: 'active', label: this.props.t('roomFeeding'), screen: this.props.auth.screenDefs.RoomFeeding}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        const record = await axios.get('/api/roomfeeding/' + this.state._id);
        let batchesSel = [];
        if(record.data.batchOptions != null && record.data.batchOptions.length > 0 && record.data.batches != null && record.data.batches.length > 0){
            record.data.batches.forEach(row => {
                let val = record.data.batchOptions.find(x => x.value === row);
                batchesSel.push(val);
            });
        }
        let feeds = record.data.feeds;
        for(let row of feeds){
            let itemSel = this.state.items.find(x => x.value === row.item)
            row.itemSel = itemSel;
            let uomSel = this.state.uoms.find(x => x.value === row.uom);
            row.uomSel = uomSel;
            if(row.container != null){
                let containerSel = row.containers.find(x => x.value === row.container);
                row.containerSel = containerSel;
            }
        }
        const signed = record.data.status === this.props.auth.constants.statuses.signed ||
            record.data.status === this.props.auth.constants.statuses.reviewed;
        const locationSel = this.state.locations.find(x => x.value === record.data.location);
        const roomSel = [];
        if(record.data.room != null && record.data.room.length > 0){
            for(let row of record.data.room){
                let val = record.data.roomList.find(x => x.value === row);
                roomSel.push(val);
            }
        }
        const statusSel = this.state.statuses.find(x => x.value === record.data.status);
        const typeSel = this.state.types.find(x => x.value === record.data.type);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let sopIds = [];
        let sopsSel = [];
        let tabLabels = [{name: t('roomFeeding'), disabled: false}];
        if(record.data.sops != null && record.data.sops.length > 0){
            record.data.sops.forEach(sop => {
                sopIds.push(sop.sop);
                let val = this.state.sopList.find(x => x.value === sop.sop);
                if(val != null){
                    sopsSel.push(val);
                    tabLabels.push({name: val.label, disabled: false});
                }

            });
        }
        if(record.data.hasLedgers){
            tabLabels.push({name: t('itemLedgers'), disabled: false});
        }
        let usersSel = [];
        let userOptions = this.state.userOptions;
        if(record.data.users != null && record.data.users.length > 0){
            record.data.users.forEach(row => {
                let val = this.state.userOptions.find(x => x.value === row);
                if(val != null){
                    usersSel.push(val);
                } else {
                    val = this.state.allUsers.find(x => x.value === row);
                    if(val != null){
                        usersSel.push(val);
                        userOptions.push(val);
                    }
                }
            });
        }
        let sigDeleteCallback = (record.data.status === this.props.auth.constants.statuses.new || record.data.status === this.props.auth.constants.statuses.inprogress) && 
                        record.data.user != null ? this.deleteSigCallback : null;
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: usersSel, name: t('complete'), object: record.data._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user,
                objectType: this.props.auth.constants.objectIds.roomFeeding, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, deleteCallback: sigDeleteCallback
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: !internal && this.state.reviewer == null ? this.state.activeManagers : this.state.managers,
                name: t('review'), title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.roomFeeding, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: !internal && this.state.signer == null ? this.state.activeQas : this.state.qas,
                name: t('approve'), title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer,
                objectType: this.props.auth.constants.objectIds.roomFeeding, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        this.setState({
            date: this.state.date,
            batches: record.data.batches,
            batchesSel: batchesSel,
            batchOptions: record.data.batchOptions,
            startTime: record.data.startTime,
            stopTime: record.data.stopTime,
            documents: record.data.documents,
            number: record.data.number,
            status: record.data.status,
            statusSel: statusSel,
            fieldAccess: fieldAccess,
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            sopIds: sopIds,
            sopsSel: sopsSel,
            sops: record.data.sops,
            renderNum: Math.random(),
            tabLabels: tabLabels,
            feeds: feeds,
            user: record.data.user,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            signed: signed,
            location: record.data.location,
            locationSel: locationSel,
            room: record.data.room,
            roomSel: roomSel,
            users: record.data.users,
            usersSel: usersSel,
            type: record.data.type,
            typeSel: typeSel,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            cost: record.data.cost,
            batchCosts: record.data.batchCosts,
            userOptions: userOptions,
            effort: record.data.effort,
            rooms: record.data.roomList,
            ec: record.data.ec != null ? record.data.ec : '',
            ph: record.data.ph != null ? record.data.ph : '',
            roomCosts: record.data.roomCosts,
            deleteOpen: false,
            finalDocument: record.data.finalDocument,
            hasLedgers: record.data.hasLedgers
        }, () => {
            const crumbs = [
                { path: '/#/roomfeedings', label: this.props.t('roomFeedings'), screen: this.props.auth.screenDefs.RoomFeedings},
                { path: 'active', label: (this.props.t('roomFeeding') + ' - ' + record.data.number), screen: this.props.auth.screenDefs.RoomFeeding}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    }

    changeAuto = (name, data) => async (e, newValue) => {
        let uom = this.state.uom;
        let uomSel = this.state.uomSel;
        let batchOptions = this.state.batchOptions;
        let batches = this.state.batches;
        let batchesSel = this.state.batchesSel;
        if(data === 'item' && newValue != null){
            uom = newValue.uom;
            uomSel = this.state.uoms.find(x => x.value === newValue.uom);
        } else if(data === 'room'){
            if(newValue != null){
                let roomBatchResult = await axios.get('/api/roombatchsel/' + newValue.value);
                batchOptions = roomBatchResult.data;
            }else {
                batchOptions = [];
            }
            batches = [];
            batchesSel = [];
        }
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            uom: uom,
            uomSel: uomSel,
            batchOptions: batchOptions,
            batches: batches,
            batchesSel: batchesSel
        }, async () => {
            if(data === 'item' && newValue != null){
                let body = {positive: true, items: [newValue.value,], date: this.state.date, available: true, consume: true};
                let lots = await axios.post('/api/itemcontainers', body);
                this.setState({containers: lots.data});
            } else if (data === 'location'){
                if(newValue != null){
                    let roomResult = await axios.get('/api/roomselect', {params: {location: newValue.value}});
                    this.setState({
                        rooms: roomResult.data,
                        room: '',
                        roomSel: null,
                        batchOptions: [],
                        batches: [],
                        batchesSel: []
                    });
                } else {
                    this.setState({
                        rooms: [],
                        room: '',
                        roomSel: null,
                        batchOptions: [],
                        batches: [],
                        batchesSel: []
                    });
                }
            }
        });
    }

    changeMulti = (name, data) => async (e, newValue) => {
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            [name]: newValue,
            [data]: ids
        });
        if(data === 'room'){
            if(newValue != null && newValue.length > 0){
                let batchRes = await axios.get('/api/roombatchessel', {params: {rooms: JSON.stringify(ids)}});
                this.setState({batchOptions: batchRes.data, batches: [], batchesSel: []});
            } else {
                this.setState({batches: [], batchesSel: [], batchOptions: []});
            }
        }
    };

    changeValue = e => {
        const name = e.target.name;
        const value = e.target.value
        this.setState({[name]: value});
    };

    changeNumber = e => {
        const name = e.target.name;
        const value = e.target.value
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value});
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const t = this.props.t;
        const valid = this.validateForm();
        let status = this.state.status;
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        if( status === this.props.auth.constants.statuses.new ){
            status = this.props.auth.constants.statuses.inprogress;
        }
        const data = {
            _id: this.state._id,
            date: this.state.date,
            batches: this.state.batches,
            room: this.state.room,
            users: this.state.users,
            feeds: this.state.feeds,
            location: this.state.location,
            startTime: this.state.startTime,
            stopTime: this.state.stopTime,
            status: status,
            type: this.state.type,
            sops: this.state.sops,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            effort: this.state.effort,
            ph: this.state.ph,
            ec: this.state.ec
        }
        try {
            const result = await axios.post('/api/roomfeeding', data);
            const completeSigSec = completeSigSecurity(this.props.permission.complete, result.data.user, result.data.status, this.props.auth.common, this.props.auth.constants);
            const reviewSigSec = reviewSigSecurity(this.props.permission.review, result.data.reviewer, result.data.status, this.props.auth.common, this.props.auth.constants);
            const approveSigSec = approveSigSecurity(this.props.permission.approve, result.data.signer, result.data.status, this.props.auth.common, this.props.auth.constants);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/roomfeeding/' + result.data.id);
            this.setState({
                _id: result.data.id,
                completeSigSec: completeSigSec,
                reviewSigSec: reviewSigSec,
                approveSigSec: approveSigSec,
                enableSave: true
            }, () => {
                this.loadRecord();
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    validateForm(){
        const t = this.props.t;
        let errors = {}
        const state = this.state;
        if(state.date == null || state.date === ''){
            errors[t('date')] = t('required');
        } else {
            let validEndDate = ConversionHelper.validActivityDate(this.state.date, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
            if(!validEndDate){
                errors[t('date')] = t('dateBeforeLock');
            }
        }
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.room == null || state.room === '')
            errors[t('room')] = t('required');
        if(state.users == null || state.users.length === 0)
            errors[t('users')] = t('required');
        if(state.type == null || state.type === '')
            errors[t('type')] = t('required');
        if(state.feeds == null || state.feeds.length === 0){
            errors[t('item')] = t('required');
        } else {
            let i = 0;
            for(let row of this.state.feeds){
                if(row.item == null || row.item === '')
                    errors[`${t('item')} (${t('line')} ${i})`] = t('required');
                if(row.itemSel != null && row.itemSel.lotTracked === true){
                    if(row.container == null || row.container === '')
                        errors[`${t('container')} (${t('line')} ${i})`] = t('required');
                }
                if(row.quantity == null || row.quantity === '')
                    errors[`${t('quantity')} (${t('line')} ${i})`] = t('required');
                else {
                    if(row.itemSel != null && row.itemSel.lotTracked === true){
                        if(row.reservation == null && parseFloat(row.quantity) > parseFloat(row.containerSel.quantity)){
                            errors[`${t('quantity')} (${t('line')} ${i})`] = t('moreThanContainer');
                        } else if (row.reservation != null && parseFloat(row.quantity) > parseFloat(row.containerSel.quantity) + parseFloat(row.reservedAmount)){
                            errors[`${t('quantity')} (${t('line')} ${i})`] = t('moreThanContainer');
                        }
                    } else {
                        if(row.itemSel != null){
                            if(parseFloat(row.quantity) > parseFloat(row.itemSel.inventory))
                                errors[`${t('quantity')} (${t('line')} ${i})`] = t('moreThanInventory');
                        }
                    }
                }
                i++;
            }
        }
        if((state.item != null && state.item !== '') || (state.container != null && state.container !== '') ||
        (state.quantity != null && state.quantity !== '')){
            errors[t('item')] = t('rowNotAdded');
        }
        if(state.startTime == null || state.startTime === '')
            errors[t('startTime')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    validateComplete = () => {
        const t = this.props.t;
        let valid = this.validateForm();
        if(!valid)
            return t('notAllComplete');
        if(this.state.sops != null && this.state.sops.length > 0){
            for(let i = 0; i < this.state.sops.length; i++){
                let sop = this.state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        return null;
    };

    changeSop = (e, newValue) => {
        let sops = this.state.sops;
        let tabLabels = this.state.tabLabels;
        if(newValue != null){
            if(sops.length > newValue.length){
                for(let i = 0; i < sops.length; i++){
                    let sop = sops[i];
                    let rec = newValue.find(x => x.value === sop.sop);
                    if(!rec){
                        sops.splice(i, 1);
                        tabLabels.splice(i + 1, 1);
                        break;
                    }
                }
            } else {
                for(let i = 0; i < newValue.length; i++){
                    let val = newValue[i];
                    let rec = sops.find(x => x.sop === val.value)
                    if(!rec){
                        sops.push({
                            sop: val.value,
                            workRecord: null,
                            original: false
                        });
                        if(this.state._id != null && this.state._id !== '')
                            tabLabels.push({name: val.label, disabled: false});
                    }
                }
            }
            let sopIds = [];
            newValue.forEach(val => {
                sopIds.push(val.value);
            });
            this.setState({
                sopIds: sopIds,
                sopsSel: newValue,
                sops: sops
            });
        } else {
            for(let i = 0; i < sops.length; i++){
                let sop = sops[i];
                if(sop.required)
                    return;
            }
            this.setState({sops: [], sopIds: [], sopsSel: [], tabLabels: [{name: this.props.t('roomFeeding'), disabled: false}]});
        }
    };

    toggleDialog = () => {
        let dialogOpen = this.state.dialogOpen;
        this.setState({ dialogOpen: !dialogOpen });
    };

    changeLineValue = (name, index) => (e) => {
        let feeds = this.state.feeds;
        let row = feeds[index];
        row[name] = e.target.value;
        this.setState({feeds: feeds});
    };

    changeLineAuto = (name, data, index) => async (e, newValue) => {
        let feeds = this.state.feeds;
        let row = feeds[index];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : null;
        if(data === 'item'){
            row.containers = null;
            row.container = '';
            row.containerSel = null;
            if(newValue != null){
                let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                row.uom = newValue.uom;
                row.uomSel = uomSel;
                let body = {positive: true, items: [newValue.value,], date: this.state.date, available: true, consume: true};
                let lots = await axios.post('/api/itemcontainers', body);
                row.containers = lots.data;
            }
        }
        if(data === 'item' || data === 'lot' || data === 'container'){
            row.reservation = null;
        }
        this.setState({feeds: feeds});
    };

    deleteInput = (index) => (e) => {
        let inputs = this.state.feeds;
        inputs.splice(index, 1);
        this.setState({feeds: inputs});
    };

    addInput = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.item == null || this.state.item === '')
            errors[t('item')] = t('required');
        if(this.state.quantity == null || this.state.quantity === '')
            errors[t('quantity')] = t('required');
        if(this.state.itemSel != null && this.state.itemSel.lotTracked === true){
            if(this.state.container == null || this.state.container === '')
                errors[t('container')] = t('required');
            else {
                if(this.state.quantity != null && this.state.quantity !== ''){
                    if(parseFloat(this.state.quantity) > parseFloat(this.state.containerSel.quantity)){
                        errors[t('container')] = t('moreThanContainer');
                    }
                }
            }
        } else {
            if(this.state.quantity != null && this.state.quantity !== '' && this.state.itemSel != null){
                if(parseFloat(this.state.quantity) > parseFloat(this.state.itemSel.inventory))
                    errors[t('quantity')] = t('moreThanInventory');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return;
        }
        let feeds = this.state.feeds;
        feeds.push({
            item: this.state.item,
            itemSel: this.state.itemSel,
            quantity: this.state.quantity,
            uom: this.state.uom,
            uomSel: this.state.uomSel,
            container: this.state.container,
            containerSel: this.state.containerSel,
            containers: this.state.containers
        });
        this.setState({
            feeds: feeds,
            item: '',
            itemSel: null,
            quantity: '',
            container: '',
            containerSel: null,
            containers: [],
            uom: '',
            uomSel: null,
            errors: {}
        });
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    delete = () => {
        if(this.state._id == null || this.state._id === ''){
            this.setState({deleteOpen: false});
            this.props.history.goBack();
            return;
        }
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._delete());
    }

    async _delete(){
        const t = this.props.t;
        if(this.state.user != null || this.state.reviewer != null || this.state.signer != null){
            this.setState({enableSave: true, deleteOpen: false});
            return;
        }
        try {
            let result = await axios.delete('/api/roomfeeding/' + this.state._id);
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            if(this.state.goBack === -1) {
                this.props.history.goBack();
            }else {
                this.props.history.go(this.state.goBack);
            }
        } catch(err){
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    changeMultiAll = (event) => {
        let val = event.target.value;
        let value = val.pop();
        let ids = this.state.batches;
        let options = this.state.batchOptions;
        let sels = this.state.batchesSel;
        if(value != null){
            if(value === 'all') {
                if(val.length === options.length) {
                    if(this.state.started === false){
                        sels = [];
                        ids = [];
                    } else {
                        return;
                    }
                }else {
                    sels = [];
                    ids = [];
                    options.forEach(option => {
                        ids.push(option.value);
                        sels.push(option)
                    });
                }
                this.setState({
                    batchesSel: sels,
                    batches: ids
                });
                return;
            }else {
                if(val.length === 0) {
                    let container = options.find(x => x.value === value);
                    ids.push(value);
                    val.push(container);
                }else {
                    if(!val.some(x => x.value === value)) {
                        let container = options.find(x => x.value === value);
                        ids.push(value);
                        val.push(container);
                    }else {
                        let index = val.findIndex(x => x.value === value);
                        val.splice(index, 1);
                        ids.splice(index,1);
                    }
                }
                this.setState({
                    batchesSel: val,
                    batches: ids,
                });
                return;
            }
        }
        this.setState({
            batchesSel: val,
            batches: ids,
        });
    };

    deleteMulti = (e, value) => {
        e.preventDefault();
        let batches = this.state.batches;
        let batchesSel = this.state.batchesSel;
        let index = batchesSel.findIndex(x => x.value === value.value);
        batches.splice(index, 1);
        batchesSel.splice(index, 1);
        this.setState({
            batchesSel: batchesSel,
            batches: batches
        });
    }

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    toggleCosts = (e) => {
        this.setState({openCosts: !this.state.openCosts});
    }

    togglePrintViewer = () => {
        this.setState({printViewer: !this.state.printViewer});
    }

    deleteSigCallback = async (type) => {
        try{
            let result = await axios.post('/api/roomfeedingsigdelete', {type: type, id: this.state._id});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    render(){
        const fieldAccess = this.state.fieldAccess;
        const errors = this.props.errors;
        const security = this.state.security;
        const t = this.props.t;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        const internal = this.props.auth.user.internal;
        return(
            <Aux>
                <BreadcrumbBar goBack={this.state.goBack}>
                    <ActionBar
                        fieldAccess={security.update}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}                        
                        printViewClick={this.togglePrintViewer}
                        printViewTitle={t('roomFeeding')}
                        enablePrintView={this.state.status === this.props.auth.constants.statuses.signed}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.roomFeeding}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TabPanel value={this.state.tabKey} index={0}>
                            <Grid container spacing={3}>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={6} sm={4}>
                                        <FormControl data-cy='number'>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={6} sm={4}>
                                    <FormControl data-cy='status'>
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <DateTimePicker 
                                            onChange={this.dateChange('date')}
                                            value={this.state.date}
                                            helperText={errors[t('date')]}
                                            error={errors[t('date')] != null ? true : false}
                                            disabled={!fieldAccess}
                                            label={t('date')}
                                            data-cy='date'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy='date'>
                                            <FormLabel>{t('date')}</FormLabel>
                                            <DateDisplay value={this.state.date} format="yyyy/MM/dd h:mm a"/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.locationSel}
                                            options={this.state.locations}
                                            onChange={this.changeAuto('locationSel', 'location')}
                                            error={errors[t('location')] != null ? true : false}
                                            helperText={errors[t('location')]}
                                            label={t('location')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy='location'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy='location'>
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                     <AutoCompleteField
                                        multiple={true}
                                        value={this.state.roomSel}
                                        options={this.state.rooms}
                                        onChange={this.changeMulti('roomSel', 'room')}
                                        error={errors[t('room')] != null ? true : false}
                                        helperText={errors[t('room')]} 
                                        label={t('room')}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy='room'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.typeSel}
                                            options={this.state.types}
                                            onChange={this.changeAuto('typeSel', 'type')}
                                            error={errors[t('type')] != null ? true : false}
                                            helperText={errors[t('type')]} 
                                            label={t('type')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy='type'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy='type'>
                                            <FormLabel>{t('type')}</FormLabel>
                                            {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={9} sm={5} md={4} lg={3}>
                                    {fieldAccess &&
                                        <DateTimePicker 
                                            onChange={this.dateChange('startTime')}
                                            value={this.state.startTime}
                                            helperText={errors[t('startTime')]}
                                            error={errors[t('startTime')] != null ? true : false}
                                            disabled={!fieldAccess}
                                            label={t('startTime')}
                                            required
                                            data-cy='startTime'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy='startTime'>
                                            <FormLabel>{t('startTime')}</FormLabel>
                                            <DateDisplay value={this.state.startTime} format="yyyy/MM/dd h:mm a"/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={9} sm={5} md={4} lg={3}>
                                    {fieldAccess &&
                                        <DateTimePicker
                                            onChange={this.dateChange('stopTime')}
                                            value={this.state.stopTime}
                                            helperText={errors[t('stopTime')]}
                                            error={errors[t('stopTime')] != null ? true : false}
                                            disabled={!fieldAccess}
                                            label={t('endTime')}
                                            data-cy='endTime'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy='endTime'>
                                            <FormLabel>{t('endTime')}</FormLabel>
                                            <DateDisplay value={this.state.stopTime} format="yyyy/MM/dd h:mm a"/>
                                        </FormControl>
                                    }
                                </Grid>
                                {fieldAccess &&
                                    <Grid item xs={4} sm={2}>
                                        <TextField type="number" value={this.state.effort} onChange={this.changeNumber} name="effort"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('effort')} data-cy='effort'
                                        error={errors[t('effort')] != null ? true : false} helperText={errors[t('effort')]}/>
                                    </Grid>
                                }
                                {!fieldAccess && this.state.effort != null && this.state.effort !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl data-cy='effort'>
                                            <FormLabel>{t('effort')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={this.state.effort} thousandSeparator={true} decimalScale={0}/>
                                        </FormControl>
                                    </Grid>
                                }
                                {fieldAccess &&
                                    <Grid item xs={4} sm={2}>
                                        <TextField type="number" value={this.state.ph} onChange={this.changeNumber} name="ph"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('ph')} 
                                        error={errors[t('ph')] != null ? true : false} helperText={errors[t('ph')]}/>
                                    </Grid>
                                }
                                {!fieldAccess && this.state.ph != null && this.state.ph !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('ph')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={this.state.ph} thousandSeparator={true} decimalScale={2}/>
                                        </FormControl>
                                    </Grid>
                                }
                                {fieldAccess &&
                                    <Grid item xs={4} sm={2}>
                                        <TextField type="number" value={this.state.ec} onChange={this.changeNumber} name="ec"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('ecPpm')} 
                                        error={errors[t('ec')] != null ? true : false} helperText={errors[t('ec')]}/>
                                    </Grid>
                                }
                                {!fieldAccess && this.state.ec != null && this.state.ec !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('ecPpm')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={this.state.ec} thousandSeparator={true} decimalScale={2}/>
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                            {this.state.hasForm === true &&
                                <Aux>
                                    <br/>
                                    {errors[t('form')] != null && errors[t('form')] !== '' &&
                                        <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                    }
                                    <Form renderer={renderer}
                                        defaultFields={this.state.form}
                                        onChange={this.valueChanged}
                                        disabled={!fieldAccess}/>
                                </Aux>
                            }
                            <Grid container spacing={3}>
                                {this.state.sopList != null && this.state.sopList.length > 0 &&
                                    <Grid item sm={11}>
                                        <AutoCompleteField
                                            multiple={true} disabled={!fieldAccess}
                                            value={this.state.sopsSel}
                                            options={this.state.sopList}
                                            onChange={this.changeSop}
                                            error={errors[t('sopIds')] != null ? true : false}
                                            helperText={errors[t('sopIds')]} 
                                            label={t('workRecs')}
                                            data-cy='sops'
                                        />
                                    </Grid>
                                }
                            </Grid>
                            <br/>
                            <br/>
                            <Grid container spacing={3}>
                                <Grid item xs={11}>
                                    <AutoCompleteField
                                        multiple={true}
                                        value={this.state.usersSel}
                                        options={fieldAccess && !internal ? this.state.activeUsers : this.state.userOptions}
                                        onChange={this.changeMulti('usersSel', 'users')}
                                        error={errors[t('users')] != null ? true : false}
                                        helperText={errors[t('users')]} 
                                        label={t('users')}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy='users'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectField
                                        selectAll
                                        value={this.state.batchesSel}
                                        options={this.state.batchOptions}
                                        label={t('batches')}
                                        selectAllLabel={t('selectAll')}
                                        error={errors[t('batches')] != null ? true : false}
                                        helperText={errors[t('batches')]} 
                                        disabled={!fieldAccess}
                                        onChange={this.changeMultiAll}
                                        onDelete={this.deleteMulti}
                                        data-cy="batches"
                                        linkTo='/#/cultivationbatch/'
                                    />
                                </Grid>
                            </Grid>
                            {this.state.cost !== '' && this.state.cost !== 0 && this.state.cost !== '0' && !fieldAccess &&
                                <Aux>
                                    <br/>
                                    <Grid container spacing={3}>
                                        <Grid item sm={2}>
                                            <SectionTitle title={t('costs')}/>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <IconButton aria-label="expand row" size="small" onClick={() => this.toggleCosts()} >
                                                {this.state.openCosts === true ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid container spacing={3}>
                                        <Grid item xs={8} sm={4}>
                                            <FormControl>
                                                <FormLabel>{t('total')}</FormLabel>
                                                <NumericFormat displayType={'text'} value={this.state.cost} thousandSeparator={true} decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Collapse in={this.state.openCosts} timeout="auto" unmountOnExit>
                                        {this.state.roomCosts != null && this.state.roomCosts.length > 0 && this.state.roomCosts.map((cost, a) =>
                                            <Aux>
                                                <br/>
                                                <SubSectionHeading title={cost.room.name}/>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControl>
                                                            <FormLabel>{t('cost')}</FormLabel>
                                                            <NumericFormat displayType={'text'} value={cost.cost} thousandSeparator={true} decimalScale={2}/>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>
                                                        <FormControl>
                                                            <FormLabel>{t('plants')}</FormLabel>
                                                            <NumericFormat displayType={'text'} value={cost.plantCount} thousandSeparator={true} decimalScale={0}/>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                {cost.batchCosts != null && cost.batchCosts.length > 0 &&
                                                    <Aux>
                                                        <br/>
                                                        <TableContainer comopnent={Paper}>
                                                            <Table size="small">
                                                                <TableHead>
                                                                    <TableRow key="costHead">
                                                                        <TableCell>{t('batch')}</TableCell>
                                                                        <TableCell>{t('plants')}</TableCell>
                                                                        <TableCell>{t('cost')}</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                {cost.batchCosts.map((row, i) =>
                                                                    <TableRow key={"cost" + i}>
                                                                        <TableCell>
                                                                            <Link to={'/evorabatch/' + row.batch._id}>{row.batch.number}</Link>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <NumericFormat displayType={'text'} value={row.plantCount} thousandSeparator={true} decimalScale={0}/>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <NumericFormat displayType={'text'} value={row.cost} thousandSeparator={true} decimalScale={2}/>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Aux>
                                                }
                                            </Aux>
                                        )}
                                    </Collapse>
                                </Aux>
                            }
                            <br/>
                            <SectionTitle title={t('inputs')}/>
                            {this.state.feeds.map((row, i) =>
                                <Box borderColor="primary.main" {...defaultProps}  key={'item' + i} component={'div'}>
                                    <Grid container spacing={3} key={row._id}>
                                        <Grid item sx={{width: 340}}>
                                            {fieldAccess &&
                                                <AutoCompleteField
                                                    value={row.itemSel}
                                                    options={this.state.items}
                                                    onChange={this.changeLineAuto('itemSel', 'item', i)}
                                                    error={errors[`${t('item')} (${t('line')} ${i})`] != null ? true : false}
                                                    helperText={errors[`${t('item')} (${t('line')} ${i})`]} 
                                                    label={t('item')}
                                                    disabled={!fieldAccess}
                                                    required
                                                    data-cy={'item' + i}
                                                />
                                            }
                                            {!fieldAccess && row.itemSel != null &&
                                                <FormControl data-cy={'item' + i}>
                                                    <FormLabel>{t('item')}</FormLabel>
                                                    <Link to={'/item/' + row.itemSel.value}>{row.itemSel != null ? row.itemSel.label : ''}</Link>
                                                </FormControl>
                                            }
                                        </Grid>
                                        {row.itemSel != null && row.itemSel.lotTracked === true &&
                                            <Grid item sx={{width: 230}}>
                                                {fieldAccess &&
                                                    <AutoCompleteField
                                                        value={row.containerSel}
                                                        options={row.containers}
                                                        onChange={this.changeLineAuto('containerSel', 'container', i)}
                                                        error={errors[`${t('container')} (${t('line')} ${i})`] != null ? true : false}
                                                        helperText={errors[`${t('container')} (${t('line')} ${i})`]} 
                                                        disabled={!fieldAccess}
                                                        label={t('container')}
                                                        data-cy={'container' + i}
                                                        groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                        noneMessage={t('noLotAssigned')}
                                                    />
                                                }
                                                {!fieldAccess && row.containerSel != null &&
                                                    <FormControl data-cy={'container' + i}>
                                                        <FormLabel>{t('container')}</FormLabel>
                                                        <Link to={'/container/' + row.container}>{row.containerSel.label}</Link>
                                                    </FormControl>
                                                }
                                            </Grid>
                                        }
                                        {!fieldAccess && row.lot != null &&
                                            <Grid item sx={{width: 150}}>
                                                <FormControl>
                                                    <FormLabel>{t('lot')}</FormLabel>
                                                    <Link to={'/lot/' + row.lot._id}>{row.lot.number}</Link>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {!fieldAccess  && row.feedingLot != null &&
                                            <Grid item sx={{width: 150}}>
                                                <FormControl>
                                                    <FormLabel>{t('feedingLot')}</FormLabel>
                                                    <Link to={'/feedinglot/' + row.feedingLot._id}>{row.feedingLot.number}</Link>
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 100}}>
                                            {fieldAccess &&
                                                <TextField type="number" value={row.quantity} onChange={this.changeLineValue('quantity', i)} name="quantity"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} data-cy={'quantity' + i}
                                                error={errors[`${t('quantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('quantity')} (${t('line')} ${i})`]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl data-cy={'quantity' + i}>
                                                    <FormLabel>{t('quantity')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true} decimalScale={3}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <FormControl>
                                                <FormLabel>{t('uom')}</FormLabel>
                                                {row.uomSel != null ? row.uomSel.label : ''}
                                            </FormControl>
                                        </Grid>
                                        {fieldAccess &&
                                            <Grid item sx={{width: 50}}>
                                                <IconButton aria-label="delete item" disabled={!fieldAccess} onClick={this.deleteInput(i)} data-cy={'delete' + i}>
                                                    <RemoveCircleTwoToneIcon  />
                                                </IconButton>
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            )}
                            {fieldAccess &&
                                <Box margin={1} borderColor="primary.main" {...defaultProps}  key={'newItem'} component={'div'}>
                                    <Grid container spacing={3} key={'feedEnter'}>
                                        <Grid item sx={{width: 340}}>
                                            <AutoCompleteField
                                                value={this.state.itemSel}
                                                options={this.state.items}
                                                onChange={this.changeAuto('itemSel', 'item')}
                                                error={errors[t('item')] != null ? true : false}
                                                helperText={errors[t('item')]}
                                                disabled={!fieldAccess}
                                                label={t('item')}
                                                data-cy='inputItem'
                                            />
                                        </Grid>
                                        {this.state.itemSel != null && this.state.itemSel.lotTracked === true &&
                                            <Grid item sx={{width: 230}}>
                                                <AutoCompleteField
                                                    value={this.state.containerSel}
                                                    options={this.state.containers}
                                                    onChange={this.changeAuto('containerSel', 'container')}
                                                    error={errors[t('container')] != null ? true : false}
                                                    helperText={errors[t('container')]} 
                                                    disabled={!fieldAccess}
                                                    label={t('container')}
                                                    data-cy='inputContainer'
                                                    groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                    noneMessage={t('noLotAssigned')}
                                                />
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 100}}>
                                            <TextField type="number" value={this.state.quantity} onChange={this.changeValue} name="quantity"
                                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} data-cy='inputQuantity'
                                            error={errors[t('quantity')] != null ? true : false} helperText={errors[t('quantity')]}/>
                                        </Grid>
                                        {this.state.uom != null && this.state.uom !== '' &&
                                            <Grid item sx={{width: 50}}>
                                                <FormControl>
                                                    <FormLabel></FormLabel>
                                                    {this.state.uomSel != null ? this.state.uomSel.label : ''}
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 50}}>
                                            <IconButton aria-label="add feed" disabled={!fieldAccess} onClick={this.addInput} data-cy='addInput'>
                                                <AddBoxTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                            <br/>
                            <FormButtonStack
                                id={this.state._id}
                                objectType={this.props.auth.constants.objectIds.roomFeeding}
                                callback={this.loadRecord}
                                saveClick={() => this.saveClick()}
                                status={this.state.status}
                                permissions={this.props.permission}
                                common={this.props.auth.common}
                                constants={this.props.auth.constants}
                                saveEnabled={this.state.enableSave}
                                deleteClick={this.deleteToggle}
                                activityDate={this.state.date}
                                lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                                isSuper={this.props.auth.user.isSuper}
                            />
                        </TabPanel>
                        {this.state._id != null && this.state._id !== '' && this.state.sopsSel != null &&
                            this.state.sopsSel.length > 0 && this.state.sopsSel.map((sop, i) =>
                            <TabPanel value={this.state.tabKey} index={i + 1} key={'sop' + i}>
                                <br/>
                                <ActivityWorkRecord users={this.state.users} sop={sop.value} id={this.state.sops[i].workRecord}
                                objectType={this.props.auth.constants.objectIds.roomFeeding} recordId={this.state._id} date={this.state.date}
                                locationId={this.state.location} renderNum={this.state.renderNum} complete={this.loadRecord}/>
                            </TabPanel>
                        )}
                        {this.state.hasLedgers &&
                            <TabPanel value={this.state.tabKey} index={this.state.tabLabels.length - 1}>
                                <ItemLedgerComponent record={this.state._id} permission={this.props.permission} auth={this.props.auth} renderNum={this.state.renderNum}/>
                            </TabPanel>
                        }
                    </Grid>
                </Grid>
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={fieldAccess}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.delete()} open={this.state.deleteOpen} />
                }
                {this.state.printViewer &&
                    <PrintView
                        id={this.state._id}
                        object={this.props.auth.constants.objectIds.roomFeeding}
                        open={this.state.printViewer}
                        onClose={this.togglePrintViewer}
                        callBack={this.loadRecord}
                        printAccess={this.state.security.print}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.roomFeeding}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(RoomFeeding));
