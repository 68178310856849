import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments'
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import Unlock from '../General/Unlock';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DateTimeSelect from '../UI/DateTimeSelect/DateTimeSelect';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import ActivityWorkRecord from '../Cultivation/ActivityWorkRecord';
import ActionBar from '../UI/Buttons/ActionBar';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class SaturationTest extends Component {
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            _id: '',
            date: new Date(),
            location: '',
            locations: [],
            user: null,
            users: [],
            room: '',
            rooms: [],
            batch: '',
            batches: [],
            volume: '',
            volumeUOM: '',
            volumeUomSelected: null,
            uoms: [],
            ph: '',
            ecPpm: '',
            managers: [],
            signed: false,
            approverOpen: false,
            schedule: null,
            reviewer: null,
            signer: null,
            status: null,
            statuses: [],
            tenant: this.props.auth.user.tenant,
            documents: [],
            number: '',
            locationSelected: null,
            roomSelected: null,
            batchSelected: null,
            sopIds: [],
            sopsSel: [],
            sopList: [],
            tabKey: 0,
            tabLabels: [{name: t('saturationTest'), disabled: false}],
            renderNum: Math.random(),
            security: [],
            optionsMenu: [],
            flagForSignature: 0,
            dialogOpen: false,
            enableSave: true,
            goBack: -1
        }
        this.sigPad = {};
        this.changeValue = this.changeValue.bind(this);
        this.dateChange = this.dateChange.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.cultivationLocation, common.cacheValues.status, common.cacheValues.quantityUOM, 
                                common.cacheValues.defaultGrowingRoom], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        let values = await axios.get('/api/saturationtestvalues');
        let params = queryString.parse(this.props.location.search);
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        let goBack = -1;
        if(scheduleId != null)
            goBack = -2;
        let schedule = null;
        let location = '';
        let locationSelected = null;
        if(scheduleId != null){
            schedule = await axios.get('/api/schedulebase/' + scheduleId);
            location = schedule.data.location;
            locationSelected = locations.find(x => x.value === location);
        } else {
            if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
                location = this.props.auth.user.defaultLocation;
                locationSelected = locations.find(x => x.value === location);
            } else {
                if(locations.length === 1){
                    locationSelected = locations[0];
                    location = locationSelected.value;
                }
            }
        }
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        let workRecords = [];
        let sopIds = [];
        let sopsSel = [];
        values.data.sops.forEach(sop => {
            workRecords.push({
                sop: sop._id,
                original: true
            });
            sopIds.push(sop._id);
            let val = values.data.sopList.find(x => x.value === sop._id);
            sopsSel.push(val);
        });
        this.setState({
            locations: locations,
            location: location,
            locationSelected: locationSelected,
            users: values.data.users,
            rooms: cacheValues.rooms,
            room: schedule != null && schedule.data.room != null ? schedule.data.room : '',
            uoms: cacheValues.quantityUOMs,
            managers: values.data.managers,
            schedule: scheduleId,
            statuses: cacheValues.statuses,
            qas: values.data.qas,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            sops: workRecords,
            sopList: values.data.sopList,
            sopIds: sopIds,
            sopsSel: sopsSel,
            tenant: this.props.auth.user.tenant,
            security: this.props.permission,
            goBack: goBack
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else { 
                const crumbs = [
                    { path: '/#/illnesses', label: this.props.t('employeeIllnesses'), screen: this.props.auth.screenDefs.EmployeeIllnesses},
                    { path: 'active', label: this.props.t('employeeIllness'), screen: this.props.auth.screenDefs.EmployeeIllnesses}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
        
    }

    loadRecord = async () => {
        const t = this.props.t;
        let id = this.state._id != null && this.state._id !== '' ? this.state._id : this.props.match.params.id;
        const record = await axios.get('/api/saturationtest/' + id);
        let signed = record.data.status === this.props.auth.constants.statuses.reviewed || 
            record.data.status === this.props.auth.constants.statuses.signed;
        let locationSelected = this.state.locations.find(x => x.value === record.data.location);
        let roomSelected = this.state.rooms.find(x => x.value === record.data.room);
        let batchSelected = this.state.batches != null ? this.state.batches.find(x => x.value === record.data.batch) : null;
        let volumeUomSelected = this.state.uoms.find(x => x.value === record.data.volumeUOM);
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let sopIds = [];
        let sopsSel = [];
        let tabLabels = [{name: t('saturationTest'), disabled: false}];
        record.data.sops.forEach(sop => {
            sopIds.push(sop.sop);
            let val = this.state.sopList.find(x => x.value === sop.sop);
            sopsSel.push(val);
            tabLabels.push({name: val.label, disabled: false});
        });
        const completeSigSec = (record.data._id != null && record.data._id !== '');
        const reviewSigSec = (record.data.user != null && record.data.user._id != null && 
                              record.data.user.signature != null && record.data.user.signature !== '');
        const approveSigSec = (record.data.reviewer != null && record.data.reviewer._id != null && 
                               record.data.reviewer.signature != null && record.data.reviewer.signature !== '');
        const optionsMenu = [
            { 
                security: completeSigSec, user: this.props.auth.user.id, users: this.state.users, name: t('complete'), object: record.data._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user, 
                objectType: this.props.auth.constants.objectIds.saturationTest, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, 
            },
            { 
                security: reviewSigSec, user: this.props.auth.user.id, users: this.state.managers, name: t('review'),
                title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id, 
                objectType: this.props.auth.constants.objectIds.saturationTest, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: this.state.qas, name: t('approve'),
                title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer, 
                objectType: this.props.auth.constants.objectIds.saturationTest, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        let params = queryString.parse(this.props.location.search); 
        this.setState({
            _id: record.data._id,
            location: record.data.location,
            user: record.data.user,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            room: record.data.room,
            batch: record.data.batch,
            volume: record.data.volume,
            volumeUOM: record.data.volumeUOM,
            ph: record.data.ph,
            ecPpm: record.data.ecPpm,
            signed: signed,
            status: record.data.status,
            locationSelected: locationSelected,
            roomSelected: roomSelected,
            batchSelected: batchSelected,
            volumeUomSelected: volumeUomSelected,
            documents: record.data.documents,
            number: record.data.number,
            statusSel: statusSel,
            date: record.data.date,
            sopIds: sopIds,
            sopsSel: sopsSel,
            sops: record.data.sops,
            tabLabels: tabLabels,
            renderNum: Math.random(),
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature
        }, () => {
            const crumbs = [
                { path: '/#/illnesses', label: this.props.t('employeeIllnesses'), screen: this.props.auth.screenDefs.EmployeeIllnesses},
                { path: 'active', label: this.props.t('employeeIllness') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.EmployeeIllnesses}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        if(params.tab != null)
            this.openNotification(params);
    });
}

openNotification = (params) => {
    let tabKey = this.state.tabKey;
    if(params != null && params.tab != null)
        tabKey = Number(params.tab);
    this.setState({tabKey});
}

    changeValue(e){
        this.setState({[e.target.name]: e.target.value});
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(data === 'room'){
            if(newValue != null){
                let roomBatchResult = await axios.get('/api/roombatchsel/' + newValue.value);
                this.setState({batches: roomBatchResult.data, batch: '', batchSelected: null});
            } else {
                this.setState({batch: '', batchSelected: null, batches: []});
            }
        }
    };

    changeNumber = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    dateChange(e){
        this.setState({date: e});
    }

    changeSop = (e, newValue) => {
        let sops = this.state.sops;
        let tabLabels = this.state.tabLabels;
        if(newValue != null){
            if(sops.length > newValue.length){
                for(let i = 0; i < sops.length; i++){
                    let sop = sops[i];
                    let rec = newValue.find(x => x.value === sop.sop);
                    if(!rec){
                        sops.splice(i, 1);
                        tabLabels.splice(i + 1, 1);
                        break;
                    }
                }
            } else {
                for(let i = 0; i < newValue.length; i++){
                    let val = newValue[i];
                    let rec = sops.find(x => x.sop === val.value)
                    if(!rec){
                        sops.push({
                            sop: val.value,
                            workRecord: null,
                            original: false
                        });
                        if(this.state._id != null && this.state._id !== '')
                            tabLabels.push({name: val.label, disabled: false});
                    }
                }
            }
            let sopIds = [];
            newValue.forEach(val => {
                sopIds.push(val.value);
            });
            this.setState({
                sopIds: sopIds,
                sopsSel: newValue,
                sops: sops
            });
        } else {
            for(let i = 0; i < sops.length; i++){
                let sop = sops[i];
                if(sop.required)
                    return;
            }
            this.setState({sops: [], sopIds: [], sopsSel: [], tabLabels: [{name: this.props.t('saturationTest'), disabled: false}]});
        }
    };

    saveClick = () => {        
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const t = this.props.t;
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            _id: this.state._id,
            date: this.state.date,
            location: this.state.location,
            room: this.state.room,
            batch: this.state.batch,
            volume: this.state.volume,
            volumeUOM: this.state.volumeUOM,
            ph: this.state.ph,
            ecPpm: this.state.ecPpm,
            schedule: this.state.schedule,
            sops: this.state.sops
        }
        try {
            let result = await axios.post('/api/saturationtest', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/saturationtest/' + result.data.id);
            this.setState({_id: result.data.id, number: result.data.number, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    }

    validateForm(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.date == null || state.date === '')
            errors[t('date')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if((state.room == null || state.room === '') && (state.batch == null || state.batch === '')){
            errors[t('room')] = t('required');
            errors[t('batch')] = t('required');
        }
        if(state.volume == null || state.volume === '')
            errors[t('volume')] = t('required');
        if(state.volumeUOM == null || state.volumeUOM === '')
            errors[t('volumeUOM')] = t('required');
        if(this.state.ph != null && this.state.ph !== ''){
            var ph = parseFloat(this.state.ph);
            if(ph < 0 || ph > 14)
                errors[t('ph')] = t('phError');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    validateComplete = () => {
        const t = this.props.t;
        let valid = this.validateForm();
        if(!valid)
            return t('notAllComplete');
        if(this.state.sops != null && this.state.sops.length > 0){
            for(let i = 0; i < this.state.sops.length; i++){
                let sop = this.state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        return null;
    };

    refresh = e => {
        this.setState({user: e});
        this.loadRecord();
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    toggleDialog = () => {
        this.setState({ dialogOpen: !this.state.dialogOpen });
    };

    render(){
        const signed = this.state.signed;
        const errors = this.props.errors;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar goBack={this.state.goBack}>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.saturationTest}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <Grid container spacing ={2}>
                    <Grid item xs={12}>
                        <TabPanel key={0} value={this.state.tabKey} index={0}>
                            <Grid container spacing={3}>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number}
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.statusSel != null &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('status')}</FormLabel>
                                            {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={6} md={3}>
                                    {!signed &&
                                        <DateTimeSelect
                                            onChange={this.dateChange}
                                            value={this.state.date}
                                            helperText={errors[t('date')]}
                                            error={errors[t('date')] != null ? true : false}
                                            disabled={signed}
                                            label={t('date')}
                                            required
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('date')}</FormLabel>
                                            <DateDisplay value={this.state.date} format="yyyy/MM/dd h:mm a"/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    {!signed &&
                                        <AutoCompleteField
                                            required
                                            disabled={signed}
                                            value={this.state.locationSelected}
                                            options={this.state.locations}
                                            onChange={this.changeAuto('locationSelected', 'location')}
                                            helperText={errors[t('location')]}
                                            error={errors[t('location')] != null ? true : false}
                                            label={t('location')}
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locationSelected != null ? this.state.locationSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    {!signed &&
                                        <AutoCompleteField
                                            required
                                            disabled={signed}
                                            value={this.state.roomSelected}
                                            options={this.state.rooms}
                                            onChange={this.changeAuto('roomSelected', 'room')}
                                            helperText={errors[t('room')]}
                                            error={errors[t('room')] != null ? true : false}
                                            label={t('room')}
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('room')}</FormLabel>
                                            {this.state.roomSelected != null ? this.state.roomSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    {!signed &&
                                        <AutoCompleteField
                                            required
                                            disabled={signed}
                                            value={this.state.batchSelected}
                                            options={this.state.batches}
                                            onChange={this.changeAuto('batchSelected', 'batch')}
                                            helperText={errors[t('batch')]}
                                            error={errors[t('batch')] != null ? true : false}
                                            label={t('batch')}
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('batch')}</FormLabel>
                                            {this.state.batchSelected != null ? this.state.batchSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!signed &&
                                        <TextField type="number" value={this.state.volume} onChange={this.changeNumber} name="volume"
                                        size="medium" fullWidth={true} disabled={signed} label={t('volume')} required
                                        error={errors[t('volume')] != null ? true : false} helperText={errors[t('volume')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('volume')}</FormLabel>
                                            {this.state.volume}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!signed &&
                                        <AutoCompleteField
                                            required
                                            disabled={signed}
                                            value={this.state.volumeUomSelected}
                                            options={this.state.uoms}
                                            onChange={this.changeAuto('volumeUomSelected', 'volumeUOM')}
                                            error={errors[t('volumeUOM')] != null ? true : false}
                                            helperText={errors[t('volumeUOM')]}
                                            label={t('uom')}
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('uom')}</FormLabel>
                                            {this.state.volumeUomSelected != null ? this.state.volumeUomSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!signed &&
                                        <TextField type="number" value={this.state.ph} onChange={this.changeNumber} name="ph"
                                            size="medium" fullWidth={true} disabled={signed} label={t('ph')}
                                            error={errors[t('ph')] != null ? true : false} helperText={errors[t('ph')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('ph')}</FormLabel>
                                            {this.state.ph}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!signed &&
                                        <TextField type="number" value={this.state.ecPpm} onChange={this.changeNumber} name="ecPpm"
                                            size="medium" fullWidth={true} disabled={signed} label={t('ecPpm')} 
                                            error={errors[t('ecPpm')] != null ? true : false} helperText={errors[t('ecPpm')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('ecPpm')}</FormLabel>
                                            {this.state.ecPpm}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={11}>
                                    <AutoCompleteField
                                        multiple={true} disabled={signed}
                                        value={this.state.sopsSel}
                                        options={this.state.sopList}
                                        onChange={this.changeSop}
                                        error={errors[t('sopIds')] != null ? true : false}
                                        helperText={errors[t('sopIds')]}
                                        label={t('workRecs')}
                                    />
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid container spacing={3}>
                                <Button variant="contained" size="small" color="primary" onClick={() => this.saveClick()} disabled={signed && !this.state.enableSave}>
                                    {t('save')}
                                </Button>&nbsp;&nbsp;
                                {signed &&
                                    <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.saturationTest} callback={this.refresh}/>
                                }
                            </Grid>
                        </TabPanel>
                        {this.state._id != null && this.state._id !== '' &&
                            <Aux>
                                {this.state.sopsSel != null && this.state.sopsSel.length > 0 && this.state.sopsSel.map((sop, i) =>
                                    <TabPanel key={i + 1} value={this.state.tabKey} index={i + 1}>
                                        <br/>
                                        <ActivityWorkRecord sop={sop.value} id={this.state.sops[i].workRecord} users={this.state.users} date={this.state.date}
                                        objectType={this.props.auth.constants.objectIds.saturationTest} recordId={this.state._id} tab={this.state.tabKey}
                                        locationId={this.state.location} renderNum={this.state.renderNum} complete={this.loadRecord}/>
                                    </TabPanel>
                                )}
                            </Aux>
                        }
                    </Grid>
                </Grid>
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.saturationTest}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    alert: state.alert,
    security: state.security,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});


export default withTranslation() (connect(mapStateToProps)(SaturationTest));