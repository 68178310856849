import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import SignatureCapture from '../General/SignatureCapture';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class Practitioner extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            firstName: '',
            lastName: '',
            licenseNumber: '',
            status: '',
            statuses: [],
            profession: '',
            professions: [],
            professionDetails: '',
            street1: '',
            street2: '',
            city: '',
            prov: '',
            provs: [],
            postal: '',
            country: '',
            countries: [],
            phone: '',
            practiceProv: '',
            verified: false,
            users: [],
            managers: [],
            verifiedBy: '',
            verifiedDate: null,
            verifierSignature: '',
            narcoticVerified: false,
            narcoticVerifyDate: null,
            narcoticVerifySignature: '',
            documents: [],
            verifyOpen: false,
            narcoticOpen: false,
            signed: false,
            statusSelected: null,
            provSelected: null,
            profSelected: null,
            countrySelected: null,
            verBySelected: null,
            narcBySelected: null,
            pracProvSelected: null,
            security: [],
            enableSave: true,
            renderNum: Math.random()
        };
        this.sigPad = {};
        this.changeValue = this.changeValue.bind(this);
        this.changeVerifyDate = this.changeVerifyDate.bind(this);
        this.changeNarcoticDate = this.changeNarcoticDate.bind(this);
        this.verifiedChange = this.verifiedChange.bind(this);
        this.narcoticChange = this.narcoticChange.bind(this);
    }

    async componentDidMount(){
        const values = await axios.get('/api/practitionervalues');
        this.setState({
            statuses: values.data.statuses,
            professions: values.data.professions,
            provs: values.data.provs,
            countries: values.data.countries,
            users: values.data.users,
            managers: values.data.managers,
            security: this.props.permission
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/practitioners', label: this.props.t('practitioners'), screen: this.props.auth.screenDefs.Practitioners},
                    { path: 'active', label: this.props.t('practitioner'), screen: this.props.auth.screenDefs.Practitioner}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/practitioner/' + this.props.match.params.id);
        let statusSelected = this.state.statuses.find(x => x.value === record.data.status);
        let profSelected = this.state.professions.find(x => x.value === record.data.profession);
        let provSelected = record.data.provs.find(x => x.value === record.data.address.prov);
        let countrySelected = this.state.countries.find(x => x.value === record.data.address.country);
        let pracProvSelected = record.data.provs.find(x => x.value === record.data.practiceProv);
        let verBySelected = this.state.users.find(x => x.value === record.data.verifiedBy);
        let narcBySelected = this.state.users.find(x => x.value === record.data.narcoticVerifier);
        this.setState({
            _id: record.data._id,
            firstName: record.data.firstName,
            lastName: record.data.lastName,
            licenseNumber: record.data.licenseNumber,
            status: record.data.status,
            profession: record.data.profession,
            professionDetails: record.data.professionDetails,
            street1: record.data.address.street1,
            street2: record.data.address.street2,
            city: record.data.address.city,
            prov: record.data.address.prov,
            postal: record.data.address.postal,
            country: record.data.address.country,
            phone: record.data.phone,
            practiceProv: record.data.practiceProv,
            verified: record.data.verified,
            verifiedBy: record.data.verifiedBy,
            verifiedDate: record.data.verifiedDate,
            verifierSignature: record.data.verifierSignature,
            narcoticVerified: record.data.narcoticVerified,
            narcoticVerifier: record.data.narcoticVerifier,
            narcoticVerifyDate: record.data.narcoticVerifyDate,
            narcoticVerifySignature: record.data.narcoticVerifySignature,
            documents: record.data.documents,
            statusSelected: statusSelected,
            profSelected: profSelected,
            provSelected: provSelected,
            countrySelected: countrySelected,
            pracProvSelected: pracProvSelected,
            verBySelected: verBySelected,
            narcBySelected: narcBySelected,
            provs: record.data.provs
        }, () => {
            let bcLabel = record.data.firstName + ' ' + record.data.lastName + ' (' + record.data.licenseNumber + ')';
            const crumbs = [
                { path: '/#/practitioners', label: this.props.t('practitioners'), screen: this.props.auth.screenDefs.Practitioners},
                { path: 'active', label: bcLabel, screen: this.props.auth.screenDefs.Practitioner}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    changeValue(e){
        this.setState({[e.target.name]: e.target.value});
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(data === 'country' && newValue != null){
            let provs = await axios.get('/api/provinceselect', {params: {country: newValue.value}});
            this.setState({provs: provs.data});
        }
    };

    changeVerifyDate(e){
        this.setState({verifiedDate: e});
    }

    changeNarcoticDate(e){
        this.setState({narcoticVerifyDate: e});
    }

    verifiedChange(){
        var verifyDate = !this.state.verified ? new Date() : '';
        this.setState({
            verified: !this.state.verified,
            verifiedDate: verifyDate
        });
    }

    narcoticChange(){
        var date = !this.state.narcoticVerified ? new Date() : '';
        this.setState({
            narcoticVerified: !this.state.narcoticVerified,
            narcoticVerifyDate: date
        });
    }

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
            
        var docs = [];
        this.state.documents.forEach(doc => {
            docs.push(doc._id);
        })
        const data = {
            _id: this.state._id,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            licenseNumber: this.state.licenseNumber,
            status: this.state.status,
            profession: this.state.profession,
            professionDetails: this.state.professionDetails,
            street1: this.state.street1,
            street2: this.state.street2,
            city: this.state.city,
            prov: this.state.prov,
            postal: this.state.postal,
            country: this.state.country,
            phone: this.state.phone,
            practiceProv: this.state.practiceProv,
            verified: this.state.verified,
            verifiedBy: this.state.verifiedBy,
            verifiedDate: this.state.verifiedDate,
            verifierSignature: this.state.verifierSignature,
            narcoticVerified: this.state.narcoticVerified,
            narcoticVerifier: this.state.narcoticVerifier,
            narcoticVerifyDate: this.state.narcoticVerifyDate,
            narcoticVerifySignature: this.state.narcoticVerifySignature,
            documents: docs
        }
        try {
            let result = await axios.post('/api/practitioner', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/practitioner/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        }
    }

    deleteClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._deleteClick());
    };

    _deleteClick = async () => {
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/practitioner/' + this.state._id);
                this.setState({enableSave: true});
                this.props.history.goBack();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            } catch(err){
                this.setState({ enableSave: true }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
                });            
            }
        }
    };

    validateForm(){
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.firstName == null || state.firstName === '')
            errors[t('firstName')] = t('required');
        if(state.lastName == null || state.lastName === '')
            errors[t('lastName')] = t('required');
        if(state.licenseNumber == null || state.licenseNumber === '')
            errors[t('licenseNumber')] = t('required');
        if(state.status == null || state.status === '')
            errors[t('status')] = t('required');
        if(state.profession == null || state.profession === '')
            errors[t('professionDetails')] = t('required');
        if(state.practiceProv == null || state.practiceProv === '')
            errors[t('practiceProvince')] = t('required');
        if(state.verifed){
            if(state.verifiedBy == null || state.verifiedBy === '')
                errors[t('verifiedBy')] = t('required');
            if(state.verifiedDate == null || state.verifiedDate === '')
                errors[t('verifiedDate')] = t('required');
        }
        if(state.narcoticVerified){
            if(state.narcoticVerifier == null || state.narcoticVerifier === '')
                errors[t('narcoticVerifier')] = t('required');
            if(state.narcoticVerifyDate == null || state.narcoticVerifyDate === '')
                errors[t('narcoticVerifiedDate')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    signVerClick = () => {
        this.setState({verifyOpen: true});
    }

    saveVerSig = signature => {
        this.setState({verifierSignature: signature});
    }

    closeVerSig = () => {
        this.setState({verifyOpen: false});
    }

    editVerSig = () => {
        this.setState({verifierSignature: '', signed: false});
    }

    signNarcClick = () => {
        this.setState({narcoticOpen: true});
    }

    saveNarcSig = signature => {
        this.setState({narcoticVerifySignature: signature, signed: true});
    }

    closeNarcSig = () => {
        this.setState({narcoticOpen: false});
    }

    editNarcSig = () => {
        this.setState({narcoticVerifySignature: '', signed: false});
    }

    render(){
        const signed = this.state.signed;
        const errors = this.props.errors;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        objectType={this.props.auth.constants.objectIds.practitioner}
                        id={this.state._id}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.firstName} onChange={this.changeValue} name="firstName"
                            size="medium" fullWidth={true} disabled={signed} label={t('firstName')} required
                            error={errors[t('firstName')] != null ? true : false} helperText={errors[t('firstName')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.lastName} onChange={this.changeValue} name="lastName"
                            size="medium" fullWidth={true} disabled={signed} label={t('lastName')} required
                            error={errors[t('lastName')] != null ? true : false} helperText={errors[t('lastName')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.licenseNumber} onChange={this.changeValue} name="licenseNumber"
                            size="medium" fullWidth={true} disabled={signed} label={t('licenseNumber')} required
                            error={errors[t('licenseNumber')] != null ? true : false} helperText={errors[t('licenseNumber')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.statusSelected}
                                options={this.state.statuses}
                                onChange={this.changeAuto('statusSelected', 'status')}
                                error={errors[t('status')] != null ? true : false}
                                helperText={errors[t('status')]} 
                                label={t('status')}
                                disabled={signed}
                                required
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.profSelected}
                                options={this.state.professions}
                                onChange={this.changeAuto('profSelected', 'profession')}
                                error={errors[t('profession')] != null ? true : false}
                                helperText={errors[t('profession')]} 
                                label={t('profession')}
                                disabled={signed}
                                required
                            />
                        </Grid>
                        <Grid item sm={11}>
                            <TextField value={this.state.professionDetails} onChange={this.changeValue} name="professionDetails" multiline={true} rows="3"
                            variant="outlined" size="medium" fullWidth={true} disabled={signed} label={t('professionDetails')} 
                            error={errors[t('professionDetails')] != null ? true : false} helperText={errors[t('professionDetails')]}/>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <TextField value={this.state.street1} onChange={this.changeValue} name="street1"
                            size="medium" fullWidth={true} disabled={signed} label={t('street')} 
                            error={errors[t('street1')] != null ? true : false} helperText={errors[t('street1')]}/>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <TextField value={this.state.street2} onChange={this.changeValue} name="street2"
                            size="medium" fullWidth={true} disabled={signed} label={t('street2')} 
                            error={errors[t('street2')] != null ? true : false} helperText={errors[t('street2')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.city} onChange={this.changeValue} name="city"
                            size="medium" fullWidth={true} disabled={signed} label={t('city')} 
                            error={errors[t('city')] != null ? true : false} helperText={errors[t('city')]}/>
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <AutoCompleteField
                                value={this.state.provSelected}
                                options={this.state.provs}
                                onChange={this.changeAuto('provSelected', 'prov')}
                                error={errors[t('prov')] != null ? true : false}
                                helperText={errors[t('prov')]} 
                                label={t('province')}
                                disabled={signed}
                            />
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <InputMaskField mask="a9a-9a9" name="postal" value={this.state.postal}
                            onChange={this.changeValue} 
                            helperText={errors[t('postal')]}
                            error={errors[t('postal')] != null ? true : false} 
                            label={t('postal')}
                            disabled={signed}
                            />
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <AutoCompleteField
                                value={this.state.countrySelected}
                                options={this.state.countries}
                                onChange={this.changeAuto('countrySelected', 'country')}
                                error={errors[t('country')] != null ? true : false}
                                helperText={errors[t('country')]} 
                                label={t('country')}
                                disabled={signed}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <InputMaskField mask="999-999-9999" name="phone" value={this.state.phone}
                            onChange={this.changeValue} 
                            helperText={errors[t('phone')]}
                            error={errors[t('phone')] != null ? true : false} 
                            label={t('phoneNumber')} 
                            disabled={signed}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.pracProvSelected}
                                options={this.state.provs}
                                onChange={this.changeAuto('pracProvSelected', 'practiceProv')}
                                error={errors[t('practiceProvince')] != null ? true : false}
                                helperText={errors[t('practiceProvince')]} 
                                label={t('practiceProv')}
                                disabled={signed}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField
                            checked={this.state.verified} onChange={this.verifiedChange}
                            label={t('verified')} name="verified" disabled={signed}
                            />
                        </Grid>
                        {this.state.verified &&
                            <Aux>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.verBySelected}
                                        options={this.state.users}
                                        onChange={this.changeAuto('verBySelected', 'verifiedBy')}
                                        error={errors[t('verifiedBy')] != null ? true : false}
                                        helperText={errors[t('verifiedBy')]} 
                                        label={t('verifiedBy')}
                                        disabled={signed}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <DateSelect 
                                        onChange={this.changeVerifyDate}
                                        value={this.state.verifiedDate}
                                        helperText={errors[t('verifiedDate')]}
                                        error={errors[t('verifiedDate')] != null ? true : false}
                                        disabled={signed}
                                        label={t('verifiedDate')}
                                        format={this.props.auth.user.dateFormat}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <Button variant="outlined" size="small" color="primary" onClick={this.signVerClick}>
                                        {this.state.verifierSignature == null || this.state.verifierSignature === '' ?
                                        t('sign'): t('view')}
                                    </Button>
                                </Grid>
                                <SignatureCapture signature={this.state.verifierSignature} editSig={this.editVerSig}
                                heading={t('verificationSignature')} open={this.state.verifyOpen} saveSig={this.saveVerSig}
                                close={this.closeVerSig} clearSig={this.clearVerSig}/>
                            </Aux>
                        }
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField
                            checked={this.state.narcoticVerified} onChange={this.narcoticChange}
                            label={t('narcoticsVerified')} name="narcoticVerified" disabled={signed}
                            />
                        </Grid>
                        {this.state.narcoticVerified &&
                            <Aux>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={this.state.narcBySelected}
                                        options={this.state.users}
                                        onChange={this.changeAuto('narcBySelected', 'narcoticVerifier')}
                                        error={errors[t('narcoticVerifier')] != null ? true : false}
                                        helperText={errors[t('narcoticVerifier')]} 
                                        label={t('narcVerifiedBy')}
                                        disabled={signed}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <DateSelect 
                                        onChange={this.changeNarcoticDate}
                                        value={this.state.narcoticVerifyDate}
                                        helperText={errors[t('narcoticVerifiedDate')]}
                                        error={errors[t('narcoticVerifiedDate')] != null ? true : false}
                                        disabled={signed}
                                        label={t('narcVerifiedDate')}
                                        format={this.props.auth.user.dateFormat}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <Button variant="outlined" size="small" color="primary" onClick={this.signNarcClick}>
                                        {this.state.narcoticVerifySignature == null || this.state.narcoticVerifySignature === '' ?
                                        t('sign'): t('view')}
                                    </Button>
                                </Grid>
                                <SignatureCapture signature={this.state.narcoticVerifySignature} editSig={this.editNarcSig}
                                heading={t('narcVerifiedSig')} open={this.state.narcoticOpen} saveSig={this.saveNarcSig}
                                close={this.closeNarcSig} clearSig={this.clearNarcSig}/>
                            </Aux>
                        }
                        
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            <Button variant="contained" disabled={!this.state.enableSave} color="primary" size="small" onClick={() => this.saveClick()}>{t('save')}</Button>&nbsp;&nbsp;
                            {this.state.security.delete &&
                                <Button variant="contained" disabled={!this.state.enableSave} color="secondary" size="small" onClick={this.deleteClick}>{t('delete')}</Button>
                            }
                        </Grid>
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.practitioner}/>
                            <ObjectLog id={this.state._id}  renderNum={this.state.renderNum}/>
                        </Aux>
                    }
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Practitioner));
