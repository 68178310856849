import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import DateTimeSelect from '../UI/DateTimeSelect/DateTimeSelect';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { approveSigSecurity, completeSigSecurity,
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { List, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';
import RichTextField from '../UI/RichTextField/RichTextField';
import RichText from '../../hoc/RichText/RichText';
import ConversionHelper from '../../helpers/conversionHelper';

class RoomCheck extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            date: new Date(),
            completedBy: '',
            inspectedBy: '',
            users: [],
            room: '',
            rooms: [],
            location: '',
            locations: [],
            schedule: null,
            signed: false,
            status: this.props.auth.constants.statuses.new,
            statuses: [],
            statusSel: {
                value: '',
                label: ''
            },
            user: null,
            reviewer: null,
            signer: null,
            tenant: this.props.auth.user.tenant,
            documents: [],
            number: '',
            locationSelected: null,
            roomSelected: null,
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            security: [],
            optionsMenu: [],
            flagForSignature: 0,
            dialogOpen: false,
            enableSave: true,
            form: '',
            hasForm: false,
            goBack: -1,
            temperature: '',
            tempUOM: '',
            tempUOMSel: null,
            minTemperature: '',
            maxTemperature: '',
            humidity: '',
            minHumidity: '',
            maxHumidity: '',
            batches: [],
            batchesSel: [],
            roomBatches: [],
            notes: '',
            roomTempUOM: '',
            roomTempUOMSel: null,
        }
        this.sigPad = {};
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.activeLocation, common.cacheValues.status, common.cacheValues.defaultRoom, common.cacheValues.tempUOM], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.roomCheck, this.props.auth.user.tenant);
        const values = await axios.get('/api/checkvalues');
        let params = queryString.parse(this.props.location.search);
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        let schedule = null;
        let location = '';
        let locationSelected = null;
        let goBack = -1;
        if(scheduleId != null){
            goBack = -2;
            schedule = await axios.get('/api/schedulebase/' + scheduleId);
            location = schedule.data.location;
            locationSelected = locations.find(x => x.value === location);
        } else {
            if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
                location = this.props.auth.user.defaultLocation;
                locationSelected = locations.find(x => x.value === location);
            } else {
                if(locations.length === 1){
                    locationSelected = locations[0];
                    location = locationSelected.value;
                }
            }
        }
        const statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let hasForm = values.data.form != null && values.data.form !== '' ? true : false;
        this.setState({
            rooms: cacheValues.rooms,
            users: userValues.create,
            managers: userValues.review,
            qas: userValues.approve,
            locations: locations,
            statuses: cacheValues.statuses,
            room: schedule != null && schedule.data.room != null ? schedule.data.room: '',
            location: location,
            locationSelected: locationSelected,
            schedule: scheduleId,
            statusSel: statusSel,
            fieldAccess: fieldAccess,
            security: this.props.permission,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && values.data.form != null && values.data.form !== '' ? JSON.parse(values.data.form) : '',
            hasForm: hasForm,
            goBack: goBack,
            tempUOMS: cacheValues.tempUOMs,
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord(values.data);
            }else {
                const crumbs = [
                    { path: '/#/roomchecklist', label: this.props.t('roomChecks'), screen: this.props.auth.screenDefs.RoomCheck},
                    { path: 'active', label: this.props.t('roomCheck'), screen: this.props.auth.screenDefs.RoomCheck}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const id = this.state._id !== '' ? this.state._id : this.props.match.params.id;
        if(id != null && id !== ''){
            const record = await axios.get('/api/roomcheck/' + id);
            const signed = record.data.status === this.props.auth.constants.statuses.reviewed ||
                record.data.status === this.props.auth.constants.statuses.signed;
            const locationSelected = this.state.locations.find(x => x.value === record.data.location);
            const rooms = this.state.rooms;
            const roomSelected = rooms.find(x => x.value === record.data.room);
            let tempUOMSel = this.state.tempUOMS.find(x => x.value === record.data.tempUOM);
            let roomTempUOMSel = this.state.tempUOMS.find(x => x.value === record.data.roomTempUOM);
            const statusSel = this.state.statuses.find(x => x.value === record.data.status);
            let batchesSel = [];
            if(record.data.batches != null && record.data.batches.length > 0){
                for(let row of record.data.batches){
                    let val = record.data.batchOptions.find(x => x.value === row);
                    batchesSel.push(val);
                }
            }
            const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
            const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.receiver, statusSel.value, this.props.auth.common, this.props.auth.constants);
            const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
            const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
            const optionsMenu = [
                {
                    security: completeSigSec, user: this.props.auth.user.id, users: this.state.users, name: t('complete'), object: record.data._id,
                    title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user,
                    objectType: this.props.auth.constants.objectIds.roomCheck, type: this.props.auth.constants.signatureTypes.user,
                    menuType: this.props.auth.constants.optionMenuTypes.signature,
                },
                {
                    security: reviewSigSec, user: this.props.auth.user.id, users: this.state.managers, name: t('review'),
                    title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id,
                    objectType: this.props.auth.constants.objectIds.roomCheck, type: this.props.auth.constants.signatureTypes.reviewer,
                    menuType: this.props.auth.constants.optionMenuTypes.signature
                },
                {
                    security: approveSigSec, user: this.props.auth.user.id, users: this.state.qas, name: t('approve'),
                    title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer,
                    objectType: this.props.auth.constants.objectIds.roomCheck, type: this.props.auth.constants.signatureTypes.signer,
                    menuType: this.props.auth.constants.optionMenuTypes.signature
                },
                {
                    menuType: this.props.auth.constants.optionMenuTypes.comments
                },
                {
                    menuType: this.props.auth.constants.optionMenuTypes.logs
                }
            ];
            let hasForm = this.state.hasForm;
            if(!hasForm && record.data.form != null && record.data.form !== '')
                hasForm = true;
            const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                        record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                        record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
            this.setState({
                _id: record.data._id,
                date: record.data.date,
                completedBy: record.data.completedBy,
                inspectedBy: record.data.inspectedBy,
                room: record.data.room,
                location: record.data.location,
                completedSignature: record.data.completedSignature,
                inspectedSignature: record.data.inspectedSignature,
                checkedBy: record.data.checkedBy,
                checkedSignature: record.data.checkedSignature,
                status: record.data.status,
                user: record.data.user,
                reviewer: record.data.reviewer,
                signer: record.data.signer,
                signed: signed,
                locationSelected: locationSelected,
                roomSelected: roomSelected,
                documents: record.data.documents,
                number: record.data.number,
                statusSel: statusSel,
                fieldAccess: fieldAccess,
                optionsMenu: optionsMenu,
                flagForSignature: flagForSignature,
                form: record.data.form != null ? JSON.parse(record.data.form) : null,
                hasForm: hasForm,
                temperature: record.data.temperature,
                tempUOM: record.data.tempUOM,
                tempUOMSel: tempUOMSel,
                minTemperature: record.data.minTemperature,
                maxTemperature: record.data.maxTemperature,
                humidity: record.data.humidity,
                minHumidity: record.data.minHumidity,
                maxHumidity: record.data.maxHumidity,
                notes: record.data.notes,
                roomTempUOM: record.data.roomTempUOM,
                roomTempUOMSel: roomTempUOMSel,
                batches: record.data.batches,
                batchesSel: batchesSel,
                roomBatches: record.data.batchOptions
            }, () => {
                const crumbs = [
                    { path: '/#/roomchecklist', label: this.props.t('roomChecks'), screen: this.props.auth.screenDefs.RoomCheck},
                    { path: 'active', label: this.props.t('roomCheck') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.RoomCheck}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
                this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            });
        }

    }

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const valid = this.validateForm();
        const t = this.props.t;
        let status = this.state.status;
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
                this.setState({enableSave: true});
                return;
            }
        }
        if( status === this.props.auth.constants.statuses.new ){
            status = this.props.auth.constants.statuses.inprogress;
        }
        let data = {
            _id: this.state._id,
            date: this.state.date,
            location: this.state.location,
            room: this.state.room,
            completedBy: this.state.completedBy,
            completedSignature: this.state.completedSignature,
            inspectedBy: this.state.inspectedBy,
            inspectedSignature: this.state.inspectedSignature,
            checkedBy: this.state.checkedBy,
            checkedSignature: this.state.checkedSignature,
            schedule: this.state.schedule,
            status: status,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            temperature: this.state.temperature,
            tempUOM: this.state.tempUOM,
            minTemperature: this.state.minTemperature,
            maxTemperature: this.state.maxTemperature,
            humidity: this.state.humidity,
            minHumidity: this.state.minHumidity,
            maxHumidity: this.state.maxHumidity,
            notes: this.state.notes,
            batches: this.state.batches,
            roomTempUOM: this.state.roomTempUOM
        }
        try {
            const result = await axios.post('/api/roomcheck', data);
            const statusSel = this.state.statuses.find(x => x.value === result.data.status);
            const completeSigSec = completeSigSecurity(this.props.permission.complete, result.data.receiver, result.data.status, this.props.auth.common, this.props.auth.constants);
            const reviewSigSec = reviewSigSecurity(this.props.permission.review, result.data.reviewer, result.data.status, this.props.auth.common, this.props.auth.constants);
            const approveSigSec = approveSigSecurity(this.props.permission.approve, result.data.signer, result.data.status, this.props.auth.common, this.props.auth.constants);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/roomcheck/' + result.data.id);
            this.setState({
                _id: result.data.id,
                number: result.data.number,
                status: result.data.status,
                statusSel: statusSel,
                completeSigSec: completeSigSec,
                reviewSigSec: reviewSigSec,
                approveSigSec: approveSigSec,
                enableSave: true
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    }

    validateForm(){
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.room == null || state.room === '')
            errors[t('room')] = t('required');
        if(state.date == null || state.date === ''){
            errors[t('date')] = t('required');
        } else {
            let validEndDate = ConversionHelper.validActivityDate(this.state.date, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
            if(!validEndDate){
                errors[t('date')] = t('dateBeforeLock');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    validateComplete = () => {
        const t = this.props.t;
        let state = this.state;
        let valid = this.validateForm();
        if(!valid)
            return t('notAllComplete');
        if(state.sops != null && state.sops.length > 0){
            for(let i = 0; i < state.sops.length; i++){
                let sop = state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        return null;
    }

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, async () => {
            if(data === 'room'){
                if(newValue != null){
                    let batchRes = await axios.get('/api/roombatches/' + newValue.value);
                    let batches = [];
                    let batchesSel = [];
                    for(let batch of batchRes.data.list){
                        batches.push(batch._id);
                        batchesSel.push({value: batch._id, label: batch.number});
                    }
                    let roomRes = await axios.get('/api/room/' + newValue.value);
                    this.setState({
                        roomBatches: batchesSel, 
                        batches: batches, 
                        batchesSel: batchesSel, 
                        minTemperature:roomRes.data.minTemperature != null ? roomRes.data.minTemperature : '', 
                        maxTemperature: roomRes.data.maxTemperature != null ? roomRes.data.maxTemperature : '', 
                        minHumidity: roomRes.data.minHumidity != null ? roomRes.data.minHumidity : '', 
                        maxHumidity: roomRes.data.maxHumidity != null ? roomRes.data.maxHumidity : '',
                        tempUOM: roomRes.data.tempUOM != null ? roomRes.data.tempUOM : '',
                        tempUOMSel: roomRes.data.tempUOM != null ? this.state.tempUOMS.find(x => x.value === roomRes.data.tempUOM) : null,
                        roomTempUOM: roomRes.data.tempUOM != null ? roomRes.data.tempUOM : '',
                        roomTempUOMSel: roomRes.data.tempUOM != null ? this.state.tempUOMS.find(x => x.value === roomRes.data.tempUOM) : null
                    });
                } else {
                    this.setState({
                        batches: [], 
                        batchesSel: [], 
                        roomBatches: [], 
                        minTemperature: '', 
                        maxTemperature: '', 
                        minHumidity: '', 
                        maxHumidity: '',
                        tempUOM: '',
                        tempUOMSel: null
                    });
                }
            }
        })
    };

    changeMulti = (name, data) => async (e, newValue) => {
        let values = [];
        if(newValue != null){
            for(let value of newValue){
                values.push(value.value);
            }
        }
        this.setState({
            [name]: newValue,
            [data]: values
        });
    }

    dateChange = e => {
        this.setState({date: e});
    };

    refresh = e => {
        this.setState({user: e});
        this.loadRecord();
    }

    changeRichText = async (index, newText, e) => {
        const content = newText;
        this.setState({notes: content});    
    }

    render(){
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const security = this.state.security;
        const t = this.props.t;
        const enableSave = this.state.enableSave;
        return(
            <Aux>
                <BreadcrumbBar goBack={this.state.goBack}>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.roomCheck}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Panel>
                            <Grid container spacing={3}>
                                <Grid container item spacing={1}>
                                    {this.state._id != null && this.state._id !== '' &&
                                        <Grid item xs={6} sm={4}>
                                            <FormControl data-cy="number">
                                                <FormLabel>{t('number')}</FormLabel>
                                                {this.state.number}
                                            </FormControl>
                                        </Grid>
                                    }
                                    <Grid item xs={6} sm={4}>
                                        <FormControl data-cy="status">
                                            <FormLabel>{t('status')}</FormLabel>
                                            {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        {fieldAccess &&
                                            <DateTimeSelect
                                                onChange={this.dateChange}
                                                value={this.state.date}
                                                helperText={errors[t('date')]}
                                                error={errors[t('date')] != null ? true : false}
                                                label={t('date')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy="date"
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('date')}</FormLabel>
                                                <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat + ' h:mm a'}/>
                                            </FormControl>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={5} md={3}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.locationSelected}
                                            options={this.state.locations}
                                            onChange={this.changeAuto('locationSelected', 'location')}
                                            error={errors[t('location')] != null ? true : false}
                                            helperText={errors[t('location')]}
                                            label={t('location')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="location"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locationSelected != null ? this.state.locationSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={5} md={3}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.roomSelected}
                                            options={this.state.rooms}
                                            onChange={this.changeAuto('roomSelected', 'room')}
                                            error={errors[t('room')] != null ? true : false}
                                            helperText={errors[t('room')]}
                                            label={t('room')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="room"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('room')}</FormLabel>
                                            {this.state.roomSelected != null ? this.state.roomSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            {fieldAccess &&
                                <br/>
                            }
                            <Grid container spacing={3}>
                                {this.state.roomBatches != null && this.state.roomBatches.length > 0 &&
                                    <Grid item sm={12}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                multiple
                                                value={this.state.batchesSel}
                                                options={this.state.roomBatches}
                                                onChange={this.changeMulti('batchesSel', 'batches')}
                                                error={errors[t('batches')] != null ? true : false}
                                                helperText={errors[t('batches')]}
                                                label={t('batches')}
                                                disabled={!fieldAccess}
                                                data-cy="batches"
                                            />
                                        }
                                    </Grid>
                                }
                                {!fieldAccess && this.state.batchesSel != null && this.state.batchesSel.length > 0 &&
                                    <Grid item sm={12}>
                                        <FormControl>
                                            <FormLabel>{t('batches')}</FormLabel>
                                            <List style={{ display: 'flex', flexDirection: 'row', padding: 2 }}>
                                                {this.state.batchesSel.map((batch, j) =>
                                                    <ListItem disableGutters={true} disablePadding={true} key={'batch' + j}><Link to={'/evorabatch/' + batch.value}>{batch.label}</Link>&nbsp;&nbsp;</ListItem>
                                                )}
                                            </List>
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                            <br/>
                            <Grid container spacing={3}>
                                {fieldAccess &&
                                    <Aux>
                                        <Grid item xs={4} sm={2}>
                                            <TextField value={this.state.temperature} type="number" onChange={this.changeValue} name="temperature"
                                                disabled={!fieldAccess} label={t('temperature')}
                                                error={errors[t('temperature')] != null ? true : false} helperText={errors[t('temperature')]}/>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <AutoCompleteField
                                                value={this.state.tempUOMSel}
                                                options={this.state.tempUOMs}
                                                onChange={this.changeAuto('tempUOM', 'tempUOM')}
                                                error={errors[t('tempUOM')] != null ? true : false}
                                                helperText={errors[t('tempUOM')]}
                                                label={t('uom')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy="tempUOM"
                                            />
                                        </Grid>
                                    </Aux>
                                }
                                {!fieldAccess && this.state.temperature != null && this.state.temperature !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('temperature')}</FormLabel>
                                            {this.state.tempUOMSel != null ? this.state.temperature + this.state.tempUOMSel.label : this.state.temperature}
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.minTemperature != null && this.state.minTemperature !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('minTemperature')}</FormLabel>
                                            {this.state.roomTempUOMSel != null ? this.state.minTemperature + ' ' + this.state.roomTempUOMSel.label : this.state.minTemperature}
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.maxTemperature != null && this.state.maxTemperature !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('maxTemperature')}</FormLabel>
                                            {this.state.roomTempUOMSel != null ? this.state.maxTemperature + ' ' + this.state.roomTempUOMSel.label : this.state.maxTemperature}
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                            <br/>
                            <Grid container spacing={3}>
                                {fieldAccess &&
                                    <Grid item xs={6} sm={3}>
                                        <TextField value={this.state.humidity} type="number" onChange={this.changeValue} name="humidity"
                                        disabled={!fieldAccess} label={t('humidity')}
                                        error={errors[t('humidity')] != null ? true : false} helperText={errors[t('humidity')]}/>
                                    </Grid>
                                }
                                {this.state.minHumidity != null && this.state.minHumidity !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('minHumidity')}</FormLabel>
                                            {this.state.minHumidity}
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.maxHumidity != null && this.state.maxHumidity !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('maxHumidity')}</FormLabel>
                                            {this.state.maxHumidity}
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                            {this.state.hasForm === true &&
                                <Aux>
                                    <br/>
                                    {errors[t('form')] != null && errors[t('form')] !== '' &&
                                        <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                    }
                                    <Form renderer={renderer}
                                        defaultFields={this.state.form}
                                        onChange={this.formValueChanged}
                                        disabled={!fieldAccess}/>
                                </Aux>
                            }
                            <br/>
                            <Grid container spacing={3}>
                                {fieldAccess &&
                                    <Grid item sm={12}>
                                        <RichTextField
                                            disabled={!enableSave}
                                            id='notes'
                                            key='notes'
                                            index={0}
                                            value={this.state.notes != null ? this.state.notes : ''}
                                            errors={errors}
                                            textAreaName='documentContent-richText'
                                            height={200}
                                            changeRichText={this.changeRichText}
                                        /> 
                                    </Grid>
                                }
                                {!fieldAccess &&
                                    <Grid item sm={12}>
                                        <FormControl>
                                            <FormLabel>{t('notes')}</FormLabel>
                                            <RichText>{this.state.notes != null ? this.state.notes : ''}</RichText>
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                            <br />
                        </Panel>
                    </Grid>
                </Grid>
                <FormButtonStack
                    id={this.state._id}
                    objectType={this.props.auth.constants.objectIds.roomCheck}
                    callback={this.refresh}
                    saveClick={() => this.saveClick()}
                    // printClick={'/#/roomcheckprint/' + this.state._id}
                    status={this.state.status}
                    permissions={this.state.security}
                    common={this.props.auth.common}
                    constants={this.props.auth.constants}
                    saveEnabled={this.state.enableSave}
                    activityDate={this.state.date}
                    lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                    isSuper={this.props.auth.user.isSuper}
                />
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.roomCheck}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(RoomCheck));
