import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import GeneratePdf from '../General/GeneratePdf';
import ReactDOMServer from 'react-dom/server';
import Button from '@mui/material/Button';
import Panel from '../UI/Panel/Panel';
import { withTranslation } from 'react-i18next';
import EvoraBatchPrintMarkup from './EvoraBatchPrintMarkup';

class EvoraBatchPrint extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            description: '',
            status: '',
            location: '',
            startItem: '',
            outputItem: '',
            template: '',
            startQuantity: '0',
            currentQuantity : '0',
            plannedStart: '',
            actualStart: '',
            vegStart: '',
            vegCount: '',
            expectedVegCount: '',
            flowerStart: '',
            flowerCount: '',
            expectedFlowerCount: '',
            harvestDate: '',
            expectedHarvest: '',
            expectedYield: '',
            expectedYieldUOM: '',
            actualYield: '',
            actualYieldUOM: '',
            harvestedCount: '',
            expectedHarvestCount: '',
            currentRooms: [],
            roomHistory: [],
            activities: [],
            stage: '',
            documents: [],
            plantedQuantity: '',
            reviewer: null,
            signer: null,
            expectedDryYield: '',
            expectedDryLoss: '',
            cloneSource: [],
            outputLots: [],
            cropTreatments: [],
            externalTreatments: [],
            outputPerHarvestedPlant: '',
            outputPerPlantedPlant: '',
            productionSchedule: '',
            productionScheduleActivity: '',
            trimmings: [],
            dryings: [],
            curings: [],
            strain: {},
            qrPath: null,
            stages: [],
            valueList: [],
            dateFormat: 'MM/dd/yyyy',
            roomCleaning: [],
            deviations: [],
            ncrs: [],
            feedings: []
        };
    }

    async componentDidMount(){
        const record = await axios.get('/api/ebatchprint/' + this.props.match.params.id);
        const qrPath = window.location.origin + '/#/cultivationbatch/' + this.props.match.params.id;
        //const qrPath = 'https://192.168.193.50:3000/#/evorabatch/' + this.props.match.params.id;
        //const qrPath = 'https://www.evoratechnologies.com/';
        //console.info(window.location);
        this.setState({
            _id: record.data._id,
            number: record.data.number,
            description: record.data.description,
            status: record.data.status,
            location: record.data.location,
            strain: record.data.strain,
            startItem: record.data.startItem,
            outputItem: record.data.outputItem,
            template: record.data.template,
            startQuantity: record.data.startQuantity,
            currentQuantity: record.data.currentQuantity,
            plannedStart: record.data.plannedStart,
            actualStart: record.data.actualStart,
            vegStart: record.data.vegStart,
            expectedVegCount: record.data.expectedVegCount,
            flowerStart: record.data.flowerStart,
            flowerCount: record.data.flowerCount,
            expectedFlowerCount: record.data.expectedFlowerCount,
            harvestDate: record.data.harvestDate,
            expectedHarvest: record.data.expectedHarvest,
            expectedYield: record.data.expectedYield,
            expectedYieldUOM: record.data.expectedYieldUOM,
            actualYield: record.data.actualYield,
            actualYieldUOM: record.data.actualYieldUOM,
            harvestedCount: record.data.harvestedCount,
            expectedHarvestCount: record.data.expectedHarvestCount,
            currentRooms: record.data.currentRooms,
            roomHistory: record.data.roomHistory,
            activities: record.data.activities,
            stage: record.data.stage,
            documents: record.data.documents,
            plantedQuantity: record.data.plantedQuantity,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            expectedDryYield: record.data.expectedDryYield,
            expectedDryLoss: record.data.expectedDryLoss,
            cloneSource: record.data.cloneSource,
            outputLots: record.data.outputLots,
            cropTreatments: record.data.cropTreatments,
            externalTreatments: record.data.externalTreatments,
            outputPerHarvestedPlant: record.data.outputPerHarvestedPlant,
            outputPerPlantedPlant: record.data.outputPerPlantedPlant,
            productionSchedule: record.data.productionSchedule,
            productionScheduleActivity: record.data.productionScheduleActivity,
            trimmings: record.data.trimmings,
            dryings: record.data.dryings,
            curings: record.data.curings,
            qrPath: qrPath,
            stages: record.data.stages,
            dateFormat: this.props.auth.user.dateFormat,
            roomCleaning: record.data.roomCleaning,
            deviations: record.data.deviations,
            ncrs: record.data.ncrs,
            feedings: record.data.feedings
        }, () => {
        });
    }

    returnClick = () => {
        this.props.history.goBack();
    }

    generateMarkup = () => {
        var htmlData = ReactDOMServer.renderToStaticMarkup(<EvoraBatchPrintMarkup data={this.state} constants={this.props.auth.constants} print t={this.props.t}/>);
        return htmlData;
    }

    render(){
        const t = this.props.t;
        return(
            <div style={{ width: '80%'}}>
                <div className="row">
                    <Button variant="contained" color="primary" size="small" onClick={this.returnClick}>{t('return')}</Button>&nbsp;&nbsp;
                    <GeneratePdf markup={this.generateMarkup} id={this.props.match.params.id} objectType={this.props.auth.constants.objectIds.evoraBatch}/>
                </div>
                <br />
                <br />
                <Panel>
                    <EvoraBatchPrintMarkup data={this.state} constants={this.props.auth.constants} print={false} t={t}/>
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default withTranslation() (connect(mapStateToProps)(EvoraBatchPrint));
