import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import { CSVDownload } from "react-csv";
import AddIcon from '@mui/icons-material/Add';
import ActionBar from '../UI/Buttons/ActionBar';
import listHelper from '../../helpers/listHelper';

const EquipmentList = (props) => {
    const isFirstRender = useRef(true);
    const [loadRefData, setLoadRefData] = useState(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [csvOutput, setCsvOutput] = useState({headers: null, data: null});
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [columnOrder, setColumnOrder] = useState([]);
    const [values, setValues] = useState({machineTypes: [], types: [], locations: [], rooms: []});
    const { t } = useTranslation('translation');
    const csvSeparator = sessionStorage.getItem('csvSeparator');
    const tenant = props.auth.user.tenant;
    const crumbs = [
        { path: 'active', label: t('equipmentList'), screen: props.auth.screenDefs.EquipmentList}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        async function fetchData(){
            const columnFilters = await listHelper.getItem('EquipmentsColumnFilters', tenant);
            const columnVisibility = await listHelper.getItem('EquipmentsColumnVisibility', tenant);
            const density = await listHelper.getItem('EquipmentsDensity', tenant);
            const globalFilter = await listHelper.getItem('EquipmentsGlobalFilter', tenant);
            const showGlobalFilter = await listHelper.getItem('EquipmentsShowGlobalFilter', tenant);
            const showColumnFilters = await listHelper.getItem('EquipmentsShowColumnFilters', tenant);
            const sorting = await listHelper.getItem('EquipmentsSorting', tenant);
            const pagination = await listHelper.getItem('EquipmentsPagination', tenant);
            const tempColumnOrder = await listHelper.getItem('EquipmentsColumnOrder', tenant);
            if (columnFilters) {
                setColumnFilters(JSON.parse(columnFilters));
            }
            if (columnVisibility) {
                setColumnVisibility(columnVisibility);
            }
            if (density) {
                setDensity(density);
            }
            if (globalFilter) {
                setGlobalFilter(globalFilter);
            }
            if (showGlobalFilter) {
                setShowGlobalFilter(showGlobalFilter);
            }
            if (showColumnFilters) {
                setShowColumnFilters(JSON.stringify(showColumnFilters));
            }
            if (sorting) {
                setSorting(JSON.stringify(sorting));
            }
            if (pagination) {
                setPagination(JSON.stringify(pagination));
            }
            if (tempColumnOrder != null && tempColumnOrder.length > 0){ 
                setColumnOrder(tempColumnOrder);
            } else {
                setColumnOrder(['identityNo', 'name', 'type', 'machineType', 'location', 'room', 'hide']);
            }
            isFirstRender.current = false;
        }
        if(isFirstRender.current === true){
            fetchData();
        }
    }, [values, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('EquipmentsColumnFilters', tenant, JSON.stringify(columnFilters));
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('EquipmentsColumnVisibility', tenant, columnVisibility);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnVisibility, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('EquipmentsDensity', tenant, density);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [density, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('EquipmentsGlobalFilter', tenant, globalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [globalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('EquipmentsShowGlobalFilter', tenant, showGlobalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [showGlobalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('EquipmentsShowColumnFilters', tenant, showColumnFilters);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [showColumnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('EquipmentsSorting', tenant, sorting);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [sorting, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('EquipmentsPagination', tenant, pagination);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [pagination, tenant]);

    useEffect(() => {
        async function fetchData(){
            await listHelper.setItem('EquipmentsColumnOrder', tenant, columnOrder);
        }
        if (isFirstRender.current === true) return;
        fetchData();
    }, [columnOrder, tenant]);

    const resetState = async () => {
        await listHelper.removeItem('EquipmentsColumnFilters', tenant);
        await listHelper.removeItem('EquipmentsColumnVisibility', tenant);
        await listHelper.removeItem('EquipmentsDensity', tenant);
        await listHelper.removeItem('EquipmentsGlobalFilter', tenant);
        await listHelper.removeItem('EquipmentsShowGlobalFilter', tenant);
        await listHelper.removeItem('EquipmentsShowColumnFilters', tenant);
        await listHelper.removeItem('EquipmentsSorting', tenant);
        await listHelper.removeItem('EquipmentsPagination', tenant);
        await listHelper.removeItem('EquipmentsColumnOrder', tenant);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.activeLocation, common.cacheValues.room, common.cacheValues.equipmentType,
                common.cacheValues.machineType], props.auth.constants, null, props.auth.user.tenant);
            setValues({
                types: cacheValues.equipmentTypes,
                locations: cacheValues.locations,
                rooms: cacheValues.rooms,
                machineTypes: cacheValues.machineTypes
            });
            setLoadRefData(false);
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, columnFilters, loadRefData]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'equipmentList',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/equipments', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });

    const columns = useMemo(() => [
        {
            accessorKey: 'identityNo',
            id: 'identityNo',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/equipment/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            ),
        },
        {
            accessorKey: 'name',
            id: 'name',
            header: t('name')
        },
        {
            accessorKey: 'type',
            id: 'type',
            header: t('type'),
            filterVariant: 'select',
            filterSelectOptions: values.types,
        },
        {
            accessorKey: 'machineType',
            id: 'machineType',
            header: t('machineType'),
            filterVariant: 'select',
            filterSelectOptions: values.machineTypes
        },
        {
            accessorKey: 'location',
            id: 'location',
            header: t('locations'),
            filterVariant: 'select',
            filterSelectOptions: values.locations
        },
        {
            accessorKey: 'room',
            id: 'room',
            header: t('room'),
            filterVariant: 'select',
            filterSelectOptions: values.rooms
        },
        {
            accessorKey: 'hide',
            id: 'hide',
            header: t('hide'),
            filterVariant: 'checkbox',
            Cell: ({ cell, column }) => (
                cell.row.original?.hide === true ? t('yes') : ''
            )
        }
    ], [t, values]);

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true,  columnVisibility: {id: false}},
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: false,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()} color="primary">
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('csv')}>
                    <IconButton onClick={() => csv()} color="primary">
                        <ExcelIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('clearListSettings')}>
                    <IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                {props.permission.create &&
                    <Tooltip arrow title={t('add')}>
                        <IconButton component={Link} to='/equipment'>
                            <AddIcon color="primary"/>
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });

    const csv = async () => {
        setCsvOutput({headers: null, data: null});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axios.get('/api/equipmentscsv', {params: params});
        let headers = response.data.headers;
        let csvData = response.data.data;
        setCsvOutput({headers: headers, data: csvData});
    }

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs}>
                <ActionBar/>
            </BreadcrumbBar>
            {csvOutput != null && csvOutput.data != null &&
                <CSVDownload data={csvOutput.data} target="_blank" headers={csvOutput.headers} separator={csvSeparator}/>
            }
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
        </Aux>
    )
    
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(EquipmentList);