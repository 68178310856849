import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI//DateDisplay/DateDisplay';
import RoomChanges from '../General/RoomChanges';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Aux from '../../hoc/Auxilary/Auxilary';
import SectionTitle from '../UI/Typography/SectionTitle';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ChangeConfigButton } from '../UI/Buttons/Buttons';
import { withTranslation } from 'react-i18next';

class RoomDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            room: {},
            changes: [],
            offset: 0,
            size: 5,
            pageCount: 1,
            checkOff: 0,
            checkSize: 5,
            checkPages: 1,
            security: []
        }
    }

    async componentDidMount(){
        this.loadRecord();
    }

    loadRecord = async () => {
        var room = await axios.get('/api/roomdetails/' + this.props.id);
        this.setState({
            room: room.data.data,
            security: this.props.permission
        });
    };

    renderSettings(){
        const t = this.props.t;
        let settings = this.state.room.settings;
        return (
            <Aux>
                <SectionTitle title={t('settings')}/>
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <FormControl>
                            <FormLabel>{t('lightsOn')}</FormLabel>
                            <DateDisplay value={settings.lightsOnTime} format='h:mm a'/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <FormControl>
                            <FormLabel>{t('lightsOff')}</FormLabel>
                            <DateDisplay value={settings.lightsOffTime} format='h:mm a'/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <FormControl>
                            <FormLabel>{t('par')}</FormLabel>
                            {settings.par}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <FormControl>
                            <FormLabel>{t('temperature')}</FormLabel>
                            {settings.temperature !== '' && settings.temperature != null ?
                            settings.temperature + settings.temperatureUOM.label : ''}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <FormControl>
                            <FormLabel>{t('humidity')}</FormLabel>
                            {settings.humidity}%
                        </FormControl>
                    </Grid>
                    {settings.co2 &&
                        <Grid item xs={6} sm={3}>
                            <FormControl>
                                <FormLabel>{t('co2Concentration')}</FormLabel>
                                {settings.co2Concentration}
                            </FormControl>
                        </Grid>
                    }
                    <Grid item sm={11}>
                        <FormControl>
                            <FormLabel>{t('lightInformation')}</FormLabel>
                            {settings.lightInformation}
                        </FormControl>
                    </Grid>
                </Grid>
            </Aux>
        )
    }

    renderFeeding(){
        let feeding = this.state.room.feeding;
        const t = this.props.t;
        return(
            <Aux>
                <br/>
                <SectionTitle title={t('feeding')}/>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <FormControl>
                            <FormLabel>{t('waterTemperature')}</FormLabel>
                            {feeding.waterTemperature != null ? feeding.waterTemperature + feeding.waterUOM.label : ''}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <FormControl>
                            <FormLabel>{t('ph')}</FormLabel>
                            {feeding.ph}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <FormControl>
                            <FormLabel>{t('ecPpm')}</FormLabel>
                            {feeding.ecPpm}
                        </FormControl>
                    </Grid>
                </Grid>
                <br/>
                <SectionTitle title={t('ingredients')}/>
                {feeding.ingredients != null && feeding.ingredients.length > 0 &&
                    <Aux>
                    <TableContainer component={Paper}>
                        <Table aria-label="Ingredients">
                            <TableHead>
                                <TableCell>{t('ingredient')}</TableCell>
                                <TableCell>{t('amount')}</TableCell>
                            </TableHead>
                            <TableBody>
                                {feeding.ingredients.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell>{row.ingredient.label}</TableCell>
                                        <TableCell>{row.amount + row.amountUOM.label}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Aux>
                }
                <br/>
                <SectionTitle title={t('feedingTimes')}/>
                {feeding.feedings != null && feeding.feedings.length > 0 &&
                    <Aux>
                    <TableContainer component={Paper}>
                        <Table aria-label="Feedings">
                            <TableHead>
                                <TableCell>{t('time')}</TableCell>
                                <TableCell>{t('duration')}</TableCell>
                                <TableCell>{t('amount')}</TableCell>
                            </TableHead>
                            <TableBody>
                                {feeding.feedings.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            {row.time != null && row.time !== '' ? <DateDisplay value={row.time} format='h:mm a'/> : ''}
                                        </TableCell>
                                        <TableCell>
                                            {row.duration != null && row.duration !== '' ? row.duration + row.durationUOM.label : ''}
                                        </TableCell>
                                        <TableCell>
                                            {row.amount != null && row.amount !== '' ? row.amount + row.amountUOM.label : ''}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Aux>
                }
            </Aux>
        )
    }

    render(){
        var room = this.state.room;

        return(
            <Aux>
                {room.location != null  &&
                    <Aux>
                        <ChangeConfigButton location={'/#/roomsettings/' + room._id} security={this.state.security.update} />
                        <br/>
                        <br/>
                        {room.settings != null &&
                            this.renderSettings()
                        }
                        {room.feeding != null && room.feeding.ingredients.length > 0 &&
                            this.renderFeeding()
                        }
                        <RoomChanges room={this.props.id}/>
                    </Aux>
                }
                {room.location == null &&
                    <Aux><h2>Loading</h2></Aux>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(RoomDetails));
