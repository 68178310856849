import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class TaskRiskAssessmentList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            user: '',
            users: [],
            location: '',
            locations: [],
            statuses: [],
            status: '',
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            locationSel: null,
            userSel: null,
            statusSel: null,
            objects: [],
            object: '',
            objSel: null,
            sops: [],
            sop: '',
            sopSel: null,
            security: []
        }
    }

    async componentDidMount(){
        const values = await axios.get('/api/taskrisklistvalues');
        this.setState({
            locations: values.data.locations,
            users: values.data.users,
            statuses: values.data.statuses,
            objects: values.data.objects,
            sops: values.data.sops,
            security: this.props.permission
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('riskAssessments'), screen: this.props.auth.screenDefs.TaskRiskAssessmentList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
        this.loadRecords();
    }

    loadRecords = async () => {
        const sort = {[this.state.orderBy]: this.state.ordDir};
        const data = {
            offset: this.state.offset,
            size: this.state.listSize,
            user: this.state.user,
            location: this.state.location,
            status: this.state.status,
            sort: JSON.stringify(sort),
            object: this.state.object,
            sop: this.state.sop
        }
        const list = await axios.get('/api/taskrisks', {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        });
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/taskrisk'
                        createAccess={security.create}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.locationSel}
                                options={this.state.locations}
                                onChange={this.changeAuto('locationSel', 'location')}
                                label={t('location')}
                            />
                        </Grid> 
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.objSel}
                                options={this.state.objects}
                                onChange={this.changeAuto('objSel', 'object')}
                                label={t('activity')}
                            />
                        </Grid>
                        {this.state.object === this.props.auth.constants.objectIds.sopInstance &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.sopSel}
                                    options={this.state.sops}
                                    onChange={this.changeAuto('sopSel', 'sop')}
                                    label={t('workRecord')}
                                />
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.userSel}
                                options={this.state.users}
                                onChange={this.changeAuto('userSel', 'user')}
                                label={t('user')}
                            />
                        </Grid> 
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.statusSel}
                                options={this.state.statuses}
                                onChange={this.changeAuto('statusSel', 'status')}
                                label={t('status')}
                            />
                        </Grid> 
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Task Risk Assessments">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('date')}
                                        active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('createdBy')}
                                        active={this.state.orderBy === 'createdBy'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('user')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('location')}
                                        active={this.state.orderBy === 'location'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('location')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Activity</TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('status')}
                                        active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/taskrisk/' + row._id} name={'riskAssLink' + i}>{row.number}</Link></TableCell>
                                        <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={row.date}/></TableCell>
                                        <TableCell>{row.createdBy.firstName + ' ' + row.createdBy.lastName}</TableCell>
                                        <TableCell>{row.location.name}</TableCell>
                                        <TableCell>{row.sop != null ? row.objectType.text + ': ' + row.sop.name : row.objectType.text}</TableCell>
                                        <TableCell>{row.status.label}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(TaskRiskAssessmentList));