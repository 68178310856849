import { SET_PROFILE, RESET_PROFILE } from '../actions/types';

const initialState = {
    profile: {}
}

export default function profileReducer(state = initialState, action){
    
    switch(action.type){
        case SET_PROFILE:
            return {
                ...state,
                profile: action.payload
            }
        case RESET_PROFILE: {
            return initialState;
        }
        default:
            return state;
    }
}