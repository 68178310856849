import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import SectionTitle from '../UI/Typography/SectionTitle'
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import FormLabel from '@mui/material/FormLabel';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { approveSigSecurity, completeSigSecurity,
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import { List, ListItem } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import queryString from 'query-string';

class MasterCase extends Component {
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            _id: '',
            number: '',
            location: '',
            locationSel: null,
            locations: [],
            date: new Date(),
            sales: [],
            salesSel: [],
            salesOptions: [],
            plannedOutputs: [],
            lines: [],
            users: [],
            usersSel: [],
            userOptions: [],
            activeUsers: [],
            user: null,
            reviewer: null,
            reviewers: [],
            activeReviewers: [],
            signer: [],
            signers: [],
            activeSigners: [],
            notes: '',
            form: '',
            finalDocument: null,
            allItems: [],
            items: [],
            uoms: [],
            bins: [],
            routings: [],
            boms: [],
            allUsers: [],
            hasForm: false,
            template: '',
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            tabLabels: [
                {name: t('masterCase'), disabled: false}
            ], 
            hasLedgers: false,
            flagForSignature: 0,
            optionsMenu: [],
            containerTypes: [],
            enableSave: true,
            tabKey: 0,
            saleObjs: [],
            caseLotItems: [],
            plannedItem: '',
            plannedItemSel: null,
            plannedQuantity: '',
            plannedUOMSel: null,
            released: false,
            renderNum: Math.random(),
            dialogOpen: false,
            deleteOpen: false,
            scheduleId: '',
            sigData: null,
            goBack: -1,
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.processingLocation, common.cacheValues.status, common.cacheValues.bin, common.cacheValues.quantityUOM,
            common.cacheValues.routing, common.cacheValues.productionItem, common.cacheValues.containerType, common.cacheValues.bom, common.cacheValues.users, 
            common.cacheValues.caseLotItems, common.cacheValues.masterCaseForm, common.cacheValues.item], this.props.auth.constants, null, this.props.auth.user.tenant);
        let params = queryString.parse(this.props.location.search);
        let scheduleId = params != null && params.schedule != null ? params.schedule : null;
        let goBack = -1;
        if(scheduleId != null)
            goBack = -2;
        const userValues = await ValueHelper.screenUsers(common.screens.eproductionOrder, this.props.auth.user.tenant);
        let saleResult = await axios.get('/api/saleselect', {params: {status: this.props.auth.constants.statuses.signed}});
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
                    this.props.auth.user.defaultLocation : '';
        let locationSel = location !== '' ? cacheValues.locations.find(x => x.value === location) : null;
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeReviewers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeReviewers.push(row);
            }
        });
        let activeSigners = [];
        userValues.approve.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeSigners.push(row);
            }
        })
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        this.setState({
            locations: cacheValues.locations,
            bins: cacheValues.bins,
            allItems: cacheValues.items,
            items: cacheValues.productionItems,
            caseLotItems: cacheValues.caseLotItems,
            statuses: cacheValues.statuses,
            uoms: cacheValues.quantityUOMs,
            routings: cacheValues.routings,
            boms: cacheValues.boms,
            containerTypes: cacheValues.containerTypes,
            location: location,
            locationSel: locationSel,
            fieldAccess: fieldAccess,
            statusSel: statusSel,
            status: this.props.auth.constants.statuses.new,
            hasform: hasForm,
            activeUsers: activeUsers,
            userOptions: userValues.create,
            activeReviewers: activeReviewers,
            reviewers: userValues.review,
            activeSigners: activeSigners,
            signers: userValues.approve,
            saleOptions: saleResult.data,
            template: cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : null,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null,
            security: this.props.permission,
            goBack: goBack
        }, () => {
            if(this.state._id != null){
                this.loadRecord();
            }
        
        });
    }
    
    loadRecord = async () => {
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        const constants = this.props.auth.constants;
        let result = await axios.get('/api/mastercase/' + this.state._id);
        let data = result.data;
        let released = data.status !== constants.statuses.new && data.status !== constants.statuses.inserted ? true : false;
        let statusSel = this.state.statuses.find(x => x.value === data.status);
        let locationSel = this.state.locations.find(x => x.value === data.location);
        let usersSel = [];
        if(data.users != null){
            data.users.forEach(row => {
                let found = this.state.userOptions.find(x => x.value === row);
                if(found != null){
                    usersSel.push(found);
                }
            });
        }
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        for(let row of data.plannedOutputs){
            let itemSel = this.state.allItems.find(x => x.value === row.item);
            row.itemSel = itemSel;
            let uomSel = this.state.uoms.find(x => x.value === itemSel.uom);
            row.uomSel = uomSel;
            
        }
        for(let line of data.lines){
            let itemSel = this.state.allItems.find(x => x.value === line.item);
            line.itemSel = itemSel;
            let uomSel = this.state.uoms.find(x => x.value === itemSel.uom);
            line.uomSel = uomSel;
            let routingSel = this.state.routings.find(x => x.value === line.routing);
            line.routingSel = routingSel;
            let bomSel = this.state.boms.find(x => x.value === line.bom);
            line.bomSel = bomSel;
            for(let row of line.inputs){
                let val = this.state.allItems.find(x => x.value === row.item);
                row.itemSel = val;
                let uomSel = this.state.uoms.find(x => x.value === val.uom);
                row.uomSel = uomSel;
                let count = line.inputs.filter(x => x.item === row.item).length;
                if(count > 1){
                    row.canDelete = true;
                }
            }
            let customLot = false;
            for(let row of line.outputs){
                let val = this.state.items.find(x => x.value === row.item);
                row.itemSel = val;
                if(val.customNumber === true)
                    customLot = true;
                let uomSel = this.state.uoms.find(x => x.value === val.uom);
                row.uomSel = uomSel;
                row.containerTypeSel = this.state.containerTypes.find(x => x.value === row.containerType);
                row.binSel = this.state.bins.find(x => x.value === row.bin);
                let count = line.outputs.filter(x => x.item === row.item).length;
                if(count > 1){
                    row.canDelete = true;
                }
                line.customLot = customLot;
            }
        }
        let salesSel = [];
        if(data.sales != null){
            for(let row of data.sales){
                let value = data.saleOptions.find(x => x.value === row);
                if(value != null){
                    salesSel.push(value);
                }
            }
        }
        const completeSigSec = completeSigSecurity(this.props.permission.complete, data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: usersSel, name: t('complete'),
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: result.data.user,
                objectType: this.props.auth.constants.objectIds.masterCase, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, object: this.state._id, saveCallback: this.saveCallback
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: !internal && data.reviewer == null ? this.state.activeReviewers : this.state.reviewers,
                name: t('review'), title: t('reviewedBy'), setId: () => this.loadRecord(), signature: data.reviewer, object: data._id,
                objectType: this.props.auth.constants.objectIds.masterCase, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: !internal && data.signer == null ? this.state.activeSigners : this.state.signers,
                name: t('approve'), title: t('approvedBy'), setId: () => this.loadRecord(), object: data._id, signature: data.signer,
                objectType: this.props.auth.constants.objectIds.masterCase, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;

        let tabLabels = [
            {name: t('masterCase'), disabled: false}
        ];
        let hasForm = this.state.hasForm;
        if(!hasForm && data.form != null && data.form !== '')
            hasForm = true;
        let form = data.form != null && data.form !== '' ? JSON.parse(data.form) : this.state.template != null && this.state.template !== '' ? this.state.template : null;
        if(data.hasLedgers){
            tabLabels.push({name: t('itemLedgers'), disabled: false});
        }
        this.setState({
            number: data.number,
            location: data.location,
            locationSel: locationSel,
            status: data.status,
            statusSel: statusSel,
            date: data.date,
            sales: data.sales,
            salesSel: salesSel,
            plannedOutputs: data.plannedOutputs,
            lines: data.lines,
            users: data.users,
            usersSel: usersSel,
            hasLedgers: data.hasLedgers,
            form: form,
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            optionsMenu: optionsMenu,
            tabLabels: tabLabels,
            hasForm: hasForm,
            flagForSignature: flagForSignature,
            saleOptions: data.saleOptions,
            released: released,
            renderNum: Math.random(),
            scheduleId: data.schedule,
            user: data.user,
            reviewer: data.reviewer,
            signer: data.signer,
            fieldAccess: fieldAccess
        }, () => {
            const crumbs = [
                { path: '/#/mastercases', label: this.props.t('masterCases'), screen: this.props.auth.screenDefs.MasterCase},
                { path: 'active', label: this.props.t('masterCase') + ' - ' + data.number, screen: this.props.auth.screenDefs.MasterCase}
            ]
            this.loadContainers();
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    }

    loadContainers = async () => {
        let result = await axios.get('/api/mastercase/inputcontainers/' + this.state._id);
        let dataLines = result.data.lines;
        let lines = this.state.lines;
        for(let i = 0; i < lines.length; i++){
            let line = lines[i];
            let dataLine = dataLines[i];
            for(let x = 0; x < line.inputs.length; x++){
                let input = line.inputs[x];
                let dataInput = dataLine.inputs[x];
                input.containers = dataInput.containers;
                input.containerSel = input.containers.find(x => x.value === input.container);
            }
        }
        this.setState({lines: lines});
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({[name]: newValue, [data]: newValue != null ? newValue.value : ''});
        if(data === 'plannedItem'){
            if(newValue != null){
                let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                this.setState({plannedUOMSel: uomSel});
            } else {
                this.setState({plannedUOMSel: null});
            
            }
        }
    };

    changeMulti = (name, data) => async (e, newValue) => {
        let ids = newValue != null ? newValue.map(x => x.value) : [];
        this.setState({[name]: newValue, [data]: newValue != null ? newValue.map(x => x.value) : []});
        if(data === 'sales' && ids != null && ids.length > 0){
            let sorting = [];
            let columnFilters = [
                {id: 'ids', value: ids},
            ];
            let params = {
                filters: JSON.stringify(columnFilters ?? []),
                sorting: JSON.stringify(sorting),
            };
            let saleRes = await axios.get('/api/sales', {params: params});
            this.setState({saleObjs: saleRes.data.data}, () => {
                this.generatePlannedOutputs();
            });
        }
    }

    generatePlannedOutputs = () => {
        let sales = this.state.saleObjs;
        let planned = [];
        if(sales != null && sales.length > 0){
            for(let sale of sales){
                for(let row of sale.lines){
                    let item = this.state.caseLotItems.find(x => x.value === row.item._id);
                    if(item != null){
                        let plannedRow = planned.find(x => x.item === item.value);
                        if(plannedRow != null){
                            let saleFound = plannedRow.sales != null ? plannedRow.sales.find(x => x.sale === sale._id) : null;
                            if(saleFound !== true){
                                if(plannedRow.sales == null)
                                    plannedRow.sales = [];
                                plannedRow.sales.push({sale: sale._id, saleLine: row._id});
                                plannedRow.quantity = parseInt(plannedRow.quantity) + parseInt(row.quantity);
                            }
                        } else {
                            planned.push({
                                item: item.value, 
                                itemSel: item, 
                                quantity: row.quantity, 
                                uom: item.uom, 
                                uomSel: this.state.uoms.find(x => x.value === item.uom),
                                sales: [{sale: sale._id, saleLine: row._id}]
                            });
                        }
                    }
                }
            }
        }
        this.setState({plannedOutputs: planned});
    }

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeLineValue = (name, i, j, type) => (e) => {
        let lines = this.state.lines;
        lines[i][type][j][name] = e.target.value;
        this.setState({lines: lines});
    };

    changeLineAuto = (name, data, i, j, type) => (e, newValue) => {
        let lines = this.state.lines;
        lines[i][type][j][name] = newValue;
        lines[i][type][j][data] = newValue != null ? newValue.value : '';
        this.setState({lines: lines});
    };

    changeOutputValue = (name, index) => (e) => {
        let rows = this.state.plannedOutputs;
        rows[index][name] = e.target.value;
        this.setState({plannedOutptus: rows});
    };

    changeOutputAuto = (name, data, index) => (e, newValue) => {
        let rows = this.state.plannedOutputs;
        rows[index][name] = newValue;
        rows[index][data] = newValue != null ? newValue.value : '';
        this.setState({plannedOutputs: rows});
    };

    addPlannedOutput = () => {
        let plannedOutputs = this.state.plannedOutputs;
        plannedOutputs.push({
            item: this.state.plannedItem, 
            itemSel: this.state.plannedItemSel, 
            quantity: this.state.plannedQuantity,
            uomSel: this.state.plannedUOMSel
        });
        this.setState({
            plannedOutputs: plannedOutputs, 
            plannedItem: '', 
            plannedItemSel: null, 
            plannedQuantity: '', plannedUOMSel: null
        });
    }

    removePlannedOutput = (index) => {
        let rows = this.state.plannedOutputs;
        rows.splice(index, 1);
        this.setState({plannedOutputs: rows});
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.save()})
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._save();
        });
    }

    _save = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid){
            this.setState({enableSave: true});
            return;
        }
        let data = {
            _id: this.state._id,
            number: this.state.number,
            location: this.state.location,
            date: this.state.date,
            sales: this.state.sales,
            plannedOutputs: this.state.plannedOutputs,
            lines: this.state.lines,
            users: this.state.users,
            sigData: this.state.sigData,
        };
        try {
            let result = await axios.post('/api/mastercase', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/mastercase/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch (err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    validate(full){
        const t = this.props.t;
        let state = this.state;
        let errors = {};
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.date == null || state.date === '')
            errors[t('date')] = t('required');
        if(state.plannedOutputs == null || state.plannedOutputs.length === 0)   
            errors[t('plannedItem')] = t('required');
        if(state.users == null || state.users.length === 0)
            errors[t('users')] = t('required');
        if(full){
            if((state.number == null || state.number === '') && (state._id != null && state._id !== ''))
                errors[t('number')] = t('required');
            for(let x = 0; x < state.lines.length; x++){
                let line = state.lines[x];
                for(let i = 0; i < line.inputs.length; i++){
                    let input = line.inputs[i];
                    if(input.quantity == null || input.quantity === '')
                        errors[`${t('input')} (${t('line')} ${x}, ${i} ${t('quantity')})`] = t('required');
                    if(input.container == null || input.container === '')
                        errors[`${t('input')} (${t('line')} ${x}, ${i} ${t('container')})`] = t('required');
                
                }
                for(let i = 0; i < line.outputs.length; i++){
                    let output = line.outputs[i];
                    if(output.quantity == null || output.quantity === '')
                        errors[`${t('output')} (${t('line')} ${x}, ${i} ${t('quantity')})`] = t('required');
                    if(output.itemSel != null && output.customNumber === true && output.lot == null){
                        errors[`${t('output')} (${t('line')} ${x}, ${i} ${t('customLot')})`] = t('required');
                    }
                    if(output.bin == null || output.bin === '')
                        errors[`${t('output')} (${t('line')} ${x}, ${i} ${t('bin')})`] = t('required');
                }
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    validateComplete(){
        this.validate(true);
    }

    toggleDialog = () => {
        this.setState({ dialogOpen: !this.state.dialogOpen });
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e));
    }

    _deleteClick = async (e) => {
        if(this.state._id == null || this.state._id === ''){
            this.setState({enableSave: true, deleteOpen: false});
            if(this.state.goBack === -1)
                this.props.history.goBack();
            else
                this.props.history.go(this.state.goBack);
            return;
        }
        const t = this.props.t;
        try {
            let result = await axios.delete('/api/mastercase/' + this.state._id);
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            if(this.state.goBack === -1)
                this.props.history.goBack();
            else
                this.props.history.go(this.state.goBack);
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    release = () => {
        if(this.state.status !== this.props.auth.constants.statuses.new)
            return;
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._release();
        });
    }

    _release = async () => {
        const t = this.props.t;
        let errors = {};
        if(this.state.plannedOutputs == null || this.state.plannedOutputs.length === 0)
            errors[t('plannedItem')] = this.props.t('required');
        if(!isEmpty(errors)){
            this.setState({errors: errors, enableSave: true});
            return;
        }
        try {
            let result = await axios.post('/api/mastercaserelease', {_id: this.state._id});
            this.setState({enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch (err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    unrelease = () => {
        if( this.state.status !== this.props.auth.constants.statuses.released)
            return;
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._unrelease();
        });
    }

    _unrelease = async () => {
        const t = this.props.t;
        try {
            let result = await axios.post('/api/mastercaseunrelease', {_id: this.state._id});
            this.setState({enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch (err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    duplicateRow = (type, i, j) => {
        let lines = this.state.lines;
        let line = lines[i];
        let rows = line[type];
        let row = rows[j];
        let newRow = {item: row.item, itemSel: row.itemSel, quantity: '', uom: row.uom, uomSel: row.uomSel, canDelete: true};
        if(type === 'inputs'){
            newRow.containerSel = null;
            newRow.container = '';
            newRow.containers = row.containers;
        }
        rows.splice(j + 1, 0, newRow);
        let itemRows = rows.filter(x => x.item === row.item);
        itemRows.forEach(x => x.canDelete = true);
        this.setState({lines: lines});
    }

    removeRow = (type, i, j) => {
        let lines = this.state.lines;
        let line = lines[i];
        let rows = line[type];
        let row = rows[j];
        let itemRows = rows.filter(x => x.item === row.item);
        if(itemRows.length > 1){
            if(itemRows.length === 2){
                itemRows.forEach(x => x.canDelete = false);
            }
            rows.splice(j, 1);
        }
        this.setState({lines: lines});
    }

    render() {
        const t = this.props.t;
        const fieldAccess = this.state.fieldAccess;
        const errors = this.props.errors;
        const released = this.state.released;
        const internal = this.props.auth.user.internal;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        fieldAccess={this.state.fieldAccess}
                        scheduleClick={this.state.scheduleId != null && this.state.scheduleId !== '' ? this.toggleDialog : null}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.masterCase}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabPanel key="main" value={this.state.tabKey} index={0}> 
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField
                                value={this.state.number}
                                type="text"
                                onChange={this.changeValue}
                                error={errors[t('number')] != null ? true : false}
                                helperText={errors[t('number')]}
                                disabled={!fieldAccess}
                                required
                                label={t('number')}
                                name='number'
                                data-cy='number'
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <FormControl data-cy="status">
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSel != null ? this.state.statusSel.label : ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess && !released &&
                                <AutoCompleteField
                                    value={this.state.locationSel}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locationSel', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]}
                                    label={t('location')}
                                    disabled={!fieldAccess && !released}
                                    required
                                    data-cy="location"
                                />
                            }
                            {(!fieldAccess  || released) &&
                                <FormControl data-cy="location">
                                    <FormLabel>{t('location')}</FormLabel>
                                    {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess && !released &&
                                <DateSelect
                                    onChange={this.changeDate('date')}
                                    value={this.state.date}
                                    helperText={errors[t('date')]}
                                    error={errors[t('date')] != null ? true : false}
                                    label={t('date')}
                                    disabled={!fieldAccess}
                                    format={this.props.auth.user.dateFormat}
                                    required
                                    data-cy="date"
                                />
                            }
                            {(!fieldAccess || released) &&
                                <FormControl data-cy="date">
                                    <FormLabel>{t('date')}</FormLabel>
                                    <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        {fieldAccess && !released &&
                            <Grid item xs={8} sm={4}>
                                <AutoCompleteField
                                    multiple={true}
                                    value={this.state.salesSel}
                                    options={this.state.saleOptions}
                                    onChange={this.changeMulti('salesSel', 'sales')}
                                    error={errors[t('sales')] != null ? true : false}
                                    helperText={errors[t('sales')]}
                                    label={t('sales')}
                                    disabled={!fieldAccess || released}
                                    data-cy="sales"
                                />
                            </Grid>
                        }
                        {(!fieldAccess || released) && this.state.salesSel != null && this.state.salesSel.length > 0 &&
                            <Grid item sm={4}>
                                <FormControl data-cy="sales">
                                    <FormLabel>{t('sales')}</FormLabel>
                                    <List style={{ display: 'flex', flexDirection: 'row', padding: 2 }}>
                                        {this.state.salesSel.map((row, j) =>
                                            <ListItem disableGutters={true} disablePadding={true} key={'sale' + j}><Link to={'/sale/' + row.value}>{row.label}</Link>&nbsp;&nbsp;</ListItem>
                                        )}
                                    </List>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item sm={10}>
                            <AutoCompleteField
                                multiple={true}
                                value={this.state.usersSel}
                                options={fieldAccess && !internal ? this.state.activeUsers : this.state.userOptions}
                                onChange={this.changeMulti('usersSel', 'users')}
                                error={errors[t('users')] != null ? true : false}
                                helperText={errors[t('users')]}
                                label={t('users')}
                                disabled={!fieldAccess}
                                data-cy="users"
                            />
                        </Grid>
                    </Grid>
                    {this.state.hasForm === true &&
                        <Aux>
                            <br/>
                            {errors[t('form')] != null && errors[t('form')] !== '' &&
                                <div style={{color: 'red'}}>{errors[t('form')]}</div>
                            }
                            <Form renderer={renderer}
                                defaultFields={this.state.form}
                                onChange={this.formValueChanged}
                                disabled={!fieldAccess}/>
                        </Aux>
                    }
                    <br/>
                    <br/>
                    <SectionTitle title={t('plannedOutputs')} />
                    <Grid container spacing={3} key="plannedHead">
                        <Grid item xs={6} sm={5}>
                            <FormLabel>{t('item')}</FormLabel>
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <FormLabel>{t('quantity')}</FormLabel>
                        </Grid>
                        <Grid item xs={1} sm={1}>
                            <FormLabel>{t('uom')}</FormLabel>
                        </Grid>
                    </Grid>
                    {this.state.plannedOutputs.map((row, index) => 
                        <Grid container spacing={3} key={'planned' + index}>
                            {fieldAccess && !released &&
                                <Grid item xs={6} sm={5}>
                                    <AutoCompleteField
                                        value={row.itemSel}
                                        options={this.state.caseLotItems}
                                        onChange={this.changeOutputAuto('itemSel', 'item', index)}
                                        error={errors[`${t('plannedOutputsItem')} (${t('line')} ${index})`] != null ? true : false}
                                        helperText={errors[`${t('plannedOutputsItem')} (${t('line')} ${index})`]}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy={'plannedOutputs' + index + 'item'}
                                    />
                                </Grid>
                            }
                            {(!fieldAccess || released) && row.itemSel != null &&
                                <Grid item xs={6} sm={5}>
                                    <FormControl data-cy={'plannedOutputs' + index + 'item'}>
                                        <Link to={'/item/' + row.item}>{row.itemSel.label}</Link>
                                    </FormControl>
                                </Grid>
                            }
                            {fieldAccess && !released &&
                                <Grid item xs={2} sm={1} align="right">
                                    <TextField
                                        value={row.quantity}
                                        type="number"
                                        onChange={this.changeOutputValue('quantity', index)}
                                        error={errors[`${t('plannedOutputsQuantity')} (${t('line')} ${index})`] != null ? true : false}
                                        helperText={errors[`${t('plannedOutputsQuantity')} (${t('line')} ${index})`]}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy={'plannedOutputs' + index + 'quantity'}
                                    />
                                </Grid>
                            }
                            {(!fieldAccess || released) &&
                                <Grid item xs={2} sm={1} align="right">
                                    <FormControl data-cy={'plannedOutputs' + index + 'quantity'}>
                                        {row.quantity}
                                    </FormControl>
                                </Grid>
                            }
                            <Grid item xs={1} sm={1}>
                                <FormControl data-cy={'plannedOutputs' + index + 'uom'}>
                                    {row.uomSel != null ? row.uomSel.label : ''}
                                </FormControl>
                            </Grid>
                            {fieldAccess && !released &&
                                <Grid item xs={1}>
                                    <IconButton aria-label="delete planned" disabled={!fieldAccess} onClick={() => this.removePlannedOutput(index)} data-cy={"deleteOutput" + index}>
                                        <RemoveCircleTwoToneIcon  />
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    )}
                    {fieldAccess && !released &&
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={5}>
                                <AutoCompleteField
                                    value={this.state.plannedItemSel}
                                    options={this.state.caseLotItems}
                                    onChange={this.changeAuto('plannedItemSel', 'plannedItem')}
                                    error={errors[t('plannedItem')] != null ? true : false}
                                    helperText={errors[t('plannedItem')]}
                                    disabled={!fieldAccess}
                                    required
                                    data-cy="plannedItem"
                                />
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <TextField
                                    value={this.state.plannedQuantity}
                                    type="number"
                                    name="plannedQuantity"
                                    onChange={this.changeValue}
                                    error={errors[t('plannedQuantity')] != null ? true : false}
                                    helperText={errors[t('plannedQuantity')]}
                                    disabled={!fieldAccess}
                                    required
                                    data-cy="plannedQuantity"
                                />
                            </Grid>
                            <Grid item xs={1} sm={1}>
                                <FormControl data-cy="plannedUOM">
                                    {this.state.plannedUOMSel != null ? this.state.plannedUOMSel.label : ''}
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton aria-label="add planned" disabled={!fieldAccess} onClick={() => this.addPlannedOutput()} data-cy={"addPlanned"}>
                                    <AddBoxTwoToneIcon  />
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                    {released && this.state.lines != null && this.state.lines.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('lines')} />
                            {this.state.lines.map((row, i) =>
                                <Aux key={'line' + i}>
                                    <br/>
                                    <SubSectionHeading title={t('output') + ' ' + (i + 1)} />
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} sm={4}>
                                            <FormControl>
                                                <FormLabel>{t('item')}</FormLabel>
                                                {row.itemSel != null ? <Link to={'/item/' + row.item}>{row.itemSel.label}</Link> : ''}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('routing')}</FormLabel>
                                                {row.routingSel != null ? <Link to={'/routing/' + row.routing}>{row.routingSel.label}</Link> : ''}
                                            </FormControl>

                                        </Grid>
                                        <Grid item xs={4} sm={4}>
                                            <FormControl>
                                                <FormLabel>{t('productionBom')}</FormLabel>
                                                {row.bomSel != null ? <Link to={'/bom/' + row.bom}>{row.bomSel.label}</Link> : ''}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <br/>
                                    <SubSectionHeading title={t('inputs')} />
                                    <Grid container spacing={3} key={'inputs' + i}>
                                        <Grid item xs={4} sm={3}>
                                            <FormLabel>{t('item')}</FormLabel>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <FormLabel>{t('quantity')}</FormLabel>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <FormLabel>{t('estimate')}</FormLabel>
                                        </Grid>
                                        <Grid item xs={4} sm={3}>
                                            <FormLabel>{t('container')}</FormLabel>
                                        </Grid>
                                    </Grid>
                                    {row.inputs.map((input, j) =>
                                        <Grid container spacing={3} key={'input' + i + 'a' + j}>
                                           <Grid item xs={4} sm={3}>
                                                <Link to={'/item/' + input.item}>{input.itemSel.label}</Link>
                                           </Grid>
                                           <Grid item xs={2} sm={1} align="right">
                                                {fieldAccess &&
                                                     <TextField
                                                        value={input.quantity}
                                                        type="number"
                                                        onChange={this.changeLineValue('quantity', i, j, 'inputs')}
                                                        error={errors[`${t('inputQuantity')} (${t('line')} ${i}, ${j})`] != null ? true : false}
                                                        helperText={errors[`${t('inputQuantity')} (${t('line')} ${i}, ${j})`]}
                                                        disabled={!fieldAccess}
                                                        required
                                                        data-cy={'input' + i + 'a' + j + 'quantity'}
                                                    />
                                                }
                                                {!fieldAccess &&
                                                    <NumericFormat value={input.quantity} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                }
                                            </Grid>
                                            <Grid item xs={2} sm={1} align="right">
                                                <NumericFormat value={input.estimate} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                            </Grid>
                                            <Grid item xs={4} sm={3}>
                                                {fieldAccess && input.containers != null && input.containers.length > 0 &&
                                                    <AutoCompleteField
                                                        value={input.containerSel}
                                                        options={input.containers}
                                                        onChange={this.changeLineAuto('containerSel', 'container', i, j, 'inputs')}
                                                        error={errors[`${t('inputContainer')} (${t('line')} ${i}, ${j})`] != null ? true : false}
                                                        helperText={errors[`${t('inputContainer')} (${t('line')} ${i}, ${j})`]}
                                                        disabled={!fieldAccess}
                                                        required
                                                        data-cy={'inputs' + i + 'a' + j + 'container'}
                                                    />
                                                }
                                                {!fieldAccess && input.containerSel != null &&
                                                    <Link to={'/container/' + input.container}>{input.containerSel.label}</Link>
                                                }
                                            </Grid>
                                            {fieldAccess &&
                                                <Grid item sx={{width: 50}}>
                                                    <IconButton aria-label="add line" onClick={() => this.duplicateRow('inputs', i, j)}>
                                                        <ContentCopyTwoToneIcon  />
                                                    </IconButton>
                                                </Grid>
                                            }
                                            {fieldAccess && input.canDelete &&
                                                <Grid item sx={{width: 50}}>
                                                    <IconButton aria-label="remove line" onClick={() => this.removeRow('inputs', i, j)}>
                                                        <RemoveCircleTwoToneIcon  />
                                                    </IconButton>
                                                </Grid>
                                            }
                                        </Grid>
                                    )}
                                    <br/>
                                    <SubSectionHeading title={t('outputs')} />
                                    <Grid container spacing={3} key={'outputs' + i}>
                                        <Grid item xs={4} sm={3}>
                                            <FormLabel>{t('item')}</FormLabel>
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <FormLabel>{t('quantity')}</FormLabel>
                                        </Grid>
                                        {(row.customLot === true || !fieldAccess) &&
                                            <Grid item xs={3} sm={2}>
                                                <FormLabel>{t('lot')}</FormLabel>
                                            </Grid>
                                        }
                                        {!fieldAccess &&
                                            <Grid item xs={3} sm={2}>
                                                <FormLabel>{t('container')}</FormLabel>
                                            </Grid>
                                        }
                                        <Grid item xs={4} sm={2}>
                                            <FormLabel>{t('bin')}</FormLabel>
                                        </Grid>
                                        <Grid item xs={4} sm={2}><FormLabel>{t('containerType')}</FormLabel></Grid>
                                    </Grid>
                                    {row.outputs.map((output, j) => 
                                        <Grid container spacing={3} key={'output' + i + 'a' + j}>
                                            <Grid item xs={4} sm={3}>
                                                <Link to={'/item/' + output.item}>{output.itemSel.label}</Link>
                                            </Grid>
                                            <Grid item xs={2} sm={1} align="right">
                                                {fieldAccess &&
                                                     <TextField
                                                        value={output.quantity}
                                                        type="number"
                                                        onChange={this.changeLineValue('quantity', i, j, 'outputs')}
                                                        error={errors[`${t('outputQuantity')} (${t('line')} ${i}, ${j})`] != null ? true : false}
                                                        helperText={errors[`${t('outputQuantity')} (${t('line')} ${i}, ${j})`]}
                                                        disabled={!fieldAccess}
                                                        required
                                                        data-cy={'output' + i + 'a' + j + 'quantity'}
                                                    />
                                                }
                                                {!fieldAccess &&
                                                    <NumericFormat value={output.quantity} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                                                }
                                            </Grid>
                                            {row.customLot === true && fieldAccess && 
                                                <Grid item xs={3} sm={2}>
                                                    {output.itemSel != null && output.itemSel.customNumber && fieldAccess &&
                                                        <TextField
                                                            value={output.customLot}
                                                            type="text"
                                                            onChange={this.changeLineValue('customLot', i, j, 'outputs')}
                                                            error={errors[`${t('outputCustomLot')} (${t('line')} ${i}, ${j})`] != null ? true : false}
                                                            helperText={errors[`${t('outputCustomLot')} (${t('line')} ${i}, ${j})`]}
                                                            disabled={!fieldAccess}
                                                            required
                                                            data-cy={'output' + i + 'a' + j + 'customLot'}
                                                        />
                                                    }
                                                </Grid>
                                            }
                                            {!fieldAccess && output.lot != null &&
                                                <Grid item xs={3} sm={2}>
                                                    <Link to={'/lot/' + output.lot._id}>{output.lot.number}</Link>
                                                </Grid>
                                            }
                                            {!fieldAccess &&
                                                <Grid item xs={3} sm={2}>
                                                    {output.container != null &&
                                                        <Link to={'/container/' + output.container._id}>{output.container.number}</Link>
                                                    }
                                                </Grid>
                                            }
                                            <Grid item xs={4} sm={2}>
                                                {fieldAccess && 
                                                    <AutoCompleteField
                                                        value={output.binSel}
                                                        options={this.state.bins}
                                                        onChange={this.changeLineAuto('binSel', 'bin', i, j, 'outputs')}
                                                        error={errors[`${t('outputBin')} (${t('line')} ${i}, ${j})`] != null ? true : false}
                                                        helperText={errors[`${t('outputBin')} (${t('line')} ${i}, ${j})`]}
                                                        disabled={!fieldAccess}
                                                        required
                                                        data-cy={'outputs' + i + 'a' + j + 'bin'}
                                                    />
                                                }
                                                {!fieldAccess && output.binSel != null &&
                                                    <Link to={'/bin/' + output.bin}>{output.binSel.label}</Link>
                                                }
                                            </Grid>
                                            <Grid item xs={4} sm={2}>
                                                {fieldAccess && 
                                                    <AutoCompleteField
                                                        value={output.containerTypeSel}
                                                        options={this.state.containerTypes}
                                                        onChange={this.changeLineAuto('containerTypeSel', 'containerType', i, j, 'outputs')}
                                                        error={errors[`${t('outputContainerType')} (${t('line')} ${i}, ${j})`] != null ? true : false}
                                                        helperText={errors[`${t('outputContainerType')} (${t('line')} ${i}, ${j})`]}
                                                        disabled={!fieldAccess}
                                                        required
                                                        data-cy={'outputs' + i + 'a' + j + 'containerType'}
                                                    />
                                                }
                                                {!fieldAccess && output.containerTypeSel != null &&
                                                    output.containerTypeSel.label
                                                }
                                            </Grid>
                                            {fieldAccess &&
                                                <Grid item sx={{width: 50}}>
                                                    <IconButton aria-label="add line" onClick={() => this.duplicateRow('outputs', i, j)}>
                                                        <ContentCopyTwoToneIcon  />
                                                    </IconButton>
                                                </Grid>
                                            }
                                            {fieldAccess && output.canDelete &&
                                                <Grid item sx={{width: 50}}>
                                                    <IconButton aria-label="remove line" onClick={() => this.removeRow('outputs', i, j)}>
                                                        <RemoveCircleTwoToneIcon  />
                                                    </IconButton>
                                                </Grid>
                                            }
                                        </Grid>
                                    )}
                                </Aux>
                            )}
                        </Aux>
                    }
                    <br/>
                    <FormButtonStack
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.masterCase}
                        callback={this.loadRecord}
                        saveClick={this.save}
                        deleteClick={this.deleteToggle}
                        status={this.state.status}
                        permissions={this.props.permission}
                        common={this.props.auth.common}
                        constants={this.props.auth.constants}
                        releaseClick={this.state._id != null && this.state._id !== '' && !released && fieldAccess ? this.release : null}
                        unreleaseClick={this.state.status === this.props.auth.constants.statuses.released && fieldAccess ? this.unrelease : null}
                        enableUnrelease={fieldAccess}
                        saveEnabled={this.state.enableSave}
                        activityDate={this.state.date}
                        lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                        isSuper={this.props.auth.user.isSuper}
                    />
                    {this.state.deleteOpen &&
                        <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                            redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                    }
                    {this.state.dialogOpen &&
                        <ScheduleDialog
                            id={this.state.scheduleId}
                            fieldAccess={fieldAccess}
                            reload={this.loadRecord}
                            permission={this.state.security}
                            open={this.state.dialogOpen}
                            onClose={() => this.toggleDialog()}
                        />
                    }
                </TabPanel>
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.masterCase}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(MasterCase));