import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class AvailableInventoryList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            orderBy: 'number',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            security: {}
        };
    }

    componentDidMount(){
        let storage = sessionStorage.getItem('availableInventoryFilters');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        this.setState({
            security: this.props.permission,
            offset: storage != null ? storage.offset : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'number',
            ordDir: storage != null ? storage.ordDir : -1,
            page: storage != null ? storage.page : 0
        }, () => {
            this.loadRecords();
        
        });
    }

    loadRecords = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let params = {
            location: this.state.location,
            status: this.state.status,
            item: this.state.item,
            sort: JSON.stringify(sort),
            offset: this.state.offset,
            size: this.state.listSize
        };
        let list = await axios.get('/api/availableinventories', {params: params});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        });
        const crumbs = [
            { path: 'active', label: this.props.t('availableInventoryReports'), screen: this.props.auth.screenDefs.Curing}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        this.saveFilters();
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            page: this.state.page
        };
        sessionStorage.setItem('curings', JSON.stringify(data));
    }

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const security = this.props.permission;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/availableInventory'
                        createAccess={security.create}
                    />
                </BreadcrumbBar>
                <Panel>
                    <TableContainer component={Paper}>
                        <Table aria-label="Available Inventories">
                            <TableHead>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('number')}
                                    active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('date')}
                                    active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                    </TableSortLabel>
                                </TableCell>
                            </TableHead>
                            <TableBody>
                                {this.state.list != null && this.state.list.length > 0 && this.state.list.map((row, i) => 
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <Link to={'/availableInventory/' + row._id}>{row.number}</Link>
                                        </TableCell>
                                        <TableCell>
                                            <DateDisplay value={row.date} format='yyyy/MM/dd h:mm a'/>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(AvailableInventoryList));