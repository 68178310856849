import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import Unlock from '../General/Unlock';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ActionBar from '../UI/Buttons/ActionBar';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import Panel from '../UI/Panel/Panel';
import Title from '../UI/Typography/Title';
import SectionTitle from '../UI/Typography/SectionTitle';
import CapaAction from './CapaAction';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';

class Ncr extends Component{
    constructor(props){
        super(props);
        this.state = {
            title: '',
            number: '',
            location: '',
            initiator: '',
            initiatedOn: '',
            occurrenceDate: '',
            entityType: '',
            records: [],
            childEntityType: null,
            childRecord: null,
            childRecordSel: null,
            specification: '',
            result: '',
            description: '',
            impactExplanation: '',
            impactAssessmentUser: '',
            impactAssessmentDate: '',
            remarks: '',
            qaDisagreeReason: '',
            signer: null,
            status: '',
            finalDocument: null,
            id: "",
            qas: [],
            users: [],
            entities: [],
            entitiesSelected: [],
            entityTypes: [],
            locations: [],
            sigOpen: false,
            signed: false,
            schedule: null,
            tenant: this.props.auth.user.tenant,
            documents: [],
            department: '',
            departments: [],
            locationSelected: null,
            depSel: null,
            initiatorSelected: null,
            entityTypeSelected: null,
            impactUserSelected: null,
            batchActivities: [],
            batchActivitySel: null,
            statusSel: null,
            statuses: [],
            qaAgree: null,
            poActivities: [],
            poActSel: null,
            tabKey: 0,

            security: [],
            dialogOpen: false,
            enableSave: true,
            form: '',
            hasForm: false,
            goBack: -1,
            capaActions: [],
            offset: 0,
            listSize: 10,
            page: 0,
            orderBy: 'number',
            ordDir: 1,
            totalCount: 0,
            capaId: '',
            capaIndex: -1,
            editCapa: false,
            optionsMenu: [],
            flagForSignature: 0
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location, common.cacheValues.object, 
            common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        let params = queryString.parse(this.props.location.search);
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        let goBack = -1;
        if(scheduleId != null)
            goBack = -2;
        const ebatch = params != null && params.ebatch != null ? params.ebatch : null;
        const batchActivity = params != null && params.batchActivity != null ? params.batchActivity : null;
        const productionOrder = params != null && params.productionOrder != null ? params.productionOrder : null;
        const poActivity = params != null && params.poActivity != null ? params.poActivity : null;
        let query = {};
        if(ebatch)
            query.ebatch = ebatch;
        if(productionOrder)
            query.productionOrder = productionOrder;
        let values = await axios.get('/api/ncrvalues', {params: query});
        let entityType = '';
        let entityTypeSelected = null;
        let childEntityType = null;
        let childRecordSel = null;
        let entitiesSelected = [];
        let selectedEntityIds = [];
        let childRecord = null;
        if(ebatch){
            entityType = this.props.auth.constants.objectIds.evoraBatch;
            entityTypeSelected = cacheValues.objects.find(x => x.value === this.props.auth.constants.objectIds.evoraBatch);
            childRecordSel = values.data.activities.find(x => x.value === batchActivity);
            childRecord = batchActivity;
            childEntityType = this.props.auth.constants.objectIds.batchActivity
            selectedEntityIds.push(ebatch);
            let batchSel = values.data.ebatches.find(x => x.value === ebatch);
            entitiesSelected.push(batchSel);
        }
        if(productionOrder && poActivity){
            entityType = this.props.auth.constants.objectIds.productionOrder;
            entityTypeSelected = cacheValues.objects.find(x => x.value === this.props.auth.constants.objectIds.productionOrder);
            childRecordSel = values.data.poActivities.find(x => x.value === poActivity);
            childRecord = poActivity;
            childEntityType = this.props.auth.constants.objectIds.poActivity;
            selectedEntityIds.push(productionOrder);
            let poSel = values.data.productionOrders.find(x => x.value === productionOrder);
            entitiesSelected.push(poSel);
        }
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
            this.props.auth.user.defaultLocation : '';
        let locSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locSel == null && locations.length === 1){
            locSel = locations[0];
            location = locSel.value;
        }
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        let hasForm = values.data.form != null && values.data.form !== '' ? true : false;
        this.setState({
            qas: values.data.qas,
            users: values.data.users,
            managers: values.data.managers,
            entityTypes: cacheValues.objects,
            locations: locations,
            locationSelected: locSel,
            location: location,
            departments: values.data.departments,
            schedule: scheduleId,
            batchActivities: values.data.activities != null ? values.data.activities : [],
            poActivities: values.data.poActivities != null ? values.data.poActivities : [],
            entities : values.data.ebatches != null ? values.data.ebatches : [],
            entityType: entityType,
            entityTypeSelected: entityTypeSelected,
            childRecordSel: childRecordSel,
            childRecord: childRecord,
            childEntityType: childEntityType,
            entitiesSelected: entitiesSelected,
            records: selectedEntityIds,
            security: this.props.permission,
            statuses: cacheValues.statuses,
            statusSel: statusSel,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && values.data.form != null && values.data.form !== '' ? JSON.parse(values.data.form) : '',
            hasForm: hasForm,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null,
            goBack: goBack
        }, async () => {
            if(this.props.match.params.id != null){
                this.loadRecord();
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        let ncr = await axios.get('/api/ncr/' + this.state._id)
        let entityIds = ncr.data.records;;
        let selectedFull = [];
        let allEnts = [];
        let signed = ncr.data.status != null && ncr.data.status === this.props.auth.constants.statuses.signed;
        let locationSelected = this.state.locations.find(x => x.value === ncr.data.location);
        let initiatorSelected = this.state.users.find(x => x.value === ncr.data.initiator);
        let entityTypeSelected = this.state.entityTypes.find(x => x.value === ncr.data.entityType);
        let impactUserSelected = this.state.users.find(x => x.value === ncr.data.impactAssessmentUser);
        let qaSignoffSelected = this.state.qas.find(x => x.value === ncr.data.qaSignoffUser);
        let depSel = this.state.departments.find(x => x.value === ncr.data.department);
        let batchActivitySel = ncr.data.batchActivity != null ? ncr.data.batchActivities.find(x => x.value === ncr.data.batchActivity) : null;
        let statusSel = this.state.statuses.find(x => x.value === ncr.data.status);
        let hasForm = this.state.hasForm;
        if(!hasForm && ncr.data.form != null && ncr.data.form !== '')
            hasForm = true;
        let childRecordSel = null;
        let childEntityType = ncr.data.childEntityType;
        if(childEntityType != null){
            if(childEntityType === this.props.auth.constants.objectIds.batchActivity){
                childRecordSel = ncr.data.batchActivities.find(x => x.value === ncr.data.childRecord);
            } else if (childEntityType === this.props.auth.constants.objectIds.poActivity){
                childRecordSel = ncr.data.poActivities.find(x => x.value === ncr.data.childRecord);
            }
        }
        if(childEntityType == null){
            childEntityType = ncr.data.entityType === this.props.auth.constants.objectIds.evoraBatch ? 
                this.props.auth.constants.batchActivity : ncr.data.entityType === this.props.auth.constants.objectIds.productionOrder ?
                this.props.auth.constants.objectIds.poActivity : null;
        }
        const optionsMenu = [
            {
                security: this.state.security.approve && !signed, user: this.props.auth.user.id, users: this.state.qas,
                name: t('approve'), title: t('approve'), setId: () => this.loadRecord(), object: ncr.data._id, signature: ncr.data.signer,
                objectType: this.props.auth.constants.objectIds.ncr, type: this.props.auth.constants.signatureTypes.signer, validate: () => this.validateComplete(4),
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = ncr.data.status === this.props.auth.constants.statuses.inprogress && ncr.data.signer != null ? 1 : 0;
        this.setState({
            title: ncr.data.title,
            number: ncr.data.number,
            location: ncr.data.location,
            initiator: ncr.data.initiator,
            initiatedOn: ncr.data.initiatedOn,
            occurrenceDate: ncr.data.occurrenceDate,
            department: ncr.data.department,
            entityType: ncr.data.entityType,
            childEntityType: childEntityType,
            childRecord: ncr.data.childRecord,
            childRecordSel: childRecordSel,
            specification: ncr.data.specification,
            result: ncr.data.result,
            description: ncr.data.description,
            impactExplanation: ncr.data.impactExplanation,
            impactAssessmentUser: ncr.data.impactAssessmentUser,
            impactAssessmentDate: ncr.data.impactAssessmentDate,
            remarks: ncr.data.remarks,
            qaDisagreeReason: ncr.data.qaDisagreeReason,
            signer: ncr.data.signer,
            finalDocument: ncr.data.finalDocument,
            signed: signed,
            locationSelected: locationSelected,
            initiatorSelected: initiatorSelected,
            entityTypeSelected: entityTypeSelected,
            impactUserSelected: impactUserSelected,
            qaSignoffSelected: qaSignoffSelected,
            documents: ncr.data.documents,
            depSel: depSel,
            qaAgree: ncr.data.qaAgree ? 'yes' : !ncr.data.qaAgree ? 'no' : null,
            batchActivitySel: batchActivitySel,
            batchActivities: ncr.data.batchActivities,
            poActivities: ncr.data.poActivities,
            status: ncr.data.status,
            statusSel: statusSel,
            form: ncr.data.form != null ? JSON.parse(ncr.data.form) : null,
            hasForm: hasForm,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature
        }, () => {
            const crumbs = [
                { path: '/#/ncrs', label: this.props.t('ncrsTitle'), screen: this.props.auth.screenDefs.NcrList},
                { path: 'active', label: this.props.t('ncrTitle') + ' - ' + ncr.data.number, screen: this.props.auth.screenDefs.NonConformanceReport}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            this.loadCapas();
        });
        if(ncr.data.entityType != null && ncr.data.entityType !== ''){
            let ents = await axios.get('/api/entitylist/' + ncr.data.entityType);
            allEnts = ents.data;
            if(entityIds.length > 0){
                for(let row of entityIds){
                    let val = ents.data.find(x => x.value === row);
                    if(val != null){
                        selectedFull.push(val);
                    }
                }
            }
            this.setState({
                entities: allEnts,
                entitiesSelected: selectedFull,
                records: entityIds
            });
        }
    }

    loadCapas = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: this.props.auth.constants.objectIds.ncr, value: this.state._id},
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let capaRes = await axios.get('/api/capas', {params: params});
        this.setState({
            capaActions: capaRes.data.data,
            totalCount: capaRes.data.totalCount
        });
    }

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    saveNcr = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveNcr());
    }

    _saveNcr = async () => {
        const t = this.props.t;
        const valid = this.validateNcr();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let url = '/api/ncr';
        let ncr = {
            _id: this.state._id,
            title: this.state.title,
            number: this.state.number,
            location: this.state.location,
            initiator: this.state.initiator,
            initiatedOn: this.state.initiatedOn,
            occurrenceDate: this.state.occurrenceDate,
            department: this.state.department,
            entityType: this.state.entityType,
            records: this.state.records,
            childEntityType: this.state.childEntityType,
            childRecord: this.state.childRecord,
            specification: this.state.specification,
            result: this.state.result,
            description: this.state.description,
            impactExplanation: this.state.impactExplanation,
            impactAssessmentUser: this.state.impactAssessmentUser,
            impactAssessmentDate: this.state.impactAssessmentDate,
            remarks: this.state.remarks,
            qaDisagreeReason: this.state.qaDisagreeReason,
            status: this.state.status
        };
        ncr.qaAgree = this.state.qaAgree === 'yes' ? true : this.state.qaAgree === 'no' ? false : null;
        ncr.schedule = this.state.schedule;
        ncr.form = form != null && form !== '' ? JSON.stringify(form) : null;
        if(this.state._id != null)
            url = '/api/ncr/' + this.state._id;
        try{
            let result = await axios.post(url, ncr);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/ncr/' + result.data.id);
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });         
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });        
        }
    };

    validateNcr(final){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.title == null || state.title === '')
            errors[t('title')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.initiator == null || state.initiator === '')
            errors[t('initiator')] = t('required');
        if(state.initiatedOn == null || state.initiatedOn === '')
            errors[t('initiatedOn')] = t('required');
        if(state.occurrenceDate == null || state.occurrenceDate === '')
            errors[t('occurrenceDate')] = t('required');
        if(state.specification == null || state.specification === '')
            errors[t('specification')] = t('required');
        if(state.result == null || state.result === "")
            errors[t('result')] = t('required');
        if(state.description == null || state.description === "")
            errors[t('description')] = t('required');
        if(state.department == null || state.department === '')
            errors[t('department')] = t('required');
        if(final === true){
            if(state.impactExplanation == null || state.impactExplanation === '')
                errors[t('impactExplanation')] = t('required');
            if(state.impactAssessmentUser == null || state.impactAssessmentUser === '')
                errors[t('impactAssessmentUser')] = t('required');
            if(state.impactAssessmentDate == null || state.impactAssessmentDate === '')
                errors[t('impactAssessmentDate')] = t('required');
            if(this.state.qaAgree === 'no' && (state.qaDisagreeReason == null || state.qaDisagreeReason === ''))
                errors[t('qaDisagreeReason')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    validateComplete(){
        let valid = this.validateNcr(true);
        if(!valid){
            return this.props.t('errors');
        }
        return null;
    }

    changeValue = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        this.setState({[name]: value});
    }

    qaAgreeAssessmentChanged = (e) => {
        this.setState({qaAgree: e.target.value});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [data]: newValue != null ? newValue.value : null,
            [name]: newValue
        });
        if(data === 'entityType'){
            this.changeEntityType(newValue != null ? newValue.value : null);
        }
    };

    changeEntityType(value){
        const t = this.props.t;
        if(value !== ''){
            axios.get('/api/entitylist/' + value)
            .then(list => {
                this.setState({
                    entities: list.data,
                    entitiesSelected: [],
                    records: [],
                    batchActivitySel: null
                })
            })
            .catch(err => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            })
        } else {
            this.setState({
                entities: [],
                entitiesSelected: [],
                records: []
            })
        }
    }

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    }

    entitiesChanged = async (e, newValue) => {
        var selectedEntities = [];
        if(newValue != null){
            newValue.forEach(entity => {
                selectedEntities.push(entity.value);
            })
        }
        let activities = this.state.batchActivities;
        if(this.state.entityType === this.props.auth.constants.objectIds.evoraBatch){
            let acts = await axios.get('/api/batchactivityselect/' + selectedEntities);
            activities = acts.data;
        }
        this.setState({
            records: selectedEntities,
            entitiesSelected: newValue,
            batchActivities: activities
        });
    }

    addAction = (e) => {
        this.setState({editCapa: true})
    };

    closeDialog = (e) => {
        this.setState({
            editCapa: false,
            capaId: '',
            capaIndex: -1
        },() => {this.loadCapas();});
    };

    editCapa = (i) => (e) => {
        let capa = this.state.capaActions[i];
        this.setState({capaId: capa._id, editCapa: true, capaIndex: i});
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const signed = this.state.signed;
        const errors = this.props.errors;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        flagForSignature={this.state.flagForSignature}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.ncr}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Title title={t('ncrTitle') + this.state.number != null ?  '' + this.state.number : ''}/>
                    <Grid container spacing={3}>
                        {this.state._id != null && this.state._id !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            <FormControl>
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSel != null ? this.state.statusSel.label : ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {!signed &&
                                <TextField value={this.state.title} onChange={this.changeValue} name="title"
                                size="medium" fullWidth={true} disabled={signed} label={t('title')} required
                                error={errors[t('title')] != null ? true : false} helperText={errors[t('title')]}/>
                            }
                            {signed &&
                                <FormControl>
                                    <FormLabel>{t('title')}</FormLabel>
                                    {this.state.title}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!signed &&
                                <AutoCompleteField
                                    value={this.state.locationSelected}
                                    options={this.state.locations}
                                    onChange={this.changeAuto('locationSelected', 'location')}
                                    error={errors[t('location')] != null ? true : false}
                                    helperText={errors[t('location')]}
                                    label={t('location')}
                                    disabled={signed}
                                    required
                                />
                            }
                            {signed &&
                                <FormControl>
                                    <FormLabel>{t('location')}</FormLabel>
                                    {this.state.locationSelected != null ? this.state.locationSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!signed &&
                                <AutoCompleteField
                                    value={this.state.depSel}
                                    options={this.state.departments}
                                    onChange={this.changeAuto('depSel', 'department')}
                                    error={errors[t('department')] != null ? true : false}
                                    helperText={errors[t('department')]}
                                    label={t('department')}
                                    disabled={signed}
                                    required
                                />
                            }
                            {signed &&
                                <FormControl>
                                    <FormLabel>{t('department')}</FormLabel>
                                    {this.state.depSel != null ? this.state.depSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!signed &&
                                <AutoCompleteField
                                    value={this.state.initiatorSelected}
                                    options={this.state.users}
                                    onChange={this.changeAuto('initiatorSelected', 'initiator')}
                                    error={errors[t('initiator')] != null ? true : false}
                                    helperText={errors[t('initiator')]}
                                    label={t('initiatedBy')}
                                    disabled={signed}
                                    required
                                />
                            }
                            {signed &&
                                <FormControl>
                                    <FormLabel>{t('initiatedBy')}</FormLabel>
                                    {this.state.initiatorSelected != null ? this.state.initiatorSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!signed &&
                                <DateSelect
                                    onChange={this.changeDate('initiatedOn')}
                                    value={this.state.initiatedOn}
                                    helperText={errors[t('initiatedOn')]}
                                    error={errors[t('initiatedOn')] != null ? true : false}
                                    label={t('dateInitiated')}
                                    disabled={signed}
                                    format={this.props.auth.user.dateFormat}
                                    required
                                />
                            }
                            {signed &&
                                <FormControl>
                                    <FormLabel>{t('dateInitiated')}</FormLabel>
                                    <DateDisplay value={this.state.initiatedOn} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!signed &&
                                <DateSelect
                                    onChange={this.changeDate('occurrenceDate')}
                                    value={this.state.occurrenceDate}
                                    helperText={errors[t('occurrenceDate')]}
                                    error={errors[t('occurrenceDate')] != null ? true : false}
                                    label={t('occurrenceDate')}
                                    disabled={signed}
                                    format={this.props.auth.user.dateFormat}
                                    required
                                />
                            }
                            {signed &&
                                <FormControl>
                                    <FormLabel>{t('occurrenceDate')}</FormLabel>
                                    <DateDisplay value={this.state.occurrenceDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!signed &&
                                <AutoCompleteField
                                    value={this.state.entityTypeSelected}
                                    options={this.state.entityTypes}
                                    onChange={this.changeAuto('entityTypeSelected', 'entityType')}
                                    error={errors[t('entityType')] != null ? true : false}
                                    helperText={errors[t('entityType')]}
                                    label={t('relatedTo')}
                                    disabled={signed}
                                />
                            }
                            {signed &&
                                <FormControl>
                                    <FormLabel>{t('relatedTo')}</FormLabel>
                                    {this.state.entityTypeSelected != null ? this.state.entityTypeSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <AutoCompleteField
                                multiple={true}
                                value={this.state.entitiesSelected}
                                options={this.state.entities}
                                onChange={this.entitiesChanged}
                                error={errors[t('entitiesSelected')] != null ? true : false}
                                helperText={errors[t('entitiesSelected')]}
                                label={t('affected')}
                                disabled={signed}
                            />
                        </Grid>
                        {this.state.batchActivities != null && this.state.batchActivities.length > 0 &&
                            <Grid item xs={6} sm={3}>
                                {!signed &&
                                    <AutoCompleteField
                                        value={this.state.childRecordSel}
                                        options={this.state.batchActivities}
                                        onChange={this.changeAuto('childRecordSel', 'childRecord')}
                                        error={errors[t('batchActivity')] != null ? true : false}
                                        helperText={errors[t('batchActivity')]}
                                        label={t('batchActivity')}
                                        disabled={signed}
                                    />
                                }
                                {signed &&
                                    <FormControl>
                                        <FormLabel>{t('batchActivity')}</FormLabel>
                                        {this.state.childRecordSel != null ? this.state.childRecordSel.label : ''}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {this.state.poActivities != null && this.state.poActivities.length > 0 &&
                            <Grid item xs={6} sm={3}>
                                {!signed &&
                                    <AutoCompleteField
                                        value={this.state.childRecordSel}
                                        options={this.state.poActivities}
                                        onChange={this.changeAuto('childRecordSel', 'childRecord')}
                                        error={errors[t('poActivity')] != null ? true : false}
                                        helperText={errors[t('poActivity')]}
                                        label={t('activity')}
                                        disabled={signed}
                                    />
                                }
                                {signed &&
                                    <FormControl>
                                        <FormLabel>{t('activity')}</FormLabel>
                                        {this.state.childRecordSel != null ? this.state.childRecordSel.label : ''}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            {!signed &&
                                <TextField value={this.state.specification} onChange={this.changeValue} name="specification"
                                size="medium" fullWidth={true} disabled={signed} label={t('specification')} required
                                error={errors[t('specification')] != null ? true : false} helperText={errors[t('specification')]}/>
                            }
                            {signed &&
                                <FormControl>
                                    <FormLabel>{t('specification')}</FormLabel>
                                    {this.state.specification}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!signed &&
                                <TextField value={this.state.result} onChange={this.changeValue} name="result"
                                size="medium" fullWidth={true} disabled={signed} label={t('result')} required
                                error={errors[t('result')] != null ? true : false} helperText={errors[t('result')]}/>
                            }
                            {signed &&
                                <FormControl>
                                    <FormLabel>{t('result')}</FormLabel>
                                    {this.state.result}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item sm={11}>
                            {!signed &&
                                <TextField value={this.state.description} onChange={this.changeValue} name="description"
                                multiline={true} rows="3"
                                variant="outlined" size="medium" fullWidth={true} disabled={signed}
                                label={t('description')} required
                                error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                            }
                            {signed &&
                                <FormControl>
                                    <FormLabel>{t('description')}</FormLabel>
                                    {this.state.description}
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    {this.state.hasForm === true &&
                        <Aux>
                            <br/>
                            {errors[t('form')] != null && errors[t('form')] !== '' &&
                                <div style={{color: 'red'}}>{errors[t('form')]}</div>
                            }
                            <Form renderer={renderer}
                                defaultFields={this.state.form}
                                onChange={this.formValueChanged}
                                disabled={signed}/>
                        </Aux>
                    }
                    <br/>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <SectionTitle title={t('impactAssessment')}/>
                            <Grid container spacing={3}>
                                <Grid item sm={11}>
                                    {!signed &&
                                        <TextField value={this.state.impactExplanation} onChange={this.changeValue} name="impactExplanation"
                                        multiline={true} rows="3"
                                        variant="outlined" size="medium" fullWidth={true} disabled={signed}
                                        label={t('impact')} required
                                        error={errors[t('impactExplanation')] != null ? true : false} helperText={errors[t('impactExplanation')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('impact')}</FormLabel>
                                            {this.state.impactExplanation}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!signed &&
                                        <AutoCompleteField
                                            value={this.state.impactUserSelected}
                                            options={this.state.qas}
                                            onChange={this.changeAuto('impactUserSelected', 'impactAssessmentUser')}
                                            error={errors[t('impactAssessmentUser')] != null ? true : false}
                                            helperText={errors[t('impactAssessmentUser')]}
                                            label={t('assessedBy')}
                                            disabled={signed}
                                            required
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('assessedBy')}</FormLabel>
                                            {this.state.impactUserSelected != null ? this.state.impactUserSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!signed &&
                                        <DateSelect
                                            onChange={this.changeDate('impactAssessmentDate')}
                                            value={this.state.impactAssessmentDate}
                                            helperText={errors[t('impactAssessmentDate')]}
                                            error={errors[t('impactAssessmentDate')] != null ? true : false}
                                            label={t('date')}
                                            disabled={signed}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('date')}</FormLabel>
                                            <DateDisplay value={this.state.impactAssessmentDate} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            <br/>
                            <SectionTitle title={t('correctiveMeasures')}/>
                            <TableContainer component={Paper}>
                                <Table aria-label="capaActions">
                                    <TableHead>
                                        <TableRow key="capaHeader">
                                            <TableCell>{t('number')}</TableCell>
                                            <TableCell>{t('title')}</TableCell>
                                            <TableCell>{t('dueDate')}</TableCell>
                                            <TableCell>{t('status')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.capaActions.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell>
                                                    <TextOnlyClickButton enabled={true} click={this.editCapa(i)} name={row.number}/>
                                                </TableCell>
                                                <TableCell>{row.title}</TableCell>
                                                <TableCell><DateDisplay value={row.dueDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                                <TableCell>{row.status.label}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow key="tableFooter">
                                            <TablePagination count={this.state.totalCount}
                                            rowsPerPage={this.state.listSize}
                                            onPageChange={this.handleChangePage}
                                            page = {this.state.page}
                                            rowsPerPageOptions={common.rowOptions}
                                            onRowsPerPageChange={this.handleRowsPerPage}/>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                            {!signed &&
                                <Grid container spacing={3}>
                                    <Grid item sm={8}>
                                        <Button variant="outlined" color="primary" size="small" disabled={signed} onClick={this.addAction}>{t('add')}</Button>
                                    </Grid>
                                </Grid>
                            }
                            {this.state.editCapa &&
                                <CapaAction
                                    reload={this.loadRecords}
                                    open={this.state.editCapa}
                                    onClose={this.closeDialog}
                                    ncr={this.state._id}
                                    id={this.state.capaId}
                                    users={this.state.users}
                                    qas={this.state.qas}
                                    managers={this.state.managers}
                                />
                            }
                            <br/>
                            <SectionTitle title={t('qaReview')}/>
                            <Grid container spacing={3}>
                                <Grid item sm={11}>
                                    {!signed &&
                                        <TextField value={this.state.remarks} onChange={this.changeValue} name="remarks"
                                        multiline={true} rows="3"
                                        variant="outlined" size="medium" fullWidth={true} disabled={signed}
                                        label={t('concludingRemarks')}
                                        error={errors[t('remarks')] != null ? true : false} helperText={errors[t('remarks')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('concludingRemarks')}</FormLabel>
                                            {this.state.remarks}
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{t('qaAgree')}</FormLabel>
                                            <RadioGroup aria-label="Agree with Impact Assessment" name="qaAgree" value={this.state.qaAgree} 
                                            onChange={this.qaAgreeAssessmentChanged} row>
                                                <FormControlLabel value="yes" control={<Radio color='secondary' disabled={signed}/>} label={t('yes')} />
                                                <FormControlLabel value="no" control={<Radio color='secondary' disabled={signed}/>} label={t('no')} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                {this.state.qaAgree != null && this.state.qaAgree === 'no'  &&
                                    <Grid item sm={11}>
                                        {!signed &&
                                            <TextField value={this.state.qaDisagreeReason} onChange={this.changeValue} name="qaDisagreeReason"
                                            multiline={true} rows="3"
                                            variant="outlined" size="medium" fullWidth={true} disabled={signed}
                                            label={t('whyNot')} required
                                            error={errors[t('qaDisagreeReason')] != null ? true : false} helperText={errors[t('qaDisagreeReason')]}/>
                                        }
                                        {signed &&
                                            <FormControl>
                                                <FormLabel>{t('whyNot')}</FormLabel>
                                                {this.state.qaDisagreeReason}
                                            </FormControl>
                                        }
                                    </Grid>
                                }
                            </Grid>
                            <br/>
                        </Aux>
                    }
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            {!signed &&
                                <Aux>
                                    <Button onClick={this.saveNcr} color="primary" size="small" variant="contained" disabled={signed && !this.state.enableSave}>{t('save')}</Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {signed &&
                                <Aux>
                                    <Button variant="contained" size="small" href={'/#/ncrprint/' + this.state._id} color="secondary">
                                        {t('print')}
                                    </Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {signed &&
                                <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.ncr} callback={this.refresh}/>
                            }
                        </Grid>
                    </Grid>
                </Panel>
                {this.state.dialogOpen &&
                    <ScheduleDialog 
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security} 
                        open={this.state.dialogOpen} 
                        onClose={() => this.toggleDialog()}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.ncr}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Ncr));