import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '../UI/TextField/TextField';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import Box from '@mui/material/Box';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import SectionTitle from '../UI/Typography/SectionTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link } from 'react-router-dom';
import { fieldSecurity } from '../../helpers/securityHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { withTranslation } from 'react-i18next';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import IconButton from '@mui/material/IconButton';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';

class ProductionBom extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            description: '',
            uom: '',
            uomSel: null,
            foreignId: '',
            lines: [{}],
            locked: false,
            items: [],
            types: [],
            uoms: [],
            boms: [],
            outputs: [],
            fieldAccess: false,
            security: [],
            showCopy: false,
            newName: '',
            renderNum: Math.random(),
            isNew: false,
            enableSave: true,
            outputItem: '',
            outputItemSel: null,
            outputQuantity: '',
            outputUOM: '',
            outputUOMSel: null,
            usedItems: [],
            deleteOpen: false,
            lotRatings: []
        }
    }

    async componentDidMount(){
        const fieldAccess = fieldSecurity(this.props.permission, null, this.props.auth.common, this.props.auth.constants);
        let isNew = (this.props.match.params.id == null || this.props.match.params.id === '') && this.props.permission.create;
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.item, common.cacheValues.quantityUOM, common.cacheValues.lotRating,
                                        common.cacheValues.bom, common.cacheValues.bomLineType], this.props.auth.constants, null, this.props.auth.user.tenant);
        let items = cacheValues.items;
        this.setState({
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            types: cacheValues.bomLineTypes,
            items: items,
            uoms: cacheValues.quantityUOMs,
            boms: cacheValues.boms,
            fieldAccess: fieldAccess,
            security: this.props.permission,
            isNew: isNew,
            locked: isNew ? false : true,
            lotRatings: cacheValues.lotRatings
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            } else {
                const crumbs = [
                    { path: '/#/boms', label: this.props.t('productionBoms'), screen: this.props.auth.screenDefs.ProductionBom},
                    { path: 'active', label: this.props.t('productionBom'), screen: this.props.auth.screenDefs.ProductionBom}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });

    }

    loadRecord = async () => {
        let record = await axios.get('/api/bom/' + this.state._id);
        let uomSel = this.state.uoms.find(x => x.value === record.data.uom);
        let lines = [];
        record.data.lines.forEach(line => {
            let typeSel = this.state.types.find(x => x.value === line.type);
            let itemSel = line.item != null ? this.state.items.find(x => x.value === line.item) : null;
            let bomSel = line.productionBom != null ? this.state.boms.find(x => x.value === line.productionBom) : null;
            let quantityUOMSel = itemSel != null ? this.state.uoms.find(x => x.value === itemSel.uom) : null;
            let ratingsSel = [];
            if(line.ratings != null && line.ratings.length > 0){
                for(let row of line.ratings){
                    let rating = this.state.lotRatings.find(x => x.value === row);
                    ratingsSel.push(rating);
                };
            }
            lines.push({
                number: line.number,
                type: line.type,
                typeSel: typeSel,
                item: line.item,
                itemSel: itemSel,
                bomSel: bomSel,
                productionBom: line.productionBom,
                quantity: line.quantity,
                uom: line.uom,
                quantityUOMSel: quantityUOMSel,
                scrapPercent: line.scrapPercent,
                exclude: line.exclude === true ? true : false,
                preMade: line.preMade === true ? true : false,
                ratings: line.ratings != null ? line.ratings : [],
                ratingsSel: ratingsSel
            })
        });
        let outputs = record.data.outputs;
        if(outputs != null && outputs.length > 0){
            outputs.forEach(row => {
                row.itemSel = this.state.items.find(x => x.value === row.item);
                row.uomSel = row.itemSel != null ? this.state.uoms.find(x => x.value === row.itemSel.uom) : null;
                row.uom = row.itemSel != null ? row.itemSel.uom : null;
            });
        } else {
            outputs = [];
        }
        const fieldAccess = fieldSecurity(this.props.permission, null, this.props.auth.common, this.props.auth.constants);
        this.setState({
            _id: record.data._id,
            number: record.data.number,
            description: record.data.description,
            uom: record.data.uom,
            uomSel: uomSel,
            foreignId: record.data.foreignId,
            lines: lines,
            outputs: outputs,
            documents: record.data.documents,
            fieldAccess: fieldAccess,
            usedItems: record.data.items,
            renderNum: Math.random(),
            deleteOpen: false
        }, () => {
            const crumbs = [
                { path: '/#/boms', label: this.props.t('productionBoms'), screen: this.props.auth.screenDefs.ProductionBom},
                { path: 'active', label: this.props.t('productionBom') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.ProductionBom}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    changeLineValue = (row) => (e) => {
        var name = e.target.name;
        var value = e.target.value
        var lines = this.state.lines;
        var line = lines[row];
        line[name] = value;
        this.setState({lines: lines});
    };

    changeOutputValue = (row) => (e) => {
        var name = e.target.name;
        var value = e.target.value
        var lines = this.state.outputs;
        var line = lines[row];
        line[name] = value;
        this.setState({outputs: lines});
    };

    sortLines = (e) => {
        let lines = this.state.lines;
        lines.sort(function(a, b) {return a.number - b.number});
        this.setState({lines: lines});
    };

    changeNumber = (row) => (e) => {
        var lines = this.state.lines;
        var line = lines[row];
        var name = e.target.name;
        var value = e.target.value
        if(isNaN(value)){
            return;
        }
        line[name] = value;
        this.setState({lines: lines});
    };

    changeAuto = (name, data) => (e, newValue) => {
        let outputUOM = this.state.outputUOM;
        let outputUOMSel = this.state.outputUOMSel;
        if(data === 'outputItem'){
            if(newValue != null){
                outputUOM = newValue.uom;
                outputUOMSel = this.state.uoms.find(x => x.value === newValue.uom);
            } else {
                outputUOM = '';
                outputUOMSel = null
            }
        }
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            outputUOM: outputUOM,
            outputUOMSel: outputUOMSel
        });
    };

    changeLineAuto = (name, data, row) => (e, newValue) => {
        let lines = this.state.lines;
        let line = lines[row];
        line[name] = newValue;
        line[data] = newValue != null ? newValue.value : null;
        if(data === 'item'){
            if(newValue != null){
                line.quantityUOMSel = this.state.uoms.find(x => x.value === newValue.uom);
                line.uom = newValue.uom;
            }else {
                line.quantityUOMSel = null;
                line.uom = '';
            }
        }
        if(data === 'type'){
            if(newValue != null && newValue.value === this.props.auth.constants.bomLineTypes.item){
                line.productionBom = null;
                line.bomSel = null;
            } else {
                line.item = null;
                line.itemSel = null;
                line.quantity = null
                line.exclude = false;
                line.preMade = false;
                line.uom = null;
                line.uomSel = null;
                line.scrapPercent = null
            }
        }
        this.setState({lines: lines});
    };

    changeLineMulti = (name, data, row) => (e, newValue) => {
        let lines = this.state.lines;
        let line = lines[row];
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        line[name] = newValue;
        line[data] = ids;
        this.setState({lines: lines});
    }

    changeLineExclude = (row) => (e) => {
        let lines = this.state.lines;
        let line = lines[row];
        line.exclude = line.exclude === true ? false : true;
        this.setState({lines: lines});
    };

    changeLinePreMade = (row) => (e) => {
        let lines = this.state.lines;
        let line = lines[row];
        line.preMade = line.preMade === true ? false : true;
        this.setState({lines: lines});
    };

    changeOutputAuto = (name, data, row) => (e, newValue) => {
        let outputs = this.state.outputs;
        let line = outputs[row];
        line[name] = newValue;
        line[data] = newValue != null ? newValue.value : null;
        if(data === 'item'){
            if(newValue != null){
                line.uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                line.uom = newValue.uom;
            }else {
                line.uomSel = null;
                line.uom = '';
            }
        }
        this.setState({outputs: outputs});
    };

    unlock = e => {
        this.setState({locked: !this.state.locked})
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            _id: this.state._id,
            number: this.state.number,
            description: this.state.description,
            foreignId: this.state.foreignId,
            lines: this.state.lines,
            uom: this.state.uom,
            outputs: this.state.outputs
        };
        try {
            let result = await axios.post('/api/bom', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/bom/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.description == null || state.description === '' )
            errors[t('description')] = t('required');
        for(let i = 0; i < state.lines.length; i++){
            let line = state.lines[i];
            if(line.number == null || line.number === '')
                errors[`${t('number')} (${t('line')} ${i})`] = t('required');
            if(line.type == null || line.type === ''){
                errors[`${t('type')} (${t('line')} ${i})`] = t('required');
            } else {
                if(line.type === this.props.auth.constants.bomLineTypes.item){
                    if(line.item == null || line.item === '')
                        errors[`${t('item')} (${t('line')} ${i})`] = t('required');
                    if(line.quantity == null || line.quantity === '')
                        errors[`${t('quantity')} (${t('line')} ${i})`] = t('required');
                    if(line.uom == null || line.uom === '')
                        errors[`${t('uom')} (${t('line')} ${i})`] = t('required');
                } else if (line.type === this.props.auth.constants.bomLineTypes.bom){
                    if(line.productionBom == null || line.productionBom === '')
                        errors[`${t('productionBOM')} (${t('line')} ${i})`] = t('required');
                }
            }
        }
        for(let i = 0; i < state.outputs.length; i++){
            let row = state.outputs[0];
            if(row.item == null || row.item === '')
                errors[`${t('item')} (${t('line')} ${i})`] = t('required');
        }
        if((state.outputItem != null && state.outputItem !== '') || (state.outputQuantity != null && state.outputQuantity !== ''))
            errors[t('outputItem')] = t('rowNotAdded');
        if(this.props.auth.user.manualItemNumber === true && this.state._id != null && this.state._id !== ''){
            if(this.state.number == null || this.state.number === '')
                errors[t('number')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    addLine = (e) => {
        let lines = this.state.lines;
        lines.push({});
        this.setState({lines: lines});
    };

    deleteLine = (index) => (e) => {
        let lines = this.state.lines;
        lines.splice(index, 1);
        this.setState({lines: lines});
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteRecord = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteRecord(e));
    };

    _deleteRecord = async (e) => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/bom', {params: {id: this.state._id}});
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.setState({enableSave: true, deleteOpen: false});
            this.props.history.goBack();
        }
    };

    copyClick = (e) => {
        this.setState({showCopy: true});
    };

    performCopy = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._performCopy(e));
    }

    _performCopy = async (e) => {
        const t = this.props.t;
        if(this.state.newName == null || this.state.newName === ''){
            this.setState({errors: {newName: t('required')}});
            return;
        }
        let data = {id: this.state._id, name: this.state.newName};
        try {
            let result = await axios.post('/api/bomcopy', data);
            this.setState({enableSave: true});
            this.closeCopy();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    closeCopy = (e) => {
        this.setState({showCopy: false, newName: ''})
    };

    addOutput = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.outputItem == null || this.state.outputItem === '')
            errors[t('outputItem')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let outputs = this.state.outputs;
        outputs.push({
            item: this.state.outputItem,
            itemSel: this.state.outputItemSel,
            quantity: this.state.outputQuantity,
            uom: this.state.outputUOM,
            uomSel: this.state.outputUOMSel
        });
        this.setState({
            outputs: outputs,
            outputItem: '',
            outputItemSel: null,
            outputQuantity: '',
            outputUOM: '',
            outputUOMSel: null
        })
    };

    deleteOutput = (i) => (e) => {
        let outputs = this.state.outputs;
        if(i != null && i >= 0 && i < this.state.outputs.length){
            outputs.splice(i, 1);
        }
        this.setState({outputs: outputs});
    };

    render(){
        const locked = this.state.locked;
        const errors = this.props.errors;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar 
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.productionBom}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        {((locked && this.state.number != null && this.state.number !== '') ||
                            (!locked && this.state.number != null && this.state.number !== '' && this.props.auth.user.manualItemNumber === false)) &&
                            <Grid item xs={6} sm={3}>
                                <FormControl data-cy="number">
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        {!locked && this.props.auth.user.manualItemNumber === true &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.number} onChange={this.changeValue} name="number"
                                size="medium" fullWidth={true} disabled={locked} label={t('number')} required data-cy="number"
                                error={errors[t('number')] != null ? true : false} helperText={errors[t('number')]}/>
                            </Grid>
                        }
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <TextField value={this.state.description} onChange={this.changeValue} name="description"
                                size="medium" fullWidth={true} disabled={locked} label={t('description')} required data-cy="description"
                                error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('description')}</FormLabel>
                                    {this.state.description}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <TextField value={this.state.foreignId} onChange={this.changeValue} name="foreignId"
                                size="medium" fullWidth={true} disabled={locked} label={t('erpId')} data-cy="foreignId"
                                error={errors[t('foreignId')] != null ? true : false} helperText={errors[t('foreignId')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('erpId')}</FormLabel>
                                    {this.state.foreignId}
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title={t('inputs')}/>
                    {this.state.lines.map((row, i) =>
                        <Box display="flex" borderColor="primary.main" {...defaultProps} key={'lines-'+i}>
                            <Grid container spacing={3} key={row._id}>
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <TextField value={row.number} onChange={this.changeLineValue(i)} name="number"
                                        size="medium" fullWidth={true} disabled={locked} label={t('number')} required
                                        error={errors[`${t('number')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('number')} (${t('line')} ${i})`]}
                                        data-cy={"number" + i}/>
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {row.number}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!locked &&
                                        <AutoCompleteField
                                            value={row.typeSel}
                                            options={this.state.types}
                                            onChange={this.changeLineAuto('typeSel', 'type', i)}
                                            error={errors[`${t('type')} (${t('line')} ${i})`] != null ? true : false}
                                            helperText={errors[`${t('type')} (${t('line')} ${i})`]}
                                            label={t('type')}
                                            disabled={locked}
                                            required
                                            data-cy={"type" + i}
                                        />
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('type')}</FormLabel>
                                            {row.typeSel != null ? row.typeSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                {row.type === this.props.auth.constants.bomLineTypes.item &&
                                    <Aux>
                                        <Grid item xs={8} sm={4}>
                                            {locked &&
                                                <FormControl>
                                                    <FormLabel>{t('item')}</FormLabel>
                                                    {row.itemSel != null &&
                                                        <Link to={'/item/' + row.item}>{row.itemSel.label}</Link>
                                                    }
                                                </FormControl>
                                            }
                                            {!locked &&
                                                <AutoCompleteField
                                                    value={row.itemSel}
                                                    options={this.state.items}
                                                    onChange={this.changeLineAuto('itemSel', 'item', i)}
                                                    error={errors[`${t('item')} (${t('line')} ${i})`] != null ? true : false}
                                                    helperText={errors[`${t('item')} (${t('line')} ${i})`]}
                                                    label={t('item')}
                                                    disabled={locked}
                                                    required
                                                    data-cy={"item" + i}
                                                />
                                            }
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <AutoCompleteField
                                                multiple={true}
                                                value={row.ratingsSel}
                                                options={this.state.lotRatings}
                                                onChange={this.changeLineMulti('ratingsSel', 'ratings', i)}
                                                error={errors[`${t('ratings')} (${t('line')} ${i})`] != null ? true : false}
                                                helperText={errors[`${t('ratings')} (${t('line')} ${i})`]}
                                                label={t('ratings')}
                                                disabled={locked}
                                                data-cy={"ratings" + i}
                                            />
                                        </Grid>
                                    </Aux>
                                }
                                {row.type === this.props.auth.constants.bomLineTypes.productionBom &&
                                    <Grid item xs={6} sm={3}>
                                        {locked &&
                                            <FormControl>
                                                <FormLabel>{t('productionBom')}</FormLabel>
                                                {row.bomSel != null &&
                                                    <Link to={'/bom/' + row.productionBom}>{row.bomSel.label}</Link>
                                                }
                                            </FormControl>
                                        }
                                        {!locked &&
                                            <AutoCompleteField
                                                value={row.bomSel}
                                                options={this.state.boms}
                                                onChange={this.changeLineAuto('bomSel', 'productionBom', i)}
                                                error={errors[`${t('productionBOM')} (${t('line')} ${i})`] != null ? true : false}
                                                helperText={errors[`${t('productionBOM')} (${t('line')} ${i})`]}
                                                label={t('productionBom')}
                                                disabled={locked}
                                                required
                                                data-cy={"productionBom" + i}
                                            />
                                        }
                                    </Grid>
                                }
                                {row.type === this.props.auth.constants.bomLineTypes.item &&
                                    <Aux>
                                        <Grid item xs={4} sm={2}>
                                            <CheckboxField
                                                checked={row.exclude} onChange={this.changeLineExclude(i)}
                                                label={t('exclude')} disabled={locked}
                                                error={errors[`${t('exclude')} (${t('line')} ${i})`] != null ? true : false}
                                                helperText={errors[`${t('exclude')} (${t('line')} ${i})`]}
                                                data-cy={"exclude" + i}
                                            />
                                        </Grid>
                                        {row.itemSel != null && (row.itemSel.preMade === true || (!locked && row.itemSel.produced === true)) &&
                                            <Grid item xs={4} sm={2}>
                                                <CheckboxField
                                                    checked={row.preMade} onChange={this.changeLinePreMade(i)}
                                                    label={t('preMade')} disabled={locked}
                                                    error={errors[`${t('preMade')} (${t('line')} ${i})`] != null ? true : false}
                                                    helperText={errors[`${t('preMade')} (${t('line')} ${i})`]}
                                                    data-cy={"preMade" + i}
                                                />
                                            </Grid>
                                        }
                                        {locked && row.preMade === true &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('preMade')}</FormLabel>
                                                    {row.preMade === true ? t('yes') : t('no')}
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item xs={4} sm={2}>
                                            {!locked &&
                                                <TextField type="number" value={row.quantity} onChange={this.changeNumber(i)} name="quantity"
                                                size="medium" fullWidth={true} disabled={locked} label={t('quantity')} data-cy={"quantity" + i} required
                                                error={errors[`${t('quantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('quantity')} (${t('line')} ${i})`]}/>
                                            }
                                            {locked &&
                                                <FormControl>
                                                    <FormLabel>{t('quantity')}</FormLabel>
                                                    {row.quantity}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={2} sm={1}>
                                            <FormControl>
                                                <FormLabel>UOM</FormLabel>
                                                {row.quantityUOMSel != null ? row.quantityUOMSel.label : ''}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            {!locked &&
                                                <TextField type="number" value={row.scrapPercent} onChange={this.changeNumber(i)} name="scrapPercent"
                                                size="medium" fullWidth={true} disabled={locked} label={t('scrapPercent')} data-cy={"scrapPercent" + i}
                                                error={errors[`${t('scrapPercent')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('scrapPercent')} (${t('line')} ${i})`]}/>
                                            }
                                            {locked &&
                                                <FormControl>
                                                    <FormLabel>{t('scrapPercent')}</FormLabel>
                                                    {row.scrapPercent}
                                                </FormControl>
                                            }
                                        </Grid>
                                    </Aux>
                                }
                                {!locked &&
                                    <Grid item sm={3}>
                                        <Button variant="outlined" color="primary" size="small" onClick={this.deleteLine(i)} data-cy={"deleteLine" + i}>
                                            {t('deleteLine')}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    )}
                    {this.state.usedItems != null && this.state.usedItems.length > 0 &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('items')}/>
                            {this.state.usedItems.map((row, i) =>
                                <Grid container spacing={3} key={'item-' + i} sx={{pb: 2}}>
                                    <Grid item xs={12} sm={8}>
                                        <Link to={'/item/' + row._id}>{row.number + '-' + row.name}</Link>
                                    </Grid>
                                </Grid>
                            )}
                            <br/>
                        </Aux>
                    }
                    {!locked &&
                        <Grid container spacing={3}>
                            <Grid item xm={8}>
                                <Button variant="outlined" color="primary" size="small" onClick={this.addLine} data-cy="addLine">{t('addLine')}</Button>&nbsp;&nbsp;
                                <Button variant="outlined" color="primary" size="small" onClick={this.sortLines} data-cy="sortLines">{t('sortLines')}</Button>
                            </Grid>
                        </Grid>
                    }
                    {((locked && this.state.outputs != null  && this.state.outputs.length > 0) || !locked ) &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('outputs')}/>
                            <Grid container spacing={3}>
                                <Grid item xs={8} sm={4}>{t('item')}</Grid>
                                <Grid item xs={4} sm={2}>{t('quantity')}</Grid>
                            </Grid>
                            {this.state.outputs != null && this.state.outputs.length > 0 && this.state.outputs.map((row, i) =>
                                <Grid container spacing={3}>
                                    <Grid item xs={8} sm={4}>
                                        {!locked &&
                                            <AutoCompleteField
                                                value={row.itemSel}
                                                options={this.state.items}
                                                onChange={this.changeOutputAuto('itemSel', 'item', i)}
                                                error={errors[`${t('item')} (${t('line')} ${i})`] != null ? true : false}
                                                helperText={errors[`${t('item')} (${t('line')} ${i})`]}
                                                disabled={locked}
                                                required
                                                data-cy={"outputItem" + i}
                                            />
                                        }
                                        {locked &&
                                             <Link to={'/item/' + row.item}>{row.itemSel.label}</Link>
                                        }
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        {!locked &&
                                            <TextField value={row.quantity} onChange={this.changeOutputValue(i)} name="quantity"
                                            size="medium" fullWidth={true} disabled={locked} data-cy={"outputQuantity" + i}
                                            error={errors[`${t('outputQuantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('outputQuantity')} (${t('line')} ${i})`]}/>
                                        }
                                        {locked &&
                                            <Aux>
                                                {row.uomSel != null ? row.quantity + row.uomSel.label :  row.quantity}
                                            </Aux>
                                        }
                                    </Grid>
                                    {!locked &&
                                        <Grid item xs={2} sm={1}>
                                            {row.uomSel != null ? row.uomSel.label : ''}
                                        </Grid>
                                    }
                                    {!locked &&
                                        <Grid item sx={{width: 50}}>
                                            <IconButton aria-label="delete output" onClick={this.deleteOutput(i)} data-cy={"deleteOutput" + i}>
                                                <RemoveCircleTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    }
                                </Grid>
                            )}
                        </Aux>
                    }
                    {!locked &&
                        <Grid container spacing={3}>
                            <Grid item xs={8} sm={4}>
                                <AutoCompleteField
                                    value={this.state.outputItemSel}
                                    options={this.state.items}
                                    onChange={this.changeAuto('outputItemSel', 'outputItem')}
                                    error={errors[t('outputItem')] != null ? true : false}
                                    helperText={errors[t('outputItem')]}
                                    disabled={locked}
                                    required
                                    data-cy="outputItem"
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.outputQuantity} onChange={this.changeValue} name="outputQuantity"
                                size="medium" fullWidth={true} disabled={locked} data-cy="outputQuantity"
                                error={errors[t('outputQuantity')] != null ? true : false} helperText={errors[t('outputQuantity')]}/>
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                {this.state.outputUOMSel != null ? this.state.outputUOMSel.label : ''}
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <IconButton aria-label="add input" onClick={this.addOutput} data-cy="addOutput">
                                    <AddBoxTwoToneIcon  />
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                    <Grid container spacing={3}>
                        <Grid item sm={4}>
                            {!locked && this.state.security.update &&
                                <Aux>
                                    <Button disabled={!this.state.enableSave} variant="contained" color="primary" size="small" onClick={this.save} data-cy="save">
                                        {t('save')}
                                    </Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {this.props.permission.unlock &&
                                <Button variant="contained" color="secondary" size="small" onClick={this.unlock} data-cy="unlock">
                                    {locked ? t('unlock') : t('lock')}
                                </Button>
                            }&nbsp;&nbsp;
                            {this.state.security.delete &&
                                <Aux>
                                    <Button variant="contained" color="secondary" size="small" onClick={this.deleteToggle} data-cy="delete">
                                        {t('delete')}
                                    </Button>
                                    &nbsp;&nbsp;
                                </Aux>
                            }
                            {this.state.security.update && locked && this.state._id != null && this.state._id !== '' &&
                                <Button variant="contained" color="secondary" size="small" onClick={this.copyClick} data-cy="copy">{t('copy')}</Button>
                            }
                        </Grid>
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.productionBom}/>
                            <ObjectLog id={this.state._id} renderNum={this.state.renderNum}/>
                        </Aux>
                    }
                </Panel>
                <Dialog open={this.state.showCopy} onClose={this.closeCopy} center maxWidth="sm" fullWidth>
                    <DialogTitle>
                        <SectionTitle title={t('copy')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item sm={10}>
                                <TextField value={this.state.newName} onChange={this.changeValue} name="newName"
                                size="medium" fullWidth={true} label={t('newDescription')} data-cy="newDescription"
                                error={errors[t('newName')] != null ? true : false} helperText={errors[t('newName')]}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={this.performCopy} data-cy="copyDialog">{t('copy')}</Button>
                        <Button variant="contained" color="secondary" onClick={this.closeCopy} data-cy="closeDialog">{t('close')}</Button>
                    </DialogActions>
                </Dialog>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteRecord()} open={this.state.deleteOpen} />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ProductionBom));
