import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { registerUser } from '../actions/authentication';

class Register extends Component {
    constructor(){
        super();

        this.state = {
            userid: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            errors: {}
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(e){
        e.preventDefault();
        const user = {
            userid: this.state.userid,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            password: this.state.password
        }
        this.props.registerUser(user, this.props.history);
    }

    componentDidUpdate(nextProps) {
        if(nextProps.auth.isAuthenticated) {
            this.props.history.push('/')
        }
        if(nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    componentDidMount() {
        if(this.props.auth.isAuthenticated) {
            this.props.history.push('/');
        }
    }

    render() {
        const { errors } = this.state;
        return(
            <div className="container" sx={{ marginTop: '50px', width: '700px'}}>
                <h2 sx={{ marginBotton: '40px'}}>Registration</h2>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <input type="text" placeholder="userid" 
                            name="userid"
                            onChange={this.handleInputChange} value={this.state.userid}/>
                        {errors.userid && (<div className="invalid-feedback">{errors.userid}</div>)}
                    </div>
                    <div className="form-group">
                        <input type="text" placeholder="first name" 
                            name="firstName" 
                            onChange={this.handleInputChange} value={this.state.firstName}/>
                        {errors.firstName && (<div className="invalid-feedback">{errors.firstName}</div>)}
                    </div>
                    <div className="form-group">
                        <input type="text" placeholder="last name" 
                            name="lastName"
                            onChange={this.handleInputChange} value={this.state.lastName}/>
                        {errors.lastName && (<div className="invalid-feedback">{errors.lastName}</div>)}
                    </div>
                    <div className="form-group">
                        <input type="email" placeholder="email" 
                            name="email"
                            onChange={this.handleInputChange} value={this.state.email}/>
                        {errors.email && (<div className="invalid-feedback">{errors.email}</div>)}
                    </div>
                    <div className="form-group">
                        <input type="password" placeholder="password"
                            name="password"
                            onChange={this.handleInputChange} value={this.state.password}/>
                        {errors.password && (<div className="invalid-feedback">{errors.password}</div>)}
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary">
                            Register User
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

Register.propTypes = {
    registerUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(mapStateToProps,{ registerUser })(withRouter(Register))