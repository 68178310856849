import React from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import Aux from '../../../../hoc/Auxilary/Auxilary';
import LogoFile from './EVT-100-EvoraTech_Logotype_TealLime_RGB.png';

export default function Logo(props) {
  const { alt, height, maxHeight, noTenant, width } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const env = useSelector(state => state.auth.user.env);
  const profile = useSelector(state => state.profile);
  const tenant = profile.profile != null ? profile.profile.tenantName : '';
  const tenantLogo = profile.profile != null && profile.profile.logo != null ? profile.profile.logo : null;
  const thumb = tenantLogo != null ? 
     "data:application/octet-stream;base64," + tenantLogo : null;
  return(
    <Aux>
      <img src={LogoFile} alt={alt} height={height} width={width}/>{!isMobile && !noTenant && env != null && env !== '' ? 
        <Aux>{tenantLogo != null ?
          <img src={thumb} alt={tenant} style={{ maxHeight: maxHeight }}/> : 
          <span style={{color: '#00AC89',
                       display: 'inline-block',
                       fontFamily: 'Josefin Sans',
                       fontSize: '45px',
                       lineHeight: '30px',
                       paddingTop: '6px'}}>{tenant}</span>}&nbsp;&nbsp;
          <span style={{color: '#FF0000',
                        display: 'inline-block',
                        fontFamily: 'Josefin Sans',
                        fontSize: '45px',
                        lineHeight: '30px',
                        paddingTop: '6px'}}>{env}</span></Aux> : 
        !isMobile && !noTenant ? tenantLogo != null ?
                    <img src={thumb} alt={tenant} style={{ maxHeight: maxHeight }} /> : 
                    <span style={{color: '#00AC89',
                                  display: 'inline-block',
                                  fontFamily: 'Josefin Sans',
                                  fontSize: '45px',
                                  lineHeight: '30px',
                                  paddingTop: '6px'}}>{tenant}</span> : ''}
    </Aux>
  );
}