import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import DateSelect from '../UI/DateSelect/DateSelect';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { FormLabel } from '@mui/material';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { approveSigSecurity, completeSigSecurity,
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-router-dom';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import Panel from '../UI/Panel/Panel';
import SectionTitle from '../UI/Typography/SectionTitle';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import CapaAction from '../Investigations/CapaAction';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { TextOnlyClickButton } from '../UI/Buttons/Buttons';
import Button from '@mui/material/Button';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';

class CustomerComplaint extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            status: '',
            statusSel: {
                value: '',
                label: '',
            },
            statuses: [],
            personName: '',
            orderNumber: '',
            requestorName: '',
            requestorPhone: '',
            requestorEmail: '',
            items: [],
            date: new Date(),
            purchaseDate: '',
            products: [],
            types: [],
            typesSel: [],
            typeList: [],
            subTypes: [],
            subTypesSel: [],
            subTypeList: [],
            details: '',
            resolution: '',
            assignee: '',
            assigneeSel: null,
            customer: '',
            customerSel: null,
            customers: [],
            users: [],
            managers: [],
            approvers: [],
            documents: [],
            user: null,
            reviewer: null,
            signer: null,
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            optionsMenu: [],
            security: [],
            item: '',
            itemSel: null,
            lot: '',
            lotSel: null,
            lots: [],
            quantity: '',
            renderNum: Math.random(),
            enableDelete: false,
            dateOfReaction: '',
            age: '',
            gender: '',
            genderSel: null,
            genders: [],
            height: '',
            weight: '',
            dose: '',
            frequency: '',
            dateOfUse: '',
            duration: '',
            durationUOM: '',
            durationUOMSel: null,
            durationUOMs: [],
            useDescription: '',
            abateAfterStop: false,
            reappearAfterRestart: false,
            otherInformation: '',
            serious: false,
            seriousReactions: [],
            seriousReactionsSel: [],
            seriousReactionList: [],
            adverseDescription: '',
            testInformation: '',
            historyInformation: '',
            regulatoryReportDate: '',
            adverseReactions: false,
            capaActions: [],
            editCapa: false,
            page: 0,
            orderBy: 'number',
            ordDir: 1,
            totalCount: 0,
            capaId: '',
            capaIndex: -1,
            listSize: 5,
            enableSave: true,
            form: '',
            hasForm: false,
            deleteOpen: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.allItems, common.cacheValues.customer, common.cacheValues.status, common.cacheValues.status,
                            common.cacheValues.quantityUOM, common.cacheValues.timeUOM, common.cacheValues.customerComplaintType, common.cacheValues.gender,
                            common.cacheValues.customerComplaintForm, common.cacheValues.seriousReaction], this.props.auth.constants, null, this.props.auth.user.tenant);
        let items = cacheValues.allItems;
        let customers = cacheValues.customers;
        const userValues = await ValueHelper.screenUsers(common.screens.customerComplaint, this.props.auth.user.tenant);
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        this.setState({
            statuses: cacheValues.statuses,
            items: items,
            customers: customers,
            typeList: cacheValues.customerComplaintTypes,
            users: userValues.create,
            managers: userValues.review,
            approvers: userValues.approve,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            seriousReactionList: cacheValues.seriousReactions,
            durationUOMs: cacheValues.timeUOMs,
            genders: cacheValues.genders,
            uoms: cacheValues.quantityUOMs,
            fieldAccess: fieldAccess,
            security: this.props.permission,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/customercomplaints', label: this.props.t('customerComplaints'), screen: this.props.auth.screenDefs.CustomerComplaints},
                    { path: 'active', label: this.props.t('customerComplaint'), screen: this.props.auth.screenDefs.CustomerComplaint}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        let record = await axios.get('/api/customercomplaint/' + this.state._id);
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let typesSel = [];
        if(record.data.types != null && record.data.types.length > 0){
            record.data.types.forEach(row => {
                let typeSel = this.state.typeList.find(x => x.value === row);
                typesSel.push(typeSel);
            });
        }
        let subTypesSel = [];
        if(record.data.subTypes != null && record.data.subTypes.length > 0){
            record.data.subTypes.forEach(row => {
                let val = record.data.subTypeList.find(x => x.value === row);
                subTypesSel.push(val);
            });
        }
        let seriousReactionsSel = [];
        if(record.data.seriousReactions != null && record.data.seriousReactions.length > 0){
            record.data.seriousReactions.forEach(row => {
                let val = this.state.seriousReactionList.find(x => x.value === row);
                seriousReactionsSel.push(val);
            });
        }
        let customerSel = this.state.customers.find(x => x.value === record.data.customer);
        let assigneeSel = record.data.assignee != null ? this.state.users.find(x => x.value === record.data.assignee) : null;
        let products = record.data.products;
        for(let row of products){
            row.itemSel = this.state.items.find(x => x.value === row.item);
            row.lotSel = row.lots.find(x => x.value === row.lot);
        }
        let genderSel = record.data.gender != null ? this.state.genders.find(x => x.value === record.data.gender) : null;
        let durationUOMSel = record.data.durationUOM != null ? this.state.durationUOMs.find(x => x.value === record.data.durationUOM) : null;
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, record.data.status, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, record.data.status, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, record.data.status, this.props.auth.common, this.props.auth.constants);
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: this.state.users, name: t('complete'), object: record.data._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user,
                objectType: this.props.auth.constants.objectIds.customerComplaint, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature,
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: this.state.managers, name: t('review'),
                title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.customerComplaint, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: this.state.approvers, name: t('approve'),
                title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer,
                objectType: this.props.auth.constants.objectIds.customerComplaint, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        let enableDelete = this.props.permission.delete && (record.data.status === this.props.auth.constants.statuses.inprogress ||
                            record.data.status === this.props.auth.constants.statuses.new);
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        this.setState({
            number: record.data.number,
            status: record.data.status,
            statusSel: statusSel,
            requestorName: record.data.requestorName,
            requestorPhone: record.data.requestorPhone,
            requestorEmail: record.data.requestorEmail,
            personName: record.data.personName,
            orderNumber: record.data.orderNumber,
            purchaseDate: record.data.purchaseDate,
            date: record.data.date,
            products: products,
            types: record.data.types,
            typesSel: typesSel,
            subTypes: record.data.subTypes,
            subTypesSel: subTypesSel,
            subTypeList: record.data.subTypeList,
            regulatoryReportDate: record.data.regulatoryReportDate,
            details: record.data.details,
            resolution: record.data.resolution,
            assignee: record.data.assignee,
            assigneeSel: assigneeSel,
            customer: record.data.customer,
            customerSel: customerSel,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            signer: record.data.signer,
            documents: record.data.documents,
            fieldAccess: fieldAccess,
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            renderNum: Math.random(),
            enableDelete: enableDelete,
            seriousReactions: record.data.seriousReactions,
            seriousReactionsSel: seriousReactionsSel,
            dateOfReaction: '',
            age: record.data.age,
            gender: record.data.gender,
            genderSel: genderSel,
            height: record.data.height,
            weight: record.data.weight,
            dose: record.data.dose,
            frequency: record.data.frequency,
            dateOfUse: record.data.dateOfUse,
            duration: record.data.duration,
            durationUOM: record.data.durationUOM,
            durationUOMSel: durationUOMSel,
            useDescription: record.data.useDescription,
            abateAfterStop: record.data.abateAfterStop,
            reappearAfterRestart: record.data.reappearAfterRestart,
            otherInformation: record.data.otherInformation,
            serious: record.data.serious,
            adverseDescription: record.data.adverseDescription,
            testInformation: record.data.testInformation,
            historyInformation: record.data.historyInformation,
            adverseReactions: record.data.adverseReactions,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            deleteOpen: false
        }, () => {
            const crumbs = [
                { path: '/#/customercomplaints', label: this.props.t('customerComplaints'), screen: this.props.auth.screenDefs.CustomerComplaints},
                { path: 'active', label: this.props.t('customerComplaint') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.CustomerComplaint}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            this.loadActions();
        });
    };

    loadActions = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: this.props.auth.constants.objectIds.customerCopmlaint, value: this.state._id},
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let enableDelete = this.props.permission.delete && (this.state.status === this.props.auth.constants.statuses.inprogress ||
            this.state.status === this.props.auth.constants.statuses.new);
        let result = await axios.get('/api/capas', {params: params});
        if(result.data.totalCount > 0)
            enableDelete = false;
        this.setState({
            capaActions: result.data.data,
            totalCount: result.data.totalCount,
            enableDelete: enableDelete
        });
    };

    changeValue = e => {
        const name = e.target.name;
        const value = e.target.value
        this.setState({[name]: value});
    };

    changeNumber = (e) => {
        const name = e.target.name;
        const value = e.target.value
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value});
    }

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(data === 'item' && newValue != null){
            let lots = await axios.get('/api/itemlots/' + newValue.value, {params: {includeEmpty: true}});
            this.setState({lots: lots.data});
        }
    };

    changeMulti = (name, data) => async (e, newValue) => {
        var val = newValue;
        var ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({
            [name]: val,
            [data]: ids
        });
        if(data === 'types' && newValue != null){
            let subResult = await axios.get('/api/parentsubvalues', {params: {parentValues: JSON.stringify(ids)}});
            this.setState({subTypeList: subResult.data});
        }
    };

    changeBool = (e) => {
        this.setState({[e.target.name]: !this.state[e.target.name]})
    }

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false}, () => this._save());
    }

    _save = async (e) => {
        let valid = this.validate();
        const t = this.props.t;
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let data = {
            _id: this.state._id,
            number: this.state.number,
            customer: this.state.customer,
            date: this.state.date,
            personName: this.state.personName,
            requestorName: this.state.requestorName,
            requestorPhone: this.state.requestorPhone,
            requestorEmail: this.state.requestorEmail,
            orderNumber: this.state.orderNumber,
            purchaseDate: this.state.purchaseDate,
            products: this.state.products,
            types: this.state.types,
            subTypes: this.state.subTypes,
            details: this.state.details,
            resolution: this.state.resolution,
            regulatoryReportDate: this.state.regulatoryReportDate,
            dateOfReaction: this.state.dateOfReaction,
            age: this.state.age,
            gender: this.state.gender,
            height: this.state.height,
            weight: this.state.weight,
            dose: this.state.dose,
            frequency: this.state.frequency,
            duration: this.state.duration,
            durationUOM: this.state.durationUOM,
            dateOfUse: this.state.dateOfUse,
            useDescription: this.state.useDescription,
            abateAfterStop: this.state.abateAfterStop,
            reappearAfterRestart: this.state.reappearAfterRestart,
            otherInformation: this.state.otherInformation,
            serious: this.state.serious,
            seriousReactions: this.state.seriousReactions,
            adverseDescription: this.state.adverseDescription,
            testInformation: this.state.testInformation,
            historyInformation: this.state.historyInformation,
            assignee: this.state.assignee,
            adverseReactions: this.state.adverseReactions,
            form: form != null && form !== '' ? JSON.stringify(form) : null
        };
        try {
            let result = await axios.post('/api/customercomplaint', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/customercomplaint/' + result.data.id);
            this.setState({_id: result.data.id, number: result.data.number, enableSave: true}, () => {
                this.loadRecord();
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.requestorName == null || state.requestorName === '')
            errors[t('requestorName')] = t('required');
        if(state.types == null || state.types.length === 0)
            errors[t('types')] = t('required');
        if(state.date == null || state.date === '')
            errors[t('date')] = t('required');
        if(state.products == null || state.products.length === 0){
            errors[t('products')] = t('required');
        } else {
            for(let i = 0; i < state.products.length; i++){
                if(state.products[i].item == null || state.products[i].item === '')
                    errors[`${t('item')} (${t('line')} ${i})`] = t('required');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    validateComplete(){
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.resolution == null || state.resolution === '')
            errors[t('resolution')] = t('required');
        if(state.assignee == null || state.assignee === '')
            errors[t('assignee')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return t('notAllComplete');
        }else {
            if(isEmpty(this.props.errors)){
                this.props.dispatch({ type: 'CLEAR_ERROR' });
            }
            return null;
        }
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e));
    }

    _deleteClick = async (e) => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            if(this.state.status === this.props.auth.constants.statuses.new || this.state.status === this.props.auth.constants.statuses.inprogress){
                try {
                    let result = await axios.delete('/api/customercomplaint/' + this.state._id);
                    this.setState({enableSave: true, deleteOpen: false});
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                    this.props.history.goBack()
                } catch(err){
                    this.setState({enableSave: true, deleteOpen: false});
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
                }
            } else {
                this.setState({enableSave: true, deleteOpen: false});
            }
        } else {
            this.props.history.goBack();
        }
    };

    changeInputNumber = (name, index) => (e) => {
        let inputs = this.state.products;
        let input = inputs[index];
        let value = e.target.value;
        if(isNaN(value))
            return;
        input[name] = value;
        this.setState({products: inputs});
    };

    changeInputAuto = (name, data, index) => async (e, newValue) => {
        let inputs = this.state.products;
        let input = inputs[index];
        input[name] = newValue;
        input[data] = newValue != null ? newValue.value : null;
        if(newValue != null && data === 'item'){
            let lots = await axios.get('/api/itemlots/' + newValue.value, {params: {includeEmpty: true}});
            input.lots = lots.data;
            input.lot = null;
            input.lotSel = null;
        }
        this.setState({
            products: inputs
        });
    };

    deleteInput = (index) => (e) => {
        let inputs = this.state.products;
        inputs.splice(index, 1);
        this.setState({products: inputs});
    };

    addInput = (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.item == null || this.state.item === '')
            errors[t('inputItem')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return;
        }
        let inputs = this.state.products;
        inputs.push({
            item: this.state.item,
            itemSel: this.state.itemSel,
            quantity: this.state.quantity,
            lot: this.state.lot,
            lotSel: this.state.lotSel,
            lots: this.state.lots
        });
        this.setState({
            products: inputs,
            item: '',
            itemSel: null,
            quantity: '',
            lot: '',
            lotSel: null,
            lots: []
        });
    };

    addAction = (e) => {
        this.setState({editCapa: true})
    };

    closeDialog = (e) => {
        this.setState({
            editCapa: false,
            capaId: '',
            capaIndex: -1
        },() => {this.loadActions();});
    };

    editCapa = (i) => (e) => {
        let capa = this.state.capaActions[i];
        this.setState({capaId: capa._id, editCapa: true, capaIndex: i});
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadActions();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadActions();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadActions();
        });
    };

    render(){
        const fieldAccess = this.state.fieldAccess;
        const errors = this.props.errors;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        fieldAccess={security.update}
                        constants={this.props.auth.constants}
                        objectType={this.props.auth.constants.objectIds.customerComplaint}
                        id={this.state._id}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        {this.state._id != null && this.state._id !== '' &&
                            <Grid item xs={6} sm={4}>
                                <FormControl data-cy='number'>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={6} sm={4}>
                            <FormControl data-cy='status'>
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSel != null ? this.state.statusSel.label : ''}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <DateSelect 
                                    onChange={this.dateChange('date')}
                                    value={this.state.date}
                                    helperText={errors[t('date')]}
                                    error={errors[t('date')] != null ? true : false}
                                    disabled={this.state.status !== this.props.auth.constants.statuses.new}
                                    label={t('date')}
                                    format={this.props.auth.user.dateFormat}
                                    data-cy='date'
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('date')}</FormLabel>
                                    <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {fieldAccess &&
                                <AutoCompleteField
                                    value={this.state.customerSel}
                                    options={this.state.customers}
                                    onChange={this.changeAuto('customerSel', 'customer')}
                                    error={errors[t('customer')] != null ? true : false}
                                    helperText={errors[t('customer')]} 
                                    label={t('customer')}
                                    disabled={!fieldAccess}
                                    data-cy='customer'
                                />
                            }
                            {!fieldAccess && this.state.customerSel != null &&
                                <FormControl>
                                    <FormLabel>{t('customer')}</FormLabel>
                                    <Link to={'/customer/' + this.state.customerSel.value}>{this.state.customerSel.label}</Link>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <TextField value={this.state.personName} onChange={this.changeValue} name="personName"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('personName')} data-cy='personName'
                                error={errors[t('personName')] != null ? true : false} helperText={errors[t('personName')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('personName')}</FormLabel>
                                    {this.state.personName}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <TextField value={this.state.requestorName} onChange={this.changeValue} name="requestorName"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('requestor')} data-cy='requestor'
                                error={errors[t('requestorName')] != null ? true : false} helperText={errors[t('requestorName')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('requestor')}</FormLabel>
                                    {this.state.requestorName}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <InputMaskField mask="999-999-9999" name="requestorPhone" value={this.state.requestorPhone}
                                onChange={this.changeValue} 
                                helperText={errors[t('requestorPhone')]}
                                error={errors[t('requestorPhone')] != null ? true : false}
                                label={t('phoneNumber')} data-cy='requestorPhone'
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('phoneNumber')}</FormLabel>
                                    {this.state.requestorPhone}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <TextField value={this.state.requestorEmail} onChange={this.changeValue} name="requestorEmail"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('email')} data-cy='requestorEmail'
                                error={errors[t('requestorEmail')] != null ? true : false} helperText={errors[t('requestorEmail')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('email')}</FormLabel>
                                    {this.state.requestorEmail}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {fieldAccess &&
                                <TextField value={this.state.orderNumber} onChange={this.changeValue} name="orderNumber"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('orderNumber')} data-cy='orderNumber'
                                error={errors[t('orderNumber')] != null ? true : false} helperText={errors[t('orderNumber')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('personName')}</FormLabel>
                                    {this.state.personName}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <DateSelect 
                                    onChange={this.dateChange('purchaseDate')}
                                    value={this.state.purchaseDate}
                                    helperText={errors[t('purchaseDate')]}
                                    error={errors[t('purchaseDate')] != null ? true : false}
                                    disabled={this.state.status !== this.props.auth.constants.statuses.new}
                                    label={t('purchaseDate')}
                                    format={this.props.auth.user.dateFormat}
                                    data-cy='purchaseDate'
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('purchaseDate')}</FormLabel>
                                    <DateDisplay value={this.state.purchaseDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={10} sm={6}>
                            <AutoCompleteField
                                multiple={true}
                                stayOpen={true}
                                value={this.state.typesSel}
                                options={this.state.typeList}
                                onChange={this.changeMulti('typesSel', 'types')}
                                error={errors[t('types')] != null ? true : false}
                                helperText={errors[t('types')]} 
                                label={t('types')}
                                disabled={!fieldAccess}
                                data-cy='types'
                            />
                        </Grid>
                        {this.state.subTypeList != null && this.state.subTypeList.length > 0 &&
                            <Grid item xs={10} sm={6}>
                                <AutoCompleteField
                                    multiple={true}
                                    stayOpen={true}
                                    value={this.state.subTypesSel}
                                    options={this.state.subTypeList}
                                    onChange={this.changeMulti('subTypesSel', 'subTypes')}
                                    error={errors[t('subTypes')] != null ? true : false}
                                    helperText={errors[t('subTypes')]} 
                                    label={t('categories')}
                                    disabled={!fieldAccess}
                                    data-cy='subTypes'
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={12}>
                            {fieldAccess &&
                                <TextField value={this.state.details} onChange={this.changeValue} name="details"
                                multiline={true} maxRows="10" data-cy='details'
                                size="medium" fullWidth={true} label={t('details')} 
                                error={errors[t('details')] != null ? true : false} helperText={errors[t('details')]}/>
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('details')}</FormLabel>
                                    {this.state.details}
                                </FormControl>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            {fieldAccess &&
                                <DateSelect 
                                    onChange={this.dateChange('regulatoryReportDate')}
                                    value={this.state.regulatoryReportDate}
                                    helperText={errors[t('regulatoryReportDate')]}
                                    error={errors[t('regulatoryReportDate')] != null ? true : false}
                                    disabled={this.state.status !== this.props.auth.constants.statuses.new}
                                    label={t('reportedOn')}
                                    format={this.props.auth.user.dateFormat}
                                    data-cy='reportedOn'
                                />
                            }
                            {!fieldAccess &&
                                <FormControl>
                                    <FormLabel>{t('purchaseDate')}</FormLabel>
                                    <DateDisplay value={this.state.purchaseDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <CheckboxField
                            checked={this.state.adverseReactions} onChange={this.changeBool}
                            label={t('adverseReactions')} name="adverseReactions"
                            data-cy='adverseReactions'
                            />
                        </Grid>
                    </Grid>
                    {this.state.hasForm === true &&
                        <Aux>
                            <br/>
                            {errors[t('form')] != null && errors[t('form')] !== '' &&
                                <div style={{color: 'red'}}>{errors[t('form')]}</div>
                            }
                            <Form renderer={renderer}
                                defaultFields={this.state.form}
                                onChange={this.formValueChanged}
                                disabled={!fieldAccess}/>
                        </Aux>
                    }
                    <br/>
                    <SectionTitle title={t('products')}/>
                    <Grid container spacing={3} key="prodHeader">
                        <Grid item xs={8} sm={4}><FormLabel>{t('item')}</FormLabel></Grid>
                        <Grid item xs={6} sm={3}><FormLabel>{t('lot')}</FormLabel></Grid>
                        <Grid item xs={2} sm={1}><FormLabel>{t('quantity')}</FormLabel></Grid>
                    </Grid>
                    {this.state.products != null && this.state.products.length > 0 && this.state.products.map((row, i) =>
                        <Grid container spacing={3} key={row._id}>
                            <Grid item xs={8} sm={4}>
                                <AutoCompleteField
                                    value={row.itemSel}
                                    options={this.state.items}
                                    onChange={this.changeInputAuto('itemSel', 'item', i)}
                                    error={errors[`${t('item')} (${t('line')} ${i})`] != null ? true : false}
                                    helperText={errors[`${t('item')} (${t('line')} ${i})`]} 
                                    disabled={!fieldAccess}
                                    data-cy={'item' + i}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={row.lotSel}
                                    options={row.lots}
                                    onChange={this.changeInputAuto('lotSel', 'lot', i)}
                                    error={errors[`${t('lot')} (${t('line')} ${i})`] != null ? true : false}
                                    helperText={errors[`${t('lot')} (${t('line')} ${i})`]} 
                                    disabled={!fieldAccess}
                                    data-cy={'lot' + i}
                                />
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                {fieldAccess &&
                                    <TextField type="number" value={row.quantity} onChange={this.changeInputNumber('quantity', i)} name={"quantity" + i}
                                    size="medium" fullWidth={true} disabled={!fieldAccess} data-cy={'quantity' + i}
                                    error={errors[`${t('quantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('quantity')} (${t('line')} ${i})`]}/>
                                }
                                {!fieldAccess &&
                                    row.quantity
                                }
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <IconButton aria-label="delete input" disabled={!fieldAccess} onClick={this.deleteInput(i)} data-cy={'deleteItem' + i}>
                                    <RemoveCircleTwoToneIcon  />
                                </IconButton>
                            </Grid>
                        </Grid>
                    )}
                    {fieldAccess &&
                        <Grid container spacing={3} key='newProdRow'>
                            <Grid item spacing={8} sm={4}>
                                <AutoCompleteField
                                    value={this.state.itemSel}
                                    options={this.state.items}
                                    onChange={this.changeAuto('itemSel', 'item')}
                                    error={errors[t('item')] != null ? true : false}
                                    helperText={errors[t('item')]} 
                                    disabled={!fieldAccess}
                                    data-cy='item'
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.lotSel}
                                    options={this.state.lots}
                                    onChange={this.changeAuto('lotSel', 'lot')}
                                    error={errors[t('lot')] != null ? true : false}
                                    helperText={errors[t('lot')]} 
                                    disabled={!fieldAccess}
                                    data-cy='lot'
                                />
                            </Grid>
                            <Grid item xs={1} sm={1}>
                                <TextField value={this.state.quantity} onChange={this.changeNumber} name="quantity"
                                size="medium" fullWidth={true} disabled={!fieldAccess} data-cy='quantity' 
                                error={errors[t('quantity')] != null ? true : false} helperText={errors[t('quantity')]}/>
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <IconButton aria-label="add product" disabled={!fieldAccess} onClick={this.addInput} data-cy='addItem'>
                                    <AddBoxTwoToneIcon  />
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                    {this.state.adverseReactions &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('reactionDetails')}/>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <DateSelect
                                            onChange={this.dateChange('dateOfReaction')}
                                            value={this.state.dateOfReaction}
                                            helperText={errors[t('dateOfReaction')]}
                                            error={errors[t('dateOfReaction')] != null ? true : false}
                                            disabled={this.state.status !== this.props.auth.constants.statuses.new}
                                            label={t('dateOfReaction')}
                                            format={this.props.auth.user.dateFormat}
                                            data-cy='dateOfReaction'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('dateOfReaction')}</FormLabel>
                                            <DateDisplay value={this.state.dateOfReaction} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    {fieldAccess &&
                                        <TextField type="number" value={this.state.age} onChange={this.changeNumber} name="age"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('age')} data-cy='age'
                                        error={errors[t('age')] != null ? true : false} helperText={errors[t('age')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('age')}</FormLabel>
                                            {this.state.age}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.genderSel}
                                            options={this.state.genders}
                                            onChange={this.changeAuto('genderSel', 'gender')}
                                            error={errors[t('gender')] != null ? true : false}
                                            helperText={errors[t('gender')]} 
                                            label={t('gender')}
                                            disabled={!fieldAccess}
                                            data-cy='gender'
                                        />
                                    }
                                    {!fieldAccess && this.state.customerSel != null &&
                                        <FormControl>
                                            <FormLabel>{t('gender')}</FormLabel>
                                            {this.state.customerSel.label}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField value={this.state.height} onChange={this.changeValue} name="height"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('height')} data-cy='height'
                                        error={errors[t('height')] != null ? true : false} helperText={errors[t('height')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('height')}</FormLabel>
                                            {this.state.height}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField value={this.state.weight} onChange={this.changeValue} name="weight"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('weight')} data-cy='weight'
                                        error={errors[t('weight')] != null ? true : false} helperText={errors[t('weight')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('weight')}</FormLabel>
                                            {this.state.weight}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField value={this.state.dose} onChange={this.changeValue} name="dose"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('dose')} data-cy='dose'
                                        error={errors[t('dose')] != null ? true : false} helperText={errors[t('dose')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('dose')}</FormLabel>
                                            {this.state.dose}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <TextField value={this.state.frequency} onChange={this.changeValue} name="frequency"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('frequency')} data-cy='frequency'
                                        error={errors[t('frequency')] != null ? true : false} helperText={errors[t('frequency')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('frequency')}</FormLabel>
                                            {this.state.frequency}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <DateSelect
                                            onChange={this.dateChange('dateOfUse')}
                                            value={this.state.dateOfUse}
                                            helperText={errors[t('dateOfUse')]}
                                            error={errors[t('dateOfUse')] != null ? true : false}
                                            disabled={this.state.status !== this.props.auth.constants.statuses.new}
                                            label={t('dateOfUse')}
                                            format={this.props.auth.user.dateFormat}
                                            data-cy='dateOfUse'
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('dateOfUse')}</FormLabel>
                                            <DateDisplay value={this.state.dateOfUse} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    {fieldAccess &&
                                        <TextField type="number" value={this.state.duration} onChange={this.changeNumber} name="duration"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('duration')} data-cy='duration'
                                        error={errors[t('duration')] != null ? true : false} helperText={errors[t('duration')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('duration')}</FormLabel>
                                            {this.state.duration}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.durationUOMSel}
                                            options={this.state.durationUOMs}
                                            onChange={this.changeAuto('durationUOMSel', 'durationUOM')}
                                            error={errors[t('durationUOM')] != null ? true : false}
                                            helperText={errors[t('durationUOM')]} 
                                            label={t('uom')}
                                            disabled={!fieldAccess}
                                            data-cy='durationUOM'
                                        />
                                    }
                                    {!fieldAccess && this.state.durationUOMSel != null &&
                                        <FormControl>
                                            <FormLabel>{t('uom')}</FormLabel>
                                            {this.state.durationUOMSel.label}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <CheckboxField
                                    checked={this.state.abateAfterStop} onChange={this.changeBool}
                                    label={t('abateAfterStop')} name="abateAfterStop"
                                    data-cy='abateAfterStop'
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <CheckboxField data-cy='reappearAfterRestart'
                                    checked={this.state.reappearAfterRestart} onChange={this.changeBool}
                                    label={t('reappearAfterRestart')} name="reappearAfterRestart"
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    {fieldAccess &&
                                        <TextField value={this.state.useDescription} onChange={this.changeValue} name="useDescription"
                                        multiline={true} maxRows="10" data-cy='useDescription'
                                        size="medium" fullWidth={true} label={t('useDescription')} 
                                        error={errors[t('useDescription')] != null ? true : false} helperText={errors[t('useDescription')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('useDescription')}</FormLabel>
                                            {this.state.useDescription}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item sm={12}>
                                    {fieldAccess &&
                                        <TextField value={this.state.otherInformation} onChange={this.changeValue} name="otherInformation"
                                        multiline={true} maxRows="10" data-cy='otherInformation'
                                        size="medium" fullWidth={true} label={t('otherInformation')} 
                                        error={errors[t('otherInformation')] != null ? true : false} helperText={errors[t('otherInformation')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('otherInformation')}</FormLabel>
                                            {this.state.otherInformation}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item sm={12}>
                                    {fieldAccess &&
                                        <TextField value={this.state.adverseDescription} onChange={this.changeValue} name="adverseDescription"
                                        multiline={true} maxRows="10" data-cy='adverseDescription'
                                        size="medium" fullWidth={true} label={t('adverseDescription')} 
                                        error={errors[t('adverseDescription')] != null ? true : false} helperText={errors[t('adverseDescription')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('adverseDescription')}</FormLabel>
                                            {this.state.adverseDescription}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item sm={12}>
                                    {fieldAccess &&
                                        <TextField value={this.state.testInformation} onChange={this.changeValue} name="testInformation"
                                        multiline={true} maxRows="10" data-cy='testInformation'
                                        size="medium" fullWidth={true} label={t('testInformation')} 
                                        error={errors[t('testInformation')] != null ? true : false} helperText={errors[t('testInformation')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('testInformation')}</FormLabel>
                                            {this.state.testInformation}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item sm={12}>
                                    {fieldAccess &&
                                        <TextField value={this.state.historyInformation} onChange={this.changeValue} name="historyInformation"
                                        multiline={true} maxRows="10" data-cy='historyInformation'
                                        size="medium" fullWidth={true} label={t('historyInformation')} 
                                        error={errors[t('historyInformation')] != null ? true : false} helperText={errors[t('historyInformation')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('historyInformation')}</FormLabel>
                                            {this.state.historyInformation}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <CheckboxField data-cy='serious'
                                    checked={this.state.serious} onChange={this.changeBool}
                                    label={t('serious')} name="serious"
                                    />
                                </Grid>
                                {this.state.serious &&
                                    <Grid item xs={10} sm={6}>
                                        <AutoCompleteField
                                            multiple={true}
                                            stayOpen={true}
                                            value={this.state.seriousReactionsSel}
                                            options={this.state.seriousReactionList}
                                            onChange={this.changeMulti('seriousReactionsSel', 'seriousReactions')}
                                            error={errors[t('seriousReactions')] != null ? true : false}
                                            helperText={errors[t('seriousReactions')]} 
                                            label={t('seriousReactions')}
                                            disabled={!fieldAccess}
                                            data-cy='seriousReactions'
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Aux>
                    }
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('resolution')}/>
                            <Grid container spacing={3}>
                                <Grid item xs={8} sm={4}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.assigneeSel}
                                            options={this.state.users}
                                            onChange={this.changeAuto('assigneeSel', 'assignee')}
                                            error={errors[t('assignee')] != null ? true : false}
                                            helperText={errors[t('assignee')]} 
                                            label={t('assignee')}
                                            disabled={!fieldAccess}
                                            data-cy='assignee'
                                        />
                                    }
                                    {!fieldAccess && this.state.assigneeSel != null &&
                                        <FormControl>
                                            <FormLabel>{t('assignee')}</FormLabel>
                                        {this.state.assigneeSel.label}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item sm={12}>
                                    {fieldAccess &&
                                        <TextField value={this.state.resolution} onChange={this.changeValue} name="resolution"
                                        multiline={true} maxRows="10" data-cy='resolution'
                                        size="medium" fullWidth={true} label={t('resolution')} 
                                        error={errors[t('resolution')] != null ? true : false} helperText={errors[t('resolution')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl>
                                            <FormLabel>{t('resolution')}</FormLabel>
                                            {this.state.resolution}
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            <br/>
                            <SectionTitle title={t('correctiveMeasures')}/>
                            <TableContainer component={Paper}>
                                <Table aria-label="capaActions">
                                    <TableHead>
                                        <TableRow key="capaHeader">
                                            <TableCell>{t('number')}</TableCell>
                                            <TableCell>{t('title')}</TableCell>
                                            <TableCell>{t('dueDate')}</TableCell>
                                            <TableCell>{t('status')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.capaActions.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell>
                                                    <TextOnlyClickButton enabled={true} click={this.editCapa(i)} name={row.number}/>
                                                </TableCell>
                                                <TableCell>{row.title}</TableCell>
                                                <TableCell><DateDisplay value={row.dueDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                                <TableCell>{row.status}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow key="tableFooter">
                                            <TablePagination count={this.state.totalCount}
                                            rowsPerPage={this.state.listSize}
                                            onPageChange={this.handleChangePage}
                                            page = {this.state.page}
                                            rowsPerPageOptions={common.rowOptions}
                                            onRowsPerPageChange={this.handleRowsPerPage}/>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={3}>
                                <Grid item sm={8}>
                                    <Button variant="outlined" color="primary" size="small" disabled={!fieldAccess} onClick={this.addAction}>{t('add')}</Button>
                                </Grid>
                            </Grid>
                            {this.state.editCapa &&
                                <CapaAction
                                    reload={this.loadActions}
                                    open={this.state.editCapa}
                                    onClose={this.closeDialog}
                                    customerComplaint={this.state._id}
                                    id={this.state.capaId}
                                    users={this.state.users}
                                    qas={this.state.approvers}
                                    managers={this.state.managers}
                                />
                            }
                        </Aux>
                    }
                    <hr />
                    <FormButtonStack
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.customerComplaint}
                        callback={this.loadRecord}
                        saveClick={this.save}
                        status={this.state.status}
                        permissions={this.props.permission}
                        common={this.props.auth.common}
                        constants={this.props.auth.constants}
                        enableDelete={this.state.enableDelete}
                        deleteClick={this.deleteToggle}
                        type={this.props.auth.common.statusTypes.standard}
                        saveEnabled={this.state.enableSave}
                    />
                    <br/>
                </Panel>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.customerComplaint}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(CustomerComplaint));
