import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import ActionBar from '../UI/Buttons/ActionBar';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import { withTranslation } from 'react-i18next';
import TenantLicenseTable from '../General/TenantLicenseTable';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class TenantUsers extends Component {
    constructor(props){
        super(props);
        this.state = {
            users: [],
            offset: 0,
            listSize: 10,
            pageCount: 1,
            search: '',
            email: '',
            locations: [],
            processes: [],
            departments: [],
            roles: [],
            location: '',
            process: '',
            department: '',
            role: '',
            orderBy: 'lastName',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            security: [],
            active: true,
            firstName: '',
            super: false,
            locked: false
        }
    }

    async componentDidMount(){
        const t = this.props.t;
        const values = await axios.get('/api/uservalues');
        let storage = sessionStorage.getItem('tenantUsers');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        let roles = values.data.roles;
        roles.forEach(row => {
            row.label = t(row.label);
        });
        this.setState({
            locations: values.data.locations,
            processes: values.data.processes,
            departments: values.data.departments,
            roles: roles,
            security: this.props.permission,
            offset: storage != null ? storage.offset : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'lastName',
            ordDir: storage != null ? storage.ordDir : 1,
            search: storage != null ? storage.search : '',
            firstName: storage != null ? storage.firstName : '',
            email: storage != null ? storage.email : '',
            active: storage != null ? storage.active : true,
            locked: storage != null ? storage.locked : false
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('users'), screen: this.props.auth.screenDefs.TenantUsers}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            search: this.state.search,
            firstName: this.state.firstName,
            email: this.state.email,
            active: this.state.active,
            super: this.state.super,
            locked: this.state.locked
        };
        sessionStorage.setItem('tenantUsers', JSON.stringify(data));
    }

    loadRecords = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            size: this.state.listSize,
            offset: this.state.offset,
            search: this.state.search,
            email: this.state.email,
            sort: JSON.stringify(sort),
            firstName: this.state.firstName,
            active: this.state.active != null ? this.state.active : true,
            super: this.state.super != null ? this.state.super : false,
            locked: this.state.locked != null ? this.state.locked : false
        };
        const result = await axios.get('/api/tenantusers', {params: data});
        this.setState({
            users: result.data.data,
            totalCount: result.data.totalCount
        });
        this.saveFilters();
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords();});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]}, () => {this.loadRecords();});
    };

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
          this.setState({listSize: parseInt(e.target.value)}, () => {
              this.loadRecords();
          });
    };

    handleChangePage = (event, newPage) => {
          this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
              this.loadRecords();
          });
    };

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/tenantuser'
                        createAccess={security.create}
                        printAccess={security.print}
                        printClick={'/#/tenantaccessreport/'}
                    />
                </BreadcrumbBar>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} lg={10}>
                        <Panel>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <TextField value={this.state.search} onChange={this.changeValue} name="search"
                                    size="medium" fullWidth label={t('lastName')} data-cy='lastName'/>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <TextField value={this.state.firstName} onChange={this.changeValue} name="firstName"
                                    size="medium" fullWidth label={t('firstName')} data-cy='firstName'/>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <TextField value={this.state.email} onChange={this.changeValue} name="email"
                                    size="medium" fullWidth label={t('email')} data-cy='email'/>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.active} onChange={this.changeBool('active')}
                                    label={t('active')} name="active" data-cy='active'
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.super} onChange={this.changeBool('super')}
                                    label={t('superUsers')} name="superUsers" data-cy='superUsers'
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.locked} onChange={this.changeBool('locked')}
                                    label={t('locked')} name="locked" data-cy='locked'
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper}>
                                <Table aria-label="User List">
                                    <TableHead>
                                        <TableRow key="tableHeader">
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('userid')}
                                                    active={this.state.orderBy === 'userid'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('userid')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('firstName')}
                                                    active={this.state.orderBy === 'firstName'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('firstName')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel onClick={() => this.sortClick('lastName')}
                                                    active={this.state.orderBy === 'lastName'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('lastName')}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>{t('status')}</TableCell>
                                            <TableCell>{t('created')}</TableCell>
                                            <TableCell>{t('firstLogin')}</TableCell>
                                            <TableCell>{t('lastLogin')}</TableCell>
                                            <TableCell>{t('disabled')}</TableCell>
                                            <TableCell>{t('locked')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.users.map((row, i) =>
                                        <TableRow key={row._id}>
                                            <TableCell><Link to={'/tenantuser/' + row._id} data-cy={'tenantUser' + i}>{row.userid}</Link></TableCell>
                                            <TableCell>{row.firstName}</TableCell>
                                            <TableCell>{row.lastName}</TableCell>
                                            <TableCell>{row.active != null && row.active && row.type != null ? row.type :
                                                        row.active != null && !row.active ? 'Disabled' : ''}</TableCell>
                                            <TableCell>
                                                {row.createdOn != null &&
                                                    <DateDisplay value={row.createdOn} format={this.props.auth.user.dateFormat}/>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {row.firstLogin != null &&
                                                    <DateDisplay value={row.firstLogin} format={this.props.auth.user.dateFormat}/>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {row.lastLogin != null &&
                                                    <DateDisplay value={row.lastLogin} format={this.props.auth.user.dateFormat}/>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {row.disabledOn != null &&
                                                    <DateDisplay value={row.disabledOn} format={this.props.auth.user.dateFormat}/>
                                                }
                                            </TableCell>
                                            <TableCell>{row.locked === true ? t('yes') : ''}</TableCell>
                                        </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow key="tableFooter">
                                        <TablePagination count={this.state.totalCount}
                                        rowsPerPage={this.state.listSize}
                                        onPageChange={this.handleChangePage}
                                        page = {this.state.page}
                                        rowsPerPageOptions={common.rowOptions}
                                        onRowsPerPageChange={this.handleRowsPerPage}/>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Panel>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                        <TenantLicenseTable
                        types={this.props.auth.common.tenantTypes}
                        type={this.props.profile.tenantType}
                        tenant={this.props.profile.tenant}
                        t={t}
                    />
                    </Grid>
                </Grid>

            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile
  });

  export default withTranslation() (connect(mapStateToProps)(TenantUsers));
