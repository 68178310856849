import React, { Component } from 'react';
import axios from 'axios';
import GeneratePdf from '../General/GeneratePdf';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class ComponentToPrint extends Component {
    render(){
        const t = this.props.t;
        return(
            <div>
                <h2>{t('ncrTitle')}: {this.props.data.number}</h2>
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('title')}</th>
                            <th width="200">{t('location')}</th>
                            <th>{t('department')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.title}</td>
                            <td>{this.props.data.location.name}</td>
                            <td>{this.props.data.department.label}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <div><strong>{t('description')}</strong></div>
                <div>{this.props.data.description}</div>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('initiatedBy')}</th>
                            <th width="200">{t('dateInitiated')}</th>
                            <th>{t('occurenceDate')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.initiator.firstName != null ? this.props.data.initiator.firstName + ' ' + 
                            this.props.data.initiator.lastName : ''}</td>
                            <td><DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.initiatedOn}/></td>
                            <td><DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.occurrenceDate}/></td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <table >
                    <thead>
                        <tr>
                            <th width="200">{this.props.data.entityType.label}</th>
                            <th>{t('result')}</th>
                            <th>{t('specification')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.entString}</td>
                            <td>{this.props.data.result}</td>
                            <td>{this.props.data.specification}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <h3>{t('impactAssessment')}</h3>
                <div><strong>{t('impact')}</strong></div>
                <div>{this.props.data.impact}</div>
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('assessedBy')}</th>
                            <th>{t('assessmentDate')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.impactAssessmentUser.firstName != null ? this.props.data.impactAssessmentUser.firstName + ' ' + 
                            this.props.data.impactAssessmentUser.lastName : ''}</td>
                            <td><DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.impactAssessmentDate}/></td>
                        </tr>
                    </tbody>
                </table>
                <h3>{t('capaPlan')}</h3>
                {this.props.data.actions.map((action) =>
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th width="400">{t('description')}</th>
                                    <th width="100">{t('number')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{action.description}</td>
                                    <td>{action.capa}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/>
                        <table>
                            <thead>
                                <tr>
                                    <th width="200">{t('qaApprover')}</th>
                                    <th width="200">{t('date')}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{action.qaApprover != null ? action.qaApprover.firstName + ' ' +
                                    action.qaApprover.lastName : ''}</td>
                                    <td><DateDisplay format={this.props.auth.user.dateFormat} value={action.qaApprovalDate}/></td>
                                    <td><img src={action.qaSignature} alt=""/></td>
                                </tr>
                            </tbody>
                        </table>
                        <br/>
                        <table>
                            <thead>
                                <tr>
                                    <th width="200">{t('completedBy')}</th>
                                    <th width="200">{t('completedOn')}</th>
                                    <th>{t('comments')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{action.completedBy != null ? action.completedBy.firstName + ' ' +
                                    action.completedBy.lastName : ''}</td>
                                    <td><DateDisplay format={this.props.auth.user.dateFormat} value={action.completedDate}/></td>
                                    <td>{action.comments}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/>
                    </div>
                )}
                <h3>{t('qaReview')}</h3>
                <div><strong>{t('concludingRemarks')}</strong></div>
                <div>{this.props.data.remarks}</div>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('qaAgree')}</th>
                            <th>{!this.props.data.qaAgree ? t('whyNot') : ''}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.qaAgree ? t('yes') : t('no')}</td>
                            <td>{this.props.data.qaDisagreeReason}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th>{t('signoff')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.signer != null ? this.props.data.signer.user.firstName + ' ' +
                            this.props.data.signer.user.lastName : ''}</td>
                        </tr>
                        <tr>
                            <td>{this.props.data.signer != null && <DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.signer.date}/>}</td>
                        </tr>
                        <tr>
                            <td>{this.props.data.signer != null && <img src={this.props.data.signer.signature} alt={t('noSignature')}/>}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

class NcrPrint extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            location: {},
            title: '',
            initiator: {},
            initiatedOn: '',
            occurrenceDate: '',
            entityType: {},
            batches: [],
            productionOrders: [],
            lots: [],
            rooms: [],
            specification: '',
            result: '',
            description: '',
            impactExplanation: '',
            impactAssessmentUser: {},
            impactAssessmentDate: '',
            actions: [],
            remarks: '',
            qaAgree: false,
            qaDisagreeReason: '',
            signer: null,
            status: {},
            entString: '',
            tenant: this.props.auth.user.tenant,
            number: '',
            department: {}
        }
    }

    async componentDidMount(){
        const record = await axios.get('/api/ncrprint/' + this.props.match.params.id);
        var entString = "";
        switch(record.data.entityType._id){
            case this.props.auth.common.sopRelatedTos.batch:
                record.data.batches.forEach(batch => {
                    entString = entString + batch.number + ' ';
                });
                break;
            case this.props.auth.common.sopRelatedTos.lot:
                record.data.lots.forEach(lot => {
                    entString = entString + lot.number + ' ';
                });
                break;
            case this.props.auth.common.sopRelatedTos.room:
                record.data.rooms.forEach(room => {
                    entString = entString + room.name + ' ';
                });
                break;
            case this.props.auth.common.sopRelatedTos.productionOrder:
                record.data.productionOrders.forEach(po => {
                    entString = entString + po.number + ' ';
                });
                break;
            case this.props.auth.common.sopRelatedTos.equipment:
                record.data.equipment.forEach(equip => {
                    entString = entString + equip.name + ' ';
                });
                break;
            default:
                break;
        }
        this.setState({
            _id: record.data._id,
            location: record.data.location,
            title: record.data.title,
            initiator: record.data.initiator,
            initiatedOn: record.data.initiatedOn,
            occurrenceDate: record.data.occurrenceDate,
            entityType: record.data.entityType,
            batches: record.data.batches,
            productionOrders: record.data.productionOrders,
            lots: record.data.lots,
            rooms: record.data.rooms,
            specification: record.data.specification,
            result: record.data.result,
            description: record.data.description,
            impactExplanation: record.data.impactExplanation,
            impactAssessmentUser: record.data.impactAssessmentUser,
            impactAssessmentDate: record.data.impactAssessmentDate,
            actions: record.data.actions,
            remarks: record.data.remarks,
            qaAgree: record.data.qaAgree,
            qaDisagreeReason: record.data.qaDisagreeReason,
            signer: record.data.signer,
            status: record.data.status,
            entString: entString,
            number: record.data.number,
            department: record.data.department
        })
    }

    generateMarkup(){
        var htmlData = ReactDOMServer.renderToStaticMarkup(<ComponentToPrint data={this.state} auth={this.props.auth} t={this.props.t}/>);
        return htmlData;
    }

    render(){
        return(
            <div className="container">
                <ComponentToPrint data={this.state} auth={this.props.auth}/>
                <div className="row">
                    <GeneratePdf markup={() => this.generateMarkup()} id={this.props.match.params.id} 
                    objectType={this.props.auth.constants.objectIds.ncr}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile
});

export default withTranslation() (connect(mapStateToProps)(NcrPrint));