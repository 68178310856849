import React, { useState, useEffect } from "react";
import { Scannerz } from "../../../../helpers/scanHelper";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScannerRounded';
import ModalDialog from "../../Dialog/ModalDialog";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Typography from "@mui/material/Typography";

export default function BarcodeScanner() {
  const { t } = useTranslation('translation');
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [hasCamera, setHasCamera] = useState(null);
  const [results, setResults] = useState([]);
  const [status, setStatus] = useState({ message: t('scanToStart'), color: 'primary' });
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkCamera = () => {
      if(hasCamera == null) {
        if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          setHasCamera(true);
        }else{
          setHasCamera(false);
          setStatus({ message: t('noDeviceFound'), color: 'error.main' });
        }
      }
    };
    checkCamera();
  }, [hasCamera, t]);

  
  // const startScanner = () => {
  //   setStatus({ message: 'Scanning...', color: 'secondary' });
  //   codeReader.decodeFromVideoDevice(undefined, videoRef.current, (result, error) => {
  //     if (result) {
  //       setResults([...results, result]);
  //       console.info(result);
  //       setStatus({ message: 'Scan successful', color: 'success' });
  //     }
  //     if (error) {
  //       setStatus({ message: 'Scan failed', color: 'error' });
  //     }
  //   });
  // };

  const buttonStack = () => {
    //TODO: Add scan on/off buttons to reset scanner
    let buttonStack = [
      <Button key='close' variant="text" onClick={handleClose} sx={{ color: 'secondary.main' }}>{t('close')}</Button>,
      // <Button key='stop' variant="text" onClick={(e) => stop(e)} sx={{ color: 'error.main' }}>{t('stop')}</Button>,
      // <Button key='scan' variant="contained" onClick={startScanner} >{t('scan')}</Button>
    ];
    return buttonStack;
  }

  const parsePath = (data) => {
    const url = new URL(data);
    return {
      address: url.href,
      host: url.host,
      pathname: url.pathname,
      search: url.search,
      hash: url.hash.substring(2),
    };
  };

  const onResult = (result) => {
    if(result.text && result.text.includes('http')) {
      const parsedDestination = parsePath(result.text);
      const parsedLocation = parsePath(window.location.href);
      if(parsedDestination.host === parsedLocation.host) {
        setStatus({ message: t('success'), color: 'success' });
        setResults([...results, result]);
        history.push(parsedDestination.hash);
        setOpen(false);
        setStatus({ message: t('scanToStart'), color: 'primary' })
      }else {
        setStatus({ message: t('qrFoundDoesntMatch') + result.text, color: 'warning.main'});
      }
    }else {
      //TODO: Need to process when a 1D code is scanned here.    
      setStatus({ message: t('codeFound') + result.text, color: 'success'});
    }
    
  }

  const onError = (error) => {
    console.error(error);
    if(error.toString().includes('NotFoundException: No MultiFormat Readers were able to detect the code.') ||
       error.toString().includes('scanFailedIndexSizeError')) {
    }else {
      setError(t('scanFailed') + error);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {!open && 
        <IconButton onClick={() => setOpen(true)} sx={{ color: '#FFF' }} disabled={!hasCamera}>
          <QrCodeScannerIcon />
        </IconButton>
      }
      {open && 
        <ModalDialog buttonStack={buttonStack()} title={t('scanner')} toggleDialog={handleClose} 
                    dialogStatus={open} maxWidth='sm' >
            <Scannerz onResult={onResult} onError={onError} />
            <Typography variant='body2' sx={{ color: status.color ?? 'primary.main', textAlign: 'center' }}>{status.message ?? ''}</Typography>
            {error && <Typography variant='body2' sx={{ color: 'error.main', textAlign: 'center' }}>{error}</Typography>}
        </ModalDialog>
      }
    </div>
  );
}