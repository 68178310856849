import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import Prescriptions from './Prescriptions';
import Prescription from './Prescription';
import ObjectLog from '../General/ObjectLog';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import SectionTitle from '../UI/Typography/SectionTitle'
import ActionBar from '../UI/Buttons/ActionBar';
import Shipments from '../General/Shipments';
import Sales from '../General/Sales';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Box from '@mui/material/Box';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import { Link } from 'react-router-dom';
import Confirmation from '../UI/Dialog/Confirmation';

class Customer extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            type: '',
            types: [],
            name: '',
            firstName: '',
            lastName: '',
            middleName: '',
            email: '',
            gender: '',
            genders: [],
            dob: '',
            addresses: [],
            addressTypes: [],
            addressType: '',
            street1: '',
            street2: '',
            city: '',
            prov: '',
            provSel: null,
            provs: [],
            postal: '',
            country: '',
            countrySel: null,
            countries: [],
            phones: [],
            phoneType: '',
            phoneTypeSel: null,
            phoneTypes: [],
            phoneNumber: '',
            nursingHome: false,
            hasCaregiver: false,
            shippingAddress: false,
            caregiverFirstName: '',
            caregiverLastName: '',
            caregiverMiddleName: '',
            caregiverGender: '',
            caregiverDob: '',
            caregiverEmail: '',
            caregiverPhone: '',
            caregiverRelation: '',
            relations: [],
            hasHealthCare: false,
            healthCareName: '',
            healthCarePhone: '',
            isVeteran: false,
            vac: '',
            isIndigenous: false,
            statusNo: '',
            isCarpMember: false,
            carpNumber: '',
            patientId: '',
            registeredWithCertificate: false,
            documents: [],
            prescriptions: [],
            phoneEdit: false,
            addressId: '',
            addressIndex: -1,
            phoneId: '',
            phoneIndex: -1,
            showPrescription: false,
            prescriptionId: '',
            refreshPrescriptions: false,
            locked: true,
            number: '',
            licenseNumber: '',
            licenseType: '',
            licenseTypes: [],
            registrationNumber: '',
            registrationStart: '',
            registrationEnd: '',
            regRenewalDate: '',
            permitId: '',
            tenant: this.props.auth.user.tenant,
            typeSelected: null,
            genderSelected: null,
            licenseTypeSelected: null,
            phneTypeSel: null,
            careGenderSelected: null,
            relationSel: null,
            addressTypeSel: null,
            tabKey: 0,
            tabLabels: [{name: 'Details', disabled: false}],
            security: [],
            enableSave: true,
            renderNum: Math.random(),
            form: '',
            hasForm: false,
            foreignId: '',
            deleteOpen: false,
            gstNumber: '',
            terms: '',
            termsSel: null,
            saleTerms: [],
            vendor: null,
            confirmOpen: false,
        };
        this.changeValue = this.changeValue.bind(this);
        this.changeDob = this.changeDob.bind(this);
        this.changeCaregiverDob = this.changeCaregiverDob.bind(this);
        this.changeNumber = this.changeNumber.bind(this);
        this.changeBool = this.changeBool.bind(this);
        this.closePrescription = this.closePrescription.bind(this);
        this.openPrescription = this.openPrescription.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.gender, common.cacheValues.phoneType, common.cacheValues.addressType,
            common.cacheValues.relation, common.cacheValues.customerType, common.cacheValues.licenseType, common.cacheValues.customerForm, 
            common.cacheValues.saleTerms], this.props.auth.constants, null, this.props.auth.user.tenant);
        const provs = await ValueHelper.getCanadianProvs();
        const countries = await ValueHelper.getCountries();
        let locked = (this.props.match.params.id == null || this.props.match.params.id === '') && this.props.permission.update ? false : true;
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        this.setState({
            genders: cacheValues.genders,
            provs: provs,
            countries: countries,
            phoneTypes: cacheValues.phoneTypes,
            addressTypes: cacheValues.addressTypes,
            relations: cacheValues.relations,
            types: cacheValues.customerTypes,
            licenseTypes: cacheValues.licenseTypes,
            security: this.props.permission,
            locked: locked,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            saleTerms: cacheValues.saleTerms
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/customers', label: this.props.t('customers'), screen: this.props.auth.screenDefs.Customers},
                    { path: 'active', label: this.props.t('customer'), screen: this.props.auth.screenDefs.Customer}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });

    }

    loadRecord = async () =>{
        let id = this.state._id != null && this.state._id !== '' ? this.state._id : this.props.match.params.id;
        let record = await axios.get('/api/customer/' + id);
        let typeSelected = this.state.types.find(x => x.value === record.data.type);
        let termsSel = record.data.terms != null ? this.state.saleTerms.find(x => x.value === record.data.terms) : null;
        let genderSelected = record.data.gender != null && record.data.gender !== '' ?
            this.state.genders.find(x => x.value === record.data.gender) : null;
        let careGenderSelected = record.data.caregiver != null && record.data.caregiver.gender != null &&
            record.data.caregiver.gender !== '' ?
            this.state.genders.find(x => x.value === record.data.caregiver.gender) : null;
        let careRelSelected = record.data.caregiver != null && record.data.caregiver.relation != null &&
            record.data.caregiver.relations !== '' ?
            this.state.relations.find(x => x.value === record.data.caregiver.relation) : null;
        let licenseTypeSelected = this.state.licenseTypes.find(x => x.value === record.data.licenseType);
        let tabLabels = [{name: this.props.t('details'), disabled: false}];
        if(record.data.type != null && record.data.type !== '' && record.data.type === this.props.auth.constants.customerTypes.individual &&
        this.props.auth.user.isCannabis)
            tabLabels.push({name: this.props.t('prescriptions'), disabled: false});
        let addresses = record.data.addresses;
        addresses.forEach(row => {
            let provSel = row.prov != null ? record.data.provs.find(x => x.value === row.prov) : null;
            row.provSel = provSel;
            let countrySel = row.country != null ? this.state.countries.find(x => x.value === row.country) : null;
            row.countrySel = countrySel;
            let val = this.state.addressTypes.find(x => x.value === row.type);
            row.typeSel = val;
        });
        let phones = record.data.phones;
        phones.forEach(row => {
            let val = this.state.phoneTypes.find(x => x.value === row.type);
            row.typeSel = val;
        });
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        this.setState({
            _id: record.data._id,
            type: record.data.type,
            name: record.data.name,
            firstName: record.data.firstName,
            lastName: record.data.lastName,
            middleName: record.data.middleName,
            email: record.data.email,
            gender: record.data.gender,
            dob: record.data.dob,
            addresses: addresses,
            phones: phones,
            nursingHome: record.data.nursingHome,
            hasCaregiver: record.data.hasCaregiver,
            caregiverFirstName: record.data.caregiver != null ? record.data.caregiver.firstName : '',
            caregiverLastName: record.data.caregiver != null ? record.data.caregiver.lastName : '',
            caregiverMiddleName: record.data.caregiver != null ? record.data.caregiver.middleName : '',
            caregiverGender: record.data.caregiver != null ? record.data.caregiver.gender : '',
            caregiverDob: record.data.caregiver != null ? record.data.caregiver.dob : '',
            caregiverEmail: record.data.caregiver != null ? record.data.caregiver.email : '',
            caregiverPhone: record.data.caregiver != null ? record.data.caregiver.phone : '',
            caregiverRelation: record.data.caregiver != null ? record.data.caregiver.relation : '',
            hasHealthCare: record.data.hasHealthCare,
            healthCareName: record.data.healthCare != null ? record.data.healthCare.name : '',
            healthCarePhone: record.data.healthCare != null ? record.data.healthCare.phone : '',
            isVeteran: record.data.isVeteran,
            vac: record.data.vac,
            isIndigenous: record.data.isIndigenous,
            statusNo: record.data.statusNo,
            isCarpMember: record.data.isCarpMember,
            carpNumber: record.data.carpNumber,
            patientId: record.data.patientId,
            registeredWithCertificate: record.data.registeredWithCertificate,
            documents: record.data.documents,
            prescriptions: record.data.prescriptions,
            typeSelected: typeSelected,
            genderSelected: genderSelected,
            careGenderSelected: careGenderSelected,
            careRelSelected: careRelSelected,
            number: record.data.number,
            licenseNumber: record.data.licenseNumber,
            licenseType: record.data.licenseType,
            licenseTypeSelected: licenseTypeSelected,
            registrationNumber: record.data.registrationNumber !== undefined ? record.data.registrationNumber : null,
            registrationStart: record.data.registrationStart,
            registrationEnd: record.data.registrationEnd,
            regRenewalDate: record.data.regRenewalDate,
            permitId: record.data.permitId,
            tabLabels: tabLabels,
            provs: record.data.provs,
            renderNum: Math.random(),
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            foreignId: record.data.foreignId,
            deleteOpen: false,
            gstNumber: record.data.gstNumber,
            terms: record.data.terms,
            termsSel: termsSel,
            vendor: record.data.vendor
        }, () => {
            let label = '';
            if(record.data.type != null && record.data.type !== '' && record.data.type === this.props.auth.constants.customerTypes.individual) {
                label = record.data.firstName + ' ' + record.data.lastName + ' (' + record.data.number + ')';
            }else if(record.data.type != null && record.data.type !== '' && record.data.type === this.props.auth.constants.customerTypes.company) {
                label = record.data.name + ' (' + record.data.number + ')';
            }
            const crumbs = [
                { path: '/#/customers', label: this.props.t('customers'), screen: this.props.auth.screenDefs.Customers},
                { path: 'active', label: label, screen: this.props.auth.screenDefs.Customer}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    changeValue(e){
        this.setState({[e.target.name]: e.target.value});
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(data === 'country' && newValue != null){
            let provs = await axios.get('/api/provinceselect', {params: {country: newValue.value}});
            this.setState({provs: provs.data});
        }
    };

    changeNumber(name, e){
        this.setState({[name]: e.value});
    }

    changeDob(e){
        this.setState({dob: e});
    }

    changeRegStart = (e) => {
        this.setState({registrationStart: e});
    }

    changeRegEnd = (e) => {
        this.setState({registrationEnd: e});
    }

    changeRegRenewal = (e) => {
        this.setState({regRenewalDate: e});
    }

    changeCaregiverDob(e){
        this.setState({caregiverDob: e});
    }

    changeBool(e){
        this.setState({[e.target.name]: !this.state[e.target.name]})
    }

    closePrescription(){
        let tabLabels = [{name: this.props.t('details'), disabled: false}, {name: this.props.t('prescriptions'), disabled: false}];
        this.setState({
            showPrescription: false,
            tabKey: 1,
            refreshPrescriptions: !this.state.refreshPrescriptions,
            tabLabels: tabLabels
        });
    }

    openPrescription(id){
        let tabLabels = [{name: this.props.t('details'), disabled: false}, {name: this.props.t('prescriptions'), disabled: false}, {name: this.props.t('prescription'), disabled: false}];
        this.setState({
            showPrescription: true,
            prescriptionId: id,
            tabKey: 2,
            tabLabels: tabLabels
        });
    }

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const t = this.props.t;
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'SET_ERRORS', payload: errors });
                return;
            }
        }
        let documents = [];
        this.state.documents.forEach(doc => {
            documents.push(doc._id);
        });
        const state = this.state;
        let data = {
            _id: state._id,
            type: state.type,
            name: state.name,
            firstName: state.firstName,
            lastName: state.lastName,
            middleName: state.middleName,
            email: state.email,
            gender: state.gender,
            dob: state.dob,
            addresses: state.addresses,
            phones: state.phones,
            nursingHome: state.nursingHome,
            hasCaregiver: state.hasCaregiver,
            caregiverFirstName: state.caregiverFirstName,
            caregiverLastName: state.caregiverLastName,
            caregiverMiddleName: state.caregiverMiddleName,
            caregiverGender: state.caregiverGender,
            caregiverDob: state.caregiverDob,
            caregiverEmail: state.caregiverEmail,
            caregiverPhone: state.caregiverPhone,
            caregiverRelation: state.caregiverRelation,
            hasHealthCare: state.hasHealthCare,
            healthCareName: state.healthCareName,
            healthCarePhone: state.healthCarePhone,
            isVeteran: state.isVeteran,
            vac: state.vac,
            isIndigenous: state.isIndigenous,
            statusNo: state.statusNo,
            isCarpMember: state.isCarpMember,
            carpNumber: state.carpNumber,
            patientId: state.patientId,
            registeredWithCertificate: state.registeredWithCertificate,
            documents: documents,
            licenseNumber: state.licenseNumber,
            licenseType: state.licenseType,
            registrationNumber: state.registrationNumber,
            registrationStart: state.registrationStart,
            registrationEnd: state.registrationEnd,
            regRenewalDate: state.regRenewalDate,
            permitId: state.permitId,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            foreignId: state.foreignId,
            gstNumber: state.gstNumber,
            terms: state.terms
        };
        try {
            let result = await axios.post('/api/customer', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/customer/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._deleteClick());
    };

    _deleteClick = async () => {
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/customer/' + this.state._id);
                this.props.history.goBack();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            } catch(err){
                this.setState({ enableSave: true, deleteOpen: false }, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
                });
            }
        } else {
            this.props.history.goBack();
        }
    };

    validateForm = () => {
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.licenseType == null || state.licenseType === '')
            errors[t('licenseType')] = t('required');
        if(state.type == null || state.type === ''){
            errors[t('type')] = t('required');
        } else {
            if(state.type === this.props.auth.constants.customerTypes.individual){
                if(state.firstName == null || state.firstName === '')
                    errors[t('firstName')] = t('required');
                if(state.lastName == null || state.lastName === '')
                    errors[t('lastName')] = t('required');
                if(state.email == null || state.email === '')
                    errors[t('email')] = t('required');
                if(state.dob == null || state.dob === '')
                    errors[t('dob')] = t('required');
                if(state.addresses == null || state.addresses.length === 0)
                    errors[t('addresses')] = t('addressRequired');
            } else {
                if(state.name == null || state.name === '')
                    errors[t('name')] = t('nameRequired');
            }
        }
        if(state.hasCaregiver){
            if(state.caregiverFirstName == null || state.caregiverFirstName === '')
                errors[t('caregiverFirstName')] = t('required');
            if(state.caregiverLastName == null || state.caregiverLastName === '')
                errors[t('caregiverLastName')] = t('required');
            if(state.caregiverDob == null || state.caregiverDob === '')
                errors[t('caregiverDOB')] = t('required');
            if(state.caregiverEmail == null || state.caregiverEmail === '')
                errors[t('caregiverEmail')] = t('required');
            if(state.caregiverRelation == null || state.caregiverRelation === '')
                errors[t('caregiverRelation')] = t('required');
        }
        if(state.hasHealthCare){
            if(state.healthCareName == null || state.healthCareName === '')
                errors[t('healthCareName')] = t('required');
            if(state.healthCarePhone == null || state.healchCarePHone === '')
                errors[t('healthCarePhone')] = t('required');
        }
        if(state.addresses == null || state.addresses.length === 0){
            errors[t('addressType')] = t('required');
        } else {
            for(let [count, row] of state.addresses.entries()){
                if(row.type == null || row.type === '')
                    errors[`${t('type')} (${t('line')} ${count})`] = t('required');
                if(row.street1 == null || row.street1 === '')
                    errors[`${t('street')} (${t('line')} ${count})`] = t('required');
                if(row.city == null || row.city === '')
                    errors[`${t('city')} (${t('line')} ${count})`] = t('required');
                if(row.postal == null || row.postal === '')
                    errors[`${t('postalCode')} (${t('line')} ${count})`] = t('required');
                if(row.prov == null || row.prov === '')
                    errors[`${t('province')} (${t('line')} ${count})`] = t('required');
                if(row.country == null || row.country === '')
                    errors[`${t('country')} (${t('line')} ${count})`] = t('required');
            }
        }
        if((state.addressType != null && state.addressType !== '') || (state.street1 != null && state.street1 !== '') || (state.city != null && state.city !== '') ||
        (state.prov != null && state.prov !== '') || (state.street2 != null && state.street2 !== '') || (state.postal != null && state.postal !== '') ||
        (state.country != null && state.country !== '') || state.shippingAddress === true){
            errors[t('addressType')] = t("rowNotAdded");
        }
        for(let i = 0; i < state.phones.length; i++){
            let phone = state.phones[i];
            if(phone.type == null || phone.type === '')
                errors[`${t('phoneType')} (${t('line')} ${i})`] = t('required');
            if(phone.number == null || phone.number === '')
                errors[`${t('number')} (${t('line')} ${i})`] = t('required');
        }
        if((state.phoneType != null && state.phoneType !== '') || (state.phoneNumber != null && state.phoneNumber !== ''))
            errors[t('phoneType')] = t('rowNotAdded');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    clearAddress(){
        this.setState({
            street1: '',
            street2: '',
            city: '',
            prov: '',
            provSel: null,
            addressType: '',
            addressTypeSel: null,
            countrySel: null,
            postal: '',
            country: '',
            shippingAddress: false
        })
    }

    clearPhone(){
        this.setState({
            phoneType: '',
            phoneTypeSel: null,
            phoneNumber: '',
        });
    }

    savePhone = () =>{
        const valid = this.validatePhone();
        if(!valid)
            return;
        let phones = this.state.phones;
        phones.push({
            type: this.state.phoneType,
            typeSel: this.state.phoneTypeSel,
            number: this.state.phoneNumber
        });
        this.setState({phones: phones});
        this.clearPhone();
    }

    deletePhone = (i) => (e) => {
        let contents = this.state.phones;
        contents.splice(i, 1);
        this.setState({phones: contents});
    };

    validatePhone(){
        var errors = {};
        const t = this.props.t;
        if(this.state.phoneType == null || this.state.phoneType === '')
            errors[t('phoneType')] = t('required');
        if(this.state.phoneNumber == null || this.state.phoneNumber === '')
            errors[t('phoneNumber')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    unlock = e => {
        this.setState({locked: !this.state.locked});
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    changeRowAuto = (name, data, i, array) => (e, newValue) => {
        let contents = this.state[array];
        let line = contents[i];
        line[name] = newValue;
        line[data] = newValue != null ? newValue.value : '';
        this.setState({[array]: contents});
    };

    changeRowValue = (name, i, array) => (e) => {
        let contents = this.state[array];
        let line = contents[i];
        line[name] = e.target.value;
        this.setState({contents: contents});
    };

    changeRowBool = (name, i, array) => (e) => {
        let contents = this.state[array];
        let line = contents[i];
        line[name] = !line[name];
        if(name === 'shippingAddress' && line[name] === true){
            for(let index = 0; index < contents.length; index++){
                if(index !== i){
                    contents[index].shippingAddress = false;
                }
            }
        }
        this.setState({[array]: contents})
    }

    deleteAddr = (i) => (e) => {
        let contents = this.state.addresses;
        contents.splice(i, 1);
        this.setState({addresses: contents});
    };

    addAddr = (e) => {
        const valid = this.validateAddress();
        if(!valid)
            return;
        let addresses = this.state.addresses;
        let shippingAddress = false;
        if(addresses.length === 0){
            shippingAddress = true;
        } else {
            shippingAddress = this.state.shippingAddress;
            if(shippingAddress === true){
                for(let row of addresses){
                    row.shippingAddress = false;
                }
            }
        }
        addresses.push({
            type: this.state.addressType,
            typeSel: this.state.addressTypeSel,
            street1: this.state.street1,
            street2: this.state.street2,
            city: this.state.city,
            prov: this.state.prov,
            provSel: this.state.provSel,
            postal: this.state.postal,
            country: this.state.country,
            countrySel: this.state.countrySel,
            shippingAddress: shippingAddress
        })
        this.setState({
            addresses: addresses
        });
        this.clearAddress();
    }

    validateAddress(){
        let errors = {};
        const t = this.props.t;
        let state = this.state;
        if(state.addressType == null || state.addressType === '')
            errors[t('addressType')] = t('required');
        if(state.street1 == null || state.street1 === '')
            errors[t('streetAddress')] = t('required');
        if(state.city == null || state.city === '')
            errors[t('city')] = t('required');
        if(state.prov == null || state.prov === '')
            errors[t('province')] = t('required');
        if(state.postal == null || state.postal === '')
            errors[t('postalCode')] = t('required');
        if(state.country == null || state.country === '')
            errors[t('country')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    toggleConfirmation = () => {
        this.setState({ confirmOpen: !this.state.confirmOpen });
    }

    createVendor = () => {
        if(!this.state.enableSave || this.state.vendor != null)
            return;
        this.setState({enableSave: false}, () => this._createVendor());
    }

    _createVendor = async () => {
        try {
            let result = await axios.post('/api/customervendor', {id: this.state._id});
            this.setState({enableSave: true, confirmOpen: false}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
                this.loadRecord();
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    }

    render(){
        const errors = this.props.errors;
        const locked = this.state.locked;
        const t = this.props.t;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            p: '1rem'
        };
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        objectType={this.props.auth.constants.objectIds.customer}
                        id={this.state._id}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabPanel value={this.state.tabKey} index={0}>
                    <Grid container spacing={3}>
                        {this.state._id != null && this.state._id !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl data-cy='number'>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.typeSelected}
                                    options={this.state.types}
                                    onChange={this.changeAuto('typeSelected', 'type')}
                                    error={errors[t('type')] != null ? true : false}
                                    helperText={errors[t('type')]} 
                                    label={t('type')}
                                    disabled={locked}
                                    required
                                    data-cy='type'
                                />
                            }
                            {locked &&
                                <FormControl data-cy='type'>
                                    <FormLabel>{t('type')}</FormLabel>
                                    {this.state.typeSelected != null ? this.state.typeSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        {this.state.type != null && this.state.type !== '' && this.state.type === this.props.auth.constants.customerTypes.company &&
                            <Aux>
                                <Grid item xs={6} sm={3}>
                                    {!locked &&
                                        <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                        size="medium" fullWidth={true} disabled={locked} label={t('name')} data-cy='name' required={this.state.type === this.props.auth.constants.customerTypes.individual ? false : true}
                                        error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('name')}</FormLabel>
                                            {this.state.name}
                                        </FormControl>
                                    }
                                </Grid>
                                {this.props.auth.user.isCannabis &&
                                    <Aux>
                                        <Grid item xs={4} sm={2}>
                                            {!locked &&
                                                <TextField value={this.state.licenseNumber} onChange={this.changeValue} name="licenseNumber"
                                                size="medium" fullWidth={true} disabled={locked} label={t('licenseNumber')} data-cy='licenseNumber'
                                                error={errors[t('licenseNumber')] != null ? true : false} helperText={errors[t('licenseNumber')]}/>
                                            }
                                            {locked &&
                                                <FormControl data-cy='licenseNumber'>
                                                    <FormLabel>{t('licenseNumber')}</FormLabel>
                                                    {this.state.licenseNumber}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            {!locked &&
                                                <AutoCompleteField
                                                    value={this.state.licenseTypeSelected}
                                                    options={this.state.licenseTypes}
                                                    onChange={this.changeAuto('licenseTypeSelected', 'licenseType')}
                                                    error={errors[t('licenseType')] != null ? true : false}
                                                    helperText={errors[t('licenseType')]} 
                                                    label={t('licenseType')}
                                                    disabled={locked}
                                                    data-cy='licenseType'
                                                />
                                            }
                                            {locked &&
                                                <FormControl data-cy='licenseType'>
                                                    <FormLabel>{t('licenseType')}</FormLabel>
                                                    {this.state.licenseTypeSelected != null ? this.state.licenseTypeSelected.label : ''}
                                                </FormControl>
                                            }
                                        </Grid>
                                    </Aux>
                                }
                            </Aux>
                        }
                        {this.state.type != null && this.state.type !== '' && this.state.type === this.props.auth.constants.customerTypes.individual &&
                            <Aux>
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <TextField value={this.state.firstName} onChange={this.changeValue} name="firstName"
                                        size="medium" fullWidth={true} disabled={locked} label={t('firstName')} data-cy='firstName'
                                        required={this.state.type === this.props.auth.constants.customerTypes.individual ? true : false}
                                        error={errors[t('firstName')] != null ? true : false} helperText={errors[t('firstName')]}/>
                                    }
                                    {locked &&
                                        <FormControl data-cy='firstName'>
                                            <FormLabel>{t('firstName')}</FormLabel>
                                            {this.state.firstName}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <TextField value={this.state.lastName} onChange={this.changeValue} name="lastName"
                                        size="medium" fullWidth={true} disabled={locked} label={t('lastName')} data-cy='lastName'
                                        required={this.state.type === this.props.auth.constants.customerTypes.individual ? true : false}
                                        error={errors[t('lastName')] != null ? true : false} helperText={errors[t('lastName')]}/>
                                    }
                                    {locked &&
                                        <FormControl data-cy='lastName'>
                                            <FormLabel>{t('lastName')}</FormLabel>
                                            {this.state.lastName}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <TextField value={this.state.middleName} onChange={this.changeValue} name="middleName"
                                        size="medium" fullWidth={true} disabled={locked} label={t('middleName')} data-cy='middleName'
                                        error={errors[t('middleName')] != null ? true : false} helperText={errors[t('middleName')]}/>
                                    }
                                        {locked &&
                                        <FormControl data-cy='middleName'>
                                            <FormLabel>{t('middleName')}</FormLabel>
                                            {this.state.middleName}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <AutoCompleteField
                                            value={this.state.genderSelected}
                                            options={this.state.genders}
                                            onChange={this.changeAuto('genderSelected', 'gender')}
                                            error={errors[t('gender')] != null ? true : false}
                                            helperText={errors[t('gender')]} 
                                            label={t('gender')}
                                            disabled={locked}
                                            data-cy='gender'
                                        />
                                    }
                                    {locked &&
                                        <FormControl data-cy='gender'>
                                            <FormLabel>{t('gender')}</FormLabel>
                                            {this.state.genderSelected != null ? this.state.genderSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <DateSelect 
                                            onChange={this.changeDob}
                                            value={this.state.dob}
                                            helperText={errors[t('dob')]}
                                            error={errors[t('dob')] != null ? true : false}
                                            disabled={locked}
                                            label={t('dob')}
                                            format={this.props.auth.user.dateFormat}
                                            required={this.state.type === this.props.auth.constants.customerTypes.individual ? true : false}
                                            data-cy='dob'
                                        />
                                    }
                                    {locked &&
                                        <FormControl data-cy='dob'>
                                            <FormLabel>{t('gender')}</FormLabel>
                                            <DateDisplay value={this.state.dob} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                            </Aux>
                        }
                        <Grid item xs={6} sm={3}>
                            {!locked &&
                                <TextField value={this.state.email} onChange={this.changeValue} name="email"
                                size="medium" fullWidth={true} disabled={locked} label={t('email')} data-cy='email'
                                required={this.state.type === this.props.auth.constants.customerTypes.individual ? true : false}
                                error={errors[t('email')] != null ? true : false} helperText={errors[t('email')]}/>
                            }
                            {locked &&
                                <FormControl data-cy='email'>
                                    <FormLabel>{t('email')}</FormLabel>
                                    {this.state.email}
                                </FormControl>
                            }
                        </Grid>
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.gstNumber} onChange={this.changeValue} name="gstNumber"
                                    size="medium" fullWidth={true} disabled={locked} label={t('gstNumber')} data-cy='gstNumber'
                                    error={errors[t('gstNumber')] != null ? true : false} helperText={errors[t('gstNumber')]}/>
                            </Grid>
                        }
                        {locked && this.state.gstNumber != null && this.state.gstNumber !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('gstNumber')}</FormLabel>
                                    {this.state.gstNumber}
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.termsSel}
                                    options={this.state.saleTerms}
                                    onChange={this.changeAuto('termsSel', 'terms')}
                                    error={errors[t('terms')] != null ? true : false}
                                    helperText={errors[t('terms')]} 
                                    label={t('terms')}
                                    disabled={locked}
                                    required
                                    data-cy='terms'
                                />
                            </Grid>
                        } 
                        {locked && this.state.termsSel != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl data-cy='terms'>
                                    <FormLabel>{t('terms')}</FormLabel>
                                    {this.state.termsSel != null ? this.state.termsSel.label : ''}
                                </FormControl>
                            </Grid>
                        }
                        {this.props.auth.user.internal &&
                            <Grid item xs={4} sm={2}>
                                {locked ?
                                    <FormControl>
                                        <FormLabel>{t('foreignId')}</FormLabel>
                                        {this.state.foreignId}
                                    </FormControl> :
                                    <TextField value={this.state.foreignId} onChange={this.changeValue} name="foreignId"
                                    size="medium" fullWidth={true} disabled={locked} label={t('foreignId')} data-cy='foreignId'
                                    error={errors[t('foreignId')] != null ? true : false} helperText={errors[t('foreignId')]}/>
                                }
                            </Grid>
                        }
                        {this.state.vendor != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('vendor')}</FormLabel>
                                    <Link to={'/vendor/' + this.state.vendor._id}>{this.state.vendor.number}</Link>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    {this.state.hasForm === true &&
                        <Aux>
                            <br/>
                            {errors[t('form')] != null && errors[t('form')] !== '' &&
                                <div style={{color: 'red'}}>{errors[t('form')]}</div>
                            }
                            <Form renderer={renderer}
                                defaultFields={this.state.form}
                                onChange={this.formValueChanged}
                                disabled={locked}/>
                        </Aux>
                    }
                    <br/>
                    <SectionTitle title="Addresses"/>
                    {this.state.addresses.map((row, i) =>
                        <Box borderColor="primary.main" {...defaultProps}  key={'addr' + i} component={'div'}>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <AutoCompleteField
                                            value={row.typeSel}
                                            options={this.state.addressTypes}
                                            onChange={this.changeRowAuto('typeSel', 'type', i, 'addresses')}
                                            error={errors[`${t('type')} (${t('line')} ${i})`] != null ? true : false}
                                            helperText={errors[`${t('type')} (${t('line')} ${i})`]} 
                                            label={t('type')}
                                            disabled={locked}
                                            data-cy={'addressType' + i}
                                        />
                                    }
                                    {locked &&
                                        <FormControl data-cy={'addressType' + i}>
                                            <FormLabel>{t('type')}</FormLabel>
                                            {row.typeSel != null ? row.typeSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <AutoCompleteField
                                            value={row.countrySel}
                                            options={this.state.countries}
                                            onChange={this.changeRowAuto('countrySel', 'country', i, 'addresses')}
                                            error={errors[`${t('country')} (${t('line')} ${i})`] != null ? true : false}
                                            helperText={errors[`${t('country')} (${t('line')} ${i})`]} 
                                            label={t('country')}
                                            disabled={locked}
                                            data-cy={'country' + i}
                                        />
                                    }
                                    {locked &&
                                        <FormControl data-cy={'country' + i}>
                                            <FormLabel>{t('country')}</FormLabel>
                                            {row.countrySel != null ? row.countrySel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!locked &&
                                        <TextField value={row.street1} onChange={this.changeRowValue('street1', i, 'addresses')} name="street1"
                                        size="medium" fullWidth={true} disabled={locked} label={t('street')} data-cy={'street' + i}
                                        error={errors[t('streetAddress')] != null ? true : false} helperText={errors[t('streetAddress')]}/>
                                    }
                                    {locked &&
                                        <FormControl data-cy={'street' + i}>
                                            <FormLabel>{t('street')}</FormLabel>
                                            {row.street1}
                                        </FormControl>
                                    }
                                </Grid>

                                {!locked &&
                                    <Grid item xs={6} sm={3}>
                                        <TextField value={row.street2} onChange={this.changeRowValue('street2', i, 'addresses')} name="street2"
                                        size="medium" fullWidth={true} disabled={locked} label={t('street2')} data-cy={'street2' + i}
                                        error={errors[t('streetAddress2')] != null ? true : false} helperText={errors[t('streetAddress2')]}/>
                                    </Grid>
                                }
                                {locked && row.street2 != null && row.steet2 !== '' &&
                                    <Grid item xs={6} sm={3}>
                                        <FormControl data-cy={'street2' + i}>
                                            <FormLabel>{t('street2')}</FormLabel>
                                            {row.street2}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <TextField value={row.city} onChange={this.changeRowValue('city', i, 'addresses')} name="city"
                                        size="medium" fullWidth={true} disabled={locked} label={t('city')} data-cy={'city' + i}
                                        error={errors[t('city')] != null ? true : false} helperText={errors[t('city')]}/>
                                    }
                                    {locked &&
                                        <FormControl data-cy={'city' + i}>
                                            <FormLabel>{t('city')}</FormLabel>
                                            {row.city}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <AutoCompleteField
                                            value={row.provSel}
                                            options={this.state.provs}
                                            onChange={this.changeRowAuto('provSel', 'prov', i, 'addresses')}
                                            error={errors[`${t('province')} (${t('line')} ${i})`] != null ? true : false}
                                            helperText={errors[`${t('province')} (${t('line')} ${i})`]} 
                                            label={t('prov')}
                                            disabled={locked}
                                            data-cy={'prov' + i}
                                        />
                                    }
                                    {locked &&
                                        <FormControl data-cy={'prov' + i}>
                                            <FormLabel>{t('prov')}</FormLabel>
                                            {row.provSel != null ? row.provSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                {(row.country == null || row.country === '' || row.country === this.props.auth.constants.countries.CA) &&
                                    <Grid item xs={4} sm={2}>
                                        {!locked &&
                                            <InputMaskField mask="a9a-9a9" name="postal" value={row.postal}
                                            onChange={this.changeRowValue('postal', i, 'addresses')} 
                                            helperText={errors[`${t('postalCode')} (${t('line')} ${i})`]}
                                            error={errors[`${t('postalCode')} (${t('line')} ${i})`] != null ? true : false}
                                            label={t('postal')}
                                            disabled={locked}
                                            data-cy={'postal' + i}
                                            />
                                        }
                                        {locked &&
                                            <FormControl data-cy={'postal' + i}>
                                                <FormLabel>{t('postal')}</FormLabel>
                                                {row.postal}
                                            </FormControl>
                                        }
                                    </Grid>
                                }
                                {row.country != null && row.country !== '' && row.country === this.props.auth.constants.countries.US &&
                                    <Grid item xs={4} sm={2}>
                                        {!locked &&
                                            <InputMaskField mask="99999" name="postal" value={row.postal}
                                            onChange={this.changeRowValue('postal', i, 'addresses')} 
                                            helperText={errors[`${t('postalCode')} (${t('line')} ${i})`]}
                                            error={errors[`${t('postalCode')} (${t('line')} ${i})`] != null ? true : false}
                                            label={t('zipCode')}
                                            disabled={locked}
                                            data-cy={'zip' + i}
                                            />
                                        }
                                        {locked &&
                                            <FormControl data-cy={'zip' + i}>
                                                <FormLabel>{t('zipCode')}</FormLabel>
                                                {row.postal}
                                            </FormControl>
                                        }
                                    </Grid>
                                }
                                {row.country != null && row.country !== '' && row.country !== this.props.auth.constants.countries.CA &&
                                row.country !== this.props.auth.constants.countries.US &&
                                    <Grid item xs={4} sm={2}>
                                        {!locked &&
                                            <TextField value={row.postal} onChange={this.changeRowValue('postal', i, 'addresses')} name="postal"
                                            size="medium" fullWidth={true} disabled={locked} label={t('zipCode')} 
                                            error={errors[`${t('postalCode')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('postalCode')} (${t('line')} ${i})`]}/>
                                            }
                                        {locked &&
                                            <FormControl>
                                                <FormLabel>{t('zipCode')}</FormLabel>
                                                {row.postal}
                                            </FormControl>
                                        }
                                    </Grid>
                                }
                                {(!locked || row.shippingAddress === true) &&
                                    <Grid item xs={4} sm={2}>
                                        <CheckboxField
                                        checked={row.shippingAddress} onChange={this.changeRowBool('shippingAddress', i, 'addresses')}
                                        label={t('shippingAddress')} name="shippingAddress" disabled={locked}
                                        data-cy={'shippingCheck' + i}
                                        />
                                    </Grid>
                                }
                                {!locked &&
                                    <Grid item sx={{width: 50}}>
                                        <IconButton aria-label="delete line" onClick={this.deleteAddr(i)} data-cy={'deleteAddress' + i}>
                                            <RemoveCircleTwoToneIcon  />
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    )}
                    {errors[t('addresses')] && <div><div className="error text-danger">{errors[t('addresses')]}</div><br/></div>}
                    {!locked &&
                        <Box borderColor="primary.main" {...defaultProps}  key={'addr'} component={'div'}>
                            <br/>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.addressTypeSel}
                                        options={this.state.addressTypes}
                                        onChange={this.changeAuto('addressTypeSel', 'addressType')}
                                        error={errors[t('addressType')] != null ? true : false}
                                        helperText={errors[t('addressType')]} 
                                        label={t('type')}
                                        disabled={locked}
                                        data-cy='addressType'
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.countrySel}
                                        options={this.state.countries}
                                        onChange={this.changeAuto('countrySel', 'country')}
                                        error={errors[t('country')] != null ? true : false}
                                        helperText={errors[t('country')]} 
                                        label={t('country')}
                                        disabled={locked}
                                        data-cy='country'
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <TextField value={this.state.street1} onChange={this.changeValue} name="street1"
                                    size="medium" fullWidth={true} disabled={locked} label={t('street')} data-cy='street'
                                    error={errors[t('streetAddress')] != null ? true : false} helperText={errors[t('streetAddress')]}/>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <TextField value={this.state.street2} onChange={this.changeValue} name="street2"
                                    size="medium" fullWidth={true} disabled={locked} label={t('street2')} data-cy='street2'
                                    error={errors[t('steetAddress2')] != null ? true : false} helperText={errors[t('steetAddress2')]}/>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <TextField value={this.state.city} onChange={this.changeValue} name="city"
                                    size="medium" fullWidth={true} disabled={locked} label={t('city')} data-cy='city'
                                    error={errors[t('city')] != null ? true : false} helperText={errors[t('city')]}/>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.provSel}
                                        options={this.state.provs}
                                        onChange={this.changeAuto('provSel', 'prov')}
                                        error={errors[t('province')] != null ? true : false}
                                        helperText={errors[t('province')]} 
                                        label={t('province')}
                                        disabled={locked}
                                        data-cy='prov'
                                    />
                                </Grid>
                                {(this.state.country == null || this.state.country === '' || this.state.country === this.props.auth.constants.countries.CA) &&
                                    <Grid item xs={4} sm={2}>
                                        <InputMaskField mask="a9a-9a9" name="postal" value={this.state.postal}
                                        onChange={this.changeValue} 
                                        helperText={errors[t('postalCode')]}
                                        error={errors[t('postalCode')] != null ? true : false}
                                        label={t('postal')}
                                        disabled={locked}
                                        data-cy='postal'
                                        />
                                    </Grid>
                                }
                                {this.state.country != null && this.state.country !== '' && this.state.country === this.props.auth.constants.countries.US &&
                                    <Grid item xs={4} sm={2}>
                                        <InputMaskField mask="99999" name="postal" value={this.state.postal}
                                        onChange={this.changeValue} 
                                        helperText={errors[t('postalCode')]}
                                        error={errors[t('postalCode')] != null ? true : false}
                                        label={t('zipCode')}
                                        disabled={locked}
                                        data-cy='zip'
                                        />
                                    </Grid>
                                }
                                {this.state.country != null && this.state.country !== '' && this.state.country !== this.props.auth.constants.countries.CA &&
                                this.state.country !== this.props.auth.constants.countries.US &&
                                    <Grid item xs={4} sm={2}>
                                        <TextField value={this.state.postal} onChange={this.changeValue} name="postal"
                                        size="medium" fullWidth={true} disabled={locked} label={t('zipCode')} data-cy='zip'
                                        error={errors[t('postalCode')] != null ? true : false} helperText={errors[t('postalCode')]}/>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.shippingAddress} onChange={this.changeBool}
                                    label={t('shippingAddress')} name="shippingAddress" disabled={locked}
                                    data-cy='shippingCheck'
                                    />
                                </Grid>
                                <Grid item sx={{width: 50}}>
                                    <IconButton aria-label="add line" onClick={this.addAddr} data-cy='addAddress'>
                                        <AddBoxTwoToneIcon  />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    <br/>
                    <SectionTitle title={t('phoneNumbers')}/>
                    <Grid container spacing={3} key="phoneHeader">
                        <Grid item xs={4} sm={2}>{t('type')}</Grid>
                        <Grid item xs={6} sm={3}>{t('number')}</Grid>
                    </Grid>
                    {this.state.phones.map((row, i) =>
                        <Grid container spacing={3} key={'phone' + i}>
                            <Grid item xs={4} sm={2}>
                                {!locked &&
                                    <AutoCompleteField
                                        value={row.typeSel}
                                        options={this.state.phoneTypes}
                                        onChange={this.changeRowAuto('typeSel', 'type', i, 'phones')}
                                        error={errors[`${t('type')} (${t('line')} ${i})`] != null ? true : false}
                                        helperText={errors[`${t('type')} (${t('line')} ${i})`]} 
                                        disabled={locked}
                                        required
                                        data-cy={'phoneType' + i}
                                    />
                                }
                                {locked && row.typeSel != null ? row.typeSel.label : ''}
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                {!locked &&
                                    <InputMaskField mask="999-999-9999" name="phoneNumber" value={row.number}
                                    onChange={this.changeRowValue('number', i, 'phones')} 
                                    helperText={errors[`${t('number')} (${t('line')} ${i})`]}
                                    error={errors[`${t('number')} (${t('line')} ${i})`] != null ? true : false}
                                    disabled={locked}
                                    required
                                    data-cy={'phoneNumber' + i}
                                    />
                                }
                                {locked &&
                                    row.number
                                }
                            </Grid>
                            {!locked &&
                                <Grid item sx={{width: 50}}>
                                    <IconButton aria-label="delete line" onClick={this.deletePhone(i)} data-cy={'deletePhone' + i}>
                                        <RemoveCircleTwoToneIcon  />
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    )}
                    {!locked &&
                        <Grid container spacing={3} key={'addPhone'}>
                            <Grid item xs={4} sm={2}>
                                <AutoCompleteField
                                    value={this.state.phoneTypeSel}
                                    options={this.state.phoneTypes}
                                    onChange={this.changeAuto('phoneTypeSel', 'phoneType')}
                                    error={errors[t('phoneType')] != null ? true : false}
                                    helperText={errors[t('phoneType')]} 
                                    disabled={locked}
                                    data-cy='phoneType'
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <InputMaskField mask="999-999-9999" name="phoneNumber" value={this.state.phoneNumber}
                                onChange={this.changeValue} 
                                helperText={errors[t('phoneNumber')]}
                                error={errors[t('phoneNumber')] != null ? true : false}
                                disabled={locked}
                                required
                                data-cy='phoneNumber'
                                />
                            </Grid>
                            <Grid item sx={{width: 50}}>
                                <IconButton aria-label="add line" onClick={this.savePhone} data-cy='addPhone'>
                                    <AddBoxTwoToneIcon  />
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                    {this.state.type != null && this.state.type !== '' && this.state.type === this.props.auth.constants.customerTypes.individual &&
                        <Aux>
                            <br/>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}>
                                    <CheckboxField
                                    checked={this.state.nursingHome} onChange={this.changeBool}
                                    label={t('inNursing')} name="nursingHome" disabled={locked}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <CheckboxField
                                    checked={this.state.hasCaregiver} onChange={this.changeBool}
                                    label={t('hasCaregiver')} name="hasCaregiver" disabled={locked}
                                    />
                                </Grid>
                            </Grid>
                            {this.state.hasCaregiver &&
                                <Aux>
                                    <SectionTitle title={t('caregiver')}/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} sm={2}>
                                            {!locked &&
                                                <TextField value={this.state.caregiverFirstName} onChange={this.changeValue} name="caregiverFirstName"
                                                size="medium" fullWidth={true} disabled={locked} label={t('firstName')} required={this.state.hasCaregiver}
                                                error={errors[t('caregiverFirstName')] != null ? true : false} helperText={errors[t('caregiverFirstName')]}/>
                                            }
                                            {locked &&
                                                <FormControl required={this.state.hasCaregiver}>
                                                    <FormLabel>{t('firstName')}</FormLabel>
                                                    {this.state.caregiverFirstName}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            {!locked &&
                                                <TextField value={this.state.caregiverLastName} onChange={this.changeValue} name="caregiverLastName"
                                                size="medium" fullWidth={true} disabled={locked} label={t('lastName')} required={this.state.hasCaregiver}
                                                error={errors[t('caregiverLastName')] != null ? true : false} helperText={errors[t('caregiverLastName')]}/>
                                            }
                                            {locked &&
                                                <FormControl required={this.state.hasCaregiver}>
                                                    <FormLabel>{t('lastName')}</FormLabel>
                                                    {this.state.caregiverLastName}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            {!locked &&
                                                <TextField value={this.state.caregiverMiddleName} onChange={this.changeValue} name="caregiverMiddleName"
                                                size="medium" fullWidth={true} disabled={locked} label={t('middleName')}
                                                error={errors[t('caregiverMiddleName')] != null ? true : false} helperText={errors[t('caregiverMiddleName')]}/>
                                            }
                                            {locked &&
                                                <FormControl>
                                                    <FormLabel>{t('middleName')}</FormLabel>
                                                    {this.state.caregiverMiddleName}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            {!locked &&
                                                <AutoCompleteField
                                                    value={this.state.careGenderSelected}
                                                    options={this.state.genders}
                                                    onChange={this.changeAuto('careGenderSelected', 'caregiverGender')}
                                                    error={errors[t('caregiverGender')] != null ? true : false}
                                                    helperText={errors[t('caregiverGender')]} 
                                                    label={t('gender')}
                                                    disabled={locked}
                                                />
                                            }
                                            {locked &&
                                                <FormControl>
                                                    <FormLabel>{t('gender')}</FormLabel>
                                                    {this.state.careGenderSelected != null ? this.state.careGenderSelected.label : ''}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            {!locked &&
                                                <DateSelect 
                                                    onChange={this.changeCaregiverDob}
                                                    value={this.state.caregiverDob}
                                                    helperText={errors[t('caregiverDOB')]}
                                                    error={errors[t('caregiverDOB')] != null ? true : false}
                                                    disabled={locked}
                                                    label={t('dob')}
                                                    format={this.props.auth.user.dateFormat}
                                                    required={this.state.hasCaregiver}
                                                />
                                            }
                                            {locked &&
                                                <FormControl required={this.state.hasCaregiver}>
                                                    <FormLabel>{t('dob')}</FormLabel>
                                                    <DateDisplay value={this.state.caregiverDob} format={this.props.auth.user.dateFormat}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            {!locked &&
                                                <TextField value={this.state.caregiverEmail} onChange={this.changeValue} name="caregiverEmail"
                                                size="medium" fullWidth={true} disabled={locked} label={t('email')} required={this.state.hasCaregiver}
                                                error={errors[t('caregiverEmail')] != null ? true : false} helperText={errors[t('caregiverEmail')]}/>
                                            }
                                            {locked &&
                                                <FormControl required={this.state.hasCaregiver}>
                                                    <FormLabel>{t('email')}</FormLabel>
                                                    {this.state.caregiverEmail}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            {!locked &&
                                                <InputMaskField mask="999-999-9999" name="caregiverPhone" value={this.state.caregiverPhone}
                                                onChange={this.changeValue} 
                                                helperText={errors[t('caregiverPhone')]}
                                                error={errors[t('caregiverPhone')] != null ? true : false}
                                                label={t('phoneNumber')}
                                                disabled={locked}
                                                />
                                            }
                                            {locked &&
                                                <FormControl>
                                                    <FormLabel>{t('phoneNumber')}</FormLabel>
                                                    {this.state.caregiverPhone}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            {!locked &&
                                                <AutoCompleteField
                                                    value={this.state.careRelSelected}
                                                    options={this.state.relations}
                                                    onChange={this.changeAuto('careRelSelected', 'caregiverRelation')}
                                                    error={errors[t('caregiverRelation')] != null ? true : false}
                                                    helperText={errors[t('caregiverRelation')]} 
                                                    label={t('relation')}
                                                    disabled={locked}
                                                    required={this.state.hasCaregiver}
                                                />
                                            }
                                            {locked &&
                                                <FormControl required={this.state.hasCaregiver}>
                                                    <FormLabel>{t('relation')}</FormLabel>
                                                    {this.state.careRelSelected != null ? this.state.careRelSelected.label : ''}
                                                </FormControl>
                                            }
                                        </Grid>
                                    </Grid>
                                </Aux>
                            }
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={3}>
                                    <CheckboxField
                                    checked={this.state.hasHealthCare} onChange={this.changeBool}
                                    label={t('hasHealthcare')} name="hasHealthCare" disabled={locked}
                                    />
                                </Grid>
                                {this.state.hasHealthCare &&
                                    <Aux>
                                        <Grid item xs={6} sm={3}>
                                            {!locked &&
                                                <TextField value={this.state.healthCareName} onChange={this.changeValue} name="healthCareName"
                                                size="medium" fullWidth={true} disabled={locked} label={t('providerName')}
                                                required={this.state.hasHealthCare}
                                                error={errors[t('healthCareName')] != null ? true : false} helperText={errors[t('healthCareName')]}/>
                                            }
                                            {locked &&
                                                <FormControl required={this.state.hasHealthCare}>
                                                    <FormLabel>{t('providerName')}</FormLabel>
                                                    {this.state.healthCareName}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            {!locked &&
                                                <InputMaskField mask="999-999-9999" name="healthCarePhone" value={this.state.healthCarePhone}
                                                onChange={this.changeValue} 
                                                helperText={errors[t('healthCarePhone')]}
                                                error={errors[t('healthCarePhone')] != null ? true : false}
                                                label={t('providerPhone')}
                                                disabled={locked}
                                                required={this.state.hasHealthCare}
                                                />
                                            }
                                            {locked &&
                                                <FormControl required={this.state.hasHealthCare}>
                                                    <FormLabel>{t('providerPhone')}</FormLabel>
                                                    {this.state.healthCarePhone}
                                                </FormControl>
                                            }
                                        </Grid>
                                    </Aux>
                                }
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.isVeteran} onChange={this.changeBool}
                                    label={t('veteran')} name="isVeteran" disabled={locked}
                                    />
                                </Grid>
                                {this.state.isVeteran &&
                                    <Grid item xs={4} sm={2}>
                                        {!locked &&
                                            <TextField value={this.state.vac} onChange={this.changeValue} name="vac"
                                            size="medium" fullWidth={true} disabled={locked} label={t('vac')} 
                                            error={errors[t('vac')] != null ? true : false} helperText={errors[t('vac')]}/>
                                        }
                                        {locked &&
                                            <FormControl>
                                                <FormLabel>{t('vac')}</FormLabel>
                                                {this.state.vac}
                                            </FormControl>
                                        }
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.isIndigenous} onChange={this.changeBool}
                                    label={t('indigenous')} name="isIndigenous" disabled={locked}
                                    />
                                </Grid>
                                {this.state.isIndigenous &&
                                    <Grid item xs={4} sm={2}>
                                        {!locked &&
                                            <TextField value={this.state.statusNo} onChange={this.changeValue} name="statusNo"
                                            size="medium" fullWidth={true} disabled={locked} label={t('treatyNo')} 
                                            error={errors[t('statusNo')] != null ? true : false} helperText={errors[t('statusNo')]}/>
                                        }
                                        {locked &&
                                            <FormControl>
                                                <FormLabel>{t('treatyNo')}</FormLabel>
                                                {this.state.statusNo}
                                            </FormControl>
                                        }
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                    checked={this.state.isCarpMember} onChange={this.changeBool}
                                    label="CARP Member" name="isCarpMember" disabled={locked}
                                    />
                                </Grid>
                                {this.state.isCarpMember &&
                                    <Grid item xs={4} sm={2}>
                                        {!locked &&
                                            <TextField value={this.state.carpNumber} onChange={this.changeValue} name="carpNumber"
                                            size="medium" fullWidth={true} disabled={locked} label={t('carpNo')} 
                                            error={errors[t('carpNumber')] != null ? true : false} helperText={errors[t('carpNumber')]}/>
                                        }
                                        {locked &&
                                            <FormControl>
                                                <FormLabel>{t('carpNo')}</FormLabel>
                                                {this.state.carpNumber}
                                            </FormControl>
                                        }
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <TextField value={this.state.patientId} onChange={this.changeValue} name="patientId"
                                        size="medium" fullWidth={true} disabled={locked} label={t('patientNo')} 
                                        error={errors[t('patientId')] != null ? true : false} helperText={errors[t('patientId')]}/>
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('patientNo')}</FormLabel>
                                            {this.state.patientId}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <CheckboxField
                                    checked={this.state.registeredWithCertificate} onChange={this.changeBool}
                                    label={t('regWithCert')} name="registeredWithCertificate" disabled={locked}
                                    />
                                </Grid>
                            </Grid>
                        </Aux>
                    }
                    {this.state.type != null && this.state.type !== '' && this.state.type === this.props.auth.constants.customerTypes.individual &&
                    this.props.auth.user.isCannabis &&
                        <Grid container spacing={3}>
                            <Grid item xs={4} sm={2}>
                                {!locked &&
                                    <TextField value={this.state.registrationNumber} onChange={this.changeValue} name="registrationNumber"
                                    size="medium" fullWidth={true} disabled={locked} label={t('regNo')} 
                                    error={errors[t('registrationNumber')] != null ? true : false} helperText={errors[t('registrationNumber')]}/>
                                }
                                {locked && this.state.registrationNumber != null && this.state.registrationNumber !== '' &&
                                    <FormControl>
                                        <FormLabel>{t('regNo')}</FormLabel>
                                        {this.state.registrationNumber}
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                {!locked &&
                                    <DateSelect 
                                        onChange={this.changeRegStart}
                                        value={this.state.registrationStart}
                                        helperText={errors[t('registrationStart')]}
                                        error={errors[t('registrationStart')] != null ? true : false}
                                        disabled={locked}
                                        label={t('regStart')}
                                        format={this.props.auth.user.dateFormat}
                                    />
                                }
                                {locked && this.state.registrationStart != null && this.state.registrationStart !== '' &&
                                    <FormControl>
                                        <FormLabel>{t('regStart')}</FormLabel>
                                        <DateDisplay value={this.state.registrationStart} format={this.props.auth.user.dateFormat}/>
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                {!locked &&
                                    <DateSelect 
                                        onChange={this.changeRegEnd}
                                        value={this.state.registrationEnd}
                                        helperText={errors[t('registrationEnd')]}
                                        error={errors[t('registrationEnd')] != null ? true : false}
                                        disabled={locked}
                                        label={t('regEnd')}
                                        format={this.props.auth.user.dateFormat}
                                    />
                                }
                                {locked && this.state.registrationEnd != null && this.state.registrationEnd !== '' &&
                                    <FormControl>
                                        <FormLabel>{t('regEnd')}</FormLabel>
                                        <DateDisplay value={this.state.registrationEnd} format={this.props.auth.user.dateFormat}/>
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                {!locked &&
                                    <DateSelect 
                                        onChange={this.changeRegRenewal}
                                        value={this.state.regRenewalDate}
                                        helperText={errors[t('regRenewalDate')]}
                                        error={errors[t('regRenewalDate')] != null ? true : false}
                                        disabled={locked}
                                        label={t('renewal')}
                                        format={this.props.auth.user.dateFormat}
                                    />
                                }
                                {locked && this.state.regRenewalDate != null && this.state.regRenewalDate !== '' &&
                                    <FormControl>
                                        <FormLabel>{t('renewal')}</FormLabel>
                                        <DateDisplay value={this.state.regRenewalDate} format={this.props.auth.user.dateFormat}/>
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                {!locked &&
                                    <TextField value={this.state.permitId} onChange={this.changeValue} name="permitId"
                                    size="medium" fullWidth={true} disabled={locked} label={t('permitId')} 
                                    error={errors[t('permitId')] != null ? true : false} helperText={errors[t('permitId')]}/>
                                }
                                {locked && this.state.permitId != null && this.state.permitId !== '' &&
                                    <FormControl>
                                        <FormLabel>{t('permitId')}</FormLabel>
                                        {this.state.permitId}
                                    </FormControl>
                                }
                            </Grid>
                        </Grid>
                    }
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            {!locked &&
                                <Aux>
                                    <Button disabled={!this.state.enableSave} variant="contained" color="primary" size="small" onClick={() => this.saveClick()}>{t('save')}</Button>
                                    &nbsp;&nbsp;
                                </Aux>
                            }
                            {this.state.security.update && locked &&
                                <Button variant="contained" color="secondary" size="small" onClick={this.unlock}>{t('unlock')}</Button>
                            }
                            {this.state.security.update && !locked &&
                                <Aux>
                                    <Button variant="contained" color="secondary" size="small" onClick={this.unlock}>{t('lock')}</Button>
                                    &nbsp;&nbsp;
                                </Aux>
                            }
                            {this.state.security.create && !locked && this.state.vendor == null &&
                                <Aux>
                                    <Button variant="contained" color="secondary" size="small" onClick={this.toggleConfirmation}>{t('createVendor')}</Button>
                                    &nbsp;&nbsp;
                                </Aux>
                            }
                            {this.state.security.delete && !locked &&
                                <Button disabled={!this.state.enableSave} variant="contained" color="secondary" size="small" onClick={this.deleteToggle}>{t('delete')}</Button>
                            }
                        </Grid>
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <br/>
                            <Sales customer={this.state._id}/>
                            <Shipments customer={this.state._id} title={t('salesShipments')} type={this.props.auth.constants.shipmentTypes.sale}/>
                        </Aux>
                    }
                    <br/>

                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.customer}/>
                            <ObjectLog id={this.state._id}  renderNum={this.state.renderNum}/>
                        </Aux>
                    }
                </TabPanel>
                {this.state.type != null && this.state.type !== '' && this.state.type === this.props.auth.constants.customerTypes.individual &&
                this.props.auth.user.isCannabis &&
                    <TabPanel value={this.state.tabKey} index={1}>
                        <Prescriptions id={this.props.match.params.id} open={this.openPrescription}
                        refresh={this.state.refreshPrescriptions}/>
                    </TabPanel>
                }
                {this.state.showPrescription &&
                    <TabPanel value={this.state.tabKey} index={2}>
                        <Prescription id={this.state.prescriptionId} customerId={this.state._id} close={this.closePrescription}/>
                    </TabPanel>
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.state.confirmOpen &&
                    <Confirmation
                        content={t('confirmCreateVendor')}
                        no={this.toggleConfirmation}
                        noButton={t('no')}
                        yes={this.createVendor}
                        yesButton={t('yes')}
                        title={t('confirm')}
                        open={this.state.confirmOpen}
                    />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Customer));
