import React from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { NumericFormat } from 'react-number-format';
// import PeopleIcon from '@mui/icons-material/People';
// import CannabisIcon from 'mdi-material-ui/Cannabis';
// import CannabisOffIcon from 'mdi-material-ui/CannabisOff';
// import PackagingIcon from 'mdi-material-ui/PackageVariantClosed';
import DateDisplay from '../../UI/DateDisplay/DateDisplay';
import ReportTitle from '../../UI/Typography/ReportTitle';
import ReportSectionTitle from '../../UI/Typography/ReportSectionTitle';
import ConversionHelper from '../../../helpers/conversionHelper';

import ProductionOrderActivity from './ProductionOrderActivity';

export default function ProductionOrderTemplate(props) {
    const { batch, constants, dateFormat, record, printView, sequence, t } = props;

    return (
        <div style={{ width: '8.5in', pageBreakBefore: batch != null ? 'always' : null }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ReportTitle 
                        title={
                            (sequence != null && sequence !== '' ? sequence + '. ' + t('order') + ': ' : t('product') + ': ') + 
                            (record.outputItem != null && record.outputItem !== '' ? 
                                record.outputItem.name : ''
                            )
                        } 
                        description={record.description != null && record.description !== '' ? record.description : null}
                    />
                </Grid>
                <Grid item xs={12}>
                    <table style={{ width: '100%' }} >
                        <thead>
                            <tr>
                                <th style={{ border: '0.5px solid rgb(224, 224, 224)', width: '16.67%', fontSize: '9pt', textAlign: 'center' }}>{t('orderNumber')}</th>
                                <th style={{ border: '0.5px solid rgb(224, 224, 224)', width: '16.67%', fontSize: '9pt', textAlign: 'center' }}>{t('batch')}</th>
                                <th style={{ border: '0.5px solid rgb(224, 224, 224)', width: '16.67%', fontSize: '9pt', textAlign: 'center' }}>{t('status')}</th>
                                <th style={{ border: '0.5px solid rgb(224, 224, 224)', width: '16.67%', fontSize: '9pt', textAlign: 'center' }}>{t('startDate')}</th>
                                <th style={{ border: '0.5px solid rgb(224, 224, 224)', width: '16.67%', fontSize: '9pt', textAlign: 'center' }}>{t('endDate')}</th>
                                <th style={{ border: '0.5px solid rgb(224, 224, 224)', width: '16.67%', fontSize: '9pt', textAlign: 'center' }}>{t('quantity') + (record.quantityUOM != null && record.quantityUOM !== '' ? ' (' + record.quantityUOM.label + ')' : '')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>{record.number}</td>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                    {record.productionBatch != null && record.productionBatch !== '' && record.productionBatch.number != null && record.productionBatch.number !== '' ? 
                                        record.productionBatch.number : 
                                     batch?.number != null && batch?.number !== '' ? batch.number : t('none')
                                    }
                                </td>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                    {record.status != null && record.status !== '' && record.status.label != null && record.status.label !== '' ?
                                        record.status.label : ''
                                    }
                                </td>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                    {record.startDate != null && record.startDate !== '' ?
                                        <DateDisplay value={record.startDate} format={dateFormat} /> : t('na')
                                    }
                                </td>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                    {record.endDate != null && record.endDate !== '' ?
                                        <DateDisplay value={record.endDate} format={dateFormat} /> : t('na')
                                    }
                                </td>
                                <td style={{ 
                                    border: '0.5px solid rgb(224, 224, 224)', 
                                    fontStyle: (record.status != null && record.status !== '' &&
                                                (record.status._id === constants.productionOrderStatuses.complete || 
                                                record.status._id === constants.productionOrderStatuses.reviewed ||
                                                record.status._id === constants.productionOrderStatuses.finished ) ? 'normal' : 'italic'),
                                    fontSize: '8pt', 
                                    padding: '5px',
                                    textAlign: 'center' 
                                    }}>
                                    {record.status != null && record.status !== '' &&
                                        (record.status._id === constants.productionOrderStatuses.complete || 
                                         record.status._id === constants.productionOrderStatuses.reviewed ||
                                         record.status._id === constants.productionOrderStatuses.finished ) ?
                                        record.quantity : record.plannedQuantity
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
                <Grid item xs={12}>
                    <table style={{ width: '100%' }} >
                        <thead>
                            <tr>
                                <th style={{ border: '0.5px solid rgb(224, 224, 224)', width: '25%', fontSize: '9pt', textAlign: 'center' }}>{t('customOrder')}</th>
                                <th style={{ border: '0.5px solid rgb(224, 224, 224)', width: '25%', fontSize: '9pt', textAlign: 'center' }}>{t('routing')}</th>
                                <th style={{ border: '0.5px solid rgb(224, 224, 224)', width: '25%', fontSize: '9pt', textAlign: 'center' }}>{t('productionBom')}</th>
                                <th style={{ border: '0.5px solid rgb(224, 224, 224)', width: '25%', fontSize: '9pt', textAlign: 'center' }}>{t('cannabisProduct')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                    {record.custom != null && record.custom !== '' && record.custom === true ? t('yes') : t('no')}
                                </td>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                    {record.routingObj != null && record.routingObj !== '' ? record.routingObj.name : t('none')}
                                </td>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center' }}>
                                    {record.productionBom != null && record.productionBom !== '' ? record.productionBom.description : t('none')}
                                </td>
                                <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'center', height: '30px' }}>
                                    {record.hasCannabis != null && record.hasCannabis !== '' && record.hasCannabis === true ? 
                                        t('yes') : t('no')
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
                <Grid item xs={12}>
                    <ReportSectionTitle title={t('performedBy')}/>
                    {record.users != null && record.users !== '' && record.users.length > 0 ?
                        <div style={{ fontSize: '9pt', padding: '5px' }}>
                            {record.users.map((user, i) => {
                                if(i === record.users.length - 1)
                                    return user.name
                                else
                                    return user.name + ', '
                                })
                            }
                        </div> : ''                             
                    }
                </Grid>
                {record.notes != null && record.notes !== '' &&
                    <Grid item xs={12}>
                        <ReportSectionTitle title={t('notes')} />
                        <div style={{ fontSize: '9pt', padding: '5px' }}>
                            {record.notes}
                        </div>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Divider sx={{ width: '80%', margin: '0 auto' }} />
                </Grid>
                {record.hasCannabis != null && record.hasCannabis !== '' && record.hasCannabis === true &&
                    <Grid item xs={12}>
                        <ReportSectionTitle title={t('cannabisInputs')} />
                        <table style={{ borderCollapse: 'collapse', width: '100%'}} >
                            <thead>
                                <tr>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '125px', textAlign: 'center' }}><strong>{t('item')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('lot')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('container')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('bin')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('quantity')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('count')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('discreteWeightG')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('totalWeightG')}</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                {record.cannabisInputs != null && record.cannabisInputs.length > 0 ?
                                    record.cannabisInputs.map((input, i) => 
                                        <tr key={`cannabisInputRow-${i}`} >
                                            <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                                {input.item != null && input.item !== '' && input.item.name != null && input.item.name !== '' ? input.item.name : ''}
                                            </td>
                                            <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                                {input.lot?.number != null && input.lot?.number !== '' ? input.lot.number : '' }    
                                            </td>
                                            <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                                {input.container?.number != null && input.container?.number !== '' ? input.container.number : ''}
                                            </td>
                                            <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', maxWidth: '75px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                {input.container?.bin?.code != null && input.container?.bin?.code !== '' ? input.container.bin.code : ''}
                                            </td>
                                            <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                                {input.amount != null && input.amount !== '' && input.uom != null && input.uom !== '' ? 
                                                    input.amount + input.uom : ''
                                                }
                                            </td>
                                            <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                                {input.count != null && input.count !== '' ? input.count : t('na')}
                                            </td>
                                            <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                                {input.discreteWeight != null && input.discreteWeight !== '' ? input.discreteWeight : t('na')}
                                            </td>
                                            <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                                {input.fullDiscreteWeight != null && input.fullDiscreteWeight !== '' ? input.fullDiscreteWeight : t('na')}
                                            </td>
                                        </tr>
                                    ) :
                                    <tr key={'cannabisInputRow-noData'}>
                                        <td colSpan={7} style={{ textAlign: 'center', border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px' }}>{t('none')}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </Grid>
                }
                {record.hasCannabis != null && record.hasCannabis !== '' && record.hasCannabis === true &&
                    <Grid item xs={12}>
                        <ReportSectionTitle title={t('cannabisOutputs')} />
                        <table style={{ borderCollapse: 'collapse', width: '100%' }} >
                            <thead>
                                <tr>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '60px', textAlign: 'center' }}><strong>{t('type')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '150px', textAlign: 'center' }}><strong>{t('item')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('lot')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('container')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('bin')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('quantity')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('count')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('discreteWeightG')}</strong></th>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('totalWeightG')}</strong></th>
                                </tr>                            
                            </thead>
                            <tbody>
                                {record.cannabisOutputs != null && record.cannabisOutputs.length > 0 ?
                                    record.cannabisOutputs.map((output, i) => 
                                    <tr key={'cannabisOutputs-' + i}>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                            {output.containerType != null && output.containerType !== '' ?
                                            output.containerType : ''}
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                            {output.item != null && output.item !== '' && output.item.name != null && output.item.name !== '' ?
                                                output.item.name : ''
                                            }    
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                            {output.lot?.number != null && output.lot?.number !== '' ?
                                                output.lot.number : ''
                                            }
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                            {output.container != null && output.container !== '' && output.container.number != null && output.container.number !== '' ?
                                                output.container.number : ''
                                            }
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', maxWidth: '75px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                            {output.container?.bin?.code != null && output.container?.bin?.code !== '' ? output.container.bin.code : ''}
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                            {output.amount != null && output.amount !== '' && output.uom != null && output.uom !== '' ?
                                                output.amount + output.uom : ''
                                            }
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                            {output.count != null && output.count !== '' ? output.count : t('na')}
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                            {output.discreteWeight != null && output.discreteWeight !== '' ? output.discreteWeight : t('na')}
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                            {output.totalWeight != null && output.totalWeight !== '' ? output.totalWeight : t('na')}
                                        </td>
                                    </tr>
                                ) :
                                    <tr>
                                        <td colSpan={9} style={{ textAlign: 'center', border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px' }}>{t('none')}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </Grid>
                }
                {record.hasCannabis != null && record.hasCannabis !== '' && record.hasCannabis === true &&
                    <Grid item xs={12}>
                        <Divider sx={{ width: '80%', margin: '0 auto' }} />
                    </Grid>
                }
                <Grid item xs={12}>
                    <ReportSectionTitle title={record.hasCannabis != null && record.hasCannabis !== '' && record.hasCannabis === true ?
                        t('otherInputs') : t('inputs')
                    }/>
                    <table style={{ borderCollapse: 'collapse', width: '100%'}} >
                        <thead>
                            <tr>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '125px', textAlign: 'center' }}><strong>{t('item')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '100px', textAlign: 'center' }}><strong>{t('lot')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '100px', textAlign: 'center' }}><strong>{t('container')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '100px', textAlign: 'center' }}><strong>{t('bin')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('quantity')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('count')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('vendorLot')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('expiration')}</strong></th>
                            </tr>                            
                        </thead>
                        <tbody>
                            {record.otherInputs != null && record.otherInputs.length > 0 ?
                                record.otherInputs.map((input, i) => 
                                    <tr key={`otherInputRow-${i}`}>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                            {input.item != null && input.item !== '' && input.item.name != null && input.item.name !== '' ? input.item.name : t('na')}
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                            {input.lot?.number != null && input.lot?.number !== '' ? 
                                                input.lot.number : t('na')
                                            }    
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                            {input.container?.number != null && input.container?.number !== '' ? input.container.number : t('na')}    
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                            {input.container?.bin?.code != null && input.container?.bin?.code !== '' ? input.container.bin.code : t('na')}    
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                            {input.amount != null && input.amount !== '' && input.uom != null && input.uom !== '' ? 
                                                input.amount + input.uom : t('na')
                                            }
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                            {input.count != null && input.count !== '' ? input.count : t('na')}
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                            {input.vendorLot != null && input.vendorLot !== '' ? input.vendorLot : t('na')}
                                        </td>
                                        <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                            {input.expiration != null && input.expiration !== '' ? input.expiration : t('na')}
                                        </td>
                                    </tr>                          
                                ) :
                                    <tr key={'inputRow-noData'}>
                                        <td colSpan={9} style={{ textAlign: 'center', border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px' }}>{t('none')}</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </Grid>
                <Grid item xs={12}>
                    <ReportSectionTitle title={record.hasCannabis != null && record.hasCannabis !== '' && record.hasCannabis === true ?
                        t('otherOutputs') : t('outputs')
                    } />
                    <table style={{ borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <tr>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '60px', textAlign: 'center' }}><strong>{t('type')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '150px', textAlign: 'center' }}><strong>{t('item')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '100px', textAlign: 'center' }}><strong>{t('lot')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '100px', textAlign: 'center' }}><strong>{t('container')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '100px', textAlign: 'center' }}><strong>{t('bin')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('quantity')}</strong></th>
                                <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '9pt', width: '75px', textAlign: 'center' }}><strong>{t('count')}</strong></th>
                            </tr>                            
                        </thead>
                        <tbody>
                            {record.otherOutputs != null && record.otherOutputs.length > 0 ?
                                record.otherOutputs.map((output, i) => 
                                <tr key={'otherOutputs-' + i}>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                        {output.containerType != null && output.containerType !== '' ?
                                         output.containerType : ''}
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                        {output.item != null && output.item !== '' && output.item.name != null && output.item.name !== '' ?
                                            output.item.name : ''
                                        }    
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                        {output.container != null && output.container !== '' && output.container.lot != null && output.container.lot !== '' && 
                                         output.container.lot.number != null && output.container.lot.number !== '' ?
                                            output.container.lot.number : ''
                                        }
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px'}}>
                                        {output.container != null && output.container !== '' && output.container.number != null && output.container.number !== '' ?
                                            output.container.number : ''
                                        }
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', maxWidth: '75px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                            {output.container?.bin?.code != null && output.container?.bin?.code !== '' ? output.container.bin.code : ''}
                                        </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                        {output.amount != null && output.amount !== '' && output.uom != null && output.uom !== '' ?
                                            output.amount + output.uom : ''
                                        }
                                    </td>
                                    <td style={{ border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right' }}>
                                        {output.count != null && output.count !== '' ? output.count : t('na')}
                                    </td>
                                </tr>
                            ) :
                                <tr>
                                    <td colSpan={8} style={{ textAlign: 'center', border: '0.5px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px' }}>{t('none')}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ width: '80%', margin: '0 auto' }} />
                </Grid>
                {record.hasCannabis != null && record.hasCannabis !== '' && record.hasCannabis === true &&
                    <Grid item xs={12}>
                        <table style={{ borderCollapse: 'collapse', width: '50%' }} >
                            <thead>
                                <tr>
                                    <th style={{ borderBottom: '2px solid rgb(224, 224, 224)', fontSize: '10pt', width: '100%', textAlign: 'center' }} colSpan='2' ><strong>{t('cannabisProductionDetails')}</strong></th>
                                </tr>                            
                            </thead>
                            <tbody>
                                <tr key={'totalCannabisInputs'}>
                                    <td style={{ border: '2px solid rgb(224, 224, 224)', borderBottom: '4px double rgb(224, 224, 224)', fontSize: '9pt', padding: '5px', fontWeight: 'bold', textAlign: 'center', width: '50%'}}>
                                        {t('totalCannabisInputs') + ' (g)'}
                                    </td>
                                    <td style={{ border: '2px solid rgb(224, 224, 224)', borderBottom: '4px double rgb(224, 224, 224)', fontSize: '9pt', padding: '5px', fontWeight: 'bold', textAlign: 'right'}}>
                                        {record.inputCannabisTotal != null && record.inputCannabisTotal !== '' ?
                                            <NumericFormat displayType={'text'} value={record.inputCannabisTotal} thousandSeparator={true}
                                            decimalScale={3} fixedDecimalScale/> : ''
                                        } 
                                    </td>
                                </tr>
                                <tr key={'cannabisReturned'}>
                                    <td style={{ border: '2px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right'}}>
                                        {t('returnedTotal') + ' (g)'}
                                    </td>
                                    <td style={{ border: '2px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right'}}>
                                        {record.returnedTotal != null && record.returnedTotal !== '' ?
                                            <NumericFormat displayType={'text'} value={record.returnedTotal} thousandSeparator={true}
                                            decimalScale={3} fixedDecimalScale/> : t('none')
                                        }
                                    </td>
                                </tr>
                                <tr key={'totalAvailableCannabis'}>
                                    <td style={{ border: '2px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right'}}>
                                        {t('availableTotal') + ' (g)'}
                                    </td>
                                    <td style={{ border: '2px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right'}}>
                                        {record.availableCannabisTotal != null && record.availableCannabisTotal !== '' ?
                                            <NumericFormat displayType={'text'} value={record.availableCannabisTotal} thousandSeparator={true}
                                            decimalScale={3} fixedDecimalScale/> : t('none')
                                        }
                                    </td>
                                </tr>
                                <tr key={'sampleTotal'}>
                                    <td style={{ border: '2px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right'}}>
                                        {t('sampleTotal') + ' (g)'}
                                    </td>
                                    <td style={{ border: '2px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right'}}>
                                        {record.sampleTotal != null && record.sampleTotal !== '' ?
                                            <NumericFormat displayType={'text'} value={record.sampleTotal} thousandSeparator={true}
                                            decimalScale={3} fixedDecimalScale/> : t('none')
                                        }
                                    </td>
                                </tr>
                                <tr key={'wasteTotal'}>
                                    <td style={{ border: '2px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right'}}>
                                        {t('wasteTotal') + ' (g)'}
                                    </td>
                                    <td style={{ border: '2px solid rgb(224, 224, 224)', fontSize: '8pt', padding: '5px', textAlign: 'right'}}>
                                        {record.wasteTotal != null && record.wasteTotal !== '' ?
                                            <NumericFormat displayType={'text'} value={record.wasteTotal} thousandSeparator={true}
                                            decimalScale={3} fixedDecimalScale/> : 0
                                        }
                                    </td>
                                </tr>
                                <tr key={'totalCannabisOutputs'}>
                                    <td style={{ border: '2px solid rgb(224, 224, 224)', fontSize: '9pt', padding: '5px', fontWeight: 'bold', textAlign: 'center'}}>
                                        {t('totalCannabisOutputs') + ' (g)'}
                                    </td>
                                    <td style={{ border: '2px solid rgb(224, 224, 224)', fontSize: '9pt', padding: '5px', fontWeight: 'bold', textAlign: 'right'}}>
                                        {record.outputCannabisTotal != null && record.outputCannabisTotal !== '' ?
                                            <NumericFormat displayType={'text'} value={record.outputCannabisTotal} thousandSeparator={true}
                                            decimalScale={3} fixedDecimalScale/> : t('none')
                                        }
                                    </td>
                                </tr>
                                <tr key={'processingLoss'}>
                                    <td style={{ border: '2px solid rgb(224, 224, 224)', fontSize: '9pt', padding: '5px', fontWeight: 'bold', textAlign: 'center'}}>
                                        {t('processingLoss') + ' (%)'}
                                    </td>
                                    <td style={{ border: '2px solid rgb(224, 224, 224)', fontSize: '9pt', padding: '5px', fontWeight: 'bold', textAlign: 'right'}}>
                                        {record.processingLoss != null && record.processingLoss !== '' ?
                                            <NumericFormat displayType={'text'} value={record.processingLoss} thousandSeparator={true}
                                            decimalScale={3} fixedDecimalScale suffix='g' /> : t('none')
                                        }
                                        {record.processingLossPercent != null && record.processingLossPercent !== '' ?
                                            <NumericFormat displayType={'text'} value={record.processingLossPercent} thousandSeparator={true}
                                            decimalScale={3} fixedDecimalScale prefix=' (' suffix='%)' /> : t('none')
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                }
                {record.hasCannabis != null && record.hasCannabis !== '' && record.hasCannabis === true &&
                    <Grid item xs={12}>
                        <Divider sx={{ width: '80%', margin: '0 auto' }} />
                    </Grid>
                }
            </Grid>
            {record.lines != null && record.lines.length > 0 &&
                <Grid container spacing={2}>
                    {record.lines.map((line, i) => {
                        let activitySequence = ConversionHelper.numberToLetterConverter(i + 1);
                        return (
                            <Grid item xs={12} key={'activityRecord-' + i}>
                                <ProductionOrderActivity record={line.activity} t={t} printView={printView} 
                                order={record.number} constants={constants} dateFormat={dateFormat} 
                                sequence={sequence != null && sequence !== '' ? 
                                    sequence + '.' + activitySequence : i + 1
                                } />
                            </Grid>
                        )
                    })}
                </Grid>
            }
        </div>
    )
}