import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Signature from '../General/Signature';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import Unlock from '../General/Unlock';
import queryString from 'query-string';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField'
import ActionBar from '../UI/Buttons/ActionBar';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class DocumentReview extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            document: '',
            documents: [],
            status: '',
            statuses: [],
            reviewers: [],
            users: [],
            qas: [],
            documentVersion: '',
            details: '',
            signer: null,
            expiration: null,
            date: null,
            schedule: null,
            reviewerSel: [],
            docSel: '',
            signed: false,
            security: [],
            dialogOpen: false,
            enableSave: true,
            goBack: -1
        };
    }

    async componentDidMount(){
        let params = queryString.parse(this.props.location.search);
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        var values = await axios.get('/api/manageddocreviewvalues');
        var statusSel = values.data.statuses.find(x => x.value === this.props.auth.constants.documentReviewStatuses.inprogress);
        var docSel = null;
        var schedule = null;
        let goBack = -1;
        if(scheduleId != null){
            goBack = -2;
            schedule = await axios.get('/api/schedulebase/' + scheduleId);
            docSel = schedule.data.managedDocument != null ?
                        values.data.documents.find(x => x.value === schedule.data.managedDocument) : null;
        }
        this.setState({
            documents: values.data.documents,
            statuses: values.data.statuses,
            users: values.data.users,
            qas: values.data.qas,
            _id: this.props.match.params.id,
            statusSel: statusSel,
            status: this.props.auth.constants.documentReviewStatuses.inprogress,
            schedule: scheduleId,
            document: schedule != null && schedule.data.managedDocument != null ? schedule.data.managedDocument : null,
            docSel: docSel,
            security: this.props.permission,
            goBack: goBack
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/manageddocreviews', label: this.props.t('documentReviews'), screen: this.props.auth.screenDefs.DocumentReviews},
                    { path: 'active', label: this.props.t('documentReview'), screen: this.props.auth.screenDefs.DocumentReview}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        var record = await axios.get('/api/manageddocreview/' + this.state._id);
        var docSel = this.state.documents.find(x => x.value === record.data.document);
        var statusSel = this.state.statuses.find(x => x.value === record.data.status);
        var signed = record.data.signer != null;
        var reviewerSel = [];
        if(record.data.reviewers != null && record.data.reviewers.length > 0){
            record.data.reviewers.forEach(row => {
                var reviewer = this.state.users.find(x => x.value === row);
                reviewerSel.push(reviewer);
            });
        }
        this.setState({
            _id: record.data._id,
            document: record.data.document,
            status: record.data.status,
            reviewers: record.data.reviewers,
            date: record.data.date,
            details: record.data.details,
            signer: record.data.signer,
            expiration: record.data.expiration,
            schedule: record.data.schedule,
            documentVersion: record.data.documentVersion,
            docSel: docSel,
            statusSel: statusSel,
            signed: signed,
            reviewerSel: reviewerSel
        }, () => {
            const crumbs = [
                { path: '/#/manageddocreviews', label: this.props.t('documentReviews'), screen: this.props.auth.screenDefs.DocumentReviews},
                { path: 'active', label: docSel.label, screen: this.props.auth.screenDefs.DocumentReview}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeValue = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        if(name === "documentVersion"){
            if(isNaN(value)){
                return;
            }
        }
        this.setState({
            [name]: value
        });
    };

    changeAutoComplete = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeMulti = (name, data) => (e, newValue) => {
        var ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            [name]: newValue,
            [data]: ids
        })
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeNumber(name, e){
        this.setState({[name]: e.value});
    }

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        var data = {
            _id: this.state._id,
            date: this.state.date,
            status: this.state.status,
            reviewers: this.state.reviewers,
            document: this.state.document,
            documentVersion: this.state.documentVersion,
            details: this.state.details,
            expiration: this.state.expiration,
            schedule: this.state.schedule !== '' ? this.state.schedule : null
        }
        try {
            var result = await axios.post('/api/manageddocreview', data);
            this.setState({_id: result.data.id, enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    };

    validate(){
        let errors = {};
        const t = this.props.t;
        const state = this.state;
        if(state.document == null || state.document === '')
            errors[t('document')] = t('required');
        if(state.date == null || state.date === '')
            errors[t('date')] = t('required');
        if(state.reviewers == null || state.reviewers.length === 0)
            errors[t('reviewers')] = t('required');
        if(state.documentVersion == null || state.documentVersion === '')
            errors[t('documentVersion')] = t('required');
        if(state.expiration == null || state.expiration === '')
            errors[t('expiration')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    toggleDialog = () => {
        let dialogOpen = this.state.dialogOpen;
        this.setState({ dialogOpen: !dialogOpen });
    };

    render(){
        const errors = this.props.errors;
        const signed = this.state.signed;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar goBack={this.state.goBack}>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.statusSel != null ? this.state.statusSel.label : ''}  name="status"
                            size="medium" fullWidth={true} disabled={true} label={t('status')} data-cy='status'/>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.docSel}
                                options={this.state.documents}
                                onChange={this.changeAutoComplete('docSel', 'document')}
                                error={errors[t('document')] != null ? true : false}
                                helperText={errors[t('document')]} 
                                label={t('document')}
                                disabled={signed}
                                data-cy='document'
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.dateChange('date')}
                                value={this.state.date}
                                helperText={errors[t('date')]}
                                error={errors[t('date')] != null ? true : false}
                                disabled={signed}
                                label={t('date')}
                                format={this.props.auth.user.dateFormat}
                                data-cy='date'
                            />
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            <TextField value={this.state.documentVersion} onChange={this.changeValue} name="documentVersion"
                            size="medium" fullWidth={true} disabled={signed} label={t('version')} data-cy='documentVersion'
                            error={errors[t('documentVersion')] != null ? true : false} helperText={errors[t('documentVersion')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                id="date-picker-inline"
                                onChange={this.dateChange('expiration')}
                                value={this.state.expiration}
                                helperText={errors[t('expiration')]}
                                error={errors[t('expiration')] != null ? true : false}
                                disabled={signed}
                                label={t('expiration')}
                                format={this.props.auth.user.dateFormat}
                                data-cy='expiration'
                            />
                        </Grid>
                        <Grid item sm={7}>
                            <AutoCompleteField
                                multiple={true}
                                value={this.state.reviewerSel}
                                options={this.state.users}
                                onChange={this.changeMulti('reviewerSel', 'reviewers')}
                                error={errors[t('reviewers')] != null ? true : false}
                                helperText={errors[t('reviewers')]}
                                label={t('reviewers')}
                                disabled={signed}
                                data-cy='reviewers'
                            />
                        </Grid>
                        <Grid item sm={11}>
                            <TextField value={this.state.details} onChange={this.changeValue} id="details" name="details" multiline="true" rows="3"
                            variant="outlined" size="medium" fullWidth="true" disabled={signed} data-cy='details' label={t('details')}
                            error={errors[t('details')] != null ? true : false} helperText={errors[t('details')]}/>
                        </Grid>
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Signature users={this.state.users} name="Approver" status={this.state.status} data-cy='approver'
                            title={t('approverSignature')} setId={this.loadRecord} object={this.state._id} signature={this.state.signer}
                            objectType={this.props.auth.constants.objectIds.documentReview} type={this.props.auth.constants.signatureTypes.signer}
                            user={this.props.auth.user.id}
                        />
                    }
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            {!signed &&
                                <Aux>
                                    <Button variant="contained" disabled={!this.state.enableSave} color="primary" size="small" onClick={this.save} data-cy='save'>
                                        {t('save')}
                                    </Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {signed &&
                                <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.documentReview} callback={this.loadRecord} data-cy='unlock'/>
                            }
                        </Grid>
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.documentReview}/>
                            <ObjectLog id={this.state._id}/>
                        </Aux>
                    }
                </Panel>
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
            </Aux>
        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(DocumentReview));
