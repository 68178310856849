import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import TextField from '../UI/TextField/TextField';
import { Link } from 'react-router-dom';
import ActionBar from '../UI/Buttons/ActionBar';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import DateSelect from '../UI/DateSelect/DateSelect';
import common from '../../jsons/common.json';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class DataUploadList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            user: null,
            userSel: null,
            users: [],
            fileName: '',
            object: '',
            objectSel: null,
            objects: [],
            date: '',
            security: {},
            status: '',
            statusSel: null,
            statuses: []
        };
    }
    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.status, common.cacheValues.object, common.cacheValues.user], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({
            objects: cacheValues.objects,
            users: cacheValues.users,
            security: this.props.permission,
            statuses: cacheValues.statuses
        }, () => {
            this.loadRecords();
        });
    }

    async loadRecords(){
        const sort = {[this.state.orderBy]: this.state.ordDir};
        const data = {
            size: this.state.listSize,
            offset: this.state.offset,
            user: this.state.user,
            object: this.state.object,
            date: this.state.date,
            fileName: this.state.fileName,
            status: this.state.status,
            sort: JSON.stringify(sort)
        };
        const list = await axios.get('/int/datauploads', {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        }, () => {
            const crumbs = [
                { path: 'active', label: 'Data Upload List', screen: this.props.auth.screenDefs.DataUploadList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => this.loadRecords());
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    changeDate = (e) => {
        this.setState({date: e, page: 0, offset: 0}, () => this.loadRecords())
    }

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    handlePageClick = data => {
        const selected = data.selected;
        const offset = Math.ceil(selected * this.state.listSize);
    
        this.setState({ offset: offset }, () => {
          this.loadRecords();
        });
    };

    render(){
        const security = this.props.permission;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/dataupload'
                        createAccess={security.create}
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.changeDate}
                                value={this.state.date}
                                label='Date'
                                format={this.props.auth.user.dateFormat}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.fileName} onChange={this.changeValue} name="fileName"
                            size="medium" fullWidth={true} label="File Name"/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.objectSel}
                                options={this.state.objects}
                                onChange={this.changeAuto('objectSel', 'object')}
                                label="Object"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.userSel}
                                options={this.state.users}
                                onChange={this.changeAuto('userSel', 'user')}
                                label="User"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.statusSel}
                                options={this.state.statuses}
                                onChange={this.changeAuto('statusSel', 'status')}
                                label="Status"
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table axia-label="DataUploads">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('date')}
                                        active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Date
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('object')}
                                        active={this.state.orderBy === 'object'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Object
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('user')}
                                        active={this.state.orderBy === 'user'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>User
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('fileName')}
                                        active={this.state.orderBy === 'fileName'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>File Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('status')}
                                        active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>Status
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list != null && this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <Link to={'/dataupload/' + row._id}>
                                                <DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            {row.object.name}
                                        </TableCell>
                                        <TableCell>{row.user != null ? row.user.firstName + ' ' + row.user.lastName : ''}</TableCell>
                                        <TableCell>{row.fileName}</TableCell>
                                        <TableCell>{row.status}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(DataUploadList);