import React, { Component } from 'react';
import axios from 'axios';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class CleaningRecords extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: []
        }
    }

    async componentDidMount(){
        this.loadRecords();
    }

    loadRecords = async () => {
        if(this.props.id != null && this.props.id !== ''){
            var list = await axios.get('/api/roomcleanings?batch=' + this.props.id);
            this.setState({
                list: list.data.data
            });
        }
    }

    render(){
        const t = this.props.t;
        return(
            <Table aria-label="Cleaning Records">
                <TableHead>
                    <TableRow key="roomCleaningRecords-tableHeader">
                        <TableCell key='roomCleaningRecordsHeader-number'>{t('number')}</TableCell>
                        <TableCell key='roomCleaningRecordsHeader-type'>{t('type')}</TableCell>
                        <TableCell key='roomCleaningRecordsHeader-room'>{t('room')}</TableCell>
                        <TableCell key='roomCleaningRecordsHeader-date'>{t('completedOn')}</TableCell>
                        <TableCell key='roomCleaningRecordsHeader-status'>{t('status')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.list != null && this.state.list.length > 0 ?
                        this.state.list.map((row, i) =>
                            <TableRow key={row._id}>
                                <TableCell key={'roomCleaningRecords-number-' + i} ><Link to={'/roomcleaning/' + row._id}>{row.number}</Link></TableCell>
                                <TableCell key={'roomCleaningRecords-type-' + i}>{row.type.label}</TableCell>
                                <TableCell key={'roomCleaningRecords-room-' + i}>{row.room.name}</TableCell>
                                <TableCell key={'roomCleaningRecords-date-' + i}><DateDisplay format={this.props.auth.user.dateFormat} value={row.date}/></TableCell>
                                <TableCell key={'roomCleaningRecords-status-' + i}>{row.status.label}</TableCell>
                            </TableRow>
                        ): 
                        <TableRow key="roomCleaningRecords-empty">
                            <TableCell key='roomCleaningRecords-empty' colSpan={5} sx={{ textAlign: 'center' }}>{t('noData')}</TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(CleaningRecords));