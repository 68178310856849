import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
// import isEmpty from '../../is-empty';
import { withTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { NumericFormat } from 'react-number-format';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import Aux from '../../hoc/Auxilary/Auxilary';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import Panel from '../UI/Panel/Panel';
import Link from '../UI/Link/Link';
import ActionBar from '../UI/Buttons/ActionBar';
import PrintView from '../Printing/PrintView';
import DateDisplay from '../UI/DateDisplay/DateDisplay';

class Invoice extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            status: '',
            statusSel: {},
            statuses: [],
            customer: '',
            customerSel: {},
            customers: [],
            customerObj: {},
            date: '',
            location: '',
            locationSel: {},
            locations: [],
            amount: 0,
            gstRate: null,
            hstRate: null,
            taxRate: null,
            pstRate: null,
            taxAmount: 0,
            pstAmount: 0,
            gstAmount: 0,
            hstAmount: 0,
            totalAmount: 0,
            lines: [],
            shipments: [],
            sale: '',
            saleSel: {},
            sales: [],
            finalDocument: '',
            notes: '',
            terms: '',
            termsSel: {},
            saleTerms: [],
            remittanceDate: '',
            discountAmount: 0,
            preDiscount: 0,
            shippingCost: 0,
            flagForSignature: 0,
            optionsMenu: [],
            shipToBill: false,
            user: '',
            userSel: {},
            users: [],
            billingAddress: {},
            shippingAddress: {},
            printViewer: false,
            security: null,
            purchaseOrder: ''
        };
    }
    
    componentDidMount = async () => {
        const crumbs = [
            { path: '/#/invoices', label: this.props.t('invoices'), screen: this.props.auth.screenDefs.InvoiceList},
            { path: 'active', label: this.props.t('invoice'), screen: this.props.auth.screenDefs.Invoice}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.allItems, common.cacheValues.customer, common.cacheValues.activeLocation, common.cacheValues.status,
            common.cacheValues.saleForm, common.cacheValues.saleType, common.cacheValues.saleTerms], this.props.auth.constants, null, this.props.auth.user.tenant);
        const provs = await ValueHelper.getCanadianProvs();
        const countries = await ValueHelper.getCountries();
        const userValues = await ValueHelper.screenUsers(common.screens.invoice, this.props.auth.user.tenant);

        this.setState({
            provs: provs,
            countries: countries,
            statuses: cacheValues.statuses,
            customers: cacheValues.customers,
            locations: cacheValues.locations,
            saleTerms: cacheValues.saleTerms,
            users: userValues.create,
            security: this.props.permission
        }, () => {            
            this.loadRecord();
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        let result = await axios.get('/api/invoice/' + this.props.match.params.id);
        const statusSel = this.state.statuses.find(x => x.value === result.data.status);
        const customerSel = this.state.customers.find(x => x.value === result.data.customer);
        const locationSel = this.state.locations.find(x => x.value === result.data.location);
        const termsSel = this.state.saleTerms.find(x => x.value === result.data.terms);
        const userSel = this.state.users.find(x => x.value === result.data.user);
        if(result.data.shippingAddress != null && result.data.shippingAddress !== '') {
            result.data.shippingAddress.provSel = this.state.provs.find(x => x.value === result.data.shippingAddress.prov);
            result.data.shippingAddress.countrySel = this.state.countries.find(x => x.value === result.data.shippingAddress.country);
        }
        if(result.data.billingAddress != null && result.data.billingAddress !== '') {
            result.data.billingAddress.provSel = this.state.provs.find(x => x.value === result.data.billingAddress.prov);
            result.data.billingAddress.countrySel = this.state.countries.find(x => x.value === result.data.billingAddress.country);
        }
        const optionsMenu = [
            {
                security: this.props.permission.complete, user: this.props.auth.user.id, users: this.state.users, name: t('complete'), 
                title: t('completedBy'), setId: () => this.loadRecord(), signature: result.data.user, object: this.props.match.params.id,
                objectType: this.props.auth.constants.objectIds.invoice, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = result.data.status === this.props.auth.constants.statuses.inprogress ? 1 : 0;
        this.setState({
            _id: this.props.match.params.id,
            number: result.data.number,
            status: result.data.status,
            statusSel: statusSel,
            customer: result.data.customer,
            customerSel: customerSel,
            customerObj: result.data.customerObj,
            billingAddress: result.data.billingAddress,
            shippingAddress: result.data.shippingAddress,
            date: result.data?.date != null && result.data?.date !== '' ? result.data.date : new Date(),
            location: result.data.location,
            locationSel: locationSel,
            amount: result.data.amount,
            gstRate: result.data.gstRate,
            hstRate: result.data.hstRate,
            taxRate: result.data.taxRate,
            pstRate: result.data.pstRate,
            taxAmount: result.data.taxAmount,
            pstAmount: result.data.pstAmount,
            gstAmount: result.data.gstAmount,
            hstAmount: result.data.hstAmount,
            totalAmount: result.data.totalAmount,
            lines: result.data.lines,
            shipments: result.data.shipments,
            sale: result.data.sale,
            finalDocument: result.data.finalDocument,
            notes: result.data.notes,
            terms: result.data.terms,
            termsSel: termsSel,
            remittanceDate: result.data.remittanceDate,
            discountAmount: result.data.discountAmount,
            preDiscount: result.data.preDiscount,
            shippingCost: result.data.shippingCost,
            shipToBill: result.data.shipToBill != null ? result.data.shipToBill : false,
            user: result.data.user,
            purchaseOrder: result.data.purchaseOrder,
            userSel: userSel,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature
        }, () => {
            const crumbs = [
                { path: '/#/invoices', label: this.props.t('invoices'), screen: this.props.auth.screenDefs.InvoiceList},
                { path: 'active', label: this.props.t('invoice') + ' - ' + result.data.number, screen: this.props.auth.screenDefs.Invoice}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    }

    togglePrintViewer = () => {
        this.setState({printViewer: !this.state.printViewer});
    }

    render() {
        const { security } = this.state;
        const { t } = this.props;
        return (
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        constants={this.props.auth.constants}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        printViewClick={this.togglePrintViewer}
                        printViewTitle={t('invoice')}
                        enablePrintView={this.state.status === this.props.auth.constants.statuses.signed}
                    />
                </BreadcrumbBar>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <Panel>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl data-cy='status'>
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.statusSel?.label != null ? this.state.statusSel.label : ''}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl data-cy='date'>
                                        <FormLabel>{t('date')}</FormLabel>
                                        {this.state.remittanceDate != null ? 
                                            <DateDisplay value={this.state.remittanceDate} format={this.props.auth.user.dateFormat} /> : ''
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl data-cy='sale'>
                                        <FormLabel>{t('sale')}</FormLabel>
                                        {this.state.sale != null && this.state.sale !== '' ? 
                                            <Link href={'/#/sale/' + this.state.sale._id}>{this.state.sale.number}</Link> : ''
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl data-cy='purchaseOrder'>
                                        <FormLabel>{t('purchaseOrder')}</FormLabel>
                                        {this.state.purchaseOrder != null && this.state.purchaseOrder !== '' ?
                                            this.state.purchaseOrder : t('none')
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl data-cy='paymentTerms'>
                                        <FormLabel>{t('terms')}</FormLabel>
                                        {this.state.termsSel?.label != null && this.state.termsSel?.label !== '' ?
                                            this.state.termsSel.label : t('none')
                                        }
                                    </FormControl>
                                </Grid>
                            </Grid>    
                        </Panel>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Panel>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl data-cy='customer'>
                                        <FormLabel>{t('customer')}</FormLabel>
                                        {this.state.customerObj?.name != null && this.state.customerObj?.name !== '' ?
                                            <Link href={'/#/customer/' + this.state.customer}>{this.state.customerObj.name}</Link> : 
                                         this.state.customerObj?.firstName != null && this.state.customerObj?.firstName !== '' &&
                                         this.state.customerObj?.lastName != null && this.state.customerObj?.lastName !== '' ?
                                            <Link href={'/#/customer/' + this.state.customer}>
                                                {this.state.customerObj.firstName + ' ' + this.state.customerObj.lastName}
                                            </Link> : ''
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl data-cy='billingAddress'>
                                        <FormLabel>{t('billTo')}</FormLabel>
                                        <span style={{ display: 'block', fontSize: '9pt', fontWeight: 'bold', textAlign: 'left', marginBottom: '0px'}}>{this.state.customerObj?.name != null ? this.state.customerObj.name : ''}</span>
                                        {this.state.customerObj?.licenseNumber != null && this.state.customerObj?.licenseNumber !== '' &&
                                            <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>
                                                {t('licenseNumber') + '# ' + this.state.customerObj.licenseNumber}
                                            </span>
                                        }                    
                                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{this.state.billingAddress?.address1 != null ? this.state.billingAddress.address1 : ''}</span>
                                        {this.state.billingAddress?.address2 != null && this.state.billingAddress?.address2 !== '' &&
                                            <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{this.state.billingAddress.address2}</span>
                                        }
                                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{this.state.billingAddress?.city != null ? this.state.billingAddress?.city + ', ' + this.state.billingAddress?.provSel?.name  : ''}</span>
                                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{this.state.billingAddress?.postal != null ? this.state.billingAddress.postal : ''}</span>
                                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{this.state.billingAddress?.country != null ? this.state.billingAddress.countrySel.label : ''}</span>
                                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}><NumericFormat displayType={'text'} value={this.state.customerObj?.phoneNumber} format='###-###-####'/></span>
                                        {this.state.customerObj?.gstNumber != null && this.state.customerObj?.gstNumber !== '' &&
                                            <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>
                                                <strong>{t('gst') + ': '}</strong>{this.state.customerObj.gstNumber}
                                            </span>
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl data-cy='shippingAddress'>
                                        <FormLabel>{t('shipTo')}</FormLabel>
                                        <span style={{ display: 'block', fontSize: '9pt', fontWeight: 'bold', textAlign: 'left', marginBottom: '0px'}}>
                                            {this.state.customerObj?.name != null ? this.state.customerObj.name : ''}
                                        </span>
                                        {this.state.customerObj?.licenseNumber != null && this.state.customerObj?.licenseNumber !== '' &&
                                            <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>
                                                {t('licenseNumber') + '# ' + this.state.customerObj.licenseNumber}
                                            </span>
                                        }                    
                                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>
                                            {this.state.shippingAddress?.address1 != null ? this.state.shippingAddress.address1 : 
                                             this.state.shipToBill && this.state.billingAddress?.address1 != null ? this.state.billingAddress.address1 : ''}
                                        </span>
                                        {this.state.shippingAddress?.address2 != null && this.state.shippingAddress?.address2 !== '' ?
                                            <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{this.state.shippingAddress.address2}</span> :
                                         this.state.shipToBill && this.state.billingAddress?.address2 != null && this.state.billingAddress?.address2 !== '' ?
                                            <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>{this.state.billingAddress.address2}</span> : ''
                                        }
                                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>
                                            {this.state.shippingAddress?.city != null ? 
                                                this.state.shippingAddress?.city + ', ' + this.state.shippingAddress?.provSel?.name  : 
                                             this.state.shipToBill && this.state.billingAddress?.city != null ?
                                                this.state.billingAddress?.city + ', ' + this.state.billingAddress?.provSel?.name  : ''
                                            }
                                        </span>
                                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>
                                            {this.state.shippingAddress?.postal != null ? this.state.shippingAddress.postal : 
                                             this.state.shipToBill && this.state.billingAddress?.postal != null ? this.state.billingAddress.postal : ''
                                            }
                                        </span>
                                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>
                                            {this.state.shippingAddress?.country != null ? this.state.shippingAddress.countrySel.label : 
                                             this.state.shipToBill && this.state.billingAddress?.country != null ? this.state.billingAddress.countrySel.label : ''
                                            }
                                        </span>
                                        <span style={{ display: 'block', fontSize: '9pt', textAlign: 'left', marginBottom: '0px'}}>
                                            <NumericFormat displayType={'text'} value={this.state.customerObj?.phoneNumber} format='###-###-####'/>
                                        </span>
                                    </FormControl>
                                </Grid>
                            </Grid>    
                        </Panel>
                    </Grid>
                    <Grid item xs={12}>
                        <Panel>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{t('item')}</TableCell>
                                                    <TableCell>{t('lot')}</TableCell>
                                                    <TableCell sx={{ textAlign: 'right '}}>{t('quantity')}</TableCell>
                                                    <TableCell sx={{ textAlign: 'right '}}>{t('price')}</TableCell>
                                                    <TableCell sx={{ textAlign: 'right '}}>{t('tax')}</TableCell>
                                                    <TableCell sx={{ textAlign: 'right '}}>{t('total')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.lines.map((line, index) => (
                                                    <Aux key={'invoiceLine-' + index}>
                                                        <TableRow key={index}>
                                                            <TableCell>{line.item?.name}</TableCell>
                                                            <TableCell>
                                                                {line.lot?.number != null && line.lot?.number !== '' ? line.lot.number : 
                                                                 line.container
                                                                }                                                            
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'right '}}>{line.quantity}</TableCell>
                                                            <TableCell sx={{ textAlign: 'right '}}>
                                                                <NumericFormat displayType={'text'} value={line.price} thousandSeparator={true} 
                                                                    decimalScale={2} fixedDecimalScale={true} prefix='$'/>
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'right '}}>
                                                                <NumericFormat displayType={'text'} value={line.tax != null && line.tax !== '' ? line.tax : 0} thousandSeparator={true} 
                                                                    decimalScale={2} fixedDecimalScale={true} prefix='$'/>
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'right '}}>
                                                                <NumericFormat displayType={'text'} value={line.total != null && line.total !== '' ? line.total : 0} thousandSeparator={true} 
                                                                    decimalScale={2} fixedDecimalScale={true} prefix='$'/>    
                                                            </TableCell>
                                                        </TableRow>
                                                        {index === this.state.lines.length - 1 &&
                                                            <Aux key='invoiceTotals'>
                                                                <TableRow sx={{ "&&": { bgcolor: "#FFF" }, border: null }}>
                                                                    <TableCell colSpan={3} sx={{ textAlign: 'left '}}>
                                                                        {this.state.notes != null && this.state.notes !== '' ? this.state.notes : ''}
                                                                    </TableCell>
                                                                    <TableCell colSpan={3} sx={{ textAlign: 'right' }}>
                                                                        <div style={{ width: '40%', display: 'inline-block', fontWeight: 'bold', fontSize: '9pt'}}>
                                                                            {this.state.discountAmount != null && this.state.discountAmount > 0 && <>{t('totalAmount')}<br /></>}
                                                                            {this.state.discountAmount != null && this.state.discountAmount > 0 && <>{t('discount')}<br /></>}
                                                                            {t('subTotal')}<br />
                                                                            {this.state.hstAmount != null && this.state.hstAmount > 0 && <>{t('hst')}<br /></>}
                                                                            {(this.state.hstAmount == null || this.state.hstAmount === '' || this.state.hstAmount === 0) && 
                                                                                this.state.gstAmount != null && this.state.gstAmount > 0 && <>{t('gst')}<br /></>}
                                                                            {(this.state.hstAmount == null || this.state.hstAmount === '' || this.state.hstAmount === 0) && 
                                                                                this.state.pstAmount != null && this.state.pstAmount > 0 && <>{this.state.isQC != null && this.state.isQC === true ? t('qst') : t('pst')}<br /></>}
                                                                            {this.state.shippingCost != null && this.state.shippingCost > 0 && <>{t('shipping')}<br /></>}
                                                                            {t('total')}
                                                                        </div>
                                                                        <div style={{ width: '60%', display: 'inline-block', textAlign: 'right', fontSize: '9pt'}}>
                                                                            {this.state.discountAmount != null && this.state.discountAmount > 0 &&
                                                                                <><NumericFormat displayType={'text'} value={this.state.preDiscount} thousandSeparator={true} 
                                                                                fixedDecimalScale={true} decimalScale={2} prefix='$'/><br /></>
                                                                            }
                                                                            {this.state.discountAmount != null && this.state.discountAmount > 0 &&
                                                                                <><NumericFormat displayType={'text'} value={this.state.discountAmount} thousandSeparator={true} 
                                                                                fixedDecimalScale={true} decimalScale={2} prefix='$'/><br /></>
                                                                            }
                                                                            <NumericFormat displayType={'text'} value={this.state.amount} thousandSeparator={true} 
                                                                            fixedDecimalScale={true} decimalScale={2} prefix='$'/><br />
                                                                            {this.state.hstAmount != null && this.state.hstAmount > 0 &&
                                                                                <>
                                                                                    <NumericFormat displayType={'text'} value={this.state.hstAmount} thousandSeparator={true} 
                                                                                    fixedDecimalScale={true} decimalScale={2} prefix='$'/><br />
                                                                                </>
                                                                            }
                                                                            {(this.state.hstAmount == null || this.state.hstAmount === '' || this.state.hstAmount === 0) && 
                                                                            this.state.gstAmount != null && this.state.gstAmount !== '' &&
                                                                                <>
                                                                                    <NumericFormat displayType={'text'} value={this.state.gstAmount} thousandSeparator={true} 
                                                                                    fixedDecimalScale={true} decimalScale={2} prefix='$'/><br />
                                                                                </>
                                                                            }
                                                                            {(this.state.hstAmount == null || this.state.hstAmount === '' || this.state.hstAmount === 0) && 
                                                                            this.state.pstAmount != null && this.state.pstAmount !== '' && this.state.pstAmount !== 0 &&
                                                                                <>
                                                                                    <NumericFormat displayType={'text'} value={this.state.pstAmount} thousandSeparator={true} 
                                                                                    fixedDecimalScale={true} decimalScale={2} prefix='$'/><br />
                                                                                </>
                                                                            }
                                                                            {this.state.shippingCost != null && this.state.shippingCost > 0 && 
                                                                                <>
                                                                                    <NumericFormat displayType={'text'} value={this.state.shippingCost} thousandSeparator={true}
                                                                                    fixedDecimalScale={true} decimalScale={2} prefix='$'/><br />
                                                                                </>
                                                                            }
                                                                            <NumericFormat displayType={'text'} value={this.state.totalAmount} 
                                                                            thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} prefix='$'/>
                                                                        </div>
                                                                    </TableCell>
                                                                    {/* <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                                        <NumericFormat 
                                                                            displayType={'text'} 
                                                                            value={this.state.amount != null && this.state.amount !== '' ? this.state.amount : 0} 
                                                                            thousandSeparator={true} 
                                                                            decimalScale={2} 
                                                                            fixedDecimalScale={true} 
                                                                            prefix='$'
                                                                        />  
                                                                    </TableCell> */}
                                                                </TableRow>
                                                                {/* <TableRow sx={{ "&&": { bgcolor: "#FFF" }, borderStyle: 'hidden' }}>
                                                                    <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                                        {t('tax')}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                                        <NumericFormat 
                                                                            displayType={'text'} 
                                                                            value={this.state.taxAmount != null && this.state.taxAmount !== '' ? this.state.taxAmount : 0} 
                                                                            thousandSeparator={true} 
                                                                            decimalScale={2} 
                                                                            fixedDecimalScale={true} 
                                                                            prefix='$'
                                                                        />  
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow sx={{ "&&": { bgcolor: "#FFF" }, borderStyle: 'hidden' }}>
                                                                    <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                                        {t('total')}
                                                                    </TableCell>
                                                                    <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                                                                        <NumericFormat 
                                                                            displayType={'text'} 
                                                                            value={this.state.totalAmount != null && this.state.totalAmount !== '' ? this.state.totalAmount : 0} 
                                                                            thousandSeparator={true} 
                                                                            decimalScale={2} 
                                                                            fixedDecimalScale={true} 
                                                                            prefix='$'
                                                                        />  
                                                                    </TableCell>
                                                                </TableRow> */}
                                                            </Aux>
                                                        }
                                                    </Aux>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Panel>
                    </Grid>
                </Grid>
                {this.state.printViewer &&
                    <PrintView
                        id={this.state._id}
                        object={this.props.auth.constants.objectIds.invoice}
                        open={this.state.printViewer}
                        onClose={this.togglePrintViewer}
                        callBack={this.loadRecord}
                        printAccess={security.print}
                    />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile
});

export default withTranslation() (connect(mapStateToProps)(Invoice));