import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class LocationList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            listSize: 10,
            pageCount: 1,
            offset: 0,
            orderBy: 'name',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            edit: this.props.auth.user.isSuper || this.props.auth.user.isAdmin ? true : false,
            security: []
        }
    }

    async componentDidMount(){
        this.loadRecords();
    }

    async loadRecords(){
        var sort = {[this.state.orderBy]: this.state.ordDir};
        var data = {
            offset: this.state.offset,
            size: this.state.listSize,
            sort: JSON.stringify(sort)
        }
        axios.get('/api/locationlist', {params: data})
        .then(locs => {
            this.setState({
                list: locs.data.data,
                pageCount: Math.ceil(locs.data.totalCount / this.state.listSize),
                totalCount: locs.data.totalCount,
                security: this.props.permission
            })
        })
        .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        });
        const crumbs = [
            { path: 'active', label: this.props.t('locations'), screen: this.props.auth.screenDefs.LocationList}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
    }

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    searchClick(e){
        this.loadRecords();
    }

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/location'
                        createAccess={security.create}
                    />
                </BreadcrumbBar>
                <Panel>
                <TableContainer component={Paper}>
                    <Table aria-label="Location List">
                        <TableHead>
                            <TableRow key="tableHeader">
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('name')}
                                    active={this.state.orderBy === 'name'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('name')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel onClick={() => this.sortClick('city')}
                                    active={this.state.orderBy === 'city'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('city')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>License Type</TableCell>
                                {this.state.edit &&
                                    <TableCell></TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list.map((row, i) =>
                                <TableRow key={row._id}>
                                    <TableCell><Link to={'/locationdetail/' + row._id} name={'locationLink' + i}>{row.name}</Link></TableCell>
                                    <TableCell>{row.city}</TableCell>
                                    <TableCell>{row.licenseType != null ? row.licenseType.label : ''}</TableCell>
                                    {this.state.edit &&
                                        <TableCell>
                                            <Link to={'/location/' + row._id}>Edit</Link>
                                        </TableCell>
                                    }
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(LocationList));