import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import { CSVDownload } from "react-csv";
import dateFormat from 'date-fns/format';
import AddIcon from '@mui/icons-material/Add';
import { Grid, FormControl, FormLabel} from '@mui/material';
import Typography from '@mui/material/Typography';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, TablePagination, Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete';
import Excel from 'exceljs';
import fileDownload from 'js-file-download';
import ActionBar from '../UI/Buttons/ActionBar';
import ConversionHelper from '../../helpers/conversionHelper';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';
import listHelper from '../../helpers/listHelper';
import ListMultiSelect from '../UI/ListMultiSelect/ListMultiSelect';

const Receipts = (props) => {
    const isFirstRender = useRef(true);
    const [loadRefData, setLoadRefData] = useState(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({'location': false});
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [csvOutput, setCsvOutput] = useState({headers: null, data: null});
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [columnOrder, setColumnOrder] = useState([]);
    const [contentPage, setContentPage] = useState(0);
    const [contentListSize, setContentListSize] = useState(5);
    const [values, setValues] = useState({items: [], customers: [], vendors: [], locations: [], types: [], statuses: []});
    const [ itemSel, setItemSel ] = useState(null);
    const [ vendor, setVendor ] = useState(null);
    const [ customer, setCustomer ] = useState(null);
    const { t } = useTranslation('translation');
    const tenant = props.auth.user.tenant;
    const csvSeparator = sessionStorage.getItem('csvSeparator');
    const [statusesSel, setStatusesSel] = useState({ids: [], values: []});
    const [showSelect, setShowSelect] = useState(false);
    const crumbs = [
        { path: 'active', label: t('receipts'), screen: props.auth.screenDefs.ReceiptList}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        async function fetchData(){
            const columnFilters = await listHelper.getItem('ReceiptsColumnFilters', tenant);
            const columnVisibility = await listHelper.getItem('ReceiptsColumnVisibility', tenant);
            const density = await listHelper.getItem('ReceiptsDensity', tenant);
            const globalFilter = await listHelper.getItem('ReceiptsGlobalFilter', tenant);
            const showGlobalFilter = await listHelper.getItem('ReceiptsShowGlobalFilter', tenant);
            const showColumnFilters = await listHelper.getItem('ReceiptsShowColumnFilters', tenant);
            const sorting = await listHelper.getItem('ReceiptsSorting', tenant);
            const pagination = await listHelper.getItem('ReceiptsPagination', tenant);
            let tempColumnOrder = await listHelper.getItem('ReceiptsColumnOrder', tenant);
            if (columnFilters) {
                let filters = ConversionHelper.filtersDateParse(JSON.parse(columnFilters));
                let date = filters.find(x => x.id === 'date');
                if(date != null && date.value == null){
                    date.value = [];
                }
                setColumnFilters(filters);
                let item = filters.find(x => x.id === 'item');
                if(item != null && values.items != null && values.items.length > 0) {
                    if(item.value != null){
                        let sel = values.items.find(x => x.value === item.value);
                        setItemSel(sel);
                    }
                }
                let vendor = filters.find(x => x.id === 'vendor');
                if(vendor != null && values.vendors != null && values.vendors.length > 0) {
                    if(vendor.value != null){
                        let sel = values.vendors.find(x => x.value === vendor.value);
                        setVendor(sel);
                    }
                }
                let customer = filters.find(x => x.id === 'customer');
                if(customer != null && values.customers != null && values.customers.length > 0) {
                    if(customer.value != null){
                        let sel = values.customers.find(x => x.value === customer.value);
                        setCustomer(sel);
                    }
                }
                let status = filters.find(x => x.id === 'status');
                if(status != null){
                    let sel = {ids: status.value, values: []};
                    status.value.forEach(id => {
                        let val = values.statuses.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setStatusesSel(sel);
                }
            }
            if (columnVisibility) {
                setColumnVisibility(columnVisibility);
            }
            if (density) {
                setDensity(density);
            }
            if (globalFilter) {
                setGlobalFilter(globalFilter);
            }
            if (showGlobalFilter) {
                setShowGlobalFilter(showGlobalFilter);
            }
            if (showColumnFilters) {
                setShowColumnFilters(showColumnFilters);
            }
            if (sorting) {
                setSorting(sorting);
            }
            if (pagination) {
                setPagination(pagination);
            }
            if (tempColumnOrder) {
                setColumnOrder(tempColumnOrder);
            } else {
                setColumnOrder(['mrt-row-expand', 'number', 'date', 'status', 'type', 'purchaseOrder', 'vendor', 'customer', 'location', 'item']);
            }
            isFirstRender.current = false;
        }
        if(isFirstRender.current){
            fetchData();
        }
    }, [values, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ReceiptsColumnFilters', tenant, JSON.stringify(columnFilters));
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ReceiptsColumnVisibility', tenant, columnVisibility);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnVisibility, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ReceiptsDensity', tenant, density);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [density, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ReceiptsGlobalFilter', tenant, globalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [globalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ReceiptsShowGlobalFilter', tenant, showGlobalFilter);
        }
        if (isFirstRender.current) return;
        fetchData()
    }, [showGlobalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ReceiptsShowColumnFilters', tenant, showColumnFilters);
        }
        if (isFirstRender.current) return;
        fetchData();

    }, [showColumnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ReceiptsSorting', tenant, sorting);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [sorting, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ReceiptsPagination', tenant, pagination);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [pagination, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('ReceiptsColumnOrder', tenant, columnOrder);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnOrder, tenant]);

    const resetState = () => {
        listHelper.removeItem('ReceiptsColumnFilters', tenant);
        listHelper.removeItem('ReceiptsColumnVisibility', tenant);
        listHelper.removeItem('ReceiptsDensity', tenant);
        listHelper.removeItem('ReceiptsGlobalFilter', tenant);
        listHelper.removeItem('ReceiptsShowGlobalFilter', tenant);
        listHelper.removeItem('ReceiptsShowColumnFilters', tenant);
        listHelper.removeItem('ReceiptsSorting', tenant);
        listHelper.removeItem('ReceiptsPagination', tenant);
        listHelper.removeItem('ReceiptsColumnOrder', tenant);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.allItems, common.cacheValues.customer, common.cacheValues.vendor,
                common.cacheValues.activeLocation, common.cacheValues.receiptType], props.auth.constants, null, props.auth.user.tenant);
            const statuses = await ValueHelper.getStatusesForObject(props.auth.constants.objectIds.receipt, props.auth.user.tenant);
            let item = columnFilters.find(x => x.id === 'item');
            if(item != null) {
                if(item.value != null){
                    let sel = cacheValues.items.find(x => x.value === item.value);
                    setItemSel(sel);
                }
            }
            let vendor = columnFilters.find(x => x.id === 'vendor');
            if(vendor != null && cacheValues.vendors != null && cacheValues.vendors.length > 0) {
                if(vendor.value != null){
                    let sel = cacheValues.vendors.find(x => x.value === vendor.value);
                    setVendor(sel);
                }
            }
            let customer = columnFilters.find(x => x.id === 'customer');
            if(customer != null && cacheValues.customers != null && cacheValues.customers.length > 0) {
                if(customer.value != null){
                    let sel = cacheValues.customers.find(x => x.value === customer.value);
                    setCustomer(sel);
                }
            }
            let status = columnFilters != null ? columnFilters.find(x => x.id === 'status') : null;
            if(status != null){
                let sel = {ids: status.value, values: []};
                status.value.forEach(id => {
                    let val = statuses.find(x => x.value === id);
                    sel.values.push(val);
                });
                setStatusesSel(sel);
            }
            setValues({
                items: cacheValues.allItems,
                locations: cacheValues.locations,
                customers: cacheValues.customers,
                vendors: cacheValues.vendors,
                types: cacheValues.receiptTypes,
                statuses: statuses
            });
            setLoadRefData(false);
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, columnFilters, loadRefData]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'receipts',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/receipts', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });

    let columns = useMemo(() => [
        {
            accessorKey: 'number',
            id: 'number',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/receipt/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            )
        },
        {
            accessorKey: 'date',
            id: 'date',
            header: t('date'),
            filterVariant: 'date-range',
            Cell: ({ cell, column }) => (
                <Box>
                    {dateFormat(Date.parse(cell.getValue()), props.auth.user.dateFormat)}
                </Box>
            )
        },
        {
            accessorKey: 'status',
            id: 'status',
            header: t('status'),
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='status'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.statuses} 
                    setSelected={setStatusesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={statusesSel.ids} 
                    selectedSel={statusesSel.values}
                    columnFilters={columnFilters}
                    screen='Receipts'
                    tenant={props.auth.user.tenant}/>
                </Box>
            ),
        },
        {
            accessorKey: 'type',
            id: 'type',
            header: t('type'),
            filterVariant: 'select',
            filterSelectOptions: values.types
        },
        {
            accessorFn: (row) => (row.purchaseOrder != null ? row.purchaseOrder.number : ''),
            id: 'purchaseOrder',
            header: t('purchaseOrder')
        },
        {
            accessorFn: (row) => (row.vendor != null ? row.vendor.number + '-' + row.vendor.name : ''),
            id: 'vendor',
            header: t('vendor'),
            Filter: ({ header, column }) =>
                <AutoComplete 
                    options={values.vendors}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, newValue) => {
                        setVendor(newValue);
                        column.setFilterValue(newValue != null ? newValue.value : null);
                    }}
                    value={vendor}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                />,
        },
        {
            accessorFn: (row) => (row.customer != null ? row.customer.number + '-' + row.customer.name : ''),
            id:'customer',
            header: t('customer'),
            Filter: ({ header, column }) =>
                <AutoComplete 
                    options={values.customers}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, newValue) => {
                        setCustomer(newValue);
                        column.setFilterValue(newValue != null ? newValue.value : null);
                    }}
                    value={customer}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                />,
        },
        {
            accessorKey: 'location.name',
            id: 'location',
            header: t('location'),
            filterVariant: 'select',
            filterSelectOptions: values.locations
        },
        {
            accessorKey: 'itemCount',
            id: 'item',
            header: t('items'),
            Filter: ({ header, column }) =>
                <AutoComplete 
                    options={values.items}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, newValue) => {
                        setItemSel(newValue);
                        column.setFilterValue(newValue != null ? newValue.value : null);
                    }}
                    value={itemSel}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                />,
        }
    ], [values, t, itemSel, vendor, customer, props, statusesSel, showSelect, columnFilters]);

    const csv = async () => {
        setCsvOutput({headers: null, data: null});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axios.get('/api/receiptscsv', {params: params});
        let headers = response.data.headers;
        let csvData = response.data.data;
        setCsvOutput({headers: headers, data: csvData});
    }

    const recordPrint = (startRow, record, sheet, t) => {
        let currRow = startRow;
        sheet.mergeCells('A' + currRow + ':J' + currRow);
        sheet.getCell('A' + currRow).value = record.number;
        currRow++
        sheet.getCell('A' + currRow).value = t('type');
        sheet.getCell('B' + currRow).value = t('date');
        sheet.getCell('C' + currRow).value = t('Status');
        sheet.getCell('D' + currRow).value = t('location');
        sheet.getCell('E' + currRow).value = t('courier');
        sheet.getCell('F' + currRow).value = t('shippingNumber');
        if(record.vendor != null){
            sheet.getCell('G' + currRow).value = t('vendor');
        } else if(record.customer != null){
            sheet.getCell('G' + currRow).value = t('customer');
        }
        if(record.purchaseOrder != null){
            sheet.getCell('H' + currRow).value = t('purchaseOrder');
        } else if (record.sale != null){
            sheet.getCell('H' + currRow).value = t('sale');
        }
        currRow++;
        sheet.getCell('A' + currRow).value = record.type;
        sheet.getCell('B' + currRow).value = dateFormat(new Date(record.date), props.auth.user.dateFormat);
        sheet.getCell('C' + currRow).value = record.status;
        sheet.getCell('D' + currRow).value = record.location != null ? record.location.name : '';
        sheet.getCell('E' + currRow).value = record.courier;
        sheet.getCell('F' + currRow).value = record.shippingNumber;
        if(record.vendor != null){
            sheet.getCell('G' + currRow).value = record.vendor.number + '-' + record.vendor.name;
        } else if(record.customer != null){
            sheet.getCell('G' + currRow).value = record.customer.number + '-' + record.customer.name;
        }
        if(record.purchaseOrder != null){
            sheet.getCell('H' + currRow).value = record.purchaseOrder.number;
        } else if (record.sale != null){
            sheet.getCell('H' + currRow).value = record.sale.number;
        }
        currRow++
        currRow++;
        sheet.getCell('A' + currRow).value = t('contents');
        currRow++
        sheet.getCell('A' + currRow).value = t('item');
        sheet.getCell('B' + currRow).value = t('quantity');
        sheet.getCell('C' + currRow).value = t('uom');
        sheet.getCell('D' + currRow).value = t('count');
        sheet.getCell('E' + currRow).value = t('container');
        sheet.getCell('F' + currRow).value = t('lot');
        sheet.getCell('G' + currRow).value = t('bin');
        sheet.getCell('H' + currRow).value = t('vendorLot');
        sheet.getCell('I' + currRow).value = t('expires');
        sheet.getCell('J' + currRow).value = t('totalWeight');
        sheet.getCell('K' + currRow).value = t('uom');
        sheet.getCell('L' + currRow).value = t('discreteWeight');
        currRow++;
        for(let row of record.contents){
            sheet.getCell('A' + currRow).value = row.item.number + '-' + row.item.name;
            sheet.getCell('B' + currRow).value = row.quantity;
            sheet.getCell('C' + currRow).value = row.uom;
            sheet.getCell('D' + currRow).value = row.count != null ? row.count : '';
            sheet.getCell('E' + currRow).value = row.container != null ? row.container.number : '';
            sheet.getCell('F' + currRow).value = row.lot != null ? row.lot.number : '';
            sheet.getCell('G' + currRow).value = row.bin != null ? row.bin.code : '';
            sheet.getCell('H' + currRow).value = row.vendorLot;
            sheet.getCell('I' + currRow).value = row.lotExpiration != null ? dateFormat(new Date(row.lotExpiration), props.auth.user.dateFormat) : '';
            if(row.totalWeight != null && row.totalWeight !== ''){
                sheet.getCell('J' + currRow).value = row.totalWeight;
                sheet.getCell('K' + currRow).value = row.totalUOM;
                sheet.getCell('L' + currRow).value = row.discreteWeight;
            }
            currRow++
        }
        return currRow;
    }

    const download = async (e) => {
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? []),
        };
        let list = await axios.get('/api/receiptsexport', {params: params});
        let excelData = list.data;
        const workbook = new Excel.Workbook();
        workbook.created = new Date();
        workbook.properties.date1904 = true;
        workbook.views = [
            {
            x: 0, y: 0, width: 25000, height: 15000,
            firstSheet: 0, activeTab: 1, visibility: 'visible'
            }
        ];
        const sheet = workbook.addWorksheet('Receipts');
        sheet.state = 'visible';
        let startRow = 1;
        for(let record of excelData){
            startRow = recordPrint(startRow, record, sheet, t)
            sheet.getCell('A' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('B' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('C' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('D' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('E' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('F' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('G' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('H' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('I' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('J' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('K' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('L' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            sheet.getCell('M' + startRow).border = {top: {style: 'thick', color: {argb:'00000000'}}};
            startRow++;
        }
        let buffer = await workbook.xlsx.writeBuffer();
        fileDownload(buffer, 'EvoraReceipts.xlsx');
    };

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true,  columnVisibility: {id: false}},
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: false,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()}>
                        <RefreshIcon color="primary"/>
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('csv')}>
                    <IconButton onClick={() => csv()}>
                        <ExcelIcon color='primary'/>
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('export')}>
                    <Button onClick={(() => download())} color='primary'>{t('export')}</Button>
                </Tooltip>
                <Tooltip arrow title={t('clearListSettings')}>
                    <IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                {props.permission.create &&
                    <Tooltip arrow title={t('add')}>
                        <IconButton component={Link} to='/receipt'>
                            <AddIcon color="primary"/>
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        ),
        renderDetailPanel: ({ row }) => (
            <Box margin={1} component={'div'}>
                <Grid container spacing={2}>
                    {row.original?.sale != null &&
                        <Grid item xs={6} sm={2} md={2} xl={1}>
                            <FormControl>
                                <FormLabel>{t('sale')}</FormLabel>
                                {row.original?.sale != null && row.original?.sale._id != null &&
                                row.original?.sale.number != null ?
                                    <Typography variant='body2'>
                                        <Link to={'/sale/' + row.original.sale._id} name={'saleLink' + row.original.sale._id}>
                                            {row.original.sale.number}
                                        </Link>
                                    </Typography>
                                    : ''
                                }
                            </FormControl>
                        </Grid>
                    }
                    {row.original?.purchaseOrder != null &&
                        <Grid item xs={6} sm={2} md={2} xl={1}>
                            <FormControl>
                                <FormLabel>{t('purchaseOrder')}</FormLabel>
                                {row.original?.purchaseOrder != null && row.original?.purchaseOrder._id != null &&
                                    row.original?.purchaseOrder.number != null ?
                                    <Typography variant='body2'>
                                        <Link to={'/purchaseorder/' + row.original?.purchaseOrder._id} name={'purchaseOrderLink' + row.original?.purchaseOrder._id}>
                                            {row.original?.purchaseOrder.number}
                                        </Link>
                                    </Typography>
                                    : ''
                                }
                            </FormControl>
                        </Grid>
                    }
                    <Grid item xs={6} sm={4}>
                        <FormControl>
                            <FormLabel>{t('shippingDetails')}</FormLabel>
                        </FormControl>
                        <div style={{display: 'flex'}}>
                            <Typography variant='body2' sx={{ fontWeight: 550, flexDirection: 'column' }}>
                                {t('shippingNumber') + ':'}
                            </Typography>
                            <Typography variant='body2' sx={{ marginLeft: 10 }}>
                                {row.original?.shippingNumber != null && row.original?.shippingNumber !== '' ? row.original?.shippingNumber : t('none')}
                            </Typography>
                        </div>
                        <div style={{display: 'flex'}}>
                            <Typography variant='body2' sx={{ fontWeight: 550, flexDirection: 'column' }}>
                                {t('courier') + ':'}
                            </Typography>
                            <Typography variant='body2' sx={{ marginLeft: 10 }}>
                                {row.original?.courier != null && row.original?.courier !== '' ? row.original?.courier : t('none')}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <br/>
                <FormLabel>{t('details')}</FormLabel>
                <TableContainer component={Paper}>
                    <Table aria-label={row.original?._id + 'item'}>
                        <TableHead >
                            <TableRow key={row.original?._id + 'item'}>
                                <TableCell sx={{ width: '450px'}}>{t('item')}</TableCell>
                                <TableCell>{t('lot')}</TableCell>
                                <TableCell>{t('container')}</TableCell>
                                <TableCell>{t('quantity')}</TableCell>
                                <TableCell>{t('status')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {row.original?.contents
                                .slice( contentPage * contentListSize,
                                        contentPage * contentListSize + contentListSize )
                                .map((item, k) =>
                                    <TableRow key={item._id}>
                                        <TableCell><Link to={'/item/' + item.item._id} name={'itemLink' + k}>{item.item.name}</Link></TableCell>
                                        <TableCell>{item.lot?._id != null && <Link to={'/lot/' + item.lot._id} name={'lotLink' + k}>{item.lot.number}</Link>}</TableCell>
                                        <TableCell>{item.container?._id != null && <Link to={'/container/' + item.container._id} name={'containerLink' + k}>{item.container.number}</Link>}</TableCell>
                                        <TableCell>
                                            {item.uom?.label != null && item.quantity != null ? item.quantity.toString() + item.uom.label : item.quantity}
                                        </TableCell>
                                        <TableCell>{item.lot?.status?.label != null && item.lot.status.label}</TableCell>
                                    </TableRow>
                            )}
                        </TableBody>
                        {row.original?.contents.length > 0 &&
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={row.original?.contents.length}
                                    rowsPerPage={contentListSize}
                                    onPageChange={handleContentChangePage}
                                    page = {contentPage}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={handleContentRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        }
                    </Table>
                </TableContainer>
            </Box>
          ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });

    const handleContentRowsPerPage = e => {
        setContentListSize(parseInt(e.target.value));
        refetch();
    };

    const handleContentChangePage = (event, newPage) => {
        setContentPage(newPage);
    };

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs}>
                <ActionBar/>
            </BreadcrumbBar>
            {csvOutput != null && csvOutput.data != null &&
                <CSVDownload data={csvOutput.data} target="_blank" headers={csvOutput.headers} separator={csvSeparator}/>
            }
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
        </Aux>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Receipts);