import React from 'react';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

export default function PassFail(props) {
     const { constants, status } = props;

     if(status === constants.pass) {
          return <CheckCircleOutlineRoundedIcon sx={{color: 'success.main'}} />
     }else if(status === constants.fail) {
          return <HighlightOffRoundedIcon sx={{color: 'warning.main'}} />
     }else {
          return <ErrorOutlineRoundedIcon sx={{color: 'primary.main'}} />
     }     
}