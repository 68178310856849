import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { DeviceHelper } from '../../../../helpers/deviceHelper';
import ModalDialog from '../../Dialog/ModalDialog';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '../../TextField/TextField';
import SelectField from '../../SelectField/SelectField';
import isEmpty from '../../../../is-empty';
import { withTranslation } from 'react-i18next';
import ValueHelper from '../../../../helpers/valueHelper';
import common from '../../../../jsons/common.json';
import PrintPreview from '../../../Devices/PrintPreview';

class Labels extends Component { 
    constructor(props){
        super(props);
        this.state = {
            errors: {},
            labelData: {}, 
            deviceList: [],
            selectedDevice: {},
            printer: {},
            count: 1,
            pauseCount: 0,
            ready: false,
            replica: 0,
            override: 'N',
            labelList: [],
            label: {},
            qualityList: [],
            selectedQuality: '',
            quality: {},
            buttonStack: [],
            sendToPrint: '',
            labelOptions: [],
            labelPrintTypes: [],
            selectedLabel: {},
            enablePrint: true,
            enablePreview: false
        }
    }

    componentDidMount = async () => {
        const cachedValues = await ValueHelper.getCachedValues([
                                common.cacheValues.label, 
                                common.cacheValues.labelPrintQuality,
                                common.cacheValues.labelPrintType
                            ], this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({ 
            labelList: cachedValues.labels,
            qualityList: cachedValues.labelPrintQualities,
            labelPrintTypes: cachedValues.labelPrintTypes
        }, () => this.loadRecord());
    }

    loadRecord = async () => {
        const devices = this.props.devices;
        let dList = [];
        let selectedDevice = {};
        let printer = {};
        if(!isEmpty(devices.registered) && devices.registered.length > 0) {
            devices.registered.forEach(device => {
                dList.push({
                    label: device.name, 
                    value: device.name.toLowerCase(), 
                    quality: device.quality != null ? device.quality : null,
                    pauseCount: device.pauseCount != null ? device.pauseCount : 0,
                });
            })
            if(!isEmpty(devices.defaultDevice)) {
                selectedDevice = {
                    label: devices.defaultDevice.name, 
                    value: devices.defaultDevice.name.toLowerCase()
                };
                printer = devices.defaultDevice;
                let qualitySel = this.state.qualityList.find((q) => q.value === printer.quality);
                printer.qualitySel = qualitySel;
            }
        };

        let labelType = {};
        if(devices.labelType != null && devices.labelType !== '') 
            labelType = this.state.labelPrintTypes.find((type) => type.value === devices.labelType);
        let options = this.state.labelList != null && this.state.labelList != null ?
                      this.state.labelList.filter((label) => label.label.includes(labelType.label)) : this.state.labelList;        
        this.setState({
            deviceList: dList,
            selectedDevice: selectedDevice,
            printer: printer,
            labelOptions: options,
            labelData: !isEmpty(devices.labelData) ? devices.labelData : this.state.labelData,
        }, () => this.setButtonStack());
    };

    componentDidUpdate = (prevProps, prevState) => {
        const t = this.props.t;
        if(prevState.ready !== this.state.ready || prevState.enablePreview !== this.state.enablePreview) {
            this.setButtonStack();
        }
        if(prevProps.devices.deviceList !== this.props.devices.deviceList) {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('deviceListChange'), title: t('success'), severity: 'success'}});
            this.loadRecord();
        }
    }

    setButtonStack = () => {      
        const t = this.props.t;
        let buttonStack = [
            <Button onClick={() => this.props.dispatch({type: 'TOGGLE_PRINTDIALOG'})} sx={{ color: 'secondary.main' }} key='print-close-btn'>Close</Button>,
            <Button disabled={!this.state.enablePreview} onClick={() => this.checkReady('preview')} sx={{ color: 'info.main' }} key='print-preview-btn'>{t('preview')}</Button>,
            <Button onClick={() => this.checkReady('print')} 
                    sx={{ color: 'primary.main' }} 
                    disabled={false} key='print-submit-btn'>{t('print')} {t('label')}</Button>
        ];
        this.setState({buttonStack});
    }

    changeValue = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({[name]: value, ready: false});        
    }

    changePrinter = (event) => {
        const newPrinter = this.props.devices.deviceList.find(x => x.name.toLowerCase() === event.target.value);
        this.setState({
            selectedDevice: {
                label: newPrinter.name, 
                value: newPrinter.name.toLowerCase()
            },
            printer: newPrinter
        });
    };

    changeQuality = event => {
        const newQuality = this.state.qualityList.find(x => x.value === event.target.value);
        this.setState({
            quality: newQuality.value,
            selectedQuality: newQuality,
            ready: false
        });
    };

    changeLabel = (event) => {
        const newLabel = this.state.labelList.find(x => x.value === event.target.value);
        let enablePreview = false;
        if(event.target.value !== '' && event.target.value != null && 
           event.target.value !== this.props.auth.constants.labelList.plantLabel1x8){
            enablePreview = true;
        }
        this.setState({
            label: newLabel.value,
            selectedLabel: newLabel,
            ready: false,
            enablePreview: enablePreview
        });    
    }

    buildLabelData = (details) => {
        let data = {};
        const printer = {
            density: this.state.printer.quality,
            pause: this.state.printer.pauseCount ?? 0,
            replica: this.state.printer.replica ?? 1,
            override: this.state.printer.override ?? false,
        }
        const label = this.state.selectedLabel.value;
        const type = this.props.devices.labelType;
        const constants = this.props.auth.constants;
        switch(type) {
            case constants.labelPrintTypes.container: {
                data = {
                    ...details,
                    ...printer,
                    count: details.count ?? 1,
                    labelFormat: label,
                    number: this.state.count ?? -1
                };
                break;
            }
            case constants.labelPrintTypes.lot: {
                data = {
                    ...details,
                    ...printer,
                    count: details.count ?? 1,
                    labelFormat: label
                };
                break;
            }
            case constants.labelPrintTypes.plant: {
                data = {
                    ...details,
                    ...printer,
                    count: label.eachPlant ? 1 : 
                           details.count ?? 1,
                    labelFormat: label,
                    number: this.state.count ?? -1,
                    eachPlant: label.eachPlant ?? false,
                };
                break;
            }
            default: 
                break;
        }
        return data;
    }

    validate = (e) => {
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.count == null || state.count === '')
            errors[t('count')] = t('required');
        if(state.count < 1)
            errors[t('count')] = t('moreThanZero');
        if(isEmpty(state.printer.quality))
            errors[t('quality')] = t('required');
        if(isEmpty(state.label))
            errors[t('label')] = t('required');
        if(isEmpty(state.selectedDevice))
            errors[t('selectedDevice')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    };

    checkReady = async (option) => {
        this.setState({ enablePrint: false });
        const t = this.props.t;
        let valid = this.validate();
        if(valid) {
            if(this.state.labelData != null && this.state.labelData !== '' && this.state.labelData.length > 1) {
                try {
                    let labelDetails = [];
                    this.state.labelData.forEach(async (data) => {
                        const details = this.buildLabelData(data);
                        labelDetails.push(details);
                    });
                    const multiLabel = await axios.post('api/multilabel', labelDetails);
                    this.setState({ sendToPrint: multiLabel.data });
                    if(multiLabel.data && option != null && option === 'print') {
                        this.handlePrint();
                    }else if(multiLabel.data && option != null && option === 'preview') {
                        let labelDimensions = this.state.selectedLabel;
                        const regex = new RegExp('\\d+x\\d+', 'g');
                        const dimensions = regex.exec(labelDimensions.label);
                        this.setState({printPreview: true, dimensions: dimensions[0]});
                    }
                    if(multiLabel.ok || multiLabel.status === 200){
                        if(this.state.printPreview) {
                            this.setState({printPreview: false, dimensions: null});
                        }   
                        this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('labelPrintSuccess'), title: t('success'), severity: 'success'}});
                    }else {
                        this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: multiLabel.statusText, title: multiLabel.status + ' ' + t('error'), severity: 'error'}});
                    }
                }catch (err) {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: t('error'), severity: 'error'}});
                }
            }else {
                try {
                    const labelDetails = this.buildLabelData(this.state.labelData);
                    const result = await axios.post('/api/label', labelDetails);
                    this.setState({sendToPrint: result.data});
                    if(result && option != null && option === 'print') {
                        this.handlePrint();
                    }else if(result && option != null && option === 'preview') {
                        let labelDimensions = this.state.selectedLabel;
                        const regex = new RegExp('\\d+x\\d+', 'g');
                        const dimensions = regex.exec(labelDimensions.label);
                        this.setState({printPreview: true, dimensions: dimensions[0]});
                    }
                    if(result.ok || result.status === 200){
                        if(this.state.printPreview) {
                            this.setState({printPreview: false, dimensions: null});
                        }   
                        this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('labelPrintSuccess'), title: t('success'), severity: 'success'}});
                    }else {
                        this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.statusText, title: result.status + ' ' + t('error'), severity: 'error'}});
                    }
                }catch (err) {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.message, title: t('error'), severity: 'error'}});
                    return false;
                }                
            }   
        }
        this.setState({ enablePrint: true });
    }

    handlePrint = async () => {
        const t = this.props.t;
        const result = await DeviceHelper.print(this.state.printer, this.state.sendToPrint);
        if(result.ok){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('labelPrintSuccess'), title: t('success'), severity: 'success'}});
        }else {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.statusText, title: result.status + ' ' + t('error'), severity: 'error'}});
        }
    }

    handleRefresh = async () => {
        const t = this.props.t;
        const result = await DeviceHelper.getDevices();
        if(!isEmpty(result)) {
            this.props.dispatch({type: 'SET_ZEBRA_PRINTERS', payload: result.printer});
        }else if(isEmpty(result)) { 
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('noPrinters'), title: t('error'), severity: 'error'}});
        }else {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('noBrowserPrint'), title: 'Error', severity: 'error'}});
        }
    }

    render() {
        const { devices, t } = this.props;
        const { buttonStack, count, deviceList, errors, label, labelOptions, printer, selectedDevice, printPreview } = this.state;
        
        return (
            <ModalDialog buttonStack={buttonStack} title={t('labelPrintService')} toggleDialog={() => this.props.dispatch({ type: 'TOGGLE_PRINTDIALOG' })}
                        dialogStatus={devices.openPrintDialog} fullWidth maxWidth='sm'>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <SelectField
                            error={errors[t('selectedDevice')]}
                            disabled={isEmpty(deviceList)}
                            className="form-control"
                            label={t('printer')}
                            name="printer"
                            options={deviceList}
                            onChange={this.changePrinter}
                            value={selectedDevice.value}
                            defaultValue={!isEmpty(devices.defaultDevice) ? 
                                          devices.defaultDevice.name.toLowerCase() : ''}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography>{t('quality') + ': ' + (printer.qualitySel != null && printer.qualitySel !== '' ? printer.qualitySel.label : '')}</Typography>
                        <Typography>{t('pauseCount') + ': ' + printer.pauseCount}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <hr/>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <SelectField
                            error={errors[t('label')]}
                            className="form-control"
                            label={t('label')}
                            name="label"
                            options={labelOptions}
                            onChange={this.changeLabel}
                            value={label.value}
                            defaultValue=''
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField value={count} onChange={this.changeValue} name='count' 
                        size="medium" fullWidth={true} disabled={false} label={t('count')} type='number'
                        error={errors[t('count')] != null ? true : false} helperText={errors[t('count')]}/>
                    </Grid>
                </Grid>
                {printPreview && 
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <PrintPreview 
                                labelData={this.state.sendToPrint}
                                quality={printer.qualitySel != null ? printer.qualitySel.label : ''}
                                dimensions={this.state.dimensions}
                                altText={this.state.selectedLabel != null ? this.state.selectedLabel.label : ''}
                            />
                        </Grid>
                    </Grid>
                }
            </ModalDialog>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    devices: state.devices
});

export default withTranslation() (connect(mapStateToProps)(Labels));