import React, { Component } from 'react';
import axios from 'axios';
import GeneratePdf from '../General/GeneratePdf';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class ComponentToPrint extends Component {
    
    render(){
        const t = this.props.t;
        return(
            <div>
                <h2>{t('lossCannabis')}: {this.props.data.number}</h2>
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('status')}</th>
                            <th width="200">{t('date')}</th>
                            <th>{t('location')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.status.label}</td>
                            <td><DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.lossDate}/></td>
                            <td>{this.props.data.location.name}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('type')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.other != null && this.props.data.other !== '' ? this.props.data.other : this.props.data.type.label}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                {this.props.data.hasBeenReported &&
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th width="200">{t('dateInitiated')}</th>
                                    <th width="200">{t('nameOfAuthority')}</th>
                                    <th>{t('reportNumber')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.dateReported}/></td>
                                    <td>{this.props.data.authority}</td>
                                    <td>{this.props.data.incidentNumber}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/>
                        <table>
                            <thead>
                                <tr>
                                    <th width="200">{t('phoneNumber')}</th>
                                    <th width="200">{t('investigatingOfficer')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{this.props.data.authority}</td>
                                    <td>{this.props.data.officerName}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br/>
                    </div>
                }
                <div><strong>{t('details')}</strong></div>
                <div>{this.props.data.details}</div>
                <br/>
                <div><strong>{t('securityBreach')}</strong></div>
                <div>{this.props.data.breach}</div>
                <br/>
                <table>
                    <thead>
                        <th width="200">{t('filedBy')}</th>
                        <th width="200">{t('date')}</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.filingEmployee.firstName != null ? this.props.data.filingEmployee.firstName + ' ' + 
                            this.props.data.filingEmployee.lastName : ''}</td>
                            <td><DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.filingDate}/></td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                {this.props.data.correctiveMeasures.length > 0 &&
                    <div>
                        <h3>{t('correctiveMeasures')}</h3>
                        <table>
                            <thead>
                                <th width="300">{t('description')}</th>
                                <th>{t('assignee')}</th>
                            </thead>
                            <tbody>
                                {this.props.data.correctiveMeasures.map((row) =>
                                    <tr>
                                        <td>{row.description}</td>
                                        <td>{row.user != null ? row.user.firstName + ' ' + row.user.lastName : ''}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <br/>
                    </div>
                }
                {this.props.data.samples.length > 0 &&
                    <div>
                        <h3>{t('containers')}</h3>
                        <table>
                            <thead>
                                <th width="200">{t('container')}</th>
                                <th width="200">{t('lastSeen')}</th>
                                <th>Amount</th>
                            </thead>
                            <tbody>
                                {this.props.data.samples.map((row) =>
                                    <tr>
                                        <td>{row.lot.number}</td>
                                        <td>{row.locationLastSeen}</td>
                                        <td>{row.quantityMissing + row.quantityUOM.label}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <br/>
                    </div>
                }
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('filedBy')}</th>
                            <th width="200">{t('reviewer')}</th>
                            <th>{t('signoff')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.user != null ? this.props.data.user.user.firstName + ' ' +
                            this.props.data.user.user.lastName : ''}</td>
                            <td>{this.props.data.reviewer != null ? this.props.data.reviewer.user.firstName + ' ' +
                            this.props.data.reviewer.user.lastName : ''}</td>
                            <td>{this.props.data.signer != null ? this.props.data.signer.user.firstName + ' ' +
                            this.props.data.signer.user.lastName : ''}</td>
                        </tr>
                        <tr>
                            <td>{this.props.data.user != null && <DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.user.date}/>}</td>
                            <td>{this.props.data.reviewer != null && <DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.reviewer.date}/>}</td>
                            <td>{this.props.data.signer != null && <DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.signer.date}/>}</td>
                        </tr>
                        <tr>
                            <td>{this.props.data.user != null && <img src={this.props.data.user.signature} alt={t('noSignature')}/>}</td>
                            <td>{this.props.data.reviewer != null && <img src={this.props.data.reviewer.signature} alt={t('noSignature')}/>}</td>
                            <td>{this.props.data.signer != null && <img src={this.props.data.signer.signature} alt={t('noSignature')}/>}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
            </div>
        )
    }
}

class LossPrint extends Component {
    constructor(props){
        super(props);
        this.state = {
            location: '',
            status: {},
            lossDate: new Date(),
            type: {},
            other: '',
            otherCheck: false,
            hasBeenReported: false,
            dateReported: '',
            authority: '',
            authorityTelephone: '',
            incidentNumber: '',
            officerName: '',
            details: '',
            breach: '',
            correctiveMeasures: [],
            samples: [],
            filingEmployee: {},
            filingDate: '',
            description: [],
            assignee: {},
            actionId: '',
            lot: {},
            locationLastSeen: '',
            quantityMissing: '',
            quantityUOM: {},
            policeReports: [],
            user: null,
            reviewer: null,
            signer: null,
            number: ''
        }
    }
    
    async componentDidMount(){
        const loss = await axios.get('/api/losscannabisprint/' + this.props.match.params.id);
        this.setState({
            _id: loss.data._id,
                location: loss.data.location,
                lossDate: Date.parse(loss.data.lossDate),
                type: loss.data.type,
                other: loss.data.other,
                otherCheck: loss.data.other != null && loss.data.other !== '' ? true : false,
                hasBeenReported: loss.data.hasBeenReported,
                dateReported: Date.parse(loss.data.dateReported),
                authority: loss.data.authority,
                authorityTelephone: loss.data.authorityTelephone,
                incidentNumber: loss.data.incidentNumber,
                officerName: loss.data.officerName,
                details: loss.data.details,
                breach: loss.data.breach,
                correctiveMeasures: loss.data.correctiveMeasures,
                samples: loss.data.samples,
                filingEmployee: loss.data.filingEmployee,
                filingDate: loss.data.filingDate,
                policeReports: loss.data.policeReports != null ? loss.data.policeReports : [],
                status: loss.data.status != null ? loss.data.status : '',
                user: loss.data.user,
                reviewer: loss.data.reviewer,
                signer: loss.data.signer,
                number: loss.data.number
        });
    }

    generateMarkup(){
        var htmlData = ReactDOMServer.renderToStaticMarkup(<ComponentToPrint data={this.state} auth={this.props.auth}/>);
        return htmlData;
    }

    render(){
        const t = this.props.t;
        return(
            <div className="container">
                <ComponentToPrint data={this.state} auth={this.props.auth} t={t}/>
                <div className="row">
                    <GeneratePdf markup={() => this.generateMarkup()} id={this.props.match.params.id} 
                    objectType={this.props.auth.constants.objectIds.loss}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(LossPrint));