import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class DocumentReviewList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            document: '',
            documents: [],
            documentSel: null,
            status: '',
            statusSel: null,
            statuses: [],
            security: []
        };
    }

    async componentDidMount(){
        const values = await axios.get('/api/manageddocreviewvalues');
        let storage = sessionStorage.getItem('documentReviews');
        storage = storage != null && storage !== '' ? JSON.parse(storage) : null;
        this.setState({
            documents: values.data.documents,
            statuses: values.data.statuses,
            security: this.props.permission,
            offset: storage != null ? storage.offset : 0,
            listSize: storage != null ? storage.listSize : 10,
            orderBy: storage != null ? storage.orderBy : 'number',
            ordDir: storage != null ? storage.ordDir : -1,
            status: storage != null ? storage.status : '',
            statusSel: storage != null ? storage.statusSel : null,
            document: storage != null ? storage.document : '',
            documentSel: storage != null ? storage.documentSel : null,
            page: storage != null ? storage.page : 0
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('documentReviews'), screen: this.props.auth.screenDefs.DocumentReviewList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
    }

    saveFilters(){
        let data = {
            offset: this.state.offset,
            listSize: this.state.listSize,
            orderBy: this.state.orderBy,
            ordDir: this.state.ordDir,
            status: this.state.status,
            statusSel: this.state.statusSel,
            document: this.state.document,
            documentSel: this.state.documentSel,
            page: this.state.page
        };
        sessionStorage.setItem('documentReviews', JSON.stringify(data));
    }

    loadRecords = async () => {
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            offset: this.state.offset,
            size: this.state.listSize,
            sort: JSON.stringify(sort),
            document: this.state.document,
            status: this.state.status
        };
        let list = await axios.get('/api/manageddocreviews', {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        })
        this.saveFilters();
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords()});
    };

    changeAuto = (name, data) => (e, value) => {
        this.setState({[name]: value, [data]: value != null ? value.value : '', page: 0, offset: 0}, () => {this.loadRecords()});
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/manageddocreview'
                        createAccess={security.create}
                        helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                        helpUser={this.props.auth.user.helpUser}
                        helpPassword={this.props.auth.user.helpPassword}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={8} sm={4}>
                            <AutoCompleteField
                                value={this.state.documentSel}
                                options={this.state.documents}
                                onChange={this.changeAuto('documentSel', 'document')}
                                label={t('document')}
                                data-cy="document"
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.statusSel}
                                options={this.state.statuses}
                                onChange={this.changeAuto('statusSel', 'status')}
                                label={t('status')}
                                data-cy='status'
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Document Reviews">
                            <TableHead>
                                <TableRow key="reviewHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('document')}
                                        active={this.state.orderBy === 'document'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('document')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('date')}
                                        active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Version</TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('status')}
                                        active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('expiration')}
                                        active={this.state.orderBy === 'expiration'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('expiration')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/manageddocreview/' + row._id} name={'docReview' + i}>{row.document.name}</Link></TableCell>
                                        <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={row.date}/></TableCell>
                                        <TableCell>{row.documentVersion}</TableCell>
                                        <TableCell>{row.status.label}</TableCell>
                                        <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={row.expiration}/></TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(DocumentReviewList));
