import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Panel from '../UI/Panel/Panel';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { CopyButton, DeleteButton, SaveButton } from '../UI/Buttons/Buttons';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '../UI/Switch/Switch';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import { withTranslation } from 'react-i18next';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import RichText from '../../hoc/RichText/RichText';
import isEmpty from '../../is-empty';
import SectionTitle from '../UI/Typography/SectionTitle';
import ActionBar from '../UI/Buttons/ActionBar';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class TenantReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            report: '',
            reports: [],
            reportSel: {},
            header: '',
            headerSel: null,
            headers: [],
            watermark: '',
            useExpiration: false,
            security: [],
            expirationTime: '',
            margin: {
                top: '',
                bottom: '',
                left: '',
                right: '',
            },
            reportDefault: false,
            description: '',
            global: false,
            enableSave: true
        };
    }

    async componentDidMount(){
        let values = await axios.get('/api/tenantreportvalues');
        this.setState({
            headers: values.data.headers,
            reports: values.data.reports,
            security: this.props.permission,
            _id: this.props.match.params.id
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/tenantreports', label: this.props.t('reportSettings'), screen: this.props.auth.screenDefs.TenantReports},
                    { path: 'active', label: this.props.t('reportSetting'), screen: this.props.auth.screenDefs.TenantReport}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/tenantreport/' + this.state._id);
        let headerSel = record.data.header != null ? this.state.headers.find(x => x.value === record.data.header) : null;
        let reportSel = record.data.report != null ? this.state.reports.find(x => x.value === record.data.report) : null;
        this.setState({
            report: record.data.report,
            reportSel: reportSel,
            header: record.data.header,
            headerSel: headerSel,
            watermark: record.data.watermark,
            useExpiration: record.data.useExpiration,
            expirationTime: record.data.expirationTime,
            margin: record.data.margin,
            reportDefault: record.data.default,
            description: record.data.description,
            global: record.data.global
        }, () => {
            const crumbs = [
                { path: '/#/tenantreports', label: this.props.t('reportSettings'), screen: this.props.auth.screenDefs.TenantReports},
                { path: 'active', label: this.props.t('reportSetting'), screen: this.props.auth.screenDefs.TenantReport}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeMargin = (e) => {
        let margin = this.state.margin;
        margin[e.target.name] = e.target.value;
        this.setState({margin});
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    };

    _copy = async () => {
        this.setState({enableSave: false}, () => this.copy());
    }

    copy = async () => {
        const data = {
            _id: this.state._id
        }
        try {
            let result = await axios.post('/api/tenantreportcopy', data);
            this.setState({ _id: result.data.id, enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}})
                this.props.history.push('/tenantreport/' + result.data.id);
            });
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    _save = async () => {
        this.setState({enableSave: false}, () => this.save());
    }

    save = async () => {
        let valid = this.validate();
        if(!valid)
            return;
        let data = {
            _id: this.state._id,
            header: this.state.header,
            watermark: this.state.watermark,
            useExpiration: this.state.useExpiration,
            expirationTime: this.state.expirationTime,
            margin: this.state.margin,
            default: this.state.reportDefault,
            report: this.state.report,
            description: this.state.description,
            global: this.state.global
        };
        try {
            let result = await axios.post('/api/tenantreport', data);
            this.setState({ _id: result.data.id, enableSave: true }, () => this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}}));

        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    };

    validate = () => {
        let errors = {};
        const state = this.state;
        const t = this.props.t;
        if(state.margin.top === '')
            errors[t('topMargin')] = this.props.t('required');
        if(state.margin.bottom === '')
            errors[t('bottomMargin')] = this.props.t('required');
        if(state.margin.left === '')
            errors[t('leftMargin')] = this.props.t('required');
        if(state.margin.right === '')
            errors[t('rightMargin')] = this.props.t('required');
        if(state.useExpiration && state.expirationTime === '')
            errors[t('duration')] = this.props.t('required');
        if(state.description === '')
            errors[t('description')] = this.props.t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    _delete = async () => {
        this.setState({enableSave: false}, () => this.delete());
    }

    delete = async () => {
        try {
            let result = await axios.delete('/api/tenantreportdelete/' + this.state._id);
            this.setState({ _id: '', enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}})
                this.props.history.push('/tenantreports');
            });
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    render(){
        const { expirationTime, global, headerSel, margin, reportDefault, security, watermark, useExpiration } = this.state;
        const { errors, t } = this.props;
        const fieldAccess = global === true && this.props.auth.user.internal === true ? true : 
                            global === true && this.props.auth.user.internal === false ? false : 
                            global === false && security.update === true ? true : false;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Grid container spacing={1} sx={{ justifyContent: 'flex-start' }}>
                    <Grid container item xs={12} sm={7} lg={4} direction='column'>
                        <Panel>
                            <SectionTitle title={t('reportDefinition')} /><br />
                            <Grid container item xs={12} sx={{ justifyContent: 'space-between' }} spacing={2}>
                                <Grid item xs={12}>
                                    <TextField value={this.state.description} onChange={this.changeValue} name="description"
                                    size="medium" fullWidth disabled={!fieldAccess} label={t('description')} required
                                    error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}
                                    data-cy='description'/>
                                </Grid>
                                <Grid item xs={12}>
                                    <AutoCompleteField
                                        value={this.state.reportSel}
                                        options={this.state.reports}
                                        onChange={this.changeAuto('reportSel', 'report')}
                                        error={errors[t('report')] != null ? true : false}
                                        helperText={errors[t('report')]}
                                        label={t('report')}
                                        disabled={!fieldAccess}
                                        fullWidth
                                        data-cy='report'
                                    />
                                </Grid>
                                {this.state.reportSel != null &&
                                    <Aux>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl>
                                                <FormLabel>{t('paperSize')}</FormLabel>
                                                {this.state.reportSel != null && this.state.reportSel.paperSize != null ?
                                                 this.state.reportSel.paperSize : t('none')}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl>
                                                <FormLabel>{t('object')}</FormLabel>
                                                {this.state.reportSel != null && this.state.reportSel.object != null ?
                                                 this.state.reportSel.object : t('none')}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl>
                                                <FormLabel>{t('documentNumber')}</FormLabel>
                                                {this.state.reportSel != null && this.state.reportSel.number != null ?
                                                 this.state.reportSel.number : t('none')}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl>
                                                <FormLabel>{t('version')}</FormLabel>
                                                {this.state.reportSel != null && this.state.reportSel.version != null ?
                                                 this.state.reportSel.version : t('none')}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sx={{ alignItems: 'flex-end'}}>
                                            <CheckboxField
                                                checked={reportDefault}
                                                onChange={this.changeBool('reportDefault')}
                                                name="reportDefault"
                                                disabled={!fieldAccess}
                                                label={t('makeDefault')}
                                                data-cy='reportDefault'
                                            />
                                        </Grid>
                                        {(this.state.global === true || this.props.auth.user.internal === true) &&
                                            <Grid item xs={6} sx={{ alignItems: 'flex-end'}}>
                                                <CheckboxField
                                                    checked={this.state.global}
                                                    onChange={this.changeBool('global')}
                                                    name="global"
                                                    disabled={!fieldAccess}
                                                    label={t('global')}
                                                    data-cy='global'
                                                />
                                            </Grid>
                                        }                                        
                                    </Aux>
                                }

                            </Grid>
                        </Panel>
                    </Grid>
                    <Grid container item xs={12} sm={5} lg={4} direction='column'>
                        <Panel>
                            <SectionTitle title={t('options')} /> <br />
                            <Grid container item xs={12} spacing={3}>
                                <Grid container item xs={12}>
                                    <Grid item xs={12}>
                                        <TextField value={watermark} onChange={this.changeValue} name="watermark"
                                        size="medium" fullWidth disabled={!fieldAccess} label={t('waterMark')} data-cy='watermark'
                                        error={errors[t('waterMark')] != null ? true : false} helperText={errors[t('waterMark')]}/>
                                    </Grid>
                                    <Grid item xs={12} lg={8} sx={{ alignItems: 'flex-end'}}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={useExpiration} onChange={this.changeBool('useExpiration')}
                                                    name="useExpiration" disabled={!fieldAccess} data-cy='useExpiration'
                                                />
                                            }
                                            label={t('documentExpires')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <TextField value={expirationTime} onChange={this.changeValue} name="expirationTime"
                                        size="medium" fullWidth disabled={!this.state.useExpiration && !fieldAccess} label={t('duration')} data-cy='expirationTime'
                                        error={errors[t('duration')] != null ? true : false} helperText={errors[t('duration')]}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Panel>
                    </Grid>
                    <Grid container item xs={12} lg={3} direction='column'>
                        <Panel>
                            <SectionTitle title={t('margins')} /> <br />
                            <Grid container item xs={12} spacing={3}>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={12} sm={6} md={3} lg={12}>
                                        <TextField value={margin.top} onChange={this.changeMargin} name="top"
                                        size="medium" fullWidth disabled={!fieldAccess} label={t('topMargin')} data-cy='top'
                                        error={errors[t('topMargin')] != null ? true : false} helperText={errors[t('topMargin')]}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={12}>
                                        <TextField value={margin.bottom} onChange={this.changeMargin} name="bottom"
                                        size="medium" fullWidth disabled={!fieldAccess} label={t('bottomMargin')} data-cy='bottom'
                                        error={errors[t('bottomMargin')] != null ? true : false} helperText={errors[t('bottomMargin')]}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={12}>
                                        <TextField value={margin.left} onChange={this.changeMargin} name="left"
                                        size="medium" fullWidth disabled={!fieldAccess} label={t('leftMargin')} data-cy='left'
                                        error={errors[t('leftMargin')] != null ? true : false} helperText={errors[t('leftMargin')]}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={12}>
                                        <TextField value={margin.right} onChange={this.changeMargin} name="right"
                                        size="medium" fullWidth disabled={!fieldAccess} label={t('rightMargin')} data-cy='right'
                                        error={errors[t('rightMargin')] != null ? true : false} helperText={errors[t('rightMargin')]}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Panel>
                    </Grid>
                    <Grid container item xs={12} direction='column'>
                        <Panel>
                            <SectionTitle title={t('headersAndFooters')} />
                            <Grid container item xs={12} sx={{ justifyContent: 'space-between' }} spacing={3}>
                                <Grid item xs={12} lg={3}>
                                    <AutoCompleteField
                                        value={this.state.headerSel}
                                        options={this.state.headers}
                                        onChange={this.changeAuto('headerSel', 'header')}
                                        error={errors[t('header')] != null ? true : false}
                                        helperText={errors[t('header')]}
                                        label={t('header')}
                                        disabled={!fieldAccess}
                                        fullWidth
                                        data-cy='header'
                                    />
                                </Grid>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={12} xl={6}>
                                        <SubSectionHeading title={t('header')} /><br />
                                        <RichText>
                                            {headerSel != null && headerSel !== '' &&
                                            headerSel.data != null && headerSel.data !== '' && headerSel.data.header}
                                        </RichText>
                                    </Grid>
                                    <Grid item xs={12} xl={6}>
                                        <SubSectionHeading title={t('footer')} /><br />
                                        <RichText>
                                            {headerSel != null && headerSel !== '' &&
                                            headerSel.data != null && headerSel.data !== '' && headerSel.data.footer}
                                        </RichText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Panel>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item sm={10}>
                        {fieldAccess && <SaveButton saveClick={() => this._save()} enabled={this.state.enableSave} data-cy='save'/>}
                        <CopyButton copyClick={() => this.copy()} enabled={this.state.enableSave} data-cy='copy' color='secondary' />
                        {this.state.global === false && security.delete === true && this.state._id != null && this.state._id !== '' &&
                            <DeleteButton deleteClick={() => this._delete()} enabled={this.state.enableSave} data-cy='delete' />
                        }
                    </Grid>
                </Grid>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(TenantReport));
