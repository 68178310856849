import React, { useState } from 'react';
import axios from 'axios';
import DocumentViewer from './DocumentViewer';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Confirmation from '../UI/Dialog/Confirmation';

const DocumentList = (props) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [index, setIndex] = useState('');
    
    const { t } = useTranslation('translation');

    let confirmClick = (answer, i) => async (e) => {
        if(answer === 'yes'){
            let doc = props.documents[index];
            let data = {
                objectType: props.objectType,
                recordId: props.recordId,
                _id: doc._id,
                property: props.propName
            };
            try {
                await axios.post('/api/documents/delete', data)
                props.callback();
            } catch(err){
                props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t("error"), severity: 'error'}});
            }
        }
        toggleConfirm();
    }

    let toggleConfirm = (i) => {
        setConfirmOpen(!confirmOpen);
        setIndex(i != null ? i : '');
    }

    return(
        <Aux>
            {props.documents != null &&
                props.documents.map((row, i) =>
                <Grid key={"document" + i} container spacing={3}>
                    <Grid item sm={10}>
                        <DocumentViewer document={row} deleteCallback={() => toggleConfirm(i)}/>
                    </Grid>
                </Grid>
            )}
            {confirmOpen &&
                <Confirmation
                    open={confirmOpen}
                    no={confirmClick('no')}
                    noButton={t('no')}
                    yes={confirmClick('yes')}
                    yesButton={t('yes')}
                    title={t('areYouSure')}
                    content={t('deleteDocumentMessage')}
                    toggleDialog={() => toggleConfirm()}
                />
            }
        </Aux>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(DocumentList);
