import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';
import { CSVDownload } from "react-csv";
import AddIcon from '@mui/icons-material/Add';
import ActionBar from '../UI/Buttons/ActionBar';
import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete';
import ListMultiSelect from '../UI/ListMultiSelect/ListMultiSelect';
import ConversionHelper from '../../helpers/conversionHelper';
import listHelper from '../../helpers/listHelper';

const CustomerComplaints = (props) => {
    const isFirstRender = useRef(true);
    const [loadRefData, setLoadRefData] = useState(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [csvOutput, setCsvOutput] = useState({headers: null, data: null});
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [columnOrder, setColumnOrder] = useState([]);
    const [values, setValues] = useState({customers: [], items: [], users: [], types: [], statuses: []});
    const [ item, setItem ] = useState(null);
    const [customer, setCustomer] = useState(null);
    const { t } = useTranslation('translation');
    const csvSeparator = sessionStorage.getItem('csvSeparator');
    const [statusesSel, setStatusesSel] = useState({ids: [], values: []});
    const [showSelect, setShowSelect] = useState(false);
    const tenant = props.auth.user.tenant;
    const crumbs = [
        { path: 'active', label: t('customerComplaints'), screen: props.auth.screenDefs.CustomerComplaints}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        async function fetchData(){
            const columnFilters = await listHelper.getItem('CustomerComplaintsColumnFilters', tenant);
            const columnVisibility = await listHelper.getItem('CustomerComplaintsColumnVisibility', tenant);
            const density = await listHelper.getItem('CustomerComplaintsDensity', tenant);
            const globalFilter = await listHelper.getItem('CustomerComplaintsGlobalFilter', tenant);
            const showGlobalFilter = await listHelper.getItem('CustomerComplaintsShowGlobalFilter', tenant);
            const showColumnFilters = await listHelper.getItem('CustomerComplaintsShowColumnFilters', tenant);
            const sorting = await listHelper.getItem('CustomerComplaintsSorting', tenant);
            const pagination = await listHelper.getItem('CustomerComplaintsPagination', tenant);
            const tempColumnOrder = await listHelper.getItem('CustomerComplaintsColumnOrder', tenant);
            if (columnFilters) {
                let filters = ConversionHelper.filtersDateParse(JSON.parse(columnFilters));
                let item = filters.find(x => x.id === 'item');
                if(item != null && values.items != null && values.items.length > 0) {
                    if(item.value != null){
                        let sel = values.items.find(x => x.value === item.value);
                        setItem(sel);
                    }
                }
                let customer = filters.find(x => x.id === 'customer');
                if(customer != null && values.customers != null && values.customers.length > 0) {
                    if(customer.value != null){
                        let sel = values.customers.find(x => x.value === customer.value);
                        setCustomer(sel);
                    }
                }
                let status = filters.find(x => x.id === 'status');
                if(status != null){
                    let sel = {ids: status.value, values: []};
                    status.value.forEach(id => {
                        let val = values.statuses.find(x => x.value === id);
                        sel.values.push(val);
                    });
                    setStatusesSel(sel);
                }
                setColumnFilters(filters);
            }
            if (columnVisibility) {
                setColumnVisibility(columnVisibility);
            }
            if (density) {
                setDensity(density);
            }
            if (globalFilter) {
                setGlobalFilter(globalFilter);
            }
            if (showGlobalFilter) {
                setShowGlobalFilter(showGlobalFilter);
            }
            if (showColumnFilters) {
                setShowColumnFilters(showColumnFilters);
            }
            if (sorting) {
                setSorting(sorting);
            }
            if (pagination) {
                setPagination(pagination);
            }
            if (tempColumnOrder) {
                setColumnOrder(tempColumnOrder);
            } else {
                setColumnOrder(['number', 'date', 'types', 'status', 'personName', 'customer', 'assignee', 'item']);
            }
            isFirstRender.current = false;
        }
        if(isFirstRender.current){
            fetchData();
        }
    }, [values, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('CustomerComplaintsColumnFilters', tenant, JSON.stringify(columnFilters));
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('CustomerComplaintsColumnVisibility', tenant, columnVisibility);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnVisibility, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('CustomerComplaintsDensity', tenant, density);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [density, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('CustomerComplaintsGlobalFilter', tenant, globalFilter);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [globalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('CustomerComplaintsShowGlobalFilter', tenant, showGlobalFilter);
        }
        if (isFirstRender.current) return;
        fetchData()
    }, [showGlobalFilter, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('CustomerComplaintsShowColumnFilters', tenant, showColumnFilters);
        }
        if (isFirstRender.current) return;
        fetchData();

    }, [showColumnFilters, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('CustomerComplaintsSorting', tenant, sorting);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [sorting, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('CustomerComplaintsPagination', tenant, pagination);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [pagination, tenant]);

    useEffect(() => {
        async function fetchData(){
            listHelper.setItem('CustomerComplaintsColumnOrder', tenant, columnOrder);
        }
        if (isFirstRender.current) return;
        fetchData();
    }, [columnOrder, tenant]);

    const resetState = () => {
        listHelper.removeItem('CustomerComplaintsColumnFilters', tenant);
        listHelper.removeItem('CustomerComplaintsColumnVisibility', tenant);
        listHelper.removeItem('CustomerComplaintsDensity', tenant);
        listHelper.removeItem('CustomerComplaintsGlobalFilter', tenant);
        listHelper.removeItem('CustomerComplaintsShowGlobalFilter', tenant);
        listHelper.removeItem('CustomerComplaintsShowColumnFilters', tenant);
        listHelper.removeItem('CustomerComplaintsSorting', tenant);
        listHelper.removeItem('CustomerComplaintsPagination', tenant);
        listHelper.removeItem('CustomerComplaintsColumnOrder', tenant);
        window.location.reload();
    };

    useEffect(() => {
        async function fetchData(){
            const userValues = await ValueHelper.screenUsers(common.screens.customerComplaints, props.auth.user.tenant);
            const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.allItems, common.cacheValues.customer, common.cacheValues.customerComplaintType], 
                props.auth.constants, null, props.auth.user.tenant);
            const statuses = await ValueHelper.getStatusesForObject(props.auth.constants.objectIds.customerComplaint, props.auth.user.tenant);
            let item = columnFilters.find(x => x.id === 'item');
            if(item != null) {
                if(item.value != null){
                    let sel = cacheValues.items.find(x => x.value === item.value);
                    setItem(sel);
                }
            }
            let customer = columnFilters.find(x => x.id === 'customer');
            if(customer != null && cacheValues.customers != null && cacheValues.customers.length > 0) {
                if(customer.value != null){
                    let sel = cacheValues.customers.find(x => x.value === customer.value);
                    setCustomer(sel);
                }
            }
            let status = columnFilters != null ? columnFilters.find(x => x.id === 'status') : null;
            if(status != null){
                let sel = {ids: status.value, values: []};
                status.value.forEach(id => {
                    let val = statuses.find(x => x.value === id);
                    sel.values.push(val);
                });
                setStatusesSel(sel);
            }
            setValues({
                customer: cacheValues.customers,
                items: cacheValues.allItems,
                statuses: statuses,
                types: cacheValues.customerComplaintTypes,
                users: userValues.create
            });
            setLoadRefData(false);
        }
        if(loadRefData){
            fetchData();
        }
    }, [props, customer, item, columnFilters, loadRefData]);

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'customer',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/customercomplaints', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });

    const columns = useMemo(() => [
        {
            accessorKey: 'number',
            id: 'number',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/customercomplaint/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            ),
        },
        {
            accessorKey: 'date',
            id: 'date',
            header: t('date'),
            filterVariant: 'date-range'
        },
        {
            accessorKey: 'types',
            id: 'types',
            header: t('type'),
            filterVariant: 'select',
            filterSelectOptions: values.types,
        },
        {
            accessorKey: 'status',
            id: 'status',
            header: t('status'),
            Filter: ({ header, column }) => (
                <Box>
                    <ListMultiSelect 
                    selectAll
                    name='status'
                    show={showSelect} 
                    setShow={() => setShowSelect(!showSelect)} 
                    t={t} 
                    options={values.statuses} 
                    setSelected={setStatusesSel} 
                    setColumnFilters={setColumnFilters}
                    selected={statusesSel.ids} 
                    selectedSel={statusesSel.values}
                    screen='CustomerComplaints'
                    tenant={props.auth.user.tenant}
                    columnFilters={columnFilters}/>
                    
                </Box>
            ),
        },
        {
            accessorKey: 'personName',
            id: 'personName',
            header: t('person')
        },
        {
            accessorFn: (row) => (row.customer != null ? row.customer.number + '-' + row.customer.name : ''),
            id:'customer',
            header: t('customer'),
            Filter: ({ header, column }) =>
                <AutoComplete 
                    options={values.customers}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, newValue) => {
                        setCustomer(newValue);
                        column.setFilterValue(newValue != null ? newValue.value : null);
                    }}
                    value={customer}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                />,
        },
        {
            accessorKey: 'assignee', 
            id: 'assignee', 
            header: t('assignee'),
            filterVariant: 'select',
            filterSelectOptions: values.users,
        },
        {
            accessorKey: 'item',
            id: 'item',
            header: t('item'),
            size: 800,
            Filter: ({ header, column }) =>
                <AutoComplete 
                    options={values.items}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, newValue) => {
                        setItem(newValue);
                        column.setFilterValue(newValue != null ? newValue.value : null);
                    }}
                    value={item}
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                />,
        }
    ], [t, customer, item, values, statusesSel, showSelect, columnFilters, props]);

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true,  columnVisibility: {id: false}},
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: false,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiSkeletonProps: {
            animation: 'none',
        },
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')} color="primary">
                    <IconButton onClick={() => refetch()}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('csv')}>
                    <IconButton onClick={() => csv()} color="primary">
                        <ExcelIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('clearListSettings')}>
                    <IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                {props.permission.create &&
                    <Tooltip arrow title={t('add')}>
                        <IconButton component={Link} to='/customercomplaint' color="primary">
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });

    const csv = async () => {
        setCsvOutput({headers: null, data: null});
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? '',
            sorting: JSON.stringify(sorting ?? []),
        };
        const response = await axios.get('/api/customercomplaintcsv', {params: params});
        let headers = response.data.headers;
        let csvData = response.data.data;
        setCsvOutput({headers: headers, data: csvData});
    }

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs}>
                <ActionBar/>
            </BreadcrumbBar>
            {csvOutput != null && csvOutput.data != null &&
                <CSVDownload data={csvOutput.data} target="_blank" headers={csvOutput.headers} separator={csvSeparator}/>
            }
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
        </Aux>
    )
    
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(CustomerComplaints);