import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import DocumentList from '../General/DocumentList';
import base64 from 'base-64';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import Unlock from '../General/Unlock';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Button from '@mui/material/Button';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SaveButton, ClearButton, DeleteButton, TextOnlyClickButton } from '../UI/Buttons/Buttons';
import ActionBar from '../UI/Buttons/ActionBar';
import SectionTitle from '../UI/Typography/SectionTitle';
import CapaAction from './CapaAction';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import DateDisplay from '../UI//DateDisplay/DateDisplay';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import common from '../../jsons/common.json';
import ValueHelper from  '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { approveSigSecurity, completeSigSecurity, reviewSigSecurity } from '../../helpers/securityHelper';

class LossCannabis extends Component{
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            location: '',
            locationSel: null,
            locations: [],
            lossDate: new Date(),
            type: '',
            typeSel: null,
            types: [],
            items: [],
            itemsSel: [],
            itemList: [],
            other: '',
            hasBeenReported: false,
            dateReported: '',
            authority: '',
            authorityTelephone: '',
            incidentNumber: '',
            officerName: '',
            details: '',
            breach: '',
            filingEmployee: '',
            filingEmployeeSel: null,
            users: [],
            managers: [],
            qas: [],
            filingDate: '',
            policeReports: [],
            schedule: '',
            status: '',
            statusSel: null,
            statuses: [],
            user: null,
            reviewer: null,
            signer: null,
            finalDocument: null,
            documents: [],
            containers: [],
            capaActions: [],
            containerId: '',
            container: '',
            containerSel: null,
            containerList: [],
            locationLastSeen: '',
            quantityMissing: '',
            quantityUOM: '',
            quantityUOMSel: null,
            uoms: [],
            description: '',
            correctiveUser: '',
            correctiveUserSel: null,
            showContainer: false,
            showCorrective: false,
            lot: null,
            containerIndex: -1,
            security: [],
            editCapa: false,
            offset: 0,
            listSize: 10,
            page: 0,
            orderBy: 'number',
            ordDir: 1,
            totalCount: 0,
            capaId: '',
            capaIndex: -1,
            dialogOpen: false,
            enableSave: { 'record': true, 'container': true },
            goBack: -1,
            optionsMenu: [],
            flagForSignature: 0,
            signed: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.allItems, common.cacheValues.activeLocation, common.cacheValues.status, 
                common.cacheValues.quantityUOM], this.props.auth.constants, null, this.props.auth.user.tenant);
        let items = cacheValues.allItems;
        let locations = cacheValues.locations;
        let params = queryString.parse(this.props.location.search);
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        let goBack = -1;
        if(scheduleId != null)
            goBack = -2;
        const values = await axios.get("/api/lossvalues")
        let schedule = null;
        if(scheduleId != null)
            schedule = await axios.get('/api/schedulebase/' + scheduleId);
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ? 
            this.props.auth.user.defaultLocation : '';
        let locSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locSel == null && locations.length === 1){
            locSel = locations[0];
            location = locSel.value;
        }
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        this.setState({
            users: values.data.users,
            types: values.data.lossEventTypes,
            locations: locations,
            locationSel: locSel,
            itemList: items,
            uoms: cacheValues.quantityUOMs,
            schedule: scheduleId,
            location: schedule != null && schedule.data.location != null ? schedule.data.location : location,
            statuses: cacheValues.statuses,
            managers: values.data.managers,
            qas: values.data.qas,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null,
            security: this.props.permission,
            goBack: goBack
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/losses', label: this.props.t('lossesCannabis'), screen: this.props.auth.screenDefs.LossCannabis},
                    { path: 'active', label: this.props.t('lossTitle'), screen: this.props.auth.screenDefs.LossCannabis}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const constants = this.props.auth.constants;
        let loss = await axios.get('/api/loss/' + this.state._id);
        let signed = loss.data.status === this.props.auth.constants.statuses.complete ||
            loss.data.status === this.props.auth.constants.statuses.reviewed ||
            loss.data.status === this.props.auth.constants.statuses.approved;
        let locationSelected = this.state.locations.find(x => x.value === loss.data.location);
        let typeSelected = this.state.types.find(x => x.value === loss.data.type);
        let employeeSelected = this.state.users.find(x => x.value === loss.data.filingEmployee);
        const statusSel = this.state.statuses.find(x => x.value === loss.data.status);
        let itemsSel = [];
        let items = loss.data.items;
        if(items != null && items.length > 0){
            items.forEach(row => {
                let value = this.state.itemList.find(x => x.value === row);
                itemsSel.push(value);
            });
        }
        let containers = loss.data.containers;
        if(containers != null && containers.length > 0){
            for(let row of containers){
                let uomSel = this.state.uoms.find(x => x.value === row.quantityUOM);
                row.uomSel = uomSel;
            }
        }
        const completeSigSec = completeSigSecurity(this.props.permission.complete, loss.data.user, loss.data.status, this.props.auth.common, constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, loss.data.reviewer, loss.data.status, this.props.auth.common, constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, loss.data.signer, loss.data.status, this.props.auth.common, constants);
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: this.state.users, name: t('filingSignature'), object: this.state._id,
                title: t('filedBy'), setId: () => this.loadRecord(), signature: loss.data.user,
                objectType: this.props.auth.constants.objectIds.loss, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature,
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: this.state.managers,
                name: t('reviewer'), title: t('reviewedBy'), setId: () => this.loadRecord(), signature: loss.data.reviewer, object: loss.data._id,
                objectType: this.props.auth.constants.objectIds.loss, type: this.props.auth.constants.signatureTypes.reviewer, 
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: this.state.qas,
                name: t('signoff'), title: t('approvedBy'), setId: () => this.loadRecord(), object: this.state._id, signature: loss.data.signer,
                objectType: this.props.auth.constants.objectIds.loss, type: this.props.auth.constants.signatureTypes.signer, 
                menuType: this.props.auth.constants.optionMenuTypes.signature
            }
        ];
        const flagForSignature = loss.data.status === this.props.auth.constants.statuses.inprogress && loss.data.user == null ? 1 :
                                 loss.data.status === this.props.auth.constants.statuses.complete && loss.data.reviewer != null ? 2 :
                                 loss.data.status === this.props.auth.constants.statuses.reviewed && loss.data.signer != null ? 3 : 0;
        this.setState({
            _id: loss.data._id,
            location: loss.data.location,
            lossDate: loss.data.lossDate,
            type: loss.data.type,
            other: loss.data.other,
            otherCheck: loss.data.other != null && loss.data.other !== '' ? true : false,
            hasBeenReported: loss.data.hasBeenReported,
            dateReported: loss.data.dateReported,
            authority: loss.data.authority,
            authorityTelephone: loss.data.authorityTelephone,
            incidentNumber: loss.data.incidentNumber,
            officerName: loss.data.officerName,
            details: loss.data.details,
            breach: loss.data.breach,
            correctiveActions: loss.data.correctiveActions,
            containers: containers,
            filingEmployee: loss.data.filingEmployee,
            filingDate: loss.data.filingDate,
            policeReports: loss.data.policeReports != null ? loss.data.policeReports : [],
            status: loss.data.status != null ? loss.data.status : this.props.auth.constants.statuses.inprogress,
            signed: signed,
            user: loss.data.user,
            reviewer: loss.data.reviewer,
            signer: loss.data.signer,
            finalDocument: loss.data.finalDocument,
            locationSel: locationSelected,
            typeSel: typeSelected,
            employeeSelected: employeeSelected,
            documents: loss.data.documents,
            number: loss.data.number,
            statusSel: statusSel,
            items: items,
            itemsSel: itemsSel,
            containerList: loss.data.containerList,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature
        }, () => {
            const crumbs = [
                { path: '/#/losses', label: t('lossesCannabis'), screen: this.props.auth.screenDefs.LossCannabis},
                { path: 'active', label: t('lossTitle') + ' - ' + loss.data.number, screen: this.props.auth.screenDefs.LossCannabis}
            ]
            this.loadRecords();
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
        });
    };

    loadRecords = async () => {
        let sorting = [{'id': this.state.orderBy, 'desc': this.state.ordDir > 0 ? 'asc' : 'desc'}];
        let columnFilters = [
            {id: this.props.auth.constants.objectIds.loss, value: this.state._id},
        ];
        let params = {
            filters: JSON.stringify(columnFilters ?? []),
            sorting: JSON.stringify(sorting),
            start: this.state.offset,
            size: this.state.listSize,
        };
        let result = await axios.get('/api/capas', {params: params});
        this.setState({
            capaActions: result.data.data,
            totalCount: result.data.totalCount
        })
    };

    changeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(data === 'container' && newValue != null){
            let container = await axios.get('/api/container/' + newValue.value);
            let lot = container.data.lot;
            lot.uom = container.data.uom;
            let quantityUOMSel = this.state.uoms.find(x => x.value === lot.uom._id);
            this.setState({
                lot: lot,
                quantityUOMSel: quantityUOMSel,
                quantityUOM: lot.uom._id
            });
        } 
    };

    changeMulti = (name, data) => async (e, newValue) => {
        let containerList = this.state.containerList;
        let val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            });
        }
        if(data === 'items'){
            let data = {
                items: ids,
                available: false
            }
            let containers = await axios.post('/api/itemcontainers', data);
            containerList = containers.data;
        }
        this.setState({
            [name]: val,
            [data]: ids,
            containerList: containerList
        })
    };

    otherChanged = (e) => {
        this.setState({otherCheck: !this.state.otherCheck});
    };

    reportedChanged = (e) => {
        this.setState({hasBeenReported: !this.state.hasBeenReported});
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    saveClick = () => {
        if(!this.state.enableSave.record)
            return;
        this.setState({ enableSave: {...this.state.enableSave, record: false}}, () => this._saveClick());
    }

    _saveClick = async () => {
        const t = this.props.t;
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: {...this.state.enableSave, record: true}});
            return;
        }
        var policeReports = [];
        this.state.policeReports.forEach(rep => {
            policeReports.push(rep._id);
        })
        var data = {
            _id: this.state._id,
            location: this.state.location,
            lossDate: this.state.lossDate,
            type: this.state.type,
            other: this.state.other,
            hasBeenReported: this.state.hasBeenReported,
            dateReported: this.state.dateReported,
            authority: this.state.authority,
            authorityTelephone: this.state.authorityTelephone,
            incidentNumber: this.state.incidentNumber,
            officerName: this.state.officerName,
            details: this.state.details,
            breach: this.state.breach,
            filingEmployee: this.state.filingEmployee,
            filingDate: this.state.filingDate,
            status: this.state.status,
            policeReports: policeReports,
            schedule: this.state.schedule,
            items: this.state.items
        }
        try {
            const result = await axios.post('api/loss', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/loss/' + result.data.id);
            this.setState({ enableSave: {...this.state.enableSave, record: true}}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: {...this.state.enableSave, record: true}}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    validateForm(){
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.lossDate == null || state.lossDate === '')
            errors[t('lossDate')] = t('required');
        if((state.type == null || state.type === '') && !state.otherCheck)
            errors[t('type')] = t('required');
        if(state.otherCheck && (state.other == null || state.other === ''))
            errors[t('other')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    editContainer = (index) => (e) => {
        let container = this.state.containers[index];
        let quantityUOMSel = this.state.uoms.find(x => x.value === container.quantityUOM);
        let containerSel = this.state.containerList.find(x => x.value === container.container._id);
        this.setState({
            containerIndex: index,
            showContainer: true,
            containerId: container._id,
            container: container.container._id,
            containerSel: containerSel,
            lot: container.lot,
            locationLastSeen: container.locationLastSeen,
            quantityMissing: container.quantityMissing,
            quantityUOM: container.quantityUOM,
            quantityUOMSel: quantityUOMSel
        });
    };

    addContainer = (e) => {
        this.setState({showContainer: true})
    };

    clearContainer = (e) => {
        this.setState({
            showContainer: false,
            container: '',
            containerSel: null,
            containerId: '',
            lot: null,
            locationLastSeen: '',
            quantityMissing: '',
            quantityUOM: '',
            quantityUOMSel: null,
            containerIndex: -1
        });
    };

    saveContainer = () => {
        if(!this.state.enableSave.container)
            return;
        this.setState({ enableSave: {...this.state.enableSave, container: false }}, () => this._saveContainer());
    }

    _saveContainer = async (e) => {
        const t = this.props.t;
        let valid = this.validateContainer();
        if(!valid) {
            this.setState({ enableSave: {...this.state.enableSave, container: true }});
            return;
        }            
        let data = {
            _id: this.state._id,
            containerId: this.state.containerId,
            container: this.state.container,
            lot: this.state.lot._id,
            locationLastSeen: this.state.locationLastSeen,
            quantityMissing: this.state.quantityMissing,
            quantityUOM: this.state.quantityUOM
        };
        try {
            let result = await axios.post('/api/losscontainer', data);
            this.setState({ enableSave: {...this.state.enableSave, container: true }}, () => {
                this.clearContainer();
                this.loadContainers();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });            
        } catch(err){
            this.setState({ enableSave: {...this.state.enableSave, container: true }}, () => {
                 this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    };

    async loadContainers(){
        let result = await axios.get('/api/losscontainers/' + this.state._id);
        let containers = result.data;
        for(let row of containers){
            let uomSel = this.state.uoms.find(x => x.value === row.quantityUOM);
            row.uomSel = uomSel;
        }
        this.setState({
            containers: containers
        });
    }

    validateContainer(){
        const t = this.props.t;
        let state = this.state;
        let errors = {};
        if(state.container == null || state.container === '')
            errors[t('container')] = t('required');
        if(state.locationLastSeen == null || state.locationLastSeen === '')
            errors[t('locationLastSeen')] = t('required');
        if(state.quantityMissing == null || state.quantityMissing === '' || state.quantityMissing === '0')
            errors[t('quantityMissing')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    };

    deleteContainer = async (e) => {
        const t = this.props.t;
        if(this.state.containerId != null && this.state.containerId !== ''){
            let data = {
                _id: this.state._id,
                containerId: this.state.containerId
            }
            try {
                let result = await axios.post('/api/losscontainerdelete', data);
                this.clearContainer();
                this.loadContainers();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            } catch(err){
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.clearContainer();
        }
    };

    handleFileUpload(file){
        const t = this.props.t;
        let files = file.target.files;
        let reader = new FileReader();
        reader.readAsBinaryString(files[0]);
        reader.onload = () => {
            let body = {
                file: base64.encode(reader.result),
                fileName: files[0].name,
                type: files[0].type,
            };
          axios.post('/api/documents/add', body)
          .then(data => {
            let docs = this.state.policeReports;
            docs.push(data.data.id);
            this.setState({
                policeReports: docs
            })
          })
          .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
          });
        }
    }

    addAction = (e) => {
        this.setState({editCapa: true})
    };

    closeDialog = (e) => {
        this.setState({
            editCapa: false,
            capaId: '',
            capaIndex: -1
        },() => {this.loadRecords();});
    };

    editCapa = (i) => (e) => {
        let capa = this.state.capaActions[i];
        this.setState({capaId: capa._id, editCapa: true, capaIndex: i});
    };

    sortClick(name) {
        let orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    toggleDialog = () => {
        this.setState({ dialogOpen: !this.state.dialogOpen });
    };

    render(){
        const errors = this.props.errors;
        const signed = this.state.signed;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar goBack={this.state.goBack}>
                    <ActionBar
                        fieldAccess={security.update}
                        flagForSignature={this.state.flagForSignature}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.loss}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Panel>
                            <Grid container spacing={3}>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!signed &&
                                        <DateSelect
                                            onChange={this.dateChange('lossDate')}
                                            value={this.state.lossDate}
                                            helperText={errors[t('lossDate')]}
                                            error={errors[t('lossDate')] != null ? true : false}
                                            disabled={signed}
                                            label={t('date')}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('date')}</FormLabel>
                                            <DateDisplay value={this.state.lossDate} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {!signed &&
                                        <AutoCompleteField
                                            value={this.state.locationSel}
                                            options={this.state.locations}
                                            onChange={this.changeAuto('locationSel', 'location')}
                                            error={errors[t('location')] != null ? true : false}
                                            helperText={errors[t('location')]}
                                            label={t('location')}
                                            disabled={signed}
                                            required
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    {!signed &&
                                        <AutoCompleteField
                                            value={this.state.typeSel}
                                            options={this.state.types}
                                            onChange={this.changeAuto('typeSel', 'type')}
                                            error={errors[t('type')] != null ? true : false}
                                            helperText={errors[t('type')]}
                                            label={t('type')}
                                            disabled={signed}
                                            required
                                        />
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('type')}</FormLabel>
                                            {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <CheckboxField
                                        checked={this.state.otherCheck} onChange={this.otherChanged} 
                                        label={t('other')} name="otherCheck" disabled={signed}
                                    />
                                </Grid>
                                {this.state.otherCheck &&
                                    <Grid item xs={6} sm={3}>
                                        {!signed &&
                                            <TextField value={this.state.other} onChange={this.changeValue} name="other"
                                            size="medium" fullWidth={true} disabled={signed} label={t('other')} required
                                            error={errors[t('other')] != null ? true : false} helperText={errors[t('other')]}/>
                                        }
                                        {signed &&
                                            <FormControl>
                                                <FormLabel>{t('other')}</FormLabel>
                                                {this.state.other}
                                            </FormControl>
                                        }
                                    </Grid>
                                }
                                <Grid item xs={10} sm={8}>
                                    <AutoCompleteField
                                        multiple={true}
                                        value={this.state.itemsSel}
                                        options={this.state.itemList}
                                        onChange={this.changeMulti('itemsSel', 'items')}
                                        error={errors[t('items')] != null ? true : false}
                                        helperText={errors[t('items')]}
                                        label={t('items')}
                                        disabled={signed}
                                    />
                                </Grid>
                                <Grid item xs={8} sm={5}>
                                    <CheckboxField
                                        checked={this.state.hasBeenReported} onChange={this.reportedChanged} disabled={signed}
                                        label={t('reportedToAuthorities')} name="hasBeenReported"
                                    />
                                </Grid>
                                {this.state.hasBeenReported &&
                                    <Aux>
                                        <Grid item xs={4} sm={2}>
                                            {signed &&
                                                <DateSelect
                                                    onChange={this.dateChange('dateReported')}
                                                    value={this.state.dateReported}
                                                    helperText={errors[t('dateReported')]}
                                                    error={errors[t('dateReported')] != null ? true : false}
                                                    disabled={signed}
                                                    label={t('date')}
                                                    format={this.props.auth.user.dateFormat}
                                                />
                                            }
                                            {signed &&
                                                <FormControl>
                                                    <FormLabel>{t('date')}</FormLabel>
                                                    <DateDisplay value={this.state.dateReported} format={this.props.auth.user.dateFormat}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={8} sm={4}>
                                            {!signed &&
                                                <TextField value={this.state.authority} onChange={this.changeValue} name="authority"
                                                size="medium" fullWidth={true} disabled={signed} label={t('nameOfAuthority')}
                                                error={errors[t('authority')] != null ? true : false} helperText={errors[t('authority')]}/>
                                            }
                                            {signed &&
                                                <FormControl>
                                                    <FormLabel>{t('nameOfAuthority')}</FormLabel>
                                                    {this.state.authority}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            {!signed &&
                                                <TextField value={this.state.incidentNumber} onChange={this.changeValue} name="incidentNumber"
                                                size="medium" fullWidth={true} disabled={signed} label={t('reportNumber')}
                                                error={errors[t('incidentNumber')] != null ? true : false} helperText={errors[t('incidentNumber')]}/>
                                            }
                                            {signed &&
                                                <FormControl>
                                                    <FormLabel>{t('reportNumber')}</FormLabel>
                                                    {this.state.incidentNumber}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            {!signed &&
                                                <InputMaskField mask="999-999-9999" name="authorityTelephone" value={this.state.authorityTelephone}
                                                onChange={this.changeValue} disabled={signed}
                                                helperText={errors[t('authorityTelephone')]}
                                                error={errors[t('authorityTelephone')] != null ? true : false}
                                                label={t('phoneNumber')}
                                                />
                                            }
                                            {signed &&
                                                <FormControl>
                                                    <FormLabel>{t('phoneNumber')}</FormLabel>
                                                    {this.state.authorityTelephone}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={8} sm={4}>
                                            {!signed &&
                                                <TextField value={this.state.officerName} onChange={this.changeValue} name="officerName"
                                                size="medium" fullWidth={true} disabled={signed} label={t('investigatingOfficer')}
                                                error={errors[t('officerName')] != null ? true : false} helperText={errors[t('officerName')]}/>
                                            }
                                            {signed &&
                                                <FormControl>
                                                    <FormLabel>{t('investigatingOfficer')}</FormLabel>
                                                    {this.state.officerName}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <label>{t('policeReports')}</label>
                                        </Grid>
                                        <Grid item xs={8} sm={4}>
                                            <input type="file" onChange={this.handleFileUpload} multiple={false} disabled={signed}/>
                                        </Grid>
                                        <Grid item xs={10} sm={5}>
                                            <DocumentList documents={this.state.policeReports != null ? this.state.policeReports : []}/>
                                        </Grid>
                                    </Aux>
                                }
                                <Grid item sm={11}>
                                    {!signed &&
                                        <TextField value={this.state.details} onChange={this.changeValue} name="details" multiline={true} rows="3"
                                        variant="outlined" size="medium" fullWidth={true} disabled={signed} label={t('details')}
                                        error={errors[t('details')] != null ? true : false} helperText={errors[t('details')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('details')}</FormLabel>
                                            {this.state.details}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item sm={11}>
                                    {!signed &&
                                        <TextField value={this.state.breach} onChange={this.changeValue} name="breach" multiline={true} rows="3"
                                        variant="outlined" size="medium" fullWidth={true} disabled={signed} label={t('securityBreach')}
                                        error={errors[t('breach')] != null ? true : false} helperText={errors[t('breach')]}/>
                                    }
                                    {signed &&
                                        <FormControl>
                                            <FormLabel>{t('securityBreach')}</FormLabel>
                                            {this.state.breach}
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            <br/>
                            {this.state._id != null && this.state._id !== '' &&
                                <Aux>
                                    <SectionTitle title={t('containersMissing')}/>
                                    {this.state.containers != null && this.state.containers.length > 0 &&
                                        <Aux>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} sm={3}>{t('container')}</Grid>
                                                <Grid item xs={6} sm={3}>{t('lot')}</Grid>
                                                <Grid item xs={6} sm={3}>{t('lastSeen')}</Grid>
                                                <Grid item xs={6} sm={3}>{t('amountMissing')}</Grid>
                                            </Grid>
                                            {this.state.containers.map((row, i) => 
                                                <Grid container spacing={3} key={row._id}>
                                                    <Grid item xs={6} sm={3}>
                                                        <TextOnlyClickButton enabled={true} click={this.editContainer(i)} name={row.container.number}/>
                                                    </Grid>
                                                    <Grid item xs={6} sm={3}>{row.lot.number}</Grid>
                                                    <Grid item xs={6} sm={3}>{row.locationLastSeen}</Grid>
                                                    <Grid item xs={6} sm={3}>{row.quantityMissing.toString() + row.uomSel.label}</Grid>
                                                </Grid>
                                            )}
                                        </Aux>
                                    }
                                    <Button variant="outlined" color="primary" size="sm" onClick={this.addContainer} disabled={signed}>{t('add')}</Button>
                                    <br/>
                                    <br/>
                                    <SectionTitle title={t('correctiveMeasures')}/>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="capaActions">
                                            <TableHead>
                                                <TableRow key="capaHeader">
                                                    <TableCell>{t('number')}</TableCell>
                                                    <TableCell>{t('title')}</TableCell>
                                                    <TableCell>{t('dueDate')}</TableCell>
                                                    <TableCell>{t('status')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.capaActions.map((row, i) =>
                                                    <TableRow key={row._id}>
                                                        <TableCell>
                                                            <TextOnlyClickButton enabled={true} click={this.editCapa(i)} name={row.number}/>
                                                        </TableCell>
                                                        <TableCell>{row.title}</TableCell>
                                                        <TableCell><DateDisplay value={row.dueDate} format={this.props.auth.user.dateFormat}/></TableCell>
                                                        <TableCell>{row.status}</TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow key="tableFooter">
                                                    <TablePagination count={this.state.totalCount}
                                                    rowsPerPage={this.state.listSize}
                                                    onPageChange={this.handleChangePage}
                                                    page = {this.state.page}
                                                    rowsPerPageOptions={common.rowOptions}
                                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                    <Grid container spacing={3}>
                                        <Grid item sm={8}>
                                            <Button variant="outlined" color="primary" size="small" onClick={this.addAction}>{t('add')}</Button>
                                        </Grid>
                                    </Grid>
                                    {this.state.editCapa &&
                                        <CapaAction
                                            reload={this.loadRecords}
                                            open={this.state.editCapa}
                                            onClose={this.closeDialog}
                                            loss={this.state._id}
                                            id={this.state.capaId}
                                            users={this.state.users}
                                            qas={this.state.qas}
                                            managers={this.state.managers}
                                        />
                                    }
                                </Aux>
                            }
                            <Grid container spacing={3}>
                                <Grid item sm={12}>
                                    <SaveButton saveClick={this.saveClick} enabled={!signed && this.state.enableSave.record}/>
                                    {signed &&
                                        <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.loss} callback={this.loadRecord}/>
                                    }
                                </Grid>
                            </Grid>
                            {this.state._id != null && this.state._id !== '' &&
                                <Aux>
                                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.loss}/>
                                    <ObjectLog id={this.state._id}/>
                                </Aux>
                            }
                        </Panel>
                        <Dialog open={this.state.showContainer} onClose={this.clearContainer} maxWidth='md' fullWidth>
                            <DialogTitle>
                                <SectionTitle title={t('container')}/>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={3}>
                                        {!signed &&
                                            <AutoCompleteField
                                                value={this.state.containerSel}
                                                options={this.state.containerList}
                                                onChange={this.changeAuto('containerSel', 'container')}
                                                error={errors[t('container')] != null ? true : false}
                                                helperText={errors[t('container')]}
                                                label={t('container')}
                                                disabled={signed}
                                                required
                                                groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                noneMessage={t('noLotAssigned')}
                                            />
                                        }
                                        {signed &&
                                            <FormControl>
                                                <FormLabel>{t('container')}</FormLabel>
                                                {this.state.containerSel != null ? this.state.containerSel.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                    {this.state.lot != null &&
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>Lot</FormLabel>
                                                {this.state.lot != null ? this.state.lot.number : ''}
                                            </FormControl>
                                        </Grid>
                                    }
                                    <Grid item xs={6} sm={3}>
                                        {!signed &&
                                            <TextField value={this.state.locationLastSeen} onChange={this.changeValue} name="locationLastSeen"
                                            size="medium" fullWidth={true} disabled={signed} label={t('lastSeen')} required
                                            error={errors[t('locationLastSeen')] != null ? true : false} helperText={errors[t('locationLastSeen')]}/>
                                        }
                                        {signed &&
                                            <FormControl>
                                                <FormLabel>{t('lastSeen')}</FormLabel>
                                                {this.state.locationLastSeen}
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        {!signed &&
                                            <TextField value={this.state.quantityMissing} onChange={this.changeNumber} name="quantityMissing"
                                            size="medium" fullWidth={true} disabled={signed} label={t('amountMissing')} required
                                            error={errors[t('quantityMissing')] != null ? true : false} helperText={errors[t('quantityMissing')]}/>
                                        }
                                        {signed &&
                                            <FormControl>
                                                <FormLabel>{t('amountMissing')}</FormLabel>
                                                {this.state.quantityMissing}
                                            </FormControl>
                                        }
                                    </Grid>
                                    {this.state.quantityUOM != null && this.state.quantityUOM !== '' &&
                                        <Grid item xs={2} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('uom')}</FormLabel>
                                                {this.state.quantityUOMSel.label}
                                            </FormControl>
                                        </Grid>
                                    }
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <SaveButton saveClick={this.saveContainer} enabled={!signed && this.state.enableSave}/>
                                <ClearButton clearClick={this.clearContainer} enabled={true}/>
                                <DeleteButton deleteClick={this.deleteContainer} enabled={!signed}/>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(LossCannabis));