import React, { Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import EditIcon from '@mui/icons-material/EditTwoTone';
import IconButton from '@mui/material/IconButton';
import Chart from 'react-google-charts';
import Button from '@mui/material/Button';
import isEmpty from '../../is-empty';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SectionTitle from '../UI/Typography/SectionTitle';
import ConversionHelper from '../../helpers/conversionHelper';
import conversionHelper from '../../helpers/conversionHelper';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class ProductionSchedule extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            productionPlan: null,
            documents: [],
            status: '',
            statuses: [],
            statusSel: null,
            number: '',
            uoms: [],
            timeUOMs: [],
            items: [],
            activities: [],
            locked: true,
            outputItem: '',
            outputItemSel: null,
            outputQuantity: '',
            outputUOM: '',
            outputUOMSel: null,
            deliveryDate: null,
            signed: false,
            users: [],
            chartData: null,
            testInstances: [],
            startItem: null,
            batches: [],
            chartHeight: 0,
            dryings: [],
            trimmings: [],
            curings: [],
            showCancel: false,
            cancelReason: '',
            cancelReasonSel: null,
            cancelReasons: [],
            cancelDetail: '',
            cancelled: false,
            productionBatch: null,
            location: null,
            security: [],
            enableSave: true
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.quantityUOM, common.cacheValues.timeUOM, common.cacheValues.producedItem, 
            common.cacheValues.productionScheduleCancelReason, common.cacheValues.productionScheduleStatus, common.cacheValues.user], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let statusSel = cacheValues.productionScheduleStatuses.find(x => x.value === this.props.auth.constants.productionScheduleStatuses.new);
        this.setState({
            statuses: cacheValues.productionScheduleStatuses,
            status: this.props.auth.constants.productionScheduleStatuses.new,
            items: cacheValues.producedItems,
            uoms: cacheValues.quantityUOMs,
            timeUOMs: cacheValues.timeUOMs,
            statusSel: statusSel,
            users: cacheValues.users,
            cancelReasons: cacheValues.productionScheduleCancelReasons,
            security: []
        }, () => {
            const crumbs = [
                { path: '/#/productionschedules', label: this.props.t('productionSchedules'), screen: this.props.auth.screenDefs.ProductionSchedules},
                { path: 'active', label: this.props.t('productionSchedule'), screen: this.props.auth.screenDefs.ProductionSchedule}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecord();
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/productionschedule/' + this.props.match.params.id);
        let statusSel = this.state.statuses.find(x => x.value === record.data.status);
        let outputItemSel = record.data.items.find(x => x.value === record.data.outputItem);
        let outputUOMSel = this.state.uoms.find(x => x.value === record.data.outputUOM);
        let currDate = new Date();
        record.data.activities.forEach(act => {
            let poSel = act.productionOrder != null ? act.productionOrders.find(x => x.value === act.productionOrder._id) : null;
            act.poSel = poSel;
            let userSel = act.user != null ? this.state.users.find(x => x.value === act.user) : null;
            act.userSel = userSel;
            act.late = false;
            act.finished = false;
            act.under = false
            let batchSel = act.evoraBatch != null ? record.data.batches.find(x => x.value === act.evoraBatch._id) : null;
            act.batchSel = batchSel;
            act.days = ConversionHelper.dateDiffDays(act.expectedEnd, act.expectedStart);
            if((act.productionOrder == null && Date.parse(act.expectedEnd) < currDate) ||
                (act.productionOrder != null && act.productionOrder.status._id !==
                    this.props.auth.constants.productionOrderStatuses.finished && Date.parse(act.expectedDate) < currDate)){
                act.late = true;
            } else if(act.propuctionOrder != null && act.prductionOrder.status._id ===
                this.props.auth.constants.productionOrderStatuses.finished &&
                Date.parse(act.expectedDate < Date.parse(act.productionOrder.finishedDate))){
                    act.late = true;
            } else if(act.productionOrder != null && act.productionOrder.status._id ===
                this.props.auth.constants.productionOrderStatuses.finished){
                act.finished = true;
            }
            if(act.productionOrder != null && act.productionOrder.quantity != null && act.productionOrder.quantity !== ''){
                if(act.productionOrder.quantity < act.outputQuantity)
                    act.under = true;
            }
            if(act.trimming != null){
                act.trimmingSel = record.data.trimmings.find(x => x.value === act.trimming._id);
            }
            if(act.drying != null){
                act.dryingSel = record.data.dryings.find(x => x.value === act.drying._id);
            }
            let testSel = act.testInstance != null ? record.data.testInstances.find(x => x.value === act.testIntance) : null;
            act.testSel = testSel;
        });
        let cancelled = record.data.status === this.props.auth.constants.productionScheduleStatuses.cancelled ||
            record.data.status === this.props.auth.constants.productionScheduleStatuses.complete;
        let chartData = this.generateGanttLines(record.data.activities);
        let chartHeight = (record.data.activities.length * 42) + 45;
        this.setState({
            _id: record.data._id,
            number: record.data.number,
            productionPlan: record.data.productionPlan,
            status: record.data.status,
            statusSel: statusSel,
            outputItem: record.data.outputItem,
            outputItemSel: outputItemSel,
            outputQuantity: record.data.outputQuantity,
            outputUOM: record.data.outputUOM,
            outputUOMSel: outputUOMSel,
            deliveryDate: record.data.deliveryDate,
            activities: record.data.activities,
            documents: record.data.documents,
            chartData: chartData,
            startItem: record.data.startItem,
            chartHeight: chartHeight,
            dryings: record.data.dryings != null ? record.data.dryings : [],
            trimmings: record.data.trimmings != null ? record.data.trimmings : [],
            testInstances: record.data.testInstances,
            batches: record.data.batches,
            curings: record.data.curings != null ? record.data.curings : [],
            items: record.data.items,
            productionBatch: record.data.productionBatch,
            location: record.data.location,
            cancelled: cancelled
        }, () => {
            const crumbs = [
                { path: '/#/productionschedules', label: this.props.t('productionSchedules'), screen: this.props.auth.screenDefs.ProductionSchedules},
                { path: 'active', label: this.props.t('productionSchedule') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.ProductionSchedule}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    generateGanttLines(activities){
        const t = this.props.t;
        let chartData = [
            [
                { type: 'string', label: 'Task ID' },
                { type: 'string', label: 'Task Name' },
                { type: 'date', label: t('startDate') },
                { type: 'date', label: t("endDate") },
                { type: 'number', label: ('duration') },
                { type: 'number', label: 'Percent Complete' },
                { type: 'string', label: 'Dependencies' },
            ]
        ];
        for(let i = 0; i < activities.length; i++){
            let act = activities[i];
            let prevAct = null;
            let name = '';
            let percent = 0;
            if(i > 0){
                prevAct = activities[i - 1];
            }
            if(act.routing != null){
                percent = act.productionOrder != null && act.finished ? 100 : 0;
                name = act.routing.number + ' - ' + act.routing.name;
            } else if(act.batchTemplate != null){
                name = act.batchTemplate.code + ' - ' + act.batchTemplate.description;
                act.finished = 0;
            } else if(act.type != null){
                name = act.type === this.props.auth.constants.objectIds.dryingOrder ? t('drying') : act.type === this.props.auth.constants.objectIds.trimmingOrder ?
                    t('trimming') : act.type === this.props.auth.constants.objectIds.curing ? t('curing') : act.name;
            } else {
                name = act.name;
                act.finished = 0;
            }

            chartData.push([
                act._id,
                name,
                new Date(act.expectedStart),
                new Date(act.expectedEnd),
                null,
                percent,
                prevAct != null ? prevAct._id : null
            ]);
        }
        return chartData;
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeLineAuto = (name, data, index) => async (e, newValue) => {
        let activities = this.state.activities;
        let act = activities[index];
        act[name] = newValue;
        let status = this.state.status;
        if(newValue != null){
            act[data] = newValue.value;
            if(data === "productionOrder"){
                let query = {
                   id: this.state._id,
                   poID: newValue.value,
                   index: index
                };
                let po = await axios.post('/api/prodschedsetpo/', query);
                act.productionOrder = po.data;
                if(status === this.props.auth.constants.productionScheduleStatuses.new){
                    status = this.props.auth.constants.productionScheduleStatuses.inprogress;
                }
            } else if (data === "user"){
                let query = {
                    id: this.state._id,
                    user: newValue.value,
                    index: index
                };
                await axios.post('/api/prodschedsetuser', query);
            } else if(data === 'batch'){
                let query = {
                    id: this.state._id,
                    batch: newValue.value,
                    index: index
                };
                let batch = await axios.post('/api/prodschedsetbatch', query);
                act.batch = batch.data;
            } else if(data === 'drying'){
                let query = {
                    id: this.state._id,
                    drying: newValue.value,
                    index: index
                }
                let drying = await axios.post('/api/prodschedsetdrying', query);
                act.drying = drying.data;
            } else if (data === 'trimming'){
                let query = {
                    id: this.state._id,
                    trimming: newValue.value,
                    index: index
                }
                let trimming = await axios.post('/api/prodschedsettrimming', query);
                act.trimming = trimming.data;
            } else if(data === 'testInstance'){
                let query = {
                    id: this.state._id,
                    testInstance: newValue.value,
                    index: index
                }
                let test = await axios.post('/api/prodschedsettest', query);
                act.testInstance = test.data;
            }
        }else {
            act[data] = null;
            act[name] = null;
        }

        this.setState({
            activities: activities,
            status: status
        });
    }

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    lineDateChange = (name, index) => (e) => {
        let activities = this.state.activities;
        let act = activities[index];
        let change = conversionHelper.dateDiffDays(e, act.expectedStart);
        if(name === 'expectedStart'){
            act.expectedEnd = conversionHelper.addDateDays(act.expectedEnd, change);
        }
        let i = index + 1;
        for(i; i < activities.length; i++){
            let row = activities[i];
            row.expectedStart = conversionHelper.addDateDays(row.expectedStart, change);
            row.expectedEnd = conversionHelper.addDateDays(row.expectedEnd, change);
        }
        let deliveryDate = this.state.deliveryDate;
        deliveryDate = conversionHelper.addDateDays(deliveryDate, change);
        act[name] = e;
        this.setState({activities: activities, deliveryDate: deliveryDate});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value});
    };

    changeLineNumber = (i) => (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value)){
            return;
        }
        let activities = this.state.activities;
        let act = activities[i];
        act[name] = value;
        this.setState({activities: activities});
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    editProductionOrder = (index) => async (e) => {
        let activities = this.state.activities;
        let act = activities[index];
        act.productionOrder = null;
        act.poSel = null;
        let query = {
            id: this.state._id,
            index: index
         };
         await axios.post('/api/prodschedsetpo/', query);
         this.setState({activities: activities});
    };

    editBatch = (index) => async (e) => {
        let activities = this.state.activities;
        let act = activities[index];
        act.batch = null;
        act.batchSel = null;
        let query = {
            id: this.state._id,
            index: index
        };
        await axios.post('/api/prodschedsetbatch', query);
        this.setState({activities: activities});
    };

    editDrying = (index) => async (e) => {
        let activities = this.state.activities;
        let act = activities[index];
        act.drying = null;
        act.dryingSel = null;
        let query = {
            id: this.state._id,
            index: index
        };
        await axios.post('/api/prodschedsetdrying', query);
        this.setState({activities: activities});
    };

    editTrimming = (index) => async (e) => {
        let activities = this.state.activities;
        let act = activities[index];
        act.trimming = null;
        act.trimmingSel = null;
        let query = {
            id: this.state._id,
            index: index
        };
        await axios.post('/api/prodschedsettrimming', query);
        this.setState({activities: activities});
    };

    editTest = (index) => async (e) => {
        let activities = this.state.activities;
        let act = activities[index];
        act.testInstance = null;
        act.testInstanceSel = null;
        let query = {
            id: this.state._id,
            index: index
        };
        await axios.post('/api/prodschedsettest', query);
        this.setState({activities: activities});
    };

    return = (e) => {
        this.props.history.goBack();
    };

    delete = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._delete(e));
    };

    _delete = async (e) => {
        const t = this.props.t;
        let body = {id: this.state._id}
        try {
            let result = await axios.delete('/api/prodscheddelete', {params: body});
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            this.props.history.goBack();
        } catch(err){
            this.setState({enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    lock = (e) => {
        this.setState({locked: !this.state.locked});
    };

    save = async (e) => {        
        if(!this.state.enableSave)
            return;
        this.toggleSave();
        const t = this.props.t;
        let errors = {};
        const state = this.state;
        if(state.outputQuantity == null || state.outputQuantity === '')
            errors[t('outputQuantity')] = t('required');
        if(state.deliveryDate == null || state.deliveryDate === '')
            errors[t('deliveryDate')] = t('required');
        for(let i = 0; i < state.activities.length; i++){
            let row = state.activities[i];
            if(row.routing != null || row.batchTemplate != null || row.type === this.props.auth.constants.objectIds.dryingOrder ||
                row.type === this.props.auth.constants.objectIds.trimmingOrder ||
                row.type === this.props.auth.constants.objectIds.curing){
                if(row.outputQuantity == null || row.outputQuantity === '')
                    errors[`${t('outputQuantity')} (${t('line')} ${i})`] = t('required');
                if(row.expectedStart == null || row.expectedStart === '')
                    errors[`${t('expectedStart')} (${t('line')} ${i})`] = t('required');
                if(row.expectedEnd == null || row.expectedEnd === '')
                    errors[`${t('expectedEnd')} (${t('line')} ${i})`] = t('required');
                else {
                    if(state.deliveryDate != null && state.deliveryDate !== ''){
                        let end = new Date(row.expectedEnd);
                        let delivery = new Date(state.deliveryDate)
                        if(end.getTime() > delivery.getTime())
                            errors[`${t('expectedEnd')} (${t('line')} ${i})`] = t('deliveryDateError')
                    }
                }
            }
            if(row.expectedStart != null && row.expectedEnd != null && row.expectedStart !== '' && row.expectedEnd !== ''){
                let end = new Date(row.expectedEnd);
                let start = new Date(row.expectedStart);
                if(start.getTime() > end.getTime())
                    errors[`${t('expectedStart')} (${t('line')} ${i})`] = t('startAfterEnd');
            }
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            this.setState({enableSave: true});
            return;
        }
        let data = {
            _id: state._id,
            outputQuantity: state.outputQuantity,
            deliveryDate: state.deliveryDate,
            activities: state.activities
        }
        try {
            let result = await axios.post('/api/prodsched', data);
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
        this.toggleSave();
    };

    openCancel = (e) => {
        this.setState({
            showCancel: true
        })
    };

    hideCancel = (e) => {
        this.setState({showCancel: false});
    }

    doCancel = async (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.cancelReason == null || this.state.cancelReason === '')
            errors[t('outputAmount')] = t('required');
        if(this.state.cancelDetail == null || this.state.cancelDetail === '')
            errors[t('cancelDetail')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let data = {
            _id: this.state._id,
            cancelReason: this.state.cancelReason,
            cancelDetail: this.state.cancelDetail
        }
        try {
            let result = await axios.post('/api/prodschedcancel', data);
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    };

    createBatch = async () => {
        const t = this.props.t;
        try {
            let result = await axios.post('/api/productionschedulebatch', {id: this.state._id});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    toggleSave = () => {
        this.setState({ enableSave: !this.state.enableSave });
    }

    render(){
        const errors = this.props.errors;
        const locked = this.state.locked;
        const signed = this.state.signed;
        const cancelled = this.state.cancelled;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar 
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.productionSchedule}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={4} sm={2}>
                            <FormControl>
                                <FormLabel>{t('number')}</FormLabel>
                                {this.state.number != null ? this.state.number: ''}
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <FormControl>
                                <FormLabel>{t('status')}</FormLabel>
                                {this.state.statusSel != null ? this.state.statusSel.label: ''}
                            </FormControl>
                        </Grid>
                        {this.state.location != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('location')}</FormLabel>
                                    {this.state.location.name}
                                </FormControl>
                            </Grid>
                        }
                        {this.state.productionPlan != null &&
                            <Aux>
                                <Grid item xs={4} sm={2}>
                                    <FormControl>
                                        <FormLabel>{t('plan')}</FormLabel>
                                        <Link to={'/productionplan/' + this.state.productionPlan._id}>{this.state.productionPlan.number}</Link>
                                    </FormControl>
                                </Grid>
                                {this.state.productionBatch != null &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('batch')}</FormLabel>
                                            <Link to={'/productionbatch/' + this.state.productionBatch._id}>{this.state.productionBatch.number}</Link>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={8} sm={5}>
                                    <FormControl>
                                        <FormLabel>{t('outputItem')}</FormLabel>
                                        {this.state.outputItemSel != null ? this.state.outputItemSel.label : ''}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <TextField value={this.state.outputQuantity} onChange={this.changeNumber} name="outputQuantity" required
                                        size="medium" fullWidth={true} label={t('quantity') + " (" + this.state.outputUOMSel.label + ')'} disabled={locked}
                                        error={errors[t('outputQuantity')] != null ? true : false} helperText={errors[t('outputQuantity')]}/>
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('quantity')}</FormLabel>
                                            {this.state.outputQuantity}
                                        </FormControl>
                                    }
                                </Grid>
                                {this.state.startItem != null &&
                                    <Grid item xs={6} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('startItem')}</FormLabel>
                                            {this.state.startItem.number + ' - ' + this.state.startItem.name}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <DateSelect
                                            onChange={this.dateChange('deliveryDate')}
                                            value={this.state.deliveryDate}
                                            helperText={errors[t('deliveryDate')]}
                                            error={errors[t('deliveryDate')] != null ? true : false}
                                            label={t('deliveryDate')}
                                            disabled={locked}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                        />
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('deliveryDate')}</FormLabel>
                                            <DateDisplay value={this.state.deliveryDate} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                            </Aux>
                        }
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={10} sm={8}>
                            {this.state.status === this.props.auth.constants.productionScheduleStatuses.new &&
                                this.props.auth.user.isSuper && !cancelled &&
                                <Aux>
                                    <Button variant="contained" color="secondary" size="small" onClick={this.delete}>{t('delete')}</Button>
                                    &nbsp;&nbsp;
                                </Aux>
                            }
                            {this.state.security.unlock &&
                                <Aux>
                                    {!locked && !cancelled &&
                                        <Aux>
                                            <Button disabled={!this.state.enableSave} variant="contained" color="primary" size="small" onClick={this.save}>{t('save')}</Button>
                                            &nbsp;&nbsp;
                                        </Aux>
                                    }
                                    {!cancelled &&
                                        <Aux>
                                            <Button variant="contained" color="secondary" size="small" onClick={this.lock}>
                                                {locked ? t('unlock') : t('lock')}
                                            </Button>&nbsp;&nbsp;
                                        </Aux>
                                    }
                                </Aux>
                            }
                            {this.props.permission.delete && !cancelled &&
                                <Aux>
                                    <Button variant="contained" color="secondary" size="small" onClick={this.openCancel}>
                                        {t('cancel')}
                                    </Button>&nbsp;&nbsp;
                                </Aux>
                            }
                             <Button variant="contained" color="secondary" size="small" onClick={this.return}>{t('return')}</Button>&nbsp;&nbsp;
                            {this.state.productionBatch == null &&
                                <Button variant="contained" color="secondary" size="small" onClick={this.createBatch}>Create Batch</Button>
                            }
                            {this.state.productionBatch != null &&
                                <Button variant="contained" color="secondary" size="small"
                                href={"/#/productionbatch/" + this.state.productionBatch._id}>View Batch</Button>
                            }
                           
                         </Grid>
                    </Grid>
                    <br/>
                    <SectionTitle title={t('activities')}/>
                    {this.state.chartData != null &&
                        <Chart
                            width={'100%'}
                            height={this.state.chartHeight}
                            chartType="Gantt"
                            loader={<div>{t('loadingChart')}</div>}
                            data={this.state.chartData}
                            rootProps={{ 'data-testid': '1' }}
                        />
                    }
                    {this.state.activities.map((row, i) =>
                        <Box borderColor="primary.main" {...defaultProps} key={row._id}>
                            <Grid container spacing={3}>
                                {row.routing != null &&
                                    <Aux>
                                        <Grid item xs={6} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('routing')}</FormLabel>
                                                <Link to={'/routing/' + row.routing._id}>{row.routing.number + ' - ' + row.routing.name}</Link>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('productionBom')}</FormLabel>
                                                <Link to={'/bom/' + row.bom._id}>{row.bom.number + ' - ' + row.bom.description}</Link>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('item')}</FormLabel>
                                                {row.outputItem.number + ' - ' + row.outputItem.name}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <TextField value={row.outputQuantity} onChange={this.changeLineNumber(i)} name="outputQuantity"
                                            size="medium" fullWidth={true} label={t("quantity") + " (" + row.outputUOM.label + ")"} disabled={locked} required
                                            error={errors[`${t('outputQuantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('outputQuantity')} (${t('line')} ${i})`]}/>
                                        </Grid>
                                    </Aux>
                                }
                                {row.batchTemplate != null &&
                                    <Aux>
                                        <Grid item xs={6} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('batchTemplate')}</FormLabel>
                                                <Link to={'/batchtemplate/' + row.batchTemplate._id}>
                                                    {row.batchTemplate.code + ' - ' + row.batchTemplate.description}
                                                </Link>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('item')}</FormLabel>
                                                {row.outputItem.number + ' - ' + row.outputItem.name}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <TextField value={row.outputQuantity} onChange={this.changeLineNumber(i)} name="outputQuantity"
                                            size="medium" fullWidth={true} label={t('quantity') + " (" + row.outputUOM.label + ")"} disabled={locked} required
                                            error={errors[`${t('outputQuantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('outputQuantity')} (${t('line')} ${i})`]}/>
                                        </Grid>
                                    </Aux>
                                }
                                {(row.type === this.props.auth.constants.objectIds.dryingOrder || row.type === this.props.auth.constants.objectIds.trimmingOrder ||
                                    row.type === this.props.auth.constants.objectIds.curing) &&
                                    <Aux>
                                        <Grid item xs={6} sm={3}>
                                            <FormControl>
                                                <FormLabel>Activity</FormLabel>
                                                {row.type === this.props.auth.constants.objectIds.dryingOrder ? t('drying') :
                                                row.type === this.props.auth.constants.objectIds.trimmingOrder ? t('trimming') :
                                                row.type === this.props.auth.constants.objectIds.curing ? t('curing') : '' }
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <FormControl>
                                                <FormLabel>Item</FormLabel>
                                                {row.outputItem.number + ' - ' + row.outputItem.name}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <TextField value={row.outputQuantity} onChange={this.changeLineNumber(i)} name="outputQuantity"
                                            size="medium" fullWidth={true} label={t('quantity') + " (" + row.outputUOM.label + ")"} disabled={locked} required
                                            error={errors[`${t('outputQuantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('outputQuantity')} (${t('line')} ${i})`]}/>
                                        </Grid>
                                    </Aux>
                                }
                                {row.routing == null && row.batchTemplate == null && row.type == null &&
                                    <Grid item xs={6} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('name')}</FormLabel>
                                            {row.name}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <DateSelect
                                            onChange={this.lineDateChange('expectedStart', i)}
                                            value={row.expectedStart}
                                            helperText={errors[`${t('expectedStart')} (${t('line')} ${i})`]}
                                            error={errors[`${t('expectedStart')} (${t('line')} ${i})`] != null ? true : false}
                                            label={t('expectedStart')}
                                            disabled={locked}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                        />
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('expectedStart')}</FormLabel>
                                            <DateDisplay value={row.expectedStart} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!row.late &&
                                        <DateSelect
                                            onChange={this.lineDateChange('expectedEnd', i)}
                                            value={row.expectedEnd}
                                            helperText={errors[`${t('expectedEnd')} (${t('line')} ${i})`]}
                                            error={errors[`${t('expectedEnd')} (${t('line')} ${i})`] != null ? true : false}
                                            label={t('expectedEnd')}
                                            disabled={locked}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                        />
                                    }
                                    {row.late &&
                                        <DateSelect
                                            onChange={this.lineDateChange('expectedEnd', i)}
                                            value={row.expectedEnd}
                                            helperText={errors[`${t('expectedEnd')} (${t('line')} ${i})`]}
                                            error={true}
                                            label={t('expectedEnd')}
                                            disabled={locked}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                        />
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={row.userSel}
                                        options={this.state.users}
                                        onChange={this.changeLineAuto('userSel', 'user', i)}
                                        error={errors[`${t('user')} (${t('line')} ${row})`]  != null ? true : false}
                                        helperText={errors[`${t('user')} (${t('line')} ${row})`]}
                                        label={t('assignee')}
                                        disabled={signed || cancelled}
                                    />
                                </Grid>
                                {row.routing != null && (row.poSel == null || row.poSel === '') &&
                                     <Grid item xs={6} sm={3}>
                                        <AutoCompleteField
                                            value={row.poSel}
                                            options={row.productionOrders}
                                            onChange={this.changeLineAuto('poSel', 'productionOrder', i)}
                                            error={errors[`${t('productionOrder')} (${t('line')} ${row})`]  != null ? true : false}
                                            helperText={errors[`${t('productionOrder')} (${t('line')} ${row})`]}
                                            label={t('productionOrder')}
                                            disabled={signed || cancelled}
                                        />
                                    </Grid>
                                }
                               {row.routing != null && row.poSel != null && row.poSel !== '' &&
                                <Aux>
                                    <Grid item xs={6} sm={3}>
                                        <FormControl>
                                            <FormLabel>{t('productionOrder')}</FormLabel>
                                            <Link to={'/productionorder/' + row.productionOrder._id}>
                                                {row.productionOrder.number + ' - ' + row.productionOrder.description}
                                            </Link>
                                        </FormControl>
                                        <IconButton aria-label="Edit" onClick={this.editProductionOrder(i)}>
                                            <EditIcon  />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('status')}</FormLabel>
                                            {row.productionOrder.status.label}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            {!row.under &&
                                                <FormLabel>{t('actualQuantity')}</FormLabel>
                                            }
                                            {row.under &&
                                                <FormLabel sx={{color: 'red'}}>{t('actualQuantity')}</FormLabel>
                                            }
                                            {row.productionOrder.quantity != null && row.productionOrder.quantity !== '' ?
                                                row.productionOrder.quantity + row.outputUOM.label : ''}
                                        </FormControl>
                                    </Grid>
                                    {row.productionOrder.status._id === this.props.auth.constants.productionOrderStatuses.finished &&
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                {!row.late &&
                                                    <FormLabel>{t('finishedDate')}</FormLabel>
                                                }
                                                {row.late &&
                                                    <FormLabel sx={{color: 'red'}}>{t('finishedDate')}</FormLabel>
                                                }
                                                <DateDisplay value={row.productionOrder.finishedDate} format={this.props.auth.user.dateFormat}/>
                                            </FormControl>
                                        </Grid>
                                    }
                                </Aux>
                               }
                               {row.type != null && row.type === this.props.auth.constants.objectIds.dryingOrder && (row.dryingSel == null || row.dryingSel === '') &&
                                    <Aux>
                                        <Grid item xs={6} sm={3}>
                                            <AutoCompleteField
                                                value={row.dryingSel}
                                                options={this.state.dryings}
                                                onChange={this.changeLineAuto('dryingSel', 'drying', i)}
                                                error={errors[`${t('drying')} (${t('line')} ${row})`]  != null ? true : false}
                                                helperText={errors[`${t('drying')} (${t('line')} ${row})`]}
                                                label={t('drying')}
                                                disabled={signed || cancelled}
                                            />
                                        </Grid>
                                        {!cancelled && !signed &&
                                            <Grid item xs={6} sm={3}>
                                                <Button variant="outlined" color="primary" size="small" href="/#/drying">{t('createDrying')}</Button>
                                            </Grid>
                                        }
                                    </Aux>
                               }
                               {row.type != null && row.type === this.props.auth.constants.objectIds.trimmingOrder && (row.trimmingSel == null || row.trimmingSel === '') &&
                                    <Aux>
                                        <Grid item xs={6} sm={3}>
                                            <AutoCompleteField
                                                value={row.trimmingSel}
                                                options={this.state.trimmings}
                                                onChange={this.changeLineAuto('trimmingSel', 'trimming', i)}
                                                error={errors[`${t('trimming')} (${t('line')} ${row})`]  != null ? true : false}
                                                helperText={errors[`${t('trimming')} (${t('line')} ${row})`]}
                                                label={t('trimming')}
                                                disabled={signed || cancelled}
                                            />
                                        </Grid>
                                        {!cancelled && !signed &&
                                            <Grid item xs={6} sm={3}>
                                                <Button variant="outlined" color="primary" size="small" href="/#/trimming">{t('createTrimming')}</Button>
                                            </Grid>
                                        }
                                    </Aux>
                               }
                               {row.type != null && row.type === this.props.auth.constants.objectIds.curing && (row.curingSel == null || row.curingSel === '') &&
                                    <Aux>
                                        <Grid item xs={6} sm={3}>
                                            <AutoCompleteField
                                                value={row.curingSel}
                                                options={this.state.curings}
                                                onChange={this.changeLineAuto('curingSel', 'curing', i)}
                                                error={errors[`${t('curing')} (${t('line')} ${row})`]  != null ? true : false}
                                                helperText={errors[`${t('curing')} (${t('line')} ${row})`]}
                                                label={t('curing')}
                                                disabled={signed || cancelled}
                                            />
                                        </Grid>
                                        {!cancelled && !signed &&
                                            <Grid item xs={6} sm={3}>
                                                <Button variant="outlined" color="primary" size="small" href="/#/curing">{t('createCuring')}</Button>
                                            </Grid>
                                        }
                                    </Aux>
                               }
                               {(row.batchTemplate != null) && (row.batchSel == null || row.batchSel === '') &&
                                    <Aux>
                                        <Grid item xs={4} sm={2}>
                                            <AutoCompleteField
                                                value={row.batchSel}
                                                options={this.state.batches}
                                                onChange={this.changeLineAuto('batchSel', 'batch', i)}
                                                error={errors[`${t('batch')} (${t('line')} ${row})`]  != null ? true : false}
                                                helperText={errors[`${t('batch')} (${t('line')} ${row})`]}
                                                label={t('batch')}
                                                disabled={signed || cancelled}
                                            />
                                        </Grid>
                                        {!cancelled && !signed &&
                                            <Grid item xs={6} sm={3}>
                                                <Button variant="outlined" color="primary" size="small" href="/#/evorabatch">{t('createBatch')}</Button>
                                            </Grid>
                                        }
                                    </Aux>
                                }
                                {row.batchSel != null && row.batchSel !== '' &&
                                    <Aux>
                                        <Grid item xs={8} sm={4}>
                                            <FormControl>
                                                <FormLabel>{t('batch')}</FormLabel>
                                                <Link to={'/evorabatch/' + row.evoraBatch._id}>
                                                    {row.evoraBatch.number + ' - ' + row.evoraBatch.description}
                                                </Link>
                                            </FormControl>
                                            <IconButton aria-label="Edit" onClick={this.editBatch(i)}>
                                                <EditIcon  />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('status')}</FormLabel>
                                                {row.evoraBatch.status.label}
                                            </FormControl>
                                        </Grid>
                                    </Aux>
                                }
                                {row.batchTemplate != null && row.batchSel != null && row.batchSel !== '' &&
                                    <Aux>
                                        <Grid item xs={8} sm={4}>
                                            <FormControl>
                                                <FormLabel>{t('batch')}</FormLabel>
                                                <Link to={'/evorabatch/' + row.evoraBatch._id}>
                                                    {row.evoraBatch.number + ' - ' + row.evoraBatch.description}
                                                </Link>
                                            </FormControl>
                                            <IconButton aria-label="Edit" onClick={this.editBatch(i)}>
                                                <EditIcon  />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('status')}</FormLabel>
                                                {row.evoraBatch.status.label}
                                            </FormControl>
                                        </Grid>
                                    </Aux>
                                }
                                {row.type === this.props.auth.constants.objectIds.dryingOrder && row.dryingSel != null && row.dryingSel !== '' &&
                                    <Aux>
                                        <Grid item xs={8} sm={4}>
                                            <FormControl>
                                                <FormLabel>{t('drying')}</FormLabel>
                                                <Link to={'/drying/' + row.drying._id}>
                                                    {row.drying.number}
                                                </Link>
                                            </FormControl>
                                            <IconButton aria-label="Edit" onClick={this.editDrying(i)}>
                                                <EditIcon  />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('status')}</FormLabel>
                                                {row.drying.status.label}
                                            </FormControl>
                                        </Grid>
                                    </Aux>
                                }
                                {row.type === this.props.auth.constants.objectIds.trimmingOrder && row.trimmingSel != null && row.trimmingSel !== '' &&
                                    <Aux>
                                        <Grid item xs={8} sm={4}>
                                            <FormControl>
                                                <FormLabel>{t('trimming')}</FormLabel>
                                                <Link to={'/trimming/' + row.trimming._id}>
                                                    {row.trimming.number}
                                                </Link>
                                            </FormControl>
                                            <IconButton aria-label="Edit" onClick={this.editTrimming(i)}>
                                                <EditIcon  />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('status')}</FormLabel>
                                                {row.trimming.status.label}
                                            </FormControl>
                                        </Grid>
                                    </Aux>
                                }
                                {row.testRequired && row.testInstance == null &&
                                    <Grid item xs={6} sm={3}>
                                        <AutoCompleteField
                                            value={row.testSel}
                                            options={this.state.testInstances}
                                            onChange={this.changeLineAuto('testSel', 'testInstance', i)}
                                            error={errors[`${t('testInstance')} (${t('line')} ${row})`]  != null ? true : false}
                                            helperText={errors[`${t('testInstance')} (${t('line')} ${row})`]}
                                            label={t('externalTest')}
                                            disabled={signed || cancelled}
                                        />
                                    </Grid>
                                }
                                {row.testRequired && row.testInstance != null &&
                                    <Aux>
                                        <Grid item xs={6} sm={3}>
                                            <FormControl>
                                                <FormLabel>{t('test')}</FormLabel>
                                                <Link to={'/testresult/' + row.testInstance._id}>{row.testInstance.number}</Link>
                                            </FormControl>
                                        </Grid>
                                        <IconButton aria-label="Edit" onClick={this.editTest(i)}>
                                            <EditIcon  />
                                        </IconButton>
                                    </Aux>
                                }
                            </Grid>
                        </Box>
                    )}
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.productionSchedule}/>
                            <ObjectLog id={this.state._id}/>
                        </Aux>
                    }
                </Panel>
                <Dialog open={this.state.showCancel} onClose={this.hideCancel} center maxWidth="md" fullWidth>
                    <DialogTitle>
                        <SectionTitle title={t('cancel')}/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={8} sm={4}>
                                <AutoCompleteField
                                    value={this.state.cancelReasonSel}
                                    options={this.state.cancelReasons}
                                    onChange={this.changeAuto('cancelReasonSel', 'cancelReason')}
                                    error={errors[t('outputAmount')] != null ? true : false}
                                    helperText={errors[t('outputAmount')]}
                                    label={t('reason')}
                                    disabled={cancelled}
                                    required
                                />
                            </Grid>
                            <Grid item sm={10}>
                                <TextField value={this.state.cancelDetail} onChange={this.changeValue} name="cancelDetail"
                                    size="medium" fullWidth={true} label={t('detail')} disabled={cancelled} required
                                    error={errors[t('cancelDetail')] != null ? true : false} helperText={errors[t('cancelDetail')]}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" size="small" onClick={this.doCancel}>{t('process')}</Button>
                        <Button variant="contained" color="secondary" size="small" onClick={this.hideCancel}>{t('close')}</Button>
                    </DialogActions>
                </Dialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ProductionSchedule));
