import React, { useRef, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Tooltip from '@mui/material/Tooltip';
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';

export default function ExportButton(props) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const { list } = props;
    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        };    
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleClick = (index) => {
        if(list != null && list.length > 0) {
            list[index].onClick();
            setOpen(false);
        }        
    }

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const multi = (
        <React.Fragment>
            <Tooltip title='Print' placement='top'>
                <IconButton onClick={handleToggle} id="exportIcon" aria-haspopup="true" sx={{ borderRadius: '4px', margin: 0, color: 'primary.main', minWidth: 0 }}
                            ref={anchorRef} disableRipple
                            aria-controls={open ? 'menu-list-grow' : undefined}>
                            <ExcelIcon sx={{ borderRadius: '4px' }}/>
                </IconButton>
            </Tooltip>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps }) => (
                <Grow
                {...TransitionProps}
                sx={{ transformOrigin: 'center bottom' }}
                >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="exportMenuList" onKeyDown={handleListKeyDown}>
                            {list != null && list.length > 1 && list.map((item, index) => (
                                <MenuItem key={'printBtn-' + index} onClick={() => handleClick(index)}>{item.label}</MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
                </Grow>
            )}
            </Popper>
        </React.Fragment>
    );

    const single = (
        <Tooltip title='Print' placement='top'>
            <IconButton onClick={() => handleClick(0)} id="exportIcon" aria-haspopup="true" sx={{ borderRadius: '4px', color: 'primary.main' }}>
                <ExcelIcon />
            </IconButton>
        </Tooltip>
    );

    if(list != null && list.length > 1) {
        return multi;
    }else {
        return single;
    }
}