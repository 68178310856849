import React from 'react';
import Typography from '@mui/material/Typography';
import Aux from '../../../hoc/Auxilary/Auxilary';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddButton from '@mui/icons-material/AddBoxTwoTone';
import Divider from '@mui/material/Divider';

export default function SubSectionHeading(props) {
  const add = props.add ?? false;
  const disabled = props.addSecurity ?? false;
  return (
    <Aux>
      <Typography variant="h5">
        {props.title}
      </Typography>      
      {(add && !disabled) &&
        <Tooltip title={props.addTitle} placement='right'>
            <IconButton aria-label={props.addTitle} onClick={() => add()}>
                <AddButton color="primary" />
            </IconButton>
        </Tooltip>
      }
      <Divider sx={{ marginBottom: 1}}/>
    </Aux>
  );
}