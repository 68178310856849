import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Fieldset from '../UI/Fieldset/Fieldset';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import { AddBlankButton, ClearButton, TextOnlyClickButton,
         DeleteButton, SaveButton } from '../UI/Buttons/Buttons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class Visitor extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            location: '',
            locations: [],
            firstName: '',
            lastName: '',
            alias: '',
            phoneNumbers: [],
            phoneId: '',
            phoneType: '',
            phoneNumberTypes: [],
            phoneNumber: '',
            reasonForAccess: '',
            companyName: '',
            companySupervisor: '',
            supervisorPhoneNumber: '',
            sponsor: '',
            users: [],
            sponsorDepartment: '',
            departments: [],
            sponsorPhone: '',
            typeOfEntry: '',
            entryTypes: [],
            startDate: null,
            endDate: null,
            singleEntry: true,
            number: '',
            locationSelected: null,
            phoneTypeSelected: null,
            sponsorSelected: null,
            depSel: null,
            typeSelected: null,
            security: [],
            enableSave: true
        };
        this.saveClick = this.saveClick.bind(this);
        this.changeNumber = this.changeNumber.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.startDateChange = this.startDateChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.savePhone = this.savePhone.bind(this);
        this.clearPhone = this.clearPhone.bind(this);
        this.deletePhone = this.deletePhone.bind(this);
        this.addPhone = this.addPhone.bind(this);
    }

    async componentDidMount(){
        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        const values = await axios.get('/api/visitorvalues')
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ? 
            this.props.auth.user.defaultLocation : '';
        let locSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locSel == null && locations.length === 1){
            locSel = locations[0];
            location = locSel.value;
        }
        this.setState({
            phoneNumberTypes: values.data.phoneNumberTypes,
            locations: locations,
            location: location,
            locationSelected: locSel,
            entryTypes: values.data.entryTypes,
            users: values.data.users,
            departments: values.data.departments,
            security: this.props.permission
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord(values.data);
            }else {
                const crumbs = [
                    { path: '/#/visitors', label: this.props.t('visitors'), screen: this.props.auth.screenDefs.Visitor},
                    { path: 'active', label: this.props.t('visitor'), screen: this.props.auth.screenDefs.Visitor}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const id = this.state._id != null && this.state._id !== '' ? this.state._id : this.props.match.params.id;
        const visitor = await axios.get('/api/visitor/' + id);
        const entryTypes = this.state.entryTypes;
        const single = this.isRecurring(visitor.data.typeOfEntry, entryTypes);
        const locationSelected = this.state.locations.find(x => x.value === visitor.data.location);
        const sponsorSelected = this.state.users.find(x => x.value === visitor.data.sponsor);
        const typeSelected = entryTypes.find(x => x.value === visitor.data.typeOfEntry);
        const depSel = this.state.departments.find(x => x.value === visitor.data.sponsorDepartment);
        this.setState({
            _id: visitor.data._id,
            location: visitor.data.location,
            firstName: visitor.data.firstName,
            lastName: visitor.data.lastName,
            alias: visitor.data.alias,
            phoneNumbers: visitor.data.phoneNumbers,
            reasonForAccess: visitor.data.reasonForAccess,
            companyName: visitor.data.companyName,
            companySupervisor: visitor.data.companySupervisor,
            supervisorPhoneNumber: visitor.data.supervisorPhoneNumber,
            sponsor: visitor.data.sponsor,
            sponsorDepartment: visitor.data.sponsorDepartment,
            sponsorPhone: visitor.data.sponsorPhone,
            typeOfEntry: visitor.data.typeOfEntry,
            startDate: visitor.data.startDate,
            endDate: visitor.data.endDate,
            singleEntry: single,
            editPhone: false,
            locationSelected: locationSelected,
            sponsorSelected: sponsorSelected,
            typeSelected: typeSelected,
            number: visitor.data.number,
            depSel: depSel
        }, () => {
            const crumbs = [
                { path: '/#/visitors', label: this.props.t('visitors'), screen: this.props.auth.screenDefs.Visitor},
                { path: 'active', label: this.props.t('visitor') + ' - ' + visitor.data.number, screen: this.props.auth.screenDefs.Visitor}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    }

    changeValue(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if(data === 'typeOfEntry'){
            var single = this.isRecurring(newValue.value, this.state.entryTypes);
            this.setState({singleEntry: single});
        }
    };

    isRecurring(typeOfEntry, types){
        let single = true;
        for(let i = 0; i < types.length; i++){
            if(typeOfEntry === types[i].value){
                single = types[i].label === this.props.t('oneTime') ? true : false;
                break;
            }
        }
        return single;
    }

    changeNumber(name, e){
        this.setState({[name]: e.value});
    }

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const t = this.props.t;
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let phones = [];
        this.state.phoneNumbers.forEach(phone => {
            phones.push({
                number: phone.number,
                type: phone.type._id
            })
        })
        const data = {
            _id: this.state._id,
            location: this.state.location,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            alias: this.state.alias,
            phoneNumbers: phones,
            reasonForAccess: this.state.reasonForAccess,
            companyName: this.state.companyName,
            companySupervisor: this.state.companySupervisor,
            supervisorPhoneNumber: this.state.supervisorPhoneNumber,
            sponsor: this.state.sponsor,
            sponsorDepartment: this.state.sponsorDepartment,
            sponsorPhone: this.state.sponsorPhone,
            typeOfEntry: this.state.typeOfEntry,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        }
        try {
            const response = await axios.post('/api/visitor', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/visitor/' + response.data.id);
            this.setState({_id: response.data.id, number: response.data.number, enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: response.data.message, title: t('success'), severity: 'success'}});
            });            
        }catch(err) {
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    }

    validateForm(){
        const t = this.props.t;
        let errors = {};
        if(this.state.location == null || this.state.location === '')
            errors[t('location')] = t('required');
        if(this.state.firstName == null || this.state.firstName === '')
            errors[t('firstName')] = t('required');
        if(this.state.lastName == null || this.state.lastName === '')
            errors[t('lastName')] = t('required');
        if(this.state.reasonForAccess == null || this.state.reasonForAccess === '')
            errors[t('reasonForAccess')] = t('required');
        if(this.state.sponsor == null || this.state.sponsor === '')
            errors[t('sponsor')] = t('required');
        if(this.state.typeOfEntry == null || this.state.typeOfEntry === '')
            errors[t('typeOfEntry')] = t('required');
        if(this.state.startDate == null || this.state.startDate === '')
            errors[t('startDate')] = t('required');
        if(this.state.phoneNumbers == null || this.state.phoneNumbers.length === 0)
            errors[t('phoneNumbers')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    startDateChange(e){
        this.setState({startDate: e})
    }

    endDateChange(e) {
        this.setState({endDate: e});
    }

    toggleEditPhone() {
        this.setState({ editPhone: !this.state.editPhone });
    }

    editClick(id){
        let selected = {};
        for(let i = 0; i < this.state.phoneNumbers.length; i++){
            if(this.state.phoneNumbers[i]._id === id){
                selected = this.state.phoneNumbers[i];
                break;
            }
        }
        this.setState({
            editPhone: true,
            phoneId: selected._id,
            phoneType: selected.type._id,
            phoneNumber: selected.number,
            phoneTypeSelected: this.state.phoneNumberTypes.find(x => x.value === selected.type._id)
        })
    }

    savePhone(e){
        const valid = this.validatePhone();
        if(valid){
            let selectedType = '';
            for(let i = 0; i < this.state.phoneNumberTypes.length; i++){
                if(this.state.phoneType === this.state.phoneNumberTypes[i].value){
                    selectedType = this.state.phoneNumberTypes[i];
                    break;
                }
            }
            let phones = this.state.phoneNumbers;
            let phone = {
                _id: 'a' + Math.floor(10000 * Math.random()),
                type: {
                    _id: selectedType.value,
                    label: selectedType.label
                },
                number: this.state.phoneNumber
            };
            if(this.state.phoneId === ''){
                phones.push(phone);
            } else {
                for (let a = 0; a < phones.length; a++){
                    if(phones[a]._id === this.state.phoneId){
                        phones[a] = phone;
                        break;
                    }
                }
            }
            this.setState({
                phoneNumbers: phones,
                editPhone: false,
                phoneNumber: '',
                phoneType: '',
                phoneId: '',
                phoneTypeSelected: null
            })
        }
    }

    validatePhone(){
        const t = this.props.t;
        let errors = {};
        let valid = true;
        if(this.state.phoneType == null || this.state.phoneType === ''){
            valid = false;
            errors[t('phoneType')] = t('required');
        }
        if(this.state.phoneNumbers == null || this.state.phoneNumber === ''){
            valid = false;
            errors[t('phoneNumber')] = t('required');
        }
        if(!valid)
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
        return valid;
    }

    clearPhone(e){
        this.setState({
            editPhone: false,
            phoneNumber: '',
            phoneType: '',
            phoneId: '',
            phoneTypeSelected: null
        })
    }

    deletePhone(e){
        let phones = this.state.phoneNumbers;
        for(let i = 0; i < phones.length; i++){
            if(phones[i]._id === this.state.phoneId){
                phones.splice(i, 1);
                break;
            }
        }
        this.setState({
            editPhone: false,
            phoneNumber: '',
            phoneType: '',
            phoneId: '',
            phoneNumbers: phones,
            phoneTypeSelected: null
        })
    }

    addPhone(e){
        this.setState({editPhone: true});
    }
    
    render(){
        const errors = this.props.errors;
        const signed = false;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        {this.state._id != null && this.state._id !== '' &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.number}  name="number"
                                size="medium" fullWidth={true} disabled={true} label={t('number')}/>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.locationSelected}
                                options={this.state.locations}
                                onChange={this.changeAuto('locationSelected', 'location')}
                                error={errors[t('location')] != null ? true : false}
                                helperText={errors[t('location')]}
                                label={t('location')}
                                required
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.firstName} onChange={this.changeValue} name="firstName"
                            size="medium" fullWidth={true} disabled={signed} label={t('firstName')} required
                            error={errors[t('firstName')] != null ? true : false} helperText={errors[t('firstName')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.lastName} onChange={this.changeValue} name="lastName"
                            size="medium" fullWidth="true" label={t('lastName')} required
                            error={errors[t('lastName')] != null ? true : false} helperText={errors[t('lastName')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.alias} onChange={this.changeValue} name="alias"
                            size="medium" fullWidth="true" label={t('aliases')}
                            error={errors[t('alias')] != null ? true : false} helperText={errors[t('alias')]}/>
                        </Grid>
                    </Grid>
                    <hr />
                    <SubSectionHeading title={t('phoneNumbers')} />
                    {this.state.phoneNumbers.length > 0 &&
                        <TableContainer component={Paper}>
                            <Table aria-label="Phone Numbers">
                                <TableHead>
                                    <TableRow key="tableHeader">
                                        <TableCell>{t('type')}</TableCell>
                                        <TableCell>{t('number')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.phoneNumbers.map((row, i) =>
                                        <TableRow key={row._id}>
                                            <TableCell>
                                                <TextOnlyClickButton enabled={true} click={() => this.editClick(row._id)} name={row.type.label}/>
                                            </TableCell>
                                            <TableCell>
                                                <NumericFormat value={row.number} displayType={'text'} 
                                                format={'### ### ####'}/>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {!this.state.editPhone &&
                        <Aux>
                            <br />
                            <AddBlankButton 
                                size="small" 
                                addClick={this.addPhone} 
                                enabled={!signed} 
                                buttonName={t('add')} 
                            />
                            <br />
                        </Aux>
                    }
                    {this.state.editPhone &&
                        <Aux>
                            <Dialog open={this.state.editPhone} onClose={() => this.toggleEditPhone()} aria-labelledby="dialog-edit-phone">
                                <DialogTitle aria-labelledby="dialog-edit-phone-title"><SubSectionHeading title={t('edit')}/></DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <AutoCompleteField
                                                value={this.state.phoneTypeSelected}
                                                options={this.state.phoneNumberTypes}
                                                onChange={this.changeAuto('phoneTypeSelected', 'phoneType')}
                                                error={errors[t('phoneType')] != null ? true : false}
                                                helperText={errors[t('phoneType')]}
                                                label={t('type')}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputMaskField mask="999-999-9999" name="phoneNumber" value={this.state.phoneNumber}
                                                onChange={this.changeValue} disabled={signed}
                                                helperText={errors[t('phoneNumber')]}
                                                error={errors[t('phoneNumber')] != null ? true : false} 
                                                label={t('phoneNumber')} 
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <SaveButton saveClick={() => this.savePhone()} enabled={!signed} />
                                    <ClearButton clearClick={() => this.clearPhone()} enabled={!signed} />
                                    <DeleteButton deleteClick={() => this.deletePhone()} enabled={!signed} />
                                </DialogActions>
                            </Dialog>
                        </Aux>
                    }
                    {errors[t('phoneNumbers')] && <div><div className="error text-danger">{errors[t('phoneNumbers')]}</div><br/></div>}
                    <hr />
                    <Grid container spacing={3}>
                        <Grid item sm={11}>
                            <TextField value={this.state.reasonForAccess} onChange={this.changeValue} name="reasonForAccess"
                                multiline={true} rows="3" required
                                variant="outlined" size="medium" fullWidth={true} disabled={signed} label={t('reason')}
                                error={errors[t('reasonForAccess')] != null ? true : false} helperText={errors[t('reasonForAccess')]}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Fieldset>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={4}>
                                        <TextField value={this.state.companyName} onChange={this.changeValue} name="companyName"
                                        size="medium" fullWidth="true" label={t('company')}
                                        error={errors[t('companyName')] != null ? true : false} helperText={errors[t('companyName')]}/>
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <TextField value={this.state.companySupervisor} onChange={this.changeValue} name="companySupervisor"
                                        size="medium" fullWidth="true" label={t('supervisor')}
                                        error={errors[t('companySupervisor')] != null ? true : false} helperText={errors[t('companySupervisor')]}/>
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <InputMaskField mask="999-999-9999" name="supervisorPhoneNumber" value={this.state.supervisorPhoneNumber}
                                        onChange={this.changeValue} disabled={signed}
                                        helperText={errors[t('supervisorPhone')]}
                                        error={errors[t('supervisorPhone')] != null ? true : false} 
                                        label={t('supervisorPhone')} 
                                        />
                                    </Grid>
                                </Grid>
                            </Fieldset>
                        </Grid>
                        <Grid item xs={6}>
                            <Fieldset>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={4}>
                                        <AutoCompleteField
                                            value={this.state.sponsorSelected}
                                            options={this.state.users}
                                            onChange={this.changeAuto('sponsorSelected', 'sponsor')}
                                            error={errors[t('sponsor')] != null ? true : false}
                                            helperText={errors[t('sponsor')]}
                                            label={t('sponsor')}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <AutoCompleteField
                                            value={this.state.depSel}
                                            options={this.state.departments}
                                            onChange={this.changeAuto('depSel', 'sponsorDepartment')}
                                            error={errors[t('sponsorDepartment')] != null ? true : false}
                                            helperText={errors[t('sponsorDepartment')]}
                                            label={t('department')}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <InputMaskField mask="999-999-9999" name="sponsorPhone" value={this.state.sponsorPhone}
                                        onChange={this.changeValue} disabled={signed}
                                        helperText={errors[t('sponsorPhone')]}
                                        error={errors[t('sponsorPhone')] != null ? true : false} 
                                        label={t('phoneNumber')} 
                                        />
                                    </Grid>
                                </Grid>
                            </Fieldset>
                        </Grid>
                        
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.typeSelected}
                                options={this.state.entryTypes}
                                onChange={this.changeAuto('typeSelected', 'typeOfEntry')}
                                error={errors[t('typeOfEntry')] != null ? true : false}
                                helperText={errors[t('typeOfEntry')]}
                                label={t('type')}
                                required
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateSelect
                                onChange={this.startDateChange}
                                value={this.state.startDate}
                                helperText={errors[t('startDate')]}
                                error={errors[t('startDate')] != null ? true : false}
                                label={this.state.singleEntry ? t('date') : t('startDate')}
                                format={this.props.auth.user.dateFormat}
                                required
                            />
                        </Grid>
                        {!this.state.singleEntry &&
                            <Grid item xs={4} sm={2}>
                                <DateSelect
                                    onChange={this.endDateChange}
                                    value={this.state.endDate}
                                    helperText={errors[t('endDate')]}
                                    error={errors[t('endDate')] != null ? true : false}
                                    label={t('endDate')}
                                    format={this.props.auth.user.dateFormat}
                                />
                            </Grid>
                        }
                    </Grid>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.visitor}/>
                            {(this.props.permission.review || this.props.permission.approve) &&
                                <ObjectLog id={this.state._id}/>
                            }
                        </Aux>
                    }
                </Panel>
                <Grid container spacing={3}>
                    <Grid item sm={4}>
                        <SaveButton saveClick={() => this.saveClick()} enabled={this.state.security.update && this.state.enableSave} />
                    </Grid>
                </Grid>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Visitor));