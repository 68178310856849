import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Panel from '../UI/Panel/Panel';
import Button from '@mui/material/Button';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class WorkCenter extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            name: '',
            room: '',
            roomSel: null,
            rooms: '',
            timeUOMs: [],
            setupTime: '0',
            setupUOM: '',
            setupUOMSel: null,
            minUnitTime: '0',
            minUnitTimeUOM: '',
            minUnitTimeUOMSel: null,
            maxUnitTime: '0',
            maxUnitTimeUOM: '',
            maxUnitTimeUOMSel: null,
            moveTime: '0',
            moveTimeUOM: '',
            moveTimeUOMSel: null,
            maxWorkers: '1',
            capacity: '',
            capacityUOM: '',
            capacityUOMSel: null,
            quantityUOMs: [],
            locked: true,
            security: [],
            enableSave: true,
            advanced: false
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.quantityUOM, common.cacheValues.room, common.cacheValues.timeUOM], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let locked = (this.props.match.params.id == null || this.props.match.params.id === '') && 
            this.props.permission.create ? false : true;
        let minuteValue = cacheValues.timeUOMs.find(x => x.label === 'm');
        this.setState({
            rooms: cacheValues.rooms,
            timeUOMs: cacheValues.timeUOMs,
            quantityUOMs: cacheValues.quantityUOMs,
            setupUOM: minuteValue.value,
            setupUOMSel: minuteValue,
            minUnitTimeUOM: minuteValue.value,
            minUnitTimeUOMSel: minuteValue,
            maxUnitTimeUOM: minuteValue.value,
            maxUnitTimeUOMSel: minuteValue,
            moveTimeUOM: minuteValue.value,
            moveTimeUOMSel: minuteValue,
            locked: locked,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            security: this.props.permission
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/workcenters', label: this.props.t('workCenters'), screen: this.props.auth.screenDefs.WorkCenterList},
                    { path: 'active', label: this.props.t('workCenter'), screen: this.props.auth.screenDefs.WorkCenter}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/workcenter/' + this.state._id);
        let roomSel = this.state.rooms.find(x => x.value === record.data.room);
        let setupUOMSel = this.state.timeUOMs.find(x => x.value === record.data.setupUOM);
        let minUnitTimeUOMSel = this.state.timeUOMs.find(x => x.value === record.data.minUnitTimeUOM);
        let maxUnitTimeUOMSel = this.state.timeUOMs.find(x => x.value === record.data.maxUnitTimeUOM);
        let moveTimeUOMSel = this.state.timeUOMs.find(x => x.value === record.data.moveTimeUOM);
        let capacityUOMSel = this.state.quantityUOMs.find(x => x.value === record.data.capacityUOM);
        let advanced = record.data.minUnitTime !== 0 || record.data.maxUnitTime !== 0 || record.data.moveTime !== 0 || record.data.capacity !== 1 || record.data.setupTime !== 0;
        this.setState({
            number: record.data.number,
            name: record.data.name,
            room: record.data.room,
            roomSel: roomSel,
            setupTime: record.data.setupTime,
            setupUOM: record.data.setupUOM,
            setupUOMSel: setupUOMSel,
            minUnitTime: record.data.minUnitTime,
            minUnitTimeUOM: record.data.minUnitTimeUOM,
            minUnitTimeUOMSel: minUnitTimeUOMSel,
            maxUnitTime: record.data.maxUnitTime,
            maxUnitTimeUOM: record.data.maxUnitTimeUOM,
            maxUnitTimeUOMSel: maxUnitTimeUOMSel,
            moveTime: record.data.moveTime,
            moveTimeUOM: record.data.moveTimeUOM,
            moveTimeUOMSel: moveTimeUOMSel,
            maxWorkers: record.data.maxWorkers,
            capacity: record.data.capacity,
            capacityUOM: record.data.capacityUOM,
            capacityUOMSel: capacityUOMSel,
            advanced: advanced
        }, () => {
            const crumbs = [
                { path: '/#/workcenters', label: this.props.t('workCenters'), screen: this.props.auth.screenDefs.WorkCenterList},
                { path: 'active', label: this.props.t('workCenter') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.WorkCenter}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeBool = (e) => {
        this.setState({ advanced: !this.state.advanced });
    }

    changeNumber = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if(isNaN(value)){
            return;
        }
        this.setState({[name]: value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async (e) => {
        const t = this.props.t;
        let valid = await this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            _id: this.state._id,
            name: this.state.name,
            room: this.state.room,
            setupTime: this.state.setupTime,
            setupUOM: this.state.setupUOM,
            minUnitTime: this.state.minUnitTime,
            minUnitTimeUOM: this.state.minUnitTimeUOM,
            maxUnitTime: this.state.maxUnitTime,
            maxUnitTimeUOM: this.state.maxUnitTimeUOM,
            moveTime: this.state.moveTime,
            moveTimeUOM: this.state.moveTimeUOM,
            maxWorkers: this.state.maxWorkers,
            capacity: this.state.capacity,
            capacityUOM: this.state.capacityUOM
        };
        try {
            let result = await axios.post('/api/workcenter', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/workcenter/' + result.data.id);
            this.setState({_id: result.data.id, number: result.data.number, enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });            
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.name == null || state.name === '')
            errors[t('name')] = t('required');
        if(state.setupTime == null || state.setupTime === '')
            errors[t('setupTime')] = t('required');
        if(state.setupUOM == null || state.setupUOM === '')
            errors[t('setupUOM')] = t('required');
        if(state.minUnitTime == null || state.minUnitTime === '')
            errors[t('minUnitTime')] = t('required');
        if(state.minUnitTimeUOM == null || state.minUnitTimeUOM === '')
            errors[t('minUnitTimeUOM')] = t('required');
        if(state.maxUnitTime == null || state.maxUnitTime === '')
            errors[t('maxUnitTime')] = t('required');
        if(state.maxUnitTimeUOM == null || state.maxUnitTimeUOM === '')
            errors[t('maxUnitTimeUOM')] = t('required');
        if(state.moveTime == null || state.moveTime === '')
            errors[t('moveTime')] = t('required');
        if(state.moveTimeUOM == null || state.moveTimeUOM === '')
            errors[t('moveTimeUOM')] = t('required');
        if(state.maxWorkers == null || state.maxWorkers === '')
            errors[t('maxWorkers')] = t('required');
        if(state.capacity == null || state.capacity === '')
            errors[t('capacity')] = t('required');
        if(state.capacityUOM == null || state.capacityUOM === '')
            errors[t('capacityUOM')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    unlock = (e) => {
        if(this.props.permission.update){
            this.setState({locked: !this.state.locked});
        }
    };

    delete = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._delete(e);
        })
    }

    _delete = async (e) => {
        const t = this.props.t;
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/workcenter', {params: {id: this.state._id}});
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({enableSave: true});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.setState({enableSave: true});
            this.props.history.goBack();
        }
    };

    render(){
        const { advanced, locked } = this.state;
        const errors = this.props.errors;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Panel>
                            <Grid container spacing={2}>
                                {this.state.number != null && this.state.number !== '' &&
                                    <Grid item xs={12}>
                                        <FormControl>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    {!locked &&
                                        <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                        size="medium" fullWidth={true} disabled={locked} label={t('name')} required
                                        error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('name')}</FormLabel>
                                            {this.state.name} 
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {!locked ?
                                        <AutoCompleteField
                                            value={this.state.roomSel}
                                            options={this.state.rooms}
                                            onChange={this.changeAuto('roomSel', 'room')}
                                            error={errors[t('room')] != null ? true : false}
                                            helperText={errors[t('room')]}
                                            label={t('room')}
                                            disabled={locked}
                                        /> : locked && this.state.roomSel != null ?
                                        <FormControl>
                                            <FormLabel>{t('room')}</FormLabel>
                                            {this.state.roomSel != null ? this.state.roomSel.label : ''} 
                                        </FormControl> : 
                                        <FormControl>
                                            <FormLabel>{t('room')}</FormLabel>
                                            {t('notDefined')} 
                                        </FormControl>
                                    }                                        
                                </Grid>
                                <Grid item xs={6}>
                                    {!locked &&
                                        <TextField type="number" value={this.state.capacity} onChange={this.changeNumber} name="capacity"
                                        size="medium" fullWidth={true} disabled={locked} label={t('capacity')} required
                                        error={errors[t('capacity')] != null ? true : false} helperText={errors[t('capacity')]}/>
                                    }
                                    {locked &&
                                        <FormControl>
                                            <FormLabel>{t('capacity')}</FormLabel>
                                            {this.state.capacityUOMSel != null ? this.state.capacity + this.state.capacityUOMSel.label : this.state.capacity} 
                                        </FormControl>
                                    }
                                </Grid>
                                {!locked &&
                                    <Grid item xs={6}>
                                        <AutoCompleteField
                                            value={this.state.capacityUOMSel}
                                            options={this.state.quantityUOMs}
                                            onChange={this.changeAuto('capacityUOMSel', 'capacityUOM')}
                                            error={errors[t('capacityUOM')] != null ? true : false}
                                            helperText={errors[t('capacityUOM')]}
                                            label={t('uom')}
                                            disabled={locked}
                                            required
                                        />
                                    </Grid>
                                }
                                {!locked &&
                                    <Grid item>
                                        <CheckboxField
                                        checked={this.state.advanced} onChange={() => this.changeBool()}
                                        label={t('advanced')} name="advanced"
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Panel>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Panel>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                {!locked &&
                                    <TextField type="number" value={this.state.setupTime} onChange={this.changeNumber} name="setupTime"
                                    size="medium" fullWidth={true} disabled={!advanced} label={t('setupTime')} required
                                    error={errors[t('setupTime')] != null ? true : false} helperText={errors[t('setupTime')]}/>
                                }
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('setupTime')}</FormLabel>
                                        {this.state.setupUOMSel != null ? this.state.setupTime + this.state.setupUOMSel.label : this.state.setupTime} 
                                    </FormControl>
                                }
                            </Grid>
                            {!locked &&
                                <Grid item xs={6}>
                                    <AutoCompleteField
                                        value={this.state.setupUOMSel}
                                        options={this.state.timeUOMs}
                                        onChange={this.changeAuto('setupUOMSel', 'setupUOM')}
                                        error={errors[t('setupUOM')] != null ? true : false}
                                        helperText={errors[t('setupUOM')]}
                                        label={t('uom')}
                                        disabled={!advanced}
                                        required
                                    />
                                </Grid>
                            }
                            <Grid item xs={6}>
                                {!locked &&
                                    <TextField type="number" value={this.state.moveTime} onChange={this.changeNumber} name="moveTime"
                                    size="medium" fullWidth={true} disabled={!advanced} label={t('moveTime')} required
                                    error={errors[t('moveTime')] != null ? true : false} helperText={errors[t('moveTime')]}/>
                                }
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('moveTime')}</FormLabel>
                                        {this.state.moveTimeUOMSel != null ? this.state.moveTime + this.state.moveTimeUOMSel.label : 
                                        this.state.moveTime} 
                                    </FormControl>
                                }
                            </Grid>
                            {!locked &&
                                <Grid item xs={6}>
                                    <AutoCompleteField
                                        value={this.state.moveTimeUOMSel}
                                        options={this.state.timeUOMs}
                                        onChange={this.changeAuto('moveTimeUOMSel', 'moveTimeUOM')}
                                        error={errors[t('moveTimeUOM')] != null ? true : false}
                                        helperText={errors[t('moveTimeUOM')]}
                                        label={t('uom')}
                                        disabled={!advanced}
                                        required
                                    />
                                </Grid>
                            }
                            <Grid item xs={6}>
                                {!locked &&
                                    <TextField type="number" value={this.state.minUnitTime} onChange={this.changeNumber} name="minUnitTime"
                                    size="medium" fullWidth={true} disabled={!advanced} label={t('minUnitTime')} required
                                    error={errors[t('minUnitTime')] != null ? true : false} helperText={errors[t('minUnitTime')]}/>
                                }
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('minUnitTime')}</FormLabel>
                                        {this.state.minUnitTimeUOMSel != null ? this.state.minUnitTime + this.state.minUnitTimeUOMSel.label : 
                                        this.state.minUnitTime} 
                                    </FormControl>
                                }
                            </Grid>
                            {!locked &&
                                <Grid item xs={6}>
                                    <AutoCompleteField
                                        value={this.state.minUnitTimeUOMSel}
                                        options={this.state.timeUOMs}
                                        onChange={this.changeAuto('minUnitTimeUOMSel', 'minUnitTimeUOM')}
                                        error={errors[t('minUnitTimeUOM')] != null ? true : false}
                                        helperText={errors[t('minUnitTimeUOM')]}
                                        label={t('uom')}
                                        disabled={!advanced}
                                        required
                                    />
                                </Grid>
                            }
                            <Grid item xs={6}>
                                {!locked &&
                                    <TextField type="number" value={this.state.maxUnitTime} onChange={this.changeNumber} name="maxUnitTime"
                                    size="medium" fullWidth={true} disabled={!advanced} label={t('maxUnitTime')} required
                                    error={errors[t('maxUnitTime')] != null ? true : false} helperText={errors[t('maxUnitTime')]}/>
                                }
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('maxUnitTime')}</FormLabel>
                                        {this.state.maxUnitTimeUOMSel != null ? this.state.maxUnitTime + this.state.maxUnitTimeUOMSel.label : 
                                        this.state.maxUnitTime} 
                                    </FormControl>
                                }
                            </Grid>
                            {!locked &&
                                <Grid item xs={6}>
                                    <AutoCompleteField
                                        value={this.state.maxUnitTimeUOMSel}
                                        options={this.state.timeUOMs}
                                        onChange={this.changeAuto('maxUnitTimeUOMSel', 'maxUnitTimeUOM')}
                                        error={errors[t('maxUnitTimeUOM')] != null ? true : false}
                                        helperText={errors[t('maxUnitTimeUOM')]}
                                        label={t('uom')}
                                        disabled={!advanced}
                                        required
                                    />
                                </Grid>
                            }
                            <Grid item xs={10}>
                                {!locked &&
                                    <TextField type="number" value={this.state.maxWorkers} onChange={this.changeNumber} name="maxWorkers"
                                    size="medium" fullWidth={true} disabled={!advanced} label={t('concurrency')} required
                                    error={errors[t('maxWorkers')] != null ? true : false} helperText={errors[t('maxWorkers')]}/>
                                }
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('concurrency')}</FormLabel>
                                        {this.state.maxWorkers} 
                                    </FormControl>
                                }
                            </Grid>
                            </Grid>
                        </Panel>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={10} sx={{ mt: 1 }}>
                        <Button disabled={!this.state.enableSave} variant="contained" color="primary" size="small" onClick={this.save}>{t('save')}</Button>&nbsp;&nbsp;
                        {this.props.permission.update &&
                            <Aux>
                                <Button variant="contained" color="secondary" size="small" onClick={this.unlock}>
                                    {locked ? t('unlock') : t('lock')}
                                </Button>&nbsp;&nbsp;
                            </Aux>
                        }
                        {this.state.security.delete && !locked &&
                            <Button variant="contained" color="secondary" size="small" onClick={this.delete}>
                                {t('delete')}
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(WorkCenter));