import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/MailOutlineTwoTone'
import { setMessageCount } from '../../../../actions/message';

export default function Mail(props) {
    const dispatch = useDispatch();
    const message = useSelector(state => state.message);
    const history = useHistory();
    const { location } = props;
    useLayoutEffect(() => {
        async function fetchData(){
            await axios.get('/api/messageunreadcount')
                       .then(result => {
                            var messageCount = result.data.totalCount != null && result.data.totalCount !== '' ? result.data.totalCount : 0;
                            dispatch(setMessageCount({messageCount: messageCount}));
                        });
        }
        fetchData();
    }, [dispatch]);

    const onClick = (e) => {
        history.push('/messages');
    };

    const button = <IconButton onClick={onClick.bind(this)} sx={{ color: '#FFF' }}>
                        <Badge overlap="rectangular" badgeContent={message.messageCount} color={location === 'bottom' ? 'primary' : 'secondary'} >
                            <MailIcon />
                        </Badge>
                    </IconButton>;   

    return button;
}