import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class VendorAuditList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            vendor: '',
            vendors: [],
            status: '',
            statuses: [],
            orderBy: 'date',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            vendorSel: null,
            statusSel: null,
            department: '',
            departments: [],
            depSel: null,
            security: []
        }
        this.changeValue = this.changeValue.bind(this);
    }

    async componentDidMount(){
        const values = await axios.get('/api/vendorauditlistvalues');
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.vendor, common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        let vendors = cacheValues.vendors;
        this.setState({
            vendors: vendors,
            statuses: cacheValues.statuses,
            departments: values.data.departments,
            security: this.props.permission
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('vendorAudits'), screen: this.props.auth.screenDefs.VendorAuditList}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
    }

    loadRecords(){
        let sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            size: this.state.listSize,
            offset: this.state.offset,
            vendor: this.state.vendor,
            status: this.state.status,
            department: this.state.department,
            sort: JSON.stringify(sort)
        }
        axios.get('/api/vendoraudits', {params: data})
        .then(data => {
            this.setState({
                list: data.data.data,
                totalCount: data.data.totalCount
            })
        })
        .catch(err => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        })
    }

    changeValue(e){
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => this.loadRecords());
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    }

    sortClick(name) {
        let orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    searchClick(e){
        this.loadRecords();
    }

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        createLocation='/#/vendoraudit'
                        createAccess={security.create}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.vendorSel}
                                options={this.state.vendors}
                                onChange={this.changeAuto('vendorSel', 'vendor')}
                                label={t('vendor')}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.statusSel}
                                options={this.state.statuses}
                                onChange={this.changeAuto('statusSel', 'status')}
                                label={t('status')}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.depSel}
                                options={this.state.departments}
                                onChange={this.changeAuto('depSel', 'department')}
                                label={t('department')}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer conmponent={Paper}>
                        <Table aria-label="Vendor Audits">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('number')}
                                        active={this.state.orderBy === 'number'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('number')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('vendor')}
                                        active={this.state.orderBy === 'vendor'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('vendor')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('date')}
                                        active={this.state.orderBy === 'date'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('date')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('rating')}
                                        active={this.state.orderBy === 'rating'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('rating')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('status')}
                                        active={this.state.orderBy === 'status'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('status')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('department')}
                                        active={this.state.orderBy === 'department'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>
                                            {t('department')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell><Link to={'/vendoraudit/' + row._id} name={'vauditRow' + i}>{row.number}</Link></TableCell>
                                        <TableCell>{row.vendor.name}</TableCell>
                                        <TableCell><DateDisplay format={this.props.auth.user.dateFormat} value={row.date}/></TableCell>
                                        <TableCell>{row.rating != null ? row.rating.label : ''}</TableCell>
                                        <TableCell>{row.status.label}</TableCell>
                                        <TableCell>{row.department != null ? row.department.label : ''}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(VendorAuditList));
