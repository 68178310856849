import { SET_HELP_CURRENTPAGE, SAVE_HELP_CACHE, RESET_HELP } from '../actions/types';

const initialState = {
    current: {},
    cache: []
}

function help(state = initialState, action){
  switch(action.type){
    case SET_HELP_CURRENTPAGE: {
      return {
        ...state, current: action.payload.current
      }
    }
    case SAVE_HELP_CACHE: {
        return {
          ...state, 
          current: JSON.parse(action.payload.current), 
          cache: JSON.parse(action.payload.cache)
        }
    }
    case RESET_HELP: {
      return initialState;
    }
    default:
      return state;
  }
}

export default help;