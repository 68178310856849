import { RESET_NOTIFICATION, SET_NOTIFICATION_COUNT } from './types';

export const setNotificationCount = (count, lastFive) => ({
    type: SET_NOTIFICATION_COUNT,
    payload: {
        count: count,
        lastFive: lastFive
    }
});

export const resetNotification = () => ({
    type: RESET_NOTIFICATION
});