import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import TextField from '../UI/TextField/TextField';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField'
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import Unlock from '../General/Unlock';
import { approveSigSecurity, completeSigSecurity,
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import { Alert } from '@mui/lab';
import { ClearButton, DeleteButton, SaveButton } from '../UI/Buttons/Buttons';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import { FormBuilder } from '@sop/react-forms-processor-formbuilder';
import {
  renderer as materialuiRenderer
} from '@sop/react-forms-processor-material-ui';
import CheckboxField from '../UI/CheckboxField/CheckboxField';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';

class Sop extends Component {
    constructor(props){
        super(props);

        this.state = {
            cleaningRequired: false,
            pesticideRequired: false,
            _id: '',
            name: '',
            required: false,
            document: "",
            original: {},
            sopFile: {},
            fileName: "",
            relatedTo: '',
            relatedTos: [],
            lotType: "",
            lotTypes: [],
            docVersion: "",
            users: [],
            managers: [],
            qas: [],
            tabKey: "1",
            departments: [],
            departmentList: [],
            documents: [],
            tenant: this.props.auth.user.tenant,
            signed: false,
            locked: false,
            docSel: null,
            depsSel: [],
            relatedToSelected: null,
            activities: [],
            objsSel: [],
            statusSel: {label: '', value: ''},
            security: [],
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            optionsMenu: [],
            formBuilderKey: 0,
            enableSave: true,
            user: null,
            reviewer: null,
            signer: null,
            template: [],
            objects: [],
            location: '',
            locationSel: null,
            locations: [],
            deleteOpen: false
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleClearForm = this.handleClearForm.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onComplianceChange = this.onComplianceChange.bind(this);
        this.onSafetyChange = this.onSafetyChange.bind(this);
        this.onUsersChange = this.onUsersChange.bind(this);
        this.onProcedureChange = this.onProcedureChange.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
        this.onDefinitionsChange = this.onDefinitionsChange.bind(this);
        this.handleCleaning = this.handleCleaning.bind(this);
        this.handlePesticide = this.handlePesticide.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location, common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        const {params} = this.props.match;
        const values = await axios.get('/api/sops/values');
        const statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.inprogress);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let locationSel = null;
            let location = '';
            if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
                locationSel = locations.find(x => x.value === this.props.auth.user.defaultLocation);
                location = this.props.auth.user.defaultLocation;
            } else {
                if(locations.length === 1){
                    locationSel = locations[0];
                    location = locationSel.value;
                }
            }
        this.setState({
            status: this.props.auth.constants.statuses.inprogress,
            template: [],
            relatedTos: values.data.relatedTos,
            documentList: values.data.documents,
            lotTypes: values.data.lotTypes,
            departmentList: values.data.departments,
            statuses: cacheValues.statuses,
            statusSel: statusSel,
            users: values.data.users,
            managers: values.data.managers,
            qas: values.data.qas,
            activities: values.data.activities,
            locations: locations,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            location: location,
            locationSel: locationSel,
            _id: params.id != null && params.id !== '' ? params.id : null
        }, () => {
            if(params.id != null && params.id !== ''){
            this.loadRecord();
            }else {
              const crumbs = [
                  { path: '/#/sops', label: this.props.t('workRecordTemplates'), screen: this.props.auth.screenDefs.WorkRecordTemplates},
                  { path: 'active', label: this.props.t('workRecTemplate'), screen: this.props.auth.screenDefs.RoomCleaning}
              ]
              this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
      const t = this.props.t;
      const result = await axios.get('/api/sops/' + this.state._id);
      let depsSel = [];
      if(result.data.departments != null && result.data.departments.length > 0){
         result.data.departments.forEach(row => {
            depsSel.push(this.state.departmentList.find(x => x.value === row));
         });
      }
      const relatedToSelected = this.state.relatedTos.find(x => x.value === result.data.relatedTo);
      const docSel = this.state.documentList.find(x => x.value === result.data.document._id);
      let locationSel = this.state.locations.find(x => x.value === result.data.location);
      const statusSel = result.data.status != null ? this.state.statuses.find(x => x.value === result.data.status) : this.state.statusSel;
      const locked = result.data.status === this.props.auth.constants.statuses.signed;
      const signed = result.data.status === this.props.auth.constants.statuses.reviewed ||
                    result.data.status === this.props.auth.constants.statuses.signed;
      let objsSel = [];
      if(result.data.objects != null && result.data.objects.length > 0){
        result.data.objects.forEach(obj => {
          let val = this.state.activities.find(x => x.value === obj);
          if(val != null && val !== undefined)
            objsSel.push(val);
        });
      }
      const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
      const completeSigSec = completeSigSecurity(this.props.permission.complete, result.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
      const reviewSigSec = reviewSigSecurity(this.props.permission.review, result.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
      const approveSigSec = approveSigSecurity(this.props.permission.approve, result.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);

      const optionsMenu = [
        {
            security: completeSigSec, user: this.props.auth.user.id, users: this.state.users, name: t('user'),
            title: t('completedBy'), setId: () => this.loadRecord(), signature: result.data.user,
            objectType: this.props.auth.constants.objectIds.sop, type: this.props.auth.constants.signatureTypes.user,
            menuType: this.props.auth.constants.optionMenuTypes.signature, object: result.data._id
        },
        {
            security: reviewSigSec, user: this.props.auth.user.id, users: this.state.managers, name: t('review'),
            title: t('reviewedBy'), setId: () => this.loadRecord(), object: result.data._id, signature: result.data.reviewer,
            objectType: this.props.auth.constants.objectIds.sop, type: this.props.auth.constants.signatureTypes.reviewer,
            menuType: this.props.auth.constants.optionMenuTypes.signature
        },
        {
            security: approveSigSec, user: this.props.auth.user.id, users: this.state.qas, name: t('approve'),
            title: t('approvedBy'), setId: () => this.loadRecord(), object: result.data._id, signature: result.data.signer,
            objectType: this.props.auth.constants.objectIds.sop, type: this.props.auth.constants.signatureTypes.signer,
            menuType: this.props.auth.constants.optionMenuTypes.signature
        },
        {
            menuType: this.props.auth.constants.optionMenuTypes.comments
        },
        {
            menuType: this.props.auth.constants.optionMenuTypes.logs
        }
      ];

      this.setState({
        _id: result.data._id,
        name: result.data.name,
        relatedTo: result.data.relatedTo,
        lotType: result.data.lotType,
        document: result.data.document != null ? result.data.document : "",
        template: JSON.parse(result.data.template),
        cleaningRequired: result.data.cleaningRequired != null ? result.data.cleaningRequired : false,
        pesticideRequired: result.data.pesticideRequired != null ? result.data.pesticideRequired : false,
        departments: result.data.departments,
        documents: result.data.documents,
        status: result.data.status != null ? result.data.status : this.props.auth.constants.statuses.inprogress,
        location: result.data.location,
        locationSel: locationSel,
        user: result.data.user,
        reviewer: result.data.reviewer,
        signer: result.data.signer,
        objects: result.data.objects != null ? result.data.objects : [],
        original: result.data,
        depsSel: depsSel,
        relatedToSelected: relatedToSelected,
        docSel: docSel,
        statusSel: statusSel,
        locked: locked,
        signed: signed,
        objsSel: objsSel,
        fieldAccess: fieldAccess,
        completeSigSec: completeSigSec,
        reviewSigSec: reviewSigSec,
        approveSigSec: approveSigSec,
        optionsMenu: optionsMenu,
        formBuilderKey: result.data._id,
        required: result.data.required === true || result.data.required === 'true' ? true : false,
        deleteOpen: false
      }, () => {
        const crumbs = [
            { path: '/#/sops', label: this.props.t('workRecordTemplates'), screen: this.props.auth.screenDefs.WorkRecordTemplates},
            { path: 'active', label: result.data.name + ' - ' + docSel.label, screen: this.props.auth.screenDefs.RoomCleaning}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
      });
    }

    handleInput(e){
        let value = e.target.value;
        let name = e.target.name;
        this.setState({[name]: value});
    }

    changeSelect = (name, data) => value => {
      this.setState({[data]: value.value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({[data]: newValue != null ? newValue.value : null, [name]: newValue});
    };

    changeMulti = (name, data) => (e, newValue) => {
        const val = newValue;
        let ids = [];
        if(val != null){
            val.forEach(row => {
                ids.push(row.value);
            })
        }
        this.setState({[data]: ids, [name]: newValue})
    };

    changeBool = (name) => (e) => {
        this.setState({[name]: !this.state[name]});
    }

    handleCleaning(e){
      this.setState({cleaningRequired: !this.state.cleaningRequired});
    }

    handlePesticide(e){
      this.setState({pesticideRequired: !this.state.pesticideRequired});
    }

    onComplianceChange(e){
      this.setState({complianceState: e.target.value});
    }

    onSafetyChange(e){
      this.setState({safetyState: e.target.value});
    }

    onUsersChange(e){
      this.setState({usersState: e.target.value});
    }

    onProcedureChange(e){
      this.setState({procedureState: e.target.value})
    }

    onItemChange(e){
      this.setState({itemState: e.target.value});
    }

    onDefinitionsChange(e){
      this.setState({definitionsState: e.target.value});
    }

    handleFormSubmit = () => {
      if(!this.state.enableSave)
        return;
      this.setState({ enableSave: false }, () => this._handleFormSubmit());
    }

    _handleFormSubmit = async () => {
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        const data = {
            _id: this.state._id,
            name: this.state.name,
            relatedTo: this.state.relatedTo,
            lotType: this.state.lotType,
            document: this.state.document,
            safetyText: this.state.safetyState,
            departments: this.state.departments,
            complianceText: this.state.complianceState,
            usersText: this.state.usersState,
            procedureText: this.state.procedureState,
            itemText: this.state.itemState,
            definitionsText: this.state.definitionsState,
            template: JSON.stringify(this.state.template),
            cleaningRequired: this.state.cleaningRequired,
            pesticideRequired: this.state.pesticideRequired,
            status: this.state.status,
            objects: this.state.objects,
            required: this.state.required === true ? true : false,
            location: this.state.location
        };
        try {
            let result = await axios.post('/api/sop', data);
            if(this.state._id == null || this.state._id === ''){
                this.props.history.replace('/sop/' + result.data.id);
                this.setState({_id: result.data.id, enableSave: true});
            } else {
                this.setState({_id: result.data.id, enableSave: true}, () => {
                    this.loadRecord();
                });
            }
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err) {
            this.setState({ enableSave: true }, () => {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    };

    validateForm(){
      let errors = {};
      const t = this.props.t;
      if(this.state.name == null || this.state.name === '')
        errors[t('name')] = t('required');
      if(this.state.template == null || this.state.template.length === 0)
        errors[t('template')] = t('required');
      if(this.state.departments == null || this.state.departments.length === 0)
        errors[t('departments')] = t('required');
      if(this.state.document == null || this.state.document === '')
        errors[t('document')] = t('required');
      if(this.state.location == null || this.state.locatino === '')
        errors[t('location')] = t('required');
      if(!isEmpty(errors)){
        this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
        return false;
      }else {
        if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
        return true;
      }
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    handleDelete(){
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._handleDelete());
    }

    async _handleDelete(){
        if(this.state._id != null && this.state._id !== ''){
          try {
            let result = await axios.delete('/api/sop/' + this.state._id);
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            this.props.history.goBack();
          } catch(err){
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
          }
        } else {
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: "Record hasn't been saved yet", title: this.props.t('success'), severity: 'success'}});
            this.props.history.goBack();
        }
    }

    handleClearForm(e) {
        e.preventDefault();
        this.setState({
            _id: "",
            name: "",
            relatedTo: "",
            document: {},
            template: [],
            cleaningRequired: false,
            pesticideRequired: false,
            departments: [],
            required: false,
            formBuilderKey: new Date().getTime()
        });
    }

    onFormBuilderChange(value) {
        this.setState({template: value});
    }

    render(){
      const errors = this.props.errors;
      const signed = this.state.signed;
      const security = this.state.security;
      const fieldAccess = this.state.fieldAccess;
      const t = this.props.t;
      //const locked = this.state.locked;
      return(
        <Aux>
          <BreadcrumbBar>
            <ActionBar
              id={this.state._id}
              objectType={this.props.auth.constants.objectIds.sop}
              enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
              // finalDocuments={this.state.finalDocument}
                optionsAvailable={true}
            />
          </BreadcrumbBar>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Panel>
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={2}>
                        <TextField value={this.state.statusSel != null ? this.state.statusSel.label : ''}  name="status"
                        size="medium" fullWidth disabled={true} label={t('status')} data-cy='status'/>
                    </Grid>
                    <Grid item xs={8} sm={5}>
                        <TextField value={this.state.name} onChange={this.handleInput} name="name"
                        size="medium" fullWidth disabled={!fieldAccess} label={t('name')} data-cy='name'
                        error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <AutoCompleteField
                        value={this.state.locationSel}
                        options={this.state.locations}
                        onChange={this.changeAuto('locationSel', 'location')}
                        error={errors[t('location')] != null ? true : false}
                        helperText={errors[t('location')]}
                        label={t('location')}
                        disabled={!fieldAccess}
                        data-cy='location'
                        />
                    </Grid>
                    <Grid item xs={10} sm={5}>
                        <AutoCompleteField
                        value={this.state.docSel}
                        options={this.state.documentList}
                        onChange={this.changeAuto('docSel', 'document')}
                        error={errors[t('document')] != null ? true : false}
                        helperText={errors[t('document')]}
                        label={t('sopDocument')}
                        disabled={!fieldAccess}
                        data-cy='document'
                        />
                    </Grid>
                    <Grid item sm={11}>
                        <AutoCompleteField
                        value={this.state.depsSel}
                        options={this.state.departmentList}
                        onChange={this.changeMulti('depsSel', 'departments')}
                        error={errors[t('departments')] != null ? true : false}
                        helperText={errors[t('departments')]}
                        label={t('departments')}
                        disabled={!fieldAccess}
                        multiple={true}
                        data-cy='departments'
                        />
                    </Grid>
                    <Grid item sm={11}>
                        <AutoCompleteField
                            multiple={true} disabled={!fieldAccess}
                            value={this.state.objsSel}
                            options={this.state.activities}
                            onChange={this.changeMulti('objsSel', 'objects')}
                            error={errors[t('objects')] != null ? true : false}
                            helperText={errors[t('objects')]}
                            label={t('activities')}
                            data-cy='objects'
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <CheckboxField
                        checked={this.state.required} onChange={this.changeBool('required')}
                        label={t('required')} name="required" disabled={!fieldAccess} data-cy='required'
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <SubSectionHeading title="Form Builder"/>
                        {errors[t('template')] &&
                        <Alert variant="outlined" severity="error" sx={{ 'marginTop': '5px', 'marginBottom': '5px' }}>{errors[t('template')]}</Alert>
                        }
                        <FormBuilder
                        key={this.state.formBuilderKey}
                        renderer={materialuiRenderer}
                        value={this.state.template}
                        onChange={(value) => {
                            this.onFormBuilderChange(value);
                        }}
                        editable={this.props.auth.user.internal}
                        />
                        <br/>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <SaveButton saveClick={this.handleFormSubmit} enabled={fieldAccess} data-cy='save'/>
                    <ClearButton clearClick={this.handleClearForm} color="primary" enabled={fieldAccess} data-cy='clear'/>
                    <DeleteButton deleteClick={this.deleteToggle} enabled={security.delete} data-cy='delete'/>
                    {signed &&
                      <Unlock id={this.state._id} objectType={this.props.auth.constants.objectIds.sop} callback={this.loadRecord} data-cy='unlock'/>
                    }
                  </Grid>
                </Grid>

              </Panel>
            </Grid>
          </Grid>
            {this.state.deleteOpen &&
                <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                    redirect={() => this.handleDelete()} open={this.state.deleteOpen} />
            }
            {this.props.openComments &&
                <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.sop}/>
            }
            {this.props.openLogs &&
                <ObjectLog id={this.state._id}/>
            }
      </Aux>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  openComments: state.optionMenu.openComments,
  openLogs: state.optionMenu.openLogs,
  errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Sop));
