import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import queryString from 'query-string';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import SectionTitle from '../UI/Typography/SectionTitle';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import IconButton from '@mui/material/IconButton';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import ActionBar from '../UI/Buttons/ActionBar';
import ActivityWorkRecord from '../Cultivation/ActivityWorkRecord';
import { EvoraTabs, TabCanvas, TabPanel } from '../UI/Tabs/Tabs';
import { approveSigSecurity, completeSigSecurity,
    fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { Link } from 'react-router-dom';
import { FormControl, FormLabel } from '@mui/material';
import { withTranslation } from 'react-i18next';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ItemLedgerComponent from './ItemLedgerComponent';
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import DateTimePicker from '../UI/DateTimeSelect/DateTimeSelect';
import Box from '@mui/material/Box';
import { NumericFormat } from 'react-number-format';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import PrintView from '../Printing/PrintView';
import Panel from '../UI/Panel/Panel';
import ConversionHelper from '../../helpers/conversionHelper';

class Shipment extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            status: '',
            statusSel: {
                value: '',
                label: '',
            },
            statuses: [],
            type: '',
            typeSel: null,
            types: [],
            date: new Date(),
            sale: '',
            saleSel: null,
            sales: [],
            customer: '',
            customerSel: null,
            customers: [],
            vendor: '',
            vendorSel: null,
            vendors: [],
            courier: '',
            courierSel: null,
            couriers: [],
            shippingNumber: '',
            item: '',
            itemSel: null,
            items: [],
            quantity: '',
            uom: '',
            uomSel: null,
            uoms: [],
            sourceContainer: '',
            sourceContainerSel: null,
            containers: [],
            locked: false,
            saleObject: null,
            allItems: [],
            location: '',
            locationSel: null,
            locations: [],
            security: [],
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            renderNum: Math.random(),
            transactionType: '',
            transactionTypeSel: null,
            transactionTypes: [],
            receivingType: '',
            receivingTypeSel: null,
            receivingTypes: [],
            user: null,
            reviewer: null,
            signer: null,
            sopIds: [],
            sopsSel: [],
            sopList: [],
            users: [],
            userList: [],
            usersSel: [],
            managers: [],
            qas: [],
            documents: null,
            tabKey: 0,
            tabLabels: [{name: 'Shipment', disabled: false}],
            optionsMenu: [],
            flagForSignature: 0,
            count: '',
            hasCount: false,
            enableSave: true,
            address1: '',
            address2: '',
            city: '',
            prov: '',
            provSel: null,
            provs: [],
            postal: '',
            country: '',
            countrySel: null,
            countries: [],
            signed: false,
            sigData: null,
            activeUsers: [],
            activeManagers: [],
            activeQas: [],
            form: '',
            hasForm: false,
            asn: '',
            appointment: '',
            enableUnlock: false,
            contentLines: [],
            printList: [],
            deleteOpen: false,
            expectedDelivery: '',
            finalDocuments: [],
            printViewer: false,
            invoice: null,
            printType: '',
            printRecord: '',
            hasLedgers: false,
            tenantReport: '',
            fullItems: []
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.item, common.cacheValues.user, common.cacheValues.vendor, common.cacheValues.allItems,
                                common.cacheValues.activeLocation, common.cacheValues.status, common.cacheValues.quantityUOM, common.cacheValues.shipmentType,
                                common.cacheValues.shipmentForm, common.cacheValues.shipmentSop, common.cacheValues.shippingVendor], 
                                this.props.auth.constants, null, this.props.auth.user.tenant);
        let items = cacheValues.items;
        let allUsers = cacheValues.users;
        let vendors = cacheValues.vendors;
        let locations = cacheValues.locations;
        let countries = await ValueHelper.getCountries();
        let provs = await ValueHelper.getCanadianProvs();
        const userValues = await ValueHelper.screenUsers(common.screens.shipment, this.props.auth.user.tenant);
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
                this.props.auth.user.defaultLocation : '';
        let locationSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locationSel == null && locations.length === 1){
            locationSel = locations[0];
            location = locationSel.value;
        }
        let statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        let workRecords = [];
        let sopIds = [];
        let sopsSel = [];
        cacheValues.sops.forEach(sop => {
            if(sop.required === true){
                workRecords.push({
                    sop: sop.value,
                    original: true
                });
                sopIds.push(sop.value);
                sopsSel.push(sop);
            }
        });
        let fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let activeQas = [];
        userValues.approve.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeQas.push(row);
            }
        })
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        this.setState({
            statuses: cacheValues.statuses,
            types: cacheValues.shipmentTypes,
            uoms: cacheValues.quantityUOMs,
            couriers: cacheValues.shippingVendors,
            allItems: items,
            items: items,
            locations: locations,
            location: location,
            locationSel: locationSel,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            security: this.props.permission,
            fieldAccess: fieldAccess,
            sops: workRecords,
            sopList: cacheValues.sops,
            sopIds: sopIds,
            sopsSel: sopsSel,
            userList: userValues.create,
            managers: userValues.review,
            qas: userValues.approve,
            vendors: vendors,
            countries: countries,
            provs: provs,
            activeUsers: activeUsers,
            activeManagers: activeManagers,
            activeQas: activeQas,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            allUsers: allUsers,
            fullItems: cacheValues.allItems
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/shipments', label: this.props.t('shipments'), screen: this.props.auth.screenDefs.Shipments},
                    { path: 'active', label: this.props.t('shipment'), screen: this.props.auth.screenDefs.Shipment}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async (e) => {
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        const constants = this.props.auth.constants;
        let record = await axios.get('/api/shipment/' + this.state._id);
        let items = record.data.type === this.props.auth.constants.shipmentTypes.sale ? record.data.items :
                    this.state.allItems;
        let statusSel = record.data.status != null ? this.state.statuses.find(x => x.value === record.data.status) : null;
        let saleSel = record.data.sale != null ? record.data.sales.find(x => x.value === record.data.sale) : null;
        let customerSel = record.data.customer != null ? record.data.customers.find(x => x.value === record.data.customer) : null;
        let typeSel = record.data.type != null ? this.state.types.find(x => x.value === record.data.type): null;
        let vendorSel = record.data.vendor != null ? record.data.vendors.find(x => x.value === record.data.vendor) : null;
        let courierSel = record.data.courier != null ? this.state.couriers.find(x => x.value === record.data.courier) : null;
        let locationSel = record.data.location != null ? this.state.locations.find(x => x.value === record.data.location) : null;
        let provSel = this.state.provs.find(x => x.value === record.data.prov);
        let countrySel = this.state.countries.find(x => x.value === record.data.country);
        let fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let salesData = [];
        if(record.data.customer != null && record.data.customer !== '' && 
           typeSel != null && typeSel.value === constants.shipmentTypes.sale) {
            let sales = await axios.get('/api/saleselect', {params: {customer: record.data.customer}});
            salesData = sales.data;
        }
        let hasCount = false;
        let contents = record.data.contents;
        let contentLines = [];
        if(contents != null && contents.length > 0){
            contents.forEach(row => {
                let itemSel = items.find(x => x.value === row.item);
                if(itemSel == null){
                    items = this.state.allItems;
                    itemSel = this.state.fullItems.find(x => x.value === row.item);
                }
                row.itemSel = itemSel;
                if(itemSel.hasCount)
                    hasCount = true;
                let uomSel = this.state.uoms.find(x => x.value === row.uom);
                row.uomSel = uomSel;
                let sourceContainerSel = row.containers.find(x => x.value === row.sourceContainer);
                row.sourceContainerSel = sourceContainerSel;
                let itemLine = contentLines.find(x => x.item != null && x.item === row.item);
                if(itemLine == null){
                    contentLines.push({
                        item: row.item,
                        itemSel: itemSel,
                        uom: row.uom,
                        uomSel: uomSel,
                        quantity: row.quantity,
                        count: row.count,
                        containers: row.containers,
                        expectedQuantity: row.expectedQuantity,
                        lines: [{
                            _id: row._id,
                            quantity: row.quantity,
                            count: row.count,
                            sourceContainer: row.sourceContainer,
                            sourceContainerSel: sourceContainerSel,
                            containerTransaction: row.containerTransaction,
                            itemLedger: row.itemLedger,
                            lot: row.lot,
                            lotTransaction: row.lotTransaction,
                            reservation: row.reservation,
                            saleLine: row.saleLine,
                            add: false
                        }]
                    })
                } else {
                    itemLine.quantity = parseFloat(row.quantity) + parseFloat(itemLine.quantity);
                    itemLine.count = row.count != null && itemLine.count != null ? parseInt(row.count) + parseInt(itemLine.count) : row.count != null ? row.count : itemLine.count;
                    itemLine.lines.push({
                        _id: row._id,
                        quantity: row.quantity,
                        count: row.count,
                        sourceContainer: row.sourceContainer,
                        sourceContainerSel: sourceContainerSel,
                        containerTransaction: row.containerTransaction,
                        itemLedger: row.itemLedger,
                        lot: row.lot,
                        lotTransaction: row.lotTransaction,
                        reservation: row.reservation,
                        saleLine: row.saleLine,
                        add: false
                    });
                }
            });
        }
        if(fieldAccess){
            for(let row of contentLines){
                row.lines.push({
                    quantity: '',
                    count: '',
                    sourceContainer: '',
                    sourceContainerSel: null,
                    saleLine: '',
                    add: true
                })
            }
        }
        let sopIds = [];
        let sopsSel = [];
        let tabLabels = [{name: t('shipment'), disabled: false}];
        if(record.data.sops != null && record.data.sops.length > 0){
            record.data.sops.forEach(sop => {
                sopIds.push(sop.sop);
                let val = this.state.sopList.find(x => x.value === sop.sop);
                sopsSel.push(val);
                tabLabels.push({name: val.label, disabled: false});
            });
        }
        if(record.data.hasLedgers){
            tabLabels.push({name: t('itemLedgers'), disabled: false});
        }
        let usersSel = [];
        let userList = this.state.userList;
        if(record.data.users != null && record.data.users.length > 0){
            record.data.users.forEach(row => {
                let val = this.state.userList.find(x => x.value === row);
                if(val != null){
                    usersSel.push(val);
                } else {
                    val = this.state.allUsers.find(x => x.value === row);
                    if(val != null){
                        usersSel.push(val);
                        userList.push(val);
                    }
                }
            });
        }
        let sigDeleteCallback = (record.data.status === this.props.auth.constants.statuses.new || record.data.status === this.props.auth.constants.statuses.inprogress) && 
                        record.data.user != null ? this.deleteSigCallback : null;
        let signed = record.data.status === constants.statuses.signed || record.data.status === constants.statuses.reviewed;
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: usersSel, name: t('complete'), object: record.data._id,
                title: t('completedBy'), setId: () => this.loadRecord(), validate: () => this.validateComplete(), signature: record.data.user,
                objectType: this.props.auth.constants.objectIds.shipment, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, saveCallback: this.saveCallback, deleteCallback: sigDeleteCallback
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: !internal && this.state.reviewer == null ? this.state.activeManagers : this.state.managers,
                name: t('review'), title: t('reviewedBy'), setId: () => this.loadRecord(), signature: record.data.reviewer, object: record.data._id,
                objectType: this.props.auth.constants.objectIds.shipment, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: !internal && this.state.signer == null ? this.state.activeQas : this.state.qas,
                name: t('approve'), title: t('approvedBy'), setId: () => this.loadRecord(), object: record.data._id, signature: record.data.signer,
                objectType: this.props.auth.constants.objectIds.shipment, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        let params = queryString.parse(this.props.location.search);
        let printList = [
            {label: t('billOfLading'), onClick: () => this.PrintViewer('bol'), enabled: this.state.security.print }
        ];
        if(record.data.type === constants.shipmentTypes.sale || record.data.type === constants.shipmentTypes.return)
            printList.push({label: t('chainOfCustody'), onClick: () => this.PrintViewer('coc'), enabled: this.state.security.print && record.data.status !== this.props.auth.constants.statuses.new && record.data.status !== this.props.auth.constants.statuses.inprogress });
        if(record.data.type === this.props.auth.constants.shipmentTypes.sale && record.data.status === this.props.auth.constants.statuses.signed)
            printList.push({
                label: t('invoice'), 
                onClick: record.data.invoice == null || record.data.invoice === '' ? () => this.createInvoice() : () => this.PrintViewer('invoice'), 
                enabled: this.state.security.print
            });
        
        let finalDocuments = [];
        if(record.data.bol != null && record.data.bol !== '')
            finalDocuments.push(record.data.bol);
        if(record.data.coc != null && record.data.coc !== '')
            finalDocuments.push(record.data.coc);
        if(record.data.invoice != null && record.data.invoice !== '')
            finalDocuments.push(record.data.invoice);
        this.setState({
            number: record.data.number,
            date: record.data.date,
            status: record.data.status,
            statusSel: statusSel,
            type: record.data.type,
            typeSel: typeSel,
            sale: record.data.sale,
            saleSel: saleSel,
            sales: salesData,
            customer: record.data.customer,
            customerSel: customerSel,
            vendor: record.data.vendor,
            vendorSel: vendorSel,
            vendors: record.data.vendors,
            courier: record.data.courier,
            courierSel: courierSel,
            shippingNumber: record.data.shippingNumber,
            saleObject: record.data.saleObject,
            items: items,
            location: record.data.location,
            locationSel: locationSel,
            user: record.data.user,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            sopIds: sopIds,
            sopsSel: sopsSel,
            sops: record.data.sops,
            renderNum: Math.random(),
            tabLabels: tabLabels,
            users: record.data.users,
            usersSel: usersSel,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            fieldAccess: fieldAccess,
            documents: record.data.documents,
            hasCount: hasCount,
            bol: record.data.bol,
            address1: record.data.address1,
            address2: record.data.address2,
            city: record.data.city,
            prov: record.data.prov,
            provSel: provSel,
            postal: record.data.postal,
            country: record.data.country,
            countrySel: countrySel,
            customers: record.data.customers,
            signed: signed,
            sigData: null,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            asn: record.data.asn,
            appointment: record.data.appointment,
            contentLines: contentLines,
	        userList: userList,
            coc: record.data.coc,
            printList: printList,
            deleteOpen: false,
            expectedDelivery: record.data.expectedDelivery,
            invoice: record.data.invoice,
            finalDocuments: finalDocuments,
            hasLedgers: record.data.hasLedgers
        }, () => {
            const crumbs = [
                { path: '/#/shipments', label: this.props.t('shipments'), screen: this.props.auth.screenDefs.Shipments},
                { path: 'active', label: this.props.t('shipment') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.Shipment}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            this.determineUnlock();
            if(params.tab != null)
                this.openNotification(params);
        });
    }

    determineUnlock() {
        let enableUnlock = false;
        let state = this.state;
        let constants = this.props.auth.constants;
        if(state.status === constants.statuses.complete && this.props.permission.unlock){
            enableUnlock = true;
        } else if (state.status === constants.statuses.reviewed || state.status === constants.statuses.signed){
            if(this.state.user != null){
                if((state.contents == null || state.contents.length === 0)){
                    enableUnlock = true;
                } else {
                    enableUnlock = true;
                }
            }
        }
        this.setState({enableUnlock: enableUnlock})
    }

    openNotification = (params) => {
        let tabKey = this.state.tabKey;
        if(params != null && params.tab != null)
            tabKey = Number(params.tab);
        this.setState({tabKey});
    }

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(isNaN(value))
            return;
        this.setState({[name]: value}, () => {
            if(name === 'quantity'){
                if(value != null && value !== ''){
                    if(this.state.itemSel != null && this.state.itemSel.avgWeight != null){
                        let count = parseFloat(value) / parseFloat(this.state.item.avgWeight);
                        count = Math.ceil(count);
                        this.setState({count: count});
                    }
                } else {
                    this.setState({count: ''})
                }
            }
        });
    };
    
    async itemContainers(item){
        let params = {items: [item], available: true };
        if(this.state.type === this.props.auth.constants.shipmentTypes.test){
            params.lab = true;
        } else {
            params.ship = true;
        }
        let result = await axios.post('/api/itemcontainers', params);
        let containers = result.data;
        if(this.state.type !== this.props.auth.constants.shipmentTypes.sale){
            for(let row of containers){
                row.origDisabled = row.disabled;
                row.disabled = false;
            }
        }
        return containers;
    }

    changeAuto = (name, data) => async (e, newValue) => {
        const constants = this.props.auth.constants;
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
        }, async () => {
            if(newValue != null){
                if(data === 'customer'){
                    let sales = await axios.get('/api/saleselect', {params: {customer: newValue.value, status: this.props.auth.constants.statuses.signed}});
                    this.setState({sales: sales.data});
                } else if(data === 'type'){
                    let contentLines = this.state.contentLines;
                    let containers = [];
                    if(this.state.itemSel != null){
                        containers = await this.itemContainers(this.state.item);
                    }
                    for(let row of contentLines){
                        let conts = await this.itemContainers(row.item);
                        row.containers = conts;
                        for(let line of row.lines){
                            line.sourceContainer = '';
                            line.sourceContainerSel = null;
                        }
                    }
                    let printList = [{label: this.props.t('billOfLading'), onClick: this.bolClick, enabled: this.state.security.print}];
                    if(newValue.value === constants.shipmentTypes.sale){
                        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.customer], this.props.auth.constants, null, this.props.auth.user.tenant);
                        let custs = cacheValues.customers;
                        printList.push({label: this.props.t('chainOfCustody'), onClick: this.cocClick, enabled: this.state.security.print});
                        this.setState({customers: custs, printList: printList});
                    } else if(newValue.value === constants.shipmentTypes.test){
                        let vendors = await axios.get('/api/vendorselect', {params: {type: this.props.auth.constants.vendorTypes.lab}});
                        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.customer], this.props.auth.constants, null, this.props.auth.user.tenant);
                        let custs = cacheValues.customers
                        let items = this.state.allItems;
                        this.setState({vendors: vendors.data, items: items, customers: custs, printList: printList});
                    } else if(newValue.value === constants.shipmentTypes.treatment){
                        let vendors = await axios.get('/api/vendorselect', {params: {type: this.props.auth.constants.vendorTypes.cropTreatment}});
                        let items = this.state.allItems;
                        this.setState({vendors: vendors.data, items: items, printList: printList});
                    } else if(newValue.value === constants.shipmentTypes.research || newValue.value === this.props.auth.constants.shipmentTypes.return){
                        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.vendor], this.props.auth.constants, null, this.props.auth.user.tenant);
                        let vendors = cacheValues.vendors
                        let items = this.state.allItems;
                        this.setState({vendors: vendors, items: items, printList: printList});
                    }
                    this.setState({containers: containers, contentLines: contentLines, sourceContainer: '', sourceContainerSel: null});
                } else if(data === "sale"){
                    let sale = await axios.get('/api/sale/' + newValue.value);
                    let items = [];
                    let contentLines = [];
                    let hasCount = false;
                    sale.data.lines.forEach(row => {
                        let item = this.state.fullItems.find(x => x.value === row.item);
                        if(item.hasCount)
                            hasCount = true;
                        let uomSel = this.state.uoms.find(x => x.value === item.uom);
                        items.push(item);
                        let containerSel = row.container != null ? row.containers.find(x => x.value === row.container) : null;
                        if(containerSel == null){
                            if(sale.data.masterCasings != null && sale.data.masterCasings.length > 0){
                                for(let casing of sale.data.masterCasings){
                                    for(let line of casing.lines){
                                        if(line.item === row.item){
                                            for(let output of line.outputs){
                                                if(output.item === row.item){
                                                    containerSel = row.containers.find(x => x.value === output.container && x.quantity === row.quantity);
                                                    if(containerSel != null) break;
                                                }
                                            }
                                        }
                                        if(containerSel != null) break;
                                    }
                                    if(containerSel != null) break;
                                }
                            }
                        }
                        let itemLine = contentLines.find(x => x.item === row.item);
                        if(itemLine == null){
                            contentLines.push({
                                item: row.item,
                                itemSel: item,
                                uom: item.uom,
                                uomSel: uomSel,
                                containers: row.containers,
                                quantity: row.quantity,
                                expectedQuantity: row.quantity,
                                lines: [{
                                    reservation: row.reservation,
                                    sourceContainer: containerSel != null ? containerSel.value : '',
                                    sourceContainerSel: containerSel,
                                    quantity: row.quantity,
                                    saleLine: row._id
                                }]
                            });
                        } else {
                            itemLine.quantity = row.container != null && itemLine.quantity != null ? parseFloat(itemLine.quantity) + parseFloat(row.quantity) :
                                            row.container != null ? row.quantity : itemLine.quantity;
                            itemLine.lines.push({
                                reservation: row.reservation,
                                sourceContainer: row.container != null ? row.container : '',
                                sourceContainerSel: containerSel,
                                quantity: row.container != null ? row.quantity : '',
                                saleLine: row._id
                            })
                        }
                    })
                    for(let row of contentLines){
                        row.lines.push({
                            quantity: '',
                            count: '',
                            sourceContainer: '',
                            sourceContainerSel: null,
                            saleLine: '',
                            add: true
                        })
                    }
                    let provSel = this.state.provs.find(x => x.value === sale.data.prov);
                    let countrySel = this.state.countries.find( x => x.value === sale.data.country);
                    this.setState({
                        items: items,
                        saleObject: sale.data,
                        hasCount: hasCount,
                        address1: sale.data.address1,
                        address2: sale.data.address2,
                        city: sale.data.city,
                        prov: sale.data.prov,
                        postal: sale.data.postal,
                        country: sale.data.country,
                        provSel: provSel,
                        countrySel: countrySel,
                        contentLines: contentLines,
                        expectedDelivery: sale.data.expectedDelivery != null ? sale.data.expectedDelivery : this.state.expectedDelivery
                    });
                } else if(data === 'vendor'){
                    let result = await axios.get('/api/vendor/' + newValue.value);
                    let provSel = this.state.provs.find(x => x.value === result.data.address.prov);
                    let countrySel = this.state.countries.find( x => x.value === result.data.address.country);
                    this.setState({
                        address1: result.data.address.address1,
                        address2: result.data.address.address2,
                        city: result.data.address.city,
                        prov: result.data.address.prov,
                        postal: result.data.address.postal,
                        country: result.data.address.country,
                        provSel: provSel,
                        countrySel: countrySel
                    });
                } else if(data === 'customer' && this.state.type !== this.props.auth.constants.shipmentTypes.sale){
                    let result = await axios.get('/api/customer/' + newValue.value);
                    let address = result.data.addresses != null && (result.data.addresses.length === 1 || result.data.shippingAddress == null) ?
                        result.data.addresses[0] : null;
                    if(address == null && result.data.addresses != null && result.data.addresses.length > 0){
                        address = result.data.addresses.find(x => x.type._id === result.data.shippingAddress);
                    }
                    if(address != null){
                        let provSel = this.state.provs.find(x => x.value === address.prov);
                        let countrySel = this.state.countries.find(x => x.value === address.country);
                        this.setState({
                            address1: address.street1,
                            address2: address.street2,
                            city: address.city,
                            prov: address.prov,
                            postal: address.postal,
                            country: address.country,
                            provSel: provSel,
                            countrySel: countrySel
                        });
                    }
                } else if(data === "item"){
                    let containers = await this.itemContainers(newValue.value);
                    if(this.state.type !== constants.shipmentTypes.sale){
                        for(let row of containers){
                            row.origDisabled = row.disabled;
                            row.disabled = false;
                        }
                    }
                    let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                    let hasCount = this.state.hasCount;
                    if(this.state.contentLines.length === 0)
                        hasCount = false;
                    if(newValue.hasCount)
                        hasCount = true;
                    this.setState({
                        uom: newValue.uom,
                        uomSel: uomSel,
                        containers: containers,
                        hasCount: hasCount
                    });
                }
            } else {
                if(data === 'item'){
                    this.setState({sourceContainer: '', sourceContainerSel: null, containers: [], quantity: '', count: ''});
                }
            }
        });
    };

    changeMulti = (name, data) => (e, newValue) => {
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            [name]: newValue,
            [data]: ids
        })
    };

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    };

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.save()})
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let contents = [];
        for(let line of this.state.contentLines){
            for(let row of line.lines){
                if(row.add !== true){
                    let count = row.count;
                    if(line.itemSel.avgWeight != null){
                        count = Math.ceil(parseFloat(row.quantity) / parseFloat(line.itemSel.avgWeight));
                    }
                    contents.push({
                        _id: row._id,
                        quantity: row.quantity,
                        item: line.item,
                        uom: line.uom,
                        count: count !== '' ? count : null,
                        sourceContainer: row.sourceContainer !== '' ? row.sourceContainer : null,
                        containerTransaction: row.containerTransaction !== '' ? row.containerTransaction : '',
                        itemLedger: row.itemLedger !== '' ? row.itemLedger : null,
                        lot: row.lot !== '' ? row.lot : null,
                        lotTransaction: row.lotTransaction !== '' ? row.lotTransaction : '',
                        reservation: row.reservation !== '' ? row.reservation : '',
                        saleLine: row.saleLine !== '' ? row.saleLine : null,
                        expectedQuantity: line.expectedQuantity
                    });
                }
            }
        }
        let data = {
            _id: this.state._id,
            number: this.state.number,
            date: this.state.date,
            type: this.state.type,
            status: this.state.status,
            location: this.state.location,
            sale: this.state.sale,
            customer: this.state.customer,
            vendor: this.state.vendor,
            courier: this.state.courier,
            shippingNumber: this.state.shippingNumber,
            contents: contents,
            sops: this.state.sops,
            users: this.state.users,
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            prov: this.state.prov,
            postal: this.state.postal,
            country: this.state.country,
            sigData: this.state.sigData,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            asn: this.state.asn,
            appointment: this.state.appointment,
            expectedDelivery: this.state.expectedDelivery
        }
        try {
            let result = await axios.post('/api/shipment', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/shipment/' + result.data.id);
            else
                this.loadRecord();
            this.setState({_id: result.data.id, enableSave: true, sigData: null}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });

        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    validate(full){
        const t = this.props.t;
        const constants = this.props.auth.constants;
        let errors = {};
        let state = this.state;
        if(state.date == null || state.date === ''){
            errors[t('date')] = t('required');
        } else {
            let validEndDate = ConversionHelper.validActivityDate(this.state.date, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
            if(!validEndDate){
                errors[t('date')] = t('dateBeforeLock');
            }
        }
        if(state.status == null || state.status === '')
            errors[t('status')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.users == null || state.users.length === 0)
            errors[t('users')] = t('required');
        if(state.address1 == null || state.address1 === '')
            errors[t('address1')] = t('required');
        if(state.city == null || state.city === '')
            errors[t('city')] = t('required');
        if(state.prov == null || state.prov === '')
            errors[t('prov')] = t('required');
        if(state.postal == null || state.postal === '')
            errors[t('postal')] = t('required');
        if(state.type == null || state.type === ''){
            errors[t('type')] = t('required');
        } else {
            if(state.type === constants.shipmentTypes.sale){
                if(state.customer == null || state.customer === '')
                    errors[t('customer')] = t('required');
                if(state.sale == null || state.sale === '')
                    errors[t('sale')] = t('required');
            } else if(state.type === constants.shipmentTypes.test){
                if((state.vendor == null || state.vendor === '') && (this.state.customer == null || this.state.customer === '')){
                    errors[t('vendor')] = t('vendorOrCustomer');
                    errors[t('customer')] = t('vendorOrCustomer');
                }
            } else if (state.type === constants.shipmentTypes.treatment || state.type === constants.shipmentTypes.research ||
                state.type === constants.shipmentTypes.return){
                if(state.vendor == null || state.vendor === '')
                    errors[t('vendor')] = t('required');
            }
        }
        for(let i = 0; i < state.contentLines.length; i++){
            let line = state.contentLines[i];
            if(line.item == null || line.item === '')
                errors[`${t('item')} (${t('line')} ${i})`] = t('required');
            for(let k = 0; k < line.lines.length; k++){
                let row = line.lines[k];
                if(row.add == null || row.add !== true){
                    if((row.quantity == null || row.quantity === '' || row.quantity === 0) && full === true)
                        errors[`${t('quantity')} (${t('line')} ${i}, ${k})`] = t('required');
                    if(line.itemSel != null && line.itemSel.lotTracked === true){
                        if(row.sourceContainer == null || row.sourceContainer === ''){
                            if(full === true){
                                errors[`${t('quantity')} (${t('line')} ${i}, ${k})`] = t('required');
                            }
                        } else {
                            if(row.quantity != null && row.quantity !== ''){
                                if(row.reservation == null && parseFloat(row.quantity) > parseFloat(row.sourceContainerSel.quantity)){
                                    errors[`${t('quantity')} (${t('line')} ${i}, ${k})`] = t('moreThanContainer');
                                } else if(row.reservation != null && parseFloat(row.quantity) > parseFloat(row.sourceContainerSel.quantity) + parseFloat(row.sourceContainerSel.reserved)){
                                    errors[`${t('quantity')} (${t('line')} ${i}, ${k})`] = t('moreThanContainer');
                                }
                            }
                        }
                    } else if(line.itemSel != null && line.itemSel.lotTracked !== true){
                        if(row.quantity != null && row.quantity !== ''){
                            if(parseFloat(line.quantity) > parseFloat(line.itemSel.inventory)){
                                errors[`${t('quantity')} (${t('line')} ${i}, ${k})`] = t('moreThanInventory');
                            }
                        }
                    }
                } else {
                    if((row.quantity != null && row.quantity !== '') || (row.sourceContainer != null && row.sourceContainer !== '')){
                        errors[`${t('quantity')} (${t('line')} ${i}, ${k})`] = t('rowNotAdded');
                    }
                }
            }
        }
        if(this.state.customerSel != null && this.state.customerSel.foreignId === 'aglc') {
            if(this.state.asn == null || this.state.asn === '') {
                errors[t('asn')] = t('required');
            }
            if(this.state.appointment == null || this.state.appointment === '') {
                errors[t('appointment')] = t('required');
            }
        }
        if(this.state.customerSel != null && this.state.customerSel.foreignId === 'ocs') {
            if(this.state.appointment == null || this.state.appointment === '') {
                errors[t('appointment')] = t('required');
            }
        }
        if((this.state.item != null && this.state.item !== '') || (this.state.quantity != null && this.state.quantity !== '') || 
        (this.state.count != null && this.state.count !== '') || (this.state.sourceContainer != null && this.state.sourceContainer !== '')){
            errors[t('item')] = t('rowNotAdded');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    unlock = (e) => {
        if(this.props.permission.update){
            this.setState({locked: !this.state.locked});
        }
    };

    addLine = (e) => {
        let valid = this.validateLine();
        if(!valid)
            return;
        let contentLines = this.state.contentLines;
        let existingLine = contentLines.find(x => x.item === this.state.item);
        let count = this.state.count;
        if(this.state.itemSel.avgWeight != null){
            count = Math.ceil(parseFloat(this.state.quantity) / parseFloat(this.state.itemSel.avgWeight));
        }
        if(existingLine != null){
            existingLine.quantity = parseFloat(existingLine.quantity) + parseFloat(this.state.quantity);
            existingLine.count = existingLine.count != null && this.state.count != null && this.state.count !== '' ? parseInt(existingLine.count) + parseInt(count) :
                            count != null && count !== '' ? count : existingLine.count;
            let line = {
                quantity: this.state.quantity,
                count: count !== '' ? count : null,
                sourceContainer: this.state.sourceContainer,
                sourceContainerSel: this.state.sourceContainerSel,
                saleLine: this.state.saleLine,
                add: false
            };
            existingLine.lines.splice(existingLine.lines.length - 1, 0, line);
        } else {
            contentLines.push({
                itemSel: this.state.itemSel,
                item: this.state.item,
                quantity: this.state.quantity,
                count: count !== '' ? count : null,
                uom: this.state.uom,
                uomSel: this.state.uomSel,
                containers: this.state.containers,
                lines: [{
                    quantity: this.state.quantity,
                    count: count !== '' ? count : null,
                    sourceContainer: this.state.sourceContainer,
                    sourceContainerSel: this.state.sourceContainerSel
                }, {
                    quantity: '',
                    count: '',
                    sourceContainer: '',
                    sourceContainerSel: null,
                    saleLine: '',
                    add: true
                }]
            });
        }
        this.setState({
            contentLines: contentLines,
            item: '',
            itemSel: null,
            quantity: '',
            uom: '',
            uomSel: null,
            sourceContainer: '',
            sourceContainerSel: null,
            containers: [],
            count: ''
        })
    };

    validateLine(){
        const t = this.props.t;
        let errors = {};
        if(this.state.item == null || this.state.item === '')
            errors[t('item')] = t('required');
        if(this.state.quantity == null || this.state.quantity === ''){
            errors[t('quantity')] = t('required');
        } else {            
            if(this.state.sourceContainerSel != null && this.state.sourceContainerSel !== ''){
                if(parseFloat(this.state.quantity) > this.state.sourceContainerSel.quantity){
                    errors[t('quantity')] = t('moreThanContainer');
                }
            }
        }
        if(this.state.itemSel != null && this.state.itemSel.lotTracked && (this.state.itemSel == null || this.state.sourceContainer === '')){
            errors[t('sourceContainer')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    validateComplete = () => {
        const t = this.props.t;
        let valid = this.validate(true);
        if(!valid)
            return t('notAllComplete');
        if(this.state.sops != null && this.state.sops.length > 0){
            for(let i = 0; i < this.state.sops.length; i++){
                let sop = this.state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        return null;
    };

    changeSop = (e, newValue) => {
        let sops = this.state.sops;
        let tabLabels = this.state.tabLabels;
        if(newValue != null){
            if(sops.length > newValue.length){
                for(let i = 0; i < sops.length; i++){
                    let sop = sops[i];
                    let rec = newValue.find(x => x.value === sop.sop);
                    if(!rec){
                        sops.splice(i, 1);
                        tabLabels.splice(i + 1, 1);
                        break;
                    }
                }
            } else {
                for(let i = 0; i < newValue.length; i++){
                    let val = newValue[i];
                    let rec = sops.find(x => x.sop === val.value)
                    if(!rec){
                        sops.push({
                            sop: val.value,
                            workRecord: null,
                            original: false
                        });
                        if(this.state._id != null && this.state._id !== '')
                            tabLabels.push({name: val.label, disabled: false});
                    }
                }
            }
            let sopIds = [];
            newValue.forEach(val => {
                sopIds.push(val.value);
            });
            this.setState({
                sopIds: sopIds,
                sopsSel: newValue,
                sops: sops
            });
        } else {
            for(let i = 0; i < sops.length; i++){
                let sop = sops[i];
                if(sop.required)
                    return;
            }
            this.setState({sops: [], sopIds: [], sopsSel: [], tabLabels: [{name: this.pros.t('shipment'), disabled: false}]});
        }
    };

    delete = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e));
    }

    _deleteClick = async (e) => {
        const t = this.props.t;
        if(!this.state.locked && this.state.fieldAccess && this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/shipment/' + this.state._id);
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch(err){
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        } else {
            this.setState({enableSave: true, deleteOpen: false});
        }
    };

    appointmentChanged = (e) => {
        this.setState({appointment: e});
    }

    deleteItem = (i) => (e) => {
        let contentLines = this.state.contentLines;
        if(i > -1 && i < contentLines.length){
            contentLines.splice(i, 1);
        }
        this.setState({contentLines: contentLines});
    }

    deleteContentRow = (i, j) => (e) => {
        let contentLines = this.state.contentLines;
        if(i > -1 && i < contentLines.length){
            let line = contentLines[i];
            if(j > -1 && j < line.lines.length){
                line.lines.splice(j, 1);
                line = this.calculateLineTotals(line);
                this.setState({contentLines: contentLines});
            }
        }
    }

    addContentRow = (i, j) => (e) => {
        const t = this.props.t;
        let contentLines = this.state.contentLines;
        if(i > -1 && i < contentLines.length){
            let line = contentLines[i];
            let row = line.lines[j];
            let errors = {};
            if(row.quantity == null || row.quantity === ''){
                errors[`${t('quantity')} (${t('line')} ${i}, ${j})`] = t('required');
            } else {            
                if(row.sourceContainer != null && row.sourceContainer !== ''){
                    if(parseFloat(row.quantity) > row.sourceContainerSel.quantity){
                        errors[`${t('quantity')} (${t('line')} ${i}, ${j})`] = t('moreThanContainer');
                    }
                }
            }
            if(row.quantity == null || row.quantity === '')
                errors[`${t('quantity')} (${t('line')} ${i}, ${j})`] = t('required');
            
            if(line.itemSel.hasCount){
                if(row.count == null || row.count === '')
                    errors[`${t('count')} (${t('line')} ${i}, ${j})`] = t('required');
            }
            if(line.itemSel.lotTracked && (row.sourceContainer == null || line.sourceContainer === '')){
                errors[`${t('sourceContainer')} (${t('line')} ${i}, ${j})`] = t('required')
            }
            if(!isEmpty(errors)){
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                return;
            }
            row.add = false;
            line.lines.push({
                quantity: '',
                count: '',
                sourceContainer: '',
                sourceContainerSel: null,
                add: true
            });
            this.setState({contentLines: contentLines, errors: {}});
        }
    };

    changeSubLineNumber = (name, i, j) => (e) => {
        let contents = this.state.contentLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        if(isNaN(value))
            return;
        row[name] = value;
        if(name === 'quantity' && value != null && value !== '' && line.itemSel.avgWeight != null){
            let count = Math.ceil(parseFloat(value) / parseFloat(line.itemSel.avgWeight));
            row.count = !isNaN(count) ? count : null;
        }
        line = this.calculateLineTotals(line);
        this.setState({contentLines: contents});
    };

    changeSubLineValue = (name, i, j) => (e) => {
        let contents = this.state.contentLines;
        let line = contents[i];
        let row = line.lines[j];
        const value = e.target.value;
        row[name] = value;
        this.setState({contentLines: contents});
    };

    changeSubLineAuto = (name, data, i, j) => (e, newValue) => {
        let contents = this.state.contentLines;
        let line = contents[i];
        let row = line.lines[j];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : '';
        let hasCount = false;
        if(data === 'sourceContainer'){
            row.lot = null;
        }
        this.setState({contentLines: contents, hasCount: hasCount});
    };

    calculateLineTotals(line){
        let quantity = 0.0;
        let count = 0;
        for(let row of line.lines){
            if(row.quantity !== '')
                quantity += parseFloat(row.quantity);
            count += row.count != null && row.count !== '' ? parseInt(row.count) : 0;
        }
        line.quantity = quantity;
        line.count = count !== 0 ? count : null;
        return line;
    }

    PrintViewer = (type) => {
        if(this.state.printViewer === false && type != null && type !== '') {
            let objectType = '';
            let printRecord = '';
            switch(type) {
                case 'bol': 
                case 'coc': {
                    objectType = 'shipment';
                    printRecord = this.state._id;
                    break;
                }
                case 'invoice': {
                    objectType = type;
                    printRecord = this.state.invoice._id;
                    break;
                }default: {
                    break;
                }
            }
            this.setState({
                printViewer: true,
                printRecord: printRecord,
                printType: objectType,
                tenantReport: type
            });
        }else {
            this.setState({
                printViewer: false,
                printRecord: '',
                printType: '',
                tenantReport: ''
            });
        }        
    }

    createInvoice = async () => {
        const t = this.props.t;
        if(this.state.sale != null && this.state.sale !== '') {
            let invoice = null;
            try {
                let result = await axios.post('/api/createinvoice', { sale: this.state.sale, shipments: [this.state._id] });
                invoice = result.data;
                this.setState({ invoice: invoice.id }, () => {
                    this.props.history.replace('/invoice/' + invoice.id);
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: invoice.message, title: t('success'), severity: 'success'}});
                });
            } catch(err){
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            }
        }else {
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: t('noSaleFound'), title: t('error'), severity: 'error'}});
        }
        
    }

    deleteSigCallback = async (type) => {
        try{
            let result = await axios.post('/api/shipmentsigdelete', {type: type, id: this.state._id});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    render(){
        const { hasCount, locked, fieldAccess, signed, security } = this.state;
        const t = this.props.t;
        const errors = this.props.errors;
        const internal = this.props.auth.user.internal;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        printList={this.state.printList}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.shipment}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        finalDocuments={this.state.finalDocuments}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabCanvas value={this.state.tabKey} index={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} lg={3}>
                            <Panel>
                                <Grid container spacing={2}>
                                    {!fieldAccess && this.state.number != null && this.state.number !== '' &&
                                        <Grid item xs={12}>
                                            <FormControl data-cy="number">
                                                <FormLabel>{t('number')}</FormLabel>
                                                {this.state.number}
                                            </FormControl>
                                        </Grid>
                                    }
                                    {fieldAccess &&
                                        <Grid item xs={12}>
                                            <TextField value={this.state.number} onChange={this.changeValue} name="number"
                                            size="medium" fullWidth disabled={!fieldAccess} label={t('number')} data-cy='number'
                                            error={errors[t('number')] != null ? true : false} helperText={errors[t('number')]}/>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <FormControl data-cy="status">
                                            <FormLabel>{t('status')}</FormLabel>
                                            {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fieldAccess &&
                                            <DateSelect
                                                onChange={this.changeDate('date')}
                                                value={this.state.date}
                                                helperText={errors[t('date')]}
                                                error={errors[t('date')] != null ? true : false}
                                                label={t('date')}
                                                disabled={!fieldAccess}
                                                format={this.props.auth.user.dateFormat}
                                                required
                                                data-cy="date"
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('date')}</FormLabel>
                                                <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                value={this.state.locationSel}
                                                options={this.state.locations}
                                                onChange={this.changeAuto('locationSel', 'location')}
                                                error={errors[t('location')] != null ? true : false}
                                                helperText={errors[t('location')]}
                                                label={t('location')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy="location"
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('location')}</FormLabel>
                                                {this.state.locationSel != null ? this.state.locationSel.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                value={this.state.typeSel}
                                                options={this.state.types}
                                                onChange={this.changeAuto('typeSel', 'type')}
                                                error={errors[t('type')] != null ? true : false}
                                                helperText={errors[t('type')]}
                                                label={t('type')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy="type"
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('type')}</FormLabel>
                                                {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                </Grid>
                            </Panel>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Panel>
                                <Grid container spacing={2}>
                                    {this.state.type != null && (this.state.type === this.props.auth.constants.shipmentTypes.sale ||
                                    this.state.type === this.props.auth.constants.shipmentTypes.test) &&
                                        <Grid item xs={12}>
                                            {fieldAccess ?
                                                <AutoCompleteField
                                                    value={this.state.customerSel}
                                                    options={this.state.customers}
                                                    onChange={this.changeAuto('customerSel', 'customer')}
                                                    error={errors[t('customer')] != null ? true : false}
                                                    helperText={errors[t('customer')]}
                                                    label={t('customer')}
                                                    disabled={!fieldAccess || (this.state.vendor != null && this.state.vendor !== '')}
                                                    required
                                                    data-cy="customer"
                                                /> : this.state.customerSel != null ?
                                                <FormControl>
                                                    <FormLabel>{t('customer')}</FormLabel>
                                                    <Link to={'/customer/' + this.state.customer}>{this.state.customerSel.label}</Link>
                                                </FormControl> : null
                                            }
                                        </Grid>
                                    }
                                    {this.state.type != null && this.state.type === this.props.auth.constants.shipmentTypes.sale &&
                                        this.state.customer != null && this.state.customer !== '' &&
                                        <Grid item xs={this.state.invoice != null && this.state.invoice !== '' ? 6 : 12}>
                                            {fieldAccess ?
                                                <AutoCompleteField
                                                    value={this.state.saleSel}
                                                    options={this.state.sales}
                                                    onChange={this.changeAuto('saleSel', 'sale')}
                                                    error={errors[t('sale')] != null ? true : false}
                                                    helperText={errors[t('sale')]}
                                                    label={t('sale')}
                                                    disabled={!fieldAccess}
                                                    required
                                                    data-cy="sale"
                                                /> : this.state.saleObject != null ?
                                                <FormControl>
                                                    <FormLabel>{t('sale')}</FormLabel>
                                                    <Link to={'/sale/' + this.state.saleObject._id}>{this.state.saleObject.number}</Link>
                                                </FormControl> : null
                                            }
                                        </Grid>
                                    }
                                    {this.state.type != null && this.state.type === this.props.auth.constants.shipmentTypes.sale &&
                                        this.state.invoice != null && this.state.invoice !== '' &&
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <FormLabel>{t('invoice')}</FormLabel>
                                                <Link to={'/invoice/' + this.state.invoice._id}>{this.state.invoice.number}</Link>
                                            </FormControl>
                                        </Grid>
                                    }
                                    {this.state.type != null && (this.state.type === this.props.auth.constants.shipmentTypes.test ||
                                    this.state.type === this.props.auth.constants.shipmentTypes.treatment ||
                                    this.state.type === this.props.auth.constants.shipmentTypes.research ||
                                    this.state.type === this.props.auth.constants.shipmentTypes.return) &&
                                        <Grid item xs={12}>
                                            {fieldAccess ?
                                                <AutoCompleteField
                                                    value={this.state.vendorSel}
                                                    options={this.state.vendors}
                                                    onChange={this.changeAuto('vendorSel', 'vendor')}
                                                    error={errors[t('vendor')] != null ? true : false}
                                                    helperText={errors[t('vendor')]}
                                                    label={t('vendor')}
                                                    disabled={!fieldAccess || (this.state.customer != null && this.state.customer !== '')}
                                                    required
                                                    data-cy="vendor"
                                                /> : this.state.vendorSel != null ?
                                                <FormControl>
                                                    <FormLabel>{t('vendor')}</FormLabel>
                                                    <Link to={'/vendor/' + this.state.vendor}>{this.state.vendorSel.label}</Link>
                                                </FormControl> : null
                                            }
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        {fieldAccess ?
                                            <AutoCompleteField
                                                value={this.state.courierSel}
                                                options={this.state.couriers}
                                                onChange={this.changeAuto('courierSel', 'courier')}
                                                error={errors[t('courier')] != null ? true : false}
                                                helperText={errors[t('courier')]}
                                                label={t('courier')}
                                                disabled={!fieldAccess}
                                                data-cy="courier"
                                            /> : this.state.courierSel != null ?
                                                <FormControl>
                                                    <FormLabel>{t('courier')}</FormLabel>
                                                    <Link to={'/vendor/' + this.state.courierSel.value}>
                                                        {this.state.courierSel != null ? this.state.courierSel.label : ''}
                                                    </Link>
                                                </FormControl> : null
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fieldAccess ?
                                            <TextField value={this.state.shippingNumber} onChange={this.changeValue} name="shippingNumber"
                                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('shippingNumber')} data-cy="shippingNumber"
                                            error={errors[t('shippingNumber')] != null ? true : false} helperText={errors[t('shippingNumber')]}/> :
                                            <FormControl>
                                                <FormLabel>{t('shippingNumber')}</FormLabel>
                                                {this.state.shippingNumber}
                                            </FormControl>
                                        }
                                    </Grid>
                                    {this.state.customerSel != null && this.state.customerSel.foreignId === 'aglc' &&
                                        <Grid item xs={12}>
                                            {fieldAccess ?
                                                <TextField value={this.state.asn} onChange={this.changeValue} name="asn"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('asn')} required
                                                error={errors[t('asn')] != null ? true : false} helperText={errors[t('asn')]}
                                                data-cy="asn"/> :
                                                <FormControl>
                                                    <FormLabel>{t('asn')}</FormLabel>
                                                    {this.state.asn}
                                                </FormControl>
                                            }
                                        </Grid>
                                    }
                                    {this.state.customerSel != null && 
                                    (this.state.customerSel.foreignId === 'aglc' || this.state.customerSel.foreignId === 'ocs') &&
                                        <Grid item xs={12}>
                                            {fieldAccess ? 
                                                <DateTimePicker
                                                    onChange={this.appointmentChanged}
                                                    value={this.state.appointment}
                                                    helperText={errors[t('appointment')]}
                                                    error={errors[t('appointment')] != null ? true : false}
                                                    disabled={!fieldAccess}
                                                    label={t('appointment')}
                                                    name="appointment"
                                                    required
                                                    data-cy="appointement"
                                                    format={this.props.auth.user.dateFormat }
                                                /> :
                                                <FormControl>
                                                    <FormLabel>{t('appointment')}</FormLabel>
                                                    <DateDisplay value={this.state.appointment} format={this.props.auth.user.dateFormat}/>
                                                </FormControl>
                                            }                                            
                                        </Grid>
                                    }
                                    {this.state.type != null && this.state.type === this.props.auth.constants.shipmentTypes.sale &&
                                        <Grid item xs={12}>
                                            {fieldAccess ?
                                                <DateSelect
                                                    onChange={this.changeDate('expectedDelivery')}
                                                    value={this.state.expectedDelivery}
                                                    helperText={errors[t('expectedDelivery')]}
                                                    error={errors[t('date')] != null ? true : false}
                                                    label={t('expectedDelivery')}
                                                    disabled={!fieldAccess}
                                                    format={this.props.auth.user.dateFormat}
                                                    required
                                                    data-cy="expectedDelivery"
                                                /> :
                                                <FormControl>
                                                    <FormLabel>{t('expectedDelivery')}</FormLabel>
                                                    <DateDisplay value={this.state.expectedDelivery} format={this.props.auth.user.dateFormat}/>
                                                </FormControl>
                                            }                                            
                                        </Grid>
                                    }
                                </Grid>
                            </Panel>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Panel>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {fieldAccess ?
                                            <AutoCompleteField
                                                value={this.state.countrySel}
                                                options={this.state.countries}
                                                onChange={this.changeAuto('countrySel', 'country')}
                                                error={errors[t('country')] != null ? true : false}
                                                helperText={errors[t('country')]}
                                                label={t('country')}
                                                disabled={!fieldAccess}
                                                data-cy="country"
                                            /> :
                                            <FormControl>
                                                <FormLabel>{t('country')}</FormLabel>
                                                {this.state.countrySel != null ? this.state.countrySel.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fieldAccess ?
                                            <TextField value={this.state.address1} onChange={this.changeValue} name="address1"
                                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('street')} required
                                            error={errors[t('address1')] != null ? true : false} helperText={errors[t('address1')]}
                                            data-cy="address1"/> :
                                            <FormControl>
                                                <FormLabel>{t('address')}</FormLabel>
                                                {this.state.address1}
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fieldAccess ?
                                            <TextField value={this.state.address2} onChange={this.changeValue} name="address2"
                                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('street2')} 
                                            error={errors[t('address2')] != null ? true : false} helperText={errors[t('address2')]}
                                            data-cy="address2"/> : this.state.address2 != null && this.state.address2 !== '' ?
                                            <FormControl>
                                                <FormLabel></FormLabel>
                                                {this.state.address2}
                                            </FormControl> : null
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fieldAccess ?
                                            <TextField value={this.state.city} onChange={this.changeValue} name="city"
                                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('city')} required
                                            error={errors[t('city')] != null ? true : false} helperText={errors[t('city')]}
                                            data-cy="city"/> : 
                                            <FormControl>
                                                <FormLabel>{t('city')}</FormLabel>
                                                {this.state.city}
                                            </FormControl>
                                        }                                        
                                    </Grid>
                                    <Grid item xs={12}>
                                        {fieldAccess ?
                                            <AutoCompleteField
                                                value={this.state.provSel}
                                                options={this.state.provs}
                                                onChange={this.changeAuto('provSel', 'prov')}
                                                error={errors[t('prov')] != null ? true : false}
                                                helperText={errors[t('prov')]}
                                                label={t('prov')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy="prov"
                                            /> :
                                            <FormControl>
                                                <FormLabel>{t('prov')}</FormLabel>
                                                {this.state.provSel != null ? this.state.provSel.label : ''}
                                            </FormControl>
                                        }                                        
                                    </Grid>
                                    {(this.state.country == null || this.state.country === '' || this.state.country === this.props.auth.constants.countries.CA) &&
                                        <Grid item xs={12}>
                                            {fieldAccess &&
                                                <InputMaskField mask="a9a-9a9" name="postal" value={this.state.postal}
                                                onChange={this.changeValue}
                                                helperText={errors[t('postal')]}
                                                error={errors[t('postal')] != null ? true : false}
                                                label={t('postal')}
                                                required
                                                data-cy="postal"
                                                />
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('postal')}</FormLabel>
                                                    {this.state.postal}
                                                </FormControl>
                                            }
                                        </Grid>
                                    }
                                    {this.state.country != null && this.state.country !== '' && this.state.country === this.props.auth.constants.countries.US &&
                                        <Grid item xs={12}>
                                            {fieldAccess &&
                                                <InputMaskField mask="99999" name="postal" value={this.state.postal}
                                                onChange={this.changeValue}
                                                helperText={errors[t('postal')]}
                                                error={errors[t('postal')] != null ? true : false}
                                                label={t('zipCode')}
                                                required
                                                data-cy="postal"
                                                />
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('zipCode')}</FormLabel>
                                                    {this.state.postal}
                                                </FormControl>
                                            }
                                        </Grid>
                                    }
                                    {this.state.country != null && this.state.country !== '' && this.state.country !== this.props.auth.constants.countries.CA &&
                                    this.state.country !== this.props.auth.constants.countries.US &&
                                        <Grid item xs={12}>
                                            {fieldAccess &&
                                                <TextField value={this.state.postal} onChange={this.changeValue} name="postal"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('postal')} data-cy="postal"
                                                error={errors[t('postal')] != null ? true : false} helperText={errors[t('postal')]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('zipCode')}</FormLabel>
                                                    {this.state.postal}
                                                </FormControl>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            </Panel>
                        </Grid>
                        {this.state.hasForm === true &&
                            <Grid item xs={12} lg={3}>
                                <Panel>
                                    <Grid item xs={12}>
                                        {errors[t('form')] != null && errors[t('form')] !== '' &&
                                            <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                        }
                                        <Form renderer={renderer}
                                            defaultFields={this.state.form}
                                            onChange={this.formValueChanged}
                                            disabled={!fieldAccess}
                                            print={false}
                                        />
                                    </Grid>
                                </Panel>                                
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Panel>
                                <Grid container spacing={2}>
                                    <Grid item xs={11}>
                                        <AutoCompleteField
                                            multiple={true}
                                            value={this.state.usersSel}
                                            options={fieldAccess && !internal ? this.state.activeUsers : this.state.userList}
                                            onChange={this.changeMulti('usersSel', 'users')}
                                            error={errors[t('users')] != null ? true : false}
                                            helperText={errors[t('users')]}
                                            label={t('users')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="users"
                                        />
                                    </Grid>
                                </Grid>
                            </Panel>
                        </Grid>
                        {this.state.sopList != null && this.state.sopList.length > 0 &&
                            <Grid item sm={12}>
                                <Panel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <AutoCompleteField
                                                multiple={true} disabled={!fieldAccess}
                                                value={this.state.sopsSel}
                                                options={this.state.sopList}
                                                onChange={this.changeSop}
                                                error={errors[t('sopIds')] != null ? true : false}
                                                helperText={errors[t('sopIds')]}
                                                label={t('workRecs')}
                                                data-cy="sops"
                                            />
                                        </Grid>
                                    </Grid>
                                </Panel>                                
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Panel>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <SectionTitle title={t('contents')}/>
                                            {this.state.contentLines.map((row, i) =>
                                                <Box borderColor="primary.main" {...defaultProps} component={'div'} key={'cl' + i}>
                                                    <Grid container spacing={3}>
                                                        <Grid item sx={{width: 350}}>
                                                            <FormControl>
                                                                <FormLabel>{t('item')}</FormLabel>
                                                                <Link to={'/item/' + row.itemSel.value}>{row.itemSel != null ? row.itemSel.label : ''}</Link>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item sx={{width: 150}}>
                                                            <FormControl>
                                                                <FormLabel>{t('quantity')}</FormLabel>
                                                                <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                    value={row.quantity != null && row.quantity !== '' ? row.quantity : 0}
                                                                    suffix={row.uomSel != null ? row.uomSel.label : ''}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        {row.count != null && row.count !== 0 &&
                                                            <Grid item sx={{width: 100}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('count')}</FormLabel>
                                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                        value={row.count != null && row.count !== '' ? row.count : 0}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        }
                                                        {row.expectedQuantity != null && row.expectedQuantity !== 0 &&
                                                            <Grid item sx={{width: 150}}>
                                                                <FormControl>
                                                                    <FormLabel>{t('expected')}</FormLabel>
                                                                    <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                    value={row.expectedQuantity != null && row.expectedQuantity !== '' ? row.expectedQuantity : 0}
                                                                    suffix={row.uomSel != null ? row.uomSel.label : ''}
                                                                />
                                                                </FormControl>
                                                            </Grid>

                                                        }
                                                        {fieldAccess &&
                                                            <Grid item sx={{width: 50}}>
                                                                <IconButton aria-label="delete line" onClick={this.deleteItem(i)} data-cy={"deleteLine" + i}>
                                                                    <RemoveCircleTwoToneIcon  />
                                                                </IconButton>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                    {row.lines != null && row.lines.length > 0 &&
                                                        <Grid container spacing={3} key={'rhead' + i}>
                                                            <Grid item sx={{width: 110}}>
                                                                <FormLabel>{t('quantity')}</FormLabel>
                                                            </Grid>
                                                            {row.itemSel.hasCount === true &&
                                                                <Grid item sx={{width: 100}}>
                                                                    <FormLabel>{t('count')}</FormLabel>
                                                                </Grid>
                                                            }
                                                            {row.itemSel.lotTracked &&
                                                                <Aux>
                                                                    <Grid item sx={{width: 250}}>
                                                                        <FormLabel>{t('container')}</FormLabel>
                                                                    </Grid>
                                                                    {!fieldAccess &&
                                                                        <Grid item sx={{width: 140}}>
                                                                            <FormLabel>{t('lot')}</FormLabel>
                                                                        </Grid>
                                                                    }
                                                                </Aux>
                                                            }
                                                        </Grid>
                                                    }
                                                    {row.lines != null && row.lines.length > 0 && row.lines.map((line, k) =>
                                                        <Aux key={'rl' + i + 'a' + k}>
                                                            {!fieldAccess &&
                                                                <Grid container spacing={3} sx={{pb: 2}}>
                                                                    <Grid item sx={{width: 110}} align="center">
                                                                        <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={3}
                                                                            value={line.quantity != null && line.quantity !== '' ? line.quantity : 0}
                                                                        />
                                                                    </Grid>
                                                                    {row.itemSel.hasCount === true &&
                                                                        <Grid item sx={{width: 100}} align="center">
                                                                            <NumericFormat displayType={'text'} thousandSeparator={true} decimalScale={0}
                                                                                value={line.count != null && line.count !== '' ? line.count : 0}
                                                                            />
                                                                        </Grid>
                                                                    }
                                                                    {row.itemSel.lotTracked &&
                                                                        <Aux>
                                                                            <Grid item sx={{width: 250}}>
                                                                                {line.sourceContainer != null &&
                                                                                    <Link to={'/container/' + line.sourceContainer}>{line.sourceContainerSel.label}</Link>
                                                                                }
                                                                            </Grid>
                                                                            <Grid item sx={{width: 140}}>
                                                                                {line.lot != null &&
                                                                                    <Link to={'/lot/' + line.lot._id}>{line.lot.number}</Link>
                                                                                }
                                                                            </Grid>
                                                                        </Aux>
                                                                    }
                                                                </Grid>
                                                            }
                                                            {fieldAccess &&
                                                                <Aux key={'conl' + i + 'a' + k}>
                                                                    <Grid container spacing={3} key={'conla' + i + 'a' + k}>
                                                                        <Grid item sx={{width: 110}}>
                                                                            <TextField type="number" value={line.quantity} onChange={this.changeSubLineNumber('quantity', i, k)} name={"quantity" + i + 'a' + k}
                                                                            size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"quantity" + i + 'a' + k}
                                                                            error={errors[`${t('quantity')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('quantity')} (${t('line')} ${i}, ${k})`]}/>
                                                                        </Grid>
                                                                        {row.itemSel.hasCount &&
                                                                            <Grid item sx={{width: 100}}>
                                                                                <TextField type="number" value={line.count} onChange={this.changeSubLineNumber('count', i, k)} name={"count" + i + 'a' + k}
                                                                                size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy={"count" + i + 'a' + k}
                                                                                error={errors[`${t('lot')} (${t('line')} ${i}, ${k})`] != null ? true : false} helperText={errors[`${t('lot')} (${t('line')} ${i}, ${k})`]}/>
                                                                            </Grid>
                                                                        }
                                                                        {row.itemSel.lotTracked &&
                                                                            <Grid item sx={{width: 250}}>
                                                                                <AutoCompleteField
                                                                                    value={line.sourceContainerSel}
                                                                                    options={row.containers}
                                                                                    onChange={this.changeSubLineAuto('sourceContainerSel', 'sourceContainer', i, k)}
                                                                                    error={errors[`${t('quantity')} (${t('line')} ${i}, ${k})`] != null ? true : false}
                                                                                    helperText={errors[`${t('quantity')} (${t('line')} ${i}, ${k})`]}
                                                                                    disabled={!fieldAccess}
                                                                                    required
                                                                                    data-cy={"container" + i + 'a' + k}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        <Grid item sx={{width: 50}}>
                                                                            {line.add === true &&
                                                                                <IconButton aria-label="add line" onClick={this.addContentRow(i, k)} data-cy={"addRow" + i + 'a' + k}>
                                                                                    <AddBoxTwoToneIcon  />
                                                                                </IconButton>
                                                                            }
                                                                            {line.add !== true &&
                                                                                <IconButton aria-label="delete line" onClick={this.deleteContentRow(i, k)} data-cy={"deleteRow" + i + 'a' + k}>
                                                                                    <RemoveCircleTwoToneIcon  />
                                                                                </IconButton>
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </Aux>
                                                            }
                                                        </Aux>
                                                    )}
                                                </Box>
                                            )}
                                            {!locked && fieldAccess &&
                                                <Aux>
                                                    <br/>
                                                    <SectionTitle title={t('add')}/>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={8} sm={4}>{t('item')}</Grid>
                                                        {fieldAccess &&
                                                            <Aux>
                                                                <Grid item sx={{width: 150}}>{t('quantity')}</Grid>
                                                                <Grid item xs={2} sm={1}>{t('uom')}</Grid>
                                                            </Aux>
                                                        }
                                                        {!fieldAccess &&
                                                            <Grid item sx={{width: 150}}>{t('quantity')}</Grid>
                                                        }
                                                        {hasCount &&
                                                            <Grid item xs={2} sm={1}>{t('count')}</Grid>
                                                        }
                                                        {signed &&
                                                            <Grid item xs={4} sm={2}>{t('lot')}</Grid>
                                                        }
                                                        {this.state.itemSel != null && this.state.itemSel.lotTracked &&
                                                            <Grid item style={{width: 230}}>{t('container')}</Grid>
                                                        }
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={8} sm={4}>
                                                            <AutoCompleteField
                                                                value={this.state.itemSel}
                                                                options={this.state.allItems}
                                                                onChange={this.changeAuto('itemSel', 'item')}
                                                                error={errors[t('item')] != null ? true : false}
                                                                helperText={errors[t('item')]}
                                                                disabled={!fieldAccess}
                                                                required
                                                                data-cy="item"
                                                            />
                                                        </Grid>
                                                        <Grid item sx={{width: 150}}>
                                                            <TextField type="number" value={this.state.quantity} onChange={this.changeNumber} name={"quantity"}
                                                            size="medium" fullWidth={true} disabled={!fieldAccess} required data-cy="quantity"
                                                            error={errors[t('quantity')] != null ? true : false} helperText={errors[t('quantity')]}/>
                                                        </Grid>
                                                        <Grid item xs={2} sm={1}>
                                                            <AutoCompleteField
                                                                value={this.state.uomSel}
                                                                options={this.state.uoms}
                                                                onChange={this.changeAuto('uomSel', 'uom')}
                                                                error={errors[t('uom')] != null ? true : false}
                                                                helperText={errors[t('uom')]}
                                                                disabled={true}
                                                                data-cy="uom"
                                                            />
                                                        </Grid>
                                                        {hasCount &&
                                                            <Grid item xs={2} sm={1}>
                                                                <TextField type="number" value={this.state.count} onChange={this.changeNumber} name={"count"}
                                                                size="medium" fullWidth={true} disabled={!fieldAccess || this.state.itemSel == null || !this.state.itemSel.hasCount}
                                                                required data-cy="count"
                                                                error={errors[t('count')] != null ? true : false} helperText={errors[t('count')]}/>
                                                            </Grid>
                                                        }
                                                        {this.state.itemSel != null && this.state.itemSel.lotTracked &&
                                                            <Grid item style={{width: 230}}>
                                                                <AutoCompleteField
                                                                    value={this.state.sourceContainerSel}
                                                                    options={this.state.containers}
                                                                    onChange={this.changeAuto('sourceContainerSel', 'sourceContainer')}
                                                                    error={errors[t('sourceContainer')] != null ? true : false}
                                                                    helperText={errors[t('sourceContainer')]}
                                                                    disabled={!fieldAccess}
                                                                    required
                                                                    data-cy="container"
                                                                    groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                                />
                                                            </Grid>
                                                        }
                                                        <Grid item xs={2} sm={1}>
                                                            <IconButton aria-label="add line" onClick={this.addLine} data-cy="addLine">
                                                                <AddBoxTwoToneIcon  />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Aux>
                                            }
                                    </Grid>
                                </Grid>
                            </Panel>
                        </Grid>
                    </Grid>
                    <br/>
                    <div style={{ display: 'inline-block' }}>
                        <FormButtonStack
                            id={this.state._id}
                            objectType={this.props.auth.constants.objectIds.shipment}
                            callback={this.loadRecord}
                            saveClick={() => this.save()}
                            status={this.state.status}
                            permissions={this.props.permission}
                            common={this.props.auth.common}
                            constants={this.props.auth.constants}
                            saveEnabled={this.state.enableSave}
                            deleteClick={this.delete}
                            enableUnlockProp={this.state.enableUnlock}
                            activityDate={this.state.date}
                            lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                            isSuper={this.props.auth.user.isSuper}
                        />
                    </div>
                </TabCanvas>
                {this.state._id != null && this.state._id !== '' && this.state.sopsSel != null &&
                    this.state.sopsSel.length > 0 && this.state.sopsSel.map((sop, i) =>
                    <TabPanel value={this.state.tabKey} index={i + 1}>
                        <br/>
                        <ActivityWorkRecord users={this.state.users} sop={sop.value} id={this.state.sops[i].workRecord} tab={this.state.tabKey}
                        objectType={this.props.auth.constants.objectIds.shipment} recordId={this.state._id} date={this.state.date}
                        locationId={this.state.location} renderNum={this.state.renderNum} complete={this.loadRecord}/>
                    </TabPanel>
                )}
                {this.state.hasLedgers &&
                    <TabCanvas value={this.state.tabKey} index={this.state.tabLabels.length - 1}>
                        <ItemLedgerComponent record={this.state._id} permission={this.props.permission} auth={this.props.auth} renderNum={this.state.renderNum}/>
                    </TabCanvas>
                }
                {this.state.printViewer &&
                    <PrintView
                        id={this.state.printRecord}
                        object={this.props.auth.constants.objectIds[this.state.printType]}
                        tenantReport={this.state.tenantReport}
                        open={this.state.printViewer}
                        onClose={this.PrintViewer}
                        callBack={this.loadRecord}
                        printAccess={security.print}
                    />
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete Dialog={() => this.delete()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.shipment}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Shipment));
