import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { FormLabel } from '@mui/material';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import FormControl from '@mui/material/FormControl';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import Panel from '../UI/Panel/Panel';
import RichTextField from '../UI/RichTextField/RichTextField';
import RichText from '../../hoc/RichText/RichText';
import Divider from '@mui/material/Divider';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';

class ManagedDocumentTemplate extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            name: '',
            documentType: '',
            documentTypeSel: null,
            documentTypes: [],
            report: '',
            reportSel: {},
            reports: [],
            text: '',
            security: {},
            fieldAccess: false,
            enableSave: true,
            deleteOpen: false
        }
    }

    async componentDidMount(){
        const { documentTypes, tenantReports } = 
        await ValueHelper.getCachedValues([common.cacheValues.documentType, common.cacheValues.tenantReport], this.props.auth.constants, null, this.props.auth.user.tenant);
        this.setState({
            security: this.props.permission,
            documentTypes: documentTypes,
            reports: tenantReports,
            fieldAccess: this.props.permission.update,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/manageddocumentTemplates', label: this.props.t('documentTemplate'), screen: this.props.auth.screenDefs.ManagedDocumentTemplate},
                    { path: 'active', label: this.props.t('documentTemplate'), screen: this.props.auth.screenDefs.ManagedDocumentTemplate}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/documenttemplate/' + this.state._id);
        let documentTypeSel = this.state.documentTypes.find(x => x.value === record.data.documentType);
        let reportSel = record.data.report != null ? this.state.reports.find(x => x.value === record.data.report) : null;
        this.setState({
            number: record.data.number,
            name: record.data.name,
            documentType: record.data.documentType,
            documentTypeSel: documentTypeSel,
            text: record.data.text,
            report: record.data.report,
            reportSel: reportSel,
            fieldAcces: this.props.permission.update,
            deleteOpen: false
        }, () => {
            const crumbs = [
                { path: '/#/manageddocumentTemplates', label: this.props.t('documentTemplate'), screen: this.props.auth.screenDefs.ManagedDocumentTemplate},
                { path: 'active', label: record.data.name + ' - ' + record.data.number, screen: this.props.auth.screenDefs.ManagedDocumentTemplate}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        let valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let data = {
            _id: this.state._id,
            name: this.state.name,
            documentType: this.state.documentType,
            text: this.state.text,
            report: this.state.report
        };
        try {
            let result = await axios.post('/api/documenttemplate', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/manageddocumenttemplate/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.loadRecord();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            });
        } catch (err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });
        }
    };

    validate(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.name == null || state.name === '')
            errors[t('name')] = t('required');
        if(state.documentType == null || state.documentType === '')
            errors[t('documentType')] = t('required');
        if(state.text == null || state.text === '')
            errors[t('text')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._deleteClick(e));
    }

    _deleteClick = async (e) => {
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/documenttemplate/' + this.state._id);
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
                this.props.history.goBack();
            } catch (err){
                this.setState({enableSave: true, deleteOpen: false});
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            }
        } else {
            this.setState({enableSave: true, deleteOpen: false});
            this.props.history.goBack();
        }
    };

    changeRichText = (index, newText, e) => {
        this.setState({text: newText});
    }
    
    render() {
        const fieldAccess = this.state.fieldAccess;
        const errors = this.props.errors;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        fieldAccess={security.update}
                    />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        {this.state._id != null && this.state._id !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl data-cy='number'>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={8} sm={4}>
                            <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('name')} data-cy='name'
                                error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.documentTypeSel}
                                options={this.state.documentTypes}
                                onChange={this.changeAuto('documentTypeSel', 'documentType')}
                                error={errors[t('documentType')] != null ? true : false}
                                helperText={errors[t('documentType')]}
                                label={t('documentType')}
                                disabled={!fieldAccess}
                                data-cy='documentType'
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.reportSel}
                                options={this.state.reports}
                                onChange={this.changeAuto('reportSel', 'report')}
                                error={errors[t('report')] != null ? true : false}
                                helperText={errors[t('report')]}
                                label={t('reportSettings')}
                                disabled={!fieldAccess}
                                data-cy='reports'
                            />
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <RichTextField
                                index={0}
                                key='documentTemplate-content'
                                id='documentTemplate-content'
                                disabled={!fieldAccess}                                
                                value={this.state.text}
                                errors={errors}
                                textAreaName='text'
                                height={600}
                                changeRichText={this.changeRichText}
                                data-cy='content'
                            />
                        </Grid>
                        {this.state.reportSel != null && this.state.reportSel !== '' &&
                         this.state.reportSel.data != null && this.state.reportSel.data !== '' &&
                            <Grid item xs={12} md={6}>
                                {this.state.reportSel.data.margin != null && this.state.reportSel.data.margin !== '' &&
                                    <FormControl sx={{width: '100%'}}>
                                        <FormLabel>{t('margins')}</FormLabel>
                                            <Grid container spacing={3}>
                                                <Grid item>
                                                    <FormControl>
                                                        <FormLabel>{t('topMargin')}</FormLabel>
                                                        {this.state.reportSel.data.margin.top != null && this.state.reportSel.data.margin.top !== '' && this.state.reportSel.data.margin.top}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <FormControl>
                                                        <FormLabel>{t('bottomMargin')}</FormLabel>
                                                        {this.state.reportSel.data.margin.bottom != null && this.state.reportSel.data.margin.bottom !== '' && this.state.reportSel.data.margin.bottom}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <FormControl>
                                                        <FormLabel>{t('leftMargin')}</FormLabel>
                                                        {this.state.reportSel.data.margin.left != null && this.state.reportSel.data.margin.left !== '' && this.state.reportSel.data.margin.left}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <FormControl>
                                                        <FormLabel>{t('rightMargin')}</FormLabel>
                                                        {this.state.reportSel.data.margin.right != null && this.state.reportSel.data.margin.right !== '' && this.state.reportSel.data.margin.right}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                    </FormControl>
                                }
                                <Divider /><br />
                                <FormControl>
                                    <FormLabel>{t('header')}</FormLabel>
                                    {this.state.reportSel.data.header.header != null && this.state.reportSel.data.header.header !== '' &&
                                        <RichText>{this.state.reportSel.data.header.header}</RichText>
                                    }
                                </FormControl>
                                <Divider /><br />
                                <FormControl sx={{width: '100%'}}>
                                    <FormLabel>{t('footer')}</FormLabel>
                                    {this.state.reportSel.data.header.footer != null && this.state.reportSel.data.header.footer !== '' &&
                                        <RichText>{this.state.reportSel.data.header.footer}</RichText>
                                    }
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <br/>
                    <FormButtonStack
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.managedDocumentTemplate}
                        saveClick={this.save}
                        status={this.props.auth.constants.statuses.inprogress}
                        permissions={this.props.permission}
                        common={this.props.auth.common}
                        constants={this.props.auth.constants}
                        saveEnabled={this.state.enableSave}
                        deleteClick={this.deleteToggle}
                    />
                </Panel>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ManagedDocumentTemplate));
