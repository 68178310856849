import React, { Component } from 'react';
import axios from 'axios';
//import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import { connect } from 'react-redux';
import SectionTitle from '../UI/Typography/SectionTitle';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import TextField from '../UI/TextField/TextField';
import { withTranslation } from 'react-i18next';
import DocumentCertification from '../Documents/DocumentCertification';
import Link from '../UI/Link/Link';
import common from '../../jsons/common.json';

class UserCertifications extends Component{
    constructor(props){
        super(props);
        this.state = {
            list: [],
            listSize: 10,
            offset: 0,
            pages: 1,
            orderBy: 'expiration',
            ordDir: -1,
            totalCount: 0,
            page: 0,
            user: '',
            userSel: null,
            users: [],
            expiration: '',
            documentSel: null,
            documents: [],
            departmentOptions: [],
            department: '',
            departmentSel: null,
            name: '',
            certification: {},
            showCertification: false,
            sopSel: ''
        };
    }

    async componentDidMount(){
        const values = await axios.get('/api/uservalues');
        this.setState({
            departmentOptions: values.data.departments
        }, () => {
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        var sort = {[this.state.orderBy]: this.state.ordDir};
        let data = {
            offset: this.state.offset,
            size: this.state.listSize,
            sort: JSON.stringify(sort),
            name: this.state.name,
            department: this.state.department
        };
        let list = await axios.get('/api/userdocuments/' + this.props.id, {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        });
    };

    changeValue = e => {
        this.setState({[e.target.name]: e.target.value, page: 0, offset: 0}, () => {this.loadRecords()});
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        var orderBy = this.state.orderBy;
        var ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir: ordDir, offset: 0, page: 0}, () => {this.loadRecords();});
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value), page: 0, offset: 0}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    closeDialog = () => {
        this.setState({ 
            showCertification: false,
            certification: null,
            documentSel: null
        }, () => this.loadRecords());
    }

    openCertify = (document) => {
        this.setState({
            showCertification: true,
            certification: document.cert,
            documentSel: document
        });
    }

    render(){
        const t = this.props.t;
        return(
            <Aux>
                {this.props.title != null &&
                    <SectionTitle title={this.props.title}/>
                }
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <TextField value={this.state.name} onChange={this.changeValue} name="name"
                        size="medium" fullWidth={true} label={t('name')}/>
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <AutoCompleteField
                            value={this.state.departmentSel}
                            options={this.state.departmentOptions}
                            onChange={this.changeAuto('departmentSel', 'department')}
                            label={t('department')}
                        />
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="usercerts">
                        <TableHead>
                            <TableRow key="userCertHeader">
                                <TableCell width="60%">
                                    <TableSortLabel onClick={() => this.sortClick('document')}
                                    active={this.state.orderBy === 'document'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>  {t('document')}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width="30%">
                                    <TableSortLabel onClick={() => this.sortClick('department')}
                                    active={this.state.orderBy === 'department'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('department')}
                                    </TableSortLabel>
                                    
                                </TableCell>
                                <TableCell width="10%">
                                    <TableSortLabel onClick={() => this.sortClick('expiration')}
                                    active={this.state.orderBy === 'expiration'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('expiration')}
                                    </TableSortLabel>
                                </TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.list.map((row, i) =>
                                <TableRow key={row._id}>
                                    <TableCell>
                                        <Link onClick={() => this.openCertify(row)}>{row.name + ' ' + row.version}</Link>
                                    </TableCell>
                                    {/* {row.certId != null &&
                                        <TableCell>
                                            <Link to={'/certid/' + row.certId}>{row.name + ' ' + row.version}</Link>
                                        </TableCell>
                                    }
                                    {row.certId == null &&
                                        <TableCell>
                                            <Link to={'/cert/?user=' + this.props.auth.user.id + '&document=' + row._id}>{row.name + ' ' + row.version}</Link>
                                        </TableCell>
                                    } */}
                                    <TableCell>{row.departmentString}</TableCell>
                                    <TableCell>{row.cert != null && <DateDisplay value={row.cert.expiration} format={this.props.auth.user.dateFormat}/>}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow key="tableFooter">
                                <TablePagination count={this.state.totalCount}
                                rowsPerPage={this.state.listSize}
                                onPageChange={this.handleChangePage}
                                page = {this.state.page}
                                rowsPerPageOptions={common.rowOptions}
                                onRowsPerPageChange={this.handleRowsPerPage}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                {this.state.showCertification &&
                    <DocumentCertification
                        certification={this.state.certification != null ? this.state.certification : false}
                        user={this.props.id}
                        dialogOpen={this.state.showCertification}
                        document={this.state.documentSel}
                        toggleDialog={this.closeDialog}
                        refresh={this.closeCertification}
                    />
                }                
            </Aux>
        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(UserCertifications));
