import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { Link } from 'react-router-dom';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class Screens extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: [],
            offset: 0,
            listSize: 10,
            screen: '',
            screenSel: null,
            screens: [],
            role: '',
            roleSel: null,
            roles: [],
            department: '',
            depSel: null,
            departments: [],
            orderBy: 'screen',
            ordDir: 1,
            totalCount: 0,
            page: 0,
            security: []
        };
    }

    async componentDidMount(){
        const values = await axios.get('/api/screenvalues');
        this.setState({
            screens: values.data.screens,
            roles: values.data.roles,
            departments: values.data.roles,
            security: this.props.permission
        }, () => {
            const crumbs = [
                { path: 'active', label: this.props.t('screens'), screen: this.props.auth.screenDefs.Screens}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.loadRecords();
        });
    }

    loadRecords = async () => {
        const sort = {[this.state.orderBy]: this.state.ordDir};
        const data = {
            size: this.state.listSize,
            offset: this.state.offset,
            screen: this.state.screen,
            sort: JSON.stringify(sort)
        };
        const list = await axios.get('/api/screens', {params: data});
        this.setState({
            list: list.data.data,
            totalCount: list.data.totalCount
        });
    };

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            page: 0,
            offset: 0
        }, () => {
            this.loadRecords();
        });
    };

    sortClick(name) {
        const orderBy = this.state.orderBy;
        let ordDir = this.state.ordDir;
        if(orderBy === name){
            if(ordDir > 0)
                ordDir = -1;
            else
                ordDir = 1;
        }
        this.setState({orderBy: name, ordDir, offset: 0, page: 0}, () => {
            this.loadRecords();
        });
    }

    handleRowsPerPage = e => {
        this.setState({listSize: parseInt(e.target.value)}, () => {
            this.loadRecords();
        });
    };

    handleChangePage = (event, newPage) => {
        this.setState({page: newPage, offset: newPage * this.state.listSize}, () => {
            this.loadRecords();
        });
    };

    render(){
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    {this.props.auth.user.internal ?
                        <ActionBar
                            createLocation='/#/screen'
                            createAccess={this.props.auth.user.internal}
                            helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                            helpUser={this.props.auth.user.helpUser}
                            helpPassword={this.props.auth.user.helpPassword}
                        /> : 
                        <ActionBar
                            helpPath={this.props.auth.user.helpUrl + '/'+ security.helpId}
                            helpUser={this.props.auth.user.helpUser}
                            helpPassword={this.props.auth.user.helpPassword}
                        />
                    }
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.screenSel}
                                options={this.state.screens}
                                onChange={this.changeAuto('screenSel', 'screen')}
                                label={t('screen')}
                                data-cy='screen'
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table aria-label="Screens">
                            <TableHead>
                                <TableRow key="tableHeader">
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('screen')}
                                            active={this.state.orderBy === 'screen'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('screen')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('all')}
                                                active={this.state.orderBy === 'all'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('all')}
                                            </TableSortLabel>
                                        </TableCell>
                                    <TableCell>
                                        <TableSortLabel onClick={() => this.sortClick('internal')}
                                            active={this.state.orderBy === 'intenal'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('internal')}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sx={{width: '35%'}}>
                                        <TableSortLabel onClick={() => this.sortClick('helpId')}
                                            active={this.state.orderBy === 'helpId'} direction={this.state.ordDir > 0 ? 'asc' : 'desc'}>{t('helpId')}
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.list.map((row, i) =>
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <Link 
                                                to={'/screen/' + row._id} 
                                                data-cy={'row' + i}
                                            >
                                                {row?.screen?.name != null && row?.screen?.name !== '' ? row.screen.name : ''}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{row.all ? 'true' : 'false'}</TableCell>
                                        <TableCell>{row.internal ? 'true' : 'false'}</TableCell>
                                        <TableCell>{row.helpId ?? ''}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow key="tableFooter">
                                    <TablePagination count={this.state.totalCount}
                                    rowsPerPage={this.state.listSize}
                                    onPageChange={this.handleChangePage}
                                    page = {this.state.page}
                                    rowsPerPageOptions={common.rowOptions}
                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Panel>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(Screens));
