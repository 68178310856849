import React, { useEffect } from 'react';
import isEmpty from '../is-empty';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser, loginAzureUser } from '../actions/authentication';
import TextField from './UI/TextField/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Logo from './UI/AppBars/Logo/Logo';
import Copyright from './UI/Copyright/Copyright';
import Typography from '@mui/material/Typography';
import { SignInButton } from './UI/Buttons/Buttons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import Button from '@mui/material/Button';
import AutoCompleteField from './UI/AutoCompleteField/AutoCompleteField';
import { encryptString } from '../helpers/securityHelper';
import SectionTitle from './UI/Typography/SectionTitle';
import Aux from '../hoc/Auxilary/Auxilary';
import { TextOnlyClickButton } from './UI/Buttons/Buttons';
import { PublicClientApplication } from '@azure/msal-browser';
import { useTranslation } from 'react-i18next';
import common from '../jsons/common.json';
import i18next from 'i18next';
import { useLocation } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import Announcements from './General/Announcements';

export default function Login(props) {
    const auth = useSelector(state => state.auth);
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();
    const location = useLocation();
    const [ values, setValues ] = React.useState({
        env: null,
        serverVersion: null,
        userid: '',
        password: '',
        tenant: '',
        tenantSel: null,
        tenants: [],
        errors: {},
        showChangePassword: false,
        originalPassword: '',
        password1: '',
        password2: '',
        id: '',
        showReset: false,
        resetEmail: '',
        tenantData: {},
        authType: '',
        authAppClientId: '',
        authAuthority: '',
        redirectUri: '',
        graphURL: '',
        userRead: '',
        apiApp: '',
        dbSignin: false,
        scope: '',
        locale: '',
        enableLogin: false
    });
    const { t } = useTranslation('translation');

    useEffect(() => {
        if(auth.isAuthenticated) {
            let authenticatedPath = auth.referralLink ?? '/home';
            if(auth.user.firstLogin) authenticatedPath = '/userprofile';
            props.history.push(authenticatedPath);
        }else if(!auth.isAuthenticated) {
            if(alert.message !== '') dispatch({ type: 'CLEAR_ALERT' });
        }
        const fetchData = async () => {
            const envResult = await axios.get('/users/env');
            setValues({...values, env: envResult.data.name, serverVersion: envResult.data.version});
        };
        if(values.env === null) fetchData();
    }, [auth, alert, dispatch, props.history, values]);

    const handleInputChange = e => {
        const {name, value} = e.target;
        if(name === 'userid') {
            let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setValues({...values, [name]: value, enableLogin: (emailRegex.test(value) ? true : false)});
        }else {
            setValues({ ...values, [name]: value });
        }        
    };

    const changeAuto = (name, data) => (e, newValue) => {
        let dbSignin = values.dbSignin;
        let authType = values.authType;
        if(data === 'tenant'){
            dbSignin = false;
            authType = newValue != null ? newValue.authType : '';
        }
        setValues({
            ...values,
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null,
            dbSignin: dbSignin,
            authType: authType
        });
    };

    const handleUserid = async e => {
        let data = {
            userid: values.userid,
            tenant: values.tenant
        }

        let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if(emailRegex.test(values.userid)) {
            try {
                let result = await axios.post('/users/userauthinfo', data);
                await i18next.changeLanguage(result.data.locale);
                if(result.data.tenant != null){
                    let dbSignin = result.data.authType === common.auth.DB;
                    setValues({ ...values,
                        tenant: result.data.tenant,
                        authType: result.data.authType,
                        authAppClientId: result.data.authAppClientId,
                        authAuthority: result.data.authAuthority,
                        graphURL: result.data.graphURL,
                        userRead: result.data.userRead,
                        redirectUri: result.data.redirectUri,
                        apiApp: result.data.apiApp,
                        dbSignin: dbSignin,
                        scope: result.data.scope,
                        locale: result.data.locale
                    });
                    let newLocale = result.data.tenant + '_' + result.data.locale;
                    if(newLocale != null && newLocale !== '' && result.data.locale  != null && result.data.locale !== '')
                        await i18next.changeLanguage(newLocale);
                    if(result.data.authType === common.auth.azure){
                        const config = {
                            auth: {
                                clientId: result.data.authAppClientId,
                                redirectUri: result.data.redirectUri,
                                postLogout: result.data.redirectUri,
                            }
                        };
                        let loginRequest = {
                            scopes: [result.data.scope]
                        };
                        const myMsal = new PublicClientApplication(config);
                        await myMsal.initialize();
                        myMsal.loginPopup(loginRequest).then(function(loginResponse){
                            if(loginResponse.account.username.toLowerCase() === values.userid.toLowerCase()){
                                dispatch(loginAzureUser(myMsal, loginResponse.accessToken, values.userid, result.data.tenant, (location.state != null && location.state.referral != null ? location.state.referral : null), result.data.authType));
                            } else {
                                loginRequest.prompt = 'login';
                                const myMsal2 = new PublicClientApplication(config);
                                myMsal2.loginPopup(loginRequest).then(function(loginResponse2){
                                    if(loginResponse2.account.username.toLowerCase() === values.userid.toLowerCase()){
                                        dispatch(loginAzureUser(myMsal2, loginResponse2.accessToken, values.userid, result.data.tenant, (location.state != null && location.state.referral != null ? location.state.referral : null), result.data.authType));
                                    } else {
                                        setValues({ ...values, errors: {userid: t('invalidCredentials')}});
                                    }
                                }).catch(err => {
                                    console.error(err);
                                });
                            }
                        }).catch(err => {
                            console.error(err);
                        })
                    }
                } else if(result.data.tenants != null){
                    setValues({ ...values, tenants: result.data.tenants});
                }
            } catch(err){
                setValues({ ...values, errors: {userid: t('invalidCredentials')}});
            }
        }
    };

    const googleSuccess = (response) => {
        const decoded = jwtDecode(response.credential);
        if(decoded.email === values.userid.toLowerCase()){
            dispatch(loginAzureUser(response.credential, response.credential, values.userid, values.tenant, (location.state != null && location.state.referral != null ? location.state.referral : null), values.authType));
        } else {
            setValues({ ...values, errors: {userid: t('invalidCredentials')}});
        }
    }

    const googleError = (error) => {
        console.error(error);
        setValues({ ...values, errors: {userid: t('invalidCredentials')}});
    }
    const handleSubmit = async e => {
        if(e != null)
            e.preventDefault();
        let passwordHash = encryptString(values.password);
        let newLocale = values.tenant + '_' + values.locale
        if(newLocale != null && newLocale !== '' && values.locale != null && values.locale !== '')
            await i18next.changeLanguage(newLocale);
        const user = {
            userid: values.userid,
            password: passwordHash,
            tenant: values.tenant
        }
        try {
            let res = await axios.post('/users/login', user);
            if(res.data.changePassword){
                setValues({...values, showChangePassword: true, id: res.data.id});
            } else {
                dispatch(loginUser(res.data.screens, res.data.screenDefinitions, res.data.token, res.data.json, (location.state != null && location.state.referral != null ? location.state.referral : null)));
            }
        } catch (err){
            setValues({ ...values, errors: {password: t('invalidCredentials')}});
        }
    };

    const closeChangePassword = e => {
        setValues({...values, showChangePassword: false})
    };

    const changePassword = async (e) => {
        let errors = {};
        if(values.originalPassword == null || values.originalPassword === '')
            errors.originalPassword = t('required');
        if(values.password1 == null || values.password1 === '')
            errors.password1 = t('required');
        else {
            if(values.password1.length < 8 || values.password1.length > 15)
                errors.password1 = t('passwordLength');
        }
        if(values.password2 == null || values.password2 === '')
            errors.password2 = t('required');
        else {
            if(values.password1 !== values.password2)
                errors.password1 = t('passwordNotMatch');
            if(values.password1 === values.originalPassword)
                errors.password1 = t('passwordOrigError');
        }
        if(!isEmpty(errors)){
            setValues({...values, errors: errors});
            return;
        }
        let passwordHash = encryptString(values.originalPassword);
        let newPasswordHash = encryptString(values.password1);
        let data = {
            _id: values.id,
            password: passwordHash,
            newPassword: newPasswordHash
        }
        try {
            await axios.post('/api/user/changepassword', data);
            setValues({...values, showChangePassword: false});
        } catch (err){
            setValues({ ...values, errors: {password: err.response}});
        }
    };

    const closeReset = (e) => {
        setValues({...values, showReset: false});
    };

    const showReset = (e) => {
        setValues({...values, showReset: true});
    };

    const doReset = async (e) => {
        let errors = {};
        if(values.resetEmail == null || values.resetEmail === '')
            errors.resetEmail = t('required');
        if(!isEmpty(errors)){
            setValues({...values, errors: errors});
            return;
        }
        let data = {email: values.resetEmail};
        try {
            await axios.post('/users/resetpassword', data)
            setValues({...values, showReset: false});
        }catch (err){
            setValues({...values, errors: {resetEmail: err.response}})
        }
    }

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid item xs={false} sm={4} md={7} 
                  sx={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/Tablet4K.jpg)`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: 'grey[50]',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                  }} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box sx={{
                     margin: (8, 4),
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                }}>
                {values.env != null && values.env !== '' &&
                    <Box sx={{
                        color: '#FF0000',
                        display: 'inline-block',
                        fontFamily: 'Josefin Sans',
                        fontSize: '45px',
                        lineHeight: '30px',
                        paddingTop: 6
                    }}>{values.env}</Box>
                }
                <Logo height={"100%"} width={"100%"} noTenant/>
                    <TextField autoFocus required value={values.userid} onChange={handleInputChange} name="userid"
                    fullWidth={true} label={t('userid')} margin="normal" variant="outlined" error={values.errors.userid != null ? true : false}
                    helperText={values.errors.userid} autoComplete="userid" />
                    {values.tenants != null && values.tenants.length > 0 && values.enableLogin &&
                        <Aux>
                            <AutoCompleteField
                                fullWidth={true}
                                variant="outlined"
                                value={values.tenantSel}
                                options={values.tenants}
                                onChange={changeAuto('tenantSel', 'tenant')}
                                helperText={values.errors.tenant}
                                label={t('tenant')}
                                name='tenant'
                            />
                            <br/>
                        </Aux>
                    }
                    {values.tenant != null && values.tenant !== '' && values.authType === common.auth.DB &&
                        <form style={{
                            width: '100%', // Fix IE 11 issue.
                            marginTop: '8px',
                        }}
                        onSubmit={(values.tenant != null && values.tenant !== '' &&
                                   values.password != null && values.password !== '' &&
                                   values.authType === common.auth.DB) ? handleSubmit : handleUserid}>
                            <TextField required value={values.password} onChange={handleInputChange} name="password"
                            fullWidth={true} label={t('password')} margin="normal" variant="outlined" type="password" autoComplete="current-password"
                            helperText={values.errors.password} error={values.errors.password != null ? true : false}/>
                            <SignInButton onClick={values.password != null && values.password !== '' ? handleSubmit : null} enabled={values.password != null && values.password !== ''} />
                            <Box mt={3}>
                                <TextOnlyClickButton click={showReset} enabled={true} name={t('forgotPassword')}/>
                            </Box>
                        </form>
                    }
                    {(values.authType === '' || values.authType === common.auth.azure) &&
                        <Button fullWidth variant="contained" color="primary" onClick={handleUserid} name="continue" disabled={!values.enableLogin}>{t('continue')}</Button>
                    }
                    {values.authType === common.auth.google &&
                        <GoogleLogin onSuccess={googleSuccess} onError={googleError} />
                    }
                    <br/>
                    <br/>
                    <Announcements type={common.announcementTypes.login}/>
                    <Box mt={5}
                         justifyContent="center"
                         alignItems="center" >
                        <Copyright serverVersion={values.serverVersion}/>
                    </Box>
                </Box>
            </Grid>
            <Dialog open={values.showChangePassword} onClose={closeChangePassword} center maxWidth="md" fullWidth>
                <DialogTitle>
                    <SectionTitle title={t('changePassword')}/>
                </DialogTitle>
                <DialogContent>
                <Aux>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                        <TextField value={values.originalPassword} onChange={handleInputChange} name="originalPassword"
                        label={t('originalPassword')} margin="normal" type="password" autoComplete="original-password"
                        helperText={values.errors.originalPassword}/>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <TextField required value={values.password1} onChange={handleInputChange} name="password1"
                        label={t('newPassword')} margin="normal" type="password" error={values.errors.password1 != null ? true : false}
                        autoComplete="new-password" helperText={values.errors.password1}/>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <TextField required value={values.password2} onChange={handleInputChange} name="password2"
                        label={t('repeatPassword')} margin="normal" type="password" error={values.errors.password2 != null ? true : false}
                        autoComplete="repeat-password" helperText={values.errors.password2}/>
                    </Grid>
                </Grid>
                <br/>
                <br/>
                </Aux>
                </DialogContent>
                <DialogActions>
                <Button variant="contained" color="primary" size="small" onClick={changePassword}>{t('changePassword')}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={values.showReset} onClose={closeReset} center maxWidth="sm" fullWidth>
                <DialogTitle>
                <Typography variant="h4" component="h5" color="primary">
                    {t('resetPassword')}
                </Typography>
                </DialogTitle>
                <DialogContent>
                <Aux>
                    <Grid container spacing={3}>
                    <Grid item sm={10}>
                        <TextField required value={values.resetEmail} onChange={handleInputChange} name="resetEmail"
                        label={t('email')}margin="normal" error={values.errors.resetEmail != null ? true : false}
                        helperText={values.errors.resetEmail}/>
                    </Grid>
                    </Grid>
                    <br/>
                    <br/>
                </Aux>
                </DialogContent>
                <DialogActions>
                <Button variant="contained" color="primary" size="small" onClick={doReset}>{t('resetPassword')}</Button>
                <Button variant="contained" color="secondary" size="small" onClick={closeReset}>{t('cancel')}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
