import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import { useTranslation } from 'react-i18next';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import ActionBar from '../UI/Buttons/ActionBar';
import ConversionHelper from '../../helpers/conversionHelper';
import ClearIcon from '@mui/icons-material/FilterAltOffOutlined';

const CleaningSolutionRecipes = (props) => {
    const isFirstRender = useRef(true);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [density, setDensity] = useState('comfortable');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(true);
    const [sorting, setSorting] = useState([]);
    const [columnOrder, setColumnOrder] = useState(['number', 'description']);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const { t } = useTranslation('translation');
    const crumbs = [
        { path: 'active', label: t('cleaningSolutionRecipes'), screen: props.auth.screenDefs.CleaningSolutionRecipes}
    ]
    props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});

    useEffect(() => {
        const columnFilters = localStorage.getItem('CleaningSolutionRecipesColumnFilters');
        const columnVisibility = localStorage.getItem('CleaningSolutionRecipesColumnVisibility',);
        const density = localStorage.getItem('CleaningSolutionRecipesDensity');
        const globalFilter = localStorage.getItem('CleaningSolutionRecipesGlobalFilter');
        const showGlobalFilter = localStorage.getItem('CleaningSolutionRecipesShowGlobalFilter',);
        const showColumnFilters = localStorage.getItem('CleaningSolutionRecipesShowColumnFilters',);
        const sorting = localStorage.getItem('CleaningSolutionRecipesSorting');
        const pagination = localStorage.getItem('CleaningSolutionRecipesPagination');
        const columnOrder = localStorage.getItem('CleaningSolutionRecipesColumnOrder');
        if (columnFilters) {
            let filters = JSON.parse(columnFilters);
            filters = ConversionHelper.filtersDateParse(filters);
            setColumnFilters(filters);
        }
        if (columnVisibility) {
            setColumnVisibility(JSON.parse(columnVisibility));
        }
        if (density) {
            setDensity(JSON.parse(density));
        }
        if (globalFilter) {
            setGlobalFilter(JSON.parse(globalFilter) || undefined);
        }
        if (showGlobalFilter) {
            setShowGlobalFilter(JSON.parse(showGlobalFilter));
        }
        if (showColumnFilters) {
            setShowColumnFilters(JSON.parse(showColumnFilters));
        }
        if (sorting) {
            setSorting(JSON.parse(sorting));
        }
        if (pagination) {
            setPagination(JSON.parse(pagination));
        }
        if (columnOrder) {
            setColumnOrder(JSON.parse(columnOrder));
        }
        isFirstRender.current = false;
    }, []);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('CleaningSolutionRecipesColumnFilters', JSON.stringify(columnFilters),);
    }, [columnFilters]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('CleaningSolutionRecipesColumnVisibility', JSON.stringify(columnVisibility),);
    }, [columnVisibility]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('CleaningSolutionRecipesDensity', JSON.stringify(density));
    }, [density]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('CleaningSolutionRecipesGlobalFilter', JSON.stringify(globalFilter ?? ''),);
    }, [globalFilter]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('CleaningSolutionRecipesShowGlobalFilter', JSON.stringify(showGlobalFilter),);
    }, [showGlobalFilter]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('CleaningSolutionRecipesShowColumnFilters', JSON.stringify(showColumnFilters),);
    }, [showColumnFilters]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('CleaningSolutionRecipesSorting', JSON.stringify(sorting));
    }, [sorting]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('CleaningSolutionRecipesPagination', JSON.stringify(pagination));
    }, [pagination]);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('CleaningSolutionRecipesColumnOrder', JSON.stringify(columnOrder));
    }, [columnOrder]);

    const resetState = () => {
        localStorage.removeItem('CleaningSolutionRecipesColumnFilters');
        localStorage.removeItem('CleaningSolutionRecipesColumnVisibility');
        localStorage.removeItem('CleaningSolutionRecipesDensity');
        localStorage.removeItem('CleaningSolutionRecipesGlobalFilter');
        localStorage.removeItem('CleaningSolutionRecipesShowGlobalFilter');
        localStorage.removeItem('CleaningSolutionRecipesShowColumnFilters');
        localStorage.removeItem('CleaningSolutionRecipesSorting');
        localStorage.removeItem('CleaningSolutionRecipesPagination');
        localStorage.removeItem('CleaningSolutionRecipesColumnOrder');
        window.location.reload();
    };

    const {
        data: { data = [], totalCount = 0 } = {}, 
        isError,
        isRefetching,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [
            'CleaningSolutionRecipes',
            columnFilters, 
            globalFilter, 
            pagination.pageIndex, 
            pagination.pageSize, 
            sorting, 
        ],
        queryFn: async () => {
            let params = {
                start: pagination.pageIndex * pagination.pageSize,
                size: pagination.pageSize,
                filters: JSON.stringify(columnFilters ?? []),
                globalFilter: globalFilter ?? '',
                sorting: JSON.stringify(sorting ?? []),
            };
            const response = await axios.get('/api/cleaningrecipes', {params: params});
            const json = await response.data.data;
            return {data: json, totalCount: response.data.totalCount};
        },
        placeholderData: keepPreviousData, 
    });

    let columns = useMemo(() => [
        {
            accessorKey: 'number',
            id: 'number',
            header: t('number'),
            Cell: ({ cell, column }) => (
                <Box>
                    <Link to={'/cleaningsolutionrecipe/' + cell.row.original?._id}>{cell.getValue()}</Link>
                </Box>
            )
        },
        {
            accessorKey: 'description',
            id: 'description',
            header: t('description'),
        }
    ], [t]);

    const table = useMaterialReactTable({
        columns,
        data,
        initialState: { showColumnFilters: true,  columnVisibility: {id: false}},
        enableColumnOrdering: true,
        manualFiltering: true,
        enableRowSelection: false,
        enableGlobalFilter: false,
        manualPagination: true, 
        manualSorting: true, 
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: t('errorLoadingData')
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnOrderChange: setColumnOrder,
        renderTopToolbarCustomActions: () => (
            <Box>
                <Tooltip arrow title={t('refresh')}>
                    <IconButton onClick={() => refetch()} color="primary">
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('clearListSettings')}>
                <   IconButton onClick={() => resetState()} color="primary">
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                {props.permission.create &&
                    <Tooltip arrow title={t('add')}>
                        <IconButton component={Link} to={'/cleaningsolutionrecipe'} color="primary">
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        ),
        rowCount: totalCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
            columnOrder
        },
    });

    return(
        <Aux>
            <BreadcrumbBar crumbs={crumbs}>
                <ActionBar/>
            </BreadcrumbBar>
            <Panel>
                <MaterialReactTable table={table} />
            </Panel>
        </Aux>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(CleaningSolutionRecipes);