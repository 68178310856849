import React from 'react';
import dateFormat from 'date-fns/format';
import Aux from '../../../hoc/Auxilary/Auxilary';

export default function DateDisplay(props){
    const { value, format} = props;

  return (
      <Aux>
          {value != null && value !== '' ? dateFormat(Date.parse(value), format) : ''}
      </Aux>
  )
}
