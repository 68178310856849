import React, { Component } from 'react';
import axios from 'axios';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import GeneratePdf from '../General/GeneratePdf';
import { connect } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import { withTranslation } from 'react-i18next';

class ComponentToPrint extends Component {
    render(){
        const t = this.props.t;
        return(
            <div className="container">
                <br/>
                <br/>
                {this.props.data.instance != null && this.props.data.instance.sop != null &&
                    <h1>{t('workRecord')} {this.props.data.instance.sop.name}</h1>
                }
                <table>
                    <thead>
                        <tr>
                            <th width="100">{t('status')}</th>
                            <th width="120">{t('location')}</th>
                            {this.props.data.batchSelected != null &&
                                <th>{this.props.data.woSelected != null ? t('batch') : ''}</th>
                            }
                            {this.props.data.lotSelected != null &&
                                <th>{t('lot')}</th>
                            }
                            {this.props.data.poSelected != null &&
                                <th>{t('productionOrder')}</th>
                            }
                            {this.props.data.roomSelected != null &&
                                <th>{t('room')}</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.status != null ? this.props.data.status.label : ''}</td>
                            <td>{this.props.data.instance != null ? this.props.data.locationSelected.label : ''}</td>
                            {this.props.data.batchSelected != null &&
                                <td>{this.props.data.woSelected != null ? 
                                    this.props.data.batchSelected.label + '/' + this.props.data.woSelected.label :
                                     this.props.data.batchSelected.label}</td>
                            }
                            {this.props.data.roomSelected != null &&
                                <td>{this.props.data.roomSelected.label}</td>
                            }
                            {this.props.data.lotSelected != null &&
                                <td>{this.props.data.lotSelected.label}</td>
                            }
                            {this.props.data.poSelected != null &&
                                <td>{this.props.data.poSelected.label}</td>
                            }
                        </tr>
                    </tbody>
                </table>
                <br/>
                <table>
                    <thead>
                        <tr>
                            <th width="120">{t('users')}</th>
                            {this.props.data.cleaningLotSelected != null &&
                                <th width="100">{t('cleaningLot')}</th>
                            }
                            {this.props.data.pesticideLotSelected != null &&
                                <th width="100">{t('pesticideLot')}</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.userString != null ? this.props.data.userString : ''}</td>
                            {this.props.data.cleaningLotSelected != null &&
                                <td>{this.props.data.cleaningLotSelected.label}</td>
                            }
                            {this.props.data.pesticideLotSelected != null &&
                                <td>{this.props.data.pesticideLotSelected.label}</td>
                            }
                        </tr>
                    </tbody>
                </table>
                {this.props.data.template != null &&
                    <Form renderer={renderer}  defaultFields={this.props.data.template} disabled={true}/>
                }
                <table>
                    <thead>
                        <tr>
                            <th width="200">{t('user')}</th>
                            <th width="200">{t('reviewer')}</th>
                            <th>Signoff</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.props.data.instance != null ? this.props.data.instance.user.user.firstName + ' ' + 
                            this.props.data.instance.user.user.lastName : ''}</td>
                            <td>{this.props.data.instance != null ? this.props.data.instance.reviewer.user.firstName + ' ' + 
                            this.props.data.instance.reviewer.user.lastName : ''}</td>
                            <td>{this.props.data.instance != null ? this.props.data.instance.signer.user.firstName + ' ' + 
                            this.props.data.instance.signer.user.lastName : ''}</td>
                        </tr>
                        <tr>
                            <td>{this.props.data.instance != null && 
                                <DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.instance.user.date}/>}</td>
                            <td>{this.props.data.instance != null && 
                                <DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.instance.reviewer.date}/>}</td>
                            <td>{this.props.data.instance != null && 
                                <DateDisplay format={this.props.auth.user.dateFormat} value={this.props.data.instance.signer.date}/>}</td>
                        </tr>
                        <tr>
                            <td>{this.props.data.instance != null && 
                                <img src={this.props.data.instance.user.signature} alt={t('noSignature')}/>}</td>
                            <td>{this.props.data.instance != null && 
                                <img src={this.props.data.instance.reviewer.signature} alt={t('noSignature')}/>}</td>
                            <td>{this.props.data.instance != null && 
                                <img src={this.props.data.instance.signer.signature} alt={t('noSignature')}/>}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

class SopInstancePrint extends Component {
    constructor(props){
        super(props);
        this.state = {
            instance: null,
            values: null
        };
    }

    async componentDidMount(){
        var url = '/api/instances/' + this.props.match.params.instanceId;
        var result = await axios.get(url);
        const values = await axios.get('/api/instancevalues/' + result.data.sop._id);
        var template = JSON.parse(result.data.formValues);
        var users = [];
        var workOrders = [];
        if(result.data.batch != null && result.data.batch !== ''){
            workOrders = await axios.get('/api/workorders/' + result.data.batch);
        }
        if(result.data.users != null && result.data.users.length > 0){
            result.data.users.forEach(user => {
                users.push({'value': user._id, 'label': user.firstName + ' ' + user.lastName});
            });
        }
        var status = this.getStatusValues(result.data.status, values.data.statuses);
        var locationSelected = values.data.locations.find(x => x.value === result.data.location);
        var batchSelected = null;
        var woSelected = null;
        var lotSelected = null;
        var poSelected = null;
        var cleaningLotSelected  = null;
        var pesticideLotSelected = null;
        var roomSelected = null;
        if(result.data.batch != null && result.data.batch !== ''){
            batchSelected = values.data.batches.find(x => x.value === result.data.batch);
        }
        if(result.data.workOrder != null && result.data.workOrder !== ''){
            woSelected = workOrders.data.find(x => x.value === result.data.workOrder);
        }
        if(result.data.room != null && result.data.room !== ''){
            roomSelected = values.data.rooms.find(x => x.value === result.data.room);
        }
        if(result.data.lot != null && result.data.lot !== ''){
            lotSelected = values.data.lots.find(x => x.value === result.data.lot);
        }
        if(result.data.productionOrder != null && result.data.productionOrder !== ''){
            poSelected = values.data.productionOrders.find(x => x.value === result.data.productionOrder);
        }
        if(result.data.cleaningLot != null && result.data.cleaningLot !== ''){
            cleaningLotSelected = values.data.cleaningSolutions.find(x => x.value === result.data.cleaningLot);
        }
        if(result.data.pesticideLot != null && result.data.pesticideLot !== ''){
            pesticideLotSelected = values.data.pesticides.find(x => x.value === result.data.pesticideLot);
        }
        var userString = '';
        var firstTime = true;
        result.data.users.forEach(user => {
            if(firstTime){
                userString = user.firstName + ' ' + user.lastName;
                firstTime = false;
            } else {
                userString = userString = ', ' + user.firstname + ' ' + user.lastName;
            }
        });
        this.setState({
            template: template,
            instance: result.data, 
            values: values.data.values,
            status: status,
            locationSelected: locationSelected,
            batchSelected: batchSelected,
            woSelected: woSelected,
            lotSelected: lotSelected,
            poSelected: poSelected,
            cleaningLotSelected: cleaningLotSelected,
            pesticideLotSelected: pesticideLotSelected,
            roomSelected: roomSelected,
            userString: userString
        });
    }

    getStatusValues(status, statuses){
        for(var i = 0; i < statuses.length; i++){
            if(statuses[i].value === status){
                return statuses[i];
            }
        }
    }

    generateMarkup(){
        var htmlData = ReactDOMServer.renderToStaticMarkup(<ComponentToPrint data={this.state} auth={this.props.auth} t={this.props.t}/>);
        return htmlData;
    }

    render(){
        return(
            <div className="container">
                <ComponentToPrint data={this.state} auth={this.props.auth}/>
                <div className="row">
                    <GeneratePdf markup={() => this.generateMarkup()} id={this.props.match.params.instanceId} 
                    objectType={this.props.auth.constants.objectIds.sopInstance}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default withTranslation() (connect(mapStateToProps)(SopInstancePrint));