import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '../Link/Link';
import packageJson from '../../../../package.json';
import { useTranslation } from 'react-i18next';

export default function Copyright(props) {
    const { serverVersion } = props;
    const { t } = useTranslation();
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {t('copyright') + ' © '}
      <Link color="inherit" href="https://www.evoratechnologies.com/">
        {t('evoraTech')}
      </Link>{' '}
      {new Date().getFullYear()}
      {'. v'}
      {packageJson.version}
      {', v'}
      {serverVersion}  
    </Typography>
  );
}