import React from 'react';
import Aux from '../../hoc/Auxilary/Auxilary';
import QRCode from '../UI/QRCode/QRCode';
import DateDisplay from '../UI/DateDisplay/DateDisplay';

export default function EvoraBatchPrintMarkup(props){
    const { constants, data, print, t } = props;
    
    return(
        <Aux>
            <QRCode mono={print} size='sm' value={data.qrPath} />
            <div style={{float: 'right', display: 'inline-block', textAlign: 'right'}}>
                <span style={{ display: 'block', fontSize: '35pt', fontWeight: 'bold' }}>{t('certificateOfProduction')}</span>
                <span style={{ display: 'block', fontSize: '20pt', fontWeight: 'medium' }}><strong>{t('batch')}:</strong>
                    {data.customNumber != null && data.customNumber !== '' ? data.customNumber : data.number}
                </span>
                <span style={{ display: 'block', fontSize: '20pt', fontWeight: 'medium' }}><strong>{t('status')}:</strong> {data.status != null ? data.status.label : ''}</span>
            </div>
            <div style={{width: '100%', padding: '10px', border: '2px solid #cfd8dc'}}>
                <h2 style={{textAlign: 'left', float: 'left'}}><strong>{t('strain')}: </strong>{data.strain != null ? data.strain.name : ''}</h2>
                <h4 style={{textAlign: 'right', float: 'none'}}><strong>{t('productionSite')}: </strong>{data.location ? data.location.name : ''}</h4>
            </div>
            <br />
            {data.deviations != null && data.deviations.length > 0 &&
                <Aux>
                    <span style={{ color: '#757575', display: 'block', fontSize: '25pt', fontWeight: 'bold' }}>{t('deviations')}</span>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('recordNumber')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('occurred')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('title')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('type')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('category')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('impact')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('status')}</th>
                        </thead>
                        <tbody>
                            {data.deviations.length > 0 ?
                                data.deviations.map((deviation, i) =>
                                    <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                        <td>{deviation.number}</td>
                                        <td><DateDisplay format={data.dateFormat} value={deviation.occuranceDate}/></td>
                                        <td>{deviation.title}</td>
                                        <td>{deviation.type != null ? deviation.type.label : ''}</td>
                                        <td>{deviation.category != null ? deviation.category.label : ''}</td>
                                        <td>{deviation.impact ?? t('notKnown')}</td>
                                        <td>{deviation.status.label}</td>
                                    </tr>
                                ) : <tr style={{ border: '1px solid #ddd', padding: '2px' }} ><td colspan='7'><center>{t('noDeviations')}</center></td></tr>
                            }
                        </tbody>
                    </table>
                </Aux>
            }
            {data.ncrs != null && data.ncrs.length > 0 &&
                <Aux>
                    <hr style={{ width: '95%' }} />
                    <span style={{ color: '#757575', display: 'block', fontSize: '25pt', fontWeight: 'bold' }}>{t('nonCorformances')}</span>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('recordNumber')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('occurred')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('title')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('status')}</th>
                        </thead>
                        <tbody>
                            {data.ncrs.length > 0 ?
                                data.ncrs.map((ncr, i) =>
                                    <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                        <td>{ncr.number}</td>
                                        <td><DateDisplay format={data.dateFormat} value={ncr.occuranceDate}/></td>
                                        <td>{ncr.title}</td>
                                        <td>{ncr.status != null && ncr.status !== '' ? ncr.status.label : 'None'}</td>
                                    </tr>
                                ) : <tr style={{ border: '1px solid #ddd', padding: '2px' }} ><td colspan='4'><center>{t('noNcrs')}</center></td></tr>
                            }
                        </tbody>
                    </table>
                </Aux>
            }
            <hr style={{ width: '95%' }} />
            <span style={{ color: '#757575', display: 'block', fontSize: '25pt', fontWeight: 'bold' }}>{t('batchProductionHistory')}</span>
            {data.stages != null && data.stages.map(stage => (
                <fieldset style={{ border: '2px solid #ddd', padding: '0px 15px 15px 15px' }}>
                    <legend><strong>{t('stage')}: </strong>{stage.stage.label}</legend>
                    <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('startDate')}: </strong>{stage.activities[0] != null && stage.activities[0] !== '' && stage.activities[0].actualDate != null && stage.activities[0].actualDate !== '' ? <DateDisplay format={data.dateFormat} value={stage.activities[0].actualDate}/> : t('none')}</span><span style={{ display: 'inline-block', marginLeft: '40pt' }} />
                    <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('startingInventory')}: </strong>{stage.activities[0] != null && stage.activities[0] !== '' && stage.activities[0].activity != null && stage.activities[0].activity !== '' ? stage.activities[0].activity.quantityCompleted : 'None'}</span><span style={{ display: 'inline-block', marginLeft: '40pt' }} />
                    {stage.stage._id !== constants.batchStages.harvest &&
                        <span style={{ fontSize: '15pt', fontWeight: 'medium' }}>
                            <strong>{t('room_s')}: </strong>{stage.activities.map((activity, i) => (activity.activity !== undefined ?
                                                        activity.activity.rooms.map((room, i) => room.room.name + (activity.activity.rooms.length > 1 && i !== activity.activity.rooms.length - 1 ? ', ' : '')) : ''))}<br />
                        </span>
                    }
                    {stage.activities.map((activity, i) => (
                        activity.activity != null && activity.activity !== '' && activity.activity.status._id === constants.statuses.signed &&
                        <fieldset style={{ border: '2px solid #ddd', padding: '0px 15px 15px 15px' }}>
                            <legend><strong>{t('activity')}: </strong>{activity.activityDefinition.description}</legend>
                            <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('completed')} </strong><DateDisplay format={data.dateFormat} value={activity.activity.actualDate}/></span><span style={{ display: 'inline-block', marginLeft: '40pt' }} />
                            <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('plantsAffected')} </strong>{activity.activity.quantityCompleted}</span><span style={{ display: 'inline-block', marginLeft: '40pt' }} />
                            {activity.totalCulls > 0 && <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('totalCulls')} </strong>{activity.totalCulls}</span>}<br />
                            <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('completedBy')} </strong>
                                {activity.activity.user != null && activity.activity.user.user != null ? 
                                    activity.activity.user.user.firstName.charAt(0) + ' ' + activity.activity.user.user.lastName : ''}
                            </span><span style={{ display: 'inline-block', marginLeft: '40pt' }} 
                            />
                            <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('reviewedBy')} </strong>
                                {activity.activity.reviewer != null && activity.activity.reviewer.user != null ? 
                                    activity.activity.reviewer.user.firstName.charAt(0) + ' ' + activity.activity.reviewer.user.lastName : ''}
                            </span><span style={{ display: 'inline-block', marginLeft: '40pt' }} 
                            />
                            <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('approvedBy')} </strong>
                                {activity.activity.signer != null && activity.activity.signer.user != null ? 
                                    activity.activity.signer.user.firstName.charAt(0) + ' ' + activity.activity.signer.user.lastName  :''}
                            </span><br />
                            <hr style={{ width: '95%' }}/>
                            {activity.activity.inputs.length > 0 &&
                                <div style={{width: (activity.activity.outputs.length !== 0 ? '49%' : '100%'), display: 'inline-block'}}>
                                    <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('inputs')}</strong></span><br />
                                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                                        <thead>
                                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('description')}</th>
                                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('lot')}</th>
                                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('amount')}</th>
                                        </thead>
                                        <tbody>
                                            {activity.activity.inputs.map(input => (
                                                <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                                    <td>{input.item.name}</td>
                                                    <td>{input.lot != null && input.lot !== '' ? input.lot.number : t('none')}</td>
                                                    <td>{input.amount + ' ' + input.uom.label}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {activity.activity.outputs.length > 0 &&
                                <div style={{width: (activity.activity.inputs.length !== 0 ? '49%' : '100%'), display: 'inline-block', }}>
                                    <span style={{ fontSize: '15pt', fontWeight: 'medium' }}><strong>{t('outputs')}</strong></span><br />
                                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                                        <thead>
                                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('description')}</th>
                                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('lot')}</th>
                                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('amount')}</th>
                                        </thead>
                                        <tbody>
                                            {activity.activity.outputs.map(output => (
                                                <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                                    <td>{output.item.name}</td>
                                                    <td>{output.lot != null && output.lot !== '' ? output.lot.number : t('none')}</td>
                                                    <td>{output.amount + ' ' + output.uom.label}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </fieldset>
                    ))}
                </fieldset>
            ))}
            {data.cropTreatments != null && data.cropTreatments.length > 0 &&
                <Aux>
                    <hr style={{ width: '95%' }} />
                    <span style={{ color: '#757575', display: 'block', fontSize: '25pt', fontWeight: 'bold' }}>{t('cropTreatments')}</span>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('number')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('date')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('name')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('pesticideRegistrationNo')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('lot')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('applicationMethod')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('quantity')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('status')}</th>
                        </thead>
                        <tbody>
                            {data.cropTreatments.length > 0 ?
                                data.cropTreatments.map((ct, i) => (
                                    <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                        <td>{ct.number}</td>
                                        <td><DateDisplay format={data.dateFormat} value={ct.date}/></td>
                                        <td>{ct.nameString}</td>
                                        <td>{ct.regNoString != null && ct.regNoString !== undefined ? ct.regNoString : ''}</td>
                                        <td>{ct.lotString}</td>
                                        <td>{ct.applicationMethod != null ? ct.applicationMethod.label : ''}</td>
                                        <td>{ct.quantityString}</td>
                                        <td>{ct.status != null ? ct.status.label : ''}</td>
                                    </tr>
                                )) : <tr style={{ border: '1px solid #ddd', padding: '2px' }} ><td colspan='8'><center>{t('noCropTreatments')}</center></td></tr>
                            }
                        </tbody>
                    </table>
                </Aux>
            }
            {data.feedings != null && data.feedings.length > 0 &&
                <Aux>
                    <hr style={{ width: '95%' }} />
                    <span style={{ color: '#757575', display: 'block', fontSize: '25pt', fontWeight: 'bold' }}>{t('roomFeedings')}</span>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('number')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('date')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('type')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('room')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('status')}</th>
                        </thead>
                        <tbody>
                            {data.feedings.length > 0 ?
                                data.feedings.map((row, i) =>
                                    <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                        <td>{row.number}</td>
                                        <td><DateDisplay format={data.dateFormat} value={row.date}/></td>
                                        <td>{row.type}</td>
                                        <td>{row.room.name}</td>
                                        <td>{row.status}</td>
                                    </tr>
                                ) : <tr style={{ border: '1px solid #ddd', padding: '2px' }} ><td colspan='5'><center>{t('noCleaningRecords')}</center></td></tr>
                            }
                        </tbody>
                    </table>
                </Aux>
            }
            {data.roomCleaning != null && data.roomCleaning.length > 0 &&
                <Aux>
                    <hr style={{ width: '95%' }} />
                    <span style={{ color: '#757575', display: 'block', fontSize: '25pt', fontWeight: 'bold' }}>{t('cleaningRecords')}</span>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('number')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('completed')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('type')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('room')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('status')}</th>
                        </thead>
                        <tbody>
                            {data.roomCleaning.length > 0 ?
                                data.roomCleaning.map((cleaning, i) =>
                                    <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                        <td>{cleaning.number}</td>
                                        <td><DateDisplay format={data.dateFormat} value={cleaning.date}/></td>
                                        <td>{cleaning.type.label}</td>
                                        <td>{cleaning.room.name}</td>
                                        <td>{cleaning.status.label}</td>
                                    </tr>
                                ) : <tr style={{ border: '1px solid #ddd', padding: '2px' }} ><td colspan='5'><center>{t('noCleaningRecords')}</center></td></tr>
                            }
                        </tbody>
                    </table>
                </Aux>
            }
            {data.dryings != null && data.dryings.length > 0 &&
                <Aux>
                    <hr style={{ width: '95%' }} />
                    <span style={{ color: '#757575', display: 'block', fontSize: '25pt', fontWeight: 'bold' }}>{t('dryings')}</span>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('number')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('type')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('startAmount')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('output')}</th>
                        </thead>
                        <tbody>
                            {data.dryings.map((row, i) => 
                               <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                    <td>{row.number}</td>
                                    <td>{row.type.label}</td>
                                    <td>{row.startAmount}</td>
                                    <td>{row.actualOutput}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Aux>
            }
            {data.trimmings != null && data.trimmings.length > 0 &&
                <Aux>
                    <hr style={{ width: '95%' }} />
                    <span style={{ color: '#757575', display: 'block', fontSize: '25pt', fontWeight: 'bold' }}>{t('trimmings')}</span>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('number')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('type')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('startAmount')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('output')}</th>
                        </thead>
                        <tbody>
                            {data.trimmings.map((row, i) => 
                               <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                    <td>{row.number}</td>
                                    <td>{row.type.label}</td>
                                    <td>{row.startAmount}</td>
                                    <td>{row.actualOutput}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Aux>
            }
            {data.curings != null && data.curings.length > 0 &&
                <Aux>
                    <hr style={{ width: '95%' }} />
                    <span style={{ color: '#757575', display: 'block', fontSize: '25pt', fontWeight: 'bold' }}>{t('curings')}</span>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('number')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('startAmount')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('output')}</th>
                        </thead>
                        <tbody>
                            {data.curings.map((row, i) => 
                               <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                    <td>{row.number}</td>
                                    <td>{row.startAmount}</td>
                                    <td>{row.actualOutput}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Aux>
            }
            {data.externalTreatments != null && data.externalTreatments.length > 0 &&
                <Aux>
                    <hr style={{ width: '95%' }} />
                    <span style={{ color: '#757575', display: 'block', fontSize: '25pt', fontWeight: 'bold' }}>{t('externalTreatments')}</span>
                    <table style={{ fontFamily: 'Arial, Helvetica, sans-serif', borderCollapse: 'collapse', width: '100%' }} >
                        <thead>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('number')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('status')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('type')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('amount')}</th>
                            <th style={{ padding: '2px', textAlign: 'left', border: '1px solid rgb(187, 186, 186)' }} >{t('vendor')}</th>
                        </thead>
                        <tbody>
                            {data.externalTreatments.map((row, i) => 
                                <tr style={{ border: '1px solid #ddd', padding: '2px' }} >
                                    <td>{row.number}</td>
                                    <td>{row.status.label}</td>
                                    <td>{row.sterilizationType.label}</td>
                                    <td>{row.radiationAmount}</td>
                                    <td>{row.vendor != null ? row.vendor.name : ''}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Aux>
            }
            <hr style={{ width: '95%' }} />
        </Aux>
    )
}