import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import TextField from '../UI/TextField/TextField';
import DateTimePicker from '../UI/DateTimeSelect/DateTimeSelect'
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField'
import InputMaskField from '../UI/InputMaskField/InputMaskField';
import { SaveButton } from '../UI/Buttons/Buttons';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import { FormControl, FormLabel } from '@mui/material';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class Signin extends Component{
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            location: '',
            locations: [],
            signinDateTime: '',
            signoutDateTime: null,
            external: false,
            employeeId: '',
            firstName: '',
            lastName: '',
            visitor: '',
            visitors: [],
            signature: '',
            outSignature: '',
            signatureLock: false,
            outSignatureLock: false,
            locationSelected: null,
            visitorSel: null, 
            security: [],
            enableSave: true,
            form: '',
            hasForm: false,
            template: null,
            phoneNumber: '',
            company: '',
            email: '',
            employees: [],
            employeeVisited: '',
            empSel: null
        }

        this.sigpad = {};
        this.outSigPad = {};
        this.changeValue = this.changeValue.bind(this);
        this.inTimeChanged = this.inTimeChanged.bind(this);
        this.outTimeChanged = this.outTimeChanged.bind(this);
        this.externalChange = this.externalChange.bind(this);
        this.saveClick = this.saveClick.bind(this);
    }

    async componentDidMount(){
        let cacheValues = await ValueHelper.getCachedValues([common.cacheValues.location], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        const location = this.props.match.params.location != null ? this.props.match.params.location : this.props.auth.user.defaultLocation;
        const vals = await axios.get('/api/signinvalues');
        let locationSelected = location != null ? locations.find(x => x.value === location) : null;
        let hasForm = vals.data.form != null && vals.data.form !== '' ? true : false;
        this.setState({
            locations: locations,
            location: location,
            locationSelected: locationSelected,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && vals.data.form != null && vals.data.form !== '' ? JSON.parse(vals.data.form) : '',
            hasForm: hasForm,
            template: vals.data.form != null && vals.data.form !== '' ? JSON.parse(vals.data.form) : null,
            employees: vals.data.employees,
            security: this.props.permission,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : null
        }, () => {
            if(this.state._id != null){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/signins', label: this.props.t('signins'), screen: this.props.auth.screenDefs.Signin},
                    { path: 'active', label: this.props.t('signin'), screen: this.props.auth.screenDefs.Signin}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        })
    }

    loadRecord = async () => {
        const record = await axios.get('/api/signin/' + this.props.match.params.id)
        const visitors = await axios.get("/api/visitorsbylocation/" + record.data.location);
        const sigLock = record.data.signature != null && record.data.signature !== '' ? true : false;
        const outSigLock = record.data.outSignature != null && record.data.outSignature !== '' ? true : false;
        let locationSelected = this.state.locations.find(x => x.value === record.data.location);
        let empSel = record.data.employeeVisited != null ? this.state.employees.find(x => x.value === record.data.employeeVisited) : null;
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        let form = record.data.form != null && record.data.form !== '' ? JSON.parse(record.data.form) : this.state.template != null && this.state.template !== '' ? this.state.template : null;
        this.setState({
            _id: record.data._id,
            location: record.data.location,
            signinDateTime: record.data.signinDateTime,
            signoutDateTime: record.data.signoutDateTime,
            external: record.data.external,
            employeeId: record.data.employeeId,
            firstName: record.data.firstName,
            lastName: record.data.lastName,
            visitor: record.data.visitor,
            signature: record.data.signature,
            outSignature: record.data.outSignature,
            visitors: visitors.data,
            signatureLock: sigLock,
            outSignatureLock: outSigLock,
            locationSelected: locationSelected,
            form: form,
            hasForm: hasForm,
            phoneNumber: record.data.phoneNumber,
            company: record.data.company,
            email: record.data.email,
            employeeVisited: record.data.employeeVisited,
            empSel: empSel,
            number: record.data.number
        });
        const crumbs = [
            { path: '/#/signins', label: this.props.t('signins'), screen: this.props.auth.screenDefs.Signin},
            { path: 'active', label: this.props.t('signin') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.Signin}
        ]
        this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
    }

    changeValue(e){
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        this.processChange(data, newValue.value);
    };

    async processChange(name, value){
        if(name === 'location' && value !== ''){
            axios.get('/api/visitorsbylocation/' + value)
            .then(visitors => {
                this.setState({visitors: visitors.data});
            })
            .catch(err => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            })
        }
    }

    inTimeChanged(e){
        this.setState({signinDateTime: e});
    }

    outTimeChanged(e){
        this.setState({signoutDateTime: e});
    }

    externalChange(e){
        this.setState({external: !this.state.external});
    }

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick(e){
        const t = this.props.t;
        let valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let data = {
            _id: this.state._id,
            location: this.state.location,
            signinDateTime: this.state.signinDateTime,
            signoutDateTime: this.state.signoutDateTime,
            company: this.state.company,
            phoneNumber: this.state.phoneNumber,
            employeeId: this.state.employeeId,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            visitor: this.state.visitor,
            signature: this.state.signature,
            outSignature: this.state.outSignature,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            email: this.state.email,
            employeeVisited: this.state.employeeVisited
        }
        axios.post('/api/signin', data)
        .then(result => {
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/signin/' + result.data.id);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/signin/' + result.data.id);
            this.setState({_id: result.data.id, enableSave: true}, () => {
                this.loadRecord();
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        })
        .catch(err => {
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        })
    }

    validateForm(){
        const t = this.props.t;
        let errors = {};
        if(this.state.location == null || this.state.location === '')
            errors[t('location')] = t('required');
        if(this.state.signinDateTime == null || this.state.signinDateTime === '')
            errors[t('signinDateTime')] = t('required');
        if(this.state.firstName == null || this.state.firstName === '')
            errors[t('firstName')] = t('required');
        if(this.state.lastName == null || this.state.lastName === '')
            errors[t('lastName')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    clear = () => {
        this.sigpad.clear();
    }

    trim = () => {
        this.setState({
            signature: this.sigpad.getTrimmedCanvas().toDataURL('image/png'),
            signatureLock: true
        });
    }

    editSignature = () => {
        this.setState({signatureLock: false, signature: ''});
    }

    outClear = () => {
        this.outSigPad.clear();
    }

    outTrim = () => {
        this.setState({
            outSignature: this.outSigPad.getTrimmedCanvas().toDataURL('image/png'),
            outSignatureLock: true
        });
    }

    editOutSignature = () => {
        this.setState({outSignatureLock: false, outSignature: ''});
    }

    formChanged = (e) => {
        this.setState({value: e});
    };

    render(){
        const errors = this.props.errors;
        const sigLock = this.state.signatureLock;
        const outSigLock = this.state.outSignatureLock;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar />
                </BreadcrumbBar>
                <Panel>
                    <Grid container spacing={3}>
                        {this.state.number != null && this.state.number !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            <AutoCompleteField
                                value={this.state.locationSelected}
                                options={this.state.locations}
                                onChange={this.changeAuto('locationSelected', 'location')}
                                error={errors[t('location')] != null ? true : false}
                                helperText={errors[t('location')]}
                                label={t('location')}
                                required
                                disabled={sigLock}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateTimePicker
                                onChange={this.inTimeChanged}
                                value={this.state.signinDateTime}
                                helperText={errors[t('signinDateTime')]}
                                error={errors[t('signinDateTime')] != null ? true : false}
                                label={t('timeIn')}
                                required
                                disabled={sigLock}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.firstName} onChange={this.changeValue} name="firstName"
                            size="medium" fullWidth={true} label={t('firstName')} required disabled={sigLock}
                            error={errors[t('firstName')] != null ? true : false} helperText={errors[t('firstName')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.lastName} onChange={this.changeValue} name="lastName"
                            size="medium" fullWidth={true} label={t('lastName')} required disabled={sigLock}
                            error={errors[t('lastName')] != null ? true : false} helperText={errors[t('lastName')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.email} onChange={this.changeValue} name="email"
                            size="medium" fullWidth={true} label={t('email')} disabled={sigLock}
                            error={errors[t('email')] != null ? true : false} helperText={errors[t('email')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField value={this.state.employeeId} onChange={this.changeValue} name="employeeId"
                                size="medium" fullWidth="true" label={t('idBadge')} disabled={sigLock}
                                error={errors[t('employeeId')] != null ? true : false} helperText={errors[t('employeeId')]}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={this.state.company} onChange={this.changeValue} name="company"
                                size="medium" fullWidth="true" label={t('company')} disabled={sigLock}
                                error={errors[t('company')] != null ? true : false} helperText={errors[t('company')]}/>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <InputMaskField mask="999-999-9999" name="phoneNumber" value={this.state.phoneNumber}
                                onChange={this.changeValue} disabled={sigLock}
                                helperText={errors[t('phoneNumber')]}
                                error={errors[t('phoneNumber')] != null ? true : false}
                                label={t('phoneNumber')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <AutoCompleteField
                                value={this.state.empSel}
                                options={this.state.employees}
                                onChange={this.changeAuto('empSel', 'employeeVisited')}
                                error={errors[t('employeeVisited')] != null ? true : false}
                                helperText={errors[t('employeeVisited')]}
                                label={t('employee')}
                                disabled={sigLock}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <DateTimePicker
                                onChange={this.outTimeChanged}
                                value={this.state.signoutDateTime}
                                helperText={errors[t('signoutDateTime')]}
                                error={errors[t('signoutDateTime')] != null ? true : false}
                                label={t('timeOut')}
                                disabled={outSigLock}
                            />
                        </Grid>
                    </Grid>
                    {this.state.hasForm === true &&
                        <Aux>
                            <br/>
                            {errors[t('form')] != null && errors[t('form')] !== '' &&
                                <div style={{color: 'red'}}>{errors[t('form')]}</div>
                            }
                            <Form renderer={renderer}
                                defaultFields={this.state.form}
                                onChange={this.formChanged}
                            />
                        </Aux>
                    }
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <h4>{t('in') + ' ' + t('signature')}</h4>
                            <Grid container spacing={3}>
                                {!this.state.signatureLock &&
                                    <Aux>
                                        <Grid item xs={12} sm={6}>
                                            <SignatureCanvas penColor="#71cac9" canvasProps={{ width: 500, height: 200}}
                                            ref={(ref) => { this.sigpad = ref }}/>
                                        </Grid>
                                        <Grid item sm={8}>
                                            <Button variant="contained" size="small" color="primary" onClick={this.trim}>{t('save')}</Button>&nbsp;
                                            <Button variant="contained" size="small" color="secondary" onClick={this.clear}>{t('clear')}</Button>
                                        </Grid>
                                    </Aux>
                                }
                                {this.state.signatureLock &&
                                    <Aux>
                                        <Grid item sm={12}>
                                            <img src={this.state.signature} alt="no signature"/>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <Button variant="contained" color="secondary" size="small" onClick={this.editSignature}>{t('edit')}</Button>
                                        </Grid>
                                    </Aux>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h4>{t('out') + ' ' + t('signature')}</h4>
                            <Grid container spacing={3}>
                                {!this.state.outSignatureLock &&
                                    <Aux>
                                        <Grid item xs={12} sm={6}>
                                            <SignatureCanvas penColor="#71cac9" canvasProps={{ width: 500, height: 200}}
                                            ref={(ref) => { this.outSigPad = ref }}/>
                                        </Grid>
                                        <Grid item sm={8}>
                                            <Button variant="contained" size="small" color="primary" onClick={this.outTrim}>{t('save')}</Button>&nbsp;
                                            <Button variant="contained" size="small" color="secondary" onClick={this.outClear}>{t('clear')}</Button>
                                        </Grid>
                                    </Aux>
                                }
                                {this.state.outSignatureLock &&
                                    <Aux>
                                        <Grid item sm={12}>
                                            <img src={this.state.outSignature} alt="no signature"/>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <Button variant="contained" color="secondary" size="small" onClick={this.editOutSignature}>{t('edit')}</Button>
                                        </Grid>
                                    </Aux>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <br/>
                    {this.state._id != null && this.state._id !== '' &&
                        <Aux>
                            <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.signin}/>
                            <ObjectLog id={this.state._id}/>
                        </Aux>
                    }
                </Panel>
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <SaveButton saveClick={this.saveClick} enabled={this.state.enableSave} />
                    </Grid>
                </Grid>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Signin));
