import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
//import TaskRiskAssessment from '../Facility/TaskRiskAssessment';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import SectionTitle from '../UI/Typography/SectionTitle';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { approveSigSecurity, completeSigSecurity,
    fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { EvoraTabs, TabPanel, TabCanvas } from '../UI/Tabs/Tabs';
import ActivityWorkRecord from './ActivityWorkRecord';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import ActionBar from '../UI/Buttons/ActionBar';
import { withTranslation } from 'react-i18next';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import IconButton from '@mui/material/IconButton';
import { NumericFormat } from 'react-number-format';
import Box from '@mui/material/Box';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import ItemLedgerComponent from '../Inventory/ItemLedgerComponent';
import ConversionHelper from '../../helpers/conversionHelper';
import ValueHelper from '../../helpers/valueHelper';
import common from '../../jsons/common.json';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';

class FeedingLot extends Component {
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            _id: '',
            number: '',
            date: new Date(),
            location: '',
            locations: [],
            expirationDate: null,
            ingredients: [],
            approver: '',
            managers: [],
            approverSignature: '',
            quantity: '',
            quantityUOM : '',
            uoms: [],
            ingredient: '',
            ingredientSelect: [],
            amount: '',
            amountUOM: '',
            inputLot: '',
            lot: '',
            container: null,
            bin: '',
            binSel: null,
            bins: [],
            ingredientExpiration: null,
            editIngredient: false,
            internalLot: '',
            internalLotSel: null,
            lots: [],
            inputContainer: '',
            inputContainerSel: null,
            inputContainers: [], 
            signed: false,
            sigOpen: false,
            sops: [],
            ingredientId: '',
            ingredientIndex: -1,
            schedule: null,
            status: this.props.auth.constants.statuses.new,
            statuses: [],
            statusSel: {
                value: '',
                label: '',
            },
            user: null,
            users: [],
            userOptions: [],
            usersSel: [],
            reviewer: null,
            signer: null,
            taskRisk: false,
            taskRisks: [],
            taskRiskAssessment: '',
            scheduleBase: null,
            tenant: this.props.auth.user.tenant,
            documents: [],
            locationSelected: null,
            quantityUOMSelected: null,
            ingredientSelected: null,
            amountUOMSelected: null,
            fieldAccess: false,
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            security: [],
            item: '',
            itemSel: null,
            items: [],
            renderNum: Math.random(),
            sopList: [],
            sopIds: [],
            sopsSel: [],
            tabKey: 0,
            tabLabels: [{name: t('feedingLot'), disabled: false}],
            flagForSignature: 0,
            optionsMenu: [],
            dialogOpen: false,
            enableSave: true,
            customLot : '',
            sigData: null,
            activeUsers: [],
            activeManagers: [],
            activeQas: [],
            form: '',
            hasForm: false,
            effort: '',
            inputCost: '',
            labourCost: '',
            totalCost: '',
            unitCost: '',
            enableUnlock: false,
            roomFeedings: [],
            feedingRecipe: null,
            outputLabel: {},
            goBack: -1,
            deleteOpen: false,
            hasLedgers: false,
            feedingRecipesAll: []
        }
        this.sigPad = {};
        this.changeValue = this.changeValue.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.expirationChange = this.expirationChange.bind(this);
        this.ingredientExpChange = this.ingredientExpChange.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.user, common.cacheValues.cultivationWarehouseLocation, common.cacheValues.status,
                            common.cacheValues.quantityUOM, common.cacheValues.feedingLotSop, common.cacheValues.feedingLotForm, common.cacheValues.bin,
                            common.cacheValues.feedingLotItem, common.cacheValues.feedingLotIngredient, common.cacheValues.feedingRecipeAll],
                            this.props.auth.constants, null, this.props.auth.user.tenant);
        let allUsers = cacheValues.users;
        let locations = cacheValues.locations;
        const userValues = await ValueHelper.screenUsers(common.screens.feedingLot, this.props.auth.user.tenant);
        const params = queryString.parse(this.props.location.search);
        const scheduleId = params != null && params.schedule != null ? params.schedule : null;
        let schedule = null;
        let locationSel = null;
        let location = '';
        let goBack = -1;
        if(scheduleId != null){
            goBack = -2;
            schedule = await axios.get('/api/schedulebase/' + scheduleId);
            if(schedule.data.riskAssessmentRequired){
                this.getTaskRiskAssessments();
            }
            locationSel = locations.find(x => x.value === schedule.data.location);
            location = schedule.data.location;
        } else {
            if(this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== ''){
                location = this.props.auth.user.defaultLocation;
                locationSel = locations.find(x => x.value === location);
            } else {
                if(locations.length === 1){
                    locationSel = locations[0];
                    location = locationSel.value;
                }
            }
        }
        let workRecords = [];
        let sopIds = [];
        let sopsSel = [];
        cacheValues.sops.forEach(sop => {
            if(sop.required === true){
                workRecords.push({
                    sop: sop.value,
                    original: true
                });
                sopIds.push(sop.value);
                sopsSel.push(sop);
            }
        });
        const statusSel = cacheValues.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let activeUsers = [];
        userValues.create.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeUsers.push(row);
            }
        });
        let activeManagers = [];
        userValues.review.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeManagers.push(row);
            }
        });
        let activeQas = [];
        userValues.approve.forEach(row => {
            if(row.active && (!row.internal || (row.internal && this.props.auth.user.internal))){
                activeQas.push(row);
            }
        })
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        this.setState({
            locations: locations,
            location: location,
            managers: userValues.review,
            qas: userValues.approve,
            uoms: cacheValues.quantityUOMs,
            ingredientSelect: cacheValues.feedingLotIngredients,
            userOptions: userValues.create,
            schedule: scheduleId,
            statuses: cacheValues.statuses,
            scheduleBase: schedule != null ? schedule.data : null,
            locationSelected: locationSel,
            status: this.props.auth.constants.statuses.new,
            statusSel: statusSel,
            fieldAccess: fieldAccess,
            security: this.props.permission,
            sops: workRecords,
            sopList: cacheValues.sops,
            sopIds: sopIds,
            sopsSel: sopsSel,
            items: cacheValues.feedingLotItems,
            bins: cacheValues.bins,
            feedingRecipesAll: cacheValues.feedingRecipesAll,
            activeUsers: activeUsers,
            activeManagers: activeManagers,
            activeQas: activeQas,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            allUsers: allUsers,
            goBack: goBack
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/feedinglots', label: this.props.t('feedingLots'), screen: this.props.auth.screenDefs.FeedingLotList},
                    { path: 'active', label: this.props.t('feedingLot'), screen: this.props.auth.screenDefs.FeedingLot}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        const t = this.props.t;
        const internal = this.props.auth.user.internal;
        const record = await axios.get('/api/feedinglot/' + this.state._id);
        const signed = record.data.status === this.props.auth.constants.statuses.reviewed ||
            record.data.status === this.props.auth.constants.statuses.signed;
        const locationSelected = this.state.locations.find(x => x.value === record.data.location);
        const quantityUOMSelected = this.state.uoms.find(x => x.value === record.data.quantityUOM);
        let binSel = record.data.bin != null ? this.state.bins.find(x => x.value === record.data.bin) : null;
        if(record.data.schedule != null && record.data.schedule.riskAssessmentRequired){
            await this.getTaskRiskAssessments(record.data.taskRiskAssessment);
        }
        let usersSel = [];
        let userOptions = this.state.userOptions;
        if(record.data.users != null && record.data.users.length > 0){
            record.data.users.forEach(row => {
                let val = this.state.userOptions.find(x => x.value === row);
                if(val != null){
                    usersSel.push(val);
                } else {
                    val = this.state.allUsers.find(x => x.value === row);
                    if(val != null){
                        usersSel.push(val);
                        userOptions.push(val);
                    }
                }
            });
        }
        let ingredients = record.data.ingredients;
        ingredients.forEach(row => {
            let ingredientSel = this.state.ingredientSelect.find(x => x.value === row.ingredient);
            row.ingredientSel = ingredientSel;
            row.amountUOMSel = this.state.uoms.find(x => x.value === row.amountUOM);
            row.containerSel = row.container != null && row.containers != null ? row.containers.find(x => x.value === row.container) : null;
        });
        const statusSel = this.state.statuses.find(x => x.value === record.data.status);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, record.data.user, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, record.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, record.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        let itemSel = this.state.items.find(x => x.value === record.data.item);
        let sopIds = [];
        let sopsSel = [];
        let tabLabels = [{name: t('feedingLot'), disabled: false}];
        if(record.data.schedule != null && record.data.schedule.riskAssessmentRequired){
            tabLabels.push({name: t('riskAssessment'), disabled: false})
        }
        if(record.data.sops != null && record.data.sops.length > 0){
            record.data.sops.forEach(sop => {
                sopIds.push(sop.sop);
                let val = this.state.sopList.find(x => x.value === sop.sop);
                sopsSel.push(val);
                if(val != null)
                    tabLabels.push({name: val.label, disabled: false});
            });
        }
        if(record.data.hasLedgers){
            tabLabels.push({name: t('itemLedgers'), disabled: false});
        }
        let sigDeleteCallback = (record.data.status === this.props.auth.constants.statuses.new || record.data.status === this.props.auth.constants.statuses.inprogress) && 
                        record.data.user != null ? this.deleteSigCallback : null;
        const optionsMenu = [
            {
                security: completeSigSec, user: this.props.auth.user.id, users: usersSel, name: t('prepare'), object: record.data._id,
                title: t('preparedBy'), setId: () => this.refresh(), validate: () => this.validateComplete(), signature: record.data.user,
                objectType: this.props.auth.constants.objectIds.feedingLot, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, saveCallback: this.saveCallback, deleteCallback: sigDeleteCallback
            },
            {
                security: reviewSigSec, user: this.props.auth.user.id, users: !internal && this.state.reviewer == null ? this.state.activeManagers : this.state.managers,
                name: t('review'), object: record.data._id, title: t('reviewedBy'), setId: () => this.refresh(), validate: () => this.validateComplete(),
                signature: record.data.reviewer, objectType: this.props.auth.constants.objectIds.feedingLot, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: !internal && this.state.signer == null ? this.state.activeQas : this.state.qas,
                name: t('approve'), title: t('approvedBy'), setId: () => this.refresh(), object: record.data._id, signature: record.data.signer,
                objectType: this.props.auth.constants.objectIds.feedingLot, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = record.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 record.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 record.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        let params = queryString.parse(this.props.location.search);
        let outputLabel = {};
        if(record.data.container != null && record.data.lot != null && quantityUOMSelected != null && itemSel != null &&
            record.data.container._id != null && record.data.container.number != null && record.data.lot.number != null){
            outputLabel = {
                dateFormat: this.props.auth.user.dateFormat,
                id: record.data.container._id,
                container: record.data.container.number,
                lot: record.data.customLot != null ? record.data.customLot : 
                     record.data.lot != null && record.data.lot.number != null ? record.data.lot.number : '',
                item: itemSel != null && itemSel.label != null ? itemSel.label : '',
                quantity: ConversionHelper.round3(record.data.quantity),
                uom: quantityUOMSelected.label,
                category: record.data.container.type,
                itemNumber: itemSel != null && itemSel.number != null ? itemSel.number : '',
                date: record.data.date,
                qrPath: `${window.location.origin}/#/container/${record.data.container._id}`
            }
        }        
        this.setState({
            _id: record.data._id,
            date: record.data.date,
            number: record.data.number,
            location: record.data.location,
            ingredients: ingredients,
            quantity: record.data.quantity,
            quantityUOM: record.data.quantityUOM,
            status: record.data.status != null ? record.data.status : this.props.auth.constants.statuses.inprogress,
            expirationDate: record.data.expirationDate,
            signed: signed,
            user: record.data.user,
            users: record.data.users,
            usersSel: usersSel,
            reviewer: record.data.reviewer,
            signer: record.data.signer,
            locationSelected: locationSelected,
            quantityUOMSelected: quantityUOMSelected,
            taskRiskAssessment: record.data.taskRiskAssessment,
            scheduleBase: record.data.schedule != null ? record.data.schedule : null,
            taskRisk: record.data.schedule != null ? record.data.schedule.riskAssessmentRequired : false,
            documents: record.data.documents,
            statusSel: statusSel,
            fieldAccess: fieldAccess,
            completeSigSec: completeSigSec,
            reviewSigSec: reviewSigSec,
            approveSigSec: approveSigSec,
            item: record.data.item,
            itemSel: itemSel,
            sopIds: sopIds,
            sopsSel: sopsSel,
            sops: record.data.sops,
            renderNum: Math.random().toString(),
            lot: record.data.lot,
            tabLabels: tabLabels,
            bin: record.data.bin,
            binSel: binSel,
            container: record.data.container,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature,
            customLot: record.data.customLot,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            sigData: null,
            effort: record.data.effort,
            inputCost: record.data.inputCost,
            labourCost: record.data.labourCost,
            totalCost: record.data.totalCost,
            unitCost: record.data.unitCost,
            feedingRecipe: record.data.feedingRecipe,
            userOptions: userOptions,
            outputLabel: outputLabel,
            deleteOpen: false,
            hasLedgers: record.data.hasLedgers
        }, async () => {
            const crumbs = [
                { path: '/#/feedinglots', label: this.props.t('feedingLots'), screen: this.props.auth.screenDefs.FeedingLotList},
                { path: 'active', label: (this.props.t('feedingLot') + ' - ' + record.data.number), screen: this.props.auth.screenDefs.FeedingLot}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.determineUnlock();
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            if(params.tab != null)
                this.openNotification(params);
            let filters = [{id: 'feedingLot', value: this.state._id}];
            let data = {filters: JSON.stringify(filters)};
            let feedings = await axios.get('/api/roomfeedings', {params: data});
            this.setState({roomFeedings: feedings.data.data});
        });
    }

    determineUnlock() {
        let enableUnlock = false;
        let state = this.state;
        let constants = this.props.auth.constants;
        if(state.status === constants.statuses.complete && this.props.permission.unlock){
            enableUnlock = true;
        } else if (state.status === constants.statuses.reviewed || state.status === constants.statuses.signed){
            if(state.container == null){
                enableUnlock = true;
            } else {
                if(ConversionHelper.round3(parseFloat(state.container.quantity)) === ConversionHelper.round3(parseFloat(state.quantity))){
                    enableUnlock = true;
                }
            }
        }
        this.setState({enableUnlock: enableUnlock})
    }

    openNotification = (params) => {
        let tabKey = this.state.tabKey;
        if(params != null && params.tab != null)
            tabKey = Number(params.tab);
        this.setState({tabKey});
    }

    async getTaskRiskAssessments(selected){
        const data = {
            user: this.props.auth.user.id,
            objectType: this.props.auth.constants.objectIds.feedingLot
        };
        const riskAsses = await axios.get('/api/taskriskselectlist', {params: data});
        let taskRiskSel = null;
        if(selected != null && selected !== '')
            taskRiskSel = riskAsses.data.find(x => x.value === selected);
        this.setState({taskRisks: riskAsses.data, taskRiskAssessmentSel: taskRiskSel});
    }

    saveCallback = (sigData) => {
        this.setState({sigData: sigData}, () => {this.saveClick()})
    };

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const t = this.props.t;
        const valid = this.validateForm();
        let status = this.state.status;
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        if( status === this.props.auth.constants.statuses.new ){
            status = this.props.auth.constants.statuses.inprogress;
        }
        const data = {
            _id: this.state._id,
            date: this.state.date,
            ingredients: this.state.ingredients,
            location: this.state.location,
            expirationDate: this.state.expirationDate,
            quantity: this.state.quantity,
            quantityUOM: this.state.quantityUOM,
            schedule: this.state.schedule,
            status: status,
            taskRiskAssessment: this.state.taskRiskAssessment,
            item: this.state.item,
            sops: this.state.sops,
            bin: this.state.bin,
            users: this.state.users,
            customLot: this.state.customLot,
            sigData: this.state.sigData,
            effort: this.state.effort,
            feedingRecipe: this.state.feedingRecipe != null ? this.state.feedingRecipe._id : null,
            form: form != null && form !== '' ? JSON.stringify(form) : null
        }
        try {
            const result = await axios.post('/api/feedinglot', data);
            const statusSel = this.state.statuses.find(x => x.value === result.data.status);
            const completeSigSec = completeSigSecurity(this.props.permission.complete, result.data.user, result.data.status, this.props.auth.common, this.props.auth.constants);
            const reviewSigSec = reviewSigSecurity(this.props.permission.review, result.data.reviewer, result.data.status, this.props.auth.common, this.props.auth.constants);
            const approveSigSec = approveSigSecurity(this.props.permission.approve, result.data.signer, result.data.status, this.props.auth.common, this.props.auth.constants);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/feedinglot/' + result.data.id);
            this.setState({
                _id: result.data.id,
                number: result.data.number,
                status: result.data.status,
                statusSel: statusSel,
                completeSigSec: completeSigSec,
                reviewSigSec: reviewSigSec,
                approveSigSec: approveSigSec,
                enableSave: true,
                sigData: null
            }, () => {
                this.loadRecord();
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    validateForm(){
        const t = this.props.t;
        var errors = {};
        const state = this.state;
        if(state.date == null || state.date === ''){
            errors[t('date')]  = t('required');
        } else {
            let validEndDate = ConversionHelper.validActivityDate(this.state.date, this.props.auth.user.lockHistory ? this.props.auth.user.lockDate : null, this.props.auth.user.isSuper);
            if(!validEndDate){
                errors[t('date')]  = t('dateBeforeLock');
            }
        }
        if(state.location == null || state.location === '')
            errors[t('location')]  = t('required');
        if(state.expirationDate == null || state.expirationDate === '')
            errors[t('expirationDate')]  = t('required');
        if(state.ingredients == null || state.ingredients.length === 0)
            errors[t('ingredients')]  = t('required');
        if(state.item == null || state.item === '')
            errors[t('item')] = t('required');
        if(state.bin == null || state.bin === '')
            errors[t('bin')] = t('required');
        if(state.effort == null || state.effort === '' || state.effort === 0 || state.effort === '0')
            errors[t('effort')] = t('required');
        if(state.users == null || state.users.length === 0)
            errors[t('users')] = t('required');
        if(state.scheduleBase != null && state.scheduleBase.riskAssessmentRequired && (state.taskRiskAssessment == null ||
            state.taskRiskAssessment === ''))
            errors[t('taskRiskAssessment')] = t('required');
        if((state.ingredient != null && state.ingredient !== '') || (state.inputLot != null && state.inputLot !== '') ||
        (state.ingredientExpiration != null && state.ingredientExpiration !== '') || (state.amount != null && state.amount !== ''))
            errors[t('ingredient')] = t('rowNotAdded');
        for(let i = 0; i < state.ingredients.length; i++){
            let row = state.ingredients[i];
            if(row.ingredient == null || row.ingredient === '')
                errors[`${t('ingredient')} (${t('line')} ${i})`] = t('required');
            if(row.amount == null || row.amount === '')
                errors[`${t('amount')} (${t('line')} ${i})`] = t('required');
            if(row.ingredientSel != null && row.ingredientSel.lotTracked === true){
                if(row.container == null || row.container === '')
                    errors[`${t('container')} (${t('line')} ${i})`] = t('required');
                else {
                    if(row.amount != null && row.amount !== ''){
                        if((row.reservation == null || row.reservation === '') && parseFloat(row.amount) > parseFloat(row.containerSel.quantity)){
                            errors[`${t('amount')} (${t('line')} ${i})`] = t('moreThanContainer');
                        } else if (row.reservation != null && row.reservation !== '' && parseFloat(row.amount) > parseFloat(row.containerSel.quantity) + parseFloat(row.reservedAmount)){
                            errors[`${t('amount')} (${t('line')} ${i})`] = t('moreThanContainer');
                        }
                    }
                }
            } else if(row.ingredientSel != null && row.ingredientSel.lotTracked === false){
                if(row.amount != null && row.amount !== ''){
                    if(parseFloat(row.amount) > parseFloat(row.ingredientSel.inventory)){
                        errors[`${t('amount')} (${t('line')} ${i})`] = t('moreThanInventory');
                    }
                }
            }
        }
        if(state.itemSel != null && state.itemSel.lotTracked && state.itemSel.customNumber){
            if(state.customLot == null || state.customLot === '')
                errors[t('customLot')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    valueChanged = (e) => {
        this.setState({value: e});
    };

    changeValue(e){
        var name = e.target.name;
        var value = e.target.value
        if(name === "quantity" || name === "amount"){
            if(isNaN(value)){
                return;
            }
        }
        this.setState({[name]: value}, () => {
            if(this.state.feedingRecipe != null && this.state.quantity != null && this.state.quantity !== ''){
                this.calculateIngredientAmounts();
            }
        });
    }

    changeAuto = (name, data) => async (e, newValue) => {
        let {quantityUOMSelected, quantityUOM, amountUOM, amountUOMSelected, taskRisk, inputContainers, ingredients,
            feedingRecipe} = this.state;
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, async () => {
            if(data === 'taskRiskAssessment'){
                if (newValue != null && newValue.value != null && newValue.value !== "")
                    taskRisk = true;
                this.setState({
                    taskRisk: taskRisk,
                });
            } else if (data === 'item'){
                if(newValue != null && newValue.value != null && newValue.value !== ''){
                    let item = await axios.get('/api/item/' + newValue.value);
                    let feedingRecipeRes = await axios.get('/api/feedingrecipe/' + newValue.feedingRecipe);
                    feedingRecipe = feedingRecipeRes.data;
                    ingredients = [];
                    for(let row of feedingRecipe.lines){
                        let ingredientSel = this.state.ingredientSelect.find(x => x.value === row.item);
                        let uomSel = this.state.uoms.find(x => x.value === row.uom);
                        ingredients.push({
                            ingredient: row.item,
                            ingredientSel: ingredientSel,
                            containers: row.containers,
                            amountUOMSel: uomSel,
                            amountUOM: row.uom
                        })
                    }
                    quantityUOM = item.data.uom;
                    quantityUOMSelected = this.state.uoms.find(x => x.value === item.data.uom);
                }
                this.setState({
                    quantityUOM: quantityUOM,
                    quantityUOMSelected: quantityUOMSelected,
                    feedingRecipe: feedingRecipe,
                    ingredients: ingredients
                }, () => {
                    if(this.state.feedingRecipe != null && this.state.quantity != null && this.state.quantity !== ''){
                        this.calculateIngredientAmounts();
                    }
                });
            } else if (data === 'ingredient'){
                if(newValue != null && newValue.value != null && newValue.value !== ''){
                    let item = await axios.post('/api/itemselect/' + newValue.value, {date: this.state.date});
                    amountUOM = item.data.uom;
                    amountUOMSelected = this.state.uoms.find(x => x.value === item.data.uom);
                    inputContainers = item.data.containers;
                }
                this.setState({
                    amountUOM: amountUOM,
                    amountUOMSelected: amountUOMSelected,
                    inputContainer: '',
                    inputContainerSel: null,
                    inputContainers: inputContainers
                });
            } else if (data === 'location'){
                if(newValue != null){
                    let bins = await axios.get('/api/binselect', {params: {location: newValue.value}});
                    this.setState({
                        bins: bins.data,
                        bin: '',
                        binSel: null
                    });
                } else {
                    this.setState({
                        bins: [],
                        bin: '',
                        binSel: null
                    });
                }
            }
        });
    };

    calculateIngredientAmounts(){
        const feedingRecipe = this.state.feedingRecipe;
        let quantity = this.state.quantity;
        let uom = this.state.quantityUOM;
        let ingredients = this.state.ingredients;
        let conversionNumber = ConversionHelper.determineConversion(feedingRecipe.uom, uom, this.props.auth.constants.quantityUOMs);
        if(quantity != null  && quantity !== '' && feedingRecipe != null && !isNaN(quantity) && conversionNumber != null){
            let quantNumber = parseFloat(quantity);
            let multiplier = quantNumber * conversionNumber;
            for(let i = 0; i < feedingRecipe.lines.length; i++){
                let line = feedingRecipe.lines[i];
                let ingredient = ingredients.find(x => x.ingredient === line.item);
                if(ingredient != null){
                    if(line.quantity != null){
                        ingredient.expectedAmount = line.quantity * multiplier;
                    } else if(line.percent != null){
                        ingredient.expectedAmount = quantNumber * (line.percent / 100);
                    }
                }
            }
        }
        this.setState({ingredients: ingredients});
    }

    changeMulti = (name, data) => (e, newValue) => {
        let ids = [];
        if(newValue != null){
            newValue.forEach(row => {
                ids.push(row.value);
            });
        }
        this.setState({
            [name]: newValue,
            [data]: ids
        })
    };

    dateChange(e){
        this.setState({date: e});
    }

    expirationChange(e){
        this.setState({expirationDate: e});
    }

    ingredientExpChange(e){
        this.setState({ingredientExpiration: e});
    }

    saveIngredient = () => {
        const valid = this.validateIngredient();
        if(!valid) return;
        let ingredients = this.state.ingredients;
        let ingredient = {};
        ingredient.ingredient = this.state.ingredient;
        ingredient.ingredientSel = this.state.ingredientSelected;
        ingredient.expirationDate = this.state.ingredientExpiration;
        ingredient.amount = this.state.amount;
        ingredient.amountUOM = this.state.amountUOM;
        ingredient.amountUOMSel = this.state.amountUOMSelected
        if(this.state.inputContainerSel != null){
            ingredient.container = this.state.inputContainer;
            ingredient.containerSel = this.state.inputContainerSel;
            ingredient.containers = this.state.inputContainers;
        } else {
            ingredient.lot = this.state.inputLot;
        }
        ingredients.push(ingredient);
        this.setState({
            ingredients: ingredients
        });
        this.clearIngredient();
    }

    validateIngredient(){
        const t = this.props.t;
        let errors = {};
        let state = this.state;
        if(state.ingredient == null || state.ingredient === '')
            errors[t('ingredient')] = t('required');
        if(state.amount == null || state.amount === '')
            errors[t('amount')] = t('required');
        if(state.amountUOM == null || state.amountUOM === '')
            errors[t('amountUOM')] = t('required');
        if(state.ingredientSelected != null && state.ingredientSelected.lotTracked === true){
            if(state.inputContainer == null || state.inputContainer === '')
                errors[t('inputContainer')] = t('required');
            else {
                if(parseFloat(state.amount) > state.inputContainerSel.quantity){
                    errors[t('amount')] = t('moreThanContainer');
                }
            }
        } else if(state.ingredientSelected != null && state.amount != null && state.amount !== '' && state.ingredientSelected.lotTracked === false){
            if(parseInt(state.amount) > state.ingredientSelected.inventory)
                errors[t('amount')] = t('moreThanInventory');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    changeLineValue = (name, index) => (e) => {
        let ingredients = this.state.ingredients;
        let row = ingredients[index];
        row[name] = e.target.value;
        this.setState({ingredients: ingredients});
    };

    changeLineDate = (name, i) => (e) => {
        let rows = this.state.ingredients;
        let row = rows[i];
        row[name] = e;
        this.setState({ingredients: rows});
    };

    changeLineAuto = (name, data, index) => async (e, newValue) => {
        let ingredients = this.state.ingredients;
        let row = ingredients[index];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : null;
        if(data === 'item' || data === 'container'){
            row.reservation = null;
        }
        this.setState({ingredients: ingredients});
    };

    clearIngredient(){
        this.setState({
            ingredient: '',
            ingredientSelected: null,
            amount: '',
            amountUOM: '',
            amountUOMSelected: null,
            inputLot: '',
            ingredientExpiration: null,
            internalLot: '',
            lots: [],
            inputContainer: '',
            inputContainerSel: null,
            inputContainers: []
        });
    }

    deleteInput = (index) => (e) => {
        let inputs = this.state.ingredients;
        inputs.splice(index, 1);
        this.setState({ingredients: inputs});
    };

    refresh = e => {
        this.loadRecord();
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    validateComplete = () => {
        const t = this.props.t;
        let valid = this.validateForm();
        if(!valid)
            return t('notAllComplete');
        if(this.state.sops != null && this.state.sops.length > 0){
            for(let i = 0; i < this.state.sops.length; i++){
                let sop = this.state.sops[i];
                if(sop.workRecord == null || sop.workRecord === ''){
                    return t('notAllWorkRecords');
                }
            }
        }
        return null;
    };

    changeSop = (e, newValue) => {
        let sops = this.state.sops;
        let tabLabels = this.state.tabLabels;
        if(newValue != null){
            if(sops.length > newValue.length){
                for(let i = 0; i < sops.length; i++){
                    let sop = sops[i];
                    let rec = newValue.find(x => x.value === sop.sop);
                    if(!rec){
                        sops.splice(i, 1);
                        tabLabels.splice(i + 1, 1);
                        break;
                    }
                }
            } else {
                for(let i = 0; i < newValue.length; i++){
                    let val = newValue[i];
                    let rec = sops.find(x => x.sop === val.value)
                    if(!rec){
                        sops.push({
                            sop: val.value,
                            workRecord: null,
                            original: false
                        });
                        if(this.state._id != null && this.state._id !== '')
                            tabLabels.push({name: val.label, disabled: false});
                    }
                }
            }
            let sopIds = [];
            newValue.forEach(val => {
                sopIds.push(val.value);
            });
            this.setState({
                sopIds: sopIds,
                sopsSel: newValue,
                sops: sops
            });
        } else {
            for(let i = 0; i < sops.length; i++){
                let sop = sops[i];
                if(sop.required)
                    return;
            }
            this.setState({sops: [], sopIds: [], sopsSel: [], tabLabels: [{name: this.props.t('feedingLot'), disabled: false}]});
        }
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    delete = () => {
        if(this.state._id == null || this.state._id === '')
            return;
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => this._delete());
    }

    async _delete(){
        const t = this.props.t;
        if(this.state.user != null || this.state.reviewer != null || this.state.signer != null){
            this.setState({enableSave: true});
            return;
        }
        try {
            let result = await axios.delete('/api/feedinglot/' + this.state._id);
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            if(this.state.goBack === -1) {
                this.props.history.goBack();
            }else {
                this.props.history.go(this.state.goBack);
            }
        } catch(err){
            this.setState({enableSave: true, deleteOpen: false});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    deleteSigCallback = async (type) => {
        try{
            let result = await axios.post('/api/feedinglotsigdelete', {type: type, id: this.state._id});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
        }
    }

    render(){
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const security = this.state.security;
        const t = this.props.t;
        const defaultProps = {
            bgcolor: 'background.paper',
            m: 1,
            border: 1,
            style: {padding: '1rem'}
        };
        const internal = this.props.auth.user.internal;
        return(
            <Aux>
                <BreadcrumbBar goBack={this.state.goBack}>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        labelData={this.state.outputLabel}
                        labelAmount='single-actionBar'
                        objectType={this.props.auth.constants.objectIds.feedingLot}
                        id={this.state._id}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={[this.state.finalDocument]}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TabPanel value={this.state.tabKey} index={0}>
                            <Grid container spacing={2}>
                                {this.state._id != null && this.state._id !== '' &&
                                    <Grid item xs={6} sm={4} md={3}>
                                        <FormControl>
                                            <FormLabel>{t('number')}</FormLabel>
                                            {this.state.number}
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={6} sm={4} md={3}>
                                    <FormControl>
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                    </FormControl>
                                </Grid>
                                {this.state.scheduleBase != null && this.state.scheduleBase.riskAssessmentRequired &&
                                    <Grid item xs={12} sm={4}>
                                        {fieldAccess &&
                                            <AutoCompleteField
                                                value={this.state.taskRiskAssessmentSel}
                                                options={this.state.taskRisks}
                                                onChange={this.changeAuto('taskRiskAssessmentSel', 'taskRiskAssessment')}
                                                error={errors[t('taskRiskAssessment')] != null ? true : false}
                                                helperText={errors[t('taskRiskAssessment')]}
                                                label={t('riskAssessment')}
                                                disabled={!fieldAccess}
                                                required={this.state.scheduleBase != null && this.state.scheduleBase.riskAssessmentRequired &&
                                                         (this.state.taskRiskAssessment == null || this.state.taskRiskAssessment === '')}
                                            />
                                        }
                                        {!fieldAccess &&
                                            <FormControl>
                                                <FormLabel>{t('riskAssessment')}</FormLabel>
                                                {this.state.taskRiskAssessmentSel != null ? this.state.taskRiskAssessmentSel.label : ''}
                                            </FormControl>
                                        }
                                    </Grid>
                                }
                                <Grid item xs={6} sm={4} lg={3}>
                                    {fieldAccess &&
                                        <DateSelect
                                            onChange={this.dateChange}
                                            value={this.state.date}
                                            helperText={errors[t('date')] }
                                            error={errors[t('date')]  != null ? true : false}
                                            label={t('date')}
                                            disabled={!fieldAccess}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                            data-cy="date"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy="date">
                                            <FormLabel>{t('date')}</FormLabel>
                                            <DateDisplay value={this.state.date} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={4} lg={3}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.locationSelected}
                                            options={this.state.locations}
                                            onChange={this.changeAuto('locationSelected', 'location')}
                                            error={errors[t('location')]  != null ? true : false}
                                            helperText={errors[t('location')] }
                                            label={t('location')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="location"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy="location">
                                            <FormLabel>{t('location')}</FormLabel>
                                            {this.state.locationSelected != null ? this.state.locationSelected.label : ''}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={8} sm={5}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.itemSel}
                                            options={this.state.items}
                                            onChange={this.changeAuto('itemSel', 'item')}
                                            error={errors[t('item')] != null ? true : false}
                                            helperText={errors[t('item')]}
                                            label={t('outputItem')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="outputItem"
                                        />
                                    }
                                    {!fieldAccess && this.state.itemSel != null &&
                                        <FormControl data-cy="outputItem">
                                            <FormLabel>{t('outputItem')}</FormLabel>
                                            <Link to={'/item/' + this.state.itemSel.value}>{this.state.itemSel != null ? this.state.itemSel.label : ''}</Link>
                                        </FormControl>
                                    }
                                </Grid>
                                {this.state.feedingRecipe != null && this.state.feedingRecipe._id != null &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('feedingRecipe')}</FormLabel>
                                            <Link to={'/feedingrecipe/' + this.state.feedingRecipe._id}>{this.state.feedingRecipe.number}</Link>
                                        </FormControl>

                                    </Grid>
                                }
                                {this.state.itemSel != null && this.state.itemSel.lotTracked && this.state.itemSel.customNumber && fieldAccess &&
                                    <Grid item xss={4} sm={2}>
                                        <TextField type="text" value={this.state.customLot} onChange={this.changeValue} name="customLot"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('customLot')} data-cy="customLot"
                                        error={errors[t('customLot')] != null ? true : false} helperText={errors[t('customLot')]}/>
                                    </Grid>
                                }
                                {this.state.lot != null && this.state.lot !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('lot')}</FormLabel>
                                            <Link to={'/lot/' + this.state.lot._id}>{this.state.lot.number}</Link>
                                        </FormControl>
                                    </Grid>
                                }
                                {this.state.container != null && this.state.container !== '' &&
                                    <Grid item xs={4} sm={2}>
                                        <FormControl>
                                            <FormLabel>{t('container')}</FormLabel>
                                            <Link to={'/container/' + this.state.container._id}>{this.state.container.number}</Link>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={6} sm={4} lg={3}>
                                    {fieldAccess &&
                                        <DateSelect
                                            onChange={this.expirationChange}
                                            value={this.state.expirationDate}
                                            helperText={errors[t('expirationDate')] }
                                            error={errors[t('expirationDate')]  != null ? true : false}
                                            label={t('expiration')}
                                            disabled={!fieldAccess}
                                            format={this.props.auth.user.dateFormat}
                                            required
                                            data-cy="expirationDate"
                                        />
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy="expirationDate">
                                            <FormLabel>{t('expiration')}</FormLabel>
                                            <DateDisplay value={this.state.expirationDate} format={this.props.auth.user.dateFormat}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2}>
                                    {fieldAccess &&
                                        <TextField type="number" value={this.state.quantity} onChange={this.changeValue} name="quantity"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} data-cy="quantity"
                                        error={errors[t('quantity')] != null ? true : false} helperText={errors[t('quantity')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy="quantity">
                                            <FormLabel>{t('quantity')}</FormLabel>
                                            <NumericFormat displayType={'text'} value={this.state.quantity} thousandSeparator={true} decimalScale={2}/>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3} lg={2}>
                                    <FormControl>
                                        <FormLabel>{t('uom')}</FormLabel>
                                        {this.state.quantityUOMSelected != null ? this.state.quantityUOMSelected.label : ''}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={4} md={3} lg={2}>
                                    {fieldAccess &&
                                        <TextField type="number" value={this.state.effort} onChange={this.changeValue} name="effort"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('effort')} required data-cy="effort"
                                        error={errors[t('effort')] != null ? true : false} helperText={errors[t('effort')]}/>
                                    }
                                    {!fieldAccess &&
                                        <FormControl data-cy="effort">
                                            <FormLabel>{t('effort')}</FormLabel>
                                            {this.state.effort}
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    {fieldAccess &&
                                        <AutoCompleteField
                                            value={this.state.binSel}
                                            options={this.state.bins}
                                            onChange={this.changeAuto('binSel', 'bin')}
                                            error={errors[t('bin')] != null ? true : false}
                                            helperText={errors[t('bin')]}
                                            label={t('bin')}
                                            disabled={!fieldAccess}
                                            required
                                            data-cy="bin"
                                            groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                            noneMessage={t('noRoomSelected')}
                                        />
                                    }
                                    {!fieldAccess && this.state.binSel != null &&
                                        <FormControl data-cy="bin">
                                            <FormLabel>{t('bin')}</FormLabel>
                                            <Link to={'/bin/' + this.state.binSel.value}>{this.state.binSel != null ? this.state.binSel.label : ''}</Link>
                                        </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            {this.state.hasForm === true &&
                                <Aux>
                                    <br/>
                                    {errors[t('form')] != null && errors[t('form')] !== '' &&
                                        <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                    }
                                    <Form renderer={renderer}
                                        defaultFields={this.state.form}
                                        onChange={this.valueChanged}
                                        disabled={!fieldAccess}/>
                                </Aux>
                            }
                            <Grid container spacing={3}>
                                <Grid item xs={11}>
                                    <AutoCompleteField
                                        multiple={true}
                                        value={this.state.usersSel}
                                        options={fieldAccess && !internal ? this.state.activeUsers : this.state.userOptions}
                                        onChange={this.changeMulti('usersSel', 'users')}
                                        error={errors[t('users')] != null ? true : false}
                                        helperText={errors[t('users')]}
                                        label={t('users')}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy="users"
                                    />
                                </Grid>
                                {this.state.sopList != null && this.state.sopList.length > 0 &&
                                    <Grid item sm={11}>
                                        <AutoCompleteField
                                            multiple={true} disabled={!fieldAccess}
                                            value={this.state.sopsSel}
                                            options={this.state.sopList}
                                            onChange={this.changeSop}
                                            error={errors[t('sopIds')] != null ? true : false}
                                            helperText={errors[t('sopIds')]}
                                            label={t('workRecs')}
                                            data-cy="sops"
                                        />
                                    </Grid>
                                }
                            </Grid>
                            {this.state.status !== this.props.auth.constants.statuses.new && this.state.status !== this.props.auth.constants.statuses.inprogress &&
                                <Aux>
                                    <SectionTitle title={t('costs')}/>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('total')}</FormLabel>
                                                <NumericFormat displayType={'text'} value={this.state.totalCost} thousandSeparator={true} decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('inputs')}</FormLabel>
                                                <NumericFormat displayType={'text'} value={this.state.inputCost} thousandSeparator={true} decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('labour')}</FormLabel>
                                                <NumericFormat displayType={'text'} value={this.state.labourCost} thousandSeparator={true} decimalScale={2}/>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            <FormControl>
                                                <FormLabel>{t('unit')}</FormLabel>
                                                <NumericFormat displayType={'text'} value={this.state.unitCost} thousandSeparator={true} decimalScale={3}/>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Aux>
                            }
                            <br/>
                            <SectionTitle title={t('inputs')} />
                            {this.state.ingredients != null && this.state.ingredients.length > 0 && this.state.ingredients.map((row, i) =>
                                <Box borderColor="primary.main" {...defaultProps}  key={'item' + i} component={'div'}>
                                    <Grid container spacing={3} key={'item' + i}>
                                        <Grid item sx={{width: 350}}>
                                            <FormControl>
                                                <FormLabel>{t('item')}</FormLabel>
                                                {row.ingredientSel != null &&
                                                    <Link to={'/item/' + row.ingredient}>{row.ingredientSel.label}</Link>
                                                }
                                            </FormControl>
                                        </Grid>
                                        {!fieldAccess && row.internalLot != null &&
                                            <Grid item sx={{width: 200}}>
                                                <FormControl>
                                                    <FormLabel>{t('lot')}</FormLabel>
                                                    <Link to={'/lot/' + row.internalLot._id}>{row.internalLot.number}</Link>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {row.ingredientSel != null && row.ingredientSel.lotTracked > 0 &&
                                            <Grid item sx={{width: 250}}>
                                                {fieldAccess &&
                                                    <AutoCompleteField
                                                        value={row.containerSel}
                                                        options={row.containers}
                                                        onChange={this.changeLineAuto('containerSel', 'container', i)}
                                                        error={errors[`${t('container')} (${t('line')} ${i})`] != null ? true : false}
                                                        helperText={errors[`${t('container')} (${t('line')} ${i})`]}
                                                        label={t('container')}
                                                        disabled={!fieldAccess}
                                                        required
                                                        data-cy={'inputContainer' + i}
                                                        groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                        noneMessage={t('noLotAssigned')}
                                                    />
                                                }
                                                {!fieldAccess && row.containerSel != null &&
                                                    <FormControl data-cy={'inputContainer' + i}>
                                                        <FormLabel>{t('container')}</FormLabel>
                                                        <Link to={'/container/' + row.container}>{row.containerSel != null ? row.containerSel.label : ''}</Link>
                                                    </FormControl>
                                                }
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 130}}>
                                            {fieldAccess &&
                                                <DateSelect
                                                onChange={this.changeLineDate('expirationDate', i)}
                                                value={row.expirationDate}
                                                helperText={errors[`${t('expirationDate')} (${t('line')} ${i})`]}
                                                error={errors[`${t('expirationDate')} (${t('line')} ${i})`] != null ? true : false}
                                                disabled={!fieldAccess}
                                                label={t('expires')}
                                                format={this.props.auth.user.dateFormat}
                                                data-cy={'inputExpires' + i}
                                            />
                                            }
                                            {!fieldAccess &&
                                                <FormControl>
                                                    <FormLabel>{t('expires')}</FormLabel>
                                                    <DateDisplay value={row.expirationDate} format={this.props.auth.user.dateFormat}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        {row.expectedAmount != null && row.expectedAmount !== '' &&
                                            <Grid item sx={{width: 110}}>
                                                <FormControl>
                                                    <FormLabel>{t('estimate')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={row.expectedAmount} thousandSeparator={true} decimalScale={2}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 140}}>
                                            {fieldAccess &&
                                                <TextField type="number" value={row.amount} onChange={this.changeLineValue('amount', i)} name="amount"
                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} data-cy={'inputQuantity' + i}
                                                error={errors[`${t('amount')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('amount')} (${t('line')} ${i})`]}/>
                                            }
                                            {!fieldAccess &&
                                                <FormControl data-cy={'inputQuantity' + i}>
                                                    <FormLabel>{t('quantity')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={row.amount} thousandSeparator={true} decimalScale={3}/>
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item sx={{width: 50}}>
                                            <FormControl>
                                                <FormLabel>{t('uom')}</FormLabel>
                                                {row.amountUOMSel != null ? row.amountUOMSel.label : ''}
                                            </FormControl>
                                        </Grid>
                                        {fieldAccess &&
                                            <Grid item sx={{width: 50}}>
                                                <IconButton aria-label="delete item" disabled={!fieldAccess} onClick={this.deleteInput(i)} data-cy={'deleteInput' + i}>
                                                    <RemoveCircleTwoToneIcon  />
                                                </IconButton>
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            )}
                            {fieldAccess &&
                                <Box borderColor="primary.main" {...defaultProps} component={'div'}>
                                    <Grid container spacing={3}>
                                        <Grid item sx={{width: 350}}>
                                            <AutoCompleteField
                                                value={this.state.ingredientSelected}
                                                options={this.state.ingredientSelect}
                                                onChange={this.changeAuto('ingredientSelected', 'ingredient')}
                                                error={errors[t('ingredient')] != null ? true : false}
                                                helperText={errors[t('ingredient')]}
                                                label={t('item')}
                                                disabled={!fieldAccess}
                                                required
                                                data-cy='inputItem'
                                            />
                                        </Grid>
                                        {this.state.ingredientSelected != null && this.state.ingredientSelected.lotTracked === true &&
                                            <Grid item sx={{width: 250}}>
                                                <AutoCompleteField
                                                    value={this.state.inputContainerSel}
                                                    options={this.state.inputContainers}
                                                    onChange={this.changeAuto('inputContainerSel', 'inputContainer')}
                                                    error={errors[t('inputContainer')] != null ? true : false}
                                                    helperText={errors[t('inputContainer')]}
                                                    label={t('container')}
                                                    disabled={!fieldAccess}
                                                    required
                                                    data-cy='inputContainer'
                                                    groupBy={this.props.auth.user.groupContainer != null && this.props.auth.user.groupContainer === true ? 'lot' : null}
                                                    noneMessage={t('noLotAssigned')}
                                                />
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 130}}>
                                            <DateSelect
                                                onChange={this.ingredientExpChange}
                                                value={this.state.ingredientExpiration}
                                                helperText={errors[t('ingredientExpiration')]}
                                                error={errors[t('ingredientExpiration')] != null ? true : false}
                                                disabled={!fieldAccess}
                                                label={t('expires')}
                                                format={this.props.auth.user.dateFormat}
                                                data-cy='inputExpires'
                                            />
                                        </Grid>
                                        <Grid item sx={{width: 140}}>
                                            <TextField type="number" value={this.state.amount} onChange={this.changeValue} name="amount"
                                            size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} data-cy='inputQuantity'
                                            error={errors[t('amount')] != null ? true : false} helperText={errors[t('amount')]}/>
                                        </Grid>
                                        {this.state.amountUOMSelected != null &&
                                            <Grid item sx={{width:50}}>
                                                <FormControl>
                                                    <FormLabel>{t('uom')}</FormLabel>
                                                    {this.state.amountUOMSelected != null ? this.state.amountUOMSelected.label : ''}
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item sx={{width: 50}}>
                                            <IconButton aria-label="add" disabled={!fieldAccess} onClick={this.saveIngredient}  data-cy='inputAdd'>
                                                <AddBoxTwoToneIcon  />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                            {this.state.roomFeedings != null && this.state.roomFeedings.length > 0 &&
                                <Aux>
                                    <br/>
                                    <SectionTitle title={t('roomFeedings')}/>
                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow key="feedingHead">
                                                    <TableCell>{t('number')}</TableCell>
                                                    <TableCell>{t('room')}</TableCell>
                                                    <TableCell>{t('status')}</TableCell>
                                                    <TableCell>{t('type')}</TableCell>
                                                    <TableCell>{t('date')}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.roomFeedings.map((row, i) =>
                                                    <TableRow key={row._id}>
                                                        <TableCell>
                                                            <Link to={'/roomfeeding/' + row._id}>{row.number}</Link>
                                                        </TableCell>
                                                        <TableCell>{row.room}</TableCell>
                                                        <TableCell>{row.status}</TableCell>
                                                        <TableCell>{row.type}</TableCell>
                                                        <TableCell>
                                                            <DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Aux>
                            }
                            <FormButtonStack
                                id={this.state._id}
                                objectType={this.props.auth.constants.objectIds.feedingLot}
                                callback={this.refresh}
                                saveClick={() => this.saveClick()}
                                // printClick={'/#/feedinglotprint/' + this.state._id}
                                status={this.state.status}
                                permissions={this.props.permission}
                                common={this.props.auth.common}
                                constants={this.props.auth.constants}
                                saveEnabled={this.state.enableSave}
                                enableUnlockProp={this.state.enableUnlock}
                                deleteClick={this.deleteToggle}
                                activityDate={this.state.date}
                                lockDate={this.props.auth.user.lockHistory === true ? this.props.auth.user.lockDate : null}
                                isSuper={this.props.auth.user.isSuper}
                            />
                        </TabPanel>
                        {/*this.state.taskRisk &&
                            <TabPanel value={this.state.tabKey} index={1}>
                                <TaskRiskAssessment id={this.state.taskRiskAssessment} recordId={this.state._id}/>
                            </TabPanel>
                        */}
                        {this.state._id != null && this.state._id !== '' &&
                            <Aux>
                                {this.state.sopsSel != null && this.state.sopsSel.length > 0 && this.state.sopsSel.map((sop, i) =>
                                    <TabPanel value={this.state.tabKey} index={i + this.state.taskRisk ? 2 : 1} key={'sop' + i}>
                                        <ActivityWorkRecord users={this.state.users} sop={sop.value} id={this.state.sops[i].workRecord} date={this.state.date}
                                        objectType={this.props.auth.constants.objectIds.feedingLot} recordId={this.state._id} tab={this.state.tabKey}
                                        locationId={this.state.location} renderNum={this.state.renderNum} complete={this.loadRecord}/>
                                    </TabPanel>
                                )}
                            </Aux>
                        }
                        {this.state.hasLedgers &&
                            <TabCanvas value={this.state.tabKey} index={this.state.tabLabels.length - 1}>
                                <ItemLedgerComponent record={this.state._id} permission={this.props.permission} auth={this.props.auth} renderNum={this.state.renderNum}/>
                            </TabCanvas>
                        }
                    </Grid>
                </Grid>
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.delete()} open={this.state.deleteOpen} />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.feedingLot}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile.profile,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(FeedingLot));
