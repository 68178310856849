import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from '../../../hoc/Auxilary/Auxilary';
import axios from 'axios';
import GeneratePdf from '../../General/GeneratePdf';
import ReactDOMServer from 'react-dom/server';
import QRCode from '../../UI/QRCode/QRCode';
import Panel from '../../UI/Panel/Panel';
// import DateDisplay from '../../UI/DateDisplay/DateDisplay';
import { withTranslation } from 'react-i18next';
import BreadcrumbBar from '../../Navigation/Breadcrumbs/Breadcrumb';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/CheckTwoTone';
import LockedIcon from '@mui/icons-material/LockTwoTone';
import UnlockedIcon from '@mui/icons-material/LockOpenTwoTone';


function ComponentToPrint(props) {
    const { isTenant, records, qrPath, print, t } = props;
    return(
        <Aux>
            {/* //Generate a report with the following pages.
            //Use page breaks to break up each page at the end of the page.
            //Use inline css styling instead of classNames to style the report.
            //User material UI REact components to style the report.
            //Page 1. Evora User or Tenant Access Report title page
            //Page 2. Evora User or Tenant Access Report table of contents
            //Page 3. Evora User or Tenant Access Report introduction
            //Page 4. Evora User or Tenant Access Report body *repeat for each user within the array*
            //Page 5. Evora User or Tenant Access Report conclusion
            //Page 6. Evora User or Tenant Access Report appendix
            //Page 7. Evora User or Tenant Access Report glossary
            //Page 8. Evora User or Tenant Access Report references
            //Page 9. Evora User or Tenant Access Report index
            //Page 10. Evora User or Tenant Access Report end page */}

            {/* //Page 1. Evora User or Tenant Access Report title page */}
            <span style={{ fontFamily: 'Open Sans', padding: '20px', pageBreakAfter: 'always' }}>
                <span style={{ display: 'block', fontSize: '16pt', fontWeight: 'bold', 
                      textAlign: 'center', color: '#333', marginBottom: '20px', textDecoration: 'underline' }}>
                        Evora {isTenant ? t('tenant') : t('user')} {t('accessReport')}
                </span>
            </span>
            {/* //Page 2. Evora User or Tenant Access Report table of contents */}
            <span style={{ fontFamily: 'Open Sans', padding: '20px', pageBreakAfter: 'always' }}>
                <span style={{ display: 'block', fontSize: '16pt', fontWeight: 'bold', 
                      textAlign: 'center', color: '#333', marginBottom: '20px', textDecoration: 'underline' }}>
                        {t('tableOfContents')}
                </span>
                <span style={{ display: 'block', fontSize: '14pt', fontWeight: 'bold',
                      textAlign: 'center', color: '#333', marginBottom: '20px' }}>
                    <ol>
                        <li>Introduction</li>
                        {records != null && records.map((user, i) => {
                            return <li key={i + 2}>{user.firstName} {user.lastName}</li>
                        })}
                        {/* <li>Conclusion</li>
                        <li>Appendix</li>
                        <li>Glossary</li>
                        <li>References</li>
                        <li>Index</li>
                        <li>End Page</li> */}
                    </ol>
                </span>
            </span>
            {/* //Page 3. Evora User or Tenant Access Report introduction */}
            <div style={{pageBreakAfter: 'always'}}>
                <div style={{textAlign: 'center'}}>
                    <h1>Introduction</h1>
                </div>
            </div>
            {/* //Page 4. Evora User or Tenant Access Report body *repeat for each user within the array* */}
            {records != null && records.map((data, i) =>
                <Grid container spacing={2} sx={{ marginBottom: '10px'}}>
                    <Grid item container xs={5} spacing={2} alignItems='flex-start' >
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item sx={{ width: 175 }}>
                                    <QRCode mono={print} size='sm' value={'xxx' + qrPath + '/#/tenantuser/' + data._id} />
                                </Grid>
                                <Grid item xs={6}>
                                    <span style={{ fontSize: '12pt', fontWeight: 'medium' }}><strong>{t('user')}</strong></span><br />
                                    <span style={{ fontSize: '10pt', fontWeight: 'medium' }}><strong>{t('firstName')}:&nbsp;</strong>
                                        {data.firstName != null ? data.firstName : ''}
                                    </span><br />
                                    <span style={{ fontSize: '10pt', fontWeight: 'medium' }}><strong>{t('lastName')}:&nbsp;</strong>
                                        {data.lastName != null ? data.lastName : ''}
                                    </span><br />
                                    <span style={{ fontSize: '10pt', fontWeight: 'medium' }}><strong>{t('email')}:&nbsp;</strong>
                                        {data.email != null ? data.email : ''}
                                    </span><br />
                                    <span style={{ fontSize: '10pt', fontWeight: 'medium' }}><strong>{t('locked')}:&nbsp;</strong>
                                        {data.locked != null ? <UnlockedIcon sx={{ color: 'success.main' }} /> : <LockedIcon sx={{ color: 'error.main' }} />}
                                    </span><br />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <span style={{ fontSize: '12pt', fontWeight: 'medium' }}><strong>{t('accessControls')}</strong></span><br />
                                <span style={{ fontSize: '10pt', fontWeight: 'medium' }}><strong>{t('roles')}:&nbsp;</strong>
                                    {data.roles != null && data.roles.length > 0 ?
                                    data.roles.map((role, index) => {return (role.label + (index + 1 !== data.roles.length ? ', ' : '.'))}): t('none')}
                                </span><br />
                                <span style={{ fontSize: '10pt', fontWeight: 'medium' }}><strong>{t('departments')}:&nbsp;</strong>
                                    {data.departments != null && data.departments.length > 0 ?
                                    data.departments.map((department, index) => {return (department.label + (index + 1 !== data.departments.length ? ', ' : '.'))}): t('none')}
                                </span><br />
                                <span style={{ fontSize: '10pt', fontWeight: 'medium' }}><strong>{t('businessProcesses')}:&nbsp;</strong>
                                    {data.processes != null && data.processes.length > 0 ? 
                                    data.processes.map((process, index) => {return (process.label + (index + 1 !== data.processes.length ? ', ' : '.'))}): t('none')}
                                </span><br />
                            </Grid>
                        </Grid>
                    </Grid>                   
                    <Grid item xs={7}>
                        <div style={{ width: '80%'}}>
                            <span style={{ fontSize: '12pt', fontWeight: 'medium' }}><strong>{t('accessTable')}</strong></span><br />
                            <TableContainer sx={{ border: '2px solid rgba(224, 224, 224, 1)', borderRadius: 3}}>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('screen')}</TableCell>
                                            <TableCell>{t('read')}</TableCell>
                                            <TableCell>{t('create')}</TableCell>
                                            <TableCell>{t('delete')}</TableCell>
                                            <TableCell>{t('update')}</TableCell>
                                            <TableCell>{t('complete')}</TableCell>
                                            <TableCell>{t('review')}</TableCell>
                                            <TableCell>{t('signoff')}</TableCell>
                                            <TableCell>{t('print')}</TableCell>
                                            <TableCell>{t('unlock')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.screens != null && data.screens.length > 0 ? 
                                            data.screens.map((screen, index) => 
                                                <TableRow key={i + '-' + screen.label + index}>
                                                    <TableCell>{screen.label}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center'}}>{screen.read ? <CheckIcon sx={{ color: 'success.main' }} /> : ''}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center'}}>{screen.create ? <CheckIcon sx={{ color: 'success.main' }} /> : ''}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center'}}>{screen.delete ? <CheckIcon sx={{ color: 'success.main' }} /> : ''}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center'}}>{screen.update ? <CheckIcon sx={{ color: 'success.main' }} /> : ''}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center'}}>{screen.complete ? <CheckIcon sx={{ color: 'success.main' }} /> : ''}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center'}}>{screen.review ? <CheckIcon sx={{ color: 'success.main' }} /> : ''}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center'}}>{screen.approve ? <CheckIcon sx={{ color: 'success.main' }} /> : ''}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center'}}>{screen.print ? <CheckIcon sx={{ color: 'success.main' }} /> : ''}</TableCell>
                                                    <TableCell sx={{ textAlign: 'center'}}>{screen.unlock ? <CheckIcon sx={{ color: 'success.main' }} /> : ''}</TableCell>
                                                </TableRow>                                         
                                            ) : <TableRow>
                                                    <TableCell colSpan={10}>{t('noData')}</TableCell>
                                                </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                </Grid>
            )}
            
            {/* //Page 10. Evora User or Tenant Access Report end page
            <div style={{pageBreakAfter: 'always'}}>
                <div style={{textAlign: 'center'}}>
                    <h1>End Page</h1>
                </div>
            </div> */}
        </Aux>
    )
}

class UACReport extends Component {
    constructor(props){
        super(props);
        this.state = {
            records: [],
            isTenantReport: false,
            qrPath: ''
        };
    }

    async componentDidMount(){
        // const cacheValues = await ValueHelper.getCachedValues([
        //     common.cacheValues.productionBatchStatus
        // ], this.props.auth.constants);
        const path = window.location.hash.substring(1);
        const qrPath = window.location.origin;
        const isTenantReport = path.includes('tenant');
        let result = await axios.get('/api' + path);
        this.setState({
            records: result.data,
            isTenantReport: isTenantReport,
            qrPath: qrPath
        })        
    }

    generateMarkup = () => {
        var htmlData = ReactDOMServer.renderToStaticMarkup(
            <ComponentToPrint 
                records={this.state.records} 
                constants={this.props.auth.constants} 
                print 
                t={this.props.t} 
                dateFormat={this.props.auth.user.dateFormat} 
                qrPath={this.state.qrPath}
                isTenant={this.state.isTenantReport} 
            />);
        return htmlData;
    }

    render(){
        const t = this.props.t;
        return(
            <div style={{ width: '80%'}}>
                <BreadcrumbBar />
                <div className="row">
                    <GeneratePdf markup={this.generateMarkup} id={this.props.match.params.id} objectType={this.props.auth.constants.objectIds.productionBatch}/>
                </div>
                <br />
                <br />
                <Panel>
                    <ComponentToPrint 
                        records={this.state.records} 
                        constants={this.props.auth.constants} 
                        print
                        t={t} 
                        dateFormat={this.props.auth.user.dateFormat}
                        qrPath={this.state.qrPath}
                        isTenant={this.state.isTenantReport}
                    />
                </Panel>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default withTranslation() (connect(mapStateToProps)(UACReport));