import React from 'react';

export default function attestationTemplate(props) {
    const { name, t } = props;
    return (
        <div style={{ width: '8.5in', breakInside: 'avoid' }}>
            <div style={{ marginTop: '50%' }}>
                <span style={{ fontSize: '10pt', display: 'inline' }}>I, </span>
                <span style={{ fontSize: '10pt', display: 'inline', borderBottom: '1px solid #000', width: (name == null || name === '' ? '120px' : null) }}>{name}</span>
                <span style={{ fontSize: '10pt', display: 'inline' }}>, {t('batchProductionAttestation')}</span>
            </div>
        </div>
    )
}