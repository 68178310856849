import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import ToolTip from '@mui/material/Tooltip';
import { logoutUser } from '../../../../actions/authentication';
import packageJson from '../../../../../package.json';
import DeviceManager from '../../../Devices/DeviceManager';
import Aux from '../../../../hoc/Auxilary/Auxilary';
import Avatar from '../../Avatar/Avatar';
import { toggleDeviceDialog } from '../../../../actions/devices';
import WhatsNew from '../../../General/WhatsNew';

export default function Profile(props) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile);
    const deviceDialogOpen = useSelector(state => state.devices.deviceDialogOpen);
    const newDevices = useSelector(state => state.devices.new);
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [showWhatsNew, setShowWhatsNew] = useState(false);
    const [newDevicesPopup, setNewDevicesPopup] = useState(false);
    const [documentId, setDocumentId] = useState(null);
    const anchorRef = useRef(null);
    const { t } = useTranslation();
    const uatDoc = '69000847824';
    const prodDoc = '69000848659';

    const changePassword = (e) => {
        if (anchorRef.current && anchorRef.current.contains(e.target)) {
            return;
        }
        setOpen(false);
        history.push('/changepassword');
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        };    
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };
    
    const deviceClick = (e) => {
        if (anchorRef.current && anchorRef.current.contains(e.target)) {
            return;
        }
        setOpen(false);
        dispatch(toggleDeviceDialog());
    }

    const onLogout = (e) => {
        e.preventDefault();
        dispatch(logoutUser(history));
        history.push('/login');
    }

    const profileClick = (e) => {
        if (anchorRef.current && anchorRef.current.contains(e.target)) {
            return;
        }
        setOpen(false);
        history.push('/userprofile');
    };

    const toggleWhatsNew = () => {
        if(showWhatsNew === false){
            setDocumentId(props.nodeEnv === 'ogkush' ? prodDoc : uatDoc);
        } else {
            setDocumentId(null);
        
        }
        setShowWhatsNew(!showWhatsNew);
    }

    const toggleWhatsNewUAT = () => {
        setDocumentId(uatDoc);
        setShowWhatsNew(!showWhatsNew);
    }

    useEffect(() => {
        let timer;
        if (newDevices.length > 0) {
            setNewDevicesPopup(true);
            timer = setTimeout(() => {
                setNewDevicesPopup(false);
            }, 30000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [newDevices]);

    const button = (
        <Aux>
            <IconButton onClick={handleToggle} id="usericon" aria-haspopup="true" sx={{ color: '#FFF' }}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}>
                    <Avatar user={profile.profile} />
            </IconButton>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                sx={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        <MenuItem onClick={profileClick.bind(this)}>{t('profile')}</MenuItem>
                        <MenuItem onClick={deviceClick.bind(this)}>{t('devices')}</MenuItem>
                        {auth.user.authType === auth.common.auth.DB &&
                            <MenuItem onClick={changePassword.bind(this)}>{t('changePassword')}</MenuItem>
                        }
                        <MenuItem onClick={onLogout.bind(this)}>{t('logout')}</MenuItem>
                        <MenuItem onClick={toggleWhatsNew}>{t('version') + ' ' + packageJson.version + ', ' + auth.user.serverVersion}</MenuItem>
                        {props.nodeEnv === 'ogkush' &&
                            <MenuItem onClick={toggleWhatsNewUAT}>{t('whatsNewUAT')}</MenuItem>
                        }
                    </MenuList>
                    </ClickAwayListener>
                </Paper>
                </Grow>
            )}
            </Popper>
            { deviceDialogOpen && <DeviceManager /> }
            {newDevices != null && newDevices.length > 0 && (
                <ToolTip open={newDevicesPopup} title={t('newPrinterDetected')} placement="bottom">
                    <div />
                </ToolTip>
            )}
            <WhatsNew open={showWhatsNew} documentId={documentId} handleClose={toggleWhatsNew} />
        </Aux>
    );

    return button;
}