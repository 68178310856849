import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import ObjectLog from '../General/ObjectLog';
import Comments from '../General/Comments';
import ExternalTreatments from '../General/ExternalTreatments';
import Shipments from '../General/Shipments';
import EquipmentLogs from '../General/EquipmentLogs';
import TestResults from '../General/TestResults';
import Panel from '../UI/Panel/Panel';
import Aux from '../../hoc/Auxilary/Auxilary';
import TextField from '../UI/TextField/TextField';
import DateSelect from '../UI/DateSelect/DateSelect';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputMaskField from '../UI/InputMaskField/InputMaskField'
import ActionBar from '../UI/Buttons/ActionBar';
import SectionTitle from '../UI/Typography/SectionTitle';
import { withTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@mui/material';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConfirmDelete from '../General/ConfirmDelete';
import EditIcon from '@mui/icons-material/EditTwoTone';
import AddIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import { Link } from 'react-router-dom';
import Confirmation from '../UI/Dialog/Confirmation';

class Vendor extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            name: '',
            address1: '',
            address2: '',
            city : '',
            prov: '',
            provs: [],
            postal: '',
            country: '',
            countries: [],
            phoneNumber: '',
            contact: '',
            sites: [],
            editSite: false,
            siteName: '',
            siteAddr1: '',
            siteAddre2: '',
            siteCity: '',
            siteProv: '',
            sitePostal: '',
            siteCountry: '',
            sitePhoneNumber: '',
            siteContact: '',
            siteId: '',
            employees: [],
            empId: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneId: '',
            empPhoneNumber: '',
            phoneType: '',
            phoneTypes: [],
            jobTitle: '',
            editEmployee: false,
            employeeIndex: -1,
            phoneNumbers: [],
            editPhone: false,
            phoneIndex: -1,
            type: [],
            vendorTypes: [],
            locked: true,
            documents: [],
            certificates: [],
            certificationTypes: [],
            qualificationStatuses: [],
            qualificationStatus: '',
            qualificationDate: '',
            provSelected: null,
            qualStatSel: null,
            countrySelected: null,
            certsSel: [],
            phoneTypeSel: null,
            contactSel: null,
            contactOptions: [],
            siteProvSel: null,
            siteCountrySel: null,
            siteContactSel: null,
            number: '',
            security: [],
            licenseNumber: '',
            licenseType: '',
            licenseTypeSel: null,
            licenseTypes: [],
            licenseExpiration: '',
            renderNum: Math.random(),
            enableSave: true,
            form: '',
            template: '',
            hasForm: false,
            labLicense: '',
            isLab: false,
            deleteOpen: false,
            gstNumber: '',
            terms: '',
            termsSel: null,
            saleTerms: [],
            customer: null,
            confirmOpen: false,
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.vendorType, common.cacheValues.licenseType, common.cacheValues.phoneType,
            common.cacheValues.certificationType, common.cacheValues.qualificationStatus, common.cacheValues.vendorForm, common.cacheValues.saleTerms], 
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let locked = (this.props.match.params.id == null || this.props.match.params.id === '') && this.props.permission.create ? false : true;
        let countries = await ValueHelper.getCountries();
        let provs = await ValueHelper.getCanadianProvs();
        let hasForm = cacheValues.form != null && cacheValues.form !== '' ? true : false;
        this.setState({
            provs: provs,
            countries: countries,
            vendorTypes: cacheValues.vendorTypes,
            licenseTypes: cacheValues.licenseTypes,
            phoneTypes: cacheValues.phoneTypes,
            certificationTypes: cacheValues.certificationTypes,
            qualificationStatuses: cacheValues.qualificationStatuses,
            security: this.props.permission,
            locked: locked,
            form: (this.props.match.params.id == null || this.props.match.params.id === '') && cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            template: cacheValues.form != null && cacheValues.form !== '' ? JSON.parse(cacheValues.form) : '',
            hasForm: hasForm,
            saleTerms: cacheValues.saleTerms
        }, () => {
            if(this.props.match.params.id != null && this.props.match.params.id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/vendors', label: this.props.t('vendors'), screen: this.props.auth.screenDefs.VendorList},
                    { path: 'active', label: this.props.t('vendor'), screen: this.props.auth.screenDefs.Vendor}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/vendor/' + this.props.match.params.id);
        let types = [];
        let isLab = false;
        if (record.data.type != null && record.data.type.length > 0){
            record.data.type.forEach(type => {
                if(type._id === this.props.auth.constants.vendorTypes.lab){
                    isLab = true;
                }
                types.push({value: type._id, label: type.label});
            });
        }
        let provSelected = record.data.provs.find(x => x.value === record.data.address.prov);
        let countrySelected = this.state.countries.find(x => x.value === record.data.address.country);
        let termsSel = record.data.terms != null ? this.state.saleTerms.find(x => x.value === record.data.terms) : null;
        let certsSel = [];
        if(record.data.certificates != null && record.data.certificates.length > 0){
            record.data.certificates.forEach(row => {
                var cert = this.state.certificationTypes.find(x => x.value === row)
                certsSel.push(cert);
            });
        }
        let qualStatSel = this.state.qualificationStatuses.find(x => x.value === record.data.qualificationStatus);
        let contactOptions = [];
        if(record.data.employees != null && record.data.employees.length > 0 ){
            record.data.employees.forEach(row => {
                contactOptions.push({
                    value: row._id,
                    label: row.firstName + ' ' + row.lastName
                })
            });
        }
        let sites = record.data.sites;
        for(let row of sites){
            let provSel = record.data.provs.find(x => x.value === row.address.prov);
            row.provSel = provSel;
            let countrySel = this.state.countries.find(x => x.value === row.address.country);
            row.countrySel = countrySel;
        }
        let contactSel = contactOptions.find(x => x.value === record.data.contact);
        let licenseTypeSel = record.data.licenseType != null ? this.state.licenseTypes.find(x => x.value === record.data.licenseType) : null;
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        let employees = record.data.employees;
        for(let emp of employees){
            if(emp.phoneNumbers != null && emp.phoneNumbers.length > 0){
                for(let row of emp.phoneNumbers){
                    let typeSel = this.state.phoneTypes.find(x => x.value === row.type);
                    row.typeSel = typeSel;
                }
            }
        }
        this.setState({
            _id: record.data._id,
            name: record.data.name,
            address1: record.data.address.address1,
            address2: record.data.address.address2,
            city: record.data.address.city,
            prov: record.data.address.prov,
            postal: record.data.address.postal,
            country: record.data.address.country,
            phoneNumber: record.data.phoneNumber,
            contact: record.data.contact,
            sites: sites,
            employees: employees,
            type: types,
            provSelected: provSelected,
            countrySelected: countrySelected,
            documents: record.data.documents,
            certificates: record.data.certificates,
            qualificationStatus: record.data.qualificationStatus,
            qualificationDate: record.data.qualificationDate,
            certsSel: certsSel,
            qualStatSel: qualStatSel,
            contactOptions: contactOptions,
            contactSel: contactSel,
            number: record.data.number,
            licenseNumber: record.data.licenseNumber,
            licenseType: record.data.licenseType,
            licenseTypeSel: licenseTypeSel,
            licenseExpiration: record.data.licenseExpiration,
            provs: record.data.provs,
            renderNum: Math.random(),
            form: record.data.form != null ? JSON.parse(record.data.form) : this.state.template != null && this.state.template !== '' ? this.state.template : null,
            hasForm: hasForm,
            labLicense: record.data.labLicense,
            isLab: isLab,
            deleteOpen: false,
            gstNumber: record.data.gstNumber,
            terms: record.data.terms,
            termsSel: termsSel,
            customer: record.data.customer
        }, () => {
            const crumbs = [
                { path: '/#/vendors', label: this.props.t('vendors'), screen: this.props.auth.screenDefs.VendorList},
                { path: 'active', label: this.props.t('vendor') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.Vendor}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
        });
    };

    changeValue = (e) =>{
        this.setState({[e.target.name]: e.target.value});
    }

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
        if((data === "country" || data === 'siteCountry') && newValue != null){
            let provs = await axios.get('/api/provinceselect', {params: {country: newValue.value}});
            this.setState({provs: provs.data});
        }
    };

    typeChanged = (e, newValue) => {
        let isLab = false;
        if(newValue != null && newValue.length > 0){
            for(let row of newValue){
                if(row.value === this.props.auth.constants.vendorTypes.lab){
                    isLab = true;
                }
            }
        }
        this.setState({type: newValue, isLab: isLab});
    };

    changeDate = (name) => (e) => {
        this.setState({[name]: e});
    }

    changePhoneValue = (i) => (e) => {
        let phoneNumbers = this.state.phoneNumbers;
        let row = phoneNumbers[i];
        row[e.target.name] = e.target.value;
        this.setState({phoneNumbers: phoneNumbers});
    };

    changePhoneAuto = (name, data, i) => async (e, newValue) => {
        let phoneNumbers = this.state.phoneNumbers;
        let row = phoneNumbers[i];
        row[name] = newValue;
        row[data] = newValue != null ? newValue.value : null;
        this.setState({phoneNumbers: phoneNumbers});
    }

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false}, () => this._saveClick());
    }

    _saveClick = async () => {
        const t = this.props.t;
        const valid = this.validateForm();
        if(!valid) {
            this.setState({enableSave: true});
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let types = [];
        this.state.type.forEach(type => {
            types.push(type.value);
        });
        let certificates = [];
        if(this.state.certsSel != null && this.state.certsSel.length > 0){
            this.state.certsSel.forEach(cert => {
                certificates.push(cert.value);
            });
        }
        let data = {
            _id: this.state._id,
            name: this.state.name,
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            prov: this.state.prov,
            postal: this.state.postal,
            country: this.state.country,
            phoneNumber: this.state.phoneNumber,
            contact: this.state.contact,
            type: types,
            qualificationStatus: this.state.qualificationStatus,
            qualificationDate: this.state.qualificationDate,
            certificates: certificates,
            licenseNumber: this.state.licenseNumber,
            licenseType: this.state.licenseType,
            licenseExpiration: this.state.licenseExpiration,
            labLicense: this.state.labLicense,
            gstNumber: this.state.gstNumber,
            terms: this.state.terms,
            form: form != null && form !== '' ? JSON.stringify(form) : null
        };
        try {
            let result = await axios.post('/api/vendor', data);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/vendor/' + result.data.id);
                this.setState({_id: result.data.id, enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    deleteToggle = () =>{
        this.setState({deleteOpen: !this.state.deleteOpen});
    }

    deleteClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false}, () => this._deleteClick());
    };

    _deleteClick = async () => {
        if(this.state._id != null && this.state._id !== ''){
            try {
                let result = await axios.delete('/api/vendor/' + this.state._id);
                this.setState({ enableSave: true, deleteOpen: false});
                this.props.history.goBack();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
            } catch(err){
                this.setState({ enableSave: true, deleteOpen: false}, () => {
                    this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
                });
            }
        } else {
            this.props.history.goBack();
        }
    };

    validateForm(){
        const t = this.props.t;
        let errors = {};
        if(this.state.name == null || this.state.name === '')
            errors[t('name')] = t('required');
        if(this.state.type == null || this.state.type.length === 0)
            errors[t('type')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    unlock = () => {
        this.setState({locked: !this.state.locked});
    };

    toggleEmployee = () => {
        this.setState({editEmployee: !this.state.editEmployee});
    }

    editEmployee = (i) => () => {
        let employee = this.state.employees[i];
        this.setState({
            empId: employee._id,
            firstName: employee.firstName,
            lastName: employee.lastName,
            email: employee.email,
            jobTitle: employee.jobTitle,
            phoneNumbers: employee.phoneNumbers,
            editEmployee: true,
            employeeIndex: i
        });
    }

    async loadEmployees(){
        let result = await axios.get('/api/vendoremployees/' + this.state._id);
        let employees = result.data;
        for(let emp of employees){
            if(emp.phoneNumbers != null && emp.phoneNumbers.length > 0){
                for(let row of emp.phoneNumbers){
                    let typeSel = this.state.phoneTypes.find(x => x.value === row.type);
                    row.typeSel = typeSel;
                }
            }
        }
        this.setState({employees: employees});
        this.clearEmployee();
    }

    clearEmployee = () => {
        this.setState({
            editEmployee: false,
            employeeIndex: -1,
            empId: '',
            firstName: '',
            lastName: '',
            jobTitle: '',
            email: '',
            phoneNumbers: []
        });
    };

    saveEmployee = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false}, () => this._saveEmployee());
    }

    _saveEmployee = async () => {
        const t = this.props.t;
        let phones = [];
        this.state.phoneNumbers.forEach(phone => {
            phones.push({
                _id: phone._id,
                number: phone.number,
                type: phone.type
            });
        });
        let data = {
            _id: this.state.empId,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phoneNumbers: this.state.phoneNumbers,
            jobTitle: this.state.jobTitle
        };
        try {
            let result = await axios.post('/api/vendoremployee/' + this.state._id, data);
            this.setState({enableSave: true}, () => {
                this.loadEmployees();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    addPhone = () => {
        const t = this.props.t;
        let phoneNumbers = this.state.phoneNumbers;
        let errors = {};
        if(this.state.phoneNumber == null || this.state.phoneNumber === '')
            errors[t('phoneNumber')] = this.props.t('required');
        if(this.state.phoneType == null || this.state.phoneType === '')
            errors[t('phoneType')] = this.props.t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        phoneNumbers.push({
            number: this.state.empPhoneNumber,
            type: this.state.phoneType,
            typeSel: this.state.phoneTypeSel
        });
        this.setState({phoneNumbers: phoneNumbers, empPhoneNumber: '', phoneType: '', phoneTypeSel: null, errors: {}});
    }

    deletePhone = (i) => () => {
        let phoneNumbers = this.state.phoneNumbers;
        phoneNumbers.splice(i, 1);
        this.setState({phoneNumbers: phoneNumbers});
    }

    deleteEmployee = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false}, () => this._deleteEmployee());
    }

    _deleteEmployee = async () => {
        const t = this.props.t;
        if(this.state.empId == null || this.state.empId === ''){
            this.setState({ enableSave: true});
            return;
        }
        try {
            let result = await axios.delete('/api/vendoremployee/' + this.state._id + '/' + this.state.empId);
            this.setState({ enableSave: true});
            this.loadEmployees();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    toggleConfirmation = () => {
        this.setState({ confirmOpen: !this.state.confirmOpen });
    }

    createCustomer = () => {
        if(!this.state.enableSave || this.state.customer != null)
            return;
        this.setState({ enableSave: false}, () => this._createCustomer());
    }

    _createCustomer = async () => {
        const t = this.props.t;
        try {
            let result = await axios.post('/api/vendorcustomer', {id: this.state._id});
            this.setState({enableSave: true, confirmOpen: false});
            this.loadRecord();
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true});
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }
    }

    render(){
        const errors = this.props.errors;
        const locked = this.state.locked;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar 
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.vendor}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <Panel>
                <Grid container spacing={3}>
                        {this.state.number != null && this.state.number !== '' &&
                            <Grid item xs={6} sm={3}>
                                <FormControl data-cy="number">
                                    <FormLabel>{t('number')}</FormLabel>
                                    {this.state.number}
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={8} sm={4}>
                            {!locked &&
                                <TextField value={this.state.name} onChange={this.changeValue} name="name"
                                size="medium" fullWidth={true} disabled={locked} label={t('name')} required
                                error={errors[t('name')] != null ? true : false} helperText={errors[t('name')]}
                                data-cy="name"/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('name')}</FormLabel>
                                    {this.state.name}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item sm={4}>
                            <AutoCompleteField
                                multiple={true}
                                value={this.state.type}
                                options={this.state.vendorTypes}
                                onChange={this.typeChanged}
                                error={errors[t('type')] != null ? true : false}
                                helperText={errors[t('type')]}
                                label={t('type')}
                                disabled={locked}
                                required
                                data-cy="type"
                            />
                        </Grid>
                        {this.state.isLab &&
                            <Grid item xs={4} sm={2}>
                                {!locked &&
                                    <TextField value={this.state.labLicense} onChange={this.changeValue} name="labLicense"
                                    size="medium" fullWidth={true} disabled={locked} label={t('labLicense')} data-cy="labLicense"
                                    error={errors[t('labLicense')] != null ? true : false} helperText={errors[t('labLicense')]}/>
                                }
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('labLicense')}</FormLabel>
                                        {this.state.labLicense}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        <Grid item xs={2} sm={1}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.countrySelected}
                                    options={this.state.countries}
                                    onChange={this.changeAuto('countrySelected', 'country')}
                                    error={errors[t('country')] != null ? true : false}
                                    helperText={errors[t('country')]}
                                    label={t('country')}
                                    disabled={locked}
                                    data-cy="country"
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('country')}</FormLabel>
                                    {this.state.countrySelected != null ? this.state.countrySelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {!locked &&
                                <TextField value={this.state.address1} onChange={this.changeValue} name="address1"
                                size="medium" fullWidth={true} disabled={locked} label={t('street')} data-cy="address1"
                                error={errors[t('address1')] != null ? true : false} helperText={errors[t('address1')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('street')}</FormLabel>
                                    {this.state.address1}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            {!locked &&
                                <TextField value={this.state.address2} onChange={this.changeValue} name="address2"
                                size="medium" fullWidth={true} disabled={locked} label={t('street2')} data-cy="address2"
                                error={errors[t('address2')] != null ? true : false} helperText={errors[t('address2')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('street2')}</FormLabel>
                                    {this.state.address2}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <TextField value={this.state.city} onChange={this.changeValue} name="city"
                                size="medium" fullWidth={true} disabled={locked} label={t('city')} data-cy="city"
                                error={errors[t('city')] != null ? true : false} helperText={errors[t('city')]}/>
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('city')}</FormLabel>
                                    {this.state.city}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={2} sm={1}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.provSelected}
                                    options={this.state.provs}
                                    onChange={this.changeAuto('provSelected', 'prov')}
                                    error={errors[t('prov')] != null ? true : false}
                                    helperText={errors[t('prov')]}
                                    label={t('province')}
                                    disabled={locked}
                                    data-cy="prov"
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('province')}</FormLabel>
                                    {this.state.provSelected != null ? this.state.provSelected.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        {(this.state.country == null || this.state.country === '' || this.state.country === this.props.auth.constants.countries.CA) &&
                            <Grid item xs={4} sm={2}>
                                {!locked &&
                                    <InputMaskField mask="a9a-9a9" name="postal" value={this.state.postal}
                                    onChange={this.changeValue} disabled={locked}
                                    helperText={errors[t('postal')]}
                                    error={errors[t('postal')] != null ? true : false}
                                    label={t('postal')}
                                    data-cy="postal"
                                    />
                                }
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('postal')}</FormLabel>
                                        {this.state.postal}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {this.state.country != null && this.state.country !== '' && this.state.country === this.props.auth.constants.countries.US &&
                            <Grid item xs={4} sm={2}>
                                {!locked &&
                                    <InputMaskField mask="99999" name="postal" value={this.state.postal}
                                    onChange={this.changeValue} disabled={locked}
                                    helperText={errors[t('postal')]}
                                    error={errors[t('postal')] != null ? true : false}
                                    label={t('zipCode')}
                                    data-cy="postal"
                                    />
                                }
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('zipCode')}</FormLabel>
                                        {this.state.postal}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {this.state.country != null && this.state.country !== '' && this.state.country !== this.props.auth.constants.countries.CA &&
                        this.state.country !== this.props.auth.constants.countries.US &&
                            <Grid item xs={4} sm={2}>
                                {!locked &&
                                    <TextField value={this.state.postal} onChange={this.changeValue} name="postal"
                                    size="medium" fullWidth={true} disabled={locked} label={t('postal')} data-cy="postal"
                                    error={errors[t('postal')] != null ? true : false} helperText={errors[t('postal')]}/>
                                }
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('zipCode')}</FormLabel>
                                        {this.state.postal}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <InputMaskField mask="999-999-9999" name="phoneNumber" value={this.state.phoneNumber}
                                onChange={this.changeValue} disabled={locked}
                                helperText={errors[t('phoneNumber')]}
                                error={errors[t('phoneNumber')] != null ? true : false}
                                label={t('phoneNumber')} data-cy="phoneNumber"
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('phoneNumber')}</FormLabel>
                                    {this.state.phoneNumber}
                                </FormControl>
                            }
                        </Grid>
                        {!locked &&
                            <Grid item xs={4} sm={2}>
                                <TextField value={this.state.gstNumber} onChange={this.changeValue} name="gstNumber"
                                    size="medium" fullWidth={true} disabled={locked} label={t('gstNumber')} data-cy="gstNumber"
                                    error={errors[t('gstNumber')] != null ? true : false} helperText={errors[t('gstNumber')]}/>
                            </Grid>
                        }
                        {locked && this.state.gstNumber != null && this.state.gstNumber !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('gstNumber')}</FormLabel>
                                    {this.state.gstNumber}
                                </FormControl>
                            </Grid>
                        }
                        {this.props.auth.user.isCannabis &&
                            <Grid item xs={4} sm={2}>
                                {!locked &&
                                    <AutoCompleteField
                                        value={this.state.licenseTypeSel}
                                        options={this.state.licenseTypes}
                                        onChange={this.changeAuto('licenseTypeSel', 'licenseType')}
                                        error={errors[t('licenseType')] != null ? true : false}
                                        helperText={errors[t('licenseType')]}
                                        label={t('licenseType')}
                                        disabled={locked}
                                        data-cy="licenseType"
                                    />
                                }
                                {locked &&
                                    <FormControl>
                                        <FormLabel>{t('licenseType')}</FormLabel>
                                        {this.state.licenseTypeSel != null ? this.state.licenseTypeSel.label : ''}
                                    </FormControl>
                                }
                            </Grid>
                        }
                        {this.state.licenseType != null && this.state.licenseType !== '' &&
                            <Aux>
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <TextField value={this.state.licenseNumber} onChange={this.changeValue} name="licenseNumber"
                                        size="medium" fullWidth={true} disabled={locked} label={t('licenseNumber')} data-cy="licenseNumber"
                                        error={errors[t('licenseNumber')] != null ? true : false} helperText={errors[t('licenseNumber')]}/>
                                    }
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    {!locked &&
                                        <DateSelect
                                            onChange={this.changeDate('licenseExpiration')}
                                            value={this.state.licenseExpiration}
                                            helperText={errors[t('licenseExpiration')]}
                                            error={errors[t('licenseExpiration')] != null ? true : false}
                                            disabled={locked}
                                            label={t('licenseExpiry')}
                                            format={this.props.auth.user.dateFormat}
                                            data-cy="licenseExpiration"
                                        />
                                    }
                                </Grid>
                            </Aux>
                        }
                        {locked && this.state.licenseNumber != null && this.state.licenseNumber !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('licenseNumber')}</FormLabel>
                                    {this.state.licenseNumber}
                                </FormControl>
                            </Grid>
                        }
                        {locked && this.state.licenseExpiration != null && this.state.licenseExpiration !== '' &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('licenseExpiry')}</FormLabel>
                                    <DateDisplay value={this.state.licenseExpiration} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            </Grid>
                        }
                        {!locked &&
                            <Grid item xs={6} sm={3}>
                                <AutoCompleteField
                                    value={this.state.termsSel}
                                    options={this.state.saleTerms}
                                    onChange={this.changeAuto('termsSel', 'terms')}
                                    error={errors[t('terms')] != null ? true : false}
                                    helperText={errors[t('terms')]}
                                    label={t('terms')}
                                    disabled={locked}
                                    data-cy="terms"
                                />
                             </Grid>
                         }
                        {locked && this.state.termsSel != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('terms')}</FormLabel>
                                    {this.state.termsSel != null ? this.state.termsSel.label : ''}
                                </FormControl>
                            </Grid>
                        }
                       
                        <Grid item sm={7}>
                            <AutoCompleteField
                                multiple={true}
                                value={this.state.certsSel}
                                options={this.state.certificationTypes}
                                onChange={this.changeAuto('certsSel', 'certificates')}
                                error={errors[t('certificates')] != null ? true : false}
                                helperText={errors[t('certificates')]}
                                label={t('certificates')}
                                disabled={locked}
                                data-cy="certificates"
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <AutoCompleteField
                                    value={this.state.qualStatSel}
                                    options={this.state.qualificationStatuses}
                                    onChange={this.changeAuto('qualStatSel', 'qualificationStatus')}
                                    error={errors[t('qualificationStatus')] != null ? true : false}
                                    helperText={errors[t('qualificationStatus')]}
                                    label={t('qualificationStatus')}
                                    disabled={locked}
                                    data-cy="qualificationStatus"
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('qualificationStatus')}</FormLabel>
                                   {this.state.qualStatSel != null ? this.state.qualStatSel.label : ''}
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            {!locked &&
                                <DateSelect
                                    onChange={this.changeDate('qualificationDate')}
                                    value={this.state.qualificationDate}
                                    helperText={errors[t('qualificationDate')]}
                                    error={errors[t('qualificationDate')] != null ? true : false}
                                    disabled={locked}
                                    label={t('qualificationDate')}
                                    format={this.props.auth.user.dateFormat}
                                    data-cy="qualificationDate"
                                />
                            }
                            {locked &&
                                <FormControl>
                                    <FormLabel>{t('qualificationDate')}</FormLabel>
                                    <DateDisplay value={this.state.qualificationDate} format={this.props.auth.user.dateFormat}/>
                                </FormControl>
                            }
                        </Grid>
                        {this.state.customer != null &&
                            <Grid item xs={4} sm={2}>
                                <FormControl>
                                    <FormLabel>{t('customer')}</FormLabel>
                                    <Link to={'/customer/' + this.state.customer._id}>{this.state.customer.number}</Link>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    {this.state.hasForm === true &&
                        <Aux>
                            <br/>
                            {errors[t('form')] != null && errors[t('form')] !== '' &&
                                <div style={{color: 'red'}}>{errors[t('form')]}</div>
                            }
                            <Form renderer={renderer}
                                defaultFields={this.state.form}
                                onChange={this.formValueChanged}
                                disabled={locked}/>
                        </Aux>
                    }
                    {this.state._id != null && this.state._id !== '' && (!locked || (this.state.employees != null && this.state.employees.length > 0)) &&
                        <Aux>
                            <br/>
                            <SectionTitle title={t('employees')} add={!locked ? this.toggleEmployee : null} security={!locked  ? this.props.permission : null}
                                          data-cy='addEmployee' />
                            <TableContainer component={Paper}>
                                <Table aria-label="employees">
                                    <TableHead>
                                        <TableRow key="empHeader">
                                            <TableCell>{t('name')}</TableCell>
                                            <TableCell>{t('email')}</TableCell>
                                            <TableCell>{t('title')}</TableCell>
                                            <TableCell>{t('phoneNumber')}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.employees.map((row, i) => 
                                            <TableRow key={'emp' + i}>
                                                <TableCell>{row.firstName + ' ' + row.lastName}</TableCell>
                                                <TableCell>{row.email}</TableCell>
                                                <TableCell>{row.jobTitle}</TableCell>
                                                <TableCell>{row.phoneNumbers != null && row.phoneNumbers.length > 0 ? row.phoneNumbers[0].number : ''}</TableCell>
                                                <TableCell>
                                                    <IconButton aria-label="Edit" onClick={this.editEmployee(i)}>
                                                        <EditIcon  />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Dialog open={this.state.editEmployee} onClose={this.clearEmployee} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
                                <DialogTitle id="form-dialog-title">{t('employee')}</DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>
                                            {!locked && 
                                                <TextField value={this.state.firstName} onChange={this.changeValue} name="firstName"
                                                size="medium" fullWidth={true} disabled={locked} label={t('firstName')} data-cy="firstName"
                                                error={errors[t('firstName')] != null ? true : false} helperText={errors[t('firstName')]}/>
                                            }
                                            {locked &&
                                                <FormControl>
                                                    <FormLabel>{t('firstName')}</FormLabel>
                                                    {this.state.firstName}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            {!locked && 
                                                <TextField value={this.state.lastName} onChange={this.changeValue} name="lastName"
                                                size="medium" fullWidth={true} disabled={locked} label={t('lastName')} data-cy="lastName"
                                                error={errors[t('lastName')] != null ? true : false} helperText={errors[t('lastName')]}/>
                                            }
                                            {locked &&
                                                <FormControl>
                                                    <FormLabel>{t('lastName')}</FormLabel>
                                                    {this.state.lastName}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            {!locked && 
                                                <TextField value={this.state.email} onChange={this.changeValue} name="email"
                                                size="medium" fullWidth={true} disabled={locked} label={t('email')} data-cy="email"
                                                error={errors[t('email')] != null ? true : false} helperText={errors[t('email')]}/>
                                            }
                                            {locked &&
                                                <FormControl>
                                                    <FormLabel>{t('email')}</FormLabel>
                                                    {this.state.email}
                                                </FormControl>
                                            }
                                        </Grid>
                                        <Grid item xs={4} sm={2}>
                                            {!locked && 
                                                <TextField value={this.state.jobTitle} onChange={this.changeValue} name="jobTitle"
                                                size="medium" fullWidth={true} disabled={locked} label={t('title')} data-cy="jobTitle"
                                                error={errors[t('jobTitle')] != null ? true : false} helperText={errors[t('jobTitle')]}/>
                                            }
                                            {locked &&
                                                <FormControl>
                                                    <FormLabel>{t('title')}</FormLabel>
                                                    {this.state.jobTitle}
                                                </FormControl>
                                            }
                                        </Grid>
                                    </Grid>
                                    {(!locked || (this.state.employees != null && this.state.employees.length > 0)) &&
                                        <Aux>
                                            <br/>
                                            <SectionTitle title={t('phoneNumbers')}/>
                                            <Grid container spacing={3} key="phoneHeader">
                                                <Grid item xs={4} sm={2}>{t('number')}</Grid>
                                                <Grid item xs={4} sm={2}>{t('type')}</Grid>
                                            </Grid>
                                            {this.state.phoneNumbers.map((row, i) => 
                                                <Grid container spacing={3} key={'phone' + i} sx={{pt: 1}}>
                                                    {locked &&
                                                        <Grid item xs={4} sm={2}>{row.number}</Grid>
                                                    }
                                                    {!locked &&
                                                        <Grid item xs={4} sm={2}>
                                                            <InputMaskField mask="999-999-9999" name="number" value={row.number}
                                                            onChange={this.changePhoneValue(i)} disabled={locked}
                                                            helperText={errors[`${t('phoneNumber')} (${t('line')} ${i})`]}
                                                            error={errors[`${t('phoneNumber')} (${t('line')} ${i})`] != null ? true : false}
                                                            data-cy={'phoneNumber' + i}
                                                            />
                                                        </Grid>
                                                    }
                                                    {locked &&
                                                        <Grid item xs={4} sm={2}>{row.typeSel.label}</Grid>
                                                    }
                                                    {!locked &&
                                                        <Grid item xs={4} sm={2}>
                                                            <AutoCompleteField
                                                                value={row.typeSel}
                                                                options={this.state.phoneTypes}
                                                                onChange={this.changePhoneAuto('typeSel', 'type', i)}
                                                                error={errors[`${t('type')} (${t('line')} ${i})`] != null ? true : false}
                                                                helperText={errors[`${t('type')} (${t('line')} ${i})`]}
                                                                disabled={locked}
                                                                data-cy={'type' + i}
                                                            />
                                                        </Grid>
                                                    }
                                                    {!locked &&
                                                        <Grid item xs={1} sm={1}>
                                                            <IconButton aria-label="DeletePhone" onClick={this.deletePhone(i)}>
                                                                <DeleteIcon  />
                                                            </IconButton>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            )}
                                            {!locked &&
                                                <Aux>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={4} sm={2}>
                                                        <InputMaskField mask="999-999-9999" name="empPhoneNumber" value={this.state.empPhoneNumber}
                                                        onChange={this.changeValue} disabled={locked}
                                                        helperText={errors[t('empPhoneNumber')]}
                                                        error={errors[t('empPhoneNumber')] != null ? true : false}
                                                        required
                                                        data-cy="employeePhone"
                                                        />
                                                        </Grid>
                                                        <Grid item xs={4} sm={2}>
                                                            <AutoCompleteField
                                                                value={this.state.phoneTypeSel}
                                                                options={this.state.phoneTypes}
                                                                onChange={this.changeAuto('phoneTypeSel', 'phoneType')}
                                                                error={errors[t('phoneType')] != null ? true : false}
                                                                helperText={errors[t('phoneType')]}
                                                                disabled={locked}
                                                                data-cy={'phoneType'}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1} sm={1}>
                                                            <IconButton aria-label="AddPhone" onClick={this.addPhone}>
                                                                <AddIcon  />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Aux>
                                            }
                                        </Aux>
                                    }
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.clearEmployee} color="primary">{t('cancel')}</Button>
                                    {!locked &&
                                        <Aux>
                                            &nbsp;&nbsp;
                                            <Button onClick={this.deleteEmployee} color="secondary">{t('delete')}</Button>
                                            &nbsp;&nbsp;
                                            <Button onClick={this.saveEmployee} variant='contained' color="primary">{t('save')}</Button>
                                        </Aux>
                                    }
                                </DialogActions>
                            </Dialog>
                        </Aux>
                    }
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item sm={10}>
                            {(!locked) &&
                                <Aux>
                                    <Button disabled={!this.state.enableSave} variant="contained" color="primary" size="small" onClick={() => this.saveClick()}>{t('save')}</Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {this.state.security.update && locked &&
                                <Aux>
                                    <Button variant="contained" color="secondary" size="small" onClick={this.unlock}>{t('unlock')}</Button>&nbsp;&nbsp;
                                </Aux>
                            }
                            {this.state.security.update && !locked &&
                                <Aux>
                                    <Button variant="contained" color="secondary" size="small" onClick={this.unlock}>{t('lock')}</Button>
                                    &nbsp;&nbsp;
                                </Aux>
                            }
                            {!locked && this.state.security.create && this.state.customer == null &&
                                <Aux>
                                    <Button variant="contained" color="secondary" size="small" onClick={this.toggleConfirmation}>{t('createCustomer')}</Button>
                                    &nbsp;&nbsp;
                                </Aux>
                            }
                            {this.state.security.delete && !locked &&
                                <Button disabled={!this.state.enableSave} variant="contained" color="secondary" size="small" onClick={this.deleteToggle}>{t('delete')}</Button>
                            }
                        </Grid>
                    </Grid>
                </Panel>
                {this.state._id != null && this.state._id !== '' &&
                    <div>
                        <br/>
                        <ExternalTreatments vendor={this.state._id}/>
                        <EquipmentLogs vendor={this.state._id}/>
                        <TestResults vendor={this.state._id}/>
                        <Shipments vendor={this.state._id} title={t('returns')} type={this.props.auth.constants.shipmentTypes.return}/>
                    </div>
                }

                {this.state._id != null && this.state._id !== '' &&
                    <div>
                        <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.vendor}/>
                        <ObjectLog id={this.state._id}  renderNum={this.state.renderNum}/>
                    </div>
                }
                {this.state.deleteOpen &&
                    <ConfirmDelete toggleDialog={() => this.deleteToggle()}
                        redirect={() => this.deleteClick()} open={this.state.deleteOpen} />
                }
                {this.state.confirmOpen &&
                    <Confirmation
                        content={t('confirmCreateCustomer')}
                        no={this.toggleConfirmation}
                        noButton={t('no')}
                        yes={this.createCustomer}
                        yesButton={t('yes')}
                        title={t('confirm')}
                        open={this.state.confirmOpen}
                    />
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Vendor));
