import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../../is-empty';
import DateSelect from '../../UI/DateSelect/DateSelect';
import TextField from '../../UI/TextField/TextField';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../../UI/AutoCompleteField/AutoCompleteField';
import FormLabel from '@mui/material/FormLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { ClearButton, SaveButton } from '../../UI/Buttons/Buttons';
import { withTranslation } from 'react-i18next';
import { Form } from "@sop/react-forms-processor";
import { renderer } from "@sop/react-forms-processor-material-ui";
import Aux from '../../../hoc/Auxilary/Auxilary';
import common from '../../../jsons/common.json';
import ValueHelper from '../../../helpers/valueHelper';
import Button from '@mui/material/Button';

class ProductionBatchEdit extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: '',
            number: '',
            description: '',
            status: this.props.auth.constants.productionBatchStatuses.inserted,
            location: '',
            locationSel: null,
            locations: [],
            startItem: '',
            startItemSel: null,
            startItems: [],
            outputItem: '',
            outputItemSel: null,
            outputItems: [],
            outputQuantity: '',
            uom: '',
            uomSel: null,
            uoms: [],
            dueDate: '',
            plannedStart: new Date(),
            openStatus: this.props.open,
            fieldAccess: false,
            toggleDialog: this.props.onClose,
            security: {},
            started: false,
            enableSave: true,
            form: '',
            hasForm: false,
            packagedOn: '',
            lines: [],
            showItemSelect: false,
            itemObj: null,
            producedBy: '',
            producedBySel: null,
            producedBys: []
        };
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.processingLocation, common.cacheValues.productionItem, common.cacheValues.quantityUOM],
            this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        let location = this.props.auth.user.defaultLocation != null && this.props.auth.user.defaultLocation !== '' ?
            this.props.auth.user.defaultLocation : '';
        let locSel = location !== '' ? locations.find(x => x.value === location) : null;
        if(locSel == null && locations.length === 1){
            locSel = locations[0];
            location = locSel.value;
        }
        this.setState({
            outputItems: cacheValues.productionItems,
            uoms: cacheValues.quantityUOMs,
            locations: locations,
            location: location,
            locationSel: locSel,
            toggleDialog: this.props.onClose,
            security: this.props.permission,
            fieldAccess: this.props.fieldAccess,
            reload: this.props.reload,
            _id: this.props.id != null && this.props._id !== '' ? this.props.id : ''
        }, () => {
            if(this.state._id != null && this.state._id !== '')
                this.loadRecord();
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/productionbatch/' + this.state._id);
        let locationSel = this.state.locations.find(x => x.value === record.data.location._id);
        let outputItemSel = this.state.outputItems.find(x => x.value === record.data.outputItem._id);
        let uomSel = this.state.uoms.find(x => x.value === record.data.uom._id);
        let startItemSel = this.state.startItem != null ? record.data.startItems.find(x => x.value === record.data.startItem) : null;
        let started = record.data.status._id === this.props.auth.constants.productionBatchStatuses.inserted ? false : true;
        let hasForm = this.state.hasForm;
        if(!hasForm && record.data.form != null && record.data.form !== '')
            hasForm = true;
        this.setState({
            number: record.data.number,
            description: record.data.description,
            location: record.data.location,
            locationSel: locationSel,
            startItem: record.data.startItem,
            startItemSel: startItemSel,
            outputItem: record.data.outputItem,
            outputItemSel: outputItemSel,
            outputQuantity: record.data.outputQuantity,
            uom: record.data.uom,
            uomSel: uomSel,
            dueDate: record.data.dueDate,
            plannedStart: record.data.plannedStart,
            status: record.data.status,
            startItems: record.data.startItems,
            started: started,
            form: record.data.form != null ? JSON.parse(record.data.form) : null,
            hasForm: hasForm,
            packagedOn: record.data.packagedOn
        })
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    changeNumber = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if((isNaN(value)))
            return;
        this.setState({[name]: value});
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    changeAuto = (name, data) => async (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        }, () => {
            if(data === 'outputItem' && newValue != null){
                let uomSel = this.state.uoms.find(x => x.value === newValue.uom);
                this.setState({uom: newValue.uom, uomSel: uomSel, startItems: [], startItem: '', startItemSel: null});
                this.loadItem();
            } else if (data === "outputItem" && newValue == null)
                this.setState({startItems: [], startItem: '', startItemSel: null});
        });

    };

    formValueChanged = (e) => {
        this.setState({value: e});
    };

    async loadItem(){
        const t = this.props.t;
        let lines = this.state.lines;
        if(this.state.producedBy != null && this.state.producedBy !== ''){
            lines[lines.length - 1].producedBy = this.state.producedBy;
        }
        let query = {
            startItem: this.state.startItem,
            lines: lines != null ? lines : [],
            poOnly: true,
            startDate: this.state.plannedStart
        };
        try {
            let result = await axios.post('/api/itemproduction/' + this.state.outputItem, query);
            if((result.data != null && result.data.activities != null && result.data.activities !== '') || result.data.itemSelect === true){
                let activities = result.data.activities;
                let lines = result.data.lines;
                if(result.data.itemSelect !== true){
                    let startItems = this.state.startItems;
                    let setSteps = startItems.length === 0;
                    activities.forEach(act => {
                        if(act.routingLines != null && act.routingLines.length > 0){
                            if(setSteps)
                                if(act.item._id !== this.state.outputItem){
                                    startItems.push({value: act.item._id, label: act.item.number + ' - ' + act.item.name});
                                }
                        }
                    });
                    this.setState({
                        activities: activities,
                        startItems: startItems,
                        lines: lines,
                        showItemSelect: false,
                        producedBy: '',
                        produceBySel: null,
                        producedBys: [],
                        itemObj: null,
                        itemSelect: false
                    });
                } else {
                    let line = lines[lines.length - 1];
                    this.setState({
                        showItemSelect: true,
                        lines: lines,
                        itemObj: line.itemObj,
                        producedBys: line.producedBys,
                        itemSelect: true
                    })
                }
            } else {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: "Configuration issue, possibly circular item reference", title: t('error'), severity: 'error'}});
            }
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        }

    }

    save = async () => {
        if(!this.state.enableSave)
            return;
            this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        const toggleDialog = this.state.toggleDialog;
        const reload = this.state.reload;
        const valid = this.validate();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let form = this.state.form;
        if(this.state.hasForm && this.state.value != null){
            for (let key in this.state.value){
                let val = this.state.value[key];
                for (let i = 0; i < form.length; i++){
                    let field = form[i];
                    if (field.name === key){
                        field.value = val;
                        break;
                    }
                }
            }
            let errors = {};
            let error = false;
            for(let row of form){
                if(row.required === true && (row.value == null || row.value === '')){
                    error = true;
                    break;
                }
            }
            if(error === true){
                errors[t('form')] = t('fieldsIncomplete');
                this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
                this.setState({enableSave: true});
                return;
            }
        }
        let data = {
            _id: this.state._id,
            number: this.state.number != null && this.state.number !== '' ? this.state.number.toUpperCase() : null,
            description: this.state.description,
            outputItem: this.state.outputItem,
            dueDate: this.state.dueDate,
            plannedStart: this.state.plannedStart,
            startItem: this.state.startItem,
            location: this.state.location,
            outputQuantity: this.state.outputQuantity,
            uom: this.state.uom != null ? this.state.uom : null,
            form: form != null && form !== '' ? JSON.stringify(form) : null,
            packagedOn: this.state.packagedOn,
            activities: this.state.activities
        };
        try {
            let result = await axios.post('/api/productionbatch', data);
            this.setState({ enableSave: true }, () => {
                toggleDialog();
                reload();
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    }

    validate(){
        const t = this.props.t;
        const state = this.state;
        let errors = {};
        if(state.description == null || state.description === '')
            errors[t('description')] = t('required');
        if(state.outputItem == null || state.outputItem === '')
            errors[t('outputItem')] = t('required');
        if(state.dueDate == null || state.dueDate === '')
            errors[t('dueDate')] = t('required');
        if(state.plannedStart == null || state.plannedStart === '')
            errors[t('plannedStart')] = t('required');
        if(state.location == null || state.location === '')
            errors[t('location')] = t('required');
        if(state.outputQuantity == null || state.outputQuantity === '')
            errors[t('outputQuantity')] = t('required');
        if(state._id != null && state._id !== '' && state.number != null && state.number !== ''){
            if(state.number.length < 6 || state.number.length > 15){
                errors[t('number')] = t('length6to20')
            }
        }
        if(state._id != null && state._id !== '' && (state.number == null || state.number === '')){
            errors[t('number')] = t('required');
        }
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    toggleItemSelect = () => {
        this.setState({showItemSelect: !this.state.showItemSelect});
    }

    selectProducedBy = () => {
        if(this.state.producedBy == null || this.state.producedBy === ''){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: { [this.props.t('producedBy')]: this.props.t('required') }});
            return;
        }
        this.setState({showItemSelect: false});
        this.loadItem();
    }

    render(){
        const openStatus = this.state.openStatus;
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const toggleDialog = this.state.toggleDialog;
        const started = this.state.started;
        const t = this.props.t;
        return(
            <Aux>
                <Dialog open={openStatus} onClose={toggleDialog} aria-labelledby="dialog-new-batch-record">
                    <DialogTitle aria-labelledby="dialog-new-batch-record-title">{this.state._id != null && this.state._id !== '' ? t('edit') : t('create')} {t('batch')}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            {fieldAccess &&
                                <Grid item xs={6} sm={3}>
                                    <TextField value={this.state.number} onChange={this.changeValue} name="number" data-cy="number"
                                    size="medium" fullWidth={true} disabled={!fieldAccess} label={this.state._id == null || this.state._id === '' ? t('customNumber') : t('number')}
                                    error={errors[t('number')] != null ? true : false} helperText={errors[t('number')]}/>
                                </Grid>
                            }
                            {!fieldAccess &&
                                <Grid item xs={4} sm={3}>
                                    <FormControl data-cy="number">
                                        <FormLabel>{t('number')}</FormLabel>
                                        {this.state.number}
                                    </FormControl>
                                </Grid>
                            }
                            {this.state._id != null && this.state._id !== '' &&
                                <Grid item xs={4} sm={3}>
                                        <FormControl data-cy="number">
                                        <FormLabel>{t('status')}</FormLabel>
                                        {this.state.status != null ? this.state.status.label : ''}
                                        </FormControl>
                                </Grid>
                            }
                            <Grid item xs={6}>
                                <AutoCompleteField
                                value={this.state.outputItemSel}
                                options={this.state.outputItems}
                                onChange={this.changeAuto('outputItemSel', 'outputItem')}
                                error={errors[t('outputItem')] != null ? true : false}
                                helperText={errors[t('outputItem')]}
                                label={t('outputItem')}
                                disabled={started || this.state.outputItems.length === 0 ? true : !fieldAccess}
                                required
                                data-cy="outputItem"
                                />
                            </Grid>
                            {this.state.startItems != null && this.state.startItems.length > 0 &&
                                <Grid item xs={6}>
                                    <AutoCompleteField
                                    value={this.state.startItemSel}
                                    options={this.state.startItems}
                                    onChange={this.changeAuto('startItemSel', 'startItem')}
                                    error={errors[t('startItem')] != null ? true : false}
                                    helperText={errors[t('startItem')]}
                                    label={t('startWithItem')}
                                    disabled={started || !fieldAccess}
                                    data-cy="startItem"
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} sm={6} lg={5}>
                                <TextField value={this.state.description} onChange={this.changeValue} name="description"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('description')} required
                                error={errors[t('description')] != null ? true : false} helperText={errors[t('description')]}
                                data-cy="description"/>
                            </Grid>
                            <Grid item xs={6}>
                                <AutoCompleteField
                                value={this.state.locationSel}
                                options={this.state.locations}
                                onChange={this.changeAuto('locationSel', 'location')}
                                error={errors[t('location')] != null ? true : false}
                                helperText={errors[t('location')]}
                                label={t('location')}
                                disabled={!started && this.state.locations.length === 0 ? true : !fieldAccess}
                                required
                                data-cy="location"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <DateSelect
                                onChange={this.dateChange('plannedStart')}
                                value={this.state.plannedStart}
                                helperText={errors[t('plannedStart')]}
                                error={errors[t('plannedStart')] != null ? true : false}
                                label={t('plannedStart')}
                                disabled={( started ) ? true : !fieldAccess}
                                format={this.props.auth.user.dateFormat}
                                required
                                data-cy="plannedStart"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <DateSelect
                                onChange={this.dateChange('dueDate')}
                                value={this.state.dueDate}
                                helperText={errors[t('dueDate')]}
                                error={errors[t('dueDate')] != null ? true : false}
                                label={t('dueDate')}
                                disabled={( started ) ? true : !fieldAccess}
                                format={this.props.auth.user.dateFormat}
                                required
                                data-cy="dueDate"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <DateSelect
                                onChange={this.dateChange('packagedOn')}
                                value={this.state.packagedOn}
                                helperText={errors[t('packagedOn')]}
                                error={errors[t('packagedOn')] != null ? true : false}
                                label={t('packagedOn')}
                                disabled={( started ) ? true : !fieldAccess}
                                format={this.props.auth.user.dateFormat}
                                data-cy="packagedOn"
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <TextField type="number" value={this.state.outputQuantity} onChange={this.changeNumber} name="outputQuantity"
                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('quantity')} required
                                error={errors[t('outputQuantity')] != null ? true : false} helperText={errors[t('outputQuantity')]}
                                data-cy="outputQuantity"/>
                            </Grid>
                            {this.state.uomSel != null &&
                                <Grid item xs={2} sm={1}>
                                    <FormControl>
                                        <FormLabel>{t('uom')}</FormLabel>
                                        {this.state.uomSel != null ? this.state.uomSel.label : ''}
                                    </FormControl>
                                </Grid>
                            }
                        </Grid>
                        {this.state.hasForm === true &&
                            <Aux>
                                <br/>
                                {errors[t('form')] != null && errors[t('form')] !== '' &&
                                    <div style={{color: 'red'}}>{errors[t('form')]}</div>
                                }
                                <Form renderer={renderer}
                                    defaultFields={this.state.form}
                                    onChange={this.formValueChanged}
                                    disabled={!fieldAccess}/>
                            </Aux>
                        }
                    </DialogContent>
                    <DialogActions>
                        <SaveButton saveClick={this.save} enabled={this.state.enableSave && !this.state.itemSelect} data-cy="save"/>
                        <ClearButton clearClick={toggleDialog} enabled={fieldAccess} data-cy="clear"/>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.showItemSelect} onClose={() => this.toggleItemSelect} aria-labelledby="dialog-new-batch-record" maxWidth="md" fullWidth={true}>
                    <DialogTitle aria-labelledby="dialog-new-batch-record-title">{t('producedBy')}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={10} sm={6}>
                                <FormControl>
                                    <FormLabel>{t('item')}</FormLabel>
                                    {this.state.itemObj != null ? this.state.itemObj.number + ' - ' + this.state.itemObj.name : ''}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={8}>
                                <AutoCompleteField
                                value={this.state.producedBySel}
                                options={this.state.producedBys}
                                onChange={this.changeAuto('producedBySel', 'producedBy')}
                                error={errors[t('producedBy')] != null ? true : false}
                                helperText={errors[t('producedBy')]}
                                label={t('producedBy')}
                                disabled={!fieldAccess}
                                required
                                data-cy="producedBy"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button name='closeProducedBy' variant="text" color="primary" onClick={() => this.toggleItemSelect()}>{t('cancel')}</Button>
                        <Button name='saveProducedBy' variant="contained" color="primary" onClick={() => this.selectProducedBy()}>{t('select')}</Button>
                    </DialogActions>
                </Dialog>
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(ProductionBatchEdit));
