import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import isEmpty from '../../is-empty';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import queryString from 'query-string';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import { ClearButton, SaveButton, TextOnlyClickButton } from '../UI/Buttons/Buttons';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SectionTitle from '../UI/Typography/SectionTitle';
import SubSectionHeading from '../UI/Typography/SubSectionHeading';
import { approveSigSecurity, completeSigSecurity, 
         fieldSecurity, reviewSigSecurity, } from '../../helpers/securityHelper';
import { EvoraTabs, TabPanel } from '../UI/Tabs/Tabs';
import Box from '@mui/material/Box';
import ActionBar from '../UI/Buttons/ActionBar';
import ScheduleDialog from '../General/ScheduleDialog';
import { withTranslation } from 'react-i18next';
import common from '../../jsons/common.json';
import ValueHelper from '../../helpers/valueHelper';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';

class LocationInspection extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id: null,
            date: new Date().setHours(0,0,0,0),
            location: this.props.match.params.locationId,
            locationName: '',
            score: '',
            scores: [],
            roomInspections: [],
            inspectionId: '',
            room: {},
            users: [],
            selectedUsers: [],
            userOptions: [],
            approver: '',
            approverDate: null,
            managers: [],
            approverSignature: '',
            floors: '',
            floorDeficiency: '',
            walls: '',
            wallDeficiency: '',
            ceiling: '',
            ceilingDeficiency: '',
            doors: '',
            doorDeficiency: '',
            equipmentInspections: [],
            equipmentId: '',
            equipmentScore: '',
            equipmentDeficiency: '',
            roomEdit: false,
            equipmentEdit: false,
            signatureOpen: false,
            signed: false,
            canSign: false,
            strFloorScore: '',
            strFloorScoreSel: null,
            strFloorDef: '',
            strWallScore: '',
            strWallScoreSel: null,
            strWallDef: '',
            strCeilScore: '',
            strCeilScoreSel: null,
            strCeilDef: '',
            strDoorScore: '',
            strDoorScoreSel: null,
            strDoorDef: '',
            strRackScore: '',
            strRackScoreSel: null,
            strRackDef: '',
            strStorScore: '',
            strStorScoreSel: null,
            strStorDef: '',
            strTempScore: '',
            strTempScoreSel: null,
            strTempDef: '',
            strChemScore: '',
            strChemScoreSel: null,
            strChemDef: '',
            strPestScore: '',
            strPestScoreSel: null,
            strPestDef: '',
            extWallScore: '',
            extWallScoreSel: null,
            extWallDef: '',
            extWindScore: '',
            extWindScoreSel: null,
            extWindDef: '',
            extFenceScore: '',
            extFenceScoreSel: null,
            extFenceDef: '',
            extGateScore: '',
            extGateScoreSel: null,
            extGateDef:'',
            schedule: null,
            user: null,
            reviewer: null,
            signer: null,
            status: '',
            statuses: [],
            statusSel: {
                value: '',
                label: ''
            },
            tenant: this.props.auth.user.tenant,
            documents: [],
            number: '',
            scoreSel: null,
            floorsSel: null,
            wallsSel: null,
            ceilingSel: null,
            doorsSel: null,
            equipmentSel: null,
            fieldAccess: false, 
            completeSigSec: false,
            reviewSigSec: false,
            approveSigSec: false,
            security: [],
            tabKey: 0,
            tabLabels: [
                { name: this.props.t('activity'), disabled: false },
                { name: this.props.t('rooms'), disabled: false },
                { name: this.props.t('storageAreas'), disabled: false },
                { name: this.props.t('exterior'), disabled: false }
            ],
            optionsMenu: [],
            flagForSignature: 0,
            dialogOpen: false,
            enableSave: true,
            goBack: -1
        };

        this.sigPad = {};
        this.changeValue = this.changeValue.bind(this);
        this.saveClick = this.saveClick.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.approverDateChange = this.approverDateChange.bind(this);
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.activeLocation, common.cacheValues.status], this.props.auth.constants, null, this.props.auth.user.tenant);
        let locations = cacheValues.locations;
        let params = queryString.parse(this.props.location.search);
        try{
            const values = await axios.get('/api/locationinspectionvalues');
            const statusSel = values.data.statuses.find(x => x.value === this.props.auth.constants.statuses.new);
            const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
            let goBack = -1;
            if(params != null && params.goBack != null)
                goBack = -2;
            this.setState({
                userOptions: values.data.users,
                managers: values.data.managers,
                qas: values.data.qas,
                locations: locations,
                statuses: cacheValues.statuses,
                scores: values.data.scores,
                schedule: params != null && params.schedule != null ? params.schedule : null,
                status: this.props.auth.constants.statuses.new,
                statusSel: statusSel,
                security: this.props.permission,
                fieldAccess: fieldAccess,
                goBack: goBack
            }, () => {
                this.loadRecord(values);
            })
            
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: 'Error', severity: 'error'}});
        }
    }

    loadRecord = async (values) => {
        const t = this.props.t;
        let inspection = null;
        let notNew = true;
        const id = this.state._id != null ? this.state._id : this.props.match.params.id;
        if(id != null && id !== ''){
            inspection = await axios.get('/api/locationinspection/' + id);
        } else {
            notNew = false;
            inspection = await axios.get('/api/locationinspectionnew/' + this.props.match.params.locationId);
        }
        let selectedUsers = [];
        let userOptions = values != null && values.data.users != null ? values.data.users : this.state.userOptions;
        if(notNew){
            for(var i = 0; i < inspection.data.users.length; i++){
                for(var j = 0; j < userOptions.length; j++){
                    if(inspection.data.users[i] === userOptions[j].value){
                        selectedUsers.push(userOptions[j]);
                        break;
                    }
                }
            }
        }
        let locationName = '';
        let locations = values != null && values.data.locations != null ? values.data.locations : this.state.locations;
        for(i = 0; i < locations.length; i++){
            if(this.props.match.params.locationId === locations[i].value){
                locationName = locations[i].label;
                break;
            }
        }
        inspection.data.roomInspections.forEach(roomInsp => {
            if(roomInsp.floors.score != null && roomInsp.floors.score !== '' && roomInsp.walls.score != null && roomInsp.walls.score !== '' &&
            roomInsp.ceiling.score != null & roomInsp.ceiling.score !== '' && roomInsp.doors.score != null && roomInsp.doors.score !== '')
                roomInsp.status = t('done');
            else 
                roomInsp.status = '';
                if(roomInsp.equipmentInspections != null && roomInsp.equipmentInspections.length > 0){
                    roomInsp.equipmentInspections.forEach(equipInsp => {
                        if(equipInsp.inspection.score != null && equipInsp.inspection.score !== '')
                            equipInsp.status = t('done')
                        else 
                            equipInsp.status = '';
                    })
                }
        })
        let signed = false;
        let canSign = false;
        if(inspection.data.status === this.props.auth.constants.statuses.reviewed || 
                inspection.data.status === this.props.auth.constants.statuses.signed){
            signed = true;
            canSign = true;
        }
        const statusSel = this.state.statuses.find(x => x.value === inspection.data.status);
        const strFloorScoreSel = this.state.scores.find(x => x.value === inspection.data.storage.floors.score);
        const strWallScoreSel = this.state.scores.find(x => x.value === inspection.data.storage.walls.score);
        const strCeilScoreSel = this.state.scores.find(x => x.value === inspection.data.storage.ceiling.score);
        const strDoorScoreSel = this.state.scores.find(x => x.value === inspection.data.storage.doors.score);
        const strRackScoreSel = this.state.scores.find(x => x.value === inspection.data.storage.racks.score);
        const strStorScoreSel = this.state.scores.find(x => x.value === inspection.data.storage.storage.score);
        const strTempScoreSel = this.state.scores.find(x => x.value === inspection.data.storage.temperature.score);
        const strChemScoreSel = this.state.scores.find(x => x.value === inspection.data.storage.chemicalStorage.score);
        const strPestScoreSel = this.state.scores.find(x => x.value === inspection.data.storage.pestControl.score);
        const extWallScoreSel = this.state.scores.find(x => x.value === inspection.data.exterior.walls.score);
        const extWindScoreSel = this.state.scores.find(x => x.value === inspection.data.exterior.windows.score);
        const extFenceScoreSel = this.state.scores.find(x => x.value === inspection.data.exterior.fence.score);
        const extGateScoreSel = this.state.scores.find(x => x.value === inspection.data.exterior.gate.score);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const completeSigSec = completeSigSecurity(this.props.permission.complete, inspection.data.receiver, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const reviewSigSec = reviewSigSecurity(this.props.permission.review, inspection.data.reviewer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const approveSigSec = approveSigSecurity(this.props.permission.approve, inspection.data.signer, statusSel.value, this.props.auth.common, this.props.auth.constants);
        const optionsMenu = [
            { 
                security: completeSigSec, user: this.props.auth.user.id, users: this.state.userOptions, name: t('complete'), 
                title: t('completedBy'), setId: () => this.loadRecord(), signature: inspection.data.user, object: inspection.data._id,
                objectType: this.props.auth.constants.objectIds.locationInspection, type: this.props.auth.constants.signatureTypes.user,
                menuType: this.props.auth.constants.optionMenuTypes.signature, 
            },
            { 
                security: reviewSigSec, user: this.props.auth.user.id, users: this.state.managers, name: t('review'),
                title: t('reviewedBy'), setId: () => this.loadRecord(), signature: inspection.data.reviewer, object: inspection.data._id, 
                objectType: this.props.auth.constants.objectIds.locationInspection, type: this.props.auth.constants.signatureTypes.reviewer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                security: approveSigSec, user: this.props.auth.user.id, users: this.state.qas, name: t('approve'),
                title: t('approvedBy'), setId: () => this.loadRecord(), object: inspection.data._id, signature: inspection.data.signer, 
                objectType: this.props.auth.constants.objectIds.locationInspection, type: this.props.auth.constants.signatureTypes.signer,
                menuType: this.props.auth.constants.optionMenuTypes.signature
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.comments
            },
            {
                menuType: this.props.auth.constants.optionMenuTypes.logs
            }
        ];
        const flagForSignature = inspection.data.status === this.props.auth.constants.statuses.inprogress ? 1 :
                                 inspection.data.status === this.props.auth.constants.statuses.complete ? 2 :
                                 inspection.data.status === this.props.auth.constants.statuses.reviewed ? 3 : 0;
        this.setState({
            _id: notNew ? inspection.data._id : '',
            score: notNew ? inspection.data.score : '',
            date: notNew ? Date.parse(inspection.data.date) : new Date().setHours(0,0,0,0),
            roomInspections: inspection.data.roomInspections,
            users: notNew ? inspection.data.users : [],
            selectedUsers: selectedUsers,
            strFloorScore: notNew && inspection.data.storage != null ? inspection.data.storage.floors.score : '',
            strFloorDef: notNew && inspection.data.storage != null ? inspection.data.storage.floors.deficiency : '',
            strWallScore: notNew && inspection.data.storage != null ? inspection.data.storage.walls.score : '',
            strWallDef: notNew && inspection.data.storage != null ? inspection.data.storage.walls.deficiency : '',
            strCeilScore: notNew && inspection.data.storage != null ? inspection.data.storage.ceiling.score : '',
            strCeilDef: notNew && inspection.data.storage != null ? inspection.data.storage.ceiling.deficiency : '',
            strDoorScore: notNew && inspection.data.storage != null ? inspection.data.storage.doors.score : '',
            strDoorDef: notNew && inspection.data.storage != null ? inspection.data.storage.doors.deficiency : '',
            strRackScore: notNew && inspection.data.storage != null ? inspection.data.storage.racks.score : '',
            strRackDef: notNew && inspection.data.storage != null ? inspection.data.storage.racks.deficiency : '',
            strStorScore: notNew && inspection.data.storage != null ? inspection.data.storage.storage.score : '',
            strStorDef: notNew && inspection.data.storage != null ? inspection.data.storage.storage.deficiency: '',
            strTempScore: notNew && inspection.data.storage != null ? inspection.data.storage.temperature.score : '',
            strTempDef: notNew && inspection.data.storage != null ? inspection.data.storage.temperature.deficiency : '',
            strChemScore: notNew && inspection.data.storage != null ? inspection.data.storage.chemicalStorage.score : '',
            strChemDef: notNew && inspection.data.storage != null ? inspection.data.storage.chemicalStorage.deficiency : '',
            strPestScore: notNew && inspection.data.storage != null ? inspection.data.storage.pestControl.score : '',
            strPestDef: notNew && inspection.data.storage != null ? inspection.data.storage.pestControl.deficiency : '',
            extWallScore: notNew && inspection.data.exterior != null ? inspection.data.exterior.walls.score : '',
            extWallDef: notNew && inspection.data.exterior != null ? inspection.data.exterior.walls.deficiency : '',
            extWindScore: notNew && inspection.data.exterior != null ? inspection.data.exterior.windows.score : '',
            extWindDef: notNew && inspection.data.exterior != null ? inspection.data.exterior.windows.deficiency : '',
            extFenceScore: notNew && inspection.data.exterior != null ? inspection.data.exterior.fence.score : '',
            extFenceDef: notNew && inspection.data.exterior != null ? inspection.data.exterior.fence.deficiency : '',
            extGateScore: notNew && inspection.data.exterior != null ? inspection.data.exterior.gate.score : '',
            extGateDef: notNew && inspection.data.exterior != null ? inspection.data.exterior.gate.deficiency : '',
            user: inspection.data.user,
            reviewer: inspection.data.reviewer,
            signer: inspection.data.signer,
            status: inspection.data.status != null ? inspection.data.status : this.props.auth.constants.statuses.inprogress,
            locationName: locationName,
            signed: signed,
            canSign: canSign,
            documents: inspection.data.documents,
            number: inspection.data.number,
            statusSel: statusSel,
            strFloorScoreSel: strFloorScoreSel,
            strWallScoreSel: strWallScoreSel,
            strCeilScoreSel: strCeilScoreSel,
            strDoorScoreSel: strDoorScoreSel,
            strRackScoreSel: strRackScoreSel,
            strStorScoreSel: strStorScoreSel,
            strTempScoreSel: strTempScoreSel,
            strChemScoreSel: strChemScoreSel,
            strPestScoreSel: strPestScoreSel,
            extWallScoreSel: extWallScoreSel,
            extWindScoreSel: extWindScoreSel,
            extFenceScoreSel: extFenceScoreSel,
            extGateScoreSel: extGateScoreSel,
            fieldAccess: fieldAccess,
            optionsMenu: optionsMenu,
            flagForSignature: flagForSignature
        }, () => {
            const crumbs = [
                { path: '/#/locations', label: this.props.t('locations'), screen: this.props.auth.screenDefs.LocationList},
                { path: '/#/locationdetail' + this.props.match.params.locationId, label: this.props.t('locationDetails'), screen: this.props.auth.screenDefs.Location},
                { path: 'active', label: this.props.t('locationInspection') + ' - ' + inspection.data.number, screen: this.props.auth.screenDefs.LocationInspection}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { options: optionsMenu, title: t('options')}});
            canSign = this.canSign(this.state);
            this.setState({canSign});
        });        
    }

    canSign(state){
        if(state.date == null || state.date === '' || state.location == null || state.location === ''
            || state.score == null || state.score === '' || state.users == null || state.users.length === 0 ||
            state.strFloorScore == null || state.strFloorScore === '' || state.strWallScore == null ||
            state.strWallScore === '' || state.strCeilScore == null || state.strCeilScore === '' ||
            state.strDoorScore == null || state.strDoorScore === '' || state.strRackScore == null ||
            state.strRackScore === '' || state.strStorScore == null || state.strStorScore === '' ||
            state.strTempScore == null || state.strTempScore === '' || state.strChemScore == null ||
            state.strChemScore === '' || state.strPestScore == null || state.strPestScore === '' ||
            state.extWallScore == null || state.extWallScore === '' || state.extWindScore == null ||
            state.extWindScore === '' || state.extFenceScore == null || state.extFenceScore === '' ||
            state.extGateScore == null || state.extGateScore === '' )
            return false;
        for(const room of this.state.roomInspections){
            if(room.floors.score == null || room.floors.score === '' || room.walls.score == null || room.walls.score === '' ||
                room.ceiling.score == null || room.ceiling.score === '' || room.doors.score == null || room.doors.score === '')
                return false;
            for(const equip of room.equipmentInspections){
                if(equip.inspection.score == null || equip.inspection.score === '')
                return false;
            }
        }
        return true;
    }

    changeValue(e){
        let state = this.state;
        state[e.target.name] = e.target.value;
        const canSign = this.canSign(state);
        this.setState({[e.target.name]: e.target.value, canSign});
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    dateChange(e){
        let state = this.state;
        const canSign = this.canSign(state);
        this.setState({date: e, canSign});
    }

    approverDateChange(e){
        const t = this.props.t;
        let state = this.state;
        state.approverDate = e;
        const canSign = this.canSign(state);
        if (e !== '' && e < this.state.date){
            this.props.dispatch({
                type: 'CREATE_ERROR',
                payload: {
                    [t('approverDate')]: this.props.t('approveDateError')
                }
            });
        }
        this.setState({approverDate: e, canSign});
    }

    saveClick = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._saveClick());
    }

    _saveClick = async () => {
        const valid = this.validateForm();
        if(!valid) {
            this.setState({ enableSave: true });
            return;
        }
        let status = this.state.status;
        if( status === this.props.auth.constants.statuses.new ){
            status = this.props.auth.constants.statuses.inprogress;
        }
        let inspections = [];
        this.state.roomInspections.forEach(inspection => {
            let roomInsp = {floors: {}, walls: {}, ceiling: {}, doors: {}};
            if(inspection._id.length > 5)
                roomInsp._id = inspection._id;
            roomInsp.room = inspection.room._id;
            roomInsp.floors.score = inspection.floors.score !== '' ? inspection.floors.score : null;
            roomInsp.floors.deficiency = inspection.floors.deficiency;
            roomInsp.walls.score = inspection.walls.score !== '' ? inspection.walls.score : null;
            roomInsp.walls.deficiency = inspection.walls.deficiency;
            roomInsp.ceiling.score = inspection.ceiling.score !== '' ? inspection.ceiling.score : null;
            roomInsp.ceiling.deficiency = inspection.ceiling.deficiency;
            roomInsp.doors.score = inspection.doors.score !== '' ? inspection.ceiling.score : null;
            roomInsp.doors.deficiency = inspection.doors.deficiency;
            if(inspection.equipmentInspections.length != null && inspection.equipmentInspections.length > 0){
                let equipInsps = [];
                inspection.equipmentInspections.forEach(equip => {
                    let equipInsp = {inspection: {}};
                    if(equip._id.length > 5)
                        equipInsp._id = equip._id;
                    equipInsp.equipment = equip.equipment._id;
                    equipInsp.inspection.score = equip.inspection.score !== '' ? equip.inspection.score : null;
                    equipInsp.inspection.deficiency = equip.inspection.deficiency;
                    equipInsps.push(equipInsp);
                })
                roomInsp.equipmentInspections = equipInsps;
            }
            inspections.push(roomInsp);
        })
        const data = {
            _id: this.state._id,
            location: this.props.match.params.locationId,
            date: this.state.date,
            users: this.state.users,
            score: this.state.score,
            roomInspections: inspections,
            storage: {
                floors: {
                    score: this.state.strFloorScore !== '' ? this.state.strFloorScore : null,
                    deficiency: this.state.strFloorDef
                },
                walls: {
                    score: this.state.strWallScore !== '' ? this.state.strWallScore : null,
                    deficiency: this.state.strWallDef
                },
                ceiling: {
                    score: this.state.strCeilScore !== '' ? this.state.strCeilScore : null,
                    deficiency: this.state.strCeilDef
                },
                doors: {
                    score: this.state.strDoorScore !== '' ? this.state.strDoorScore : null,
                    deficiency: this.state.strDoorDef
                },
                racks: {
                    score: this.state.strRackScore !== '' ? this.state.strRackScore : null,
                    deficiency: this.state.strRackDef
                },
                storage: {
                    score: this.state.strStorScore !== '' ? this.state.strStorScore : null,
                    deficiency: this.state.strStorDef
                },
                temperature: {
                    score: this.state.strTempScore !== '' ? this.state.strTempScore : null,
                    deficiency: this.state.strTempDef
                },
                chemicalStorage: {
                    score: this.state.strChemScore !== '' ? this.state.strChemScore : null,
                    deficiency: this.state.strChemDef
                },
                pestControl: {
                    score: this.state.strPestScore !== '' ? this.state.strPestScore : null,
                    deficiency: this.state.strPestDef
                }
            },
            exterior: {
                walls: {
                    score: this.state.extWallScore !== '' ? this.state.extWallScore : null,
                    deficiency: this.state.extWallDef
                },
                windows: {
                    score: this.state.extWindScore !== '' ? this.state.extWindScore : null,
                    deficiency: this.state.extWindDef
                },
                fence: {
                    score: this.state.extFenceScore !== '' ? this.state.extFenceScore : null,
                    deficiency: this.state.extFenceDef
                },
                gate: {
                    score: this.state.extGateScore !== '' ? this.state.extGateScore : null,
                    deficiency: this.state.extGateDef
                }
            },
            approver: this.state.approver,
            approverDate: this.state.approverDate,
            approverSignature: this.state.approverSignature,
            schedule: this.state.schedule,
            status: status
        }
        try {
            const result = await axios.post('/api/locationinspection', data)
            const statusSel = this.state.statuses.find(x => x.value === result.data.status);
            const completeSigSec = completeSigSecurity(this.props.permission.complete, result.data.receiver, result.data.status, this.props.auth.common, this.props.auth.constants);
            const reviewSigSec = reviewSigSecurity(this.props.permission.review, result.data.reviewer, result.data.status, this.props.auth.common, this.props.auth.constants);
            const approveSigSec = approveSigSecurity(this.props.permission.approve, result.data.signer, result.data.status, this.props.auth.common, this.props.auth.constants);
            if(this.state._id == null || this.state._id === '')
                this.props.history.replace('/locationinspection/' + result.data.id);
            this.setState({
                _id: result.data.id, 
                number: result.data.number,
                status: result.data.status, 
                statusSel: statusSel, 
                completeSigSec: completeSigSec,
                reviewSigSec: reviewSigSec,
                approveSigSec: approveSigSec,
                enableSave: true
            });
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: this.props.t('success'), severity: 'success'}});
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: this.props.t('error'), severity: 'error'}});
            });            
        }
    }

    validateForm(){
        const t = this.props.t;
        let errors = {};
        if(this.state.date == null || this.state.date === '')
            errors[t('date')] = t('required');
        if(this.state.users == null || this.state.users.length === 0)
            errors[t('users')] = t('required');
        if(this.state.strFloorScore != null & this.state.strFloorScore !== '' && this.state.strFloorScore === this.props.auth.constants.testResults.fail
            && this.state.strFloorDef === '')
            errors[t('strFloorDef')] = t('required');
        if(this.state.strWallScore != null && this.state.strWallScore !== '' && this.state.strWallScore === this.props.auth.constants.testResults.fail
            && this.state.strWallDef === '')
            errors[t('strWallDef')] = t('required');
        if(this.state.strCeilScore != null && this.state.strCeilScore !== '' && this.state.strCeilScore === this.props.auth.constants.testResults.fail
            && this.state.strCeilDef === '')
            errors[t('strCeilDef')] = t('required');
        if(this.state.strDoorScore != null && this.state.strDoorScore !== '' && this.state.strDoorScore === this.props.auth.constants.testResults.fail
            && this.state.strDoorDef === '')
            errors[t('strDoorDef')] = t('required');
        if(this.state.strRackScore != null && this.state.strRackScore !== '' && this.state.strRackScore === this.props.auth.constants.testResults.fail
            && this.state.strRackDef === '')
            errors[t('strRackDef')] = t('required');
        if(this.state.strStorScore != null && this.state.strStorScore !== '' && this.state.strStorScore === this.props.auth.constants.testResults.fail
            && this.state.strStorScore === '')
            errors[t('strStorScore')] = t('required');
        if(this.state.strTempScore != null && this.state.strTempScore !== '' && this.state.strTempScore === this.props.auth.constants.testResults.fail
            && this.state.strTempDef === '')
            errors[t('strTempDef')] = t('required');
        if(this.state.strChemScore != null && this.state.strChemScore !== '' && this.state.strChemScore === this.props.auth.constants.testResults.fail
            && this.state.strChemDef === '')
            errors[t('strChemDef')] = t('required');
        if(this.state.strPestScore != null && this.state.strPestScore !== '' && this.state.strPestScore === this.props.auth.constants.testResults.fail
            && this.state.strPestDef === '')
            errors[t('strPestDef')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    editRoomClick(id){
        let inspection = null;
        for(const room of this.state.roomInspections){
            if(room._id === id){
                inspection = room;
                break;
            }
        }
        const floorsSel = this.state.scores.find(x => x.value === inspection.floors.score);
        const wallsSel = this.state.scores.find(x => x.value === inspection.walls.score);
        const ceilingSel = this.state.scores.find(x => x.value === inspection.ceiling.score);
        const doorsSel = this.state.scores.find(x => x.value === inspection.doors.score);
        this.setState({
            roomEdit: true,
            inspectionId: inspection._id,
            room: inspection.room,
            floors: inspection.floors.score,
            floorDeficiency: inspection.floors.deficiency,
            walls: inspection.walls.score,
            wallDeficiency: inspection.walls.deficiency,
            ceiling: inspection.ceiling.score,
            ceilingDeficiency: inspection.ceiling.deficiency,
            doors: inspection.doors.score,
            doorDeficiency: inspection.doors.deficiency,
            equipmentInspections: inspection.equipmentInspections,
            floorsSel: floorsSel,
            wallsSel: wallsSel,
            ceilingSel: ceilingSel,
            doorsSel: doorsSel
        })
    }

    toggleRoom() {
        this.setState({ roomEdit: !this.state.roomEdit });
    }

    editEquipment(id){
        let equipment = null;
        for(const equip of this.state.equipmentInspections){
            if(equip._id === id){
                equipment = equip;
                break;
            }
        }
        const equipmentSel = this.state.scores.find(x => x.value === equipment.inspection.score);
        this.setState({
            equipmentEdit: true,
            equipmentId: equipment._id,
            equipment: equipment.equipment,
            equipmentScore: equipment.inspection.score,
            equipmentDeficiency: equipment.inspection.deficiency,
            equipmentSel: equipmentSel
        })
    }

    saveRoom(){
        const valid = this.validateRoom();
        if(!valid)
            return;
        let inspection = null;
        let roomInspections = this.state.roomInspections;
        for(const room of roomInspections){
            if(this.state.inspectionId === room._id){
                inspection = room;
                break;
            }
        }
        inspection.floors.score = this.state.floors;
        inspection.floors.deficiency = this.state.floorDeficiency;
        inspection.walls.score = this.state.walls;
        inspection.walls.deficiency = this.state.wallDeficiency;
        inspection.ceiling.score = this.state.ceiling;
        inspection.ceiling.deficiency = this.state.ceilingDeficiency;
        inspection.doors.score = this.state.doors;
        inspection.doors.deficiency = this.state.doorDeficiency;
        inspection.status = "Done";
        this.setState({roomInspections: roomInspections});
        this.clearRoom();
    }

    validateRoom(){
        let errors = {};
        const t = this.props.t;
        if(this.state.floors == null || this.state.floors === '')
            errors[t('floors')] = t('required');
        if(this.state.floors === this.props.auth.constants.testResults.fail && 
            (this.state.floorDeficiency == null || this.state.floorDeficiency === ''))
            errors[t('floorDeficiency')] = t('required');
        if(this.state.walls == null || this.state.walls === '')
            errors[t('walls')] =t('required');
        if(this.state.walls === this.props.auth.constants.testResults.fail && 
            (this.state.wallDeficiency == null || this.state.wallDeficiency === ''))
            errors[t('wallDeficiency')] = t('required');
        if(this.state.ceiling == null || this.state.ceiling === '')
            errors[t('ceiling')] = t('required');
        if(this.state.ceiling === this.props.auth.constants.testResults.fail && 
            (this.state.ceilingDeficiency == null || this.state.ceilingDeficiency === ''))
            errors[t('ceilingDeficiency')] = t('required');
        if(this.state.doors == null || this.state.doors === '')
            errors[t('doors')] = t('required');
        if(this.state.doors === this.props.auth.constants.testResults.fail && 
            (this.state.doorDeficiency == null || this.state.doorDeficiency === ''))
            errors[t('doorDeficiency')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }
  
    saveEquipment(){
        const valid = this.validateEquipment();
        if(!valid)
            return;
        const equipmentInspections = this.state.equipmentInspections;
        let inspection = null;
        for(const equipment of equipmentInspections){
            if(this.state.equipmentId === equipment._id){
                inspection = equipment;
                break;
            }
        }
        inspection.inspection.score = this.state.equipmentScore;
        inspection.inspection.deficiency = this.state.equipmentDeficiency;
        inspection.status = this.props.t('done');
        this.setState({equipmentInspections: equipmentInspections})
        this.clearEquipment();
        
    }

    validateEquipment(){
        const t = this.props.t;
        let errors = {};
        if(this.state.equipmentScore == null || this.state.equipmentScore === '')
            errors[t('equipmentScore')] = this.props.t('required');
        if(this.state.equipmentScore === this.props.auth.constants.testResults.fail && 
            (this.state.equipmentDeficiency == null || this.state.equipmentDeficiency === ''))
            errors[t('equipmentDeficiency')] = this.props.t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            return false;
        }else {
            if(!isEmpty(this.props.errors)) this.props.dispatch({ type: 'CLEAR_ERROR' });
            return true;
        }
    }

    clearRoom(){
        this.setState({
            roomEdit: false,
            inspectionId: '',
            room: {},
            floors: '',
            floorDeficiency: '',
            wals: '',
            wallDeficiency: '',
            ceiling: '',
            ceilingDeficiency: '',
            doors: '',
            doorDeficiency: '',
            equipmentInspections: []
        })
    }

    clearEquipment(){
        this.setState({
            equipmentEdit: false,
            equipmentId: '',
            equipment: {},
            equipmentScore: '',
            equipmentDeficiency: ''
        })
    }

    changeUsers = (e, selectedUsers) => {
        let state = this.state;
        let users = [];
        if(selectedUsers != null){
            selectedUsers.forEach(user =>{
                users.push(user.value);
            })
        }
        state.users = users;
        let canSign = this.canSign(state);
        this.setState({selectedUsers, users, canSign});
    }

    refresh = e => {
        this.loadRecord();
    }

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    toggleDialog = () => {
        let dialogOpen = this.state.dialogOpen;
        this.setState({ dialogOpen: !dialogOpen });
    };

    render(){
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const security = this.state.security;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar goBack={this.state.goBack}>
                    <ActionBar
                        fieldAccess={security.update}
                        scheduleClick={this.state.schedule != null && this.state.schedule !== '' ? this.toggleDialog : null}
                        optionsAvailable={this.state.optionsMenu.length !== 0}
                        flagForSignature={this.state.flagForSignature}
                        id={this.state._id}
                        objectType={this.props.auth.constants.objectIds.locationInspection}
                        enableAttachments={this.state._id != null && this.state._id !== '' ? true : false}
                        // finalDocuments={this.state.finalDocument}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TabPanel value={this.state.tabKey} index={0}>
                            <Aux>
                                <Grid container spacing={3}>
                                    <Grid container item spacing={1}>
                                        <Grid item xs={6} sm={3}>
                                            <TextField value={this.state.statusSel != null ? this.state.statusSel.label : ''}  name="status"
                                            size="medium" fullWidth={true} disabled={true} label={t('status')}/>
                                        </Grid>
                                        {this.state._id != null && this.state._id !== '' &&
                                            <Grid item xs={6} sm={3}>
                                                <TextField value={this.state.number}  name="number"
                                                size="medium" fullWidth={true} disabled={true} label={t('number')}/>
                                            </Grid>
                                        }
                                        <Grid item xs={6} sm={3}>
                                            <DateSelect
                                                onChange={this.dateChange}
                                                value={this.state.date}
                                                helperText={errors[t('date')]}
                                                error={errors[t('date')] != null ? true : false}
                                                disabled={!fieldAccess}
                                                label={t('date')}
                                                format={this.props.auth.user.dateFormat}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <AutoCompleteField
                                                value={this.state.scoreSel}
                                                options={this.state.scores}
                                                onChange={this.changeAuto('scoreSel', 'score')}
                                                error={errors[t('score')] != null ? true : false}
                                                helperText={errors[t('score')]}
                                                label={t('score')}
                                                disabled={!fieldAccess}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={10} sm={8}>
                                        <AutoCompleteField
                                            multiple={true}
                                            value={this.state.selectedUsers}
                                            options={this.state.userOptions}
                                            onChange={this.changeUsers}
                                            error={errors[t('users')] != null ? true : false}
                                            helperText={errors[t('users')]}
                                            label={t('users')}
                                            disabled={!fieldAccess}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </Aux>
                        </TabPanel>
                        <TabPanel value={this.state.tabKey} index={1}>
                            <SectionTitle title={t('rooms')} />
                            <TableContainer component={Paper}>
                                <Table aria-label="Rooms">
                                    <TableHead>
                                        <TableRow key="tableHeader">
                                            <TableCell>{t('room')}</TableCell>
                                            <TableCell>{t('status')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.roomInspections.map((row, i) =>
                                            <TableRow key={row._id}>
                                                <TableCell>
                                                    <TextOnlyClickButton enabled={true} click={() => this.editRoomClick(row._id)} name={row.room.name}/>
                                                </TableCell>
                                                <TableCell>{row.status}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {this.state.roomEdit &&
                                <Aux>
                                    <Dialog open={this.state.roomEdit} onClose={() => this.toggleRoom()} aria-labelledby="dialog-room-inspection">
                                        <DialogTitle aria-labelledby="dialog-room-inspection-title"><SubSectionHeading title={`${this.state.room.name}`} /></DialogTitle>
                                        <DialogContent>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6}>
                                                    <AutoCompleteField
                                                        value={this.state.floorsSel}
                                                        options={this.state.scores}
                                                        onChange={this.changeAuto('floorsSel', 'floors')}
                                                        error={errors[t('floors')] != null ? true : false}
                                                        helperText={errors[t('floors')]}
                                                        label={t('floors')}
                                                        disabled={!fieldAccess}
                                                        required
                                                    />
                                                </Grid>
                                                {this.state.floors === this.props.auth.constants.testResults.fail &&
                                                    <Grid item xs={8}>
                                                        <TextField value={this.state.floorDeficiency} onChange={this.changeValue} name="floorDeficiency"
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('floorDeficiency')}
                                                        required={this.state.floors === this.props.auth.constants.testResults.fail}
                                                        error={errors[t('floorDeficiency')] != null ? true : false} helperText={errors[t('floorDeficiency')]}/>
                                                    </Grid>
                                                }
                                                <Grid item xs={6}>
                                                    <AutoCompleteField
                                                        value={this.state.wallsSel}
                                                        options={this.state.scores}
                                                        onChange={this.changeAuto('wallsSel', 'walls')}
                                                        error={errors[t('walls')] != null ? true : false}
                                                        helperText={errors[t('walls')]}
                                                        label={t('walls')}
                                                        disabled={!fieldAccess}
                                                        required
                                                    />
                                                </Grid>
                                                {this.state.walls === this.props.auth.constants.testResults.fail &&
                                                    <Grid item xs={8}>
                                                        <TextField value={this.state.wallDeficiency} onChange={this.changeValue} name="wallDeficiency"
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('wallDeficiency')}
                                                        required={this.state.walls === this.props.auth.constants.testResults.fail}
                                                        error={errors[t('wallDeficiency')] != null ? true : false} helperText={errors[t('wallDeficiency')]}/>
                                                    </Grid>
                                                }
                                                <Grid item xs={6}>
                                                    <AutoCompleteField
                                                        value={this.state.ceilingSel}
                                                        options={this.state.scores}
                                                        onChange={this.changeAuto('ceilingSel', 'ceiling')}
                                                        error={errors[t('ceiling')] != null ? true : false}
                                                        helperText={errors[t('ceiling')]}
                                                        label={t('ceiling')}
                                                        disabled={!fieldAccess}
                                                        required
                                                    />
                                                </Grid>
                                                {this.state.ceiling === this.props.auth.constants.testResults.fail &&
                                                    <Grid item xs={8}>
                                                        <TextField value={this.state.ceilingDeficiency} onChange={this.changeValue} name="ceilingDeficiency"
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('ceilingDeficiency')}
                                                        required={this.state.ceiling === this.props.auth.constants.testResults.fail}
                                                        error={errors[t('ceilingDeficiency')] != null ? true : false} helperText={errors[t('ceilingDeficiency')]}/>
                                                    </Grid>
                                                }
                                                <Grid item xs={6}>
                                                    <AutoCompleteField
                                                        value={this.state.doorsSel}
                                                        options={this.state.scores}
                                                        onChange={this.changeAuto('doorsSel', 'doors')}
                                                        error={errors[t('doors')] != null ? true : false}
                                                        helperText={errors[t('doors')]}
                                                        label={t('doors')}
                                                        disabled={!fieldAccess}
                                                        required
                                                    />
                                                </Grid>
                                                {this.state.doors === this.props.auth.constants.testResults.fail &&
                                                    <Grid item xs={8}>
                                                        <TextField value={this.state.doorDeficiency} onChange={this.changeValue} name="doorDeficiency"
                                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('doorDeficiency')}
                                                        required={this.state.doors === this.props.auth.constants.testResults.fail}
                                                        error={errors[t('doorDeficiency')] != null ? true : false} helperText={errors[t('doorDeficiency')]}/>
                                                    </Grid>
                                                }
                                            </Grid>
                                            {this.state.equipmentInspections != null && this.state.equipmentInspections.length > 0 &&
                                                <Aux>
                                                    <br/>
                                                    <SubSectionHeading title={t('equipmentInspections')}/>
                                                    <TableContainer component={Paper}>
                                                        <Table aria-label="Equipment Inspections">
                                                            <TableHead>
                                                                <TableRow key="tableHeader">
                                                                    <TableCell>{t('name')}</TableCell>
                                                                    <TableCell>{t('status')}</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {this.state.equipmentInspections.map((row, i) =>
                                                                    <TableRow key={row._id}>
                                                                        <TableCell>
                                                                            <TextOnlyClickButton enabled={true} click={() => this.editEquipment(row._id)} 
                                                                            name={row.equipment.name}/>
                                                                        </TableCell>
                                                                        <TableCell>{row.status}</TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Aux>
                                            }
                                            {this.state.equipmentEdit &&
                                                <Box 
                                                    component="div" 
                                                    borderRadius="borderRadius"
                                                    padding={1}
                                                    sx={{
                                                        border: "1px solid #9E9E9E", 
                                                        marginTop: "16px",
                                                    }}>
                                                    <SubSectionHeading title={this.state.equipment.name} />
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <AutoCompleteField
                                                                value={this.state.equipmentSel}
                                                                options={this.state.scores}
                                                                onChange={this.changeAuto('equipmentSel', 'equipmentScore')}
                                                                error={errors[t('equipmentScore')] != null ? true : false}
                                                                helperText={errors[t('equipmentScore')]}
                                                                label={t('score')}
                                                                disabled={!fieldAccess}
                                                            />
                                                        </Grid>
                                                        {this.state.equipmentScore === this.props.auth.constants.testResults.fail &&
                                                            <Grid item xs={8}>
                                                                <TextField value={this.state.equipmentDeficiency} onChange={this.changeValue} name="equipmentDeficiency"
                                                                size="medium" fullWidth={true} disabled={!fieldAccess} label={t('deficiency')}
                                                                error={errors[t('equipmentDeficiency')] != null ? true : false} helperText={errors[t('equipmentDeficiency')]}/>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                    <Grid container sx={{ alignContent: "flex-start", alignItems:"flex-start" }} spacing={1}>
                                                        <Grid item xs={6}>
                                                            <SaveButton saveClick={() => this.saveEquipment()} enabled={fieldAccess} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <ClearButton clearClick={() => this.clearEquipment()} enabled={fieldAccess} />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            }
                                            </DialogContent>
                                        <DialogActions>
                                            <SaveButton saveClick={() => this.saveRoom()} enabled={fieldAccess} />
                                            <ClearButton clearClick={() => this.clearRoom()} enabled={fieldAccess} />
                                        </DialogActions>
                                    </Dialog>
                                </Aux>
                            }
                        </TabPanel>
                        <TabPanel value={this.state.tabKey} index={2}>
                            <SectionTitle title={t('storageAreas')} />
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.strFloorScoreSel}
                                        options={this.state.scores}
                                        onChange={this.changeAuto('strFloorScoreSel', 'strFloorScore')}
                                        error={errors[t('strFloorScore')] != null ? true : false}
                                        helperText={errors[t('strFloorScore')]}
                                        label={t('floors')}
                                        disabled={!fieldAccess}
                                    />
                                </Grid>
                                {this.state.strFloorScore === this.props.auth.constants.testResults.fail &&
                                    <Grid item xs={8} sm={4}>
                                        <TextField value={this.state.strFloorDef} onChange={this.changeValue} name="strFloorDef"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('floorDeficiency')}
                                        required={this.state.strFloorScore != null && this.state.strFloorScore !== '' && 
                                                  this.state.strFloorScore === this.props.auth.constants.testResults.fail}
                                        error={errors[t('strFloorDef')] != null ? true : false} helperText={errors[t('strFloorDef')]}/>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.strWallScoreSel}
                                        options={this.state.scores}
                                        onChange={this.changeAuto('strWallScoreSel', 'strWallScore')}
                                        error={errors[t('strWallScore')] != null ? true : false}
                                        helperText={errors[t('strWallScore')]}
                                        label={t('walls')}
                                        disabled={!fieldAccess}
                                    />
                                </Grid>
                                {this.state.strWallScore === this.props.auth.constants.testResults.fail &&
                                    <Grid item xs={8} sm={4}>
                                        <TextField value={this.state.strWallDef} onChange={this.changeValue} name="strWallDef"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('wallDeficiency')}
                                        required={this.state.strWallScore != null && this.state.strWallScore !== '' && 
                                                  this.state.strWallScore === this.props.auth.constants.testResults.fail}
                                        error={errors[t('strWallDef')] != null ? true : false} helperText={errors[t('strWallDef')]}/>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.strDoorScoreSel}
                                        options={this.state.scores}
                                        onChange={this.changeAuto('strDoorScoreSel', 'strDoorScore')}
                                        error={errors[t('strDoorScore')] != null ? true : false}
                                        helperText={errors[t('strDoorScore')]}
                                        label={t('doors')}
                                        disabled={!fieldAccess}
                                    />
                                </Grid>
                                {this.state.strDoorScore === this.props.auth.constants.testResults.fail &&
                                    <Grid item xs={8} sm={4}>
                                        <TextField value={this.state.strDoorDef} onChange={this.changeValue} name="strDoorDef"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('doorDeficiency')}
                                        required={this.state.strDoorScore != null && this.state.strDoorScore !== '' && 
                                                  this.state.strDoorScore === this.props.auth.constants.testResults.fail}
                                        error={errors[t('strDoorDef')] != null ? true : false} helperText={errors[t('strDoorDef')]}/>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.strRackScoreSel}
                                        options={this.state.scores}
                                        onChange={this.changeAuto('strRackScoreSel', 'strRackScore')}
                                        error={errors[t('strRackScore')] != null ? true : false}
                                        helperText={errors[t('strRackScore')]}
                                        label={t('rack')}
                                        disabled={!fieldAccess}
                                    />
                                </Grid>
                                {this.state.strRackScore === this.props.auth.constants.testResults.fail &&
                                    <Grid item xs={8} sm={4}>
                                        <TextField value={this.state.strRackDef} onChange={this.changeValue} name="strRackDef"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('rackDeficiency')}
                                        required={this.state.strRackScore != null && this.state.strRackScore !== '' && 
                                                  this.state.strRackScore === this.props.auth.constants.testResults.fail}
                                        error={errors[t('strRackDef')] != null ? true : false} helperText={errors[t('strRackDef')]}/>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.strStorScoreSel}
                                        options={this.state.scores}
                                        onChange={this.changeAuto('strStorScoreSel', 'strStorScore')}
                                        error={errors[t('strStorScore')] != null ? true : false}
                                        helperText={errors[t('strStorScore')]}
                                        label={t('storage')}
                                        disabled={!fieldAccess}
                                    />
                                </Grid>
                                {this.state.strStorScore === this.props.auth.constants.testResults.fail &&
                                    <Grid item xs={8} sm={4}>
                                        <TextField value={this.state.strStorDef} onChange={this.changeValue} name="strStorDef"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('deficiency')}
                                        required={this.state.strStorScore != null && this.state.strStorScore !== '' && 
                                                  this.state.strStorScore === this.props.auth.constants.testResults.fail}
                                        error={errors[t('strStorDef')] != null ? true : false} helperText={errors[t('strStorDef')]}/>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.strTempScoreSel}
                                        options={this.state.scores}
                                        onChange={this.changeAuto('strTempScoreSel', 'strTempScore')}
                                        error={errors[t('strTempScore')] != null ? true : false}
                                        helperText={errors[t('strTempScore')]}
                                        label={t('temperature')}
                                        disabled={!fieldAccess}
                                    />
                                </Grid>
                                {this.state.strTempScore === this.props.auth.constants.testResults.fail &&
                                    <Grid iten xs={8} sm={4}>
                                        <TextField value={this.state.strTempDef} onChange={this.changeValue} name="strTempDef"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('deficiency')}
                                        required={this.state.strTempScore != null && this.state.strTempScore !== '' && 
                                                  this.state.strTempScore === this.props.auth.constants.testResults.fail}
                                        error={errors[t('strTempDef')] != null ? true : false} helperText={errors[t('strTempDef')]}/>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.strChemScoreSel}
                                        options={this.state.scores}
                                        onChange={this.changeAuto('strChemScoreSel', 'strChemScore')}
                                        error={errors[t('strChemScore')] != null ? true : false}
                                        helperText={errors[t('strChemScore')]}
                                        label={t('chemicalStorage')}
                                        disabled={!fieldAccess}
                                    />
                                </Grid>
                                {this.state.strChemScore === this.props.auth.constants.testResults.fail &&
                                    <Grid item xs={8} sm={4}>
                                        <TextField value={this.state.strChemDef} onChange={this.changeValue} name="strChemDef"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('deficiency')}
                                        required={this.state.strChemScore != null && this.state.strChemScore !== '' && 
                                                  this.state.strChemScore === this.props.auth.constants.testResults.fail}
                                        error={errors[t('strChemDef')] != null ? true : false} helperText={errors[t('strChemDef')]}/>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.strPestScoreSel}
                                        options={this.state.scores}
                                        onChange={this.changeAuto('strPestScoreSel', 'strPestScore')}
                                        error={errors[t('strPestScore')] != null ? true : false}
                                        helperText={errors[t('strPestScore')]}
                                        label={t('pestControl')}
                                        disabled={!fieldAccess}
                                    />
                                </Grid>
                                {this.state.strPestScore === this.props.auth.constants.testResults.fail &&
                                    <Grid item xs={8} sm={4}>
                                        <TextField value={this.state.strPestDef} onChange={this.changeValue} name="strPestDef"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('deficiency')}
                                        required={this.state.strPestScore != null && this.state.strPestScore !== '' && 
                                                  this.state.strPestScore === this.props.auth.constants.testResults.fail}
                                        error={errors[t('strPestDef')] != null ? true : false} helperText={errors[t('strPestDef')]}/>
                                    </Grid>
                                }
                            </Grid>
                        </TabPanel>
                        <TabPanel value={this.state.tabKey} index={3}>
                            <SectionTitle title={t('exterior')} />
                            <Grid container spacing={3}>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.extWallScoreSel}
                                        options={this.state.scores}
                                        onChange={this.changeAuto('extWallScoreSel', 'extWallScore')}
                                        error={errors[t('extWallScore')] != null ? true : false}
                                        helperText={errors[t('extWallScore')]}
                                        label={t('walls')}
                                        disabled={!fieldAccess}
                                    />
                                </Grid>
                                {this.state.extWallScore === this.props.auth.constants.testResults.fail &&
                                    <Grid item xs={8} sm={4}>
                                        <TextField value={this.state.extWallDef} onChange={this.changeValue} name="extWallDef"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('deficiency')}
                                        required={this.state.extWallScore != null && this.state.extWallScore !== '' && 
                                                  this.state.extWallScore === this.props.auth.constants.testResults.fail}
                                        error={errors[t('extWallDef')] != null ? true : false} helperText={errors[t('extWallDef')]}/>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.extWindScoreSel}
                                        options={this.state.scores}
                                        onChange={this.changeAuto('extWindScoreSel', 'extWindScore')}
                                        error={errors[t('extWindScore')] != null ? true : false}
                                        helperText={errors[t('extWindScore')]}
                                        label={t('windows')}
                                        disabled={!fieldAccess}
                                    />
                                </Grid>
                                {this.state.extWindScore === this.props.auth.constants.testResults.fail &&
                                    <Grid item xs={8} sm={4}>
                                        <TextField value={this.state.extWindDef} onChange={this.changeValue} name="extWindDef"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('deficiency')}
                                        required={this.state.extWindScore != null && this.state.extWindScore !== '' && 
                                                  this.state.extWindScore === this.props.auth.constants.testResults.fail}
                                        error={errors[t('extWindDef')] != null ? true : false} helperText={errors[t('extWindDef')]}/>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.extFenceScoreSel}
                                        options={this.state.scores}
                                        onChange={this.changeAuto('extFenceScoreSel', 'extFenceScore')}
                                        error={errors[t('extFenceScore')] != null ? true : false}
                                        helperText={errors[t('extFenceScore')]}
                                        label={t('fence')}
                                        disabled={!fieldAccess}
                                    />
                                </Grid>
                                {this.state.extFenceScore === this.props.auth.constants.testResults.fail &&
                                    <Grid item xs={8} sm={4}>
                                        <TextField value={this.state.extFenceDef} onChange={this.changeValue} name="extFenceDef"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('deficiency')}
                                        required={this.state.extFenceScore != null && this.state.extFenceScore !== '' && 
                                                  this.state.extFenceScore === this.props.auth.constants.testResults.fail}
                                        error={errors[t('extFenceDef')] != null ? true : false} helperText={errors[t('extFenceDef')]}/>
                                    </Grid>
                                }
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={this.state.extGateScoreSel}
                                        options={this.state.scores}
                                        onChange={this.changeAuto('extGateScoreSel', 'extGateScore')}
                                        error={errors[t('extGateScore')] != null ? true : false}
                                        helperText={errors[t('extGateScore')]}
                                        label={t('gate')}
                                        disabled={!fieldAccess}
                                    />
                                </Grid>
                                {this.state.extGateScore === this.props.auth.constants.testResults.fail &&
                                    <Grid item xs={8} sm={4}>
                                        <TextField value={this.state.extGateDef} onChange={this.changeValue} name="extGateDef"
                                        size="medium" fullWidth={true} disabled={!fieldAccess} label={t('deficiency')}
                                        required={this.state.extGateScore != null && this.state.extGateScore !== '' && 
                                                  this.state.extGateScore === this.props.auth.constants.testResults.fail}
                                        error={errors[t('extGateDef')] != null ? true : false} helperText={errors[t('extGateDef')]}/>
                                    </Grid>
                                }
                            </Grid>
                            
                        </TabPanel>
                    </Grid>
                </Grid>
                {this.state.dialogOpen &&
                    <ScheduleDialog
                        id={this.state.schedule}
                        fieldAccess={security.update}
                        reload={this.loadRecord}
                        permission={this.state.security}
                        open={this.state.dialogOpen}
                        onClose={() => this.toggleDialog()}
                    />
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.locationInspection}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(LocationInspection));