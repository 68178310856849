import { SAVE_HELP_CACHE, SET_HELP_CURRENTPAGE, SET_MENU_OPTIONS, SAVE_SECURITY, 
         SET_SECURITY, SET_CONSTANTS, SAVE_CACHE, RESET_SECURITY } from './types';

export const newPage = (data, securityCache, helpCache, path) => dispatch => {
    let sCache = securityCache;
    let hCache = helpCache;
    let security = data.security;
    let help = data.help;
    security.path = path;
    help.path = path;
    sCache.push(security);    
    let exists = hCache.find(cache => cache.path === help.path);
    if(!exists)
      hCache.push(help);
    dispatch(saveSecurity(security, sCache));
    dispatch({ type: SAVE_HELP_CACHE, payload: {current: help, cache: hCache}});
};

export const changePage = (path, securityCache, helpCache) => dispatch => {
  let sCache = securityCache;
  let hCache = helpCache;

  let currentSecurity = sCache.find(security => security.path === path);  
  let currentHelp = hCache.find(help => help.path === path);
  dispatch(setSecurity(currentSecurity));
  dispatch({ type: SET_HELP_CURRENTPAGE, payload: { current: currentHelp }});
};

export const removePath = (path, cache) => dispatch => {
  let existingCache = cache;
  const index = existingCache.findIndex(screen => screen.path === path);
  if(index !== -1){
    existingCache.splice(index, 1);
    dispatch(saveCache(existingCache));
  }
}

export const setMenuOptions = menuOptions => ({
  type: SET_MENU_OPTIONS,
  payload: {
    menuOptions: menuOptions
  }
});

export const saveCache = cache => ({
  type: SAVE_CACHE,
  payload: {
    cache: cache
  }
})

export const saveSecurity = (current, cache) => ({
  type: SAVE_SECURITY,
  payload: {
    current: current,
    cache: cache
  }
});

export const setSecurity = current => ({
  type: SET_SECURITY,
  payload: {
    current: current
  }
});

export const setConstants = tenant => ({
  type: SET_CONSTANTS,
  payload: {
    tenant: tenant
  }
});

export const resetSecurity = () => ({
  type: RESET_SECURITY,
});
