import React from 'react';
import { useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTheme } from '@mui/material/styles';

function isISO8601(dateString) {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(([+-]\d{2}:\d{2})|Z)?$/;
  return regex.test(dateString);
}

export default function DateSelect(props) {
  const { label, onChange, value, helperText, error, disabled, required = false} = props;
  const theme = useTheme();
  
  const dateFormat = useSelector(state => state.auth.user.dateFormat);
  let newFormat = 'd/M/yyyy';
  if(dateFormat != null && dateFormat !== '') {
    newFormat = dateFormat.replace('mm', 'MM');
  }

  let dateValue = null;
  if(value != null && value !== ''){
    const adapter = new AdapterDateFns();
    if(isISO8601(value)) {
        dateValue = adapter.parseISO(value);
    }else if(value instanceof Date){
        dateValue = value;
    }else {
        dateValue = null;
        onChange(null)
    }
  }

  return (
    <FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          closeOnSelect
          format={newFormat}
          id={"date-picker-inline-" + label}
          name={"date-picker-inline-" + label}
          label={label}
          value={dateValue}
          onChange={onChange}
          disabled={disabled}
          slotProps={{
            textField: { 
              helperText: helperText,
              variant: 'standard', 
              required: required,
              error: error,
              clearable: true, 
              onClear: () => onChange(null), 
            },
            openPickerIcon: {
              style: { color: error ? theme.palette.error.main : 'grey' }              
            }
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );  
};