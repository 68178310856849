import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Aux from '../../hoc/Auxilary/Auxilary';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Link } from 'react-router-dom';
import DateDisplay from '../UI/DateDisplay/DateDisplay';
import AutoCompleteField from '../UI/AutoCompleteField/AutoCompleteField';
import FormButtonStack from '../UI/Buttons/FormButtonStack';
import { fieldSecurity} from '../../helpers/securityHelper';
import isEmpty from '../../is-empty';
import Button from '@mui/material/Button';
import DateSelect from '../UI/DateSelect/DateSelect';
import TextField from '../UI/TextField/TextField';
import ActionBar from '../UI/Buttons/ActionBar';
import { NumericFormat } from 'react-number-format';
import { withTranslation } from 'react-i18next';
import TablePagination from '@mui/material/TablePagination';
import Comments from '../General/Comments';
import ObjectLog from '../General/ObjectLog';
import common from '../../jsons/common.json';
import ItemLedgerComponent from './ItemLedgerComponent';
import { EvoraTabs, TabCanvas } from '../UI/Tabs/Tabs';
import ValueHelper from '../../helpers/valueHelper';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IrradiatedIcon from 'mdi-material-ui/Radioactive';
import Tooltip from '@mui/material/Tooltip';
import ModalDialog from '../UI/Dialog/ModalDialog';
import Panel from '../UI/Panel/Panel';
import BoxTitle from '../UI/Typography/BoxTitle';
import Divider from '@mui/material/Divider';
import BreadcrumbBar from '../Navigation/Breadcrumbs/Breadcrumb';
import ConversionHelper from '../../helpers/conversionHelper';
import { CSVDownload } from "react-csv";
import ExcelIcon from 'mdi-material-ui/MicrosoftExcel';

class Container extends Component {
    constructor(props){
        super(props);
        const t = this.props.t;
        this.state = {
            _id: '',
            number: '',
            lot: '',
            item: '',
            quantity: '',
            uom: '',
            uomSel: [],
            uoms: [],
            tareWeight: '',
            totalWeight: '',
            transactions: [],
            status: '',
            originalStatus: '',
            statusSel: {
                value: '',
                label: '',
            },
            statuses: [],
            destroy: false,
            destructionReasons: [],
            destroyReason: '',
            destroyReasonSel: null,
            fieldAccess: false,
            showSplit: false,
            splitQuantity: '',
            splitCount: '',
            date: new Date(),
            splitBin: '',
            splitBinSel: null,
            bins: [],
            splitTare: '',
            splitTotal: '',
            splitCountTotal: '',
            newOrigTotal: '',
            showMove: false,
            moveBin: '',
            moveBinSel: null,
            bin: null,
            type: '',
            typeSel: null,
            types: [],
            splitType: '',
            splitTypeBin: null,
            irradiated: false,
            security: [],
            count: '',
            strain: null,
            expires: null,
            enableSave: true,
            discreteWeight: '',
            fullDiscreteWeight: '',
            transPage: 0,
            rowsPerPage: 10,
            transCount: 0,
            fullTransactions: [],
            locked: true,
            packagedOn: '',
            itemCategory: null,
            tabLabels: [{name: t('detail'), disabled: false}, {name: t('itemLedgers'), disabled: false}],
            tabKey: 0,
            renderNum: Math.random(),
            reserved: '',
            reservedCount: '',
            reservations: [],
            available: '',
            availableCount: '',
            outputLabel: {},
            csvData: null,
            rating: '',
            ratingSel: null,
            ratings: [],
            splitRows: [{tare: '', quantity: '', count: '', bin: '', binSel: null, type:  '', typeSel: null, rating: '', ratingSel: null}],
            cullPlants: null,
            rtv: false,
            availableTypes: []
        }
    }

    async componentDidMount(){
        const cacheValues = await ValueHelper.getCachedValues([common.cacheValues.bin, common.cacheValues.quantityUOM, common.cacheValues.containerType,
                                common.cacheValues.containerStatus, common.cacheValues.lotRating, common.cacheValues.containerTypeAvailable], 
                                this.props.auth.constants, null, this.props.auth.user.tenant);
        let bins = cacheValues.bins;
        let statusSel = cacheValues.containerStatuses.find(x => x.value === this.props.auth.constants.containerStatuses.unapproved);
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.container);
        this.setState({
            _id: this.props.match.params.id != null && this.props.match.params.id !== '' ? this.props.match.params.id : '',
            statuses: cacheValues.containerStatuses,
            status: this.props.auth.constants.containerStatuses.unapproved,
            statusSel: statusSel,
            bins: bins,
            security: this.props.permission,
            fieldAccess: fieldAccess,
            types: cacheValues.containerTypes,
            uoms: cacheValues.quantityUOMs,
            ratings: cacheValues.lotRatings,
            availableTypes: cacheValues.availableContainerTypes
        }, () => {
            if(this.state._id != null && this.state._id !== ''){
                this.loadRecord();
            }else {
                const crumbs = [
                    { path: '/#/containers', label: this.props.t('containers'), screen: this.props.auth.screenDefs.ContainerList},
                    { path: 'active', label: this.props.t('container'), screen: this.props.auth.screenDefs.Container}
                ]
                this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            }
        });
    }

    loadRecord = async () => {
        let record = await axios.get('/api/container/' + this.state._id);
        let status = record.data.status;
        if(status == null)
            status = this.props.auth.constants.containerStatuses.unapproved;
        let statusSel = status != null ? this.state.statuses.find(x => x.value === status) : null;
        let typeSel = record.data.type != null ? this.state.types.find(x => x.value === record.data.type._id) : null;
        let ratingSel = record.data.rating != null ? this.state.ratings.find(x => x.value === record.data.rating) : null;
        const fieldAccess = fieldSecurity(this.props.permission, statusSel.value, this.props.auth.common, this.props.auth.constants, this.props.auth.common.statusTypes.container);
        let outputLabel = {
            dateFormat: this.props.auth.user.dateFormat,
            id: this.state._id,
            container: record.data.number,
            lot: record.data.customLot != null ? record.data.customLot : 
                 record.data.lot != null && record.data.lot.number != null ? record.data.lot.number : '',
            item: record.data.item.number + ' - ' + record.data.item.name,
            quantity: ConversionHelper.round3(record.data.quantity),
            uom: record.data.uom.label,
            category: typeSel.label,
            itemNumber: record.data.item != null && record.data.item.number != null ? record.data.item.number : '',
            date: record.data.transactions.length > 0 ? record.data.transactions[0].date : null,
            qrPath: `${window.location.origin}/#/container/${this.state._id}`,
            strain: record.data.strain != null && record.data.strain !== '' && record.data.strain.name != null && record.data.strain.name !== '' ?
                    record.data.strain.name : null,
            strainCode: record.data.strain != null && record.data.strain !== '' && record.data.strain.shortCode != null && record.data.strain.shortCode !== '' ?
                        record.data.strain.shortCode : null
        }
        this.setState({
            number: record.data.number,
            lot: record.data.lot,
            item: record.data.item,
            quantity: record.data.quantity,
            uom: record.data.uom._id,
            uomSel: record.data.uom,
            tareWeight: record.data.tareWeight,
            totalWeight: record.data.tareWeight != null && record.data.tareWeight !== '' ? record.data.tareWeight + record.data.quantity : '',
            status: status,
            originalStatus: status,
            statusSel: statusSel,
            destroy: record.data.destroy ? true : false,
            destroyReason: record.data.destroyReason,
            bin: record.data.bin,
            fieldAccess: fieldAccess,
            type: record.data.type._id,
            typeSel: typeSel,
            irradiated: record.data.irradiated,
            count: record.data.count,
            strain: record.data.strain != null ? record.data.strain : null,
            expires: record.data.expires != null && record.data.expires !== '' ? record.data.expires : record.data.lot != null && record.data.lot.expires != null && record.data.lot.expires !== '' ? record.data.lot.expires : '',
            discreteWeight: record.data.discreteWeight,
            fullDiscreteWeight: record.data.fullDiscreteWeight,
            packagedOn: record.data.packagedOn,
            itemCategory: record.data.itemCategory,
            renderNum: Math.random(),
            reserved: record.data.reserved,
            reservedCount: record.data.reservedCount,
            reservations: record.data.reservations,
            available: record.data.available,
            availableCount: record.data.availableCount,
            outputLabel: outputLabel,
            csvData: null,
            rating: record.data.rating,
            ratingSel: ratingSel,
            cullPlants: record.data.cullPlants != null && record.data.cullPlants !== '' && record.data.cullPlants !== 0 ? record.data.cullPlants: null,
            rtv: record.data.rtv === true ? true : false
        }, () => {
            const crumbs = [
                { path: '/#/containers', label: this.props.t('containers'), screen: this.props.auth.screenDefs.ContainerList},
                { path: 'active', label: this.props.t('container') + ' - ' + record.data.number, screen: this.props.auth.screenDefs.Container}
            ]
            this.props.dispatch({ type: 'SET_NAV_CRUMBS', payload: crumbs});
            this.props.dispatch({ type: 'SET_OPTIONMENU', payload: { 
                options: [
                    { menuType: this.props.auth.constants.optionMenuTypes.comments },
                    { menuType: this.props.auth.constants.optionMenuTypes.logs }
                ],
                title: this.props.t('options')
            }});
        });
        this.loadTransactions();
    };

    loadTransactions = async () => {
        let record = await axios.get('/api/containertransactions/' + this.state._id);
        let allTrans = record.data.transactions;
        if(allTrans != null){
            allTrans.sort(function(a, b){
                if(a.lastUpdated < b.lastUpdated){
                    return 1;
                }
                if(a.lastUpdated > b.lastUpdated){
                    return -1
                }
                return 0;
            });
        }
        let transactions = allTrans != null ? allTrans.slice(0, this.state.rowsPerPage) : [];
        this.setState({
            transactions: transactions,
            fullTransactions: allTrans
        });
    }

    changeAuto = (name, data) => (e, newValue) => {
        this.setState({
            [name]: newValue,
            [data]: newValue != null ? newValue.value : null
        });
    };

    changeDestroy = (e) => {
        let destroy = !this.state.destroy;
        let destroyReason = this.state.destroyReason;
        let destroyReasonSel = this.state.destroyReasonSel;
        if(!destroy){
            destroyReason = '';
            destroyReasonSel = null;
        }
        this.setState({
            destroy: destroy,
            destroyReason: destroyReason,
            destroyReasonSel: destroyReasonSel
        });
    };

    changeValue = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    changeNumber = (e) => {
        var name = e.target.name;
        var value = e.target.value
        if(isNaN(value)){
            return;
        }
        let newOrigTotal = this.state.newOrigTotal;
        if(name === 'splitQuantity'){
            if(!isNaN(newOrigTotal) && !isNaN(value)){
                let origFloat = parseFloat(this.state.totalWeight);
                let quantity = parseFloat(value);
                if(origFloat != null && origFloat !== 0)
                    newOrigTotal = origFloat - quantity;
                else
                    newOrigTotal = 0;
            }
        }
        this.setState({[name]: value, newOrigTotal: newOrigTotal});
    };

    dateChange = (name) => (e) => {
        this.setState({[name]: e});
    };

    save = () => {
        if(!this.state.enableSave)
            return;
        this.setState({ enableSave: false }, () => this._save());
    }

    _save = async () => {
        const t = this.props.t;
        let errors = {};
        if(this.state.status == null || this.state.status === '')
            errors[t('status')] = t('required');
        if(this.state.destroy && (this.state.destroyReason == null || this.state.destroyReason === ''))
            errors[t('destroyReason')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors});
            this.setState({enableSave: true}, () => {return});
        }
        let data = {
            _id: this.state._id,
            number: this.state.number != null && this.state.number !== '' ? this.state.number.toUpperCase() : null,
            status: this.state.status,
            destroy: this.state.destroy,
            destroyReason: this.state.destroyReason,
            type: this.state.type,
            totalWeight: this.state.totalWeight,
            tareWeight: this.state.tareWeight,
            expires: this.state.expires,
            packagedOn: this.state.packagedOn,
            rating: this.state.rating
        };
        try {
            let result = await axios.post('/api/container', data);
            this.setState({originalStatus: this.state.status, enableSave: true}, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            });
            this.loadRecord();
        } catch(err){
            this.setState({ enableSave: true }, () => {
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            });
        }
    };

    split = (e) => {
        this.setState({showSplit: true, newOrigTotal: this.state.totalWeight});
    };

    clearSplit = (e) => {
        this.setState({
            showSplit: false,
            date: new Date(),
            newOrigTotal: '',
            splitRows: [{tare: '', quantity: '', count: '', bin: '', binSel: null, type:  '', typeSel: null, rating: '', ratingSel: null}]
        })
    };

    saveSplit = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._saveSplit(e);
        });
    }

    _saveSplit = async (e) => {
        const t = this.props.t;
        let errors = {};
        let splitQuantity = 0;
        for(let [i, row] of this.state.splitRows.entries()){
            splitQuantity += parseFloat(row.quantity);
            if(row.quantity == null || row.quantity === '' || row.quantity === '0' || row.quantity === 0)
                errors[`${t('quantity')} (${t('line')} ${i})`] = t('required');
            if(row.bin == null || row.bin === '')
                errors[`${t('bin')} (${t('line')} ${i})`] = t('required');
            if(row.type == null || row.type === '')
                errors[`${t('type')} (${t('line')} ${i})`] = t('required');
        }
        if(ValueHelper.inList(this.state.type, this.state.availableTypes)){
            if(parseFloat(this.state.available) < splitQuantity){
                errors[`${t('quantity')} (${t('line')} 0)`] = t('moreThanContainer');
            }
        } else {
            if(parseFloat(this.state.quantity) < splitQuantity){
                errors[`${t('quantity')} (${t('line')} 0)`] = t('moreThanContainer');
            }
        }
        if(parseFloat(this.state.totalWeight) < parseFloat(this.state.newOrigTotal)){
            errors[`${t('quantity')} (${t('line')} 0)`] = t('moreThanContainer');
        }
        if(this.state.date == null || this.state.date === '')
            errors[t('date')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            this.setState({enableSave: true});
            return;
        }
        let data = {
            _id: this.state._id,
            date: this.state.date,
            type: this.state.splitType,
            splitRows: this.state.splitRows,
            origTotal: this.state.newOrigTotal
        };
        try {
            let result = await axios.post('/api/containersplit', data);
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            this.clearSplit();
            this.loadRecord();
        } catch(err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        } finally {
            this.setState({enableSave: true});
        }
    };

    move = (e) => {
        this.setState({showMove: true});
    };

    clearMove = (e) => {
        this.setState({
            showMove: false,
            moveBin: '',
            moveBinSel: null,
            date: new Date()
        });
    };

    saveMove = (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._saveMove(e);
        })
    }

    _saveMove = async (e) => {
        const t = this.props.t;
        let errors = {};
        if(this.state.moveBin == null || this.state.moveBin === '')
            errors[t('moveBin')] = t('required');
        if(this.state.date == null || this.state.date === '')
            errors[t('date')] = t('required');
        if(!isEmpty(errors)){
            this.props.dispatch({ type: 'CREATE_ERROR', payload: errors });
            return;
        }
        let data = {
            _id: this.state._id,
            bin: this.state.moveBin,
            date: this.state.date
        };
        try {
            let result = await axios.post('/api/containermove', data);
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
            this.clearMove();
            this.loadRecord();
        } catch (err){
            this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
        } finally {
            this.setState({enableSave: true});
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({transPage: newPage}, () => {
            this.sliceTransactions();
        });
    };

    sliceTransactions(){
        let transactions = this.state.fullTransactions.slice(this.state.rowsPerPage * this.state.transPage, (this.state.transPage * this.state.rowsPerPage) + this.state.rowsPerPage);
        this.setState({transactions: transactions})
    }

    handleRowsPerPage = e => {
        this.setState({rowsPerPage: parseInt(e.target.value)}, () => {
            this.sliceTransactions();
        });
    };

    toggleLock = (e) => {
        this.setState({locked: !this.state.locked});
    };

    changeTab = (event, newValue) => {
        this.setState({ tabKey: newValue });
    };

    deleteReservation = (i) => (e) => {
        if(!this.state.enableSave)
            return;
        this.setState({enableSave: false}, () => {
            this._deleteReservation(i);
        })
    }

    _deleteReservation = async (i) => {
        const t = this.props.t;
        let reservations = this.state.reservations;
        let reservation = reservations[i];
        if(reservation != null){
            let data = {
                container: this.state._id,
                reservation: reservation._id
            };
            try{
                let result = await axios.post('/api/reservationdelete', data);
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: result.data.message, title: t('success'), severity: 'success'}});
                this.loadRecord();
            } catch(err){
                this.props.dispatch({ type: 'CREATE_ALERT', payload: {message: err.response.data, title: t('error'), severity: 'error'}});
            } finally {
                this.setState({enableSave: true});
            }
        } else {
            this.setState({enableSave: true});
        }
    }

    buttonStack = dialog => {
        const t = this.props.t;
        let buttons = [];
        switch(dialog) {
            case 'split': {
                buttons = [
                    <Button variant="text" color="secondary" onClick={this.clearSplit} data-cy="splitClose">{t('close')}</Button>,
                    <Button variant="contained" color="primary" onClick={this.saveSplit} data-cy="splitSave" disabled={!this.state.enableSave}>{t('save')}</Button>
                ];
                break;
            }
            case 'move': {
                buttons = [
                    <Button variant="text" color="secondary" onClick={this.clearMove} data-cy="moveClose">{t('close')}</Button>,
                    <Button variant="contained" color="primary" onClick={this.saveMove} data-cy="moveSave" disabled={!this.state.enableSave}>{t('save')}</Button>
                ];
                break;
            }
            default: 
                break;
        }
        return buttons;
    }

    csvClick = async (e) => {
        const t = this.props.t;
        this.setState({csvData: null});
        let transactions = this.state.fullTransactions;
        let data = [];
        for(let row of transactions){
            data.push({
                date: row.date,
                quantity: row.quantity,
                count: row.count,
                discrete: row.fullDiscreteWeight,
                record: row.object.text + '-' + row.record.number,
                lastUpdated: row.lastUpdated
            })
        }
        let headers = [
            {label: t('date'), key: 'date'},
            {label: t('quantity'), key: 'quantity'},
            {label: t('count'), key: 'count'},
            {label: t('discreteWeightG'), key: 'discrete'},
            {label: t('record'), key: 'record'},
            {label: t('lastUpdated'), key: 'lastUpdated'}
        ];
        this.setState({csvData: data, headers: headers});
    }

    changeSplitNumber = (i) => (e) => {
        let splitRows = this.state.splitRows;
        if(isNaN(e.target.value))
            return;
        splitRows[i][e.target.name] = e.target.value;
        this.setState({splitRows: splitRows}, () => this.calculateSplitTotals());
    }

    changeSplitAuto = (i, name, data) => (e, newValue) => {
        let splitRows = this.state.splitRows;
        splitRows[i][name] = newValue;
        splitRows[i][data] = newValue != null ? newValue.value : null;
        this.setState({splitRows: splitRows});
    }

    changeSplitRating = (i) => (e, newValue) => {
        let splitRows = this.state.splitRows;
        const ratingSel = this.state.ratings.find(x => x.label === newValue.toString());
        splitRows[i].ratingSel = ratingSel != null ? ratingSel : null;
        splitRows[i].rating = newValue != null && ratingSel != null ? ratingSel.value : null;
        this.setState({splitRows: splitRows});
    };

    addSplitRow = (e) => {
        let splitRows = this.state.splitRows;
        splitRows.push({tare: '', quantity: '', count: '', bin: '', binSel: null, type:  '', typeSel: null, rating: '', ratingSel: null});
        this.setState({splitRows: splitRows});
    }

    removeSplitRow = (i) => (e) => {
        let splitRows = this.state.splitRows;
        splitRows.splice(i, 1);
        this.setState({splitRows: splitRows}, () => this.calculateSplitTotals());
    };

    calculateSplitTotals(){
        let splitRows = this.state.splitRows;
        let origFloat = parseFloat(this.state.totalWeight);
        let splitAmount = 0;
        for(let row of splitRows){
            let quantity = parseFloat(row.quantity);
            if(!isNaN(quantity)){
                splitAmount += quantity;
            }
        }
        let newOrigTotal = origFloat - splitAmount;
        this.setState({newOrigTotal: newOrigTotal});
    }

    render(){
        const errors = this.props.errors;
        const fieldAccess = this.state.fieldAccess;
        const locked = this.state.locked;
        const t = this.props.t;
        return(
            <Aux>
                <BreadcrumbBar>
                    <ActionBar
                        objectId={this.props.auth.constants.objectIds.container}
                        labelData={this.state.outputLabel}
                        labelAmount='single-actionBar'
                        optionsAvailable={this.state._id != null && this.state._id !== '' ? true : false}
                        flagForSignature={0}
                    />
                </BreadcrumbBar>
                <EvoraTabs tabLabels={this.state.tabLabels} tabKey={this.state.tabKey} changeTab={this.changeTab} />
                <TabCanvas value={this.state.tabKey} index={0}>
                    <Grid container spacing={3}>
                        <Grid item container xs={12} lg={6} spacing={2} alignContent='flex-start'>
                            <Grid item xs={12} lg={8}>
                                <Panel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <BoxTitle title={t('details')} />
                                        </Grid>
                                        <Grid item xs={11}>
                                            <FormControl>
                                                <FormLabel>{t('item')}</FormLabel>
                                                <Link to={'/item/' + this.state.item._id}>
                                                    {this.state.item !== '' ? this.state.item.number + ' - ' + this.state.item.name : ''}
                                                </Link>
                                            </FormControl>
                                        </Grid>
                                        {this.state.irradiated &&
                                            <Grid item xs={1}>
                                                <Tooltip title={t('irradiated')} placement='top' >
                                                    <IrradiatedIcon color='secondary' />    
                                                </Tooltip>
                                            </Grid>
                                        }
                                        <Grid item xs={6}>
                                            {locked ?
                                                <FormControl data-cy="number">
                                                    <FormLabel>{t('container')}</FormLabel>
                                                    {this.state.number}
                                                </FormControl> :
                                                <TextField type="text" value={this.state.number} onChange={this.changeValue} name="number"
                                                size="medium" fullWidth={true} label={t('container')} disabled={!fieldAccess || locked}data-cy="number"
                                                error={errors[t('number')] != null ? true : false} helperText={errors[t('number')]}/>
                                            }                                        
                                        </Grid>
                                        {/* {fieldAccess === true  && fieldAccess === false &&
                                            <Grid item xs={6} sm={3}>
                                                <AutoCompleteField
                                                    value={this.state.statusSel}
                                                    options={this.state.statuses}
                                                    onChange={this.changeAuto('statusSel', 'status')}
                                                    error={errors[t('status')] != null ? true : false}
                                                    helperText={errors[t('status')]}
                                                    label={t('status')}
                                                    disabled={!fieldAccess}
                                                    required
                                                    data-cy="status"
                                                />
                                            </Grid>
                                        }
                                        {fieldAccess === false && fieldAccess === true &&
                                            <Grid item xs={4} sm={2}>
                                                <FormControl>
                                                    <FormLabel>{t('status')}</FormLabel>
                                                    {this.state.statusSel != null ? this.state.statusSel.label : ''}
                                                </FormControl>
                                            </Grid>
                                        } */}
                                        {this.state.lot != null &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('lot')}</FormLabel>
                                                    <Link to={'/lot/' + this.state.lot._id}>
                                                        {this.state.lot.number}
                                                    </Link>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.lot != null && this.state.lot.vendorLot != null && this.state.vendorLot !== '' &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('vendorLot')}</FormLabel>
                                                    {this.state.lot.vendorLot}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.destroy &&
                                            <Grid item xs={6}>
                                                <AutoCompleteField
                                                    value={this.state.destroyReasonSel}
                                                    options={this.state.destructionReasons}
                                                    onChange={this.changeAuto('destroyReasonSel', 'destroyReason')}
                                                    error={errors[t('destroyReason')] != null ? true : false}
                                                    helperText={errors[t('destroyReason')]}
                                                    label={t('reason')}
                                                    disabled={!fieldAccess}
                                                    required
                                                    data-cy="destroyReason"
                                                />
                                            </Grid>
                                        }
                                        {fieldAccess && !locked && this.state.itemCategory != null && this.state.itemCategory.packagedOn === true &&
                                            <Grid item xs={6}>
                                                <DateSelect
                                                    onChange={this.dateChange('packagedOn')}
                                                    value={this.state.packagedOn}
                                                    helperText={errors[t('packagedOn')]}
                                                    error={errors[t('packagedOn')] != null ? true : false}
                                                    disabled={!fieldAccess}
                                                    label={t('packagedOn')}
                                                    format={this.props.auth.user.dateFormat}
                                                    data-cy="packagedOn"
                                                />
                                            </Grid>
                                        }
                                        {(!fieldAccess || locked) && this.state.packagedOn != null && this.state.packagedOn !== '' &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('packagedOn')}</FormLabel>
                                                    {this.state.packagedOn != null && this.state.packagedOn !== '' && <DateDisplay format={this.props.auth.user.dateFormat} value={this.state.packagedOn}/>}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.expires != null && this.state.expires !== '' && locked &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('expires')}</FormLabel>
                                                    {this.state.expires != null && this.state.expires !== '' ?
                                                        <DateDisplay format={this.props.auth.user.dateFormat} value={this.state.expires}/> :
                                                        t('noneSet')
                                                    }   
                                                </FormControl>
                                            </Grid>                                        
                                        }
                                        {fieldAccess && !locked &&
                                            <Grid item xs={6}>
                                                <DateSelect
                                                    onChange={this.dateChange('expires')}
                                                    value={this.state.expires}
                                                    helperText={errors[t('expires')]}
                                                    error={errors[t('expires')] != null ? true : false}
                                                    disabled={!fieldAccess}
                                                    label={t('expires')}
                                                    format={this.props.auth.user.dateFormat}
                                                    data-cy="expires"
                                                />
                                            </Grid>
                                        }                                        
                                        {this.state.strain != null &&
                                            <Grid item xs={6} lg={12}>
                                                <FormControl>
                                                    <FormLabel>{t('strain')}</FormLabel>
                                                    {this.state.strain.name}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {!locked && fieldAccess &&
                                            <Grid item xs={6}>
                                                <AutoCompleteField
                                                    value={this.state.typeSel}
                                                    options={this.state.types}
                                                    onChange={this.changeAuto('typeSel', 'type')}
                                                    error={errors[t('type')] != null ? true : false}
                                                    helperText={errors[t('type')]}
                                                    label={t('type')}
                                                    disabled={!fieldAccess}
                                                    data-cy="type"
                                                />
                                            </Grid>
                                        }
                                        {(locked || !fieldAccess) &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('type')}</FormLabel>
                                                    {this.state.typeSel != null ? this.state.typeSel.label : ''}
                                                </FormControl>
                                            </Grid>
                                        }
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <FormLabel>{t('bin')}</FormLabel>
                                                {this.state.bin != null  &&
                                                    <Link to={'/bin/' + this.state.bin._id}>{this.state.bin.description}</Link>
                                                }
                                            </FormControl>
                                        </Grid>
                                        {this.state.rtv === true &&
                                            <Grid item xs={6}>
                                                <FormControl>
                                                    <FormLabel>{t('rtv')}</FormLabel>
                                                    {t('yes')}
                                                </FormControl>
                                            </Grid>
                                        }
                                        {!locked && fieldAccess &&
                                            <Grid item xs={6} md={6} lg={6}>
                                                <AutoCompleteField
                                                    value={this.state.ratingSel}
                                                    options={this.state.ratings}
                                                    onChange={this.changeAuto('ratingSel', 'rating')}
                                                    error={errors[t('type')] != null ? true : false}
                                                    helperText={errors[t('rating')]}
                                                    label={t('rating')}
                                                    disabled={!fieldAccess || locked}
                                                    data-cy="rating"
                                                />
                                            </Grid>
                                        }
                                        {this.state.ratingSel != null && (locked || !fieldAccess) &&
                                            <Grid item xs={6} md={6} lg={6}>
                                                <FormControl>
                                                    <FormLabel>{t('rating')}</FormLabel>
                                                    {this.state.ratingSel.label}
                                                </FormControl>
                                            </Grid>
                                        }
                                    </Grid>
                                </Panel>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Panel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <BoxTitle title={t('quantities')} />
                                        </Grid>
                                        {!locked && this.state.uomSel.exportName !== 'each' && this.state.uomSel.exportName !== 'roll' && this.state.uomSel.exportName !== 'case' && 
                                            <Grid item xs={4} lg={12} sx={{ textAlign: 'right' }}>
                                                <TextField type="number" value={this.state.tareWeight} onChange={this.changeNumber} name={"tareWeight"}
                                                size="medium" fullWidth={true} label={t('tareWeight') + ' (' + this.state.uomSel.label + ')'} disabled={!fieldAccess}
                                                data-cy="tareWeight" error={errors[t('tareWeight')] != null ? true : false} helperText={errors[t('tareWeight')]}/>
                                            </Grid>
                                        }
                                        {locked && this.state.tareWeight != null && this.state.tareWeight !== '' && this.state.tareWeight !== 0 &&
                                            <Grid item xs={4} lg={12} sx={{ textAlign: 'right' }}>
                                                <FormControl>
                                                    <FormLabel>{t('tareWeight') + ' (' + this.state.uomSel.label + ')'}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.tareWeight} thousandSeparator={true}
                                                    decimalScale={3}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.uomSel != null &&
                                            <Grid item xs={6} lg={12} sx={{ textAlign: 'right' }}>
                                                <FormControl>
                                                    <FormLabel>
                                                        {this.state.tareWeight != null && this.state.tareWeight !== '' && this.state.tareWeight !== 0 && this.state.totalWeight != null && this.state.totalWeight !== '' ?
                                                            t('netWeight') + ' (' + this.state.uomSel.label + ')' :
                                                            t('total') + ' (' + this.state.uomSel.label + ')'
                                                        }                                                        
                                                    </FormLabel>
                                                    {this.state.quantity != null && this.state.uom != null &&
                                                        <NumericFormat displayType={'text'} value={this.state.quantity} thousandSeparator={true}
                                                        decimalScale={3}/>
                                                    }
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.tareWeight != null && this.state.tareWeight !== '' && this.state.tareWeight !== 0 && this.state.totalWeight != null && this.state.totalWeight !== '' &&
                                            <Grid item xs={4} lg={12} sx={{ textAlign: 'right' }}>
                                                <FormControl>
                                                    <FormLabel>{t('totalWeight') + ' (' + this.state.uomSel.label + ')'}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.totalWeight} thousandSeparator={true}
                                                    decimalScale={3}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.count != null && this.state.count !== '' && this.state.count !== '0' && this.state.count !== 0 &&
                                            <Grid item xs={4} lg={12} sx={{ textAlign: 'right' }}>
                                                <FormControl>
                                                    <FormLabel>{t('count')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.count} thousandSeparator={true}
                                                    decimalScale={0}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.available != null && this.state.available !== '' && this.state.available !== this.state.quantity && this.state.uomSel != null &&
                                            <Grid item xs={4} lg={12} sx={{ textAlign: 'right' }}>
                                                <FormControl>
                                                    <FormLabel>{t('available') + ' (' + this.state.uomSel.label + ')'}</FormLabel>
                                                    {this.state.quantity != null && this.state.uom != null &&
                                                        <NumericFormat displayType={'text'} value={this.state.available} thousandSeparator={true}
                                                        decimalScale={3} suffix={this.state.uomSel.label}/>
                                                    }
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.availableCount != null && this.state.availableCount !== '' && this.state.availableCount !== '0' && this.state.availableCount !== 0 &&
                                        this.state.availableCount !== this.state.count &&
                                            <Grid item xs={4} lg={12} sx={{ textAlign: 'right' }}>
                                                <FormControl>
                                                    <FormLabel>{t('availableCount')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.availableCount} thousandSeparator={true}
                                                    decimalScale={0}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.reserved != null && this.state.reserved !== '' && this.state.reserved !== 0 && this.state.uomSel != null &&
                                            <Grid item xs={4} lg={12} sx={{ textAlign: 'right' }}>
                                                <FormControl>
                                                    <FormLabel>{t('reserved') + ' (' + this.state.uomSel.label + ')'}</FormLabel>
                                                    {this.state.reserved != null && this.state.uom != null &&
                                                        <NumericFormat displayType={'text'} value={this.state.reserved} thousandSeparator={true}
                                                        decimalScale={3} />
                                                    }
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.reservedCount != null && this.state.reservedCount !== '' && this.state.reservedCount !== '0' && this.state.reservedCount !== 0 &&
                                            <Grid item xs={4} lg={12} sx={{ textAlign: 'right' }}>
                                                <FormControl>
                                                    <FormLabel>{t('reservedCount')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.reservedCount} thousandSeparator={true}
                                                    decimalScale={0}/>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.discreteWeight != null && this.state.discreteWeight !== '' &&
                                            <Grid item xs={4} lg={12} sx={{ textAlign: 'right' }}>
                                                <FormControl>
                                                    <FormLabel>{t('discreteWeightG')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.discreteWeight} thousandSeparator={true}
                                                    decimalScale={3} />
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.fullDiscreteWeight != null && this.state.fullDiscreteWeight !== '' &&
                                            <Grid item xs={4} lg={12} sx={{ textAlign: 'right' }}>
                                                <FormControl>
                                                    <FormLabel>{t('fullDiscreteG')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.fullDiscreteWeight} thousandSeparator={true}
                                                    decimalScale={3} />
                                                </FormControl>
                                            </Grid>
                                        }
                                        {this.state.cullPlants != null && this.state.cullPlants !== '' &&
                                            <Grid item xs={4} lg={12} sx={{ textAlign: 'right' }}>
                                                <FormControl>
                                                    <FormLabel>{t('plants')}</FormLabel>
                                                    <NumericFormat displayType={'text'} value={this.state.cullPlants} thousandSeparator={true}
                                                    decimalScale={3} />
                                                </FormControl>
                                            </Grid>
                                        }
                                    </Grid>
                                </Panel>
                            </Grid>
                            <Grid item xs={12}>
                                <Panel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <BoxTitle title={t('reservations')}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow key="reservationHead">
                                                            <TableCell>{t('quantity') + ' (' + this.state.uomSel.label + ')'}</TableCell>
                                                            {this.state.count != null && this.state.count !== '' && this.state.count !== 0 &&
                                                                <TableCell>{t('count')}</TableCell>
                                                            }
                                                            <TableCell item xs={6} sm={3}>{t('source')}</TableCell>
                                                            {!locked &&
                                                                <TableCell></TableCell>
                                                            }
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.reservations != null && this.state.reservations.length > 0 ? 
                                                            this.state.reservations.map((row, i) => (
                                                                <TableRow key={row._id}>
                                                                    <TableCell>
                                                                        <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}
                                                                        decimalScale={3} />
                                                                    </TableCell>
                                                                    {this.state.count != null && this.state.count !== '' && this.state.count !== 0 &&
                                                                        <TableCell>
                                                                            <NumericFormat displayType={'text'} value={row.count} thousandSeparator={true}
                                                                            decimalScale={0}/>
                                                                        </TableCell>
                                                                    }
                                                                    <TableCell>
                                                                        {row.record != null  &&
                                                                            <Link to={row.object.link + row.record._id}>{row.object.text + '-' + row.record.number}</Link>
                                                                        }
                                                                    </TableCell>
                                                                    {!locked &&
                                                                        <TableCell>
                                                                            <IconButton aria-label="delete" onClick={this.deleteReservation(i)} data-cy="deleteReservation">
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    }
                                                                </TableRow>
                                                            )) : 
                                                            <TableRow key='aBourdainRow'>
                                                                <TableCell colSpan={4} align="center">{t('noData')}</TableCell>
                                                            </TableRow>                                                                
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Panel>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} lg={6} spacing={2}>
                            <Grid item xs={12}>
                                <Panel>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <BoxTitle title={t('transactions')}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow key="transHead">
                                                            <TableCell align="right">{t('quantity') + ' (' + this.state.uomSel.label + ')'}</TableCell>
                                                            {this.state.count != null && this.state.count !== '' && this.state.count !== 0 &&
                                                                <TableCell align="right">{t('count')}</TableCell>
                                                            }
                                                            {this.state.discreteWeight != null && this.state.discreteWeight !== '' &&
                                                                <TableCell align="right">{t('discreteWeightG')}</TableCell>
                                                            }
                                                            <TableCell>{t('source')}</TableCell>
                                                            <TableCell sx={{ display: { xs: 'none', md: 'table-cell' }}}>{t('bin')}</TableCell>
                                                            <TableCell>{t('date')}</TableCell>
                                                            <TableCell sx={{ display: { xs: 'none', md: 'table-cell' }}}>{t('lastUpdated')}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.transactions.map((row, i) =>
                                                            <TableRow key={row._id}>
                                                                <TableCell align="right">
                                                                    {this.state.uom != null && row.quantity != null &&
                                                                        <NumericFormat displayType={'text'} value={row.quantity} thousandSeparator={true}
                                                                        decimalScale={3} />
                                                                    }
                                                                </TableCell>
                                                                {this.state.count != null && this.state.count !== '' && this.state.count !== 0 &&
                                                                    <TableCell align="right">
                                                                        <NumericFormat displayType={'text'} value={row.count} thousandSeparator={true}
                                                                        decimalScale={0}/>
                                                                    </TableCell>
                                                                }
                                                                {this.state.discreteWeight != null && this.state.discreteWeight !== '' &&
                                                                    <TableCell align="right">
                                                                        <NumericFormat displayType={'text'} value={row.fullDiscreteWeight} thousandSeparator={true}
                                                                        decimalScale={3} />
                                                                    </TableCell>
                                                                }
                                                                <TableCell>
                                                                    {row.record != null && row.object != null &&
                                                                        <Link to={row.object.link + row.record._id}>
                                                                            {row.object._id === this.props.auth.constants.objectIds.batchActivity ? row.record.batch.number + ' - ' +
                                                                            row.record.definition.description + ' - ' + row.record.number :
                                                                            row.object.text + ' - ' + row.record.number
                                                                            }
                                                                        </Link>
                                                                    }
                                                                    {row.record == null && row.object != null ? row.object.text : ''}
                                                                </TableCell>
                                                                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' }}}>{row.bin != null ? row.bin.description : ''}</TableCell>
                                                                <TableCell><DateDisplay value={row.date} format={this.props.auth.user.dateFormat}/></TableCell>
                                                                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' }}}><DateDisplay value={row.lastUpdated} format={this.props.auth.common.dateTimeFormat}/></TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                    {this.state.fullTransactions != null && this.state.fullTransactions.length > 0 &&
                                                            <TableFooter>
                                                                <TableRow key="transFoot">
                                                                    <Tooltip title={t('exportToCsv')} >
                                                                        <IconButton
                                                                            disabled={false}
                                                                            component={'div'}
                                                                            onClick={this.csvClick}
                                                                            name='actionCsv'
                                                                        >
                                                                            <ExcelIcon fontSize='medium'/>
                                                                            </IconButton>
                                                                    </Tooltip>
                                                                    <TablePagination count={this.state.fullTransactions.length}
                                                                    rowsPerPage={this.state.rowsPerPage}
                                                                    onPageChange={this.handleChangePage}
                                                                    page = {this.state.transPage}
                                                                    rowsPerPageOptions={common.rowOptions}
                                                                    onRowsPerPageChange={this.handleRowsPerPage}/>
                                                                </TableRow>
                                                            </TableFooter>
                                                        }
                                                </Table>
                                            </TableContainer>
                                            {this.state.csvData != null &&
                                                <CSVDownload data={this.state.csvData} target="_blank" headers={this.state.headers} separator={this.state.separator}/>
                                            }
                                        </Grid>
                                    </Grid>
                                </Panel>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item xs={12}>
                                <FormButtonStack
                                    id={this.state._id}
                                    objectType={this.props.auth.constants.objectIds.container}
                                    callback={this.loadRecord}
                                    saveClick={this.save}
                                    status={this.state.originalStatus}
                                    permissions={this.props.permission}
                                    common={this.props.auth.common}
                                    constants={this.props.auth.constants}
                                    type={this.props.auth.common.statusTypes.container}
                                    splitClick={this.split}
                                    moveClick={this.move}
                                    saveEnabled={this.state.enableSave}
                                    simpleUnlockClick={this.toggleLock}
                                    locked={this.state.locked}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </TabCanvas>
                <TabCanvas value={this.state.tabKey} index={this.state.tabLabels.length - 1}>
                    <ItemLedgerComponent container={this.state._id} permission={this.props.permission} auth={this.props.auth} renderNum={this.state.renderNum}/>
                </TabCanvas>
                {this.state.showSplit &&
                    <ModalDialog buttonStack={this.buttonStack('split')} title={t('splitContainer')} toggleDialog={this.clearSplit} 
                    dialogStatus={this.state.showSplit} fullWidth maxWidth='lg'>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <DateSelect
                                    onChange={this.dateChange('date')}
                                    value={this.state.date}
                                    helperText={errors[t('date')]}
                                    error={errors[t('date')] != null ? true : false}
                                    label={t('date')}
                                    disabled={!fieldAccess}
                                    format={this.props.auth.user.dateFormat}
                                    data-cy="splitDate"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item container xs={8}>
                                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                    <FormControl>
                                        <FormLabel>{t('originalTotal') + (this.state.uomSel != null && this.state.uomSel.label != null ?  ' (' + this.state.uomSel.label + ')' : '')}</FormLabel>
                                        <NumericFormat displayType={'text'} value={this.state.totalWeight != null && this.state.totalWeight > 0 ? this.state.totalWeight : 0} thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                    <FormControl>
                                        <FormLabel>{t('newTotal') + (this.state.uomSel != null && this.state.uomSel.label != null ?  ' (' + this.state.uomSel.label + ')' : '')}</FormLabel>
                                        <NumericFormat displayType={'text'} value={this.state.newOrigTotal != null && this.state.newOrigTotal > 0 ? this.state.newOrigTotal : 0} thousandSeparator={true} decimalScale={3}/>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} key="containerListHead">
                            <Grid item xs={4} sm={2}>
                                <FormLabel>{t('quantity') + (this.state.uomSel != null && this.state.uomSel.label != null ? ' (' + this.state.uomSel.label +')' : '')}</FormLabel>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <FormLabel>{t('tare') + (this.state.uomSel != null && this.state.uomSel.label != null ?  ' (' + this.state.uomSel.label + ')' : '')}</FormLabel>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <FormLabel>{t('type')}</FormLabel>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormLabel>{t('bin')}</FormLabel>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <FormLabel>{t('rating')}</FormLabel>
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <IconButton aria-label='addSplit' onClick={this.addSplitRow} data-cy='addSplit'>
                                    <AddIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        {this.state.splitRows.map((row, i) =>
                            <Grid container spacing={3} key={"containerList" + i}>
                                <Grid item xs={4} sm={2}>
                                    <TextField type="number" value={row.quantity} onChange={this.changeSplitNumber(i)} name={"quantity"}
                                    size="medium" fullWidth={true} data-cy={"quantity" + i}
                                    error={errors[`${t('quantity')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('quantity')} (${t('line')} ${i})`]}/>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <TextField type="number" value={row.tare} onChange={this.changeSplitNumber(i)} name={"tare"}
                                    size="medium" fullWidth={true} data-cy={"tare" + i}
                                    error={errors[`${t('tare')} (${t('line')} ${i})`] != null ? true : false} helperText={errors[`${t('tare')} (${t('line')} ${i})`]}/>
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <AutoCompleteField
                                        value={row.typeSel}
                                        options={this.state.types}
                                        onChange={this.changeSplitAuto(i, 'typeSel', 'type')}
                                        error={errors[`${t('splitType')} (${t('line')} ${i})`] != null ? true : false}
                                        helperText={errors[`${t('splitType')} (${t('line')} ${i})`]}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy={"splitType" + i}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={row.binSel}
                                        options={this.state.bins}
                                        onChange={this.changeSplitAuto(i, 'binSel', 'bin')}
                                        error={errors[`${t('bin')} (${t('line')} ${i})`] != null ? true : false}
                                        helperText={errors[`${t('bin')} (${t('line')} ${i})`]}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy={"splitBin" + i}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <AutoCompleteField
                                        value={row.ratingSel}
                                        options={this.state.ratings}
                                        onChange={this.changeSplitAuto(i, 'ratingSel', 'rating')}
                                        error={errors[`${t('rating')} (${t('line')} ${i})`] != null ? true : false}
                                        helperText={errors[`${t('rating')} (${t('line')} ${i})`]}
                                        disabled={!fieldAccess}
                                        required
                                        data-cy={"splkitRating" + i}
                                    />
                                </Grid>
                                {i > 0 &&
                                    <Grid xs={2} sm={1}>
                                        <IconButton aria-label={"delete" + i} onClick={this.removeSplitRow(i)} data-cy={"removeSplitRow" + i}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>
                        )}         
                    </ModalDialog>
                }
                {this.state.showMove &&
                    <ModalDialog buttonStack={this.buttonStack('move')} title={t('moveContainer')} toggleDialog={this.clearMove} 
                                 dialogStatus={this.state.showMove} fullWidth maxWidth='xs'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <DateSelect
                                    onChange={this.dateChange('date')}
                                    value={this.state.date}
                                    helperText={errors[t('date')]}
                                    error={errors[t('date')] != null ? true : false}
                                    label={t('date')}
                                    disabled={!fieldAccess}
                                    format={this.props.auth.user.dateFormat}
                                    required
                                    data-cy="moveDate"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AutoCompleteField
                                    value={this.state.moveBinSel}
                                    options={this.state.bins}
                                    onChange={this.changeAuto('moveBinSel', 'moveBin')}
                                    error={errors[t('moveBin')] != null ? true : false}
                                    helperText={errors[t('moveBin')]}
                                    label={t('bin')}
                                    disabled={!fieldAccess}
                                    data-cy="moveBin"
                                    groupBy={this.props.auth.user.groupBin != null && this.props.auth.user.groupBin === true ? 'room' : null}
                                    noneMessage={t('noRoomSelected')}
                                />
                            </Grid>
                        </Grid>
                    </ModalDialog>
                }
                {this.props.openComments &&
                    <Comments entityId={this.state._id} object={this.props.auth.constants.objectIds.container}/>
                }
                {this.props.openLogs &&
                    <ObjectLog id={this.state._id}/>
                }
            </Aux>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    devices: state.devices,
    openComments: state.optionMenu.openComments,
    openLogs: state.optionMenu.openLogs,
    errors: state.errors.list
});

export default withTranslation() (connect(mapStateToProps)(Container));
